import axios from "axios";

 // export const apiHost = "https://localhost:44315";
   export const apiHost = "https://api.mobala.ca";
// export const apiHost = "https://api.hassancomputing.com";

export const InviteStatuses = (status, isAccepted = false) => {
  if (isAccepted) return { buttonText: "Invite Accepted", disabled: true };
  switch (status) {
    case "None":
      return { buttonText: "Send Invite", disabled: false };
    case "Pending":
      return { buttonText: "Invite Sent", disabled: true };
    case "Accepted":
      return { buttonText: "Invite Accepted", disabled: true };
    case "Rejected":
      return { buttonText: "Invite Rejected", disabled: true };
    default:
      break;
  }
};

export const setDoc = (path, Data) => {
  return new Promise((resolve, reject) => {
    Data["path"] = path;
    let jsonData = JSON.stringify(Data);
    let url = `${apiHost}/api/Update/Setdata`;
    axios
      .post(url, jsonData)
      .then((response) => {
        // //console.log("Uploaded", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  });
};

export const uploadToStorage = (base64String, path) => {
  let data = { base64String: base64String, path: path };
  let url = `${apiHost}/api/Storage/UploadStorage`;
  axios
    .post(url, data)
    .then((response) => {
      // //console.log("Uploaded", response.data);
    })
    .catch((error) => {
      //console.log(error);
    });
};

export const getStorage = (path) => {
  return new Promise((resolve, reject) => {
    let url = `${apiHost}/api/Storage/GetStorage?path=${path}`;
    axios
      .get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        //console.log(error);
        resolve("");
      });
  });
};

export const addDoc = (path, Data) => {
  return new Promise((resolve, reject) => {
    let jsonData = JSON.stringify(Data);
    let url = `${apiHost}/api/Update/Adddata?data=${jsonData}&path=${path}`;
    axios
      .post(url, Data)
      .then((response) => {
        // //console.log("Uploaded", response.data);
        resolve(response.data);
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

export const deleteDoc = (path) => {
  return new Promise((resolve, reject) => {
    let authToken = localStorage.getItem("authToken");
    let url = `${apiHost}/api/Update/Deletedata?path=${path}`;
    axios
      .post(
        url,
        { doc: "deleted" },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  });
};
