import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Container, Row, Col, Form } from "react-bootstrap";
import Logo from "../asstes/HoneyLogo.svg";
import { AppContext } from "../Context";
import "../App.css";

function SecondaryHeader() {
  const navigate = useNavigate();
  const [uid, setUID] = useState(sessionStorage.getItem("UserID"));
  const context = useContext(AppContext);
 
  return (
    <Navbar className="bgnav" expand="lg">
      <Container>
        <Navbar.Brand>
          <img
            src={Logo}
            className="Logo cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
            alt="Logo"
          />
        </Navbar.Brand>
      </Container>

     
    </Navbar>
  );
}

export default SecondaryHeader;
