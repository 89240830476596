let country = ['Afghanistan',
'Aland Islands',
'Albania',
'Algeria',
'American Samoa',
'Andorra',
'Angola',
'Anguilla',
'Antarctica',
'Antigua And Barbuda',
'Argentina',
'Armenia',
'Aruba',
'Australia',
'Austria',
'Azerbaijan',
'Bahamas The',
'Bahrain',
'Bangladesh',
'Barbados',
'Belarus',
'Belgium',
'Belize',
'Benin',
'Bermuda',
'Bhutan',
'Bolivia',
'Bosnia and Herzegovina',
'Botswana',
'Bouvet Island',
'Brazil',
'British Indian Ocean Territory',
'Brunei',
'Bulgaria',
'Burkina Faso',
'Burundi',
'Cambodia',
'Cameroon',
'Canada',
'Cape Verde',
'Cayman Islands',
'Central African Republic',
'Chad',
'Chile',
'China',
'Christmas Island',
'Cocos (Keeling) Islands',
'Colombia',
'Comoros',
'Congo',
'Congo The Democratic Republic Of The',
'Cook Islands',
'Costa Rica',
'Cote DIvoire (Ivory Coast)',
'Croatia (Hrvatska)',
'Cuba',
'Cyprus',
'Czech Republic',
'Denmark',
'Djibouti',
'Dominica',
'Dominican Republic',
'East Timor',
'Ecuador',
'Egypt',
'El Salvador',
'Equatorial Guinea',
'Eritrea',
'Estonia',
'Ethiopia',
'Falkland Islands',
'Faroe Islands',
'Fiji Islands',
'Finland',
'France',
'French Guiana',
'French Polynesia',
'French Southern Territories',
'Gabon',
'Gambia The',
'Georgia',
'Germany',
'Ghana',
'Gibraltar',
'Greece',
'Greenland',
'Grenada',
'Guadeloupe',
'Guam',
'Guatemala',
'Guernsey and Alderney',
'Guinea',
'Guinea-Bissau',
'Guyana',
'Haiti',
'Heard Island and McDonald Islands',
'Honduras',
'Hong Kong S.A.R.',
'Hungary',
'Iceland',
'India',
'Indonesia',
'Iran',
'Iraq',
'Ireland',
'Israel',
'Italy',
'Jamaica',
'Japan',
'Jersey',
'Jordan',
'Kazakhstan',
'Kenya',
'Kiribati',
'Korea North',
'Korea South',
'Kuwait',
'Kyrgyzstan',
'Laos',
'Latvia',
'Lebanon',
'Lesotho',
'Liberia',
'Libya',
'Liechtenstein',
'Lithuania',
'Luxembourg',
'Macau S.A.R.',
'Macedonia',
'Madagascar',
'Malawi',
'Malaysia',
'Maldives',
'Mali',
'Malta',
'Man (Isle of)',
'Marshall Islands',
'Martinique',
'Mauritania',
'Mauritius',
'Mayotte',
'Mexico',
'Micronesia',
'Moldova',
'Monaco',
'Mongolia',
'Montenegro',
'Montserrat',
'Morocco',
'Mozambique',
'Myanmar',
'Namibia',
'Nauru',
'Nepal',
'Bonaire, Sint Eustatius and Saba',
'Netherlands The',
'New Caledonia',
'New Zealand',
'Nicaragua',
'Niger',
'Nigeria',
'Niue',
'Norfolk Island',
'Northern Mariana Islands',
'Norway',
'Oman',
'Pakistan',
'Palau',
'Palestinian Territory Occupied',
'Panama',
'Papua new Guinea',
'Paraguay',
'Peru',
'Philippines',
'Pitcairn Island',
'Poland',
'Portugal',
'Puerto Rico',
'Qatar',
'Reunion',
'Romania',
'Russia',
'Rwanda',
'Saint Helena',
'Saint Kitts And Nevis',
'Saint Lucia',
'Saint Pierre and Miquelon',
'Saint Vincent And The Grenadines',
'Saint-Barthelemy',
'Saint-Martin (French part)',
'Samoa',
'San Marino',
'Sao Tome and Principe',
'Saudi Arabia',
'Senegal',
'Serbia',
'Seychelles',
'Sierra Leone',
'Singapore',
'Slovakia',
'Slovenia',
'Solomon Islands',
'Somalia',
'South Africa',
'South Georgia',
'South Sudan',
'Spain',
'Sri Lanka',
'Sudan',
'Suriname',
'Svalbard And Jan Mayen Islands',
'Swaziland',
'Sweden',
'Switzerland',
'Syria',
'Taiwan',
'Tajikistan',
'Tanzania',
'Thailand',
'Togo',
'Tokelau',
'Tonga',
'Trinidad And Tobago',
'Tunisia',
'Turkey',
'Turkmenistan',
'Turks And Caicos Islands',
'Tuvalu',
'Uganda',
'Ukraine',
'United Arab Emirates',
'United Kingdom',
'United States',
'United States Minor Outlying Islands',
'Uruguay',
'Uzbekistan',
'Vanuatu',
'Vatican City State (Holy See)',
'Venezuela',
'Vietnam',
'Virgin Islands (British)',
'Virgin Islands (US)',
'Wallis And Futuna Islands',
'Western Sahara',
'Yemen',
'Zambia',
'Zimbabwe',
'Kosovo',
'Curacao',
'Sint Maarten (Dutch part)',
]
let idCountry = {}
for (let index = 0; index < country.length; index++) {
    idCountry[index] = country[index];
}
export let Countries = idCountry;

export let States = {
    3901: ['Badakhshan', 1],
    3871: ['Badghis', 1],
    3875: ['Baghlan', 1],
    3884: ['Balkh', 1],
    3872: ['Bamyan', 1],
    3892: ['Daykundi', 1],
    3899: ['Farah', 1],
    3889: ['Faryab', 1],
    3870: ['Ghazni', 1],
    3888: ['Ghōr', 1],
    3873: ['Helmand', 1],
    3887: ['Herat', 1],
    3886: ['Jowzjan', 1],
    3902: ['Kabul', 1],
    3890: ['Kandahar', 1],
    3879: ['Kapisa', 1],
    3878: ['Khost', 1],
    3876: ['Kunar', 1],
    3900: ['Kunduz Province', 1],
    3891: ['Laghman', 1],
    3897: ['Logar', 1],
    3882: ['Nangarhar', 1],
    3896: ['Nimruz', 1],
    3880: ['Nuristan', 1],
    3894: ['Paktia', 1],
    3877: ['Paktika', 1],
    3881: ['Panjshir', 1],
    3895: ['Parwan', 1],
    3883: ['Samangan', 1],
    3885: ['Sar-e Pol', 1],
    3893: ['Takhar', 1],
    3898: ['Urozgan', 1],
    3874: ['Zabul', 1],
    603: ['Berat County', 3],
    629: ['Berat District', 3],
    607: ['Bulqizë District', 3],
    618: ['Delvinë District', 3],
    608: ['Devoll District', 3],
    610: ['Dibër County', 3],
    605: ['Dibër District', 3],
    632: ['Durrës County', 3],
    639: ['Durrës District', 3],
    598: ['Elbasan County', 3],
    631: ['Fier County', 3],
    627: ['Fier District', 3],
    604: ['Gjirokastër County', 3],
    621: ['Gjirokastër District', 3],
    617: ['Gramsh District', 3],
    600: ['Has District', 3],
    594: ['Kavajë District', 3],
    628: ['Kolonjë District', 3],
    630: ['Korçë County', 3],
    597: ['Korçë District', 3],
    614: ['Krujë District', 3],
    612: ['Kuçovë District', 3],
    601: ['Kukës County', 3],
    623: ['Kukës District', 3],
    622: ['Kurbin District', 3],
    609: ['Lezhë County', 3],
    595: ['Lezhë District', 3],
    596: ['Librazhd District', 3],
    599: ['Lushnjë District', 3],
    602: ['Malësi e Madhe District', 3],
    637: ['Mallakastër District', 3],
    635: ['Mat District', 3],
    638: ['Mirditë District', 3],
    619: ['Peqin District', 3],
    625: ['Përmet District', 3],
    606: ['Pogradec District', 3],
    620: ['Pukë District', 3],
    624: ['Sarandë District', 3],
    611: ['Shkodër County', 3],
    626: ['Shkodër District', 3],
    593: ['Skrapar District', 3],
    616: ['Tepelenë District', 3],
    615: ['Tirana County', 3],
    633: ['Tirana District', 3],
    636: ['Tropojë District', 3],
    634: ['Vlorë County', 3],
    613: ['Vlorë District', 3],
    1118: ['Adrar', 4],
    1119: ['Aïn Defla', 4],
    1122: ['Aïn Témouchent', 4],
    1144: ['Algiers', 4],
    1103: ['Annaba', 4],
    1142: ['Batna', 4],
    1108: ['Béchar', 4],
    1128: ['Béjaïa', 4],
    4909: ['Béni Abbès', 4],
    1114: ['Biskra', 4],
    1111: ['Blida', 4],
    4908: ['Bordj Baji Mokhtar', 4],
    1116: ['Bordj Bou Arréridj', 4],
    1104: ['Bouïra', 4],
    1125: ['Boumerdès', 4],
    1105: ['Chlef', 4],
    1121: ['Constantine', 4],
    4912: ['Djanet', 4],
    1098: ['Djelfa', 4],
    1129: ['El Bayadh', 4],
    4905: ['El M  ghair', 4],
    4906: ['El Menia', 4],
    1099: ['El Oued', 4],
    1100: ['El Tarf', 4],
    1127: ['Ghardaïa', 4],
    1137: ['Guelma', 4],
    1112: ['Illizi', 4],
    4914: ['In Guezzam', 4],
    4913: ['In Salah', 4],
    1113: ['Jijel', 4],
    1126: ['Khenchela', 4],
    1138: ['Laghouat', 4],
    1134: ['M  Sila', 4],
    1124: ['Mascara', 4],
    1109: ['Médéa', 4],
    1132: ['Mila', 4],
    1140: ['Mostaganem', 4],
    1102: ['Naama', 4],
    1101: ['Oran', 4],
    1139: ['Ouargla', 4],
    4907: ['Ouled Djellal', 4],
    1136: ['Oum El Bouaghi', 4],
    1130: ['Relizane', 4],
    1123: ['Saïda', 4],
    1141: ['Sétif', 4],
    4902: ['Sidi Bel Abbès', 4],
    1110: ['Skikda', 4],
    1143: ['Souk Ahras', 4],
    1135: ['Tamanghasset', 4],
    1117: ['Tébessa', 4],
    1106: ['Tiaret', 4],
    4910: ['Timimoun', 4],
    1120: ['Tindouf', 4],
    1115: ['Tipasa', 4],
    1133: ['Tissemsilt', 4],
    1131: ['Tizi Ouzou', 4],
    1107: ['Tlemcen', 4],
    4911: ['Touggourt', 4],
    488: ['Andorra la Vella', 6],
    489: ['Canillo', 6],
    487: ['Encamp', 6],
    492: ['Escaldes-Engordany', 6],
    493: ['La Massana', 6],
    491: ['Ordino', 6],
    490: ['Sant Julià de Lòria', 6],
    221: ['Bengo Province', 7],
    218: ['Benguela Province', 7],
    212: ['Bié Province', 7],
    228: ['Cabinda Province', 7],
    226: ['Cuando Cubango Province', 7],
    217: ['Cuanza Norte Province', 7],
    216: ['Cuanza Sul', 7],
    215: ['Cunene Province', 7],
    213: ['Huambo Province', 7],
    225: ['Huíla Province', 7],
    222: ['Luanda Province', 7],
    223: ['Lunda Norte Province', 7],
    220: ['Lunda Sul Province', 7],
    227: ['Malanje Province', 7],
    219: ['Moxico Province', 7],
    224: ['Uíge Province', 7],
    214: ['Zaire Province', 7],
    3708: ['Barbuda', 10],
    3703: ['Redonda', 10],
    3709: ['Saint George Parish', 10],
    3706: ['Saint John Parish', 10],
    3707: ['Saint Mary Parish', 10],
    3705: ['Saint Paul Parish', 10],
    3704: ['Saint Peter Parish', 10],
    3710: ['Saint Philip Parish', 10],
    4880: ['Autonomous City Of Buenos Aires', 11],
    3656: ['Buenos Aires Province', 11],
    3647: ['Catamarca Province', 11],
    3640: ['Chaco Province', 11],
    3651: ['Chubut Province', 11],
    3642: ['Córdoba Province', 11],
    3638: ['Corrientes', 11],
    3654: ['Entre Ríos Province', 11],
    3652: ['Formosa Province', 11],
    3645: ['Jujuy Province', 11],
    3655: ['La Pampa', 11],
    3653: ['La Rioja Province', 11],
    3646: ['Mendoza', 11],
    3644: ['Misiones Province', 11],
    3648: ['Neuquén Province', 11],
    3639: ['Río Negro Province', 11],
    3643: ['Salta Province', 11],
    3634: ['San Juan Province', 11],
    3636: ['San Luis Province', 11],
    3649: ['Santa Cruz Province', 11],
    3641: ['Santa Fe Province', 11],
    3635: ['Santiago del Estero Province', 11],
    3650: ['Tierra del Fuego Province', 11],
    3637: ['Tucumán Province', 11],
    2023: ['Aragatsotn Region', 12],
    2024: ['Ararat Province', 12],
    2026: ['Armavir Region', 12],
    2028: ['Gegharkunik Province', 12],
    2033: ['Kotayk Region', 12],
    2029: ['Lori Region', 12],
    2031: ['Shirak Region', 12],
    2027: ['Syunik Province', 12],
    2032: ['Tavush Region', 12],
    2025: ['Vayots Dzor Region', 12],
    2030: ['Yerevan', 12],
    3907: ['Australian Capital Territory', 14],
    3909: ['New South Wales', 14],
    3910: ['Northern Territory', 14],
    3905: ['Queensland', 14],
    3904: ['South Australia', 14],
    3908: ['Tasmania', 14],
    3903: ['Victoria', 14],
    3906: ['Western Australia', 14],
    2062: ['Burgenland', 15],
    2057: ['Carinthia', 15],
    2065: ['Lower Austria', 15],
    2061: ['Salzburg', 15],
    2059: ['Styria', 15],
    2064: ['Tyrol', 15],
    2058: ['Upper Austria', 15],
    2060: ['Vienna', 15],
    2063: ['Vorarlberg', 15],
    540: ['Absheron District', 16],
    559: ['Agdam District', 16],
    553: ['Agdash District', 16],
    577: ['Aghjabadi District', 16],
    543: ['Agstafa District', 16],
    547: ['Agsu District', 16],
    528: ['Astara District', 16],
    575: ['Babek District', 16],
    552: ['Baku', 16],
    560: ['Balakan District', 16],
    569: ['Barda District', 16],
    554: ['Beylagan District', 16],
    532: ['Bilasuvar District', 16],
    561: ['Dashkasan District', 16],
    527: ['Fizuli District', 16],
    585: ['Ganja', 16],
    589: ['Gədəbəy', 16],
    573: ['Gobustan District', 16],
    551: ['Goranboy District', 16],
    531: ['Goychay', 16],
    574: ['Goygol District', 16],
    571: ['Hajigabul District', 16],
    544: ['Imishli District', 16],
    564: ['Ismailli District', 16],
    570: ['Jabrayil District', 16],
    578: ['Jalilabad District', 16],
    572: ['Julfa District', 16],
    525: ['Kalbajar District', 16],
    567: ['Kangarli District', 16],
    590: ['Khachmaz District', 16],
    537: ['Khizi District', 16],
    524: ['Khojali District', 16],
    549: ['Kurdamir District', 16],
    541: ['Lachin District', 16],
    587: ['Lankaran', 16],
    558: ['Lankaran District', 16],
    546: ['Lerik District', 16],
    568: ['Martuni', 16],
    555: ['Masally District', 16],
    580: ['Mingachevir', 16],
    562: ['Nakhchivan Autonomous Republic', 16],
    530: ['Neftchala District', 16],
    556: ['Oghuz District', 16],
    534: ['Ordubad District', 16],
    542: ['Qabala District', 16],
    526: ['Qakh District', 16],
    521: ['Qazakh District', 16],
    563: ['Quba District', 16],
    548: ['Qubadli District', 16],
    588: ['Qusar District', 16],
    557: ['Saatly District', 16],
    565: ['Sabirabad District', 16],
    522: ['Sadarak District', 16],
    545: ['Salyan District', 16],
    536: ['Samukh District', 16],
    591: ['Shabran District', 16],
    579: ['Shahbuz District', 16],
    518: ['Shaki', 16],
    586: ['Shaki District', 16],
    529: ['Shamakhi District', 16],
    583: ['Shamkir District', 16],
    535: ['Sharur District', 16],
    520: ['Shirvan', 16],
    592: ['Shusha District', 16],
    584: ['Siazan District', 16],
    582: ['Sumqayit', 16],
    519: ['Tartar District', 16],
    533: ['Tovuz District', 16],
    539: ['Ujar District', 16],
    550: ['Yardymli District', 16],
    538: ['Yevlakh', 16],
    523: ['Yevlakh District', 16],
    581: ['Zangilan District', 16],
    566: ['Zaqatala District', 16],
    576: ['Zardab District', 16],
    3601: ['Acklins', 17],
    3628: ['Acklins and Crooked Islands', 17],
    3593: ['Berry Islands', 17],
    3629: ['Bimini', 17],
    3605: ['Black Point', 17],
    3611: ['Cat Island', 17],
    3603: ['Central Abaco', 17],
    3631: ['Central Andros', 17],
    3596: ['Central Eleuthera', 17],
    3621: ['Crooked Island', 17],
    3614: ['East Grand Bahama', 17],
    3612: ['Exuma', 17],
    3626: ['Freeport', 17],
    3619: ['Fresh Creek', 17],
    3597: ['Governor  s Harbour', 17],
    3632: ['Grand Cay', 17],
    3595: ['Green Turtle Cay', 17],
    3613: ['Harbour Island', 17],
    3598: ['High Rock', 17],
    3624: ['Hope Town', 17],
    3609: ['Inagua', 17],
    3618: ['Kemps Bay', 17],
    3610: ['Long Island', 17],
    3625: ['Mangrove Cay', 17],
    3604: ['Marsh Harbour', 17],
    3633: ['Mayaguana District', 17],
    4881: ['New Providence', 17],
    3594: ['Nichollstown and Berry Islands', 17],
    3616: ['North Abaco', 17],
    3617: ['North Andros', 17],
    3602: ['North Eleuthera', 17],
    3615: ['Ragged Island', 17],
    3623: ['Rock Sound', 17],
    3600: ['Rum Cay District', 17],
    3620: ['San Salvador and Rum Cay', 17],
    3627: ['San Salvador Island', 17],
    3606: ['Sandy Point', 17],
    3608: ['South Abaco', 17],
    3622: ['South Andros', 17],
    3607: ['South Eleuthera', 17],
    3630: ['Spanish Wells', 17],
    3599: ['West Grand Bahama', 17],
    1992: ['Capital Governorate', 18],
    1996: ['Central Governorate', 18],
    1995: ['Muharraq Governorate', 18],
    1994: ['Northern Governorate', 18],
    1993: ['Southern Governorate', 18],
    796: ['Bagerhat District', 19],
    802: ['Bahadia', 19],
    752: ['Bandarban District', 19],
    784: ['Barguna District', 19],
    818: ['Barisal District', 19],
    807: ['Barisal Division', 19],
    756: ['Bhola District', 19],
    797: ['Bogra District', 19],
    810: ['Brahmanbaria District', 19],
    768: ['Chandpur District', 19],
    761: ['Chapai Nawabganj District', 19],
    785: ['Chittagong District', 19],
    803: ['Chittagong Division', 19],
    788: ['Chuadanga District', 19],
    763: ['Comilla District', 19],
    751: ['Cox  s Bazar District', 19],
    771: ['Dhaka District', 19],
    760: ['Dhaka Division', 19],
    783: ['Dinajpur District', 19],
    762: ['Faridpur District', 19],
    816: ['Feni District', 19],
    795: ['Gaibandha District', 19],
    798: ['Gazipur District', 19],
    792: ['Gopalganj District', 19],
    805: ['Habiganj District', 19],
    808: ['Jamalpur District', 19],
    757: ['Jessore District', 19],
    778: ['Jhalokati District', 19],
    789: ['Jhenaidah District', 19],
    806: ['Joypurhat District', 19],
    786: ['Khagrachari District', 19],
    811: ['Khulna District', 19],
    775: ['Khulna Division', 19],
    779: ['Kishoreganj District', 19],
    793: ['Kurigram District', 19],
    774: ['Kushtia District', 19],
    819: ['Lakshmipur District', 19],
    780: ['Lalmonirhat District', 19],
    817: ['Madaripur District', 19],
    776: ['Meherpur District', 19],
    794: ['Moulvibazar District', 19],
    790: ['Munshiganj District', 19],
    766: ['Mymensingh District', 19],
    758: ['Mymensingh Division', 19],
    814: ['Naogaon District', 19],
    769: ['Narail District', 19],
    770: ['Narayanganj District', 19],
    787: ['Natore District', 19],
    764: ['Netrokona District', 19],
    772: ['Nilphamari District', 19],
    815: ['Noakhali District', 19],
    754: ['Pabna District', 19],
    800: ['Panchagarh District', 19],
    777: ['Patuakhali District', 19],
    791: ['Pirojpur District', 19],
    773: ['Rajbari District', 19],
    813: ['Rajshahi District', 19],
    753: ['Rajshahi Division', 19],
    809: ['Rangamati Hill District', 19],
    759: ['Rangpur District', 19],
    750: ['Rangpur Division', 19],
    799: ['Satkhira District', 19],
    801: ['Shariatpur District', 19],
    755: ['Sherpur District', 19],
    781: ['Sirajganj District', 19],
    812: ['Sunamganj District', 19],
    767: ['Sylhet District', 19],
    765: ['Sylhet Division', 19],
    782: ['Tangail District', 19],
    804: ['Thakurgaon District', 19],
    1228: ['Christ Church', 20],
    1229: ['Saint Andrew', 20],
    1226: ['Saint George', 20],
    1224: ['Saint James', 20],
    1227: ['Saint John', 20],
    1223: ['Saint Joseph', 20],
    1221: ['Saint Lucy', 20],
    1230: ['Saint Michael', 20],
    1222: ['Saint Peter', 20],
    1220: ['Saint Philip', 20],
    1225: ['Saint Thomas', 20],
    2959: ['Brest Region', 21],
    2955: ['Gomel Region', 21],
    2956: ['Grodno Region', 21],
    2958: ['Minsk', 21],
    2957: ['Minsk Region', 21],
    2954: ['Mogilev Region', 21],
    2960: ['Vitebsk Region', 21],
    1381: ['Antwerp', 22],
    1376: ['Brussels-Capital Region', 22],
    1377: ['East Flanders', 22],
    1373: ['Flanders', 22],
    1374: ['Flemish Brabant', 22],
    1375: ['Hainaut', 22],
    1384: ['Liège', 22],
    1372: ['Limburg', 22],
    1379: ['Luxembourg', 22],
    1378: ['Namur', 22],
    1380: ['Wallonia', 22],
    1382: ['Walloon Brabant', 22],
    1383: ['West Flanders', 22],
    264: ['Belize District', 23],
    269: ['Cayo District', 23],
    266: ['Corozal District', 23],
    268: ['Orange Walk District', 23],
    265: ['Stann Creek District', 23],
    267: ['Toledo District', 23],
    3077: ['Alibori Department', 24],
    3076: ['Atakora Department', 24],
    3079: ['Atlantique Department', 24],
    3078: ['Borgou Department', 24],
    3070: ['Collines Department', 24],
    3072: ['Donga Department', 24],
    3071: ['Kouffo Department', 24],
    3081: ['Littoral Department', 24],
    3075: ['Mono Department', 24],
    3080: ['Ouémé Department', 24],
    3074: ['Plateau Department', 24],
    3073: ['Zou Department', 24],
    4860: ['Devonshire Parish', 25],
    4862: ['Hamilton Municipality', 25],
    4861: ['Hamilton Parish', 25],
    4863: ['Paget Parish', 25],
    4864: ['Pembroke Parish', 25],
    4865: ['Saint George  s Municipality', 25],
    4866: ['Saint George  s Parish', 25],
    4867: ['Sandys Parish', 25],
    4868: ['Smith  s Parish ', 25],
    4869: ['Southampton Parish', 25],
    4870: ['Warwick Parish', 25],
    240: ['Bumthang District', 26],
    239: ['Chukha District', 26],
    238: ['Dagana District', 26],
    229: ['Gasa District', 26],
    232: ['Haa District', 26],
    234: ['Lhuntse District', 26],
    242: ['Mongar District', 26],
    237: ['Paro District', 26],
    244: ['Pemagatshel District', 26],
    235: ['Punakha District', 26],
    243: ['Samdrup Jongkhar District', 26],
    246: ['Samtse District', 26],
    247: ['Sarpang District', 26],
    241: ['Thimphu District', 26],
    236: ['Trashigang District', 26],
    245: ['Trongsa District', 26],
    230: ['Tsirang District', 26],
    231: ['Wangdue Phodrang District', 26],
    233: ['Zhemgang District', 26],
    3375: ['Beni Department', 27],
    3382: ['Chuquisaca Department', 27],
    3381: ['Cochabamba Department', 27],
    3380: ['La Paz Department', 27],
    3376: ['Oruro Department', 27],
    3379: ['Pando Department', 27],
    3383: ['Potosí Department', 27],
    3377: ['Santa Cruz Department', 27],
    3378: ['Tarija Department', 27],
    472: ['Bosnian Podrinje Canton', 28],
    460: ['Brčko District', 28],
    471: ['Canton 10', 28],
    462: ['Central Bosnia Canton', 28],
    467: ['Federation of Bosnia and Herzegovina', 28],
    463: ['Herzegovina-Neretva Canton', 28],
    464: ['Posavina Canton', 28],
    470: ['Republika Srpska', 28],
    466: ['Sarajevo Canton', 28],
    461: ['Tuzla Canton', 28],
    465: ['Una-Sana Canton', 28],
    469: ['West Herzegovina Canton', 28],
    468: ['Zenica-Doboj Canton', 28],
    3067: ['Central District', 29],
    3061: ['Ghanzi District', 29],
    3066: ['Kgalagadi District', 29],
    3062: ['Kgatleng District', 29],
    3069: ['Kweneng District', 29],
    3060: ['Ngamiland', 29],
    3068: ['North-East District', 29],
    3065: ['North-West District', 29],
    3064: ['South-East District', 29],
    3063: ['Southern District', 29],
    2012: ['Acre', 31],
    2007: ['Alagoas', 31],
    1999: ['Amapá', 31],
    2004: ['Amazonas', 31],
    2002: ['Bahia', 31],
    2016: ['Ceará', 31],
    2018: ['Espírito Santo', 31],
    2017: ['Federal District', 31],
    2000: ['Goiás', 31],
    2015: ['Maranhão', 31],
    2011: ['Mato Grosso', 31],
    2010: ['Mato Grosso do Sul', 31],
    1998: ['Minas Gerais', 31],
    2009: ['Pará', 31],
    2005: ['Paraíba', 31],
    2022: ['Paraná', 31],
    2006: ['Pernambuco', 31],
    2008: ['Piauí', 31],
    1997: ['Rio de Janeiro', 31],
    2019: ['Rio Grande do Norte', 31],
    2001: ['Rio Grande do Sul', 31],
    2013: ['Rondônia', 31],
    4858: ['Roraima', 31],
    2014: ['Santa Catarina', 31],
    2021: ['São Paulo', 31],
    2003: ['Sergipe', 31],
    2020: ['Tocantins', 31],
    1217: ['Belait District', 33],
    1216: ['Brunei-Muara District', 33],
    1218: ['Temburong District', 33],
    1219: ['Tutong District', 33],
    4699: ['Blagoevgrad Province', 34],
    4715: ['Burgas Province', 34],
    4718: ['Dobrich Province', 34],
    4693: ['Gabrovo Province', 34],
    4704: ['Haskovo Province', 34],
    4702: ['Kardzhali Province', 34],
    4703: ['Kyustendil Province', 34],
    4710: ['Lovech Province', 34],
    4696: ['Montana Province', 34],
    4712: ['Pazardzhik Province', 34],
    4695: ['Pernik Province', 34],
    4706: ['Pleven Province', 34],
    4701: ['Plovdiv Province', 34],
    4698: ['Razgrad Province', 34],
    4713: ['Ruse Province', 34],
    4882: ['Shumen', 34],
    4708: ['Silistra Province', 34],
    4700: ['Sliven Province', 34],
    4694: ['Smolyan Province', 34],
    4705: ['Sofia City Province', 34],
    4719: ['Sofia Province', 34],
    4707: ['Stara Zagora Province', 34],
    4714: ['Targovishte Province', 34],
    4717: ['Varna Province', 34],
    4709: ['Veliko Tarnovo Province', 34],
    4697: ['Vidin Province', 34],
    4711: ['Vratsa Province', 34],
    4716: ['Yambol Province', 34],
    3160: ['Balé Province', 35],
    3155: ['Bam Province', 35],
    3120: ['Banwa Province', 35],
    3152: ['Bazèga Province', 35],
    3138: ['Boucle du Mouhoun Region', 35],
    3121: ['Bougouriba Province', 35],
    3131: ['Boulgou', 35],
    3153: ['Cascades Region', 35],
    3136: ['Centre', 35],
    3162: ['Centre-Est Region', 35],
    3127: ['Centre-Nord Region', 35],
    3115: ['Centre-Ouest Region', 35],
    3149: ['Centre-Sud Region', 35],
    3167: ['Comoé Province', 35],
    3158: ['Est Region', 35],
    3148: ['Ganzourgou Province', 35],
    3122: ['Gnagna Province', 35],
    3143: ['Gourma Province', 35],
    3165: ['Hauts-Bassins Region', 35],
    3129: ['Houet Province', 35],
    3135: ['Ioba Province', 35],
    3168: ['Kadiogo Province', 35],
    3112: ['Kénédougou Province', 35],
    3132: ['Komondjari Province', 35],
    3157: ['Kompienga Province', 35],
    3146: ['Kossi Province', 35],
    3133: ['Koulpélogo Province', 35],
    3161: ['Kouritenga Province', 35],
    3147: ['Kourwéogo Province', 35],
    3159: ['Léraba Province', 35],
    3151: ['Loroum Province', 35],
    3123: ['Mouhoun', 35],
    3116: ['Nahouri Province', 35],
    3113: ['Namentenga Province', 35],
    3142: ['Nayala Province', 35],
    3164: ['Nord Region  Burkina Faso', 35],
    3156: ['Noumbiel Province', 35],
    3141: ['Oubritenga Province', 35],
    3144: ['Oudalan Province', 35],
    3117: ['Passoré Province', 35],
    3125: ['Plateau-Central Region', 35],
    3163: ['Poni Province', 35],
    3114: ['Sahel Region', 35],
    3154: ['Sanguié Province', 35],
    3126: ['Sanmatenga Province', 35],
    3139: ['Séno Province', 35],
    3119: ['Sissili Province', 35],
    3166: ['Soum Province', 35],
    3137: ['Sourou Province', 35],
    3140: ['Sud-Ouest Region', 35],
    3128: ['Tapoa Province', 35],
    3134: ['Tuy Province', 35],
    3124: ['Yagha Province', 35],
    3150: ['Yatenga Province', 35],
    3145: ['Ziro Province', 35],
    3130: ['Zondoma Province', 35],
    3118: ['Zoundwéogo Province', 35],
    3196: ['Bubanza Province', 36],
    3198: ['Bujumbura Mairie Province', 36],
    3200: ['Bujumbura Rural Province', 36],
    3202: ['Bururi Province', 36],
    3201: ['Cankuzo Province', 36],
    3190: ['Cibitoke Province', 36],
    3197: ['Gitega Province', 36],
    3194: ['Karuzi Province', 36],
    3192: ['Kayanza Province', 36],
    3195: ['Kirundo Province', 36],
    3188: ['Makamba Province', 36],
    3193: ['Muramvya Province', 36],
    3186: ['Muyinga Province', 36],
    3187: ['Mwaro Province', 36],
    3199: ['Ngozi Province', 36],
    3185: ['Rumonge Province', 36],
    3189: ['Rutana Province', 36],
    3191: ['Ruyigi Province', 36],
    3984: ['Banteay Meanchey Province', 37],
    3976: ['Battambang Province', 37],
    3991: ['Kampong Cham Province', 37],
    3979: ['Kampong Chhnang Province', 37],
    3988: ['Kampong Speu Province', 37],
    3981: ['Kampot Province', 37],
    3983: ['Kandal Province', 37],
    3978: ['Kep Province', 37],
    3982: ['Koh Kong Province', 37],
    3986: ['Kratié Province', 37],
    3985: ['Mondulkiri Province', 37],
    3987: ['Oddar Meanchey Province', 37],
    3980: ['Pailin Province', 37],
    3994: ['Phnom Penh', 37],
    3973: ['Preah Vihear Province', 37],
    3974: ['Prey Veng Province', 37],
    3977: ['Pursat Province', 37],
    3990: ['Ratanakiri Province', 37],
    3992: ['Siem Reap Province', 37],
    3989: ['Sihanoukville Province', 37],
    3993: ['Stung Treng Province', 37],
    3972: ['Svay Rieng Province', 37],
    3975: ['Takéo Province', 37],
    2663: ['Adamawa', 38],
    2660: ['Centre', 38],
    2661: ['East', 38],
    2656: ['Far North', 38],
    2662: ['Littoral', 38],
    2665: ['North', 38],
    2657: ['Northwest', 38],
    2659: ['South', 38],
    2658: ['Southwest', 38],
    2664: ['West', 38],
    872: ['Alberta', 39],
    875: ['British Columbia', 39],
    867: ['Manitoba', 39],
    868: ['New Brunswick', 39],
    877: ['Newfoundland and Labrador', 39],
    878: ['Northwest Territories', 39],
    874: ['Nova Scotia', 39],
    876: ['Nunavut', 39],
    866: ['Ontario', 39],
    871: ['Prince Edward Island', 39],
    873: ['Quebec', 39],
    870: ['Saskatchewan', 39],
    869: ['Yukon', 39],
    2994: ['Barlavento Islands', 40],
    2999: ['Boa Vista', 40],
    2996: ['Brava', 40],
    2991: ['Maio Municipality', 40],
    2987: ['Mosteiros', 40],
    2997: ['Paul', 40],
    2989: ['Porto Novo', 40],
    2988: ['Praia', 40],
    2982: ['Ribeira Brava Municipality', 40],
    3002: ['Ribeira Grande', 40],
    2984: ['Ribeira Grande de Santiago', 40],
    2998: ['Sal', 40],
    2985: ['Santa Catarina', 40],
    2995: ['Santa Catarina do Fogo', 40],
    3004: ['Santa Cruz', 40],
    2986: ['São Domingos', 40],
    3000: ['São Filipe', 40],
    2993: ['São Lourenço dos Órgãos', 40],
    2990: ['São Miguel', 40],
    3001: ['São Vicente', 40],
    2992: ['Sotavento Islands', 40],
    2983: ['Tarrafal', 40],
    3003: ['Tarrafal de São Nicolau', 40],
    1259: ['Bamingui-Bangoran Prefecture', 42],
    1262: ['Bangui', 42],
    1264: ['Basse-Kotto Prefecture', 42],
    1258: ['Haut-Mbomou Prefecture', 42],
    1268: ['Haute-Kotto Prefecture', 42],
    1263: ['Kémo Prefecture', 42],
    1256: ['Lobaye Prefecture', 42],
    1257: ['Mambéré-Kadéï', 42],
    1266: ['Mbomou Prefecture', 42],
    1253: ['Nana-Grébizi Economic Prefecture', 42],
    1260: ['Nana-Mambéré Prefecture', 42],
    1255: ['Ombella-M  Poko Prefecture', 42],
    1265: ['Ouaka Prefecture', 42],
    1254: ['Ouham Prefecture', 42],
    1267: ['Ouham-Pendé Prefecture', 42],
    1252: ['Sangha-Mbaéré', 42],
    1261: ['Vakaga Prefecture', 42],
    3583: ['Bahr el Gazel', 43],
    3590: ['Batha Region', 43],
    3574: ['Borkou', 43],
    3578: ['Ennedi Region', 43],
    3575: ['Ennedi-Est', 43],
    3584: ['Ennedi-Ouest', 43],
    3576: ['Guéra Region', 43],
    3573: ['Hadjer-Lamis', 43],
    3588: ['Kanem Region', 43],
    3577: ['Lac Region', 43],
    3585: ['Logone Occidental Region', 43],
    3591: ['Logone Oriental Region', 43],
    3589: ['Mandoul Region', 43],
    3580: ['Mayo-Kebbi Est Region', 43],
    3571: ['Mayo-Kebbi Ouest Region', 43],
    3570: ['Moyen-Chari Region', 43],
    3586: ['N  Djamena', 43],
    3582: ['Ouaddaï Region', 43],
    3592: ['Salamat Region', 43],
    3572: ['Sila Region', 43],
    3579: ['Tandjilé Region', 43],
    3587: ['Tibesti Region', 43],
    3581: ['Wadi Fira Region', 43],
    2828: ['Aisén del General Carlos Ibañez del Campo', 44],
    2832: ['Antofagasta', 44],
    2829: ['Arica y Parinacota', 44],
    2823: ['Atacama', 44],
    2827: ['Biobío', 44],
    2825: ['Coquimbo', 44],
    2826: ['La Araucanía', 44],
    2838: ['Libertador General Bernardo O  Higgins', 44],
    2835: ['Los Lagos', 44],
    2834: ['Los Ríos', 44],
    2836: ['Magallanes y de la Antártica Chilena', 44],
    2833: ['Maule', 44],
    2831: ['Ñuble', 44],
    2824: ['Región Metropolitana de Santiago', 44],
    2837: ['Tarapacá', 44],
    2830: ['Valparaíso', 44],
    2251: ['Anhui', 45],
    2257: ['Beijing', 45],
    2271: ['Chongqing', 45],
    2248: ['Fujian', 45],
    2275: ['Gansu', 45],
    2279: ['Guangdong', 45],
    2278: ['Guangxi Zhuang Autonomous Region', 45],
    2261: ['Guizhou', 45],
    2273: ['Hainan', 45],
    2280: ['Hebei', 45],
    2265: ['Heilongjiang', 45],
    2259: ['Henan', 45],
    2267: ['Hong Kong', 45],
    2274: ['Hubei', 45],
    2258: ['Hunan', 45],
    2269: ['Inner Mongolia', 45],
    2250: ['Jiangsu', 45],
    2256: ['Jiangxi', 45],
    2253: ['Jilin', 45],
    2276: ['Keelung', 45],
    2268: ['Liaoning', 45],
    2266: ['Macau', 45],
    2262: ['Ningxia Hui Autonomous Region', 45],
    2270: ['Qinghai', 45],
    2272: ['Shaanxi', 45],
    2252: ['Shandong', 45],
    2249: ['Shanghai', 45],
    2254: ['Shanxi', 45],
    2277: ['Sichuan', 45],
    2255: ['Taiwan Province  People  s Republic of China', 45],
    2264: ['Tibet Autonomous Region', 45],
    2263: ['Xinjiang', 45],
    2260: ['Yunnan', 45],
    2247: ['Zhejiang', 45],
    2895: ['Amazonas', 48],
    2890: ['Antioquia', 48],
    2881: ['Arauca', 48],
    2880: ['Atlántico', 48],
    4921: ['Bogotá', 48],
    2893: ['Bolívar', 48],
    2903: ['Boyacá', 48],
    2887: ['Caldas', 48],
    2891: ['Caquetá', 48],
    2892: ['Casanare', 48],
    2884: ['Cauca', 48],
    2899: ['Cesar', 48],
    2876: ['Chocó', 48],
    2898: ['Córdoba', 48],
    2875: ['Cundinamarca', 48],
    2882: ['Guainía', 48],
    2888: ['Guaviare', 48],
    4871: ['Huila', 48],
    2889: ['La Guajira', 48],
    2886: ['Magdalena', 48],
    2878: ['Meta', 48],
    2897: ['Nariño', 48],
    2877: ['Norte de Santander', 48],
    2896: ['Putumayo', 48],
    2874: ['Quindío', 48],
    2879: ['Risaralda', 48],
    2900: ['San Andrés  Providencia y Santa Catalina', 48],
    2901: ['Santander', 48],
    2902: ['Sucre', 48],
    2883: ['Tolima', 48],
    2904: ['Valle del Cauca', 48],
    2885: ['Vaupés', 48],
    2894: ['Vichada', 48],
    2821: ['Anjouan', 49],
    2822: ['Grande Comore', 49],
    2820: ['Mohéli', 49],
    2866: ['Bouenza Department', 50],
    2870: ['Brazzaville', 50],
    2864: ['Cuvette Department', 50],
    2869: ['Cuvette-Ouest Department', 50],
    2867: ['Kouilou Department', 50],
    2868: ['Lékoumou Department', 50],
    2865: ['Likouala Department', 50],
    2872: ['Niari Department', 50],
    2862: ['Plateaux Department', 50],
    2863: ['Pointe-Noire', 50],
    2873: ['Pool Department', 50],
    2871: ['Sangha Department', 50],
    2754: ['Bandundu Province', 51],
    2746: ['Bas-Congo province', 51],
    2753: ['Bas-Uele', 51],
    2744: ['Équateur', 51],
    2750: ['Haut-Katanga Province', 51],
    2758: ['Haut-Lomami District', 51],
    2734: ['Haut-Uele', 51],
    2751: ['Ituri Interim Administration', 51],
    2757: ['Kasaï District', 51],
    2737: ['Kasaï-Occidental', 51],
    2735: ['Kasaï-Oriental', 51],
    2742: ['Katanga Province', 51],
    2741: ['Kinshasa', 51],
    2740: ['Kwango District', 51],
    2759: ['Kwilu District', 51],
    2747: ['Lomami Province', 51],
    2755: ['Mai-Ndombe Province', 51],
    2745: ['Maniema', 51],
    2752: ['Mongala District', 51],
    2739: ['Nord-Ubangi District', 51],
    2749: ['North Kivu', 51],
    2736: ['Orientale Province', 51],
    2743: ['Sankuru District', 51],
    2738: ['South Kivu', 51],
    2748: ['Sud-Ubangi', 51],
    2733: ['Tanganyika Province', 51],
    2756: ['Tshopo District', 51],
    2732: ['Tshuapa District', 51],
    1215: ['Alajuela Province', 53],
    1209: ['Guanacaste Province', 53],
    1212: ['Heredia Province', 53],
    1213: ['Limón Province', 53],
    1211: ['Provincia de Cartago', 53],
    1210: ['Puntarenas Province', 53],
    1214: ['San José Province', 53],
    2634: ['Abidjan', 54],
    2626: ['Agnéby', 54],
    2636: ['Bafing Region', 54],
    2643: ['Bas-Sassandra District', 54],
    2635: ['Bas-Sassandra Region', 54],
    2654: ['Comoé District', 54],
    2644: ['Denguélé District', 54],
    2642: ['Denguélé Region', 54],
    2645: ['Dix-Huit Montagnes', 54],
    2633: ['Fromager', 54],
    2651: ['Gôh-Djiboua District', 54],
    2638: ['Haut-Sassandra', 54],
    2632: ['Lacs District', 54],
    2640: ['Lacs Region', 54],
    2627: ['Lagunes District', 54],
    2639: ['Lagunes region', 54],
    2631: ['Marahoué Region', 54],
    2629: ['Montagnes District', 54],
    2646: ['Moyen-Cavally', 54],
    2630: ['Moyen-Comoé', 54],
    2655: ['N  zi-Comoé', 54],
    2648: ['Sassandra-Marahoué District', 54],
    2625: ['Savanes Region', 54],
    2628: ['Sud-Bandama', 54],
    2652: ['Sud-Comoé', 54],
    2637: ['Vallée du Bandama District', 54],
    2647: ['Vallée du Bandama Region', 54],
    2650: ['Woroba District', 54],
    2649: ['Worodougou', 54],
    2653: ['Yamoussoukro', 54],
    2641: ['Zanzan Region', 54],
    734: ['Bjelovar-Bilogora County', 55],
    737: ['Brod-Posavina County', 55],
    728: ['Dubrovnik-Neretva County', 55],
    743: ['Istria County', 55],
    742: ['Koprivnica-Križevci County', 55],
    729: ['Krapina-Zagorje County', 55],
    731: ['Lika-Senj County', 55],
    726: ['Međimurje County', 55],
    740: ['Osijek-Baranja County', 55],
    724: ['Požega-Slavonia County', 55],
    735: ['Primorje-Gorski Kotar County', 55],
    730: ['Šibenik-Knin County', 55],
    733: ['Sisak-Moslavina County', 55],
    725: ['Split-Dalmatia County', 55],
    739: ['Varaždin County', 55],
    732: ['Virovitica-Podravina County', 55],
    741: ['Vukovar-Syrmia County', 55],
    727: ['Zadar County', 55],
    738: ['Zagreb', 55],
    736: ['Zagreb County', 55],
    283: ['Artemisa Province', 56],
    286: ['Camagüey Province', 56],
    282: ['Ciego de Ávila Province', 56],
    287: ['Cienfuegos Province', 56],
    275: ['Granma Province', 56],
    285: ['Guantánamo Province', 56],
    272: ['Havana Province', 56],
    279: ['Holguín Province', 56],
    278: ['Isla de la Juventud', 56],
    281: ['Las Tunas Province', 56],
    284: ['Matanzas Province', 56],
    276: ['Mayabeque Province', 56],
    277: ['Pinar del Río Province', 56],
    274: ['Sancti Spíritus Province', 56],
    273: ['Santiago de Cuba Province', 56],
    280: ['Villa Clara Province', 56],
    749: ['Famagusta District', 57],
    744: ['Kyrenia District', 57],
    747: ['Larnaca District', 57],
    748: ['Limassol District', 57],
    745: ['Nicosia District', 57],
    746: ['Paphos District', 57],
    4627: ['Benešov', 58],
    4620: ['Beroun', 58],
    4615: ['Blansko', 58],
    4542: ['Břeclav', 58],
    4568: ['Brno-město', 58],
    4545: ['Brno-venkov', 58],
    4644: ['Bruntál', 58],
    4633: ['Česká Lípa', 58],
    4556: ['České Budějovice', 58],
    4543: ['Český Krumlov', 58],
    4573: ['Cheb', 58],
    4553: ['Chomutov', 58],
    4634: ['Chrudim', 58],
    4609: ['Děčín', 58],
    4641: ['Domažlice', 58],
    4559: ['Frýdek-Místek', 58],
    4611: ['Havlíčkův Brod', 58],
    4561: ['Hodonín', 58],
    4580: ['Hradec Králové', 58],
    4612: ['Jablonec nad Nisou', 58],
    4625: ['Jeseník', 58],
    4640: ['Jičín', 58],
    4613: ['Jihlava', 58],
    4639: ['Jihočeský kraj', 58],
    4602: ['Jihomoravský kraj', 58],
    4624: ['Jindřichův Hradec', 58],
    4581: ['Karlovarský kraj', 58],
    4604: ['Karlovy Vary', 58],
    4586: ['Karviná', 58],
    4631: ['Kladno', 58],
    4591: ['Klatovy', 58],
    4618: ['Kolín', 58],
    4575: ['Kraj Vysočina', 58],
    4614: ['Královéhradecký kraj', 58],
    4593: ['Kroměříž', 58],
    4923: ['Kutná Hora', 58],
    4590: ['Liberec', 58],
    4601: ['Liberecký kraj', 58],
    4605: ['Litoměřice', 58],
    4617: ['Louny', 58],
    4638: ['Mělník', 58],
    4643: ['Mladá Boleslav', 58],
    4600: ['Moravskoslezský kraj', 58],
    4629: ['Most', 58],
    4550: ['Náchod', 58],
    4548: ['Nový Jičín', 58],
    4582: ['Nymburk', 58],
    4574: ['Olomouc', 58],
    4589: ['Olomoucký kraj', 58],
    4623: ['Opava', 58],
    4584: ['Ostrava-město', 58],
    4547: ['Pardubice', 58],
    4588: ['Pardubický kraj', 58],
    4645: ['Pelhřimov', 58],
    4560: ['Písek', 58],
    4608: ['Plzeň-jih', 58],
    4544: ['Plzeň-město', 58],
    4564: ['Plzeň-sever', 58],
    4607: ['Plzeňský kraj', 58],
    4578: ['Prachatice', 58],
    4606: ['Praha-východ', 58],
    4619: ['Praha-západ', 58],
    4598: ['Praha  Hlavní město', 58],
    4626: ['Přerov', 58],
    4546: ['Příbram', 58],
    4551: ['Prostějov', 58],
    4558: ['Rakovník', 58],
    4583: ['Rokycany', 58],
    4636: ['Rychnov nad Kněžnou', 58],
    4596: ['Semily', 58],
    4595: ['Sokolov', 58],
    4628: ['Strakonice', 58],
    4554: ['Středočeský kraj', 58],
    4642: ['Šumperk', 58],
    4571: ['Svitavy', 58],
    4565: ['Tábor', 58],
    4646: ['Tachov', 58],
    4621: ['Teplice', 58],
    4597: ['Třebíč', 58],
    4579: ['Trutnov', 58],
    4592: ['Uherské Hradiště', 58],
    4576: ['Ústecký kraj', 58],
    4599: ['Ústí nad Labem', 58],
    4647: ['Ústí nad Orlicí', 58],
    4572: ['Vsetín', 58],
    4622: ['Vyškov', 58],
    4648: ['Žďár nad Sázavou', 58],
    4563: ['Zlín', 58],
    4552: ['Zlínský kraj', 58],
    4630: ['Znojmo', 58],
    1530: ['Capital Region of Denmark', 59],
    1531: ['Central Denmark Region', 59],
    1532: ['North Denmark Region', 59],
    1529: ['Region of Southern Denmark', 59],
    1528: ['Region Zealand', 59],
    2933: ['Ali Sabieh Region', 60],
    2932: ['Arta Region', 60],
    2930: ['Dikhil Region', 60],
    2929: ['Djibouti', 60],
    2928: ['Obock Region', 60],
    2931: ['Tadjourah Region', 60],
    4082: ['Saint Andrew Parish', 61],
    4078: ['Saint David Parish', 61],
    4079: ['Saint George Parish', 61],
    4076: ['Saint John Parish', 61],
    4085: ['Saint Joseph Parish', 61],
    4083: ['Saint Luke Parish', 61],
    4077: ['Saint Mark Parish', 61],
    4080: ['Saint Patrick Parish', 61],
    4084: ['Saint Paul Parish', 61],
    4081: ['Saint Peter Parish', 61],
    4114: ['Azua Province', 62],
    4105: ['Baoruco Province', 62],
    4090: ['Barahona Province', 62],
    4107: ['Dajabón Province', 62],
    4095: ['Distrito Nacional', 62],
    4113: ['Duarte Province', 62],
    4086: ['El Seibo Province', 62],
    4102: ['Espaillat Province', 62],
    4106: ['Hato Mayor Province', 62],
    4089: ['Hermanas Mirabal Province', 62],
    4097: ['Independencia', 62],
    4109: ['La Altagracia Province', 62],
    4087: ['La Romana Province', 62],
    4116: ['La Vega Province', 62],
    4094: ['María Trinidad Sánchez Province', 62],
    4099: ['Monseñor Nouel Province', 62],
    4115: ['Monte Cristi Province', 62],
    4111: ['Monte Plata Province', 62],
    4101: ['Pedernales Province', 62],
    4096: ['Peravia Province', 62],
    4092: ['Puerto Plata Province', 62],
    4103: ['Samaná Province', 62],
    4091: ['San Cristóbal Province', 62],
    4112: ['San José de Ocoa Province', 62],
    4098: ['San Juan Province', 62],
    4110: ['San Pedro de Macorís', 62],
    4088: ['Sánchez Ramírez Province', 62],
    4108: ['Santiago Province', 62],
    4100: ['Santiago Rodríguez Province', 62],
    4093: ['Santo Domingo Province', 62],
    4104: ['Valverde Province', 62],
    4520: ['Aileu municipality', 63],
    4518: ['Ainaro Municipality', 63],
    4521: ['Baucau Municipality', 63],
    4525: ['Bobonaro Municipality', 63],
    4522: ['Cova Lima Municipality', 63],
    4524: ['Dili municipality', 63],
    4516: ['Ermera District', 63],
    4523: ['Lautém Municipality', 63],
    4515: ['Liquiçá Municipality', 63],
    4517: ['Manatuto District', 63],
    4519: ['Manufahi Municipality', 63],
    4514: ['Viqueque Municipality', 63],
    2923: ['Azuay Province', 64],
    2920: ['Bolívar Province', 64],
    2917: ['Cañar Province', 64],
    2915: ['Carchi Province', 64],
    2925: ['Chimborazo Province', 64],
    2921: ['Cotopaxi Province', 64],
    2924: ['El Oro Province', 64],
    2922: ['Esmeraldas', 64],
    2905: ['Galápagos Province', 64],
    2914: ['Guayas Province', 64],
    2911: ['Imbabura Province', 64],
    2910: ['Los Ríos Province', 64],
    2913: ['Manabí Province', 64],
    2918: ['Morona-Santiago Province', 64],
    2916: ['Napo Province', 64],
    2926: ['Orellana Province', 64],
    2907: ['Pastaza Province', 64],
    2927: ['Pichincha Province', 64],
    2912: ['Santa Elena Province', 64],
    2919: ['Santo Domingo de los Tsáchilas Province', 64],
    2906: ['Sucumbíos Province', 64],
    2908: ['Tungurahua Province', 64],
    2909: ['Zamora-Chinchipe Province', 64],
    3235: ['Alexandria Governorate', 65],
    3225: ['Aswan Governorate', 65],
    3236: ['Asyut Governorate', 65],
    3241: ['Beheira Governorate', 65],
    3230: ['Beni Suef Governorate', 65],
    3223: ['Cairo Governorate', 65],
    3245: ['Dakahlia Governorate', 65],
    3224: ['Damietta Governorate', 65],
    3238: ['Faiyum Governorate', 65],
    3234: ['Gharbia Governorate', 65],
    3239: ['Giza Governorate', 65],
    3244: ['Ismailia Governorate', 65],
    3222: ['Kafr el-Sheikh Governorate', 65],
    3242: ['Luxor Governorate', 65],
    3231: ['Matrouh Governorate', 65],
    3243: ['Minya Governorate', 65],
    3228: ['Monufia Governorate', 65],
    3246: ['New Valley Governorate', 65],
    3227: ['North Sinai Governorate', 65],
    3229: ['Port Said Governorate', 65],
    3232: ['Qalyubia Governorate', 65],
    3247: ['Qena Governorate', 65],
    3240: ['Red Sea Governorate', 65],
    3226: ['Sohag Governorate', 65],
    3237: ['South Sinai Governorate', 65],
    3233: ['Suez Governorate', 65],
    4139: ['Ahuachapán Department', 66],
    4132: ['Cabañas Department', 66],
    4131: ['Chalatenango Department', 66],
    4137: ['Cuscatlán Department', 66],
    4134: ['La Libertad Department', 66],
    4136: ['La Paz Department', 66],
    4138: ['La Unión Department', 66],
    4130: ['Morazán Department', 66],
    4135: ['San Miguel Department', 66],
    4133: ['San Salvador Department', 66],
    4127: ['San Vicente Department', 66],
    4128: ['Santa Ana Department', 66],
    4140: ['Sonsonate Department', 66],
    4129: ['Usulután Department', 66],
    3444: ['Annobón Province', 67],
    3446: ['Bioko Norte Province', 67],
    3443: ['Bioko Sur Province', 67],
    3445: ['Centro Sur Province', 67],
    3442: ['Insular Region', 67],
    3439: ['Kié-Ntem Province', 67],
    3441: ['Litoral Province', 67],
    3438: ['Río Muni', 67],
    3440: ['Wele-Nzas Province', 67],
    3425: ['Anseba Region', 68],
    3427: ['Debub Region', 68],
    3428: ['Gash-Barka Region', 68],
    3426: ['Maekel Region', 68],
    3424: ['Northern Red Sea Region', 68],
    3429: ['Southern Red Sea Region', 68],
    3567: ['Harju County', 69],
    3555: ['Hiiu County', 69],
    3569: ['Ida-Viru County', 69],
    3566: ['Järva County', 69],
    3565: ['Jõgeva County', 69],
    3568: ['Lääne County', 69],
    3564: ['Lääne-Viru County', 69],
    3562: ['Pärnu County', 69],
    3563: ['Põlva County', 69],
    3559: ['Rapla County', 69],
    3561: ['Saare County', 69],
    3557: ['Tartu County', 69],
    3558: ['Valga County', 69],
    3556: ['Viljandi County', 69],
    3560: ['Võru County', 69],
    11: ['Addis Ababa', 70],
    6: ['Afar Region', 70],
    3: ['Amhara Region', 70],
    9: ['Benishangul-Gumuz Region', 70],
    8: ['Dire Dawa', 70],
    10: ['Gambela Region', 70],
    7: ['Harari Region', 70],
    5: ['Oromia Region', 70],
    2: ['Somali Region', 70],
    1: ['Southern Nations  Nationalities  and Peoples   Region', 70],
    4: ['Tigray Region', 70],
    1917: ['Ba', 73],
    1930: ['Bua', 73],
    1924: ['Cakaudrove', 73],
    1929: ['Central Division', 73],
    1932: ['Eastern Division', 73],
    1934: ['Kadavu', 73],
    1933: ['Lau', 73],
    1916: ['Lomaiviti', 73],
    1922: ['Macuata', 73],
    1919: ['Nadroga-Navosa', 73],
    1927: ['Naitasiri', 73],
    1928: ['Namosi', 73],
    1921: ['Northern Division', 73],
    1926: ['Ra', 73],
    1920: ['Rewa', 73],
    1931: ['Rotuma', 73],
    1925: ['Serua', 73],
    1918: ['Tailevu', 73],
    1923: ['Western Division', 73],
    1509: ['Åland Islands', 74],
    1511: ['Central Finland', 74],
    1494: ['Central Ostrobothnia', 74],
    1492: ['Eastern Finland Province', 74],
    1507: ['Finland Proper', 74],
    1496: ['Kainuu', 74],
    1512: ['Kymenlaakso', 74],
    1500: ['Lapland', 74],
    1504: ['North Karelia', 74],
    1505: ['Northern Ostrobothnia', 74],
    1503: ['Northern Savonia', 74],
    1508: ['Ostrobothnia', 74],
    1499: ['Oulu Province', 74],
    1502: ['Päijänne Tavastia', 74],
    1506: ['Pirkanmaa', 74],
    1501: ['Satakunta', 74],
    1497: ['South Karelia', 74],
    1498: ['Southern Ostrobothnia', 74],
    1495: ['Southern Savonia', 74],
    1493: ['Tavastia Proper', 74],
    1510: ['Uusimaa', 74],
    4800: ['Alo', 75],
    4811: ['Alsace', 75],
    4808: ['Aquitaine', 75],
    4789: ['Auvergne', 75],
    4798: ['Auvergne-Rhône-Alpes', 75],
    4825: ['Bourgogne-Franche-Comté', 75],
    4807: ['Brittany', 75],
    4788: ['Burgundy', 75],
    4818: ['Centre-Val de Loire', 75],
    4791: ['Champagne-Ardenne', 75],
    4806: ['Corsica', 75],
    4805: ['Franche-Comté', 75],
    4822: ['French Guiana', 75],
    4824: ['French Polynesia', 75],
    4820: ['Grand Est', 75],
    4829: ['Guadeloupe', 75],
    4828: ['Hauts-de-France', 75],
    4796: ['Île-de-France', 75],
    4803: ['Languedoc-Roussillon', 75],
    4792: ['Limousin', 75],
    4801: ['Lorraine', 75],
    4814: ['Lower Normandy', 75],
    4827: ['Martinique', 75],
    4797: ['Mayotte', 75],
    4793: ['Nord-Pas-de-Calais', 75],
    4804: ['Normandy', 75],
    4795: ['Nouvelle-Aquitaine', 75],
    4799: ['Occitania', 75],
    4816: ['Paris', 75],
    4802: ['Pays de la Loire', 75],
    4790: ['Picardy', 75],
    4815: ['Poitou-Charentes', 75],
    4812: ['Provence-Alpes-Côte d  Azur', 75],
    4823: ['Réunion', 75],
    4813: ['Rhône-Alpes', 75],
    4794: ['Saint Barthélemy', 75],
    4809: ['Saint Martin', 75],
    4821: ['Saint Pierre and Miquelon', 75],
    4819: ['Sigave', 75],
    4826: ['Upper Normandy', 75],
    4817: ['Uvea', 75],
    4810: ['Wallis and Futuna', 75],
    2727: ['Estuaire Province', 79],
    2726: ['Haut-Ogooué Province', 79],
    2730: ['Moyen-Ogooué Province', 79],
    2731: ['Ngounié Province', 79],
    2725: ['Nyanga Province', 79],
    2724: ['Ogooué-Ivindo Province', 79],
    2729: ['Ogooué-Lolo Province', 79],
    2728: ['Ogooué-Maritime Province', 79],
    2723: ['Woleu-Ntem Province', 79],
    2666: ['Banjul', 80],
    2669: ['Central River Division', 80],
    2670: ['Lower River Division', 80],
    2671: ['North Bank Division', 80],
    2668: ['Upper River Division', 80],
    2667: ['West Coast Division', 80],
    900: ['Adjara', 81],
    901: ['Autonomous Republic of Abkhazia', 81],
    907: ['Guria', 81],
    905: ['Imereti', 81],
    910: ['Kakheti', 81],
    897: ['Khelvachauri Municipality', 81],
    904: ['Kvemo Kartli', 81],
    902: ['Mtskheta-Mtianeti', 81],
    909: ['Racha-Lechkhumi and Kvemo Svaneti', 81],
    908: ['Samegrelo-Zemo Svaneti', 81],
    906: ['Samtskhe-Javakheti', 81],
    898: ['Senaki Municipality', 81],
    903: ['Shida Kartli', 81],
    899: ['Tbilisi', 81],
    3006: ['Baden-Württemberg', 82],
    3009: ['Bavaria', 82],
    3010: ['Berlin', 82],
    3013: ['Brandenburg', 82],
    3014: ['Bremen', 82],
    3016: ['Hamburg', 82],
    3018: ['Hesse', 82],
    3008: ['Lower Saxony', 82],
    3007: ['Mecklenburg-Vorpommern', 82],
    3017: ['North Rhine-Westphalia', 82],
    3019: ['Rhineland-Palatinate', 82],
    3020: ['Saarland', 82],
    3021: ['Saxony', 82],
    3011: ['Saxony-Anhalt', 82],
    3005: ['Schleswig-Holstein', 82],
    3015: ['Thuringia', 82],
    48: ['Ashanti Region', 83],
    53: ['Brong-Ahafo Region', 83],
    52: ['Central Region', 83],
    50: ['Eastern Region', 83],
    54: ['Greater Accra Region', 83],
    51: ['Northern Region', 83],
    55: ['Upper East Region', 83],
    57: ['Upper West Region', 83],
    56: ['Volta Region', 83],
    49: ['Western Region', 83],
    2116: ['Achaea Regional Unit', 85],
    2123: ['Aetolia-Acarnania Regional Unit', 85],
    2098: ['Arcadia Prefecture', 85],
    2105: ['Argolis Regional Unit', 85],
    2122: ['Attica Region', 85],
    2126: ['Boeotia Regional Unit', 85],
    2128: ['Central Greece Region', 85],
    2125: ['Central Macedonia', 85],
    2115: ['Chania Regional Unit', 85],
    2124: ['Corfu Prefecture', 85],
    2129: ['Corinthia Regional Unit', 85],
    2109: ['Crete Region', 85],
    2130: ['Drama Regional Unit', 85],
    2120: ['East Attica Regional Unit', 85],
    2117: ['East Macedonia and Thrace', 85],
    2110: ['Epirus Region', 85],
    2101: ['Euboea', 85],
    2102: ['Grevena Prefecture', 85],
    2099: ['Imathia Regional Unit', 85],
    2113: ['Ioannina Regional Unit', 85],
    2131: ['Ionian Islands Region', 85],
    2095: ['Karditsa Regional Unit', 85],
    2100: ['Kastoria Regional Unit', 85],
    2127: ['Kefalonia Prefecture', 85],
    2111: ['Kilkis Regional Unit', 85],
    2112: ['Kozani Prefecture', 85],
    2106: ['Laconia', 85],
    2132: ['Larissa Prefecture', 85],
    2104: ['Lefkada Regional Unit', 85],
    2107: ['Pella Regional Unit', 85],
    2119: ['Peloponnese Region', 85],
    2114: ['Phthiotis Prefecture', 85],
    2103: ['Preveza Prefecture', 85],
    2121: ['Serres Prefecture', 85],
    2118: ['South Aegean', 85],
    2097: ['Thessaloniki Regional Unit', 85],
    2096: ['West Greece Region', 85],
    2108: ['West Macedonia Region', 85],
    3867: ['Carriacou and Petite Martinique', 87],
    3865: ['Saint Andrew Parish', 87],
    3869: ['Saint David Parish', 87],
    3864: ['Saint George Parish', 87],
    3868: ['Saint John Parish', 87],
    3866: ['Saint Mark Parish', 87],
    3863: ['Saint Patrick Parish', 87],
    3671: ['Alta Verapaz Department', 90],
    3674: ['Baja Verapaz Department', 90],
    3675: ['Chimaltenango Department', 90],
    3666: ['Chiquimula Department', 90],
    3662: ['El Progreso Department', 90],
    3677: ['Escuintla Department', 90],
    3672: ['Guatemala Department', 90],
    3670: ['Huehuetenango Department', 90],
    3659: ['Izabal Department', 90],
    3658: ['Jalapa Department', 90],
    3673: ['Jutiapa Department', 90],
    3669: ['Petén Department', 90],
    3668: ['Quetzaltenango Department', 90],
    3657: ['Quiché Department', 90],
    3664: ['Retalhuleu Department', 90],
    3676: ['Sacatepéquez Department', 90],
    3667: ['San Marcos Department', 90],
    3665: ['Santa Rosa Department', 90],
    3661: ['Sololá Department', 90],
    3660: ['Suchitepéquez Department', 90],
    3663: ['Totonicapán Department', 90],
    2672: ['Beyla Prefecture', 92],
    2699: ['Boffa Prefecture', 92],
    2709: ['Boké Prefecture', 92],
    2676: ['Boké Region', 92],
    2686: ['Conakry', 92],
    2705: ['Coyah Prefecture', 92],
    2679: ['Dabola Prefecture', 92],
    2706: ['Dalaba Prefecture', 92],
    2688: ['Dinguiraye Prefecture', 92],
    2681: ['Dubréka Prefecture', 92],
    2682: ['Faranah Prefecture', 92],
    2683: ['Forécariah Prefecture', 92],
    2675: ['Fria Prefecture', 92],
    2685: ['Gaoual Prefecture', 92],
    2711: ['Guéckédou Prefecture', 92],
    2704: ['Kankan Prefecture', 92],
    2697: ['Kankan Region', 92],
    2710: ['Kérouané Prefecture', 92],
    2693: ['Kindia Prefecture', 92],
    2701: ['Kindia Region', 92],
    2691: ['Kissidougou Prefecture', 92],
    2692: ['Koubia Prefecture', 92],
    2703: ['Koundara Prefecture', 92],
    2695: ['Kouroussa Prefecture', 92],
    2680: ['Labé Prefecture', 92],
    2677: ['Labé Region', 92],
    2690: ['Lélouma Prefecture', 92],
    2708: ['Lola Prefecture', 92],
    2702: ['Macenta Prefecture', 92],
    2700: ['Mali Prefecture', 92],
    2689: ['Mamou Prefecture', 92],
    2698: ['Mamou Region', 92],
    2673: ['Mandiana Prefecture', 92],
    2678: ['Nzérékoré Prefecture', 92],
    2684: ['Nzérékoré Region', 92],
    2694: ['Pita Prefecture', 92],
    2707: ['Siguiri Prefecture', 92],
    2687: ['Télimélé Prefecture', 92],
    2696: ['Tougué Prefecture', 92],
    2674: ['Yomou Prefecture', 92],
    2720: ['Bafatá', 93],
    2714: ['Biombo Region', 93],
    2722: ['Bolama Region', 93],
    2713: ['Cacheu Region', 93],
    2719: ['Gabú Region', 93],
    2721: ['Leste Province', 93],
    2717: ['Norte Province', 93],
    2718: ['Oio Region', 93],
    2715: ['Quinara Region', 93],
    2716: ['Sul Province', 93],
    2712: ['Tombali Region', 93],
    2764: ['Barima-Waini', 94],
    2760: ['Cuyuni-Mazaruni', 94],
    2767: ['Demerara-Mahaica', 94],
    2766: ['East Berbice-Corentyne', 94],
    2768: ['Essequibo Islands-West Demerara', 94],
    2762: ['Mahaica-Berbice', 94],
    2765: ['Pomeroon-Supenaam', 94],
    2761: ['Potaro-Siparuni', 94],
    2763: ['Upper Demerara-Berbice', 94],
    2769: ['Upper Takutu-Upper Essequibo', 94],
    4123: ['Artibonite', 95],
    4125: ['Centre', 95],
    4119: ['Grand  Anse', 95],
    4118: ['Nippes', 95],
    4117: ['Nord', 95],
    4121: ['Nord-Est', 95],
    4126: ['Nord-Ouest', 95],
    4120: ['Ouest', 95],
    4122: ['Sud', 95],
    4124: ['Sud-Est', 95],
    4047: ['Atlántida Department', 97],
    4045: ['Bay Islands Department', 97],
    4041: ['Choluteca Department', 97],
    4051: ['Colón Department', 97],
    4042: ['Comayagua Department', 97],
    4049: ['Copán Department', 97],
    4046: ['Cortés Department', 97],
    4043: ['El Paraíso Department', 97],
    4052: ['Francisco Morazán Department', 97],
    4048: ['Gracias a Dios Department', 97],
    4044: ['Intibucá Department', 97],
    4058: ['La Paz Department', 97],
    4054: ['Lempira Department', 97],
    4056: ['Ocotepeque Department', 97],
    4050: ['Olancho Department', 97],
    4053: ['Santa Bárbara Department', 97],
    4055: ['Valle Department', 97],
    4057: ['Yoro Department', 97],
    4889: ['Central and Western District', 98],
    4891: ['Eastern', 98],
    4888: ['Islands District', 98],
    4895: ['Kowloon City', 98],
    4898: ['Kwai Tsing', 98],
    4897: ['Kwun Tong', 98],
    4900: ['North', 98],
    4887: ['Sai Kung District', 98],
    4901: ['Sha Tin', 98],
    4894: ['Sham Shui Po', 98],
    4892: ['Southern', 98],
    4885: ['Tai Po District', 98],
    4884: ['Tsuen Wan District', 98],
    4899: ['Tuen Mun', 98],
    4890: ['Wan Chai', 98],
    4896: ['Wong Tai Sin', 98],
    4893: ['Yau Tsim Mong', 98],
    4883: ['Yuen Long District', 98],
    1048: ['Bács-Kiskun County', 99],
    1055: ['Baranya County', 99],
    1060: ['Békés County', 99],
    1036: ['Békéscsaba', 99],
    1058: ['Borsod-Abaúj-Zemplén County', 99],
    1064: ['Budapest', 99],
    1031: ['Csongrád County', 99],
    1032: ['Debrecen', 99],
    1049: ['Dunaújváros', 99],
    1037: ['Eger', 99],
    1028: ['Érd', 99],
    1044: ['Fejér County', 99],
    1041: ['Győr', 99],
    1042: ['Győr-Moson-Sopron County', 99],
    1063: ['Hajdú-Bihar County', 99],
    1040: ['Heves County', 99],
    1027: ['Hódmezővásárhely', 99],
    1043: ['Jász-Nagykun-Szolnok County', 99],
    1067: ['Kaposvár', 99],
    1056: ['Kecskemét', 99],
    1065: ['Miskolc', 99],
    1030: ['Nagykanizsa', 99],
    1051: ['Nógrád County', 99],
    1034: ['Nyíregyháza', 99],
    1053: ['Pécs', 99],
    1059: ['Pest County', 99],
    1068: ['Salgótarján', 99],
    1035: ['Somogy County', 99],
    1057: ['Sopron', 99],
    1045: ['Szabolcs-Szatmár-Bereg County', 99],
    1029: ['Szeged', 99],
    1033: ['Székesfehérvár', 99],
    1061: ['Szekszárd', 99],
    1047: ['Szolnok', 99],
    1052: ['Szombathely', 99],
    1066: ['Tatabánya', 99],
    1038: ['Tolna County', 99],
    1039: ['Vas County', 99],
    1062: ['Veszprém', 99],
    1054: ['Veszprém County', 99],
    1046: ['Zala County', 99],
    1050: ['Zalaegerszeg', 99],
    3431: ['Capital Region', 100],
    3433: ['Eastern Region', 100],
    3437: ['Northeastern Region', 100],
    3435: ['Northwestern Region', 100],
    3430: ['Southern Peninsula Region', 100],
    3434: ['Southern Region', 100],
    3436: ['Western Region', 100],
    3432: ['Westfjords', 100],
    4023: ['Andaman and Nicobar Islands', 101],
    4017: ['Andhra Pradesh', 101],
    4024: ['Arunachal Pradesh', 101],
    4027: ['Assam', 101],
    4037: ['Bihar', 101],
    4031: ['Chandigarh', 101],
    4040: ['Chhattisgarh', 101],
    4033: ['Dadra and Nagar Haveli and Daman and Diu', 101],
    4021: ['Delhi', 101],
    4009: ['Goa', 101],
    4030: ['Gujarat', 101],
    4007: ['Haryana', 101],
    4020: ['Himachal Pradesh', 101],
    4029: ['Jammu and Kashmir', 101],
    4025: ['Jharkhand', 101],
    4026: ['Karnataka', 101],
    4028: ['Kerala', 101],
    4852: ['Ladakh', 101],
    4019: ['Lakshadweep', 101],
    4039: ['Madhya Pradesh', 101],
    4008: ['Maharashtra', 101],
    4010: ['Manipur', 101],
    4006: ['Meghalaya', 101],
    4036: ['Mizoram', 101],
    4018: ['Nagaland', 101],
    4013: ['Odisha', 101],
    4011: ['Puducherry', 101],
    4015: ['Punjab', 101],
    4014: ['Rajasthan', 101],
    4034: ['Sikkim', 101],
    4035: ['Tamil Nadu', 101],
    4012: ['Telangana', 101],
    4038: ['Tripura', 101],
    4022: ['Uttar Pradesh', 101],
    4016: ['Uttarakhand', 101],
    4853: ['West Bengal', 101],
    1822: ['Aceh', 102],
    1826: ['Bali', 102],
    1820: ['Bangka Belitung Islands', 102],
    1810: ['Banten', 102],
    1793: ['Bengkulu', 102],
    1802: ['Central Java', 102],
    1794: ['Central Kalimantan', 102],
    1813: ['Central Sulawesi', 102],
    1827: ['East Java', 102],
    1804: ['East Kalimantan', 102],
    1818: ['East Nusa Tenggara', 102],
    1812: ['Gorontalo', 102],
    1805: ['Jakarta', 102],
    1815: ['Jambi', 102],
    1806: ['Kalimantan', 102],
    1811: ['Lampung', 102],
    1821: ['Lesser Sunda Islands', 102],
    1800: ['Maluku', 102],
    1823: ['Maluku Islands', 102],
    1824: ['North Kalimantan', 102],
    1801: ['North Maluku', 102],
    1808: ['North Sulawesi', 102],
    1792: ['North Sumatra', 102],
    1798: ['Papua', 102],
    1809: ['Riau', 102],
    1807: ['Riau Islands', 102],
    1819: ['South Kalimantan', 102],
    1795: ['South Sulawesi', 102],
    1816: ['South Sumatra', 102],
    1796: ['Southeast Sulawesi', 102],
    1829: ['Special Region of Yogyakarta', 102],
    1803: ['Sulawesi', 102],
    1797: ['Sumatra', 102],
    1825: ['West Java', 102],
    1814: ['West Nusa Tenggara', 102],
    1799: ['West Papua', 102],
    1817: ['West Sulawesi', 102],
    1828: ['West Sumatra', 102],
    3929: ['Alborz Province', 103],
    3934: ['Ardabil Province', 103],
    3932: ['Bushehr Province', 103],
    3921: ['Chaharmahal and Bakhtiari Province', 103],
    3944: ['East Azerbaijan Province', 103],
    3939: ['Fars Province', 103],
    3920: ['Gilan Province', 103],
    3933: ['Golestan Province', 103],
    4920: ['Hamadan Province', 103],
    3937: ['Hormozgan Province', 103],
    3918: ['Ilam Province', 103],
    3923: ['Isfahan Province', 103],
    3943: ['Kerman Province', 103],
    3919: ['Kermanshah Province', 103],
    3917: ['Khuzestan Province', 103],
    3926: ['Kohgiluyeh and Boyer-Ahmad Province', 103],
    3935: ['Kurdistan Province', 103],
    3928: ['Lorestan Province', 103],
    3916: ['Markazi Province', 103],
    3938: ['Mazandaran Province', 103],
    3942: ['North Khorasan Province', 103],
    3941: ['Qazvin Province', 103],
    3922: ['Qom Province', 103],
    3927: ['Razavi Khorasan Province', 103],
    3940: ['Semnan Province', 103],
    3931: ['Sistan and Baluchestan', 103],
    3930: ['South Khorasan Province', 103],
    3945: ['Tehran Province', 103],
    3924: ['West Azarbaijan Province', 103],
    3936: ['Yazd Province', 103],
    3925: ['Zanjan Province', 103],
    3964: ['Al Anbar Governorate', 104],
    3958: ['Al Muthanna Governorate', 104],
    3956: ['Al-Qādisiyyah Governorate', 104],
    3955: ['Babylon Governorate', 104],
    3959: ['Baghdad Governorate', 104],
    3960: ['Basra Governorate', 104],
    3954: ['Dhi Qar Governorate', 104],
    3965: ['Diyala Governorate', 104],
    3967: ['Dohuk Governorate', 104],
    3968: ['Erbil Governorate', 104],
    3957: ['Karbala Governorate', 104],
    3971: ['Kirkuk Governorate', 104],
    3966: ['Maysan Governorate', 104],
    3962: ['Najaf Governorate', 104],
    3963: ['Nineveh Governorate', 104],
    3961: ['Saladin Governorate', 104],
    3969: ['Sulaymaniyah Governorate', 104],
    3970: ['Wasit Governorate', 104],
    1087: ['Connacht', 105],
    1095: ['County Carlow', 105],
    1088: ['County Cavan', 105],
    1091: ['County Clare', 105],
    1074: ['County Cork', 105],
    1071: ['County Donegal', 105],
    1072: ['County Dublin', 105],
    1079: ['County Galway', 105],
    1077: ['County Kerry', 105],
    1082: ['County Kildare', 105],
    1090: ['County Kilkenny', 105],
    1096: ['County Laois', 105],
    1094: ['County Limerick', 105],
    1076: ['County Longford', 105],
    1083: ['County Louth', 105],
    1084: ['County Mayo', 105],
    1092: ['County Meath', 105],
    1075: ['County Monaghan', 105],
    1078: ['County Offaly', 105],
    1081: ['County Roscommon', 105],
    1070: ['County Sligo', 105],
    1069: ['County Tipperary', 105],
    1089: ['County Waterford', 105],
    1097: ['County Westmeath', 105],
    1093: ['County Wexford', 105],
    1085: ['County Wicklow', 105],
    1073: ['Leinster', 105],
    1080: ['Munster', 105],
    1086: ['Ulster', 105],
    1367: ['Central District', 106],
    1369: ['Haifa District', 106],
    1370: ['Jerusalem District', 106],
    1366: ['Northern District', 106],
    1368: ['Southern District', 106],
    1371: ['Tel Aviv District', 106],
    1679: ['Abruzzo', 107],
    1716: ['Aosta Valley', 107],
    1688: ['Apulia', 107],
    1706: ['Basilicata', 107],
    1701: ['Benevento Province', 107],
    1703: ['Calabria', 107],
    1669: ['Campania', 107],
    1773: ['Emilia-Romagna', 107],
    1756: ['Friuli–Venezia Giulia', 107],
    1678: ['Lazio', 107],
    1727: ['Libero consorzio comunale di Agrigento', 107],
    1718: ['Libero consorzio comunale di Caltanissetta', 107],
    1723: ['Libero consorzio comunale di Enna', 107],
    1729: ['Libero consorzio comunale di Ragusa', 107],
    1667: ['Libero consorzio comunale di Siracusa', 107],
    1733: ['Libero consorzio comunale di Trapani', 107],
    1768: ['Liguria', 107],
    1705: ['Lombardy', 107],
    1670: ['Marche', 107],
    1772: ['Metropolitan City of Bari', 107],
    1684: ['Metropolitan City of Bologna', 107],
    1682: ['Metropolitan City of Cagliari', 107],
    1766: ['Metropolitan City of Catania', 107],
    1680: ['Metropolitan City of Florence', 107],
    1699: ['Metropolitan City of Genoa', 107],
    1770: ['Metropolitan City of Messina', 107],
    1698: ['Metropolitan City of Milan', 107],
    1724: ['Metropolitan City of Naples', 107],
    1668: ['Metropolitan City of Palermo', 107],
    1671: ['Metropolitan City of Reggio Calabria', 107],
    1711: ['Metropolitan City of Rome', 107],
    1710: ['Metropolitan City of Turin', 107],
    1673: ['Metropolitan City of Venice', 107],
    1695: ['Molise', 107],
    1693: ['Pesaro and Urbino Province', 107],
    1702: ['Piedmont', 107],
    1783: ['Province of Alessandria', 107],
    1672: ['Province of Ancona', 107],
    1681: ['Province of Ascoli Piceno', 107],
    1780: ['Province of Asti', 107],
    1692: ['Province of Avellino', 107],
    1686: ['Province of Barletta-Andria-Trani', 107],
    1689: ['Province of Belluno', 107],
    1704: ['Province of Bergamo', 107],
    1778: ['Province of Biella', 107],
    1717: ['Province of Brescia', 107],
    1714: ['Province of Brindisi', 107],
    1721: ['Province of Campobasso', 107],
    1730: ['Province of Carbonia-Iglesias', 107],
    1731: ['Province of Caserta', 107],
    1728: ['Province of Catanzaro', 107],
    1739: ['Province of Chieti', 107],
    1740: ['Province of Como', 107],
    1742: ['Province of Cosenza', 107],
    1751: ['Province of Cremona', 107],
    1754: ['Province of Crotone', 107],
    1775: ['Province of Cuneo', 107],
    1744: ['Province of Fermo', 107],
    1746: ['Province of Ferrara', 107],
    1771: ['Province of Foggia', 107],
    1779: ['Province of Forlì-Cesena', 107],
    1776: ['Province of Frosinone', 107],
    1777: ['Province of Gorizia', 107],
    1787: ['Province of Grosseto', 107],
    1788: ['Province of Imperia', 107],
    1789: ['Province of Isernia', 107],
    1781: ['Province of L  Aquila', 107],
    1791: ['Province of La Spezia', 107],
    1674: ['Province of Latina', 107],
    1675: ['Province of Lecce', 107],
    1677: ['Province of Lecco', 107],
    1745: ['Province of Livorno', 107],
    1747: ['Province of Lodi', 107],
    1749: ['Province of Lucca', 107],
    1750: ['Province of Macerata', 107],
    1758: ['Province of Mantua', 107],
    1759: ['Province of Massa and Carrara', 107],
    1760: ['Province of Matera', 107],
    1761: ['Province of Medio Campidano', 107],
    1757: ['Province of Modena', 107],
    1769: ['Province of Monza and Brianza', 107],
    1774: ['Province of Novara', 107],
    1790: ['Province of Nuoro', 107],
    1782: ['Province of Ogliastra', 107],
    1784: ['Province of Olbia-Tempio', 107],
    1786: ['Province of Oristano', 107],
    1665: ['Province of Padua', 107],
    1666: ['Province of Parma', 107],
    1676: ['Province of Pavia', 107],
    1691: ['Province of Perugia', 107],
    1694: ['Province of Pescara', 107],
    1696: ['Province of Piacenza', 107],
    1685: ['Province of Pisa', 107],
    1687: ['Province of Pistoia', 107],
    1690: ['Province of Pordenone', 107],
    1697: ['Province of Potenza', 107],
    1700: ['Province of Prato', 107],
    1707: ['Province of Ravenna', 107],
    1708: ['Province of Reggio Emilia', 107],
    1712: ['Province of Rieti', 107],
    1713: ['Province of Rimini', 107],
    1719: ['Province of Rovigo', 107],
    1720: ['Province of Salerno', 107],
    1722: ['Province of Sassari', 107],
    1732: ['Province of Savona', 107],
    1734: ['Province of Siena', 107],
    1741: ['Province of Sondrio', 107],
    1743: ['Province of Taranto', 107],
    1752: ['Province of Teramo', 107],
    1755: ['Province of Terni', 107],
    1762: ['Province of Treviso', 107],
    1763: ['Province of Trieste', 107],
    1764: ['Province of Udine', 107],
    1765: ['Province of Varese', 107],
    1726: ['Province of Verbano-Cusio-Ossola', 107],
    1785: ['Province of Vercelli', 107],
    1736: ['Province of Verona', 107],
    1737: ['Province of Vibo Valentia', 107],
    1738: ['Province of Vicenza', 107],
    1735: ['Province of Viterbo', 107],
    1715: ['Sardinia', 107],
    1709: ['Sicily', 107],
    1767: ['South Tyrol', 107],
    1748: ['Trentino', 107],
    1725: ['Trentino-South Tyrol', 107],
    1664: ['Tuscany', 107],
    1683: ['Umbria', 107],
    1753: ['Veneto', 107],
    3753: ['Clarendon Parish', 108],
    3749: ['Hanover Parish', 108],
    3748: ['Kingston Parish', 108],
    3754: ['Manchester Parish', 108],
    3752: ['Portland Parish', 108],
    3751: ['Saint Andrew', 108],
    3744: ['Saint Ann Parish', 108],
    3746: ['Saint Catherine Parish', 108],
    3743: ['Saint Elizabeth Parish', 108],
    3745: ['Saint James Parish', 108],
    3747: ['Saint Mary Parish', 108],
    3750: ['Saint Thomas Parish', 108],
    3755: ['Trelawny Parish', 108],
    3742: ['Westmoreland Parish', 108],
    827: ['Aichi Prefecture', 109],
    829: ['Akita Prefecture', 109],
    839: ['Aomori Prefecture', 109],
    821: ['Chiba Prefecture', 109],
    865: ['Ehime Prefecture', 109],
    848: ['Fukui Prefecture', 109],
    861: ['Fukuoka Prefecture', 109],
    847: ['Fukushima Prefecture', 109],
    858: ['Gifu Prefecture', 109],
    862: ['Gunma Prefecture', 109],
    828: ['Hiroshima Prefecture', 109],
    832: ['Hokkaidō Prefecture', 109],
    831: ['Hyōgo Prefecture', 109],
    851: ['Ibaraki Prefecture', 109],
    830: ['Ishikawa Prefecture', 109],
    856: ['Iwate Prefecture', 109],
    864: ['Kagawa Prefecture', 109],
    840: ['Kagoshima Prefecture', 109],
    842: ['Kanagawa Prefecture', 109],
    4924: ['Kōchi Prefecture', 109],
    846: ['Kumamoto Prefecture', 109],
    834: ['Kyōto Prefecture', 109],
    833: ['Mie Prefecture', 109],
    857: ['Miyagi Prefecture', 109],
    855: ['Miyazaki Prefecture', 109],
    843: ['Nagano Prefecture', 109],
    849: ['Nagasaki Prefecture', 109],
    824: ['Nara Prefecture', 109],
    841: ['Niigata Prefecture', 109],
    822: ['Ōita Prefecture', 109],
    820: ['Okayama Prefecture', 109],
    853: ['Okinawa Prefecture', 109],
    859: ['Ōsaka Prefecture', 109],
    863: ['Saga Prefecture', 109],
    860: ['Saitama Prefecture', 109],
    845: ['Shiga Prefecture', 109],
    826: ['Shimane Prefecture', 109],
    825: ['Shizuoka Prefecture', 109],
    854: ['Tochigi Prefecture', 109],
    836: ['Tokushima Prefecture', 109],
    823: ['Tokyo', 109],
    850: ['Tottori Prefecture', 109],
    838: ['Toyama Prefecture', 109],
    844: ['Wakayama Prefecture', 109],
    837: ['Yamagata Prefecture', 109],
    835: ['Yamaguchi Prefecture', 109],
    852: ['Yamanashi Prefecture', 109],
    963: ['Ajloun Governorate', 111],
    965: ['Amman Governorate', 111],
    959: ['Aqaba Governorate', 111],
    961: ['Balqa Governorate', 111],
    960: ['Irbid Governorate', 111],
    966: ['Jerash Governorate', 111],
    956: ['Karak Governorate', 111],
    964: ['Ma  an Governorate', 111],
    958: ['Madaba Governorate', 111],
    962: ['Mafraq Governorate', 111],
    957: ['Tafilah Governorate', 111],
    967: ['Zarqa Governorate', 111],
    145: ['Akmola Region', 112],
    151: ['Aktobe Region', 112],
    152: ['Almaty', 112],
    143: ['Almaty Region', 112],
    153: ['Atyrau Region', 112],
    155: ['Baikonur', 112],
    154: ['East Kazakhstan Region', 112],
    147: ['Jambyl Region', 112],
    150: ['Karaganda Region', 112],
    157: ['Kostanay Region', 112],
    142: ['Kyzylorda Region', 112],
    141: ['Mangystau Region', 112],
    144: ['North Kazakhstan Region', 112],
    156: ['Nur-Sultan', 112],
    146: ['Pavlodar Region', 112],
    149: ['Turkestan Region', 112],
    148: ['West Kazakhstan Province', 112],
    181: ['Baringo County', 113],
    210: ['Bomet County', 113],
    168: ['Bungoma County', 113],
    161: ['Busia County', 113],
    160: ['Central Province', 113],
    172: ['Coast Province', 113],
    189: ['Eastern Province', 113],
    201: ['Elgeyo-Marakwet County', 113],
    163: ['Embu County', 113],
    196: ['Garissa County', 113],
    195: ['Homa Bay County', 113],
    170: ['Isiolo County', 113],
    197: ['Kajiado County', 113],
    158: ['Kakamega County', 113],
    193: ['Kericho County', 113],
    199: ['Kiambu County', 113],
    174: ['Kilifi County', 113],
    167: ['Kirinyaga County', 113],
    159: ['Kisii County', 113],
    171: ['Kisumu County', 113],
    211: ['Kitui County', 113],
    173: ['Kwale County', 113],
    164: ['Laikipia County', 113],
    166: ['Lamu County', 113],
    184: ['Machakos County', 113],
    188: ['Makueni County', 113],
    187: ['Mandera County', 113],
    194: ['Marsabit County', 113],
    198: ['Meru County', 113],
    190: ['Migori County', 113],
    200: ['Mombasa County', 113],
    178: ['Muranga County', 113],
    191: ['Nairobi', 113],
    203: ['Nakuru District', 113],
    165: ['Nandi District', 113],
    175: ['Narok County', 113],
    162: ['North Eastern Province', 113],
    209: ['Nyamira District', 113],
    192: ['Nyandarua County', 113],
    204: ['Nyanza Province', 113],
    180: ['Nyeri County', 113],
    179: ['Rift Valley Province', 113],
    207: ['Samburu County', 113],
    186: ['Siaya County', 113],
    176: ['Taita–Taveta County', 113],
    205: ['Tana River County', 113],
    185: ['Tharaka Nithi County', 113],
    183: ['Trans-Nzoia District', 113],
    206: ['Turkana County', 113],
    169: ['Uasin Gishu District', 113],
    202: ['Vihiga District', 113],
    182: ['Wajir County', 113],
    208: ['West Pokot County', 113],
    177: ['Western Province', 113],
    1831: ['Gilbert Islands', 114],
    1832: ['Line Islands', 114],
    1830: ['Phoenix Islands', 114],
    3998: ['Chagang Province', 115],
    3999: ['Kangwon Province', 115],
    3995: ['North Hamgyong Province', 115],
    4004: ['North Hwanghae Province', 115],
    4002: ['North Pyongan Province', 115],
    4005: ['Pyongyang', 115],
    4001: ['Rason', 115],
    3996: ['Ryanggang Province', 115],
    4000: ['South Hamgyong Province', 115],
    4003: ['South Hwanghae Province', 115],
    3997: ['South Pyongan Province', 115],
    3860: ['Busan', 116],
    3846: ['Daegu', 116],
    3850: ['Daejeon', 116],
    3862: ['Gangwon Province', 116],
    3858: ['Gwangju', 116],
    3847: ['Gyeonggi Province', 116],
    3848: ['Incheon', 116],
    3853: ['Jeju', 116],
    3854: ['North Chungcheong Province', 116],
    3855: ['North Gyeongsang Province', 116],
    3851: ['North Jeolla Province', 116],
    3861: ['Sejong City', 116],
    3849: ['Seoul', 116],
    3859: ['South Chungcheong Province', 116],
    3857: ['South Gyeongsang Province', 116],
    3856: ['South Jeolla Province', 116],
    3852: ['Ulsan', 116],
    4876: ['Đakovica District (Gjakove)', 248],
    4877: ['Gjilan District', 248],
    4878: ['Kosovska Mitrovica District', 248],
    3738: ['Peć District', 248],
    4879: ['Pristina (Priştine)', 248],
    3723: ['Prizren District', 248],
    4874: ['Uroševac District (Ferizaj)', 248],
    977: ['Al Ahmadi Governorate', 117],
    975: ['Al Farwaniyah Governorate', 117],
    972: ['Al Jahra Governorate', 117],
    976: ['Capital Governorate', 117],
    973: ['Hawalli Governorate', 117],
    974: ['Mubarak Al-Kabeer Governorate', 117],
    998: ['Batken Region', 118],
    1001: ['Bishkek', 118],
    1004: ['Chuy Region', 118],
    1002: ['Issyk-Kul Region', 118],
    1000: ['Jalal-Abad Region', 118],
    999: ['Naryn Region', 118],
    1003: ['Osh', 118],
    1005: ['Osh Region', 118],
    997: ['Talas Region', 118],
    982: ['Attapeu Province', 119],
    991: ['Bokeo Province', 119],
    985: ['Bolikhamsai Province', 119],
    996: ['Champasak Province', 119],
    989: ['Houaphanh Province', 119],
    986: ['Khammouane Province', 119],
    992: ['Luang Namtha Province', 119],
    978: ['Luang Prabang Province', 119],
    988: ['Oudomxay Province', 119],
    987: ['Phongsaly Province', 119],
    993: ['Sainyabuli Province', 119],
    981: ['Salavan Province', 119],
    990: ['Savannakhet Province', 119],
    984: ['Sekong Province', 119],
    979: ['Vientiane Prefecture', 119],
    980: ['Vientiane Province', 119],
    994: ['Xaisomboun', 119],
    983: ['Xaisomboun Province', 119],
    995: ['Xiangkhouang Province', 119],
    4445: ['Aglona Municipality', 120],
    4472: ['Aizkraukle Municipality', 120],
    4496: ['Aizpute Municipality', 120],
    4499: ['Aknīste Municipality', 120],
    4484: ['Aloja Municipality', 120],
    4485: ['Alsunga Municipality', 120],
    4487: ['Alūksne Municipality', 120],
    4497: ['Amata Municipality', 120],
    4457: ['Ape Municipality', 120],
    4481: ['Auce Municipality', 120],
    4427: ['Babīte Municipality', 120],
    4482: ['Baldone Municipality', 120],
    4498: ['Baltinava Municipality', 120],
    4505: ['Balvi Municipality', 120],
    4465: ['Bauska Municipality', 120],
    4471: ['Beverīna Municipality', 120],
    4468: ['Brocēni Municipality', 120],
    4411: ['Burtnieki Municipality', 120],
    4454: ['Carnikava Municipality', 120],
    4469: ['Cēsis Municipality', 120],
    4414: ['Cesvaine Municipality', 120],
    4410: ['Cibla Municipality', 120],
    4504: ['Dagda Municipality', 120],
    4463: ['Daugavpils', 120],
    4492: ['Daugavpils Municipality', 120],
    4437: ['Dobele Municipality', 120],
    4428: ['Dundaga Municipality', 120],
    4458: ['Durbe Municipality', 120],
    4448: ['Engure Municipality', 120],
    4444: ['Ērgļi Municipality', 120],
    4510: ['Garkalne Municipality', 120],
    4470: ['Grobiņa Municipality', 120],
    4400: ['Gulbene Municipality', 120],
    4441: ['Iecava Municipality', 120],
    4511: ['Ikšķile Municipality', 120],
    4399: ['Ilūkste Municipality', 120],
    4449: ['Inčukalns Municipality', 120],
    4475: ['Jaunjelgava Municipality', 120],
    4407: ['Jaunpiebalga Municipality', 120],
    4489: ['Jaunpils Municipality', 120],
    4464: ['Jēkabpils', 120],
    4438: ['Jēkabpils Municipality', 120],
    4500: ['Jelgava', 120],
    4424: ['Jelgava Municipality', 120],
    4446: ['Jūrmala', 120],
    4420: ['Kandava Municipality', 120],
    4453: ['Kārsava Municipality', 120],
    4412: ['Ķegums Municipality', 120],
    4435: ['Ķekava Municipality', 120],
    4495: ['Kocēni Municipality', 120],
    4452: ['Koknese Municipality', 120],
    4474: ['Krāslava Municipality', 120],
    4422: ['Krimulda Municipality', 120],
    4413: ['Krustpils Municipality', 120],
    4490: ['Kuldīga Municipality', 120],
    4512: ['Lielvārde Municipality', 120],
    4460: ['Liepāja', 120],
    4488: ['Līgatne Municipality', 120],
    4418: ['Limbaži Municipality', 120],
    4401: ['Līvāni Municipality', 120],
    4419: ['Lubāna Municipality', 120],
    4501: ['Ludza Municipality', 120],
    4433: ['Madona Municipality', 120],
    4461: ['Mālpils Municipality', 120],
    4450: ['Mārupe Municipality', 120],
    4513: ['Mazsalaca Municipality', 120],
    4451: ['Mērsrags Municipality', 120],
    4398: ['Naukšēni Municipality', 120],
    4432: ['Nereta Municipality', 120],
    4436: ['Nīca Municipality', 120],
    4416: ['Ogre Municipality', 120],
    4417: ['Olaine Municipality', 120],
    4442: ['Ozolnieki Municipality', 120],
    4507: ['Pārgauja Municipality', 120],
    4467: ['Pāvilosta Municipality', 120],
    4405: ['Pļaviņas Municipality', 120],
    4483: ['Preiļi Municipality', 120],
    4429: ['Priekule Municipality', 120],
    4506: ['Priekuļi Municipality', 120],
    4479: ['Rauna Municipality', 120],
    4509: ['Rēzekne', 120],
    4455: ['Rēzekne Municipality', 120],
    4502: ['Riebiņi Municipality', 120],
    4491: ['Riga', 120],
    4440: ['Roja Municipality', 120],
    4493: ['Ropaži Municipality', 120],
    4503: ['Rucava Municipality', 120],
    4423: ['Rugāji Municipality', 120],
    4426: ['Rūjiena Municipality', 120],
    4404: ['Rundāle Municipality', 120],
    4434: ['Sala Municipality', 120],
    4396: ['Salacgrīva Municipality', 120],
    4402: ['Salaspils Municipality', 120],
    4439: ['Saldus Municipality', 120],
    4443: ['Saulkrasti Municipality', 120],
    4408: ['Sēja Municipality', 120],
    4476: ['Sigulda Municipality', 120],
    4415: ['Skrīveri Municipality', 120],
    4447: ['Skrunda Municipality', 120],
    4462: ['Smiltene Municipality', 120],
    4478: ['Stopiņi Municipality', 120],
    4494: ['Strenči Municipality', 120],
    4459: ['Talsi Municipality', 120],
    4480: ['Tērvete Municipality', 120],
    4409: ['Tukums Municipality', 120],
    4508: ['Vaiņode Municipality', 120],
    4425: ['Valka Municipality', 120],
    4473: ['Valmiera', 120],
    4431: ['Varakļāni Municipality', 120],
    4406: ['Vārkava Municipality', 120],
    4466: ['Vecpiebalga Municipality', 120],
    4397: ['Vecumnieki Municipality', 120],
    4421: ['Ventspils', 120],
    4403: ['Ventspils Municipality', 120],
    4456: ['Viesīte Municipality', 120],
    4477: ['Viļaka Municipality', 120],
    4486: ['Viļāni Municipality', 120],
    4430: ['Zilupe Municipality', 120],
    2285: ['Akkar Governorate', 121],
    2283: ['Baalbek-Hermel Governorate', 121],
    2286: ['Beirut Governorate', 121],
    2287: ['Beqaa Governorate', 121],
    2282: ['Mount Lebanon Governorate', 121],
    2288: ['Nabatieh Governorate', 121],
    2284: ['North Governorate', 121],
    2281: ['South Governorate', 121],
    3030: ['Berea District', 122],
    3029: ['Butha-Buthe District', 122],
    3026: ['Leribe District', 122],
    3022: ['Mafeteng District', 122],
    3028: ['Maseru District', 122],
    3023: ['Mohale  s Hoek District', 122],
    3024: ['Mokhotlong District', 122],
    3025: ['Qacha  s Nek District', 122],
    3027: ['Quthing District', 122],
    3031: ['Thaba-Tseka District', 122],
    3041: ['Bomi County', 123],
    3034: ['Bong County', 123],
    3044: ['Gbarpolu County', 123],
    3040: ['Grand Bassa County', 123],
    3036: ['Grand Cape Mount County', 123],
    3039: ['Grand Gedeh County', 123],
    3045: ['Grand Kru County', 123],
    3037: ['Lofa County', 123],
    3043: ['Margibi County', 123],
    3042: ['Maryland County', 123],
    3032: ['Montserrado County', 123],
    3046: ['Nimba', 123],
    3033: ['River Cess County', 123],
    3038: ['River Gee County', 123],
    3035: ['Sinoe County', 123],
    2964: ['Al Wahat District', 124],
    2981: ['Benghazi', 124],
    2966: ['Derna District', 124],
    2969: ['Ghat District', 124],
    2980: ['Jabal al Akhdar', 124],
    2974: ['Jabal al Gharbi District', 124],
    2979: ['Jafara', 124],
    2970: ['Jufra', 124],
    2972: ['Kufra District', 124],
    2968: ['Marj District', 124],
    2978: ['Misrata District', 124],
    2961: ['Murqub', 124],
    2967: ['Murzuq District', 124],
    2976: ['Nalut District', 124],
    2962: ['Nuqat al Khams', 124],
    2965: ['Sabha District', 124],
    2977: ['Sirte District', 124],
    2971: ['Tripoli District', 124],
    2973: ['Wadi al Hayaa District', 124],
    2975: ['Wadi al Shatii District', 124],
    2963: ['Zawiya District', 124],
    458: ['Balzers', 125],
    451: ['Eschen', 125],
    457: ['Gamprin', 125],
    455: ['Mauren', 125],
    454: ['Planken', 125],
    453: ['Ruggell', 125],
    450: ['Schaan', 125],
    449: ['Schellenberg', 125],
    459: ['Triesen', 125],
    456: ['Triesenberg', 125],
    452: ['Vaduz', 125],
    1561: ['Akmenė District Municipality', 126],
    1605: ['Alytus City Municipality', 126],
    1574: ['Alytus County', 126],
    1599: ['Alytus District Municipality', 126],
    1603: ['Birštonas Municipality', 126],
    1566: ['Biržai District Municipality', 126],
    1579: ['Druskininkai municipality', 126],
    1559: ['Elektrėnai municipality', 126],
    1562: ['Ignalina District Municipality', 126],
    1567: ['Jonava District Municipality', 126],
    1581: ['Joniškis District Municipality', 126],
    1555: ['Jurbarkas District Municipality', 126],
    1583: ['Kaišiadorys District Municipality', 126],
    1591: ['Kalvarija municipality', 126],
    1580: ['Kaunas City Municipality', 126],
    1556: ['Kaunas County', 126],
    1565: ['Kaunas District Municipality', 126],
    1575: ['Kazlų Rūda municipality', 126],
    1584: ['Kėdainiai District Municipality', 126],
    1618: ['Kelmė District Municipality', 126],
    1597: ['Klaipeda City Municipality', 126],
    1600: ['Klaipėda County', 126],
    1604: ['Klaipėda District Municipality', 126],
    1571: ['Kretinga District Municipality', 126],
    1585: ['Kupiškis District Municipality', 126],
    1611: ['Lazdijai District Municipality', 126],
    1570: ['Marijampolė County', 126],
    1610: ['Marijampolė Municipality', 126],
    1557: ['Mažeikiai District Municipality', 126],
    1582: ['Molėtai District Municipality', 126],
    1563: ['Neringa Municipality', 126],
    1612: ['Pagėgiai municipality', 126],
    1595: ['Pakruojis District Municipality', 126],
    1588: ['Palanga City Municipality', 126],
    1589: ['Panevėžys City Municipality', 126],
    1558: ['Panevėžys County', 126],
    1614: ['Panevėžys District Municipality', 126],
    1616: ['Pasvalys District Municipality', 126],
    1553: ['Plungė District Municipality', 126],
    1578: ['Prienai District Municipality', 126],
    1568: ['Radviliškis District Municipality', 126],
    1587: ['Raseiniai District Municipality', 126],
    1590: ['Rietavas municipality', 126],
    1615: ['Rokiškis District Municipality', 126],
    1576: ['Šakiai District Municipality', 126],
    1577: ['Šalčininkai District Municipality', 126],
    1609: ['Šiauliai City Municipality', 126],
    1586: ['Šiauliai County', 126],
    1554: ['Šiauliai District Municipality', 126],
    1613: ['Šilalė District Municipality', 126],
    1607: ['Šilutė District Municipality', 126],
    1594: ['Širvintos District Municipality', 126],
    1617: ['Skuodas District Municipality', 126],
    1560: ['Švenčionys District Municipality', 126],
    1573: ['Tauragė County', 126],
    1572: ['Tauragė District Municipality', 126],
    1569: ['Telšiai County', 126],
    1608: ['Telšiai District Municipality', 126],
    1593: ['Trakai District Municipality', 126],
    1596: ['Ukmergė District Municipality', 126],
    1621: ['Utena County', 126],
    1598: ['Utena District Municipality', 126],
    1602: ['Varėna District Municipality', 126],
    1620: ['Vilkaviškis District Municipality', 126],
    1606: ['Vilnius City Municipality', 126],
    1601: ['Vilnius County', 126],
    1592: ['Vilnius District Municipality', 126],
    1564: ['Visaginas Municipality', 126],
    1619: ['Zarasai District Municipality', 126],
    1518: ['Canton of Capellen', 127],
    1521: ['Canton of Clervaux', 127],
    1513: ['Canton of Diekirch', 127],
    1515: ['Canton of Echternach', 127],
    1517: ['Canton of Esch-sur-Alzette', 127],
    1525: ['Canton of Grevenmacher', 127],
    1527: ['Canton of Luxembourg', 127],
    1522: ['Canton of Mersch', 127],
    1516: ['Canton of Redange', 127],
    1519: ['Canton of Remich', 127],
    1523: ['Canton of Vianden', 127],
    1526: ['Canton of Wiltz', 127],
    1524: ['Diekirch District', 127],
    1520: ['Grevenmacher District', 127],
    1514: ['Luxembourg District', 127],
    703: ['Aerodrom Municipality', 129],
    656: ['Aračinovo Municipality', 129],
    716: ['Berovo Municipality', 129],
    679: ['Bitola Municipality', 129],
    649: ['Bogdanci Municipality', 129],
    721: ['Bogovinje Municipality', 129],
    652: ['Bosilovo Municipality', 129],
    660: ['Brvenica Municipality', 129],
    694: ['Butel Municipality', 129],
    704: ['Čair Municipality', 129],
    676: ['Čaška Municipality', 129],
    702: ['Centar Municipality', 129],
    720: ['Centar Župa Municipality', 129],
    644: ['Češinovo-Obleševo Municipality', 129],
    715: ['Čučer-Sandevo Municipality', 129],
    645: ['Debarca Municipality', 129],
    695: ['Delčevo Municipality', 129],
    687: ['Demir Hisar Municipality', 129],
    655: ['Demir Kapija Municipality', 129],
    697: ['Dojran Municipality', 129],
    675: ['Dolneni Municipality', 129],
    657: ['Drugovo Municipality', 129],
    707: ['Gazi Baba Municipality', 129],
    648: ['Gevgelija Municipality', 129],
    722: ['Gjorče Petrov Municipality', 129],
    693: ['Gostivar Municipality', 129],
    708: ['Gradsko Municipality', 129],
    684: ['Greater Skopje', 129],
    690: ['Ilinden Municipality', 129],
    678: ['Jegunovce Municipality', 129],
    674: ['Karbinci', 129],
    681: ['Karpoš Municipality', 129],
    713: ['Kavadarci Municipality', 129],
    688: ['Kičevo Municipality', 129],
    686: ['Kisela Voda Municipality', 129],
    723: ['Kočani Municipality', 129],
    665: ['Konče Municipality', 129],
    641: ['Kratovo Municipality', 129],
    677: ['Kriva Palanka Municipality', 129],
    647: ['Krivogaštani Municipality', 129],
    714: ['Kruševo Municipality', 129],
    683: ['Kumanovo Municipality', 129],
    659: ['Lipkovo Municipality', 129],
    705: ['Lozovo Municipality', 129],
    701: ['Makedonska Kamenica Municipality', 129],
    692: ['Makedonski Brod Municipality', 129],
    669: ['Mavrovo and Rostuša Municipality', 129],
    653: ['Mogila Municipality', 129],
    664: ['Negotino Municipality', 129],
    696: ['Novaci Municipality', 129],
    718: ['Novo Selo Municipality', 129],
    699: ['Ohrid Municipality', 129],
    682: ['Oslomej Municipality', 129],
    685: ['Pehčevo Municipality', 129],
    698: ['Petrovec Municipality', 129],
    670: ['Plasnica Municipality', 129],
    666: ['Prilep Municipality', 129],
    646: ['Probištip Municipality', 129],
    709: ['Radoviš Municipality', 129],
    717: ['Rankovce Municipality', 129],
    712: ['Resen Municipality', 129],
    691: ['Rosoman Municipality', 129],
    667: ['Saraj Municipality', 129],
    719: ['Sopište Municipality', 129],
    643: ['Staro Nagoričane Municipality', 129],
    661: ['Štip Municipality', 129],
    700: ['Struga Municipality', 129],
    710: ['Strumica Municipality', 129],
    711: ['Studeničani Municipality', 129],
    680: ['Šuto Orizari Municipality', 129],
    640: ['Sveti Nikole Municipality', 129],
    654: ['Tearce Municipality', 129],
    663: ['Tetovo Municipality', 129],
    671: ['Valandovo Municipality', 129],
    658: ['Vasilevo Municipality', 129],
    651: ['Veles Municipality', 129],
    662: ['Vevčani Municipality', 129],
    672: ['Vinica Municipality', 129],
    650: ['Vraneštica Municipality', 129],
    689: ['Vrapčište Municipality', 129],
    642: ['Zajas Municipality', 129],
    706: ['Zelenikovo Municipality', 129],
    668: ['Želino Municipality', 129],
    673: ['Zrnovci Municipality', 129],
    2951: ['Antananarivo Province', 130],
    2950: ['Antsiranana Province', 130],
    2948: ['Fianarantsoa Province', 130],
    2953: ['Mahajanga Province', 130],
    2952: ['Toamasina Province', 130],
    2949: ['Toliara Province', 130],
    3096: ['Balaka District', 131],
    3102: ['Blantyre District', 131],
    3092: ['Central Region', 131],
    3107: ['Chikwawa District', 131],
    3109: ['Chiradzulu District', 131],
    3087: ['Chitipa district', 131],
    3097: ['Dedza District', 131],
    3090: ['Dowa District', 131],
    3091: ['Karonga District', 131],
    3094: ['Kasungu District', 131],
    3093: ['Likoma District', 131],
    3101: ['Lilongwe District', 131],
    3082: ['Machinga District', 131],
    3110: ['Mangochi District', 131],
    3099: ['Mchinji District', 131],
    3103: ['Mulanje District', 131],
    3084: ['Mwanza District', 131],
    3104: ['Mzimba District', 131],
    3095: ['Nkhata Bay District', 131],
    3100: ['Nkhotakota District', 131],
    3105: ['Northern Region', 131],
    3085: ['Nsanje District', 131],
    3088: ['Ntcheu District', 131],
    3111: ['Ntchisi District', 131],
    3108: ['Phalombe District', 131],
    3089: ['Rumphi District', 131],
    3086: ['Salima District', 131],
    3106: ['Southern Region', 131],
    3098: ['Thyolo District', 131],
    3083: ['Zomba District', 131],
    1950: ['Johor', 132],
    1947: ['Kedah', 132],
    1946: ['Kelantan', 132],
    1949: ['Kuala Lumpur', 132],
    1935: ['Labuan', 132],
    1941: ['Malacca', 132],
    1948: ['Negeri Sembilan', 132],
    1940: ['Pahang', 132],
    1939: ['Penang', 132],
    1943: ['Perak', 132],
    1938: ['Perlis', 132],
    1945: ['Putrajaya', 132],
    1936: ['Sabah', 132],
    1937: ['Sarawak', 132],
    1944: ['Selangor', 132],
    1942: ['Terengganu', 132],
    2594: ['Addu Atoll', 133],
    2587: ['Alif Alif Atoll', 133],
    2600: ['Alif Dhaal Atoll', 133],
    2604: ['Central Province', 133],
    2590: ['Dhaalu Atoll', 133],
    2599: ['Faafu Atoll', 133],
    2598: ['Gaafu Alif Atoll', 133],
    2603: ['Gaafu Dhaalu Atoll', 133],
    2595: ['Gnaviyani Atoll', 133],
    2586: ['Haa Alif Atoll', 133],
    2597: ['Haa Dhaalu Atoll', 133],
    2596: ['Kaafu Atoll', 133],
    2601: ['Laamu Atoll', 133],
    2607: ['Lhaviyani Atoll', 133],
    2609: ['Malé', 133],
    2608: ['Meemu Atoll', 133],
    2592: ['Noonu Atoll', 133],
    2589: ['North Central Province', 133],
    2588: ['North Province', 133],
    2602: ['Raa Atoll', 133],
    2585: ['Shaviyani Atoll', 133],
    2606: ['South Central Province', 133],
    2605: ['South Province', 133],
    2591: ['Thaa Atoll', 133],
    2593: ['Upper South Province', 133],
    2584: ['Vaavu Atoll', 133],
    253: ['Bamako', 134],
    258: ['Gao Region', 134],
    252: ['Kayes Region', 134],
    257: ['Kidal Region', 134],
    250: ['Koulikoro Region', 134],
    251: ['Ménaka Region', 134],
    255: ['Mopti Region', 134],
    249: ['Ségou Region', 134],
    254: ['Sikasso Region', 134],
    256: ['Taoudénit Region', 134],
    248: ['Tombouctou Region', 134],
    110: ['Attard', 135],
    108: ['Balzan', 135],
    107: ['Birgu', 135],
    97: ['Birkirkara', 135],
    88: ['Birżebbuġa', 135],
    138: ['Cospicua', 135],
    117: ['Dingli', 135],
    129: ['Fgura', 135],
    84: ['Floriana', 135],
    134: ['Fontana', 135],
    130: ['Għajnsielem', 135],
    92: ['Għarb', 135],
    120: ['Għargħur', 135],
    106: ['Għasri', 135],
    124: ['Għaxaq', 135],
    118: ['Gudja', 135],
    113: ['Gżira', 135],
    105: ['Ħamrun', 135],
    93: ['Iklin', 135],
    99: ['Kalkara', 135],
    91: ['Kerċem', 135],
    82: ['Kirkop', 135],
    126: ['Lija', 135],
    77: ['Luqa', 135],
    128: ['Marsa', 135],
    137: ['Marsaskala', 135],
    78: ['Marsaxlokk', 135],
    89: ['Mdina', 135],
    102: ['Mellieħa', 135],
    109: ['Mġarr', 135],
    140: ['Mosta', 135],
    74: ['Mqabba', 135],
    96: ['Msida', 135],
    131: ['Mtarfa', 135],
    132: ['Munxar', 135],
    133: ['Nadur', 135],
    112: ['Naxxar', 135],
    115: ['Paola', 135],
    125: ['Pembroke', 135],
    127: ['Pietà', 135],
    79: ['Qala', 135],
    119: ['Qormi', 135],
    111: ['Qrendi', 135],
    83: ['Rabat', 135],
    87: ['Saint Lawrence', 135],
    75: ['San Ġwann', 135],
    116: ['Sannat', 135],
    94: ['Santa Luċija', 135],
    90: ['Santa Venera', 135],
    136: ['Senglea', 135],
    98: ['Siġġiewi', 135],
    104: ['Sliema', 135],
    100: ['St. Julian  s', 135],
    139: ['St. Paul  s Bay', 135],
    86: ['Swieqi', 135],
    122: ['Ta   Xbiex', 135],
    103: ['Tarxien', 135],
    95: ['Valletta', 135],
    101: ['Victoria', 135],
    114: ['Xagħra', 135],
    121: ['Xewkija', 135],
    81: ['Xgħajra', 135],
    123: ['Żabbar', 135],
    85: ['Żebbuġ Gozo', 135],
    80: ['Żebbuġ Malta', 135],
    135: ['Żejtun', 135],
    76: ['Żurrieq', 135],
    2574: ['Ralik Chain', 137],
    2573: ['Ratak Chain', 137],
    3344: ['Adrar Region', 139],
    3349: ['Assaba Region', 139],
    3339: ['Brakna Region', 139],
    3346: ['Dakhlet Nouadhibou', 139],
    3341: ['Gorgol Region', 139],
    3350: ['Guidimaka Region', 139],
    3338: ['Hodh Ech Chargui Region', 139],
    3351: ['Hodh El Gharbi Region', 139],
    3342: ['Inchiri Region', 139],
    3343: ['Nouakchott-Nord Region', 139],
    3352: ['Nouakchott-Ouest Region', 139],
    3347: ['Nouakchott-Sud Region', 139],
    3345: ['Tagant Region', 139],
    3340: ['Tiris Zemmour Region', 139],
    3348: ['Trarza Region', 139],
    3248: ['Agaléga', 140],
    3262: ['Beau Bassin-Rose Hill', 140],
    3251: ['Cargados Carajos', 140],
    3255: ['Curepipe', 140],
    3254: ['Flacq District', 140],
    3264: ['Grand Port District', 140],
    3253: ['Moka District', 140],
    3250: ['Pamplemousses District', 140],
    3263: ['Plaines Wilhems District', 140],
    3256: ['Port Louis', 140],
    3260: ['Port Louis District', 140],
    3258: ['Quatre Bornes', 140],
    3261: ['Rivière du Rempart District', 140],
    3259: ['Rivière Noire District', 140],
    3249: ['Rodrigues', 140],
    3257: ['Savanne District', 140],
    3252: ['Vacoas-Phoenix', 140],
    3456: ['Aguascalientes', 142],
    3457: ['Baja California', 142],
    3460: ['Baja California Sur', 142],
    3475: ['Campeche', 142],
    3451: ['Chiapas', 142],
    3447: ['Chihuahua', 142],
    3471: ['Coahuila', 142],
    3472: ['Colima', 142],
    3453: ['Durango', 142],
    3469: ['Guanajuato', 142],
    3459: ['Guerrero', 142],
    3470: ['Hidalgo', 142],
    4857: ['Jalisco', 142],
    3450: ['México', 142],
    3473: ['Mexico City', 142],
    3474: ['Michoacán', 142],
    3465: ['Morelos', 142],
    3477: ['Nayarit', 142],
    3452: ['Nuevo León', 142],
    3448: ['Oaxaca', 142],
    3476: ['Puebla', 142],
    3455: ['Querétaro', 142],
    3467: ['Quintana Roo', 142],
    3461: ['San Luis Potosí', 142],
    3449: ['Sinaloa', 142],
    3468: ['Sonora', 142],
    3454: ['Tabasco', 142],
    3463: ['Tamaulipas', 142],
    3458: ['Tlaxcala', 142],
    3464: ['Veracruz', 142],
    3466: ['Yucatán', 142],
    3462: ['Zacatecas', 142],
    2580: ['Chuuk State', 143],
    2583: ['Kosrae State', 143],
    2581: ['Pohnpei State', 143],
    2582: ['Yap State', 143],
    4368: ['Anenii Noi District', 144],
    4393: ['Bălți Municipality', 144],
    4379: ['Basarabeasca District', 144],
    4362: ['Bender Municipality', 144],
    4375: ['Briceni District', 144],
    4391: ['Cahul District', 144],
    4366: ['Călărași District', 144],
    4380: ['Cantemir District', 144],
    4365: ['Căușeni District', 144],
    4373: ['Chișinău Municipality', 144],
    4360: ['Cimișlia District', 144],
    4390: ['Criuleni District', 144],
    4384: ['Dondușeni District', 144],
    4392: ['Drochia District', 144],
    4383: ['Dubăsari District', 144],
    4387: ['Edineț District', 144],
    4381: ['Fălești District', 144],
    4370: ['Florești District', 144],
    4385: ['Gagauzia', 144],
    4367: ['Glodeni District', 144],
    4382: ['Hîncești District', 144],
    4369: ['Ialoveni District', 144],
    4363: ['Nisporeni District', 144],
    4389: ['Ocnița District', 144],
    4361: ['Orhei District', 144],
    4394: ['Rezina District', 144],
    4376: ['Rîșcani District', 144],
    4364: ['Sîngerei District', 144],
    4388: ['Șoldănești District', 144],
    4374: ['Soroca District', 144],
    4378: ['Ștefan Vodă District', 144],
    4377: ['Strășeni District', 144],
    4372: ['Taraclia District', 144],
    4371: ['Telenești District', 144],
    4395: ['Transnistria autonomous territorial unit', 144],
    4386: ['Ungheni District', 144],
    4917: ['La Colle', 145],
    4918: ['La Condamine', 145],
    4919: ['Moneghetti', 145],
    1973: ['Arkhangai Province', 146],
    1969: ['Bayan-Ölgii Province', 146],
    1976: ['Bayankhongor Province', 146],
    1961: ['Bulgan Province', 146],
    1962: ['Darkhan-Uul Province', 146],
    1963: ['Dornod Province', 146],
    1981: ['Dornogovi Province', 146],
    1970: ['Dundgovi Province', 146],
    1972: ['Govi-Altai Province', 146],
    1978: ['Govisümber Province', 146],
    1974: ['Khentii Province', 146],
    1964: ['Khovd Province', 146],
    1975: ['Khövsgöl Province', 146],
    1967: ['Ömnögovi Province', 146],
    1966: ['Orkhon Province', 146],
    1965: ['Övörkhangai Province', 146],
    1980: ['Selenge Province', 146],
    1977: ['Sükhbaatar Province', 146],
    1968: ['Töv Province', 146],
    1971: ['Uvs Province', 146],
    1979: ['Zavkhan Province', 146],
    23: ['Andrijevica Municipality', 147],
    13: ['Bar Municipality', 147],
    21: ['Berane Municipality', 147],
    25: ['Bijelo Polje Municipality', 147],
    30: ['Budva Municipality', 147],
    14: ['Danilovgrad Municipality', 147],
    24: ['Gusinje Municipality', 147],
    31: ['Kolašin Municipality', 147],
    26: ['Kotor Municipality', 147],
    22: ['Mojkovac Municipality', 147],
    17: ['Nikšić Municipality', 147],
    28: ['Old Royal Capital Cetinje', 147],
    12: ['Petnjica Municipality', 147],
    19: ['Plav Municipality', 147],
    20: ['Pljevlja Municipality', 147],
    16: ['Plužine Municipality', 147],
    27: ['Podgorica Municipality', 147],
    15: ['Rožaje Municipality', 147],
    18: ['Šavnik Municipality', 147],
    29: ['Tivat Municipality', 147],
    33: ['Ulcinj Municipality', 147],
    32: ['Žabljak Municipality', 147],
    3320: ['Al Haouz Province', 149],
    3267: ['Al Hoceïma Province', 149],
    3266: ['Aousserd Province', 149],
    3297: ['Assa-Zag Province', 149],
    3321: ['Azilal Province', 149],
    3304: ['Ben Slimane Province', 149],
    3278: ['Béni Mellal-Khénifra', 149],
    3272: ['Béni-Mellal Province', 149],
    3285: ['Berkane Province', 149],
    3275: ['Boujdour Province', 149],
    3270: ['Boulemane Province', 149],
    3303: ['Casablanca-Settat', 149],
    3310: ['Chefchaouen Province', 149],
    3274: ['Chichaoua Province', 149],
    3306: ['Dakhla-Oued Ed-Dahab', 149],
    3290: ['Drâa-Tafilalet', 149],
    3291: ['El Hajeb Province', 149],
    3280: ['El Jadida Province', 149],
    3299: ['Errachidia Province', 149],
    3292: ['Es Semara Province', 149],
    3316: ['Essaouira Province', 149],
    3300: ['Fahs Anjra Province', 149],
    3313: ['Fès-Meknès', 149],
    3301: ['Figuig Province', 149],
    3265: ['Guelmim Province', 149],
    3305: ['Guelmim-Oued Noun', 149],
    3325: ['Ifrane Province', 149],
    3294: ['Inezgane-Aït Melloul Prefecture', 149],
    3307: ['Jerada Province', 149],
    3309: ['Kelaat Sraghna Province', 149],
    3308: ['Kénitra Province', 149],
    3276: ['Khémisset Province', 149],
    3317: ['Khénifra Province', 149],
    3326: ['Khouribga Province', 149],
    3293: ['Laâyoune Province', 149],
    3298: ['Laâyoune-Sakia El Hamra', 149],
    3268: ['Larache Province', 149],
    3288: ['Marrakesh-Safi', 149],
    3284: ['Mediouna Province', 149],
    3315: ['Moulay Yacoub Province', 149],
    3281: ['Nador Province', 149],
    3287: ['Nouaceur Province', 149],
    3271: ['Oriental', 149],
    3269: ['Ouarzazate Province', 149],
    3319: ['Oued Ed-Dahab Province', 149],
    3311: ['Safi Province', 149],
    3289: ['Sefrou Province', 149],
    3282: ['Settat Province', 149],
    3302: ['Shtouka Ait Baha Province', 149],
    3279: ['Sidi Kacem Province', 149],
    3273: ['Sidi Youssef Ben Ali', 149],
    3295: ['Souss-Massa', 149],
    3286: ['Tan-Tan Province', 149],
    3324: ['Tanger-Tétouan-Al Hoceïma', 149],
    3323: ['Taounate Province', 149],
    3322: ['Taourirt Province', 149],
    3314: ['Taroudant Province', 149],
    3312: ['Tata Province', 149],
    3296: ['Taza Province', 149],
    3318: ['Tétouan Province', 149],
    3277: ['Tiznit Province', 149],
    3283: ['Zagora Province', 149],
    3327: ['Cabo Delgado Province', 150],
    3329: ['Gaza Province', 150],
    3330: ['Inhambane Province', 150],
    3337: ['Manica Province', 150],
    3335: ['Maputo', 150],
    3332: ['Maputo Province', 150],
    3336: ['Nampula Province', 150],
    3333: ['Niassa Province', 150],
    3331: ['Sofala Province', 150],
    3334: ['Tete Province', 150],
    3328: ['Zambezia Province', 150],
    2142: ['Ayeyarwady Region', 151],
    2141: ['Bago', 151],
    2137: ['Chin State', 151],
    2143: ['Kachin State', 151],
    2144: ['Kayah State', 151],
    2133: ['Kayin State', 151],
    2136: ['Magway Region', 151],
    2134: ['Mandalay Region', 151],
    2147: ['Mon State', 151],
    2146: ['Naypyidaw Union Territory', 151],
    2138: ['Rakhine State', 151],
    2145: ['Sagaing Region', 151],
    2139: ['Shan State', 151],
    2140: ['Tanintharyi Region', 151],
    2135: ['Yangon Region', 151],
    43: ['Erongo Region', 152],
    38: ['Hardap Region', 152],
    45: ['Karas Region', 152],
    36: ['Kavango East Region', 152],
    35: ['Kavango West Region', 152],
    44: ['Khomas Region', 152],
    34: ['Kunene Region', 152],
    40: ['Ohangwena Region', 152],
    41: ['Omaheke Region', 152],
    39: ['Omusati Region', 152],
    37: ['Oshana Region', 152],
    42: ['Oshikoto Region', 152],
    46: ['Otjozondjupa Region', 152],
    47: ['Zambezi Region', 152],
    4656: ['Aiwo District', 153],
    4658: ['Anabar District', 153],
    4667: ['Anetan District', 153],
    4663: ['Anibare District', 153],
    4660: ['Baiti District', 153],
    4665: ['Boe District', 153],
    4662: ['Buada District', 153],
    4666: ['Denigomodu District', 153],
    4654: ['Ewa District', 153],
    4661: ['Ijuw District', 153],
    4657: ['Meneng District', 153],
    4659: ['Nibok District', 153],
    4655: ['Uaboe District', 153],
    4664: ['Yaren District', 153],
    2082: ['Bagmati Zone', 154],
    2071: ['Bheri Zone', 154],
    2073: ['Central Region', 154],
    2080: ['Dhaulagiri Zone', 154],
    2069: ['Eastern Development Region', 154],
    2068: ['Far-Western Development Region', 154],
    2081: ['Gandaki Zone', 154],
    2076: ['Janakpur Zone', 154],
    2079: ['Karnali Zone', 154],
    2072: ['Kosi Zone', 154],
    2074: ['Lumbini Zone', 154],
    2083: ['Mahakali Zone', 154],
    2070: ['Mechi Zone', 154],
    2066: ['Mid-Western Region', 154],
    2075: ['Narayani Zone', 154],
    2077: ['Rapti Zone', 154],
    2084: ['Sagarmatha Zone', 154],
    2078: ['Seti Zone', 154],
    2067: ['Western Region', 154],
    2624: ['Bonaire', 156],
    2613: ['Drenthe', 156],
    2619: ['Flevoland', 156],
    2622: ['Friesland', 156],
    2611: ['Gelderland', 156],
    2617: ['Groningen', 156],
    2615: ['Limburg', 156],
    2623: ['North Brabant', 156],
    2612: ['North Holland', 156],
    2618: ['Overijssel', 156],
    2621: ['Saba', 156],
    2616: ['Sint Eustatius', 156],
    2614: ['South Holland', 156],
    2610: ['Utrecht', 156],
    2620: ['Zeeland', 156],
    4072: ['Auckland Region', 158],
    4074: ['Bay of Plenty Region', 158],
    4066: ['Canterbury Region', 158],
    4067: ['Chatham Islands', 158],
    4068: ['Gisborne District', 158],
    4075: ['Hawke  s Bay Region', 158],
    4060: ['Manawatu-Wanganui Region', 158],
    4063: ['Marlborough Region', 158],
    4070: ['Nelson Region', 158],
    4059: ['Northland Region', 158],
    4062: ['Otago Region', 158],
    4071: ['Southland Region', 158],
    4069: ['Taranaki Region', 158],
    4073: ['Tasman District', 158],
    4061: ['Waikato Region', 158],
    4065: ['Wellington Region', 158],
    4064: ['West Coast Region', 158],
    946: ['Boaco Department', 159],
    950: ['Carazo Department', 159],
    954: ['Chinandega Department', 159],
    940: ['Chontales Department', 159],
    945: ['Estelí Department', 159],
    943: ['Granada Department', 159],
    955: ['Jinotega Department', 159],
    944: ['León Department', 159],
    948: ['Madriz Department', 159],
    941: ['Managua Department', 159],
    953: ['Masaya Department', 159],
    947: ['Matagalpa Department', 159],
    951: ['North Caribbean Coast Autonomous Region', 159],
    949: ['Río San Juan Department', 159],
    942: ['Rivas Department', 159],
    952: ['South Caribbean Coast Autonomous Region', 159],
    71: ['Agadez Region', 160],
    72: ['Diffa Region', 160],
    68: ['Dosso Region', 160],
    70: ['Maradi Region', 160],
    73: ['Tahoua Region', 160],
    67: ['Tillabéri Region', 160],
    69: ['Zinder Region', 160],
    303: ['Abia State', 161],
    320: ['Adamawa State', 161],
    304: ['Akwa Ibom State', 161],
    315: ['Anambra State', 161],
    312: ['Bauchi State', 161],
    305: ['Bayelsa State', 161],
    291: ['Benue State', 161],
    307: ['Borno State', 161],
    314: ['Cross River State', 161],
    316: ['Delta State', 161],
    311: ['Ebonyi State', 161],
    318: ['Edo State', 161],
    309: ['Ekiti State', 161],
    289: ['Enugu State', 161],
    293: ['Federal Capital Territory', 161],
    310: ['Gombe State', 161],
    308: ['Imo State', 161],
    288: ['Jigawa State', 161],
    294: ['Kaduna State', 161],
    300: ['Kano State', 161],
    313: ['Katsina State', 161],
    290: ['Kebbi State', 161],
    298: ['Kogi State', 161],
    295: ['Kwara State', 161],
    306: ['Lagos', 161],
    301: ['Nasarawa State', 161],
    317: ['Niger State', 161],
    323: ['Ogun State', 161],
    321: ['Ondo State', 161],
    322: ['Osun State', 161],
    296: ['Oyo State', 161],
    302: ['Plateau State', 161],
    292: ['Sokoto State', 161],
    319: ['Taraba State', 161],
    297: ['Yobe State', 161],
    299: ['Zamfara State', 161],
    1017: ['Akershus', 165],
    1011: ['Buskerud', 165],
    1016: ['Finnmark', 165],
    1019: ['Hedmark', 165],
    1023: ['Hordaland', 165],
    1026: ['Jan Mayen', 165],
    1020: ['Møre og Romsdal', 165],
    1012: ['Nord-Trøndelag', 165],
    1025: ['Nordland', 165],
    1009: ['Oppland', 165],
    1007: ['Oslo', 165],
    1022: ['Østfold', 165],
    1021: ['Rogaland', 165],
    1018: ['Sogn og Fjordane', 165],
    1010: ['Sør-Trøndelag', 165],
    1013: ['Svalbard', 165],
    1024: ['Telemark', 165],
    1015: ['Troms', 165],
    1006: ['Trøndelag', 165],
    1014: ['Vest-Agder', 165],
    1008: ['Vestfold', 165],
    3058: ['Ad Dakhiliyah Governorate', 166],
    3047: ['Ad Dhahirah Governorate', 166],
    3048: ['Al Batinah North Governorate', 166],
    3050: ['Al Batinah Region', 166],
    3049: ['Al Batinah South Governorate', 166],
    3059: ['Al Buraimi Governorate', 166],
    3056: ['Al Wusta Governorate', 166],
    3053: ['Ash Sharqiyah North Governorate', 166],
    3051: ['Ash Sharqiyah Region', 166],
    3054: ['Ash Sharqiyah South Governorate', 166],
    3057: ['Dhofar Governorate', 166],
    3052: ['Musandam Governorate', 166],
    3055: ['Muscat Governorate', 166],
    3172: ['Azad Kashmir', 167],
    3174: ['Balochistan', 167],
    3173: ['Federally Administered Tribal Areas', 167],
    3170: ['Gilgit-Baltistan', 167],
    3169: ['Islamabad Capital Territory', 167],
    3171: ['Khyber Pakhtunkhwa', 167],
    3176: ['punjab', 167],
    3175: ['Sindh', 167],
    4540: ['Aimeliik', 168],
    4528: ['Airai', 168],
    4538: ['Angaur', 168],
    4529: ['Hatohobei', 168],
    4539: ['Kayangel', 168],
    4532: ['Koror', 168],
    4530: ['Melekeok', 168],
    4537: ['Ngaraard', 168],
    4533: ['Ngarchelong', 168],
    4527: ['Ngardmau', 168],
    4531: ['Ngatpang', 168],
    4536: ['Ngchesar', 168],
    4541: ['Ngeremlengui', 168],
    4534: ['Ngiwal', 168],
    4526: ['Peleliu', 168],
    4535: ['Sonsorol', 168],
    1393: ['Bocas del Toro Province', 170],
    1397: ['Chiriquí Province', 170],
    1387: ['Coclé Province', 170],
    1386: ['Colón Province', 170],
    1385: ['Darién Province', 170],
    1396: ['Emberá-Wounaan Comarca', 170],
    1388: ['Guna Yala', 170],
    1389: ['Herrera Province', 170],
    1390: ['Los Santos Province', 170],
    1391: ['Ngöbe-Buglé Comarca', 170],
    1394: ['Panamá Oeste Province', 170],
    1395: ['Panamá Province', 170],
    1392: ['Veraguas Province', 170],
    4831: ['Bougainville', 171],
    4847: ['Central Province', 171],
    4846: ['Chimbu Province', 171],
    4834: ['East New Britain', 171],
    4845: ['Eastern Highlands Province', 171],
    4848: ['Enga Province', 171],
    4839: ['Gulf', 171],
    4833: ['Hela', 171],
    4832: ['Jiwaka Province', 171],
    4843: ['Madang Province', 171],
    4842: ['Manus Province', 171],
    4849: ['Milne Bay Province', 171],
    4835: ['Morobe Province', 171],
    4841: ['New Ireland Province', 171],
    4838: ['Oro Province', 171],
    4837: ['Port Moresby', 171],
    4836: ['Sandaun Province', 171],
    4844: ['Southern Highlands Province', 171],
    4830: ['West New Britain Province', 171],
    4840: ['Western Highlands Province', 171],
    4850: ['Western Province', 171],
    2785: ['Alto Paraguay Department', 172],
    2784: ['Alto Paraná Department', 172],
    2782: ['Amambay Department', 172],
    2780: ['Boquerón Department', 172],
    2773: ['Caaguazú', 172],
    2775: ['Caazapá', 172],
    2771: ['Canindeyú', 172],
    2777: ['Central Department', 172],
    2779: ['Concepción Department', 172],
    2783: ['Cordillera Department', 172],
    2772: ['Guairá Department', 172],
    2778: ['Itapúa', 172],
    2786: ['Misiones Department', 172],
    2781: ['Ñeembucú Department', 172],
    2774: ['Paraguarí Department', 172],
    2770: ['Presidente Hayes Department', 172],
    2776: ['San Pedro Department', 172],
    3685: ['Amazonas', 173],
    3680: ['Áncash', 173],
    3699: ['Apurímac', 173],
    3681: ['Arequipa', 173],
    3692: ['Ayacucho', 173],
    3688: ['Cajamarca', 173],
    3701: ['Callao', 173],
    3691: ['Cusco', 173],
    3679: ['Huancavelica', 173],
    3687: ['Huanuco', 173],
    3700: ['Ica', 173],
    3693: ['Junín', 173],
    3683: ['La Libertad', 173],
    3702: ['Lambayeque', 173],
    3695: ['Lima', 173],
    4922: ['Loreto', 173],
    3678: ['Madre de Dios', 173],
    3698: ['Moquegua', 173],
    3686: ['Pasco', 173],
    3697: ['Piura', 173],
    3682: ['Puno', 173],
    3694: ['San Martín', 173],
    3696: ['Tacna', 173],
    3689: ['Tumbes', 173],
    3684: ['Ucayali', 173],
    1324: ['Abra', 174],
    1323: ['Agusan del Norte', 174],
    1326: ['Agusan del Sur', 174],
    1331: ['Aklan', 174],
    1337: ['Albay', 174],
    1336: ['Antique', 174],
    1334: ['Apayao', 174],
    1341: ['Aurora', 174],
    1316: ['Autonomous Region in Muslim Mindanao', 174],
    1346: ['Basilan', 174],
    1344: ['Bataan', 174],
    1352: ['Batanes', 174],
    1359: ['Batangas', 174],
    1363: ['Benguet', 174],
    1304: ['Bicol Region', 174],
    1274: ['Biliran', 174],
    1272: ['Bohol', 174],
    1270: ['Bukidnon', 174],
    1278: ['Bulacan', 174],
    1279: ['Cagayan', 174],
    1342: ['Cagayan Valley', 174],
    1294: ['Calabarzon', 174],
    1283: ['Camarines Norte', 174],
    1287: ['Camarines Sur', 174],
    1285: ['Camiguin', 174],
    1292: ['Capiz', 174],
    1314: ['Caraga', 174],
    1301: ['Catanduanes', 174],
    1307: ['Cavite', 174],
    1306: ['Cebu', 174],
    1345: ['Central Luzon', 174],
    1308: ['Central Visayas', 174],
    1311: ['Compostela Valley', 174],
    1335: ['Cordillera Administrative Region', 174],
    1320: ['Cotabato', 174],
    1319: ['Davao del Norte', 174],
    1318: ['Davao del Sur', 174],
    1309: ['Davao Occidental', 174],
    1289: ['Davao Oriental', 174],
    1340: ['Davao Region', 174],
    1291: ['Dinagat Islands', 174],
    1290: ['Eastern Samar', 174],
    1322: ['Eastern Visayas', 174],
    1303: ['Guimaras', 174],
    1300: ['Ifugao', 174],
    1298: ['Ilocos Norte', 174],
    1355: ['Ilocos Region', 174],
    1321: ['Ilocos Sur', 174],
    1315: ['Iloilo', 174],
    1313: ['Isabela', 174],
    1312: ['Kalinga', 174],
    1317: ['La Union', 174],
    1328: ['Laguna', 174],
    1327: ['Lanao del Norte', 174],
    1333: ['Lanao del Sur', 174],
    1332: ['Leyte', 174],
    1330: ['Maguindanao', 174],
    1329: ['Marinduque', 174],
    1338: ['Masbate', 174],
    1347: ['Metro Manila', 174],
    1299: ['Mimaropa', 174],
    1343: ['Misamis Occidental', 174],
    1348: ['Misamis Oriental', 174],
    1353: ['Mountain Province', 174],
    1351: ['Negros Occidental', 174],
    1350: ['Negros Oriental', 174],
    1339: ['Northern Mindanao', 174],
    1349: ['Northern Samar', 174],
    1360: ['Nueva Ecija', 174],
    1358: ['Nueva Vizcaya', 174],
    1356: ['Occidental Mindoro', 174],
    1354: ['Oriental Mindoro', 174],
    1361: ['Palawan', 174],
    1365: ['Pampanga', 174],
    1364: ['Pangasinan', 174],
    1275: ['Quezon', 174],
    1273: ['Quirino', 174],
    1271: ['Rizal', 174],
    1269: ['Romblon', 174],
    1277: ['Sarangani', 174],
    1276: ['Siquijor', 174],
    1310: ['Soccsksargen', 174],
    1281: ['Sorsogon', 174],
    1280: ['South Cotabato', 174],
    1284: ['Southern Leyte', 174],
    1282: ['Sultan Kudarat', 174],
    1288: ['Sulu', 174],
    1286: ['Surigao del Norte', 174],
    1296: ['Surigao del Sur', 174],
    1295: ['Tarlac', 174],
    1293: ['Tawi-Tawi', 174],
    1305: ['Western Visayas', 174],
    1297: ['Zambales', 174],
    1302: ['Zamboanga del Norte', 174],
    1357: ['Zamboanga del Sur', 174],
    1325: ['Zamboanga Peninsula', 174],
    1362: ['Zamboanga Sibugay', 174],
    1634: ['Greater Poland Voivodeship', 176],
    1625: ['Kuyavian-Pomeranian Voivodeship', 176],
    1635: ['Lesser Poland Voivodeship', 176],
    1629: ['Lower Silesian Voivodeship', 176],
    1638: ['Lublin Voivodeship', 176],
    1631: ['Lubusz Voivodeship', 176],
    1636: ['Łódź Voivodeship', 176],
    1637: ['Masovian Voivodeship', 176],
    1622: ['Opole Voivodeship', 176],
    1626: ['Podkarpackie Voivodeship', 176],
    1632: ['Podlaskie Voivodeship', 176],
    1624: ['Pomeranian Voivodeship', 176],
    1623: ['Silesian Voivodeship', 176],
    1630: ['Świętokrzyskie Voivodeship', 176],
    1628: ['Warmian-Masurian Voivodeship', 176],
    1633: ['West Pomeranian Voivodeship', 176],
    2233: ['Açores', 177],
    2235: ['Aveiro', 177],
    2230: ['Beja', 177],
    2244: ['Braga', 177],
    2229: ['Bragança', 177],
    2241: ['Castelo Branco', 177],
    2246: ['Coimbra', 177],
    2236: ['Évora', 177],
    2239: ['Faro', 177],
    4859: ['Guarda', 177],
    2240: ['Leiria', 177],
    2228: ['Lisbon', 177],
    2231: ['Madeira', 177],
    2232: ['Portalegre', 177],
    2243: ['Porto', 177],
    2238: ['Santarém', 177],
    2242: ['Setúbal', 177],
    2245: ['Viana do Castelo', 177],
    2234: ['Vila Real', 177],
    2237: ['Viseu', 177],
    3182: ['Al Daayen', 179],
    3183: ['Al Khor', 179],
    3177: ['Al Rayyan Municipality', 179],
    3179: ['Al Wakrah', 179],
    3178: ['Al-Shahaniya', 179],
    3181: ['Doha', 179],
    3180: ['Madinat ash Shamal', 179],
    3184: ['Umm Salal Municipality', 179],
    4724: ['Alba', 181],
    4739: ['Arad County', 181],
    4722: ['Arges', 181],
    4744: ['Bacău County', 181],
    4723: ['Bihor County', 181],
    4733: ['Bistrița-Năsăud County', 181],
    4740: ['Botoșani County', 181],
    4736: ['Braila', 181],
    4759: ['Brașov County', 181],
    4730: ['Bucharest', 181],
    4756: ['Buzău County', 181],
    4732: ['Călărași County', 181],
    4753: ['Caraș-Severin County', 181],
    4734: ['Cluj County', 181],
    4737: ['Constanța County', 181],
    4754: ['Covasna County', 181],
    4745: ['Dâmbovița County', 181],
    4742: ['Dolj County', 181],
    4747: ['Galați County', 181],
    4726: ['Giurgiu County', 181],
    4750: ['Gorj County', 181],
    4749: ['Harghita County', 181],
    4721: ['Hunedoara County', 181],
    4743: ['Ialomița County', 181],
    4735: ['Iași County', 181],
    4725: ['Ilfov County', 181],
    4760: ['Maramureș County', 181],
    4751: ['Mehedinți County', 181],
    4915: ['Mureș County', 181],
    4731: ['Neamț County', 181],
    4738: ['Olt County', 181],
    4729: ['Prahova County', 181],
    4741: ['Sălaj County', 181],
    4746: ['Satu Mare County', 181],
    4755: ['Sibiu County', 181],
    4720: ['Suceava County', 181],
    4728: ['Teleorman County', 181],
    4748: ['Timiș County', 181],
    4727: ['Tulcea County', 181],
    4757: ['Vâlcea County', 181],
    4752: ['Vaslui County', 181],
    4758: ['Vrancea County', 181],
    1911: ['Altai Krai', 182],
    1876: ['Altai Republic', 182],
    1858: ['Amur Oblast', 182],
    1849: ['Arkhangelsk', 182],
    1866: ['Astrakhan Oblast', 182],
    1903: ['Belgorod Oblast', 182],
    1867: ['Bryansk Oblast', 182],
    1893: ['Chechen Republic', 182],
    1845: ['Chelyabinsk Oblast', 182],
    1859: ['Chukotka Autonomous Okrug', 182],
    1914: ['Chuvash Republic', 182],
    1880: ['Irkutsk', 182],
    1864: ['Ivanovo Oblast', 182],
    1835: ['Jewish Autonomous Oblast', 182],
    1892: ['Kabardino-Balkar Republic', 182],
    1902: ['Kaliningrad', 182],
    1844: ['Kaluga Oblast', 182],
    1865: ['Kamchatka Krai', 182],
    1869: ['Karachay-Cherkess Republic', 182],
    1897: ['Kemerovo Oblast', 182],
    1873: ['Khabarovsk Krai', 182],
    1838: ['Khanty-Mansi Autonomous Okrug', 182],
    1890: ['Kirov Oblast', 182],
    1899: ['Komi Republic', 182],
    1910: ['Kostroma Oblast', 182],
    1891: ['Krasnodar Krai', 182],
    1840: ['Krasnoyarsk Krai', 182],
    1915: ['Kurgan Oblast', 182],
    1855: ['Kursk Oblast', 182],
    1896: ['Leningrad Oblast', 182],
    1889: ['Lipetsk Oblast', 182],
    1839: ['Magadan Oblast', 182],
    1870: ['Mari El Republic', 182],
    1901: ['Moscow', 182],
    1882: ['Moscow Oblast', 182],
    1843: ['Murmansk Oblast', 182],
    1836: ['Nenets Autonomous Okrug', 182],
    1857: ['Nizhny Novgorod Oblast', 182],
    1834: ['Novgorod Oblast', 182],
    1888: ['Novosibirsk', 182],
    1846: ['Omsk Oblast', 182],
    1886: ['Orenburg Oblast', 182],
    1908: ['Oryol Oblast', 182],
    1909: ['Penza Oblast', 182],
    1871: ['Perm Krai', 182],
    1833: ['Primorsky Krai', 182],
    1863: ['Pskov Oblast', 182],
    1852: ['Republic of Adygea', 182],
    1854: ['Republic of Bashkortostan', 182],
    1842: ['Republic of Buryatia', 182],
    1850: ['Republic of Dagestan', 182],
    1884: ['Republic of Ingushetia', 182],
    1883: ['Republic of Kalmykia', 182],
    1841: ['Republic of Karelia', 182],
    1877: ['Republic of Khakassia', 182],
    1898: ['Republic of Mordovia', 182],
    1853: ['Republic of North Ossetia-Alania', 182],
    1861: ['Republic of Tatarstan', 182],
    1837: ['Rostov Oblast', 182],
    1905: ['Ryazan Oblast', 182],
    1879: ['Saint Petersburg', 182],
    1848: ['Sakha Republic', 182],
    1875: ['Sakhalin', 182],
    1862: ['Samara Oblast', 182],
    1887: ['Saratov Oblast', 182],
    1912: ['Sevastopol', 182],
    1885: ['Smolensk Oblast', 182],
    1868: ['Stavropol Krai', 182],
    1894: ['Sverdlovsk', 182],
    1878: ['Tambov Oblast', 182],
    1872: ['Tomsk Oblast', 182],
    1895: ['Tula Oblast', 182],
    1900: ['Tuva Republic', 182],
    1860: ['Tver Oblast', 182],
    1907: ['Tyumen Oblast', 182],
    1913: ['Udmurt Republic', 182],
    1856: ['Ulyanovsk Oblast', 182],
    1881: ['Vladimir Oblast', 182],
    4916: ['Volgograd Oblast', 182],
    1874: ['Vologda Oblast', 182],
    1906: ['Voronezh Oblast', 182],
    1847: ['Yamalo-Nenets Autonomous Okrug', 182],
    1851: ['Yaroslavl Oblast', 182],
    1904: ['Zabaykalsky Krai', 182],
    261: ['Eastern Province', 183],
    262: ['Kigali district', 183],
    263: ['Northern Province', 183],
    259: ['Southern Province', 183],
    260: ['Western Province', 183],
    3833: ['Christ Church Nichola Town Parish', 185],
    3832: ['Nevis', 185],
    3836: ['Saint Anne Sandy Point Parish', 185],
    3837: ['Saint George Gingerland Parish', 185],
    3835: ['Saint James Windward Parish', 185],
    3845: ['Saint John Capisterre Parish', 185],
    3840: ['Saint John Figtree Parish', 185],
    3841: ['Saint Kitts', 185],
    3844: ['Saint Mary Cayon Parish', 185],
    3834: ['Saint Paul Capisterre Parish', 185],
    3838: ['Saint Paul Charlestown Parish', 185],
    3831: ['Saint Peter Basseterre Parish', 185],
    3839: ['Saint Thomas Lowland Parish', 185],
    3842: ['Saint Thomas Middle Island Parish', 185],
    3843: ['Trinity Palmetto Point Parish', 185],
    3757: ['Anse la Raye Quarter', 186],
    3761: ['Canaries', 186],
    3758: ['Castries Quarter', 186],
    3760: ['Choiseul Quarter', 186],
    3767: ['Dauphin Quarter', 186],
    3756: ['Dennery Quarter', 186],
    3766: ['Gros Islet Quarter', 186],
    3759: ['Laborie Quarter', 186],
    3762: ['Micoud Quarter', 186],
    3765: ['Praslin Quarter', 186],
    3764: ['Soufrière Quarter', 186],
    3763: ['Vieux Fort Quarter', 186],
    3389: ['Charlotte Parish', 188],
    3388: ['Grenadines Parish', 188],
    3386: ['Saint Andrew Parish', 188],
    3387: ['Saint David Parish', 188],
    3384: ['Saint George Parish', 188],
    3385: ['Saint Patrick Parish', 188],
    4763: ['A  ana', 191],
    4761: ['Aiga-i-le-Tai', 191],
    4765: ['Atua', 191],
    4764: ['Fa  asaleleaga', 191],
    4769: ['Gaga  emauga', 191],
    4771: ['Gaga  ifomauga', 191],
    4767: ['Palauli', 191],
    4762: ['Satupa  itea', 191],
    4770: ['Tuamasaga', 191],
    4768: ['Va  a-o-Fonoti', 191],
    4766: ['Vaisigano', 191],
    59: ['Acquaviva', 192],
    61: ['Borgo Maggiore', 192],
    60: ['Chiesanuova', 192],
    64: ['Domagnano', 192],
    62: ['Faetano', 192],
    66: ['Fiorentino', 192],
    63: ['Montegiardino', 192],
    58: ['San Marino', 192],
    65: ['Serravalle', 192],
    270: ['Príncipe Province', 193],
    271: ['São Tomé Province', 193],
    2853: ['  Asir Region', 194],
    2859: ['Al Bahah Region', 194],
    2857: ['Al Jawf Region', 194],
    2851: ['Al Madinah Region', 194],
    2861: ['Al-Qassim Region', 194],
    2856: ['Eastern Province', 194],
    2855: ['Ha  il Region', 194],
    2858: ['Jizan Region', 194],
    2850: ['Makkah Region', 194],
    2860: ['Najran Region', 194],
    2854: ['Northern Borders Region', 194],
    2849: ['Riyadh Region', 194],
    2852: ['Tabuk Region', 194],
    473: ['Dakar', 195],
    480: ['Diourbel Region', 195],
    479: ['Fatick', 195],
    475: ['Kaffrine', 195],
    483: ['Kaolack', 195],
    481: ['Kédougou', 195],
    474: ['Kolda', 195],
    485: ['Louga', 195],
    476: ['Matam', 195],
    477: ['Saint-Louis', 195],
    482: ['Sédhiou', 195],
    486: ['Tambacounda Region', 195],
    484: ['Thiès Region', 195],
    478: ['Ziguinchor', 195],
    3728: ['Belgrade', 196],
    3717: ['Bor District', 196],
    3732: ['Braničevo District', 196],
    3716: ['Central Banat District', 196],
    3715: ['Jablanica District', 196],
    3724: ['Kolubara District', 196],
    3719: ['Mačva District', 196],
    3727: ['Moravica District', 196],
    3722: ['Nišava District', 196],
    3714: ['North Bačka District', 196],
    3736: ['North Banat District', 196],
    3721: ['Pčinja District', 196],
    3712: ['Pirot District', 196],
    3741: ['Podunavlje District', 196],
    3737: ['Pomoravlje District', 196],
    3720: ['Rasina District', 196],
    3725: ['Raška District', 196],
    3711: ['South Bačka District', 196],
    3713: ['South Banat District', 196],
    3740: ['Srem District', 196],
    3734: ['Šumadija District', 196],
    3718: ['Toplica District', 196],
    3733: ['Vojvodina', 196],
    3726: ['West Bačka District', 196],
    3731: ['Zaječar District', 196],
    3729: ['Zlatibor District', 196],
    513: ['Anse Boileau', 197],
    502: ['Anse Royale', 197],
    506: ['Anse-aux-Pins', 197],
    508: ['Au Cap', 197],
    497: ['Baie Lazare', 197],
    514: ['Baie Sainte Anne', 197],
    512: ['Beau Vallon', 197],
    515: ['Bel Air', 197],
    505: ['Bel Ombre', 197],
    517: ['Cascade', 197],
    503: ['Glacis', 197],
    500: ['Grand  Anse Mahé', 197],
    504: ['Grand  Anse Praslin', 197],
    495: ['La Digue', 197],
    516: ['La Rivière Anglaise', 197],
    499: ['Les Mamelles', 197],
    494: ['Mont Buxton', 197],
    498: ['Mont Fleuri', 197],
    511: ['Plaisance', 197],
    510: ['Pointe La Rue', 197],
    507: ['Port Glaud', 197],
    501: ['Roche Caiman', 197],
    496: ['Saint Louis', 197],
    509: ['Takamaka', 197],
    914: ['Eastern Province', 198],
    911: ['Northern Province', 198],
    912: ['Southern Province', 198],
    913: ['Western Area', 198],
    4651: ['Central Singapore Community Development Council', 199],
    4649: ['North East Community Development Council', 199],
    4653: ['North West Community Development Council', 199],
    4650: ['South East Community Development Council', 199],
    4652: ['South West Community Development Council', 199],
    4352: ['Banská Bystrica Region', 200],
    4356: ['Bratislava Region', 200],
    4353: ['Košice Region', 200],
    4357: ['Nitra Region', 200],
    4354: ['Prešov Region', 200],
    4358: ['Trenčín Region', 200],
    4355: ['Trnava Region', 200],
    4359: ['Žilina Region', 200],
    4183: ['Ajdovščina Municipality', 201],
    4326: ['Ankaran Municipality', 201],
    4301: ['Beltinci Municipality', 201],
    4166: ['Benedikt Municipality', 201],
    4179: ['Bistrica ob Sotli Municipality', 201],
    4202: ['Bled Municipality', 201],
    4278: ['Bloke Municipality', 201],
    4282: ['Bohinj Municipality', 201],
    4200: ['Borovnica Municipality', 201],
    4181: ['Bovec Municipality', 201],
    4141: ['Braslovče Municipality', 201],
    4240: ['Brda Municipality', 201],
    4215: ['Brežice Municipality', 201],
    4165: ['Brezovica Municipality', 201],
    4147: ['Cankova Municipality', 201],
    4310: ['Cerklje na Gorenjskem Municipality', 201],
    4162: ['Cerknica Municipality', 201],
    4178: ['Cerkno Municipality', 201],
    4176: ['Cerkvenjak Municipality', 201],
    4191: ['City Municipality of Celje', 201],
    4236: ['City Municipality of Novo Mesto', 201],
    4151: ['Črenšovci Municipality', 201],
    4232: ['Črna na Koroškem Municipality', 201],
    4291: ['Črnomelj Municipality', 201],
    4304: ['Destrnik Municipality', 201],
    4167: ['Divača Municipality', 201],
    4295: ['Dobje Municipality', 201],
    4216: ['Dobrepolje Municipality', 201],
    4252: ['Dobrna Municipality', 201],
    4308: ['Dobrova–Polhov Gradec Municipality', 201],
    4189: ['Dobrovnik Municipality', 201],
    4173: ['Dol pri Ljubljani Municipality', 201],
    4281: ['Dolenjske Toplice Municipality', 201],
    4159: ['Domžale Municipality', 201],
    4290: ['Dornava Municipality', 201],
    4345: ['Dravograd Municipality', 201],
    4213: ['Duplek Municipality', 201],
    4293: ['Gorenja Vas–Poljane Municipality', 201],
    4210: ['Gorišnica Municipality', 201],
    4284: ['Gorje Municipality', 201],
    4343: ['Gornja Radgona Municipality', 201],
    4339: ['Gornji Grad Municipality', 201],
    4271: ['Gornji Petrovci Municipality', 201],
    4217: ['Grad Municipality', 201],
    4336: ['Grosuplje Municipality', 201],
    4145: ['Hajdina Municipality', 201],
    4175: ['Hoče–Slivnica Municipality', 201],
    4327: ['Hodoš Municipality', 201],
    4193: ['Horjul Municipality', 201],
    4341: ['Hrastnik Municipality', 201],
    4321: ['Hrpelje–Kozina Municipality', 201],
    4152: ['Idrija Municipality', 201],
    4286: ['Ig Municipality', 201],
    4305: ['Ivančna Gorica Municipality', 201],
    4322: ['Izola Municipality', 201],
    4337: ['Jesenice Municipality', 201],
    4203: ['Jezersko Municipality', 201],
    4266: ['Juršinci Municipality', 201],
    4180: ['Kamnik Municipality', 201],
    4227: ['Kanal ob Soči Municipality', 201],
    4150: ['Kidričevo Municipality', 201],
    4243: ['Kobarid Municipality', 201],
    4325: ['Kobilje Municipality', 201],
    4335: ['Kočevje Municipality', 201],
    4315: ['Komen Municipality', 201],
    4283: ['Komenda Municipality', 201],
    4319: ['Koper City Municipality', 201],
    4254: ['Kostanjevica na Krki Municipality', 201],
    4331: ['Kostel Municipality', 201],
    4186: ['Kozje Municipality', 201],
    4287: ['Kranj City Municipality', 201],
    4340: ['Kranjska Gora Municipality', 201],
    4238: ['Križevci Municipality', 201],
    4197: ['Kungota', 201],
    4211: ['Kuzma Municipality', 201],
    4338: ['Laško Municipality', 201],
    4142: ['Lenart Municipality', 201],
    4225: ['Lendava Municipality', 201],
    4347: ['Litija Municipality', 201],
    4270: ['Ljubljana City Municipality', 201],
    4294: ['Ljubno Municipality', 201],
    4351: ['Ljutomer Municipality', 201],
    4306: ['Log–Dragomer Municipality', 201],
    4350: ['Logatec Municipality', 201],
    4174: ['Loška Dolina Municipality', 201],
    4158: ['Loški Potok Municipality', 201],
    4156: ['Lovrenc na Pohorju Municipality', 201],
    4219: ['Luče Municipality', 201],
    4302: ['Lukovica Municipality', 201],
    4157: ['Majšperk Municipality', 201],
    4224: ['Makole Municipality', 201],
    4242: ['Maribor City Municipality', 201],
    4244: ['Markovci Municipality', 201],
    4349: ['Medvode Municipality', 201],
    4348: ['Mengeš Municipality', 201],
    4323: ['Metlika Municipality', 201],
    4265: ['Mežica Municipality', 201],
    4223: ['Miklavž na Dravskem Polju Municipality', 201],
    4220: ['Miren–Kostanjevica Municipality', 201],
    4298: ['Mirna Municipality', 201],
    4237: ['Mirna Peč Municipality', 201],
    4212: ['Mislinja Municipality', 201],
    4297: ['Mokronog–Trebelno Municipality', 201],
    4168: ['Moravče Municipality', 201],
    4218: ['Moravske Toplice Municipality', 201],
    4190: ['Mozirje Municipality', 201],
    4318: ['Municipality of Apače', 201],
    4309: ['Municipality of Cirkulane', 201],
    4344: ['Municipality of Ilirska Bistrica', 201],
    4314: ['Municipality of Krško', 201],
    4187: ['Municipality of Škofljica', 201],
    4313: ['Murska Sobota City Municipality', 201],
    4208: ['Muta Municipality', 201],
    4177: ['Naklo Municipality', 201],
    4329: ['Nazarje Municipality', 201],
    4205: ['Nova Gorica City Municipality', 201],
    4320: ['Odranci Municipality', 201],
    4143: ['Oplotnica', 201],
    4221: ['Ormož Municipality', 201],
    4199: ['Osilnica Municipality', 201],
    4172: ['Pesnica Municipality', 201],
    4201: ['Piran Municipality', 201],
    4184: ['Pivka Municipality', 201],
    4146: ['Podčetrtek Municipality', 201],
    4161: ['Podlehnik Municipality', 201],
    4234: ['Podvelka Municipality', 201],
    4239: ['Poljčane Municipality', 201],
    4272: ['Polzela Municipality', 201],
    4330: ['Postojna Municipality', 201],
    4188: ['Prebold Municipality', 201],
    4303: ['Preddvor Municipality', 201],
    4274: ['Prevalje Municipality', 201],
    4228: ['Ptuj City Municipality', 201],
    4288: ['Puconci Municipality', 201],
    4204: ['Rače–Fram Municipality', 201],
    4195: ['Radeče Municipality', 201],
    4292: ['Radenci Municipality', 201],
    4275: ['Radlje ob Dravi Municipality', 201],
    4231: ['Radovljica Municipality', 201],
    4155: ['Ravne na Koroškem Municipality', 201],
    4206: ['Razkrižje Municipality', 201],
    4160: ['Rečica ob Savinji Municipality', 201],
    4253: ['Renče–Vogrsko Municipality', 201],
    4235: ['Ribnica Municipality', 201],
    4207: ['Ribnica na Pohorju Municipality', 201],
    4233: ['Rogaška Slatina Municipality', 201],
    4264: ['Rogašovci Municipality', 201],
    4209: ['Rogatec Municipality', 201],
    4280: ['Ruše Municipality', 201],
    4222: ['Šalovci Municipality', 201],
    4230: ['Selnica ob Dravi Municipality', 201],
    4346: ['Semič Municipality', 201],
    4317: ['Šempeter–Vrtojba Municipality', 201],
    4299: ['Šenčur Municipality', 201],
    4324: ['Šentilj Municipality', 201],
    4241: ['Šentjernej Municipality', 201],
    4171: ['Šentjur Municipality', 201],
    4311: ['Šentrupert Municipality', 201],
    4268: ['Sevnica Municipality', 201],
    4149: ['Sežana Municipality', 201],
    4170: ['Škocjan Municipality', 201],
    4316: ['Škofja Loka Municipality', 201],
    4169: ['Slovenj Gradec City Municipality', 201],
    4332: ['Slovenska Bistrica Municipality', 201],
    4198: ['Slovenske Konjice Municipality', 201],
    4285: ['Šmarje pri Jelšah Municipality', 201],
    4289: ['Šmarješke Toplice Municipality', 201],
    4296: ['Šmartno ob Paki Municipality', 201],
    4279: ['Šmartno pri Litiji Municipality', 201],
    4277: ['Sodražica Municipality', 201],
    4261: ['Solčava Municipality', 201],
    4248: ['Šoštanj Municipality', 201],
    4263: ['Središče ob Dravi', 201],
    4259: ['Starše Municipality', 201],
    4185: ['Štore Municipality', 201],
    4333: ['Straža Municipality', 201],
    4164: ['Sveta Ana Municipality', 201],
    4260: ['Sveta Trojica v Slovenskih Goricah Municipality', 201],
    4229: ['Sveti Andraž v Slovenskih Goricah Municipality', 201],
    4255: ['Sveti Jurij ob Ščavnici Municipality', 201],
    4328: ['Sveti Jurij v Slovenskih Goricah Municipality', 201],
    4273: ['Sveti Tomaž Municipality', 201],
    4194: ['Tabor Municipality', 201],
    4312: ['Tišina Municipality', 201],
    4247: ['Tolmin Municipality', 201],
    4246: ['Trbovlje Municipality', 201],
    4214: ['Trebnje Municipality', 201],
    4153: ['Trnovska Vas Municipality', 201],
    4250: ['Tržič Municipality', 201],
    4334: ['Trzin Municipality', 201],
    4251: ['Turnišče Municipality', 201],
    4267: ['Velika Polana Municipality', 201],
    4144: ['Velike Lašče Municipality', 201],
    4257: ['Veržej Municipality', 201],
    4300: ['Videm Municipality', 201],
    4196: ['Vipava Municipality', 201],
    4148: ['Vitanje Municipality', 201],
    4154: ['Vodice Municipality', 201],
    4245: ['Vojnik Municipality', 201],
    4163: ['Vransko Municipality', 201],
    4262: ['Vrhnika Municipality', 201],
    4226: ['Vuzenica Municipality', 201],
    4269: ['Zagorje ob Savi Municipality', 201],
    4258: ['Žalec Municipality', 201],
    4182: ['Zavrč Municipality', 201],
    4256: ['Železniki Municipality', 201],
    4249: ['Žetale Municipality', 201],
    4192: ['Žiri Municipality', 201],
    4276: ['Žirovnica Municipality', 201],
    4342: ['Zreče Municipality', 201],
    4307: ['Žužemberk Municipality', 201],
    4784: ['Central Province', 202],
    4781: ['Choiseul Province', 202],
    4785: ['Guadalcanal Province', 202],
    4778: ['Honiara', 202],
    4780: ['Isabel Province', 202],
    4782: ['Makira-Ulawa Province', 202],
    4783: ['Malaita Province', 202],
    4787: ['Rennell and Bellona Province', 202],
    4779: ['Temotu Province', 202],
    4786: ['Western Province', 202],
    925: ['Awdal Region', 203],
    917: ['Bakool', 203],
    927: ['Banaadir', 203],
    930: ['Bari', 203],
    926: ['Bay', 203],
    918: ['Galguduud', 203],
    928: ['Gedo', 203],
    915: ['Hiran', 203],
    924: ['Lower Juba', 203],
    921: ['Lower Shebelle', 203],
    922: ['Middle Juba', 203],
    923: ['Middle Shebelle', 203],
    916: ['Mudug', 203],
    920: ['Nugal', 203],
    919: ['Sanaag Region', 203],
    929: ['Togdheer Region', 203],
    938: ['Eastern Cape', 204],
    932: ['Free State', 204],
    936: ['Gauteng', 204],
    935: ['KwaZulu-Natal', 204],
    933: ['Limpopo', 204],
    937: ['Mpumalanga', 204],
    934: ['North West', 204],
    931: ['Northern Cape', 204],
    939: ['Western Cape', 204],
    2092: ['Central Equatoria', 206],
    2093: ['Eastern Equatoria', 206],
    2094: ['Jonglei State', 206],
    2090: ['Lakes', 206],
    2088: ['Northern Bahr el Ghazal', 206],
    2085: ['Unity', 206],
    2086: ['Upper Nile', 206],
    2087: ['Warrap', 206],
    2091: ['Western Bahr el Ghazal', 206],
    2089: ['Western Equatoria', 206],
    1193: ['Andalusia', 207],
    1177: ['Aragon', 207],
    1160: ['Asturias', 207],
    1189: ['Ávila', 207],
    1174: ['Balearic Islands', 207],
    1191: ['Basque Country', 207],
    1146: ['Burgos Province', 207],
    1185: ['Canary Islands', 207],
    1170: ['Cantabria', 207],
    1184: ['Castile and León', 207],
    1205: ['Castilla La Mancha', 207],
    1203: ['Catalonia', 207],
    1206: ['Ceuta', 207],
    1190: ['Extremadura', 207],
    1167: ['Galicia', 207],
    1171: ['La Rioja', 207],
    1200: ['Léon', 207],
    1158: ['Madrid', 207],
    1159: ['Melilla', 207],
    1176: ['Murcia', 207],
    1204: ['Navarra', 207],
    1157: ['Palencia Province', 207],
    1147: ['Salamanca Province', 207],
    1192: ['Segovia Province', 207],
    1208: ['Soria Province', 207],
    1175: ['Valencia', 207],
    1183: ['Valladolid Province', 207],
    1161: ['Zamora Province', 207],
    2799: ['Ampara District', 208],
    2816: ['Anuradhapura District', 208],
    2790: ['Badulla District', 208],
    2818: ['Batticaloa District', 208],
    2798: ['Central Province', 208],
    2815: ['Colombo District', 208],
    2808: ['Eastern Province', 208],
    2792: ['Galle District', 208],
    2804: ['Gampaha District', 208],
    2791: ['Hambantota District', 208],
    2787: ['Jaffna District', 208],
    2789: ['Kalutara District', 208],
    2788: ['Kandy District', 208],
    2797: ['Kegalle District', 208],
    2793: ['Kilinochchi District', 208],
    2805: ['Mannar District', 208],
    2810: ['Matale District', 208],
    2806: ['Matara District', 208],
    2819: ['Monaragala District', 208],
    2814: ['Mullaitivu District', 208],
    2800: ['North Central Province', 208],
    2817: ['North Western Province', 208],
    2813: ['Northern Province', 208],
    2794: ['Nuwara Eliya District', 208],
    2812: ['Polonnaruwa District', 208],
    2796: ['Puttalam District', 208],
    2807: ['Ratnapura district', 208],
    2803: ['Sabaragamuwa Province', 208],
    2801: ['Southern Province', 208],
    2795: ['Trincomalee District', 208],
    2811: ['Uva Province', 208],
    2809: ['Vavuniya District', 208],
    2802: ['Western Province', 208],
    885: ['Al Jazirah', 209],
    886: ['Al Qadarif', 209],
    887: ['Blue Nile', 209],
    896: ['Central Darfur', 209],
    892: ['East Darfur', 209],
    884: ['Kassala', 209],
    881: ['Khartoum', 209],
    890: ['North Darfur', 209],
    893: ['North Kordofan', 209],
    895: ['Northern', 209],
    880: ['Red Sea', 209],
    891: ['River Nile', 209],
    882: ['Sennar', 209],
    894: ['South Darfur', 209],
    883: ['South Kordofan', 209],
    888: ['West Darfur', 209],
    889: ['West Kordofan', 209],
    879: ['White Nile', 209],
    2846: ['Brokopondo District', 210],
    2839: ['Commewijne District', 210],
    2842: ['Coronie District', 210],
    2845: ['Marowijne District', 210],
    2840: ['Nickerie District', 210],
    2841: ['Para District', 210],
    2843: ['Paramaribo District', 210],
    2848: ['Saramacca District', 210],
    2847: ['Sipaliwini District', 210],
    2844: ['Wanica District', 210],
    969: ['Hhohho District', 212],
    970: ['Lubombo District', 212],
    968: ['Manzini District', 212],
    971: ['Shiselweni District', 212],
    1537: ['Blekinge', 213],
    1534: ['Dalarna County', 213],
    1533: ['Gävleborg County', 213],
    1546: ['Gotland County', 213],
    1548: ['Halland County', 213],
    1550: ['Jönköping County', 213],
    1544: ['Kalmar County', 213],
    1542: ['Kronoberg County', 213],
    1538: ['Norrbotten County', 213],
    1539: ['Örebro County', 213],
    1536: ['Östergötland County', 213],
    1541: ['Skåne County', 213],
    1540: ['Södermanland County', 213],
    1551: ['Stockholm County', 213],
    1545: ['Uppsala County', 213],
    1535: ['Värmland County', 213],
    1543: ['Västerbotten County', 213],
    1552: ['Västernorrland County', 213],
    1549: ['Västmanland County', 213],
    1547: ['Västra Götaland County', 213],
    1639: ['Aargau', 214],
    1655: ['Appenzell Ausserrhoden', 214],
    1649: ['Appenzell Innerrhoden', 214],
    1641: ['Basel-Landschaft', 214],
    1645: ['canton of Bern', 214],
    1640: ['Canton of Fribourg', 214],
    1647: ['Canton of Geneva', 214],
    1658: ['Canton of Jura', 214],
    1663: ['Canton of Lucerne', 214],
    1659: ['Canton of Neuchâtel', 214],
    1654: ['Canton of Schaffhausen', 214],
    1662: ['Canton of Solothurn', 214],
    1644: ['Canton of St. Gallen', 214],
    1648: ['Canton of Valais', 214],
    1651: ['Canton of Vaud', 214],
    1646: ['Canton of Zug', 214],
    1656: ['canton of Zürich', 214],
    1661: ['Glarus', 214],
    1660: ['Graubünden', 214],
    1652: ['Nidwalden', 214],
    1650: ['Obwalden', 214],
    1653: ['Schwyz', 214],
    1657: ['Thurgau', 214],
    1643: ['Ticino', 214],
    1642: ['Uri', 214],
    2941: ['Al-Hasakah Governorate', 215],
    2944: ['Al-Raqqah Governorate', 215],
    2946: ['Aleppo Governorate', 215],
    2936: ['As-Suwayda Governorate', 215],
    2939: ['Damascus Governorate', 215],
    2945: ['Daraa Governorate', 215],
    2937: ['Deir ez-Zor Governorate', 215],
    2934: ['Hama Governorate', 215],
    2942: ['Homs Governorate', 215],
    2940: ['Idlib Governorate', 215],
    2938: ['Latakia Governorate', 215],
    2943: ['Quneitra Governorate', 215],
    2935: ['Rif Dimashq Governorate', 215],
    2947: ['Tartus Governorate', 215],
    3404: ['Changhua County', 216],
    3418: ['Chiayi City', 216],
    3408: ['Chiayi County', 216],
    3417: ['Hsinchu', 216],
    3423: ['Hsinchu County', 216],
    3411: ['Hualien County', 216],
    3412: ['Kaohsiung', 216],
    3409: ['Kaohsiung County', 216],
    3415: ['Kinmen', 216],
    3420: ['Lienchiang County', 216],
    3413: ['Miaoli County', 216],
    3407: ['Nantou County', 216],
    3403: ['Penghu County', 216],
    3405: ['Pingtung County', 216],
    3406: ['Taichung', 216],
    3414: ['Taichung County', 216],
    3421: ['Tainan', 216],
    3401: ['Tainan County', 216],
    3422: ['Taipei', 216],
    3410: ['Taitung County', 216],
    3419: ['Taoyuan City', 216],
    3402: ['Yilan County', 216],
    3416: ['Yunlin County', 216],
    3397: ['districts of Republican Subordination', 217],
    3399: ['Gorno-Badakhshan Autonomous Province', 217],
    3398: ['Khatlon Province', 217],
    3400: ['Sughd Province', 217],
    1491: ['Arusha Region', 218],
    1490: ['Dar es Salaam Region', 218],
    1466: ['Dodoma Region', 218],
    1481: ['Geita Region', 218],
    1489: ['Iringa Region', 218],
    1465: ['Kagera Region', 218],
    1482: ['Katavi Region', 218],
    1478: ['Kigoma Region', 218],
    1467: ['Kilimanjaro Region', 218],
    1483: ['Lindi Region', 218],
    1484: ['Manyara Region', 218],
    1468: ['Mara Region', 218],
    1470: ['Morogoro Region', 218],
    1476: ['Mtwara Region', 218],
    1479: ['Mwanza Region', 218],
    1480: ['Njombe Region', 218],
    1488: ['North Pemba Region', 218],
    1485: ['Pwani Region', 218],
    1477: ['Rukwa Region', 218],
    1486: ['Ruvuma Region', 218],
    1463: ['Shinyanga Region', 218],
    1464: ['Simiyu Region', 218],
    1474: ['Singida Region', 218],
    1472: ['South Pemba Region', 218],
    1469: ['Tabora Region', 218],
    1487: ['Tanga Region', 218],
    1471: ['Zanzibar Central/South Region', 218],
    1473: ['Zanzibar North Region', 218],
    1475: ['Zanzibar Urban/West Region', 218],
    3523: ['Amnat Charoen', 219],
    3519: ['Ang Thong', 219],
    3554: ['Bangkok', 219],
    3533: ['Bueng Kan', 219],
    3534: ['Buri Ram', 219],
    3552: ['Chachoengsao', 219],
    3522: ['Chai Nat', 219],
    3486: ['Chanthaburi', 219],
    3491: ['Chiang Mai', 219],
    3498: ['Chiang Rai', 219],
    3513: ['Chon Buri', 219],
    3526: ['Chumphon', 219],
    3550: ['Kalasin', 219],
    3516: ['Kamphaeng Phet', 219],
    3511: ['Kanchanaburi', 219],
    3485: ['Khon Kaen', 219],
    3478: ['Krabi', 219],
    3544: ['Lampang', 219],
    3483: ['Lamphun', 219],
    3509: ['Loei', 219],
    3543: ['Lopburi', 219],
    3505: ['Mae Hong Son', 219],
    3517: ['Maha Sarakham', 219],
    3546: ['Mukdahan', 219],
    3535: ['Nakhon Nayok', 219],
    3503: ['Nakhon Pathom', 219],
    3548: ['Nakhon Phanom', 219],
    3497: ['Nakhon Ratchasima', 219],
    3492: ['Nakhon Sawan', 219],
    3520: ['Nakhon Si Thammarat', 219],
    3530: ['Nan', 219],
    3553: ['Narathiwat', 219],
    3480: ['Nong Bua Lam Phu', 219],
    3484: ['Nong Khai', 219],
    3495: ['Nonthaburi', 219],
    3500: ['Pathum Thani', 219],
    3540: ['Pattani', 219],
    3507: ['Pattaya', 219],
    3549: ['Phang Nga', 219],
    3488: ['Phatthalung', 219],
    3538: ['Phayao', 219],
    3515: ['Phetchabun', 219],
    3532: ['Phetchaburi', 219],
    3514: ['Phichit', 219],
    3506: ['Phitsanulok', 219],
    3494: ['Phra Nakhon Si Ayutthaya', 219],
    3528: ['Phrae', 219],
    3536: ['Phuket', 219],
    3542: ['Prachin Buri', 219],
    3508: ['Prachuap Khiri Khan', 219],
    3479: ['Ranong', 219],
    3499: ['Ratchaburi', 219],
    3518: ['Rayong', 219],
    3510: ['Roi Et', 219],
    3529: ['Sa Kaeo', 219],
    3501: ['Sakon Nakhon', 219],
    3481: ['Samut Prakan', 219],
    3504: ['Samut Sakhon', 219],
    3502: ['Samut Songkhram', 219],
    3487: ['Saraburi', 219],
    3537: ['Satun', 219],
    3547: ['Si Sa Ket', 219],
    3490: ['Sing Buri', 219],
    3539: ['Songkhla', 219],
    3545: ['Sukhothai', 219],
    3524: ['Suphanburi', 219],
    3482: ['Surat Thani', 219],
    3531: ['Surin', 219],
    3525: ['Tak', 219],
    3541: ['Trang', 219],
    3496: ['Trat', 219],
    3512: ['Ubon Ratchathani', 219],
    3527: ['Udon Thani', 219],
    3551: ['Uthai Thani', 219],
    3489: ['Uttaradit', 219],
    3493: ['Yala', 219],
    3521: ['Yasothon', 219],
    2575: ['Centrale Region', 220],
    2579: ['Kara Region', 220],
    2576: ['Maritime', 220],
    2577: ['Plateaux Region', 220],
    2578: ['Savanes Region', 220],
    3913: ['Haʻapai', 222],
    3915: ['ʻEua', 222],
    3914: ['Niuas', 222],
    3912: ['Tongatapu', 222],
    3911: ['Vavaʻu', 222],
    3362: ['Arima', 223],
    3366: ['Chaguanas', 223],
    3354: ['Couva-Tabaquite-Talparo Regional Corporation', 223],
    3367: ['Diego Martin Regional Corporation', 223],
    3355: ['Eastern Tobago', 223],
    3365: ['Penal-Debe Regional Corporation', 223],
    3360: ['Point Fortin', 223],
    3363: ['Port of Spain', 223],
    3368: ['Princes Town Regional Corporation', 223],
    3356: ['Rio Claro-Mayaro Regional Corporation', 223],
    3359: ['San Fernando', 223],
    3357: ['San Juan-Laventille Regional Corporation', 223],
    3361: ['Sangre Grande Regional Corporation', 223],
    3364: ['Siparia Regional Corporation', 223],
    3358: ['Tunapuna-Piarco Regional Corporation', 223],
    3353: ['Western Tobago', 223],
    2550: ['Ariana Governorate', 224],
    2566: ['Ben Arous Governorate', 224],
    2551: ['Bizerte Governorate', 224],
    2558: ['Gabès Governorate', 224],
    2556: ['Gafsa Governorate', 224],
    2552: ['Jendouba Governorate', 224],
    2564: ['Kairouan Governorate', 224],
    2570: ['Kasserine Governorate', 224],
    2572: ['Kassrine', 224],
    2562: ['Kebili Governorate', 224],
    2561: ['Kef Governorate', 224],
    2568: ['Mahdia Governorate', 224],
    2555: ['Manouba Governorate', 224],
    2560: ['Medenine Governorate', 224],
    2553: ['Monastir Governorate', 224],
    2557: ['Sfax Governorate', 224],
    2567: ['Sidi Bouzid Governorate', 224],
    2563: ['Siliana Governorate', 224],
    2571: ['Sousse Governorate', 224],
    2559: ['Tataouine Governorate', 224],
    2569: ['Tozeur Governorate', 224],
    2554: ['Tunis Governorate', 224],
    2565: ['Zaghouan Governorate', 224],
    2212: ['Adana Province', 225],
    2155: ['Adıyaman Province', 225],
    2179: ['Afyonkarahisar Province', 225],
    2193: ['Ağrı Province', 225],
    2210: ['Aksaray Province', 225],
    2161: ['Amasya Province', 225],
    2217: ['Ankara Province', 225],
    2169: ['Antalya Province', 225],
    2185: ['Ardahan Province', 225],
    2191: ['Artvin Province', 225],
    2187: ['Aydın Province', 225],
    2175: ['Balıkesir Province', 225],
    2148: ['Bartın Province', 225],
    2194: ['Batman Province', 225],
    2177: ['Bayburt Province', 225],
    2221: ['Bilecik Province', 225],
    2153: ['Bingöl Province', 225],
    2215: ['Bitlis Province', 225],
    2172: ['Bolu Province', 225],
    2209: ['Burdur Province', 225],
    2163: ['Bursa Province', 225],
    2216: ['Çanakkale Province', 225],
    2168: ['Çankırı Province', 225],
    2173: ['Çorum Province', 225],
    2157: ['Denizli Province', 225],
    2226: ['Diyarbakır Province', 225],
    2202: ['Düzce Province', 225],
    2151: ['Edirne Province', 225],
    2159: ['Elazığ Province', 225],
    2160: ['Erzincan Province', 225],
    2165: ['Erzurum Province', 225],
    2164: ['Eskişehir Province', 225],
    2203: ['Gaziantep Province', 225],
    2186: ['Giresun Province', 225],
    2204: ['Gümüşhane Province', 225],
    2190: ['Hakkâri Province', 225],
    2211: ['Hatay Province', 225],
    2166: ['Iğdır Province', 225],
    2222: ['Isparta Province', 225],
    2170: ['Istanbul Province', 225],
    2205: ['İzmir Province', 225],
    2227: ['Kahramanmaraş Province', 225],
    2223: ['Karabük Province', 225],
    2184: ['Karaman Province', 225],
    2208: ['Kars Province', 225],
    2197: ['Kastamonu Province', 225],
    2200: ['Kayseri Province', 225],
    2154: ['Kilis Province', 225],
    2178: ['Kırıkkale Province', 225],
    2176: ['Kırklareli Province', 225],
    2180: ['Kırşehir Province', 225],
    2195: ['Kocaeli Province', 225],
    2171: ['Konya Province', 225],
    2149: ['Kütahya Province', 225],
    2158: ['Malatya Province', 225],
    2198: ['Manisa Province', 225],
    2224: ['Mardin Province', 225],
    2156: ['Mersin Province', 225],
    2182: ['Muğla Province', 225],
    2162: ['Muş Province', 225],
    2196: ['Nevşehir Province', 225],
    2189: ['Niğde Province', 225],
    2174: ['Ordu Province', 225],
    2214: ['Osmaniye Province', 225],
    2219: ['Rize Province', 225],
    2150: ['Sakarya Province', 225],
    2220: ['Samsun Province', 225],
    2183: ['Şanlıurfa Province', 225],
    2207: ['Siirt Province', 225],
    4854: ['Sinop Province', 225],
    2181: ['Sivas Province', 225],
    2225: ['Şırnak Province', 225],
    2167: ['Tekirdağ Province', 225],
    2199: ['Tokat Province', 225],
    2206: ['Trabzon Province', 225],
    2192: ['Tunceli Province', 225],
    2201: ['Uşak Province', 225],
    2152: ['Van Province', 225],
    2218: ['Yalova Province', 225],
    2188: ['Yozgat Province', 225],
    2213: ['Zonguldak Province', 225],
    3374: ['Ahal Region', 226],
    3371: ['Ashgabat', 226],
    3372: ['Balkan Region', 226],
    3373: ['Daşoguz Region', 226],
    3370: ['Lebap Region', 226],
    3369: ['Mary Region', 226],
    3951: ['Funafuti', 228],
    3947: ['Nanumanga', 228],
    3949: ['Nanumea', 228],
    3946: ['Niutao Island Council', 228],
    3948: ['Nui', 228],
    3952: ['Nukufetau', 228],
    3953: ['Nukulaelae', 228],
    3950: ['Vaitupu', 228],
    329: ['Abim District', 229],
    361: ['Adjumani District', 229],
    392: ['Agago District', 229],
    344: ['Alebtong District', 229],
    416: ['Amolatar District', 229],
    353: ['Amudat District', 229],
    352: ['Amuria District', 229],
    335: ['Amuru District', 229],
    328: ['Apac District', 229],
    447: ['Arua District', 229],
    441: ['Budaka District', 229],
    349: ['Bududa District', 229],
    387: ['Bugiri District', 229],
    391: ['Buhweju District', 229],
    377: ['Buikwe District', 229],
    343: ['Bukedea District', 229],
    375: ['Bukomansimbi District', 229],
    385: ['Bukwo District', 229],
    428: ['Bulambuli District', 229],
    389: ['Buliisa District', 229],
    419: ['Bundibugyo District', 229],
    381: ['Bunyangabu District', 229],
    386: ['Bushenyi District', 229],
    431: ['Busia District', 229],
    365: ['Butaleja District', 229],
    384: ['Butambala District', 229],
    388: ['Butebo District', 229],
    414: ['Buvuma District', 229],
    380: ['Buyende District', 229],
    396: ['Central Region', 229],
    341: ['Dokolo District', 229],
    372: ['Eastern Region', 229],
    366: ['Gomba District', 229],
    413: ['Gulu District', 229],
    339: ['Ibanda District', 229],
    340: ['Iganga District', 229],
    383: ['Isingiro District', 229],
    367: ['Jinja District', 229],
    434: ['Kaabong District', 229],
    426: ['Kabale District', 229],
    326: ['Kabarole District', 229],
    336: ['Kaberamaido District', 229],
    403: ['Kagadi District', 229],
    399: ['Kakumiro District', 229],
    405: ['Kalangala District', 229],
    398: ['Kaliro District', 229],
    394: ['Kalungu District', 229],
    382: ['Kampala District', 229],
    334: ['Kamuli District', 229],
    360: ['Kamwenge District', 229],
    373: ['Kanungu District', 229],
    432: ['Kapchorwa District', 229],
    440: ['Kasese District', 229],
    420: ['Katakwi District', 229],
    368: ['Kayunga District', 229],
    436: ['Kibaale District', 229],
    347: ['Kiboga District', 229],
    338: ['Kibuku District', 229],
    355: ['Kiruhura District', 229],
    346: ['Kiryandongo District', 229],
    409: ['Kisoro District', 229],
    348: ['Kitgum District', 229],
    345: ['Koboko District', 229],
    401: ['Kole District', 229],
    443: ['Kotido District', 229],
    425: ['Kumi District', 229],
    369: ['Kween District', 229],
    325: ['Kyankwanzi District', 229],
    437: ['Kyegegwa District', 229],
    402: ['Kyenjojo District', 229],
    448: ['Kyotera District', 229],
    411: ['Lamwo District', 229],
    342: ['Lira District', 229],
    445: ['Luuka District', 229],
    433: ['Luwero District', 229],
    417: ['Lwengo District', 229],
    376: ['Lyantonde District', 229],
    438: ['Manafwa District', 229],
    421: ['Maracha District', 229],
    356: ['Masaka District', 229],
    354: ['Masindi District', 229],
    418: ['Mayuge District', 229],
    350: ['Mbale District', 229],
    415: ['Mbarara District', 229],
    435: ['Mitooma District', 229],
    364: ['Mityana District', 229],
    395: ['Moroto District', 229],
    363: ['Moyo District', 229],
    327: ['Mpigi District', 229],
    371: ['Mubende District', 229],
    410: ['Mukono District', 229],
    393: ['Nakapiripirit District', 229],
    423: ['Nakaseke District', 229],
    406: ['Nakasongola District', 229],
    351: ['Namayingo District', 229],
    400: ['Namisindwa District', 229],
    337: ['Namutumba District', 229],
    430: ['Napak District', 229],
    446: ['Nebbi District', 229],
    424: ['Ngora District', 229],
    332: ['Northern Region', 229],
    422: ['Ntoroko District', 229],
    404: ['Ntungamo District', 229],
    378: ['Nwoya District', 229],
    374: ['Omoro District', 229],
    390: ['Otuke District', 229],
    397: ['Oyam District', 229],
    408: ['Pader District', 229],
    357: ['Pakwach District', 229],
    412: ['Pallisa District', 229],
    439: ['Rakai District', 229],
    358: ['Rubanda District', 229],
    442: ['Rubirizi District', 229],
    331: ['Rukiga District', 229],
    324: ['Rukungiri District', 229],
    427: ['Sembabule District', 229],
    333: ['Serere District', 229],
    407: ['Sheema District', 229],
    429: ['Sironko District', 229],
    444: ['Soroti District', 229],
    359: ['Tororo District', 229],
    362: ['Wakiso District', 229],
    370: ['Western Region', 229],
    330: ['Yumbe District', 229],
    379: ['Zombo District', 229],
    4689: ['Autonomous Republic of Crimea', 230],
    4680: ['Cherkasy Oblast', 230],
    4692: ['Chernihiv Oblast', 230],
    4678: ['Chernivtsi Oblast', 230],
    4675: ['Dnipropetrovsk Oblast', 230],
    4691: ['Donetsk Oblast', 230],
    4682: ['Ivano-Frankivsk Oblast', 230],
    4686: ['Kharkiv Oblast', 230],
    4684: ['Kherson Oblast', 230],
    4681: ['Khmelnytsky Oblast', 230],
    4676: ['Kiev', 230],
    4677: ['Kirovohrad Oblast', 230],
    4671: ['Kyiv Oblast', 230],
    4673: ['Luhansk Oblast', 230],
    4672: ['Lviv Oblast', 230],
    4679: ['Mykolaiv Oblast', 230],
    4688: ['Odessa Oblast', 230],
    4683: ['Rivne Oblast', 230],
    4685: ['Sumy Oblast', 230],
    4674: ['Ternopil Oblast', 230],
    4669: ['Vinnytsia Oblast', 230],
    4690: ['Volyn Oblast', 230],
    4670: ['Zakarpattia Oblast', 230],
    4687: ['Zaporizhzhya Oblast', 230],
    4668: ['Zhytomyr Oblast', 230],
    3396: ['Abu Dhabi Emirate', 231],
    3395: ['Ajman Emirate', 231],
    3391: ['Dubai', 231],
    3393: ['Fujairah', 231],
    3394: ['Ras al-Khaimah', 231],
    3390: ['Sharjah Emirate', 231],
    3392: ['Umm al-Quwain', 231],
    2463: ['Aberdeen', 232],
    2401: ['Aberdeenshire', 232],
    2387: ['Angus', 232],
    2533: ['Antrim', 232],
    2412: ['Antrim and Newtownabbey', 232],
    2498: ['Ards', 232],
    2523: ['Ards and North Down', 232],
    2392: ['Argyll and Bute', 232],
    2331: ['Armagh City and District Council', 232],
    2324: ['Armagh  Banbridge and Craigavon', 232],
    2378: ['Ascension Island', 232],
    2363: ['Ballymena Borough', 232],
    2361: ['Ballymoney', 232],
    2315: ['Banbridge', 232],
    2499: ['Barnsley', 232],
    2339: ['Bath and North East Somerset', 232],
    2507: ['Bedford', 232],
    2311: ['Belfast district', 232],
    2425: ['Birmingham', 232],
    2329: ['Blackburn with Darwen', 232],
    2451: ['Blackpool', 232],
    2530: ['Blaenau Gwent County Borough', 232],
    2504: ['Bolton', 232],
    2342: ['Bournemouth', 232],
    2470: ['Bracknell Forest', 232],
    2529: ['Bradford', 232],
    2452: ['Bridgend County Borough', 232],
    2395: ['Brighton and Hove', 232],
    2405: ['Buckinghamshire', 232],
    2459: ['Bury', 232],
    2298: ['Caerphilly County Borough', 232],
    2517: ['Calderdale', 232],
    2423: ['Cambridgeshire', 232],
    2484: ['Carmarthenshire', 232],
    2439: ['Carrickfergus Borough Council', 232],
    2525: ['Castlereagh', 232],
    2316: ['Causeway Coast and Glens', 232],
    2303: ['Central Bedfordshire', 232],
    2509: ['Ceredigion', 232],
    2444: ['Cheshire East', 232],
    2442: ['Cheshire West and Chester', 232],
    2528: ['City and County of Cardiff', 232],
    2433: ['City and County of Swansea', 232],
    2413: ['City of Bristol', 232],
    2485: ['City of Derby', 232],
    2475: ['City of Kingston upon Hull', 232],
    2318: ['City of Leicester', 232],
    2424: ['City of London', 232],
    2359: ['City of Nottingham', 232],
    2297: ['City of Peterborough', 232],
    2514: ['City of Plymouth', 232],
    2305: ['City of Portsmouth', 232],
    2294: ['City of Southampton', 232],
    2506: ['City of Stoke-on-Trent', 232],
    2372: ['City of Sunderland', 232],
    2357: ['City of Westminster', 232],
    2489: ['City of Wolverhampton', 232],
    2426: ['City of York', 232],
    2450: ['Clackmannanshire', 232],
    2461: ['Coleraine Borough Council', 232],
    2352: ['Conwy County Borough', 232],
    2445: ['Cookstown District Council', 232],
    2312: ['Cornwall', 232],
    2406: ['County Durham', 232],
    2438: ['Coventry', 232],
    2449: ['Craigavon Borough Council', 232],
    2334: ['Cumbria', 232],
    2389: ['Darlington', 232],
    2497: ['Denbighshire', 232],
    2403: ['Derbyshire', 232],
    2446: ['Derry City and Strabane', 232],
    2417: ['Derry City Council', 232],
    2491: ['Devon', 232],
    2364: ['Doncaster', 232],
    2345: ['Dorset', 232],
    2304: ['Down District Council', 232],
    2457: ['Dudley', 232],
    2415: ['Dumfries and Galloway', 232],
    2511: ['Dundee', 232],
    2508: ['Dungannon and South Tyrone Borough Council', 232],
    2374: ['East Ayrshire', 232],
    2454: ['East Dunbartonshire', 232],
    2462: ['East Lothian', 232],
    2333: ['East Renfrewshire', 232],
    2370: ['East Riding of Yorkshire', 232],
    2414: ['East Sussex', 232],
    2428: ['Edinburgh', 232],
    2336: ['England', 232],
    2410: ['Essex', 232],
    2344: ['Falkirk', 232],
    2366: ['Fermanagh and Omagh', 232],
    2531: ['Fermanagh District Council', 232],
    2479: ['Fife', 232],
    2437: ['Flintshire', 232],
    2431: ['Gateshead', 232],
    2404: ['Glasgow', 232],
    2373: ['Gloucestershire', 232],
    2379: ['Gwynedd', 232],
    2466: ['Halton', 232],
    2435: ['Hampshire', 232],
    2309: ['Hartlepool', 232],
    2500: ['Herefordshire', 232],
    2369: ['Hertfordshire', 232],
    2383: ['Highland', 232],
    2388: ['Inverclyde', 232],
    2289: ['Isle of Wight', 232],
    2343: ['Isles of Scilly', 232],
    2464: ['Kent', 232],
    2371: ['Kirklees', 232],
    2330: ['Knowsley', 232],
    2495: ['Lancashire', 232],
    2515: ['Larne Borough Council', 232],
    2503: ['Leeds', 232],
    2516: ['Leicestershire', 232],
    2382: ['Limavady Borough Council', 232],
    2355: ['Lincolnshire', 232],
    2460: ['Lisburn and Castlereagh', 232],
    2494: ['Lisburn City Council', 232],
    2340: ['Liverpool', 232],
    2356: ['London Borough of Barking and Dagenham', 232],
    2520: ['London Borough of Barnet', 232],
    2307: ['London Borough of Bexley', 232],
    2291: ['London Borough of Brent', 232],
    2490: ['London Borough of Bromley', 232],
    2349: ['London Borough of Camden', 232],
    2512: ['London Borough of Croydon', 232],
    2532: ['London Borough of Ealing', 232],
    2476: ['London Borough of Enfield', 232],
    2411: ['London Borough of Hackney', 232],
    2448: ['London Borough of Hammersmith and Fulham', 232],
    2306: ['London Borough of Haringey', 232],
    2385: ['London Borough of Harrow', 232],
    2347: ['London Borough of Havering', 232],
    2376: ['London Borough of Hillingdon', 232],
    2380: ['London Borough of Hounslow', 232],
    2319: ['London Borough of Islington', 232],
    2396: ['London Borough of Lambeth', 232],
    2358: ['London Borough of Lewisham', 232],
    2483: ['London Borough of Merton', 232],
    2418: ['London Borough of Newham', 232],
    2397: ['London Borough of Redbridge', 232],
    2501: ['London Borough of Richmond upon Thames', 232],
    2432: ['London Borough of Southwark', 232],
    2313: ['London Borough of Sutton', 232],
    2390: ['London Borough of Tower Hamlets', 232],
    2326: ['London Borough of Waltham Forest', 232],
    2434: ['London Borough of Wandsworth', 232],
    2322: ['Magherafelt District Council', 232],
    2398: ['Manchester', 232],
    2381: ['Medway', 232],
    2328: ['Merthyr Tydfil County Borough', 232],
    2320: ['Metropolitan Borough of Wigan', 232],
    2429: ['Mid and East Antrim', 232],
    2399: ['Mid Ulster', 232],
    2332: ['Middlesbrough', 232],
    2519: ['Midlothian', 232],
    2416: ['Milton Keynes', 232],
    2402: ['Monmouthshire', 232],
    2360: ['Moray', 232],
    2348: ['Moyle District Council', 232],
    2351: ['Neath Port Talbot County Borough', 232],
    2458: ['Newcastle upon Tyne', 232],
    2524: ['Newport', 232],
    2350: ['Newry and Mourne District Council', 232],
    2534: ['Newry  Mourne and Down', 232],
    2317: ['Newtownabbey Borough Council', 232],
    2473: ['Norfolk', 232],
    2535: ['North Ayrshire', 232],
    2513: ['North Down Borough Council', 232],
    2384: ['North East Lincolnshire', 232],
    2487: ['North Lanarkshire', 232],
    2453: ['North Lincolnshire', 232],
    2430: ['North Somerset', 232],
    2521: ['North Tyneside', 232],
    2522: ['North Yorkshire', 232],
    2480: ['Northamptonshire', 232],
    2337: ['Northern Ireland', 232],
    2365: ['Northumberland', 232],
    2456: ['Nottinghamshire', 232],
    2477: ['Oldham', 232],
    2314: ['Omagh District Council', 232],
    2474: ['Orkney Islands', 232],
    2353: ['Outer Hebrides', 232],
    2321: ['Oxfordshire', 232],
    2486: ['Pembrokeshire', 232],
    2325: ['Perth and Kinross', 232],
    2302: ['Poole', 232],
    2441: ['Powys', 232],
    2455: ['Reading', 232],
    2527: ['Redcar and Cleveland', 232],
    2443: ['Renfrewshire', 232],
    2301: ['Rhondda Cynon Taf', 232],
    2327: ['Rochdale', 232],
    2308: ['Rotherham', 232],
    2492: ['Royal Borough of Greenwich', 232],
    2368: ['Royal Borough of Kensington and Chelsea', 232],
    2481: ['Royal Borough of Kingston upon Thames', 232],
    2472: ['Rutland', 232],
    2502: ['Saint Helena', 232],
    2493: ['Salford', 232],
    2341: ['Sandwell', 232],
    2335: ['Scotland', 232],
    2346: ['Scottish Borders', 232],
    2518: ['Sefton', 232],
    2295: ['Sheffield', 232],
    2300: ['Shetland Islands', 232],
    2407: ['Shropshire', 232],
    2427: ['Slough', 232],
    2469: ['Solihull', 232],
    2386: ['Somerset', 232],
    2377: ['South Ayrshire', 232],
    2400: ['South Gloucestershire', 232],
    2362: ['South Lanarkshire', 232],
    2409: ['South Tyneside', 232],
    2323: ['Southend-on-Sea', 232],
    2290: ['St Helens', 232],
    2447: ['Staffordshire', 232],
    2488: ['Stirling', 232],
    2394: ['Stockport', 232],
    2421: ['Stockton-on-Tees', 232],
    2393: ['Strabane District Council', 232],
    2467: ['Suffolk', 232],
    2526: ['Surrey', 232],
    2422: ['Swindon', 232],
    2367: ['Tameside', 232],
    2310: ['Telford and Wrekin', 232],
    2468: ['Thurrock', 232],
    2478: ['Torbay', 232],
    2496: ['Torfaen', 232],
    2293: ['Trafford', 232],
    2375: ['United Kingdom', 232],
    2299: ['Vale of Glamorgan', 232],
    2465: ['Wakefield', 232],
    2338: ['Wales', 232],
    2292: ['Walsall', 232],
    2420: ['Warrington', 232],
    2505: ['Warwickshire', 232],
    2471: ['West Berkshire', 232],
    2440: ['West Dunbartonshire', 232],
    2354: ['West Lothian', 232],
    2296: ['West Sussex', 232],
    2391: ['Wiltshire', 232],
    2482: ['Windsor and Maidenhead', 232],
    2408: ['Wirral', 232],
    2419: ['Wokingham', 232],
    2510: ['Worcestershire', 232],
    2436: ['Wrexham County Borough', 232],
    1456: ['Alabama', 233],
    1400: ['Alaska', 233],
    1424: ['American Samoa', 233],
    1434: ['Arizona', 233],
    1444: ['Arkansas', 233],
    1402: ['Baker Island', 233],
    1416: ['California', 233],
    1450: ['Colorado', 233],
    1435: ['Connecticut', 233],
    1399: ['Delaware', 233],
    1437: ['District of Columbia', 233],
    1436: ['Florida', 233],
    1455: ['Georgia', 233],
    1412: ['Guam', 233],
    1411: ['Hawaii', 233],
    1398: ['Howland Island', 233],
    1460: ['Idaho', 233],
    1425: ['Illinois', 233],
    1440: ['Indiana', 233],
    1459: ['Iowa', 233],
    1410: ['Jarvis Island', 233],
    1428: ['Johnston Atoll', 233],
    1406: ['Kansas', 233],
    1419: ['Kentucky', 233],
    1403: ['Kingman Reef', 233],
    1457: ['Louisiana', 233],
    1453: ['Maine', 233],
    1401: ['Maryland', 233],
    1433: ['Massachusetts', 233],
    1426: ['Michigan', 233],
    1438: ['Midway Atoll', 233],
    1420: ['Minnesota', 233],
    1430: ['Mississippi', 233],
    1451: ['Missouri', 233],
    1446: ['Montana', 233],
    1439: ['Navassa Island', 233],
    1408: ['Nebraska', 233],
    1458: ['Nevada', 233],
    1404: ['New Hampshire', 233],
    1417: ['New Jersey', 233],
    1423: ['New Mexico', 233],
    1452: ['New York', 233],
    1447: ['North Carolina', 233],
    1418: ['North Dakota', 233],
    1431: ['Northern Mariana Islands', 233],
    4851: ['Ohio', 233],
    1421: ['Oklahoma', 233],
    1415: ['Oregon', 233],
    1448: ['Palmyra Atoll', 233],
    1422: ['Pennsylvania', 233],
    1449: ['Puerto Rico', 233],
    1461: ['Rhode Island', 233],
    1443: ['South Carolina', 233],
    1445: ['South Dakota', 233],
    1454: ['Tennessee', 233],
    1407: ['Texas', 233],
    1432: ['United States Minor Outlying Islands', 233],
    1413: ['United States Virgin Islands', 233],
    1414: ['Utah', 233],
    1409: ['Vermont', 233],
    1427: ['Virginia', 233],
    1405: ['Wake Island', 233],
    1462: ['Washington', 233],
    1429: ['West Virginia', 233],
    1441: ['Wisconsin', 233],
    1442: ['Wyoming', 233],
    3205: ['Artigas Department', 235],
    3213: ['Canelones Department', 235],
    3211: ['Cerro Largo Department', 235],
    3208: ['Colonia Department', 235],
    3209: ['Durazno Department', 235],
    3203: ['Flores Department', 235],
    3217: ['Florida Department', 235],
    3215: ['Lavalleja Department', 235],
    3206: ['Maldonado Department', 235],
    3218: ['Montevideo Department', 235],
    3212: ['Paysandú Department', 235],
    3210: ['Río Negro Department', 235],
    3207: ['Rivera Department', 235],
    3216: ['Rocha Department', 235],
    3220: ['Salto Department', 235],
    3204: ['San José Department', 235],
    3219: ['Soriano Department', 235],
    3221: ['Tacuarembó Department', 235],
    3214: ['Treinta y Tres Department', 235],
    2540: ['Andijan Region', 236],
    2541: ['Bukhara Region', 236],
    2538: ['Fergana Region', 236],
    2545: ['Jizzakh Region', 236],
    2548: ['Karakalpakstan', 236],
    2537: ['Namangan Region', 236],
    2542: ['Navoiy Region', 236],
    2543: ['Qashqadaryo Region', 236],
    2544: ['Samarqand Region', 236],
    2547: ['Sirdaryo Region', 236],
    2546: ['Surxondaryo Region', 236],
    2536: ['Tashkent', 236],
    2549: ['Tashkent Region', 236],
    2539: ['Xorazm Region', 236],
    4775: ['Malampa', 237],
    4773: ['Penama', 237],
    4776: ['Sanma', 237],
    4774: ['Shefa', 237],
    4777: ['Tafea', 237],
    4772: ['Torba', 237],
    2044: ['Amazonas', 239],
    2050: ['Anzoátegui', 239],
    4856: ['Apure', 239],
    2047: ['Aragua', 239],
    2049: ['Barinas', 239],
    2039: ['Bolívar', 239],
    4855: ['Capital District', 239],
    2040: ['Carabobo', 239],
    2034: ['Cojedes', 239],
    2051: ['Delta Amacuro', 239],
    2035: ['Falcón', 239],
    2046: ['Federal Dependencies of Venezuela', 239],
    2045: ['Guárico', 239],
    2038: ['Lara', 239],
    2053: ['Mérida', 239],
    2037: ['Miranda', 239],
    2054: ['Monagas', 239],
    2052: ['Nueva Esparta', 239],
    2036: ['Portuguesa', 239],
    2056: ['Sucre', 239],
    2048: ['Táchira', 239],
    2043: ['Trujillo', 239],
    2055: ['Vargas', 239],
    2041: ['Yaracuy', 239],
    2042: ['Zulia', 239],
    3794: ['An Giang', 240],
    3770: ['Bà Rịa-Vũng Tàu', 240],
    3815: ['Bắc Giang', 240],
    3822: ['Bắc Kạn', 240],
    3804: ['Bạc Liêu', 240],
    3791: ['Bắc Ninh', 240],
    3796: ['Bến Tre', 240],
    3785: ['Bình Dương', 240],
    3830: ['Bình Định', 240],
    3797: ['Bình Phước', 240],
    3787: ['Bình Thuận', 240],
    3778: ['Cà Mau', 240],
    4925: ['Cần Thơ', 240],
    3782: ['Cao Bằng', 240],
    3806: ['Đà Nẵng', 240],
    3829: ['Đắk Lắk', 240],
    3823: ['Đắk Nông', 240],
    3773: ['Điện Biên', 240],
    3821: ['Đồng Nai', 240],
    3769: ['Đồng Tháp', 240],
    3813: ['Gia Lai', 240],
    3779: ['Hà Giang', 240],
    3802: ['Hà Nam', 240],
    3810: ['Hà Nội', 240],
    3816: ['Hà Tĩnh', 240],
    3827: ['Hải Dương', 240],
    3783: ['Hải Phòng', 240],
    3777: ['Hậu Giang', 240],
    3811: ['Hồ Chí Minh', 240],
    3799: ['Hòa Bình', 240],
    3768: ['Hưng Yên', 240],
    3793: ['Khánh Hòa', 240],
    3800: ['Kiên Giang', 240],
    3772: ['Kon Tum', 240],
    3825: ['Lai Châu', 240],
    3818: ['Lâm Đồng', 240],
    3792: ['Lạng Sơn', 240],
    3817: ['Lào Cai', 240],
    3808: ['Long An', 240],
    3789: ['Nam Định', 240],
    3780: ['Nghệ An', 240],
    3786: ['Ninh Bình', 240],
    3788: ['Ninh Thuận', 240],
    3801: ['Phú Thọ', 240],
    3824: ['Phú Yên', 240],
    3809: ['Quảng Bình', 240],
    3776: ['Quảng Nam', 240],
    3828: ['Quảng Ngãi', 240],
    3814: ['Quảng Ninh', 240],
    3803: ['Quảng Trị', 240],
    3819: ['Sóc Trăng', 240],
    3812: ['Sơn La', 240],
    3826: ['Tây Ninh', 240],
    3775: ['Thái Bình', 240],
    3807: ['Thái Nguyên', 240],
    3771: ['Thanh Hóa', 240],
    3798: ['Thừa Thiên-Huế', 240],
    3781: ['Tiền Giang', 240],
    3805: ['Trà Vinh', 240],
    3795: ['Tuyên Quang', 240],
    3790: ['Vĩnh Long', 240],
    3774: ['Vĩnh Phúc', 240],
    3784: ['Yên Bái', 240],
    1242: ['  Adan Governorate', 245],
    1250: ['  Amran Governorate', 245],
    1237: ['Abyan Governorate', 245],
    1240: ['Al Bayda   Governorate', 245],
    1241: ['Al Hudaydah Governorate', 245],
    1243: ['Al Jawf Governorate', 245],
    1251: ['Al Mahrah Governorate', 245],
    1235: ['Al Mahwit Governorate', 245],
    1246: ['Dhamar Governorate', 245],
    1238: ['Hadhramaut Governorate', 245],
    1244: ['Hajjah Governorate', 245],
    1233: ['Ibb Governorate', 245],
    1245: ['Lahij Governorate', 245],
    1234: ['Ma  rib Governorate', 245],
    1248: ['Raymah Governorate', 245],
    1249: ['Saada Governorate', 245],
    1232: ['Sana  a', 245],
    1236: ['Sana  a Governorate', 245],
    1247: ['Shabwah Governorate', 245],
    1239: ['Socotra Governorate', 245],
    1231: ['Ta  izz Governorate', 245],
    1986: ['Central Province', 246],
    1984: ['Copperbelt Province', 246],
    1991: ['Eastern Province', 246],
    1987: ['Luapula Province', 246],
    1988: ['Lusaka Province', 246],
    1989: ['Muchinga Province', 246],
    1982: ['Northern Province', 246],
    1985: ['Northwestern Province', 246],
    1990: ['Southern Province', 246],
    1983: ['Western Province', 246],
    1956: ['Bulawayo Province', 247],
    1958: ['Harare Province', 247],
    1959: ['Manicaland', 247],
    1955: ['Mashonaland Central Province', 247],
    1951: ['Mashonaland East Province', 247],
    1953: ['Mashonaland West Province', 247],
    1960: ['Masvingo Province', 247],
    1954: ['Matabeleland North Province', 247],
    1952: ['Matabeleland South Province', 247],
    1957: ['Midlands Province', 247],
}

export let Cities = {
    'Ashkāsham': 3901,
    'Fayzabad': 3901,
    'Jurm': 3901,
    'Khandūd': 3901,
    'Rāghistān': 3901,
    'Wākhān': 3901,
    'Ghormach': 3871,
    'Qala i Naw': 3871,
    'Baghlān': 3875,
    'Ḩukūmatī Dahanah-ye Ghōrī': 3875,
    'Nahrīn': 3875,
    'Pul-e Khumrī': 3875,
    'Balkh': 3884,
    'Dowlatābād': 3884,
    'Khulm': 3884,
    'Lab-Sar': 3884,
    'Mazār-e Sharīf': 3884,
    'Qarchī Gak': 3884,
    'Bāmyān': 3872,
    'Panjāb': 3872,
    'Nīlī': 3892,
    'Farah': 3899,
    'Andkhoy': 3889,
    'Maymana': 3889,
    'Ghazni': 3870,
    'Fayrōz Kōh': 3888,
    'Shahrak': 3888,
    '‘Alāqahdārī Dīshū': 3873,
    'Gereshk': 3873,
    'Lashkar Gāh': 3873,
    'Markaz-e Ḩukūmat-e Darwēshān': 3873,
    'Sangīn': 3873,
    'Chahār Burj': 3887,
    'Ghōriyān': 3887,
    'Herāt': 3887,
    'Kafir Qala': 3887,
    'Karukh': 3887,
    'Kuhsān': 3887,
    'Kushk': 3887,
    'Qarah Bāgh': 3887,
    'Shīnḏanḏ': 3887,
    'Tīr Pul': 3887,
    'Zindah Jān': 3887,
    'Āqchah': 3886,
    'Darzāb': 3886,
    'Qarqīn': 3886,
    'Shibirghān': 3886,
    'Kabul': 3902,
    'Mīr Bachah Kōṯ': 3902,
    'Paghmān': 3902,
    'Kandahār': 3890,
    'Sidqābād': 3879,
    'Khōst': 3878,
    'Āsmār': 3876,
    'Asadabad': 3876,
    'Dasht-e Archī': 3900,
    'Imām Şāḩib': 3900,
    'Khanabad': 3900,
    'Kunduz': 3900,
    'Qarāwul': 3900,
    'Mehtar Lām': 3891,
    'Baraki Barak': 3897,
    'Ḩukūmatī Azrah': 3897,
    'Pul-e ‘Alam': 3897,
    'Bāsawul': 3882,
    'Jalālābād': 3882,
    'Markaz-e Woluswalī-ye Āchīn': 3882,
    'Khāsh': 3896,
    'Mīrābād': 3896,
    'Rūdbār': 3896,
    'Zaranj': 3896,
    'Pārūn': 3880,
    'Gardez': 3894,
    'Saṟōbī': 3877,
    'Zaṟah Sharan': 3877,
    'Zarghūn Shahr': 3877,
    'Bāzārak': 3881,
    'Charikar': 3895,
    'Jabal os Saraj': 3895,
    'Aībak': 3883,
    'Chīras': 3885,
    'Larkird': 3885,
    'Qal‘ah-ye Shahr': 3885,
    'Sang-e Chārak': 3885,
    'Sar-e Pul': 3885,
    'Tagāw-Bāy': 3885,
    'Tukzār': 3885,
    'Ārt Khwājah': 3893,
    'Taloqan': 3893,
    'Tarinkot': 3898,
    'Uruzgān': 3898,
    'Qalāt': 3874,
    'Banaj': 629,
    'Bashkia Berat': 629,
    'Bashkia Kuçovë': 629,
    'Bashkia Poliçan': 629,
    'Bashkia Skrapar': 629,
    'Berat': 629,
    'Çorovodë': 629,
    'Kuçovë': 629,
    'Poliçan': 629,
    'Rrethi i Beratit': 629,
    'Rrethi i Kuçovës': 629,
    'Rrethi i Skraparit': 629,
    'Ura Vajgurore': 629,
    'Bashkia Bulqizë': 610,
    'Bashkia Klos': 610,
    'Bashkia Mat': 610,
    'Bulqizë': 610,
    'Burrel': 610,
    'Klos': 610,
    'Peshkopi': 610,
    'Rrethi i Bulqizës': 610,
    'Rrethi i Dibrës': 610,
    'Rrethi i Matit': 610,
    'Ulëz': 610,
    'Bashkia Durrës': 639,
    'Bashkia Krujë': 639,
    'Bashkia Shijak': 639,
    'Durrës': 639,
    'Durrës District': 639,
    'Fushë-Krujë': 639,
    'Krujë': 639,
    'Rrethi i Krujës': 639,
    'Shijak': 639,
    'Sukth': 639,
    'Ballsh': 631,
    'Bashkia Divjakë': 631,
    'Bashkia Fier': 631,
    'Bashkia Mallakastër': 631,
    'Bashkia Patos': 631,
    'Divjakë': 631,
    'Fier': 631,
    'Fier-Çifçi': 631,
    'Lushnjë': 631,
    'Patos': 631,
    'Patos Fshat': 631,
    'Roskovec': 631,
    'Rrethi i Mallakastrës': 631,
    'Bashkia Dropull': 621,
    'Bashkia Kelcyrë': 621,
    'Bashkia Libohovë': 621,
    'Bashkia Memaliaj': 621,
    'Bashkia Përmet': 621,
    'Bashkia Tepelenë': 621,
    'Gjinkar': 621,
    'Gjirokastër': 621,
    'Këlcyrë': 621,
    'Lazarat': 621,
    'Libohovë': 621,
    'Memaliaj': 621,
    'Përmet': 621,
    'Tepelenë': 621,
    'Bashkia Devoll': 630,
    'Bashkia Kolonjë': 630,
    'Bashkia Maliq': 630,
    'Bashkia Pustec': 630,
    'Bilisht': 630,
    'Ersekë': 630,
    'Korçë': 630,
    'Leskovik': 630,
    'Libonik': 630,
    'Maliq': 630,
    'Mborje': 630,
    'Pogradec': 630,
    'Rrethi i Devollit': 630,
    'Rrethi i Kolonjës': 630,
    'Velçan': 630,
    'Voskopojë': 630,
    'Bajram Curri': 623,
    'Krumë': 623,
    'Kukës': 623,
    'Rrethi i Hasit': 623,
    'Rrethi i Kukësit': 623,
    'Bashkia Kurbin': 609,
    'Bashkia Lezhë': 609,
    'Bashkia Mirditë': 609,
    'Kurbnesh': 609,
    'Laç': 609,
    'Lezhë': 609,
    'Mamurras': 609,
    'Milot': 609,
    'Rrëshen': 609,
    'Rrethi i Kurbinit': 609,
    'Rubik': 609,
    'Shëngjin': 609,
    'Bashkia Malësi e Madhe': 626,
    'Bashkia Pukë': 626,
    'Bashkia Vau i Dejës': 626,
    'Fushë-Arrëz': 626,
    'Koplik': 626,
    'Pukë': 626,
    'Rrethi i Malësia e Madhe': 626,
    'Rrethi i Shkodrës': 626,
    'Shkodër': 626,
    'Vau i Dejës': 626,
    'Vukatanë': 626,
    'Bashkia Kavajë': 633,
    'Bashkia Vorë': 633,
    'Kamëz': 633,
    'Kavajë': 633,
    'Krrabë': 633,
    'Rrethi i Kavajës': 633,
    'Rrethi i Tiranës': 633,
    'Rrogozhinë': 633,
    'Sinaballaj': 633,
    'Tirana': 633,
    'Vorë': 633,
    'Bashkia Finiq': 634,
    'Bashkia Himarë': 634,
    'Bashkia Konispol': 634,
    'Bashkia Selenicë': 634,
    'Bashkia Vlorë': 634,
    'Delvinë': 634,
    'Himarë': 634,
    'Konispol': 634,
    'Ksamil': 634,
    'Orikum': 634,
    'Rrethi i Delvinës': 634,
    'Sarandë': 634,
    'Selenicë': 634,
    'Vlorë': 634,
    'Adrar': 1118,
    'Aoulef': 1118,
    'Reggane': 1118,
    'Timimoun': 1118,
    'Aïn Defla': 1119,
    'El Abadia': 1119,
    'El Attaf': 1119,
    'Khemis Miliana': 1119,
    'Theniet el Had': 1119,
    'Aïn Temouchent': 1122,
    'Beni Saf': 1122,
    'El Amria': 1122,
    'El Malah': 1122,
    'Hammam Bou Hadjar': 1122,
    'Aïn Taya': 1144,
    'Algiers': 1144,
    'Bab Ezzouar': 1144,
    'Birkhadem': 1144,
    'Bordj el Kiffan': 1144,
    'Dar el Beïda': 1144,
    'Rouiba': 1144,
    'Annaba': 1103,
    'Berrahal': 1103,
    'Drean': 1103,
    'El Hadjar': 1103,
    'Aïn Touta': 1142,
    'Arris': 1142,
    'Barika': 1142,
    'Batna': 1142,
    'Boumagueur': 1142,
    'Merouana': 1142,
    'Râs el Aïoun': 1142,
    'Tazoult-Lambese': 1142,
    'Béchar': 1108,
    'Akbou': 1128,
    'Amizour': 1128,
    'Barbacha': 1128,
    'Bejaïa': 1128,
    'el hed': 1128,
    'El Kseur': 1128,
    'Feraoun': 1128,
    'Seddouk': 1128,
    'Biskra': 1114,
    'Oumache': 1114,
    'Sidi Khaled': 1114,
    'Sidi Okba': 1114,
    'Tolga': 1114,
    'Zeribet el Oued': 1114,
    'Beni Mered': 1111,
    'Blida': 1111,
    'Boû Arfa': 1111,
    'Boufarik': 1111,
    'Bougara': 1111,
    'Bouinan': 1111,
    'Chebli': 1111,
    'Chiffa': 1111,
    'Larbaâ': 1111,
    'Meftah': 1111,
    'Sidi Moussa': 1111,
    'Souma': 1111,
    'Bordj Bou Arreridj': 1116,
    'Bordj Ghdir': 1116,
    'Bordj Zemoura': 1116,
    'El Achir': 1116,
    'Mansourah': 1116,
    'Melouza': 1116,
    'Râs el Oued': 1116,
    'Aïn Bessem': 1104,
    'Bouïra': 1104,
    'Chorfa': 1104,
    'Draa el Mizan': 1104,
    'Lakhdaria': 1104,
    'Sour el Ghozlane': 1104,
    'Arbatache': 1125,
    'Beni Amrane': 1125,
    'Boudouaou': 1125,
    'Boumerdas': 1125,
    'Chabet el Ameur': 1125,
    'Dellys': 1125,
    'Khemis el Khechna': 1125,
    'Makouda': 1125,
    'Naciria': 1125,
    'Ouled Moussa': 1125,
    'Reghaïa': 1125,
    'Tadmaït': 1125,
    'Thenia': 1125,
    'Tizi Gheniff': 1125,
    'Abou el Hassan': 1105,
    'Boukadir': 1105,
    'Chlef': 1105,
    'Ech Chettia': 1105,
    'Oued Fodda': 1105,
    'Oued Sly': 1105,
    'Sidi Akkacha': 1105,
    '’Aïn Abid': 1121,
    'Aïn Smara': 1121,
    'Constantine': 1121,
    'Didouche Mourad': 1121,
    'El Khroub': 1121,
    'Hamma Bouziane': 1121,
    '’Aïn el Bell': 1098,
    'Aïn Oussera': 1098,
    'Birine': 1098,
    'Charef': 1098,
    'Dar Chioukh': 1098,
    'Djelfa': 1098,
    'El Idrissia': 1098,
    'Messaad': 1098,
    'Brezina': 1129,
    'El Abiodh Sidi Cheikh': 1129,
    'El Bayadh': 1129,
    'Debila': 1099,
    'El Oued': 1099,
    'Reguiba': 1099,
    'Robbah': 1099,
    'Ben Mehidi': 1100,
    'Besbes': 1100,
    'El Kala': 1100,
    'El Tarf': 1100,
    'Berriane': 1127,
    'Ghardaïa': 1127,
    'Metlili Chaamba': 1127,
    'Boumahra Ahmed': 1137,
    'Guelma': 1137,
    'Héliopolis': 1137,
    'Illizi': 1112,
    'Jijel': 1113,
    'Khenchela': 1126,
    'Aflou': 1138,
    'Laghouat': 1138,
    '‘Aïn el Hadjel': 1134,
    '’Aïn el Melh': 1134,
    'M’Sila': 1134,
    'Sidi Aïssa': 1134,
    'Bou Hanifia el Hamamat': 1124,
    'Mascara': 1124,
    'Oued el Abtal': 1124,
    'Sig': 1124,
    '’Aïn Boucif': 1109,
    'Berrouaghia': 1109,
    'Ksar el Boukhari': 1109,
    'Médéa': 1109,
    'Chelghoum el Aïd': 1132,
    'Mila': 1132,
    'Rouached': 1132,
    'Sidi Mérouane': 1132,
    'Telerghma': 1132,
    'Mostaganem': 1140,
    'Aïn Sefra': 1102,
    'Naama': 1102,
    '’Aïn el Turk': 1101,
    'Aïn el Bya': 1101,
    'Bir el Djir': 1101,
    'Bou Tlelis': 1101,
    'Es Senia': 1101,
    'Mers el Kebir': 1101,
    'Oran': 1101,
    'Sidi ech Chahmi': 1101,
    'Djamaa': 1139,
    'El Hadjira': 1139,
    'Hassi Messaoud': 1139,
    'Megarine': 1139,
    'Ouargla': 1139,
    'Rouissat': 1139,
    'Sidi Amrane': 1139,
    'Tebesbest': 1139,
    'Touggourt': 1139,
    'Aïn Beïda': 1136,
    'Aïn Fakroun': 1136,
    'Aïn Kercha': 1136,
    'El Aouinet': 1136,
    'Meskiana': 1136,
    'Oum el Bouaghi': 1136,
    '’Aïn Merane': 1130,
    'Ammi Moussa': 1130,
    'Djidiouia': 1130,
    'Mazouna': 1130,
    'Oued Rhiou': 1130,
    'Relizane': 1130,
    'Smala': 1130,
    'Zemoura': 1130,
    '’Aïn el Hadjar': 1123,
    'Saïda': 1123,
    'Aïn Arnat': 1141,
    'BABOR - VILLE': 1141,
    'Bougaa': 1141,
    'El Eulma': 1141,
    'Salah Bey': 1141,
    'Sétif': 1141,
    'Aïn El Berd District': 4902,
    'Balidat Ameur': 4902,
    'Belarbi': 4902,
    'Ben Badis, Sid Bel Abbés': 4902,
    'Djamaa': 4902,
    'El Bour': 4902,
    'El Hadjira': 4902,
    'Haoud El Hamra': 4902,
    'Hassi Messaoud': 4902,
    'Lamtar': 4902,
    'Marhoum': 4902,
    'Megarine': 4902,
    'Merine': 4902,
    'Mezaourou': 4902,
    'Moggar': 4902,
    'Moulay Slissen': 4902,
    'N  Goussa': 4902,
    'Ouargla': 4902,
    'Rouissat': 4902,
    'Sfissef': 4902,
    'Sidi Ali Boussidi': 4902,
    'Sidi Amrane': 4902,
    'Sidi Bel Abbès': 4902,
    'Sidi Brahim': 4902,
    'Sidi Hamadouche': 4902,
    'Sidi Slimane': 4902,
    'Sidi Yacoub': 4902,
    'Sidi Yahia': 4902,
    'Tabia, Sid Bel Abbés': 4902,
    'Taibet': 4902,
    'Tamellaht': 4902,
    'Tamerna Djedida': 4902,
    'Tebesbest': 4902,
    'Teghalimet': 4902,
    'Telagh': 4902,
    'Tenezara': 4902,
    'Tenira': 4902,
    'Tessala': 4902,
    'Touggourt': 4902,
    'Zerouala': 4902,
    'Azzaba': 1110,
    'Karkira': 1110,
    'Skikda': 1110,
    'Tamalous': 1110,
    'Sedrata': 1143,
    'Souk Ahras': 1143,
    'I-n-Salah': 1135,
    'Tamanrasset': 1135,
    'Bir el Ater': 1117,
    'Cheria': 1117,
    'Hammamet': 1117,
    'Tébessa': 1117,
    '’Aïn Deheb': 1106,
    'Djebilet Rosfa': 1106,
    'Frenda': 1106,
    'Ksar Chellala': 1106,
    'Mehdia daira de meghila': 1106,
    'Sougueur': 1106,
    'Tiaret': 1106,
    'Tindouf': 1120,
    '’Aïn Benian': 1115,
    'Baraki': 1115,
    'Bou Ismaïl': 1115,
    'Cheraga': 1115,
    'Douera': 1115,
    'El Affroun': 1115,
    'Hadjout': 1115,
    'Kolea': 1115,
    'Mouzaïa': 1115,
    'Oued el Alleug': 1115,
    'Saoula': 1115,
    'Tipasa': 1115,
    'Zeralda': 1115,
    'Lardjem': 1133,
    'Tissemsilt': 1133,
    '’Aïn el Hammam': 1131,
    'Arhribs': 1131,
    'Azazga': 1131,
    'Beni Douala': 1131,
    'Boghni': 1131,
    'Boudjima': 1131,
    'Chemini': 1131,
    'Draa Ben Khedda': 1131,
    'Freha': 1131,
    'Ighram': 1131,
    'L’Arbaa Naït Irathen': 1131,
    'Mekla': 1131,
    'Timizart': 1131,
    'Tirmitine': 1131,
    'Tizi Ouzou': 1131,
    'Tizi Rached': 1131,
    'Tizi-n-Tleta': 1131,
    'Beni Mester': 1107,
    'Bensekrane': 1107,
    'Chetouane': 1107,
    'Hennaya': 1107,
    'Mansoûra': 1107,
    'Nedroma': 1107,
    'Ouled Mimoun': 1107,
    'Remchi': 1107,
    'Sebdou': 1107,
    'Sidi Abdelli': 1107,
    'Sidi Senoussi سيدي سنوسي': 1107,
    'Tlemcen': 1107,
    'Andorra la Vella': 488,
    'Canillo': 489,
    'El Tarter': 489,
    'Encamp': 487,
    'Pas de la Casa': 487,
    'les Escaldes': 492,
    'Arinsal': 493,
    'la Massana': 493,
    'Ordino': 491,
    'Sant Julià de Lòria': 490,
    'Caxito': 221,
    'Benguela': 218,
    'Catumbela': 218,
    'Lobito': 218,
    'Camacupa': 212,
    'Catabola': 212,
    'Chissamba': 212,
    'Cuito': 212,
    'Cabinda': 228,
    'Menongue': 226,
    'Camabatela': 217,
    'N’dalatando': 217,
    'Quibala': 216,
    'Sumbe': 216,
    'Uacu Cungo': 216,
    'Ondjiva': 215,
    'Caála': 213,
    'Chela': 213,
    'Huambo': 213,
    'Longonjo': 213,
    'Caconda': 225,
    'Caluquembe': 225,
    'Chibia': 225,
    'Chicomba': 225,
    'Chipindo': 225,
    'Cuvango': 225,
    'Gambos': 225,
    'Humpata': 225,
    'Jamba': 225,
    'Lubango': 225,
    'Matala': 225,
    'Quilengues': 225,
    'Quipungo': 225,
    'Belas': 222,
    'Icolo e Bengo': 222,
    'Luanda': 222,
    'Lucapa': 223,
    'Cazaji': 220,
    'Saurimo': 220,
    'Malanje': 227,
    'Léua': 219,
    'Luau': 219,
    'Luena': 219,
    'Lumeje': 219,
    'Uíge': 224,
    'Mbanza Congo': 214,
    'N  zeto': 214,
    'Soio': 214,
    'Codrington': 3708,
    'Piggotts': 3709,
    'Potters Village': 3706,
    'Saint John’s': 3706,
    'Bolands': 3707,
    'Falmouth': 3705,
    'Liberta': 3705,
    'All Saints': 3704,
    'Parham': 3704,
    'Balvanera': 3656,
    'Barracas': 3656,
    'Belgrano': 3656,
    'Boedo': 3656,
    'Buenos Aires': 3656,
    'Colegiales': 3656,
    'Retiro': 3656,
    'Villa Lugano': 3656,
    'Villa Ortúzar': 3656,
    'Villa Santa Rita': 3656,
    'Ancasti': 3647,
    'Andalgalá': 3647,
    'Antofagasta de la Sierra': 3647,
    'Capayán': 3647,
    'Departamento de Ambato': 3647,
    'Departamento de Ancasti': 3647,
    'Departamento de Andalgalá': 3647,
    'Departamento de Antofagasta de la Sierra': 3647,
    'Departamento de Capayán': 3647,
    'Departamento de Capital': 3647,
    'Departamento de El Alto': 3647,
    'Departamento de Fray Mamerto Esquiú': 3647,
    'Departamento de La Paz': 3647,
    'Departamento de Pomán': 3647,
    'Departamento de Santa María': 3647,
    'Departamento de Santa Rosa': 3647,
    'Departamento de Tinogasta': 3647,
    'Departamento de Valle Viejo': 3647,
    'El Rodeo': 3647,
    'Fiambalá': 3647,
    'Hualfín': 3647,
    'Huillapima': 3647,
    'Icaño': 3647,
    'La Puerta de San José': 3647,
    'Londres': 3647,
    'Los Altos': 3647,
    'Los Varela': 3647,
    'Mutquín': 3647,
    'Pomán': 3647,
    'Puerta de Corral Quemado': 3647,
    'Recreo': 3647,
    'San Antonio': 3647,
    'San Fernando del Valle de Catamarca': 3647,
    'Santa María': 3647,
    'Tinogasta': 3647,
    'Aviá Terai': 3640,
    'Barranqueras': 3640,
    'Basail': 3640,
    'Campo Largo': 3640,
    'Capitán Solari': 3640,
    'Castelli': 3640,
    'Charadai': 3640,
    'Charata': 3640,
    'Chorotis': 3640,
    'Ciervo Petiso': 3640,
    'Colonia Benítez': 3640,
    'Colonia Elisa': 3640,
    'Colonias Unidas': 3640,
    'Concepción del Bermejo': 3640,
    'Coronel Du Graty': 3640,
    'Corzuela': 3640,
    'Coté-Lai': 3640,
    'Departamento de Almirante Brown': 3640,
    'Departamento de Bermejo': 3640,
    'Departamento de Comandante Fernández': 3640,
    'Departamento de Doce de Octubre': 3640,
    'Departamento de Dos de Abril': 3640,
    'Departamento de General Donovan': 3640,
    'Departamento de General Güemes': 3640,
    'Departamento de Independencia': 3640,
    'Departamento de Libertad': 3640,
    'Departamento de Maipú': 3640,
    'Departamento de Nueve de Julio': 3640,
    'Departamento de O’Higgins': 3640,
    'Departamento de Presidencia de la Plaza': 3640,
    'Departamento de Quitilipi': 3640,
    'Departamento de San Fernando': 3640,
    'Departamento de San Lorenzo': 3640,
    'Departamento de Sargento Cabral': 3640,
    'Departamento de Tapenagá': 3640,
    'Fontana': 3640,
    'Gancedo': 3640,
    'General José de San Martín': 3640,
    'General Pinedo': 3640,
    'General Vedia': 3640,
    'Hermoso Campo': 3640,
    'La Clotilde': 3640,
    'La Eduvigis': 3640,
    'La Escondida': 3640,
    'La Leonesa': 3640,
    'La Tigra': 3640,
    'La Verde': 3640,
    'Laguna Limpia': 3640,
    'Lapachito': 3640,
    'Las Breñas': 3640,
    'Las Garcitas': 3640,
    'Los Frentones': 3640,
    'Machagai': 3640,
    'Makallé': 3640,
    'Margarita Belén': 3640,
    'Napenay': 3640,
    'Pampa Almirón': 3640,
    'Pampa del Indio': 3640,
    'Pampa del Infierno': 3640,
    'Presidencia de la Plaza': 3640,
    'Presidencia Roca': 3640,
    'Presidencia Roque Sáenz Peña': 3640,
    'Puerto Bermejo': 3640,
    'Puerto Tirol': 3640,
    'Puerto Vilelas': 3640,
    'Quitilipi': 3640,
    'Resistencia': 3640,
    'Samuhú': 3640,
    'San Bernardo': 3640,
    'Santa Sylvina': 3640,
    'Taco Pozo': 3640,
    'Tres Isletas': 3640,
    'Villa Ángela': 3640,
    'Villa Berthet': 3640,
    'Alto Río Senguer': 3651,
    'Camarones': 3651,
    'Comodoro Rivadavia': 3651,
    'Departamento de Biedma': 3651,
    'Departamento de Cushamen': 3651,
    'Departamento de Escalante': 3651,
    'Departamento de Florentino Ameghino': 3651,
    'Departamento de Futaleufú': 3651,
    'Departamento de Gaimán': 3651,
    'Departamento de Gastre': 3651,
    'Departamento de Languiñeo': 3651,
    'Departamento de Mártires': 3651,
    'Departamento de Paso de Indios': 3651,
    'Departamento de Rawson': 3651,
    'Departamento de Río Senguerr': 3651,
    'Departamento de Sarmiento': 3651,
    'Departamento de Tehuelches': 3651,
    'Departamento de Telsen': 3651,
    'Dolavón': 3651,
    'El Maitén': 3651,
    'Esquel': 3651,
    'Gaimán': 3651,
    'Gastre': 3651,
    'Gobernador Costa': 3651,
    'Hoyo de Epuyén': 3651,
    'José de San Martín': 3651,
    'Lago Puelo': 3651,
    'Las Plumas': 3651,
    'Puerto Madryn': 3651,
    'Rada Tilly': 3651,
    'Rawson': 3651,
    'Río Mayo': 3651,
    'Río Pico': 3651,
    'Sarmiento': 3651,
    'Tecka': 3651,
    'Trelew': 3651,
    'Trevelin': 3651,
    'Achiras': 3642,
    'Adelia María': 3642,
    'Agua de Oro': 3642,
    'Alejandro Roca': 3642,
    'Alejo Ledesma': 3642,
    'Almafuerte': 3642,
    'Alta Gracia': 3642,
    'Altos de Chipión': 3642,
    'Arias': 3642,
    'Arroyito': 3642,
    'Arroyo Cabral': 3642,
    'Balnearia': 3642,
    'Bell Ville': 3642,
    'Berrotarán': 3642,
    'Brinkmann': 3642,
    'Buchardo': 3642,
    'Camilo Aldao': 3642,
    'Cañada de Luque': 3642,
    'Canals': 3642,
    'Capilla del Monte': 3642,
    'Carnerillo': 3642,
    'Carrilobo': 3642,
    'Cavanagh': 3642,
    'Charras': 3642,
    'Chazón': 3642,
    'Cintra': 3642,
    'Colonia La Tordilla': 3642,
    'Colonia San Bartolomé': 3642,
    'Córdoba': 3642,
    'Coronel Baigorria': 3642,
    'Coronel Moldes': 3642,
    'Corral de Bustos': 3642,
    'Corralito': 3642,
    'Cosquín': 3642,
    'Costa Sacate': 3642,
    'Cruz Alta': 3642,
    'Cruz del Eje': 3642,
    'Cuesta Blanca': 3642,
    'Dalmacio Vélez Sársfield': 3642,
    'Deán Funes': 3642,
    'Del Campillo': 3642,
    'Departamento de Calamuchita': 3642,
    'Departamento de Colón': 3642,
    'Departamento de Cruz del Eje': 3642,
    'Departamento de General Roca': 3642,
    'Departamento de General San Martín': 3642,
    'Departamento de Juárez Celman': 3642,
    'Departamento de Marcos Juárez': 3642,
    'Departamento de Minas': 3642,
    'Departamento de Presidente Roque Sáenz Peña': 3642,
    'Departamento de Río Cuarto': 3642,
    'Departamento de Río Primero': 3642,
    'Departamento de Río Seco': 3642,
    'Departamento de Río Segundo': 3642,
    'Departamento de San Alberto': 3642,
    'Departamento de San Javier': 3642,
    'Departamento de San Justo': 3642,
    'Departamento de Sobremonte': 3642,
    'Departamento de Totoral': 3642,
    'Departamento de Tulumba': 3642,
    'Departamento de Unión': 3642,
    'Despeñaderos': 3642,
    'Devoto': 3642,
    'El Arañado': 3642,
    'El Tío': 3642,
    'Elena': 3642,
    'Embalse': 3642,
    'Etruria': 3642,
    'General Baldissera': 3642,
    'General Cabrera': 3642,
    'General Levalle': 3642,
    'General Roca': 3642,
    'Guatimozín': 3642,
    'Hernando': 3642,
    'Huanchillas': 3642,
    'Huerta Grande': 3642,
    'Huinca Renancó': 3642,
    'Idiazábal': 3642,
    'Inriville': 3642,
    'Isla Verde': 3642,
    'Italó': 3642,
    'James Craik': 3642,
    'Jesús María': 3642,
    'Justiniano Posse': 3642,
    'La Calera': 3642,
    'La Carlota': 3642,
    'La Cesira': 3642,
    'La Cumbre': 3642,
    'La Falda': 3642,
    'La Francia': 3642,
    'La Granja': 3642,
    'La Para': 3642,
    'La Playosa': 3642,
    'Laborde': 3642,
    'Laboulaye': 3642,
    'Laguna Larga': 3642,
    'Las Acequias': 3642,
    'Las Higueras': 3642,
    'Las Junturas': 3642,
    'Las Perdices': 3642,
    'Las Varas': 3642,
    'Las Varillas': 3642,
    'Leones': 3642,
    'Los Cocos': 3642,
    'Los Cóndores': 3642,
    'Los Surgentes': 3642,
    'Malagueño': 3642,
    'Malvinas Argentinas': 3642,
    'Marcos Juárez': 3642,
    'Marull': 3642,
    'Mattaldi': 3642,
    'Mendiolaza': 3642,
    'Mina Clavero': 3642,
    'Miramar': 3642,
    'Monte Buey': 3642,
    'Monte Cristo': 3642,
    'Monte Maíz': 3642,
    'Morrison': 3642,
    'Morteros': 3642,
    'Noetinger': 3642,
    'Obispo Trejo': 3642,
    'Oliva': 3642,
    'Oncativo': 3642,
    'Ordóñez': 3642,
    'Pascanas': 3642,
    'Pasco': 3642,
    'Pilar': 3642,
    'Piquillín': 3642,
    'Porteña': 3642,
    'Pozo del Molle': 3642,
    'Quilino': 3642,
    'Río Ceballos': 3642,
    'Río Cuarto': 3642,
    'Río Segundo': 3642,
    'Río Tercero': 3642,
    'Sacanta': 3642,
    'Saldán': 3642,
    'Salsacate': 3642,
    'Salsipuedes': 3642,
    'Sampacho': 3642,
    'San Agustín': 3642,
    'San Antonio de Litín': 3642,
    'San Basilio': 3642,
    'San Carlos': 3642,
    'San Francisco': 3642,
    'San Francisco del Chañar': 3642,
    'San José de la Dormida': 3642,
    'Santa Eufemia': 3642,
    'Santa Magdalena': 3642,
    'Santa Rosa de Calamuchita': 3642,
    'Santa Rosa de Río Primero': 3642,
    'Santiago Temple': 3642,
    'Saturnino M. Laspiur': 3642,
    'Sebastián Elcano': 3642,
    'Serrano': 3642,
    'Serrezuela': 3642,
    'Tancacha': 3642,
    'Ticino': 3642,
    'Tío Pujio': 3642,
    'Toledo': 3642,
    'Ucacha': 3642,
    'Unquillo': 3642,
    'Valle Hermoso': 3642,
    'Viamonte': 3642,
    'Vicuña Mackenna': 3642,
    'Villa Allende': 3642,
    'Villa Ascasubi': 3642,
    'Villa Berna': 3642,
    'Villa Carlos Paz': 3642,
    'Villa Concepción del Tío': 3642,
    'Villa Cura Brochero': 3642,
    'Villa de Soto': 3642,
    'Villa del Dique': 3642,
    'Villa del Rosario': 3642,
    'Villa del Totoral': 3642,
    'Villa Dolores': 3642,
    'Villa General Belgrano': 3642,
    'Villa Giardino': 3642,
    'Villa Huidobro': 3642,
    'Villa Las Rosas': 3642,
    'Villa María': 3642,
    'Villa Nueva': 3642,
    'Villa Reducción': 3642,
    'Villa Rumipal': 3642,
    'Villa Tulumba': 3642,
    'Villa Valeria': 3642,
    'Wenceslao Escalante': 3642,
    'Alvear': 3638,
    'Berón de Astrada': 3638,
    'Bonpland': 3638,
    'Chavarría': 3638,
    'Concepción': 3638,
    'Corrientes': 3638,
    'Cruz de los Milagros': 3638,
    'Curuzú Cuatiá': 3638,
    'Departamento de Bella Vista': 3638,
    'Departamento de Berón de Astrada': 3638,
    'Departamento de Capital': 3638,
    'Departamento de Concepción': 3638,
    'Departamento de Curuzú Cuatiá': 3638,
    'Departamento de Empedrado': 3638,
    'Departamento de Esquina': 3638,
    'Departamento de General Alvear': 3638,
    'Departamento de General Paz': 3638,
    'Departamento de Goya': 3638,
    'Departamento de Itatí': 3638,
    'Departamento de Ituzaingó': 3638,
    'Departamento de Lavalle': 3638,
    'Departamento de Mburucuyá': 3638,
    'Departamento de Mercedes': 3638,
    'Departamento de Monte Caseros': 3638,
    'Departamento de Paso de los Libres': 3638,
    'Departamento de Saladas': 3638,
    'Departamento de San Cosme': 3638,
    'Departamento de San Luis del Palmar': 3638,
    'Departamento de San Martín': 3638,
    'Departamento de San Miguel': 3638,
    'Departamento de San Roque': 3638,
    'Departamento de Santo Tomé': 3638,
    'Departamento de Sauce': 3638,
    'Empedrado': 3638,
    'Esquina': 3638,
    'Felipe Yofré': 3638,
    'Garruchos': 3638,
    'Gobernador Juan E. Martínez': 3638,
    'Gobernador Virasora': 3638,
    'Goya': 3638,
    'Herlitzka': 3638,
    'Itá Ibaté': 3638,
    'Itatí': 3638,
    'Ituzaingó': 3638,
    'Juan Pujol': 3638,
    'La Cruz': 3638,
    'Libertad': 3638,
    'Lomas de Vallejos': 3638,
    'Loreto': 3638,
    'Mariano I. Loza': 3638,
    'Mburucuyá': 3638,
    'Mercedes': 3638,
    'Mocoretá': 3638,
    'Monte Caseros': 3638,
    'Nuestra Señora del Rosario de Caa Catí': 3638,
    'Nueve de Julio': 3638,
    'Palmar Grande': 3638,
    'Paso de la Patria': 3638,
    'Paso de los Libres': 3638,
    'Pedro R. Fernández': 3638,
    'Perugorría': 3638,
    'Pueblo Libertador': 3638,
    'Riachuelo': 3638,
    'Saladas': 3638,
    'San Carlos': 3638,
    'San Cosme': 3638,
    'San Lorenzo': 3638,
    'San Luis del Palmar': 3638,
    'San Miguel': 3638,
    'Santa Lucía': 3638,
    'Santa Rosa': 3638,
    'Santo Tomé': 3638,
    'Yapeyú': 3638,
    'Yataity Calle': 3638,
    'Aldea San Antonio': 3654,
    'Aranguren': 3654,
    'Bovril': 3654,
    'Caseros': 3654,
    'Ceibas': 3654,
    'Chajarí': 3654,
    'Colón': 3654,
    'Colonia Elía': 3654,
    'Concepción del Uruguay': 3654,
    'Concordia': 3654,
    'Conscripto Bernardi': 3654,
    'Crespo': 3654,
    'Departamento de Gualeguaychú': 3654,
    'Departamento de Paraná': 3654,
    'Diamante': 3654,
    'Domínguez': 3654,
    'Federación': 3654,
    'Federal': 3654,
    'General Campos': 3654,
    'General Galarza': 3654,
    'General Ramírez': 3654,
    'Gobernador Mansilla': 3654,
    'Gualeguay': 3654,
    'Gualeguaychú': 3654,
    'Hasenkamp': 3654,
    'Hernández': 3654,
    'Herrera': 3654,
    'La Criolla': 3654,
    'La Paz': 3654,
    'Larroque': 3654,
    'Los Charrúas': 3654,
    'Los Conquistadores': 3654,
    'Lucas González': 3654,
    'Maciá': 3654,
    'Nogoyá': 3654,
    'Oro Verde': 3654,
    'Paraná': 3654,
    'Piedras Blancas': 3654,
    'Pronunciamiento': 3654,
    'Puerto Ibicuy': 3654,
    'Puerto Yeruá': 3654,
    'Rosario del Tala': 3654,
    'San Benito': 3654,
    'San Gustavo': 3654,
    'San José de Feliciano': 3654,
    'San Justo': 3654,
    'San Salvador': 3654,
    'Santa Ana': 3654,
    'Santa Anita': 3654,
    'Santa Elena': 3654,
    'Sauce de Luna': 3654,
    'Seguí': 3654,
    'Tabossi': 3654,
    'Ubajay': 3654,
    'Urdinarrain': 3654,
    'Viale': 3654,
    'Victoria': 3654,
    'Villa del Rosario': 3654,
    'Villa Elisa': 3654,
    'Villa Hernandarias': 3654,
    'Villa Mantero': 3654,
    'Villa María Grande': 3654,
    'Villa Paranacito': 3654,
    'Villa Urquiza': 3654,
    'Villaguay': 3654,
    'Clorinda': 3652,
    'Comandante Fontana': 3652,
    'Departamento de Pilcomayo': 3652,
    'El Colorado': 3652,
    'Estanislao del Campo': 3652,
    'Formosa': 3652,
    'General Enrique Mosconi': 3652,
    'Herradura': 3652,
    'Ibarreta': 3652,
    'Ingeniero Guillermo N. Juárez': 3652,
    'Laguna Naick-Neck': 3652,
    'Laguna Yema': 3652,
    'Las Lomitas': 3652,
    'Palo Santo': 3652,
    'Pirané': 3652,
    'Pozo del Tigre': 3652,
    'Riacho Eh-Eh': 3652,
    'San Francisco de Laishí': 3652,
    'Villa Escolar': 3652,
    'Villa General Guemes': 3652,
    'Abra Pampa': 3645,
    'Caimancito': 3645,
    'Calilegua': 3645,
    'Departamento de Cochinoca': 3645,
    'Departamento de Rinconada': 3645,
    'Departamento de Tumbaya': 3645,
    'El Aguilar': 3645,
    'Fraile Pintado': 3645,
    'Humahuaca': 3645,
    'Ingenio La Esperanza': 3645,
    'La Mendieta': 3645,
    'La Quiaca': 3645,
    'Libertador General San Martín': 3645,
    'Maimará': 3645,
    'Palma Sola': 3645,
    'Palpalá': 3645,
    'San Pedro de Jujuy': 3645,
    'San Salvador de Jujuy': 3645,
    'Santa Clara': 3645,
    'Tilcara': 3645,
    'Yuto': 3645,
    'Alpachiri': 3655,
    'Alta Italia': 3655,
    'Anguil': 3655,
    'Arata': 3655,
    'Bernardo Larroudé': 3655,
    'Bernasconi': 3655,
    'Caleufú': 3655,
    'Catriló': 3655,
    'Colonia Barón': 3655,
    'Departamento de Caleu-Caleu': 3655,
    'Departamento de Toay': 3655,
    'Doblas': 3655,
    'Eduardo Castex': 3655,
    'Embajador Martini': 3655,
    'General Acha': 3655,
    'General Manuel J. Campos': 3655,
    'General Pico': 3655,
    'General San Martín': 3655,
    'Guatraché': 3655,
    'Ingeniero Luiggi': 3655,
    'Intendente Alvear': 3655,
    'Jacinto Arauz': 3655,
    'La Adela': 3655,
    'La Maruja': 3655,
    'Lonquimay': 3655,
    'Macachín': 3655,
    'Miguel Riglos': 3655,
    'Parera': 3655,
    'Quemú Quemú': 3655,
    'Rancul': 3655,
    'Realicó': 3655,
    'Santa Isabel': 3655,
    'Santa Rosa': 3655,
    'Telén': 3655,
    'Trenel': 3655,
    'Uriburu': 3655,
    'Veinticinco de Mayo': 3655,
    'Victorica': 3655,
    'Winifreda': 3655,
    'Arauco': 3653,
    'Castro Barros': 3653,
    'Chamical': 3653,
    'Chilecito': 3653,
    'Departamento de Arauco': 3653,
    'Departamento de General Lamadrid': 3653,
    'Departamento de Independencia': 3653,
    'La Rioja': 3653,
    'Villa Bustos': 3653,
    'Vinchina': 3653,
    'Departamento de Capital': 3646,
    'Departamento de General Alvear': 3646,
    'Departamento de Godoy Cruz': 3646,
    'Departamento de Guaymallén': 3646,
    'Departamento de La Paz': 3646,
    'Departamento de Las Heras': 3646,
    'Departamento de Lavalle': 3646,
    'Departamento de Luján': 3646,
    'Departamento de Maipú': 3646,
    'Departamento de Malargüe': 3646,
    'Departamento de Rivadavia': 3646,
    'Departamento de San Carlos': 3646,
    'Departamento de San Martín': 3646,
    'Departamento de San Rafael': 3646,
    'Departamento de Santa Rosa': 3646,
    'Departamento de Tunuyán': 3646,
    'Departamento de Tupungato': 3646,
    'Godoy Cruz': 3646,
    'Las Heras': 3646,
    'Mendoza': 3646,
    'San Martín': 3646,
    'San Rafael': 3646,
    'Alba Posse': 3644,
    'Almafuerte': 3644,
    'Aristóbulo del Valle': 3644,
    'Arroyo del Medio': 3644,
    'Azara': 3644,
    'Bernardo de Irigoyen': 3644,
    'Bonpland': 3644,
    'Campo Grande': 3644,
    'Campo Ramón': 3644,
    'Campo Viera': 3644,
    'Candelaria': 3644,
    'Capioví': 3644,
    'Caraguatay': 3644,
    'Cerro Azul': 3644,
    'Cerro Corá': 3644,
    'Colonia Aurora': 3644,
    'Concepción de la Sierra': 3644,
    'Departamento de Apóstoles': 3644,
    'Departamento de Cainguás': 3644,
    'Departamento de Candelaria': 3644,
    'Departamento de Capital': 3644,
    'Departamento de Concepción de la Sierra': 3644,
    'Departamento de Eldorado': 3644,
    'Departamento de General Manuel Belgrano': 3644,
    'Departamento de Guaraní': 3644,
    'Departamento de Iguazú': 3644,
    'Departamento de Leandro N. Alem': 3644,
    'Departamento de Libertador General San Martín': 3644,
    'Departamento de Montecarlo': 3644,
    'Departamento de Oberá': 3644,
    'Departamento de San Ignacio': 3644,
    'Departamento de San Javier': 3644,
    'Departamento de San Pedro': 3644,
    'Departamento de Veinticinco de Mayo': 3644,
    'Dos Arroyos': 3644,
    'Dos de Mayo': 3644,
    'El Alcázar': 3644,
    'El Soberbio': 3644,
    'Florentino Ameghino': 3644,
    'Garuhapé': 3644,
    'Garupá': 3644,
    'General Alvear': 3644,
    'Gobernador Roca': 3644,
    'Guaraní': 3644,
    'Jardín América': 3644,
    'Loreto': 3644,
    'Los Helechos': 3644,
    'Mártires': 3644,
    'Mojón Grande': 3644,
    'Montecarlo': 3644,
    'Oberá': 3644,
    'Panambí': 3644,
    'Picada Gobernador López': 3644,
    'Posadas': 3644,
    'Puerto Eldorado': 3644,
    'Puerto Esperanza': 3644,
    'Puerto Iguazú': 3644,
    'Puerto Leoni': 3644,
    'Puerto Libertad': 3644,
    'Puerto Piray': 3644,
    'Puerto Rico': 3644,
    'Ruiz de Montoya': 3644,
    'San José': 3644,
    'San Pedro': 3644,
    'San Vicente': 3644,
    'Santa María': 3644,
    'Santo Pipó': 3644,
    'Tres Capones': 3644,
    'Veinticinco de Mayo': 3644,
    'Wanda': 3644,
    'Aluminé': 3648,
    'Andacollo': 3648,
    'Añelo': 3648,
    'Barrancas': 3648,
    'Buta Ranquil': 3648,
    'Centenario': 3648,
    'Chos Malal': 3648,
    'Cutral-Có': 3648,
    'Departamento de Aluminé': 3648,
    'Departamento de Añelo': 3648,
    'Departamento de Catán-Lil': 3648,
    'Departamento de Chos-Malal': 3648,
    'Departamento de Collón-Curá': 3648,
    'Departamento de Confluencia': 3648,
    'Departamento de Lácar': 3648,
    'Departamento de Minas': 3648,
    'Departamento de Zapala': 3648,
    'El Huecú': 3648,
    'Junín de los Andes': 3648,
    'Las Coloradas': 3648,
    'Las Lajas': 3648,
    'Las Ovejas': 3648,
    'Loncopué': 3648,
    'Mariano Moreno': 3648,
    'Neuquén': 3648,
    'Picún Leufú': 3648,
    'Piedra del Águila': 3648,
    'Plaza Huincul': 3648,
    'Plottier': 3648,
    'San Martín de los Andes': 3648,
    'Senillosa': 3648,
    'Villa La Angostura': 3648,
    'Vista Alegre': 3648,
    'Zapala': 3648,
    'Allen': 3639,
    'Catriel': 3639,
    'Cervantes': 3639,
    'Chichinales': 3639,
    'Chimpay': 3639,
    'Choele Choel': 3639,
    'Cinco Saltos': 3639,
    'Cipolletti': 3639,
    'Comallo': 3639,
    'Contraalmirante Cordero': 3639,
    'Coronel Belisle': 3639,
    'Darwin': 3639,
    'Departamento de Avellaneda': 3639,
    'Departamento de Veinticinco de Mayo': 3639,
    'El Bolsón': 3639,
    'El Cuy': 3639,
    'Fray Luis Beltrán': 3639,
    'General Conesa': 3639,
    'General Enrique Godoy': 3639,
    'General Fernández Oro': 3639,
    'General Roca': 3639,
    'Ingeniero Jacobacci': 3639,
    'Ingeniero Luis A. Huergo': 3639,
    'Lamarque': 3639,
    'Los Menucos': 3639,
    'Mainque': 3639,
    'Maquinchao': 3639,
    'Ñorquinco': 3639,
    'Pilcaniyeu': 3639,
    'Río Colorado': 3639,
    'San Antonio Oeste': 3639,
    'San Carlos de Bariloche': 3639,
    'Sierra Colorada': 3639,
    'Sierra Grande': 3639,
    'Valcheta': 3639,
    'Viedma': 3639,
    'Villa Regina': 3639,
    'Apolinario Saravia': 3643,
    'Cachí': 3643,
    'Cafayate': 3643,
    'Campo Quijano': 3643,
    'Chicoana': 3643,
    'Departamento Capital': 3643,
    'Departamento de Anta': 3643,
    'Departamento de Cerrillos': 3643,
    'Departamento de Chicoana': 3643,
    'Departamento de General Güemes': 3643,
    'Departamento de Guachipas': 3643,
    'Departamento de Iruya': 3643,
    'Departamento de La Poma': 3643,
    'Departamento de La Viña': 3643,
    'Departamento de Los Andes': 3643,
    'Departamento de Metán': 3643,
    'Departamento de Rivadavia': 3643,
    'Departamento de Rosario de la Frontera': 3643,
    'Departamento de Rosario de Lerma': 3643,
    'Departamento de San Carlos': 3643,
    'El Carril': 3643,
    'El Galpón': 3643,
    'El Quebrachal': 3643,
    'Embarcación': 3643,
    'General Enrique Mosconi': 3643,
    'Joaquín V. González': 3643,
    'La Caldera': 3643,
    'Las Lajitas': 3643,
    'Salta': 3643,
    'San Antonio de los Cobres': 3643,
    'San Ramón de la Nueva Orán': 3643,
    'Santa Rosa de Tastil': 3643,
    'Tartagal': 3643,
    'Albardón': 3634,
    'Calingasta': 3634,
    'Caucete': 3634,
    'Chimbas': 3634,
    'Departamento de Albardón': 3634,
    'Departamento de Angaco': 3634,
    'Departamento de Calingasta': 3634,
    'Departamento de Capital': 3634,
    'Departamento de Caucete': 3634,
    'Departamento de Chimbas': 3634,
    'Departamento de Iglesia': 3634,
    'Departamento de Jáchal': 3634,
    'Departamento de Nueve de Julio': 3634,
    'Departamento de Rawson': 3634,
    'Departamento de Rivadavia': 3634,
    'Departamento de San Martín': 3634,
    'Departamento de Santa Lucía': 3634,
    'Departamento de Sarmiento': 3634,
    'Departamento de Ullúm': 3634,
    'Departamento de Zonda': 3634,
    'Nueve de Julio': 3634,
    'Pocito': 3634,
    'San Agustín de Valle Fértil': 3634,
    'San José de Jáchal': 3634,
    'San Juan': 3634,
    'San Martín': 3634,
    'Santa Lucía': 3634,
    'Villa Basilio Nievas': 3634,
    'Villa Paula de Sarmiento': 3634,
    'Buena Esperanza': 3636,
    'Candelaria': 3636,
    'Concarán': 3636,
    'Juan Martín de Pueyrredón': 3636,
    'Justo Daract': 3636,
    'La Punta': 3636,
    'La Toma': 3636,
    'Luján': 3636,
    'Merlo': 3636,
    'Naschel': 3636,
    'San Francisco del Monte de Oro': 3636,
    'San Luis': 3636,
    'Santa Rosa del Conlara': 3636,
    'Tilisarao': 3636,
    'Unión': 3636,
    'Villa General Roca': 3636,
    'Villa Mercedes': 3636,
    '28 de Noviembre': 3649,
    'Caleta Olivia': 3649,
    'Comandante Luis Piedra Buena': 3649,
    'Departamento de Deseado': 3649,
    'Departamento de Güer Aike': 3649,
    'Departamento de Lago Argentino': 3649,
    'Departamento de Magallanes': 3649,
    'Departamento de Río Chico': 3649,
    'El Calafate': 3649,
    'Gobernador Gregores': 3649,
    'Las Heras': 3649,
    'Los Antiguos': 3649,
    'Perito Moreno': 3649,
    'Pico Truncado': 3649,
    'Puerto Deseado': 3649,
    'Puerto Santa Cruz': 3649,
    'Río Gallegos': 3649,
    'Río Turbio': 3649,
    'San Julián': 3649,
    'Yacimiento Río Turbio': 3649,
    'Armstrong': 3641,
    'Arroyo Seco': 3641,
    'Arrufó': 3641,
    'Avellaneda': 3641,
    'Bella Italia': 3641,
    'Calchaquí': 3641,
    'Cañada de Gómez': 3641,
    'Capitán Bermúdez': 3641,
    'Carcarañá': 3641,
    'Casilda': 3641,
    'Ceres': 3641,
    'Chañar Ladeado': 3641,
    'Coronda': 3641,
    'Departamento de Belgrano': 3641,
    'Departamento de Caseros': 3641,
    'Departamento de Castellanos': 3641,
    'Departamento de Constitución': 3641,
    'Departamento de La Capital': 3641,
    'Departamento de Nueve de Julio': 3641,
    'Departamento de San Cristóbal': 3641,
    'Departamento de San Javier': 3641,
    'Departamento de San Justo': 3641,
    'Departamento de San Lorenzo': 3641,
    'Departamento de San Martín': 3641,
    'Departamento de Vera': 3641,
    'El Trébol': 3641,
    'Esperanza': 3641,
    'Firmat': 3641,
    'Fray Luis A. Beltrán': 3641,
    'Funes': 3641,
    'Gálvez': 3641,
    'Gato Colorado': 3641,
    'Gobernador Gálvez': 3641,
    'Granadero Baigorria': 3641,
    'Helvecia': 3641,
    'Hersilia': 3641,
    'Iriondo Department': 3641,
    'Laguna Paiva': 3641,
    'Las Parejas': 3641,
    'Las Rosas': 3641,
    'Las Toscas': 3641,
    'Los Laureles': 3641,
    'Malabrigo': 3641,
    'Melincué': 3641,
    'Pérez': 3641,
    'Rafaela': 3641,
    'Reconquista': 3641,
    'Recreo': 3641,
    'Roldán': 3641,
    'Rosario': 3641,
    'Rufino': 3641,
    'San Carlos Centro': 3641,
    'San Cristóbal': 3641,
    'San Javier': 3641,
    'San Jorge': 3641,
    'San Justo': 3641,
    'Santa Fe': 3641,
    'Santo Tomé': 3641,
    'Sastre': 3641,
    'Sunchales': 3641,
    'Tacuarendí': 3641,
    'Tostado': 3641,
    'Totoras': 3641,
    'Venado Tuerto': 3641,
    'Vera': 3641,
    'Villa Cañás': 3641,
    'Villa Constitución': 3641,
    'Villa Mugueta': 3641,
    'Villa Ocampo': 3641,
    'Villa Trinidad': 3641,
    'Añatuya': 3635,
    'Beltrán': 3635,
    'Campo Gallo': 3635,
    'Clodomira': 3635,
    'Colonia Dora': 3635,
    'Departamento de Aguirre': 3635,
    'Departamento de Banda': 3635,
    'Departamento de Choya': 3635,
    'Departamento de Guasayán': 3635,
    'Departamento de Loreto': 3635,
    'Departamento de Moreno': 3635,
    'Departamento de Río Hondo': 3635,
    'Departamento de Robles': 3635,
    'Departamento de San Martín': 3635,
    'Departamento de Sarmiento': 3635,
    'El Hoyo': 3635,
    'La Banda': 3635,
    'Los Juríes': 3635,
    'Los Telares': 3635,
    'Pampa de los Guanacos': 3635,
    'Quimilí': 3635,
    'San Pedro': 3635,
    'Santiago del Estero': 3635,
    'Sumampa': 3635,
    'Suncho Corral': 3635,
    'Termas de Río Hondo': 3635,
    'Tintina': 3635,
    'Villa Atamisqui': 3635,
    'Villa General Mitre': 3635,
    'Villa Ojo de Agua': 3635,
    'Río Grande': 3650,
    'Tolhuin': 3650,
    'Ushuaia': 3650,
    'Aguilares': 3637,
    'Alderetes': 3637,
    'Bella Vista': 3637,
    'Burruyacú': 3637,
    'Departamento de Burruyacú': 3637,
    'Departamento de Capital': 3637,
    'Departamento de Cruz Alta': 3637,
    'Departamento de Famaillá': 3637,
    'Departamento de Graneros': 3637,
    'Departamento de La Cocha': 3637,
    'Departamento de Lules': 3637,
    'Departamento de Monteros': 3637,
    'Departamento de Río Chico': 3637,
    'Departamento de Simoca': 3637,
    'Departamento de Trancas': 3637,
    'Departamento de Yerba Buena': 3637,
    'Famaillá': 3637,
    'Graneros': 3637,
    'La Cocha': 3637,
    'Monteros': 3637,
    'San Miguel de Tucumán': 3637,
    'Simoca': 3637,
    'Tafí del Valle': 3637,
    'Tafí Viejo': 3637,
    'Trancas': 3637,
    'Yerba Buena': 3637,
    'Agarakavan': 2023,
    'Aparan': 2023,
    'Aragats': 2023,
    'Arteni': 2023,
    'Ashnak': 2023,
    'Ashtarak': 2023,
    'Byurakan': 2023,
    'Hnaberd': 2023,
    'Karbi': 2023,
    'Kasakh': 2023,
    'Kosh': 2023,
    'Nor Yerznka': 2023,
    'Oshakan': 2023,
    'Sasunik': 2023,
    'Shenavan': 2023,
    'T’alin': 2023,
    'Tsaghkahovit': 2023,
    'Ushi': 2023,
    'Voskevaz': 2023,
    'Zovuni': 2023,
    'Abovyan': 2024,
    'Aralez': 2024,
    'Ararat': 2024,
    'Arevabuyr': 2024,
    'Arevshat': 2024,
    'Armash': 2024,
    'Artashat': 2024,
    'Avshar': 2024,
    'Aygavan': 2024,
    'Aygepat': 2024,
    'Aygestan': 2024,
    'Aygezard': 2024,
    'Bardzrashen': 2024,
    'Berk’anush': 2024,
    'Burastan': 2024,
    'Byuravan': 2024,
    'Dalar': 2024,
    'Darakert': 2024,
    'Dashtavan': 2024,
    'Dimitrov': 2024,
    'Dvin': 2024,
    'Getazat': 2024,
    'Ghukasavan': 2024,
    'Goravan': 2024,
    'Hayanist': 2024,
    'Hovtashat': 2024,
    'Hovtashen': 2024,
    'Jrahovit': 2024,
    'Lusarrat': 2024,
    'Marmarashen': 2024,
    'Masis': 2024,
    'Mrganush': 2024,
    'Mrgavan': 2024,
    'Mrgavet': 2024,
    'Nizami': 2024,
    'Norabats’': 2024,
    'Noramarg': 2024,
    'Norashen': 2024,
    'Noyakert': 2024,
    'Nshavan': 2024,
    'Sayat’-Nova': 2024,
    'Shahumyan': 2024,
    'Sis': 2024,
    'Sisavan': 2024,
    'Surenavan': 2024,
    'Vedi': 2024,
    'Verin Artashat': 2024,
    'Verin Dvin': 2024,
    'Vosketap’': 2024,
    'Vostan': 2024,
    'Yeghegnavan': 2024,
    'Zangakatun': 2024,
    'Zorak': 2024,
    'Aghavnatun': 2026,
    'Aknalich': 2026,
    'Aknashen': 2026,
    'Alashkert': 2026,
    'Apaga': 2026,
    'Arak’s': 2026,
    'Arazap’': 2026,
    'Arbat’': 2026,
    'Arevashat': 2026,
    'Arevik': 2026,
    'Argavand': 2026,
    'Armavir': 2026,
    'Arshaluys': 2026,
    'Artimet': 2026,
    'Aygek': 2026,
    'Aygeshat': 2026,
    'Baghramyan': 2026,
    'Bambakashat': 2026,
    'Dalarik': 2026,
    'Doghs': 2026,
    'Gay': 2026,
    'Geghakert': 2026,
    'Geghanist': 2026,
    'Getashen': 2026,
    'Gmbet’': 2026,
    'Griboyedov': 2026,
    'Haykashen': 2026,
    'Hovtamej': 2026,
    'Janfida': 2026,
    'Khoronk’': 2026,
    'Lenughi': 2026,
    'Lukashin': 2026,
    'Margara': 2026,
    'Mayisyan': 2026,
    'Merdzavan': 2026,
    'Metsamor': 2026,
    'Mrgashat': 2026,
    'Musalerr': 2026,
    'Myasnikyan': 2026,
    'Nalbandyan': 2026,
    'Nor Armavir': 2026,
    'Norakert': 2026,
    'P’shatavan': 2026,
    'Ptghunk’': 2026,
    'Sardarapat': 2026,
    'Shenavan': 2026,
    'Tandzut': 2026,
    'Taronik': 2026,
    'Tsaghkunk’': 2026,
    'Tsiatsan': 2026,
    'Vagharshapat': 2026,
    'Voskehat': 2026,
    'Yeghegnut': 2026,
    'Yeraskhahun': 2026,
    'Akunk’': 2028,
    'Astghadzor': 2028,
    'Chambarak': 2028,
    'Ddmashen': 2028,
    'Drakhtik': 2028,
    'Dzoragyugh': 2028,
    'Gagarin': 2028,
    'Gandzak': 2028,
    'Gavarr': 2028,
    'Geghamasar': 2028,
    'Geghamavan': 2028,
    'Karanlukh': 2028,
    'Karchaghbyur': 2028,
    'Lanjaghbyur': 2028,
    'Lchap’': 2028,
    'Lchashen': 2028,
    'Lichk’': 2028,
    'Madina': 2028,
    'Martuni': 2028,
    'Mets Masrik': 2028,
    'Nerk’in Getashen': 2028,
    'Noratus': 2028,
    'Sarukhan': 2028,
    'Sevan': 2028,
    'Tsovagyugh': 2028,
    'Tsovak': 2028,
    'Tsovasar': 2028,
    'Tsovazard': 2028,
    'Tsovinar': 2028,
    'Vaghashen': 2028,
    'Vahan': 2028,
    'Vardenik': 2028,
    'Vardenis': 2028,
    'Varser': 2028,
    'Verin Getashen': 2028,
    'Yeranos': 2028,
    'Abovyan': 2033,
    'Aghavnadzor': 2033,
    'Akunk’': 2033,
    'Aramus': 2033,
    'Argel': 2033,
    'Arzakan': 2033,
    'Arzni': 2033,
    'Balahovit': 2033,
    'Bjni': 2033,
    'Buzhakan': 2033,
    'Byureghavan': 2033,
    'Dzoraghbyur': 2033,
    'Fantan': 2033,
    'Garrni': 2033,
    'Goght’': 2033,
    'Hrazdan': 2033,
    'Kaputan': 2033,
    'Kotayk’': 2033,
    'Lerrnanist': 2033,
    'Mayakovski': 2033,
    'Meghradzor': 2033,
    'Mrgashen': 2033,
    'Nor Geghi': 2033,
    'Nor Gyugh': 2033,
    'Prroshyan': 2033,
    'Ptghni': 2033,
    'Solak': 2033,
    'Tsaghkadzor': 2033,
    'Yeghvard': 2033,
    'Zarr': 2033,
    'Zoravan': 2033,
    'Zovaber': 2033,
    'Agarak': 2029,
    'Akht’ala': 2029,
    'Alaverdi': 2029,
    'Arevashogh': 2029,
    'Bazum': 2029,
    'Chochkan': 2029,
    'Darpas': 2029,
    'Dsegh': 2029,
    'Fioletovo': 2029,
    'Gogaran': 2029,
    'Gugark’': 2029,
    'Gyulagarak': 2029,
    'Jrashen': 2029,
    'Lerrnants’k’': 2029,
    'Lerrnapat': 2029,
    'Lerrnavan': 2029,
    'Lorut': 2029,
    'Margahovit': 2029,
    'Mets Parni': 2029,
    'Metsavan': 2029,
    'Norashen': 2029,
    'Odzun': 2029,
    'Sarahart’': 2029,
    'Saramej': 2029,
    'Shahumyan': 2029,
    'Shirakamut': 2029,
    'Shnogh': 2029,
    'Spitak': 2029,
    'Step’anavan': 2029,
    'Tashir': 2029,
    'Tsaghkaber': 2029,
    'Urrut': 2029,
    'Vahagni': 2029,
    'Vanadzor': 2029,
    'Vardablur': 2029,
    'Yeghegnut': 2029,
    'Akhuryan': 2031,
    'Amasia': 2031,
    'Anushavan': 2031,
    'Arevik': 2031,
    'Arevshat': 2031,
    'Arrap’i': 2031,
    'Azatan': 2031,
    'Basen': 2031,
    'Dzit’hank’ov': 2031,
    'Gyumri': 2031,
    'Haykavan': 2031,
    'Horrom': 2031,
    'Kamo': 2031,
    'Lerrnakert': 2031,
    'Maralik': 2031,
    'Marmashen': 2031,
    'Mayisyan': 2031,
    'Meghrashen': 2031,
    'Mets Mant’ash': 2031,
    'P’ok’r Mant’ash': 2031,
    'Pemzashen': 2031,
    'Saratak': 2031,
    'Shirak': 2031,
    'Spandaryan': 2031,
    'Voskehask': 2031,
    'Yerazgavors': 2031,
    'Agarak': 2027,
    'Akner': 2027,
    'Angeghakot’': 2027,
    'Brrnakot’': 2027,
    'Dzorastan': 2027,
    'Goris': 2027,
    'Hats’avan': 2027,
    'Kapan': 2027,
    'Khndzoresk': 2027,
    'Meghri': 2027,
    'Shaghat': 2027,
    'Shinuhayr': 2027,
    'Tegh': 2027,
    'Verishen': 2027,
    'Archis': 2032,
    'Artsvaberd': 2032,
    'Aygehovit': 2032,
    'Azatamut': 2032,
    'Bagratashen': 2032,
    'Berd': 2032,
    'Berdavan': 2032,
    'Dilijan': 2032,
    'Haghartsin': 2032,
    'Ijevan': 2032,
    'Khasht’arrak': 2032,
    'Mosesgegh': 2032,
    'Navur': 2032,
    'Noyemberyan': 2032,
    'Parravak’ar': 2032,
    'Sarigyugh': 2032,
    'Voskevan': 2032,
    'Agarakadzor': 2025,
    'Aghavnadzor': 2025,
    'Areni': 2025,
    'Getap’': 2025,
    'Gladzor': 2025,
    'Jermuk': 2025,
    'Malishka': 2025,
    'Rrind': 2025,
    'Shatin': 2025,
    'Vayk’': 2025,
    'Vernashen': 2025,
    'Yeghegis': 2025,
    'Yeghegnadzor': 2025,
    'Zarrit’ap’': 2025,
    'Arabkir': 2030,
    'Argavand': 2030,
    'Jrashen': 2030,
    'K’anak’erravan': 2030,
    'Vardadzor': 2030,
    'Yerevan': 2030,
    'Acton': 3907,
    'Ainslie': 3907,
    'Amaroo': 3907,
    'Aranda': 3907,
    'Banks': 3907,
    'Barton': 3907,
    'Belconnen': 3907,
    'Bonner': 3907,
    'Bonython': 3907,
    'Braddon': 3907,
    'Bruce': 3907,
    'Calwell': 3907,
    'Campbell': 3907,
    'Canberra': 3907,
    'Casey': 3907,
    'Chapman': 3907,
    'Charnwood': 3907,
    'Chifley': 3907,
    'Chisholm': 3907,
    'City': 3907,
    'Conder': 3907,
    'Cook': 3907,
    'Coombs': 3907,
    'Crace': 3907,
    'Curtin': 3907,
    'Deakin': 3907,
    'Dickson': 3907,
    'Downer': 3907,
    'Duffy': 3907,
    'Dunlop': 3907,
    'Evatt': 3907,
    'Fadden': 3907,
    'Farrer': 3907,
    'Fisher': 3907,
    'Florey': 3907,
    'Flynn': 3907,
    'Forde': 3907,
    'Forrest': 3907,
    'Franklin': 3907,
    'Fraser': 3907,
    'Garran': 3907,
    'Gilmore': 3907,
    'Giralang': 3907,
    'Gordon': 3907,
    'Gowrie': 3907,
    'Greenway': 3907,
    'Griffith': 3907,
    'Gungahlin': 3907,
    'Hackett': 3907,
    'Harrison': 3907,
    'Hawker': 3907,
    'Higgins': 3907,
    'Holder': 3907,
    'Holt': 3907,
    'Hughes': 3907,
    'Isaacs': 3907,
    'Isabella Plains': 3907,
    'Kaleen': 3907,
    'Kambah': 3907,
    'Kingston': 3907,
    'Latham': 3907,
    'Lyneham': 3907,
    'Lyons': 3907,
    'Macarthur': 3907,
    'Macgregor': 3907,
    'Macquarie': 3907,
    'Mawson': 3907,
    'McKellar': 3907,
    'Melba': 3907,
    'Monash': 3907,
    'Narrabundah': 3907,
    'Ngunnawal': 3907,
    'Nicholls': 3907,
    'O  Connor': 3907,
    'Oxley': 3907,
    'Page': 3907,
    'Palmerston': 3907,
    'Pearce': 3907,
    'Phillip': 3907,
    'Red Hill': 3907,
    'Reid': 3907,
    'Richardson': 3907,
    'Rivett': 3907,
    'Scullin': 3907,
    'Spence': 3907,
    'Stirling': 3907,
    'Theodore': 3907,
    'Torrens': 3907,
    'Turner': 3907,
    'Wanniassa': 3907,
    'Waramanga': 3907,
    'Watson': 3907,
    'Weetangera': 3907,
    'Weston': 3907,
    'Wright': 3907,
    'Yarralumla': 3907,
    'Abbotsbury': 3909,
    'Abbotsford': 3909,
    'Abercrombie': 3909,
    'Aberdare': 3909,
    'Aberdeen': 3909,
    'Aberglasslyn': 3909,
    'Abermain': 3909,
    'Acacia Gardens': 3909,
    'Adamstown': 3909,
    'Adamstown Heights': 3909,
    'Airds': 3909,
    'Albion Park': 3909,
    'Albion Park Rail': 3909,
    'Albury': 3909,
    'Albury Municipality': 3909,
    'Aldavilla': 3909,
    'Alexandria': 3909,
    'Alfords Point': 3909,
    'Allambie Heights': 3909,
    'Allawah': 3909,
    'Alstonville': 3909,
    'Ambarvale': 3909,
    'Anna Bay': 3909,
    'Annandale': 3909,
    'Annangrove': 3909,
    'Appin': 3909,
    'Arcadia': 3909,
    'Arcadia vale': 3909,
    'Argenton': 3909,
    'Armidale': 3909,
    'Arncliffe': 3909,
    'Artarmon': 3909,
    'Ashbury': 3909,
    'Ashcroft': 3909,
    'Ashfield': 3909,
    'Ashmont': 3909,
    'Ashtonfield': 3909,
    'Asquith': 3909,
    'Auburn': 3909,
    'Austinmer': 3909,
    'Austral': 3909,
    'Avoca Beach': 3909,
    'Avondale': 3909,
    'Balgowlah': 3909,
    'Balgowlah Heights': 3909,
    'Balgownie': 3909,
    'Ballina': 3909,
    'Balmain': 3909,
    'Balmain East': 3909,
    'Balranald': 3909,
    'Bangalow': 3909,
    'Bangor': 3909,
    'Banksia': 3909,
    'Bankstown': 3909,
    'Banora Point': 3909,
    'Bar Beach': 3909,
    'Barden Ridge': 3909,
    'Bardia': 3909,
    'Bardwell Park': 3909,
    'Bardwell Valley': 3909,
    'Bargo': 3909,
    'Barham': 3909,
    'Barnsley': 3909,
    'Barooga': 3909,
    'Barraba': 3909,
    'Barrack Heights': 3909,
    'Basin View': 3909,
    'Bass Hill': 3909,
    'Bateau Bay': 3909,
    'Batehaven': 3909,
    'Batemans Bay': 3909,
    'Bathurst': 3909,
    'Bathurst city centre': 3909,
    'Bathurst Regional': 3909,
    'Batlow': 3909,
    'Baulkham Hills': 3909,
    'Bay View': 3909,
    'Beacon Hill': 3909,
    'Beaumont Hills': 3909,
    'Beecroft': 3909,
    'Bega': 3909,
    'Bega Valley': 3909,
    'Belfield': 3909,
    'Bella Vista': 3909,
    'Bellambi': 3909,
    'Bellbird': 3909,
    'Bellevue Hill': 3909,
    'Bellingen': 3909,
    'Belmont': 3909,
    'Belmont North': 3909,
    'Belmont South': 3909,
    'Belmore': 3909,
    'Belrose': 3909,
    'Bensville': 3909,
    'Berala': 3909,
    'Beresfield': 3909,
    'Berkeley': 3909,
    'Berkeley Vale': 3909,
    'Berkshire Park': 3909,
    'Bermagui': 3909,
    'Berowra': 3909,
    'Berowra Heights': 3909,
    'Berridale': 3909,
    'Berrigan': 3909,
    'Berry': 3909,
    'Beverley Park': 3909,
    'Beverly Hills': 3909,
    'Bexley': 3909,
    'Bexley North': 3909,
    'Bidwill': 3909,
    'Bilambil Heights': 3909,
    'Bilgola Plateau': 3909,
    'Bingara': 3909,
    'Birchgrove': 3909,
    'Birmingham Gardens': 3909,
    'Birrong': 3909,
    'Blackalls Park': 3909,
    'Blackbutt': 3909,
    'Blackett': 3909,
    'Blackheath': 3909,
    'Blacksmiths': 3909,
    'Blacktown': 3909,
    'Blackwall': 3909,
    'Blair Athol': 3909,
    'Blakehurst': 3909,
    'Bland': 3909,
    'Blaxland': 3909,
    'Blayney': 3909,
    'Bligh Park': 3909,
    'Blue Bay': 3909,
    'Blue Haven': 3909,
    'Blue Mountains Municipality': 3909,
    'Boambee': 3909,
    'Boambee East': 3909,
    'Bogan': 3909,
    'Bogangar': 3909,
    'Boggabri': 3909,
    'Bolton Point': 3909,
    'Bolwarra Heights': 3909,
    'Bomaderry': 3909,
    'Bombala': 3909,
    'Bondi': 3909,
    'Bondi Beach': 3909,
    'Bondi Junction': 3909,
    'Bonnells Bay': 3909,
    'Bonnet Bay': 3909,
    'Bonny Hills': 3909,
    'Bonnyrigg': 3909,
    'Bonnyrigg Heights': 3909,
    'Bonville': 3909,
    'Booker Bay': 3909,
    'Booragul': 3909,
    'Boorowa': 3909,
    'Bossley Park': 3909,
    'Botany': 3909,
    'Botany Bay': 3909,
    'Bourke': 3909,
    'Bourkelands': 3909,
    'Bow Bowing': 3909,
    'Bowen Mountain': 3909,
    'Bowenfels': 3909,
    'Bowral': 3909,
    'Bowraville': 3909,
    'Bradbury': 3909,
    'Braidwood': 3909,
    'Branxton': 3909,
    'Breakfast Point': 3909,
    'Brewarrina': 3909,
    'Brighton-Le-Sands': 3909,
    'Bringelly': 3909,
    'Broadmeadow': 3909,
    'Broken Hill': 3909,
    'Broken Hill Municipality': 3909,
    'Bronte': 3909,
    'Brookvale': 3909,
    'Broulee': 3909,
    'Brunswick Heads': 3909,
    'Budgewoi': 3909,
    'Buff Point': 3909,
    'Bulahdelah': 3909,
    'Bullaburra': 3909,
    'Bulli': 3909,
    'Bundanoon': 3909,
    'Bundeena': 3909,
    'Bungarribee': 3909,
    'Bungendore': 3909,
    'Buronga': 3909,
    'Burradoo': 3909,
    'Burraneer': 3909,
    'Burrill Lake': 3909,
    'Burwood': 3909,
    'Busby': 3909,
    'Buttaba': 3909,
    'Buxton': 3909,
    'Byron Bay': 3909,
    'Byron Shire': 3909,
    'Bywong': 3909,
    'Cabarita': 3909,
    'Cabonne': 3909,
    'Cabramatta': 3909,
    'Cabramatta West': 3909,
    'Caddens': 3909,
    'Calala': 3909,
    'Callaghan': 3909,
    'Callala Bay': 3909,
    'Cambewarra Village': 3909,
    'Cambridge Gardens': 3909,
    'Cambridge Park': 3909,
    'Camden': 3909,
    'Camden Haven': 3909,
    'Camden South': 3909,
    'Cameron Park': 3909,
    'Cammeray': 3909,
    'Campbelltown': 3909,
    'Campbelltown Municipality': 3909,
    'Camperdown': 3909,
    'Campsie': 3909,
    'Canada Bay': 3909,
    'Canley Heights': 3909,
    'Canley Vale': 3909,
    'Canowindra': 3909,
    'Canterbury': 3909,
    'Canton Beach': 3909,
    'Cardiff': 3909,
    'Cardiff Heights': 3909,
    'Cardiff South': 3909,
    'Caringbah': 3909,
    'Caringbah South': 3909,
    'Carlingford': 3909,
    'Carlton': 3909,
    'Carnes Hill': 3909,
    'Carramar': 3909,
    'Carrathool': 3909,
    'Carrington': 3909,
    'Carss Park': 3909,
    'Cartwright': 3909,
    'Carwoola': 3909,
    'Casino': 3909,
    'Castle Cove': 3909,
    'Castle Hill': 3909,
    'Castlecrag': 3909,
    'Castlereagh': 3909,
    'Casuarina': 3909,
    'Casula': 3909,
    'Catalina': 3909,
    'Catherine Field': 3909,
    'Caves Beach': 3909,
    'Cecil Hills': 3909,
    'Centennial Park': 3909,
    'Central Darling': 3909,
    'Cessnock': 3909,
    'Chain Valley Bay': 3909,
    'Charlestown': 3909,
    'Charmhaven': 3909,
    'Chatswood': 3909,
    'Chatswood West': 3909,
    'Cherrybrook': 3909,
    'Chester Hill': 3909,
    'Chifley': 3909,
    'Chinderah': 3909,
    'Chippendale': 3909,
    'Chipping Norton': 3909,
    'Chiswick': 3909,
    'Chittaway Bay': 3909,
    'City of Sydney': 3909,
    'Claremont Meadows': 3909,
    'Clarence Town': 3909,
    'Clarence Valley': 3909,
    'Claymore': 3909,
    'Clemton Park': 3909,
    'Clontarf': 3909,
    'Clovelly': 3909,
    'Coal Point': 3909,
    'Cobar': 3909,
    'Cobbitty': 3909,
    'Coffs Harbour': 3909,
    'Coleambally': 3909,
    'Colebee': 3909,
    'Coledale': 3909,
    'Collaroy': 3909,
    'Collaroy Plateau': 3909,
    'Colo Vale': 3909,
    'Colyton': 3909,
    'Como': 3909,
    'Concord': 3909,
    'Concord West': 3909,
    'Condell Park': 3909,
    'Condobolin': 3909,
    'Coniston': 3909,
    'Connells Point': 3909,
    'Constitution Hill': 3909,
    'Coogee': 3909,
    'Cooks Hill': 3909,
    'Coolah': 3909,
    'Coolamon': 3909,
    'Cooma': 3909,
    'Coonabarabran': 3909,
    'Coonamble': 3909,
    'Cooranbong': 3909,
    'Cootamundra': 3909,
    'Copacabana': 3909,
    'Coraki': 3909,
    'Cordeaux Heights': 3909,
    'Corindi Beach': 3909,
    'Corlette': 3909,
    'Corowa': 3909,
    'Corrimal': 3909,
    'Coutts Crossing': 3909,
    'Cowra': 3909,
    'Cranebrook': 3909,
    'Cremorne': 3909,
    'Cremorne Point': 3909,
    'Crescent Head': 3909,
    'Crestwood': 3909,
    'Cringila': 3909,
    'Cromer': 3909,
    'Cronulla': 3909,
    'Crookwell': 3909,
    'Crows Nest': 3909,
    'Croydon': 3909,
    'Culburra Beach': 3909,
    'Culcairn': 3909,
    'Cumbalum': 3909,
    'Cundletown': 3909,
    'Curl Curl': 3909,
    'Currans Hill': 3909,
    'Daceyville': 3909,
    'Dalmeny': 3909,
    'Dapto': 3909,
    'Darling Point': 3909,
    'Darlinghurst': 3909,
    'Darlington': 3909,
    'Darlington Point': 3909,
    'Davidson': 3909,
    'Davistown': 3909,
    'Dean Park': 3909,
    'Dee Why': 3909,
    'Denham Court': 3909,
    'Deniliquin': 3909,
    'Denistone': 3909,
    'Denistone East': 3909,
    'Denman': 3909,
    'Dharruk': 3909,
    'Dolls Point': 3909,
    'Doonside': 3909,
    'Dora Creek': 3909,
    'Dorrigo': 3909,
    'Double Bay': 3909,
    'Douglas Park': 3909,
    'Dover Heights': 3909,
    'Drummoyne': 3909,
    'Dubbo': 3909,
    'Dudley': 3909,
    'Dulwich Hill': 3909,
    'Dundas Valley': 3909,
    'Dunedoo': 3909,
    'Dungog': 3909,
    'Dural': 3909,
    'Eagle Vale': 3909,
    'Earlwood': 3909,
    'East Albury': 3909,
    'East Ballina': 3909,
    'East Branxton': 3909,
    'East Corrimal': 3909,
    'East Gosford': 3909,
    'East Hills': 3909,
    'East Jindabyne': 3909,
    'East Kempsey': 3909,
    'East Killara': 3909,
    'East Kurrajong': 3909,
    'East Lindfield': 3909,
    'East Lismore': 3909,
    'East Maitland': 3909,
    'East Ryde': 3909,
    'East Tamworth': 3909,
    'Eastlakes': 3909,
    'Eastwood': 3909,
    'Eden': 3909,
    'Edensor Park': 3909,
    'Edgecliff': 3909,
    'Edgeworth': 3909,
    'Edmondson Park': 3909,
    'Eglinton': 3909,
    'Elanora Heights': 3909,
    'Elderslie': 3909,
    'Eleebana': 3909,
    'Elermore Vale': 3909,
    'Elizabeth Bay': 3909,
    'Elizabeth Hills': 3909,
    'Ellalong': 3909,
    'Emerald Beach': 3909,
    'Emerton': 3909,
    'Empire Bay': 3909,
    'Emu Heights': 3909,
    'Emu Plains': 3909,
    'Engadine': 3909,
    'Enmore': 3909,
    'Epping': 3909,
    'Erina': 3909,
    'Ermington': 3909,
    'Erskine Park': 3909,
    'Erskineville': 3909,
    'Eschol Park': 3909,
    'Estella': 3909,
    'Ettalong': 3909,
    'Ettalong Beach': 3909,
    'Eulomogo': 3909,
    'Eurobodalla': 3909,
    'Evans Head': 3909,
    'Fairfield': 3909,
    'Fairfield East': 3909,
    'Fairfield Heights': 3909,
    'Fairfield West': 3909,
    'Fairlight': 3909,
    'Fairy Meadow': 3909,
    'Farmborough Heights': 3909,
    'Faulconbridge': 3909,
    'Fennell Bay': 3909,
    'Fern Bay': 3909,
    'Fern Hill': 3909,
    'Figtree': 3909,
    'Fingal Bay': 3909,
    'Finley': 3909,
    'Five Dock': 3909,
    'Fletcher': 3909,
    'Flinders': 3909,
    'Floraville': 3909,
    'Forbes': 3909,
    'Forest Hill': 3909,
    'Forest Lodge': 3909,
    'Forestville': 3909,
    'Forresters Beach': 3909,
    'Forster': 3909,
    'Frederickton': 3909,
    'Freemans Reach': 3909,
    'Frenchs Forest': 3909,
    'Freshwater': 3909,
    'Galston': 3909,
    'Garden Suburb': 3909,
    'Gateshead': 3909,
    'Georges Hall': 3909,
    'Georgetown': 3909,
    'Gerringong': 3909,
    'Gilgandra': 3909,
    'Gillieston Heights': 3909,
    'Girards Hill': 3909,
    'Girraween': 3909,
    'Gladesville': 3909,
    'Glebe': 3909,
    'Glen Alpine': 3909,
    'Glen Innes': 3909,
    'Glen Innes Severn': 3909,
    'Glenbrook': 3909,
    'Glendale': 3909,
    'Glendenning': 3909,
    'Glenfield': 3909,
    'Glenfield Park': 3909,
    'Glenhaven': 3909,
    'Glenmore Park': 3909,
    'Glenning Valley': 3909,
    'Glenorie': 3909,
    'Glenroy': 3909,
    'Glenwood': 3909,
    'Glossodia': 3909,
    'Gloucester': 3909,
    'Gol Gol': 3909,
    'Googong': 3909,
    'Goonellabah': 3909,
    'Gordon': 3909,
    'Gorokan': 3909,
    'Gosford': 3909,
    'Goulburn': 3909,
    'Goulburn Mulwaree': 3909,
    'Grafton': 3909,
    'Granville': 3909,
    'Grasmere': 3909,
    'Grays Point': 3909,
    'Greater Hume Shire': 3909,
    'Green Valley': 3909,
    'Greenacre': 3909,
    'Greenfield Park': 3909,
    'Greenwell Point': 3909,
    'Greenwich': 3909,
    'Gregory Hills': 3909,
    'Grenfell': 3909,
    'Greta': 3909,
    'Greystanes': 3909,
    'Griffith': 3909,
    'Grose Vale': 3909,
    'Guildford West': 3909,
    'Gulgong': 3909,
    'Gulmarrad': 3909,
    'Gundagai': 3909,
    'Gundaroo': 3909,
    'Gunnedah': 3909,
    'Guyra': 3909,
    'Gwandalan': 3909,
    'Gwydir': 3909,
    'Gwynneville': 3909,
    'Gymea': 3909,
    'Gymea Bay': 3909,
    'Haberfield': 3909,
    'Halekulani': 3909,
    'Hamilton': 3909,
    'Hamlyn Terrace': 3909,
    'Hammondville': 3909,
    'Hanwood': 3909,
    'Harrington': 3909,
    'Harrington Park': 3909,
    'Harris Park': 3909,
    'Hassall Grove': 3909,
    'Hawkesbury': 3909,
    'Hawks Nest': 3909,
    'Hay': 3909,
    'Haymarket': 3909,
    'Hazelbrook': 3909,
    'Heathcote': 3909,
    'Hebersham': 3909,
    'Heckenberg': 3909,
    'Heddon Greta': 3909,
    'Helensburgh': 3909,
    'Henty': 3909,
    'Hill Top': 3909,
    'Hillsdale': 3909,
    'Hillston': 3909,
    'Hillvue': 3909,
    'Hinchinbrook': 3909,
    'Hobartville': 3909,
    'Holbrook': 3909,
    'Holmesville': 3909,
    'Holroyd': 3909,
    'Holsworthy': 3909,
    'Homebush': 3909,
    'Homebush West': 3909,
    'Horningsea Park': 3909,
    'Hornsby': 3909,
    'Hornsby Heights': 3909,
    'Hornsby Shire': 3909,
    'Horsley': 3909,
    'Horsley Park': 3909,
    'Howlong': 3909,
    'Hoxton Park': 3909,
    'Hunters Hill': 3909,
    'Hunterview': 3909,
    'Hurlstone Park': 3909,
    'Hurstville': 3909,
    'Hurstville Grove': 3909,
    'Illawong': 3909,
    'Iluka': 3909,
    'Ingleburn': 3909,
    'Inverell': 3909,
    'Islington': 3909,
    'Jamberoo': 3909,
    'Jamisontown': 3909,
    'Jannali': 3909,
    'Jerilderie': 3909,
    'Jerrabomberra': 3909,
    'Jesmond': 3909,
    'Jewells': 3909,
    'Jilliby': 3909,
    'Jindabyne': 3909,
    'Jindera': 3909,
    'Jordan Springs': 3909,
    'Junction Hill': 3909,
    'Junee': 3909,
    'Kahibah': 3909,
    'Kanahooka': 3909,
    'Kandos': 3909,
    'Kanwal': 3909,
    'Kapooka': 3909,
    'Karabar': 3909,
    'Kareela': 3909,
    'Kariong': 3909,
    'Karuah': 3909,
    'Katoomba': 3909,
    'Kearns': 3909,
    'Keiraville': 3909,
    'Kellyville': 3909,
    'Kellyville Ridge': 3909,
    'Kelso': 3909,
    'Kemps Creek': 3909,
    'Kempsey': 3909,
    'Kendall': 3909,
    'Kensington': 3909,
    'Kenthurst': 3909,
    'Kew': 3909,
    'Kiama': 3909,
    'Kiama Downs': 3909,
    'Kilaben Bay': 3909,
    'Killara': 3909,
    'Killarney Heights': 3909,
    'Killarney Vale': 3909,
    'King Creek': 3909,
    'Kings Langley': 3909,
    'Kings Park': 3909,
    'Kingscliff': 3909,
    'Kingsford': 3909,
    'Kingsgrove': 3909,
    'Kingswood Park': 3909,
    'Kirrawee': 3909,
    'Kirribilli': 3909,
    'Kogarah': 3909,
    'Kogarah Bay': 3909,
    'Koonawarra': 3909,
    'Kooringal': 3909,
    'Kootingal': 3909,
    'Korora': 3909,
    'Kosciuszko National Park': 3909,
    'Kotara': 3909,
    'Kotara South': 3909,
    'Ku-ring-gai': 3909,
    'Kurnell': 3909,
    'Kurraba Point': 3909,
    'Kurrajong Heights': 3909,
    'Kurri Kurri': 3909,
    'Kyle Bay': 3909,
    'Kyogle': 3909,
    'Lachlan': 3909,
    'Lake Albert': 3909,
    'Lake Cargelligo': 3909,
    'Lake Cathie': 3909,
    'Lake Haven': 3909,
    'Lake Heights': 3909,
    'Lake Illawarra': 3909,
    'Lake Macquarie Shire': 3909,
    'Lake Munmorah': 3909,
    'Lakelands': 3909,
    'Lakemba': 3909,
    'Lakewood': 3909,
    'Lalor Park': 3909,
    'Lambton': 3909,
    'Lane Cove': 3909,
    'Lane Cove North': 3909,
    'Lane Cove West': 3909,
    'Lansvale': 3909,
    'Largs': 3909,
    'Lavington': 3909,
    'Lawrence': 3909,
    'Lawson': 3909,
    'Leeton': 3909,
    'Leichhardt': 3909,
    'Lemon Tree Passage': 3909,
    'Lennox Head': 3909,
    'Leonay': 3909,
    'Leppington': 3909,
    'Lethbridge Park': 3909,
    'Leumeah': 3909,
    'Leura': 3909,
    'Lewisham': 3909,
    'Liberty Grove': 3909,
    'Lidcombe': 3909,
    'Lightning Ridge': 3909,
    'Lilli Pilli': 3909,
    'Lilyfield': 3909,
    'Lindfield': 3909,
    'Lisarow': 3909,
    'Lismore': 3909,
    'Lismore Heights': 3909,
    'Lismore Municipality': 3909,
    'Lithgow': 3909,
    'Little Bay': 3909,
    'Liverpool': 3909,
    'Liverpool Plains': 3909,
    'Llanarth': 3909,
    'Llandilo': 3909,
    'Lockhart': 3909,
    'Loftus': 3909,
    'Londonderry': 3909,
    'Long Beach': 3909,
    'Long Jetty': 3909,
    'Longueville': 3909,
    'Lorn': 3909,
    'Luddenham': 3909,
    'Lugarno': 3909,
    'Lurnea': 3909,
    'Macksville': 3909,
    'Maclean': 3909,
    'Macmasters Beach': 3909,
    'Macquarie Fields': 3909,
    'Macquarie Hills': 3909,
    'Macquarie Links': 3909,
    'Macquarie Park': 3909,
    'Maitland': 3909,
    'Maitland city centre': 3909,
    'Maitland Municipality': 3909,
    'Malabar': 3909,
    'Malua Bay': 3909,
    'Mangerton': 3909,
    'Manilla': 3909,
    'Manly': 3909,
    'Manly Vale': 3909,
    'Mannering Park': 3909,
    'Maraylya': 3909,
    'Marayong': 3909,
    'Mardi': 3909,
    'Marks Point': 3909,
    'Maroubra': 3909,
    'Marrickville': 3909,
    'Marsfield': 3909,
    'Marulan': 3909,
    'Maryland': 3909,
    'Maryville': 3909,
    'Mascot': 3909,
    'Matraville': 3909,
    'Mayfield': 3909,
    'Mayfield East': 3909,
    'Mayfield West': 3909,
    'McGraths Hill': 3909,
    'McMahons Point': 3909,
    'Meadowbank': 3909,
    'Medowie': 3909,
    'Melrose Park': 3909,
    'Menai': 3909,
    'Menangle': 3909,
    'Merewether': 3909,
    'Merewether Heights': 3909,
    'Merimbula': 3909,
    'Merriwa': 3909,
    'Merrylands': 3909,
    'Merrylands West': 3909,
    'Metford': 3909,
    'Mid-Western Regional': 3909,
    'Middle Cove': 3909,
    'Middleton Grange': 3909,
    'Miller': 3909,
    'Millers Point': 3909,
    'Millthorpe': 3909,
    'Milperra': 3909,
    'Milsons Point': 3909,
    'Milton': 3909,
    'Minchinbury': 3909,
    'Minto': 3909,
    'Miranda': 3909,
    'Mitchell': 3909,
    'Mittagong': 3909,
    'Moama': 3909,
    'Mollymook': 3909,
    'Mollymook Beach': 3909,
    'Molong': 3909,
    'Mona Vale': 3909,
    'Monterey': 3909,
    'Moonbi': 3909,
    'Moonee Beach': 3909,
    'Moorebank': 3909,
    'Moree': 3909,
    'Moree Plains': 3909,
    'Morisset': 3909,
    'Morpeth': 3909,
    'Mortdale': 3909,
    'Mortlake': 3909,
    'Moruya': 3909,
    'Mosman': 3909,
    'Moss Vale': 3909,
    'Mount Annan': 3909,
    'Mount Austin': 3909,
    'Mount Colah': 3909,
    'Mount Druitt': 3909,
    'Mount Hutton': 3909,
    'Mount Keira': 3909,
    'Mount Kembla': 3909,
    'Mount Kuring-Gai': 3909,
    'Mount Lewis': 3909,
    'Mount Ousley': 3909,
    'Mount Pleasant': 3909,
    'Mount Pritchard': 3909,
    'Mount Riverview': 3909,
    'Mount Saint Thomas': 3909,
    'Mount Vernon': 3909,
    'Mount Warrigal': 3909,
    'Mudgee': 3909,
    'Mulgoa': 3909,
    'Mullumbimby': 3909,
    'Mulwala': 3909,
    'Murrumbateman': 3909,
    'Murrurundi': 3909,
    'Murwillumbah': 3909,
    'Muswellbrook': 3909,
    'Nabiac': 3909,
    'Nambucca': 3909,
    'Nambucca Heads': 3909,
    'Nambucca Shire': 3909,
    'Nana Glen': 3909,
    'Narara': 3909,
    'Narellan': 3909,
    'Narellan Vale': 3909,
    'Naremburn': 3909,
    'Narooma': 3909,
    'Narrabeen': 3909,
    'Narrabri': 3909,
    'Narrandera': 3909,
    'Narrawallee': 3909,
    'Narraweena': 3909,
    'Narromine': 3909,
    'Narwee': 3909,
    'Nelson Bay': 3909,
    'Neutral Bay': 3909,
    'New Lambton': 3909,
    'New Lambton Heights': 3909,
    'Newcastle': 3909,
    'Newcastle city centre': 3909,
    'Newcastle East': 3909,
    'Newington': 3909,
    'Newport': 3909,
    'Newtown': 3909,
    'Niagara Park': 3909,
    'Nimbin': 3909,
    'Noraville': 3909,
    'Normanhurst': 3909,
    'North Albury': 3909,
    'North Avoca': 3909,
    'North Balgowlah': 3909,
    'North Boambee Valley': 3909,
    'North Bondi': 3909,
    'North Curl Curl': 3909,
    'North Epping': 3909,
    'North Gosford': 3909,
    'North Haven': 3909,
    'North Lambton': 3909,
    'North Manly': 3909,
    'North Narrabeen': 3909,
    'North Nowra': 3909,
    'North Parramatta': 3909,
    'North Richmond': 3909,
    'North Rocks': 3909,
    'North Ryde': 3909,
    'North St Marys': 3909,
    'North Strathfield': 3909,
    'North Sydney': 3909,
    'North Tamworth': 3909,
    'North Turramurra': 3909,
    'North Wahroonga': 3909,
    'North Willoughby': 3909,
    'North Wollongong': 3909,
    'Northbridge': 3909,
    'Northmead': 3909,
    'Nowra': 3909,
    'Nowra Hill': 3909,
    'Nyngan': 3909,
    'Oak Flats': 3909,
    'Oakdale': 3909,
    'Oakhurst': 3909,
    'Oakville': 3909,
    'Oatlands': 3909,
    'Oatley': 3909,
    'Oberon': 3909,
    'Ocean Shores': 3909,
    'Old Bar': 3909,
    'Old Erowal Bay': 3909,
    'Old Guildford': 3909,
    'Old Toongabbie': 3909,
    'Oran Park': 3909,
    'Orange': 3909,
    'Orange Municipality': 3909,
    'Orangeville': 3909,
    'Orchard Hills': 3909,
    'Ourimbah': 3909,
    'Oxley Park': 3909,
    'Oxley Vale': 3909,
    'Oyster Bay': 3909,
    'Paddington': 3909,
    'Padstow': 3909,
    'Padstow Heights': 3909,
    'Pagewood': 3909,
    'Palm Beach': 3909,
    'Panania': 3909,
    'Parkes': 3909,
    'Parklea': 3909,
    'Parramatta': 3909,
    'Peak Hill': 3909,
    'Peakhurst': 3909,
    'Peakhurst Heights': 3909,
    'Pemulwuy': 3909,
    'Pendle Hill': 3909,
    'Pennant Hills': 3909,
    'Penrith': 3909,
    'Penrith Municipality': 3909,
    'Penshurst': 3909,
    'Petersham': 3909,
    'Phillip': 3909,
    'Picnic Point': 3909,
    'Picton': 3909,
    'Pitt Town': 3909,
    'Plumpton': 3909,
    'Point Clare': 3909,
    'Point Frederick': 3909,
    'Point Piper': 3909,
    'Pokolbin': 3909,
    'Port Hacking': 3909,
    'Port Kembla': 3909,
    'Port Macquarie': 3909,
    'Port Macquarie-Hastings': 3909,
    'Port Stephens Shire': 3909,
    'Portland': 3909,
    'Potts Point': 3909,
    'Pottsville': 3909,
    'Pottsville Beach': 3909,
    'Prairiewood': 3909,
    'Prestons': 3909,
    'Primbee': 3909,
    'Prospect': 3909,
    'Punchbowl': 3909,
    'Putney': 3909,
    'Pymble': 3909,
    'Pyrmont': 3909,
    'Quakers Hill': 3909,
    'Queanbeyan': 3909,
    'Queanbeyan East': 3909,
    'Queanbeyan West': 3909,
    'Queens Park': 3909,
    'Queenscliff': 3909,
    'Quirindi': 3909,
    'Raby': 3909,
    'Raglan': 3909,
    'Ramsgate': 3909,
    'Ramsgate Beach': 3909,
    'Randwick': 3909,
    'Rankin Park': 3909,
    'Rathmines': 3909,
    'Raworth': 3909,
    'Raymond Terrace': 3909,
    'Razorback': 3909,
    'Redfern': 3909,
    'Redhead': 3909,
    'Regents Park': 3909,
    'Revesby': 3909,
    'Revesby Heights': 3909,
    'Rhodes': 3909,
    'Richmond': 3909,
    'Richmond Valley': 3909,
    'Riverstone': 3909,
    'Riverview': 3909,
    'Riverwood': 3909,
    'Robertson': 3909,
    'Rockdale': 3909,
    'Rodd Point': 3909,
    'Rooty Hill': 3909,
    'Ropes Crossing': 3909,
    'Rose Bay': 3909,
    'Rosehill': 3909,
    'Roselands': 3909,
    'Rosemeadow': 3909,
    'Roseville': 3909,
    'Roseville Chase': 3909,
    'Rouse Hill': 3909,
    'Rozelle': 3909,
    'Ruse': 3909,
    'Rushcutters Bay': 3909,
    'Russell Lea': 3909,
    'Russell Vale': 3909,
    'Rutherford': 3909,
    'Rydalmere': 3909,
    'Ryde': 3909,
    'Sadleir': 3909,
    'Saint Ives': 3909,
    'Saint Peters': 3909,
    'Salamander Bay': 3909,
    'Salt Ash': 3909,
    'San Remo': 3909,
    'Sanctuary Point': 3909,
    'Sandringham': 3909,
    'Sandy Beach': 3909,
    'Sans Souci': 3909,
    'Sapphire Beach': 3909,
    'Saratoga': 3909,
    'Sawtell': 3909,
    'Schofields': 3909,
    'Scone': 3909,
    'Seaforth': 3909,
    'Sefton': 3909,
    'Seven Hills': 3909,
    'Shalvey': 3909,
    'Shell Cove': 3909,
    'Shellharbour': 3909,
    'Shelly Beach': 3909,
    'Shoal Bay': 3909,
    'Shoalhaven Heads': 3909,
    'Shoalhaven Shire': 3909,
    'Shortland': 3909,
    'Silverdale': 3909,
    'Silverwater': 3909,
    'Singleton': 3909,
    'Singleton Heights': 3909,
    'Skennars Head': 3909,
    'Smithfield': 3909,
    'Smiths Lake': 3909,
    'Soldiers Point': 3909,
    'Somersby': 3909,
    'South Albury': 3909,
    'South Bathurst': 3909,
    'South Bowenfels': 3909,
    'South Coogee': 3909,
    'South Grafton': 3909,
    'South Granville': 3909,
    'South Hurstville': 3909,
    'South Kempsey': 3909,
    'South Lismore': 3909,
    'South Murwillumbah': 3909,
    'South Nowra': 3909,
    'South Penrith': 3909,
    'South Tamworth': 3909,
    'South Turramurra': 3909,
    'South Wentworthville': 3909,
    'South West Rocks': 3909,
    'South Windsor': 3909,
    'Speers Point': 3909,
    'Spring Farm': 3909,
    'Springdale Heights': 3909,
    'Springvale': 3909,
    'Springwood': 3909,
    'St Andrews': 3909,
    'St Clair': 3909,
    'St Helens Park': 3909,
    'St Huberts Island': 3909,
    'St Ives Chase': 3909,
    'St Johns Park': 3909,
    'St Leonards': 3909,
    'St. Georges Basin': 3909,
    'Stanhope Gardens': 3909,
    'Stanmore': 3909,
    'Stanwell Park': 3909,
    'Stockton': 3909,
    'Strathfield': 3909,
    'Strathfield South': 3909,
    'Suffolk Park': 3909,
    'Summer Hill': 3909,
    'Summerland Point': 3909,
    'Sunshine Bay': 3909,
    'Surfside': 3909,
    'Surry Hills': 3909,
    'Sussex Inlet': 3909,
    'Sutherland': 3909,
    'Sutherland Shire': 3909,
    'Sutton': 3909,
    'Swansea': 3909,
    'Sydenham': 3909,
    'Sydney': 3909,
    'Sydney Central Business District': 3909,
    'Sydney Olympic Park': 3909,
    'Sylvania': 3909,
    'Sylvania Waters': 3909,
    'Table Top': 3909,
    'Tahmoor': 3909,
    'Tamarama': 3909,
    'Tamworth': 3909,
    'Tamworth Municipality': 3909,
    'Tanilba Bay': 3909,
    'Taree': 3909,
    'Taren Point': 3909,
    'Tarrawanna': 3909,
    'Tarro': 3909,
    'Tascott': 3909,
    'Tathra': 3909,
    'Tatton': 3909,
    'Tea Gardens': 3909,
    'Telarah': 3909,
    'Telopea': 3909,
    'Temora': 3909,
    'Temora Municipality': 3909,
    'Tempe': 3909,
    'Tenambit': 3909,
    'Tennyson Point': 3909,
    'Tenterfield': 3909,
    'Tenterfield Municipality': 3909,
    'Teralba': 3909,
    'Terranora': 3909,
    'Terrigal': 3909,
    'Terry Hills': 3909,
    'The Entrance': 3909,
    'The Entrance North': 3909,
    'The Hill': 3909,
    'The Hills Shire': 3909,
    'The Junction': 3909,
    'The Oaks': 3909,
    'The Ponds': 3909,
    'The Rock': 3909,
    'The Rocks': 3909,
    'Thirlmere': 3909,
    'Thirroul': 3909,
    'Thornleigh': 3909,
    'Thornton': 3909,
    'Thurgoona': 3909,
    'Tighes Hill': 3909,
    'Tingira Heights': 3909,
    'Tinonee': 3909,
    'Tocumwal': 3909,
    'Tolland': 3909,
    'Tomakin': 3909,
    'Tomerong': 3909,
    'Toongabbie': 3909,
    'Toormina': 3909,
    'Toronto': 3909,
    'Toukley': 3909,
    'Towradgi': 3909,
    'Trangie': 3909,
    'Tregear': 3909,
    'Tuggerawong': 3909,
    'Tumbarumba': 3909,
    'Tumbi Vmbi': 3909,
    'Tumut': 3909,
    'Tuncurry': 3909,
    'Tura Beach': 3909,
    'Tuross Head': 3909,
    'Turramurra': 3909,
    'Turrella': 3909,
    'Turvey Park': 3909,
    'Tweed': 3909,
    'Tweed Heads': 3909,
    'Tweed Heads South': 3909,
    'Tweed Heads West': 3909,
    'Ulladulla': 3909,
    'Ultimo': 3909,
    'Umina Beach': 3909,
    'Unanderra': 3909,
    'Upper Hunter Shire': 3909,
    'Upper Lachlan Shire': 3909,
    'Uralla': 3909,
    'Urunga': 3909,
    'Valentine': 3909,
    'Valla Beach': 3909,
    'Valley Heights': 3909,
    'Vaucluse': 3909,
    'Villawood': 3909,
    'Vincentia': 3909,
    'Vineyard': 3909,
    'Voyager Point': 3909,
    'Wadalba': 3909,
    'Wagga Wagga': 3909,
    'Wahroonga': 3909,
    'Waitara': 3909,
    'Wakeley': 3909,
    'Walcha': 3909,
    'Walgett': 3909,
    'Wallacia': 3909,
    'Wallalong': 3909,
    'Wallerawang': 3909,
    'Wallsend': 3909,
    'Wamberal': 3909,
    'Wamboin': 3909,
    'Wangi Wangi': 3909,
    'Warabrook': 3909,
    'Waratah': 3909,
    'Waratah West': 3909,
    'Wareemba': 3909,
    'Warialda': 3909,
    'Warilla': 3909,
    'Warners Bay': 3909,
    'Warragamba': 3909,
    'Warrawee': 3909,
    'Warrawong': 3909,
    'Warren': 3909,
    'Warren Shire': 3909,
    'Warriewood': 3909,
    'Warrimoo': 3909,
    'Warrumbungle Shire': 3909,
    'Warwick Farm': 3909,
    'Watanobbi': 3909,
    'Waterview Heights': 3909,
    'Wattle Grove': 3909,
    'Wattle Ponds': 3909,
    'Wauchope': 3909,
    'Waverley': 3909,
    'Waverton': 3909,
    'Weddin': 3909,
    'Wee Waa': 3909,
    'Wellington': 3909,
    'Wentworth': 3909,
    'Wentworth Falls': 3909,
    'Wentworth Point': 3909,
    'Wentworthville': 3909,
    'Werrington': 3909,
    'Werrington County': 3909,
    'Werrington Downs': 3909,
    'Werris Creek': 3909,
    'West Albury': 3909,
    'West Ballina': 3909,
    'West Bathurst': 3909,
    'West Gosford': 3909,
    'West Haven': 3909,
    'West Hoxton': 3909,
    'West Kempsey': 3909,
    'West Nowra': 3909,
    'West Pennant Hills': 3909,
    'West Pymble': 3909,
    'West Ryde': 3909,
    'West Tamworth': 3909,
    'West Wallsend': 3909,
    'West Wollongong': 3909,
    'West Wyalong': 3909,
    'Westdale': 3909,
    'Westleigh': 3909,
    'Westmead': 3909,
    'Wetherill Park': 3909,
    'Whalan': 3909,
    'Wheeler Heights': 3909,
    'Whitebridge': 3909,
    'Wickham': 3909,
    'Wilberforce': 3909,
    'Wiley Park': 3909,
    'Williamtown': 3909,
    'Willmot': 3909,
    'Willoughby': 3909,
    'Willoughby East': 3909,
    'Wilton': 3909,
    'Windale': 3909,
    'Windang': 3909,
    'Windradyne': 3909,
    'Windsor': 3909,
    'Windsor Downs': 3909,
    'Wingecarribee': 3909,
    'Wingham': 3909,
    'Winmalee': 3909,
    'Winston Hills': 3909,
    'Wolli Creek': 3909,
    'Wollondilly': 3909,
    'Wollongbar': 3909,
    'Wollongong': 3909,
    'Wollongong city centre': 3909,
    'Wollstonecraft': 3909,
    'Woodberry': 3909,
    'Woodbine': 3909,
    'Woodcroft': 3909,
    'Woodford': 3909,
    'Woodpark': 3909,
    'Woodrising': 3909,
    'Woolgoolga': 3909,
    'Woollahra': 3909,
    'Woolloomooloo': 3909,
    'Woolooware': 3909,
    'Woongarrah': 3909,
    'Woonona': 3909,
    'Woronora': 3909,
    'Woronora Heights': 3909,
    'Worrigee': 3909,
    'Woy Woy': 3909,
    'Wyee': 3909,
    'Wyee Point': 3909,
    'Wyoming': 3909,
    'Wyong': 3909,
    'Wyongah': 3909,
    'Yagoona': 3909,
    'Yamba': 3909,
    'Yarravel': 3909,
    'Yarrawarrah': 3909,
    'Yass': 3909,
    'Yass Valley': 3909,
    'Yenda': 3909,
    'Yennora': 3909,
    'Yerrinbool': 3909,
    'Yoogali': 3909,
    'Young': 3909,
    'Yowie Bay': 3909,
    'Zetland': 3909,
    'Alawa': 3910,
    'Alice Springs': 3910,
    'Alyangula': 3910,
    'Anula': 3910,
    'Araluen': 3910,
    'Bakewell': 3910,
    'Barkly': 3910,
    'Bellamack': 3910,
    'Belyuen': 3910,
    'Berrimah': 3910,
    'Braitling': 3910,
    'Brinkin': 3910,
    'Central Desert': 3910,
    'Coconut Grove': 3910,
    'Coomalie': 3910,
    'Cossack': 3910,
    'Darwin': 3910,
    'Desert Springs': 3910,
    'Driver': 3910,
    'Durack': 3910,
    'East Arnhem': 3910,
    'East Side': 3910,
    'Fannie Bay': 3910,
    'Farrar': 3910,
    'Galiwinku': 3910,
    'Gillen': 3910,
    'Girraween': 3910,
    'Gray': 3910,
    'Gunbalanya': 3910,
    'Gunn': 3910,
    'Herbert': 3910,
    'Holtze': 3910,
    'Howard Springs': 3910,
    'Humpty Doo': 3910,
    'Jabiru': 3910,
    'Jingili': 3910,
    'Johnston': 3910,
    'Karama': 3910,
    'Katherine': 3910,
    'Katherine East': 3910,
    'Katherine South': 3910,
    'Larapinta': 3910,
    'Larrakeyah': 3910,
    'Leanyer': 3910,
    'Litchfield': 3910,
    'Ludmilla': 3910,
    'Lyons': 3910,
    'MacDonnell': 3910,
    'Malak': 3910,
    'Maningrida': 3910,
    'Marrara': 3910,
    'Milingimbi': 3910,
    'Millner': 3910,
    'Moil': 3910,
    'Moulden': 3910,
    'Muirhead': 3910,
    'Nakara': 3910,
    'Ngukurr': 3910,
    'Nhulunbuy': 3910,
    'Nightcliff': 3910,
    'Palmerston': 3910,
    'Parap': 3910,
    'Rapid Creek': 3910,
    'Roper Gulf': 3910,
    'Rosebery': 3910,
    'Ross': 3910,
    'Sadadeen': 3910,
    'Stuart Park': 3910,
    'Tennant Creek': 3910,
    'The Gap': 3910,
    'Tiwi': 3910,
    'Tiwi Islands': 3910,
    'Victoria Daly': 3910,
    'Virginia': 3910,
    'Wadeye': 3910,
    'Wagait': 3910,
    'Wagaman': 3910,
    'Wanguri': 3910,
    'West Arnhem': 3910,
    'Woodroffe': 3910,
    'Wulagi': 3910,
    'Wurrumiyanga': 3910,
    'Yulara': 3910,
    'Zuccoli': 3910,
    'Acacia Ridge': 3905,
    'Agnes Water': 3905,
    'Airlie Beach': 3905,
    'Aitkenvale': 3905,
    'Albany Creek': 3905,
    'Alderley': 3905,
    'Aldershot': 3905,
    'Alexandra Headland': 3905,
    'Alexandra Hills': 3905,
    'Algester': 3905,
    'Alice River': 3905,
    'Allenstown': 3905,
    'Alligator Creek': 3905,
    'Allora': 3905,
    'Alton Downs': 3905,
    'Andergrove': 3905,
    'Annandale': 3905,
    'Annerley': 3905,
    'Anstead': 3905,
    'Arana Hills': 3905,
    'Aroona': 3905,
    'Arundel': 3905,
    'Ascot': 3905,
    'Ashgrove': 3905,
    'Ashmore': 3905,
    'Aspley': 3905,
    'Atherton': 3905,
    'Auchenflower': 3905,
    'Augustine Heights': 3905,
    'Aurukun': 3905,
    'Avenell Heights': 3905,
    'Avoca': 3905,
    'Ayr': 3905,
    'Babinda': 3905,
    'Bahrs Scrub': 3905,
    'Bakers Creek': 3905,
    'Bald Hills': 3905,
    'Balmoral': 3905,
    'Balonne Shire': 3905,
    'Bamaga': 3905,
    'Banana': 3905,
    'Banksia Beach': 3905,
    'Banyo': 3905,
    'Barcaldine': 3905,
    'Barcoo': 3905,
    'Bardon': 3905,
    'Barellan Point': 3905,
    'Bargara': 3905,
    'Barney Point': 3905,
    'Battery Hill': 3905,
    'Bayview Heights': 3905,
    'Beachmere': 3905,
    'Beaconsfield': 3905,
    'Beaudesert': 3905,
    'Beenleigh': 3905,
    'Beerwah': 3905,
    'Belgian Gardens': 3905,
    'Bellara': 3905,
    'Bellbird Park': 3905,
    'Bellbowrie': 3905,
    'Bellmere': 3905,
    'Belmont': 3905,
    'Benaraby': 3905,
    'Benowa': 3905,
    'Bentley Park': 3905,
    'Berrinba': 3905,
    'Berserker': 3905,
    'Biggera Waters': 3905,
    'Bilinga': 3905,
    'Biloela': 3905,
    'Birkdale': 3905,
    'Birtinya': 3905,
    'Black Mountain': 3905,
    'Black River': 3905,
    'Blackall': 3905,
    'Blackall Tambo': 3905,
    'Blacks Beach': 3905,
    'Blackwater': 3905,
    'Bli Bli': 3905,
    'Bluewater': 3905,
    'Bohle Plains': 3905,
    'Bokarina': 3905,
    'Bongaree': 3905,
    'Bonogin': 3905,
    'Booie': 3905,
    'Boonah': 3905,
    'Boondall': 3905,
    'Booral': 3905,
    'Booval': 3905,
    'Boronia Heights': 3905,
    'Bouldercombe': 3905,
    'Boulia': 3905,
    'Bowen': 3905,
    'Bowen Hills': 3905,
    'Boyne Island': 3905,
    'Bracken Ridge': 3905,
    'Brandon': 3905,
    'Branyan': 3905,
    'Brassall': 3905,
    'Bray Park': 3905,
    'Brendale': 3905,
    'Bridgeman Downs': 3905,
    'Brighton': 3905,
    'Brinsmead': 3905,
    'Brisbane': 3905,
    'Broadbeach': 3905,
    'Broadbeach Waters': 3905,
    'Brookfield': 3905,
    'Brookwater': 3905,
    'Bucasia': 3905,
    'Bucca': 3905,
    'Buccan': 3905,
    'Buddina': 3905,
    'Buderim': 3905,
    'Bulimba': 3905,
    'Bulloo': 3905,
    'Bundaberg': 3905,
    'Bundaberg East': 3905,
    'Bundaberg North': 3905,
    'Bundaberg South': 3905,
    'Bundaberg West': 3905,
    'Bundall': 3905,
    'Bundamba': 3905,
    'Bungalow': 3905,
    'Bunya': 3905,
    'Burbank': 3905,
    'Burdekin': 3905,
    'Burdell': 3905,
    'Burke': 3905,
    'Burleigh Heads': 3905,
    'Burleigh Waters': 3905,
    'Burnett Heads': 3905,
    'Burnside': 3905,
    'Burpengary': 3905,
    'Burpengary East': 3905,
    'Burrum Heads': 3905,
    'Bushland Beach': 3905,
    'Cabarlah': 3905,
    'Caboolture': 3905,
    'Caboolture South': 3905,
    'Cairns': 3905,
    'Cairns City': 3905,
    'Cairns North': 3905,
    'Calamvale': 3905,
    'Calliope': 3905,
    'Caloundra': 3905,
    'Caloundra West': 3905,
    'Cambooya': 3905,
    'Camira': 3905,
    'Camp Hill': 3905,
    'Camp Mountain': 3905,
    'Cannon Hill': 3905,
    'Cannonvale': 3905,
    'Canungra': 3905,
    'Capalaba': 3905,
    'Capella': 3905,
    'Caravonica': 3905,
    'Carbrook': 3905,
    'Cardwell': 3905,
    'Carina Heights': 3905,
    'Carindale': 3905,
    'Carpentaria': 3905,
    'Carrara': 3905,
    'Carseldine': 3905,
    'Cashmere': 3905,
    'Cassowary Coast': 3905,
    'Cedar Grove': 3905,
    'Cedar Vale': 3905,
    'Centenary Heights': 3905,
    'Central Highlands': 3905,
    'Chambers Flat': 3905,
    'Chandler': 3905,
    'Chapel Hill': 3905,
    'Charleville': 3905,
    'Charters Towers': 3905,
    'Charters Towers City': 3905,
    'Chatsworth': 3905,
    'Chelmer': 3905,
    'Cherbourg': 3905,
    'Chermside': 3905,
    'Chermside West': 3905,
    'Childers': 3905,
    'Chinchilla': 3905,
    'Churchill': 3905,
    'Chuwar': 3905,
    'Clayfield': 3905,
    'Clear Island Waters': 3905,
    'Clermont': 3905,
    'Cleveland': 3905,
    'Clifton': 3905,
    'Clifton Beach': 3905,
    'Clinton': 3905,
    'Cloncurry': 3905,
    'Clontarf': 3905,
    'Coes Creek': 3905,
    'Collingwood Park': 3905,
    'Collinsville': 3905,
    'Condon': 3905,
    'Cooee Bay': 3905,
    'Cook Shire': 3905,
    'Cooktown': 3905,
    'Coolangatta': 3905,
    'Cooloola Cove': 3905,
    'Coolum Beach': 3905,
    'Coombabah': 3905,
    'Coomera': 3905,
    'Coominya': 3905,
    'Coopers Plains': 3905,
    'Cooran': 3905,
    'Cooroibah': 3905,
    'Cooroy': 3905,
    'Coorparoo': 3905,
    'Coppabella': 3905,
    'Coral Cove': 3905,
    'Corinda': 3905,
    'Cornubia': 3905,
    'Cotswold Hills': 3905,
    'Craiglie': 3905,
    'Craignish': 3905,
    'Cranbrook': 3905,
    'Cranley': 3905,
    'Crestmead': 3905,
    'Crows Nest': 3905,
    'Croydon': 3905,
    'Cunnamulla': 3905,
    'Curra': 3905,
    'Currajong': 3905,
    'Currimundi': 3905,
    'Currumbin': 3905,
    'Currumbin Valley': 3905,
    'Currumbin Waters': 3905,
    'D’Aguilar': 3905,
    'Daisy Hill': 3905,
    'Dakabin': 3905,
    'Dalby': 3905,
    'Darling Heights': 3905,
    'Darra': 3905,
    'Dayboro': 3905,
    'Deagon': 3905,
    'Deception Bay': 3905,
    'Deebing Heights': 3905,
    'Deeragun': 3905,
    'Delaneys Creek': 3905,
    'Depot Hill': 3905,
    'Diamantina': 3905,
    'Dicky Beach': 3905,
    'Diddillibah': 3905,
    'Dimbulah': 3905,
    'Doolandella': 3905,
    'Doomadgee': 3905,
    'Doonan': 3905,
    'Douglas': 3905,
    'Drayton': 3905,
    'Drewvale': 3905,
    'Dundowran Beach': 3905,
    'Durack': 3905,
    'Dutton Park': 3905,
    'Dysart': 3905,
    'Eagleby': 3905,
    'Earlville': 3905,
    'East Brisbane': 3905,
    'East Innisfail': 3905,
    'East Ipswich': 3905,
    'East Mackay': 3905,
    'East Toowoomba': 3905,
    'Eastern Heights': 3905,
    'Eatons Hill': 3905,
    'Edens Landing': 3905,
    'Edge Hill': 3905,
    'Edmonton': 3905,
    'Eight Mile Plains': 3905,
    'Eimeo': 3905,
    'Elanora': 3905,
    'Eli Waters': 3905,
    'Elimbah': 3905,
    'Ellen Grove': 3905,
    'Elliott Heads': 3905,
    'Emerald': 3905,
    'Emu Park': 3905,
    'Enoggera': 3905,
    'Esk': 3905,
    'Etheridge': 3905,
    'Eudlo': 3905,
    'Eumundi': 3905,
    'Everton Hills': 3905,
    'Everton Park': 3905,
    'Fairfield': 3905,
    'Fernvale': 3905,
    'Ferny Grove': 3905,
    'Ferny Hills': 3905,
    'Fig Tree Pocket': 3905,
    'Fitzgibbon': 3905,
    'Flinders': 3905,
    'Flinders View': 3905,
    'Forest Glen': 3905,
    'Forest Lake': 3905,
    'Forestdale': 3905,
    'Fortitude Valley': 3905,
    'Fraser Coast': 3905,
    'Frenchville': 3905,
    'Freshwater': 3905,
    'Gailes': 3905,
    'Garbutt': 3905,
    'Gatton': 3905,
    'Gaven': 3905,
    'Gayndah': 3905,
    'Gaythorne': 3905,
    'Geebung': 3905,
    'Gilston': 3905,
    'Gin Gin': 3905,
    'Gladstone': 3905,
    'Gladstone Central': 3905,
    'Glass House Mountains': 3905,
    'Glen Eden': 3905,
    'Gleneagle': 3905,
    'Glenella': 3905,
    'Glenvale': 3905,
    'Glenview': 3905,
    'Gold Coast': 3905,
    'Golden Beach': 3905,
    'Gooburrum': 3905,
    'Goodna': 3905,
    'Goondiwindi': 3905,
    'Gordon Park': 3905,
    'Gordonvale': 3905,
    'Gowrie Junction': 3905,
    'Gracemere': 3905,
    'Graceville': 3905,
    'Grange': 3905,
    'Granville': 3905,
    'Greenbank': 3905,
    'Greenslopes': 3905,
    'Griffin': 3905,
    'Gulliver': 3905,
    'Gumdale': 3905,
    'Gympie': 3905,
    'Gympie Regional Council': 3905,
    'Hamilton': 3905,
    'Harlaxton': 3905,
    'Harristown': 3905,
    'Hatton Vale': 3905,
    'Hawthorne': 3905,
    'Hay Point': 3905,
    'Healy': 3905,
    'Heathwood': 3905,
    'Heatley': 3905,
    'Helensvale': 3905,
    'Helidon': 3905,
    'Hemmant': 3905,
    'Hendra': 3905,
    'Heritage Park': 3905,
    'Hermit Park': 3905,
    'Herston': 3905,
    'Hervey Bay': 3905,
    'Highfields': 3905,
    'Highgate Hill': 3905,
    'Highland Park': 3905,
    'Highvale': 3905,
    'Hillcrest': 3905,
    'Hinchinbrook': 3905,
    'Hodgson Vale': 3905,
    'Holland Park': 3905,
    'Holland Park West': 3905,
    'Holloways Beach': 3905,
    'Hollywell': 3905,
    'Holmview': 3905,
    'Home Hill': 3905,
    'Hope Island': 3905,
    'Hope Vale': 3905,
    'Howard': 3905,
    'Hughenden': 3905,
    'Hyde Park': 3905,
    'Idalia': 3905,
    'Inala': 3905,
    'Indooroopilly': 3905,
    'Ingham': 3905,
    'Innes Park': 3905,
    'Innisfail': 3905,
    'Innisfail Estate': 3905,
    'Ipswich': 3905,
    'Isaac': 3905,
    'Jacobs Well': 3905,
    'Jamboree Heights': 3905,
    'Jandowae': 3905,
    'Jensen': 3905,
    'Jimboomba': 3905,
    'Jindalee': 3905,
    'Joyner': 3905,
    'Jubilee Pocket': 3905,
    'Julatten': 3905,
    'Kalbar': 3905,
    'Kalinga': 3905,
    'Kalkie': 3905,
    'Kallangur': 3905,
    'Kamerunga': 3905,
    'Kangaroo Point': 3905,
    'Kanimbla': 3905,
    'Karalee': 3905,
    'Karana Downs': 3905,
    'Karumba': 3905,
    'Kawana': 3905,
    'Kawungan': 3905,
    'Kearneys Spring': 3905,
    'Kedron': 3905,
    'Kelso': 3905,
    'Kelvin Grove': 3905,
    'Kenmore': 3905,
    'Kenmore Hills': 3905,
    'Kensington Grove': 3905,
    'Keperra': 3905,
    'Kepnock': 3905,
    'Kewarra Beach': 3905,
    'Kilcoy': 3905,
    'Kin Kora': 3905,
    'Kingaroy': 3905,
    'Kings Beach': 3905,
    'Kingsthorpe': 3905,
    'Kingston': 3905,
    'Kippa-Ring': 3905,
    'Kirkwood': 3905,
    'Kirwan': 3905,
    'Kleinton': 3905,
    'Koongal': 3905,
    'Kooralbyn': 3905,
    'Kowanyama': 3905,
    'Kuluin': 3905,
    'Kuraby': 3905,
    'Kuranda': 3905,
    'Kurwongbah': 3905,
    'Labrador': 3905,
    'Laidley': 3905,
    'Lake Macdonald': 3905,
    'Lammermoor': 3905,
    'Landsborough': 3905,
    'Lawnton': 3905,
    'Leichhardt': 3905,
    'Little Mountain': 3905,
    'Lockhart River': 3905,
    'Lockyer Valley': 3905,
    'Logan': 3905,
    'Logan Central': 3905,
    'Logan City': 3905,
    'Logan Reserve': 3905,
    'Logan Village': 3905,
    'Loganholme': 3905,
    'Loganlea': 3905,
    'Longreach': 3905,
    'Lota': 3905,
    'Lower Beechmont': 3905,
    'Lowood': 3905,
    'Lutwyche': 3905,
    'Macgregor': 3905,
    'Machans Beach': 3905,
    'Mackay': 3905,
    'Mackay City': 3905,
    'Mackenzie': 3905,
    'Macleay Island': 3905,
    'Magnetic Island': 3905,
    'Main Beach': 3905,
    'Malanda': 3905,
    'Maleny': 3905,
    'Mango Hill': 3905,
    'Manly West': 3905,
    'Manoora': 3905,
    'Mansfield': 3905,
    'Manunda': 3905,
    'Mapleton': 3905,
    'Mapoon': 3905,
    'Maranoa': 3905,
    'Marcoola': 3905,
    'Mareeba': 3905,
    'Margate': 3905,
    'Marian': 3905,
    'Maroochy River': 3905,
    'Maroochydore': 3905,
    'Marsden': 3905,
    'Maryborough': 3905,
    'Maudsland': 3905,
    'McDowall': 3905,
    'McKinlay': 3905,
    'Meadowbrook': 3905,
    'Menzies': 3905,
    'Meridan Plains': 3905,
    'Meringandan West': 3905,
    'Mermaid Beach': 3905,
    'Mermaid Waters': 3905,
    'Merrimac': 3905,
    'Miami': 3905,
    'Middle Park': 3905,
    'Middle Ridge': 3905,
    'Middlemount': 3905,
    'Miles': 3905,
    'Millbank': 3905,
    'Millmerran': 3905,
    'Millstream': 3905,
    'Milton': 3905,
    'Minden': 3905,
    'Minyama': 3905,
    'Mirani': 3905,
    'Mission Beach': 3905,
    'Mission River': 3905,
    'Mitchell': 3905,
    'Mitchelton': 3905,
    'Moffat Beach': 3905,
    'Moggill': 3905,
    'Molendinar': 3905,
    'Monkland': 3905,
    'Monto': 3905,
    'Montville': 3905,
    'Mooloolaba': 3905,
    'Moore Park Beach': 3905,
    'Mooroobool': 3905,
    'Moorooka': 3905,
    'Moranbah': 3905,
    'Morayfield': 3905,
    'Moreton Bay': 3905,
    'Morningside': 3905,
    'Mornington': 3905,
    'Mossman': 3905,
    'Mount Coolum': 3905,
    'Mount Cotton': 3905,
    'Mount Crosby': 3905,
    'Mount Gravatt': 3905,
    'Mount Gravatt East': 3905,
    'Mount Isa': 3905,
    'Mount Lofty': 3905,
    'Mount Louisa': 3905,
    'Mount Low': 3905,
    'Mount Morgan': 3905,
    'Mount Nathan': 3905,
    'Mount Ommaney': 3905,
    'Mount Pleasant': 3905,
    'Mount Sheridan': 3905,
    'Mount Warren Park': 3905,
    'Mountain Creek': 3905,
    'Moura': 3905,
    'Mudgeeraba': 3905,
    'Mudjimba': 3905,
    'Mulambin': 3905,
    'Mundingburra': 3905,
    'Mundoolun': 3905,
    'Mundubbera': 3905,
    'Munruben': 3905,
    'Murarrie': 3905,
    'Murgon': 3905,
    'Murray': 3905,
    'Murrumba Downs': 3905,
    'Murweh': 3905,
    'Nambour': 3905,
    'Nanango': 3905,
    'Nanum': 3905,
    'Napranum': 3905,
    'Narangba': 3905,
    'Nathan': 3905,
    'Nebo': 3905,
    'Nelly Bay': 3905,
    'Nerang': 3905,
    'New Auckland': 3905,
    'New Beith': 3905,
    'New Farm': 3905,
    'Newmarket': 3905,
    'Newport': 3905,
    'Newstead': 3905,
    'Newtown': 3905,
    'Ninderry': 3905,
    'Ningi': 3905,
    'Nome': 3905,
    'Noosa Heads': 3905,
    'Noosaville': 3905,
    'Norman Gardens': 3905,
    'Norman Park': 3905,
    'Normanton': 3905,
    'North Booval': 3905,
    'North Burnett': 3905,
    'North Ipswich': 3905,
    'North Lakes': 3905,
    'North Mackay': 3905,
    'North Maclean': 3905,
    'North Toowoomba': 3905,
    'North Ward': 3905,
    'Northern Peninsula Area': 3905,
    'Norville': 3905,
    'Nudgee': 3905,
    'Nundah': 3905,
    'Oakey': 3905,
    'Oakhurst': 3905,
    'One Mile': 3905,
    'Oonoonba': 3905,
    'Ooralea': 3905,
    'Ormeau': 3905,
    'Ormeau Hills': 3905,
    'Ormiston': 3905,
    'Oxenford': 3905,
    'Oxley': 3905,
    'Pacific Paradise': 3905,
    'Pacific Pines': 3905,
    'Paddington': 3905,
    'Palm Beach': 3905,
    'Palm Cove': 3905,
    'Palm Island': 3905,
    'Palmwoods': 3905,
    'Paradise Point': 3905,
    'Park Avenue': 3905,
    'Park Ridge': 3905,
    'Park Ridge South': 3905,
    'Parkhurst': 3905,
    'Parkinson': 3905,
    'Parkside': 3905,
    'Parkwood': 3905,
    'Paroo': 3905,
    'Parramatta Park': 3905,
    'Parrearra': 3905,
    'Peachester': 3905,
    'Pelican Waters': 3905,
    'Peregian Beach': 3905,
    'Peregian Springs': 3905,
    'Petrie': 3905,
    'Petrie Terrace': 3905,
    'Pialba': 3905,
    'Pie Creek': 3905,
    'Pimlico': 3905,
    'Pimpama': 3905,
    'Pine Mountain': 3905,
    'Pioneer': 3905,
    'Pittsworth': 3905,
    'Plainland': 3905,
    'Point Vernon': 3905,
    'Pomona': 3905,
    'Pormpuraaw': 3905,
    'Port Douglas': 3905,
    'Proserpine': 3905,
    'Pullenvale': 3905,
    'Queenton': 3905,
    'Quilpie': 3905,
    'Raceview': 3905,
    'Railway Estate': 3905,
    'Rainbow Beach': 3905,
    'Rangeville': 3905,
    'Rangewood': 3905,
    'Rasmussen': 3905,
    'Ravenshoe': 3905,
    'Red Hill': 3905,
    'Redbank': 3905,
    'Redbank Plains': 3905,
    'Redland': 3905,
    'Redland Bay': 3905,
    'Redlynch': 3905,
    'Reedy Creek': 3905,
    'Regency Downs': 3905,
    'Regents Park': 3905,
    'Richlands': 3905,
    'Richmond': 3905,
    'River Heads': 3905,
    'Riverhills': 3905,
    'Riverview': 3905,
    'Robertson': 3905,
    'Robina': 3905,
    'Rochedale': 3905,
    'Rochedale South': 3905,
    'Rockhampton': 3905,
    'Rocklea': 3905,
    'Rockville': 3905,
    'Roma': 3905,
    'Rosemount': 3905,
    'Rosenthal Heights': 3905,
    'Rosewood': 3905,
    'Rosslea': 3905,
    'Rothwell': 3905,
    'Runaway Bay': 3905,
    'Runcorn': 3905,
    'Rural View': 3905,
    'Russell Island': 3905,
    'Sadliers Crossing': 3905,
    'Salisbury': 3905,
    'Samford Valley': 3905,
    'Sandgate': 3905,
    'Sandstone Point': 3905,
    'Sarina': 3905,
    'Scarborough': 3905,
    'Scarness': 3905,
    'Scenic Rim': 3905,
    'Seven Hills': 3905,
    'Seventeen Mile Rocks': 3905,
    'Shailer Park': 3905,
    'Sharon': 3905,
    'Sheldon': 3905,
    'Shorncliffe': 3905,
    'Silkstone': 3905,
    'Sinnamon Park': 3905,
    'Sippy Downs': 3905,
    'Slacks Creek': 3905,
    'Slade Point': 3905,
    'Soldiers Hill': 3905,
    'Somerset': 3905,
    'South Brisbane': 3905,
    'South Burnett': 3905,
    'South Gladstone': 3905,
    'South Kolan': 3905,
    'South Mackay': 3905,
    'South Maclean': 3905,
    'South Toowoomba': 3905,
    'South Townsville': 3905,
    'Southern Downs': 3905,
    'Southport': 3905,
    'Southside': 3905,
    'Spring Hill': 3905,
    'Springfield': 3905,
    'Springfield Lakes': 3905,
    'Springsure': 3905,
    'Springwood': 3905,
    'St George': 3905,
    'Stafford': 3905,
    'Stafford Heights': 3905,
    'Stanthorpe': 3905,
    'Strathpine': 3905,
    'Stretton': 3905,
    'Stuart': 3905,
    'Sun Valley': 3905,
    'Sunnybank': 3905,
    'Sunnybank Hills': 3905,
    'Sunrise Beach': 3905,
    'Sunset': 3905,
    'Sunshine Beach': 3905,
    'Sunshine Coast': 3905,
    'Surfers Paradise': 3905,
    'Svensson Heights': 3905,
    'Taigum': 3905,
    'Tallai': 3905,
    'Tallebudgera': 3905,
    'Tallebudgera Valley': 3905,
    'Tamborine': 3905,
    'Tamborine Mountain': 3905,
    'Tanah Merah': 3905,
    'Tanawha': 3905,
    'Tannum Sands': 3905,
    'Tara': 3905,
    'Taranganba': 3905,
    'Taringa': 3905,
    'Tarragindi': 3905,
    'Telina': 3905,
    'Teneriffe': 3905,
    'Tewantin': 3905,
    'Thabeban': 3905,
    'The Gap': 3905,
    'The Gemfields': 3905,
    'The Range': 3905,
    'Thorneside': 3905,
    'Thornlands': 3905,
    'Thursday Island': 3905,
    'Tieri': 3905,
    'Tin Can Bay': 3905,
    'Tinana': 3905,
    'Tingalpa': 3905,
    'Tivoli': 3905,
    'Tolga': 3905,
    'Toogoolawah': 3905,
    'Toogoom': 3905,
    'Toowong': 3905,
    'Toowoomba': 3905,
    'Torquay': 3905,
    'Torres': 3905,
    'Torres Strait Island Region': 3905,
    'Townsville': 3905,
    'Townview': 3905,
    'Trinity Beach': 3905,
    'Trinity Park': 3905,
    'Trunding': 3905,
    'Tugun': 3905,
    'Tully': 3905,
    'Twin Waters': 3905,
    'Underwood': 3905,
    'Upper Caboolture': 3905,
    'Upper Coomera': 3905,
    'Upper Kedron': 3905,
    'Upper Mount Gravatt': 3905,
    'Urangan': 3905,
    'Urraween': 3905,
    'Varsity Lakes': 3905,
    'Victoria Point': 3905,
    'Vincent': 3905,
    'Virginia': 3905,
    'Wacol': 3905,
    'Wakerley': 3905,
    'Walkerston': 3905,
    'Walkervale': 3905,
    'Walloon': 3905,
    'Wamuran': 3905,
    'Wandal': 3905,
    'Wandoan': 3905,
    'Warana': 3905,
    'Warner': 3905,
    'Warwick': 3905,
    'Waterford West': 3905,
    'Wavell Heights': 3905,
    'Weipa': 3905,
    'Wellesley Islands': 3905,
    'Wellington Point': 3905,
    'West End': 3905,
    'West Gladstone': 3905,
    'West Mackay': 3905,
    'West Rockhampton': 3905,
    'West Woombye': 3905,
    'Westbrook': 3905,
    'Westcourt': 3905,
    'Western Downs': 3905,
    'Westlake': 3905,
    'White Rock': 3905,
    'Whitfield': 3905,
    'Whitsunday': 3905,
    'Whitsundays': 3905,
    'Willow Vale': 3905,
    'Willowbank': 3905,
    'Wilsonton': 3905,
    'Wilsonton Heights': 3905,
    'Wilston': 3905,
    'Windaroo': 3905,
    'Windsor': 3905,
    'Winston': 3905,
    'Winton': 3905,
    'Wishart': 3905,
    'Withcott': 3905,
    'Witta': 3905,
    'Wondai': 3905,
    'Wondunna': 3905,
    'Wongaling Beach': 3905,
    'Wongawallan': 3905,
    'Woodend': 3905,
    'Woodford': 3905,
    'Woodgate': 3905,
    'Woodridge': 3905,
    'Woody Point': 3905,
    'Woolloongabba': 3905,
    'Wooloowin': 3905,
    'Woombye': 3905,
    'Woorabinda': 3905,
    'Woorim': 3905,
    'Woree': 3905,
    'Worongary': 3905,
    'Wujal Wujal': 3905,
    'Wulguru': 3905,
    'Wulkuraka': 3905,
    'Wurtulla': 3905,
    'Wynnum': 3905,
    'Wynnum West': 3905,
    'Wyreema': 3905,
    'Yamanto': 3905,
    'Yandina': 3905,
    'Yaroomba': 3905,
    'Yarrabah': 3905,
    'Yarrabilba': 3905,
    'Yarraman': 3905,
    'Yatala': 3905,
    'Yeerongpilly': 3905,
    'Yeppoon': 3905,
    'Yeppoon city centre': 3905,
    'Yeronga': 3905,
    'Yorkeys Knob': 3905,
    'Yungaburra': 3905,
    'Zillmere': 3905,
    'Zilzie': 3905,
    'Aberfoyle Park': 3904,
    'Adelaide': 3904,
    'Adelaide city centre': 3904,
    'Adelaide Hills': 3904,
    'Albert Park': 3904,
    'Alberton': 3904,
    'Aldgate': 3904,
    'Aldinga Beach': 3904,
    'Alexandrina': 3904,
    'Allenby Gardens': 3904,
    'Anangu Pitjantjatjara': 3904,
    'Andrews Farm': 3904,
    'Angaston': 3904,
    'Angle Park': 3904,
    'Angle Vale': 3904,
    'Ardrossan': 3904,
    'Ascot Park': 3904,
    'Ashford': 3904,
    'Athelstone': 3904,
    'Athol Park': 3904,
    'Balaklava': 3904,
    'Balhannah': 3904,
    'Banksia Park': 3904,
    'Barmera': 3904,
    'Barossa': 3904,
    'Barunga West': 3904,
    'Beaumont': 3904,
    'Bedford Park': 3904,
    'Belair': 3904,
    'Bellevue Heights': 3904,
    'Berri': 3904,
    'Berri and Barmera': 3904,
    'Beulah Park': 3904,
    'Beverley': 3904,
    'Birdwood': 3904,
    'Birkenhead': 3904,
    'Black Forest': 3904,
    'Blackwood': 3904,
    'Blair Athol': 3904,
    'Blakeview': 3904,
    'Bordertown': 3904,
    'Brahma Lodge': 3904,
    'Bridgewater': 3904,
    'Brighton': 3904,
    'Broadview': 3904,
    'Brompton': 3904,
    'Brooklyn Park': 3904,
    'Burnside': 3904,
    'Burra': 3904,
    'Burton': 3904,
    'Camden Park': 3904,
    'Campbelltown': 3904,
    'Ceduna': 3904,
    'Charles Sturt': 3904,
    'Cheltenham': 3904,
    'Christie Downs': 3904,
    'Christies Beach': 3904,
    'City of West Torrens': 3904,
    'Clapham': 3904,
    'Clare': 3904,
    'Clare and Gilbert Valleys': 3904,
    'Clarence Gardens': 3904,
    'Clarence Park': 3904,
    'Clearview': 3904,
    'Cleve': 3904,
    'Clovelly Park': 3904,
    'Collinswood': 3904,
    'Colonel Light Gardens': 3904,
    'Coober Pedy': 3904,
    'Copper Coast': 3904,
    'Coromandel Valley': 3904,
    'Cowandilla': 3904,
    'Cowell': 3904,
    'Crafers': 3904,
    'Crafers West': 3904,
    'Craigburn Farm': 3904,
    'Craigmore': 3904,
    'Croydon Park': 3904,
    'Crystal Brook': 3904,
    'Cumberland Park': 3904,
    'Darlington': 3904,
    'Davoren Park': 3904,
    'Daw Park': 3904,
    'Dernancourt': 3904,
    'Dover Gardens': 3904,
    'Dulwich': 3904,
    'Echunga': 3904,
    'Eden Hills': 3904,
    'Edwardstown': 3904,
    'Elizabeth Downs': 3904,
    'Elizabeth East': 3904,
    'Elizabeth Grove': 3904,
    'Elizabeth North': 3904,
    'Elizabeth Park': 3904,
    'Elizabeth South': 3904,
    'Elizabeth Vale': 3904,
    'Elliston': 3904,
    'Encounter Bay': 3904,
    'Enfield': 3904,
    'Erindale': 3904,
    'Ethelton': 3904,
    'Evandale': 3904,
    'Evanston': 3904,
    'Evanston Gardens': 3904,
    'Evanston Park': 3904,
    'Everard Park': 3904,
    'Exeter': 3904,
    'Fairview Park': 3904,
    'Felixstow': 3904,
    'Ferryden Park': 3904,
    'Findon': 3904,
    'Firle': 3904,
    'Flagstaff Hill': 3904,
    'Flinders Park': 3904,
    'Flinders Ranges': 3904,
    'Forestville': 3904,
    'Franklin Harbour': 3904,
    'Freeling': 3904,
    'Fulham': 3904,
    'Fulham Gardens': 3904,
    'Fullarton': 3904,
    'Gawler': 3904,
    'Gawler East': 3904,
    'Gawler South': 3904,
    'Gilberton': 3904,
    'Gilles Plains': 3904,
    'Glandore': 3904,
    'Glen Osmond': 3904,
    'Glenalta': 3904,
    'Glenelg': 3904,
    'Glenelg East': 3904,
    'Glenelg North': 3904,
    'Glenelg South': 3904,
    'Glengowrie': 3904,
    'Glenside': 3904,
    'Glenunga': 3904,
    'Glynde': 3904,
    'Golden Grove': 3904,
    'Goodwood': 3904,
    'Goolwa': 3904,
    'Goolwa Beach': 3904,
    'Goyder': 3904,
    'Grange': 3904,
    'Grant': 3904,
    'Greenacres': 3904,
    'Greenock': 3904,
    'Greenwith': 3904,
    'Gulfview Heights': 3904,
    'Hackham': 3904,
    'Hackham West': 3904,
    'Hahndorf': 3904,
    'Hallett Cove': 3904,
    'Hampstead Gardens': 3904,
    'Happy Valley': 3904,
    'Hawthorn': 3904,
    'Hawthorndene': 3904,
    'Hayborough': 3904,
    'Hazelwood Park': 3904,
    'Hectorville': 3904,
    'Henley Beach': 3904,
    'Henley Beach South': 3904,
    'Hewett': 3904,
    'Highbury': 3904,
    'Highgate': 3904,
    'Hillbank': 3904,
    'Hillcrest': 3904,
    'Hindmarsh Island': 3904,
    'Holden Hill': 3904,
    'Holdfast Bay': 3904,
    'Hope Valley': 3904,
    'Hove': 3904,
    'Huntfield Heights': 3904,
    'Hyde Park': 3904,
    'Ingle Farm': 3904,
    'Jamestown': 3904,
    'Joslin': 3904,
    'Kadina': 3904,
    'Kangaroo Island': 3904,
    'Kapunda': 3904,
    'Karoonda East Murray': 3904,
    'Keith': 3904,
    'Kensington Gardens': 3904,
    'Kensington Park': 3904,
    'Kent Town': 3904,
    'Kersbrook': 3904,
    'Kidman Park': 3904,
    'Kilburn': 3904,
    'Kilkenny': 3904,
    'Kimba': 3904,
    'Kingscote': 3904,
    'Kingston': 3904,
    'Kingston South East': 3904,
    'Klemzig': 3904,
    'Kurralta Park': 3904,
    'Largs Bay': 3904,
    'Largs North': 3904,
    'Leabrook': 3904,
    'Lewiston': 3904,
    'Light': 3904,
    'Linden Park': 3904,
    'Little Hampton': 3904,
    'Lobethal': 3904,
    'Lockleys': 3904,
    'Lower Eyre Peninsula': 3904,
    'Lower Mitcham': 3904,
    'Loxton': 3904,
    'Loxton Waikerie': 3904,
    'Lyndoch': 3904,
    'Macclesfield': 3904,
    'Magill': 3904,
    'Maitland': 3904,
    'Mallala': 3904,
    'Malvern': 3904,
    'Manningham': 3904,
    'Mannum': 3904,
    'Mansfield Park': 3904,
    'Maralinga Tjarutja': 3904,
    'Marden': 3904,
    'Marino': 3904,
    'Marion': 3904,
    'Marleston': 3904,
    'Maslin Beach': 3904,
    'Mawson Lakes': 3904,
    'Maylands': 3904,
    'McCracken': 3904,
    'McLaren Flat': 3904,
    'McLaren Vale': 3904,
    'Meadows': 3904,
    'Medindie': 3904,
    'Melrose Park': 3904,
    'Meningie': 3904,
    'Mid Murray': 3904,
    'Middleton': 3904,
    'Mile End': 3904,
    'Millicent': 3904,
    'Millswood': 3904,
    'Minlaton': 3904,
    'Mitcham': 3904,
    'Mitchell Park': 3904,
    'Moana': 3904,
    'Modbury': 3904,
    'Modbury Heights': 3904,
    'Modbury North': 3904,
    'Monash': 3904,
    'Moonta Bay': 3904,
    'Moorak': 3904,
    'Morphett Vale': 3904,
    'Morphettville': 3904,
    'Mount Barker': 3904,
    'Mount Compass': 3904,
    'Mount Gambier': 3904,
    'Mount Remarkable': 3904,
    'Munno Para': 3904,
    'Munno Para West': 3904,
    'Murray Bridge': 3904,
    'Mylor': 3904,
    'Myrtle Bank': 3904,
    'Nailsworth': 3904,
    'Nairne': 3904,
    'Naracoorte': 3904,
    'Naracoorte and Lucindale': 3904,
    'Netherby': 3904,
    'Netley': 3904,
    'Newton': 3904,
    'Noarlunga Downs': 3904,
    'Normanville': 3904,
    'North Adelaide': 3904,
    'North Brighton': 3904,
    'North Haven': 3904,
    'North Plympton': 3904,
    'Northern Areas': 3904,
    'Northfield': 3904,
    'Northgate': 3904,
    'Norwood': 3904,
    'Norwood Payneham St Peters': 3904,
    'Novar Gardens': 3904,
    'Nuriootpa': 3904,
    'O  Sullivan Beach': 3904,
    'O’Halloran Hill': 3904,
    'Oakden': 3904,
    'Oaklands Park': 3904,
    'Old Noarlunga': 3904,
    'Old Reynella': 3904,
    'One Tree Hill': 3904,
    'Onkaparinga': 3904,
    'Onkaparinga Hills': 3904,
    'Orroroo/Carrieton': 3904,
    'Osborne': 3904,
    'Ottoway': 3904,
    'Panorama': 3904,
    'Para Hills': 3904,
    'Para Hills West': 3904,
    'Para Vista': 3904,
    'Paradise': 3904,
    'Parafield Gardens': 3904,
    'Paralowie': 3904,
    'Paringa': 3904,
    'Park Holme': 3904,
    'Parkside': 3904,
    'Pasadena': 3904,
    'Payneham': 3904,
    'Payneham South': 3904,
    'Pennington': 3904,
    'Penola': 3904,
    'Peterborough': 3904,
    'Peterhead': 3904,
    'Playford': 3904,
    'Plympton': 3904,
    'Plympton Park': 3904,
    'Pooraka': 3904,
    'Port Adelaide': 3904,
    'Port Adelaide Enfield': 3904,
    'Port Augusta': 3904,
    'Port Augusta West': 3904,
    'Port Broughton': 3904,
    'Port Elliot': 3904,
    'Port Lincoln': 3904,
    'Port Noarlunga': 3904,
    'Port Noarlunga South': 3904,
    'Port Pirie': 3904,
    'Port Pirie City and Dists': 3904,
    'Port Pirie South': 3904,
    'Port Pirie West': 3904,
    'Port Willunga': 3904,
    'Prospect': 3904,
    'Queenstown': 3904,
    'Quorn': 3904,
    'Redwood Park': 3904,
    'Renmark': 3904,
    'Renmark Paringa': 3904,
    'Renmark West': 3904,
    'Renown Park': 3904,
    'Reynella': 3904,
    'Reynella East': 3904,
    'Richmond': 3904,
    'Ridgehaven': 3904,
    'Ridleyton': 3904,
    'Risdon Park': 3904,
    'Risdon Park South': 3904,
    'Robe': 3904,
    'Rose Park': 3904,
    'Rosewater': 3904,
    'Rosslyn Park': 3904,
    'Rostrevor': 3904,
    'Roxby Downs': 3904,
    'Royal Park': 3904,
    'Royston Park': 3904,
    'Salisbury': 3904,
    'Salisbury Downs': 3904,
    'Salisbury East': 3904,
    'Salisbury Heights': 3904,
    'Salisbury North': 3904,
    'Salisbury Park': 3904,
    'Salisbury Plain': 3904,
    'Seacliff': 3904,
    'Seacliff Park': 3904,
    'Seacombe Gardens': 3904,
    'Seacombe Heights': 3904,
    'Seaford': 3904,
    'Seaford Meadows': 3904,
    'Seaford Rise': 3904,
    'Seaton': 3904,
    'Seaview Downs': 3904,
    'Sefton Park': 3904,
    'Sellicks Beach': 3904,
    'Semaphore': 3904,
    'Semaphore Park': 3904,
    'Semaphore South': 3904,
    'Sheidow Park': 3904,
    'Smithfield': 3904,
    'Smithfield Plains': 3904,
    'Solomontown': 3904,
    'Somerton Park': 3904,
    'South Brighton': 3904,
    'South Plympton': 3904,
    'Southern Mallee': 3904,
    'St Agnes': 3904,
    'St Georges': 3904,
    'St Marys': 3904,
    'St Morris': 3904,
    'St Peters': 3904,
    'Stirling': 3904,
    'Stirling North': 3904,
    'Stonyfell': 3904,
    'Strathalbyn': 3904,
    'Streaky Bay': 3904,
    'Sturt': 3904,
    'Surrey Downs': 3904,
    'Tailem Bend': 3904,
    'Tanunda': 3904,
    'Taperoo': 3904,
    'Tatiara': 3904,
    'Tea Tree Gully': 3904,
    'Tennyson': 3904,
    'The Coorong': 3904,
    'Thebarton': 3904,
    'Toorak Gardens': 3904,
    'Torrens Park': 3904,
    'Torrensville': 3904,
    'Tranmere': 3904,
    'Trinity Gardens': 3904,
    'Trott Park': 3904,
    'Tumby Bay': 3904,
    'Tusmore': 3904,
    'Two Wells': 3904,
    'Underdale': 3904,
    'Unley': 3904,
    'Unley Park': 3904,
    'Vale Park': 3904,
    'Valley View': 3904,
    'Victor Harbor': 3904,
    'Virginia': 3904,
    'Waikerie': 3904,
    'Wakefield': 3904,
    'Walkerville': 3904,
    'Walkley Heights': 3904,
    'Wallaroo': 3904,
    'Warradale': 3904,
    'Waterloo Corner': 3904,
    'Wattle Park': 3904,
    'Wattle Range': 3904,
    'Wayville': 3904,
    'West Beach': 3904,
    'West Croydon': 3904,
    'West Hindmarsh': 3904,
    'West Lakes': 3904,
    'West Lakes Shore': 3904,
    'Westbourne Park': 3904,
    'Whyalla': 3904,
    'Whyalla Jenkins': 3904,
    'Whyalla Norrie': 3904,
    'Whyalla Playford': 3904,
    'Whyalla Stuart': 3904,
    'Willaston': 3904,
    'Williamstown': 3904,
    'Willunga': 3904,
    'Windsor Gardens': 3904,
    'Woodcroft': 3904,
    'Woodside': 3904,
    'Woodville': 3904,
    'Woodville Gardens': 3904,
    'Woodville North': 3904,
    'Woodville Park': 3904,
    'Woodville South': 3904,
    'Woodville West': 3904,
    'Wudinna': 3904,
    'Wynn Vale': 3904,
    'Yankalilla': 3904,
    'Yorke Peninsula': 3904,
    'Acton Park': 3908,
    'Austins Ferry': 3908,
    'Bagdad': 3908,
    'Battery Point': 3908,
    'Beaconsfield': 3908,
    'Beauty Point': 3908,
    'Bellerive': 3908,
    'Berriedale': 3908,
    'Blackmans Bay': 3908,
    'Blackstone Heights': 3908,
    'Break O  Day': 3908,
    'Bridgewater': 3908,
    'Bridport': 3908,
    'Brighton': 3908,
    'Burnie': 3908,
    'Cambridge': 3908,
    'Central Coast': 3908,
    'Central Highlands': 3908,
    'Chigwell': 3908,
    'Circular Head': 3908,
    'Claremont': 3908,
    'Clarence': 3908,
    'Clarendon Vale': 3908,
    'Cressy': 3908,
    'Currie': 3908,
    'Cygnet': 3908,
    'Deloraine': 3908,
    'Derwent Valley': 3908,
    'Devonport': 3908,
    'Dodges Ferry': 3908,
    'Dorset': 3908,
    'Dynnyrne': 3908,
    'East Devonport': 3908,
    'East Launceston': 3908,
    'Evandale': 3908,
    'Flinders': 3908,
    'Franklin': 3908,
    'Gagebrook': 3908,
    'Geeveston': 3908,
    'Geilston Bay': 3908,
    'George Town': 3908,
    'Glamorgan/Spring Bay': 3908,
    'Glenorchy': 3908,
    'Goodwood': 3908,
    'Granton': 3908,
    'Hadspen': 3908,
    'Herdsmans Cove': 3908,
    'Hillcrest': 3908,
    'Hobart': 3908,
    'Hobart city centre': 3908,
    'Howrah': 3908,
    'Huon Valley': 3908,
    'Huonville': 3908,
    'Invermay': 3908,
    'Kentish': 3908,
    'King Island': 3908,
    'Kingborough': 3908,
    'Kings Meadows': 3908,
    'Kingston': 3908,
    'Kingston Beach': 3908,
    'Latrobe': 3908,
    'Lauderdale': 3908,
    'Launceston': 3908,
    'Launceston city centre': 3908,
    'Legana': 3908,
    'Lenah Valley': 3908,
    'Lindisfarne': 3908,
    'Longford': 3908,
    'Lutana': 3908,
    'Margate': 3908,
    'Mayfield': 3908,
    'Meander Valley': 3908,
    'Miandetta': 3908,
    'Midway Point': 3908,
    'Montello': 3908,
    'Montrose': 3908,
    'Moonah': 3908,
    'Mornington': 3908,
    'Mount Nelson': 3908,
    'Mount Stuart': 3908,
    'Mowbray': 3908,
    'New Norfolk': 3908,
    'New Town': 3908,
    'Newnham': 3908,
    'Newstead': 3908,
    'North Hobart': 3908,
    'Northern Midlands': 3908,
    'Norwood': 3908,
    'Oakdowns': 3908,
    'Old Beach': 3908,
    'Park Grove': 3908,
    'Penguin': 3908,
    'Perth': 3908,
    'Port Sorell': 3908,
    'Prospect Vale': 3908,
    'Queenstown': 3908,
    'Ranelagh': 3908,
    'Ravenswood': 3908,
    'Richmond': 3908,
    'Risdon Vale': 3908,
    'Riverside': 3908,
    'Rocherlea': 3908,
    'Rokeby': 3908,
    'Romaine': 3908,
    'Rosetta': 3908,
    'Saint Leonards': 3908,
    'Sandford': 3908,
    'Sandy Bay': 3908,
    'Scottsdale': 3908,
    'Seven Mile Beach': 3908,
    'Shearwater': 3908,
    'Sheffield': 3908,
    'Shorewell Park': 3908,
    'Smithton': 3908,
    'Snug': 3908,
    'Somerset': 3908,
    'Sorell': 3908,
    'South Hobart': 3908,
    'South Launceston': 3908,
    'Southern Midlands': 3908,
    'Spreyton': 3908,
    'St Helens': 3908,
    'Summerhill': 3908,
    'Taroona': 3908,
    'Tasman Peninsula': 3908,
    'Tranmere': 3908,
    'Trevallyn': 3908,
    'Turners Beach': 3908,
    'Ulverstone': 3908,
    'Upper Burnie': 3908,
    'Waratah/Wynyard': 3908,
    'Warrane': 3908,
    'Waverley': 3908,
    'West Coast': 3908,
    'West Hobart': 3908,
    'West Launceston': 3908,
    'West Moonah': 3908,
    'West Tamar': 3908,
    'West Ulverstone': 3908,
    'Westbury': 3908,
    'Wynyard': 3908,
    'Youngtown': 3908,
    'Abbotsford': 3903,
    'Aberfeldie': 3903,
    'Airport West': 3903,
    'Albanvale': 3903,
    'Albert Park': 3903,
    'Albion': 3903,
    'Alexandra': 3903,
    'Alfredton': 3903,
    'Allansford': 3903,
    'Alphington': 3903,
    'Alpine': 3903,
    'Altona': 3903,
    'Altona Meadows': 3903,
    'Altona North': 3903,
    'Anglesea': 3903,
    'Apollo Bay': 3903,
    'Ararat': 3903,
    'Ardeer': 3903,
    'Armadale': 3903,
    'Armstrong Creek': 3903,
    'Ascot': 3903,
    'Ascot Vale': 3903,
    'Ashburton': 3903,
    'Ashwood': 3903,
    'Aspendale': 3903,
    'Aspendale Gardens': 3903,
    'Attwood': 3903,
    'Avenel': 3903,
    'Avoca': 3903,
    'Avondale Heights': 3903,
    'Bacchus Marsh': 3903,
    'Badger Creek': 3903,
    'Bairnsdale': 3903,
    'Balaclava': 3903,
    'Ballan': 3903,
    'Ballarat': 3903,
    'Ballarat Central': 3903,
    'Ballarat East': 3903,
    'Ballarat North': 3903,
    'Balnarring': 3903,
    'Balwyn': 3903,
    'Balwyn North': 3903,
    'Bannockburn': 3903,
    'Banyule': 3903,
    'Baranduda': 3903,
    'Barwon Heads': 3903,
    'Bass Coast': 3903,
    'Baw Baw': 3903,
    'Baxter': 3903,
    'Bayside': 3903,
    'Bayswater': 3903,
    'Bayswater North': 3903,
    'Beaconsfield': 3903,
    'Beaconsfield Upper': 3903,
    'Beaufort': 3903,
    'Beaumaris': 3903,
    'Beechworth': 3903,
    'Belgrave': 3903,
    'Belgrave Heights': 3903,
    'Belgrave South': 3903,
    'Bell Park': 3903,
    'Bell Post Hill': 3903,
    'Bellfield': 3903,
    'Belmont': 3903,
    'Benalla': 3903,
    'Bendigo': 3903,
    'Bendigo city centre': 3903,
    'Bentleigh': 3903,
    'Bentleigh East': 3903,
    'Berwick': 3903,
    'Beveridge': 3903,
    'Bittern': 3903,
    'Black Hill': 3903,
    'Black Rock': 3903,
    'Blackburn': 3903,
    'Blackburn North': 3903,
    'Blackburn South': 3903,
    'Blairgowrie': 3903,
    'Blind Bight': 3903,
    'Bonbeach': 3903,
    'Boronia': 3903,
    'Boroondara': 3903,
    'Botanic Ridge': 3903,
    'Box Hill': 3903,
    'Box Hill North': 3903,
    'Box Hill South': 3903,
    'Braybrook': 3903,
    'Briagolong': 3903,
    'Briar Hill': 3903,
    'Bright': 3903,
    'Brighton': 3903,
    'Brighton East': 3903,
    'Brimbank': 3903,
    'Broadford': 3903,
    'Broadmeadows': 3903,
    'Brookfield': 3903,
    'Brooklyn': 3903,
    'Brown Hill': 3903,
    'Brunswick': 3903,
    'Brunswick East': 3903,
    'Brunswick West': 3903,
    'Bulleen': 3903,
    'Buloke': 3903,
    'Bundoora': 3903,
    'Buninyong': 3903,
    'Bunyip': 3903,
    'Burnside': 3903,
    'Burnside Heights': 3903,
    'Burwood': 3903,
    'Burwood East': 3903,
    'Cairnlea': 3903,
    'California Gully': 3903,
    'Camberwell': 3903,
    'Campaspe': 3903,
    'Campbellfield': 3903,
    'Campbells Creek': 3903,
    'Camperdown': 3903,
    'Canadian': 3903,
    'Canterbury': 3903,
    'Cape Woolamai': 3903,
    'Cardinia': 3903,
    'Carisbrook': 3903,
    'Carlton': 3903,
    'Carlton North': 3903,
    'Carnegie': 3903,
    'Caroline Springs': 3903,
    'Carrum': 3903,
    'Carrum Downs': 3903,
    'Casey': 3903,
    'Casterton': 3903,
    'Castlemaine': 3903,
    'Caulfield': 3903,
    'Caulfield East': 3903,
    'Caulfield North': 3903,
    'Caulfield South': 3903,
    'Central Goldfields': 3903,
    'Chadstone': 3903,
    'Charlton': 3903,
    'Chelsea': 3903,
    'Chelsea Heights': 3903,
    'Cheltenham': 3903,
    'Chewton': 3903,
    'Chiltern': 3903,
    'Chirnside Park': 3903,
    'Churchill': 3903,
    'Clarinda': 3903,
    'Clayton': 3903,
    'Clayton South': 3903,
    'Clifton Hill': 3903,
    'Clifton Springs': 3903,
    'Clunes': 3903,
    'Clyde': 3903,
    'Clyde North': 3903,
    'Cobden': 3903,
    'Cobram': 3903,
    'Coburg': 3903,
    'Coburg North': 3903,
    'Cockatoo': 3903,
    'Cohuna': 3903,
    'Colac': 3903,
    'Colac-Otway': 3903,
    'Coldstream': 3903,
    'Collingwood': 3903,
    'Coolaroo': 3903,
    'Corangamite': 3903,
    'Corio': 3903,
    'Corryong': 3903,
    'Cowes': 3903,
    'Craigieburn': 3903,
    'Cranbourne': 3903,
    'Cranbourne East': 3903,
    'Cranbourne North': 3903,
    'Cranbourne South': 3903,
    'Cranbourne West': 3903,
    'Cremorne': 3903,
    'Creswick': 3903,
    'Crib Point': 3903,
    'Croydon': 3903,
    'Croydon Hills': 3903,
    'Croydon North': 3903,
    'Croydon South': 3903,
    'Dallas': 3903,
    'Dandenong': 3903,
    'Dandenong North': 3903,
    'Darebin': 3903,
    'Darley': 3903,
    'Daylesford': 3903,
    'Deer Park': 3903,
    'Delacombe': 3903,
    'Delahey': 3903,
    'Dennington': 3903,
    'Derrimut': 3903,
    'Devon Meadows': 3903,
    'Diamond Creek': 3903,
    'Diggers Rest': 3903,
    'Dimboola': 3903,
    'Dingley Village': 3903,
    'Dinner Plain': 3903,
    'Docklands': 3903,
    'Donald': 3903,
    'Doncaster': 3903,
    'Doncaster East': 3903,
    'Donvale': 3903,
    'Doreen': 3903,
    'Doveton': 3903,
    'Dromana': 3903,
    'Drouin': 3903,
    'Drysdale': 3903,
    'Eagle Point': 3903,
    'Eaglehawk': 3903,
    'Eaglemont': 3903,
    'East Bairnsdale': 3903,
    'East Bendigo': 3903,
    'East Geelong': 3903,
    'East Gippsland': 3903,
    'East Melbourne': 3903,
    'Echuca': 3903,
    'Eden Park': 3903,
    'Edithvale': 3903,
    'Elliminyt': 3903,
    'Elsternwick': 3903,
    'Eltham': 3903,
    'Eltham North': 3903,
    'Elwood': 3903,
    'Emerald': 3903,
    'Endeavour Hills': 3903,
    'Epping': 3903,
    'Epsom': 3903,
    'Essendon': 3903,
    'Essendon North': 3903,
    'Essendon West': 3903,
    'Eumemmerring': 3903,
    'Euroa': 3903,
    'Eynesbury': 3903,
    'Fairfield': 3903,
    'Falls Creek': 3903,
    'Fawkner': 3903,
    'Ferntree Gully': 3903,
    'Ferny Creek': 3903,
    'Fitzroy': 3903,
    'Fitzroy North': 3903,
    'Flemington': 3903,
    'Flora Hill': 3903,
    'Footscray': 3903,
    'Forest Hill': 3903,
    'Foster': 3903,
    'Frankston': 3903,
    'Frankston East': 3903,
    'Frankston North': 3903,
    'Frankston South': 3903,
    'Gannawarra': 3903,
    'Garfield': 3903,
    'Geelong': 3903,
    'Geelong city centre': 3903,
    'Geelong West': 3903,
    'Gembrook': 3903,
    'Gisborne': 3903,
    'Gladstone Park': 3903,
    'Glen Eira': 3903,
    'Glen Huntly': 3903,
    'Glen Iris': 3903,
    'Glen Waverley': 3903,
    'Glenelg': 3903,
    'Glenferrie': 3903,
    'Glengarry': 3903,
    'Glenroy': 3903,
    'Golden Plains': 3903,
    'Golden Point': 3903,
    'Golden Square': 3903,
    'Gordon': 3903,
    'Gowanbrae': 3903,
    'Greater Bendigo': 3903,
    'Greater Dandenong': 3903,
    'Greater Geelong': 3903,
    'Greater Shepparton': 3903,
    'Greensborough': 3903,
    'Greenvale': 3903,
    'Grovedale': 3903,
    'Haddon': 3903,
    'Hadfield': 3903,
    'Hallam': 3903,
    'Hamilton': 3903,
    'Hamlyn Heights': 3903,
    'Hampton': 3903,
    'Hampton East': 3903,
    'Hampton Park': 3903,
    'Hastings': 3903,
    'Haven': 3903,
    'Hawthorn': 3903,
    'Hawthorn East': 3903,
    'Hawthorn South': 3903,
    'Hazelwood North': 3903,
    'Healesville': 3903,
    'Heathcote': 3903,
    'Heatherton': 3903,
    'Heathmont': 3903,
    'Heidelberg': 3903,
    'Heidelberg Heights': 3903,
    'Heidelberg West': 3903,
    'Hepburn': 3903,
    'Herne Hill': 3903,
    'Heyfield': 3903,
    'Heywood': 3903,
    'Highett': 3903,
    'Highton': 3903,
    'Hillside': 3903,
    'Hindmarsh': 3903,
    'Hmas Cerberus': 3903,
    'Hobsons Bay': 3903,
    'Hoppers Crossing': 3903,
    'Horsham': 3903,
    'Hotham Heights': 3903,
    'Hughesdale': 3903,
    'Hume': 3903,
    'Huntingdale': 3903,
    'Huntly': 3903,
    'Hurstbridge': 3903,
    'Indented Head': 3903,
    'Indigo': 3903,
    'Inverleigh': 3903,
    'Inverloch': 3903,
    'Invermay Park': 3903,
    'Ironbark': 3903,
    'Irymple': 3903,
    'Ivanhoe': 3903,
    'Ivanhoe East': 3903,
    'Jacana': 3903,
    'Jackass Flat': 3903,
    'Jan Juc': 3903,
    'Junction Village': 3903,
    'Junortoun': 3903,
    'Kalimna': 3903,
    'Kallista': 3903,
    'Kalorama': 3903,
    'Kangaroo Flat': 3903,
    'Kangaroo Ground': 3903,
    'Kealba': 3903,
    'Keilor': 3903,
    'Keilor Downs': 3903,
    'Keilor East': 3903,
    'Keilor Lodge': 3903,
    'Keilor Park': 3903,
    'Kennington': 3903,
    'Kensington': 3903,
    'Kerang': 3903,
    'Kew': 3903,
    'Kew East': 3903,
    'Keysborough': 3903,
    'Kialla': 3903,
    'Kilmore': 3903,
    'Kilsyth': 3903,
    'Kilsyth South': 3903,
    'Kinglake': 3903,
    'Kinglake West': 3903,
    'Kings Park': 3903,
    'Kingsbury': 3903,
    'Kingston': 3903,
    'Kingsville': 3903,
    'Knox': 3903,
    'Knoxfield': 3903,
    'Koo-Wee-Rup': 3903,
    'Koroit': 3903,
    'Korumburra': 3903,
    'Kurunjang': 3903,
    'Kyabram': 3903,
    'Kyneton': 3903,
    'Lake Gardens': 3903,
    'Lake Wendouree': 3903,
    'Lakes Entrance': 3903,
    'Lalor': 3903,
    'Lancefield': 3903,
    'Lang Lang': 3903,
    'Langwarrin': 3903,
    'Langwarrin South': 3903,
    'Lara': 3903,
    'Latrobe': 3903,
    'Launching Place': 3903,
    'Laverton': 3903,
    'Leongatha': 3903,
    'Leopold': 3903,
    'Lilydale': 3903,
    'Little River': 3903,
    'Loddon': 3903,
    'Long Gully': 3903,
    'Longford': 3903,
    'Longwarry': 3903,
    'Lorne': 3903,
    'Lovely Banks': 3903,
    'Lower Plenty': 3903,
    'Lucknow': 3903,
    'Lynbrook': 3903,
    'Lysterfield': 3903,
    'Macedon': 3903,
    'Macedon Ranges': 3903,
    'Macleod': 3903,
    'Maddingley': 3903,
    'Maffra': 3903,
    'Maiden Gully': 3903,
    'Maidstone': 3903,
    'Maldon': 3903,
    'Mallacoota': 3903,
    'Malvern': 3903,
    'Malvern East': 3903,
    'Manifold Heights': 3903,
    'Manningham': 3903,
    'Mansfield': 3903,
    'Maribyrnong': 3903,
    'Marong': 3903,
    'Maroondah': 3903,
    'Maryborough': 3903,
    'McCrae': 3903,
    'McKinnon': 3903,
    'Meadow Heights': 3903,
    'Melbourne': 3903,
    'Melbourne City Centre': 3903,
    'Melton': 3903,
    'Melton South': 3903,
    'Melton West': 3903,
    'Mentone': 3903,
    'Merbein': 3903,
    'Mernda': 3903,
    'Metung': 3903,
    'Mickleham': 3903,
    'Middle Park': 3903,
    'Mildura': 3903,
    'Mildura Shire': 3903,
    'Mill Park': 3903,
    'Millgrove': 3903,
    'Miners Rest': 3903,
    'Mirboo North': 3903,
    'Mitcham': 3903,
    'Mitchell': 3903,
    'Moe': 3903,
    'Moira': 3903,
    'Monash': 3903,
    'Monbulk': 3903,
    'Mont Albert': 3903,
    'Mont Albert North': 3903,
    'Montmorency': 3903,
    'Montrose': 3903,
    'Moolap': 3903,
    'Moonee Ponds': 3903,
    'Moonee Valley': 3903,
    'Moorabbin': 3903,
    'Moorabool': 3903,
    'Moorooduc': 3903,
    'Mooroolbark': 3903,
    'Mooroopna': 3903,
    'Mordialloc': 3903,
    'Moreland': 3903,
    'Mornington': 3903,
    'Mornington Peninsula': 3903,
    'Mortlake': 3903,
    'Morwell': 3903,
    'Mount Alexander': 3903,
    'Mount Buller': 3903,
    'Mount Clear': 3903,
    'Mount Dandenong': 3903,
    'Mount Duneed': 3903,
    'Mount Eliza': 3903,
    'Mount Evelyn': 3903,
    'Mount Helen': 3903,
    'Mount Macedon': 3903,
    'Mount Martha': 3903,
    'Mount Pleasant': 3903,
    'Mount Waverley': 3903,
    'Moyne': 3903,
    'Mulgrave': 3903,
    'Murrindindi': 3903,
    'Murrumbeena': 3903,
    'Myrtleford': 3903,
    'Nagambie': 3903,
    'Narre Warren': 3903,
    'Narre Warren North': 3903,
    'Narre Warren South': 3903,
    'Nathalia': 3903,
    'Neerim South': 3903,
    'New Gisborne': 3903,
    'Newborough': 3903,
    'Newcomb': 3903,
    'Newington': 3903,
    'Newport': 3903,
    'Newtown': 3903,
    'Nhill': 3903,
    'Nichols Point': 3903,
    'Nicholson': 3903,
    'Niddrie': 3903,
    'Nillumbik': 3903,
    'Noble Park': 3903,
    'Noble Park North': 3903,
    'Norlane': 3903,
    'North Bendigo': 3903,
    'North Brighton': 3903,
    'North Geelong': 3903,
    'North Melbourne': 3903,
    'North Warrandyte': 3903,
    'North Wonthaggi': 3903,
    'Northcote': 3903,
    'Northern Grampians': 3903,
    'Notting Hill': 3903,
    'Numurkah': 3903,
    'Nunawading': 3903,
    'Nyora': 3903,
    'Oak Park': 3903,
    'Oakleigh': 3903,
    'Oakleigh East': 3903,
    'Oakleigh South': 3903,
    'Ocean Grove': 3903,
    'Officer': 3903,
    'Olinda': 3903,
    'Orbost': 3903,
    'Ormond': 3903,
    'Ouyen': 3903,
    'Pakenham': 3903,
    'Pakenham Upper': 3903,
    'Panton Hill': 3903,
    'Park Orchards': 3903,
    'Parkdale': 3903,
    'Parkville': 3903,
    'Pascoe Vale': 3903,
    'Pascoe Vale South': 3903,
    'Patterson Lakes': 3903,
    'Paynesville': 3903,
    'Pearcedale': 3903,
    'Phillip Island': 3903,
    'Plenty': 3903,
    'Plumpton': 3903,
    'Point Cook': 3903,
    'Point Lonsdale': 3903,
    'Port Fairy': 3903,
    'Port Melbourne': 3903,
    'Port Phillip': 3903,
    'Portarlington': 3903,
    'Portland': 3903,
    'Prahran': 3903,
    'Preston': 3903,
    'Princes Hill': 3903,
    'Puckapunyal': 3903,
    'Pyrenees': 3903,
    'Quarry Hill': 3903,
    'Queenscliff': 3903,
    'Queenscliffe': 3903,
    'Ravenhall': 3903,
    'Red Cliffs': 3903,
    'Redan': 3903,
    'Research': 3903,
    'Reservoir': 3903,
    'Richmond': 3903,
    'Ringwood': 3903,
    'Ringwood East': 3903,
    'Ringwood North': 3903,
    'Ripponlea': 3903,
    'Robinvale': 3903,
    'Rochester': 3903,
    'Rockbank': 3903,
    'Romsey': 3903,
    'Rosanna': 3903,
    'Rosebud': 3903,
    'Rosebud West': 3903,
    'Rosedale': 3903,
    'Ross Creek': 3903,
    'Rowville': 3903,
    'Roxburgh Park': 3903,
    'Rushworth': 3903,
    'Rutherglen': 3903,
    'Rye': 3903,
    'Safety Beach': 3903,
    'Saint Albans': 3903,
    'Saint Andrews': 3903,
    'Saint Andrews Beach': 3903,
    'Saint Helena': 3903,
    'Saint Kilda': 3903,
    'Saint Leonards': 3903,
    'Sale': 3903,
    'San Remo': 3903,
    'Sandhurst': 3903,
    'Sandringham': 3903,
    'Sassafras': 3903,
    'Scoresby': 3903,
    'Seabrook': 3903,
    'Seaford': 3903,
    'Seaholme': 3903,
    'Sebastopol': 3903,
    'Seddon': 3903,
    'Selby': 3903,
    'Seville': 3903,
    'Seymour': 3903,
    'Shepparton': 3903,
    'Shepparton East': 3903,
    'Silvan': 3903,
    'Skye': 3903,
    'Smythes Creek': 3903,
    'Smythesdale': 3903,
    'Soldiers Hill': 3903,
    'Somers': 3903,
    'Somerville': 3903,
    'Sorrento': 3903,
    'South Gippsland': 3903,
    'South Kingsville': 3903,
    'South Melbourne': 3903,
    'South Morang': 3903,
    'South Yarra': 3903,
    'Southbank': 3903,
    'Southern Grampians': 3903,
    'Spotswood': 3903,
    'Springvale': 3903,
    'Springvale South': 3903,
    'St Albans Park': 3903,
    'St Helena': 3903,
    'St Kilda East': 3903,
    'St Kilda West': 3903,
    'Stawell': 3903,
    'Stonnington': 3903,
    'Stratford': 3903,
    'Strathbogie': 3903,
    'Strathdale': 3903,
    'Strathfieldsaye': 3903,
    'Strathmerton': 3903,
    'Strathmore': 3903,
    'Sunbury': 3903,
    'Sunshine': 3903,
    'Sunshine North': 3903,
    'Sunshine West': 3903,
    'Surf Coast': 3903,
    'Surrey Hills': 3903,
    'Swan Hill': 3903,
    'Sydenham': 3903,
    'Tallangatta': 3903,
    'Tarneit': 3903,
    'Tatura': 3903,
    'Taylors Hill': 3903,
    'Taylors Lakes': 3903,
    'Tecoma': 3903,
    'Teesdale': 3903,
    'Templestowe': 3903,
    'Templestowe Lower': 3903,
    'Terang': 3903,
    'The Basin': 3903,
    'The Patch': 3903,
    'Thomastown': 3903,
    'Thomson': 3903,
    'Thornbury': 3903,
    'Timboon': 3903,
    'Tongala': 3903,
    'Tooradin': 3903,
    'Toorak': 3903,
    'Tootgarook': 3903,
    'Torquay': 3903,
    'Towong': 3903,
    'Trafalgar': 3903,
    'Traralgon': 3903,
    'Travancore': 3903,
    'Trentham': 3903,
    'Truganina': 3903,
    'Tullamarine': 3903,
    'Tyabb': 3903,
    'Upwey': 3903,
    'Vermont': 3903,
    'Vermont South': 3903,
    'Viewbank': 3903,
    'Wahgunyah': 3903,
    'Wallan': 3903,
    'Wallington': 3903,
    'Wandana Heights': 3903,
    'Wandin North': 3903,
    'Wandong': 3903,
    'Wangaratta': 3903,
    'Wantirna': 3903,
    'Wantirna South': 3903,
    'Warburton': 3903,
    'Warracknabeal': 3903,
    'Warragul': 3903,
    'Warrandyte': 3903,
    'Warranwood': 3903,
    'Warrnambool': 3903,
    'Waterways': 3903,
    'Watsonia': 3903,
    'Watsonia North': 3903,
    'Wattleglen': 3903,
    'Waurn Ponds': 3903,
    'Wellington': 3903,
    'Wendouree': 3903,
    'Werribee': 3903,
    'Werribee South': 3903,
    'Wesburn': 3903,
    'West Footscray': 3903,
    'West Melbourne': 3903,
    'West Wimmera': 3903,
    'West Wodonga': 3903,
    'Westmeadows': 3903,
    'Wheelers Hill': 3903,
    'White Hills': 3903,
    'Whitehorse': 3903,
    'Whittington': 3903,
    'Whittlesea': 3903,
    'Williams Landing': 3903,
    'Williamstown': 3903,
    'Williamstown North': 3903,
    'Winchelsea': 3903,
    'Windsor': 3903,
    'Wodonga': 3903,
    'Wollert': 3903,
    'Wonga Park': 3903,
    'Wonthaggi': 3903,
    'Woodend': 3903,
    'Woori Yallock': 3903,
    'Wurruk': 3903,
    'Wy Yung': 3903,
    'Wyndham': 3903,
    'Wyndham Vale': 3903,
    'Yackandandah': 3903,
    'Yallambie': 3903,
    'Yallourn North': 3903,
    'Yarra': 3903,
    'Yarra Glen': 3903,
    'Yarra Junction': 3903,
    'Yarra Ranges': 3903,
    'Yarragon': 3903,
    'Yarram': 3903,
    'Yarrambat': 3903,
    'Yarraville': 3903,
    'Yarrawonga': 3903,
    'Yarriambiack': 3903,
    'Yea': 3903,
    'Abbey': 3906,
    'Albany': 3906,
    'Albany city centre': 3906,
    'Alexander Heights': 3906,
    'Alfred Cove': 3906,
    'Alkimos': 3906,
    'Applecross': 3906,
    'Ardross': 3906,
    'Armadale': 3906,
    'Ascot': 3906,
    'Ashburton': 3906,
    'Ashby': 3906,
    'Ashfield': 3906,
    'Attadale': 3906,
    'Atwell': 3906,
    'Aubin Grove': 3906,
    'Augusta': 3906,
    'Augusta-Margaret River Shire': 3906,
    'Australind': 3906,
    'Aveley': 3906,
    'Bakers Hill': 3906,
    'Balcatta': 3906,
    'Baldivis': 3906,
    'Balga': 3906,
    'Ballajura': 3906,
    'Banjup': 3906,
    'Banksia Grove': 3906,
    'Bassendean': 3906,
    'Bateman': 3906,
    'Baynton': 3906,
    'Bayonet Head': 3906,
    'Bayswater': 3906,
    'Beachlands': 3906,
    'Beaconsfield': 3906,
    'Beckenham': 3906,
    'Bedford': 3906,
    'Bedfordale': 3906,
    'Beechboro': 3906,
    'Beeliar': 3906,
    'Beldon': 3906,
    'Bellevue': 3906,
    'Belmont': 3906,
    'Bennett Springs': 3906,
    'Bentley': 3906,
    'Beresford': 3906,
    'Bertram': 3906,
    'Beverley': 3906,
    'Bibra Lake': 3906,
    'Bicton': 3906,
    'Bilingurr': 3906,
    'Bindoon': 3906,
    'Binningup': 3906,
    'Bluff Point': 3906,
    'Boddington': 3906,
    'Booragoon': 3906,
    'Boulder': 3906,
    'Boyanup': 3906,
    'Boyup Brook': 3906,
    'Brabham': 3906,
    'Brentwood': 3906,
    'Bridgetown': 3906,
    'Bridgetown-Greenbushes': 3906,
    'Broadwater': 3906,
    'Brockman': 3906,
    'Brookdale': 3906,
    'Brookton': 3906,
    'Broome': 3906,
    'Broomehill-Tambellup': 3906,
    'Bruce Rock': 3906,
    'Brunswick': 3906,
    'Bulgarra': 3906,
    'Bull Creek': 3906,
    'Bullsbrook': 3906,
    'Bunbury': 3906,
    'Burns Beach': 3906,
    'Burswood': 3906,
    'Busselton': 3906,
    'Busselton city cenre': 3906,
    'Butler': 3906,
    'Byford': 3906,
    'Cable Beach': 3906,
    'Calista': 3906,
    'Cambridge': 3906,
    'Camillo': 3906,
    'Canning': 3906,
    'Canning Vale': 3906,
    'Cannington': 3906,
    'Capel': 3906,
    'Cardup': 3906,
    'Carey Park': 3906,
    'Carine': 3906,
    'Carlisle': 3906,
    'Carnamah': 3906,
    'Carnarvon': 3906,
    'Carramar': 3906,
    'Castletown': 3906,
    'Casuarina': 3906,
    'Caversham': 3906,
    'Champion Lakes': 3906,
    'Chapman Valley': 3906,
    'Chidlow': 3906,
    'Chittering': 3906,
    'Churchlands': 3906,
    'City Beach': 3906,
    'City of Cockburn': 3906,
    'City of Perth': 3906,
    'Claremont': 3906,
    'Clarkson': 3906,
    'Cloverdale': 3906,
    'Cockburn Central': 3906,
    'College Grove': 3906,
    'Collie': 3906,
    'Como': 3906,
    'Connolly': 3906,
    'Coodanup': 3906,
    'Coogee': 3906,
    'Coolbellup': 3906,
    'Coolbinia': 3906,
    'Coolgardie': 3906,
    'Cooloongup': 3906,
    'Coorow': 3906,
    'Corrigin': 3906,
    'Cottesloe': 3906,
    'Cowaramup': 3906,
    'Craigie': 3906,
    'Cranbrook': 3906,
    'Crawley': 3906,
    'Cuballing': 3906,
    'Cue': 3906,
    'Cunderdin': 3906,
    'Currambine': 3906,
    'Daglish': 3906,
    'Dalkeith': 3906,
    'Dalwallinu': 3906,
    'Dalyellup': 3906,
    'Dampier': 3906,
    'Dampier Peninsula': 3906,
    'Dandaragan': 3906,
    'Darch': 3906,
    'Dardanup': 3906,
    'Darling Downs': 3906,
    'Darlington': 3906,
    'Dawesville': 3906,
    'Dayton': 3906,
    'Denham': 3906,
    'Denmark': 3906,
    'Derby': 3906,
    'Derby-West Kimberley': 3906,
    'Dianella': 3906,
    'Djugun': 3906,
    'Dongara': 3906,
    'Donnybrook': 3906,
    'Donnybrook-Balingup': 3906,
    'Doubleview': 3906,
    'Dowerin': 3906,
    'Drummond Cove': 3906,
    'Dudley Park': 3906,
    'Dumbleyung Shire': 3906,
    'Duncraig': 3906,
    'Dundas': 3906,
    'Dunsborough': 3906,
    'East Bunbury': 3906,
    'East Cannington': 3906,
    'East Carnarvon': 3906,
    'East Fremantle': 3906,
    'East Perth': 3906,
    'East Pilbara': 3906,
    'East Victoria Park': 3906,
    'Eaton': 3906,
    'Eden Hill': 3906,
    'Edgewater': 3906,
    'Eglinton': 3906,
    'Ellenbrook': 3906,
    'Embleton': 3906,
    'Erskine': 3906,
    'Esperance': 3906,
    'Esperance Shire': 3906,
    'Exmouth': 3906,
    'Falcon': 3906,
    'Ferndale': 3906,
    'Fitzroy Crossing': 3906,
    'Floreat': 3906,
    'Forrestdale': 3906,
    'Forrestfield': 3906,
    'Fremantle': 3906,
    'Garden Island': 3906,
    'Gelorup': 3906,
    'Geographe': 3906,
    'Geraldton': 3906,
    'Geraldton city centre': 3906,
    'Gidgegannup': 3906,
    'Gingin': 3906,
    'Girrawheen': 3906,
    'Glen Forrest': 3906,
    'Glen Iris': 3906,
    'Glendalough': 3906,
    'Gnangara': 3906,
    'Gnowangerup': 3906,
    'Golden Bay': 3906,
    'Goomalling': 3906,
    'Gooseberry Hill': 3906,
    'Gosnells': 3906,
    'Grasmere': 3906,
    'Greenfields': 3906,
    'Greenmount': 3906,
    'Greenwood': 3906,
    'Guildford': 3906,
    'Gwelup': 3906,
    'Halls Creek': 3906,
    'Halls Head': 3906,
    'Hamersley': 3906,
    'Hamilton Hill': 3906,
    'Hammond Park': 3906,
    'Hannans': 3906,
    'Harrisdale': 3906,
    'Harvey': 3906,
    'Heathridge': 3906,
    'Helena Valley': 3906,
    'Henley Brook': 3906,
    'Herne Hill': 3906,
    'High Wycombe': 3906,
    'Highgate': 3906,
    'Hilbert': 3906,
    'Hillarys': 3906,
    'Hillman': 3906,
    'Hilton': 3906,
    'Hocking': 3906,
    'Huntingdale': 3906,
    'Iluka': 3906,
    'Inglewood': 3906,
    'Innaloo': 3906,
    'Irwin': 3906,
    'Jandakot': 3906,
    'Jane Brook': 3906,
    'Jarrahdale': 3906,
    'Jerramungup': 3906,
    'Jindalee': 3906,
    'Jolimont': 3906,
    'Joondalup': 3906,
    'Joondanna': 3906,
    'Jurien Bay': 3906,
    'Kalamunda': 3906,
    'Kalbarri': 3906,
    'Kalgoorlie': 3906,
    'Kalgoorlie/Boulder': 3906,
    'Kallaroo': 3906,
    'Kambalda East': 3906,
    'Kambalda West': 3906,
    'Karawara': 3906,
    'Kardinya': 3906,
    'Karnup': 3906,
    'Karratha': 3906,
    'Karrinyup': 3906,
    'Katanning': 3906,
    'Kellerberrin': 3906,
    'Kelmscott': 3906,
    'Kent Shire': 3906,
    'Kenwick': 3906,
    'Kewdale': 3906,
    'Kiara': 3906,
    'Kingsley': 3906,
    'Kinross': 3906,
    'Kojonup': 3906,
    'Kondinin': 3906,
    'Koondoola': 3906,
    'Koorda': 3906,
    'Kulin': 3906,
    'Kununurra': 3906,
    'Kwinana': 3906,
    'Lake Grace': 3906,
    'Lakelands': 3906,
    'Lamington': 3906,
    'Landsdale': 3906,
    'Langford': 3906,
    'Lathlain': 3906,
    'Laverton': 3906,
    'Leda': 3906,
    'Leederville': 3906,
    'Leeming': 3906,
    'Leinster': 3906,
    'Leonora': 3906,
    'Leschenault': 3906,
    'Lesmurdie': 3906,
    'Little Grove': 3906,
    'Lockridge': 3906,
    'Lockyer': 3906,
    'Lower Chittering': 3906,
    'Lower King': 3906,
    'Lynwood': 3906,
    'Maddington': 3906,
    'Madeley': 3906,
    'Madora Bay': 3906,
    'Maida Vale': 3906,
    'Mandurah': 3906,
    'Mandurah city centre': 3906,
    'Manjimup': 3906,
    'Manning': 3906,
    'Marangaroo': 3906,
    'Marble Bar': 3906,
    'Margaret River': 3906,
    'Marmion': 3906,
    'Martin': 3906,
    'Maylands': 3906,
    'McKail': 3906,
    'Meadow Springs': 3906,
    'Medina': 3906,
    'Meekatharra': 3906,
    'Melville': 3906,
    'Menora': 3906,
    'Menzies': 3906,
    'Merredin': 3906,
    'Merriwa': 3906,
    'Middle Swan': 3906,
    'Midland': 3906,
    'Midvale': 3906,
    'Millars Well': 3906,
    'Millbridge': 3906,
    'Mindarie': 3906,
    'Mingenew': 3906,
    'Mira Mar': 3906,
    'Mirrabooka': 3906,
    'Moora': 3906,
    'Morawa': 3906,
    'Morley': 3906,
    'Mosman Park': 3906,
    'Mount Barker': 3906,
    'Mount Claremont': 3906,
    'Mount Hawthorn': 3906,
    'Mount Helena': 3906,
    'Mount Lawley': 3906,
    'Mount Magnet': 3906,
    'Mount Marshall': 3906,
    'Mount Melville': 3906,
    'Mount Nasura': 3906,
    'Mount Pleasant': 3906,
    'Mount Richon': 3906,
    'Mount Tarcoola': 3906,
    'Mukinbudin': 3906,
    'Mullaloo': 3906,
    'Mundaring': 3906,
    'Mundijong': 3906,
    'Munster': 3906,
    'Murchison': 3906,
    'Murdoch': 3906,
    'Murray': 3906,
    'Myaree': 3906,
    'Nannup': 3906,
    'Narembeen': 3906,
    'Narrogin': 3906,
    'Nedlands': 3906,
    'Newman': 3906,
    'Ngaanyatjarraku': 3906,
    'Nickol': 3906,
    'Nollamara': 3906,
    'Noranda': 3906,
    'North Beach': 3906,
    'North Coogee': 3906,
    'North Fremantle': 3906,
    'North Lake': 3906,
    'North Perth': 3906,
    'Northam': 3906,
    'Northampton Shire': 3906,
    'Northbridge': 3906,
    'Nullagine': 3906,
    'Nulsen': 3906,
    'Nungarin': 3906,
    'Oakford': 3906,
    'Ocean Reef': 3906,
    'Onslow': 3906,
    'Orana': 3906,
    'Orelia': 3906,
    'Osborne Park': 3906,
    'Padbury': 3906,
    'Palmyra': 3906,
    'Paraburdoo': 3906,
    'Parkerville': 3906,
    'Parkwood': 3906,
    'Parmelia': 3906,
    'Pearce': 3906,
    'Pearsall': 3906,
    'Pegs Creek': 3906,
    'Pemberton': 3906,
    'Peppermint Grove': 3906,
    'Perenjori': 3906,
    'Perth': 3906,
    'Perth city centre': 3906,
    'Piara Waters': 3906,
    'Piccadilly': 3906,
    'Pingelly': 3906,
    'Pinjarra': 3906,
    'Plantagenet Shire': 3906,
    'Port Denison': 3906,
    'Port Hedland': 3906,
    'Port Kennedy': 3906,
    'Quairading': 3906,
    'Queens Park': 3906,
    'Quindalup': 3906,
    'Quinns Rocks': 3906,
    'Rangeway': 3906,
    'Ravensthorpe': 3906,
    'Ravenswood': 3906,
    'Redcliffe': 3906,
    'Ridgewood': 3906,
    'Riverton': 3906,
    'Rivervale': 3906,
    'Rockingham': 3906,
    'Rockingham city centre': 3906,
    'Roebuck': 3906,
    'Roleystone': 3906,
    'Rossmoyne': 3906,
    'Safety Bay': 3906,
    'Salter Point': 3906,
    'Samson': 3906,
    'Sandstone': 3906,
    'Scarborough': 3906,
    'Secret Harbour': 3906,
    'Serpentine': 3906,
    'Serpentine-Jarrahdale': 3906,
    'Seville Grove': 3906,
    'Shark Bay': 3906,
    'Shelley': 3906,
    'Shenton Park': 3906,
    'Shoalwater': 3906,
    'Silver Sands': 3906,
    'Sinagra': 3906,
    'Singleton': 3906,
    'Somerville': 3906,
    'Sorrento': 3906,
    'South Bunbury': 3906,
    'South Carnarvon': 3906,
    'South Fremantle': 3906,
    'South Guildford': 3906,
    'South Hedland': 3906,
    'South Kalgoorlie': 3906,
    'South Lake': 3906,
    'South Perth': 3906,
    'South Yunderup': 3906,
    'Southern River': 3906,
    'Spalding': 3906,
    'Spearwood': 3906,
    'Spencer Park': 3906,
    'St George Ranges': 3906,
    'St James': 3906,
    'Stirling': 3906,
    'Stoneville': 3906,
    'Strathalbyn': 3906,
    'Stratton': 3906,
    'Subiaco': 3906,
    'Success': 3906,
    'Sunset Beach': 3906,
    'Swan': 3906,
    'Swan View': 3906,
    'Swanbourne': 3906,
    'Tammin': 3906,
    'Tapping': 3906,
    'Tarcoola Beach': 3906,
    'Telfer': 3906,
    'The Vines': 3906,
    'Thornlie': 3906,
    'Three Springs': 3906,
    'Tom Price': 3906,
    'Toodyay': 3906,
    'Trayning': 3906,
    'Trigg': 3906,
    'Tuart Hill': 3906,
    'Two Rocks': 3906,
    'Upper Gascoyne': 3906,
    'Usher': 3906,
    'Utakarra': 3906,
    'Vasse': 3906,
    'Victoria Park': 3906,
    'Victoria Plains': 3906,
    'Vincent': 3906,
    'Viveash': 3906,
    'Waggrakine': 3906,
    'Wagin': 3906,
    'Waikiki': 3906,
    'Wandering': 3906,
    'Wandi': 3906,
    'Wandina': 3906,
    'Wannanup': 3906,
    'Wanneroo': 3906,
    'Warnbro': 3906,
    'Waroona': 3906,
    'Warwick': 3906,
    'Waterford': 3906,
    'Watermans Bay': 3906,
    'Wattle Grove': 3906,
    'Wellard': 3906,
    'Wembley': 3906,
    'Wembley Downs': 3906,
    'West Arthur': 3906,
    'West Beach': 3906,
    'West Busselton': 3906,
    'West Lamington': 3906,
    'West Leederville': 3906,
    'West Perth': 3906,
    'Westminster': 3906,
    'Westonia': 3906,
    'White Gum Valley': 3906,
    'Wickepin': 3906,
    'Wickham': 3906,
    'Willagee': 3906,
    'Willetton': 3906,
    'Williams': 3906,
    'Wilson': 3906,
    'Wiluna': 3906,
    'Winthrop': 3906,
    'Withers': 3906,
    'Wongan-Ballidu': 3906,
    'Wonthella': 3906,
    'Woodanilling': 3906,
    'Woodbridge': 3906,
    'Woodvale': 3906,
    'Wooroloo': 3906,
    'Woorree': 3906,
    'Wundowie': 3906,
    'Wyalkatchem': 3906,
    'Wyndham-East Kimberley': 3906,
    'Yakamia': 3906,
    'Yalgoo': 3906,
    'Yallingup': 3906,
    'Yalyalup': 3906,
    'Yanchep': 3906,
    'Yangebup': 3906,
    'Yilgarn': 3906,
    'Yokine': 3906,
    'York': 3906,
    'Andau': 2062,
    'Antau': 2062,
    'Apetlon': 2062,
    'Bad Sauerbrunn': 2062,
    'Bad Tatzmannsdorf': 2062,
    'Badersdorf': 2062,
    'Bernstein': 2062,
    'Bocksdorf': 2062,
    'Breitenbrunn': 2062,
    'Bruckneudorf': 2062,
    'Deutsch Jahrndorf': 2062,
    'Deutsch Kaltenbrunn': 2062,
    'Deutschkreutz': 2062,
    'Donnerskirchen': 2062,
    'Drassburg': 2062,
    'Eberau': 2062,
    'Edelstal': 2062,
    'Eisenstadt': 2062,
    'Eisenstadt Stadt': 2062,
    'Eisenstadt-Umgebung': 2062,
    'Eltendorf': 2062,
    'Forchtenstein': 2062,
    'Frauenkirchen': 2062,
    'Gattendorf': 2062,
    'Gols': 2062,
    'Grafenschachen': 2062,
    'Grosshöflein': 2062,
    'Grosspetersdorf': 2062,
    'Güssing': 2062,
    'Güttenbach': 2062,
    'Hackerberg': 2062,
    'Halbturn': 2062,
    'Hannersdorf': 2062,
    'Heiligenbrunn': 2062,
    'Heiligenkreuz im Lafnitztal': 2062,
    'Heugraben': 2062,
    'Hirm': 2062,
    'Horitschon': 2062,
    'Hornstein': 2062,
    'Illmitz': 2062,
    'Inzenhof': 2062,
    'Jabing': 2062,
    'Jennersdorf': 2062,
    'Jois': 2062,
    'Kaisersdorf': 2062,
    'Kemeten': 2062,
    'Kittsee': 2062,
    'Kleinhöflein im Burgenland': 2062,
    'Kleinmürbisch': 2062,
    'Klingenbach': 2062,
    'Kobersdorf': 2062,
    'Kohfidisch': 2062,
    'Königsdorf': 2062,
    'Krensdorf': 2062,
    'Kukmirn': 2062,
    'Lackenbach': 2062,
    'Lackendorf': 2062,
    'Leithaprodersdorf': 2062,
    'Litzelsdorf': 2062,
    'Lockenhaus': 2062,
    'Loipersbach im Burgenland': 2062,
    'Loretto': 2062,
    'Lutzmannsburg': 2062,
    'Mannersdorf an der Rabnitz': 2062,
    'Mariasdorf': 2062,
    'Markt Allhau': 2062,
    'Markt Neuhodis': 2062,
    'Markt Sankt Martin': 2062,
    'Marz': 2062,
    'Mattersburg': 2062,
    'Mischendorf': 2062,
    'Mitterpullendorf': 2062,
    'Mogersdorf': 2062,
    'Mönchhof': 2062,
    'Mörbisch am See': 2062,
    'Moschendorf': 2062,
    'Mühlgraben': 2062,
    'Müllendorf': 2062,
    'Neckenmarkt': 2062,
    'Neuberg im Burgenland': 2062,
    'Neudörfl': 2062,
    'Neufeld an der Leitha': 2062,
    'Neuhaus am Klausenbach': 2062,
    'Neusiedl am See': 2062,
    'Neustift an der Lafnitz': 2062,
    'Neustift bei Güssing': 2062,
    'Neutal': 2062,
    'Nickelsdorf': 2062,
    'Nikitsch': 2062,
    'Oberdorf im Burgenland': 2062,
    'Oberloisdorf': 2062,
    'Oberpullendorf': 2062,
    'Oberschützen': 2062,
    'Oberwart': 2062,
    'Oggau': 2062,
    'Olbendorf': 2062,
    'Ollersdorf im Burgenland': 2062,
    'Oslip': 2062,
    'Pama': 2062,
    'Pamhagen': 2062,
    'Parndorf': 2062,
    'Pilgersdorf': 2062,
    'Pinkafeld': 2062,
    'Piringsdorf': 2062,
    'Podersdorf am See': 2062,
    'Politischer Bezirk Güssing': 2062,
    'Politischer Bezirk Jennersdorf': 2062,
    'Politischer Bezirk Mattersburg': 2062,
    'Politischer Bezirk Neusiedl am See': 2062,
    'Politischer Bezirk Oberpullendorf': 2062,
    'Politischer Bezirk Oberwart': 2062,
    'Pöttelsdorf': 2062,
    'Pöttsching': 2062,
    'Potzneusiedl': 2062,
    'Purbach am Neusiedler See': 2062,
    'Raiding': 2062,
    'Rechnitz': 2062,
    'Riedlingsdorf': 2062,
    'Ritzing': 2062,
    'Rohr im Burgenland': 2062,
    'Rohrbach bei Mattersburg': 2062,
    'Rotenturm an der Pinka': 2062,
    'Rudersdorf': 2062,
    'Rust': 2062,
    'Rust Stadt': 2062,
    'Sankt Andrä am Zicksee': 2062,
    'Sankt Georgen am Leithagebirge': 2062,
    'Sankt Margarethen im Burgenland': 2062,
    'Sankt Martin an der Raab': 2062,
    'Sankt Michael im Burgenland': 2062,
    'Schachendorf': 2062,
    'Schandorf': 2062,
    'Schattendorf': 2062,
    'Schützen am Gebirge': 2062,
    'Siegendorf im Burgenland': 2062,
    'Sieggraben': 2062,
    'Sigless': 2062,
    'Stadtschlaining': 2062,
    'Stegersbach': 2062,
    'Steinbrunn': 2062,
    'Stinatz': 2062,
    'Stoob': 2062,
    'Stotzing': 2062,
    'Strem': 2062,
    'Tadten': 2062,
    'Tobaj': 2062,
    'Trausdorf an der Wulka': 2062,
    'Tschanigraben': 2062,
    'Unterfrauenhaid': 2062,
    'Unterkohlstätten': 2062,
    'Unterwart': 2062,
    'Wallern im Burgenland': 2062,
    'Weichselbaum': 2062,
    'Weiden am See': 2062,
    'Weiden bei Rechnitz': 2062,
    'Weingraben': 2062,
    'Weppersdorf': 2062,
    'Wiesen': 2062,
    'Wiesfleck': 2062,
    'Wimpassing an der Leitha': 2062,
    'Winden am See': 2062,
    'Wolfau': 2062,
    'Wörterberg': 2062,
    'Wulkaprodersdorf': 2062,
    'Zagersdorf': 2062,
    'Zillingtal': 2062,
    'Zurndorf': 2062,
    'Albeck': 2057,
    'Althofen': 2057,
    'Annabichl': 2057,
    'Arnoldstein': 2057,
    'Arriach': 2057,
    'Auen': 2057,
    'Bad Bleiberg': 2057,
    'Bad Eisenkappel': 2057,
    'Bad Kleinkirchheim': 2057,
    'Bad Sankt Leonhard im Lavanttal': 2057,
    'Baldramsdorf': 2057,
    'Berg im Drautal': 2057,
    'Bleiburg': 2057,
    'Bodensdorf': 2057,
    'Brückl': 2057,
    'Dellach': 2057,
    'Dellach im Drautal': 2057,
    'Deutsch Griffen': 2057,
    'Deutsch-Griffen': 2057,
    'Diex': 2057,
    'Döbriach': 2057,
    'Draschen': 2057,
    'Ebenthal': 2057,
    'Eberndorf': 2057,
    'Eberstein': 2057,
    'Ebriach': 2057,
    'Eisenkappel-Vellach': 2057,
    'Faak am See': 2057,
    'Feffernitz': 2057,
    'Feistritz an der Drau': 2057,
    'Feistritz an der Gail': 2057,
    'Feistritz im Rosental': 2057,
    'Feistritz ob Bleiburg': 2057,
    'Feld am See': 2057,
    'Feldkirchen District': 2057,
    'Feldkirchen in Kärnten': 2057,
    'Ferlach': 2057,
    'Ferndorf': 2057,
    'Finkenstein': 2057,
    'Finkenstein am Faaker See': 2057,
    'Flattach': 2057,
    'Föderlach I': 2057,
    'Frantschach-Sankt Gertraud': 2057,
    'Frauenstein': 2057,
    'Fresach': 2057,
    'Fresen': 2057,
    'Friesach': 2057,
    'Fürnitz': 2057,
    'Gailitz': 2057,
    'Gallizien': 2057,
    'Gemmersdorf': 2057,
    'Gerlamoos': 2057,
    'Gitschtal': 2057,
    'Glanegg': 2057,
    'Globasnitz': 2057,
    'Glödnitz': 2057,
    'Gmünd': 2057,
    'Gmünd in Kärnten': 2057,
    'Gnesau': 2057,
    'Görtschach': 2057,
    'Grafenstein': 2057,
    'Greifenburg': 2057,
    'Gries': 2057,
    'Griffen': 2057,
    'Großkirchheim': 2057,
    'Gurk': 2057,
    'Guttaring': 2057,
    'Heiligenblut': 2057,
    'Hermagor': 2057,
    'Hermagor District': 2057,
    'Hermagor-Pressegger See': 2057,
    'Himmelberg': 2057,
    'Hohenthurn': 2057,
    'Hörtendorf': 2057,
    'Hüttenberg': 2057,
    'Irschen': 2057,
    'Kappel am Krappfeld': 2057,
    'Keutschach am See': 2057,
    'Kirchbach': 2057,
    'Klagenfurt': 2057,
    'Klagenfurt am Wörthersee': 2057,
    'Klagenfurt-Land District': 2057,
    'Kleblach-Lind': 2057,
    'Klein Sankt Paul': 2057,
    'Kleinedling': 2057,
    'Kötschach': 2057,
    'Kötschach-Mauthen': 2057,
    'Köttmannsdorf': 2057,
    'Krems': 2057,
    'Krumpendorf': 2057,
    'Kühnsdorf': 2057,
    'Lamnitz': 2057,
    'Landskron': 2057,
    'Launsdorf': 2057,
    'Lavamünd': 2057,
    'Ledenitzen': 2057,
    'Lendorf': 2057,
    'Leppen': 2057,
    'Lesachtal': 2057,
    'Liebenfels': 2057,
    'Lind': 2057,
    'Lobersberg': 2057,
    'Ludmannsdorf': 2057,
    'Lurnfeld': 2057,
    'Magdalensberg': 2057,
    'Mallnitz': 2057,
    'Malta': 2057,
    'Maria Rain': 2057,
    'Maria Saal': 2057,
    'Maria Wörth': 2057,
    'Mautbrücken': 2057,
    'Metnitz': 2057,
    'Micheldorf': 2057,
    'Millstatt': 2057,
    'Mölbling': 2057,
    'Möllbrücke': 2057,
    'Moosburg': 2057,
    'Mörtschach': 2057,
    'Mühldorf': 2057,
    'Neu-Feffernitz': 2057,
    'Neuhaus': 2057,
    'Niederdorf': 2057,
    'Nötsch im Gailtal': 2057,
    'Oberdörfl': 2057,
    'Oberdrauburg': 2057,
    'Obermillstatt': 2057,
    'Obervellach': 2057,
    'Ossiach': 2057,
    'Paternion': 2057,
    'Pischeldorf': 2057,
    'Pöckau': 2057,
    'Poggersdorf': 2057,
    'Pörtschach am Wörthersee': 2057,
    'Preitenegg': 2057,
    'Priel': 2057,
    'Pusarnitz': 2057,
    'Radenthein': 2057,
    'Rain': 2057,
    'Rangersdorf': 2057,
    'Reding': 2057,
    'Reichenau': 2057,
    'Reichenfels': 2057,
    'Reißeck': 2057,
    'Rennweg am Katschberg': 2057,
    'Rittersdorf': 2057,
    'Rosegg': 2057,
    'Ruden': 2057,
    'Sachsenburg': 2057,
    'Sankt Andrä': 2057,
    'Sankt Egyden an der Drau': 2057,
    'Sankt Georgen am Längsee': 2057,
    'Sankt Georgen im Lavanttal': 2057,
    'Sankt Jakob': 2057,
    'Sankt Jakob im Rosental': 2057,
    'Sankt Kanzian am Klopeiner See': 2057,
    'Sankt Leonhard': 2057,
    'Sankt Magdalen': 2057,
    'Sankt Margareten im Rosental': 2057,
    'Sankt Martin': 2057,
    'Sankt Paul im Lavanttal': 2057,
    'Sankt Peter': 2057,
    'Sankt Ruprecht': 2057,
    'Sankt Stefan': 2057,
    'Sankt Stefan im Gailtal': 2057,
    'Sankt Urban': 2057,
    'Sankt Veit an der Glan': 2057,
    'Sankt Veit an der Glan District': 2057,
    'Schiefling am See': 2057,
    'Schleben': 2057,
    'Seebach': 2057,
    'Seeboden': 2057,
    'Seitenberg': 2057,
    'Sirnitz': 2057,
    'Sittersdorf': 2057,
    'Spittal an der Drau': 2057,
    'Spittal an der Drau District': 2057,
    'St. Agathen und Perau': 2057,
    'St. Andrä': 2057,
    'St. Margareten im Rosental': 2057,
    'St. Paul im Lavanttal': 2057,
    'St. Stefan im Gailtal': 2057,
    'St. Urban': 2057,
    'St. Veit an der Glan': 2057,
    'St. Veiter Vorstadt': 2057,
    'Stall': 2057,
    'Steindorf am Ossiacher See': 2057,
    'Steinfeld': 2057,
    'Steuerberg': 2057,
    'Stockenboi': 2057,
    'Straßburg': 2057,
    'Straßburg-Stadt': 2057,
    'Suetschach': 2057,
    'Techelsberg': 2057,
    'Timenitz': 2057,
    'Trebesing': 2057,
    'Treffen': 2057,
    'Treibach': 2057,
    'Tschachoritsch': 2057,
    'Umberg': 2057,
    'Untere Fellach': 2057,
    'Velden am Wörther See': 2057,
    'Velden am Wörthersee': 2057,
    'Viktring': 2057,
    'Viktringer Vorstadt': 2057,
    'Villach': 2057,
    'Villach-Innere Stadt': 2057,
    'Villach-Land District': 2057,
    'Villacher Vorstadt': 2057,
    'Völkendorf': 2057,
    'Völkermarkt': 2057,
    'Völkermarkt District': 2057,
    'Völkermarkter Vorstadt': 2057,
    'Waiern': 2057,
    'Warmbad-Judendorf': 2057,
    'Weißensee': 2057,
    'Weißenstein': 2057,
    'Weitensfeld': 2057,
    'Weitensfeld im Gurktal': 2057,
    'Wernberg': 2057,
    'Winklern': 2057,
    'Wölfnitz': 2057,
    'Wolfsberg': 2057,
    'Wolfsberg District': 2057,
    'Wudmath': 2057,
    'Zell': 2057,
    'Absdorf': 2065,
    'Achau': 2065,
    'Aderklaa': 2065,
    'Aggsbach': 2065,
    'Alland': 2065,
    'Allentsteig': 2065,
    'Allhartsberg': 2065,
    'Altenburg': 2065,
    'Altendorf': 2065,
    'Altenmarkt an der Triesting': 2065,
    'Altlengbach': 2065,
    'Altlichtenwarth': 2065,
    'Altmelon': 2065,
    'Amstetten': 2065,
    'Andlersdorf': 2065,
    'Angern an der March': 2065,
    'Arbesbach': 2065,
    'Ardagger Stift': 2065,
    'Aschbach Markt': 2065,
    'Aspang Markt': 2065,
    'Asparn an der Zaya': 2065,
    'Asperhofen': 2065,
    'Atzenbrugg': 2065,
    'Au am Leithaberge': 2065,
    'Auersthal': 2065,
    'Bad Deutsch-Altenburg': 2065,
    'Bad Erlach': 2065,
    'Bad Fischau': 2065,
    'Bad Pirawarth': 2065,
    'Bad Schönau': 2065,
    'Bad Traunstein': 2065,
    'Bad Vöslau': 2065,
    'Baden': 2065,
    'Bärnkopf': 2065,
    'Behamberg': 2065,
    'Berg': 2065,
    'Bergland': 2065,
    'Berndorf': 2065,
    'Bernhardsthal': 2065,
    'Biberbach': 2065,
    'Biedermannsdorf': 2065,
    'Bisamberg': 2065,
    'Bischofstetten': 2065,
    'Blindenmarkt': 2065,
    'Bockfließ': 2065,
    'Böheimkirchen': 2065,
    'Breitenau': 2065,
    'Breitenfurt bei Wien': 2065,
    'Bromberg': 2065,
    'Bruck an der Leitha': 2065,
    'Brunn am Gebirge': 2065,
    'Brunn an der Schneebergbahn': 2065,
    'Brunn an der Wild': 2065,
    'Buchbach': 2065,
    'Deutsch-Wagram': 2065,
    'Dobersberg': 2065,
    'Dorfstetten': 2065,
    'Drasenhofen': 2065,
    'Drösing': 2065,
    'Droß': 2065,
    'Dürnkrut': 2065,
    'Dürnstein': 2065,
    'Ebenfurth': 2065,
    'Ebenthal': 2065,
    'Ebergassing': 2065,
    'Ebreichsdorf': 2065,
    'Echsenbach': 2065,
    'Eckartsau': 2065,
    'Edlitz': 2065,
    'Eggenburg': 2065,
    'Eggendorf': 2065,
    'Eggern': 2065,
    'Eichgraben': 2065,
    'Eisgarn': 2065,
    'Emmersdorf an der Donau': 2065,
    'Engelhartstetten': 2065,
    'Ennsdorf': 2065,
    'Enzenreith': 2065,
    'Enzersdorf an der Fischa': 2065,
    'Enzersfeld': 2065,
    'Erlauf': 2065,
    'Ernstbrunn': 2065,
    'Ernsthofen': 2065,
    'Erpersdorf': 2065,
    'Ertl': 2065,
    'Eschenau': 2065,
    'Euratsfeld': 2065,
    'Falkenstein': 2065,
    'Fallbach': 2065,
    'Feistritz am Wechsel': 2065,
    'Felixdorf': 2065,
    'Fels am Wagram': 2065,
    'Ferschnitz': 2065,
    'Fischamend-Markt': 2065,
    'Frankenfels': 2065,
    'Frohsdorf': 2065,
    'Furth an der Triesting': 2065,
    'Furth bei Göttweig': 2065,
    'Gaaden': 2065,
    'Gablitz': 2065,
    'Gainfarn': 2065,
    'Gaming': 2065,
    'Gänserndorf': 2065,
    'Gars am Kamp': 2065,
    'Gastern': 2065,
    'Gaubitsch': 2065,
    'Gaweinstal': 2065,
    'Gedersdorf': 2065,
    'Geras': 2065,
    'Gerasdorf bei Wien': 2065,
    'Gerersdorf': 2065,
    'Gerolding': 2065,
    'Gföhl': 2065,
    'Gießhübl': 2065,
    'Glinzendorf': 2065,
    'Gloggnitz': 2065,
    'Gmünd': 2065,
    'Gnadendorf': 2065,
    'Gneixendorf': 2065,
    'Göllersdorf': 2065,
    'Göpfritz an der Wild': 2065,
    'Göstling an der Ybbs': 2065,
    'Götzendorf an der Leitha': 2065,
    'Grafenegg': 2065,
    'Grafenschlag': 2065,
    'Grafenwörth': 2065,
    'Gramatneusiedl': 2065,
    'Greinsfurth': 2065,
    'Gresten': 2065,
    'Grimmenstein': 2065,
    'Groß-Engersdorf': 2065,
    'Groß-Enzersdorf': 2065,
    'Groß-Gerungs': 2065,
    'Groß-Schweinbarth': 2065,
    'Groß-Siegharts': 2065,
    'Großebersdorf': 2065,
    'Großharras': 2065,
    'Großhofen': 2065,
    'Großkrut': 2065,
    'Großmugl': 2065,
    'Großriedenthal': 2065,
    'Großrußbach': 2065,
    'Großweikersdorf': 2065,
    'Grünbach am Schneeberg': 2065,
    'Gugging': 2065,
    'Gumpoldskirchen': 2065,
    'Günselsdorf': 2065,
    'Guntersdorf': 2065,
    'Guntramsdorf': 2065,
    'Gutenbrunn': 2065,
    'Gutenstein': 2065,
    'Haag': 2065,
    'Hadersdorf am Kamp': 2065,
    'Hadres': 2065,
    'Hafnerbach': 2065,
    'Hagenbrunn': 2065,
    'Haidershofen': 2065,
    'Hainburg an der Donau': 2065,
    'Hainfeld': 2065,
    'Hardegg': 2065,
    'Haringsee': 2065,
    'Harland': 2065,
    'Harmannsdorf': 2065,
    'Hart': 2065,
    'Haugschlag': 2065,
    'Haugsdorf': 2065,
    'Haunoldstein': 2065,
    'Hausbrunn': 2065,
    'Hauskirchen': 2065,
    'Hausleiten': 2065,
    'Hausmening': 2065,
    'Heidenreichstein': 2065,
    'Heiligeneich': 2065,
    'Heiligenkreuz': 2065,
    'Hennersdorf': 2065,
    'Hernstein': 2065,
    'Herrnbaumgarten': 2065,
    'Herzogenburg': 2065,
    'Himberg': 2065,
    'Hinterbrühl': 2065,
    'Hinterleiten': 2065,
    'Hirschbach': 2065,
    'Hirtenberg': 2065,
    'Hochleithen': 2065,
    'Hochwolkersdorf': 2065,
    'Hof am Leithaberge': 2065,
    'Hofamt Priel': 2065,
    'Höflein': 2065,
    'Höfling': 2065,
    'Hofstetten': 2065,
    'Hohenau': 2065,
    'Hohenberg': 2065,
    'Hoheneich': 2065,
    'Hohenruppersdorf': 2065,
    'Hollabrunn': 2065,
    'Hollenstein an der Ybbs': 2065,
    'Hollenthon': 2065,
    'Horn': 2065,
    'Hundsheim': 2065,
    'Hürm': 2065,
    'Hutten': 2065,
    'Jaidhof': 2065,
    'Japons': 2065,
    'Jedenspeigen': 2065,
    'Kaltenleutgeben': 2065,
    'Kapelln': 2065,
    'Karlstetten': 2065,
    'Kasten bei Böheimkirchen': 2065,
    'Katzelsdorf': 2065,
    'Kaumberg': 2065,
    'Kautzen': 2065,
    'Kematen an der Ybbs': 2065,
    'Kierling': 2065,
    'Kilb': 2065,
    'Kirchberg am Wagram': 2065,
    'Kirchberg am Walde': 2065,
    'Kirchberg am Wechsel': 2065,
    'Kirchberg an der Pielach': 2065,
    'Kirchschlag': 2065,
    'Kirchschlag in der Buckligen Welt': 2065,
    'Kirchstetten': 2065,
    'Kirnberg an der Mank': 2065,
    'Klausen-Leopoldsdorf': 2065,
    'Klein-Neusiedl': 2065,
    'Klein-Pöchlarn': 2065,
    'Kleinzell': 2065,
    'Klosterneuburg': 2065,
    'Königstetten': 2065,
    'Korneuburg': 2065,
    'Kottingbrunn': 2065,
    'Krems an der Donau': 2065,
    'Krems an der Donau Stadt': 2065,
    'Kritzendorf': 2065,
    'Krumau am Kamp': 2065,
    'Krumbach Markt': 2065,
    'Krumnussbaum': 2065,
    'Laa an der Thaya': 2065,
    'Laab im Walde': 2065,
    'Ladendorf': 2065,
    'Landegg': 2065,
    'Langau': 2065,
    'Langenlebarn-Oberaigen': 2065,
    'Langenlois': 2065,
    'Langenrohr': 2065,
    'Langenzersdorf': 2065,
    'Langschlag': 2065,
    'Lanzendorf': 2065,
    'Lanzenkirchen': 2065,
    'Lassee': 2065,
    'Laxenburg': 2065,
    'Leiben': 2065,
    'Leitzersdorf': 2065,
    'Lengenfeld': 2065,
    'Leobendorf': 2065,
    'Leobersdorf': 2065,
    'Leopoldsdorf': 2065,
    'Leopoldsdorf im Marchfelde': 2065,
    'Lerchenfeld': 2065,
    'Lichtenegg': 2065,
    'Lichtenwörth': 2065,
    'Lilienfeld': 2065,
    'Litschau': 2065,
    'Loich': 2065,
    'Loosdorf': 2065,
    'Lunz am See': 2065,
    'Mailberg': 2065,
    'Maissau': 2065,
    'Mank': 2065,
    'Mannersdorf am Leithagebirge': 2065,
    'Mannsdorf an der Donau': 2065,
    'Mannswörth': 2065,
    'Marbach an der Donau': 2065,
    'Marchegg': 2065,
    'Margarethen am Moos': 2065,
    'Maria Ellend': 2065,
    'Maria Enzersdorf': 2065,
    'Maria Laach am Jauerling': 2065,
    'Maria Lanzendorf': 2065,
    'Maria Taferl': 2065,
    'Maria-Anzbach': 2065,
    'Markersdorf an der Pielach': 2065,
    'Markgrafneusiedl': 2065,
    'Markt Piesting': 2065,
    'Martinsberg': 2065,
    'Matzen': 2065,
    'Matzendorf': 2065,
    'Mauer bei Amstetten': 2065,
    'Mauerbach': 2065,
    'Mautern': 2065,
    'Melk': 2065,
    'Michelhausen': 2065,
    'Miesenbach': 2065,
    'Mistelbach': 2065,
    'Mitterbach am Erlaufsee': 2065,
    'Mitterndorf an der Fischa': 2065,
    'Mödling': 2065,
    'Möllersdorf': 2065,
    'Mönichkirchen': 2065,
    'Moosbrunn': 2065,
    'Muckendorf an der Donau': 2065,
    'Muggendorf': 2065,
    'Mühldorf': 2065,
    'Münchendorf': 2065,
    'Neidling': 2065,
    'Neu-Guntramsdorf': 2065,
    'Neudorf bei Staatz': 2065,
    'Neufurth': 2065,
    'Neuhofen an der Ybbs': 2065,
    'Neulengbach': 2065,
    'Neumarkt an der Ybbs': 2065,
    'Neunkirchen': 2065,
    'Neusiedl': 2065,
    'Neusiedl an der Zaya': 2065,
    'Niederhollabrunn': 2065,
    'Niederleis': 2065,
    'Nöchling': 2065,
    'Ober-Grafendorf': 2065,
    'Oberhausen': 2065,
    'Oberndorf an der Melk': 2065,
    'Oberndorf in der Ebene': 2065,
    'Obersdorf': 2065,
    'Obersiebenbrunn': 2065,
    'Oberwagram': 2065,
    'Oberwaltersdorf': 2065,
    'Oberwölbling': 2065,
    'Oehling': 2065,
    'Oeynhausen': 2065,
    'Opponitz': 2065,
    'Orth an der Donau': 2065,
    'Ottenschlag': 2065,
    'Ottenthal': 2065,
    'Otterthal': 2065,
    'Parbasdorf': 2065,
    'Paudorf': 2065,
    'Payerbach': 2065,
    'Penz': 2065,
    'Perchtoldsdorf': 2065,
    'Pernegg': 2065,
    'Pernersdorf': 2065,
    'Pernitz': 2065,
    'Persenbeug': 2065,
    'Petronell-Carnuntum': 2065,
    'Petzenkirchen': 2065,
    'Pfaffstätten': 2065,
    'Pillichsdorf': 2065,
    'Pitten': 2065,
    'Pöchlarn': 2065,
    'Pöggstall': 2065,
    'Politischer Bezirk Amstetten': 2065,
    'Politischer Bezirk Baden': 2065,
    'Politischer Bezirk Bruck an der Leitha': 2065,
    'Politischer Bezirk Gänserndorf': 2065,
    'Politischer Bezirk Gmünd': 2065,
    'Politischer Bezirk Hollabrunn': 2065,
    'Politischer Bezirk Horn': 2065,
    'Politischer Bezirk Korneuburg': 2065,
    'Politischer Bezirk Krems': 2065,
    'Politischer Bezirk Lilienfeld': 2065,
    'Politischer Bezirk Melk': 2065,
    'Politischer Bezirk Mistelbach': 2065,
    'Politischer Bezirk Mödling': 2065,
    'Politischer Bezirk Neunkirchen': 2065,
    'Politischer Bezirk Sankt Pölten': 2065,
    'Politischer Bezirk Scheibbs': 2065,
    'Politischer Bezirk Tulln': 2065,
    'Politischer Bezirk Waidhofen an der Thaya': 2065,
    'Politischer Bezirk Wiener Neustadt': 2065,
    'Politischer Bezirk Zwettl': 2065,
    'Pottenbrunn': 2065,
    'Pottendorf': 2065,
    'Pottenstein': 2065,
    'Pottschach': 2065,
    'Poysdorf': 2065,
    'Prellenkirchen': 2065,
    'Pressbaum': 2065,
    'Prigglitz': 2065,
    'Prinzersdorf': 2065,
    'Prottes': 2065,
    'Puchberg am Schneeberg': 2065,
    'Puchenstuben': 2065,
    'Pulkau': 2065,
    'Purgstall': 2065,
    'Purkersdorf': 2065,
    'Pyhra': 2065,
    'Raabs an der Thaya': 2065,
    'Raach am Hochgebirge': 2065,
    'Raasdorf': 2065,
    'Rabensburg': 2065,
    'Rabenstein an der Pielach': 2065,
    'Ramsau': 2065,
    'Randegg': 2065,
    'Rannersdorf': 2065,
    'Rappottenstein': 2065,
    'Rastenfeld': 2065,
    'Ratzersdorf an der Traisen': 2065,
    'Rauchenwarth': 2065,
    'Ravelsbach': 2065,
    'Raxendorf': 2065,
    'Rehberg': 2065,
    'Reichenau an der Rax': 2065,
    'Reingers': 2065,
    'Reinsberg': 2065,
    'Reisenberg': 2065,
    'Retz': 2065,
    'Rohr im Gebirge': 2065,
    'Rohrau': 2065,
    'Rohrbach an der Gölsen': 2065,
    'Röhrenbach': 2065,
    'Rohrendorf bei Krems': 2065,
    'Röschitz': 2065,
    'Rosenau am Sonntagberg': 2065,
    'Ruprechtshofen': 2065,
    'Sallingberg': 2065,
    'Sankt Aegyd am Neuwalde': 2065,
    'Sankt Andrä vor dem Hagenthale': 2065,
    'Sankt Corona am Wechsel': 2065,
    'Sankt Georgen am Ybbsfelde': 2065,
    'Sankt Georgen an der Leys': 2065,
    'Sankt Leonhard am Forst': 2065,
    'Sankt Leonhard am Hornerwald': 2065,
    'Sankt Margarethen an der Sierning': 2065,
    'Sankt Martin am Ybbsfelde': 2065,
    'Sankt Pantaleon': 2065,
    'Sankt Peter in der Au Markt': 2065,
    'Sankt Pölten': 2065,
    'Sankt Pölten Stadt': 2065,
    'Sankt Valentin': 2065,
    'Sankt Veit an der Gölsen': 2065,
    'Scharndorf': 2065,
    'Scheibbs': 2065,
    'Schleinbach': 2065,
    'Schönau an der Triesting': 2065,
    'Schönbach': 2065,
    'Schönkirchen-Reyersdorf': 2065,
    'Schottwien': 2065,
    'Schrattenbach': 2065,
    'Schrattenberg': 2065,
    'Schrattenthal': 2065,
    'Schrems': 2065,
    'Schwadorf': 2065,
    'Schwarzau am Steinfeld': 2065,
    'Schwarzau im Gebirge': 2065,
    'Schwarzenau': 2065,
    'Schwarzenbach': 2065,
    'Schwarzenbach an der Pielach': 2065,
    'Schwechat': 2065,
    'Schweiggers': 2065,
    'Seebenstein': 2065,
    'Seibersdorf': 2065,
    'Seitenstetten Markt': 2065,
    'Semmering': 2065,
    'Senftenberg': 2065,
    'Seyring': 2065,
    'Sieghartskirchen': 2065,
    'Sierndorf': 2065,
    'Sigmundsherberg': 2065,
    'Sitzendorf an der Schmida': 2065,
    'Sollenau': 2065,
    'Sommerein': 2065,
    'Sonntagberg': 2065,
    'Sooss': 2065,
    'Spannberg': 2065,
    'Spillern': 2065,
    'Spitz': 2065,
    'Spratzern': 2065,
    'Staatz': 2065,
    'Stattersdorf': 2065,
    'Statzendorf': 2065,
    'Stein an der Donau': 2065,
    'Steinabrückl': 2065,
    'Steinakirchen am Forst': 2065,
    'Stephanshart': 2065,
    'Stetteldorf am Wagram': 2065,
    'Stetten': 2065,
    'Stockerau': 2065,
    'Stollhofen': 2065,
    'Stössing': 2065,
    'Strass': 2065,
    'Strasshof an der Nordbahn': 2065,
    'Stratzing': 2065,
    'Strengberg': 2065,
    'Stronsdorf': 2065,
    'Tattendorf': 2065,
    'Teesdorf': 2065,
    'Ternitz': 2065,
    'Thaya': 2065,
    'Theresienfeld': 2065,
    'Thomasberg': 2065,
    'Tiefenbach': 2065,
    'Traisen': 2065,
    'Traiskirchen': 2065,
    'Traismauer': 2065,
    'Trattenbach': 2065,
    'Trautmannsdorf an der Leitha': 2065,
    'Tribuswinkel': 2065,
    'Trumau': 2065,
    'Tulbing': 2065,
    'Tulln': 2065,
    'Tullnerbach-Lawies': 2065,
    'Türnitz': 2065,
    'Ulrichskirchen': 2065,
    'Untersiebenbrunn': 2065,
    'Unterstinkenbrunn': 2065,
    'Unterwagram': 2065,
    'Unterwaltersdorf': 2065,
    'Velm': 2065,
    'Viehofen': 2065,
    'Vitis': 2065,
    'Vösendorf': 2065,
    'Waidhofen an der Thaya': 2065,
    'Waidhofen an der Ybbs': 2065,
    'Waidhofen an der Ybbs Stadt': 2065,
    'Waidmannsfeld': 2065,
    'Waldegg': 2065,
    'Waldenstein': 2065,
    'Waldhausen': 2065,
    'Waldkirchen an der Thaya': 2065,
    'Wallsee': 2065,
    'Walpersbach': 2065,
    'Wang': 2065,
    'Warth': 2065,
    'Wartmannstetten': 2065,
    'Weidling': 2065,
    'Weigelsdorf': 2065,
    'Weikendorf': 2065,
    'Weikersdorf am Steinfelde': 2065,
    'Weinburg': 2065,
    'Weinzierl am Walde': 2065,
    'Weinzierl bei Krems': 2065,
    'Weissenbach an der Triesting': 2065,
    'Weißenkirchen in der Wachau': 2065,
    'Weistrach': 2065,
    'Weiten': 2065,
    'Weitersfeld': 2065,
    'Weitra': 2065,
    'Wiener Neudorf': 2065,
    'Wiener Neustadt': 2065,
    'Wiener Neustadt Stadt': 2065,
    'Wienersdorf': 2065,
    'Wieselburg': 2065,
    'Wiesmath': 2065,
    'Wilfersdorf': 2065,
    'Wilfleinsdorf': 2065,
    'Wilhelmsburg': 2065,
    'Willendorf am Steinfelde': 2065,
    'Wimpassing im Schwarzatale': 2065,
    'Windhag': 2065,
    'Windigsteig': 2065,
    'Winklarn': 2065,
    'Winzendorf': 2065,
    'Wolfpassing': 2065,
    'Wolfsbach': 2065,
    'Wolfsgraben': 2065,
    'Wolfsthal': 2065,
    'Wolkersdorf im Weinviertel': 2065,
    'Wöllersdorf': 2065,
    'Wördern': 2065,
    'Wullersdorf': 2065,
    'Würflach': 2065,
    'Würmla': 2065,
    'Ybbs an der Donau': 2065,
    'Ybbsitz': 2065,
    'Ysper': 2065,
    'Zeillern': 2065,
    'Zeiselmauer': 2065,
    'Zell-Arzberg': 2065,
    'Zell-Markt': 2065,
    'Zellerndorf': 2065,
    'Ziersdorf': 2065,
    'Zillingdorf': 2065,
    'Zistersdorf': 2065,
    'Zöbern': 2065,
    'Zwentendorf': 2065,
    'Zwettl Stadt': 2065,
    'Zwölfaxing': 2065,
    'Abtenau': 2061,
    'Adnet': 2061,
    'Altenmarkt im Pongau': 2061,
    'Anger': 2061,
    'Anif': 2061,
    'Anthering': 2061,
    'Au': 2061,
    'Bad Gastein': 2061,
    'Bad Hofgastein': 2061,
    'Bergheim': 2061,
    'Berndorf bei Salzburg': 2061,
    'Bischofshofen': 2061,
    'Bramberg am Wildkogel': 2061,
    'Bruck an der Großglocknerstraße': 2061,
    'Burgfried': 2061,
    'Bürmoos': 2061,
    'Dienten am Hochkönig': 2061,
    'Dorfbeuern': 2061,
    'Dorfgastein': 2061,
    'Eben im Pongau': 2061,
    'Ebenau': 2061,
    'Eching': 2061,
    'Elixhausen': 2061,
    'Elsbethen': 2061,
    'Esch': 2061,
    'Eugendorf': 2061,
    'Faistenau': 2061,
    'Filzmoos': 2061,
    'Flachau': 2061,
    'Forstau': 2061,
    'Fuschl am See': 2061,
    'Georgenberg': 2061,
    'Glanegg': 2061,
    'Glasenbach': 2061,
    'Golling an der Salzach': 2061,
    'Grödig': 2061,
    'Grossarl': 2061,
    'Grossgmain': 2061,
    'Habach': 2061,
    'Hallein': 2061,
    'Hallwang': 2061,
    'Henndorf am Wallersee': 2061,
    'Himmelreich': 2061,
    'Hinterglemm': 2061,
    'Hintersee': 2061,
    'Hof': 2061,
    'Hof bei Salzburg': 2061,
    'Hollersbach im Pinzgau': 2061,
    'Hüttau': 2061,
    'Hüttschlag': 2061,
    'Irrsdorf': 2061,
    'Kaprun': 2061,
    'Kleinarl': 2061,
    'Koppl': 2061,
    'Köstendorf': 2061,
    'Krimml': 2061,
    'Krispl': 2061,
    'Kuchl': 2061,
    'Lamprechtshausen': 2061,
    'Lehen': 2061,
    'Lend': 2061,
    'Lengfelden': 2061,
    'Leogang': 2061,
    'Lofer': 2061,
    'Maishofen': 2061,
    'Maria Alm am Steinernen Meer': 2061,
    'Mariapfarr': 2061,
    'Mattsee': 2061,
    'Mauterndorf': 2061,
    'Mitterberghütten': 2061,
    'Mitterhofen': 2061,
    'Mittersill': 2061,
    'Mühlbach am Hochkönig': 2061,
    'Neualm': 2061,
    'Neukirchen am Großvenediger': 2061,
    'Neumarkt am Wallersee': 2061,
    'Niederalm': 2061,
    'Niedernsill': 2061,
    'Nußdorf am Haunsberg': 2061,
    'Oberalm': 2061,
    'Obergäu': 2061,
    'Oberndorf bei Salzburg': 2061,
    'Obertrum am See': 2061,
    'Pfarrwerfen': 2061,
    'Pichl': 2061,
    'Piesendorf': 2061,
    'Plainfeld': 2061,
    'Plankenau': 2061,
    'Politischer Bezirk Hallein': 2061,
    'Politischer Bezirk Salzburg-Umgebung': 2061,
    'Politischer Bezirk Sankt Johann im Pongau': 2061,
    'Politischer Bezirk Tamsweg': 2061,
    'Puch bei Hallein': 2061,
    'Radstadt': 2061,
    'Ramingstein': 2061,
    'Rauris': 2061,
    'Reinbach': 2061,
    'Reitberg': 2061,
    'Saalbach': 2061,
    'Saalfelden am Steinernen Meer': 2061,
    'Salzburg': 2061,
    'Salzburg Stadt': 2061,
    'Sankt Andrä im Lungau': 2061,
    'Sankt Gilgen': 2061,
    'Sankt Johann im Pongau': 2061,
    'Sankt Leonhard': 2061,
    'Sankt Margarethen im Lungau': 2061,
    'Sankt Martin bei Lofer': 2061,
    'Sankt Michael im Lungau': 2061,
    'Sankt Veit im Pongau': 2061,
    'Scheffau am Tennengebirge': 2061,
    'Schleedorf': 2061,
    'Schwarzach im Pongau': 2061,
    'Seeham': 2061,
    'Seekirchen am Wallersee': 2061,
    'Seewalchen': 2061,
    'Siezenheim': 2061,
    'St. Martin of Tennengebirge': 2061,
    'Strasswalchen': 2061,
    'Strobl': 2061,
    'Stuhlfelden': 2061,
    'Tamsweg': 2061,
    'Taugl': 2061,
    'Taxach': 2061,
    'Taxenbach': 2061,
    'Thalgau': 2061,
    'Thomatal': 2061,
    'Thumersbach': 2061,
    'Tweng': 2061,
    'Unken': 2061,
    'Unternberg': 2061,
    'Untertauern': 2061,
    'Uttendorf': 2061,
    'Viehhausen': 2061,
    'Viehhofen': 2061,
    'Vigaun': 2061,
    'Wagnergraben': 2061,
    'Wagrain': 2061,
    'Walchen': 2061,
    'Wald im Pinzgau': 2061,
    'Waldprechting': 2061,
    'Wals': 2061,
    'Walserfeld': 2061,
    'Werfen': 2061,
    'Werfenweng': 2061,
    'Zederhaus': 2061,
    'Zell am See': 2061,
    'Abtissendorf': 2059,
    'Admont': 2059,
    'Aflenz Kurort': 2059,
    'Aibl': 2059,
    'Aigen im Ennstal': 2059,
    'Albersdorf': 2059,
    'Allerheiligen bei Wildon': 2059,
    'Allerheiligen im Mürztal': 2059,
    'Altaussee': 2059,
    'Altenmarkt bei Fürstenfeld': 2059,
    'Altenmarkt bei Sankt Gallen': 2059,
    'Andritz': 2059,
    'Anger': 2059,
    'Apfelberg': 2059,
    'Ardning': 2059,
    'Arnfels': 2059,
    'Arzberg': 2059,
    'Attendorf': 2059,
    'Auersbach': 2059,
    'Bad Aussee': 2059,
    'Bad Blumau': 2059,
    'Bad Gams': 2059,
    'Bad Gleichenberg': 2059,
    'Bad Radkersburg': 2059,
    'Bad Waltersdorf': 2059,
    'Baierdorf-Umgebung': 2059,
    'Bairisch Kölldorf': 2059,
    'Bärnbach': 2059,
    'Baumgarten bei Gnas': 2059,
    'Berndorf': 2059,
    'Bierbaum am Auersbach': 2059,
    'Birkfeld': 2059,
    'Blaindorf': 2059,
    'Breitenfeld am Tannenriegel': 2059,
    'Breitenfeld an der Rittschein': 2059,
    'Bretstein': 2059,
    'Bruck an der Mur': 2059,
    'Brunn': 2059,
    'Burgau': 2059,
    'Dechantskirchen': 2059,
    'Deuchendorf': 2059,
    'Deutsch Goritz': 2059,
    'Deutschfeistritz': 2059,
    'Deutschlandsberg': 2059,
    'Diemlach': 2059,
    'Dienersdorf': 2059,
    'Dietersdorf': 2059,
    'Dietersdorf am Gnasbach': 2059,
    'Donawitz': 2059,
    'Donnersbach': 2059,
    'Dürnstein in der Steiermark': 2059,
    'Ebersdorf': 2059,
    'Edelsbach bei Feldbach': 2059,
    'Edelschrott': 2059,
    'Edelsgrub': 2059,
    'Edelstauden': 2059,
    'Eggenberg': 2059,
    'Eggersdorf bei Graz': 2059,
    'Ehrenhausen': 2059,
    'Eibiswald': 2059,
    'Eichberg': 2059,
    'Eichfeld': 2059,
    'Eisbach': 2059,
    'Eisenerz': 2059,
    'Empersdorf': 2059,
    'Eppenstein': 2059,
    'Falkenburg': 2059,
    'Farrach': 2059,
    'Fehring': 2059,
    'Feistritz bei Knittelfeld': 2059,
    'Feldbach': 2059,
    'Feldkirchen bei Graz': 2059,
    'Fernitz': 2059,
    'Fischbach': 2059,
    'Fladnitz im Raabtal': 2059,
    'Flatschach': 2059,
    'Floing': 2059,
    'Fohnsdorf': 2059,
    'Frannach': 2059,
    'Frauenberg': 2059,
    'Freidorf an der Laßnitz': 2059,
    'Freiland bei Deutschlandsberg': 2059,
    'Friedberg': 2059,
    'Frohnleiten': 2059,
    'Fürstenfeld': 2059,
    'Gaal': 2059,
    'Gabersdorf': 2059,
    'Gallmannsegg': 2059,
    'Gamlitz': 2059,
    'Gams bei Hieflau': 2059,
    'Ganz': 2059,
    'Garanas': 2059,
    'Gasen': 2059,
    'Geidorf': 2059,
    'Geistthal': 2059,
    'Gersdorf an der Feistritz': 2059,
    'Gleinstätten': 2059,
    'Gleisdorf': 2059,
    'Glojach': 2059,
    'Gnas': 2059,
    'Gniebing': 2059,
    'Gosdorf': 2059,
    'Göss': 2059,
    'Gössenberg': 2059,
    'Gossendorf': 2059,
    'Gösting': 2059,
    'Göttelsberg': 2059,
    'Grabersdorf': 2059,
    'Grafendorf bei Hartberg': 2059,
    'Gralla': 2059,
    'Grambach': 2059,
    'Gratkorn': 2059,
    'Gratwein': 2059,
    'Graz': 2059,
    'Graz Stadt': 2059,
    'Greisdorf': 2059,
    'Gressenberg': 2059,
    'Gries': 2059,
    'Gröbming': 2059,
    'Groß Sankt Florian': 2059,
    'Großklein': 2059,
    'Großlobming': 2059,
    'Großsölk': 2059,
    'Grosssulz': 2059,
    'Gundersdorf': 2059,
    'Hafendorf': 2059,
    'Hainersdorf': 2059,
    'Hainsdorf im Schwarzautal': 2059,
    'Halbenrain': 2059,
    'Hall bei Admont': 2059,
    'Hart bei Graz': 2059,
    'Hartberg': 2059,
    'Hartl': 2059,
    'Hartmannsdorf': 2059,
    'Hatzendorf': 2059,
    'Haus': 2059,
    'Haus im Ennstal': 2059,
    'Hausmannstätten': 2059,
    'Heiligenkreuz am Waasen': 2059,
    'Heimschuh': 2059,
    'Hengsberg': 2059,
    'Hetzendorf': 2059,
    'Hieflau': 2059,
    'Hinterberg': 2059,
    'Hirnsdorf': 2059,
    'Hitzendorf': 2059,
    'Hochtregist': 2059,
    'Höf': 2059,
    'Hof bei Straden': 2059,
    'Hofstätten an der Raab': 2059,
    'Hohenau an der Raab': 2059,
    'Hohentauern': 2059,
    'Hollenegg': 2059,
    'Hönigsberg': 2059,
    'Ilz': 2059,
    'Innere Stadt': 2059,
    'Irdning': 2059,
    'Jagerberg': 2059,
    'Jakomini': 2059,
    'Johnsbach': 2059,
    'Judenburg': 2059,
    'Judendorf': 2059,
    'Kaibing': 2059,
    'Kainach bei Voitsberg': 2059,
    'Kainbach': 2059,
    'Kaindorf': 2059,
    'Kaindorf an der Sulm': 2059,
    'Kalsdorf bei Graz': 2059,
    'Kalwang': 2059,
    'Kammern im Liesingtal': 2059,
    'Kapellen': 2059,
    'Kapfenberg': 2059,
    'Kapfenstein': 2059,
    'Kindberg': 2059,
    'Kirchbach in Steiermark': 2059,
    'Kirchberg an der Raab': 2059,
    'Kirchenviertel': 2059,
    'Kitzeck im Sausal': 2059,
    'Kleinlobming': 2059,
    'Kleinsöding': 2059,
    'Kleinsölk': 2059,
    'Klöch': 2059,
    'Kloster': 2059,
    'Knittelfeld': 2059,
    'Kobenz': 2059,
    'Köflach': 2059,
    'Kohlberg': 2059,
    'Kohlschwarz': 2059,
    'Krakaudorf': 2059,
    'Krakauhintermühlen': 2059,
    'Krakauschatten': 2059,
    'Kraubath an der Mur': 2059,
    'Krieglach': 2059,
    'Krottendorf': 2059,
    'Krottendorf bei Ligist': 2059,
    'Krumegg': 2059,
    'Krusdorf': 2059,
    'Kulm am Zirbitz': 2059,
    'Kumberg': 2059,
    'Labuch': 2059,
    'Lafnitz': 2059,
    'Landl': 2059,
    'Lang': 2059,
    'Langegg bei Graz': 2059,
    'Langenwang': 2059,
    'Lannach': 2059,
    'Lassnitzhöhe': 2059,
    'Leibnitz': 2059,
    'Leitendorf': 2059,
    'Leitersdorf im Raabtal': 2059,
    'Leitring': 2059,
    'Lend': 2059,
    'Leoben': 2059,
    'Leutschach': 2059,
    'Liebenau': 2059,
    'Lieboch': 2059,
    'Liesingtal': 2059,
    'Liezen': 2059,
    'Ligist': 2059,
    'Lödersdorf': 2059,
    'Loipersdorf bei Fürstenfeld': 2059,
    'Maierdorf': 2059,
    'Maria Lankowitz': 2059,
    'Mariahof': 2059,
    'Mariatrost': 2059,
    'Mariazell': 2059,
    'Mautern in Steiermark': 2059,
    'Mellach': 2059,
    'Merkendorf': 2059,
    'Mettersdorf am Saßbach': 2059,
    'Michaelerberg': 2059,
    'Mitterdorf an der Raab': 2059,
    'Mitterdorf im Mürztal': 2059,
    'Mitterlabill': 2059,
    'Mitterndorf im Steirischen Salzkammergut': 2059,
    'Modriach': 2059,
    'Mönichwald': 2059,
    'Mooskirchen': 2059,
    'Mortantsch': 2059,
    'Mühldorf bei Feldbach': 2059,
    'Mühlen': 2059,
    'Murau': 2059,
    'Mureck': 2059,
    'Mürzhofen': 2059,
    'Mürzsteg': 2059,
    'Mürzzuschlag': 2059,
    'Naas': 2059,
    'Nestelbach bei Graz': 2059,
    'Nestelbach im Ilztal': 2059,
    'Neuberg an der Mürz': 2059,
    'Neudau': 2059,
    'Neumarkt in Steiermark': 2059,
    'Neutillmitsch': 2059,
    'Niederöblarn': 2059,
    'Niederschöckl': 2059,
    'Niederwölz': 2059,
    'Niklasdorf': 2059,
    'Nitscha': 2059,
    'Obdach': 2059,
    'Oberaich': 2059,
    'Oberdorf am Hochegg': 2059,
    'Oberhaag': 2059,
    'Oberkurzheim': 2059,
    'Oberpremstätten': 2059,
    'Oberrettenbach': 2059,
    'Oberstorcha': 2059,
    'Obervogau': 2059,
    'Oberweg': 2059,
    'Oberwölz Stadt': 2059,
    'Oberzeiring': 2059,
    'Öblarn': 2059,
    'Oppenberg': 2059,
    'Ortgraben': 2059,
    'Osterwitz': 2059,
    'Pack': 2059,
    'Paldau': 2059,
    'Palfau': 2059,
    'Parschlug': 2059,
    'Passail': 2059,
    'Peggau': 2059,
    'Perchau am Sattel': 2059,
    'Perlsdorf': 2059,
    'Pernegg an der Mur': 2059,
    'Pertlstein': 2059,
    'Piberegg': 2059,
    'Pichling bei Köflach': 2059,
    'Pinggau': 2059,
    'Pirching am Traubenberg': 2059,
    'Pirka': 2059,
    'Pischelsdorf in der Steiermark': 2059,
    'Pistorf': 2059,
    'Pitschgau': 2059,
    'Politischer Bezirk Bruck-Mürzzuschlag': 2059,
    'Politischer Bezirk Deutschlandsberg': 2059,
    'Politischer Bezirk Graz-Umgebung': 2059,
    'Politischer Bezirk Hartberg-Fürstenfeld': 2059,
    'Politischer Bezirk Leibnitz': 2059,
    'Politischer Bezirk Leoben': 2059,
    'Politischer Bezirk Liezen': 2059,
    'Politischer Bezirk Murau': 2059,
    'Politischer Bezirk Murtal': 2059,
    'Politischer Bezirk Südoststeiermark': 2059,
    'Politischer Bezirk Voitsberg': 2059,
    'Politischer Bezirk Weiz': 2059,
    'Pöllau': 2059,
    'Pöllauberg': 2059,
    'Pöls': 2059,
    'Poppendorf': 2059,
    'Preding': 2059,
    'Proleb': 2059,
    'Pruggern': 2059,
    'Puch bei Weiz': 2059,
    'Puntigam': 2059,
    'Pusterwald': 2059,
    'Raaba': 2059,
    'Raabau': 2059,
    'Rabenwald': 2059,
    'Rachau': 2059,
    'Ragnitz': 2059,
    'Ramsau am Dachstein': 2059,
    'Raning': 2059,
    'Ranten': 2059,
    'Rassach': 2059,
    'Ratsch an der Weinstraße': 2059,
    'Ratschendorf': 2059,
    'Ratten': 2059,
    'Reichendorf': 2059,
    'Reifling': 2059,
    'Rettenegg': 2059,
    'Retznei': 2059,
    'Riegersburg': 2059,
    'Ries': 2059,
    'Rohrbach an der Lafnitz': 2059,
    'Rosental an der Kainach': 2059,
    'Röthelstein': 2059,
    'Rottenmann': 2059,
    'Salla': 2059,
    'Sankt Anna am Aigen': 2059,
    'Sankt Bartholomä': 2059,
    'Sankt Blasen': 2059,
    'Sankt Gallen': 2059,
    'Sankt Georgen an der Stiefing': 2059,
    'Sankt Georgen ob Judenburg': 2059,
    'Sankt Georgen ob Murau': 2059,
    'Sankt Ilgen': 2059,
    'Sankt Jakob im Walde': 2059,
    'Sankt Jakob-Breitenau': 2059,
    'Sankt Johann am Tauern': 2059,
    'Sankt Johann bei Herberstein': 2059,
    'Sankt Johann im Saggautal': 2059,
    'Sankt Johann in der Haide': 2059,
    'Sankt Josef (Weststeiermark)': 2059,
    'Sankt Katharein an der Laming': 2059,
    'Sankt Kathrein am Hauenstein': 2059,
    'Sankt Kathrein am Offenegg': 2059,
    'Sankt Lambrecht': 2059,
    'Sankt Leonhard': 2059,
    'Sankt Lorenzen am Wechsel': 2059,
    'Sankt Lorenzen bei Knittelfeld': 2059,
    'Sankt Lorenzen bei Scheifling': 2059,
    'Sankt Lorenzen im Mürztal': 2059,
    'Sankt Marein bei Graz': 2059,
    'Sankt Marein bei Knittelfeld': 2059,
    'Sankt Marein bei Neumarkt': 2059,
    'Sankt Marein im Mürztal': 2059,
    'Sankt Margarethen an der Raab': 2059,
    'Sankt Margarethen bei Knittelfeld': 2059,
    'Sankt Martin': 2059,
    'Sankt Martin am Grimming': 2059,
    'Sankt Martin im Sulmtal': 2059,
    'Sankt Michael in Obersteiermark': 2059,
    'Sankt Nikolai im Sausal': 2059,
    'Sankt Nikolai im Sölktal': 2059,
    'Sankt Nikolai ob Draßling': 2059,
    'Sankt Oswald bei Plankenwarth': 2059,
    'Sankt Oswald ob Eibiswald': 2059,
    'Sankt Peter': 2059,
    'Sankt Peter am Kammersberg': 2059,
    'Sankt Peter am Ottersbach': 2059,
    'Sankt Peter im Sulmtal': 2059,
    'Sankt Peter ob Judenburg': 2059,
    'Sankt Peter-Freienstein': 2059,
    'Sankt Radegund bei Graz': 2059,
    'Sankt Ruprecht an der Raab': 2059,
    'Sankt Stefan im Rosental': 2059,
    'Sankt Stefan ob Leoben': 2059,
    'Sankt Stefan ob Stainz': 2059,
    'Sankt Ulrich am Waasen': 2059,
    'Sankt Veit am Vogau': 2059,
    'Schachen bei Vorau': 2059,
    'Schäffern': 2059,
    'Scheifling': 2059,
    'Schladming': 2059,
    'Schöder': 2059,
    'Schrems bei Frohnleiten': 2059,
    'Schwanberg': 2059,
    'Schwarzau im Schwarzautal': 2059,
    'Sebersdorf': 2059,
    'Seckau': 2059,
    'Seggauberg': 2059,
    'Seiersberg': 2059,
    'Selzthal': 2059,
    'Semriach': 2059,
    'Siegersdorf bei Herberstein': 2059,
    'Sinabelkirchen': 2059,
    'Soboth': 2059,
    'Söchau': 2059,
    'Södingberg': 2059,
    'Spatenhof': 2059,
    'Spielberg bei Knittelfeld': 2059,
    'Spielfeld': 2059,
    'Spital am Semmering': 2059,
    'Stadl an der Mur': 2059,
    'Stainach': 2059,
    'Stainz': 2059,
    'Stainz bei Straden': 2059,
    'Stallhof': 2059,
    'Stallhofen': 2059,
    'Stambach': 2059,
    'Stattegg': 2059,
    'Stein': 2059,
    'Stenzengreith': 2059,
    'Stiwoll': 2059,
    'Straden': 2059,
    'Strallegg': 2059,
    'Straß in Steiermark': 2059,
    'Strassengel': 2059,
    'Straßgang': 2059,
    'Stubenberg': 2059,
    'Studenzen': 2059,
    'Sulztal an der Weinstraße': 2059,
    'Tauplitz': 2059,
    'Teufenbach': 2059,
    'Thal': 2059,
    'Thörl': 2059,
    'Tieschen': 2059,
    'Tillmitsch': 2059,
    'Tillmitsch Links der Laßnitz': 2059,
    'Traboch': 2059,
    'Trahütten': 2059,
    'Trautmannsdorf in Oststeiermark': 2059,
    'Treglwang': 2059,
    'Trieben': 2059,
    'Triebendorf': 2059,
    'Trofaiach': 2059,
    'Trössing': 2059,
    'Turnau': 2059,
    'Übelbach': 2059,
    'Übersbach': 2059,
    'Ungerdorf': 2059,
    'Unterauersbach': 2059,
    'Unterbergla': 2059,
    'Unterfladnitz': 2059,
    'Unterlamm': 2059,
    'Unterpremstätten': 2059,
    'Utschtal': 2059,
    'Vasoldsberg': 2059,
    'Veitsch': 2059,
    'Vogau': 2059,
    'Voitsberg': 2059,
    'Vorau': 2059,
    'Vordernberg': 2059,
    'Wagna': 2059,
    'Wagnitz': 2059,
    'Wald am Schoberpaß': 2059,
    'Waltendorf': 2059,
    'Wartberg': 2059,
    'Weinburg am Saßbach': 2059,
    'Weißenbach bei Liezen': 2059,
    'Weißkirchen in Steiermark': 2059,
    'Weiz': 2059,
    'Werndorf': 2059,
    'Wernersdorf': 2059,
    'Wettmannstätten': 2059,
    'Wetzelsdorf': 2059,
    'Wies': 2059,
    'Wildalpen': 2059,
    'Wildon': 2059,
    'Winklern bei Oberwölz': 2059,
    'Wolfsberg im Schwarzautal': 2059,
    'Wörschach': 2059,
    'Wörth an der Lafnitz': 2059,
    'Wundschuh': 2059,
    'Zeltweg': 2059,
    'Zerlach': 2059,
    'Zettling': 2059,
    'Zeutschach': 2059,
    'Abfaltersbach': 2064,
    'Absam': 2064,
    'Achenkirch': 2064,
    'Ainet': 2064,
    'Aldrans': 2064,
    'Alpbach': 2064,
    'Amlach': 2064,
    'Ampass': 2064,
    'Amras': 2064,
    'Angath': 2064,
    'Angerberg': 2064,
    'Anras': 2064,
    'Arzl': 2064,
    'Arzl im Pitztal': 2064,
    'Aschau': 2064,
    'Aschau im Zillertal': 2064,
    'Aurach': 2064,
    'Außervillgraten': 2064,
    'Axams': 2064,
    'Bad Häring': 2064,
    'Barwies': 2064,
    'Baumkirchen': 2064,
    'Berwang': 2064,
    'Biberwier': 2064,
    'Bichlbach': 2064,
    'Birgitz': 2064,
    'Brandenberg': 2064,
    'Breitenbach am Inn': 2064,
    'Breitenwang': 2064,
    'Brixen im Thale': 2064,
    'Brixlegg': 2064,
    'Bruck am Ziller': 2064,
    'Buch in Tirol': 2064,
    'Debant': 2064,
    'Dölsach': 2064,
    'Ebbs': 2064,
    'Eben am Achensee': 2064,
    'Ehenbichl': 2064,
    'Ehrwald': 2064,
    'Elbigenalp': 2064,
    'Ellbögen': 2064,
    'Ellmau': 2064,
    'Elmen': 2064,
    'Endach': 2064,
    'Erl': 2064,
    'Erpfendorf': 2064,
    'Fendels': 2064,
    'Fieberbrunn': 2064,
    'Fiecht': 2064,
    'Finkenberg': 2064,
    'Fiss': 2064,
    'Flaurling': 2064,
    'Fliess': 2064,
    'Flirsch': 2064,
    'Forchach': 2064,
    'Fritzens': 2064,
    'Fügen': 2064,
    'Fügenberg': 2064,
    'Fulpmes': 2064,
    'Gallzein': 2064,
    'Galtür': 2064,
    'Gerlos': 2064,
    'Gerlosberg': 2064,
    'Gnadenwald': 2064,
    'Going': 2064,
    'Götzens': 2064,
    'Gramais': 2064,
    'Grän': 2064,
    'Gries am Brenner': 2064,
    'Gries im Sellrain': 2064,
    'Grins': 2064,
    'Grinzens': 2064,
    'Gschnitz': 2064,
    'Haiming': 2064,
    'Hainzenberg': 2064,
    'Hall in Tirol': 2064,
    'Hart im Zillertal': 2064,
    'Häselgehr': 2064,
    'Hatting': 2064,
    'Heinfels': 2064,
    'Heiterwang': 2064,
    'Hippach': 2064,
    'Hochfilzen': 2064,
    'Höfen': 2064,
    'Holzgau': 2064,
    'Hopfgarten im Brixental': 2064,
    'Hopfgarten in Defereggen': 2064,
    'Hötting': 2064,
    'Igls': 2064,
    'Imst': 2064,
    'Imsterberg': 2064,
    'Innervillgraten': 2064,
    'Innsbruck': 2064,
    'Innsbruck Stadt': 2064,
    'Inzing': 2064,
    'Ischgl': 2064,
    'Itter': 2064,
    'Jenbach': 2064,
    'Jerzens': 2064,
    'Jochberg': 2064,
    'Jungholz': 2064,
    'Kaltenbach': 2064,
    'Kapfing': 2064,
    'Kappl': 2064,
    'Karres': 2064,
    'Karrösten': 2064,
    'Kartitsch': 2064,
    'Kauns': 2064,
    'Kematen in Tirol': 2064,
    'Kirchberg in Tirol': 2064,
    'Kirchbichl': 2064,
    'Kirchdorf in Tirol': 2064,
    'Kitzbühel': 2064,
    'Kolsass': 2064,
    'Kolsassberg': 2064,
    'Kössen': 2064,
    'Kramsach': 2064,
    'Kufstein': 2064,
    'Kundl': 2064,
    'Ladis': 2064,
    'Landeck': 2064,
    'Längenfeld': 2064,
    'Lans': 2064,
    'Lavant': 2064,
    'Lechaschau': 2064,
    'Leisach': 2064,
    'Lermoos': 2064,
    'Leutasch': 2064,
    'Lienz': 2064,
    'Mariastein': 2064,
    'Mariatal': 2064,
    'Matrei am Brenner': 2064,
    'Matrei in Osttirol': 2064,
    'Maurach': 2064,
    'Mayrhofen': 2064,
    'Medraz': 2064,
    'Mieders': 2064,
    'Mieming': 2064,
    'Mils bei Imst': 2064,
    'Mils bei Solbad Hall': 2064,
    'Mitterndorf': 2064,
    'Mötz': 2064,
    'Mühlau': 2064,
    'Mühlbachl': 2064,
    'Münster': 2064,
    'Musau': 2064,
    'Mutters': 2064,
    'Namlos': 2064,
    'Nassereith': 2064,
    'Natters': 2064,
    'Nauders': 2064,
    'Nesselwängle': 2064,
    'Neustift im Stubaital': 2064,
    'Niederau': 2064,
    'Niederbreitenbach': 2064,
    'Niederndorf': 2064,
    'Nikolsdorf': 2064,
    'Oberau': 2064,
    'Oberhofen im Inntal': 2064,
    'Oberlienz': 2064,
    'Obermieming': 2064,
    'Obernberg am Brenner': 2064,
    'Oberndorf in Tirol': 2064,
    'Oberperfuss': 2064,
    'Obertilliach': 2064,
    'Obsteig': 2064,
    'Oetz': 2064,
    'Ötztal-Bahnhof': 2064,
    'Patsch': 2064,
    'Pettnau': 2064,
    'Pettneu': 2064,
    'Pfaffenhofen': 2064,
    'Pflach': 2064,
    'Pfons': 2064,
    'Pfunds': 2064,
    'Pians': 2064,
    'Pill': 2064,
    'Politischer Bezirk Imst': 2064,
    'Politischer Bezirk Innsbruck Land': 2064,
    'Politischer Bezirk Kitzbühel': 2064,
    'Politischer Bezirk Kufstein': 2064,
    'Politischer Bezirk Landeck': 2064,
    'Politischer Bezirk Lienz': 2064,
    'Politischer Bezirk Reutte': 2064,
    'Politischer Bezirk Schwaz': 2064,
    'Polling in Tirol': 2064,
    'Pradl': 2064,
    'Prutz': 2064,
    'Radfeld': 2064,
    'Ramsau im Zillertal': 2064,
    'Ranggen': 2064,
    'Rattenberg': 2064,
    'Reith bei Kitzbühel': 2064,
    'Reith bei Seefeld': 2064,
    'Reith im Alpbachtal': 2064,
    'Rettenschöss': 2064,
    'Reutte': 2064,
    'Ried im Oberinntal': 2064,
    'Ried im Zillertal': 2064,
    'Rietz': 2064,
    'Rinn': 2064,
    'Rohrberg': 2064,
    'Roppen': 2064,
    'Rum': 2064,
    'Sankt Johann in Tirol': 2064,
    'Sankt Ulrich am Pillersee': 2064,
    'Sankt Veit in Defereggen': 2064,
    'Sautens': 2064,
    'Scharnitz': 2064,
    'Schattwald': 2064,
    'Scheffau am Wilden Kaiser': 2064,
    'Schlaiten': 2064,
    'Schlitters': 2064,
    'Schönberg im Stubaital': 2064,
    'Schönwies': 2064,
    'Schwaz': 2064,
    'Schwendau': 2064,
    'Schwendt': 2064,
    'Schwoich': 2064,
    'See': 2064,
    'Seefeld in Tirol': 2064,
    'Sellrain': 2064,
    'Serfaus': 2064,
    'Sillian': 2064,
    'Silz': 2064,
    'Sistrans': 2064,
    'Sölden': 2064,
    'Söll': 2064,
    'St Anton am Arlberg': 2064,
    'Stams': 2064,
    'Stans': 2064,
    'Stanz bei Landeck': 2064,
    'Stanzach': 2064,
    'Steeg': 2064,
    'Steinach am Brenner': 2064,
    'Strass im Zillertal': 2064,
    'Strassen': 2064,
    'Strengen': 2064,
    'Stumm': 2064,
    'Stummerberg': 2064,
    'Tannheim': 2064,
    'Tarrenz': 2064,
    'Telfes im Stubai': 2064,
    'Telfs': 2064,
    'Terfens': 2064,
    'Thaur': 2064,
    'Thurn': 2064,
    'Tobadill': 2064,
    'Trins': 2064,
    'Tristach': 2064,
    'Tulfes': 2064,
    'Tux': 2064,
    'Uderns': 2064,
    'Umhausen': 2064,
    'Unterlangkampfen': 2064,
    'Unterperfuss': 2064,
    'Untertilliach': 2064,
    'Vals': 2064,
    'Vils': 2064,
    'Virgen': 2064,
    'Volders': 2064,
    'Voldöpp': 2064,
    'Völs': 2064,
    'Vomp': 2064,
    'Vorderhornbach': 2064,
    'Vorderthiersee': 2064,
    'Waidring': 2064,
    'Walchsee': 2064,
    'Wängle': 2064,
    'Wattenberg': 2064,
    'Wattens': 2064,
    'Weer': 2064,
    'Weerberg': 2064,
    'Weissach': 2064,
    'Weissenbach am Lech': 2064,
    'Wenns': 2064,
    'Westendorf': 2064,
    'Wiesing': 2064,
    'Wildermieming': 2064,
    'Wildschönau': 2064,
    'Wilten': 2064,
    'Wörgl': 2064,
    'Zams': 2064,
    'Zell': 2064,
    'Zell am Ziller': 2064,
    'Zellberg': 2064,
    'Zirl': 2064,
    'Zöblen': 2064,
    'Abwinden': 2058,
    'Adlwang': 2058,
    'Afiesl': 2058,
    'Ahorn': 2058,
    'Aichkirchen': 2058,
    'Aigen im Mühlkreis': 2058,
    'Aistersheim': 2058,
    'Alberndorf in der Riedmark': 2058,
    'Alkoven': 2058,
    'Allerheiligen im Mühlkreis': 2058,
    'Allhaming': 2058,
    'Altenberg bei Linz': 2058,
    'Altenfelden': 2058,
    'Altheim': 2058,
    'Altlichtenberg': 2058,
    'Altmünster': 2058,
    'Ampflwang': 2058,
    'Andorf': 2058,
    'Ansfelden': 2058,
    'Arnreit': 2058,
    'Aschach an der Donau': 2058,
    'Aschach an der Steyr': 2058,
    'Asten': 2058,
    'Attersee': 2058,
    'Attnang': 2058,
    'Attnang-Puchheim': 2058,
    'Atzbach': 2058,
    'Atzesberg': 2058,
    'Au an der Traun': 2058,
    'Audorf': 2058,
    'Aurach am Hongar': 2058,
    'Aurolzmünster': 2058,
    'Bachmanning': 2058,
    'Bad Goisern': 2058,
    'Bad Hall': 2058,
    'Bad Ischl': 2058,
    'Bad Kreuzen': 2058,
    'Bad Leonfelden': 2058,
    'Bad Schallerbach': 2058,
    'Bad Zell': 2058,
    'Berg': 2058,
    'Bergern': 2058,
    'Bergham': 2058,
    'Bernardin': 2058,
    'Brandln': 2058,
    'Braunau am Inn': 2058,
    'Braunau Neustadt': 2058,
    'Buchkirchen': 2058,
    'Burgfried': 2058,
    'Desselbrunn': 2058,
    'Dickerldorf': 2058,
    'Diesseits': 2058,
    'Dietach': 2058,
    'Dietachdorf': 2058,
    'Doppl': 2058,
    'Dörfl': 2058,
    'Dürnau': 2058,
    'Ebensee': 2058,
    'Eberschwang': 2058,
    'Eberstalzell': 2058,
    'Edt bei Lambach': 2058,
    'Eferding': 2058,
    'Eidenberg': 2058,
    'Engerwitzdorf': 2058,
    'Enns': 2058,
    'Feldkirchen an der Donau': 2058,
    'Fisching': 2058,
    'Fornach': 2058,
    'Frankenburg': 2058,
    'Frankenmarkt': 2058,
    'Freindorf': 2058,
    'Freistadt': 2058,
    'Gaflenz': 2058,
    'Gallneukirchen': 2058,
    'Gallspach': 2058,
    'Gampern': 2058,
    'Garsten': 2058,
    'Gaspoltshofen': 2058,
    'Gaumberg': 2058,
    'Geboltskirchen': 2058,
    'Gmunden': 2058,
    'Goldwörth': 2058,
    'Gosau': 2058,
    'Gramastetten': 2058,
    'Grein': 2058,
    'Grieskirchen': 2058,
    'Grossraming': 2058,
    'Grünau im Almtal': 2058,
    'Grünburg': 2058,
    'Gschwandt': 2058,
    'Gunskirchen': 2058,
    'Gutau': 2058,
    'Haag': 2058,
    'Haag am Hausruck': 2058,
    'Hagenberg im Mühlkreis': 2058,
    'Haibach im Mühlkreis': 2058,
    'Haid': 2058,
    'Haiden': 2058,
    'Haidl': 2058,
    'Handenberg': 2058,
    'Hart': 2058,
    'Hartheim': 2058,
    'Hartkirchen': 2058,
    'Haselbach': 2058,
    'Haslach an der Mühl': 2058,
    'Hellmonsödt': 2058,
    'Herzogsdorf': 2058,
    'Hochburg-Ach': 2058,
    'Hochpoint': 2058,
    'Hof': 2058,
    'Hofkirchen im Traunkreis': 2058,
    'Holzhausen': 2058,
    'Hörsching': 2058,
    'Innerschwand': 2058,
    'Inzersdorf im Kremstal': 2058,
    'Kaltenbach': 2058,
    'Katsdorf': 2058,
    'Kematen an der Krems': 2058,
    'Kirchberg ob der Donau': 2058,
    'Kirchdorf an der Krems': 2058,
    'Kirchschlag bei Linz': 2058,
    'Klaffer am Hochficht': 2058,
    'Königswiesen': 2058,
    'Kopfing im Innkreis': 2058,
    'Kremsdorf': 2058,
    'Kremsmünster': 2058,
    'Kronstorf': 2058,
    'Laab': 2058,
    'Laahen': 2058,
    'Laakirchen': 2058,
    'Lambach': 2058,
    'Langenstein': 2058,
    'Langholzfeld': 2058,
    'Laussa': 2058,
    'Lembach im Mühlkreis': 2058,
    'Lenzing': 2058,
    'Leonding': 2058,
    'Leonstein': 2058,
    'Lichtenegg': 2058,
    'Linz': 2058,
    'Linz Stadt': 2058,
    'Losenstein': 2058,
    'Luftenberg an der Donau': 2058,
    'Manning': 2058,
    'Marchtrenk': 2058,
    'Maria Neustift': 2058,
    'Maria Schmolln': 2058,
    'Mattighofen': 2058,
    'Mauerkirchen': 2058,
    'Meggenhofen': 2058,
    'Mehrnbach': 2058,
    'Michaelnbach': 2058,
    'Micheldorf in Oberösterreich': 2058,
    'Mitterkirchen im Machland': 2058,
    'Mittertreffling': 2058,
    'Molln': 2058,
    'Mondsee': 2058,
    'Moosdorf': 2058,
    'Mühldorf': 2058,
    'Munderfing': 2058,
    'Münzbach': 2058,
    'Münzkirchen': 2058,
    'Naarn im Machlande': 2058,
    'Natternbach': 2058,
    'Nebelberg': 2058,
    'Neubau': 2058,
    'Neue Heimat': 2058,
    'Neuhofen an der Krems': 2058,
    'Neukirchen': 2058,
    'Neukirchen am Walde': 2058,
    'Neukirchen an der Vöckla': 2058,
    'Neukirchen bei Lambach': 2058,
    'Neumarkt im Hausruckkreis': 2058,
    'Neumarkt im Mühlkreis': 2058,
    'Neuzeug': 2058,
    'Niederneukirchen': 2058,
    'Niederstraß': 2058,
    'Niederthalheim': 2058,
    'Nöstlbach': 2058,
    'Oberhaid': 2058,
    'Oberhart': 2058,
    'Oberhofen am Irrsee': 2058,
    'Obernberg am Inn': 2058,
    'Oberndorf bei Schwanenstadt': 2058,
    'Oberneukirchen': 2058,
    'Oberwang': 2058,
    'Oedt': 2058,
    'Oepping': 2058,
    'Offenhausen': 2058,
    'Ohlsdorf': 2058,
    'Ort im Innkreis': 2058,
    'Ostermiething': 2058,
    'Ottenschlag im Mühlkreis': 2058,
    'Ottensheim': 2058,
    'Ottnang am Hausruck': 2058,
    'Pabneukirchen': 2058,
    'Palting': 2058,
    'Pasching': 2058,
    'Pattigham': 2058,
    'Pennewang': 2058,
    'Perg': 2058,
    'Perwang am Grabensee': 2058,
    'Pettenbach': 2058,
    'Peuerbach': 2058,
    'Pfaffing': 2058,
    'Pfarrkirchen bei Bad Hall': 2058,
    'Pichl bei Wels': 2058,
    'Pierbach': 2058,
    'Pinsdorf': 2058,
    'Pitzenberg': 2058,
    'Plesching': 2058,
    'Politischer Bezirk Braunau am Inn': 2058,
    'Politischer Bezirk Eferding': 2058,
    'Politischer Bezirk Freistadt': 2058,
    'Politischer Bezirk Gmunden': 2058,
    'Politischer Bezirk Grieskirchen': 2058,
    'Politischer Bezirk Kirchdorf an der Krems': 2058,
    'Politischer Bezirk Linz-Land': 2058,
    'Politischer Bezirk Perg': 2058,
    'Politischer Bezirk Ried im Innkreis': 2058,
    'Politischer Bezirk Rohrbach': 2058,
    'Politischer Bezirk Schärding': 2058,
    'Politischer Bezirk Steyr-Land': 2058,
    'Politischer Bezirk Urfahr-Umgebung': 2058,
    'Politischer Bezirk Vöcklabruck': 2058,
    'Pötting': 2058,
    'Pram': 2058,
    'Prambachkirchen': 2058,
    'Pramet': 2058,
    'Pregarten': 2058,
    'Puchenau': 2058,
    'Puchheim': 2058,
    'Puchlkirchen am Trattberg': 2058,
    'Pucking': 2058,
    'Pühret': 2058,
    'Raab': 2058,
    'Raffelstetten': 2058,
    'Rainbach im Mühlkreis': 2058,
    'Ranshofen': 2058,
    'Redleiten': 2058,
    'Redlham': 2058,
    'Regau': 2058,
    'Reichenau im Mühlkreis': 2058,
    'Reichenthal': 2058,
    'Reichraming': 2058,
    'Reiterndorf': 2058,
    'Ried im Innkreis': 2058,
    'Ried im Traunkreis': 2058,
    'Ried in der Riedmark': 2058,
    'Rohr im Kremstal': 2058,
    'Rohrbach-Berg': 2058,
    'Roitham': 2058,
    'Rufling': 2058,
    'Rüstorf': 2058,
    'Rutzenham': 2058,
    'Sammersdorf': 2058,
    'Sankt Aegidi': 2058,
    'Sankt Dionysen': 2058,
    'Sankt Florian': 2058,
    'Sankt Georgen am Fillmannsbach': 2058,
    'Sankt Georgen an der Gusen': 2058,
    'Sankt Georgen bei Obernberg am Inn': 2058,
    'Sankt Georgen im Attergau': 2058,
    'Sankt Gotthard im Mühlkreis': 2058,
    'Sankt Lorenz': 2058,
    'Sankt Marien': 2058,
    'Sankt Martin': 2058,
    'Sankt Martin im Mühlkreis': 2058,
    'Sankt Oswald bei Freistadt': 2058,
    'Sankt Peter am Hart': 2058,
    'Sankt Ulrich bei Steyr': 2058,
    'Sankt Wolfgang im Salzkammergut': 2058,
    'Sarleinsbach': 2058,
    'Sattledt': 2058,
    'Schafwiesen': 2058,
    'Schalchen': 2058,
    'Schalchham': 2058,
    'Schardenberg': 2058,
    'Schärding': 2058,
    'Schärding Vorstadt': 2058,
    'Scharnstein': 2058,
    'Schenkenfelden': 2058,
    'Schildorn': 2058,
    'Schlatt': 2058,
    'Schlierbach': 2058,
    'Schlüsslberg': 2058,
    'Schöndorf': 2058,
    'Schönering': 2058,
    'Schörfling': 2058,
    'Schwanenstadt': 2058,
    'Schweinbach': 2058,
    'Schwertberg': 2058,
    'Seewalchen': 2058,
    'Sierning': 2058,
    'Sonnberg im Mühlkreis': 2058,
    'Spital am Pyhrn': 2058,
    'Stadl-Hausruck': 2058,
    'Stadl-Paura': 2058,
    'Stadl-Traun': 2058,
    'Steinbach am Attersee': 2058,
    'Steinerkirchen an der Traun': 2058,
    'Steinfeld': 2058,
    'Steinhaus': 2058,
    'Steyr': 2058,
    'Steyr Stadt': 2058,
    'Steyregg': 2058,
    'Strassham': 2058,
    'Taiskirchen im Innkreis': 2058,
    'Taufkirchen an der Trattnach': 2058,
    'Ternberg': 2058,
    'Thalheim bei Wels': 2058,
    'Tiefgraben': 2058,
    'Timelkam': 2058,
    'Tragwein': 2058,
    'Traun': 2058,
    'Traunkirchen': 2058,
    'Ufer': 2058,
    'Ulrichsberg': 2058,
    'Ungenach': 2058,
    'Untereisenfeld': 2058,
    'Unterjosefstal': 2058,
    'Uttendorf': 2058,
    'Utzenaich': 2058,
    'Viechtwang': 2058,
    'Vöcklabruck': 2058,
    'Vöcklamarkt': 2058,
    'Vorchdorf': 2058,
    'Wagrain': 2058,
    'Wagram': 2058,
    'Waizenkirchen': 2058,
    'Walding': 2058,
    'Waldkirchen am Wesen': 2058,
    'Waldneukirchen': 2058,
    'Waldzell': 2058,
    'Wallern an der Trattnach': 2058,
    'Wartberg an der Krems': 2058,
    'Wartberg ob der Aist': 2058,
    'Weibern': 2058,
    'Weilbach': 2058,
    'Weißenkirchen im Attergau': 2058,
    'Weisskirchen an der Traun': 2058,
    'Weitersfelden': 2058,
    'Wels': 2058,
    'Wels Stadt': 2058,
    'Wels-Land': 2058,
    'Wendling': 2058,
    'Weng im Innkreis': 2058,
    'Wernstein am Inn': 2058,
    'Weyer': 2058,
    'Weyregg': 2058,
    'Wimpassing': 2058,
    'Wimsbach': 2058,
    'Windhaag bei Freistadt': 2058,
    'Windhaag bei Perg': 2058,
    'Windischgarsten': 2058,
    'Wippenham': 2058,
    'Wispl': 2058,
    'Wolfern': 2058,
    'Wolfsegg am Hausruck': 2058,
    'Zell am Moos': 2058,
    'Zell am Pettenfirst': 2058,
    'Zell an der Pram': 2058,
    'Zwettl an der Rodl': 2058,
    'Donaustadt': 2060,
    'Favoriten': 2060,
    'Floridsdorf': 2060,
    'Hernals': 2060,
    'Hietzing': 2060,
    'Innere Stadt': 2060,
    'Meidling': 2060,
    'Ottakring': 2060,
    'Simmering': 2060,
    'Vienna': 2060,
    'Alberschwende': 2063,
    'Altach': 2063,
    'Andelsbuch': 2063,
    'Au': 2063,
    'Ausserbraz': 2063,
    'Bartholomäberg': 2063,
    'Batschuns': 2063,
    'Bezau': 2063,
    'Bildstein': 2063,
    'Bizau': 2063,
    'Blons': 2063,
    'Bludenz': 2063,
    'Bludesch': 2063,
    'Brand': 2063,
    'Bregenz': 2063,
    'Bürs': 2063,
    'Bürserberg': 2063,
    'Dalaas': 2063,
    'Damüls': 2063,
    'Doren': 2063,
    'Dornbirn': 2063,
    'Düns': 2063,
    'Dünserberg': 2063,
    'Egg': 2063,
    'Eichenberg': 2063,
    'Feldkirch': 2063,
    'Frastanz': 2063,
    'Fraxern': 2063,
    'Fußach': 2063,
    'Gaißau': 2063,
    'Gaschurn': 2063,
    'Göfis': 2063,
    'Götzis': 2063,
    'Großdorf': 2063,
    'Hard': 2063,
    'Hirschegg': 2063,
    'Hittisau': 2063,
    'Höchst': 2063,
    'Hohenems': 2063,
    'Hohenweiler': 2063,
    'Hörbranz': 2063,
    'Innerbraz': 2063,
    'Kennelbach': 2063,
    'Klaus': 2063,
    'Klösterle': 2063,
    'Koblach': 2063,
    'Krumbach': 2063,
    'Langen': 2063,
    'Langenegg': 2063,
    'Lauterach': 2063,
    'Lech': 2063,
    'Lingenau': 2063,
    'Lochau': 2063,
    'Lorüns': 2063,
    'Ludesch': 2063,
    'Lustenau': 2063,
    'Mäder': 2063,
    'Meiningen': 2063,
    'Mellau': 2063,
    'Mittelberg': 2063,
    'Möggers': 2063,
    'Muntlix': 2063,
    'Nenzing': 2063,
    'Nüziders': 2063,
    'Politischer Bezirk Bludenz': 2063,
    'Politischer Bezirk Bregenz': 2063,
    'Politischer Bezirk Dornbirn': 2063,
    'Politischer Bezirk Feldkirch': 2063,
    'Raggal': 2063,
    'Rankweil': 2063,
    'Reuthe': 2063,
    'Riefensberg': 2063,
    'Riezlern': 2063,
    'Röns': 2063,
    'Röthis': 2063,
    'Sankt Anton im Montafon': 2063,
    'Sankt Gallenkirch': 2063,
    'Satteins': 2063,
    'Schlins': 2063,
    'Schnepfau': 2063,
    'Schnifis': 2063,
    'Schoppernau': 2063,
    'Schröcken': 2063,
    'Schruns': 2063,
    'Schwarzach': 2063,
    'Schwarzenberg': 2063,
    'Sibratsgfäll': 2063,
    'Silbertal': 2063,
    'Stallehr': 2063,
    'Sulz': 2063,
    'Sulzberg': 2063,
    'Thüringen': 2063,
    'Tschagguns': 2063,
    'Übersaxen': 2063,
    'Vandans': 2063,
    'Viktorsberg': 2063,
    'Warth': 2063,
    'Weiler': 2063,
    'Wolfurt': 2063,
    'Ceyranbatan': 540,
    'Digah': 540,
    'Gyuzdek': 540,
    'Khirdalan': 540,
    'Qobu': 540,
    'Saray': 540,
    'Ağdam': 559,
    'Ağdaş': 553,
    'Agdzhabedy': 577,
    'Avşar': 577,
    'Aghstafa': 543,
    'Saloğlu': 543,
    'Vurğun': 543,
    'Aghsu': 547,
    'Astara': 528,
    'Kizhaba': 528,
    'Amirdzhan': 552,
    'Badamdar': 552,
    'Bakıxanov': 552,
    'Baku': 552,
    'Balakhani': 552,
    'Bilajari': 552,
    'Bilajer': 552,
    'Binagadi': 552,
    'Biny Selo': 552,
    'Buzovna': 552,
    'Hövsan': 552,
    'Khodzhi-Gasan': 552,
    'Korgöz': 552,
    'Lökbatan': 552,
    'Mardakan': 552,
    'Maştağa': 552,
    'Nardaran': 552,
    'Nizami Rayonu': 552,
    'Pirallahı': 552,
    'Puta': 552,
    'Qala': 552,
    'Qaraçuxur': 552,
    'Qobustan': 552,
    'Ramana': 552,
    'Sabunçu': 552,
    'Sanqaçal': 552,
    'Türkan': 552,
    'Yeni Suraxanı': 552,
    'Zabrat': 552,
    'Zyrya': 552,
    'Belokany': 560,
    'Qabaqçöl': 560,
    'Barda': 569,
    'Samuxlu': 569,
    'Beylagan': 554,
    'Birinci Aşıqlı': 554,
    'Dünyamalılar': 554,
    'Orjonikidze': 554,
    'Yuxarı Aran': 554,
    'Pushkino': 532,
    'Verkhniy Dashkesan': 561,
    'Yukhary-Dashkesan': 561,
    'Fizuli': 527,
    'Horadiz': 527,
    'Ganja': 585,
    'Arıqdam': 589,
    'Arıqıran': 589,
    'Böyük Qaramurad': 589,
    'Kyadabek': 589,
    'Novosaratovka': 589,
    'Qobustan': 573,
    'Goranboy': 551,
    'Qızılhacılı': 551,
    'Geoktschai': 531,
    'Yelenendorf': 574,
    'Hacıqabul': 571,
    'Mughan': 571,
    'Imishli': 544,
    'Basqal': 564,
    'İsmayıllı': 564,
    'Jebrail': 570,
    'Jalilabad': 578,
    'Prishibinskoye': 578,
    'Kerbakhiar': 525,
    'Vank': 525,
    'Xaçmaz': 590,
    'Xudat': 590,
    'Altıağac': 537,
    'Khyzy': 537,
    'Kilyazi': 537,
    'Şuraabad': 537,
    'Askyaran': 524,
    'Xocalı': 524,
    'Kyurdarmir': 549,
    'Laçın': 541,
    'Haftoni': 558,
    'Lankaran': 558,
    'Lerik': 546,
    'Hadrut': 568,
    'Novyy Karanlug': 568,
    'Qırmızı Bazar': 568,
    'Boradigah': 555,
    'Masally': 555,
    'Mingelchaur': 580,
    'Cahri': 562,
    'Çalxanqala': 562,
    'Culfa': 562,
    'Deste': 562,
    'Heydarabad': 562,
    'Julfa Rayon': 562,
    'Nakhchivan': 562,
    'Oğlanqala': 562,
    'Ordubad': 562,
    'Ordubad Rayon': 562,
    'Qıvraq': 562,
    'Şahbuz': 562,
    'Sedarak': 562,
    'Shahbuz Rayon': 562,
    'Sharur City': 562,
    'Sumbatan-diza': 562,
    'Tazakend': 562,
    'Yaycı': 562,
    'Neftçala': 530,
    'Severo-Vostotchnyi Bank': 530,
    'Sovetabad': 530,
    'Xıllı': 530,
    'Oğuz': 556,
    'Qutqashen': 542,
    'Çinarlı': 526,
    'Qax': 526,
    'Qax İngiloy': 526,
    'Qaxbaş': 526,
    'Qazax': 521,
    'Hacıhüseynli': 563,
    'Quba': 563,
    'Qubadlı': 548,
    'Qusar': 588,
    'Samur': 588,
    'Əhmədbəyli': 557,
    'Saatlı': 557,
    'Sabirabad': 565,
    'Qaraçala': 545,
    'Salyan': 545,
    'Qarayeri': 536,
    'Qırmızı Samux': 536,
    'Samux': 536,
    'Divichibazar': 591,
    'Sheki': 518,
    'Baş Göynük': 586,
    'Shamakhi': 529,
    'Dolyar': 583,
    'Dzagam': 583,
    'Qasım İsmayılov': 583,
    'Shamkhor': 583,
    'Şirvan': 520,
    'Shushi': 592,
    'Gilgilçay': 584,
    'Kyzyl-Burun': 584,
    'Corat': 582,
    'Hacı Zeynalabdin': 582,
    'Sumqayıt': 582,
    'Martakert': 519,
    'Terter': 519,
    'Çatax': 533,
    'Çobansığnaq': 533,
    'Dondar Quşçu': 533,
    'Qaraxanlı': 533,
    'Tovuz': 533,
    'Yanıqlı': 533,
    'Ujar': 539,
    'Yardımlı': 550,
    'Yevlakh': 538,
    'Aran': 523,
    'Qaramanlı': 523,
    'Mincivan': 581,
    'Zangilan': 581,
    'Aliabad': 566,
    'Faldarlı': 566,
    'Mamrux': 566,
    'Qandax': 566,
    'Zaqatala': 566,
    'Zardob': 576,
    'Alice Town': 3629,
    'Arthur’s Town': 3611,
    'Marsh Harbour': 3603,
    'Colonel Hill': 3621,
    'High Rock': 3614,
    'George Town': 3612,
    'Freeport': 3626,
    'Lucaya': 3626,
    'Dunmore Town': 3613,
    'Matthew Town': 3609,
    'Clarence Town': 3610,
    'Abraham’s Bay': 3633,
    'Nassau': 4881,
    'Cooper’s Town': 3616,
    'Andros Town': 3617,
    'San Andros': 3617,
    'Duncan Town': 3615,
    'Port Nelson': 3600,
    'Cockburn Town': 3627,
    'Spanish Wells': 3630,
    'West End': 3599,
    'Jidd Ḩafş': 1992,
    'Manama': 1992,
    'Sitrah': 1992,
    'Madīnat Ḩamad': 1996,
    'Al Ḩadd': 1995,
    'Al Muharraq': 1995,
    'Ar Rifā‘': 1993,
    'Dār Kulayb': 1993,
    'Madīnat ‘Īsá': 1993,
    'Barguna': 818,
    'Barisal': 818,
    'Bhāndāria': 818,
    'Bhola': 818,
    'Burhānuddin': 818,
    'Gaurnadi': 818,
    'Jhalokati': 818,
    'Lālmohan': 818,
    'Mathba': 818,
    'Mehendiganj': 818,
    'Nālchiti': 818,
    'Patuakhali': 818,
    'Pirojpur': 818,
    'Bandarban': 803,
    'Bibir Hat': 803,
    'Brahmanbaria': 803,
    'Chandpur': 803,
    'Chhāgalnāiya': 803,
    'Chittagong': 803,
    'Comilla': 803,
    'Cox  s Bazar': 803,
    'Cox’s Bāzār': 803,
    'Feni': 803,
    'Hājīganj': 803,
    'Khagrachhari': 803,
    'Lākshām': 803,
    'Lakshmipur': 803,
    'Manikchari': 803,
    'Nabīnagar': 803,
    'Noakhali': 803,
    'Patiya': 803,
    'Rāipur': 803,
    'Rāmganj': 803,
    'Rangamati': 803,
    'Raojān': 803,
    'Sandwīp': 803,
    'Sātkania': 803,
    'Teknāf': 803,
    'Azimpur': 771,
    'Bājitpur': 771,
    'Bhairab Bāzār': 771,
    'Char Bhadrāsan': 771,
    'Char Golora': 771,
    'Dhaka': 771,
    'Dohār': 771,
    'Faridpur': 771,
    'Gazipur': 771,
    'Gopalganj': 771,
    'Joymontop': 771,
    'Khanbaniara': 771,
    'Kishoregonj': 771,
    'Kishorganj': 771,
    'Madaripur': 771,
    'Manikganj': 771,
    'Mirzāpur': 771,
    'Munshiganj': 771,
    'Nāgarpur': 771,
    'Narayanganj': 771,
    'Narsingdi': 771,
    'Pālang': 771,
    'Paltan': 771,
    'Parvez Ali': 771,
    'Parvez Ali Hossain': 771,
    'Rajbari': 771,
    'Ramnagar': 771,
    'Sakhipur': 771,
    'Sayani': 771,
    'Shariatpur': 771,
    'Sonārgaon': 771,
    'Tangail': 771,
    'Tungi': 771,
    'Tungipāra': 771,
    'Bagerhat': 811,
    'Bhātpāra Abhaynagar': 811,
    'Bherāmāra': 811,
    'Chuadanga': 811,
    'Jessore': 811,
    'Jhenaidah': 811,
    'Jhingergācha': 811,
    'Kālia': 811,
    'Kālīganj': 811,
    'Kesabpur': 811,
    'Khulna': 811,
    'Kushtia': 811,
    'Magura': 811,
    'Meherpur': 811,
    'Morrelgonj': 811,
    'Narail': 811,
    'Nowlamary': 811,
    'Phultala': 811,
    'Sarankhola': 811,
    'Satkhira': 811,
    'Ujalpur': 811,
    'Uttar Char Fasson': 811,
    'Gafargaon': 766,
    'Jamalpur': 766,
    'Muktāgācha': 766,
    'Mymensingh': 766,
    'Netrakona': 766,
    'Sarishābāri': 766,
    'Sherpur': 766,
    'Bera': 813,
    'Bogra': 813,
    'Chapai Nababganj': 813,
    'Ishurdi': 813,
    'Joypur Hāt': 813,
    'Joypurhat': 813,
    'Mahasthangarh': 813,
    'Naogaon': 813,
    'Natore': 813,
    'Nawābganj': 813,
    'Pabna': 813,
    'Pār Naogaon': 813,
    'Puthia': 813,
    'Rajshahi': 813,
    'Saidpur': 813,
    'Shāhzādpur': 813,
    'Shibganj': 813,
    'Sirajganj': 813,
    'Badarganj': 759,
    'Chilmāri': 759,
    'Dinajpur': 759,
    'Gaibandha': 759,
    'Kurigram': 759,
    'Lalmonirhat': 759,
    'Lalmonirhat District': 759,
    'Nageswari': 759,
    'Nilphamari Zila': 759,
    'Panchagarh': 759,
    'Parbatipur': 759,
    'Pīrgaaj': 759,
    'Rangpur': 759,
    'Thakurgaon': 759,
    'Baniachang': 767,
    'Chhātak': 767,
    'Habiganj': 767,
    'Jahedpur': 767,
    'Maulavi Bāzār': 767,
    'Maulvibazar': 767,
    'Sunamganj': 767,
    'Sylhet': 767,
    'Oistins': 1228,
    'Greenland': 1229,
    'Holetown': 1224,
    'Bathsheba': 1223,
    'Bridgetown': 1230,
    'Speightstown': 1222,
    'Crane': 1220,
    'Antopal’': 2959,
    'Asnyezhytsy': 2959,
    'Baranovichi': 2959,
    'Baranovichskiy Rayon': 2959,
    'Brest': 2959,
    'Brestski Rayon': 2959,
    'Byaroza': 2959,
    'Byarozawski Rayon': 2959,
    'Byelaazyorsk': 2959,
    'Charnawchytsy': 2959,
    'Damachava': 2959,
    'Davyd-Haradok': 2959,
    'Drahichyn': 2959,
    'Drahichynski Rayon': 2959,
    'Hantsavichy': 2959,
    'Hantsavitski Rayon': 2959,
    'Haradzishcha': 2959,
    'Horad Baranavichy': 2959,
    'Horad Brest': 2959,
    'Ivanava': 2959,
    'Ivanawski Rayon': 2959,
    'Ivatsevichy': 2959,
    'Kamyanyets': 2959,
    'Kamyanyetski Rayon': 2959,
    'Kamyanyuki': 2959,
    'Kobryn': 2959,
    'Kosava': 2959,
    'Lahishyn': 2959,
    'Luninyets': 2959,
    'Lyakhavichy': 2959,
    'Malaryta': 2959,
    'Mikashevichy': 2959,
    'Motal’': 2959,
    'Nyakhachava': 2959,
    'Pinsk': 2959,
    'Pruzhanski Rayon': 2959,
    'Pruzhany': 2959,
    'Ruzhany': 2959,
    'Stolin': 2959,
    'Stolinski Rayon': 2959,
    'Tsyelyakhany': 2959,
    'Vysokaye': 2959,
    'Zhabinka': 2959,
    'Zhabinkawski Rayon': 2959,
    'Znamenka': 2959,
    'Aktsyabrski': 2955,
    'Brahin': 2955,
    'Brahinski Rayon': 2955,
    'Buda-Kashalyova': 2955,
    'Chachersk': 2955,
    'Chacherski Rayon': 2955,
    'Dobrush': 2955,
    'Dowsk': 2955,
    'Homyel  ': 2955,
    'Homyel’ski Rayon': 2955,
    'Kalinkavichy': 2955,
    'Karanyowka': 2955,
    'Karma': 2955,
    'Kastsyukowka': 2955,
    'Khal’ch': 2955,
    'Khoyniki': 2955,
    'Loyew': 2955,
    'Lyel’chytski Rayon': 2955,
    'Lyel’chytsy': 2955,
    'Mazyr': 2955,
    'Mazyrski Rayon': 2955,
    'Narowlya': 2955,
    'Novaya Huta': 2955,
    'Parychy': 2955,
    'Peramoga': 2955,
    'Pyetrykaw': 2955,
    'Rahachow': 2955,
    'Rahachowski Rayon': 2955,
    'Rechytsa': 2955,
    'Sasnovy Bor': 2955,
    'Svyetlahorsk': 2955,
    'Turaw': 2955,
    'Vasilyevichy': 2955,
    'Vyetka': 2955,
    'Vyetkawski Rayon': 2955,
    'Yel’sk': 2955,
    'Zhlobin': 2955,
    'Zhlobinski Rayon': 2955,
    'Zhytkavichy': 2955,
    'Ashmyanski Rayon': 2956,
    'Ashmyany': 2956,
    'Astravyets': 2956,
    'Astravyetski Rayon': 2956,
    'Baruny': 2956,
    'Byarozawka': 2956,
    'Dyatlovo': 2956,
    'Grodnenskiy Rayon': 2956,
    'Hal’shany': 2956,
    'Horad Hrodna': 2956,
    'Hrodna': 2956,
    'Hyeranyony': 2956,
    'Indura': 2956,
    'Iwye': 2956,
    'Karelichy': 2956,
    'Karelitski Rayon': 2956,
    'Krasnosel’skiy': 2956,
    'Kreva': 2956,
    'Lida': 2956,
    'Lidski Rayon': 2956,
    'Lyubcha': 2956,
    'Mir': 2956,
    'Mosty': 2956,
    'Novogrudok': 2956,
    'Ross’': 2956,
    'Sapotskin': 2956,
    'Shchuchyn': 2956,
    'Shchuchynski Rayon': 2956,
    'Skidel’': 2956,
    'Slonim': 2956,
    'Smarhon’': 2956,
    'Soly': 2956,
    'Svislach': 2956,
    'Vishnyeva': 2956,
    'Volkovysk': 2956,
    'Voranava': 2956,
    'Vyalikaya Byerastavitsa': 2956,
    'Zel’va': 2956,
    'Zhaludok': 2956,
    'Zhirovichi': 2956,
    'Frunzyenski Rayon': 2958,
    'Kastrychnitski Rayon': 2958,
    'Lyeninski Rayon': 2958,
    'Maskowski Rayon': 2958,
    'Minsk': 2958,
    'Partyzanski Rayon': 2958,
    'Savyetski Rayon': 2958,
    'Tsentral’ny Rayon': 2958,
    'Zavodski Rayon': 2958,
    'Astrashytski Haradok': 2957,
    'Atolina': 2957,
    'Azyartso': 2957,
    'Bal’shavik': 2957,
    'Barysaw': 2957,
    'Barysawski Rayon': 2957,
    'Blon’': 2957,
    'Bobr': 2957,
    'Borovlyany': 2957,
    'Byarezinski Rayon': 2957,
    'Byerazino': 2957,
    'Chervyen’': 2957,
    'Chervyen’ski Rayon': 2957,
    'Chyrvonaya Slabada': 2957,
    'Chyst’': 2957,
    'Druzhny': 2957,
    'Dukora': 2957,
    'Dzyarzhynsk': 2957,
    'Enyerhyetykaw': 2957,
    'Fanipol': 2957,
    'Haradzyeya': 2957,
    'Hatava': 2957,
    'Horad Smalyavichy': 2957,
    'Horad Zhodzina': 2957,
    'Hotsk': 2957,
    'Il’ya': 2957,
    'Ivyanyets': 2957,
    'Kalodzishchy': 2957,
    'Kapyl’': 2957,
    'Kapyl’ski Rayon': 2957,
    'Khatsyezhyna': 2957,
    'Kholopenichi': 2957,
    'Klyetsk': 2957,
    'Klyetski Rayon': 2957,
    'Korolëv Stan': 2957,
    'Krasnaye': 2957,
    'Krupki': 2957,
    'Kryvichy': 2957,
    'Lahoysk': 2957,
    'Loshnitsa': 2957,
    'Luhavaya Slabada': 2957,
    'Lyasny': 2957,
    'Lyeskawka': 2957,
    'Lyuban’': 2957,
    'Machulishchy': 2957,
    'Maladzyechna': 2957,
    'Mar’’ina Horka': 2957,
    'Michanovichi': 2957,
    'Myadzyel': 2957,
    'Myadzyel’ski Rayon': 2957,
    'Narach': 2957,
    'Nasilava': 2957,
    'Novosel’ye': 2957,
    'Novy Svyerzhan’': 2957,
    'Nyasvizh': 2957,
    'Nyasvizhski Rayon': 2957,
    'Plyeshchanitsy': 2957,
    'Prawdzinski': 2957,
    'Pryvol’ny': 2957,
    'Pukhavichy': 2957,
    'Pukhavichy Raion': 2957,
    'Pyatryshki': 2957,
    'Radashkovichy': 2957,
    'Rakaw': 2957,
    'Rudzyensk': 2957,
    'Salihorsk': 2957,
    'Samakhvalavichy': 2957,
    'Sarachy': 2957,
    'Schomyslitsa': 2957,
    'Slabada': 2957,
    'Slutsk': 2957,
    'Slutski Rayon': 2957,
    'Smalyavitski Rayon': 2957,
    'Smilavichy': 2957,
    'Snow': 2957,
    'Stan’kava': 2957,
    'Staradarozhski Rayon': 2957,
    'Starobin': 2957,
    'Staryya Darohi': 2957,
    'Stawbtsowski Rayon': 2957,
    'Stowbtsy': 2957,
    'Svir': 2957,
    'Svislach': 2957,
    'Syenitsa': 2957,
    'Syomkava': 2957,
    'Tsimkavichy': 2957,
    'Turets-Bayary': 2957,
    'Urechcha': 2957,
    'Usiazh': 2957,
    'Uzda': 2957,
    'Uzdzyenski Rayon': 2957,
    'Valozhyn': 2957,
    'Vilyeyka': 2957,
    'Vilyeyski Rayon': 2957,
    'Vyaliki Trastsyanets': 2957,
    'Yubilyeyny': 2957,
    'Zamostochye': 2957,
    'Zaslawye': 2957,
    'Zhdanovichy': 2957,
    'Zyembin': 2957,
    'Октябрьский': 2957,
    'Asipovichy': 2954,
    'Asipovitski Rayon': 2954,
    'Babruysk': 2954,
    'Babruyski Rayon': 2954,
    'Buynichy': 2954,
    'Byalynichy': 2954,
    'Byalynitski Rayon': 2954,
    'Bykhaw': 2954,
    'Chavuski Rayon': 2954,
    'Chavusy': 2954,
    'Cherykaw': 2954,
    'Cherykawski Rayon': 2954,
    'Dashkawka': 2954,
    'Drybin': 2954,
    'Drybinski Rayon': 2954,
    'Hlusha': 2954,
    'Hlusk': 2954,
    'Horatski Rayon': 2954,
    'Horki': 2954,
    'Kadino': 2954,
    'Kamyennyya Lavy': 2954,
    'Kastsyukovichy': 2954,
    'Khodasy': 2954,
    'Khotsimsk': 2954,
    'Khotsimski Rayon': 2954,
    'Kirawsk': 2954,
    'Klichaw': 2954,
    'Klimavichy': 2954,
    'Krasnapol’ski Rayon': 2954,
    'Krasnapollye': 2954,
    'Krasnyy Bereg': 2954,
    'Kruhlaye': 2954,
    'Krychaw': 2954,
    'Mahilyow': 2954,
    'Mahilyowski Rayon': 2954,
    'Mstsislaw': 2954,
    'Myazhysyatki': 2954,
    'Myshkavichy': 2954,
    'Palykavichy Pyershyya': 2954,
    'Posëlok Voskhod': 2954,
    'Ramanavichy': 2954,
    'Shklow': 2954,
    'Shklowski Rayon': 2954,
    'Slawharad': 2954,
    'Veyno': 2954,
    'Vishow': 2954,
    'Yalizava': 2954,
    'Balbasava': 2960,
    'Baran’': 2960,
    'Braslaw': 2960,
    'Braslawski Rayon': 2960,
    'Byahoml’': 2960,
    'Byeshankovitski Rayon': 2960,
    'Chashniki': 2960,
    'Chashnitski Rayon': 2960,
    'Dokshytski Rayon': 2960,
    'Dokshytsy': 2960,
    'Druya': 2960,
    'Dubrowna': 2960,
    'Dzisna': 2960,
    'Haradok': 2960,
    'Haradotski Rayon': 2960,
    'Hlybokaye': 2960,
    'Hlybotski Rayon': 2960,
    'Kokhanava': 2960,
    'Konstantinovo': 2960,
    'Lyepyel’': 2960,
    'Lyepyel’ski Rayon': 2960,
    'Lyntupy': 2960,
    'Lyozna': 2960,
    'Lyoznyenski Rayon': 2960,
    'Mosar': 2960,
    'Myorski Rayon': 2960,
    'Myory': 2960,
    'Navapolatsk': 2960,
    'Novolukoml’': 2960,
    'Orsha': 2960,
    'Osveya': 2960,
    'Pastavy': 2960,
    'Pastawski Rayon': 2960,
    'Polatsk': 2960,
    'Polatski Rayon': 2960,
    'Rasonski Rayon': 2960,
    'Rasony': 2960,
    'Sharkawshchyna': 2960,
    'Sharkawshchynski Rayon': 2960,
    'Shumilinski Rayon': 2960,
    'Syanno': 2960,
    'Syennyenski Rayon': 2960,
    'Talachyn': 2960,
    'Ushachy': 2960,
    'Vidzy': 2960,
    'Vitebsk': 2960,
    'Vyerkhnyadzvinsk': 2960,
    'Vyerkhnyadzvinski Rayon': 2960,
    'Brussels': 1376,
    'Aalst': 1373,
    'Aalter': 1373,
    'Aarschot': 1373,
    'Aartselaar': 1373,
    'Alken': 1373,
    'Alveringem': 1373,
    'Antwerpen': 1373,
    'Anzegem': 1373,
    'Ardooie': 1373,
    'Arendonk': 1373,
    'As': 1373,
    'Asse': 1373,
    'Assenede': 1373,
    'Avelgem': 1373,
    'Baarle-Hertog': 1373,
    'Balen': 1373,
    'Beernem': 1373,
    'Beerse': 1373,
    'Beersel': 1373,
    'Begijnendijk': 1373,
    'Bekkevoort': 1373,
    'Beringen': 1373,
    'Berlaar': 1373,
    'Berlare': 1373,
    'Bertem': 1373,
    'Bever': 1373,
    'Beveren': 1373,
    'Bierbeek': 1373,
    'Bilzen': 1373,
    'Blankenberge': 1373,
    'Bocholt': 1373,
    'Boechout': 1373,
    'Bonheiden': 1373,
    'Boom': 1373,
    'Boortmeerbeek': 1373,
    'Borgloon': 1373,
    'Bornem': 1373,
    'Borsbeek': 1373,
    'Boutersem': 1373,
    'Brasschaat': 1373,
    'Brecht': 1373,
    'Bredene': 1373,
    'Bree': 1373,
    'Brugge': 1373,
    'Buggenhout': 1373,
    'Damme': 1373,
    'De Haan': 1373,
    'De Panne': 1373,
    'De Pinte': 1373,
    'Deerlijk': 1373,
    'Deinze': 1373,
    'Denderleeuw': 1373,
    'Dendermonde': 1373,
    'Dentergem': 1373,
    'Dessel': 1373,
    'Destelbergen': 1373,
    'Deurne': 1373,
    'Diegem': 1373,
    'Diepenbeek': 1373,
    'Diest': 1373,
    'Diksmuide': 1373,
    'Dilbeek': 1373,
    'Drogenbos': 1373,
    'Duffel': 1373,
    'Edegem': 1373,
    'Eeklo': 1373,
    'Essen': 1373,
    'Evergem': 1373,
    'Galmaarden': 1373,
    'Gavere': 1373,
    'Geel': 1373,
    'Geetbets': 1373,
    'Genk': 1373,
    'Gent': 1373,
    'Geraardsbergen': 1373,
    'Gingelom': 1373,
    'Gistel': 1373,
    'Glabbeek': 1373,
    'Gooik': 1373,
    'Grimbergen': 1373,
    'Grobbendonk': 1373,
    'Haacht': 1373,
    'Haaltert': 1373,
    'Halen': 1373,
    'Halle': 1373,
    'Hamme': 1373,
    'Harelbeke': 1373,
    'Hasselt': 1373,
    'Heers': 1373,
    'Heist-op-den-Berg': 1373,
    'Helchteren': 1373,
    'Hemiksem': 1373,
    'Herent': 1373,
    'Herentals': 1373,
    'Herenthout': 1373,
    'Herk-de-Stad': 1373,
    'Herne': 1373,
    'Herselt': 1373,
    'Herzele': 1373,
    'Heusden': 1373,
    'Hoboken': 1373,
    'Hoegaarden': 1373,
    'Hoeilaart': 1373,
    'Hoeselt': 1373,
    'Holsbeek': 1373,
    'Hooglede': 1373,
    'Hoogstraten': 1373,
    'Houthalen': 1373,
    'Houthulst': 1373,
    'Hove': 1373,
    'Huldenberg': 1373,
    'Hulshout': 1373,
    'Ichtegem': 1373,
    'Ieper': 1373,
    'Ingelmunster': 1373,
    'Izegem': 1373,
    'Jabbeke': 1373,
    'Kalmthout': 1373,
    'Kampenhout': 1373,
    'Kapelle-op-den-Bos': 1373,
    'Kapellen': 1373,
    'Kaprijke': 1373,
    'Kasterlee': 1373,
    'Keerbergen': 1373,
    'Kinrooi': 1373,
    'Knesselare': 1373,
    'Knokke-Heist': 1373,
    'Koekelare': 1373,
    'Koksijde': 1373,
    'Kontich': 1373,
    'Kortemark': 1373,
    'Kortenaken': 1373,
    'Kortenberg': 1373,
    'Kortessem': 1373,
    'Kortrijk': 1373,
    'Kraainem': 1373,
    'Kruibeke': 1373,
    'Kruishoutem': 1373,
    'Kuurne': 1373,
    'Laarne': 1373,
    'Lanaken': 1373,
    'Landen': 1373,
    'Lebbeke': 1373,
    'Lede': 1373,
    'Ledeberg': 1373,
    'Ledegem': 1373,
    'Lendelede': 1373,
    'Leopoldsburg': 1373,
    'Leuven': 1373,
    'Lichtervelde': 1373,
    'Liedekerke': 1373,
    'Lier': 1373,
    'Lille': 1373,
    'Linkebeek': 1373,
    'Lint': 1373,
    'Lochristi': 1373,
    'Lokeren': 1373,
    'Lommel': 1373,
    'Londerzeel': 1373,
    'Lovendegem': 1373,
    'Lubbeek': 1373,
    'Lummen': 1373,
    'Maaseik': 1373,
    'Maasmechelen': 1373,
    'Machelen': 1373,
    'Maldegem': 1373,
    'Mechelen': 1373,
    'Meerhout': 1373,
    'Meise': 1373,
    'Melle': 1373,
    'Menen': 1373,
    'Merchtem': 1373,
    'Merelbeke': 1373,
    'Merksplas': 1373,
    'Meulebeke': 1373,
    'Middelkerke': 1373,
    'Moerbeke': 1373,
    'Mol': 1373,
    'Moorslede': 1373,
    'Mortsel': 1373,
    'Nazareth': 1373,
    'Neerpelt': 1373,
    'Nevele': 1373,
    'Niel': 1373,
    'Nieuwerkerken': 1373,
    'Nieuwpoort': 1373,
    'Nijlen': 1373,
    'Ninove': 1373,
    'Olen': 1373,
    'Oostduinkerke': 1373,
    'Oosterzele': 1373,
    'Oostkamp': 1373,
    'Oostmalle': 1373,
    'Oostrozebeke': 1373,
    'Opglabbeek': 1373,
    'Opwijk': 1373,
    'Ostend': 1373,
    'Oud-Heverlee': 1373,
    'Oud-Turnhout': 1373,
    'Oudenaarde': 1373,
    'Oudenburg': 1373,
    'Overijse': 1373,
    'Overpelt': 1373,
    'Peer': 1373,
    'Pepingen': 1373,
    'Perre': 1373,
    'Pittem': 1373,
    'Poperinge': 1373,
    'Provincie Antwerpen': 1373,
    'Provincie Limburg': 1373,
    'Provincie Oost-Vlaanderen': 1373,
    'Provincie Vlaams-Brabant': 1373,
    'Provincie West-Vlaanderen': 1373,
    'Putte': 1373,
    'Puurs': 1373,
    'Ranst': 1373,
    'Ravels': 1373,
    'Retie': 1373,
    'Riemst': 1373,
    'Rijkevorsel': 1373,
    'Roeselare': 1373,
    'Ronse': 1373,
    'Rotselaar': 1373,
    'Ruiselede': 1373,
    'Rumst': 1373,
    'Schelle': 1373,
    'Schilde': 1373,
    'Schoten': 1373,
    'Sint-Amands': 1373,
    'Sint-Genesius-Rode': 1373,
    'Sint-Gillis-Waas': 1373,
    'Sint-Joris': 1373,
    'Sint-Katelijne-Waver': 1373,
    'Sint-Kruis': 1373,
    'Sint-Laureins': 1373,
    'Sint-Lievens-Houtem': 1373,
    'Sint-Maria-Lierde': 1373,
    'Sint-Martens-Latem': 1373,
    'Sint-Martens-Lennik': 1373,
    'Sint-Niklaas': 1373,
    'Sint-Pieters-Leeuw': 1373,
    'Sint-Pieters-Voeren': 1373,
    'Sint-Truiden': 1373,
    'Stabroek': 1373,
    'Staden': 1373,
    'Steenokkerzeel': 1373,
    'Stekene': 1373,
    'Temse': 1373,
    'Terkoest': 1373,
    'Ternat': 1373,
    'Tervuren': 1373,
    'Tessenderlo': 1373,
    'Tielt': 1373,
    'Tienen': 1373,
    'Tongeren': 1373,
    'Torhout': 1373,
    'Tremelo': 1373,
    'Turnhout': 1373,
    'Veurne': 1373,
    'Vilvoorde': 1373,
    'Vorselaar': 1373,
    'Vosselaar': 1373,
    'Waarschoot': 1373,
    'Waasmunster': 1373,
    'Wachtebeke': 1373,
    'Waregem': 1373,
    'Wellen': 1373,
    'Wemmel': 1373,
    'Wenduine': 1373,
    'Wervik': 1373,
    'Westerlo': 1373,
    'Wetteren': 1373,
    'Wevelgem': 1373,
    'Wezembeek-Oppem': 1373,
    'Wichelen': 1373,
    'Wielsbeke': 1373,
    'Wijnegem': 1373,
    'Willebroek': 1373,
    'Wingene': 1373,
    'Wommelgem': 1373,
    'Wuustwezel': 1373,
    'Zandhoven': 1373,
    'Zaventem': 1373,
    'Zedelgem': 1373,
    'Zeebrugge': 1373,
    'Zele': 1373,
    'Zelzate': 1373,
    'Zemst': 1373,
    'Zingem': 1373,
    'Zoersel': 1373,
    'Zomergem': 1373,
    'Zonhoven': 1373,
    'Zonnebeke': 1373,
    'Zottegem': 1373,
    'Zoutleeuw': 1373,
    'Zuienkerke': 1373,
    'Zulte': 1373,
    'Zutendaal': 1373,
    'Zwevegem': 1373,
    'Zwijndrecht': 1373,
    'Aiseau': 1380,
    'Amay': 1380,
    'Amblève': 1380,
    'Andenne': 1380,
    'Anderlues': 1380,
    'Anhée': 1380,
    'Ans': 1380,
    'Anthisnes': 1380,
    'Antoing': 1380,
    'Arlon': 1380,
    'Assesse': 1380,
    'Ath': 1380,
    'Attert': 1380,
    'Aubange': 1380,
    'Aubel': 1380,
    'Awans': 1380,
    'Aywaille': 1380,
    'Baelen': 1380,
    'Basse Lasne': 1380,
    'Bassenge': 1380,
    'Bastogne': 1380,
    'Beaumont': 1380,
    'Beauraing': 1380,
    'Beauvechain': 1380,
    'Beloeil': 1380,
    'Berloz': 1380,
    'Bernissart': 1380,
    'Bertogne': 1380,
    'Bertrix': 1380,
    'Beyne-Heusay': 1380,
    'Bièvre': 1380,
    'Binche': 1380,
    'Blégny': 1380,
    'Bouillon': 1380,
    'Boussu': 1380,
    'Braine-l  Alleud': 1380,
    'Braine-le-Château': 1380,
    'Braine-le-Comte': 1380,
    'Braives': 1380,
    'Brugelette': 1380,
    'Brunehault': 1380,
    'Bullange': 1380,
    'Burdinne': 1380,
    'Butgenbach': 1380,
    'Celles': 1380,
    'Cerfontaine': 1380,
    'Chapelle-lez-Herlaimont': 1380,
    'Charleroi': 1380,
    'Chastre-Villeroux-Blanmont': 1380,
    'Châtelet': 1380,
    'Chaudfontaine': 1380,
    'Chaumont-Gistoux': 1380,
    'Chièvres': 1380,
    'Chimay': 1380,
    'Chiny': 1380,
    'Ciney': 1380,
    'Clavier': 1380,
    'Colfontaine': 1380,
    'Comblain-au-Pont': 1380,
    'Courcelles': 1380,
    'Court-Saint-Étienne': 1380,
    'Couvin': 1380,
    'Crisnée': 1380,
    'Dalhem': 1380,
    'Daverdisse': 1380,
    'Dinant': 1380,
    'Dison': 1380,
    'Doische': 1380,
    'Donceel': 1380,
    'Dour': 1380,
    'Durbuy': 1380,
    'Écaussinnes-d’Enghien': 1380,
    'Éghezée': 1380,
    'Ellezelles': 1380,
    'Enghien': 1380,
    'Engis': 1380,
    'Érezée': 1380,
    'Erquelinnes': 1380,
    'Esneux': 1380,
    'Estaimpuis': 1380,
    'Estinnes-au-Val': 1380,
    'Étalle': 1380,
    'Eupen': 1380,
    'Faimes': 1380,
    'Farciennes': 1380,
    'Fauvillers': 1380,
    'Ferrières': 1380,
    'Fexhe-le-Haut-Clocher': 1380,
    'Flémalle-Haute': 1380,
    'Fléron': 1380,
    'Fleurus': 1380,
    'Flobecq': 1380,
    'Floreffe': 1380,
    'Florennes': 1380,
    'Florenville': 1380,
    'Forville': 1380,
    'Fosses-la-Ville': 1380,
    'Frameries': 1380,
    'Frasnes-lez-Buissenal': 1380,
    'Froidchapelle': 1380,
    'Gedinne': 1380,
    'Geer': 1380,
    'Gembloux': 1380,
    'Genappe': 1380,
    'Gerpinnes': 1380,
    'Gesves': 1380,
    'Gouvy': 1380,
    'Grez-Doiceau': 1380,
    'Habay-la-Vieille': 1380,
    'Hamoir': 1380,
    'Hamois': 1380,
    'Hannut': 1380,
    'Hastière-Lavaux': 1380,
    'Havelange': 1380,
    'Hensies': 1380,
    'Herbeumont': 1380,
    'Héron': 1380,
    'Herstal': 1380,
    'Herve': 1380,
    'Hotton': 1380,
    'Houffalize': 1380,
    'Houyet': 1380,
    'Huy': 1380,
    'Incourt': 1380,
    'Ittre': 1380,
    'Jalhay': 1380,
    'Jodoigne': 1380,
    'Juprelle': 1380,
    'Jurbise': 1380,
    'La Bruyère': 1380,
    'La Calamine': 1380,
    'La Hulpe': 1380,
    'La Louvière': 1380,
    'La Roche-en-Ardenne': 1380,
    'Léglise': 1380,
    'Lens': 1380,
    'Lessines': 1380,
    'Libin': 1380,
    'Liège': 1380,
    'Lierneux': 1380,
    'Limbourg': 1380,
    'Lincent': 1380,
    'Lobbes': 1380,
    'Lontzen': 1380,
    'Louvain-la-Neuve': 1380,
    'Malmédy': 1380,
    'Manage': 1380,
    'Manhay': 1380,
    'Marche-en-Famenne': 1380,
    'Marchin': 1380,
    'Martelange': 1380,
    'Meix-devant-Virton': 1380,
    'Merbes-le-Château': 1380,
    'Messancy': 1380,
    'Mettet': 1380,
    'Modave': 1380,
    'Momignies': 1380,
    'Mons': 1380,
    'Mons-lez-Liège': 1380,
    'Mont-Saint-Guibert': 1380,
    'Morlanwelz-Mariemont': 1380,
    'Mouscron': 1380,
    'Musson': 1380,
    'Namur': 1380,
    'Nandrin': 1380,
    'Nassogne': 1380,
    'Neufchâteau': 1380,
    'Nivelles': 1380,
    'Noville-les-Bois': 1380,
    'Ohey': 1380,
    'Olne': 1380,
    'Onhaye': 1380,
    'Oreye': 1380,
    'Ottignies': 1380,
    'Ouffet': 1380,
    'Oupeye': 1380,
    'Paliseul': 1380,
    'Pecq': 1380,
    'Pepinster': 1380,
    'Péruwelz': 1380,
    'Perwez': 1380,
    'Philippeville': 1380,
    'Plombières': 1380,
    'Pont-à-Celles': 1380,
    'Profondeville': 1380,
    'Province de Liège': 1380,
    'Province de Namur': 1380,
    'Province du Brabant Wallon': 1380,
    'Province du Hainaut': 1380,
    'Province du Luxembourg': 1380,
    'Quaregnon': 1380,
    'Quévy-le-Petit': 1380,
    'Quiévrain': 1380,
    'Raeren': 1380,
    'Ramillies': 1380,
    'Rebecq-Rognon': 1380,
    'Remicourt': 1380,
    'Rendeux': 1380,
    'Rixensart': 1380,
    'Rochefort': 1380,
    'Roeulx': 1380,
    'Rouvroy': 1380,
    'Rumes': 1380,
    'Saint-Ghislain': 1380,
    'Saint-Hubert': 1380,
    'Saint-Léger': 1380,
    'Saint-Nicolas': 1380,
    'Saint-Vith': 1380,
    'Sainte-Ode': 1380,
    'Seneffe': 1380,
    'Seraing': 1380,
    'Silly': 1380,
    'Soignies': 1380,
    'Sombreffe': 1380,
    'Somme-Leuze': 1380,
    'Soumagne': 1380,
    'Spa': 1380,
    'Sprimont': 1380,
    'Stavelot': 1380,
    'Stoumont': 1380,
    'Tellin': 1380,
    'Tenneville': 1380,
    'Theux': 1380,
    'Thuin': 1380,
    'Tinlot': 1380,
    'Tintigny': 1380,
    'Tournai': 1380,
    'Trois-Ponts': 1380,
    'Trooz': 1380,
    'Tubize': 1380,
    'Vaux-sur-Sûre': 1380,
    'Verlaine': 1380,
    'Verviers': 1380,
    'Vielsalm': 1380,
    'Villers-la-Ville': 1380,
    'Villers-le-Bouillet': 1380,
    'Virton': 1380,
    'Visé': 1380,
    'Waimes': 1380,
    'Walcourt': 1380,
    'Walhain-Saint-Paul': 1380,
    'Wanze': 1380,
    'Waremme': 1380,
    'Wasseiges': 1380,
    'Waterloo': 1380,
    'Wavre': 1380,
    'Welkenraedt': 1380,
    'Wellin': 1380,
    'Yvoir': 1380,
    'Belize City': 264,
    'San Pedro': 264,
    'Belmopan': 269,
    'Benque Viejo el Carmen': 269,
    'San Ignacio': 269,
    'Valley of Peace': 269,
    'Corozal': 266,
    'Hopelchén': 268,
    'Orange Walk': 268,
    'Shipyard': 268,
    'Dangriga': 265,
    'Placencia': 265,
    'Punta Gorda': 267,
    'Banikoara': 3077,
    'Kandi': 3077,
    'Malanville': 3077,
    'Guilmaro': 3076,
    'Natitingou': 3076,
    'Tanguieta': 3076,
    'Abomey-Calavi': 3079,
    'Allada': 3079,
    'Hévié': 3079,
    'Hinvi': 3079,
    'Ouidah': 3079,
    'Bembèrèkè': 3078,
    'Bétérou': 3078,
    'Nikki': 3078,
    'Parakou': 3078,
    'Tchaourou': 3078,
    'Comé': 3070,
    'Dassa-Zoumé': 3070,
    'Savalou': 3070,
    'Savé': 3070,
    'Bassila': 3072,
    'Commune of Djougou': 3072,
    'Djougou': 3072,
    'Djakotomey': 3071,
    'Dogbo': 3071,
    'Cotonou': 3081,
    'Commune of Athieme': 3075,
    'Lokossa': 3075,
    'Porto-Novo': 3080,
    'Kétou': 3074,
    'Pobé': 3074,
    'Sakété': 3074,
    'Abomey': 3073,
    'Bohicon': 3073,
    'Commune of Agbangnizoun': 3073,
    'Cové': 3073,
    'Jakar': 240,
    'Daphu': 239,
    'Phuntsholing': 239,
    'Tsimasham': 239,
    'Daga': 238,
    'Wangdue Phodrang': 238,
    'Gasa': 229,
    'Ha': 232,
    'Lhuentse': 234,
    'Mongar': 242,
    'Paro': 237,
    'Pemagatshel': 244,
    'Pajo': 235,
    'Punākha': 235,
    'Samdrup Jongkhar': 243,
    'Samtse': 246,
    'Sarpang': 247,
    'Thimphu': 241,
    'Trashigang': 236,
    'Trongsa': 230,
    'Tsirang': 230,
    'Trashi Yangtse': 231,
    'Panbang': 233,
    'Shemgang': 233,
    'Guayaramerín': 3375,
    'Provincia Cercado': 3375,
    'Provincia General José Ballivián': 3375,
    'Provincia Iténez': 3375,
    'Provincia Mamoré': 3375,
    'Provincia Marbán': 3375,
    'Provincia Moxos': 3375,
    'Provincia Vaca Diez': 3375,
    'Provincia Yacuma': 3375,
    'Reyes': 3375,
    'Riberalta': 3375,
    'Rurrenabaque': 3375,
    'San Borja': 3375,
    'San Ramón': 3375,
    'Santa Ana de Yacuma': 3375,
    'Santa Rosa': 3375,
    'Trinidad': 3375,
    'Camargo': 3382,
    'Monteagudo': 3382,
    'Padilla': 3382,
    'Provincia Azurduy': 3382,
    'Provincia Belisario Boeto': 3382,
    'Provincia Hernando Siles': 3382,
    'Provincia Luis Calvo': 3382,
    'Provincia Nor Cinti': 3382,
    'Provincia Oropeza': 3382,
    'Provincia Sud Cinti': 3382,
    'Provincia Tomina': 3382,
    'Provincia Yamparáez': 3382,
    'Provincia Zudáñez': 3382,
    'Sucre': 3382,
    'Tarabuco': 3382,
    'Aiquile': 3381,
    'Arani': 3381,
    'Bolivar': 3381,
    'Capinota': 3381,
    'Chimoré': 3381,
    'Cliza': 3381,
    'Cochabamba': 3381,
    'Colchani': 3381,
    'Colomi': 3381,
    'Independencia': 3381,
    'Irpa Irpa': 3381,
    'Mizque': 3381,
    'Provincia Arani': 3381,
    'Provincia Arque': 3381,
    'Provincia Ayopaya': 3381,
    'Provincia Campero': 3381,
    'Provincia Capinota': 3381,
    'Provincia Carrasco': 3381,
    'Provincia Cercado': 3381,
    'Provincia Chaparé': 3381,
    'Provincia Esteban Arce': 3381,
    'Provincia Germán Jordán': 3381,
    'Provincia Mizque': 3381,
    'Provincia Punata': 3381,
    'Provincia Quillacollo': 3381,
    'Provincia Tapacarí': 3381,
    'Punata': 3381,
    'Quillacollo': 3381,
    'Sacaba': 3381,
    'Sipe Sipe': 3381,
    'Tarata': 3381,
    'Tiquipaya': 3381,
    'Tiraque Province': 3381,
    'Totora': 3381,
    'Achacachi': 3380,
    'Amarete': 3380,
    'Batallas': 3380,
    'Caranavi': 3380,
    'Chulumani': 3380,
    'Colquiri': 3380,
    'Coripata': 3380,
    'Coroico': 3380,
    'Curahuara de Carangas': 3380,
    'Eucaliptus': 3380,
    'Guanay': 3380,
    'Huarina': 3380,
    'Huatajata': 3380,
    'José Manuel Pando': 3380,
    'La Paz': 3380,
    'Lahuachaca': 3380,
    'Mapiri': 3380,
    'Patacamaya': 3380,
    'Provincia Aroma': 3380,
    'Provincia Bautista Saavedra': 3380,
    'Provincia Camacho': 3380,
    'Provincia Franz Tamayo': 3380,
    'Provincia Gualberto Villarroel': 3380,
    'Provincia Ingavi': 3380,
    'Provincia Inquisivi': 3380,
    'Provincia Iturralde': 3380,
    'Provincia Larecaja': 3380,
    'Provincia Loayza': 3380,
    'Provincia Los Andes': 3380,
    'Provincia Manco Kapac': 3380,
    'Provincia Muñecas': 3380,
    'Provincia Murillo': 3380,
    'Provincia Nor Yungas': 3380,
    'Provincia Omasuyos': 3380,
    'Provincia Pacajes': 3380,
    'Provincia Sud Yungas': 3380,
    'Quime': 3380,
    'San Pablo': 3380,
    'San Pedro': 3380,
    'Sorata': 3380,
    'Tiahuanaco': 3380,
    'Viloco': 3380,
    'Yumani': 3380,
    'Challapata': 3376,
    'Huanuni': 3376,
    'Litoral de Atacama': 3376,
    'Machacamarca': 3376,
    'Nor Carangas Province': 3376,
    'Oruro': 3376,
    'Poopó': 3376,
    'Provincia Avaroa': 3376,
    'Provincia Carangas': 3376,
    'Provincia Cercado': 3376,
    'Provincia Ladislao Cabrera': 3376,
    'Provincia Pantaleón Dalence': 3376,
    'Provincia Poopó': 3376,
    'Provincia Sabaya': 3376,
    'Provincia Sajama': 3376,
    'Provincia San Pedro de Totora': 3376,
    'Provincia Saucari': 3376,
    'Provincia Tomás Barron': 3376,
    'Puerto de Mejillones': 3376,
    'Sebastian Pagador Province': 3376,
    'Sud Carangas Province': 3376,
    'Totoral': 3376,
    'Cobija': 3379,
    'Provincia Abuná': 3379,
    'Provincia General Federico Román': 3379,
    'Provincia Madre de Dios': 3379,
    'Provincia Manuripi': 3379,
    'Provincia Nicolás Suárez': 3379,
    'Atocha': 3383,
    'Betanzos': 3383,
    'Colchani': 3383,
    'Colquechaca': 3383,
    'Enrique Baldivieso': 3383,
    'Llallagua': 3383,
    'Potosí': 3383,
    'Provincia Alonzo de Ibáñez': 3383,
    'Provincia Charcas': 3383,
    'Provincia Chayanta': 3383,
    'Provincia Daniel Campos': 3383,
    'Provincia General Bilbao': 3383,
    'Provincia Linares': 3383,
    'Provincia Modesto Omiste': 3383,
    'Provincia Nor Chichas': 3383,
    'Provincia Nor Lípez': 3383,
    'Provincia Quijarro': 3383,
    'Provincia Rafael Bustillo': 3383,
    'Provincia Saavedra': 3383,
    'Provincia Sud Chichas': 3383,
    'Provincia Sud Lípez': 3383,
    'Provincia Tomás Frías': 3383,
    'Santa Bárbara': 3383,
    'Tupiza': 3383,
    'Uyuni': 3383,
    'Villazón': 3383,
    'Abapó': 3377,
    'Ascención de Guarayos': 3377,
    'Ascensión': 3377,
    'Boyuibe': 3377,
    'Buena Vista': 3377,
    'Camiri': 3377,
    'Charagua': 3377,
    'Comarapa': 3377,
    'Concepción': 3377,
    'Cotoca': 3377,
    'German Busch': 3377,
    'Guarayos': 3377,
    'Jorochito': 3377,
    'La Bélgica': 3377,
    'Limoncito': 3377,
    'Los Negros': 3377,
    'Mairana': 3377,
    'Mineros': 3377,
    'Montero': 3377,
    'Okinawa Número Uno': 3377,
    'Pailón': 3377,
    'Paurito': 3377,
    'Portachuelo': 3377,
    'Provincia Andrés Ibáñez': 3377,
    'Provincia Ángel Sandoval': 3377,
    'Provincia Chiquitos': 3377,
    'Provincia Cordillera': 3377,
    'Provincia Florida': 3377,
    'Provincia Ichilo': 3377,
    'Provincia Manuel María Caballero': 3377,
    'Provincia Ñuflo de Chávez': 3377,
    'Provincia Santiesteban': 3377,
    'Provincia Sara': 3377,
    'Provincia Vallegrande': 3377,
    'Provincia Velasco': 3377,
    'Provincia Warnes': 3377,
    'Puerto Quijarro': 3377,
    'Puesto de Pailas': 3377,
    'Roboré': 3377,
    'Samaipata': 3377,
    'San Carlos': 3377,
    'San Ignacio de Velasco': 3377,
    'San Juan del Surutú': 3377,
    'San Julian': 3377,
    'San Matías': 3377,
    'San Pedro': 3377,
    'Santa Cruz de la Sierra': 3377,
    'Santa Rita': 3377,
    'Santa Rosa del Sara': 3377,
    'Santiago del Torno': 3377,
    'Urubichá': 3377,
    'Vallegrande': 3377,
    'Villa Yapacaní': 3377,
    'Warnes': 3377,
    'Bermejo': 3378,
    'Entre Ríos': 3378,
    'Provincia Arce': 3378,
    'Provincia Avilez': 3378,
    'Provincia Cercado': 3378,
    'Provincia Gran Chaco': 3378,
    'Provincia Méndez': 3378,
    'Provincia O’Connor': 3378,
    'Tarija': 3378,
    'Villamontes': 3378,
    'Yacuiba': 3378,
    'Brčko': 460,
    'Brka': 460,
    'Banovići': 467,
    'Barice': 467,
    'Bihać': 467,
    'Bijela': 467,
    'Bila': 467,
    'Blagaj': 467,
    'Bosanska Krupa': 467,
    'Bosanski Petrovac': 467,
    'Bosansko Grahovo': 467,
    'Breza': 467,
    'Bugojno': 467,
    'Busovača': 467,
    'Bužim': 467,
    'Čapljina': 467,
    'Cazin': 467,
    'Čelić': 467,
    'Cim': 467,
    'Čitluk': 467,
    'Ćoralići': 467,
    'Crnići': 467,
    'Divičani': 467,
    'Dobrinje': 467,
    'Domaljevac': 467,
    'Donja Dubica': 467,
    'Donja Mahala': 467,
    'Donja Međiđa': 467,
    'Donji Vakuf': 467,
    'Drežnica': 467,
    'Drinovci': 467,
    'Drvar': 467,
    'Dubrave Donje': 467,
    'Dubrave Gornje': 467,
    'Dubravica': 467,
    'Fojnica': 467,
    'Glamoč': 467,
    'Gnojnica': 467,
    'Goražde': 467,
    'Gorica': 467,
    'Gornja Breza': 467,
    'Gornja Koprivna': 467,
    'Gornja Tuzla': 467,
    'Gornje Moštre': 467,
    'Gornje Živinice': 467,
    'Gornji Vakuf': 467,
    'Gostovići': 467,
    'Gračanica': 467,
    'Gradačac': 467,
    'Gromiljak': 467,
    'Grude': 467,
    'Hadžići': 467,
    'Hercegovačko-Neretvanski Kanton': 467,
    'Hotonj': 467,
    'Ilići': 467,
    'Ilijaš': 467,
    'Izačić': 467,
    'Jablanica': 467,
    'Jajce': 467,
    'Jelah': 467,
    'Jezerski': 467,
    'Kačuni': 467,
    'Kakanj': 467,
    'Kanton Sarajevo': 467,
    'Karadaglije': 467,
    'Kiseljak': 467,
    'Kladanj': 467,
    'Ključ': 467,
    'Kobilja Glava': 467,
    'Kočerin': 467,
    'Konjic': 467,
    'Kovači': 467,
    'Liješnica': 467,
    'Livno': 467,
    'Ljubuški': 467,
    'Lokvine': 467,
    'Lukavac': 467,
    'Lukavica': 467,
    'Maglaj': 467,
    'Mahala': 467,
    'Mala Kladuša': 467,
    'Malešići': 467,
    'Mionica': 467,
    'Mostar': 467,
    'Mramor': 467,
    'Neum': 467,
    'Novi Šeher': 467,
    'Novi Travnik': 467,
    'Odžak': 467,
    'Olovo': 467,
    'Omanjska': 467,
    'Orahovica Donja': 467,
    'Orašac': 467,
    'Orašje': 467,
    'Orguz': 467,
    'Ostrožac': 467,
    'Otoka': 467,
    'Pajić Polje': 467,
    'Pazarić': 467,
    'Peći': 467,
    'Pećigrad': 467,
    'Pjanići': 467,
    'Podhum': 467,
    'Podzvizd': 467,
    'Polje': 467,
    'Polje-Bijela': 467,
    'Potoci': 467,
    'Prozor': 467,
    'Puračić': 467,
    'Radišići': 467,
    'Rodoč': 467,
    'Rumboci': 467,
    'Sanica': 467,
    'Sanski Most': 467,
    'Sapna': 467,
    'Sarajevo': 467,
    'Šerići': 467,
    'Široki Brijeg': 467,
    'Skokovi': 467,
    'Sladna': 467,
    'Solina': 467,
    'Srebrenik': 467,
    'Stijena': 467,
    'Stjepan-Polje': 467,
    'Stolac': 467,
    'Šturlić': 467,
    'Šumatac': 467,
    'Tasovčići': 467,
    'Tešanj': 467,
    'Tešanjka': 467,
    'Todorovo': 467,
    'Tojšići': 467,
    'Tomislavgrad': 467,
    'Travnik': 467,
    'Tržačka Raštela': 467,
    'Turbe': 467,
    'Tuzla': 467,
    'Ustikolina': 467,
    'Vareš': 467,
    'Varoška Rijeka': 467,
    'Velagići': 467,
    'Velika Kladuša': 467,
    'Vidoši': 467,
    'Visoko': 467,
    'Vitez': 467,
    'Vitina': 467,
    'Vogošća': 467,
    'Voljevac': 467,
    'Vrnograč': 467,
    'Vukovije Donje': 467,
    'Zabrišće': 467,
    'Zavidovići': 467,
    'Zborište': 467,
    'Željezno Polje': 467,
    'Zenica': 467,
    'Žepče': 467,
    'Živinice': 467,
    'Balatun': 470,
    'Banja Luka': 470,
    'Bijeljina': 470,
    'Bileća': 470,
    'Blatnica': 470,
    'Brod': 470,
    'Bronzani Majdan': 470,
    'Čajniče': 470,
    'Čečava': 470,
    'Čelinac': 470,
    'Derventa': 470,
    'Doboj': 470,
    'Dobrljin': 470,
    'Dvorovi': 470,
    'Foča': 470,
    'Gacko': 470,
    'Gradiška': 470,
    'Hiseti': 470,
    'Istočni Mostar': 470,
    'Janja': 470,
    'Kalenderovci Donji': 470,
    'Kneževo': 470,
    'Knežica': 470,
    'Koran': 470,
    'Kostajnica': 470,
    'Kotor Varoš': 470,
    'Kozarska Dubica': 470,
    'Krupa na Vrbasu': 470,
    'Laktaši': 470,
    'Lamovita': 470,
    'Ljubinje': 470,
    'Lopare': 470,
    'Maglajani': 470,
    'Marićka': 470,
    'Maslovare': 470,
    'Mejdan - Obilićevo': 470,
    'Milići': 470,
    'Modriča': 470,
    'Mrkonjić Grad': 470,
    'Nevesinje': 470,
    'Novi Grad': 470,
    'Obudovac': 470,
    'Omarska': 470,
    'Opština Oštra Luka': 470,
    'Opština Višegrad': 470,
    'Oštra Luka': 470,
    'Pale': 470,
    'Pelagićevo': 470,
    'Petkovci': 470,
    'Piskavica': 470,
    'Podbrdo': 470,
    'Popovi': 470,
    'Pribinić': 470,
    'Priboj': 470,
    'Prijedor': 470,
    'Rogatica': 470,
    'Rudo': 470,
    'Šamac': 470,
    'Šekovići': 470,
    'Šipovo': 470,
    'Sokolac': 470,
    'Srbac': 470,
    'Srebrenica': 470,
    'Stanari': 470,
    'Starcevica': 470,
    'Svodna': 470,
    'Teslić': 470,
    'Trebinje': 470,
    'Trn': 470,
    'Ugljevik': 470,
    'Velika Obarska': 470,
    'Višegrad': 470,
    'Vlasenica': 470,
    'Živinice': 470,
    'Zvornik': 470,
    'Gobojango': 3067,
    'Gweta': 3067,
    'Kalamare': 3067,
    'Letlhakane': 3067,
    'Letsheng': 3067,
    'Maapi': 3067,
    'Machaneng': 3067,
    'Mahalapye': 3067,
    'Makobeng': 3067,
    'Makwata': 3067,
    'Mathakola': 3067,
    'Mathambgwane': 3067,
    'Mathathane': 3067,
    'Maunatlala': 3067,
    'Mogapi': 3067,
    'Moijabana': 3067,
    'Mookane': 3067,
    'Mopipi': 3067,
    'Mosetse': 3067,
    'Nata': 3067,
    'Orapa': 3067,
    'Palapye': 3067,
    'Pilikwe': 3067,
    'Rakops': 3067,
    'Ramokgonami': 3067,
    'Ratholo': 3067,
    'Sefophe': 3067,
    'Serowe': 3067,
    'Sua': 3067,
    'Tamasane': 3067,
    'Tobane': 3067,
    'Tonota': 3067,
    'Dekar': 3061,
    'Ghanzi': 3061,
    'Hukuntsi': 3066,
    'Kang': 3066,
    'Lehututu': 3066,
    'Manyana': 3066,
    'Tshabong': 3066,
    'Werda': 3066,
    'Bokaa': 3062,
    'Mmathubudukwane': 3062,
    'Mochudi': 3062,
    'Pilane': 3062,
    'Botlhapatlou': 3069,
    'Dutlwe': 3069,
    'Gabane': 3069,
    'Gaphatshwe': 3069,
    'Khudumelapye': 3069,
    'Lenchwe Le Tau': 3069,
    'Letlhakeng': 3069,
    'Metsemotlhaba': 3069,
    'Mmopone': 3069,
    'Mogoditshane': 3069,
    'Molepolole': 3069,
    'Nkoyaphiri': 3069,
    'Thamaga': 3069,
    'Dukwe': 3068,
    'Makaleng': 3068,
    'Masunga': 3068,
    'Sebina': 3068,
    'Maun': 3065,
    'Nokaneng': 3065,
    'Pandamatenga': 3065,
    'Sehithwa': 3065,
    'Shakawe': 3065,
    'Gaborone': 3064,
    'Janeng': 3064,
    'Kopong': 3064,
    'Otse': 3064,
    'Ramotswa': 3064,
    'Kanye': 3063,
    'Khakhea': 3063,
    'Mosopa': 3063,
    'Sekoma': 3063,
    'Acrelândia': 2012,
    'Assis Brasil': 2012,
    'Brasiléia': 2012,
    'Bujari': 2012,
    'Capixaba': 2012,
    'Cruzeiro do Sul': 2012,
    'Epitaciolândia': 2012,
    'Feijó': 2012,
    'Jordão': 2012,
    'Mâncio Lima': 2012,
    'Manoel Urbano': 2012,
    'Marechal Thaumaturgo': 2012,
    'Plácido de Castro': 2012,
    'Porto Acre': 2012,
    'Porto Walter': 2012,
    'Rio Branco': 2012,
    'Rodrigues Alves': 2012,
    'Santa Rosa do Purus': 2012,
    'Sena Madureira': 2012,
    'Senador Guiomard': 2012,
    'Tarauacá': 2012,
    'Xapuri': 2012,
    'Água Branca': 2007,
    'Anadia': 2007,
    'Arapiraca': 2007,
    'Atalaia': 2007,
    'Barra de Santo Antônio': 2007,
    'Barra de São Miguel': 2007,
    'Batalha': 2007,
    'Belém': 2007,
    'Belo Monte': 2007,
    'Boca da Mata': 2007,
    'Branquinha': 2007,
    'Cacimbinhas': 2007,
    'Cajueiro': 2007,
    'Campestre': 2007,
    'Campo Alegre': 2007,
    'Campo Grande': 2007,
    'Canapi': 2007,
    'Capela': 2007,
    'Carneiros': 2007,
    'Chã Preta': 2007,
    'Coité do Nóia': 2007,
    'Colônia Leopoldina': 2007,
    'Coqueiro Seco': 2007,
    'Coruripe': 2007,
    'Craíbas': 2007,
    'Delmiro Gouveia': 2007,
    'Dois Riachos': 2007,
    'Estrela de Alagoas': 2007,
    'Feira Grande': 2007,
    'Feliz Deserto': 2007,
    'Flexeiras': 2007,
    'Girau do Ponciano': 2007,
    'Ibateguara': 2007,
    'Igaci': 2007,
    'Igreja Nova': 2007,
    'Inhapi': 2007,
    'Jacaré dos Homens': 2007,
    'Jacuípe': 2007,
    'Japaratinga': 2007,
    'Jaramataia': 2007,
    'Jequiá da Praia': 2007,
    'Joaquim Gomes': 2007,
    'Jundiá': 2007,
    'Junqueiro': 2007,
    'Lagoa da Canoa': 2007,
    'Limoeiro de Anadia': 2007,
    'Maceió': 2007,
    'Major Isidoro': 2007,
    'Mar Vermelho': 2007,
    'Maragogi': 2007,
    'Maravilha': 2007,
    'Marechal Deodoro': 2007,
    'Maribondo': 2007,
    'Mata Grande': 2007,
    'Matriz de Camaragibe': 2007,
    'Messias': 2007,
    'Minador do Negrão': 2007,
    'Monteirópolis': 2007,
    'Murici': 2007,
    'Novo Lino': 2007,
    'Olho d  Água das Flores': 2007,
    'Olho d  Água do Casado': 2007,
    'Olho d  Água Grande': 2007,
    'Olivença': 2007,
    'Ouro Branco': 2007,
    'Palestina': 2007,
    'Palmeira dos Índios': 2007,
    'Pão de Açúcar': 2007,
    'Pariconha': 2007,
    'Paripueira': 2007,
    'Passo de Camaragibe': 2007,
    'Paulo Jacinto': 2007,
    'Penedo': 2007,
    'Piaçabuçu': 2007,
    'Pilar': 2007,
    'Pindoba': 2007,
    'Piranhas': 2007,
    'Poço das Trincheiras': 2007,
    'Porto Calvo': 2007,
    'Porto de Pedras': 2007,
    'Porto Real do Colégio': 2007,
    'Quebrangulo': 2007,
    'Rio Largo': 2007,
    'Roteiro': 2007,
    'Santa Luzia do Norte': 2007,
    'Santana do Ipanema': 2007,
    'Santana do Mundaú': 2007,
    'São Brás': 2007,
    'São José da Laje': 2007,
    'São José da Tapera': 2007,
    'São Luís do Quitunde': 2007,
    'São Miguel dos Campos': 2007,
    'São Miguel dos Milagres': 2007,
    'São Sebastião': 2007,
    'Satuba': 2007,
    'Senador Rui Palmeira': 2007,
    'Tanque d  Arca': 2007,
    'Taquarana': 2007,
    'Teotônio Vilela': 2007,
    'Traipu': 2007,
    'União dos Palmares': 2007,
    'Viçosa': 2007,
    'Amapá': 1999,
    'Calçoene': 1999,
    'Cutias': 1999,
    'Ferreira Gomes': 1999,
    'Itaubal': 1999,
    'Laranjal do Jari': 1999,
    'Macapá': 1999,
    'Mazagão': 1999,
    'Oiapoque': 1999,
    'Pedra Branca do Amapari': 1999,
    'Porto Grande': 1999,
    'Pracuúba': 1999,
    'Santana': 1999,
    'Serra do Navio': 1999,
    'Tartarugalzinho': 1999,
    'Vitória do Jari': 1999,
    'Alvarães': 2004,
    'Amaturá': 2004,
    'Anamã': 2004,
    'Anori': 2004,
    'Apuí': 2004,
    'Atalaia do Norte': 2004,
    'Autazes': 2004,
    'Barcelos': 2004,
    'Barreirinha': 2004,
    'Benjamin Constant': 2004,
    'Beruri': 2004,
    'Boa Vista do Ramos': 2004,
    'Boca do Acre': 2004,
    'Borba': 2004,
    'Caapiranga': 2004,
    'Canutama': 2004,
    'Carauari': 2004,
    'Careiro': 2004,
    'Careiro da Várzea': 2004,
    'Coari': 2004,
    'Codajás': 2004,
    'Eirunepé': 2004,
    'Envira': 2004,
    'Fonte Boa': 2004,
    'Guajará': 2004,
    'Humaitá': 2004,
    'Ipixuna': 2004,
    'Iranduba': 2004,
    'Itacoatiara': 2004,
    'Itamarati': 2004,
    'Itapiranga': 2004,
    'Japurá': 2004,
    'Juruá': 2004,
    'Jutaí': 2004,
    'Lábrea': 2004,
    'Manacapuru': 2004,
    'Manaquiri': 2004,
    'Manaus': 2004,
    'Manicoré': 2004,
    'Maraã': 2004,
    'Maués': 2004,
    'Nhamundá': 2004,
    'Nova Olinda do Norte': 2004,
    'Novo Airão': 2004,
    'Novo Aripuanã': 2004,
    'Parintins': 2004,
    'Pauini': 2004,
    'Presidente Figueiredo': 2004,
    'Rio Preto da Eva': 2004,
    'Santa Isabel do Rio Negro': 2004,
    'Santo Antônio do Içá': 2004,
    'São Gabriel da Cachoeira': 2004,
    'São Paulo de Olivença': 2004,
    'São Sebastião do Uatumã': 2004,
    'Silves': 2004,
    'Tabatinga': 2004,
    'Tapauá': 2004,
    'Tefé': 2004,
    'Tonantins': 2004,
    'Uarini': 2004,
    'Urucará': 2004,
    'Urucurituba': 2004,
    'Abaíra': 2002,
    'Abaré': 2002,
    'Acajutiba': 2002,
    'Adustina': 2002,
    'Água Fria': 2002,
    'Aiquara': 2002,
    'Alagoinhas': 2002,
    'Alcobaça': 2002,
    'Almadina': 2002,
    'Amargosa': 2002,
    'Amélia Rodrigues': 2002,
    'América Dourada': 2002,
    'Anagé': 2002,
    'Andaraí': 2002,
    'Andorinha': 2002,
    'Angical': 2002,
    'Anguera': 2002,
    'Antas': 2002,
    'Antônio Cardoso': 2002,
    'Antônio Gonçalves': 2002,
    'Aporá': 2002,
    'Apuarema': 2002,
    'Araçás': 2002,
    'Aracatu': 2002,
    'Araci': 2002,
    'Aramari': 2002,
    'Arataca': 2002,
    'Aratuípe': 2002,
    'Aurelino Leal': 2002,
    'Baianópolis': 2002,
    'Baixa Grande': 2002,
    'Banzaê': 2002,
    'Barra': 2002,
    'Barra da Estiva': 2002,
    'Barra do Choça': 2002,
    'Barra do Mendes': 2002,
    'Barra do Rocha': 2002,
    'Barreiras': 2002,
    'Barro Alto': 2002,
    'Barro Preto': 2002,
    'Barrocas': 2002,
    'Beira Rio': 2002,
    'Belmonte': 2002,
    'Belo Campo': 2002,
    'Biritinga': 2002,
    'Boa Nova': 2002,
    'Boa Vista do Tupim': 2002,
    'Bom Jesus da Lapa': 2002,
    'Bom Jesus da Serra': 2002,
    'Boninal': 2002,
    'Bonito': 2002,
    'Boquira': 2002,
    'Botuporã': 2002,
    'Brejões': 2002,
    'Brejolândia': 2002,
    'Brotas de Macaúbas': 2002,
    'Brumado': 2002,
    'Buerarema': 2002,
    'Buritirama': 2002,
    'Caatiba': 2002,
    'Cabaceiras do Paraguaçu': 2002,
    'Cachoeira': 2002,
    'Caculé': 2002,
    'Caém': 2002,
    'Caetanos': 2002,
    'Caetité': 2002,
    'Cafarnaum': 2002,
    'Cairu': 2002,
    'Caldeirão Grande': 2002,
    'Camacan': 2002,
    'Camaçari': 2002,
    'Camamu': 2002,
    'Campo Alegre de Lourdes': 2002,
    'Campo Formoso': 2002,
    'Canápolis': 2002,
    'Canarana': 2002,
    'Canavieiras': 2002,
    'Candeal': 2002,
    'Candeias': 2002,
    'Candiba': 2002,
    'Cândido Sales': 2002,
    'Cansanção': 2002,
    'Canudos': 2002,
    'Capela do Alto Alegre': 2002,
    'Capim Grosso': 2002,
    'Caraíbas': 2002,
    'Caravelas': 2002,
    'Cardeal da Silva': 2002,
    'Carinhanha': 2002,
    'Casa Nova': 2002,
    'Castro Alves': 2002,
    'Catolândia': 2002,
    'Catu': 2002,
    'Caturama': 2002,
    'Central': 2002,
    'Chorrochó': 2002,
    'Cícero Dantas': 2002,
    'Cipó': 2002,
    'Coaraci': 2002,
    'Cocos': 2002,
    'Conceição da Feira': 2002,
    'Conceição do Almeida': 2002,
    'Conceição do Coité': 2002,
    'Conceição do Jacuípe': 2002,
    'Conde': 2002,
    'Condeúba': 2002,
    'Contendas do Sincorá': 2002,
    'Coração de Maria': 2002,
    'Cordeiros': 2002,
    'Coribe': 2002,
    'Coronel João Sá': 2002,
    'Correntina': 2002,
    'Cotegipe': 2002,
    'Cravolândia': 2002,
    'Crisópolis': 2002,
    'Cristópolis': 2002,
    'Cruz das Almas': 2002,
    'Curaçá': 2002,
    'Dário Meira': 2002,
    'Dias d  Ávila': 2002,
    'Dom Basílio': 2002,
    'Dom Macedo Costa': 2002,
    'Elísio Medrado': 2002,
    'Encruzilhada': 2002,
    'Entre Rios': 2002,
    'Érico Cardoso': 2002,
    'Esplanada': 2002,
    'Euclides da Cunha': 2002,
    'Eunápolis': 2002,
    'Fátima': 2002,
    'Feira da Mata': 2002,
    'Feira de Santana': 2002,
    'Filadélfia': 2002,
    'Firmino Alves': 2002,
    'Floresta Azul': 2002,
    'Formosa do Rio Preto': 2002,
    'Gandu': 2002,
    'Gavião': 2002,
    'Gentio do Ouro': 2002,
    'Glória': 2002,
    'Gongogi': 2002,
    'Governador Mangabeira': 2002,
    'Guajeru': 2002,
    'Guanambi': 2002,
    'Guaratinga': 2002,
    'Heliópolis': 2002,
    'Iaçu': 2002,
    'Ibiassucê': 2002,
    'Ibicaraí': 2002,
    'Ibicoara': 2002,
    'Ibicuí': 2002,
    'Ibipeba': 2002,
    'Ibipitanga': 2002,
    'Ibiquera': 2002,
    'Ibirapitanga': 2002,
    'Ibirapuã': 2002,
    'Ibirataia': 2002,
    'Ibitiara': 2002,
    'Ibititá': 2002,
    'Ibotirama': 2002,
    'Ichu': 2002,
    'Igaporã': 2002,
    'Igrapiúna': 2002,
    'Iguaí': 2002,
    'Ilhéus': 2002,
    'Inhambupe': 2002,
    'Ipecaetá': 2002,
    'Ipiaú': 2002,
    'Ipirá': 2002,
    'Ipupiara': 2002,
    'Irajuba': 2002,
    'Iramaia': 2002,
    'Iraquara': 2002,
    'Irará': 2002,
    'Irecê': 2002,
    'Itabela': 2002,
    'Itaberaba': 2002,
    'Itabuna': 2002,
    'Itacaré': 2002,
    'Itaeté': 2002,
    'Itagi': 2002,
    'Itagibá': 2002,
    'Itagimirim': 2002,
    'Itaguaçu da Bahia': 2002,
    'Itaju do Colônia': 2002,
    'Itajuípe': 2002,
    'Itamaraju': 2002,
    'Itamari': 2002,
    'Itambé': 2002,
    'Itanagra': 2002,
    'Itanhém': 2002,
    'Itaparica': 2002,
    'Itapé': 2002,
    'Itapebi': 2002,
    'Itapetinga': 2002,
    'Itapicuru': 2002,
    'Itapitanga': 2002,
    'Itaquara': 2002,
    'Itarantim': 2002,
    'Itatim': 2002,
    'Itiruçu': 2002,
    'Itiúba': 2002,
    'Itororó': 2002,
    'Ituaçu': 2002,
    'Ituberá': 2002,
    'Iuiu': 2002,
    'Jaborandi': 2002,
    'Jacaraci': 2002,
    'Jacobina': 2002,
    'Jaguaquara': 2002,
    'Jaguarari': 2002,
    'Jaguaripe': 2002,
    'Jandaíra': 2002,
    'Jequié': 2002,
    'Jeremoabo': 2002,
    'Jiquiriçá': 2002,
    'Jitaúna': 2002,
    'João Dourado': 2002,
    'Juazeiro': 2002,
    'Jucuruçu': 2002,
    'Jussara': 2002,
    'Jussari': 2002,
    'Jussiape': 2002,
    'Lafaiete Coutinho': 2002,
    'Lagoa Real': 2002,
    'Laje': 2002,
    'Lajedão': 2002,
    'Lajedinho': 2002,
    'Lajedo do Tabocal': 2002,
    'Lamarão': 2002,
    'Lapão': 2002,
    'Lauro de Freitas': 2002,
    'Lençóis': 2002,
    'Licínio de Almeida': 2002,
    'Livramento de Nossa Senhora': 2002,
    'Livramento do Brumado': 2002,
    'Luís Eduardo Magalhães': 2002,
    'Macajuba': 2002,
    'Macarani': 2002,
    'Macaúbas': 2002,
    'Macururé': 2002,
    'Madre de Deus': 2002,
    'Maetinga': 2002,
    'Maiquinique': 2002,
    'Mairi': 2002,
    'Malhada': 2002,
    'Malhada de Pedras': 2002,
    'Manoel Vitorino': 2002,
    'Mansidão': 2002,
    'Maracás': 2002,
    'Maragogipe': 2002,
    'Maraú': 2002,
    'Marcionílio Souza': 2002,
    'Mascote': 2002,
    'Mata de São João': 2002,
    'Matina': 2002,
    'Medeiros Neto': 2002,
    'Miguel Calmon': 2002,
    'Milagres': 2002,
    'Mirangaba': 2002,
    'Mirante': 2002,
    'Monte Santo': 2002,
    'Morpará': 2002,
    'Morro do Chapéu': 2002,
    'Mortugaba': 2002,
    'Mucugê': 2002,
    'Mucuri': 2002,
    'Mulungu do Morro': 2002,
    'Mundo Novo': 2002,
    'Muniz Ferreira': 2002,
    'Muquém do São Francisco': 2002,
    'Muritiba': 2002,
    'Mutuípe': 2002,
    'Nazaré': 2002,
    'Nilo Peçanha': 2002,
    'Nordestina': 2002,
    'Nova Canaã': 2002,
    'Nova Fátima': 2002,
    'Nova Ibiá': 2002,
    'Nova Itarana': 2002,
    'Nova Redenção': 2002,
    'Nova Soure': 2002,
    'Nova Viçosa': 2002,
    'Novo Horizonte': 2002,
    'Novo Triunfo': 2002,
    'Olindina': 2002,
    'Oliveira dos Brejinhos': 2002,
    'Ouriçangas': 2002,
    'Ourolândia': 2002,
    'Palmas de Monte Alto': 2002,
    'Palmeiras': 2002,
    'Paramirim': 2002,
    'Paratinga': 2002,
    'Paripiranga': 2002,
    'Pau Brasil': 2002,
    'Paulo Afonso': 2002,
    'Pé de Serra': 2002,
    'Pedrão': 2002,
    'Pedro Alexandre': 2002,
    'Piatã': 2002,
    'Pilão Arcado': 2002,
    'Pindaí': 2002,
    'Pindobaçu': 2002,
    'Pintadas': 2002,
    'Piraí do Norte': 2002,
    'Piripá': 2002,
    'Piritiba': 2002,
    'Planaltino': 2002,
    'Planalto': 2002,
    'Poções': 2002,
    'Pojuca': 2002,
    'Ponto Novo': 2002,
    'Porto Seguro': 2002,
    'Posto da Mata': 2002,
    'Potiraguá': 2002,
    'Prado': 2002,
    'Presidente Dutra': 2002,
    'Presidente Jânio Quadros': 2002,
    'Presidente Tancredo Neves': 2002,
    'Queimadas': 2002,
    'Quijingue': 2002,
    'Quixabeira': 2002,
    'Rafael Jambeiro': 2002,
    'Remanso': 2002,
    'Retirolândia': 2002,
    'Riachão das Neves': 2002,
    'Riachão do Jacuípe': 2002,
    'Riacho de Santana': 2002,
    'Ribeira do Amparo': 2002,
    'Ribeira do Pombal': 2002,
    'Ribeirão do Largo': 2002,
    'Rio de Contas': 2002,
    'Rio do Antônio': 2002,
    'Rio do Pires': 2002,
    'Rio Real': 2002,
    'Rodelas': 2002,
    'Ruy Barbosa': 2002,
    'Salinas da Margarida': 2002,
    'Salvador': 2002,
    'Santa Bárbara': 2002,
    'Santa Brígida': 2002,
    'Santa Cruz Cabrália': 2002,
    'Santa Cruz da Vitória': 2002,
    'Santa Inês': 2002,
    'Santa Luzia': 2002,
    'Santa Maria da Vitória': 2002,
    'Santa Rita de Cássia': 2002,
    'Santa Terezinha': 2002,
    'Santaluz': 2002,
    'Santana': 2002,
    'Santanópolis': 2002,
    'Santo Amaro': 2002,
    'Santo Antônio de Jesus': 2002,
    'Santo Estêvão': 2002,
    'São Desidério': 2002,
    'São Domingos': 2002,
    'São Felipe': 2002,
    'São Félix': 2002,
    'São Félix do Coribe': 2002,
    'São Francisco do Conde': 2002,
    'São Gabriel': 2002,
    'São Gonçalo dos Campos': 2002,
    'São José da Vitória': 2002,
    'São José do Jacuípe': 2002,
    'São Miguel das Matas': 2002,
    'São Sebastião do Passé': 2002,
    'Sapeaçu': 2002,
    'Sátiro Dias': 2002,
    'Saubara': 2002,
    'Saúde': 2002,
    'Seabra': 2002,
    'Sebastião Laranjeiras': 2002,
    'Senhor do Bonfim': 2002,
    'Sento Sé': 2002,
    'Serra do Ramalho': 2002,
    'Serra Dourada': 2002,
    'Serra Preta': 2002,
    'Serrinha': 2002,
    'Serrolândia': 2002,
    'Simões Filho': 2002,
    'Sítio do Mato': 2002,
    'Sítio do Quinto': 2002,
    'Sobradinho': 2002,
    'Souto Soares': 2002,
    'Tabocas do Brejo Velho': 2002,
    'Tanhaçu': 2002,
    'Tanque Novo': 2002,
    'Tanquinho': 2002,
    'Taperoá': 2002,
    'Tapiramutá': 2002,
    'Teixeira de Freitas': 2002,
    'Teodoro Sampaio': 2002,
    'Teofilândia': 2002,
    'Teolândia': 2002,
    'Terra Nova': 2002,
    'Tremedal': 2002,
    'Tucano': 2002,
    'Uauá': 2002,
    'Ubaíra': 2002,
    'Ubaitaba': 2002,
    'Ubatã': 2002,
    'Uibaí': 2002,
    'Umburanas': 2002,
    'Una': 2002,
    'Urandi': 2002,
    'Uruçuca': 2002,
    'Utinga': 2002,
    'Valença': 2002,
    'Valente': 2002,
    'Várzea da Roça': 2002,
    'Várzea do Poço': 2002,
    'Várzea Nova': 2002,
    'Varzedo': 2002,
    'Vera Cruz': 2002,
    'Vereda': 2002,
    'Vitória da Conquista': 2002,
    'Wagner': 2002,
    'Wanderley': 2002,
    'Wenceslau Guimarães': 2002,
    'Xique Xique': 2002,
    'Xique-Xique': 2002,
    'Abaiara': 2016,
    'Acarape': 2016,
    'Acaraú': 2016,
    'Acopiara': 2016,
    'Aiuaba': 2016,
    'Alcântaras': 2016,
    'Altaneira': 2016,
    'Alto Santo': 2016,
    'Amontada': 2016,
    'Antonina do Norte': 2016,
    'Apuiarés': 2016,
    'Aquiraz': 2016,
    'Aracati': 2016,
    'Aracoiaba': 2016,
    'Ararendá': 2016,
    'Araripe': 2016,
    'Aratuba': 2016,
    'Arneiroz': 2016,
    'Assaré': 2016,
    'Aurora': 2016,
    'Baixio': 2016,
    'Banabuiú': 2016,
    'Barbalha': 2016,
    'Barreira': 2016,
    'Barro': 2016,
    'Barroquinha': 2016,
    'Baturité': 2016,
    'Beberibe': 2016,
    'Bela Cruz': 2016,
    'Boa Viagem': 2016,
    'Brejo Santo': 2016,
    'Camocim': 2016,
    'Campos Sales': 2016,
    'Canindé': 2016,
    'Capistrano': 2016,
    'Caridade': 2016,
    'Cariré': 2016,
    'Caririaçu': 2016,
    'Cariús': 2016,
    'Carnaubal': 2016,
    'Cascavel': 2016,
    'Catarina': 2016,
    'Catunda': 2016,
    'Caucaia': 2016,
    'Cedro': 2016,
    'Chaval': 2016,
    'Choró': 2016,
    'Chorozinho': 2016,
    'Coreaú': 2016,
    'Crateús': 2016,
    'Crato': 2016,
    'Croatá': 2016,
    'Cruz': 2016,
    'Deputado Irapuan Pinheiro': 2016,
    'Ererê': 2016,
    'Eusébio': 2016,
    'Farias Brito': 2016,
    'Forquilha': 2016,
    'Fortaleza': 2016,
    'Fortim': 2016,
    'Frecheirinha': 2016,
    'General Sampaio': 2016,
    'Graça': 2016,
    'Granja': 2016,
    'Granjeiro': 2016,
    'Groaíras': 2016,
    'Guaiúba': 2016,
    'Guaraciaba do Norte': 2016,
    'Guaramiranga': 2016,
    'Hidrolândia': 2016,
    'Horizonte': 2016,
    'Ibaretama': 2016,
    'Ibiapina': 2016,
    'Ibicuitinga': 2016,
    'Icapuí': 2016,
    'Icó': 2016,
    'Iguatu': 2016,
    'Independência': 2016,
    'Ipaporanga': 2016,
    'Ipaumirim': 2016,
    'Ipu': 2016,
    'Ipueiras': 2016,
    'Iracema': 2016,
    'Irauçuba': 2016,
    'Itaiçaba': 2016,
    'Itaitinga': 2016,
    'Itapagé': 2016,
    'Itapajé': 2016,
    'Itapipoca': 2016,
    'Itapiúna': 2016,
    'Itarema': 2016,
    'Itatira': 2016,
    'Jaguaretama': 2016,
    'Jaguaribara': 2016,
    'Jaguaribe': 2016,
    'Jaguaruana': 2016,
    'Jardim': 2016,
    'Jati': 2016,
    'Jijoca de Jericoacoara': 2016,
    'Juá dos Vieiras': 2016,
    'Juazeiro do Norte': 2016,
    'Jucás': 2016,
    'Lavras da Mangabeira': 2016,
    'Limoeiro do Norte': 2016,
    'Madalena': 2016,
    'Maracanaú': 2016,
    'Maranguape': 2016,
    'Marco': 2016,
    'Martinópole': 2016,
    'Massapê': 2016,
    'Mauriti': 2016,
    'Meruoca': 2016,
    'Milagres': 2016,
    'Milhã': 2016,
    'Miraíma': 2016,
    'Missão Velha': 2016,
    'Mombaça': 2016,
    'Monsenhor Tabosa': 2016,
    'Morada Nova': 2016,
    'Moraújo': 2016,
    'Morrinhos': 2016,
    'Mucambo': 2016,
    'Mulungu': 2016,
    'Nova Olinda': 2016,
    'Nova Russas': 2016,
    'Novo Oriente': 2016,
    'Ocara': 2016,
    'Orós': 2016,
    'Pacajus': 2016,
    'Pacatuba': 2016,
    'Pacoti': 2016,
    'Pacujá': 2016,
    'Palhano': 2016,
    'Palmácia': 2016,
    'Paracuru': 2016,
    'Paraipaba': 2016,
    'Parambu': 2016,
    'Paramoti': 2016,
    'Pedra Branca': 2016,
    'Penaforte': 2016,
    'Pentecoste': 2016,
    'Pereiro': 2016,
    'Pindoretama': 2016,
    'Piquet Carneiro': 2016,
    'Pires Ferreira': 2016,
    'Poranga': 2016,
    'Porteiras': 2016,
    'Potengi': 2016,
    'Potiretama': 2016,
    'Quiterianópolis': 2016,
    'Quixadá': 2016,
    'Quixelô': 2016,
    'Quixeramobim': 2016,
    'Quixeré': 2016,
    'Redenção': 2016,
    'Reriutaba': 2016,
    'Russas': 2016,
    'Saboeiro': 2016,
    'Salitre': 2016,
    'Santa Quitéria': 2016,
    'Santana do Acaraú': 2016,
    'Santana do Cariri': 2016,
    'São Benedito': 2016,
    'São Gonçalo do Amarante': 2016,
    'São João do Jaguaribe': 2016,
    'São João dos Inhamuns': 2016,
    'São Luís do Curu': 2016,
    'Senador Pompeu': 2016,
    'Senador Sá': 2016,
    'Sobral': 2016,
    'Solonópole': 2016,
    'Tabuleiro do Norte': 2016,
    'Tamboril': 2016,
    'Tarrafas': 2016,
    'Tauá': 2016,
    'Tejuçuoca': 2016,
    'Tianguá': 2016,
    'Trairi': 2016,
    'Tururu': 2016,
    'Ubajara': 2016,
    'Umari': 2016,
    'Umirim': 2016,
    'Uruburetama': 2016,
    'Uruoca': 2016,
    'Varjota': 2016,
    'Várzea Alegre': 2016,
    'Viçosa do Ceará': 2016,
    'Afonso Cláudio': 2018,
    'Água Doce do Norte': 2018,
    'Águia Branca': 2018,
    'Alegre': 2018,
    'Alfredo Chaves': 2018,
    'Alto Rio Novo': 2018,
    'Anchieta': 2018,
    'Apiacá': 2018,
    'Aracruz': 2018,
    'Atílio Vivacqua': 2018,
    'Baixo Guandu': 2018,
    'Barra de São Francisco': 2018,
    'Boa Esperança': 2018,
    'Bom Jesus do Norte': 2018,
    'Brejetuba': 2018,
    'Cachoeiro de Itapemirim': 2018,
    'Cariacica': 2018,
    'Castelo': 2018,
    'Colatina': 2018,
    'Conceição da Barra': 2018,
    'Conceição do Castelo': 2018,
    'Divino de São Lourenço': 2018,
    'Domingos Martins': 2018,
    'Dores do Rio Preto': 2018,
    'Ecoporanga': 2018,
    'Fundão': 2018,
    'Governador Lindenberg': 2018,
    'Guaçuí': 2018,
    'Guarapari': 2018,
    'Ibatiba': 2018,
    'Ibiraçu': 2018,
    'Ibitirama': 2018,
    'Iconha': 2018,
    'Irupi': 2018,
    'Itaguaçu': 2018,
    'Itapemirim': 2018,
    'Itarana': 2018,
    'Iúna': 2018,
    'Jaguaré': 2018,
    'Jerônimo Monteiro': 2018,
    'Jetibá': 2018,
    'João Neiva': 2018,
    'Laranja da Terra': 2018,
    'Linhares': 2018,
    'Mantenópolis': 2018,
    'Marataizes': 2018,
    'Marechal Floriano': 2018,
    'Marilândia': 2018,
    'Mimoso do Sul': 2018,
    'Montanha': 2018,
    'Mucurici': 2018,
    'Muniz Freire': 2018,
    'Muqui': 2018,
    'Nova Venécia': 2018,
    'Pancas': 2018,
    'Pedro Canário': 2018,
    'Pinheiros': 2018,
    'Piúma': 2018,
    'Ponto Belo': 2018,
    'Presidente Kennedy': 2018,
    'Rio Bananal': 2018,
    'Rio Novo do Sul': 2018,
    'Santa Leopoldina': 2018,
    'Santa Maria de Jetibá': 2018,
    'Santa Teresa': 2018,
    'São Domingos do Norte': 2018,
    'São Gabriel da Palha': 2018,
    'São José do Calçado': 2018,
    'São Mateus': 2018,
    'São Roque do Canaã': 2018,
    'Serra': 2018,
    'Sooretama': 2018,
    'Vargem Alta': 2018,
    'Venda Nova do Imigrante': 2018,
    'Viana': 2018,
    'Vila Pavão': 2018,
    'Vila Valério': 2018,
    'Vila Velha': 2018,
    'Vitória': 2018,
    'Brasília': 2017,
    'Planaltina': 2017,
    'Abadia de Goiás': 2000,
    'Abadiânia': 2000,
    'Acreúna': 2000,
    'Adelândia': 2000,
    'Água Fria de Goiás': 2000,
    'Água Limpa': 2000,
    'Águas Lindas de Goiás': 2000,
    'Alexânia': 2000,
    'Aloândia': 2000,
    'Alto Horizonte': 2000,
    'Alto Paraíso de Goiás': 2000,
    'Alvorada do Norte': 2000,
    'Amaralina': 2000,
    'Americano do Brasil': 2000,
    'Amorinópolis': 2000,
    'Anápolis': 2000,
    'Anhanguera': 2000,
    'Anicuns': 2000,
    'Aparecida de Goiânia': 2000,
    'Aparecida do Rio Doce': 2000,
    'Aporé': 2000,
    'Araçu': 2000,
    'Aragarças': 2000,
    'Aragoiânia': 2000,
    'Araguapaz': 2000,
    'Arenópolis': 2000,
    'Aruanã': 2000,
    'Aurilândia': 2000,
    'Avelinópolis': 2000,
    'Baliza': 2000,
    'Barro Alto': 2000,
    'Bela Vista de Goiás': 2000,
    'Bom Jardim de Goiás': 2000,
    'Bom Jesus de Goiás': 2000,
    'Bonfinópolis': 2000,
    'Bonópolis': 2000,
    'Brazabrantes': 2000,
    'Britânia': 2000,
    'Buriti Alegre': 2000,
    'Buriti de Goiás': 2000,
    'Buritinópolis': 2000,
    'Cabeceiras': 2000,
    'Cachoeira Alta': 2000,
    'Cachoeira de Goiás': 2000,
    'Cachoeira Dourada': 2000,
    'Caçu': 2000,
    'Caiapônia': 2000,
    'Caldas Novas': 2000,
    'Caldazinha': 2000,
    'Campestre de Goiás': 2000,
    'Campinaçu': 2000,
    'Campinorte': 2000,
    'Campo Alegre de Goiás': 2000,
    'Campo Limpo de Goiás': 2000,
    'Campos Belos': 2000,
    'Campos Verdes': 2000,
    'Carmo do Rio Verde': 2000,
    'Castelândia': 2000,
    'Catalão': 2000,
    'Caturaí': 2000,
    'Cavalcante': 2000,
    'Ceres': 2000,
    'Cezarina': 2000,
    'Chapadão do Céu': 2000,
    'Cidade Ocidental': 2000,
    'Cocalzinho de Goiás': 2000,
    'Colinas do Sul': 2000,
    'Córrego do Ouro': 2000,
    'Corumbá de Goiás': 2000,
    'Corumbaíba': 2000,
    'Cristalina': 2000,
    'Cristianópolis': 2000,
    'Crixás': 2000,
    'Cromínia': 2000,
    'Cumari': 2000,
    'Damianópolis': 2000,
    'Damolândia': 2000,
    'Davinópolis': 2000,
    'Diorama': 2000,
    'Divinópolis de Goiás': 2000,
    'Doverlândia': 2000,
    'Edealina': 2000,
    'Edéia': 2000,
    'Estrela do Norte': 2000,
    'Faina': 2000,
    'Fazenda Nova': 2000,
    'Firminópolis': 2000,
    'Flores de Goiás': 2000,
    'Formosa': 2000,
    'Formoso': 2000,
    'Gameleira de Goiás': 2000,
    'Goianápolis': 2000,
    'Goiandira': 2000,
    'Goianésia': 2000,
    'Goiânia': 2000,
    'Goianira': 2000,
    'Goiás': 2000,
    'Goiatuba': 2000,
    'Gouvelândia': 2000,
    'Guapó': 2000,
    'Guaraíta': 2000,
    'Guarani de Goiás': 2000,
    'Guarinos': 2000,
    'Heitoraí': 2000,
    'Hidrolândia': 2000,
    'Hidrolina': 2000,
    'Iaciara': 2000,
    'Inaciolândia': 2000,
    'Indiara': 2000,
    'Inhumas': 2000,
    'Ipameri': 2000,
    'Ipiranga de Goiás': 2000,
    'Iporá': 2000,
    'Israelândia': 2000,
    'Itaberaí': 2000,
    'Itaguari': 2000,
    'Itaguaru': 2000,
    'Itajá': 2000,
    'Itapaci': 2000,
    'Itapirapuã': 2000,
    'Itapuranga': 2000,
    'Itarumã': 2000,
    'Itauçu': 2000,
    'Itumbiara': 2000,
    'Ivolândia': 2000,
    'Jandaia': 2000,
    'Jaraguá': 2000,
    'Jataí': 2000,
    'Jaupaci': 2000,
    'Jesúpolis': 2000,
    'Joviânia': 2000,
    'Jussara': 2000,
    'Lagoa Santa': 2000,
    'Leopoldo de Bulhões': 2000,
    'Luziânia': 2000,
    'Mairipotaba': 2000,
    'Mambaí': 2000,
    'Mara Rosa': 2000,
    'Marzagão': 2000,
    'Matrinchã': 2000,
    'Maurilândia': 2000,
    'Mimoso de Goiás': 2000,
    'Minaçu': 2000,
    'Mineiros': 2000,
    'Moiporá': 2000,
    'Monte Alegre de Goiás': 2000,
    'Montes Claros de Goiás': 2000,
    'Montividiu': 2000,
    'Montividiu do Norte': 2000,
    'Morrinhos': 2000,
    'Morro Agudo de Goiás': 2000,
    'Mossâmedes': 2000,
    'Mozarlândia': 2000,
    'Mundo Novo': 2000,
    'Mutunópolis': 2000,
    'Nazário': 2000,
    'Nerópolis': 2000,
    'Niquelândia': 2000,
    'Nova América': 2000,
    'Nova Aurora': 2000,
    'Nova Crixás': 2000,
    'Nova Glória': 2000,
    'Nova Iguaçu de Goiás': 2000,
    'Nova Roma': 2000,
    'Nova Veneza': 2000,
    'Novo Brasil': 2000,
    'Novo Gama': 2000,
    'Novo Planalto': 2000,
    'Orizona': 2000,
    'Ouro Verde de Goiás': 2000,
    'Ouvidor': 2000,
    'Padre Bernardo': 2000,
    'Palestina de Goiás': 2000,
    'Palmeiras de Goiás': 2000,
    'Palmelo': 2000,
    'Palminópolis': 2000,
    'Panamá': 2000,
    'Paranaiguara': 2000,
    'Paraúna': 2000,
    'Perolândia': 2000,
    'Petrolina de Goiás': 2000,
    'Pilar de Goiás': 2000,
    'Piracanjuba': 2000,
    'Piranhas': 2000,
    'Pirenópolis': 2000,
    'Pires do Rio': 2000,
    'Planaltina': 2000,
    'Pontalina': 2000,
    'Porangatu': 2000,
    'Porteirão': 2000,
    'Portelândia': 2000,
    'Posse': 2000,
    'Professor Jamil': 2000,
    'Quirinópolis': 2000,
    'Rialma': 2000,
    'Rianápolis': 2000,
    'Rio Quente': 2000,
    'Rio Verde': 2000,
    'Rubiataba': 2000,
    'Sanclerlândia': 2000,
    'Santa Bárbara de Goiás': 2000,
    'Santa Cruz de Goiás': 2000,
    'Santa Fé de Goiás': 2000,
    'Santa Helena de Goiás': 2000,
    'Santa Isabel': 2000,
    'Santa Rita do Araguaia': 2000,
    'Santa Rita do Novo Destino': 2000,
    'Santa Rosa de Goiás': 2000,
    'Santa Tereza de Goiás': 2000,
    'Santa Terezinha de Goiás': 2000,
    'Santo Antônio da Barra': 2000,
    'Santo Antônio de Goiás': 2000,
    'Santo Antônio do Descoberto': 2000,
    'São Domingos': 2000,
    'São Francisco de Goiás': 2000,
    'São João d  Aliança': 2000,
    'São João da Paraúna': 2000,
    'São Luís de Montes Belos': 2000,
    'São Luiz do Norte': 2000,
    'São Miguel do Araguaia': 2000,
    'São Miguel do Passa Quatro': 2000,
    'São Patrício': 2000,
    'São Simão': 2000,
    'Senador Canedo': 2000,
    'Serranópolis': 2000,
    'Silvânia': 2000,
    'Simolândia': 2000,
    'Sítio d  Abadia': 2000,
    'Taquaral de Goiás': 2000,
    'Teresina de Goiás': 2000,
    'Terezópolis de Goiás': 2000,
    'Três Ranchos': 2000,
    'Trindade': 2000,
    'Trombas': 2000,
    'Turvânia': 2000,
    'Turvelândia': 2000,
    'Uirapuru': 2000,
    'Uruaçu': 2000,
    'Uruana': 2000,
    'Urutaí': 2000,
    'Valparaíso de Goiás': 2000,
    'Varjão': 2000,
    'Vianópolis': 2000,
    'Vicentinópolis': 2000,
    'Vila Boa': 2000,
    'Vila Propício': 2000,
    'Açailândia': 2015,
    'Afonso Cunha': 2015,
    'Água Doce do Maranhão': 2015,
    'Alcântara': 2015,
    'Aldeias Altas': 2015,
    'Altamira do Maranhão': 2015,
    'Alto Alegre do Maranhão': 2015,
    'Alto Alegre do Pindaré': 2015,
    'Alto Parnaíba': 2015,
    'Amapá do Maranhão': 2015,
    'Amarante do Maranhão': 2015,
    'Anajatuba': 2015,
    'Anapurus': 2015,
    'Apicum-Açu': 2015,
    'Araguanã': 2015,
    'Araioses': 2015,
    'Arame': 2015,
    'Arari': 2015,
    'Atins': 2015,
    'Axixá': 2015,
    'Bacabal': 2015,
    'Bacabeira': 2015,
    'Bacuri': 2015,
    'Bacurituba': 2015,
    'Balsas': 2015,
    'Barão de Grajaú': 2015,
    'Barra do Corda': 2015,
    'Barreirinhas': 2015,
    'Bela Vista do Maranhão': 2015,
    'Belágua': 2015,
    'Benedito Leite': 2015,
    'Bequimão': 2015,
    'Bernardo do Mearim': 2015,
    'Boa Vista do Gurupi': 2015,
    'Bom Jardim': 2015,
    'Bom Jesus das Selvas': 2015,
    'Bom Lugar': 2015,
    'Brejo': 2015,
    'Brejo de Areia': 2015,
    'Buriti': 2015,
    'Buriti Bravo': 2015,
    'Buriticupu': 2015,
    'Buritirana': 2015,
    'Cachoeira Grande': 2015,
    'Cajapió': 2015,
    'Cajari': 2015,
    'Campestre do Maranhão': 2015,
    'Cândido Mendes': 2015,
    'Cantanhede': 2015,
    'Capinzal do Norte': 2015,
    'Carolina': 2015,
    'Carutapera': 2015,
    'Caxias': 2015,
    'Cedral': 2015,
    'Central do Maranhão': 2015,
    'Centro do Guilherme': 2015,
    'Centro Novo do Maranhão': 2015,
    'Chapadinha': 2015,
    'Cidelândia': 2015,
    'Codó': 2015,
    'Coelho Neto': 2015,
    'Colinas': 2015,
    'Conceição do Lago-Açu': 2015,
    'Coroatá': 2015,
    'Cururupu': 2015,
    'Davinópolis': 2015,
    'Dom Pedro': 2015,
    'Duque Bacelar': 2015,
    'Esperantinópolis': 2015,
    'Estreito': 2015,
    'Feira Nova do Maranhão': 2015,
    'Fernando Falcão': 2015,
    'Formosa da Serra Negra': 2015,
    'Fortaleza dos Nogueiras': 2015,
    'Fortuna': 2015,
    'Godofredo Viana': 2015,
    'Gonçalves Dias': 2015,
    'Governador Archer': 2015,
    'Governador Edison Lobão': 2015,
    'Governador Eugênio Barros': 2015,
    'Governador Luiz Rocha': 2015,
    'Governador Newton Bello': 2015,
    'Governador Nunes Freire': 2015,
    'Graça Aranha': 2015,
    'Grajaú': 2015,
    'Guimarães': 2015,
    'Humberto de Campos': 2015,
    'Icatu': 2015,
    'Igarapé do Meio': 2015,
    'Igarapé Grande': 2015,
    'Imperatriz': 2015,
    'Itaipava do Grajaú': 2015,
    'Itapecuru Mirim': 2015,
    'Itinga do Maranhão': 2015,
    'Jatobá': 2015,
    'Jenipapo dos Vieiras': 2015,
    'João Lisboa': 2015,
    'Joselândia': 2015,
    'Junco do Maranhão': 2015,
    'Lago da Pedra': 2015,
    'Lago do Junco': 2015,
    'Lago dos Rodrigues': 2015,
    'Lago Verde': 2015,
    'Lagoa do Mato': 2015,
    'Lagoa Grande do Maranhão': 2015,
    'Lajeado Novo': 2015,
    'Lima Campos': 2015,
    'Loreto': 2015,
    'Luís Domingues': 2015,
    'Magalhães de Almeida': 2015,
    'Maracaçumé': 2015,
    'Marajá do Sena': 2015,
    'Maranhãozinho': 2015,
    'Mata Roma': 2015,
    'Matinha': 2015,
    'Matões': 2015,
    'Matões do Norte': 2015,
    'Milagres do Maranhão': 2015,
    'Mirador': 2015,
    'Miranda do Norte': 2015,
    'Mirinzal': 2015,
    'Monção': 2015,
    'Montes Altos': 2015,
    'Morros': 2015,
    'Nina Rodrigues': 2015,
    'Nova Colinas': 2015,
    'Nova Iorque': 2015,
    'Nova Olinda do Maranhão': 2015,
    'Olho d  Água das Cunhãs': 2015,
    'Olinda Nova do Maranhão': 2015,
    'Paço do Lumiar': 2015,
    'Palmeirândia': 2015,
    'Paraibano': 2015,
    'Parnarama': 2015,
    'Passagem Franca': 2015,
    'Pastos Bons': 2015,
    'Paulino Neves': 2015,
    'Paulo Ramos': 2015,
    'Pedreiras': 2015,
    'Pedro do Rosário': 2015,
    'Penalva': 2015,
    'Peri Mirim': 2015,
    'Peritoró': 2015,
    'Pindaré Mirim': 2015,
    'Pindaré-Mirim': 2015,
    'Pinheiro': 2015,
    'Pio XII': 2015,
    'Pirapemas': 2015,
    'Poção de Pedras': 2015,
    'Porto Franco': 2015,
    'Porto Rico do Maranhão': 2015,
    'Presidente Dutra': 2015,
    'Presidente Juscelino': 2015,
    'Presidente Médici': 2015,
    'Presidente Sarney': 2015,
    'Presidente Vargas': 2015,
    'Primeira Cruz': 2015,
    'Raposa': 2015,
    'Riachão': 2015,
    'Ribamar Fiquene': 2015,
    'Rosário': 2015,
    'Sambaíba': 2015,
    'Santa Filomena do Maranhão': 2015,
    'Santa Helena': 2015,
    'Santa Inês': 2015,
    'Santa Luzia': 2015,
    'Santa Luzia do Paruá': 2015,
    'Santa Quitéria do Maranhão': 2015,
    'Santa Rita': 2015,
    'Santana do Maranhão': 2015,
    'Santo Amaro do Maranhão': 2015,
    'Santo Antônio dos Lopes': 2015,
    'São Benedito do Rio Preto': 2015,
    'São Bento': 2015,
    'São Bernardo': 2015,
    'São Domingos do Azeitão': 2015,
    'São Domingos do Maranhão': 2015,
    'São Félix de Balsas': 2015,
    'São Francisco do Brejão': 2015,
    'São Francisco do Maranhão': 2015,
    'São João Batista': 2015,
    'São João do Carú': 2015,
    'São João do Paraíso': 2015,
    'São João do Soter': 2015,
    'São João dos Patos': 2015,
    'São José de Ribamar': 2015,
    'São José dos Basílios': 2015,
    'São Luís': 2015,
    'São Luís Gonzaga do Maranhão': 2015,
    'São Mateus do Maranhão': 2015,
    'São Pedro da Água Branca': 2015,
    'São Pedro dos Crentes': 2015,
    'São Raimundo das Mangabeiras': 2015,
    'São Raimundo do Doca Bezerra': 2015,
    'São Roberto': 2015,
    'São Vicente Ferrer': 2015,
    'Satubinha': 2015,
    'Senador Alexandre Costa': 2015,
    'Senador La Rocque': 2015,
    'Serrano do Maranhão': 2015,
    'Sítio Novo': 2015,
    'Sucupira do Norte': 2015,
    'Sucupira do Riachão': 2015,
    'Tasso Fragoso': 2015,
    'Timbiras': 2015,
    'Timon': 2015,
    'Trizidela do Vale': 2015,
    'Tufilândia': 2015,
    'Tuntum': 2015,
    'Turiaçu': 2015,
    'Turilândia': 2015,
    'Tutóia': 2015,
    'Urbano Santos': 2015,
    'Vargem Grande': 2015,
    'Viana': 2015,
    'Vila Nova dos Martírios': 2015,
    'Vitória do Mearim': 2015,
    'Vitorino Freire': 2015,
    'Zé Doca': 2015,
    'Acorizal': 2011,
    'Água Boa': 2011,
    'Alta Floresta': 2011,
    'Alto Araguaia': 2011,
    'Alto Boa Vista': 2011,
    'Alto Garças': 2011,
    'Alto Paraguai': 2011,
    'Alto Taquari': 2011,
    'Apiacás': 2011,
    'Araguaiana': 2011,
    'Araguainha': 2011,
    'Araputanga': 2011,
    'Arenápolis': 2011,
    'Aripuanã': 2011,
    'Barão de Melgaço': 2011,
    'Barra do Bugres': 2011,
    'Barra do Garças': 2011,
    'Bom Jesus do Araguaia': 2011,
    'Brasnorte': 2011,
    'Cáceres': 2011,
    'Campinápolis': 2011,
    'Campo Novo do Parecis': 2011,
    'Campo Verde': 2011,
    'Campos de Júlio': 2011,
    'CanaBrava do Norte': 2011,
    'Canarana': 2011,
    'Carlinda': 2011,
    'Castanheira': 2011,
    'Chapada dos Guimarães': 2011,
    'Cláudia': 2011,
    'Cocalinho': 2011,
    'Colíder': 2011,
    'Colniza': 2011,
    'Comodoro': 2011,
    'Confresa': 2011,
    'Conquista D  oeste': 2011,
    'Cotriguaçu': 2011,
    'Cuiabá': 2011,
    'Curvelândia': 2011,
    'Denise': 2011,
    'Diamantino': 2011,
    'Dom Aquino': 2011,
    'Feliz Natal': 2011,
    'Figueirópolis d  Oeste': 2011,
    'Gaúcha do Norte': 2011,
    'General Carneiro': 2011,
    'Glória d  Oeste': 2011,
    'Guarantã do Norte': 2011,
    'Guiratinga': 2011,
    'Indiavaí': 2011,
    'Ipiranga do Norte': 2011,
    'Itanhangá': 2011,
    'Itaúba': 2011,
    'Itiquira': 2011,
    'Jaciara': 2011,
    'Jangada': 2011,
    'Jauru': 2011,
    'Juara': 2011,
    'Juína': 2011,
    'Juruena': 2011,
    'Juscimeira': 2011,
    'Lambari d  Oeste': 2011,
    'Lucas': 2011,
    'Lucas do Rio Verde': 2011,
    'Luciara': 2011,
    'Marcelândia': 2011,
    'Matupá': 2011,
    'Mirassol d  Oeste': 2011,
    'Nobres': 2011,
    'Nortelândia': 2011,
    'Nossa Senhora do Livramento': 2011,
    'Nova Bandeirantes': 2011,
    'Nova Brasilândia': 2011,
    'Nova Canaã do Norte': 2011,
    'Nova Guarita': 2011,
    'Nova Lacerda': 2011,
    'Nova Marilândia': 2011,
    'Nova Maringá': 2011,
    'Nova Monte Verde': 2011,
    'Nova Mutum': 2011,
    'Nova Nazaré': 2011,
    'Nova Olímpia': 2011,
    'Nova Santa Helena': 2011,
    'Nova Ubiratã': 2011,
    'Nova Xavantina': 2011,
    'Novo Horizonte do Norte': 2011,
    'Novo Mundo': 2011,
    'Novo Santo Antônio': 2011,
    'Novo São Joaquim': 2011,
    'Paranaíta': 2011,
    'Paranatinga': 2011,
    'Pedra Preta': 2011,
    'Peixoto de Azevedo': 2011,
    'Planalto da Serra': 2011,
    'Poconé': 2011,
    'Pontal do Araguaia': 2011,
    'Ponte Branca': 2011,
    'Pontes e Lacerda': 2011,
    'Porto Alegre do Norte': 2011,
    'Porto dos Gaúchos': 2011,
    'Porto Esperidião': 2011,
    'Porto Estrela': 2011,
    'Poxoréo': 2011,
    'Poxoréu': 2011,
    'Primavera do Leste': 2011,
    'Querência': 2011,
    'Reserva do Cabaçal': 2011,
    'Ribeirão Cascalheira': 2011,
    'Ribeirãozinho': 2011,
    'Rio Branco': 2011,
    'Rondolândia': 2011,
    'Rondonópolis': 2011,
    'Rosário Oeste': 2011,
    'Salto do Céu': 2011,
    'Santa Carmem': 2011,
    'Santa Cruz do Xingu': 2011,
    'Santa Rita do Trivelato': 2011,
    'Santa Terezinha': 2011,
    'Santo Afonso': 2011,
    'Santo Antônio do Leste': 2011,
    'Santo Antônio do Leverger': 2011,
    'São Félix do Araguaia': 2011,
    'São José do Povo': 2011,
    'São José do Rio Claro': 2011,
    'São José do Xingu': 2011,
    'São José dos Quatro Marcos': 2011,
    'São Pedro da Cipa': 2011,
    'Sapezal': 2011,
    'Serra Nova Dourada': 2011,
    'Sinop': 2011,
    'Sorriso': 2011,
    'Tabaporã': 2011,
    'Tangará da Serra': 2011,
    'Tapurah': 2011,
    'Terra Nova do Norte': 2011,
    'Tesouro': 2011,
    'Torixoréu': 2011,
    'União do Sul': 2011,
    'Vale de São Domingos': 2011,
    'Várzea Grande': 2011,
    'Vera': 2011,
    'Vila Bela da Santíssima Trindade': 2011,
    'Vila Rica': 2011,
    'Água Clara': 2010,
    'Alcinópolis': 2010,
    'Amambai': 2010,
    'Anastácio': 2010,
    'Anaurilândia': 2010,
    'Angélica': 2010,
    'Antônio João': 2010,
    'Aparecida do Taboado': 2010,
    'Aquidauana': 2010,
    'Aral Moreira': 2010,
    'Bandeirantes': 2010,
    'Bataguassu': 2010,
    'Bataiporã': 2010,
    'Batayporã': 2010,
    'Bela Vista': 2010,
    'Bodoquena': 2010,
    'Bonito': 2010,
    'Brasilândia': 2010,
    'Caarapó': 2010,
    'Camapuã': 2010,
    'Campo Grande': 2010,
    'Campo Verde': 2010,
    'Caracol': 2010,
    'Cassilândia': 2010,
    'Chapadão do Sul': 2010,
    'Corguinho': 2010,
    'Coronel Sapucaia': 2010,
    'Corumbá': 2010,
    'Costa Rica': 2010,
    'Coxim': 2010,
    'Deodápolis': 2010,
    'Dois Irmãos do Buriti': 2010,
    'Douradina': 2010,
    'Dourados': 2010,
    'Eldorado': 2010,
    'Fátima do Sul': 2010,
    'Figueirão': 2010,
    'Glória de Dourados': 2010,
    'Guia Lopes da Laguna': 2010,
    'Iguatemi': 2010,
    'Inocência': 2010,
    'Itaporã': 2010,
    'Itaquiraí': 2010,
    'Ivinhema': 2010,
    'Japorã': 2010,
    'Jaraguari': 2010,
    'Jardim': 2010,
    'Jateí': 2010,
    'Juti': 2010,
    'Ladário': 2010,
    'Laguna Carapã': 2010,
    'Maracaju': 2010,
    'Miranda': 2010,
    'Mundo Novo': 2010,
    'Naviraí': 2010,
    'Nioaque': 2010,
    'Nova Alvorada do Sul': 2010,
    'Nova Andradina': 2010,
    'Novo Horizonte do Sul': 2010,
    'Paraíso das Águas': 2010,
    'Paranaíba': 2010,
    'Paranhos': 2010,
    'Pedro Gomes': 2010,
    'Ponta Porã': 2010,
    'Pôrto Barra do Ivinheima': 2010,
    'Porto Murtinho': 2010,
    'Ribas do Rio Pardo': 2010,
    'Rio Brilhante': 2010,
    'Rio Negro': 2010,
    'Rio Verde de Mato Grosso': 2010,
    'Rochedo': 2010,
    'Santa Rita do Pardo': 2010,
    'São Gabriel do Oeste': 2010,
    'Selvíria': 2010,
    'Sete Quedas': 2010,
    'Sidrolândia': 2010,
    'Sonora': 2010,
    'Tacuru': 2010,
    'Taquarussu': 2010,
    'Terenos': 2010,
    'Três Lagoas': 2010,
    'Vicentina': 2010,
    'Abadia dos Dourados': 1998,
    'Abaeté': 1998,
    'Abre Campo': 1998,
    'Acaiaca': 1998,
    'Açucena': 1998,
    'Água Boa': 1998,
    'Água Comprida': 1998,
    'Aguanil': 1998,
    'Águas Formosas': 1998,
    'Águas Vermelhas': 1998,
    'Aimorés': 1998,
    'Aiuruoca': 1998,
    'Alagoa': 1998,
    'Albertina': 1998,
    'Além Paraíba': 1998,
    'Alfenas': 1998,
    'Alfredo Vasconcelos': 1998,
    'Almenara': 1998,
    'Alpercata': 1998,
    'Alpinópolis': 1998,
    'Alterosa': 1998,
    'Alto Caparaó': 1998,
    'Alto Jequitibá': 1998,
    'Alto Rio Doce': 1998,
    'Alvarenga': 1998,
    'Alvinópolis': 1998,
    'Alvorada de Minas': 1998,
    'Amparo da Serra': 1998,
    'Andradas': 1998,
    'Andrelândia': 1998,
    'Angelândia': 1998,
    'Antônio Carlos': 1998,
    'Antônio Dias': 1998,
    'Antônio Prado de Minas': 1998,
    'Araçaí': 1998,
    'Aracitaba': 1998,
    'Araçuaí': 1998,
    'Araguari': 1998,
    'Arantina': 1998,
    'Araponga': 1998,
    'Araporã': 1998,
    'Arapuá': 1998,
    'Araújos': 1998,
    'Araxá': 1998,
    'Arceburgo': 1998,
    'Arcos': 1998,
    'Areado': 1998,
    'Argirita': 1998,
    'Aricanduva': 1998,
    'Arinos': 1998,
    'Astolfo Dutra': 1998,
    'Ataléia': 1998,
    'Augusto de Lima': 1998,
    'Baependi': 1998,
    'Baldim': 1998,
    'Bambuí': 1998,
    'Bandeira': 1998,
    'Bandeira do Sul': 1998,
    'Barão de Cocais': 1998,
    'Barão de Monte Alto': 1998,
    'Barbacena': 1998,
    'Barra Longa': 1998,
    'Barreiro do Jaíba': 1998,
    'Barroso': 1998,
    'Bela Vista de Minas': 1998,
    'Belmiro Braga': 1998,
    'Belo Horizonte': 1998,
    'Belo Oriente': 1998,
    'Belo Vale': 1998,
    'Berilo': 1998,
    'Berizal': 1998,
    'Bertópolis': 1998,
    'Betim': 1998,
    'Bias Fortes': 1998,
    'Bicas': 1998,
    'Biquinhas': 1998,
    'Boa Esperança': 1998,
    'Bocaina de Minas': 1998,
    'Bocaiúva': 1998,
    'Bom Despacho': 1998,
    'Bom Jardim de Minas': 1998,
    'Bom Jesus da Penha': 1998,
    'Bom Jesus do Amparo': 1998,
    'Bom Jesus do Galho': 1998,
    'Bom Repouso': 1998,
    'Bom Sucesso': 1998,
    'Bonfim': 1998,
    'Bonfinópolis de Minas': 1998,
    'Bonito de Minas': 1998,
    'Borda da Mata': 1998,
    'Botelhos': 1998,
    'Botumirim': 1998,
    'Brás Pires': 1998,
    'Brasilândia de Minas': 1998,
    'Brasília de Minas': 1998,
    'Braúnas': 1998,
    'Brazópolis': 1998,
    'Brumadinho': 1998,
    'Bueno Brandão': 1998,
    'Buenópolis': 1998,
    'Bugre': 1998,
    'Buritis': 1998,
    'Buritizeiro': 1998,
    'Cabeceira Grande': 1998,
    'Cabo Verde': 1998,
    'Cachoeira da Prata': 1998,
    'Cachoeira de Minas': 1998,
    'Cachoeira de Pajeú': 1998,
    'Cachoeira Dourada': 1998,
    'Caetanópolis': 1998,
    'Caeté': 1998,
    'Caiana': 1998,
    'Cajuri': 1998,
    'Caldas': 1998,
    'Camacho': 1998,
    'Camanducaia': 1998,
    'Cambuí': 1998,
    'Cambuquira': 1998,
    'Campanário': 1998,
    'Campanha': 1998,
    'Campestre': 1998,
    'Campina Verde': 1998,
    'Campo Azul': 1998,
    'Campo Belo': 1998,
    'Campo do Meio': 1998,
    'Campo Florido': 1998,
    'Campos Altos': 1998,
    'Campos Gerais': 1998,
    'Cana Verde': 1998,
    'Canaã': 1998,
    'Canápolis': 1998,
    'Candeias': 1998,
    'Cantagalo': 1998,
    'Caparaó': 1998,
    'Capela Nova': 1998,
    'Capelinha': 1998,
    'Capetinga': 1998,
    'Capim Branco': 1998,
    'Capinópolis': 1998,
    'Capitão Andrade': 1998,
    'Capitão Enéas': 1998,
    'Capitólio': 1998,
    'Caputira': 1998,
    'Caraí': 1998,
    'Caranaíba': 1998,
    'Carandaí': 1998,
    'Carangola': 1998,
    'Caratinga': 1998,
    'Carbonita': 1998,
    'Careaçu': 1998,
    'Carlos Chagas': 1998,
    'Carmésia': 1998,
    'Carmo da Cachoeira': 1998,
    'Carmo da Mata': 1998,
    'Carmo de Minas': 1998,
    'Carmo do Cajuru': 1998,
    'Carmo do Paranaíba': 1998,
    'Carmo do Rio Claro': 1998,
    'Carmópolis de Minas': 1998,
    'Carneirinho': 1998,
    'Carrancas': 1998,
    'Carvalhópolis': 1998,
    'Carvalhos': 1998,
    'Casa Grande': 1998,
    'Cascalho Rico': 1998,
    'Cássia': 1998,
    'Cataguases': 1998,
    'Catas Altas': 1998,
    'Catas Altas da Noruega': 1998,
    'Catuji': 1998,
    'Catuti': 1998,
    'Caxambu': 1998,
    'Cedro do Abaeté': 1998,
    'Central de Minas': 1998,
    'Centralina': 1998,
    'Chácara': 1998,
    'Chalé': 1998,
    'Chapada do Norte': 1998,
    'Chapada Gaúcha': 1998,
    'Chiador': 1998,
    'Cipotânea': 1998,
    'Claraval': 1998,
    'Claro dos Poções': 1998,
    'Cláudio': 1998,
    'Coimbra': 1998,
    'Coluna': 1998,
    'Comendador Gomes': 1998,
    'Comercinho': 1998,
    'Conceição da Aparecida': 1998,
    'Conceição da Barra de Minas': 1998,
    'Conceição das Alagoas': 1998,
    'Conceição das Pedras': 1998,
    'Conceição de Ipanema': 1998,
    'Conceição do Mato Dentro': 1998,
    'Conceição do Pará': 1998,
    'Conceição do Rio Verde': 1998,
    'Conceição dos Ouros': 1998,
    'Cônego Marinho': 1998,
    'Confins': 1998,
    'Congonhal': 1998,
    'Congonhas': 1998,
    'Congonhas do Norte': 1998,
    'Conquista': 1998,
    'Conselheiro Lafaiete': 1998,
    'Conselheiro Pena': 1998,
    'Consolação': 1998,
    'Contagem': 1998,
    'Coqueiral': 1998,
    'Coração de Jesus': 1998,
    'Cordisburgo': 1998,
    'Cordislândia': 1998,
    'Corinto': 1998,
    'Coroaci': 1998,
    'Coromandel': 1998,
    'Coronel Fabriciano': 1998,
    'Coronel Murta': 1998,
    'Coronel Pacheco': 1998,
    'Coronel Xavier Chaves': 1998,
    'Córrego Danta': 1998,
    'Córrego do Bom Jesus': 1998,
    'Córrego Fundo': 1998,
    'Córrego Novo': 1998,
    'Couto de Magalhães de Minas': 1998,
    'Crisólita': 1998,
    'Cristais': 1998,
    'Cristália': 1998,
    'Cristiano Otoni': 1998,
    'Cristina': 1998,
    'Crucilândia': 1998,
    'Cruzeiro da Fortaleza': 1998,
    'Cruzília': 1998,
    'Cuparaque': 1998,
    'Curral de Dentro': 1998,
    'Curvelo': 1998,
    'Datas': 1998,
    'Delfim Moreira': 1998,
    'Delfinópolis': 1998,
    'Delta': 1998,
    'Descoberto': 1998,
    'Desterro de Entre Rios': 1998,
    'Desterro do Melo': 1998,
    'Diamantina': 1998,
    'Diogo de Vasconcelos': 1998,
    'Dionísio': 1998,
    'Divinésia': 1998,
    'Divino': 1998,
    'Divino das Laranjeiras': 1998,
    'Divinolândia de Minas': 1998,
    'Divinópolis': 1998,
    'Divisa Alegre': 1998,
    'Divisa Nova': 1998,
    'Divisópolis': 1998,
    'Dom Bosco': 1998,
    'Dom Cavati': 1998,
    'Dom Joaquim': 1998,
    'Dom Silvério': 1998,
    'Dom Viçoso': 1998,
    'Dona Eusébia': 1998,
    'Dores de Campos': 1998,
    'Dores de Guanhães': 1998,
    'Dores do Indaiá': 1998,
    'Dores do Turvo': 1998,
    'Doresópolis': 1998,
    'Douradoquara': 1998,
    'Durandé': 1998,
    'Elói Mendes': 1998,
    'Engenheiro Caldas': 1998,
    'Engenheiro Navarro': 1998,
    'Entre Folhas': 1998,
    'Entre Rios de Minas': 1998,
    'Ervália': 1998,
    'Esmeraldas': 1998,
    'Espera Feliz': 1998,
    'Espinosa': 1998,
    'Espírito Santo do Dourado': 1998,
    'Estiva': 1998,
    'Estrela Dalva': 1998,
    'Estrela do Indaiá': 1998,
    'Estrela do Sul': 1998,
    'Eugenópolis': 1998,
    'Ewbank da Câmara': 1998,
    'Extrema': 1998,
    'Fama': 1998,
    'Faria Lemos': 1998,
    'Felício dos Santos': 1998,
    'Felisburgo': 1998,
    'Felixlândia': 1998,
    'Fernandes Tourinho': 1998,
    'Ferros': 1998,
    'Fervedouro': 1998,
    'Florestal': 1998,
    'Formiga': 1998,
    'Formoso': 1998,
    'Fortaleza de Minas': 1998,
    'Fortuna de Minas': 1998,
    'Francisco Badaró': 1998,
    'Francisco Dumont': 1998,
    'Francisco Sá': 1998,
    'Franciscópolis': 1998,
    'Frei Gaspar': 1998,
    'Frei Inocêncio': 1998,
    'Frei Lagonegro': 1998,
    'Fronteira': 1998,
    'Fronteira dos Vales': 1998,
    'Fruta de Leite': 1998,
    'Frutal': 1998,
    'Funilândia': 1998,
    'Galiléia': 1998,
    'Gameleiras': 1998,
    'Glaucilândia': 1998,
    'Goiabeira': 1998,
    'Goianá': 1998,
    'Gonçalves': 1998,
    'Gonzaga': 1998,
    'Gouveia': 1998,
    'Governador Valadares': 1998,
    'Grão Mogol': 1998,
    'Grupiara': 1998,
    'Guanhães': 1998,
    'Guapé': 1998,
    'Guaraciaba': 1998,
    'Guaraciama': 1998,
    'Guaranésia': 1998,
    'Guarani': 1998,
    'Guarará': 1998,
    'Guarda-Mor': 1998,
    'Guaxupé': 1998,
    'Guidoval': 1998,
    'Guimarânia': 1998,
    'Guiricema': 1998,
    'Gurinhatã': 1998,
    'Heliodora': 1998,
    'Iapu': 1998,
    'Ibertioga': 1998,
    'Ibiá': 1998,
    'Ibiaí': 1998,
    'Ibiracatu': 1998,
    'Ibiraci': 1998,
    'Ibirité': 1998,
    'Ibitiúra de Minas': 1998,
    'Ibituruna': 1998,
    'Icaraí de Minas': 1998,
    'Igarapé': 1998,
    'Igaratinga': 1998,
    'Iguatama': 1998,
    'Ijaci': 1998,
    'Ilicínea': 1998,
    'Imbé de Minas': 1998,
    'Inconfidentes': 1998,
    'Indaiabira': 1998,
    'Indianópolis': 1998,
    'Ingaí': 1998,
    'Inhapim': 1998,
    'Inhaúma': 1998,
    'Inimutaba': 1998,
    'Ipaba': 1998,
    'Ipanema': 1998,
    'Ipatinga': 1998,
    'Ipiaçu': 1998,
    'Ipuiúna': 1998,
    'Iraí de Minas': 1998,
    'Itabira': 1998,
    'Itabirinha': 1998,
    'Itabirito': 1998,
    'Itacambira': 1998,
    'Itacarambi': 1998,
    'Itaguara': 1998,
    'Itaipé': 1998,
    'Itajubá': 1998,
    'Itamarandiba': 1998,
    'Itamarati de Minas': 1998,
    'Itambacuri': 1998,
    'Itambé do Mato Dentro': 1998,
    'Itamogi': 1998,
    'Itamonte': 1998,
    'Itanhandu': 1998,
    'Itanhomi': 1998,
    'Itaobim': 1998,
    'Itapagipe': 1998,
    'Itapecerica': 1998,
    'Itapeva': 1998,
    'Itatiaiuçu': 1998,
    'Itaú de Minas': 1998,
    'Itaúna': 1998,
    'Itaverava': 1998,
    'Itinga': 1998,
    'Itueta': 1998,
    'Ituiutaba': 1998,
    'Itumirim': 1998,
    'Iturama': 1998,
    'Itutinga': 1998,
    'Jaboticatubas': 1998,
    'Jacinto': 1998,
    'Jacuí': 1998,
    'Jacutinga': 1998,
    'Jaguaraçu': 1998,
    'Jaíba': 1998,
    'Jampruca': 1998,
    'Janaúba': 1998,
    'Januária': 1998,
    'Japaraíba': 1998,
    'Japonvar': 1998,
    'Jeceaba': 1998,
    'Jenipapo de Minas': 1998,
    'Jequeri': 1998,
    'Jequitaí': 1998,
    'Jequitibá': 1998,
    'Jequitinhonha': 1998,
    'Jesuânia': 1998,
    'Joaíma': 1998,
    'Joanésia': 1998,
    'João Monlevade': 1998,
    'João Pinheiro': 1998,
    'Joaquim Felício': 1998,
    'Jordânia': 1998,
    'José Gonçalves de Minas': 1998,
    'José Raydan': 1998,
    'Josenópolis': 1998,
    'Juatuba': 1998,
    'Juiz de Fora': 1998,
    'Juramento': 1998,
    'Juruaia': 1998,
    'Juvenília': 1998,
    'Ladainha': 1998,
    'Lagamar': 1998,
    'Lagoa da Prata': 1998,
    'Lagoa dos Patos': 1998,
    'Lagoa Dourada': 1998,
    'Lagoa Formosa': 1998,
    'Lagoa Grande': 1998,
    'Lagoa Santa': 1998,
    'Lajinha': 1998,
    'Lambari': 1998,
    'Lamim': 1998,
    'Laranjal': 1998,
    'Lassance': 1998,
    'Lavras': 1998,
    'Leandro Ferreira': 1998,
    'Leme do Prado': 1998,
    'Leopoldina': 1998,
    'Liberdade': 1998,
    'Lima Duarte': 1998,
    'Limeira do Oeste': 1998,
    'Lontra': 1998,
    'Luisburgo': 1998,
    'Luislândia': 1998,
    'Luminárias': 1998,
    'Luz': 1998,
    'Machacalis': 1998,
    'Machado': 1998,
    'Madre de Deus de Minas': 1998,
    'Malacacheta': 1998,
    'Mamonas': 1998,
    'Manga': 1998,
    'Manhuaçu': 1998,
    'Manhumirim': 1998,
    'Mantena': 1998,
    'Mar de Espanha': 1998,
    'Maravilhas': 1998,
    'Maria da Fé': 1998,
    'Mariana': 1998,
    'Marilac': 1998,
    'Mário Campos': 1998,
    'Maripá de Minas': 1998,
    'Marliéria': 1998,
    'Marmelópolis': 1998,
    'Martinho Campos': 1998,
    'Martins Soares': 1998,
    'Mata Verde': 1998,
    'Materlândia': 1998,
    'Mateus Leme': 1998,
    'Mathias Lobato': 1998,
    'Matias Barbosa': 1998,
    'Matias Cardoso': 1998,
    'Matipó': 1998,
    'Mato Verde': 1998,
    'Matozinhos': 1998,
    'Matutina': 1998,
    'Medeiros': 1998,
    'Medina': 1998,
    'Mendes Pimentel': 1998,
    'Mercês': 1998,
    'Mesquita': 1998,
    'Minas Novas': 1998,
    'Minduri': 1998,
    'Mirabela': 1998,
    'Miradouro': 1998,
    'Miraí': 1998,
    'Miravânia': 1998,
    'Moeda': 1998,
    'Moema': 1998,
    'Monjolos': 1998,
    'Monsenhor Paulo': 1998,
    'Montalvânia': 1998,
    'Monte Alegre de Minas': 1998,
    'Monte Azul': 1998,
    'Monte Belo': 1998,
    'Monte Carmelo': 1998,
    'Monte Formoso': 1998,
    'Monte Santo de Minas': 1998,
    'Monte Sião': 1998,
    'Monte Verde': 1998,
    'Montes Claros': 1998,
    'Montezuma': 1998,
    'Morada Nova de Minas': 1998,
    'Morro da Garça': 1998,
    'Morro do Pilar': 1998,
    'Munhoz': 1998,
    'Muriaé': 1998,
    'Mutum': 1998,
    'Muzambinho': 1998,
    'Nacip Raydan': 1998,
    'Nanuque': 1998,
    'Naque': 1998,
    'Natalândia': 1998,
    'Natércia': 1998,
    'Nazareno': 1998,
    'Nepomuceno': 1998,
    'Ninheira': 1998,
    'Nova Belém': 1998,
    'Nova Era': 1998,
    'Nova Lima': 1998,
    'Nova Módica': 1998,
    'Nova Ponte': 1998,
    'Nova Porteirinha': 1998,
    'Nova Resende': 1998,
    'Nova Serrana': 1998,
    'Nova União': 1998,
    'Novo Cruzeiro': 1998,
    'Novo Oriente de Minas': 1998,
    'Novorizonte': 1998,
    'Olaria': 1998,
    'Olhos-d  Água': 1998,
    'Olímpio Noronha': 1998,
    'Oliveira': 1998,
    'Oliveira Fortes': 1998,
    'Onça de Pitangui': 1998,
    'Oratórios': 1998,
    'Orizânia': 1998,
    'Ouro Branco': 1998,
    'Ouro Fino': 1998,
    'Ouro Preto': 1998,
    'Ouro Verde de Minas': 1998,
    'Padre Carvalho': 1998,
    'Padre Paraíso': 1998,
    'Pai Pedro': 1998,
    'Paineiras': 1998,
    'Pains': 1998,
    'Paiva': 1998,
    'Palma': 1998,
    'Palmópolis': 1998,
    'Papagaios': 1998,
    'Pará de Minas': 1998,
    'Paracatu': 1998,
    'Paraguaçu': 1998,
    'Paraisópolis': 1998,
    'Paraopeba': 1998,
    'Passa Quatro': 1998,
    'Passa Tempo': 1998,
    'Passa-Vinte': 1998,
    'Passabém': 1998,
    'Passos': 1998,
    'Patis': 1998,
    'Patos de Minas': 1998,
    'Patrocínio': 1998,
    'Patrocínio do Muriaé': 1998,
    'Paula Cândido': 1998,
    'Paulistas': 1998,
    'Pavão': 1998,
    'Peçanha': 1998,
    'Pedra Azul': 1998,
    'Pedra Bonita': 1998,
    'Pedra do Anta': 1998,
    'Pedra do Indaiá': 1998,
    'Pedra Dourada': 1998,
    'Pedralva': 1998,
    'Pedras de Maria da Cruz': 1998,
    'Pedrinópolis': 1998,
    'Pedro Leopoldo': 1998,
    'Pedro Teixeira': 1998,
    'Pequeri': 1998,
    'Pequi': 1998,
    'Perdigão': 1998,
    'Perdizes': 1998,
    'Perdões': 1998,
    'Periquito': 1998,
    'Pescador': 1998,
    'Piau': 1998,
    'Piedade de Caratinga': 1998,
    'Piedade de Ponte Nova': 1998,
    'Piedade do Rio Grande': 1998,
    'Piedade dos Gerais': 1998,
    'Pimenta': 1998,
    'Pingo-d  Água': 1998,
    'Pintópolis': 1998,
    'Piracema': 1998,
    'Pirajuba': 1998,
    'Piranga': 1998,
    'Piranguçu': 1998,
    'Piranguinho': 1998,
    'Pirapetinga': 1998,
    'Pirapora': 1998,
    'Piraúba': 1998,
    'Pitangui': 1998,
    'Piuí': 1998,
    'Piumhi': 1998,
    'Planura': 1998,
    'Poço Fundo': 1998,
    'Poços de Caldas': 1998,
    'Pocrane': 1998,
    'Pompéu': 1998,
    'Ponte Nova': 1998,
    'Ponto Chique': 1998,
    'Ponto dos Volantes': 1998,
    'Porteirinha': 1998,
    'Porto Firme': 1998,
    'Poté': 1998,
    'Pouso Alegre': 1998,
    'Pouso Alto': 1998,
    'Prados': 1998,
    'Prata': 1998,
    'Pratápolis': 1998,
    'Pratinha': 1998,
    'Presidente Bernardes': 1998,
    'Presidente Juscelino': 1998,
    'Presidente Kubitschek': 1998,
    'Presidente Olegário': 1998,
    'Prudente de Morais': 1998,
    'Quartel Geral': 1998,
    'Queluzito': 1998,
    'Raposos': 1998,
    'Raul Soares': 1998,
    'Recreio': 1998,
    'Reduto': 1998,
    'Resende Costa': 1998,
    'Resplendor': 1998,
    'Ressaquinha': 1998,
    'Riachinho': 1998,
    'Riacho dos Machados': 1998,
    'Ribeirão das Neves': 1998,
    'Ribeirão Vermelho': 1998,
    'Rio Acima': 1998,
    'Rio Casca': 1998,
    'Rio do Prado': 1998,
    'Rio Doce': 1998,
    'Rio Espera': 1998,
    'Rio Manso': 1998,
    'Rio Novo': 1998,
    'Rio Paranaíba': 1998,
    'Rio Pardo de Minas': 1998,
    'Rio Piracicaba': 1998,
    'Rio Pomba': 1998,
    'Rio Preto': 1998,
    'Rio Vermelho': 1998,
    'Ritápolis': 1998,
    'Rochedo de Minas': 1998,
    'Rodeiro': 1998,
    'Romaria': 1998,
    'Rosário da Limeira': 1998,
    'Rubelita': 1998,
    'Rubim': 1998,
    'Sabará': 1998,
    'Sabinópolis': 1998,
    'Sacramento': 1998,
    'Salinas': 1998,
    'Salto da Divisa': 1998,
    'Santa Bárbara': 1998,
    'Santa Bárbara do Leste': 1998,
    'Santa Bárbara do Monte Verde': 1998,
    'Santa Bárbara do Tugúrio': 1998,
    'Santa Cruz de Minas': 1998,
    'Santa Cruz de Salinas': 1998,
    'Santa Cruz do Escalvado': 1998,
    'Santa Efigênia de Minas': 1998,
    'Santa Fé de Minas': 1998,
    'Santa Helena de Minas': 1998,
    'Santa Juliana': 1998,
    'Santa Luzia': 1998,
    'Santa Margarida': 1998,
    'Santa Maria de Itabira': 1998,
    'Santa Maria do Salto': 1998,
    'Santa Maria do Suaçuí': 1998,
    'Santa Rita de Caldas': 1998,
    'Santa Rita de Ibitipoca': 1998,
    'Santa Rita de Jacutinga': 1998,
    'Santa Rita de Minas': 1998,
    'Santa Rita do Itueto': 1998,
    'Santa Rita do Sapucaí': 1998,
    'Santa Rosa da Serra': 1998,
    'Santa Vitória': 1998,
    'Santana da Vargem': 1998,
    'Santana de Cataguases': 1998,
    'Santana de Pirapama': 1998,
    'Santana do Deserto': 1998,
    'Santana do Garambéu': 1998,
    'Santana do Jacaré': 1998,
    'Santana do Manhuaçu': 1998,
    'Santana do Paraíso': 1998,
    'Santana do Riacho': 1998,
    'Santana dos Montes': 1998,
    'Santo Antônio do Amparo': 1998,
    'Santo Antônio do Aventureiro': 1998,
    'Santo Antônio do Grama': 1998,
    'Santo Antônio do Itambé': 1998,
    'Santo Antônio do Jacinto': 1998,
    'Santo Antônio do Monte': 1998,
    'Santo Antônio do Retiro': 1998,
    'Santo Antônio do Rio Abaixo': 1998,
    'Santo Hipólito': 1998,
    'Santos Dumont': 1998,
    'São Bento Abade': 1998,
    'São Brás do Suaçuí': 1998,
    'São Domingos das Dores': 1998,
    'São Domingos do Prata': 1998,
    'São Félix de Minas': 1998,
    'São Francisco': 1998,
    'São Francisco de Paula': 1998,
    'São Francisco de Sales': 1998,
    'São Francisco do Glória': 1998,
    'São Geraldo': 1998,
    'São Geraldo da Piedade': 1998,
    'São Geraldo do Baixio': 1998,
    'São Gonçalo do Abaeté': 1998,
    'São Gonçalo do Pará': 1998,
    'São Gonçalo do Rio Abaixo': 1998,
    'São Gonçalo do Rio Preto': 1998,
    'São Gonçalo do Sapucaí': 1998,
    'São Gotardo': 1998,
    'São João Batista do Glória': 1998,
    'São João da Lagoa': 1998,
    'São João da Mata': 1998,
    'São João da Ponte': 1998,
    'São João das Missões': 1998,
    'São João del Rei': 1998,
    'São João do Manhuaçu': 1998,
    'São João do Manteninha': 1998,
    'São João do Oriente': 1998,
    'São João do Pacuí': 1998,
    'São João do Paraíso': 1998,
    'São João Evangelista': 1998,
    'São João Nepomuceno': 1998,
    'São Joaquim de Bicas': 1998,
    'São José da Barra': 1998,
    'São José da Lapa': 1998,
    'São José da Safira': 1998,
    'São José da Varginha': 1998,
    'São José do Alegre': 1998,
    'São José do Divino': 1998,
    'São José do Goiabal': 1998,
    'São José do Jacuri': 1998,
    'São José do Mantimento': 1998,
    'São Lourenço': 1998,
    'São Miguel do Anta': 1998,
    'São Pedro da União': 1998,
    'São Pedro do Suaçuí': 1998,
    'São Pedro dos Ferros': 1998,
    'São Romão': 1998,
    'São Roque de Minas': 1998,
    'São Sebastião da Bela Vista': 1998,
    'São Sebastião da Vargem Alegre': 1998,
    'São Sebastião do Anta': 1998,
    'São Sebastião do Maranhão': 1998,
    'São Sebastião do Oeste': 1998,
    'São Sebastião do Paraíso': 1998,
    'São Sebastião do Rio Preto': 1998,
    'São Sebastião do Rio Verde': 1998,
    'São Thomé das Letras': 1998,
    'São Tiago': 1998,
    'São Tomás de Aquino': 1998,
    'São Vicente de Minas': 1998,
    'Sapucaí-Mirim': 1998,
    'Sardoá': 1998,
    'Sarzedo': 1998,
    'Sem-Peixe': 1998,
    'Senador Amaral': 1998,
    'Senador Cortes': 1998,
    'Senador Firmino': 1998,
    'Senador José Bento': 1998,
    'Senador Modestino Gonçalves': 1998,
    'Senhora de Oliveira': 1998,
    'Senhora do Porto': 1998,
    'Senhora dos Remédios': 1998,
    'Sericita': 1998,
    'Seritinga': 1998,
    'Serra Azul de Minas': 1998,
    'Serra da Saudade': 1998,
    'Serra do Salitre': 1998,
    'Serra dos Aimorés': 1998,
    'Serrania': 1998,
    'Serranópolis de Minas': 1998,
    'Serranos': 1998,
    'Serro': 1998,
    'Sete Lagoas': 1998,
    'Setubinha': 1998,
    'Silveirânia': 1998,
    'Silvianópolis': 1998,
    'Simão Pereira': 1998,
    'Simonésia': 1998,
    'Sobrália': 1998,
    'Soledade de Minas': 1998,
    'Tabuleiro': 1998,
    'Taiobeiras': 1998,
    'Taparuba': 1998,
    'Tapira': 1998,
    'Tapiraí': 1998,
    'Taquaraçu de Minas': 1998,
    'Tarumirim': 1998,
    'Teixeiras': 1998,
    'Teófilo Otoni': 1998,
    'Timóteo': 1998,
    'Tiradentes': 1998,
    'Tiros': 1998,
    'Tocantins': 1998,
    'Tocos do Moji': 1998,
    'Toledo': 1998,
    'Tombos': 1998,
    'Três Corações': 1998,
    'Três Marias': 1998,
    'Três Pontas': 1998,
    'Tumiritinga': 1998,
    'Tupaciguara': 1998,
    'Turmalina': 1998,
    'Turvolândia': 1998,
    'Ubá': 1998,
    'Ubaí': 1998,
    'Ubaporanga': 1998,
    'Uberaba': 1998,
    'Uberlândia': 1998,
    'Umburatiba': 1998,
    'Unaí': 1998,
    'União de Minas': 1998,
    'Uruana de Minas': 1998,
    'Urucânia': 1998,
    'Urucuia': 1998,
    'Vargem Alegre': 1998,
    'Vargem Bonita': 1998,
    'Vargem Grande do Rio Pardo': 1998,
    'Varginha': 1998,
    'Varjão de MInas': 1998,
    'Várzea da Palma': 1998,
    'Varzelândia': 1998,
    'Vazante': 1998,
    'Verdelândia': 1998,
    'Veredinha': 1998,
    'Veríssimo': 1998,
    'Vermelho Novo': 1998,
    'Vespasiano': 1998,
    'Viçosa': 1998,
    'Vieiras': 1998,
    'Virgem da Lapa': 1998,
    'Virgínia': 1998,
    'Virginópolis': 1998,
    'Virgolândia': 1998,
    'Visconde do Rio Branco': 1998,
    'Volta Grande': 1998,
    'Wenceslau Braz': 1998,
    'Abaetetuba': 2009,
    'Abel Figueiredo': 2009,
    'Acará': 2009,
    'Afuá': 2009,
    'Água Azul do Norte': 2009,
    'Alenquer': 2009,
    'Almeirim': 2009,
    'Altamira': 2009,
    'Anajás': 2009,
    'Ananindeua': 2009,
    'Anapu': 2009,
    'Augusto Corrêa': 2009,
    'Aurora do Pará': 2009,
    'Aveiro': 2009,
    'Bagre': 2009,
    'Baião': 2009,
    'Bannach': 2009,
    'Barcarena': 2009,
    'Belém': 2009,
    'Belterra': 2009,
    'Benevides': 2009,
    'Bom Jesus do Tocantins': 2009,
    'Bonito': 2009,
    'Bragança': 2009,
    'Brasil Novo': 2009,
    'Brejo Grande do Araguaia': 2009,
    'Breu Branco': 2009,
    'Breves': 2009,
    'Bujaru': 2009,
    'Cachoeira do Arari': 2009,
    'Cachoeira do Piriá': 2009,
    'Cametá': 2009,
    'Canaã dos Carajás': 2009,
    'Capanema': 2009,
    'Capitão Poço': 2009,
    'Castanhal': 2009,
    'Chaves': 2009,
    'Colares': 2009,
    'Conceição do Araguaia': 2009,
    'Concórdia do Pará': 2009,
    'Cumaru do Norte': 2009,
    'Curionópolis': 2009,
    'Curralinho': 2009,
    'Curuá': 2009,
    'Curuçá': 2009,
    'Dom Eliseu': 2009,
    'Eldorado do Carajás': 2009,
    'Faro': 2009,
    'Floresta do Araguaia': 2009,
    'Garrafão do Norte': 2009,
    'Goianésia do Pará': 2009,
    'Gurupá': 2009,
    'Igarapé Açu': 2009,
    'Igarapé Miri': 2009,
    'Igarapé-Açu': 2009,
    'Igarapé-Miri': 2009,
    'Inhangapi': 2009,
    'Ipixuna do Pará': 2009,
    'Irituia': 2009,
    'Itaituba': 2009,
    'Itupiranga': 2009,
    'Jacareacanga': 2009,
    'Jacundá': 2009,
    'Juruti': 2009,
    'Limoeiro do Ajuru': 2009,
    'Mãe do Rio': 2009,
    'Magalhães Barata': 2009,
    'Marabá': 2009,
    'Maracanã': 2009,
    'Marapanim': 2009,
    'Marituba': 2009,
    'Medicilândia': 2009,
    'Melgaço': 2009,
    'Mocajuba': 2009,
    'Moju': 2009,
    'Mojuí Dos Campos': 2009,
    'Monte Alegre': 2009,
    'Muaná': 2009,
    'Nova Esperança do Piriá': 2009,
    'Nova Ipixuna': 2009,
    'Nova Timboteua': 2009,
    'Novo Progresso': 2009,
    'Novo Repartimento': 2009,
    'Óbidos': 2009,
    'Oeiras do Pará': 2009,
    'Oriximiná': 2009,
    'Ourém': 2009,
    'Ourilândia do Norte': 2009,
    'Pacajá': 2009,
    'Palestina do Pará': 2009,
    'Paragominas': 2009,
    'Parauapebas': 2009,
    'Pau d  Arco': 2009,
    'Peixe-Boi': 2009,
    'Piçarra': 2009,
    'Placas': 2009,
    'Ponta de Pedras': 2009,
    'Portel': 2009,
    'Porto de Moz': 2009,
    'Prainha': 2009,
    'Primavera': 2009,
    'Quatipuru': 2009,
    'Redenção': 2009,
    'Rio Maria': 2009,
    'Rondon do Pará': 2009,
    'Rurópolis': 2009,
    'Salinópolis': 2009,
    'Salvaterra': 2009,
    'Santa Bárbara do Pará': 2009,
    'Santa Cruz do Arari': 2009,
    'Santa Izabel do Pará': 2009,
    'Santa Luzia do Pará': 2009,
    'Santa Maria das Barreiras': 2009,
    'Santa Maria do Pará': 2009,
    'Santana do Araguaia': 2009,
    'Santarém': 2009,
    'Santarém Novo': 2009,
    'Santo Antônio do Tauá': 2009,
    'São Caetano de Odivelas': 2009,
    'São Domingos do Araguaia': 2009,
    'São Domingos do Capim': 2009,
    'São Félix do Xingu': 2009,
    'São Francisco do Pará': 2009,
    'São Geraldo do Araguaia': 2009,
    'São João da Ponta': 2009,
    'São João de Pirabas': 2009,
    'São João do Araguaia': 2009,
    'São Miguel do Guamá': 2009,
    'São Sebastião da Boa Vista': 2009,
    'Sapucaia': 2009,
    'Senador José Porfírio': 2009,
    'Soure': 2009,
    'Tailândia': 2009,
    'Terra Alta': 2009,
    'Terra Santa': 2009,
    'Tomé Açu': 2009,
    'Tomé-Açu': 2009,
    'Tracuateua': 2009,
    'Trairão': 2009,
    'Tucumã': 2009,
    'Tucuruí': 2009,
    'Ulianópolis': 2009,
    'Uruará': 2009,
    'Vigia': 2009,
    'Viseu': 2009,
    'Vitória do Xingu': 2009,
    'Xinguara': 2009,
    'Água Branca': 2005,
    'Aguiar': 2005,
    'Alagoa Grande': 2005,
    'Alagoa Nova': 2005,
    'Alagoinha': 2005,
    'Alcantil': 2005,
    'Algodão de Jandaíra': 2005,
    'Alhandra': 2005,
    'Amparo': 2005,
    'Aparecida': 2005,
    'Araçagi': 2005,
    'Arara': 2005,
    'Araruna': 2005,
    'Areia': 2005,
    'Areia de Baraúnas': 2005,
    'Areial': 2005,
    'Aroeiras': 2005,
    'Assunção': 2005,
    'Baía da Traição': 2005,
    'Bananeiras': 2005,
    'Baraúna': 2005,
    'Barra de Santa Rosa': 2005,
    'Barra de Santana': 2005,
    'Barra de São Miguel': 2005,
    'Bayeux': 2005,
    'Belém': 2005,
    'Belém do Brejo do Cruz': 2005,
    'Bernardino Batista': 2005,
    'Boa Ventura': 2005,
    'Boa Vista': 2005,
    'Bom Jesus': 2005,
    'Bom Sucesso': 2005,
    'Bonito de Santa Fé': 2005,
    'Boqueirão': 2005,
    'Borborema': 2005,
    'Brejo do Cruz': 2005,
    'Brejo dos Santos': 2005,
    'Caaporã': 2005,
    'Cabaceiras': 2005,
    'Cabedelo': 2005,
    'Cachoeira dos Índios': 2005,
    'Cacimba de Areia': 2005,
    'Cacimba de Dentro': 2005,
    'Cacimbas': 2005,
    'Caiçara': 2005,
    'Cajazeiras': 2005,
    'Cajazeirinhas': 2005,
    'Caldas Brandão': 2005,
    'Camalaú': 2005,
    'Campina Grande': 2005,
    'Capim': 2005,
    'Caraúbas': 2005,
    'Carrapateira': 2005,
    'Casserengue': 2005,
    'Catingueira': 2005,
    'Catolé do Rocha': 2005,
    'Caturité': 2005,
    'Conceição': 2005,
    'Condado': 2005,
    'Conde': 2005,
    'Congo': 2005,
    'Coremas': 2005,
    'Coxixola': 2005,
    'Cruz do Espírito Santo': 2005,
    'Cubati': 2005,
    'Cuité': 2005,
    'Cuité de Mamanguape': 2005,
    'Cuitegi': 2005,
    'Curral de Cima': 2005,
    'Curral Velho': 2005,
    'Damião': 2005,
    'Desterro': 2005,
    'Diamante': 2005,
    'Dona Inês': 2005,
    'Duas Estradas': 2005,
    'Emas': 2005,
    'Esperança': 2005,
    'Fagundes': 2005,
    'Frei Martinho': 2005,
    'Gado Bravo': 2005,
    'Guarabira': 2005,
    'Gurinhém': 2005,
    'Gurjão': 2005,
    'Ibiara': 2005,
    'Igaracy': 2005,
    'Imaculada': 2005,
    'Ingá': 2005,
    'Itabaiana': 2005,
    'Itaporanga': 2005,
    'Itapororoca': 2005,
    'Itatuba': 2005,
    'Jacaraú': 2005,
    'Jericó': 2005,
    'João Pessoa': 2005,
    'Joca Claudino': 2005,
    'Juarez Távora': 2005,
    'Juazeirinho': 2005,
    'Junco do Seridó': 2005,
    'Juripiranga': 2005,
    'Juru': 2005,
    'Lagoa': 2005,
    'Lagoa de Dentro': 2005,
    'Lagoa Seca': 2005,
    'Lastro': 2005,
    'Livramento': 2005,
    'Logradouro': 2005,
    'Lucena': 2005,
    'Mãe d  Água': 2005,
    'Malta': 2005,
    'Mamanguape': 2005,
    'Manaíra': 2005,
    'Marcação': 2005,
    'Mari': 2005,
    'Marizópolis': 2005,
    'Massaranduba': 2005,
    'Mataraca': 2005,
    'Matinhas': 2005,
    'Mato Grosso': 2005,
    'Maturéia': 2005,
    'Mogeiro': 2005,
    'Montadas': 2005,
    'Monte Horebe': 2005,
    'Monteiro': 2005,
    'Mulungu': 2005,
    'Natuba': 2005,
    'Nazarezinho': 2005,
    'Nova Floresta': 2005,
    'Nova Olinda': 2005,
    'Nova Palmeira': 2005,
    'Olho d  Água': 2005,
    'Olivedos': 2005,
    'Ouro Velho': 2005,
    'Parari': 2005,
    'Passagem': 2005,
    'Patos': 2005,
    'Paulista': 2005,
    'Pedra Branca': 2005,
    'Pedra Lavrada': 2005,
    'Pedras de Fogo': 2005,
    'Pedro Régis': 2005,
    'Piancó': 2005,
    'Picuí': 2005,
    'Pilar': 2005,
    'Pilões': 2005,
    'Pilõezinhos': 2005,
    'Pirpirituba': 2005,
    'Pitimbu': 2005,
    'Pocinhos': 2005,
    'Poço Dantas': 2005,
    'Poço de José de Moura': 2005,
    'Pombal': 2005,
    'Prata': 2005,
    'Princesa Isabel': 2005,
    'Puxinanã': 2005,
    'Queimadas': 2005,
    'Quixaba': 2005,
    'Remígio': 2005,
    'Riachão': 2005,
    'Riachão do Bacamarte': 2005,
    'Riachão do Poço': 2005,
    'Riacho de Santo Antônio': 2005,
    'Riacho dos Cavalos': 2005,
    'Rio Tinto': 2005,
    'Salgadinho': 2005,
    'Salgado de São Félix': 2005,
    'Santa Cecília': 2005,
    'Santa Cruz': 2005,
    'Santa Helena': 2005,
    'Santa Inês': 2005,
    'Santa Luzia': 2005,
    'Santa Rita': 2005,
    'Santa Teresinha': 2005,
    'Santana de Mangueira': 2005,
    'Santana dos Garrotes': 2005,
    'Santo André': 2005,
    'São Bentinho': 2005,
    'São Bento': 2005,
    'São Domingos': 2005,
    'São Domingos do Cariri': 2005,
    'São Francisco': 2005,
    'São João do Cariri': 2005,
    'São João do Rio do Peixe': 2005,
    'São João do Tigre': 2005,
    'São José da Lagoa Tapada': 2005,
    'São José de Caiana': 2005,
    'São José de Espinharas': 2005,
    'São José de Piranhas': 2005,
    'São José de Princesa': 2005,
    'São José do Bonfim': 2005,
    'São José do Brejo do Cruz': 2005,
    'São José do Sabugi': 2005,
    'São José dos Cordeiros': 2005,
    'São José dos Ramos': 2005,
    'São Mamede': 2005,
    'São Miguel de Taipu': 2005,
    'São Sebastião de Lagoa de Roça': 2005,
    'São Sebastião do Umbuzeiro': 2005,
    'São Vicente do Seridó': 2005,
    'Sapé': 2005,
    'Serra Branca': 2005,
    'Serra da Raiz': 2005,
    'Serra Grande': 2005,
    'Serra Redonda': 2005,
    'Serraria': 2005,
    'Sertãozinho': 2005,
    'Sobrado': 2005,
    'Solânea': 2005,
    'Soledade': 2005,
    'Sossêgo': 2005,
    'Sousa': 2005,
    'Sumé': 2005,
    'Tacima': 2005,
    'Taperoá': 2005,
    'Tavares': 2005,
    'Teixeira': 2005,
    'Tenório': 2005,
    'Triunfo': 2005,
    'Uiraúna': 2005,
    'Umbuzeiro': 2005,
    'Várzea': 2005,
    'Vieirópolis': 2005,
    'Vista Serrana': 2005,
    'Zabelê': 2005,
    'Abatiá': 2022,
    'Adrianópolis': 2022,
    'Agudos do Sul': 2022,
    'Almirante Tamandaré': 2022,
    'Altamira do Paraná': 2022,
    'Altãnia': 2022,
    'Alto Paraíso': 2022,
    'Alto Paraná': 2022,
    'Alto Piquiri': 2022,
    'Altônia': 2022,
    'Alvorada do Sul': 2022,
    'Amaporã': 2022,
    'Ampére': 2022,
    'Anahy': 2022,
    'Andirá': 2022,
    'Ângulo': 2022,
    'Antonina': 2022,
    'Antônio Olinto': 2022,
    'Apucarana': 2022,
    'Arapongas': 2022,
    'Arapoti': 2022,
    'Arapuã': 2022,
    'Araruna': 2022,
    'Araucária': 2022,
    'Ariranha do Ivaí': 2022,
    'Assaí': 2022,
    'Assis Chateaubriand': 2022,
    'Astorga': 2022,
    'Atalaia': 2022,
    'Balsa Nova': 2022,
    'Bandeirantes': 2022,
    'Barbosa Ferraz': 2022,
    'Barra do Jacaré': 2022,
    'Barracão': 2022,
    'Bela Vista da Caroba': 2022,
    'Bela Vista do Paraíso': 2022,
    'Bituruna': 2022,
    'Boa Esperança': 2022,
    'Boa Esperança do Iguaçu': 2022,
    'Boa Ventura de São Roque': 2022,
    'Boa Vista da Aparecida': 2022,
    'Bocaiúva do Sul': 2022,
    'Bom Jesus do Sul': 2022,
    'Bom Sucesso': 2022,
    'Bom Sucesso do Sul': 2022,
    'Borrazópolis': 2022,
    'Braganey': 2022,
    'Brasilândia do Sul': 2022,
    'Cafeara': 2022,
    'Cafelândia': 2022,
    'Cafezal do Sul': 2022,
    'Califórnia': 2022,
    'Cambará': 2022,
    'Cambé': 2022,
    'Cambira': 2022,
    'Campina da Lagoa': 2022,
    'Campina do Simão': 2022,
    'Campina Grande do Sul': 2022,
    'Campo Bonito': 2022,
    'Campo do Tenente': 2022,
    'Campo Largo': 2022,
    'Campo Magro': 2022,
    'Campo Mourão': 2022,
    'Cândido de Abreu': 2022,
    'Candói': 2022,
    'Cantagalo': 2022,
    'Capanema': 2022,
    'Capitão Leônidas Marques': 2022,
    'Carambeí': 2022,
    'Carlópolis': 2022,
    'Cascavel': 2022,
    'Castro': 2022,
    'Catanduvas': 2022,
    'Centenário do Sul': 2022,
    'Cerro Azul': 2022,
    'Céu Azul': 2022,
    'Chopinzinho': 2022,
    'Cianorte': 2022,
    'Cidade Gaúcha': 2022,
    'Clevelândia': 2022,
    'Colombo': 2022,
    'Colorado': 2022,
    'Congonhinhas': 2022,
    'Conselheiro Mairinck': 2022,
    'Contenda': 2022,
    'Corbélia': 2022,
    'Cornélio Procópio': 2022,
    'Coronel Domingos Soares': 2022,
    'Coronel Vivida': 2022,
    'Corumbataí do Sul': 2022,
    'Cruz Machado': 2022,
    'Cruzeiro do Iguaçu': 2022,
    'Cruzeiro do Oeste': 2022,
    'Cruzeiro do Sul': 2022,
    'Cruzmaltina': 2022,
    'Curitiba': 2022,
    'Curiúva': 2022,
    'Diamante d  Oeste': 2022,
    'Diamante do Norte': 2022,
    'Diamante do Sul': 2022,
    'Dois Vizinhos': 2022,
    'Douradina': 2022,
    'Doutor Camargo': 2022,
    'Doutor Ulysses': 2022,
    'Enéas Marques': 2022,
    'Engenheiro Beltrão': 2022,
    'Entre Rios do Oeste': 2022,
    'Esperança Nova': 2022,
    'Espigão Alto do Iguaçu': 2022,
    'Farol': 2022,
    'Faxinal': 2022,
    'Fazenda Rio Grande': 2022,
    'Fênix': 2022,
    'Fernandes Pinheiro': 2022,
    'Figueira': 2022,
    'Flor da Serra do Sul': 2022,
    'Floraí': 2022,
    'Floresta': 2022,
    'Florestópolis': 2022,
    'Flórida': 2022,
    'Formosa do Oeste': 2022,
    'Foz do Iguaçu': 2022,
    'Foz do Jordão': 2022,
    'Francisco Alves': 2022,
    'Francisco Beltrão': 2022,
    'General Carneiro': 2022,
    'Godoy Moreira': 2022,
    'Goioerê': 2022,
    'Goioxim': 2022,
    'Grandes Rios': 2022,
    'Guaíra': 2022,
    'Guairaçá': 2022,
    'Guamiranga': 2022,
    'Guapirama': 2022,
    'Guaporema': 2022,
    'Guaraci': 2022,
    'Guaraniaçu': 2022,
    'Guarapuava': 2022,
    'Guaraqueçaba': 2022,
    'Guaratuba': 2022,
    'Honório Serpa': 2022,
    'Ibaiti': 2022,
    'Ibema': 2022,
    'Ibiporã': 2022,
    'Icaraíma': 2022,
    'Iguaraçu': 2022,
    'Iguatu': 2022,
    'Imbaú': 2022,
    'Imbituva': 2022,
    'Inácio Martins': 2022,
    'Inajá': 2022,
    'Indianópolis': 2022,
    'Ipiranga': 2022,
    'Iporã': 2022,
    'Iracema do Oeste': 2022,
    'Irati': 2022,
    'Iretama': 2022,
    'Itaguajé': 2022,
    'Itaipulândia': 2022,
    'Itambaracá': 2022,
    'Itambé': 2022,
    'Itapejara d  Oeste': 2022,
    'Itaperuçu': 2022,
    'Itaúna do Sul': 2022,
    'Ivaí': 2022,
    'Ivaiporã': 2022,
    'Ivaté': 2022,
    'Ivatuba': 2022,
    'Jaboti': 2022,
    'Jacarezinho': 2022,
    'Jaguapitã': 2022,
    'Jaguariaíva': 2022,
    'Jandaia do Sul': 2022,
    'Janiópolis': 2022,
    'Japira': 2022,
    'Japurá': 2022,
    'Jardim Alegre': 2022,
    'Jardim Olinda': 2022,
    'Jataizinho': 2022,
    'Jesuítas': 2022,
    'Joaquim Távora': 2022,
    'Jundiaí do Sul': 2022,
    'Juranda': 2022,
    'Jussara': 2022,
    'Kaloré': 2022,
    'Lapa': 2022,
    'Laranjal': 2022,
    'Laranjeiras do Sul': 2022,
    'Leópolis': 2022,
    'Lidianópolis': 2022,
    'Lindoeste': 2022,
    'Loanda': 2022,
    'Lobato': 2022,
    'Londrina': 2022,
    'Luiziana': 2022,
    'Lunardelli': 2022,
    'Lupionópolis': 2022,
    'Mallet': 2022,
    'Mamborê': 2022,
    'Mandaguaçu': 2022,
    'Mandaguari': 2022,
    'Mandirituba': 2022,
    'Manfrinópolis': 2022,
    'Mangueirinha': 2022,
    'Manoel Ribas': 2022,
    'Marechal Cândido Rondon': 2022,
    'Maria Helena': 2022,
    'Marialva': 2022,
    'Marilândia do Sul': 2022,
    'Marilena': 2022,
    'Mariluz': 2022,
    'Maringá': 2022,
    'Mariópolis': 2022,
    'Maripá': 2022,
    'Marmeleiro': 2022,
    'Marquinho': 2022,
    'Marumbi': 2022,
    'Matelândia': 2022,
    'Matinhos': 2022,
    'Mato Rico': 2022,
    'Mauá da Serra': 2022,
    'Medianeira': 2022,
    'Mercedes': 2022,
    'Mirador': 2022,
    'Miraselva': 2022,
    'Missal': 2022,
    'Moreira Sales': 2022,
    'Morretes': 2022,
    'Munhoz de Melo': 2022,
    'Nossa Senhora das Graças': 2022,
    'Nova Aliança do Ivaí': 2022,
    'Nova América da Colina': 2022,
    'Nova Aurora': 2022,
    'Nova Cantu': 2022,
    'Nova Esperança': 2022,
    'Nova Esperança do Sudoeste': 2022,
    'Nova Fátima': 2022,
    'Nova Laranjeiras': 2022,
    'Nova Londrina': 2022,
    'Nova Olímpia': 2022,
    'Nova Prata do Iguaçu': 2022,
    'Nova Santa Bárbara': 2022,
    'Nova Santa Rosa': 2022,
    'Nova Tebas': 2022,
    'Novo Itacolomi': 2022,
    'Ortigueira': 2022,
    'Ourizona': 2022,
    'Ouro Verde do Oeste': 2022,
    'Paiçandu': 2022,
    'Palmas': 2022,
    'Palmeira': 2022,
    'Palmital': 2022,
    'Palotina': 2022,
    'Paraíso do Norte': 2022,
    'Paranacity': 2022,
    'Paranaguá': 2022,
    'Paranapoema': 2022,
    'Paranavaí': 2022,
    'Pato Bragado': 2022,
    'Pato Branco': 2022,
    'Paula Freitas': 2022,
    'Paulo Frontin': 2022,
    'Peabiru': 2022,
    'Perobal': 2022,
    'Pérola': 2022,
    'Pérola d  Oeste': 2022,
    'Piên': 2022,
    'Pinhais': 2022,
    'Pinhal de São Bento': 2022,
    'Pinhalão': 2022,
    'Pinhão': 2022,
    'Piraí do Sul': 2022,
    'Piraquara': 2022,
    'Pitanga': 2022,
    'Pitangueiras': 2022,
    'Planaltina do Paraná': 2022,
    'Planalto': 2022,
    'Ponta Grossa': 2022,
    'Pontal do Paraná': 2022,
    'Porecatu': 2022,
    'Porto Amazonas': 2022,
    'Porto Barreiro': 2022,
    'Porto Rico': 2022,
    'Porto Vitória': 2022,
    'Prado Ferreira': 2022,
    'Pranchita': 2022,
    'Presidente Castelo Branco': 2022,
    'Primeiro de Maio': 2022,
    'Prudentópolis': 2022,
    'Quarto Centenário': 2022,
    'Quatiguá': 2022,
    'Quatro Barras': 2022,
    'Quatro Pontes': 2022,
    'Quedas do Iguaçu': 2022,
    'Querência do Norte': 2022,
    'Quinta do Sol': 2022,
    'Quitandinha': 2022,
    'Ramilândia': 2022,
    'Rancho Alegre': 2022,
    'Rancho Alegre d  Oeste': 2022,
    'Realeza': 2022,
    'Rebouças': 2022,
    'Renascença': 2022,
    'Reserva': 2022,
    'Reserva do Iguaçu': 2022,
    'Ribeirão Claro': 2022,
    'Ribeirão do Pinhal': 2022,
    'Rio Azul': 2022,
    'Rio Bom': 2022,
    'Rio Bonito do Iguaçu': 2022,
    'Rio Branco do Ivaí': 2022,
    'Rio Branco do Sul': 2022,
    'Rio Negro': 2022,
    'Rolândia': 2022,
    'Roncador': 2022,
    'Rondon': 2022,
    'Rosário do Ivaí': 2022,
    'Sabáudia': 2022,
    'Salgado Filho': 2022,
    'Salto do Itararé': 2022,
    'Salto do Lontra': 2022,
    'Santa Amélia': 2022,
    'Santa Cecília do Pavão': 2022,
    'Santa Cruz de Monte Castelo': 2022,
    'Santa Fé': 2022,
    'Santa Helena': 2022,
    'Santa Inês': 2022,
    'Santa Isabel do Ivaí': 2022,
    'Santa Izabel do Oeste': 2022,
    'Santa Lúcia': 2022,
    'Santa Maria do Oeste': 2022,
    'Santa Mariana': 2022,
    'Santa Mônica': 2022,
    'Santa Tereza do Oeste': 2022,
    'Santa Terezinha de Itaipu': 2022,
    'Santana do Itararé': 2022,
    'Santo Antônio da Platina': 2022,
    'Santo Antônio do Caiuá': 2022,
    'Santo Antônio do Paraíso': 2022,
    'Santo Antônio do Sudoeste': 2022,
    'Santo Inácio': 2022,
    'São Carlos do Ivaí': 2022,
    'São Jerônimo da Serra': 2022,
    'São João': 2022,
    'São João do Caiuá': 2022,
    'São João do Ivaí': 2022,
    'São João do Triunfo': 2022,
    'São Jorge d  Oeste': 2022,
    'São Jorge do Ivaí': 2022,
    'São Jorge do Patrocínio': 2022,
    'São José da Boa Vista': 2022,
    'São José das Palmeiras': 2022,
    'São José dos Pinhais': 2022,
    'São Manoel do Paraná': 2022,
    'São Mateus do Sul': 2022,
    'São Miguel do Iguaçu': 2022,
    'São Pedro do Iguaçu': 2022,
    'São Pedro do Ivaí': 2022,
    'São Pedro do Paraná': 2022,
    'São Sebastião da Amoreira': 2022,
    'São Tomé': 2022,
    'Sapopema': 2022,
    'Sarandi': 2022,
    'Saudade do Iguaçu': 2022,
    'Sengés': 2022,
    'Serranópolis do Iguaçu': 2022,
    'Sertaneja': 2022,
    'Sertanópolis': 2022,
    'Siqueira Campos': 2022,
    'Sulina': 2022,
    'Tamarana': 2022,
    'Tamboara': 2022,
    'Tapejara': 2022,
    'Tapira': 2022,
    'Teixeira Soares': 2022,
    'Telêmaco Borba': 2022,
    'Terra Boa': 2022,
    'Terra Rica': 2022,
    'Terra Roxa': 2022,
    'Tibagi': 2022,
    'Tijucas do Sul': 2022,
    'Toledo': 2022,
    'Tomazina': 2022,
    'Três Barras do Paraná': 2022,
    'Tunas do Paraná': 2022,
    'Tuneiras do Oeste': 2022,
    'Tupãssi': 2022,
    'Turvo': 2022,
    'Ubiratã': 2022,
    'Umuarama': 2022,
    'União da Vitória': 2022,
    'Uniflor': 2022,
    'Uraí': 2022,
    'Ventania': 2022,
    'Vera Cruz do Oeste': 2022,
    'Verê': 2022,
    'Virmond': 2022,
    'Vitorino': 2022,
    'Wenceslau Braz': 2022,
    'Xambrê': 2022,
    'Abreu e Lima': 2006,
    'Afogados da Ingazeira': 2006,
    'Afrânio': 2006,
    'Agrestina': 2006,
    'Água Preta': 2006,
    'Águas Belas': 2006,
    'Alagoinha': 2006,
    'Aliança': 2006,
    'Altinho': 2006,
    'Amaraji': 2006,
    'Angelim': 2006,
    'Araçoiaba': 2006,
    'Araripina': 2006,
    'Arcoverde': 2006,
    'Barra de Guabiraba': 2006,
    'Barreiros': 2006,
    'Belém de Maria': 2006,
    'Belém de São Francisco': 2006,
    'Belém do São Francisco': 2006,
    'Belo Jardim': 2006,
    'Betânia': 2006,
    'Bezerros': 2006,
    'Bodocó': 2006,
    'Bom Conselho': 2006,
    'Bom Jardim': 2006,
    'Bonito': 2006,
    'Brejão': 2006,
    'Brejinho': 2006,
    'Brejo da Madre de Deus': 2006,
    'Buenos Aires': 2006,
    'Buíque': 2006,
    'Cabo': 2006,
    'Cabo de Santo Agostinho': 2006,
    'Cabrobó': 2006,
    'Cachoeirinha': 2006,
    'Caetés': 2006,
    'Calçado': 2006,
    'Calumbi': 2006,
    'Camaragibe': 2006,
    'Camocim de São Félix': 2006,
    'Camutanga': 2006,
    'Canhotinho': 2006,
    'Capoeiras': 2006,
    'Carnaíba': 2006,
    'Carnaubeira da Penha': 2006,
    'Carpina': 2006,
    'Caruaru': 2006,
    'Casinhas': 2006,
    'Catende': 2006,
    'Cedro': 2006,
    'Chã de Alegria': 2006,
    'Chã Grande': 2006,
    'Colônia Leopoldina': 2006,
    'Condado': 2006,
    'Correntes': 2006,
    'Cortês': 2006,
    'Cumaru': 2006,
    'Cupira': 2006,
    'Custódia': 2006,
    'Dormentes': 2006,
    'Escada': 2006,
    'Exu': 2006,
    'Feira Nova': 2006,
    'Fernando de Noronha': 2006,
    'Fernando de Noronha (Distrito Estadual)': 2006,
    'Ferreiros': 2006,
    'Flores': 2006,
    'Floresta': 2006,
    'Frei Miguelinho': 2006,
    'Gameleira': 2006,
    'Garanhuns': 2006,
    'Glória do Goitá': 2006,
    'Goiana': 2006,
    'Granito': 2006,
    'Gravatá': 2006,
    'Guabiraba': 2006,
    'Iati': 2006,
    'Ibimirim': 2006,
    'Ibirajuba': 2006,
    'Igarassu': 2006,
    'Iguaracy': 2006,
    'Ilha de Itamaracá': 2006,
    'Inajá': 2006,
    'Ingazeira': 2006,
    'Ipojuca': 2006,
    'Ipubi': 2006,
    'Itacuruba': 2006,
    'Itaíba': 2006,
    'Itamaracá': 2006,
    'Itambé': 2006,
    'Itapetim': 2006,
    'Itapissuma': 2006,
    'Itaquitinga': 2006,
    'Jaboatão': 2006,
    'Jaboatão dos Guararapes': 2006,
    'Jaqueira': 2006,
    'Jataúba': 2006,
    'Jatobá': 2006,
    'João Alfredo': 2006,
    'Joaquim Nabuco': 2006,
    'Jucati': 2006,
    'Jupi': 2006,
    'Jurema': 2006,
    'Lagoa de Itaenga': 2006,
    'Lagoa do Carro': 2006,
    'Lagoa do Itaenga': 2006,
    'Lagoa do Ouro': 2006,
    'Lagoa dos Gatos': 2006,
    'Lagoa Grande': 2006,
    'Lajedo': 2006,
    'Limoeiro': 2006,
    'Macaparana': 2006,
    'Machados': 2006,
    'Manari': 2006,
    'Maraial': 2006,
    'Mirandiba': 2006,
    'Moreilândia': 2006,
    'Moreno': 2006,
    'Nazaré da Mata': 2006,
    'Olinda': 2006,
    'Orobó': 2006,
    'Orocó': 2006,
    'Ouricuri': 2006,
    'Palmares': 2006,
    'Palmeirina': 2006,
    'Panelas': 2006,
    'Paranatama': 2006,
    'Parnamirim': 2006,
    'Passira': 2006,
    'Paudalho': 2006,
    'Paulista': 2006,
    'Pedra': 2006,
    'Pesqueira': 2006,
    'Petrolândia': 2006,
    'Petrolina': 2006,
    'Poção': 2006,
    'Pombos': 2006,
    'Primavera': 2006,
    'Quipapá': 2006,
    'Quixaba': 2006,
    'Recife': 2006,
    'Riacho das Almas': 2006,
    'Ribeirão': 2006,
    'Rio Formoso': 2006,
    'Sairé': 2006,
    'Salgadinho': 2006,
    'Salgueiro': 2006,
    'Saloá': 2006,
    'Sanharó': 2006,
    'Santa Cruz': 2006,
    'Santa Cruz da Baixa Verde': 2006,
    'Santa Cruz do Capibaribe': 2006,
    'Santa Filomena': 2006,
    'Santa Maria da Boa Vista': 2006,
    'Santa Maria do Cambucá': 2006,
    'Santa Terezinha': 2006,
    'São Benedito do Sul': 2006,
    'São Bento do Una': 2006,
    'São Caitano': 2006,
    'São João': 2006,
    'São Joaquim do Monte': 2006,
    'São José da Coroa Grande': 2006,
    'São José do Belmonte': 2006,
    'São José do Egito': 2006,
    'São Lourenço da Mata': 2006,
    'São Vicente Férrer': 2006,
    'Serra Talhada': 2006,
    'Serrita': 2006,
    'Sertânia': 2006,
    'Sirinhaém': 2006,
    'Solidão': 2006,
    'Surubim': 2006,
    'Tabira': 2006,
    'Tacaimbó': 2006,
    'Tacaratu': 2006,
    'Tamandaré': 2006,
    'Taquaritinga do Norte': 2006,
    'Terezinha': 2006,
    'Terra Nova': 2006,
    'Timbaúba': 2006,
    'Toritama': 2006,
    'Tracunhaém': 2006,
    'Trindade': 2006,
    'Triunfo': 2006,
    'Tupanatinga': 2006,
    'Tuparetama': 2006,
    'Venturosa': 2006,
    'Verdejante': 2006,
    'Vertente do Lério': 2006,
    'Vertentes': 2006,
    'Vicência': 2006,
    'Vitória de Santo Antão': 2006,
    'Xexéu': 2006,
    'Acauã': 2008,
    'Agricolândia': 2008,
    'Água Branca': 2008,
    'Alagoinha do Piauí': 2008,
    'Alegrete do Piauí': 2008,
    'Alto Longá': 2008,
    'Altos': 2008,
    'Alvorada do Gurguéia': 2008,
    'Amarante': 2008,
    'Angical do Piauí': 2008,
    'Anísio de Abreu': 2008,
    'Antônio Almeida': 2008,
    'Aroazes': 2008,
    'Aroeiras do Itaim': 2008,
    'Arraial': 2008,
    'Assunção do Piauí': 2008,
    'Avelino Lopes': 2008,
    'Baixa Grande do Ribeiro': 2008,
    'Barra d  Alcântara': 2008,
    'Barras': 2008,
    'Barreiras do Piauí': 2008,
    'Barro Duro': 2008,
    'Batalha': 2008,
    'Bela Vista do Piauí': 2008,
    'Belém do Piauí': 2008,
    'Beneditinos': 2008,
    'Bertolínia': 2008,
    'Betânia do Piauí': 2008,
    'Boa Hora': 2008,
    'Bocaina': 2008,
    'Bom Jesus': 2008,
    'Bom Princípio do Piauí': 2008,
    'Bonfim do Piauí': 2008,
    'Boqueirão do Piauí': 2008,
    'Brasileira': 2008,
    'Brejo do Piauí': 2008,
    'Buriti dos Lopes': 2008,
    'Buriti dos Montes': 2008,
    'Cabeceiras do Piauí': 2008,
    'Cajazeiras do Piauí': 2008,
    'Cajueiro da Praia': 2008,
    'Caldeirão Grande do Piauí': 2008,
    'Campinas do Piauí': 2008,
    'Campo Alegre do Fidalgo': 2008,
    'Campo Grande do Piauí': 2008,
    'Campo Largo do Piauí': 2008,
    'Campo Maior': 2008,
    'Canavieira': 2008,
    'Canto do Buriti': 2008,
    'Capitão de Campos': 2008,
    'Capitão Gervásio Oliveira': 2008,
    'Caracol': 2008,
    'Caraúbas do Piauí': 2008,
    'Caridade do Piauí': 2008,
    'Castelo do Piauí': 2008,
    'Caxingó': 2008,
    'Cocal': 2008,
    'Cocal de Telha': 2008,
    'Cocal dos Alves': 2008,
    'Coivaras': 2008,
    'Colônia do Gurguéia': 2008,
    'Colônia do Piauí': 2008,
    'Conceição do Canindé': 2008,
    'Coronel José Dias': 2008,
    'Corrente': 2008,
    'Cristalândia do Piauí': 2008,
    'Cristino Castro': 2008,
    'Curimatá': 2008,
    'Currais': 2008,
    'Curral Novo do Piauí': 2008,
    'Curralinhos': 2008,
    'Demerval Lobão': 2008,
    'Dirceu Arcoverde': 2008,
    'Dom Expedito Lopes': 2008,
    'Dom Inocêncio': 2008,
    'Domingos Mourão': 2008,
    'Elesbão Veloso': 2008,
    'Eliseu Martins': 2008,
    'Esperantina': 2008,
    'Fartura do Piauí': 2008,
    'Flores do Piauí': 2008,
    'Floresta do Piauí': 2008,
    'Floriano': 2008,
    'Francinópolis': 2008,
    'Francisco Ayres': 2008,
    'Francisco Macedo': 2008,
    'Francisco Santos': 2008,
    'Fronteiras': 2008,
    'Geminiano': 2008,
    'Gilbués': 2008,
    'Guadalupe': 2008,
    'Guaribas': 2008,
    'Hugo Napoleão': 2008,
    'Ilha Grande': 2008,
    'Inhuma': 2008,
    'Ipiranga do Piauí': 2008,
    'Ipueiras': 2008,
    'Isaías Coelho': 2008,
    'Itainópolis': 2008,
    'Itaueira': 2008,
    'Jacobina do Piauí': 2008,
    'Jaicós': 2008,
    'Jardim do Mulato': 2008,
    'Jatobá do Piauí': 2008,
    'Jerumenha': 2008,
    'João Costa': 2008,
    'Joaquim Pires': 2008,
    'Joca Marques': 2008,
    'José de Freitas': 2008,
    'Juazeiro do Piauí': 2008,
    'Júlio Borges': 2008,
    'Jurema': 2008,
    'Lagoa Alegre': 2008,
    'Lagoa de São Francisco': 2008,
    'Lagoa do Barro do Piauí': 2008,
    'Lagoa do Piauí': 2008,
    'Lagoa do Sítio': 2008,
    'Lagoinha do Piauí': 2008,
    'Landri Sales': 2008,
    'Luís Correia': 2008,
    'Luzilândia': 2008,
    'Madeiro': 2008,
    'Manoel Emídio': 2008,
    'Marcolândia': 2008,
    'Marcos Parente': 2008,
    'Massapê do Piauí': 2008,
    'Matias Olímpio': 2008,
    'Miguel Alves': 2008,
    'Miguel Leão': 2008,
    'Milton Brandão': 2008,
    'Monsenhor Gil': 2008,
    'Monsenhor Hipólito': 2008,
    'Monte Alegre do Piauí': 2008,
    'Morro Cabeça no Tempo': 2008,
    'Morro do Chapéu do Piauí': 2008,
    'Murici dos Portelas': 2008,
    'Nazaré do Piauí': 2008,
    'Nazária': 2008,
    'Nossa Senhora de Nazaré': 2008,
    'Nossa Senhora dos Remédios': 2008,
    'Nova Santa Rita': 2008,
    'Novo Oriente do Piauí': 2008,
    'Novo Santo Antônio': 2008,
    'Oeiras': 2008,
    'Olho d  Água do Piauí': 2008,
    'Padre Marcos': 2008,
    'Paes Landim': 2008,
    'Pajeú do Piauí': 2008,
    'Palmeira do Piauí': 2008,
    'Palmeirais': 2008,
    'Paquetá': 2008,
    'Parnaguá': 2008,
    'Parnaíba': 2008,
    'Passagem Franca do Piauí': 2008,
    'Patos do Piauí': 2008,
    'Pau D  arco do Piauí': 2008,
    'Paulistana': 2008,
    'Pavussu': 2008,
    'Pedro II': 2008,
    'Pedro Laurentino': 2008,
    'Picos': 2008,
    'Pimenteiras': 2008,
    'Pio IX': 2008,
    'Piracuruca': 2008,
    'Piripiri': 2008,
    'Porto': 2008,
    'Porto Alegre do Piauí': 2008,
    'Prata do Piauí': 2008,
    'Queimada Nova': 2008,
    'Redenção do Gurguéia': 2008,
    'Regeneração': 2008,
    'Riacho Frio': 2008,
    'Ribeira do Piauí': 2008,
    'Ribeiro Gonçalves': 2008,
    'Rio Grande do Piauí': 2008,
    'Santa Cruz do Piauí': 2008,
    'Santa Cruz dos Milagres': 2008,
    'Santa Filomena': 2008,
    'Santa Luz': 2008,
    'Santa Rosa do Piauí': 2008,
    'Santana do Piauí': 2008,
    'Santo Antônio de Lisboa': 2008,
    'Santo Antônio dos Milagres': 2008,
    'Santo Inácio do Piauí': 2008,
    'São Braz do Piauí': 2008,
    'São Félix do Piauí': 2008,
    'São Francisco de Assis do Piauí': 2008,
    'São Francisco do Piauí': 2008,
    'São Gonçalo do Gurguéia': 2008,
    'São Gonçalo do Piauí': 2008,
    'São João da Canabrava': 2008,
    'São João da Fronteira': 2008,
    'São João da Serra': 2008,
    'São João da Varjota': 2008,
    'São João do Arraial': 2008,
    'São João do Piauí': 2008,
    'São José do Divino': 2008,
    'São José do Peixe': 2008,
    'São José do Piauí': 2008,
    'São Julião': 2008,
    'São Lourenço do Piauí': 2008,
    'São Luis do Piauí': 2008,
    'São Miguel da Baixa Grande': 2008,
    'São Miguel do Fidalgo': 2008,
    'São Miguel do Tapuio': 2008,
    'São Pedro do Piauí': 2008,
    'São Raimundo Nonato': 2008,
    'Sebastião Barros': 2008,
    'Sebastião Leal': 2008,
    'Sigefredo Pacheco': 2008,
    'Simões': 2008,
    'Simplício Mendes': 2008,
    'Socorro do Piauí': 2008,
    'Sussuapara': 2008,
    'Tamboril do Piauí': 2008,
    'Tanque do Piauí': 2008,
    'Teresina': 2008,
    'União': 2008,
    'Uruçuí': 2008,
    'Valença do Piauí': 2008,
    'Várzea Branca': 2008,
    'Várzea Grande': 2008,
    'Vera Mendes': 2008,
    'Vila Nova do Piauí': 2008,
    'Wall Ferraz': 2008,
    'Angra dos Reis': 1997,
    'Aperibé': 1997,
    'Araruama': 1997,
    'Areal': 1997,
    'Armação de Búzios': 1997,
    'Armação dos Búzios': 1997,
    'Arraial do Cabo': 1997,
    'Barra do Piraí': 1997,
    'Barra Mansa': 1997,
    'Belford Roxo': 1997,
    'Bom Jardim': 1997,
    'Bom Jesus do Itabapoana': 1997,
    'Cabo Frio': 1997,
    'Cachoeiras de Macacu': 1997,
    'Cambuci': 1997,
    'Campos dos Goytacazes': 1997,
    'Cantagalo': 1997,
    'Carapebus': 1997,
    'Cardoso Moreira': 1997,
    'Carmo': 1997,
    'Casimiro de Abreu': 1997,
    'Comendador Levy Gasparian': 1997,
    'Conceição de Macabu': 1997,
    'Cordeiro': 1997,
    'Duas Barras': 1997,
    'Duque de Caxias': 1997,
    'Engenheiro Paulo de Frontin': 1997,
    'Guapimirim': 1997,
    'Iguaba Grande': 1997,
    'Ilha Grande': 1997,
    'Itaboraí': 1997,
    'Itaguaí': 1997,
    'Italva': 1997,
    'Itaocara': 1997,
    'Itaperuna': 1997,
    'Itatiaia': 1997,
    'Japeri': 1997,
    'Laje do Muriaé': 1997,
    'Macaé': 1997,
    'Macuco': 1997,
    'Magé': 1997,
    'Mangaratiba': 1997,
    'Maricá': 1997,
    'Mendes': 1997,
    'Mesquita': 1997,
    'Miguel Pereira': 1997,
    'Miracema': 1997,
    'Natividade': 1997,
    'Nilópolis': 1997,
    'Niterói': 1997,
    'Nova Friburgo': 1997,
    'Nova Iguaçu': 1997,
    'Paracambi': 1997,
    'Paraíba do Sul': 1997,
    'Paraty': 1997,
    'Paty do Alferes': 1997,
    'Petrópolis': 1997,
    'Pinheiral': 1997,
    'Piraí': 1997,
    'Porciúncula': 1997,
    'Porto Real': 1997,
    'Quatis': 1997,
    'Queimados': 1997,
    'Quissamã': 1997,
    'Resende': 1997,
    'Rio Bonito': 1997,
    'Rio Claro': 1997,
    'Rio das Flores': 1997,
    'Rio das Ostras': 1997,
    'Rio de Janeiro': 1997,
    'Santa Maria Madalena': 1997,
    'Santo Antônio de Pádua': 1997,
    'São Fidélis': 1997,
    'São Francisco de Itabapoana': 1997,
    'São Gonçalo': 1997,
    'São João da Barra': 1997,
    'São João de Meriti': 1997,
    'São José de Ubá': 1997,
    'São José do Vale do Rio Preto': 1997,
    'São Pedro': 1997,
    'São Pedro da Aldeia': 1997,
    'São Sebastião do Alto': 1997,
    'Sapucaia': 1997,
    'Saquarema': 1997,
    'Seropédica': 1997,
    'Silva Jardim': 1997,
    'Sumidouro': 1997,
    'Tanguá': 1997,
    'Teresópolis': 1997,
    'Trajano de Moraes': 1997,
    'Três Rios': 1997,
    'Valença': 1997,
    'Varre-Sai': 1997,
    'Vassouras': 1997,
    'Volta Redonda': 1997,
    'Acari': 2019,
    'Açu': 2019,
    'Afonso Bezerra': 2019,
    'Água Nova': 2019,
    'Alexandria': 2019,
    'Almino Afonso': 2019,
    'Alto do Rodrigues': 2019,
    'Angicos': 2019,
    'Antônio Martins': 2019,
    'Apodi': 2019,
    'Areia Branca': 2019,
    'Arês': 2019,
    'Augusto Severo': 2019,
    'Baía Formosa': 2019,
    'Baraúna': 2019,
    'Barcelona': 2019,
    'Bento Fernandes': 2019,
    'Bodó': 2019,
    'Bom Jesus': 2019,
    'Brejinho': 2019,
    'Caiçara do Norte': 2019,
    'Caiçara do Rio do Vento': 2019,
    'Caicó': 2019,
    'Campo Redondo': 2019,
    'Canguaretama': 2019,
    'Caraúbas': 2019,
    'Carnaúba dos Dantas': 2019,
    'Carnaubais': 2019,
    'Ceará Mirim': 2019,
    'Ceará-Mirim': 2019,
    'Cerro Corá': 2019,
    'Coronel Ezequiel': 2019,
    'Coronel João Pessoa': 2019,
    'Cruzeta': 2019,
    'Currais Novos': 2019,
    'Doutor Severiano': 2019,
    'Encanto': 2019,
    'Equador': 2019,
    'Espírito Santo': 2019,
    'Extremoz': 2019,
    'Felipe Guerra': 2019,
    'Fernando Pedroza': 2019,
    'Florânia': 2019,
    'Francisco Dantas': 2019,
    'Frutuoso Gomes': 2019,
    'Galinhos': 2019,
    'Goianinha': 2019,
    'Governador Dix Sept Rosado': 2019,
    'Governador Dix-Sept Rosado': 2019,
    'Grossos': 2019,
    'Guamaré': 2019,
    'Ielmo Marinho': 2019,
    'Ipanguaçu': 2019,
    'Ipueira': 2019,
    'Itajá': 2019,
    'Itaú': 2019,
    'Jaçanã': 2019,
    'Jandaíra': 2019,
    'Janduís': 2019,
    'Januário Cicco': 2019,
    'Japi': 2019,
    'Jardim de Angicos': 2019,
    'Jardim de Piranhas': 2019,
    'Jardim do Seridó': 2019,
    'João Câmara': 2019,
    'João Dias': 2019,
    'José da Penha': 2019,
    'Jucurutu': 2019,
    'Jundiá': 2019,
    'Lagoa d  Anta': 2019,
    'Lagoa de Pedras': 2019,
    'Lagoa de Velhos': 2019,
    'Lagoa Nova': 2019,
    'Lagoa Salgada': 2019,
    'Lajes': 2019,
    'Lajes Pintadas': 2019,
    'Lucrécia': 2019,
    'Luís Gomes': 2019,
    'Macaíba': 2019,
    'Macau': 2019,
    'Major Sales': 2019,
    'Marcelino Vieira': 2019,
    'Martins': 2019,
    'Maxaranguape': 2019,
    'Messias Targino': 2019,
    'Montanhas': 2019,
    'Monte Alegre': 2019,
    'Monte das Gameleiras': 2019,
    'Mossoró': 2019,
    'Natal': 2019,
    'Nísia Floresta': 2019,
    'Nova Cruz': 2019,
    'Olho-d  Água do Borges': 2019,
    'Ouro Branco': 2019,
    'Paraná': 2019,
    'Paraú': 2019,
    'Parazinho': 2019,
    'Parelhas': 2019,
    'Parnamirim': 2019,
    'Passa e Fica': 2019,
    'Passagem': 2019,
    'Patu': 2019,
    'Pau dos Ferros': 2019,
    'Pedra Grande': 2019,
    'Pedra Preta': 2019,
    'Pedro Avelino': 2019,
    'Pedro Velho': 2019,
    'Pendências': 2019,
    'Pilões': 2019,
    'Poço Branco': 2019,
    'Portalegre': 2019,
    'Porto do Mangue': 2019,
    'Pureza': 2019,
    'Rafael Fernandes': 2019,
    'Rafael Godeiro': 2019,
    'Riacho da Cruz': 2019,
    'Riacho de Santana': 2019,
    'Riachuelo': 2019,
    'Rio do Fogo': 2019,
    'Rodolfo Fernandes': 2019,
    'Ruy Barbosa': 2019,
    'Santa Cruz': 2019,
    'Santa Maria': 2019,
    'Santana do Matos': 2019,
    'Santana do Seridó': 2019,
    'Santo Antônio': 2019,
    'São Bento do Norte': 2019,
    'São Bento do Trairí': 2019,
    'São Fernando': 2019,
    'São Francisco do Oeste': 2019,
    'São Gonçalo do Amarante': 2019,
    'São João do Sabugi': 2019,
    'São José de Mipibu': 2019,
    'São José do Campestre': 2019,
    'São José do Seridó': 2019,
    'São Miguel': 2019,
    'São Miguel do Gostoso': 2019,
    'São Paulo do Potengi': 2019,
    'São Pedro': 2019,
    'São Rafael': 2019,
    'São Tomé': 2019,
    'São Vicente': 2019,
    'Senador Elói de Souza': 2019,
    'Senador Georgino Avelino': 2019,
    'Serra Caiada': 2019,
    'Serra de São Bento': 2019,
    'Serra do Mel': 2019,
    'Serra Negra do Norte': 2019,
    'Serrinha': 2019,
    'Serrinha dos Pintos': 2019,
    'Severiano Melo': 2019,
    'Sítio Novo': 2019,
    'Taboleiro Grande': 2019,
    'Taipu': 2019,
    'Tangará': 2019,
    'Tenente Ananias': 2019,
    'Tenente Laurentino Cruz': 2019,
    'Tibau': 2019,
    'Tibau do Sul': 2019,
    'Timbaúba dos Batistas': 2019,
    'Touros': 2019,
    'Triunfo Potiguar': 2019,
    'Umarizal': 2019,
    'Upanema': 2019,
    'Várzea': 2019,
    'Venha-Ver': 2019,
    'Vera Cruz': 2019,
    'Viçosa': 2019,
    'Vila Flor': 2019,
    'Aceguá': 2001,
    'Água Santa': 2001,
    'Agudo': 2001,
    'Ajuricaba': 2001,
    'Alecrim': 2001,
    'Alegrete': 2001,
    'Alegria': 2001,
    'Almirante Tamandaré do Sul': 2001,
    'Alpestre': 2001,
    'Alto Alegre': 2001,
    'Alto Feliz': 2001,
    'Alvorada': 2001,
    'Amaral Ferrador': 2001,
    'Ametista do Sul': 2001,
    'André da Rocha': 2001,
    'Anta Gorda': 2001,
    'Antônio Prado': 2001,
    'Arambaré': 2001,
    'Araricá': 2001,
    'Aratiba': 2001,
    'Arroio do Meio': 2001,
    'Arroio do Padre': 2001,
    'Arroio do Sal': 2001,
    'Arroio do Tigre': 2001,
    'Arroio dos Ratos': 2001,
    'Arroio Grande': 2001,
    'Arvorezinha': 2001,
    'Augusto Pestana': 2001,
    'Áurea': 2001,
    'Bagé': 2001,
    'Balneário Pinhal': 2001,
    'Barão': 2001,
    'Barão de Cotegipe': 2001,
    'Barão do Triunfo': 2001,
    'Barra do Guarita': 2001,
    'Barra do Quaraí': 2001,
    'Barra do Ribeiro': 2001,
    'Barra do Rio Azul': 2001,
    'Barra Funda': 2001,
    'Barracão': 2001,
    'Barros Cassal': 2001,
    'Benjamin Constant do Sul': 2001,
    'Bento Gonçalves': 2001,
    'Boa Vista das Missões': 2001,
    'Boa Vista do Buricá': 2001,
    'Boa Vista do Cadeado': 2001,
    'Boa Vista do Incra': 2001,
    'Boa Vista do Sul': 2001,
    'Bom Jesus': 2001,
    'Bom Princípio': 2001,
    'Bom Progresso': 2001,
    'Bom Retiro do Sul': 2001,
    'Boqueirão do Leão': 2001,
    'Bossoroca': 2001,
    'Bozano': 2001,
    'Braga': 2001,
    'Brochier': 2001,
    'Butiá': 2001,
    'Caçapava do Sul': 2001,
    'Cacequi': 2001,
    'Cachoeira do Sul': 2001,
    'Cachoeirinha': 2001,
    'Cacique Doble': 2001,
    'Caibaté': 2001,
    'Caiçara': 2001,
    'Camaquã': 2001,
    'Camargo': 2001,
    'Cambará do Sul': 2001,
    'Campestre da Serra': 2001,
    'Campina das Missões': 2001,
    'Campinas do Sul': 2001,
    'Campo Bom': 2001,
    'Campo Novo': 2001,
    'Campos Borges': 2001,
    'Candelária': 2001,
    'Cândido Godói': 2001,
    'Candiota': 2001,
    'Canela': 2001,
    'Canguçu': 2001,
    'Canoas': 2001,
    'Canudos do Vale': 2001,
    'Capão Bonito do Sul': 2001,
    'Capão da Canoa': 2001,
    'Capão do Cipó': 2001,
    'Capão do Leão': 2001,
    'Capela de Santana': 2001,
    'Capitão': 2001,
    'Capivari do Sul': 2001,
    'Caraá': 2001,
    'Carazinho': 2001,
    'Carlos Barbosa': 2001,
    'Carlos Gomes': 2001,
    'Casca': 2001,
    'Caseiros': 2001,
    'Catuípe': 2001,
    'Caxias do Sul': 2001,
    'Centenário': 2001,
    'Cerrito': 2001,
    'Cerro Branco': 2001,
    'Cerro Grande': 2001,
    'Cerro Grande do Sul': 2001,
    'Cerro Largo': 2001,
    'Chapada': 2001,
    'Charqueadas': 2001,
    'Charrua': 2001,
    'Chiapetta': 2001,
    'Chuí': 2001,
    'Chuvisca': 2001,
    'Cidreira': 2001,
    'Ciríaco': 2001,
    'Colinas': 2001,
    'Colorado': 2001,
    'Condor': 2001,
    'Constantina': 2001,
    'Coqueiro Baixo': 2001,
    'Coqueiros do Sul': 2001,
    'Coronel Barros': 2001,
    'Coronel Bicaco': 2001,
    'Coronel Pilar': 2001,
    'Cotiporã': 2001,
    'Coxilha': 2001,
    'Crissiumal': 2001,
    'Cristal': 2001,
    'Cristal do Sul': 2001,
    'Cruz Alta': 2001,
    'Cruzaltense': 2001,
    'Cruzeiro do Sul': 2001,
    'David Canabarro': 2001,
    'Derrubadas': 2001,
    'Dezesseis de Novembro': 2001,
    'Dilermando de Aguiar': 2001,
    'Dois Irmãos': 2001,
    'Dois Irmãos das Missões': 2001,
    'Dois Lajeados': 2001,
    'Dom Feliciano': 2001,
    'Dom Pedrito': 2001,
    'Dom Pedro de Alcântara': 2001,
    'Dona Francisca': 2001,
    'Doutor Maurício Cardoso': 2001,
    'Doutor Ricardo': 2001,
    'Eldorado do Sul': 2001,
    'Encantado': 2001,
    'Encruzilhada do Sul': 2001,
    'Engenho Velho': 2001,
    'Entre Rios do Sul': 2001,
    'Entre-Ijuís': 2001,
    'Erebango': 2001,
    'Erechim': 2001,
    'Ernestina': 2001,
    'Erval Grande': 2001,
    'Erval Seco': 2001,
    'Esmeralda': 2001,
    'Esperança do Sul': 2001,
    'Espumoso': 2001,
    'Estação': 2001,
    'Estância Velha': 2001,
    'Esteio': 2001,
    'Estrela': 2001,
    'Estrela Velha': 2001,
    'Eugênio de Castro': 2001,
    'Fagundes Varela': 2001,
    'Farroupilha': 2001,
    'Faxinal do Soturno': 2001,
    'Faxinalzinho': 2001,
    'Fazenda Vilanova': 2001,
    'Feliz': 2001,
    'Flores da Cunha': 2001,
    'Floriano Peixoto': 2001,
    'Fontoura Xavier': 2001,
    'Formigueiro': 2001,
    'Forquetinha': 2001,
    'Fortaleza dos Valos': 2001,
    'Frederico Westphalen': 2001,
    'Garibaldi': 2001,
    'Garruchos': 2001,
    'Gaurama': 2001,
    'General Câmara': 2001,
    'Gentil': 2001,
    'Getúlio Vargas': 2001,
    'Giruá': 2001,
    'Glorinha': 2001,
    'Gramado': 2001,
    'Gramado dos Loureiros': 2001,
    'Gramado Xavier': 2001,
    'Gravataí': 2001,
    'Guabiju': 2001,
    'Guaíba': 2001,
    'Guaporé': 2001,
    'Guarani das Missões': 2001,
    'Harmonia': 2001,
    'Herval': 2001,
    'Herveiras': 2001,
    'Horizontina': 2001,
    'Hulha Negra': 2001,
    'Humaitá': 2001,
    'Ibarama': 2001,
    'Ibiaçá': 2001,
    'Ibiraiaras': 2001,
    'Ibirapuitã': 2001,
    'Ibirubá': 2001,
    'Igrejinha': 2001,
    'Ijuí': 2001,
    'Ilópolis': 2001,
    'Imbé': 2001,
    'Imigrante': 2001,
    'Independência': 2001,
    'Inhacorá': 2001,
    'Ipê': 2001,
    'Ipiranga do Sul': 2001,
    'Iraí': 2001,
    'Itaara': 2001,
    'Itacurubi': 2001,
    'Itapuca': 2001,
    'Itaqui': 2001,
    'Itati': 2001,
    'Itatiba do Sul': 2001,
    'Ivorá': 2001,
    'Ivoti': 2001,
    'Jaboticaba': 2001,
    'Jacuizinho': 2001,
    'Jacutinga': 2001,
    'Jaguarão': 2001,
    'Jaguari': 2001,
    'Jaquirana': 2001,
    'Jari': 2001,
    'Jóia': 2001,
    'Júlio de Castilhos': 2001,
    'Lagoa Bonita do Sul': 2001,
    'Lagoa dos Três Cantos': 2001,
    'Lagoa Vermelha': 2001,
    'Lagoão': 2001,
    'Lajeado': 2001,
    'Lajeado do Bugre': 2001,
    'Lavras do Sul': 2001,
    'Liberato Salzano': 2001,
    'Lindolfo Collor': 2001,
    'Linha Nova': 2001,
    'Maçambará': 2001,
    'Machadinho': 2001,
    'Mampituba': 2001,
    'Manoel Viana': 2001,
    'Maquiné': 2001,
    'Maratá': 2001,
    'Marau': 2001,
    'Marcelino Ramos': 2001,
    'Mariana Pimentel': 2001,
    'Mariano Moro': 2001,
    'Marques de Souza': 2001,
    'Mata': 2001,
    'Mato Castelhano': 2001,
    'Mato Leitão': 2001,
    'Mato Queimado': 2001,
    'Maximiliano de Almeida': 2001,
    'Minas do Leão': 2001,
    'Miraguaí': 2001,
    'Montauri': 2001,
    'Monte Alegre dos Campos': 2001,
    'Monte Belo do Sul': 2001,
    'Montenegro': 2001,
    'Mormaço': 2001,
    'Morrinhos do Sul': 2001,
    'Morro Redondo': 2001,
    'Morro Reuter': 2001,
    'Mostardas': 2001,
    'Muçum': 2001,
    'Muitos Capões': 2001,
    'Muliterno': 2001,
    'Não Me Toque': 2001,
    'Não-Me-Toque': 2001,
    'Nicolau Vergueiro': 2001,
    'Nonoai': 2001,
    'Nova Alvorada': 2001,
    'Nova Araçá': 2001,
    'Nova Bassano': 2001,
    'Nova Boa Vista': 2001,
    'Nova Bréscia': 2001,
    'Nova Candelária': 2001,
    'Nova Esperança do Sul': 2001,
    'Nova Hartz': 2001,
    'Nova Pádua': 2001,
    'Nova Palma': 2001,
    'Nova Petrópolis': 2001,
    'Nova Prata': 2001,
    'Nova Ramada': 2001,
    'Nova Roma do Sul': 2001,
    'Nova Santa Rita': 2001,
    'Novo Barreiro': 2001,
    'Novo Cabrais': 2001,
    'Novo Hamburgo': 2001,
    'Novo Machado': 2001,
    'Novo Tiradentes': 2001,
    'Novo Xingu': 2001,
    'Osório': 2001,
    'Paim Filho': 2001,
    'Palmares do Sul': 2001,
    'Palmeira das Missões': 2001,
    'Palmitinho': 2001,
    'Panambi': 2001,
    'Pantano Grande': 2001,
    'Paraí': 2001,
    'Paraíso do Sul': 2001,
    'Pareci Novo': 2001,
    'Parobé': 2001,
    'Passa Sete': 2001,
    'Passo do Sobrado': 2001,
    'Passo Fundo': 2001,
    'Paulo Bento': 2001,
    'Paverama': 2001,
    'Pedras Altas': 2001,
    'Pedro Osório': 2001,
    'Pejuçara': 2001,
    'Pelotas': 2001,
    'Picada Café': 2001,
    'Pinhal': 2001,
    'Pinhal da Serra': 2001,
    'Pinhal Grande': 2001,
    'Pinheirinho do Vale': 2001,
    'Pinheiro Machado': 2001,
    'Pinheiros': 2001,
    'Pinto Bandeira': 2001,
    'Pirapó': 2001,
    'Piratini': 2001,
    'Planalto': 2001,
    'Poço das Antas': 2001,
    'Pontão': 2001,
    'Ponte Preta': 2001,
    'Portão': 2001,
    'Porto Alegre': 2001,
    'Porto Lucena': 2001,
    'Porto Mauá': 2001,
    'Porto Vera Cruz': 2001,
    'Porto Xavier': 2001,
    'Pouso Novo': 2001,
    'Presidente Lucena': 2001,
    'Progresso': 2001,
    'Protásio Alves': 2001,
    'Putinga': 2001,
    'Quaraí': 2001,
    'Quatro Irmãos': 2001,
    'Quevedos': 2001,
    'Quinze de Novembro': 2001,
    'Redentora': 2001,
    'Relvado': 2001,
    'Restinga Sêca': 2001,
    'Rio dos Índios': 2001,
    'Rio Grande': 2001,
    'Rio Pardo': 2001,
    'Riozinho': 2001,
    'Roca Sales': 2001,
    'Rodeio Bonito': 2001,
    'Rolador': 2001,
    'Rolante': 2001,
    'Ronda Alta': 2001,
    'Rondinha': 2001,
    'Roque Gonzales': 2001,
    'Rosário do Sul': 2001,
    'Sagrada Família': 2001,
    'Saldanha Marinho': 2001,
    'Salto do Jacuí': 2001,
    'Salvador das Missões': 2001,
    'Salvador do Sul': 2001,
    'Sananduva': 2001,
    'Sant  Ana do Livramento': 2001,
    'Santa Bárbara do Sul': 2001,
    'Santa Cecília do Sul': 2001,
    'Santa Clara do Sul': 2001,
    'Santa Cruz do Sul': 2001,
    'Santa Margarida do Sul': 2001,
    'Santa Maria': 2001,
    'Santa Maria do Herval': 2001,
    'Santa Rosa': 2001,
    'Santa Tereza': 2001,
    'Santa Vitória do Palmar': 2001,
    'Santana da Boa Vista': 2001,
    'Santana do Livramento': 2001,
    'Santiago': 2001,
    'Santo Ângelo': 2001,
    'Santo Antônio da Patrulha': 2001,
    'Santo Antônio das Missões': 2001,
    'Santo Antônio do Palma': 2001,
    'Santo Antônio do Planalto': 2001,
    'Santo Augusto': 2001,
    'Santo Cristo': 2001,
    'Santo Expedito do Sul': 2001,
    'São Borja': 2001,
    'São Domingos do Sul': 2001,
    'São Francisco de Assis': 2001,
    'São Francisco de Paula': 2001,
    'São Gabriel': 2001,
    'São Jerônimo': 2001,
    'São João da Urtiga': 2001,
    'São João do Polêsine': 2001,
    'São Jorge': 2001,
    'São José das Missões': 2001,
    'São José do Herval': 2001,
    'São José do Hortêncio': 2001,
    'São José do Inhacorá': 2001,
    'São José do Norte': 2001,
    'São José do Ouro': 2001,
    'São José do Sul': 2001,
    'São José dos Ausentes': 2001,
    'São Leopoldo': 2001,
    'São Lourenço do Sul': 2001,
    'São Luiz Gonzaga': 2001,
    'São Marcos': 2001,
    'São Martinho': 2001,
    'São Martinho da Serra': 2001,
    'São Miguel das Missões': 2001,
    'São Nicolau': 2001,
    'São Paulo das Missões': 2001,
    'São Pedro da Serra': 2001,
    'São Pedro das Missões': 2001,
    'São Pedro do Butiá': 2001,
    'São Pedro do Sul': 2001,
    'São Sebastião do Caí': 2001,
    'São Sepé': 2001,
    'São Valentim': 2001,
    'São Valentim do Sul': 2001,
    'São Valério do Sul': 2001,
    'São Vendelino': 2001,
    'São Vicente do Sul': 2001,
    'Sapiranga': 2001,
    'Sapucaia': 2001,
    'Sapucaia do Sul': 2001,
    'Sarandi': 2001,
    'Seberi': 2001,
    'Sede Nova': 2001,
    'Segredo': 2001,
    'Selbach': 2001,
    'Senador Salgado Filho': 2001,
    'Sentinela do Sul': 2001,
    'Serafina Corrêa': 2001,
    'Sério': 2001,
    'Sertão': 2001,
    'Sertão Santana': 2001,
    'Sete de Setembro': 2001,
    'Severiano de Almeida': 2001,
    'Silveira Martins': 2001,
    'Sinimbu': 2001,
    'Sobradinho': 2001,
    'Soledade': 2001,
    'Tabaí': 2001,
    'Tapejara': 2001,
    'Tapera': 2001,
    'Tapes': 2001,
    'Taquara': 2001,
    'Taquari': 2001,
    'Taquaruçu do Sul': 2001,
    'Tavares': 2001,
    'Tenente Portela': 2001,
    'Terra de Areia': 2001,
    'Teutônia': 2001,
    'Tio Hugo': 2001,
    'Tiradentes do Sul': 2001,
    'Toropi': 2001,
    'Torres': 2001,
    'Tramandaí': 2001,
    'Travesseiro': 2001,
    'Três Arroios': 2001,
    'Três Cachoeiras': 2001,
    'Três Coroas': 2001,
    'Três de Maio': 2001,
    'Três Forquilhas': 2001,
    'Três Palmeiras': 2001,
    'Três Passos': 2001,
    'Trindade do Sul': 2001,
    'Triunfo': 2001,
    'Tucunduva': 2001,
    'Tunas': 2001,
    'Tupanci do Sul': 2001,
    'Tupanciretã': 2001,
    'Tupandi': 2001,
    'Tuparendi': 2001,
    'Turuçu': 2001,
    'Ubiretama': 2001,
    'União da Serra': 2001,
    'Unistalda': 2001,
    'Uruguaiana': 2001,
    'Vacaria': 2001,
    'Vale do Sol': 2001,
    'Vale Real': 2001,
    'Vale Verde': 2001,
    'Vanini': 2001,
    'Venâncio Aires': 2001,
    'Vera Cruz': 2001,
    'Veranópolis': 2001,
    'Vespasiano Corrêa': 2001,
    'Viadutos': 2001,
    'Viamão': 2001,
    'Vicente Dutra': 2001,
    'Victor Graeff': 2001,
    'Vila Flores': 2001,
    'Vila Lângaro': 2001,
    'Vila Maria': 2001,
    'Vila Nova do Sul': 2001,
    'Vista Alegre': 2001,
    'Vista Alegre do Prata': 2001,
    'Vista Gaúcha': 2001,
    'Vitória das Missões': 2001,
    'Westfália': 2001,
    'Xangri-lá': 2001,
    'Alta Floresta d  Oeste': 2013,
    'Alto Alegre dos Parecis': 2013,
    'Alto Paraíso': 2013,
    'Alvorada d  Oeste': 2013,
    'Ariquemes': 2013,
    'Buritis': 2013,
    'Cabixi': 2013,
    'Cacaulândia': 2013,
    'Cacoal': 2013,
    'Campo Novo de Rondônia': 2013,
    'Candeias do Jamari': 2013,
    'Castanheiras': 2013,
    'Cerejeiras': 2013,
    'Chupinguaia': 2013,
    'Colorado do Oeste': 2013,
    'Corumbiara': 2013,
    'Costa Marques': 2013,
    'Cujubim': 2013,
    'Espigão d  Oeste': 2013,
    'Extrema': 2013,
    'Governador Jorge Teixeira': 2013,
    'Guajará Mirim': 2013,
    'Guajará-Mirim': 2013,
    'Itapuã do Oeste': 2013,
    'Jaru': 2013,
    'Ji Paraná': 2013,
    'Ji-Paraná': 2013,
    'Machadinho d  Oeste': 2013,
    'Ministro Andreazza': 2013,
    'Mirante da Serra': 2013,
    'Monte Negro': 2013,
    'Nova Brasilândia d  Oeste': 2013,
    'Nova Mamoré': 2013,
    'Nova União': 2013,
    'Novo Horizonte do Oeste': 2013,
    'Ouro Preto do Oeste': 2013,
    'Parecis': 2013,
    'Pimenta Bueno': 2013,
    'Pimenteiras do Oeste': 2013,
    'Porto Velho': 2013,
    'Pôsto Fiscal Rolim de Moura': 2013,
    'Presidente Médici': 2013,
    'Primavera de Rondônia': 2013,
    'Rio Crespo': 2013,
    'Rolim de Moura': 2013,
    'Santa Luzia d  Oeste': 2013,
    'São Felipe d  Oeste': 2013,
    'São Francisco do Guaporé': 2013,
    'São Miguel do Guaporé': 2013,
    'Seringueiras': 2013,
    'Teixeirópolis': 2013,
    'Theobroma': 2013,
    'Urupá': 2013,
    'Vale do Anari': 2013,
    'Vale do Paraíso': 2013,
    'Vilhena': 2013,
    'Amajari': 4858,
    'Boa Vista': 4858,
    'Bonfim': 4858,
    'Cantá': 4858,
    'Caracaraí': 4858,
    'Caroebe': 4858,
    'Iracema': 4858,
    'Mucajaí': 4858,
    'Normandia': 4858,
    'Pacaraima': 4858,
    'Rorainópolis': 4858,
    'São João da Baliza': 4858,
    'São Luís': 4858,
    'Uiramutã': 4858,
    'Abdon Batista': 2014,
    'Abelardo Luz': 2014,
    'Agrolândia': 2014,
    'Agronômica': 2014,
    'Água Doce': 2014,
    'Águas de Chapecó': 2014,
    'Águas Frias': 2014,
    'Águas Mornas': 2014,
    'Alfredo Wagner': 2014,
    'Alto Bela Vista': 2014,
    'Anchieta': 2014,
    'Angelina': 2014,
    'Anita Garibaldi': 2014,
    'Anitápolis': 2014,
    'Antônio Carlos': 2014,
    'Apiúna': 2014,
    'Arabutã': 2014,
    'Araquari': 2014,
    'Araranguá': 2014,
    'Armação': 2014,
    'Armazém': 2014,
    'Arroio Trinta': 2014,
    'Arvoredo': 2014,
    'Ascurra': 2014,
    'Atalanta': 2014,
    'Aurora': 2014,
    'Balneário Arroio do Silva': 2014,
    'Balneário Barra do Sul': 2014,
    'Balneário Camboriú': 2014,
    'Balneário Gaivota': 2014,
    'Balneário Piçarras': 2014,
    'Balneário Rincão': 2014,
    'Bandeirante': 2014,
    'Barra Bonita': 2014,
    'Barra Velha': 2014,
    'Bela Vista do Toldo': 2014,
    'Belmonte': 2014,
    'Benedito Novo': 2014,
    'Biguaçu': 2014,
    'Blumenau': 2014,
    'Bocaina do Sul': 2014,
    'Bom Jardim da Serra': 2014,
    'Bom Jesus': 2014,
    'Bom Jesus do Oeste': 2014,
    'Bom Retiro': 2014,
    'Bombinhas': 2014,
    'Botuverá': 2014,
    'Braço do Norte': 2014,
    'Braço do Trombudo': 2014,
    'Brunópolis': 2014,
    'Brusque': 2014,
    'Caçador': 2014,
    'Caibi': 2014,
    'Calmon': 2014,
    'Camboriú': 2014,
    'Campinas': 2014,
    'Campo Alegre': 2014,
    'Campo Belo do Sul': 2014,
    'Campo Erê': 2014,
    'Campos Novos': 2014,
    'Canelinha': 2014,
    'Canoinhas': 2014,
    'Capão Alto': 2014,
    'Capinzal': 2014,
    'Capivari de Baixo': 2014,
    'Carianos': 2014,
    'Carvoeira': 2014,
    'Catanduvas': 2014,
    'Caxambu do Sul': 2014,
    'Celso Ramos': 2014,
    'Cerro Negro': 2014,
    'Chapadão do Lageado': 2014,
    'Chapecó': 2014,
    'Cocal': 2014,
    'Cocal do Sul': 2014,
    'Concórdia': 2014,
    'Cordilheira Alta': 2014,
    'Coronel Freitas': 2014,
    'Coronel Martins': 2014,
    'Corrego Grande': 2014,
    'Correia Pinto': 2014,
    'Corupá': 2014,
    'Costeira do Pirajubae': 2014,
    'Criciúma': 2014,
    'Cunha Porã': 2014,
    'Cunhataí': 2014,
    'Curitibanos': 2014,
    'Descanso': 2014,
    'Dionísio Cerqueira': 2014,
    'Dona Emma': 2014,
    'Doutor Pedrinho': 2014,
    'Entre Rios': 2014,
    'Ermo': 2014,
    'Erval Velho': 2014,
    'Faxinal dos Guedes': 2014,
    'Flor do Sertão': 2014,
    'Florianópolis': 2014,
    'Formosa do Sul': 2014,
    'Forquilhinha': 2014,
    'Fraiburgo': 2014,
    'Freguesia do Ribeirao da Ilha': 2014,
    'Frei Rogério': 2014,
    'Galvão': 2014,
    'Garopaba': 2014,
    'Garuva': 2014,
    'Gaspar': 2014,
    'Governador Celso Ramos': 2014,
    'Grão Pará': 2014,
    'Gravatal': 2014,
    'Guabiruba': 2014,
    'Guaraciaba': 2014,
    'Guaramirim': 2014,
    'Guarujá do Sul': 2014,
    'Guatambú': 2014,
    'Herval d  Oeste': 2014,
    'Ibiam': 2014,
    'Ibicaré': 2014,
    'Ibirama': 2014,
    'Içara': 2014,
    'Ilhota': 2014,
    'Imaruí': 2014,
    'Imbituba': 2014,
    'Imbuia': 2014,
    'Indaial': 2014,
    'Iomerê': 2014,
    'Ipira': 2014,
    'Iporã do Oeste': 2014,
    'Ipuaçu': 2014,
    'Ipumirim': 2014,
    'Iraceminha': 2014,
    'Irani': 2014,
    'Irati': 2014,
    'Irineópolis': 2014,
    'Itá': 2014,
    'Itacorubi': 2014,
    'Itaiópolis': 2014,
    'Itajaí': 2014,
    'Itapema': 2014,
    'Itapiranga': 2014,
    'Itapoá': 2014,
    'Ituporanga': 2014,
    'Jaborá': 2014,
    'Jacinto Machado': 2014,
    'Jaguaruna': 2014,
    'Jaraguá do Sul': 2014,
    'Jardinópolis': 2014,
    'Joaçaba': 2014,
    'Joinville': 2014,
    'José Boiteux': 2014,
    'Jupiá': 2014,
    'Lacerdópolis': 2014,
    'Lages': 2014,
    'Lagoa': 2014,
    'Laguna': 2014,
    'Lajeado Grande': 2014,
    'Laurentino': 2014,
    'Lauro Muller': 2014,
    'Lebon Régis': 2014,
    'Leoberto Leal': 2014,
    'Lindóia do Sul': 2014,
    'Lontras': 2014,
    'Luiz Alves': 2014,
    'Luzerna': 2014,
    'Macieira': 2014,
    'Mafra': 2014,
    'Major Gercino': 2014,
    'Major Vieira': 2014,
    'Maracajá': 2014,
    'Maravilha': 2014,
    'Marema': 2014,
    'Massaranduba': 2014,
    'Matos Costa': 2014,
    'Meleiro': 2014,
    'Mirim Doce': 2014,
    'Modelo': 2014,
    'Mondaí': 2014,
    'Monte Carlo': 2014,
    'Monte Castelo': 2014,
    'Morro da Cruz': 2014,
    'Morro da Fumaça': 2014,
    'Morro Grande': 2014,
    'Navegantes': 2014,
    'Nova Erechim': 2014,
    'Nova Itaberaba': 2014,
    'Nova Trento': 2014,
    'Nova Veneza': 2014,
    'Novo Horizonte': 2014,
    'Orleans': 2014,
    'Otacílio Costa': 2014,
    'Ouro': 2014,
    'Ouro Verde': 2014,
    'Paial': 2014,
    'Painel': 2014,
    'Palhoça': 2014,
    'Palma Sola': 2014,
    'Palmeira': 2014,
    'Palmitos': 2014,
    'Pantanal': 2014,
    'Pantano do Sul': 2014,
    'Papanduva': 2014,
    'Paraíso': 2014,
    'Passo de Torres': 2014,
    'Passos Maia': 2014,
    'Paulo Lopes': 2014,
    'Pedras Grandes': 2014,
    'Penha': 2014,
    'Peritiba': 2014,
    'Petrolândia': 2014,
    'Pinhalzinho': 2014,
    'Pinheiro Preto': 2014,
    'Piratuba': 2014,
    'Planalto Alegre': 2014,
    'Pomerode': 2014,
    'Ponte Alta': 2014,
    'Ponte Alta do Norte': 2014,
    'Ponte Serrada': 2014,
    'Porto Belo': 2014,
    'Porto União': 2014,
    'Pouso Redondo': 2014,
    'Praia Grande': 2014,
    'Presidente Castello Branco': 2014,
    'Presidente Getúlio': 2014,
    'Presidente Nereu': 2014,
    'Princesa': 2014,
    'Quilombo': 2014,
    'Rancho Queimado': 2014,
    'Residencia Moacir PU5BHV': 2014,
    'Ribeirão da Ilha': 2014,
    'Rio das Antas': 2014,
    'Rio do Campo': 2014,
    'Rio do Oeste': 2014,
    'Rio do Sul': 2014,
    'Rio dos Cedros': 2014,
    'Rio Fortuna': 2014,
    'Rio Negrinho': 2014,
    'Rio Rufino': 2014,
    'Rio Tavares': 2014,
    'Riqueza': 2014,
    'Rodeio': 2014,
    'Romelândia': 2014,
    'Saco dos Limoes': 2014,
    'Salete': 2014,
    'Saltinho': 2014,
    'Salto Veloso': 2014,
    'Sangão': 2014,
    'Santa Cecília': 2014,
    'Santa Helena': 2014,
    'Santa Monica': 2014,
    'Santa Rosa de Lima': 2014,
    'Santa Rosa do Sul': 2014,
    'Santa Terezinha': 2014,
    'Santa Terezinha do Progresso': 2014,
    'Santiago do Sul': 2014,
    'Santo Amaro da Imperatriz': 2014,
    'São Bento do Sul': 2014,
    'São Bernardino': 2014,
    'São Bonifácio': 2014,
    'São Carlos': 2014,
    'São Cristóvão do Sul': 2014,
    'São Domingos': 2014,
    'São Francisco do Sul': 2014,
    'São João Batista': 2014,
    'São João do Itaperiú': 2014,
    'São João do Oeste': 2014,
    'São João do Sul': 2014,
    'São Joaquim': 2014,
    'São José': 2014,
    'São José do Cedro': 2014,
    'São José do Cerrito': 2014,
    'São Lourenço do Oeste': 2014,
    'São Ludgero': 2014,
    'São Martinho': 2014,
    'São Miguel da Boa Vista': 2014,
    'São Miguel do Oeste': 2014,
    'São Pedro de Alcântara': 2014,
    'Saudades': 2014,
    'Schroeder': 2014,
    'Seara': 2014,
    'Serra Alta': 2014,
    'Siderópolis': 2014,
    'Sombrio': 2014,
    'Sul Brasil': 2014,
    'Taió': 2014,
    'Tangará': 2014,
    'Tapera': 2014,
    'Tigrinhos': 2014,
    'Tijucas': 2014,
    'Timbé do Sul': 2014,
    'Timbó': 2014,
    'Timbó Grande': 2014,
    'Três Barras': 2014,
    'Treviso': 2014,
    'Treze de Maio': 2014,
    'Treze Tílias': 2014,
    'Trindade': 2014,
    'Trombudo Central': 2014,
    'Tubarão': 2014,
    'Tunápolis': 2014,
    'Turvo': 2014,
    'União do Oeste': 2014,
    'Urubici': 2014,
    'Urupema': 2014,
    'Urussanga': 2014,
    'Vargeão': 2014,
    'Vargem': 2014,
    'Vargem Bonita': 2014,
    'Vidal Ramos': 2014,
    'Videira': 2014,
    'Vitor Meireles': 2014,
    'Witmarsum': 2014,
    'Xanxerê': 2014,
    'Xavantina': 2014,
    'Xaxim': 2014,
    'Zortéa': 2014,
    'Adamantina': 2021,
    'Adolfo': 2021,
    'Aguaí': 2021,
    'Águas da Prata': 2021,
    'Águas de Lindóia': 2021,
    'Águas de Santa Bárbara': 2021,
    'Águas de São Pedro': 2021,
    'Agudos': 2021,
    'Alambari': 2021,
    'Alfredo Marcondes': 2021,
    'Altair': 2021,
    'Altinópolis': 2021,
    'Alto Alegre': 2021,
    'Alumínio': 2021,
    'Álvares Florence': 2021,
    'Álvares Machado': 2021,
    'Álvaro de Carvalho': 2021,
    'Alvinlândia': 2021,
    'Americana': 2021,
    'Américo Brasiliense': 2021,
    'Américo de Campos': 2021,
    'Amparo': 2021,
    'Analândia': 2021,
    'Andradina': 2021,
    'Angatuba': 2021,
    'Anhembi': 2021,
    'Anhumas': 2021,
    'Aparecida': 2021,
    'Aparecida d  Oeste': 2021,
    'Apiaí': 2021,
    'Araçariguama': 2021,
    'Araçatuba': 2021,
    'Araçoiaba da Serra': 2021,
    'Aramina': 2021,
    'Arandu': 2021,
    'Arapeí': 2021,
    'Araraquara': 2021,
    'Araras': 2021,
    'Arco-Íris': 2021,
    'Arealva': 2021,
    'Areias': 2021,
    'Areiópolis': 2021,
    'Ariranha': 2021,
    'Artur Nogueira': 2021,
    'Arujá': 2021,
    'Aspásia': 2021,
    'Assis': 2021,
    'Atibaia': 2021,
    'Auriflama': 2021,
    'Avaí': 2021,
    'Avanhandava': 2021,
    'Avaré': 2021,
    'Bady Bassitt': 2021,
    'Balbinos': 2021,
    'Bálsamo': 2021,
    'Bananal': 2021,
    'Barão de Antonina': 2021,
    'Barbosa': 2021,
    'Bariri': 2021,
    'Barra Bonita': 2021,
    'Barra do Chapéu': 2021,
    'Barra do Turvo': 2021,
    'Barretos': 2021,
    'Barrinha': 2021,
    'Barueri': 2021,
    'Bastos': 2021,
    'Batatais': 2021,
    'Bauru': 2021,
    'Bebedouro': 2021,
    'Bento de Abreu': 2021,
    'Bernardino de Campos': 2021,
    'Bertioga': 2021,
    'Bilac': 2021,
    'Birigui': 2021,
    'Biritiba Mirim': 2021,
    'Biritiba-Mirim': 2021,
    'Boa Esperança do Sul': 2021,
    'Bocaina': 2021,
    'Bofete': 2021,
    'Boituva': 2021,
    'Bom Jesus dos Perdões': 2021,
    'Bom Sucesso de Itararé': 2021,
    'Borá': 2021,
    'Boracéia': 2021,
    'Borborema': 2021,
    'Borebi': 2021,
    'Botucatu': 2021,
    'Bragança Paulista': 2021,
    'Braúna': 2021,
    'Brejo Alegre': 2021,
    'Brodósqui': 2021,
    'Brodowski': 2021,
    'Brotas': 2021,
    'Buri': 2021,
    'Buritama': 2021,
    'Buritizal': 2021,
    'Cabrália Paulista': 2021,
    'Cabreúva': 2021,
    'Caçapava': 2021,
    'Cachoeira Paulista': 2021,
    'Caconde': 2021,
    'Cafelândia': 2021,
    'Caiabu': 2021,
    'Caieiras': 2021,
    'Caiuá': 2021,
    'Cajamar': 2021,
    'Cajati': 2021,
    'Cajobi': 2021,
    'Cajuru': 2021,
    'Campina do Monte Alegre': 2021,
    'Campinas': 2021,
    'Campo Limpo Paulista': 2021,
    'Campos do Jordão': 2021,
    'Campos Novos Paulista': 2021,
    'Cananéia': 2021,
    'Canas': 2021,
    'Cândido Mota': 2021,
    'Cândido Rodrigues': 2021,
    'Canitar': 2021,
    'Capâo Bonito': 2021,
    'Capela do Alto': 2021,
    'Capivari': 2021,
    'Caraguatatuba': 2021,
    'Carapicuíba': 2021,
    'Cardoso': 2021,
    'Casa Branca': 2021,
    'Cássia dos Coqueiros': 2021,
    'Castilho': 2021,
    'Catanduva': 2021,
    'Catiguá': 2021,
    'Cedral': 2021,
    'Cerqueira César': 2021,
    'Cerquilho': 2021,
    'Cesário Lange': 2021,
    'Charqueada': 2021,
    'Chavantes': 2021,
    'Clementina': 2021,
    'Colina': 2021,
    'Colômbia': 2021,
    'Conchal': 2021,
    'Conchas': 2021,
    'Cordeirópolis': 2021,
    'Coroados': 2021,
    'Coronel Macedo': 2021,
    'Corumbataí': 2021,
    'Cosmópolis': 2021,
    'Cosmorama': 2021,
    'Cotia': 2021,
    'Cravinhos': 2021,
    'Cristais Paulista': 2021,
    'Cruzália': 2021,
    'Cruzeiro': 2021,
    'Cubatão': 2021,
    'Cunha': 2021,
    'Descalvado': 2021,
    'Diadema': 2021,
    'Dirce Reis': 2021,
    'Divinolândia': 2021,
    'Dobrada': 2021,
    'Dois Córregos': 2021,
    'Dolcinópolis': 2021,
    'Dourado': 2021,
    'Dracena': 2021,
    'Duartina': 2021,
    'Dumont': 2021,
    'Echaporã': 2021,
    'Eldorado': 2021,
    'Elias Fausto': 2021,
    'Elisiário': 2021,
    'Embaúba': 2021,
    'Embu': 2021,
    'Embu das Artes': 2021,
    'Embu Guaçu': 2021,
    'Embu-Guaçu': 2021,
    'Emilianópolis': 2021,
    'Engenheiro Coelho': 2021,
    'Espírito Santo do Pinhal': 2021,
    'Espírito Santo do Turvo': 2021,
    'Estiva Gerbi': 2021,
    'Estrela d  Oeste': 2021,
    'Estrela do Norte': 2021,
    'Euclides da Cunha Paulista': 2021,
    'Fartura': 2021,
    'Fernando Prestes': 2021,
    'Fernandópolis': 2021,
    'Fernão': 2021,
    'Ferraz de Vasconcelos': 2021,
    'Flora Rica': 2021,
    'Floreal': 2021,
    'Flórida Paulista': 2021,
    'Florínea': 2021,
    'Franca': 2021,
    'Francisco Morato': 2021,
    'Franco da Rocha': 2021,
    'Gabriel Monteiro': 2021,
    'Gália': 2021,
    'Garça': 2021,
    'Gastão Vidigal': 2021,
    'Gavião Peixoto': 2021,
    'General Salgado': 2021,
    'Getulina': 2021,
    'Glicério': 2021,
    'Guaiçara': 2021,
    'Guaimbê': 2021,
    'Guaíra': 2021,
    'Guapiaçu': 2021,
    'Guapiara': 2021,
    'Guará': 2021,
    'Guaraçaí': 2021,
    'Guaraci': 2021,
    'Guarani d  Oeste': 2021,
    'Guarantã': 2021,
    'Guararapes': 2021,
    'Guararema': 2021,
    'Guaratinguetá': 2021,
    'Guareí': 2021,
    'Guariba': 2021,
    'Guarujá': 2021,
    'Guarulhos': 2021,
    'Guatapará': 2021,
    'Guzolândia': 2021,
    'Herculândia': 2021,
    'Holambra': 2021,
    'Hortolândia': 2021,
    'Iacanga': 2021,
    'Iacri': 2021,
    'Iaras': 2021,
    'Ibaté': 2021,
    'Ibirá': 2021,
    'Ibirarema': 2021,
    'Ibitinga': 2021,
    'Ibiúna': 2021,
    'Icém': 2021,
    'Iepê': 2021,
    'Igaraçu do Tietê': 2021,
    'Igarapava': 2021,
    'Igaratá': 2021,
    'Iguape': 2021,
    'Ilha Comprida': 2021,
    'Ilha Solteira': 2021,
    'Ilhabela': 2021,
    'Indaiatuba': 2021,
    'Indiana': 2021,
    'Indiaporã': 2021,
    'Inúbia Paulista': 2021,
    'Ipauçu': 2021,
    'Ipaussu': 2021,
    'Iperó': 2021,
    'Ipeúna': 2021,
    'Ipiguá': 2021,
    'Iporanga': 2021,
    'Ipuã': 2021,
    'Iracemápolis': 2021,
    'Irapuã': 2021,
    'Irapuru': 2021,
    'Itaberá': 2021,
    'Itaí': 2021,
    'Itajobi': 2021,
    'Itaju': 2021,
    'Itanhaém': 2021,
    'Itaoca': 2021,
    'Itapecerica da Serra': 2021,
    'Itapetininga': 2021,
    'Itapeva': 2021,
    'Itapevi': 2021,
    'Itapira': 2021,
    'Itapirapuã Paulista': 2021,
    'Itápolis': 2021,
    'Itaporanga': 2021,
    'Itapuí': 2021,
    'Itapura': 2021,
    'Itaquaquecetuba': 2021,
    'Itararé': 2021,
    'Itariri': 2021,
    'Itatiba': 2021,
    'Itatinga': 2021,
    'Itirapina': 2021,
    'Itirapuã': 2021,
    'Itobi': 2021,
    'Itu': 2021,
    'Itupeva': 2021,
    'Ituverava': 2021,
    'Jaborandi': 2021,
    'Jaboticabal': 2021,
    'Jacareí': 2021,
    'Jaci': 2021,
    'Jacupiranga': 2021,
    'Jaguariúna': 2021,
    'Jales': 2021,
    'Jambeiro': 2021,
    'Jandira': 2021,
    'Jardim Paulista': 2021,
    'Jardinópolis': 2021,
    'Jarinu': 2021,
    'Jaú': 2021,
    'Jeriquara': 2021,
    'Joanópolis': 2021,
    'João Ramalho': 2021,
    'José Bonifácio': 2021,
    'Júlio Mesquita': 2021,
    'Jumirim': 2021,
    'Jundiaí': 2021,
    'Junqueirópolis': 2021,
    'Juquiá': 2021,
    'Juquitiba': 2021,
    'Lagoinha': 2021,
    'Laranjal Paulista': 2021,
    'Lavínia': 2021,
    'Lavrinhas': 2021,
    'Leme': 2021,
    'Lençóis Paulista': 2021,
    'Limeira': 2021,
    'Lindóia': 2021,
    'Lins': 2021,
    'Lorena': 2021,
    'Lourdes': 2021,
    'Louveira': 2021,
    'Lucélia': 2021,
    'Lucianópolis': 2021,
    'Luís Antônio': 2021,
    'Luiziânia': 2021,
    'Lupércio': 2021,
    'Lutécia': 2021,
    'Macatuba': 2021,
    'Macaubal': 2021,
    'Macedônia': 2021,
    'Magda': 2021,
    'Mairinque': 2021,
    'Mairiporã': 2021,
    'Manduri': 2021,
    'Marabá Paulista': 2021,
    'Maracaí': 2021,
    'Marapoama': 2021,
    'Mariápolis': 2021,
    'Marília': 2021,
    'Marinópolis': 2021,
    'Martinópolis': 2021,
    'Matão': 2021,
    'Mauá': 2021,
    'Mendonça': 2021,
    'Meridiano': 2021,
    'Mesópolis': 2021,
    'Miguelópolis': 2021,
    'Mineiros do Tietê': 2021,
    'Mira Estrela': 2021,
    'Miracatu': 2021,
    'Mirandopólis': 2021,
    'Mirante do Paranapanema': 2021,
    'Mirassol': 2021,
    'Mirassolândia': 2021,
    'Mococa': 2021,
    'Mogi das Cruzes': 2021,
    'Mogi Guaçu': 2021,
    'Mogi Mirim': 2021,
    'Mombuca': 2021,
    'Monções': 2021,
    'Mongaguá': 2021,
    'Monte Alegre do Sul': 2021,
    'Monte Alto': 2021,
    'Monte Aprazível': 2021,
    'Monte Azul Paulista': 2021,
    'Monte Castelo': 2021,
    'Monte Mor': 2021,
    'Monteiro Lobato': 2021,
    'Morro Agudo': 2021,
    'Morungaba': 2021,
    'Motuca': 2021,
    'Murutinga do Sul': 2021,
    'Nantes': 2021,
    'Narandiba': 2021,
    'Natividade da Serra': 2021,
    'Nazaré Paulista': 2021,
    'Neves Paulista': 2021,
    'Nhandeara': 2021,
    'Nipoã': 2021,
    'Nova Aliança': 2021,
    'Nova Campina': 2021,
    'Nova Canaã Paulista': 2021,
    'Nova Castilho': 2021,
    'Nova Europa': 2021,
    'Nova Granada': 2021,
    'Nova Guataporanga': 2021,
    'Nova Independência': 2021,
    'Nova Luzitânia': 2021,
    'Nova Odessa': 2021,
    'Novais': 2021,
    'Novo Horizonte': 2021,
    'Nuporanga': 2021,
    'Ocauçu': 2021,
    'Óleo': 2021,
    'Olímpia': 2021,
    'Onda Verde': 2021,
    'Oriente': 2021,
    'Orindiúva': 2021,
    'Orlândia': 2021,
    'Osasco': 2021,
    'Oscar Bressane': 2021,
    'Osvaldo Cruz': 2021,
    'Ourinhos': 2021,
    'Ouro Verde': 2021,
    'Ouroeste': 2021,
    'Pacaembu': 2021,
    'Palestina': 2021,
    'Palmares Paulista': 2021,
    'Palmeira d  Oeste': 2021,
    'Palmital': 2021,
    'Panorama': 2021,
    'Paraguaçu Paulista': 2021,
    'Paraibuna': 2021,
    'Paraíso': 2021,
    'Paranapanema': 2021,
    'Paranapuã': 2021,
    'Parapuã': 2021,
    'Pardinho': 2021,
    'Pariquera Açu': 2021,
    'Pariquera-Açu': 2021,
    'Parisi': 2021,
    'Patrocínio Paulista': 2021,
    'Paulicéia': 2021,
    'Paulínia': 2021,
    'Paulista Flórida': 2021,
    'Paulistânia': 2021,
    'Paulo de Faria': 2021,
    'Pederneiras': 2021,
    'Pedra Bela': 2021,
    'Pedranópolis': 2021,
    'Pedregulho': 2021,
    'Pedreira': 2021,
    'Pedrinhas Paulista': 2021,
    'Pedro de Toledo': 2021,
    'Penápolis': 2021,
    'Pereira Barreto': 2021,
    'Pereiras': 2021,
    'Peruíbe': 2021,
    'Piacatu': 2021,
    'Piedade': 2021,
    'Pilar do Sul': 2021,
    'Pindamonhangaba': 2021,
    'Pindorama': 2021,
    'Pinhalzinho': 2021,
    'Piquerobi': 2021,
    'Piquete': 2021,
    'Piracaia': 2021,
    'Piracicaba': 2021,
    'Piraju': 2021,
    'Pirajuí': 2021,
    'Pirangi': 2021,
    'Pirapora do Bom Jesus': 2021,
    'Pirapozinho': 2021,
    'Pirassununga': 2021,
    'Piratininga': 2021,
    'Pitangueiras': 2021,
    'Planalto': 2021,
    'Platina': 2021,
    'Poá': 2021,
    'Poloni': 2021,
    'Pompéia': 2021,
    'Pongaí': 2021,
    'Pontal': 2021,
    'Pontalinda': 2021,
    'Pontes Gestal': 2021,
    'Populina': 2021,
    'Porangaba': 2021,
    'Porto Feliz': 2021,
    'Porto Ferreira': 2021,
    'Potim': 2021,
    'Potirendaba': 2021,
    'Pracinha': 2021,
    'Pradópolis': 2021,
    'Praia Grande': 2021,
    'Pratânia': 2021,
    'Presidente Alves': 2021,
    'Presidente Bernardes': 2021,
    'Presidente Epitácio': 2021,
    'Presidente Prudente': 2021,
    'Presidente Venceslau': 2021,
    'Promissão': 2021,
    'Quadra': 2021,
    'Quatá': 2021,
    'Queiroz': 2021,
    'Queluz': 2021,
    'Quintana': 2021,
    'Rafard': 2021,
    'Rancharia': 2021,
    'Redenção da Serra': 2021,
    'Regente Feijó': 2021,
    'Reginópolis': 2021,
    'Registro': 2021,
    'Restinga': 2021,
    'Ribeira': 2021,
    'Ribeirão Bonito': 2021,
    'Ribeirão Branco': 2021,
    'Ribeirão Corrente': 2021,
    'Ribeirão do Sul': 2021,
    'Ribeirão dos Índios': 2021,
    'Ribeirão Grande': 2021,
    'Ribeirão Pires': 2021,
    'Ribeirão Preto': 2021,
    'Rifaina': 2021,
    'Rincão': 2021,
    'Rinópolis': 2021,
    'Rio Claro': 2021,
    'Rio das Pedras': 2021,
    'Rio Grande da Serra': 2021,
    'Riolândia': 2021,
    'Riversul': 2021,
    'Rosana': 2021,
    'Roseira': 2021,
    'Rubiácea': 2021,
    'Rubinéia': 2021,
    'Sabino': 2021,
    'Sagres': 2021,
    'Sales': 2021,
    'Sales Oliveira': 2021,
    'Salesópolis': 2021,
    'Salmourão': 2021,
    'Saltinho': 2021,
    'Salto': 2021,
    'Salto de Pirapora': 2021,
    'Salto Grande': 2021,
    'Sandovalina': 2021,
    'Santa Adélia': 2021,
    'Santa Albertina': 2021,
    'Santa Bárbara d  Oeste': 2021,
    'Santa Branca': 2021,
    'Santa Clara d  Oeste': 2021,
    'Santa Cruz da Conceição': 2021,
    'Santa Cruz da Esperança': 2021,
    'Santa Cruz das Palmeiras': 2021,
    'Santa Cruz do Rio Pardo': 2021,
    'Santa Ernestina': 2021,
    'Santa Fé do Sul': 2021,
    'Santa Gertrudes': 2021,
    'Santa Isabel': 2021,
    'Santa Lúcia': 2021,
    'Santa Maria da Serra': 2021,
    'Santa Mercedes': 2021,
    'Santa Rita d  Oeste': 2021,
    'Santa Rita do Passa Quatro': 2021,
    'Santa Rosa de Viterbo': 2021,
    'Santa Salete': 2021,
    'Santana da Ponte Pensa': 2021,
    'Santana de Parnaíba': 2021,
    'Santo Anastácio': 2021,
    'Santo André': 2021,
    'Santo Antônio da Alegria': 2021,
    'Santo Antônio de Posse': 2021,
    'Santo Antônio do Aracanguá': 2021,
    'Santo Antônio do Jardim': 2021,
    'Santo Antônio do Pinhal': 2021,
    'Santo Expedito': 2021,
    'Santópolis do Aguapeí': 2021,
    'Santos': 2021,
    'São Bento do Sapucaí': 2021,
    'São Bernardo do Campo': 2021,
    'São Caetano do Sul': 2021,
    'São Carlos': 2021,
    'São Francisco': 2021,
    'São João da Boa Vista': 2021,
    'São João das Duas Pontes': 2021,
    'São João de Iracema': 2021,
    'São João do Pau d  Alho': 2021,
    'São Joaquim da Barra': 2021,
    'São José da Bela Vista': 2021,
    'São José do Barreiro': 2021,
    'São José do Rio Pardo': 2021,
    'São José do Rio Preto': 2021,
    'São José dos Campos': 2021,
    'São Lourenço da Serra': 2021,
    'São Luiz do Paraitinga': 2021,
    'São Manuel': 2021,
    'São Miguel Arcanjo': 2021,
    'São Paulo': 2021,
    'São Pedro': 2021,
    'São Pedro do Turvo': 2021,
    'São Roque': 2021,
    'São Sebastião': 2021,
    'São Sebastião da Grama': 2021,
    'São Simão': 2021,
    'São Vicente': 2021,
    'Sarapuí': 2021,
    'Sarutaiá': 2021,
    'Sebastianópolis do Sul': 2021,
    'Serra Azul': 2021,
    'Serra Negra': 2021,
    'Serrana': 2021,
    'Sertãozinho': 2021,
    'Sete Barras': 2021,
    'Severínia': 2021,
    'Silveiras': 2021,
    'Socorro': 2021,
    'Sorocaba': 2021,
    'Sud Mennucci': 2021,
    'Sumaré': 2021,
    'Suzanápolis': 2021,
    'Suzano': 2021,
    'Tabapuã': 2021,
    'Tabatinga': 2021,
    'Taboão da Serra': 2021,
    'Taciba': 2021,
    'Taguaí': 2021,
    'Taiaçu': 2021,
    'Taiúva': 2021,
    'Tambaú': 2021,
    'Tanabi': 2021,
    'Tapiraí': 2021,
    'Tapiratiba': 2021,
    'Taquaral': 2021,
    'Taquaritinga': 2021,
    'Taquarituba': 2021,
    'Taquarivaí': 2021,
    'Tarabai': 2021,
    'Tarumã': 2021,
    'Tatuí': 2021,
    'Taubaté': 2021,
    'Tejupá': 2021,
    'Teodoro Sampaio': 2021,
    'Terra Roxa': 2021,
    'Tietê': 2021,
    'Timburi': 2021,
    'Torre de Pedra': 2021,
    'Torrinha': 2021,
    'Trabiju': 2021,
    'Tremembé': 2021,
    'Três Fronteiras': 2021,
    'Tuiuti': 2021,
    'Tupã': 2021,
    'Tupi Paulista': 2021,
    'Turiúba': 2021,
    'Turmalina': 2021,
    'Ubarana': 2021,
    'Ubatuba': 2021,
    'Ubirajara': 2021,
    'Uchoa': 2021,
    'União Paulista': 2021,
    'Urânia': 2021,
    'Uru': 2021,
    'Urupês': 2021,
    'Valentim Gentil': 2021,
    'Valinhos': 2021,
    'Valparaíso': 2021,
    'Vargem': 2021,
    'Vargem Grande do Sul': 2021,
    'Vargem Grande Paulista': 2021,
    'Várzea Paulista': 2021,
    'Vera Cruz': 2021,
    'Vinhedo': 2021,
    'Viradouro': 2021,
    'Vista Alegre do Alto': 2021,
    'Vitória Brasil': 2021,
    'Votorantim': 2021,
    'Votuporanga': 2021,
    'Zacarias': 2021,
    'Amparo de São Francisco': 2003,
    'Aquidabã': 2003,
    'Aracaju': 2003,
    'Arauá': 2003,
    'Areia Branca': 2003,
    'Barra dos Coqueiros': 2003,
    'Boquim': 2003,
    'Brejo Grande': 2003,
    'Campo do Brito': 2003,
    'Canhoba': 2003,
    'Canindé de São Francisco': 2003,
    'Capela': 2003,
    'Carira': 2003,
    'Carmópolis': 2003,
    'Cedro de São João': 2003,
    'Cristinápolis': 2003,
    'Cumbe': 2003,
    'Divina Pastora': 2003,
    'Estância': 2003,
    'Feira Nova': 2003,
    'Frei Paulo': 2003,
    'Gararu': 2003,
    'General Maynard': 2003,
    'Gracho Cardoso': 2003,
    'Ilha das Flores': 2003,
    'Indiaroba': 2003,
    'Itabaiana': 2003,
    'Itabaianinha': 2003,
    'Itabi': 2003,
    'Itaporanga d  Ajuda': 2003,
    'Japaratuba': 2003,
    'Japoatã': 2003,
    'Lagarto': 2003,
    'Laranjeiras': 2003,
    'Macambira': 2003,
    'Malhada dos Bois': 2003,
    'Malhador': 2003,
    'Maruim': 2003,
    'Moita Bonita': 2003,
    'Monte Alegre de Sergipe': 2003,
    'Muribeca': 2003,
    'Neópolis': 2003,
    'Nossa Senhora Aparecida': 2003,
    'Nossa Senhora da Glória': 2003,
    'Nossa Senhora das Dores': 2003,
    'Nossa Senhora de Lourdes': 2003,
    'Nossa Senhora do Socorro': 2003,
    'Pacatuba': 2003,
    'Pedra Mole': 2003,
    'Pedrinhas': 2003,
    'Pinhão': 2003,
    'Pirambu': 2003,
    'Poço Redondo': 2003,
    'Poço Verde': 2003,
    'Porto da Folha': 2003,
    'Propriá': 2003,
    'Riachão do Dantas': 2003,
    'Riachuelo': 2003,
    'Ribeirópolis': 2003,
    'Rosário do Catete': 2003,
    'Salgado': 2003,
    'Santa Luzia do Itanhy': 2003,
    'Santa Rosa de Lima': 2003,
    'Santana do São Francisco': 2003,
    'Santo Amaro das Brotas': 2003,
    'São Cristóvão': 2003,
    'São Domingos': 2003,
    'São Francisco': 2003,
    'São Miguel do Aleixo': 2003,
    'Simão Dias': 2003,
    'Siriri': 2003,
    'Telha': 2003,
    'Tobias Barreto': 2003,
    'Tomar do Geru': 2003,
    'Umbaúba': 2003,
    'Abreulândia': 2020,
    'Aguiarnópolis': 2020,
    'Aliança do Tocantins': 2020,
    'Almas': 2020,
    'Alvorada': 2020,
    'Ananás': 2020,
    'Angico': 2020,
    'Aparecida do Rio Negro': 2020,
    'Aragominas': 2020,
    'Araguacema': 2020,
    'Araguaçu': 2020,
    'Araguaína': 2020,
    'Araguanã': 2020,
    'Araguatins': 2020,
    'Arapoema': 2020,
    'Arraias': 2020,
    'Augustinópolis': 2020,
    'Aurora do Tocantins': 2020,
    'Axixá do Tocantins': 2020,
    'Babaçulândia': 2020,
    'Bandeirantes do Tocantins': 2020,
    'Barra do Ouro': 2020,
    'Barrolândia': 2020,
    'Bernardo Sayão': 2020,
    'Bom Jesus do Tocantins': 2020,
    'Brasilândia do Tocantins': 2020,
    'Brejinho de Nazaré': 2020,
    'Buriti do Tocantins': 2020,
    'Cachoeirinha': 2020,
    'Campos Lindos': 2020,
    'Cariri do Tocantins': 2020,
    'Carmolândia': 2020,
    'Carrasco Bonito': 2020,
    'Caseara': 2020,
    'Centenário': 2020,
    'Chapada da Natividade': 2020,
    'Chapada de Areia': 2020,
    'Colinas do Tocantins': 2020,
    'Colméia': 2020,
    'Combinado': 2020,
    'Conceição do Tocantins': 2020,
    'Couto Magalhães': 2020,
    'Cristalândia': 2020,
    'Crixás do Tocantins': 2020,
    'Darcinópolis': 2020,
    'Dianópolis': 2020,
    'Divinópolis do Tocantins': 2020,
    'Dois Irmãos do Tocantins': 2020,
    'Dueré': 2020,
    'Esperantina': 2020,
    'Fátima': 2020,
    'Figueirópolis': 2020,
    'Filadélfia': 2020,
    'Formoso do Araguaia': 2020,
    'Fortaleza do Tabocão': 2020,
    'Goianorte': 2020,
    'Goiatins': 2020,
    'Guaraí': 2020,
    'Gurupi': 2020,
    'Ipueiras': 2020,
    'Itacajá': 2020,
    'Itaguatins': 2020,
    'Itapiratins': 2020,
    'Itaporã do Tocantins': 2020,
    'Jaú do Tocantins': 2020,
    'Juarina': 2020,
    'Lagoa da Confusão': 2020,
    'Lagoa do Tocantins': 2020,
    'Lajeado': 2020,
    'Lavandeira': 2020,
    'Lizarda': 2020,
    'Luzinópolis': 2020,
    'Marianópolis do Tocantins': 2020,
    'Mateiros': 2020,
    'Maurilândia do Tocantins': 2020,
    'Miracema do Tocantins': 2020,
    'Miranorte': 2020,
    'Monte do Carmo': 2020,
    'Monte Santo do Tocantins': 2020,
    'Muricilândia': 2020,
    'Natividade': 2020,
    'Nazaré': 2020,
    'Nova Olinda': 2020,
    'Nova Rosalândia': 2020,
    'Novo Acordo': 2020,
    'Novo Alegre': 2020,
    'Novo Jardim': 2020,
    'Oliveira de Fátima': 2020,
    'Palmas': 2020,
    'Palmeirante': 2020,
    'Palmeiras do Tocantins': 2020,
    'Palmeirópolis': 2020,
    'Paraíso do Tocantins': 2020,
    'Paranã': 2020,
    'Pau d  Arco': 2020,
    'Pedro Afonso': 2020,
    'Peixe': 2020,
    'Pequizeiro': 2020,
    'Pindorama do Tocantins': 2020,
    'Piraquê': 2020,
    'Pium': 2020,
    'Ponte Alta do Bom Jesus': 2020,
    'Ponte Alta do Tocantins': 2020,
    'Porto Alegre do Tocantins': 2020,
    'Porto Nacional': 2020,
    'Praia Norte': 2020,
    'Presidente Kennedy': 2020,
    'Pugmil': 2020,
    'Recursolândia': 2020,
    'Riachinho': 2020,
    'Rio da Conceição': 2020,
    'Rio dos Bois': 2020,
    'Rio Sono': 2020,
    'Sampaio': 2020,
    'Sandolândia': 2020,
    'Santa Fé do Araguaia': 2020,
    'Santa Maria do Tocantins': 2020,
    'Santa Rita do Tocantins': 2020,
    'Santa Rosa do Tocantins': 2020,
    'Santa Tereza do Tocantins': 2020,
    'Santa Terezinha do Tocantins': 2020,
    'São Bento do Tocantins': 2020,
    'São Félix do Tocantins': 2020,
    'São Miguel do Tocantins': 2020,
    'São Salvador do Tocantins': 2020,
    'São Sebastião do Tocantins': 2020,
    'São Valério': 2020,
    'Silvanópolis': 2020,
    'Sítio Novo do Tocantins': 2020,
    'Sucupira': 2020,
    'Taguatinga': 2020,
    'Taipas do Tocantins': 2020,
    'Talismã': 2020,
    'Tocantínia': 2020,
    'Tocantinópolis': 2020,
    'Tupirama': 2020,
    'Tupiratins': 2020,
    'Wanderlândia': 2020,
    'Xambioá': 2020,
    'Kuala Belait': 1217,
    'Seria': 1217,
    'Bandar Seri Begawan': 1216,
    'Berakas A': 1216,
    'Kapok': 1216,
    'Mentiri': 1216,
    'Serasa': 1216,
    'Bangar': 1218,
    'Tutong': 1219,
    'Bansko': 4699,
    'Belitsa': 4699,
    'Blagoevgrad': 4699,
    'Garmen': 4699,
    'Gotse Delchev': 4699,
    'Hadzhidimovo': 4699,
    'Kolarovo': 4699,
    'Kresna': 4699,
    'Obshtina Bansko': 4699,
    'Obshtina Belitsa': 4699,
    'Obshtina Blagoevgrad': 4699,
    'Obshtina Garmen': 4699,
    'Obshtina Gotse Delchev': 4699,
    'Obshtina Kresna': 4699,
    'Obshtina Petrich': 4699,
    'Obshtina Razlog': 4699,
    'Obshtina Sandanski': 4699,
    'Obshtina Satovcha': 4699,
    'Obshtina Simitli': 4699,
    'Obshtina Strumyani': 4699,
    'Obshtina Yakoruda': 4699,
    'Petrich': 4699,
    'Razlog': 4699,
    'Sandanski': 4699,
    'Satovcha': 4699,
    'Simitli': 4699,
    'Stara Kresna': 4699,
    'Strumyani': 4699,
    'Yakoruda': 4699,
    'Aheloy': 4715,
    'Ahtopol': 4715,
    'Aytos': 4715,
    'Bata': 4715,
    'Burgas': 4715,
    'Chernomorets': 4715,
    'Kameno': 4715,
    'Karnobat': 4715,
    'Kiten': 4715,
    'Malko Tarnovo': 4715,
    'Nesebar': 4715,
    'Obshtina Aytos': 4715,
    'Obshtina Burgas': 4715,
    'Obshtina Kameno': 4715,
    'Obshtina Karnobat': 4715,
    'Obshtina Malko Tarnovo': 4715,
    'Obshtina Nesebar': 4715,
    'Obshtina Pomorie': 4715,
    'Obshtina Primorsko': 4715,
    'Obshtina Sozopol': 4715,
    'Obshtina Sungurlare': 4715,
    'Obzor': 4715,
    'Pomorie': 4715,
    'Primorsko': 4715,
    'Ravda': 4715,
    'Ruen': 4715,
    'Sarafovo': 4715,
    'Sozopol': 4715,
    'Sredets': 4715,
    'Sungurlare': 4715,
    'Sveti Vlas': 4715,
    'Tsarevo': 4715,
    'Balchik': 4718,
    'Dobrich': 4718,
    'General Toshevo': 4718,
    'Kavarna': 4718,
    'Krushari': 4718,
    'Obshtina Balchik': 4718,
    'Obshtina Dobrich': 4718,
    'Obshtina Dobrich-Selska': 4718,
    'Obshtina General Toshevo': 4718,
    'Obshtina Kavarna': 4718,
    'Obshtina Krushari': 4718,
    'Obshtina Shabla': 4718,
    'Obshtina Tervel': 4718,
    'Shabla': 4718,
    'Tervel': 4718,
    'Dryanovo': 4693,
    'Gabrovo': 4693,
    'Obshtina Dryanovo': 4693,
    'Obshtina Gabrovo': 4693,
    'Obshtina Sevlievo': 4693,
    'Obshtina Tryavna': 4693,
    'Sevlievo': 4693,
    'Tryavna': 4693,
    'Dimitrovgrad': 4704,
    'Harmanli': 4704,
    'Haskovo': 4704,
    'Ivaylovgrad': 4704,
    'Lyubimets': 4704,
    'Madzharovo': 4704,
    'Mineralni Bani': 4704,
    'Obshtina Dimitrovgrad': 4704,
    'Obshtina Harmanli': 4704,
    'Obshtina Haskovo': 4704,
    'Obshtina Ivaylovgrad': 4704,
    'Obshtina Madzharovo': 4704,
    'Obshtina Mineralni Bani': 4704,
    'Obshtina Stambolovo': 4704,
    'Obshtina Svilengrad': 4704,
    'Obshtina Topolovgrad': 4704,
    'Simeonovgrad': 4704,
    'Svilengrad': 4704,
    'Topolovgrad': 4704,
    'Ardino': 4702,
    'Dzhebel': 4702,
    'Kardzhali': 4702,
    'Kirkovo': 4702,
    'Krumovgrad': 4702,
    'Obshtina Ardino': 4702,
    'Obshtina Chernoochene': 4702,
    'Obshtina Dzhebel': 4702,
    'Obshtina Kardzhali': 4702,
    'Obshtina Kirkovo': 4702,
    'Obshtina Momchilgrad': 4702,
    'Boboshevo': 4703,
    'Bobov Dol': 4703,
    'Dupnitsa': 4703,
    'Kocherinovo': 4703,
    'Kyustendil': 4703,
    'Nevestino': 4703,
    'Obshtina Boboshevo': 4703,
    'Obshtina Bobov Dol': 4703,
    'Obshtina Dupnitsa': 4703,
    'Obshtina Kocherinovo': 4703,
    'Obshtina Kyustendil': 4703,
    'Obshtina Nevestino': 4703,
    'Obshtina Rila': 4703,
    'Obshtina Sapareva Banya': 4703,
    'Obshtina Treklyano': 4703,
    'Rila': 4703,
    'Sapareva Banya': 4703,
    'Apriltsi': 4710,
    'Letnitsa': 4710,
    'Lovech': 4710,
    'Lukovit': 4710,
    'Obshtina Lovech': 4710,
    'Obshtina Teteven': 4710,
    'Obshtina Ugarchin': 4710,
    'Teteven': 4710,
    'Troyan': 4710,
    'Ugarchin': 4710,
    'Yablanitsa': 4710,
    'Berkovitsa': 4696,
    'Boychinovtsi': 4696,
    'Brusartsi': 4696,
    'Chiprovtsi': 4696,
    'Lom': 4696,
    'Medkovets': 4696,
    'Montana': 4696,
    'Obshtina Boychinovtsi': 4696,
    'Obshtina Chiprovtsi': 4696,
    'Obshtina Georgi Damyanovo': 4696,
    'Obshtina Lom': 4696,
    'Obshtina Montana': 4696,
    'Obshtina Valchedram': 4696,
    'Obshtina Varshets': 4696,
    'Obshtina Yakimovo': 4696,
    'Valchedram': 4696,
    'Varshets': 4696,
    'Yakimovo': 4696,
    'Batak': 4712,
    'Belovo': 4712,
    'Bratsigovo': 4712,
    'Lesichovo': 4712,
    'Obshtina Batak': 4712,
    'Obshtina Belovo': 4712,
    'Obshtina Bratsigovo': 4712,
    'Obshtina Lesichovo': 4712,
    'Obshtina Panagyurishte': 4712,
    'Obshtina Pazardzhik': 4712,
    'Obshtina Peshtera': 4712,
    'Obshtina Rakitovo': 4712,
    'Obshtina Septemvri': 4712,
    'Obshtina Strelcha': 4712,
    'Obshtina Velingrad': 4712,
    'Panagyurishte': 4712,
    'Pazardzhik': 4712,
    'Peshtera': 4712,
    'Rakitovo': 4712,
    'Sarnitsa': 4712,
    'Sarnitsa Obshtina': 4712,
    'Septemvri': 4712,
    'Strelcha': 4712,
    'Velingrad': 4712,
    'Batanovtsi': 4695,
    'Breznik': 4695,
    'Obshtina Kovachevtsi': 4695,
    'Obshtina Pernik': 4695,
    'Obshtina Radomir': 4695,
    'Obshtina Zemen': 4695,
    'Pernik': 4695,
    'Radomir': 4695,
    'Tran': 4695,
    'Zemen': 4695,
    'Belene': 4706,
    'Cherven Bryag': 4706,
    'Dolna Mitropolia': 4706,
    'Dolni Dabnik': 4706,
    'Gulyantsi': 4706,
    'Iskar': 4706,
    'Knezha': 4706,
    'Koynare': 4706,
    'Levski': 4706,
    'Nikopol': 4706,
    'Obshtina Belene': 4706,
    'Obshtina Cherven Bryag': 4706,
    'Obshtina Dolna Mitropolia': 4706,
    'Obshtina Dolni Dabnik': 4706,
    'Obshtina Gulyantsi': 4706,
    'Obshtina Iskar': 4706,
    'Obshtina Knezha': 4706,
    'Obshtina Levski': 4706,
    'Obshtina Nikopol': 4706,
    'Obshtina Pleven': 4706,
    'Obshtina Pordim': 4706,
    'Pleven': 4706,
    'Pordim': 4706,
    'Slavyanovo': 4706,
    'Asenovgrad': 4701,
    'Brezovo': 4701,
    'Hisarya': 4701,
    'Kalofer': 4701,
    'Kaloyanovo': 4701,
    'Karlovo': 4701,
    'Klisura': 4701,
    'Krichim': 4701,
    'Laki': 4701,
    'Obshtina Asenovgrad': 4701,
    'Obshtina Hisarya': 4701,
    'Obshtina Kaloyanovo': 4701,
    'Obshtina Karlovo': 4701,
    'Obshtina Krichim': 4701,
    'Obshtina Kuklen': 4701,
    'Obshtina Laki': 4701,
    'Obshtina Maritsa': 4701,
    'Obshtina Parvomay': 4701,
    'Obshtina Perushtitsa': 4701,
    'Obshtina Plovdiv': 4701,
    'Obshtina Rakovski': 4701,
    'Obshtina Rodopi': 4701,
    'Obshtina Sadovo': 4701,
    'Obshtina Saedinenie': 4701,
    'Obshtina Sopot': 4701,
    'Obshtina Stamboliyski': 4701,
    'Parvomay': 4701,
    'Perushtitsa': 4701,
    'Plovdiv': 4701,
    'Rakovski': 4701,
    'Sadovo': 4701,
    'Saedinenie': 4701,
    'Stamboliyski': 4701,
    'Topolovo': 4701,
    'Isperih': 4698,
    'Kubrat': 4698,
    'Loznitsa': 4698,
    'Medovene': 4698,
    'Obshtina Isperih': 4698,
    'Obshtina Kubrat': 4698,
    'Obshtina Loznitsa': 4698,
    'Obshtina Razgrad': 4698,
    'Obshtina Samuil': 4698,
    'Obshtina Tsar Kaloyan': 4698,
    'Obshtina Zavet': 4698,
    'Razgrad': 4698,
    'Samuil': 4698,
    'Tsar Kaloyan': 4698,
    'Zavet': 4698,
    'Borovo': 4713,
    'Dve Mogili': 4713,
    'Ivanovo': 4713,
    'Obshtina Borovo': 4713,
    'Obshtina Byala': 4713,
    'Obshtina Dve Mogili': 4713,
    'Obshtina Ivanovo': 4713,
    'Obshtina Ruse': 4713,
    'Obshtina Slivo Pole': 4713,
    'Obshtina Tsenovo': 4713,
    'Obshtina Vetovo': 4713,
    'Ruse': 4713,
    'Senovo': 4713,
    'Slivo Pole': 4713,
    'Tsenovo': 4713,
    'Vetovo': 4713,
    'Shumen': 4882,
    'Alfatar': 4708,
    'Dulovo': 4708,
    'Glavinitsa': 4708,
    'Kaynardzha': 4708,
    'Obshtina Alfatar': 4708,
    'Obshtina Dulovo': 4708,
    'Obshtina Glavinitsa': 4708,
    'Obshtina Kaynardzha': 4708,
    'Obshtina Silistra': 4708,
    'Obshtina Sitovo': 4708,
    'Obshtina Tutrakan': 4708,
    'Silistra': 4708,
    'Sitovo': 4708,
    'Tutrakan': 4708,
    'Kermen': 4700,
    'Kotel': 4700,
    'Nova Zagora': 4700,
    'Obshtina Kotel': 4700,
    'Obshtina Nova Zagora': 4700,
    'Obshtina Sliven': 4700,
    'Obshtina Tvarditsa': 4700,
    'Sliven': 4700,
    'Tvarditsa': 4700,
    'Banite': 4694,
    'Borino': 4694,
    'Chepelare': 4694,
    'Devin': 4694,
    'Dospat': 4694,
    'Gyovren': 4694,
    'Madan': 4694,
    'Nedelino': 4694,
    'Obshtina Banite': 4694,
    'Obshtina Borino': 4694,
    'Obshtina Chepelare': 4694,
    'Obshtina Devin': 4694,
    'Obshtina Dospat': 4694,
    'Obshtina Madan': 4694,
    'Obshtina Nedelino': 4694,
    'Obshtina Rudozem': 4694,
    'Obshtina Smolyan': 4694,
    'Obshtina Zlatograd': 4694,
    'Rudozem': 4694,
    'Smolyan': 4694,
    'Zlatograd': 4694,
    'Buhovo': 4705,
    'Sofia': 4705,
    'Stolichna Obshtina': 4705,
    'Anton': 4719,
    'Botevgrad': 4719,
    'Bozhurishte': 4719,
    'Chavdar': 4719,
    'Chelopech': 4719,
    'Dolna Banya': 4719,
    'Dragoman': 4719,
    'Elin Pelin': 4719,
    'Etropole': 4719,
    'Godech': 4719,
    'Gorna Malina': 4719,
    'Ihtiman': 4719,
    'Koprivshtitsa': 4719,
    'Kostinbrod': 4719,
    'Lakatnik': 4719,
    'Mirkovo': 4719,
    'Obshtina Anton': 4719,
    'Obshtina Botevgrad': 4719,
    'Obshtina Bozhurishte': 4719,
    'Obshtina Chavdar': 4719,
    'Obshtina Chelopech': 4719,
    'Obshtina Dolna Banya': 4719,
    'Obshtina Dragoman': 4719,
    'Obshtina Elin Pelin': 4719,
    'Obshtina Etropole': 4719,
    'Obshtina Gorna Malina': 4719,
    'Obshtina Koprivshtitsa': 4719,
    'Obshtina Kostenets': 4719,
    'Obshtina Kostinbrod': 4719,
    'Obshtina Mirkovo': 4719,
    'Obshtina Pirdop': 4719,
    'Obshtina Pravets': 4719,
    'Obshtina Samokov': 4719,
    'Obshtina Slivnitsa': 4719,
    'Obshtina Svoge': 4719,
    'Obshtina Zlatitsa': 4719,
    'Pirdop': 4719,
    'Pravets': 4719,
    'Samokov': 4719,
    'Slivnitsa': 4719,
    'Svoge': 4719,
    'Zlatitsa': 4719,
    'Asen': 4707,
    'Chirpan': 4707,
    'Gŭlŭbovo': 4707,
    'Gurkovo': 4707,
    'Kazanlak': 4707,
    'Maglizh': 4707,
    'Nikolaevo': 4707,
    'Obshtina Bratya Daskalovi': 4707,
    'Obshtina Chirpan': 4707,
    'Obshtina Galabovo': 4707,
    'Obshtina Gurkovo': 4707,
    'Obshtina Kazanlŭk': 4707,
    'Obshtina Maglizh': 4707,
    'Obshtina Nikolaevo': 4707,
    'Obshtina Opan': 4707,
    'Obshtina Pavel Banya': 4707,
    'Obshtina Radnevo': 4707,
    'Obshtina Stara Zagora': 4707,
    'Pavel Banya': 4707,
    'Radnevo': 4707,
    'Shipka': 4707,
    'Stara Zagora': 4707,
    'Antonovo': 4714,
    'Obshtina Antonovo': 4714,
    'Obshtina Omurtag': 4714,
    'Obshtina Opaka': 4714,
    'Obshtina Popovo': 4714,
    'Obshtina Targovishte': 4714,
    'Omurtag': 4714,
    'Opaka': 4714,
    'Popovo': 4714,
    'Targovishte': 4714,
    'Aksakovo': 4717,
    'Asparuhovo': 4717,
    'Balgarevo': 4717,
    'Beloslav': 4717,
    'Byala': 4717,
    'Dalgopol': 4717,
    'Devnya': 4717,
    'Dolni Chiflik': 4717,
    'Kiten': 4717,
    'Obshtina Aksakovo': 4717,
    'Obshtina Avren': 4717,
    'Obshtina Beloslav': 4717,
    'Obshtina Byala': 4717,
    'Obshtina Dalgopol': 4717,
    'Obshtina Devnya': 4717,
    'Obshtina Dolni Chiflik': 4717,
    'Obshtina Provadia': 4717,
    'Obshtina Suvorovo': 4717,
    'Obshtina Valchidol': 4717,
    'Obshtina Varna': 4717,
    'Obshtina Vetrino': 4717,
    'Provadia': 4717,
    'Suvorovo': 4717,
    'Valchidol': 4717,
    'Varna': 4717,
    'Vetrino': 4717,
    'Zlatni Pyasatsi': 4717,
    'Byala Cherkva': 4709,
    'Debelets': 4709,
    'Elena': 4709,
    'Gorna Oryahovitsa': 4709,
    'Kilifarevo': 4709,
    'Lyaskovets': 4709,
    'Obshtina Elena': 4709,
    'Obshtina Gorna Oryahovitsa': 4709,
    'Obshtina Lyaskovets': 4709,
    'Obshtina Pavlikeni': 4709,
    'Obshtina Polski Trambesh': 4709,
    'Obshtina Strazhitsa': 4709,
    'Obshtina Suhindol': 4709,
    'Obshtina Svishtov': 4709,
    'Obshtina Veliko Tŭrnovo': 4709,
    'Obshtina Zlataritsa': 4709,
    'Parvomaytsi': 4709,
    'Pavlikeni': 4709,
    'Polski Trambesh': 4709,
    'Strazhitsa': 4709,
    'Suhindol': 4709,
    'Svishtov': 4709,
    'Veliko Tŭrnovo': 4709,
    'Zlataritsa': 4709,
    'Belogradchik': 4697,
    'Boynitsa': 4697,
    'Bregovo': 4697,
    'Chuprene': 4697,
    'Dimovo': 4697,
    'Drenovets': 4697,
    'Dunavtsi': 4697,
    'Gramada': 4697,
    'Kula': 4697,
    'Makresh': 4697,
    'Novo Selo': 4697,
    'Obshtina Belogradchik': 4697,
    'Obshtina Boynitsa': 4697,
    'Obshtina Dimovo': 4697,
    'Obshtina Gramada': 4697,
    'Obshtina Kula': 4697,
    'Obshtina Ruzhintsi': 4697,
    'Obshtina Vidin': 4697,
    'Ruzhintsi': 4697,
    'Vidin': 4697,
    'Borovan': 4711,
    'Byala Slatina': 4711,
    'Hayredin': 4711,
    'Kozloduy': 4711,
    'Krivodol': 4711,
    'Mezdra': 4711,
    'Mizia': 4711,
    'Obshtina Borovan': 4711,
    'Obshtina Hayredin': 4711,
    'Obshtina Kozloduy': 4711,
    'Obshtina Krivodol': 4711,
    'Obshtina Mezdra': 4711,
    'Obshtina Mizia': 4711,
    'Obshtina Oryahovo': 4711,
    'Obshtina Roman': 4711,
    'Obshtina Vratsa': 4711,
    'Oryahovo': 4711,
    'Roman': 4711,
    'Vratsa': 4711,
    'Bolyarovo': 4716,
    'Elhovo': 4716,
    'Obshtina Bolyarovo': 4716,
    'Obshtina Elhovo': 4716,
    'Obshtina Straldzha': 4716,
    'Obshtina Tundzha': 4716,
    'Obshtina Yambol': 4716,
    'Straldzha': 4716,
    'Yambol': 4716,
    'Barani': 3138,
    'Boromo': 3138,
    'Dédougou': 3138,
    'Nouna': 3138,
    'Province de la Kossi': 3138,
    'Province des Balé': 3138,
    'Province des Banwa': 3138,
    'Province du Mouhoun': 3138,
    'Province du Nayala': 3138,
    'Province du Sourou': 3138,
    'Salanso': 3138,
    'Toma': 3138,
    'Tougan': 3138,
    'Banfora': 3153,
    'Province de la Comoé': 3153,
    'Province de la Léraba': 3153,
    'Sindou': 3153,
    'Kadiogo Province': 3136,
    'Ouagadougou': 3136,
    'Garango': 3162,
    'Koupéla': 3162,
    'Kouritenga Province': 3162,
    'Ouargaye': 3162,
    'Province du Boulgou': 3162,
    'Province du Koulpélogo': 3162,
    'Tenkodogo': 3162,
    'Boulsa': 3127,
    'Kaya': 3127,
    'Kongoussi': 3127,
    'Province du Bam': 3127,
    'Province du Namentenga': 3127,
    'Province du Sanmatenga': 3127,
    'Goulouré': 3115,
    'Kokologo': 3115,
    'Koudougou': 3115,
    'Léo': 3115,
    'Pitmoaga': 3115,
    'Province de la Sissili': 3115,
    'Province du Boulkiemdé': 3115,
    'Province du Sanguié': 3115,
    'Province du Ziro': 3115,
    'Réo': 3115,
    'Sapouy': 3115,
    'Bazega Province': 3149,
    'Kombissiri': 3149,
    'Manga': 3149,
    'Nahouri Province': 3149,
    'Pô': 3149,
    'Zoundweogo Province': 3149,
    'Bogandé': 3158,
    'Diapaga': 3158,
    'Fada N  gourma': 3158,
    'Gayéri': 3158,
    'Gnagna Province': 3158,
    'Pama': 3158,
    'Province de la Komandjoari': 3158,
    'Province de la Kompienga': 3158,
    'Province de la Tapoa': 3158,
    'Province du Gourma': 3158,
    'Bobo-Dioulasso': 3165,
    'Houndé': 3165,
    'Province du Houet': 3165,
    'Province du Kénédougou': 3165,
    'Province du Tuy': 3165,
    'Gourcy': 3164,
    'Ouahigouya': 3164,
    'Province du Loroum': 3164,
    'Province du Passoré': 3164,
    'Province du Yatenga': 3164,
    'Province du Zondoma': 3164,
    'Titao': 3164,
    'Yako': 3164,
    'Boussé': 3125,
    'Oubritenga': 3125,
    'Province du Ganzourgou': 3125,
    'Province du Kourwéogo': 3125,
    'Ziniaré': 3125,
    'Zorgo': 3125,
    'Djibo': 3114,
    'Dori': 3114,
    'Gorom-Gorom': 3114,
    'Province de l’Oudalan': 3114,
    'Province du Séno': 3114,
    'Province du Soum': 3114,
    'Province du Yagha': 3114,
    'Batié': 3140,
    'Dano': 3140,
    'Diébougou': 3140,
    'Province de la Bougouriba': 3140,
    'Province du Ioba': 3140,
    'Province du Noumbièl': 3140,
    'Province du Poni': 3140,
    'Bubanza': 3196,
    'Bujumbura': 3198,
    'Bururi': 3202,
    'Cankuzo': 3201,
    'Cibitoke': 3190,
    'Gitega': 3197,
    'Karuzi': 3194,
    'Kayanza': 3192,
    'Kirundo': 3195,
    'Makamba': 3188,
    'Muramvya': 3193,
    'Muyinga': 3186,
    'Mwaro': 3187,
    'Ngozi': 3199,
    'Rumonge': 3185,
    'Rutana': 3189,
    'Ruyigi': 3191,
    'Mongkol Borei': 3984,
    'Paoy Paet': 3984,
    'Sisophon': 3984,
    'Srŏk Malai': 3984,
    'Srŏk Svay Chék': 3984,
    'Battambang': 3976,
    'Srŏk Âk Phnŭm': 3976,
    'Srŏk Banăn': 3976,
    'Srŏk Bâvĭl': 3976,
    'Srŏk Rotanak Mondol': 3976,
    'Cheung Prey': 3991,
    'Kampong Cham': 3991,
    'Srŏk Bathéay': 3991,
    'Baribour': 3979,
    'Kampong Chhnang': 3979,
    'Rolea B  ier': 3979,
    'Srŏk Chol Kiri': 3979,
    'Srŏk Sameakki Mean Chey': 3979,
    'Kampong Speu': 3988,
    'Krŏng Chbar Mon': 3988,
    'Srŏk Basedth': 3988,
    'Srŏk Kông Pĭsei': 3988,
    'Srŏk Ŏdŏngk': 3988,
    'Angkor Chey': 3981,
    'Banteay Meas': 3981,
    'Chhouk District': 3981,
    'Kampong Bay': 3981,
    'Kampong Tranch': 3981,
    'Kampot': 3981,
    'Srŏk Chŭm Kiri': 3981,
    'Srŏk Dângtóng': 3981,
    'Srok Tuek Chhou': 3981,
    'Krŏng Ta Khmau': 3983,
    'Srŏk Khsăch Kândal': 3983,
    'Ta Khmau': 3983,
    'Krong Kep': 3978,
    'Srŏk Dâmnăk Châng’aeur': 3978,
    'Koh Kong': 3982,
    'Smach Mean Chey': 3982,
    'Srae Ambel': 3982,
    'Srŏk Batum Sakôr': 3982,
    'Srŏk Môndôl Seima': 3982,
    'Kracheh': 3986,
    'Kratié': 3986,
    'Snuol': 3986,
    'Krŏng Sênmônoŭrôm': 3985,
    'Sen Monorom': 3985,
    'Srŏk Kaev Seima': 3985,
    'Srŏk Pech Chreada': 3985,
    'Samraong': 3987,
    'Srŏk Bântéay Âmpĭl': 3987,
    'Srŏk Sâmraông': 3987,
    'Srŏk Trâpeăng Prasat': 3987,
    'Khan Sala Krau': 3980,
    'Pailin': 3980,
    'Khan 7 Makara': 3994,
    'Khan Châmkar Mon': 3994,
    'Khan Dângkaô': 3994,
    'Khan Duŏn Pénh': 3994,
    'Khan Méan Chey': 3994,
    'Khan Russey Keo': 3994,
    'Khan Saen Sokh': 3994,
    'Phnom Penh': 3994,
    'Sangkom Thmei': 3973,
    'Srŏk Ch’êh Sên': 3973,
    'Srŏk Chhêb': 3973,
    'Srŏk Kulén': 3973,
    'Srŏk Rôviĕng': 3973,
    'Srŏk Tbêng Méanchey': 3973,
    'Tbeng Meanchey': 3973,
    'Prey Veng': 3974,
    'Srŏk Kâmpóng Léav': 3974,
    'Srŏk Mésang': 3974,
    'Srŏk Preăh Sdéch': 3974,
    'Bakan': 3977,
    'Krakor': 3977,
    'Pursat': 3977,
    'Sampov Meas': 3977,
    'Srŏk Kândiĕng': 3977,
    'Srŏk Véal Vêng': 3977,
    'Banlung': 3990,
    'Lumphat': 3990,
    'Srŏk Ândong Méas': 3990,
    'Srŏk Bâ Kêv': 3990,
    'Srŏk Ban Lŭng': 3990,
    'Srŏk Koun Mom': 3990,
    'Srŏk Ou Chum': 3990,
    'Srŏk Ou Ya Dav': 3990,
    'Srŏk Ta Vêng': 3990,
    'Siem Reap': 3992,
    'Srŏk Ângkôr Thum': 3992,
    'Srŏk Prasat Bakong': 3992,
    'Svay Leu': 3992,
    'Varin': 3992,
    'Sihanoukville': 3989,
    'Srok Stueng Hav': 3989,
    'Srŏk Srêsén': 3993,
    'Stueng Traeng': 3993,
    'Stung Treng': 3993,
    'Srŏk Svay Chrŭm': 3972,
    'Svay Rieng': 3972,
    'Krŏng Doun Kaev': 3975,
    'Phumĭ Véal Srê': 3975,
    'Srŏk Ângkôr Borei': 3975,
    'Srŏk Borei Cholsar': 3975,
    'Takeo': 3975,
    'Bankim': 2663,
    'Banyo': 2663,
    'Bélel': 2663,
    'Djohong': 2663,
    'Kontcha': 2663,
    'Mayo-Banyo': 2663,
    'Meïganga': 2663,
    'Ngaoundéré': 2663,
    'Somié': 2663,
    'Tibati': 2663,
    'Tignère': 2663,
    'Vina': 2663,
    'Akono': 2660,
    'Akonolinga': 2660,
    'Bafia': 2660,
    'Eséka': 2660,
    'Essé': 2660,
    'Évodoula': 2660,
    'Mbalmayo': 2660,
    'Mbam-Et-Inoubou': 2660,
    'Mbandjok': 2660,
    'Mbankomo': 2660,
    'Mefou-et-Akono': 2660,
    'Mfoundi': 2660,
    'Minta': 2660,
    'Nanga Eboko': 2660,
    'Ndikiniméki': 2660,
    'Ngomedzap': 2660,
    'Ngoro': 2660,
    'Nkoteng': 2660,
    'Ntui': 2660,
    'Obala': 2660,
    'Okoa': 2660,
    'Okola': 2660,
    'Ombésa': 2660,
    'Saa': 2660,
    'Yaoundé': 2660,
    'Yoko': 2660,
    'Abong Mbang': 2661,
    'Batouri': 2661,
    'Bélabo': 2661,
    'Bertoua': 2661,
    'Bétaré Oya': 2661,
    'Dimako': 2661,
    'Doumé': 2661,
    'Garoua Boulaï': 2661,
    'Mbang': 2661,
    'Ndelele': 2661,
    'Yokadouma': 2661,
    'Bogo': 2656,
    'Kaélé': 2656,
    'Kousséri': 2656,
    'Koza': 2656,
    'Makary': 2656,
    'Maroua': 2656,
    'Mayo-Sava': 2656,
    'Mayo-Tsanaga': 2656,
    'Mindif': 2656,
    'Mokolo': 2656,
    'Mora': 2656,
    'Yagoua': 2656,
    'Bonabéri': 2662,
    'Diang': 2662,
    'Dibombari': 2662,
    'Dizangué': 2662,
    'Douala': 2662,
    'Edéa': 2662,
    'Loum': 2662,
    'Manjo': 2662,
    'Mbanga': 2662,
    'Melong': 2662,
    'Mouanko': 2662,
    'Ndom': 2662,
    'Ngambé': 2662,
    'Nkongsamba': 2662,
    'Penja': 2662,
    'Yabassi': 2662,
    'Faro Department': 2665,
    'Garoua': 2665,
    'Guider': 2665,
    'Lagdo': 2665,
    'Mayo-Louti': 2665,
    'Mayo-Rey': 2665,
    'Pitoa': 2665,
    'Poli': 2665,
    'Rey Bouba': 2665,
    'Tcholliré': 2665,
    'Babanki': 2657,
    'Bali': 2657,
    'Bamenda': 2657,
    'Batibo': 2657,
    'Belo': 2657,
    'Boyo': 2657,
    'Fundong': 2657,
    'Jakiri': 2657,
    'Kumbo': 2657,
    'Mbengwi': 2657,
    'Mme-Bafumen': 2657,
    'Njinikom': 2657,
    'Wum': 2657,
    'Akom II': 2659,
    'Ambam': 2659,
    'Ébolowa': 2659,
    'Kribi': 2659,
    'Lolodorf': 2659,
    'Mvangué': 2659,
    'Mvila': 2659,
    'Sangmélima': 2659,
    'Bamusso': 2658,
    'Bekondo': 2658,
    'Buea': 2658,
    'Fako Division': 2658,
    'Fontem': 2658,
    'Kumba': 2658,
    'Lebialem': 2658,
    'Limbe': 2658,
    'Mamfe': 2658,
    'Mundemba': 2658,
    'Mutengene': 2658,
    'Muyuka': 2658,
    'Nguti': 2658,
    'Tiko': 2658,
    'Bafang': 2664,
    'Bafoussam': 2664,
    'Bamendjou': 2664,
    'Bana': 2664,
    'Bandjoun': 2664,
    'Bangangté': 2664,
    'Bansoa': 2664,
    'Bazou': 2664,
    'Dschang': 2664,
    'Foumban': 2664,
    'Foumbot': 2664,
    'Hauts-Plateaux': 2664,
    'Koung-Khi': 2664,
    'Mbouda': 2664,
    'Ngou': 2664,
    'Noun': 2664,
    'Tonga': 2664,
    'Airdrie': 872,
    'Athabasca': 872,
    'Banff': 872,
    'Barrhead': 872,
    'Bassano': 872,
    'Beaumont': 872,
    'Beaverlodge': 872,
    'Black Diamond': 872,
    'Blackfalds': 872,
    'Bon Accord': 872,
    'Bonnyville': 872,
    'Bow Island': 872,
    'Brooks': 872,
    'Calgary': 872,
    'Calmar': 872,
    'Camrose': 872,
    'Canmore': 872,
    'Cardston': 872,
    'Carstairs': 872,
    'Chestermere': 872,
    'Claresholm': 872,
    'Coaldale': 872,
    'Coalhurst': 872,
    'Cochrane': 872,
    'Cold Lake': 872,
    'Crossfield': 872,
    'Devon': 872,
    'Didsbury': 872,
    'Drayton Valley': 872,
    'Edmonton': 872,
    'Edson': 872,
    'Elk Point': 872,
    'Fairview': 872,
    'Falher': 872,
    'Fort Macleod': 872,
    'Fort McMurray': 872,
    'Fort Saskatchewan': 872,
    'Fox Creek': 872,
    'Gibbons': 872,
    'Grand Centre': 872,
    'Grande Cache': 872,
    'Grande Prairie': 872,
    'Grimshaw': 872,
    'Hanna': 872,
    'Heritage Pointe': 872,
    'High Level': 872,
    'High Prairie': 872,
    'High River': 872,
    'Hinton': 872,
    'Irricana': 872,
    'Jasper Park Lodge': 872,
    'Killam': 872,
    'Lac La Biche': 872,
    'Lacombe': 872,
    'Lamont': 872,
    'Larkspur': 872,
    'Laurel': 872,
    'Leduc': 872,
    'Lethbridge': 872,
    'Lloydminster': 872,
    'Magrath': 872,
    'Manning': 872,
    'Mannville': 872,
    'Maple Ridge': 872,
    'Mayerthorpe': 872,
    'Medicine Hat': 872,
    'Mill Woods Town Centre': 872,
    'Millet': 872,
    'Morinville': 872,
    'Nanton': 872,
    'Okotoks': 872,
    'Olds': 872,
    'Peace River': 872,
    'Penhold': 872,
    'Picture Butte': 872,
    'Pincher Creek': 872,
    'Ponoka': 872,
    'Provost': 872,
    'Raymond': 872,
    'Red Deer': 872,
    'Rideau Park': 872,
    'Rimbey': 872,
    'Rocky Mountain House': 872,
    'Sexsmith': 872,
    'Sherwood Park': 872,
    'Silver Berry': 872,
    'Slave Lake': 872,
    'Smoky Lake': 872,
    'Spirit River': 872,
    'Springbrook': 872,
    'Spruce Grove': 872,
    'St. Albert': 872,
    'Stettler': 872,
    'Stony Plain': 872,
    'Strathmore': 872,
    'Sundre': 872,
    'Swan Hills': 872,
    'Sylvan Lake': 872,
    'Taber': 872,
    'Tamarack': 872,
    'Three Hills': 872,
    'Tofield': 872,
    'Two Hills': 872,
    'Valleyview': 872,
    'Vegreville': 872,
    'Vermilion': 872,
    'Viking': 872,
    'Vulcan': 872,
    'Wainwright': 872,
    'Wembley': 872,
    'Westlake': 872,
    'Westlock': 872,
    'Wetaskiwin': 872,
    'Whitecourt': 872,
    'Wild Rose': 872,
    'Abbotsford': 875,
    'Agassiz': 875,
    'Aldergrove': 875,
    'Aldergrove East': 875,
    'Anmore': 875,
    'Arbutus Ridge': 875,
    'Armstrong': 875,
    'Ashcroft': 875,
    'Barrière': 875,
    'Bowen Island': 875,
    'Burnaby': 875,
    'Burns Lake': 875,
    'Cache Creek': 875,
    'Campbell River': 875,
    'Castlegar': 875,
    'Cedar': 875,
    'Central Coast Regional District': 875,
    'Chase': 875,
    'Chemainus': 875,
    'Chetwynd': 875,
    'Chilliwack': 875,
    'Colwood': 875,
    'Coombs': 875,
    'Coquitlam': 875,
    'Courtenay': 875,
    'Cowichan Bay': 875,
    'Cranbrook': 875,
    'Creston': 875,
    'Cumberland': 875,
    'Dawson Creek': 875,
    'Delta': 875,
    'Denman Island': 875,
    'Denman Island Trust Area': 875,
    'Duck Lake': 875,
    'Duncan': 875,
    'East Wellington': 875,
    'Elkford': 875,
    'Ellison': 875,
    'Enderby': 875,
    'Fairwinds': 875,
    'Fernie': 875,
    'Fort Nelson': 875,
    'Fort St. John': 875,
    'Fraser Valley Regional District': 875,
    'French Creek': 875,
    'Fruitvale': 875,
    'Gibsons': 875,
    'Golden': 875,
    'Grand Forks': 875,
    'Hanceville': 875,
    'Hope': 875,
    'Hornby Island': 875,
    'Houston': 875,
    'Invermere': 875,
    'Kamloops': 875,
    'Kelowna': 875,
    'Kimberley': 875,
    'Kitimat': 875,
    'Ladner': 875,
    'Ladysmith': 875,
    'Lake Cowichan': 875,
    'Langford': 875,
    'Langley': 875,
    'Lillooet': 875,
    'Lions Bay': 875,
    'Logan Lake': 875,
    'Lumby': 875,
    'Mackenzie': 875,
    'Maple Ridge': 875,
    'Merritt': 875,
    'Metchosin': 875,
    'Metro Vancouver Regional District': 875,
    'Mission': 875,
    'Nakusp': 875,
    'Nanaimo': 875,
    'Nelson': 875,
    'New Westminster': 875,
    'North Cowichan': 875,
    'North Oyster/Yellow Point': 875,
    'North Saanich': 875,
    'North Vancouver': 875,
    'Oak Bay': 875,
    'Okanagan': 875,
    'Okanagan Falls': 875,
    'Oliver': 875,
    'Osoyoos': 875,
    'Parksville': 875,
    'Peace River Regional District': 875,
    'Peachland': 875,
    'Pemberton': 875,
    'Penticton': 875,
    'Pitt Meadows': 875,
    'Port Alberni': 875,
    'Port Coquitlam': 875,
    'Port McNeill': 875,
    'Port Moody': 875,
    'Powell River': 875,
    'Prince George': 875,
    'Prince Rupert': 875,
    'Princeton': 875,
    'Puntledge': 875,
    'Quesnel': 875,
    'Regional District of Alberni-Clayoquot': 875,
    'Regional District of Central Okanagan': 875,
    'Revelstoke': 875,
    'Richmond': 875,
    'Rossland': 875,
    'Royston': 875,
    'Salmo': 875,
    'Salmon Arm': 875,
    'Salt Spring Island': 875,
    'Saltair': 875,
    'Sechelt': 875,
    'Sicamous': 875,
    'Six Mile': 875,
    'Smithers': 875,
    'Sooke': 875,
    'South Pender Harbour': 875,
    'Sparwood': 875,
    'Summerland': 875,
    'Surrey': 875,
    'Terrace': 875,
    'Tofino': 875,
    'Trail': 875,
    'Tsawwassen': 875,
    'Tumbler Ridge': 875,
    'Ucluelet': 875,
    'Vancouver': 875,
    'Vanderhoof': 875,
    'Vernon': 875,
    'Victoria': 875,
    'Walnut Grove': 875,
    'Welcome Beach': 875,
    'West End': 875,
    'West Kelowna': 875,
    'West Vancouver': 875,
    'Whistler': 875,
    'White Rock': 875,
    'Williams Lake': 875,
    'Altona': 867,
    'Beausejour': 867,
    'Boissevain': 867,
    'Brandon': 867,
    'Carberry': 867,
    'Carman': 867,
    'Cross Lake 19A': 867,
    'Dauphin': 867,
    'De Salaberry': 867,
    'Deloraine': 867,
    'Flin Flon': 867,
    'Gimli': 867,
    'Grunthal': 867,
    'Headingley': 867,
    'Ile des Chênes': 867,
    'Killarney': 867,
    'La Broquerie': 867,
    'Lac du Bonnet': 867,
    'Landmark': 867,
    'Lorette': 867,
    'Melita': 867,
    'Minnedosa': 867,
    'Moose Lake': 867,
    'Morden': 867,
    'Morris': 867,
    'Neepawa': 867,
    'Niverville': 867,
    'Portage la Prairie': 867,
    'Rivers': 867,
    'Roblin': 867,
    'Selkirk': 867,
    'Shilo': 867,
    'Souris': 867,
    'St. Adolphe': 867,
    'Steinbach': 867,
    'Stonewall': 867,
    'Swan River': 867,
    'The Pas': 867,
    'Thompson': 867,
    'Virden': 867,
    'West St. Paul': 867,
    'Winkler': 867,
    'Winnipeg': 867,
    'Baie Ste. Anne': 868,
    'Bathurst': 868,
    'Bouctouche': 868,
    'Campbellton': 868,
    'Dieppe': 868,
    'Edmundston': 868,
    'Florenceville-Bristol': 868,
    'Fredericton': 868,
    'Fundy Bay': 868,
    'Grande-Digue': 868,
    'Greater Lakeburn': 868,
    'Hampton': 868,
    'Harrison Brook': 868,
    'Keswick Ridge': 868,
    'Lincoln': 868,
    'Lutes Mountain': 868,
    'McEwen': 868,
    'Miramichi': 868,
    'Moncton': 868,
    'Nackawic': 868,
    'New Maryland': 868,
    'Noonan': 868,
    'Oromocto': 868,
    'Richibucto': 868,
    'Sackville': 868,
    'Saint Andrews': 868,
    'Saint John': 868,
    'Saint-Antoine': 868,
    'Saint-Léonard': 868,
    'Salisbury': 868,
    'Shediac': 868,
    'Shediac Bridge-Shediac River': 868,
    'Shippagan': 868,
    'Starlight Village': 868,
    'Sussex': 868,
    'Tracadie-Sheila': 868,
    'Wells': 868,
    'Bay Roberts': 877,
    'Bay St. George South': 877,
    'Bonavista': 877,
    'Botwood': 877,
    'Burgeo': 877,
    'Carbonear': 877,
    'Catalina': 877,
    'Channel-Port aux Basques': 877,
    'Clarenville-Shoal Harbour': 877,
    'Conception Bay South': 877,
    'Corner Brook': 877,
    'Deer Lake': 877,
    'Fogo Island': 877,
    'Gambo': 877,
    'Goulds': 877,
    'Grand Bank': 877,
    'Grand Falls-Windsor': 877,
    'Happy Valley-Goose Bay': 877,
    'Harbour Breton': 877,
    'Labrador City': 877,
    'Lewisporte': 877,
    'Marystown': 877,
    'Mount Pearl': 877,
    'Pasadena': 877,
    'Springdale': 877,
    'St. Anthony': 877,
    'St. John  s': 877,
    'Stephenville': 877,
    'Stephenville Crossing': 877,
    'Torbay': 877,
    'Upper Island Cove': 877,
    'Wabana': 877,
    'Behchokǫ̀': 878,
    'Fort McPherson': 878,
    'Fort Smith': 878,
    'Hay River': 878,
    'Inuvik': 878,
    'Norman Wells': 878,
    'Yellowknife': 878,
    'Amherst': 874,
    'Annapolis County': 874,
    'Antigonish': 874,
    'Berwick': 874,
    'Bridgewater': 874,
    'Cape Breton County': 874,
    'Chester': 874,
    'Colchester': 874,
    'Cole Harbour': 874,
    'Cow Bay': 874,
    'Dartmouth': 874,
    'Digby': 874,
    'Digby County': 874,
    'English Corner': 874,
    'Eskasoni 3': 874,
    'Fall River': 874,
    'Glace Bay': 874,
    'Greenwood': 874,
    'Halifax': 874,
    'Hantsport': 874,
    'Hayes Subdivision': 874,
    'Kentville': 874,
    'Lake Echo': 874,
    'Lantz': 874,
    'Lower Sackville': 874,
    'Lunenburg': 874,
    'Middleton': 874,
    'New Glasgow': 874,
    'Oxford': 874,
    'Parrsboro': 874,
    'Pictou': 874,
    'Pictou County': 874,
    'Port Hawkesbury': 874,
    'Port Williams': 874,
    'Princeville': 874,
    'Shelburne': 874,
    'Springhill': 874,
    'Sydney': 874,
    'Sydney Mines': 874,
    'Truro': 874,
    'Windsor': 874,
    'Wolfville': 874,
    'Yarmouth': 874,
    'Clyde River': 876,
    'Gjoa Haven': 876,
    'Iqaluit': 876,
    'Kugluktuk': 876,
    'Pangnirtung': 876,
    'Rankin Inlet': 876,
    'Ajax': 866,
    'Algoma': 866,
    'Alliston': 866,
    'Amherstburg': 866,
    'Amigo Beach': 866,
    'Ancaster': 866,
    'Angus': 866,
    'Arnprior': 866,
    'Atikokan': 866,
    'Attawapiskat': 866,
    'Aurora': 866,
    'Aylmer': 866,
    'Azilda': 866,
    'Ballantrae': 866,
    'Bancroft': 866,
    'Barrie': 866,
    'Bath': 866,
    'Belleville': 866,
    'Bells Corners': 866,
    'Belmont': 866,
    'Binbrook': 866,
    'Bluewater': 866,
    'Bourget': 866,
    'Bracebridge': 866,
    'Brampton': 866,
    'Brant': 866,
    'Brantford': 866,
    'Brockville': 866,
    'Brussels': 866,
    'Burford': 866,
    'Burlington': 866,
    'Cambridge': 866,
    'Camlachie': 866,
    'Capreol': 866,
    'Carleton Place': 866,
    'Casselman': 866,
    'Chatham': 866,
    'Chatham-Kent': 866,
    'Clarence-Rockland': 866,
    'Cobourg': 866,
    'Cochrane District': 866,
    'Collingwood': 866,
    'Concord': 866,
    'Constance Bay': 866,
    'Cookstown': 866,
    'Cornwall': 866,
    'Corunna': 866,
    'Deep River': 866,
    'Delaware': 866,
    'Deseronto': 866,
    'Dorchester': 866,
    'Dowling': 866,
    'Dryden': 866,
    'Durham': 866,
    'Ear Falls': 866,
    'East Gwillimbury': 866,
    'East York': 866,
    'Elliot Lake': 866,
    'Elmvale': 866,
    'Englehart': 866,
    'Espanola': 866,
    'Essex': 866,
    'Etobicoke': 866,
    'Fort Erie': 866,
    'Fort Frances': 866,
    'Gananoque': 866,
    'Glencoe': 866,
    'Goderich': 866,
    'Golden': 866,
    'Gravenhurst': 866,
    'Greater Napanee': 866,
    'Greater Sudbury': 866,
    'Greenstone': 866,
    'Guelph': 866,
    'Haldimand County': 866,
    'Haliburton Village': 866,
    'Halton': 866,
    'Hamilton': 866,
    'Hanover': 866,
    'Harriston': 866,
    'Hawkesbury': 866,
    'Hearst': 866,
    'Hornepayne': 866,
    'Huntsville': 866,
    'Huron East': 866,
    'Ingersoll': 866,
    'Innisfil': 866,
    'Iroquois Falls': 866,
    'Jarvis': 866,
    'Kanata': 866,
    'Kapuskasing': 866,
    'Kawartha Lakes': 866,
    'Kenora': 866,
    'Keswick': 866,
    'Kincardine': 866,
    'King': 866,
    'Kingston': 866,
    'Kirkland Lake': 866,
    'Kitchener': 866,
    'L  Orignal': 866,
    'Lakefield': 866,
    'Lambton Shores': 866,
    'Lappe': 866,
    'Leamington': 866,
    'Limoges': 866,
    'Lindsay': 866,
    'Listowel': 866,
    'Little Current': 866,
    'Lively': 866,
    'London': 866,
    'Lucan': 866,
    'Madoc': 866,
    'Manitoulin District': 866,
    'Manitouwadge': 866,
    'Marathon': 866,
    'Markdale': 866,
    'Markham': 866,
    'Mattawa': 866,
    'Meaford': 866,
    'Metcalfe': 866,
    'Midland': 866,
    'Mildmay': 866,
    'Millbrook': 866,
    'Milton': 866,
    'Mississauga': 866,
    'Mississauga Beach': 866,
    'Moose Factory': 866,
    'Moosonee': 866,
    'Morrisburg': 866,
    'Mount Albert': 866,
    'Mount Brydges': 866,
    'Napanee': 866,
    'Napanee Downtown': 866,
    'Neebing': 866,
    'Nepean': 866,
    'New Hamburg': 866,
    'Newmarket': 866,
    'Niagara Falls': 866,
    'Nipissing District': 866,
    'Norfolk County': 866,
    'North Bay': 866,
    'North Perth': 866,
    'North York': 866,
    'Norwood': 866,
    'Oakville': 866,
    'Omemee': 866,
    'Orangeville': 866,
    'Orillia': 866,
    'Osgoode': 866,
    'Oshawa': 866,
    'Ottawa': 866,
    'Owen Sound': 866,
    'Paisley': 866,
    'Paris': 866,
    'Parkhill': 866,
    'Parry Sound': 866,
    'Parry Sound District': 866,
    'Peel': 866,
    'Pembroke': 866,
    'Perth': 866,
    'Petawawa': 866,
    'Peterborough': 866,
    'Petrolia': 866,
    'Pickering': 866,
    'Picton': 866,
    'Plantagenet': 866,
    'Plattsville': 866,
    'Port Colborne': 866,
    'Port Hope': 866,
    'Port Rowan': 866,
    'Port Stanley': 866,
    'Powassan': 866,
    'Prescott': 866,
    'Prince Edward': 866,
    'Queenswood Heights': 866,
    'Quinte West': 866,
    'Rainy River District': 866,
    'Rayside-Balfour': 866,
    'Red Lake': 866,
    'Regional Municipality of Waterloo': 866,
    'Renfrew': 866,
    'Richmond': 866,
    'Richmond Hill': 866,
    'Ridgetown': 866,
    'Rockwood': 866,
    'Russell': 866,
    'Sarnia': 866,
    'Sault Ste. Marie': 866,
    'Scarborough': 866,
    'Seaforth': 866,
    'Shelburne': 866,
    'Simcoe': 866,
    'Sioux Lookout': 866,
    'Skatepark': 866,
    'Smiths Falls': 866,
    'South Huron': 866,
    'South River': 866,
    'St. Catharines': 866,
    'St. George': 866,
    'St. Thomas': 866,
    'Stirling': 866,
    'Stoney Point': 866,
    'Stratford': 866,
    'Sudbury': 866,
    'Tavistock': 866,
    'Temiskaming Shores': 866,
    'Thessalon': 866,
    'Thorold': 866,
    'Thunder Bay': 866,
    'Thunder Bay District': 866,
    'Timiskaming District': 866,
    'Timmins': 866,
    'Tobermory': 866,
    'Toronto': 866,
    'Toronto county': 866,
    'Tottenham': 866,
    'Tweed': 866,
    'Uxbridge': 866,
    'Valley East': 866,
    'Vanier': 866,
    'Vaughan': 866,
    'Vineland': 866,
    'Virgil': 866,
    'Walpole Island': 866,
    'Wasaga Beach': 866,
    'Waterford': 866,
    'Waterloo': 866,
    'Watford': 866,
    'Wawa': 866,
    'Welland': 866,
    'Wellesley': 866,
    'Wendover': 866,
    'West Lorne': 866,
    'Willowdale': 866,
    'Winchester': 866,
    'Windsor': 866,
    'Wingham': 866,
    'Woodstock': 866,
    'York': 866,
    'Alberton': 871,
    'Belfast': 871,
    'Charlottetown': 871,
    'Cornwall': 871,
    'Fallingbrook': 871,
    'Kensington': 871,
    'Montague': 871,
    'Souris': 871,
    'Summerside': 871,
    'Abitibi-Témiscamingue': 873,
    'Acton Vale': 873,
    'Adstock': 873,
    'Albanel': 873,
    'Alma': 873,
    'Amos': 873,
    'Amqui': 873,
    'Ange-Gardien': 873,
    'Asbestos': 873,
    'Baie-Comeau': 873,
    'Baie-D  Urfé': 873,
    'Baie-Saint-Paul': 873,
    'Barraute': 873,
    'Bas-Saint-Laurent': 873,
    'Beaconsfield': 873,
    'Beauceville': 873,
    'Beauharnois': 873,
    'Beaupré': 873,
    'Bécancour': 873,
    'Bedford': 873,
    'Beloeil': 873,
    'Berthierville': 873,
    'Blainville': 873,
    'Bois-des-Filion': 873,
    'Boisbriand': 873,
    'Bonaventure': 873,
    'Boucherville': 873,
    'Breakeyville': 873,
    'Bromont': 873,
    'Brossard': 873,
    'Brownsburg-Chatham': 873,
    'Buckingham': 873,
    'Cabano': 873,
    'Cacouna': 873,
    'Candiac': 873,
    'Cantley': 873,
    'Cap-Chat': 873,
    'Cap-Santé': 873,
    'Capitale-Nationale': 873,
    'Carignan': 873,
    'Carleton': 873,
    'Carleton-sur-Mer': 873,
    'Centre-du-Québec': 873,
    'Chambly': 873,
    'Chambord': 873,
    'Chandler': 873,
    'Chapais': 873,
    'Charlemagne': 873,
    'Château-Richer': 873,
    'Châteauguay': 873,
    'Chaudière-Appalaches': 873,
    'Chertsey': 873,
    'Chibougamau': 873,
    'Chute-aux-Outardes': 873,
    'Coaticook': 873,
    'Contrecoeur': 873,
    'Cookshire': 873,
    'Cookshire-Eaton': 873,
    'Côte-Nord': 873,
    'Côte-Saint-Luc': 873,
    'Coteau-du-Lac': 873,
    'Cowansville': 873,
    'Crabtree': 873,
    'Danville': 873,
    'Daveluyville': 873,
    'Delson': 873,
    'Deux-Montagnes': 873,
    'Disraeli': 873,
    'Dolbeau-Mistassini': 873,
    'Dollard-Des Ormeaux': 873,
    'Donnacona': 873,
    'Dorval': 873,
    'Drummondville': 873,
    'Dunham': 873,
    'East Angus': 873,
    'East Broughton': 873,
    'Farnham': 873,
    'Ferme-Neuve': 873,
    'Fermont': 873,
    'Forestville': 873,
    'Fort-Coulonge': 873,
    'Fossambault-sur-le-Lac': 873,
    'Franklin': 873,
    'Gaspé': 873,
    'Gaspésie-Îles-de-la-Madeleine': 873,
    'Gatineau': 873,
    'Godefroy': 873,
    'Granby': 873,
    'Hampstead': 873,
    'Hauterive': 873,
    'Havre-Saint-Pierre': 873,
    'Hérouxville': 873,
    'Hudson': 873,
    'Huntingdon': 873,
    'Joliette': 873,
    'Jonquière': 873,
    'Kingsey Falls': 873,
    'Kirkland': 873,
    'L  Ancienne-Lorette': 873,
    'L  Ange-Gardien': 873,
    'L  Ascension-de-Notre-Seigneur': 873,
    'L  Assomption': 873,
    'L  Épiphanie': 873,
    'L  Île-Perrot': 873,
    'La Conception': 873,
    'La Haute-Saint-Charles': 873,
    'La Malbaie': 873,
    'La Minerve': 873,
    'La Pocatière': 873,
    'La Prairie': 873,
    'La Sarre': 873,
    'La Tuque': 873,
    'Labelle': 873,
    'Lac-Alouette': 873,
    'Lac-Brome': 873,
    'Lac-Connelly': 873,
    'Lac-Lapierre': 873,
    'Lac-Mégantic': 873,
    'Lac-Simon': 873,
    'Lachute': 873,
    'Lacolle': 873,
    'Lanoraie': 873,
    'Laval': 873,
    'Lavaltrie': 873,
    'Le Bic': 873,
    'le Plateau': 873,
    'Lebel-sur-Quévillon': 873,
    'Leblanc': 873,
    'Les Cèdres': 873,
    'Les Coteaux': 873,
    'Les Escoumins': 873,
    'Lévis': 873,
    'Linière': 873,
    'Longueuil': 873,
    'Lorraine': 873,
    'Louiseville': 873,
    'Luceville': 873,
    'Macamic': 873,
    'Magog': 873,
    'Malartic': 873,
    'Maliotenam': 873,
    'Manawan': 873,
    'Mandeville': 873,
    'Maniwaki': 873,
    'Maria': 873,
    'Marieville': 873,
    'Mascouche': 873,
    'Maskinongé': 873,
    'Matagami': 873,
    'Matane': 873,
    'Mauricie': 873,
    'Melocheville': 873,
    'Mercier': 873,
    'Métabetchouan': 873,
    'Metabetchouan-Lac-a-la-Croix': 873,
    'Mirabel': 873,
    'Mistissini': 873,
    'Mont-Joli': 873,
    'Mont-Laurier': 873,
    'Mont-Royal': 873,
    'Mont-Saint-Grégoire': 873,
    'Mont-Saint-Hilaire': 873,
    'Mont-Tremblant': 873,
    'Montmagny': 873,
    'Montréal': 873,
    'Montréal-Est': 873,
    'Montréal-Ouest': 873,
    'Morin-Heights': 873,
    'Napierville': 873,
    'Neuville': 873,
    'New Carlisle': 873,
    'New-Richmond': 873,
    'Nicolet': 873,
    'Nord-du-Québec': 873,
    'Normandin': 873,
    'Notre-Dame-de-Grâce': 873,
    'Notre-Dame-de-l  Île-Perrot': 873,
    'Notre-Dame-des-Prairies': 873,
    'Notre-Dame-du-Lac': 873,
    'Notre-Dame-du-Mont-Carmel': 873,
    'Oka': 873,
    'Ormstown': 873,
    'Otterburn Park': 873,
    'Outaouais': 873,
    'Papineauville': 873,
    'Parc-Boutin': 873,
    'Piedmont': 873,
    'Pierreville': 873,
    'Pincourt': 873,
    'Plessisville': 873,
    'Pohénégamook': 873,
    'Pointe-Calumet': 873,
    'Pointe-Claire': 873,
    'Pointe-du-Lac': 873,
    'Pont Rouge': 873,
    'Pont-Rouge': 873,
    'Port-Cartier': 873,
    'Portneuf': 873,
    'Prévost': 873,
    'Princeville': 873,
    'Québec': 873,
    'Rawdon': 873,
    'Repentigny': 873,
    'Richelieu': 873,
    'Richmond': 873,
    'Rigaud': 873,
    'Rimouski': 873,
    'Rivière-du-Loup': 873,
    'Rivière-Rouge': 873,
    'Roberval': 873,
    'Rock Forest': 873,
    'Rosemère': 873,
    'Rougemont': 873,
    'Rouyn-Noranda': 873,
    'Sacré-Coeur': 873,
    'Saguenay': 873,
    'Saint-Adolphe-d  Howard': 873,
    'Saint-Alexandre': 873,
    'Saint-Amable': 873,
    'Saint-Ambroise': 873,
    'Saint-André-Avellin': 873,
    'Saint-Anselme': 873,
    'Saint-Antoine-de-Tilly': 873,
    'Saint-Augustin': 873,
    'Saint-Augustin-de-Desmaures': 873,
    'Saint-Barnabé-Sud': 873,
    'Saint-Basile-le-Grand': 873,
    'Saint-Boniface': 873,
    'Saint-Bruno': 873,
    'Saint-Bruno-de-Guigues': 873,
    'Saint-Bruno-de-Montarville': 873,
    'Saint-Canut': 873,
    'Saint-Césaire': 873,
    'Saint-Charles': 873,
    'Saint-Côme--Linière': 873,
    'Saint-Constant': 873,
    'Saint-Cyrille-de-Wendover': 873,
    'Saint-Damase': 873,
    'Saint-Denis-sur-Richelieu': 873,
    'Saint-Donat-de-Montcalm': 873,
    'Saint-Édouard': 873,
    'Saint-Elzéar': 873,
    'Saint-Éphrem-de-Beauce': 873,
    'Saint-Eustache': 873,
    'Saint-Félicien': 873,
    'Saint-Félix-de-Valois': 873,
    'Saint-Gabriel': 873,
    'Saint-Gédéon': 873,
    'Saint-Georges': 873,
    'Saint-Germain-de-Grantham': 873,
    'Saint-Henri': 873,
    'Saint-Hippolyte': 873,
    'Saint-Honoré': 873,
    'Saint-Hyacinthe': 873,
    'Saint-Isidore': 873,
    'Saint-Jacques-le-Mineur': 873,
    'Saint-Jean-Baptiste': 873,
    'Saint-Jean-sur-Richelieu': 873,
    'Saint-Jérôme': 873,
    'Saint-Joseph': 873,
    'Saint-Joseph-de-Beauce': 873,
    'Saint-Joseph-de-Coleraine': 873,
    'Saint-Joseph-du-Lac': 873,
    'Saint-Lambert-de-Lauzon': 873,
    'Saint-Laurent': 873,
    'Saint-Lazare': 873,
    'Saint-Léonard': 873,
    'Saint-Léonard-d  Aston': 873,
    'Saint-Liboire': 873,
    'Saint-Lin-Laurentides': 873,
    'Saint-Marc-des-Carrières': 873,
    'Saint-Mathieu': 873,
    'Saint-Michel': 873,
    'Saint-Michel-des-Saints': 873,
    'Saint-Nazaire': 873,
    'Saint-Norbert': 873,
    'Saint-Pacôme': 873,
    'Saint-Pascal': 873,
    'Saint-Philippe-de-La Prairie': 873,
    'Saint-Pie': 873,
    'Saint-Pierre-les-Becquets': 873,
    'Saint-Prime': 873,
    'Saint-Raphaël': 873,
    'Saint-Raymond': 873,
    'Saint-Rémi': 873,
    'Saint-Rémi-de-Tingwick': 873,
    'Saint-Sauveur': 873,
    'Saint-Sauveur-des-Monts': 873,
    'Saint-Siméon': 873,
    'Saint-Thomas': 873,
    'Saint-Tite': 873,
    'Saint-Victor': 873,
    'Saint-Zotique': 873,
    'Sainte Catherine de la Jacques Cartier': 873,
    'Sainte-Adèle': 873,
    'Sainte-Agathe-des-Monts': 873,
    'Sainte-Anne-de-Bellevue': 873,
    'Sainte-Anne-des-Monts': 873,
    'Sainte-Anne-des-Plaines': 873,
    'Sainte-Béatrix': 873,
    'Sainte-Catherine': 873,
    'Sainte-Croix': 873,
    'Sainte-Élisabeth': 873,
    'Sainte-Julie': 873,
    'Sainte-Julienne': 873,
    'Sainte-Madeleine': 873,
    'Sainte-Marie': 873,
    'Sainte-Marthe-sur-le-Lac': 873,
    'Sainte-Martine': 873,
    'Sainte-Sophie': 873,
    'Sainte-Thècle': 873,
    'Sainte-Thérèse': 873,
    'Salaberry-de-Valleyfield': 873,
    'Salluit': 873,
    'Senneterre': 873,
    'Sept-Îles': 873,
    'Shannon': 873,
    'Shawinigan': 873,
    'Shawville': 873,
    'Sherbrooke': 873,
    'Sorel-Tracy': 873,
    'St-Jean-Port-Joli': 873,
    'Sutton': 873,
    'Témiscaming': 873,
    'Terrasse-des-Pins': 873,
    'Terrebonne': 873,
    'Thetford-Mines': 873,
    'Thurso': 873,
    'Trois-Rivières': 873,
    'Val-d  Or': 873,
    'Val-David': 873,
    'Val-des-Monts': 873,
    'Val-Morin': 873,
    'Valcourt': 873,
    'Vallée-Jonction': 873,
    'Varennes': 873,
    'Vaudreuil-Dorion': 873,
    'Venise-en-Québec': 873,
    'Verchères': 873,
    'Victoriaville': 873,
    'Ville-Marie': 873,
    'Wakefield': 873,
    'Warwick': 873,
    'Waskaganish': 873,
    'Waswanipi': 873,
    'Waterloo': 873,
    'Weedon Centre': 873,
    'Westmount': 873,
    'Weymontachie': 873,
    'Windsor': 873,
    'Yamachiche': 873,
    'Assiniboia': 870,
    'Biggar': 870,
    'Canora': 870,
    'Carlyle': 870,
    'Dalmeny': 870,
    'Esterhazy': 870,
    'Estevan': 870,
    'Foam Lake': 870,
    'Gravelbourg': 870,
    'Hudson Bay': 870,
    'Humboldt': 870,
    'Indian Head': 870,
    'Kamsack': 870,
    'Kerrobert': 870,
    'Kindersley': 870,
    'La Ronge': 870,
    'Langenburg': 870,
    'Langham': 870,
    'Lanigan': 870,
    'Lumsden': 870,
    'Macklin': 870,
    'Maple Creek': 870,
    'Martensville': 870,
    'Meadow Lake': 870,
    'Melfort': 870,
    'Melville': 870,
    'Moose Jaw': 870,
    'Moosomin': 870,
    'Nipawin': 870,
    'North Battleford': 870,
    'Outlook': 870,
    'Oxbow': 870,
    'Pelican Narrows': 870,
    'Pilot Butte': 870,
    'Preeceville': 870,
    'Prince Albert': 870,
    'Regina': 870,
    'Regina Beach': 870,
    'Rosetown': 870,
    'Rosthern': 870,
    'Saskatoon': 870,
    'Shaunavon': 870,
    'Shellbrook': 870,
    'Swift Current': 870,
    'Tisdale': 870,
    'Unity': 870,
    'Wadena': 870,
    'Warman': 870,
    'Watrous': 870,
    'Weyburn': 870,
    'White City': 870,
    'Wilkie': 870,
    'Wynyard': 870,
    'Yorkton': 870,
    'Dawson City': 869,
    'Haines Junction': 869,
    'Watson Lake': 869,
    'Whitehorse': 869,
    'Sal Rei': 2999,
    'Nova Sintra': 2996,
    'Vila do Maio': 2991,
    'Igreja': 2987,
    'Pombas': 2997,
    'Porto Novo': 2989,
    'Praia': 2988,
    'Ribeira Brava': 2982,
    'Ponta do Sol': 3002,
    'Ribeira Grande': 3002,
    'Cidade Velha': 2984,
    'Espargos': 2998,
    'Santa Maria': 2998,
    'Assomada': 2985,
    'Cova Figueira': 2995,
    'Pedra Badejo': 3004,
    'Santa Cruz': 3004,
    'São Domingos': 2986,
    'São Filipe': 3000,
    'João Teves': 2993,
    'Calheta': 2990,
    'Mindelo': 3001,
    'Tarrafal': 2983,
    'Tarrafal de São Nicolau': 3003,
    'Bamingui': 1259,
    'Ndélé': 1259,
    'Bangui': 1262,
    'Alindao': 1264,
    'Kembé': 1264,
    'Mobaye': 1264,
    'Obo': 1258,
    'Zemio': 1258,
    'Bria': 1268,
    'Ouadda': 1268,
    'Sibut': 1263,
    'Boda': 1256,
    'Boganangone': 1256,
    'Mbaiki': 1256,
    'Mongoumba': 1256,
    'Berberati': 1257,
    'Carnot': 1257,
    'Gamboula': 1257,
    'Bangassou': 1266,
    'Gambo': 1266,
    'Ouango': 1266,
    'Rafai': 1266,
    'Kaga Bandoro': 1253,
    'Kaga-Bandoro': 1253,
    'Baoro': 1260,
    'Bouar': 1260,
    'Bimbo': 1255,
    'Boali': 1255,
    'Damara': 1255,
    'Bambari': 1265,
    'Grimari': 1265,
    'Ippy': 1265,
    'Kouango': 1265,
    'Batangafo': 1254,
    'Bossangoa': 1254,
    'Bouca': 1254,
    'Kabo': 1254,
    'Bocaranga': 1267,
    'Bozoum': 1267,
    'Paoua': 1267,
    'Nola': 1252,
    'Birao': 1261,
    'Ouanda-Djallé': 1261,
    'Moussoro': 3583,
    'Ati': 3590,
    'Oum Hadjer': 3590,
    'Faya-Largeau': 3574,
    'Fada': 3584,
    'Bitkine': 3576,
    'Melfi': 3576,
    'Mongo': 3576,
    'Bokoro': 3573,
    'Massaguet': 3573,
    'Massakory': 3573,
    'Mao': 3588,
    'Bol': 3577,
    'Beïnamar': 3585,
    'Benoy': 3585,
    'Lac Wey': 3585,
    'Moundou': 3585,
    'Bébédja': 3591,
    'Béboto': 3591,
    'Doba': 3591,
    'Goundi': 3589,
    'Koumra': 3589,
    'Moïssala': 3589,
    'Bongor': 3580,
    'Gounou Gaya': 3580,
    'Guelendeng': 3580,
    'Mboursou Léré': 3571,
    'Pala': 3571,
    'Kyabé': 3570,
    'Sarh': 3570,
    'Abéché': 3582,
    'Adré': 3582,
    'Goz Béïda': 3582,
    'Am Timan': 3592,
    'Goz Beïda': 3572,
    'Béré': 3579,
    'Kelo': 3579,
    'Laï': 3579,
    'Aozou': 3587,
    'Biltine': 3581,
    'Iriba': 3581,
    'Aysén': 2828,
    'Chile Chico': 2828,
    'Cisnes': 2828,
    'Cochrane': 2828,
    'Coyhaique': 2828,
    'Guaitecas': 2828,
    'Lago Verde': 2828,
    'O  Higgins': 2828,
    'Río Ibáñez': 2828,
    'Tortel': 2828,
    'Antofagasta': 2832,
    'Calama': 2832,
    'María Elena': 2832,
    'Mejillones': 2832,
    'Ollagüe': 2832,
    'San Pedro de Atacama': 2832,
    'Sierra Gorda': 2832,
    'Taltal': 2832,
    'Tocopilla': 2832,
    'Arica': 2829,
    'Camarones': 2829,
    'General Lagos': 2829,
    'Putre': 2829,
    'Alto del Carmen': 2823,
    'Caldera': 2823,
    'Chañaral': 2823,
    'Copiapó': 2823,
    'Diego de Almagro': 2823,
    'Freirina': 2823,
    'Huasco': 2823,
    'Tierra Amarilla': 2823,
    'Vallenar': 2823,
    'Alto Biobío': 2827,
    'Antuco': 2827,
    'Arauco': 2827,
    'Cabrero': 2827,
    'Cañete': 2827,
    'Chiguayante': 2827,
    'Concepción': 2827,
    'Contulmo': 2827,
    'Coronel': 2827,
    'Curanilahue': 2827,
    'Florida': 2827,
    'Hualpén': 2827,
    'Hualqui': 2827,
    'Laja': 2827,
    'Lebu': 2827,
    'Los Álamos': 2827,
    'Los Ángeles': 2827,
    'Lota': 2827,
    'Mulchén': 2827,
    'Nacimiento': 2827,
    'Negrete': 2827,
    'Penco': 2827,
    'Quilaco': 2827,
    'Quilleco': 2827,
    'San Pedro de la Paz': 2827,
    'San Rosendo': 2827,
    'Santa Bárbara': 2827,
    'Santa Juana': 2827,
    'Talcahuano': 2827,
    'Tirúa': 2827,
    'Tomé': 2827,
    'Tucapel': 2827,
    'Yumbel': 2827,
    'Andacollo': 2825,
    'Canela': 2825,
    'Combarbalá': 2825,
    'Coquimbo': 2825,
    'Illapel': 2825,
    'La Higuera': 2825,
    'La Serena': 2825,
    'Los Vilos': 2825,
    'Monte Patria': 2825,
    'Ovalle': 2825,
    'Paihuano': 2825,
    'Punitaqui': 2825,
    'Río Hurtado': 2825,
    'Salamanca': 2825,
    'Vicuña': 2825,
    'Angol': 2826,
    'Carahue': 2826,
    'Cholchol': 2826,
    'Collipulli': 2826,
    'Cunco': 2826,
    'Curacautín': 2826,
    'Curarrehue': 2826,
    'Ercilla': 2826,
    'Freire': 2826,
    'Galvarino': 2826,
    'Gorbea': 2826,
    'Lautaro': 2826,
    'Loncoche': 2826,
    'Lonquimay': 2826,
    'Los Sauces': 2826,
    'Lumaco': 2826,
    'Melipeuco': 2826,
    'Nueva Imperial': 2826,
    'Padre Las Casas': 2826,
    'Perquenco': 2826,
    'Pitrufquén': 2826,
    'Pucón': 2826,
    'Purén': 2826,
    'Renaico': 2826,
    'Saavedra': 2826,
    'Temuco': 2826,
    'Teodoro Schmidt': 2826,
    'Toltén': 2826,
    'Traiguén': 2826,
    'Victoria': 2826,
    'Vilcún': 2826,
    'Villarrica': 2826,
    'Chépica': 2838,
    'Chimbarongo': 2838,
    'Codegua': 2838,
    'Coínco': 2838,
    'Coltauco': 2838,
    'Doñihue': 2838,
    'Graneros': 2838,
    'La Estrella': 2838,
    'Las Cabras': 2838,
    'Litueche': 2838,
    'Lolol': 2838,
    'Machalí': 2838,
    'Malloa': 2838,
    'Marchigüe': 2838,
    'Mostazal': 2838,
    'Nancagua': 2838,
    'Navidad': 2838,
    'Olivar': 2838,
    'Palmilla': 2838,
    'Paredones': 2838,
    'Peralillo': 2838,
    'Peumo': 2838,
    'Pichidegua': 2838,
    'Pichilemu': 2838,
    'Placilla': 2838,
    'Pumanque': 2838,
    'Quinta de Tilcoco': 2838,
    'Rancagua': 2838,
    'Rengo': 2838,
    'Requínoa': 2838,
    'San Fernando': 2838,
    'San Vicente': 2838,
    'Santa Cruz': 2838,
    'Ancud': 2835,
    'Calbuco': 2835,
    'Castro': 2835,
    'Chaitén': 2835,
    'Chonchi': 2835,
    'Cochamó': 2835,
    'Curaco de Vélez': 2835,
    'Dalcahue': 2835,
    'Fresia': 2835,
    'Frutillar': 2835,
    'Futaleufú': 2835,
    'Hualaihué': 2835,
    'Llanquihue': 2835,
    'Los Muermos': 2835,
    'Maullín': 2835,
    'Osorno': 2835,
    'Palena': 2835,
    'Puerto Montt': 2835,
    'Puerto Octay': 2835,
    'Puerto Varas': 2835,
    'Puqueldón': 2835,
    'Purranque': 2835,
    'Puyehue': 2835,
    'Queilén': 2835,
    'Quellón': 2835,
    'Quemchi': 2835,
    'Quinchao': 2835,
    'Río Negro': 2835,
    'San Juan de la Costa': 2835,
    'San Pablo': 2835,
    'Corral': 2834,
    'Futrono': 2834,
    'La Unión': 2834,
    'Lago Ranco': 2834,
    'Lanco': 2834,
    'Los Lagos': 2834,
    'Máfil': 2834,
    'Mariquina': 2834,
    'Paillaco': 2834,
    'Panguipulli': 2834,
    'Río Bueno': 2834,
    'Valdivia': 2834,
    'Antártica': 2836,
    'Cabo de Hornos': 2836,
    'Laguna Blanca': 2836,
    'Natales': 2836,
    'Porvenir': 2836,
    'Primavera': 2836,
    'Punta Arenas': 2836,
    'Río Verde': 2836,
    'San Gregorio': 2836,
    'Timaukel': 2836,
    'Torres del Paine': 2836,
    'Cauquenes': 2833,
    'Chanco': 2833,
    'Colbún': 2833,
    'Constitución': 2833,
    'Curepto': 2833,
    'Curicó': 2833,
    'Empedrado': 2833,
    'Hualañé': 2833,
    'Licantén': 2833,
    'Linares': 2833,
    'Longaví': 2833,
    'Maule': 2833,
    'Molina': 2833,
    'Parral': 2833,
    'Pelarco': 2833,
    'Pelluhue': 2833,
    'Pencahue': 2833,
    'Rauco': 2833,
    'Retiro': 2833,
    'Río Claro': 2833,
    'Romeral': 2833,
    'Sagrada Familia': 2833,
    'San Clemente': 2833,
    'San Javier': 2833,
    'San Rafael': 2833,
    'Talca': 2833,
    'Teno': 2833,
    'Vichuquén': 2833,
    'Villa Alegre': 2833,
    'Yerbas Buenas': 2833,
    'Bulnes': 2831,
    'Chillán': 2831,
    'Chillán Viejo': 2831,
    'Cobquecura': 2831,
    'Coelemu': 2831,
    'Coihueco': 2831,
    'El Carmen': 2831,
    'Ninhue': 2831,
    'Ñiquén': 2831,
    'Pemuco': 2831,
    'Pinto': 2831,
    'Portezuelo': 2831,
    'Quillón': 2831,
    'Quirihue': 2831,
    'Ránquil': 2831,
    'San Carlos': 2831,
    'San Fabián': 2831,
    'San Ignacio': 2831,
    'San Nicolás': 2831,
    'Treguaco': 2831,
    'Yungay': 2831,
    'Alhué': 2824,
    'Buin': 2824,
    'Calera de Tango': 2824,
    'Cerrillos': 2824,
    'Cerro Navia': 2824,
    'Colina': 2824,
    'Conchalí': 2824,
    'Curacaví': 2824,
    'El Bosque': 2824,
    'El Monte': 2824,
    'Estación Central': 2824,
    'Huechuraba': 2824,
    'Independencia': 2824,
    'Isla de Maipo': 2824,
    'La Cisterna': 2824,
    'La Florida': 2824,
    'La Granja': 2824,
    'La Pintana': 2824,
    'La Reina': 2824,
    'Lampa': 2824,
    'Las Condes': 2824,
    'Lo Barnechea': 2824,
    'Lo Espejo': 2824,
    'Lo Prado': 2824,
    'Macul': 2824,
    'Maipú': 2824,
    'María Pinto': 2824,
    'Melipilla': 2824,
    'Ñuñoa': 2824,
    'Padre Hurtado': 2824,
    'Paine': 2824,
    'Pedro Aguirre Cerda': 2824,
    'Peñaflor': 2824,
    'Peñalolén': 2824,
    'Pirque': 2824,
    'Providencia': 2824,
    'Pudahuel': 2824,
    'Puente Alto': 2824,
    'Quilicura': 2824,
    'Quinta Normal': 2824,
    'Recoleta': 2824,
    'Renca': 2824,
    'San Bernardo': 2824,
    'San Joaquín': 2824,
    'San José de Maipo': 2824,
    'San Miguel': 2824,
    'San Pedro': 2824,
    'San Ramón': 2824,
    'Santiago': 2824,
    'Talagante': 2824,
    'Tiltil': 2824,
    'Vitacura': 2824,
    'Alto Hospicio': 2837,
    'Camiña': 2837,
    'Colchane': 2837,
    'Huara': 2837,
    'Iquique': 2837,
    'Pica': 2837,
    'Pozo Almonte': 2837,
    'Algarrobo': 2830,
    'Cabildo': 2830,
    'Calle Larga': 2830,
    'Cartagena': 2830,
    'Casablanca': 2830,
    'Catemu': 2830,
    'Concón': 2830,
    'El Quisco': 2830,
    'El Tabo': 2830,
    'Hijuelas': 2830,
    'Isla de Pascua': 2830,
    'Juan Fernández': 2830,
    'La Calera': 2830,
    'La Cruz': 2830,
    'La Ligua': 2830,
    'Limache': 2830,
    'Llaillay': 2830,
    'Los Andes': 2830,
    'Nogales': 2830,
    'Olmué': 2830,
    'Panquehue': 2830,
    'Papudo': 2830,
    'Petorca': 2830,
    'Puchuncaví': 2830,
    'Putaendo': 2830,
    'Quillota': 2830,
    'Quilpué': 2830,
    'Quintero': 2830,
    'Rinconada': 2830,
    'San Antonio': 2830,
    'San Esteban': 2830,
    'San Felipe': 2830,
    'Santa María': 2830,
    'Santo Domingo': 2830,
    'Valparaíso': 2830,
    'Villa Alemana': 2830,
    'Viña del Mar': 2830,
    'Zapallar': 2830,
    'Anqing': 2251,
    'Anqing Shi': 2251,
    'Bengbu': 2251,
    'Bozhou': 2251,
    'Chaohu': 2251,
    'Chizhou': 2251,
    'Chizhou Shi': 2251,
    'Chuzhou': 2251,
    'Chuzhou Shi': 2251,
    'Datong': 2251,
    'Fuyang': 2251,
    'Fuyang Shi': 2251,
    'Gushu': 2251,
    'Hefei': 2251,
    'Hefei Shi': 2251,
    'Huaibei': 2251,
    'Huainan': 2251,
    'Huainan Shi': 2251,
    'Huaiyuan Chengguanzhen': 2251,
    'Huangshan': 2251,
    'Huangshan Shi': 2251,
    'Huoqiu Chengguanzhen': 2251,
    'Jieshou': 2251,
    'Lu’an': 2251,
    'Lucheng': 2251,
    'Mengcheng Chengguanzhen': 2251,
    'Mingguang': 2251,
    'Suixi': 2251,
    'Suzhou': 2251,
    'Suzhou Shi': 2251,
    'Tangzhai': 2251,
    'Wucheng': 2251,
    'Wuhu': 2251,
    'Wusong': 2251,
    'Wuyang': 2251,
    'Xuanzhou': 2251,
    'Yingshang Chengguanzhen': 2251,
    'Beijing': 2257,
    'Changping': 2257,
    'Daxing': 2257,
    'Fangshan': 2257,
    'Liangxiang': 2257,
    'Mentougou': 2257,
    'Shunyi': 2257,
    'Tongzhou': 2257,
    'Beibei': 2271,
    'Caijia': 2271,
    'Chongqing': 2271,
    'Dongxi': 2271,
    'Fuling': 2271,
    'Ganshui': 2271,
    'Guofuchang': 2271,
    'Hechuan': 2271,
    'Jijiang': 2271,
    'Liangping District': 2271,
    'Puhechang': 2271,
    'Shapingba District': 2271,
    'Shijiaochang': 2271,
    'Wanxian': 2271,
    'Wanzhou District': 2271,
    'Yongchuan': 2271,
    'Yudong': 2271,
    'Yuzhong District': 2271,
    'Badu': 2248,
    'Baiqi': 2248,
    'Baiyun': 2248,
    'Beishancun': 2248,
    'Changqiao': 2248,
    'Chengmen': 2248,
    'Chixi': 2248,
    'Chongru': 2248,
    'Dadeng': 2248,
    'Daixi': 2248,
    'Danyang': 2248,
    'Daqiao': 2248,
    'Dazuo': 2248,
    'Dinghaicun': 2248,
    'Dingtoucun': 2248,
    'Dongchongcun': 2248,
    'Dongdai': 2248,
    'Donghu': 2248,
    'Dongling': 2248,
    'Dongyuan': 2248,
    'Feiluan': 2248,
    'Fengpu': 2248,
    'Fengzhou': 2248,
    'Fu’an': 2248,
    'Fuding': 2248,
    'Fuqing': 2248,
    'Fuzhou': 2248,
    'Gantang': 2248,
    'Guantou': 2248,
    'Gufeng': 2248,
    'Hetang': 2248,
    'Hongtang': 2248,
    'Hongyang': 2248,
    'Houyu': 2248,
    'Huai’an': 2248,
    'Huangtian': 2248,
    'Huotong': 2248,
    'Jian’ou': 2248,
    'Jiangkou': 2248,
    'Jianjiang': 2248,
    'Jingfeng': 2248,
    'Jinjiang': 2248,
    'Jinjing': 2248,
    'Jitoucun': 2248,
    'Kengyuan': 2248,
    'Kerencun': 2248,
    'Kuai’an': 2248,
    'Lianhecun': 2248,
    'Liuwudiancun': 2248,
    'Longmen': 2248,
    'Longyan': 2248,
    'Luoqiao': 2248,
    'Luoyang': 2248,
    'Luxia': 2248,
    'Maping': 2248,
    'Meipu': 2248,
    'Min’an': 2248,
    'Nanping': 2248,
    'Neikeng': 2248,
    'Ningde': 2248,
    'Pandu': 2248,
    'Pucheng': 2248,
    'Putian': 2248,
    'Qibu': 2248,
    'Qidu': 2248,
    'Quanzhou': 2248,
    'Rong’an': 2248,
    'Sanming': 2248,
    'Shajiang': 2248,
    'Shangjie': 2248,
    'Shanxia': 2248,
    'Shanyang': 2248,
    'Shaowu': 2248,
    'Shijing': 2248,
    'Shima': 2248,
    'Shoushan': 2248,
    'Shuangxi': 2248,
    'Shuangzhu': 2248,
    'Shuikou': 2248,
    'Tangkou': 2248,
    'Tantou': 2248,
    'Tatou': 2248,
    'Tingjiang': 2248,
    'Tuzhai': 2248,
    'Wubao': 2248,
    'Wuyishan': 2248,
    'Wuyucun': 2248,
    'Xiabaishi': 2248,
    'Xiahu': 2248,
    'Xiamen': 2248,
    'Xiancun': 2248,
    'Xiangyun': 2248,
    'Xibing': 2248,
    'Xiling': 2248,
    'Ximei': 2248,
    'Xinan': 2248,
    'Xindian': 2248,
    'Yakou': 2248,
    'Yanghou': 2248,
    'Yangzhong': 2248,
    'Yantian': 2248,
    'Yingdu': 2248,
    'Yinglin': 2248,
    'Yongning': 2248,
    'Yushan': 2248,
    'Zhangwan': 2248,
    'Zhangzhou': 2248,
    'Zhenhaicun': 2248,
    'Zhongfang': 2248,
    'Zhuoyang': 2248,
    'Zhuqi': 2248,
    'Baiyin': 2275,
    'Beidao': 2275,
    'Dingxi Shi': 2275,
    'Hezuo': 2275,
    'Jiayuguan': 2275,
    'Jinchang': 2275,
    'Jiuquan': 2275,
    'Lanzhou': 2275,
    'Laojunmiao': 2275,
    'Linxia Chengguanzhen': 2275,
    'Linxia Huizu Zizhizhou': 2275,
    'Longnan Shi': 2275,
    'Mawu': 2275,
    'Pingliang': 2275,
    'Qincheng': 2275,
    'Qingyang Shi': 2275,
    'Tianshui': 2275,
    'Wuwei': 2275,
    'Zhangye': 2275,
    'Zhangye Shi': 2275,
    'Anbu': 2279,
    'Chaozhou': 2279,
    'Chenghua': 2279,
    'Daliang': 2279,
    'Danshui': 2279,
    'Dasha': 2279,
    'Dongguan': 2279,
    'Donghai': 2279,
    'Ducheng': 2279,
    'Encheng': 2279,
    'Foshan': 2279,
    'Foshan Shi': 2279,
    'Gaoyao': 2279,
    'Gaozhou': 2279,
    'Guangzhou': 2279,
    'Guangzhou Shi': 2279,
    'Haikuotiankong': 2279,
    'Haimen': 2279,
    'Hepo': 2279,
    'Heyuan': 2279,
    'Huaicheng': 2279,
    'Huanggang': 2279,
    'Huazhou': 2279,
    'Huicheng': 2279,
    'Huizhou': 2279,
    'Humen': 2279,
    'Jiangmen': 2279,
    'Jiazi': 2279,
    'Jieshi': 2279,
    'Jieyang': 2279,
    'Lecheng': 2279,
    'Lianjiang': 2279,
    'Lianzhou': 2279,
    'Licheng': 2279,
    'Lubu': 2279,
    'Luocheng': 2279,
    'Luoyang': 2279,
    'Maba': 2279,
    'Maoming': 2279,
    'Meizhou': 2279,
    'Nanfeng': 2279,
    'Pingshan': 2279,
    'Puning': 2279,
    'Qingyuan': 2279,
    'Sanshui': 2279,
    'Shantou': 2279,
    'Shanwei': 2279,
    'Shaoguan': 2279,
    'Shaping': 2279,
    'Shenzhen': 2279,
    'Shilong': 2279,
    'Shiqi': 2279,
    'Shiqiao': 2279,
    'Shiwan': 2279,
    'Shixing': 2279,
    'Taishan': 2279,
    'Tangping': 2279,
    'Wuchuan': 2279,
    'Xingning': 2279,
    'Xinhui': 2279,
    'Xinyi': 2279,
    'Xiongzhou': 2279,
    'Xucheng': 2279,
    'Yangchun': 2279,
    'Yangjiang': 2279,
    'Yingcheng': 2279,
    'Yunfu': 2279,
    'Zhanjiang': 2279,
    'Zhaoqing': 2279,
    'Zhongshan': 2279,
    'Zhongshan Prefecture': 2279,
    'Zhuhai': 2279,
    'Babu': 2278,
    'Baihe': 2278,
    'Baise City': 2278,
    'Baise Shi': 2278,
    'Beihai': 2278,
    'Chongzuo Shi': 2278,
    'Dazhai': 2278,
    'Fangchenggang Shi': 2278,
    'Guigang': 2278,
    'Guilin': 2278,
    'Guilin Shi': 2278,
    'Guiping': 2278,
    'Hechi Shi': 2278,
    'Jinji': 2278,
    'Laibin': 2278,
    'Lianzhou': 2278,
    'Lingcheng': 2278,
    'Liuzhou Shi': 2278,
    'Luorong': 2278,
    'Nandu': 2278,
    'Nanning': 2278,
    'Pingnan': 2278,
    'Pumiao': 2278,
    'Qinzhou': 2278,
    'Wuzhou': 2278,
    'Yangshuo': 2278,
    'Yashan': 2278,
    'Yulin': 2278,
    'Anshun': 2261,
    'Aoshi': 2261,
    'Bahuang': 2261,
    'Baishi': 2261,
    'Bangdong': 2261,
    'Benchu': 2261,
    'Bijie': 2261,
    'Chadian': 2261,
    'Changsha': 2261,
    'Chumi': 2261,
    'Dabachang': 2261,
    'Darong': 2261,
    'Dundong': 2261,
    'Duyun': 2261,
    'Gaoniang': 2261,
    'Gaowu': 2261,
    'Gaozeng': 2261,
    'Guandu': 2261,
    'Guiyang': 2261,
    'Hongzhou': 2261,
    'Huaqiu': 2261,
    'Lantian': 2261,
    'Liangcunchang': 2261,
    'Liupanshui': 2261,
    'Longlisuo': 2261,
    'Loushanguan': 2261,
    'Maoping': 2261,
    'Ouyang': 2261,
    'Pingjiang': 2261,
    'Qiandongnan Miao and Dong Autonomous Prefecture': 2261,
    'Qianxinan Bouyeizu Miaozu Zizhizhou': 2261,
    'Qimeng': 2261,
    'Qinglang': 2261,
    'Runsong': 2261,
    'Sanchahe': 2261,
    'Sangmu': 2261,
    'Shiqian': 2261,
    'Songkan': 2261,
    'Tingdong': 2261,
    'Tonggu': 2261,
    'Tongren': 2261,
    'Tongren Diqu': 2261,
    'Weining': 2261,
    'Wenshui': 2261,
    'Xiajiang': 2261,
    'Xiaoweizhai': 2261,
    'Xinzhan': 2261,
    'Xishan': 2261,
    'Xujiaba': 2261,
    'Yangtou': 2261,
    'Youyupu': 2261,
    'Zhongchao': 2261,
    'Zhujiachang': 2261,
    'Zunyi': 2261,
    'Basuo': 2273,
    'Chongshan': 2273,
    'Dadonghai': 2273,
    'Haikou': 2273,
    'Jinjiang': 2273,
    'Lincheng': 2273,
    'Nada': 2273,
    'Qionghai': 2273,
    'Qiongshan': 2273,
    'Sansha': 2273,
    'Sanya': 2273,
    'Wanning': 2273,
    'Wenchang': 2273,
    'Xiuying': 2273,
    'Baoding': 2280,
    'Beidaihehaibin': 2280,
    'Botou': 2280,
    'Cangzhou': 2280,
    'Cangzhou Shi': 2280,
    'Changli': 2280,
    'Chengde': 2280,
    'Chengde Prefecture': 2280,
    'Dingzhou': 2280,
    'Fengrun': 2280,
    'Guye': 2280,
    'Handan': 2280,
    'Hecun': 2280,
    'Hengshui': 2280,
    'Langfang': 2280,
    'Langfang Shi': 2280,
    'Linshui': 2280,
    'Linxi': 2280,
    'Luancheng': 2280,
    'Nangong': 2280,
    'Pengcheng': 2280,
    'Qinhuangdao': 2280,
    'Renqiu': 2280,
    'Shahecheng': 2280,
    'Shanhaiguan': 2280,
    'Shijiazhuang': 2280,
    'Shijiazhuang Shi': 2280,
    'Songling': 2280,
    'Tangjiazhuang': 2280,
    'Tangshan': 2280,
    'Tangshan Shi': 2280,
    'Tianchang': 2280,
    'Xingtai': 2280,
    'Xinji': 2280,
    'Zhangjiakou': 2280,
    'Zhangjiakou Shi': 2280,
    'Zhangjiakou Shi Xuanhua Qu': 2280,
    'Zhaogezhuang': 2280,
    'Zunhua': 2280,
    'Acheng': 2265,
    'Anda': 2265,
    'Baiquan': 2265,
    'Bamiantong': 2265,
    'Baoqing': 2265,
    'Baoshan': 2265,
    'Bayan': 2265,
    'Bei’an': 2265,
    'Binzhou': 2265,
    'Boli': 2265,
    'Chaihe': 2265,
    'Chengzihe': 2265,
    'Daqing': 2265,
    'Dongning': 2265,
    'Dongxing': 2265,
    'Fendou': 2265,
    'Fengxiang': 2265,
    'Fujin': 2265,
    'Fuli': 2265,
    'Fuyu': 2265,
    'Fuyuan': 2265,
    'Gannan': 2265,
    'Hailin': 2265,
    'Hailun': 2265,
    'Harbin': 2265,
    'Hegang': 2265,
    'Heihe': 2265,
    'Honggang': 2265,
    'Huanan': 2265,
    'Hulan': 2265,
    'Hulan Ergi': 2265,
    'Jiamusi': 2265,
    'Jidong': 2265,
    'Jixi': 2265,
    'Langxiang': 2265,
    'Lanxi': 2265,
    'Lianhe': 2265,
    'Lingdong': 2265,
    'Linkou': 2265,
    'Longfeng': 2265,
    'Longjiang': 2265,
    'Mingshui': 2265,
    'Mishan': 2265,
    'Mudanjiang': 2265,
    'Nehe': 2265,
    'Nenjiang': 2265,
    'Nianzishan': 2265,
    'Ning’an': 2265,
    'Qinggang': 2265,
    'Qiqihar': 2265,
    'Shangzhi': 2265,
    'Shanhecun': 2265,
    'Shuangcheng': 2265,
    'Shuangyashan': 2265,
    'Suifenhe': 2265,
    'Suihua': 2265,
    'Suileng': 2265,
    'Tahe': 2265,
    'Taihecun': 2265,
    'Taikang': 2265,
    'Tailai': 2265,
    'Tieli': 2265,
    'Wangkui': 2265,
    'Wuchang': 2265,
    'Xinqing': 2265,
    'Yichun': 2265,
    'Yilan': 2265,
    'Youhao': 2265,
    'Zhaodong': 2265,
    'Zhaoyuan': 2265,
    'Zhaozhou': 2265,
    'Anyang': 2259,
    'Anyang Shi': 2259,
    'Binhe': 2259,
    'Chengguan': 2259,
    'Chengjiao': 2259,
    'Daokou': 2259,
    'Dingcheng': 2259,
    'Hancheng': 2259,
    'Hebi': 2259,
    'Huaidian': 2259,
    'Huazhou': 2259,
    'Huichang': 2259,
    'Jianshe': 2259,
    'Jiaozuo': 2259,
    'Jishui': 2259,
    'Jiyuan': 2259,
    'Kaifeng': 2259,
    'Kaiyuan': 2259,
    'Lingbao Chengguanzhen': 2259,
    'Luohe': 2259,
    'Luohe Shi': 2259,
    'Luoyang': 2259,
    'Minggang': 2259,
    'Nanyang': 2259,
    'Pingdingshan': 2259,
    'Puyang Chengguanzhen': 2259,
    'Puyang Shi': 2259,
    'Qingping': 2259,
    'Runing': 2259,
    'Ruzhou': 2259,
    'Shangqiu': 2259,
    'Songyang': 2259,
    'Suohe': 2259,
    'Tanbei': 2259,
    'Wacheng': 2259,
    'Xiangcheng Chengguanzhen': 2259,
    'Xincheng': 2259,
    'Xinhualu': 2259,
    'Xinxiang': 2259,
    'Xinxiang Shi': 2259,
    'Xinyang': 2259,
    'Xixiang': 2259,
    'Xuchang': 2259,
    'Xuchang Shi': 2259,
    'Yakou': 2259,
    'Yanshi Chengguanzhen': 2259,
    'Yigou': 2259,
    'Yima': 2259,
    'Yingchuan': 2259,
    'Yunyang': 2259,
    'Zhengzhou': 2259,
    'Zhoukou': 2259,
    'Zhumadian': 2259,
    'Zhumadian Shi': 2259,
    'Zijinglu': 2259,
    'Anlu': 2274,
    'Buhe': 2274,
    'Caidian': 2274,
    'Caohe': 2274,
    'Chengzhong': 2274,
    'Danjiangkou': 2274,
    'Daye': 2274,
    'Duobao': 2274,
    'Enshi': 2274,
    'Enshi Tujiazu Miaozu Zizhizhou': 2274,
    'Ezhou': 2274,
    'Ezhou Shi': 2274,
    'Fengkou': 2274,
    'Guangshui': 2274,
    'Gucheng Chengguanzhen': 2274,
    'Hanchuan': 2274,
    'Huanggang': 2274,
    'Huangmei': 2274,
    'Huangpi': 2274,
    'Huangshi': 2274,
    'Huangzhou': 2274,
    'Jingling': 2274,
    'Jingmen': 2274,
    'Jingmen Shi': 2274,
    'Jingzhou': 2274,
    'Laohekou': 2274,
    'Lichuan': 2274,
    'Macheng': 2274,
    'Nanzhang Chengguanzhen': 2274,
    'Puqi': 2274,
    'Qianjiang': 2274,
    'Qingquan': 2274,
    'Shashi': 2274,
    'Shennongjia': 2274,
    'Shiyan': 2274,
    'Suizhou': 2274,
    'Wuhan': 2274,
    'Wuxue': 2274,
    'Xiangyang': 2274,
    'Xianning': 2274,
    'Xianning Prefecture': 2274,
    'Xiantao': 2274,
    'Xiaogan': 2274,
    'Xihe': 2274,
    'Xindi': 2274,
    'Xinshi': 2274,
    'Xinzhou': 2274,
    'Xiulin': 2274,
    'Yichang': 2274,
    'Yicheng': 2274,
    'Yunmeng Chengguanzhen': 2274,
    'Zaoyang': 2274,
    'Zhicheng': 2274,
    'Zhijiang': 2274,
    'Zhongxiang': 2274,
    'Anjiang': 2258,
    'Anping': 2258,
    'Anxiang': 2258,
    'Baisha': 2258,
    'Biyong': 2258,
    'Bojia': 2258,
    'Boyang': 2258,
    'Bozhou': 2258,
    'Changde': 2258,
    'Changsha': 2258,
    'Changsha Shi': 2258,
    'Chatian': 2258,
    'Chenzhou': 2258,
    'Dabaozi': 2258,
    'Dehang': 2258,
    'Dengjiapu': 2258,
    'Dengyuantai': 2258,
    'Dongshan Dongzuxiang': 2258,
    'Fenghuang': 2258,
    'Gangdong': 2258,
    'Gaoqiao': 2258,
    'Gaoyi': 2258,
    'Guankou': 2258,
    'Hengbanqiao': 2258,
    'Hengyang': 2258,
    'Hexiangqiao': 2258,
    'Hongjiang': 2258,
    'Hongqiao': 2258,
    'Huaihua': 2258,
    'Huangjinjing': 2258,
    'Huanglong': 2258,
    'Huangmaoyuan': 2258,
    'Huangqiao': 2258,
    'Huangtukuang': 2258,
    'Huangxikou': 2258,
    'Huaqiao': 2258,
    'Huayuan': 2258,
    'Huomachong': 2258,
    'Jiangfang': 2258,
    'Jiangkouxu': 2258,
    'Jiangshi': 2258,
    'Jinhe': 2258,
    'Jinshi': 2258,
    'Jinshiqiao': 2258,
    'Lanli': 2258,
    'Leiyang': 2258,
    'Lengshuijiang': 2258,
    'Lengshuitan': 2258,
    'Liangyaping': 2258,
    'Lianyuan': 2258,
    'Linkou': 2258,
    'Liuduzhai': 2258,
    'Lixiqiao': 2258,
    'Longtan': 2258,
    'Longtou’an': 2258,
    'Loudi': 2258,
    'Luojiu': 2258,
    'Luyang': 2258,
    'Ma’an': 2258,
    'Malin': 2258,
    'Maoping': 2258,
    'Nanmuping': 2258,
    'Nanzhou': 2258,
    'Prefecture of Chenzhou': 2258,
    'Pukou': 2258,
    'Puzi': 2258,
    'Qiancheng': 2258,
    'Qianzhou': 2258,
    'Qiaojiang': 2258,
    'Qingjiangqiao': 2258,
    'Qingxi': 2258,
    'Qionghu': 2258,
    'Ruoshui': 2258,
    'Shangmei': 2258,
    'Shanmen': 2258,
    'Shijiang': 2258,
    'Shuangjiang': 2258,
    'Shuangxi': 2258,
    'Shuiche': 2258,
    'Shuidatian': 2258,
    'Simenqian': 2258,
    'Tangjiafang': 2258,
    'Tanwan': 2258,
    'Tongwan': 2258,
    'Tuokou': 2258,
    'Wantouqiao': 2258,
    'Wenxing': 2258,
    'Wulingyuan': 2258,
    'Wuxi': 2258,
    'Wuyang': 2258,
    'Xiangtan': 2258,
    'Xiangxi Tujiazu Miaozu Zizhizhou': 2258,
    'Xiangxiang': 2258,
    'Xianrenwan': 2258,
    'Xianxi': 2258,
    'Xiaohenglong': 2258,
    'Xiaolongmen': 2258,
    'Xiaoshajiang': 2258,
    'Xishan': 2258,
    'Xixi': 2258,
    'Xiyan': 2258,
    'Yanmen': 2258,
    'Yaoshi': 2258,
    'Yatunpu': 2258,
    'Yiyang': 2258,
    'Yongfeng': 2258,
    'Yongzhou': 2258,
    'Yueyang': 2258,
    'Yueyang Shi': 2258,
    'Yutan': 2258,
    'Zhaishi Miaozu Dongzuxiang': 2258,
    'Zhangjiajie': 2258,
    'Zhongfang': 2258,
    'Zhongzhai': 2258,
    'Zhushi': 2258,
    'Zhuzhou': 2258,
    'Zhuzhou Shi': 2258,
    'Zhuzhoujiang Miaozuxiang': 2258,
    'Baotou': 2269,
    'Bayan Nur': 2269,
    'Bayannur Shi': 2269,
    'Beichengqu': 2269,
    'Chifeng': 2269,
    'Dongsheng': 2269,
    'E’erguna': 2269,
    'Erenhot': 2269,
    'Genhe': 2269,
    'Hailar': 2269,
    'Hohhot': 2269,
    'Hulunbuir Region': 2269,
    'Jalai Nur': 2269,
    'Jiagedaqi': 2269,
    'Jining': 2269,
    'Manzhouli': 2269,
    'Mositai': 2269,
    'Mujiayingzi': 2269,
    'Ordos': 2269,
    'Ordos Shi': 2269,
    'Oroqen Zizhiqi': 2269,
    'Pingzhuang': 2269,
    'Salaqi': 2269,
    'Shiguai': 2269,
    'Tongliao': 2269,
    'Ulanhot': 2269,
    'Wenquan': 2269,
    'Wuda': 2269,
    'Wuhai': 2269,
    'Xilin Gol Meng': 2269,
    'Xilin Hot': 2269,
    'Yakeshi': 2269,
    'Zhalantun': 2269,
    'Changleng': 2256,
    'Fenyi': 2256,
    'Ganzhou': 2256,
    'Ganzhou Shi': 2256,
    'Guixi': 2256,
    'Ji’an': 2256,
    'Jianguang': 2256,
    'Jingdezhen': 2256,
    'Jingdezhen Shi': 2256,
    'Jiujiang': 2256,
    'Nanchang': 2256,
    'Pingxiang': 2256,
    'Poyang': 2256,
    'Shangrao': 2256,
    'Xinyu': 2256,
    'Yichun': 2256,
    'Baicheng': 2253,
    'Baishan': 2253,
    'Baishishan': 2253,
    'Changchun': 2253,
    'Changling': 2253,
    'Chaoyang': 2253,
    'Dalai': 2253,
    'Dashitou': 2253,
    'Dehui': 2253,
    'Dongfeng': 2253,
    'Dunhua': 2253,
    'Erdaojiang': 2253,
    'Fuyu': 2253,
    'Gongzhuling': 2253,
    'Guangming': 2253,
    'Helong': 2253,
    'Hepingjie': 2253,
    'Huadian': 2253,
    'Huangnihe': 2253,
    'Huinan': 2253,
    'Hunchun': 2253,
    'Ji’an': 2253,
    'Jilin': 2253,
    'Jishu': 2253,
    'Jiutai': 2253,
    'Kaitong': 2253,
    'Liaoyuan': 2253,
    'Linjiang': 2253,
    'Lishu': 2253,
    'Liuhe': 2253,
    'Longjing': 2253,
    'Meihekou': 2253,
    'Mingyue': 2253,
    'Minzhu': 2253,
    'Panshi': 2253,
    'Sanchazi': 2253,
    'Shuangyang': 2253,
    'Shulan': 2253,
    'Siping': 2253,
    'Songjianghe': 2253,
    'Songyuan': 2253,
    'Tonghua': 2253,
    'Tonghua Shi': 2253,
    'Tumen': 2253,
    'Wangqing': 2253,
    'Xinglongshan': 2253,
    'Yanbian Chaoxianzu Zizhizhou': 2253,
    'Yanji': 2253,
    'Yantongshan': 2253,
    'Yushu': 2253,
    'Zhengjiatun': 2253,
    'Zhenlai': 2253,
    'Anshan': 2268,
    'Beipiao': 2268,
    'Benxi': 2268,
    'Changtu': 2268,
    'Chaoyang': 2268,
    'Dalian': 2268,
    'Dalianwan': 2268,
    'Dandong': 2268,
    'Dashiqiao': 2268,
    'Dongling': 2268,
    'Fengcheng': 2268,
    'Fushun': 2268,
    'Fuxin': 2268,
    'Gaizhou': 2268,
    'Gongchangling': 2268,
    'Haicheng': 2268,
    'Heishan': 2268,
    'Huanren': 2268,
    'Huludao': 2268,
    'Huludao Shi': 2268,
    'Hushitai': 2268,
    'Jinzhou': 2268,
    'Jiupu': 2268,
    'Kaiyuan': 2268,
    'Kuandian': 2268,
    'Langtoucun': 2268,
    'Lianshan': 2268,
    'Liaoyang': 2268,
    'Liaozhong': 2268,
    'Linghai': 2268,
    'Lingyuan': 2268,
    'Lüshun': 2268,
    'Nanpiao': 2268,
    'Nantai': 2268,
    'Panjin Shi': 2268,
    'Panshan': 2268,
    'Pulandian': 2268,
    'Shenyang': 2268,
    'Sujiatun': 2268,
    'Tieling': 2268,
    'Tieling Shi': 2268,
    'Wafangdian': 2268,
    'Xiaoshi': 2268,
    'Xifeng': 2268,
    'Xingcheng': 2268,
    'Xinmin': 2268,
    'Xinxing': 2268,
    'Xiuyan': 2268,
    'Yebaishou': 2268,
    'Yingkou': 2268,
    'Zhuanghe': 2268,
    'Dawukou': 2262,
    'Dongta': 2262,
    'Shitanjing': 2262,
    'Shizuishan': 2262,
    'Wuzhong': 2262,
    'Yinchuan': 2262,
    'Zhongwei': 2262,
    'Delingha': 2270,
    'Golmud': 2270,
    'Golog Tibetan Autonomous Prefecture': 2270,
    'Haibei Tibetan Autonomous Prefecture': 2270,
    'Huangnan Zangzu Zizhizhou': 2270,
    'Xining': 2270,
    'Xireg': 2270,
    'Yushu Zangzu Zizhizhou': 2270,
    'Ankang': 2272,
    'Baoji Shi': 2272,
    'Guozhen': 2272,
    'Hancheng': 2272,
    'Hanzhong': 2272,
    'Huayin': 2272,
    'Lintong': 2272,
    'Tongchuanshi': 2272,
    'Weinan': 2272,
    'Xi’an': 2272,
    'Xianyang': 2272,
    'Yanliang': 2272,
    'Yulinshi': 2272,
    'Yuxia': 2272,
    'Anqiu': 2252,
    'Beizhai': 2252,
    'Bianzhuang': 2252,
    'Binzhou': 2252,
    'Boshan': 2252,
    'Changqing': 2252,
    'Chengqu': 2252,
    'Chengtangcun': 2252,
    'Chengyang': 2252,
    'Dengying': 2252,
    'Dengzhou': 2252,
    'Dezhou': 2252,
    'Dingtao': 2252,
    'Dongcun': 2252,
    'Dongdu': 2252,
    'Feicheng': 2252,
    'Gaomi': 2252,
    'Hanting': 2252,
    'Heze': 2252,
    'Jiamaying': 2252,
    'Jiaozhou': 2252,
    'Jiehu': 2252,
    'Jimo': 2252,
    'Jinan': 2252,
    'Jining': 2252,
    'Juye': 2252,
    'Kuiju': 2252,
    'Kutao': 2252,
    'Laiwu': 2252,
    'Laixi': 2252,
    'Laiyang': 2252,
    'Laizhou': 2252,
    'Laocheng': 2252,
    'Liaocheng': 2252,
    'Linqu': 2252,
    'Linyi': 2252,
    'Longgang': 2252,
    'Mengyin': 2252,
    'Mingshui': 2252,
    'Mizhou': 2252,
    'Nanding': 2252,
    'Nanma': 2252,
    'Ninghai': 2252,
    'Ningyang': 2252,
    'Pingdu': 2252,
    'Pingyi': 2252,
    'Pingyin': 2252,
    'Qingdao': 2252,
    'Qingnian': 2252,
    'Qingshancun': 2252,
    'Qingyang': 2252,
    'Qingzhou': 2252,
    'Qufu': 2252,
    'Rizhao': 2252,
    'Shancheng': 2252,
    'Shanting': 2252,
    'Shazikou': 2252,
    'Shengli': 2252,
    'Shilaorencun': 2252,
    'Shizilu': 2252,
    'Shouguang': 2252,
    'Sishui': 2252,
    'Suozhen': 2252,
    'Tai’an': 2252,
    'Taozhuang': 2252,
    'Tianfu': 2252,
    'Weifang': 2252,
    'Weihai': 2252,
    'Wenshang': 2252,
    'Xiazhen': 2252,
    'Xiazhuang': 2252,
    'Xiliguantun': 2252,
    'Xindian': 2252,
    'Xintai': 2252,
    'Yanggu': 2252,
    'Yanta': 2252,
    'Yantai': 2252,
    'Yanzhou': 2252,
    'Yatou': 2252,
    'Yinzhu': 2252,
    'Yishui': 2252,
    'Yucheng': 2252,
    'Zaozhuang': 2252,
    'Zhaoyuan': 2252,
    'Zhoucheng': 2252,
    'Zhoucun': 2252,
    'Zhu Cheng City': 2252,
    'Zhuangyuan': 2252,
    'Zibo': 2252,
    'Zoucheng': 2252,
    'Shanghai': 2249,
    'Songjiang': 2249,
    'Zhabei': 2249,
    'Zhujiajiao': 2249,
    'Changzhi': 2254,
    'Datong': 2254,
    'Datong Shi': 2254,
    'Gutao': 2254,
    'Jiexiu': 2254,
    'Jincheng': 2254,
    'Jinzhong Shi': 2254,
    'Linfen': 2254,
    'Lüliang': 2254,
    'Shuozhou': 2254,
    'Taiyuan': 2254,
    'Xintian': 2254,
    'Xinzhi': 2254,
    'Xinzhou': 2254,
    'Yangquan': 2254,
    'Yuanping': 2254,
    'Yuci': 2254,
    'Yuncheng': 2254,
    'Aba Zangzu Qiangzu Zizhizhou': 2277,
    'Barkam': 2277,
    'Bazhong Shi': 2277,
    'Changchi': 2277,
    'Chengdu': 2277,
    'Chonglong': 2277,
    'Dadukou': 2277,
    'Dazhou': 2277,
    'Deyang': 2277,
    'Dongxi': 2277,
    'Fangting': 2277,
    'Fubao': 2277,
    'Gaoping': 2277,
    'Garzê Zangzu Zizhizhou': 2277,
    'Guang’an': 2277,
    'Guangyuan': 2277,
    'Jiancheng': 2277,
    'Jiangyou': 2277,
    'Jiannan': 2277,
    'Kangding': 2277,
    'Langzhong': 2277,
    'Leshan': 2277,
    'Liangshan Yizu Zizhizhou': 2277,
    'Linqiong': 2277,
    'Luocheng': 2277,
    'Luzhou': 2277,
    'Meishan Shi': 2277,
    'Mianyang': 2277,
    'Nanchong': 2277,
    'Nanlong': 2277,
    'Neijiang': 2277,
    'Panzhihua': 2277,
    'Puji': 2277,
    'Shuanghejiedao': 2277,
    'Suining': 2277,
    'Taihe': 2277,
    'Taiping': 2277,
    'Tianpeng': 2277,
    'Tongchuan': 2277,
    'Xialiang': 2277,
    'Xiantan': 2277,
    'Xichang': 2277,
    'Xunchang': 2277,
    'Yanjiang': 2277,
    'Yibin': 2277,
    'Yucheng': 2277,
    'Zengjia': 2277,
    'Zhongba': 2277,
    'Zigong': 2277,
    'Baoying': 2255,
    'Changshu City': 2255,
    'Changzhou': 2255,
    'Chengxiang': 2255,
    'Dazhong': 2255,
    'Dongkan': 2255,
    'Dongtai': 2255,
    'Fengxian': 2255,
    'Gaogou': 2255,
    'Gaoyou': 2255,
    'Guiren': 2255,
    'Haizhou': 2255,
    'Hede': 2255,
    'Huai  an': 2255,
    'Huai’an Shi': 2255,
    'Huilong': 2255,
    'Hutang': 2255,
    'Jiangyan': 2255,
    'Jiangyin': 2255,
    'Jingjiang': 2255,
    'Jinsha': 2255,
    'Juegang': 2255,
    'Kunshan': 2255,
    'Lianyungang Shi': 2255,
    'Licheng': 2255,
    'Mudu': 2255,
    'Nanjing': 2255,
    'Nantong': 2255,
    'Pizhou': 2255,
    'Qinnan': 2255,
    'Rucheng': 2255,
    'Sanmao': 2255,
    'Songling': 2255,
    'Suicheng': 2255,
    'Suzhou': 2255,
    'Taixing': 2255,
    'Taizhou': 2255,
    'Tongshan': 2255,
    'Wuxi': 2255,
    'Xiannü': 2255,
    'Xiaolingwei': 2255,
    'Xinghua': 2255,
    'Xinpu': 2255,
    'Yancheng': 2255,
    'Yangzhou': 2255,
    'Yicheng': 2255,
    'Yushan': 2255,
    'Zhangjiagang': 2255,
    'Zhenjiang': 2255,
    'Zhenzhou': 2255,
    'Zhongxing': 2255,
    'Zhouzhuang': 2255,
    'Burang': 2264,
    'Dêqên': 2264,
    'Jiangzi': 2264,
    'Lhasa': 2264,
    'Nagqu': 2264,
    'Nagqu Diqu': 2264,
    'Ngari Diqu': 2264,
    'Nyingchi Prefecture': 2264,
    'Qamdo': 2264,
    'Qamdo Shi': 2264,
    'Rikaze': 2264,
    'Saga': 2264,
    'Shannan Diqu': 2264,
    'Ailan Mubage': 2263,
    'Aksu': 2263,
    'Aksu Diqu': 2263,
    'Altay': 2263,
    'Altay Diqu': 2263,
    'Aral': 2263,
    'Aykol': 2263,
    'Baijiantan': 2263,
    'Baluntaicun': 2263,
    'Bayingolin Mongol Zizhizhou': 2263,
    'Changji': 2263,
    'Changji Huizu Zizhizhou': 2263,
    'Fukang': 2263,
    'Hami': 2263,
    'Hotan': 2263,
    'Hoxtolgay': 2263,
    'Huocheng': 2263,
    'Ili Kazak Zizhizhou': 2263,
    'Karamay': 2263,
    'Karamay Shi': 2263,
    'Kashgar': 2263,
    'Kaxgar Diqu': 2263,
    'Korla': 2263,
    'Kuqa': 2263,
    'Kuytun': 2263,
    'Qapqal': 2263,
    'Shache': 2263,
    'Shihezi': 2263,
    'Sishilichengzi': 2263,
    'Tacheng': 2263,
    'Tacheng Diqu': 2263,
    'Turpan': 2263,
    'Turpan Diqu': 2263,
    'Ürümqi': 2263,
    'Urumqi Shi': 2263,
    'Xinyuan': 2263,
    'Zangguy': 2263,
    'Chuxiong Yizu Zizhizhou': 2260,
    'Dali': 2260,
    'Dali Baizu Zizhizhou': 2260,
    'Dehong Daizu Jingpozu Zizhizhou': 2260,
    'Dêqên Tibetan Autonomous Prefecture': 2260,
    'Gejiu': 2260,
    'Haikou': 2260,
    'Honghe Hanizu Yizu Zizhizhou': 2260,
    'Jinghong': 2260,
    'Kaihua': 2260,
    'Kaiyuan': 2260,
    'Kunming': 2260,
    'Lianran': 2260,
    'Lijiang': 2260,
    'Lincang Shi': 2260,
    'Longquan': 2260,
    'Mabai': 2260,
    'Majie': 2260,
    'Miyang': 2260,
    'Nujiang Lisuzu Zizhizhou': 2260,
    'Qujing': 2260,
    'Shangri-La': 2260,
    'Shilin': 2260,
    'Wenlan': 2260,
    'Wenshan City': 2260,
    'Wenshan Zhuangzu Miaozu Zizhizhou': 2260,
    'Yuxi': 2260,
    'Zhaotong': 2260,
    'Zhongshu': 2260,
    'Deqing': 2247,
    'Dongyang': 2247,
    'Fenghua': 2247,
    'Fuyang': 2247,
    'Guli': 2247,
    'Haining': 2247,
    'Hangzhou': 2247,
    'Huangyan': 2247,
    'Huzhou': 2247,
    'Jiaojiang': 2247,
    'Jiashan': 2247,
    'Jiaxing': 2247,
    'Jiaxing Shi': 2247,
    'Jinhua': 2247,
    'Jinxiang': 2247,
    'Kunyang': 2247,
    'Lanxi': 2247,
    'Lianghu': 2247,
    'Linhai': 2247,
    'Linping': 2247,
    'Lishui': 2247,
    'Luqiao': 2247,
    'Ningbo': 2247,
    'Ninghai': 2247,
    'Puyang': 2247,
    'Quzhou': 2247,
    'Shangyu': 2247,
    'Shaoxing': 2247,
    'Shenjiamen': 2247,
    'Taizhou': 2247,
    'Wenling': 2247,
    'Wenzhou': 2247,
    'Wuzhen': 2247,
    'Xianju': 2247,
    'Xiaoshan': 2247,
    'Yiwu': 2247,
    'Yuyao': 2247,
    'Zhaobaoshan': 2247,
    'Zhicheng': 2247,
    'Zhoushan': 2247,
    'Zhuji': 2247,
    'El Encanto': 2895,
    'La Chorrera': 2895,
    'La Pedrera': 2895,
    'La Victoria': 2895,
    'Leticia': 2895,
    'Miriti - Paraná': 2895,
    'Puerto Alegría': 2895,
    'Puerto Arica': 2895,
    'Puerto Nariño': 2895,
    'Puerto Santander': 2895,
    'Tarapacá': 2895,
    'Abejorral': 2890,
    'Abriaquí': 2890,
    'Alejandría': 2890,
    'Amagá': 2890,
    'Amalfi': 2890,
    'Andes': 2890,
    'Angelópolis': 2890,
    'Angostura': 2890,
    'Anza': 2890,
    'Apartadó': 2890,
    'Arboletes': 2890,
    'Argelia': 2890,
    'Armenia': 2890,
    'Barbosa': 2890,
    'Bello': 2890,
    'Belmira': 2890,
    'Betania': 2890,
    'Betulia': 2890,
    'Briceño': 2890,
    'Buriticá': 2890,
    'Cáceres': 2890,
    'Caicedo': 2890,
    'Caldas': 2890,
    'Campamento': 2890,
    'Cañasgordas': 2890,
    'Caracolí': 2890,
    'Caramanta': 2890,
    'Carepa': 2890,
    'Carmen de Viboral': 2890,
    'Carolina': 2890,
    'Caucasia': 2890,
    'Chigorodó': 2890,
    'Cisneros': 2890,
    'Ciudad Bolívar': 2890,
    'Cocorná': 2890,
    'Concepción': 2890,
    'Concordia': 2890,
    'Copacabana': 2890,
    'Cruces de Anorí': 2890,
    'Dabeiba': 2890,
    'Don Matías': 2890,
    'Donmatías': 2890,
    'Ebéjico': 2890,
    'El Bagre': 2890,
    'El Carmen de Viboral': 2890,
    'El Santuario': 2890,
    'Entrerrios': 2890,
    'Envigado': 2890,
    'Fredonia': 2890,
    'Frontino': 2890,
    'Giraldo': 2890,
    'Girardota': 2890,
    'Gómez Plata': 2890,
    'Granada': 2890,
    'Guadalupe': 2890,
    'Guarne': 2890,
    'Guatapé': 2890,
    'Heliconia': 2890,
    'Hispania': 2890,
    'Itagui': 2890,
    'Ituango': 2890,
    'Jardín': 2890,
    'Jericó': 2890,
    'La Ceja': 2890,
    'La Estrella': 2890,
    'La Pintada': 2890,
    'La Unión': 2890,
    'Liborina': 2890,
    'Maceo': 2890,
    'Marinilla': 2890,
    'Medellín': 2890,
    'Montebello': 2890,
    'Municipio de San Pedro de los Milagros': 2890,
    'Murindó': 2890,
    'Mutatá': 2890,
    'Nariño': 2890,
    'Nechí': 2890,
    'Necoclí': 2890,
    'Olaya': 2890,
    'Peñol': 2890,
    'Peque': 2890,
    'Pueblorrico': 2890,
    'Puerto Berrío': 2890,
    'Puerto Nare': 2890,
    'Puerto Triunfo': 2890,
    'Remedios': 2890,
    'Retiro': 2890,
    'Rionegro': 2890,
    'Sabanalarga': 2890,
    'Sabaneta': 2890,
    'Salgar': 2890,
    'San Andrés': 2890,
    'San Andrés de Cuerquía': 2890,
    'San Carlos': 2890,
    'San Francisco': 2890,
    'San Jerónimo': 2890,
    'San José de la Montaña': 2890,
    'San Juan de Urabá': 2890,
    'San Pedro': 2890,
    'San Pedro de Uraba': 2890,
    'San Rafael': 2890,
    'San Roque': 2890,
    'San Vicente': 2890,
    'San Vicente Ferrer': 2890,
    'Santa Bárbara': 2890,
    'Santa Fe de Antioquia': 2890,
    'Santa Rosa de Osos': 2890,
    'Santafé de Antioquia': 2890,
    'Santo Domingo': 2890,
    'Santuario': 2890,
    'Segovia': 2890,
    'Sonson': 2890,
    'Sopetrán': 2890,
    'Támesis': 2890,
    'Tarazá': 2890,
    'Tarso': 2890,
    'Titiribí': 2890,
    'Toledo': 2890,
    'Turbo': 2890,
    'Uramita': 2890,
    'Urrao': 2890,
    'Valdivia': 2890,
    'Valparaíso': 2890,
    'Vegachí': 2890,
    'Venecia': 2890,
    'Vigía del Fuerte': 2890,
    'Yalí': 2890,
    'Yarumal': 2890,
    'Yolombó': 2890,
    'Yondó': 2890,
    'Zaragoza': 2890,
    'Arauca': 2881,
    'Arauquita': 2881,
    'Cravo Norte': 2881,
    'Fortul': 2881,
    'Puerto Rondón': 2881,
    'Saravena': 2881,
    'Tame': 2881,
    'Baranoa': 2880,
    'Barranquilla': 2880,
    'Campo de la Cruz': 2880,
    'Candelaria': 2880,
    'Galapa': 2880,
    'Juan de Acosta': 2880,
    'Luruaco': 2880,
    'Malambo': 2880,
    'Manatí': 2880,
    'Palmar de Varela': 2880,
    'Piojó': 2880,
    'Polonuevo': 2880,
    'Ponedera': 2880,
    'Puerto Colombia': 2880,
    'Repelón': 2880,
    'Sabanagrande': 2880,
    'Sabanalarga': 2880,
    'Santa Lucía': 2880,
    'Santo Tomás': 2880,
    'Soledad': 2880,
    'Suan': 2880,
    'Tubará': 2880,
    'Usiacurí': 2880,
    'Achí': 2893,
    'Altos del Rosario': 2893,
    'Arenal': 2893,
    'Arjona': 2893,
    'Arroyohondo': 2893,
    'Barranco de Loba': 2893,
    'Calamar': 2893,
    'Cantagallo': 2893,
    'Cartagena': 2893,
    'Cicuco': 2893,
    'Clemencia': 2893,
    'Córdoba': 2893,
    'El Carmen de Bolívar': 2893,
    'El Guamo': 2893,
    'El Peñón': 2893,
    'Hatillo de Loba': 2893,
    'Magangué': 2893,
    'Mahates': 2893,
    'Margarita': 2893,
    'María la Baja': 2893,
    'Mompós': 2893,
    'Montecristo': 2893,
    'Morales': 2893,
    'Municipio de Cartagena de Indias': 2893,
    'Municipio de María la Baja': 2893,
    'Norosí': 2893,
    'Pinillos': 2893,
    'Regidor': 2893,
    'Río Viejo': 2893,
    'San Cristóbal': 2893,
    'San Estanislao': 2893,
    'San Fernando': 2893,
    'San Jacinto': 2893,
    'San Jacinto del Cauca': 2893,
    'San Juan Nepomuceno': 2893,
    'San Martín de Loba': 2893,
    'San Pablo': 2893,
    'Santa Catalina': 2893,
    'Santa Rosa': 2893,
    'Santa Rosa del Sur': 2893,
    'Simití': 2893,
    'Soplaviento': 2893,
    'Talaigua Nuevo': 2893,
    'Talaigua Viejo': 2893,
    'Tiquisio': 2893,
    'Turbaco': 2893,
    'Turbaná': 2893,
    'Villanueva': 2893,
    'Zambrano': 2893,
    'Almeida': 2903,
    'Aquitania': 2903,
    'Arcabuco': 2903,
    'Belén': 2903,
    'Berbeo': 2903,
    'Betéitiva': 2903,
    'Boavita': 2903,
    'Boyacá': 2903,
    'Briceño': 2903,
    'Buenavista': 2903,
    'Busbanzá': 2903,
    'Caldas': 2903,
    'Campohermoso': 2903,
    'Cerinza': 2903,
    'Chinavita': 2903,
    'Chiquinquirá': 2903,
    'Chíquiza': 2903,
    'Chiscas': 2903,
    'Chita': 2903,
    'Chitaraque': 2903,
    'Chivatá': 2903,
    'Chivor': 2903,
    'Ciénega': 2903,
    'Combita': 2903,
    'Coper': 2903,
    'Corrales': 2903,
    'Covarachía': 2903,
    'Cubará': 2903,
    'Cucaita': 2903,
    'Cuítiva': 2903,
    'Duitama': 2903,
    'El Cocuy': 2903,
    'El Espino': 2903,
    'Firavitoba': 2903,
    'Floresta': 2903,
    'Gachantivá': 2903,
    'Gameza': 2903,
    'Garagoa': 2903,
    'Guacamayas': 2903,
    'Guateque': 2903,
    'Guayatá': 2903,
    'Güicán': 2903,
    'Iza': 2903,
    'Jenesano': 2903,
    'Jericó': 2903,
    'La Capilla': 2903,
    'La Uvita': 2903,
    'La Victoria': 2903,
    'Labranzagrande': 2903,
    'Macanal': 2903,
    'Maripí': 2903,
    'Miraflores': 2903,
    'Mongua': 2903,
    'Monguí': 2903,
    'Moniquirá': 2903,
    'Motavita': 2903,
    'Muzo': 2903,
    'Nobsa': 2903,
    'Nuevo Colón': 2903,
    'Oicatá': 2903,
    'Otanche': 2903,
    'Pachavita': 2903,
    'Páez': 2903,
    'Paipa': 2903,
    'Pajarito': 2903,
    'Panqueba': 2903,
    'Pauna': 2903,
    'Paya': 2903,
    'Paz de Río': 2903,
    'Pesca': 2903,
    'Pisba': 2903,
    'Puerto Boyacá': 2903,
    'Quípama': 2903,
    'Ramiriquí': 2903,
    'Ráquira': 2903,
    'Rondón': 2903,
    'Saboyá': 2903,
    'Sáchica': 2903,
    'Samacá': 2903,
    'San Eduardo': 2903,
    'San José de Pare': 2903,
    'San Luis de Gaceno': 2903,
    'San Mateo': 2903,
    'San Miguel de Sema': 2903,
    'San Pablo de Borbur': 2903,
    'Santa María': 2903,
    'Santa Rosa de Viterbo': 2903,
    'Santa Sofía': 2903,
    'Santana': 2903,
    'Sativanorte': 2903,
    'Sativasur': 2903,
    'Siachoque': 2903,
    'Soatá': 2903,
    'Socha': 2903,
    'Socha Viejo': 2903,
    'Socotá': 2903,
    'Sogamoso': 2903,
    'Somondoco': 2903,
    'Sora': 2903,
    'Soracá': 2903,
    'Sotaquirá': 2903,
    'Susacón': 2903,
    'Sutamarchán': 2903,
    'Sutatenza': 2903,
    'Tasco': 2903,
    'Tenza': 2903,
    'Tibaná': 2903,
    'Tibasosa': 2903,
    'Tinjacá': 2903,
    'Tipacoque': 2903,
    'Toca': 2903,
    'Togüí': 2903,
    'Tópaga': 2903,
    'Tota': 2903,
    'Tunja': 2903,
    'Tununguá': 2903,
    'Turmequé': 2903,
    'Tuta': 2903,
    'Tutazá': 2903,
    'Umbita': 2903,
    'Ventaquemada': 2903,
    'Villa de Leyva': 2903,
    'Viracachá': 2903,
    'Zetaquira': 2903,
    'Aguadas': 2887,
    'Anserma': 2887,
    'Aranzazu': 2887,
    'Belalcázar': 2887,
    'Chinchiná': 2887,
    'Filadelfia': 2887,
    'La Dorada': 2887,
    'La Merced': 2887,
    'Manizales': 2887,
    'Manzanares': 2887,
    'Marmato': 2887,
    'Marquetalia': 2887,
    'Marulanda': 2887,
    'Neira': 2887,
    'Norcasia': 2887,
    'Pácora': 2887,
    'Palestina': 2887,
    'Pensilvania': 2887,
    'Riosucio': 2887,
    'Risaralda': 2887,
    'Salamina': 2887,
    'Samaná': 2887,
    'San José': 2887,
    'Supía': 2887,
    'Victoria': 2887,
    'Villamaría': 2887,
    'Viterbo': 2887,
    'Albania': 2891,
    'Belén de Los Andaquies': 2891,
    'Cartagena del Chairá': 2891,
    'Curillo': 2891,
    'El Doncello': 2891,
    'El Paujil': 2891,
    'Florencia': 2891,
    'La Montañita': 2891,
    'Milán': 2891,
    'Morelia': 2891,
    'Puerto Rico': 2891,
    'San José del Fragua': 2891,
    'Solano': 2891,
    'Valparaíso': 2891,
    'Aguazul': 2892,
    'Chameza': 2892,
    'Hato Corozal': 2892,
    'Maní': 2892,
    'Monterrey': 2892,
    'Municipio Hato Corozal': 2892,
    'Nunchía': 2892,
    'Orocué': 2892,
    'Pore': 2892,
    'Recetor': 2892,
    'Sabanalarga': 2892,
    'Sácama': 2892,
    'San Luis de Palenque': 2892,
    'Támara': 2892,
    'Tauramena': 2892,
    'Trinidad': 2892,
    'Villanueva': 2892,
    'Yopal': 2892,
    'Almaguer': 2884,
    'Argelia': 2884,
    'Balboa': 2884,
    'Belalcázar': 2884,
    'Bolívar': 2884,
    'Buenos Aires': 2884,
    'Cajibío': 2884,
    'Caldono': 2884,
    'Caloto': 2884,
    'Corinto': 2884,
    'El Bordo': 2884,
    'El Tambo': 2884,
    'Florencia': 2884,
    'Guapi': 2884,
    'Inzá': 2884,
    'Jambaló': 2884,
    'La Sierra': 2884,
    'La Vega': 2884,
    'López': 2884,
    'Mercaderes': 2884,
    'Miranda': 2884,
    'Morales': 2884,
    'Municipio de López de Micay': 2884,
    'Padilla': 2884,
    'Paez': 2884,
    'Paispamba': 2884,
    'Patía': 2884,
    'Piendamo': 2884,
    'Popayán': 2884,
    'Puerto Tejada': 2884,
    'Puracé': 2884,
    'Rosas': 2884,
    'San Sebastián': 2884,
    'Santander de Quilichao': 2884,
    'Silvia': 2884,
    'Sotara': 2884,
    'Suárez': 2884,
    'Sucre': 2884,
    'Timbiquí': 2884,
    'Toribio': 2884,
    'Totoró': 2884,
    'Villa Rica': 2884,
    'Aguachica': 2899,
    'Agustín Codazzi': 2899,
    'Ariguaní': 2899,
    'Astrea': 2899,
    'Becerril': 2899,
    'Chimichagua': 2899,
    'Chiriguaná': 2899,
    'Curumaní': 2899,
    'El Copey': 2899,
    'El Paso': 2899,
    'Gamarra': 2899,
    'González': 2899,
    'La Gloria': 2899,
    'La Jagua de Ibirico': 2899,
    'La Paz': 2899,
    'Manaure': 2899,
    'Manaure Balcón del Cesar': 2899,
    'Pailitas': 2899,
    'Pelaya': 2899,
    'Río de Oro': 2899,
    'San Alberto': 2899,
    'San Diego': 2899,
    'San Martín': 2899,
    'Tamalameque': 2899,
    'Valledupar': 2899,
    'Acandí': 2876,
    'Alto Baudo': 2876,
    'Atrato': 2876,
    'Bagadó': 2876,
    'Bahía Solano': 2876,
    'Bajo Baudó': 2876,
    'Bellavista': 2876,
    'Bojaya': 2876,
    'Carmen del Darien': 2876,
    'Cértegui': 2876,
    'Condoto': 2876,
    'El Cantón de San Pablo': 2876,
    'El Cantón del San Pablo': 2876,
    'El Carmen': 2876,
    'El Carmen de Atrato': 2876,
    'El Litoral del San Juan': 2876,
    'Istmina': 2876,
    'Juradó': 2876,
    'Lloró': 2876,
    'Medio Atrato': 2876,
    'Medio Baudó': 2876,
    'Medio San Juan': 2876,
    'Nóvita': 2876,
    'Nuquí': 2876,
    'Pie de Pató': 2876,
    'Pizarro': 2876,
    'Quibdó': 2876,
    'Río Iro': 2876,
    'Río Quito': 2876,
    'Riosucio': 2876,
    'San José del Palmar': 2876,
    'Santa Genoveva de Docordó': 2876,
    'Sipí': 2876,
    'Tadó': 2876,
    'Unguía': 2876,
    'Unión Panamericana': 2876,
    'Ayapel': 2898,
    'Buenavista': 2898,
    'Canalete': 2898,
    'Cereté': 2898,
    'Chimá': 2898,
    'Chinú': 2898,
    'Ciénaga de Oro': 2898,
    'Cotorra': 2898,
    'La Apartada': 2898,
    'Lorica': 2898,
    'Los Córdobas': 2898,
    'Momil': 2898,
    'Moñitos': 2898,
    'Montelíbano': 2898,
    'Montería': 2898,
    'Planeta Rica': 2898,
    'Pueblo Nuevo': 2898,
    'Puerto Escondido': 2898,
    'Puerto Libertador': 2898,
    'Purísima': 2898,
    'Purísima de la Concepción': 2898,
    'Sahagún': 2898,
    'San Andrés Sotavento': 2898,
    'San Antero': 2898,
    'San Bernardo del Viento': 2898,
    'San Carlos': 2898,
    'San Pelayo': 2898,
    'Tierralta': 2898,
    'Valencia': 2898,
    'Agua de Dios': 2875,
    'Albán': 2875,
    'Anapoima': 2875,
    'Anolaima': 2875,
    'Apulo': 2875,
    'Arbeláez': 2875,
    'Barrio San Luis': 2875,
    'Beltrán': 2875,
    'Bituima': 2875,
    'Bogota': 2875,
    'Bojacá': 2875,
    'Cabrera': 2875,
    'Cachipay': 2875,
    'Cajicá': 2875,
    'Caparrapí': 2875,
    'Caqueza': 2875,
    'Carmen de Carupa': 2875,
    'Chaguaní': 2875,
    'Chía': 2875,
    'Chipaque': 2875,
    'Choachí': 2875,
    'Chocontá': 2875,
    'Cogua': 2875,
    'Cota': 2875,
    'Cucunubá': 2875,
    'El Colegio': 2875,
    'El Maríachi': 2875,
    'El Peñón': 2875,
    'El Rosal': 2875,
    'Facatativá': 2875,
    'Fomeque': 2875,
    'Fosca': 2875,
    'Funza': 2875,
    'Fúquene': 2875,
    'Fusagasugá': 2875,
    'Gachala': 2875,
    'Gachancipá': 2875,
    'Gachetá': 2875,
    'Gama': 2875,
    'Girardot': 2875,
    'Granada': 2875,
    'Guachetá': 2875,
    'Guaduas': 2875,
    'Guasca': 2875,
    'Guataquí': 2875,
    'Guatavita': 2875,
    'Guayabal de Siquima': 2875,
    'Guayabetal': 2875,
    'Gutiérrez': 2875,
    'Jerusalén': 2875,
    'Junín': 2875,
    'La Calera': 2875,
    'La Mesa': 2875,
    'La Palma': 2875,
    'La Peña': 2875,
    'La Vega': 2875,
    'Lenguazaque': 2875,
    'Macheta': 2875,
    'Madrid': 2875,
    'Manta': 2875,
    'Medina': 2875,
    'Mosquera': 2875,
    'Nariño': 2875,
    'Nemocón': 2875,
    'Nilo': 2875,
    'Nimaima': 2875,
    'Nocaima': 2875,
    'Paime': 2875,
    'Pandi': 2875,
    'Paratebueno': 2875,
    'Pasca': 2875,
    'Puerto Bogotá': 2875,
    'Puerto Salgar': 2875,
    'Pulí': 2875,
    'Quebradanegra': 2875,
    'Quetame': 2875,
    'Quipile': 2875,
    'Ricaurte': 2875,
    'San Antonio del Tequendama': 2875,
    'San Bernardo': 2875,
    'San Cayetano': 2875,
    'San Francisco': 2875,
    'San Juan de Río Seco': 2875,
    'Sasaima': 2875,
    'Sesquilé': 2875,
    'Sibaté': 2875,
    'Silvania': 2875,
    'Simijaca': 2875,
    'Soacha': 2875,
    'Sopó': 2875,
    'Subachoque': 2875,
    'Suesca': 2875,
    'Supatá': 2875,
    'Susa': 2875,
    'Sutatausa': 2875,
    'Tabio': 2875,
    'Tausa': 2875,
    'Tena': 2875,
    'Tenjo': 2875,
    'Tibacuy': 2875,
    'Tibirita': 2875,
    'Tocaima': 2875,
    'Tocancipá': 2875,
    'Topaipí': 2875,
    'Ubalá': 2875,
    'Ubaque': 2875,
    'Une': 2875,
    'Útica': 2875,
    'Venecia': 2875,
    'Vergara': 2875,
    'Vianí': 2875,
    'Villa de San Diego de Ubate': 2875,
    'Villagómez': 2875,
    'Villapinzón': 2875,
    'Villeta': 2875,
    'Viotá': 2875,
    'Yacopí': 2875,
    'Zipacón': 2875,
    'Zipaquirá': 2875,
    'Barranco Minas': 2882,
    'Cacahual': 2882,
    'Inírida': 2882,
    'La Guadalupe': 2882,
    'Mapiripana': 2882,
    'Morichal': 2882,
    'Pana Pana': 2882,
    'Puerto Colombia': 2882,
    'San Felipe': 2882,
    'Calamar': 2888,
    'El Retorno': 2888,
    'Miraflores': 2888,
    'San José del Guaviare': 2888,
    'Acevedo': 4871,
    'Aipe': 4871,
    'Algeciras': 4871,
    'Altamira': 4871,
    'Baraya': 4871,
    'Campoalegre': 4871,
    'Colombia': 4871,
    'El Agrado': 4871,
    'Elias': 4871,
    'Garzón': 4871,
    'Gigante': 4871,
    'Guadalupe': 4871,
    'Hobo': 4871,
    'Iquira': 4871,
    'Isnos': 4871,
    'La Plata': 4871,
    'Nataga': 4871,
    'Neiva': 4871,
    'Oporapa': 4871,
    'Paicol': 4871,
    'Palermo': 4871,
    'Palestina': 4871,
    'Pital': 4871,
    'Pitalito': 4871,
    'Rivera': 4871,
    'Saladoblanco': 4871,
    'San Agustín': 4871,
    'Santa María': 4871,
    'Suaza': 4871,
    'Tarqui': 4871,
    'Tello': 4871,
    'Teruel': 4871,
    'Tesalia': 4871,
    'Timana': 4871,
    'Villavieja': 4871,
    'Yaguará': 4871,
    'Albania': 2889,
    'Barrancas': 2889,
    'Dibulla': 2889,
    'Distracción': 2889,
    'El Molino': 2889,
    'Fonseca': 2889,
    'Hatonuevo': 2889,
    'La Jagua del Pilar': 2889,
    'Maicao': 2889,
    'Manaure': 2889,
    'Riohacha': 2889,
    'San Juan del Cesar': 2889,
    'Uribia': 2889,
    'Urumita': 2889,
    'Villanueva': 2889,
    'Algarrobo': 2886,
    'Aracataca': 2886,
    'Ariguaní': 2886,
    'Buenavista': 2886,
    'Cerro de San Antonio': 2886,
    'Cerro San Antonio': 2886,
    'Chibolo': 2886,
    'Chivolo': 2886,
    'Ciénaga': 2886,
    'Concordia': 2886,
    'El Banco': 2886,
    'El Piñon': 2886,
    'El Retén': 2886,
    'Fundación': 2886,
    'Guamal': 2886,
    'Nueva Granada': 2886,
    'Pedraza': 2886,
    'Pijiño del Carmen': 2886,
    'Pivijay': 2886,
    'Plato': 2886,
    'Puebloviejo': 2886,
    'Remolino': 2886,
    'Sabanas de San Angel': 2886,
    'Salamina': 2886,
    'San Antonio': 2886,
    'San Zenón': 2886,
    'Santa Ana': 2886,
    'Santa Bárbara de Pinto': 2886,
    'Santa Marta': 2886,
    'Sitionuevo': 2886,
    'Tenerife': 2886,
    'Zapayán': 2886,
    'Zona Bananera': 2886,
    'Acacías': 2878,
    'Barranca de Upía': 2878,
    'Cabuyaro': 2878,
    'Castilla la Nueva': 2878,
    'Cubarral': 2878,
    'Cumaral': 2878,
    'El Calvario': 2878,
    'El Castillo': 2878,
    'Fuente de Oro': 2878,
    'Granada': 2878,
    'Guamal': 2878,
    'La Macarena': 2878,
    'Lejanías': 2878,
    'Mapiripán': 2878,
    'Mesetas': 2878,
    'Puerto Concordia': 2878,
    'Puerto Gaitán': 2878,
    'Puerto Lleras': 2878,
    'Puerto López': 2878,
    'Puerto Rico': 2878,
    'Puerto Yuca': 2878,
    'Restrepo': 2878,
    'San Carlos de Guaroa': 2878,
    'San Juan de Arama': 2878,
    'San Martín': 2878,
    'Villavicencio': 2878,
    'Vistahermosa': 2878,
    'Albán': 2897,
    'Aldana': 2897,
    'Ancuya': 2897,
    'Arboleda': 2897,
    'Barbacoas': 2897,
    'Belén': 2897,
    'Buesaco': 2897,
    'Carlosama': 2897,
    'Cartago': 2897,
    'Chachagüí': 2897,
    'Colón': 2897,
    'Consaca': 2897,
    'Contadero': 2897,
    'Córdoba': 2897,
    'Cuaspud': 2897,
    'Cumbal': 2897,
    'Cumbitara': 2897,
    'El Charco': 2897,
    'El Peñol': 2897,
    'El Rosario': 2897,
    'El Tablón': 2897,
    'El Tablón de Gómez': 2897,
    'El Tambo': 2897,
    'Francisco Pizarro': 2897,
    'Funes': 2897,
    'Génova': 2897,
    'Guachucal': 2897,
    'Guaitarilla': 2897,
    'Gualmatán': 2897,
    'Iles': 2897,
    'Imués': 2897,
    'Ipiales': 2897,
    'Iscuandé': 2897,
    'La Cruz': 2897,
    'La Florida': 2897,
    'La Llanada': 2897,
    'La Tola': 2897,
    'La Unión': 2897,
    'Leiva': 2897,
    'Linares': 2897,
    'Los Andes': 2897,
    'Magüi': 2897,
    'Mallama': 2897,
    'Mosquera': 2897,
    'Nariño': 2897,
    'Olaya Herrera': 2897,
    'Ospina': 2897,
    'Pasto': 2897,
    'Payán': 2897,
    'Piedrancha': 2897,
    'Policarpa': 2897,
    'Potosí': 2897,
    'Puerres': 2897,
    'Pupiales': 2897,
    'Ricaurte': 2897,
    'Roberto Payán': 2897,
    'Salahonda': 2897,
    'Samaniego': 2897,
    'San Andres de Tumaco': 2897,
    'San Bernardo': 2897,
    'San José': 2897,
    'San Lorenzo': 2897,
    'San Pablo': 2897,
    'San Pedro de Cartago': 2897,
    'Sandoná': 2897,
    'Santa Bárbara': 2897,
    'Santacruz': 2897,
    'Sapuyes': 2897,
    'Sotomayor': 2897,
    'Taminango': 2897,
    'Tangua': 2897,
    'Tumaco': 2897,
    'Túquerres': 2897,
    'Yacuanquer': 2897,
    'Abrego': 2877,
    'Arboledas': 2877,
    'Bochalema': 2877,
    'Bucarasica': 2877,
    'Cachirá': 2877,
    'Cácota': 2877,
    'Chinácota': 2877,
    'Chitagá': 2877,
    'Convención': 2877,
    'Cúcuta': 2877,
    'Cucutilla': 2877,
    'Durania': 2877,
    'El Carmen': 2877,
    'El Tarra': 2877,
    'El Zulia': 2877,
    'Gramalote': 2877,
    'Hacarí': 2877,
    'Herrán': 2877,
    'La Esperanza': 2877,
    'La Playa': 2877,
    'Labateca': 2877,
    'Los Patios': 2877,
    'Lourdes': 2877,
    'Mutiscua': 2877,
    'Ocaña': 2877,
    'Pamplona': 2877,
    'Pamplonita': 2877,
    'Puerto Santander': 2877,
    'Ragonvalia': 2877,
    'Salazar': 2877,
    'San Calixto': 2877,
    'San Cayetano': 2877,
    'Santiago': 2877,
    'Sardinata': 2877,
    'Silos': 2877,
    'Tibú': 2877,
    'Toledo': 2877,
    'Villa del Rosario': 2877,
    'Colón': 2896,
    'La Dorada': 2896,
    'Leguízamo': 2896,
    'Mocoa': 2896,
    'Orito': 2896,
    'Puerto Asís': 2896,
    'Puerto Guzmán': 2896,
    'Puerto Leguízamo': 2896,
    'San Francisco': 2896,
    'San Miguel': 2896,
    'Santiago': 2896,
    'Sibundoy': 2896,
    'Valle del Guamuez': 2896,
    'Villagarzón': 2896,
    'Armenia': 2874,
    'Buenavista': 2874,
    'Calarca': 2874,
    'Circasia': 2874,
    'Córdoba': 2874,
    'Filandia': 2874,
    'Génova': 2874,
    'La Tebaida': 2874,
    'Montenegro': 2874,
    'Pijao': 2874,
    'Quimbaya': 2874,
    'Salento': 2874,
    'Anserma': 2879,
    'Apía': 2879,
    'Balboa': 2879,
    'Belén de Umbría': 2879,
    'Dosquebradas': 2879,
    'Guática': 2879,
    'La Celia': 2879,
    'La Merced': 2879,
    'La Virginia': 2879,
    'Marsella': 2879,
    'Mistrató': 2879,
    'Pereira': 2879,
    'Pueblo Rico': 2879,
    'Quinchía': 2879,
    'Santa Rosa de Cabal': 2879,
    'Santuario': 2879,
    'Mountain': 2900,
    'Providencia': 2900,
    'San Andrés': 2900,
    'Aguada': 2901,
    'Albania': 2901,
    'Aratoca': 2901,
    'Barbosa': 2901,
    'Barichara': 2901,
    'Barrancabermeja': 2901,
    'Betulia': 2901,
    'Bolívar': 2901,
    'Bucaramanga': 2901,
    'Cabrera': 2901,
    'California': 2901,
    'Capitanejo': 2901,
    'Carcasí': 2901,
    'Cepitá': 2901,
    'Cerrito': 2901,
    'Charalá': 2901,
    'Charta': 2901,
    'Chima': 2901,
    'Chipatá': 2901,
    'Cimitarra': 2901,
    'Concepción': 2901,
    'Confines': 2901,
    'Contratación': 2901,
    'Coromoro': 2901,
    'Curití': 2901,
    'El Carmen de Chucurí': 2901,
    'El Guacamayo': 2901,
    'El Peñón': 2901,
    'El Playón': 2901,
    'Encino': 2901,
    'Enciso': 2901,
    'Florián': 2901,
    'Floridablanca': 2901,
    'Galán': 2901,
    'Gambita': 2901,
    'Girón': 2901,
    'Guaca': 2901,
    'Guadalupe': 2901,
    'Guapotá': 2901,
    'Guavatá': 2901,
    'Güepsa': 2901,
    'Hato': 2901,
    'Jesús María': 2901,
    'Jordán': 2901,
    'La Belleza': 2901,
    'La Paz': 2901,
    'Landázuri': 2901,
    'Lebrija': 2901,
    'Los Santos': 2901,
    'Macaravita': 2901,
    'Málaga': 2901,
    'Matanza': 2901,
    'Mogotes': 2901,
    'Molagavita': 2901,
    'Ocamonte': 2901,
    'Oiba': 2901,
    'Onzaga': 2901,
    'Palmar': 2901,
    'Palmas del Socorro': 2901,
    'Páramo': 2901,
    'Piedecuesta': 2901,
    'Pinchote': 2901,
    'Puente Nacional': 2901,
    'Puerto Parra': 2901,
    'Puerto Wilches': 2901,
    'Rionegro': 2901,
    'Sabana de Torres': 2901,
    'San Andrés': 2901,
    'San Benito': 2901,
    'San Gil': 2901,
    'San Joaquín': 2901,
    'San José de Miranda': 2901,
    'San Miguel': 2901,
    'San Vicente de Chucurí': 2901,
    'Santa Bárbara': 2901,
    'Simacota': 2901,
    'Socorro': 2901,
    'Suaita': 2901,
    'Sucre': 2901,
    'Suratá': 2901,
    'Tona': 2901,
    'Valle de San José': 2901,
    'Vélez': 2901,
    'Vetas': 2901,
    'Villanueva': 2901,
    'Zapatoca': 2901,
    'Caimito': 2902,
    'Chalán': 2902,
    'Coloso': 2902,
    'Corozal': 2902,
    'Coveñas': 2902,
    'El Roble': 2902,
    'Galeras': 2902,
    'Guaranda': 2902,
    'La Unión': 2902,
    'Los Palmitos': 2902,
    'Majagual': 2902,
    'Morroa': 2902,
    'Ovejas': 2902,
    'Palmito': 2902,
    'Sampués': 2902,
    'San Benito Abad': 2902,
    'San Juan de Betulia': 2902,
    'San Luis de Sincé': 2902,
    'San Marcos': 2902,
    'San Onofre': 2902,
    'San Pedro': 2902,
    'Santiago de Tolú': 2902,
    'Sincelejo': 2902,
    'Sucre': 2902,
    'Tolú Viejo': 2902,
    'Alpujarra': 2883,
    'Alvarado': 2883,
    'Ambalema': 2883,
    'Anaime': 2883,
    'Anzoátegui': 2883,
    'Armero': 2883,
    'Armero-Guyabal': 2883,
    'Ataco': 2883,
    'Cajamarca': 2883,
    'Campo Alegre': 2883,
    'Carmen de Apicalá': 2883,
    'Casabianca': 2883,
    'Chaparral': 2883,
    'Chicoral': 2883,
    'Coello': 2883,
    'Coyaima': 2883,
    'Cunday': 2883,
    'Doima': 2883,
    'Dolores': 2883,
    'Espinal': 2883,
    'Falan': 2883,
    'Flandes': 2883,
    'Fresno': 2883,
    'Frías': 2883,
    'Gaitania': 2883,
    'Guamo': 2883,
    'Guayabal': 2883,
    'Herveo': 2883,
    'Honda': 2883,
    'Ibagué': 2883,
    'Icononzo': 2883,
    'Junín': 2883,
    'La Chamba': 2883,
    'Laureles': 2883,
    'Lérida': 2883,
    'Líbano': 2883,
    'Melgar': 2883,
    'Municipio de San Sebastián de Mariquita': 2883,
    'Murillo': 2883,
    'Natagaima': 2883,
    'Ortega': 2883,
    'Padua': 2883,
    'Palocabildo': 2883,
    'Payandé': 2883,
    'Piedras': 2883,
    'Planadas': 2883,
    'Playarrica': 2883,
    'Prado': 2883,
    'Purificación': 2883,
    'Rioblanco': 2883,
    'Roncesvalles': 2883,
    'Rovira': 2883,
    'Saldaña': 2883,
    'San Antonio': 2883,
    'San Luis': 2883,
    'San Sebastián de Mariquita': 2883,
    'Santa Isabel': 2883,
    'Santiago Pérez': 2883,
    'Suárez': 2883,
    'Tres Esquinas': 2883,
    'Valle de San Juan': 2883,
    'Venadillo': 2883,
    'Villahermosa': 2883,
    'Villarrica': 2883,
    'Alcalá': 2904,
    'Andalucía': 2904,
    'Ansermanuevo': 2904,
    'Argelia': 2904,
    'Bolívar': 2904,
    'Buenaventura': 2904,
    'Bugalagrande': 2904,
    'Caicedonia': 2904,
    'Cali': 2904,
    'Calima': 2904,
    'Candelaria': 2904,
    'Cartago': 2904,
    'Dagua': 2904,
    'Darien': 2904,
    'El Águila': 2904,
    'El Cairo': 2904,
    'El Cerrito': 2904,
    'El Dovio': 2904,
    'Florida': 2904,
    'Ginebra': 2904,
    'Guacarí': 2904,
    'Guadalajara de Buga': 2904,
    'Jamundí': 2904,
    'La Cumbre': 2904,
    'La Unión': 2904,
    'La Victoria': 2904,
    'Obando': 2904,
    'Palmira': 2904,
    'Pradera': 2904,
    'Restrepo': 2904,
    'Riofrío': 2904,
    'Roldanillo': 2904,
    'San Pedro': 2904,
    'Sevilla': 2904,
    'Toro': 2904,
    'Trujillo': 2904,
    'Tuluá': 2904,
    'Ulloa': 2904,
    'Versalles': 2904,
    'Vijes': 2904,
    'Yotoco': 2904,
    'Yumbo': 2904,
    'Zarzal': 2904,
    'Caruru': 2885,
    'Mitú': 2885,
    'Pacoa': 2885,
    'Papunaua': 2885,
    'Taraira': 2885,
    'Yavaraté': 2885,
    'Cumaribo': 2894,
    'La Primavera': 2894,
    'Puerto Carreño': 2894,
    'Santa Rosalia': 2894,
    'Adda-Douéni': 2821,
    'Antsahé': 2821,
    'Assimpao': 2821,
    'Bambao': 2821,
    'Bandajou': 2821,
    'Barakani': 2821,
    'Bimbini': 2821,
    'Boungouéni': 2821,
    'Chandra': 2821,
    'Chironkamba': 2821,
    'Chitrouni': 2821,
    'Daji': 2821,
    'Domoni': 2821,
    'Dziani': 2821,
    'Hajoho': 2821,
    'Harembo': 2821,
    'Kangani': 2821,
    'Kavani': 2821,
    'Koki': 2821,
    'Koni-Djodjo': 2821,
    'Koni-Ngani': 2821,
    'Kyo': 2821,
    'Limbi': 2821,
    'Lingoni': 2821,
    'Magnassini-Nindri': 2821,
    'Maraharé': 2821,
    'Mirontsi': 2821,
    'Mjamaoué': 2821,
    'Mjimandra': 2821,
    'Moutsamoudou': 2821,
    'Moya': 2821,
    'Mramani': 2821,
    'Mrémani': 2821,
    'Ongoni': 2821,
    'Ouani': 2821,
    'Ouzini': 2821,
    'Pajé': 2821,
    'Patsi': 2821,
    'Sima': 2821,
    'Tsimbeo': 2821,
    'Vouani': 2821,
    'Bahani': 2822,
    'Bambadjani': 2822,
    'Bouni': 2822,
    'Chezani': 2822,
    'Chindini': 2822,
    'Chouani': 2822,
    'Dembéni': 2822,
    'Douniani': 2822,
    'Dzahadjou': 2822,
    'Foumbouni': 2822,
    'Hantsindzi': 2822,
    'Héroumbili': 2822,
    'Itsandra': 2822,
    'Itsandzéni': 2822,
    'Ivouani': 2822,
    'Koua': 2822,
    'Madjeouéni': 2822,
    'Mandza': 2822,
    'Mavingouni': 2822,
    'Mbéni': 2822,
    'Mitsamiouli': 2822,
    'Mitsoudjé': 2822,
    'Mnoungou': 2822,
    'Mohoro': 2822,
    'Moroni': 2822,
    'Mtsamdou': 2822,
    'Mvouni': 2822,
    'Nioumamilima': 2822,
    'Ntsaouéni': 2822,
    'Ntsoudjini': 2822,
    'Ouellah': 2822,
    'Ouhozi': 2822,
    'Ourovéni': 2822,
    'Oussivo': 2822,
    'Salimani': 2822,
    'Séléa': 2822,
    'Singani': 2822,
    'Tsidjé': 2822,
    'Vanadjou': 2822,
    'Vanambouani': 2822,
    'Djoyézi': 2820,
    'Fomboni': 2820,
    'Hoani': 2820,
    'Mtakoudja': 2820,
    'Nioumachoua': 2820,
    'Ouanani': 2820,
    'Ziroudani': 2820,
    'Kayes': 2866,
    'Madingou': 2866,
    'Brazzaville': 2870,
    'Makoua': 2864,
    'Owando': 2864,
    'Ewo': 2869,
    'Sibiti': 2868,
    'Impfondo': 2865,
    'Dolisie': 2872,
    'Mossendjo': 2872,
    'Djambala': 2862,
    'Gamboma': 2862,
    'Loandjili': 2863,
    'Pointe-Noire': 2863,
    'Kinkala': 2873,
    'Ouésso': 2871,
    'Sémbé': 2871,
    'Boma': 2746,
    'Kasangulu': 2746,
    'Matadi': 2746,
    'Mbanza-Ngungu': 2746,
    'Moanda': 2746,
    'Tshela': 2746,
    'Aketi': 2753,
    'Bondo': 2753,
    'Buta': 2753,
    'Gemena': 2744,
    'Lisala': 2744,
    'Lukolela': 2744,
    'Mbandaka': 2744,
    'Haut Katanga': 2750,
    'Kambove': 2750,
    'Kipushi': 2750,
    'Likasi': 2750,
    'Lubumbashi': 2750,
    'Bukama': 2758,
    'Kamina': 2758,
    'Isiro': 2734,
    'Wamba': 2734,
    'Watsa': 2734,
    'Bunia': 2751,
    'Ilebo': 2757,
    'Luebo': 2757,
    'Mweka': 2757,
    'Tshikapa': 2757,
    'Gandajika': 2735,
    'Kabinda': 2735,
    'Mbuji-Mayi': 2735,
    'Kinshasa': 2741,
    'Masina': 2741,
    'Kasongo-Lunda': 2740,
    'Bandundu': 2759,
    'Bulungu': 2759,
    'Kikwit': 2759,
    'Mangai': 2759,
    'Lubao': 2747,
    'Mwene-Ditu': 2747,
    'Bolobo': 2755,
    'Inongo': 2755,
    'Mushie': 2755,
    'Nioki': 2755,
    'Kampene': 2745,
    'Kasongo': 2745,
    'Kindu': 2745,
    'Bumba': 2752,
    'Bosobolo': 2739,
    'Businga': 2739,
    'Gbadolite': 2739,
    'Beni': 2749,
    'Butembo': 2749,
    'Goma': 2749,
    'Sake': 2749,
    'Lodja': 2743,
    'Lusambo': 2743,
    'Bukavu': 2738,
    'Kabare': 2738,
    'Uvira': 2738,
    'Bongandanga': 2748,
    'Libenge': 2748,
    'Kabalo': 2733,
    'Kalemie': 2733,
    'Kongolo': 2733,
    'Basoko': 2756,
    'Kisangani': 2756,
    'Yangambi': 2756,
    'Boende': 2732,
    'Alajuela': 1215,
    'Atenas': 1215,
    'Bijagua': 1215,
    'Carrillos': 1215,
    'Desamparados': 1215,
    'Esquipulas': 1215,
    'Grecia': 1215,
    'Guatuso': 1215,
    'La Fortuna': 1215,
    'Los Chiles': 1215,
    'Naranjo': 1215,
    'Orotina': 1215,
    'Palmares': 1215,
    'Pital': 1215,
    'Poás': 1215,
    'Pocosol': 1215,
    'Quesada': 1215,
    'Río Segundo': 1215,
    'Sabanilla': 1215,
    'San Carlos': 1215,
    'San José': 1215,
    'San Juan': 1215,
    'San Mateo': 1215,
    'San Rafael': 1215,
    'San Ramón': 1215,
    'Santiago': 1215,
    'Upala': 1215,
    'Valverde Vega': 1215,
    'Zarcero': 1215,
    'Abangares': 1209,
    'Bagaces': 1209,
    'Belén': 1209,
    'Cañas': 1209,
    'Carrillo': 1209,
    'Fortuna': 1209,
    'Hojancha': 1209,
    'Juntas': 1209,
    'La Cruz': 1209,
    'Liberia': 1209,
    'Nandayure': 1209,
    'Nicoya': 1209,
    'Sámara': 1209,
    'Santa Cruz': 1209,
    'Sardinal': 1209,
    'Tilarán': 1209,
    'Ángeles': 1212,
    'Barva': 1212,
    'Belén': 1212,
    'Flores': 1212,
    'Heredia': 1212,
    'La Asunción': 1212,
    'Llorente': 1212,
    'Mercedes': 1212,
    'San Antonio': 1212,
    'San Francisco': 1212,
    'San Isidro': 1212,
    'San Josecito': 1212,
    'San Pablo': 1212,
    'San Rafael': 1212,
    'Santa Bárbara': 1212,
    'Santo Domingo': 1212,
    'Sarapiquí': 1212,
    'Batán': 1213,
    'Guácimo': 1213,
    'Guápiles': 1213,
    'Limón': 1213,
    'Matina': 1213,
    'Pococí': 1213,
    'Pocora': 1213,
    'Roxana': 1213,
    'Siquirres': 1213,
    'Sixaola': 1213,
    'Talamanca': 1213,
    'Alvarado': 1211,
    'Cartago': 1211,
    'Concepción': 1211,
    'Cot': 1211,
    'El Guarco': 1211,
    'Jiménez': 1211,
    'La Suiza': 1211,
    'La Unión': 1211,
    'Oreamuno': 1211,
    'Orosí': 1211,
    'Pacayas': 1211,
    'Paraíso': 1211,
    'Pejibaye': 1211,
    'San Diego': 1211,
    'Tobosi': 1211,
    'Tres Ríos': 1211,
    'Tucurrique': 1211,
    'Turrialba': 1211,
    'Buenos Aires': 1210,
    'Canoas': 1210,
    'Chacarita': 1210,
    'Ciudad Cortés': 1210,
    'Corredor': 1210,
    'Corredores': 1210,
    'Coto Brus': 1210,
    'Esparza': 1210,
    'Garabito': 1210,
    'Golfito': 1210,
    'Jacó': 1210,
    'Miramar': 1210,
    'Montes de Oro': 1210,
    'Osa': 1210,
    'Paquera': 1210,
    'Parrita': 1210,
    'Puntarenas': 1210,
    'Quepos': 1210,
    'San Vito': 1210,
    'Acosta': 1214,
    'Alajuelita': 1214,
    'Aserrí': 1214,
    'Calle Blancos': 1214,
    'Colima': 1214,
    'Colón': 1214,
    'Curridabat': 1214,
    'Daniel Flores': 1214,
    'Desamparados': 1214,
    'Dota': 1214,
    'Escazú': 1214,
    'Goicoechea': 1214,
    'Granadilla': 1214,
    'Guadalupe': 1214,
    'Ipís': 1214,
    'León Cortés': 1214,
    'Mercedes': 1214,
    'Montes de Oca': 1214,
    'Mora': 1214,
    'Moravia': 1214,
    'Palmichal': 1214,
    'Patarrá': 1214,
    'Pérez Zeledón': 1214,
    'Puriscal': 1214,
    'Purral': 1214,
    'Sabanilla': 1214,
    'Salitral': 1214,
    'Salitrillos': 1214,
    'San Felipe': 1214,
    'San Ignacio': 1214,
    'San Isidro': 1214,
    'San José': 1214,
    'San Juan': 1214,
    'San Juan de Dios': 1214,
    'San Marcos': 1214,
    'San Miguel': 1214,
    'San Pedro': 1214,
    'San Rafael': 1214,
    'San Rafael Abajo': 1214,
    'San Rafael Arriba': 1214,
    'San Vicente': 1214,
    'San Vicente de Moravia': 1214,
    'Santa Ana': 1214,
    'Santiago': 1214,
    'Tarrazú': 1214,
    'Tejar': 1214,
    'Tibás': 1214,
    'Turrubares': 1214,
    'Vázquez de Coronado': 1214,
    'Abidjan': 2634,
    'Abobo': 2634,
    'Anyama': 2634,
    'Bingerville': 2634,
    'Gbôklé': 2643,
    'Nawa': 2643,
    'San-Pédro': 2643,
    'Sassandra': 2643,
    'Tabou': 2643,
    'Abengourou': 2654,
    'Aboisso': 2654,
    'Adiaké': 2654,
    'Agnibilékrou': 2654,
    'Ayamé': 2654,
    'Bonoua': 2654,
    'Grand-Bassam': 2654,
    'Indénié-Djuablin': 2654,
    'Sud-Comoé': 2654,
    'Folon': 2644,
    'Kabadougou': 2644,
    'Odienné': 2644,
    'Bangolo': 2645,
    'Biankouma': 2645,
    'Cavally': 2645,
    'Danané': 2645,
    'Duekoué': 2645,
    'Guémon': 2645,
    'Guiglo': 2645,
    'Man': 2645,
    'Tonkpi': 2645,
    'Toulépleu Gueré': 2645,
    'Divo': 2651,
    'Gagnoa': 2651,
    'Gôh': 2651,
    'Guibéroua': 2651,
    'Lakota': 2651,
    'Lôh-Djiboua': 2651,
    'Oumé': 2651,
    'Arrah': 2640,
    'Bélier': 2640,
    'Bocanda': 2640,
    'Bongouanou': 2640,
    'Daoukro': 2640,
    'Dimbokro': 2640,
    'Iffou': 2640,
    'Moronou': 2640,
    'N  Zi': 2640,
    'Toumodi': 2640,
    'Yamoussoukro': 2640,
    'Adzopé': 2639,
    'Affery': 2639,
    'Agboville': 2639,
    'Agnéby-Tiassa': 2639,
    'Akoupé': 2639,
    'Dabou': 2639,
    'Grand-Lahou': 2639,
    'Grands-Ponts': 2639,
    'Tiassalé': 2639,
    'Bouaflé': 2648,
    'Daloa': 2648,
    'Haut-Sassandra': 2648,
    'Issia': 2648,
    'Marahoué': 2648,
    'Vavoua': 2648,
    'Zuénoula': 2648,
    'Béoumi': 2647,
    'Botro': 2647,
    'Bouaké': 2647,
    'Dabakala': 2647,
    'Gbêkê': 2647,
    'Hambol': 2647,
    'Katiola': 2647,
    'Sakassou': 2647,
    'Bafing': 2650,
    'Béré': 2650,
    'Mankono': 2650,
    'Séguéla': 2650,
    'Touba': 2650,
    'Worodougou': 2650,
    'Bondoukou': 2641,
    'Bouna': 2641,
    'Bounkani': 2641,
    'Gontougo': 2641,
    'Sinfra': 2641,
    'Tanda': 2641,
    'Bjelovar': 734,
    'Brezovac': 734,
    'Čazma': 734,
    'Daruvar': 734,
    'Dežanovac': 734,
    'Đulovac': 734,
    'Garešnica': 734,
    'Grad Bjelovar': 734,
    'Grad Čazma': 734,
    'Grad Daruvar': 734,
    'Grad Garešnica': 734,
    'Grad Grubišno Polje': 734,
    'Grubišno Polje': 734,
    'Gudovac': 734,
    'Hercegovac': 734,
    'Ivanska': 734,
    'Kapela': 734,
    'Končanica': 734,
    'Predavac': 734,
    'Rovišće': 734,
    'Šandrovac': 734,
    'Severin': 734,
    'Sirač': 734,
    'Velika Pisanica': 734,
    'Veliki Grđevac': 734,
    'Ždralovi': 734,
    'Zrinski Topolovac': 734,
    'Batrina': 737,
    'Brodski Varoš': 737,
    'Bukovlje': 737,
    'Cernik': 737,
    'Davor': 737,
    'Donji Andrijevci': 737,
    'Garčin': 737,
    'Gornji Bogićevci': 737,
    'Grad Nova Gradiška': 737,
    'Grad Slavonski Brod': 737,
    'Gundinci': 737,
    'Korenica': 737,
    'Kruševica': 737,
    'Lužani': 737,
    'Nova Gradiška': 737,
    'Okučani': 737,
    'Oprisavci': 737,
    'Oriovac': 737,
    'Podvinje': 737,
    'Rešetari': 737,
    'Ruščica': 737,
    'Sibinj': 737,
    'Sikirevci': 737,
    'Slavonski Brod': 737,
    'Slobodnica': 737,
    'Stari Perkovci': 737,
    'Velika Kopanica': 737,
    'Vrpolje': 737,
    'Cavtat': 728,
    'Čibača': 728,
    'Dubrovačko primorje': 728,
    'Grad Dubrovnik': 728,
    'Grad Korčula': 728,
    'Grad Ploče': 728,
    'Komin': 728,
    'Konavle': 728,
    'Korčula': 728,
    'Lastovo': 728,
    'Lumbarda': 728,
    'Metković': 728,
    'Mljet': 728,
    'Mokošica': 728,
    'Općina Lastovo': 728,
    'Opuzen': 728,
    'Orebić': 728,
    'Podgora': 728,
    'Pojezerje': 728,
    'Slivno': 728,
    'Smokvica': 728,
    'Ston': 728,
    'Vela Luka': 728,
    'Zažablje': 728,
    'Žrnovo': 728,
    'Župa dubrovačka': 728,
    'Bale': 743,
    'Bale-Valle': 743,
    'Brtonigla': 743,
    'Brtonigla-Verteneglio': 743,
    'Buje': 743,
    'Buje-Buie': 743,
    'Buzet': 743,
    'Fažana': 743,
    'Fažana-Fasana': 743,
    'Funtana': 743,
    'Funtana-Fontane': 743,
    'Galižana': 743,
    'Grad Buzet': 743,
    'Grad Labin': 743,
    'Grad Pazin': 743,
    'Grožnjan': 743,
    'Grožnjan-Grisignana': 743,
    'Kanfanar': 743,
    'Karojba': 743,
    'Kaštelir-Labinci': 743,
    'Labin': 743,
    'Ližnjan': 743,
    'Ližnjan-Lisignano': 743,
    'Lupoglav': 743,
    'Marčana': 743,
    'Medulin': 743,
    'Motovun': 743,
    'Motovun-Montona': 743,
    'Novigrad': 743,
    'Novigrad-Cittanova': 743,
    'Općina Lanišće': 743,
    'Oprtalj-Portole': 743,
    'Pazin': 743,
    'Poreč': 743,
    'Poreč-Parenzo': 743,
    'Pula': 743,
    'Pula-Pola': 743,
    'Rabac': 743,
    'Raša': 743,
    'Rovinj': 743,
    'Rovinj-Rovigno': 743,
    'Sveta Nedelja': 743,
    'Sveti Lovreč': 743,
    'Tar': 743,
    'Tar-Vabriga-Torre Abrega': 743,
    'Umag': 743,
    'Umag-Umago': 743,
    'Valbandon': 743,
    'Vinež': 743,
    'Višnjan-Visignano': 743,
    'Vižinada-Visinada': 743,
    'Vodnjan': 743,
    'Vodnjan-Dignano': 743,
    'Vrsar': 743,
    'Vrsar-Orsera': 743,
    'Žminj': 743,
    'Drnje': 742,
    'Đelekovec': 742,
    'Đurđevac': 742,
    'Ferdinandovac': 742,
    'Gola': 742,
    'Gornja Rijeka': 742,
    'Grad Koprivnica': 742,
    'Grad Križevci': 742,
    'Hlebine': 742,
    'Kalinovac': 742,
    'Koprivnica': 742,
    'Koprivnički Ivanec': 742,
    'Križevci': 742,
    'Legrad': 742,
    'Molve': 742,
    'Novo Virje': 742,
    'Peteranec': 742,
    'Rasinja': 742,
    'Reka': 742,
    'Sigetec': 742,
    'Virje': 742,
    'Bedekovčina': 729,
    'Budinščina': 729,
    'Đurmanec': 729,
    'Grad Donja Stubica': 729,
    'Grad Klanjec': 729,
    'Grad Krapina': 729,
    'Grad Zabok': 729,
    'Grad Zlatar': 729,
    'Jesenje': 729,
    'Klanjec': 729,
    'Konjščina': 729,
    'Krapina': 729,
    'Kumrovec': 729,
    'Mače': 729,
    'Marija Bistrica': 729,
    'Mihovljan': 729,
    'Oroslavje': 729,
    'Pregrada': 729,
    'Radoboj': 729,
    'Stubičke Toplice': 729,
    'Sveti Križ Začretje': 729,
    'Zabok': 729,
    'Zlatar': 729,
    'Zlatar Bistrica': 729,
    'Brinje': 731,
    'Gospić': 731,
    'Karlobag': 731,
    'Lički Osik': 731,
    'Novalja': 731,
    'Otočac': 731,
    'Perušić': 731,
    'Plitvička Jezera': 731,
    'Popovača': 731,
    'Senj': 731,
    'Belica': 726,
    'Čakovec': 726,
    'Dekanovec': 726,
    'Domašinec': 726,
    'Goričan': 726,
    'Grad Čakovec': 726,
    'Hodošan': 726,
    'Ivanovec': 726,
    'Kotoriba': 726,
    'Kuršanec': 726,
    'Lopatinec': 726,
    'Mačkovec': 726,
    'Mala Subotica': 726,
    'Mihovljan': 726,
    'Mursko Središće': 726,
    'Nedelišće': 726,
    'Novo Selo Rok': 726,
    'Orehovica': 726,
    'Peklenica': 726,
    'Podturen': 726,
    'Prelog': 726,
    'Pribislavec': 726,
    'Šenkovec': 726,
    'Strahoninec': 726,
    'Sveti Juraj na Bregu': 726,
    'Vratišinec': 726,
    'Antunovac': 740,
    'Beli Manastir': 740,
    'Belišće': 740,
    'Bijelo Brdo': 740,
    'Bilje': 740,
    'Bistrinci': 740,
    'Bizovac': 740,
    'Brijest': 740,
    'Budrovci': 740,
    'Čeminac': 740,
    'Čepin': 740,
    'Dalj': 740,
    'Darda': 740,
    'Donji Miholjac': 740,
    'Draž': 740,
    'Đakovo': 740,
    'Đurđenovac': 740,
    'Erdut': 740,
    'Ernestinovo': 740,
    'Feričanci': 740,
    'Gorjani': 740,
    'Grad Beli Manastir': 740,
    'Grad Donji Miholjac': 740,
    'Grad Našice': 740,
    'Grad Osijek': 740,
    'Grad Valpovo': 740,
    'Jagodnjak': 740,
    'Jelisavac': 740,
    'Josipovac': 740,
    'Karanac': 740,
    'Kneževi Vinogradi': 740,
    'Koška': 740,
    'Kuševac': 740,
    'Ladimirevci': 740,
    'Laslovo': 740,
    'Magadenovac': 740,
    'Marijanci': 740,
    'Marjanci': 740,
    'Markovac Našički': 740,
    'Martin': 740,
    'Našice': 740,
    'Osijek': 740,
    'Petlovac': 740,
    'Petrijevci': 740,
    'Piškorevci': 740,
    'Podgorač': 740,
    'Podravska Moslavina': 740,
    'Sarvaš': 740,
    'Satnica Đakovačka': 740,
    'Semeljci': 740,
    'Široko Polje': 740,
    'Šodolovci': 740,
    'Strizivojna': 740,
    'Tenja': 740,
    'Valpovo': 740,
    'Velimirovac': 740,
    'Viljevo': 740,
    'Viškovci': 740,
    'Višnjevac': 740,
    'Vladislavci': 740,
    'Vuka': 740,
    'Brestovac': 724,
    'Grad Pakrac': 724,
    'Grad Požega': 724,
    'Jakšić': 724,
    'Kaptol': 724,
    'Kutjevo': 724,
    'Lipik': 724,
    'Pakrac': 724,
    'Pleternica': 724,
    'Požega': 724,
    'Velika': 724,
    'Vidovci': 724,
    'Bakar': 735,
    'Banjol': 735,
    'Baška': 735,
    'Bribir': 735,
    'Buzdohanj': 735,
    'Čavle': 735,
    'Cernik': 735,
    'Cres': 735,
    'Crikvenica': 735,
    'Delnice': 735,
    'Dražice': 735,
    'Drenova': 735,
    'Fužine': 735,
    'Grad Čabar': 735,
    'Grad Crikvenica': 735,
    'Grad Delnice': 735,
    'Grad Krk': 735,
    'Grad Opatija': 735,
    'Grad Rijeka': 735,
    'Grad Vrbovsko': 735,
    'Hreljin': 735,
    'Jadranovo': 735,
    'Kampor': 735,
    'Kastav': 735,
    'Klana': 735,
    'Kraljevica': 735,
    'Krasica': 735,
    'Krk': 735,
    'Lopar': 735,
    'Lovran': 735,
    'Mali Lošinj': 735,
    'Malinska-Dubašnica': 735,
    'Marčelji': 735,
    'Marinići': 735,
    'Matulji': 735,
    'Mihotići': 735,
    'Mrkopalj': 735,
    'Njivice': 735,
    'Novi Vinodolski': 735,
    'Omišalj': 735,
    'Opatija': 735,
    'Podhum': 735,
    'Punat': 735,
    'Rab': 735,
    'Rijeka': 735,
    'Rubeši': 735,
    'Selce': 735,
    'Skrad': 735,
    'Škrljevo': 735,
    'Supetarska Draga': 735,
    'Vinodolska općina': 735,
    'Viškovo': 735,
    'Vrbnik': 735,
    'Vrbovsko': 735,
    'Brodarica': 730,
    'Drniš': 730,
    'Grad Drniš': 730,
    'Grad Šibenik': 730,
    'Kistanje': 730,
    'Knin': 730,
    'Murter': 730,
    'Murter-Kornati': 730,
    'Pirovac': 730,
    'Primošten': 730,
    'Promina': 730,
    'Rogoznica': 730,
    'Rogoznica Općina': 730,
    'Šibenik': 730,
    'Skradin': 730,
    'Tisno': 730,
    'Tribunj': 730,
    'Vodice': 730,
    'Budaševo': 733,
    'Dvor': 733,
    'Glina': 733,
    'Grad Glina': 733,
    'Grad Hrvatska Kostajnica': 733,
    'Grad Kutina': 733,
    'Grad Novska': 733,
    'Grad Petrinja': 733,
    'Grad Sisak': 733,
    'Gvozd': 733,
    'Hrvatska Kostajnica': 733,
    'Kutina': 733,
    'Lekenik': 733,
    'Lipovljani': 733,
    'Martinska Ves': 733,
    'Novska': 733,
    'Općina Dvor': 733,
    'Općina Gvozd': 733,
    'Petrinja': 733,
    'Popovača': 733,
    'Repušnica': 733,
    'Sisak': 733,
    'Sunja': 733,
    'Voloder': 733,
    'Baška Voda': 725,
    'Bol': 725,
    'Brela': 725,
    'Brnaze': 725,
    'Dicmo': 725,
    'Donji Vinjani': 725,
    'Duće': 725,
    'Dugi Rat Općina': 725,
    'Dugopolje': 725,
    'Gala': 725,
    'Grabovci': 725,
    'Grad Hvar': 725,
    'Grad Imotski': 725,
    'Grad Makarska': 725,
    'Grad Omiš': 725,
    'Grad Sinj': 725,
    'Grad Split': 725,
    'Grad Supetar': 725,
    'Grad Trogir': 725,
    'Grad Vis': 725,
    'Grad Vrgorac': 725,
    'Gradac': 725,
    'Greda': 725,
    'Grubine': 725,
    'Hrvace': 725,
    'Hvar': 725,
    'Imotski': 725,
    'Jelsa': 725,
    'Jesenice': 725,
    'Kamen': 725,
    'Kaštel Gomilica': 725,
    'Kaštel Kambelovac': 725,
    'Kaštel Lukšić': 725,
    'Kaštel Novi': 725,
    'Kaštel Štafilić': 725,
    'Kaštel Stari': 725,
    'Kaštela': 725,
    'Klis': 725,
    'Komiža': 725,
    'Košute': 725,
    'Lokvičići': 725,
    'Lovreć': 725,
    'Makarska': 725,
    'Milna': 725,
    'Mravince': 725,
    'Muć': 725,
    'Nerežišća': 725,
    'Okrug': 725,
    'Okrug Gornji': 725,
    'Omiš': 725,
    'Otok': 725,
    'Podstrana': 725,
    'Postira': 725,
    'Proložac': 725,
    'Pučišća': 725,
    'Runović': 725,
    'Runovići': 725,
    'Seget': 725,
    'Seget Vranjica': 725,
    'Selca': 725,
    'Sinj': 725,
    'Slatine': 725,
    'Solin': 725,
    'Šolta': 725,
    'Split': 725,
    'Srinjine': 725,
    'Stari Grad': 725,
    'Stobreč': 725,
    'Supetar': 725,
    'Trilj': 725,
    'Trogir': 725,
    'Tučepi': 725,
    'Turjaci': 725,
    'Veliki Drvenik': 725,
    'Vis': 725,
    'Vranjic': 725,
    'Vrgorac': 725,
    'Vrlika': 725,
    'Zagvozd': 725,
    'Zmijavci': 725,
    'Žrnovnica': 725,
    'Beretinec': 739,
    'Breznica': 739,
    'Breznički Hum': 739,
    'Cestica': 739,
    'Donje Ladanje': 739,
    'Gornje Vratno': 739,
    'Gornji Kneginec': 739,
    'Grad Ivanec': 739,
    'Grad Ludbreg': 739,
    'Grad Novi Marof': 739,
    'Grad Varaždin': 739,
    'Hrašćica': 739,
    'Ivanec': 739,
    'Jalkovec': 739,
    'Jalžabet': 739,
    'Klenovnik': 739,
    'Kućan Marof': 739,
    'Lepoglava': 739,
    'Ljubešćica': 739,
    'Ludbreg': 739,
    'Nedeljanec': 739,
    'Petrijanec': 739,
    'Remetinec': 739,
    'Sračinec': 739,
    'Sveti Đurđ': 739,
    'Tužno': 739,
    'Varaždin': 739,
    'Vidovec': 739,
    'Vinica': 739,
    'Čačinci': 732,
    'Čađavica': 732,
    'Crnac': 732,
    'Grad Orahovica': 732,
    'Grad Slatina': 732,
    'Grad Virovitica': 732,
    'Gradina': 732,
    'Mikleuš': 732,
    'Nova Bukovica': 732,
    'Orahovica': 732,
    'Pitomača': 732,
    'Rezovac': 732,
    'Slatina': 732,
    'Sopje': 732,
    'Špišić Bukovica': 732,
    'Suhopolje': 732,
    'Virovitica': 732,
    'Voćin': 732,
    'Zdenci': 732,
    'Andrijaševci': 741,
    'Babina Greda': 741,
    'Bobota': 741,
    'Bogdanovci': 741,
    'Borovo': 741,
    'Borovo Selo': 741,
    'Bošnjaci': 741,
    'Bršadin': 741,
    'Cerić': 741,
    'Cerna': 741,
    'Drenovci': 741,
    'Grad Vinkovci': 741,
    'Grad Vukovar': 741,
    'Grad Županja': 741,
    'Gradište': 741,
    'Gunja': 741,
    'Ilok': 741,
    'Ivankovo': 741,
    'Jarmina': 741,
    'Komletinci': 741,
    'Lovas': 741,
    'Markušica': 741,
    'Mirkovci': 741,
    'Negoslavci': 741,
    'Nijemci': 741,
    'Nuštar': 741,
    'Otok': 741,
    'Privlaka': 741,
    'Retkovci': 741,
    'Rokovci': 741,
    'Soljani': 741,
    'Stari Jankovci': 741,
    'Štitar': 741,
    'Tordinci': 741,
    'Tovarnik': 741,
    'Trpinja': 741,
    'Vinkovci': 741,
    'Vođinci': 741,
    'Vrbanja': 741,
    'Vukovar': 741,
    'Županja': 741,
    'Benkovac': 727,
    'Bibinje': 727,
    'Biograd na Moru': 727,
    'Galovac': 727,
    'Gornji Karin': 727,
    'Gračac': 727,
    'Grad Biograd na Moru': 727,
    'Jasenice': 727,
    'Kali': 727,
    'Kruševo': 727,
    'Nin': 727,
    'Novigrad Općina': 727,
    'Obrovac': 727,
    'Osljak': 727,
    'Pag': 727,
    'Pakoštane': 727,
    'Polača': 727,
    'Poličnik': 727,
    'Posedarje': 727,
    'Preko': 727,
    'Pridraga': 727,
    'Privlaka': 727,
    'Ražanac': 727,
    'Sali': 727,
    'Škabrnja': 727,
    'Stari Grad': 727,
    'Starigrad': 727,
    'Sukošan': 727,
    'Sveti Filip i Jakov': 727,
    'Tkon': 727,
    'Turanj': 727,
    'Ugljan': 727,
    'Vir': 727,
    'Vrsi': 727,
    'Zadar': 727,
    'Brezovica': 738,
    'Centar': 738,
    'Dubrava': 738,
    'Gradska četvrt Donji grad': 738,
    'Gradska četvrt Gornji Grad - Medvescak': 738,
    'Gradska četvrt Podsljeme': 738,
    'Horvati': 738,
    'Jankomir': 738,
    'Ježdovec': 738,
    'Kašina': 738,
    'Lučko': 738,
    'Novi Zagreb': 738,
    'Odra': 738,
    'Sesvete': 738,
    'Stenjevec': 738,
    'Strmec': 738,
    'Zadvorsko': 738,
    'Zagreb': 738,
    'Bestovje': 736,
    'Bistra': 736,
    'Brckovljani': 736,
    'Brdovec': 736,
    'Bregana': 736,
    'Donja Bistra': 736,
    'Donja Lomnica': 736,
    'Donja Zdenčina': 736,
    'Donji Stupnik': 736,
    'Farkaševac': 736,
    'Gornja Bistra': 736,
    'Gračec': 736,
    'Grad Dugo Selo': 736,
    'Grad Jastrebarsko': 736,
    'Grad Samobor': 736,
    'Grad Sveti Ivan Zelina': 736,
    'Grad Velika Gorica': 736,
    'Grad Vrbovec': 736,
    'Grad Zaprešić': 736,
    'Gradec': 736,
    'Gradići': 736,
    'Jablanovec': 736,
    'Jakovlje': 736,
    'Jastrebarsko': 736,
    'Kerestinec': 736,
    'Križ': 736,
    'Kuče': 736,
    'Lonjica': 736,
    'Luka': 736,
    'Lukavec': 736,
    'Lupoglav': 736,
    'Mičevec': 736,
    'Mraclin': 736,
    'Novo Čiče': 736,
    'Novoselec': 736,
    'Općina Dubrava': 736,
    'Orešje': 736,
    'Pojatno': 736,
    'Preseka': 736,
    'Prigorje Brdovečko': 736,
    'Pušća': 736,
    'Rakitje': 736,
    'Rakov Potok': 736,
    'Rude': 736,
    'Samobor': 736,
    'Stupnik': 736,
    'Sveta Nedelja': 736,
    'Sveta Nedjelja': 736,
    'Velika Gorica': 736,
    'Velika Mlaka': 736,
    'Velika Ostrna': 736,
    'Vrbovec': 736,
    'Zaprešić': 736,
    'Zdenci Brdovečki': 736,
    'Alquízar': 283,
    'Artemisa': 283,
    'Bahía Honda': 283,
    'Bauta': 283,
    'Cabañas': 283,
    'Candelaria': 283,
    'Guanajay': 283,
    'Güira de Melena': 283,
    'Mariel': 283,
    'Municipio de Artemisa': 283,
    'Municipio de Bauta': 283,
    'Municipio de Guanajay': 283,
    'Municipio de Mariel': 283,
    'Municipio de San Cristóbal': 283,
    'San Antonio de los Baños': 283,
    'San Cristobal': 283,
    'Soroa': 283,
    'Camagüey': 286,
    'El Caney': 286,
    'Esmeralda': 286,
    'Florida': 286,
    'Guáimaro': 286,
    'Jimaguayú': 286,
    'Minas': 286,
    'Municipio de Florida': 286,
    'Municipio de Nuevitas': 286,
    'Nuevitas': 286,
    'Santa Cruz del Sur': 286,
    'Sibanicú': 286,
    'Vertientes': 286,
    'Baraguá': 282,
    'Chambas': 282,
    'Ciego de Ávila': 282,
    'Ciro Redondo': 282,
    'Florencia': 282,
    'Morón': 282,
    'Municipio de Ciego de Ávila': 282,
    'Municipio de Morón': 282,
    'Primero de Enero': 282,
    'Venezuela': 282,
    'Abreus': 287,
    'Aguada de Pasajeros': 287,
    'Cienfuegos': 287,
    'Cruces': 287,
    'Cumanayagua': 287,
    'Lajas': 287,
    'Municipio de Abreus': 287,
    'Municipio de Cienfuegos': 287,
    'Palmira': 287,
    'Rodas': 287,
    'Bartolomé Masó': 275,
    'Bayamo': 275,
    'Campechuela': 275,
    'Cauto Cristo': 275,
    'Guisa': 275,
    'Jiguaní': 275,
    'Manzanillo': 275,
    'Media Luna': 275,
    'Municipio de Bayamo': 275,
    'Municipio de Manzanillo': 275,
    'Municipio de Niquero': 275,
    'Niquero': 275,
    'Río Cauto': 275,
    'Yara': 275,
    'Baracoa': 285,
    'Guantánamo': 285,
    'Maisí': 285,
    'Municipio de Guantánamo': 285,
    'Río Guayabal de Yateras': 285,
    'San Antonio del Sur': 285,
    'Alamar': 272,
    'Arroyo Naranjo': 272,
    'Boyeros': 272,
    'Centro Habana': 272,
    'Cerro': 272,
    'Diez de Octubre': 272,
    'Guanabacoa': 272,
    'Habana del Este': 272,
    'Havana': 272,
    'La Habana Vieja': 272,
    'Regla': 272,
    'San Miguel del Padrón': 272,
    'Santiago de las Vegas': 272,
    'Banes': 279,
    'Cacocum': 279,
    'Cueto': 279,
    'Gibara': 279,
    'Holguín': 279,
    'Jobabo': 279,
    'Moa': 279,
    'Municipio de Banes': 279,
    'Municipio de Holguín': 279,
    'Sagua de Tánamo': 279,
    'San Germán': 279,
    'Nueva Gerona': 278,
    'Amancio': 281,
    'Colombia': 281,
    'Jesús Menéndez': 281,
    'Jobabo': 281,
    'Las Tunas': 281,
    'Manatí': 281,
    'Puerto Padre': 281,
    'Alacranes': 284,
    'Bolondrón': 284,
    'Calimete': 284,
    'Cárdenas': 284,
    'Colón': 284,
    'Jagüey Grande': 284,
    'Jovellanos': 284,
    'Limonar': 284,
    'Los Arabos': 284,
    'Manguito': 284,
    'Martí': 284,
    'Matanzas': 284,
    'Municipio de Cárdenas': 284,
    'Municipio de Matanzas': 284,
    'Pedro Betancourt': 284,
    'Perico': 284,
    'Unión de Reyes': 284,
    'Varadero': 284,
    'Batabanó': 276,
    'Bejucal': 276,
    'Güines': 276,
    'Jamaica': 276,
    'Jaruco': 276,
    'La Salud': 276,
    'Madruga': 276,
    'Municipio de Güines': 276,
    'Municipio de Melena del Sur': 276,
    'Quivicán': 276,
    'San José de las Lajas': 276,
    'San Nicolás de Bari': 276,
    'Santa Cruz del Norte': 276,
    'Consolación del Sur': 277,
    'Guane': 277,
    'Los Palacios': 277,
    'Mantua': 277,
    'Minas de Matahambre': 277,
    'Municipio de Consolación del Sur': 277,
    'Municipio de Guane': 277,
    'Municipio de La Palma': 277,
    'Municipio de Los Palacios': 277,
    'Pinar del Río': 277,
    'Puerto Esperanza': 277,
    'San Diego de Los Baños': 277,
    'San Luis': 277,
    'Viñales': 277,
    'Cabaiguán': 274,
    'Condado': 274,
    'Fomento': 274,
    'Guayos': 274,
    'Jatibonico': 274,
    'La Sierpe': 274,
    'Municipio de Cabaiguán': 274,
    'Municipio de Jatibonico': 274,
    'Municipio de Sancti Spíritus': 274,
    'Municipio de Trinidad': 274,
    'Sancti Spíritus': 274,
    'Topes de Collantes': 274,
    'Trinidad': 274,
    'Yaguajay': 274,
    'Zaza del Medio': 274,
    'Contramaestre': 273,
    'El Cobre': 273,
    'Municipio de Palma Soriano': 273,
    'Municipio de Santiago de Cuba': 273,
    'Palma Soriano': 273,
    'San Luis': 273,
    'Santiago de Cuba': 273,
    'Caibarién': 280,
    'Calabazar de Sagua': 280,
    'Camajuaní': 280,
    'Cifuentes': 280,
    'Corralillo': 280,
    'Encrucijada': 280,
    'Esperanza': 280,
    'Isabela de Sagua': 280,
    'Manicaragua': 280,
    'Municipio de Placetas': 280,
    'Municipio de Santa Clara': 280,
    'Placetas': 280,
    'Quemado de Güines': 280,
    'Rancho Veloz': 280,
    'Ranchuelo': 280,
    'Sagua la Grande': 280,
    'Santa Clara': 280,
    'Santo Domingo': 280,
    'Acherítou': 749,
    'Áchna': 749,
    'Ammochostos Municipality': 749,
    'Avgórou': 749,
    'Ayia Napa': 749,
    'Derýneia': 749,
    'Famagusta': 749,
    'Frénaros': 749,
    'Lefkónoiko': 749,
    'Leonárisso': 749,
    'Liopétri': 749,
    'Paralímni': 749,
    'Protaras': 749,
    'Rizokárpaso': 749,
    'Tríkomo': 749,
    'Kyrenia': 744,
    'Kyrenia Municipality': 744,
    'Lápithos': 744,
    'Ágios Týchon': 747,
    'Aradíppou': 747,
    'Athíenou': 747,
    'Dhromolaxia': 747,
    'Kíti': 747,
    'Kofínou': 747,
    'Kolossi': 747,
    'Kórnos': 747,
    'Larnaca': 747,
    'Livádia': 747,
    'Meneou': 747,
    'Mosfilotí': 747,
    'Pérgamos': 747,
    'Perivólia': 747,
    'Psevdás': 747,
    'Pýla': 747,
    'Tersefánou': 747,
    'Troúlloi': 747,
    'Voróklini': 747,
    'Xylofágou': 747,
    'Xylotymbou': 747,
    'Ágios Tomás': 748,
    'Erími': 748,
    'Germasógeia': 748,
    'Kyperoúnta': 748,
    'Lemesós': 748,
    'Limassol': 748,
    'Mouttagiáka': 748,
    'Páchna': 748,
    'Páno Polemídia': 748,
    'Parekklisha': 748,
    'Peléndri': 748,
    'Pissoúri': 748,
    'Pyrgos': 748,
    'Sotíra': 748,
    'Soúni-Zanakiá': 748,
    'Ýpsonas': 748,
    'Akáki': 745,
    'Alámpra': 745,
    'Aredioú': 745,
    'Astromerítis': 745,
    'Dáli': 745,
    'Ergátes': 745,
    'Géri': 745,
    'Kakopetriá': 745,
    'Káto Defterá': 745,
    'Káto Pýrgos': 745,
    'Klírou': 745,
    'Kokkinotrimithiá': 745,
    'Léfka': 745,
    'Lýmpia': 745,
    'Lythrodóntas': 745,
    'Mámmari': 745,
    'Méniko': 745,
    'Mórfou': 745,
    'Nicosia': 745,
    'Nicosia Municipality': 745,
    'Páno Defterá': 745,
    'Péra': 745,
    'Peristeróna': 745,
    'Psimolofou': 745,
    'Tséri': 745,
    'Argáka': 746,
    'Chlórakas': 746,
    'Emba': 746,
    'Geroskipou': 746,
    'Geroskípou (quarter)': 746,
    'Geroskípou Municipality': 746,
    'Kissonerga': 746,
    'Koloni': 746,
    'Konia': 746,
    'Mesógi': 746,
    'Paphos': 746,
    'Pégeia': 746,
    'Pólis': 746,
    'Tála': 746,
    'Tsáda': 746,
    'Bavorov': 4639,
    'Bechyně': 4639,
    'Benešov nad Černou': 4639,
    'Bernartice': 4639,
    'Blatná': 4639,
    'Borek': 4639,
    'Borová Lada': 4639,
    'Borovany': 4639,
    'Brloh': 4639,
    'České Budějovice': 4639,
    'České Velenice': 4639,
    'Český Krumlov': 4639,
    'Český Rudolec': 4639,
    'Chlum u Třeboně': 4639,
    'Chotoviny': 4639,
    'Chvalšiny': 4639,
    'Chýnov': 4639,
    'Chyšky': 4639,
    'Čimelice': 4639,
    'Čkyně': 4639,
    'Dačice': 4639,
    'Dolní Bukovsko': 4639,
    'Dolní Dvořiště': 4639,
    'Dřiteň': 4639,
    'Dubné': 4639,
    'Frymburk': 4639,
    'Hluboká nad Vltavou': 4639,
    'Horní Planá': 4639,
    'Horní Stropnice': 4639,
    'Hrdějovice': 4639,
    'Husinec': 4639,
    'Jarošov nad Nežárkou': 4639,
    'Jindřichův Hradec': 4639,
    'Jistebnice': 4639,
    'Kájov': 4639,
    'Kamenný Újezd': 4639,
    'Kaplice': 4639,
    'Kardašova Řečice': 4639,
    'Katovice': 4639,
    'Kovářov': 4639,
    'Křemže': 4639,
    'Kunžak': 4639,
    'Ledenice': 4639,
    'Lhenice': 4639,
    'Lišov': 4639,
    'Litvínovice': 4639,
    'Lomnice nad Lužnicí': 4639,
    'Loučovice': 4639,
    'Malonty': 4639,
    'Malšice': 4639,
    'Milevsko': 4639,
    'Mirotice': 4639,
    'Mirovice': 4639,
    'Mladá Vožice': 4639,
    'Netolice': 4639,
    'Nová Bystřice': 4639,
    'Nová Včelnice': 4639,
    'Nové Hrady': 4639,
    'Okres České Budějovice': 4639,
    'Okres Český Krumlov': 4639,
    'Okres Jindřichův Hradec': 4639,
    'Okres Písek': 4639,
    'Okres Prachatice': 4639,
    'Okres Strakonice': 4639,
    'Okres Tábor': 4639,
    'Opařany': 4639,
    'Písek': 4639,
    'Planá nad Lužnicí': 4639,
    'Prachatice': 4639,
    'Protivín': 4639,
    'Radomyšl': 4639,
    'Rudolfov': 4639,
    'Sedlice': 4639,
    'Sepekov': 4639,
    'Ševětín': 4639,
    'Sezimovo Ústí': 4639,
    'Slavonice': 4639,
    'Soběslav': 4639,
    'Srubec': 4639,
    'Stachy': 4639,
    'Strakonice': 4639,
    'Strmilov': 4639,
    'Strunkovice nad Blanicí': 4639,
    'Studená': 4639,
    'Suchdol nad Lužnicí': 4639,
    'Tábor': 4639,
    'Třeboň': 4639,
    'Trhové Sviny': 4639,
    'Týn nad Vltavou': 4639,
    'Vacov': 4639,
    'Včelná': 4639,
    'Velešín': 4639,
    'Veselí nad Lužnicí': 4639,
    'Větřní': 4639,
    'Vimperk': 4639,
    'Vlachovo Březí': 4639,
    'Vodňany': 4639,
    'Volary': 4639,
    'Volyně': 4639,
    'Vyšší Brod': 4639,
    'Zdíkov': 4639,
    'Zliv': 4639,
    'Adamov': 4602,
    'Bílovice nad Svitavou': 4602,
    'Blansko': 4602,
    'Blažovice': 4602,
    'Blížkovice': 4602,
    'Blučina': 4602,
    'Bořetice': 4602,
    'Bořitov': 4602,
    'Boskovice': 4602,
    'Bošovice': 4602,
    'Božice': 4602,
    'Břeclav': 4602,
    'Březí': 4602,
    'Brno': 4602,
    'Bučovice': 4602,
    'Bzenec': 4602,
    'Čebín': 4602,
    'Čejč': 4602,
    'Čejkovice': 4602,
    'Černá Hora': 4602,
    'Dambořice': 4602,
    'Dobšice': 4602,
    'Dolní Bojanovice': 4602,
    'Dolní Dunajovice': 4602,
    'Dolní Kounice': 4602,
    'Dolní Loućky': 4602,
    'Domanín': 4602,
    'Doubravice nad Svitavou': 4602,
    'Drásov': 4602,
    'Drnholec': 4602,
    'Drnovice': 4602,
    'Dubňany': 4602,
    'Hevlín': 4602,
    'Hlohovec': 4602,
    'Hodonice': 4602,
    'Hodonín': 4602,
    'Hostěradice': 4602,
    'Hovorany': 4602,
    'Hroznová Lhota': 4602,
    'Hrušky': 4602,
    'Hrušovany nad Jevišovkou': 4602,
    'Hrušovany u Brna': 4602,
    'Hustopeče': 4602,
    'Ivančice': 4602,
    'Ivanovice na Hané': 4602,
    'Jaroslavice': 4602,
    'Jedovnice': 4602,
    'Jevišovice': 4602,
    'Klobouky': 4602,
    'Kněždub': 4602,
    'Kobylí': 4602,
    'Kostice': 4602,
    'Křenovice': 4602,
    'Křepice': 4602,
    'Krumvíř': 4602,
    'Kunštát': 4602,
    'Kuřim': 4602,
    'Kyjov': 4602,
    'Lanžhot': 4602,
    'Lednice': 4602,
    'Lelekovice': 4602,
    'Letonice': 4602,
    'Letovice': 4602,
    'Lipov': 4602,
    'Lipovec': 4602,
    'Lipůvka': 4602,
    'Lomnice': 4602,
    'Louka': 4602,
    'Lužice': 4602,
    'Lysice': 4602,
    'Měnín': 4602,
    'Město Brno': 4602,
    'Mikulčice': 4602,
    'Mikulov': 4602,
    'Milotice': 4602,
    'Miroslav': 4602,
    'Modřice': 4602,
    'Mokrá Hora': 4602,
    'Moravany': 4602,
    'Moravská Nová Ves': 4602,
    'Moravský Krumlov': 4602,
    'Moravský Písek': 4602,
    'Moravský Žižkov': 4602,
    'Moutnice': 4602,
    'Mutěnice': 4602,
    'Nedvědice': 4602,
    'Nesovice': 4602,
    'Nosislav': 4602,
    'Novosedly': 4602,
    'Okres Blansko': 4602,
    'Okres Břeclav': 4602,
    'Okres Brno-venkov': 4602,
    'Okres Hodonín': 4602,
    'Okres Vyškov': 4602,
    'Okres Znojmo': 4602,
    'Olbramovice': 4602,
    'Olešnice': 4602,
    'Ořechov': 4602,
    'Oslavany': 4602,
    'Ostopovice': 4602,
    'Ostrov u Macochy': 4602,
    'Otnice': 4602,
    'Petrov': 4602,
    'Podivín': 4602,
    'Podolí': 4602,
    'Pohořelice': 4602,
    'Pozořice': 4602,
    'Předklášteří': 4602,
    'Prušánky': 4602,
    'Pustiměř': 4602,
    'Rájec-Jestřebí': 4602,
    'Ráječko': 4602,
    'Rajhrad': 4602,
    'Rajhradice': 4602,
    'Rakvice': 4602,
    'Ratíškovice': 4602,
    'Říčany': 4602,
    'Rohatec': 4602,
    'Rosice': 4602,
    'Rousínov': 4602,
    'Šakvice': 4602,
    'Šanov': 4602,
    'Šardice': 4602,
    'Šatov': 4602,
    'Šitbořice': 4602,
    'Šlapanice': 4602,
    'Slavkov u Brna': 4602,
    'Sokolnice': 4602,
    'Strážnice': 4602,
    'Střelice': 4602,
    'Suchohrdly': 4602,
    'Sudoměřice': 4602,
    'Svitávka': 4602,
    'Tasovice': 4602,
    'Telnice': 4602,
    'Těšany': 4602,
    'Tišnov': 4602,
    'Troubsko': 4602,
    'Tvarožná': 4602,
    'Tvrdonice': 4602,
    'Týnec': 4602,
    'Únanov': 4602,
    'Vacenovice': 4602,
    'Valtice': 4602,
    'Velká nad Veličkou': 4602,
    'Velké Bílovice': 4602,
    'Velké Němčice': 4602,
    'Velké Opatovice': 4602,
    'Velké Pavlovice': 4602,
    'Veselí nad Moravou': 4602,
    'Veverská Bítýška': 4602,
    'Viničné Šumice': 4602,
    'Višňové': 4602,
    'Vlkoš': 4602,
    'Vnorovy': 4602,
    'Vojkovice': 4602,
    'Vracov': 4602,
    'Vranovice': 4602,
    'Vrbice': 4602,
    'Vrbovec': 4602,
    'Vyškov': 4602,
    'Žabčice': 4602,
    'Zaječí': 4602,
    'Zastávka': 4602,
    'Zbraslav': 4602,
    'Zbýšov': 4602,
    'Ždánice': 4602,
    'Želešice': 4602,
    'Žeravice': 4602,
    'Židlochovice': 4602,
    'Znojmo': 4602,
    'Abertamy': 4581,
    'Aš': 4581,
    'Bochov': 4581,
    'Březová': 4581,
    'Bukovany': 4581,
    'Cheb': 4581,
    'Chodov': 4581,
    'Dalovice': 4581,
    'Dolní Rychnov': 4581,
    'Dolní Žandov': 4581,
    'Františkovy Lázně': 4581,
    'Habartov': 4581,
    'Hazlov': 4581,
    'Horní Slavkov': 4581,
    'Hranice': 4581,
    'Hroznětín': 4581,
    'Jáchymov': 4581,
    'Karlovy Vary': 4581,
    'Klášter': 4581,
    'Kraslice': 4581,
    'Kynšperk nad Ohří': 4581,
    'Lázně Kynžvart': 4581,
    'Loket': 4581,
    'Lomnice': 4581,
    'Luby': 4581,
    'Mariánské Lázně': 4581,
    'Merklín': 4581,
    'Město': 4581,
    'Nejdek': 4581,
    'Nová Role': 4581,
    'Nové Sedlo': 4581,
    'Okres Cheb': 4581,
    'Okres Karlovy Vary': 4581,
    'Okres Sokolov': 4581,
    'Oloví': 4581,
    'Ostrov': 4581,
    'Plesná': 4581,
    'Rotava': 4581,
    'Sadov': 4581,
    'Skalná': 4581,
    'Sokolov': 4581,
    'Svatava': 4581,
    'Toužim': 4581,
    'Velká Hleďsebe': 4581,
    'Vintířov': 4581,
    'Žlutice': 4581,
    'Batelov': 4575,
    'Bohdalov': 4575,
    'Brtnice': 4575,
    'Budišov': 4575,
    'Bystřice nad Pernštejnem': 4575,
    'Černovice': 4575,
    'Chotěboř': 4575,
    'Dobronín': 4575,
    'Dolní Cerekev': 4575,
    'Golčův Jeníkov': 4575,
    'Habry': 4575,
    'Havlíčkův Brod': 4575,
    'Herálec': 4575,
    'Horní Cerekev': 4575,
    'Hrotovice': 4575,
    'Humpolec': 4575,
    'Jaroměřice nad Rokytnou': 4575,
    'Jemnice': 4575,
    'Jihlava': 4575,
    'Jimramov': 4575,
    'Kamenice': 4575,
    'Kamenice nad Lipou': 4575,
    'Kněžice': 4575,
    'Křižanov': 4575,
    'Křížová': 4575,
    'Ledeč nad Sázavou': 4575,
    'Lípa': 4575,
    'Luka nad Jihlavou': 4575,
    'Lukavec': 4575,
    'Měřín': 4575,
    'Mohelno': 4575,
    'Moravské Budějovice': 4575,
    'Náměšť nad Oslavou': 4575,
    'Nová Cerekev': 4575,
    'Nové Město na Moravě': 4575,
    'Nové Syrovice': 4575,
    'Nové Veselí': 4575,
    'Okres Havlíčkův Brod': 4575,
    'Okres Jihlava': 4575,
    'Okres Pelhřimov': 4575,
    'Okres Třebíč': 4575,
    'Okres Žďár nad Sázavou': 4575,
    'Okříšky': 4575,
    'Okrouhlice': 4575,
    'Pacov': 4575,
    'Pelhřimov': 4575,
    'Počátky': 4575,
    'Polná': 4575,
    'Přibyslav': 4575,
    'Rouchovany': 4575,
    'Stařeč': 4575,
    'Štoky': 4575,
    'Světlá nad Sázavou': 4575,
    'Svratka': 4575,
    'Telč': 4575,
    'Třebíč': 4575,
    'Třešť': 4575,
    'Velká Bíteš': 4575,
    'Velké Meziříčí': 4575,
    'Velký Beranov': 4575,
    'Vilémov': 4575,
    'Vladislav': 4575,
    'Žďár nad Sázavou': 4575,
    'Žďár nad Sázavou Druhy': 4575,
    'Želetava': 4575,
    'Želiv': 4575,
    'Žirovnice': 4575,
    'Albrechtice nad Orlicí': 4614,
    'Bílá Třemešná': 4614,
    'Borohrádek': 4614,
    'Broumov': 4614,
    'Častolovice': 4614,
    'Černilov': 4614,
    'Černožice': 4614,
    'Červený Kostelec': 4614,
    'Česká Skalice': 4614,
    'České Meziříčí': 4614,
    'Chlumec nad Cidlinou': 4614,
    'Dobruška': 4614,
    'Dolní Černilov': 4614,
    'Doudleby nad Orlicí': 4614,
    'Dvůr Králové nad Labem': 4614,
    'Hořice': 4614,
    'Hostinné': 4614,
    'Hradec Králové': 4614,
    'Hronov': 4614,
    'Jaroměř': 4614,
    'Jičín': 4614,
    'Kopidlno': 4614,
    'Kostelec nad Orlicí': 4614,
    'Kvasiny': 4614,
    'Lázně Bělohrad': 4614,
    'Libáň': 4614,
    'Machov': 4614,
    'Malé Svatoňovice': 4614,
    'Meziměstí': 4614,
    'Mladé Buky': 4614,
    'Mostek': 4614,
    'Náchod': 4614,
    'Nechanice': 4614,
    'Nová Paka': 4614,
    'Nové Město nad Metují': 4614,
    'Nový Bydžov': 4614,
    'Okres Hradec Králové': 4614,
    'Okres Jičín': 4614,
    'Okres Náchod': 4614,
    'Okres Rychnov nad Kněžnou': 4614,
    'Okres Trutnov': 4614,
    'Opočno': 4614,
    'Ostroměř': 4614,
    'Pecka': 4614,
    'Pilníkov': 4614,
    'Police nad Metují': 4614,
    'Předměřice nad Labem': 4614,
    'Provodov-Šonov': 4614,
    'Radvanice': 4614,
    'Rokytnice v Orlických Horách': 4614,
    'Rtyně v Podkrkonoší': 4614,
    'Rudník': 4614,
    'Rychnov nad Kněžnou': 4614,
    'Skuhrov nad Bělou': 4614,
    'Smidary': 4614,
    'Smiřice': 4614,
    'Sobotka': 4614,
    'Solnice': 4614,
    'Špindlerův Mlýn': 4614,
    'Stará Paka': 4614,
    'Staré Nechanice': 4614,
    'Stěžery': 4614,
    'Svoboda nad Úpou': 4614,
    'Teplice nad Metují': 4614,
    'Třebechovice pod Orebem': 4614,
    'Trutnov': 4614,
    'Týniště nad Orlicí': 4614,
    'Úpice': 4614,
    'Valdice': 4614,
    'Vamberk': 4614,
    'Velké Poříčí': 4614,
    'Velké Svatoňovice': 4614,
    'Vrchlabí': 4614,
    'Všestary': 4614,
    'Žacléř': 4614,
    'Zadní Mostek': 4614,
    'Železnice': 4614,
    'Benecko': 4601,
    'Brniště': 4601,
    'Česká Lípa': 4601,
    'Český Dub': 4601,
    'Chrastava': 4601,
    'Cvikov': 4601,
    'Desná': 4601,
    'Doksy': 4601,
    'Dubá': 4601,
    'Frýdlant': 4601,
    'Harrachov': 4601,
    'Hejnice': 4601,
    'Hodkovice nad Mohelkou': 4601,
    'Horní Branná': 4601,
    'Hrádek nad Nisou': 4601,
    'Jablonec nad Jizerou': 4601,
    'Jablonec nad Nisou': 4601,
    'Jablonné v Podještědí': 4601,
    'Janov nad Nisou': 4601,
    'Jilemnice': 4601,
    'Josefův Důl': 4601,
    'Kamenický Šenov': 4601,
    'Kořenov': 4601,
    'Košťálov': 4601,
    'Liberec': 4601,
    'Lomnice nad Popelkou': 4601,
    'Lučany nad Nisou': 4601,
    'Malá Skála': 4601,
    'Mimoň': 4601,
    'Mníšek': 4601,
    'Nové Město pod Smrkem': 4601,
    'Nový Bor': 4601,
    'Ohrazenice': 4601,
    'Okres Česká Lípa': 4601,
    'Okres Jablonec nad Nisou': 4601,
    'Okres Liberec': 4601,
    'Okres Semily': 4601,
    'Osečná': 4601,
    'Pěnčín': 4601,
    'Plavy': 4601,
    'Poniklá': 4601,
    'Příšovice': 4601,
    'Raspenava': 4601,
    'Rokytnice nad Jizerou': 4601,
    'Rovensko pod Troskami': 4601,
    'Semily': 4601,
    'Smržovka': 4601,
    'Stráž nad Nisou': 4601,
    'Stráž pod Ralskem': 4601,
    'Studenec': 4601,
    'Tanvald': 4601,
    'Turnov': 4601,
    'Valdice': 4601,
    'Velké Hamry': 4601,
    'Višňova': 4601,
    'Vysoké nad Jizerou': 4601,
    'Zákupy': 4601,
    'Žandov': 4601,
    'Železný Brod': 4601,
    'Albrechtice': 4600,
    'Bartošovice': 4600,
    'Baška': 4600,
    'Bílovec': 4600,
    'Bohumín': 4600,
    'Bohuslavice': 4600,
    'Bolatice': 4600,
    'Brantice': 4600,
    'Březová': 4600,
    'Břidličná': 4600,
    'Brumovice': 4600,
    'Bruntál': 4600,
    'Brušperk': 4600,
    'Budišov nad Budišovkou': 4600,
    'Bukovec': 4600,
    'Bystřice': 4600,
    'Čeladná': 4600,
    'Český Těšín': 4600,
    'Chlebičov': 4600,
    'Chotěbuz': 4600,
    'Chuchelná': 4600,
    'Darkovice': 4600,
    'Dětmarovice': 4600,
    'Dobrá': 4600,
    'Dobratice': 4600,
    'Dolní Benešov': 4600,
    'Dolní Lhota': 4600,
    'Dolní Lutyně': 4600,
    'Dolní Životice': 4600,
    'Doubrava': 4600,
    'Dvorce': 4600,
    'Frenštát pod Radhoštěm': 4600,
    'Fryčovice': 4600,
    'Frýdek-Místek': 4600,
    'Frýdlant nad Ostravicí': 4600,
    'Fulnek': 4600,
    'Háj ve Slezsku': 4600,
    'Hať': 4600,
    'Havířov': 4600,
    'Hlučín': 4600,
    'Hněvošice': 4600,
    'Hnojník': 4600,
    'Hodslavice': 4600,
    'Holasovice': 4600,
    'Horní Benešov': 4600,
    'Horní Bludovice': 4600,
    'Horní Město': 4600,
    'Horní Suchá': 4600,
    'Horní Těrlicko': 4600,
    'Hrabyně': 4600,
    'Hradec nad Moravici': 4600,
    'Hrádek': 4600,
    'Hukvaldy': 4600,
    'Jablunkov': 4600,
    'Jakartovice': 4600,
    'Janovice': 4600,
    'Jeseník nad Odrou': 4600,
    'Jindřichov': 4600,
    'Jistebník': 4600,
    'Karlovice': 4600,
    'Karviná': 4600,
    'Klimkovice': 4600,
    'Kobeřice': 4600,
    'Komorní Lhotka': 4600,
    'Kopřivnice': 4600,
    'Kozlovice': 4600,
    'Kozmice': 4600,
    'Kravaře': 4600,
    'Krmelín': 4600,
    'Krnov': 4600,
    'Kunčice pod Ondřejníkem': 4600,
    'Kunín': 4600,
    'Lichnov': 4600,
    'Lichnov (o. Nový Jičín)': 4600,
    'Lučina': 4600,
    'Ludgeřovice': 4600,
    'Markvartovice': 4600,
    'Město Albrechtice': 4600,
    'Metylovice': 4600,
    'Milíkov': 4600,
    'Mokré Lazce': 4600,
    'Morávka': 4600,
    'Mořkov': 4600,
    'Mosty u Jablunkova': 4600,
    'Návsí u Jablunkova': 4600,
    'Nový Jičín': 4600,
    'Nýdek': 4600,
    'Odry': 4600,
    'Okres Bruntál': 4600,
    'Okres Frýdek-Místek': 4600,
    'Okres Karviná': 4600,
    'Okres Nový Jičín': 4600,
    'Okres Opava': 4600,
    'Okres Ostrava-město': 4600,
    'Oldřišov': 4600,
    'Opava': 4600,
    'Orlová': 4600,
    'Osoblaha': 4600,
    'Ostrava': 4600,
    'Ostravice': 4600,
    'Otice': 4600,
    'Palkovice': 4600,
    'Paskov': 4600,
    'Petrovice u Karviné': 4600,
    'Petřvald': 4600,
    'Písek': 4600,
    'Píšť': 4600,
    'Pražmo': 4600,
    'Příbor': 4600,
    'Pustá Polom': 4600,
    'Raškovice': 4600,
    'Řepiště': 4600,
    'Ropice': 4600,
    'Rybí': 4600,
    'Rychvald': 4600,
    'Rýmařov': 4600,
    'Sedliště': 4600,
    'Sedlnice': 4600,
    'Šenov': 4600,
    'Šilheřovice': 4600,
    'Slavkov': 4600,
    'Stará Ves nad Ondřejnicí': 4600,
    'Staré Město': 4600,
    'Staříč': 4600,
    'Starý Bohumín': 4600,
    'Starý Jičín': 4600,
    'Stěbořice': 4600,
    'Štěpánkovice': 4600,
    'Štítina': 4600,
    'Stonava': 4600,
    'Štramberk': 4600,
    'Studénka': 4600,
    'Sviadnov': 4600,
    'Tichá': 4600,
    'Třinec': 4600,
    'Trojanovice': 4600,
    'Václavovice': 4600,
    'Velká Polom': 4600,
    'Velké Heraltice': 4600,
    'Velké Hoštice': 4600,
    'Vendryně': 4600,
    'Veřovice': 4600,
    'Vítkov': 4600,
    'Vlčnov': 4600,
    'Vratimov': 4600,
    'Vrbice': 4600,
    'Vrbno pod Pradědem': 4600,
    'Vřesina': 4600,
    'Zátor': 4600,
    'Bedihošť': 4589,
    'Bělotín': 4589,
    'Bílá Lhota': 4589,
    'Bludov': 4589,
    'Bohuňovice': 4589,
    'Bouzov': 4589,
    'Brodek u Přerova': 4589,
    'Čelechovice na Hané': 4589,
    'Červenka': 4589,
    'Česká Ves': 4589,
    'Dolany': 4589,
    'Dolní Bohdíkov': 4589,
    'Dolní Studénky': 4589,
    'Dolní Újezd': 4589,
    'Doloplazy': 4589,
    'Drahanovice': 4589,
    'Dřevohostice': 4589,
    'Dub nad Moravou': 4589,
    'Dubicko': 4589,
    'Grygov': 4589,
    'Hanušovice': 4589,
    'Hlubočky': 4589,
    'Hněvotín': 4589,
    'Horka nad Moravou': 4589,
    'Horní Moštěnice': 4589,
    'Horní Štěpánov': 4589,
    'Hranice': 4589,
    'Hustopeče Nad Bečvou': 4589,
    'Javorník': 4589,
    'Jeseník': 4589,
    'Kojetín': 4589,
    'Kokory': 4589,
    'Konice': 4589,
    'Kostelec na Hané': 4589,
    'Kralice na Hané': 4589,
    'Leština': 4589,
    'Lipník nad Bečvou': 4589,
    'Litovel': 4589,
    'Loštice': 4589,
    'Loučná nad Desnou': 4589,
    'Lutín': 4589,
    'Majetín': 4589,
    'Medlov': 4589,
    'Město Libavá': 4589,
    'Mikulovice': 4589,
    'Mohelnice': 4589,
    'Moravičany': 4589,
    'Moravský Beroun': 4589,
    'Mostkovice': 4589,
    'Náklo': 4589,
    'Náměšť na Hané': 4589,
    'Němčice nad Hanou': 4589,
    'Nezamyslice': 4589,
    'Nový Malín': 4589,
    'Okres Jeseník': 4589,
    'Okres Olomouc': 4589,
    'Okres Přerov': 4589,
    'Okres Prostějov': 4589,
    'Okres Šumperk': 4589,
    'Olomouc': 4589,
    'Olšany': 4589,
    'Osek nad Bečvou': 4589,
    'Oskava': 4589,
    'Otaslavice': 4589,
    'Paseka': 4589,
    'Písečná': 4589,
    'Plumlov': 4589,
    'Postřelmov': 4589,
    'Potštát': 4589,
    'Přáslavice': 4589,
    'Přemyslovice': 4589,
    'Přerov': 4589,
    'Příkazy': 4589,
    'Prostějov': 4589,
    'Protivanov': 4589,
    'Ptení': 4589,
    'Radslavice': 4589,
    'Rapotín': 4589,
    'Rokytnice': 4589,
    'Ruda nad Moravou': 4589,
    'Samotíšky': 4589,
    'Senice na Hané': 4589,
    'Skrbeň': 4589,
    'Slatinice': 4589,
    'Smržice': 4589,
    'Sobotín': 4589,
    'Staré Město': 4589,
    'Štěpánov': 4589,
    'Šternberk': 4589,
    'Štíty': 4589,
    'Strážná': 4589,
    'Sudkov': 4589,
    'Šumperk': 4589,
    'Těšetice': 4589,
    'Tovačov': 4589,
    'Troubelice': 4589,
    'Troubky': 4589,
    'Tršice': 4589,
    'Újezd': 4589,
    'Uničov': 4589,
    'Určice': 4589,
    'Úsov': 4589,
    'Vápenná': 4589,
    'Velká Bystřice': 4589,
    'Velké Losiny': 4589,
    'Velký Týnec': 4589,
    'Velký Újezd': 4589,
    'Věrovany': 4589,
    'Vidnava': 4589,
    'Vikýřovice': 4589,
    'Vrbátky': 4589,
    'Zábřeh': 4589,
    'Zlaté Hory': 4589,
    'Žulová': 4589,
    'Brandýs nad Orlicí': 4588,
    'Březová nad Svitavou': 4588,
    'Brněnec': 4588,
    'Býšť': 4588,
    'Bystré': 4588,
    'Bystřec': 4588,
    'Červená Voda': 4588,
    'Česká Třebová': 4588,
    'Choceň': 4588,
    'Chrast': 4588,
    'Chroustovice': 4588,
    'Chrudim': 4588,
    'Chvaletice': 4588,
    'Dašice': 4588,
    'Dlouhá Třebová': 4588,
    'Dolní Čermná': 4588,
    'Dolní Dobrouč': 4588,
    'Dolní Roveň': 4588,
    'Dolní Sloupnice': 4588,
    'Dolní Újezd': 4588,
    'Heřmanův Městec': 4588,
    'Hlinsko': 4588,
    'Holice': 4588,
    'Horní Čermná': 4588,
    'Horní Jelení': 4588,
    'Horní Sloupnice': 4588,
    'Hradec nad Svitavou': 4588,
    'Hrochův Týnec': 4588,
    'Jablonné nad Orlicí': 4588,
    'Jaroměřice': 4588,
    'Jedlová': 4588,
    'Jevíčko': 4588,
    'Králíky': 4588,
    'Krouna': 4588,
    'Kunčina': 4588,
    'Kunvald': 4588,
    'Lanškroun': 4588,
    'Lázně Bohdaneč': 4588,
    'Letohrad': 4588,
    'Litomyšl': 4588,
    'Lukavice': 4588,
    'Luže': 4588,
    'Městečko Trnávka': 4588,
    'Miřetice': 4588,
    'Moravany': 4588,
    'Moravská Třebová': 4588,
    'Nasavrky': 4588,
    'Okres Chrudim': 4588,
    'Okres Pardubice': 4588,
    'Okres Svitavy': 4588,
    'Okres Ústí nad Orlicí': 4588,
    'Opatov': 4588,
    'Opatovice nad Labem': 4588,
    'Osík': 4588,
    'Pardubice': 4588,
    'Polička': 4588,
    'Pomezí': 4588,
    'Prachovice': 4588,
    'Přelouč': 4588,
    'Proseč': 4588,
    'Radiměř': 4588,
    'Řečany nad Labem': 4588,
    'Ronov nad Doubravou': 4588,
    'Rosice': 4588,
    'Rybitví': 4588,
    'Seč': 4588,
    'Sezemice': 4588,
    'Skuteč': 4588,
    'Slatiňany': 4588,
    'Staré Hradiště': 4588,
    'Svitavy': 4588,
    'Třemošnice': 4588,
    'Ústí nad Orlicí': 4588,
    'Vysoké Mýto': 4588,
    'Žamberk': 4588,
    'Bělá nad Radbuzou': 4608,
    'Bezdružice': 4608,
    'Blížejov': 4608,
    'Blovice': 4608,
    'Bor': 4608,
    'Břasy': 4608,
    'Černošín': 4608,
    'Chlumčany': 4608,
    'Chodová Planá': 4608,
    'Chotěšov': 4608,
    'Chrást': 4608,
    'Dobřany': 4608,
    'Dobřív': 4608,
    'Domažlice': 4608,
    'Dýšina': 4608,
    'Hartmanice': 4608,
    'Heřmanova Huť': 4608,
    'Holoubkov': 4608,
    'Holýšov': 4608,
    'Horažďovice': 4608,
    'Horní Bříza': 4608,
    'Horšovský Týn': 4608,
    'Hostouň': 4608,
    'Hrádek': 4608,
    'Hromnice': 4608,
    'Janovice nad Úhlavou': 4608,
    'Kasejovice': 4608,
    'Kašperské Hory': 4608,
    'Kaznějov': 4608,
    'Kdyně': 4608,
    'Kladruby': 4608,
    'Klatovy': 4608,
    'Klenčí pod Čerchovem': 4608,
    'Kolinec': 4608,
    'Kout na Šumavě': 4608,
    'Kožlany': 4608,
    'Kralovice': 4608,
    'Líně': 4608,
    'Manětín': 4608,
    'Měčín': 4608,
    'Meclov': 4608,
    'Merklín': 4608,
    'Město Touškov': 4608,
    'Mirošov': 4608,
    'Mrákov': 4608,
    'Mýto': 4608,
    'Nepomuk': 4608,
    'Nezvěstice': 4608,
    'Nýřany': 4608,
    'Nýrsko': 4608,
    'Okres Domažlice': 4608,
    'Okres Klatovy': 4608,
    'Okres Plzeň-jih': 4608,
    'Okres Plzeň-město': 4608,
    'Okres Plzeň-sever': 4608,
    'Okres Rokycany': 4608,
    'Okres Tachov': 4608,
    'Osek': 4608,
    'Pilsen': 4608,
    'Planá': 4608,
    'Plánice': 4608,
    'Plasy': 4608,
    'Poběžovice': 4608,
    'Postřekov': 4608,
    'Přeštice': 4608,
    'Přimda': 4608,
    'Radnice': 4608,
    'Rokycany': 4608,
    'Spálené Poříčí': 4608,
    'Šťáhlavy': 4608,
    'Staňkov': 4608,
    'Starý Plzenec': 4608,
    'Štěnovice': 4608,
    'Stod': 4608,
    'Strašice': 4608,
    'Stráž': 4608,
    'Strážov': 4608,
    'Stříbro': 4608,
    'Sušice': 4608,
    'Švihov': 4608,
    'Tachov': 4608,
    'Tlučná': 4608,
    'Třemošná': 4608,
    'Vejprnice': 4608,
    'Všeruby': 4608,
    'Zbiroh': 4608,
    'Zbůch': 4608,
    'Železná Ruda': 4608,
    'Žihle': 4608,
    'Braník': 4598,
    'Černý Most': 4598,
    'Dolní Počernice': 4598,
    'Hodkovičky': 4598,
    'Horní Počernice': 4598,
    'Hostavice': 4598,
    'Karlín': 4598,
    'Kbely': 4598,
    'Letňany': 4598,
    'Libeň': 4598,
    'Malá Strana': 4598,
    'Modřany': 4598,
    'Prague': 4598,
    'Praha 1': 4598,
    'Praha 16': 4598,
    'Praha 20': 4598,
    'Praha 21': 4598,
    'Prosek': 4598,
    'Satalice': 4598,
    'Staré Město': 4598,
    'Střížkov': 4598,
    'Vysehrad': 4598,
    'Vysočany': 4598,
    'Bakov nad Jizerou': 4554,
    'Bělá pod Bezdězem': 4554,
    'Benátky nad Jizerou': 4554,
    'Benešov': 4554,
    'Beroun': 4554,
    'Bohutín': 4554,
    'Brandýs nad Labem-Stará Boleslav': 4554,
    'Brandýsek': 4554,
    'Březnice': 4554,
    'Buštěhrad': 4554,
    'Byšice': 4554,
    'Bystřice': 4554,
    'Čáslav': 4554,
    'Čechtice': 4554,
    'Čelákovice': 4554,
    'Čerčany': 4554,
    'Cerhenice': 4554,
    'Černošice': 4554,
    'Červené Pečky': 4554,
    'Český Brod': 4554,
    'Chyňava': 4554,
    'Církvice': 4554,
    'Cítov': 4554,
    'Davle': 4554,
    'Divišov': 4554,
    'Dobřichovice': 4554,
    'Dobříš': 4554,
    'Dobrovice': 4554,
    'Doksy': 4554,
    'Dolní Beřkovice': 4554,
    'Dolní Bousov': 4554,
    'Dolní Břežany': 4554,
    'Horní Počaply': 4554,
    'Horoměřice': 4554,
    'Hořovice': 4554,
    'Hostivice': 4554,
    'Hostomice': 4554,
    'Hovorčovice': 4554,
    'Hradištko': 4554,
    'Hřebeč': 4554,
    'Hudlice': 4554,
    'Hýskov': 4554,
    'Jeneč': 4554,
    'Jesenice': 4554,
    'Jílové u Prahy': 4554,
    'Jince': 4554,
    'Jirny': 4554,
    'Kačice': 4554,
    'Kamenice': 4554,
    'Kamenné Žehrovice': 4554,
    'Kamenný Přívoz': 4554,
    'Kladno': 4554,
    'Klecany': 4554,
    'Klobuky': 4554,
    'Kněžmost': 4554,
    'Kolín': 4554,
    'Komárov': 4554,
    'Kosmonosy': 4554,
    'Kosova Hora': 4554,
    'Kostelec nad Černými Lesy': 4554,
    'Kostelec nad Labem': 4554,
    'Kouřim': 4554,
    'Kralupy nad Vltavou': 4554,
    'Králův Dvůr': 4554,
    'Krásná Hora nad Vltavou': 4554,
    'Křinec': 4554,
    'Kutná Hora': 4554,
    'Lány': 4554,
    'Libčice nad Vltavou': 4554,
    'Líbeznice': 4554,
    'Libice nad Cidlinou': 4554,
    'Libiš': 4554,
    'Libušín': 4554,
    'Lochovice': 4554,
    'Loděnice': 4554,
    'Loučeň': 4554,
    'Luštěnice': 4554,
    'Lužec nad Vltavou': 4554,
    'Lužná': 4554,
    'Lysá nad Labem': 4554,
    'Mělník': 4554,
    'Měšice': 4554,
    'Městec Králové': 4554,
    'Milín': 4554,
    'Milovice': 4554,
    'Mladá Boleslav': 4554,
    'Mnichovice': 4554,
    'Mnichovo Hradiště': 4554,
    'Mníšek pod Brdy': 4554,
    'Mšeno': 4554,
    'Mukařov': 4554,
    'Načeradec': 4554,
    'Nehvizdy': 4554,
    'Nelahozeves': 4554,
    'Neratovice': 4554,
    'Netvořice': 4554,
    'Neveklov': 4554,
    'Nižbor': 4554,
    'Nové Strašecí': 4554,
    'Nový Knín': 4554,
    'Nymburk': 4554,
    'Obecnice': 4554,
    'Odolena Voda': 4554,
    'Okres Benešov': 4554,
    'Okres Beroun': 4554,
    'Okres Kladno': 4554,
    'Okres Kolín': 4554,
    'Okres Kutná Hora': 4554,
    'Okres Mělník': 4554,
    'Okres Mladá Boleslav': 4554,
    'Okres Nymburk': 4554,
    'Okres Praha-východ': 4554,
    'Okres Praha-západ': 4554,
    'Okres Příbram': 4554,
    'Okres Rakovník': 4554,
    'Ondřejov': 4554,
    'Pavlíkov': 4554,
    'Pchery': 4554,
    'Pečky': 4554,
    'Petrovice': 4554,
    'Plaňany': 4554,
    'Poděbrady': 4554,
    'Poříčany': 4554,
    'Poříčí nad Sázavou': 4554,
    'Postupice': 4554,
    'Přerov nad Labem': 4554,
    'Příbram': 4554,
    'Průhonice': 4554,
    'Psáry': 4554,
    'Pyšely': 4554,
    'Rakovník': 4554,
    'Řevnice': 4554,
    'Řevničov': 4554,
    'Řež': 4554,
    'Říčany': 4554,
    'Rožďalovice': 4554,
    'Rožmitál pod Třemšínem': 4554,
    'Roztoky': 4554,
    'Rudná': 4554,
    'Sadská': 4554,
    'Sázava': 4554,
    'Sedlčany': 4554,
    'Sedlec': 4554,
    'Šestajovice': 4554,
    'Slaný': 4554,
    'Smečno': 4554,
    'Stará Huť': 4554,
    'Starý Kolín': 4554,
    'Štěchovice': 4554,
    'Stochov': 4554,
    'Strančice': 4554,
    'Suchdol': 4554,
    'Tišice': 4554,
    'Třebotov': 4554,
    'Trhový Štěpánov': 4554,
    'Tuchlovice': 4554,
    'Tuchoměřice': 4554,
    'Týnec nad Labem': 4554,
    'Týnec nad Sázavou': 4554,
    'Uhlířské Janovice': 4554,
    'Unhošť': 4554,
    'Úvaly': 4554,
    'Velim': 4554,
    'Velká Dobrá': 4554,
    'Velké Popovice': 4554,
    'Velké Přílepy': 4554,
    'Velký Osek': 4554,
    'Veltruby': 4554,
    'Veltrusy': 4554,
    'Velvary': 4554,
    'Vestec': 4554,
    'Vinařice': 4554,
    'Vlašim': 4554,
    'Votice': 4554,
    'Vrané nad Vltavou': 4554,
    'Vrdy': 4554,
    'Všenory': 4554,
    'Všetaty': 4554,
    'Zaječov': 4554,
    'Zásmuky': 4554,
    'Zbraslavice': 4554,
    'Žďár': 4554,
    'Zdiby': 4554,
    'Zdice': 4554,
    'Žebrák': 4554,
    'Zeleneč': 4554,
    'Žiželice': 4554,
    'Žleby': 4554,
    'Zlonice': 4554,
    'Zruč nad Sázavou': 4554,
    'Zvole': 4554,
    'Bechlín': 4576,
    'Bečov': 4576,
    'Benešov nad Ploučnicí': 4576,
    'Bílina': 4576,
    'Bílina Kyselka': 4576,
    'Bohušovice nad Ohří': 4576,
    'Braňany': 4576,
    'Březno': 4576,
    'Budyně nad Ohří': 4576,
    'Bystřany': 4576,
    'Černčice': 4576,
    'Česká Kamenice': 4576,
    'Chabařovice': 4576,
    'Chlumec': 4576,
    'Chomutov': 4576,
    'Chřibská': 4576,
    'Čížkovice': 4576,
    'Děčín': 4576,
    'Dobroměřice': 4576,
    'Dolní Podluží': 4576,
    'Dolní Poustevna': 4576,
    'Dubí': 4576,
    'Duchcov': 4576,
    'Horní Jiřetín': 4576,
    'Hošťka': 4576,
    'Hostomice': 4576,
    'Hrob': 4576,
    'Jílové': 4576,
    'Jiříkov': 4576,
    'Jirkov': 4576,
    'Kadaň': 4576,
    'Klášterec nad Ohří': 4576,
    'Košťany': 4576,
    'Kovářská': 4576,
    'Krásná Lípa': 4576,
    'Křešice': 4576,
    'Krupka': 4576,
    'Kryry': 4576,
    'Lenešice': 4576,
    'Liběšice': 4576,
    'Libochovice': 4576,
    'Libouchec': 4576,
    'Litoměřice': 4576,
    'Litvínov': 4576,
    'Lom u Mostu': 4576,
    'Louny': 4576,
    'Lovosice': 4576,
    'Lubenec': 4576,
    'Měcholupy': 4576,
    'Meziboři': 4576,
    'Mikulášovice': 4576,
    'Most': 4576,
    'Novosedlice': 4576,
    'Obrnice': 4576,
    'Okres Chomutov': 4576,
    'Okres Děčín': 4576,
    'Okres Litoměřice': 4576,
    'Okres Louny': 4576,
    'Okres Most': 4576,
    'Okres Teplice': 4576,
    'Okres Ústí nad Labem': 4576,
    'Osek': 4576,
    'Perštejn': 4576,
    'Peruc': 4576,
    'Podbořany': 4576,
    'Polepy': 4576,
    'Postoloprty': 4576,
    'Povrly': 4576,
    'Proboštov': 4576,
    'Radonice': 4576,
    'Řehlovice': 4576,
    'Roudnice nad Labem': 4576,
    'Rumburk': 4576,
    'Šluknov': 4576,
    'Staré Křečany': 4576,
    'Štětí': 4576,
    'Teplice': 4576,
    'Terezín': 4576,
    'Třebenice': 4576,
    'Trmice': 4576,
    'Údlice': 4576,
    'Úštěk': 4576,
    'Varnsdorf': 4576,
    'Vejprty': 4576,
    'Velemín': 4576,
    'Velké Březno': 4576,
    'Velký Šenov': 4576,
    'Verneřice': 4576,
    'Vilémov': 4576,
    'Vroutek': 4576,
    'Zabrušany': 4576,
    'Žatec': 4576,
    'Žitenice': 4576,
    'Babice': 4563,
    'Bánov': 4563,
    'Bílovice': 4563,
    'Bojkovice': 4563,
    'Boršice': 4563,
    'Březnice': 4563,
    'Březolupy': 4563,
    'Březová': 4563,
    'Buchlovice': 4563,
    'Bystřice pod Hostýnem': 4563,
    'Chropyně': 4563,
    'Chvalčov': 4563,
    'Dolní Bečva': 4563,
    'Dolní Němčí': 4563,
    'Francova Lhota': 4563,
    'Fryšták': 4563,
    'Halenkov': 4563,
    'Halenkovice': 4563,
    'Hluk': 4563,
    'Holešov': 4563,
    'Horní Bečva': 4563,
    'Horní Lideč': 4563,
    'Hošťálková': 4563,
    'Hovězí': 4563,
    'Hulín': 4563,
    'Hvozdná': 4563,
    'Jablůnka': 4563,
    'Jalubí': 4563,
    'Karolinka': 4563,
    'Kelč': 4563,
    'Kněžpole': 4563,
    'Koryčany': 4563,
    'Kroměříž': 4563,
    'Kunovice': 4563,
    'Kvasice': 4563,
    'Lešná': 4563,
    'Lidečko': 4563,
    'Liptál': 4563,
    'Luhačovice': 4563,
    'Lukov': 4563,
    'Mistřice': 4563,
    'Napajedla': 4563,
    'Nedakonice': 4563,
    'Nedašov': 4563,
    'Nivnice': 4563,
    'Nový Hrozenkov': 4563,
    'Okres Kroměříž': 4563,
    'Okres Uherské Hradiště': 4563,
    'Okres Vsetín': 4563,
    'Okres Zlín': 4563,
    'Ostrožská Lhota': 4563,
    'Ostrožská Nová Ves': 4563,
    'Otrokovice': 4563,
    'Polešovice': 4563,
    'Popovice': 4563,
    'Pozlovice': 4563,
    'Prostřední Bečva': 4563,
    'Prusinovice': 4563,
    'Rataje': 4563,
    'Ratiboř': 4563,
    'Rožnov pod Radhoštěm': 4563,
    'Slavičín': 4563,
    'Slušovice': 4563,
    'Spytihněv': 4563,
    'Staré Město': 4563,
    'Štítná nad Vláří': 4563,
    'Strání': 4563,
    'Suchá Loz': 4563,
    'Šumice': 4563,
    'Tečovice': 4563,
    'Tlumačov': 4563,
    'Topolná': 4563,
    'Traplice': 4563,
    'Trnava': 4563,
    'Tupesy': 4563,
    'Uherské Hradiště': 4563,
    'Uherský Brod': 4563,
    'Uherský Ostroh': 4563,
    'Újezd': 4563,
    'Valašská Bystřice': 4563,
    'Valašská Polanka': 4563,
    'Valašské Klobouky': 4563,
    'Valašské Meziříčí': 4563,
    'Velehrad': 4563,
    'Velké Karlovice': 4563,
    'Vidče': 4563,
    'Vizovice': 4563,
    'Vlachovice': 4563,
    'Vlčnov': 4563,
    'Všemina': 4563,
    'Vsetín': 4563,
    'Záhorovice': 4563,
    'Zašová': 4563,
    'Zborovice': 4563,
    'Zdounky': 4563,
    'Zlechov': 4563,
    'Zlín': 4563,
    'Žlutava': 4563,
    'Zubří': 4563,
    'Åkirkeby': 1530,
    'Albertslund': 1530,
    'Albertslund Kommune': 1530,
    'Allerød Kommune': 1530,
    'Ballerup': 1530,
    'Ballerup Kommune': 1530,
    'Birkerød': 1530,
    'Blovstrød': 1530,
    'Bornholm Kommune': 1530,
    'Brøndby Kommune': 1530,
    'Charlottenlund': 1530,
    'Christianshavn': 1530,
    'Christiansø': 1530,
    'Copenhagen': 1530,
    'Dragør': 1530,
    'Dragør Kommune': 1530,
    'Egedal Kommune': 1530,
    'Espergærde': 1530,
    'Farum': 1530,
    'Fløng': 1530,
    'Fredensborg': 1530,
    'Fredensborg Kommune': 1530,
    'Frederiksberg': 1530,
    'Frederiksberg Kommune': 1530,
    'Frederikssund': 1530,
    'Frederikssund Kommune': 1530,
    'Frederiksværk': 1530,
    'Furesø Kommune': 1530,
    'Ganløse': 1530,
    'Gentofte Kommune': 1530,
    'Gilleleje': 1530,
    'Gladsaxe Municipality': 1530,
    'Glostrup': 1530,
    'Glostrup Kommune': 1530,
    'Græsted': 1530,
    'Gribskov Kommune': 1530,
    'Halsnæs Kommune': 1530,
    'Hellebæk': 1530,
    'Helsinge': 1530,
    'Helsingør': 1530,
    'Helsingør Kommune': 1530,
    'Herlev Kommune': 1530,
    'Hillerød': 1530,
    'Hillerød Kommune': 1530,
    'Hornbæk': 1530,
    'Høje-Taastrup Kommune': 1530,
    'Hørsholm': 1530,
    'Hørsholm Kommune': 1530,
    'Humlebæk': 1530,
    'Hundested': 1530,
    'Hvidovre': 1530,
    'Hvidovre Kommune': 1530,
    'Ishøj': 1530,
    'Ishøj Kommune': 1530,
    'Jægerspris': 1530,
    'Kokkedal': 1530,
    'Kongens Lyngby': 1530,
    'København': 1530,
    'Lillerød': 1530,
    'Liseleje': 1530,
    'Lyngby-Tårbæk Kommune': 1530,
    'Lynge': 1530,
    'Måløv': 1530,
    'Nexø': 1530,
    'Nivå': 1530,
    'Nødebo': 1530,
    'Ølstykke': 1530,
    'Rødovre': 1530,
    'Rødovre Kommune': 1530,
    'Rønne': 1530,
    'Rudersdal Kommune': 1530,
    'Skævinge': 1530,
    'Skibby': 1530,
    'Slangerup': 1530,
    'Smørumnedre': 1530,
    'Stavnsholt': 1530,
    'Stenløse': 1530,
    'Taastrup': 1530,
    'Tårnby': 1530,
    'Tårnby Kommune': 1530,
    'Trørød': 1530,
    'Vallensbæk': 1530,
    'Vallensbæk Kommune': 1530,
    'Vanløse': 1530,
    'Værløse': 1530,
    'Veksø': 1530,
    'Allingåbro': 1531,
    'Århus': 1531,
    'Århus Kommune': 1531,
    'Assentoft': 1531,
    'Auning': 1531,
    'Avlum': 1531,
    'Beder': 1531,
    'Bjerringbro': 1531,
    'Bording Kirkeby': 1531,
    'Brande': 1531,
    'Brædstrup': 1531,
    'Ebeltoft': 1531,
    'Favrskov Kommune': 1531,
    'Framlev': 1531,
    'Galten': 1531,
    'Gjellerup': 1531,
    'Grenaa': 1531,
    'Hadsten': 1531,
    'Hammel': 1531,
    'Hammerum': 1531,
    'Harboøre': 1531,
    'Hedensted': 1531,
    'Hedensted Kommune': 1531,
    'Herning': 1531,
    'Herning Kommune': 1531,
    'Hinnerup': 1531,
    'Hjortshøj': 1531,
    'Holstebro': 1531,
    'Holstebro Kommune': 1531,
    'Hornslet': 1531,
    'Hornsyld': 1531,
    'Horsens': 1531,
    'Horsens Kommune': 1531,
    'Hørning': 1531,
    'Hvide Sande': 1531,
    'Ikast': 1531,
    'Ikast-Brande Kommune': 1531,
    'Juelsminde': 1531,
    'Karup': 1531,
    'Kibæk': 1531,
    'Kjellerup': 1531,
    'Kolt': 1531,
    'Langå': 1531,
    'Lemvig': 1531,
    'Lemvig Kommune': 1531,
    'Lind': 1531,
    'Løgten': 1531,
    'Lystrup': 1531,
    'Malling': 1531,
    'Mårslet': 1531,
    'Norddjurs Kommune': 1531,
    'Odder': 1531,
    'Odder Kommune': 1531,
    'Randers': 1531,
    'Randers Kommune': 1531,
    'Ringkøbing': 1531,
    'Ringkøbing-Skjern Kommune': 1531,
    'Rønde': 1531,
    'Ry': 1531,
    'Ryomgård': 1531,
    'Sabro': 1531,
    'Samsø Kommune': 1531,
    'Silkeborg': 1531,
    'Silkeborg Kommune': 1531,
    'Skanderborg': 1531,
    'Skanderborg Kommune': 1531,
    'Skive': 1531,
    'Skive Kommune': 1531,
    'Skjern': 1531,
    'Skovby': 1531,
    'Snejbjerg': 1531,
    'Solbjerg': 1531,
    'Søften': 1531,
    'Spentrup': 1531,
    'Stavtrup': 1531,
    'Stilling': 1531,
    'Stoholm': 1531,
    'Struer': 1531,
    'Struer Kommune': 1531,
    'Sunds': 1531,
    'Svejbæk': 1531,
    'Syddjurs Kommune': 1531,
    'Tarm': 1531,
    'Thyborøn': 1531,
    'Tørring': 1531,
    'Tranbjerg': 1531,
    'Trige': 1531,
    'Ulfborg': 1531,
    'Ulstrup': 1531,
    'Viborg': 1531,
    'Viborg Kommune': 1531,
    'Videbæk': 1531,
    'Vildbjerg': 1531,
    'Vinderup': 1531,
    'Virklund': 1531,
    'Aalborg': 1532,
    'Aars': 1532,
    'Åbybro': 1532,
    'Ålborg Kommune': 1532,
    'Ålestrup': 1532,
    'Arden': 1532,
    'Brovst': 1532,
    'Brønderslev': 1532,
    'Brønderslev Kommune': 1532,
    'Dronninglund': 1532,
    'Farsø': 1532,
    'Fjerritslev': 1532,
    'Frederikshavn': 1532,
    'Frederikshavn Kommune': 1532,
    'Frejlev': 1532,
    'Gistrup': 1532,
    'Hadsund': 1532,
    'Hals': 1532,
    'Hanstholm': 1532,
    'Hirtshals': 1532,
    'Hjallerup': 1532,
    'Hjørring': 1532,
    'Hjørring Kommune': 1532,
    'Hobro': 1532,
    'Hurup': 1532,
    'Jammerbugt Kommune': 1532,
    'Kås': 1532,
    'Klarup': 1532,
    'Læso Kommune': 1532,
    'Løgstør': 1532,
    'Løkken': 1532,
    'Mariager': 1532,
    'Mariagerfjord Kommune': 1532,
    'Morsø Kommune': 1532,
    'Nibe': 1532,
    'Nørresundby': 1532,
    'Nykøbing Mors': 1532,
    'Pandrup': 1532,
    'Rebild Kommune': 1532,
    'Sæby': 1532,
    'Sindal': 1532,
    'Skagen': 1532,
    'Skørping': 1532,
    'Storvorde': 1532,
    'Støvring': 1532,
    'Strandby': 1532,
    'Svenstrup': 1532,
    'Tårs': 1532,
    'Thisted': 1532,
    'Thisted Kommune': 1532,
    'Vadum': 1532,
    'Vestbjerg': 1532,
    'Vester Hassing': 1532,
    'Vesthimmerland Kommune': 1532,
    'Vodskov': 1532,
    'Vrå': 1532,
    'Aabenraa': 1529,
    'Aabenraa Kommune': 1529,
    'Årslev': 1529,
    'Årup': 1529,
    'Assens': 1529,
    'Assens Kommune': 1529,
    'Augustenborg': 1529,
    'Ærø Kommune': 1529,
    'Ærøskøbing': 1529,
    'Bellinge': 1529,
    'Billund': 1529,
    'Billund Kommune': 1529,
    'Bogense': 1529,
    'Børkop': 1529,
    'Bramming': 1529,
    'Brejning': 1529,
    'Brenderup': 1529,
    'Broager': 1529,
    'Brørup': 1529,
    'Bullerup': 1529,
    'Christiansfeld': 1529,
    'Dybbøl': 1529,
    'Egtved': 1529,
    'Ejby': 1529,
    'Esbjerg': 1529,
    'Esbjerg Kommune': 1529,
    'Faaborg': 1529,
    'Faaborg-Midtfyn Kommune': 1529,
    'Fanø Kommune': 1529,
    'Fredericia': 1529,
    'Fredericia Kommune': 1529,
    'Give': 1529,
    'Glamsbjerg': 1529,
    'Gram': 1529,
    'Gråsten': 1529,
    'Grindsted': 1529,
    'Guderup': 1529,
    'Haderslev': 1529,
    'Haderslev Kommune': 1529,
    'Hårby': 1529,
    'Holsted': 1529,
    'Højby': 1529,
    'Høruphav': 1529,
    'Jelling': 1529,
    'Kerteminde': 1529,
    'Kerteminde Kommune': 1529,
    'Kolding': 1529,
    'Kolding Kommune': 1529,
    'Kruså': 1529,
    'Langeland Kommune': 1529,
    'Langeskov': 1529,
    'Løgumkloster': 1529,
    'Løjt Kirkeby': 1529,
    'Lunderskov': 1529,
    'Marstal': 1529,
    'Middelfart': 1529,
    'Middelfart Kommune': 1529,
    'Munkebo': 1529,
    'Neder Holluf': 1529,
    'Nordborg': 1529,
    'Nordby': 1529,
    'Nordfyns Kommune': 1529,
    'Nørre Åby': 1529,
    'Nyborg': 1529,
    'Nyborg Kommune': 1529,
    'Odense': 1529,
    'Odense Kommune': 1529,
    'Oksbøl': 1529,
    'Otterup': 1529,
    'Ølgod': 1529,
    'Padborg': 1529,
    'Ribe': 1529,
    'Ringe': 1529,
    'Rødding': 1529,
    'Rødekro': 1529,
    'Rudkøbing': 1529,
    'Seden': 1529,
    'Skaerbaek': 1529,
    'Snoghøj': 1529,
    'Sønder Bjert': 1529,
    'Sønderborg': 1529,
    'Sønderborg Kommune': 1529,
    'Søndersø': 1529,
    'Starup': 1529,
    'Stige': 1529,
    'Strib': 1529,
    'Svendborg': 1529,
    'Svendborg Kommune': 1529,
    'Taulov': 1529,
    'Thurø By': 1529,
    'Tinglev': 1529,
    'Tjæreborg': 1529,
    'Toftlund': 1529,
    'Tommerup': 1529,
    'Tommerup Stationsby': 1529,
    'Tønder': 1529,
    'Tønder Kommune': 1529,
    'Ullerslev': 1529,
    'Vamdrup': 1529,
    'Varde': 1529,
    'Varde Kommune': 1529,
    'Vejen': 1529,
    'Vejen Kommune': 1529,
    'Vejle': 1529,
    'Vejle Kommune': 1529,
    'Vester-Skerninge': 1529,
    'Vindeby': 1529,
    'Vissenbjerg': 1529,
    'Vojens': 1529,
    'Asnæs': 1528,
    'Bjæverskov': 1528,
    'Borup': 1528,
    'Dianalund': 1528,
    'Ejby': 1528,
    'Faxe': 1528,
    'Faxe Kommune': 1528,
    'Faxe Ladeplads': 1528,
    'Fensmark': 1528,
    'Forlev': 1528,
    'Frederiksberg': 1528,
    'Fuglebjerg': 1528,
    'Gørlev': 1528,
    'Greve': 1528,
    'Greve Kommune': 1528,
    'Guldborgsund Kommune': 1528,
    'Gundsømagle': 1528,
    'Hårlev': 1528,
    'Haslev': 1528,
    'Havdrup': 1528,
    'Holbæk': 1528,
    'Holbæk Kommune': 1528,
    'Holeby': 1528,
    'Høng': 1528,
    'Hørve': 1528,
    'Jyderup': 1528,
    'Jyllinge': 1528,
    'Kalundborg': 1528,
    'Kalundborg Kommune': 1528,
    'Kirke Hvalsø': 1528,
    'Korsør': 1528,
    'Køge': 1528,
    'Køge Kommune': 1528,
    'Lejre': 1528,
    'Lejre Kommune': 1528,
    'Lolland Kommune': 1528,
    'Maribo': 1528,
    'Nakskov': 1528,
    'Næstved': 1528,
    'Næstved Kommune': 1528,
    'Neder Vindinge': 1528,
    'Nørre Alslev': 1528,
    'Nykøbing Falster': 1528,
    'Nykøbing Sjælland': 1528,
    'Nyråd': 1528,
    'Odsherred Kommune': 1528,
    'Osted': 1528,
    'Ørslev': 1528,
    'Præstø': 1528,
    'Ringsted': 1528,
    'Ringsted Kommune': 1528,
    'Roskilde': 1528,
    'Roskilde Kommune': 1528,
    'Rødby': 1528,
    'Rødbyhavn': 1528,
    'Rødvig': 1528,
    'Rønnede': 1528,
    'Sakskøbing': 1528,
    'Skælskør': 1528,
    'Slagelse': 1528,
    'Slagelse Kommune': 1528,
    'Solrød': 1528,
    'Solrød Kommune': 1528,
    'Solrød Strand': 1528,
    'Sorø': 1528,
    'Sorø Kommune': 1528,
    'Stege': 1528,
    'Stenlille': 1528,
    'Stevns Kommune': 1528,
    'Store Heddinge': 1528,
    'Strøby Egede': 1528,
    'Stubbekøbing': 1528,
    'Sundby': 1528,
    'Svebølle': 1528,
    'Svinninge': 1528,
    'Svogerslev': 1528,
    'Tølløse': 1528,
    'Tune': 1528,
    'Viby': 1528,
    'Vindinge': 1528,
    'Vipperød': 1528,
    'Vordingborg': 1528,
    'Vordingborg Kommune': 1528,
    '  Ali Sabieh': 2933,
    'Goubétto': 2933,
    'Holhol': 2933,
    'Arta': 2932,
    'Dikhil': 2930,
    'Gâlâfi': 2930,
    'Djibouti': 2929,
    'Loyada': 2929,
    'Alaïli Ḏaḏḏa‘': 2928,
    'Obock': 2928,
    'Dorra': 2931,
    'Tadjourah': 2931,
    'Calibishie': 4082,
    'Marigot': 4082,
    'Wesley': 4082,
    'Woodford Hill': 4082,
    'Castle Bruce': 4078,
    'Rosalie': 4078,
    'Roseau': 4079,
    'Portsmouth': 4076,
    'Saint Joseph': 4085,
    'Salisbury': 4085,
    'Pointe Michel': 4083,
    'Soufrière': 4077,
    'Berekua': 4080,
    'La Plaine': 4080,
    'Mahaut': 4084,
    'Pont Cassé': 4084,
    'Colihaut': 4081,
    'Azua': 4114,
    'El Guayabal': 4114,
    'Estebanía': 4114,
    'Las Charcas': 4114,
    'Padre Las Casas': 4114,
    'Palmar de Ocoa': 4114,
    'Peralta': 4114,
    'Pueblo Viejo': 4114,
    'Sabana Yegua': 4114,
    'Tábara Arriba': 4114,
    'Villarpando': 4114,
    'Yayas de Viajama': 4114,
    'El Palmar': 4105,
    'Galván': 4105,
    'La Uvilla': 4105,
    'Los Ríos': 4105,
    'Neiba': 4105,
    'Tamayo': 4105,
    'Villa Jaragua': 4105,
    'Cabral': 4090,
    'Cachón': 4090,
    'Canoa': 4090,
    'El Peñón': 4090,
    'Enriquillo': 4090,
    'Fundación': 4090,
    'Jaquimeyes': 4090,
    'La Ciénaga': 4090,
    'Las Salinas': 4090,
    'Paraíso': 4090,
    'Pescadería': 4090,
    'Polo': 4090,
    'Santa Cruz de Barahona': 4090,
    'Vicente Noble': 4090,
    'Dajabón': 4107,
    'El Pino': 4107,
    'Loma de Cabrera': 4107,
    'Partido': 4107,
    'Restauración': 4107,
    'Bella Vista': 4095,
    'Ciudad Nueva': 4095,
    'Cristo Rey': 4095,
    'Ensanche Luperón': 4095,
    'La Agustina': 4095,
    'La Julia': 4095,
    'San Carlos': 4095,
    'Santo Domingo': 4095,
    'Villa Consuelo': 4095,
    'Villa Francisca': 4095,
    'Agua Santa del Yuna': 4113,
    'Arenoso': 4113,
    'Castillo': 4113,
    'Hostos': 4113,
    'Las Guáranas': 4113,
    'Pimentel': 4113,
    'San Francisco de Macorís': 4113,
    'Villa Riva': 4113,
    'Miches': 4086,
    'Pedro Sánchez': 4086,
    'Santa Cruz de El Seibo': 4086,
    'Cayetano Germosén': 4102,
    'Gaspar Hernández': 4102,
    'Jamao al Norte': 4102,
    'Joba Arriba': 4102,
    'Juan López Abajo': 4102,
    'Moca': 4102,
    'San Víctor Arriba': 4102,
    'Veragua Arriba': 4102,
    'El Valle': 4106,
    'Guayabo Dulce': 4106,
    'Hato Mayor del Rey': 4106,
    'Sabana de la Mar': 4106,
    'Salcedo': 4089,
    'Salsipuedes': 4089,
    'Tenares': 4089,
    'Villa Tapia': 4089,
    'Cristóbal': 4097,
    'Duvergé': 4097,
    'Guayabal': 4097,
    'Jimaní': 4097,
    'La Descubierta': 4097,
    'Mella': 4097,
    'Postrer Río': 4097,
    'Boca de Yuma': 4109,
    'Higüey': 4109,
    'Otra Banda': 4109,
    'Punta Cana': 4109,
    'Salvaleón de Higüey': 4109,
    'San Rafael del Yuma': 4109,
    'Guaymate': 4087,
    'La Romana': 4087,
    'Concepción de La Vega': 4116,
    'Constanza': 4116,
    'Jarabacoa': 4116,
    'Jima Abajo': 4116,
    'Rincón': 4116,
    'Río Verde Arriba': 4116,
    'Tireo Arriba': 4116,
    'Arroyo Salado': 4094,
    'Cabrera': 4094,
    'El Factor': 4094,
    'La Entrada': 4094,
    'Nagua': 4094,
    'Río San Juan': 4094,
    'Bonao': 4099,
    'Juan Adrián': 4099,
    'Maimón': 4099,
    'Piedra Blanca': 4099,
    'Sabana del Puerto': 4099,
    'Cana Chapetón': 4115,
    'Castañuelas': 4115,
    'Guayubín': 4115,
    'Hatillo Palma': 4115,
    'Las Matas de Santa Cruz': 4115,
    'Monte Cristi': 4115,
    'Pepillo Salcedo': 4115,
    'San Fernando de Monte Cristi': 4115,
    'Villa Elisa': 4115,
    'Villa Vásquez': 4115,
    'Bayaguana': 4111,
    'Don Juan': 4111,
    'Esperalvillo': 4111,
    'Gonzalo': 4111,
    'Los Botados': 4111,
    'Majagual': 4111,
    'Monte Plata': 4111,
    'Sabana Grande de Boyá': 4111,
    'Yamasá': 4111,
    'Juancho': 4101,
    'Oviedo': 4101,
    'Pedernales': 4101,
    'Baní': 4096,
    'Matanzas': 4096,
    'Nizao': 4096,
    'Paya': 4096,
    'Pizarrete': 4096,
    'Sabana Buey': 4096,
    'Altamira': 4092,
    'Cabarete': 4092,
    'Estero Hondo': 4092,
    'Guananico': 4092,
    'Imbert': 4092,
    'Los Hidalgos': 4092,
    'Luperón': 4092,
    'Monte Llano': 4092,
    'Puerto Plata': 4092,
    'Río Grande': 4092,
    'Sosúa': 4092,
    'Villa Isabela': 4092,
    'Las Terrenas': 4103,
    'Samaná': 4103,
    'Sánchez': 4103,
    'Bajos de Haina': 4091,
    'Cambita Garabitos': 4091,
    'El Cacao': 4091,
    'El Carril': 4091,
    'Sabana Grande de Palenque': 4091,
    'San Cristóbal': 4091,
    'San Gregorio de Nigua': 4091,
    'Villa Altagracia': 4091,
    'Yaguate': 4091,
    'San José de Ocoa': 4112,
    'Bohechío': 4098,
    'Cercado Abajo': 4098,
    'El Cercado': 4098,
    'Juan de Herrera': 4098,
    'Las Matas de Farfán': 4098,
    'Matayaya': 4098,
    'Pedro Corto': 4098,
    'San Juan de la Maguana': 4098,
    'Vallejuelo': 4098,
    'El Puerto': 4110,
    'Los Llanos': 4110,
    'Quisqueya': 4110,
    'Ramón Santana': 4110,
    'San Pedro de Macorís': 4110,
    'Cevicos': 4088,
    'Cotuí': 4088,
    'Fantino': 4088,
    'Baitoa': 4108,
    'Bisonó': 4108,
    'Juncalito Abajo': 4108,
    'La Canela': 4108,
    'Licey al Medio': 4108,
    'Palmar Arriba': 4108,
    'Pedro García': 4108,
    'Sabana Iglesia': 4108,
    'San José de Las Matas': 4108,
    'Santiago de los Caballeros': 4108,
    'Santo Tomás de Jánico': 4108,
    'Tamboril': 4108,
    'Villa Bisonó': 4108,
    'Villa González': 4108,
    'Monción': 4100,
    'Sabaneta': 4100,
    'San Ignacio de Sabaneta': 4100,
    'Boca Chica': 4093,
    'Santo Domingo Este': 4093,
    'Santo Domingo Oeste': 4093,
    'Amina': 4104,
    'Esperanza': 4104,
    'Guatapanal': 4104,
    'Jaibón': 4104,
    'Jicomé': 4104,
    'La Caya': 4104,
    'Laguna Salada': 4104,
    'Maizal': 4104,
    'Mao': 4104,
    'Aileu': 4520,
    'Lequidoe': 4520,
    'Remexio': 4520,
    'Ainaro': 4518,
    'Hato-Udo': 4518,
    'Baguia': 4521,
    'Baucau': 4521,
    'Baukau': 4521,
    'Laga': 4521,
    'Quelicai': 4521,
    'Vemasse': 4521,
    'Venilale': 4521,
    'Maliana': 4525,
    'Fatumean': 4522,
    'Fohorem': 4522,
    'Maucatar': 4522,
    'Suai': 4522,
    'Tilomar': 4522,
    'Atauro Island': 4524,
    'Cristo Rei': 4524,
    'Dili': 4524,
    'Metinaro': 4524,
    'Ermera Villa': 4516,
    'Gleno': 4516,
    'Hatulia': 4516,
    'Letefoho': 4516,
    'Railaco': 4516,
    'Iliomar': 4523,
    'Lautem': 4523,
    'Lospalos': 4523,
    'Luro': 4523,
    'Tutuala': 4523,
    'Bazartete': 4515,
    'Likisá': 4515,
    'Maubara': 4515,
    'Barique': 4517,
    'Laclo': 4517,
    'Laclubar': 4517,
    'Manatuto': 4517,
    'Manatutu': 4517,
    'Soibada': 4517,
    'Alas': 4519,
    'Fatuberliu': 4519,
    'Same': 4519,
    'Turiscai': 4519,
    'Lacluta': 4514,
    'Ossu': 4514,
    'Uatocarabau': 4514,
    'Uatolari': 4514,
    'Viqueque': 4514,
    'Cantón San Fernando': 2923,
    'Cuenca': 2923,
    'Gualaceo': 2923,
    'La Unión': 2923,
    'Llacao': 2923,
    'Nulti': 2923,
    'Guaranda': 2920,
    'San Miguel': 2920,
    'Azogues': 2917,
    'Cañar': 2917,
    'La Troncal': 2917,
    'El Ángel': 2915,
    'San Gabriel': 2915,
    'Tulcán': 2915,
    'Alausí': 2925,
    'Guano': 2925,
    'Riobamba': 2925,
    'La Maná': 2921,
    'Latacunga': 2921,
    'Pujilí': 2921,
    'San Miguel de Salcedo': 2921,
    'Saquisilí': 2921,
    'Huaquillas': 2924,
    'Machala': 2924,
    'Pasaje': 2924,
    'Piñas': 2924,
    'Portovelo': 2924,
    'Puerto Bolívar': 2924,
    'Santa Rosa': 2924,
    'Zaruma': 2924,
    'Esmeraldas': 2922,
    'Muisne': 2922,
    'Pampanal de Bolívar': 2922,
    'Rio Verde': 2922,
    'Rosa Zarate': 2922,
    'San Lorenzo de Esmeraldas': 2922,
    'Valdez': 2922,
    'Puerto Ayora': 2905,
    'Puerto Baquerizo Moreno': 2905,
    'Puerto Villamil': 2905,
    'Alfredo Baquerizo Moreno': 2914,
    'Baláo': 2914,
    'Balzar': 2914,
    'Colimes': 2914,
    'Coronel Marcelino Maridueña': 2914,
    'El Triunfo': 2914,
    'Eloy Alfaro': 2914,
    'Guayaquil': 2914,
    'La Libertad': 2914,
    'Lomas de Sargentillo': 2914,
    'Milagro': 2914,
    'Naranjal': 2914,
    'Naranjito': 2914,
    'Palestina': 2914,
    'Pedro Carbo': 2914,
    'Playas': 2914,
    'Samborondón': 2914,
    'Santa Lucía': 2914,
    'Velasco Ibarra': 2914,
    'Yaguachi Nuevo': 2914,
    'Atuntaqui': 2911,
    'Cotacachi': 2911,
    'Ibarra': 2911,
    'Otavalo': 2911,
    'Pimampiro': 2911,
    'Babahoyo': 2910,
    'Catarama': 2910,
    'Montalvo': 2910,
    'Palenque': 2910,
    'Quevedo': 2910,
    'Ventanas': 2910,
    'Vinces': 2910,
    'Bahía de Caráquez': 2913,
    'Calceta': 2913,
    'Cantón Portoviejo': 2913,
    'Chone': 2913,
    'Jipijapa': 2913,
    'Junín': 2913,
    'Manta': 2913,
    'Montecristi': 2913,
    'Paján': 2913,
    'Pedernales': 2913,
    'Portoviejo': 2913,
    'Rocafuerte': 2913,
    'San Vicente': 2913,
    'Santa Ana': 2913,
    'Sucre': 2913,
    'Tosagua': 2913,
    'Gualaquiza': 2918,
    'Macas': 2918,
    'Palora': 2918,
    'Sucúa': 2918,
    'Archidona': 2916,
    'Tena': 2916,
    'Boca Suno': 2926,
    'Francisco de Orellana Canton': 2926,
    'Loreto Canton': 2926,
    'Puerto Francisco de Orellana': 2926,
    'Puyo': 2907,
    'Cayambe': 2927,
    'Machachi': 2927,
    'Quito': 2927,
    'Sangolquí': 2927,
    'Tutamandahostel': 2927,
    'Salinas': 2912,
    'Santa Elena': 2912,
    'Santo Domingo de los Colorados': 2919,
    'Nueva Loja': 2906,
    'Ambato': 2908,
    'Baños': 2908,
    'Pelileo': 2908,
    'Píllaro': 2908,
    'Yantzaza': 2909,
    'Zamora': 2909,
    'Alexandria': 3235,
    'Abu Simbel': 3225,
    'Aswan': 3225,
    'Idfū': 3225,
    'Kawm Umbū': 3225,
    'Abnūb': 3236,
    'Abū Tīj': 3236,
    'Al Badārī': 3236,
    'Al Qūşīyah': 3236,
    'Asyūţ': 3236,
    'Dayrūţ': 3236,
    'Manfalūţ': 3236,
    'Abū al Maţāmīr': 3241,
    'Ad Dilinjāt': 3241,
    'Damanhūr': 3241,
    'Ḩawsh ‘Īsá': 3241,
    'Idkū': 3241,
    'Kafr ad Dawwār': 3241,
    'Kawm Ḩamādah': 3241,
    'Rosetta': 3241,
    'Al Fashn': 3230,
    'Banī Suwayf': 3230,
    'Būsh': 3230,
    'Sumusţā as Sulţānī': 3230,
    'Cairo': 3223,
    'Ḩalwān': 3223,
    'New Cairo': 3223,
    '‘Izbat al Burj': 3245,
    'Ajā': 3245,
    'Al Jammālīyah': 3245,
    'Al Manşūrah': 3245,
    'Al Manzalah': 3245,
    'Al Maţarīyah': 3245,
    'Bilqās': 3245,
    'Dikirnis': 3245,
    'Minyat an Naşr': 3245,
    'Shirbīn': 3245,
    'Ţalkhā': 3245,
    'Az Zarqā': 3224,
    'Damietta': 3224,
    'Fāraskūr': 3224,
    'Al Fayyūm': 3238,
    'Al Wāsiţah': 3238,
    'Ibshawāy': 3238,
    'Iţsā': 3238,
    'Ţāmiyah': 3238,
    'Al Maḩallah al Kubrá': 3234,
    'Basyūn': 3234,
    'Kafr az Zayyāt': 3234,
    'Quţūr': 3234,
    'Samannūd': 3234,
    'Tanda': 3234,
    'Zefta': 3234,
    'Al ‘Ayyāţ': 3239,
    'Al Bawīţī': 3239,
    'Al Ḩawāmidīyah': 3239,
    'Aş Şaff': 3239,
    'Awsīm': 3239,
    'Giza': 3239,
    'Madīnat Sittah Uktūbar': 3239,
    'Ismailia': 3244,
    'Al Ḩāmūl': 3222,
    'Disūq': 3222,
    'Fuwwah': 3222,
    'Kafr ash Shaykh': 3222,
    'Markaz Disūq': 3222,
    'Munshāt ‘Alī Āghā': 3222,
    'Sīdī Sālim': 3222,
    'Luxor': 3242,
    'Markaz al Uqşur': 3242,
    'Al ‘Alamayn': 3231,
    'Mersa Matruh': 3231,
    'Siwa Oasis': 3231,
    'Abū Qurqāş': 3243,
    'Al Minyā': 3243,
    'Banī Mazār': 3243,
    'Dayr Mawās': 3243,
    'Mallawī': 3243,
    'Maţāy': 3243,
    'Samālūţ': 3243,
    'Al Bājūr': 3228,
    'Ash Shuhadā’': 3228,
    'Ashmūn': 3228,
    'Munūf': 3228,
    'Quwaysinā': 3228,
    'Shibīn al Kawm': 3228,
    'Talā': 3228,
    'Al Khārijah': 3246,
    'Qaşr al Farāfirah': 3246,
    'Arish': 3227,
    'Port Said': 3229,
    'Al Khānkah': 3232,
    'Al Qanāţir al Khayrīyah': 3232,
    'Banhā': 3232,
    'Qalyūb': 3232,
    'Shibīn al Qanāṭir': 3232,
    'Toukh': 3232,
    'Dishnā': 3247,
    'Farshūţ': 3247,
    'Isnā': 3247,
    'Kousa': 3247,
    'Naja   Ḥammādī': 3247,
    'Qinā': 3247,
    'Al Quşayr': 3240,
    'El Gouna': 3240,
    'Hurghada': 3240,
    'Makadi Bay': 3240,
    'Marsa Alam': 3240,
    'Ras Gharib': 3240,
    'Safaga': 3240,
    'Akhmīm': 3226,
    'Al Balyanā': 3226,
    'Al Manshāh': 3226,
    'Jirjā': 3226,
    'Juhaynah': 3226,
    'Markaz Jirjā': 3226,
    'Markaz Sūhāj': 3226,
    'Sohag': 3226,
    'Ţahţā': 3226,
    'Dahab': 3237,
    'El-Tor': 3237,
    'Nuwaybi‘a': 3237,
    'Saint Catherine': 3237,
    'Sharm el-Sheikh': 3237,
    'Ain Sukhna': 3233,
    'Suez': 3233,
    'Ahuachapán': 4139,
    'Atiquizaya': 4139,
    'Concepción de Ataco': 4139,
    'Guaymango': 4139,
    'Jujutla': 4139,
    'San Francisco Menéndez': 4139,
    'Tacuba': 4139,
    'Sensuntepeque': 4132,
    'Victoria': 4132,
    'Chalatenango': 4131,
    'Nueva Concepción': 4131,
    'Cojutepeque': 4137,
    'San Martín': 4137,
    'Suchitoto': 4137,
    'Tecoluca': 4137,
    'Tenancingo': 4137,
    'Antiguo Cuscatlán': 4134,
    'Ciudad Arce': 4134,
    'La Libertad': 4134,
    'Nuevo Cuscatlán': 4134,
    'Quezaltepeque': 4134,
    'San Juan Opico': 4134,
    'San Pablo Tacachico': 4134,
    'Santa Tecla': 4134,
    'Zaragoza': 4134,
    'El Rosario': 4136,
    'Olocuilta': 4136,
    'San Pedro Masahuat': 4136,
    'Santiago Nonualco': 4136,
    'Zacatecoluca': 4136,
    'Anamorós': 4138,
    'Conchagua': 4138,
    'Intipucá': 4138,
    'La Unión': 4138,
    'Nueva Esparta': 4138,
    'Pasaquina': 4138,
    'San Alejo': 4138,
    'Santa Rosa de Lima': 4138,
    'Cacaopera': 4130,
    'Corinto': 4130,
    'Guatajiagua': 4130,
    'Jocoro': 4130,
    'San Francisco': 4130,
    'Sociedad': 4130,
    'Chapeltique': 4135,
    'Chinameca': 4135,
    'Chirilagua': 4135,
    'Ciudad Barrios': 4135,
    'El Tránsito': 4135,
    'Lolotique': 4135,
    'Moncagua': 4135,
    'Nueva Guadalupe': 4135,
    'San Miguel': 4135,
    'San Rafael Oriente': 4135,
    'Sesori': 4135,
    'Aguilares': 4133,
    'Apopa': 4133,
    'Ayutuxtepeque': 4133,
    'Cuscatancingo': 4133,
    'Delgado': 4133,
    'El Paisnal': 4133,
    'Guazapa': 4133,
    'Ilopango': 4133,
    'Mejicanos': 4133,
    'Panchimalco': 4133,
    'Rosario de Mora': 4133,
    'San Marcos': 4133,
    'San Salvador': 4133,
    'Santo Tomás': 4133,
    'Soyapango': 4133,
    'Tonacatepeque': 4133,
    'Apastepeque': 4127,
    'San Sebastián': 4127,
    'San Vicente': 4127,
    'Candelaria de La Frontera': 4128,
    'Chalchuapa': 4128,
    'Coatepeque': 4128,
    'El Congo': 4128,
    'Metapán': 4128,
    'Santa Ana': 4128,
    'Texistepeque': 4128,
    'Acajutla': 4140,
    'Armenia': 4140,
    'Izalco': 4140,
    'Juayúa': 4140,
    'Nahuizalco': 4140,
    'San Antonio del Monte': 4140,
    'Sonsonate': 4140,
    'Sonzacate': 4140,
    'Berlín': 4129,
    'Concepción Batres': 4129,
    'Jiquilisco': 4129,
    'Jucuapa': 4129,
    'Jucuarán': 4129,
    'Ozatlán': 4129,
    'Puerto El Triunfo': 4129,
    'San Agustín': 4129,
    'Santa Elena': 4129,
    'Santiago de María': 4129,
    'Usulután': 4129,
    'San Antonio de Palé': 3444,
    'Malabo': 3446,
    'Rebola': 3446,
    'Santiago de Baney': 3446,
    'Luba': 3443,
    'Acurenam': 3445,
    'Bicurga': 3445,
    'Evinayong': 3445,
    'Ebebiyin': 3439,
    'Mikomeseng': 3439,
    'Ncue': 3439,
    'Nsang': 3439,
    'Bata': 3441,
    'Bitica': 3441,
    'Cogo': 3441,
    'Machinda': 3441,
    'Mbini': 3441,
    'Río Campo': 3441,
    'Aconibe': 3440,
    'Añisoc': 3440,
    'Ayene': 3440,
    'Mengomeyén': 3440,
    'Mongomo': 3440,
    'Nsok': 3440,
    'Keren': 3425,
    'Adi Keyh': 3427,
    'Dek’emhāre': 3427,
    'Mendefera': 3427,
    'Ak’ordat': 3428,
    'Barentu': 3428,
    'Teseney': 3428,
    'Asmara': 3426,
    'Massawa': 3424,
    'Assab': 3429,
    'Edd': 3429,
    'Anija vald': 3567,
    'Aruküla': 3567,
    'Haabneeme': 3567,
    'Harku': 3567,
    'Harku vald': 3567,
    'Jõelähtme vald': 3567,
    'Jüri': 3567,
    'Kehra': 3567,
    'Keila': 3567,
    'Kiili': 3567,
    'Kiili vald': 3567,
    'Kose': 3567,
    'Kuusalu': 3567,
    'Laagri': 3567,
    'Loksa': 3567,
    'Loksa linn': 3567,
    'Loo': 3567,
    'Maardu': 3567,
    'Maardu linn': 3567,
    'Paldiski': 3567,
    'Pringi': 3567,
    'Raasiku': 3567,
    'Rae vald': 3567,
    'Riisipere': 3567,
    'Rummu': 3567,
    'Saku': 3567,
    'Saku vald': 3567,
    'Saue': 3567,
    'Saue vald': 3567,
    'Tabasalu': 3567,
    'Tallinn': 3567,
    'Turba': 3567,
    'Vaida': 3567,
    'Viimsi': 3567,
    'Viimsi vald': 3567,
    'Kärdla': 3555,
    'Iisaku': 3569,
    'Jõhvi': 3569,
    'Jõhvi vald': 3569,
    'Kiviõli': 3569,
    'Kohtla-Järve': 3569,
    'Kohtla-Nõmme': 3569,
    'Lüganuse vald': 3569,
    'Narva': 3569,
    'Narva-Jõesuu': 3569,
    'Narva-Jõesuu linn': 3569,
    'Püssi': 3569,
    'Sillamäe': 3569,
    'Toila': 3569,
    'Voka': 3569,
    'Järva-Jaani': 3566,
    'Koeru': 3566,
    'Paide': 3566,
    'Paide linn': 3566,
    'Särevere': 3566,
    'Türi': 3566,
    'Türi vald': 3566,
    'Jõgeva': 3565,
    'Jõgeva vald': 3565,
    'Mustvee': 3565,
    'Põltsamaa': 3565,
    'Põltsamaa vald': 3565,
    'Haapsalu': 3568,
    'Haapsalu linn': 3568,
    'Hullo': 3568,
    'Lääne-Nigula vald': 3568,
    'Taebla': 3568,
    'Uuemõisa': 3568,
    'Vormsi vald': 3568,
    'Aseri': 3564,
    'Haljala': 3564,
    'Haljala vald': 3564,
    'Kadrina': 3564,
    'Kadrina vald': 3564,
    'Kunda': 3564,
    'Pajusti': 3564,
    'Rakke': 3564,
    'Rakvere': 3564,
    'Rakvere vald': 3564,
    'Sõmeru': 3564,
    'Tamsalu': 3564,
    'Tapa': 3564,
    'Tapa vald': 3564,
    'Vaiatu': 3564,
    'Väike-Maarja': 3564,
    'Väike-Maarja vald': 3564,
    'Vinni': 3564,
    'Vinni vald': 3564,
    'Viru-Nigula vald': 3564,
    'Audru': 3562,
    'Kihnu vald': 3562,
    'Kilingi-Nõmme': 3562,
    'Lihula': 3562,
    'Linaküla': 3562,
    'Paikuse': 3562,
    'Pärnu': 3562,
    'Pärnu linn': 3562,
    'Pärnu-Jaagupi': 3562,
    'Saarde vald': 3562,
    'Sauga': 3562,
    'Sindi': 3562,
    'Tootsi': 3562,
    'Tori vald': 3562,
    'Uulu': 3562,
    'Vändra': 3562,
    'Kanepi': 3563,
    'Kanepi vald': 3563,
    'Põlva': 3563,
    'Põlva vald': 3563,
    'Räpina': 3563,
    'Räpina vald': 3563,
    'Järvakandi': 3559,
    'Kehtna': 3559,
    'Kehtna vald': 3559,
    'Kohila': 3559,
    'Kohila vald': 3559,
    'Märjamaa': 3559,
    'Märjamaa vald': 3559,
    'Rapla': 3559,
    'Rapla vald': 3559,
    'Kuressaare': 3561,
    'Liiva': 3561,
    'Muhu vald': 3561,
    'Orissaare': 3561,
    'Ruhnu': 3561,
    'Ruhnu vald': 3561,
    'Tehumardi': 3561,
    'Alatskivi': 3557,
    'Elva': 3557,
    'Kallaste': 3557,
    'Kambja vald': 3557,
    'Kõrveküla': 3557,
    'Kurepalu': 3557,
    'Luunja': 3557,
    'Luunja vald': 3557,
    'Nõo': 3557,
    'Nõo vald': 3557,
    'Peipsiääre vald': 3557,
    'Puhja': 3557,
    'Tartu': 3557,
    'Tartu linn': 3557,
    'Tartu vald': 3557,
    'Ülenurme': 3557,
    'Otepää vald': 3558,
    'Tõrva': 3558,
    'Valga': 3558,
    'Abja-Paluoja': 3556,
    'Karksi-Nuia': 3556,
    'Mõisaküla': 3556,
    'Suure-Jaani': 3556,
    'Viiratsi': 3556,
    'Viljandi': 3556,
    'Viljandi vald': 3556,
    'Võhma': 3556,
    'Antsla': 3560,
    'Antsla vald': 3560,
    'Rõuge': 3560,
    'Rõuge vald': 3560,
    'Vana-Antsla': 3560,
    'Värska': 3560,
    'Võru': 3560,
    'Võru vald': 3560,
    'Addis Ababa': 11,
    'Administrative Zone 2': 6,
    'Administrative Zone 3': 6,
    'Asaita': 6,
    'Āwash': 6,
    'Dubti': 6,
    'Gewanē': 6,
    'Semera': 6,
    'Abomsa': 3,
    'Addiet Canna': 3,
    'Ādīs Zemen': 3,
    'Bahir Dar': 3,
    'Batī': 3,
    'Bichena': 3,
    'Burē': 3,
    'Dabat': 3,
    'Debark’': 3,
    'Debre Birhan': 3,
    'Debre Mark’os': 3,
    'Debre Sīna': 3,
    'Debre Tabor': 3,
    'Debre Werk’': 3,
    'Dejen': 3,
    'Desē': 3,
    'Finote Selam': 3,
    'Gondar': 3,
    'Kemisē': 3,
    'Kombolcha': 3,
    'Lalībela': 3,
    'North Shewa Zone': 3,
    'North Wollo Zone': 3,
    'Robīt': 3,
    'South Gondar Zone': 3,
    'South Wollo Zone': 3,
    'Wag Hemra Zone': 3,
    'Were Īlu': 3,
    'Werota': 3,
    'Asosa': 9,
    'Metekel': 9,
    'Dire Dawa': 8,
    'Administrative Zone 1': 10,
    'Gambēla': 10,
    'Harar': 7,
    'Ādīs ‘Alem': 5,
    'Āgaro': 5,
    'Arsi Zone': 5,
    'Āsasa': 5,
    'Āsbe Teferī': 5,
    'Bedelē': 5,
    'Bedēsa': 5,
    'Bishoftu': 5,
    'Deder': 5,
    'Dembī Dolo': 5,
    'Dodola': 5,
    'East Harerghe Zone': 5,
    'East Shewa Zone': 5,
    'East Wellega Zone': 5,
    'Fichē': 5,
    'Gebre Guracha': 5,
    'Gēdo': 5,
    'Gelemso': 5,
    'Genet': 5,
    'Gimbi': 5,
    'Ginir': 5,
    'Goba': 5,
    'Gorē': 5,
    'Guji Zone': 5,
    'Hāgere Hiywet': 5,
    'Hagere Maryam': 5,
    'Hīrna': 5,
    'Huruta': 5,
    'Illubabor Zone': 5,
    'Jimma': 5,
    'Jimma Zone': 5,
    'Kibre Mengist': 5,
    'Kofelē': 5,
    'Mēga': 5,
    'Mendī': 5,
    'Metahāra': 5,
    'Metu': 5,
    'Mojo': 5,
    'Nazrēt': 5,
    'Nejo': 5,
    'North Shewa Zone': 5,
    'Sebeta': 5,
    'Sendafa': 5,
    'Shakiso': 5,
    'Shambu': 5,
    'Shashemenē': 5,
    'Sirre': 5,
    'Tulu Bolo': 5,
    'Waliso': 5,
    'Wenjī': 5,
    'West Harerghe Zone': 5,
    'West Wellega Zone': 5,
    'Yabēlo': 5,
    'Ziway': 5,
    'Afder Zone': 2,
    'Degehabur Zone': 2,
    'Gode Zone': 2,
    'Jijiga': 2,
    'Liben zone': 2,
    'Shinile Zone': 2,
    'Alaba Special Wereda': 1,
    'Arba Minch': 1,
    'Āreka': 1,
    'Bako': 1,
    'Bench Maji Zone': 1,
    'Bodītī': 1,
    'Bonga': 1,
    'Butajīra': 1,
    'Dīla': 1,
    'Felege Neway': 1,
    'Gedeo Zone': 1,
    'Gīdolē': 1,
    'Guraghe Zone': 1,
    'Hadiya Zone': 1,
    'Hāgere Selam': 1,
    'Hawassa': 1,
    'Hosa’ina': 1,
    'Jinka': 1,
    'K’olīto': 1,
    'Kembata Alaba Tembaro Zone': 1,
    'Konso': 1,
    'Leku': 1,
    'Lobuni': 1,
    'Mīzan Teferī': 1,
    'Sheka Zone': 1,
    'Sidama Zone': 1,
    'Sodo': 1,
    'Tippi': 1,
    'Turmi': 1,
    'Wendo': 1,
    'Wolayita Zone': 1,
    'Yem': 1,
    'Yirga ‘Alem': 1,
    'Ādīgrat': 4,
    'Axum': 4,
    'Inda Silasē': 4,
    'Korem': 4,
    'Maych’ew': 4,
    'Mek  ele': 4,
    'Southeastern Tigray Zone': 4,
    'Southern Tigray Zone': 4,
    'Naitasiri Province': 1929,
    'Namosi Province': 1929,
    'Rewa Province': 1929,
    'Serua Province': 1929,
    'Suva': 1929,
    'Tailevu Province': 1929,
    'Kadavu Province': 1932,
    'Lau Province': 1932,
    'Levuka': 1932,
    'Lomaiviti Province': 1932,
    'Bua Province': 1921,
    'Cakaudrove Province': 1921,
    'Labasa': 1921,
    'Macuata Province': 1921,
    'Ba': 1923,
    'Ba Province': 1923,
    'Lautoka': 1923,
    'Nadi': 1923,
    'Nandronga and Navosa Province': 1923,
    'Ra Province': 1923,
    'Äänekoski': 1511,
    'Hankasalmi': 1511,
    'Jämsä': 1511,
    'Jämsänkoski': 1511,
    'Joutsa': 1511,
    'Jyväskylä': 1511,
    'Kannonkoski': 1511,
    'Karstula': 1511,
    'Keuruu': 1511,
    'Kinnula': 1511,
    'Kivijärvi': 1511,
    'Konnevesi': 1511,
    'Korpilahti': 1511,
    'Kuhmoinen': 1511,
    'Kyyjärvi': 1511,
    'Laukaa': 1511,
    'Leivonmäki': 1511,
    'Luhanka': 1511,
    'Multia': 1511,
    'Muurame': 1511,
    'Petäjävesi': 1511,
    'Pihtipudas': 1511,
    'Pylkönmäki': 1511,
    'Saarijärvi': 1511,
    'Säynätsalo': 1511,
    'Sumiainen': 1511,
    'Suolahti': 1511,
    'Toivakka': 1511,
    'Uurainen': 1511,
    'Viitasaari': 1511,
    'Halsua': 1494,
    'Kälviä': 1494,
    'Kannus': 1494,
    'Kaustinen': 1494,
    'Kokkola': 1494,
    'Lestijärvi': 1494,
    'Lohtaja': 1494,
    'Perho': 1494,
    'Toholampi': 1494,
    'Ullava': 1494,
    'Veteli': 1494,
    'Alastaro': 1507,
    'Askainen': 1507,
    'Aura': 1507,
    'Dragsfjärd': 1507,
    'Halikko': 1507,
    'Houtskär': 1507,
    'Iniö': 1507,
    'Kaarina': 1507,
    'Karinainen': 1507,
    'Kiikala': 1507,
    'Kimito': 1507,
    'Kisko': 1507,
    'Kustavi': 1507,
    'Kuusjoki': 1507,
    'Laitila': 1507,
    'Lemu': 1507,
    'Lieto': 1507,
    'Marttila': 1507,
    'Masku': 1507,
    'Mellilä': 1507,
    'Merimasku': 1507,
    'Mietoinen': 1507,
    'Muurla': 1507,
    'Mynämäki': 1507,
    'Naantali': 1507,
    'Nagu': 1507,
    'Nousiainen': 1507,
    'Oripää': 1507,
    'Paimio': 1507,
    'Pargas': 1507,
    'Perniö': 1507,
    'Pertteli': 1507,
    'Piikkiö': 1507,
    'Pöytyä': 1507,
    'Pyhäranta': 1507,
    'Raisio': 1507,
    'Rusko': 1507,
    'Rymättylä': 1507,
    'Salo': 1507,
    'Särkisalo': 1507,
    'Sauvo': 1507,
    'Somero': 1507,
    'Suomusjärvi': 1507,
    'Taivassalo': 1507,
    'Tarvasjoki': 1507,
    'Turku': 1507,
    'Uusikaupunki': 1507,
    'Vahto': 1507,
    'Väståboland': 1507,
    'Västanfjärd': 1507,
    'Vehmaa': 1507,
    'Velkua': 1507,
    'Yläne': 1507,
    'Hyrynsalmi': 1496,
    'Kajaani': 1496,
    'Kuhmo': 1496,
    'Paltamo': 1496,
    'Puolanka': 1496,
    'Ristijärvi': 1496,
    'Sotkamo': 1496,
    'Suomussalmi': 1496,
    'Vaala': 1496,
    'Vuokatti': 1496,
    'Vuolijoki': 1496,
    'Anjala': 1512,
    'Elimäki': 1512,
    'Hamina': 1512,
    'Iitti': 1512,
    'Jaala': 1512,
    'Karhula': 1512,
    'Kotka': 1512,
    'Kouvola': 1512,
    'Miehikkälä': 1512,
    'Pyhtää': 1512,
    'Virojoki': 1512,
    'Enontekiö': 1500,
    'Inari': 1500,
    'Ivalo': 1500,
    'Kemi': 1500,
    'Kemijärvi': 1500,
    'Keminmaa': 1500,
    'Kittilä': 1500,
    'Kolari': 1500,
    'Muonio': 1500,
    'Pelkosenniemi': 1500,
    'Pello': 1500,
    'Posio': 1500,
    'Pyhäjärvi': 1500,
    'Ranua': 1500,
    'Rovaniemi': 1500,
    'Salla': 1500,
    'Savukoski': 1500,
    'Simo': 1500,
    'Sodankylä': 1500,
    'Tervola': 1500,
    'Tornio': 1500,
    'Utsjoki': 1500,
    'Ylitornio': 1500,
    'Eno': 1504,
    'Ilomantsi': 1504,
    'Joensuu': 1504,
    'Juuka': 1504,
    'Kesälahti': 1504,
    'Kiihtelysvaara': 1504,
    'Kitee': 1504,
    'Kontiolahti': 1504,
    'Lieksa': 1504,
    'Liperi': 1504,
    'Nurmes': 1504,
    'Outokumpu': 1504,
    'Polvijärvi': 1504,
    'Pyhäselkä': 1504,
    'Rääkkylä': 1504,
    'Tohmajärvi': 1504,
    'Tuupovaara': 1504,
    'Valtimo': 1504,
    'Alavieska': 1505,
    'Haapajärvi': 1505,
    'Haapavesi': 1505,
    'Hailuoto': 1505,
    'Haukipudas': 1505,
    'Himanka': 1505,
    'Ii': 1505,
    'Kalajoki': 1505,
    'Kärsämäki': 1505,
    'Kempele': 1505,
    'Kestilä': 1505,
    'Kiiminki': 1505,
    'Kuivaniemi': 1505,
    'Kuusamo': 1505,
    'Liminka': 1505,
    'Lumijoki': 1505,
    'Merijärvi': 1505,
    'Muhos': 1505,
    'Nivala': 1505,
    'Oulainen': 1505,
    'Oulu': 1505,
    'Oulunsalo': 1505,
    'Piippola': 1505,
    'Pudasjärvi': 1505,
    'Pulkkila': 1505,
    'Pyhäjärvi': 1505,
    'Pyhäjoki': 1505,
    'Pyhäntä': 1505,
    'Raahe': 1505,
    'Rantsila': 1505,
    'Reisjärvi': 1505,
    'Ruukki': 1505,
    'Sievi': 1505,
    'Siikajoki': 1505,
    'Taivalkoski': 1505,
    'Tyrnävä': 1505,
    'Utajärvi': 1505,
    'Vihanti': 1505,
    'Yli-Ii': 1505,
    'Ylikiiminki': 1505,
    'Ylivieska': 1505,
    'Hietalahti': 1508,
    'Isokyrö': 1508,
    'Jakobstad': 1508,
    'Kaskinen': 1508,
    'Korsholm': 1508,
    'Korsnäs': 1508,
    'Kristinestad': 1508,
    'Kronoby': 1508,
    'Laihia': 1508,
    'Larsmo': 1508,
    'Malax': 1508,
    'Nykarleby': 1508,
    'Oravais': 1508,
    'Pedersöre': 1508,
    'Replot': 1508,
    'Ristinummi': 1508,
    'Teeriniemi': 1508,
    'Vaasa': 1508,
    'Vähäkyrö': 1508,
    'Vörå': 1508,
    'Artjärvi': 1502,
    'Asikkala': 1502,
    'Auttoinen': 1502,
    'Hämeenkoski': 1502,
    'Hartola': 1502,
    'Heinola': 1502,
    'Hollola': 1502,
    'Lahti': 1502,
    'Nastola': 1502,
    'Orimattila': 1502,
    'Padasjoki': 1502,
    'Sysmä': 1502,
    'Iisalmi': 1506,
    'Juankoski': 1506,
    'Kaavi': 1506,
    'Kangaslampi': 1506,
    'Karttula': 1506,
    'Keitele': 1506,
    'Kiuruvesi': 1506,
    'Kuopio': 1506,
    'Lapinlahti': 1506,
    'Leppävirta': 1506,
    'Maaninka': 1506,
    'Nilsiä': 1506,
    'Pielavesi': 1506,
    'Rautalampi': 1506,
    'Rautavaara': 1506,
    'Siilinjärvi': 1506,
    'Sonkajärvi': 1506,
    'Suonenjoki': 1506,
    'Tervo': 1506,
    'Tuusniemi': 1506,
    'Varkaus': 1506,
    'Varpaisjärvi': 1506,
    'Vehmersalmi': 1506,
    'Vesanto': 1506,
    'Vieremä': 1506,
    'Eura': 1501,
    'Eurajoki': 1501,
    'Harjavalta': 1501,
    'Huittinen': 1501,
    'Jämijärvi': 1501,
    'Kankaanpää': 1501,
    'Karvia': 1501,
    'Kiukainen': 1501,
    'Kokemäki': 1501,
    'Köyliö': 1501,
    'Kullaa': 1501,
    'Längelmäki': 1501,
    'Lappi': 1501,
    'Lavia': 1501,
    'Luvia': 1501,
    'Merikarvia': 1501,
    'Nakkila': 1501,
    'Noormarkku': 1501,
    'Pomarkku': 1501,
    'Pori': 1501,
    'Rauma': 1501,
    'Säkylä': 1501,
    'Siikainen': 1501,
    'Ulvila': 1501,
    'Vampula': 1501,
    'Imatra': 1497,
    'Joutseno': 1497,
    'Lappeenranta': 1497,
    'Lemi': 1497,
    'Luumäki': 1497,
    'Nuijamaa': 1497,
    'Parikkala': 1497,
    'Rautjärvi': 1497,
    'Ruokolahti': 1497,
    'Saari': 1497,
    'Savitaipale': 1497,
    'Taipalsaari': 1497,
    'Ylämaa': 1497,
    'Ähtäri': 1498,
    'Alahärmä': 1498,
    'Alajärvi': 1498,
    'Alavus': 1498,
    'Evijärvi': 1498,
    'Ilmajoki': 1498,
    'Isojoki': 1498,
    'Jalasjärvi': 1498,
    'Jurva': 1498,
    'Karijoki': 1498,
    'Kauhajoki': 1498,
    'Kauhava': 1498,
    'Kortesjärvi': 1498,
    'Kuortane': 1498,
    'Kurikka': 1498,
    'Lappajärvi': 1498,
    'Lapua': 1498,
    'Lehtimäki': 1498,
    'Nurmo': 1498,
    'Seinäjoki': 1498,
    'Soini': 1498,
    'Teuva': 1498,
    'Töysä': 1498,
    'Vimpeli': 1498,
    'Ylihärmä': 1498,
    'Ylistaro': 1498,
    'Enonkoski': 1495,
    'Haukivuori': 1495,
    'Heinävesi': 1495,
    'Hirvensalmi': 1495,
    'Jäppilä': 1495,
    'Joroinen': 1495,
    'Juva': 1495,
    'Kangasniemi': 1495,
    'Kerimäki': 1495,
    'Mäntyharju': 1495,
    'Mikkeli': 1495,
    'Pertunmaa': 1495,
    'Pieksämäki': 1495,
    'Punkaharju': 1495,
    'Puumala': 1495,
    'Rantasalmi': 1495,
    'Ristiina': 1495,
    'Savonlinna': 1495,
    'Savonranta': 1495,
    'Sulkava': 1495,
    'Virtasalmi': 1495,
    'Forssa': 1493,
    'Hämeenlinna': 1493,
    'Hauho': 1493,
    'Hausjärvi': 1493,
    'Humppila': 1493,
    'Janakkala': 1493,
    'Jokioinen': 1493,
    'Kalvola': 1493,
    'Lammi': 1493,
    'Loppi': 1493,
    'Renko': 1493,
    'Riihimäki': 1493,
    'Tammela': 1493,
    'Tervakoski': 1493,
    'Tuulos': 1493,
    'Ypäjä': 1493,
    'Askola': 1510,
    'Ekenäs': 1510,
    'Espoo': 1510,
    'Gumböle': 1510,
    'Hanko': 1510,
    'Helsinki': 1510,
    'Hyvinge': 1510,
    'Ingå': 1510,
    'Järvenpää': 1510,
    'Kaarela': 1510,
    'Kallio': 1510,
    'Karis': 1510,
    'Karjalohja': 1510,
    'Karkkila': 1510,
    'Kärkölä': 1510,
    'Kauniainen': 1510,
    'Kellokoski': 1510,
    'Kerava': 1510,
    'Kilo': 1510,
    'Kirkkonummi': 1510,
    'Koukkuniemi': 1510,
    'Lapinjärvi': 1510,
    'Lauttasaari': 1510,
    'Liljendal': 1510,
    'Lohja': 1510,
    'Lovisa': 1510,
    'Mäntsälä': 1510,
    'Mellunkylä': 1510,
    'Munkkiniemi': 1510,
    'Myrskylä': 1510,
    'Nurmijärvi': 1510,
    'Otaniemi': 1510,
    'Pernå': 1510,
    'Pohja': 1510,
    'Pornainen': 1510,
    'Porvoo': 1510,
    'Pukkila': 1510,
    'Raaseporin': 1510,
    'Ruotsinpyhtää': 1510,
    'Sammatti': 1510,
    'Sibbo': 1510,
    'Siuntio': 1510,
    'Tuusula': 1510,
    'Vantaa': 1510,
    'Vihti': 1510,
    'Vuosaari': 1510,
    'Abondance': 4798,
    'Abrest': 4798,
    'Aigueblanche': 4798,
    'Aigueperse': 4798,
    'Aime': 4798,
    'Ainay-le-Château': 4798,
    'Aiton': 4798,
    'Aix-les-Bains': 4798,
    'Alba-la-Romaine': 4798,
    'Albens': 4798,
    'Albertville': 4798,
    'Albigny-sur-Saône': 4798,
    'Alby-sur-Chéran': 4798,
    'Alissas': 4798,
    'Alixan': 4798,
    'Allan': 4798,
    'Allanche': 4798,
    'Allègre': 4798,
    'Allevard': 4798,
    'Allex': 4798,
    'Allières-et-Risset': 4798,
    'Allinges': 4798,
    'Allonzier-la-Caille': 4798,
    'Amancy': 4798,
    'Ambérieu-en-Bugey': 4798,
    'Ambérieux-en-Dombes': 4798,
    'Ambert': 4798,
    'Ambierle': 4798,
    'Ambronay': 4798,
    'Amplepuis': 4798,
    'Ampuis': 4798,
    'Ancône': 4798,
    'Andance': 4798,
    'Andancette': 4798,
    'Andrézieux-Bouthéon': 4798,
    'Annecy': 4798,
    'Annecy-le-Vieux': 4798,
    'Annemasse': 4798,
    'Anneyron': 4798,
    'Annonay': 4798,
    'Anse': 4798,
    'Anthy-sur-Léman': 4798,
    'Aoste': 4798,
    'Aouste-sur-Sye': 4798,
    'Apprieu': 4798,
    'Arâches-la-Frasse': 4798,
    'Arbent': 4798,
    'Archamps': 4798,
    'Arenthon': 4798,
    'Arlanc': 4798,
    'Armoy': 4798,
    'Arnas': 4798,
    'Arpajon-sur-Cère': 4798,
    'Ars-sur-Formans': 4798,
    'Artas': 4798,
    'Artemare': 4798,
    'Arthaz-Pont-Notre-Dame': 4798,
    'Attignat': 4798,
    'Aubenas': 4798,
    'Auberives-sur-Varèze': 4798,
    'Aubière': 4798,
    'Aulnat': 4798,
    'Aurec-sur-Loire': 4798,
    'Aurillac': 4798,
    'Autrans': 4798,
    'Auzat-la-Combelle': 4798,
    'Aveize': 4798,
    'Aveizieux': 4798,
    'Avermes': 4798,
    'Aydat': 4798,
    'Ayse': 4798,
    'Bâgé-la-Ville': 4798,
    'Bains': 4798,
    'Balan': 4798,
    'Balbigny': 4798,
    'Ballaison': 4798,
    'Barberaz': 4798,
    'Barby': 4798,
    'Barraux': 4798,
    'Bas-en-Basset': 4798,
    'Bassens': 4798,
    'Beauchastel': 4798,
    'Beaucroissant': 4798,
    'Beaufort': 4798,
    'Beaujeu': 4798,
    'Beaulon': 4798,
    'Beaumont': 4798,
    'Beaumont-lès-Valence': 4798,
    'Beaumont-Monteux': 4798,
    'Beaurepaire': 4798,
    'Beausemblant': 4798,
    'Beauvallon': 4798,
    'Beauvoir-de-Marc': 4798,
    'Beauzac': 4798,
    'Béligneux': 4798,
    'Belle-Plagne': 4798,
    'Bellegarde-en-Forez': 4798,
    'Bellegarde-sur-Valserine': 4798,
    'Bellenaves': 4798,
    'Bellerive-sur-Allier': 4798,
    'Bellevaux': 4798,
    'Belleville': 4798,
    'Belley': 4798,
    'Bellignat': 4798,
    'Belmont-de-la-Loire': 4798,
    'Bernin': 4798,
    'Bessay-sur-Allier': 4798,
    'Besse-et-Saint-Anastaise': 4798,
    'Bessenay': 4798,
    'Beynost': 4798,
    'Bilieu': 4798,
    'Billom': 4798,
    'Biol': 4798,
    'Biviers': 4798,
    'Blacé': 4798,
    'Blanzat': 4798,
    'Blavozy': 4798,
    'Boëge': 4798,
    'Boën-sur-Lignon': 4798,
    'Bonne': 4798,
    'Bonnefamille': 4798,
    'Bonneville': 4798,
    'Bons-en-Chablais': 4798,
    'Bonson': 4798,
    'Boulieu-lès-Annonay': 4798,
    'Bourbon-l’Archambault': 4798,
    'Bourg-Argental': 4798,
    'Bourg-de-Péage': 4798,
    'Bourg-de-Thizy': 4798,
    'Bourg-en-Bresse': 4798,
    'Bourg-lès-Valence': 4798,
    'Bourg-Saint-Andéol': 4798,
    'Bourg-Saint-Maurice': 4798,
    'Bourgoin-Jallieu': 4798,
    'Bournoncle-Saint-Pierre': 4798,
    'Bouvesse-Quirieu': 4798,
    'Bozel': 4798,
    'Brassac-les-Mines': 4798,
    'Brézins': 4798,
    'Brié-et-Angonnes': 4798,
    'Briennon': 4798,
    'Brignais': 4798,
    'Brindas': 4798,
    'Brioude': 4798,
    'Brison-Saint-Innocent': 4798,
    'Brives-Charensac': 4798,
    'Bron': 4798,
    'Broût-Vernet': 4798,
    'Brugheas': 4798,
    'Buellas': 4798,
    'Buis-les-Baronnies': 4798,
    'Bully': 4798,
    'Bussières': 4798,
    'Buxières-les-Mines': 4798,
    'Cailloux-sur-Fontaines': 4798,
    'Caluire-et-Cuire': 4798,
    'Cantal': 4798,
    'Cébazat': 4798,
    'Celles-sur-Durolle': 4798,
    'Cellieu': 4798,
    'Cérilly': 4798,
    'Certines': 4798,
    'Cessieu': 4798,
    'Cessy': 4798,
    'Ceyrat': 4798,
    'Ceyzériat': 4798,
    'Chabeuil': 4798,
    'Châbons': 4798,
    'Chabreloche': 4798,
    'Chadrac': 4798,
    'Chalamont': 4798,
    'Chaleins': 4798,
    'Challes-les-Eaux': 4798,
    'Challex': 4798,
    'Chamagnieu': 4798,
    'Chamalières': 4798,
    'Chambéry': 4798,
    'Chambœuf': 4798,
    'Chamonix-Mont-Blanc': 4798,
    'Champ-sur-Drac': 4798,
    'Champagnac': 4798,
    'Champagne-au-Mont-d’Or': 4798,
    'Champagnier': 4798,
    'Champdieu': 4798,
    'Champeix': 4798,
    'Champier': 4798,
    'Champs-sur-Tarentaine-Marchal': 4798,
    'Chanas': 4798,
    'Chandon': 4798,
    'Chanonat': 4798,
    'Chanos-Curson': 4798,
    'Chantelle': 4798,
    'Chapareillan': 4798,
    'Chapdes-Beaufort': 4798,
    'Chaponnay': 4798,
    'Chaponost': 4798,
    'Chappes': 4798,
    'Charantonnay': 4798,
    'Charavines': 4798,
    'Charbonnières-les-Bains': 4798,
    'Charbonnières-les-Varennes': 4798,
    'Charentay': 4798,
    'Charlieu': 4798,
    'Charly': 4798,
    'Charmes-sur-Rhône': 4798,
    'Charnay': 4798,
    'Charnècles': 4798,
    'Charvieu-Chavagneux': 4798,
    'Chassagny': 4798,
    'Chasse-sur-Rhône': 4798,
    'Chasselay': 4798,
    'Chassieu': 4798,
    'Château-Gaillard': 4798,
    'Châteaugay': 4798,
    'Châteauneuf': 4798,
    'Châteauneuf-de-Galaure': 4798,
    'Châteauneuf-du-Rhône': 4798,
    'Châteauneuf-sur-Isère': 4798,
    'Châtel': 4798,
    'Châtel-Guyon': 4798,
    'Châtillon': 4798,
    'Châtillon-en-Michaille': 4798,
    'Châtillon-la-Palud': 4798,
    'Châtillon-sur-Chalaronne': 4798,
    'Châtillon-sur-Cluses': 4798,
    'Châtonnay': 4798,
    'Chatte': 4798,
    'Chatuzange-le-Goubet': 4798,
    'Chaudes-Aigues': 4798,
    'Chauriat': 4798,
    'Chavanay': 4798,
    'Chavanod': 4798,
    'Chavanoz': 4798,
    'Chazelles-sur-Lyon': 4798,
    'Chazey-sur-Ain': 4798,
    'Chens-sur-Léman': 4798,
    'Chessy': 4798,
    'Chevry': 4798,
    'Chilly': 4798,
    'Chimilin': 4798,
    'Chindrieux': 4798,
    'Chirens': 4798,
    'Choisy': 4798,
    'Chomérac': 4798,
    'Chuzelles': 4798,
    'Civens': 4798,
    'Civrieux': 4798,
    'Claix': 4798,
    'Clérieux': 4798,
    'Clermont-Ferrand': 4798,
    'Clonas-sur-Varèze': 4798,
    'Cluses': 4798,
    'Cognin': 4798,
    'Cogny': 4798,
    'Coise': 4798,
    'Coligny': 4798,
    'Collonges': 4798,
    'Collonges-sous-Salève': 4798,
    'Colombe': 4798,
    'Combloux': 4798,
    'Combronde': 4798,
    'Commelle': 4798,
    'Commentry': 4798,
    'Communay': 4798,
    'Condat': 4798,
    'Condrieu': 4798,
    'Contamine-sur-Arve': 4798,
    'Corbas': 4798,
    'Corbelin': 4798,
    'Cordon': 4798,
    'Corenc': 4798,
    'Cornas': 4798,
    'Cornier': 4798,
    'Coublevie': 4798,
    'Coubon': 4798,
    'Courchevel': 4798,
    'Cournon-d’Auvergne': 4798,
    'Courpière': 4798,
    'Cours-la-Ville': 4798,
    'Courzieu': 4798,
    'Coutouvre': 4798,
    'Coux': 4798,
    'Couzon-au-Mont-d’Or': 4798,
    'Cran-Gevrier': 4798,
    'Cranves-Sales': 4798,
    'Craponne': 4798,
    'Craponne-sur-Arzon': 4798,
    'Crémieu': 4798,
    'Crest': 4798,
    'Creuzier-le-Vieux': 4798,
    'Creys-Mépieu': 4798,
    'Crolles': 4798,
    'Crottet': 4798,
    'Cruas': 4798,
    'Cruet': 4798,
    'Cruseilles': 4798,
    'Cublize': 4798,
    'Cuisiat': 4798,
    'Culhat': 4798,
    'Culoz': 4798,
    'Cunlhat': 4798,
    'Curtin': 4798,
    'Cussac-sur-Loire': 4798,
    'Cusset': 4798,
    'Cusy': 4798,
    'Cuzieu': 4798,
    'Dagneux': 4798,
    'Dallet': 4798,
    'Dardilly': 4798,
    'Davézieux': 4798,
    'Décines-Charpieu': 4798,
    'Denicé': 4798,
    'Département de l  Ain': 4798,
    'Département de l  Allier': 4798,
    'Département de l  Ardèche': 4798,
    'Département de l  Isère': 4798,
    'Département de la Drôme': 4798,
    'Département de la Loire': 4798,
    'Département du Puy-de-Dôme': 4798,
    'Département du Rhône': 4798,
    'Désaignes': 4798,
    'Désertines': 4798,
    'Die': 4798,
    'Diémoz': 4798,
    'Dieulefit': 4798,
    'Dingy-Saint-Clair': 4798,
    'Diou': 4798,
    'Divonne-les-Bains': 4798,
    'Dolomieu': 4798,
    'Domancy': 4798,
    'Domarin': 4798,
    'Domène': 4798,
    'Domérat': 4798,
    'Domessin': 4798,
    'Dompierre-sur-Besbre': 4798,
    'Dompierre-sur-Veyle': 4798,
    'Donzère': 4798,
    'Dortan': 4798,
    'Doussard': 4798,
    'Douvaine': 4798,
    'Doyet': 4798,
    'Drumettaz': 4798,
    'Dunières': 4798,
    'Durtol': 4798,
    'Ébreuil': 4798,
    'Échalas': 4798,
    'Échenevex': 4798,
    'Échirolles': 4798,
    'Écully': 4798,
    'Ennezat': 4798,
    'Entre-Deux-Guiers': 4798,
    'Enval': 4798,
    'Épagny': 4798,
    'Épinouze': 4798,
    'Escoutoux': 4798,
    'Espaly-Saint-Marcel': 4798,
    'Estivareilles': 4798,
    'Estrablin': 4798,
    'Etaux': 4798,
    'Étoile-sur-Rhône': 4798,
    'Étrembières': 4798,
    'Eurre': 4798,
    'Évian-les-Bains': 4798,
    'Évires': 4798,
    'Eybens': 4798,
    'Eyzin-Pinet': 4798,
    'Fareins': 4798,
    'Farnay': 4798,
    'Faverges': 4798,
    'Faverges-de-la-Tour': 4798,
    'Feigères': 4798,
    'Feillens': 4798,
    'Félines': 4798,
    'Ferney-Voltaire': 4798,
    'Féternes': 4798,
    'Feurs': 4798,
    'Feyzin': 4798,
    'Fillinges': 4798,
    'Firminy': 4798,
    'Fitilieu': 4798,
    'Flaviac': 4798,
    'Fleurie': 4798,
    'Fleurieu-sur-Saône': 4798,
    'Foissiat': 4798,
    'Fontaine': 4798,
    'Fontaines-Saint-Martin': 4798,
    'Fontaines-sur-Saône': 4798,
    'Fontanil-Cornillon': 4798,
    'Fontannes': 4798,
    'Fraisses': 4798,
    'Francheleins': 4798,
    'Francheville': 4798,
    'Frangy': 4798,
    'Frans': 4798,
    'Froges': 4798,
    'Frontenex': 4798,
    'Frontonas': 4798,
    'Gaillard': 4798,
    'Gannat': 4798,
    'Gelles': 4798,
    'Genas': 4798,
    'Genay': 4798,
    'Génissieux': 4798,
    'Gerzat': 4798,
    'Gex': 4798,
    'Gières': 4798,
    'Gillonnay': 4798,
    'Gilly-sur-Isère': 4798,
    'Givors': 4798,
    'Gleizé': 4798,
    'Goncelin': 4798,
    'Grandris': 4798,
    'Grane': 4798,
    'Grenay': 4798,
    'Grenoble': 4798,
    'Grésy-sur-Aix': 4798,
    'Grésy-sur-Isère': 4798,
    'Grézieu-la-Varenne': 4798,
    'Grièges': 4798,
    'Grignan': 4798,
    'Grignon': 4798,
    'Grigny': 4798,
    'Groissiat': 4798,
    'Groisy': 4798,
    'Gruffy': 4798,
    'Guéreins': 4798,
    'Guilherand-Granges': 4798,
    'Haute-Loire': 4798,
    'Haute-Rivoire': 4798,
    'Haute-Savoie': 4798,
    'Hauterive': 4798,
    'Hauterives': 4798,
    'Hauteville-Lompnes': 4798,
    'Herbeys': 4798,
    'Heyrieux': 4798,
    'Hières-sur-Amby': 4798,
    'Huez': 4798,
    'Huriel': 4798,
    'Irigny': 4798,
    'Issoire': 4798,
    'Izeaux': 4798,
    'Izernore': 4798,
    'Jacob-Bellecombette': 4798,
    'Janneyrias': 4798,
    'Jardin': 4798,
    'Jarrie': 4798,
    'Jassans-Riottier': 4798,
    'Jasseron': 4798,
    'Jaujac': 4798,
    'Job': 4798,
    'Jonage': 4798,
    'Jons': 4798,
    'Jonzieux': 4798,
    'Joyeuse': 4798,
    'Jujurieux': 4798,
    'Jussac': 4798,
    'L’Étrat': 4798,
    'L’Isle-d’Abeau': 4798,
    'La Balme-de-Sillingy': 4798,
    'La Bâthie': 4798,
    'La Bâtie-Montgascon': 4798,
    'La Bégude-de-Mazenc': 4798,
    'La Biolle': 4798,
    'La Boisse': 4798,
    'La Bourboule': 4798,
    'La Bridoire': 4798,
    'La Buisse': 4798,
    'La Chambre': 4798,
    'La Chapelle-de-la-Tour': 4798,
    'La Clusaz': 4798,
    'La Côte-Saint-André': 4798,
    'La Fouillouse': 4798,
    'La Garde-Adhémar': 4798,
    'La Grand-Croix': 4798,
    'La Monnerie-le-Montel': 4798,
    'La Motte-d’Aveillans': 4798,
    'La Motte-Saint-Martin': 4798,
    'La Motte-Servolex': 4798,
    'La Mulatière': 4798,
    'La Mure': 4798,
    'La Murette': 4798,
    'La Pacaudière': 4798,
    'La Ravoire': 4798,
    'La Ricamarie': 4798,
    'La Roche-Blanche': 4798,
    'La Roche-de-Glun': 4798,
    'La Roche-sur-Foron': 4798,
    'La Rochette': 4798,
    'La Séauve-sur-Semène': 4798,
    'La Talaudière': 4798,
    'La Terrasse': 4798,
    'La Tour': 4798,
    'La Tour-de-Salvagny': 4798,
    'La Tour-du-Pin': 4798,
    'La Tour-en-Jarez': 4798,
    'La Tronche': 4798,
    'La Verpillière': 4798,
    'La Voulte-sur-Rhône': 4798,
    'Labégude': 4798,
    'Lablachère': 4798,
    'Lachapelle-sous-Aubenas': 4798,
    'Lagnieu': 4798,
    'Laiz': 4798,
    'Lalevade-d’Ardèche': 4798,
    'Lamastre': 4798,
    'Lamure-sur-Azergues': 4798,
    'Lancrans': 4798,
    'Langeac': 4798,
    'Lanobre': 4798,
    'Lans-en-Vercors': 4798,
    'Lantriac': 4798,
    'Lapalisse': 4798,
    'Lapte': 4798,
    'Larajasse': 4798,
    'Larequille': 4798,
    'Largentière': 4798,
    'Laroquebrou': 4798,
    'Larringes': 4798,
    'Laussonne': 4798,
    'Lavault-Sainte-Anne': 4798,
    'Lavilledieu': 4798,
    'Le Bois-d  Oingt': 4798,
    'Le Bourg-d’Oisans': 4798,
    'Le Bourget-du-Lac': 4798,
    'Le Breuil-sur-Couze': 4798,
    'Le Cendre': 4798,
    'Le Chambon-Feugerolles': 4798,
    'Le Chambon-sur-Lignon': 4798,
    'Le Champ-près-Froges': 4798,
    'Le Cheylard': 4798,
    'Le Cheylas': 4798,
    'Le Coteau': 4798,
    'Le Crest': 4798,
    'Le Donjon': 4798,
    'Le Grand-Bornand': 4798,
    'Le Grand-Lemps': 4798,
    'Le Gua': 4798,
    'Le Mayet-de-Montagne': 4798,
    'Le Monastier-sur-Gazeille': 4798,
    'Le Péage-de-Roussillon': 4798,
    'Le Perréon': 4798,
    'Le Pin': 4798,
    'Le Pont-de-Beauvoisin': 4798,
    'Le Pont-de-Claix': 4798,
    'Le Pouzin': 4798,
    'Le Puy-en-Velay': 4798,
    'Le Sappey-en-Chartreuse': 4798,
    'Le Teil': 4798,
    'Le Touvet': 4798,
    'Le Versoud': 4798,
    'Lempdes-sur-Allagnon': 4798,
    'Lent': 4798,
    'Lentigny': 4798,
    'Lentilly': 4798,
    'Les Abrets': 4798,
    'Les Allues': 4798,
    'Les Ancizes': 4798,
    'Les Avenières': 4798,
    'Les Chères': 4798,
    'Les Contamines-Montjoie': 4798,
    'Les Côtes-d  Arey': 4798,
    'Les Deux Alpes': 4798,
    'Les Échelles': 4798,
    'Les Gets': 4798,
    'Les Houches': 4798,
    'Les Marches': 4798,
    'Les Martres-d  Artière': 4798,
    'Les Roches-de-Condrieu': 4798,
    'Les Vans': 4798,
    'Leyment': 4798,
    'Lézigneux': 4798,
    'Lezoux': 4798,
    'Liergues': 4798,
    'Limas': 4798,
    'Limonest': 4798,
    'Lissieu': 4798,
    'Livet-et-Gavet': 4798,
    'Livron-sur-Drôme': 4798,
    'Loire-sur-Rhône': 4798,
    'Loisin': 4798,
    'Lorette': 4798,
    'Loriol-sur-Drôme': 4798,
    'Lovagny': 4798,
    'Loyettes': 4798,
    'Lozanne': 4798,
    'Lucenay': 4798,
    'Lucinges': 4798,
    'Lugrin': 4798,
    'Lumbin': 4798,
    'Lurcy-Lévis': 4798,
    'Lusigny': 4798,
    'Luzinay': 4798,
    'Lyaud': 4798,
    'Lyon': 4798,
    'Mably': 4798,
    'Maclas': 4798,
    'Mâcot-la-Plagne': 4798,
    'Magland': 4798,
    'Malataverne': 4798,
    'Malintrat': 4798,
    'Malissard': 4798,
    'Manzat': 4798,
    'Manziat': 4798,
    'Marboz': 4798,
    'Marcellaz-Albanais': 4798,
    'Marcilly-le-Châtel': 4798,
    'Marennes': 4798,
    'Margencel': 4798,
    'Marignier': 4798,
    'Marin': 4798,
    'Maringues': 4798,
    'Marlhes': 4798,
    'Marnaz': 4798,
    'Marsac-en-Livradois': 4798,
    'Marsanne': 4798,
    'Marsat': 4798,
    'Marthod': 4798,
    'Martignat': 4798,
    'Massiac': 4798,
    'Massieux': 4798,
    'Massongy': 4798,
    'Maubec': 4798,
    'Mauriac': 4798,
    'Maurs': 4798,
    'Mauves': 4798,
    'Maxilly-sur-Léman': 4798,
    'Mazet-Saint-Voy': 4798,
    'Méaudre': 4798,
    'Megève': 4798,
    'Meillonnas': 4798,
    'Ménétrol': 4798,
    'Mens': 4798,
    'Menthon-Saint-Bernard': 4798,
    'Mercurol-Veaunes': 4798,
    'Mercury': 4798,
    'Méribel': 4798,
    'Méry': 4798,
    'Messeix': 4798,
    'Messery': 4798,
    'Messimy': 4798,
    'Metz': 4798,
    'Meximieux': 4798,
    'Meylan': 4798,
    'Meysse': 4798,
    'Meythet': 4798,
    'Meyzieu': 4798,
    'Mezel': 4798,
    'Mézériat': 4798,
    'Mieussy': 4798,
    'Millery': 4798,
    'Mionnay': 4798,
    'Mions': 4798,
    'Mirabel-aux-Baronnies': 4798,
    'Mirefleurs': 4798,
    'Miribel': 4798,
    'Miribel-les-Échelles': 4798,
    'Misérieux': 4798,
    'Modane': 4798,
    'Moidieu': 4798,
    'Moirans': 4798,
    'Molinet': 4798,
    'Monistrol-sur-Loire': 4798,
    'Monnetier-Mornex': 4798,
    'Mont-de-Lans': 4798,
    'Mont-Dore': 4798,
    'Mont-Saxonnex': 4798,
    'Montagnat': 4798,
    'Montagny': 4798,
    'Montaigut': 4798,
    'Montalieu': 4798,
    'Montanay': 4798,
    'Montbonnot-Saint-Martin': 4798,
    'Montboucher-sur-Jabron': 4798,
    'Montbrison': 4798,
    'Montéléger': 4798,
    'Montélier': 4798,
    'Montélimar': 4798,
    'Montfaucon-en-Velay': 4798,
    'Montferrat': 4798,
    'Montluçon': 4798,
    'Montluel': 4798,
    'Montmarault': 4798,
    'Montmélian': 4798,
    'Montmerle-sur-Saône': 4798,
    'Montmeyran': 4798,
    'Montoison': 4798,
    'Montréal-la-Cluse': 4798,
    'Montrevel-en-Bresse': 4798,
    'Montrond-les-Bains': 4798,
    'Montrottier': 4798,
    'Montségur-sur-Lauzon': 4798,
    'Morancé': 4798,
    'Morestel': 4798,
    'Mornant': 4798,
    'Morzine': 4798,
    'Moulins': 4798,
    'Mours-Saint-Eusèbe': 4798,
    'Moûtiers': 4798,
    'Mouxy': 4798,
    'Moye': 4798,
    'Mozac': 4798,
    'Murat': 4798,
    'Nantua': 4798,
    'Naucelles': 4798,
    'Néris-les-Bains': 4798,
    'Neuilly-le-Réal': 4798,
    'Neulise': 4798,
    'Neussargues': 4798,
    'Neuvecelle': 4798,
    'Neuvéglise': 4798,
    'Neuville-les-Dames': 4798,
    'Neuville-sur-Ain': 4798,
    'Neuville-sur-Saône': 4798,
    'Neuvy': 4798,
    'Neydens': 4798,
    'Niévroz': 4798,
    'Nivolas-Vermelle': 4798,
    'Nohanent': 4798,
    'Noirétable': 4798,
    'Notre-Dame-de-Mésage': 4798,
    'Novalaise': 4798,
    'Noyarey': 4798,
    'Nyons': 4798,
    'Orcet': 4798,
    'Orcines': 4798,
    'Orléat': 4798,
    'Orliénas': 4798,
    'Ornex': 4798,
    'Ouches': 4798,
    'Oullins': 4798,
    'Oyonnax': 4798,
    'Oytier-Saint-Oblas': 4798,
    'Panissières': 4798,
    'Paslières': 4798,
    'Passy': 4798,
    'Paulhaguet': 4798,
    'Peaugres': 4798,
    'Peillonnex': 4798,
    'Pélussin': 4798,
    'Pérignat-lès-Sarliève': 4798,
    'Pérignat-sur-Allier': 4798,
    'Périgneux': 4798,
    'Péron': 4798,
    'Péronnas': 4798,
    'Pérouges': 4798,
    'Perreux': 4798,
    'Perrignier': 4798,
    'Pers-Jussy': 4798,
    'Peschadoires': 4798,
    'Peyrins': 4798,
    'Pierre-Bénite': 4798,
    'Pierre-Châtel': 4798,
    'Pierrefort': 4798,
    'Pierrelatte': 4798,
    'Pionsat': 4798,
    'Plauzat': 4798,
    'Pleaux': 4798,
    'Poisat': 4798,
    'Poisy': 4798,
    'Polignac': 4798,
    'Polliat': 4798,
    'Pollionnay': 4798,
    'Polminhac': 4798,
    'Pommiers': 4798,
    'Poncin': 4798,
    'Pont-d’Ain': 4798,
    'Pont-de-Chéruy': 4798,
    'Pont-de-Vaux': 4798,
    'Pont-de-Veyle': 4798,
    'Pont-du-Château': 4798,
    'Pont-en-Royans': 4798,
    'Pont-Évêque': 4798,
    'Pont-Salomon': 4798,
    'Pontcharra': 4798,
    'Pontcharra-sur-Turdine': 4798,
    'Porcieu': 4798,
    'Portes-lès-Valence': 4798,
    'Pouilly-lès-Feurs': 4798,
    'Pouilly-les-Nonains': 4798,
    'Pouilly-sous-Charlieu': 4798,
    'Pragoulin': 4798,
    'Praz-sur-Arly': 4798,
    'Précieux': 4798,
    'Prémilhat': 4798,
    'Priay': 4798,
    'Pringy': 4798,
    'Privas': 4798,
    'Publier': 4798,
    'Pusignan': 4798,
    'Puy-Guillaume': 4798,
    'Quincié-en-Beaujolais': 4798,
    'Quincieux': 4798,
    'Quinssaines': 4798,
    'Quintenas': 4798,
    'Randan': 4798,
    'Régny': 4798,
    'Reignier-Ésery': 4798,
    'Renage': 4798,
    'Renaison': 4798,
    'Replonges': 4798,
    'Retournac': 4798,
    'Revel': 4798,
    'Reventin-Vaugris': 4798,
    'Reyrieux': 4798,
    'Rillieux-la-Pape': 4798,
    'Riom': 4798,
    'Riom-ès-Montagnes': 4798,
    'Riorges': 4798,
    'Riotord': 4798,
    'Rive-de-Gier': 4798,
    'Rives': 4798,
    'Roanne': 4798,
    'Roche': 4798,
    'Roche-la-Molière': 4798,
    'Rochegude': 4798,
    'Rochemaure': 4798,
    'Rochetaillée-sur-Saône': 4798,
    'Roiffieux': 4798,
    'Romagnat': 4798,
    'Romagnieu': 4798,
    'Romans-sur-Isère': 4798,
    'Rosières': 4798,
    'Roussillon': 4798,
    'Royat': 4798,
    'Roybon': 4798,
    'Rozier-en-Donzy': 4798,
    'Rumilly': 4798,
    'Ruoms': 4798,
    'Ruy': 4798,
    'Sablons': 4798,
    'Sail-sous-Couzan': 4798,
    'Sain-Bel': 4798,
    'Saint-Agrève': 4798,
    'Saint-Alban-de-Roche': 4798,
    'Saint-Alban-Leysse': 4798,
    'Saint-Amant-Tallende': 4798,
    'Saint-Andéol-le-Château': 4798,
    'Saint-André-de-Corcy': 4798,
    'Saint-André-le-Gaz': 4798,
    'Saint-André-le-Puy': 4798,
    'Saint-André-sur-Vieux-Jonc': 4798,
    'Saint-Antoine-l  Abbaye': 4798,
    'Saint-Aupre': 4798,
    'Saint-Baldoph': 4798,
    'Saint-Barthélemy-de-Vals': 4798,
    'Saint-Beauzire': 4798,
    'Saint-Béron': 4798,
    'Saint-Bon-Tarentaise': 4798,
    'Saint-Bonnet-de-Mure': 4798,
    'Saint-Bonnet-le-Château': 4798,
    'Saint-Bonnet-les-Oules': 4798,
    'Saint-Bonnet-près-Riom': 4798,
    'Saint-Cassien': 4798,
    'Saint-Cergues': 4798,
    'Saint-Cernin': 4798,
    'Saint-Chamond': 4798,
    'Saint-Chef': 4798,
    'Saint-Christo-en-Jarez': 4798,
    'Saint-Clair-de-la-Tour': 4798,
    'Saint-Clair-du-Rhône': 4798,
    'Saint-Cyprien': 4798,
    'Saint-Cyr': 4798,
    'Saint-Cyr-sur-le-Rhône': 4798,
    'Saint-Cyr-sur-Menthon': 4798,
    'Saint-Denis-de-Cabanne': 4798,
    'Saint-Denis-en-Bugey': 4798,
    'Saint-Denis-lès-Bourg': 4798,
    'Saint-Didier-de-Formans': 4798,
    'Saint-Didier-de-la-Tour': 4798,
    'Saint-Didier-en-Velay': 4798,
    'Saint-Didier-sous-Riverie': 4798,
    'Saint-Didier-sur-Chalaronne': 4798,
    'Saint-Égrève': 4798,
    'Saint-Éloy-les-Mines': 4798,
    'Saint-Étienne': 4798,
    'Saint-Étienne-de-Crossey': 4798,
    'Saint-Etienne-de-Cuines': 4798,
    'Saint-Étienne-de-Fontbellon': 4798,
    'Saint-Étienne-de-Saint-Geoirs': 4798,
    'Saint-Étienne-des-Oullières': 4798,
    'Saint-Étienne-du-Bois': 4798,
    'Saint-Étienne-sur-Chalaronne': 4798,
    'Saint-Félicien': 4798,
    'Saint-Félix': 4798,
    'Saint-Flour': 4798,
    'Saint-Fons': 4798,
    'Saint-Forgeux': 4798,
    'Saint-François': 4798,
    'Saint-Galmier': 4798,
    'Saint-Genès-Champanelle': 4798,
    'Saint-Genest-Lerpt': 4798,
    'Saint-Genest-Malifaux': 4798,
    'Saint-Genis-Laval': 4798,
    'Saint-Genis-les-Ollières': 4798,
    'Saint-Genis-Pouilly': 4798,
    'Saint-Genix-sur-Guiers': 4798,
    'Saint-Geoire-en-Valdaine': 4798,
    'Saint-Georges-de-Commiers': 4798,
    'Saint-Georges-de-Mons': 4798,
    'Saint-Georges-de-Reneins': 4798,
    'Saint-Georges-Haute-Ville': 4798,
    'Saint-Georges-les-Bains': 4798,
    'Saint-Georges-sur-Allier': 4798,
    'Saint-Gérand-le-Puy': 4798,
    'Saint-Germain-des-Fossés': 4798,
    'Saint-Germain-Laprade': 4798,
    'Saint-Germain-Laval': 4798,
    'Saint-Germain-Lembron': 4798,
    'Saint-Germain-Lespinasse': 4798,
    'Saint-Germain-Nuelles': 4798,
    'Saint-Gervais-d’Auvergne': 4798,
    'Saint-Gervais-les-Bains': 4798,
    'Saint-Héand': 4798,
    'Saint-Hilaire': 4798,
    'Saint-Hilaire-de-la-Côte': 4798,
    'Saint-Hilaire-du-Rosier': 4798,
    'Saint-Ismier': 4798,
    'Saint-Jean-Bonnefonds': 4798,
    'Saint-Jean-d’Aulps': 4798,
    'Saint-Jean-de-Bournay': 4798,
    'Saint-Jean-de-Gonville': 4798,
    'Saint-Jean-de-Maurienne': 4798,
    'Saint-Jean-de-Moirans': 4798,
    'Saint-Jean-de-Muzols': 4798,
    'Saint-Jean-de-Niost': 4798,
    'Saint-Jean-de-Sixt': 4798,
    'Saint-Jean-de-Soudain': 4798,
    'Saint-Jean-en-Royans': 4798,
    'Saint-Jean-le-Vieux': 4798,
    'Saint-Jean-sur-Veyle': 4798,
    'Saint-Jeoire': 4798,
    'Saint-Jorioz': 4798,
    'Saint-Joseph': 4798,
    'Saint-Joseph-de-Rivière': 4798,
    'Saint-Julien-Chapteuil': 4798,
    'Saint-Julien-en-Genevois': 4798,
    'Saint-Julien-en-Saint-Alban': 4798,
    'Saint-Julien-Molin-Molette': 4798,
    'Saint-Just-Chaleyssin': 4798,
    'Saint-Just-d  Ardèche': 4798,
    'Saint-Just-de-Claix': 4798,
    'Saint-Just-en-Chevalet': 4798,
    'Saint-Just-la-Pendue': 4798,
    'Saint-Just-Malmont': 4798,
    'Saint-Just-Saint-Rambert': 4798,
    'Saint-Lattier': 4798,
    'Saint-Laurent-de-Chamousset': 4798,
    'Saint-Laurent-de-Mure': 4798,
    'Saint-Laurent-du-Pape': 4798,
    'Saint-Laurent-du-Pont': 4798,
    'Saint-Laurent-en-Royans': 4798,
    'Saint-Laurent-sur-Saône': 4798,
    'Saint-Léger-sur-Roanne': 4798,
    'Saint-Mamet-la-Salvetat': 4798,
    'Saint-Marcel-d  Ardèche': 4798,
    'Saint-Marcel-lès-Annonay': 4798,
    'Saint-Marcel-lès-Sauzet': 4798,
    'Saint-Marcel-lès-Valence': 4798,
    'Saint-Marcellin': 4798,
    'Saint-Marcellin-en-Forez': 4798,
    'Saint-Martin-Bellevue': 4798,
    'Saint-Martin-d’Hères': 4798,
    'Saint-Martin-de-Belleville': 4798,
    'Saint-Martin-de-Valamas': 4798,
    'Saint-Martin-du-Frêne': 4798,
    'Saint-Martin-du-Mont': 4798,
    'Saint-Martin-en-Haut': 4798,
    'Saint-Martin-la-Plaine': 4798,
    'Saint-Martin-le-Vinoux': 4798,
    'Saint-Maurice-de-Beynost': 4798,
    'Saint-Maurice-de-Gourdans': 4798,
    'Saint-Maurice-de-Lignon': 4798,
    'Saint-Maurice-en-Gourgois': 4798,
    'Saint-Maurice-sur-Dargoire': 4798,
    'Saint-Michel-de-Maurienne': 4798,
    'Saint-Nazaire-les-Eymes': 4798,
    'Saint-Nizier-sous-Charlieu': 4798,
    'Saint-Ours': 4798,
    'Saint-Pal-de-Mons': 4798,
    'Saint-Paul': 4798,
    'Saint-Paul-de-Varax': 4798,
    'Saint-Paul-de-Varces': 4798,
    'Saint-Paul-des-Landes': 4798,
    'Saint-Paul-en-Chablais': 4798,
    'Saint-Paul-en-Jarez': 4798,
    'Saint-Paul-lès-Romans': 4798,
    'Saint-Paul-Trois-Châteaux': 4798,
    'Saint-Paulien': 4798,
    'Saint-Péray': 4798,
    'Saint-Pierre-de-Bœuf': 4798,
    'Saint-Pierre-de-Chandieu': 4798,
    'Saint-Pierre-la-Palud': 4798,
    'Saint-Pourçain-sur-Sioule': 4798,
    'Saint-Priest': 4798,
    'Saint-Priest-en-Jarez': 4798,
    'Saint-Privat': 4798,
    'Saint-Quentin-Fallavier': 4798,
    'Saint-Quentin-sur-Isère': 4798,
    'Saint-Rambert-en-Bugey': 4798,
    'Saint-Rémy-de-Maurienne': 4798,
    'Saint-Rémy-en-Rollat': 4798,
    'Saint-Rémy-sur-Durolle': 4798,
    'Saint-Restitut': 4798,
    'Saint-Romain-de-Jalionas': 4798,
    'Saint-Romain-de-Popey': 4798,
    'Saint-Romain-en-Gal': 4798,
    'Saint-Romain-la-Motte': 4798,
    'Saint-Romain-le-Puy': 4798,
    'Saint-Romans': 4798,
    'Saint-Saturnin': 4798,
    'Saint-Sauves-d  Auvergne': 4798,
    'Saint-Sauveur': 4798,
    'Saint-Sauveur-de-Montagut': 4798,
    'Saint-Sauveur-en-Rue': 4798,
    'Saint-Savin': 4798,
    'Saint-Sernin': 4798,
    'Saint-Siméon-de-Bressieux': 4798,
    'Saint-Simon': 4798,
    'Saint-Sorlin-en-Bugey': 4798,
    'Saint-Sorlin-en-Valloire': 4798,
    'Saint-Symphorien-de-Lay': 4798,
    'Saint-Symphorien-sur-Coise': 4798,
    'Saint-Trivier-de-Courtes': 4798,
    'Saint-Trivier-sur-Moignans': 4798,
    'Saint-Uze': 4798,
    'Saint-Vallier': 4798,
    'Saint-Vérand': 4798,
    'Saint-Victor': 4798,
    'Saint-Victor-de-Cessieu': 4798,
    'Saint-Vincent-de-Mercuze': 4798,
    'Saint-Yorre': 4798,
    'Sainte-Colombe': 4798,
    'Sainte-Consorce': 4798,
    'Sainte-Euphémie': 4798,
    'Sainte-Florine': 4798,
    'Sainte-Foy-l’Argentière': 4798,
    'Sainte-Foy-lès-Lyon': 4798,
    'Sainte-Hélène-sur-Isère': 4798,
    'Sainte-Sigolène': 4798,
    'Salagnon': 4798,
    'Salaise-sur-Sanne': 4798,
    'Sales': 4798,
    'Salins-les-Thermes': 4798,
    'Sallanches': 4798,
    'Samoëns': 4798,
    'Sansac-de-Marmiesse': 4798,
    'Sarras': 4798,
    'Sassenage': 4798,
    'Sathonay-Camp': 4798,
    'Sathonay-Village': 4798,
    'Satillieu': 4798,
    'Satolas-et-Bonce': 4798,
    'Saugues': 4798,
    'Saulce-sur-Rhône': 4798,
    'Sault-Brénaz': 4798,
    'Sauverny': 4798,
    'Sauxillanges': 4798,
    'Sauzet': 4798,
    'Savasse': 4798,
    'Savigneux': 4798,
    'Savigny': 4798,
    'Savoie': 4798,
    'Sayat': 4798,
    'Sciez': 4798,
    'Scionzier': 4798,
    'Séez': 4798,
    'Ségny': 4798,
    'Septème': 4798,
    'Sérézin-du-Rhône': 4798,
    'Sergy': 4798,
    'Sermérieu': 4798,
    'Serpaize': 4798,
    'Serrières': 4798,
    'Serrières-de-Briord': 4798,
    'Servas': 4798,
    'Sevrier': 4798,
    'Seynod': 4798,
    'Seyssinet-Pariset': 4798,
    'Seyssins': 4798,
    'Seyssuel': 4798,
    'Sillans': 4798,
    'Sillingy': 4798,
    'Simandres': 4798,
    'Solaize': 4798,
    'Solignac-sur-Loire': 4798,
    'Sonnay': 4798,
    'Sonnaz': 4798,
    'Sorbiers': 4798,
    'Soucieu-en-Jarrest': 4798,
    'Sourcieux-les-Mines': 4798,
    'Souvigny': 4798,
    'Soyons': 4798,
    'Sury-le-Comtal': 4798,
    'Suze-la-Rousse': 4798,
    'Tain-l’Hermitage': 4798,
    'Tallende': 4798,
    'Talloires': 4798,
    'Taluyers': 4798,
    'Taninges': 4798,
    'Tarare': 4798,
    'Tassin-la-Demi-Lune': 4798,
    'Taulignan': 4798,
    'Tenay': 4798,
    'Tence': 4798,
    'Tencin': 4798,
    'Ternay': 4798,
    'Theizé': 4798,
    'Theys': 4798,
    'Thiers': 4798,
    'Thil': 4798,
    'Thizy-les-Bourgs': 4798,
    'Thoiry': 4798,
    'Thoissey': 4798,
    'Thônes': 4798,
    'Thonon-les-Bains': 4798,
    'Thorens-Glières': 4798,
    'Thuellin': 4798,
    'Thueyts': 4798,
    'Thurins': 4798,
    'Thyez': 4798,
    'Tignes': 4798,
    'Tignieu': 4798,
    'Tossiat': 4798,
    'Toulaud': 4798,
    'Toulon-sur-Allier': 4798,
    'Tournon-sur-Rhône': 4798,
    'Toussieu': 4798,
    'Tramoyes': 4798,
    'Treffort-Cuisiat': 4798,
    'Trept': 4798,
    'Tresserve': 4798,
    'Trévol': 4798,
    'Trévoux': 4798,
    'Tulette': 4798,
    'Tullins': 4798,
    'Ugine': 4798,
    'Unieux': 4798,
    'Upie': 4798,
    'Usson-en-Forez': 4798,
    'Val Thorens': 4798,
    'Val-d’Isère': 4798,
    'Valence': 4798,
    'Valencin': 4798,
    'Valleiry': 4798,
    'Vallières': 4798,
    'Valloire': 4798,
    'Vallon-en-Sully': 4798,
    'Vallon-Pont-d’Arc': 4798,
    'Vals-les-Bains': 4798,
    'Vals-près-le-Puy': 4798,
    'Varennes-sur-Allier': 4798,
    'Vaugneray': 4798,
    'Vaulnaveys-le-Bas': 4798,
    'Vaulnaveys-le-Haut': 4798,
    'Vaulx-en-Velin': 4798,
    'Vaulx-Milieu': 4798,
    'Vaux-en-Bugey': 4798,
    'Veauche': 4798,
    'Veigy': 4798,
    'Vendat': 4798,
    'Vénissieux': 4798,
    'Vergongheon': 4798,
    'Vernaison': 4798,
    'Vernioz': 4798,
    'Vernosc-lès-Annonay': 4798,
    'Vernoux-en-Vivarais': 4798,
    'Versonnex': 4798,
    'Vertaizon': 4798,
    'Vesseaux': 4798,
    'Vétraz-Monthoux': 4798,
    'Veurey-Voroize': 4798,
    'Veyras': 4798,
    'Veyre-Monton': 4798,
    'Veyrier-du-Lac': 4798,
    'Vic-le-Comte': 4798,
    'Vic-sur-Cère': 4798,
    'Vichy': 4798,
    'Vieille-Brioude': 4798,
    'Vienne': 4798,
    'Vif': 4798,
    'Villard-Bonnot': 4798,
    'Villard-de-Lans': 4798,
    'Villargondran': 4798,
    'Villars': 4798,
    'Villars-les-Dombes': 4798,
    'Villaz': 4798,
    'Ville-la-Grand': 4798,
    'Ville-sous-Anjou': 4798,
    'Villebois': 4798,
    'Villebret': 4798,
    'Villefontaine': 4798,
    'Villefranche-d’Allier': 4798,
    'Villefranche-sur-Saône': 4798,
    'Villemoirieu': 4798,
    'Villeneuve': 4798,
    'Villeneuve-de-Berg': 4798,
    'Villerest': 4798,
    'Villereversure': 4798,
    'Villette-de-Vienne': 4798,
    'Villeurbanne': 4798,
    'Villevocance': 4798,
    'Villié-Morgon': 4798,
    'Vimines': 4798,
    'Vinay': 4798,
    'Vinsobres': 4798,
    'Violay': 4798,
    'Viriat': 4798,
    'Virieu': 4798,
    'Virieu-le-Grand': 4798,
    'Viriville': 4798,
    'Viry': 4798,
    'Viuz-en-Sallaz': 4798,
    'Viuz-la-Chiésaz': 4798,
    'Viviers': 4798,
    'Viviers-du-Lac': 4798,
    'Vizille': 4798,
    'Voglans': 4798,
    'Voiron': 4798,
    'Volvic': 4798,
    'Vonnas': 4798,
    'Voreppe': 4798,
    'Vorey': 4798,
    'Vougy': 4798,
    'Vourey': 4798,
    'Vourles': 4798,
    'Ydes': 4798,
    'Yenne': 4798,
    'Youx': 4798,
    'Yssingeaux': 4798,
    'Ytrac': 4798,
    'Yzeure': 4798,
    'Ahuy': 4825,
    'Aillant-sur-Tholon': 4825,
    'Aillevillers-et-Lyaumont': 4825,
    'Aiserey': 4825,
    'Ancy-le-Franc': 4825,
    'Andelnans': 4825,
    'Appoigny': 4825,
    'Arbois': 4825,
    'Arbouans': 4825,
    'Arc-et-Senans': 4825,
    'Arc-lès-Gray': 4825,
    'Arc-sur-Tille': 4825,
    'Arcey': 4825,
    'Arinthod': 4825,
    'Arnay-le-Duc': 4825,
    'Audincourt': 4825,
    'Augy': 4825,
    'Autun': 4825,
    'Auxerre': 4825,
    'Auxon-Dessous': 4825,
    'Auxonne': 4825,
    'Auxy': 4825,
    'Avallon': 4825,
    'Avanne-Aveney': 4825,
    'Bart': 4825,
    'Baume-les-Dames': 4825,
    'Bavans': 4825,
    'Bavilliers': 4825,
    'Beaucourt': 4825,
    'Beaune': 4825,
    'Belfort': 4825,
    'Belleneuve': 4825,
    'Besançon': 4825,
    'Bethoncourt': 4825,
    'Beure': 4825,
    'Blamont': 4825,
    'Blanzy': 4825,
    'Bléneau': 4825,
    'Bletterans': 4825,
    'Bligny-lès-Beaune': 4825,
    'Bois-d’Amont': 4825,
    'Bourbon-Lancy': 4825,
    'Bourogne': 4825,
    'Boussières': 4825,
    'Branges': 4825,
    'Brazey-en-Plaine': 4825,
    'Brienon-sur-Armançon': 4825,
    'Buxy': 4825,
    'Cercy-la-Tour': 4825,
    'Cézy': 4825,
    'Chablis': 4825,
    'Chagny': 4825,
    'Challuy': 4825,
    'Chalon-sur-Saône': 4825,
    'Châlonvillars': 4825,
    'Champagney': 4825,
    'Champagnole': 4825,
    'Champforgeuil': 4825,
    'Champignelles': 4825,
    'Champigny': 4825,
    'Champlitte': 4825,
    'Champlitte-la-Ville': 4825,
    'Champs-sur-Yonne': 4825,
    'Champvans': 4825,
    'Chantenay-Saint-Imbert': 4825,
    'Charbuy': 4825,
    'Charnay-lès-Mâcon': 4825,
    'Charny': 4825,
    'Charolles': 4825,
    'Charquemont': 4825,
    'Château-Chinon(Ville)': 4825,
    'Châtenois-les-Forges': 4825,
    'Châtenoy-le-Royal': 4825,
    'Châtillon-en-Bazois': 4825,
    'Châtillon-le-Duc': 4825,
    'Châtillon-sur-Seine': 4825,
    'Chauffailles': 4825,
    'Chaulgnes': 4825,
    'Chaussin': 4825,
    'Chemaudin': 4825,
    'Chenôve': 4825,
    'Cheny': 4825,
    'Chéroy': 4825,
    'Chevigny-Saint-Sauveur': 4825,
    'Chèvremont': 4825,
    'Choisey': 4825,
    'Ciry-le-Noble': 4825,
    'Clairvaux-les-Lacs': 4825,
    'Clamecy': 4825,
    'Clerval': 4825,
    'Cluny': 4825,
    'Colombier-Fontaine': 4825,
    'Corbenay': 4825,
    'Corbigny': 4825,
    'Corpeau': 4825,
    'Cosne-Cours-sur-Loire': 4825,
    'Couches': 4825,
    'Couchey': 4825,
    'Coulanges-lès-Nevers': 4825,
    'Courcelles-lès-Montbéliard': 4825,
    'Courlon-sur-Yonne': 4825,
    'Cousance': 4825,
    'Couternon': 4825,
    'Cravanche': 4825,
    'Crêches-sur-Saône': 4825,
    'Crissey': 4825,
    'Cuiseaux': 4825,
    'Cuisery': 4825,
    'Curgy': 4825,
    'Daix': 4825,
    'Damparis': 4825,
    'Dampierre': 4825,
    'Dampierre-les-Bois': 4825,
    'Dampierre-sur-Salon': 4825,
    'Damprichard': 4825,
    'Danjoutin': 4825,
    'Dannemarie-sur-Crête': 4825,
    'Dasle': 4825,
    'Decize': 4825,
    'Delle': 4825,
    'Demigny': 4825,
    'Département de l  Yonne': 4825,
    'Département de la Côte-d  Or': 4825,
    'Département de la Haute-Saône': 4825,
    'Département de la Nièvre': 4825,
    'Département de Saône-et-Loire': 4825,
    'Devecey': 4825,
    'Diges': 4825,
    'Digoin': 4825,
    'Dijon': 4825,
    'Dole': 4825,
    'Donzy': 4825,
    'Dornes': 4825,
    'Doubs': 4825,
    'Dracy-le-Fort': 4825,
    'Échenoz-la-Méline': 4825,
    'École-Valentin': 4825,
    'Écuisses': 4825,
    'Égriselles-le-Bocage': 4825,
    'Épervans': 4825,
    'Épinac': 4825,
    'Essert': 4825,
    'Étalans': 4825,
    'Étang-sur-Arroux': 4825,
    'Étupes': 4825,
    'Exincourt': 4825,
    'Faverney': 4825,
    'Fénay': 4825,
    'Fesches-le-Châtel': 4825,
    'Fleurey-sur-Ouche': 4825,
    'Fleury-la-Vallée': 4825,
    'Flogny-la-Chapelle': 4825,
    'Fontaine-lès-Dijon': 4825,
    'Fontaine-lès-Luxeuil': 4825,
    'Fontaines': 4825,
    'Foucherans': 4825,
    'Fougerolles': 4825,
    'Fourchambault': 4825,
    'Frahier-et-Chatebier': 4825,
    'Fraisans': 4825,
    'Franois': 4825,
    'Frasne': 4825,
    'Froideconche': 4825,
    'Frotey-lès-Vesoul': 4825,
    'Garchizy': 4825,
    'Génelard': 4825,
    'Genlis': 4825,
    'Gergy': 4825,
    'Gevrey-Chambertin': 4825,
    'Gilley': 4825,
    'Giromagny': 4825,
    'Givry': 4825,
    'Grand-Charmont': 4825,
    'Grandfontaine': 4825,
    'Grandvillars': 4825,
    'Gray': 4825,
    'Gray-la-Ville': 4825,
    'Gron': 4825,
    'Guérigny': 4825,
    'Gueugnon': 4825,
    'Gurgy': 4825,
    'Gy': 4825,
    'Hauteville-lès-Dijon': 4825,
    'Héricourt': 4825,
    'Hérimoncourt': 4825,
    'Héry': 4825,
    'Hurigny': 4825,
    'Imphy': 4825,
    'Is-sur-Tille': 4825,
    'Joigny': 4825,
    'Joncherey': 4825,
    'Jougne': 4825,
    'Joux-la-Ville': 4825,
    'Jura': 4825,
    'Jussey': 4825,
    'La Chapelle-de-Guinchay': 4825,
    'La Charité-sur-Loire': 4825,
    'La Clayette': 4825,
    'La Cluse-et-Mijoux': 4825,
    'La Machine': 4825,
    'La Motte-Saint-Jean': 4825,
    'La Roche-Vineuse': 4825,
    'Ladoix-Serrigny': 4825,
    'Lamarche-sur-Saône': 4825,
    'Laroche-Saint-Cydroine': 4825,
    'Lavans-lès-Saint-Claude': 4825,
    'Le Breuil': 4825,
    'Le Creusot': 4825,
    'Le Russey': 4825,
    'Lepuix': 4825,
    'Les Fins': 4825,
    'Les Fourgs': 4825,
    'Les Rousses': 4825,
    'Levier': 4825,
    'Ligny-le-Châtel': 4825,
    'Longchamp': 4825,
    'Longchaumois': 4825,
    'Longecourt-en-Plaine': 4825,
    'Longvic': 4825,
    'Lons-le-Saunier': 4825,
    'Lormes': 4825,
    'Losne': 4825,
    'Louhans': 4825,
    'Lucenay-lès-Aix': 4825,
    'Lure': 4825,
    'Luxeuil-les-Bains': 4825,
    'Luzy': 4825,
    'Mâcon': 4825,
    'Magny-Cours': 4825,
    'Magny-Vernois': 4825,
    'Maîche': 4825,
    'Maillot': 4825,
    'Malay-le-Grand': 4825,
    'Mamirolle': 4825,
    'Mandeure': 4825,
    'Marcigny': 4825,
    'Marcilly-sur-Tille': 4825,
    'Marmagne': 4825,
    'Marnay': 4825,
    'Marsannay-la-Côte': 4825,
    'Marzy': 4825,
    'Mathay': 4825,
    'Matour': 4825,
    'Mélisey': 4825,
    'Mellecey': 4825,
    'Mercurey': 4825,
    'Mervans': 4825,
    'Messigny-et-Vantoux': 4825,
    'Meursault': 4825,
    'Méziré': 4825,
    'Migennes': 4825,
    'Mirebeau-sur-Bèze': 4825,
    'Miserey-Salines': 4825,
    'Moirans-en-Montagne': 4825,
    'Monéteau': 4825,
    'Mont-sous-Vaudrey': 4825,
    'Montbard': 4825,
    'Montbéliard': 4825,
    'Montceau-les-Mines': 4825,
    'Montcenis': 4825,
    'Montchanin': 4825,
    'Montenois': 4825,
    'Montfaucon': 4825,
    'Montferrand-le-Château': 4825,
    'Montlebon': 4825,
    'Montmorot': 4825,
    'Montpont-en-Bresse': 4825,
    'Montreux-Château': 4825,
    'Morbier': 4825,
    'Morez': 4825,
    'Morre': 4825,
    'Morteau': 4825,
    'Morvillars': 4825,
    'Mouchard': 4825,
    'Moulins-Engilbert': 4825,
    'Nancray': 4825,
    'Navenne': 4825,
    'Neuilly-lès-Dijon': 4825,
    'Neuvy-Sautour': 4825,
    'Neuvy-sur-Loire': 4825,
    'Nevers': 4825,
    'Noidans-lès-Vesoul': 4825,
    'Nolay': 4825,
    'Nommay': 4825,
    'Novillars': 4825,
    'Nuits-Saint-Georges': 4825,
    'Offemont': 4825,
    'Orchamps': 4825,
    'Orchamps-Vennes': 4825,
    'Orgelet': 4825,
    'Ornans': 4825,
    'Ouges': 4825,
    'Ouroux-sur-Saône': 4825,
    'Palinges': 4825,
    'Paray-le-Monial': 4825,
    'Paron': 4825,
    'Pelousey': 4825,
    'Perrecy-les-Forges': 4825,
    'Perrigny': 4825,
    'Perrigny-lès-Dijon': 4825,
    'Pesmes': 4825,
    'Petit-Noir': 4825,
    'Pierre-de-Bresse': 4825,
    'Pierrefontaine-les-Varans': 4825,
    'Pirey': 4825,
    'Plancher-Bas': 4825,
    'Plancher-les-Mines': 4825,
    'Plombières-lès-Dijon': 4825,
    'Poligny': 4825,
    'Pont-de-Roide': 4825,
    'Pont-sur-Yonne': 4825,
    'Pontailler-sur-Saône': 4825,
    'Pontarlier': 4825,
    'Port-sur-Saône': 4825,
    'Pougues-les-Eaux': 4825,
    'Pouilley-les-Vignes': 4825,
    'Pouilly-en-Auxois': 4825,
    'Pouilly-sur-Loire': 4825,
    'Pourrain': 4825,
    'Prémery': 4825,
    'Prissé': 4825,
    'Pusey': 4825,
    'Quetigny': 4825,
    'Quincey': 4825,
    'Quingey': 4825,
    'Ravières': 4825,
    'Rioz': 4825,
    'Roche-lez-Beaupré': 4825,
    'Romanèche-Thorins': 4825,
    'Romenay': 4825,
    'Ronchamp': 4825,
    'Rougemont': 4825,
    'Rougemont-le-Château': 4825,
    'Roulans': 4825,
    'Roye': 4825,
    'Ruffey-lès-Echirey': 4825,
    'Rully': 4825,
    'Sagy': 4825,
    'Saint-Amand-en-Puisaye': 4825,
    'Saint-Amour': 4825,
    'Saint-Apollinaire': 4825,
    'Saint-Aubin': 4825,
    'Saint-Benin-d’Azy': 4825,
    'Saint-Berain-sous-Sanvignes': 4825,
    'Saint-Bris-le-Vineux': 4825,
    'Saint-Claude': 4825,
    'Saint-Clément': 4825,
    'Saint-Éloi': 4825,
    'Saint-Eusèbe': 4825,
    'Saint-Fargeau': 4825,
    'Saint-Florentin': 4825,
    'Saint-Gengoux-le-National': 4825,
    'Saint-Georges-sur-Baulche': 4825,
    'Saint-Germain': 4825,
    'Saint-Germain-du-Bois': 4825,
    'Saint-Germain-du-Plain': 4825,
    'Saint-Hippolyte': 4825,
    'Saint-Jean-de-Losne': 4825,
    'Saint-Julien': 4825,
    'Saint-Julien-du-Sault': 4825,
    'Saint-Laurent-en-Grandvaux': 4825,
    'Saint-Léger-des-Vignes': 4825,
    'Saint-Léger-sur-Dheune': 4825,
    'Saint-Leu': 4825,
    'Saint-Loup-sur-Semouse': 4825,
    'Saint-Lupicin': 4825,
    'Saint-Marcel': 4825,
    'Saint-Martin-Belle-Roche': 4825,
    'Saint-Martin-du-Tertre': 4825,
    'Saint-Martin-en-Bresse': 4825,
    'Saint-Parize-le-Châtel': 4825,
    'Saint-Pierre-le-Moûtier': 4825,
    'Saint-Rémy': 4825,
    'Saint-Sauveur': 4825,
    'Saint-Sernin-du-Bois': 4825,
    'Saint-Usage': 4825,
    'Saint-Usuge': 4825,
    'Saint-Valérien': 4825,
    'Saint-Vallier': 4825,
    'Saint-Vit': 4825,
    'Saint-Yan': 4825,
    'Sainte-Suzanne': 4825,
    'Salbert': 4825,
    'Salins-les-Bains': 4825,
    'Sancé': 4825,
    'Sancey-le-Grand': 4825,
    'Sanvignes-les-Mines': 4825,
    'Saône': 4825,
    'Sassenay': 4825,
    'Saulieu': 4825,
    'Sauvigny-les-Bois': 4825,
    'Savigny-lès-Beaune': 4825,
    'Scey-sur-Saône-et-Saint-Albin': 4825,
    'Seignelay': 4825,
    'Seloncourt': 4825,
    'Selongey': 4825,
    'Semur-en-Auxois': 4825,
    'Sennecey-le-Grand': 4825,
    'Sennecey-lès-Dijon': 4825,
    'Sens': 4825,
    'Sergines': 4825,
    'Sermoise-sur-Loire': 4825,
    'Serre-les-Sapins': 4825,
    'Seurre': 4825,
    'Sevrey': 4825,
    'Simandre': 4825,
    'Sochaux': 4825,
    'Sornay': 4825,
    'Soucy': 4825,
    'Taillecourt': 4825,
    'Talant': 4825,
    'Tanlay': 4825,
    'Tavaux': 4825,
    'Territoire de Belfort': 4825,
    'Thise': 4825,
    'Thorigny-sur-Oreuse': 4825,
    'Tonnerre': 4825,
    'Torcy': 4825,
    'Toucy': 4825,
    'Toulon-sur-Arroux': 4825,
    'Tournus': 4825,
    'Urzy': 4825,
    'Vaivre-et-Montoille': 4825,
    'Valdahon': 4825,
    'Valdoie': 4825,
    'Valentigney': 4825,
    'Valfin-lès-Saint-Claude': 4825,
    'Varennes-le-Grand': 4825,
    'Varennes-Saint-Sauveur': 4825,
    'Varennes-Vauzelles': 4825,
    'Varois-et-Chaignot': 4825,
    'Varzy': 4825,
    'Velars-sur-Ouche': 4825,
    'Venarey-les-Laumes': 4825,
    'Venoy': 4825,
    'Vercel-Villedieu-le-Camp': 4825,
    'Verdun-sur-le-Doubs': 4825,
    'Vergigny': 4825,
    'Vermenton': 4825,
    'Véron': 4825,
    'Vesoul': 4825,
    'Vieux-Charmont': 4825,
    'Villeblevin': 4825,
    'Villeneuve-la-Guyard': 4825,
    'Villeneuve-sur-Yonne': 4825,
    'Villers-le-Lac': 4825,
    'Villersexel': 4825,
    'Vinneuf': 4825,
    'Virey-le-Grand': 4825,
    'Vitteaux': 4825,
    'Voujeaucourt': 4825,
    'Acigné': 4807,
    'Allaire': 4807,
    'Amanlis': 4807,
    'Ambon': 4807,
    'Antrain': 4807,
    'Argentré-du-Plessis': 4807,
    'Arradon': 4807,
    'Arzano': 4807,
    'Arzon': 4807,
    'Audierne': 4807,
    'Augan': 4807,
    'Auray': 4807,
    'Baden': 4807,
    'Baguer-Morvan': 4807,
    'Baguer-Pican': 4807,
    'Bain-de-Bretagne': 4807,
    'Bains-sur-Oust': 4807,
    'Bais': 4807,
    'Balazé': 4807,
    'Bannalec': 4807,
    'Baud': 4807,
    'Baulon': 4807,
    'Bazouges-la-Pérouse': 4807,
    'Beaucé': 4807,
    'Bédée': 4807,
    'Béganne': 4807,
    'Bégard': 4807,
    'Beignon': 4807,
    'Belle-Isle-en-Terre': 4807,
    'Belz': 4807,
    'Bénodet': 4807,
    'Berné': 4807,
    'Berric': 4807,
    'Berrien': 4807,
    'Betton': 4807,
    'Beuzec-Cap-Sizun': 4807,
    'Bignan': 4807,
    'Binic': 4807,
    'Bodilis': 4807,
    'Bohars': 4807,
    'Boisgervilly': 4807,
    'Bonnemain': 4807,
    'Bourbriac': 4807,
    'Bourg-Blanc': 4807,
    'Bourg-des-Comptes': 4807,
    'Bourgbarré': 4807,
    'Brandérion': 4807,
    'Brasparts': 4807,
    'Bréal-sous-Montfort': 4807,
    'Brécé': 4807,
    'Brech': 4807,
    'Bréhand': 4807,
    'Brest': 4807,
    'Breteil': 4807,
    'Briec': 4807,
    'Broons': 4807,
    'Bruz': 4807,
    'Bubry': 4807,
    'Caden': 4807,
    'Callac': 4807,
    'Camaret-sur-Mer': 4807,
    'Camors': 4807,
    'Campénéac': 4807,
    'Cancale': 4807,
    'Carantec': 4807,
    'Carentoir': 4807,
    'Carhaix-Plouguer': 4807,
    'Carnac': 4807,
    'Caro': 4807,
    'Cast': 4807,
    'Caudan': 4807,
    'Caulnes': 4807,
    'Cavan': 4807,
    'Cesson-Sévigné': 4807,
    'Chanteloup': 4807,
    'Chantepie': 4807,
    'Chartres-de-Bretagne': 4807,
    'Chasné-sur-Illet': 4807,
    'Châteaubourg': 4807,
    'Châteaugiron': 4807,
    'Châteaulin': 4807,
    'Châteauneuf-d’Ille-et-Vilaine': 4807,
    'Châteauneuf-du-Faou': 4807,
    'Châtillon-en-Vendelais': 4807,
    'Châtillon-sur-Seiche': 4807,
    'Chavagne': 4807,
    'Cherrueix': 4807,
    'Chevaigné': 4807,
    'Cintré': 4807,
    'Cléden-Poher': 4807,
    'Cléder': 4807,
    'Cléguer': 4807,
    'Cléguérec': 4807,
    'Clohars-Carnoët': 4807,
    'Clohars-Fouesnant': 4807,
    'Coësmes': 4807,
    'Coëtmieux': 4807,
    'Colpo': 4807,
    'Combourg': 4807,
    'Combrit': 4807,
    'Concarneau': 4807,
    'Coray': 4807,
    'Corlay': 4807,
    'Corps-Nuds': 4807,
    'Corseul': 4807,
    'Crach': 4807,
    'Crédin': 4807,
    'Créhen': 4807,
    'Crevin': 4807,
    'Crozon': 4807,
    'Damgan': 4807,
    'Daoulas': 4807,
    'Département d  Ille-et-Vilaine': 4807,
    'Département des Côtes-d’Armor': 4807,
    'Département du Finistère': 4807,
    'Dinan': 4807,
    'Dinard': 4807,
    'Dinéault': 4807,
    'Dingé': 4807,
    'Dirinon': 4807,
    'Dol-de-Bretagne': 4807,
    'Domagné': 4807,
    'Domalain': 4807,
    'Douarnenez': 4807,
    'Edern': 4807,
    'Elliant': 4807,
    'Elven': 4807,
    'Epiniac': 4807,
    'Erbrée': 4807,
    'Ercé-en-Lamée': 4807,
    'Ercé-près-Liffré': 4807,
    'Erdeven': 4807,
    'Ergué-Gabéric': 4807,
    'Erquy': 4807,
    'Esquibien': 4807,
    'Étables-sur-Mer': 4807,
    'Étel': 4807,
    'Étrelles': 4807,
    'Évran': 4807,
    'Férel': 4807,
    'Fouesnant': 4807,
    'Fougères': 4807,
    'Fouillard': 4807,
    'Fréhel': 4807,
    'Gaël': 4807,
    'Gestel': 4807,
    'Gévezé': 4807,
    'Glomel': 4807,
    'Gosné': 4807,
    'Gouarec': 4807,
    'Goudelin': 4807,
    'Gouesnach': 4807,
    'Gouesnou': 4807,
    'Gouézec': 4807,
    'Gourin': 4807,
    'Goven': 4807,
    'Grâces': 4807,
    'Grand-Champ': 4807,
    'Grand-Fougeray': 4807,
    'Groix': 4807,
    'Guégon': 4807,
    'Guémené-sur-Scorff': 4807,
    'Guengat': 4807,
    'Guénin': 4807,
    'Guer': 4807,
    'Guerlesquin': 4807,
    'Guern': 4807,
    'Guichen': 4807,
    'Guiclan': 4807,
    'Guidel-Plage': 4807,
    'Guignen': 4807,
    'Guilers': 4807,
    'Guillac': 4807,
    'Guilliers': 4807,
    'Guilvinec': 4807,
    'Guingamp': 4807,
    'Guipavas': 4807,
    'Guipel': 4807,
    'Guipry': 4807,
    'Guiscriff': 4807,
    'Guissény': 4807,
    'Hanvec': 4807,
    'Hédé-Bazouges': 4807,
    'Hénanbihen': 4807,
    'Hennebont': 4807,
    'Hénon': 4807,
    'Henvic': 4807,
    'Hillion': 4807,
    'Hirel': 4807,
    'Hôpital-Camfrout': 4807,
    'Huelgoat': 4807,
    'Iffendic': 4807,
    'Inguiniel': 4807,
    'Irodouër': 4807,
    'Irvillac': 4807,
    'Janzé': 4807,
    'Javené': 4807,
    'Josselin': 4807,
    'Jugon-les-Lacs': 4807,
    'Kerlouan': 4807,
    'Kernilis': 4807,
    'Kersaint-Plabennec': 4807,
    'Kervignac': 4807,
    'La Bouëxière': 4807,
    'La Boussac': 4807,
    'La Chapelle-des-Fougeretz': 4807,
    'La Chapelle-Janson': 4807,
    'La Chapelle-Thouarault': 4807,
    'La Dominelais': 4807,
    'La Forest-Landerneau': 4807,
    'La Forêt-Fouesnant': 4807,
    'La Fresnais': 4807,
    'La Gacilly': 4807,
    'La Gouesnière': 4807,
    'La Guerche-de-Bretagne': 4807,
    'La Méaugon': 4807,
    'La Mézière': 4807,
    'La Motte': 4807,
    'La Richardais': 4807,
    'La Roche-Derrien': 4807,
    'La Roche-Maurice': 4807,
    'La Trinité-sur-Mer': 4807,
    'La Vraie-Croix': 4807,
    'Laillé': 4807,
    'Lamballe': 4807,
    'Lampaul-Guimiliau': 4807,
    'Lampaul-Plouarzel': 4807,
    'Lancieux': 4807,
    'Landaul': 4807,
    'Landéan': 4807,
    'Landéda': 4807,
    'Landéhen': 4807,
    'Landeleau': 4807,
    'Landerneau': 4807,
    'Landévant': 4807,
    'Landivisiau': 4807,
    'Landrévarzec': 4807,
    'Landudec': 4807,
    'Landunvez': 4807,
    'Lanester': 4807,
    'Langoat': 4807,
    'Langon': 4807,
    'Langonnet': 4807,
    'Langueux': 4807,
    'Languidic': 4807,
    'Lanmeur': 4807,
    'Lannilis': 4807,
    'Lannion': 4807,
    'Lanouée': 4807,
    'Lanrivoaré': 4807,
    'Lanrodec': 4807,
    'Lantic': 4807,
    'Lanvallay': 4807,
    'Lanvénégen': 4807,
    'Lanvéoc': 4807,
    'Lanvollon': 4807,
    'Larmor-Baden': 4807,
    'Larmor-Plage': 4807,
    'Lassy': 4807,
    'Le Bono': 4807,
    'Le Conquet': 4807,
    'Le Drennec': 4807,
    'Le Faou': 4807,
    'Le Faouët': 4807,
    'Le Fœil': 4807,
    'Le Folgoët': 4807,
    'Le Minihic-sur-Rance': 4807,
    'Le Palais': 4807,
    'Le Pertre': 4807,
    'Le Relecq-Kerhuon': 4807,
    'Le Rheu': 4807,
    'Le Sourn': 4807,
    'Le Theil-de-Bretagne': 4807,
    'Le Trévoux': 4807,
    'Le Vieux-Marché': 4807,
    'Le Vivier-sur-Mer': 4807,
    'Lécousse': 4807,
    'Léhon': 4807,
    'Les Champs-Géraux': 4807,
    'Lesneven': 4807,
    'Lézardrieux': 4807,
    'Liffré': 4807,
    'Limerzel': 4807,
    'Livré-sur-Changeon': 4807,
    'Locmariaquer': 4807,
    'Locminé': 4807,
    'Locmiquélic': 4807,
    'Locoal-Mendon': 4807,
    'Locquémeau': 4807,
    'Locquirec': 4807,
    'Loctudy': 4807,
    'Logonna-Daoulas': 4807,
    'Lopérec': 4807,
    'Loperhet': 4807,
    'Lorient': 4807,
    'Louannec': 4807,
    'Louargat': 4807,
    'Loudéac': 4807,
    'Louvigné-de-Bais': 4807,
    'Louvigné-du-Désert': 4807,
    'Loyat': 4807,
    'Luitré': 4807,
    'Maël-Carhaix': 4807,
    'Malansac': 4807,
    'Malestroit': 4807,
    'Malguénac': 4807,
    'Martigné-Ferchaud': 4807,
    'Marzan': 4807,
    'Matignon': 4807,
    'Maure-de-Bretagne': 4807,
    'Mauron': 4807,
    'Maxent': 4807,
    'Médréac': 4807,
    'Meillac': 4807,
    'Melesse': 4807,
    'Melgven': 4807,
    'Mellac': 4807,
    'Melrand': 4807,
    'Ménéac': 4807,
    'Merdrignac': 4807,
    'Merlevenez': 4807,
    'Meslan': 4807,
    'Messac': 4807,
    'Meucon': 4807,
    'Milizac': 4807,
    'Miniac-Morvan': 4807,
    'Minihy-Tréguier': 4807,
    'Moëlan-sur-Mer': 4807,
    'Molac': 4807,
    'Montauban-de-Bretagne': 4807,
    'Monterblanc': 4807,
    'Monterfil': 4807,
    'Montfort-sur-Meu': 4807,
    'Montgermont': 4807,
    'Montreuil-le-Gast': 4807,
    'Montreuil-sous-Pérouse': 4807,
    'Montreuil-sur-Ille': 4807,
    'Morbihan': 4807,
    'Mordelles': 4807,
    'Moréac': 4807,
    'Morlaix': 4807,
    'Moustoir-Ac': 4807,
    'Mûr-de-Bretagne': 4807,
    'Muzillac': 4807,
    'Naizin': 4807,
    'Neulliac': 4807,
    'Névez': 4807,
    'Nivillac': 4807,
    'Nostang': 4807,
    'Nouvoitou': 4807,
    'Noyal-Muzillac': 4807,
    'Noyal-Pontivy': 4807,
    'Noyal-sur-Vilaine': 4807,
    'Orgères': 4807,
    'Ossé': 4807,
    'Pabu': 4807,
    'Pacé': 4807,
    'Paimpol': 4807,
    'Paimpont': 4807,
    'Pancé': 4807,
    'Parigné': 4807,
    'Péaule': 4807,
    'Pédernec': 4807,
    'Peillac': 4807,
    'Pencran': 4807,
    'Pénestin': 4807,
    'Penvénan': 4807,
    'Perros-Guirec': 4807,
    'Pipriac': 4807,
    'Piré-sur-Seiche': 4807,
    'Plabennec': 4807,
    'Plaine-Haute': 4807,
    'Plaintel': 4807,
    'Plancoët': 4807,
    'Planguenoual': 4807,
    'Plaudren': 4807,
    'Pléchâtel': 4807,
    'Plédéliac': 4807,
    'Plédran': 4807,
    'Pléguien': 4807,
    'Pléhédel': 4807,
    'Pleine-Fougères': 4807,
    'Plélan-le-Grand': 4807,
    'Plélan-le-Petit': 4807,
    'Plélo': 4807,
    'Plémet': 4807,
    'Plémy': 4807,
    'Plénée-Jugon': 4807,
    'Pléneuf-Val-André': 4807,
    'Plerguer': 4807,
    'Plérin': 4807,
    'Plescop': 4807,
    'Pleslin-Trigavou': 4807,
    'Plessala': 4807,
    'Plestan': 4807,
    'Plestin-les-Grèves': 4807,
    'Pleubian': 4807,
    'Pleucadeuc': 4807,
    'Pleudaniel': 4807,
    'Pleudihen-sur-Rance': 4807,
    'Pleugriffet': 4807,
    'Pleugueneuc': 4807,
    'Pleumeleuc': 4807,
    'Pleumeur-Bodou': 4807,
    'Pleumeur-Gautier': 4807,
    'Pleurtuit': 4807,
    'Pleuven': 4807,
    'Pleyben': 4807,
    'Pleyber-Christ': 4807,
    'Plobannalec-Lesconil': 4807,
    'Ploemel': 4807,
    'Ploemeur': 4807,
    'Ploërdut': 4807,
    'Ploeren': 4807,
    'Ploërmel': 4807,
    'Plœuc-sur-Lié': 4807,
    'Ploëzal': 4807,
    'Plogastel-Saint-Germain': 4807,
    'Plogoff': 4807,
    'Plogonnec': 4807,
    'Plomelin': 4807,
    'Plomeur': 4807,
    'Plomodiern': 4807,
    'Plonéis': 4807,
    'Plonéour-Lanvern': 4807,
    'Plonévez-du-Faou': 4807,
    'Plonévez-Porzay': 4807,
    'Plouagat': 4807,
    'Plouaret': 4807,
    'Plouarzel': 4807,
    'Plouasne': 4807,
    'Plouay': 4807,
    'Ploubalay': 4807,
    'Ploubazlanec': 4807,
    'Ploubezre': 4807,
    'Ploudalmézeau': 4807,
    'Ploudaniel': 4807,
    'Plouëc-du-Trieux': 4807,
    'Plouédern': 4807,
    'Plouénan': 4807,
    'Plouër-sur-Rance': 4807,
    'Plouescat': 4807,
    'Plouézec': 4807,
    'Plouezoc  h': 4807,
    'Ploufragan': 4807,
    'Plougasnou': 4807,
    'Plougastel-Daoulas': 4807,
    'Plougonvelin': 4807,
    'Plougonven': 4807,
    'Plougoulm': 4807,
    'Plougoumelen': 4807,
    'Plougourvest': 4807,
    'Plougrescant': 4807,
    'Plouguenast': 4807,
    'Plouguerneau': 4807,
    'Plouguernével': 4807,
    'Plouguiel': 4807,
    'Plouguin': 4807,
    'Plouha': 4807,
    'Plouharnel': 4807,
    'Plouhinec': 4807,
    'Plouider': 4807,
    'Plouigneau': 4807,
    'Plouisy': 4807,
    'Ploumagoar': 4807,
    'Ploumilliau': 4807,
    'Ploumoguer': 4807,
    'Plounéour-Ménez': 4807,
    'Plounéour-Trez': 4807,
    'Plounéventer': 4807,
    'Plounévez-Lochrist': 4807,
    'Plounévez-Moëdec': 4807,
    'Plounévez-Quintin': 4807,
    'Plounévézel': 4807,
    'Plouray': 4807,
    'Plourin-lès-Morlaix': 4807,
    'Plourivo': 4807,
    'Plouvien': 4807,
    'Plouvorn': 4807,
    'Plouzané': 4807,
    'Plouzévédé': 4807,
    'Plozévet': 4807,
    'Pluduno': 4807,
    'Pluguffan': 4807,
    'Pluherlin': 4807,
    'Plumaugat': 4807,
    'Plumelec': 4807,
    'Pluméliau': 4807,
    'Plumelin': 4807,
    'Plumergat': 4807,
    'Plumieux': 4807,
    'Pluneret': 4807,
    'Plurien': 4807,
    'Pluvigner': 4807,
    'Pluzunet': 4807,
    'Pocé-les-Bois': 4807,
    'Pommeret': 4807,
    'Pommerit-Jaudy': 4807,
    'Pommerit-le-Vicomte': 4807,
    'Pont-Aven': 4807,
    'Pont-Croix': 4807,
    'Pont-l’Abbé': 4807,
    'Pont-Scorff': 4807,
    'Pontivy': 4807,
    'Pontrieux': 4807,
    'Pordic': 4807,
    'Porspoder': 4807,
    'Port-Louis': 4807,
    'Pouldergat': 4807,
    'Pouldreuzic': 4807,
    'Poullan-sur-Mer': 4807,
    'Poullaouen': 4807,
    'Prat': 4807,
    'Priziac': 4807,
    'Québriac': 4807,
    'Quédillac': 4807,
    'Quéménéven': 4807,
    'Quemper-Guézennec': 4807,
    'Querrien': 4807,
    'Quessoy': 4807,
    'Questembert': 4807,
    'Quéven': 4807,
    'Quévert': 4807,
    'Quiberon': 4807,
    'Quimper': 4807,
    'Quimperlé': 4807,
    'Quintin': 4807,
    'Quistinic': 4807,
    'Rannée': 4807,
    'Rd pt des Chataigniers, Rte de Milizac, Guilers, Finistère, France': 4807,
    'Rédené': 4807,
    'Redon': 4807,
    'Réguiny': 4807,
    'Rennes': 4807,
    'Retiers': 4807,
    'Riantec': 4807,
    'Riec-sur-Belon': 4807,
    'Rieux': 4807,
    'Rohan': 4807,
    'Romagné': 4807,
    'Romillé': 4807,
    'Roscanvel': 4807,
    'Roscoff': 4807,
    'Rospez': 4807,
    'Rosporden': 4807,
    'Rostrenen': 4807,
    'Roz-Landrieux': 4807,
    'Roz-sur-Couesnon': 4807,
    'Ruffiac': 4807,
    'Saint-Agathon': 4807,
    'Saint-Alban': 4807,
    'Saint-Armel': 4807,
    'Saint-Aubin-du-Cormier': 4807,
    'Saint-Avé': 4807,
    'Saint-Barnabé': 4807,
    'Saint-Brandan': 4807,
    'Saint-Briac-sur-Mer': 4807,
    'Saint-Brice-en-Coglès': 4807,
    'Saint-Brieuc': 4807,
    'Saint-Broladre': 4807,
    'Saint-Caradec': 4807,
    'Saint-Carreuc': 4807,
    'Saint-Cast-le-Guildo': 4807,
    'Saint-Coulomb': 4807,
    'Saint-Didier': 4807,
    'Saint-Dolay': 4807,
    'Saint-Domineuc': 4807,
    'Saint-Donan': 4807,
    'Saint-Erblon': 4807,
    'Saint-Étienne-en-Coglès': 4807,
    'Saint-Évarzec': 4807,
    'Saint-Georges-de-Reintembault': 4807,
    'Saint-Germain-en-Coglès': 4807,
    'Saint-Gildas-de-Rhuys': 4807,
    'Saint-Gilles': 4807,
    'Saint-Gonnery': 4807,
    'Saint-Grégoire': 4807,
    'Saint-Hélen': 4807,
    'Saint-Jacques-de-la-Lande': 4807,
    'Saint-Jacut-les-Pins': 4807,
    'Saint-Jean-Brévelay': 4807,
    'Saint-Jean-la-Poterie': 4807,
    'Saint-Jean-sur-Couesnon': 4807,
    'Saint-Jouan-des-Guérets': 4807,
    'Saint-Julien': 4807,
    'Saint-Just': 4807,
    'Saint-Lunaire': 4807,
    'Saint-Malo': 4807,
    'Saint-Marc-le-Blanc': 4807,
    'Saint-Martin-des-Champs': 4807,
    'Saint-Martin-sur-Oust': 4807,
    'Saint-Médard-sur-Ille': 4807,
    'Saint-Méen-le-Grand': 4807,
    'Saint-Méloir-des-Ondes': 4807,
    'Saint-Nicolas-du-Pélem': 4807,
    'Saint-Nolff': 4807,
    'Saint-Ouen-des-Alleux': 4807,
    'Saint-Pabu': 4807,
    'Saint-Père': 4807,
    'Saint-Perreux': 4807,
    'Saint-Philibert': 4807,
    'Saint-Pierre-de-Plesguen': 4807,
    'Saint-Pierre-Quiberon': 4807,
    'Saint-Pol-de-Léon': 4807,
    'Saint-Quay-Perros': 4807,
    'Saint-Quay-Portrieux': 4807,
    'Saint-Renan': 4807,
    'Saint-Samson-sur-Rance': 4807,
    'Saint-Sauveur-des-Landes': 4807,
    'Saint-Senoux': 4807,
    'Saint-Sulpice-la-Forêt': 4807,
    'Saint-Thégonnec': 4807,
    'Saint-Thonan': 4807,
    'Saint-Thurial': 4807,
    'Saint-Thuriau': 4807,
    'Saint-Urbain': 4807,
    'Saint-Vincent-sur-Oust': 4807,
    'Saint-Yvi': 4807,
    'Sainte-Anne-d  Auray': 4807,
    'Sainte-Hélène': 4807,
    'Sainte-Marie': 4807,
    'Santec': 4807,
    'Sarzeau': 4807,
    'Scaër': 4807,
    'Séné': 4807,
    'Sens-de-Bretagne': 4807,
    'Sérent': 4807,
    'Servon-sur-Vilaine': 4807,
    'Sévignac': 4807,
    'Sibiril': 4807,
    'Sixt-sur-Aff': 4807,
    'Sizun': 4807,
    'Spézet': 4807,
    'Sulniac': 4807,
    'Surzur': 4807,
    'Taden': 4807,
    'Talensac': 4807,
    'Taulé': 4807,
    'Taupont': 4807,
    'Telgruc-sur-Mer': 4807,
    'Theix': 4807,
    'Tinténiac': 4807,
    'Tonquédec': 4807,
    'Torcé': 4807,
    'Trébeurden': 4807,
    'Trédarzec': 4807,
    'Trédrez-Locquémeau': 4807,
    'Treffiagat': 4807,
    'Treffléan': 4807,
    'Trégastel': 4807,
    'Tréguier': 4807,
    'Trégunc': 4807,
    'Trélévern': 4807,
    'Trélivan': 4807,
    'Tremblay': 4807,
    'Tréméven': 4807,
    'Trémuson': 4807,
    'Tresbœuf': 4807,
    'Trévé': 4807,
    'Trévou-Tréguignec': 4807,
    'Vannes': 4807,
    'Vern-sur-Seiche': 4807,
    'Vezin-le-Coquet': 4807,
    'Vignoc': 4807,
    'Vitré': 4807,
    'Yffiniac': 4807,
    'Yvignac-la-Tour': 4807,
    'Abilly': 4818,
    'Abondant': 4818,
    'Aigurande': 4818,
    'Ambillou': 4818,
    'Amboise': 4818,
    'Amilly': 4818,
    'Anet': 4818,
    'Ardentes': 4818,
    'Argent-sur-Sauldre': 4818,
    'Argenton-sur-Creuse': 4818,
    'Armenonville-les-Gâtineaux': 4818,
    'Arrou': 4818,
    'Artannes-sur-Indre': 4818,
    'Artenay': 4818,
    'Arthon': 4818,
    'Aschères-le-Marché': 4818,
    'Athée-sur-Cher': 4818,
    'Aubigny-sur-Nère': 4818,
    'Aunay-sous-Auneau': 4818,
    'Auneau': 4818,
    'Authon-du-Perche': 4818,
    'Autry-le-Châtel': 4818,
    'Auzouer-en-Touraine': 4818,
    'Avoine': 4818,
    'Avord': 4818,
    'Azay-le-Ferron': 4818,
    'Azay-le-Rideau': 4818,
    'Azay-sur-Cher': 4818,
    'Azé': 4818,
    'Bailleau-l’Évêque': 4818,
    'Bailleau-le-Pin': 4818,
    'Ballan-Miré': 4818,
    'Barjouville': 4818,
    'Baugy': 4818,
    'Baule': 4818,
    'Bazoches-les-Gallerandes': 4818,
    'Beaugency': 4818,
    'Beaulieu-lès-Loches': 4818,
    'Beaumont-en-Véron': 4818,
    'Beaumont-la-Ronce': 4818,
    'Beaune-la-Rolande': 4818,
    'Bélâbre': 4818,
    'Bellegarde': 4818,
    'Belleville-sur-Loire': 4818,
    'Béville-le-Comte': 4818,
    'Blancafort': 4818,
    'Bléré': 4818,
    'Blois': 4818,
    'Boigny-sur-Bionne': 4818,
    'Boiscommun': 4818,
    'Bonneval': 4818,
    'Bonny-sur-Loire': 4818,
    'Boulleret': 4818,
    'Bourges': 4818,
    'Bourgueil': 4818,
    'Bouzy-la-Forêt': 4818,
    'Boynes': 4818,
    'Bracieux': 4818,
    'Bray-en-Val': 4818,
    'Brezolles': 4818,
    'Briare': 4818,
    'Bricy': 4818,
    'Brinon-sur-Sauldre': 4818,
    'Brou': 4818,
    'Bû': 4818,
    'Buzançais': 4818,
    'Candé-sur-Beuvron': 4818,
    'Cangey': 4818,
    'Cellettes': 4818,
    'Cepoy': 4818,
    'Cerdon': 4818,
    'Cerelles': 4818,
    'Chabris': 4818,
    'Chaillac': 4818,
    'Chailles': 4818,
    'Chaingy': 4818,
    'Châlette-sur-Loing': 4818,
    'Chambourg-sur-Indre': 4818,
    'Chambray-lès-Tours': 4818,
    'Champhol': 4818,
    'Chanceaux-sur-Choisille': 4818,
    'Changy-les-Bois': 4818,
    'Chanteau': 4818,
    'Chantôme': 4818,
    'Charentilly': 4818,
    'Charenton-du-Cher': 4818,
    'Chargé': 4818,
    'Chârost': 4818,
    'Chartres': 4818,
    'Château-la-Vallière': 4818,
    'Château-Renard': 4818,
    'Château-Renault': 4818,
    'Châteaudun': 4818,
    'Châteaumeillant': 4818,
    'Châteauneuf-en-Thymerais': 4818,
    'Châteauneuf-sur-Cher': 4818,
    'Châteauneuf-sur-Loire': 4818,
    'Châteauroux': 4818,
    'Châtillon-Coligny': 4818,
    'Châtillon-sur-Cher': 4818,
    'Châtillon-sur-Indre': 4818,
    'Châtres-sur-Cher': 4818,
    'Chaudon': 4818,
    'Chaumont-sur-Loire': 4818,
    'Chaumont-sur-Tharonne': 4818,
    'Chécy': 4818,
    'Cheillé': 4818,
    'Cher': 4818,
    'Cherisy': 4818,
    'Cheverny': 4818,
    'Chevillon-sur-Huillard': 4818,
    'Chevilly': 4818,
    'Chilleurs-aux-Bois': 4818,
    'Chinon': 4818,
    'Chissay-en-Touraine': 4818,
    'Chitenay': 4818,
    'Chouzé-sur-Loire': 4818,
    'Chouzy-sur-Cisse': 4818,
    'Cinq-Mars-la-Pile': 4818,
    'Civray-de-Touraine': 4818,
    'Cléré-les-Pins': 4818,
    'Cléry-Saint-André': 4818,
    'Clion': 4818,
    'Cloyes-sur-le-Loir': 4818,
    'Cluis': 4818,
    'Contres': 4818,
    'Corbeilles': 4818,
    'Cormeray': 4818,
    'Cormery': 4818,
    'Corquilleroy': 4818,
    'Coullons': 4818,
    'Coulombs': 4818,
    'Cour-Cheverny': 4818,
    'Cours-les-Barres': 4818,
    'Courtenay': 4818,
    'Courville-sur-Eure': 4818,
    'Cuffy': 4818,
    'Dadonville': 4818,
    'Dammarie': 4818,
    'Dampierre-en-Burly': 4818,
    'Darvoy': 4818,
    'Déols': 4818,
    'Département d  Eure-et-Loir': 4818,
    'Département d  Indre-et-Loire': 4818,
    'Département de l  Indre': 4818,
    'Département du Loir-et-Cher': 4818,
    'Descartes': 4818,
    'Dhuizon': 4818,
    'Donnery': 4818,
    'Dordives': 4818,
    'Douchy': 4818,
    'Dreux': 4818,
    'Droué': 4818,
    'Droue-sur-Drouette': 4818,
    'Dry': 4818,
    'Dun-sur-Auron': 4818,
    'Écueillé': 4818,
    'Éguzon-Chantôme': 4818,
    'Épernon': 4818,
    'Épieds-en-Beauce': 4818,
    'Esvres': 4818,
    'Faverolles-sur-Cher': 4818,
    'Fay-aux-Loges': 4818,
    'Férolles': 4818,
    'Fleury-les-Aubrais': 4818,
    'Foëcy': 4818,
    'Fondettes': 4818,
    'Fontaine-la-Guyon': 4818,
    'Fontenay-sur-Loing': 4818,
    'Fussy': 4818,
    'Gallardon': 4818,
    'Garnay': 4818,
    'Gasville-Oisème': 4818,
    'Genillé': 4818,
    'Gidy': 4818,
    'Gien': 4818,
    'Gièvres': 4818,
    'Graçay': 4818,
    'Hanches': 4818,
    'Henrichemont': 4818,
    'Herbault': 4818,
    'Herry': 4818,
    'Huismes': 4818,
    'Huisseau-sur-Cosson': 4818,
    'Huisseau-sur-Mauves': 4818,
    'Ingré': 4818,
    'Issoudun': 4818,
    'Janville': 4818,
    'Jargeau': 4818,
    'Joué-lès-Tours': 4818,
    'Jouet-sur-l  Aubois': 4818,
    'Jouy': 4818,
    'Jouy-le-Potier': 4818,
    'L  Île-Bouchard': 4818,
    'La Bazoche-Gouet': 4818,
    'La Celle-Saint-Avant': 4818,
    'La Chapelle-du-Noyer': 4818,
    'La Chapelle-Saint-Mesmin': 4818,
    'La Chapelle-Saint-Ursin': 4818,
    'La Chapelle-sur-Loire': 4818,
    'La Châtre': 4818,
    'La Chaussée-Saint-Victor': 4818,
    'La Croix-en-Touraine': 4818,
    'La Ferté-Imbault': 4818,
    'La Ferté-Saint-Aubin': 4818,
    'La Loupe': 4818,
    'La Membrolle-sur-Choisille': 4818,
    'La Riche': 4818,
    'La Ville-aux-Clercs': 4818,
    'La Ville-aux-Dames': 4818,
    'Ladon': 4818,
    'Lailly-en-Val': 4818,
    'Lamotte-Beuvron': 4818,
    'Langeais': 4818,
    'Larçay': 4818,
    'Le Blanc': 4818,
    'Le Châtelet': 4818,
    'Le Coudray': 4818,
    'Le Grand-Pressigny': 4818,
    'Le Pêchereau': 4818,
    'Le Poinçonnet': 4818,
    'Léré': 4818,
    'Les Aix-d’Angillon': 4818,
    'Les Montils': 4818,
    'Lèves': 4818,
    'Levet': 4818,
    'Levroux': 4818,
    'Lignières': 4818,
    'Ligny-le-Ribault': 4818,
    'Ligré': 4818,
    'Ligueil': 4818,
    'Loches': 4818,
    'Loiret': 4818,
    'Lorris': 4818,
    'Loury': 4818,
    'Luant': 4818,
    'Luçay-le-Mâle': 4818,
    'Lucé': 4818,
    'Luisant': 4818,
    'Lunay': 4818,
    'Lunery': 4818,
    'Luray': 4818,
    'Luynes': 4818,
    'Maintenon': 4818,
    'Mainvilliers': 4818,
    'Malesherbes': 4818,
    'Manthelan': 4818,
    'Marboué': 4818,
    'Marcilly-en-Villette': 4818,
    'Mardié': 4818,
    'Mareau-aux-Prés': 4818,
    'Mareuil-sur-Cher': 4818,
    'Margon': 4818,
    'Marigny-les-Usages': 4818,
    'Marmagne': 4818,
    'Martizay': 4818,
    'Massay': 4818,
    'Mazières-de-Touraine': 4818,
    'Mehun-sur-Yèvre': 4818,
    'Ménestreau-en-Villette': 4818,
    'Menetou-Salon': 4818,
    'Mer': 4818,
    'Méreau': 4818,
    'Mettray': 4818,
    'Meung-sur-Loire': 4818,
    'Mézières-en-Brenne': 4818,
    'Mézières-en-Drouais': 4818,
    'Mondoubleau': 4818,
    'Monnaie': 4818,
    'Mont-près-Chambord': 4818,
    'Montargis': 4818,
    'Montbazon': 4818,
    'Montcresson': 4818,
    'Montgivray': 4818,
    'Montierchaume': 4818,
    'Montlivault': 4818,
    'Montlouis-sur-Loire': 4818,
    'Montoire-sur-le-Loir': 4818,
    'Montrichard': 4818,
    'Monts': 4818,
    'Morancez': 4818,
    'Morée': 4818,
    'Muides-sur-Loire': 4818,
    'Mur-de-Sologne': 4818,
    'Nargis': 4818,
    'Naveil': 4818,
    'Nazelles-Négron': 4818,
    'Nérondes': 4818,
    'Neuillé-Pont-Pierre': 4818,
    'Neung-sur-Beuvron': 4818,
    'Neuville-aux-Bois': 4818,
    'Neuvy-le-Roi': 4818,
    'Neuvy-Pailloux': 4818,
    'Neuvy-Saint-Sépulchre': 4818,
    'Neuvy-sur-Barangeon': 4818,
    'Nevoy': 4818,
    'Niherne': 4818,
    'Nogent-le-Phaye': 4818,
    'Nogent-le-Roi': 4818,
    'Nogent-le-Rotrou': 4818,
    'Nogent-sur-Vernisson': 4818,
    'Noizay': 4818,
    'Nouan-le-Fuzelier': 4818,
    'Nouzilly': 4818,
    'Noyers-sur-Cher': 4818,
    'Olivet': 4818,
    'Onzain': 4818,
    'Orgères-en-Beauce': 4818,
    'Orléans': 4818,
    'Ormes': 4818,
    'Orval': 4818,
    'Oucques': 4818,
    'Oulins': 4818,
    'Outarville': 4818,
    'Ouzouer-le-Marché': 4818,
    'Ouzouer-sur-Loire': 4818,
    'Ouzouer-sur-Trézée': 4818,
    'Pannes': 4818,
    'Parçay-Meslay': 4818,
    'Patay': 4818,
    'Perrusson': 4818,
    'Pierres': 4818,
    'Pithiviers': 4818,
    'Pithiviers-le-Vieil': 4818,
    'Plaimpied-Givaudins': 4818,
    'Pocé-sur-Cisse': 4818,
    'Poilly-lez-Gien': 4818,
    'Pontlevoy': 4818,
    'Pouligny-Saint-Pierre': 4818,
    'Preuilly-sur-Claise': 4818,
    'Pruniers-en-Sologne': 4818,
    'Puiseaux': 4818,
    'Quiers-sur-Bézonde': 4818,
    'Rebréchien': 4818,
    'Reignac-sur-Indre': 4818,
    'Restigné': 4818,
    'Reugny': 4818,
    'Reuilly': 4818,
    'Rians': 4818,
    'Richelieu': 4818,
    'Rochecorbon': 4818,
    'Romorantin-Lanthenay': 4818,
    'Rouziers-de-Touraine': 4818,
    'Saché': 4818,
    'Saint-Aignan': 4818,
    'Saint-Amand-Longpré': 4818,
    'Saint-Amand-Montrond': 4818,
    'Saint-Antoine-du-Rocher': 4818,
    'Saint-Aubin-des-Bois': 4818,
    'Saint-Avertin': 4818,
    'Saint-Ay': 4818,
    'Saint-Benoît-sur-Loire': 4818,
    'Saint-Branchs': 4818,
    'Saint-Brisson-sur-Loire': 4818,
    'Saint-Christophe-sur-le-Nais': 4818,
    'Saint-Claude-de-Diray': 4818,
    'Saint-Cyr-en-Val': 4818,
    'Saint-Cyr-sur-Loire': 4818,
    'Saint-Denis-en-Val': 4818,
    'Saint-Denis-les-Ponts': 4818,
    'Saint-Doulchard': 4818,
    'Saint-Dyé-sur-Loire': 4818,
    'Saint-Éloy-de-Gy': 4818,
    'Saint-Épain': 4818,
    'Saint-Étienne-de-Chigny': 4818,
    'Saint-Florent-sur-Cher': 4818,
    'Saint-Gaultier': 4818,
    'Saint-Georges-sur-Cher': 4818,
    'Saint-Georges-sur-Eure': 4818,
    'Saint-Germain-des-Prés': 4818,
    'Saint-Germain-du-Puy': 4818,
    'Saint-Gervais-la-Forêt': 4818,
    'Saint-Hilaire-Saint-Mesmin': 4818,
    'Saint-Jean-de-Braye': 4818,
    'Saint-Jean-de-la-Ruelle': 4818,
    'Saint-Jean-le-Blanc': 4818,
    'Saint-Laurent-Nouan': 4818,
    'Saint-Lubin-des-Joncherets': 4818,
    'Saint-Marcel': 4818,
    'Saint-Martin-d’Auxigny': 4818,
    'Saint-Martin-de-Nigelles': 4818,
    'Saint-Martin-le-Beau': 4818,
    'Saint-Martin-sur-Ocre': 4818,
    'Saint-Maur': 4818,
    'Saint-Maurice-sur-Fessard': 4818,
    'Saint-Nicolas-de-Bourgueil': 4818,
    'Saint-Ouen': 4818,
    'Saint-Paterne-Racan': 4818,
    'Saint-Père-sur-Loire': 4818,
    'Saint-Piat': 4818,
    'Saint-Pierre-des-Corps': 4818,
    'Saint-Prest': 4818,
    'Saint-Pryvé-Saint-Mesmin': 4818,
    'Saint-Rémy-sur-Avre': 4818,
    'Saint-Romain-sur-Cher': 4818,
    'Saint-Satur': 4818,
    'Saint-Sulpice-de-Pommeray': 4818,
    'Saint-Viâtre': 4818,
    'Sainte-Geneviève-des-Bois': 4818,
    'Sainte-Lizaigne': 4818,
    'Sainte-Maure-de-Touraine': 4818,
    'Sainte-Solange': 4818,
    'Salbris': 4818,
    'Sancerre': 4818,
    'Sancoins': 4818,
    'Sandillon': 4818,
    'Saran': 4818,
    'Sargé-sur-Braye': 4818,
    'Saussay': 4818,
    'Savigné-sur-Lathan': 4818,
    'Savigny-en-Sancerre': 4818,
    'Savigny-en-Véron': 4818,
    'Savigny-sur-Braye': 4818,
    'Savonnières': 4818,
    'Seigy': 4818,
    'Selles-Saint-Denis': 4818,
    'Selles-sur-Cher': 4818,
    'Semblançay': 4818,
    'Semoy': 4818,
    'Senonches': 4818,
    'Sermaises': 4818,
    'Soings-en-Sologne': 4818,
    'Sonzay': 4818,
    'Sorel-Moussel': 4818,
    'Sorigny': 4818,
    'Souesmes': 4818,
    'Sours': 4818,
    'Suèvres': 4818,
    'Sully-sur-Loire': 4818,
    'Tauxigny': 4818,
    'Tavers': 4818,
    'Theillay': 4818,
    'Thésée': 4818,
    'Thilouze': 4818,
    'Thiron Gardais': 4818,
    'Tigy': 4818,
    'Tournon-Saint-Martin': 4818,
    'Tours': 4818,
    'Toury': 4818,
    'Traînou': 4818,
    'Tréon': 4818,
    'Triguères': 4818,
    'Trouy': 4818,
    'Truyes': 4818,
    'Unverre': 4818,
    'Valençay': 4818,
    'Vasselay': 4818,
    'Vatan': 4818,
    'Veigné': 4818,
    'Vendœuvres': 4818,
    'Vendôme': 4818,
    'Vennecy': 4818,
    'Véretz': 4818,
    'Vernou-sur-Brenne': 4818,
    'Vernouillet': 4818,
    'Vert-en-Drouais': 4818,
    'Vienne-en-Val': 4818,
    'Vierzon': 4818,
    'Vignoux-sur-Barangeon': 4818,
    'Villandry': 4818,
    'Villebarou': 4818,
    'Villedieu-sur-Indre': 4818,
    'Villedômer': 4818,
    'Villefranche-sur-Cher': 4818,
    'Villemandeur': 4818,
    'Villemeux-sur-Eure': 4818,
    'Villiers-le-Morhier': 4818,
    'Villiers-sur-Loir': 4818,
    'Vimory': 4818,
    'Vineuil': 4818,
    'Vitry-aux-Loges': 4818,
    'Vouvray': 4818,
    'Vouzon': 4818,
    'Voves': 4818,
    'Yèvres': 4818,
    'Yzeures-sur-Creuse': 4818,
    'Afa': 4806,
    'Ajaccio': 4806,
    'Alata': 4806,
    'Albitreccia': 4806,
    'Aléria': 4806,
    'Appietto': 4806,
    'Bastia': 4806,
    'Biguglia': 4806,
    'Bonifacio': 4806,
    'Borgo': 4806,
    'Brando': 4806,
    'Calenzana': 4806,
    'Calvi': 4806,
    'Cargèse': 4806,
    'Cauro': 4806,
    'Cervione': 4806,
    'Corte': 4806,
    'Cuttoli-Corticchiato': 4806,
    'Département de la Corse-du-Sud': 4806,
    'Département de la Haute-Corse': 4806,
    'Figari': 4806,
    'Furiani': 4806,
    'Ghisonaccia': 4806,
    'Grosseto-Prugna': 4806,
    'L  Île-Rousse': 4806,
    'Linguizzetta': 4806,
    'Lucciana': 4806,
    'Lumio': 4806,
    'Monticello': 4806,
    'Morosaglia': 4806,
    'Oletta': 4806,
    'Olmeto': 4806,
    'Penta-di-Casinca': 4806,
    'Peri': 4806,
    'Pietranera': 4806,
    'Pietrosella': 4806,
    'Porto-Vecchio': 4806,
    'Propriano': 4806,
    'Prunelli-di-Fiumorbo': 4806,
    'Saint-Florent': 4806,
    'San-Martino-di-Lota': 4806,
    'San-Nicolao': 4806,
    'Santa-Lucia-di-Moriani': 4806,
    'Santa-Maria-di-Lota': 4806,
    'Sari-Solenzara': 4806,
    'Sarrola': 4806,
    'Sartène': 4806,
    'Sisco': 4806,
    'Ventiseri': 4806,
    'Vescovato': 4806,
    'Zonza': 4806,
    'Abreschviller': 4820,
    'Achenheim': 4820,
    'Aiglemont': 4820,
    'Aix-en-Othe': 4820,
    'Algolsheim': 4820,
    'Algrange': 4820,
    'Alsting': 4820,
    'Altkirch': 4820,
    'Altorf': 4820,
    'Amanvillers': 4820,
    'Ammerschwihr': 4820,
    'Amnéville': 4820,
    'Ancerville': 4820,
    'Ancy-sur-Moselle': 4820,
    'Andlau': 4820,
    'Andolsheim': 4820,
    'Angevillers': 4820,
    'Anould': 4820,
    'Arches': 4820,
    'Archettes': 4820,
    'Arcis-sur-Aube': 4820,
    'Ardennes': 4820,
    'Argancy': 4820,
    'Ars-sur-Moselle': 4820,
    'Art-sur-Meurthe': 4820,
    'Aspach': 4820,
    'Aspach-le-Bas': 4820,
    'Aspach-le-Haut': 4820,
    'Attigny': 4820,
    'Auboué': 4820,
    'Audun-le-Roman': 4820,
    'Audun-le-Tiche': 4820,
    'Augny': 4820,
    'Aumetz': 4820,
    'Avize': 4820,
    'Ay': 4820,
    'Ay-sur-Moselle': 4820,
    'Aydoilles': 4820,
    'Baccarat': 4820,
    'Badonviller': 4820,
    'Bains-les-Bains': 4820,
    'Bainville-sur-Madon': 4820,
    'Balan': 4820,
    'Baldersheim': 4820,
    'Ban-de-Laveline': 4820,
    'Bantzenheim': 4820,
    'Bar-le-Duc': 4820,
    'Bar-sur-Aube': 4820,
    'Bar-sur-Seine': 4820,
    'Barr': 4820,
    'Bartenheim': 4820,
    'Bas-Rhin': 4820,
    'Basse-Ham': 4820,
    'Batilly': 4820,
    'Battenheim': 4820,
    'Bayard-sur-Marne': 4820,
    'Bayon': 4820,
    'Bazancourt': 4820,
    'Bazeilles': 4820,
    'Behren-lès-Forbach': 4820,
    'Belleville': 4820,
    'Belleville-sur-Meuse': 4820,
    'Benfeld': 4820,
    'Béning-lès-Saint-Avold': 4820,
    'Bennwihr': 4820,
    'Bergheim': 4820,
    'Bergholtz': 4820,
    'Bernardswiller': 4820,
    'Berrwiller': 4820,
    'Berstett': 4820,
    'Bertrange': 4820,
    'Bétheny': 4820,
    'Betschdorf': 4820,
    'Bettancourt-la-Ferrée': 4820,
    'Bezannes': 4820,
    'Bienville': 4820,
    'Biesheim': 4820,
    'Biesles': 4820,
    'Bining': 4820,
    'Bischheim': 4820,
    'Bischoffsheim': 4820,
    'Bischwiller': 4820,
    'Bitche': 4820,
    'Bitschwiller-lès-Thann': 4820,
    'Blaesheim': 4820,
    'Blagny': 4820,
    'Blénod-lès-Pont-à-Mousson': 4820,
    'Blénod-lès-Toul': 4820,
    'Bliesbruck': 4820,
    'Blodelsheim': 4820,
    'Blotzheim': 4820,
    'Bœrsch': 4820,
    'Bollwiller': 4820,
    'Bologne': 4820,
    'Boofzheim': 4820,
    'Bouilly': 4820,
    'Boulange': 4820,
    'Boulay-Moselle': 4820,
    'Bouligny': 4820,
    'Boult-sur-Suippe': 4820,
    'Bourbonne-les-Bains': 4820,
    'Bourgogne': 4820,
    'Bousse': 4820,
    'Bouxières-aux-Chênes': 4820,
    'Bouxières-aux-Dames': 4820,
    'Bouxwiller': 4820,
    'Bouzonville': 4820,
    'Breuschwickersheim': 4820,
    'Bréviandes': 4820,
    'Brienne-le-Château': 4820,
    'Briey': 4820,
    'Brumath': 4820,
    'Brunstatt': 4820,
    'Bruyères': 4820,
    'Buchères': 4820,
    'Buhl': 4820,
    'Bulgnéville': 4820,
    'Burnhaupt-le-Bas': 4820,
    'Burnhaupt-le-Haut': 4820,
    'Bussang': 4820,
    'Carignan': 4820,
    'Carling': 4820,
    'Carspach': 4820,
    'Cattenom': 4820,
    'Cernay': 4820,
    'Cernay-lès-Reims': 4820,
    'Chalampé': 4820,
    'Chaligny': 4820,
    'Chalindrey': 4820,
    'Châlons-en-Champagne': 4820,
    'Chamarandes-Choignes': 4820,
    'Champenoux': 4820,
    'Champigneulles': 4820,
    'Chancenay': 4820,
    'Chanteheux': 4820,
    'Chantraine': 4820,
    'Chaource': 4820,
    'Charleville-Mézières': 4820,
    'Charmes': 4820,
    'Château-Porcien': 4820,
    'Château-Salins': 4820,
    'Châteauvillain': 4820,
    'Châtel-Saint-Germain': 4820,
    'Châtel-sur-Moselle': 4820,
    'Châtenois': 4820,
    'Châtillon-sur-Marne': 4820,
    'Chaumont': 4820,
    'Chavelot': 4820,
    'Chavigny': 4820,
    'Cheniménil': 4820,
    'Chevillon': 4820,
    'Cirey-sur-Vezouze': 4820,
    'Clermont-en-Argonne': 4820,
    'Clouange': 4820,
    'Cocheren': 4820,
    'Colmar': 4820,
    'Colombey-les-Belles': 4820,
    'Commercy': 4820,
    'Compertrix': 4820,
    'Conflans-en-Jarnisy': 4820,
    'Connantre': 4820,
    'Contrexéville': 4820,
    'Corcieux': 4820,
    'Cormicy': 4820,
    'Cormontreuil': 4820,
    'Cornimont': 4820,
    'Corny-sur-Moselle': 4820,
    'Cosnes': 4820,
    'Courcelles-Chaussy': 4820,
    'Courcy': 4820,
    'Courtisols': 4820,
    'Créhange': 4820,
    'Creney-près-Troyes': 4820,
    'Creutzwald': 4820,
    'Crusnes': 4820,
    'Custines': 4820,
    'Dabo': 4820,
    'Dachstein': 4820,
    'Dambach-la-Ville': 4820,
    'Damelevières': 4820,
    'Damery': 4820,
    'Dannemarie': 4820,
    'Darney': 4820,
    'Darnieulles': 4820,
    'Dauendorf': 4820,
    'Département de l  Aube': 4820,
    'Dessenheim': 4820,
    'Dettwiller': 4820,
    'Deville': 4820,
    'Deyvillers': 4820,
    'Didenheim': 4820,
    'Diebling': 4820,
    'Diemeringen': 4820,
    'Diesen': 4820,
    'Dietwiller': 4820,
    'Dieue-sur-Meuse': 4820,
    'Dieulouard': 4820,
    'Dieuze': 4820,
    'Dingsheim': 4820,
    'Dinsheim-sur-Bruche': 4820,
    'Distroff': 4820,
    'Dizy': 4820,
    'Docelles': 4820,
    'Dogneville': 4820,
    'Dom-le-Mesnil': 4820,
    'Dombasle-sur-Meurthe': 4820,
    'Domgermain': 4820,
    'Dommartin-lès-Remiremont': 4820,
    'Dommartin-lès-Toul': 4820,
    'Donchery': 4820,
    'Doncourt-lès-Conflans': 4820,
    'Dorlisheim': 4820,
    'Dormans': 4820,
    'Dossenheim-sur-Zinsel': 4820,
    'Doulaincourt-Saucourt': 4820,
    'Douzy': 4820,
    'Drulingen': 4820,
    'Drusenheim': 4820,
    'Dugny-sur-Meuse': 4820,
    'Duppigheim': 4820,
    'Durrenbach': 4820,
    'Duttlenheim': 4820,
    'Ebersheim': 4820,
    'Eckbolsheim': 4820,
    'Eckwersheim': 4820,
    'Écrouves': 4820,
    'Eguisheim': 4820,
    'Einville-au-Jard': 4820,
    'Éloyes': 4820,
    'Enchenberg': 4820,
    'Ennery': 4820,
    'Ensisheim': 4820,
    'Entrange': 4820,
    'Entzheim': 4820,
    'Épernay': 4820,
    'Epfig': 4820,
    'Épinal': 4820,
    'Ernolsheim-Bruche': 4820,
    'Erstein': 4820,
    'Ervy-le-Châtel': 4820,
    'Eschau': 4820,
    'Eschbach': 4820,
    'Eschentzwiller': 4820,
    'Essey-lès-Nancy': 4820,
    'Esternay': 4820,
    'Estissac': 4820,
    'Étain': 4820,
    'Étival-Clairefontaine': 4820,
    'Etzling': 4820,
    'Eulmont': 4820,
    'Eurville-Bienville': 4820,
    'Euville': 4820,
    'Fagnières': 4820,
    'Fains-Véel': 4820,
    'Falck': 4820,
    'Fameck': 4820,
    'Farébersviller': 4820,
    'Farschviller': 4820,
    'Faulquemont': 4820,
    'Faulx': 4820,
    'Fayl-Billot': 4820,
    'Fegersheim': 4820,
    'Fellering': 4820,
    'Fère-Champenoise': 4820,
    'Ferrette': 4820,
    'Fessenheim': 4820,
    'Fismes': 4820,
    'Flavigny-sur-Moselle': 4820,
    'Flaxlanden': 4820,
    'Fleury': 4820,
    'Fléville-devant-Nancy': 4820,
    'Flize': 4820,
    'Floing': 4820,
    'Florange': 4820,
    'Folkling': 4820,
    'Folschviller': 4820,
    'Fontoy': 4820,
    'Forbach': 4820,
    'Fortschwihr': 4820,
    'Foug': 4820,
    'Fraize': 4820,
    'Fréland': 4820,
    'Fresse-sur-Moselle': 4820,
    'Freyming-Merlebach': 4820,
    'Frignicourt': 4820,
    'Fromelennes': 4820,
    'Froncles': 4820,
    'Frouard': 4820,
    'Fumay': 4820,
    'Furdenheim': 4820,
    'Gambsheim': 4820,
    'Gandrange': 4820,
    'Geispolsheim': 4820,
    'Gérardmer': 4820,
    'Gerbéviller': 4820,
    'Gerstheim': 4820,
    'Gespunsart': 4820,
    'Geudertheim': 4820,
    'Giraumont': 4820,
    'Givet': 4820,
    'Gœrsdorf': 4820,
    'Goetzenbruck': 4820,
    'Golbey': 4820,
    'Gondrecourt-le-Château': 4820,
    'Gondreville': 4820,
    'Gorcy': 4820,
    'Gorze': 4820,
    'Granges-sur-Vologne': 4820,
    'Grendelbruch': 4820,
    'Gresswiller': 4820,
    'Gries': 4820,
    'Griesheim-près-Molsheim': 4820,
    'Griesheim-sur-Souffel': 4820,
    'Gros-Réderching': 4820,
    'Grosbliederstroff': 4820,
    'Guebwiller': 4820,
    'Guémar': 4820,
    'Guénange': 4820,
    'Gueux': 4820,
    'Guewenheim': 4820,
    'Gumbrechtshoffen': 4820,
    'Gundershoffen': 4820,
    'Habsheim': 4820,
    'Hadol': 4820,
    'Hagenthal-le-Bas': 4820,
    'Hagondange': 4820,
    'Haguenau': 4820,
    'Ham-sous-Varsberg': 4820,
    'Hambach': 4820,
    'Hangenbieten': 4820,
    'Hargarten-aux-Mines': 4820,
    'Hatten': 4820,
    'Haucourt': 4820,
    'Haut-Rhin': 4820,
    'Haute-Marne': 4820,
    'Hayange': 4820,
    'Haybes': 4820,
    'Hégenheim': 4820,
    'Heillecourt': 4820,
    'Heimsbrunn': 4820,
    'Herbitzheim': 4820,
    'Hermonville': 4820,
    'Herrlisheim': 4820,
    'Herrlisheim-près-Colmar': 4820,
    'Herserange': 4820,
    'Hésingue': 4820,
    'Hettange-Grande': 4820,
    'Hilsenheim': 4820,
    'Hindisheim': 4820,
    'Hirsingue': 4820,
    'Hirtzbach': 4820,
    'Hirtzfelden': 4820,
    'Hochfelden': 4820,
    'Hochstatt': 4820,
    'Hœnheim': 4820,
    'Hœrdt': 4820,
    'Holtzheim': 4820,
    'Holtzwihr': 4820,
    'Holving': 4820,
    'Hombourg-Haut': 4820,
    'Homécourt': 4820,
    'Horbourg-Wihr': 4820,
    'Houdemont': 4820,
    'Houssen': 4820,
    'Hundling': 4820,
    'Huningue': 4820,
    'Husseren-Wesserling': 4820,
    'Hussigny-Godbrange': 4820,
    'Huttenheim': 4820,
    'Igney': 4820,
    'Illange': 4820,
    'Illfurth': 4820,
    'Illkirch-Graffenstaden': 4820,
    'Illzach': 4820,
    'Ingersheim': 4820,
    'Ingwiller': 4820,
    'Innenheim': 4820,
    'Irmstett': 4820,
    'Issenheim': 4820,
    'Ittenheim': 4820,
    'Jarny': 4820,
    'Jarville-la-Malgrange': 4820,
    'Jeanménil': 4820,
    'Jebsheim': 4820,
    'Jœuf': 4820,
    'Joinville': 4820,
    'Jonchery-sur-Vesle': 4820,
    'Joudreville': 4820,
    'Jouy-aux-Arches': 4820,
    'Kaltenhouse': 4820,
    'Kaysersberg': 4820,
    'Kédange-sur-Canner': 4820,
    'Kembs': 4820,
    'Kerbach': 4820,
    'Kertzfeld': 4820,
    'Keskastel': 4820,
    'Kilstett': 4820,
    'Kingersheim': 4820,
    'Kintzheim': 4820,
    'Knutange': 4820,
    'Kœnigsmacker': 4820,
    'Krautergersheim': 4820,
    'Kruth': 4820,
    'Kunheim': 4820,
    'Kuntzig': 4820,
    'La Bresse': 4820,
    'La Broque': 4820,
    'La Chapelle-Saint-Luc': 4820,
    'La Francheville': 4820,
    'La Rivière-de-Corps': 4820,
    'La Wantzenau': 4820,
    'Labaroche': 4820,
    'Labry': 4820,
    'Lamarche': 4820,
    'Lampertheim': 4820,
    'Landres': 4820,
    'Landser': 4820,
    'Laneuveville-devant-Nancy': 4820,
    'Langres': 4820,
    'Lapoutroie': 4820,
    'Lautenbach': 4820,
    'Lautenbachzell': 4820,
    'Lauterbourg': 4820,
    'Laxou': 4820,
    'Lay-Saint-Christophe': 4820,
    'Le Ban Saint-Martin': 4820,
    'Le Ménil': 4820,
    'Le Mesnil-sur-Oger': 4820,
    'Le Syndicat': 4820,
    'Le Thillot': 4820,
    'Le Tholy': 4820,
    'Lembach': 4820,
    'Lemberg': 4820,
    'Lérouville': 4820,
    'Les Forges': 4820,
    'Les Hautes-Rivières': 4820,
    'Les Noës-près-Troyes': 4820,
    'Les Riceys': 4820,
    'Lexy': 4820,
    'Leymen': 4820,
    'Lièpvre': 4820,
    'Liffol-le-Grand': 4820,
    'Ligny-en-Barrois': 4820,
    'Lingolsheim': 4820,
    'Lipsheim': 4820,
    'Liverdun': 4820,
    'Loivre': 4820,
    'Longeville-en-Barrois': 4820,
    'Longeville-lès-Metz': 4820,
    'Longeville-lès-Saint-Avold': 4820,
    'Longlaville': 4820,
    'Longuyon': 4820,
    'Longwy': 4820,
    'Lorquin': 4820,
    'Ludres': 4820,
    'Lumes': 4820,
    'Lunéville': 4820,
    'Lusigny-sur-Barse': 4820,
    'Lutterbach': 4820,
    'Lutzelhouse': 4820,
    'Macheren': 4820,
    'Magenta': 4820,
    'Maidières': 4820,
    'Mailly-le-Camp': 4820,
    'Maizières-la-Grande-Paroisse': 4820,
    'Maizières-lès-Metz': 4820,
    'Malzéville': 4820,
    'Mancieulles': 4820,
    'Manom': 4820,
    'Marange-Silvange': 4820,
    'Marbache': 4820,
    'Marckolsheim': 4820,
    'Mardeuil': 4820,
    'Mareuil-le-Port': 4820,
    'Mareuil-sur-Ay': 4820,
    'Marigny-le-Châtel': 4820,
    'Marlenheim': 4820,
    'Marly': 4820,
    'Marmoutier': 4820,
    'Marne': 4820,
    'Masevaux': 4820,
    'Matzenheim': 4820,
    'Maxéville': 4820,
    'Mécleuves': 4820,
    'Meistratzheim': 4820,
    'Mercy-le-Bas': 4820,
    'Méréville': 4820,
    'Merten': 4820,
    'Mertzwiller': 4820,
    'Merxheim': 4820,
    'Méry-sur-Seine': 4820,
    'Messein': 4820,
    'Metz': 4820,
    'Metzeral': 4820,
    'Metzervisse': 4820,
    'Meurthe-et-Moselle': 4820,
    'Meuse': 4820,
    'Mexy': 4820,
    'Meyenheim': 4820,
    'Mirecourt': 4820,
    'Mittelhausbergen': 4820,
    'Molsheim': 4820,
    'Mommenheim': 4820,
    'Mondelange': 4820,
    'Monswiller': 4820,
    'Mont-Saint-Martin': 4820,
    'Montauville': 4820,
    'Montbronn': 4820,
    'Montcy-Notre-Dame': 4820,
    'Monthermé': 4820,
    'Montier-en-Der': 4820,
    'Montigny-lès-Metz': 4820,
    'Montmédy': 4820,
    'Montmirail': 4820,
    'Montois-la-Montagne': 4820,
    'Moosch': 4820,
    'Morhange': 4820,
    'Morsbach': 4820,
    'Morschwiller-le-Bas': 4820,
    'Moselle': 4820,
    'Moulins-lès-Metz': 4820,
    'Mourmelon-le-Grand': 4820,
    'Moutiers': 4820,
    'Mouzon': 4820,
    'Moyenmoutier': 4820,
    'Moyeuvre-Grande': 4820,
    'Muizon': 4820,
    'Mulhouse': 4820,
    'Munchhouse': 4820,
    'Mundolsheim': 4820,
    'Munster': 4820,
    'Mussig': 4820,
    'Mussy-sur-Seine': 4820,
    'Muttersholtz': 4820,
    'Mutzig': 4820,
    'Nancy': 4820,
    'Neuf-Brisach': 4820,
    'Neufchâteau': 4820,
    'Neufchef': 4820,
    'Neufgrange': 4820,
    'Neufmanil': 4820,
    'Neuves-Maisons': 4820,
    'Neuwiller-lès-Saverne': 4820,
    'Niderviller': 4820,
    'Niederbronn-les-Bains': 4820,
    'Niederhaslach': 4820,
    'Niederhausbergen': 4820,
    'Niedernai': 4820,
    'Niederschaeffolsheim': 4820,
    'Nilvange': 4820,
    'Nogent-le-Bas': 4820,
    'Nogent-sur-Seine': 4820,
    'Nomeny': 4820,
    'Nomexy': 4820,
    'Nordhouse': 4820,
    'Norroy-lès-Pont-à-Mousson': 4820,
    'Nouvion-sur-Meuse': 4820,
    'Nouzonville': 4820,
    'Novéant-sur-Moselle': 4820,
    'Obenheim': 4820,
    'Oberbronn': 4820,
    'Oberhaslach': 4820,
    'Oberhausbergen': 4820,
    'Oberhergheim': 4820,
    'Oberhoffen-sur-Moder': 4820,
    'Obermodern-Zutzendorf': 4820,
    'Obernai': 4820,
    'Oberschaeffolsheim': 4820,
    'Oderen': 4820,
    'Oermingen': 4820,
    'Œting': 4820,
    'Offenheim': 4820,
    'Ohlungen': 4820,
    'Orbey': 4820,
    'Ostheim': 4820,
    'Ostwald': 4820,
    'Ottange': 4820,
    'Otterswiller': 4820,
    'Ottmarsheim': 4820,
    'Ottrott': 4820,
    'Pagny-sur-Moselle': 4820,
    'Pargny-sur-Saulx': 4820,
    'Payns': 4820,
    'Peltre': 4820,
    'Petit-Réderching': 4820,
    'Petite-Rosselle': 4820,
    'Pfaffenheim': 4820,
    'Pfaffenhoffen': 4820,
    'Pfastatt': 4820,
    'Pfetterhouse': 4820,
    'Pfulgriesheim': 4820,
    'Phalsbourg': 4820,
    'Piennes': 4820,
    'Pierrepont': 4820,
    'Pierrevillers': 4820,
    'Pierry': 4820,
    'Piney': 4820,
    'Plainfaing': 4820,
    'Plappeville': 4820,
    'Plobsheim': 4820,
    'Plombières-les-Bains': 4820,
    'Pompey': 4820,
    'Pont-à-Mousson': 4820,
    'Pont-Saint-Vincent': 4820,
    'Pont-Sainte-Marie': 4820,
    'Pontfaverger-Moronvilliers': 4820,
    'Porcelette': 4820,
    'Port à Binson': 4820,
    'Portieux': 4820,
    'Pouru-Saint-Remy': 4820,
    'Pouxeux': 4820,
    'Prix-lès-Mézières': 4820,
    'Pulligny': 4820,
    'Pulnoy': 4820,
    'Pulversheim': 4820,
    'Raedersheim': 4820,
    'Rambervillers': 4820,
    'Ramonchamp': 4820,
    'Raon-aux-Bois': 4820,
    'Réding': 4820,
    'Réguisheim': 4820,
    'Réhon': 4820,
    'Reichshoffen': 4820,
    'Reichstett': 4820,
    'Reims': 4820,
    'Reiningue': 4820,
    'Rémelfing': 4820,
    'Rémilly': 4820,
    'Remiremont': 4820,
    'Renwez': 4820,
    'Rethel': 4820,
    'Retonfey': 4820,
    'Revigny-sur-Ornain': 4820,
    'Revin': 4820,
    'Rhinau': 4820,
    'Ribeauvillé': 4820,
    'Richardménil': 4820,
    'Richemont': 4820,
    'Richwiller': 4820,
    'Riedisheim': 4820,
    'Rilly-la-Montagne': 4820,
    'Rimogne': 4820,
    'Riquewihr': 4820,
    'Rixheim': 4820,
    'Rocroi': 4820,
    'Roeschwoog': 4820,
    'Rohrbach-lès-Bitche': 4820,
    'Rolampont': 4820,
    'Romanswiller': 4820,
    'Rombas': 4820,
    'Romilly-sur-Seine': 4820,
    'Rosenau': 4820,
    'Rosheim': 4820,
    'Rosières-aux-Salines': 4820,
    'Rosières-près-Troyes': 4820,
    'Rosselange': 4820,
    'Rothau': 4820,
    'Rouffach': 4820,
    'Rouhling': 4820,
    'Rozérieulles': 4820,
    'Ruelisheim': 4820,
    'Rumersheim-le-Haut': 4820,
    'Rupt-sur-Moselle': 4820,
    'Rurange-lès-Thionville': 4820,
    'Russ': 4820,
    'Russange': 4820,
    'Saint-Amarin': 4820,
    'Saint-Amé': 4820,
    'Saint-André-les-Vergers': 4820,
    'Saint-Avold': 4820,
    'Saint-Brice-Courcelles': 4820,
    'Saint-Dié-des-Vosges': 4820,
    'Saint-Dizier': 4820,
    'Saint-Étienne-lès-Remiremont': 4820,
    'Saint-Germain': 4820,
    'Saint-Hippolyte': 4820,
    'Saint-Julien-lès-Metz': 4820,
    'Saint-Julien-les-Villas': 4820,
    'Saint-Just-Sauvage': 4820,
    'Saint-Laurent': 4820,
    'Saint-Léonard': 4820,
    'Saint-Louis': 4820,
    'Saint-Lyé': 4820,
    'Saint-Maurice-sur-Moselle': 4820,
    'Saint-Max': 4820,
    'Saint-Memmie': 4820,
    'Saint-Menges': 4820,
    'Saint-Michel-sur-Meurthe': 4820,
    'Saint-Mihiel': 4820,
    'Saint-Nabord': 4820,
    'Saint-Nicolas-de-Port': 4820,
    'Saint-Parres-aux-Tertres': 4820,
    'Saint-Pouange': 4820,
    'Saint-Privat-la-Montagne': 4820,
    'Sainte-Croix-aux-Mines': 4820,
    'Sainte-Croix-en-Plaine': 4820,
    'Sainte-Marguerite': 4820,
    'Sainte-Marie-aux-Chênes': 4820,
    'Sainte-Marie-aux-Mines': 4820,
    'Sainte-Maure': 4820,
    'Sainte-Menehould': 4820,
    'Sainte-Savine': 4820,
    'Saizerais': 4820,
    'Sand': 4820,
    'Sarralbe': 4820,
    'Sarre-Union': 4820,
    'Sarrebourg': 4820,
    'Sarreguemines': 4820,
    'Sarreinsming': 4820,
    'Sarrewerden': 4820,
    'Sarry': 4820,
    'Saulcy-sur-Meurthe': 4820,
    'Saulnes': 4820,
    'Saulny': 4820,
    'Sault-lès-Rethel': 4820,
    'Saulxures-lès-Nancy': 4820,
    'Saulxures-sur-Moselotte': 4820,
    'Sausheim': 4820,
    'Saverne': 4820,
    'Scherwiller': 4820,
    'Schiltigheim': 4820,
    'Schirmeck': 4820,
    'Schirrhein': 4820,
    'Schnersheim': 4820,
    'Schœneck': 4820,
    'Schweighouse-sur-Moder': 4820,
    'Schwindratzheim': 4820,
    'Scy-Chazelles': 4820,
    'Sedan': 4820,
    'Seichamps': 4820,
    'Seingbouse': 4820,
    'Sélestat': 4820,
    'Seltz': 4820,
    'Senones': 4820,
    'Sentheim': 4820,
    'Serémange-Erzange': 4820,
    'Sermaize-les-Bains': 4820,
    'Sessenheim': 4820,
    'Sézanne': 4820,
    'Sierck-les-Bains': 4820,
    'Sierentz': 4820,
    'Signy-le-Petit': 4820,
    'Sigolsheim': 4820,
    'Sillery': 4820,
    'Solgne': 4820,
    'Soucht': 4820,
    'Souffelweyersheim': 4820,
    'Soufflenheim': 4820,
    'Soultz-Haut-Rhin': 4820,
    'Soultz-sous-Forêts': 4820,
    'Soultzeren': 4820,
    'Soultzmatt': 4820,
    'Spicheren': 4820,
    'Staffelfelden': 4820,
    'Steinbach': 4820,
    'Steinbourg': 4820,
    'Stenay': 4820,
    'Still': 4820,
    'Stiring-Wendel': 4820,
    'Stosswihr': 4820,
    'Stotzheim': 4820,
    'Strasbourg': 4820,
    'Suippes': 4820,
    'Sundhoffen': 4820,
    'Sundhouse': 4820,
    'Surbourg': 4820,
    'Taintrux': 4820,
    'Taissy': 4820,
    'Talange': 4820,
    'Tenteling': 4820,
    'Terville': 4820,
    'Teting-sur-Nied': 4820,
    'Thann': 4820,
    'Thaon-les-Vosges': 4820,
    'Théding': 4820,
    'Thierville-sur-Meuse': 4820,
    'Thil': 4820,
    'Thilay': 4820,
    'Thionville': 4820,
    'Tinqueux': 4820,
    'Tomblaine': 4820,
    'Toul': 4820,
    'Tournes': 4820,
    'Tours-sur-Marne': 4820,
    'Trémery': 4820,
    'Tressange': 4820,
    'Trieux': 4820,
    'Tronville-en-Barrois': 4820,
    'Troyes': 4820,
    'Truchtersheim': 4820,
    'Tucquegnieux': 4820,
    'Turckheim': 4820,
    'Uberach': 4820,
    'Uckange': 4820,
    'Uffholtz': 4820,
    'Ungersheim': 4820,
    'Uriménil': 4820,
    'Urmatt': 4820,
    'Uxegney': 4820,
    'Uzemain': 4820,
    'Vacon': 4820,
    'Vagney': 4820,
    'Val-de-Meuse': 4820,
    'Valff': 4820,
    'Valleroy': 4820,
    'Valmont': 4820,
    'Vandœuvre-lès-Nancy': 4820,
    'Varangéville': 4820,
    'Vaucouleurs': 4820,
    'Vecoux': 4820,
    'Velaine-en-Haye': 4820,
    'Velaines': 4820,
    'Vendenheim': 4820,
    'Vendeuvre-sur-Barse': 4820,
    'Verdun': 4820,
    'Verny': 4820,
    'Verrières': 4820,
    'Vertus': 4820,
    'Verzenay': 4820,
    'Verzy': 4820,
    'Vézelise': 4820,
    'Vic-sur-Seille': 4820,
    'Vieux-Thann': 4820,
    'Vigneulles-lès-Hattonchâtel': 4820,
    'Vignot': 4820,
    'Vigy': 4820,
    'Village-Neuf': 4820,
    'Villé': 4820,
    'Ville-sous-la-Ferté': 4820,
    'Villenauxe-la-Grande': 4820,
    'Villers-la-Montagne': 4820,
    'Villers-lès-Nancy': 4820,
    'Villers-Semeuse': 4820,
    'Villerupt': 4820,
    'Villey-Saint-Étienne': 4820,
    'Villiers-en-Lieu': 4820,
    'Vincey': 4820,
    'Vireux-Molhain': 4820,
    'Vireux-Wallerand': 4820,
    'Vitry-le-François': 4820,
    'Vitry-sur-Orne': 4820,
    'Vittel': 4820,
    'Vivier-au-Court': 4820,
    'Void-Vacon': 4820,
    'Volgelsheim': 4820,
    'Volmerange-les-Mines': 4820,
    'Volstroff': 4820,
    'Vosges': 4820,
    'Vouziers': 4820,
    'Vrigne-aux-Bois': 4820,
    'Walbourg': 4820,
    'Waldighofen': 4820,
    'Walscheid': 4820,
    'Wangenbourg-Engenthal': 4820,
    'Warcq': 4820,
    'Warmeriville': 4820,
    'Wasselonne': 4820,
    'Wassy': 4820,
    'Wattwiller': 4820,
    'Weitbruch': 4820,
    'Westhoffen': 4820,
    'Wettolsheim': 4820,
    'Weyersheim': 4820,
    'Widensolen': 4820,
    'Wiesviller': 4820,
    'Wihr-au-Val': 4820,
    'Willerwald': 4820,
    'Wimmenau': 4820,
    'Wingen-sur-Moder': 4820,
    'Wingersheim': 4820,
    'Wintzenheim': 4820,
    'Wisches': 4820,
    'Wissembourg': 4820,
    'Witry-lès-Reims': 4820,
    'Wittelsheim': 4820,
    'Wittenheim': 4820,
    'Wittisheim': 4820,
    'Woerth': 4820,
    'Woippy': 4820,
    'Wolfgantzen': 4820,
    'Wolfisheim': 4820,
    'Woustviller': 4820,
    'Xertigny': 4820,
    'Xonrupt-Longemer': 4820,
    'Yutz': 4820,
    'Zillisheim': 4820,
    'Zimmersheim': 4820,
    'Abbeville': 4828,
    'Ablain-Saint-Nazaire': 4828,
    'Abscon': 4828,
    'Achicourt': 4828,
    'Achiet-le-Grand': 4828,
    'Agnetz': 4828,
    'Agny': 4828,
    'Ailly-sur-Noye': 4828,
    'Ailly-sur-Somme': 4828,
    'Airaines': 4828,
    'Aire-sur-la-Lys': 4828,
    'Aix-Noulette': 4828,
    'Albert': 4828,
    'Allennes-les-Marais': 4828,
    'Allonne': 4828,
    'Allouagne': 4828,
    'Amblainville': 4828,
    'Ambleny': 4828,
    'Ambleteuse': 4828,
    'Amiens': 4828,
    'Andeville': 4828,
    'Andres': 4828,
    'Angicourt': 4828,
    'Angres': 4828,
    'Angy': 4828,
    'Anhiers': 4828,
    'Aniche': 4828,
    'Anizy-le-Château': 4828,
    'Annay': 4828,
    'Annequin': 4828,
    'Annezin': 4828,
    'Annoeullin': 4828,
    'Anor': 4828,
    'Ansauvillers': 4828,
    'Anstaing': 4828,
    'Anzin': 4828,
    'Anzin-Saint-Aubin': 4828,
    'Ardres': 4828,
    'Arleux': 4828,
    'Armbouts-Cappel': 4828,
    'Armentières': 4828,
    'Arnèke': 4828,
    'Arques': 4828,
    'Arras': 4828,
    'Artres': 4828,
    'Athies-sous-Laon': 4828,
    'Attiches': 4828,
    'Attichy': 4828,
    'Auberchicourt': 4828,
    'Aubers': 4828,
    'Aubigny-au-Bac': 4828,
    'Aubigny-en-Artois': 4828,
    'Aubry-du-Hainaut': 4828,
    'Auby': 4828,
    'Auchel': 4828,
    'Auchy-lès-Hesdin': 4828,
    'Auchy-les-Mines': 4828,
    'Audruicq': 4828,
    'Aulnois-sous-Laon': 4828,
    'Aulnoy-lez-Valenciennes': 4828,
    'Aulnoye-Aymeries': 4828,
    'Ault': 4828,
    'Auneuil': 4828,
    'Auxi-le-Château': 4828,
    'Avelin': 4828,
    'Avesnelles': 4828,
    'Avesnes-le-Comte': 4828,
    'Avesnes-le-Sec': 4828,
    'Avesnes-les-Aubert': 4828,
    'Avilly-Saint-Léonard': 4828,
    'Avion': 4828,
    'Avrechy': 4828,
    'Bachant': 4828,
    'Bachy': 4828,
    'Bailleul': 4828,
    'Bailleul-Sir-Berthoult': 4828,
    'Bailleul-sur-Thérain': 4828,
    'Bailleval': 4828,
    'Baincthun': 4828,
    'Baisieux': 4828,
    'Balagny-sur-Thérain': 4828,
    'Bapaume': 4828,
    'Barlin': 4828,
    'Bauvin': 4828,
    'Bavay': 4828,
    'Beaucamps-le-Vieux': 4828,
    'Beauchamps': 4828,
    'Beaumetz-lès-Loges': 4828,
    'Beauquesne': 4828,
    'Beaurains': 4828,
    'Beaurainville': 4828,
    'Beaurevoir': 4828,
    'Beautor': 4828,
    'Beauvais': 4828,
    'Beauval': 4828,
    'Beauvois-en-Cambrésis': 4828,
    'Bellaing': 4828,
    'Belleu': 4828,
    'Berck': 4828,
    'Berck-Plage': 4828,
    'Bergues': 4828,
    'Berlaimont': 4828,
    'Bernaville': 4828,
    'Bersée': 4828,
    'Berteaucourt-les-Dames': 4828,
    'Berthecourt': 4828,
    'Bertry': 4828,
    'Béthencourt-sur-Mer': 4828,
    'Béthisy-Saint-Martin': 4828,
    'Béthisy-Saint-Pierre': 4828,
    'Béthune': 4828,
    'Betz': 4828,
    'Beuvrages': 4828,
    'Beuvry': 4828,
    'Biache-Saint-Vaast': 4828,
    'Bierne': 4828,
    'Billy-Berclau': 4828,
    'Billy-Montigny': 4828,
    'Billy-sur-Aisne': 4828,
    'Blaincourt-lès-Précy': 4828,
    'Blaringhem': 4828,
    'Blendecques': 4828,
    'Blérancourt': 4828,
    'Boeschepe': 4828,
    'Bohain-en-Vermandois': 4828,
    'Bois-Grenier': 4828,
    'Bollezeele': 4828,
    'Bondues': 4828,
    'Boran-sur-Oise': 4828,
    'Bornel': 4828,
    'Bouchain': 4828,
    'Boué': 4828,
    'Boulogne-sur-Mer': 4828,
    'Bourbourg': 4828,
    'Bourghelles': 4828,
    'Bourlon': 4828,
    'Bousbecque': 4828,
    'Bousies': 4828,
    'Boussois': 4828,
    'Bouttencourt': 4828,
    'Bouvignies': 4828,
    'Bouvigny-Boyeffles': 4828,
    'Boves': 4828,
    'Braine': 4828,
    'Brasles': 4828,
    'Bray-Dunes': 4828,
    'Bray-sur-Somme': 4828,
    'Brebières': 4828,
    'Brêmes': 4828,
    'Brenouille': 4828,
    'Bresles': 4828,
    'Breteuil': 4828,
    'Breuil-le-Sec': 4828,
    'Breuil-le-Vert': 4828,
    'Brouckerque': 4828,
    'Bruay-la-Buissière': 4828,
    'Bruay-sur-l’Escaut': 4828,
    'Bruille-lez-Marchiennes': 4828,
    'Bruille-Saint-Amand': 4828,
    'Bruyères-et-Montbérault': 4828,
    'Bucquoy': 4828,
    'Bucy-le-Long': 4828,
    'Buironfosse': 4828,
    'Bully-les-Mines': 4828,
    'Burbure': 4828,
    'Bury': 4828,
    'Busigny': 4828,
    'Busnes': 4828,
    'Caëstre': 4828,
    'Cagny': 4828,
    'Calais': 4828,
    'Calonne-Ricouart': 4828,
    'Calonne-sur-la-Lys': 4828,
    'Camblain-Châtelain': 4828,
    'Cambrai': 4828,
    'Cambronne-lès-Clermont': 4828,
    'Cambronne-lès-Ribécourt': 4828,
    'Camiers': 4828,
    'Camon': 4828,
    'Campagne-lès-Hesdin': 4828,
    'Camphin-en-Carembault': 4828,
    'Camphin-en-Pévèle': 4828,
    'Cantin': 4828,
    'Capinghem': 4828,
    'Cappelle-en-Pévèle': 4828,
    'Cappelle-la-Grande': 4828,
    'Carlepont': 4828,
    'Cartignies': 4828,
    'Carvin': 4828,
    'Cassel': 4828,
    'Catenoy': 4828,
    'Cauchy-à-la-Tour': 4828,
    'Caudry': 4828,
    'Cauffry': 4828,
    'Cauvigny': 4828,
    'Cayeux-sur-Mer': 4828,
    'Chamant': 4828,
    'Chambly': 4828,
    'Chantilly': 4828,
    'Charly-sur-Marne': 4828,
    'Charmes': 4828,
    'Château-Thierry': 4828,
    'Chaulnes': 4828,
    'Chaumont-en-Vexin': 4828,
    'Chauny': 4828,
    'Chépy': 4828,
    'Chéreng': 4828,
    'Chevrières': 4828,
    'Chézy-sur-Marne': 4828,
    'Chierry': 4828,
    'Chiry-Ourscamp': 4828,
    'Chocques': 4828,
    'Choisy-au-Bac': 4828,
    'Cinqueux': 4828,
    'Cires-lès-Mello': 4828,
    'Clairoix': 4828,
    'Clary': 4828,
    'Clermont': 4828,
    'Coincy': 4828,
    'Colleret': 4828,
    'Comines': 4828,
    'Compiègne': 4828,
    'Condé-sur-l’Escaut': 4828,
    'Condette': 4828,
    'Conty': 4828,
    'Coquelles': 4828,
    'Corbehem': 4828,
    'Corbie': 4828,
    'Coucy-le-Château-Auffrique': 4828,
    'Coudekerque-Branche': 4828,
    'Coudekerque-Village': 4828,
    'Coudun': 4828,
    'Coulogne': 4828,
    'Courcelles-lès-Lens': 4828,
    'Courchelettes': 4828,
    'Courmelles': 4828,
    'Courrières': 4828,
    'Cousolre': 4828,
    'Coutiches': 4828,
    'Couvron-et-Aumencourt': 4828,
    'Coye-la-Forêt': 4828,
    'Crécy-en-Ponthieu': 4828,
    'Crécy-sur-Serre': 4828,
    'Creil': 4828,
    'Crépy': 4828,
    'Crépy-en-Valois': 4828,
    'Crespin': 4828,
    'Crèvecœur-le-Grand': 4828,
    'Crézancy': 4828,
    'Crisolles': 4828,
    'Croisilles': 4828,
    'Croix': 4828,
    'Crouy': 4828,
    'Crouy-en-Thelle': 4828,
    'Cucq': 4828,
    'Cuffies': 4828,
    'Cuinchy': 4828,
    'Cuincy': 4828,
    'Cuise-la-Motte': 4828,
    'Curgies': 4828,
    'Cysoing': 4828,
    'Dainville': 4828,
    'Dannes': 4828,
    'Dargnies': 4828,
    'Dechy': 4828,
    'Denain': 4828,
    'Département de l  Aisne': 4828,
    'Département de l  Oise': 4828,
    'Desvres': 4828,
    'Deûlémont': 4828,
    'Divion': 4828,
    'Doingt': 4828,
    'Domart-en-Ponthieu': 4828,
    'Don': 4828,
    'Douai': 4828,
    'Douchy-les-Mines': 4828,
    'Doullens': 4828,
    'Dourges': 4828,
    'Douvrin': 4828,
    'Dreslincourt': 4828,
    'Dreuil-lès-Amiens': 4828,
    'Drocourt': 4828,
    'Duisans': 4828,
    'Dunkerque': 4828,
    'Dury': 4828,
    'Écaillon': 4828,
    'Écourt-Saint-Quentin': 4828,
    'Ecques': 4828,
    'Éleu-dit-Leauwette': 4828,
    'Émerchicourt': 4828,
    'Emmerin': 4828,
    'Englefontaine': 4828,
    'Ennetières-en-Weppes': 4828,
    'Ennevelin': 4828,
    'Épehy': 4828,
    'Éperlecques': 4828,
    'Eppeville': 4828,
    'Équihen-Plage': 4828,
    'Ercuis': 4828,
    'Erquinghem-Lys': 4828,
    'Erre': 4828,
    'Escaudain': 4828,
    'Escaudœuvres': 4828,
    'Escautpont': 4828,
    'Esches': 4828,
    'Esquéhéries': 4828,
    'Esquelbecq': 4828,
    'Esquerdes': 4828,
    'Essars': 4828,
    'Essigny-le-Grand': 4828,
    'Essômes-sur-Marne': 4828,
    'Estaires': 4828,
    'Estevelles': 4828,
    'Estrées': 4828,
    'Estrées-Saint-Denis': 4828,
    'Étampes-sur-Marne': 4828,
    'Étaples': 4828,
    'Étreillers': 4828,
    'Étreux': 4828,
    'Évin-Malmaison': 4828,
    'Faches-Thumesnil': 4828,
    'Famars': 4828,
    'Fampoux': 4828,
    'Faumont': 4828,
    'Féchain': 4828,
    'Feignies': 4828,
    'Fenain': 4828,
    'Fère-en-Tardenois': 4828,
    'Férin': 4828,
    'Ferques': 4828,
    'Ferrière-la-Grande': 4828,
    'Festubert': 4828,
    'Feuchy': 4828,
    'Feuquières': 4828,
    'Feuquières-en-Vimeu': 4828,
    'Fitz-James': 4828,
    'Flavy-le-Martel': 4828,
    'Flers-en-Escrebieux': 4828,
    'Flesselles': 4828,
    'Fleurbaix': 4828,
    'Fleurines': 4828,
    'Flines-lès-Mortagne': 4828,
    'Flines-lez-Raches': 4828,
    'Flixecourt': 4828,
    'Folembray': 4828,
    'Fontaine-au-Pire': 4828,
    'Fontaine-Notre-Dame': 4828,
    'Forest-sur-Marque': 4828,
    'Formerie': 4828,
    'Fort-Mahon-Plage': 4828,
    'Fort-Mardyck': 4828,
    'Fouquereuil': 4828,
    'Fouquières-lès-Béthune': 4828,
    'Fouquières-lès-Lens': 4828,
    'Fourmies': 4828,
    'Fournes-en-Weppes': 4828,
    'Frelinghien': 4828,
    'Fresnes-sur-Escaut': 4828,
    'Fresnoy-le-Grand': 4828,
    'Fressenneville': 4828,
    'Fréthun': 4828,
    'Fretin': 4828,
    'Frévent': 4828,
    'Friville-Escarbotin': 4828,
    'Fruges': 4828,
    'Gamaches': 4828,
    'Gauchy': 4828,
    'Genech': 4828,
    'Ghyvelde': 4828,
    'Givenchy-en-Gohelle': 4828,
    'Godewaersvelde': 4828,
    'Gœulzin': 4828,
    'Goincourt': 4828,
    'Gommegnies': 4828,
    'Gondecourt': 4828,
    'Gonnehem': 4828,
    'Gosnay': 4828,
    'Gouvieux': 4828,
    'Gouy-sous-Bellonne': 4828,
    'Gouzeaucourt': 4828,
    'Grand-Fort-Philippe': 4828,
    'Grande-Synthe': 4828,
    'Grandfresnoy': 4828,
    'Grandvilliers': 4828,
    'Gravelines': 4828,
    'Grenay': 4828,
    'Groffliers': 4828,
    'Gruson': 4828,
    'Guarbecque': 4828,
    'Guesnain': 4828,
    'Guignicourt': 4828,
    'Guînes': 4828,
    'Guiscard': 4828,
    'Guise': 4828,
    'Haillicourt': 4828,
    'Haisnes': 4828,
    'Hallencourt': 4828,
    'Hallennes-lez-Haubourdin': 4828,
    'Hallines': 4828,
    'Halluin': 4828,
    'Ham': 4828,
    'Hames-Boucres': 4828,
    'Harbonnières': 4828,
    'Hardinghen': 4828,
    'Harly': 4828,
    'Harnes': 4828,
    'Hasnon': 4828,
    'Haspres': 4828,
    'Haubourdin': 4828,
    'Haulchin': 4828,
    'Haussy': 4828,
    'Hautmont': 4828,
    'Haveluy': 4828,
    'Haverskerque': 4828,
    'Hazebrouck': 4828,
    'Hélesmes': 4828,
    'Helfaut': 4828,
    'Hem': 4828,
    'Hénin-Beaumont': 4828,
    'Hergnies': 4828,
    'Hérin': 4828,
    'Herlies': 4828,
    'Hermes': 4828,
    'Hermies': 4828,
    'Hersin-Coupigny': 4828,
    'Herzeele': 4828,
    'Hesdin': 4828,
    'Heuringhem': 4828,
    'Hinges': 4828,
    'Hirson': 4828,
    'Holnon': 4828,
    'Hombleux': 4828,
    'Homblières': 4828,
    'Hondschoote': 4828,
    'Hordain': 4828,
    'Hornaing': 4828,
    'Hornoy-le-Bourg': 4828,
    'Houdain': 4828,
    'Houplin-Ancoisne': 4828,
    'Houplines': 4828,
    'Hoymille': 4828,
    'Hulluch': 4828,
    'Illies': 4828,
    'Isbergues': 4828,
    'Isques': 4828,
    'Itancourt': 4828,
    'Iwuy': 4828,
    'Jaux': 4828,
    'Jenlain': 4828,
    'Jeumont': 4828,
    'Jussy': 4828,
    'Killem': 4828,
    'La Bassée': 4828,
    'La Capelle': 4828,
    'La Capelle-lès-Boulogne': 4828,
    'La Chapelle-en-Serval': 4828,
    'La Couture': 4828,
    'La Fère': 4828,
    'La Ferté-Milon': 4828,
    'La Flamengrie': 4828,
    'La Gorgue': 4828,
    'La Longueville': 4828,
    'La Madeleine': 4828,
    'La Sentinelle': 4828,
    'Labeuvrière': 4828,
    'Laboissière-en-Thelle': 4828,
    'Labourse': 4828,
    'Lachapelle-aux-Pots': 4828,
    'Lacroix-Saint-Ouen': 4828,
    'Lagny-le-Sec': 4828,
    'Laigneville': 4828,
    'Lallaing': 4828,
    'Lambersart': 4828,
    'Lambres-lez-Douai': 4828,
    'Lamorlaye': 4828,
    'Landas': 4828,
    'Landrecies': 4828,
    'Lannoy': 4828,
    'Laon': 4828,
    'Lapugnoy': 4828,
    'Lassigny': 4828,
    'Lauwin-Planque': 4828,
    'Laventie': 4828,
    'Le Crotoy': 4828,
    'Le Doulieu': 4828,
    'Le Mesnil-en-Thelle': 4828,
    'Le Meux': 4828,
    'Le Nouvion-en-Thiérache': 4828,
    'Le Plessis-Belleville': 4828,
    'Le Plessis-Brion': 4828,
    'Le Portel': 4828,
    'Le Quesnoy': 4828,
    'Le Touquet-Paris-Plage': 4828,
    'Lecelles': 4828,
    'Lécluse': 4828,
    'Leers': 4828,
    'Leffrinckoucke': 4828,
    'Leforest': 4828,
    'Lens': 4828,
    'Les Ageux': 4828,
    'Les Attaques': 4828,
    'Lesquin': 4828,
    'Lestrem': 4828,
    'Leval': 4828,
    'Lewarde': 4828,
    'Lezennes': 4828,
    'Liancourt': 4828,
    'Libercourt': 4828,
    'Licques': 4828,
    'Liesse-Notre-Dame': 4828,
    'Lieu-Saint-Amand': 4828,
    'Liévin': 4828,
    'Ligny-en-Cambrésis': 4828,
    'Lille': 4828,
    'Lillers': 4828,
    'Linselles': 4828,
    'Locon': 4828,
    'Loison-sous-Lens': 4828,
    'Lomme': 4828,
    'Lompret': 4828,
    'Longfossé': 4828,
    'Longpré-les-Corps-Saints': 4828,
    'Longueau': 4828,
    'Longueil-Annel': 4828,
    'Longueil-Sainte-Marie': 4828,
    'Longuenesse': 4828,
    'Looberghe': 4828,
    'Loon-Plage': 4828,
    'Loos': 4828,
    'Loos-en-Gohelle': 4828,
    'Lorgies': 4828,
    'Lormaison': 4828,
    'Lourches': 4828,
    'Louvroil': 4828,
    'Lozinghem': 4828,
    'Lumbres': 4828,
    'Lys-lez-Lannoy': 4828,
    'Maignelay-Montigny': 4828,
    'Maing': 4828,
    'Maisnil-lès-Ruitz': 4828,
    'Mametz': 4828,
    'Marchiennes': 4828,
    'Marck': 4828,
    'Marcoing': 4828,
    'Marconne': 4828,
    'Marconnelle': 4828,
    'Marcq-en-Barœul': 4828,
    'Maretz': 4828,
    'Mareuil-sur-Ourcq': 4828,
    'Margny-lès-Compiègne': 4828,
    'Marle': 4828,
    'Marles-les-Mines': 4828,
    'Marly': 4828,
    'Marœuil': 4828,
    'Maroilles': 4828,
    'Marpent': 4828,
    'Marquette-en-Ostrevant': 4828,
    'Marquette-lez-Lille': 4828,
    'Marquillies': 4828,
    'Marquion': 4828,
    'Marquise': 4828,
    'Masnières': 4828,
    'Masny': 4828,
    'Maubeuge': 4828,
    'Mazingarbe': 4828,
    'Méaulte': 4828,
    'Méricourt': 4828,
    'Mérignies': 4828,
    'Merlimont': 4828,
    'Mers-les-Bains': 4828,
    'Méru': 4828,
    'Merville': 4828,
    'Méteren': 4828,
    'Meurchin': 4828,
    'Milly-sur-Thérain': 4828,
    'Mogneville': 4828,
    'Moislains': 4828,
    'Monchecourt': 4828,
    'Monchy-Saint-Éloi': 4828,
    'Mons-en-Barœul': 4828,
    'Mons-en-Pévèle': 4828,
    'Mont-Bernanchon': 4828,
    'Mont-Saint-Éloi': 4828,
    'Montataire': 4828,
    'Montcornet': 4828,
    'Montdidier': 4828,
    'Montescourt-Lizerolles': 4828,
    'Montigny-en-Gohelle': 4828,
    'Montigny-en-Ostrevent': 4828,
    'Montmacq': 4828,
    'Montreuil': 4828,
    'Montreuil-aux-Lions': 4828,
    'Morbecque': 4828,
    'Moreuil': 4828,
    'Morienval': 4828,
    'Mortagne-du-Nord': 4828,
    'Mouchin': 4828,
    'Mouvaux': 4828,
    'Mouy': 4828,
    'Nanteuil-le-Haudouin': 4828,
    'Naours': 4828,
    'Nesle': 4828,
    'Nesles': 4828,
    'Nesles-la-Montagne': 4828,
    'Neuf-Berquin': 4828,
    'Neufchâtel-Hardelot': 4828,
    'Neuilly-en-Thelle': 4828,
    'Neuilly-Saint-Front': 4828,
    'Neuilly-sous-Clermont': 4828,
    'Neuville-en-Ferrain': 4828,
    'Neuville-Saint-Rémy': 4828,
    'Neuville-Saint-Vaast': 4828,
    'Neuville-sur-Escaut': 4828,
    'Nieppe': 4828,
    'Noailles': 4828,
    'Nœux-les-Mines': 4828,
    'Nogent-l’Artaud': 4828,
    'Nogent-sur-Oise': 4828,
    'Nogentel': 4828,
    'Nomain': 4828,
    'Nord': 4828,
    'Norrent-Fontes': 4828,
    'Nortkerque': 4828,
    'Nouvion': 4828,
    'Noyelles-Godault': 4828,
    'Noyelles-lès-Vermelles': 4828,
    'Noyelles-sous-Lens': 4828,
    'Noyon': 4828,
    'Ognes': 4828,
    'Oignies': 4828,
    'Oisemont': 4828,
    'Oisy-le-Verger': 4828,
    'Omissy': 4828,
    'Onnaing': 4828,
    'Ons-en-Bray': 4828,
    'Orchies': 4828,
    'Origny-en-Thiérache': 4828,
    'Origny-Sainte-Benoite': 4828,
    'Orry-la-Ville': 4828,
    'Ostricourt': 4828,
    'Outreau': 4828,
    'Oye-Plage': 4828,
    'Pas-de-Calais': 4828,
    'Pasly': 4828,
    'Pecquencourt': 4828,
    'Pendé': 4828,
    'Pérenchies': 4828,
    'Pernes': 4828,
    'Péronne': 4828,
    'Péroy-les-Gombries': 4828,
    'Petite-Forêt': 4828,
    'Phalempin': 4828,
    'Picquigny': 4828,
    'Pierrefonds': 4828,
    'Pinon': 4828,
    'Plailly': 4828,
    'Poix-de-Picardie': 4828,
    'Poix-du-Nord': 4828,
    'Ponchon': 4828,
    'Pont-à-Marcq': 4828,
    'Pont-à-Vendin': 4828,
    'Pont-de-Metz': 4828,
    'Pont-Remy': 4828,
    'Pont-Sainte-Maxence': 4828,
    'Pont-sur-Sambre': 4828,
    'Pontpoint': 4828,
    'Poulainville': 4828,
    'Précy-sur-Oise': 4828,
    'Prémesques': 4828,
    'Préseau': 4828,
    'Prouvy': 4828,
    'Proville': 4828,
    'Provin': 4828,
    'Quaëdypre': 4828,
    'Quarouble': 4828,
    'Quend': 4828,
    'Quesnoy-sur-Deûle': 4828,
    'Quevauvillers': 4828,
    'Quiévrechain': 4828,
    'Quiévy': 4828,
    'Râches': 4828,
    'Racquinghem': 4828,
    'Radinghem-en-Weppes': 4828,
    'Raillencourt-Sainte-Olle': 4828,
    'Raimbeaucourt': 4828,
    'Raismes': 4828,
    'Ranchicourt': 4828,
    'Rang-du-Fliers': 4828,
    'Rantigny': 4828,
    'Ravenel': 4828,
    'Recquignies': 4828,
    'Remy': 4828,
    'Renescure': 4828,
    'Ressons-sur-Matz': 4828,
    'Rety': 4828,
    'Rexpoëde': 4828,
    'Ribemont': 4828,
    'Richebourg': 4828,
    'Richebourg-l  Avoué': 4828,
    'Rieulay': 4828,
    'Rieux': 4828,
    'Rieux-en-Cambrésis': 4828,
    'Rinxent': 4828,
    'Rivery': 4828,
    'Rivière': 4828,
    'Robecq': 4828,
    'Rœulx': 4828,
    'Rœux': 4828,
    'Roisel': 4828,
    'Ronchin': 4828,
    'Roncq': 4828,
    'Roost-Warendin': 4828,
    'Roquetoire': 4828,
    'Rosières-en-Santerre': 4828,
    'Rosult': 4828,
    'Roubaix': 4828,
    'Rousies': 4828,
    'Rouvroy': 4828,
    'Roye': 4828,
    'Rozoy-sur-Serre': 4828,
    'Rue': 4828,
    'Ruitz': 4828,
    'Rumegies': 4828,
    'Rumilly-en-Cambrésis': 4828,
    'Ruminghem': 4828,
    'Sacy-le-Grand': 4828,
    'Sailly-Flibeaucourt': 4828,
    'Sailly-Labourse': 4828,
    'Sailly-lez-Lannoy': 4828,
    'Sailly-sur-la-Lys': 4828,
    'Sainghin-en-Mélantois': 4828,
    'Sainghin-en-Weppes': 4828,
    'Sains-du-Nord': 4828,
    'Sains-en-Amiénois': 4828,
    'Sains-en-Gohelle': 4828,
    'Saint-Amand-les-Eaux': 4828,
    'Saint-André-lez-Lille': 4828,
    'Saint-Aubert': 4828,
    'Saint-Aubin-en-Bray': 4828,
    'Saint-Crépin-Ibouvillers': 4828,
    'Saint-Erme-Outre-et-Ramecourt': 4828,
    'Saint-Étienne-au-Mont': 4828,
    'Saint-Folquin': 4828,
    'Saint-Germer-de-Fly': 4828,
    'Saint-Gobain': 4828,
    'Saint-Hilaire-lez-Cambrai': 4828,
    'Saint-Jans-Cappel': 4828,
    'Saint-Josse': 4828,
    'Saint-Just-en-Chaussée': 4828,
    'Saint-Laurent-Blangy': 4828,
    'Saint-Léger-lès-Domart': 4828,
    'Saint-Léonard': 4828,
    'Saint-Martin-au-Laërt': 4828,
    'Saint-Martin-Boulogne': 4828,
    'Saint-Martin-Longueau': 4828,
    'Saint-Maximin': 4828,
    'Saint-Michel': 4828,
    'Saint-Nicolas': 4828,
    'Saint-Omer': 4828,
    'Saint-Omer-en-Chaussée': 4828,
    'Saint-Ouen': 4828,
    'Saint-Paul': 4828,
    'Saint-Pol-sur-Mer': 4828,
    'Saint-Pol-sur-Ternoise': 4828,
    'Saint-Python': 4828,
    'Saint-Quentin': 4828,
    'Saint-Riquier': 4828,
    'Saint-Saulve': 4828,
    'Saint-Sauveur': 4828,
    'Saint-Souplet': 4828,
    'Saint-Sulpice': 4828,
    'Saint-Sylvestre-Cappel': 4828,
    'Saint-Valery-sur-Somme': 4828,
    'Saint-Venant': 4828,
    'Sainte-Catherine': 4828,
    'Sainte-Geneviève': 4828,
    'Sainte-Marie-Kerque': 4828,
    'Saleux': 4828,
    'Sallaumines': 4828,
    'Salomé': 4828,
    'Salouël': 4828,
    'Saméon': 4828,
    'Samer': 4828,
    'Sangatte': 4828,
    'Santes': 4828,
    'Sars-Poteries': 4828,
    'Saultain': 4828,
    'Saulzoir': 4828,
    'Savy-Berlette': 4828,
    'Seboncourt': 4828,
    'Sebourg': 4828,
    'Seclin': 4828,
    'Selvigny': 4828,
    'Senlis': 4828,
    'Sequedin': 4828,
    'Sérifontaine': 4828,
    'Serques': 4828,
    'Silly-le-Long': 4828,
    'Sin-le-Noble': 4828,
    'Sinceny': 4828,
    'Sissonne': 4828,
    'Socx': 4828,
    'Soissons': 4828,
    'Solesmes': 4828,
    'Solre-le-Château': 4828,
    'Somain': 4828,
    'Somme': 4828,
    'Songeons': 4828,
    'Souchez': 4828,
    'Spycker': 4828,
    'Steenbecque': 4828,
    'Steene': 4828,
    'Steenvoorde': 4828,
    'Steenwerck': 4828,
    'Talmas': 4828,
    'Tatinghem': 4828,
    'Templemars': 4828,
    'Templeuve-en-Pévèle': 4828,
    'Tergnier': 4828,
    'Téteghem': 4828,
    'Thélus': 4828,
    'Thérouanne': 4828,
    'Thiant': 4828,
    'Thiers-sur-Thève': 4828,
    'Thiverny': 4828,
    'Thourotte': 4828,
    'Thumeries': 4828,
    'Thun-Saint-Amand': 4828,
    'Tillé': 4828,
    'Tilloy-lès-Mofflaines': 4828,
    'Tilques': 4828,
    'Toufflers': 4828,
    'Tourcoing': 4828,
    'Tournehem-sur-la-Hem': 4828,
    'Tracy-le-Mont': 4828,
    'Trélon': 4828,
    'Tressin': 4828,
    'Tricot': 4828,
    'Trie-Château': 4828,
    'Trith-Saint-Léger': 4828,
    'Troissereux': 4828,
    'Trosly-Breuil': 4828,
    'Ully-Saint-Georges': 4828,
    'Uxem': 4828,
    'Vailly-sur-Aisne': 4828,
    'Valenciennes': 4828,
    'Vaulx-Vraucourt': 4828,
    'Vaumoise': 4828,
    'Vendegies-sur-Écaillon': 4828,
    'Vendeville': 4828,
    'Vendin-le-Vieil': 4828,
    'Vendin-lès-Béthune': 4828,
    'Venette': 4828,
    'Venizel': 4828,
    'Ver-sur-Launette': 4828,
    'Verberie': 4828,
    'Verlinghem': 4828,
    'Vermand': 4828,
    'Vermelles': 4828,
    'Verneuil-en-Halatte': 4828,
    'Verquin': 4828,
    'Verton': 4828,
    'Vervins': 4828,
    'Vic-sur-Aisne': 4828,
    'Vicq': 4828,
    'Vieille-Église': 4828,
    'Viesly': 4828,
    'Vieux-Berquin': 4828,
    'Vieux-Condé': 4828,
    'Vignacourt': 4828,
    'Villeneuve-d  Ascq': 4828,
    'Villeneuve-les-Sablons': 4828,
    'Villeneuve-Saint-Germain': 4828,
    'Villers-Bocage': 4828,
    'Villers-Bretonneux': 4828,
    'Villers-Cotterêts': 4828,
    'Villers-en-Cauchies': 4828,
    'Villers-Outréaux': 4828,
    'Villers-Pol': 4828,
    'Villers-Saint-Paul': 4828,
    'Villers-sous-Saint-Leu': 4828,
    'Villers-sur-Coudun': 4828,
    'Vimy': 4828,
    'Vineuil-Saint-Firmin': 4828,
    'Violaines': 4828,
    'Viry-Noureuil': 4828,
    'Vitry-en-Artois': 4828,
    'Vred': 4828,
    'Wahagnies': 4828,
    'Wailly': 4828,
    'Walincourt-Selvigny': 4828,
    'Wallers': 4828,
    'Wambrechies': 4828,
    'Wandignies-Hamage': 4828,
    'Wardrecques': 4828,
    'Wargnies-le-Grand': 4828,
    'Warhem': 4828,
    'Warluis': 4828,
    'Wasquehal': 4828,
    'Wassigny': 4828,
    'Watten': 4828,
    'Wattignies': 4828,
    'Wattrelos': 4828,
    'Wavignies': 4828,
    'Wavrechain-sous-Denain': 4828,
    'Wavrin': 4828,
    'Waziers': 4828,
    'Wervicq-Sud': 4828,
    'Wignehies': 4828,
    'Willems': 4828,
    'Wimereux': 4828,
    'Wimille': 4828,
    'Wingles': 4828,
    'Winnezeele': 4828,
    'Wissant': 4828,
    'Wizernes': 4828,
    'Woincourt': 4828,
    'Wormhout': 4828,
    'Zegerscappel': 4828,
    'Zutkerque': 4828,
    'Zuydcoote': 4828,
    'Ableiges': 4796,
    'Ablis': 4796,
    'Ablon-sur-Seine': 4796,
    'Achères': 4796,
    'Achères-la-Forêt': 4796,
    'Alfortville': 4796,
    'Andilly': 4796,
    'Andrésy': 4796,
    'Angerville': 4796,
    'Angervilliers': 4796,
    'Annet-sur-Marne': 4796,
    'Antony': 4796,
    'Arbonne-la-Forêt': 4796,
    'Arcueil': 4796,
    'Argenteuil': 4796,
    'Armentières-en-Brie': 4796,
    'Arnouville': 4796,
    'Arpajon': 4796,
    'Asnières-sur-Oise': 4796,
    'Asnières-sur-Seine': 4796,
    'Athis-Mons': 4796,
    'Attainville': 4796,
    'Aubergenville': 4796,
    'Aubervilliers': 4796,
    'Auffargis': 4796,
    'Aulnay-sous-Bois': 4796,
    'Aulnay-sur-Mauldre': 4796,
    'Auvers-Saint-Georges': 4796,
    'Auvers-sur-Oise': 4796,
    'Avon': 4796,
    'Bagneaux-sur-Loing': 4796,
    'Bagneux': 4796,
    'Bagnolet': 4796,
    'Baillet-en-France': 4796,
    'Bailly': 4796,
    'Bailly-Carrois': 4796,
    'Bailly-Romainvilliers': 4796,
    'Ballainvilliers': 4796,
    'Ballancourt-sur-Essonne': 4796,
    'Barbizon': 4796,
    'Baulne': 4796,
    'Bazainville': 4796,
    'Bazemont': 4796,
    'Beauchamp': 4796,
    'Beaumont-du-Gâtinais': 4796,
    'Beaumont-sur-Oise': 4796,
    'Belloy-en-France': 4796,
    'Bennecourt': 4796,
    'Bernes-sur-Oise': 4796,
    'Bessancourt': 4796,
    'Beynes': 4796,
    'Bezons': 4796,
    'Bièvres': 4796,
    'Bobigny': 4796,
    'Bois-Colombes': 4796,
    'Bois-d’Arcy': 4796,
    'Bois-le-Roi': 4796,
    'Boissise-le-Roi': 4796,
    'Boissy-le-Châtel': 4796,
    'Boissy-le-Cutté': 4796,
    'Boissy-Saint-Léger': 4796,
    'Boissy-sous-Saint-Yon': 4796,
    'Bondoufle': 4796,
    'Bondy': 4796,
    'Bonnelles': 4796,
    'Bonneuil-sur-Marne': 4796,
    'Bonnières-sur-Seine': 4796,
    'Bouafle': 4796,
    'Bouffémont': 4796,
    'Bougival': 4796,
    'Bouleurs': 4796,
    'Boulogne-Billancourt': 4796,
    'Bouray-sur-Juine': 4796,
    'Bourg-la-Reine': 4796,
    'Bourron-Marlotte': 4796,
    'Boussy-Saint-Antoine': 4796,
    'Boutigny-sur-Essonne': 4796,
    'Bray-sur-Seine': 4796,
    'Brétigny-sur-Orge': 4796,
    'Breuillet': 4796,
    'Bréval': 4796,
    'Brie-Comte-Robert': 4796,
    'Briis-sous-Forges': 4796,
    'Brou-sur-Chantereine': 4796,
    'Brunoy': 4796,
    'Bruyères-le-Châtel': 4796,
    'Bruyères-sur-Oise': 4796,
    'Bry-sur-Marne': 4796,
    'Buc': 4796,
    'Buchelay': 4796,
    'Bullion': 4796,
    'Bures-sur-Yvette': 4796,
    'Bussy-Saint-Georges': 4796,
    'Butry-sur-Oise': 4796,
    'Cachan': 4796,
    'Cannes-Écluse': 4796,
    'Carrières-sous-Poissy': 4796,
    'Carrières-sur-Seine': 4796,
    'Cély': 4796,
    'Cergy': 4796,
    'Cergy-Pontoise': 4796,
    'Cernay-la-Ville': 4796,
    'Cerny': 4796,
    'Cesson': 4796,
    'Chailly-en-Bière': 4796,
    'Chailly-en-Brie': 4796,
    'Chalifert': 4796,
    'Chalo-Saint-Mars': 4796,
    'Chamarande': 4796,
    'Chambourcy': 4796,
    'Chamigny': 4796,
    'Champagne-sur-Oise': 4796,
    'Champagne-sur-Seine': 4796,
    'Champcueil': 4796,
    'Champigny-sur-Marne': 4796,
    'Champlan': 4796,
    'Champs-sur-Marne': 4796,
    'Changis-sur-Marne': 4796,
    'Chanteloup-en-Brie': 4796,
    'Chanteloup-les-Vignes': 4796,
    'Chapet': 4796,
    'Charenton-le-Pont': 4796,
    'Charny': 4796,
    'Chars': 4796,
    'Chartrettes': 4796,
    'Château-Landon': 4796,
    'Châteaufort': 4796,
    'Châtenay-Malabry': 4796,
    'Châtenay-sur-Seine': 4796,
    'Châtillon': 4796,
    'Chatou': 4796,
    'Chauconin-Neufmontiers': 4796,
    'Chaumes-en-Brie': 4796,
    'Chaumontel': 4796,
    'Chavenay': 4796,
    'Chaville': 4796,
    'Chelles': 4796,
    'Chennevières-sur-Marne': 4796,
    'Chenoise': 4796,
    'Cheptainville': 4796,
    'Chessy': 4796,
    'Chevannes': 4796,
    'Chevilly-Larue': 4796,
    'Chevreuse': 4796,
    'Chevry-Cossigny': 4796,
    'Chilly-Mazarin': 4796,
    'Choisy-en-Brie': 4796,
    'Choisy-le-Roi': 4796,
    'Clamart': 4796,
    'Claye-Souilly': 4796,
    'Clichy': 4796,
    'Clichy-sous-Bois': 4796,
    'Coignières': 4796,
    'Collégien': 4796,
    'Colombes': 4796,
    'Combs-la-Ville': 4796,
    'Conches-sur-Gondoire': 4796,
    'Condé-Sainte-Libiaire': 4796,
    'Condé-sur-Vesgre': 4796,
    'Conflans-Sainte-Honorine': 4796,
    'Congis-sur-Thérouanne': 4796,
    'Corbeil-Essonnes': 4796,
    'Corbreuse': 4796,
    'Cormeilles-en-Parisis': 4796,
    'Coubert': 4796,
    'Coubron': 4796,
    'Couilly-Pont-aux-Dames': 4796,
    'Coulommiers': 4796,
    'Coupvray': 4796,
    'Courbevoie': 4796,
    'Courcouronnes': 4796,
    'Courdimanche': 4796,
    'Courpalay': 4796,
    'Courtry': 4796,
    'Crégy-lès-Meaux': 4796,
    'Crespières': 4796,
    'Créteil': 4796,
    'Croissy-Beaubourg': 4796,
    'Croissy-sur-Seine': 4796,
    'Crosne': 4796,
    'Crouy-sur-Ourcq': 4796,
    'Dammarie-les-Lys': 4796,
    'Dammartin-en-Goële': 4796,
    'Dampierre-en-Yvelines': 4796,
    'Dampmart': 4796,
    'Département de l  Essonne': 4796,
    'Département du Val-d’Oise': 4796,
    'Deuil-la-Barre': 4796,
    'Domont': 4796,
    'Donnemarie-Dontilly': 4796,
    'Doue': 4796,
    'Dourdan': 4796,
    'Drancy': 4796,
    'Draveil': 4796,
    'Dugny': 4796,
    'Eaubonne': 4796,
    'Écouen': 4796,
    'Ecquevilly': 4796,
    'Écuelles': 4796,
    'Égly': 4796,
    'Égreville': 4796,
    'Élancourt': 4796,
    'Émerainville': 4796,
    'Enghien-les-Bains': 4796,
    'Ennery': 4796,
    'Épinay-sous-Sénart': 4796,
    'Épinay-sur-Orge': 4796,
    'Épinay-sur-Seine': 4796,
    'Épône': 4796,
    'Éragny': 4796,
    'Ermont': 4796,
    'Esbly': 4796,
    'Étampes': 4796,
    'Étiolles': 4796,
    'Étréchy': 4796,
    'Évry': 4796,
    'Ézanville': 4796,
    'Faremoutiers': 4796,
    'Favières': 4796,
    'Férolles-Attilly': 4796,
    'Ferrières-en-Brie': 4796,
    'Feucherolles': 4796,
    'Fleury-Mérogis': 4796,
    'Flins-sur-Seine': 4796,
    'Follainville-Dennemont': 4796,
    'Fontainebleau': 4796,
    'Fontenay-aux-Roses': 4796,
    'Fontenay-en-Parisis': 4796,
    'Fontenay-le-Fleury': 4796,
    'Fontenay-lès-Briis': 4796,
    'Fontenay-sous-Bois': 4796,
    'Fontenay-Trésigny': 4796,
    'Forges-les-Bains': 4796,
    'Fosses': 4796,
    'Fourqueux': 4796,
    'Franconville': 4796,
    'Freneuse': 4796,
    'Frépillon': 4796,
    'Fresnes': 4796,
    'Fublaines': 4796,
    'Gagny': 4796,
    'Galluis': 4796,
    'Gambais': 4796,
    'Garancières': 4796,
    'Garches': 4796,
    'Gargenville': 4796,
    'Garges-lès-Gonesse': 4796,
    'Gazeran': 4796,
    'Gennevilliers': 4796,
    'Gentilly': 4796,
    'Gif-sur-Yvette': 4796,
    'Gometz-la-Ville': 4796,
    'Gometz-le-Châtel': 4796,
    'Gonesse': 4796,
    'Gouaix': 4796,
    'Gournay-sur-Marne': 4796,
    'Goussainville': 4796,
    'Gouvernes': 4796,
    'Gretz-Armainvilliers': 4796,
    'Grez-sur-Loing': 4796,
    'Grigny': 4796,
    'Grisy-Suisnes': 4796,
    'Groslay': 4796,
    'Guérard': 4796,
    'Guermantes': 4796,
    'Guerville': 4796,
    'Guignes': 4796,
    'Guyancourt': 4796,
    'Hardricourt': 4796,
    'Hauts-de-Seine': 4796,
    'Herblay': 4796,
    'Héricy': 4796,
    'Houdan': 4796,
    'Houilles': 4796,
    'Igny': 4796,
    'Issou': 4796,
    'Issy-les-Moulineaux': 4796,
    'Itteville': 4796,
    'Ivry-sur-Seine': 4796,
    'Janville-sur-Juine': 4796,
    'Joinville-le-Pont': 4796,
    'Jouarre': 4796,
    'Jouars-Pontchartrain': 4796,
    'Jouy-en-Josas': 4796,
    'Jouy-le-Châtel': 4796,
    'Jouy-le-Moutier': 4796,
    'Jouy-sur-Morin': 4796,
    'Juilly': 4796,
    'Juvisy-sur-Orge': 4796,
    'Juziers': 4796,
    'L  Haÿ-les-Roses': 4796,
    'L’Étang-la-Ville': 4796,
    'L’Isle-Adam': 4796,
    'La Celle-Saint-Cloud': 4796,
    'La Celle-sur-Morin': 4796,
    'La Chapelle-Gauthier': 4796,
    'La Chapelle-la-Reine': 4796,
    'La Chapelle-sur-Crécy': 4796,
    'La Courneuve': 4796,
    'La Defense': 4796,
    'La Ferté-Alais': 4796,
    'La Ferté-Gaucher': 4796,
    'La Ferté-sous-Jouarre': 4796,
    'La Frette-sur-Seine': 4796,
    'La Garenne-Colombes': 4796,
    'La Grande-Paroisse': 4796,
    'La Houssaye-en-Brie': 4796,
    'La Norville': 4796,
    'La Queue-en-Brie': 4796,
    'La Queue-les-Yvelines': 4796,
    'La Rochette': 4796,
    'La Verrière': 4796,
    'La Ville-du-Bois': 4796,
    'Lagny-sur-Marne': 4796,
    'Lardy': 4796,
    'Le Blanc-Mesnil': 4796,
    'Le Bourget': 4796,
    'Le Châtelet-en-Brie': 4796,
    'Le Chesnay': 4796,
    'Le Coudray-Montceaux': 4796,
    'Le Kremlin-Bicêtre': 4796,
    'Le Mée-sur-Seine': 4796,
    'Le Mesnil-le-Roi': 4796,
    'Le Mesnil-Saint-Denis': 4796,
    'Le Pecq': 4796,
    'Le Perray-en-Yvelines': 4796,
    'Le Perreux-sur-Marne': 4796,
    'Le Pin': 4796,
    'Le Plessis-Bouchard': 4796,
    'Le Plessis-Pâté': 4796,
    'Le Plessis-Robinson': 4796,
    'Le Plessis-Trévise': 4796,
    'Le Port-Marly': 4796,
    'Le Pré-Saint-Gervais': 4796,
    'Le Raincy': 4796,
    'Le Thillay': 4796,
    'Le Val-Saint-Germain': 4796,
    'Le Vésinet': 4796,
    'Les Alluets-le-Roi': 4796,
    'Les Clayes-sous-Bois': 4796,
    'Les Essarts-le-Roi': 4796,
    'Les Lilas': 4796,
    'Les Loges-en-Josas': 4796,
    'Les Molières': 4796,
    'Les Mureaux': 4796,
    'Les Pavillons-sous-Bois': 4796,
    'Les Ulis': 4796,
    'Lésigny': 4796,
    'Leudeville': 4796,
    'Leuville-sur-Orge': 4796,
    'Levallois-Perret': 4796,
    'Lévis-Saint-Nom': 4796,
    'Lieusaint': 4796,
    'Limay': 4796,
    'Limeil-Brévannes': 4796,
    'Limetz-Villez': 4796,
    'Limours': 4796,
    'Linas': 4796,
    'Lisses': 4796,
    'Liverdy-en-Brie': 4796,
    'Livry-Gargan': 4796,
    'Livry-sur-Seine': 4796,
    'Lizy-sur-Ourcq': 4796,
    'Lognes': 4796,
    'Longjumeau': 4796,
    'Longnes': 4796,
    'Longperrier': 4796,
    'Longpont-sur-Orge': 4796,
    'Longueville': 4796,
    'Lorrez-le-Bocage-Préaux': 4796,
    'Louveciennes': 4796,
    'Louvres': 4796,
    'Lumigny-Nesles-Ormeaux': 4796,
    'Luzarches': 4796,
    'Maffliers': 4796,
    'Magnanville': 4796,
    'Magny-en-Vexin': 4796,
    'Magny-le-Hongre': 4796,
    'Magny-les-Hameaux': 4796,
    'Maincy': 4796,
    'Maisons-Alfort': 4796,
    'Maisons-Laffitte': 4796,
    'Maisse': 4796,
    'Malakoff': 4796,
    'Mandres-les-Roses': 4796,
    'Mantes-la-Jolie': 4796,
    'Mantes-la-Ville': 4796,
    'Marcoussis': 4796,
    'Mareil-Marly': 4796,
    'Mareil-sur-Mauldre': 4796,
    'Mareuil-lès-Meaux': 4796,
    'Margency': 4796,
    'Marines': 4796,
    'Marles-en-Brie': 4796,
    'Marly-la-Ville': 4796,
    'Marly-le-Roi': 4796,
    'Marnes-la-Coquette': 4796,
    'Marolles-en-Hurepoix': 4796,
    'Marolles-sur-Seine': 4796,
    'Mary-sur-Marne': 4796,
    'Massy': 4796,
    'Maule': 4796,
    'Maurecourt': 4796,
    'Maurepas': 4796,
    'Meaux': 4796,
    'Médan': 4796,
    'Melun': 4796,
    'Mennecy': 4796,
    'Menucourt': 4796,
    'Méré': 4796,
    'Méréville': 4796,
    'Mériel': 4796,
    'Méry-sur-Oise': 4796,
    'Meudon': 4796,
    'Meulan-en-Yvelines': 4796,
    'Mézières-sur-Seine': 4796,
    'Mézy-sur-Seine': 4796,
    'Milly-la-Forêt': 4796,
    'Mitry-Mory': 4796,
    'Moigny-sur-École': 4796,
    'Moisenay': 4796,
    'Moisselles': 4796,
    'Moissy-Cramayel': 4796,
    'Montcourt-Fromonville': 4796,
    'Montereau-Fault-Yonne': 4796,
    'Montesson': 4796,
    'Montévrain': 4796,
    'Montfermeil': 4796,
    'Montgeron': 4796,
    'Monthyon': 4796,
    'Montigny-le-Bretonneux': 4796,
    'Montigny-Lencoup': 4796,
    'Montigny-lès-Cormeilles': 4796,
    'Montigny-sur-Loing': 4796,
    'Montlhéry': 4796,
    'Montlignon': 4796,
    'Montmagny': 4796,
    'Montmorency': 4796,
    'Montreuil': 4796,
    'Montrouge': 4796,
    'Montry': 4796,
    'Montsoult': 4796,
    'Morainvilliers': 4796,
    'Morangis': 4796,
    'Moret-sur-Loing': 4796,
    'Morigny-Champigny': 4796,
    'Mormant': 4796,
    'Morsang-sur-Orge': 4796,
    'Mortcerf': 4796,
    'Mouroux': 4796,
    'Mours': 4796,
    'Moussy-le-Neuf': 4796,
    'Moussy-le-Vieux': 4796,
    'Nandy': 4796,
    'Nangis': 4796,
    'Nanterre': 4796,
    'Nanteuil-lès-Meaux': 4796,
    'Neauphle-le-Château': 4796,
    'Nemours': 4796,
    'Nesles-la-Vallée': 4796,
    'Neufmoutiers-en-Brie': 4796,
    'Neuilly-Plaisance': 4796,
    'Neuilly-sur-Marne': 4796,
    'Neuilly-sur-Seine': 4796,
    'Neuville-sur-Oise': 4796,
    'Nogent-sur-Marne': 4796,
    'Noiseau': 4796,
    'Noisiel': 4796,
    'Noisy-le-Grand': 4796,
    'Noisy-le-Roi': 4796,
    'Noisy-le-Sec': 4796,
    'Noisy-sur-École': 4796,
    'Nozay': 4796,
    'Oinville-sur-Montcient': 4796,
    'Oissery': 4796,
    'Ollainville': 4796,
    'Orgerus': 4796,
    'Orgeval': 4796,
    'Orly': 4796,
    'Ormesson-sur-Marne': 4796,
    'Ormoy': 4796,
    'Orsay': 4796,
    'Osny': 4796,
    'Othis': 4796,
    'Ozoir-la-Ferrière': 4796,
    'Ozouer-le-Voulgis': 4796,
    'Palaiseau': 4796,
    'Pantin': 4796,
    'Paray-Vieille-Poste': 4796,
    'Paris': 4796,
    'Parmain': 4796,
    'Périgny': 4796,
    'Persan': 4796,
    'Perthes': 4796,
    'Pierrefitte-sur-Seine': 4796,
    'Pierrelaye': 4796,
    'Plaisir': 4796,
    'Poissy': 4796,
    'Pommeuse': 4796,
    'Pomponne': 4796,
    'Pontault-Combault': 4796,
    'Pontcarré': 4796,
    'Ponthierry': 4796,
    'Pontoise': 4796,
    'Porcheville': 4796,
    'Presles-en-Brie': 4796,
    'Pringy': 4796,
    'Provins': 4796,
    'Puiseux-en-France': 4796,
    'Pussay': 4796,
    'Puteaux': 4796,
    'Quincy-sous-Sénart': 4796,
    'Quincy-Voisins': 4796,
    'Rambouillet': 4796,
    'Rebais': 4796,
    'Ris-Orangis': 4796,
    'Rocquencourt': 4796,
    'Roissy-en-Brie': 4796,
    'Roissy-en-France': 4796,
    'Romainville': 4796,
    'Rosny-sous-Bois': 4796,
    'Rosny-sur-Seine': 4796,
    'Rozay-en-Brie': 4796,
    'Rubelles': 4796,
    'Rueil-Malmaison': 4796,
    'Rungis': 4796,
    'Saâcy-sur-Marne': 4796,
    'Saclas': 4796,
    'Saclay': 4796,
    'Sagy': 4796,
    'Saint-Arnoult-en-Yvelines': 4796,
    'Saint-Augustin': 4796,
    'Saint-Brice-sous-Forêt': 4796,
    'Saint-Chéron': 4796,
    'Saint-Cloud': 4796,
    'Saint-Cyr-l’École': 4796,
    'Saint-Cyr-sous-Dourdan': 4796,
    'Saint-Cyr-sur-Morin': 4796,
    'Saint-Denis': 4796,
    'Saint-Fargeau-Ponthierry': 4796,
    'Saint-Germain-de-la-Grange': 4796,
    'Saint-Germain-en-Laye': 4796,
    'Saint-Germain-Laval': 4796,
    'Saint-Germain-lès-Arpajon': 4796,
    'Saint-Germain-lès-Corbeil': 4796,
    'Saint-Germain-sur-Morin': 4796,
    'Saint-Gratien': 4796,
    'Saint-Jean-les-Deux-Jumeaux': 4796,
    'Saint-Léger-en-Yvelines': 4796,
    'Saint-Leu-la-Forêt': 4796,
    'Saint-Mammès': 4796,
    'Saint-Mandé': 4796,
    'Saint-Mard': 4796,
    'Saint-Martin-du-Tertre': 4796,
    'Saint-Maur-des-Fossés': 4796,
    'Saint-Maurice': 4796,
    'Saint-Maurice-Montcouronne': 4796,
    'Saint-Michel-sur-Orge': 4796,
    'Saint-Nom-la-Bretêche': 4796,
    'Saint-Ouen': 4796,
    'Saint-Ouen-l’Aumône': 4796,
    'Saint-Pathus': 4796,
    'Saint-Pierre-du-Perray': 4796,
    'Saint-Pierre-lès-Nemours': 4796,
    'Saint-Prix': 4796,
    'Saint-Quentin-en-Yvelines': 4796,
    'Saint-Rémy-lès-Chevreuse': 4796,
    'Saint-Sauveur-sur-École': 4796,
    'Saint-Soupplets': 4796,
    'Saint-Thibault-des-Vignes': 4796,
    'Saint-Vrain': 4796,
    'Saint-Witz': 4796,
    'Sainte-Colombe': 4796,
    'Sainte-Geneviève-des-Bois': 4796,
    'Saintry-sur-Seine': 4796,
    'Saints': 4796,
    'Salins': 4796,
    'Sammeron': 4796,
    'Samois-sur-Seine': 4796,
    'Samoreau': 4796,
    'Sannois': 4796,
    'Santeny': 4796,
    'Sarcelles': 4796,
    'Sartrouville': 4796,
    'Saulx-les-Chartreux': 4796,
    'Savigny-le-Temple': 4796,
    'Savigny-sur-Orge': 4796,
    'Sceaux': 4796,
    'Seine-et-Marne': 4796,
    'Seine-Port': 4796,
    'Seine-Saint-Denis': 4796,
    'Septeuil': 4796,
    'Seraincourt': 4796,
    'Serris': 4796,
    'Servon': 4796,
    'Seugy': 4796,
    'Sevran': 4796,
    'Sèvres': 4796,
    'Soignolles-en-Brie': 4796,
    'Soisy-sous-Montmorency': 4796,
    'Soisy-sur-École': 4796,
    'Soisy-sur-Seine': 4796,
    'Solers': 4796,
    'Sonchamp': 4796,
    'Souppes-sur-Loing': 4796,
    'Sourdun': 4796,
    'Stains': 4796,
    'Sucy-en-Brie': 4796,
    'Suresnes': 4796,
    'Survilliers': 4796,
    'Tacoignières': 4796,
    'Taverny': 4796,
    'Thiais': 4796,
    'Thiverval-Grignon': 4796,
    'Thoiry': 4796,
    'Thomery': 4796,
    'Thorigny-sur-Marne': 4796,
    'Tigery': 4796,
    'Torcy': 4796,
    'Touquin': 4796,
    'Tournan-en-Brie': 4796,
    'Trappes': 4796,
    'Tremblay-en-France': 4796,
    'Triel-sur-Seine': 4796,
    'Trilport': 4796,
    'Us': 4796,
    'Vaires-sur-Marne': 4796,
    'Val-de-Marne': 4796,
    'Valenton': 4796,
    'Valmondois': 4796,
    'Vanves': 4796,
    'Varennes-Jarcy': 4796,
    'Varennes-sur-Seine': 4796,
    'Varreddes': 4796,
    'Vaucresson': 4796,
    'Vaugrigneuse': 4796,
    'Vauhallan': 4796,
    'Vaujours': 4796,
    'Vauréal': 4796,
    'Vaux-le-Pénil': 4796,
    'Vaux-sur-Seine': 4796,
    'Vélizy-Villacoublay': 4796,
    'Vémars': 4796,
    'Veneux-les-Sablons': 4796,
    'Verneuil-sur-Seine': 4796,
    'Vernou-la-Celle-sur-Seine': 4796,
    'Vernouillet': 4796,
    'Verrières-le-Buisson': 4796,
    'Versailles': 4796,
    'Vert-le-Grand': 4796,
    'Vert-le-Petit': 4796,
    'Vert-Saint-Denis': 4796,
    'Viarmes': 4796,
    'Vigneux-sur-Seine': 4796,
    'Vigny': 4796,
    'Villabé': 4796,
    'Ville-d’Avray': 4796,
    'Villebon-sur-Yvette': 4796,
    'Villecresnes': 4796,
    'Villejuif': 4796,
    'Villejust': 4796,
    'Villemoisson-sur-Orge': 4796,
    'Villemomble': 4796,
    'Villeneuve-la-Garenne': 4796,
    'Villeneuve-le-Comte': 4796,
    'Villeneuve-le-Roi': 4796,
    'Villeneuve-Saint-Georges': 4796,
    'Villeneuve-sur-Bellot': 4796,
    'Villennes-sur-Seine': 4796,
    'Villenoy': 4796,
    'Villeparisis': 4796,
    'Villepinte': 4796,
    'Villepreux': 4796,
    'Villetaneuse': 4796,
    'Villevaudé': 4796,
    'Villiers-le-Bâcle': 4796,
    'Villiers-le-Bel': 4796,
    'Villiers-Saint-Fréderic': 4796,
    'Villiers-Saint-Georges': 4796,
    'Villiers-sur-Marne': 4796,
    'Villiers-sur-Morin': 4796,
    'Villiers-sur-Orge': 4796,
    'Vincennes': 4796,
    'Viroflay': 4796,
    'Viry-Châtillon': 4796,
    'Vitry-sur-Seine': 4796,
    'Voisenon': 4796,
    'Voisins-le-Bretonneux': 4796,
    'Voulangis': 4796,
    'Voulx': 4796,
    'Vulaines-sur-Seine': 4796,
    'Wissous': 4796,
    'Yerres': 4796,
    'Yvelines': 4796,
    'Ablon': 4804,
    'Acquigny': 4804,
    'Agneaux': 4804,
    'Agon-Coutainville': 4804,
    'Alençon': 4804,
    'Alizay': 4804,
    'Amfreville': 4804,
    'Amfreville-la-Mi-Voie': 4804,
    'Andé': 4804,
    'Angerville-l’Orcher': 4804,
    'Argences': 4804,
    'Argentan': 4804,
    'Arnières-sur-Iton': 4804,
    'Arques-la-Bataille': 4804,
    'Athis-de-l  Orne': 4804,
    'Aube-sur-Rîle': 4804,
    'Aubevoye': 4804,
    'Auffay': 4804,
    'Aumale': 4804,
    'Aunay-sur-Odon': 4804,
    'Authie': 4804,
    'Auzebosc': 4804,
    'Aviron': 4804,
    'Avranches': 4804,
    'Bacqueville-en-Caux': 4804,
    'Bagnoles-de-l  Orne': 4804,
    'Barentin': 4804,
    'Barenton': 4804,
    'Barneville-Carteret': 4804,
    'Barneville-Plage': 4804,
    'Bavent': 4804,
    'Bayeux': 4804,
    'Beaumont-Hague': 4804,
    'Beaumont-le-Roger': 4804,
    'Belbeuf': 4804,
    'Bellême': 4804,
    'Bellengreville': 4804,
    'Bénouville': 4804,
    'Bernay': 4804,
    'Berneval-le-Grand': 4804,
    'Bernières-sur-Mer': 4804,
    'Beuville': 4804,
    'Beuvillers': 4804,
    'Beuzeville': 4804,
    'Beuzeville-la-Grenier': 4804,
    'Bézu-Saint-Éloi': 4804,
    'Biéville-Beuville': 4804,
    'Bihorel': 4804,
    'Blainville-Crevon': 4804,
    'Blainville-sur-Mer': 4804,
    'Blainville-sur-Orne': 4804,
    'Blangy-sur-Bresle': 4804,
    'Blonville-sur-Mer': 4804,
    'Bois-Guillaume': 4804,
    'Bolbec': 4804,
    'Boos': 4804,
    'Bosc-le-Hard': 4804,
    'Bourg-Achard': 4804,
    'Bourgtheroulde-Infreville': 4804,
    'Bourguébus': 4804,
    'Bourth': 4804,
    'Bouville': 4804,
    'Bréauté': 4804,
    'Brécey': 4804,
    'Bréhal': 4804,
    'Breteuil': 4804,
    'Bretoncelles': 4804,
    'Bretteville': 4804,
    'Bretteville-du-Grand-Caux': 4804,
    'Bretteville-l’Orgueilleuse': 4804,
    'Bretteville-sur-Laize': 4804,
    'Bretteville-sur-Odon': 4804,
    'Breuilpont': 4804,
    'Bricquebec': 4804,
    'Brionne': 4804,
    'Briouze': 4804,
    'Brix': 4804,
    'Broglie': 4804,
    'Buchy': 4804,
    'Bueil': 4804,
    'Bully': 4804,
    'Cabourg': 4804,
    'Caen': 4804,
    'Cagny': 4804,
    'Cahagnes': 4804,
    'Cairon': 4804,
    'Calvados': 4804,
    'Cambes-en-Plaine': 4804,
    'Cambremer': 4804,
    'Canon': 4804,
    'Canteleu': 4804,
    'Cany-Barville': 4804,
    'Carentan': 4804,
    'Carpiquet': 4804,
    'Caudebec-en-Caux': 4804,
    'Caudebec-lès-Elbeuf': 4804,
    'Caumont': 4804,
    'Caumont-l  Éventé': 4804,
    'Cauville-sur-Mer': 4804,
    'Ceaucé': 4804,
    'Cérences': 4804,
    'Cerisy-la-Salle': 4804,
    'Ceton': 4804,
    'Champsecret': 4804,
    'Chanu': 4804,
    'Charleval': 4804,
    'Cherbourg-Octeville': 4804,
    'Cheux': 4804,
    'Claville': 4804,
    'Clécy': 4804,
    'Cléon': 4804,
    'Clères': 4804,
    'Clinchamps-sur-Orne': 4804,
    'Colleville-Montgomery': 4804,
    'Colombelles': 4804,
    'Conches-en-Ouche': 4804,
    'Condé-sur-Huisne': 4804,
    'Condé-sur-Noireau': 4804,
    'Condé-sur-Sarthe': 4804,
    'Condé-sur-Vire': 4804,
    'Cormeilles': 4804,
    'Cormelles-le-Royal': 4804,
    'Corneville-sur-Risle': 4804,
    'Courcelles-sur-Seine': 4804,
    'Courseulles-sur-Mer': 4804,
    'Courteilles': 4804,
    'Coutances': 4804,
    'Couterne': 4804,
    'Créances': 4804,
    'Cresserons': 4804,
    'Creully': 4804,
    'Criel-sur-Mer': 4804,
    'Criquebeuf-sur-Seine': 4804,
    'Criquetot-l’Esneval': 4804,
    'Croth': 4804,
    'Cuverville': 4804,
    'Damigny': 4804,
    'Damville': 4804,
    'Darnétal': 4804,
    'Deauville': 4804,
    'Démouville': 4804,
    'Département de l  Eure': 4804,
    'Département de l  Orne': 4804,
    'Déville-lès-Rouen': 4804,
    'Dieppe': 4804,
    'Digosville': 4804,
    'Dives-sur-Mer': 4804,
    'Domfront': 4804,
    'Donville-les-Bains': 4804,
    'Doudeville': 4804,
    'Douvres-la-Délivrande': 4804,
    'Dozulé': 4804,
    'Ducey': 4804,
    'Duclair': 4804,
    'Écouché': 4804,
    'Écrainville': 4804,
    'Elbeuf': 4804,
    'Envermeu': 4804,
    'Épaignes': 4804,
    'Épouville': 4804,
    'Épron': 4804,
    'Équemauville': 4804,
    'Équeurdreville-Hainneville': 4804,
    'Eslettes': 4804,
    'Étainhus': 4804,
    'Éterville': 4804,
    'Étrépagny': 4804,
    'Étretat': 4804,
    'Eu': 4804,
    'Évrecy': 4804,
    'Évreux': 4804,
    'Ézy-sur-Eure': 4804,
    'Falaise': 4804,
    'Fauville-en-Caux': 4804,
    'Fécamp': 4804,
    'Fermanville': 4804,
    'Flamanville': 4804,
    'Flers': 4804,
    'Fleury-sur-Andelle': 4804,
    'Fleury-sur-Orne': 4804,
    'Fontaine-Étoupefour': 4804,
    'Fontaine-la-Mallet': 4804,
    'Fontaine-le-Bourg': 4804,
    'Fontaine-le-Dun': 4804,
    'Fontenay': 4804,
    'Fontenay-le-Marmion': 4804,
    'Forges-les-Eaux': 4804,
    'Foucarmont': 4804,
    'Francheville': 4804,
    'Gacé': 4804,
    'Gaillefontaine': 4804,
    'Gaillon': 4804,
    'Gainneville': 4804,
    'Garennes-sur-Eure': 4804,
    'Gasny': 4804,
    'Gavray': 4804,
    'Giberville': 4804,
    'Gisors': 4804,
    'Goderville': 4804,
    'Gonneville-la-Mallet': 4804,
    'Gournay-en-Bray': 4804,
    'Gouville-sur-Mer': 4804,
    'Grainville-la-Teinturière': 4804,
    'Grainville-sur-Odon': 4804,
    'Grand-Couronne': 4804,
    'Grandcamp-Maisy': 4804,
    'Granville': 4804,
    'Gravigny': 4804,
    'Gruchet-le-Valasse': 4804,
    'Guichainville': 4804,
    'Hambye': 4804,
    'Harfleur': 4804,
    'Hautot-sur-Mer': 4804,
    'Hauville': 4804,
    'Hénouville': 4804,
    'Hermanville-sur-Mer': 4804,
    'Hérouville-Saint-Clair': 4804,
    'Hérouvillette': 4804,
    'Heudreville-sur-Eure': 4804,
    'Honfleur': 4804,
    'Houlbec-Cocherel': 4804,
    'Houlgate': 4804,
    'Houppeville': 4804,
    'Ifs': 4804,
    'Igoville': 4804,
    'Incheville': 4804,
    'Isigny-le-Buat': 4804,
    'Isigny-sur-Mer': 4804,
    'Isneauville': 4804,
    'Ivry-la-Bataille': 4804,
    'Jullouville': 4804,
    'Jumièges': 4804,
    'Juvigny-sous-Andaine': 4804,
    'La Bonneville-sur-Iton': 4804,
    'La Cerlangue': 4804,
    'La Chapelle-Réanville': 4804,
    'La Couture-Boussey': 4804,
    'La Ferrière-aux-Étangs': 4804,
    'La Ferté-Macé': 4804,
    'La Feuillie': 4804,
    'La Glacerie': 4804,
    'La Haye-du-Puits': 4804,
    'La Haye-Malherbe': 4804,
    'La Haye-Pesnel': 4804,
    'La Lande-Patry': 4804,
    'La Madeleine-de-Nonancourt': 4804,
    'La Mailleraye-sur-Seine': 4804,
    'La Meauffe': 4804,
    'La Remuée': 4804,
    'La Rivière-Saint-Sauveur': 4804,
    'La Saussaye': 4804,
    'La Selle-la-Forge': 4804,
    'La Vaupalière': 4804,
    'La Vespière': 4804,
    'Langrune-sur-Mer': 4804,
    'Lanquetot': 4804,
    'Le Bosc-Roger-en-Roumois': 4804,
    'Le Grand-Quevilly': 4804,
    'Le Havre': 4804,
    'Le Houlme': 4804,
    'Le Manoir': 4804,
    'Le Mesnil-Esnard': 4804,
    'Le Molay-Littry': 4804,
    'Le Neubourg': 4804,
    'Le Petit-Quevilly': 4804,
    'Le Teilleul': 4804,
    'Le Theil-Bocage': 4804,
    'Le Thuit-Signol': 4804,
    'Le Trait': 4804,
    'Le Tréport': 4804,
    'Le Val-Saint-Père': 4804,
    'Le Vaudreuil': 4804,
    'Léry': 4804,
    'Les Andelys': 4804,
    'Les Authieux-sur-le-Port-Saint-Ouen': 4804,
    'Les Grandes-Ventes': 4804,
    'Les Loges': 4804,
    'Les Loges-Marchis': 4804,
    'Les Pieux': 4804,
    'Lessay': 4804,
    'Lieurey': 4804,
    'Lillebonne': 4804,
    'Limésy': 4804,
    'Lion-sur-Mer': 4804,
    'Lisieux': 4804,
    'Livarot': 4804,
    'Londinières': 4804,
    'Longny-au-Perche': 4804,
    'Louviers': 4804,
    'Louvigny': 4804,
    'Luc-sur-Mer': 4804,
    'Luneray': 4804,
    'Magny-le-Désert': 4804,
    'Malaunay': 4804,
    'Manche': 4804,
    'Manéglise': 4804,
    'Manneville-sur-Risle': 4804,
    'Marcey-les-Grèves': 4804,
    'Marcilly-sur-Eure': 4804,
    'Marigny': 4804,
    'Maromme': 4804,
    'Martin-Église': 4804,
    'Martinvast': 4804,
    'Mathieu': 4804,
    'May-sur-Orne': 4804,
    'Ménilles': 4804,
    'Menneval': 4804,
    'Merville-Franceville-Plage': 4804,
    'Mesnières-en-Bray': 4804,
    'Messei': 4804,
    'Mondeville': 4804,
    'Mont-Saint-Aignan': 4804,
    'Montaure': 4804,
    'Montebourg': 4804,
    'Montigny': 4804,
    'Montivilliers': 4804,
    'Montmain': 4804,
    'Montmartin-sur-Mer': 4804,
    'Mortagne-au-Perche': 4804,
    'Mortain': 4804,
    'Mortrée': 4804,
    'Mouen': 4804,
    'Moult': 4804,
    'Moyaux': 4804,
    'Nassandres': 4804,
    'Neaufles-Saint-Martin': 4804,
    'Neufchâtel-en-Bray': 4804,
    'Néville': 4804,
    'Nointot': 4804,
    'Nonancourt': 4804,
    'Normanville': 4804,
    'Notre-Dame-de-Bondeville': 4804,
    'Notre-Dame-de-Gravenchon': 4804,
    'Octeville': 4804,
    'Octeville-sur-Mer': 4804,
    'Offranville': 4804,
    'Oissel': 4804,
    'Orbec': 4804,
    'Orival': 4804,
    'Ouistreham': 4804,
    'Ourville-en-Caux': 4804,
    'Pacy-sur-Eure': 4804,
    'Parigny': 4804,
    'Pavilly': 4804,
    'Percy': 4804,
    'Périers': 4804,
    'Perriers-sur-Andelle': 4804,
    'Petit-Couronne': 4804,
    'Petiville': 4804,
    'Picauville': 4804,
    'Pirou': 4804,
    'Pissy-Pôville': 4804,
    'Pîtres': 4804,
    'Pont-Audemer': 4804,
    'Pont-d  Ouilly': 4804,
    'Pont-Écrepin': 4804,
    'Pont-Hébert': 4804,
    'Pont-l’Évêque': 4804,
    'Pont-Saint-Pierre': 4804,
    'Pontorson': 4804,
    'Port-en-Bessin-Huppain': 4804,
    'Portbail': 4804,
    'Poses': 4804,
    'Potigny': 4804,
    'Préaux': 4804,
    'Putanges-Pont-Écrepin': 4804,
    'Querqueville': 4804,
    'Quettehou': 4804,
    'Quettreville-sur-Sienne': 4804,
    'Quillebeuf-sur-Seine': 4804,
    'Quincampoix': 4804,
    'Radon': 4804,
    'Rai': 4804,
    'Ranville': 4804,
    'Rémalard': 4804,
    'Réville': 4804,
    'Rogerville': 4804,
    'Rolleville': 4804,
    'Romagny': 4804,
    'Romilly-sur-Andelle': 4804,
    'Roncherolles-sur-le-Vivier': 4804,
    'Rots': 4804,
    'Rouen': 4804,
    'Roumare': 4804,
    'Routot': 4804,
    'Rouxmesnil-Bouteilles': 4804,
    'Rugles': 4804,
    'Sahurs': 4804,
    'Saint-Amand': 4804,
    'Saint-André-sur-Orne': 4804,
    'Saint-Arnoult': 4804,
    'Saint-Aubin-lès-Elbeuf': 4804,
    'Saint-Aubin-Routot': 4804,
    'Saint-Aubin-sur-Gaillon': 4804,
    'Saint-Aubin-sur-Mer': 4804,
    'Saint-Aubin-sur-Scie': 4804,
    'Saint-Contest': 4804,
    'Saint-Denis-sur-Sarthon': 4804,
    'Saint-Désir': 4804,
    'Saint-Étienne-du-Rouvray': 4804,
    'Saint-Eustache-la-Forêt': 4804,
    'Saint-Gatien-des-Bois': 4804,
    'Saint-Georges-des-Groseillers': 4804,
    'Saint-Germain-du-Corbéis': 4804,
    'Saint-Germain-la-Blanche-Herbe': 4804,
    'Saint-Germain-sur-Avre': 4804,
    'Saint-Germain-Village': 4804,
    'Saint-Hilaire-du-Harcouët': 4804,
    'Saint-Hilaire-Petitville': 4804,
    'Saint-Jacques-sur-Darnétal': 4804,
    'Saint-James': 4804,
    'Saint-Jean-des-Baisants': 4804,
    'Saint-Jean-des-Champs': 4804,
    'Saint-Jean-du-Cardonnay': 4804,
    'Saint-Jouin-Bruneval': 4804,
    'Saint-Just': 4804,
    'Saint-Laurent-de-Brévedent': 4804,
    'Saint-Léger-du-Bourg-Denis': 4804,
    'Saint-Léonard': 4804,
    'Saint-Lô': 4804,
    'Saint-Manvieu': 4804,
    'Saint-Manvieu-Norrey': 4804,
    'Saint-Marcel': 4804,
    'Saint-Martin-de-Boscherville': 4804,
    'Saint-Martin-de-Fontenay': 4804,
    'Saint-Martin-de-Landelles': 4804,
    'Saint-Martin-des-Besaces': 4804,
    'Saint-Martin-des-Champs': 4804,
    'Saint-Martin-du-Manoir': 4804,
    'Saint-Martin-du-Vivier': 4804,
    'Saint-Martin-en-Campagne': 4804,
    'Saint-Martin-Osmonville': 4804,
    'Saint-Nicolas-d’Aliermont': 4804,
    'Saint-Nicolas-de-la-Taille': 4804,
    'Saint-Ouen-de-Thouberville': 4804,
    'Saint-Ouen-du-Tilleul': 4804,
    'Saint-Paër': 4804,
    'Saint-Pair-sur-Mer': 4804,
    'Saint-Pierre-de-Varengeville': 4804,
    'Saint-Pierre-des-Fleurs': 4804,
    'Saint-Pierre-du-Regard': 4804,
    'Saint-Pierre-du-Vauvray': 4804,
    'Saint-Pierre-Église': 4804,
    'Saint-Pierre-en-Val': 4804,
    'Saint-Pierre-lès-Elbeuf': 4804,
    'Saint-Pierre-sur-Dives': 4804,
    'Saint-Planchers': 4804,
    'Saint-Quentin-sur-le-Homme': 4804,
    'Saint-Rémy': 4804,
    'Saint-Romain-de-Colbosc': 4804,
    'Saint-Saëns': 4804,
    'Saint-Sauveur-le-Vicomte': 4804,
    'Saint-Sauveur-Lendelin': 4804,
    'Saint-Sébastien-de-Morsent': 4804,
    'Saint-Senier-sous-Avranches': 4804,
    'Saint-Sever-Calvados': 4804,
    'Saint-Sulpice-sur-Risle': 4804,
    'Saint-Sylvain': 4804,
    'Saint-Vaast-la-Hougue': 4804,
    'Saint-Valery-en-Caux': 4804,
    'Saint-Vigor-le-Grand': 4804,
    'Saint-Wandrille-Rançon': 4804,
    'Sainte-Adresse': 4804,
    'Sainte-Gauburge-Sainte-Colombe': 4804,
    'Sainte-Honorine-du-Fay': 4804,
    'Sainte-Marguerite-sur-Duclair': 4804,
    'Sainte-Marie-des-Champs': 4804,
    'Sainte-Mère-Église': 4804,
    'Sannerville': 4804,
    'Sartilly': 4804,
    'Sées': 4804,
    'Seine-Maritime': 4804,
    'Serqueux': 4804,
    'Serquigny': 4804,
    'Soliers': 4804,
    'Sottevast': 4804,
    'Sotteville-lès-Rouen': 4804,
    'Sourdeval': 4804,
    'Surtainville': 4804,
    'Tancarville': 4804,
    'Tessé-la-Madeleine': 4804,
    'Tessy-sur-Vire': 4804,
    'Thaon': 4804,
    'Thiberville': 4804,
    'Thury-Harcourt': 4804,
    'Tillières-sur-Avre': 4804,
    'Tilly-sur-Seulles': 4804,
    'Tinchebray': 4804,
    'Tollevast': 4804,
    'Torigni-sur-Vire': 4804,
    'Tôtes': 4804,
    'Touques': 4804,
    'Tourlaville': 4804,
    'Tourouvre': 4804,
    'Tourville-la-Rivière': 4804,
    'Tourville-sur-Arques': 4804,
    'Tourville-sur-Odon': 4804,
    'Toutainville': 4804,
    'Troarn': 4804,
    'Trouville-sur-Mer': 4804,
    'Trun': 4804,
    'Turretot': 4804,
    'Urville-Nacqueville': 4804,
    'Val-de-Reuil': 4804,
    'Valframbert': 4804,
    'Valliquerville': 4804,
    'Valognes': 4804,
    'Varengeville-sur-Mer': 4804,
    'Vassy': 4804,
    'Vaudry': 4804,
    'Ver-sur-Mer': 4804,
    'Verneuil-sur-Avre': 4804,
    'Vernon': 4804,
    'Verson': 4804,
    'Villedieu-les-Poêles': 4804,
    'Villers-Bocage': 4804,
    'Villers-Écalles': 4804,
    'Villers-sur-Mer': 4804,
    'Vimoutiers': 4804,
    'Vire': 4804,
    'Yainville': 4804,
    'Yébleron': 4804,
    'Yerville': 4804,
    'Ymare': 4804,
    'Yport': 4804,
    'Yvetot': 4804,
    'Abzac': 4795,
    'Agen': 4795,
    'Agonac': 4795,
    'Ahetze': 4795,
    'Ahun': 4795,
    'Aiffres': 4795,
    'Aigre': 4795,
    'Aiguillon': 4795,
    'Airvault': 4795,
    'Aixe-sur-Vienne': 4795,
    'Ajain': 4795,
    'Allassac': 4795,
    'Ambarès-et-Lagrave': 4795,
    'Ambazac': 4795,
    'Ambès': 4795,
    'Amou': 4795,
    'Andernos-les-Bains': 4795,
    'Andilly': 4795,
    'Anglet': 4795,
    'Angoulême': 4795,
    'Angoulins': 4795,
    'Angresse': 4795,
    'Annesse-et-Beaulieu': 4795,
    'Antonne-et-Trigonant': 4795,
    'Antran': 4795,
    'Arbonne': 4795,
    'Arbus': 4795,
    'Arcachon': 4795,
    'Arcangues': 4795,
    'Archigny': 4795,
    'Ardin': 4795,
    'Arès': 4795,
    'Arette': 4795,
    'Argentat': 4795,
    'Argenton-les-Vallées': 4795,
    'Arnac-la-Poste': 4795,
    'Arnac-Pompadour': 4795,
    'Ars-en-Ré': 4795,
    'Arsac': 4795,
    'Arthez-de-Béarn': 4795,
    'Artiguelouve': 4795,
    'Artigues-près-Bordeaux': 4795,
    'Artix': 4795,
    'Arudy': 4795,
    'Arvert': 4795,
    'Arveyres': 4795,
    'Ascain': 4795,
    'Asnières-sur-Nouère': 4795,
    'Assat': 4795,
    'Asson': 4795,
    'Astaffort': 4795,
    'Atur': 4795,
    'Aubie-et-Espessas': 4795,
    'Aubusson': 4795,
    'Audenge': 4795,
    'Aulnay': 4795,
    'Auzances': 4795,
    'Availles-Limouzine': 4795,
    'Avanton': 4795,
    'Avensan': 4795,
    'Ayguemorte-les-Graves': 4795,
    'Ayron': 4795,
    'Aytré': 4795,
    'Azay-le-Brûlé': 4795,
    'Baignes-Sainte-Radegonde': 4795,
    'Balzac': 4795,
    'Barbaste': 4795,
    'Barbezieux-Saint-Hilaire': 4795,
    'Bardos': 4795,
    'Barsac': 4795,
    'Bassens': 4795,
    'Bassillac': 4795,
    'Bassussarry': 4795,
    'Bayonne': 4795,
    'Bazas': 4795,
    'Beaulieu-sur-Dordogne': 4795,
    'Beaumont': 4795,
    'Beaumont-du-Périgord': 4795,
    'Beaupuy': 4795,
    'Beautiran': 4795,
    'Beauvoir-sur-Niort': 4795,
    'Bégaar': 4795,
    'Bègles': 4795,
    'Belin-Béliet': 4795,
    'Bellac': 4795,
    'Belvès': 4795,
    'Bénéjacq': 4795,
    'Bénesse-Maremne': 4795,
    'Benquet': 4795,
    'Bergerac': 4795,
    'Berson': 4795,
    'Béruges': 4795,
    'Bessines': 4795,
    'Bessines-sur-Gartempe': 4795,
    'Beychac-et-Caillau': 4795,
    'Beynat': 4795,
    'Biard': 4795,
    'Biarritz': 4795,
    'Bias': 4795,
    'Bidache': 4795,
    'Bidart': 4795,
    'Biganos': 4795,
    'Bignoux': 4795,
    'Billère': 4795,
    'Biscarrosse': 4795,
    'Bizanos': 4795,
    'Blanquefort': 4795,
    'Blaye': 4795,
    'Boé': 4795,
    'Boeil-Bezing': 4795,
    'Boismé': 4795,
    'Boisseuil': 4795,
    'Bon-Encontre': 4795,
    'Bonnac-la-Côte': 4795,
    'Bonnat': 4795,
    'Bonnes': 4795,
    'Bonneuil-Matours': 4795,
    'Bordeaux': 4795,
    'Bordes': 4795,
    'Bords': 4795,
    'Bort-les-Orgues': 4795,
    'Bosdarros': 4795,
    'Bosmie-l  Aiguille': 4795,
    'Boucau': 4795,
    'Bouillé-Loretz': 4795,
    'Boulazac': 4795,
    'Bouliac': 4795,
    'Bourcefranc-le-Chapus': 4795,
    'Bourg': 4795,
    'Bourganeuf': 4795,
    'Bourgneuf': 4795,
    'Boussac': 4795,
    'Boutiers-Saint-Trojan': 4795,
    'Boyard-Ville': 4795,
    'Branne': 4795,
    'Brantôme': 4795,
    'Braud-et-Saint-Louis': 4795,
    'Brax': 4795,
    'Bressuire': 4795,
    'Breuil-Magné': 4795,
    'Breuillet': 4795,
    'Brie': 4795,
    'Brigueuil': 4795,
    'Brioux-sur-Boutonne': 4795,
    'Briscous': 4795,
    'Brive-la-Gaillarde': 4795,
    'Bruges': 4795,
    'Bugeat': 4795,
    'Burie': 4795,
    'Buros': 4795,
    'Bussac-sur-Charente': 4795,
    'Bussière-Dunoise': 4795,
    'Bussière-Galant': 4795,
    'Bussière-Poitevine': 4795,
    'Buxerolles': 4795,
    'Buzet-sur-Baïse': 4795,
    'Cabanac-et-Villagrains': 4795,
    'Cabariot': 4795,
    'Cadaujac': 4795,
    'Cadillac': 4795,
    'Cambes': 4795,
    'Camblanes-et-Meynac': 4795,
    'Cambo-les-Bains': 4795,
    'Cancon': 4795,
    'Canéjan': 4795,
    'Cantenac': 4795,
    'Capbreton': 4795,
    'Captieux': 4795,
    'Carbon-Blanc': 4795,
    'Carcans': 4795,
    'Carignan-de-Bordeaux': 4795,
    'Cars': 4795,
    'Carsac-Aillac': 4795,
    'Casseneuil': 4795,
    'Castelculier': 4795,
    'Casteljaloux': 4795,
    'Castelmoron-sur-Lot': 4795,
    'Castelnau-de-Médoc': 4795,
    'Castets': 4795,
    'Castets-en-Dorthe': 4795,
    'Castillon-la-Bataille': 4795,
    'Castillonnès': 4795,
    'Castres-Gironde': 4795,
    'Cavignac': 4795,
    'Cazères-sur-l’Adour': 4795,
    'Celle-Lévescault': 4795,
    'Celles-sur-Belle': 4795,
    'Cénac': 4795,
    'Cénac-et-Saint-Julien': 4795,
    'Cenon': 4795,
    'Cenon-sur-Vienne': 4795,
    'Cercoux': 4795,
    'Cerizay': 4795,
    'Cérons': 4795,
    'Cestas': 4795,
    'Cézac': 4795,
    'Chabanais': 4795,
    'Chaillevette': 4795,
    'Chalais': 4795,
    'Châlus': 4795,
    'Chamberet': 4795,
    'Chambon-sur-Voueize': 4795,
    'Chamboulive': 4795,
    'Chameyrat': 4795,
    'Champagne-Mouton': 4795,
    'Champcevinel': 4795,
    'Champdeniers-Saint-Denis': 4795,
    'Champniers': 4795,
    'Chancelade': 4795,
    'Chaniers': 4795,
    'Chaptelat': 4795,
    'Charente': 4795,
    'Charente-Maritime': 4795,
    'Charron': 4795,
    'Charroux': 4795,
    'Chasseneuil-du-Poitou': 4795,
    'Chasseneuil-sur-Bonnieure': 4795,
    'Chassors': 4795,
    'Châteaubernard': 4795,
    'Châteauneuf-la-Forêt': 4795,
    'Châteauneuf-sur-Charente': 4795,
    'Châteauponsac': 4795,
    'Châtelaillon-Plage': 4795,
    'Châtellerault': 4795,
    'Châtillon-sur-Thouet': 4795,
    'Chaunay': 4795,
    'Chauray': 4795,
    'Chauvigny': 4795,
    'Chazelles': 4795,
    'Chef-Boutonne': 4795,
    'Chérac': 4795,
    'Chéraute': 4795,
    'Chermignac': 4795,
    'Cherves-Richemont': 4795,
    'Cherveux': 4795,
    'Chevanceaux': 4795,
    'Chiché': 4795,
    'Ciboure': 4795,
    'Ciré-d’Aunis': 4795,
    'Cissé': 4795,
    'Civray': 4795,
    'Clairac': 4795,
    'Clérac': 4795,
    'Coarraze': 4795,
    'Cognac': 4795,
    'Cognac-la-Forêt': 4795,
    'Colayrac-Saint-Cirq': 4795,
    'Colombiers': 4795,
    'Combrand': 4795,
    'Compreignac': 4795,
    'Condat-sur-Vienne': 4795,
    'Confolens': 4795,
    'Corme-Royal': 4795,
    'Cornil': 4795,
    'Corrèze': 4795,
    'Cosnac': 4795,
    'Couhé': 4795,
    'Coulombiers': 4795,
    'Coulon': 4795,
    'Coulonges-sur-l’Autize': 4795,
    'Coulounieix-Chamiers': 4795,
    'Courçon': 4795,
    'Courlay': 4795,
    'Cours-de-Pile': 4795,
    'Coursac': 4795,
    'Coussac-Bonneval': 4795,
    'Coutras': 4795,
    'Couzeix': 4795,
    'Cozes': 4795,
    'Créon': 4795,
    'Creuse': 4795,
    'Creysse': 4795,
    'Cublac': 4795,
    'Cubzac-les-Ponts': 4795,
    'Cussac': 4795,
    'Cussac-Fort-Médoc': 4795,
    'Damazan': 4795,
    'Dangé-Saint-Romain': 4795,
    'Dax': 4795,
    'Denguin': 4795,
    'Département de la Corrèze': 4795,
    'Département des Deux-Sèvres': 4795,
    'Département des Pyrénées-Atlantiques': 4795,
    'Département du Lot-et-Garonne': 4795,
    'Dignac': 4795,
    'Dirac': 4795,
    'Dissay': 4795,
    'Dolus-d  Oléron': 4795,
    'Domme': 4795,
    'Dompierre-sur-Mer': 4795,
    'Donzenac': 4795,
    'Dordogne': 4795,
    'Dun-le-Palestel': 4795,
    'Duras': 4795,
    'Échillais': 4795,
    'Échiré': 4795,
    'Écoyeux': 4795,
    'Égletons': 4795,
    'Esnandes': 4795,
    'Espelette': 4795,
    'Estillac': 4795,
    'Étagnac': 4795,
    'Étaules': 4795,
    'Étauliers': 4795,
    'Évaux-les-Bains': 4795,
    'Excideuil': 4795,
    'Exideuil': 4795,
    'Exireuil': 4795,
    'Eymet': 4795,
    'Eymoutiers': 4795,
    'Eysines': 4795,
    'Eyvigues-et-Eybènes': 4795,
    'Fargues-Saint-Hilaire': 4795,
    'Felletin': 4795,
    'Feytiat': 4795,
    'Fléac': 4795,
    'Floirac': 4795,
    'Fontaine-le-Comte': 4795,
    'Fontcouverte': 4795,
    'Fors': 4795,
    'Foulayronnes': 4795,
    'Fouras': 4795,
    'Fourques-sur-Garonne': 4795,
    'Fronsac': 4795,
    'Frontenay-Rohan-Rohan': 4795,
    'Fumel': 4795,
    'Gabarret': 4795,
    'Gaillan-en-Médoc': 4795,
    'Galgon': 4795,
    'Gan': 4795,
    'Garat': 4795,
    'Gardonne': 4795,
    'Garlin': 4795,
    'Gauriaguet': 4795,
    'Gelos': 4795,
    'Gémozac': 4795,
    'Génissac': 4795,
    'Gensac-la-Pallue': 4795,
    'Ger': 4795,
    'Gironde': 4795,
    'Gironde-sur-Dropt': 4795,
    'Gond-Pontouvre': 4795,
    'Gontaud-de-Nogaret': 4795,
    'Gouzon': 4795,
    'Gradignan': 4795,
    'Grenade-sur-l’Adour': 4795,
    'Grignols': 4795,
    'Guéret': 4795,
    'Guéthary': 4795,
    'Guîtres': 4795,
    'Gujan-Mestras': 4795,
    'Habas': 4795,
    'Hagetmau': 4795,
    'Hasparren': 4795,
    'Haute-Vienne': 4795,
    'Hautefort': 4795,
    'Hendaye': 4795,
    'Heugas': 4795,
    'Hiersac': 4795,
    'Hinx': 4795,
    'Hourtin': 4795,
    'Idron': 4795,
    'Igon': 4795,
    'Illats': 4795,
    'Isle': 4795,
    'Iteuil': 4795,
    'Itxassou': 4795,
    'Izon': 4795,
    'Jarnac': 4795,
    'Jaunay-Clan': 4795,
    'Jonzac': 4795,
    'Juillac': 4795,
    'Jumilhac-le-Grand': 4795,
    'Jurançon': 4795,
    'La Chapelle-Saint-Laurent': 4795,
    'La Coquille': 4795,
    'La Couarde-sur-Mer': 4795,
    'La Couronne': 4795,
    'La Courtine': 4795,
    'La Crèche': 4795,
    'La Flotte': 4795,
    'La Force': 4795,
    'La Forêt-sur-Sèvre': 4795,
    'La Jarne': 4795,
    'La Jarrie': 4795,
    'La Lande-de-Fronsac': 4795,
    'La Mothe-Saint-Héray': 4795,
    'La Peyratte': 4795,
    'La Réole': 4795,
    'La Roche-Chalais': 4795,
    'La Roche-Posay': 4795,
    'La Rochefoucauld': 4795,
    'La Rochelle': 4795,
    'La Sauve': 4795,
    'La Souterraine': 4795,
    'La Teste-de-Buch': 4795,
    'La Tremblade': 4795,
    'La Villedieu-du-Clain': 4795,
    'Labatut': 4795,
    'Labenne': 4795,
    'Labouheyre': 4795,
    'Lacanau': 4795,
    'Ladignac-le-Long': 4795,
    'Lagor': 4795,
    'Lagorce': 4795,
    'Lagord': 4795,
    'Laguenne': 4795,
    'Lahonce': 4795,
    'Lalinde': 4795,
    'Lamarque': 4795,
    'Lamonzie-Saint-Martin': 4795,
    'Lamothe-Montravel': 4795,
    'Landes': 4795,
    'Landiras': 4795,
    'Langoiran': 4795,
    'Langon': 4795,
    'Lanouaille': 4795,
    'Lanton': 4795,
    'Laplume': 4795,
    'Larche': 4795,
    'Laroque-Timbaut': 4795,
    'Larressore': 4795,
    'Laruns': 4795,
    'Laruscade': 4795,
    'Lasseube': 4795,
    'Lathus-Saint-Rémy': 4795,
    'Latillé': 4795,
    'Latresne': 4795,
    'Lavardac': 4795,
    'Lavoux': 4795,
    'Layrac': 4795,
    'Le Barp': 4795,
    'Le Bois-Plage-en-Ré': 4795,
    'Le Bouscat': 4795,
    'Le Bugue': 4795,
    'Le Buisson-de-Cadouin': 4795,
    'Le Dorat': 4795,
    'Le Fleix': 4795,
    'Le Grand-Bourg': 4795,
    'Le Gua': 4795,
    'Le Haillan': 4795,
    'Le Lardin-Saint-Lazare': 4795,
    'Le Mas-d’Agenais': 4795,
    'Le Palais-sur-Vienne': 4795,
    'Le Passage': 4795,
    'Le Pian-Médoc': 4795,
    'Le Pin': 4795,
    'Le Pizou': 4795,
    'Le Porge': 4795,
    'Le Taillan-Médoc': 4795,
    'Le Tallud': 4795,
    'Le Teich': 4795,
    'Le Thou': 4795,
    'Le Verdon-sur-Mer': 4795,
    'Le Vigen': 4795,
    'Ledeuix': 4795,
    'Lembras': 4795,
    'Lencloître': 4795,
    'Léognan': 4795,
    'Léon': 4795,
    'Les Artigues-de-Lussac': 4795,
    'Les Églisottes-et-Chalaures': 4795,
    'Les Gonds': 4795,
    'Les Mathes': 4795,
    'Les Ormes': 4795,
    'Les Peintures': 4795,
    'Lescar': 4795,
    'Lesparre-Médoc': 4795,
    'Lestelle-Bétharram': 4795,
    'Lezay': 4795,
    'Libourne': 4795,
    'Ligugé': 4795,
    'Limoges': 4795,
    'Linards': 4795,
    'Linars': 4795,
    'Linxe': 4795,
    'Listrac-Médoc': 4795,
    'Lit-et-Mixe': 4795,
    'Lons': 4795,
    'Lormont': 4795,
    'Loubert': 4795,
    'Loudun': 4795,
    'Loupiac': 4795,
    'Louvie-Juzon': 4795,
    'Louzy': 4795,
    'Lubersac': 4795,
    'Lucq-de-Béarn': 4795,
    'Ludon-Médoc': 4795,
    'Lugon-et-l  Île-du-Carnay': 4795,
    'Lusignan': 4795,
    'Lussac': 4795,
    'Lussac-les-Châteaux': 4795,
    'Macau': 4795,
    'Magescq': 4795,
    'Magnac-Laval': 4795,
    'Magnac-sur-Touvre': 4795,
    'Magné': 4795,
    'Malemort-sur-Corrèze': 4795,
    'Mansac': 4795,
    'Mansle': 4795,
    'Marans': 4795,
    'Marcamps': 4795,
    'Marcheprime': 4795,
    'Marcillac': 4795,
    'Marennes': 4795,
    'Mareuil': 4795,
    'Margaux': 4795,
    'Marigny-Brizay': 4795,
    'Marmande': 4795,
    'Marsilly': 4795,
    'Martignas-sur-Jalle': 4795,
    'Martillac': 4795,
    'Matha': 4795,
    'Mauléon-Licharre': 4795,
    'Mauzé-sur-le-Mignon': 4795,
    'Mauzé-Thouarsais': 4795,
    'Mazères-Lezons': 4795,
    'Médis': 4795,
    'Mées': 4795,
    'Meilhan': 4795,
    'Meilhan-sur-Garonne': 4795,
    'Melle': 4795,
    'Ménesplet': 4795,
    'Mensignac': 4795,
    'Mérignac': 4795,
    'Meschers-sur-Gironde': 4795,
    'Meursac': 4795,
    'Meymac': 4795,
    'Meyssac': 4795,
    'Mézin': 4795,
    'Mignaloux-Beauvoir': 4795,
    'Migné-Auxances': 4795,
    'Mimbaste': 4795,
    'Mimizan': 4795,
    'Mios': 4795,
    'Mirambeau': 4795,
    'Miramont-de-Guyenne': 4795,
    'Mirebeau': 4795,
    'Mirepeix': 4795,
    'Monbazillac': 4795,
    'Moncontour': 4795,
    'Moncoutant': 4795,
    'Monein': 4795,
    'Monflanquin': 4795,
    'Monségur': 4795,
    'Monsempron-Libos': 4795,
    'Mont': 4795,
    'Mont-de-Marsan': 4795,
    'Montagne': 4795,
    'Montamisé': 4795,
    'Montardon': 4795,
    'Montaut': 4795,
    'Montayral': 4795,
    'Montbron': 4795,
    'Montcaret': 4795,
    'Montendre': 4795,
    'Montfort-en-Chalosse': 4795,
    'Montguyon': 4795,
    'Montignac': 4795,
    'Montlieu-la-Garde': 4795,
    'Montmoreau-Saint-Cybard': 4795,
    'Montmorillon': 4795,
    'Montpon-Ménestérol': 4795,
    'Montrem': 4795,
    'Montussan': 4795,
    'Morcenx': 4795,
    'Morlaas': 4795,
    'Mornac': 4795,
    'Mortagne-sur-Gironde': 4795,
    'Mougon': 4795,
    'Mouguerre': 4795,
    'Mouleydier': 4795,
    'Mouliets-et-Villemartin': 4795,
    'Moulis-en-Médoc': 4795,
    'Mourenx': 4795,
    'Mouthiers-sur-Boëme': 4795,
    'Mugron': 4795,
    'Muron': 4795,
    'Mussidan': 4795,
    'Naintré': 4795,
    'Nanteuil': 4795,
    'Nanteuil-en-Vallée': 4795,
    'Nantiat': 4795,
    'Narrosse': 4795,
    'Navailles-Angos': 4795,
    'Navarrenx': 4795,
    'Naves': 4795,
    'Nay': 4795,
    'Nérac': 4795,
    'Nercillac': 4795,
    'Nersac': 4795,
    'Neuvic': 4795,
    'Neuvic-Entier': 4795,
    'Neuville-de-Poitou': 4795,
    'Nexon': 4795,
    'Nieul': 4795,
    'Nieul-lès-Saintes': 4795,
    'Nieul-sur-Mer': 4795,
    'Niort': 4795,
    'Noaillan': 4795,
    'Nontron': 4795,
    'Notre-Dame-de-Sanilhac': 4795,
    'Nouaillé-Maupertuis': 4795,
    'Nousty': 4795,
    'Nueil-les-Aubiers': 4795,
    'Objat': 4795,
    'Oeyreluy': 4795,
    'Ogeu-les-Bains': 4795,
    'Oloron-Sainte-Marie': 4795,
    'Ondres': 4795,
    'Onesse-Laharie': 4795,
    'Oradour-sur-Glane': 4795,
    'Oradour-sur-Vayres': 4795,
    'Orthez': 4795,
    'Ousse': 4795,
    'Paillet': 4795,
    'Pamproux': 4795,
    'Panazol': 4795,
    'Parempuyre': 4795,
    'Parentis-en-Born': 4795,
    'Parthenay': 4795,
    'Pau': 4795,
    'Pauillac': 4795,
    'Payzac': 4795,
    'Pellegrue': 4795,
    'Pérignac': 4795,
    'Périgny': 4795,
    'Périgueux': 4795,
    'Pessac': 4795,
    'Peujard': 4795,
    'Peyrat-de-Bellac': 4795,
    'Peyrat-le-Château': 4795,
    'Peyrehorade': 4795,
    'Peyrilhac': 4795,
    'Piégut-Pluviers': 4795,
    'Pierre-Buffière': 4795,
    'Pissos': 4795,
    'Pleumartin': 4795,
    'Podensac': 4795,
    'Poey-de-Lescar': 4795,
    'Poitiers': 4795,
    'Pomarez': 4795,
    'Pompaire': 4795,
    'Pompignac': 4795,
    'Pons': 4795,
    'Pont-du-Casse': 4795,
    'Pont-l’Abbé-d’Arnoult': 4795,
    'Pontacq': 4795,
    'Pontenx-les-Forges': 4795,
    'Pontonx-sur-l  Adour': 4795,
    'Port-des-Barques': 4795,
    'Port-Sainte-Foy-et-Ponchapt': 4795,
    'Port-Sainte-Marie': 4795,
    'Portets': 4795,
    'Pouillon': 4795,
    'Prahecq': 4795,
    'Préchac': 4795,
    'Preignac': 4795,
    'Prigonrieux': 4795,
    'Pugnac': 4795,
    'Puilboreau': 4795,
    'Pujols': 4795,
    'Puymoyen': 4795,
    'Puyoô': 4795,
    'Quinsac': 4795,
    'Rauzan': 4795,
    'Razac-sur-l’Isle': 4795,
    'Razès': 4795,
    'Reignac': 4795,
    'Ribérac': 4795,
    'Rilhac-Rancon': 4795,
    'Rion-des-Landes': 4795,
    'Rions': 4795,
    'Rivedoux-Plage': 4795,
    'Rivière-Saas-et-Gourby': 4795,
    'Rivières': 4795,
    'Rochechouart': 4795,
    'Rochefort': 4795,
    'Roches-Prémarie-Andillé': 4795,
    'Roquefort': 4795,
    'Rouffignac-Saint-Cernin-de-Reilhac': 4795,
    'Rouillac': 4795,
    'Rouillé': 4795,
    'Roullet-Saint-Estèphe': 4795,
    'Roumazières-Loubert': 4795,
    'Royan': 4795,
    'Ruelle-sur-Touvre': 4795,
    'Ruffec': 4795,
    'Sablonceaux': 4795,
    'Sablons': 4795,
    'Sabres': 4795,
    'Sadirac': 4795,
    'Saint-Agnant': 4795,
    'Saint-Agnant-de-Versillat': 4795,
    'Saint-Aigulin': 4795,
    'Saint-Amand-sur-Sèvre': 4795,
    'Saint-Amant-de-Boixe': 4795,
    'Saint-André-de-Seignanx': 4795,
    'Saint-Antoine-de-Breuilh': 4795,
    'Saint-Astier': 4795,
    'Saint-Aubin-de-Médoc': 4795,
    'Saint-Aubin-le-Cloud': 4795,
    'Saint-Aulaye': 4795,
    'Saint-Benoît': 4795,
    'Saint-Brice': 4795,
    'Saint-Brice-sur-Vienne': 4795,
    'Saint-Caprais-de-Bordeaux': 4795,
    'Saint-Christoly-de-Blaye': 4795,
    'Saint-Ciers-d’Abzac': 4795,
    'Saint-Ciers-sur-Gironde': 4795,
    'Saint-Claud': 4795,
    'Saint-Clément': 4795,
    'Saint-Cyprien': 4795,
    'Saint-Denis-d’Oléron': 4795,
    'Saint-Denis-de-Pile': 4795,
    'Saint-Émilion': 4795,
    'Saint-Estèphe': 4795,
    'Saint-Étienne-de-Baïgorry': 4795,
    'Saint-Gelais': 4795,
    'Saint-Gence': 4795,
    'Saint-Genis-de-Saintonge': 4795,
    'Saint-Georges-de-Didonne': 4795,
    'Saint-Georges-des-Coteaux': 4795,
    'Saint-Georges-du-Bois': 4795,
    'Saint-Georges-lès-Baillargeaux': 4795,
    'Saint-Geours-de-Maremne': 4795,
    'Saint-Germain-de-Lusignan': 4795,
    'Saint-Germain-de-Marencennes': 4795,
    'Saint-Germain-du-Puch': 4795,
    'Saint-Germain-les-Belles': 4795,
    'Saint-Gervais': 4795,
    'Saint-Gervais-les-Trois-Clochers': 4795,
    'Saint-Hilaire': 4795,
    'Saint-Hilaire-de-Villefranche': 4795,
    'Saint-Hilaire-la-Palud': 4795,
    'Saint-Hippolyte': 4795,
    'Saint-Jean-d’Illac': 4795,
    'Saint-Jean-de-Liversay': 4795,
    'Saint-Jean-de-Luz': 4795,
    'Saint-Jean-de-Sauves': 4795,
    'Saint-Jean-de-Thouars': 4795,
    'Saint-Jean-Pied-de-Port': 4795,
    'Saint-Jouvent': 4795,
    'Saint-Julien-en-Born': 4795,
    'Saint-Junien': 4795,
    'Saint-Just-le-Martel': 4795,
    'Saint-Just-Luzac': 4795,
    'Saint-Laurent-de-la-Prée': 4795,
    'Saint-Laurent-sur-Gorre': 4795,
    'Saint-Léger-de-Montbrun': 4795,
    'Saint-Léon-sur-l’Isle': 4795,
    'Saint-Léonard-de-Noblat': 4795,
    'Saint-Loubès': 4795,
    'Saint-Louis-de-Montferrand': 4795,
    'Saint-Macaire': 4795,
    'Saint-Maixant': 4795,
    'Saint-Mariens': 4795,
    'Saint-Martin-de-Hinx': 4795,
    'Saint-Martin-de-Ré': 4795,
    'Saint-Martin-de-Seignanx': 4795,
    'Saint-Martin-Lacaussade': 4795,
    'Saint-Mathieu': 4795,
    'Saint-Maurice-la-Clouère': 4795,
    'Saint-Maurice-la-Souterraine': 4795,
    'Saint-Maxire': 4795,
    'Saint-Médard-d’Eyrans': 4795,
    'Saint-Médard-de-Guizières': 4795,
    'Saint-Médard-de-Mussidan': 4795,
    'Saint-Médard-en-Jalles': 4795,
    'Saint-Même-les-Carrières': 4795,
    'Saint-Mexant': 4795,
    'Saint-Michel': 4795,
    'Saint-Morillon': 4795,
    'Saint-Palais': 4795,
    'Saint-Palais-sur-Mer': 4795,
    'Saint-Pantaléon-de-Larche': 4795,
    'Saint-Pardoux': 4795,
    'Saint-Pardoux-Isaac': 4795,
    'Saint-Pardoux-la-Rivière': 4795,
    'Saint-Paul': 4795,
    'Saint-Paul-lès-Dax': 4795,
    'Saint-Pée-sur-Nivelle': 4795,
    'Saint-Perdon': 4795,
    'Saint-Pierre-d’Aurillac': 4795,
    'Saint-Pierre-d’Oléron': 4795,
    'Saint-Pierre-des-Échaubrognes': 4795,
    'Saint-Pierre-du-Mont': 4795,
    'Saint-Porchaire': 4795,
    'Saint-Priest-sous-Aixe': 4795,
    'Saint-Priest-Taurion': 4795,
    'Saint-Privat': 4795,
    'Saint-Projet-Saint-Constant': 4795,
    'Saint-Quentin-de-Baron': 4795,
    'Saint-Rogatien': 4795,
    'Saint-Romain-de-Benet': 4795,
    'Saint-Saturnin': 4795,
    'Saint-Sauvant': 4795,
    'Saint-Sauveur': 4795,
    'Saint-Sauveur-d’Aunis': 4795,
    'Saint-Savin': 4795,
    'Saint-Savinien': 4795,
    'Saint-Selve': 4795,
    'Saint-Seurin-sur-l’Isle': 4795,
    'Saint-Sever': 4795,
    'Saint-Sulpice-de-Cognac': 4795,
    'Saint-Sulpice-de-Faleyrens': 4795,
    'Saint-Sulpice-de-Royan': 4795,
    'Saint-Sulpice-et-Cameyrac': 4795,
    'Saint-Sulpice-le-Guérétois': 4795,
    'Saint-Sulpice-les-Feuilles': 4795,
    'Saint-Sylvestre-sur-Lot': 4795,
    'Saint-Symphorien': 4795,
    'Saint-Trojan-les-Bains': 4795,
    'Saint-Varent': 4795,
    'Saint-Vaury': 4795,
    'Saint-Viance': 4795,
    'Saint-Victurnien': 4795,
    'Saint-Vincent-de-Paul': 4795,
    'Saint-Vincent-de-Tyrosse': 4795,
    'Saint-Vite': 4795,
    'Saint-Vivien-de-Médoc': 4795,
    'Saint-Xandre': 4795,
    'Saint-Yrieix-la-Perche': 4795,
    'Saint-Yrieix-sur-Charente': 4795,
    'Saint-Yzan-de-Soudiac': 4795,
    'Sainte-Bazeille': 4795,
    'Sainte-Colombe-en-Bruilhois': 4795,
    'Sainte-Eulalie': 4795,
    'Sainte-Féréole': 4795,
    'Sainte-Feyre': 4795,
    'Sainte-Fortunade': 4795,
    'Sainte-Foy-la-Grande': 4795,
    'Sainte-Hélène': 4795,
    'Sainte-Livrade-sur-Lot': 4795,
    'Sainte-Marie-de-Gosse': 4795,
    'Sainte-Marie-de-Ré': 4795,
    'Sainte-Radegonde': 4795,
    'Sainte-Soulle': 4795,
    'Sainte-Terre': 4795,
    'Sainte-Verge': 4795,
    'Saintes': 4795,
    'Saivres': 4795,
    'Salies-de-Béarn': 4795,
    'Salignac': 4795,
    'Salignac-Eyvigues': 4795,
    'Sallebœuf': 4795,
    'Salles': 4795,
    'Salles-sur-Mer': 4795,
    'Samadet': 4795,
    'Sanguinet': 4795,
    'Sarbazan': 4795,
    'Sare': 4795,
    'Sarlat-la-Canéda': 4795,
    'Saubion': 4795,
    'Saubrigues': 4795,
    'Saucats': 4795,
    'Saugnac-et-Cambran': 4795,
    'Saujon': 4795,
    'Saulgé': 4795,
    'Sauvagnon': 4795,
    'Sauveterre-de-Béarn': 4795,
    'Sauveterre-de-Guyenne': 4795,
    'Sauviat-sur-Vige': 4795,
    'Sauzé-Vaussais': 4795,
    'Savigné': 4795,
    'Savigny-Lévescault': 4795,
    'Scorbé-Clairvaux': 4795,
    'Secondigny': 4795,
    'Segonzac': 4795,
    'Seignosse': 4795,
    'Seilhac': 4795,
    'Semussac': 4795,
    'Séreilhac': 4795,
    'Serres-Castet': 4795,
    'Sèvres-Anxaumont': 4795,
    'Sireuil': 4795,
    'Smarves': 4795,
    'Solignac': 4795,
    'Soorts-Hossegor': 4795,
    'Sorges': 4795,
    'Soubise': 4795,
    'Soulac-sur-Mer': 4795,
    'Soumoulou': 4795,
    'Souprosse': 4795,
    'Souraïde': 4795,
    'Sourzac': 4795,
    'Soussans': 4795,
    'Soustons': 4795,
    'Soyaux': 4795,
    'Surgères': 4795,
    'Tabanac': 4795,
    'Talence': 4795,
    'Taponnat-Fleurignac': 4795,
    'Targon': 4795,
    'Tarnos': 4795,
    'Tartas': 4795,
    'Tauriac': 4795,
    'Tercé': 4795,
    'Tercis-les-Bains': 4795,
    'Terrasson-Lavilledieu': 4795,
    'Thairé': 4795,
    'Thénac': 4795,
    'Thénezay': 4795,
    'Thenon': 4795,
    'Thiviers': 4795,
    'Thorigné': 4795,
    'Thouars': 4795,
    'Thuré': 4795,
    'Tocane-Saint-Apre': 4795,
    'Tonnay-Boutonne': 4795,
    'Tonnay-Charente': 4795,
    'Tonneins': 4795,
    'Tosse': 4795,
    'Toulenne': 4795,
    'Touvre': 4795,
    'Treignac': 4795,
    'Trélissac': 4795,
    'Tresses': 4795,
    'Trizay': 4795,
    'Tulle': 4795,
    'Urcuit': 4795,
    'Urrugne': 4795,
    'Urt': 4795,
    'Ussac': 4795,
    'Ussel': 4795,
    'Usson-du-Poitou': 4795,
    'Ustaritz': 4795,
    'Uzein': 4795,
    'Uzerche': 4795,
    'Varetz': 4795,
    'Vars': 4795,
    'Vasles': 4795,
    'Vaux-sur-Mer': 4795,
    'Vayres': 4795,
    'Vélines': 4795,
    'Vendays-Montalivet': 4795,
    'Vendeuvre-du-Poitou': 4795,
    'Vergt': 4795,
    'Vérines': 4795,
    'Verneuil-sur-Vienne': 4795,
    'Vertheuil': 4795,
    'Veyrac': 4795,
    'Vianne': 4795,
    'Vicq-sur-Breuilh': 4795,
    'Vielle-Saint-Girons': 4795,
    'Viennay': 4795,
    'Vienne': 4795,
    'Vieux-Boucau-les-Bains': 4795,
    'Vigeois': 4795,
    'Villefagnan': 4795,
    'Villefranque': 4795,
    'Villegouge': 4795,
    'Villenave-d’Ornon': 4795,
    'Villeneuve-de-Marsan': 4795,
    'Villeneuve-sur-Lot': 4795,
    'Villeréal': 4795,
    'Villiers-en-Plaine': 4795,
    'Virazeil': 4795,
    'Vivonne': 4795,
    'Vœuil-et-Giget': 4795,
    'Vouillé': 4795,
    'Vouneuil-sous-Biard': 4795,
    'Vouneuil-sur-Vienne': 4795,
    'Voutezac': 4795,
    'Ychoux': 4795,
    'Ygos-Saint-Saturnin': 4795,
    'Yves': 4795,
    'Yvrac': 4795,
    'Abeilhan': 4799,
    'Agde': 4799,
    'Aiguefonde': 4799,
    'Aigues-Mortes': 4799,
    'Aigues-Vives': 4799,
    'Aimargues': 4799,
    'Albi': 4799,
    'Albias': 4799,
    'Alénya': 4799,
    'Alès': 4799,
    'Alignan-du-Vent': 4799,
    'Alzonne': 4799,
    'Andrest': 4799,
    'Anduze': 4799,
    'Aniane': 4799,
    'Aramon': 4799,
    'Argelers': 4799,
    'Argelès-Gazost': 4799,
    'Argeliers': 4799,
    'Arles': 4799,
    'Armissan': 4799,
    'Arthès': 4799,
    'Arzens': 4799,
    'Aspet': 4799,
    'Aspiran': 4799,
    'Assas': 4799,
    'Aubais': 4799,
    'Aubiet': 4799,
    'Aubin': 4799,
    'Aubord': 4799,
    'Aucamville': 4799,
    'Auch': 4799,
    'Aumont-Aubrac': 4799,
    'Aureilhan': 4799,
    'Auriac-sur-Vendinelle': 4799,
    'Aurignac': 4799,
    'Aussillon': 4799,
    'Aussonne': 4799,
    'Auterive': 4799,
    'Auvillar': 4799,
    'Auzeville-Tolosane': 4799,
    'Auzielle': 4799,
    'Avèze': 4799,
    'Avignonet-Lauragais': 4799,
    'Ax-les-Thermes': 4799,
    'Ayguesvives': 4799,
    'Azille': 4799,
    'Bagard': 4799,
    'Bages': 4799,
    'Bagnac-sur-Célé': 4799,
    'Bagnères-de-Bigorre': 4799,
    'Bagnères-de-Luchon': 4799,
    'Bagnols-sur-Cèze': 4799,
    'Baho': 4799,
    'Baillargues': 4799,
    'Baixas': 4799,
    'Balaruc-le-Vieux': 4799,
    'Balaruc-les-Bains': 4799,
    'Balma': 4799,
    'Banyuls de la Marenda': 4799,
    'Banyuls-dels-Aspres': 4799,
    'Baraqueville': 4799,
    'Barbazan-Debat': 4799,
    'Barcelonne-du-Gers': 4799,
    'Barjac': 4799,
    'Bassan': 4799,
    'Bazet': 4799,
    'Baziège': 4799,
    'Beaucaire': 4799,
    'Beaulieu': 4799,
    'Beaumont-de-Lomagne': 4799,
    'Beaumont-sur-Lèze': 4799,
    'Beaupuy': 4799,
    'Beauvoisin': 4799,
    'Beauzelle': 4799,
    'Bédarieux': 4799,
    'Belberaud': 4799,
    'Bélesta': 4799,
    'Bellegarde': 4799,
    'Belmont-sur-Rance': 4799,
    'Belpech': 4799,
    'Bérat': 4799,
    'Bernis': 4799,
    'Bessan': 4799,
    'Bessèges': 4799,
    'Bessières': 4799,
    'Béziers': 4799,
    'Bezouce': 4799,
    'Biars-sur-Cère': 4799,
    'Bizanet': 4799,
    'Bize-Minervois': 4799,
    'Blagnac': 4799,
    'Blauzac': 4799,
    'Blaye-les-Mines': 4799,
    'Boisseron': 4799,
    'Boisset-et-Gaujac': 4799,
    'Bompas': 4799,
    'Bouillargues': 4799,
    'Boujan-sur-Libron': 4799,
    'Bouloc': 4799,
    'Boulogne-sur-Gesse': 4799,
    'Bout-du-Pont-de-Larn': 4799,
    'Bouzigues': 4799,
    'Bozouls': 4799,
    'Bram': 4799,
    'Branoux-les-Taillades': 4799,
    'Brassac': 4799,
    'Brax': 4799,
    'Brens': 4799,
    'Bressols': 4799,
    'Bretenoux': 4799,
    'Briatexte': 4799,
    'Bruguières': 4799,
    'Burlats': 4799,
    'Buzet-sur-Tarn': 4799,
    'Cabestany': 4799,
    'Cabrières': 4799,
    'Cadalen': 4799,
    'Cagnac-les-Mines': 4799,
    'Cahors': 4799,
    'Cahuzac-sur-Vère': 4799,
    'Caissargues': 4799,
    'Cajarc': 4799,
    'Calmont': 4799,
    'Calvisson': 4799,
    'Camarès': 4799,
    'Campan': 4799,
    'Campsas': 4799,
    'Candillargues': 4799,
    'Canet': 4799,
    'Canet-en-Roussillon': 4799,
    'Canohès': 4799,
    'Capdenac-Gare': 4799,
    'Capendu': 4799,
    'Capestang': 4799,
    'Capvern': 4799,
    'Caraman': 4799,
    'Carbonne': 4799,
    'Carcassonne': 4799,
    'Carmaux': 4799,
    'Cassagnes-Bégonhès': 4799,
    'Castanet-Tolosan': 4799,
    'Castelginest': 4799,
    'Castelmaurou': 4799,
    'Castelnau-d  Estrétefonds': 4799,
    'Castelnau-de-Guers': 4799,
    'Castelnau-de-Lévis': 4799,
    'Castelnau-le-Lez': 4799,
    'Castelnau-Montratier': 4799,
    'Castelnaudary': 4799,
    'Castelsarrasin': 4799,
    'Castillon-du-Gard': 4799,
    'Castres': 4799,
    'Castries': 4799,
    'Caunes-Minervois': 4799,
    'Caussade': 4799,
    'Cauterets': 4799,
    'Caux': 4799,
    'Caveirac': 4799,
    'Caylus': 4799,
    'Cazaubon': 4799,
    'Cazères': 4799,
    'Cazes-Mondenard': 4799,
    'Cazilhac': 4799,
    'Cazouls-lès-Béziers': 4799,
    'Cendras': 4799,
    'Cépet': 4799,
    'Ceret': 4799,
    'Cers': 4799,
    'Cervera de la Marenda': 4799,
    'Cessenon-sur-Orb': 4799,
    'Chalabre': 4799,
    'Chanac': 4799,
    'Chirac': 4799,
    'Cintegabelle': 4799,
    'Claira': 4799,
    'Clapiers': 4799,
    'Clarensac': 4799,
    'Claret': 4799,
    'Codognan': 4799,
    'Collioure': 4799,
    'Colombiers': 4799,
    'Colombiès': 4799,
    'Colomiers': 4799,
    'Combaillaux': 4799,
    'Comps': 4799,
    'Condom': 4799,
    'Congénies': 4799,
    'Connaux': 4799,
    'Conques-sur-Orbiel': 4799,
    'Corbarieu': 4799,
    'Cordes-sur-Ciel': 4799,
    'Cornebarrieu': 4799,
    'Corneilhan': 4799,
    'Corneilla-del-Vercol': 4799,
    'Corneilla-la-Rivière': 4799,
    'Coufouleux': 4799,
    'Couiza': 4799,
    'Cournonsec': 4799,
    'Cournonterral': 4799,
    'Coursan': 4799,
    'Cransac': 4799,
    'Creissan': 4799,
    'Creissels': 4799,
    'Cugnaux': 4799,
    'Cunac': 4799,
    'Cuxac-Cabardès': 4799,
    'Daux': 4799,
    'Decazeville': 4799,
    'Département de l  Ariège': 4799,
    'Département de l  Aude': 4799,
    'Département de l  Aveyron': 4799,
    'Département de l  Hérault': 4799,
    'Département de la Lozère': 4799,
    'Département des Hautes-Pyrénées': 4799,
    'Département des Pyrénées-Orientales': 4799,
    'Département du Tarn-et-Garonne': 4799,
    'Dourgne': 4799,
    'Drémil-Lafage': 4799,
    'Druelle': 4799,
    'Eaunes': 4799,
    'Eauze': 4799,
    'el Voló': 4799,
    'Elne': 4799,
    'els Banys d  Arles': 4799,
    'Entraygues-sur-Truyère': 4799,
    'Escalquens': 4799,
    'Espalion': 4799,
    'Espéraza': 4799,
    'Estagel': 4799,
    'Fabrègues': 4799,
    'Fabrezan': 4799,
    'Fenouillet': 4799,
    'Ferrals-les-Corbières': 4799,
    'Figeac': 4799,
    'Finhan': 4799,
    'Firmi': 4799,
    'Flavin': 4799,
    'Fleurance': 4799,
    'Fleury': 4799,
    'Florac': 4799,
    'Florensac': 4799,
    'Flourens': 4799,
    'Foix': 4799,
    'Fonbeauzard': 4799,
    'Fonsorbes': 4799,
    'Font-Romeu-Odeillo-Via': 4799,
    'Fontenilles': 4799,
    'Fourques': 4799,
    'Fréjairolles': 4799,
    'Frontignan': 4799,
    'Fronton': 4799,
    'Frouzins': 4799,
    'Gagnac-sur-Garonne': 4799,
    'Gaillac': 4799,
    'Gaillac-Toulza': 4799,
    'Gallargues-le-Montueux': 4799,
    'Ganges': 4799,
    'Gard': 4799,
    'Gardouch': 4799,
    'Garidech': 4799,
    'Garons': 4799,
    'Générac': 4799,
    'Gerde': 4799,
    'Gers': 4799,
    'Gigean': 4799,
    'Gignac': 4799,
    'Gimont': 4799,
    'Ginestas': 4799,
    'Giroussens': 4799,
    'Gondrin': 4799,
    'Goudargues': 4799,
    'Gourdan-Polignan': 4799,
    'Gourdon': 4799,
    'Grabels': 4799,
    'Gragnague': 4799,
    'Gramat': 4799,
    'Gratentour': 4799,
    'Graulhet': 4799,
    'Grenade': 4799,
    'Grisolles': 4799,
    'Gruissan': 4799,
    'Haute-Garonne': 4799,
    'Hérépian': 4799,
    'Horgues': 4799,
    'Ibos': 4799,
    'Ille-sur-Têt': 4799,
    'Jacou': 4799,
    'Jegun': 4799,
    'Jonquières-Saint-Vincent': 4799,
    'Juillan': 4799,
    'Juvignac': 4799,
    'L  Union': 4799,
    'L’Isle-en-Dodon': 4799,
    'L’Isle-Jourdain': 4799,
    'La Barthe-de-Neste': 4799,
    'La Calmette': 4799,
    'La Canourgue': 4799,
    'La Cavalerie': 4799,
    'La Fouillade': 4799,
    'La Grande-Motte': 4799,
    'la Guingueta d  Ix': 4799,
    'La Loubière': 4799,
    'La Palme': 4799,
    'la Roca d  Albera': 4799,
    'La Salvetat-Peyralès': 4799,
    'La Salvetat-Saint-Gilles': 4799,
    'La Salvetat-sur-Agout': 4799,
    'La Tour-du-Crieu': 4799,
    'La Tour-sur-Orb': 4799,
    'Labarthe-Rivière': 4799,
    'Labarthe-sur-Lèze': 4799,
    'Labastide-Beauvoir': 4799,
    'Labastide-Rouairoux': 4799,
    'Labastide-Saint-Georges': 4799,
    'Labastide-Saint-Pierre': 4799,
    'Labastide-Saint-Sernin': 4799,
    'Labastidette': 4799,
    'Labège': 4799,
    'Labruguière': 4799,
    'Lacapelle-Marival': 4799,
    'Lacasse': 4799,
    'Lacaune': 4799,
    'Lacroix-Falgarde': 4799,
    'Lacrouzette': 4799,
    'Lafrançaise': 4799,
    'Lagardelle-sur-Lèze': 4799,
    'Lagarrigue': 4799,
    'Lagrave': 4799,
    'Laguiole': 4799,
    'Laissac': 4799,
    'Lalbenque': 4799,
    'Laloubère': 4799,
    'Lamagistère': 4799,
    'Lamalou-les-Bains': 4799,
    'Langlade': 4799,
    'Langogne': 4799,
    'Lannemezan': 4799,
    'Lansargues': 4799,
    'Lanta': 4799,
    'Lapeyrouse-Fossat': 4799,
    'Laroque': 4799,
    'Laroque-d’Olmes': 4799,
    'Lasalle': 4799,
    'Latour-Bas-Elne': 4799,
    'Lattes': 4799,
    'Laudun-l  Ardoise': 4799,
    'Launac': 4799,
    'Launaguet': 4799,
    'Laure-Minervois': 4799,
    'Laurens': 4799,
    'Lautrec': 4799,
    'Lauzerte': 4799,
    'Laval-Pradel': 4799,
    'Lavalette': 4799,
    'Lavaur': 4799,
    'Lavelanet': 4799,
    'Lavernose-Lacasse': 4799,
    'Lavérune': 4799,
    'Lavit': 4799,
    'Le Barcarès': 4799,
    'Le Bousquet-d’Orb': 4799,
    'Le Cailar': 4799,
    'Le Cap d  Agde': 4799,
    'Le Crès': 4799,
    'Le Fauga': 4799,
    'Le Fousseret': 4799,
    'Le Garric': 4799,
    'Le Grau-du-Roi': 4799,
    'Le Houga': 4799,
    'Le Malzieu-Ville': 4799,
    'Le Monastère': 4799,
    'Le Pouget': 4799,
    'Le Poujol-sur-Orb': 4799,
    'Le Sequestre': 4799,
    'Le Soler': 4799,
    'Le Vigan': 4799,
    'Lectoure': 4799,
    'Lédenon': 4799,
    'Lédignan': 4799,
    'Léguevin': 4799,
    'Les Angles': 4799,
    'Les Mages': 4799,
    'Les Matelles': 4799,
    'Les Salles-du-Gardon': 4799,
    'Lespignan': 4799,
    'Lespinasse': 4799,
    'Leucate': 4799,
    'Lévignac': 4799,
    'Leyme': 4799,
    'Lézan': 4799,
    'Lézat-sur-Lèze': 4799,
    'Lézignan-Corbières': 4799,
    'Lézignan-la-Cèbe': 4799,
    'Lherm': 4799,
    'Lieuran-lès-Béziers': 4799,
    'Lignan-sur-Orb': 4799,
    'Limoux': 4799,
    'Lisle-sur-Tarn': 4799,
    'Livinhac-le-Haut': 4799,
    'Llupia': 4799,
    'Lodève': 4799,
    'Lombez': 4799,
    'Longages': 4799,
    'Lot': 4799,
    'Loupian': 4799,
    'Lourdes': 4799,
    'Luc-la-Primaube': 4799,
    'Lunel': 4799,
    'Lunel-Viel': 4799,
    'Luz-Saint-Sauveur': 4799,
    'Luzech': 4799,
    'Magalas': 4799,
    'Manduel': 4799,
    'Maraussan': 4799,
    'Marciac': 4799,
    'Marcillac-Vallon': 4799,
    'Marcorignan': 4799,
    'Marguerittes': 4799,
    'Marquefave': 4799,
    'Marseillan': 4799,
    'Marsillargues': 4799,
    'Marssac-sur-Tarn': 4799,
    'Martel': 4799,
    'Martres-Tolosane': 4799,
    'Marvejols': 4799,
    'Masseube': 4799,
    'Maubourguet': 4799,
    'Mauguio': 4799,
    'Maureilhan': 4799,
    'Maureillas-las-Illas': 4799,
    'Mauvezin': 4799,
    'Mazamet': 4799,
    'Mazères': 4799,
    'Mende': 4799,
    'Mercus-Garrabet': 4799,
    'Merville': 4799,
    'Meynes': 4799,
    'Meyrueis': 4799,
    'Mèze': 4799,
    'Miélan': 4799,
    'Milhaud': 4799,
    'Millas': 4799,
    'Millau': 4799,
    'Mirande': 4799,
    'Mirandol-Bourgnounac': 4799,
    'Miremont': 4799,
    'Mirepoix': 4799,
    'Mireval': 4799,
    'Moissac': 4799,
    'Molières': 4799,
    'Molières-sur-Cèze': 4799,
    'Monclar-de-Quercy': 4799,
    'Mondonville': 4799,
    'Monestiés': 4799,
    'Mons': 4799,
    'Montady': 4799,
    'Montagnac': 4799,
    'Montaigu-de-Quercy': 4799,
    'Montaigut-sur-Save': 4799,
    'Montans': 4799,
    'Montaren-et-Saint-Médiers': 4799,
    'Montarnaud': 4799,
    'Montastruc-la-Conseillère': 4799,
    'Montauban': 4799,
    'Montbazens': 4799,
    'Montbazin': 4799,
    'Montberon': 4799,
    'Montbeton': 4799,
    'Montblanc': 4799,
    'Montcuq': 4799,
    'Montech': 4799,
    'Monteils': 4799,
    'Montescot': 4799,
    'Montesquieu-Volvestre': 4799,
    'Montesquiu d  Albera': 4799,
    'Montfaucon': 4799,
    'Montferrier-sur-Lez': 4799,
    'Montfrin': 4799,
    'Montgaillard': 4799,
    'Montgiscard': 4799,
    'Montjoie-en-Couserans': 4799,
    'Montjoire': 4799,
    'Montlaur': 4799,
    'Montpellier': 4799,
    'Montpeyroux': 4799,
    'Montpezat-de-Quercy': 4799,
    'Montrabé': 4799,
    'Montréal': 4799,
    'Montredon-Labessonnié': 4799,
    'Montréjeau': 4799,
    'Montricoux': 4799,
    'Montrodat': 4799,
    'Moussac': 4799,
    'Moussan': 4799,
    'Moyrazès': 4799,
    'Mudaison': 4799,
    'Muret': 4799,
    'Murviel-lès-Béziers': 4799,
    'Murviel-lès-Montpellier': 4799,
    'Mus': 4799,
    'Nages-et-Solorgues': 4799,
    'Nailloux': 4799,
    'Narbonne': 4799,
    'Naucelle': 4799,
    'Nébian': 4799,
    'Nègrepelisse': 4799,
    'Névian': 4799,
    'Nîmes': 4799,
    'Nissan-lez-Enserune': 4799,
    'Noé': 4799,
    'Nogaro': 4799,
    'Nohic': 4799,
    'Odos': 4799,
    'Olemps': 4799,
    'Olonzac': 4799,
    'Ondes': 4799,
    'Orgueil': 4799,
    'Orleix': 4799,
    'Ornaisons': 4799,
    'Orsan': 4799,
    'Ortaffa': 4799,
    'Osséja': 4799,
    'Ossun': 4799,
    'Oursbelille': 4799,
    'Ouveillan': 4799,
    'Palaja': 4799,
    'Palau-del-Vidre': 4799,
    'Palavas-les-Flots': 4799,
    'Pamiers': 4799,
    'Paulhac': 4799,
    'Paulhan': 4799,
    'Pavie': 4799,
    'Payrin-Augmontel': 4799,
    'Péchabou': 4799,
    'Pechbonnieu': 4799,
    'Pennautier': 4799,
    'Pépieux': 4799,
    'Pérols': 4799,
    'Perpignan': 4799,
    'Peyrestortes': 4799,
    'Peyriac-de-Mer': 4799,
    'Peyriac-Minervois': 4799,
    'Pézenas': 4799,
    'Pezens': 4799,
    'Pézilla-la-Rivière': 4799,
    'Pia': 4799,
    'Pibrac': 4799,
    'Pierrefitte-Nestalas': 4799,
    'Pignan': 4799,
    'Pinet': 4799,
    'Pinsaguel': 4799,
    'Plaisance': 4799,
    'Plaisance-du-Touch': 4799,
    'Pollestres': 4799,
    'Pomérols': 4799,
    'Pompertuzat': 4799,
    'Pompignan': 4799,
    'Pont-de-Larn': 4799,
    'Pont-de-Salars': 4799,
    'Pont-Saint-Esprit': 4799,
    'Ponteilla': 4799,
    'Port-la-Nouvelle': 4799,
    'Portel-des-Corbières': 4799,
    'Portet-sur-Garonne': 4799,
    'Portiragnes': 4799,
    'Portvendres': 4799,
    'Poulx': 4799,
    'Poussan': 4799,
    'Pouzac': 4799,
    'Prades': 4799,
    'Prades-le-Lez': 4799,
    'Pradines': 4799,
    'Prats de Molló': 4799,
    'Prayssac': 4799,
    'Preignan': 4799,
    'Puicheric': 4799,
    'Puissalicon': 4799,
    'Puisserguier': 4799,
    'Pujaudran': 4799,
    'Pujaut': 4799,
    'Puy-l’Évêque': 4799,
    'Puygouzon': 4799,
    'Puylaurens': 4799,
    'Quarante': 4799,
    'Quillan': 4799,
    'Quissac': 4799,
    'Rabastens': 4799,
    'Rabastens-de-Bigorre': 4799,
    'Ramonville-Saint-Agne': 4799,
    'Réalmont': 4799,
    'Réalville': 4799,
    'Redessan': 4799,
    'Remoulins': 4799,
    'Réquista': 4799,
    'Restinclières': 4799,
    'Revel': 4799,
    'Reynès': 4799,
    'Ria-Sirach': 4799,
    'Ribaute-les-Tavernes': 4799,
    'Rieumes': 4799,
    'Rieupeyroux': 4799,
    'Rieux-Minervois': 4799,
    'Rieux-Volvestre': 4799,
    'Rignac': 4799,
    'Riscle': 4799,
    'Rivesaltes': 4799,
    'Rochefort-du-Gard': 4799,
    'Rodez': 4799,
    'Rodilhan': 4799,
    'Roquecourbe': 4799,
    'Roquemaure': 4799,
    'Roquettes': 4799,
    'Rouffiac-Tolosan': 4799,
    'Roujan': 4799,
    'Rousson': 4799,
    'Sabran': 4799,
    'Saint-Affrique': 4799,
    'Saint-Alban': 4799,
    'Saint-Alban-sur-Limagnole': 4799,
    'Saint-Alexandre': 4799,
    'Saint-Amans-Soult': 4799,
    'Saint-Amans-Valtoret': 4799,
    'Saint-Ambroix': 4799,
    'Saint-André-de-Sangonis': 4799,
    'Saint-Aunès': 4799,
    'Saint-Bauzille-de-Putois': 4799,
    'Saint-Benoît-de-Carmaux': 4799,
    'Saint-Brès': 4799,
    'Saint-Céré': 4799,
    'Saint-Chaptes': 4799,
    'Saint-Chinian': 4799,
    'Saint-Christol': 4799,
    'Saint-Christol-lès-Alès': 4799,
    'Saint-Christophe-Vallon': 4799,
    'Saint-Clément-de-Rivière': 4799,
    'Saint-Cyprien-Plage': 4799,
    'Saint-Drézéry': 4799,
    'Saint-Estève': 4799,
    'Saint-Etienne-de-Tulmont': 4799,
    'Saint-Féliu-d’Avall': 4799,
    'Saint-Félix-Lauragais': 4799,
    'Saint-Florent-sur-Auzonnet': 4799,
    'Saint-Gaudens': 4799,
    'Saint-Gély-du-Fesc': 4799,
    'Saint-Geniès-Bellevue': 4799,
    'Saint-Geniès-de-Comolas': 4799,
    'Saint-Geniès-de-Fontedit': 4799,
    'Saint-Geniès-de-Malgoirès': 4799,
    'Saint-Geniès-des-Mourgues': 4799,
    'Saint-Geniez-d’Olt': 4799,
    'Saint-Génis-des-Fontaines': 4799,
    'Saint-Georges-de-Luzençon': 4799,
    'Saint-Gervasy': 4799,
    'Saint-Gilles': 4799,
    'Saint-Girons': 4799,
    'Saint-Hilaire-de-Brethmas': 4799,
    'Saint-Hippolyte': 4799,
    'Saint-Hippolyte-du-Fort': 4799,
    'Saint-Jean': 4799,
    'Saint-Jean-de-Fos': 4799,
    'Saint-Jean-de-Védas': 4799,
    'Saint-Jean-du-Falga': 4799,
    'Saint-Jean-du-Gard': 4799,
    'Saint-Jory': 4799,
    'Saint-Juéry': 4799,
    'Saint-Julien-de-Peyrolas': 4799,
    'Saint-Julien-les-Rosiers': 4799,
    'Saint-Just': 4799,
    'Saint-Lary-Soulan': 4799,
    'Saint-Laurent-de-la-Salanque': 4799,
    'Saint-Laurent-des-Arbres': 4799,
    'Saint-Lizier': 4799,
    'Saint-Loup-Cammas': 4799,
    'Saint-Lys': 4799,
    'Saint-Mamert-du-Gard': 4799,
    'Saint-Marcel-sur-Aude': 4799,
    'Saint-Martin-de-Londres': 4799,
    'Saint-Martin-de-Valgalgues': 4799,
    'Saint-Martin-Lalande': 4799,
    'Saint-Mathieu-de-Tréviers': 4799,
    'Saint-Nauphary': 4799,
    'Saint-Nazaire': 4799,
    'Saint-Nazaire-d’Aude': 4799,
    'Saint-Nicolas-de-la-Grave': 4799,
    'Saint-Orens-de-Gameville': 4799,
    'Saint-Pargoire': 4799,
    'Saint-Paul-Cap-de-Joux': 4799,
    'Saint-Paul-de-Fenouillet': 4799,
    'Saint-Paul-de-Jarrat': 4799,
    'Saint-Paulet-de-Caisson': 4799,
    'Saint-Pé-de-Bigorre': 4799,
    'Saint-Pons-de-Thomières': 4799,
    'Saint-Porquier': 4799,
    'Saint-Privat-des-Vieux': 4799,
    'Saint-Quentin-la-Poterie': 4799,
    'Saint-Sauveur': 4799,
    'Saint-Sulpice-la-Pointe': 4799,
    'Saint-Sulpice-sur-Lèze': 4799,
    'Saint-Thibéry': 4799,
    'Saint-Victor-la-Coste': 4799,
    'Sainte-Foy-de-Peyrolières': 4799,
    'Sainte-Geneviève-sur-Argence': 4799,
    'Sainte-Marie-Plage': 4799,
    'Sainte-Radegonde': 4799,
    'Saïx': 4799,
    'Saleilles': 4799,
    'Salies-du-Salat': 4799,
    'Salindres': 4799,
    'Salles-Curan': 4799,
    'Salles-la-Source': 4799,
    'Salses-le-Château': 4799,
    'Salviac': 4799,
    'Samatan': 4799,
    'Sant Andreu de Sureda': 4799,
    'Sant Joan de Pladecorts': 4799,
    'Sant Llorenç de Cerdans': 4799,
    'Saubens': 4799,
    'Saussan': 4799,
    'Sauve': 4799,
    'Sauveterre': 4799,
    'Sauvian': 4799,
    'Saverdun': 4799,
    'Saze': 4799,
    'Sébazac-Concourès': 4799,
    'Seilh': 4799,
    'Seissan': 4799,
    'Sémalens': 4799,
    'Séméac': 4799,
    'Septfonds': 4799,
    'Sérignan': 4799,
    'Sernhac': 4799,
    'Servian': 4799,
    'Sète': 4799,
    'Sévérac-le-Château': 4799,
    'Seysses': 4799,
    'Sigean': 4799,
    'Sommières': 4799,
    'Sorède': 4799,
    'Sorèze': 4799,
    'Soual': 4799,
    'Soues': 4799,
    'Souillac': 4799,
    'Sumène': 4799,
    'Sussargues': 4799,
    'Tarascon-sur-Ariège': 4799,
    'Tarbes': 4799,
    'Tarn': 4799,
    'Tavel': 4799,
    'Teyran': 4799,
    'Théza': 4799,
    'Thézan-lès-Béziers': 4799,
    'Thuir': 4799,
    'Torreilles': 4799,
    'Toulouges': 4799,
    'Toulouse': 4799,
    'Tourbes': 4799,
    'Tournay': 4799,
    'Tournefeuille': 4799,
    'Trèbes': 4799,
    'Tresques': 4799,
    'Trie-sur-Baïse': 4799,
    'Trouillas': 4799,
    'Uchaud': 4799,
    'Uzès': 4799,
    'Vacquiers': 4799,
    'Vailhauquès': 4799,
    'Valady': 4799,
    'Valence': 4799,
    'Valence-d’Albigeois': 4799,
    'Valence-sur-Baïse': 4799,
    'Valergues': 4799,
    'Vallabrègues': 4799,
    'Valleraugue': 4799,
    'Valras-Plage': 4799,
    'Valros': 4799,
    'Varilhes': 4799,
    'Vauvert': 4799,
    'Vayrac': 4799,
    'Vendargues': 4799,
    'Vendres': 4799,
    'Vénéjan': 4799,
    'Venerque': 4799,
    'Verdun-sur-Garonne': 4799,
    'Verfeil': 4799,
    'Vergèze': 4799,
    'Vernet': 4799,
    'Vernet-les-Bains': 4799,
    'Verniolle': 4799,
    'Vers-Pont-du-Gard': 4799,
    'Vestric-et-Candiac': 4799,
    'Vézénobres': 4799,
    'Vias': 4799,
    'Vic-en-Bigorre': 4799,
    'Vic-Fezensac': 4799,
    'Vic-la-Gardiole': 4799,
    'Vieille-Toulouse': 4799,
    'Vielmur-sur-Agout': 4799,
    'Vilallonga dels Monts': 4799,
    'Villaudric': 4799,
    'Villefranche-d  Albigeois': 4799,
    'Villefranche-de-Lauragais': 4799,
    'Villefranche-de-Rouergue': 4799,
    'Villegailhenc': 4799,
    'Villelongue-de-la-Salanque': 4799,
    'Villemolaque': 4799,
    'Villemoustaussou': 4799,
    'Villemur-sur-Tarn': 4799,
    'Villeneuve': 4799,
    'Villeneuve-d’Olmes': 4799,
    'Villeneuve-de-la-Raho': 4799,
    'Villeneuve-de-Rivière': 4799,
    'Villeneuve-la-Comptal': 4799,
    'Villeneuve-la-Rivière': 4799,
    'Villeneuve-lès-Avignon': 4799,
    'Villeneuve-lès-Béziers': 4799,
    'Villeneuve-lès-Bouloc': 4799,
    'Villeneuve-lès-Maguelone': 4799,
    'Villeneuve-Tolosane': 4799,
    'Villenouvelle': 4799,
    'Villepinte': 4799,
    'Villetelle': 4799,
    'Villeveyrac': 4799,
    'Villevieille': 4799,
    'Vinassan': 4799,
    'Vinça': 4799,
    'Viviers-lès-Montagnes': 4799,
    'Viviez': 4799,
    'Abbaretz': 4802,
    'Ahuillé': 4802,
    'Aigné': 4802,
    'Aigrefeuille-sur-Maine': 4802,
    'Aizenay': 4802,
    'Allonnes': 4802,
    'Ambrières-les-Vallées': 4802,
    'Ancenis': 4802,
    'Andard': 4802,
    'Andouillé': 4802,
    'Andrezé': 4802,
    'Anetz': 4802,
    'Angers': 4802,
    'Angles': 4802,
    'Antigny': 4802,
    'Apremont': 4802,
    'Arçonnay': 4802,
    'Argentré': 4802,
    'Arnage': 4802,
    'Aron': 4802,
    'Arthon-en-Retz': 4802,
    'Assérac': 4802,
    'Aubigné-Racan': 4802,
    'Aubigny': 4802,
    'Auvers-le-Hamon': 4802,
    'Avessac': 4802,
    'Avrillé': 4802,
    'Azé': 4802,
    'Bais': 4802,
    'Ballon': 4802,
    'Ballots': 4802,
    'Barbâtre': 4802,
    'Barbechat': 4802,
    'Basse-Goulaine': 4802,
    'Batz-sur-Mer': 4802,
    'Baugé-en-Anjou': 4802,
    'Bauné': 4802,
    'Bazoges-en-Pareds': 4802,
    'Bazouges-sur-le-Loir': 4802,
    'Beaucouzé': 4802,
    'Beaufay': 4802,
    'Beaufort-en-Vallée': 4802,
    'Beaulieu-sous-la-Roche': 4802,
    'Beaulieu-sur-Layon': 4802,
    'Beaumont-sur-Sarthe': 4802,
    'Beaupréau': 4802,
    'Beaurepaire': 4802,
    'Beauvoir-sur-Mer': 4802,
    'Bécon-les-Granits': 4802,
    'Bégrolles-en-Mauges': 4802,
    'Belleville-sur-Vie': 4802,
    'Belligné': 4802,
    'Benet': 4802,
    'Besné': 4802,
    'Bessé-sur-Braye': 4802,
    'Blain': 4802,
    'Blaison-Gohier': 4802,
    'Bois-de-Cené': 4802,
    'Bonchamp-lès-Laval': 4802,
    'Bonnétable': 4802,
    'Bouaye': 4802,
    'Bouchemaine': 4802,
    'Boufféré': 4802,
    'Bouguenais': 4802,
    'Bouin': 4802,
    'Bouloire': 4802,
    'Bourg de Joué-sur-Erdre': 4802,
    'Bourgneuf-en-Retz': 4802,
    'Bournezeau': 4802,
    'Boussay': 4802,
    'Bouvron': 4802,
    'Bouzillé': 4802,
    'Brain-sur-Allonnes': 4802,
    'Brains': 4802,
    'Bretignolles-sur-Mer': 4802,
    'Brette-les-Pins': 4802,
    'Brézé': 4802,
    'Briollay': 4802,
    'Brion': 4802,
    'Brissac-Quincé': 4802,
    'Brûlon': 4802,
    'Campbon': 4802,
    'Candé': 4802,
    'Cantenay-Épinard': 4802,
    'Carquefou': 4802,
    'Casson': 4802,
    'Cérans-Foulletourte': 4802,
    'Chacé': 4802,
    'Chailland': 4802,
    'Chaillé-les-Marais': 4802,
    'Challans': 4802,
    'Challes': 4802,
    'Chalonnes-sur-Loire': 4802,
    'Chambretaud': 4802,
    'Champagné': 4802,
    'Champagné-les-Marais': 4802,
    'Champfleur': 4802,
    'Champigné': 4802,
    'Champtocé-sur-Loire': 4802,
    'Champtoceaux': 4802,
    'Changé': 4802,
    'Chantonnay': 4802,
    'Château-d’Olonne': 4802,
    'Château-du-Loir': 4802,
    'Château-Gontier': 4802,
    'Château-Guibert': 4802,
    'Châteaubriant': 4802,
    'Châteauneuf-sur-Sarthe': 4802,
    'Chauché': 4802,
    'Chaudron-en-Mauges': 4802,
    'Chauvé': 4802,
    'Chavagnes-en-Paillers': 4802,
    'Chemazé': 4802,
    'Chéméré': 4802,
    'Chemillé-Melay': 4802,
    'Chênehutte-Trèves-Cunault': 4802,
    'Cherré': 4802,
    'Cholet': 4802,
    'Clermont-Créans': 4802,
    'Clisson': 4802,
    'Coëx': 4802,
    'Combrée': 4802,
    'Commequiers': 4802,
    'Commer': 4802,
    'Congrier': 4802,
    'Conlie': 4802,
    'Connerré': 4802,
    'Conquereuil': 4802,
    'Corcoué-sur-Logne': 4802,
    'Cordemais': 4802,
    'Corné': 4802,
    'Coron': 4802,
    'Corsept': 4802,
    'Corzé': 4802,
    'Cossé-le-Vivien': 4802,
    'Couëron': 4802,
    'Couffé': 4802,
    'Coulaines': 4802,
    'Coulans-sur-Gée': 4802,
    'Courcité': 4802,
    'Craon': 4802,
    'Crossac': 4802,
    'Cugand': 4802,
    'Daumeray': 4802,
    'Denée': 4802,
    'Département de la Vendée': 4802,
    'Département de Maine-et-Loire': 4802,
    'Derval': 4802,
    'Distré': 4802,
    'Dollon': 4802,
    'Dompierre-sur-Yon': 4802,
    'Donges': 4802,
    'Doué-la-Fontaine': 4802,
    'Drain': 4802,
    'Drefféac': 4802,
    'Durtal': 4802,
    'Écommoy': 4802,
    'Écouflant': 4802,
    'Entrammes': 4802,
    'Erbray': 4802,
    'Ernée': 4802,
    'Étival-lès-le-Mans': 4802,
    'Étriché': 4802,
    'Évron': 4802,
    'Falleron': 4802,
    'Fay-de-Bretagne': 4802,
    'Fégréac': 4802,
    'Feneu': 4802,
    'Fillé': 4802,
    'Fontenay-le-Comte': 4802,
    'Fontevraud-l  Abbaye': 4802,
    'Fougerolles-du-Plessis': 4802,
    'Foussais-Payré': 4802,
    'Freigné': 4802,
    'Fresnay-sur-Sarthe': 4802,
    'Froidfond': 4802,
    'Frossay': 4802,
    'Geneston': 4802,
    'Gennes': 4802,
    'Gesté': 4802,
    'Gétigné': 4802,
    'Givrand': 4802,
    'Gorges': 4802,
    'Gorron': 4802,
    'Grez-Neuville': 4802,
    'Grosbreuil': 4802,
    'Guécélard': 4802,
    'Guémené-Penfao': 4802,
    'Guenrouet': 4802,
    'Guérande': 4802,
    'Haute-Goulaine': 4802,
    'Herbignac': 4802,
    'Héric': 4802,
    'Indre': 4802,
    'Ingrandes': 4802,
    'Issé': 4802,
    'Jallais': 4802,
    'Jans': 4802,
    'Jard-sur-Mer': 4802,
    'Jarzé': 4802,
    'Javron-les-Chapelles': 4802,
    'Juigné': 4802,
    'Juigné-sur-Loire': 4802,
    'Juigné-sur-Sarthe': 4802,
    'Jumelles': 4802,
    'Juvigné': 4802,
    'La Baconnière': 4802,
    'La Barre-de-Monts': 4802,
    'La Baule-Escoublac': 4802,
    'La Bazoge': 4802,
    'La Bernardière': 4802,
    'La Bernerie-en-Retz': 4802,
    'La Bohalle': 4802,
    'La Boissière-de-Montaigu': 4802,
    'La Boissière-des-Landes': 4802,
    'La Bruffière': 4802,
    'La Chaize-le-Vicomte': 4802,
    'La Chapelle-Achard': 4802,
    'La Chapelle-Basse-Mer': 4802,
    'La Chapelle-des-Marais': 4802,
    'La Chapelle-du-Genêt': 4802,
    'La Chapelle-Heulin': 4802,
    'La Chapelle-Launay': 4802,
    'La Chapelle-Saint-Aubin': 4802,
    'La Chapelle-Saint-Florent': 4802,
    'La Chapelle-sur-Erdre': 4802,
    'La Chartre-sur-le-Loir': 4802,
    'La Châtaigneraie': 4802,
    'La Chevrolière': 4802,
    'La Daguenière': 4802,
    'La Ferrière': 4802,
    'La Ferté-Bernard': 4802,
    'La Flèche': 4802,
    'La Flocellière': 4802,
    'La Garnache': 4802,
    'La Gaubretière': 4802,
    'La Génétouze': 4802,
    'La Grigonnais': 4802,
    'La Guérinière': 4802,
    'La Guyonnière': 4802,
    'La Haie-Fouassière': 4802,
    'La Jubaudière': 4802,
    'La Jumellière': 4802,
    'La Limouzinière': 4802,
    'La Meignanne': 4802,
    'La Meilleraie-Tillay': 4802,
    'La Meilleraye-de-Bretagne': 4802,
    'La Membrolle-sur-Longuenée': 4802,
    'La Ménitré': 4802,
    'La Milesse': 4802,
    'La Montagne': 4802,
    'La Mothe-Achard': 4802,
    'La Plaine-sur-Mer': 4802,
    'La Planche': 4802,
    'La Pommeraie-sur-Sèvre': 4802,
    'La Pommeraye': 4802,
    'La Possonnière': 4802,
    'La Pouëze': 4802,
    'La Regrippière': 4802,
    'La Remaudière': 4802,
    'La Roche-sur-Yon': 4802,
    'La Romagne': 4802,
    'La Salle-et-Chapelle-Aubry': 4802,
    'La Séguinière': 4802,
    'La Suze-sur-Sarthe': 4802,
    'La Tardière': 4802,
    'La Tessoualle': 4802,
    'La Tourlandry': 4802,
    'La Tranche-sur-Mer': 4802,
    'La Turballe': 4802,
    'La Varenne': 4802,
    'La Verrie': 4802,
    'Laigné-en-Belin': 4802,
    'Landemont': 4802,
    'Landeronde': 4802,
    'Landivy': 4802,
    'Larchamp': 4802,
    'Lassay-les-Châteaux': 4802,
    'Laval': 4802,
    'Le Bignon': 4802,
    'Le Boupère': 4802,
    'Le Bourgneuf-la-Forêt': 4802,
    'Le Breil-sur-Mérize': 4802,
    'Le Cellier': 4802,
    'Le Champ-Saint-Père': 4802,
    'Le Croisic': 4802,
    'Le Fenouiller': 4802,
    'Le Fief-Sauvin': 4802,
    'Le Fuilet': 4802,
    'Le Gâvre': 4802,
    'Le Grand-Lucé': 4802,
    'Le Landreau': 4802,
    'Le Langon': 4802,
    'Le Longeron': 4802,
    'Le Loroux-Bottereau': 4802,
    'Le Louroux-Béconnais': 4802,
    'Le Luart': 4802,
    'Le Lude': 4802,
    'Le Mans': 4802,
    'Le May-sur-Èvre': 4802,
    'Le Mesnil-en-Vallée': 4802,
    'Le Pallet': 4802,
    'Le Pellerin': 4802,
    'Le Perrier': 4802,
    'Le Pin-en-Mauges': 4802,
    'Le Plessis-Grammoire': 4802,
    'Le Plessis-Macé': 4802,
    'Le Poiré-sur-Vie': 4802,
    'Le Pouliguen': 4802,
    'Le Puy-Notre-Dame': 4802,
    'Le Temple-de-Bretagne': 4802,
    'Le Vieil-Baugé': 4802,
    'Legé': 4802,
    'Les Brouzils': 4802,
    'Les Clouzeaux': 4802,
    'Les Epesses': 4802,
    'Les Essarts': 4802,
    'Les Herbiers': 4802,
    'Les Landes-Genusson': 4802,
    'Les Lucs-sur-Boulogne': 4802,
    'Les Magnils-Reigniers': 4802,
    'Les Moutiers-en-Retz': 4802,
    'Les Ponts-de-Cé': 4802,
    'Les Rosiers-sur-Loire': 4802,
    'Les Sables-d’Olonne': 4802,
    'Les Sorinières': 4802,
    'Les Touches': 4802,
    'Ligné': 4802,
    'Liré': 4802,
    'Loire-Atlantique': 4802,
    'Loiron': 4802,
    'Lombron': 4802,
    'Longeville-sur-Mer': 4802,
    'Loué': 4802,
    'Louplande': 4802,
    'Louverné': 4802,
    'Luceau': 4802,
    'Luché-Pringé': 4802,
    'Luçon': 4802,
    'Lusanger': 4802,
    'Maché': 4802,
    'Machecoul': 4802,
    'Maillezais': 4802,
    'Maisdon-sur-Sèvre': 4802,
    'Malicorne-sur-Sarthe': 4802,
    'Malville': 4802,
    'Mamers': 4802,
    'Mansigné': 4802,
    'Marçon': 4802,
    'Mareuil-sur-Lay-Dissais': 4802,
    'Marigné-Laillé': 4802,
    'Marolles-les-Braults': 4802,
    'Marsac-sur-Don': 4802,
    'Martigné-Briand': 4802,
    'Martigné-sur-Mayenne': 4802,
    'Maulévrier': 4802,
    'Mauves-sur-Loire': 4802,
    'Mayenne': 4802,
    'Mayet': 4802,
    'Mazé': 4802,
    'Mazières-en-Mauges': 4802,
    'Melay': 4802,
    'Mervent': 4802,
    'Mésanger': 4802,
    'Meslay-du-Maine': 4802,
    'Mesquer': 4802,
    'Mézeray': 4802,
    'Miré': 4802,
    'Missillac': 4802,
    'Moisdon-la-Rivière': 4802,
    'Moncé-en-Belin': 4802,
    'Monnières': 4802,
    'Montaigu': 4802,
    'Montbert': 4802,
    'Montbizot': 4802,
    'Montenay': 4802,
    'Montfaucon-Montigné': 4802,
    'Montfort-le-Gesnois': 4802,
    'Montigné-le-Brillant': 4802,
    'Montjean-sur-Loire': 4802,
    'Montoir-de-Bretagne': 4802,
    'Montournais': 4802,
    'Montreuil-Bellay': 4802,
    'Montrevault': 4802,
    'Montsûrs': 4802,
    'Morannes': 4802,
    'Mortagne-sur-Sèvre': 4802,
    'Mouchamps': 4802,
    'Mouilleron-en-Pareds': 4802,
    'Mouilleron-le-Captif': 4802,
    'Moulay': 4802,
    'Moutiers-les-Mauxfaits': 4802,
    'Mouzeil': 4802,
    'Mouzillon': 4802,
    'Mozé-sur-Louet': 4802,
    'Mulsanne': 4802,
    'Mûrs-Erigné': 4802,
    'Nalliers': 4802,
    'Nantes': 4802,
    'Nesmy': 4802,
    'Neuville-sur-Sarthe': 4802,
    'Nieul-le-Dolent': 4802,
    'Noirmoutier-en-l’Île': 4802,
    'Nort-sur-Erdre': 4802,
    'Notre-Dame-de-Monts': 4802,
    'Notre-Dame-de-Riez': 4802,
    'Notre-Dame-des-Landes': 4802,
    'Noyant': 4802,
    'Noyant-la-Gravoyère': 4802,
    'Noyen-sur-Sarthe': 4802,
    'Nozay': 4802,
    'Nuaillé': 4802,
    'Nueil-sur-Layon': 4802,
    'Nuillé-sur-Vicoin': 4802,
    'Nyoiseau': 4802,
    'Oisseau': 4802,
    'Olonne-sur-Mer': 4802,
    'Orvault': 4802,
    'Oudon': 4802,
    'Paimboeuf': 4802,
    'Pannecé': 4802,
    'Parçay-les-Pins': 4802,
    'Parcé-sur-Sarthe': 4802,
    'Parigné-le-Pôlin': 4802,
    'Paulx': 4802,
    'Pellouailles-les-Vignes': 4802,
    'Petit-Mars': 4802,
    'Piriac-sur-Mer': 4802,
    'Pissotte': 4802,
    'Plessé': 4802,
    'Pont-Saint-Martin': 4802,
    'Pontchâteau': 4802,
    'Pontvallain': 4802,
    'Pornic': 4802,
    'Pornichet': 4802,
    'Port-Brillet': 4802,
    'Port-Saint-Père': 4802,
    'Pouancé': 4802,
    'Pouzauges': 4802,
    'Pré-en-Pail': 4802,
    'Précigné': 4802,
    'Préfailles': 4802,
    'Prinquiau': 4802,
    'Pruillé-le-Chétif': 4802,
    'Quelaines-Saint-Gault': 4802,
    'Quilly': 4802,
    'Remouillé': 4802,
    'Renazé': 4802,
    'Requeil': 4802,
    'Rezé': 4802,
    'Riaillé': 4802,
    'Rochefort-sur-Loire': 4802,
    'Rocheservière': 4802,
    'Roézé-sur-Sarthe': 4802,
    'Rouans': 4802,
    'Rougé': 4802,
    'Rouillon': 4802,
    'Roussay': 4802,
    'Ruaudin': 4802,
    'Ruillé-sur-Loir': 4802,
    'Sablé-sur-Sarthe': 4802,
    'Saffré': 4802,
    'Saint-André-de-la-Marche': 4802,
    'Saint-André-des-Eaux': 4802,
    'Saint-André-Treize-Voies': 4802,
    'Saint-Aubin-des-Châteaux': 4802,
    'Saint-Aubin-des-Ormeaux': 4802,
    'Saint-Baudelle': 4802,
    'Saint-Berthevin': 4802,
    'Saint-Brevin-les-Pins': 4802,
    'Saint-Calais': 4802,
    'Saint-Christophe-du-Bois': 4802,
    'Saint-Christophe-du-Ligneron': 4802,
    'Saint-Clément-de-la-Place': 4802,
    'Saint-Clément-des-Levées': 4802,
    'Saint-Cosme-en-Vairais': 4802,
    'Saint-Crespin-sur-Moine': 4802,
    'Saint-Cyr-en-Bourg': 4802,
    'Saint-Denis-de-Gastines': 4802,
    'Saint-Denis-la-Chevasse': 4802,
    'Saint-Étienne-de-Mer-Morte': 4802,
    'Saint-Étienne-de-Montluc': 4802,
    'Saint-Étienne-du-Bois': 4802,
    'Saint-Fiacre-sur-Maine': 4802,
    'Saint-Florent-des-Bois': 4802,
    'Saint-Florent-le-Vieil': 4802,
    'Saint-Fort': 4802,
    'Saint-Fulgent': 4802,
    'Saint-Georges-Buttavent': 4802,
    'Saint-Georges-de-Montaigu': 4802,
    'Saint-Georges-de-Pointindoux': 4802,
    'Saint-Georges-du-Bois': 4802,
    'Saint-Georges-sur-Loire': 4802,
    'Saint-Géréon': 4802,
    'Saint-Germain-de-Prinçay': 4802,
    'Saint-Germain-des-Prés': 4802,
    'Saint-Germain-sur-Moine': 4802,
    'Saint-Gervais': 4802,
    'Saint-Gervais-en-Belin': 4802,
    'Saint-Gildas-des-Bois': 4802,
    'Saint-Gilles-Croix-de-Vie': 4802,
    'Saint-Herblain': 4802,
    'Saint-Herblon': 4802,
    'Saint-Hilaire-de-Chaléons': 4802,
    'Saint-Hilaire-de-Clisson': 4802,
    'Saint-Hilaire-de-Loulay': 4802,
    'Saint-Hilaire-de-Riez': 4802,
    'Saint-Hilaire-de-Talmont': 4802,
    'Saint-Hilaire-des-Loges': 4802,
    'Saint-Jean': 4802,
    'Saint-Jean-de-Boiseau': 4802,
    'Saint-Jean-de-Monts': 4802,
    'Saint-Jean-des-Mauvrets': 4802,
    'Saint-Jean-sur-Mayenne': 4802,
    'Saint-Joachim': 4802,
    'Saint-Julien-de-Concelles': 4802,
    'Saint-Julien-des-Landes': 4802,
    'Saint-Lambert-du-Lattay': 4802,
    'Saint-Lambert-la-Potherie': 4802,
    'Saint-Laurent-de-la-Plaine': 4802,
    'Saint-Laurent-des-Autels': 4802,
    'Saint-Laurent-sur-Sèvre': 4802,
    'Saint-Léger-des-Bois': 4802,
    'Saint-Léger-les-Vignes': 4802,
    'Saint-Léger-sous-Cholet': 4802,
    'Saint-Lumine-de-Clisson': 4802,
    'Saint-Lumine-de-Coutais': 4802,
    'Saint-Lyphard': 4802,
    'Saint-Macaire-en-Mauges': 4802,
    'Saint-Malo-de-Guersac': 4802,
    'Saint-Malô-du-Bois': 4802,
    'Saint-Mars-d’Outillé': 4802,
    'Saint-Mars-de-Coutais': 4802,
    'Saint-Mars-du-Désert': 4802,
    'Saint-Mars-la-Brière': 4802,
    'Saint-Mars-la-Jaille': 4802,
    'Saint-Martin-de-la-Place': 4802,
    'Saint-Martin-des-Noyers': 4802,
    'Saint-Martin-du-Fouilloux': 4802,
    'Saint-Martin-sous-Mouzeuil': 4802,
    'Saint-Mathurin': 4802,
    'Saint-Mathurin-sur-Loire': 4802,
    'Saint-Melaine-sur-Aubance': 4802,
    'Saint-Même-le-Tenu': 4802,
    'Saint-Mesmin': 4802,
    'Saint-Michel-Chef-Chef': 4802,
    'Saint-Michel-le-Cloucq': 4802,
    'Saint-Michel-Mont-Mercure': 4802,
    'Saint-Molf': 4802,
    'Saint-Nazaire': 4802,
    'Saint-Nicolas-de-Redon': 4802,
    'Saint-Ouën-des-Toits': 4802,
    'Saint-Ouen-en-Belin': 4802,
    'Saint-Paterne': 4802,
    'Saint-Paul-en-Pareds': 4802,
    'Saint-Père-en-Retz': 4802,
    'Saint-Philbert-de-Bouaine': 4802,
    'Saint-Philbert-de-Grand-Lieu': 4802,
    'Saint-Philbert-du-Peuple': 4802,
    'Saint-Pierre-des-Nids': 4802,
    'Saint-Pierre-du-Chemin': 4802,
    'Saint-Pierre-la-Cour': 4802,
    'Saint-Pierre-Montlimart': 4802,
    'Saint-Prouant': 4802,
    'Saint-Quentin-en-Mauges': 4802,
    'Saint-Rémy-en-Mauges': 4802,
    'Saint-Saturnin': 4802,
    'Saint-Saturnin-sur-Loire': 4802,
    'Saint-Sébastien-sur-Loire': 4802,
    'Saint-Urbain': 4802,
    'Saint-Viaud': 4802,
    'Saint-Vincent-des-Landes': 4802,
    'Saint-Vincent-sur-Graon': 4802,
    'Sainte-Anne-sur-Brivet': 4802,
    'Sainte-Cécile': 4802,
    'Sainte-Flaive-des-Loups': 4802,
    'Sainte-Foy': 4802,
    'Sainte-Gemme-la-Plaine': 4802,
    'Sainte-Gemmes-sur-Loire': 4802,
    'Sainte-Hermine': 4802,
    'Sainte-Jamme-sur-Sarthe': 4802,
    'Sainte-Luce-sur-Loire': 4802,
    'Sainte-Pazanne': 4802,
    'Sainte-Reine-de-Bretagne': 4802,
    'Sainte-Suzanne': 4802,
    'Saligny': 4802,
    'Sallertaine': 4802,
    'Sargé-lès-le-Mans': 4802,
    'Sarthe': 4802,
    'Saumur': 4802,
    'Sautron': 4802,
    'Savenay': 4802,
    'Savennières': 4802,
    'Segré': 4802,
    'Seiches-sur-le-Loir': 4802,
    'Sérigné': 4802,
    'Sévérac': 4802,
    'Sillé-le-Guillaume': 4802,
    'Sion-les-Mines': 4802,
    'Solesmes': 4802,
    'Soucelles': 4802,
    'Soudan': 4802,
    'Soulaines-sur-Aubance': 4802,
    'Soulaire-et-Bourg': 4802,
    'Soulgé-sur-Ouette': 4802,
    'Souligné-sous-Ballon': 4802,
    'Soullans': 4802,
    'Spay': 4802,
    'Sucé-sur-Erdre': 4802,
    'Teillé': 4802,
    'Teloché': 4802,
    'Tennie': 4802,
    'Thorigné-sur-Dué': 4802,
    'Thouarcé': 4802,
    'Thouaré-sur-Loire': 4802,
    'Tiercé': 4802,
    'Tiffauges': 4802,
    'Tillières': 4802,
    'Torfou': 4802,
    'Toutlemonde': 4802,
    'Touvois': 4802,
    'Trangé': 4802,
    'Treillières': 4802,
    'Treize-Septiers': 4802,
    'Trélazé': 4802,
    'Trémentines': 4802,
    'Triaize': 4802,
    'Trignac': 4802,
    'Tuffé': 4802,
    'Vaas': 4802,
    'Vaiges': 4802,
    'Vairé': 4802,
    'Vallet': 4802,
    'Varades': 4802,
    'Varennes-sur-Loire': 4802,
    'Varrains': 4802,
    'Vauchrétien': 4802,
    'Vaudelnay': 4802,
    'Vay': 4802,
    'Venansault': 4802,
    'Vendrennes': 4802,
    'Vern-d’Anjou': 4802,
    'Vernantes': 4802,
    'Vernoil-le-Fourrier': 4802,
    'Vertou': 4802,
    'Vezins': 4802,
    'Vibraye': 4802,
    'Vieillevigne': 4802,
    'Vigneux-de-Bretagne': 4802,
    'Vihiers': 4802,
    'Villaines-la-Juhel': 4802,
    'Villebernier': 4802,
    'Villedieu-la-Blouère': 4802,
    'Villevêque': 4802,
    'Vion': 4802,
    'Vivy': 4802,
    'Vix': 4802,
    'Vue': 4802,
    'Yvré-le-Pôlin': 4802,
    'Yzernay': 4802,
    'Aix-en-Provence': 4812,
    'Allauch': 4812,
    'Alleins': 4812,
    'Alpes-de-Haute-Provence': 4812,
    'Alpes-Maritimes': 4812,
    'Althen-des-Paluds': 4812,
    'Annot': 4812,
    'Ansouis': 4812,
    'Antibes': 4812,
    'Apt': 4812,
    'Arenc': 4812,
    'Arles': 4812,
    'Aspremont': 4812,
    'Aubagne': 4812,
    'Aubignan': 4812,
    'Aups': 4812,
    'Aureille': 4812,
    'Auribeau-sur-Siagne': 4812,
    'Auriol': 4812,
    'Avignon': 4812,
    'Bagnols-en-Forêt': 4812,
    'Baille': 4812,
    'Bandol': 4812,
    'Barbentane': 4812,
    'Barcelonnette': 4812,
    'Bargemon': 4812,
    'Barjols': 4812,
    'Beaulieu-sur-Mer': 4812,
    'Beaumes-de-Venise': 4812,
    'Beaumont-de-Pertuis': 4812,
    'Beausoleil': 4812,
    'Bédarrides': 4812,
    'Bédoin': 4812,
    'Belcodène': 4812,
    'Belgentier': 4812,
    'Belle de Mai': 4812,
    'Belsunce': 4812,
    'Berre-l  Étang': 4812,
    'Berre-les-Alpes': 4812,
    'Besse-sur-Issole': 4812,
    'Biot': 4812,
    'Blausasc': 4812,
    'Bollène': 4812,
    'Bon-Secours': 4812,
    'Bonneveine': 4812,
    'Bonnieux': 4812,
    'Borel': 4812,
    'Bormes-les-Mimosas': 4812,
    'Bouc-Bel-Air': 4812,
    'Boulbon': 4812,
    'Bras': 4812,
    'Breil-sur-Roya': 4812,
    'Briançon': 4812,
    'Brignoles': 4812,
    'Cabannes': 4812,
    'Cabasse': 4812,
    'Cabriès': 4812,
    'Cabris': 4812,
    'Cadenet': 4812,
    'Caderousse': 4812,
    'Cadolive': 4812,
    'Cagnes-sur-Mer': 4812,
    'Callas': 4812,
    'Callian': 4812,
    'Camaret-sur-Aigues': 4812,
    'Camps-la-Source': 4812,
    'Cannes': 4812,
    'Cantaron': 4812,
    'Cap-d’Ail': 4812,
    'Carcès': 4812,
    'Carnoules': 4812,
    'Carnoux-en-Provence': 4812,
    'Caromb': 4812,
    'Carpentras': 4812,
    'Carqueiranne': 4812,
    'Carros': 4812,
    'Carry-le-Rouet': 4812,
    'Cassis': 4812,
    'Castagniers': 4812,
    'Castellane': 4812,
    'Caumont-sur-Durance': 4812,
    'Cavaillon': 4812,
    'Cavalaire-sur-Mer': 4812,
    'Céreste': 4812,
    'Ceyreste': 4812,
    'Charleval': 4812,
    'Châteauneuf-de-Gadagne': 4812,
    'Châteauneuf-du-Pape': 4812,
    'Châteauneuf-Grasse': 4812,
    'Châteauneuf-le-Rouge': 4812,
    'Châteauneuf-les-Martigues': 4812,
    'Châteaurenard': 4812,
    'Cheval-Blanc': 4812,
    'Chorges': 4812,
    'Chutes-Lavie': 4812,
    'Cinq Avenues': 4812,
    'Cogolin': 4812,
    'Collobrières': 4812,
    'Colomars': 4812,
    'Contes': 4812,
    'Cornillon-Confoux': 4812,
    'Cotignac': 4812,
    'Coudoux': 4812,
    'Courthézon': 4812,
    'Cucuron': 4812,
    'Cuers': 4812,
    'Cuges-les-Pins': 4812,
    'Département des Bouches-du-Rhône': 4812,
    'Département du Vaucluse': 4812,
    'Digne-les-Bains': 4812,
    'Draguignan': 4812,
    'Drap': 4812,
    'Éguilles': 4812,
    'Embrun': 4812,
    'Ensuès-la-Redonne': 4812,
    'Entraigues-sur-la-Sorgue': 4812,
    'Éoures': 4812,
    'Évenos': 4812,
    'Eygalières': 4812,
    'Eyguières': 4812,
    'Eyragues': 4812,
    'Èze': 4812,
    'Falicon': 4812,
    'Fayence': 4812,
    'Figanières': 4812,
    'Flassans-sur-Issole': 4812,
    'Flayosc': 4812,
    'Fontvieille': 4812,
    'Forcalqueiret': 4812,
    'Forcalquier': 4812,
    'Fos-sur-Mer': 4812,
    'Fréjus': 4812,
    'Fuveau': 4812,
    'Gap': 4812,
    'Gardanne': 4812,
    'Garéoult': 4812,
    'Gargas': 4812,
    'Gassin': 4812,
    'Gattières': 4812,
    'Gémenos': 4812,
    'Gignac-la-Nerthe': 4812,
    'Gilette': 4812,
    'Ginasservis': 4812,
    'Gonfaron': 4812,
    'Gorbio': 4812,
    'Gordes': 4812,
    'Goult': 4812,
    'Grambois': 4812,
    'Grans': 4812,
    'Grasse': 4812,
    'Graveson': 4812,
    'Gréasque': 4812,
    'Gréoux-les-Bains': 4812,
    'Grillon': 4812,
    'Grimaud': 4812,
    'Guillestre': 4812,
    'Hautes-Alpes': 4812,
    'Hyères': 4812,
    'Istres': 4812,
    'Jausiers': 4812,
    'Jonquerettes': 4812,
    'Jonquières': 4812,
    'Jouques': 4812,
    'L  Estaque': 4812,
    'L’Escale': 4812,
    'L’Escarène': 4812,
    'L’Isle-sur-la-Sorgue': 4812,
    'La Barasse': 4812,
    'La Bastide-des-Jourdans': 4812,
    'La Bâtie-Neuve': 4812,
    'La Blancarde': 4812,
    'La Bouilladisse': 4812,
    'La Cabucelle': 4812,
    'La Calade': 4812,
    'La Capelette': 4812,
    'La Celle': 4812,
    'La Ciotat': 4812,
    'La Colle-sur-Loup': 4812,
    'La Conception': 4812,
    'La Crau': 4812,
    'La Croix-Rouge': 4812,
    'La Croix-Valmer': 4812,
    'La Delorme': 4812,
    'La Destrousse': 4812,
    'La Fare-les-Oliviers': 4812,
    'La Farlède': 4812,
    'La Fourragère': 4812,
    'La Garde': 4812,
    'La Garde-Freinet': 4812,
    'La Gaude': 4812,
    'La Joliette': 4812,
    'La Londe-les-Maures': 4812,
    'La Millère': 4812,
    'La Motte': 4812,
    'La Page': 4812,
    'La Panouse': 4812,
    'La Penne-sur-Huveaune': 4812,
    'La Pointe Rouge': 4812,
    'La Pomme': 4812,
    'La Roche-des-Arnauds': 4812,
    'La Roque-d’Anthéron': 4812,
    'La Roquebrussanne': 4812,
    'La Roquette-sur-Siagne': 4812,
    'La Rose': 4812,
    'La Seyne-sur-Mer': 4812,
    'La Timone': 4812,
    'La Trinité': 4812,
    'La Turbie': 4812,
    'La Valbarelle': 4812,
    'La Valentine': 4812,
    'La Valette-du-Var': 4812,
    'La Villette': 4812,
    'La Viste': 4812,
    'Lagnes': 4812,
    'Lamanon': 4812,
    'Lambesc': 4812,
    'Lançon-Provence': 4812,
    'Lantosque': 4812,
    'Lapalud': 4812,
    'Laragne-Montéglin': 4812,
    'Lauris': 4812,
    'Le Bar-sur-Loup': 4812,
    'Le Beausset': 4812,
    'Le Broc': 4812,
    'Le Brusquet': 4812,
    'Le Cabot': 4812,
    'Le Camas': 4812,
    'Le Canet': 4812,
    'Le Cannet': 4812,
    'Le Cannet-des-Maures': 4812,
    'Le Castellet': 4812,
    'Le Chapitre': 4812,
    'Le Lavandou': 4812,
    'Le Luc': 4812,
    'Le Merlan': 4812,
    'Le Monêtier-les-Bains': 4812,
    'Le Muy': 4812,
    'Le Pharo': 4812,
    'Le Plan-de-la-Tour': 4812,
    'Le Pontet': 4812,
    'Le Pradet': 4812,
    'Le Puy-Sainte-Réparade': 4812,
    'Le Redon': 4812,
    'Le Revest-les-Eaux': 4812,
    'Le Rouret': 4812,
    'Le Rove': 4812,
    'Le Tholonet': 4812,
    'Le Thor': 4812,
    'Le Thoronet': 4812,
    'Le Tignet': 4812,
    'Le Val': 4812,
    'Les Accates': 4812,
    'Les Arcs': 4812,
    'Les Arnavaux': 4812,
    'Les Aygalades': 4812,
    'Les Baumettes': 4812,
    'Les Caillols': 4812,
    'Les Camoins': 4812,
    'Les Chartreux': 4812,
    'Les Crottes': 4812,
    'Les Grands Carmes': 4812,
    'Les Médecins': 4812,
    'Les Mées': 4812,
    'Les Olives': 4812,
    'Les Pennes-Mirabeau': 4812,
    'Les Trois-Lucs': 4812,
    'Levens': 4812,
    'Lodi': 4812,
    'Lorgues': 4812,
    'Loriol-du-Comtat': 4812,
    'Lourmarin': 4812,
    'Lucéram': 4812,
    'Maillane': 4812,
    'Malaucène': 4812,
    'Malemort-du-Comtat': 4812,
    'Malijai': 4812,
    'Mallemoisson': 4812,
    'Mallemort': 4812,
    'Malpassé': 4812,
    'Mandelieu-la-Napoule': 4812,
    'Mane': 4812,
    'Manosque': 4812,
    'Marignane': 4812,
    'Marseille': 4812,
    'Marseille 01': 4812,
    'Marseille 02': 4812,
    'Marseille 03': 4812,
    'Marseille 04': 4812,
    'Marseille 05': 4812,
    'Marseille 06': 4812,
    'Marseille 07': 4812,
    'Marseille 08': 4812,
    'Marseille 09': 4812,
    'Marseille 10': 4812,
    'Marseille 11': 4812,
    'Marseille 12': 4812,
    'Marseille 13': 4812,
    'Marseille 14': 4812,
    'Marseille 15': 4812,
    'Marseille 16': 4812,
    'Marseille Bompard': 4812,
    'Marseille Endoume': 4812,
    'Marseille Prefecture': 4812,
    'Marseille Roucas-Blanc': 4812,
    'Marseille Saint-Victor': 4812,
    'Marseille Vauban': 4812,
    'Martigues': 4812,
    'Maubec': 4812,
    'Maussane-les-Alpilles': 4812,
    'Mazan': 4812,
    'Mazargues': 4812,
    'Ménerbes': 4812,
    'Menpenti': 4812,
    'Menton': 4812,
    'Méounes-lès-Montrieux': 4812,
    'Mérindol': 4812,
    'Meyrargues': 4812,
    'Meyreuil': 4812,
    'Mimet': 4812,
    'Miramas': 4812,
    'Mollégès': 4812,
    'Mondragon': 4812,
    'Montauroux': 4812,
    'Monteux': 4812,
    'Montfavet': 4812,
    'Montferrat': 4812,
    'Montolivet': 4812,
    'Montredon': 4812,
    'Morières-lès-Avignon': 4812,
    'Mormoiron': 4812,
    'Mornas': 4812,
    'Mouans-Sartoux': 4812,
    'Mougins': 4812,
    'Mouret': 4812,
    'Mouriès': 4812,
    'Nans-les-Pins': 4812,
    'Néoules': 4812,
    'Nice': 4812,
    'Noailles': 4812,
    'Notre-Dame du Mont': 4812,
    'Notre-Dame Limite': 4812,
    'Noves': 4812,
    'Ollioules': 4812,
    'Opéra': 4812,
    'Opio': 4812,
    'Oppède le Vieux': 4812,
    'Oraison': 4812,
    'Orange': 4812,
    'Orgon': 4812,
    'Palais de Justice': 4812,
    'Palama': 4812,
    'Paradou': 4812,
    'Pégomas': 4812,
    'Peille': 4812,
    'Peillon': 4812,
    'Peipin': 4812,
    'Pélissanne': 4812,
    'Périer': 4812,
    'Pernes-les-Fontaines': 4812,
    'Pertuis': 4812,
    'Peymeinade': 4812,
    'Peynier': 4812,
    'Peypin': 4812,
    'Peyrolles-en-Provence': 4812,
    'Peyruis': 4812,
    'Pierrefeu-du-Var': 4812,
    'Pierrevert': 4812,
    'Pignans': 4812,
    'Piolenc': 4812,
    'Plan-d  Aups-Sainte-Baume': 4812,
    'Plan-de-Cuques': 4812,
    'Pont de Vivaux': 4812,
    'Port-de-Bouc': 4812,
    'Port-Saint-Louis-du-Rhône': 4812,
    'Pourrières': 4812,
    'Puget-sur-Argens': 4812,
    'Puget-Théniers': 4812,
    'Puget-Ville': 4812,
    'Puyloubier': 4812,
    'Ramatuelle': 4812,
    'Régusse': 4812,
    'Reillanne': 4812,
    'Rians': 4812,
    'Riez': 4812,
    'Robion': 4812,
    'Rocbaron': 4812,
    'Rognac': 4812,
    'Rognes': 4812,
    'Rognonas': 4812,
    'Roquebillière': 4812,
    'Roquebrune-Cap-Martin': 4812,
    'Roquebrune-sur-Argens': 4812,
    'Roquefort-la-Bédoule': 4812,
    'Roquevaire': 4812,
    'Rouet': 4812,
    'Rougiers': 4812,
    'Rousset': 4812,
    'Roussillon': 4812,
    'Sablet': 4812,
    'Saignon': 4812,
    'Saint-Andiol': 4812,
    'Saint-André': 4812,
    'Saint-André-de-la-Roche': 4812,
    'Saint-Antoine': 4812,
    'Saint-Barnabé': 4812,
    'Saint-Barthélémy': 4812,
    'Saint-Bonnet-en-Champsaur': 4812,
    'Saint-Cannat': 4812,
    'Saint-Cézaire-sur-Siagne': 4812,
    'Saint-Chaffrey': 4812,
    'Saint-Chamas': 4812,
    'Saint-Charles': 4812,
    'Saint-Cyr-sur-Mer': 4812,
    'Saint-Didier': 4812,
    'Saint-Étienne-de-Tinée': 4812,
    'Saint-Étienne-du-Grès': 4812,
    'Saint-Giniez': 4812,
    'Saint-Henri': 4812,
    'Saint-Jean du Désert': 4812,
    'Saint-Jean-Cap-Ferrat': 4812,
    'Saint-Jeannet': 4812,
    'Saint-Jérôme': 4812,
    'Saint-Joseph': 4812,
    'Saint-Julien': 4812,
    'Saint-Just': 4812,
    'Saint-Lambert': 4812,
    'Saint-Laurent-du-Var': 4812,
    'Saint-Lazare': 4812,
    'Saint-Louis': 4812,
    'Saint-Loup': 4812,
    'Saint-Mandrier-sur-Mer': 4812,
    'Saint-Marc-Jaumegarde': 4812,
    'Saint-Marcel': 4812,
    'Saint-Martin-de-Crau': 4812,
    'Saint-Martin-de-Queyrières': 4812,
    'Saint-Martin-du-Var': 4812,
    'Saint-Martin-Vésubie': 4812,
    'Saint-Mauront': 4812,
    'Saint-Maximin-la-Sainte-Baume': 4812,
    'Saint-Menet': 4812,
    'Saint-Michel-l’Observatoire': 4812,
    'Saint-Mitre': 4812,
    'Saint-Mitre-les-Remparts': 4812,
    'Saint-Paul-de-Vence': 4812,
    'Saint-Paul-en-Forêt': 4812,
    'Saint-Pierre': 4812,
    'Saint-Raphaël': 4812,
    'Saint-Rémy-de-Provence': 4812,
    'Saint-Saturnin-lès-Apt': 4812,
    'Saint-Saturnin-lès-Avignon': 4812,
    'Saint-Savournin': 4812,
    'Saint-Tronc': 4812,
    'Saint-Tropez': 4812,
    'Saint-Vallier-de-Thiey': 4812,
    'Saint-Victoret': 4812,
    'Saint-Zacharie': 4812,
    'Sainte-Agnès': 4812,
    'Sainte-Anastasie-sur-Issole': 4812,
    'Sainte-Anne': 4812,
    'Sainte-Cécile-les-Vignes': 4812,
    'Sainte-Marguerite': 4812,
    'Sainte-Marthe': 4812,
    'Sainte-Maxime': 4812,
    'Sainte-Tulle': 4812,
    'Saintes-Maries-de-la-Mer': 4812,
    'Salernes': 4812,
    'Salon-de-Provence': 4812,
    'Sanary-sur-Mer': 4812,
    'Sarrians': 4812,
    'Sault': 4812,
    'Sausset-les-Pins': 4812,
    'Seillans': 4812,
    'Sénas': 4812,
    'Septèmes-les-Vallons': 4812,
    'Sérignan-du-Comtat': 4812,
    'Serres': 4812,
    'Seyne-les-Alpes': 4812,
    'Signes': 4812,
    'Simiane-Collongue': 4812,
    'Sisteron': 4812,
    'Six-Fours-les-Plages': 4812,
    'Solliès-Pont': 4812,
    'Solliès-Toucas': 4812,
    'Solliès-Ville': 4812,
    'Sorgues': 4812,
    'Sormiou': 4812,
    'Sospel': 4812,
    'Spéracèdes': 4812,
    'Taillades': 4812,
    'Tallard': 4812,
    'Tanneron': 4812,
    'Taradeau': 4812,
    'Tarascon': 4812,
    'Tende': 4812,
    'Théoule-sur-Mer': 4812,
    'Thiers': 4812,
    'Toulon': 4812,
    'Tourrette-Levens': 4812,
    'Tourrettes-sur-Loup': 4812,
    'Tourves': 4812,
    'Trans-en-Provence': 4812,
    'Trets': 4812,
    'Uchaux': 4812,
    'Vacqueyras': 4812,
    'Vaison-la-Romaine': 4812,
    'Valbonne': 4812,
    'Valensole': 4812,
    'Vallauris': 4812,
    'Valréas': 4812,
    'Var': 4812,
    'Vedène': 4812,
    'Velaux': 4812,
    'Velleron': 4812,
    'Venasque': 4812,
    'Vence': 4812,
    'Venelles': 4812,
    'Ventabren': 4812,
    'Verduron': 4812,
    'Vernègues': 4812,
    'Veynes': 4812,
    'Vidauban': 4812,
    'Vieille Chapelle': 4812,
    'Villar-Saint-Pancrace': 4812,
    'Villecroze': 4812,
    'Villefranche-sur-Mer': 4812,
    'Villelaure': 4812,
    'Villeneuve': 4812,
    'Villeneuve-Loubet': 4812,
    'Villes-sur-Auzon': 4812,
    'Vinon-sur-Verdon': 4812,
    'Violès': 4812,
    'Visan': 4812,
    'Vitrolles': 4812,
    'Volonne': 4812,
    'Volx': 4812,
    'Cocobeach': 2727,
    'Libreville': 2727,
    'Ntoum': 2727,
    'Franceville': 2726,
    'Lékoni': 2726,
    'Moanda': 2726,
    'Mounana': 2726,
    'Okondja': 2726,
    'Lambaréné': 2730,
    'Ndjolé': 2730,
    'Fougamou': 2731,
    'Mbigou': 2731,
    'Mimongo': 2731,
    'Mouila': 2731,
    'Ndendé': 2731,
    'Mayumba': 2725,
    'Tchibanga': 2725,
    'Booué': 2724,
    'Makokou': 2724,
    'Zadie': 2724,
    'Koulamoutou': 2729,
    'Lastoursville': 2729,
    'Gamba': 2728,
    'Omboué': 2728,
    'Port-Gentil': 2728,
    'Bitam': 2723,
    'Mitzic': 2723,
    'Oyem': 2723,
    'Bakau': 2666,
    'Banjul': 2666,
    'Kombo Saint Mary District': 2666,
    'Serekunda': 2666,
    'Bansang': 2669,
    'Brikama Nding': 2669,
    'Dankunku': 2669,
    'Denton': 2669,
    'Fulladu West': 2669,
    'Galleh Manda': 2669,
    'Georgetown': 2669,
    'Jakhaly': 2669,
    'Janjanbureh': 2669,
    'Jarreng': 2669,
    'Karantaba': 2669,
    'Kass Wollof': 2669,
    'Kuntaur': 2669,
    'Kunting': 2669,
    'Lower Saloum': 2669,
    'Niamina East District': 2669,
    'Niamina West District': 2669,
    'Niani': 2669,
    'Nianija District': 2669,
    'Pateh Sam': 2669,
    'Sami': 2669,
    'Sami District': 2669,
    'Saruja': 2669,
    'Sukuta': 2669,
    'Upper Saloum': 2669,
    'Wassu': 2669,
    'Baro Kunda': 2670,
    'Bureng': 2670,
    'Jali': 2670,
    'Jarra Central': 2670,
    'Jarra East': 2670,
    'Jarra West': 2670,
    'Jenoi': 2670,
    'Jifarong': 2670,
    'Kaiaf': 2670,
    'Karantaba': 2670,
    'Keneba': 2670,
    'Kiang Central': 2670,
    'Kiang East': 2670,
    'Kiang West District': 2670,
    'Mansa Konko': 2670,
    'Nioro': 2670,
    'Sankwia': 2670,
    'Si Kunda': 2670,
    'Soma': 2670,
    'Sutukung': 2670,
    'Toniataba': 2670,
    'Wellingara Ba': 2670,
    'Bambali': 2671,
    'Barra': 2671,
    'Central Baddibu': 2671,
    'Chilla': 2671,
    'Daru Rilwan': 2671,
    'Essau': 2671,
    'Farafenni': 2671,
    'Gunjur': 2671,
    'Jokadu': 2671,
    'Katchang': 2671,
    'Kerewan': 2671,
    'Lamin': 2671,
    'Lower Baddibu District': 2671,
    'Lower Niumi District': 2671,
    'No Kunda': 2671,
    'Saba': 2671,
    'Sara Kunda': 2671,
    'Upper Baddibu': 2671,
    'Upper Niumi District': 2671,
    'Bakadagy': 2668,
    'Basse Santa Su': 2668,
    'Brifu': 2668,
    'Daba Kunda': 2668,
    'Demba Kunda': 2668,
    'Diabugu': 2668,
    'Diabugu Basilla': 2668,
    'Fulladu East': 2668,
    'Gunjur Kuta': 2668,
    'Kantora': 2668,
    'Koina': 2668,
    'Kumbija': 2668,
    'Nyamanari': 2668,
    'Perai': 2668,
    'Sabi': 2668,
    'Sandu': 2668,
    'Sudowol': 2668,
    'Sun Kunda': 2668,
    'Sutukoba': 2668,
    'Wuli': 2668,
    'Abuko': 2667,
    'Brikama': 2667,
    'Foni Bondali': 2667,
    'Foni Brefet': 2667,
    'Foni Jarrol': 2667,
    'Foni Kansala': 2667,
    'Gunjur': 2667,
    'Kombo Central District': 2667,
    'Kombo East District': 2667,
    'Kombo North District': 2667,
    'Kombo South District': 2667,
    'Somita': 2667,
    'Sukuta': 2667,
    'Akhaldaba': 900,
    'Batumi': 900,
    'Chakvi': 900,
    'Dioknisi': 900,
    'Khelvachauri': 900,
    'Khulo': 900,
    'Kobuleti': 900,
    'Makhinjauri': 900,
    'Ochkhamuri': 900,
    'Shuakhevi': 900,
    'Tsikhisdziri': 900,
    'Bich’vinta': 901,
    'Dranda': 901,
    'Gagra': 901,
    'Gali': 901,
    'Gantiadi': 901,
    'Gudauta': 901,
    'Kelasuri': 901,
    'Och’amch’ire': 901,
    'P’rimorsk’oe': 901,
    'Sokhumi': 901,
    'Stantsiya Novyy Afon': 901,
    'Tqvarch  eli': 901,
    'Lanchkhuti': 907,
    'Naruja': 907,
    'Ozurgeti': 907,
    'Urek’i': 907,
    'Baghdatis Munitsip’alit’et’i': 905,
    'Chiat’ura': 905,
    'K’alak’i Chiat’ura': 905,
    'K’ulashi': 905,
    'Kharagauli': 905,
    'Khoni': 905,
    'Kutaisi': 905,
    'Sach’khere': 905,
    'Samtredia': 905,
    'Shorapani': 905,
    'Tqibuli': 905,
    'Tsqaltubo': 905,
    'Vani': 905,
    'Zestap’oni': 905,
    'Akhmet’a': 910,
    'Akhmet’is Munitsip’alit’et’i': 910,
    'Gurjaani': 910,
    'Lagodekhi': 910,
    'Qvareli': 910,
    'Sagarejo': 910,
    'Sighnaghi': 910,
    'Sighnaghis Munitsip’alit’et’i': 910,
    'Telavi': 910,
    'Tsinandali': 910,
    'Tsnori': 910,
    'Bolnisi': 904,
    'Bolnisis Munitsip’alit’et’i': 904,
    'Didi Lilo': 904,
    'Dmanisis Munitsip’alit’et’i': 904,
    'Gardabani': 904,
    'Gardabnis Munitsip’alit’et’i': 904,
    'Manglisi': 904,
    'Marneuli': 904,
    'Marneulis Munitsip’alit’et’i': 904,
    'Naghvarevi': 904,
    'Rust’avi': 904,
    'T’et’ri Tsqaro': 904,
    'Tetrits’q’alos Munitsip’alit’et’i': 904,
    'Ts’alk’is Munitsip’alit’et’i': 904,
    'Tsalka': 904,
    'Akhalgori': 902,
    'Dzegvi': 902,
    'Gudauri': 902,
    'Java': 902,
    'Mtskheta': 902,
    'P’asanauri': 902,
    'Step’antsminda': 902,
    'Zhinvali': 902,
    'Ambrolauri': 909,
    'Ambrolauris Munitsip’alit’et’i': 909,
    'Lent’ekhi': 909,
    'Oni': 909,
    'Abasha': 908,
    'Jvari': 908,
    'Khobi': 908,
    'Kveda Chkhorots’q’u': 908,
    'Mart’vili': 908,
    'Mest’ia': 908,
    'Mest’iis Munitsip’alit’et’i': 908,
    'Orsant’ia': 908,
    'P’ot’i': 908,
    'Senak’i': 908,
    'Tsalenjikha': 908,
    'Zugdidi': 908,
    'Adigeni': 906,
    'Adigeni Municipality': 906,
    'Akhaldaba': 906,
    'Akhalk’alak’i': 906,
    'Akhaltsikhe': 906,
    'Akhaltsikhis Munitsip’alit’et’i': 906,
    'Asp’indzis Munitsip’alit’et’i': 906,
    'Aspindza': 906,
    'Bakuriani': 906,
    'Borjomi': 906,
    'Ninotsminda': 906,
    'Tsaghveri': 906,
    'Vale': 906,
    'Agara': 903,
    'Gori': 903,
    'Goris Munitsip’alit’et’i': 903,
    'Kaspi': 903,
    'Khashuri': 903,
    'Surami': 903,
    'Ts’khinvali': 903,
    'Tbilisi': 899,
    'Aach': 3006,
    'Aalen': 3006,
    'Abstatt': 3006,
    'Abtsgmünd': 3006,
    'Achern': 3006,
    'Achstetten': 3006,
    'Adelberg': 3006,
    'Adelmannsfelden': 3006,
    'Adelsheim': 3006,
    'Affalterbach': 3006,
    'Aglasterhausen': 3006,
    'Aichelberg': 3006,
    'Aichhalden': 3006,
    'Aichstetten': 3006,
    'Aidlingen': 3006,
    'Aitrach': 3006,
    'Albbruck': 3006,
    'Albershausen': 3006,
    'Albstadt': 3006,
    'Aldingen': 3006,
    'Alfdorf': 3006,
    'Allensbach': 3006,
    'Allmendingen': 3006,
    'Allmersbach im Tal': 3006,
    'Alpirsbach': 3006,
    'Altbach': 3006,
    'Altdorf': 3006,
    'Altenriet': 3006,
    'Altensteig': 3006,
    'Altes Lager': 3006,
    'Althengstett': 3006,
    'Althütte': 3006,
    'Altlußheim': 3006,
    'Altshausen': 3006,
    'Amstetten': 3006,
    'Amtzell': 3006,
    'Aspach': 3006,
    'Asperg': 3006,
    'Assamstadt': 3006,
    'Asselfingen': 3006,
    'Attenweiler': 3006,
    'Au': 3006,
    'Au am Rhein': 3006,
    'Auggen': 3006,
    'Aulendorf': 3006,
    'Backnang': 3006,
    'Bad Bellingen': 3006,
    'Bad Buchau': 3006,
    'Bad Ditzenbach': 3006,
    'Bad Dürrheim': 3006,
    'Bad Herrenalb': 3006,
    'Bad Liebenzell': 3006,
    'Bad Mergentheim': 3006,
    'Bad Peterstal-Griesbach': 3006,
    'Bad Rappenau': 3006,
    'Bad Rippoldsau-Schapbach': 3006,
    'Bad Säckingen': 3006,
    'Bad Schussenried': 3006,
    'Bad Teinach-Zavelstein': 3006,
    'Bad Überkingen': 3006,
    'Bad Urach': 3006,
    'Bad Waldsee': 3006,
    'Bad Wildbad': 3006,
    'Bad Wimpfen': 3006,
    'Bad Wurzach': 3006,
    'Baden-Baden': 3006,
    'Badenweiler': 3006,
    'Bahlingen': 3006,
    'Baienfurt': 3006,
    'Baiersbronn': 3006,
    'Baindt': 3006,
    'Balingen': 3006,
    'Baltmannsweiler': 3006,
    'Balzfeld': 3006,
    'Bammental': 3006,
    'Bartholomä': 3006,
    'Beilstein': 3006,
    'Beimerstetten': 3006,
    'Bempflingen': 3006,
    'Benningen am Neckar': 3006,
    'Bergatreute': 3006,
    'Berghaupten': 3006,
    'Berghülen': 3006,
    'Berkheim': 3006,
    'Bermatingen': 3006,
    'Bernau': 3006,
    'Bernstadt': 3006,
    'Besigheim': 3006,
    'Beuren': 3006,
    'Biberach': 3006,
    'Biberach an der Riß': 3006,
    'Bietigheim': 3006,
    'Bietigheim-Bissingen': 3006,
    'Billigheim': 3006,
    'Binau': 3006,
    'Bingen': 3006,
    'Binzen': 3006,
    'Birenbach': 3006,
    'Birkenfeld': 3006,
    'Bischweier': 3006,
    'Bisingen': 3006,
    'Bissingen an der Teck': 3006,
    'Bitz': 3006,
    'Blaubeuren': 3006,
    'Blaufelden': 3006,
    'Blumberg': 3006,
    'Böbingen an der Rems': 3006,
    'Böblingen': 3006,
    'Bodelshausen': 3006,
    'Bodman-Ludwigshafen': 3006,
    'Bodnegg': 3006,
    'Böhmenkirch': 3006,
    'Bolheim': 3006,
    'Boll': 3006,
    'Bollschweil': 3006,
    'Bondorf': 3006,
    'Bonndorf': 3006,
    'Bönnigheim': 3006,
    'Bopfingen': 3006,
    'Börtlingen': 3006,
    'Bösingen': 3006,
    'Böttingen': 3006,
    'Bötzingen': 3006,
    'Boxberg': 3006,
    'Brackenheim': 3006,
    'Bräunlingen': 3006,
    'Braunsbach': 3006,
    'Breisach am Rhein': 3006,
    'Breitnau': 3006,
    'Bretten': 3006,
    'Bretzfeld': 3006,
    'Bruchsal': 3006,
    'Brühl': 3006,
    'Bubsheim': 3006,
    'Buch am Ahorn': 3006,
    'Buchen in Odenwald': 3006,
    'Buchenbach': 3006,
    'Buggingen': 3006,
    'Bühl': 3006,
    'Bühlertal': 3006,
    'Bühlertann': 3006,
    'Bühlerzell': 3006,
    'Burgrieden': 3006,
    'Burgstetten': 3006,
    'Burladingen': 3006,
    'Büsingen': 3006,
    'Calw': 3006,
    'Cleebronn': 3006,
    'Crailsheim': 3006,
    'Creglingen': 3006,
    'Daisendorf': 3006,
    'Dauchingen': 3006,
    'Deckenpfronn': 3006,
    'Deggingen': 3006,
    'Deilingen': 3006,
    'Deißlingen': 3006,
    'Deizisau': 3006,
    'Denkendorf': 3006,
    'Denkingen': 3006,
    'Denzlingen': 3006,
    'Dettenhausen': 3006,
    'Dettighofen': 3006,
    'Dettingen': 3006,
    'Dettingen an der Erms': 3006,
    'Dettingen unter Teck': 3006,
    'Dielheim': 3006,
    'Dietenheim': 3006,
    'Dietingen': 3006,
    'Dischingen': 3006,
    'Ditzingen': 3006,
    'Dobel': 3006,
    'Dogern': 3006,
    'Donaueschingen': 3006,
    'Donzdorf': 3006,
    'Dormettingen': 3006,
    'Dornhan': 3006,
    'Dornstadt': 3006,
    'Dornstetten': 3006,
    'Dörzbach': 3006,
    'Dossenheim': 3006,
    'Dotternhausen': 3006,
    'Dunningen': 3006,
    'Durbach': 3006,
    'Dürbheim': 3006,
    'Durlangen': 3006,
    'Dürmentingen': 3006,
    'Durmersheim': 3006,
    'Dürnau': 3006,
    'Dußlingen': 3006,
    'Ebenweiler': 3006,
    'Eberbach': 3006,
    'Eberdingen': 3006,
    'Eberhardzell': 3006,
    'Ebersbach an der Fils': 3006,
    'Ebersbach-Musbach': 3006,
    'Eberstadt': 3006,
    'Ebhausen': 3006,
    'Ebringen': 3006,
    'Edingen-Neckarhausen': 3006,
    'Efringen-Kirchen': 3006,
    'Egenhausen': 3006,
    'Eggenstein-Leopoldshafen': 3006,
    'Eggingen': 3006,
    'Ehingen': 3006,
    'Ehningen': 3006,
    'Eichstetten': 3006,
    'Eigeltingen': 3006,
    'Eimeldingen': 3006,
    'Eisenbach': 3006,
    'Eisingen': 3006,
    'Eislingen': 3006,
    'Ellenberg': 3006,
    'Ellhofen': 3006,
    'Ellwangen': 3006,
    'Elzach': 3006,
    'Emmendingen': 3006,
    'Emmingen-Liptingen': 3006,
    'Empfingen': 3006,
    'Endingen': 3006,
    'Engelsbrand': 3006,
    'Engen': 3006,
    'Eningen unter Achalm': 3006,
    'Ennetach': 3006,
    'Enzklösterle': 3006,
    'Epfenbach': 3006,
    'Epfendorf': 3006,
    'Eppelheim': 3006,
    'Eppingen': 3006,
    'Erbach': 3006,
    'Erdmannhausen': 3006,
    'Eriskirch': 3006,
    'Erkenbrechtsweiler': 3006,
    'Erlenbach': 3006,
    'Erlenmoos': 3006,
    'Erligheim': 3006,
    'Erolzheim': 3006,
    'Ersingen': 3006,
    'Ertingen': 3006,
    'Eschach': 3006,
    'Eschelbronn': 3006,
    'Eschenbach': 3006,
    'Essingen': 3006,
    'Esslingen': 3006,
    'Ettenheim': 3006,
    'Ettlingen': 3006,
    'Eutingen an der Enz': 3006,
    'Fahrenbach': 3006,
    'Feldberg': 3006,
    'Fellbach': 3006,
    'Fichtenberg': 3006,
    'Filderstadt': 3006,
    'Fischerbach': 3006,
    'Flein': 3006,
    'Forbach': 3006,
    'Forchheim': 3006,
    'Forchtenberg': 3006,
    'Forst': 3006,
    'Freiberg am Neckar': 3006,
    'Freiburg': 3006,
    'Freiburg Region': 3006,
    'Freudenberg': 3006,
    'Freudenstadt': 3006,
    'Freudental': 3006,
    'Frickenhausen': 3006,
    'Frickingen': 3006,
    'Fridingen an der Donau': 3006,
    'Friedenweiler': 3006,
    'Friedrichshafen': 3006,
    'Friolzheim': 3006,
    'Frittlingen': 3006,
    'Fronreute': 3006,
    'Fürstenberg': 3006,
    'Furtwangen': 3006,
    'Gaggenau': 3006,
    'Gaiberg': 3006,
    'Gaienhofen': 3006,
    'Gaildorf': 3006,
    'Gailingen': 3006,
    'Gammelshausen': 3006,
    'Gammertingen': 3006,
    'Gärtringen': 3006,
    'Gechingen': 3006,
    'Geisingen': 3006,
    'Geislingen': 3006,
    'Geislingen an der Steige': 3006,
    'Gemmingen': 3006,
    'Gemmrigheim': 3006,
    'Gengenbach': 3006,
    'Gerabronn': 3006,
    'Gerlingen': 3006,
    'Gernsbach': 3006,
    'Gerstetten': 3006,
    'Giengen an der Brenz': 3006,
    'Gingen an der Fils': 3006,
    'Glatten': 3006,
    'Göggingen': 3006,
    'Gomadingen': 3006,
    'Gomaringen': 3006,
    'Gondelsheim': 3006,
    'Göppingen': 3006,
    'Görwihl': 3006,
    'Gosheim': 3006,
    'Gottenheim': 3006,
    'Gottmadingen': 3006,
    'Graben-Neudorf': 3006,
    'Grabenstetten': 3006,
    'Grafenau': 3006,
    'Grafenhausen': 3006,
    'Greffern': 3006,
    'Grenzach-Wyhlen': 3006,
    'Großbettlingen': 3006,
    'Großbottwar': 3006,
    'Grosselfingen': 3006,
    'Großerlach': 3006,
    'Großrinderfeld': 3006,
    'Gruibingen': 3006,
    'Grünkraut': 3006,
    'Grünsfeld': 3006,
    'Gschwend': 3006,
    'Güglingen': 3006,
    'Gundelfingen': 3006,
    'Gundelsheim': 3006,
    'Gutach': 3006,
    'Gutach im Breisgau': 3006,
    'Gütenbach': 3006,
    'Gutenzell-Hürbel': 3006,
    'Hagnau': 3006,
    'Haigerloch': 3006,
    'Haiterbach': 3006,
    'Hambrücken': 3006,
    'Hardheim': 3006,
    'Hardt': 3006,
    'Hartheim': 3006,
    'Hasel': 3006,
    'Haslach': 3006,
    'Haßmersheim': 3006,
    'Hattenhofen': 3006,
    'Hausach': 3006,
    'Hausen': 3006,
    'Häusern': 3006,
    'Hayingen': 3006,
    'Hechingen': 3006,
    'Heddesheim': 3006,
    'Heidelberg': 3006,
    'Heidenheim an der Brenz': 3006,
    'Heilbronn': 3006,
    'Heiligenberg': 3006,
    'Heiligkreuzsteinach': 3006,
    'Heimsheim': 3006,
    'Heiningen': 3006,
    'Heitersheim': 3006,
    'Helmstadt-Bargen': 3006,
    'Hemmingen': 3006,
    'Hemsbach': 3006,
    'Herbertingen': 3006,
    'Herbolzheim': 3006,
    'Herbrechtingen': 3006,
    'Herdwangen-Schönach': 3006,
    'Hermaringen': 3006,
    'Herrenberg': 3006,
    'Herrischried': 3006,
    'Hessigheim': 3006,
    'Hettingen': 3006,
    'Heubach': 3006,
    'Heuchlingen': 3006,
    'Hildrizhausen': 3006,
    'Hilzingen': 3006,
    'Hinterzarten': 3006,
    'Hirrlingen': 3006,
    'Hirschberg an der Bergstraße': 3006,
    'Hochdorf': 3006,
    'Höchenschwand': 3006,
    'Hockenheim': 3006,
    'Höfen an der Enz': 3006,
    'Hofstetten': 3006,
    'Hohberg': 3006,
    'Hohentengen': 3006,
    'Holzgerlingen': 3006,
    'Holzmaden': 3006,
    'Höpfingen': 3006,
    'Horb am Neckar': 3006,
    'Horben': 3006,
    'Horgenzell': 3006,
    'Hornberg': 3006,
    'Horrenberg': 3006,
    'Hüffenhardt': 3006,
    'Hüfingen': 3006,
    'Hügelsheim': 3006,
    'Hülben': 3006,
    'Hüttisheim': 3006,
    'Hüttlingen': 3006,
    'Iffezheim': 3006,
    'Igersheim': 3006,
    'Iggingen': 3006,
    'Ihringen': 3006,
    'Illerrieden': 3006,
    'Illingen': 3006,
    'Illmensee': 3006,
    'Ilsfeld': 3006,
    'Ilshofen': 3006,
    'Ilvesheim': 3006,
    'Immendingen': 3006,
    'Immenstaad am Bodensee': 3006,
    'Ingelfingen': 3006,
    'Ingoldingen': 3006,
    'Inzigkofen': 3006,
    'Inzlingen': 3006,
    'Isny': 3006,
    'Ispringen': 3006,
    'Ittlingen': 3006,
    'Jagsthausen': 3006,
    'Jagstzell': 3006,
    'Jestetten': 3006,
    'Jöhlingen': 3006,
    'Jungingen': 3006,
    'Kaisersbach': 3006,
    'Kandern': 3006,
    'Kappel-Grafenhausen': 3006,
    'Kappelrodeck': 3006,
    'Karlsdorf-Neuthard': 3006,
    'Karlsruhe': 3006,
    'Karlsruhe Region': 3006,
    'Kehl': 3006,
    'Kenzingen': 3006,
    'Ketsch': 3006,
    'Kieselbronn': 3006,
    'Kippenheim': 3006,
    'Kirchardt': 3006,
    'Kirchberg an der Iller': 3006,
    'Kirchberg an der Jagst': 3006,
    'Kirchberg an der Murr': 3006,
    'Kirchdorf': 3006,
    'Kirchentellinsfurt': 3006,
    'Kirchheim am Neckar': 3006,
    'Kirchheim am Ries': 3006,
    'Kirchheim unter Teck': 3006,
    'Kirchzarten': 3006,
    'Kißlegg': 3006,
    'Klingenstein': 3006,
    'Knittlingen': 3006,
    'Kohlberg': 3006,
    'Kolbingen': 3006,
    'Köngen': 3006,
    'Königheim': 3006,
    'Königsbach-Stein': 3006,
    'Königsbronn': 3006,
    'Königsfeld im Schwarzwald': 3006,
    'Konstanz': 3006,
    'Korb': 3006,
    'Korntal': 3006,
    'Kornwestheim': 3006,
    'Kraichtal': 3006,
    'Krauchenwies': 3006,
    'Krautheim': 3006,
    'Kressbronn am Bodensee': 3006,
    'Kronau': 3006,
    'Kuchen': 3006,
    'Külsheim': 3006,
    'Künzelsau': 3006,
    'Kupferzell': 3006,
    'Kuppenheim': 3006,
    'Kürnbach': 3006,
    'Kusterdingen': 3006,
    'Ladenburg': 3006,
    'Lahr': 3006,
    'Laichingen': 3006,
    'Langenargen': 3006,
    'Langenau': 3006,
    'Langenbrettach': 3006,
    'Langenburg': 3006,
    'Langenenslingen': 3006,
    'Lauchheim': 3006,
    'Lauchringen': 3006,
    'Lauda-Königshofen': 3006,
    'Laudenbach': 3006,
    'Lauf': 3006,
    'Laufenburg': 3006,
    'Lauffen am Neckar': 3006,
    'Laupheim': 3006,
    'Lautenbach': 3006,
    'Lauterbach/Schwarzwald': 3006,
    'Lehrensteinsfeld': 3006,
    'Leibertingen': 3006,
    'Leimen': 3006,
    'Leinfelden-Echterdingen': 3006,
    'Leingarten': 3006,
    'Leinzell': 3006,
    'Lenningen': 3006,
    'Lenzkirch': 3006,
    'Leonberg': 3006,
    'Leutenbach': 3006,
    'Leutkirch': 3006,
    'Lichtenau': 3006,
    'Linkenheim-Hochstetten': 3006,
    'Lobbach': 3006,
    'Löchgau': 3006,
    'Loffenau': 3006,
    'Löffingen': 3006,
    'Lonsee': 3006,
    'Lorch': 3006,
    'Lörrach': 3006,
    'Loßburg': 3006,
    'Lottstetten': 3006,
    'Löwenstein': 3006,
    'Ludwigsburg': 3006,
    'Magstadt': 3006,
    'Mahlberg': 3006,
    'Mainhardt': 3006,
    'Malsch': 3006,
    'Malterdingen': 3006,
    'Mannheim': 3006,
    'Marbach am Neckar': 3006,
    'Markdorf': 3006,
    'Markgröningen': 3006,
    'Maselheim': 3006,
    'Massenbachhausen': 3006,
    'Mauer': 3006,
    'Maulbronn': 3006,
    'Maulburg': 3006,
    'Meckenbeuren': 3006,
    'Meckesheim': 3006,
    'Meersburg': 3006,
    'Mehrstetten': 3006,
    'Meißenheim': 3006,
    'Mengen': 3006,
    'Merdingen': 3006,
    'Merklingen': 3006,
    'Merzhausen': 3006,
    'Meßkirch': 3006,
    'Meßstetten': 3006,
    'Metzingen': 3006,
    'Michelbach an der Bilz': 3006,
    'Michelfeld': 3006,
    'Mietingen': 3006,
    'Mittelbiberach': 3006,
    'Mittelschöntal': 3006,
    'Möckmühl': 3006,
    'Mögglingen': 3006,
    'Möglingen': 3006,
    'Mönchweiler': 3006,
    'Mönsheim': 3006,
    'Moos': 3006,
    'Mosbach': 3006,
    'Mössingen': 3006,
    'Mötzingen': 3006,
    'Mudau': 3006,
    'Muggensturm': 3006,
    'Mühlacker': 3006,
    'Mühlenbach': 3006,
    'Mühlhausen': 3006,
    'Mühlhausen-Ehingen': 3006,
    'Mühlheim am Bach': 3006,
    'Mühlingen': 3006,
    'Mulfingen': 3006,
    'Müllheim': 3006,
    'Mundelsheim': 3006,
    'Munderkingen': 3006,
    'Münsingen': 3006,
    'Münstertal/Schwarzwald': 3006,
    'Murg': 3006,
    'Murr': 3006,
    'Murrhardt': 3006,
    'Mutlangen': 3006,
    'Nagold': 3006,
    'Nattheim': 3006,
    'Neckarbischofsheim': 3006,
    'Neckargemünd': 3006,
    'Neckargerach': 3006,
    'Neckarsulm': 3006,
    'Neckartailfingen': 3006,
    'Neckartenzlingen': 3006,
    'Neckarwestheim': 3006,
    'Neckarzimmern': 3006,
    'Nehren': 3006,
    'Neidenstein': 3006,
    'Neidlingen': 3006,
    'Nellingen': 3006,
    'Neresheim': 3006,
    'Neubulach': 3006,
    'Neudenau': 3006,
    'Neuenbürg': 3006,
    'Neuenburg am Rhein': 3006,
    'Neuenstadt am Kocher': 3006,
    'Neuenstein': 3006,
    'Neuffen': 3006,
    'Neufra': 3006,
    'Neuhausen': 3006,
    'Neuhausen auf den Fildern': 3006,
    'Neukirch': 3006,
    'Neuler': 3006,
    'Neulußheim': 3006,
    'Neunkirchen': 3006,
    'Neuweiler': 3006,
    'Niedereschach': 3006,
    'Niedernhall': 3006,
    'Niederstetten': 3006,
    'Niederstotzingen': 3006,
    'Niederwangen': 3006,
    'Niefern-Öschelbronn': 3006,
    'Nordheim': 3006,
    'Nordrach': 3006,
    'Notzingen': 3006,
    'Nufringen': 3006,
    'Nürtingen': 3006,
    'Nußloch': 3006,
    'Oberboihingen': 3006,
    'Oberderdingen': 3006,
    'Oberdischingen': 3006,
    'Oberharmersbach': 3006,
    'Oberhausen-Rheinhausen': 3006,
    'Oberjettingen': 3006,
    'Oberkirch': 3006,
    'Oberkochen': 3006,
    'Obermarchtal': 3006,
    'Oberndorf': 3006,
    'Obernheim': 3006,
    'Oberreichenbach': 3006,
    'Oberried': 3006,
    'Oberriexingen': 3006,
    'Oberrot': 3006,
    'Oberrotweil': 3006,
    'Obersontheim': 3006,
    'Oberstadion': 3006,
    'Oberstenfeld': 3006,
    'Oberteuringen': 3006,
    'Oberwolfach': 3006,
    'Obrigheim': 3006,
    'Ochsenhausen': 3006,
    'Oedheim': 3006,
    'Offenau': 3006,
    'Offenburg': 3006,
    'Ofterdingen': 3006,
    'Oftersheim': 3006,
    'Ohlsbach': 3006,
    'Ohmden': 3006,
    'Öhningen': 3006,
    'Öhringen': 3006,
    'Ölbronn-Dürrn': 3006,
    'Öpfingen': 3006,
    'Oppenau': 3006,
    'Oppenweiler': 3006,
    'Orsingen-Nenzingen': 3006,
    'Ortenberg': 3006,
    'Ostelsheim': 3006,
    'Osterburken': 3006,
    'Ostfildern': 3006,
    'Ostrach': 3006,
    'Östringen': 3006,
    'Ötigheim': 3006,
    'Ötisheim': 3006,
    'Ottenbach': 3006,
    'Ottenhofen': 3006,
    'Ottersweier': 3006,
    'Owen': 3006,
    'Owingen': 3006,
    'Pfaffenhofen': 3006,
    'Pfaffenweiler': 3006,
    'Pfalzgrafenweiler': 3006,
    'Pfedelbach': 3006,
    'Pforzheim': 3006,
    'Pfronstetten': 3006,
    'Pfullendorf': 3006,
    'Pfullingen': 3006,
    'Philippsburg': 3006,
    'Plankstadt': 3006,
    'Pleidelsheim': 3006,
    'Pliezhausen': 3006,
    'Plochingen': 3006,
    'Plüderhausen': 3006,
    'Radolfzell': 3006,
    'Rammingen': 3006,
    'Rangendingen': 3006,
    'Rastatt': 3006,
    'Rauenberg': 3006,
    'Ravensburg': 3006,
    'Rechberghausen': 3006,
    'Regierungsbezirk Stuttgart': 3006,
    'Reichartshausen': 3006,
    'Reichenau': 3006,
    'Reichenbach an der Fils': 3006,
    'Reilingen': 3006,
    'Renchen': 3006,
    'Renningen': 3006,
    'Reutlingen': 3006,
    'Rheinau': 3006,
    'Rheinfelden': 3006,
    'Rheinstetten': 3006,
    'Rickenbach': 3006,
    'Riederich': 3006,
    'Riedlingen': 3006,
    'Riegel': 3006,
    'Rielasingen-Worblingen': 3006,
    'Rietheim-Weilheim': 3006,
    'Ringsheim': 3006,
    'Rohrdorf': 3006,
    'Roigheim': 3006,
    'Rosenberg': 3006,
    'Rosenfeld': 3006,
    'Rot': 3006,
    'Rot am See': 3006,
    'Rottenacker': 3006,
    'Rottenburg': 3006,
    'Rottweil': 3006,
    'Rudersberg': 3006,
    'Rümmingen': 3006,
    'Ruppertshofen': 3006,
    'Rust': 3006,
    'Rutesheim': 3006,
    'Sachsenheim': 3006,
    'Salach': 3006,
    'Salem': 3006,
    'Sandhausen': 3006,
    'Sankt Blasien': 3006,
    'Sankt Georgen im Schwarzwald': 3006,
    'Sankt Johann': 3006,
    'Sankt Leon-Rot': 3006,
    'Sankt Märgen': 3006,
    'Sankt Peter': 3006,
    'Sasbach': 3006,
    'Sasbachwalden': 3006,
    'Satteldorf': 3006,
    'Sauldorf': 3006,
    'Saulgau': 3006,
    'Schallstadt': 3006,
    'Schechingen': 3006,
    'Scheer': 3006,
    'Schelklingen': 3006,
    'Schenkenzell': 3006,
    'Schiltach': 3006,
    'Schlaitdorf': 3006,
    'Schlat': 3006,
    'Schliengen': 3006,
    'Schlier': 3006,
    'Schlierbach': 3006,
    'Schluchsee': 3006,
    'Schnürpflingen': 3006,
    'Schömberg': 3006,
    'Schonach im Schwarzwald': 3006,
    'Schönaich': 3006,
    'Schönau': 3006,
    'Schönau im Schwarzwald': 3006,
    'Schönwald': 3006,
    'Schopfheim': 3006,
    'Schopfloch': 3006,
    'Schorndorf': 3006,
    'Schramberg': 3006,
    'Schriesheim': 3006,
    'Schrozberg': 3006,
    'Schuttertal': 3006,
    'Schutterwald': 3006,
    'Schwäbisch Gmünd': 3006,
    'Schwäbisch Hall': 3006,
    'Schwaigern': 3006,
    'Schwaikheim': 3006,
    'Schwanau': 3006,
    'Schwarzach': 3006,
    'Schwendi': 3006,
    'Schwenningen': 3006,
    'Schwetzingen': 3006,
    'Schwieberdingen': 3006,
    'Schwörstadt': 3006,
    'Seckach': 3006,
    'Seebach': 3006,
    'Seedorf': 3006,
    'Seelbach': 3006,
    'Seitingen-Oberflacht': 3006,
    'Sersheim': 3006,
    'Sexau': 3006,
    'Sickenhausen': 3006,
    'Siegelsbach': 3006,
    'Sigmaringen': 3006,
    'Sigmaringendorf': 3006,
    'Simmersfeld': 3006,
    'Simmozheim': 3006,
    'Sindelfingen': 3006,
    'Singen': 3006,
    'Sinsheim': 3006,
    'Sinzheim': 3006,
    'Sipplingen': 3006,
    'Sölden': 3006,
    'Sontheim an der Brenz': 3006,
    'Spaichingen': 3006,
    'Spechbach': 3006,
    'Spiegelberg': 3006,
    'Spraitbach': 3006,
    'Staig': 3006,
    'Staufen': 3006,
    'Stegen': 3006,
    'Steinach': 3006,
    'Steinen': 3006,
    'Steinenbronn': 3006,
    'Steinheim am Albuch': 3006,
    'Steinheim am der Murr': 3006,
    'Steinmauern': 3006,
    'Steißlingen': 3006,
    'Sternenfels': 3006,
    'Stetten am Kalten Markt': 3006,
    'Stimpfach': 3006,
    'Stockach': 3006,
    'Stödtlen': 3006,
    'Straßberg': 3006,
    'Stühlingen': 3006,
    'Stuttgart': 3006,
    'Stuttgart Feuerbach': 3006,
    'Stuttgart Mühlhausen': 3006,
    'Stuttgart-Ost': 3006,
    'Sulz am Neckar': 3006,
    'Sulzbach an der Murr': 3006,
    'Sulzburg': 3006,
    'Sulzfeld': 3006,
    'Süßen': 3006,
    'Täferrot': 3006,
    'Talheim': 3006,
    'Tamm': 3006,
    'Tannhausen': 3006,
    'Tannheim': 3006,
    'Tauberbischofsheim': 3006,
    'Tengen': 3006,
    'Teningen': 3006,
    'Tennenbronn': 3006,
    'Tettnang': 3006,
    'Tiefenbronn': 3006,
    'Titisee-Neustadt': 3006,
    'Todtmoos': 3006,
    'Todtnau': 3006,
    'Triberg': 3006,
    'Trochtelfingen': 3006,
    'Trossingen': 3006,
    'Tübingen': 3006,
    'Tübingen Region': 3006,
    'Tuningen': 3006,
    'Tuttlingen': 3006,
    'Überlingen': 3006,
    'Ubstadt-Weiher': 3006,
    'Uhingen': 3006,
    'Uhldingen-Mühlhofen': 3006,
    'Ühlingen-Birkendorf': 3006,
    'Ulm': 3006,
    'Umkirch': 3006,
    'Ummendorf': 3006,
    'Unlingen': 3006,
    'Untereisesheim': 3006,
    'Unterensingen': 3006,
    'Untergruppenbach': 3006,
    'Unterhausen': 3006,
    'Unterjettingen': 3006,
    'Unterkirnach': 3006,
    'Unterkrozingen': 3006,
    'Untermünkheim': 3006,
    'Unterreichenbach': 3006,
    'Unterschneidheim': 3006,
    'Urbach': 3006,
    'Uttenweiler': 3006,
    'Vaihingen an der Enz': 3006,
    'Vellberg': 3006,
    'Veringenstadt': 3006,
    'Villingen-Schwenningen': 3006,
    'Villingendorf': 3006,
    'Vogt': 3006,
    'Vogtsburg': 3006,
    'Vöhrenbach': 3006,
    'Vöhringen': 3006,
    'Volkertshausen': 3006,
    'Vörstetten': 3006,
    'Waghäusel': 3006,
    'Waiblingen': 3006,
    'Waibstadt': 3006,
    'Wain': 3006,
    'Wald': 3006,
    'Waldburg': 3006,
    'Waldenbuch': 3006,
    'Waldenburg': 3006,
    'Waldkirch': 3006,
    'Waldshut-Tiengen': 3006,
    'Waldstetten': 3006,
    'Walheim': 3006,
    'Walldorf': 3006,
    'Walldürn': 3006,
    'Wallhausen': 3006,
    'Wangen': 3006,
    'Wannweil': 3006,
    'Warthausen': 3006,
    'Wäschenbeuren': 3006,
    'Wehingen': 3006,
    'Wehr': 3006,
    'Weidenstetten': 3006,
    'Weikersheim': 3006,
    'Weil am Rhein': 3006,
    'Weil der Stadt': 3006,
    'Weil im Schönbuch': 3006,
    'Weilheim': 3006,
    'Weilheim an der Teck': 3006,
    'Weingarten': 3006,
    'Weinheim': 3006,
    'Weinsberg': 3006,
    'Weinstadt-Endersbach': 3006,
    'Weisenbach': 3006,
    'Weissach': 3006,
    'Weißbach': 3006,
    'Weisweil': 3006,
    'Wellendingen': 3006,
    'Welzheim': 3006,
    'Wendlingen am Neckar': 3006,
    'Werbach': 3006,
    'Wernau': 3006,
    'Wertheim': 3006,
    'Westerheim': 3006,
    'Westerstetten': 3006,
    'Westhausen': 3006,
    'Widdern': 3006,
    'Wiernsheim': 3006,
    'Wiesenbach': 3006,
    'Wiesensteig': 3006,
    'Wiesloch': 3006,
    'Wildberg': 3006,
    'Wilhelmsdorf': 3006,
    'Wilhelmsfeld': 3006,
    'Willstätt': 3006,
    'Wimsheim': 3006,
    'Winnenden': 3006,
    'Winterbach': 3006,
    'Winterlingen': 3006,
    'Wittnau': 3006,
    'Wolfach': 3006,
    'Wolfegg': 3006,
    'Wolfschlugen': 3006,
    'Wolpertshausen': 3006,
    'Wolpertswende': 3006,
    'Wört': 3006,
    'Wössingen': 3006,
    'Wurmberg': 3006,
    'Wurmlingen': 3006,
    'Wüstenrot': 3006,
    'Wutöschingen': 3006,
    'Wyhl': 3006,
    'Zaberfeld': 3006,
    'Zaisenhausen': 3006,
    'Zell': 3006,
    'Zell im Wiesental': 3006,
    'Zell unter Aichelberg': 3006,
    'Zimmern ob Rottweil': 3006,
    'Zuzenhausen': 3006,
    'Zweiflingen': 3006,
    'Zwiefalten': 3006,
    'Abenberg': 3009,
    'Abensberg': 3009,
    'Absberg': 3009,
    'Achslach': 3009,
    'Adelsdorf': 3009,
    'Adelshofen': 3009,
    'Adelsried': 3009,
    'Adelzhausen': 3009,
    'Adlkofen': 3009,
    'Affing': 3009,
    'Aham': 3009,
    'Aholfing': 3009,
    'Aholming': 3009,
    'Ahorn': 3009,
    'Aichach': 3009,
    'Aichen': 3009,
    'Aidenbach': 3009,
    'Aidhausen': 3009,
    'Aiglsbach': 3009,
    'Aindling': 3009,
    'Ainring': 3009,
    'Aislingen': 3009,
    'Aitrang': 3009,
    'Albaching': 3009,
    'Albertshofen': 3009,
    'Aldersbach': 3009,
    'Alerheim': 3009,
    'Alesheim': 3009,
    'Aletshausen': 3009,
    'Alfeld': 3009,
    'Allersberg': 3009,
    'Allershausen': 3009,
    'Alling': 3009,
    'Altdorf': 3009,
    'Alteglofsheim': 3009,
    'Altenbuch': 3009,
    'Altendorf': 3009,
    'Altenkunstadt': 3009,
    'Altenmarkt': 3009,
    'Altenmünster': 3009,
    'Altenstadt': 3009,
    'Altenthann': 3009,
    'Alterhofen': 3009,
    'Altfraunhofen': 3009,
    'Althegnenberg': 3009,
    'Altomünster': 3009,
    'Altötting': 3009,
    'Altusried': 3009,
    'Alzenau in Unterfranken': 3009,
    'Amberg': 3009,
    'Amendingen': 3009,
    'Amerang': 3009,
    'Ammerndorf': 3009,
    'Ammerthal': 3009,
    'Ampfing': 3009,
    'Andechs': 3009,
    'Ansbach': 3009,
    'Antdorf': 3009,
    'Anzing': 3009,
    'Apfeldorf': 3009,
    'Arberg': 3009,
    'Aresing': 3009,
    'Arnbruck': 3009,
    'Arnschwang': 3009,
    'Arnstein': 3009,
    'Arnstorf': 3009,
    'Arrach': 3009,
    'Arzberg': 3009,
    'Asbach-Bäumenheim': 3009,
    'Ascha': 3009,
    'Aschaffenburg': 3009,
    'Aschau am Inn': 3009,
    'Aschau im Chiemgau': 3009,
    'Aschheim': 3009,
    'Aßling': 3009,
    'Attenhofen': 3009,
    'Attenkirchen': 3009,
    'Atting': 3009,
    'Au in der Hallertau': 3009,
    'Aub': 3009,
    'Auerbach': 3009,
    'Aufhausen': 3009,
    'Aufseß': 3009,
    'Augsburg': 3009,
    'Auhausen': 3009,
    'Aura im Sinngrund': 3009,
    'Außernzell': 3009,
    'Aying': 3009,
    'Aystetten': 3009,
    'Baar-Ebenhausen': 3009,
    'Babenhausen': 3009,
    'Babensham': 3009,
    'Bach': 3009,
    'Bachhagel': 3009,
    'Bächingen an der Brenz': 3009,
    'Bad Abbach': 3009,
    'Bad Aibling': 3009,
    'Bad Alexandersbad': 3009,
    'Bad Berneck im Fichtelgebirge': 3009,
    'Bad Birnbach': 3009,
    'Bad Bocklet': 3009,
    'Bad Brückenau': 3009,
    'Bad Endorf': 3009,
    'Bad Feilnbach': 3009,
    'Bad Füssing': 3009,
    'Bad Griesbach': 3009,
    'Bad Heilbrunn': 3009,
    'Bad Kissingen': 3009,
    'Bad Kohlgrub': 3009,
    'Bad Königshofen im Grabfeld': 3009,
    'Bad Neustadt an der Saale': 3009,
    'Bad Reichenhall': 3009,
    'Bad Staffelstein': 3009,
    'Bad Steben': 3009,
    'Bad Tölz': 3009,
    'Bad Wiessee': 3009,
    'Bad Windsheim': 3009,
    'Bad Wörishofen': 3009,
    'Baierbrunn': 3009,
    'Baiersdorf': 3009,
    'Baisweil': 3009,
    'Balzhausen': 3009,
    'Bamberg': 3009,
    'Barbing': 3009,
    'Bärnau': 3009,
    'Bastheim': 3009,
    'Baudenbach': 3009,
    'Baunach': 3009,
    'Bayerbach': 3009,
    'Bayerisch Eisenstein': 3009,
    'Bayerisch Gmain': 3009,
    'Bayreuth': 3009,
    'Bayrischzell': 3009,
    'Bechtsrieth': 3009,
    'Bellenberg': 3009,
    'Benediktbeuern': 3009,
    'Benningen': 3009,
    'Beratzhausen': 3009,
    'Berching': 3009,
    'Berchtesgaden': 3009,
    'Berg': 3009,
    'Berg im Gau': 3009,
    'Bergen': 3009,
    'Bergkirchen': 3009,
    'Berglern': 3009,
    'Bergrheinfeld': 3009,
    'Bergtheim': 3009,
    'Bernau am Chiemsee': 3009,
    'Bernbeuren': 3009,
    'Berngau': 3009,
    'Bernhardswald': 3009,
    'Bernried': 3009,
    'Betzigau': 3009,
    'Beutelsbach': 3009,
    'Biberbach': 3009,
    'Biburg': 3009,
    'Bichl': 3009,
    'Bidingen': 3009,
    'Biebelried': 3009,
    'Biessenhofen': 3009,
    'Bindlach': 3009,
    'Binswangen': 3009,
    'Birkenfeld': 3009,
    'Bischberg': 3009,
    'Bischbrunn': 3009,
    'Bischofsgrün': 3009,
    'Bischofsheim an der Rhön': 3009,
    'Bischofsmais': 3009,
    'Bischofswiesen': 3009,
    'Bissingen': 3009,
    'Blaibach': 3009,
    'Blaichach': 3009,
    'Blankenbach': 3009,
    'Blindheim': 3009,
    'Böbing': 3009,
    'Bobingen': 3009,
    'Böbrach': 3009,
    'Bockhorn': 3009,
    'Bodenkirchen': 3009,
    'Bodenmais': 3009,
    'Bodenwöhr': 3009,
    'Bodolz': 3009,
    'Bogen': 3009,
    'Bogenhausen': 3009,
    'Bolsterlang': 3009,
    'Bonstetten': 3009,
    'Boos': 3009,
    'Brand': 3009,
    'Brannenburg': 3009,
    'Breitbrunn': 3009,
    'Breitenberg': 3009,
    'Breitenbrunn': 3009,
    'Breitengüßbach': 3009,
    'Breitenthal': 3009,
    'Brennberg': 3009,
    'Bruck': 3009,
    'Bruck in der Oberpfalz': 3009,
    'Bruckberg': 3009,
    'Bruckmühl': 3009,
    'Brunn': 3009,
    'Brunnen': 3009,
    'Brunnthal': 3009,
    'Bubenreuth': 3009,
    'Bubesheim': 3009,
    'Buch': 3009,
    'Buch am Buchrain': 3009,
    'Buchbach': 3009,
    'Buchbrunn': 3009,
    'Buchdorf': 3009,
    'Büchenbach': 3009,
    'Buchenberg': 3009,
    'Büchlberg': 3009,
    'Buchloe': 3009,
    'Buckenhof': 3009,
    'Burgau': 3009,
    'Burgberg': 3009,
    'Burgbernheim': 3009,
    'Burgebrach': 3009,
    'Burggen': 3009,
    'Burghaslach': 3009,
    'Burghausen': 3009,
    'Burgheim': 3009,
    'Burgkirchen an der Alz': 3009,
    'Burgkunstadt': 3009,
    'Burglauer': 3009,
    'Burglengenfeld': 3009,
    'Burgoberbach': 3009,
    'Burgpreppach': 3009,
    'Burgsalach': 3009,
    'Burgsinn': 3009,
    'Bürgstadt': 3009,
    'Burgthann': 3009,
    'Burgwindheim': 3009,
    'Burk': 3009,
    'Burkardroth': 3009,
    'Burtenbach': 3009,
    'Buttenheim': 3009,
    'Buttenwiesen': 3009,
    'Bütthard': 3009,
    'Buxheim': 3009,
    'Cadolzburg': 3009,
    'Cham': 3009,
    'Chamerau': 3009,
    'Chieming': 3009,
    'Chostlarn': 3009,
    'Coburg': 3009,
    'Colmberg': 3009,
    'Creußen': 3009,
    'Dachau': 3009,
    'Dasing': 3009,
    'Deggendorf': 3009,
    'Deining': 3009,
    'Deiningen': 3009,
    'Deisenhausen': 3009,
    'Denklingen': 3009,
    'Dentlein am Forst': 3009,
    'Dettelbach': 3009,
    'Deuerling': 3009,
    'Diebach': 3009,
    'Diedorf': 3009,
    'Diespeck': 3009,
    'Dießen am Ammersee': 3009,
    'Dietenhofen': 3009,
    'Dietersburg': 3009,
    'Dietersheim': 3009,
    'Dieterskirchen': 3009,
    'Dietfurt': 3009,
    'Dietmannsried': 3009,
    'Dietramszell': 3009,
    'Dillingen an der Donau': 3009,
    'Dingolfing': 3009,
    'Dingolshausen': 3009,
    'Dinkelsbühl': 3009,
    'Dinkelscherben': 3009,
    'Dirlewang': 3009,
    'Dittelbrunn': 3009,
    'Dittenheim': 3009,
    'Döhlau': 3009,
    'Dombühl': 3009,
    'Donaustauf': 3009,
    'Donauwörth': 3009,
    'Donnersdorf': 3009,
    'Dorfen': 3009,
    'Dorfprozelten': 3009,
    'Dormitz': 3009,
    'Drachselsried': 3009,
    'Duggendorf': 3009,
    'Durach': 3009,
    'Dürrlauingen': 3009,
    'Dürrwangen': 3009,
    'Ebelsbach': 3009,
    'Ebensfeld': 3009,
    'Ebermannsdorf': 3009,
    'Ebermannstadt': 3009,
    'Ebersberg': 3009,
    'Ebersdorf': 3009,
    'Ebnath': 3009,
    'Eching': 3009,
    'Eckersdorf': 3009,
    'Edelsfeld': 3009,
    'Ederheim': 3009,
    'Effeltrich': 3009,
    'Egenhofen': 3009,
    'Egg an der Günz': 3009,
    'Eggenfelden': 3009,
    'Eggenthal': 3009,
    'Egglham': 3009,
    'Egglkofen': 3009,
    'Eggolsheim': 3009,
    'Eggstätt': 3009,
    'Eging': 3009,
    'Egling': 3009,
    'Egloffstein': 3009,
    'Egmating': 3009,
    'Ehekirchen': 3009,
    'Ehingen': 3009,
    'Eibelstadt': 3009,
    'Eichenau': 3009,
    'Eichenbühl': 3009,
    'Eichendorf': 3009,
    'Eichenried': 3009,
    'Eichstätt': 3009,
    'Eiselfing': 3009,
    'Eisenburg': 3009,
    'Eisingen': 3009,
    'Eitting': 3009,
    'Elfershausen': 3009,
    'Ellgau': 3009,
    'Ellingen': 3009,
    'Ellzee': 3009,
    'Elsendorf': 3009,
    'Elsenfeld': 3009,
    'Eltmann': 3009,
    'Emersacker': 3009,
    'Emmering': 3009,
    'Emskirchen': 3009,
    'Emtmannsberg': 3009,
    'Engelsberg': 3009,
    'Engelthal': 3009,
    'Ensdorf': 3009,
    'Eppishausen': 3009,
    'Erbendorf': 3009,
    'Erding': 3009,
    'Erdweg': 3009,
    'Eresing': 3009,
    'Ergersheim': 3009,
    'Ergolding': 3009,
    'Ergoldsbach': 3009,
    'Ering': 3009,
    'Erkheim': 3009,
    'Erlabrunn': 3009,
    'Erlangen': 3009,
    'Erlbach': 3009,
    'Erlenbach': 3009,
    'Erlenbach am Main': 3009,
    'Ernsgaden': 3009,
    'Eschau': 3009,
    'Eschenlohe': 3009,
    'Eschlkam': 3009,
    'Eslarn': 3009,
    'Esselbach': 3009,
    'Essenbach': 3009,
    'Essing': 3009,
    'Estenfeld': 3009,
    'Ettringen': 3009,
    'Etzelwang': 3009,
    'Etzenricht': 3009,
    'Euerbach': 3009,
    'Euerdorf': 3009,
    'Eurasburg': 3009,
    'Eußenheim': 3009,
    'Fahrenzhausen': 3009,
    'Falkenberg': 3009,
    'Falkenfels': 3009,
    'Falkenstein': 3009,
    'Farchant': 3009,
    'Faulbach': 3009,
    'Feilitzsch': 3009,
    'Feldafing': 3009,
    'Feldkirchen': 3009,
    'Feldkirchen-Westerham': 3009,
    'Fellheim': 3009,
    'Feucht': 3009,
    'Feuchtwangen': 3009,
    'Fichtelberg': 3009,
    'Finningen': 3009,
    'Finsing': 3009,
    'Fischach': 3009,
    'Fischbachau': 3009,
    'Flachslanden': 3009,
    'Fladungen': 3009,
    'Flintsbach': 3009,
    'Floß': 3009,
    'Flossenbürg': 3009,
    'Forchheim': 3009,
    'Forstinning': 3009,
    'Frammersbach': 3009,
    'Frankenwinheim': 3009,
    'Frasdorf': 3009,
    'Frauenau': 3009,
    'Fraueneuharting': 3009,
    'Fraunberg': 3009,
    'Freihung': 3009,
    'Freilassing': 3009,
    'Freising': 3009,
    'Fremdingen': 3009,
    'Frensdorf': 3009,
    'Freudenberg': 3009,
    'Freystadt': 3009,
    'Freyung': 3009,
    'Frickenhausen': 3009,
    'Fridolfing': 3009,
    'Friedberg': 3009,
    'Friedenfels': 3009,
    'Friesenried': 3009,
    'Frontenhausen': 3009,
    'Fuchsmühl': 3009,
    'Fuchsstadt': 3009,
    'Fünfstetten': 3009,
    'Fürsteneck': 3009,
    'Fürstenfeldbruck': 3009,
    'Fürstenstein': 3009,
    'Fürstenzell': 3009,
    'Furth': 3009,
    'Furth im Wald': 3009,
    'Füssen': 3009,
    'Gablingen': 3009,
    'Gachenbach': 3009,
    'Gadheim': 3009,
    'Gaimersheim': 3009,
    'Gaißach': 3009,
    'Gammelsdorf': 3009,
    'Gangkofen': 3009,
    'Garching an der Alz': 3009,
    'Garching bei München': 3009,
    'Garmisch-Partenkirchen': 3009,
    'Gars': 3009,
    'Gattendorf': 3009,
    'Gaukönigshofen': 3009,
    'Gauting': 3009,
    'Gebsattel': 3009,
    'Gefrees': 3009,
    'Geiersthal': 3009,
    'Geiselbach': 3009,
    'Geiselhöring': 3009,
    'Geiselwind': 3009,
    'Geisenfeld': 3009,
    'Geisenhausen': 3009,
    'Geldersheim': 3009,
    'Geltendorf': 3009,
    'Gemünden am Main': 3009,
    'Genderkingen': 3009,
    'Georgenberg': 3009,
    'Georgensgmünd': 3009,
    'Gerach': 3009,
    'Gerbrunn': 3009,
    'Geretsried': 3009,
    'Gerhardshofen': 3009,
    'Germering': 3009,
    'Geroldsgrün': 3009,
    'Geroldshausen': 3009,
    'Gerolfingen': 3009,
    'Gerolsbach': 3009,
    'Gerolzhofen': 3009,
    'Gersthofen': 3009,
    'Gerzen': 3009,
    'Gesees': 3009,
    'Geslau': 3009,
    'Gessertshausen': 3009,
    'Gestratz': 3009,
    'Giebelstadt': 3009,
    'Gilching': 3009,
    'Glashütten': 3009,
    'Glattbach': 3009,
    'Glonn': 3009,
    'Glött': 3009,
    'Gmund am Tegernsee': 3009,
    'Gochsheim': 3009,
    'Goldbach': 3009,
    'Goldkronach': 3009,
    'Görisried': 3009,
    'Gössenheim': 3009,
    'Gößweinstein': 3009,
    'Gotteszell': 3009,
    'Gottfrieding': 3009,
    'Graben': 3009,
    'Grabenstätt': 3009,
    'Gräfelfing': 3009,
    'Grafenau': 3009,
    'Gräfenberg': 3009,
    'Gräfendorf': 3009,
    'Grafengehaig': 3009,
    'Grafenrheinfeld': 3009,
    'Grafenwiesen': 3009,
    'Grafenwöhr': 3009,
    'Grafing bei München': 3009,
    'Grafrath': 3009,
    'Grainau': 3009,
    'Grainet': 3009,
    'Grasbrunn': 3009,
    'Grassau': 3009,
    'Grattersdorf': 3009,
    'Greding': 3009,
    'Greifenberg': 3009,
    'Greiling': 3009,
    'Gremsdorf': 3009,
    'Grettstadt': 3009,
    'Greußenheim': 3009,
    'Griesstätt': 3009,
    'Gröbenzell': 3009,
    'Großaitingen': 3009,
    'Großbardorf': 3009,
    'Großeibstadt': 3009,
    'Großenseebach': 3009,
    'Großhabersdorf': 3009,
    'Großheirath': 3009,
    'Großheubach': 3009,
    'Großkarolinenfeld': 3009,
    'Großlangheim': 3009,
    'Großostheim': 3009,
    'Großreuth bei Schweinau': 3009,
    'Großwallstadt': 3009,
    'Großweil': 3009,
    'Grub': 3009,
    'Grünenbach': 3009,
    'Grünwald': 3009,
    'Gstadt am Chiemsee': 3009,
    'Gundelfingen': 3009,
    'Gundelsheim': 3009,
    'Gundremmingen': 3009,
    'Güntersleben': 3009,
    'Günzach': 3009,
    'Günzburg': 3009,
    'Gunzenhausen': 3009,
    'Gutenstetten': 3009,
    'Haag an der Amper': 3009,
    'Haag in Oberbayern': 3009,
    'Haar': 3009,
    'Haarbach': 3009,
    'Habach': 3009,
    'Hafenlohr': 3009,
    'Hagelstadt': 3009,
    'Hagenbüchach': 3009,
    'Hahnbach': 3009,
    'Haibach': 3009,
    'Haidmühle': 3009,
    'Haimhausen': 3009,
    'Haiming': 3009,
    'Hainsfarth': 3009,
    'Halblech': 3009,
    'Haldenwang': 3009,
    'Halfing': 3009,
    'Hallbergmoos': 3009,
    'Hallerndorf': 3009,
    'Hallstadt': 3009,
    'Hammelburg': 3009,
    'Happurg': 3009,
    'Harburg': 3009,
    'Harsdorf': 3009,
    'Hartenstein': 3009,
    'Haselbach': 3009,
    'Hasloch': 3009,
    'Haßfurt': 3009,
    'Hattenhofen': 3009,
    'Haundorf': 3009,
    'Haunsheim': 3009,
    'Hausen': 3009,
    'Hausham': 3009,
    'Hauzenberg': 3009,
    'Hawangen': 3009,
    'Hebertsfelden': 3009,
    'Hebertshausen': 3009,
    'Heideck': 3009,
    'Heidenheim': 3009,
    'Heigenbrücken': 3009,
    'Heiligenstadt': 3009,
    'Heilsbronn': 3009,
    'Heimbuchenthal': 3009,
    'Heimenkirch': 3009,
    'Heimertingen': 3009,
    'Helmbrechts': 3009,
    'Helmstadt': 3009,
    'Hemau': 3009,
    'Hemhofen': 3009,
    'Hendungen': 3009,
    'Henfenfeld': 3009,
    'Hengersberg': 3009,
    'Heretsried': 3009,
    'Hergensweiler': 3009,
    'Heroldsbach': 3009,
    'Heroldsberg': 3009,
    'Herrieden': 3009,
    'Herrngiersdorf': 3009,
    'Herrsching am Ammersee': 3009,
    'Hersbruck': 3009,
    'Herzogenaurach': 3009,
    'Heßdorf': 3009,
    'Hettenshausen': 3009,
    'Hettstadt': 3009,
    'Hetzles': 3009,
    'Heustreu': 3009,
    'Hilgertshausen-Tandern': 3009,
    'Hilpoltstein': 3009,
    'Hiltenfingen': 3009,
    'Hiltpoltstein': 3009,
    'Himmelkron': 3009,
    'Himmelstadt': 3009,
    'Hinterschmiding': 3009,
    'Hirschaid': 3009,
    'Hirschau': 3009,
    'Hirschbach': 3009,
    'Höchberg': 3009,
    'Höchheim': 3009,
    'Hochstadt am Main': 3009,
    'Höchstadt an der Aisch': 3009,
    'Höchstädt an der Donau': 3009,
    'Höchstädt bei Thiersheim': 3009,
    'Hof': 3009,
    'Hofheim in Unterfranken': 3009,
    'Hofkirchen': 3009,
    'Hofstetten': 3009,
    'Hohenau': 3009,
    'Hohenberg an der Eger': 3009,
    'Hohenbrunn': 3009,
    'Hohenburg': 3009,
    'Hohenfels': 3009,
    'Hohenfurch': 3009,
    'Hohenkammer': 3009,
    'Höhenkirchen-Siegertsbrunn': 3009,
    'Hohenlinden': 3009,
    'Hohenpeißenberg': 3009,
    'Hohenpolding': 3009,
    'Hohenthann': 3009,
    'Hohenwarth': 3009,
    'Hollenbach': 3009,
    'Hollfeld': 3009,
    'Hollstadt': 3009,
    'Holzgünz': 3009,
    'Holzheim': 3009,
    'Holzkirchen': 3009,
    'Hopferau': 3009,
    'Horgau': 3009,
    'Hörgertshausen': 3009,
    'Hösbach': 3009,
    'Höslwang': 3009,
    'Höttingen': 3009,
    'Huglfing': 3009,
    'Huisheim': 3009,
    'Hunderdorf': 3009,
    'Hunding': 3009,
    'Hurlach': 3009,
    'Hutthurm': 3009,
    'Ichenhausen': 3009,
    'Icking': 3009,
    'Iffeldorf': 3009,
    'Igensdorf': 3009,
    'Iggensbach': 3009,
    'Ihrlerstein': 3009,
    'Illertissen': 3009,
    'Illschwang': 3009,
    'Ilmmünster': 3009,
    'Immenreuth': 3009,
    'Immenstadt im Allgäu': 3009,
    'Inchenhofen': 3009,
    'Ingolstadt': 3009,
    'Innernzell': 3009,
    'Inning am Ammersee': 3009,
    'Inning am Holz': 3009,
    'Insingen': 3009,
    'Inzell': 3009,
    'Iphofen': 3009,
    'Ippesheim': 3009,
    'Ipsheim': 3009,
    'Irchenrieth': 3009,
    'Irlbach': 3009,
    'Irschenberg': 3009,
    'Irsee': 3009,
    'Isen': 3009,
    'Ismaning': 3009,
    'Jandelsbrunn': 3009,
    'Jengen': 3009,
    'Jesenwang': 3009,
    'Jettingen-Scheppach': 3009,
    'Jetzendorf': 3009,
    'Johannesberg': 3009,
    'Johanniskirchen': 3009,
    'Julbach': 3009,
    'Kahl am Main': 3009,
    'Kaisheim': 3009,
    'Kalchreuth': 3009,
    'Kallmünz': 3009,
    'Kammerstein': 3009,
    'Karbach': 3009,
    'Karlsfeld': 3009,
    'Karlshuld': 3009,
    'Karlskron': 3009,
    'Karlstadt': 3009,
    'Karsbach': 3009,
    'Kasendorf': 3009,
    'Kastl': 3009,
    'Kaufbeuren': 3009,
    'Kaufering': 3009,
    'Kelheim': 3009,
    'Kellmünz': 3009,
    'Kemmern': 3009,
    'Kemnath': 3009,
    'Kempten (Allgäu)': 3009,
    'Kettershausen': 3009,
    'Kiefersfelden': 3009,
    'Kienberg': 3009,
    'Kirchanschöring': 3009,
    'Kirchberg': 3009,
    'Kirchdorf': 3009,
    'Kirchdorf am Inn': 3009,
    'Kirchdorf im Wald': 3009,
    'Kirchehrenbach': 3009,
    'Kirchenlamitz': 3009,
    'Kirchenpingarten': 3009,
    'Kirchensittenbach': 3009,
    'Kirchenthumbach': 3009,
    'Kirchham': 3009,
    'Kirchhaslach': 3009,
    'Kirchheim': 3009,
    'Kirchheim bei München': 3009,
    'Kirchlauter': 3009,
    'Kirchroth': 3009,
    'Kirchseeon': 3009,
    'Kirchweidach': 3009,
    'Kirchzell': 3009,
    'Kissing': 3009,
    'Kist': 3009,
    'Kitzingen': 3009,
    'Kleinaitingen': 3009,
    'Kleinheubach': 3009,
    'Kleinkahl': 3009,
    'Kleinlangheim': 3009,
    'Kleinostheim': 3009,
    'Kleinrinderfeld': 3009,
    'Kleinsendelbach': 3009,
    'Kleinwallstadt': 3009,
    'Klingenberg am Main': 3009,
    'Klosterlechfeld': 3009,
    'Knetzgau': 3009,
    'Kochel': 3009,
    'Köditz': 3009,
    'Ködnitz': 3009,
    'Köfering': 3009,
    'Kohlberg': 3009,
    'Kolbermoor': 3009,
    'Kolitzheim': 3009,
    'Kollnburg': 3009,
    'Königsbrunn': 3009,
    'Königsdorf': 3009,
    'Königsfeld': 3009,
    'Königstein': 3009,
    'Konradsreuth': 3009,
    'Konzell': 3009,
    'Kottgeisering': 3009,
    'Kötzting': 3009,
    'Kraiburg am Inn': 3009,
    'Krailling': 3009,
    'Kreut': 3009,
    'Kreuzwertheim': 3009,
    'Krombach': 3009,
    'Kronach': 3009,
    'Kronburg': 3009,
    'Krumbach': 3009,
    'Krummennaab': 3009,
    'Krün': 3009,
    'Kueps Oberfranken': 3009,
    'Kühbach': 3009,
    'Kulmain': 3009,
    'Kulmbach': 3009,
    'Kumhausen': 3009,
    'Kümmersbruck': 3009,
    'Kunreuth': 3009,
    'Künzing': 3009,
    'Kupferberg': 3009,
    'Kürnach': 3009,
    'Kutzenhausen': 3009,
    'Laberweinting': 3009,
    'Lachen': 3009,
    'Lalling': 3009,
    'Lam': 3009,
    'Lamerdingen': 3009,
    'Landau an der Isar': 3009,
    'Landsberg am Lech': 3009,
    'Landsberied': 3009,
    'Landshut': 3009,
    'Langenaltheim': 3009,
    'Langenbach': 3009,
    'Langenfeld': 3009,
    'Langenmosen': 3009,
    'Langenneufnach': 3009,
    'Langenpreising': 3009,
    'Langensendelbach': 3009,
    'Langenzenn': 3009,
    'Langerringen': 3009,
    'Langfurth': 3009,
    'Langquaid': 3009,
    'Langweid': 3009,
    'Lappersdorf': 3009,
    'Lauben': 3009,
    'Laudenbach': 3009,
    'Lauf an der Pegnitz': 3009,
    'Laufach': 3009,
    'Laufen': 3009,
    'Laugna': 3009,
    'Lauingen': 3009,
    'Lauter': 3009,
    'Lauterhofen': 3009,
    'Lautertal': 3009,
    'Lautrach': 3009,
    'Lechbruck': 3009,
    'Legau': 3009,
    'Lehrberg': 3009,
    'Leiblfing': 3009,
    'Leidersbach': 3009,
    'Leinburg': 3009,
    'Leipheim': 3009,
    'Lengdorf': 3009,
    'Lengenwang': 3009,
    'Lenggries': 3009,
    'Leonberg': 3009,
    'Leuchtenberg': 3009,
    'Leupoldsgrün': 3009,
    'Leutenbach': 3009,
    'Leutershausen': 3009,
    'Lichtenau': 3009,
    'Lichtenberg': 3009,
    'Lichtenfels': 3009,
    'Lindau': 3009,
    'Lindberg': 3009,
    'Litzendorf': 3009,
    'Lohberg': 3009,
    'Lohr am Main': 3009,
    'Loiching': 3009,
    'Lonnerstadt': 3009,
    'Lower Bavaria': 3009,
    'Ludwigsstadt': 3009,
    'Luhe-Wildenau': 3009,
    'Lupburg': 3009,
    'Mähring': 3009,
    'Maierhöfen': 3009,
    'Maihingen': 3009,
    'Mainaschaff': 3009,
    'Mainbernheim': 3009,
    'Mainburg': 3009,
    'Mainleus': 3009,
    'Mainstockheim': 3009,
    'Maisach': 3009,
    'Maitenbeth': 3009,
    'Malching': 3009,
    'Malgersdorf': 3009,
    'Mallersdorf-Pfaffenberg': 3009,
    'Mammendorf': 3009,
    'Mamming': 3009,
    'Manching': 3009,
    'Mantel': 3009,
    'Margetshöchheim': 3009,
    'Mariaposching': 3009,
    'Marklkofen': 3009,
    'Markt Berolzheim': 3009,
    'Markt Bibart': 3009,
    'Markt Einersheim': 3009,
    'Markt Erlbach': 3009,
    'Markt Indersdorf': 3009,
    'Markt Nordheim': 3009,
    'Markt Rettenbach': 3009,
    'Markt Schwaben': 3009,
    'Markt Taschendorf': 3009,
    'Markt Wald': 3009,
    'Marktbergel': 3009,
    'Marktbreit': 3009,
    'Marktgraitz': 3009,
    'Marktheidenfeld': 3009,
    'Marktl': 3009,
    'Marktleugast': 3009,
    'Marktleuthen': 3009,
    'Marktoberdorf': 3009,
    'Marktoffingen': 3009,
    'Marktredwitz': 3009,
    'Marktrodach': 3009,
    'Marktschellenberg': 3009,
    'Marktschorgast': 3009,
    'Marktsteft': 3009,
    'Marktzeuln': 3009,
    'Marloffstein': 3009,
    'Maroldsweisach': 3009,
    'Marquartstein': 3009,
    'Martinsheim': 3009,
    'Marxheim': 3009,
    'Marzling': 3009,
    'Maßbach': 3009,
    'Massing': 3009,
    'Mauern': 3009,
    'Mauerstetten': 3009,
    'Mauth': 3009,
    'Maxhütte-Haidhof': 3009,
    'Medlingen': 3009,
    'Meeder': 3009,
    'Mehlmeisel': 3009,
    'Mehring': 3009,
    'Meitingen': 3009,
    'Mellrichstadt': 3009,
    'Memmelsdorf': 3009,
    'Memmingen': 3009,
    'Memmingerberg': 3009,
    'Mengkofen': 3009,
    'Merching': 3009,
    'Mering': 3009,
    'Merkendorf': 3009,
    'Mertingen': 3009,
    'Mespelbrunn': 3009,
    'Metten': 3009,
    'Mettenheim': 3009,
    'Michelau': 3009,
    'Michelsneukirchen': 3009,
    'Mickhausen': 3009,
    'Miesbach': 3009,
    'Miltach': 3009,
    'Miltenberg': 3009,
    'Mindelheim': 3009,
    'Mintraching': 3009,
    'Missen-Wilhams': 3009,
    'Mistelgau': 3009,
    'Mitteleschenbach': 3009,
    'Mittelneufnach': 3009,
    'Mittelstetten': 3009,
    'Mittenwald': 3009,
    'Mitterfels': 3009,
    'Mitterskirchen': 3009,
    'Mitterteich': 3009,
    'Mitwitz': 3009,
    'Mödingen': 3009,
    'Möhrendorf': 3009,
    'Mömbris': 3009,
    'Mömlingen': 3009,
    'Mönchberg': 3009,
    'Mönchsdeggingen': 3009,
    'Mönchsroth': 3009,
    'Monheim': 3009,
    'Moorenweis': 3009,
    'Moosach': 3009,
    'Moosbach': 3009,
    'Moosburg': 3009,
    'Moosinning': 3009,
    'Moosthenning': 3009,
    'Motten': 3009,
    'Möttingen': 3009,
    'Mötzing': 3009,
    'Mühldorf': 3009,
    'Mühlhausen': 3009,
    'Muhr am See': 3009,
    'Münchberg': 3009,
    'Münchsmünster': 3009,
    'Münchsteinach': 3009,
    'Munich': 3009,
    'Münnerstadt': 3009,
    'Munningen': 3009,
    'Münsing': 3009,
    'Münster': 3009,
    'Münsterhausen': 3009,
    'Murnau am Staffelsee': 3009,
    'Nabburg': 3009,
    'Nagel': 3009,
    'Naila': 3009,
    'Nandlstadt': 3009,
    'Nennslingen': 3009,
    'Nersingen': 3009,
    'Nesselwang': 3009,
    'Neu-Ulm': 3009,
    'Neualbenreuth': 3009,
    'Neubeuern': 3009,
    'Neubiberg': 3009,
    'Neubrunn': 3009,
    'Neuburg': 3009,
    'Neuburg an der Donau': 3009,
    'Neudrossenfeld': 3009,
    'Neuendettelsau': 3009,
    'Neuenmarkt': 3009,
    'Neufahrn': 3009,
    'Neufahrn bei Freising': 3009,
    'Neufraunhofen': 3009,
    'Neuhaus am Inn': 3009,
    'Neuhaus an der Pegnitz': 3009,
    'Neuhof an der Zenn': 3009,
    'Neuhütten': 3009,
    'Neukirchen': 3009,
    'Neukirchen-Balbini': 3009,
    'Neumarkt in der Oberpfalz': 3009,
    'Neumarkt-Sankt Veit': 3009,
    'Neunburg vorm Wald': 3009,
    'Neunkirchen am Brand': 3009,
    'Neunkirchen am Main': 3009,
    'Neunkirchen am Sand': 3009,
    'Neuötting': 3009,
    'Neureichenau': 3009,
    'Neuried': 3009,
    'Neuschönau': 3009,
    'Neusitz': 3009,
    'Neusorg': 3009,
    'Neustadt am Main': 3009,
    'Neustadt an der Aisch': 3009,
    'Neustadt an der Donau': 3009,
    'Neustadt an der Waldnaab': 3009,
    'Neustadt bei Coburg': 3009,
    'Neutraubling': 3009,
    'Niederaichbach': 3009,
    'Niederalteich': 3009,
    'Niederbergkirchen': 3009,
    'Niederfüllbach': 3009,
    'Niederlauer': 3009,
    'Niedermurach': 3009,
    'Niedernberg': 3009,
    'Niederrieden': 3009,
    'Niederschönenfeld': 3009,
    'Niedertaufkirchen': 3009,
    'Niederviehbach': 3009,
    'Niederwerrn': 3009,
    'Niederwinkling': 3009,
    'Nittenau': 3009,
    'Nittendorf': 3009,
    'Nonnenhorn': 3009,
    'Nordendorf': 3009,
    'Nordhalben': 3009,
    'Nordheim': 3009,
    'Nördlingen': 3009,
    'Nüdlingen': 3009,
    'Nürnberg': 3009,
    'Nußdorf': 3009,
    'Nußdorf am Inn': 3009,
    'Oberammergau': 3009,
    'Oberasbach': 3009,
    'Oberaudorf': 3009,
    'Oberbergkirchen': 3009,
    'Oberdachstetten': 3009,
    'Oberding': 3009,
    'Oberelsbach': 3009,
    'Obergriesbach': 3009,
    'Obergünzburg': 3009,
    'Oberhaching': 3009,
    'Oberhaid': 3009,
    'Oberhausen': 3009,
    'Oberkotzau': 3009,
    'Oberleichtersbach': 3009,
    'Obermeitingen': 3009,
    'Obermichelbach': 3009,
    'Obernbreit': 3009,
    'Obernburg am Main': 3009,
    'Oberndorf': 3009,
    'Obernzell': 3009,
    'Obernzenn': 3009,
    'Oberostendorf': 3009,
    'Oberottmarshausen': 3009,
    'Oberpframmern': 3009,
    'Oberpleichfeld': 3009,
    'Oberpöring': 3009,
    'Oberreichenbach': 3009,
    'Oberreute': 3009,
    'Oberrieden': 3009,
    'Oberscheinfeld': 3009,
    'Oberschleißheim': 3009,
    'Oberschneiding': 3009,
    'Oberschwarzach': 3009,
    'Oberschweinbach': 3009,
    'Obersinn': 3009,
    'Obersöchering': 3009,
    'Oberstaufen': 3009,
    'Oberstdorf': 3009,
    'Oberstreu': 3009,
    'Obersüßbach': 3009,
    'Obertaufkirchen': 3009,
    'Oberthulba': 3009,
    'Obertraubling': 3009,
    'Obertrubach': 3009,
    'Oberviechtach': 3009,
    'Obing': 3009,
    'Ochsenfurt': 3009,
    'Odelzhausen': 3009,
    'Oerlenbach': 3009,
    'Oettingen in Bayern': 3009,
    'Offenberg': 3009,
    'Offenhausen': 3009,
    'Offingen': 3009,
    'Ofterschwang': 3009,
    'Ohlstadt': 3009,
    'Olching': 3009,
    'Opfenbach': 3009,
    'Ornbau': 3009,
    'Ortenburg': 3009,
    'Osterhofen': 3009,
    'Ostheim vor der Rhön': 3009,
    'Ottenhofen': 3009,
    'Ottensoos': 3009,
    'Otterfing': 3009,
    'Ottobeuren': 3009,
    'Ottobrunn': 3009,
    'Otzing': 3009,
    'Oy-Mittelberg': 3009,
    'Pähl': 3009,
    'Painten': 3009,
    'Palling': 3009,
    'Pappenheim': 3009,
    'Parkstein': 3009,
    'Parkstetten': 3009,
    'Parsberg': 3009,
    'Partenstein': 3009,
    'Pasing': 3009,
    'Passau': 3009,
    'Pastetten': 3009,
    'Patersdorf': 3009,
    'Paunzhausen': 3009,
    'Pechbrunn': 3009,
    'Pegnitz': 3009,
    'Peißenberg': 3009,
    'Peiting': 3009,
    'Pemfling': 3009,
    'Pentling': 3009,
    'Penzberg': 3009,
    'Penzing': 3009,
    'Perach': 3009,
    'Perkam': 3009,
    'Perlesreut': 3009,
    'Petersaurach': 3009,
    'Petersdorf': 3009,
    'Petershausen': 3009,
    'Pettendorf': 3009,
    'Petting': 3009,
    'Pettstadt': 3009,
    'Pfaffenhausen': 3009,
    'Pfaffenhofen': 3009,
    'Pfaffenhofen an der Ilm': 3009,
    'Pfaffenhofen an der Roth': 3009,
    'Pfaffing': 3009,
    'Pfakofen': 3009,
    'Pfarrkirchen': 3009,
    'Pfarrweisach': 3009,
    'Pfatter': 3009,
    'Pfeffenhausen': 3009,
    'Pfofeld': 3009,
    'Pforzen': 3009,
    'Pfreimd': 3009,
    'Pfronten': 3009,
    'Piding': 3009,
    'Pielenhofen': 3009,
    'Pilsach': 3009,
    'Pilsting': 3009,
    'Pinzberg': 3009,
    'Pirk': 3009,
    'Pittenhart': 3009,
    'Planegg': 3009,
    'Plattling': 3009,
    'Pleinfeld': 3009,
    'Pleiskirchen': 3009,
    'Pleystein': 3009,
    'Pliening': 3009,
    'Plößberg': 3009,
    'Pocking': 3009,
    'Poing': 3009,
    'Polling': 3009,
    'Polsingen': 3009,
    'Pommelsbrunn': 3009,
    'Pommersfelden': 3009,
    'Poppenhausen': 3009,
    'Poppenricht': 3009,
    'Pörnbach': 3009,
    'Postau': 3009,
    'Postbauer-Heng': 3009,
    'Postmünster': 3009,
    'Pottenstein': 3009,
    'Pöttmes': 3009,
    'Poxdorf': 3009,
    'Prackenbach': 3009,
    'Prebitz': 3009,
    'Pressath': 3009,
    'Presseck': 3009,
    'Pressig': 3009,
    'Pretzfeld': 3009,
    'Prichsenstadt': 3009,
    'Prien am Chiemsee': 3009,
    'Priesendorf': 3009,
    'Prittriching': 3009,
    'Prosselsheim': 3009,
    'Prutting': 3009,
    'Püchersreuth': 3009,
    'Puchheim': 3009,
    'Pullach im Isartal': 3009,
    'Pullenreuth': 3009,
    'Pürgen': 3009,
    'Puschendorf': 3009,
    'Putzbrunn': 3009,
    'Pyrbaum': 3009,
    'Rain': 3009,
    'Raisting': 3009,
    'Raitenbuch': 3009,
    'Ramerberg': 3009,
    'Ramsau': 3009,
    'Ramsthal': 3009,
    'Randersacker': 3009,
    'Rannungen': 3009,
    'Rattelsdorf': 3009,
    'Rattenberg': 3009,
    'Rattiszell': 3009,
    'Raubling': 3009,
    'Rechtenbach': 3009,
    'Rechtmehring': 3009,
    'Rednitzhembach': 3009,
    'Redwitz an der Rodach': 3009,
    'Regen': 3009,
    'Regensburg': 3009,
    'Regenstauf': 3009,
    'Regierungsbezirk Mittelfranken': 3009,
    'Regierungsbezirk Unterfranken': 3009,
    'Regnitzlosau': 3009,
    'Rehau': 3009,
    'Rehling': 3009,
    'Reichenbach': 3009,
    'Reichenberg': 3009,
    'Reichenschwand': 3009,
    'Reichersbeuern': 3009,
    'Reichertshausen': 3009,
    'Reichertsheim': 3009,
    'Reichertshofen': 3009,
    'Reichling': 3009,
    'Reimlingen': 3009,
    'Reinhausen': 3009,
    'Reisbach': 3009,
    'Reischach': 3009,
    'Reit im Winkl': 3009,
    'Remlingen': 3009,
    'Rennertshofen': 3009,
    'Rentweinsdorf': 3009,
    'Rettenbach': 3009,
    'Rettenberg': 3009,
    'Retzstadt': 3009,
    'Reut': 3009,
    'Reuth': 3009,
    'Ried': 3009,
    'Rieden': 3009,
    'Rieden an der Kötz': 3009,
    'Riedenberg': 3009,
    'Riedenburg': 3009,
    'Riedering': 3009,
    'Riegsee': 3009,
    'Rieneck': 3009,
    'Rimbach': 3009,
    'Rimpar': 3009,
    'Rimsting': 3009,
    'Rinchnach': 3009,
    'Ringelai': 3009,
    'Rödelsee': 3009,
    'Roden': 3009,
    'Rödental': 3009,
    'Roding': 3009,
    'Röfingen': 3009,
    'Roggenburg': 3009,
    'Rohr': 3009,
    'Rohrbach': 3009,
    'Rohrdorf': 3009,
    'Rohrenfels': 3009,
    'Röhrmoos': 3009,
    'Röhrnbach': 3009,
    'Röllbach': 3009,
    'Ronsberg': 3009,
    'Rosenheim': 3009,
    'Röslau': 3009,
    'Rossbach': 3009,
    'Roßhaupten': 3009,
    'Roßtal': 3009,
    'Roth': 3009,
    'Röthenbach': 3009,
    'Röthenbach an der Pegnitz': 3009,
    'Rothenbuch': 3009,
    'Rothenburg ob der Tauber': 3009,
    'Rothenfels': 3009,
    'Röthlein': 3009,
    'Rott': 3009,
    'Rottach-Egern': 3009,
    'Röttenbach': 3009,
    'Rottenbuch': 3009,
    'Rottenburg an der Laaber': 3009,
    'Rottendorf': 3009,
    'Rotthalmünster': 3009,
    'Röttingen': 3009,
    'Rötz': 3009,
    'Rückersdorf': 3009,
    'Rudelzhausen': 3009,
    'Ruderatshofen': 3009,
    'Ruderting': 3009,
    'Rugendorf': 3009,
    'Rügland': 3009,
    'Ruhmannsfelden': 3009,
    'Ruhpolding': 3009,
    'Ruhstorf': 3009,
    'Runding': 3009,
    'Saal': 3009,
    'Saal an der Saale': 3009,
    'Sachsen': 3009,
    'Sachsenkam': 3009,
    'Sailauf': 3009,
    'Salching': 3009,
    'Saldenburg': 3009,
    'Salgen': 3009,
    'Salz': 3009,
    'Salzweg': 3009,
    'Samerberg': 3009,
    'Sand': 3009,
    'Sandberg': 3009,
    'Sankt Englmar': 3009,
    'Sankt Leonhard am Wonneberg': 3009,
    'Sankt Wolfgang': 3009,
    'Sauerlach': 3009,
    'Saulgrub': 3009,
    'Schäftlarn': 3009,
    'Schauenstein': 3009,
    'Schaufling': 3009,
    'Schechen': 3009,
    'Scheidegg': 3009,
    'Scheinfeld': 3009,
    'Scherstetten': 3009,
    'Scheßlitz': 3009,
    'Scheuring': 3009,
    'Scheyern': 3009,
    'Schierling': 3009,
    'Schillingsfürst': 3009,
    'Schiltberg': 3009,
    'Schirmitz': 3009,
    'Schirnding': 3009,
    'Schleching': 3009,
    'Schlehdorf': 3009,
    'Schliersee': 3009,
    'Schlüsselfeld': 3009,
    'Schmidgaden': 3009,
    'Schmidmühlen': 3009,
    'Schmiechen': 3009,
    'Schnaitsee': 3009,
    'Schnaittach': 3009,
    'Schnaittenbach': 3009,
    'Schneckenlohe': 3009,
    'Schneizlreuth': 3009,
    'Schnelldorf': 3009,
    'Schöfweg': 3009,
    'Schöllkrippen': 3009,
    'Schöllnach': 3009,
    'Schönau': 3009,
    'Schönau am Königssee': 3009,
    'Schondorf am Ammersee': 3009,
    'Schondra': 3009,
    'Schongau': 3009,
    'Schöngeising': 3009,
    'Schönsee': 3009,
    'Schonstett': 3009,
    'Schönthal': 3009,
    'Schonungen': 3009,
    'Schönwald': 3009,
    'Schopfloch': 3009,
    'Schorndorf': 3009,
    'Schrobenhausen': 3009,
    'Schwabach': 3009,
    'Schwabhausen': 3009,
    'Schwabmünchen': 3009,
    'Schwabsoien': 3009,
    'Schwaig': 3009,
    'Schwandorf in Bayern': 3009,
    'Schwanfeld': 3009,
    'Schwangau': 3009,
    'Schwarzach': 3009,
    'Schwarzenbach': 3009,
    'Schwarzenbach an der Saale': 3009,
    'Schwarzenbruck': 3009,
    'Schwarzenfeld': 3009,
    'Schwarzhofen': 3009,
    'Schwebheim': 3009,
    'Schweinfurt': 3009,
    'Schweitenkirchen': 3009,
    'Schwenningen': 3009,
    'Schwindegg': 3009,
    'Seefeld': 3009,
    'Seeg': 3009,
    'Seehausen am Staffelsee': 3009,
    'Seeon-Seebruck': 3009,
    'Seeshaupt': 3009,
    'Seinsheim': 3009,
    'Selb': 3009,
    'Selbitz': 3009,
    'Senden': 3009,
    'Sengenthal': 3009,
    'Sennfeld': 3009,
    'Seßlach': 3009,
    'Seubersdorf': 3009,
    'Seukendorf': 3009,
    'Seybothenreuth': 3009,
    'Siegenburg': 3009,
    'Siegsdorf': 3009,
    'Sielenbach': 3009,
    'Sigmarszell': 3009,
    'Simbach': 3009,
    'Simbach am Inn': 3009,
    'Simmelsdorf': 3009,
    'Sindelsdorf': 3009,
    'Sinzing': 3009,
    'Söchtenau': 3009,
    'Solnhofen': 3009,
    'Sommerach': 3009,
    'Sommerhausen': 3009,
    'Sommerkahl': 3009,
    'Sondheim vor der Rhön': 3009,
    'Sonnefeld': 3009,
    'Sonnen': 3009,
    'Sontheim': 3009,
    'Sonthofen': 3009,
    'Soyen': 3009,
    'Spalt': 3009,
    'Spardorf': 3009,
    'Sparneck': 3009,
    'Speichersdorf': 3009,
    'Speinshart': 3009,
    'Spiegelau': 3009,
    'Stadelhofen': 3009,
    'Stadtbergen': 3009,
    'Stadtlauringen': 3009,
    'Stadtprozelten': 3009,
    'Stadtsteinach': 3009,
    'Stallwang': 3009,
    'Stammbach': 3009,
    'Stammham': 3009,
    'Stamsried': 3009,
    'Starnberg': 3009,
    'Staudach-Egerndach': 3009,
    'Stegaurach': 3009,
    'Stein': 3009,
    'Steinach': 3009,
    'Steinbach': 3009,
    'Steinfeld': 3009,
    'Steingaden': 3009,
    'Steinheim': 3009,
    'Steinhöring': 3009,
    'Steinkirchen': 3009,
    'Steinsfeld': 3009,
    'Steinwiesen': 3009,
    'Stephanskirchen': 3009,
    'Stephansposching': 3009,
    'Stetten': 3009,
    'Stettfeld': 3009,
    'Stiefenhofen': 3009,
    'Stockheim': 3009,
    'Stockstadt am Main': 3009,
    'Störnstein': 3009,
    'Stötten am Auerberg': 3009,
    'Stöttwang': 3009,
    'Straßkirchen': 3009,
    'Straßlach-Dingharting': 3009,
    'Straubing': 3009,
    'Strullendorf': 3009,
    'Stubenberg': 3009,
    'Stulln': 3009,
    'Sugenheim': 3009,
    'Sulzbach am Main': 3009,
    'Sulzbach-Rosenberg': 3009,
    'Sulzberg': 3009,
    'Sulzdorf': 3009,
    'Sulzemoos': 3009,
    'Sulzfeld': 3009,
    'Sulzfeld am Main': 3009,
    'Sulzheim': 3009,
    'Sünching': 3009,
    'Surberg': 3009,
    'Swabia': 3009,
    'Tacherting': 3009,
    'Taching am See': 3009,
    'Tagmersheim': 3009,
    'Tann': 3009,
    'Tännesberg': 3009,
    'Tapfheim': 3009,
    'Taufkirchen': 3009,
    'Tegernheim': 3009,
    'Tegernsee': 3009,
    'Teisendorf': 3009,
    'Teising': 3009,
    'Teisnach': 3009,
    'Tettau': 3009,
    'Tettenweis': 3009,
    'Teublitz': 3009,
    'Teugn': 3009,
    'Teunz': 3009,
    'Teuschnitz': 3009,
    'Thalmassing': 3009,
    'Thannhausen': 3009,
    'Thanstein': 3009,
    'Theilheim': 3009,
    'Theisseil': 3009,
    'Thierhaupten': 3009,
    'Thiersheim': 3009,
    'Thierstein': 3009,
    'Thundorf in Unterfranken': 3009,
    'Thüngen': 3009,
    'Thüngersheim': 3009,
    'Thurmansbang': 3009,
    'Thurnau': 3009,
    'Thyrnau': 3009,
    'Tiefenbach': 3009,
    'Tirschenreuth': 3009,
    'Tittling': 3009,
    'Tittmoning': 3009,
    'Todtenweis': 3009,
    'Töging am Inn': 3009,
    'Töpen': 3009,
    'Trabitz': 3009,
    'Train': 3009,
    'Traitsching': 3009,
    'Trappstadt': 3009,
    'Traunreut': 3009,
    'Traunstein': 3009,
    'Trausnitz': 3009,
    'Trautskirchen': 3009,
    'Trebgast': 3009,
    'Treffelstein': 3009,
    'Treuchtlingen': 3009,
    'Triftern': 3009,
    'Trogen': 3009,
    'Tröstau': 3009,
    'Trostberg an der Alz': 3009,
    'Trunkelsberg': 3009,
    'Tuchenbach': 3009,
    'Tuntenhausen': 3009,
    'Türkenfeld': 3009,
    'Türkheim': 3009,
    'Tussenhausen': 3009,
    'Tüßling': 3009,
    'Tutzing': 3009,
    'Tyrlaching': 3009,
    'Übersee': 3009,
    'Üchtelhausen': 3009,
    'Uehlfeld': 3009,
    'Uettingen': 3009,
    'Uffenheim': 3009,
    'Uffing': 3009,
    'Ungerhausen': 3009,
    'Unterammergau': 3009,
    'Unterdießen': 3009,
    'Unterdietfurt': 3009,
    'Unteregg': 3009,
    'Unterföhring': 3009,
    'Untergriesbach': 3009,
    'Unterhaching': 3009,
    'Unterleinleiter': 3009,
    'Untermeitingen': 3009,
    'Untermerzbach': 3009,
    'Unterneukirchen': 3009,
    'Unterpleichfeld': 3009,
    'Unterreit': 3009,
    'Unterschleißheim': 3009,
    'Untersiemau': 3009,
    'Untersteinach': 3009,
    'Unterthingau': 3009,
    'Unterwössen': 3009,
    'Untrasried': 3009,
    'Upper Bavaria': 3009,
    'Upper Franconia': 3009,
    'Upper Palatinate': 3009,
    'Ursberg': 3009,
    'Ursensollen': 3009,
    'Urspringen': 3009,
    'Ustersbach': 3009,
    'Uttenreuth': 3009,
    'Utting am Ammersee': 3009,
    'Vachendorf': 3009,
    'Vagen': 3009,
    'Valley': 3009,
    'Vaterstetten': 3009,
    'Veitsbronn': 3009,
    'Veitshöchheim': 3009,
    'Velburg': 3009,
    'Velden': 3009,
    'Vestenbergsgreuth': 3009,
    'Viechtach': 3009,
    'Viereth-Trunstadt': 3009,
    'Vierkirchen': 3009,
    'Vilgertshofen': 3009,
    'Villenbach': 3009,
    'Vilsbiburg': 3009,
    'Vilseck': 3009,
    'Vilsheim': 3009,
    'Vilshofen': 3009,
    'Vogtareuth': 3009,
    'Vohburg an der Donau': 3009,
    'Vohenstrauß': 3009,
    'Vöhringen': 3009,
    'Volkach': 3009,
    'Volkenschwand': 3009,
    'Vorbach': 3009,
    'Vorra': 3009,
    'Waakirchen': 3009,
    'Waal': 3009,
    'Wachenroth': 3009,
    'Wackersberg': 3009,
    'Wackersdorf': 3009,
    'Waffenbrunn': 3009,
    'Waging am See': 3009,
    'Waidhaus': 3009,
    'Waidhofen': 3009,
    'Waigolshausen': 3009,
    'Waischenfeld': 3009,
    'Wald': 3009,
    'Waldaschaff': 3009,
    'Waldbrunn': 3009,
    'Waldbüttelbrunn': 3009,
    'Walderbach': 3009,
    'Waldershof': 3009,
    'Waldkirchen': 3009,
    'Waldkraiburg': 3009,
    'Waldmünchen': 3009,
    'Waldsassen': 3009,
    'Waldstetten': 3009,
    'Waldthurn': 3009,
    'Walkertshofen': 3009,
    'Wallenfels': 3009,
    'Wallerfing': 3009,
    'Wallersdorf': 3009,
    'Wallerstein': 3009,
    'Wallgau': 3009,
    'Walpertskirchen': 3009,
    'Walsdorf': 3009,
    'Waltenhofen': 3009,
    'Wang': 3009,
    'Warmensteinach': 3009,
    'Warngau': 3009,
    'Wartenberg': 3009,
    'Wartmannsroth': 3009,
    'Wasserburg': 3009,
    'Wasserburg am Inn': 3009,
    'Wasserlosen': 3009,
    'Wassertrüdingen': 3009,
    'Wechingen': 3009,
    'Wegscheid': 3009,
    'Wehringen': 3009,
    'Weibersbrunn': 3009,
    'Weichering': 3009,
    'Weichs': 3009,
    'Weiden': 3009,
    'Weidenbach': 3009,
    'Weidhausen bei Coburg': 3009,
    'Weiding': 3009,
    'Weigendorf': 3009,
    'Weihenzell': 3009,
    'Weiherhammer': 3009,
    'Weihmichl': 3009,
    'Weil': 3009,
    'Weilbach': 3009,
    'Weiler-Simmerberg': 3009,
    'Weilersbach': 3009,
    'Weilheim': 3009,
    'Weiltingen': 3009,
    'Weisendorf': 3009,
    'Weismain': 3009,
    'Weißdorf': 3009,
    'Weißenbrunn': 3009,
    'Weißenburg in Bayern': 3009,
    'Weißenhorn': 3009,
    'Weißenohe': 3009,
    'Weißensberg': 3009,
    'Weißenstadt': 3009,
    'Weitnau': 3009,
    'Weitramsdorf': 3009,
    'Welden': 3009,
    'Wemding': 3009,
    'Wendelstein': 3009,
    'Weng': 3009,
    'Wenzenbach': 3009,
    'Wernberg-Köblitz': 3009,
    'Werneck': 3009,
    'Wertach': 3009,
    'Wertingen': 3009,
    'Weßling': 3009,
    'Wessobrunn': 3009,
    'Westendorf': 3009,
    'Westerheim': 3009,
    'Westheim': 3009,
    'Wetzendorf': 3009,
    'Weyarn': 3009,
    'Wiedergeltingen': 3009,
    'Wiesau': 3009,
    'Wiesen': 3009,
    'Wiesenfelden': 3009,
    'Wiesent': 3009,
    'Wiesenthau': 3009,
    'Wiesentheid': 3009,
    'Wiesthal': 3009,
    'Wiggensbach': 3009,
    'Wilburgstetten': 3009,
    'Wildenberg': 3009,
    'Wildflecken': 3009,
    'Wildpoldsried': 3009,
    'Wildsteig': 3009,
    'Wilhelmsdorf': 3009,
    'Wilhelmsthal': 3009,
    'Wilhermsdorf': 3009,
    'Willanzheim': 3009,
    'Willmering': 3009,
    'Windach': 3009,
    'Windberg': 3009,
    'Windelsbach': 3009,
    'Windischeschenbach': 3009,
    'Windsbach': 3009,
    'Winhöring': 3009,
    'Winkelhaid': 3009,
    'Winklarn': 3009,
    'Winterhausen': 3009,
    'Winzer': 3009,
    'Wipfeld': 3009,
    'Wirsberg': 3009,
    'Wittelshofen': 3009,
    'Wittislingen': 3009,
    'Witzmannsberg': 3009,
    'Wolfersdorf': 3009,
    'Wolferstadt': 3009,
    'Wolfertschwenden': 3009,
    'Wolframs-Eschenbach': 3009,
    'Wolfratshausen': 3009,
    'Wolfsegg': 3009,
    'Wollbach': 3009,
    'Wolnzach': 3009,
    'Wonfurt': 3009,
    'Wonsees': 3009,
    'Woringen': 3009,
    'Wörth': 3009,
    'Wörth am Main': 3009,
    'Wörth an der Donau': 3009,
    'Wörth an der Isar': 3009,
    'Wörthsee': 3009,
    'Wülfershausen': 3009,
    'Wunsiedel': 3009,
    'Wurmannsquick': 3009,
    'Wurmsham': 3009,
    'Würzburg': 3009,
    'Zachenberg': 3009,
    'Zandt': 3009,
    'Zangberg': 3009,
    'Zapfendorf': 3009,
    'Zeil': 3009,
    'Zeilarn': 3009,
    'Zeitlarn': 3009,
    'Zeitlofs': 3009,
    'Zell am Main': 3009,
    'Zell im Fichtelgebirge': 3009,
    'Zellingen': 3009,
    'Zenting': 3009,
    'Ziemetshausen': 3009,
    'Ziertheim': 3009,
    'Zirndorf': 3009,
    'Zolling': 3009,
    'Zorneding': 3009,
    'Zusamaltheim': 3009,
    'Zusmarshausen': 3009,
    'Zwiesel': 3009,
    'Adlershof': 3010,
    'Alt-Hohenschönhausen': 3010,
    'Alt-Treptow': 3010,
    'Altglienicke': 3010,
    'Baumschulenweg': 3010,
    'Berlin': 3010,
    'Berlin Köpenick': 3010,
    'Berlin Treptow': 3010,
    'Biesdorf': 3010,
    'Blankenburg': 3010,
    'Blankenfelde': 3010,
    'Bohnsdorf': 3010,
    'Britz': 3010,
    'Buch': 3010,
    'Buckow': 3010,
    'Charlottenburg': 3010,
    'Charlottenburg-Nord': 3010,
    'Dahlem': 3010,
    'Falkenberg': 3010,
    'Falkenhagener Feld': 3010,
    'Fennpfuhl': 3010,
    'Französisch Buchholz': 3010,
    'Friedenau': 3010,
    'Friedrichsfelde': 3010,
    'Friedrichshagen': 3010,
    'Friedrichshain': 3010,
    'Frohnau': 3010,
    'Gatow': 3010,
    'Gesundbrunnen': 3010,
    'Gropiusstadt': 3010,
    'Grünau': 3010,
    'Grunewald': 3010,
    'Hakenfelde': 3010,
    'Halensee': 3010,
    'Hansaviertel': 3010,
    'Haselhorst': 3010,
    'Heiligensee': 3010,
    'Heinersdorf': 3010,
    'Hellersdorf': 3010,
    'Hermsdorf': 3010,
    'Johannisthal': 3010,
    'Karlshorst': 3010,
    'Karow': 3010,
    'Kaulsdorf': 3010,
    'Kladow': 3010,
    'Konradshöhe': 3010,
    'Köpenick': 3010,
    'Kreuzberg': 3010,
    'Lankwitz': 3010,
    'Lichtenberg': 3010,
    'Lichtenrade': 3010,
    'Lichterfelde': 3010,
    'Lübars': 3010,
    'Mahlsdorf': 3010,
    'Mariendorf': 3010,
    'Marienfelde': 3010,
    'Märkisches Viertel': 3010,
    'Marzahn': 3010,
    'Mitte': 3010,
    'Moabit': 3010,
    'Müggelheim': 3010,
    'Neu-Hohenschönhausen': 3010,
    'Neukölln': 3010,
    'Niederschöneweide': 3010,
    'Niederschönhausen': 3010,
    'Nikolassee': 3010,
    'Oberschöneweide': 3010,
    'Pankow': 3010,
    'Plänterwald': 3010,
    'Prenzlauer Berg': 3010,
    'Rahnsdorf': 3010,
    'Reinickendorf': 3010,
    'Rosenthal': 3010,
    'Rudow': 3010,
    'Rummelsburg': 3010,
    'Schmargendorf': 3010,
    'Schmöckwitz': 3010,
    'Schöneberg': 3010,
    'Siemensstadt': 3010,
    'Spandau': 3010,
    'Staaken': 3010,
    'Stadtrandsiedlung Malchow': 3010,
    'Steglitz': 3010,
    'Tegel': 3010,
    'Tempelhof': 3010,
    'Tiergarten': 3010,
    'Waidmannslust': 3010,
    'Wannsee': 3010,
    'Wartenberg': 3010,
    'Wedding': 3010,
    'Weißensee': 3010,
    'Westend': 3010,
    'Wilhelmsruh': 3010,
    'Wilhelmstadt': 3010,
    'Wilmersdorf': 3010,
    'Wittenau': 3010,
    'Zehlendorf': 3010,
    'Alt Tucheband': 3013,
    'Altdöbern': 3013,
    'Altlandsberg': 3013,
    'Angermünde': 3013,
    'Bad Belzig': 3013,
    'Bad Freienwalde': 3013,
    'Bad Liebenwerda': 3013,
    'Bad Saarow': 3013,
    'Bad Wilsnack': 3013,
    'Baruth': 3013,
    'Beelitz': 3013,
    'Beeskow': 3013,
    'Bensdorf': 3013,
    'Berkenbrück': 3013,
    'Bernau bei Berlin': 3013,
    'Bestensee': 3013,
    'Biesenthal': 3013,
    'Birkenwerder': 3013,
    'Bliesdorf': 3013,
    'Borkheide': 3013,
    'Borkwalde': 3013,
    'Brandenburg an der Havel': 3013,
    'Breddin': 3013,
    'Breese': 3013,
    'Brieselang': 3013,
    'Briesen': 3013,
    'Brieskow-Finkenheerd': 3013,
    'Britz': 3013,
    'Brück': 3013,
    'Brüssow': 3013,
    'Buckow': 3013,
    'Burg': 3013,
    'Calau': 3013,
    'Casekow': 3013,
    'Chorin': 3013,
    'Cottbus': 3013,
    'Crinitz': 3013,
    'Dahme': 3013,
    'Dallgow-Döberitz': 3013,
    'Doberlug-Kirchhain': 3013,
    'Drebkau': 3013,
    'Dreetz': 3013,
    'Eberswalde': 3013,
    'Eichwalde': 3013,
    'Eisenhüttenstadt': 3013,
    'Elsterwerda': 3013,
    'Erkner': 3013,
    'Falkenberg': 3013,
    'Falkensee': 3013,
    'Fehrbellin': 3013,
    'Fichtenwalde': 3013,
    'Finsterwalde': 3013,
    'Forst': 3013,
    'Frankfurt (Oder)': 3013,
    'Friedland': 3013,
    'Friesack': 3013,
    'Fürstenberg': 3013,
    'Fürstenwalde': 3013,
    'Gartz': 3013,
    'Gerswalde': 3013,
    'Glienicke': 3013,
    'Golßen': 3013,
    'Golzow': 3013,
    'Görzke': 3013,
    'Gramzow': 3013,
    'Gransee': 3013,
    'Gröden': 3013,
    'Groß Köris': 3013,
    'Groß Kreutz': 3013,
    'Groß Lindow': 3013,
    'Groß Pankow': 3013,
    'Großbeeren': 3013,
    'Großkmehlen': 3013,
    'Großräschen': 3013,
    'Großthiemig': 3013,
    'Großwoltersdorf': 3013,
    'Grünheide': 3013,
    'Guben': 3013,
    'Gumtow': 3013,
    'Halbe': 3013,
    'Heiligengrabe': 3013,
    'Hennigsdorf': 3013,
    'Herzberg': 3013,
    'Hirschfeld': 3013,
    'Hohen Neuendorf': 3013,
    'Hohenbocka': 3013,
    'Hohenleipisch': 3013,
    'Jacobsdorf': 3013,
    'Jänschwalde': 3013,
    'Joachimsthal': 3013,
    'Jüterbog': 3013,
    'Karstädt': 3013,
    'Ketzin': 3013,
    'Kleinmachnow': 3013,
    'Kloster Lehnin': 3013,
    'Kolkwitz': 3013,
    'Königs Wusterhausen': 3013,
    'Kremmen': 3013,
    'Kyritz': 3013,
    'Lauchhammer': 3013,
    'Lebus': 3013,
    'Leegebruch': 3013,
    'Lenzen': 3013,
    'Letschin': 3013,
    'Liebenwalde': 3013,
    'Lieberose': 3013,
    'Lindow': 3013,
    'Lübben': 3013,
    'Lübbenau': 3013,
    'Luckau': 3013,
    'Luckenwalde': 3013,
    'Ludwigsfelde': 3013,
    'Lychen': 3013,
    'Manschnow': 3013,
    'Marienwerder': 3013,
    'Melchow': 3013,
    'Meyenburg': 3013,
    'Michendorf': 3013,
    'Milmersdorf': 3013,
    'Mittenwalde': 3013,
    'Mixdorf': 3013,
    'Mühlberg': 3013,
    'Müllrose': 3013,
    'Müncheberg': 3013,
    'Nauen': 3013,
    'Nennhausen': 3013,
    'Neu Zauche': 3013,
    'Neuenhagen': 3013,
    'Neuhardenberg': 3013,
    'Neulewin': 3013,
    'Neuruppin': 3013,
    'Neutrebbin': 3013,
    'Neuzelle': 3013,
    'Niedergörsdorf': 3013,
    'Niemegk': 3013,
    'Oderberg': 3013,
    'Oranienburg': 3013,
    'Ortrand': 3013,
    'Paulinenaue': 3013,
    'Peitz': 3013,
    'Perleberg': 3013,
    'Petershagen': 3013,
    'Plattenburg': 3013,
    'Plessa': 3013,
    'Podelzig': 3013,
    'Potsdam': 3013,
    'Premnitz': 3013,
    'Prenzlau': 3013,
    'Pritzwalk': 3013,
    'Prötzel': 3013,
    'Putlitz': 3013,
    'Rangsdorf': 3013,
    'Rathenow': 3013,
    'Rauen': 3013,
    'Rehfelde': 3013,
    'Reichenwalde': 3013,
    'Rheinsberg': 3013,
    'Rhinow': 3013,
    'Rietz Neuendorf': 3013,
    'Roskow': 3013,
    'Rückersdorf': 3013,
    'Rüdnitz': 3013,
    'Ruhland': 3013,
    'Sallgast': 3013,
    'Schenkendöbern': 3013,
    'Schipkau': 3013,
    'Schlieben': 3013,
    'Schönborn': 3013,
    'Schönefeld': 3013,
    'Schöneiche': 3013,
    'Schönewalde': 3013,
    'Schulzendorf': 3013,
    'Schwarzheide': 3013,
    'Schwedt (Oder)': 3013,
    'Seelow': 3013,
    'Senftenberg': 3013,
    'Sonnewalde': 3013,
    'Sperenberg': 3013,
    'Spreenhagen': 3013,
    'Stahnsdorf': 3013,
    'Steinhöfel': 3013,
    'Storkow': 3013,
    'Straupitz': 3013,
    'Strausberg': 3013,
    'Tauche': 3013,
    'Teltow': 3013,
    'Templin': 3013,
    'Teupitz': 3013,
    'Trebbin': 3013,
    'Treuenbrietzen': 3013,
    'Tschernitz': 3013,
    'Uebigau': 3013,
    'Velten': 3013,
    'Vetschau': 3013,
    'Waldsieversdorf': 3013,
    'Wandlitz': 3013,
    'Weisen': 3013,
    'Welzow': 3013,
    'Wendisch Rietz': 3013,
    'Werben': 3013,
    'Werder': 3013,
    'Werftpfuhl': 3013,
    'Werneuchen': 3013,
    'Wiesenau': 3013,
    'Wiesenburg': 3013,
    'Wittenberge': 3013,
    'Wittstock': 3013,
    'Woltersdorf': 3013,
    'Wriezen': 3013,
    'Wusterhausen': 3013,
    'Wustermark': 3013,
    'Wusterwitz': 3013,
    'Zehdenick': 3013,
    'Zeuthen': 3013,
    'Ziesar': 3013,
    'Ziltendorf': 3013,
    'Zossen': 3013,
    'Bremen': 3014,
    'Bremerhaven': 3014,
    'Burglesum': 3014,
    'Vegesack': 3014,
    'Alsterdorf': 3016,
    'Altona': 3016,
    'Barmbek-Nord': 3016,
    'Bergedorf': 3016,
    'Bergstedt': 3016,
    'Borgfelde': 3016,
    'Duvenstedt': 3016,
    'Eidelstedt': 3016,
    'Eimsbüttel': 3016,
    'Farmsen-Berne': 3016,
    'Fuhlsbüttel': 3016,
    'Hamburg': 3016,
    'Hamburg-Altstadt': 3016,
    'Hamburg-Mitte': 3016,
    'Hamburg-Nord': 3016,
    'Hammerbrook': 3016,
    'Harburg': 3016,
    'Hummelsbüttel': 3016,
    'Kleiner Grasbrook': 3016,
    'Langenhorn': 3016,
    'Lemsahl-Mellingstedt': 3016,
    'Lurup': 3016,
    'Marienthal': 3016,
    'Neustadt': 3016,
    'Ohlsdorf': 3016,
    'Ottensen': 3016,
    'Poppenbüttel': 3016,
    'Rothenburgsort': 3016,
    'Sasel': 3016,
    'St. Georg': 3016,
    'St. Pauli': 3016,
    'Steilshoop': 3016,
    'Stellingen': 3016,
    'Wandsbek': 3016,
    'Wellingsbüttel': 3016,
    'Winterhude': 3016,
    'Wohldorf-Ohlstedt': 3016,
    'Albshausen': 3018,
    'Alheim': 3018,
    'Allendorf': 3018,
    'Allendorf an der Lahn': 3018,
    'Alsbach-Hähnlein': 3018,
    'Alsfeld': 3018,
    'Alten Buseck': 3018,
    'Altenstadt': 3018,
    'Amöneburg': 3018,
    'Aßlar': 3018,
    'Babenhausen': 3018,
    'Bad Arolsen': 3018,
    'Bad Camberg': 3018,
    'Bad Endbach': 3018,
    'Bad Hersfeld': 3018,
    'Bad Homburg vor der Höhe': 3018,
    'Bad Karlshafen': 3018,
    'Bad König': 3018,
    'Bad Nauheim': 3018,
    'Bad Orb': 3018,
    'Bad Salzschlirf': 3018,
    'Bad Schwalbach': 3018,
    'Bad Soden am Taunus': 3018,
    'Bad Soden-Salmünster': 3018,
    'Bad Sooden-Allendorf': 3018,
    'Bad Vilbel': 3018,
    'Bad Wildungen': 3018,
    'Battenberg': 3018,
    'Baunatal': 3018,
    'Bebra': 3018,
    'Beerfelden': 3018,
    'Bensheim': 3018,
    'Berstadt': 3018,
    'Beuern': 3018,
    'Biblis': 3018,
    'Bickenbach': 3018,
    'Biebesheim': 3018,
    'Biedenkopf': 3018,
    'Birkenau': 3018,
    'Birstein': 3018,
    'Bischoffen': 3018,
    'Bischofsheim': 3018,
    'Borken': 3018,
    'Braunfels': 3018,
    'Breidenbach': 3018,
    'Breitenbach': 3018,
    'Breitscheid': 3018,
    'Brensbach': 3018,
    'Breuna': 3018,
    'Bromskirchen': 3018,
    'Bruchköbel': 3018,
    'Büdingen': 3018,
    'Burghaun': 3018,
    'Burgsolms': 3018,
    'Bürstadt': 3018,
    'Büttelborn': 3018,
    'Butzbach': 3018,
    'Calden': 3018,
    'Cölbe': 3018,
    'Cornberg': 3018,
    'Darmstadt': 3018,
    'Dehrn': 3018,
    'Dieburg': 3018,
    'Dietzenbach': 3018,
    'Dillenburg': 3018,
    'Dipperz': 3018,
    'Dreieich': 3018,
    'Driedorf': 3018,
    'Echzell': 3018,
    'Egelsbach': 3018,
    'Ehringshausen': 3018,
    'Eichenzell': 3018,
    'Einhausen': 3018,
    'Eiterfeld': 3018,
    'Eltville': 3018,
    'Elz': 3018,
    'Eppertshausen': 3018,
    'Eppstein': 3018,
    'Erbach': 3018,
    'Erlensee': 3018,
    'Erzhausen': 3018,
    'Eschborn': 3018,
    'Eschwege': 3018,
    'Espenau': 3018,
    'Felsberg': 3018,
    'Flieden': 3018,
    'Flörsheim': 3018,
    'Florstadt': 3018,
    'Frankenau': 3018,
    'Frankenberg': 3018,
    'Frankfurt am Main': 3018,
    'Fränkisch-Crumbach': 3018,
    'Freiensteinau': 3018,
    'Friedberg': 3018,
    'Friedewald': 3018,
    'Friedrichsdorf': 3018,
    'Frielendorf': 3018,
    'Fritzlar': 3018,
    'Fronhausen': 3018,
    'Fulda': 3018,
    'Fuldatal': 3018,
    'Fürth': 3018,
    'Gallus': 3018,
    'Gedern': 3018,
    'Geisenheim': 3018,
    'Gelnhausen': 3018,
    'Gemünden an der Wohra': 3018,
    'Gernsheim': 3018,
    'Gersfeld': 3018,
    'Gießen': 3018,
    'Gilserberg': 3018,
    'Ginsheim-Gustavsburg': 3018,
    'Gladenbach': 3018,
    'Glashütten': 3018,
    'Glauburg': 3018,
    'Grävenwiesbach': 3018,
    'Grebenau': 3018,
    'Grebenhain': 3018,
    'Grebenstein': 3018,
    'Greifenstein': 3018,
    'Griesheim': 3018,
    'Groß-Bieberau': 3018,
    'Groß-Gerau': 3018,
    'Groß-Rohrheim': 3018,
    'Groß-Umstadt': 3018,
    'Groß-Zimmern': 3018,
    'Großalmerode': 3018,
    'Großenlüder': 3018,
    'Großkrotzenburg': 3018,
    'Grünberg': 3018,
    'Gudensberg': 3018,
    'Guxhagen': 3018,
    'Hadamar': 3018,
    'Haiger': 3018,
    'Hain-Gründau': 3018,
    'Haina': 3018,
    'Hammersbach': 3018,
    'Hanau am Main': 3018,
    'Hattersheim': 3018,
    'Hatzfeld': 3018,
    'Helsa': 3018,
    'Heppenheim an der Bergstrasse': 3018,
    'Herborn': 3018,
    'Herbstein': 3018,
    'Heringen': 3018,
    'Herleshausen': 3018,
    'Hessisch Lichtenau': 3018,
    'Heuchelheim': 3018,
    'Heusenstamm': 3018,
    'Hilders': 3018,
    'Hirschhorn': 3018,
    'Hirzenhain': 3018,
    'Hochheim am Main': 3018,
    'Höchst im Odenwald': 3018,
    'Hofbieber': 3018,
    'Hofgeismar': 3018,
    'Hofheim am Taunus': 3018,
    'Höingen': 3018,
    'Homberg': 3018,
    'Hörnsheim': 3018,
    'Hosenfeld': 3018,
    'Hünfeld': 3018,
    'Hungen': 3018,
    'Idstein': 3018,
    'Immenhausen': 3018,
    'Jesberg': 3018,
    'Karben': 3018,
    'Kassel': 3018,
    'Kaufungen': 3018,
    'Kefenrod': 3018,
    'Kelkheim (Taunus)': 3018,
    'Kelsterbach': 3018,
    'Kiedrich': 3018,
    'Kirchhain': 3018,
    'Kirchheim': 3018,
    'Kirtorf': 3018,
    'Königstein im Taunus': 3018,
    'Korbach': 3018,
    'Körle': 3018,
    'Kriftel': 3018,
    'Kronberg': 3018,
    'Kronberg Tal': 3018,
    'Künzell': 3018,
    'Lampertheim': 3018,
    'Langen': 3018,
    'Langenselbold': 3018,
    'Langgöns': 3018,
    'Laubach': 3018,
    'Laufdorf': 3018,
    'Lauterbach': 3018,
    'Lautertal': 3018,
    'Leun': 3018,
    'Lich': 3018,
    'Liebenau': 3018,
    'Liederbach': 3018,
    'Limburg an der Lahn': 3018,
    'Lindenfels': 3018,
    'Lohfelden': 3018,
    'Löhnberg': 3018,
    'Lohra': 3018,
    'Lollar': 3018,
    'Lorsch': 3018,
    'Lützelbach': 3018,
    'Maintal': 3018,
    'Malsfeld': 3018,
    'Marburg an der Lahn': 3018,
    'Martinhagen': 3018,
    'Melbach': 3018,
    'Melsungen': 3018,
    'Mengerskirchen': 3018,
    'Merenberg': 3018,
    'Merkenbach': 3018,
    'Messel': 3018,
    'Michelstadt': 3018,
    'Mittenaar': 3018,
    'Mörfelden-Walldorf': 3018,
    'Mörlenbach': 3018,
    'Mühlheim am Main': 3018,
    'Münchhausen': 3018,
    'Münster': 3018,
    'Münzenberg': 3018,
    'Naumburg': 3018,
    'Neckarsteinach': 3018,
    'Nentershausen': 3018,
    'Neu Isenburg': 3018,
    'Neu-Anspach': 3018,
    'Neuental': 3018,
    'Neuhof': 3018,
    'Neukirchen': 3018,
    'Neustadt (Hessen)': 3018,
    'Nidda': 3018,
    'Nidderau': 3018,
    'Niedenstein': 3018,
    'Nieder-Gründau': 3018,
    'Niederaula': 3018,
    'Niederbiel': 3018,
    'Niederdorfelden': 3018,
    'Niederklein': 3018,
    'Niedernhausen': 3018,
    'Niederrad': 3018,
    'Ober-Mörlen': 3018,
    'Ober-Ramstadt': 3018,
    'Oberaula': 3018,
    'Oberbiel': 3018,
    'Obertshausen': 3018,
    'Oberursel': 3018,
    'Offenbach': 3018,
    'Ortenberg': 3018,
    'Ottrau': 3018,
    'Pfungstadt': 3018,
    'Philippsthal': 3018,
    'Poppenhausen': 3018,
    'Rabenau': 3018,
    'Ranstadt': 3018,
    'Rasdorf': 3018,
    'Raunheim': 3018,
    'Rauschenberg': 3018,
    'Regierungsbezirk Darmstadt': 3018,
    'Regierungsbezirk Gießen': 3018,
    'Regierungsbezirk Kassel': 3018,
    'Reichelsheim': 3018,
    'Reinhardshausen': 3018,
    'Reinheim': 3018,
    'Reiskirchen': 3018,
    'Riedstadt': 3018,
    'Rimbach': 3018,
    'Rockenberg': 3018,
    'Rodenbach': 3018,
    'Rodgau': 3018,
    'Romrod': 3018,
    'Ronshausen': 3018,
    'Rosbach vor der Höhe': 3018,
    'Rosenthal': 3018,
    'Roßdorf': 3018,
    'Rotenburg an der Fulda': 3018,
    'Rothenberg': 3018,
    'Rüdesheim am Rhein': 3018,
    'Runkel': 3018,
    'Rüsselsheim': 3018,
    'Sachsenhausen': 3018,
    'Schaafheim': 3018,
    'Schenklengsfeld': 3018,
    'Schlangenbad': 3018,
    'Schlitz': 3018,
    'Schlüchtern': 3018,
    'Schmitten': 3018,
    'Schöffengrund': 3018,
    'Schotten': 3018,
    'Schrecksbach': 3018,
    'Schwalbach': 3018,
    'Schwalbach am Taunus': 3018,
    'Schwalmstadt': 3018,
    'Schwalmtal': 3018,
    'Schwarzenborn': 3018,
    'Seeheim-Jugenheim': 3018,
    'Seligenstadt': 3018,
    'Selters': 3018,
    'Sinn': 3018,
    'Södel': 3018,
    'Solms': 3018,
    'Sontra': 3018,
    'Spangenberg': 3018,
    'Stadtallendorf': 3018,
    'Staufenberg': 3018,
    'Steeden': 3018,
    'Steinau an der Straße': 3018,
    'Steinbach am Taunus': 3018,
    'Stockstadt am Rhein': 3018,
    'Sulzbach': 3018,
    'Tann': 3018,
    'Taunusstein': 3018,
    'Trebur': 3018,
    'Trendelburg': 3018,
    'Udenhausen': 3018,
    'Ulrichstein': 3018,
    'Unter-Abtsteinach': 3018,
    'Usingen': 3018,
    'Vellmar': 3018,
    'Viernheim': 3018,
    'Villmar': 3018,
    'Vöhl': 3018,
    'Volkmarsen': 3018,
    'Wabern': 3018,
    'Wächtersbach': 3018,
    'Wald-Michelbach': 3018,
    'Waldbrunn': 3018,
    'Waldeck': 3018,
    'Waldems': 3018,
    'Waldkappel': 3018,
    'Walluf': 3018,
    'Wanfried': 3018,
    'Wehrheim': 3018,
    'Weilburg': 3018,
    'Weilmünster': 3018,
    'Weinbach': 3018,
    'Weiterstadt': 3018,
    'Wetter': 3018,
    'Wetzlar': 3018,
    'Wiesbaden': 3018,
    'Willingen': 3018,
    'Willingshausen': 3018,
    'Witzenhausen': 3018,
    'Wohnbach': 3018,
    'Wölfersheim': 3018,
    'Wolfhagen': 3018,
    'Zierenberg': 3018,
    'Zwingenberg': 3018,
    'Abbesbüttel': 3008,
    'Achim': 3008,
    'Adelebsen': 3008,
    'Adelheidsdorf': 3008,
    'Adenbüttel': 3008,
    'Adendorf': 3008,
    'Adenstedt': 3008,
    'Aerzen': 3008,
    'Agathenburg': 3008,
    'Ahausen': 3008,
    'Ahlden': 3008,
    'Ahlerstedt': 3008,
    'Ahnsbeck': 3008,
    'Ahnsen': 3008,
    'Ahsen-Oetzen': 3008,
    'Alfeld': 3008,
    'Alfhausen': 3008,
    'Algermissen': 3008,
    'Alt Wallmoden': 3008,
    'Altenau': 3008,
    'Altenmedingen': 3008,
    'Amelinghausen': 3008,
    'Ankum': 3008,
    'Apelern': 3008,
    'Apen': 3008,
    'Apensen': 3008,
    'Appel': 3008,
    'Arpke': 3008,
    'Artlenburg': 3008,
    'Asendorf': 3008,
    'Auf der Horst': 3008,
    'Auhagen': 3008,
    'Aurich': 3008,
    'Axstedt': 3008,
    'Bad Bentheim': 3008,
    'Bad Bevensen': 3008,
    'Bad Eilsen': 3008,
    'Bad Essen': 3008,
    'Bad Fallingbostel': 3008,
    'Bad Gandersheim': 3008,
    'Bad Grund': 3008,
    'Bad Harzburg': 3008,
    'Bad Iburg': 3008,
    'Bad Laer': 3008,
    'Bad Lauterberg im Harz': 3008,
    'Bad Münder am Deister': 3008,
    'Bad Nenndorf': 3008,
    'Bad Pyrmont': 3008,
    'Bad Rothenfelde': 3008,
    'Bad Sachsa': 3008,
    'Bad Salzdetfurth': 3008,
    'Bad Zwischenahn': 3008,
    'Badbergen': 3008,
    'Baddeckenstedt': 3008,
    'Badenhausen': 3008,
    'Bahrdorf': 3008,
    'Bahrenborstel': 3008,
    'Bakum': 3008,
    'Balge': 3008,
    'Balje': 3008,
    'Banteln': 3008,
    'Bardowick': 3008,
    'Barenburg': 3008,
    'Barendorf': 3008,
    'Bargstedt': 3008,
    'Barnstorf': 3008,
    'Barsinghausen': 3008,
    'Barßel': 3008,
    'Barum': 3008,
    'Barver': 3008,
    'Barwedel': 3008,
    'Basdahl': 3008,
    'Bassum': 3008,
    'Bawinkel': 3008,
    'Beckdorf': 3008,
    'Beckedorf': 3008,
    'Beedenbostel': 3008,
    'Beesten': 3008,
    'Bei der Höhne': 3008,
    'Belm': 3008,
    'Bendestorf': 3008,
    'Berge': 3008,
    'Bergen': 3008,
    'Bergen an der Dumme': 3008,
    'Bersenbrück': 3008,
    'Berumbur': 3008,
    'Betheln': 3008,
    'Betzendorf': 3008,
    'Bevenrode': 3008,
    'Bevern': 3008,
    'Beverstedt': 3008,
    'Bienenbüttel': 3008,
    'Bilshausen': 3008,
    'Binnen': 3008,
    'Bippen': 3008,
    'Bispingen': 3008,
    'Bissendorf': 3008,
    'Bleckede': 3008,
    'Blender': 3008,
    'Bliedersdorf': 3008,
    'Blomberg': 3008,
    'Bockenem': 3008,
    'Bockhorn': 3008,
    'Bockhorst': 3008,
    'Bodenfelde': 3008,
    'Bodenwerder': 3008,
    'Boffzen': 3008,
    'Bohmte': 3008,
    'Bokel': 3008,
    'Bomlitz': 3008,
    'Börger': 3008,
    'Borkum': 3008,
    'Börßum': 3008,
    'Borstel': 3008,
    'Bösel': 3008,
    'Bötersen': 3008,
    'Bothel': 3008,
    'Bovenden': 3008,
    'Brackel': 3008,
    'Brake (Unterweser)': 3008,
    'Bramsche': 3008,
    'Bramstedt': 3008,
    'Braunlage': 3008,
    'Braunschweig': 3008,
    'Breddorf': 3008,
    'Bremervörde': 3008,
    'Brietlingen': 3008,
    'Brockel': 3008,
    'Brockum': 3008,
    'Brome': 3008,
    'Bruchhausen-Vilsen': 3008,
    'Brüggen': 3008,
    'Buchholz': 3008,
    'Buchholz in der Nordheide': 3008,
    'Bückeburg': 3008,
    'Bücken': 3008,
    'Büddenstedt': 3008,
    'Bühren': 3008,
    'Bunde': 3008,
    'Burgdorf': 3008,
    'Butjadingen': 3008,
    'Buxtehude': 3008,
    'Cadenberge': 3008,
    'Calberlah': 3008,
    'Cappeln': 3008,
    'Celle': 3008,
    'Clausthal-Zellerfeld': 3008,
    'Clenze': 3008,
    'Cloppenburg': 3008,
    'Coppenbrügge': 3008,
    'Cremlingen': 3008,
    'Cuxhaven': 3008,
    'Dahlenburg': 3008,
    'Damme': 3008,
    'Danndorf': 3008,
    'Dannenberg': 3008,
    'Dassel': 3008,
    'Dedelstorf': 3008,
    'Deensen': 3008,
    'Deinste': 3008,
    'Delligsen': 3008,
    'Delmenhorst': 3008,
    'Dersum': 3008,
    'Destedt': 3008,
    'Detern': 3008,
    'Detmerode': 3008,
    'Dettum': 3008,
    'Deutsch Evern': 3008,
    'Didderse': 3008,
    'Diekholzen': 3008,
    'Diepholz': 3008,
    'Dinklage': 3008,
    'Dissen': 3008,
    'Dohren': 3008,
    'Dollbergen': 3008,
    'Dollern': 3008,
    'Dornum': 3008,
    'Dörpen': 3008,
    'Dorum': 3008,
    'Dörverden': 3008,
    'Dötlingen': 3008,
    'Drage': 3008,
    'Drakenburg': 3008,
    'Drangstedt': 3008,
    'Dransfeld': 3008,
    'Drentwede': 3008,
    'Drochtersen': 3008,
    'Duderstadt': 3008,
    'Duingen': 3008,
    'Dünsen': 3008,
    'Dunum': 3008,
    'Ebergötzen': 3008,
    'Ebersdorf': 3008,
    'Ebstorf': 3008,
    'Echem': 3008,
    'Edemissen': 3008,
    'Edewecht': 3008,
    'Egestorf': 3008,
    'Eggermühlen': 3008,
    'Ehrenburg': 3008,
    'Eicklingen': 3008,
    'Eime': 3008,
    'Eimen': 3008,
    'Eimke': 3008,
    'Einbeck': 3008,
    'Eisdorf am Harz': 3008,
    'Elbe': 3008,
    'Eldingen': 3008,
    'Elsdorf': 3008,
    'Elsfleth': 3008,
    'Elze': 3008,
    'Embsen': 3008,
    'Emden': 3008,
    'Emlichheim': 3008,
    'Emsbüren': 3008,
    'Emstek': 3008,
    'Emtinghausen': 3008,
    'Engeln': 3008,
    'Erkerode': 3008,
    'Eschede': 3008,
    'Eschershausen': 3008,
    'Esens': 3008,
    'Essel': 3008,
    'Essen': 3008,
    'Esterwegen': 3008,
    'Estorf': 3008,
    'Evessen': 3008,
    'Eydelstedt': 3008,
    'Eyendorf': 3008,
    'Eystrup': 3008,
    'Faßberg': 3008,
    'Fedderwarden': 3008,
    'Filsum': 3008,
    'Fintel': 3008,
    'Flöthe': 3008,
    'Freden': 3008,
    'Freiburg': 3008,
    'Freren': 3008,
    'Friedeburg': 3008,
    'Friedland': 3008,
    'Friesoythe': 3008,
    'Fürstenau': 3008,
    'Fürstenberg': 3008,
    'Ganderkesee': 3008,
    'Garbsen': 3008,
    'Garbsen-Mitte': 3008,
    'Garlstorf': 3008,
    'Garrel': 3008,
    'Garstedt': 3008,
    'Gartow': 3008,
    'Geeste': 3008,
    'Gehrde': 3008,
    'Gehrden': 3008,
    'Gemeinde Friedland': 3008,
    'Georgsdorf': 3008,
    'Georgsmarienhütte': 3008,
    'Gerdau': 3008,
    'Gersten': 3008,
    'Gieboldehausen': 3008,
    'Giesen': 3008,
    'Gifhorn': 3008,
    'Gilten': 3008,
    'Gittelde': 3008,
    'Glandorf': 3008,
    'Gnarrenburg': 3008,
    'Goldenstedt': 3008,
    'Golmbach': 3008,
    'Goslar': 3008,
    'Göttingen': 3008,
    'Grafhorst': 3008,
    'Grasberg': 3008,
    'Grasleben': 3008,
    'Gronau': 3008,
    'Groß Ippener': 3008,
    'Groß Munzel': 3008,
    'Groß Oesingen': 3008,
    'Groß Twülpstedt': 3008,
    'Großenkneten': 3008,
    'Großgoltern': 3008,
    'Grünendeich': 3008,
    'Guderhandviertel': 3008,
    'Gyhum': 3008,
    'Hage': 3008,
    'Hagen': 3008,
    'Hagen im Bremischen': 3008,
    'Hagenburg': 3008,
    'Halbemond': 3008,
    'Halle': 3008,
    'Hambergen': 3008,
    'Hambühren': 3008,
    'Hameln': 3008,
    'Hammah': 3008,
    'Handeloh': 3008,
    'Handorf': 3008,
    'Hänigsen': 3008,
    'Hankensbüttel': 3008,
    'Hannover': 3008,
    'Hannoversch Münden': 3008,
    'Hanstedt': 3008,
    'Hanstedt Eins': 3008,
    'Hardegsen': 3008,
    'Haren': 3008,
    'Harpstedt': 3008,
    'Harsefeld': 3008,
    'Harsum': 3008,
    'Hasbergen': 3008,
    'Haselünne': 3008,
    'Haßbergen': 3008,
    'Hassel': 3008,
    'Hassendorf': 3008,
    'Haste': 3008,
    'Hatten': 3008,
    'Hattorf': 3008,
    'Haverlah': 3008,
    'Hechthausen': 3008,
    'Heede': 3008,
    'Heemsen': 3008,
    'Heere': 3008,
    'Heeslingen': 3008,
    'Heeßen': 3008,
    'Hehlen': 3008,
    'Heidenau': 3008,
    'Heinade': 3008,
    'Heinböckel': 3008,
    'Heinsen': 3008,
    'Hellwege': 3008,
    'Helmstedt': 3008,
    'Helpsen': 3008,
    'Hemmingen': 3008,
    'Hemmoor': 3008,
    'Hemsbünde': 3008,
    'Hemslingen': 3008,
    'Hepstedt': 3008,
    'Hermannsburg': 3008,
    'Herzberg am Harz': 3008,
    'Herzlake': 3008,
    'Hesel': 3008,
    'Hespe': 3008,
    'Hessisch Oldendorf': 3008,
    'Heuerßen': 3008,
    'Heyersum': 3008,
    'Hildesheim': 3008,
    'Hilgermissen': 3008,
    'Hillerse': 3008,
    'Hilter': 3008,
    'Himbergen': 3008,
    'Himmelpforten': 3008,
    'Hinte': 3008,
    'Hipstedt': 3008,
    'Hitzacker': 3008,
    'Hodenhagen': 3008,
    'Hohenhameln': 3008,
    'Hohnhorst': 3008,
    'Holdorf': 3008,
    'Holle': 3008,
    'Hollenstedt': 3008,
    'Holtland': 3008,
    'Holzminden': 3008,
    'Hoogstede': 3008,
    'Hörden': 3008,
    'Hornburg': 3008,
    'Horneburg': 3008,
    'Horstedt': 3008,
    'Hoya': 3008,
    'Hude': 3008,
    'Huede': 3008,
    'Hülsede': 3008,
    'Husum': 3008,
    'Ihlienworth': 3008,
    'Ilsede': 3008,
    'Isenbüttel': 3008,
    'Isernhagen Farster Bauerschaft': 3008,
    'Itterbeck': 3008,
    'Jameln': 3008,
    'Jembke': 3008,
    'Jemgum': 3008,
    'Jerxheim': 3008,
    'Jesteburg': 3008,
    'Jever': 3008,
    'Jork': 3008,
    'Jühnde': 3008,
    'Juist': 3008,
    'Kakenstorf': 3008,
    'Kalefeld': 3008,
    'Katlenburg-Lindau': 3008,
    'Kettenkamp': 3008,
    'Kirchbrak': 3008,
    'Kirchdorf': 3008,
    'Kirchgellersen': 3008,
    'Kirchlinteln': 3008,
    'Kirchseelte': 3008,
    'Kirchtimke': 3008,
    'Kirchwalsede': 3008,
    'Kissenbrück': 3008,
    'Klein Berßen': 3008,
    'Klein Gusborn': 3008,
    'Klein Schwülper': 3008,
    'Kluse': 3008,
    'Königslutter am Elm': 3008,
    'Krebeck': 3008,
    'Kreiensen': 3008,
    'Kührstedt': 3008,
    'Küsten': 3008,
    'Kutenholz': 3008,
    'Laar': 3008,
    'Laatzen': 3008,
    'Lachendorf': 3008,
    'Lähden': 3008,
    'Lamspringe': 3008,
    'Lamstedt': 3008,
    'Landesbergen': 3008,
    'Landolfshausen': 3008,
    'Langelsheim': 3008,
    'Langen': 3008,
    'Langenhagen': 3008,
    'Langeoog': 3008,
    'Langlingen': 3008,
    'Langwedel': 3008,
    'Lastrup': 3008,
    'Lathen': 3008,
    'Lauenau': 3008,
    'Lauenbrück': 3008,
    'Lauenhagen': 3008,
    'Leer': 3008,
    'Leese': 3008,
    'Leezdorf': 3008,
    'Lehre': 3008,
    'Lehrte': 3008,
    'Leiferde': 3008,
    'Lemförde': 3008,
    'Lemwerder': 3008,
    'Lengede': 3008,
    'Lengerich': 3008,
    'Liebenau': 3008,
    'Liebenburg': 3008,
    'Lilienthal': 3008,
    'Lindhorst': 3008,
    'Lindwedel': 3008,
    'Lingen': 3008,
    'Lintig': 3008,
    'Lohne': 3008,
    'Löningen': 3008,
    'Lorup': 3008,
    'Loxstedt': 3008,
    'Lüchow': 3008,
    'Lüder': 3008,
    'Lüdersfeld': 3008,
    'Luhden': 3008,
    'Lüneburg': 3008,
    'Lunestedt': 3008,
    'Lünne': 3008,
    'Lutter am Barenberge': 3008,
    'Marienhafe': 3008,
    'Mariental': 3008,
    'Marklohe': 3008,
    'Marschacht': 3008,
    'Martfeld': 3008,
    'Marxen': 3008,
    'Meerbeck': 3008,
    'Meerdorf': 3008,
    'Meinersen': 3008,
    'Melbeck': 3008,
    'Melle': 3008,
    'Mellinghausen': 3008,
    'Menslage': 3008,
    'Meppen': 3008,
    'Merzen': 3008,
    'Messingen': 3008,
    'Midlum': 3008,
    'Mittegroßefehn': 3008,
    'Moisburg': 3008,
    'Molbergen': 3008,
    'Moringen': 3008,
    'Morsum': 3008,
    'Müden': 3008,
    'Munster': 3008,
    'Nahrendorf': 3008,
    'Neu Darchau': 3008,
    'Neu Wulmstorf': 3008,
    'Neubörger': 3008,
    'Neubrück': 3008,
    'Neuenkirchen': 3008,
    'Neuharlingersiel': 3008,
    'Neuhaus an der Oste': 3008,
    'Neukamperfehn': 3008,
    'Neustadt am Rübenberge': 3008,
    'Niederlangen': 3008,
    'Niedernwöhren': 3008,
    'Nienburg': 3008,
    'Nienstädt': 3008,
    'Norddeich': 3008,
    'Norden': 3008,
    'Nordenham': 3008,
    'Norderney': 3008,
    'Nordholz': 3008,
    'Nordhorn': 3008,
    'Nordleda': 3008,
    'Nordstemmen': 3008,
    'Nörten-Hardenberg': 3008,
    'Northeim': 3008,
    'Nortmoor': 3008,
    'Nortrup': 3008,
    'Nottensdorf': 3008,
    'Oberndorf': 3008,
    'Obernfeld': 3008,
    'Obernkirchen': 3008,
    'Oederquart': 3008,
    'Oerel': 3008,
    'Oldenburg': 3008,
    'Oldendorf': 3008,
    'Osloß': 3008,
    'Osnabrück': 3008,
    'Osteel': 3008,
    'Osten': 3008,
    'Ostercappeln': 3008,
    'Osterholz-Scharmbeck': 3008,
    'Osterode am Harz': 3008,
    'Ostrhauderfehn': 3008,
    'Ottenstein': 3008,
    'Otter': 3008,
    'Otterndorf': 3008,
    'Ottersberg': 3008,
    'Ovelgönne': 3008,
    'Oyten': 3008,
    'Papenburg': 3008,
    'Parsau': 3008,
    'Pattensen': 3008,
    'Peine': 3008,
    'Pennigsehl': 3008,
    'Polle': 3008,
    'Pollhagen': 3008,
    'Quakenbrück': 3008,
    'Radbruch': 3008,
    'Raddestorf': 3008,
    'Rastede': 3008,
    'Rechtsupweg': 3008,
    'Reeßum': 3008,
    'Regesbostel': 3008,
    'Rehburg-Loccum': 3008,
    'Rehden': 3008,
    'Reinstorf': 3008,
    'Remlingen': 3008,
    'Reppenstedt': 3008,
    'Rethem': 3008,
    'Rethen': 3008,
    'Rhade': 3008,
    'Rhede': 3008,
    'Rheden': 3008,
    'Rhumspringe': 3008,
    'Ribbesbüttel': 3008,
    'Riede': 3008,
    'Rieste': 3008,
    'Rinteln': 3008,
    'Ritterhude': 3008,
    'Rodenberg': 3008,
    'Röhrsen': 3008,
    'Ronnenberg': 3008,
    'Rosche': 3008,
    'Rosdorf': 3008,
    'Rotenburg': 3008,
    'Rötgesbüttel': 3008,
    'Rühen': 3008,
    'Rullstorf': 3008,
    'Sachsenhagen': 3008,
    'Salzbergen': 3008,
    'Salzgitter': 3008,
    'Salzhausen': 3008,
    'Salzhemmendorf': 3008,
    'Sande': 3008,
    'Sandstedt': 3008,
    'Sankt Andreasberg': 3008,
    'Sarstedt': 3008,
    'Sassenburg': 3008,
    'Sauensiek': 3008,
    'Schapen': 3008,
    'Scharnebeck': 3008,
    'Scheden': 3008,
    'Scheeßel': 3008,
    'Schellerten': 3008,
    'Schiffdorf': 3008,
    'Schillig': 3008,
    'Schladen': 3008,
    'Schnega': 3008,
    'Schneverdingen': 3008,
    'Schöningen': 3008,
    'Schöppenstedt': 3008,
    'Schortens': 3008,
    'Schüttorf': 3008,
    'Schwaförden': 3008,
    'Schwanewede': 3008,
    'Schwarme': 3008,
    'Schwarmstedt': 3008,
    'Seeburg': 3008,
    'Seelze': 3008,
    'Seesen': 3008,
    'Seevetal': 3008,
    'Seggebruch': 3008,
    'Sehlde': 3008,
    'Sehlem': 3008,
    'Sehnde': 3008,
    'Selsingen': 3008,
    'Seulingen': 3008,
    'Sibbesse': 3008,
    'Sickte': 3008,
    'Siedenburg': 3008,
    'Sillenstede': 3008,
    'Sittensen': 3008,
    'Soderstorf': 3008,
    'Sögel': 3008,
    'Söhlde': 3008,
    'Soltau': 3008,
    'Soltendieck': 3008,
    'Sottrum': 3008,
    'Spelle': 3008,
    'Sprakensehl': 3008,
    'Springe': 3008,
    'Stade': 3008,
    'Stadensen': 3008,
    'Stadthagen': 3008,
    'Stadtoldendorf': 3008,
    'Stedesdorf': 3008,
    'Steimbke': 3008,
    'Steinfeld': 3008,
    'Steinhorst': 3008,
    'Steinkirchen': 3008,
    'Stelle': 3008,
    'Steyerberg': 3008,
    'Stöckse': 3008,
    'Stolzenau': 3008,
    'Stuhr': 3008,
    'Suddendorf': 3008,
    'Suderburg': 3008,
    'Südergellersen': 3008,
    'Sudwalde': 3008,
    'Suhlendorf': 3008,
    'Sulingen': 3008,
    'Süpplingen': 3008,
    'Surwold': 3008,
    'Süstedt': 3008,
    'Sustrum': 3008,
    'Syke': 3008,
    'Tappenbeck': 3008,
    'Tarmstedt': 3008,
    'Tespe': 3008,
    'Thedinghausen': 3008,
    'Thomasburg': 3008,
    'Thuine': 3008,
    'Tiddische': 3008,
    'Toppenstedt': 3008,
    'Tostedt': 3008,
    'Tülau': 3008,
    'Twistringen': 3008,
    'Uchte': 3008,
    'Uehrde': 3008,
    'Uelsen': 3008,
    'Uelzen': 3008,
    'Uetze': 3008,
    'Ummern': 3008,
    'Unterlüß': 3008,
    'Upgant-Schott': 3008,
    'Uslar': 3008,
    'Uthlede': 3008,
    'Varel': 3008,
    'Varrel': 3008,
    'Vechelde': 3008,
    'Vechta': 3008,
    'Velpke': 3008,
    'Veltheim': 3008,
    'Verden': 3008,
    'Vienenburg': 3008,
    'Visbek': 3008,
    'Visselhövede': 3008,
    'Vögelsen': 3008,
    'Vollersode': 3008,
    'Voltlage': 3008,
    'Vordorf': 3008,
    'Vorwerk': 3008,
    'Vrees': 3008,
    'Waake': 3008,
    'Wagenfeld': 3008,
    'Wagenhoff': 3008,
    'Wahrenholz': 3008,
    'Walchum': 3008,
    'Walkenried': 3008,
    'Wallenhorst': 3008,
    'Walsrode': 3008,
    'Wangerooge': 3008,
    'Wanna': 3008,
    'Wardenburg': 3008,
    'Warmsen': 3008,
    'Wasbüttel': 3008,
    'Wathlingen': 3008,
    'Weener': 3008,
    'Welle': 3008,
    'Wendeburg': 3008,
    'Wendisch Evern': 3008,
    'Wennigsen': 3008,
    'Wenzendorf': 3008,
    'Werlte': 3008,
    'Werpeloh': 3008,
    'Wesendorf': 3008,
    'Weste': 3008,
    'Westergellersen': 3008,
    'Westerholt': 3008,
    'Westerstede': 3008,
    'Westhagen': 3008,
    'Wetschen': 3008,
    'Weyhausen': 3008,
    'Wieda': 3008,
    'Wiedensahl': 3008,
    'Wiefelstede': 3008,
    'Wienhausen': 3008,
    'Wieren': 3008,
    'Wiesmoor': 3008,
    'Wietmarschen': 3008,
    'Wietze': 3008,
    'Wietzen': 3008,
    'Wietzendorf': 3008,
    'Wildemann': 3008,
    'Wildeshausen': 3008,
    'Wilhelmshaven': 3008,
    'Wilstedt': 3008,
    'Wilsum': 3008,
    'Windhausen': 3008,
    'Wingst': 3008,
    'Winsen': 3008,
    'Wirdum': 3008,
    'Wischhafen': 3008,
    'Wistedt': 3008,
    'Wittingen': 3008,
    'Wittmar': 3008,
    'Wittmund': 3008,
    'Wittorf': 3008,
    'Wolfenbüttel': 3008,
    'Wolfsburg': 3008,
    'Wölpinghausen': 3008,
    'Wolsdorf': 3008,
    'Woltersdorf': 3008,
    'Worpswede': 3008,
    'Wremen': 3008,
    'Wrestedt': 3008,
    'Wriedel': 3008,
    'Wulfsen': 3008,
    'Wulften': 3008,
    'Wulsbüttel': 3008,
    'Wunstorf': 3008,
    'Wustrow': 3008,
    'Zernien': 3008,
    'Zetel': 3008,
    'Zeven': 3008,
    'Zorge': 3008,
    'Admannshagen-Bargeshagen': 3007,
    'Ahlbeck': 3007,
    'Alt Meteln': 3007,
    'Alt-Sanitz': 3007,
    'Altefähr': 3007,
    'Altenkirchen': 3007,
    'Altenpleen': 3007,
    'Altentreptow': 3007,
    'Altstadt': 3007,
    'Anklam': 3007,
    'Bad Doberan': 3007,
    'Bad Kleinen': 3007,
    'Bad Sülze': 3007,
    'Banzkow': 3007,
    'Bartenshagen-Parkentin': 3007,
    'Barth': 3007,
    'Bastorf': 3007,
    'Bentwisch': 3007,
    'Bentzin': 3007,
    'Bergen auf Rügen': 3007,
    'Bernitt': 3007,
    'Biendorf': 3007,
    'Blankensee': 3007,
    'Blowatz': 3007,
    'Bobitz': 3007,
    'Boizenburg': 3007,
    'Börgerende-Rethwisch': 3007,
    'Born': 3007,
    'Borrentin': 3007,
    'Brandshagen': 3007,
    'Broderstorf': 3007,
    'Brüel': 3007,
    'Brunn': 3007,
    'Brüsewitz': 3007,
    'Burg Stargard': 3007,
    'Burow': 3007,
    'Bützow': 3007,
    'Carlow': 3007,
    'Carpin': 3007,
    'Crivitz': 3007,
    'Dabel': 3007,
    'Dargun': 3007,
    'Dassow': 3007,
    'Demen': 3007,
    'Demmin': 3007,
    'Dersekow': 3007,
    'Dierkow-Neu': 3007,
    'Dierkow-West': 3007,
    'Dobbertin': 3007,
    'Dömitz': 3007,
    'Domsühl': 3007,
    'Dranske': 3007,
    'Ducherow': 3007,
    'Dümmer': 3007,
    'Dummerstorf': 3007,
    'Eggesin': 3007,
    'Eldena': 3007,
    'Elmenhorst': 3007,
    'Feldstadt': 3007,
    'Ferdinandshof': 3007,
    'Franzburg': 3007,
    'Friedland': 3007,
    'Gadebusch': 3007,
    'Gägelow': 3007,
    'Garz': 3007,
    'Gelbensande': 3007,
    'Gielow': 3007,
    'Gingst': 3007,
    'Glowe': 3007,
    'Gnoien': 3007,
    'Goldberg': 3007,
    'Görmin': 3007,
    'Grabow': 3007,
    'Grabowhöfe': 3007,
    'Gramkow': 3007,
    'Greifswald': 3007,
    'Grevesmühlen': 3007,
    'Grimmen': 3007,
    'Groß Kiesow': 3007,
    'Groß Laasch': 3007,
    'Groß Miltzow': 3007,
    'Groß Nemerow': 3007,
    'Groß Wokern': 3007,
    'Güstrow': 3007,
    'Gützkow': 3007,
    'Hagenow': 3007,
    'Hiddensee': 3007,
    'Hornstorf': 3007,
    'Jarmen': 3007,
    'Jatznick': 3007,
    'Jördenstorf': 3007,
    'Jürgenshagen': 3007,
    'Kalkhorst': 3007,
    'Karlshagen': 3007,
    'Kavelstorf': 3007,
    'Kemnitz': 3007,
    'Kessin': 3007,
    'Klein Rogahn': 3007,
    'Klink': 3007,
    'Klütz': 3007,
    'Koserow': 3007,
    'Krakow am See': 3007,
    'Kramerhof': 3007,
    'Kritzmow': 3007,
    'Kröpelin': 3007,
    'Kröslin': 3007,
    'Laage': 3007,
    'Lalendorf': 3007,
    'Lambrechtshagen': 3007,
    'Lankow': 3007,
    'Lassan': 3007,
    'Leezen': 3007,
    'Lewenberg': 3007,
    'Löcknitz': 3007,
    'Loddin': 3007,
    'Loitz': 3007,
    'Lubmin': 3007,
    'Lübow': 3007,
    'Lübstorf': 3007,
    'Lübtheen': 3007,
    'Lübz': 3007,
    'Lüdersdorf': 3007,
    'Ludwigslust': 3007,
    'Lützow': 3007,
    'Malchin': 3007,
    'Malchow': 3007,
    'Malliß': 3007,
    'Marlow': 3007,
    'Mecklenburg': 3007,
    'Mesekenhagen': 3007,
    'Mirow': 3007,
    'Möllenhagen': 3007,
    'Mönchhagen': 3007,
    'Mühl Rosin': 3007,
    'Mühlen Eichsen': 3007,
    'Neu Kaliß': 3007,
    'Neubrandenburg': 3007,
    'Neubukow': 3007,
    'Neuburg': 3007,
    'Neuenkirchen': 3007,
    'Neukalen': 3007,
    'Neukloster': 3007,
    'Neumühle': 3007,
    'Neustadt-Glewe': 3007,
    'Neustrelitz': 3007,
    'Neverin': 3007,
    'Nienhagen': 3007,
    'Niepars': 3007,
    'Nostorf': 3007,
    'Ostseebad Binz': 3007,
    'Ostseebad Boltenhagen': 3007,
    'Ostseebad Dierhagen': 3007,
    'Ostseebad Göhren': 3007,
    'Ostseebad Kühlungsborn': 3007,
    'Ostseebad Prerow': 3007,
    'Ostseebad Sellin': 3007,
    'Ostseebad Zinnowitz': 3007,
    'Pampow': 3007,
    'Papendorf': 3007,
    'Parchim': 3007,
    'Pasewalk': 3007,
    'Paulsstadt': 3007,
    'Penkun': 3007,
    'Penzlin': 3007,
    'Pinnow': 3007,
    'Plate': 3007,
    'Plau am See': 3007,
    'Poseritz': 3007,
    'Preetz': 3007,
    'Prohn': 3007,
    'Putbus': 3007,
    'Raben Steinfeld': 3007,
    'Rambin': 3007,
    'Rastow': 3007,
    'Rechlin': 3007,
    'Rehna': 3007,
    'Reinberg': 3007,
    'Retgendorf': 3007,
    'Retschow': 3007,
    'Ribnitz-Damgarten': 3007,
    'Richtenberg': 3007,
    'Röbel': 3007,
    'Roggendorf': 3007,
    'Roggentin': 3007,
    'Rosenow': 3007,
    'Rostock': 3007,
    'Rövershagen': 3007,
    'Saal': 3007,
    'Sagard': 3007,
    'Samtens': 3007,
    'Saßnitz': 3007,
    'Satow-Oberhagen': 3007,
    'Schelfstadt': 3007,
    'Schlagsdorf': 3007,
    'Schwaan': 3007,
    'Schwerin': 3007,
    'Seebad Bansin': 3007,
    'Seebad Heringsdorf': 3007,
    'Seeheilbad Graal-Müritz': 3007,
    'Seehof': 3007,
    'Sehlen': 3007,
    'Sellin': 3007,
    'Selmsdorf': 3007,
    'Siggelkow': 3007,
    'Spornitz': 3007,
    'Stäbelow': 3007,
    'Steinhagen': 3007,
    'Sternberg': 3007,
    'Stralendorf': 3007,
    'Stralsund': 3007,
    'Strasburg': 3007,
    'Sukow': 3007,
    'Sülstorf': 3007,
    'Tarnow': 3007,
    'Tessin': 3007,
    'Teterow': 3007,
    'Torgelow': 3007,
    'Tribsees': 3007,
    'Trinwillershagen': 3007,
    'Trollenhagen': 3007,
    'Tutow': 3007,
    'Ueckermünde': 3007,
    'Usedom': 3007,
    'Velgast': 3007,
    'Viereck': 3007,
    'Wackerow': 3007,
    'Wardow': 3007,
    'Waren': 3007,
    'Warin': 3007,
    'Warnemünde': 3007,
    'Warnow': 3007,
    'Wattmannshagen': 3007,
    'Weitenhagen': 3007,
    'Wendorf': 3007,
    'Werdervorstadt': 3007,
    'Wesenberg': 3007,
    'Weststadt': 3007,
    'Wiek': 3007,
    'Wismar': 3007,
    'Wittenburg': 3007,
    'Wittenförden': 3007,
    'Wittenhagen': 3007,
    'Woldegk': 3007,
    'Wolgast': 3007,
    'Wulkenzin': 3007,
    'Wusterhusen': 3007,
    'Wustrow': 3007,
    'Zarrendorf': 3007,
    'Zarrentin': 3007,
    'Ziesendorf': 3007,
    'Zingst': 3007,
    'Zurow': 3007,
    'Züssow': 3007,
    'Aachen': 3017,
    'Ahaus': 3017,
    'Ahlen': 3017,
    'Aldenhoven': 3017,
    'Alfter': 3017,
    'Alpen': 3017,
    'Alsdorf': 3017,
    'Altena': 3017,
    'Altenbeken': 3017,
    'Altenberge': 3017,
    'Altenbüren': 3017,
    'Altstadt Nord': 3017,
    'Altstadt Sud': 3017,
    'Anröchte': 3017,
    'Arnsberg': 3017,
    'Ascheberg': 3017,
    'Attendorn': 3017,
    'Augustdorf': 3017,
    'Bad Berleburg': 3017,
    'Bad Driburg': 3017,
    'Bad Fredeburg': 3017,
    'Bad Holzhausen': 3017,
    'Bad Honnef': 3017,
    'Bad Laasphe': 3017,
    'Bad Lippspringe': 3017,
    'Bad Meinberg': 3017,
    'Bad Münstereifel': 3017,
    'Bad Oeynhausen': 3017,
    'Bad Salzuflen': 3017,
    'Bad Sassendorf': 3017,
    'Baesweiler': 3017,
    'Balve': 3017,
    'Bamenohl': 3017,
    'Barntrup': 3017,
    'Bayenthal': 3017,
    'Beckum': 3017,
    'Bedburg': 3017,
    'Beelen': 3017,
    'Bergheim': 3017,
    'Bergisch Gladbach': 3017,
    'Bergkamen': 3017,
    'Bergneustadt': 3017,
    'Bestwig': 3017,
    'Beverungen': 3017,
    'Bielefeld': 3017,
    'Bilderstoeckchen': 3017,
    'Billerbeck': 3017,
    'Birgte': 3017,
    'Blankenheim': 3017,
    'Blomberg': 3017,
    'Bocholt': 3017,
    'Bochum': 3017,
    'Bochum-Hordel': 3017,
    'Bönen': 3017,
    'Bonn': 3017,
    'Borgentreich': 3017,
    'Borgholzhausen': 3017,
    'Borken': 3017,
    'Bornheim': 3017,
    'Bottrop': 3017,
    'Brakel': 3017,
    'Breckerfeld': 3017,
    'Brilon': 3017,
    'Brüggen': 3017,
    'Brühl': 3017,
    'Buchforst': 3017,
    'Buchheim': 3017,
    'Bünde': 3017,
    'Burbach': 3017,
    'Büren': 3017,
    'Burscheid': 3017,
    'Castrop-Rauxel': 3017,
    'Coesfeld': 3017,
    'Dahlem': 3017,
    'Datteln': 3017,
    'Delbrück': 3017,
    'Detmold': 3017,
    'Deutz': 3017,
    'Dinslaken': 3017,
    'Dörentrup': 3017,
    'Dormagen': 3017,
    'Dorsten': 3017,
    'Dortmund': 3017,
    'Dreierwalde': 3017,
    'Drensteinfurt': 3017,
    'Drolshagen': 3017,
    'Duisburg': 3017,
    'Dülmen': 3017,
    'Düren': 3017,
    'Düsseldorf': 3017,
    'Düsseldorf District': 3017,
    'Düsseldorf-Pempelfort': 3017,
    'Eil': 3017,
    'Eitorf': 3017,
    'Elsdorf': 3017,
    'Emmerich': 3017,
    'Emsdetten': 3017,
    'Engelskirchen': 3017,
    'Enger': 3017,
    'Ennepetal': 3017,
    'Ennigerloh': 3017,
    'Erftstadt': 3017,
    'Erkelenz': 3017,
    'Erkrath': 3017,
    'Erndtebrück': 3017,
    'Erwitte': 3017,
    'Eschweiler': 3017,
    'Eslohe': 3017,
    'Espelkamp': 3017,
    'Essen': 3017,
    'Euskirchen': 3017,
    'Everswinkel': 3017,
    'Finnentrop': 3017,
    'Frechen': 3017,
    'Freudenberg': 3017,
    'Fröndenberg': 3017,
    'Gangelt': 3017,
    'Geilenkirchen': 3017,
    'Geldern': 3017,
    'Gelsenkirchen': 3017,
    'Gescher': 3017,
    'Geseke': 3017,
    'Gevelsberg': 3017,
    'Gladbeck': 3017,
    'Goch': 3017,
    'Grefrath': 3017,
    'Gremberghoven': 3017,
    'Greven': 3017,
    'Grevenbroich': 3017,
    'Gronau': 3017,
    'Gummersbach': 3017,
    'Gütersloh': 3017,
    'Haan': 3017,
    'Hagen': 3017,
    'Halle': 3017,
    'Hallenberg': 3017,
    'Haltern am See': 3017,
    'Halver': 3017,
    'Hamm': 3017,
    'Hamminkeln': 3017,
    'Harsewinkel': 3017,
    'Hattingen': 3017,
    'Havixbeck': 3017,
    'Heek': 3017,
    'Heiden': 3017,
    'Heiligenhaus': 3017,
    'Heimbach': 3017,
    'Heinsberg': 3017,
    'Hellenthal': 3017,
    'Hemer': 3017,
    'Hennef': 3017,
    'Herdecke': 3017,
    'Herford': 3017,
    'Herne': 3017,
    'Herscheid': 3017,
    'Herten': 3017,
    'Herzogenrath': 3017,
    'Hiddenhausen': 3017,
    'Hilchenbach': 3017,
    'Hilden': 3017,
    'Hille': 3017,
    'Hochfeld': 3017,
    'Höhenberg': 3017,
    'Holzwickede': 3017,
    'Hordel': 3017,
    'Horn': 3017,
    'Hörstel': 3017,
    'Horstmar': 3017,
    'Hövelhof': 3017,
    'Höxter': 3017,
    'Hückelhoven': 3017,
    'Hückeswagen': 3017,
    'Hüllhorst': 3017,
    'Humboldtkolonie': 3017,
    'Hünxe': 3017,
    'Hürtgenwald': 3017,
    'Hürth': 3017,
    'Ibbenbüren': 3017,
    'Inden': 3017,
    'Iserlohn': 3017,
    'Isselburg': 3017,
    'Issum': 3017,
    'Jüchen': 3017,
    'Jülich': 3017,
    'Kaarst': 3017,
    'Kalk': 3017,
    'Kalkar': 3017,
    'Kall': 3017,
    'Kamen': 3017,
    'Kamp-Lintfort': 3017,
    'Kempen': 3017,
    'Kerpen': 3017,
    'Kevelaer': 3017,
    'Kierspe': 3017,
    'Kirchhundem': 3017,
    'Kirchlengern': 3017,
    'Klein Reken': 3017,
    'Kleve': 3017,
    'Köln': 3017,
    'Königswinter': 3017,
    'Korschenbroich': 3017,
    'Kranenburg': 3017,
    'Krefeld': 3017,
    'Kreuzau': 3017,
    'Kreuztal': 3017,
    'Kürten': 3017,
    'Ladbergen': 3017,
    'Laer': 3017,
    'Lage': 3017,
    'Langenberg': 3017,
    'Langenfeld': 3017,
    'Langerwehe': 3017,
    'Lanstrop': 3017,
    'Legden': 3017,
    'Leichlingen': 3017,
    'Lemgo': 3017,
    'Lengerich': 3017,
    'Lennestadt': 3017,
    'Leopoldshöhe': 3017,
    'Leverkusen': 3017,
    'Lichtenau': 3017,
    'Lienen': 3017,
    'Lindlar': 3017,
    'Linnich': 3017,
    'Lippstadt': 3017,
    'Lohmar': 3017,
    'Löhne': 3017,
    'Lotte': 3017,
    'Lübbecke': 3017,
    'Lüdenscheid': 3017,
    'Lüdinghausen': 3017,
    'Lügde': 3017,
    'Lünen': 3017,
    'Marienheide': 3017,
    'Marl': 3017,
    'Marsberg': 3017,
    'Mechernich': 3017,
    'Meckenheim': 3017,
    'Medebach': 3017,
    'Meerbusch': 3017,
    'Mehrhoog': 3017,
    'Meiderich': 3017,
    'Meinerzhagen': 3017,
    'Menden': 3017,
    'Mengenich': 3017,
    'Meschede': 3017,
    'Metelen': 3017,
    'Mettingen': 3017,
    'Mettmann': 3017,
    'Minden': 3017,
    'Moers': 3017,
    'Möhnesee': 3017,
    'Mönchengladbach': 3017,
    'Monheim am Rhein': 3017,
    'Monschau': 3017,
    'Morsbach': 3017,
    'Much': 3017,
    'Mülheim': 3017,
    'Münster': 3017,
    'Nachrodt-Wiblingwerde': 3017,
    'Netphen': 3017,
    'Nettersheim': 3017,
    'Nettetal': 3017,
    'Neu-Pattern': 3017,
    'Neubrück': 3017,
    'Neuehrenfeld': 3017,
    'Neuenkirchen': 3017,
    'Neuenrade': 3017,
    'Neunkirchen': 3017,
    'Neuss': 3017,
    'Neustadt/Nord': 3017,
    'Neustadt/Süd': 3017,
    'Nideggen': 3017,
    'Niederkassel': 3017,
    'Niederkrüchten': 3017,
    'Niedermerz': 3017,
    'Niederzier': 3017,
    'Nieheim': 3017,
    'Nippes': 3017,
    'Nordkirchen': 3017,
    'Nordwalde': 3017,
    'Nörvenich': 3017,
    'Nottuln': 3017,
    'Nümbrecht': 3017,
    'Oberhausen': 3017,
    'Obernbeck': 3017,
    'Ochtrup': 3017,
    'Odenthal': 3017,
    'Oelde': 3017,
    'Oer-Erkenschwick': 3017,
    'Oerlinghausen': 3017,
    'Olfen': 3017,
    'Olpe': 3017,
    'Olsberg': 3017,
    'Opladen': 3017,
    'Ossendorf': 3017,
    'Ostbevern': 3017,
    'Ostheim': 3017,
    'Overath': 3017,
    'Paderborn': 3017,
    'Petershagen': 3017,
    'Plettenberg': 3017,
    'Poll': 3017,
    'Porta Westfalica': 3017,
    'Porz am Rhein': 3017,
    'Preußisch Oldendorf': 3017,
    'Pulheim': 3017,
    'Radevormwald': 3017,
    'Raesfeld': 3017,
    'Rahden': 3017,
    'Rath': 3017,
    'Ratingen': 3017,
    'Recke': 3017,
    'Recklinghausen': 3017,
    'Rees': 3017,
    'Regierungsbezirk Arnsberg': 3017,
    'Regierungsbezirk Detmold': 3017,
    'Regierungsbezirk Köln': 3017,
    'Regierungsbezirk Münster': 3017,
    'Remscheid': 3017,
    'Rheda-Wiedenbrück': 3017,
    'Rhede': 3017,
    'Rheinbach': 3017,
    'Rheinberg': 3017,
    'Rheine': 3017,
    'Rheurdt': 3017,
    'Riehl': 3017,
    'Rietberg': 3017,
    'Rödinghausen': 3017,
    'Roetgen': 3017,
    'Rommerskirchen': 3017,
    'Rösrath': 3017,
    'Ruppichteroth': 3017,
    'Rüthen': 3017,
    'Saerbeck': 3017,
    'Salzkotten': 3017,
    'Sankt Augustin': 3017,
    'Sassenberg': 3017,
    'Schalksmühle': 3017,
    'Schermbeck': 3017,
    'Schieder-Schwalenberg': 3017,
    'Schlangen': 3017,
    'Schleiden': 3017,
    'Schmallenberg': 3017,
    'Schöppingen': 3017,
    'Schwalmtal': 3017,
    'Schwelm': 3017,
    'Schwerte': 3017,
    'Selm': 3017,
    'Senden': 3017,
    'Sendenhorst': 3017,
    'Siegburg': 3017,
    'Siegen': 3017,
    'Siersdorf': 3017,
    'Simmerath': 3017,
    'Sinnersdorf': 3017,
    'Soest': 3017,
    'Solingen': 3017,
    'Sonsbeck': 3017,
    'Spenge': 3017,
    'Sprockhövel': 3017,
    'Stadtlohn': 3017,
    'Steinfurt': 3017,
    'Steinhagen': 3017,
    'Steinheim': 3017,
    'Stolberg': 3017,
    'Stoßdorf': 3017,
    'Straelen': 3017,
    'Südlohn': 3017,
    'Sundern': 3017,
    'Tecklenburg': 3017,
    'Telgte': 3017,
    'Titz': 3017,
    'Tönisvorst': 3017,
    'Troisdorf': 3017,
    'Türnich': 3017,
    'Übach-Palenberg': 3017,
    'Uedem': 3017,
    'Unna': 3017,
    'Velbert': 3017,
    'Velen': 3017,
    'Verl': 3017,
    'Versmold': 3017,
    'Vettweiß': 3017,
    'Viersen': 3017,
    'Vlotho': 3017,
    'Voerde': 3017,
    'Vreden': 3017,
    'Wachtberg': 3017,
    'Wachtendonk': 3017,
    'Wadersloh': 3017,
    'Wahn-Heide': 3017,
    'Waldbröl': 3017,
    'Waldfeucht': 3017,
    'Waltrop': 3017,
    'Warburg': 3017,
    'Warendorf': 3017,
    'Warstein': 3017,
    'Wassenberg': 3017,
    'Weeze': 3017,
    'Wegberg': 3017,
    'Weilerswist': 3017,
    'Welver': 3017,
    'Wenden': 3017,
    'Werdohl': 3017,
    'Werl': 3017,
    'Wermelskirchen': 3017,
    'Werne': 3017,
    'Werther': 3017,
    'Wesel': 3017,
    'Wesseling': 3017,
    'Westerkappeln': 3017,
    'Wetter (Ruhr)': 3017,
    'Wettringen': 3017,
    'Wickede': 3017,
    'Wiehl': 3017,
    'Willebadessen': 3017,
    'Willich': 3017,
    'Wilnsdorf': 3017,
    'Winterberg': 3017,
    'Wipperfürth': 3017,
    'Witten': 3017,
    'Wülfrath': 3017,
    'Wünnenberg': 3017,
    'Wuppertal': 3017,
    'Würselen': 3017,
    'Xanten': 3017,
    'Zulpich': 3017,
    'Aach': 3019,
    'Adenau': 3019,
    'Ahrbrück': 3019,
    'Albersweiler': 3019,
    'Albig': 3019,
    'Albisheim': 3019,
    'Alpenrod': 3019,
    'Alsdorf': 3019,
    'Alsenz': 3019,
    'Alsheim': 3019,
    'Altenahr': 3019,
    'Altendiez': 3019,
    'Altenglan': 3019,
    'Altenkirchen': 3019,
    'Altleiningen': 3019,
    'Altrich': 3019,
    'Altrip': 3019,
    'Alzey': 3019,
    'Andernach': 3019,
    'Anhausen': 3019,
    'Annweiler am Trifels': 3019,
    'Appenheim': 3019,
    'Argenthal': 3019,
    'Armsheim': 3019,
    'Arzbach': 3019,
    'Arzfeld': 3019,
    'Asbach': 3019,
    'Ayl': 3019,
    'Bacharach': 3019,
    'Bad Bergzabern': 3019,
    'Bad Breisig': 3019,
    'Bad Dürkheim': 3019,
    'Bad Ems': 3019,
    'Bad Hönningen': 3019,
    'Bad Kreuznach': 3019,
    'Bad Marienberg': 3019,
    'Bad Münster am Stein-Ebernburg': 3019,
    'Bad Neuenahr-Ahrweiler': 3019,
    'Badem': 3019,
    'Bann': 3019,
    'Bassenheim': 3019,
    'Baumholder': 3019,
    'Bausendorf': 3019,
    'Bechhofen': 3019,
    'Bechtheim': 3019,
    'Bechtolsheim': 3019,
    'Beindersheim': 3019,
    'Bell': 3019,
    'Bellheim': 3019,
    'Beltheim': 3019,
    'Bendorf': 3019,
    'Berg': 3019,
    'Bernkastel-Kues': 3019,
    'Bettingen': 3019,
    'Betzdorf': 3019,
    'Billigheim-Ingenheim': 3019,
    'Bingen am Rhein': 3019,
    'Binsfeld': 3019,
    'Birken-Honigsessen': 3019,
    'Birkenfeld': 3019,
    'Birkenheide': 3019,
    'Birlenbach': 3019,
    'Birresborn': 3019,
    'Bitburg': 3019,
    'Blankenrath': 3019,
    'Bleialf': 3019,
    'Bobenheim-Roxheim': 3019,
    'Bockenau': 3019,
    'Bockenheim': 3019,
    'Bodenheim': 3019,
    'Böhl-Iggelheim': 3019,
    'Bolanden': 3019,
    'Bollendorf': 3019,
    'Bonefeld': 3019,
    'Boppard': 3019,
    'Bornheim': 3019,
    'Bornich': 3019,
    'Brachbach': 3019,
    'Braubach': 3019,
    'Brauneberg': 3019,
    'Breitscheidt': 3019,
    'Bretzenheim': 3019,
    'Brey': 3019,
    'Briedel': 3019,
    'Brohl-Lützing': 3019,
    'Bruchmühlbach-Miesau': 3019,
    'Bruchweiler-Bärenbach': 3019,
    'Brücken': 3019,
    'Bruttig-Fankel': 3019,
    'Büchel': 3019,
    'Büchenbeuren': 3019,
    'Budenheim': 3019,
    'Bullay': 3019,
    'Bundenbach': 3019,
    'Bundenthal': 3019,
    'Burgbrohl': 3019,
    'Burgschwalbach': 3019,
    'Busenberg': 3019,
    'Carlsberg': 3019,
    'Clausen': 3019,
    'Cochem': 3019,
    'Contwig': 3019,
    'Daaden': 3019,
    'Dachsenhausen': 3019,
    'Dahn': 3019,
    'Dalheim': 3019,
    'Dannstadt-Schauernheim': 3019,
    'Dattenberg': 3019,
    'Daun': 3019,
    'Dausenau': 3019,
    'Deidesheim': 3019,
    'Dellfeld': 3019,
    'Dernau': 3019,
    'Dernbach': 3019,
    'Derschen': 3019,
    'Dexheim': 3019,
    'Dieblich': 3019,
    'Dienheim': 3019,
    'Dierdorf': 3019,
    'Diez': 3019,
    'Dirmstein': 3019,
    'Dittelsheim-Heßloch': 3019,
    'Dommershausen': 3019,
    'Dörrenbach': 3019,
    'Dreikirchen': 3019,
    'Dreis': 3019,
    'Dreisen': 3019,
    'Dudeldorf': 3019,
    'Dudenhofen': 3019,
    'Düngenheim': 3019,
    'Ebernhahn': 3019,
    'Ebertsheim': 3019,
    'Edenkoben': 3019,
    'Edesheim': 3019,
    'Ediger-Eller': 3019,
    'Ehlscheid': 3019,
    'Eisenberg': 3019,
    'Eitelborn': 3019,
    'Elkenroth': 3019,
    'Ellerstadt': 3019,
    'Elmstein': 3019,
    'Emmelshausen': 3019,
    'Enkenbach-Alsenborn': 3019,
    'Enkirch': 3019,
    'Eppelsheim': 3019,
    'Eppenbrunn': 3019,
    'Erbes-Büdesheim': 3019,
    'Erfweiler': 3019,
    'Erpel': 3019,
    'Erpolzheim': 3019,
    'Essenheim': 3019,
    'Essingen': 3019,
    'Esthal': 3019,
    'Ettringen': 3019,
    'Etzbach': 3019,
    'Fachbach': 3019,
    'Faid': 3019,
    'Feilbingert': 3019,
    'Fell': 3019,
    'Fischbach': 3019,
    'Flacht': 3019,
    'Flammersfeld': 3019,
    'Flomborn': 3019,
    'Flonheim': 3019,
    'Föhren': 3019,
    'Framersheim': 3019,
    'Frankenstein': 3019,
    'Frankenthal': 3019,
    'Freckenfeld': 3019,
    'Frei-Laubersheim': 3019,
    'Freinsheim': 3019,
    'Freisbach': 3019,
    'Freudenburg': 3019,
    'Friedelsheim': 3019,
    'Friedewald': 3019,
    'Friesenhagen': 3019,
    'Fürfeld': 3019,
    'Fürthen': 3019,
    'Fußgönheim': 3019,
    'Gartenstadt': 3019,
    'Gau-Algesheim': 3019,
    'Gau-Bickelheim': 3019,
    'Gau-Bischofsheim': 3019,
    'Gau-Odernheim': 3019,
    'Gebhardshain': 3019,
    'Gemünden': 3019,
    'Gensingen': 3019,
    'Germersheim': 3019,
    'Gerolsheim': 3019,
    'Gerolstein': 3019,
    'Gillenfeld': 3019,
    'Gimbsheim': 3019,
    'Girod': 3019,
    'Glan-Münchweiler': 3019,
    'Göllheim': 3019,
    'Gommersheim': 3019,
    'Gondershausen': 3019,
    'Gönnheim': 3019,
    'Gossersweiler-Stein': 3019,
    'Greimerath': 3019,
    'Gries': 3019,
    'Grolsheim': 3019,
    'Großholbach': 3019,
    'Großkarlbach': 3019,
    'Großlittgen': 3019,
    'Großmaischeid': 3019,
    'Großniedesheim': 3019,
    'Grünstadt': 3019,
    'Gückingen': 3019,
    'Gundersheim': 3019,
    'Guntersblum': 3019,
    'Gusenburg': 3019,
    'Gusterath': 3019,
    'Gutenberg': 3019,
    'Hachenburg': 3019,
    'Hackenheim': 3019,
    'Hagenbach': 3019,
    'Hahnheim': 3019,
    'Hahnstätten': 3019,
    'Halsenbach': 3019,
    'Hamm': 3019,
    'Hanhofen': 3019,
    'Hargesheim': 3019,
    'Harthausen': 3019,
    'Harxheim': 3019,
    'Haßloch': 3019,
    'Hattert': 3019,
    'Hatzenbühl': 3019,
    'Hauenstein': 3019,
    'Hauptstuhl': 3019,
    'Hausen': 3019,
    'Heidesheim': 3019,
    'Heiligenroth': 3019,
    'Heimbach': 3019,
    'Heistenbach': 3019,
    'Helferskirchen': 3019,
    'Hellenhahn-Schellenberg': 3019,
    'Heltersberg': 3019,
    'Hennweiler': 3019,
    'Herdorf': 3019,
    'Herforst': 3019,
    'Hermersberg': 3019,
    'Hermeskeil': 3019,
    'Herschweiler-Pettersheim': 3019,
    'Herxheim am Berg': 3019,
    'Heßheim': 3019,
    'Hettenleidelheim': 3019,
    'Hetzerath': 3019,
    'Heuchelheim bei Frankenthal': 3019,
    'Hilgert': 3019,
    'Hillesheim': 3019,
    'Hillscheid': 3019,
    'Hinterweidenthal': 3019,
    'Hochdorf-Assenheim': 3019,
    'Hochspeyer': 3019,
    'Hochstadt': 3019,
    'Hochstetten-Dhaun': 3019,
    'Hof': 3019,
    'Höheinöd': 3019,
    'Höheischweiler': 3019,
    'Höhn': 3019,
    'Höhr-Grenzhausen': 3019,
    'Holler': 3019,
    'Holzappel': 3019,
    'Holzhausen an der Haide': 3019,
    'Hoppstädten-Weiersbach': 3019,
    'Hördt': 3019,
    'Horhausen': 3019,
    'Hornbach': 3019,
    'Hüffelsheim': 3019,
    'Hundsangen': 3019,
    'Hütschenhausen': 3019,
    'Idar-Oberstein': 3019,
    'Igel': 3019,
    'Ilbesheim': 3019,
    'Imsbach': 3019,
    'Ingelheim am Rhein': 3019,
    'Insheim': 3019,
    'Irrel': 3019,
    'Irsch': 3019,
    'Jockgrim': 3019,
    'Jugenheim': 3019,
    'Jünkerath': 3019,
    'Kadenbach': 3019,
    'Kaisersesch': 3019,
    'Kaiserslautern': 3019,
    'Kallstadt': 3019,
    'Kaltenengers': 3019,
    'Kamp-Bornhofen': 3019,
    'Kandel': 3019,
    'Kapsweyer': 3019,
    'Kasbach-Ohlenberg': 3019,
    'Kasel': 3019,
    'Kastellaun': 3019,
    'Katzenelnbogen': 3019,
    'Katzweiler': 3019,
    'Katzwinkel': 3019,
    'Kaub': 3019,
    'Kehrig': 3019,
    'Kelberg': 3019,
    'Kell': 3019,
    'Kempenich': 3019,
    'Kenn': 3019,
    'Kerzenheim': 3019,
    'Kettig': 3019,
    'Kindenheim': 3019,
    'Kinderbeuern': 3019,
    'Kindsbach': 3019,
    'Kirchberg': 3019,
    'Kirchen': 3019,
    'Kirchheim an der Weinstraße': 3019,
    'Kirchheimbolanden': 3019,
    'Kirchwald': 3019,
    'Kirn': 3019,
    'Kirrweiler': 3019,
    'Kirschweiler': 3019,
    'Klausen': 3019,
    'Klein-Winternheim': 3019,
    'Kleinmaischeid': 3019,
    'Klingenmünster': 3019,
    'Klotten': 3019,
    'Klüsserath': 3019,
    'Knittelsheim': 3019,
    'Kobern-Gondorf': 3019,
    'Koblenz': 3019,
    'Kölbingen': 3019,
    'Köngernheim': 3019,
    'Konz': 3019,
    'Kordel': 3019,
    'Körperich': 3019,
    'Kottenheim': 3019,
    'Kottweiler-Schwanden': 3019,
    'Krickenbach': 3019,
    'Kriegsfeld': 3019,
    'Kröv': 3019,
    'Kruft': 3019,
    'Kuhardt': 3019,
    'Kusel': 3019,
    'Kyllburg': 3019,
    'Lachen-Speyerdorf': 3019,
    'Lahnstein': 3019,
    'Lambrecht': 3019,
    'Lambsheim': 3019,
    'Landau in der Pfalz': 3019,
    'Landscheid': 3019,
    'Landstuhl': 3019,
    'Langenbach bei Marienberg': 3019,
    'Langenhahn': 3019,
    'Langenlonsheim': 3019,
    'Langsur': 3019,
    'Laubach': 3019,
    'Lauterecken': 3019,
    'Lehmen': 3019,
    'Leimersheim': 3019,
    'Leiwen': 3019,
    'Lemberg': 3019,
    'Leubsdorf': 3019,
    'Leutesdorf': 3019,
    'Lieser': 3019,
    'Limburgerhof': 3019,
    'Linden': 3019,
    'Lindenberg': 3019,
    'Lingenfeld': 3019,
    'Linz am Rhein': 3019,
    'Lissendorf': 3019,
    'Löf': 3019,
    'Longkamp': 3019,
    'Longuich': 3019,
    'Lonnig': 3019,
    'Lörzweiler': 3019,
    'Ludwigshafen am Rhein': 3019,
    'Lustadt': 3019,
    'Lutzerath': 3019,
    'Mackenbach': 3019,
    'Maikammer': 3019,
    'Mainz': 3019,
    'Malborn': 3019,
    'Mammelzen': 3019,
    'Manderscheid': 3019,
    'Marienrachdorf': 3019,
    'Maring-Noviand': 3019,
    'Marnheim': 3019,
    'Martinshöhe': 3019,
    'Masburg': 3019,
    'Maßweiler': 3019,
    'Mastershausen': 3019,
    'Maxdorf': 3019,
    'Maxsain': 3019,
    'Mayen': 3019,
    'Meckenheim': 3019,
    'Meddersheim': 3019,
    'Mehlbach': 3019,
    'Mehlingen': 3019,
    'Mehren': 3019,
    'Mehring': 3019,
    'Meisenheim': 3019,
    'Melsbach': 3019,
    'Mendig': 3019,
    'Mertesdorf': 3019,
    'Mertloch': 3019,
    'Merxheim': 3019,
    'Merzalben': 3019,
    'Mettendorf': 3019,
    'Mettenheim': 3019,
    'Meudt': 3019,
    'Miehlen': 3019,
    'Minfeld': 3019,
    'Mittelhof': 3019,
    'Mogendorf': 3019,
    'Mommenheim': 3019,
    'Monsheim': 3019,
    'Montabaur': 3019,
    'Monzelfeld': 3019,
    'Monzingen': 3019,
    'Morbach': 3019,
    'Mudersbach': 3019,
    'Mülheim-Kärlich': 3019,
    'Münchweiler an der Alsenz': 3019,
    'Münchweiler an der Rodalbe': 3019,
    'Münster-Sarmsheim': 3019,
    'Münstermaifeld': 3019,
    'Müschenbach': 3019,
    'Mutterstadt': 3019,
    'Nackenheim': 3019,
    'Nanzdietschweiler': 3019,
    'Nassau': 3019,
    'Nastätten': 3019,
    'Nauort': 3019,
    'Nauroth': 3019,
    'Nentershausen': 3019,
    'Neuburg': 3019,
    'Neuerburg': 3019,
    'Neuhäusel': 3019,
    'Neuhofen': 3019,
    'Neumagen-Dhron': 3019,
    'Neunkhausen': 3019,
    'Neupotz': 3019,
    'Neustadt': 3019,
    'Neuwied': 3019,
    'Newel': 3019,
    'Nickenich': 3019,
    'Nieder-Ingelheim': 3019,
    'Nieder-Olm': 3019,
    'Niederbreitbach': 3019,
    'Niederdreisbach': 3019,
    'Niederdürenbach': 3019,
    'Niederelbert': 3019,
    'Niedererbach': 3019,
    'Niederfell': 3019,
    'Niederfischbach': 3019,
    'Niederkirchen': 3019,
    'Niederkirchen bei Deidesheim': 3019,
    'Niedermohr': 3019,
    'Niederneisen': 3019,
    'Niederwerth': 3019,
    'Niederzissen': 3019,
    'Nierstein': 3019,
    'Nister': 3019,
    'Nittel': 3019,
    'Norheim': 3019,
    'Nörtershausen': 3019,
    'Ober-Flörsheim': 3019,
    'Ober-Olm': 3019,
    'Ober-Saulheim': 3019,
    'Oberelbert': 3019,
    'Oberfell': 3019,
    'Obermoschel': 3019,
    'Obernheim-Kirchenarnbach': 3019,
    'Oberotterbach': 3019,
    'Oberwesel': 3019,
    'Oberzissen': 3019,
    'Obrigheim': 3019,
    'Ochtendung': 3019,
    'Ockenfels': 3019,
    'Ockenheim': 3019,
    'Odernheim': 3019,
    'Offenbach an der Queich': 3019,
    'Offenbach-Hundheim': 3019,
    'Offstein': 3019,
    'Olsbrücken': 3019,
    'Oppenheim': 3019,
    'Orenhofen': 3019,
    'Osann-Monzel': 3019,
    'Osburg': 3019,
    'Osterspai': 3019,
    'Osthofen': 3019,
    'Otterbach': 3019,
    'Otterberg': 3019,
    'Ottersheim': 3019,
    'Otterstadt': 3019,
    'Ötzingen': 3019,
    'Palzem': 3019,
    'Partenheim': 3019,
    'Pellingen': 3019,
    'Pelm': 3019,
    'Pfaffen-Schwabenheim': 3019,
    'Pfeffelbach': 3019,
    'Piesport': 3019,
    'Pirmasens': 3019,
    'Plaidt': 3019,
    'Pluwig': 3019,
    'Polch': 3019,
    'Pottum': 3019,
    'Pracht': 3019,
    'Prüm': 3019,
    'Puderbach': 3019,
    'Queidersbach': 3019,
    'Ralingen': 3019,
    'Ramberg': 3019,
    'Rammelsbach': 3019,
    'Ramsen': 3019,
    'Ramstein-Miesenbach': 3019,
    'Ransbach-Baumbach': 3019,
    'Raubach': 3019,
    'Rehe': 3019,
    'Reichenbach-Steegen': 3019,
    'Reil': 3019,
    'Reinsfeld': 3019,
    'Remagen': 3019,
    'Rengsdorf': 3019,
    'Rennerod': 3019,
    'Rhaunen': 3019,
    'Rheinböllen': 3019,
    'Rheinbreitbach': 3019,
    'Rheinbrohl': 3019,
    'Rheinzabern': 3019,
    'Rhens': 3019,
    'Rhodt unter Rietburg': 3019,
    'Rieden': 3019,
    'Rieschweiler-Mühlbach': 3019,
    'Rimschweiler': 3019,
    'Riol': 3019,
    'Rittersdorf': 3019,
    'Rockenhausen': 3019,
    'Rodalben': 3019,
    'Rodenbach': 3019,
    'Rödersheim-Gronau': 3019,
    'Rohrbach': 3019,
    'Roth': 3019,
    'Roxheim': 3019,
    'Rüdesheim': 3019,
    'Rülzheim': 3019,
    'Rümmelsheim': 3019,
    'Ruppach-Goldhausen': 3019,
    'Ruppertsberg': 3019,
    'Ruppertsweiler': 3019,
    'Saarburg': 3019,
    'Saffig': 3019,
    'Salmtal': 3019,
    'Sankt Goar': 3019,
    'Sankt Goarshausen': 3019,
    'Sankt Julian': 3019,
    'Sankt Katharinen': 3019,
    'Sankt Martin': 3019,
    'Sankt Sebastian': 3019,
    'Scheuerfeld': 3019,
    'Schifferstadt': 3019,
    'Schillingen': 3019,
    'Schönecken': 3019,
    'Schönenberg-Kübelberg': 3019,
    'Schopp': 3019,
    'Schornsheim': 3019,
    'Schwabenheim': 3019,
    'Schwedelbach': 3019,
    'Schwegenheim': 3019,
    'Schweich': 3019,
    'Schweigen-Rechtenbach': 3019,
    'Seck': 3019,
    'Seibersbach': 3019,
    'Selters': 3019,
    'Selzen': 3019,
    'Sembach': 3019,
    'Serrig': 3019,
    'Siebeldingen': 3019,
    'Siefersheim': 3019,
    'Siershahn': 3019,
    'Simmern': 3019,
    'Simmertal': 3019,
    'Singhofen': 3019,
    'Sinzig': 3019,
    'Sippersfeld': 3019,
    'Sohren': 3019,
    'Sörgenloch': 3019,
    'Spabrücken': 3019,
    'Spay': 3019,
    'Speicher': 3019,
    'Speyer': 3019,
    'Spiesheim': 3019,
    'Sprendlingen': 3019,
    'Stadecken-Elsheim': 3019,
    'Stadtkyll': 3019,
    'Staudernheim': 3019,
    'Steimel': 3019,
    'Steinfeld': 3019,
    'Steinweiler': 3019,
    'Steinwenden': 3019,
    'Stelzenberg': 3019,
    'Stromberg': 3019,
    'Sulzheim': 3019,
    'Tawern': 3019,
    'Thaleischweiler-Fröschen': 3019,
    'Thalfang': 3019,
    'Thomm': 3019,
    'Thür': 3019,
    'Traben-Trarbach': 3019,
    'Trassem': 3019,
    'Trechtingshausen': 3019,
    'Treis-Karden': 3019,
    'Trier': 3019,
    'Trierweiler': 3019,
    'Trippstadt': 3019,
    'Trittenheim': 3019,
    'Trulben': 3019,
    'Udenheim': 3019,
    'Üdersdorf': 3019,
    'Uelversheim': 3019,
    'Ulmen': 3019,
    'Undenheim': 3019,
    'Unkel': 3019,
    'Unnau': 3019,
    'Urbach-Überdorf': 3019,
    'Urbar': 3019,
    'Urmitz': 3019,
    'Üxheim': 3019,
    'Vallendar': 3019,
    'Vettelschoß': 3019,
    'Vinningen': 3019,
    'Volxheim': 3019,
    'Wachenheim': 3019,
    'Wackernheim': 3019,
    'Waldalgesheim': 3019,
    'Waldböckelheim': 3019,
    'Waldbreitbach': 3019,
    'Waldfischbach-Burgalben': 3019,
    'Waldmohr': 3019,
    'Waldrach': 3019,
    'Waldsee': 3019,
    'Wallertheim': 3019,
    'Wallhausen': 3019,
    'Wallmenroth': 3019,
    'Wallmerod': 3019,
    'Wassenach': 3019,
    'Wasserliesch': 3019,
    'Wattenheim': 3019,
    'Waxweiler': 3019,
    'Wehr': 3019,
    'Weibern': 3019,
    'Weidenthal': 3019,
    'Weiler': 3019,
    'Weilerbach': 3019,
    'Weingarten': 3019,
    'Weinsheim': 3019,
    'Weisel': 3019,
    'Weißenthurm': 3019,
    'Weitefeld': 3019,
    'Weitersburg': 3019,
    'Welschbillig': 3019,
    'Wendelsheim': 3019,
    'Wernersberg': 3019,
    'Weselberg': 3019,
    'Westerburg': 3019,
    'Westheim': 3019,
    'Westhofen': 3019,
    'Weyerbusch': 3019,
    'Wilgartswiesen': 3019,
    'Wiltingen': 3019,
    'Wincheringen': 3019,
    'Winden': 3019,
    'Windesheim': 3019,
    'Windhagen': 3019,
    'Winningen': 3019,
    'Winnweiler': 3019,
    'Wirges': 3019,
    'Wissen': 3019,
    'Wittlich': 3019,
    'Wolfstein': 3019,
    'Wolken': 3019,
    'Wöllstein': 3019,
    'Worms': 3019,
    'Wörrstadt': 3019,
    'Wörth am Rhein': 3019,
    'Zeiskam': 3019,
    'Zell': 3019,
    'Zeltingen-Rachtig': 3019,
    'Zemmer': 3019,
    'Zerf': 3019,
    'Zornheim': 3019,
    'Zweibrücken': 3019,
    'Beckingen': 3020,
    'Bexbach': 3020,
    'Blieskastel': 3020,
    'Bous': 3020,
    'Britten': 3020,
    'Dillingen': 3020,
    'Ensdorf': 3020,
    'Eppelborn': 3020,
    'Freisen': 3020,
    'Friedrichsthal': 3020,
    'Fürstenhausen': 3020,
    'Gersheim': 3020,
    'Großrosseln': 3020,
    'Hangard': 3020,
    'Heidstock': 3020,
    'Heusweiler': 3020,
    'Homburg': 3020,
    'Illingen': 3020,
    'Kirkel': 3020,
    'Kleinblittersdorf': 3020,
    'Lebach': 3020,
    'Losheim': 3020,
    'Ludweiler-Warndt': 3020,
    'Luisenthal': 3020,
    'Mainzweiler': 3020,
    'Marpingen': 3020,
    'Merchweiler': 3020,
    'Merzig': 3020,
    'Mettlach': 3020,
    'Nalbach': 3020,
    'Namborn': 3020,
    'Neunkirchen': 3020,
    'Nohfelden': 3020,
    'Nonnweiler': 3020,
    'Oberthal': 3020,
    'Orscholz': 3020,
    'Ottweiler': 3020,
    'Püttlingen': 3020,
    'Quierschied': 3020,
    'Riegelsberg': 3020,
    'Röchling-Höhe': 3020,
    'Saarbrücken': 3020,
    'Saarhölzbach': 3020,
    'Saarlouis': 3020,
    'Saarwellingen': 3020,
    'Sankt Ingbert': 3020,
    'Sankt Wendel': 3020,
    'Schiffweiler': 3020,
    'Schmelz': 3020,
    'Schwalbach': 3020,
    'Spiesen-Elversberg': 3020,
    'Sulzbach': 3020,
    'Tholey': 3020,
    'Überherrn': 3020,
    'Völklingen': 3020,
    'Wadern': 3020,
    'Wadgassen': 3020,
    'Wallerfangen': 3020,
    'Weiskirchen': 3020,
    'Weiten': 3020,
    'Adorf': 3021,
    'Albertstadt': 3021,
    'Altenberg': 3021,
    'Altmittweida': 3021,
    'Annaberg-Buchholz': 3021,
    'Arzberg': 3021,
    'Aue': 3021,
    'Auerbach': 3021,
    'Augustusburg': 3021,
    'Bad Brambach': 3021,
    'Bad Düben': 3021,
    'Bad Elster': 3021,
    'Bad Lausick': 3021,
    'Bad Muskau': 3021,
    'Bad Schandau': 3021,
    'Bad Schlema': 3021,
    'Bannewitz': 3021,
    'Bärenstein': 3021,
    'Bautzen': 3021,
    'Beierfeld': 3021,
    'Beiersdorf': 3021,
    'Beilrode': 3021,
    'Belgern': 3021,
    'Belgershain': 3021,
    'Bennewitz': 3021,
    'Bergen': 3021,
    'Bernsbach': 3021,
    'Bernsdorf': 3021,
    'Bernstadt': 3021,
    'Berthelsdorf': 3021,
    'Bertsdorf-Hörnitz': 3021,
    'Bischofswerda': 3021,
    'Bockau': 3021,
    'Bockelwitz': 3021,
    'Böhlen': 3021,
    'Borna': 3021,
    'Börnichen': 3021,
    'Borsdorf': 3021,
    'Borstendorf': 3021,
    'Bösenbrunn': 3021,
    'Boxberg': 3021,
    'Brand-Erbisdorf': 3021,
    'Brandis': 3021,
    'Breitenbrunn': 3021,
    'Burgstädt': 3021,
    'Burkau': 3021,
    'Burkhardtsdorf': 3021,
    'Callenberg': 3021,
    'Cavertitz': 3021,
    'Chemnitz': 3021,
    'Claußnitz': 3021,
    'Colditz': 3021,
    'Coswig': 3021,
    'Crimmitschau': 3021,
    'Crostau': 3021,
    'Crostwitz': 3021,
    'Crottendorf': 3021,
    'Cunewalde': 3021,
    'Dahlen': 3021,
    'Delitzsch': 3021,
    'Demitz-Thumitz': 3021,
    'Dennheritz': 3021,
    'Deutzen': 3021,
    'Dippoldiswalde': 3021,
    'Döbeln': 3021,
    'Döbernitz': 3021,
    'Doberschütz': 3021,
    'Dohma': 3021,
    'Dohna': 3021,
    'Dommitzsch': 3021,
    'Dorfchemnitz': 3021,
    'Dorfhain': 3021,
    'Drebach': 3021,
    'Dresden': 3021,
    'Dürrhennersdorf': 3021,
    'Dürrröhrsdorf': 3021,
    'Ebersbach': 3021,
    'Ehrenfriedersdorf': 3021,
    'Eibau': 3021,
    'Eibenstock': 3021,
    'Eichigt': 3021,
    'Eilenburg': 3021,
    'Ellefeld': 3021,
    'Elsnig': 3021,
    'Elsterberg': 3021,
    'Elstertrebnitz': 3021,
    'Elstra': 3021,
    'Elterlein': 3021,
    'Eppendorf': 3021,
    'Erlau': 3021,
    'Erlbach': 3021,
    'Espenhain': 3021,
    'Falkenau': 3021,
    'Falkenhain': 3021,
    'Falkenstein': 3021,
    'Flöha': 3021,
    'Frankenberg': 3021,
    'Frankenstein': 3021,
    'Frankenthal': 3021,
    'Frauenstein': 3021,
    'Fraureuth': 3021,
    'Freiberg': 3021,
    'Freital': 3021,
    'Friedersdorf': 3021,
    'Frohburg': 3021,
    'Gablenz': 3021,
    'Geising': 3021,
    'Geithain': 3021,
    'Gelenau': 3021,
    'Geringswalde': 3021,
    'Gersdorf': 3021,
    'Geyer': 3021,
    'Glashütte': 3021,
    'Glaubitz': 3021,
    'Glauchau': 3021,
    'Göda': 3021,
    'Görlitz': 3021,
    'Gornau': 3021,
    'Gornsdorf': 3021,
    'Grimma': 3021,
    'Groitzsch': 3021,
    'Groß Düben': 3021,
    'Großbardau': 3021,
    'Großbothen': 3021,
    'Großdubrau': 3021,
    'Großenhain': 3021,
    'Großharthau': 3021,
    'Großhartmannsdorf': 3021,
    'Großhennersdorf': 3021,
    'Großlehna': 3021,
    'Großnaundorf': 3021,
    'Großolbersdorf': 3021,
    'Großpösna': 3021,
    'Großpostwitz': 3021,
    'Großröhrsdorf': 3021,
    'Großrückerswalde': 3021,
    'Großschirma': 3021,
    'Großschönau': 3021,
    'Großschweidnitz': 3021,
    'Großweitzschen': 3021,
    'Grünbach': 3021,
    'Grünhain': 3021,
    'Grünhainichen': 3021,
    'Guttau': 3021,
    'Hähnichen': 3021,
    'Hainewalde': 3021,
    'Hainichen': 3021,
    'Halsbrücke': 3021,
    'Hammerbrücke': 3021,
    'Hartenstein': 3021,
    'Hartha': 3021,
    'Hartmannsdorf': 3021,
    'Haselbachtal': 3021,
    'Hauswalde': 3021,
    'Heidenau': 3021,
    'Hermsdorf': 3021,
    'Herrnhut': 3021,
    'Hilbersdorf': 3021,
    'Hirschfeld': 3021,
    'Hirschfelde': 3021,
    'Hochkirch': 3021,
    'Höckendorf': 3021,
    'Hohburg': 3021,
    'Hohenstein-Ernstthal': 3021,
    'Hohndorf': 3021,
    'Hohnstein': 3021,
    'Horka': 3021,
    'Hormersdorf': 3021,
    'Hoyerswerda': 3021,
    'Jahnsdorf': 3021,
    'Jesewitz': 3021,
    'Johanngeorgenstadt': 3021,
    'Jöhstadt': 3021,
    'Kamenz': 3021,
    'Kirchberg': 3021,
    'Kirschau': 3021,
    'Kitzen': 3021,
    'Kitzscher': 3021,
    'Klingenthal': 3021,
    'Klipphausen': 3021,
    'Klitten': 3021,
    'Kodersdorf': 3021,
    'Königsbrück': 3021,
    'Königsfeld': 3021,
    'Königshain': 3021,
    'Königstein': 3021,
    'Königswalde': 3021,
    'Königswartha': 3021,
    'Kossa': 3021,
    'Krauschwitz': 3021,
    'Kreischa': 3021,
    'Kriebstein': 3021,
    'Krostitz': 3021,
    'Kubschütz': 3021,
    'Kurort Gohrisch': 3021,
    'Kurort Jonsdorf': 3021,
    'Kurort Oberwiesenthal': 3021,
    'Kurort Oybin': 3021,
    'Lampertswalde': 3021,
    'Langenbernsdorf': 3021,
    'Laußig': 3021,
    'Laußnitz': 3021,
    'Lauter': 3021,
    'Lawalde': 3021,
    'Leipzig': 3021,
    'Leisnig': 3021,
    'Lengefeld': 3021,
    'Lengenfeld': 3021,
    'Leubnitz': 3021,
    'Leubsdorf': 3021,
    'Leutersdorf': 3021,
    'Lichtenberg': 3021,
    'Lichtenstein': 3021,
    'Lichtentanne': 3021,
    'Liebstadt': 3021,
    'Limbach': 3021,
    'Limbach-Oberfrohna': 3021,
    'Löbau': 3021,
    'Löbnitz': 3021,
    'Lobstädt': 3021,
    'Lohmen': 3021,
    'Lohsa': 3021,
    'Lommatzsch': 3021,
    'Lößnitz': 3021,
    'Lugau': 3021,
    'Lunzenau': 3021,
    'Machern': 3021,
    'Malschwitz': 3021,
    'Marienberg': 3021,
    'Markersbach': 3021,
    'Markersdorf': 3021,
    'Markkleeberg': 3021,
    'Markneukirchen': 3021,
    'Markranstädt': 3021,
    'Meerane': 3021,
    'Mehltheuer': 3021,
    'Meissen': 3021,
    'Mildenau': 3021,
    'Mittelherwigsdorf': 3021,
    'Mittweida': 3021,
    'Mochau': 3021,
    'Mockrehna': 3021,
    'Moritzburg': 3021,
    'Mücka': 3021,
    'Mügeln': 3021,
    'Mühlau': 3021,
    'Mühltroff': 3021,
    'Mulda': 3021,
    'Mülsen': 3021,
    'Mutzschen': 3021,
    'Mylau': 3021,
    'Narsdorf': 3021,
    'Naundorf': 3021,
    'Naunhof': 3021,
    'Nauwalde': 3021,
    'Nebelschütz': 3021,
    'Nerchau': 3021,
    'Neschwitz': 3021,
    'Netzschkau': 3021,
    'Neuensalz': 3021,
    'Neugersdorf': 3021,
    'Neuhausen': 3021,
    'Neukieritzsch': 3021,
    'Neukirch': 3021,
    'Neukirch/Lausitz': 3021,
    'Neukirchen': 3021,
    'Neumark': 3021,
    'Neusalza-Spremberg': 3021,
    'Neustadt in Sachsen': 3021,
    'Neustadt Vogtland': 3021,
    'Niederau': 3021,
    'Niedercunnersdorf': 3021,
    'Niederdorf': 3021,
    'Niederfrohna': 3021,
    'Niederschöna': 3021,
    'Niederstriegis': 3021,
    'Niederwiesa': 3021,
    'Niesky': 3021,
    'Nossen': 3021,
    'Nünchritz': 3021,
    'Obercunnersdorf': 3021,
    'Obergurig': 3021,
    'Oberlichtenau': 3021,
    'Oberlungwitz': 3021,
    'Oberschöna': 3021,
    'Oberwiera': 3021,
    'Oederan': 3021,
    'Oelsnitz': 3021,
    'Ohorn': 3021,
    'Olbernhau': 3021,
    'Olbersdorf': 3021,
    'Oppach': 3021,
    'Oschatz': 3021,
    'Oßling': 3021,
    'Ostrau': 3021,
    'Ostritz': 3021,
    'Ottendorf-Okrilla': 3021,
    'Otterwisch': 3021,
    'Panschwitz-Kuckau': 3021,
    'Pausa': 3021,
    'Pegau': 3021,
    'Penig': 3021,
    'Pfaffroda': 3021,
    'Pirna': 3021,
    'Plauen': 3021,
    'Pobershau': 3021,
    'Pockau': 3021,
    'Pöhl': 3021,
    'Pöhla': 3021,
    'Porschdorf': 3021,
    'Pretzschendorf': 3021,
    'Priestewitz': 3021,
    'Puschwitz': 3021,
    'Quitzdorf': 3021,
    'Rabenau': 3021,
    'Räckelwitz': 3021,
    'Rackwitz': 3021,
    'Radeberg': 3021,
    'Radebeul': 3021,
    'Radeburg': 3021,
    'Radibor': 3021,
    'Rammenau': 3021,
    'Raschau': 3021,
    'Rathmannsdorf': 3021,
    'Rechenberg-Bienenmühle': 3021,
    'Regis-Breitingen': 3021,
    'Reichenbach': 3021,
    'Reichenbach/Vogtland': 3021,
    'Reinhardtsgrimma': 3021,
    'Reinsberg': 3021,
    'Reinsdorf': 3021,
    'Remse': 3021,
    'Reuth': 3021,
    'Riesa': 3021,
    'Rietschen': 3021,
    'Rittersgrün': 3021,
    'Rochlitz': 3021,
    'Rodewisch': 3021,
    'Rossau': 3021,
    'Roßwein': 3021,
    'Rötha': 3021,
    'Rothenburg': 3021,
    'Sankt Egidien': 3021,
    'Sayda': 3021,
    'Scharfenstein': 3021,
    'Scheibenberg': 3021,
    'Schildau': 3021,
    'Schirgiswalde': 3021,
    'Schkeuditz': 3021,
    'Schlegel': 3021,
    'Schleife': 3021,
    'Schlettau': 3021,
    'Schmiedeberg': 3021,
    'Schneeberg': 3021,
    'Schönau-Berzdorf': 3021,
    'Schönbach': 3021,
    'Schönberg': 3021,
    'Schöneck': 3021,
    'Schönfeld': 3021,
    'Schönheide': 3021,
    'Schwarzenberg': 3021,
    'Schwepnitz': 3021,
    'Sebnitz': 3021,
    'Seelitz': 3021,
    'Seiffen': 3021,
    'Seifhennersdorf': 3021,
    'Sohland': 3021,
    'Sohland am Rotstein': 3021,
    'Sosa': 3021,
    'Stadt Wehlen': 3021,
    'Stauchitz': 3021,
    'Steina': 3021,
    'Steinigtwolmsdorf': 3021,
    'Stollberg': 3021,
    'Stolpen': 3021,
    'Strehla': 3021,
    'Struppen': 3021,
    'Stützengrün': 3021,
    'Syrau': 3021,
    'Tannenberg': 3021,
    'Tannenbergsthal': 3021,
    'Taucha': 3021,
    'Taura': 3021,
    'Tauscha': 3021,
    'Thalheim': 3021,
    'Thallwitz': 3021,
    'Tharandt': 3021,
    'Theuma': 3021,
    'Thiendorf': 3021,
    'Thum': 3021,
    'Tirpersdorf': 3021,
    'Torgau': 3021,
    'Trebendorf': 3021,
    'Trebsen': 3021,
    'Treuen': 3021,
    'Triebel': 3021,
    'Trossin': 3021,
    'Uhyst': 3021,
    'Venusberg': 3021,
    'Wachau': 3021,
    'Waldenburg': 3021,
    'Waldheim': 3021,
    'Waldkirchen': 3021,
    'Wechselburg': 3021,
    'Weinböhla': 3021,
    'Weischlitz': 3021,
    'Weißenberg': 3021,
    'Weißenborn': 3021,
    'Weißig': 3021,
    'Weißkeißel': 3021,
    'Weißwasser': 3021,
    'Werda': 3021,
    'Werdau': 3021,
    'Wermsdorf': 3021,
    'Wiedemar': 3021,
    'Wiednitz': 3021,
    'Wiesa': 3021,
    'Wildenfels': 3021,
    'Wildenhain': 3021,
    'Wilkau-Haßlau': 3021,
    'Wilsdruff': 3021,
    'Wilthen': 3021,
    'Wittgensdorf': 3021,
    'Wittichenau': 3021,
    'Wolkenstein': 3021,
    'Wülknitz': 3021,
    'Wurzen': 3021,
    'Zabeltitz': 3021,
    'Zeithain': 3021,
    'Zinna': 3021,
    'Zittau': 3021,
    'Zöblitz': 3021,
    'Zschadrass': 3021,
    'Zschepplin': 3021,
    'Zschopau': 3021,
    'Zschorlau': 3021,
    'Zschortau': 3021,
    'Zwenkau': 3021,
    'Zwickau': 3021,
    'Zwochau': 3021,
    'Zwönitz': 3021,
    'Zwota': 3021,
    'Abtsdorf': 3011,
    'Ahlsdorf': 3011,
    'Aken': 3011,
    'Allstedt': 3011,
    'Alsleben': 3011,
    'Angern': 3011,
    'Angersdorf': 3011,
    'Annaburg': 3011,
    'Apollensdorf': 3011,
    'Arneburg': 3011,
    'Aschersleben': 3011,
    'Atzendorf': 3011,
    'Ausleben': 3011,
    'Baalberge': 3011,
    'Bad Bibra': 3011,
    'Bad Dürrenberg': 3011,
    'Bad Kösen': 3011,
    'Bad Lauchstädt': 3011,
    'Bad Schmiedeberg': 3011,
    'Bad Suderode': 3011,
    'Ballenstedt': 3011,
    'Barby': 3011,
    'Barleben': 3011,
    'Barnstädt': 3011,
    'Beesenlaublingen': 3011,
    'Beesenstedt': 3011,
    'Beetzendorf': 3011,
    'Belleben': 3011,
    'Benndorf': 3011,
    'Benneckenstein': 3011,
    'Bennstedt': 3011,
    'Bennungen': 3011,
    'Berga': 3011,
    'Bergwitz': 3011,
    'Bernburg': 3011,
    'Beuna': 3011,
    'Biederitz': 3011,
    'Biere': 3011,
    'Bismark': 3011,
    'Bitterfeld-Wolfen': 3011,
    'Blankenburg': 3011,
    'Blankenheim': 3011,
    'Bobbau': 3011,
    'Borne': 3011,
    'Braschwitz': 3011,
    'Braunsbedra': 3011,
    'Brehna': 3011,
    'Brumby': 3011,
    'Burg bei Magdeburg': 3011,
    'Burgwerben': 3011,
    'Calbe': 3011,
    'Calvörde': 3011,
    'Cochstedt': 3011,
    'Colbitz': 3011,
    'Coswig': 3011,
    'Dahlenwarsleben': 3011,
    'Darlingerode': 3011,
    'Derenburg': 3011,
    'Dessau': 3011,
    'Deuben': 3011,
    'Diesdorf': 3011,
    'Dieskau': 3011,
    'Ditfurt': 3011,
    'Dobien': 3011,
    'Dölbau': 3011,
    'Döllnitz': 3011,
    'Domersleben': 3011,
    'Droyßig': 3011,
    'Drübeck': 3011,
    'Ebendorf': 3011,
    'Eckartsberga': 3011,
    'Edderitz': 3011,
    'Edersleben': 3011,
    'Egeln': 3011,
    'Eggersdorf': 3011,
    'Eichenbarleben': 3011,
    'Eickendorf': 3011,
    'Eilsleben': 3011,
    'Eisleben Lutherstadt': 3011,
    'Elbingerode': 3011,
    'Elster': 3011,
    'Erdeborn': 3011,
    'Ermlitz': 3011,
    'Erxleben': 3011,
    'Farnstädt': 3011,
    'Flechtingen': 3011,
    'Flessau': 3011,
    'Förderstedt': 3011,
    'Frankleben': 3011,
    'Freyburg': 3011,
    'Friedersdorf': 3011,
    'Friedrichsbrunn': 3011,
    'Friedrichstadt': 3011,
    'Frose': 3011,
    'Gardelegen': 3011,
    'Gatersleben': 3011,
    'Genthin': 3011,
    'Gerbstedt': 3011,
    'Gernrode': 3011,
    'Gerwisch': 3011,
    'Geusa': 3011,
    'Giersleben': 3011,
    'Glindenberg': 3011,
    'Glöthe': 3011,
    'Goldbeck': 3011,
    'Gommern': 3011,
    'Görzig': 3011,
    'Goseck': 3011,
    'Gräfenhainichen': 3011,
    'Granschütz': 3011,
    'Greppin': 3011,
    'Gröbers': 3011,
    'Gröbzig': 3011,
    'Gröningen': 3011,
    'Groß Ammensleben': 3011,
    'Groß Börnecke': 3011,
    'Groß Quenstedt': 3011,
    'Groß Rodensleben': 3011,
    'Groß Rosenburg': 3011,
    'Groß Santersleben': 3011,
    'Großkayna': 3011,
    'Großkorbetha': 3011,
    'Großkugel': 3011,
    'Großörner': 3011,
    'Günthersdorf': 3011,
    'Güsten': 3011,
    'Gutenberg': 3011,
    'Hadmersleben': 3011,
    'Halberstadt': 3011,
    'Haldensleben I': 3011,
    'Halle (Saale)': 3011,
    'Halle Neustadt': 3011,
    'Harbke': 3011,
    'Harsleben': 3011,
    'Harzgerode': 3011,
    'Hasselfelde': 3011,
    'Havelberg': 3011,
    'Hecklingen': 3011,
    'Hedersleben': 3011,
    'Helbra': 3011,
    'Hergisdorf': 3011,
    'Hermsdorf': 3011,
    'Hettstedt': 3011,
    'Heudeber': 3011,
    'Hohendodeleben': 3011,
    'Hohenmölsen': 3011,
    'Hohenthurm': 3011,
    'Hohenwarsleben': 3011,
    'Hohenwarthe': 3011,
    'Höhnstedt': 3011,
    'Holleben': 3011,
    'Holzdorf': 3011,
    'Holzweißig': 3011,
    'Hornhausen': 3011,
    'Hötensleben': 3011,
    'Hoym': 3011,
    'Hüttenrode': 3011,
    'Huy-Neinstedt': 3011,
    'Ilberstedt': 3011,
    'Ilsenburg': 3011,
    'Irxleben': 3011,
    'Jävenitz': 3011,
    'Jerichow': 3011,
    'Jessen': 3011,
    'Jeßnitz': 3011,
    'Kalbe': 3011,
    'Karsdorf': 3011,
    'Kayna': 3011,
    'Kelbra': 3011,
    'Kemberg': 3011,
    'Klein Wanzleben': 3011,
    'Klieken': 3011,
    'Klietz': 3011,
    'Klostermansfeld': 3011,
    'Klötze': 3011,
    'Könnern': 3011,
    'Köthen': 3011,
    'Kötzschau': 3011,
    'Kretzschau': 3011,
    'Kroppenstedt': 3011,
    'Kropstädt': 3011,
    'Krumpa': 3011,
    'Kusey': 3011,
    'Landsberg': 3011,
    'Langeln': 3011,
    'Langenbogen': 3011,
    'Langendorf': 3011,
    'Langeneichstädt': 3011,
    'Langenstein': 3011,
    'Laucha': 3011,
    'Leißling': 3011,
    'Leitzkau': 3011,
    'Letzlingen': 3011,
    'Leuna': 3011,
    'Lieskau': 3011,
    'Lindau': 3011,
    'Löbejün': 3011,
    'Löberitz': 3011,
    'Loburg': 3011,
    'Lochau': 3011,
    'Lostau': 3011,
    'Lüderitz': 3011,
    'Luftkurort Arendsee': 3011,
    'Lützen': 3011,
    'Magdeburg': 3011,
    'Mansfeld': 3011,
    'Mehringen': 3011,
    'Meitzendorf': 3011,
    'Merseburg': 3011,
    'Mieste': 3011,
    'Möckern': 3011,
    'Möhlau': 3011,
    'Möser': 3011,
    'Mücheln': 3011,
    'Mühlanger': 3011,
    'Muldenstein': 3011,
    'Muschwitz': 3011,
    'Nachterstedt': 3011,
    'Nauendorf': 3011,
    'Naumburg': 3011,
    'Nebra': 3011,
    'Neinstedt': 3011,
    'Nessa': 3011,
    'Neue Neustadt': 3011,
    'Neundorf': 3011,
    'Niederndodeleben': 3011,
    'Niemberg': 3011,
    'Nienburg/Saale': 3011,
    'Nudersdorf': 3011,
    'Oberröblingen': 3011,
    'Obhausen': 3011,
    'Oebisfelde': 3011,
    'Oppin': 3011,
    'Oranienbaum': 3011,
    'Oschersleben': 3011,
    'Osterburg': 3011,
    'Osterfeld': 3011,
    'Osterhausen': 3011,
    'Osternienburg': 3011,
    'Osternienburger Land': 3011,
    'Osterwieck': 3011,
    'Peißen': 3011,
    'Piesteritz': 3011,
    'Plötzkau': 3011,
    'Plötzky': 3011,
    'Polleben': 3011,
    'Pouch': 3011,
    'Pratau': 3011,
    'Prettin': 3011,
    'Pretzier': 3011,
    'Pretzsch': 3011,
    'Prittitz': 3011,
    'Quedlinburg': 3011,
    'Queis': 3011,
    'Quellendorf': 3011,
    'Querfurt': 3011,
    'Radegast': 3011,
    'Radis': 3011,
    'Raguhn': 3011,
    'Ramsin': 3011,
    'Raßnitz': 3011,
    'Reichardtswerben': 3011,
    'Reußen': 3011,
    'Rieder': 3011,
    'Riestedt': 3011,
    'Röblingen am See': 3011,
    'Rodleben': 3011,
    'Rogätz': 3011,
    'Roitzsch': 3011,
    'Roßla': 3011,
    'Roßlau': 3011,
    'Rottleberode': 3011,
    'Rübeland': 3011,
    'Salzmünde': 3011,
    'Salzwedel': 3011,
    'Samswegen': 3011,
    'Sandau': 3011,
    'Sandersdorf': 3011,
    'Sandersleben': 3011,
    'Sangerhausen': 3011,
    'Schafstädt': 3011,
    'Schermen': 3011,
    'Schkopau': 3011,
    'Schlaitz': 3011,
    'Schneidlingen': 3011,
    'Schochwitz': 3011,
    'Schönburg': 3011,
    'Schönebeck': 3011,
    'Schönhausen': 3011,
    'Schraplau': 3011,
    'Schwanebeck': 3011,
    'Seehausen': 3011,
    'Sennewitz': 3011,
    'Seyda': 3011,
    'Siersleben': 3011,
    'Sommersdorf': 3011,
    'Spergau': 3011,
    'Stapelburg': 3011,
    'Staßfurt': 3011,
    'Stedten': 3011,
    'Stendal': 3011,
    'Stolberg': 3011,
    'Stößen': 3011,
    'Ströbeck': 3011,
    'Süplingen': 3011,
    'Tangerhütte': 3011,
    'Tangermünde': 3011,
    'Teicha': 3011,
    'Teuchel': 3011,
    'Teuchern': 3011,
    'Teutschenthal': 3011,
    'Thale': 3011,
    'Thalheim': 3011,
    'Theißen': 3011,
    'Timmenrode': 3011,
    'Tollwitz': 3011,
    'Trebitz': 3011,
    'Tucheim': 3011,
    'Uchtspringe': 3011,
    'Uenglingen': 3011,
    'Uftrungen': 3011,
    'Uichteritz': 3011,
    'Ummendorf': 3011,
    'Unseburg': 3011,
    'Veckenstedt': 3011,
    'Vockerode': 3011,
    'Volkstedt': 3011,
    'Völpke': 3011,
    'Wahlitz': 3011,
    'Wallendorf': 3011,
    'Wallhausen': 3011,
    'Wallwitz': 3011,
    'Wansleben': 3011,
    'Wanzleben': 3011,
    'Wasserleben': 3011,
    'Weddersleben': 3011,
    'Wefensleben': 3011,
    'Weferlingen': 3011,
    'Wegeleben': 3011,
    'Weißandt-Gölzau': 3011,
    'Weißenfels': 3011,
    'Wellen': 3011,
    'Welsleben': 3011,
    'Wernigerode': 3011,
    'Westeregeln': 3011,
    'Westerhausen': 3011,
    'Wetterzeube': 3011,
    'Wettin': 3011,
    'Wiederstedt': 3011,
    'Wimmelburg': 3011,
    'Wippra': 3011,
    'Wittenberg': 3011,
    'Wolfen': 3011,
    'Wolferode': 3011,
    'Wolmirsleben': 3011,
    'Wolmirstedt': 3011,
    'Wörlitz': 3011,
    'Wulfen': 3011,
    'Zahna': 3011,
    'Zappendorf': 3011,
    'Zeitz': 3011,
    'Zerbst': 3011,
    'Zielitz': 3011,
    'Zörbig': 3011,
    'Zöschen': 3011,
    'Zscherben': 3011,
    'Zscherndorf': 3011,
    'Zschornewitz': 3011,
    'Achtrup': 3005,
    'Ahrensbök': 3005,
    'Ahrensburg': 3005,
    'Albersdorf': 3005,
    'Alt Duvenstedt': 3005,
    'Altenholz': 3005,
    'Altenkrempe': 3005,
    'Alveslohe': 3005,
    'Appen': 3005,
    'Ascheberg': 3005,
    'Aukrug': 3005,
    'Bad Bramstedt': 3005,
    'Bad Oldesloe': 3005,
    'Bad Schwartau': 3005,
    'Bad Segeberg': 3005,
    'Bargfeld-Stegen': 3005,
    'Bargteheide': 3005,
    'Bark': 3005,
    'Barkelsby': 3005,
    'Barmstedt': 3005,
    'Barsbüttel': 3005,
    'Berkenthin': 3005,
    'Blekendorf': 3005,
    'Bokholt-Hanredder': 3005,
    'Bollingstedt': 3005,
    'Bönebüttel': 3005,
    'Bönningstedt': 3005,
    'Boostedt': 3005,
    'Bordelum': 3005,
    'Bordesholm': 3005,
    'Borgstedt': 3005,
    'Bornhöved': 3005,
    'Börnsen': 3005,
    'Borstel-Hohenraden': 3005,
    'Bosau': 3005,
    'Bösdorf': 3005,
    'Bovenau': 3005,
    'Brande-Hörnerkirchen': 3005,
    'Bredenbek': 3005,
    'Bredstedt': 3005,
    'Breiholz': 3005,
    'Breitenburg': 3005,
    'Breitenfelde': 3005,
    'Brekendorf': 3005,
    'Breklum': 3005,
    'Brokdorf': 3005,
    'Brokstedt': 3005,
    'Brügge': 3005,
    'Brunsbüttel': 3005,
    'Büchen': 3005,
    'Buchholz': 3005,
    'Büdelsdorf': 3005,
    'Burg': 3005,
    'Burg auf Fehmarn': 3005,
    'Busdorf': 3005,
    'Büsum': 3005,
    'Dägeling': 3005,
    'Dahme': 3005,
    'Damp': 3005,
    'Dänischenhagen': 3005,
    'Dannewerk': 3005,
    'Dassendorf': 3005,
    'Delingsdorf': 3005,
    'Dobersdorf': 3005,
    'Dollerup': 3005,
    'Drelsdorf': 3005,
    'Eckernförde': 3005,
    'Eddelak': 3005,
    'Eggebek': 3005,
    'Ellerau': 3005,
    'Ellerbek': 3005,
    'Ellerhoop': 3005,
    'Elmenhorst': 3005,
    'Elmshorn': 3005,
    'Elsdorf-Westermühlen': 3005,
    'Emkendorf': 3005,
    'Emmelsbüll-Horsbüll': 3005,
    'Erfde': 3005,
    'Escheburg': 3005,
    'Eutin': 3005,
    'Fahrdorf': 3005,
    'Fahrenkrug': 3005,
    'Fehmarn': 3005,
    'Felde': 3005,
    'Felm': 3005,
    'Fleckeby': 3005,
    'Flensburg': 3005,
    'Flintbek': 3005,
    'Fockbek': 3005,
    'Freienwill': 3005,
    'Friedrichskoog': 3005,
    'Friedrichstadt': 3005,
    'Garding': 3005,
    'Geesthacht': 3005,
    'Gelting': 3005,
    'Gettorf': 3005,
    'Giekau': 3005,
    'Glinde': 3005,
    'Glücksburg': 3005,
    'Glückstadt': 3005,
    'Gnutz': 3005,
    'Goel': 3005,
    'Grebin': 3005,
    'Gremersdorf': 3005,
    'Grömitz': 3005,
    'Grönwohld': 3005,
    'Groß Grönau': 3005,
    'Groß Kummerfeld': 3005,
    'Groß Rheide': 3005,
    'Groß Wittensee': 3005,
    'Großenaspe': 3005,
    'Grossenbrode': 3005,
    'Großensee': 3005,
    'Großenwiehe': 3005,
    'Großhansdorf': 3005,
    'Großsolt': 3005,
    'Grube': 3005,
    'Gudow': 3005,
    'Gülzow': 3005,
    'Güster': 3005,
    'Halstenbek': 3005,
    'Hamberge': 3005,
    'Hamdorf': 3005,
    'Hammoor': 3005,
    'Handewitt': 3005,
    'Hanerau-Hademarschen': 3005,
    'Harrislee': 3005,
    'Hartenholm': 3005,
    'Haselau': 3005,
    'Haseldorf': 3005,
    'Hasloh': 3005,
    'Hattstedt': 3005,
    'Heide': 3005,
    'Heidgraben': 3005,
    'Heikendorf': 3005,
    'Heiligenhafen': 3005,
    'Heiligenstedten': 3005,
    'Heist': 3005,
    'Helgoland': 3005,
    'Hemdingen': 3005,
    'Hemmingstedt': 3005,
    'Hennstedt': 3005,
    'Henstedt-Ulzburg': 3005,
    'Heringsdorf': 3005,
    'Herzhorn': 3005,
    'Hetlingen': 3005,
    'Hitzhusen': 3005,
    'Hochdonn': 3005,
    'Hohenaspe': 3005,
    'Hohenfelde': 3005,
    'Hohenlockstedt': 3005,
    'Hohenwestedt': 3005,
    'Hohn': 3005,
    'Hoisdorf': 3005,
    'Hollingstedt': 3005,
    'Holm': 3005,
    'Holtsee': 3005,
    'Horst': 3005,
    'Hürup': 3005,
    'Husum': 3005,
    'Itzehoe': 3005,
    'Itzstedt': 3005,
    'Jarplund-Weding': 3005,
    'Jerrishoe': 3005,
    'Jersbek': 3005,
    'Jevenstedt': 3005,
    'Jübek': 3005,
    'Kaltenkirchen': 3005,
    'Kappeln': 3005,
    'Kasseedorf': 3005,
    'Kastorf': 3005,
    'Kayhude': 3005,
    'Keitum': 3005,
    'Kellenhusen': 3005,
    'Kellinghusen': 3005,
    'Kiebitzreihe': 3005,
    'Kiel': 3005,
    'Kisdorf': 3005,
    'Klausdorf': 3005,
    'Klein Nordende': 3005,
    'Klein Offenseth-Sparrieshoop': 3005,
    'Klein Rönnau': 3005,
    'Kollmar': 3005,
    'Kölln-Reisiek': 3005,
    'Kosel': 3005,
    'Krempe': 3005,
    'Kremperheide': 3005,
    'Kronshagen': 3005,
    'Kropp': 3005,
    'Kröppelshagen-Fahrendorf': 3005,
    'Krummesse': 3005,
    'Kuddewörde': 3005,
    'Kummerfeld': 3005,
    'Laboe': 3005,
    'Ladelund': 3005,
    'Lägerdorf': 3005,
    'Langballig': 3005,
    'Langenhorn': 3005,
    'Langstedt': 3005,
    'Langwedel': 3005,
    'Lasbek': 3005,
    'Lauenburg': 3005,
    'Leck': 3005,
    'Leezen': 3005,
    'Lehe': 3005,
    'Lehmkuhlen': 3005,
    'Lensahn': 3005,
    'Lentföhrden': 3005,
    'Linau': 3005,
    'Lindau': 3005,
    'Lindewitt': 3005,
    'List': 3005,
    'Lohe-Rickelshof': 3005,
    'Lübeck': 3005,
    'Lunden': 3005,
    'Lürschau': 3005,
    'Lütjenburg': 3005,
    'Lütjensee': 3005,
    'Malente': 3005,
    'Martensrade': 3005,
    'Meldorf': 3005,
    'Melsdorf': 3005,
    'Mielkendorf': 3005,
    'Mildstedt': 3005,
    'Mohrkirch': 3005,
    'Molfsee': 3005,
    'Mölln': 3005,
    'Mönkeberg': 3005,
    'Moorrege': 3005,
    'Munkbrarup': 3005,
    'Münsterdorf': 3005,
    'Nahe': 3005,
    'Negernbötel': 3005,
    'Neuberend': 3005,
    'Neudorf-Bornstein': 3005,
    'Neukirchen': 3005,
    'Neumünster': 3005,
    'Neustadt in Holstein': 3005,
    'Neuwittenbek': 3005,
    'Niebüll': 3005,
    'Nindorf': 3005,
    'Norderstedt': 3005,
    'Nordhastedt': 3005,
    'Nortorf': 3005,
    'Nübbel': 3005,
    'Nusse': 3005,
    'Nützen': 3005,
    'Oelixdorf': 3005,
    'Oeversee': 3005,
    'Oldenburg in Holstein': 3005,
    'Oldendorf': 3005,
    'Oldenswort': 3005,
    'Osdorf': 3005,
    'Ostenfeld': 3005,
    'Osterrönfeld': 3005,
    'Oststeinbek': 3005,
    'Owschlag': 3005,
    'Padenstedt': 3005,
    'Pahlen': 3005,
    'Panker': 3005,
    'Pellworm': 3005,
    'Pinneberg': 3005,
    'Plön': 3005,
    'Pölitz': 3005,
    'Preetz': 3005,
    'Prisdorf': 3005,
    'Probsteierhagen': 3005,
    'Pronstorf': 3005,
    'Quarnbek': 3005,
    'Quern': 3005,
    'Quickborn': 3005,
    'Raisdorf': 3005,
    'Rantrum': 3005,
    'Ratekau': 3005,
    'Ratzeburg': 3005,
    'Reinbek': 3005,
    'Reinfeld': 3005,
    'Rellingen': 3005,
    'Rendsburg': 3005,
    'Rickert': 3005,
    'Rickling': 3005,
    'Riepsdorf': 3005,
    'Rieseby': 3005,
    'Risum-Lindholm': 3005,
    'Ruhwinkel': 3005,
    'Rümpel': 3005,
    'Sandesneben': 3005,
    'Sankelmark': 3005,
    'Sankt Margarethen': 3005,
    'Sankt Michaelisdonn': 3005,
    'Sankt Peter-Ording': 3005,
    'Satrup': 3005,
    'Schaalby': 3005,
    'Schacht-Audorf': 3005,
    'Schafflund': 3005,
    'Scharbeutz': 3005,
    'Schashagen': 3005,
    'Schellhorn': 3005,
    'Schenefeld': 3005,
    'Schleswig': 3005,
    'Schmalfeld': 3005,
    'Schobüll': 3005,
    'Schönberg': 3005,
    'Schönkirchen': 3005,
    'Schönwalde am Bungsberg': 3005,
    'Schuby': 3005,
    'Schülp': 3005,
    'Schwabstedt': 3005,
    'Schwarzenbek': 3005,
    'Seedorf': 3005,
    'Selent': 3005,
    'Seth': 3005,
    'Siek': 3005,
    'Sierksdorf': 3005,
    'Sievershütten': 3005,
    'Sieverstedt': 3005,
    'Silberstedt': 3005,
    'Sörup': 3005,
    'Stadum': 3005,
    'Stapelfeld': 3005,
    'Steinberg': 3005,
    'Steinbergkirche': 3005,
    'Sterup': 3005,
    'Stockelsdorf': 3005,
    'Stolpe': 3005,
    'Strande': 3005,
    'Struvenhütten': 3005,
    'Süderbrarup': 3005,
    'Süderlügum': 3005,
    'Süderstapel': 3005,
    'Sülfeld': 3005,
    'Süsel': 3005,
    'Sylt-Ost': 3005,
    'Tangstedt': 3005,
    'Tarp': 3005,
    'Tating': 3005,
    'Tellingstedt': 3005,
    'Timmaspe': 3005,
    'Timmendorfer Strand': 3005,
    'Tinnum': 3005,
    'Todenbüttel': 3005,
    'Todendorf': 3005,
    'Todesfelde': 3005,
    'Tolk': 3005,
    'Tönning': 3005,
    'Tornesch': 3005,
    'Trappenkamp': 3005,
    'Travemünde': 3005,
    'Treia': 3005,
    'Tremsbüttel': 3005,
    'Trittau': 3005,
    'Tüttendorf': 3005,
    'Uetersen': 3005,
    'Vaale': 3005,
    'Viöl': 3005,
    'Waabs': 3005,
    'Wacken': 3005,
    'Wahlstedt': 3005,
    'Wanderup': 3005,
    'Wangels': 3005,
    'Wankendorf': 3005,
    'Wasbek': 3005,
    'Wattenbek': 3005,
    'Weddelbrook': 3005,
    'Weddingstedt': 3005,
    'Wedel': 3005,
    'Wees': 3005,
    'Wendtorf': 3005,
    'Wentorf bei Hamburg': 3005,
    'Wesselburen': 3005,
    'Wesseln': 3005,
    'Westensee': 3005,
    'Wester-Ohrstedt': 3005,
    'Westerhorn': 3005,
    'Westerland': 3005,
    'Westerrönfeld': 3005,
    'Wewelsfleth': 3005,
    'Wiemersdorf': 3005,
    'Wilster': 3005,
    'Windeby': 3005,
    'Witzhave': 3005,
    'Wohltorf': 3005,
    'Wöhrden': 3005,
    'Wrist': 3005,
    'Wyk auf Föhr': 3005,
    'Zarpen': 3005,
    'Altenburg': 3015,
    'Altenfeld': 3015,
    'Altengottern': 3015,
    'Altkirchen': 3015,
    'Anrode': 3015,
    'Apfelstädt': 3015,
    'Apolda': 3015,
    'Arenshausen': 3015,
    'Arnstadt': 3015,
    'Artern': 3015,
    'Auleben': 3015,
    'Auma': 3015,
    'Bad Berka': 3015,
    'Bad Blankenburg': 3015,
    'Bad Frankenhausen': 3015,
    'Bad Klosterlausnitz': 3015,
    'Bad Köstritz': 3015,
    'Bad Langensalza': 3015,
    'Bad Liebenstein': 3015,
    'Bad Lobenstein': 3015,
    'Bad Salzungen': 3015,
    'Bad Sulza': 3015,
    'Bad Tennstedt': 3015,
    'Barchfeld': 3015,
    'Behringen': 3015,
    'Benshausen': 3015,
    'Berga': 3015,
    'Berlingerode': 3015,
    'Berlstedt': 3015,
    'Bischofferode': 3015,
    'Blankenhain': 3015,
    'Bleicherode': 3015,
    'Brahmenau': 3015,
    'Brehme': 3015,
    'Breitenbach': 3015,
    'Breitenworbis': 3015,
    'Breitungen': 3015,
    'Brotterode': 3015,
    'Bucha': 3015,
    'Bufleben': 3015,
    'Bürgel': 3015,
    'Buttelstedt': 3015,
    'Buttlar': 3015,
    'Buttstädt': 3015,
    'Büttstedt': 3015,
    'Camburg': 3015,
    'Clingen': 3015,
    'Crawinkel': 3015,
    'Creuzburg': 3015,
    'Dachwig': 3015,
    'Dankmarshausen': 3015,
    'Dermbach': 3015,
    'Deuna': 3015,
    'Dingelstädt': 3015,
    'Dippach': 3015,
    'Döllstädt': 3015,
    'Dorndorf': 3015,
    'Ebeleben': 3015,
    'Effelder': 3015,
    'Eisenach': 3015,
    'Eisenberg': 3015,
    'Eisfeld': 3015,
    'Elgersburg': 3015,
    'Ellrich': 3015,
    'Elxleben': 3015,
    'Erfurt': 3015,
    'Ernstroda': 3015,
    'Fambach': 3015,
    'Finsterbergen': 3015,
    'Föritz': 3015,
    'Frankenheim': 3015,
    'Frauenprießnitz': 3015,
    'Frauenwald': 3015,
    'Freienbessingen': 3015,
    'Friedrichroda': 3015,
    'Friemar': 3015,
    'Gangloffsömmern': 3015,
    'Gebesee': 3015,
    'Gehren': 3015,
    'Geisa': 3015,
    'Geisleden': 3015,
    'Geismar': 3015,
    'Georgenthal': 3015,
    'Gera': 3015,
    'Geraberg': 3015,
    'Gernrode': 3015,
    'Gerstungen': 3015,
    'Geschwenda': 3015,
    'Gleichamberg': 3015,
    'Goldbach': 3015,
    'Görsbach': 3015,
    'Gößnitz': 3015,
    'Goßwitz': 3015,
    'Gotha': 3015,
    'Grabsleben': 3015,
    'Gräfenhain': 3015,
    'Gräfenroda': 3015,
    'Greiz': 3015,
    'Greußen': 3015,
    'Großbartloff': 3015,
    'Großbodungen': 3015,
    'Großbreitenbach': 3015,
    'Großenehrich': 3015,
    'Großengottern': 3015,
    'Großenstein': 3015,
    'Großmonra': 3015,
    'Großrudestedt': 3015,
    'Haina': 3015,
    'Haßleben': 3015,
    'Heilbad Heiligenstadt': 3015,
    'Heldrungen': 3015,
    'Hellingen': 3015,
    'Herbsleben': 3015,
    'Heringen': 3015,
    'Hermsdorf': 3015,
    'Herschdorf': 3015,
    'Heyerode': 3015,
    'Hildburghausen': 3015,
    'Hohenleuben': 3015,
    'Hörselgau': 3015,
    'Hundeshagen': 3015,
    'Ichtershausen': 3015,
    'Ifta': 3015,
    'Ilfeld': 3015,
    'Ilmenau': 3015,
    'Immelborn': 3015,
    'Ingersleben': 3015,
    'Jena': 3015,
    'Jüchsen': 3015,
    'Judenbach': 3015,
    'Kahla': 3015,
    'Kaltennordheim': 3015,
    'Kaltenwestheim': 3015,
    'Kamsdorf': 3015,
    'Kannawurf': 3015,
    'Katharinenberg': 3015,
    'Katzhütte': 3015,
    'Kaulsdorf': 3015,
    'Kindelbrück': 3015,
    'Kirchheim': 3015,
    'Kirchworbis': 3015,
    'Kleinfurra': 3015,
    'Kleinwenden': 3015,
    'Klettbach': 3015,
    'Kölleda': 3015,
    'Königsee': 3015,
    'Könitz': 3015,
    'Körner': 3015,
    'Kraftsdorf': 3015,
    'Kranichfeld': 3015,
    'Krauthausen': 3015,
    'Kriebitzsch': 3015,
    'Krölpa': 3015,
    'Kromsdorf': 3015,
    'Kühndorf': 3015,
    'Küllstedt': 3015,
    'Langenorla': 3015,
    'Langenwetzendorf': 3015,
    'Langewiesen': 3015,
    'Langula': 3015,
    'Lauchröden': 3015,
    'Lauscha': 3015,
    'Lehesten': 3015,
    'Leimbach': 3015,
    'Leinefelde-Worbis': 3015,
    'Leutenberg': 3015,
    'Lichte': 3015,
    'Löbichau': 3015,
    'Lucka': 3015,
    'Luisenthal': 3015,
    'Magdala': 3015,
    'Marksuhl': 3015,
    'Masserberg': 3015,
    'Mechterstädt': 3015,
    'Meiningen': 3015,
    'Mellenbach-Glasbach': 3015,
    'Mellingen': 3015,
    'Mengersgereuth-Hämmern': 3015,
    'Menteroda': 3015,
    'Meuselbach': 3015,
    'Meuselwitz': 3015,
    'Mihla': 3015,
    'Milz': 3015,
    'Mohlsdorf': 3015,
    'Molschleben': 3015,
    'Mönchenholzhausen': 3015,
    'Mühlhausen': 3015,
    'Münchenbernsdorf': 3015,
    'Neudietendorf': 3015,
    'Neuhaus': 3015,
    'Neuhaus am Rennweg': 3015,
    'Neuhaus-Schierschnitz': 3015,
    'Neustadt am Rennsteig': 3015,
    'Neustadt an der Orla': 3015,
    'Niederdorla': 3015,
    'Niederorschel': 3015,
    'Niederroßla': 3015,
    'Niedersachswerfen': 3015,
    'Niederzimmern': 3015,
    'Nöbdenitz': 3015,
    'Nobitz': 3015,
    'Nohra': 3015,
    'Nordhausen': 3015,
    'Oberdorla': 3015,
    'Oberhof': 3015,
    'Obermaßfeld-Grimmenthal': 3015,
    'Obermehler': 3015,
    'Oberweißbach': 3015,
    'Oldisleben': 3015,
    'Oppurg': 3015,
    'Orlamünde': 3015,
    'Oßmanstedt': 3015,
    'Pappenheim': 3015,
    'Plaue': 3015,
    'Pölzig': 3015,
    'Ponitz': 3015,
    'Pößneck': 3015,
    'Probstzella': 3015,
    'Ranis': 3015,
    'Rastenberg': 3015,
    'Remptendorf': 3015,
    'Riethnordhausen': 3015,
    'Ringleben': 3015,
    'Rohr': 3015,
    'Römhild': 3015,
    'Ronneburg': 3015,
    'Rositz': 3015,
    'Roßleben': 3015,
    'Rothenstein': 3015,
    'Rottenbach': 3015,
    'Rudolstadt': 3015,
    'Ruhla': 3015,
    'Saalfeld': 3015,
    'Saara': 3015,
    'Sankt Gangloff': 3015,
    'Sankt Kilian': 3015,
    'Schalkau': 3015,
    'Schernberg': 3015,
    'Schkölen': 3015,
    'Schleid': 3015,
    'Schleiz': 3015,
    'Schleusingen': 3015,
    'Schloßvippach': 3015,
    'Schlotheim': 3015,
    'Schmalkalden': 3015,
    'Schmiedefeld': 3015,
    'Schmiedefeld am Rennsteig': 3015,
    'Schmölln': 3015,
    'Schönstedt': 3015,
    'Schwallungen': 3015,
    'Schwarza': 3015,
    'Schweina': 3015,
    'Seebach': 3015,
    'Seebergen': 3015,
    'Seelingstädt': 3015,
    'Sitzendorf': 3015,
    'Sollstedt': 3015,
    'Sömmerda': 3015,
    'Sondershausen': 3015,
    'Sonneberg': 3015,
    'Sonneborn': 3015,
    'Stadtilm': 3015,
    'Stadtlengsfeld': 3015,
    'Stadtroda': 3015,
    'Steinach': 3015,
    'Steinbach': 3015,
    'Steinbach-Hallenberg': 3015,
    'Steinheid': 3015,
    'Straußfurt': 3015,
    'Stützerbach': 3015,
    'Suhl': 3015,
    'Tabarz': 3015,
    'Tambach-Dietharz': 3015,
    'Tanna': 3015,
    'Tautenhain': 3015,
    'Teichwolframsdorf': 3015,
    'Teistungen': 3015,
    'Thal': 3015,
    'Themar': 3015,
    'Tiefenort': 3015,
    'Treffurt': 3015,
    'Triebes': 3015,
    'Triptis': 3015,
    'Trusetal': 3015,
    'Uder': 3015,
    'Unterbreizbach': 3015,
    'Untermaßfeld': 3015,
    'Unterwellenborn': 3015,
    'Urbach': 3015,
    'Uthleben': 3015,
    'Vacha': 3015,
    'Veilsdorf': 3015,
    'Viernau': 3015,
    'Voigtstedt': 3015,
    'Völkershausen': 3015,
    'Walldorf': 3015,
    'Walschleben': 3015,
    'Waltershausen': 3015,
    'Wandersleben': 3015,
    'Wasungen': 3015,
    'Weida': 3015,
    'Weimar': 3015,
    'Weißenborn': 3015,
    'Weißenborn-Lüderode': 3015,
    'Weißensee': 3015,
    'Wernshausen': 3015,
    'Wiehe': 3015,
    'Windischleuba': 3015,
    'Wingerode': 3015,
    'Wintersdorf': 3015,
    'Wipperdorf': 3015,
    'Witterda': 3015,
    'Wölfis': 3015,
    'Wolkramshausen': 3015,
    'Worbis': 3015,
    'Wünschendorf': 3015,
    'Wurzbach': 3015,
    'Zella-Mehlis': 3015,
    'Zeulenroda': 3015,
    'Agogo': 48,
    'Bekwai': 48,
    'Ejura': 48,
    'Konongo': 48,
    'Kumasi': 48,
    'Mampong': 48,
    'Obuase': 48,
    'Tafo': 48,
    'Bechem': 53,
    'Berekum': 53,
    'Duayaw-Nkwanta': 53,
    'Japekrom': 53,
    'Kintampo': 53,
    'Sunyani': 53,
    'Techiman': 53,
    'Wenchi': 53,
    'Apam': 52,
    'Cape Coast': 52,
    'Dunkwa': 52,
    'Elmina': 52,
    'Foso': 52,
    'Kasoa': 52,
    'Mumford': 52,
    'Saltpond': 52,
    'Swedru': 52,
    'Winneba': 52,
    'Aburi': 50,
    'Akim Oda': 50,
    'Akim Swedru': 50,
    'Akropong': 50,
    'Akwatia': 50,
    'Asamankese': 50,
    'Begoro': 50,
    'Kibi': 50,
    'Koforidua': 50,
    'Mpraeso': 50,
    'Nsawam': 50,
    'Suhum': 50,
    'Accra': 54,
    'Atsiaman': 54,
    'Dome': 54,
    'Gbawe': 54,
    'Medina Estates': 54,
    'Nungua': 54,
    'Tema': 54,
    'Teshi Old Town': 54,
    'Kpandae': 51,
    'Salaga': 51,
    'Savelugu': 51,
    'Tamale': 51,
    'Yendi': 51,
    'Bawku': 55,
    'Bolgatanga': 55,
    'Navrongo': 55,
    'Wa': 57,
    'Aflao': 56,
    'Anloga': 56,
    'Ho': 56,
    'Hohoe': 56,
    'Keta': 56,
    'Kete Krachi': 56,
    'Kpandu': 56,
    'Aboso': 49,
    'Axim': 49,
    'Bibiani': 49,
    'Prestea': 49,
    'Sekondi-Takoradi': 49,
    'Shama Junction': 49,
    'Takoradi': 49,
    'Tarkwa': 49,
    'Acharnés': 2122,
    'Aegina': 2122,
    'Afidnés': 2122,
    'Aghios Panteleímon': 2122,
    'Agía Marína': 2122,
    'Agía Paraskeví': 2122,
    'Agía Varvára': 2122,
    'Ágioi Anárgyroi': 2122,
    'Agios Dimitrios': 2122,
    'Agios Dimítrios Kropiás': 2122,
    'Agios Ioannis Rentis': 2122,
    'Ágios Stéfanos': 2122,
    'Aiánteio': 2122,
    'Aigáleo': 2122,
    'Álimos': 2122,
    'Ampelákia': 2122,
    'Anávyssos': 2122,
    'Áno Liósia': 2122,
    'Anoixi': 2122,
    'Anthoúsa': 2122,
    'Argithéa': 2122,
    'Argyroúpoli': 2122,
    'Artémida': 2122,
    'Asprópyrgos': 2122,
    'Athens': 2122,
    'Avlónas': 2122,
    'Áyioi Apóstoloi': 2122,
    'Chaïdári': 2122,
    'Cholargós': 2122,
    'Dhafní': 2122,
    'Dhráfi': 2122,
    'Dióni': 2122,
    'Diónysos': 2122,
    'Drapetsóna': 2122,
    'Drosiá': 2122,
    'Ekáli': 2122,
    'Elefsína': 2122,
    'Ellinikó': 2122,
    'Erythrés': 2122,
    'Filothéi': 2122,
    'Fylí': 2122,
    'Galatás': 2122,
    'Galátsi': 2122,
    'Gérakas': 2122,
    'Glyfáda': 2122,
    'Grammatikó': 2122,
    'Ílion': 2122,
    'Ilioúpoli': 2122,
    'Irákleio': 2122,
    'Kaisarianí': 2122,
    'Kálamos': 2122,
    'Kallithéa': 2122,
    'Kalývia Thorikoú': 2122,
    'Kamaterón': 2122,
    'Kapandríti': 2122,
    'Karellás': 2122,
    'Káto Soúlion': 2122,
    'Keratéa': 2122,
    'Keratsíni': 2122,
    'Khalándrion': 2122,
    'Khalkoútsion': 2122,
    'Kifisiá': 2122,
    'Kinéta': 2122,
    'Kipséli': 2122,
    'Kítsi': 2122,
    'Koropí': 2122,
    'Korydallós': 2122,
    'Kouvarás': 2122,
    'Kryonéri': 2122,
    'Kypséli': 2122,
    'Kýthira': 2122,
    'Lávrio': 2122,
    'Leondárion': 2122,
    'Limín Mesoyaías': 2122,
    'Lykóvrysi': 2122,
    'Magoúla': 2122,
    'Mándra': 2122,
    'Marathónas': 2122,
    'Markópoulo': 2122,
    'Markópoulo Oropoú': 2122,
    'Maroúsi': 2122,
    'Megalochóri': 2122,
    'Mégara': 2122,
    'Melíssia': 2122,
    'Metamórfosi': 2122,
    'Moskháton': 2122,
    'Néa Chalkidóna': 2122,
    'Néa Erythraía': 2122,
    'Néa Filadélfeia': 2122,
    'Néa Ionía': 2122,
    'Néa Mákri': 2122,
    'Néa Palátia': 2122,
    'Néa Pentéli': 2122,
    'Néa Péramos': 2122,
    'Néa Smýrni': 2122,
    'Néo Psychikó': 2122,
    'Neos Voutzás': 2122,
    'Níkaia': 2122,
    'Oropós': 2122,
    'Paianía': 2122,
    'Palaiá Fókaia': 2122,
    'Palaió Fáliro': 2122,
    'Pallíni': 2122,
    'Papágou': 2122,
    'Péfki': 2122,
    'Pentéli': 2122,
    'Pérama': 2122,
    'Peristéri': 2122,
    'Petroúpolis': 2122,
    'Pikérmi': 2122,
    'Piraeus': 2122,
    'Polydéndri': 2122,
    'Póros': 2122,
    'Psychikó': 2122,
    'Rafína': 2122,
    'Rodópoli': 2122,
    'Salamína': 2122,
    'Saronída': 2122,
    'Selínia': 2122,
    'Skála Oropoú': 2122,
    'Skarmagkás': 2122,
    'Spáta': 2122,
    'Spétses': 2122,
    'Stamáta': 2122,
    'Távros': 2122,
    'Thrakomakedónes': 2122,
    'Vári': 2122,
    'Varnávas': 2122,
    'Varybóbi': 2122,
    'Vathý': 2122,
    'Vília': 2122,
    'Vlycháda': 2122,
    'Voúla': 2122,
    'Vouliagméni': 2122,
    'Vraná': 2122,
    'Vrilissia': 2122,
    'Výronas': 2122,
    'Ýdra': 2122,
    'Ymittos': 2122,
    'Zefyri': 2122,
    'Zográfos': 2122,
    'Afrátion': 2128,
    'Agía Triáda': 2128,
    'Ágios Geórgios': 2128,
    'Aidipsós': 2128,
    'Akraifnía': 2128,
    'Alíartos': 2128,
    'Alivéri': 2128,
    'Amárynthos': 2128,
    'Amfíkleia': 2128,
    'Ámfissa': 2128,
    'Anthíli': 2128,
    'Antikyra': 2128,
    'Aráchova': 2128,
    'Árma': 2128,
    'Asopía': 2128,
    'Atalánti': 2128,
    'Áyios Konstandínos': 2128,
    'Áyios Nikólaos': 2128,
    'Áyios Thomás': 2128,
    'Chairóneia': 2128,
    'Chalkída': 2128,
    'Delphi': 2128,
    'Dhrosiá': 2128,
    'Dílesi': 2128,
    'Dístomo': 2128,
    'Domokós': 2128,
    'Domvraína': 2128,
    'Eláteia': 2128,
    'Erétria': 2128,
    'Fáros': 2128,
    'Fílla': 2128,
    'Galaxídhion': 2128,
    'Istiaía': 2128,
    'Itéa': 2128,
    'Kainoúryion': 2128,
    'Kaména Voúrla': 2128,
    'Kaparéllion': 2128,
    'Karpenísi': 2128,
    'Kárystos': 2128,
    'Kastélla': 2128,
    'Káto Tithoréa': 2128,
    'Kerasochóri': 2128,
    'Kírra': 2128,
    'Kými': 2128,
    'Kyriáki': 2128,
    'Lamía': 2128,
    'Lianokládhion': 2128,
    'Lidoríki': 2128,
    'Límni': 2128,
    'Livadeiá': 2128,
    'Livanátes': 2128,
    'Loukísia': 2128,
    'Loutrá Aidhipsoú': 2128,
    'Magoúla': 2128,
    'Makrakómi': 2128,
    'Malakónta': 2128,
    'Malesína': 2128,
    'Mantoúdi': 2128,
    'Marmárion': 2128,
    'Martínon': 2128,
    'Mólos': 2128,
    'Mytikas': 2128,
    'Néa Artáki': 2128,
    'Néa Lámpsakos': 2128,
    'Néa Stíra': 2128,
    'Néon Monastírion': 2128,
    'Nomós Evrytanías': 2128,
    'Nomós Fokídos': 2128,
    'Oinófyta': 2128,
    'Omvriakí': 2128,
    'Orchomenós': 2128,
    'Oreoí': 2128,
    'Oxílithos': 2128,
    'Paralía Avlídhos': 2128,
    'Pelasgía': 2128,
    'Pláka Dílesi': 2128,
    'Politiká': 2128,
    'Prokópi': 2128,
    'Psachná': 2128,
    'Rodhítsa': 2128,
    'Roviés': 2128,
    'Schimatári': 2128,
    'Skýros': 2128,
    'Spercheiáda': 2128,
    'Stavrós': 2128,
    'Steíri': 2128,
    'Stylída': 2128,
    'Thespiés': 2128,
    'Thívai': 2128,
    'Vágia': 2128,
    'Vasilikón': 2128,
    'Vathí': 2128,
    'Yimnón': 2128,
    'Ádendro': 2125,
    'Áfytos': 2125,
    'Agía Paraskeví': 2125,
    'Agía Triáda': 2125,
    'Ágio Pnévma': 2125,
    'Ágios Athanásios': 2125,
    'Ágios Geórgios': 2125,
    'Ágios Loukás': 2125,
    'Ágios Nikólaos': 2125,
    'Ágios Pávlos': 2125,
    'Ágios Pétros': 2125,
    'Ágios Spyrídon': 2125,
    'Ágios Vasíleios': 2125,
    'Agkathiá': 2125,
    'Aigínio': 2125,
    'Akrolímni': 2125,
    'Alexándreia': 2125,
    'Alistráti': 2125,
    'Ampeleíes': 2125,
    'Ampelókipoi': 2125,
    'Anatolikó': 2125,
    'Angelochóri': 2125,
    'Ápsalos': 2125,
    'Aravissós': 2125,
    'Aridaía': 2125,
    'Arnaía': 2125,
    'Árnissa': 2125,
    'Arsénio': 2125,
    'Askós': 2125,
    'Asproválta': 2125,
    'Ássiros': 2125,
    'Asvestochóri': 2125,
    'Áthyra': 2125,
    'Axioúpoli': 2125,
    'Axós': 2125,
    'Chalástra': 2125,
    'Chalkidóna': 2125,
    'Charopó': 2125,
    'Chortiátis': 2125,
    'Chrysó': 2125,
    'Chrysochórafa': 2125,
    'Diavatá': 2125,
    'Diavatós': 2125,
    'Díon': 2125,
    'Dravískos': 2125,
    'Drymós': 2125,
    'Édessa': 2125,
    'Efkarpía': 2125,
    'Eirinoúpoli': 2125,
    'Epanomí': 2125,
    'Episkopí': 2125,
    'Évosmos': 2125,
    'Evropós': 2125,
    'Exaplátanos': 2125,
    'Fíliro': 2125,
    'Galatádes': 2125,
    'Galátista': 2125,
    'Gázoros': 2125,
    'Géfyra': 2125,
    'Gerakaroú': 2125,
    'Giannitsá': 2125,
    'Gouménissa': 2125,
    'Ierissós': 2125,
    'Irákleia': 2125,
    'Kalá Déndra': 2125,
    'Kalamariá': 2125,
    'Kalí': 2125,
    'Kallithéa': 2125,
    'Kalochóri': 2125,
    'Kalýves Polygýrou': 2125,
    'Kalývia': 2125,
    'Kampánis': 2125,
    'Kardiá': 2125,
    'Karítsa': 2125,
    'Karyótissa': 2125,
    'Kassándreia': 2125,
    'Kateríni': 2125,
    'Káto Kamíla': 2125,
    'Káto Lipochóri': 2125,
    'Káto Miliá': 2125,
    'Káto Scholári': 2125,
    'Kavallári': 2125,
    'Kilkís': 2125,
    'Kítros': 2125,
    'Kleidí': 2125,
    'Koímisi': 2125,
    'Kolchikón': 2125,
    'Kolindrós': 2125,
    'Kontariótissa': 2125,
    'Kopanós': 2125,
    'Korinós': 2125,
    'Koryfí': 2125,
    'Koufália': 2125,
    'Kouloúra': 2125,
    'Krithiá': 2125,
    'Krýa Vrýsi': 2125,
    'Kýmina': 2125,
    'Lagkadás': 2125,
    'Lagyná': 2125,
    'Lákkoma': 2125,
    'Lefkónas': 2125,
    'Leptokaryá': 2125,
    'Lianovérgi': 2125,
    'Lití': 2125,
    'Litóchoro': 2125,
    'Lófos': 2125,
    'Loutráki': 2125,
    'Loutrós': 2125,
    'Makrochóri': 2125,
    'Makrýgialos': 2125,
    'Mándalo': 2125,
    'Marína': 2125,
    'Mavrothálassa': 2125,
    'Mavrovoúni': 2125,
    'Megáli Panagía': 2125,
    'Melíki': 2125,
    'Melíssi': 2125,
    'Melissochóri': 2125,
    'Meneméni': 2125,
    'Mesiméri': 2125,
    'Mikró Monastíri': 2125,
    'Mitroúsi': 2125,
    'Náousa': 2125,
    'Néa Apollonía': 2125,
    'Néa Éfesos': 2125,
    'Néa Flogitá': 2125,
    'Néa Fókaia': 2125,
    'Néa Kallikráteia': 2125,
    'Néa Magnisía': 2125,
    'Néa Málgara': 2125,
    'Néa Mesimvría': 2125,
    'Néa Michanióna': 2125,
    'Néa Moudhaniá': 2125,
    'Néa Pélla': 2125,
    'Néa Plágia': 2125,
    'Néa Poteídaia': 2125,
    'Néa Potídhaia': 2125,
    'Néa Róda': 2125,
    'Néa Sánta': 2125,
    'Néa Tríglia': 2125,
    'Néa Vrasná': 2125,
    'Néa Zíchni': 2125,
    'Neapoli': 2125,
    'Néo Agionéri': 2125,
    'Néo Petrítsi': 2125,
    'Néo Rýsi': 2125,
    'Néo Soúli': 2125,
    'Neochóri': 2125,
    'Neochoroúda': 2125,
    'Néoi Epivátes': 2125,
    'Néos Marmarás': 2125,
    'Néos Mylótopos': 2125,
    'Néos Skopós': 2125,
    'Nigríta': 2125,
    'Níkiti': 2125,
    'Nisí': 2125,
    'Nomós Chalkidikís': 2125,
    'Nomós Péllis': 2125,
    'Nomós Thessaloníkis': 2125,
    'Ólynthos': 2125,
    'Oraiókastro': 2125,
    'Ormýlia': 2125,
    'Ouranoupolis': 2125,
    'Palaífyto': 2125,
    'Palaiochóri': 2125,
    'Palaiokómi': 2125,
    'Panórama': 2125,
    'Paralía': 2125,
    'Patrída': 2125,
    'Péfka': 2125,
    'Pefkochóri': 2125,
    'Pélla': 2125,
    'Pentálofos': 2125,
    'Pentaplátano': 2125,
    'Peraía': 2125,
    'Perístasi': 2125,
    'Peristerá': 2125,
    'Plagiári': 2125,
    'Platý': 2125,
    'Políchni': 2125,
    'Polýgyros': 2125,
    'Polykárpi': 2125,
    'Polýkastro': 2125,
    'Pontisméno': 2125,
    'Portariá': 2125,
    'Próchoma': 2125,
    'Profítis Ilías': 2125,
    'Prómachoi': 2125,
    'Próti': 2125,
    'Provatás': 2125,
    'Pylaía': 2125,
    'Ritíni': 2125,
    'Rizári': 2125,
    'Rizó': 2125,
    'Rizómata': 2125,
    'Rodolívos': 2125,
    'Sárti': 2125,
    'Sérres': 2125,
    'Sevastianá': 2125,
    'Sfendámi': 2125,
    'Sidirókastro': 2125,
    'Símantra': 2125,
    'Síndos': 2125,
    'Skotoússa': 2125,
    'Skoútari': 2125,
    'Skýdra': 2125,
    'Sochós': 2125,
    'Sosándra': 2125,
    'Sourotí': 2125,
    'Stathmós Mourión': 2125,
    'Stavrós': 2125,
    'Stavroúpoli': 2125,
    'Stratónion': 2125,
    'Strymonikó': 2125,
    'Svorónos': 2125,
    'Sykiá': 2125,
    'Sykiés': 2125,
    'Terpní': 2125,
    'Thérmi': 2125,
    'Thessaloníki': 2125,
    'Triandría': 2125,
    'Tríkala': 2125,
    'Trílofos': 2125,
    'Valteró': 2125,
    'Vamvakófyto': 2125,
    'Vasiliká': 2125,
    'Vathýlakkos': 2125,
    'Vergína': 2125,
    'Véroia': 2125,
    'Vrontoú': 2125,
    'Xilópolis': 2125,
    'Farkadóna': 2124,
    'Fíki': 2124,
    'Gómfoi': 2124,
    'Grizáno': 2124,
    'Kalampáka': 2124,
    'Kastráki': 2124,
    'Megála Kalývia': 2124,
    'Megalochóri': 2124,
    'Oichalía': 2124,
    'Palaiomonástiro': 2124,
    'Palaiópyrgos': 2124,
    'Pigí': 2124,
    'Pýli': 2124,
    'Pyrgetós': 2124,
    'Rízoma': 2124,
    'Taxiárches': 2124,
    'Tríkala': 2124,
    'Vasilikí': 2124,
    'Zárkos': 2124,
    'Agía Foteiní': 2109,
    'Agía Galíni': 2109,
    'Agía Marína': 2109,
    'Agía Varvára': 2109,
    'Ágioi Déka': 2109,
    'Ágios Nikólaos': 2109,
    'Ano Arhanes': 2109,
    'Anógeia': 2109,
    'Arkalochóri': 2109,
    'Asímion': 2109,
    'Atsipópoulo': 2109,
    'Chaniá': 2109,
    'Chóra Sfakíon': 2109,
    'Darátsos': 2109,
    'Eloúnda': 2109,
    'Galatás': 2109,
    'Gázi': 2109,
    'Georgioupolis': 2109,
    'Geráni': 2109,
    'Gérgeri': 2109,
    'Goúrnes': 2109,
    'Gra Liyiá': 2109,
    'Ierápetra': 2109,
    'Irákleion': 2109,
    'Kalýves': 2109,
    'Kastélli': 2109,
    'Kastrí': 2109,
    'Káto Asítai': 2109,
    'Káto Goúves': 2109,
    'Kentrí': 2109,
    'Kíssamos': 2109,
    'Kokkíni Cháni': 2109,
    'Kolympári': 2109,
    'Kritsá': 2109,
    'Krousón': 2109,
    'Limín Khersonísou': 2109,
    'Mália': 2109,
    'Moíres': 2109,
    'Mokhós': 2109,
    'Mourniés': 2109,
    'Mouzourás': 2109,
    'Néa Alikarnassós': 2109,
    'Néa Anatolí': 2109,
    'Neápoli': 2109,
    'Nerokoúros': 2109,
    'Nomós Irakleíou': 2109,
    'Nomós Rethýmnis': 2109,
    'Palaióchora': 2109,
    'Palekastro': 2109,
    'Pánormos': 2109,
    'Pérama': 2109,
    'Perivólia': 2109,
    'Pithári': 2109,
    'Profítis Ilías': 2109,
    'Pýrgos': 2109,
    'Rethymno': 2109,
    'Schísma Eloúndas': 2109,
    'Sísion': 2109,
    'Sitia': 2109,
    'Skalánion': 2109,
    'Soúda': 2109,
    'Stalís': 2109,
    'Thrapsanón': 2109,
    'Tílisos': 2109,
    'Tsikalariá': 2109,
    'Tympáki': 2109,
    'Violí Charáki': 2109,
    'Vrýses': 2109,
    'Zarós': 2109,
    'Zonianá': 2109,
    'Ágios Athanásios': 2120,
    'Alexandroupoli': 2120,
    'Amygdaleónas': 2120,
    'Áratos': 2120,
    'Arísvi': 2120,
    'Arrianá': 2120,
    'Ávato': 2120,
    'Choristí': 2120,
    'Chrysochóri': 2120,
    'Chrysoúpolis': 2120,
    'Didymóteicho': 2120,
    'Dráma': 2120,
    'Echínos': 2120,
    'Elaiochóri': 2120,
    'Eleftherés': 2120,
    'Eleftheroúpolis': 2120,
    'Évlalo': 2120,
    'Féres': 2120,
    'Fillýra': 2120,
    'Fotolívos': 2120,
    'Fteliá': 2120,
    'Genisséa': 2120,
    'Íasmos': 2120,
    'Iliokentima': 2120,
    'Kalampáki': 2120,
    'Kalí Vrýsi': 2120,
    'Kallífytos': 2120,
    'Kalós Agrós': 2120,
    'Kamariótissa': 2120,
    'Kastaniés': 2120,
    'Káto Nevrokópi': 2120,
    'Kavála': 2120,
    'Kavýli': 2120,
    'Keramotí': 2120,
    'Kimméria': 2120,
    'Kokkinóchoma': 2120,
    'Komotiní': 2120,
    'Krinídes': 2120,
    'Kyprínos': 2120,
    'Kýria': 2120,
    'Lagós': 2120,
    'Lávara': 2120,
    'Limenária': 2120,
    'Lýkeio': 2120,
    'Mikrópolis': 2120,
    'Mýki': 2120,
    'Néa Iraklítsa': 2120,
    'Néa Karváli': 2120,
    'Néa Karyá': 2120,
    'Néa Péramos': 2120,
    'Néa Výssa': 2120,
    'Neochóri': 2120,
    'Nikísiani': 2120,
    'Orestiáda': 2120,
    'Páchni': 2120,
    'Palaió Tsiflíki': 2120,
    'Palaiochóri': 2120,
    'Paralía Ofryníou': 2120,
    'Paranésti': 2120,
    'Péplos': 2120,
    'Peteinós': 2120,
    'Petrochóri': 2120,
    'Petroússa': 2120,
    'Potamiá': 2120,
    'Prínos': 2120,
    'Prosotsáni': 2120,
    'Rízia': 2120,
    'Samothráki': 2120,
    'Sápes': 2120,
    'Sélero': 2120,
    'Sitagroí': 2120,
    'Smínthi': 2120,
    'Souflí': 2120,
    'Thásos': 2120,
    'Tycheró': 2120,
    'Vólakas': 2120,
    'Xánthi': 2120,
    'Xiropótamos': 2120,
    'Xylaganí': 2120,
    'Zygós': 2120,
    'Agía Kyriakí': 2110,
    'Anatolí': 2110,
    'Anéza': 2110,
    'Áno Kalentíni': 2110,
    'Árta': 2110,
    'Asprángeloi': 2110,
    'Chalkiádes': 2110,
    'Eksochí': 2110,
    'Eleoúsa': 2110,
    'Filiátes': 2110,
    'Filippiáda': 2110,
    'Graikochóri': 2110,
    'Grammenítsa': 2110,
    'Igoumenítsa': 2110,
    'Ioánnina': 2110,
    'Kalamiá': 2110,
    'Kalpáki': 2110,
    'Kanaláki': 2110,
    'Kardamítsia': 2110,
    'Katsikás': 2110,
    'Kompóti': 2110,
    'Kónitsa': 2110,
    'Kostakioí': 2110,
    'Koutselió': 2110,
    'Loúros': 2110,
    'Metsovo': 2110,
    'Néa Seléfkeia': 2110,
    'Neochóri': 2110,
    'Neochorópoulo': 2110,
    'Néos Oropós': 2110,
    'Nomós Ioannínon': 2110,
    'Pappadátes': 2110,
    'Paramythiá': 2110,
    'Parapótamos': 2110,
    'Párga': 2110,
    'Pediní': 2110,
    'Pérama': 2110,
    'Pérdika': 2110,
    'Péta': 2110,
    'Platariá': 2110,
    'Prámanta': 2110,
    'Préveza': 2110,
    'Rodotópi': 2110,
    'Stavráki': 2110,
    'Thesprotikó': 2110,
    'Tsiflikópoulo': 2110,
    'Voulgaréli': 2110,
    'Vounoplagiá': 2110,
    'Acharávi': 2131,
    'Agios Georgis': 2131,
    'Ágios Matthaíos': 2131,
    'Alepoú': 2131,
    'Ambelókipoi': 2131,
    'Argostólion': 2131,
    'Corfu': 2131,
    'Gáïos': 2131,
    'Gaïtánion': 2131,
    'Itháki': 2131,
    'Kanáli': 2131,
    'Katastárion': 2131,
    'Kontokáli': 2131,
    'Kynopiástes': 2131,
    'Lefkada': 2131,
    'Lefkímmi': 2131,
    'Lithakiá': 2131,
    'Lixoúri': 2131,
    'Mouzaki': 2131,
    'Nomós Kerkýras': 2131,
    'Nomós Zakýnthou': 2131,
    'Perama': 2131,
    'Perivóli': 2131,
    'Póros': 2131,
    'Potamós': 2131,
    'Sámi': 2131,
    'Valsamáta': 2131,
    'Vanáton': 2131,
    'Virós': 2131,
    'Zakynthos': 2131,
    'Agía Triáda': 2127,
    'Agnanteró': 2127,
    'Anávra': 2127,
    'Anthiró': 2127,
    'Artesianó': 2127,
    'Itéa': 2127,
    'Kallifóni': 2127,
    'Kallíthiro': 2127,
    'Kardítsa': 2127,
    'Karditsomagoúla': 2127,
    'Karpochóri': 2127,
    'Magoúla': 2127,
    'Makrychóri': 2127,
    'Mavrommáti': 2127,
    'Mitrópoli': 2127,
    'Morfovoúni': 2127,
    'Mouzáki': 2127,
    'Palamás': 2127,
    'Proástio': 2127,
    'Sofádes': 2127,
    'Agriá': 2104,
    'Álli Meriá': 2104,
    'Almyrós': 2104,
    'Anakasiá': 2104,
    'Áno Lekhónia': 2104,
    'Argalastí': 2104,
    'Evxinoúpolis': 2104,
    'Kanália': 2104,
    'Káto Lekhónia': 2104,
    'Néa Anchiálos': 2104,
    'Néa Ionía': 2104,
    'Patitírion': 2104,
    'Portariá': 2104,
    'Pteleós': 2104,
    'Rizómylos': 2104,
    'Skiáthos': 2104,
    'Skópelos': 2104,
    'Soúrpi': 2104,
    'Stefanovíkeio': 2104,
    'Tríkeri': 2104,
    'Velestíno': 2104,
    'Volos': 2104,
    'Zagorá': 2104,
    'Ágioi Theódoroi': 2119,
    'Ágios Andréas': 2119,
    'Arfará': 2119,
    'Árgos': 2119,
    'Aria': 2119,
    'Áris': 2119,
    'Arkhaía Kórinthos': 2119,
    'Asíni': 2119,
    'Asopós': 2119,
    'Ássos': 2119,
    'Ástros': 2119,
    'Athíkia': 2119,
    'Ayía Triás': 2119,
    'Áyios Adhrianós': 2119,
    'Áyios Vasílios': 2119,
    'Chóra': 2119,
    'Dídyma': 2119,
    'Dimitsána': 2119,
    'Drépanon': 2119,
    'Ermióni': 2119,
    'Examília': 2119,
    'Filiatrá': 2119,
    'Gargaliánoi': 2119,
    'Géfyra': 2119,
    'Geráki': 2119,
    'Gýtheio': 2119,
    'Isthmía': 2119,
    'Kalamata': 2119,
    'Kardamýli': 2119,
    'Karyés': 2119,
    'Káto Dhiminió': 2119,
    'Káto Glykóvrysi': 2119,
    'Khiliomódhi': 2119,
    'Kiáto': 2119,
    'Koilás': 2119,
    'Kokkónion': 2119,
    'Kopanáki': 2119,
    'Kórinthos': 2119,
    'Koróni': 2119,
    'Koutsopódi': 2119,
    'Kranídi': 2119,
    'Krokeés': 2119,
    'Kyparissía': 2119,
    'Kyrás Vrýsi': 2119,
    'Langádhia': 2119,
    'Lékhaio': 2119,
    'Leonídio': 2119,
    'Levídion': 2119,
    'Ligourión': 2119,
    'Loutrá Oraías Elénis': 2119,
    'Loutráki': 2119,
    'Magoúla': 2119,
    'Megalópoli': 2119,
    'Meligalás': 2119,
    'Messíni': 2119,
    'Methóni': 2119,
    'Moláoi': 2119,
    'Moúlki': 2119,
    'Náfplio': 2119,
    'Néa Kíos': 2119,
    'Néa Tírins': 2119,
    'Neméa': 2119,
    'Nomós Arkadías': 2119,
    'Palaiá Epídavros': 2119,
    'Paralía Vérgas': 2119,
    'Pásion': 2119,
    'Perachóra': 2119,
    'Periyiáli': 2119,
    'Pórto Chéli': 2119,
    'Pýlos': 2119,
    'Sikyón': 2119,
    'Skála': 2119,
    'Sofikón': 2119,
    'Spárti': 2119,
    'Sperchógeia': 2119,
    'Sykiá': 2119,
    'Thouría': 2119,
    'Tolón': 2119,
    'Traganón': 2119,
    'Trípoli': 2119,
    'Vélo': 2119,
    'Vlachiótis': 2119,
    'Vlachópoulo': 2119,
    'Vokhaïkó': 2119,
    'Vrakháti': 2119,
    'Xylókastro': 2119,
    'Zevgolateió': 2119,
    'Adámas': 2118,
    'Afántou': 2118,
    'Agía Marína': 2118,
    'Amorgós': 2118,
    'Anáfi': 2118,
    'Andros': 2118,
    'Áno Merá': 2118,
    'Áno Sýros': 2118,
    'Antimácheia': 2118,
    'Antíparos': 2118,
    'Archángelos': 2118,
    'Astypálaia': 2118,
    'Chálki': 2118,
    'Émponas': 2118,
    'Emporeío': 2118,
    'Ermoúpolis': 2118,
    'Faliraki': 2118,
    'Filótion': 2118,
    'Firá': 2118,
    'Folégandros': 2118,
    'Fry': 2118,
    'Ialysós': 2118,
    'Íos': 2118,
    'Kálymnos': 2118,
    'Kardámaina': 2118,
    'Karpathos': 2118,
    'Kéfalos': 2118,
    'Kímolos': 2118,
    'Kos': 2118,
    'Kremastí': 2118,
    'Kýthnos': 2118,
    'Lakkí': 2118,
    'Lárdos': 2118,
    'Mandráki': 2118,
    'Megálo Chorió': 2118,
    'Megísti': 2118,
    'Mesariá': 2118,
    'Mílos': 2118,
    'Mykonos': 2118,
    'Náousa': 2118,
    'Náxos': 2118,
    'Nomós Kykládon': 2118,
    'Oía': 2118,
    'Ornós': 2118,
    'Pánormos': 2118,
    'Páros': 2118,
    'Pátmos': 2118,
    'Pylí': 2118,
    'Ródos': 2118,
    'Sérifos': 2118,
    'Skála': 2118,
    'Sými': 2118,
    'Tínos': 2118,
    'Vári': 2118,
    'Zipári': 2118,
    'Agrínio': 2096,
    'Aígio': 2096,
    'Aitolikó': 2096,
    'Aíyira': 2096,
    'Akráta': 2096,
    'Aktaío': 2096,
    'Alfeioúsa': 2096,
    'Amaliáda': 2096,
    'Amfilochía': 2096,
    'Andravída': 2096,
    'Angelókastro': 2096,
    'Áno Kastrítsi': 2096,
    'Antirrio': 2096,
    'Archaía Olympía': 2096,
    'Archontochóri': 2096,
    'Arkoúdi': 2096,
    'Astakós': 2096,
    'Áyios Konstandínos': 2096,
    'Chalandrítsa': 2096,
    'Chávari': 2096,
    'Dhokímion': 2096,
    'Epitálio': 2096,
    'Fyteíes': 2096,
    'Galatás': 2096,
    'Gastoúni': 2096,
    'Gavaloú': 2096,
    'Goúmero': 2096,
    'Kainoúryion': 2096,
    'Kalávryta': 2096,
    'Kamárai': 2096,
    'Kandíla': 2096,
    'Karátoula': 2096,
    'Kardamás': 2096,
    'Káto Achaḯa': 2096,
    'Káto Mazaráki': 2096,
    'Katochí': 2096,
    'Katoúna': 2096,
    'Kavásila': 2096,
    'Kréstena': 2096,
    'Kríkellos': 2096,
    'Kyllíni': 2096,
    'Lálas': 2096,
    'Lápas': 2096,
    'Lechainá': 2096,
    'Lepenoú': 2096,
    'Limnokhórion': 2096,
    'Makrísia': 2096,
    'Manoláda': 2096,
    'Mataránga': 2096,
    'Megáli Khóra': 2096,
    'Menídi': 2096,
    'Mesolóngi': 2096,
    'Mindilóglion': 2096,
    'Monastiráki': 2096,
    'Myrsíni': 2096,
    'Náfpaktos': 2096,
    'Néa Manoláda': 2096,
    'Neápolis': 2096,
    'Neochóri': 2096,
    'Neochórion': 2096,
    'Nomós Achaḯas': 2096,
    'Nomós Aitolías kai Akarnanías': 2096,
    'Nomós Ileías': 2096,
    'Ovriá': 2096,
    'Pálairos': 2096,
    'Panaitólion': 2096,
    'Pappadhátai': 2096,
    'Paralía': 2096,
    'Paravóla': 2096,
    'Pátra': 2096,
    'Pelópi': 2096,
    'Plátanos': 2096,
    'Pýrgos': 2096,
    'Río': 2096,
    'Rododáfni': 2096,
    'Royítika': 2096,
    'Sardínia': 2096,
    'Savália': 2096,
    'Stános': 2096,
    'Sylivainiótika': 2096,
    'Synoikismós Chavaríou': 2096,
    'Témeni': 2096,
    'Thérmo': 2096,
    'Triandaíika': 2096,
    'Várda': 2096,
    'Vartholomió': 2096,
    'Varvásaina': 2096,
    'Vónitsa': 2096,
    'Vrachnaíika': 2096,
    'Zacháro': 2096,
    'Aianí': 2108,
    'Akriní': 2108,
    'Ammochóri': 2108,
    'Amýntaio': 2108,
    'Anaráchi': 2108,
    'Áno Kómi': 2108,
    'Argos Orestiko': 2108,
    'Chlói': 2108,
    'Deskáti': 2108,
    'Empório': 2108,
    'Erátyra': 2108,
    'Filótas': 2108,
    'Flórina': 2108,
    'Galatiní': 2108,
    'Grevená': 2108,
    'Kastoria': 2108,
    'Kleítos': 2108,
    'Koíla': 2108,
    'Komniná': 2108,
    'Kozáni': 2108,
    'Krókos': 2108,
    'Laimós': 2108,
    'Léchovo': 2108,
    'Livaderó': 2108,
    'Maniákoi': 2108,
    'Mavrochóri': 2108,
    'Melíti': 2108,
    'Mesopotamía': 2108,
    'Nea Lava': 2108,
    'Nestório': 2108,
    'Nomós Kozánis': 2108,
    'Platanórevma': 2108,
    'Ptolemaḯda': 2108,
    'Sérvia': 2108,
    'Siátista': 2108,
    'Tsotíli': 2108,
    'Velventós': 2108,
    'Xinó Neró': 2108,
    'Hillsborough': 3867,
    'Grenville': 3865,
    'Saint David’s': 3869,
    'Saint George  s': 3864,
    'Gouyave': 3868,
    'Victoria': 3866,
    'Sauteurs': 3863,
    'Cahabón': 3671,
    'Chahal Guatemala': 3671,
    'Chisec': 3671,
    'Cobán': 3671,
    'La Tinta': 3671,
    'Lanquín': 3671,
    'Panzós': 3671,
    'San Cristóbal Verapaz': 3671,
    'San Juan Chamelco': 3671,
    'San Pedro Carchá': 3671,
    'Santa Cruz Verapaz': 3671,
    'Senahú': 3671,
    'Tactic': 3671,
    'Tamahú': 3671,
    'Tucurú': 3671,
    'Cubulco': 3674,
    'El Chol': 3674,
    'Granados': 3674,
    'Purulhá': 3674,
    'Rabinal': 3674,
    'Salamá': 3674,
    'San Jerónimo': 3674,
    'San Miguel Chicaj': 3674,
    'Acatenango': 3675,
    'Chimaltenango': 3675,
    'Comalapa': 3675,
    'El Tejar': 3675,
    'Parramos': 3675,
    'Patzicía': 3675,
    'Patzún': 3675,
    'Pochuta': 3675,
    'San Andrés Itzapa': 3675,
    'San José Poaquil': 3675,
    'San Martín Jilotepeque': 3675,
    'Santa Apolonia': 3675,
    'Santa Cruz Balanyá': 3675,
    'Tecpán Guatemala': 3675,
    'Yepocapa': 3675,
    'Zaragoza': 3675,
    'Camotán': 3666,
    'Chiquimula': 3666,
    'Concepción Las Minas': 3666,
    'Esquipulas': 3666,
    'Ipala': 3666,
    'Jocotán': 3666,
    'Olopa': 3666,
    'Quezaltepeque': 3666,
    'San Jacinto': 3666,
    'San José La Arada': 3666,
    'San Juan Ermita': 3666,
    'El Jícaro': 3662,
    'Guastatoya': 3662,
    'Morazán': 3662,
    'San Agustín Acasaguastlán': 3662,
    'San Antonio La Paz': 3662,
    'San Cristóbal Acasaguastlán': 3662,
    'Sanarate': 3662,
    'Sansare': 3662,
    'Escuintla': 3677,
    'Guanagazapa': 3677,
    'Iztapa': 3677,
    'La Democracia': 3677,
    'La Gomera': 3677,
    'Masagua': 3677,
    'Nueva Concepción': 3677,
    'Palín': 3677,
    'Puerto San José': 3677,
    'San Vicente Pacaya': 3677,
    'Santa Lucía Cotzumalguapa': 3677,
    'Siquinalá': 3677,
    'Tiquisate': 3677,
    'Amatitlán': 3672,
    'Chinautla': 3672,
    'Chuarrancho': 3672,
    'Fraijanes': 3672,
    'Guatemala City': 3672,
    'Mixco': 3672,
    'Palencia': 3672,
    'Petapa': 3672,
    'San José del Golfo': 3672,
    'San José Pinula': 3672,
    'San Juan Sacatepéquez': 3672,
    'San Pedro Ayampuc': 3672,
    'San Pedro Sacatepéquez': 3672,
    'San Raimundo': 3672,
    'Santa Catarina Pinula': 3672,
    'Villa Canales': 3672,
    'Villa Nueva': 3672,
    'Aguacatán': 3670,
    'Barillas': 3670,
    'Chiantla': 3670,
    'Colotenango': 3670,
    'Concepción Huista': 3670,
    'Cuilco': 3670,
    'Huehuetenango': 3670,
    'Ixtahuacán': 3670,
    'Jacaltenango': 3670,
    'La Libertad': 3670,
    'Malacatancito': 3670,
    'Nentón': 3670,
    'San Antonio Huista': 3670,
    'San Gaspar Ixchil': 3670,
    'San Juan Atitán': 3670,
    'San Juan Ixcoy': 3670,
    'San Mateo Ixtatán': 3670,
    'San Miguel Acatán': 3670,
    'San Pedro Necta': 3670,
    'San Rafael La Independencia': 3670,
    'San Rafael Petzal': 3670,
    'San Sebastián Coatán': 3670,
    'San Sebastián Huehuetenango': 3670,
    'Santa Ana Huista': 3670,
    'Santa Bárbara': 3670,
    'Santa Eulalia': 3670,
    'Santiago Chimaltenango': 3670,
    'Soloma': 3670,
    'Tectitán': 3670,
    'Todos Santos Cuchumatán': 3670,
    'El Estor': 3659,
    'Lívingston': 3659,
    'Los Amates': 3659,
    'Morales': 3659,
    'Municipio de Morales': 3659,
    'Municipio de Puerto Barrios': 3659,
    'Puerto Barrios': 3659,
    'Jalapa': 3658,
    'Mataquescuintla': 3658,
    'Monjas': 3658,
    'Municipio de Jalapa': 3658,
    'Municipio de Mataquescuintla': 3658,
    'San Luis Jilotepeque': 3658,
    'San Manuel Chaparrón': 3658,
    'San Pedro Pinula': 3658,
    'Agua Blanca': 3673,
    'Asunción Mita': 3673,
    'Atescatempa': 3673,
    'Comapa': 3673,
    'Conguaco': 3673,
    'El Adelanto': 3673,
    'El Progreso': 3673,
    'Jalpatagua': 3673,
    'Jerez': 3673,
    'Jutiapa': 3673,
    'Moyuta': 3673,
    'Municipio de Asunción Mita': 3673,
    'Pasaco': 3673,
    'Quesada': 3673,
    'San José Acatempa': 3673,
    'Santa Catarina Mita': 3673,
    'Yupiltepeque': 3673,
    'Zapotitlán': 3673,
    'Dolores': 3669,
    'Flores': 3669,
    'La Libertad': 3669,
    'Melchor de Mencos': 3669,
    'Municipio de Flores': 3669,
    'Municipio de Poptún': 3669,
    'Municipio de San Andrés': 3669,
    'Municipio de San Benito': 3669,
    'Municipio de San Francisco': 3669,
    'Municipio de Santa Ana': 3669,
    'Municipio de Sayaxché': 3669,
    'Poptún': 3669,
    'San Andrés': 3669,
    'San Benito': 3669,
    'San Francisco': 3669,
    'San José': 3669,
    'San Luis': 3669,
    'Santa Ana': 3669,
    'Sayaxché': 3669,
    'Almolonga': 3668,
    'Cabricán': 3668,
    'Cajolá': 3668,
    'Cantel': 3668,
    'Coatepeque': 3668,
    'Colomba': 3668,
    'Concepción Chiquirichapa': 3668,
    'El Palmar': 3668,
    'Flores Costa Cuca': 3668,
    'Génova': 3668,
    'Huitán': 3668,
    'La Esperanza': 3668,
    'Municipio de Almolonga': 3668,
    'Municipio de Cabricán': 3668,
    'Municipio de Cantel': 3668,
    'Municipio de Coatepeque': 3668,
    'Municipio de Colomba': 3668,
    'Municipio de Concepción Chiquirichapa': 3668,
    'Municipio de Flores Costa Cuca': 3668,
    'Municipio de San Juan Ostuncalco': 3668,
    'Olintepeque': 3668,
    'Ostuncalco': 3668,
    'Palestina de los Altos': 3668,
    'Quetzaltenango': 3668,
    'Salcajá': 3668,
    'Samayac': 3668,
    'San Carlos Sija': 3668,
    'San Francisco la Unión': 3668,
    'San Martín Sacatepéquez': 3668,
    'San Mateo': 3668,
    'San Miguel Sigüilá': 3668,
    'Sibilia': 3668,
    'Zunil': 3668,
    'Canillá': 3657,
    'Chajul': 3657,
    'Chicamán': 3657,
    'Chiché': 3657,
    'Chichicastenango': 3657,
    'Chinique': 3657,
    'Cunén': 3657,
    'Joyabaj': 3657,
    'Municipio de Canillá': 3657,
    'Municipio de Chajul': 3657,
    'Municipio de Chicaman': 3657,
    'Municipio de Chiché': 3657,
    'Municipio de Chichicastenango': 3657,
    'Municipio de Chinique': 3657,
    'Municipio de Cunén': 3657,
    'Municipio de Ixcan': 3657,
    'Municipio de Joyabaj': 3657,
    'Municipio de Pachalum': 3657,
    'Municipio de Patzité': 3657,
    'Municipio de San Andrés Sajcabajá': 3657,
    'Municipio de San Antonio Ilotenango': 3657,
    'Municipio de San Juan Cotzal': 3657,
    'Municipio de San Pedro Jocopilas': 3657,
    'Municipio de Uspantán': 3657,
    'Municipio de Zacualpa': 3657,
    'Nebaj': 3657,
    'Pachalum': 3657,
    'Patzité': 3657,
    'Playa Grande': 3657,
    'Sacapulas': 3657,
    'San Andrés Sajcabajá': 3657,
    'San Antonio Ilotenango': 3657,
    'San Bartolomé Jocotenango': 3657,
    'San Juan Cotzal': 3657,
    'San Luis Ixcán': 3657,
    'San Pédro Jocopilas': 3657,
    'Santa Cruz del Quiché': 3657,
    'Uspantán': 3657,
    'Zacualpa': 3657,
    'Champerico': 3664,
    'El Asintal': 3664,
    'Municipio de San Felipe': 3664,
    'Nuevo San Carlos': 3664,
    'Retalhuleu': 3664,
    'San Andrés Villa Seca': 3664,
    'San Felipe': 3664,
    'San Martín Zapotitlán': 3664,
    'San Sebastián': 3664,
    'Santa Cruz Muluá': 3664,
    'Alotenango': 3676,
    'Antigua Guatemala': 3676,
    'Ciudad Vieja': 3676,
    'Jocotenango': 3676,
    'Magdalena Milpas Altas': 3676,
    'Municipio de Alotenango': 3676,
    'Municipio de Antigua Guatemala': 3676,
    'Municipio de Ciudad Vieja': 3676,
    'Municipio de Jocotenango': 3676,
    'Municipio de Magdalena Milpas Altas': 3676,
    'Municipio de Santa Lucía Milpas Altas': 3676,
    'Municipio de Santa María de Jesús': 3676,
    'Pastores': 3676,
    'San Antonio Aguas Calientes': 3676,
    'San Bartolomé Milpas Altas': 3676,
    'San Lucas Sacatepéquez': 3676,
    'San Miguel Dueñas': 3676,
    'Santa Catarina Barahona': 3676,
    'Santa Lucía Milpas Altas': 3676,
    'Santa María de Jesús': 3676,
    'Santiago Sacatepéquez': 3676,
    'Santo Domingo Xenacoj': 3676,
    'Sumpango': 3676,
    'Catarina': 3667,
    'Ciudad Tecún Umán': 3667,
    'Comitancillo': 3667,
    'Concepción Tutuapa': 3667,
    'El Quetzal': 3667,
    'El Rodeo': 3667,
    'El Tumbador': 3667,
    'Esquipulas Palo Gordo': 3667,
    'Ixchiguán': 3667,
    'La Reforma': 3667,
    'Malacatán': 3667,
    'Municipio de Concepción Tutuapa': 3667,
    'Municipio de Malacatán': 3667,
    'Municipio de Sipacapa': 3667,
    'Municipio de Tejutla': 3667,
    'Nuevo Progreso': 3667,
    'Ocós': 3667,
    'Pajapita': 3667,
    'Río Blanco': 3667,
    'San Antonio Sacatepéquez': 3667,
    'San Cristóbal Cucho': 3667,
    'San José Ojetenam': 3667,
    'San José Ojetenán': 3667,
    'San Lorenzo': 3667,
    'San Marcos': 3667,
    'San Miguel Ixtahuacán': 3667,
    'San Pablo': 3667,
    'San Pedro Sacatepéquez': 3667,
    'San Rafael Pie de la Cuesta': 3667,
    'Sibinal': 3667,
    'Sipacapa': 3667,
    'Tacaná': 3667,
    'Tajumulco': 3667,
    'Tejutla': 3667,
    'Barberena': 3665,
    'Casillas': 3665,
    'Chiquimulilla': 3665,
    'Cuilapa': 3665,
    'Guazacapán': 3665,
    'Municipio de Casillas': 3665,
    'Municipio de Chiquimulilla': 3665,
    'Municipio de Guazacapán': 3665,
    'Nueva Santa Rosa': 3665,
    'Oratorio': 3665,
    'Pueblo Nuevo Viñas': 3665,
    'San Juan Tecuaco': 3665,
    'San Rafael Las Flores': 3665,
    'Santa Cruz Naranjo': 3665,
    'Santa María Ixhuatán': 3665,
    'Santa Rosa de Lima': 3665,
    'Taxisco': 3665,
    'Concepción': 3661,
    'Municipio de Nahualá': 3661,
    'Municipio de Panajachel': 3661,
    'Municipio de Santa Catarina Palopó': 3661,
    'Municipio de Santa Cruz La Laguna': 3661,
    'Nahualá': 3661,
    'Panajachel': 3661,
    'San Andrés Semetabaj': 3661,
    'San Antonio Palopó': 3661,
    'San José Chacayá': 3661,
    'San Juan La Laguna': 3661,
    'San Lucas Tolimán': 3661,
    'San Marcos La Laguna': 3661,
    'San Pablo La Laguna': 3661,
    'San Pedro La Laguna': 3661,
    'Santa Catarina Ixtahuacán': 3661,
    'Santa Catarina Palopó': 3661,
    'Santa Clara La Laguna': 3661,
    'Santa Cruz La Laguna': 3661,
    'Santa Lucía Utatlán': 3661,
    'Santa María Visitación': 3661,
    'Santiago Atitlán': 3661,
    'Sololá': 3661,
    'Chicacao': 3660,
    'Cuyotenango': 3660,
    'Mazatenango': 3660,
    'Municipio de San Antonio Suchitepéquez': 3660,
    'Municipio de San Miguel Panán': 3660,
    'Municipio de Santa Bárbara': 3660,
    'Patulul': 3660,
    'Pueblo Nuevo': 3660,
    'Río Bravo': 3660,
    'San Antonio Suchitepéquez': 3660,
    'San Bernardino': 3660,
    'San Francisco Zapotitlán': 3660,
    'San Gabriel': 3660,
    'San José El Ídolo': 3660,
    'San Juan Bautista': 3660,
    'San Lorenzo': 3660,
    'San Miguel Panán': 3660,
    'San Pablo Jocopilas': 3660,
    'Santa Bárbara': 3660,
    'Santo Domingo Suchitepéquez': 3660,
    'Santo Tomás La Unión': 3660,
    'Zunilito': 3660,
    'Momostenango': 3663,
    'Municipio de Momostenango': 3663,
    'Municipio de Santa María Chiquimula': 3663,
    'Municipio de Totonicapán': 3663,
    'San Andrés Xecul': 3663,
    'San Bartolo': 3663,
    'San Cristóbal Totonicapán': 3663,
    'San Francisco El Alto': 3663,
    'Santa Lucia La Reforma': 3663,
    'Santa María Chiquimula': 3663,
    'Totonicapán': 3663,
    'Boffa': 2676,
    'Boké': 2676,
    'Boke Prefecture': 2676,
    'Fria': 2676,
    'Gaoual': 2676,
    'Gaoual Prefecture': 2676,
    'Kimbo': 2676,
    'Koundara': 2676,
    'Koundara Prefecture': 2676,
    'Sanguéya': 2676,
    'Youkounkoun': 2676,
    'Camayenne': 2686,
    'Conakry': 2686,
    'Kankan': 2697,
    'Kankan Prefecture': 2697,
    'Kérouané': 2697,
    'Kerouane Prefecture': 2697,
    'Kouroussa': 2697,
    'Mandiana': 2697,
    'Mandiana Prefecture': 2697,
    'Siguiri': 2697,
    'Siguiri Prefecture': 2697,
    'Tokonou': 2697,
    'Coyah': 2701,
    'Dubréka': 2701,
    'Forécariah': 2701,
    'Kindia': 2701,
    'Préfecture de Dubréka': 2701,
    'Préfecture de Forécariah': 2701,
    'Télimélé': 2701,
    'Telimele Prefecture': 2701,
    'Tondon': 2701,
    'Koubia': 2677,
    'Labé': 2677,
    'Labe Prefecture': 2677,
    'Lélouma': 2677,
    'Lelouma Prefecture': 2677,
    'Mali': 2677,
    'Mali Prefecture': 2677,
    'Tougué': 2677,
    'Tougue Prefecture': 2677,
    'Dalaba': 2698,
    'Mamou': 2698,
    'Mamou Prefecture': 2698,
    'Pita': 2698,
    'Beyla': 2684,
    'Beyla Prefecture': 2684,
    'Gueckedou': 2684,
    'Lola': 2684,
    'Macenta': 2684,
    'Nzérékoré': 2684,
    'Nzerekore Prefecture': 2684,
    'Préfecture de Guékédou': 2684,
    'Yomou': 2684,
    'Bafatá': 2720,
    'Contuboel Sector': 2720,
    'Quinhámel': 2714,
    'Bolama': 2722,
    'Bubaque': 2722,
    'Cacheu': 2713,
    'Canchungo': 2713,
    'Gabú': 2719,
    'Bissorã': 2718,
    'Farim': 2718,
    'Mansôa': 2718,
    'Buba': 2715,
    'Catió': 2712,
    'Quebo': 2712,
    'Mabaruma': 2764,
    'Bartica': 2760,
    'Georgetown': 2767,
    'Mahaica Village': 2767,
    'New Amsterdam': 2766,
    'Skeldon': 2766,
    'Parika': 2768,
    'Vreed-en-Hoop': 2768,
    'Mahaicony Village': 2762,
    'Rosignol': 2762,
    'Anna Regina': 2765,
    'Mahdia': 2761,
    'Linden': 2763,
    'Lethem': 2769,
    'Anse Rouge': 4123,
    'Arrondissement de Saint-Marc': 4123,
    'Désarmes': 4123,
    'Dessalines': 4123,
    'Ennery': 4123,
    'Gonaïves': 4123,
    'Grande Saline': 4123,
    'Gros Morne': 4123,
    'Marmelade': 4123,
    'Saint-Marc': 4123,
    'Verrettes': 4123,
    'Arrondissement de Cerca La Source': 4125,
    'Cerca la Source': 4125,
    'Hinche': 4125,
    'Lascahobas': 4125,
    'Mayisad': 4125,
    'Mirebalais': 4125,
    'Thomassique': 4125,
    'Thomonde': 4125,
    'Anse-à-Veau': 4119,
    'Chambellan': 4119,
    'Corail': 4119,
    'Dame-Marie': 4119,
    'Jeremi': 4119,
    'Jérémie': 4119,
    'Les Abricots': 4119,
    'Les Irois': 4119,
    'Moron': 4119,
    'Petite Rivière de Nippes': 4119,
    'Ansavo': 4118,
    'Baradères': 4118,
    'Miragoâne': 4118,
    'Petit Trou de Nippes': 4118,
    'Acul du Nord': 4117,
    'Arrondissement de la Grande Rivière du Nord': 4117,
    'Arrondissement de Plaisance': 4117,
    'Arrondissement du Borgne': 4117,
    'Bahon': 4117,
    'Borgne': 4117,
    'Dondon': 4117,
    'Grande Rivière du Nord': 4117,
    'Lenbe': 4117,
    'Limonade': 4117,
    'Milot': 4117,
    'Okap': 4117,
    'Pignon': 4117,
    'Pilate': 4117,
    'Plaine du Nord': 4117,
    'Plaisance': 4117,
    'Port-Margot': 4117,
    'Quartier Morin': 4117,
    'Ranquitte': 4117,
    'Saint-Raphaël': 4117,
    'Arrondissement de Fort Liberté': 4121,
    'Arrondissement du Trou du Nord': 4121,
    'Caracol': 4121,
    'Carice': 4121,
    'Dérac': 4121,
    'Ferrier': 4121,
    'Fort Liberté': 4121,
    'Montòrganize': 4121,
    'Ouanaminthe': 4121,
    'Perches': 4121,
    'Phaëton': 4121,
    'Trou du Nord': 4121,
    'Wanament': 4121,
    'Arcahaie': 4126,
    'Arrondissement de Port-de-Paix': 4126,
    'Arrondissement de Saint-Louis du Nord': 4126,
    'Arrondissement du Môle Saint-Nicolas': 4126,
    'Baie de Henne': 4126,
    'Bombardopolis': 4126,
    'Fond Bassin Bleu': 4126,
    'Jean-Rabel': 4126,
    'Môle Saint-Nicolas': 4126,
    'Petite Anse': 4126,
    'Port-de-Paix': 4126,
    'Saint-Louis du Nord': 4126,
    'Ti Port-de-Paix': 4126,
    'Anse à Galets': 4120,
    'Arcahaie': 4120,
    'Arrondissement de Croix des Bouquets': 4120,
    'Arrondissement de Léogâne': 4120,
    'Arrondissement de Port-au-Prince': 4120,
    'Cabaret': 4120,
    'Carrefour': 4120,
    'Cornillon': 4120,
    'Croix-des-Bouquets': 4120,
    'Delmas 73': 4120,
    'Fond Parisien': 4120,
    'Fonds Verrettes': 4120,
    'Grangwav': 4120,
    'Gressier': 4120,
    'Kenscoff': 4120,
    'Lagonav': 4120,
    'Léogâne': 4120,
    'Pétionville': 4120,
    'Port-au-Prince': 4120,
    'Thomazeau': 4120,
    'Tigwav': 4120,
    'Aquin': 4122,
    'Arrondissement de Port-Salut': 4122,
    'Arrondissement des Cayes': 4122,
    'Cavaillon': 4122,
    'Chantal': 4122,
    'Chardonnière': 4122,
    'Fond des Blancs': 4122,
    'Koto': 4122,
    'Les Anglais': 4122,
    'Les Cayes': 4122,
    'Port-à-Piment': 4122,
    'Roche-à-Bateau': 4122,
    'Saint-Louis du Sud': 4122,
    'Tiburon': 4122,
    'Torbeck': 4122,
    'Anse-à-Pitre': 4124,
    'Arrondissement de Bainet': 4124,
    'Arrondissement de Jacmel': 4124,
    'Belle-Anse': 4124,
    'Cayes-Jacmel': 4124,
    'Jacmel': 4124,
    'Kotdefè': 4124,
    'Marigot': 4124,
    'Thiotte': 4124,
    'Arizona': 4047,
    'Atenas de San Cristóbal': 4047,
    'Corozal': 4047,
    'El Pino': 4047,
    'El Porvenir': 4047,
    'El Triunfo de la Cruz': 4047,
    'Esparta': 4047,
    'Jutiapa': 4047,
    'La Ceiba': 4047,
    'La Masica': 4047,
    'La Unión': 4047,
    'Mezapa': 4047,
    'Nueva Armenia': 4047,
    'Sambo Creek': 4047,
    'San Antonio': 4047,
    'San Francisco': 4047,
    'San Juan Pueblo': 4047,
    'Santa Ana': 4047,
    'Tela': 4047,
    'Tornabé': 4047,
    'Coxen Hole': 4045,
    'French Harbor': 4045,
    'Guanaja': 4045,
    'José Santos Guardiola': 4045,
    'Roatán': 4045,
    'Sandy Bay': 4045,
    'Savannah Bight': 4045,
    'Utila': 4045,
    'Apacilagua': 4041,
    'Choluteca': 4041,
    'Ciudad Choluteca': 4041,
    'Concepción de María': 4041,
    'Corpus': 4041,
    'Duyure': 4041,
    'El Corpus': 4041,
    'El Obraje': 4041,
    'El Puente': 4041,
    'El Triunfo': 4041,
    'Los Llanitos': 4041,
    'Marcovia': 4041,
    'Monjarás': 4041,
    'Morolica': 4041,
    'Namasigüe': 4041,
    'Orocuina': 4041,
    'Pespire': 4041,
    'San Antonio de Flores': 4041,
    'San Isidro': 4041,
    'San Jerónimo': 4041,
    'San José': 4041,
    'San José de Las Conchas': 4041,
    'San Marcos de Colón': 4041,
    'Santa Ana de Yusguare': 4041,
    'Santa Cruz': 4041,
    'Balfate': 4051,
    'Bonito Oriental': 4051,
    'Corocito': 4051,
    'Cusuna': 4051,
    'Elíxir': 4051,
    'Francia': 4051,
    'Iriona': 4051,
    'Jericó': 4051,
    'La Brea': 4051,
    'La Esperanza': 4051,
    'Limón': 4051,
    'Municipio de Sabá': 4051,
    'Prieta': 4051,
    'Puerto Castilla': 4051,
    'Punta Piedra': 4051,
    'Quebrada de Arena': 4051,
    'Río Esteban': 4051,
    'Sabá': 4051,
    'Salamá': 4051,
    'Santa Fe': 4051,
    'Santa Rosa de Aguán': 4051,
    'Sonaguera': 4051,
    'Taujica': 4051,
    'Tocoa': 4051,
    'Trujillo': 4051,
    'Zamora': 4051,
    'Aguas del Padre': 4042,
    'Ajuterique': 4042,
    'Cerro Blanco': 4042,
    'Comayagua': 4042,
    'Concepción de Guasistagua': 4042,
    'El Agua Dulcita': 4042,
    'El Porvenir': 4042,
    'El Rancho': 4042,
    'El Rincón': 4042,
    'El Rosario': 4042,
    'El Sauce': 4042,
    'El Socorro': 4042,
    'Esquías': 4042,
    'Flores': 4042,
    'Humuya': 4042,
    'Jamalteca': 4042,
    'La Libertad': 4042,
    'La Trinidad': 4042,
    'Lamaní': 4042,
    'Las Lajas': 4042,
    'Lejamaní': 4042,
    'Meámbar': 4042,
    'Minas de Oro': 4042,
    'Ojos de Agua': 4042,
    'Potrerillos': 4042,
    'Río Bonito': 4042,
    'San Antonio de la Cuesta': 4042,
    'San Jerónimo': 4042,
    'San José de Comayagua': 4042,
    'San José del Potrero': 4042,
    'San Luis': 4042,
    'San Sebastián': 4042,
    'Siguatepeque': 4042,
    'Taulabé': 4042,
    'Valle de Ángeles': 4042,
    'Villa de San Antonio': 4042,
    'Agua Caliente': 4049,
    'Buenos Aires': 4049,
    'Cabañas': 4049,
    'Chalmeca': 4049,
    'Concepción': 4049,
    'Concepción de la Barranca': 4049,
    'Copán': 4049,
    'Copán Ruinas': 4049,
    'Corquín': 4049,
    'Cucuyagua': 4049,
    'Dolores': 4049,
    'Dulce Nombre': 4049,
    'El Corpus': 4049,
    'El Ocotón': 4049,
    'El Paraíso': 4049,
    'Florida': 4049,
    'La Entrada': 4049,
    'La Jigua': 4049,
    'La Playona': 4049,
    'La Unión': 4049,
    'La Zumbadora': 4049,
    'Los Tangos': 4049,
    'Nueva Arcadia': 4049,
    'Ojos de Agua': 4049,
    'Pueblo Nuevo': 4049,
    'Quezailica': 4049,
    'San Agustín': 4049,
    'San Antonio': 4049,
    'San Jerónimo': 4049,
    'San Joaquín': 4049,
    'San José': 4049,
    'San José de Copán': 4049,
    'San Juan de Opoa': 4049,
    'San Juan de Planes': 4049,
    'San Nicolás': 4049,
    'San Pedro de Copán': 4049,
    'Santa Rita': 4049,
    'Santa Rita, Copan': 4049,
    'Santa Rosa de Copán': 4049,
    'Trinidad de Copán': 4049,
    'Veracruz': 4049,
    'Agua Azul': 4046,
    'Agua Azul Rancho': 4046,
    'Armenta': 4046,
    'Baja Mar': 4046,
    'Baracoa': 4046,
    'Bejuco': 4046,
    'Cañaveral': 4046,
    'Casa Quemada': 4046,
    'Chivana': 4046,
    'Choloma': 4046,
    'Chotepe': 4046,
    'Cofradía': 4046,
    'Cuyamel': 4046,
    'El Llano': 4046,
    'El Marañón': 4046,
    'El Milagro': 4046,
    'El Olivar': 4046,
    'El Perico': 4046,
    'El Plan': 4046,
    'El Porvenir': 4046,
    'El Rancho': 4046,
    'El Tigre': 4046,
    'El Zapotal del Norte': 4046,
    'La Guama': 4046,
    'La Huesa': 4046,
    'La Jutosa': 4046,
    'La Lima': 4046,
    'La Sabana': 4046,
    'Los Caminos': 4046,
    'Los Naranjos': 4046,
    'Monterrey': 4046,
    'Nuevo Chamelecón': 4046,
    'Omoa': 4046,
    'Oropéndolas': 4046,
    'Peña Blanca': 4046,
    'Pimienta': 4046,
    'Pimienta Vieja': 4046,
    'Potrerillos': 4046,
    'Pueblo Nuevo': 4046,
    'Puerto Alto': 4046,
    'Puerto Cortés': 4046,
    'Puerto Cortez': 4046,
    'Quebrada Seca': 4046,
    'Río Blanquito': 4046,
    'Río Chiquito': 4046,
    'Río Lindo': 4046,
    'San Antonio de Cortés': 4046,
    'San Buenaventura': 4046,
    'San Francisco de Yojoa': 4046,
    'San José del Boquerón': 4046,
    'San Manuel': 4046,
    'San Pedro Sula': 4046,
    'Santa Cruz de Yojoa': 4046,
    'Santa Elena': 4046,
    'Travesía': 4046,
    'Villanueva': 4046,
    'Alauca': 4043,
    'Araulí': 4043,
    'Cuyalí': 4043,
    'Danlí': 4043,
    'El Benque': 4043,
    'El Chichicaste': 4043,
    'El Obraje': 4043,
    'El Paraíso': 4043,
    'Güinope': 4043,
    'Jacaleapa': 4043,
    'Jutiapa': 4043,
    'Las Ánimas': 4043,
    'Las Trojes': 4043,
    'Liure': 4043,
    'Morocelí': 4043,
    'Municipio de Texiguat': 4043,
    'Ojo de Agua': 4043,
    'Oropolí': 4043,
    'Potrerillos': 4043,
    'Quebrada Larga': 4043,
    'San Antonio de Flores': 4043,
    'San Diego': 4043,
    'San Lucas': 4043,
    'San Matías': 4043,
    'Santa Cruz': 4043,
    'Soledad': 4043,
    'Teupasenti': 4043,
    'Texíguat': 4043,
    'Trojes': 4043,
    'Vado Ancho': 4043,
    'Yauyupe': 4043,
    'Yuscarán': 4043,
    'Agalteca': 4052,
    'Alubarén': 4052,
    'Cedros': 4052,
    'Cerro Grande': 4052,
    'Cofradía': 4052,
    'Curarén': 4052,
    'Distrito Central': 4052,
    'El Chimbo': 4052,
    'El Escanito': 4052,
    'El Escaño de Tepale': 4052,
    'El Guante': 4052,
    'El Guantillo': 4052,
    'El Guapinol': 4052,
    'El Lolo': 4052,
    'El Pedernal': 4052,
    'El Porvenir': 4052,
    'El Suyatal': 4052,
    'El Tablón': 4052,
    'El Terrero': 4052,
    'Guaimaca': 4052,
    'La Ermita': 4052,
    'La Libertad': 4052,
    'La Venta': 4052,
    'Lepaterique': 4052,
    'Maraita': 4052,
    'Marale': 4052,
    'Mata de Plátano': 4052,
    'Mateo': 4052,
    'Nueva Armenia': 4052,
    'Ojojona': 4052,
    'Orica': 4052,
    'Quebradas': 4052,
    'Reitoca': 4052,
    'Río Abajo': 4052,
    'Sabanagrande': 4052,
    'San Antonio de Oriente': 4052,
    'San Buenaventura': 4052,
    'San Ignacio': 4052,
    'San Juan de Flores': 4052,
    'San Miguelito': 4052,
    'Santa Ana': 4052,
    'Santa Lucía': 4052,
    'Talanga': 4052,
    'Támara': 4052,
    'Tatumbla': 4052,
    'Tegucigalpa': 4052,
    'Valle de Ángeles': 4052,
    'Vallecillo': 4052,
    'Villa de San Francisco': 4052,
    'Villa Nueva': 4052,
    'Yaguacire': 4052,
    'Zambrano': 4052,
    'Ahuas': 4048,
    'Auas': 4048,
    'Auka': 4048,
    'Barra Patuca': 4048,
    'Brus Laguna': 4048,
    'Iralaya': 4048,
    'Juan Francisco Bulnes': 4048,
    'Paptalaya': 4048,
    'Puerto Lempira': 4048,
    'Villeda Morales': 4048,
    'Wampusirpi': 4048,
    'Wawina': 4048,
    'Camasca': 4044,
    'Colomoncagua': 4044,
    'Concepción': 4044,
    'Dolores': 4044,
    'Intibucá': 4044,
    'Jesús de Otoro': 4044,
    'Jiquinlaca': 4044,
    'La Esperanza': 4044,
    'Magdalena': 4044,
    'Masaguara': 4044,
    'San Antonio': 4044,
    'San Francisco de Opalaca': 4044,
    'San Isidro': 4044,
    'San Juan': 4044,
    'San Marcos de la Sierra': 4044,
    'San Miguelito': 4044,
    'Santa Lucía': 4044,
    'Yamaranguila': 4044,
    'Aguanqueterique': 4058,
    'Cabañas': 4058,
    'Cane': 4058,
    'Chinacla': 4058,
    'Guajiquiro': 4058,
    'La Paz': 4058,
    'Lauterique': 4058,
    'Los Planes': 4058,
    'Marcala': 4058,
    'Mercedes de Oriente': 4058,
    'Opatoro': 4058,
    'San Antonio del Norte': 4058,
    'San José': 4058,
    'San Juan': 4058,
    'San Pedro de Tutule': 4058,
    'Santa Ana': 4058,
    'Santa Elena': 4058,
    'Santa María': 4058,
    'Santiago Puringla': 4058,
    'Tepanguare': 4058,
    'Yarula': 4058,
    'Yarumela': 4058,
    'Belén': 4054,
    'Candelaria': 4054,
    'Cololaca': 4054,
    'El Achiotal': 4054,
    'Erandique': 4054,
    'Gracias': 4054,
    'Gualcince': 4054,
    'Guarita': 4054,
    'La Campa': 4054,
    'La Iguala': 4054,
    'La Libertad': 4054,
    'La Unión': 4054,
    'La Virtud': 4054,
    'Las Flores': 4054,
    'Las Tejeras': 4054,
    'Lepaera': 4054,
    'Mapulaca': 4054,
    'Piraera': 4054,
    'San Andrés': 4054,
    'San Francisco': 4054,
    'San Juan Guarita': 4054,
    'San Manuel Colohete': 4054,
    'San Marcos de Caiquin': 4054,
    'San Rafael': 4054,
    'San Sebastián': 4054,
    'Santa Cruz': 4054,
    'Talgua': 4054,
    'Tambla': 4054,
    'Taragual': 4054,
    'Tomalá': 4054,
    'Valladolid': 4054,
    'Virginia': 4054,
    'Antigua Ocotepeque': 4056,
    'Belén Gualcho': 4056,
    'Concepción': 4056,
    'Dolores Merendón': 4056,
    'El Tránsito': 4056,
    'Fraternidad': 4056,
    'La Encarnación': 4056,
    'La Labor': 4056,
    'Lucerna': 4056,
    'Mercedes': 4056,
    'Nueva Ocotepeque': 4056,
    'San Fernando': 4056,
    'San Francisco de Cones': 4056,
    'San Francisco del Valle': 4056,
    'San Jorge': 4056,
    'San Marcos': 4056,
    'Santa Fe': 4056,
    'Santa Lucía': 4056,
    'Sensenti': 4056,
    'Sinuapa': 4056,
    'Yaruchel': 4056,
    'Arimís': 4050,
    'Campamento': 4050,
    'Catacamas': 4050,
    'Concordia': 4050,
    'Dulce Nombre de Culmí': 4050,
    'El Guayabito': 4050,
    'El Rosario': 4050,
    'El Rusio': 4050,
    'Esquipulas del Norte': 4050,
    'Gualaco': 4050,
    'Guarizama': 4050,
    'Guata': 4050,
    'Guayape': 4050,
    'Jano': 4050,
    'Juticalpa': 4050,
    'Jutiquile': 4050,
    'La Concepción': 4050,
    'La Estancia': 4050,
    'La Guata': 4050,
    'La Unión': 4050,
    'Laguna Seca': 4050,
    'Mangulile': 4050,
    'Manto': 4050,
    'Municipio de San Francisco de La Paz': 4050,
    'Patuca': 4050,
    'Punuare': 4050,
    'Salamá': 4050,
    'San Esteban': 4050,
    'San Francisco de Becerra': 4050,
    'San Francisco de la Paz': 4050,
    'San José de Río Tinto': 4050,
    'San Nicolás': 4050,
    'Santa María del Real': 4050,
    'Silca': 4050,
    'Yocón': 4050,
    'Zopilotepe': 4050,
    'Agualote': 4053,
    'Arada': 4053,
    'Atima': 4053,
    'Azacualpa': 4053,
    'Berlín': 4053,
    'Callejones': 4053,
    'Camalote': 4053,
    'Casa Quemada': 4053,
    'Ceguaca': 4053,
    'Chinda': 4053,
    'Concepción del Norte': 4053,
    'Concepción del Sur': 4053,
    'Correderos': 4053,
    'El Ciruelo': 4053,
    'El Corozal': 4053,
    'El Mochito': 4053,
    'El Níspero': 4053,
    'Guacamaya': 4053,
    'Gualala': 4053,
    'Gualjoco': 4053,
    'Ilama': 4053,
    'Joconal': 4053,
    'La Flecha': 4053,
    'Laguna Verde': 4053,
    'Las Vegas': 4053,
    'Las Vegas, Santa Barbara': 4053,
    'Loma Alta': 4053,
    'Macuelizo': 4053,
    'Naco': 4053,
    'Naranjito': 4053,
    'Nueva Frontera': 4053,
    'Nueva Jalapa': 4053,
    'Nuevo Celilac': 4053,
    'Petoa': 4053,
    'Pinalejo': 4053,
    'Protección': 4053,
    'Quimistán': 4053,
    'San Francisco de Ojuera': 4053,
    'San José de Colinas': 4053,
    'San José de Tarros': 4053,
    'San Luis': 4053,
    'San Luis de Planes': 4053,
    'San Marcos': 4053,
    'San Nicolás': 4053,
    'San Pedro Zacapa': 4053,
    'San Vicente Centenario': 4053,
    'Santa Bárbara': 4053,
    'Santa Rita': 4053,
    'Sula': 4053,
    'Tras Cerros': 4053,
    'Trinidad': 4053,
    'Agua Fría': 4055,
    'Alianza': 4055,
    'Amapala': 4055,
    'Aramecina': 4055,
    'Caridad': 4055,
    'El Cubolero': 4055,
    'El Tular': 4055,
    'Goascorán': 4055,
    'Jícaro Galán': 4055,
    'La Alianza': 4055,
    'La Criba': 4055,
    'Langue': 4055,
    'Nacaome': 4055,
    'San Francisco de Coray': 4055,
    'San Lorenzo': 4055,
    'Agua Blanca Sur': 4057,
    'Arenal': 4057,
    'Armenia': 4057,
    'Ayapa': 4057,
    'Bálsamo Oriental': 4057,
    'Carbajales': 4057,
    'Coyoles Central': 4057,
    'El Bálsamo': 4057,
    'El Juncal': 4057,
    'El Negrito': 4057,
    'El Ocote': 4057,
    'El Progreso': 4057,
    'Guaimitas': 4057,
    'Jocón': 4057,
    'La Estancia': 4057,
    'La Guacamaya': 4057,
    'La Mina': 4057,
    'La Rosa': 4057,
    'La Sarrosa': 4057,
    'La Trinidad': 4057,
    'Las Vegas': 4057,
    'Lomitas': 4057,
    'Mojimán': 4057,
    'Morazán': 4057,
    'Nombre de Jesús': 4057,
    'Nueva Esperanza': 4057,
    'Ocote Paulino': 4057,
    'Olanchito': 4057,
    'Paujiles': 4057,
    'Punta Ocote': 4057,
    'San Antonio': 4057,
    'San José': 4057,
    'Santa Rita': 4057,
    'Subirana': 4057,
    'Sulaco': 4057,
    'Teguajinal': 4057,
    'Tepusteca': 4057,
    'Toyós': 4057,
    'Trojas': 4057,
    'Victoria': 4057,
    'Yorito': 4057,
    'Yoro': 4057,
    'Ágasegyháza': 1048,
    'Akasztó': 1048,
    'Apostag': 1048,
    'Bácsalmás': 1048,
    'Bácsalmási Járás': 1048,
    'Bácsbokod': 1048,
    'Baja': 1048,
    'Bajai Járás': 1048,
    'Ballószög': 1048,
    'Bátya': 1048,
    'Bugac': 1048,
    'Császártöltés': 1048,
    'Csávoly': 1048,
    'Csengőd': 1048,
    'Dávod': 1048,
    'Dunapataj': 1048,
    'Dunavecse': 1048,
    'Dusnok': 1048,
    'Érsekcsanád': 1048,
    'Felsőszentiván': 1048,
    'Fülöpjakab': 1048,
    'Fülöpszállás': 1048,
    'Gara': 1048,
    'Hajós': 1048,
    'Harta': 1048,
    'Helvécia': 1048,
    'Hercegszántó': 1048,
    'Izsák': 1048,
    'Jánoshalma': 1048,
    'Jánoshalmai Járás': 1048,
    'Jászszentlászló': 1048,
    'Kalocsa': 1048,
    'Kalocsai Járás': 1048,
    'Katymár': 1048,
    'Kecel': 1048,
    'Kecskemét': 1048,
    'Kecskeméti Járás': 1048,
    'Kelebia': 1048,
    'Kerekegyháza': 1048,
    'Kiskőrös': 1048,
    'Kiskőrösi Járás': 1048,
    'Kiskunfélegyháza': 1048,
    'Kiskunfélegyházi Járás': 1048,
    'Kiskunhalas': 1048,
    'Kiskunhalasi Járás': 1048,
    'Kiskunmajsa': 1048,
    'Kiskunmajsai Járás': 1048,
    'Kisszállás': 1048,
    'Kunfehértó': 1048,
    'Kunszentmiklós': 1048,
    'Kunszentmiklósi Járás': 1048,
    'Lajosmizse': 1048,
    'Lakitelek': 1048,
    'Madaras': 1048,
    'Mélykút': 1048,
    'Nagybaracska': 1048,
    'Nemesnádudvar': 1048,
    'Nyárlőrinc': 1048,
    'Orgovány': 1048,
    'Pálmonostora': 1048,
    'Solt': 1048,
    'Soltvadkert': 1048,
    'Sükösd': 1048,
    'Szabadszállás': 1048,
    'Szalkszentmárton': 1048,
    'Szank': 1048,
    'Szentkirály': 1048,
    'Tass': 1048,
    'Tázlár': 1048,
    'Tiszaalpár': 1048,
    'Tiszakécske': 1048,
    'Tiszakécskei Járás': 1048,
    'Tompa': 1048,
    'Városföld': 1048,
    'Vaskút': 1048,
    'Beremend': 1055,
    'Bóly': 1055,
    'Bólyi Járás': 1055,
    'Bükkösd': 1055,
    'Dunaszekcső': 1055,
    'Harkány': 1055,
    'Hegyháti Járás': 1055,
    'Hidas': 1055,
    'Hosszúhetény': 1055,
    'Komló': 1055,
    'Komlói Járás': 1055,
    'Kozármisleny': 1055,
    'Lánycsók': 1055,
    'Mágocs': 1055,
    'Mecseknádasd': 1055,
    'Mohács': 1055,
    'Mohácsi Járás': 1055,
    'Pécs': 1055,
    'Pécsi Járás': 1055,
    'Pécsvárad': 1055,
    'Pécsváradi Járás': 1055,
    'Pellérd': 1055,
    'Sásd': 1055,
    'Sellye': 1055,
    'Sellyei Járás': 1055,
    'Siklós': 1055,
    'Siklósi Járás': 1055,
    'Szászvár': 1055,
    'Szentlőrinc': 1055,
    'Szentlőrinci Járás': 1055,
    'Szigetvár': 1055,
    'Szigetvári Járás': 1055,
    'Vajszló': 1055,
    'Villány': 1055,
    'Battonya': 1060,
    'Békés': 1060,
    'Békéscsaba': 1060,
    'Békéscsabai Járás': 1060,
    'Békési Járás': 1060,
    'Békéssámson': 1060,
    'Békésszentandrás': 1060,
    'Bucsa': 1060,
    'Csanádapáca': 1060,
    'Csorvás': 1060,
    'Dévaványa': 1060,
    'Doboz': 1060,
    'Dombegyház': 1060,
    'Elek': 1060,
    'Füzesgyarmat': 1060,
    'Gádoros': 1060,
    'Gyomaendrőd': 1060,
    'Gyomaendrődi Járás': 1060,
    'Gyula': 1060,
    'Gyulai Járás': 1060,
    'Kaszaper': 1060,
    'Kétegyháza': 1060,
    'Kevermes': 1060,
    'Kondoros': 1060,
    'Körösladány': 1060,
    'Köröstarcsa': 1060,
    'Kunágota': 1060,
    'Lőkösháza': 1060,
    'Magyarbánhegyes': 1060,
    'Medgyesegyháza': 1060,
    'Méhkerék': 1060,
    'Mezőberény': 1060,
    'Mezőhegyes': 1060,
    'Mezőkovácsháza': 1060,
    'Mezőkovácsházai Járás': 1060,
    'Nagyszénás': 1060,
    'Okány': 1060,
    'Orosháza': 1060,
    'Orosházi Járás': 1060,
    'Pusztaföldvár': 1060,
    'Sarkad': 1060,
    'Sarkadi Járás': 1060,
    'Szabadkígyós': 1060,
    'Szarvas': 1060,
    'Szarvasi Járás': 1060,
    'Szeghalmi Járás': 1060,
    'Szeghalom': 1060,
    'Tótkomlós': 1060,
    'Újkígyós': 1060,
    'Vésztő': 1060,
    'Abaújszántó': 1058,
    'Alsózsolca': 1058,
    'Arló': 1058,
    'Arnót': 1058,
    'Aszaló': 1058,
    'Bekecs': 1058,
    'Bőcs': 1058,
    'Bogács': 1058,
    'Boldva': 1058,
    'Borsodnádasd': 1058,
    'Cigánd': 1058,
    'Cigándi Járás': 1058,
    'Edelény': 1058,
    'Edelényi Járás': 1058,
    'Emőd': 1058,
    'Encs': 1058,
    'Encsi Járás': 1058,
    'Farkaslyuk': 1058,
    'Felsőzsolca': 1058,
    'Gesztely': 1058,
    'Gönc': 1058,
    'Gönci Járás': 1058,
    'Halmaj': 1058,
    'Harsány': 1058,
    'Hejőbába': 1058,
    'Hernádnémeti': 1058,
    'Izsófalva': 1058,
    'Járdánháza': 1058,
    'Karcsa': 1058,
    'Kazincbarcika': 1058,
    'Kazincbarcikai Járás': 1058,
    'Mád': 1058,
    'Mályi': 1058,
    'Megyaszó': 1058,
    'Mezőcsát': 1058,
    'Mezőcsáti Járás': 1058,
    'Mezőkeresztes': 1058,
    'Mezőkövesd': 1058,
    'Mezőkövesdi Járás': 1058,
    'Mezőzombor': 1058,
    'Miskolc': 1058,
    'Miskolci Járás': 1058,
    'Monok': 1058,
    'Múcsony': 1058,
    'Nyékládháza': 1058,
    'Olaszliszka': 1058,
    'Onga': 1058,
    'Ónod': 1058,
    'Ózd': 1058,
    'Ózdi Járás': 1058,
    'Prügy': 1058,
    'Putnok': 1058,
    'Putnoki Járás': 1058,
    'Ricse': 1058,
    'Rudabánya': 1058,
    'Sajóbábony': 1058,
    'Sajókaza': 1058,
    'Sajólád': 1058,
    'Sajóörös': 1058,
    'Sajószentpéter': 1058,
    'Sajószöged': 1058,
    'Sajóvámos': 1058,
    'Sály': 1058,
    'Sárospatak': 1058,
    'Sárospataki Járás': 1058,
    'Sátoraljaújhely': 1058,
    'Sátoraljaújhelyi Járás': 1058,
    'Szendrő': 1058,
    'Szentistván': 1058,
    'Szerencs': 1058,
    'Szerencsi Járás': 1058,
    'Szikszó': 1058,
    'Szikszói Járás': 1058,
    'Szirmabesenyő': 1058,
    'Taktaharkány': 1058,
    'Taktaszada': 1058,
    'Tállya': 1058,
    'Tarcal': 1058,
    'Tiszakarád': 1058,
    'Tiszakeszi': 1058,
    'Tiszalúc': 1058,
    'Tiszaújváros': 1058,
    'Tiszaújvárosi Járás': 1058,
    'Tokaj': 1058,
    'Tokaji Járás': 1058,
    'Tolcsva': 1058,
    'Budapest': 1064,
    'Budapest I. kerület': 1064,
    'Budapest II. kerület': 1064,
    'Budapest III. kerület': 1064,
    'Budapest IV. kerület': 1064,
    'Budapest VI. kerület': 1064,
    'Budapest VIII. kerület': 1064,
    'Budapest X. kerület': 1064,
    'Budapest XI. kerület': 1064,
    'Budapest XII. kerület': 1064,
    'Budapest XIII. kerület': 1064,
    'Budapest XV. kerület': 1064,
    'Budapest XVI. kerület': 1064,
    'Budapest XVII. kerület': 1064,
    'Budapest XVIII. kerület': 1064,
    'Budapest XX. kerület': 1064,
    'Budapest XXI. kerület': 1064,
    'Budapest XXII. kerület': 1064,
    'Budapest XXIII. kerület': 1064,
    'Erzsébetváros': 1064,
    'Józsefváros': 1064,
    'Kispest': 1064,
    'Zugló': 1064,
    'Algyő': 1031,
    'Apátfalva': 1031,
    'Ásotthalom': 1031,
    'Baks': 1031,
    'Balástya': 1031,
    'Bordány': 1031,
    'Csanádpalota': 1031,
    'Csanytelek': 1031,
    'Csengele': 1031,
    'Csongrád': 1031,
    'Csongrádi Járás': 1031,
    'Deszk': 1031,
    'Domaszék': 1031,
    'Fábiánsebestyén': 1031,
    'Földeák': 1031,
    'Forráskút': 1031,
    'Hódmezővásárhely': 1031,
    'Hódmezővásárhelyi Járás': 1031,
    'Kistelek': 1031,
    'Kisteleki Járás': 1031,
    'Kiszombor': 1031,
    'Makó': 1031,
    'Makói Járás': 1031,
    'Maroslele': 1031,
    'Mindszent': 1031,
    'Mórahalmi Járás': 1031,
    'Mórahalom': 1031,
    'Ópusztaszer': 1031,
    'Pusztaszer': 1031,
    'Röszke': 1031,
    'Ruzsa': 1031,
    'Sándorfalva': 1031,
    'Szatymaz': 1031,
    'Szeged': 1031,
    'Szegedi Járás': 1031,
    'Szegvár': 1031,
    'Székkutas': 1031,
    'Szentes': 1031,
    'Szentesi Járás': 1031,
    'Tömörkény': 1031,
    'Üllés': 1031,
    'Zákányszék': 1031,
    'Zsombó': 1031,
    'Aba': 1044,
    'Adony': 1044,
    'Alap': 1044,
    'Bakonycsernye': 1044,
    'Baracs': 1044,
    'Baracska': 1044,
    'Bicske': 1044,
    'Bicskei Járás': 1044,
    'Bodajk': 1044,
    'Cece': 1044,
    'Csákvár': 1044,
    'Dég': 1044,
    'dunaújváros': 1044,
    'Dunaújvárosi Járás': 1044,
    'Előszállás': 1044,
    'Enying': 1044,
    'Enyingi Járás': 1044,
    'Ercsi': 1044,
    'Etyek': 1044,
    'Fehérvárcsurgó': 1044,
    'Gárdony': 1044,
    'Gárdonyi Járás': 1044,
    'Iváncsa': 1044,
    'Káloz': 1044,
    'Kápolnásnyék': 1044,
    'Kincsesbánya': 1044,
    'Kisláng': 1044,
    'Lajoskomárom': 1044,
    'Lepsény': 1044,
    'Lovasberény': 1044,
    'Mány': 1044,
    'Martonvásár': 1044,
    'Martonvásári Járás': 1044,
    'Mezőfalva': 1044,
    'Mezőszilas': 1044,
    'Mór': 1044,
    'Móri Járás': 1044,
    'Pákozd': 1044,
    'Pázmánd': 1044,
    'Perkáta': 1044,
    'Polgárdi': 1044,
    'Pusztaszabolcs': 1044,
    'Pusztavám': 1044,
    'Rácalmás': 1044,
    'Ráckeresztúr': 1044,
    'Sárbogárd': 1044,
    'Sárbogárdi Járás': 1044,
    'Sárkeresztúr': 1044,
    'Sárosd': 1044,
    'Sárszentmihály': 1044,
    'Seregélyes': 1044,
    'Soponya': 1044,
    'Szabadbattyán': 1044,
    'Szárliget': 1044,
    'Székesfehérvár': 1044,
    'Székesfehérvári Járás': 1044,
    'Vál': 1044,
    'Velence': 1044,
    'Zámoly': 1044,
    'Abda': 1042,
    'Ágfalva': 1042,
    'Ásványráró': 1042,
    'Bakonyszentlászló': 1042,
    'Beled': 1042,
    'Bőny': 1042,
    'Bősárkány': 1042,
    'Csorna': 1042,
    'Csornai Járás': 1042,
    'Farád': 1042,
    'Fertőd': 1042,
    'Fertőrákos': 1042,
    'Fertőszentmiklós': 1042,
    'Győr': 1042,
    'Győri Járás': 1042,
    'Győrszemere': 1042,
    'Győrújbarát': 1042,
    'Halászi': 1042,
    'Jánossomorja': 1042,
    'Kapuvár': 1042,
    'Kapuvári Járás': 1042,
    'Kimle': 1042,
    'Kóny': 1042,
    'Lébény': 1042,
    'Mihályi': 1042,
    'Mosonmagyaróvár': 1042,
    'Mosonmagyaróvári Járás': 1042,
    'Mosonszentmiklós': 1042,
    'Nagycenk': 1042,
    'Nyúl': 1042,
    'Öttevény': 1042,
    'Pannonhalma': 1042,
    'Pannonhalmi Járás': 1042,
    'Pér': 1042,
    'Rábapatona': 1042,
    'Rajka': 1042,
    'Sopron': 1042,
    'Soproni Járás': 1042,
    'Szany': 1042,
    'Tét': 1042,
    'Téti Járás': 1042,
    'Töltéstava': 1042,
    'Bagamér': 1063,
    'Balmazújváros': 1063,
    'Balmazújvárosi Járás': 1063,
    'Báránd': 1063,
    'Berettyóújfalu': 1063,
    'Berettyóújfalui Járás': 1063,
    'Biharkeresztes': 1063,
    'Biharnagybajom': 1063,
    'Csökmő': 1063,
    'Debrecen': 1063,
    'Debreceni Járás': 1063,
    'Derecske': 1063,
    'Derecskei Járás': 1063,
    'Ebes': 1063,
    'Egyek': 1063,
    'Földes': 1063,
    'Görbeháza': 1063,
    'Hadjúszoboszlói Járás': 1063,
    'Hajdúbagos': 1063,
    'Hajdúböszörmény': 1063,
    'Hajdúböszörményi Járás': 1063,
    'Hajdúdorog': 1063,
    'Hajdúhadház': 1063,
    'Hajdúhadházi Járás': 1063,
    'Hajdúnánás': 1063,
    'Hajdúnánási Járás': 1063,
    'Hajdúsámson': 1063,
    'Hajdúszoboszló': 1063,
    'Hajdúszovát': 1063,
    'Hortobágy': 1063,
    'Hosszúpályi': 1063,
    'Kaba': 1063,
    'Komádi': 1063,
    'Konyár': 1063,
    'Létavértes': 1063,
    'Mikepércs': 1063,
    'Monostorpályi': 1063,
    'Nádudvar': 1063,
    'Nagyrábé': 1063,
    'Nyírábrány': 1063,
    'Nyíracsád': 1063,
    'Nyíradony': 1063,
    'Nyíradonyi Járás': 1063,
    'Nyírmártonfalva': 1063,
    'Pocsaj': 1063,
    'Polgár': 1063,
    'Püspökladány': 1063,
    'Püspökladányi Járás': 1063,
    'Sáránd': 1063,
    'Sárrétudvari': 1063,
    'Téglás': 1063,
    'Tiszacsege': 1063,
    'Vámospércs': 1063,
    'Abasár': 1040,
    'Adács': 1040,
    'Andornaktálya': 1040,
    'Apc': 1040,
    'Bélapátfalva': 1040,
    'Bélapátfalvai Járás': 1040,
    'Besenyőtelek': 1040,
    'Boldog': 1040,
    'Csány': 1040,
    'Domoszló': 1040,
    'Ecséd': 1040,
    'Eger': 1040,
    'Egerszalók': 1040,
    'Egri Járás': 1040,
    'Erdőtelek': 1040,
    'Felsőtárkány': 1040,
    'Füzesabony': 1040,
    'Füzesabonyi Járás': 1040,
    'Gyöngyös': 1040,
    'Gyöngyöshalász': 1040,
    'Gyöngyösi Járás': 1040,
    'Gyöngyöspata': 1040,
    'Gyöngyössolymos': 1040,
    'Gyöngyöstarján': 1040,
    'Hatvan': 1040,
    'Hatvani Járás': 1040,
    'Heréd': 1040,
    'Heves': 1040,
    'Hevesi Járás': 1040,
    'Hort': 1040,
    'Kál': 1040,
    'Karácsond': 1040,
    'Kerecsend': 1040,
    'Kisköre': 1040,
    'Kompolt': 1040,
    'Lőrinci': 1040,
    'Maklár': 1040,
    'Mátraderecske': 1040,
    'Nagyréde': 1040,
    'Ostoros': 1040,
    'Parád': 1040,
    'Parádsasvár': 1040,
    'Pétervására': 1040,
    'Pétervásárai Járás': 1040,
    'Petőfibánya': 1040,
    'Poroszló': 1040,
    'Recsk': 1040,
    'Rózsaszentmárton': 1040,
    'Sirok': 1040,
    'Szihalom': 1040,
    'Szilvásvárad': 1040,
    'Tarnalelesz': 1040,
    'Tarnaörs': 1040,
    'Tiszanána': 1040,
    'Vámosgyörk': 1040,
    'Verpelét': 1040,
    'Zagyvaszántó': 1040,
    'Abádszalók': 1043,
    'Alattyán': 1043,
    'Besenyszög': 1043,
    'Cibakháza': 1043,
    'Cserkeszőlő': 1043,
    'Fegyvernek': 1043,
    'Jánoshida': 1043,
    'Jászalsószentgyörgy': 1043,
    'Jászapáti': 1043,
    'Jászapáti Járás': 1043,
    'Jászárokszállás': 1043,
    'Jászberény': 1043,
    'Jászberényi Járás': 1043,
    'Jászdózsa': 1043,
    'Jászjákóhalma': 1043,
    'Jászkisér': 1043,
    'Jászladány': 1043,
    'Jászszentandrás': 1043,
    'Karcag': 1043,
    'Karcagi Járás': 1043,
    'Kenderes': 1043,
    'Kengyel': 1043,
    'Kisújszállás': 1043,
    'Kunhegyes': 1043,
    'Kunhegyesi Járás': 1043,
    'Kunmadaras': 1043,
    'Kunszentmárton': 1043,
    'Kunszentmártoni Járás': 1043,
    'Mezőtúr': 1043,
    'Mezőtúri Járás': 1043,
    'Öcsöd': 1043,
    'Rákóczifalva': 1043,
    'Rákócziújfalu': 1043,
    'Szajol': 1043,
    'Szelevény': 1043,
    'Szolnok': 1043,
    'Szolnoki Járás': 1043,
    'Tiszabő': 1043,
    'Tiszabura': 1043,
    'Tiszaföldvár': 1043,
    'Tiszafüred': 1043,
    'Tiszafüredi Járás': 1043,
    'Tiszapüspöki': 1043,
    'Tiszaroff': 1043,
    'Tiszasüly': 1043,
    'Tiszaszentimre': 1043,
    'Tiszaszőlős': 1043,
    'Törökszentmiklós': 1043,
    'Törökszentmiklósi Járás': 1043,
    'Tószeg': 1043,
    'Túrkeve': 1043,
    'Újszász': 1043,
    'Zagyvarékas': 1043,
    'Balassagyarmat': 1051,
    'Balassagyarmati Járás': 1051,
    'Bátonyterenye': 1051,
    'Bátonyterenyei Járás': 1051,
    'Bercel': 1051,
    'Buják': 1051,
    'Diósjenő': 1051,
    'Érsekvadkert': 1051,
    'Héhalom': 1051,
    'Jobbágyi': 1051,
    'Karancskeszi': 1051,
    'Karancslapujtő': 1051,
    'Kazár': 1051,
    'Mátranovák': 1051,
    'Mátraterenye': 1051,
    'Mátraverebély': 1051,
    'Nagyoroszi': 1051,
    'Palotás': 1051,
    'Pásztó': 1051,
    'Pásztói Járás': 1051,
    'Rétság': 1051,
    'Rétsági Járás': 1051,
    'Rimóc': 1051,
    'Romhány': 1051,
    'Salgótarján': 1051,
    'Salgótarjáni Járás': 1051,
    'Somoskőújfalu': 1051,
    'Szécsény': 1051,
    'Szécsényi Járás': 1051,
    'Szurdokpüspöki': 1051,
    'Tar': 1051,
    'Abony': 1059,
    'Acsa': 1059,
    'Albertirsa': 1059,
    'Alsónémedi': 1059,
    'Aszód': 1059,
    'Aszódi Járás': 1059,
    'Bag': 1059,
    'Biatorbágy': 1059,
    'Budakalász': 1059,
    'Budakeszi': 1059,
    'Budakeszi Járás': 1059,
    'Budaörs': 1059,
    'Bugyi': 1059,
    'Cegléd': 1059,
    'Ceglédbercel': 1059,
    'Ceglédi Járás': 1059,
    'Csemő': 1059,
    'Csobánka': 1059,
    'Csömör': 1059,
    'Dabas': 1059,
    'Dabasi Járás': 1059,
    'Dánszentmiklós': 1059,
    'Dány': 1059,
    'Délegyháza': 1059,
    'Diósd': 1059,
    'Domony': 1059,
    'Dömsöd': 1059,
    'Dunabogdány': 1059,
    'Dunaharaszti': 1059,
    'Dunakeszi': 1059,
    'Dunakeszi Járás': 1059,
    'Dunavarsány': 1059,
    'Ecser': 1059,
    'Érd': 1059,
    'Érdi Járás': 1059,
    'Erdőkertes': 1059,
    'Farmos': 1059,
    'Felsőpakony': 1059,
    'Forrópuszta': 1059,
    'Fót': 1059,
    'Galgahévíz': 1059,
    'Galgamácsa': 1059,
    'Göd': 1059,
    'Gödöllő': 1059,
    'Gödöllői Járás': 1059,
    'Gomba': 1059,
    'Gyál': 1059,
    'Gyáli Járás': 1059,
    'Gyömrő': 1059,
    'Halásztelek': 1059,
    'Hernád': 1059,
    'Hévízgyörk': 1059,
    'Iklad': 1059,
    'Inárcs': 1059,
    'Isaszeg': 1059,
    'Jászkarajenő': 1059,
    'Kakucs': 1059,
    'Kartal': 1059,
    'Kerepes': 1059,
    'Kiskunlacháza': 1059,
    'Kismaros': 1059,
    'Kistarcsa': 1059,
    'Kocsér': 1059,
    'Kóka': 1059,
    'Kosd': 1059,
    'Leányfalu': 1059,
    'Maglód': 1059,
    'Mende': 1059,
    'Mogyoród': 1059,
    'Monor': 1059,
    'Monori Járás': 1059,
    'Nagykáta': 1059,
    'Nagykátai Járás': 1059,
    'Nagykőrös': 1059,
    'Nagykőrösi Járás': 1059,
    'Nagykovácsi': 1059,
    'Nagymaros': 1059,
    'Nagytarcsa': 1059,
    'Nyáregyháza': 1059,
    'Ócsa': 1059,
    'Őrbottyán': 1059,
    'Örkény': 1059,
    'Pánd': 1059,
    'Páty': 1059,
    'Pécel': 1059,
    'Perbál': 1059,
    'Péteri': 1059,
    'Pilis': 1059,
    'Pilisborosjenő': 1059,
    'Piliscsaba': 1059,
    'Pilisszántó': 1059,
    'Pilisszentiván': 1059,
    'Pilisszentkereszt': 1059,
    'Pilisvörösvár': 1059,
    'Pilisvörösvári Járás': 1059,
    'Pomáz': 1059,
    'Ráckeve': 1059,
    'Ráckevei Járás': 1059,
    'Solymár': 1059,
    'Sóskút': 1059,
    'Sülysáp': 1059,
    'Szada': 1059,
    'Százhalombatta': 1059,
    'Szentendre': 1059,
    'Szentendrei Járás': 1059,
    'Szentlőrinckáta': 1059,
    'Szentmártonkáta': 1059,
    'Szigetcsép': 1059,
    'Szigethalom': 1059,
    'Szigetszentmiklós': 1059,
    'Szigetszentmiklósi Járás': 1059,
    'Szigetújfalu': 1059,
    'Szob': 1059,
    'Szobi Járás': 1059,
    'Sződ': 1059,
    'Sződliget': 1059,
    'Táborfalva': 1059,
    'Tahitótfalu': 1059,
    'Taksony': 1059,
    'Tápióbicske': 1059,
    'Tápiógyörgye': 1059,
    'Tápióság': 1059,
    'Tápiószecső': 1059,
    'Tápiószele': 1059,
    'Tápiószentmárton': 1059,
    'Tápiószőlős': 1059,
    'Tárnok': 1059,
    'Telki': 1059,
    'Tóalmás': 1059,
    'Tököl': 1059,
    'Törökbálint': 1059,
    'Törtel': 1059,
    'Tura': 1059,
    'Újhartyán': 1059,
    'Újszilvás': 1059,
    'Üllő': 1059,
    'Úri': 1059,
    'Üröm': 1059,
    'Vác': 1059,
    'Váci Járás': 1059,
    'Vácszentlászló': 1059,
    'Valkó': 1059,
    'Vecsés': 1059,
    'Vecsési Járás': 1059,
    'Veresegyház': 1059,
    'Verőce': 1059,
    'Visegrád': 1059,
    'Zsámbék': 1059,
    'Zsámbok': 1059,
    'Ádánd': 1035,
    'Babócsa': 1035,
    'Balatonberény': 1035,
    'Balatonboglár': 1035,
    'Balatonfenyves': 1035,
    'Balatonföldvár': 1035,
    'Balatonlelle': 1035,
    'Balatonszabadi': 1035,
    'Balatonszárszó': 1035,
    'Barcs': 1035,
    'Barcsi Járás': 1035,
    'Berzence': 1035,
    'Böhönye': 1035,
    'Csurgó': 1035,
    'Csurgói Járás': 1035,
    'Fonyód': 1035,
    'Fonyódi Járás': 1035,
    'Kadarkút': 1035,
    'Kaposmérő': 1035,
    'Kaposvár': 1035,
    'Kaposvári Járás': 1035,
    'Karád': 1035,
    'Kéthely': 1035,
    'Lábod': 1035,
    'Lengyeltóti': 1035,
    'Marcali': 1035,
    'Marcali Járás': 1035,
    'Nagyatád': 1035,
    'Nagyatádi Járás': 1035,
    'Nagybajom': 1035,
    'Segesd': 1035,
    'Siófok': 1035,
    'Siófoki Járás': 1035,
    'Somogyvár': 1035,
    'Tab': 1035,
    'Tabi Járás': 1035,
    'Taszár': 1035,
    'Zamárdi': 1035,
    'Ajak': 1045,
    'Anarcs': 1045,
    'Apagy': 1045,
    'Aranyosapáti': 1045,
    'Baktalórántháza': 1045,
    'Baktalórántházai Járás': 1045,
    'Balkány': 1045,
    'Bököny': 1045,
    'Buj': 1045,
    'Csenger': 1045,
    'Csengeri Járás': 1045,
    'Demecser': 1045,
    'Döge': 1045,
    'Dombrád': 1045,
    'Encsencs': 1045,
    'Fehérgyarmat': 1045,
    'Fehérgyarmati Járás': 1045,
    'Fényeslitke': 1045,
    'Gégény': 1045,
    'Gyulaháza': 1045,
    'Hodász': 1045,
    'Ibrány': 1045,
    'Ibrányi Járás': 1045,
    'Kállósemjén': 1045,
    'Kálmánháza': 1045,
    'Kántorjánosi': 1045,
    'Kék': 1045,
    'Kemecse': 1045,
    'Kemecsei Járás': 1045,
    'Kisléta': 1045,
    'Kisvárda': 1045,
    'Kisvárdai Járás': 1045,
    'Kocsord': 1045,
    'Kótaj': 1045,
    'Levelek': 1045,
    'Mándok': 1045,
    'Máriapócs': 1045,
    'Mátészalka': 1045,
    'Mátészalkai Járás': 1045,
    'Mérk': 1045,
    'Nagycserkesz': 1045,
    'Nagydobos': 1045,
    'Nagyecsed': 1045,
    'Nagyhalász': 1045,
    'Nagykálló': 1045,
    'Nagykállói Járás': 1045,
    'Napkor': 1045,
    'Nyírbátor': 1045,
    'Nyírbátori Járás': 1045,
    'Nyírbéltek': 1045,
    'Nyírbogát': 1045,
    'Nyírbogdány': 1045,
    'Nyírcsaholy': 1045,
    'Nyíregyháza': 1045,
    'Nyíregyházi Járás': 1045,
    'Nyírgyulaj': 1045,
    'Nyírkarász': 1045,
    'Nyírlugos': 1045,
    'Nyírmada': 1045,
    'Nyírmeggyes': 1045,
    'Nyírmihálydi': 1045,
    'Nyírpazony': 1045,
    'Nyírtass': 1045,
    'Nyírtelek': 1045,
    'Nyírvasvári': 1045,
    'Ófehértó': 1045,
    'Ököritófülpös': 1045,
    'Ópályi': 1045,
    'Pátroha': 1045,
    'Petneháza': 1045,
    'Porcsalma': 1045,
    'Rakamaz': 1045,
    'Szakoly': 1045,
    'Szamosszeg': 1045,
    'Tarpa': 1045,
    'Tiszabercel': 1045,
    'Tiszabezdéd': 1045,
    'Tiszadada': 1045,
    'Tiszadob': 1045,
    'Tiszaeszlár': 1045,
    'Tiszalök': 1045,
    'Tiszanagyfalu': 1045,
    'Tiszavasvári': 1045,
    'Tiszavasvári Járás': 1045,
    'Tornyospálca': 1045,
    'Tunyogmatolcs': 1045,
    'Tuzsér': 1045,
    'Tyukod': 1045,
    'Újfehértó': 1045,
    'Vaja': 1045,
    'Vásárosnamény': 1045,
    'Vásárosnaményi Járás': 1045,
    'Záhony': 1045,
    'Záhonyi Járás': 1045,
    'Báta': 1038,
    'Bátaszék': 1038,
    'Bogyiszló': 1038,
    'Bölcske': 1038,
    'Bonyhád': 1038,
    'Bonyhádi Járás': 1038,
    'Decs': 1038,
    'Döbrököz': 1038,
    'Dombóvár': 1038,
    'Dombóvári Járás': 1038,
    'Dunaföldvár': 1038,
    'Dunaszentgyörgy': 1038,
    'Fadd': 1038,
    'Gyönk': 1038,
    'Hőgyész': 1038,
    'Iregszemcse': 1038,
    'Madocsa': 1038,
    'Nagydorog': 1038,
    'Nagymányok': 1038,
    'Németkér': 1038,
    'Őcsény': 1038,
    'Ozora': 1038,
    'Paks': 1038,
    'Paksi Járás': 1038,
    'Pincehely': 1038,
    'Simontornya': 1038,
    'Szedres': 1038,
    'Szekszárd': 1038,
    'Szekszárdi Járás': 1038,
    'Szentgálpuszta': 1038,
    'Tamási': 1038,
    'Tamási Járás': 1038,
    'Tengelic': 1038,
    'Tolna': 1038,
    'Tolnai Járás': 1038,
    'Zomba': 1038,
    'Bük': 1039,
    'Celldömölk': 1039,
    'Celldömölki Járás': 1039,
    'Csepreg': 1039,
    'Gencsapáti': 1039,
    'Ják': 1039,
    'Jánosháza': 1039,
    'Körmend': 1039,
    'Körmendi Járás': 1039,
    'Kőszeg': 1039,
    'Kőszegi Járás': 1039,
    'Répcelak': 1039,
    'Sárvár': 1039,
    'Sárvári Járás': 1039,
    'Szentgotthárd': 1039,
    'Szentgotthárdi Járás': 1039,
    'Szombathely': 1039,
    'Szombathelyi Járás': 1039,
    'Táplánszentkereszt': 1039,
    'Vasvár': 1039,
    'Vasvári Járás': 1039,
    'Vép': 1039,
    'Ajka': 1054,
    'Ajkai Járás': 1054,
    'Badacsonytomaj': 1054,
    'Balatonalmádi': 1054,
    'Balatonalmádi Járás': 1054,
    'Balatonfüred': 1054,
    'Balatonfüredi Járás': 1054,
    'Balatonkenese': 1054,
    'Berhida': 1054,
    'Csabrendek': 1054,
    'Csetény': 1054,
    'Csopak': 1054,
    'Devecser': 1054,
    'Devecseri Járás': 1054,
    'Hajmáskér': 1054,
    'Herend': 1054,
    'Litér': 1054,
    'Nemesvámos': 1054,
    'Ősi': 1054,
    'Pápa': 1054,
    'Pápai Járás': 1054,
    'Pétfürdő': 1054,
    'Révfülöp': 1054,
    'Sümeg': 1054,
    'Sümegi Járás': 1054,
    'Szentkirályszabadja': 1054,
    'Tapolca': 1054,
    'Tapolcai Járás': 1054,
    'Tihany': 1054,
    'Úrkút': 1054,
    'Várpalota': 1054,
    'Várpalotai Járás': 1054,
    'Veszprém': 1054,
    'Veszprémi Járás': 1054,
    'Zánka': 1054,
    'Zirc': 1054,
    'Zirci Járás': 1054,
    'Becsehely': 1046,
    'Cserszegtomaj': 1046,
    'Gyenesdiás': 1046,
    'Hévíz': 1046,
    'Keszthely': 1046,
    'Keszthelyi Járás': 1046,
    'Lenti': 1046,
    'Lenti Járás': 1046,
    'Letenye': 1046,
    'Letenyei Járás': 1046,
    'Murakeresztúr': 1046,
    'Nagykanizsa': 1046,
    'Nagykanizsai Járás': 1046,
    'Pacsa': 1046,
    'Sármellék': 1046,
    'Türje': 1046,
    'Vonyarcvashegy': 1046,
    'Zalaegerszeg': 1046,
    'Zalaegerszegi Járás': 1046,
    'Zalakomár': 1046,
    'Zalalövő': 1046,
    'Zalaszentgrót': 1046,
    'Zalaszentgróti Járás': 1046,
    'Álftanes': 3431,
    'Garðabær': 3431,
    'Hafnarfjörður': 3431,
    'Kjósarhreppur': 3431,
    'Kópavogur': 3431,
    'Mosfellsbaer': 3431,
    'Mosfellsbær': 3431,
    'Reykjavík': 3431,
    'Seltjarnarnes': 3431,
    'Borgarfjarðarhreppur': 3433,
    'Breiðdalshreppur': 3433,
    'Egilsstaðir': 3433,
    'Eskifjörður': 3433,
    'Fjarðabyggð': 3433,
    'Fljótsdalshérað': 3433,
    'Fljótsdalshreppur': 3433,
    'Höfn': 3433,
    'Neskaupstaður': 3433,
    'Reyðarfjörður': 3433,
    'Akureyri': 3437,
    'Dalvík': 3437,
    'Dalvíkurbyggð': 3437,
    'Eyjafjarðarsveit': 3437,
    'Fjallabyggð': 3437,
    'Grýtubakkahreppur': 3437,
    'Hörgársveit': 3437,
    'Húsavík': 3437,
    'Langanesbyggð': 3437,
    'Laugar': 3437,
    'Siglufjörður': 3437,
    'Skútustaðahreppur': 3437,
    'Svalbarðsstrandarhreppur': 3437,
    'Tjörneshreppur': 3437,
    'Þingeyjarsveit': 3437,
    'Akrahreppur': 3435,
    'Húnaþing Vestra': 3435,
    'Sauðárkrókur': 3435,
    'Skagabyggð': 3435,
    'Sveitarfélagið Skagafjörður': 3435,
    'Garður': 3430,
    'Grindavík': 3430,
    'Keflavík': 3430,
    'Reykjanesbær': 3430,
    'Sandgerði': 3430,
    'Vogar': 3430,
    'Ásahreppur': 3434,
    'Bláskógabyggð': 3434,
    'Flóahreppur': 3434,
    'Grímsnes- og Grafningshreppur': 3434,
    'Hrunamannahreppur': 3434,
    'Hveragerði': 3434,
    'Mýrdalshreppur': 3434,
    'Selfoss': 3434,
    'Skaftárhreppur': 3434,
    'Skeiða- og Gnúpverjahreppur': 3434,
    'Vestmannaeyjabær': 3434,
    'Vestmannaeyjar': 3434,
    'Þorlákshöfn': 3434,
    'Akranes': 3436,
    'Borgarbyggð': 3436,
    'Borgarnes': 3436,
    'Dalabyggð': 3436,
    'Eyja- og Miklaholtshreppur': 3436,
    'Helgafellssveit': 3436,
    'Hvalfjarðarsveit': 3436,
    'Ólafsvík': 3436,
    'Skorradalshreppur': 3436,
    'Snæfellsbær': 3436,
    'Stykkishólmur': 3436,
    'Ísafjarðarbær': 3432,
    'Ísafjörður': 3432,
    'Reykhólahreppur': 3432,
    'Strandabyggð': 3432,
    'Tálknafjarðarhreppur': 3432,
    'Bamboo Flat': 4023,
    'Nicobar': 4023,
    'Port Blair': 4023,
    'South Andaman': 4023,
    'Addanki': 4017,
    'Adoni': 4017,
    'Akasahebpet': 4017,
    'Akividu': 4017,
    'Akkarampalle': 4017,
    'Amalapuram': 4017,
    'Amudalavalasa': 4017,
    'Anakapalle': 4017,
    'Anantapur': 4017,
    'Atmakur': 4017,
    'Attili': 4017,
    'Avanigadda': 4017,
    'Badvel': 4017,
    'Banganapalle': 4017,
    'Bapatla': 4017,
    'Betamcherla': 4017,
    'Bhattiprolu': 4017,
    'Bhimavaram': 4017,
    'Bhimunipatnam': 4017,
    'Bobbili': 4017,
    'Challapalle': 4017,
    'Chemmumiahpet': 4017,
    'Chilakalurupet': 4017,
    'Chinnachowk': 4017,
    'Chipurupalle': 4017,
    'Chirala': 4017,
    'Chittoor': 4017,
    'Chodavaram': 4017,
    'Cuddapah': 4017,
    'Cumbum': 4017,
    'Darsi': 4017,
    'Dharmavaram': 4017,
    'Dhone': 4017,
    'Diguvametta': 4017,
    'East Godavari': 4017,
    'Elamanchili': 4017,
    'Ellore': 4017,
    'Emmiganur': 4017,
    'Erraguntla': 4017,
    'Etikoppaka': 4017,
    'Gajuwaka': 4017,
    'Ganguvada': 4017,
    'Gannavaram': 4017,
    'Giddalur': 4017,
    'Gokavaram': 4017,
    'Gorantla': 4017,
    'Govindapuram,Chilakaluripet,Guntur': 4017,
    'Gudivada': 4017,
    'Gudlavalleru': 4017,
    'Gudur': 4017,
    'Guntakal Junction': 4017,
    'Guntur': 4017,
    'Hindupur': 4017,
    'Ichchapuram': 4017,
    'Jaggayyapeta': 4017,
    'Jammalamadugu': 4017,
    'Kadiri': 4017,
    'Kaikalur': 4017,
    'Kakinada': 4017,
    'Kalyandurg': 4017,
    'Kamalapuram': 4017,
    'Kandukur': 4017,
    'Kanigiri': 4017,
    'Kankipadu': 4017,
    'Kanuru': 4017,
    'Kavali': 4017,
    'Kolanukonda': 4017,
    'Kondapalle': 4017,
    'Korukollu': 4017,
    'Kosigi': 4017,
    'Kovvur': 4017,
    'Krishna': 4017,
    'Kuppam': 4017,
    'Kurnool': 4017,
    'Macherla': 4017,
    'Machilipatnam': 4017,
    'Madanapalle': 4017,
    'Madugula': 4017,
    'Mandapeta': 4017,
    'Mandasa': 4017,
    'Mangalagiri': 4017,
    'Markapur': 4017,
    'Nagari': 4017,
    'Nagireddipalli': 4017,
    'Nandigama': 4017,
    'Nandikotkur': 4017,
    'Nandyal': 4017,
    'Narasannapeta': 4017,
    'Narasapur': 4017,
    'Narasaraopet': 4017,
    'Narasingapuram': 4017,
    'Narayanavanam': 4017,
    'Narsipatnam': 4017,
    'Nayudupet': 4017,
    'Nellore': 4017,
    'Nidadavole': 4017,
    'Nuzvid': 4017,
    'Ongole': 4017,
    'Pakala': 4017,
    'Palakollu': 4017,
    'Palasa': 4017,
    'Palkonda': 4017,
    'Pallevada': 4017,
    'Palmaner': 4017,
    'Parlakimidi': 4017,
    'Parvatipuram': 4017,
    'Pavuluru': 4017,
    'Pedana': 4017,
    'pedda nakkalapalem': 4017,
    'Peddapuram': 4017,
    'Penugonda': 4017,
    'Penukonda': 4017,
    'Phirangipuram': 4017,
    'Pippara': 4017,
    'Pithapuram': 4017,
    'Polavaram': 4017,
    'Ponnur': 4017,
    'Ponnuru': 4017,
    'Prakasam': 4017,
    'Proddatur': 4017,
    'Pulivendla': 4017,
    'Punganuru': 4017,
    'Puttaparthi': 4017,
    'Puttur': 4017,
    'Rajahmundry': 4017,
    'Ramachandrapuram': 4017,
    'Ramanayyapeta': 4017,
    'Ramapuram': 4017,
    'Rampachodavaram': 4017,
    'Rayachoti': 4017,
    'Rayadrug': 4017,
    'Razam': 4017,
    'Razampeta': 4017,
    'Razole': 4017,
    'Renigunta': 4017,
    'Repalle': 4017,
    'Salur': 4017,
    'Samalkot': 4017,
    'Sattenapalle': 4017,
    'Singarayakonda': 4017,
    'Sompeta': 4017,
    'Srikakulam': 4017,
    'Srisailain': 4017,
    'Suluru': 4017,
    'Tadepalle': 4017,
    'Tadepallegudem': 4017,
    'Tadpatri': 4017,
    'Tanuku': 4017,
    'Tekkali': 4017,
    'Tirumala': 4017,
    'Tirupati': 4017,
    'Tuni': 4017,
    'Uravakonda': 4017,
    'vadlamuru': 4017,
    'Vadlapudi': 4017,
    'Venkatagiri': 4017,
    'Vepagunta': 4017,
    'Vetapalem': 4017,
    'Vijayawada': 4017,
    'Vinukonda': 4017,
    'Visakhapatnam': 4017,
    'Vizianagaram': 4017,
    'Vizianagaram District': 4017,
    'Vuyyuru': 4017,
    'West Godavari': 4017,
    'Yanam': 4017,
    'Yanamalakuduru': 4017,
    'Yarada': 4017,
    'Along': 4024,
    'Anjaw': 4024,
    'Basar': 4024,
    'Bomdila': 4024,
    'Changlang': 4024,
    'Dibang Valley': 4024,
    'East Kameng': 4024,
    'East Siang': 4024,
    'Hayuliang': 4024,
    'Itanagar': 4024,
    'Khonsa': 4024,
    'Kurung Kumey': 4024,
    'Lohit District': 4024,
    'Lower Dibang Valley': 4024,
    'Lower Subansiri': 4024,
    'Margherita': 4024,
    'Naharlagun': 4024,
    'Pasighat': 4024,
    'Tawang': 4024,
    'Tezu': 4024,
    'Tirap': 4024,
    'Upper Siang': 4024,
    'Upper Subansiri': 4024,
    'West Kameng': 4024,
    'West Siang': 4024,
    'Ziro': 4024,
    'Abhayapuri': 4027,
    'Amguri': 4027,
    'Badarpur': 4027,
    'Baksa': 4027,
    'Barpathar': 4027,
    'Barpeta': 4027,
    'Barpeta Road': 4027,
    'Basugaon': 4027,
    'Bihpuriagaon': 4027,
    'Bijni': 4027,
    'Bilasipara': 4027,
    'Bokajan': 4027,
    'Bokakhat': 4027,
    'Bongaigaon': 4027,
    'Cachar': 4027,
    'Chabua': 4027,
    'Chapar': 4027,
    'Chirang': 4027,
    'Darrang': 4027,
    'Dergaon': 4027,
    'Dhekiajuli': 4027,
    'Dhemaji': 4027,
    'Dhing': 4027,
    'Dhubri': 4027,
    'Dibrugarh': 4027,
    'Digboi': 4027,
    'Dima Hasao District': 4027,
    'Diphu': 4027,
    'Dispur': 4027,
    'Duliagaon': 4027,
    'Dum Duma': 4027,
    'Gauripur': 4027,
    'Goalpara': 4027,
    'Gohpur': 4027,
    'Golaghat': 4027,
    'Golakganj': 4027,
    'Goshaingaon': 4027,
    'Guwahati': 4027,
    'Haflong': 4027,
    'Hailakandi': 4027,
    'Hajo': 4027,
    'Hojai': 4027,
    'Howli': 4027,
    'Jogighopa': 4027,
    'Jorhat': 4027,
    'Kamrup': 4027,
    'Kamrup Metropolitan': 4027,
    'Karbi Anglong': 4027,
    'Karimganj': 4027,
    'Kharupatia': 4027,
    'Kokrajhar': 4027,
    'Lakhimpur': 4027,
    'Lakhipur': 4027,
    'Lala': 4027,
    'Lumding Railway Colony': 4027,
    'Mahur': 4027,
    'Maibong': 4027,
    'Makum': 4027,
    'Mangaldai': 4027,
    'Mariani': 4027,
    'Moranha': 4027,
    'Morigaon': 4027,
    'Nagaon': 4027,
    'Nahorkatiya': 4027,
    'Nalbari': 4027,
    'Namrup': 4027,
    'Nazira': 4027,
    'North Guwahati': 4027,
    'North Lakhimpur': 4027,
    'Numaligarh': 4027,
    'Palasbari': 4027,
    'Raha': 4027,
    'Rangapara': 4027,
    'Rangia': 4027,
    'Sapatgram': 4027,
    'Sarupathar': 4027,
    'Sibsagar': 4027,
    'Silapathar': 4027,
    'Silchar': 4027,
    'Soalkuchi': 4027,
    'Sonari': 4027,
    'Sonitpur': 4027,
    'Sorbhog': 4027,
    'Tezpur': 4027,
    'Tinsukia': 4027,
    'Titabar': 4027,
    'Udalguri': 4027,
    'Amarpur': 4037,
    'Araria': 4037,
    'Arrah': 4037,
    'Arwal': 4037,
    'Asarganj': 4037,
    'Aurangabad': 4037,
    'Bagaha': 4037,
    'Bahadurganj': 4037,
    'Bairagnia': 4037,
    'Baisi': 4037,
    'Bakhtiyarpur': 4037,
    'Bangaon': 4037,
    'Banka': 4037,
    'Banmankhi': 4037,
    'Bar Bigha': 4037,
    'Barauli': 4037,
    'Barh': 4037,
    'Barhiya': 4037,
    'Bariarpur': 4037,
    'Baruni': 4037,
    'Begusarai': 4037,
    'Belsand': 4037,
    'Bettiah': 4037,
    'Bhabhua': 4037,
    'Bhagalpur': 4037,
    'Bhagirathpur': 4037,
    'Bhawanipur': 4037,
    'Bhojpur': 4037,
    'Bihar Sharif': 4037,
    'Bihariganj': 4037,
    'Bikramganj': 4037,
    'Birpur': 4037,
    'Buddh Gaya': 4037,
    'Buxar': 4037,
    'Chakia': 4037,
    'Chapra': 4037,
    'Chhatapur': 4037,
    'Colgong': 4037,
    'Dalsingh Sarai': 4037,
    'Darbhanga': 4037,
    'Daudnagar': 4037,
    'Dehri': 4037,
    'Dhaka': 4037,
    'Dighwara': 4037,
    'Dinapore': 4037,
    'Dumra': 4037,
    'Dumraon': 4037,
    'Fatwa': 4037,
    'Forbesganj': 4037,
    'Gaya': 4037,
    'Ghoga': 4037,
    'Gopalganj': 4037,
    'Hajipur': 4037,
    'Hilsa': 4037,
    'Hisua': 4037,
    'Islampur': 4037,
    'Jagdispur': 4037,
    'Jahanabad': 4037,
    'Jamalpur': 4037,
    'Jamui': 4037,
    'Jaynagar': 4037,
    'Jehanabad': 4037,
    'Jha-Jha': 4037,
    'Jhanjharpur': 4037,
    'Jogbani': 4037,
    'Kaimur District': 4037,
    'Kasba': 4037,
    'Katihar': 4037,
    'Khagaria': 4037,
    'Khagaul': 4037,
    'Kharagpur': 4037,
    'Khusropur': 4037,
    'Kishanganj': 4037,
    'Koath': 4037,
    'Koelwar': 4037,
    'Lakhisarai': 4037,
    'Lalganj': 4037,
    'Luckeesarai': 4037,
    'Madhepura': 4037,
    'Madhipura': 4037,
    'Madhubani': 4037,
    'Maharajgani': 4037,
    'Mairwa': 4037,
    'Maner': 4037,
    'Manihari': 4037,
    'Marhaura': 4037,
    'Masaurhi Buzurg': 4037,
    'Mohiuddinnagar': 4037,
    'Mokameh': 4037,
    'Monghyr': 4037,
    'Mothihari': 4037,
    'Munger': 4037,
    'Murliganj': 4037,
    'Muzaffarpur': 4037,
    'Nabinagar': 4037,
    'Nalanda': 4037,
    'Nasriganj': 4037,
    'Naugachhia': 4037,
    'Nawada': 4037,
    'Nirmali': 4037,
    'Pashchim Champaran': 4037,
    'Patna': 4037,
    'Piro': 4037,
    'Pupri': 4037,
    'Purba Champaran': 4037,
    'Purnia': 4037,
    'Rafiganj': 4037,
    'Raghunathpur': 4037,
    'Rajgir': 4037,
    'Ramnagar': 4037,
    'Raxaul': 4037,
    'Revelganj': 4037,
    'Rohtas': 4037,
    'Rusera': 4037,
    'Sagauli': 4037,
    'Saharsa': 4037,
    'Samastipur': 4037,
    'Saran': 4037,
    'Shahbazpur': 4037,
    'Shahpur': 4037,
    'Sheikhpura': 4037,
    'Sheohar': 4037,
    'Sherghati': 4037,
    'Silao': 4037,
    'Sitamarhi': 4037,
    'Siwan': 4037,
    'Supaul': 4037,
    'Teghra': 4037,
    'Tekari': 4037,
    'Thakurganj': 4037,
    'Vaishali': 4037,
    'Waris Aliganj': 4037,
    'Chandigarh': 4031,
    'Akaltara': 4040,
    'Ambagarh Chauki': 4040,
    'Ambikapur': 4040,
    'Arang': 4040,
    'Baikunthpur': 4040,
    'Balod': 4040,
    'Baloda': 4040,
    'Baloda Bazar': 4040,
    'Basna': 4040,
    'Bastar': 4040,
    'Bemetara': 4040,
    'Bhanpuri': 4040,
    'Bhatapara': 4040,
    'Bhatgaon': 4040,
    'Bhilai': 4040,
    'Bijapur': 4040,
    'Bilaspur': 4040,
    'Champa': 4040,
    'Chhuikhadan': 4040,
    'Deori': 4040,
    'Dhamtari': 4040,
    'Dongargaon': 4040,
    'Dongargarh': 4040,
    'Durg': 4040,
    'Gandai': 4040,
    'Gariaband': 4040,
    'Gaurela': 4040,
    'Gharghoda': 4040,
    'Gidam': 4040,
    'Jagdalpur': 4040,
    'Janjgir': 4040,
    'Janjgir-Champa': 4040,
    'Jashpur': 4040,
    'Jashpurnagar': 4040,
    'Junagarh': 4040,
    'Kabeerdham': 4040,
    'Kanker': 4040,
    'Katghora': 4040,
    'Kawardha': 4040,
    'Khairagarh': 4040,
    'Khamharia': 4040,
    'Kharod': 4040,
    'Kharsia': 4040,
    'Kirandul': 4040,
    'Kondagaon': 4040,
    'Korba': 4040,
    'Koriya': 4040,
    'Kota': 4040,
    'Kotaparh': 4040,
    'Kumhari': 4040,
    'Kurud': 4040,
    'Lormi': 4040,
    'Mahasamund': 4040,
    'Mungeli': 4040,
    'Narayanpur': 4040,
    'Narharpur': 4040,
    'Pandaria': 4040,
    'Pandatarai': 4040,
    'Pasan': 4040,
    'Patan': 4040,
    'Pathalgaon': 4040,
    'Pendra': 4040,
    'Pithora': 4040,
    'Raigarh': 4040,
    'Raipur': 4040,
    'Raj Nandgaon': 4040,
    'Raj-Nandgaon': 4040,
    'Ramanuj Ganj': 4040,
    'Ratanpur': 4040,
    'Sakti': 4040,
    'Saraipali': 4040,
    'Sarangarh': 4040,
    'Seorinarayan': 4040,
    'Simga': 4040,
    'Surguja': 4040,
    'Takhatpur': 4040,
    'Umarkot': 4040,
    'Uttar Bastar Kanker': 4040,
    'Amli': 4033,
    'Dadra': 4033,
    'Dadra & Nagar Haveli': 4033,
    'Daman': 4033,
    'Daman District': 4033,
    'Diu': 4033,
    'Silvassa': 4033,
    'Alipur': 4021,
    'Bawana': 4021,
    'Central Delhi': 4021,
    'Delhi': 4021,
    'Deoli': 4021,
    'East Delhi': 4021,
    'Karol Bagh': 4021,
    'Najafgarh': 4021,
    'Nangloi Jat': 4021,
    'Narela': 4021,
    'New Delhi': 4021,
    'North Delhi': 4021,
    'North East Delhi': 4021,
    'North West Delhi': 4021,
    'Pitampura': 4021,
    'Rohini': 4021,
    'South Delhi': 4021,
    'South West Delhi': 4021,
    'West Delhi': 4021,
    'Aldona': 4009,
    'Arambol': 4009,
    'Baga': 4009,
    'Bambolim': 4009,
    'Bandora': 4009,
    'Benaulim': 4009,
    'Calangute': 4009,
    'Candolim': 4009,
    'Carapur': 4009,
    'Cavelossim': 4009,
    'Chicalim': 4009,
    'Chinchinim': 4009,
    'Colovale': 4009,
    'Colva': 4009,
    'Cortalim': 4009,
    'Cuncolim': 4009,
    'Curchorem': 4009,
    'Curti': 4009,
    'Davorlim': 4009,
    'Dicholi': 4009,
    'Goa Velha': 4009,
    'Guirim': 4009,
    'Jua': 4009,
    'Kankon': 4009,
    'Madgaon': 4009,
    'Mapuca': 4009,
    'Morjim': 4009,
    'Mormugao': 4009,
    'Navelim': 4009,
    'North Goa': 4009,
    'Palle': 4009,
    'Panaji': 4009,
    'Pernem': 4009,
    'Ponda': 4009,
    'Quepem': 4009,
    'Queula': 4009,
    'Raia': 4009,
    'Saligao': 4009,
    'Sancoale': 4009,
    'Sanguem': 4009,
    'Sanquelim': 4009,
    'Sanvordem': 4009,
    'Serula': 4009,
    'Solim': 4009,
    'South Goa': 4009,
    'Taleigao': 4009,
    'Vagator': 4009,
    'Valpoy': 4009,
    'Varca': 4009,
    'Vasco da Gama': 4009,
    'Abrama': 4030,
    'Adalaj': 4030,
    'Agol': 4030,
    'Ahmedabad': 4030,
    'Ahwa': 4030,
    'Akrund': 4030,
    'Amod': 4030,
    'Amod,': 4030,
    'Amreli': 4030,
    'Amroli': 4030,
    'Anand': 4030,
    'Anjar': 4030,
    'Ankleshwar': 4030,
    'Babra': 4030,
    'Bagasara': 4030,
    'Bagasra': 4030,
    'Bakharla': 4030,
    'Balagam': 4030,
    'Balasinor': 4030,
    'Balisana': 4030,
    'Bamanbore': 4030,
    'Banas Kantha': 4030,
    'Bandia': 4030,
    'Bantva': 4030,
    'Bardoli': 4030,
    'Bedi': 4030,
    'Bhachau': 4030,
    'Bhadran': 4030,
    'Bhandu': 4030,
    'Bhanvad': 4030,
    'Bharuch': 4030,
    'Bhatha': 4030,
    'Bhavnagar': 4030,
    'Bhayavadar': 4030,
    'Bhildi': 4030,
    'Bhojpur Dharampur': 4030,
    'Bhuj': 4030,
    'Bilimora': 4030,
    'Bilkha': 4030,
    'Borsad': 4030,
    'Botad': 4030,
    'Chaklasi': 4030,
    'Chalala': 4030,
    'Chaloda': 4030,
    'Champaner': 4030,
    'Chanasma': 4030,
    'Chhala': 4030,
    'Chhota Udepur': 4030,
    'Chikhli': 4030,
    'Chotila': 4030,
    'Chuda': 4030,
    'Dabhoda': 4030,
    'Dabhoi': 4030,
    'Dahegam': 4030,
    'Dahod': 4030,
    'Dakor': 4030,
    'Damnagar': 4030,
    'Dandi': 4030,
    'Dangs (India)': 4030,
    'Danta': 4030,
    'Dayapar': 4030,
    'Delvada': 4030,
    'Delwada': 4030,
    'Detroj': 4030,
    'Devbhumi Dwarka': 4030,
    'Devgadh Bariya': 4030,
    'Dhandhuka': 4030,
    'Dhanera': 4030,
    'Dhansura': 4030,
    'Dharampur': 4030,
    'Dharasana': 4030,
    'Dhari': 4030,
    'Dhasa': 4030,
    'Dhola': 4030,
    'Dholera': 4030,
    'Dholka': 4030,
    'Dhoraji': 4030,
    'Dhrangadhra': 4030,
    'Dhrol': 4030,
    'Dhuwaran': 4030,
    'Disa': 4030,
    'Dohad': 4030,
    'Dumkhal': 4030,
    'Dungarpur': 4030,
    'Dwarka': 4030,
    'Gadhada': 4030,
    'Gandevi': 4030,
    'Gandhidham': 4030,
    'Gandhinagar': 4030,
    'Gariadhar': 4030,
    'Ghodasar': 4030,
    'Ghogha': 4030,
    'Gir Somnath': 4030,
    'Godhra': 4030,
    'Gondal': 4030,
    'Gorwa': 4030,
    'Halenda': 4030,
    'Halol': 4030,
    'Halvad': 4030,
    'Hansot': 4030,
    'Harij': 4030,
    'Harsol': 4030,
    'Hathuran': 4030,
    'Himatnagar': 4030,
    'Idar': 4030,
    'Jakhau': 4030,
    'Jalalpore': 4030,
    'Jalalpur': 4030,
    'Jalia': 4030,
    'Jambuda': 4030,
    'Jambusar': 4030,
    'Jamnagar': 4030,
    'Jarod,': 4030,
    'Jasdan': 4030,
    'Jetalpur': 4030,
    'Jetalsar': 4030,
    'Jetpur': 4030,
    'Jetpur (Navagadh)': 4030,
    'Jhalod': 4030,
    'Jhulasan': 4030,
    'Jodhpur': 4030,
    'Jodhpur (Ahmedabad)': 4030,
    'Jodia': 4030,
    'Jodiya Bandar': 4030,
    'Junagadh': 4030,
    'Kachchh': 4030,
    'Kachholi': 4030,
    'Kadi': 4030,
    'Kadod': 4030,
    'Kalavad': 4030,
    'Kalol': 4030,
    'Kandla': 4030,
    'Kandla port': 4030,
    'Kanodar': 4030,
    'Kapadvanj': 4030,
    'Karamsad': 4030,
    'Kariana': 4030,
    'Karjan': 4030,
    'Kathor': 4030,
    'Katpur': 4030,
    'Kavant': 4030,
    'Kawant': 4030,
    'Kayavarohan': 4030,
    'Kerwada': 4030,
    'Keshod': 4030,
    'Khambhalia': 4030,
    'Khambhat': 4030,
    'Khavda': 4030,
    'Kheda': 4030,
    'Khedbrahma': 4030,
    'Khedoi': 4030,
    'Kherali': 4030,
    'Kheralu': 4030,
    'Kodinar': 4030,
    'Kosamba': 4030,
    'Kothara': 4030,
    'Kotharia': 4030,
    'Kukarmunda': 4030,
    'Kukma': 4030,
    'Kundla': 4030,
    'Kutch district': 4030,
    'Kutiyana': 4030,
    'Ladol': 4030,
    'Lakhpat': 4030,
    'Lakhtar': 4030,
    'Lalpur': 4030,
    'Langhnaj': 4030,
    'Lathi': 4030,
    'Limbdi': 4030,
    'Limkheda': 4030,
    'Lunavada': 4030,
    'Madhavpur Ghed': 4030,
    'Madhi': 4030,
    'Mahemdavad': 4030,
    'Mahesana': 4030,
    'Mahisa': 4030,
    'Mahudha': 4030,
    'Mahuva': 4030,
    'Mahuva (Surat)': 4030,
    'Malpur': 4030,
    'Manavadar': 4030,
    'Mandal': 4030,
    'Mandvi': 4030,
    'Mandvi (Surat)': 4030,
    'Mangrol': 4030,
    'Mangrol (Junagadh)': 4030,
    'Mansa': 4030,
    'Meghraj': 4030,
    'Mehsana': 4030,
    'Mendarda': 4030,
    'Mithapur': 4030,
    'Modasa': 4030,
    'Morbi': 4030,
    'Morva (Hadaf)': 4030,
    'Morwa': 4030,
    'Mundra': 4030,
    'Nadiad': 4030,
    'Nadiad,': 4030,
    'Nagwa': 4030,
    'Naldhara': 4030,
    'Naliya': 4030,
    'Nargol': 4030,
    'Narmada': 4030,
    'Naroda': 4030,
    'Navsari': 4030,
    'Nikora': 4030,
    'Nizar': 4030,
    'Odadar': 4030,
    'Okha': 4030,
    'Olpad': 4030,
    'Paddhari': 4030,
    'Padra': 4030,
    'Palanpur': 4030,
    'Palanswa': 4030,
    'Palitana': 4030,
    'Paliyad': 4030,
    'Paliyad (Bhavnagar)': 4030,
    'Palsana': 4030,
    'Panch Mahals': 4030,
    'Panchmahal district': 4030,
    'Pardi': 4030,
    'Parnera': 4030,
    'Patan': 4030,
    'Pavi Jetpur': 4030,
    'Petlad': 4030,
    'Pipavav': 4030,
    'Piplod': 4030,
    'Porbandar': 4030,
    'Prabhas Patan': 4030,
    'Prantij': 4030,
    'Radhanpur': 4030,
    'Rajkot': 4030,
    'Rajpipla': 4030,
    'Rajula': 4030,
    'Ranavav': 4030,
    'Ranpur': 4030,
    'Rapar': 4030,
    'Reha': 4030,
    'Roha': 4030,
    'Sabar Kantha': 4030,
    'Sachin': 4030,
    'Salaya': 4030,
    'Samakhiali': 4030,
    'Sanand': 4030,
    'Sankheda': 4030,
    'Sarbhon': 4030,
    'Sardoi': 4030,
    'Sarkhej': 4030,
    'Sathamba': 4030,
    'Savarkundla': 4030,
    'Savli': 4030,
    'Sayla': 4030,
    'Shahpur': 4030,
    'Shivrajpur': 4030,
    'Siddhpur': 4030,
    'Sihor': 4030,
    'Sikka': 4030,
    'Sinor': 4030,
    'Sojitra': 4030,
    'Songadh': 4030,
    'Supedi': 4030,
    'Surat': 4030,
    'Surendranagar': 4030,
    'Sutrapada': 4030,
    'Talaja': 4030,
    'Tankara': 4030,
    'Tapi': 4030,
    'Than': 4030,
    'Thangadh': 4030,
    'Tharad': 4030,
    'Thasra': 4030,
    'The Dangs': 4030,
    'Umarpada': 4030,
    'Umrala': 4030,
    'Umreth': 4030,
    'Un': 4030,
    'Una': 4030,
    'Unjha': 4030,
    'Upleta': 4030,
    'Utran': 4030,
    'Vadgam': 4030,
    'Vadnagar': 4030,
    'Vadodara': 4030,
    'Vaghodia': 4030,
    'Vaghodia INA': 4030,
    'Vallabh Vidyanagar': 4030,
    'Vallabhipur': 4030,
    'Valsad': 4030,
    'Vanala': 4030,
    'Vansda': 4030,
    'Vanthli': 4030,
    'Vapi': 4030,
    'Vartej': 4030,
    'Vasa': 4030,
    'Vasavad': 4030,
    'Vaso': 4030,
    'Vataman': 4030,
    'Vejalpur': 4030,
    'Veraval': 4030,
    'Vijapur': 4030,
    'Vinchhiya': 4030,
    'Vinchia': 4030,
    'Viramgam': 4030,
    'Virpur': 4030,
    'Visavadar': 4030,
    'Visnagar': 4030,
    'Vyara': 4030,
    'Wadhai': 4030,
    'Wadhwan': 4030,
    'Waghai': 4030,
    'Wankaner': 4030,
    'Ambala': 4007,
    'Asandh': 4007,
    'Ateli Mandi': 4007,
    'Bahadurgarh': 4007,
    'Bara Uchana': 4007,
    'Barwala': 4007,
    'Bawal': 4007,
    'Beri Khas': 4007,
    'Bhiwani': 4007,
    'Bilaspur': 4007,
    'Buriya': 4007,
    'Charkhi Dadri': 4007,
    'Chhachhrauli': 4007,
    'Dabwali': 4007,
    'Dharuhera': 4007,
    'Ellenabad': 4007,
    'Faridabad': 4007,
    'Faridabad District': 4007,
    'Farrukhnagar': 4007,
    'Fatehabad': 4007,
    'Fatehabad District': 4007,
    'Firozpur Jhirka': 4007,
    'Gharaunda': 4007,
    'Gohana': 4007,
    'Gorakhpur': 4007,
    'Gurgaon': 4007,
    'Hansi': 4007,
    'Hasanpur': 4007,
    'Hisar': 4007,
    'Hodal': 4007,
    'Inda Chhoi': 4007,
    'Indri': 4007,
    'Jagadhri': 4007,
    'Jakhal': 4007,
    'Jhajjar': 4007,
    'Jind': 4007,
    'Kaithal': 4007,
    'Kalanaur': 4007,
    'Kalanwali': 4007,
    'Kanina Khas': 4007,
    'Karnal': 4007,
    'Kharkhauda': 4007,
    'Kheri Sampla': 4007,
    'Kurukshetra': 4007,
    'Ladwa': 4007,
    'Loharu': 4007,
    'Maham': 4007,
    'Mahendragarh': 4007,
    'Mandholi Kalan': 4007,
    'Mustafabad': 4007,
    'Narayangarh': 4007,
    'Narnaul': 4007,
    'Narnaund': 4007,
    'Narwana': 4007,
    'Nilokheri': 4007,
    'Nuh': 4007,
    'Palwal': 4007,
    'Panchkula': 4007,
    'Panipat': 4007,
    'Pataudi': 4007,
    'Pehowa': 4007,
    'Pinjaur': 4007,
    'Punahana': 4007,
    'Pundri': 4007,
    'Radaur': 4007,
    'Rania': 4007,
    'Ratia': 4007,
    'Rewari': 4007,
    'Rewari District': 4007,
    'Rohtak': 4007,
    'Safidon': 4007,
    'Samalkha': 4007,
    'Shadipur Julana': 4007,
    'Shahabad': 4007,
    'Sirsa': 4007,
    'Sohna': 4007,
    'Sonipat': 4007,
    'Taoru': 4007,
    'Thanesar': 4007,
    'Tohana': 4007,
    'Tosham': 4007,
    'Uklana': 4007,
    'Yamunanagar': 4007,
    'Arki': 4020,
    'Baddi': 4020,
    'Banjar': 4020,
    'Bilaspur': 4020,
    'Chamba': 4020,
    'Chaupal': 4020,
    'Chowari': 4020,
    'Chuari Khas': 4020,
    'Dagshai': 4020,
    'Dalhousie': 4020,
    'Daulatpur': 4020,
    'Dera Gopipur': 4020,
    'Dharamsala': 4020,
    'Gagret': 4020,
    'Ghumarwin': 4020,
    'Hamirpur': 4020,
    'Jawala Mukhi': 4020,
    'Jogindarnagar': 4020,
    'Jubbal': 4020,
    'Jutogh': 4020,
    'Kalka': 4020,
    'Kangar': 4020,
    'Kangra': 4020,
    'Kasauli': 4020,
    'Kinnaur': 4020,
    'Kotkhai': 4020,
    'Kotla': 4020,
    'Kulu': 4020,
    'Kyelang': 4020,
    'Lahul and Spiti': 4020,
    'Manali': 4020,
    'Mandi': 4020,
    'Nadaun': 4020,
    'Nagar': 4020,
    'Nagrota': 4020,
    'Nahan': 4020,
    'Nalagarh': 4020,
    'Palampur': 4020,
    'Pandoh': 4020,
    'Paonta Sahib': 4020,
    'Parwanoo': 4020,
    'Rajgarh': 4020,
    'Rampur': 4020,
    'Rohru': 4020,
    'Sabathu': 4020,
    'Santokhgarh': 4020,
    'Sarahan': 4020,
    'Sarka Ghat': 4020,
    'Seoni': 4020,
    'Shimla': 4020,
    'Sirmaur': 4020,
    'Solan': 4020,
    'Sundarnagar': 4020,
    'Theog': 4020,
    'Tira Sujanpur': 4020,
    'Una': 4020,
    'Yol': 4020,
    'Akhnur': 4029,
    'Anantnag': 4029,
    'Awantipur': 4029,
    'Badgam': 4029,
    'Bandipore': 4029,
    'Bandipura': 4029,
    'Banihal': 4029,
    'Baramula': 4029,
    'Batoti': 4029,
    'Bhadarwah': 4029,
    'Bijbehara': 4029,
    'Bishnah': 4029,
    'Doda': 4029,
    'Gandarbal': 4029,
    'Ganderbal': 4029,
    'Gho Brahmanan de': 4029,
    'Hajan': 4029,
    'Hiranagar': 4029,
    'Jammu': 4029,
    'Jaurian': 4029,
    'Kathua': 4029,
    'Katra': 4029,
    'Khaur': 4029,
    'Kishtwar': 4029,
    'Kud': 4029,
    'Kulgam': 4029,
    'Kupwara': 4029,
    'Ladakh': 4029,
    'Magam': 4029,
    'Nawanshahr': 4029,
    'Noria': 4029,
    'Padam': 4029,
    'Pahlgam': 4029,
    'Parol': 4029,
    'Pattan': 4029,
    'Pulwama': 4029,
    'Punch': 4029,
    'Qazigund': 4029,
    'Rajaori': 4029,
    'Rajauri': 4029,
    'Ramban': 4029,
    'Ramgarh': 4029,
    'Ramnagar': 4029,
    'Riasi': 4029,
    'Samba': 4029,
    'Shupiyan': 4029,
    'Sopur': 4029,
    'Soyibug': 4029,
    'Srinagar': 4029,
    'Sumbal': 4029,
    'Thang': 4029,
    'Thanna Mandi': 4029,
    'Tral': 4029,
    'Tsrar Sharif': 4029,
    'Udhampur': 4029,
    'Uri': 4029,
    'Bagra': 4025,
    'Barka Kana': 4025,
    'Barki Saria': 4025,
    'Barwadih': 4025,
    'Bhojudih': 4025,
    'Bokaro': 4025,
    'Bundu': 4025,
    'Chaibasa': 4025,
    'Chakradharpur': 4025,
    'Chakulia': 4025,
    'Chandil': 4025,
    'Chas': 4025,
    'Chatra': 4025,
    'Chiria': 4025,
    'Daltonganj': 4025,
    'Deogarh': 4025,
    'Dhanbad': 4025,
    'Dhanwar': 4025,
    'Dugda': 4025,
    'Dumka': 4025,
    'Garhwa': 4025,
    'Ghatsila': 4025,
    'Giridih': 4025,
    'Gobindpur': 4025,
    'Godda': 4025,
    'Gomoh': 4025,
    'Gopinathpur': 4025,
    'Gua': 4025,
    'Gumia': 4025,
    'Gumla': 4025,
    'Hazaribag': 4025,
    'Hazaribagh': 4025,
    'Hesla': 4025,
    'Husainabad': 4025,
    'Jagannathpur': 4025,
    'Jamadoba': 4025,
    'Jamshedpur': 4025,
    'Jamtara': 4025,
    'Jasidih': 4025,
    'Jharia': 4025,
    'Jugsalai': 4025,
    'Jumri Tilaiya': 4025,
    'Kalikapur': 4025,
    'Kandra': 4025,
    'Kanke': 4025,
    'Katras': 4025,
    'Kenduadih': 4025,
    'Kharsawan': 4025,
    'Khunti': 4025,
    'Kodarma': 4025,
    'Kuju': 4025,
    'Latehar': 4025,
    'Lohardaga': 4025,
    'Madhupur': 4025,
    'Malkera': 4025,
    'Manoharpur': 4025,
    'Mugma': 4025,
    'Mushabani': 4025,
    'Neturhat': 4025,
    'Nirsa': 4025,
    'Noamundi': 4025,
    'Pakur': 4025,
    'Palamu': 4025,
    'Pashchim Singhbhum': 4025,
    'patamda': 4025,
    'Pathardih': 4025,
    'Purba Singhbhum': 4025,
    'Ramgarh': 4025,
    'Ranchi': 4025,
    'Ray': 4025,
    'Sahibganj': 4025,
    'Saraikela': 4025,
    'Sarubera': 4025,
    'Sijua': 4025,
    'Simdega': 4025,
    'Sini': 4025,
    'Topchanchi': 4025,
    'Afzalpur': 4026,
    'Ajjampur': 4026,
    'Aland': 4026,
    'Alnavar': 4026,
    'Alur': 4026,
    'Anekal': 4026,
    'Ankola': 4026,
    'Annigeri': 4026,
    'Arkalgud': 4026,
    'Arsikere': 4026,
    'Athni': 4026,
    'Aurad': 4026,
    'Badami': 4026,
    'Bagalkot': 4026,
    'Bagepalli': 4026,
    'Bail-Hongal': 4026,
    'Ballari': 4026,
    'Banavar': 4026,
    'Bangalore Rural': 4026,
    'Bangalore Urban': 4026,
    'Bangarapet': 4026,
    'Bannur': 4026,
    'Bantval': 4026,
    'Basavakalyan': 4026,
    'Basavana Bagevadi': 4026,
    'Belgaum': 4026,
    'Bellary': 4026,
    'Belluru': 4026,
    'Beltangadi': 4026,
    'Belur': 4026,
    'Bengaluru': 4026,
    'Bhadravati': 4026,
    'Bhalki': 4026,
    'Bhatkal': 4026,
    'Bidar': 4026,
    'Bijapur': 4026,
    'Bilgi': 4026,
    'Birur': 4026,
    'Byadgi': 4026,
    'Byndoor': 4026,
    'Canacona': 4026,
    'Challakere': 4026,
    'Chamrajnagar': 4026,
    'Channagiri': 4026,
    'Channapatna': 4026,
    'Channarayapatna': 4026,
    'Chik Ballapur': 4026,
    'Chikkaballapur': 4026,
    'Chikmagalur': 4026,
    'Chiknayakanhalli': 4026,
    'Chikodi': 4026,
    'Chincholi': 4026,
    'Chintamani': 4026,
    'Chitapur': 4026,
    'Chitradurga': 4026,
    'Closepet': 4026,
    'Coondapoor': 4026,
    'Dakshina Kannada': 4026,
    'Dandeli': 4026,
    'Davanagere': 4026,
    'Devanhalli': 4026,
    'Dharwad': 4026,
    'Dod Ballapur': 4026,
    'French Rocks': 4026,
    'Gadag': 4026,
    'Gadag-Betageri': 4026,
    'Gajendragarh': 4026,
    'Gangawati': 4026,
    'Gangolli': 4026,
    'Gokak': 4026,
    'Gokarna': 4026,
    'Goribidnur': 4026,
    'Gorur': 4026,
    'Gubbi': 4026,
    'Gudibanda': 4026,
    'Gulbarga': 4026,
    'Guledagudda': 4026,
    'Gundlupēt': 4026,
    'Gurmatkal': 4026,
    'Hadagalli': 4026,
    'Haliyal': 4026,
    'Hampi': 4026,
    'Hangal': 4026,
    'Harihar': 4026,
    'Harpanahalli': 4026,
    'Hassan': 4026,
    'Haveri': 4026,
    'Heggadadevankote': 4026,
    'Hirekerur': 4026,
    'Hiriyur': 4026,
    'Holalkere': 4026,
    'Hole Narsipur': 4026,
    'Homnabad': 4026,
    'Honavar': 4026,
    'Honnali': 4026,
    'Hosanagara': 4026,
    'Hosangadi': 4026,
    'Hosdurga': 4026,
    'Hoskote': 4026,
    'Hospet': 4026,
    'Hubli': 4026,
    'Hukeri': 4026,
    'Hungund': 4026,
    'Hunsur': 4026,
    'Ilkal': 4026,
    'Indi': 4026,
    'Jagalur': 4026,
    'Jamkhandi': 4026,
    'Jevargi': 4026,
    'Kadur': 4026,
    'Kalghatgi': 4026,
    'Kampli': 4026,
    'Kankanhalli': 4026,
    'Karkala': 4026,
    'Karwar': 4026,
    'Kavalur': 4026,
    'Kerur': 4026,
    'Khanapur': 4026,
    'Kodagu': 4026,
    'Kodigenahalli': 4026,
    'Kodlipet': 4026,
    'Kolar': 4026,
    'Kollegal': 4026,
    'Konanur': 4026,
    'Konnur': 4026,
    'Koppa': 4026,
    'Koppal': 4026,
    'Koratagere': 4026,
    'Kotturu': 4026,
    'Krishnarajpet': 4026,
    'Kudachi': 4026,
    'Kudligi': 4026,
    'Kumsi': 4026,
    'Kumta': 4026,
    'Kundgol': 4026,
    'Kunigal': 4026,
    'Kurgunta': 4026,
    'Kushalnagar': 4026,
    'Kushtagi': 4026,
    'Lakshmeshwar': 4026,
    'Lingsugur': 4026,
    'Londa': 4026,
    'Maddagiri': 4026,
    'Maddur': 4026,
    'Madikeri': 4026,
    'Magadi': 4026,
    'Mahalingpur': 4026,
    'Malavalli': 4026,
    'Malpe': 4026,
    'Malur': 4026,
    'Mandya': 4026,
    'Mangalore': 4026,
    'Manipal': 4026,
    'Manvi': 4026,
    'Mayakonda': 4026,
    'Melukote': 4026,
    'Mudbidri': 4026,
    'Muddebihal': 4026,
    'Mudgal': 4026,
    'Mudgere': 4026,
    'Mudhol': 4026,
    'Mulbagal': 4026,
    'Mulgund': 4026,
    'Mulki': 4026,
    'Mundargi': 4026,
    'Mundgod': 4026,
    'Munirabad': 4026,
    'Murudeshwara': 4026,
    'Mysore': 4026,
    'Nagamangala': 4026,
    'Nanjangud': 4026,
    'Narasimharajapura': 4026,
    'Naregal': 4026,
    'Nargund': 4026,
    'Navalgund': 4026,
    'Nelamangala': 4026,
    'Nyamti': 4026,
    'Pangala': 4026,
    'Pavugada': 4026,
    'Piriyapatna': 4026,
    'Ponnampet': 4026,
    'Puttur': 4026,
    'Rabkavi': 4026,
    'Raichur': 4026,
    'Ramanagara': 4026,
    'Ranibennur': 4026,
    'Raybag': 4026,
    'Robertsonpet': 4026,
    'Ron': 4026,
    'Sadalgi': 4026,
    'Sagar': 4026,
    'Sakleshpur': 4026,
    'Sandur': 4026,
    'Sanivarsante': 4026,
    'Sankeshwar': 4026,
    'Sargur': 4026,
    'Saundatti': 4026,
    'Savanur': 4026,
    'Seram': 4026,
    'Shahabad': 4026,
    'Shahpur': 4026,
    'Shiggaon': 4026,
    'Shikarpur': 4026,
    'Shimoga': 4026,
    'Shirhatti': 4026,
    'Shorapur': 4026,
    'Shrirangapattana': 4026,
    'Siddapur': 4026,
    'Sidlaghatta': 4026,
    'Sindgi': 4026,
    'Sindhnur': 4026,
    'Sira': 4026,
    'Sirsi': 4026,
    'Siruguppa': 4026,
    'Someshwar': 4026,
    'Somvarpet': 4026,
    'Sorab': 4026,
    'Sravana Belgola': 4026,
    'Sringeri': 4026,
    'Srinivaspur': 4026,
    'Sulya': 4026,
    'Suntikoppa': 4026,
    'Talikota': 4026,
    'Tarikere': 4026,
    'Tekkalakote': 4026,
    'Terdal': 4026,
    'Tiptur': 4026,
    'Tirthahalli': 4026,
    'Tirumakudal Narsipur': 4026,
    'Tumkur': 4026,
    'Turuvekere': 4026,
    'Udupi': 4026,
    'Ullal': 4026,
    'Uttar Kannada': 4026,
    'Vadigenhalli': 4026,
    'Virarajendrapet': 4026,
    'Wadi': 4026,
    'Yadgir': 4026,
    'Yelahanka': 4026,
    'Yelandur': 4026,
    'Yelbarga': 4026,
    'Yellapur': 4026,
    'Adur': 4028,
    'Alappuzha': 4028,
    'Aluva': 4028,
    'Alwaye': 4028,
    'Angamali': 4028,
    'Aroor': 4028,
    'Arukutti': 4028,
    'Attingal': 4028,
    'Avanoor': 4028,
    'Azhikkal': 4028,
    'Badagara': 4028,
    'Beypore': 4028,
    'Changanacheri': 4028,
    'Chēlakara': 4028,
    'Chengannur': 4028,
    'Cherpulassery': 4028,
    'Cherthala': 4028,
    'Chetwayi': 4028,
    'Chittur': 4028,
    'Cochin': 4028,
    'Dharmadam': 4028,
    'Edakkulam': 4028,
    'Elur': 4028,
    'Erattupetta': 4028,
    'Ernakulam': 4028,
    'Ferokh': 4028,
    'Guruvayur': 4028,
    'Idukki': 4028,
    'Iringal': 4028,
    'Irinjalakuda': 4028,
    'Kadakkavoor': 4028,
    'Kalamassery': 4028,
    'Kalavoor': 4028,
    'Kalpatta': 4028,
    'Kannangad': 4028,
    'Kannavam': 4028,
    'Kannur': 4028,
    'Kasaragod': 4028,
    'Kasaragod District': 4028,
    'Kattanam': 4028,
    'Kayankulam': 4028,
    'Kizhake Chalakudi': 4028,
    'Kodungallur': 4028,
    'Kollam': 4028,
    'Kotamangalam': 4028,
    'Kottayam': 4028,
    'Kovalam': 4028,
    'Kozhikode': 4028,
    'Kumbalam': 4028,
    'Kunnamangalam': 4028,
    'Kunnamkulam': 4028,
    'Kunnumma': 4028,
    'Kutiatodu': 4028,
    'Kuttampuzha': 4028,
    'Lalam': 4028,
    'Mahē': 4028,
    'Malappuram': 4028,
    'Manjeri': 4028,
    'Manjēshvar': 4028,
    'Mannarakkat': 4028,
    'Marayur': 4028,
    'Mattanur': 4028,
    'Mavelikara': 4028,
    'Mavoor': 4028,
    'Muluppilagadu': 4028,
    'Munnar': 4028,
    'Muvattupula': 4028,
    'Muvattupuzha': 4028,
    'Nadapuram': 4028,
    'Naduvannur': 4028,
    'Nedumangad': 4028,
    'Neyyattinkara': 4028,
    'Nilēshwar': 4028,
    'Ottappalam': 4028,
    'Palackattumala': 4028,
    'Palakkad district': 4028,
    'Palghat': 4028,
    'Panamaram': 4028,
    'Pappinisshēri': 4028,
    'Paravur Tekkumbhagam': 4028,
    'Pariyapuram': 4028,
    'Pathanamthitta': 4028,
    'Pattanamtitta': 4028,
    'Payyannur': 4028,
    'Perumbavoor': 4028,
    'Perumpavur': 4028,
    'Perya': 4028,
    'Piravam': 4028,
    'Ponmana': 4028,
    'Ponnani': 4028,
    'Punalur': 4028,
    'Ramamangalam': 4028,
    'Shertallai': 4028,
    'Shōranur': 4028,
    'Talipparamba': 4028,
    'Tellicherry': 4028,
    'Thanniyam': 4028,
    'Thiruvananthapuram': 4028,
    'Thrissur': 4028,
    'Thrissur District': 4028,
    'Tirur': 4028,
    'Tiruvalla': 4028,
    'Vaikam': 4028,
    'Varkala': 4028,
    'Vayalar': 4028,
    'Vettur': 4028,
    'Wayanad': 4028,
    'Kargil': 4852,
    'Leh': 4852,
    'Kavaratti': 4019,
    'Lakshadweep': 4019,
    'Agar': 4039,
    'Ajaigarh': 4039,
    'Akodia': 4039,
    'Alampur': 4039,
    'Alirajpur': 4039,
    'Alot': 4039,
    'Amanganj': 4039,
    'Amarkantak': 4039,
    'Amarpatan': 4039,
    'Amarwara': 4039,
    'Ambah': 4039,
    'Amla': 4039,
    'Anjad': 4039,
    'Antri': 4039,
    'Anuppur': 4039,
    'Aron': 4039,
    'Ashoknagar': 4039,
    'Ashta': 4039,
    'Babai': 4039,
    'Badarwas': 4039,
    'Badnawar': 4039,
    'Bag': 4039,
    'Bagli': 4039,
    'Baihar': 4039,
    'Baikunthpur': 4039,
    'Bakshwaho': 4039,
    'Balaghat': 4039,
    'Baldeogarh': 4039,
    'Bamna': 4039,
    'Bamor Kalan': 4039,
    'Bamora': 4039,
    'Banda': 4039,
    'Barela': 4039,
    'Barghat': 4039,
    'Bargi': 4039,
    'Barhi': 4039,
    'Barwani': 4039,
    'Basoda': 4039,
    'Begamganj': 4039,
    'Beohari': 4039,
    'Berasia': 4039,
    'Betma': 4039,
    'Betul': 4039,
    'Betul Bazar': 4039,
    'Bhabhra': 4039,
    'Bhainsdehi': 4039,
    'Bhander': 4039,
    'Bhanpura': 4039,
    'Bhawaniganj': 4039,
    'Bhikangaon': 4039,
    'Bhind': 4039,
    'Bhitarwar': 4039,
    'Bhopal': 4039,
    'Biaora': 4039,
    'Bijawar': 4039,
    'Bijrauni': 4039,
    'Bodri': 4039,
    'Burhanpur': 4039,
    'Burhar': 4039,
    'Chanderi': 4039,
    'Chandia': 4039,
    'Chandla': 4039,
    'Chhatarpur': 4039,
    'Chhindwara': 4039,
    'Chichli': 4039,
    'Chorhat': 4039,
    'Daboh': 4039,
    'Dabra': 4039,
    'Damoh': 4039,
    'Datia': 4039,
    'Deori Khas': 4039,
    'Depalpur': 4039,
    'Dewas': 4039,
    'Dhamnod': 4039,
    'Dhana': 4039,
    'Dhar': 4039,
    'Dharampuri': 4039,
    'Dindori': 4039,
    'Etawa': 4039,
    'Gadarwara': 4039,
    'Garha Brahman': 4039,
    'Garhakota': 4039,
    'Gautampura': 4039,
    'Ghansor': 4039,
    'Gogapur': 4039,
    'Gohadi': 4039,
    'Govindgarh': 4039,
    'Guna': 4039,
    'Gurh': 4039,
    'Gwalior': 4039,
    'Harda': 4039,
    'Harda Khas': 4039,
    'Harpalpur': 4039,
    'Harrai': 4039,
    'Harsud': 4039,
    'Hatod': 4039,
    'Hatta': 4039,
    'Hindoria': 4039,
    'Hoshangabad': 4039,
    'Iawar': 4039,
    'Ichhawar': 4039,
    'Iklehra': 4039,
    'Indore': 4039,
    'Isagarh': 4039,
    'Itarsi': 4039,
    'Jabalpur': 4039,
    'Jaisinghnagar': 4039,
    'Jaithari': 4039,
    'Jamai': 4039,
    'Jaora': 4039,
    'Jatara': 4039,
    'Jawad': 4039,
    'Jhabua': 4039,
    'Jiran': 4039,
    'Jobat': 4039,
    'Kailaras': 4039,
    'Kaimori': 4039,
    'Kannod': 4039,
    'Kareli': 4039,
    'Karera': 4039,
    'Karrapur': 4039,
    'Kasrawad': 4039,
    'Katangi': 4039,
    'Katni': 4039,
    'Khachrod': 4039,
    'Khailar': 4039,
    'Khajuraho Group of Monuments': 4039,
    'Khamaria': 4039,
    'Khandwa': 4039,
    'Khandwa district': 4039,
    'Khargapur': 4039,
    'Khargone': 4039,
    'Khategaon': 4039,
    'Khilchipur': 4039,
    'Khirkiyan': 4039,
    'Khujner': 4039,
    'Khurai': 4039,
    'Kolaras': 4039,
    'Korwai': 4039,
    'Kotar': 4039,
    'Kothi': 4039,
    'Kotma': 4039,
    'Kotwa': 4039,
    'Kukshi': 4039,
    'Kumbhraj': 4039,
    'Lahar': 4039,
    'Lakhnadon': 4039,
    'Leteri': 4039,
    'Lodhikheda': 4039,
    'Machalpur': 4039,
    'Madhogarh': 4039,
    'Maheshwar': 4039,
    'Mahgawan': 4039,
    'Maihar': 4039,
    'Majholi': 4039,
    'Maksi': 4039,
    'Malhargarh': 4039,
    'Manasa': 4039,
    'Manawar': 4039,
    'Mandideep': 4039,
    'Mandla': 4039,
    'Mandleshwar': 4039,
    'Mandsaur': 4039,
    'Mangawan': 4039,
    'Manpur': 4039,
    'Mau': 4039,
    'Mauganj': 4039,
    'Mihona': 4039,
    'Mohgaon': 4039,
    'Morar': 4039,
    'Morena': 4039,
    'Multai': 4039,
    'Mundi': 4039,
    'Mungaoli': 4039,
    'Murwara': 4039,
    'Nagda': 4039,
    'Nagod': 4039,
    'Naigarhi': 4039,
    'Nainpur': 4039,
    'Namli': 4039,
    'Naraini': 4039,
    'Narayangarh': 4039,
    'Narsimhapur': 4039,
    'Narsinghgarh': 4039,
    'Narwar': 4039,
    'Nasrullahganj': 4039,
    'Neemuch': 4039,
    'Nepanagar': 4039,
    'Orchha': 4039,
    'Pachmarhi': 4039,
    'Palera': 4039,
    'Pali': 4039,
    'Panagar': 4039,
    'Panara': 4039,
    'Pandhana': 4039,
    'Pandhurna': 4039,
    'Panna': 4039,
    'Pansemal': 4039,
    'Parasia': 4039,
    'Patan': 4039,
    'Patharia': 4039,
    'Pawai': 4039,
    'Petlawad': 4039,
    'Piploda': 4039,
    'Pithampur': 4039,
    'Porsa': 4039,
    'Punasa': 4039,
    'Raghogarh': 4039,
    'Rahatgarh': 4039,
    'Raisen': 4039,
    'Rajgarh': 4039,
    'Rajnagar': 4039,
    'Rajpur': 4039,
    'Rampura': 4039,
    'Ranapur': 4039,
    'Ratangarh': 4039,
    'Ratlam': 4039,
    'Rehli': 4039,
    'Rehti': 4039,
    'Rewa': 4039,
    'Sabalgarh': 4039,
    'Sagar': 4039,
    'Sailana': 4039,
    'Sanawad': 4039,
    'Sanchi': 4039,
    'Sanwer': 4039,
    'Sarangpur': 4039,
    'Satna': 4039,
    'Satwas': 4039,
    'Saugor': 4039,
    'Sausar': 4039,
    'Sehore': 4039,
    'Sendhwa': 4039,
    'Seondha': 4039,
    'Seoni': 4039,
    'Seoni Malwa': 4039,
    'Shahdol': 4039,
    'Shahgarh': 4039,
    'Shahpur': 4039,
    'Shahpura': 4039,
    'Shajapur': 4039,
    'Shamgarh': 4039,
    'Sheopur': 4039,
    'Shivpuri': 4039,
    'Shujalpur': 4039,
    'Sidhi': 4039,
    'Sihora': 4039,
    'Simaria': 4039,
    'Singoli': 4039,
    'Singrauli': 4039,
    'Sirmaur': 4039,
    'Sironj': 4039,
    'Sitamau': 4039,
    'Sohagi': 4039,
    'Sohagpur': 4039,
    'Sultanpur': 4039,
    'Susner': 4039,
    'Tal': 4039,
    'Talen': 4039,
    'Tarana': 4039,
    'Tekanpur': 4039,
    'Tendukheda': 4039,
    'Teonthar': 4039,
    'Thandla': 4039,
    'Tikamgarh': 4039,
    'Tirodi': 4039,
    'Udaipura': 4039,
    'Ujjain': 4039,
    'Ukwa': 4039,
    'Umaria': 4039,
    'Umaria District': 4039,
    'Umri': 4039,
    'Unhel': 4039,
    'Vidisha': 4039,
    'Waraseoni': 4039,
    'Achalpur': 4008,
    'Adawad': 4008,
    'Agar Panchaitan': 4008,
    'Aheri': 4008,
    'Ahiri': 4008,
    'Ahmadnagar': 4008,
    'Ahmadpur': 4008,
    'Ahmednagar': 4008,
    'Airoli': 4008,
    'Ajra': 4008,
    'Akalkot': 4008,
    'Akkalkot': 4008,
    'Akluj': 4008,
    'Akola': 4008,
    'Akolner': 4008,
    'Akot': 4008,
    'Akrani': 4008,
    'Alandi': 4008,
    'Ale': 4008,
    'Alibag': 4008,
    'Alkuti': 4008,
    'Allapalli': 4008,
    'Amalner': 4008,
    'Amarnath': 4008,
    'Ambad': 4008,
    'Ambajogai': 4008,
    'Ambarnath': 4008,
    'Ambegaon': 4008,
    'Amgaon': 4008,
    'Amravati': 4008,
    'Amravati Division': 4008,
    'Andheri': 4008,
    'Andura': 4008,
    'Anjangaon': 4008,
    'Anjarle': 4008,
    'Anshing': 4008,
    'Arag': 4008,
    'Arangaon': 4008,
    'Ardhapur': 4008,
    'Argaon': 4008,
    'Artist Village': 4008,
    'Arvi': 4008,
    'Ashta': 4008,
    'Ashti': 4008,
    'Asoda': 4008,
    'Assaye': 4008,
    'Astagaon': 4008,
    'Aundh, Satara': 4008,
    'Aurangabad': 4008,
    'Ausa': 4008,
    'Badlapur': 4008,
    'Badnapur': 4008,
    'Badnera': 4008,
    'Bagewadi': 4008,
    'Balapur': 4008,
    'Balapur, Akola district': 4008,
    'Ballalpur': 4008,
    'Ballard Estate': 4008,
    'Ballarpur': 4008,
    'Banda, Maharashtra': 4008,
    'Bandra': 4008,
    'Baner': 4008,
    'Bankot': 4008,
    'Baramati': 4008,
    'Barsi': 4008,
    'Basmat': 4008,
    'Basmath': 4008,
    'Bavdhan': 4008,
    'Bawanbir': 4008,
    'Beed': 4008,
    'Bhadgaon, Maharashtra': 4008,
    'Bhandara': 4008,
    'Bhandardara': 4008,
    'Bhandup': 4008,
    'Bhayandar': 4008,
    'Bhigvan': 4008,
    'Bhiwandi': 4008,
    'Bhiwapur': 4008,
    'Bhokar': 4008,
    'Bhokardan': 4008,
    'Bhoom': 4008,
    'Bhor': 4008,
    'Bhudgaon': 4008,
    'Bhugaon': 4008,
    'Bhum': 4008,
    'Bhusaval': 4008,
    'Bhusawal': 4008,
    'Bid': 4008,
    'Bijur': 4008,
    'Bilashi': 4008,
    'Biloli': 4008,
    'Boisar': 4008,
    'Borgaon Manju': 4008,
    'Borivali': 4008,
    'Borivli': 4008,
    'Brahmapuri': 4008,
    'Breach Candy': 4008,
    'Buldana': 4008,
    'Buldhana': 4008,
    'Byculla': 4008,
    'Chakan': 4008,
    'Chakur': 4008,
    'Chalisgaon': 4008,
    'Chanda': 4008,
    'Chandgad': 4008,
    'Chandor': 4008,
    'Chandrapur': 4008,
    'Chandur': 4008,
    'Chandur Bazar': 4008,
    'Chausala': 4008,
    'Chembur': 4008,
    'Chicholi': 4008,
    'Chichondi Patil': 4008,
    'Chikhli (Buldhana)': 4008,
    'Chikhli (Jalna)': 4008,
    'Chimur': 4008,
    'Chinchani': 4008,
    'Chinchpokli': 4008,
    'Chiplun': 4008,
    'Chopda': 4008,
    'Colaba': 4008,
    'Dabhol': 4008,
    'Daddi': 4008,
    'Dahanu': 4008,
    'Dahivel': 4008,
    'Dapoli': 4008,
    'Darwha': 4008,
    'Daryapur': 4008,
    'Dattapur': 4008,
    'Daulatabad': 4008,
    'Daund': 4008,
    'Deccan Gymkhana': 4008,
    'Deglur': 4008,
    'Dehu': 4008,
    'Deolali': 4008,
    'Deolapar': 4008,
    'Deoli': 4008,
    'Deoni': 4008,
    'Deulgaon Raja': 4008,
    'Devrukh': 4008,
    'Dharangaon': 4008,
    'Dharavi': 4008,
    'Dharmabad': 4008,
    'Dharur': 4008,
    'Dhawalpuri': 4008,
    'Dhule': 4008,
    'Dhulia': 4008,
    'Dighori': 4008,
    'Diglur': 4008,
    'Digras': 4008,
    'Dindori, Maharashtra': 4008,
    'Diveagar': 4008,
    'Dombivli': 4008,
    'Dondaicha': 4008,
    'Dongri': 4008,
    'Dudhani': 4008,
    'Durgapur': 4008,
    'Durgapur, Chandrapur': 4008,
    'Erandol': 4008,
    'Faizpur': 4008,
    'Fort': 4008,
    'Gadchiroli': 4008,
    'Gadhinglaj': 4008,
    'Gangakher': 4008,
    'Gangapur': 4008,
    'Ganpatipule': 4008,
    'Gevrai': 4008,
    'Ghargaon': 4008,
    'Ghatanji': 4008,
    'Ghatkopar': 4008,
    'Ghoti Budrukh': 4008,
    'Ghugus': 4008,
    'Girgaon': 4008,
    'Gondia': 4008,
    'Gondiya': 4008,
    'Gorai': 4008,
    'Goregaon': 4008,
    'Guhagar': 4008,
    'Hadapsar, Pune': 4008,
    'Hadgaon': 4008,
    'Halkarni': 4008,
    'Harangul': 4008,
    'Harnai': 4008,
    'Helwak': 4008,
    'Hinganghat': 4008,
    'Hingoli': 4008,
    'Hirapur': 4008,
    'Hirapur Hamesha': 4008,
    'Hotgi': 4008,
    'Ichalkaranji': 4008,
    'Igatpuri': 4008,
    'Indapur': 4008,
    'Jaisingpur': 4008,
    'Jaitapur': 4008,
    'Jakhangaon': 4008,
    'Jalgaon': 4008,
    'Jalgaon Jamod': 4008,
    'Jalkot': 4008,
    'Jalna': 4008,
    'Jamkhed': 4008,
    'Jamod': 4008,
    'Janephal': 4008,
    'Jaoli': 4008,
    'Jat, Sangli': 4008,
    'Jategaon': 4008,
    'Jawhar': 4008,
    'Jaysingpur': 4008,
    'Jejuri': 4008,
    'Jintur': 4008,
    'Jogeshwari': 4008,
    'Juhu': 4008,
    'Junnar': 4008,
    'Kachurwahi': 4008,
    'Kadegaon': 4008,
    'Kadus': 4008,
    'Kagal': 4008,
    'Kaij': 4008,
    'Kalamb': 4008,
    'Kalamb, Osmanabad': 4008,
    'Kalamboli': 4008,
    'Kalamnuri': 4008,
    'Kalas': 4008,
    'Kali(DK)': 4008,
    'Kalmeshwar': 4008,
    'Kalundri': 4008,
    'Kalyan': 4008,
    'Kalyani Nagar': 4008,
    'Kamargaon': 4008,
    'Kamatgi': 4008,
    'Kamptee': 4008,
    'Kamthi': 4008,
    'Kandri': 4008,
    'Kankauli': 4008,
    'Kankavli': 4008,
    'Kannad': 4008,
    'Karad': 4008,
    'Karajagi': 4008,
    'Karanja': 4008,
    'Karanja Lad': 4008,
    'Karjat': 4008,
    'Karkamb': 4008,
    'Karmala': 4008,
    'Kasara': 4008,
    'Kasoda': 4008,
    'Kati': 4008,
    'Katol': 4008,
    'Katral': 4008,
    'Khadki': 4008,
    'Khalapur': 4008,
    'Khallar': 4008,
    'Khamgaon': 4008,
    'Khanapur': 4008,
    'Khandala': 4008,
    'Khangaon': 4008,
    'Khapa': 4008,
    'Kharakvasla': 4008,
    'Kharda': 4008,
    'Kharghar': 4008,
    'Kharsundi': 4008,
    'Khed': 4008,
    'Khede': 4008,
    'Khetia': 4008,
    'Khoni': 4008,
    'Khopoli': 4008,
    'Khuldabad': 4008,
    'Kinwat': 4008,
    'Kodoli': 4008,
    'Kolhapur': 4008,
    'Kondalwadi': 4008,
    'Kondhali': 4008,
    'Kopar Khairane': 4008,
    'Kopargaon': 4008,
    'Kopela': 4008,
    'Koradi': 4008,
    'Koregaon': 4008,
    'Koynanagar': 4008,
    'Kudal': 4008,
    'Kuhi': 4008,
    'Kurandvad': 4008,
    'Kurankhed': 4008,
    'Kurduvadi': 4008,
    'Kusumba': 4008,
    'Lakhandur': 4008,
    'Lanja': 4008,
    'Lasalgaon': 4008,
    'Latur': 4008,
    'Lavasa': 4008,
    'Lohogaon': 4008,
    'Lonar': 4008,
    'Lonavla': 4008,
    'Mahabaleshwar': 4008,
    'Mahad': 4008,
    'Mahape': 4008,
    'Mahim': 4008,
    'Maindargi': 4008,
    'Majalgaon': 4008,
    'Makhjan': 4008,
    'Malabar Hill': 4008,
    'Malad': 4008,
    'Malegaon': 4008,
    'Malkapur': 4008,
    'Malvan': 4008,
    'Manchar': 4008,
    'Mandangad': 4008,
    'Mandhal': 4008,
    'Mandwa': 4008,
    'Mangaon': 4008,
    'Mangrul Pir': 4008,
    'Manjlegaon': 4008,
    'Mankeshwar': 4008,
    'Mankhurd': 4008,
    'Manmad': 4008,
    'Manor': 4008,
    'Mansar': 4008,
    'Manwat': 4008,
    'Manwath': 4008,
    'Maregaon': 4008,
    'Mastiholi': 4008,
    'Masur, India': 4008,
    'Matheran': 4008,
    'Matunga': 4008,
    'Mazagaon': 4008,
    'Mehekar': 4008,
    'Mehergaon': 4008,
    'Mehkar': 4008,
    'Mhasla': 4008,
    'Mhasvad': 4008,
    'Miraj': 4008,
    'Mohadi': 4008,
    'Mohol': 4008,
    'Mohpa': 4008,
    'Mokhada taluka': 4008,
    'Mora, Maharashtra': 4008,
    'Moram': 4008,
    'Morsi': 4008,
    'Mowad': 4008,
    'Mudkhed': 4008,
    'Mukher': 4008,
    'Mul': 4008,
    'Mulher': 4008,
    'Mulund': 4008,
    'Mumbai': 4008,
    'Mumbai Suburban': 4008,
    'Murbad': 4008,
    'Murgud': 4008,
    'Murtajapur': 4008,
    'Murud (Raigad)': 4008,
    'Murud (Ratnagiri)': 4008,
    'Murum': 4008,
    'Nadgaon': 4008,
    'Nagapur': 4008,
    'Nagothana': 4008,
    'Nagpur': 4008,
    'Nagpur Division': 4008,
    'Nala Sopara': 4008,
    'Naldurg': 4008,
    'Nalegaon': 4008,
    'Nampur': 4008,
    'Nanded': 4008,
    'Nandgaon': 4008,
    'Nandnee': 4008,
    'Nandura': 4008,
    'Nandura Buzurg': 4008,
    'Nandurbar': 4008,
    'Narayangaon': 4008,
    'Nardana': 4008,
    'Nariman Point': 4008,
    'Narkhed': 4008,
    'Nashik': 4008,
    'Nashik Division': 4008,
    'Navapur': 4008,
    'Navi Mumbai': 4008,
    'Neral': 4008,
    'Nerur': 4008,
    'Nevasa': 4008,
    'Nighoj': 4008,
    'Nilanga': 4008,
    'Nipani': 4008,
    'Niphad': 4008,
    'Nira Narsingpur': 4008,
    'Osmanabad': 4008,
    'Ozar': 4008,
    'Pabal': 4008,
    'Pachora': 4008,
    'Pahur, Maharashtra': 4008,
    'Paithan': 4008,
    'Palghar': 4008,
    'Pali, Raigad': 4008,
    'Palso': 4008,
    'Panchgani': 4008,
    'Pandharpur': 4008,
    'Pandhurli': 4008,
    'Panhala': 4008,
    'Panvel': 4008,
    'Parbhani': 4008,
    'Parel': 4008,
    'Parli Vaijnath': 4008,
    'Parner': 4008,
    'Parola': 4008,
    'Parseoni': 4008,
    'Partur': 4008,
    'Patan': 4008,
    'Pathardi': 4008,
    'Pathri': 4008,
    'Patur': 4008,
    'Paturda': 4008,
    'Paud': 4008,
    'Pauni': 4008,
    'Pawni': 4008,
    'Pedgaon': 4008,
    'Peint': 4008,
    'Pen': 4008,
    'Phaltan': 4008,
    'Phulambri': 4008,
    'Piliv': 4008,
    'Pimpalgaon Baswant': 4008,
    'Pimpalgaon Raja': 4008,
    'Pimpri': 4008,
    'Pimpri-Chinchwad': 4008,
    'Pipri': 4008,
    'Powai': 4008,
    'Prabhadevi': 4008,
    'Prakasha': 4008,
    'Pulgaon': 4008,
    'Pune': 4008,
    'Pune Division': 4008,
    'Puntamba': 4008,
    'Pural': 4008,
    'Purna': 4008,
    'Pusad': 4008,
    'Radhanagari': 4008,
    'Rahata': 4008,
    'Rahimatpur': 4008,
    'Rahuri': 4008,
    'Raigarh': 4008,
    'Raireshwar': 4008,
    'Rajapur': 4008,
    'Rajgurunagar': 4008,
    'Rajur': 4008,
    'Rajura': 4008,
    'Ralegaon': 4008,
    'Ramewadi': 4008,
    'Ramtek': 4008,
    'Ratnagiri': 4008,
    'Raver': 4008,
    'Renapur': 4008,
    'Renavi': 4008,
    'Revadanda': 4008,
    'Revdanda': 4008,
    'Risod': 4008,
    'Roha': 4008,
    'Sailu': 4008,
    'Sakol': 4008,
    'Sakoli': 4008,
    'Sakri': 4008,
    'Samudrapur': 4008,
    'Sangameshwar': 4008,
    'Sangamner': 4008,
    'Sangli': 4008,
    'Sangola': 4008,
    'Sangole': 4008,
    'Sangrampur, Maharashtra': 4008,
    'Saoli': 4008,
    'Saoner': 4008,
    'Sarangkheda': 4008,
    'Sasvad': 4008,
    'Saswad': 4008,
    'Satana': 4008,
    'Satara': 4008,
    'Satara Division': 4008,
    'Satpati': 4008,
    'Savantvadi': 4008,
    'Savda': 4008,
    'Savlaj': 4008,
    'Sawantvadi': 4008,
    'Selu': 4008,
    'Sevagram': 4008,
    'Sewri': 4008,
    'Shahada': 4008,
    'Shahapur': 4008,
    'Shedbal': 4008,
    'Shegaon': 4008,
    'Shevgaon': 4008,
    'Shikrapur': 4008,
    'Shiraguppi': 4008,
    'Shirala': 4008,
    'Shirdi': 4008,
    'Shirgaon': 4008,
    'Shirol': 4008,
    'Shirpur': 4008,
    'Shirud': 4008,
    'Shirwal': 4008,
    'Shivaji Nagar': 4008,
    'Shrigonda': 4008,
    'Sillod': 4008,
    'Sindewahi': 4008,
    'Sindhudurg': 4008,
    'Sindi': 4008,
    'Sindkheda': 4008,
    'Sinnar': 4008,
    'Sion, Mumbai': 4008,
    'Sironcha': 4008,
    'Sirur': 4008,
    'Sivala, East Godavari district': 4008,
    'Solapur': 4008,
    'Sonala': 4008,
    'Sonegaon': 4008,
    'Songir': 4008,
    'Sonvad': 4008,
    'Soygaon': 4008,
    'Srivardhan': 4008,
    'Surgana': 4008,
    'Taklibhan': 4008,
    'Talbid': 4008,
    'Talegaon Dabhade': 4008,
    'Talegaon Dhamdhere': 4008,
    'Taloda': 4008,
    'Talode': 4008,
    'Tarapur': 4008,
    'Tardeo': 4008,
    'Tasgaon': 4008,
    'Telhara': 4008,
    'Thalner': 4008,
    'Thane': 4008,
    'Trimbak': 4008,
    'Trombay': 4008,
    'Tuljapur': 4008,
    'Tumsar': 4008,
    'Udgir': 4008,
    'Ulhasnagar': 4008,
    'Umarga': 4008,
    'Umarkhed': 4008,
    'Umred': 4008,
    'Uran': 4008,
    'Uruli Kanchan': 4008,
    'Vada': 4008,
    'Vadgaon': 4008,
    'Vadner': 4008,
    'Vaijapur': 4008,
    'Vairag': 4008,
    'Valsang': 4008,
    'Vangaon': 4008,
    'Varangaon': 4008,
    'Vashi': 4008,
    'Vasind': 4008,
    'Vatul': 4008,
    'Velas, Maharashtra': 4008,
    'Velneshwar': 4008,
    'Vengurla': 4008,
    'Vijaydurg': 4008,
    'Vikhroli': 4008,
    'Vile Parle': 4008,
    'Vinchur': 4008,
    'Virar': 4008,
    'Vita, Maharashtra': 4008,
    'Vite': 4008,
    'Wadala': 4008,
    'Wadgaon': 4008,
    'Wadner': 4008,
    'Wadwani': 4008,
    'Wagholi': 4008,
    'Wai': 4008,
    'Wakad': 4008,
    'Walgaon': 4008,
    'Walki': 4008,
    'Wani': 4008,
    'Wardha': 4008,
    'Warora': 4008,
    'Warud': 4008,
    'Washim': 4008,
    'Worli': 4008,
    'Yaval': 4008,
    'Yavatmal': 4008,
    'Yeola': 4008,
    'Bishnupur': 4010,
    'Churachandpur': 4010,
    'Imphal': 4010,
    'Kakching': 4010,
    'Mayang Imphal': 4010,
    'Moirang': 4010,
    'Phek': 4010,
    'Senapati': 4010,
    'Tamenglong': 4010,
    'Thoubal': 4010,
    'Ukhrul': 4010,
    'Wangjing': 4010,
    'Yairipok': 4010,
    'Cherrapunji': 4006,
    'East Garo Hills': 4006,
    'East Jaintia Hills': 4006,
    'East Khasi Hills': 4006,
    'Mairang': 4006,
    'Mankachar': 4006,
    'Nongpoh': 4006,
    'Nongstoin': 4006,
    'North Garo Hills': 4006,
    'Ri-Bhoi': 4006,
    'Shillong': 4006,
    'South Garo Hills': 4006,
    'South West Garo Hills': 4006,
    'South West Khasi Hills': 4006,
    'Tura': 4006,
    'West Garo Hills': 4006,
    'West Jaintia Hills': 4006,
    'West Khasi Hills': 4006,
    'Aizawl': 4036,
    'Champhai': 4036,
    'Darlawn': 4036,
    'Khawhai': 4036,
    'Kolasib': 4036,
    'Kolasib district': 4036,
    'Lawngtlai': 4036,
    'Lunglei': 4036,
    'Mamit': 4036,
    'North Vanlaiphai': 4036,
    'Saiha': 4036,
    'Sairang': 4036,
    'Saitlaw': 4036,
    'Serchhip': 4036,
    'Thenzawl': 4036,
    'Dimapur': 4018,
    'Kohima': 4018,
    'Mokokchung': 4018,
    'Mon': 4018,
    'Peren': 4018,
    'Phek': 4018,
    'Tuensang': 4018,
    'Tuensang District': 4018,
    'Wokha': 4018,
    'Zunheboto': 4018,
    'Angul': 4013,
    'Angul District': 4013,
    'Asika': 4013,
    'Athagarh': 4013,
    'Bada Barabil': 4013,
    'Balangir': 4013,
    'Balasore': 4013,
    'Baleshwar': 4013,
    'Balimila': 4013,
    'Balugaon': 4013,
    'Banapur': 4013,
    'Banki': 4013,
    'Banposh': 4013,
    'Baragarh': 4013,
    'Barbil': 4013,
    'Bargarh': 4013,
    'Barpali': 4013,
    'Basudebpur': 4013,
    'Baud': 4013,
    'Baudh': 4013,
    'Belaguntha': 4013,
    'Bhadrak': 4013,
    'Bhadrakh': 4013,
    'Bhanjanagar': 4013,
    'Bhawanipatna': 4013,
    'Bhuban': 4013,
    'Bhubaneshwar': 4013,
    'Binka': 4013,
    'Birmitrapur': 4013,
    'Bolanikhodan': 4013,
    'Brahmapur': 4013,
    'Brajarajnagar': 4013,
    'Buguda': 4013,
    'Burla': 4013,
    'Champua': 4013,
    'Chandbali': 4013,
    'Chatrapur': 4013,
    'Chikitigarh': 4013,
    'Chittarkonda': 4013,
    'Cuttack': 4013,
    'Daitari': 4013,
    'Deogarh': 4013,
    'Dhenkanal': 4013,
    'Digapahandi': 4013,
    'Gajapati': 4013,
    'Ganjam': 4013,
    'Gopalpur': 4013,
    'Gudari': 4013,
    'Gunupur': 4013,
    'Hinjilikatu': 4013,
    'Hirakud': 4013,
    'Jagatsinghapur': 4013,
    'Jagatsinghpur': 4013,
    'Jajpur': 4013,
    'Jaleshwar': 4013,
    'Jatani': 4013,
    'Jeypore': 4013,
    'Jharsuguda': 4013,
    'Jharsuguda District': 4013,
    'Kaintragarh': 4013,
    'Kalahandi': 4013,
    'Kamakhyanagar': 4013,
    'Kandhamal': 4013,
    'Kantabanji': 4013,
    'Kantilo': 4013,
    'Kendrapara': 4013,
    'Kendraparha': 4013,
    'Kendujhar': 4013,
    'Kesinga': 4013,
    'Khallikot': 4013,
    'Kharhial': 4013,
    'Khordha': 4013,
    'Khurda': 4013,
    'Kiri Buru': 4013,
    'Kodala': 4013,
    'Konarka': 4013,
    'Koraput': 4013,
    'Kuchaiburi': 4013,
    'Kuchinda': 4013,
    'Malakanagiri': 4013,
    'Malkangiri': 4013,
    'Mayurbhanj': 4013,
    'Nabarangpur': 4013,
    'Nayagarh': 4013,
    'Nayagarh District': 4013,
    'Nilgiri': 4013,
    'Nimaparha': 4013,
    'Nowrangapur': 4013,
    'Nuapada': 4013,
    'Padampur': 4013,
    'Paradip Garh': 4013,
    'Patamundai': 4013,
    'Patnagarh': 4013,
    'Phulbani': 4013,
    'Pipili': 4013,
    'Polasara': 4013,
    'Puri': 4013,
    'Purushottampur': 4013,
    'Rambha': 4013,
    'Raurkela': 4013,
    'Rayagada': 4013,
    'Remuna': 4013,
    'Rengali': 4013,
    'Sambalpur': 4013,
    'Sonepur': 4013,
    'Sorada': 4013,
    'Soro': 4013,
    'Subarnapur': 4013,
    'Sundargarh': 4013,
    'Talcher': 4013,
    'Tarabha': 4013,
    'Titlagarh': 4013,
    'Udayagiri': 4013,
    'Karaikal': 4011,
    'Mahe': 4011,
    'Puducherry': 4011,
    'Yanam': 4011,
    'Abohar': 4015,
    'Adampur': 4015,
    'Ajitgarh': 4015,
    'Ajnala': 4015,
    'Akalgarh': 4015,
    'Alawalpur': 4015,
    'Amloh': 4015,
    'Amritsar': 4015,
    'Anandpur Sahib': 4015,
    'Badhni Kalan': 4015,
    'Bagha Purana': 4015,
    'Bakloh': 4015,
    'Balachor': 4015,
    'Banga': 4015,
    'Banur': 4015,
    'Barnala': 4015,
    'Batala': 4015,
    'Begowal': 4015,
    'Bhadaur': 4015,
    'Bhatinda': 4015,
    'Bhawanigarh': 4015,
    'Bhikhi': 4015,
    'Bhogpur': 4015,
    'Bholath': 4015,
    'Budhlada': 4015,
    'Chima': 4015,
    'Dasuya': 4015,
    'Dera Baba Nanak': 4015,
    'Dera Bassi': 4015,
    'Dhanaula': 4015,
    'Dhariwal': 4015,
    'Dhilwan': 4015,
    'Dhudi': 4015,
    'Dhuri': 4015,
    'Dina Nagar': 4015,
    'Dirba': 4015,
    'Doraha': 4015,
    'Faridkot': 4015,
    'Fatehgarh Churian': 4015,
    'Fatehgarh Sahib': 4015,
    'Fazilka': 4015,
    'Firozpur': 4015,
    'Firozpur District': 4015,
    'Gardhiwala': 4015,
    'Garhshankar': 4015,
    'Ghanaur': 4015,
    'Giddarbaha': 4015,
    'Gurdaspur': 4015,
    'Guru Har Sahai': 4015,
    'Hajipur': 4015,
    'Hariana': 4015,
    'Hoshiarpur': 4015,
    'Ishanpur': 4015,
    'Jagraon': 4015,
    'Jaito': 4015,
    'Jalalabad': 4015,
    'Jalandhar': 4015,
    'Jandiala': 4015,
    'Jandiala Guru': 4015,
    'Kalanaur': 4015,
    'Kapurthala': 4015,
    'Kartarpur': 4015,
    'Khamanon': 4015,
    'Khanna': 4015,
    'Kharar': 4015,
    'Khemkaran': 4015,
    'Kot Isa Khan': 4015,
    'Kotkapura': 4015,
    'Laungowal': 4015,
    'Ludhiana': 4015,
    'Machhiwara': 4015,
    'Majitha': 4015,
    'Makhu': 4015,
    'Malaut': 4015,
    'Malerkotla': 4015,
    'Mansa': 4015,
    'Maur Mandi': 4015,
    'Moga': 4015,
    'Mohali': 4015,
    'Morinda': 4015,
    'Mukerian': 4015,
    'Nabha': 4015,
    'Nakodar': 4015,
    'Nangal': 4015,
    'Nawanshahr': 4015,
    'Nurmahal': 4015,
    'Nurpur Kalan': 4015,
    'Pathankot': 4015,
    'Patiala': 4015,
    'Patti': 4015,
    'Phagwara': 4015,
    'Phillaur': 4015,
    'Qadian': 4015,
    'Rahon': 4015,
    'Raikot': 4015,
    'Rajasansi': 4015,
    'Rajpura': 4015,
    'Ram Das': 4015,
    'Rampura': 4015,
    'Rupnagar': 4015,
    'Samrala': 4015,
    'Sanaur': 4015,
    'Sangrur': 4015,
    'Sardulgarh': 4015,
    'Shahid Bhagat Singh Nagar': 4015,
    'Shahkot': 4015,
    'Sham Churasi': 4015,
    'Sirhind-Fategarh': 4015,
    'Sri Muktsar Sahib': 4015,
    'Sultanpur Lodhi': 4015,
    'Sunam': 4015,
    'Talwandi Bhai': 4015,
    'Talwara': 4015,
    'Tarn Taran Sahib': 4015,
    'Zira': 4015,
    'Abhaneri': 4014,
    'Abu': 4014,
    'Abu Road': 4014,
    'Ajmer': 4014,
    'Aklera': 4014,
    'Alwar': 4014,
    'Amet': 4014,
    'Anta': 4014,
    'Anupgarh': 4014,
    'Asind': 4014,
    'Bagar': 4014,
    'Bakani': 4014,
    'Bali': 4014,
    'Balotra': 4014,
    'Bandikui': 4014,
    'Banswara': 4014,
    'Baran': 4014,
    'Bari': 4014,
    'Bari Sadri': 4014,
    'Barmer': 4014,
    'Basi': 4014,
    'Basni': 4014,
    'Baswa': 4014,
    'Bayana': 4014,
    'Beawar': 4014,
    'Begun': 4014,
    'Behror': 4014,
    'Bhadasar': 4014,
    'Bhadra': 4014,
    'Bharatpur': 4014,
    'Bhasawar': 4014,
    'Bhilwara': 4014,
    'Bhindar': 4014,
    'Bhinmal': 4014,
    'Bhiwadi': 4014,
    'Bhuma': 4014,
    'Bikaner': 4014,
    'Bilara': 4014,
    'Bissau': 4014,
    'Borkhera': 4014,
    'Bundi': 4014,
    'Chaksu': 4014,
    'Chechat': 4014,
    'Chhabra': 4014,
    'Chhapar': 4014,
    'Chhoti Sadri': 4014,
    'Chidawa': 4014,
    'Chittaurgarh': 4014,
    'Churu': 4014,
    'Dariba': 4014,
    'Dausa': 4014,
    'Deoli': 4014,
    'Deshnoke': 4014,
    'Devgarh': 4014,
    'Dhaulpur': 4014,
    'Didwana': 4014,
    'Dig': 4014,
    'Dungarpur': 4014,
    'Fatehpur': 4014,
    'Galiakot': 4014,
    'Ganganagar': 4014,
    'Gangapur': 4014,
    'Govindgarh': 4014,
    'Gulabpura': 4014,
    'Hanumangarh': 4014,
    'Hindaun': 4014,
    'Jahazpur': 4014,
    'Jaipur': 4014,
    'Jaisalmer': 4014,
    'Jaitaran': 4014,
    'Jalor': 4014,
    'Jalore': 4014,
    'Jhalawar': 4014,
    'Jhalrapatan': 4014,
    'Jhunjhunun': 4014,
    'Jobner': 4014,
    'Jodhpur': 4014,
    'Kaman': 4014,
    'Kanor': 4014,
    'Kapren': 4014,
    'Karanpur': 4014,
    'Karauli': 4014,
    'Kekri': 4014,
    'Keshorai Patan': 4014,
    'Khandela': 4014,
    'Khanpur': 4014,
    'Khetri': 4014,
    'Kishangarh': 4014,
    'Kota': 4014,
    'Kotputli': 4014,
    'Kuchaman': 4014,
    'Kuchera': 4014,
    'Kumher': 4014,
    'Kushalgarh': 4014,
    'Lachhmangarh Sikar': 4014,
    'Ladnun': 4014,
    'Lakheri': 4014,
    'Lalsot': 4014,
    'Losal': 4014,
    'Mahwah': 4014,
    'Makrana': 4014,
    'Malpura': 4014,
    'Mandal': 4014,
    'Mandalgarh': 4014,
    'Mandawar': 4014,
    'Mangrol': 4014,
    'Manohar Thana': 4014,
    'Manoharpur': 4014,
    'Meethari Marwar': 4014,
    'Merta': 4014,
    'Mundwa': 4014,
    'Nadbai': 4014,
    'Nagar': 4014,
    'Nagaur': 4014,
    'Nainwa': 4014,
    'Napasar': 4014,
    'Naraina': 4014,
    'Nasirabad': 4014,
    'Nathdwara': 4014,
    'Nawa': 4014,
    'Nawalgarh': 4014,
    'Neem ka Thana': 4014,
    'Nimaj': 4014,
    'Nimbahera': 4014,
    'Niwai': 4014,
    'Nohar': 4014,
    'Nokha': 4014,
    'Padampur': 4014,
    'Pali': 4014,
    'Partapur': 4014,
    'Parvatsar': 4014,
    'Phalodi': 4014,
    'Phulera': 4014,
    'Pilani': 4014,
    'Pilibangan': 4014,
    'Pindwara': 4014,
    'Pipar': 4014,
    'Pirawa': 4014,
    'Pokaran': 4014,
    'Pratapgarh': 4014,
    'Pushkar': 4014,
    'Raipur': 4014,
    'Raisinghnagar': 4014,
    'Rajakhera': 4014,
    'Rajaldesar': 4014,
    'Rajgarh': 4014,
    'Rajsamand': 4014,
    'Ramganj Mandi': 4014,
    'Ramgarh': 4014,
    'Rani': 4014,
    'Ratangarh': 4014,
    'Rawatbhata': 4014,
    'Rawatsar': 4014,
    'Ringas': 4014,
    'Sadri': 4014,
    'Salumbar': 4014,
    'Sambhar': 4014,
    'Samdari': 4014,
    'Sanchor': 4014,
    'Sangaria': 4014,
    'Sangod': 4014,
    'Sardarshahr': 4014,
    'Sarwar': 4014,
    'Sawai Madhopur': 4014,
    'Shahpura': 4014,
    'Sheoganj': 4014,
    'Sikar': 4014,
    'Sirohi': 4014,
    'Siwana': 4014,
    'Sojat': 4014,
    'Sri Dungargarh': 4014,
    'Sri Madhopur': 4014,
    'Sujangarh': 4014,
    'Suket': 4014,
    'Sunel': 4014,
    'Surajgarh': 4014,
    'Suratgarh': 4014,
    'Takhatgarh': 4014,
    'Taranagar': 4014,
    'Tijara': 4014,
    'Todabhim': 4014,
    'Todaraisingh': 4014,
    'Tonk': 4014,
    'Udaipur': 4014,
    'Udpura': 4014,
    'Uniara': 4014,
    'Wer': 4014,
    'East District': 4034,
    'Gangtok': 4034,
    'Gyalshing': 4034,
    'Jorethang': 4034,
    'Mangan': 4034,
    'Namchi': 4034,
    'Naya Bazar': 4034,
    'North District': 4034,
    'Rangpo': 4034,
    'Singtam': 4034,
    'South District': 4034,
    'West District': 4034,
    'Abiramam': 4035,
    'Adirampattinam': 4035,
    'Aduthurai': 4035,
    'Alagapuram': 4035,
    'Alandur': 4035,
    'Alanganallur': 4035,
    'Alangayam': 4035,
    'Alangudi': 4035,
    'Alangulam': 4035,
    'Alappakkam': 4035,
    'Alwa Tirunagari': 4035,
    'Ambasamudram': 4035,
    'Ambattur': 4035,
    'Ambur': 4035,
    'Ammapettai': 4035,
    'Anamalais': 4035,
    'Andippatti': 4035,
    'Annamalainagar': 4035,
    'Annavasal': 4035,
    'Annur': 4035,
    'Anthiyur': 4035,
    'Arakkonam': 4035,
    'Arantangi': 4035,
    'Arcot': 4035,
    'Arimalam': 4035,
    'Ariyalur': 4035,
    'Arni': 4035,
    'Arumbavur': 4035,
    'Arumuganeri': 4035,
    'Aruppukkottai': 4035,
    'Aruvankad': 4035,
    'Attayyampatti': 4035,
    'Attur': 4035,
    'Auroville': 4035,
    'Avadi': 4035,
    'Avinashi': 4035,
    'Ayakudi': 4035,
    'Ayyampettai': 4035,
    'Belur': 4035,
    'Bhavani': 4035,
    'Bodinayakkanur': 4035,
    'Chengam': 4035,
    'Chennai': 4035,
    'Chennimalai': 4035,
    'Chetput': 4035,
    'Chettipalaiyam': 4035,
    'Cheyyar': 4035,
    'Cheyyur': 4035,
    'Chidambaram': 4035,
    'Chingleput': 4035,
    'Chinna Salem': 4035,
    'Chinnamanur': 4035,
    'Chinnasekkadu': 4035,
    'Cholapuram': 4035,
    'Coimbatore': 4035,
    'Colachel': 4035,
    'Cuddalore': 4035,
    'Cumbum': 4035,
    'Denkanikota': 4035,
    'Desur': 4035,
    'Devadanappatti': 4035,
    'Devakottai': 4035,
    'Dhali': 4035,
    'Dharapuram': 4035,
    'Dharmapuri': 4035,
    'Dindigul': 4035,
    'Dusi': 4035,
    'Elayirampannai': 4035,
    'Elumalai': 4035,
    'Eral': 4035,
    'Eraniel': 4035,
    'Erode': 4035,
    'Erumaippatti': 4035,
    'Ettaiyapuram': 4035,
    'Gandhi Nagar': 4035,
    'Gangaikondan': 4035,
    'Gangavalli': 4035,
    'Gingee': 4035,
    'Gobichettipalayam': 4035,
    'Gudalur': 4035,
    'Gudiyatham': 4035,
    'Guduvancheri': 4035,
    'Gummidipundi': 4035,
    'Harur': 4035,
    'Hosur': 4035,
    'Idappadi': 4035,
    'Ilampillai': 4035,
    'Iluppur': 4035,
    'Injambakkam': 4035,
    'Irugur': 4035,
    'Jalakandapuram': 4035,
    'Jalarpet': 4035,
    'Jayamkondacholapuram': 4035,
    'Kadambur': 4035,
    'Kadayanallur': 4035,
    'Kalakkadu': 4035,
    'Kalavai': 4035,
    'Kallakkurichchi': 4035,
    'Kallidaikurichi': 4035,
    'Kallupatti': 4035,
    'Kalugumalai': 4035,
    'Kamuthi': 4035,
    'Kanadukattan': 4035,
    'Kancheepuram': 4035,
    'Kanchipuram': 4035,
    'Kangayam': 4035,
    'Kanniyakumari': 4035,
    'Karaikkudi': 4035,
    'Karamadai': 4035,
    'Karambakkudi': 4035,
    'Kariapatti': 4035,
    'Karumbakkam': 4035,
    'Karur': 4035,
    'Katpadi': 4035,
    'Kattivakkam': 4035,
    'Kattupputtur': 4035,
    'Kaveripatnam': 4035,
    'Kayalpattinam': 4035,
    'Kayattar': 4035,
    'Keelakarai': 4035,
    'Kelamangalam': 4035,
    'Kil Bhuvanagiri': 4035,
    'Kilvelur': 4035,
    'Kiranur': 4035,
    'Kodaikanal': 4035,
    'Kodumudi': 4035,
    'Kombai': 4035,
    'Konganapuram': 4035,
    'Koothanallur': 4035,
    'Koradachcheri': 4035,
    'Korampallam': 4035,
    'Kotagiri': 4035,
    'Kottaiyur': 4035,
    'Kovilpatti': 4035,
    'Krishnagiri': 4035,
    'Kulattur': 4035,
    'Kulittalai': 4035,
    'Kumaralingam': 4035,
    'Kumbakonam': 4035,
    'Kunnattur': 4035,
    'Kurinjippadi': 4035,
    'Kuttalam': 4035,
    'Kuzhithurai': 4035,
    'Lalgudi': 4035,
    'Madambakkam': 4035,
    'Madipakkam': 4035,
    'Madukkarai': 4035,
    'Madukkur': 4035,
    'Madurai': 4035,
    'Madurantakam': 4035,
    'Mallapuram': 4035,
    'Mallasamudram': 4035,
    'Mallur': 4035,
    'Manali': 4035,
    'Manalurpettai': 4035,
    'Manamadurai': 4035,
    'Manappakkam': 4035,
    'Manapparai': 4035,
    'Manavalakurichi': 4035,
    'Mandapam': 4035,
    'Mangalam': 4035,
    'Mannargudi': 4035,
    'Marakkanam': 4035,
    'Marandahalli': 4035,
    'Masinigudi': 4035,
    'Mattur': 4035,
    'Mayiladuthurai': 4035,
    'Melur': 4035,
    'Mettuppalaiyam': 4035,
    'Mettur': 4035,
    'Minjur': 4035,
    'Mohanur': 4035,
    'Mudukulattur': 4035,
    'Mulanur': 4035,
    'Musiri': 4035,
    'Muttupet': 4035,
    'Naduvattam': 4035,
    'Nagapattinam': 4035,
    'Nagercoil': 4035,
    'Namagiripettai': 4035,
    'Namakkal': 4035,
    'Nambiyur': 4035,
    'Nambutalai': 4035,
    'Nandambakkam': 4035,
    'Nangavalli': 4035,
    'Nangilickondan': 4035,
    'Nanguneri': 4035,
    'Nannilam': 4035,
    'Naravarikuppam': 4035,
    'Nattam': 4035,
    'Nattarasankottai': 4035,
    'Needamangalam': 4035,
    'Neelankarai': 4035,
    'Negapatam': 4035,
    'Nellikkuppam': 4035,
    'Nilakottai': 4035,
    'Nilgiris': 4035,
    'Odugattur': 4035,
    'Omalur': 4035,
    'Ooty': 4035,
    'Padmanabhapuram': 4035,
    'Palakkodu': 4035,
    'Palamedu': 4035,
    'Palani': 4035,
    'Palavakkam': 4035,
    'Palladam': 4035,
    'Pallappatti': 4035,
    'Pallattur': 4035,
    'Pallavaram': 4035,
    'Pallikondai': 4035,
    'Pallipattu': 4035,
    'Pallippatti': 4035,
    'Panruti': 4035,
    'Papanasam': 4035,
    'Papireddippatti': 4035,
    'Papparappatti': 4035,
    'Paramagudi': 4035,
    'Pattukkottai': 4035,
    'Pennadam': 4035,
    'Pennagaram': 4035,
    'Pennathur': 4035,
    'Peraiyur': 4035,
    'Perambalur': 4035,
    'Peranamallur': 4035,
    'Peranampattu': 4035,
    'Peravurani': 4035,
    'Periyakulam': 4035,
    'Periyanayakkanpalaiyam': 4035,
    'Periyanegamam': 4035,
    'Periyapatti': 4035,
    'Periyapattinam': 4035,
    'Perundurai': 4035,
    'Perungudi': 4035,
    'Perur': 4035,
    'Pollachi': 4035,
    'Polur': 4035,
    'Ponnamaravati': 4035,
    'Ponneri': 4035,
    'Poonamalle': 4035,
    'Porur': 4035,
    'Pudukkottai': 4035,
    'Puduppatti': 4035,
    'Pudur': 4035,
    'Puduvayal': 4035,
    'Puliyangudi': 4035,
    'Puliyur': 4035,
    'Pullambadi': 4035,
    'Punjai Puliyampatti': 4035,
    'Rajapalaiyam': 4035,
    'Ramanathapuram': 4035,
    'Rameswaram': 4035,
    'Rasipuram': 4035,
    'Saint Thomas Mount': 4035,
    'Salem': 4035,
    'Sathankulam': 4035,
    'Sathyamangalam': 4035,
    'Sattur': 4035,
    'Sayalkudi': 4035,
    'Seven Pagodas': 4035,
    'Sholinghur': 4035,
    'Singanallur': 4035,
    'Singapperumalkovil': 4035,
    'Sirkazhi': 4035,
    'Sirumugai': 4035,
    'Sivaganga': 4035,
    'Sivagiri': 4035,
    'Sivakasi': 4035,
    'Srimushnam': 4035,
    'Sriperumbudur': 4035,
    'Srivaikuntam': 4035,
    'Srivilliputhur': 4035,
    'Suchindram': 4035,
    'Sulur': 4035,
    'Surandai': 4035,
    'Swamimalai': 4035,
    'Tambaram': 4035,
    'Tanjore': 4035,
    'Taramangalam': 4035,
    'Tattayyangarpettai': 4035,
    'Thanjavur': 4035,
    'Tharangambadi': 4035,
    'Theni': 4035,
    'Thenkasi': 4035,
    'Thirukattupalli': 4035,
    'Thiruthani': 4035,
    'Thiruvaiyaru': 4035,
    'Thiruvallur': 4035,
    'Thiruvarur': 4035,
    'Thiruvidaimaruthur': 4035,
    'Thoothukudi': 4035,
    'Tindivanam': 4035,
    'Tinnanur': 4035,
    'Tiruchchendur': 4035,
    'Tiruchengode': 4035,
    'Tiruchirappalli': 4035,
    'Tirukkoyilur': 4035,
    'Tirumullaivasal': 4035,
    'Tirunelveli': 4035,
    'Tirunelveli Kattabo': 4035,
    'Tiruppalaikudi': 4035,
    'Tirupparangunram': 4035,
    'Tiruppur': 4035,
    'Tiruppuvanam': 4035,
    'Tiruttangal': 4035,
    'Tiruvannamalai': 4035,
    'Tiruvottiyur': 4035,
    'Tisaiyanvilai': 4035,
    'Tondi': 4035,
    'Turaiyur': 4035,
    'Udangudi': 4035,
    'Udumalaippettai': 4035,
    'Uppiliyapuram': 4035,
    'Usilampatti': 4035,
    'Uttamapalaiyam': 4035,
    'Uttiramerur': 4035,
    'Uttukkuli': 4035,
    'V.S.K.Valasai (Dindigul-Dist.)': 4035,
    'Vadakku Valliyur': 4035,
    'Vadakku Viravanallur': 4035,
    'Vadamadurai': 4035,
    'Vadippatti': 4035,
    'Valangaiman': 4035,
    'Valavanur': 4035,
    'Vallam': 4035,
    'Valparai': 4035,
    'Vandalur': 4035,
    'Vandavasi': 4035,
    'Vaniyambadi': 4035,
    'Vasudevanallur': 4035,
    'Vattalkundu': 4035,
    'Vedaraniyam': 4035,
    'Vedasandur': 4035,
    'Velankanni': 4035,
    'Vellanur': 4035,
    'Vellore': 4035,
    'Velur': 4035,
    'Vengavasal': 4035,
    'Vettaikkaranpudur': 4035,
    'Vettavalam': 4035,
    'Vijayapuri': 4035,
    'Vikravandi': 4035,
    'Vilattikulam': 4035,
    'Villupuram': 4035,
    'Viraganur': 4035,
    'Virudhunagar': 4035,
    'Vriddhachalam': 4035,
    'Walajapet': 4035,
    'Wallajahbad': 4035,
    'Wellington': 4035,
    'Adilabad': 4012,
    'Alampur': 4012,
    'Andol': 4012,
    'Asifabad': 4012,
    'Balapur': 4012,
    'Banswada': 4012,
    'Bellampalli': 4012,
    'Bhadrachalam': 4012,
    'Bhadradri Kothagudem': 4012,
    'Bhaisa': 4012,
    'Bhongir': 4012,
    'Bodhan': 4012,
    'Chandur': 4012,
    'Chatakonda': 4012,
    'Dasnapur': 4012,
    'Devarkonda': 4012,
    'Dornakal': 4012,
    'Farrukhnagar': 4012,
    'Gaddi Annaram': 4012,
    'Gadwal': 4012,
    'Ghatkesar': 4012,
    'Gopalur': 4012,
    'Gudur': 4012,
    'Hyderabad': 4012,
    'Jagtial': 4012,
    'Jangaon': 4012,
    'Jangoan': 4012,
    'Jayashankar Bhupalapally': 4012,
    'Jogulamba Gadwal': 4012,
    'Kagaznagar': 4012,
    'Kamareddi': 4012,
    'Kamareddy': 4012,
    'Karimnagar': 4012,
    'Khammam': 4012,
    'Kodar': 4012,
    'Koratla': 4012,
    'Kothapet': 4012,
    'Kottagudem': 4012,
    'Kottapalli': 4012,
    'Kukatpalli': 4012,
    'Kyathampalle': 4012,
    'Lakshettipet': 4012,
    'Lal Bahadur Nagar': 4012,
    'Mahabubabad': 4012,
    'Mahbubnagar': 4012,
    'Malkajgiri': 4012,
    'Mancheral': 4012,
    'Mandamarri': 4012,
    'Manthani': 4012,
    'Manuguru': 4012,
    'Medak': 4012,
    'Medchal': 4012,
    'Medchal Malkajgiri': 4012,
    'Mirialguda': 4012,
    'Nagar Karnul': 4012,
    'Nalgonda': 4012,
    'Narayanpet': 4012,
    'Narsingi': 4012,
    'Naspur': 4012,
    'Nirmal': 4012,
    'Nizamabad': 4012,
    'Paloncha': 4012,
    'Palwancha': 4012,
    'Patancheru': 4012,
    'Peddapalli': 4012,
    'Quthbullapur': 4012,
    'Rajanna Sircilla': 4012,
    'Ramagundam': 4012,
    'Ramgundam': 4012,
    'Rangareddi': 4012,
    'Sadaseopet': 4012,
    'Sangareddi': 4012,
    'Sathupalli': 4012,
    'Secunderabad': 4012,
    'Serilingampalle': 4012,
    'Siddipet': 4012,
    'Singapur': 4012,
    'Sirpur': 4012,
    'Sirsilla': 4012,
    'Sriramnagar': 4012,
    'Suriapet': 4012,
    'Tandur': 4012,
    'Uppal Kalan': 4012,
    'Vemalwada': 4012,
    'Vikarabad': 4012,
    'Wanparti': 4012,
    'Warangal': 4012,
    'Yellandu': 4012,
    'Zahirabad': 4012,
    'Agartala': 4038,
    'Amarpur': 4038,
    'Ambasa': 4038,
    'Barjala': 4038,
    'Belonia': 4038,
    'Dhalai': 4038,
    'Dharmanagar': 4038,
    'Gomati': 4038,
    'Kailashahar': 4038,
    'Kamalpur': 4038,
    'Khowai': 4038,
    'North Tripura': 4038,
    'Ranir Bazar': 4038,
    'Sabrum': 4038,
    'Sonamura': 4038,
    'South Tripura': 4038,
    'Udaipur': 4038,
    'Unakoti': 4038,
    'West Tripura': 4038,
    'Achhnera': 4022,
    'Afzalgarh': 4022,
    'Agra': 4022,
    'Ahraura': 4022,
    'Aidalpur': 4022,
    'Airwa': 4022,
    'Akbarpur': 4022,
    'Akola': 4022,
    'Aliganj': 4022,
    'Aligarh': 4022,
    'Allahabad': 4022,
    'Allahganj': 4022,
    'Amanpur': 4022,
    'Amauli': 4022,
    'Ambahta': 4022,
    'Ambedkar Nagar': 4022,
    'Amethi': 4022,
    'Amroha': 4022,
    'Anandnagar': 4022,
    'Antu': 4022,
    'Anupshahr': 4022,
    'Aonla': 4022,
    'Araul': 4022,
    'Asalatganj': 4022,
    'Atarra': 4022,
    'Atrauli': 4022,
    'Atraulia': 4022,
    'Auraiya': 4022,
    'Auras': 4022,
    'Ayodhya': 4022,
    'Azamgarh': 4022,
    'Azizpur': 4022,
    'Baberu': 4022,
    'Babina': 4022,
    'Babrala': 4022,
    'Babugarh': 4022,
    'Bachhraon': 4022,
    'Bachhrawan': 4022,
    'Baghpat': 4022,
    'Baghra': 4022,
    'Bah': 4022,
    'Baheri': 4022,
    'Bahjoi': 4022,
    'Bahraich': 4022,
    'Bahraigh': 4022,
    'Bahsuma': 4022,
    'Bahua': 4022,
    'Bajna': 4022,
    'Bakewar': 4022,
    'Baksar': 4022,
    'Balamau': 4022,
    'Baldeo': 4022,
    'Baldev': 4022,
    'Ballia': 4022,
    'Balrampur': 4022,
    'Banat': 4022,
    'Banbasa': 4022,
    'Banda': 4022,
    'Bangarmau': 4022,
    'Bansdih': 4022,
    'Bansgaon': 4022,
    'Bansi': 4022,
    'Banthra': 4022,
    'Bara Banki': 4022,
    'Baragaon': 4022,
    'Baraut': 4022,
    'Bareilly': 4022,
    'Barhalganj': 4022,
    'Barkhera': 4022,
    'Barkhera Kalan': 4022,
    'Barokhar': 4022,
    'Barsana': 4022,
    'Barwar (Lakhimpur Kheri)': 4022,
    'Basti': 4022,
    'Behat': 4022,
    'Bela': 4022,
    'Belthara': 4022,
    'Beniganj': 4022,
    'Beswan': 4022,
    'Bewar': 4022,
    'Bhadarsa': 4022,
    'Bhadohi': 4022,
    'Bhagwantnagar': 4022,
    'Bharatpura': 4022,
    'Bhargain': 4022,
    'Bharthana': 4022,
    'Bharwari': 4022,
    'Bhaupur': 4022,
    'Bhimtal': 4022,
    'Bhinga': 4022,
    'Bhognipur': 4022,
    'Bhongaon': 4022,
    'Bidhnu': 4022,
    'Bidhuna': 4022,
    'Bighapur': 4022,
    'Bighapur Khurd': 4022,
    'Bijnor': 4022,
    'Bikapur': 4022,
    'Bilari': 4022,
    'Bilariaganj': 4022,
    'Bilaspur': 4022,
    'Bilgram': 4022,
    'Bilhaur': 4022,
    'Bilsanda': 4022,
    'Bilsi': 4022,
    'Bilthra': 4022,
    'Binauli': 4022,
    'Binaur': 4022,
    'Bindki': 4022,
    'Birdpur': 4022,
    'Birpur': 4022,
    'Bisalpur': 4022,
    'Bisanda Buzurg': 4022,
    'Bisauli': 4022,
    'Bisenda Buzurg': 4022,
    'Bishunpur Urf Maharajganj': 4022,
    'Biswan': 4022,
    'Bithur': 4022,
    'Budaun': 4022,
    'Budhana': 4022,
    'Bulandshahr': 4022,
    'Captainganj': 4022,
    'Chail': 4022,
    'Chakia': 4022,
    'Chandauli': 4022,
    'Chandauli District': 4022,
    'Chandausi': 4022,
    'Chandpur': 4022,
    'Chanduasi': 4022,
    'Charkhari': 4022,
    'Charthawal': 4022,
    'Chhaprauli': 4022,
    'Chharra': 4022,
    'Chhata': 4022,
    'Chhibramau': 4022,
    'Chhitauni': 4022,
    'Chhutmalpur': 4022,
    'Chillupar': 4022,
    'Chirgaon': 4022,
    'Chitrakoot': 4022,
    'Chitrakoot Dham': 4022,
    'Chopan': 4022,
    'Chunar': 4022,
    'Churk': 4022,
    'Colonelganj': 4022,
    'Dadri': 4022,
    'Dalmau': 4022,
    'Dankaur': 4022,
    'Daraganj': 4022,
    'Daranagar': 4022,
    'Dasna': 4022,
    'Dataganj': 4022,
    'Daurala': 4022,
    'Dayal Bagh': 4022,
    'Deoband': 4022,
    'Deogarh': 4022,
    'Deoranian': 4022,
    'Deoria': 4022,
    'Derapur': 4022,
    'Dewa': 4022,
    'Dhampur': 4022,
    'Dhanaura': 4022,
    'Dhanghata': 4022,
    'Dharau': 4022,
    'Dhaurahra': 4022,
    'Dibai': 4022,
    'Divrasai': 4022,
    'Dohrighat': 4022,
    'Domariaganj': 4022,
    'Dostpur': 4022,
    'Dudhi': 4022,
    'Etah': 4022,
    'Etawah': 4022,
    'Etmadpur': 4022,
    'Faizabad': 4022,
    'Farah': 4022,
    'Faridnagar': 4022,
    'Faridpur': 4022,
    'Farrukhabad': 4022,
    'Fatehabad': 4022,
    'Fatehganj West': 4022,
    'Fatehgarh': 4022,
    'Fatehpur': 4022,
    'Fatehpur (Barabanki)': 4022,
    'Fatehpur Chaurasi': 4022,
    'Fatehpur Sikri': 4022,
    'Firozabad': 4022,
    'Fyzabad': 4022,
    'Gahlon': 4022,
    'Gahmar': 4022,
    'Gaini': 4022,
    'Gajraula': 4022,
    'Gangoh': 4022,
    'Ganj Dundawara': 4022,
    'Ganj Dundwara': 4022,
    'Ganj Muradabad': 4022,
    'Garautha': 4022,
    'Garhi Pukhta': 4022,
    'Garhmuktesar': 4022,
    'Garhwa': 4022,
    'Gauriganj': 4022,
    'Gautam Buddha Nagar': 4022,
    'Gawan': 4022,
    'Ghatampur': 4022,
    'Ghaziabad': 4022,
    'Ghazipur': 4022,
    'Ghiror': 4022,
    'Ghorawal': 4022,
    'Ghosi': 4022,
    'Gohand': 4022,
    'Gokul': 4022,
    'Gola Bazar': 4022,
    'Gola Gokarannath': 4022,
    'Gonda': 4022,
    'Gonda (Aligarh)': 4022,
    'Gonda City': 4022,
    'Gopamau': 4022,
    'Gorakhpur': 4022,
    'Gosainganj': 4022,
    'Goshainganj': 4022,
    'Govardhan': 4022,
    'Greater Noida': 4022,
    'Gulaothi': 4022,
    'Gunnaur': 4022,
    'Gursahaiganj': 4022,
    'Gursarai': 4022,
    'Gyanpur': 4022,
    'Haldaur': 4022,
    'Hamirpur': 4022,
    'Handia': 4022,
    'Hapur': 4022,
    'Haraipur': 4022,
    'Haraiya': 4022,
    'Harchandpur': 4022,
    'Hardoi': 4022,
    'Harduaganj': 4022,
    'Hasanpur': 4022,
    'Hastinapur': 4022,
    'Hata': 4022,
    'Hata (India)': 4022,
    'Hathras': 4022,
    'Hulas': 4022,
    'Ibrahimpur': 4022,
    'Iglas': 4022,
    'Ikauna': 4022,
    'Indergarh': 4022,
    'Indragarh': 4022,
    'Islamnagar': 4022,
    'Islamnagar (Badaun)': 4022,
    'Itaunja': 4022,
    'Itimadpur': 4022,
    'Jagdishpur': 4022,
    'Jagnair': 4022,
    'Jahanabad': 4022,
    'Jahanabad (Pilibhit)': 4022,
    'Jahangirabad': 4022,
    'Jahangirpur': 4022,
    'Jainpur': 4022,
    'Jais': 4022,
    'Jalalabad': 4022,
    'Jalali': 4022,
    'Jalalpur': 4022,
    'Jalaun': 4022,
    'Jalesar': 4022,
    'Janghai': 4022,
    'Jansath': 4022,
    'Jarwa': 4022,
    'Jarwal': 4022,
    'Jasrana': 4022,
    'Jaswantnagar': 4022,
    'Jaunpur': 4022,
    'Jewar': 4022,
    'Jhajhar': 4022,
    'Jhalu': 4022,
    'Jhansi': 4022,
    'Jhinjhak': 4022,
    'Jhinjhana': 4022,
    'Jhusi': 4022,
    'Jiyanpur': 4022,
    'Jyotiba Phule Nagar': 4022,
    'Kabrai': 4022,
    'Kachhwa': 4022,
    'Kadaura': 4022,
    'Kadipur': 4022,
    'Kagarol': 4022,
    'Kaimganj': 4022,
    'Kairana': 4022,
    'Kakori': 4022,
    'Kakrala': 4022,
    'Kalinagar': 4022,
    'Kalpi': 4022,
    'Kalyanpur': 4022,
    'Kamalganj': 4022,
    'Kampil': 4022,
    'Kandhla': 4022,
    'Kannauj': 4022,
    'Kanpur': 4022,
    'Kanpur Dehat': 4022,
    'Kant': 4022,
    'Kanth': 4022,
    'Kaptanganj': 4022,
    'Kara': 4022,
    'Karari': 4022,
    'Karbigwan': 4022,
    'Karchana': 4022,
    'Karhal': 4022,
    'Kasganj': 4022,
    'Katra': 4022,
    'Kausani': 4022,
    'Kaushambi District': 4022,
    'Kemri': 4022,
    'Khada': 4022,
    'Khaga': 4022,
    'Khailar': 4022,
    'Khair': 4022,
    'Khairabad': 4022,
    'Khalilabad': 4022,
    'Khanpur': 4022,
    'Kharela': 4022,
    'Khargupur': 4022,
    'Kharkhauda': 4022,
    'Khatauli': 4022,
    'Khekra': 4022,
    'Kheri': 4022,
    'Khudaganj': 4022,
    'Khurja': 4022,
    'Khutar': 4022,
    'Kirakat': 4022,
    'Kiraoli': 4022,
    'Kiratpur': 4022,
    'Kishanpur': 4022,
    'Kishanpur baral': 4022,
    'Kishni': 4022,
    'Kithor': 4022,
    'Konch': 4022,
    'Kopaganj': 4022,
    'Kosi': 4022,
    'Kota': 4022,
    'Kotra': 4022,
    'Kuchesar': 4022,
    'Kudarkot': 4022,
    'Kulpahar': 4022,
    'Kunda': 4022,
    'Kundarkhi': 4022,
    'Kundarki': 4022,
    'Kurara': 4022,
    'Kurebhar,saidkhanpur': 4022,
    'Kushinagar': 4022,
    'Kusmara': 4022,
    'Kuthaund': 4022,
    'Laharpur': 4022,
    'Lakhimpur': 4022,
    'Lakhna': 4022,
    'Lalganj': 4022,
    'Lalitpur': 4022,
    'Lambhua': 4022,
    'Lar': 4022,
    'Lawar': 4022,
    'Lawar Khas': 4022,
    'Loni': 4022,
    'Lucknow': 4022,
    'Lucknow District': 4022,
    'Machhali Shahar': 4022,
    'Machhlishahr': 4022,
    'Madhoganj': 4022,
    'Madhogarh': 4022,
    'Maghar': 4022,
    'Mahaban': 4022,
    'Maharajganj': 4022,
    'Mahmudabad': 4022,
    'Mahoba': 4022,
    'Maholi': 4022,
    'Mahrajganj': 4022,
    'Mahrajganj (Raebareli)': 4022,
    'Mahroni': 4022,
    'Mahul': 4022,
    'Mailani': 4022,
    'Mainpuri': 4022,
    'Majhupur': 4022,
    'Makanpur': 4022,
    'Malasa': 4022,
    'Malihabad': 4022,
    'Mandawar': 4022,
    'Maniar': 4022,
    'Manikpur': 4022,
    'Manjhanpur': 4022,
    'Mankapur': 4022,
    'Marahra': 4022,
    'Mariahu': 4022,
    'Mataundh': 4022,
    'Mathura': 4022,
    'Mau': 4022,
    'Mau Aima': 4022,
    'Mau Aimma': 4022,
    'Maudaha': 4022,
    'Mauranwan': 4022,
    'Maurawan': 4022,
    'Mawana': 4022,
    'Mawar': 4022,
    'Meerut': 4022,
    'Mehdawal': 4022,
    'Mehnagar': 4022,
    'Mehndawal': 4022,
    'Milak': 4022,
    'Milkipur': 4022,
    'Miranpur': 4022,
    'Miranpur Katra': 4022,
    'Mirganj': 4022,
    'Mirzapur': 4022,
    'Misrikh': 4022,
    'Mohan': 4022,
    'Mohanpur': 4022,
    'Moradabad': 4022,
    'Moth': 4022,
    'Mubarakpur': 4022,
    'Mughal Sarai': 4022,
    'Muhammadabad': 4022,
    'Mukteshwar': 4022,
    'Mungra Badshahpur': 4022,
    'Munsyari': 4022,
    'Muradabad': 4022,
    'Muradnagar': 4022,
    'Mursan': 4022,
    'Musafir-Khana': 4022,
    'Musafirkhana': 4022,
    'Muzaffarnagar': 4022,
    'Nadigaon': 4022,
    'Nagina': 4022,
    'Nagla': 4022,
    'Nagram': 4022,
    'Najibabad': 4022,
    'Nakur': 4022,
    'Nanauta': 4022,
    'Nandgaon': 4022,
    'Nanpara': 4022,
    'Narauli': 4022,
    'Naraura': 4022,
    'Narora': 4022,
    'Naugama': 4022,
    'Naurangpur': 4022,
    'Nautanwa': 4022,
    'Nawabganj': 4022,
    'Nawabganj (Barabanki)': 4022,
    'Nawabganj (Bareilly)': 4022,
    'Newara': 4022,
    'Nichlaul': 4022,
    'Nigoh': 4022,
    'Nihtaur': 4022,
    'Niwari': 4022,
    'Nizamabad': 4022,
    'Noida': 4022,
    'Nurpur': 4022,
    'Obra': 4022,
    'Orai': 4022,
    'Oran': 4022,
    'Pachperwa': 4022,
    'Padrauna': 4022,
    'Pahasu': 4022,
    'Paigaon': 4022,
    'Pali': 4022,
    'Palia Kalan': 4022,
    'Paras Rampur': 4022,
    'Parichha': 4022,
    'Parichhatgarh': 4022,
    'Parshadepur': 4022,
    'Pathakpura': 4022,
    'Patiali': 4022,
    'Patti': 4022,
    'Pawayan': 4022,
    'Payagpur': 4022,
    'Phalauda': 4022,
    'Phaphamau': 4022,
    'Phaphund': 4022,
    'Phariha': 4022,
    'Pheona': 4022,
    'Phulpur': 4022,
    'Pichhaura': 4022,
    'Pihani': 4022,
    'Pilibhit': 4022,
    'Pilkhua': 4022,
    'Pilkhuwa': 4022,
    'Pinahat': 4022,
    'Pipraich': 4022,
    'Pipri': 4022,
    'Pratapgarh': 4022,
    'Prayagraj (Allahabad)': 4022,
    'Pukhrayan': 4022,
    'Puranpur': 4022,
    'Purmafi': 4022,
    'Purwa': 4022,
    'Qadirganj': 4022,
    'Rabupura': 4022,
    'Radha Kund': 4022,
    'Radhakund': 4022,
    'Raebareli': 4022,
    'Rajapur': 4022,
    'Ramkola': 4022,
    'Ramnagar': 4022,
    'Rampur': 4022,
    'Rampura': 4022,
    'Ranipur': 4022,
    'Ranipur Barsi': 4022,
    'Rasra': 4022,
    'Rasulabad': 4022,
    'Rath': 4022,
    'Raya': 4022,
    'Rehar': 4022,
    'Renukoot': 4022,
    'Renukut': 4022,
    'Reoti': 4022,
    'Reotipur': 4022,
    'Richha': 4022,
    'Robertsganj': 4022,
    'Rudarpur': 4022,
    'Rudauli': 4022,
    'Rura': 4022,
    'Sabalpur': 4022,
    'Sachendi': 4022,
    'Sadabad': 4022,
    'Sadat': 4022,
    'Safipur': 4022,
    'Saharanpur': 4022,
    'Sahaspur': 4022,
    'Sahaswan': 4022,
    'Sahawar': 4022,
    'Sahibabad': 4022,
    'Sahpau': 4022,
    'Saidpur': 4022,
    'Sakhanu': 4022,
    'Sakit': 4022,
    'Salempur': 4022,
    'Salon': 4022,
    'Sambhal': 4022,
    'Samthar': 4022,
    'Sandi': 4022,
    'Sandila': 4022,
    'Sant Kabir Nagar': 4022,
    'Sant Ravi Das Nagar': 4022,
    'Sarai Akil': 4022,
    'Sarai Ekdil': 4022,
    'Sarai Mir': 4022,
    'Sarauli': 4022,
    'Sardhana': 4022,
    'Sarila': 4022,
    'Sarurpur': 4022,
    'Sasni': 4022,
    'Satrikh': 4022,
    'Saurikh': 4022,
    'Sector': 4022,
    'Seohara': 4022,
    'Shahabad': 4022,
    'Shahganj': 4022,
    'Shahi': 4022,
    'Shahjahanpur': 4022,
    'Shahpur': 4022,
    'Shamli': 4022,
    'Shamsabad': 4022,
    'Shankargarh': 4022,
    'Shergarh': 4022,
    'Sherkot': 4022,
    'Shibnagar': 4022,
    'Shikarpur': 4022,
    'Shikarpur (Bulandshahr)': 4022,
    'Shikohabad': 4022,
    'Shishgarh': 4022,
    'Shivrajpur': 4022,
    'Shrawasti': 4022,
    'Siddharthnagar': 4022,
    'Siddhaur': 4022,
    'Sidhauli': 4022,
    'Sidhpura': 4022,
    'Sikandarabad': 4022,
    'Sikandarpur': 4022,
    'Sikandra': 4022,
    'Sikandra Rao': 4022,
    'Sikandrabad': 4022,
    'Sirathu': 4022,
    'Sirsa': 4022,
    'Sirsaganj': 4022,
    'Sirsi': 4022,
    'Sisauli': 4022,
    'Siswa Bazar': 4022,
    'Sitapur': 4022,
    'Sonbhadra': 4022,
    'Soron': 4022,
    'Suar': 4022,
    'Sultanpur': 4022,
    'Surianwan': 4022,
    'Tajpur': 4022,
    'Talbahat': 4022,
    'Talgram': 4022,
    'Tanda': 4022,
    'Terha': 4022,
    'Thakurdwara': 4022,
    'Thana Bhawan': 4022,
    'Tigri': 4022,
    'Tikaitnagar': 4022,
    'Tikri': 4022,
    'Tilhar': 4022,
    'Tilsahri': 4022,
    'Tindwari': 4022,
    'Titron': 4022,
    'Tori Fatehpur': 4022,
    'Tori-Fatehpur': 4022,
    'Tulsipur': 4022,
    'Tundla': 4022,
    'Ugu': 4022,
    'Ujhani': 4022,
    'Umri': 4022,
    'Un': 4022,
    'Unnao': 4022,
    'Usawan': 4022,
    'Usehat': 4022,
    'Uska': 4022,
    'Utraula': 4022,
    'Varanasi': 4022,
    'Vindhyachal': 4022,
    'Vrindavan': 4022,
    'Walterganj': 4022,
    'Wazirganj': 4022,
    'Yusufpur': 4022,
    'Zafarabad': 4022,
    'Zaidpur': 4022,
    'Zamania': 4022,
    'Almora': 4016,
    'Bageshwar': 4016,
    'Barkot': 4016,
    'Bazpur': 4016,
    'Bhim Tal': 4016,
    'Bhowali': 4016,
    'Birbhaddar': 4016,
    'Chakrata': 4016,
    'Chamoli': 4016,
    'Champawat': 4016,
    'Clement Town': 4016,
    'Dehradun': 4016,
    'Devaprayag': 4016,
    'Dharchula': 4016,
    'Doiwala': 4016,
    'Dugadda': 4016,
    'Dwarahat': 4016,
    'Garhwal': 4016,
    'Haldwani': 4016,
    'Harbatpur': 4016,
    'Haridwar': 4016,
    'Jaspur': 4016,
    'Joshimath': 4016,
    'Kaladhungi': 4016,
    'Kalagarh Project Colony': 4016,
    'Kashipur': 4016,
    'Khatima': 4016,
    'Kichha': 4016,
    'Kotdwara': 4016,
    'Laksar': 4016,
    'Lansdowne': 4016,
    'Lohaghat': 4016,
    'Manglaur': 4016,
    'Mussoorie': 4016,
    'Naini Tal': 4016,
    'Narendranagar': 4016,
    'Pauri': 4016,
    'Pipalkoti': 4016,
    'Pithoragarh': 4016,
    'Raipur': 4016,
    'Raiwala Bara': 4016,
    'Ramnagar': 4016,
    'Ranikhet': 4016,
    'Rishikesh': 4016,
    'Roorkee': 4016,
    'Rudraprayag': 4016,
    'Sitarganj': 4016,
    'Srinagar': 4016,
    'Sultanpur': 4016,
    'Tanakpur': 4016,
    'Tehri': 4016,
    'Tehri-Garhwal': 4016,
    'Udham Singh Nagar': 4016,
    'Uttarkashi': 4016,
    'Vikasnagar': 4016,
    'Ahmedpur': 4853,
    'Aistala': 4853,
    'Aknapur': 4853,
    'Alipurduar': 4853,
    'Amlagora': 4853,
    'Amta': 4853,
    'Amtala': 4853,
    'Andal': 4853,
    'Arambagh community development block': 4853,
    'Asansol': 4853,
    'Ashoknagar Kalyangarh': 4853,
    'Badkulla': 4853,
    'Baduria': 4853,
    'Bagdogra': 4853,
    'Bagnan': 4853,
    'Bagula': 4853,
    'Bahula': 4853,
    'Baidyabati': 4853,
    'Bakreswar': 4853,
    'Balarampur': 4853,
    'Bali Chak': 4853,
    'Bally': 4853,
    'Balurghat': 4853,
    'Bamangola community development block': 4853,
    'Baneswar': 4853,
    'Bangaon': 4853,
    'Bankra': 4853,
    'Bankura': 4853,
    'Bansberia': 4853,
    'Bansihari community development block': 4853,
    'Barabazar': 4853,
    'Baranagar': 4853,
    'Barasat': 4853,
    'Bardhaman': 4853,
    'Barjora': 4853,
    'Barrackpore': 4853,
    'Baruipur': 4853,
    'Basanti': 4853,
    'Basirhat': 4853,
    'Bawali': 4853,
    'Begampur': 4853,
    'Belda': 4853,
    'Beldanga': 4853,
    'Beliatore': 4853,
    'Berhampore': 4853,
    'Bhadreswar': 4853,
    'Bhandardaha': 4853,
    'Bhatpara': 4853,
    'Birbhum district': 4853,
    'Birpara': 4853,
    'Bishnupur': 4853,
    'Bolpur': 4853,
    'Budge Budge': 4853,
    'Canning': 4853,
    'Chakapara': 4853,
    'Chakdaha': 4853,
    'Champadanga': 4853,
    'Champahati': 4853,
    'Champdani': 4853,
    'Chandannagar': 4853,
    'Chandrakona': 4853,
    'Chittaranjan': 4853,
    'Churulia': 4853,
    'Contai': 4853,
    'Cooch Behar': 4853,
    'Cossimbazar': 4853,
    'Dakshin Dinajpur district': 4853,
    'Dalkola': 4853,
    'Dam Dam': 4853,
    'Darjeeling': 4853,
    'Daulatpur': 4853,
    'Debagram': 4853,
    'Debipur': 4853,
    'Dhaniakhali community development block': 4853,
    'Dhulagari': 4853,
    'Dhulian': 4853,
    'Dhupguri': 4853,
    'Diamond Harbour': 4853,
    'Digha': 4853,
    'Dinhata': 4853,
    'Domjur': 4853,
    'Dubrajpur': 4853,
    'Durgapur': 4853,
    'Egra': 4853,
    'Falakata': 4853,
    'Farakka': 4853,
    'Fort Gloster': 4853,
    'Gaighata community development block': 4853,
    'Gairkata': 4853,
    'Gangadharpur': 4853,
    'Gangarampur': 4853,
    'Garui': 4853,
    'Garulia': 4853,
    'Ghatal': 4853,
    'Giria': 4853,
    'Gobardanga': 4853,
    'Gobindapur': 4853,
    'Gopalpur': 4853,
    'Gopinathpur': 4853,
    'Gorubathan': 4853,
    'Gosaba': 4853,
    'Gosanimari': 4853,
    'Gurdaha': 4853,
    'Guskhara': 4853,
    'Habra': 4853,
    'Haldia': 4853,
    'Haldibari': 4853,
    'Halisahar': 4853,
    'Harindanga': 4853,
    'Haringhata': 4853,
    'Haripur': 4853,
    'Hasimara': 4853,
    'Hindusthan Cables Town': 4853,
    'Hooghly district': 4853,
    'Howrah': 4853,
    'Ichapur': 4853,
    'Indpur community development block': 4853,
    'Ingraj Bazar': 4853,
    'Islampur': 4853,
    'Jafarpur': 4853,
    'Jaigaon': 4853,
    'Jalpaiguri': 4853,
    'Jamuria': 4853,
    'Jangipur': 4853,
    'Jaynagar Majilpur': 4853,
    'Jejur': 4853,
    'Jhalida': 4853,
    'Jhargram': 4853,
    'Jhilimili': 4853,
    'Kakdwip': 4853,
    'Kalaikunda': 4853,
    'Kaliaganj': 4853,
    'Kalimpong': 4853,
    'Kalna': 4853,
    'Kalyani': 4853,
    'Kamarhati': 4853,
    'Kamarpukur': 4853,
    'Kanchrapara': 4853,
    'Kandi': 4853,
    'Karimpur': 4853,
    'Katwa': 4853,
    'Kenda': 4853,
    'Keshabpur': 4853,
    'Kharagpur': 4853,
    'Kharar': 4853,
    'Kharba': 4853,
    'Khardaha': 4853,
    'Khatra': 4853,
    'Kirnahar': 4853,
    'Kolkata': 4853,
    'Konnagar': 4853,
    'Krishnanagar': 4853,
    'Krishnapur': 4853,
    'Kshirpai': 4853,
    'Kulpi': 4853,
    'Kultali': 4853,
    'Kulti': 4853,
    'Kurseong': 4853,
    'Lalgarh': 4853,
    'Lalgola': 4853,
    'Loyabad': 4853,
    'Madanpur': 4853,
    'Madhyamgram': 4853,
    'Mahiari': 4853,
    'Mahishadal community development block': 4853,
    'Mainaguri': 4853,
    'Manikpara': 4853,
    'Masila': 4853,
    'Mathabhanga': 4853,
    'Matiali community development block': 4853,
    'Matigara community development block': 4853,
    'Medinipur': 4853,
    'Mejia community development block': 4853,
    'Memari': 4853,
    'Mirik': 4853,
    'Mohanpur community development block': 4853,
    'Monoharpur': 4853,
    'Muragacha': 4853,
    'Muri': 4853,
    'Murshidabad': 4853,
    'Nabadwip': 4853,
    'Nabagram': 4853,
    'Nadia district': 4853,
    'Nagarukhra': 4853,
    'Nagrakata': 4853,
    'Naihati': 4853,
    'Naksalbari': 4853,
    'Nalhati': 4853,
    'Nalpur': 4853,
    'Namkhana community development block': 4853,
    'Nandigram': 4853,
    'Nangi': 4853,
    'Nayagram community development block': 4853,
    'North 24 Parganas district': 4853,
    'Odlabari': 4853,
    'Paikpara': 4853,
    'Panagarh': 4853,
    'Panchla': 4853,
    'Panchmura': 4853,
    'Pandua': 4853,
    'Panihati': 4853,
    'Panskura': 4853,
    'Parbatipur': 4853,
    'Paschim Medinipur district': 4853,
    'Patiram': 4853,
    'Patrasaer': 4853,
    'Patuli': 4853,
    'Pujali': 4853,
    'Puncha community development block': 4853,
    'Purba Medinipur district': 4853,
    'Purulia': 4853,
    'Raghudebbati': 4853,
    'Raghunathpur': 4853,
    'Raiganj': 4853,
    'Rajmahal': 4853,
    'Rajnagar community development block': 4853,
    'Ramchandrapur': 4853,
    'Ramjibanpur': 4853,
    'Ramnagar': 4853,
    'Rampur Hat': 4853,
    'Ranaghat': 4853,
    'Raniganj': 4853,
    'Raypur': 4853,
    'Rishra': 4853,
    'Sahapur': 4853,
    'Sainthia': 4853,
    'Salanpur community development block': 4853,
    'Sankarpur': 4853,
    'Sankrail': 4853,
    'Santipur': 4853,
    'Santoshpur': 4853,
    'Santuri community development block': 4853,
    'Sarenga': 4853,
    'Serampore': 4853,
    'Serpur': 4853,
    'Shyamnagar, West Bengal': 4853,
    'Siliguri': 4853,
    'Singur': 4853,
    'Sodpur': 4853,
    'Solap': 4853,
    'Sonada': 4853,
    'Sonamukhi': 4853,
    'Sonarpur community development block': 4853,
    'South 24 Parganas district': 4853,
    'Srikhanda': 4853,
    'Srirampur': 4853,
    'Suri': 4853,
    'Swarupnagar community development block': 4853,
    'Takdah': 4853,
    'Taki': 4853,
    'Tamluk': 4853,
    'Tarakeswar': 4853,
    'Titagarh': 4853,
    'Tufanganj': 4853,
    'Tulin': 4853,
    'Uchalan': 4853,
    'Ula': 4853,
    'Uluberia': 4853,
    'Uttar Dinajpur district': 4853,
    'Uttarpara Kotrung': 4853,
    'Banda Aceh': 1822,
    'Bireun': 1822,
    'Kabupaten Aceh Barat': 1822,
    'Kabupaten Aceh Barat Daya': 1822,
    'Kabupaten Aceh Besar': 1822,
    'Kabupaten Aceh Jaya': 1822,
    'Kabupaten Aceh Selatan': 1822,
    'Kabupaten Aceh Singkil': 1822,
    'Kabupaten Aceh Tamiang': 1822,
    'Kabupaten Aceh Tengah': 1822,
    'Kabupaten Aceh Tenggara': 1822,
    'Kabupaten Aceh Timur': 1822,
    'Kabupaten Aceh Utara': 1822,
    'Kabupaten Bener Meriah': 1822,
    'Kabupaten Bireuen': 1822,
    'Kabupaten Gayo Lues': 1822,
    'Kabupaten Nagan Raya': 1822,
    'Kabupaten Pidie': 1822,
    'Kabupaten Simeulue': 1822,
    'Kota Banda Aceh': 1822,
    'Kota Langsa': 1822,
    'Kota Lhokseumawe': 1822,
    'Kota Sabang': 1822,
    'Kota Subulussalam': 1822,
    'Langsa': 1822,
    'Lhokseumawe': 1822,
    'Meulaboh': 1822,
    'Reuleuet': 1822,
    'Sabang': 1822,
    'Sigli': 1822,
    'Sinabang': 1822,
    'Singkil': 1822,
    'Amlapura': 1826,
    'Amlapura city': 1826,
    'Banjar': 1826,
    'Banjar Wangsian': 1826,
    'Bedugul': 1826,
    'Denpasar': 1826,
    'Jimbaran': 1826,
    'Kabupaten Badung': 1826,
    'Kabupaten Bangli': 1826,
    'Kabupaten Buleleng': 1826,
    'Kabupaten Gianyar': 1826,
    'Kabupaten Jembrana': 1826,
    'Kabupaten Karang Asem': 1826,
    'Kabupaten Klungkung': 1826,
    'Kabupaten Tabanan': 1826,
    'Klungkung': 1826,
    'Kota Denpasar': 1826,
    'Kuta': 1826,
    'Legian': 1826,
    'Lovina': 1826,
    'Munduk': 1826,
    'Negara': 1826,
    'Nusa Dua': 1826,
    'Seririt': 1826,
    'Singaraja': 1826,
    'Tabanan': 1826,
    'Ubud': 1826,
    'Kabupaten Bangka': 1820,
    'Kabupaten Bangka Barat': 1820,
    'Kabupaten Bangka Selatan': 1820,
    'Kabupaten Bangka Tengah': 1820,
    'Kabupaten Belitung': 1820,
    'Kabupaten Belitung Timur': 1820,
    'Kota Pangkal Pinang': 1820,
    'Manggar': 1820,
    'Muntok': 1820,
    'Pangkalpinang': 1820,
    'Sungailiat': 1820,
    'Tanjung Pandan': 1820,
    'Curug': 1810,
    'Kabupaten Lebak': 1810,
    'Kabupaten Pandeglang': 1810,
    'Kabupaten Serang': 1810,
    'Kabupaten Tangerang': 1810,
    'Kota Cilegon': 1810,
    'Kota Serang': 1810,
    'Kota Tangerang': 1810,
    'Kota Tangerang Selatan': 1810,
    'Labuan': 1810,
    'Pandeglang': 1810,
    'Rangkasbitung': 1810,
    'Serang': 1810,
    'South Tangerang': 1810,
    'Tangerang': 1810,
    'Bengkulu': 1793,
    'Curup': 1793,
    'Kabupaten Bengkulu Selatan': 1793,
    'Kabupaten Bengkulu Tengah': 1793,
    'Kabupaten Bengkulu Utara': 1793,
    'Kabupaten Kaur': 1793,
    'Kabupaten Kepahiang': 1793,
    'Kabupaten Lebong': 1793,
    'Kabupaten Mukomuko': 1793,
    'Kabupaten Rejang Lebong': 1793,
    'Kabupaten Seluma': 1793,
    'Kota Bengkulu': 1793,
    'Adiwerna': 1802,
    'Ambarawa': 1802,
    'Baekrajan': 1802,
    'Baki': 1802,
    'Balapulang': 1802,
    'Banyumas': 1802,
    'Batang': 1802,
    'Baturaden': 1802,
    'Blora': 1802,
    'Boyolali': 1802,
    'Buaran': 1802,
    'Bulakamba': 1802,
    'Candi Prambanan': 1802,
    'Ceper': 1802,
    'Cepu': 1802,
    'Colomadu': 1802,
    'Comal': 1802,
    'Delanggu': 1802,
    'Demak': 1802,
    'Dukuhturi': 1802,
    'Gatak': 1802,
    'Gebog': 1802,
    'Gombong': 1802,
    'Grogol': 1802,
    'Gunung Kendil': 1802,
    'Jaten': 1802,
    'Jatiroto': 1802,
    'Jekulo': 1802,
    'Jogonalan': 1802,
    'Juwana': 1802,
    'Kabupaten Banjarnegara': 1802,
    'Kabupaten Banyumas': 1802,
    'Kabupaten Batang': 1802,
    'Kabupaten Blora': 1802,
    'Kabupaten Boyolali': 1802,
    'Kabupaten Brebes': 1802,
    'Kabupaten Cilacap': 1802,
    'Kabupaten Demak': 1802,
    'Kabupaten Grobogan': 1802,
    'Kabupaten Jepara': 1802,
    'Kabupaten Karanganyar': 1802,
    'Kabupaten Kebumen': 1802,
    'Kabupaten Kendal': 1802,
    'Kabupaten Klaten': 1802,
    'Kabupaten Kudus': 1802,
    'Kabupaten Magelang': 1802,
    'Kabupaten Pati': 1802,
    'Kabupaten Pekalongan': 1802,
    'Kabupaten Pemalang': 1802,
    'Kabupaten Purbalingga': 1802,
    'Kabupaten Purworejo': 1802,
    'Kabupaten Rembang': 1802,
    'Kabupaten Semarang': 1802,
    'Kabupaten Sragen': 1802,
    'Kabupaten Sukoharjo': 1802,
    'Kabupaten Tegal': 1802,
    'Kabupaten Temanggung': 1802,
    'Kabupaten Wonogiri': 1802,
    'Kabupaten Wonosobo': 1802,
    'Karanganom': 1802,
    'Kartasura': 1802,
    'Kebonarun': 1802,
    'Kedungwuni': 1802,
    'Ketanggungan': 1802,
    'Klaten': 1802,
    'Kota Magelang': 1802,
    'Kota Pekalongan': 1802,
    'Kota Salatiga': 1802,
    'Kota Semarang': 1802,
    'Kota Surakarta': 1802,
    'Kota Tegal': 1802,
    'Kroya': 1802,
    'Kudus': 1802,
    'Kutoarjo': 1802,
    'Lasem': 1802,
    'Lebaksiu': 1802,
    'Magelang': 1802,
    'Majenang': 1802,
    'Margasari': 1802,
    'Mertoyudan': 1802,
    'Mlonggo': 1802,
    'Mranggen': 1802,
    'Muntilan': 1802,
    'Ngemplak': 1802,
    'Pati': 1802,
    'Pecangaan': 1802,
    'Pekalongan': 1802,
    'Pemalang': 1802,
    'Purbalingga': 1802,
    'Purwodadi': 1802,
    'Purwokerto': 1802,
    'Randudongkal': 1802,
    'Rembangan': 1802,
    'Salatiga': 1802,
    'Selogiri': 1802,
    'Semarang': 1802,
    'Sidareja': 1802,
    'Slawi': 1802,
    'Sokaraja': 1802,
    'Sragen': 1802,
    'Surakarta': 1802,
    'Tarub': 1802,
    'Tayu': 1802,
    'Tegal': 1802,
    'Trucuk': 1802,
    'Ungaran': 1802,
    'Wangon': 1802,
    'Wedi': 1802,
    'Welahan': 1802,
    'Weleri': 1802,
    'Wiradesa': 1802,
    'Wonopringgo': 1802,
    'Wonosobo': 1802,
    'Kabupaten Barito Selatan': 1794,
    'Kabupaten Barito Timur': 1794,
    'Kabupaten Barito Utara': 1794,
    'Kabupaten Gunung Mas': 1794,
    'Kabupaten Kapuas': 1794,
    'Kabupaten Katingan': 1794,
    'Kabupaten Kotawaringin Barat': 1794,
    'Kabupaten Kotawaringin Timur': 1794,
    'Kabupaten Lamandau': 1794,
    'Kabupaten Murung Raya': 1794,
    'Kabupaten Pulang Pisau': 1794,
    'Kabupaten Seruyan': 1794,
    'Kabupaten Sukamara': 1794,
    'Kota Palangka Raya': 1794,
    'Kualakapuas': 1794,
    'Palangkaraya': 1794,
    'Pangkalanbuun': 1794,
    'Sampit': 1794,
    'Kabupaten Banggai': 1813,
    'Kabupaten Banggai Kepulauan': 1813,
    'Kabupaten Banggai Laut': 1813,
    'Kabupaten Buol': 1813,
    'Kabupaten Donggala': 1813,
    'Kabupaten Morowali Utara': 1813,
    'Kabupaten Parigi Moutong': 1813,
    'Kabupaten Poso': 1813,
    'Kabupaten Sigi': 1813,
    'Kabupaten Toli-Toli': 1813,
    'Kota Palu': 1813,
    'Luwuk': 1813,
    'Morowali Regency': 1813,
    'Palu': 1813,
    'Poso': 1813,
    'Tojo Una-Una Regency': 1813,
    'Babat': 1827,
    'Balung': 1827,
    'Bangil': 1827,
    'Bangkalan': 1827,
    'Banyuwangi': 1827,
    'Batu': 1827,
    'Besuki': 1827,
    'Blitar': 1827,
    'Bojonegoro': 1827,
    'Bondowoso': 1827,
    'Boyolangu': 1827,
    'Buduran': 1827,
    'Dampit': 1827,
    'Diwek': 1827,
    'Driyorejo': 1827,
    'Gambiran Satu': 1827,
    'Gampengrejo': 1827,
    'Gedangan': 1827,
    'Genteng': 1827,
    'Gongdanglegi Kulon': 1827,
    'Gresik': 1827,
    'Gresik Regency': 1827,
    'Jember': 1827,
    'Jombang': 1827,
    'Kabupaten Bangkalan': 1827,
    'Kabupaten Banyuwangi': 1827,
    'Kabupaten Blitar': 1827,
    'Kabupaten Bojonegoro': 1827,
    'Kabupaten Bondowoso': 1827,
    'Kabupaten Jember': 1827,
    'Kabupaten Jombang': 1827,
    'Kabupaten Kediri': 1827,
    'Kabupaten Lamongan': 1827,
    'Kabupaten Lumajang': 1827,
    'Kabupaten Madiun': 1827,
    'Kabupaten Magetan': 1827,
    'Kabupaten Malang': 1827,
    'Kabupaten Mojokerto': 1827,
    'Kabupaten Nganjuk': 1827,
    'Kabupaten Ngawi': 1827,
    'Kabupaten Pacitan': 1827,
    'Kabupaten Pamekasan': 1827,
    'Kabupaten Pasuruan': 1827,
    'Kabupaten Ponorogo': 1827,
    'Kabupaten Probolinggo': 1827,
    'Kabupaten Sampang': 1827,
    'Kabupaten Sidoarjo': 1827,
    'Kabupaten Situbondo': 1827,
    'Kabupaten Sumenep': 1827,
    'Kabupaten Trenggalek': 1827,
    'Kabupaten Tuban': 1827,
    'Kabupaten Tulungagung': 1827,
    'Kalianget': 1827,
    'Kamal': 1827,
    'Kebomas': 1827,
    'Kediri': 1827,
    'Kedungwaru': 1827,
    'Kencong': 1827,
    'Kepanjen': 1827,
    'Kertosono': 1827,
    'Kota Batu': 1827,
    'Kota Blitar': 1827,
    'Kota Kediri': 1827,
    'Kota Madiun': 1827,
    'Kota Malang': 1827,
    'Kota Mojokerto': 1827,
    'Kota Pasuruan': 1827,
    'Kota Probolinggo': 1827,
    'Kota Surabaya': 1827,
    'Kraksaan': 1827,
    'Krian': 1827,
    'Lamongan': 1827,
    'Lawang': 1827,
    'Lumajang': 1827,
    'Madiun': 1827,
    'Malang': 1827,
    'Mojoagung': 1827,
    'Mojokerto': 1827,
    'Muncar': 1827,
    'Nganjuk': 1827,
    'Ngoro': 1827,
    'Ngunut': 1827,
    'Paciran': 1827,
    'Pakisaji': 1827,
    'Pamekasan': 1827,
    'Panarukan': 1827,
    'Pandaan': 1827,
    'Panji': 1827,
    'Pare': 1827,
    'Pasuruan': 1827,
    'Ponorogo': 1827,
    'Prigen': 1827,
    'Probolinggo': 1827,
    'Sampang': 1827,
    'Sidoarjo': 1827,
    'Singojuruh': 1827,
    'Singosari': 1827,
    'Situbondo': 1827,
    'Soko': 1827,
    'Srono': 1827,
    'Sumberpucung': 1827,
    'Sumenep': 1827,
    'Surabaya': 1827,
    'Tanggul': 1827,
    'Tanggulangin': 1827,
    'Trenggalek': 1827,
    'Tuban': 1827,
    'Tulangan Utara': 1827,
    'Tulungagung': 1827,
    'Wongsorejo': 1827,
    'Balikpapan': 1804,
    'Bontang': 1804,
    'City of Balikpapan': 1804,
    'Kabupaten Berau': 1804,
    'Kabupaten Kutai Barat': 1804,
    'Kabupaten Kutai Kartanegara': 1804,
    'Kabupaten Kutai Timur': 1804,
    'Kabupaten Mahakam Hulu': 1804,
    'Kabupaten Paser': 1804,
    'Kabupaten Penajam Paser Utara': 1804,
    'Kota Balikpapan': 1804,
    'Kota Bontang': 1804,
    'Kota Samarinda': 1804,
    'Loa Janan': 1804,
    'Samarinda': 1804,
    'Atambua': 1818,
    'Ende': 1818,
    'Kabupaten Alor': 1818,
    'Kabupaten Belu': 1818,
    'Kabupaten Ende': 1818,
    'Kabupaten Flores Timur': 1818,
    'Kabupaten Kupang': 1818,
    'Kabupaten Lembata': 1818,
    'Kabupaten Malaka': 1818,
    'Kabupaten Manggarai': 1818,
    'Kabupaten Manggarai Barat': 1818,
    'Kabupaten Manggarai Timur': 1818,
    'Kabupaten Nagekeo': 1818,
    'Kabupaten Ngada': 1818,
    'Kabupaten Rote Ndao': 1818,
    'Kabupaten Sabu Raijua': 1818,
    'Kabupaten Sikka': 1818,
    'Kabupaten Sumba Barat': 1818,
    'Kabupaten Sumba Barat Daya': 1818,
    'Kabupaten Sumba Tengah': 1818,
    'Kabupaten Sumba Timur': 1818,
    'Kabupaten Timor Tengah Selatan': 1818,
    'Kabupaten Timor Tengah Utara': 1818,
    'Kefamenanu': 1818,
    'Komodo': 1818,
    'Kota Kupang': 1818,
    'Kupang': 1818,
    'Labuan Bajo': 1818,
    'Maumere': 1818,
    'Naisano Dua': 1818,
    'Ruteng': 1818,
    'Soe': 1818,
    'Waingapu': 1818,
    'Gorontalo': 1812,
    'Kabupaten Boalemo': 1812,
    'Kabupaten Bone Bolango': 1812,
    'Kabupaten Gorontalo': 1812,
    'Kabupaten Gorontalo Utara': 1812,
    'Kabupaten Pohuwato': 1812,
    'Kota Gorontalo': 1812,
    'Jakarta': 1805,
    'Kota Administrasi Jakarta Barat': 1805,
    'Kota Administrasi Jakarta Pusat': 1805,
    'Kota Administrasi Jakarta Selatan': 1805,
    'Kota Administrasi Jakarta Timur': 1805,
    'Kota Administrasi Jakarta Utara': 1805,
    'Bejubang Dua': 1815,
    'Jambi City': 1815,
    'Kabupaten Batang Hari': 1815,
    'Kabupaten Bungo': 1815,
    'Kabupaten Kerinci': 1815,
    'Kabupaten Merangin': 1815,
    'Kabupaten Muaro Jambi': 1815,
    'Kabupaten Sarolangun': 1815,
    'Kabupaten Tanjung Jabung Barat': 1815,
    'Kabupaten Tanjung Jabung Timur': 1815,
    'Kabupaten Tebo': 1815,
    'Kota Jambi': 1815,
    'Kota Sungai Penuh': 1815,
    'Kuala Tungkal': 1815,
    'Mendaha': 1815,
    'Simpang': 1815,
    'Sungai Penuh': 1815,
    'Bandar Lampung': 1811,
    'Kabupaten Lampung Barat': 1811,
    'Kabupaten Lampung Selatan': 1811,
    'Kabupaten Lampung Tengah': 1811,
    'Kabupaten Lampung Timur': 1811,
    'Kabupaten Lampung Utara': 1811,
    'Kabupaten Mesuji': 1811,
    'Kabupaten Pesawaran': 1811,
    'Kabupaten Pesisir Barat': 1811,
    'Kabupaten Pringsewu': 1811,
    'Kabupaten Tanggamus': 1811,
    'Kabupaten Tulangbawang': 1811,
    'Kabupaten Way Kanan': 1811,
    'Kota Bandar Lampung': 1811,
    'Kota Metro': 1811,
    'Kotabumi': 1811,
    'Metro': 1811,
    'Terbanggi Besar': 1811,
    'Amahai': 1800,
    'Ambon': 1800,
    'Kabupaten Buru': 1800,
    'Kabupaten Buru Selatan': 1800,
    'Kabupaten Kepulauan Aru': 1800,
    'Kabupaten Maluku Barat Daya': 1800,
    'Kabupaten Maluku Tengah': 1800,
    'Kabupaten Maluku Tenggara': 1800,
    'Kabupaten Maluku Tenggara Barat': 1800,
    'Kabupaten Seram Bagian Barat': 1800,
    'Kabupaten Seram Bagian Timur': 1800,
    'Kota Ambon': 1800,
    'Kota Tual': 1800,
    'Tual': 1800,
    'Kabupaten Bulungan': 1824,
    'Kabupaten Malinau': 1824,
    'Kabupaten Nunukan': 1824,
    'Kabupaten Tana Tidung': 1824,
    'Tanjung Selor': 1824,
    'Tarakan': 1824,
    'East Halmahera Regency': 1801,
    'Kabupaten Halmahera Barat': 1801,
    'Kabupaten Halmahera Selatan': 1801,
    'Kabupaten Halmahera Tengah': 1801,
    'Kabupaten Halmahera Utara': 1801,
    'Kabupaten Kepulauan Sula': 1801,
    'Kabupaten Pulau Morotai': 1801,
    'Kabupaten Pulau Taliabu': 1801,
    'Kota Ternate': 1801,
    'Kota Tidore Kepulauan': 1801,
    'Sofifi': 1801,
    'Ternate': 1801,
    'Tobelo': 1801,
    'Kabupaten Bolaang Mongondow': 1808,
    'Kabupaten Bolaang Mongondow Selatan': 1808,
    'Kabupaten Bolaang Mongondow Timur': 1808,
    'Kabupaten Bolaang Mongondow Utara': 1808,
    'Kabupaten Kepulauan Sangihe': 1808,
    'Kabupaten Kepulauan Talaud': 1808,
    'Kabupaten Minahasa': 1808,
    'Kabupaten Minahasa Selatan': 1808,
    'Kabupaten Minahasa Tenggara': 1808,
    'Kabupaten Minahasa Utara': 1808,
    'Kabupaten Siau Tagulandang Biaro': 1808,
    'Kota Bitung': 1808,
    'Kota Kotamobagu': 1808,
    'Kota Manado': 1808,
    'Kota Tomohon': 1808,
    'Laikit, Laikit II (Dimembe)': 1808,
    'Manado': 1808,
    'Tomohon': 1808,
    'Tondano': 1808,
    'Ambarita': 1792,
    'Bandar': 1792,
    'Belawan': 1792,
    'Berastagi': 1792,
    'Binjai': 1792,
    'Deli Tua': 1792,
    'Gunungsitoli': 1792,
    'Kabanjahe': 1792,
    'Kabupaten Asahan': 1792,
    'Kabupaten Batu Bara': 1792,
    'Kabupaten Dairi': 1792,
    'Kabupaten Deli Serdang': 1792,
    'Kabupaten Humbang Hasundutan': 1792,
    'Kabupaten Karo': 1792,
    'Kabupaten Labuhan Batu': 1792,
    'Kabupaten Labuhan Batu Selatan': 1792,
    'Kabupaten Labuhan Batu Utara': 1792,
    'Kabupaten Langkat': 1792,
    'Kabupaten Mandailing Natal': 1792,
    'Kabupaten Nias': 1792,
    'Kabupaten Nias Barat': 1792,
    'Kabupaten Nias Utara': 1792,
    'Kabupaten Padang Lawas': 1792,
    'Kabupaten Padang Lawas Utara': 1792,
    'Kabupaten Pakpak Bharat': 1792,
    'Kabupaten Samosir': 1792,
    'Kabupaten Serdang Bedagai': 1792,
    'Kabupaten Simalungun': 1792,
    'Kabupaten Tapanuli Selatan': 1792,
    'Kabupaten Tapanuli Tengah': 1792,
    'Kabupaten Tapanuli Utara': 1792,
    'Kisaran': 1792,
    'Kota Binjai': 1792,
    'Kota Gunungsitoli': 1792,
    'Kota Medan': 1792,
    'Kota Padangsidimpuan': 1792,
    'Kota Pematang Siantar': 1792,
    'Kota Sibolga': 1792,
    'Kota Tanjung Balai': 1792,
    'Kota Tebing Tinggi': 1792,
    'Labuhan Deli': 1792,
    'Medan': 1792,
    'Padangsidempuan': 1792,
    'Pangkalan Brandan': 1792,
    'Parapat': 1792,
    'Pekan Bahapal': 1792,
    'Pematangsiantar': 1792,
    'Perbaungan': 1792,
    'Percut': 1792,
    'Rantauprapat': 1792,
    'Sibolga': 1792,
    'Stabat': 1792,
    'Sunggal': 1792,
    'Tanjungbalai': 1792,
    'Tanjungtiram': 1792,
    'Tebingtinggi': 1792,
    'Teluk Nibung': 1792,
    'Tomok Bolon': 1792,
    'Tongging': 1792,
    'Tuktuk Sonak': 1792,
    'Abepura': 1798,
    'Biak': 1798,
    'Insrom': 1798,
    'Jayapura': 1798,
    'Kabupaten Asmat': 1798,
    'Kabupaten Biak Numfor': 1798,
    'Kabupaten Boven Digoel': 1798,
    'Kabupaten Deiyai': 1798,
    'Kabupaten Dogiyai': 1798,
    'Kabupaten Intan Jaya': 1798,
    'Kabupaten Jayapura': 1798,
    'Kabupaten Jayawijaya': 1798,
    'Kabupaten Keerom': 1798,
    'Kabupaten Kepulauan Yapen': 1798,
    'Kabupaten Lanny Jaya': 1798,
    'Kabupaten Mamberamo Raya': 1798,
    'Kabupaten Mamberamo Tengah': 1798,
    'Kabupaten Mappi': 1798,
    'Kabupaten Merauke': 1798,
    'Kabupaten Mimika': 1798,
    'Kabupaten Nabire': 1798,
    'Kabupaten Nduga': 1798,
    'Kabupaten Paniai': 1798,
    'Kabupaten Pegunungan Bintang': 1798,
    'Kabupaten Puncak Jaya': 1798,
    'Kabupaten Sarmi': 1798,
    'Kabupaten Supiori': 1798,
    'Kabupaten Tolikara': 1798,
    'Kabupaten Waropen': 1798,
    'Kabupaten Yahukimo': 1798,
    'Kabupaten Yalimo': 1798,
    'Kota Jayapura': 1798,
    'Nabire': 1798,
    'Balaipungut': 1809,
    'Batam': 1809,
    'Dumai': 1809,
    'Kabupaten Bengkalis': 1809,
    'Kabupaten Indragiri Hilir': 1809,
    'Kabupaten Indragiri Hulu': 1809,
    'Kabupaten Kampar': 1809,
    'Kabupaten Kepulauan Meranti': 1809,
    'Kabupaten Kuantan Singingi': 1809,
    'Kabupaten Pelalawan': 1809,
    'Kabupaten Rokan Hilir': 1809,
    'Kabupaten Rokan Hulu': 1809,
    'Kabupaten Siak': 1809,
    'Kota Dumai': 1809,
    'Kota Pekanbaru': 1809,
    'Pekanbaru': 1809,
    'Kabupaten Bintan': 1807,
    'Kabupaten Karimun': 1807,
    'Kabupaten Kepulauan Anambas': 1807,
    'Kabupaten Lingga': 1807,
    'Kabupaten Natuna': 1807,
    'Kijang': 1807,
    'Kota Batam': 1807,
    'Kota Tanjung Pinang': 1807,
    'Tanjung Pinang': 1807,
    'Amuntai': 1819,
    'Banjarmasin': 1819,
    'Barabai': 1819,
    'Kabupaten Balangan': 1819,
    'Kabupaten Banjar': 1819,
    'Kabupaten Barito Kuala': 1819,
    'Kabupaten Hulu Sungai Selatan': 1819,
    'Kabupaten Hulu Sungai Tengah': 1819,
    'Kabupaten Hulu Sungai Utara': 1819,
    'Kabupaten Kota Baru': 1819,
    'Kabupaten Tabalong': 1819,
    'Kabupaten Tanah Bumbu': 1819,
    'Kabupaten Tanah Laut': 1819,
    'Kabupaten Tapin': 1819,
    'Kota Banjar Baru': 1819,
    'Kota Banjarmasin': 1819,
    'Martapura': 1819,
    'Galesong': 1795,
    'Kabupaten Bantaeng': 1795,
    'Kabupaten Barru': 1795,
    'Kabupaten Bone': 1795,
    'Kabupaten Bulukumba': 1795,
    'Kabupaten Enrekang': 1795,
    'Kabupaten Gowa': 1795,
    'Kabupaten Jeneponto': 1795,
    'Kabupaten Luwu': 1795,
    'Kabupaten Luwu Timur': 1795,
    'Kabupaten Luwu Utara': 1795,
    'Kabupaten Maros': 1795,
    'Kabupaten Pangkajene Dan Kepulauan': 1795,
    'Kabupaten Pinrang': 1795,
    'Kabupaten Sidenreng Rappang': 1795,
    'Kabupaten Sinjai': 1795,
    'Kabupaten Soppeng': 1795,
    'Kabupaten Takalar': 1795,
    'Kabupaten Tana Toraja': 1795,
    'Kabupaten Toraja Utara': 1795,
    'Kabupaten Wajo': 1795,
    'Kota Makassar': 1795,
    'Kota Palopo': 1795,
    'Kota Parepare': 1795,
    'Makassar': 1795,
    'Maros': 1795,
    'Palopo': 1795,
    'Parepare': 1795,
    'Rantepao': 1795,
    'Selayar Islands Regency': 1795,
    'Sengkang': 1795,
    'Sinjai': 1795,
    'Watampone': 1795,
    'Baturaja': 1816,
    'Kabupaten Empat Lawang': 1816,
    'Kabupaten Muara Enim': 1816,
    'Kabupaten Musi Banyuasin': 1816,
    'Kabupaten Musi Rawas': 1816,
    'Kabupaten Musi Rawas Utara': 1816,
    'Kabupaten Ogan Ilir': 1816,
    'Kabupaten Ogan Komering Ilir': 1816,
    'Kabupaten Ogan Komering Ulu': 1816,
    'Kabupaten Ogan Komering Ulu Selatan': 1816,
    'Kabupaten Ogan Komering Ulu Timur': 1816,
    'Kabupaten Penukal Abab Lematang Ilir': 1816,
    'Kota Lubuklinggau': 1816,
    'Kota Pagar Alam': 1816,
    'Kota Palembang': 1816,
    'Kota Prabumulih': 1816,
    'Lahat': 1816,
    'Lahat Regency': 1816,
    'Lubuklinggau': 1816,
    'Pagar Alam': 1816,
    'Palembang': 1816,
    'Prabumulih': 1816,
    'Tanjungagung': 1816,
    'Kabupaten Bombana': 1796,
    'Kabupaten Buton': 1796,
    'Kabupaten Buton Selatan': 1796,
    'Kabupaten Buton Tengah': 1796,
    'Kabupaten Buton Utara': 1796,
    'Kabupaten Kolaka': 1796,
    'Kabupaten Kolaka Timur': 1796,
    'Kabupaten Kolaka Utara': 1796,
    'Kabupaten Konawe': 1796,
    'Kabupaten Konawe Kepulauan': 1796,
    'Kabupaten Konawe Selatan': 1796,
    'Kabupaten Konawe Utara': 1796,
    'Kabupaten Muna': 1796,
    'Kabupaten Muna Barat': 1796,
    'Katabu': 1796,
    'Kendari': 1796,
    'Kota Baubau': 1796,
    'Kota Kendari': 1796,
    'Wakatobi Regency': 1796,
    'Bambanglipuro': 1829,
    'Bantul': 1829,
    'Depok': 1829,
    'Gamping Lor': 1829,
    'Godean': 1829,
    'Kabupaten Bantul': 1829,
    'Kabupaten Gunung Kidul': 1829,
    'Kabupaten Kulon Progo': 1829,
    'Kabupaten Sleman': 1829,
    'Kasihan': 1829,
    'Kota Yogyakarta': 1829,
    'Melati': 1829,
    'Pandak': 1829,
    'Pundong': 1829,
    'Sewon': 1829,
    'Sleman': 1829,
    'Srandakan': 1829,
    'Yogyakarta': 1829,
    'Arjawinangun': 1825,
    'Astanajapura': 1825,
    'Bandung': 1825,
    'Banjar': 1825,
    'Banjaran': 1825,
    'Bekasi': 1825,
    'Bogor': 1825,
    'Caringin': 1825,
    'Ciamis': 1825,
    'Ciampea': 1825,
    'Cibinong': 1825,
    'Cicurug': 1825,
    'Cikampek': 1825,
    'Cikarang': 1825,
    'Cikupa': 1825,
    'Cileungsir': 1825,
    'Cileunyi': 1825,
    'Cimahi': 1825,
    'Ciputat': 1825,
    'Ciranjang-hilir': 1825,
    'Cirebon': 1825,
    'Citeureup': 1825,
    'Depok': 1825,
    'Indramayu': 1825,
    'Jatibarang': 1825,
    'Jatiwangi': 1825,
    'Kabupaten Bandung': 1825,
    'Kabupaten Bandung Barat': 1825,
    'Kabupaten Bekasi': 1825,
    'Kabupaten Bogor': 1825,
    'Kabupaten Ciamis': 1825,
    'Kabupaten Cianjur': 1825,
    'Kabupaten Cirebon': 1825,
    'Kabupaten Garut': 1825,
    'Kabupaten Indramayu': 1825,
    'Kabupaten Karawang': 1825,
    'Kabupaten Kuningan': 1825,
    'Kabupaten Majalengka': 1825,
    'Kabupaten Pangandaran': 1825,
    'Kabupaten Purwakarta': 1825,
    'Kabupaten Subang': 1825,
    'Kabupaten Sukabumi': 1825,
    'Kabupaten Sumedang': 1825,
    'Kabupaten Tasikmalaya': 1825,
    'Karangampel': 1825,
    'Karangsembung': 1825,
    'Kawalu': 1825,
    'Klangenan': 1825,
    'Kota Bandung': 1825,
    'Kota Banjar': 1825,
    'Kota Bekasi': 1825,
    'Kota Bogor': 1825,
    'Kota Cimahi': 1825,
    'Kota Cirebon': 1825,
    'Kota Depok': 1825,
    'Kota Sukabumi': 1825,
    'Kota Tasikmalaya': 1825,
    'Kresek': 1825,
    'Kuningan': 1825,
    'Lembang': 1825,
    'Majalengka': 1825,
    'Margahayukencana': 1825,
    'Ngawi': 1825,
    'Padalarang': 1825,
    'Palimanan': 1825,
    'Pamanukan': 1825,
    'Pameungpeuk': 1825,
    'Pamulang': 1825,
    'Parung': 1825,
    'Pasarkemis': 1825,
    'Paseh': 1825,
    'Pelabuhanratu': 1825,
    'Plumbon': 1825,
    'Purwakarta': 1825,
    'Rajapolah': 1825,
    'Rengasdengklok': 1825,
    'Sawangan': 1825,
    'Sepatan': 1825,
    'Serpong': 1825,
    'Singaparna': 1825,
    'Soreang': 1825,
    'Sukabumi': 1825,
    'Sumber': 1825,
    'Sumedang': 1825,
    'Sumedang Utara': 1825,
    'Tasikmalaya': 1825,
    'Teluknaga': 1825,
    'Wanaraja': 1825,
    'Weru': 1825,
    'Bima': 1814,
    'Dompu': 1814,
    'Gili Air': 1814,
    'Kabupaten Bima': 1814,
    'Kabupaten Dompu': 1814,
    'Kabupaten Lombok Barat': 1814,
    'Kabupaten Lombok Tengah': 1814,
    'Kabupaten Lombok Timur': 1814,
    'Kabupaten Lombok Utara': 1814,
    'Kabupaten Sumbawa': 1814,
    'Kabupaten Sumbawa Barat': 1814,
    'Kota Bima': 1814,
    'Kota Mataram': 1814,
    'Labuan Lombok': 1814,
    'Lembar': 1814,
    'Mataram': 1814,
    'Pemenang': 1814,
    'Pototano': 1814,
    'Praya': 1814,
    'Senggigi': 1814,
    'Sumbawa Besar': 1814,
    'Kabupaten Fakfak': 1799,
    'Kabupaten Kaimana': 1799,
    'Kabupaten Manokwari': 1799,
    'Kabupaten Manokwari Selatan': 1799,
    'Kabupaten Maybrat': 1799,
    'Kabupaten Raja Ampat': 1799,
    'Kabupaten Sorong': 1799,
    'Kabupaten Sorong Selatan': 1799,
    'Kabupaten Tambrauw': 1799,
    'Kabupaten Teluk Bintuni': 1799,
    'Kabupaten Teluk Wondama': 1799,
    'Kota Sorong': 1799,
    'Manokwari': 1799,
    'Sorong': 1799,
    'Kabupaten Majene': 1817,
    'Kabupaten Mamasa': 1817,
    'Kabupaten Mamuju': 1817,
    'Kabupaten Mamuju Tengah': 1817,
    'Kabupaten Mamuju Utara': 1817,
    'Kabupaten Polewali Mandar': 1817,
    'Majene': 1817,
    'Mamuju': 1817,
    'Polewali': 1817,
    'Bukittinggi': 1828,
    'Kabupaten Agam': 1828,
    'Kabupaten Dharmasraya': 1828,
    'Kabupaten Kepulauan Mentawai': 1828,
    'Kabupaten Lima Puluh Kota': 1828,
    'Kabupaten Padang Pariaman': 1828,
    'Kabupaten Pasaman': 1828,
    'Kabupaten Pasaman Barat': 1828,
    'Kabupaten Pesisir Selatan': 1828,
    'Kabupaten Sijunjung': 1828,
    'Kabupaten Solok': 1828,
    'Kabupaten Solok Selatan': 1828,
    'Kabupaten Tanah Datar': 1828,
    'Kota Bukittinggi': 1828,
    'Kota Padang': 1828,
    'Kota Padang Panjang': 1828,
    'Kota Pariaman': 1828,
    'Kota Payakumbuh': 1828,
    'Kota Sawah Lunto': 1828,
    'Kota Solok': 1828,
    'Padang': 1828,
    'Pariaman': 1828,
    'Payakumbuh': 1828,
    'Sijunjung': 1828,
    'Solok': 1828,
    'Fardis': 3929,
    'Karaj': 3929,
    'Naz̧arābād': 3929,
    'Shahrestān-e Eshtehārd': 3929,
    'Shahrestān-e Naz̧arābād': 3929,
    'Shahrestān-e Sāvojbolāgh': 3929,
    'Shahrestān-e Ţāleqān': 3929,
    'Ardabīl': 3934,
    'Bīleh Savār': 3934,
    'Khalkhāl': 3934,
    'Omīdcheh': 3934,
    'Pārsābād': 3934,
    'Shahrestān-e Ardabīl': 3934,
    'Shahrestān-e Bīleh Savār': 3934,
    'Shahrestān-e Germī': 3934,
    'Shahrestān-e Khalkhāl': 3934,
    'Shahrestān-e Kows̄ar': 3934,
    'Shahrestān-e Meshgīn Shahr': 3934,
    'Shahrestān-e Namīn': 3934,
    'Shahrestān-e Nīr': 3934,
    'Shahrestān-e Pārsābād': 3934,
    'Shahrestān-e Sar‘eyn': 3934,
    'Ahram': 3932,
    'Bandar-e Genāveh': 3932,
    'Borāzjān': 3932,
    'Bushehr': 3932,
    'Deylam': 3932,
    'Khārk': 3932,
    'Shahrestān-e ‘Asalūyeh': 3932,
    'Shahrestān-e Būshehr': 3932,
    'Shahrestān-e Dashtestān': 3932,
    'Shahrestān-e Dashtī': 3932,
    'Shahrestān-e Genāveh': 3932,
    'Shahrestān-e Kangān': 3932,
    'Shahrestān-e Tangestān': 3932,
    'Ben': 3921,
    'Borūjen': 3921,
    'Chelgard': 3921,
    'Farrokh Shahr': 3921,
    'Fārsān': 3921,
    'Saman': 3921,
    'Shahr-e Kord': 3921,
    'Shahrekord': 3921,
    'Shahrestān-e Borūjen': 3921,
    'Shahrestān-e Fārsān': 3921,
    'Shahrestān-e Kīār': 3921,
    'Shahrestān-e Kūhrang': 3921,
    'Shahrestān-e Lordegān': 3921,
    '‘Ajab Shīr': 3944,
    'Ahar': 3944,
    'Bonāb': 3944,
    'Hashtrūd': 3944,
    'Marand': 3944,
    'Sarāb': 3944,
    'Shahrestān-e ‘Ajab Shīr': 3944,
    'Shahrestān-e Āz̄arshahr': 3944,
    'Shahrestān-e Bonāb': 3944,
    'Shahrestān-e Bostānābād': 3944,
    'Shahrestān-e Chārāvīmāq': 3944,
    'Shahrestān-e Hashtrūd': 3944,
    'Shahrestān-e Herīs': 3944,
    'Shahrestān-e Jolfā': 3944,
    'Shahrestān-e Khodā Āfarīn': 3944,
    'Shahrestān-e Malekān': 3944,
    'Shahrestān-e Marāgheh': 3944,
    'Shahrestān-e Mīāneh': 3944,
    'Shahrestān-e Oskū': 3944,
    'Shahrestān-e Sarāb': 3944,
    'Shahrestān-e Tabrīz': 3944,
    'Shahrestān-e Varzaqān': 3944,
    'Tabriz': 3944,
    'Ābādeh': 3939,
    'Akbarābād': 3939,
    'Dārāb': 3939,
    'Fasā': 3939,
    'Fīrūzābād': 3939,
    'Gerāsh': 3939,
    'Kāzerūn': 3939,
    'Mamasanī': 3939,
    'Marvdasht': 3939,
    'Mohr': 3939,
    'Neyrīz': 3939,
    'Nūrābād': 3939,
    'Pāsārgād': 3939,
    'Rostam': 3939,
    'Shahr-e Qadīm-e Lār': 3939,
    'Shahrestān-e Ābādeh': 3939,
    'Shahrestān-e Arsanjān': 3939,
    'Shahrestān-e Bavānāt': 3939,
    'Shahrestān-e Dārāb': 3939,
    'Shahrestān-e Eqlīd': 3939,
    'Shahrestān-e Estahbān': 3939,
    'Shahrestān-e Farāshband': 3939,
    'Shahrestān-e Fasā': 3939,
    'Shahrestān-e Fīrūzābād': 3939,
    'Shahrestān-e Gerāsh': 3939,
    'Shahrestān-e Kavār': 3939,
    'Shahrestān-e Kāzerūn': 3939,
    'Shahrestān-e Kherāmeh': 3939,
    'Shahrestān-e Khorrambīd': 3939,
    'Shahrestān-e Lāmerd': 3939,
    'Shahrestān-e Lārestān': 3939,
    'Shahrestān-e Neyrīz': 3939,
    'Shahrestān-e Pāsārgād': 3939,
    'Shahrestān-e Qīr va Kārzīn': 3939,
    'Shahrestān-e Sarvestān': 3939,
    'Shahrestān-e Sepīdān': 3939,
    'Shahrestān-e Shīrāz': 3939,
    'Shahrestān-e Zarrīn Dasht': 3939,
    'Shiraz': 3939,
    'Āstāneh-ye Ashrafīyeh': 3920,
    'Āstārā': 3920,
    'Bandar-e Anzalī': 3920,
    'Fūman': 3920,
    'Hashtpar': 3920,
    'Langarūd': 3920,
    'Pādegān-e Manjīl': 3920,
    'Rasht': 3920,
    'Reẕvānshahr': 3920,
    'Rūdsar': 3920,
    'Shahrestān-e Āstāneh-ye Ashrafīyeh': 3920,
    'Shahrestān-e Āstārā': 3920,
    'Shahrestān-e Bandar-e Anzalī': 3920,
    'Shahrestān-e Fūman': 3920,
    'Shahrestān-e Lāhījān': 3920,
    'Shahrestān-e Langarūd': 3920,
    'Shahrestān-e Māsāl': 3920,
    'Shahrestān-e Reẕvānshahr': 3920,
    'Shahrestān-e Rūdbār': 3920,
    'Shahrestān-e Rūdsar': 3920,
    'Shahrestān-e Sīāhkal': 3920,
    'Shahrestān-e Şowme‘eh Sarā': 3920,
    'Shahrestān-e Tālesh': 3920,
    'Ziabar (Gaskar)': 3920,
    'Āq Qāyeh': 3933,
    'Āzādshahr': 3933,
    'Gonbad-e Kāvūs': 3933,
    'Gorgān': 3933,
    'Kalāleh': 3933,
    'Qarnābād': 3933,
    'Shahrestān-e ‘Alīābād': 3933,
    'Shahrestān-e Āq Qalā': 3933,
    'Shahrestān-e Āzādshahr': 3933,
    'Shahrestān-e Gālīkesh': 3933,
    'Shahrestān-e Gomīshān': 3933,
    'Shahrestān-e Gonbad-e Kāvūs': 3933,
    'Shahrestān-e Gorgān': 3933,
    'Shahrestān-e Kalāleh': 3933,
    'Shahrestān-e Kordkūy': 3933,
    'Shahrestān-e Marāveh Tappeh': 3933,
    'Shahrestān-e Mīnūdasht': 3933,
    'Shahrestān-e Rāmīān': 3933,
    'Torkaman': 3933,
    'Ab Meshkin': 4920,
    'Abarlaq-e Sofla': 4920,
    'Abdalan': 4920,
    'Abdol Rahim': 4920,
    'Abrumand': 4920,
    'Abshineh': 4920,
    'Abu ol Fathabad': 4920,
    'Aghcheh Qayah': 4920,
    'Ahmadabad-e Tappeh': 4920,
    'Ahu Tappeh': 4920,
    'Ajin': 4920,
    'Ajorlu': 4920,
    'Akanlu': 4920,
    'Akleh': 4920,
    'Alan-e Sofla': 4920,
    'Alanjeh': 4920,
    'Alfavut': 4920,
    'Aliabad-e Posht Shahr': 4920,
    'Amirabad-e Kord': 4920,
    'Amzajerd': 4920,
    'Angoshteh': 4920,
    'Anjir Baghi': 4920,
    'Ansar ol Emam': 4920,
    'Anuch': 4920,
    'Aq Bolagh-e Aqdaq': 4920,
    'Aq Bolagh-e Latgah': 4920,
    'Aq Bolagh-e Morshed': 4920,
    'Aq Chay': 4920,
    'Aqa Bolaghi': 4920,
    'Aqa Jan Bolaghi': 4920,
    'Aqaj': 4920,
    'Aqcheh Kharabeh': 4920,
    'Aqchelu': 4920,
    'Arges-e Sofla': 4920,
    'Arikan': 4920,
    'Arpa Darreh': 4920,
    'Arteh Bolagh': 4920,
    'Artiman': 4920,
    'Arzan Fud': 4920,
    'Arzuvaj': 4920,
    'Asadabad': 4920,
    'Ashmizan': 4920,
    'Avarzaman': 4920,
    'Azandarian': 4920,
    'Aznahri': 4920,
    'Baba Khanjar': 4920,
    'Baba Pir': 4920,
    'Baba Qasem': 4920,
    'Babolqani': 4920,
    'Bad Khvoreh': 4920,
    'Bahar': 4920,
    'Band Aman': 4920,
    'Banesareh': 4920,
    'Baraband': 4920,
    'Bargecheh': 4920,
    'Barreh Farakh': 4920,
    'Barzul': 4920,
    'Bashqurtaran': 4920,
    'Bazeran': 4920,
    'Behkandan': 4920,
    'Belartu': 4920,
    'Beshik Tappeh': 4920,
    'Biaj': 4920,
    'Biatan-e Sofla': 4920,
    'Bid Korpeh-ye Sofla': 4920,
    'Bid Korpeh-ye Vosta': 4920,
    'Bifanaj': 4920,
    'Bighash': 4920,
    'Bish Aghaj': 4920,
    'Bitervan': 4920,
    'Borjaki': 4920,
    'Boyukabad': 4920,
    'Bozchalu': 4920,
    'Bozineh Jerd': 4920,
    'Bujin': 4920,
    'Bustandar': 4920,
    'Buyaqchi': 4920,
    'Chahar Bolagh': 4920,
    'Chal Boqa': 4920,
    'Chaleh Kand': 4920,
    'Charoq': 4920,
    'Chasht Khvoreh': 4920,
    'Chenari': 4920,
    'Cheshmeh Malek': 4920,
    'Cheshmeh Pahn-e Nanaj': 4920,
    'Cheshmeh Qandab': 4920,
    'Cheshmeh Qareh': 4920,
    'Cheshmeh Qassaban': 4920,
    'Cheshmeh Valad': 4920,
    'Cheshmeh Zowraq': 4920,
    'Choqa Sarahi': 4920,
    'Chowtash': 4920,
    'Chulak Qapanuri': 4920,
    'Dahnejerd': 4920,
    'Dali Chu': 4920,
    'Damaq': 4920,
    'Daqdaqabad': 4920,
    'Dar Qeshlaq': 4920,
    'Darani-ye Sofla': 4920,
    'Darcham': 4920,
    'Darreh Chenar': 4920,
    'Darreh Mianeh-ye Sofla': 4920,
    'Darreh Mirza': 4920,
    'Darreh-ye Ebrahim': 4920,
    'Darsibeh': 4920,
    'Davijan': 4920,
    'Deh Chaneh': 4920,
    'Deh Dalian': 4920,
    'Deh Ful': 4920,
    'Deh Mianeh': 4920,
    'Deh Nush': 4920,
    'Deh Piaz': 4920,
    'Deh-e Shaker': 4920,
    'Dehnow-e Avarzaman': 4920,
    'Dingeleh Kahriz': 4920,
    'Do Rudan': 4920,
    'Doraneh': 4920,
    'Dorudgaran': 4920,
    'Dow Baraleh': 4920,
    'Dowlatabad-e Hajjilu': 4920,
    'Dowlujerdin-e Bala': 4920,
    'Durijan': 4920,
    'Durnian': 4920,
    'Emamzadeh Khatun': 4920,
    'Emamzadeh Pir Nahan': 4920,
    'Eskanan': 4920,
    'Fahrumand': 4920,
    'Famast': 4920,
    'Famenin': 4920,
    'Farasfaj': 4920,
    'Farsban': 4920,
    'Farvaz': 4920,
    'Faryazan': 4920,
    'Fasijan': 4920,
    'Fiazaman': 4920,
    'Firuzabad-e Sofla': 4920,
    'Firuzan': 4920,
    'Gamasa': 4920,
    'Gamasb': 4920,
    'Ganj Tappeh': 4920,
    'Ganjab': 4920,
    'Gashani': 4920,
    'Gav Zaban': 4920,
    'Gavkaran': 4920,
    'Ghazyatan': 4920,
    'Gholam Veys': 4920,
    'Givaki': 4920,
    'Giyan': 4920,
    'Gol Darreh-ye Anuch': 4920,
    'Gol Heydar': 4920,
    'Golparabad': 4920,
    'Golshir': 4920,
    'Golushjerd': 4920,
    'Gomush Bolagh': 4920,
    'Gonbad Chay': 4920,
    'Gonbad-e Kabud': 4920,
    'Gostar': 4920,
    'Gozal Abdal': 4920,
    'Gug Qash': 4920,
    'Gujak': 4920,
    'Gulvand': 4920,
    'Gunestan': 4920,
    'Gur Gaz': 4920,
    'Gurjiu': 4920,
    'Gushalan': 4920,
    'Gusheh-ye Kasavand': 4920,
    'Hajji Kheder': 4920,
    'Hajji Maqsud': 4920,
    'Hajji Tu': 4920,
    'Hamadan': 4920,
    'Hamilabad': 4920,
    'Hamzehlu-ye Sofla': 4920,
    'Haramabad': 4920,
    'Hasan Kusej': 4920,
    'Hasan Teymur': 4920,
    'Hasanabad-e Emam': 4920,
    'Hasanabad-e Qush Bolagh': 4920,
    'Hasanabad-e Sheverin': 4920,
    'Heriraz': 4920,
    'Hesar-e Qarah Baghi': 4920,
    'Hesar-e Qujeh Baghi': 4920,
    'Heydareh-ye Dar-e Emam': 4920,
    'Heydareh-ye Posht-e Shahr': 4920,
    'Hizaj': 4920,
    'Holvar-e Sofla': 4920,
    'Hom-e Khosrow': 4920,
    'Horhoreh': 4920,
    'Hoseynabad-e Bahar': 4920,
    'Hoseynabad-e Nazem': 4920,
    'Hoseynabad-e Shamlu': 4920,
    'Hudaraj': 4920,
    'Iraneh': 4920,
    'Isti Bolagh': 4920,
    'Jamishlu': 4920,
    'Jaria': 4920,
    'Javar Sajin': 4920,
    'Jerbanlu': 4920,
    'Jighi': 4920,
    'Jijan Kuh': 4920,
    'Jijian Rud': 4920,
    'Jin Taraqayah': 4920,
    'Juraqan': 4920,
    'Kaboodrahang': 4920,
    'Kahard': 4920,
    'Kahkadan': 4920,
    'Kahriz-e Baba Hoseyn': 4920,
    'Kahriz-e Boqazi': 4920,
    'Kahriz-e Hoseynabad-e Nazem': 4920,
    'Kahriz-e Jamal': 4920,
    'Kahriz-e Salim': 4920,
    'Kal Kabud': 4920,
    'Kalb Hesari': 4920,
    'Kalilabad': 4920,
    'Kamak-e Sofla': 4920,
    'Kamar Boneh': 4920,
    'Kamazan': 4920,
    'Kand Halan': 4920,
    'Kand Tappeh': 4920,
    'Kand-e Bolaghi': 4920,
    'Kangavar-e Kohneh': 4920,
    'Karafs': 4920,
    'Kark-e Sofla': 4920,
    'Kartilabad': 4920,
    'Kasavand': 4920,
    'Kaslan Qayah': 4920,
    'Kavanaj': 4920,
    'Kesb': 4920,
    'Keykaleh': 4920,
    'Keyni Sayeh': 4920,
    'Khabar Arkhi': 4920,
    'Khaku': 4920,
    'Khaleq Verdi': 4920,
    'Khalil Kord': 4920,
    'Kheradmand': 4920,
    'Kheyr Qoli': 4920,
    'Khomeygan': 4920,
    'Khvajeh Hesar': 4920,
    'Khvajeh Hoseyni': 4920,
    'Khvajeh Kandi': 4920,
    'Khvoshab-e Sofla': 4920,
    'Kitu': 4920,
    'Kohnush': 4920,
    'Kolanjan': 4920,
    'Konjineh': 4920,
    'Kord Bolagh': 4920,
    'Korzan': 4920,
    'Kowzareh': 4920,
    'Kuhani': 4920,
    'Kur Kahriz': 4920,
    'Kusaj Khalil': 4920,
    'Kutah Darreh': 4920,
    'Laleh Dan': 4920,
    'Lalejin': 4920,
    'Lamiyan': 4920,
    'Latgah': 4920,
    'Lavashan': 4920,
    'Leyli Yadegar': 4920,
    'Luluhar': 4920,
    'Mahanabad': 4920,
    'Mahbar': 4920,
    'Mahdaviyeh': 4920,
    'Mahnian': 4920,
    'Malayer': 4920,
    'Malek-e Ashtar': 4920,
    'Malusan': 4920,
    'Mangavi': 4920,
    'Marvil': 4920,
    'Maryanaj': 4920,
    'Masjedin': 4920,
    'Menjan': 4920,
    'Mesinak': 4920,
    'Meyvaleh': 4920,
    'Mianzulan': 4920,
    'Mihamleh-ye Sofla': 4920,
    'Milab': 4920,
    'Mirza Hesari': 4920,
    'Mishen': 4920,
    'Mobarakin': 4920,
    'Mohajeran': 4920,
    'Mohammadabad-e Chulak': 4920,
    'Mohammadiyeh-e Sofla': 4920,
    'Mohara': 4920,
    'Mokarrabi': 4920,
    'Molham Darreh': 4920,
    'Molla Bodagh': 4920,
    'Monavvar Tappeh': 4920,
    'Morghabad': 4920,
    'Mowdaran': 4920,
    'Musa Bolaghi': 4920,
    'Nahandar': 4920,
    'Nahavand': 4920,
    'Najafiyeh': 4920,
    'Nakilabad': 4920,
    'Nakin': 4920,
    'Namileh': 4920,
    'Nanaj': 4920,
    'Nazul': 4920,
    'Negar Khatun': 4920,
    'Negarabad': 4920,
    'Nehenjeh': 4920,
    'Nesar': 4920,
    'Neshar': 4920,
    'Niyanj': 4920,
    'Nosratabad-e Behraz': 4920,
    'Nosratabad-e Laklak': 4920,
    'Nurabad-e Simineh': 4920,
    'Ojaq': 4920,
    'Ommatlar': 4920,
    'Oshtoran': 4920,
    'Oshtorjin': 4920,
    'Ovraqin': 4920,
    'Owrteh Qamish': 4920,
    'Owryad': 4920,
    'Pahnabad': 4920,
    'Panbeh Dar': 4920,
    'Parluk': 4920,
    'Parvaraq': 4920,
    'Payandeh': 4920,
    'Peyhan': 4920,
    'Pileh Jin': 4920,
    'Pir Anbar': 4920,
    'Pir Badam': 4920,
    'Pir Khodaverdi': 4920,
    'Pir Malu': 4920,
    'Pir Mishan': 4920,
    'Pir Savaran': 4920,
    'Pir Yusof': 4920,
    'Piruz': 4920,
    'Poshtjin': 4920,
    'Qabanqoli': 4920,
    'Qader Khalaj': 4920,
    'Qadimi': 4920,
    'Qahavand': 4920,
    'Qajar Ab-e Sofla': 4920,
    'Qarah Day': 4920,
    'Qarah Tegini': 4920,
    'Qaranqu Darreh': 4920,
    'Qasabestan': 4920,
    'Qasemabad-e Laklak': 4920,
    'Qater Owlan': 4920,
    'Qayesh': 4920,
    'Qazan Qarah': 4920,
    'Qazi Mardan': 4920,
    'Qeshlaq Baba Rostam': 4920,
    'Qeshlaq-e Anuch': 4920,
    'Qeshlaq-e Deh Ful': 4920,
    'Qeshlaq-e Dehnow': 4920,
    'Qeshlaq-e Mohammadi': 4920,
    'Qeshlaq-e Najaf': 4920,
    'Qeshlaq-e Pust Shuran': 4920,
    'Qeshlaq-e Qobad': 4920,
    'Qeshlaq-e Shirazi': 4920,
    'Qeshlaq-e Sofla': 4920,
    'Qeshlaq-e Valiollah': 4920,
    'Qeydli Bolagh': 4920,
    'Qilab': 4920,
    'Qohurd-e Sofla': 4920,
    'Qoli Laleh-ye Sofla': 4920,
    'Qolqolabad': 4920,
    'Quch Tappeh': 4920,
    'Qushjeh': 4920,
    'Rahmanabad, Malayer': 4920,
    'Ramishan': 4920,
    'Rastguyan': 4920,
    'Rasulabad-e Sofla': 4920,
    'Ravand-e Vosta': 4920,
    'Rayegan-e Sofla': 4920,
    'Razaj': 4920,
    'Razan': 4920,
    'Razini': 4920,
    'Reza Baghi': 4920,
    'Rezvankadeh': 4920,
    'Rivasijan': 4920,
    'Robat-e Sheverin': 4920,
    'Rud Avar': 4920,
    'Rud-e Hasan-e Sofla': 4920,
    'Safa Riz': 4920,
    'Sahamabad': 4920,
    'Salehabad': 4920,
    'Salilak': 4920,
    'Saluk': 4920,
    'Samen': 4920,
    'Sanaj': 4920,
    'Sanjuzan': 4920,
    'Sar Duran': 4920,
    'Sarab-e Tajar': 4920,
    'Saravak': 4920,
    'Sarayjuq': 4920,
    'Sarijlu': 4920,
    'Sarkan': 4920,
    'Saziyan': 4920,
    'Sefid Khaneh': 4920,
    'Segavi': 4920,
    'Setaq': 4920,
    'Shademaneh': 4920,
    'Shanabad': 4920,
    'Shanjur': 4920,
    'Sharifabad-e Quzan': 4920,
    'Sharifabad-e Tajar': 4920,
    'Shatzal': 4920,
    'Shavand': 4920,
    'Sheverin': 4920,
    'Sheykh Jarrah': 4920,
    'Shilandar-e Sofla': 4920,
    'Shir Barat': 4920,
    'Shirin Su': 4920,
    'Shush Ab': 4920,
    'Siah Goleh': 4920,
    'Sib Dar': 4920,
    'Silvar': 4920,
    'Simin-e Abaru': 4920,
    'Simin-e Zagheh': 4920,
    'Siravand': 4920,
    'Sistaneh': 4920,
    'Solulan': 4920,
    'Sorkh Kand': 4920,
    'Sulijeh': 4920,
    'Surtajin': 4920,
    'Sutlaq': 4920,
    'Tafrijan': 4920,
    'Tahunabad': 4920,
    'Tajar-e Samen': 4920,
    'Takhtiabad': 4920,
    'Taleh Jerd-e Sofla': 4920,
    'Tamuzan': 4920,
    'Tappeh Yazdan': 4920,
    'Tappeh-ye Dibi': 4920,
    'Taraqayah': 4920,
    'Tarik Darreh-ye Bala': 4920,
    'Tarkhinabad': 4920,
    'Tas Tappeh': 4920,
    'Tasbandi': 4920,
    'Tasran': 4920,
    'Tavaleh': 4920,
    'Tavaneh': 4920,
    'Taveh': 4920,
    'Tavilan-e Sofla': 4920,
    'Tazehnab-e Sofla': 4920,
    'Termianak': 4920,
    'Timi Jan': 4920,
    'Tuchaqaz': 4920,
    'Tulki Tappeh': 4920,
    'Tusk-e Sofla': 4920,
    'Tutal': 4920,
    'Tuyserkan': 4920,
    'Vafr-e Jin': 4920,
    'Vahandeh': 4920,
    'Vahman': 4920,
    'Vahnan': 4920,
    'Vali Mohammad': 4920,
    'Vali Sir Ab': 4920,
    'Vandarabad': 4920,
    'Varayeneh': 4920,
    'Varazaneh': 4920,
    'Varchaq': 4920,
    'Vardavard-e Sofla': 4920,
    'Vardavard-e Vosta': 4920,
    'Varkaneh': 4920,
    'Varqestan': 4920,
    'Varvazin': 4920,
    'Vasaj': 4920,
    'Vashur': 4920,
    'Vasleh': 4920,
    'Vesht': 4920,
    'Vezendan': 4920,
    'Yalfan': 4920,
    'Yarom Qayah': 4920,
    'Yaromjeh Bagh': 4920,
    'Yeganeh': 4920,
    'Yekanabad': 4920,
    'Yekleh': 4920,
    'Yeserlu': 4920,
    'Yunes': 4920,
    'Yunji': 4920,
    'Zagheh-ye Anuch': 4920,
    'Zagheh-ye Tasbandi': 4920,
    'Zaghlijeh': 4920,
    'Zamanabad-e Mohammadabad': 4920,
    'Zangeneh-ye Sofla': 4920,
    'Zapon': 4920,
    'Zaramin-e Sofla': 4920,
    'Zarrin Bagh': 4920,
    'Zeraq': 4920,
    'Zirabiyeh': 4920,
    'Bandar Abbas': 3937,
    'Bandar Lengeh': 3937,
    'Bandar-e Lengeh': 3937,
    'Bastak': 3937,
    'Kīsh': 3937,
    'Mīnāb': 3937,
    'Qeshm': 3937,
    'Shahrestān-e Abū Mūsá': 3937,
    'Shahrestān-e Bandar ‘Abbās': 3937,
    'Shahrestān-e Bashāgard': 3937,
    'Shahrestān-e Ḩājjīābād': 3937,
    'Shahrestān-e Jāsk': 3937,
    'Shahrestān-e Khamīr': 3937,
    'Shahrestān-e Mīnāb': 3937,
    'Shahrestān-e Pārsīān': 3937,
    'Shahrestān-e Rūdān': 3937,
    'Shahrestān-e Sīrīk': 3937,
    'Ābdānān': 3918,
    'Badreh': 3918,
    'Chardavol County': 3918,
    'Darreh Shahr': 3918,
    'Darrehshahr': 3918,
    'Dehlorān': 3918,
    'Īlām': 3918,
    'Mehrān': 3918,
    'Shahrestān-e Ābdānān': 3918,
    'Shahrestān-e Dehlorān': 3918,
    'Shahrestān-e Eyvān': 3918,
    'Shahrestān-e Īlām': 3918,
    'Shahrestān-e Malekshāhī': 3918,
    'Shahrestān-e Mehrān': 3918,
    'Sirvan': 3918,
    'Abrīsham': 3923,
    'Ardestān': 3923,
    'Buin va Miandasht': 3923,
    'Chādegān': 3923,
    'Dārān': 3923,
    'Dehāqān': 3923,
    'Dorcheh Pīāz': 3923,
    'Dowlatābād': 3923,
    'Falāvarjān': 3923,
    'Fareydūnshahr': 3923,
    'Fereydan': 3923,
    'Golpāyegān': 3923,
    'Isfahan': 3923,
    'Kelīshād va Sūdarjān': 3923,
    'Khomeynī Shahr': 3923,
    'Khūr': 3923,
    'Khvānsār': 3923,
    'Mobārakeh': 3923,
    'Nā’īn': 3923,
    'Najafābād': 3923,
    'Naţanz': 3923,
    'Qahderījān': 3923,
    'Rehnān': 3923,
    'Semīrom': 3923,
    'Shāhīn Shahr': 3923,
    'Shahrestān-e Ārān va Bīdgol': 3923,
    'Shahrestān-e Ardestān': 3923,
    'Shahrestān-e Borkhvār': 3923,
    'Shahrestān-e Chādegān': 3923,
    'Shahrestān-e Dehāqān': 3923,
    'Shahrestān-e Eşfahān': 3923,
    'Shahrestān-e Falāvarjān': 3923,
    'Shahrestān-e Fareydūnshahr': 3923,
    'Shahrestān-e Golpāyegān': 3923,
    'Shahrestān-e Kāshān': 3923,
    'Shahrestān-e Khomeynī Shahr': 3923,
    'Shahrestān-e Khūr va Bīābānak': 3923,
    'Shahrestān-e Khvānsār': 3923,
    'Shahrestān-e Lenjān': 3923,
    'Shahrestān-e Mobārakeh': 3923,
    'Shahrestān-e Nā’īn': 3923,
    'Shahrestān-e Najafābād': 3923,
    'Shahrestān-e Naţanz': 3923,
    'Shahrestān-e Semīrom': 3923,
    'Shahrestān-e Shāhīn Shahr va Meymeh': 3923,
    'Shahrestān-e Shahreẕā': 3923,
    'Shahrestān-e Tīrān va Karvan': 3923,
    'Shahreẕā': 3923,
    'Tīrān': 3923,
    'Zarrīn Shahr': 3923,
    'Bāft': 3943,
    'Bam': 3943,
    'Bardsīr': 3943,
    'Kerman': 3943,
    'Kūh Sefīd': 3943,
    'Rafsanjān': 3943,
    'Rāvar': 3943,
    'Rīgān': 3943,
    'Shahr-e Bābak': 3943,
    'Shahrak-e Pābedānā': 3943,
    'Shahrestān-e ‘Anbarābād': 3943,
    'Shahrestān-e Anār': 3943,
    'Shahrestān-e Bāft': 3943,
    'Shahrestān-e Bardsīr': 3943,
    'Shahrestān-e Fāryāb': 3943,
    'Shahrestān-e Jīroft': 3943,
    'Shahrestān-e Kahnūj': 3943,
    'Shahrestān-e Kermān': 3943,
    'Shahrestān-e Kūhbanān': 3943,
    'Shahrestān-e Manūjān': 3943,
    'Shahrestān-e Narmāshīr': 3943,
    'Shahrestān-e Orzū‘īyeh': 3943,
    'Shahrestān-e Qal‘eh Ganj': 3943,
    'Shahrestān-e Rābor': 3943,
    'Shahrestān-e Rafsanjān': 3943,
    'Shahrestān-e Rūdbār-e Jonūbī': 3943,
    'Shahrestān-e Shahr-e Bābak': 3943,
    'Shahrestān-e Sīrjān': 3943,
    'Sirjan': 3943,
    'Zarand': 3943,
    'Harsīn': 3919,
    'Javānrūd': 3919,
    'Kahrīz': 3919,
    'Kangāvar': 3919,
    'Kermanshah': 3919,
    'Pāveh': 3919,
    'Sarpol-e Z̄ahāb': 3919,
    'Shahrestān-e Dālāhū': 3919,
    'Shahrestān-e Eslāmābād-e Gharb': 3919,
    'Shahrestān-e Gīlān-e Gharb': 3919,
    'Shahrestān-e Harsīn': 3919,
    'Shahrestān-e Javānrūd': 3919,
    'Shahrestān-e Kangāvar': 3919,
    'Shahrestān-e Kermānshāh': 3919,
    'Shahrestān-e Pāveh': 3919,
    'Shahrestān-e Qaşr-e Shīrīn': 3919,
    'Shahrestān-e Ravānsar': 3919,
    'Shahrestān-e Şaḩneh': 3919,
    'Shahrestān-e S̄alās̄-e Bābā Jānī': 3919,
    'Shahrestān-e Sarpol-e Z̄ahāb': 3919,
    'Sonqor': 3919,
    'Abadan': 3917,
    'Aghajari': 3917,
    'Ahvaz': 3917,
    'Bāgh Shahrestān-e Malek': 3917,
    'Behbahān': 3917,
    'Gotvand': 3917,
    'Hamidiyeh': 3917,
    'Jongīyeh': 3917,
    'Karun': 3917,
    'Khorramshahr': 3917,
    'Masjed Soleymān': 3917,
    'Omīdīyeh': 3917,
    'Rāmhormoz': 3917,
    'Rāmshīr': 3917,
    'sedeyen-e Yek': 3917,
    'Shādegān': 3917,
    'Shahrak-e Kūlūrī': 3917,
    'Shahrestān-e Ābādān': 3917,
    'Shahrestān-e Ahvāz': 3917,
    'Shahrestān-e Andīkā': 3917,
    'Shahrestān-e Andīmeshk': 3917,
    'Shahrestān-e Bandar-e Māhshahr': 3917,
    'Shahrestān-e Bāvī': 3917,
    'Shahrestān-e Behbahān': 3917,
    'Shahrestān-e Dasht-e Āzādegān': 3917,
    'Shahrestān-e Dezfūl': 3917,
    'Shahrestān-e Hendījān': 3917,
    'Shahrestān-e Īz̄eh': 3917,
    'Shahrestān-e Lālī': 3917,
    'Shahrestān-e Masjed Soleymān': 3917,
    'Shahrestān-e Omīdīyeh': 3917,
    'Shahrestān-e Rāmhormoz': 3917,
    'Shahrestān-e Rāmshīr': 3917,
    'Shahrestān-e Shādegān': 3917,
    'Shahrestān-e Shūsh': 3917,
    'Shahrestān-e Shūshtar': 3917,
    'Shūsh': 3917,
    'Shūshtar': 3917,
    'Sūsangerd': 3917,
    'Dehdasht': 3926,
    'Dogonbadan': 3926,
    'Landeh': 3926,
    'Shahrestān-e Bahma’ī': 3926,
    'Shahrestān-e Bāsht': 3926,
    'Shahrestān-e Bowyer Aḩmad': 3926,
    'Shahrestān-e Charām': 3926,
    'Shahrestān-e Danā': 3926,
    'Shahrestān-e Gachsārān': 3926,
    'Shahrestān-e Kohgīlūyeh': 3926,
    'Yasuj': 3926,
    'Bāneh': 3935,
    'Bījār': 3935,
    'Kāmyārān': 3935,
    'Marīvān': 3935,
    'Qorveh': 3935,
    'Sanandaj': 3935,
    'Saqqez': 3935,
    'Shahrestān-e Bāneh': 3935,
    'Shahrestān-e Bījār': 3935,
    'Shahrestān-e Dehgolān': 3935,
    'Shahrestān-e Dīvāndarreh': 3935,
    'Shahrestān-e Kāmyārān': 3935,
    'Shahrestān-e Marīvān': 3935,
    'Shahrestān-e Sarvābād': 3935,
    'Aleshtar': 3928,
    'Alīgūdarz': 3928,
    'Aznā': 3928,
    'Borūjerd': 3928,
    'Delfan': 3928,
    'Khorramabad': 3928,
    'Kūhdasht': 3928,
    'Nūrābād': 3928,
    'Pol-e Dokhtar': 3928,
    'Rumeshkhan County': 3928,
    'Selseleh': 3928,
    'Shahrestān-e Alīgūdarz': 3928,
    'Shahrestān-e Aznā': 3928,
    'Shahrestān-e Borūjerd': 3928,
    'Shahrestān-e Dūreh': 3928,
    'Shahrestān-e Khorramābād': 3928,
    'Shahrestān-e Kūhdasht': 3928,
    'Vasīān': 3928,
    'Ābyek': 3916,
    'Arāk': 3916,
    'Delījān': 3916,
    'Khomeyn': 3916,
    'Komījān': 3916,
    'Sāveh': 3916,
    'Shahrestān-e Arāk': 3916,
    'Shahrestān-e Āshtīān': 3916,
    'Shahrestān-e Delījān': 3916,
    'Shahrestān-e Farahān': 3916,
    'Shahrestān-e Khondāb': 3916,
    'Shahrestān-e Komījān': 3916,
    'Shahrestān-e Maḩallāt': 3916,
    'Shahrestān-e Sāveh': 3916,
    'Shahrestān-e Shāzand': 3916,
    'Shahrestān-e Zarandīyeh': 3916,
    'Tafresh': 3916,
    'Āmol': 3938,
    'Bābol': 3938,
    'Bābolsar': 3938,
    'Behshahr': 3938,
    'Chālūs': 3938,
    'Fereydūnkenār': 3938,
    'Jūybār': 3938,
    'Nashtārūd': 3938,
    'Nekā': 3938,
    'Nowshahr': 3938,
    'Sari': 3938,
    'Savadkuh-e Shomali': 3938,
    'Shahrestān-e ‘Abbāsābād': 3938,
    'Shahrestān-e Āmol': 3938,
    'Shahrestān-e Bābol': 3938,
    'Shahrestān-e Bābolsar': 3938,
    'Shahrestān-e Chālūs': 3938,
    'Shahrestān-e Fareydūnkenār': 3938,
    'Shahrestān-e Galūgāh': 3938,
    'Shahrestān-e Jūybār': 3938,
    'Shahrestan-e Kalār Dasht': 3938,
    'Shahrestān-e Maḩmūdābād': 3938,
    'Shahrestān-e Mīāndorūd': 3938,
    'Shahrestān-e Nekā': 3938,
    'Shahrestān-e Nūr': 3938,
    'Shahrestān-e Qā’em Shahr': 3938,
    'Shahrestān-e Rāmsar': 3938,
    'Shahrestān-e Sārī': 3938,
    'Shahrestān-e Savādkūh': 3938,
    'Shahrestān-e Tonekābon': 3938,
    'Simorgh County': 3938,
    'Tonekābon': 3938,
    'Bojnūrd': 3942,
    'Esfarāyen': 3942,
    'Raz and Jargalan': 3942,
    'Shahrestān-e Bojnūrd': 3942,
    'Shahrestān-e Esfarāyen': 3942,
    'Shahrestān-e Fārūj': 3942,
    'Shahrestān-e Jājarm': 3942,
    'Shahrestān-e Māneh va Samalqān': 3942,
    'Shahrestān-e Shīrvān': 3942,
    'Shīrvān': 3942,
    'Alborz': 3941,
    'Alvand': 3941,
    'Avaj': 3941,
    'Qazvin': 3941,
    'Shahrestān-e Bū’īn Zahrā': 3941,
    'Shahrestān-e Qazvīn': 3941,
    'Shahrestān-e Tākestān': 3941,
    'Tākestān': 3941,
    'Qom': 3922,
    'Bardaskan': 3927,
    'Chenārān': 3927,
    'Dargaz': 3927,
    'Dāvarzan': 3927,
    'Gonābād': 3927,
    'Joveyn': 3927,
    'Kāshmar': 3927,
    'Mashhad': 3927,
    'Neyshābūr': 3927,
    'Qūchān': 3927,
    'Sabzevar': 3927,
    'Sarakhs': 3927,
    'Shahrestān-e Bajestān': 3927,
    'Shahrestān-e Bākharz': 3927,
    'Shahrestān-e Bīnālūd': 3927,
    'Shahrestān-e Chenārān': 3927,
    'Shahrestān-e Farīmān': 3927,
    'Shahrestān-e Fīrūzeh': 3927,
    'Shahrestān-e Gonābād': 3927,
    'Shahrestān-e Joghatāy': 3927,
    'Shahrestān-e Kalāt': 3927,
    'Shahrestān-e Kāshmar': 3927,
    'Shahrestān-e Khalīlābād': 3927,
    'Shahrestān-e Khowshāb': 3927,
    'Shahrestān-e Khvāf': 3927,
    'Shahrestān-e Mah Velāt': 3927,
    'Shahrestān-e Neyshābūr': 3927,
    'Shahrestān-e Qūchān': 3927,
    'Shahrestān-e Roshtkhvār': 3927,
    'Shahrestān-e Sabzevār': 3927,
    'Shahrestān-e Tāybād': 3927,
    'Shahrestān-e Torbat-e Ḩeydarīyeh': 3927,
    'Shahrestān-e Torbat-e Jām': 3927,
    'Shahrestān-e Zāveh': 3927,
    'Tāybād': 3927,
    'Torbat-e Ḩeydarīyeh': 3927,
    'Torbat-e Jām': 3927,
    'Dāmghān': 3940,
    'Īstgāh-e Rāh Āhan-e Garmsār': 3940,
    'Mahdishahr': 3940,
    'Semnan': 3940,
    'Shahrestān-e Ārādān': 3940,
    'Shahrestān-e Dāmghān': 3940,
    'Shahrestān-e Garmsār': 3940,
    'Shahrestān-e Mayāmey': 3940,
    'Shahrestān-e Semnān': 3940,
    'Shahrestān-e Shāhrūd': 3940,
    'Shahrud': 3940,
    'Chabahar': 3931,
    'Fannūj': 3931,
    'Fanuj': 3931,
    'Hamoon': 3931,
    'Iranshahr': 3931,
    'Khāsh': 3931,
    'Mirjaveh': 3931,
    'Nīkshahr': 3931,
    'Nimruz': 3931,
    'Noşratābād': 3931,
    'Qaser-e Qand': 3931,
    'Qaşr-e Qand': 3931,
    'Shahrestān-e Chābahār': 3931,
    'Shahrestān-e Dalgān': 3931,
    'Shahrestān-e Hīrmand': 3931,
    'Shahrestān-e Īrānshahr': 3931,
    'Shahrestān-e Khāsh': 3931,
    'Shahrestān-e Konārak': 3931,
    'Shahrestān-e Mehrestān': 3931,
    'Shahrestān-e Nīkshahr': 3931,
    'Shahrestān-e Sarāvān': 3931,
    'Shahrestān-e Sarbāz': 3931,
    'Shahrestān-e Sīb va Sūrān': 3931,
    'Shahrestān-e Zābol': 3931,
    'Shahrestān-e Zāhedān': 3931,
    'Zābol': 3931,
    'Zahedan': 3931,
    'Zehak': 3931,
    'Bīrjand': 3930,
    'Boshrūyeh': 3930,
    'Qā’en': 3930,
    'Shahrestān-e Bīrjand': 3930,
    'Shahrestān-e Darmīān': 3930,
    'Shahrestān-e Khūsf': 3930,
    'Shahrestān-e Nehbandān': 3930,
    'Shahrestān-e Qā’en': 3930,
    'Shahrestān-e Sarāyān': 3930,
    'Shahrestān-e Sarbīsheh': 3930,
    'Shahrestān-e Zīrkūh': 3930,
    'Tabas': 3930,
    'Damāvand': 3945,
    'Eqbālīyeh': 3945,
    'Eslāmshahr': 3945,
    'Malārd': 3945,
    'Pardis': 3945,
    'Pīshvā': 3945,
    'Qarchak': 3945,
    'Qods': 3945,
    'Rey': 3945,
    'Robāţ Karīm': 3945,
    'Shahrak-e Emām Ḩasan': 3945,
    'Shahre Jadide Andisheh': 3945,
    'Shahrestān-e Bahārestān': 3945,
    'Shahrestān-e Damāvand': 3945,
    'Shahrestān-e Eslāmshahr': 3945,
    'Shahrestān-e Fīrūzkūh': 3945,
    'Shahrestān-e Malārd': 3945,
    'Shahrestān-e Pākdasht': 3945,
    'Shahrestān-e Pīshvā': 3945,
    'Shahrestān-e Robāţ Karīm': 3945,
    'Shahrestān-e Shahrīār': 3945,
    'Shahrestān-e Shemīrānāt': 3945,
    'Shahrestān-e Tehrān': 3945,
    'Shahrestān-e Varāmīn': 3945,
    'Shahrīār': 3945,
    'Sharīfābād': 3945,
    'Soleh Bon': 3945,
    'Ţāleb ābād': 3945,
    'Tehran': 3945,
    'Varāmīn': 3945,
    'Būkān': 3924,
    'Chāypāreh': 3924,
    'Khowy': 3924,
    'Mahābād': 3924,
    'Mīāndoāb': 3924,
    'Naqadeh': 3924,
    'Orūmīyeh': 3924,
    'Oshnavīyeh': 3924,
    'Piranshahr': 3924,
    'Poldasht': 3924,
    'Qarah Ẕīā’ od Dīn': 3924,
    'Salmās': 3924,
    'Sardasht': 3924,
    'Shāhīn Dezh': 3924,
    'Shahrestān-e Būkān': 3924,
    'Shahrestān-e Chāldorān': 3924,
    'Shahrestān-e Mahābād': 3924,
    'Shahrestān-e Mākū': 3924,
    'Shahrestān-e Mīāndoāb': 3924,
    'Shahrestān-e Orūmīyeh': 3924,
    'Shahrestān-e Oshnavīyeh': 3924,
    'Shahrestān-e Pīrānshahr': 3924,
    'Shahrestān-e Salmās': 3924,
    'Shahrestān-e Shāhīn Dezh': 3924,
    'Shahrestān-e Takāb': 3924,
    'Takāb': 3924,
    'Ardakān': 3936,
    'Bāfq': 3936,
    'Khavāş Kūh': 3936,
    'Mahrīz': 3936,
    'Meybod': 3936,
    'Shahrestān-e Abarkūh': 3936,
    'Shahrestān-e Ardakān': 3936,
    'Shahrestān-e Ashkez̄ar': 3936,
    'Shahrestān-e Bāfq': 3936,
    'Shahrestān-e Behābād': 3936,
    'Shahrestān-e Khātam': 3936,
    'Shahrestān-e Mehrīz': 3936,
    'Shahrestān-e Yazd': 3936,
    'Tabas': 3936,
    'Taft': 3936,
    'Yazd': 3936,
    'Abhar': 3925,
    'Alvand': 3925,
    'Khorramdarreh': 3925,
    'Shahrestān-e Ījrūd': 3925,
    'Shahrestān-e Khodābandeh': 3925,
    'Shahrestān-e Māhneshān': 3925,
    'Shahrestān-e Ţārom': 3925,
    'Shahrestān-e Zanjān': 3925,
    'Soltaniyeh': 3925,
    'Zanjān': 3925,
    '‘Anah': 3964,
    '‘Anat al Qadīmah': 3964,
    'Al Fallūjah': 3964,
    'Ar Ruţbah': 3964,
    'Ḩadīthah': 3964,
    'Hīt': 3964,
    'Hīt District': 3964,
    'Ramadi': 3964,
    'Rāwah': 3964,
    'Ar Rumaythah': 3958,
    'As Samawah': 3958,
    '‘Afak': 3956,
    'Ad Dīwānīyah': 3956,
    'Ash Shāmīyah': 3956,
    'Nāḩiyat ash Shināfīyah': 3956,
    'Nahiyat Ghammas': 3956,
    'Al Ḩillah': 3955,
    'Al Musayyib': 3955,
    'Imam Qasim': 3955,
    'Nāḩīyat Saddat al Hindīyah': 3955,
    'Abu Ghraib District': 3959,
    'Abū Ghurayb': 3959,
    'Baghdad': 3959,
    'Al Başrah al Qadīmah': 3960,
    'Al Fāw': 3960,
    'Al Hārithah': 3960,
    'Az Zubayr': 3960,
    'Basrah': 3960,
    'Umm Qaşr': 3960,
    'Ash Shaţrah': 3954,
    'Nāḩiyat al Fuhūd': 3954,
    'Nasiriyah': 3954,
    'Al Miqdādīyah': 3965,
    'Baladrūz': 3965,
    'Baqubah': 3965,
    'Khāliş': 3965,
    'Kifrī': 3965,
    'Mandalī': 3965,
    'Qaḑā’ Kifrī': 3965,
    'Al ‘Amādīyah': 3967,
    'Batifa': 3967,
    'Dihok': 3967,
    'Sīnah': 3967,
    'Zaxo': 3967,
    'Arbil': 3968,
    'Erbil': 3968,
    'Koysinceq': 3968,
    'Ruwāndiz': 3968,
    'Shaqlāwah': 3968,
    'Soran': 3968,
    'Al Hindīyah': 3957,
    'Karbala': 3957,
    'Kirkuk': 3971,
    '‘Alī al Gharbī': 3966,
    'Al ‘Amārah': 3966,
    'Al-Mejar Al-Kabi District': 3966,
    'Al Mishkhāb': 3962,
    'Kufa': 3962,
    'Najaf': 3962,
    '‘Aqrah': 3963,
    'Al Mawşil al Jadīdah': 3963,
    'Al-Hamdaniya': 3963,
    'Ash Shaykhān': 3963,
    'Mosul': 3963,
    'Sinjar': 3963,
    'Tall ‘Afar': 3963,
    'Tallkayf': 3963,
    'Ad Dujayl': 3961,
    'Balad': 3961,
    'Bayjī': 3961,
    'Sāmarrā’': 3961,
    'Tikrīt': 3961,
    'Tozkhurmato': 3961,
    'As Sulaymānīyah': 3969,
    'Baynjiwayn': 3969,
    'Ḩalabjah': 3969,
    'Jamjamāl': 3969,
    'Al ‘Azīzīyah': 3970,
    'Al Ḩayy': 3970,
    'Al Kūt': 3970,
    'Aş Şuwayrah': 3970,
    'Athenry': 1087,
    'Ballaghaderreen': 1087,
    'Ballina': 1087,
    'Ballinasloe': 1087,
    'Ballinrobe': 1087,
    'Ballisodare': 1087,
    'Ballyhaunis': 1087,
    'Ballymote': 1087,
    'Bearna': 1087,
    'Belmullet': 1087,
    'Boyle': 1087,
    'Carrick-on-Shannon': 1087,
    'Castlebar': 1087,
    'Castlerea': 1087,
    'Claregalway': 1087,
    'Claremorris': 1087,
    'Clifden': 1087,
    'Collooney': 1087,
    'County Galway': 1087,
    'County Leitrim': 1087,
    'Crossmolina': 1087,
    'Foxford': 1087,
    'Gaillimh': 1087,
    'Galway City': 1087,
    'Gort': 1087,
    'Inishcrone': 1087,
    'Kiltamagh': 1087,
    'Kinlough': 1087,
    'Loughrea': 1087,
    'Manorhamilton': 1087,
    'Mayo County': 1087,
    'Moycullen': 1087,
    'Oranmore': 1087,
    'Oughterard': 1087,
    'Portumna': 1087,
    'Roscommon': 1087,
    'Sligo': 1087,
    'Strandhill': 1087,
    'Swinford': 1087,
    'Tobercurry': 1087,
    'Tuam': 1087,
    'Westport': 1087,
    'Abbeyleix': 1073,
    'An Iarmhí': 1073,
    'An Longfort': 1073,
    'An Mhí': 1073,
    'An Muileann gCearr': 1073,
    'An Ros': 1073,
    'Ardee': 1073,
    'Arklow': 1073,
    'Artane': 1073,
    'Ashbourne': 1073,
    'Ashford': 1073,
    'Athboy': 1073,
    'Athgarvan': 1073,
    'Athlone': 1073,
    'Athy': 1073,
    'Aughrim': 1073,
    'Bagenalstown': 1073,
    'Balally': 1073,
    'Balbriggan': 1073,
    'Baldoyle': 1073,
    'Ballinroad': 1073,
    'Ballinteer': 1073,
    'Ballivor': 1073,
    'Ballyboden': 1073,
    'Ballyfermot': 1073,
    'Ballygerry': 1073,
    'Ballylinan': 1073,
    'Ballymahon': 1073,
    'Ballymun': 1073,
    'Ballyragget': 1073,
    'Balrothery': 1073,
    'Baltinglass': 1073,
    'Banagher': 1073,
    'Bayside': 1073,
    'Beaumont': 1073,
    'Birr': 1073,
    'Blackrock': 1073,
    'Blanchardstown': 1073,
    'Blessington': 1073,
    'Bonnybrook': 1073,
    'Booterstown': 1073,
    'Bray': 1073,
    'Bunclody': 1073,
    'Cabinteely': 1073,
    'Cabra': 1073,
    'Callan': 1073,
    'Carlingford': 1073,
    'Carlow': 1073,
    'Carnew': 1073,
    'Castlebellingham': 1073,
    'Castlebridge': 1073,
    'Castlecomer': 1073,
    'Castledermot': 1073,
    'Castleknock': 1073,
    'Castlepollard': 1073,
    'Castletown': 1073,
    'Celbridge': 1073,
    'Chapelizod': 1073,
    'Charlesland': 1073,
    'Cherry Orchard': 1073,
    'Cherryville': 1073,
    'Clane': 1073,
    'Clara': 1073,
    'Clogherhead': 1073,
    'Clondalkin': 1073,
    'Clonskeagh': 1073,
    'Confey': 1073,
    'Coolock': 1073,
    'County Carlow': 1073,
    'Courtown': 1073,
    'Crumlin': 1073,
    'Daingean': 1073,
    'Dalkey': 1073,
    'Darndale': 1073,
    'Derrinturn': 1073,
    'Dollymount': 1073,
    'Donabate': 1073,
    'Donaghmede': 1073,
    'Donnybrook': 1073,
    'Donnycarney': 1073,
    'Drogheda': 1073,
    'Droichead Nua': 1073,
    'Dromiskin': 1073,
    'Drumcondra': 1073,
    'Dublin': 1073,
    'Dublin City': 1073,
    'Duleek': 1073,
    'Dún Laoghaire': 1073,
    'Dún Laoghaire-Rathdown': 1073,
    'Dunboyne': 1073,
    'Dundalk': 1073,
    'Dundrum': 1073,
    'Dunleer': 1073,
    'Dunshaughlin': 1073,
    'Eadestown': 1073,
    'Edenderry': 1073,
    'Edgeworthstown': 1073,
    'Enfield': 1073,
    'Enniscorthy': 1073,
    'Enniskerry': 1073,
    'Fairview': 1073,
    'Ferbane': 1073,
    'Ferns': 1073,
    'Fingal County': 1073,
    'Finglas': 1073,
    'Firhouse': 1073,
    'Foxrock': 1073,
    'Glasnevin': 1073,
    'Gorey': 1073,
    'Graiguenamanagh': 1073,
    'Granard': 1073,
    'Greenhills': 1073,
    'Greystones': 1073,
    'Hartstown': 1073,
    'Howth': 1073,
    'Jobstown': 1073,
    'Johnstown': 1073,
    'Kells': 1073,
    'Kentstown': 1073,
    'Kilbeggan': 1073,
    'Kilcock': 1073,
    'Kilcoole': 1073,
    'Kilcullen': 1073,
    'Kildare': 1073,
    'Kilkenny': 1073,
    'Kill': 1073,
    'Killester': 1073,
    'Kilmacanoge': 1073,
    'Kilpedder': 1073,
    'Kilquade': 1073,
    'Kinnegad': 1073,
    'Kinsealy-Drinan': 1073,
    'Knocklyon': 1073,
    'Lanesborough': 1073,
    'Laois': 1073,
    'Laytown': 1073,
    'Leixlip': 1073,
    'Little Bray': 1073,
    'Loch Garman': 1073,
    'Longford': 1073,
    'Longwood': 1073,
    'Loughlinstown': 1073,
    'Lú': 1073,
    'Lucan': 1073,
    'Lusk': 1073,
    'Malahide': 1073,
    'Marino': 1073,
    'Maynooth': 1073,
    'Milltown': 1073,
    'Moate': 1073,
    'Monasterevin': 1073,
    'Monkstown': 1073,
    'Mooncoin': 1073,
    'Moone': 1073,
    'Mount Merrion': 1073,
    'Mountmellick': 1073,
    'Mountrath': 1073,
    'Naas': 1073,
    'Navan': 1073,
    'New Ross': 1073,
    'Newcastle': 1073,
    'Newtown Trim': 1073,
    'Newtownmountkennedy': 1073,
    'Old Kilcullen': 1073,
    'Oldbawn': 1073,
    'Oldcastle': 1073,
    'Palmerstown': 1073,
    'Piltown': 1073,
    'Portarlington': 1073,
    'Portlaoise': 1073,
    'Portmarnock': 1073,
    'Portraine': 1073,
    'Prosperous': 1073,
    'Raheny': 1073,
    'Rathangan': 1073,
    'Rathcoole': 1073,
    'Rathdowney': 1073,
    'Rathdrum': 1073,
    'Rathgar': 1073,
    'Rathmines': 1073,
    'Rathnew': 1073,
    'Rathwire': 1073,
    'Ratoath': 1073,
    'Rialto': 1073,
    'Ringsend': 1073,
    'Rochfortbridge': 1073,
    'Rosslare': 1073,
    'Saggart': 1073,
    'Sallins': 1073,
    'Sallynoggin': 1073,
    'Sandyford': 1073,
    'Sandymount': 1073,
    'Shankill': 1073,
    'Skerries': 1073,
    'Slane': 1073,
    'South Dublin': 1073,
    'Stamullin': 1073,
    'Stradbally': 1073,
    'Sutton': 1073,
    'Swords': 1073,
    'Tallaght': 1073,
    'Templeogue': 1073,
    'Terenure': 1073,
    'Termonfeckin': 1073,
    'Thomastown': 1073,
    'Trim': 1073,
    'Tullamore': 1073,
    'Tullow': 1073,
    'Tullyallen': 1073,
    'Uíbh Fhailí': 1073,
    'Valleymount': 1073,
    'Wicklow': 1073,
    'Abbeyfeale': 1080,
    'Adare': 1080,
    'Aghada': 1080,
    'An Clár': 1080,
    'Annacotty': 1080,
    'Ardnacrusha': 1080,
    'Askeaton': 1080,
    'Ballina': 1080,
    'Ballybunnion': 1080,
    'Bandon': 1080,
    'Bantry': 1080,
    'Blarney': 1080,
    'Caherconlish': 1080,
    'Cahersiveen': 1080,
    'Cahir': 1080,
    'Carrick-on-Suir': 1080,
    'Carrigaline': 1080,
    'Carrigtwohill': 1080,
    'Cashel': 1080,
    'Castleconnell': 1080,
    'Castleisland': 1080,
    'Castlemartyr': 1080,
    'Ciarraí': 1080,
    'Cill Airne': 1080,
    'Clonakilty': 1080,
    'Cloyne': 1080,
    'Cluain Meala': 1080,
    'Cobh': 1080,
    'Cork': 1080,
    'Cork City': 1080,
    'County Cork': 1080,
    'County Tipperary': 1080,
    'Croom': 1080,
    'Crosshaven': 1080,
    'Derry': 1080,
    'Dingle': 1080,
    'Dungarvan': 1080,
    'Dunmanway': 1080,
    'Dunmore East': 1080,
    'Ennis': 1080,
    'Fermoy': 1080,
    'Fethard': 1080,
    'Kanturk': 1080,
    'Kenmare': 1080,
    'Killaloe': 1080,
    'Killorglin': 1080,
    'Killumney': 1080,
    'Kilmallock': 1080,
    'Kilrush': 1080,
    'Kinsale': 1080,
    'Listowel': 1080,
    'Luimneach': 1080,
    'Macroom': 1080,
    'Mallow': 1080,
    'Midleton': 1080,
    'Millstreet': 1080,
    'Mitchelstown': 1080,
    'Moroe': 1080,
    'Moyross': 1080,
    'Nenagh': 1080,
    'Nenagh Bridge': 1080,
    'Newcastle West': 1080,
    'Newmarket on Fergus': 1080,
    'Newport': 1080,
    'Passage West': 1080,
    'Portlaw': 1080,
    'Ráth Luirc': 1080,
    'Rathcormac': 1080,
    'Rathkeale': 1080,
    'Roscrea': 1080,
    'Shannon': 1080,
    'Sixmilebridge': 1080,
    'Skibbereen': 1080,
    'Templemore': 1080,
    'Thurles': 1080,
    'Tipperary': 1080,
    'Tower': 1080,
    'Trá Mhór': 1080,
    'Tralee': 1080,
    'Waterford': 1080,
    'Watergrasshill': 1080,
    'Whitegate': 1080,
    'Youghal': 1080,
    'An Cabhán': 1086,
    'Bailieborough': 1086,
    'Ballybofey': 1086,
    'Ballyconnell': 1086,
    'Ballyjamesduff': 1086,
    'Ballyshannon': 1086,
    'Belturbet': 1086,
    'Buncrana': 1086,
    'Bundoran': 1086,
    'Carndonagh': 1086,
    'Carrickmacross': 1086,
    'Castleblayney': 1086,
    'Cavan': 1086,
    'Clones': 1086,
    'Convoy': 1086,
    'Cootehill': 1086,
    'County Donegal': 1086,
    'County Monaghan': 1086,
    'Derrybeg': 1086,
    'Donegal': 1086,
    'Dungloe': 1086,
    'Dunlewy': 1086,
    'Gweedore': 1086,
    'Killybegs': 1086,
    'Kingscourt': 1086,
    'Leifear': 1086,
    'Letterkenny': 1086,
    'Monaghan': 1086,
    'Moville': 1086,
    'Muff': 1086,
    'Mullagh': 1086,
    'Newtown Cunningham': 1086,
    'Ramelton': 1086,
    'Raphoe': 1086,
    'Virginia': 1086,
    'Bet Dagan': 1367,
    'Bet Yiẕẖaq': 1367,
    'Bnei Ayish': 1367,
    'Elyakhin': 1367,
    'Eṭ Ṭaiyiba': 1367,
    'Even Yehuda': 1367,
    'Gan Yavne': 1367,
    'Ganei Tikva': 1367,
    'Gedera': 1367,
    'Hod HaSharon': 1367,
    'Jaljūlya': 1367,
    'Kafr Qāsim': 1367,
    'Kefar H̱abad': 1367,
    'Kefar Yona': 1367,
    'Kfar Saba': 1367,
    'Lapid': 1367,
    'Lod': 1367,
    'Mazkeret Batya': 1367,
    'Modi‘in Makkabbim Re‘ut': 1367,
    'Neẖalim': 1367,
    'Ness Ziona': 1367,
    'Netanya': 1367,
    'Nirit': 1367,
    'Nof Ayalon': 1367,
    'Nordiyya': 1367,
    'Pardesiyya': 1367,
    'Petaẖ Tiqwa': 1367,
    'Qalansuwa': 1367,
    'Ra  anana': 1367,
    'Ramla': 1367,
    'Reẖovot': 1367,
    'Rishon LeẔiyyon': 1367,
    'Rosh Ha‘Ayin': 1367,
    'Savyon': 1367,
    'Shoham': 1367,
    'Tel Mond': 1367,
    'Tirah': 1367,
    'Yavné': 1367,
    'Yehud': 1367,
    'Ẕur Moshe': 1367,
    'Atlit': 1369,
    'Caesarea': 1369,
    'Daliyat al Karmel': 1369,
    'El Fureidīs': 1369,
    'Hadera': 1369,
    'Haifa': 1369,
    'Ibṭīn': 1369,
    'Nesher': 1369,
    'Qiryat Ata': 1369,
    'Qiryat Bialik': 1369,
    'Qiryat Moẕqin': 1369,
    'Qiryat Yam': 1369,
    'Rekhasim': 1369,
    'Tirat Karmel': 1369,
    'Umm el Faḥm': 1369,
    'Abū Ghaush': 1370,
    'Bet Shemesh': 1370,
    'Har Adar': 1370,
    'Jerusalem': 1370,
    'Mevasseret Ẕiyyon': 1370,
    'Modiin Ilit': 1370,
    'West Jerusalem': 1370,
    'Ẕur Hadassa': 1370,
    '‘Eilabun': 1366,
    '‘Uzeir': 1366,
    'Acre': 1366,
    'Afula': 1366,
    'Basmat Ṭab‘ūn': 1366,
    'Beit Jann': 1366,
    'Bet She’an': 1366,
    'Bīr el Maksūr': 1366,
    'Bu‘eina': 1366,
    'Buqei‘a': 1366,
    'Dabbūrīya': 1366,
    'Deir Ḥannā': 1366,
    'El Mazra‘a': 1366,
    'Er Reina': 1366,
    'Esh Sheikh Dannūn': 1366,
    'Ḥurfeish': 1366,
    'Iksāl': 1366,
    'Jīsh': 1366,
    'Judeida Makr': 1366,
    'Kābūl': 1366,
    'Kafr Kammā': 1366,
    'Kafr Kannā': 1366,
    'Kafr Mandā': 1366,
    'Kafr Miṣr': 1366,
    'Karmi’el': 1366,
    'Kaukab Abū el Hījā': 1366,
    'Kefar Rosh HaNiqra': 1366,
    'Kefar Tavor': 1366,
    'Kefar Weradim': 1366,
    'Kfar Yasif': 1366,
    'maalot Tarshīhā': 1366,
    'Maghār': 1366,
    'Metulla': 1366,
    'Mi‘ilyā': 1366,
    'Migdal Ha‘Emeq': 1366,
    'Nahariyya': 1366,
    'Naḥf': 1366,
    'Nazareth': 1366,
    'Nefat ‘Akko': 1366,
    'Nein': 1366,
    'Pasuta': 1366,
    'Qiryat Shemona': 1366,
    'Ramat Yishay': 1366,
    'Rosh Pinna': 1366,
    'Rumat Heib': 1366,
    'Safed': 1366,
    'Sājūr': 1366,
    'Sakhnīn': 1366,
    'Sallama': 1366,
    'Shelomi': 1366,
    'Shibli': 1366,
    'Sūlam': 1366,
    'Tamra': 1366,
    'Tiberias': 1366,
    'Timrat': 1366,
    'Yavne’el': 1366,
    '‘En Boqeq': 1368,
    'Arad': 1368,
    'Ashdod': 1368,
    'Ashkelon': 1368,
    'Beersheba': 1368,
    'Dimona': 1368,
    'Eilat': 1368,
    'Lehavim': 1368,
    'Midreshet Ben-Gurion': 1368,
    'Mitzpe Ramon': 1368,
    'Netivot': 1368,
    'Ofaqim': 1368,
    'Qiryat Gat': 1368,
    'Rahat': 1368,
    'Sederot': 1368,
    'Yeroẖam': 1368,
    'Azor': 1371,
    'Bat Yam': 1371,
    'Bnei Brak': 1371,
    'Giv  at Shmuel': 1371,
    'Givatayim': 1371,
    'Herzliya': 1371,
    'Herzliya Pituah': 1371,
    'H̱olon': 1371,
    'Jaffa': 1371,
    'Kefar Shemaryahu': 1371,
    'Or Yehuda': 1371,
    'Ramat Gan': 1371,
    'Ramat HaSharon': 1371,
    'Tel Aviv': 1371,
    'Yehud-Monosson': 1371,
    'Abbateggio': 1679,
    'Acciano': 1679,
    'Aielli': 1679,
    'Alanno': 1679,
    'Alba Adriatica': 1679,
    'Alfedena': 1679,
    'Altino': 1679,
    'Ancarano': 1679,
    'Anversa degli Abruzzi': 1679,
    'Archi': 1679,
    'Ari': 1679,
    'Arielli': 1679,
    'Arsita': 1679,
    'Ateleta': 1679,
    'Atessa': 1679,
    'Atri': 1679,
    'Avezzano': 1679,
    'Balsorano': 1679,
    'Balsorano Nuovo': 1679,
    'Barberi': 1679,
    'Barete': 1679,
    'Barisciano': 1679,
    'Barrea': 1679,
    'Basciano': 1679,
    'Bellante': 1679,
    'Bellante Stazione': 1679,
    'Bisegna': 1679,
    'Bisenti': 1679,
    'Bolognano': 1679,
    'Bomba': 1679,
    'Borgo Santa Maria Immacolata': 1679,
    'Borrello': 1679,
    'Brittoli': 1679,
    'Bucchianico': 1679,
    'Bugnara': 1679,
    'Bussi sul Tirino': 1679,
    'Cagnano Amiterno': 1679,
    'Calascio': 1679,
    'Campli': 1679,
    'Campo di Giove': 1679,
    'Campotosto': 1679,
    'Canistro Inferiore': 1679,
    'Canosa Sannita': 1679,
    'Cansano': 1679,
    'Canzano': 1679,
    'Capestrano': 1679,
    'Capistrello': 1679,
    'Capitignano': 1679,
    'Caporciano': 1679,
    'Cappadocia': 1679,
    'Cappelle sul Tavo': 1679,
    'Caramanico Terme': 1679,
    'Carapelle Calvisio': 1679,
    'Carpineto della Nora': 1679,
    'Carpineto Sinello': 1679,
    'Carsoli': 1679,
    'Carunchio': 1679,
    'Casacanditella': 1679,
    'Casalanguida': 1679,
    'Casalbordino-Miracoli': 1679,
    'Casalincontrada': 1679,
    'Casoli': 1679,
    'Castel Castagna': 1679,
    'Castel del Monte': 1679,
    'Castel di Ieri': 1679,
    'Castel di Sangro': 1679,
    'Castel Frentano': 1679,
    'Castelguidone': 1679,
    'Castellafiume': 1679,
    'Castellalto': 1679,
    'Castelli': 1679,
    'Castelnuovo Vomano': 1679,
    'Castelvecchio Calvisio': 1679,
    'Castelvecchio Subequo': 1679,
    'Castiglione a Casauria': 1679,
    'Castiglione Messer Marino': 1679,
    'Castiglione Messer Raimondo': 1679,
    'Castilenti': 1679,
    'Catignano': 1679,
    'Celano': 1679,
    'Celenza sul Trigno': 1679,
    'Cellino Attanasio': 1679,
    'Cepagatti': 1679,
    'Cerchio': 1679,
    'Cermignano': 1679,
    'Cerratina': 1679,
    'Chieti': 1679,
    'Città Sant  Angelo': 1679,
    'Civita d  Antino': 1679,
    'Civitaluparella': 1679,
    'Civitaquana': 1679,
    'Civitella Alfedena': 1679,
    'Civitella Casanova': 1679,
    'Civitella del Tronto': 1679,
    'Civitella Messer Raimondo': 1679,
    'Civitella Roveto': 1679,
    'Cocullo': 1679,
    'Collarmele': 1679,
    'Collecorvino': 1679,
    'Colledara': 1679,
    'Colledimacine': 1679,
    'Colledimezzo': 1679,
    'Collelongo': 1679,
    'Collepietro': 1679,
    'Colleranesco': 1679,
    'Cologna Spiaggia': 1679,
    'Colonnella': 1679,
    'Cominio': 1679,
    'Controguerra': 1679,
    'Coppito': 1679,
    'Corfinio': 1679,
    'Corropoli': 1679,
    'Cortino': 1679,
    'Corvara': 1679,
    'Crecchio': 1679,
    'Crognaleto': 1679,
    'Cugnoli': 1679,
    'Cupello': 1679,
    'Dogliola': 1679,
    'Elice': 1679,
    'Fallo': 1679,
    'Fano Adriano': 1679,
    'Fara Filiorum Petri': 1679,
    'Fara San Martino': 1679,
    'Farindola': 1679,
    'Filetto': 1679,
    'Fonte Umano-San Martino Alta': 1679,
    'Fontecchio': 1679,
    'Fossa': 1679,
    'Fossacesia': 1679,
    'Fraine': 1679,
    'Francavilla al Mare': 1679,
    'Fresagrandinaria': 1679,
    'Frisa': 1679,
    'Furci': 1679,
    'Gagliano Aterno': 1679,
    'Gamberale': 1679,
    'Gessopalena': 1679,
    'Gioia dei Marsi': 1679,
    'Gissi': 1679,
    'Giuliano Teatino': 1679,
    'Giulianova': 1679,
    'Goriano Sicoli': 1679,
    'Guardiagrele': 1679,
    'Guilmi': 1679,
    'Imposte': 1679,
    'Introdacqua': 1679,
    'Isola del Gran Sasso d  Italia': 1679,
    'L  Aquila': 1679,
    'Lama dei Peligni': 1679,
    'Lanciano': 1679,
    'Lecce Nei Marsi': 1679,
    'Lentella': 1679,
    'Lettomanoppello': 1679,
    'Lettopalena': 1679,
    'Liscia': 1679,
    'Loreto Aprutino': 1679,
    'Luco dei Marsi': 1679,
    'Lucoli': 1679,
    'Magliano De  Marsi': 1679,
    'Manoppello': 1679,
    'Manoppello Scalo': 1679,
    'Marina di San Vito': 1679,
    'Marina di Vasto': 1679,
    'Martinsicuro': 1679,
    'Massa d  Albe-Corona': 1679,
    'Miglianico': 1679,
    'Molina Aterno': 1679,
    'Montazzoli': 1679,
    'Montebello di Bertona': 1679,
    'Montebello sul Sangro': 1679,
    'Monteferrante': 1679,
    'Montefino': 1679,
    'Montelapiano': 1679,
    'Montenerodomo': 1679,
    'Monteodorisio': 1679,
    'Montereale': 1679,
    'Montesilvano Marina': 1679,
    'Monticchio': 1679,
    'Montorio al Vomano': 1679,
    'Morino': 1679,
    'Morro d  Oro': 1679,
    'Mosciano Sant  Angelo': 1679,
    'Moscufo': 1679,
    'Mozzagrogna': 1679,
    'Navelli': 1679,
    'Nepezzano': 1679,
    'Nereto': 1679,
    'Nerito': 1679,
    'Nocciano': 1679,
    'Notaresco': 1679,
    'Nucleo Industriale di Bazzano': 1679,
    'Ofena': 1679,
    'Opi': 1679,
    'Oricola': 1679,
    'Orsogna': 1679,
    'Ortona': 1679,
    'Ortona dei Marsi': 1679,
    'Ortucchio': 1679,
    'Ovindoli': 1679,
    'Pacentro': 1679,
    'Paganica-Tempera': 1679,
    'Pagliare': 1679,
    'Paglieta': 1679,
    'Palena': 1679,
    'Palmoli': 1679,
    'Palombaro': 1679,
    'Penna Sant  Andrea': 1679,
    'Pennadomo': 1679,
    'Pennapiedimonte': 1679,
    'Penne': 1679,
    'Perano': 1679,
    'Pereto': 1679,
    'Pescara': 1679,
    'Pescasseroli': 1679,
    'Pescina': 1679,
    'Pescocostanzo': 1679,
    'Pescosansonesco Nuovo': 1679,
    'Pettorano sul Gizio': 1679,
    'Pianella': 1679,
    'Pianola': 1679,
    'Pianura Vomano': 1679,
    'Picciano': 1679,
    'Pietracamela': 1679,
    'Pietraferrazzana': 1679,
    'Pietranico': 1679,
    'Pineto': 1679,
    'Pizzoferrato': 1679,
    'Pizzoli': 1679,
    'Poggio Picenze': 1679,
    'Poggiofiorito': 1679,
    'Pollutri': 1679,
    'Popoli': 1679,
    'Prata d  Ansidonia': 1679,
    'Pratola Peligna': 1679,
    'Pretoro': 1679,
    'Preturo': 1679,
    'Prezza': 1679,
    'Progetto Case Bazzano': 1679,
    'Progetto Case Coppito 3': 1679,
    'Progetto Case Sassa Nsi': 1679,
    'Provincia dell   Aquila': 1679,
    'Provincia di Chieti': 1679,
    'Provincia di Pescara': 1679,
    'Provincia di Teramo': 1679,
    'Quadri': 1679,
    'Raiano': 1679,
    'Rapino': 1679,
    'Ripa Teatina': 1679,
    'Rivisondoli': 1679,
    'Rocca di Botte': 1679,
    'Rocca di Cambio': 1679,
    'Rocca di Mezzo': 1679,
    'Rocca Pia': 1679,
    'Rocca San Giovanni': 1679,
    'Roccacasale': 1679,
    'Roccamorice': 1679,
    'Roccaraso': 1679,
    'Roccascalegna': 1679,
    'Roccaspinalveti-Santa Giusta': 1679,
    'Roccavivi': 1679,
    'Roio del Sangro': 1679,
    'Rosciano': 1679,
    'Rosello': 1679,
    'Roseto degli Abruzzi': 1679,
    'Salino': 1679,
    'Salle': 1679,
    'Salvo Marina': 1679,
    'Sambuceto': 1679,
    'San Benedetto dei Marsi': 1679,
    'San Benedetto in Perillis': 1679,
    'San Buono': 1679,
    'San Demetrio Ne   Vestini': 1679,
    'San Giovanni Lipioni': 1679,
    'San Martino Bassa': 1679,
    'San Martino sulla Marrucina': 1679,
    'San Nicolò a Tordino': 1679,
    'San Panfilo d  Ocre': 1679,
    'San Pio delle Camere': 1679,
    'San Rocco': 1679,
    'San Salvo': 1679,
    'San Valentino in Abruzzo Citeriore': 1679,
    'San Vincenzo Valle Roveto': 1679,
    'San Vito Chietino': 1679,
    'Sant  Egidio alla Vibrata': 1679,
    'Sant  Eufemia a Maiella': 1679,
    'Sant  Eusanio del Sangro': 1679,
    'Sant  Eusanio Forconese': 1679,
    'Sant  Omero': 1679,
    'Santa Maria Imbaro': 1679,
    'Santa Teresa': 1679,
    'Sante Marie': 1679,
    'Santo Stefano di Sessanio': 1679,
    'Sassa': 1679,
    'Scafa': 1679,
    'Scanno': 1679,
    'Scerne': 1679,
    'Scerni': 1679,
    'Schiavi di Abruzzo': 1679,
    'Scontrone': 1679,
    'Scoppito': 1679,
    'Scurcola Marsicana': 1679,
    'Secinaro': 1679,
    'Selva': 1679,
    'Serramonacesca': 1679,
    'Silvi': 1679,
    'Spoltore': 1679,
    'Sulmona': 1679,
    'Tagliacozzo': 1679,
    'Taranta Peligna': 1679,
    'Teramo': 1679,
    'Tione degli Abruzzi': 1679,
    'Tocco da Casauria': 1679,
    'Tollo': 1679,
    'Torano Nuovo': 1679,
    'Torino di Sangro': 1679,
    'Tornareccio': 1679,
    'Tornimparte': 1679,
    'Torre de   Passeri': 1679,
    'Torrebruna': 1679,
    'Torrevecchia': 1679,
    'Torricella': 1679,
    'Torricella Peligna': 1679,
    'Tortoreto': 1679,
    'Tortoreto Lido': 1679,
    'Tossicia': 1679,
    'Trasacco': 1679,
    'Treglio': 1679,
    'Tufillo': 1679,
    'Turrivalignani': 1679,
    'Vacri': 1679,
    'Valle Castellana': 1679,
    'Vallecupa': 1679,
    'Vasto': 1679,
    'Vicoli': 1679,
    'Villa Caldari': 1679,
    'Villa Celiera': 1679,
    'Villa Lempa': 1679,
    'Villa Raspa': 1679,
    'Villa Rosa': 1679,
    'Villa Sant  Angelo': 1679,
    'Villa Santa Lucia degli Abruzzi': 1679,
    'Villa Santa Maria': 1679,
    'Villagrande': 1679,
    'Villalago': 1679,
    'Villalfonsina': 1679,
    'Villamagna': 1679,
    'Villanova': 1679,
    'Villavallelonga': 1679,
    'Villetta Barrea': 1679,
    'Vittorito': 1679,
    'Allein': 1716,
    'Antagnod': 1716,
    'Antey-Saint-Andrè': 1716,
    'Aosta': 1716,
    'Arnad': 1716,
    'Arvier': 1716,
    'Avise': 1716,
    'Ayas': 1716,
    'Aymavilles': 1716,
    'Bard': 1716,
    'Berriat': 1716,
    'Bionaz': 1716,
    'Brusson': 1716,
    'Challand-Saint-Anselme': 1716,
    'Challand-Saint-Victor': 1716,
    'Chambave': 1716,
    'Chamois': 1716,
    'Champdepraz': 1716,
    'Champorcher': 1716,
    'Charvensod': 1716,
    'Chatillon': 1716,
    'Chef-Lieu': 1716,
    'Cogne': 1716,
    'Courmayeur': 1716,
    'Donnas': 1716,
    'Doues': 1716,
    'Emarese': 1716,
    'Etroubles': 1716,
    'Fénis': 1716,
    'Fontainemore': 1716,
    'Gaby': 1716,
    'Gignod': 1716,
    'Grand Brissogne': 1716,
    'Gressan': 1716,
    'Gressoney-La-Trinitè': 1716,
    'Gressoney-Saint-Jean': 1716,
    'Hone': 1716,
    'Introd': 1716,
    'Issime': 1716,
    'Issogne': 1716,
    'Jovencan': 1716,
    'La Cretaz-Roisan': 1716,
    'La Magdeleine': 1716,
    'La Place': 1716,
    'La Salle': 1716,
    'La Thuile': 1716,
    'Lassolaz': 1716,
    'Lillianes': 1716,
    'Mongnod': 1716,
    'Montan-Angelin-Arensod': 1716,
    'Montjovet': 1716,
    'Morgex': 1716,
    'Nus': 1716,
    'Ollomont': 1716,
    'Oyace': 1716,
    'Paquier': 1716,
    'Perloz': 1716,
    'Petit Fenis': 1716,
    'Plan d  Introd': 1716,
    'Pollein': 1716,
    'Pont-Bozet': 1716,
    'Pont-Saint-Martin': 1716,
    'Pontey': 1716,
    'Prè Saint Didier': 1716,
    'Quart': 1716,
    'Quincod': 1716,
    'Rhemes-Notre-Dame': 1716,
    'Rhemes-Saint-Georges': 1716,
    'Saint Marcel': 1716,
    'Saint Maurice': 1716,
    'Saint-Christophe': 1716,
    'Saint-Denis': 1716,
    'Saint-Nicolas': 1716,
    'Saint-Oyen': 1716,
    'Saint-Pierre': 1716,
    'Saint-Rhémy': 1716,
    'Saint-Vincent': 1716,
    'San Leonardo': 1716,
    'Torgnon': 1716,
    'Valgrisenche': 1716,
    'Valle d  Aosta': 1716,
    'Valpelline': 1716,
    'Valsavarenche': 1716,
    'Valtournenche': 1716,
    'Verrayes': 1716,
    'Verres': 1716,
    'Villa-Nabian': 1716,
    'Villair-Amerique': 1716,
    'Ville Sur Sarre': 1716,
    'Villefranche': 1716,
    'Villeneuve': 1716,
    'Accadia': 1688,
    'Acquarica del Capo': 1688,
    'Acquaviva delle Fonti': 1688,
    'Adelfia': 1688,
    'Alberobello': 1688,
    'Alberona': 1688,
    'Alessano': 1688,
    'Alezio': 1688,
    'Alliste': 1688,
    'Altamura': 1688,
    'Andrano': 1688,
    'Andria': 1688,
    'Anzano di Puglia': 1688,
    'Apricena': 1688,
    'Aradeo': 1688,
    'Arnesano': 1688,
    'Ascoli Satriano': 1688,
    'Avetrana': 1688,
    'Bagnolo del Salento': 1688,
    'Bari': 1688,
    'Barletta': 1688,
    'Biccari': 1688,
    'Binetto': 1688,
    'Bisceglie': 1688,
    'Bitetto': 1688,
    'Bitonto': 1688,
    'Bitritto': 1688,
    'Borgagne': 1688,
    'Botrugno': 1688,
    'Bovino': 1688,
    'Brindisi': 1688,
    'Cagnano Varano': 1688,
    'Calimera': 1688,
    'Campi Salentina': 1688,
    'Candela': 1688,
    'Cannole': 1688,
    'Canosa di Puglia': 1688,
    'Capirro': 1688,
    'Caprarica di Lecce': 1688,
    'Capurso': 1688,
    'Carapelle': 1688,
    'Carlantino': 1688,
    'Carmiano': 1688,
    'Carosino': 1688,
    'Carovigno': 1688,
    'Carpignano Salentino': 1688,
    'Carpino': 1688,
    'Casalini': 1688,
    'Casalnuovo Monterotaro': 1688,
    'Casalvecchio di Puglia': 1688,
    'Casamassella': 1688,
    'Casamassima': 1688,
    'Casarano': 1688,
    'Cassano delle Murge': 1688,
    'Castellana': 1688,
    'Castellaneta': 1688,
    'Castelluccio dei Sauri': 1688,
    'Castelluccio Valmaggiore': 1688,
    'Castelnuovo della Daunia': 1688,
    'Castiglione': 1688,
    'Castri di Lecce': 1688,
    'Castrignano De   Greci': 1688,
    'Castrignano del Capo': 1688,
    'Castro': 1688,
    'Castromediano': 1688,
    'Cavallino': 1688,
    'Ceglie Messapica': 1688,
    'Celenza Valfortore': 1688,
    'Cellamare': 1688,
    'Celle di San Vito': 1688,
    'Cellino San Marco': 1688,
    'Cerfignano': 1688,
    'Cerignola': 1688,
    'Chieuti': 1688,
    'Cisternino': 1688,
    'Collemeto': 1688,
    'Collepasso': 1688,
    'Conversano': 1688,
    'Copertino': 1688,
    'Corato': 1688,
    'Corigliano d  Otranto': 1688,
    'Corsano': 1688,
    'Cozzana': 1688,
    'Crispiano': 1688,
    'Cursi': 1688,
    'Cutrofiano': 1688,
    'Deliceto': 1688,
    'Depressa': 1688,
    'Diso': 1688,
    'Erchie': 1688,
    'Faeto': 1688,
    'Faggiano': 1688,
    'Fasano': 1688,
    'Felline': 1688,
    'Foggia': 1688,
    'Fragagnano': 1688,
    'Francavilla Fontana': 1688,
    'Gagliano del Capo': 1688,
    'Galatina': 1688,
    'Galatone': 1688,
    'Gallipoli': 1688,
    'Galugnano': 1688,
    'Gemini': 1688,
    'Ginosa': 1688,
    'Gioia del Colle': 1688,
    'Giorgilorio': 1688,
    'Giovinazzo': 1688,
    'Giuggianello': 1688,
    'Giurdignano': 1688,
    'Gravina in Puglia': 1688,
    'Grottaglie': 1688,
    'Grumo Appula': 1688,
    'Guagnano': 1688,
    'Ischitella': 1688,
    'Isole Tremiti': 1688,
    'La Rosa': 1688,
    'Lamie': 1688,
    'Lamie di Olimpie-Selva': 1688,
    'Laterza': 1688,
    'Latiano': 1688,
    'Lecce': 1688,
    'Leporano': 1688,
    'Leporano Marina': 1688,
    'Lequile': 1688,
    'Lesina': 1688,
    'Leuca': 1688,
    'Leverano': 1688,
    'Lizzanello': 1688,
    'Lizzano': 1688,
    'Locorotondo': 1688,
    'Loseto': 1688,
    'Lucera': 1688,
    'Lucugnano': 1688,
    'Magliano': 1688,
    'Maglie': 1688,
    'Manduria': 1688,
    'Manfredonia': 1688,
    'Margherita di Savoia': 1688,
    'Marina di Ginosa': 1688,
    'Mariotto': 1688,
    'Marittima': 1688,
    'Martano': 1688,
    'Martignano': 1688,
    'Martina Franca': 1688,
    'Maruggio': 1688,
    'Massafra': 1688,
    'Materdomini': 1688,
    'Matino': 1688,
    'Mattinata': 1688,
    'Melendugno': 1688,
    'Melissano': 1688,
    'Melpignano': 1688,
    'Merine': 1688,
    'Mesagne': 1688,
    'Miggiano': 1688,
    'Minervino di Lecce': 1688,
    'Minervino Murge': 1688,
    'Modugno': 1688,
    'Mola di Bari': 1688,
    'Molfetta': 1688,
    'Monopoli': 1688,
    'Montalbano': 1688,
    'Monte Sant  Angelo': 1688,
    'Monteiasi': 1688,
    'Monteleone di Puglia': 1688,
    'Montemesola': 1688,
    'Monteparano': 1688,
    'Monteroni di Lecce': 1688,
    'Montesano Salentino': 1688,
    'Montesardo': 1688,
    'Monti d  Arena-Bosco Caggione': 1688,
    'Morciano di Leuca': 1688,
    'Motta Montecorvino': 1688,
    'Mottola': 1688,
    'Muro Leccese': 1688,
    'Nardò': 1688,
    'Neviano': 1688,
    'Noci': 1688,
    'Nociglia': 1688,
    'Noha': 1688,
    'Noicattaro': 1688,
    'Novoli': 1688,
    'Ordona': 1688,
    'Oria': 1688,
    'Orsara di Puglia': 1688,
    'Orta Nova': 1688,
    'Ortelle': 1688,
    'Ostuni': 1688,
    'Otranto': 1688,
    'Palagianello': 1688,
    'Palagiano': 1688,
    'Palmariggi': 1688,
    'Palo del Colle': 1688,
    'Palombaio': 1688,
    'Panni': 1688,
    'Paolo VI': 1688,
    'Parabita': 1688,
    'Parco Scizzo-Parchitello': 1688,
    'Patù': 1688,
    'Peschici': 1688,
    'Pezze di Greco': 1688,
    'Pietramontecorvino': 1688,
    'Pisignano': 1688,
    'Poggiardo': 1688,
    'Poggio Imperiale': 1688,
    'Poggiorsini': 1688,
    'Polignano a Mare': 1688,
    'Porto Cesareo': 1688,
    'Presicce': 1688,
    'Provincia di Barletta - Andria - Trani': 1688,
    'Provincia di Brindisi': 1688,
    'Provincia di Foggia': 1688,
    'Provincia di Lecce': 1688,
    'Provincia di Taranto': 1688,
    'Pulsano': 1688,
    'Putignano': 1688,
    'Quasano': 1688,
    'Racale': 1688,
    'Rignano Garganico': 1688,
    'Roccaforzata': 1688,
    'Rocchetta Sant  Antonio': 1688,
    'Rodi Garganico': 1688,
    'Roseto Valfortore': 1688,
    'Ruffano': 1688,
    'Rutigliano': 1688,
    'Ruvo di Puglia': 1688,
    'Salice Salentino': 1688,
    'Salve': 1688,
    'Sammichele di Bari': 1688,
    'San Cassiano': 1688,
    'San Cesario di Lecce': 1688,
    'San Donaci': 1688,
    'San Donato di Lecce': 1688,
    'San Ferdinando di Puglia': 1688,
    'San Giorgio Ionico': 1688,
    'San Giovanni Rotondo': 1688,
    'San Marco in Lamis': 1688,
    'San Marco la Catola': 1688,
    'San Marzano di San Giuseppe': 1688,
    'San Michele Salentino': 1688,
    'San Pancrazio Salentino': 1688,
    'San Paolo': 1688,
    'San Paolo di Civitate': 1688,
    'San Pietro in Lama': 1688,
    'San Pietro Vernotico': 1688,
    'San Severo': 1688,
    'San Vito dei Normanni': 1688,
    'Sanarica': 1688,
    'Sannicandro di Bari': 1688,
    'Sannicandro Garganico': 1688,
    'Sannicola': 1688,
    'Sant  Agata di Puglia': 1688,
    'Santa Cesarea Terme': 1688,
    'Santeramo in Colle': 1688,
    'Sava': 1688,
    'Scorrano': 1688,
    'Seclì': 1688,
    'Serracapriola': 1688,
    'Serrano': 1688,
    'Sogliano Cavour': 1688,
    'Soleto': 1688,
    'Specchia': 1688,
    'Spinazzola': 1688,
    'Spongano': 1688,
    'Squinzano': 1688,
    'Statte': 1688,
    'Sternatia': 1688,
    'Stornara': 1688,
    'Stornarella': 1688,
    'Strudà': 1688,
    'Supersano': 1688,
    'Surano': 1688,
    'Surbo': 1688,
    'Taranto': 1688,
    'Taurisano': 1688,
    'Taviano': 1688,
    'Terlizzi': 1688,
    'Tiggiano': 1688,
    'Torchiarolo': 1688,
    'Toritto': 1688,
    'Torre San Giovanni': 1688,
    'Torre Santa Susanna': 1688,
    'Torremaggiore': 1688,
    'Torricella': 1688,
    'Trani': 1688,
    'Trepuzzi': 1688,
    'Tricase': 1688,
    'Triggiano': 1688,
    'Trinitapoli': 1688,
    'Troia': 1688,
    'Tuglie': 1688,
    'Turi': 1688,
    'Tuturano': 1688,
    'Ugento': 1688,
    'Uggiano la Chiesa': 1688,
    'Uggiano Montefusco': 1688,
    'Valenzano': 1688,
    'Veglie': 1688,
    'Vernole': 1688,
    'Vico del Gargano': 1688,
    'Vieste': 1688,
    'Vignacastrisi': 1688,
    'Villa Castelli': 1688,
    'Vitigliano': 1688,
    'Volturara Appula': 1688,
    'Volturino': 1688,
    'Zapponeta': 1688,
    'Zollino': 1688,
    'Abriola': 1706,
    'Accettura': 1706,
    'Acerenza': 1706,
    'Albano di Lucania': 1706,
    'Aliano': 1706,
    'Anzi': 1706,
    'Armento': 1706,
    'Atella': 1706,
    'Avigliano': 1706,
    'Balvano': 1706,
    'Banzi': 1706,
    'Baragiano': 1706,
    'Barile': 1706,
    'Bella': 1706,
    'Bernalda': 1706,
    'Brienza': 1706,
    'Brindisi Montagna': 1706,
    'Calciano': 1706,
    'Calvello': 1706,
    'Calvera': 1706,
    'Campomaggiore': 1706,
    'Cancellara': 1706,
    'Carbone': 1706,
    'Castelgrande': 1706,
    'Castelluccio Inferiore': 1706,
    'Castelluccio Superiore': 1706,
    'Castelmezzano': 1706,
    'Castelsaraceno': 1706,
    'Castronuovo di Sant  Andrea': 1706,
    'Cersosimo': 1706,
    'Chiaromonte': 1706,
    'Cirigliano': 1706,
    'Colobraro': 1706,
    'Corleto Perticara': 1706,
    'Craco-Sant  Angelo': 1706,
    'Episcopia': 1706,
    'Fardella': 1706,
    'Ferrandina': 1706,
    'Filiano': 1706,
    'Forenza': 1706,
    'Francavilla in Sinni': 1706,
    'Gallicchio': 1706,
    'Garaguso': 1706,
    'Genzano di Lucania': 1706,
    'Ginestra': 1706,
    'Gorgoglione': 1706,
    'Grassano': 1706,
    'Grottole': 1706,
    'Grumento Nova': 1706,
    'Guardia Perticara': 1706,
    'Irsina': 1706,
    'La Martella': 1706,
    'Lagonegro': 1706,
    'Latronico': 1706,
    'Laurenzana': 1706,
    'Lauria': 1706,
    'Lavello': 1706,
    'Lido': 1706,
    'Maratea': 1706,
    'Marconia': 1706,
    'Marsico Nuovo': 1706,
    'Marsicovetere': 1706,
    'Maschito': 1706,
    'Matera': 1706,
    'Melfi': 1706,
    'Miglionico': 1706,
    'Missanello': 1706,
    'Moliterno': 1706,
    'Montalbano Jonico': 1706,
    'Montemilone': 1706,
    'Montemurro': 1706,
    'Montescaglioso': 1706,
    'Muro Lucano': 1706,
    'Nemoli': 1706,
    'Noepoli': 1706,
    'Nova Siri': 1706,
    'Nova Siri Scalo': 1706,
    'Oliveto Lucano': 1706,
    'Oppido Lucano': 1706,
    'Palazzo San Gervasio': 1706,
    'Paterno': 1706,
    'Pescopagano': 1706,
    'Picerno': 1706,
    'Pietragalla': 1706,
    'Pietrapertosa': 1706,
    'Pignola': 1706,
    'Pisticci': 1706,
    'Policoro': 1706,
    'Pomarico': 1706,
    'Potenza': 1706,
    'Provincia di Matera': 1706,
    'Provincia di Potenza': 1706,
    'Rapolla': 1706,
    'Rapone': 1706,
    'Rionero in Vulture': 1706,
    'Ripacandida': 1706,
    'Rivello': 1706,
    'Roccanova': 1706,
    'Rotonda': 1706,
    'Rotondella': 1706,
    'Ruoti': 1706,
    'Ruvo del Monte': 1706,
    'Salandra': 1706,
    'San Brancato': 1706,
    'San Chirico Nuovo': 1706,
    'San Chirico Raparo': 1706,
    'San Costantino Albanese': 1706,
    'San Fele': 1706,
    'San Giorgio Lucano': 1706,
    'San Martino d  Agri': 1706,
    'San Mauro Forte': 1706,
    'San Paolo Albanese': 1706,
    'San Severino Lucano': 1706,
    'Sant  Angelo le Fratte': 1706,
    'Sant  Arcangelo': 1706,
    'Sarconi': 1706,
    'Sasso di Castalda': 1706,
    'Satriano di Lucania': 1706,
    'Savoia di Lucania': 1706,
    'Scalo di Baragiano': 1706,
    'Scanzano Jonico': 1706,
    'Senise': 1706,
    'Spinoso': 1706,
    'Stigliano': 1706,
    'Teana': 1706,
    'Terranova di Pollino': 1706,
    'Tito': 1706,
    'Tolve': 1706,
    'Tramutola': 1706,
    'Trecchina': 1706,
    'Tricarico': 1706,
    'Trivigno': 1706,
    'Tursi': 1706,
    'Vaglio Basilicata': 1706,
    'Valsinni': 1706,
    'Venosa': 1706,
    'Vietri di Potenza': 1706,
    'Viggianello': 1706,
    'Viggiano': 1706,
    'Villa d  Agri': 1706,
    'Zona 179': 1706,
    'Acconia': 1703,
    'Acquaformosa': 1703,
    'Acquappesa': 1703,
    'Acquaro': 1703,
    'Acri': 1703,
    'Africo Nuovo': 1703,
    'Africo Vecchio': 1703,
    'Agnana Calabra': 1703,
    'Aiello Calabro': 1703,
    'Aieta': 1703,
    'Albi': 1703,
    'Albidona': 1703,
    'Alessandria del Carretto': 1703,
    'Altilia': 1703,
    'Altomonte': 1703,
    'Amantea': 1703,
    'Amaroni': 1703,
    'Amato': 1703,
    'Amendolara': 1703,
    'Amica': 1703,
    'Andali': 1703,
    'Andreotta': 1703,
    'Anoia Inferiore': 1703,
    'Anoia Superiore': 1703,
    'Antonimina': 1703,
    'Aprigliano': 1703,
    'Arcavacata': 1703,
    'Ardore': 1703,
    'Ardore Marina': 1703,
    'Arena': 1703,
    'Argusto': 1703,
    'Badolato': 1703,
    'Badolato Marina': 1703,
    'Bagaladi': 1703,
    'Bagnara Calabra': 1703,
    'Barone': 1703,
    'Belcastro': 1703,
    'Belmonte Calabro': 1703,
    'Belsito': 1703,
    'Belvedere Marittimo': 1703,
    'Belvedere Spinello': 1703,
    'Benestare': 1703,
    'Bianchi': 1703,
    'Bianco': 1703,
    'Bisignano': 1703,
    'Bivongi': 1703,
    'Bocchigliero': 1703,
    'Bonifati': 1703,
    'Borgata Marina': 1703,
    'Borgia': 1703,
    'Botricello': 1703,
    'Bova': 1703,
    'Bova Marina': 1703,
    'Bovalino': 1703,
    'Bovalino Superiore': 1703,
    'Brancaleone': 1703,
    'Brancaleone-Marina': 1703,
    'Briatico': 1703,
    'Brognaturo': 1703,
    'Bruzzano Zeffirio': 1703,
    'Buonvicino': 1703,
    'Caccuri': 1703,
    'Calanna': 1703,
    'Caloppezzati': 1703,
    'Caloveto': 1703,
    'Calvisi': 1703,
    'Camini': 1703,
    'Campana': 1703,
    'Campo Calabro': 1703,
    'Campora San Giovanni': 1703,
    'Candidoni': 1703,
    'Canna': 1703,
    'Canolo': 1703,
    'Cantinella': 1703,
    'Capistrano': 1703,
    'Capo Rizzuto': 1703,
    'Caraffa del Bianco': 1703,
    'Caraffa di Catanzaro': 1703,
    'Cardeto': 1703,
    'Cardinale': 1703,
    'Careri': 1703,
    'Carfizzi': 1703,
    'Cariati': 1703,
    'Carlopoli': 1703,
    'Carolei': 1703,
    'Carpanzano': 1703,
    'Casabona': 1703,
    'Casignana': 1703,
    'Casole Bruzio': 1703,
    'Cassano Allo Ionio': 1703,
    'Castelsilano': 1703,
    'Castiglione Cosentino': 1703,
    'Castrolibero': 1703,
    'Castroregio': 1703,
    'Castrovillari': 1703,
    'Catanzaro': 1703,
    'Caulonia': 1703,
    'Caulonia Marina': 1703,
    'Cava-Cuculera Nobile': 1703,
    'Cavoni-Ginestreto': 1703,
    'Celico': 1703,
    'Cellara': 1703,
    'Cenadi': 1703,
    'Centrache': 1703,
    'Ceramida-Pellegrina': 1703,
    'Cerchiara di Calabria': 1703,
    'Cerenzia': 1703,
    'Cerisano': 1703,
    'Cerva': 1703,
    'Cervicati': 1703,
    'Cerzeto': 1703,
    'Cessaniti': 1703,
    'Cetraro': 1703,
    'Cetraro Marina': 1703,
    'Chiaravalle Centrale': 1703,
    'Cicala': 1703,
    'Ciminà': 1703,
    'Cinque Frondi': 1703,
    'Cirò': 1703,
    'Cirò Marina': 1703,
    'Cittadella del Capo': 1703,
    'Cittanova': 1703,
    'Civita': 1703,
    'Cleto': 1703,
    'Colosimi': 1703,
    'Condofuri': 1703,
    'Conflenti': 1703,
    'Corigliano Calabro': 1703,
    'Corigliano Scalo': 1703,
    'Cortale': 1703,
    'Cosenza': 1703,
    'Cosoleto': 1703,
    'Cotronei': 1703,
    'Crichi': 1703,
    'Cropalati': 1703,
    'Cropani': 1703,
    'Cropani Marina': 1703,
    'Crosia': 1703,
    'Crotone': 1703,
    'Crucoli': 1703,
    'Curinga': 1703,
    'Cutro': 1703,
    'Dasà': 1703,
    'Davoli': 1703,
    'Decollatura': 1703,
    'Delianuova': 1703,
    'Diamante': 1703,
    'Dinami': 1703,
    'Dipignano': 1703,
    'Domanico': 1703,
    'Donnici Inferiore': 1703,
    'Doria': 1703,
    'Drapia': 1703,
    'Fabrizia': 1703,
    'Fabrizio': 1703,
    'Fagnano Castello': 1703,
    'Falconara Albanese': 1703,
    'Falerna': 1703,
    'Falerna Scalo': 1703,
    'Feroleto Antico': 1703,
    'Feroleto della Chiesa': 1703,
    'Ferruzzano': 1703,
    'Figline Vegliaturo': 1703,
    'Filadelfia': 1703,
    'Filandari': 1703,
    'Filogaso': 1703,
    'Firmo': 1703,
    'Fiumara': 1703,
    'Fiumefreddo Bruzio': 1703,
    'Foresta': 1703,
    'Fossato Ionico-Fossatello-San Luca Marcelluzzo': 1703,
    'Fossato Serralta': 1703,
    'Francavilla Angitola': 1703,
    'Francavilla Marittima': 1703,
    'Francica': 1703,
    'Frascineto': 1703,
    'Fuscaldo': 1703,
    'Gagliato': 1703,
    'Galatro': 1703,
    'Gasperina': 1703,
    'Gerace': 1703,
    'Gerocarne': 1703,
    'Giffone': 1703,
    'Gimigliano': 1703,
    'Gioia Tauro': 1703,
    'Gioiosa Ionica': 1703,
    'Girifalco': 1703,
    'Gizzeria': 1703,
    'Grimaldi': 1703,
    'Grisolia': 1703,
    'Grotteria': 1703,
    'Guardavalle': 1703,
    'Guardavalle Marina': 1703,
    'Guardia Piemontese': 1703,
    'Isca Marina': 1703,
    'Isca sullo Ionio': 1703,
    'Isola di Capo Rizzuto': 1703,
    'Jacurso': 1703,
    'Jonadi': 1703,
    'Joppolo': 1703,
    'Laganadi': 1703,
    'Lago': 1703,
    'Laino Borgo': 1703,
    'Laino Castello-Nuovo Centro': 1703,
    'Lamezia Terme': 1703,
    'Lappano': 1703,
    'Lattarico': 1703,
    'Laureana di Borrello': 1703,
    'Laurignano': 1703,
    'Lazzaro': 1703,
    'Le Castella': 1703,
    'Limbadi-Caroni': 1703,
    'Locri': 1703,
    'Longobardi': 1703,
    'Longobucco': 1703,
    'Lungro': 1703,
    'Luzzi': 1703,
    'Magisano': 1703,
    'Maida': 1703,
    'Maierà': 1703,
    'Maierato': 1703,
    'Malito': 1703,
    'Malvito': 1703,
    'Mammola': 1703,
    'Mandatoriccio': 1703,
    'Mangone': 1703,
    'Marano Marchesato': 1703,
    'Marano Principato': 1703,
    'Marcedusa': 1703,
    'Marcellina': 1703,
    'Marcellinara': 1703,
    'Marina': 1703,
    'Marina di Davoli': 1703,
    'Marina di Fuscaldo': 1703,
    'Marina di Gioiosa Ionica': 1703,
    'Marina di Schiavonea': 1703,
    'Maropati': 1703,
    'Martelli-Laganosa': 1703,
    'Martirano': 1703,
    'Martirano Lombardo': 1703,
    'Martone': 1703,
    'Marzi': 1703,
    'Melicuccà': 1703,
    'Melicucco': 1703,
    'Melissa': 1703,
    'Melito di Porto Salvo': 1703,
    'Mendicino': 1703,
    'Mesoraca': 1703,
    'Messignadi': 1703,
    'Miglierina': 1703,
    'Mileto': 1703,
    'Mirto': 1703,
    'Molochio': 1703,
    'Monasterace': 1703,
    'Monasterace Marina': 1703,
    'Mongiana': 1703,
    'Mongrassano': 1703,
    'Montalto Uffugo': 1703,
    'Montauro': 1703,
    'Montebello Jonico': 1703,
    'Montegiordano': 1703,
    'Montepaone': 1703,
    'Montepaone Lido': 1703,
    'Monterosso Calabro': 1703,
    'Morano Calabro': 1703,
    'Mormanno': 1703,
    'Mosorrofa': 1703,
    'Motta San Giovanni': 1703,
    'Motta Santa Lucia': 1703,
    'Mottafollone': 1703,
    'Nardodipace': 1703,
    'Natile Nuovo': 1703,
    'Nicastro': 1703,
    'Nicotera': 1703,
    'Nocara': 1703,
    'Nocera Scalo': 1703,
    'Nocera Terinese': 1703,
    'Olivadi': 1703,
    'Oppido Mamertina': 1703,
    'Oriolo': 1703,
    'Orsomarso': 1703,
    'Pagliarelle': 1703,
    'Palermiti': 1703,
    'Palizzi': 1703,
    'Palizzi Marina': 1703,
    'Pallagorio': 1703,
    'Palmi': 1703,
    'Paludi': 1703,
    'Panettieri': 1703,
    'Pannaconi': 1703,
    'Paola': 1703,
    'Papanice': 1703,
    'Papasidero': 1703,
    'Paravati': 1703,
    'Parenti': 1703,
    'Parghelia': 1703,
    'Paterno Calabro': 1703,
    'Pavigliana': 1703,
    'Pazzano': 1703,
    'Pedace-Perito': 1703,
    'Pedivigliano': 1703,
    'Pellaro': 1703,
    'Pentone': 1703,
    'Pernocari-Presinaci': 1703,
    'Petilia Policastro': 1703,
    'Petrizzi': 1703,
    'Petronà': 1703,
    'Piane Crati': 1703,
    'Pianopoli': 1703,
    'Pietrafitta': 1703,
    'Pietrapaola': 1703,
    'Piscopio': 1703,
    'Pizzo': 1703,
    'Pizzoni': 1703,
    'Placanica': 1703,
    'Plataci': 1703,
    'Platania': 1703,
    'Platì': 1703,
    'Polia': 1703,
    'Polistena': 1703,
    'Portigliola': 1703,
    'Praia a Mare': 1703,
    'Provincia di Catanzaro': 1703,
    'Provincia di Cosenza': 1703,
    'Provincia di Crotone': 1703,
    'Provincia di Reggio Calabria': 1703,
    'Provincia di Vibo-Valentia': 1703,
    'Quattromiglia': 1703,
    'Reggio Calabria': 1703,
    'Rende': 1703,
    'Riace': 1703,
    'Riace Marina': 1703,
    'Ricadi': 1703,
    'Rizziconi': 1703,
    'Rocca di Neto': 1703,
    'Rocca Imperiale': 1703,
    'Rocca Imperiale Marina': 1703,
    'Roccabernarda': 1703,
    'Roccaforte del Greco': 1703,
    'Roccella Ionica': 1703,
    'Roccelletta': 1703,
    'Roggiano Gravina': 1703,
    'Roghudi': 1703,
    'Rogliano': 1703,
    'Rombiolo': 1703,
    'Rosario': 1703,
    'Rosarno': 1703,
    'Rose': 1703,
    'Roseto Capo Spulico': 1703,
    'Rossano': 1703,
    'Rossano Stazione': 1703,
    'Rota Greca': 1703,
    'Rovito': 1703,
    'Saline Ioniche': 1703,
    'Sambiase': 1703,
    'Samo': 1703,
    'San Basile': 1703,
    'San Benedetto Ullano': 1703,
    'San Calogero': 1703,
    'San Carlo-Condofuri Marina': 1703,
    'San Cosmo Albanese': 1703,
    'San Costantino Calabro': 1703,
    'San Demetrio Corone': 1703,
    'San Donato di Ninea': 1703,
    'San Ferdinando': 1703,
    'San Fili': 1703,
    'San Floro': 1703,
    'San Giorgio Albanese': 1703,
    'San Giorgio Morgeto': 1703,
    'San Giovanni di Gerace': 1703,
    'San Giovanni in Fiore': 1703,
    'San Gregorio d  Ippona': 1703,
    'San Lorenzo': 1703,
    'San Lorenzo Bellizzi': 1703,
    'San Lorenzo del Vallo': 1703,
    'San Luca': 1703,
    'San Lucido': 1703,
    'San Mango d  Aquino': 1703,
    'San Marco Argentano': 1703,
    'San Martino': 1703,
    'San Martino di Finita': 1703,
    'San Mauro Marchesato': 1703,
    'San Nico': 1703,
    'San Nicola': 1703,
    'San Nicola Arcella': 1703,
    'San Nicola da Crissa': 1703,
    'San Nicola dell  Alto': 1703,
    'San Nicolò': 1703,
    'San Pietro a Maida': 1703,
    'San Pietro Apostolo': 1703,
    'San Pietro di Caridà': 1703,
    'San Pietro in Amantea': 1703,
    'San Pietro in Guarano': 1703,
    'San Procopio': 1703,
    'San Roberto': 1703,
    'San Sostene': 1703,
    'San Sosti': 1703,
    'San Vincenzo la Costa': 1703,
    'San Vito Sullo Ionio': 1703,
    'Sangineto': 1703,
    'Sant  Agata del Bianco': 1703,
    'Sant  Agata di Esaro': 1703,
    'Sant  Alessio in Aspromonte': 1703,
    'Sant  Andrea Apostolo dello Ionio': 1703,
    'Sant  Andrea Ionio Marina': 1703,
    'Sant  Elia': 1703,
    'Sant  Eufemia d  Aspromonte': 1703,
    'Sant  Ilario dello Ionio': 1703,
    'Sant  Onofrio': 1703,
    'Santa Caterina Albanese': 1703,
    'Santa Caterina dello Ionio': 1703,
    'Santa Caterina dello Ionio Marina': 1703,
    'Santa Cristina d  Aspromonte': 1703,
    'Santa Domenica': 1703,
    'Santa Domenica Talao': 1703,
    'Santa Eufemia Lamezia': 1703,
    'Santa Maria del Cedro': 1703,
    'Santa Severina': 1703,
    'Santa Sofia d  Epiro': 1703,
    'Santo Stefano': 1703,
    'Santo Stefano di Rogliano': 1703,
    'Santo Stefano in Aspromonte': 1703,
    'Saracena': 1703,
    'Sartano': 1703,
    'Satriano': 1703,
    'Savelli': 1703,
    'Scala Coeli': 1703,
    'Scalea': 1703,
    'Scandale': 1703,
    'Scarcelli': 1703,
    'Scido': 1703,
    'Scilla': 1703,
    'Sellia': 1703,
    'Sellia Marina': 1703,
    'Seminara': 1703,
    'Serra d  Aiello': 1703,
    'Serra Pedace': 1703,
    'Serra San Bruno': 1703,
    'Serrastretta': 1703,
    'Serrata': 1703,
    'Sersale': 1703,
    'Settimo': 1703,
    'Settingiano': 1703,
    'Siano': 1703,
    'Sibari': 1703,
    'Siderno': 1703,
    'Simbario': 1703,
    'Sinopoli': 1703,
    'Sorbo San Basile': 1703,
    'Sorianello': 1703,
    'Soriano Calabro': 1703,
    'Soverato Marina': 1703,
    'Soverato Superiore': 1703,
    'Soveria Mannelli': 1703,
    'Soveria Simeri': 1703,
    'Spadola': 1703,
    'Spezzano Albanese': 1703,
    'Spezzano della Sila': 1703,
    'Spezzano Piccolo': 1703,
    'Spilinga': 1703,
    'Squillace': 1703,
    'Squillace Lido': 1703,
    'Staiti': 1703,
    'Staletti': 1703,
    'Stazione Montalto-Coretto': 1703,
    'Stefanaconi': 1703,
    'Stignano': 1703,
    'Stilo': 1703,
    'Strongoli': 1703,
    'Surdo': 1703,
    'Tarsia': 1703,
    'Taurianova': 1703,
    'Taverna': 1703,
    'Terranova da Sibari': 1703,
    'Terranova Sappo Minulio': 1703,
    'Terravecchia': 1703,
    'Tiriolo': 1703,
    'Tivolille Pasquali-Merenzata': 1703,
    'Torano Castello': 1703,
    'Torre di Ruggiero': 1703,
    'Torre Melissa': 1703,
    'Torretta': 1703,
    'Tortora': 1703,
    'Tortora Marina': 1703,
    'Trebisacce': 1703,
    'Trenta': 1703,
    'Tronca': 1703,
    'Tropea': 1703,
    'Umbriatico': 1703,
    'Vaccarizzo Albanese': 1703,
    'Vadue': 1703,
    'Vallefiorita': 1703,
    'Vallelonga': 1703,
    'Varapodio': 1703,
    'Vazzano': 1703,
    'Verbicaro': 1703,
    'Verzino': 1703,
    'Vibo Valentia': 1703,
    'Villa San Giovanni': 1703,
    'Villapiana': 1703,
    'Villapiana Lido': 1703,
    'Weather Station': 1703,
    'Zaccanopoli': 1703,
    'Zagarise': 1703,
    'Zambrone': 1703,
    'Zumpano': 1703,
    'Zungri': 1703,
    'Acerno': 1669,
    'Acerra': 1669,
    'Afragola': 1669,
    'Agerola': 1669,
    'Agropoli': 1669,
    'Aiello del Sabato': 1669,
    'Ailano': 1669,
    'Airola': 1669,
    'Albanella': 1669,
    'Alfano': 1669,
    'Alife': 1669,
    'Altavilla Irpina': 1669,
    'Altavilla Silentina': 1669,
    'Alvignano': 1669,
    'Amalfi': 1669,
    'Amodio-Massariola': 1669,
    'Amorosi': 1669,
    'Anacapri': 1669,
    'Andretta-Mattinella': 1669,
    'Angri': 1669,
    'Annunziata': 1669,
    'Antessano': 1669,
    'Apice Vecchio': 1669,
    'Apollosa': 1669,
    'Aquara': 1669,
    'Aquilonia': 1669,
    'Arenella': 1669,
    'Ariano': 1669,
    'Ariano Irpino-Martiri': 1669,
    'Arienzo': 1669,
    'Ariola': 1669,
    'Arola-Preazzano': 1669,
    'Arpaia': 1669,
    'Arpaise': 1669,
    'Arpino': 1669,
    'Arzano': 1669,
    'Ascea': 1669,
    'Atena Lucana': 1669,
    'Atrani': 1669,
    'Atripalda': 1669,
    'Auletta': 1669,
    'Avella': 1669,
    'Avellino': 1669,
    'Aversa': 1669,
    'Avvocata': 1669,
    'Bacoli': 1669,
    'Bagnoli': 1669,
    'Bagnoli Irpino': 1669,
    'Baia': 1669,
    'Baiano': 1669,
    'Banzano': 1669,
    'Barano d  Ischia': 1669,
    'Baronissi': 1669,
    'Barra': 1669,
    'Baselice': 1669,
    'Battipaglia': 1669,
    'Bellizzi': 1669,
    'Bellona': 1669,
    'Bellosguardo': 1669,
    'Beltiglio-San Giovanni': 1669,
    'Benevento': 1669,
    'Bisaccia': 1669,
    'Bisaccia Nuova': 1669,
    'Bivio Mortola': 1669,
    'Bivio Santa Cecilia': 1669,
    'Bonea': 1669,
    'Bonito': 1669,
    'Borgo': 1669,
    'Boscoreale': 1669,
    'Boscotrecase': 1669,
    'Bracigliano': 1669,
    'Brezza': 1669,
    'Brusciano': 1669,
    'Bucciano': 1669,
    'Buccino': 1669,
    'Buonabitacolo': 1669,
    'Buonalbergo': 1669,
    'Caggiano': 1669,
    'Caianello': 1669,
    'Caiazzo': 1669,
    'Cairano': 1669,
    'Caivano': 1669,
    'Calabritto': 1669,
    'Calitri': 1669,
    'Calvanico': 1669,
    'Calvi': 1669,
    'Calvi Risorta': 1669,
    'Calvizzano': 1669,
    'Camerota': 1669,
    'Camigliano': 1669,
    'Campagna': 1669,
    'Campanarello': 1669,
    'Campolattaro': 1669,
    'Campoli del Monte Taburno': 1669,
    'Campora': 1669,
    'Camposano': 1669,
    'Cancello-Arnone': 1669,
    'Candida': 1669,
    'Cannalonga': 1669,
    'Capaccio': 1669,
    'Capaccio Scalo': 1669,
    'Capezzano Inferiore': 1669,
    'Capezzano-Cologna': 1669,
    'Capitignano': 1669,
    'Capodrise': 1669,
    'Caposele': 1669,
    'Capri': 1669,
    'Capriati A Volturno': 1669,
    'Capriglia': 1669,
    'Capriglia Irpina': 1669,
    'Capua': 1669,
    'Carano': 1669,
    'Carbonara di Nola': 1669,
    'Cardito': 1669,
    'Carife': 1669,
    'Carifi-Torello-Priscoli': 1669,
    'Carinaro': 1669,
    'Carinola': 1669,
    'Casagiove': 1669,
    'Casal di Principe': 1669,
    'Casal Velino': 1669,
    'Casalbore': 1669,
    'Casalbuono': 1669,
    'Casalduni': 1669,
    'Casale': 1669,
    'Casaletto Spartano': 1669,
    'Casali-San Potito': 1669,
    'Casalnuovo di Napoli': 1669,
    'Casaluce': 1669,
    'Casamarciano': 1669,
    'Casamicciola Terme': 1669,
    'Casandrino': 1669,
    'Casanova': 1669,
    'Casapesenna': 1669,
    'Casapulla': 1669,
    'Casarea': 1669,
    'Casavatore': 1669,
    'Cascano': 1669,
    'Caselle in Pittari': 1669,
    'Caserta': 1669,
    'Casola': 1669,
    'Casola di Napoli': 1669,
    'Casoria': 1669,
    'Cassano Irpino': 1669,
    'Castel Baronia': 1669,
    'Castel Campagnano': 1669,
    'Castel di Sasso': 1669,
    'Castel Morrone': 1669,
    'Castel San Giorgio': 1669,
    'Castel San Lorenzo': 1669,
    'Castel Volturno': 1669,
    'Castelcivita': 1669,
    'Castelfranci': 1669,
    'Castelfranco in Miscano': 1669,
    'Castellammare di Stabia': 1669,
    'Castello del Matese': 1669,
    'Castello di Cisterna': 1669,
    'Castelnuovo Cilento': 1669,
    'Castelnuovo di Conza': 1669,
    'Castelpagano': 1669,
    'Castelpoto': 1669,
    'Castelvenere': 1669,
    'Castelvetere in Val Fortore': 1669,
    'Castelvetere sul Calore': 1669,
    'Castiglione del Genovesi': 1669,
    'Cautano': 1669,
    'Cava Dè Tirreni': 1669,
    'Celle di Bulgheria': 1669,
    'Cellole': 1669,
    'Centola': 1669,
    'Centro Urbano': 1669,
    'Ceppaloni': 1669,
    'Ceraso': 1669,
    'Cercola': 1669,
    'Cerreto Sannita': 1669,
    'Cervinara': 1669,
    'Cervino': 1669,
    'Cesa': 1669,
    'Cesinali': 1669,
    'Cetara': 1669,
    'Chiaia': 1669,
    'Chiaiano': 1669,
    'Chianche': 1669,
    'Chiusano di San Domenico': 1669,
    'Cicciano': 1669,
    'Cicerale': 1669,
    'Cimitile': 1669,
    'Ciorlano': 1669,
    'Circello': 1669,
    'Colle Sannita': 1669,
    'Colliano': 1669,
    'Comiziano': 1669,
    'Conca dei Marini': 1669,
    'Conca della Campania': 1669,
    'Contrada': 1669,
    'Controne': 1669,
    'Contursi Terme': 1669,
    'Coperchia': 1669,
    'Corbara': 1669,
    'Corleto Monforte': 1669,
    'Country Park': 1669,
    'Crispano': 1669,
    'Cuccaro Vetere': 1669,
    'Curti': 1669,
    'Cusano Mutri': 1669,
    'Domicella': 1669,
    'Dragonea': 1669,
    'Dragoni': 1669,
    'Dugenta': 1669,
    'Durazzano': 1669,
    'Eboli': 1669,
    'Ercolano': 1669,
    'Faiano': 1669,
    'Faicchio': 1669,
    'Falciano del Massico': 1669,
    'Faraldo-Nocelleto': 1669,
    'Felitto': 1669,
    'Fisciano': 1669,
    'Flumeri': 1669,
    'Foglianise': 1669,
    'Foiano di Val Fortore': 1669,
    'Fontanarosa': 1669,
    'Fontegreca': 1669,
    'Forchia': 1669,
    'Forino': 1669,
    'Forio': 1669,
    'Formicola': 1669,
    'Fragneto L  Abate': 1669,
    'Fragneto Monforte': 1669,
    'Franche': 1669,
    'Francolise': 1669,
    'Frasso Telesino': 1669,
    'Frattamaggiore': 1669,
    'Frattaminore': 1669,
    'Frigento': 1669,
    'Frignano': 1669,
    'Fuorigrotta': 1669,
    'Fuorni': 1669,
    'Furore': 1669,
    'Futani': 1669,
    'Gallo': 1669,
    'Gallo Matese': 1669,
    'Galluccio': 1669,
    'Gesualdo': 1669,
    'Giano Vetusto': 1669,
    'Giffoni Valle Piana': 1669,
    'Ginestra degli Schiavoni': 1669,
    'Gioi': 1669,
    'Gioia Sannitica': 1669,
    'Giugliano in Campania': 1669,
    'Giungano': 1669,
    'Gragnano': 1669,
    'Grazzanise': 1669,
    'Greci': 1669,
    'Gricignano di Aversa': 1669,
    'Grottaminarda': 1669,
    'Grottola': 1669,
    'Grottolella': 1669,
    'Grumo Nevano': 1669,
    'Guardia Lombardi': 1669,
    'Guardia Sanframondi': 1669,
    'Ischia': 1669,
    'Ischia Porto': 1669,
    'Ispani': 1669,
    'Lacco Ameno': 1669,
    'Lacedonia': 1669,
    'Lancusi-Penta-Bolano': 1669,
    'Lanzara': 1669,
    'Lapio': 1669,
    'Laura': 1669,
    'Laureana Cilento': 1669,
    'Laurino': 1669,
    'Laurito': 1669,
    'Lauro': 1669,
    'Laviano': 1669,
    'Letino': 1669,
    'Lettere': 1669,
    'Liberi': 1669,
    'Licinella-Torre di Paestum': 1669,
    'Licusati': 1669,
    'Limatola': 1669,
    'Lioni': 1669,
    'Liveri': 1669,
    'Luogosano': 1669,
    'Lusciano': 1669,
    'Lustra': 1669,
    'Luzzano': 1669,
    'Macchia': 1669,
    'Maccoli-Perrillo': 1669,
    'Macerata Campania': 1669,
    'Maddaloni': 1669,
    'Magliano Vetere': 1669,
    'Maiori': 1669,
    'Malche-Santa Croce-Serroni': 1669,
    'Manocalzati': 1669,
    'Marano di Napoli': 1669,
    'Marcianise': 1669,
    'Mariglianella': 1669,
    'Marigliano': 1669,
    'Marina di Camerota': 1669,
    'Marina di Casal Velino': 1669,
    'Marzanello': 1669,
    'Marzano Appio': 1669,
    'Marzano di Nola': 1669,
    'Massa di Somma': 1669,
    'Massa Lubrense': 1669,
    'Masseria Vecchia Ovest': 1669,
    'Matinella': 1669,
    'Melito di Napoli': 1669,
    'Melito Irpino': 1669,
    'Melizzano': 1669,
    'Mercato': 1669,
    'Mercato San Severino': 1669,
    'Mercogliano': 1669,
    'Meta': 1669,
    'Miano': 1669,
    'Mignano Monte Lungo': 1669,
    'Minori': 1669,
    'Mirabella Eclano': 1669,
    'Moiano': 1669,
    'Moio della Civitella-Pellare': 1669,
    'Molinara': 1669,
    'Mondragone': 1669,
    'Montaguto': 1669,
    'Montano Antilia': 1669,
    'Monte di Procida': 1669,
    'Monte San Giacomo': 1669,
    'Montecalvario': 1669,
    'Montecalvo Irpino': 1669,
    'Montecorice': 1669,
    'Montecorvino Pugliano': 1669,
    'Montecorvino Rovella': 1669,
    'Montedecoro': 1669,
    'Montefalcione': 1669,
    'Montefalcone di Val Fortore': 1669,
    'Monteforte Cilento': 1669,
    'Monteforte Irpino': 1669,
    'Montefredane': 1669,
    'Montefusco': 1669,
    'Montella': 1669,
    'Montemarano': 1669,
    'Montemiletto': 1669,
    'Monterusciello': 1669,
    'Montesano sulla Marcellana': 1669,
    'Montesarchio': 1669,
    'Monteverde': 1669,
    'Monticelli': 1669,
    'Montoro Superiore': 1669,
    'Morcone': 1669,
    'Morigerati': 1669,
    'Morra de Sanctis': 1669,
    'Moschiano': 1669,
    'Mugnano del Cardinale': 1669,
    'Mugnano di Napoli': 1669,
    'Musci': 1669,
    'Naples': 1669,
    'Napoli': 1669,
    'Nocelleto': 1669,
    'Nocera Inferiore': 1669,
    'Nocera Superiore': 1669,
    'Nola': 1669,
    'Novi Velia': 1669,
    'Nuova Conza della Campania': 1669,
    'Nusco': 1669,
    'Ogliastro Cilento': 1669,
    'Olevano sul Tusciano': 1669,
    'Oliveto Citra': 1669,
    'Omignano': 1669,
    'Orria': 1669,
    'Orta di Atella': 1669,
    'Ospedaletto d  Alpinolo': 1669,
    'Ottati': 1669,
    'Ottaviano': 1669,
    'Padula': 1669,
    'Paduli': 1669,
    'Pagani': 1669,
    'Pagliarone': 1669,
    'Pago del Vallo di Lauro': 1669,
    'Pago Veiano': 1669,
    'Palinuro': 1669,
    'Palma Campania': 1669,
    'Palomonte': 1669,
    'Pannarano': 1669,
    'Paolisi': 1669,
    'Parete': 1669,
    'Parolise': 1669,
    'Pascarola': 1669,
    'Passo di Mirabella-Pianopantano': 1669,
    'Pastorano': 1669,
    'Paternopoli': 1669,
    'Paupisi': 1669,
    'Pellezzano': 1669,
    'Pendino': 1669,
    'Perdifumo': 1669,
    'Perito': 1669,
    'Pertosa': 1669,
    'Pesco Sannita': 1669,
    'Petina': 1669,
    'Petruro Irpino': 1669,
    'Pezzano-Filetta': 1669,
    'Piaggine': 1669,
    'Piana di Monte Verna': 1669,
    'Pianillo': 1669,
    'Piano': 1669,
    'Piano di Sorrento': 1669,
    'Pianura': 1669,
    'Piazza del Galdo-Sant  Angelo': 1669,
    'Piazza di Pandola': 1669,
    'Piazza Roma': 1669,
    'Piazza-Tralia-Pendolo': 1669,
    'Piazzola': 1669,
    'Piazzolla': 1669,
    'Piedimonte': 1669,
    'Piedimonte Matese': 1669,
    'Piegolelle-San Bartolomeo': 1669,
    'Pietradefusi': 1669,
    'Pietramelara': 1669,
    'Pietraroja': 1669,
    'Pietrastornina': 1669,
    'Pietravairano': 1669,
    'Pietre': 1669,
    'Pietrelcina': 1669,
    'Pignataro Maggiore': 1669,
    'Pimonte': 1669,
    'Piscinola': 1669,
    'Pisciotta': 1669,
    'Poggiomarino': 1669,
    'Poggioreale': 1669,
    'Policastro Bussentino': 1669,
    'Polla': 1669,
    'Pollena Trocchia': 1669,
    'Pollica': 1669,
    'Polvica': 1669,
    'Pomigliano d  Arco': 1669,
    'Pompei': 1669,
    'Ponte': 1669,
    'Pontecagnano': 1669,
    'Pontelandolfo': 1669,
    'Pontelatone': 1669,
    'Ponticelli': 1669,
    'Portici': 1669,
    'Portico di Caserta': 1669,
    'Porto': 1669,
    'Posillipo': 1669,
    'Positano': 1669,
    'Postiglione': 1669,
    'Pozzillo': 1669,
    'Pozzuoli': 1669,
    'Praiano': 1669,
    'Prata di Principato Ultra': 1669,
    'Prata Sannita Centro': 1669,
    'Pratella': 1669,
    'Prato Perillo': 1669,
    'Pratola Serra': 1669,
    'Pratole': 1669,
    'Prepezzano': 1669,
    'Presenzano': 1669,
    'Prignano Cilento': 1669,
    'Procida': 1669,
    'Provincia di Avellino': 1669,
    'Provincia di Benevento': 1669,
    'Provincia di Caserta': 1669,
    'Provincia di Salerno': 1669,
    'Puglianello': 1669,
    'Pugliano': 1669,
    'Quadrelle': 1669,
    'Quadrivio': 1669,
    'Qualiano': 1669,
    'Quarto': 1669,
    'Quindici': 1669,
    'Ravello': 1669,
    'Raviscanina': 1669,
    'Recale': 1669,
    'Reino': 1669,
    'Rettifilo-Vannullo': 1669,
    'Riardo': 1669,
    'Ricigliano': 1669,
    'Rocca San Felice': 1669,
    'Roccabascerana': 1669,
    'Roccadaspide': 1669,
    'Roccagloriosa': 1669,
    'Roccamonfina': 1669,
    'Roccapiemonte': 1669,
    'Roccarainola': 1669,
    'Roccaromana': 1669,
    'Rocchetta': 1669,
    'Rofrano': 1669,
    'Roscigno': 1669,
    'Rotondi': 1669,
    'Rutino': 1669,
    'Ruviano': 1669,
    'Sacco': 1669,
    'Sala': 1669,
    'Sala Consilina': 1669,
    'Salento': 1669,
    'Salerno': 1669,
    'Salitto-Valle': 1669,
    'Salvitelle': 1669,
    'Salza Irpina': 1669,
    'San Bartolomeo in Galdo': 1669,
    'San Carlo All  Arena': 1669,
    'San Castrese': 1669,
    'San Cipriano d  Aversa': 1669,
    'San Cipriano Picentino': 1669,
    'San Clemente': 1669,
    'San Felice A Cancello': 1669,
    'San Ferdinando': 1669,
    'San Gennaro Vesuviano': 1669,
    'San Giorgio': 1669,
    'San Giorgio a Cremano': 1669,
    'San Giorgio del Sannio': 1669,
    'San Giorgio la Molara': 1669,
    'San Giovanni A Piro': 1669,
    'San Giovanni a Teduccio': 1669,
    'San Giuseppe': 1669,
    'San Giuseppe Vesuviano': 1669,
    'San Gregorio Magno': 1669,
    'San Gregorio Matese': 1669,
    'San Leucio del Sannio-Cavuoti': 1669,
    'San Lorenzello': 1669,
    'San Lorenzo': 1669,
    'San Lorenzo Maggiore': 1669,
    'San Lupo': 1669,
    'San Mango Piemonte': 1669,
    'San Mango sul Calore': 1669,
    'San Marcellino': 1669,
    'San Marco': 1669,
    'San Marco dei Cavoti': 1669,
    'San Marco Evangelista': 1669,
    'San Martino Sannita': 1669,
    'San Martino Valle Caudina': 1669,
    'San Marzano sul Sarno': 1669,
    'San Mauro Cilento': 1669,
    'San Mauro la Bruca': 1669,
    'San Michele': 1669,
    'San Michele di Serino': 1669,
    'San Nazzaro': 1669,
    'San Nicola Baronia': 1669,
    'San Nicola la Strada': 1669,
    'San Nicola Manfredi': 1669,
    'San Paolo Bel Sito': 1669,
    'San Pietro': 1669,
    'San Pietro a Patierno': 1669,
    'San Pietro al Tanagro': 1669,
    'San Pietro Infine': 1669,
    'San Potito Sannitico': 1669,
    'San Potito Ultra': 1669,
    'San Prisco': 1669,
    'San Rufo': 1669,
    'San Salvatore Telesino': 1669,
    'San Sebastiano al Vesuvio': 1669,
    'San Sossio Baronia': 1669,
    'San Tammaro': 1669,
    'San Valentino Torio': 1669,
    'San Vitaliano': 1669,
    'Sant  Agata de  Goti': 1669,
    'Sant  Agata sui Due Golfi': 1669,
    'Sant  Agnello': 1669,
    'Sant  Anastasia': 1669,
    'Sant  Andrea di Conza': 1669,
    'Sant  Andrea-Pizzone-Ciamprisco': 1669,
    'Sant  Angelo': 1669,
    'Sant  Angelo A Cupolo': 1669,
    'Sant  Angelo A Fasanella': 1669,
    'Sant  Angelo A Scala': 1669,
    'Sant  Angelo All  Esca': 1669,
    'Sant  Angelo d  Alife': 1669,
    'Sant  Angelo dei Lombardi': 1669,
    'Sant  Angelo in Formis': 1669,
    'Sant  Antimo': 1669,
    'Sant  Antonio': 1669,
    'Sant  Antonio Abate': 1669,
    'Sant  Arcangelo Trimonte': 1669,
    'Sant  Arpino': 1669,
    'Sant  Arsenio': 1669,
    'Sant  Egidio del Monte Albino': 1669,
    'Sant  Elena Irpina': 1669,
    'Santa Croce del Sannio': 1669,
    'Santa Lucia': 1669,
    'Santa Lucia di Serino': 1669,
    'Santa Maria': 1669,
    'Santa Maria A Vico': 1669,
    'Santa Maria Capua Vetere': 1669,
    'Santa Maria La Carità': 1669,
    'Santa Maria la Fossa': 1669,
    'Santa Marina': 1669,
    'Santa Paolina': 1669,
    'Santa Tecla-Castelpagano': 1669,
    'Santo Stefano del Sole': 1669,
    'Santomenna': 1669,
    'Sanza': 1669,
    'Sapri': 1669,
    'Sarno': 1669,
    'Sassano': 1669,
    'Sassinoro': 1669,
    'Saviano': 1669,
    'Savignano Irpino': 1669,
    'Scafati': 1669,
    'Scala': 1669,
    'Scalo Romagnano al Monte': 1669,
    'Scampia': 1669,
    'Scampitella': 1669,
    'Scario': 1669,
    'Scisciano': 1669,
    'Secondigliano': 1669,
    'Senerchia': 1669,
    'Serino': 1669,
    'Serramezzana': 1669,
    'Serrara Fontana': 1669,
    'Serre': 1669,
    'Sessa Aurunca': 1669,
    'Sessa Cilento': 1669,
    'Siano': 1669,
    'Sicignano degli Alburni': 1669,
    'Sirignano': 1669,
    'Soccavo': 1669,
    'Solofra': 1669,
    'Solopaca': 1669,
    'Somma Vesuviana': 1669,
    'Sorbo Serpico': 1669,
    'Sorrento': 1669,
    'Sparanise': 1669,
    'Spartimento': 1669,
    'Sperone': 1669,
    'Starza Vecchia': 1669,
    'Stella': 1669,
    'Stella Cilento': 1669,
    'Stio': 1669,
    'Striano': 1669,
    'Sturno': 1669,
    'Succivo': 1669,
    'Summonte': 1669,
    'Taurano': 1669,
    'Taurasi': 1669,
    'Teano': 1669,
    'Teggiano-Macchiaroli': 1669,
    'Telese': 1669,
    'Teora': 1669,
    'Terzigno': 1669,
    'Teverola': 1669,
    'Tocco Caudio': 1669,
    'Tora': 1669,
    'Torchiara': 1669,
    'Torchiati': 1669,
    'Torella dei Lombardi': 1669,
    'Torelli-Torrette': 1669,
    'Torraca': 1669,
    'Torre Annunziata': 1669,
    'Torre Caracciolo': 1669,
    'Torre del Greco': 1669,
    'Torre le Nocelle': 1669,
    'Torre Orsaia': 1669,
    'Torrecuso': 1669,
    'Torretta-Scalzapecora': 1669,
    'Torrioni': 1669,
    'Tortorella': 1669,
    'Tramonti': 1669,
    'Trecase': 1669,
    'Trentinara': 1669,
    'Trentola-Ducenta': 1669,
    'Trescine': 1669,
    'Trevico': 1669,
    'Tufino': 1669,
    'Tufo': 1669,
    'Vairano-Patenora': 1669,
    'Vallata': 1669,
    'Valle Agricola': 1669,
    'Valle dell  Angelo': 1669,
    'Valle di Maddaloni': 1669,
    'Vallesaccarda': 1669,
    'Vallo della Lucania': 1669,
    'Valva': 1669,
    'Venticano': 1669,
    'Vibonati': 1669,
    'Vicaria': 1669,
    'Vico Equense': 1669,
    'Vietri sul Mare': 1669,
    'Villa di Briano': 1669,
    'Villa Literno': 1669,
    'Villamaina': 1669,
    'Villammare': 1669,
    'Villanova del Battista': 1669,
    'Villaricca': 1669,
    'Visciano': 1669,
    'Vitulano': 1669,
    'Vitulazio': 1669,
    'Volla': 1669,
    'Volturara Irpina': 1669,
    'Vomero': 1669,
    'Zona Industriale': 1669,
    'Zungoli': 1669,
    'Zuni': 1669,
    'Agazzano': 1773,
    'Albareto': 1773,
    'Alberi': 1773,
    'Albinea': 1773,
    'Alfonsine': 1773,
    'Alseno': 1773,
    'Altedo': 1773,
    'Anzola dell  Emilia': 1773,
    'Arceto': 1773,
    'Argelato': 1773,
    'Argenta': 1773,
    'Argine': 1773,
    'Baganzola': 1773,
    'Baggiovara': 1773,
    'Bagnacavallo': 1773,
    'Bagnara di Romagna': 1773,
    'Bagnarola': 1773,
    'Bagno': 1773,
    'Bagnolo in Piano': 1773,
    'Baiso': 1773,
    'Barbiano': 1773,
    'Barco': 1773,
    'Bardi': 1773,
    'Baricella': 1773,
    'Basilicagoiano': 1773,
    'Basilicanova': 1773,
    'Bastiglia': 1773,
    'Bazzano': 1773,
    'Bedonia': 1773,
    'Bellaria-Igea Marina': 1773,
    'Bentivoglio': 1773,
    'Berceto': 1773,
    'Berra': 1773,
    'Bertinoro': 1773,
    'Berzantina': 1773,
    'Besenzone': 1773,
    'Bettola': 1773,
    'Bibbiano': 1773,
    'Bobbio': 1773,
    'Bologna': 1773,
    'Bomporto': 1773,
    'Bondeno': 1773,
    'Bora Bassa': 1773,
    'Bore': 1773,
    'Borello': 1773,
    'Boretto': 1773,
    'Borghi': 1773,
    'Borgo Tossignano': 1773,
    'Borgo Val di Taro': 1773,
    'Borgonovo Valtidone': 1773,
    'Borgonuovo': 1773,
    'Borzano': 1773,
    'Bosco Mesola': 1773,
    'Brescello': 1773,
    'Brisighella': 1773,
    'Bubano': 1773,
    'Budrio': 1773,
    'Busana': 1773,
    'Busseto': 1773,
    'Cà Dè Fabbri': 1773,
    'Ca   di Sola': 1773,
    'Cadè-Gaida': 1773,
    'Cadelbosco di Sopra': 1773,
    'Cadelbosco di Sotto': 1773,
    'Calcara': 1773,
    'Calderara di Reno': 1773,
    'Calderino': 1773,
    'Calendasco': 1773,
    'Calerno': 1773,
    'Calestano': 1773,
    'Caminata': 1773,
    'Campagnola Emilia': 1773,
    'Campegine': 1773,
    'Campogalliano': 1773,
    'Camposanto': 1773,
    'Camugnano': 1773,
    'Caorso': 1773,
    'Caprara': 1773,
    'Carpaneto Piacentino': 1773,
    'Carpi Centro': 1773,
    'Carpineti': 1773,
    'Casale': 1773,
    'Casalecchio di Reno': 1773,
    'Casalfiumanese': 1773,
    'Casalgrande': 1773,
    'Casina': 1773,
    'Casola Valsenio': 1773,
    'Castel Bolognese': 1773,
    'Castel d  Aiano': 1773,
    'Castel del Rio': 1773,
    'Castel di Casio': 1773,
    'Castel Guelfo di Bologna': 1773,
    'Castel Maggiore': 1773,
    'Castel San Giovanni': 1773,
    'Castel San Pietro Terme': 1773,
    'Casteldelci': 1773,
    'Castelfranco Emilia': 1773,
    'Castell  Arquato': 1773,
    'Castellarano': 1773,
    'Castelletto': 1773,
    'Castello d  Argile': 1773,
    'Castelnovo di Sotto': 1773,
    'Castelnovo ne  Monti': 1773,
    'Castelnuovo Rangone': 1773,
    'Castelvetro di Modena': 1773,
    'Castelvetro Piacentino': 1773,
    'Castenaso': 1773,
    'Castiglione dei Pepoli': 1773,
    'Castrocaro Terme e Terra del Sole': 1773,
    'Cattolica': 1773,
    'Cavazzona': 1773,
    'Cavezzo': 1773,
    'Cavriago': 1773,
    'Cento': 1773,
    'Cerasolo': 1773,
    'Cerignale': 1773,
    'Cervia': 1773,
    'Cesena': 1773,
    'Cesenatico': 1773,
    'Ciano d  Enza': 1773,
    'Civitella di Romagna': 1773,
    'Classe': 1773,
    'Coccanile-Cesta': 1773,
    'Codemondo-Quaresimo': 1773,
    'Codigoro': 1773,
    'Codisotto': 1773,
    'Coli': 1773,
    'Collagna': 1773,
    'Collecchio': 1773,
    'Colombaro': 1773,
    'Colorno': 1773,
    'Comacchio': 1773,
    'Compiano': 1773,
    'Cona': 1773,
    'Concordia sulla Secchia': 1773,
    'Consandolo': 1773,
    'Conselice': 1773,
    'Copparo': 1773,
    'Corcagnano': 1773,
    'Coriano': 1773,
    'Corniglio': 1773,
    'Corpo Reno': 1773,
    'Corpolò': 1773,
    'Correggio': 1773,
    'Cortemaggiore': 1773,
    'Cotignola': 1773,
    'Crespellano': 1773,
    'Crevalcore': 1773,
    'Cusercoli': 1773,
    'Decima': 1773,
    'Dodici Morelli': 1773,
    'Dosso': 1773,
    'Dovadola': 1773,
    'Dozza': 1773,
    'Fabbrico': 1773,
    'Faenza': 1773,
    'Fanano': 1773,
    'Farini': 1773,
    'Felegara': 1773,
    'Felina': 1773,
    'Felino': 1773,
    'Fellegara': 1773,
    'Ferrara': 1773,
    'Ferriere': 1773,
    'Fidenza': 1773,
    'Finale Emilia': 1773,
    'Fiorano': 1773,
    'Fiorenzuola d  Arda': 1773,
    'Fiumalbo-Dogana': 1773,
    'Fiumana': 1773,
    'Fogliano': 1773,
    'Fognano': 1773,
    'Fontanelice': 1773,
    'Fontanellato': 1773,
    'Fontevivo': 1773,
    'Forche': 1773,
    'Forlì': 1773,
    'Forlimpopoli': 1773,
    'Formica': 1773,
    'Formigine': 1773,
    'Formignana': 1773,
    'Fornace Zarattini': 1773,
    'Fornovo di Taro': 1773,
    'Fossa': 1773,
    'Fosso Ghiaia': 1773,
    'Fossoli': 1773,
    'Francolino': 1773,
    'Frassinoro': 1773,
    'Fratta Terme': 1773,
    'Fusignano': 1773,
    'Gaggio': 1773,
    'Gaggio Montano': 1773,
    'Gaiano': 1773,
    'Gaibanella-Sant  Edigio': 1773,
    'Galeata': 1773,
    'Gallo': 1773,
    'Gambettola': 1773,
    'Gatteo a Mare': 1773,
    'Gatteo-Sant  Angelo': 1773,
    'Gazzola': 1773,
    'Gemmano': 1773,
    'Glorie': 1773,
    'Godo': 1773,
    'Goro': 1773,
    'Gorzano': 1773,
    'Gossolengo': 1773,
    'Gragnano Trebbiense': 1773,
    'Granarolo': 1773,
    'Granarolo dell  Emilia e Viadagola': 1773,
    'Grizzana': 1773,
    'Grizzana Morandi': 1773,
    'Gropparello': 1773,
    'Gualtieri': 1773,
    'Guastalla': 1773,
    'Guiglia': 1773,
    'Imola': 1773,
    'Jolanda di Savoia': 1773,
    'La Saletta-Tamara': 1773,
    'Lagosanto': 1773,
    'Lama': 1773,
    'Langhirano': 1773,
    'Lavezzola': 1773,
    'Lentigione-Sorbolo a Mane': 1773,
    'Lesignano de  Bagni': 1773,
    'Lido Adriano': 1773,
    'Lido degli Estensi': 1773,
    'Lido di Pomposa-Lido degli Scacchi': 1773,
    'Ligonchio': 1773,
    'Limidi': 1773,
    'Lizzano in Belvedere': 1773,
    'Loiano': 1773,
    'Longara': 1773,
    'Longastrino': 1773,
    'Longiano': 1773,
    'Lugagnano Val d  Arda': 1773,
    'Lugo': 1773,
    'Luzzara': 1773,
    'Madonna Dell  Albero': 1773,
    'Magreta': 1773,
    'Malalbergo': 1773,
    'Manzolino': 1773,
    'Maranello': 1773,
    'Marano sul Panaro': 1773,
    'Marina di Ravenna': 1773,
    'Marina Romea': 1773,
    'Marsaglia': 1773,
    'Marzabotto': 1773,
    'Masi-Torello': 1773,
    'Massa Finalese': 1773,
    'Massa Fiscaglia': 1773,
    'Massa Lombarda': 1773,
    'Massenzatico': 1773,
    'Medesano': 1773,
    'Medicina-Buda': 1773,
    'Medolla': 1773,
    'Meldola': 1773,
    'Mercato Saraceno': 1773,
    'Mesola': 1773,
    'Mezzano': 1773,
    'Mezzano Inferiore': 1773,
    'Mezzogoro': 1773,
    'Mezzolara': 1773,
    'Migliarina': 1773,
    'Migliarino': 1773,
    'Migliaro': 1773,
    'Milano Marittima': 1773,
    'Minerbio': 1773,
    'Mirabello': 1773,
    'Mirandola': 1773,
    'Misano Adriatico': 1773,
    'Modena': 1773,
    'Modigliana': 1773,
    'Molinella': 1773,
    'Molino del Pallone': 1773,
    'Monchio delle Corti': 1773,
    'Mondaino': 1773,
    'Monghidoro': 1773,
    'Montalbano': 1773,
    'Montale': 1773,
    'Monte Colombo': 1773,
    'Monte San Giovanni': 1773,
    'Montecavolo': 1773,
    'Montecchio Emilia': 1773,
    'Montechiarugolo': 1773,
    'Montecreto': 1773,
    'Montefiore Conca': 1773,
    'Montefiorino': 1773,
    'Montegridolfo': 1773,
    'Monterenzio': 1773,
    'Montescudo': 1773,
    'Montese': 1773,
    'Montiano': 1773,
    'Monticelli d  Ongina': 1773,
    'Monticelli Terme': 1773,
    'Monzuno': 1773,
    'Morciano di Romagna': 1773,
    'Mordano': 1773,
    'Morfasso': 1773,
    'Mulino': 1773,
    'Neviano degli Arduini': 1773,
    'Nibbiano': 1773,
    'Niviano': 1773,
    'Noceto': 1773,
    'Nonantola': 1773,
    'Novafeltria': 1773,
    'Novellara': 1773,
    'Novi di Modena': 1773,
    'Ospedaletto': 1773,
    'Ostellato': 1773,
    'Osteria Grande': 1773,
    'Osteria Nuova': 1773,
    'Ottone': 1773,
    'Ozzano dell  Emilia': 1773,
    'Ozzano Taro': 1773,
    'Padulle': 1773,
    'Palagano': 1773,
    'Palanzano': 1773,
    'Panighina': 1773,
    'Parma': 1773,
    'Pavullo nel Frignano': 1773,
    'Pecorara': 1773,
    'Pellegrino Parmense': 1773,
    'Pennabilli': 1773,
    'Piacenza': 1773,
    'Pianello Val Tidone': 1773,
    'Piangipane': 1773,
    'Pianoro': 1773,
    'Pietracuta': 1773,
    'Pieve di Cento': 1773,
    'Pievepelago': 1773,
    'Pilastro': 1773,
    'Pinarella': 1773,
    'Piozzano': 1773,
    'Pisignano': 1773,
    'Piumazzo': 1773,
    'Pizzano': 1773,
    'Podenzano': 1773,
    'Poggio Berni': 1773,
    'Poggio Renatico': 1773,
    'Polesine Parmense': 1773,
    'Polinago': 1773,
    'Ponte dell  Olio': 1773,
    'Ponte della Venturina': 1773,
    'Ponte Ronca': 1773,
    'Ponte Taro': 1773,
    'Pontegradella': 1773,
    'Pontelangorino': 1773,
    'Pontenure': 1773,
    'Ponticella': 1773,
    'Porotto-Cassama': 1773,
    'Porporano': 1773,
    'Porretta Terme': 1773,
    'Portico di Romagna': 1773,
    'Portico e San Benedetto': 1773,
    'Porto Corsini': 1773,
    'Porto Fuori': 1773,
    'Porto Garibaldi': 1773,
    'Portomaggiore': 1773,
    'Poviglio': 1773,
    'Pozza': 1773,
    'Praticello': 1773,
    'Pratissolo': 1773,
    'Prato': 1773,
    'Predappio': 1773,
    'Premilcuore': 1773,
    'Prignano': 1773,
    'Prignano sulla Secchia': 1773,
    'Progresso': 1773,
    'Provincia di Ferrara': 1773,
    'Provincia di Forlì-Cesena': 1773,
    'Provincia di Modena': 1773,
    'Provincia di Parma': 1773,
    'Provincia di Piacenza': 1773,
    'Provincia di Ravenna': 1773,
    'Provincia di Reggio Emilia': 1773,
    'Provincia di Rimini': 1773,
    'Puianello': 1773,
    'Pulce': 1773,
    'Punta Marina': 1773,
    'Quarantoli': 1773,
    'Quartesana': 1773,
    'Quarto Inferiore': 1773,
    'Quattro Castella': 1773,
    'Ramiola': 1773,
    'Ramiseto': 1773,
    'Rastignano-Carteria di Sesto': 1773,
    'Ravarino': 1773,
    'Ravenna': 1773,
    'Reggio nell  Emilia': 1773,
    'Reggiolo': 1773,
    'Renazzo': 1773,
    'Riale': 1773,
    'Riccione': 1773,
    'Riccò': 1773,
    'Rimini': 1773,
    'Rio Saliceto': 1773,
    'Riolo Terme': 1773,
    'Riolunato': 1773,
    'Rivazzurra': 1773,
    'Rivergaro': 1773,
    'Ro': 1773,
    'Rocca San Casciano': 1773,
    'Roccabianca': 1773,
    'Rolo': 1773,
    'Roncocesi': 1773,
    'Roncofreddo': 1773,
    'Roncofreddo-Santa Paola': 1773,
    'Roteglia': 1773,
    'Rottofreno': 1773,
    'Roveleto': 1773,
    'Rovereto': 1773,
    'Rubiera': 1773,
    'Russi': 1773,
    'Sala': 1773,
    'Sala Baganza': 1773,
    'Sala Bolognese': 1773,
    'Salsomaggiore Terme': 1773,
    'Saludecio': 1773,
    'Salvaterra': 1773,
    'Sam Marino': 1773,
    'San Bartolomeo': 1773,
    'San Bartolomeo In Bosco': 1773,
    'San Benedetto Val di Sambro': 1773,
    'San Biagio': 1773,
    'San Carlo': 1773,
    'San Cesario sul Panaro': 1773,
    'San Clemente': 1773,
    'San Damaso': 1773,
    'San Felice sul Panaro': 1773,
    'San Gabriele-Mondonuovo': 1773,
    'San Giorgio di Piano': 1773,
    'San Giorgio Piacentino': 1773,
    'San Giovanni in Marignano': 1773,
    'San Giovanni in Persiceto': 1773,
    'San Giovanni-San Bernardino': 1773,
    'San Giuseppe': 1773,
    'San Lazzaro': 1773,
    'San Leo': 1773,
    'San Martino': 1773,
    'San Martino dei Mulini': 1773,
    'San Martino in Argine': 1773,
    'San Martino in Rio': 1773,
    'San Mauro a Mare': 1773,
    'San Mauro Pascoli': 1773,
    'San Michele dei Mucchietti': 1773,
    'San Michele Tiorre': 1773,
    'San Nicolò': 1773,
    'San Pancrazio': 1773,
    'San Piero in Bagno': 1773,
    'San Pietro Capofiume': 1773,
    'San Pietro in Casale': 1773,
    'San Pietro in Cerro': 1773,
    'San Pietro In Vincoli': 1773,
    'San Polo': 1773,
    'San Polo d  Enza': 1773,
    'San Possidonio': 1773,
    'San Prospero': 1773,
    'San Secondo Parmense': 1773,
    'San Venanzio': 1773,
    'San Vincenzo': 1773,
    'San Vito': 1773,
    'Sant  Agata Bolognese': 1773,
    'Sant  Agata Feltria': 1773,
    'Sant  Agata sul Santerno': 1773,
    'Sant  Agostino': 1773,
    'Sant  Alberto': 1773,
    'Sant  Andrea': 1773,
    'Sant  Andrea in Casale': 1773,
    'Sant  Antonio': 1773,
    'Sant  Ilario d  Enza': 1773,
    'Santa Croce Scuole': 1773,
    'Santa Giustina': 1773,
    'Santa Maria Codifiume': 1773,
    'Santa Maria Nuova': 1773,
    'Santa Monica-Cella': 1773,
    'Santa Sofia': 1773,
    'Santa Vittoria': 1773,
    'Santarcangelo': 1773,
    'Santerno': 1773,
    'Santo Stefano-Carraie': 1773,
    'Sarmato': 1773,
    'Sarsina': 1773,
    'Sasso Marconi': 1773,
    'Sassuolo': 1773,
    'Savarna-Conventello': 1773,
    'Savignano sul Panaro': 1773,
    'Savignano sul Rubicone': 1773,
    'Savigno': 1773,
    'Savio': 1773,
    'Scandiano': 1773,
    'Scortichino': 1773,
    'Secchiano': 1773,
    'Serra di Maiolo': 1773,
    'Serramazzoni': 1773,
    'Serravalle': 1773,
    'Sesto Imolese': 1773,
    'Sestola': 1773,
    'Sissa': 1773,
    'Sogliano al Rubicone': 1773,
    'Solara': 1773,
    'Solarolo': 1773,
    'Soliera': 1773,
    'Solignano': 1773,
    'Solignano Nuovo': 1773,
    'Soragna': 1773,
    'Sorbara': 1773,
    'Sorbolo': 1773,
    'Spilamberto': 1773,
    'Stazione Valmozzola': 1773,
    'Talamello': 1773,
    'Taneto': 1773,
    'Terenzo': 1773,
    'Tizzano Val Parma': 1773,
    'Toano': 1773,
    'Tornolo': 1773,
    'Torriana': 1773,
    'Torrile': 1773,
    'Toscanella': 1773,
    'Trarivi': 1773,
    'Traversetolo': 1773,
    'Travo': 1773,
    'Trebbo': 1773,
    'Trecasali': 1773,
    'Tredozio': 1773,
    'Tresigallo-Final di Rero': 1773,
    'Vado': 1773,
    'Varano De   Melegari': 1773,
    'Varsi': 1773,
    'Veggia-Villalunga': 1773,
    'Venezzano': 1773,
    'Ventoso': 1773,
    'Vergato': 1773,
    'Verghereto': 1773,
    'Vernasca': 1773,
    'Verucchio': 1773,
    'Vetto': 1773,
    'Vezzano sul Crostolo': 1773,
    'Viano': 1773,
    'Vicofertile': 1773,
    'Vigarano Mainarda': 1773,
    'Vigarano Pieve': 1773,
    'Vigatto': 1773,
    'Vignola': 1773,
    'Vigolzone': 1773,
    'Villa Fontana': 1773,
    'Villa Minozzo': 1773,
    'Villa Verucchio': 1773,
    'Villanova': 1773,
    'Villarotta': 1773,
    'Voghiera': 1773,
    'Voltana': 1773,
    'Zerba': 1773,
    'Ziano Piacentino': 1773,
    'Zibello': 1773,
    'Zocca': 1773,
    'Zola Predosa': 1773,
    'Aiello del Friuli': 1756,
    'Amaro': 1756,
    'Ampezzo': 1756,
    'Andreis': 1756,
    'Anduins': 1756,
    'Aquileia': 1756,
    'Arba': 1756,
    'Arta Terme': 1756,
    'Artegna': 1756,
    'Arzene': 1756,
    'Attimis': 1756,
    'Aurisina': 1756,
    'Aviano': 1756,
    'Aviano-Castello': 1756,
    'Azzano Decimo': 1756,
    'Bagnaria Arsa': 1756,
    'Bagnarola': 1756,
    'Barcis': 1756,
    'Basaldella': 1756,
    'Basiliano': 1756,
    'Basiliano-Vissandone': 1756,
    'Begliano': 1756,
    'Bertiolo': 1756,
    'Bicinicco': 1756,
    'Bordano': 1756,
    'Bressa': 1756,
    'Brugnera': 1756,
    'Budoia': 1756,
    'Buia': 1756,
    'Buttrio': 1756,
    'Camino al Tagliamento': 1756,
    'Campoformido': 1756,
    'Campolongo al Torre': 1756,
    'Campolongo al Torre-Cavenzano': 1756,
    'Caneva': 1756,
    'Capriva del Friuli': 1756,
    'Carlino': 1756,
    'Casarsa della Delizia': 1756,
    'Cassacco': 1756,
    'Castions': 1756,
    'Castions di Strada': 1756,
    'Cavasso Nuovo': 1756,
    'Cavazzo Carnico': 1756,
    'Cavolano-Schiavoi': 1756,
    'Cecchini': 1756,
    'Cercivento': 1756,
    'Cervignano del Friuli': 1756,
    'Chiaulis': 1756,
    'Chions': 1756,
    'Chiopris': 1756,
    'Chiusaforte': 1756,
    'Cimolais': 1756,
    'Cividale del Friuli': 1756,
    'Claut': 1756,
    'Clauzetto': 1756,
    'Clodig': 1756,
    'Codroipo': 1756,
    'Colloredo di Monte Albano': 1756,
    'Colloredo di Monte Albano-Lauzzana': 1756,
    'Colloredo di Prato': 1756,
    'Colugna': 1756,
    'Comeglians': 1756,
    'Cordenons': 1756,
    'Cordovado': 1756,
    'Cormons': 1756,
    'Corno di Rosazzo': 1756,
    'Coseano': 1756,
    'Cras': 1756,
    'Dignano': 1756,
    'Doberdò del Lago': 1756,
    'Dogna': 1756,
    'Dolegna del Collio': 1756,
    'Dolina': 1756,
    'Domio': 1756,
    'Duino': 1756,
    'Enemonzo-Quinis': 1756,
    'Erto': 1756,
    'Faedis': 1756,
    'Fagagna': 1756,
    'Fanna': 1756,
    'Farra d  Isonzo': 1756,
    'Fiume Veneto': 1756,
    'Fiumicello': 1756,
    'Flaibano': 1756,
    'Fogliano': 1756,
    'Forgaria nel Friuli': 1756,
    'Forni Avoltri': 1756,
    'Forni di Sopra': 1756,
    'Forni di Sotto': 1756,
    'Frisanco': 1756,
    'Gemona': 1756,
    'Gonars': 1756,
    'Gorizia': 1756,
    'Gradisca d  Isonzo': 1756,
    'Grado': 1756,
    'Granvilla': 1756,
    'Ialmicco': 1756,
    'Isola': 1756,
    'Latisana': 1756,
    'Lauco': 1756,
    'Lauzacco': 1756,
    'Lestans': 1756,
    'Lestizza': 1756,
    'Lignano Sabbiadoro': 1756,
    'Ligosullo': 1756,
    'Lusevera': 1756,
    'Magnano in Riviera': 1756,
    'Majano': 1756,
    'Malborghetto': 1756,
    'Maniago': 1756,
    'Manzano': 1756,
    'Marano Lagunare': 1756,
    'Mariano del Friuli': 1756,
    'Marsure': 1756,
    'Martignacco': 1756,
    'Medea': 1756,
    'Mediis': 1756,
    'Meduno': 1756,
    'Mereto di Tomba': 1756,
    'Merso di Sopra': 1756,
    'Moggio di Sotto': 1756,
    'Moggio Udinese': 1756,
    'Moimacco': 1756,
    'Monfalcone': 1756,
    'Montenars': 1756,
    'Montereale Valcellina': 1756,
    'Moraro': 1756,
    'Morsano': 1756,
    'Mortegliano': 1756,
    'Moruzzo': 1756,
    'Mossa': 1756,
    'Muggia': 1756,
    'Muzzana del Turgnano': 1756,
    'Nimis': 1756,
    'Orcenico Inferiore': 1756,
    'Orsaria': 1756,
    'Osoppo': 1756,
    'Ovaro': 1756,
    'Pagnacco': 1756,
    'Palazzolo dello Stella': 1756,
    'Palmanova': 1756,
    'Paludea': 1756,
    'Paluzza': 1756,
    'Pasian di Prato': 1756,
    'Pasiano': 1756,
    'Passons': 1756,
    'Paularo': 1756,
    'Pavia di Udine': 1756,
    'Percoto': 1756,
    'Pertegada': 1756,
    'Pieria-Prato Carnico': 1756,
    'Pieris': 1756,
    'Pinzano al Tagliamento': 1756,
    'Pocenia': 1756,
    'Polcenigo': 1756,
    'Pontebba': 1756,
    'Porcia': 1756,
    'Pordenone': 1756,
    'Porpetto': 1756,
    'Povoletto': 1756,
    'Pozzuolo del Friuli': 1756,
    'Pradamano': 1756,
    'Prata di Pordenone': 1756,
    'Prato': 1756,
    'Prato Carnico': 1756,
    'Pravisdomini': 1756,
    'Precenicco': 1756,
    'Premariacco': 1756,
    'Preone': 1756,
    'Prepotto': 1756,
    'Prosecco-Contovello': 1756,
    'Provincia di Gorizia': 1756,
    'Provincia di Pordenone': 1756,
    'Provincia di Trieste': 1756,
    'Provincia di Udine': 1756,
    'Puia-Villanova': 1756,
    'Pulfero': 1756,
    'Ramuscello': 1756,
    'Rauscedo-Domanins': 1756,
    'Ravascletto': 1756,
    'Raveo': 1756,
    'Reana del Roiale': 1756,
    'Reana del Rojale': 1756,
    'Remanzacco': 1756,
    'Resiutta': 1756,
    'Rigolato': 1756,
    'Rive d  Arcano': 1756,
    'Rivignano': 1756,
    'Rodeano': 1756,
    'Romans d  Isonzo': 1756,
    'Ronchi dei Legionari': 1756,
    'Ronchis': 1756,
    'Rosa': 1756,
    'Roveredo in Piano': 1756,
    'Ruda': 1756,
    'Sacile': 1756,
    'Sagrado': 1756,
    'San Canzian d  Isonzo': 1756,
    'San Daniele del Friuli': 1756,
    'San Floriano del Collio': 1756,
    'San Giacomo': 1756,
    'San Giorgio della Richinvelda': 1756,
    'San Giorgio di Nogaro': 1756,
    'San Giovanni': 1756,
    'San Giovanni al Natisone': 1756,
    'San Leonardo': 1756,
    'San Lorenzo Isontino': 1756,
    'San Martino al Tagliamento': 1756,
    'San Pier d  Isonzo': 1756,
    'San Pietro al Natisone': 1756,
    'San Quirino': 1756,
    'San Valentino': 1756,
    'San Vito al Tagliamento': 1756,
    'San Vito al Torre': 1756,
    'San Vito di Fagagna': 1756,
    'Santa Croce': 1756,
    'Santa Maria la Longa': 1756,
    'Sappada': 1756,
    'Sauris di Sotto': 1756,
    'Savogna': 1756,
    'Savogna d  Isonzo': 1756,
    'Sedegliano': 1756,
    'Sequals': 1756,
    'Sesto al Reghena': 1756,
    'Sevegliano': 1756,
    'Sgonico': 1756,
    'Sistiana-Visogliano': 1756,
    'Sottoselva': 1756,
    'Spilimbergo': 1756,
    'Staranzano': 1756,
    'Stregna': 1756,
    'Sutrio': 1756,
    'Taipana': 1756,
    'Talmassons': 1756,
    'Tamai': 1756,
    'Tarcento': 1756,
    'Tarvisio': 1756,
    'Tavagnacco': 1756,
    'Teor': 1756,
    'Terzo d  Aquileia': 1756,
    'Tolmezzo': 1756,
    'Torreano': 1756,
    'Torviscosa': 1756,
    'Tramonti di Sopra': 1756,
    'Tramonti di Sotto': 1756,
    'Trasaghis': 1756,
    'Travesio': 1756,
    'Treppo Carnico': 1756,
    'Treppo Grande': 1756,
    'Tricesimo': 1756,
    'Trieste': 1756,
    'Trivignano Udinese': 1756,
    'Turriaco': 1756,
    'Udine': 1756,
    'Vajont': 1756,
    'Valvasone': 1756,
    'Varmo': 1756,
    'Venzone': 1756,
    'Vigonovo-Fontanafredda': 1756,
    'Villa Opicina': 1756,
    'Villa Santina': 1756,
    'Villa Vicentina': 1756,
    'Villanova': 1756,
    'Villesse': 1756,
    'Villotta': 1756,
    'Visco': 1756,
    'Vivaro': 1756,
    'Zolla': 1756,
    'Zoppola': 1756,
    'Zugliano-Terenzano-Cargnacco': 1756,
    'Zuglio': 1756,
    'Accumoli': 1678,
    'Acilia-Castel Fusano-Ostia Antica': 1678,
    'Acquafondata': 1678,
    'Acquapendente': 1678,
    'Acuto': 1678,
    'Affile': 1678,
    'Agosta': 1678,
    'Alatri': 1678,
    'Albano Laziale': 1678,
    'Albuccione': 1678,
    'Allumiere': 1678,
    'Alvito': 1678,
    'Amaseno': 1678,
    'Amatrice': 1678,
    'Anagni': 1678,
    'Anguillara Sabazia': 1678,
    'Anitrella-Chiaiamari': 1678,
    'Anticoli Corrado': 1678,
    'Antrodoco': 1678,
    'Anzio': 1678,
    'Aprilia': 1678,
    'Aquino': 1678,
    'Ara Nova': 1678,
    'Arce': 1678,
    'Arci-Empolitana': 1678,
    'Arcinazzo Romano': 1678,
    'Ardea': 1678,
    'Area Produttiva': 1678,
    'Ariccia': 1678,
    'Arlena di Castro': 1678,
    'Armetta': 1678,
    'Arnara': 1678,
    'Arpino': 1678,
    'Arsoli': 1678,
    'Artena': 1678,
    'Ascrea': 1678,
    'Atina': 1678,
    'Atina Inferiore': 1678,
    'Aurelia': 1678,
    'Ausonia': 1678,
    'Bagni di Tivoli': 1678,
    'Bagnoregio': 1678,
    'Barbarano Romano': 1678,
    'Bassano in Teverina': 1678,
    'Bassano Romano': 1678,
    'Bassiano': 1678,
    'Bella Farnia': 1678,
    'Bellegra': 1678,
    'Belmonte Castello': 1678,
    'Belmonte in Sabina': 1678,
    'Bivio di Capanelle': 1678,
    'Bivio San Polo': 1678,
    'Blera': 1678,
    'Bolsena': 1678,
    'Bomarzo': 1678,
    'Borbona': 1678,
    'Borgo Grappa': 1678,
    'Borgo Hermada': 1678,
    'Borgo Lotti': 1678,
    'Borgo Podgora': 1678,
    'Borgo Sabotino-Foce Verde': 1678,
    'Borgo San Michele': 1678,
    'Borgo Velino': 1678,
    'Borgorose': 1678,
    'Boville Ernica': 1678,
    'Bracciano': 1678,
    'Broccostella': 1678,
    'Caira': 1678,
    'Calcata Nuova': 1678,
    'Camerata Nuova': 1678,
    'Camilleri-Vallelata': 1678,
    'Campagnano di Roma': 1678,
    'Campo di Carne': 1678,
    'Campo Jemini': 1678,
    'Campo Limpido-Favale': 1678,
    'Campodimele': 1678,
    'Campoleone': 1678,
    'Campoli Appennino': 1678,
    'Canale Monterano': 1678,
    'Canepina': 1678,
    'Canino': 1678,
    'Cantalice': 1678,
    'Cantalupo in Sabina': 1678,
    'Canterano': 1678,
    'Capena': 1678,
    'Capodimonte': 1678,
    'Capranica': 1678,
    'Capranica Prenestina': 1678,
    'Caprarola': 1678,
    'Carbognano': 1678,
    'Carchitti': 1678,
    'Carpineto Romano': 1678,
    'Carrara-Pontenuovo': 1678,
    'Cartiera-Stazione': 1678,
    'Casal Palocco': 1678,
    'Casalattico': 1678,
    'Casalazzara': 1678,
    'Casali': 1678,
    'Casalvieri': 1678,
    'Casape': 1678,
    'Casaprota': 1678,
    'Case Campoli-Panetta': 1678,
    'Casperia': 1678,
    'Cassino': 1678,
    'Castel Chiodato': 1678,
    'Castel di Tora': 1678,
    'Castel Fusano': 1678,
    'Castel Gandolfo': 1678,
    'Castel Madama': 1678,
    'Castel San Pietro Romano': 1678,
    'Castel Sant  Angelo': 1678,
    'Castel Sant  Elia': 1678,
    'Castelforte': 1678,
    'Castelliri': 1678,
    'Castelmassimo': 1678,
    'Castelnuovo di Farfa': 1678,
    'Castelnuovo di Porto': 1678,
    'Castelnuovo Parano': 1678,
    'Castiglione in Teverina': 1678,
    'Castro dei Volsci': 1678,
    'Castrocielo': 1678,
    'Cave': 1678,
    'Ceccano': 1678,
    'Cecchina': 1678,
    'Celleno': 1678,
    'Cellere': 1678,
    'Ceprano': 1678,
    'Cerquotti-Madonna del Piano': 1678,
    'Cerreto Laziale': 1678,
    'Cervara di Roma': 1678,
    'Cervaro': 1678,
    'Cerveteri': 1678,
    'Cesano': 1678,
    'Ciampino': 1678,
    'Ciciliano': 1678,
    'Cineto Romano': 1678,
    'Cisterna di Latina': 1678,
    'Città metropolitana di Roma Capitale': 1678,
    'Cittaducale': 1678,
    'Cittareale': 1678,
    'Civita Castellana': 1678,
    'Civitavecchia': 1678,
    'Civitella d  Agliano': 1678,
    'Civitella San Paolo': 1678,
    'Coldragone': 1678,
    'Collalto Sabino': 1678,
    'Colle Campano-Scrima': 1678,
    'Colle del Pino': 1678,
    'Colle di Fuori': 1678,
    'Colle di Tora': 1678,
    'Colle Mainello': 1678,
    'Colle San Magno': 1678,
    'Colle Spina': 1678,
    'Colle Verde': 1678,
    'Colleferro': 1678,
    'Collefontana-Fontana Liri Inferiore': 1678,
    'Collegiove': 1678,
    'Collepardo': 1678,
    'Collevecchio': 1678,
    'Colleverde II': 1678,
    'Colli di Enea': 1678,
    'Colli sul Velino': 1678,
    'Colonna': 1678,
    'Colubro': 1678,
    'Concerviano': 1678,
    'Configni': 1678,
    'Contigliano': 1678,
    'Corchiano': 1678,
    'Corcolle': 1678,
    'Coreno Ausonio': 1678,
    'Cori': 1678,
    'Corvaro': 1678,
    'Costaroni': 1678,
    'Cottanello': 1678,
    'Cretone': 1678,
    'Esperia': 1678,
    'Fabrica di Roma': 1678,
    'Faleria': 1678,
    'Falvaterra': 1678,
    'Fara in Sabina': 1678,
    'Farnese': 1678,
    'Ferentino': 1678,
    'Fiamignano': 1678,
    'Fiano Romano': 1678,
    'Filacciano': 1678,
    'Filettino': 1678,
    'Fiuggi': 1678,
    'Fiumicino-Isola Sacra': 1678,
    'Focene': 1678,
    'Fondi': 1678,
    'Fontechiari': 1678,
    'Forano': 1678,
    'Formello': 1678,
    'Formia': 1678,
    'Fossignano': 1678,
    'Frascati': 1678,
    'Frasso Sabino': 1678,
    'Fregene': 1678,
    'Frosinone': 1678,
    'Fumone': 1678,
    'Gaeta': 1678,
    'Gallese': 1678,
    'Gallicano nel Lazio': 1678,
    'Gallinaro': 1678,
    'Gavignano': 1678,
    'Genazzano': 1678,
    'Genio Civile': 1678,
    'Genzano di Roma': 1678,
    'Gerano': 1678,
    'Girardi-Bellavista-Terrazze': 1678,
    'Giulianello': 1678,
    'Giuliano di Roma': 1678,
    'Gorga': 1678,
    'Gradoli': 1678,
    'Graffignano': 1678,
    'Grottaferrata': 1678,
    'Grotte di Castro': 1678,
    'Grotte Santo Stefano': 1678,
    'Grunuovo-Campomaggiore San Luca': 1678,
    'Guarcino': 1678,
    'Guidonia': 1678,
    'Guidonia Montecelio': 1678,
    'Ischia di Castro': 1678,
    'Isola del Liri': 1678,
    'Itri': 1678,
    'Jenne': 1678,
    'La Botte': 1678,
    'La Forma': 1678,
    'La Massimina-Casal Lumbroso': 1678,
    'Labico': 1678,
    'Labro': 1678,
    'Ladispoli': 1678,
    'Laghetto': 1678,
    'Lanuvio': 1678,
    'Lariano': 1678,
    'Latera': 1678,
    'Latina': 1678,
    'Latina Scalo': 1678,
    'Lavinio': 1678,
    'Le Forna': 1678,
    'Le Rughe': 1678,
    'Lenola': 1678,
    'Leonessa': 1678,
    'Licenza': 1678,
    'Lido dei Pini': 1678,
    'Lido di Ostia': 1678,
    'Limiti di Greccio': 1678,
    'Longone Sabino': 1678,
    'Lubriano': 1678,
    'Maccarese': 1678,
    'Macere': 1678,
    'Maenza': 1678,
    'Magliano Romano': 1678,
    'Magliano Sabina': 1678,
    'Mandela': 1678,
    'Mantiglia di Ardea': 1678,
    'Manziana': 1678,
    'Marano Equo': 1678,
    'Maranola-Trivio': 1678,
    'Marcellina': 1678,
    'Marcetelli': 1678,
    'Marco Simone': 1678,
    'Marina di Ardea-Tor San Lorenzo': 1678,
    'Marina di Cerveteri': 1678,
    'Marina San Nicola': 1678,
    'Marino': 1678,
    'Marta': 1678,
    'Mazzano Romano': 1678,
    'Mentana': 1678,
    'Micigliano': 1678,
    'Minturno': 1678,
    'Molella': 1678,
    'Mompeo': 1678,
    'Montalto di Castro': 1678,
    'Montasola': 1678,
    'Monte Caminetto': 1678,
    'Monte Migliore La Selvotta': 1678,
    'Monte Porzio Catone': 1678,
    'Monte Romano': 1678,
    'Monte San Biagio': 1678,
    'Monte San Giovanni Campano': 1678,
    'Monte San Giovanni in Sabina': 1678,
    'Monte San Marino': 1678,
    'Montebuono': 1678,
    'Montecelio': 1678,
    'Montecompatri': 1678,
    'Montefiascone': 1678,
    'Monteflavio': 1678,
    'Montelanico': 1678,
    'Montelarco': 1678,
    'Monteleone Sabino': 1678,
    'Montelibretti': 1678,
    'Montenero Sabino': 1678,
    'Monterosi': 1678,
    'Monterotondo': 1678,
    'Montopoli in Sabina': 1678,
    'Montorio Romano': 1678,
    'Moricone': 1678,
    'Morlupo': 1678,
    'Morolo': 1678,
    'Morro Reatino': 1678,
    'Nazzano': 1678,
    'Nemi': 1678,
    'Nepi': 1678,
    'Nerola': 1678,
    'Nespolo': 1678,
    'Nettuno': 1678,
    'Norma': 1678,
    'Olevano Romano': 1678,
    'Onano': 1678,
    'Oriolo Romano': 1678,
    'Orte': 1678,
    'Orte Scalo': 1678,
    'Orvinio': 1678,
    'Osteria Nuova': 1678,
    'Paganico Sabino': 1678,
    'Palestrina': 1678,
    'Paliano': 1678,
    'Palombara Sabina': 1678,
    'Parco Leonardo': 1678,
    'Passoscuro': 1678,
    'Pastena': 1678,
    'Patrica': 1678,
    'Pavona': 1678,
    'Penitro': 1678,
    'Percile': 1678,
    'Pescia Romana': 1678,
    'Pescorocchiano': 1678,
    'Pescosolido': 1678,
    'Petrella Salto': 1678,
    'Piansano': 1678,
    'Picinisco': 1678,
    'Pico': 1678,
    'Piedimonte San Germano': 1678,
    'Piedimonte San Germano Alta': 1678,
    'Piglio': 1678,
    'Pignataro Interamna': 1678,
    'Pisoniano': 1678,
    'Pofi': 1678,
    'Poggio Bustone': 1678,
    'Poggio Catino': 1678,
    'Poggio Ellera': 1678,
    'Poggio Mirteto': 1678,
    'Poggio Moiano': 1678,
    'Poggio Nativo': 1678,
    'Poggio San Lorenzo': 1678,
    'Poli': 1678,
    'Pomezia': 1678,
    'Ponte Galeria-La Pisana': 1678,
    'Pontecorvo': 1678,
    'Pontinia': 1678,
    'Ponton dell  Elce': 1678,
    'Ponza': 1678,
    'Ponzano Romano': 1678,
    'Posta': 1678,
    'Posta Fibreno': 1678,
    'Pozzaglia Sabino': 1678,
    'Prato di Coppola': 1678,
    'Priverno': 1678,
    'Proceno': 1678,
    'Prossedi': 1678,
    'Provincia di Frosinone': 1678,
    'Provincia di Latina': 1678,
    'Provincia di Rieti': 1678,
    'Provincia di Viterbo': 1678,
    'Regolelli': 1678,
    'Riano': 1678,
    'Rieti': 1678,
    'Rignano Flaminio': 1678,
    'Riofreddo': 1678,
    'Ripi': 1678,
    'Rivodutri': 1678,
    'Rocca Canterano': 1678,
    'Rocca d  Arce': 1678,
    'Rocca di Cave': 1678,
    'Rocca di Papa': 1678,
    'Rocca Massima': 1678,
    'Rocca Priora': 1678,
    'Rocca Santo Stefano': 1678,
    'Rocca Sinibalda': 1678,
    'Roccagiovine': 1678,
    'Roccagorga': 1678,
    'Roccantica': 1678,
    'Roccasecca': 1678,
    'Roccasecca dei Volsci': 1678,
    'Roccasecca Stazione': 1678,
    'Roiate': 1678,
    'Rome': 1678,
    'Ronciglione': 1678,
    'Roviano': 1678,
    'Sabaudia': 1678,
    'Sacrofano': 1678,
    'Salisano': 1678,
    'Sambuci': 1678,
    'San Bartolomeo': 1678,
    'San Biagio Saracinisco': 1678,
    'San Cesareo': 1678,
    'San Donato Val di Comino': 1678,
    'San Felice Circeo': 1678,
    'San Giorgio a Liri': 1678,
    'San Giovanni Incarico': 1678,
    'San Giovanni-Patoni': 1678,
    'San Giuseppe le Prata-Cotropagno': 1678,
    'San Gregorio da Sassola': 1678,
    'San Lorenzo Nuovo': 1678,
    'San Martino al Cimino': 1678,
    'San Polo dei Cavalieri': 1678,
    'San Vito Romano': 1678,
    'San Vittore del Lazio': 1678,
    'Sant  Ambrogio sul Garigliano': 1678,
    'Sant  Andrea del Garigliano': 1678,
    'Sant  Angelo in Villa-Giglio': 1678,
    'Sant  Angelo Romano': 1678,
    'Sant  Apollinare': 1678,
    'Sant  Elia Fiumerapido': 1678,
    'Sant  Oreste': 1678,
    'Santa Lucia': 1678,
    'Santa Marinella': 1678,
    'Santa Rufina': 1678,
    'Santi Cosma e Damiano': 1678,
    'Santopadre': 1678,
    'Saracinesco': 1678,
    'Sassacci': 1678,
    'Scandriglia': 1678,
    'Segni': 1678,
    'Selcetta': 1678,
    'Selci': 1678,
    'Sermoneta': 1678,
    'Serrone': 1678,
    'Settefrati': 1678,
    'Setteville': 1678,
    'Sezze': 1678,
    'Sezze Scalo': 1678,
    'Sgurgola': 1678,
    'Sonnino': 1678,
    'Sora': 1678,
    'Soriano nel Cimino': 1678,
    'Sperlonga': 1678,
    'Spigno Saturnia Inferiore': 1678,
    'Spigno Saturnia Superiore': 1678,
    'Stimigliano': 1678,
    'Strangolagalli': 1678,
    'Subiaco': 1678,
    'Supino': 1678,
    'Sutri': 1678,
    'Tarano': 1678,
    'Tarquinia': 1678,
    'Tecchiena': 1678,
    'Terelle': 1678,
    'Terracina': 1678,
    'Tessennano': 1678,
    'Tivoli': 1678,
    'Toffia': 1678,
    'Tolfa': 1678,
    'Tor Lupara': 1678,
    'Torre Caietani': 1678,
    'Torri in Sabina': 1678,
    'Torrice': 1678,
    'Torricella in Sabina': 1678,
    'Torrita Tiberina': 1678,
    'Torvaianica': 1678,
    'Torvaianica Alta': 1678,
    'Tragliatella Campitello': 1678,
    'Trevi nel Lazio': 1678,
    'Trevignano Romano': 1678,
    'Trivigliano': 1678,
    'Turania': 1678,
    'Tuscania': 1678,
    'Vacone': 1678,
    'Valcanneto': 1678,
    'Valentano': 1678,
    'Valle Martella': 1678,
    'Valle Santa': 1678,
    'Vallecorsa': 1678,
    'Vallemaio': 1678,
    'Vallepietra': 1678,
    'Vallerano': 1678,
    'Vallerotonda': 1678,
    'Vallinfreda': 1678,
    'Valmontone': 1678,
    'Varco Sabino': 1678,
    'Vasanello': 1678,
    'Vejano': 1678,
    'Velletri': 1678,
    'Ventotene': 1678,
    'Veroli': 1678,
    'Vetralla': 1678,
    'Vicalvi': 1678,
    'Vico nel Lazio': 1678,
    'Vicovaro': 1678,
    'Vignanello': 1678,
    'Villa Adriana': 1678,
    'Villa Latina': 1678,
    'Villa San Giovanni in Tuscia': 1678,
    'Villa Santa Lucia': 1678,
    'Villa Santo Stefano': 1678,
    'Villalba': 1678,
    'Villanova': 1678,
    'Viterbo': 1678,
    'Viticuso': 1678,
    'Vitinia': 1678,
    'Vitorchiano': 1678,
    'Vivaro Romano': 1678,
    'Zagarolo': 1678,
    'Zepponami': 1678,
    'Airole': 1768,
    'Alassio': 1768,
    'Albenga': 1768,
    'Albisola Marina': 1768,
    'Albisola Superiore': 1768,
    'Altare': 1768,
    'Ameglia': 1768,
    'Andora': 1768,
    'Apricale': 1768,
    'Aquila di Arroscia': 1768,
    'Arcola': 1768,
    'Arenzano': 1768,
    'Armo': 1768,
    'Arnasco': 1768,
    'Aurigo': 1768,
    'Avegno': 1768,
    'Badalucco': 1768,
    'Bajardo': 1768,
    'Balestrino': 1768,
    'Bardineto': 1768,
    'Bargagli': 1768,
    'Bergeggi': 1768,
    'Beverino': 1768,
    'Bocco': 1768,
    'Bogliasco': 1768,
    'Boissano': 1768,
    'Bolano': 1768,
    'Bonassola': 1768,
    'Bordighera': 1768,
    'Borghetto d  Arroscia': 1768,
    'Borghetto di Vara': 1768,
    'Borghetto Santo Spirito': 1768,
    'Borgio': 1768,
    'Borgo di Ranzo': 1768,
    'Borgo Fornari-Pieve': 1768,
    'Borgomaro': 1768,
    'Bormida': 1768,
    'Bormida-Genepro': 1768,
    'Borzonasca': 1768,
    'Brugnato': 1768,
    'Busalla': 1768,
    'Cairo Montenotte': 1768,
    'Calice al Cornoviglio': 1768,
    'Calice Ligure': 1768,
    'Calizzano': 1768,
    'Camogli': 1768,
    'Campo Ligure': 1768,
    'Campochiesa': 1768,
    'Campomorone': 1768,
    'Camporosso': 1768,
    'Carasco': 1768,
    'Caravonica': 1768,
    'Carcare': 1768,
    'Carpasio': 1768,
    'Carro': 1768,
    'Carrodano': 1768,
    'Casano-Dogana-Isola': 1768,
    'Casanova Lerrone': 1768,
    'Casarza Ligure': 1768,
    'Casavecchia': 1768,
    'Casella': 1768,
    'Castel Vittorio': 1768,
    'Castelbianco': 1768,
    'Castellaro': 1768,
    'Castelnuovo Magra': 1768,
    'Castelvecchio di Rocca Barbena': 1768,
    'Castiglione': 1768,
    'Celle Ligure': 1768,
    'Cengio Alto': 1768,
    'Ceparana-Carpena': 1768,
    'Ceranesi': 1768,
    'Ceriale': 1768,
    'Ceriana': 1768,
    'Cervo': 1768,
    'Cesio': 1768,
    'Chiavari': 1768,
    'Chiesa': 1768,
    'Chiesa Nuova': 1768,
    'Chiusanico': 1768,
    'Chiusavecchia': 1768,
    'Cicagna': 1768,
    'Cipressa': 1768,
    'Cisano': 1768,
    'Civezza': 1768,
    'Cogoleto': 1768,
    'Cogorno': 1768,
    'Colombiera-Molicciara': 1768,
    'Conscenti': 1768,
    'Coreglia Ligure': 1768,
    'Cosio di Arroscia': 1768,
    'Cosseria': 1768,
    'Costarainera': 1768,
    'Crocefieschi': 1768,
    'Davagna': 1768,
    'Dego': 1768,
    'Deiva Marina': 1768,
    'Diano Arentino': 1768,
    'Diano Castello': 1768,
    'Diano Marina': 1768,
    'Diano San Pietro': 1768,
    'Dolceacqua': 1768,
    'Dolcedo': 1768,
    'Erli': 1768,
    'Fascia': 1768,
    'Favale di Malvaro': 1768,
    'Feglino': 1768,
    'Ferrada': 1768,
    'Ferriere': 1768,
    'Finale Ligure': 1768,
    'Fontanigorda': 1768,
    'Framura': 1768,
    'Garlenda': 1768,
    'Genoa': 1768,
    'Giustenice': 1768,
    'Giusvalla': 1768,
    'Gorreto': 1768,
    'Imperia': 1768,
    'Isola del Cantone': 1768,
    'Isolabona': 1768,
    'Isoverde': 1768,
    'La Spezia': 1768,
    'Laigueglia': 1768,
    'Lavagna': 1768,
    'Le Grazie': 1768,
    'Leivi': 1768,
    'Lerici': 1768,
    'Levanto': 1768,
    'Loano': 1768,
    'Lorsica': 1768,
    'Lucinasco': 1768,
    'Magliolo': 1768,
    'Maissana': 1768,
    'Mallare': 1768,
    'Manesseno': 1768,
    'Marina di Andora': 1768,
    'Masone': 1768,
    'Massimino': 1768,
    'Mele': 1768,
    'Mendatica': 1768,
    'Mezzanego': 1768,
    'Mignanego': 1768,
    'Millesimo': 1768,
    'Mioglia': 1768,
    'Moconesi': 1768,
    'Molini': 1768,
    'Molini di Triora': 1768,
    'Molino Vecchio-Scapitola-Baio': 1768,
    'Molino-Pera': 1768,
    'Moneglia': 1768,
    'Montalto Ligure': 1768,
    'Montebruno': 1768,
    'Montegrosso Pian Latte': 1768,
    'Monterosso al Mare': 1768,
    'Montoggio': 1768,
    'Murialdo': 1768,
    'Nasino-Borgo': 1768,
    'Neirone': 1768,
    'Noli': 1768,
    'Olivetta San Michele': 1768,
    'Onzo': 1768,
    'Orco Feglino': 1768,
    'Orero': 1768,
    'Ortovero': 1768,
    'Osiglia': 1768,
    'Ospedaletti': 1768,
    'Osteria dei Cacciatori-Stella': 1768,
    'Padivarma': 1768,
    'Pallare': 1768,
    'Pedemonte': 1768,
    'Perinaldo': 1768,
    'Piana Battolla': 1768,
    'Piana Crixia': 1768,
    'Piano': 1768,
    'Piano di Follo': 1768,
    'Piccarello': 1768,
    'Pietra Ligure': 1768,
    'Pietrabruna': 1768,
    'Pieve di Teco': 1768,
    'Pieve di Zignago': 1768,
    'Pieve Ligure': 1768,
    'Pigna': 1768,
    'Pignone': 1768,
    'Pitelli': 1768,
    'Plodio': 1768,
    'Pompeiana': 1768,
    'Pontedassio': 1768,
    'Pontinvrea': 1768,
    'Pornassio': 1768,
    'Portofino': 1768,
    'Portovenere': 1768,
    'Prati': 1768,
    'Prelà Castello': 1768,
    'Propata': 1768,
    'Provincia di Genova': 1768,
    'Provincia di Imperia': 1768,
    'Provincia di La Spezia': 1768,
    'Provincia di Savona': 1768,
    'Quiliano': 1768,
    'Rapallo': 1768,
    'Recco': 1768,
    'Rezzo': 1768,
    'Rezzoaglio': 1768,
    'Rialto': 1768,
    'Riccò del Golfo': 1768,
    'Riomaggiore': 1768,
    'Riva Ligure': 1768,
    'Roccavignale': 1768,
    'Rocchetta di Vara': 1768,
    'Rocchetta Nervina': 1768,
    'Romito Magra': 1768,
    'Ronco Scrivia': 1768,
    'Rondanina': 1768,
    'Rossi': 1768,
    'Rossiglione': 1768,
    'Rovegno': 1768,
    'San Bartolomeo al Mare': 1768,
    'San Biagio della Cima': 1768,
    'San Fedele-Lusignano': 1768,
    'San Giovanni': 1768,
    'San Lorenzo': 1768,
    'San Lorenzo al Mare': 1768,
    'San Pietro d  Olba': 1768,
    'San Remo': 1768,
    'San Salvatore': 1768,
    'San Terenzo': 1768,
    'Santa Margherita Ligure': 1768,
    'Santa Marta': 1768,
    'Santo Stefano al Mare': 1768,
    'Santo Stefano d  Aveto': 1768,
    'Santo Stefano di Magra': 1768,
    'Sarzana': 1768,
    'Sassello': 1768,
    'Savignone': 1768,
    'Savona': 1768,
    'Sciarborasca': 1768,
    'Seborga': 1768,
    'Serra Riccò': 1768,
    'Sesta Godano': 1768,
    'Sestri Levante': 1768,
    'Soldano': 1768,
    'Sori': 1768,
    'Spotorno': 1768,
    'Stazione-Fornola': 1768,
    'Stellanello': 1768,
    'Taggia': 1768,
    'Terzorio': 1768,
    'Testico': 1768,
    'Tiglieto': 1768,
    'Toirano': 1768,
    'Torriglia': 1768,
    'Tovo San Giacomo': 1768,
    'Tribogna': 1768,
    'Triora': 1768,
    'Urbe': 1768,
    'Uscio': 1768,
    'Vado Centro': 1768,
    'Vado Ligure': 1768,
    'Valle di Vado': 1768,
    'Vallebona': 1768,
    'Vallecrosia': 1768,
    'Valleggia': 1768,
    'Valzemola': 1768,
    'Varazze': 1768,
    'Varese Ligure': 1768,
    'Vasia': 1768,
    'Vendone': 1768,
    'Ventimiglia': 1768,
    'Vernazza': 1768,
    'Vessalico': 1768,
    'Vezzano Ligure': 1768,
    'Vezzi Portio': 1768,
    'Villa Faraldi': 1768,
    'Villafranca': 1768,
    'Villanova d  Albenga': 1768,
    'Vobbia': 1768,
    'Zoagli': 1768,
    'Zuccarello': 1768,
    'Abbadia Cerreto': 1705,
    'Abbadia Lariana': 1705,
    'Abbazia': 1705,
    'Abbiategrasso': 1705,
    'Acquafredda': 1705,
    'Acquanegra Cremonese': 1705,
    'Acquanegra sul Chiese': 1705,
    'Adrara San Martino': 1705,
    'Adrara San Rocco': 1705,
    'Adro': 1705,
    'Agnadello': 1705,
    'Agnosine': 1705,
    'Agra': 1705,
    'Agrate Brianza': 1705,
    'Aicurzio': 1705,
    'Airuno': 1705,
    'Alagna': 1705,
    'Albairate': 1705,
    'Albano Sant  Alessandro': 1705,
    'Albaredo': 1705,
    'Albaredo Arnaboldi': 1705,
    'Albavilla': 1705,
    'Albese Con Cassano': 1705,
    'Albiate': 1705,
    'Albignano': 1705,
    'Albino': 1705,
    'Albiolo': 1705,
    'Albizzate': 1705,
    'Albonese': 1705,
    'Albosaggia': 1705,
    'Albuzzano': 1705,
    'Alfianello': 1705,
    'Algua': 1705,
    'Almè': 1705,
    'Almenno San Bartolomeo': 1705,
    'Almenno San Salvatore': 1705,
    'Alserio': 1705,
    'Alzano Lombardo': 1705,
    'Alzate Brianza': 1705,
    'Ambivere': 1705,
    'Andalo Valtellino': 1705,
    'Anfo': 1705,
    'Angera': 1705,
    'Angolo Terme': 1705,
    'Annicco': 1705,
    'Annone di Brianza': 1705,
    'Antegnate': 1705,
    'Anzano del Parco': 1705,
    'Appiano Gentile': 1705,
    'Aprica': 1705,
    'Arcene': 1705,
    'Arcisate': 1705,
    'Arconate': 1705,
    'Arcore': 1705,
    'Ardenno': 1705,
    'Ardesio': 1705,
    'Ardole San Marino': 1705,
    'Arena Po': 1705,
    'Arese': 1705,
    'Argegno': 1705,
    'Arlate': 1705,
    'Arluno': 1705,
    'Arosio': 1705,
    'Arsago Seprio': 1705,
    'Artogne': 1705,
    'Arzago d  Adda': 1705,
    'Asola': 1705,
    'Assago': 1705,
    'Asso': 1705,
    'Aurogna': 1705,
    'Averara': 1705,
    'Aviatico': 1705,
    'Azzanello': 1705,
    'Azzano': 1705,
    'Azzano Mella': 1705,
    'Azzano San Paolo': 1705,
    'Azzate': 1705,
    'Azzio': 1705,
    'Azzone': 1705,
    'Badalasco': 1705,
    'Badia Pavese': 1705,
    'Badile': 1705,
    'Bagnaria': 1705,
    'Bagnatica': 1705,
    'Bagnolo Cremasco': 1705,
    'Bagnolo Mella': 1705,
    'Bagnolo San Vito': 1705,
    'Bagolino': 1705,
    'Ballabio': 1705,
    'Baraggia': 1705,
    'Baranzate': 1705,
    'Barasso': 1705,
    'Barbariga': 1705,
    'Barbata': 1705,
    'Barbianello': 1705,
    'Bardello': 1705,
    'Bareggio': 1705,
    'Bargano': 1705,
    'Barghe': 1705,
    'Bariano': 1705,
    'Barlassina': 1705,
    'Barni': 1705,
    'Barzago': 1705,
    'Barzana': 1705,
    'Barzanò': 1705,
    'Barzio': 1705,
    'Bascapè': 1705,
    'Basiano': 1705,
    'Basiglio': 1705,
    'Bassano Bresciano': 1705,
    'Bastida de   Dossi': 1705,
    'Bastida Pancarana': 1705,
    'Battaglione-Bagnara': 1705,
    'Battuda': 1705,
    'Bedero': 1705,
    'Bedero Valcuvia': 1705,
    'Bedizzole': 1705,
    'Bedulita': 1705,
    'Belforte': 1705,
    'Belgioioso': 1705,
    'Bellagio': 1705,
    'Bellano': 1705,
    'Bellinzago Lombardo': 1705,
    'Bellusco': 1705,
    'Bema': 1705,
    'Bene Lario': 1705,
    'Berbenno': 1705,
    'Berbenno di Valtellina': 1705,
    'Beregazzo': 1705,
    'Beregazzo con Figliaro': 1705,
    'Bereguardo': 1705,
    'Bergamo': 1705,
    'Berlingo': 1705,
    'Bernareggio': 1705,
    'Bernate Ticino': 1705,
    'Bertonico': 1705,
    'Berzo': 1705,
    'Berzo Inferiore': 1705,
    'Berzo San Fermo': 1705,
    'Besana in Brianza': 1705,
    'Besano': 1705,
    'Besate': 1705,
    'Besnate': 1705,
    'Besozzo': 1705,
    'Bettola': 1705,
    'Bettola-Zeloforomagno': 1705,
    'Biandronno': 1705,
    'Bianzano': 1705,
    'Bianzone': 1705,
    'Biassono': 1705,
    'Bienno': 1705,
    'Binago': 1705,
    'Binasco': 1705,
    'Bione': 1705,
    'Bisuschio': 1705,
    'Bizzarone': 1705,
    'Blello': 1705,
    'Blessagno': 1705,
    'Blevio': 1705,
    'Bodio': 1705,
    'Bodio Lomnago': 1705,
    'Boffalora d  Adda': 1705,
    'Boffalora Sopra Ticino': 1705,
    'Bolgare': 1705,
    'Bolladello-Peveranza': 1705,
    'Bollate': 1705,
    'Boltiere': 1705,
    'Bonate Sopra': 1705,
    'Bonate Sotto': 1705,
    'Bondeno': 1705,
    'Bondione': 1705,
    'Bonemerse': 1705,
    'Bordolano': 1705,
    'Borgarello': 1705,
    'Borghetto Lodigiano': 1705,
    'Borgo di Terzo': 1705,
    'Borgo Priolo': 1705,
    'Borgo San Giacomo': 1705,
    'Borgo San Giovanni': 1705,
    'Borgo San Siro': 1705,
    'Borgoforte': 1705,
    'Borgofranco sul Po': 1705,
    'Borgoratto Mormorolo': 1705,
    'Borgosatollo': 1705,
    'Bormio': 1705,
    'Bornasco': 1705,
    'Bornato': 1705,
    'Borno': 1705,
    'Boschetto': 1705,
    'Bosco Ex Parmigiano': 1705,
    'Bosisio Parini': 1705,
    'Bosnasco': 1705,
    'Bossico': 1705,
    'Botta': 1705,
    'Bottanuco': 1705,
    'Botticino': 1705,
    'Bovegno': 1705,
    'Bovezzo': 1705,
    'Bovisio-Masciago': 1705,
    'Bozzolo': 1705,
    'Bracca': 1705,
    'Brandico': 1705,
    'Branzi': 1705,
    'Braone': 1705,
    'Brebbia': 1705,
    'Bregano': 1705,
    'Bregnano': 1705,
    'Brembate': 1705,
    'Brembate di Sopra': 1705,
    'Brembilla': 1705,
    'Brembio': 1705,
    'Breme': 1705,
    'Brenna': 1705,
    'Brenno Useria': 1705,
    'Breno': 1705,
    'Brenta': 1705,
    'Brescia': 1705,
    'Bressana': 1705,
    'Bresso': 1705,
    'Brezzo di Bedero': 1705,
    'Brienno': 1705,
    'Brignano Gera d  Adda': 1705,
    'Brinzio': 1705,
    'Brione': 1705,
    'Briosco': 1705,
    'Brissago-Valtravaglia': 1705,
    'Brivio': 1705,
    'Brongio': 1705,
    'Broni': 1705,
    'Brozzo': 1705,
    'Brugherio': 1705,
    'Brumano': 1705,
    'Brunate': 1705,
    'Brunello': 1705,
    'Brusaporto': 1705,
    'Brusimpiano': 1705,
    'Bubbiano': 1705,
    'Buccinasco': 1705,
    'Buffalora-Bettole': 1705,
    'Buglio in Monte': 1705,
    'Buguggiate': 1705,
    'Bulciago': 1705,
    'Bulgarograsso': 1705,
    'Bulgorello': 1705,
    'Burago di Molgora': 1705,
    'Buscate': 1705,
    'Buscoldo': 1705,
    'Busnago': 1705,
    'Bussero': 1705,
    'Busto Arsizio': 1705,
    'Busto Garolfo': 1705,
    'Ca   d  Andrea': 1705,
    'Cabiate': 1705,
    'Cadegliano-Viconago': 1705,
    'Cadenabbia': 1705,
    'Cadorago-Caslino al Piano': 1705,
    'Cadrezzate': 1705,
    'Caglio': 1705,
    'Cagno': 1705,
    'Caino': 1705,
    'Caiolo': 1705,
    'Cairate': 1705,
    'Calchera-Frontale': 1705,
    'Calcinate': 1705,
    'Calcinato': 1705,
    'Calcio': 1705,
    'Calco': 1705,
    'Caleppio': 1705,
    'Calò': 1705,
    'Calolziocorte': 1705,
    'Calozzo': 1705,
    'Calusco d  Adda': 1705,
    'Calvagese della Riviera': 1705,
    'Calvatone': 1705,
    'Calvenzano': 1705,
    'Calvignano': 1705,
    'Calvignasco': 1705,
    'Calvisano': 1705,
    'Camairago': 1705,
    'Cambiago': 1705,
    'Camerata Cornello': 1705,
    'Camisano': 1705,
    'Camnago-Boscone': 1705,
    'Camoneone': 1705,
    'Campagnola Cremasca': 1705,
    'Camparada': 1705,
    'Campione': 1705,
    'Campitello': 1705,
    'Campodolcino': 1705,
    'Campofiorenzo-California': 1705,
    'Campospinoso': 1705,
    'Candia Lomellina': 1705,
    'Canegrate': 1705,
    'Canneto': 1705,
    'Canneto Pavese': 1705,
    'Canneto sull  Oglio': 1705,
    'Canonica': 1705,
    'Canonica d  Adda': 1705,
    'Canova-San Zeno': 1705,
    'Cantalupo': 1705,
    'Cantello': 1705,
    'Cantù': 1705,
    'Canzo': 1705,
    'Capergnanica': 1705,
    'Capiago-Intimiano-Olmeda': 1705,
    'Capo di Ponte': 1705,
    'Caponago': 1705,
    'Capovalle': 1705,
    'Cappella Cantone': 1705,
    'Cappella de   Picenardi': 1705,
    'Cappelletta': 1705,
    'Capralba': 1705,
    'Capriano': 1705,
    'Capriano del Colle': 1705,
    'Capriate San Gervasio': 1705,
    'Caprino': 1705,
    'Capriolo': 1705,
    'Carate Brianza': 1705,
    'Carate Urio': 1705,
    'Caravaggio': 1705,
    'Caravate': 1705,
    'Carbonara al Ticino': 1705,
    'Carbonara di Po': 1705,
    'Carbonate': 1705,
    'Cardano al Campo': 1705,
    'Carenno': 1705,
    'Carimate': 1705,
    'Carlazzo': 1705,
    'Carnago': 1705,
    'Carnate': 1705,
    'Carobbio': 1705,
    'Carobbio degli Angeli': 1705,
    'Carona': 1705,
    'Caronno Pertusella': 1705,
    'Caronno Varesino': 1705,
    'Carpenedolo': 1705,
    'Carpiano': 1705,
    'Carugate': 1705,
    'Carugo': 1705,
    'Carvico': 1705,
    'Carzago Riviera': 1705,
    'Casa Ponte': 1705,
    'Casalbuttano': 1705,
    'Casale Cremasco': 1705,
    'Casale Litta': 1705,
    'Casaletto Ceredano': 1705,
    'Casaletto di Sopra': 1705,
    'Casaletto Lodigiano': 1705,
    'Casaletto Vaprio': 1705,
    'Casalmaggiore': 1705,
    'Casalmaiocco': 1705,
    'Casalmorano': 1705,
    'Casalmoro': 1705,
    'Casaloldo': 1705,
    'Casalpusterlengo': 1705,
    'Casalromano': 1705,
    'Casalzuigno': 1705,
    'Casanova Lonati': 1705,
    'Casargo': 1705,
    'Casarile': 1705,
    'Casasco Intelvi': 1705,
    'Casate': 1705,
    'Casatenovo': 1705,
    'Casatisma': 1705,
    'Casazza': 1705,
    'Casciago': 1705,
    'Cascina Elisa': 1705,
    'Casco': 1705,
    'Case Nuove': 1705,
    'Casei': 1705,
    'Caselle Landi': 1705,
    'Caselle Lurani': 1705,
    'Caseo': 1705,
    'Casirate d  Adda': 1705,
    'Caslino d  Erba': 1705,
    'Casnate Con Bernate': 1705,
    'Casnigo': 1705,
    'Casone': 1705,
    'Casorate Primo': 1705,
    'Casorate Sempione': 1705,
    'Casorezzo': 1705,
    'Caspoggio': 1705,
    'Cassago Brianza': 1705,
    'Cassano d  Adda': 1705,
    'Cassano Magnago': 1705,
    'Cassano Valcuvia': 1705,
    'Cassiglio': 1705,
    'Cassina de   Pecchi': 1705,
    'Cassina Rizzardi': 1705,
    'Cassina Valsassina': 1705,
    'Cassinetta di Lugagnano': 1705,
    'Cassino d  Alberi': 1705,
    'Cassolnovo': 1705,
    'Castana': 1705,
    'Castano Primo': 1705,
    'Casteggio': 1705,
    'Castegnato': 1705,
    'Castel d  Ario': 1705,
    'Castel Gabbiano': 1705,
    'Castel Goffredo': 1705,
    'Castel Mella': 1705,
    'Castel Rozzone': 1705,
    'Castelbelforte': 1705,
    'Castelcovati': 1705,
    'Casteldidone': 1705,
    'Castellanza': 1705,
    'Castelleone': 1705,
    'Castelletto': 1705,
    'Castelletto di Branduzzo': 1705,
    'Castelletto Po': 1705,
    'Castelli Calepio': 1705,
    'Castello': 1705,
    'Castello Cabiaglio': 1705,
    'Castello d  Agogna': 1705,
    'Castello dell  Acqua': 1705,
    'Castello di Brianza': 1705,
    'Castellucchio': 1705,
    'Castelmarte': 1705,
    'Castelnovetto': 1705,
    'Castelnuovo Bocca d  Adda': 1705,
    'Castelnuovo Bozzente': 1705,
    'Castelseprio': 1705,
    'Castelveccana': 1705,
    'Castelverde': 1705,
    'Castelvisconti': 1705,
    'Castenedolo': 1705,
    'Castiglione d  Adda': 1705,
    'Castiglione d  Intelvi': 1705,
    'Castiglione delle Stiviere': 1705,
    'Castiglione Olona': 1705,
    'Castione': 1705,
    'Castione Andevenno': 1705,
    'Castione della Presolana': 1705,
    'Castiraga Vidardo': 1705,
    'Casto': 1705,
    'Castrezzato': 1705,
    'Castro': 1705,
    'Castronno': 1705,
    'Cataeggio': 1705,
    'Cava Manara': 1705,
    'Cavacurta': 1705,
    'Cavallasca': 1705,
    'Cavargna': 1705,
    'Cavaria Con Premezzo': 1705,
    'Cavenago d  Adda': 1705,
    'Cavenago di Brianza': 1705,
    'Cavernago': 1705,
    'Cavriana': 1705,
    'Cazzago Brabbia': 1705,
    'Cazzago San Martino-Calino': 1705,
    'Cazzano Sant  Andrea': 1705,
    'Cecima': 1705,
    'Cedegolo': 1705,
    'Cedessano': 1705,
    'Cedrasco': 1705,
    'Cella Dati': 1705,
    'Cellatica': 1705,
    'Cenate di Sotto': 1705,
    'Cenate Sopra': 1705,
    'Cene': 1705,
    'Cepina': 1705,
    'Cerano d  Intelvi': 1705,
    'Ceranova': 1705,
    'Cercino': 1705,
    'Cerello-Battuello': 1705,
    'Ceresara': 1705,
    'Cerese': 1705,
    'Cerete Alto': 1705,
    'Ceretto Lomellina': 1705,
    'Cergnago': 1705,
    'Ceriano Laghetto': 1705,
    'Cerlongo': 1705,
    'Cermenate': 1705,
    'Cernobbio': 1705,
    'Cernusco Lombardone': 1705,
    'Cernusco sul Naviglio': 1705,
    'Cerro al Lambro': 1705,
    'Cerro Maggiore': 1705,
    'Certosa di Pavia': 1705,
    'Cerveno': 1705,
    'Cervesina': 1705,
    'Cervignano d  Adda': 1705,
    'Cesana Brianza': 1705,
    'Cesano Boscone': 1705,
    'Cesano Maderno': 1705,
    'Cesate': 1705,
    'Ceto': 1705,
    'Cevo': 1705,
    'Chiaravalle': 1705,
    'Chiari': 1705,
    'Chiavenna': 1705,
    'Chiesa': 1705,
    'Chiesa in Valmalenco': 1705,
    'Chieve': 1705,
    'Chignolo d  Isola': 1705,
    'Chignolo Po': 1705,
    'Chiuduno': 1705,
    'Chiuro': 1705,
    'Cicognolo': 1705,
    'Cigognola': 1705,
    'Cigole': 1705,
    'Cilavegna': 1705,
    'Cimbergo': 1705,
    'Cimbro': 1705,
    'Cingia de   Botti': 1705,
    'Cinisello Balsamo': 1705,
    'Cino': 1705,
    'Cirimido': 1705,
    'Cisano Bergamasco': 1705,
    'Ciserano': 1705,
    'Cislago': 1705,
    'Cisliano': 1705,
    'Città metropolitana di Milano': 1705,
    'Cittiglio': 1705,
    'Civate': 1705,
    'Civesio': 1705,
    'Cividate al Piano': 1705,
    'Cividate Camuno': 1705,
    'Cizzago-Comezzano': 1705,
    'Clivio': 1705,
    'Clusane': 1705,
    'Clusone': 1705,
    'Coccaglio': 1705,
    'Cocquio': 1705,
    'Codevilla': 1705,
    'Codogna-Cardano': 1705,
    'Codogno': 1705,
    'Cogliate': 1705,
    'Colere': 1705,
    'Colico Piano': 1705,
    'Colla-Muggiasca': 1705,
    'Colle Brianza': 1705,
    'Collebeato': 1705,
    'Collio': 1705,
    'Cologna-Caraverio': 1705,
    'Cologne': 1705,
    'Cologno al Serio': 1705,
    'Cologno Monzese': 1705,
    'Colombaro-Timoline': 1705,
    'Colonno': 1705,
    'Colorina': 1705,
    'Colturano': 1705,
    'Colzate': 1705,
    'Comabbio': 1705,
    'Comazzo': 1705,
    'Comerio': 1705,
    'Commessaggio': 1705,
    'Como': 1705,
    'Comun Nuovo': 1705,
    'Concesio': 1705,
    'Concorezzo': 1705,
    'Confienza': 1705,
    'Consiglio di Rumo': 1705,
    'Copiano': 1705,
    'Corana': 1705,
    'Corbetta': 1705,
    'Corgeno': 1705,
    'Cormano': 1705,
    'Corna Imagna': 1705,
    'Cornalba': 1705,
    'Cornale': 1705,
    'Cornaredo': 1705,
    'Cornate d  Adda': 1705,
    'Corneno-Galliano-Carella Mariaga': 1705,
    'Corno Giovine': 1705,
    'Cornovecchio': 1705,
    'Correzzana': 1705,
    'Corrido': 1705,
    'Corsico': 1705,
    'Corte de   Cortesi': 1705,
    'Corte de   Cortesi con Cignone': 1705,
    'Corte de   Frati': 1705,
    'Corte Franca': 1705,
    'Corteno Golgi': 1705,
    'Cortenova': 1705,
    'Cortenuova': 1705,
    'Corteolona': 1705,
    'Corvino San Quirico': 1705,
    'Corzano': 1705,
    'Cosio Valtellino': 1705,
    'Costa de   Nobili': 1705,
    'Costa di Mezzate': 1705,
    'Costa di Serina': 1705,
    'Costa Lambro': 1705,
    'Costa Masnaga': 1705,
    'Costa Sant  Abramo': 1705,
    'Costa Valle Imagna': 1705,
    'Costa Volpino': 1705,
    'Costa-Barco': 1705,
    'Covo': 1705,
    'Cozzo': 1705,
    'Crandola Valsassina': 1705,
    'Credaro': 1705,
    'Credera': 1705,
    'Crema': 1705,
    'Cremella': 1705,
    'Cremenaga': 1705,
    'Cremeno': 1705,
    'Cremia': 1705,
    'Cremona': 1705,
    'Cremosano': 1705,
    'Crespiatica': 1705,
    'Crone': 1705,
    'Crosio della Valle': 1705,
    'Crotta d  Adda': 1705,
    'Crugnola': 1705,
    'Cuasso al Monte': 1705,
    'Cuasso al Piano': 1705,
    'Cucciago': 1705,
    'Cuggiono': 1705,
    'Cugliate-Fabiasco': 1705,
    'Cumignano sul Naviglio': 1705,
    'Cunardo': 1705,
    'Cunettone-Villa': 1705,
    'Cura Carpignano': 1705,
    'Curiglia': 1705,
    'Curno': 1705,
    'Cusago': 1705,
    'Cusano': 1705,
    'Cusino': 1705,
    'Cusio': 1705,
    'Cuveglio': 1705,
    'Cuvio': 1705,
    'Dairago': 1705,
    'Dalmine': 1705,
    'Darfo Boario Terme': 1705,
    'Daverio': 1705,
    'Dazio': 1705,
    'Delebio': 1705,
    'Dello': 1705,
    'Derovere': 1705,
    'Dervio': 1705,
    'Desenzano del Garda': 1705,
    'Desio': 1705,
    'Dizzasco-Biazzeno': 1705,
    'Dolzago': 1705,
    'Domaso': 1705,
    'Dongo': 1705,
    'Dorio': 1705,
    'Dorno': 1705,
    'Dosimo': 1705,
    'Dosolo': 1705,
    'Dossena': 1705,
    'Dosso del Liro': 1705,
    'Dosso-Ville': 1705,
    'Dovera': 1705,
    'Dresano': 1705,
    'Drizzona': 1705,
    'Dubino': 1705,
    'Dumenza': 1705,
    'Duno': 1705,
    'Duomo': 1705,
    'Edolo': 1705,
    'Ello': 1705,
    'Endine': 1705,
    'Entratico': 1705,
    'Era': 1705,
    'Erba': 1705,
    'Erbusco': 1705,
    'Eremo': 1705,
    'Erve': 1705,
    'Esine': 1705,
    'Esino Lario': 1705,
    'Faedo Valtellino': 1705,
    'Faggeto Lario': 1705,
    'Fagnano Olona-Bergoro': 1705,
    'Faloppio': 1705,
    'Fantasina': 1705,
    'Fara Gera d  Adda': 1705,
    'Fara Olivana': 1705,
    'Felonica': 1705,
    'Fenegrò': 1705,
    'Ferno': 1705,
    'Ferrera di Varese': 1705,
    'Ferrera Erbognone': 1705,
    'Fiesco': 1705,
    'Fiesse': 1705,
    'Figino': 1705,
    'Figino Serenza': 1705,
    'Figliaro': 1705,
    'Filago': 1705,
    'Filighera': 1705,
    'Fino del Monte': 1705,
    'Fino Mornasco': 1705,
    'Fiorano al Serio': 1705,
    'Fizzonasco': 1705,
    'Flero': 1705,
    'Folzano': 1705,
    'Fombio': 1705,
    'Fontane-Zurane-Gresine': 1705,
    'Fontanella': 1705,
    'Fonteno': 1705,
    'Foppolo': 1705,
    'Forcola': 1705,
    'Foresto Sparso': 1705,
    'Formigara': 1705,
    'Fornaci': 1705,
    'Fornovo San Giovanni': 1705,
    'Fortunago': 1705,
    'Frascarolo': 1705,
    'Fuipiano Valle Imagna': 1705,
    'Furato': 1705,
    'Fusine': 1705,
    'Gabbioneta': 1705,
    'Gadesco-Pieve Delmona': 1705,
    'Gaggiano': 1705,
    'Gaggino': 1705,
    'Galbiate': 1705,
    'Galgagnano': 1705,
    'Gallarate': 1705,
    'Galliate Lombardo': 1705,
    'Galliavola': 1705,
    'Gallo-Tre Re-Mezzana Corti': 1705,
    'Gambara': 1705,
    'Gambarana': 1705,
    'Gambolò': 1705,
    'Gandellino': 1705,
    'Gandino': 1705,
    'Gandosso': 1705,
    'Ganna': 1705,
    'Garbagnate': 1705,
    'Garbagnate Milanese': 1705,
    'Garbagnate Monastero': 1705,
    'Gardola': 1705,
    'Gardone Riviera': 1705,
    'Gardone Val Trompia': 1705,
    'Gargnano': 1705,
    'Garlasco': 1705,
    'Garlate': 1705,
    'Garzeno': 1705,
    'Gavardo-Sopraponte': 1705,
    'Gavarno Rinnovata': 1705,
    'Gavarno-Tribulina': 1705,
    'Gaverina Terme': 1705,
    'Gavirate': 1705,
    'Gazoldo degli Ippoliti': 1705,
    'Gazzada Schianno': 1705,
    'Gazzaniga': 1705,
    'Gazzo': 1705,
    'Gazzuolo': 1705,
    'Gemonio': 1705,
    'Genivolta': 1705,
    'Genzone': 1705,
    'Gera Lario': 1705,
    'Gerenzago': 1705,
    'Gerenzano': 1705,
    'Germignaga': 1705,
    'Gerola Alta': 1705,
    'Geromina': 1705,
    'Gerosa': 1705,
    'Gerre de   Caprioli': 1705,
    'Gessate': 1705,
    'Ghedi': 1705,
    'Ghiaie': 1705,
    'Ghisalba': 1705,
    'Gianico': 1705,
    'Giovenzano': 1705,
    'Gironico al Piano': 1705,
    'Giussago': 1705,
    'Giussano': 1705,
    'Godiasco': 1705,
    'Goito': 1705,
    'Golasecca': 1705,
    'Golferenzo': 1705,
    'Gombito': 1705,
    'Gonzaga': 1705,
    'Gordona': 1705,
    'Gorgonzola': 1705,
    'Gorla Maggiore': 1705,
    'Gorla Minore': 1705,
    'Gorlago': 1705,
    'Gorle': 1705,
    'Gornate Olona': 1705,
    'Gorno': 1705,
    'Gottolengo': 1705,
    'Graffignana': 1705,
    'Grandate': 1705,
    'Grandola ed Uniti': 1705,
    'Grantola': 1705,
    'Grassobbio': 1705,
    'Gratacasolo': 1705,
    'Gravedona-San Gregorio': 1705,
    'Gravellona': 1705,
    'Grezzago': 1705,
    'Griante': 1705,
    'Grignano': 1705,
    'Gromlongo': 1705,
    'Gromo': 1705,
    'Grone': 1705,
    'Grontardo': 1705,
    'Gropello Cairoli': 1705,
    'Grosio': 1705,
    'Grosotto': 1705,
    'Grumello Cremonese': 1705,
    'Grumello del Monte': 1705,
    'Guanzate': 1705,
    'Guardamiglio': 1705,
    'Gudo Visconti': 1705,
    'Guidizzolo': 1705,
    'Guinzano': 1705,
    'Gussago': 1705,
    'Gussola': 1705,
    'Idro': 1705,
    'Imbersago': 1705,
    'Inarzo': 1705,
    'Incudine': 1705,
    'Induno Olona': 1705,
    'Introbio': 1705,
    'Introzzo': 1705,
    'Inverigo': 1705,
    'Inverno': 1705,
    'Inverno e Monteleone': 1705,
    'Inveruno': 1705,
    'Inzago': 1705,
    'Irma': 1705,
    'Iseo': 1705,
    'Isola di Fondra': 1705,
    'Isola Dovarese': 1705,
    'Isolaccia': 1705,
    'Isorella': 1705,
    'Ispra': 1705,
    'Isso': 1705,
    'Izano': 1705,
    'Jerago Con Orago': 1705,
    'Lacchiarella': 1705,
    'Laglio': 1705,
    'Lainate': 1705,
    'Laino': 1705,
    'Lallio': 1705,
    'Lambrinia': 1705,
    'Lambrugo': 1705,
    'Landriano': 1705,
    'Langosco': 1705,
    'Lanzada': 1705,
    'Lanzo d  Intelvi': 1705,
    'Lardirago': 1705,
    'Lasnigo': 1705,
    'Lavena Ponte Tresa': 1705,
    'Laveno': 1705,
    'Lavenone': 1705,
    'Laxolo': 1705,
    'Lazzate': 1705,
    'Lecco': 1705,
    'Leffe': 1705,
    'Leggiuno': 1705,
    'Legnano': 1705,
    'Lemna': 1705,
    'Lenna': 1705,
    'Lenno': 1705,
    'Leno': 1705,
    'Lentate sul Seveso': 1705,
    'Lesmo': 1705,
    'Levata': 1705,
    'Levate': 1705,
    'Lezzeno': 1705,
    'Lierna': 1705,
    'Limbiate': 1705,
    'Limido Comasco': 1705,
    'Limone sul Garda': 1705,
    'Linarolo': 1705,
    'Linate': 1705,
    'Lipomo': 1705,
    'Lirio': 1705,
    'Lisanza': 1705,
    'Liscate': 1705,
    'Lissone': 1705,
    'Livigno': 1705,
    'Livo': 1705,
    'Livraga': 1705,
    'Locate di Triulzi': 1705,
    'Locate Varesino': 1705,
    'Locatello': 1705,
    'Lodetto': 1705,
    'Lodi': 1705,
    'Lodi Vecchio': 1705,
    'Lodrino': 1705,
    'Lograto': 1705,
    'Lomagna': 1705,
    'Lomazzo': 1705,
    'Lomello': 1705,
    'Lonate Ceppino': 1705,
    'Lonate Pozzolo': 1705,
    'Lonato': 1705,
    'Longhena': 1705,
    'Longone al Segrino': 1705,
    'Losine': 1705,
    'Lovere': 1705,
    'Lovero': 1705,
    'Lozio': 1705,
    'Lozza': 1705,
    'Lucino': 1705,
    'Lucino-Rodano': 1705,
    'Ludriano': 1705,
    'Luino': 1705,
    'Luisago': 1705,
    'Lumezzane': 1705,
    'Lunetta-Frassino': 1705,
    'Lungavilla': 1705,
    'Lurago d  Erba': 1705,
    'Lurago Marinone': 1705,
    'Lurano': 1705,
    'Lurate Caccivio': 1705,
    'Luvinate': 1705,
    'Luzzana': 1705,
    'Maccagno': 1705,
    'Maccastorna': 1705,
    'Macherio': 1705,
    'Maclodio': 1705,
    'Madignano': 1705,
    'Madone': 1705,
    'Magasa': 1705,
    'Magenta': 1705,
    'Magherno': 1705,
    'Magnacavallo': 1705,
    'Magnago': 1705,
    'Magno': 1705,
    'Magreglio': 1705,
    'Mairago': 1705,
    'Mairano': 1705,
    'Malagnino': 1705,
    'Malavicina': 1705,
    'Malegno': 1705,
    'Maleo': 1705,
    'Malgesso': 1705,
    'Malgrate': 1705,
    'Malnate': 1705,
    'Malonno': 1705,
    'Mandello del Lario': 1705,
    'Manera': 1705,
    'Manerba del Garda': 1705,
    'Manerbio': 1705,
    'Mantegazza': 1705,
    'Mantello': 1705,
    'Mantova': 1705,
    'Mapello': 1705,
    'Marcallo': 1705,
    'Marcaria': 1705,
    'Marcheno': 1705,
    'Marchirolo': 1705,
    'Marcignago': 1705,
    'Maresso': 1705,
    'Margno': 1705,
    'Mariana Mantovana': 1705,
    'Mariano Comense': 1705,
    'Marmentino': 1705,
    'Marmirolo': 1705,
    'Marnate': 1705,
    'Marone': 1705,
    'Martignana di Po': 1705,
    'Martinengo': 1705,
    'Marudo': 1705,
    'Marzano': 1705,
    'Marzio': 1705,
    'Masate': 1705,
    'Masciago Primo': 1705,
    'Maslianico': 1705,
    'Massalengo-Motta Vigana': 1705,
    'Mazzano': 1705,
    'Mazzo di Valtellina': 1705,
    'Meda': 1705,
    'Mede': 1705,
    'Mediglia': 1705,
    'Medolago': 1705,
    'Medole': 1705,
    'Melegnano': 1705,
    'Meleti': 1705,
    'Mello': 1705,
    'Melzo': 1705,
    'Menaggio': 1705,
    'Menarola': 1705,
    'Menconico': 1705,
    'Merate': 1705,
    'Mercallo': 1705,
    'Merlino': 1705,
    'Merone': 1705,
    'Mese': 1705,
    'Mesenzana': 1705,
    'Mesero': 1705,
    'Mezzago': 1705,
    'Mezzana Bigli': 1705,
    'Mezzana Rabattone': 1705,
    'Mezzanino': 1705,
    'Mezzate': 1705,
    'Mezzegra': 1705,
    'Mezzoldo': 1705,
    'Milan': 1705,
    'Millepini': 1705,
    'Milzano': 1705,
    'Miradolo Terme': 1705,
    'Misano di Gera d  Adda': 1705,
    'Misinto': 1705,
    'Missaglia': 1705,
    'Moggio': 1705,
    'Moglia': 1705,
    'Moio de   Calvi': 1705,
    'Molinetto': 1705,
    'Molteno': 1705,
    'Moltrasio': 1705,
    'Mombelli': 1705,
    'Mombretto': 1705,
    'Monasterolo': 1705,
    'Monasterolo del Castello': 1705,
    'Moncucco': 1705,
    'Moniga del Garda': 1705,
    'Monno': 1705,
    'Montagna in Valtellina': 1705,
    'Montalto Pavese': 1705,
    'Montanara': 1705,
    'Montanaso Lombardo': 1705,
    'Montano': 1705,
    'Montano Lucino': 1705,
    'Monte': 1705,
    'Monte Cremasco': 1705,
    'Monte Marenzo': 1705,
    'Montebello': 1705,
    'Montecalvo Versiggia': 1705,
    'Montegrino Valtravaglia': 1705,
    'Montello': 1705,
    'Montemezzo': 1705,
    'Montescano': 1705,
    'Montesegale': 1705,
    'Montesolaro': 1705,
    'Montevecchia': 1705,
    'Monticelli Brusati': 1705,
    'Monticelli Pavese': 1705,
    'Monticello': 1705,
    'Monticello Brianza': 1705,
    'Montichiari': 1705,
    'Montirone': 1705,
    'Montodine': 1705,
    'Montorfano': 1705,
    'Montù Beccaria': 1705,
    'Monvalle': 1705,
    'Monza': 1705,
    'Monzambano': 1705,
    'Morazzone': 1705,
    'Morbegno': 1705,
    'Morengo': 1705,
    'Morimondo': 1705,
    'Mornago': 1705,
    'Mornico': 1705,
    'Mornico al Serio': 1705,
    'Mornico Losana': 1705,
    'Mortara': 1705,
    'Morterone': 1705,
    'Moscazzano': 1705,
    'Motta Baluffi': 1705,
    'Motta Visconti': 1705,
    'Motteggiana': 1705,
    'Mottella': 1705,
    'Mozzanica': 1705,
    'Mozzate': 1705,
    'Mozzo': 1705,
    'Muggiò': 1705,
    'Mulazzano': 1705,
    'Mura': 1705,
    'Muscoline': 1705,
    'Musso': 1705,
    'Muzza di Cornegliano Laudense': 1705,
    'Nava': 1705,
    'Nave': 1705,
    'Navedano': 1705,
    'Negrone': 1705,
    'Nembro': 1705,
    'Nerviano': 1705,
    'Nesso': 1705,
    'Niardo': 1705,
    'Nibionno': 1705,
    'Nicorvo': 1705,
    'Nigoline-Bonomelli': 1705,
    'Nobile-Monguzzo': 1705,
    'Nosadello': 1705,
    'Nosate': 1705,
    'Nova Milanese': 1705,
    'Novagli': 1705,
    'Novate Mezzola': 1705,
    'Novate Milanese': 1705,
    'Novedrate': 1705,
    'Novegro-Tregarezzo-San Felice': 1705,
    'Noverasco-Sporting Mirasole': 1705,
    'Noviglio': 1705,
    'Nuvolento': 1705,
    'Nuvolera': 1705,
    'Occagno': 1705,
    'Odolo': 1705,
    'Offanengo': 1705,
    'Offlaga': 1705,
    'Oggiona-Santo Stefano': 1705,
    'Oggiono': 1705,
    'Olevano di Lomellina': 1705,
    'Olgiate Comasco': 1705,
    'Olgiate Molgora': 1705,
    'Olgiate Olona': 1705,
    'Olginate': 1705,
    'Oliva Gessi': 1705,
    'Olmeneta': 1705,
    'Olmo al Brembo': 1705,
    'Oltre Il Colle': 1705,
    'Oltressenda Alta': 1705,
    'Oltrona di San Mamette': 1705,
    'Ome': 1705,
    'Oneta': 1705,
    'Ono San Pietro': 1705,
    'Onore': 1705,
    'Opera': 1705,
    'Origgio': 1705,
    'Orino': 1705,
    'Orio al Serio': 1705,
    'Orio Litta': 1705,
    'Ornago': 1705,
    'Ornica': 1705,
    'Orsenigo': 1705,
    'Orzinuovi': 1705,
    'Orzivecchi': 1705,
    'Osigo': 1705,
    'Osio Sopra': 1705,
    'Osio Sotto': 1705,
    'Osmate': 1705,
    'Osnago': 1705,
    'Ospedaletto Lodigiano': 1705,
    'Ospitaletto': 1705,
    'Ossago Lodigiano': 1705,
    'Ossimo Superiore': 1705,
    'Ossona': 1705,
    'Ossuccio': 1705,
    'Osteno': 1705,
    'Osteno-Claino': 1705,
    'Ostiano': 1705,
    'Ostiglia': 1705,
    'Ottobiano': 1705,
    'Ozzero': 1705,
    'Padenghe sul Garda': 1705,
    'Paderno d  Adda': 1705,
    'Paderno Dugnano': 1705,
    'Paderno Franciacorta': 1705,
    'Paderno Ponchielli': 1705,
    'Pagazzano': 1705,
    'Pagnona': 1705,
    'Paisco Loveno': 1705,
    'Paitone': 1705,
    'Paladina': 1705,
    'Palazzago': 1705,
    'Palazzo Pignano': 1705,
    'Palazzolo sull  Oglio': 1705,
    'Palestro': 1705,
    'Palidano': 1705,
    'Palosco': 1705,
    'Pancarana': 1705,
    'Pandino': 1705,
    'Pantigliate': 1705,
    'Parabiago': 1705,
    'Paratico': 1705,
    'Parè': 1705,
    'Parlasco': 1705,
    'Parmezzana Calzana': 1705,
    'Parona': 1705,
    'Parre': 1705,
    'Parzanica': 1705,
    'Paspardo': 1705,
    'Passirana': 1705,
    'Passirano': 1705,
    'Pasturo': 1705,
    'Paullo': 1705,
    'Pavia': 1705,
    'Pavone del Mella': 1705,
    'Pedesina': 1705,
    'Pedrengo': 1705,
    'Peglio': 1705,
    'Pegognaga': 1705,
    'Peia': 1705,
    'Pellio Intelvi': 1705,
    'Perego': 1705,
    'Perledo': 1705,
    'Pero': 1705,
    'Persico Dosimo': 1705,
    'Pertica Alta': 1705,
    'Pertica Bassa': 1705,
    'Pescarolo': 1705,
    'Pescate': 1705,
    'Pessano Con Bornago': 1705,
    'Pessina Cremonese': 1705,
    'Pezzaze': 1705,
    'Piadena': 1705,
    'Piamborno': 1705,
    'Pian Camuno': 1705,
    'Pianazzo': 1705,
    'Pianello del Lario': 1705,
    'Pianengo': 1705,
    'Piangaiano': 1705,
    'Pianico': 1705,
    'Piantedo': 1705,
    'Piario': 1705,
    'Piateda Centro': 1705,
    'Piazza': 1705,
    'Piazza Brembana': 1705,
    'Piazza Caduti': 1705,
    'Piazzatorre': 1705,
    'Piazzolo': 1705,
    'Pieranica': 1705,
    'Pietra de   Giorgi': 1705,
    'Pieve': 1705,
    'Pieve Albignola': 1705,
    'Pieve d  Olmi': 1705,
    'Pieve del Cairo': 1705,
    'Pieve di Coriano': 1705,
    'Pieve Emanuele': 1705,
    'Pieve Fissiraga': 1705,
    'Pieve Porto Morone': 1705,
    'Pieve San Giacomo': 1705,
    'Pievedizio': 1705,
    'Pigra': 1705,
    'Pinarolo Po': 1705,
    'Pino sulla Sponda del Lago Maggiore': 1705,
    'Pioltello': 1705,
    'Pisogne': 1705,
    'Piubega': 1705,
    'Piuro': 1705,
    'Pizzale': 1705,
    'Pizzighettone': 1705,
    'Plesio': 1705,
    'Poasco-Sorigherio': 1705,
    'Poggio Rusco': 1705,
    'Poggiridenti Alto': 1705,
    'Poggiridenti Piano': 1705,
    'Pogliano Milanese': 1705,
    'Pognana Lario': 1705,
    'Pognano': 1705,
    'Polaveno': 1705,
    'Polpenazze del Garda': 1705,
    'Pometo': 1705,
    'Pompiano': 1705,
    'Pomponesco': 1705,
    'Poncarale': 1705,
    'Ponna Superiore': 1705,
    'Ponte Caffaro': 1705,
    'Ponte Cingoli': 1705,
    'Ponte di Legno': 1705,
    'Ponte in Valtellina': 1705,
    'Ponte Lambro': 1705,
    'Ponte Nizza': 1705,
    'Ponte Nossa': 1705,
    'Ponte San Marco': 1705,
    'Ponte San Pietro': 1705,
    'Ponteranica': 1705,
    'Pontevico': 1705,
    'Ponti sul Mincio': 1705,
    'Pontida': 1705,
    'Pontirolo Nuovo': 1705,
    'Pontoglio': 1705,
    'Porlezza': 1705,
    'Portalbera': 1705,
    'Porto Ceresio': 1705,
    'Porto d  Adda': 1705,
    'Porto Valtravaglia': 1705,
    'Postalesio': 1705,
    'Pozzaglio': 1705,
    'Pozzaglio ed Uniti': 1705,
    'Pozzo d  Adda': 1705,
    'Pozzolengo': 1705,
    'Pozzuolo Martesana': 1705,
    'Pradalunga': 1705,
    'Pralboino': 1705,
    'Prata Camportaccio': 1705,
    'Prata Centro': 1705,
    'Predore': 1705,
    'Pregnana Milanese': 1705,
    'Pregola': 1705,
    'Premana': 1705,
    'Premenugo': 1705,
    'Premolo': 1705,
    'Preseglie': 1705,
    'Presezzo': 1705,
    'Prevalle': 1705,
    'Primaluna': 1705,
    'Proserpio': 1705,
    'Prosto': 1705,
    'Provaglio d  Iseo': 1705,
    'Provaglio Val Sabbia': 1705,
    'Provezze': 1705,
    'Provincia di Bergamo': 1705,
    'Provincia di Brescia': 1705,
    'Provincia di Como': 1705,
    'Provincia di Cremona': 1705,
    'Provincia di Lecco': 1705,
    'Provincia di Lodi': 1705,
    'Provincia di Mantova': 1705,
    'Provincia di Monza e della Brianza': 1705,
    'Provincia di Pavia': 1705,
    'Provincia di Sondrio': 1705,
    'Provincia di Varese': 1705,
    'Puegnago sul Garda': 1705,
    'Puginate': 1705,
    'Pumenengo': 1705,
    'Pusiano': 1705,
    'Quartiano': 1705,
    'Quattro Strade': 1705,
    'Quingentole': 1705,
    'Quintano': 1705,
    'Quinzano d  Oglio': 1705,
    'Quistello': 1705,
    'Raffa': 1705,
    'Ramponio Verna': 1705,
    'Rancio Valcuvia': 1705,
    'Ranco': 1705,
    'Ranica': 1705,
    'Ranzanico': 1705,
    'Rasura': 1705,
    'Rea': 1705,
    'Redavalle': 1705,
    'Redondesco': 1705,
    'Regoledo': 1705,
    'Remedello': 1705,
    'Remedello di Sopra': 1705,
    'Renate': 1705,
    'Rescaldina': 1705,
    'Retorbido': 1705,
    'Revere': 1705,
    'Rezzago': 1705,
    'Rezzato': 1705,
    'Rho': 1705,
    'Ricengo': 1705,
    'Riozzo': 1705,
    'Ripalta Arpina': 1705,
    'Ripalta Guerina': 1705,
    'Ripalta Nuova': 1705,
    'Riva di Solto': 1705,
    'Rivanazzano': 1705,
    'Rivarolo del Re': 1705,
    'Rivarolo Mantovano': 1705,
    'Rivolta d  Adda': 1705,
    'Robbiate': 1705,
    'Robbio': 1705,
    'Robecchetto Con Induno': 1705,
    'Robecco d  Oglio': 1705,
    'Robecco Pavese': 1705,
    'Robecco sul Naviglio': 1705,
    'Roccafranca': 1705,
    'Rodengo-Saiano': 1705,
    'Rodero': 1705,
    'Rodigo': 1705,
    'Roè': 1705,
    'Roè Volciano': 1705,
    'Rogeno': 1705,
    'Roggione': 1705,
    'Rognano': 1705,
    'Rogno': 1705,
    'Rogolo': 1705,
    'Rogoredo-Valaperta-Rimoldo': 1705,
    'Romagnese': 1705,
    'Romanengo': 1705,
    'Romano Banco': 1705,
    'Romano di Lombardia': 1705,
    'Ronago': 1705,
    'Roncadelle': 1705,
    'Roncaro': 1705,
    'Roncello': 1705,
    'Ronco': 1705,
    'Ronco Briantino': 1705,
    'Roncobello': 1705,
    'Roncoferraro': 1705,
    'Roncola': 1705,
    'Rosasco': 1705,
    'Rosate': 1705,
    'Rota d  Imagna': 1705,
    'Rotta': 1705,
    'Rovagnate': 1705,
    'Rovato': 1705,
    'Rovellasca': 1705,
    'Rovello Porro': 1705,
    'Roverbella': 1705,
    'Rovescala': 1705,
    'Rovetta': 1705,
    'Rozzano': 1705,
    'Rudiano': 1705,
    'Ruino': 1705,
    'Sabbio Chiese': 1705,
    'Sabbioneta': 1705,
    'Sacca': 1705,
    'Sairano': 1705,
    'Sala al Barro': 1705,
    'Sala Comacina': 1705,
    'Sale Marasino': 1705,
    'Salerano sul Lambro': 1705,
    'Salice Terme': 1705,
    'Salò': 1705,
    'Saltrio': 1705,
    'Salvirola': 1705,
    'Samarate': 1705,
    'Samolaco': 1705,
    'San Bartolomeo Val Cavargna': 1705,
    'San Bassano': 1705,
    'San Benedetto Po': 1705,
    'San Biagio': 1705,
    'San Bovio-San Felice': 1705,
    'San Cassiano': 1705,
    'San Cipriano Po': 1705,
    'San Colombano al Lambro': 1705,
    'San Damiano al Colle': 1705,
    'San Daniele Po': 1705,
    'San Donato Milanese': 1705,
    'San Fedele Intelvi': 1705,
    'San Fedele Superiore': 1705,
    'San Felice': 1705,
    'San Felice del Benaco': 1705,
    'San Fermo della Battaglia': 1705,
    'San Fiorano': 1705,
    'San Genesio ed Uniti': 1705,
    'San Gervasio Bresciano': 1705,
    'San Giacomo': 1705,
    'San Giacomo delle Segnate': 1705,
    'San Giacomo Filippo': 1705,
    'San Giorgio di Lomellina': 1705,
    'San Giorgio Su Legnano': 1705,
    'San Giovanni Bianco': 1705,
    'San Giovanni del Dosso': 1705,
    'San Giovanni in Croce': 1705,
    'San Giuliano Milanese': 1705,
    'San Lorenzo di Rovetta': 1705,
    'San Mamete': 1705,
    'San Martino Dall  Argine': 1705,
    'San Martino del Lago': 1705,
    'San Martino della Battaglia': 1705,
    'San Martino in Strada': 1705,
    'San Martino Siccomario': 1705,
    'San Michele-San Giorgio': 1705,
    'San Nazzaro Val Cavargna': 1705,
    'San Nicolò': 1705,
    'San Paolo': 1705,
    'San Paolo d  Argon': 1705,
    'San Pellegrino Terme': 1705,
    'San Rocco': 1705,
    'San Rocco al Porto': 1705,
    'San Sebastiano': 1705,
    'San Siro': 1705,
    'San Vigilio': 1705,
    'San Vittore': 1705,
    'San Vittore Olona': 1705,
    'San Zeno Naviglio': 1705,
    'San Zenone': 1705,
    'San Zenone al Lambro': 1705,
    'San Zenone al Po': 1705,
    'Sangiano': 1705,
    'Sannazzaro de   Burgondi': 1705,
    'Sant  Agata Martesana': 1705,
    'Sant  Alessio Con Vialone': 1705,
    'Sant  Angelo Lodigiano': 1705,
    'Sant  Angelo Lomellina': 1705,
    'Sant  Antonio': 1705,
    'Sant  Omobono Terme': 1705,
    'Santa Brigida': 1705,
    'Santa Corinna': 1705,
    'Santa Cristina': 1705,
    'Santa Giuletta': 1705,
    'Santa Margherita': 1705,
    'Santa Margherita di Staffora': 1705,
    'Santa Maria dei Sabbioni': 1705,
    'Santa Maria della Versa': 1705,
    'Santa Maria Hoè': 1705,
    'Santo Stefano Lodigiano': 1705,
    'Santo Stefano Ticino': 1705,
    'Sarezzo': 1705,
    'Sarnico': 1705,
    'Saronno': 1705,
    'Sartirana Lomellina': 1705,
    'Saviore': 1705,
    'Scaldasole': 1705,
    'Scandolara Ravara': 1705,
    'Scandolara Ripa d  Oglio': 1705,
    'Scannabue-Cascine Capri': 1705,
    'Scano al Brembo': 1705,
    'Scanzo-Rosciate': 1705,
    'Schignano': 1705,
    'Schilpario': 1705,
    'Schivenoglia': 1705,
    'Secugnago': 1705,
    'Sedriano': 1705,
    'Sedrina': 1705,
    'Segrate': 1705,
    'Selino Basso': 1705,
    'Sellero': 1705,
    'Selvino': 1705,
    'Semiana': 1705,
    'Senago': 1705,
    'Seniga': 1705,
    'Senna Comasco': 1705,
    'Senna Lodigiana': 1705,
    'Seregno': 1705,
    'Sergnano': 1705,
    'Seriate': 1705,
    'Serina': 1705,
    'Serle': 1705,
    'Sermide': 1705,
    'Sernio': 1705,
    'Serone': 1705,
    'Serravalle a Po': 1705,
    'Sesto Calende': 1705,
    'Sesto ed Uniti': 1705,
    'Sesto San Giovanni': 1705,
    'Settala': 1705,
    'Settimo Milanese': 1705,
    'Seveso': 1705,
    'Silvano Pietra': 1705,
    'Sirmione': 1705,
    'Sirone': 1705,
    'Sirta': 1705,
    'Sirtori': 1705,
    'Siviano': 1705,
    'Siziano': 1705,
    'Soave': 1705,
    'Soiano': 1705,
    'Solaro': 1705,
    'Solarolo': 1705,
    'Solarolo Rainerio': 1705,
    'Solbiate': 1705,
    'Solbiate Arno': 1705,
    'Solbiate Olona': 1705,
    'Solferino': 1705,
    'Solto Collina': 1705,
    'Solza': 1705,
    'Somaglia': 1705,
    'Somma Lombardo': 1705,
    'Sommo': 1705,
    'Soncino': 1705,
    'Sondalo': 1705,
    'Sondrio': 1705,
    'Songavazzo': 1705,
    'Sonico': 1705,
    'Sordio': 1705,
    'Soresina': 1705,
    'Sorico': 1705,
    'Sorisole': 1705,
    'Sormano': 1705,
    'Sospiro': 1705,
    'Sotto il Monte Giovanni XXIII': 1705,
    'Sottocastello': 1705,
    'Sovere': 1705,
    'Sovico': 1705,
    'Spessa': 1705,
    'Spinadesco': 1705,
    'Spineda': 1705,
    'Spino d  Adda': 1705,
    'Spinone al Lago': 1705,
    'Spirano': 1705,
    'Spriana': 1705,
    'Stagno Lombardo': 1705,
    'Stazzona': 1705,
    'Stezzano': 1705,
    'Stradella': 1705,
    'Stravignino': 1705,
    'Strozza': 1705,
    'Suardi': 1705,
    'Sueglio': 1705,
    'Suello': 1705,
    'Suisio': 1705,
    'Sulbiate': 1705,
    'Sulzano': 1705,
    'Sumirago': 1705,
    'Susella': 1705,
    'Sustinente': 1705,
    'Suzzara': 1705,
    'Tabiago-Cibrone': 1705,
    'Taceno': 1705,
    'Taino': 1705,
    'Talamona': 1705,
    'Taleggio': 1705,
    'Tartano': 1705,
    'Tavazzano': 1705,
    'Tavernerio': 1705,
    'Tavernola Bergamasca': 1705,
    'Tavernole': 1705,
    'Teglio': 1705,
    'Telgate': 1705,
    'Temù': 1705,
    'Ternate': 1705,
    'Terno d  Isola': 1705,
    'Terranova dei Passerini': 1705,
    'Terraverde-Corte Palasio': 1705,
    'Ticengo': 1705,
    'Tirano': 1705,
    'Torbole Casaglia': 1705,
    'Torchione-Moia': 1705,
    'Torlino Vimercati': 1705,
    'Tornata': 1705,
    'Torno': 1705,
    'Torrazza Coste': 1705,
    'Torrazza dei Mandelli': 1705,
    'Torre Beretti': 1705,
    'Torre Boldone': 1705,
    'Torre d  Arese': 1705,
    'Torre d  Isola': 1705,
    'Torre de   Busi': 1705,
    'Torre de   Negri': 1705,
    'Torre de   Picenardi': 1705,
    'Torre de   Roveri': 1705,
    'Torre del Mangano': 1705,
    'Torre di Santa Maria': 1705,
    'Torre Pallavicina': 1705,
    'Torrevecchia Pia': 1705,
    'Torricella del Pizzo': 1705,
    'Torricella Verzate': 1705,
    'Toscolano Maderno': 1705,
    'Tovo di Sant  Agata': 1705,
    'Tradate': 1705,
    'Traona': 1705,
    'Travacò Siccomario': 1705,
    'Travagliato': 1705,
    'Travedona Monate': 1705,
    'Trebbio': 1705,
    'Trecella': 1705,
    'Tregasio': 1705,
    'Tremenico': 1705,
    'Tremezzo': 1705,
    'Trenzano': 1705,
    'Trescore Balneario': 1705,
    'Trescore Cremasco': 1705,
    'Tresivio': 1705,
    'Treviglio': 1705,
    'Treviolo': 1705,
    'Trezzano Rosa': 1705,
    'Trezzano sul Naviglio': 1705,
    'Trezzo sull  Adda': 1705,
    'Trezzone': 1705,
    'Tribiano': 1705,
    'Triginto': 1705,
    'Trigolo': 1705,
    'Triuggio': 1705,
    'Trivolzio': 1705,
    'Tromello': 1705,
    'Tronzano Lago Maggiore': 1705,
    'Trovo': 1705,
    'Truccazzano': 1705,
    'Turano Lodigiano': 1705,
    'Turate': 1705,
    'Turbigo': 1705,
    'Ubiale': 1705,
    'Ubiale Clanezzo': 1705,
    'Uboldo': 1705,
    'Uggiate Trevano': 1705,
    'Urago d  Oglio': 1705,
    'Urgnano': 1705,
    'Usmate-Velate': 1705,
    'Vaiano Cremasco': 1705,
    'Vailate': 1705,
    'Val Masino': 1705,
    'Val Rezzo': 1705,
    'Valbrona': 1705,
    'Valdisotto': 1705,
    'Valeggio': 1705,
    'Valera Fratta': 1705,
    'Valganna': 1705,
    'Valgoglio': 1705,
    'Valgreghentino': 1705,
    'Vall  Alta': 1705,
    'Valle Lomellina': 1705,
    'Valle Salimbene': 1705,
    'Valleve': 1705,
    'Vallio Terme': 1705,
    'Valmadrera-Caserta': 1705,
    'Valmorea': 1705,
    'Valnegra': 1705,
    'Valtorta': 1705,
    'Valvestino': 1705,
    'Vanzaghello': 1705,
    'Vanzago': 1705,
    'Vaprio d  Adda': 1705,
    'Varano Borghi': 1705,
    'Varedo': 1705,
    'Varenna': 1705,
    'Varese': 1705,
    'Varzi': 1705,
    'Vassena': 1705,
    'Vedano al Lambro': 1705,
    'Vedano Olona': 1705,
    'Veddasca': 1705,
    'Vedeseta': 1705,
    'Veduggio Con Colzano': 1705,
    'Velasca': 1705,
    'Veleso': 1705,
    'Velezzo Lomellina': 1705,
    'Vellezzo Bellini': 1705,
    'Vendrogno': 1705,
    'Venegono Inferiore': 1705,
    'Venegono Superiore': 1705,
    'Veniano': 1705,
    'Verano Brianza': 1705,
    'Vercana': 1705,
    'Verceia': 1705,
    'Vercurago': 1705,
    'Verdellino': 1705,
    'Verdello': 1705,
    'Verderio Inferiore': 1705,
    'Verderio Superiore': 1705,
    'Vergano-Villa': 1705,
    'Vergiate': 1705,
    'Vermezzo': 1705,
    'Vernate': 1705,
    'Verolanuova': 1705,
    'Verolavecchia': 1705,
    'Verretto': 1705,
    'Verrua Po': 1705,
    'Vertemate Con Minoprio': 1705,
    'Vertova': 1705,
    'Vervio': 1705,
    'Vescovato': 1705,
    'Vestone': 1705,
    'Vestreno': 1705,
    'Vezza d  Oglio': 1705,
    'Viadana': 1705,
    'Viadanica': 1705,
    'Vicomoscano': 1705,
    'Vidalengo': 1705,
    'Vidardo': 1705,
    'Vidigulfo': 1705,
    'Viganò': 1705,
    'Vigano San Martino': 1705,
    'Vigevano': 1705,
    'Viggiù': 1705,
    'Vighignolo': 1705,
    'Vighizzolo': 1705,
    'Vigliano-Bettolino': 1705,
    'Vignate': 1705,
    'Vignola': 1705,
    'Vigolo': 1705,
    'Vigonzone': 1705,
    'Vill  Albese': 1705,
    'Villa': 1705,
    'Villa Biscossi': 1705,
    'Villa Carcina': 1705,
    'Villa Cortese': 1705,
    'Villa d  Adda': 1705,
    'Villa d  Almè': 1705,
    'Villa d  Ogna': 1705,
    'Villa d  Oneta': 1705,
    'Villa di Chiavenna': 1705,
    'Villa di Serio': 1705,
    'Villa Fornace': 1705,
    'Villa Guardia': 1705,
    'Villa Pedergnano': 1705,
    'Villa Poma': 1705,
    'Villa Raverio': 1705,
    'Villachiara': 1705,
    'Villaggio del Sole': 1705,
    'Villaggio Residenziale': 1705,
    'Villanova': 1705,
    'Villanova d  Ardenghi': 1705,
    'Villanova del Sillaro': 1705,
    'Villanterio': 1705,
    'Villanuova': 1705,
    'Villanuova sul Clisi': 1705,
    'Villapinta': 1705,
    'Villasanta': 1705,
    'Villassio': 1705,
    'Villimpenta': 1705,
    'Villongo': 1705,
    'Vilminore': 1705,
    'Vilminore di Scalve': 1705,
    'Vimercate': 1705,
    'Vimodrone': 1705,
    'Vione': 1705,
    'Visano': 1705,
    'Vistarino': 1705,
    'Vittuone': 1705,
    'Vizzola Ticino': 1705,
    'Vizzolo Predabissi': 1705,
    'Vobarno': 1705,
    'Voghera': 1705,
    'Volongo': 1705,
    'Volpara': 1705,
    'Volta Mantovana': 1705,
    'Voltido': 1705,
    'Zandobbio': 1705,
    'Zanica': 1705,
    'Zavattarello': 1705,
    'Zeccone': 1705,
    'Zelbio': 1705,
    'Zelo Buon Persico': 1705,
    'Zelo Surrigone': 1705,
    'Zeme': 1705,
    'Zenevredo': 1705,
    'Zerbo': 1705,
    'Zerbolò': 1705,
    'Zibido San Giacomo': 1705,
    'Zinasco Vecchio': 1705,
    'Zoccorino-Vergo': 1705,
    'Zogno': 1705,
    'Zone': 1705,
    'Zorlesco': 1705,
    'Acqualagna': 1670,
    'Acquasanta Terme': 1670,
    'Acquaviva Picena': 1670,
    'Agugliano': 1670,
    'Altidona': 1670,
    'Amandola': 1670,
    'Ancona': 1670,
    'Apecchio': 1670,
    'Apiro': 1670,
    'Appignano': 1670,
    'Appignano del Tronto': 1670,
    'Arcevia': 1670,
    'Arquata del Tronto': 1670,
    'Ascoli Piceno': 1670,
    'Auditore': 1670,
    'Balzo': 1670,
    'Barbara': 1670,
    'Barchi': 1670,
    'Belforte': 1670,
    'Belforte del Chienti': 1670,
    'Bellocchi': 1670,
    'Belmonte Piceno': 1670,
    'Belvedere Ostrense': 1670,
    'Bolognola': 1670,
    'Borghetto': 1670,
    'Borgo Massano': 1670,
    'Borgo Pace': 1670,
    'Borgo Santa Maria': 1670,
    'Borgo Stazione': 1670,
    'Bottega': 1670,
    'Cagli': 1670,
    'Calcinelli': 1670,
    'Caldarola': 1670,
    'Camerano': 1670,
    'Camerata Picena': 1670,
    'Camerino': 1670,
    'Campiglione': 1670,
    'Campocavallo': 1670,
    'Campofilone': 1670,
    'Camporotondo di Fiastrone': 1670,
    'Canavaccio': 1670,
    'Cantiano': 1670,
    'Capodarco': 1670,
    'Cappone': 1670,
    'Carassai': 1670,
    'Carpegna': 1670,
    'Cartoceto': 1670,
    'Cascinare': 1670,
    'Casette d  Ete': 1670,
    'Casette Verdini': 1670,
    'Casine': 1670,
    'Casinina': 1670,
    'Castel di Lama': 1670,
    'Castelbellino': 1670,
    'Castelfidardo': 1670,
    'Castelleone di Suasa': 1670,
    'Castelplanio': 1670,
    'Castelraimondo': 1670,
    'Castelsantangelo sul Nera': 1670,
    'Castignano': 1670,
    'Castorano': 1670,
    'Centinarola': 1670,
    'Centobuchi': 1670,
    'Cerreto d  Esi': 1670,
    'Cessapalombo': 1670,
    'Chiaravalle': 1670,
    'Cingoli': 1670,
    'Civitanova Alta': 1670,
    'Civitanova Marche': 1670,
    'Colbordolo': 1670,
    'Colli del Tronto': 1670,
    'Colmurano': 1670,
    'Comunanza': 1670,
    'Corinaldo': 1670,
    'Corridonia': 1670,
    'Cossignano': 1670,
    'Cuccurano': 1670,
    'Cupra Marittima': 1670,
    'Cupramontana': 1670,
    'Esanatoglia': 1670,
    'Fabriano': 1670,
    'Falconara Marittima': 1670,
    'Falerone': 1670,
    'Fanano': 1670,
    'Fano': 1670,
    'Fenile': 1670,
    'Fermignano': 1670,
    'Fermo': 1670,
    'Filottrano': 1670,
    'Folignano': 1670,
    'Force': 1670,
    'Fossombrone': 1670,
    'Francavilla d  Ete': 1670,
    'Fratte Rosa': 1670,
    'Frontino': 1670,
    'Frontone': 1670,
    'Gabicce Mare': 1670,
    'Gagliole': 1670,
    'Gallo': 1670,
    'Genga': 1670,
    'Gradara': 1670,
    'Grottammare': 1670,
    'Grottazzolina': 1670,
    'Gualdo': 1670,
    'Isola del Piano': 1670,
    'Jesi': 1670,
    'Lapedona': 1670,
    'Le Grazie di Ancona': 1670,
    'Lido di Fermo': 1670,
    'Loreto': 1670,
    'Loreto Stazione': 1670,
    'Loro Piceno': 1670,
    'Lucrezia': 1670,
    'Lunano': 1670,
    'Macerata': 1670,
    'Macerata Feltria': 1670,
    'Macine-Borgo Loreto': 1670,
    'Magliano di Tenna': 1670,
    'Maiolati Spontini': 1670,
    'Maltignano': 1670,
    'Marcelli': 1670,
    'Marina di Altidona': 1670,
    'Marina di Montemarciano': 1670,
    'Marotta': 1670,
    'Marsia': 1670,
    'Massa': 1670,
    'Massa Fermana': 1670,
    'Massignano': 1670,
    'Matelica': 1670,
    'Mercatale': 1670,
    'Mercatello sul Metauro': 1670,
    'Mercatino Conca': 1670,
    'Mercato Vecchio': 1670,
    'Mergo': 1670,
    'Mogliano': 1670,
    'Moie': 1670,
    'Mombaroccio': 1670,
    'Mondavio': 1670,
    'Mondolfo': 1670,
    'Monsampietro Morico': 1670,
    'Monsampolo del Tronto': 1670,
    'Monsano': 1670,
    'Montalto delle Marche': 1670,
    'Montappone': 1670,
    'Monte Cerignone': 1670,
    'Monte Giberto': 1670,
    'Monte Grimano Terme': 1670,
    'Monte Porzio': 1670,
    'Monte Rinaldo': 1670,
    'Monte Roberto': 1670,
    'Monte San Giusto': 1670,
    'Monte San Martino': 1670,
    'Monte San Pietrangeli': 1670,
    'Monte San Vito': 1670,
    'Monte Urano': 1670,
    'Monte Vidon Combatte': 1670,
    'Monte Vidon Corrado': 1670,
    'Montecalvo in Foglia': 1670,
    'Montecarotto': 1670,
    'Montecassiano': 1670,
    'Monteciccardo': 1670,
    'Montecosaro': 1670,
    'Montedinove': 1670,
    'Montefalcone Appennino': 1670,
    'Montefano': 1670,
    'Montefelcino': 1670,
    'Montefiore dell  Aso': 1670,
    'Montefortino': 1670,
    'Montegiorgio': 1670,
    'Montegranaro': 1670,
    'Montelabbate': 1670,
    'Monteleone di Fermo': 1670,
    'Montelparo': 1670,
    'Montelupone': 1670,
    'Montemaggiore al Metauro': 1670,
    'Montemarciano': 1670,
    'Montemonaco': 1670,
    'Monteprandone': 1670,
    'Monterado': 1670,
    'Monterubbiano': 1670,
    'Montignano-Marzocca': 1670,
    'Montottone': 1670,
    'Moresco': 1670,
    'Morro d  Alba': 1670,
    'Morrovalle': 1670,
    'Muccia': 1670,
    'Numana': 1670,
    'Offagna': 1670,
    'Offida': 1670,
    'Orciano di Pesaro': 1670,
    'Ortezzano': 1670,
    'Osimo': 1670,
    'Osteria': 1670,
    'Ostra': 1670,
    'Ostra Vetere': 1670,
    'Padiglione': 1670,
    'Pagliare': 1670,
    'Palmiano': 1670,
    'Passo di Treia': 1670,
    'Passo Ripe': 1670,
    'Pedaso': 1670,
    'Peglio': 1670,
    'Penna San Giovanni': 1670,
    'Pergola': 1670,
    'Pesaro': 1670,
    'Petriano': 1670,
    'Petriolo': 1670,
    'Petritoli': 1670,
    'Piagge': 1670,
    'Piandimeleto': 1670,
    'Piane': 1670,
    'Piane di Montegiorgio': 1670,
    'Piane di Morro': 1670,
    'Pianello': 1670,
    'Pianello Vallesina': 1670,
    'Piattoni-Villa Sant  Antonio': 1670,
    'Pie   del Colle': 1670,
    'Pie   del Sasso': 1670,
    'Piediripa': 1670,
    'Pietra la Croce': 1670,
    'Pieve Torina': 1670,
    'Pievebovigliana': 1670,
    'Pinocchio di Ancona': 1670,
    'Piobbico': 1670,
    'Pioraco': 1670,
    'Poggio San Marcello': 1670,
    'Poggio San Vicino': 1670,
    'Pollenza': 1670,
    'Polverigi': 1670,
    'Ponzano di Fermo': 1670,
    'Porto Potenza Picena': 1670,
    'Porto Recanati': 1670,
    'Porto San Giorgio': 1670,
    'Porto Sant  Elpidio': 1670,
    'Posatora': 1670,
    'Potenza Picena': 1670,
    'Province of Fermo': 1670,
    'Provincia di Ancona': 1670,
    'Provincia di Ascoli Piceno': 1670,
    'Provincia di Macerata': 1670,
    'Provincia di Pesaro e Urbino': 1670,
    'Rapagnano': 1670,
    'Recanati': 1670,
    'Rio Salso-Case Bernardi': 1670,
    'Ripatransone': 1670,
    'Ripe': 1670,
    'Ripe San Ginesio': 1670,
    'Rosciano': 1670,
    'Rosora': 1670,
    'Rotella': 1670,
    'Saltara': 1670,
    'San Benedetto del Tronto': 1670,
    'San Biagio': 1670,
    'San Costanzo': 1670,
    'San Ginesio': 1670,
    'San Giorgio di Pesaro': 1670,
    'San Lorenzo in Campo': 1670,
    'San Marcello': 1670,
    'San Michele': 1670,
    'San Paolo di Jesi': 1670,
    'San Severino Marche': 1670,
    'San Tommaso Tre Archi': 1670,
    'San Vittoria in Matenano': 1670,
    'Sant  Angelo in Lizzola': 1670,
    'Sant  Angelo in Pontano': 1670,
    'Sant  Angelo in Vado': 1670,
    'Sant  Elpidio a Mare': 1670,
    'Sant  Ippolito': 1670,
    'Santa Maria Apparente': 1670,
    'Santa Maria Nuova': 1670,
    'Sarnano': 1670,
    'Sassocorvaro': 1670,
    'Sassofeltrio': 1670,
    'Sassoferrato': 1670,
    'Sefro': 1670,
    'Senigallia': 1670,
    'Serra de   Conti': 1670,
    'Serra San Quirico': 1670,
    'Serra Sant  Abbondio': 1670,
    'Serrapetrona': 1670,
    'Serravalle di Chienti': 1670,
    'Serrungarina': 1670,
    'Servigliano': 1670,
    'Sforzacosta': 1670,
    'Sirolo': 1670,
    'Smerillo': 1670,
    'Spinetoli': 1670,
    'Staffolo': 1670,
    'Stazione': 1670,
    'Stella': 1670,
    'Strada': 1670,
    'Tavernelle': 1670,
    'Tavoleto': 1670,
    'Tavullia': 1670,
    'Tolentino': 1670,
    'Torre San Patrizio': 1670,
    'Trebbio': 1670,
    'Treia': 1670,
    'Trodica': 1670,
    'Troviggiano': 1670,
    'Urbania': 1670,
    'Urbino': 1670,
    'Urbisaglia': 1670,
    'Valle e Castello': 1670,
    'Venarotta': 1670,
    'Villa Ceccolini': 1670,
    'Villa Musone': 1670,
    'Villa Pigna': 1670,
    'Villa Potenza': 1670,
    'Villa San Filippo': 1670,
    'Villa San Giuseppe': 1670,
    'Villagrande': 1670,
    'Villanova': 1670,
    'Visso': 1670,
    'Zona Industriale': 1670,
    'Acquaviva Collecroce': 1695,
    'Acquaviva d  Isernia': 1695,
    'Agnone': 1695,
    'Bagnoli del Trigno': 1695,
    'Baranello': 1695,
    'Belmonte del Sannio': 1695,
    'Bojano': 1695,
    'Bonefro': 1695,
    'Busso': 1695,
    'Campobasso': 1695,
    'Campochiaro': 1695,
    'Campodipietra': 1695,
    'Campolieto': 1695,
    'Campomarino': 1695,
    'Cantalupo nel Sannio': 1695,
    'Capracotta': 1695,
    'Carovilli': 1695,
    'Carpinone': 1695,
    'Casacalenda': 1695,
    'Casalciprano': 1695,
    'Castel del Giudice': 1695,
    'Castel San Vincenzo': 1695,
    'Castelbottaccio': 1695,
    'Castellino del Biferno': 1695,
    'Castelmauro': 1695,
    'Castelpetroso': 1695,
    'Castelpizzuto': 1695,
    'Castelverrino': 1695,
    'Castropignano': 1695,
    'Cercemaggiore': 1695,
    'Cercepiccola': 1695,
    'Cerro al Volturno': 1695,
    'Chiauci': 1695,
    'Civitacampomarano': 1695,
    'Civitanova del Sannio': 1695,
    'Colle d  Anchise': 1695,
    'Colletorto': 1695,
    'Colli a Volturno': 1695,
    'Conca Casale': 1695,
    'Duronia': 1695,
    'Ferrazzano': 1695,
    'Filignano': 1695,
    'Forlì del Sannio': 1695,
    'Fornelli': 1695,
    'Fossalto': 1695,
    'Frosolone': 1695,
    'Gambatesa': 1695,
    'Gildone': 1695,
    'Guardialfiera': 1695,
    'Guardiaregia': 1695,
    'Guglionesi': 1695,
    'Isernia': 1695,
    'Jelsi': 1695,
    'Larino': 1695,
    'Limosano': 1695,
    'Longano': 1695,
    'Lucito': 1695,
    'Lupara': 1695,
    'Macchia d  Isernia': 1695,
    'Macchia Valfortore': 1695,
    'Macchiagodena': 1695,
    'Mafalda': 1695,
    'Matrice': 1695,
    'Mimosa-Poggio Verde-Nuova Comunità': 1695,
    'Mirabello Sannitico': 1695,
    'Miranda': 1695,
    'Molise': 1695,
    'Monacilioni': 1695,
    'Montagano': 1695,
    'Montaquila': 1695,
    'Montecilfone': 1695,
    'Montefalcone nel Sannio': 1695,
    'Montelongo': 1695,
    'Montemitro': 1695,
    'Montenero di Bisaccia': 1695,
    'Montenero Val Cocchiara': 1695,
    'Monteroduni': 1695,
    'Montorio Nei Frentani': 1695,
    'Morrone del Sannio': 1695,
    'Oratino': 1695,
    'Palata': 1695,
    'Pesche': 1695,
    'Pescolanciano': 1695,
    'Pescopennataro': 1695,
    'Petacciato': 1695,
    'Petrella Tifernina': 1695,
    'Pettoranello del Molise': 1695,
    'Pietrabbondante': 1695,
    'Pietracatella': 1695,
    'Pietracupa': 1695,
    'Pizzone': 1695,
    'Poggio Sannita': 1695,
    'Portocannone': 1695,
    'Pozzilli': 1695,
    'Provincia di Campobasso': 1695,
    'Provincia di Isernia': 1695,
    'Provvidenti': 1695,
    'Riccia': 1695,
    'Rionero Sannitico': 1695,
    'Ripabottoni': 1695,
    'Ripalimosani': 1695,
    'Roccamandolfi': 1695,
    'Roccasicura': 1695,
    'Roccavivara': 1695,
    'Rocchetta a Volturno': 1695,
    'Rocchetta Nuova': 1695,
    'Rotello': 1695,
    'Salcito': 1695,
    'San Biase': 1695,
    'San Felice del Molise': 1695,
    'San Giacomo degli Schiavoni': 1695,
    'San Giovanni in Galdo': 1695,
    'San Giuliano del Sannio': 1695,
    'San Giuliano di Puglia': 1695,
    'San Martino in Pensilis': 1695,
    'San Massimo': 1695,
    'San Pietro Avellana': 1695,
    'San Polomatese': 1695,
    'Sant  Agapito': 1695,
    'Sant  Angelo del Pesco': 1695,
    'Sant  Angelo Limosano': 1695,
    'Sant  Elena Sannita': 1695,
    'Sant  Elia a Pianisi': 1695,
    'Santa Croce di Magliano': 1695,
    'Santa Maria del Molise': 1695,
    'Scapoli': 1695,
    'Sepino': 1695,
    'Sessano del Molise': 1695,
    'Sesto Campano': 1695,
    'Spinete': 1695,
    'Tavenna': 1695,
    'Taverna Ravindola': 1695,
    'Termoli': 1695,
    'Torella del Sannio': 1695,
    'Toro': 1695,
    'Trivento': 1695,
    'Tufara': 1695,
    'Ururi': 1695,
    'Vastogirardi': 1695,
    'Venafro': 1695,
    'Vinchiaturo': 1695,
    'Acceglio': 1702,
    'Acqui Terme': 1702,
    'Agliano': 1702,
    'Agliano Terme': 1702,
    'Agliè': 1702,
    'Agrate': 1702,
    'Agrate Conturbia': 1702,
    'Ailoche': 1702,
    'Airali': 1702,
    'Airasca': 1702,
    'Aisone': 1702,
    'Ala di Stura': 1702,
    'Alagna Valsesia': 1702,
    'Alba': 1702,
    'Albano Vercellese': 1702,
    'Albaretto della Torre': 1702,
    'Albera Ligure': 1702,
    'Albiano d  Ivrea': 1702,
    'Albugnano': 1702,
    'Alessandria': 1702,
    'Alfiano Natta': 1702,
    'Alice Bel Colle': 1702,
    'Alice Castello': 1702,
    'Alice Superiore': 1702,
    'Almese-Rivera': 1702,
    'Alpette': 1702,
    'Alpignano': 1702,
    'Altavilla Monferrato': 1702,
    'Alto': 1702,
    'Alzano Scrivia': 1702,
    'Ameno': 1702,
    'Andezeno': 1702,
    'Andorno Cacciorna': 1702,
    'Andrate': 1702,
    'Angrogna': 1702,
    'Antignano': 1702,
    'Antronapiana': 1702,
    'Anzola d  Ossola': 1702,
    'Aramengo': 1702,
    'Arborio': 1702,
    'Arguello': 1702,
    'Arignano': 1702,
    'Arizzano': 1702,
    'Armeno': 1702,
    'Arola': 1702,
    'Arona': 1702,
    'Arquata Scrivia': 1702,
    'Asigliano Vercellese': 1702,
    'Asti': 1702,
    'Aurano': 1702,
    'Avigliana': 1702,
    'Avolasca': 1702,
    'Azeglio': 1702,
    'Azzano d  Asti': 1702,
    'Baceno': 1702,
    'Bagnasco': 1702,
    'Bagnolo Piemonte': 1702,
    'Bairo': 1702,
    'Balangero': 1702,
    'Baldichieri d  Asti': 1702,
    'Baldissero Canavese': 1702,
    'Baldissero d  Alba': 1702,
    'Baldissero Torinese': 1702,
    'Balme': 1702,
    'Balmuccia': 1702,
    'Balocco': 1702,
    'Balzola': 1702,
    'Banchette': 1702,
    'Bandito': 1702,
    'Bannio': 1702,
    'Baratte': 1702,
    'Barbania': 1702,
    'Barbaresco': 1702,
    'Bardonecchia': 1702,
    'Barengo': 1702,
    'Barge': 1702,
    'Barolo': 1702,
    'Barone Canavese': 1702,
    'Barquedo': 1702,
    'Basaluzzo': 1702,
    'Bassignana': 1702,
    'Bastia Mondovì': 1702,
    'Battifollo': 1702,
    'Baveno': 1702,
    'Bee': 1702,
    'Beinasco': 1702,
    'Beinette': 1702,
    'Belforte Monferrato': 1702,
    'Belgirate': 1702,
    'Bellinzago Novarese': 1702,
    'Belvedere Langhe': 1702,
    'Belveglio': 1702,
    'Bene Vagienna': 1702,
    'Benevello': 1702,
    'Benna': 1702,
    'Bergamasco': 1702,
    'Bergolo': 1702,
    'Bernezzo': 1702,
    'Bersezio': 1702,
    'Berzano di San Pietro': 1702,
    'Berzano di Tortona': 1702,
    'Beura': 1702,
    'Biandrate': 1702,
    'Bianzè': 1702,
    'Bibiana': 1702,
    'Biella': 1702,
    'Bioglio-Portula-Andrè': 1702,
    'Bistagno': 1702,
    'Bobbio Pellice': 1702,
    'Boca': 1702,
    'Boccioleto': 1702,
    'Bogogno': 1702,
    'Bollengo': 1702,
    'Bolzano Novarese': 1702,
    'Bonvicino': 1702,
    'Borgaro Torinese': 1702,
    'Borghetto di Borbera': 1702,
    'Borgiallo': 1702,
    'Borgo': 1702,
    'Borgo d  Ale': 1702,
    'Borgo Melano': 1702,
    'Borgo San Dalmazzo': 1702,
    'Borgo San Martino': 1702,
    'Borgo Ticino': 1702,
    'Borgo Vercelli': 1702,
    'Borgofranco d  Ivrea': 1702,
    'Borgolavezzaro': 1702,
    'Borgomale': 1702,
    'Borgomanero': 1702,
    'Borgomasino': 1702,
    'Borgone Susa': 1702,
    'Borgoratto Alessandrino': 1702,
    'Borgosesia': 1702,
    'Borriana': 1702,
    'Bosco Marengo': 1702,
    'Bosconero': 1702,
    'Bosia': 1702,
    'Bosio': 1702,
    'Bossolasco': 1702,
    'Boves': 1702,
    'Bozzole': 1702,
    'Bra': 1702,
    'Brandizzo': 1702,
    'Breia': 1702,
    'Breo': 1702,
    'Briaglia': 1702,
    'Bricco di Neive': 1702,
    'Bricherasio': 1702,
    'Briga Alta': 1702,
    'Briga Novarese': 1702,
    'Brignano': 1702,
    'Briona': 1702,
    'Brondello': 1702,
    'Brossasco': 1702,
    'Brosso': 1702,
    'Brovello': 1702,
    'Brovello-Carpugnino': 1702,
    'Brozolo': 1702,
    'Bruino': 1702,
    'Bruno': 1702,
    'Brusasco': 1702,
    'Brusnengo': 1702,
    'Bruzolo': 1702,
    'Bubbio': 1702,
    'Buriasco': 1702,
    'Burolo': 1702,
    'Buronzo': 1702,
    'Busano': 1702,
    'Busca': 1702,
    'Bussoleno': 1702,
    'Buttigliera Alta': 1702,
    'Buttigliera d  Asti': 1702,
    'Cabella Ligure': 1702,
    'Cafasse': 1702,
    'Calamandrana': 1702,
    'Calasca-Castiglione': 1702,
    'Callabiana - Chiesa': 1702,
    'Calliano': 1702,
    'Calosso': 1702,
    'Caltignaga': 1702,
    'Caluso': 1702,
    'Camagna Monferrato': 1702,
    'Camandona': 1702,
    'Cambiano': 1702,
    'Cambiasca': 1702,
    'Camburzano': 1702,
    'Camerana': 1702,
    'Camerano': 1702,
    'Camerano Casasco': 1702,
    'Cameri': 1702,
    'Camino': 1702,
    'Camo': 1702,
    'Campertogno': 1702,
    'Campiglia Cervo': 1702,
    'Campiglione': 1702,
    'Campomolino': 1702,
    'Canale': 1702,
    'Candelo': 1702,
    'Candia Canavese': 1702,
    'Candiolo': 1702,
    'Canelli': 1702,
    'Canischio': 1702,
    'Cannero Riviera': 1702,
    'Cannobio': 1702,
    'Canosio': 1702,
    'Cantalupa': 1702,
    'Cantalupo Ligure': 1702,
    'Cantarana': 1702,
    'Cantoira': 1702,
    'Caprauna': 1702,
    'Caprezzo': 1702,
    'Capriata d  Orba': 1702,
    'Caprie': 1702,
    'Capriglio': 1702,
    'Caraglio': 1702,
    'Caramagna Piemonte': 1702,
    'Caravino': 1702,
    'Carbonara Scrivia': 1702,
    'Carcoforo': 1702,
    'Cardè': 1702,
    'Carema': 1702,
    'Carentino': 1702,
    'Caresana': 1702,
    'Caresanablot': 1702,
    'Carezzano Maggiore': 1702,
    'Carignano': 1702,
    'Carisio': 1702,
    'Carmagnola': 1702,
    'Carpeneto': 1702,
    'Carpignano Sesia': 1702,
    'Carrega Ligure': 1702,
    'Carrosio': 1702,
    'Carrù': 1702,
    'Cartignano': 1702,
    'Cartosio': 1702,
    'Casal Cermelli': 1702,
    'Casalbeltrame': 1702,
    'Casalborgone': 1702,
    'Casale Corte Cerro': 1702,
    'Casale Monferrato': 1702,
    'Casaleggio Boiro': 1702,
    'Casaleggio Novara': 1702,
    'Casalgrasso': 1702,
    'Casalino': 1702,
    'Casalnoceto': 1702,
    'Casalvolone': 1702,
    'Casanova Elvo': 1702,
    'Casapinta': 1702,
    'Casasco': 1702,
    'Cascinette d  Ivrea': 1702,
    'Caselette': 1702,
    'Caselle Torinese': 1702,
    'Casorzo': 1702,
    'Cassano Spinola': 1702,
    'Cassinasco': 1702,
    'Cassine': 1702,
    'Cassinelle-Concentrico': 1702,
    'Castagneto Po': 1702,
    'Castagnito': 1702,
    'Castagnole delle Lanze': 1702,
    'Castagnole Monferrato': 1702,
    'Castagnole Piemonte': 1702,
    'Castel Boglione': 1702,
    'Castel Rocchero': 1702,
    'Castelceriolo': 1702,
    'Casteldelfino': 1702,
    'Castell  Alfero': 1702,
    'Castellamonte': 1702,
    'Castellania': 1702,
    'Castellar': 1702,
    'Castellar Guidobono': 1702,
    'Castellazzo Bormida': 1702,
    'Castellazzo Novarese': 1702,
    'Castellero': 1702,
    'Castelletto Cervo': 1702,
    'Castelletto d  Erro': 1702,
    'Castelletto d  Orba': 1702,
    'Castelletto Merli': 1702,
    'Castelletto Molina': 1702,
    'Castelletto Monferrato': 1702,
    'Castelletto Sopra Ticino': 1702,
    'Castelletto Stura': 1702,
    'Castelletto Uzzone': 1702,
    'Castellinaldo': 1702,
    'Castellino Tanaro': 1702,
    'Castello di Annone': 1702,
    'Castelnuovo Belbo': 1702,
    'Castelnuovo Bormida': 1702,
    'Castelnuovo Calcea': 1702,
    'Castelnuovo di Ceva': 1702,
    'Castelnuovo Don Bosco': 1702,
    'Castelnuovo Nigra': 1702,
    'Castelnuovo Scrivia': 1702,
    'Castelspina': 1702,
    'Castiglione Falletto': 1702,
    'Castiglione Tinella': 1702,
    'Castiglione Torinese': 1702,
    'Castino': 1702,
    'Cavaglià': 1702,
    'Cavaglietto': 1702,
    'Cavaglio D  Agogna': 1702,
    'Cavaglio-Spoccia': 1702,
    'Cavagnolo': 1702,
    'Cavallerleone': 1702,
    'Cavallermaggiore': 1702,
    'Cavallirio': 1702,
    'Cavatore': 1702,
    'Cavour': 1702,
    'Cella Monte': 1702,
    'Cellarengo': 1702,
    'Celle Enomondo': 1702,
    'Cellio': 1702,
    'Centallo': 1702,
    'Ceppo Morelli': 1702,
    'Cerano': 1702,
    'Cercenasco': 1702,
    'Ceres': 1702,
    'Ceresane-Curanuova': 1702,
    'Cereseto': 1702,
    'Ceresole Alba': 1702,
    'Ceresole Reale': 1702,
    'Cerreto Castello': 1702,
    'Cerreto d  Asti': 1702,
    'Cerreto Grue': 1702,
    'Cerreto Langhe': 1702,
    'Cerrina': 1702,
    'Cerrione': 1702,
    'Cerro Tanaro': 1702,
    'Cervasca': 1702,
    'Cervatto': 1702,
    'Cervere': 1702,
    'Cesana Torinese': 1702,
    'Cesara': 1702,
    'Cessole': 1702,
    'Ceva': 1702,
    'Cherasco': 1702,
    'Chialamberto': 1702,
    'Chianocco': 1702,
    'Chiaverano': 1702,
    'Chieri': 1702,
    'Chiesa di Macra': 1702,
    'Chiesanuova': 1702,
    'Chiomonte': 1702,
    'Chiusa di Pesio': 1702,
    'Chiusa di San Michele': 1702,
    'Chiusano d  Asti': 1702,
    'Chivasso': 1702,
    'Ciconio': 1702,
    'Cigliano': 1702,
    'Cigliè': 1702,
    'Cinaglio': 1702,
    'Cintano': 1702,
    'Cinzano': 1702,
    'Ciriè': 1702,
    'Cissone': 1702,
    'Cisterna d  Asti': 1702,
    'Civiasco': 1702,
    'Clavesana': 1702,
    'Claviere': 1702,
    'Coassolo': 1702,
    'Coassolo Torinese': 1702,
    'Coazze': 1702,
    'Coazzolo': 1702,
    'Cocconato': 1702,
    'Coggiola': 1702,
    'Colazza': 1702,
    'Collegno': 1702,
    'Colleretto Castelnuovo': 1702,
    'Colleretto Giacosa': 1702,
    'Collobiano': 1702,
    'Comignago': 1702,
    'Condove': 1702,
    'Coniolo Bricco': 1702,
    'Conzano': 1702,
    'Corio': 1702,
    'Corneliano d  Alba': 1702,
    'Corsione': 1702,
    'Cortandone': 1702,
    'Cortanze': 1702,
    'Cortazzone': 1702,
    'Cortemilia': 1702,
    'Cortiglione': 1702,
    'Cossano Belbo': 1702,
    'Cossano Canavese': 1702,
    'Cossato': 1702,
    'Cossogno': 1702,
    'Cossombrato': 1702,
    'Costa Vescovato': 1702,
    'Costanzana': 1702,
    'Costigliole d  Asti': 1702,
    'Costiglione Saluzzo': 1702,
    'Crava': 1702,
    'Cravagliana': 1702,
    'Cravanzana': 1702,
    'Craveggia': 1702,
    'Cremolino': 1702,
    'Crescentino': 1702,
    'Cressa': 1702,
    'Crevacuore': 1702,
    'Crevoladossola': 1702,
    'Crissolo': 1702,
    'Crodo': 1702,
    'Crova': 1702,
    'Cuccaro Monferrato': 1702,
    'Cuceglio': 1702,
    'Cumiana': 1702,
    'Cuneo': 1702,
    'Cunico': 1702,
    'Cuorgnè': 1702,
    'Cureggio': 1702,
    'Curino': 1702,
    'Cursolo': 1702,
    'Demonte': 1702,
    'Denice': 1702,
    'Dernice': 1702,
    'Desana': 1702,
    'Diano d  Alba': 1702,
    'Divignano': 1702,
    'Dogliani': 1702,
    'Domodossola': 1702,
    'Donato': 1702,
    'Dormelletto': 1702,
    'Dorzano': 1702,
    'Dronero': 1702,
    'Drubiaglio-Grangia': 1702,
    'Druento': 1702,
    'Druogno': 1702,
    'Dusino': 1702,
    'Entracque': 1702,
    'Envie': 1702,
    'Exilles': 1702,
    'Falmenta': 1702,
    'Fara Novarese': 1702,
    'Farigliano': 1702,
    'Faule': 1702,
    'Favari-Avatanei': 1702,
    'Favria': 1702,
    'Feisoglio': 1702,
    'Feletto': 1702,
    'Felizzano': 1702,
    'Fenestrelle': 1702,
    'Ferrere': 1702,
    'Ferriera': 1702,
    'Fiano': 1702,
    'Fiorano Canavese': 1702,
    'Fleccia-Chianavasso': 1702,
    'Fobello': 1702,
    'Foglizzo': 1702,
    'Fontanella-Ozino': 1702,
    'Fontaneto D  Agogna': 1702,
    'Fontanetto Po': 1702,
    'Fontanile': 1702,
    'Formazza': 1702,
    'Formigliana': 1702,
    'Fornaci': 1702,
    'Forno Canavese': 1702,
    'Fossano': 1702,
    'Frabosa Soprana': 1702,
    'Frabosa Sottana': 1702,
    'Fraconalto': 1702,
    'Francavilla Bisio': 1702,
    'Frascaro': 1702,
    'Frassinello Monferrato': 1702,
    'Frassineto Po': 1702,
    'Frassinetto': 1702,
    'Frassino': 1702,
    'Frazione Chiesa': 1702,
    'Fresonara': 1702,
    'Frinco': 1702,
    'Front': 1702,
    'Frossasco': 1702,
    'Frugarolo': 1702,
    'Fubine': 1702,
    'Gabbio-Cereda-Ramate': 1702,
    'Gabiano': 1702,
    'Gaglianico': 1702,
    'Gaiola': 1702,
    'Galliate': 1702,
    'Gamalero': 1702,
    'Gambasca': 1702,
    'Garadassi': 1702,
    'Garbagna': 1702,
    'Garbagna Novarese': 1702,
    'Garessio': 1702,
    'Gargallo': 1702,
    'Garino': 1702,
    'Garzigliana': 1702,
    'Gassino Torinese': 1702,
    'Gattico': 1702,
    'Gattinara': 1702,
    'Gavazzana': 1702,
    'Gavi': 1702,
    'Genola': 1702,
    'Gerbido': 1702,
    'Gerbole': 1702,
    'Gerbole-Zucche': 1702,
    'Germagnano': 1702,
    'Germagno': 1702,
    'Ghemme': 1702,
    'Ghiare-Madonna': 1702,
    'Ghiffa': 1702,
    'Ghislarengo': 1702,
    'Giaglione': 1702,
    'Giarole': 1702,
    'Giaveno': 1702,
    'Gifflenga': 1702,
    'Gignese': 1702,
    'Givoletto': 1702,
    'Gonte': 1702,
    'Gorzegno': 1702,
    'Gottasecca': 1702,
    'Govone': 1702,
    'Gozzano': 1702,
    'Graglia': 1702,
    'Grana': 1702,
    'Granozzo con Monticello': 1702,
    'Gravellona Toce': 1702,
    'Gravere': 1702,
    'Grazzano Badoglio': 1702,
    'Greggio': 1702,
    'Gremiasco': 1702,
    'Grignasco': 1702,
    'Grinzane Cavour': 1702,
    'Grognardo': 1702,
    'Grondona': 1702,
    'Groscavallo': 1702,
    'Grosso': 1702,
    'Grugliasco': 1702,
    'Guardabosone': 1702,
    'Guarene': 1702,
    'Guazzora': 1702,
    'Gurro': 1702,
    'Igliano': 1702,
    'Incisa Scapaccino': 1702,
    'Ingria': 1702,
    'Intragna': 1702,
    'Inverso Pinasca': 1702,
    'Invorio': 1702,
    'Isasca': 1702,
    'Isola d  Asti': 1702,
    'Isola Sant  Antonio': 1702,
    'Isolabella': 1702,
    'Issiglio': 1702,
    'Ivrea': 1702,
    'La Cassa': 1702,
    'La Loggia': 1702,
    'La Morra': 1702,
    'La Villa': 1702,
    'Lagnasco': 1702,
    'Lamporo': 1702,
    'Landiona': 1702,
    'Lanvario': 1702,
    'Lanzo Torinese': 1702,
    'Lauriano': 1702,
    'Leini': 1702,
    'Lemie': 1702,
    'Lenta': 1702,
    'Lequio Berria': 1702,
    'Lequio Tanaro': 1702,
    'Lerma': 1702,
    'Lesa': 1702,
    'Lesegno': 1702,
    'Lesna': 1702,
    'Lessolo': 1702,
    'Lessona': 1702,
    'Levice': 1702,
    'Levone': 1702,
    'Lignana': 1702,
    'Limone Piemonte': 1702,
    'Lisio': 1702,
    'Litta Parodi-Cascinagrossa': 1702,
    'Livera': 1702,
    'Livorno Ferraris': 1702,
    'Loazzolo': 1702,
    'Locana': 1702,
    'Lombardore': 1702,
    'Lombriasco': 1702,
    'Loranzè': 1702,
    'Loreglia': 1702,
    'Lozzolo': 1702,
    'Lu': 1702,
    'Lugnacco': 1702,
    'Lumellogno': 1702,
    'Luserna': 1702,
    'Lusernetta': 1702,
    'Lusigliè': 1702,
    'Macello': 1702,
    'Macra': 1702,
    'Macugnaga': 1702,
    'Madonna del Sasso': 1702,
    'Maggiora': 1702,
    'Magliano Alfieri': 1702,
    'Magliano Alpi': 1702,
    'Maglione': 1702,
    'Maglione-Crosa': 1702,
    'Magnano': 1702,
    'Malesco': 1702,
    'Malvicino': 1702,
    'Mandello Vitta': 1702,
    'Mandrogne': 1702,
    'Mango': 1702,
    'Manta': 1702,
    'Mappano': 1702,
    'Marano Ticino': 1702,
    'Maranzana': 1702,
    'Marene': 1702,
    'Marentino': 1702,
    'Maretto': 1702,
    'Margarita': 1702,
    'Marmora': 1702,
    'Marocchi': 1702,
    'Marsaglia': 1702,
    'Martiniana Po': 1702,
    'Masera': 1702,
    'Masio': 1702,
    'Massazza': 1702,
    'Massello': 1702,
    'Masserano': 1702,
    'Massino Visconti': 1702,
    'Massiola': 1702,
    'Mathi': 1702,
    'Mattie': 1702,
    'Mazze': 1702,
    'Meana di Susa': 1702,
    'Meina': 1702,
    'Melazzo': 1702,
    'Melle': 1702,
    'Merana': 1702,
    'Mercenasco': 1702,
    'Mergozzo': 1702,
    'Meugliano': 1702,
    'Mezzana Mortigliengo': 1702,
    'Mezzenile': 1702,
    'Mezzomerico': 1702,
    'Miagliano': 1702,
    'Miasino': 1702,
    'Miazzina': 1702,
    'Milanere': 1702,
    'Mirabello Monferrato': 1702,
    'Moasca': 1702,
    'Moiola': 1702,
    'Molare': 1702,
    'Molino dei Torti': 1702,
    'Mollia': 1702,
    'Mombaldone': 1702,
    'Mombarcaro': 1702,
    'Mombaruzzo': 1702,
    'Mombasiglio': 1702,
    'Mombello di Torino': 1702,
    'Mombello Monferrato': 1702,
    'Mombercelli': 1702,
    'Momo': 1702,
    'Mompantero': 1702,
    'Momperone': 1702,
    'Monale': 1702,
    'Monastero Bormida': 1702,
    'Monastero di Lanzo': 1702,
    'Monastero di Vasco': 1702,
    'Monasterolo': 1702,
    'Monasterolo Casotto': 1702,
    'Monasterolo di Savigliano': 1702,
    'Moncalieri': 1702,
    'Moncalvo': 1702,
    'Moncenisio': 1702,
    'Moncestino': 1702,
    'Monchiero Borgonuovo': 1702,
    'Moncrivello': 1702,
    'Moncucco Torinese': 1702,
    'Mondovì': 1702,
    'Monesiglio': 1702,
    'Monforte d  Alba': 1702,
    'Mongardino': 1702,
    'Mongiardino Ligure': 1702,
    'Mongrando': 1702,
    'Monleale': 1702,
    'Montà': 1702,
    'Montabone': 1702,
    'Montacuto': 1702,
    'Montafia': 1702,
    'Montaldeo': 1702,
    'Montaldo Bormida': 1702,
    'Montaldo di Mondovì': 1702,
    'Montaldo Roero': 1702,
    'Montaldo Scarampi': 1702,
    'Montaldo Torinese': 1702,
    'Montalenghe': 1702,
    'Montalto Dora': 1702,
    'Montanaro': 1702,
    'Montanera': 1702,
    'Montariolo': 1702,
    'Montecastello': 1702,
    'Montechiaro d  Acqui': 1702,
    'Montechiaro d  Asti': 1702,
    'Montecrestese': 1702,
    'Montegioco': 1702,
    'Montegrosso': 1702,
    'Montelera': 1702,
    'Montelupo Albese': 1702,
    'Montemagno': 1702,
    'Montemale di Cuneo': 1702,
    'Montemarzino': 1702,
    'Monterosso Grana': 1702,
    'Montescheno': 1702,
    'Monteu da Po': 1702,
    'Monteu Roero': 1702,
    'Montezemolo': 1702,
    'Monticello d  Alba': 1702,
    'Montiglio': 1702,
    'Morano sul Po': 1702,
    'Moransengo': 1702,
    'Morbello': 1702,
    'Moretta': 1702,
    'Moriondo Torinese': 1702,
    'Mornese': 1702,
    'Morozzo': 1702,
    'Morsasco': 1702,
    'Mosso': 1702,
    'Mosso Santa Maria': 1702,
    'Motta Dè Conti': 1702,
    'Mottalciata': 1702,
    'Murazzano': 1702,
    'Murello': 1702,
    'Murisengo': 1702,
    'Mussotto': 1702,
    'Muzzano': 1702,
    'Narzole': 1702,
    'Nebbiuno': 1702,
    'Neive-Borgonovo': 1702,
    'Netro': 1702,
    'Neviglie': 1702,
    'Nibbiola': 1702,
    'Nichelino': 1702,
    'Niella Belbo': 1702,
    'Niella Tanaro': 1702,
    'Nizza Monferrato': 1702,
    'Noasca': 1702,
    'Nole': 1702,
    'Nomaglio': 1702,
    'None': 1702,
    'Nonio': 1702,
    'Novalesa': 1702,
    'Novara': 1702,
    'Novello': 1702,
    'Novi Ligure': 1702,
    'Nucetto': 1702,
    'Occhieppo Inferiore': 1702,
    'Occhieppo Superiore': 1702,
    'Occimiano': 1702,
    'Odalengo Grande': 1702,
    'Odalengo Piccolo': 1702,
    'Oggebbio': 1702,
    'Oglianico': 1702,
    'Olcenengo': 1702,
    'Oldenico': 1702,
    'Oleggio': 1702,
    'Oleggio Castello': 1702,
    'Olivola': 1702,
    'Olmo Gentile': 1702,
    'Omegna': 1702,
    'Oncino': 1702,
    'Orbassano': 1702,
    'Orio Canavese': 1702,
    'Ormea': 1702,
    'Ornavasso': 1702,
    'Orsara Bormida': 1702,
    'Orta San Giulio': 1702,
    'Osasco': 1702,
    'Osasio': 1702,
    'Ostana': 1702,
    'Ottiglio': 1702,
    'Oulx': 1702,
    'Ovada': 1702,
    'Oviglio': 1702,
    'Ozegna': 1702,
    'Ozzano Monferrato': 1702,
    'Paderna': 1702,
    'Paesana': 1702,
    'Pagno': 1702,
    'Palazzo Canavese': 1702,
    'Palazzolo Vercellese': 1702,
    'Pallanza-Intra-Suna': 1702,
    'Pallanzeno': 1702,
    'Pamparato': 1702,
    'Pancalieri': 1702,
    'Parella': 1702,
    'Pareto': 1702,
    'Parodi Ligure': 1702,
    'Paroldo': 1702,
    'Paruzzaro': 1702,
    'Passerano Marmorito': 1702,
    'Pasta': 1702,
    'Pasturana': 1702,
    'Pavarolo': 1702,
    'Pavone Canavese': 1702,
    'Pecco': 1702,
    'Pecetto': 1702,
    'Pecetto di Valenza': 1702,
    'Pella': 1702,
    'Penango': 1702,
    'Perletto': 1702,
    'Perlo': 1702,
    'Pernate': 1702,
    'Perosa Argentina': 1702,
    'Perosa Canavese': 1702,
    'Perrero': 1702,
    'Pertengo': 1702,
    'Pertusio': 1702,
    'Pessinetto': 1702,
    'Pessione': 1702,
    'Pettenasco': 1702,
    'Pettinengo': 1702,
    'Peveragno': 1702,
    'Pezzana': 1702,
    'Pezzolo Valle Uzzone': 1702,
    'Piana San Raffaele': 1702,
    'Pianezza': 1702,
    'Pianfei': 1702,
    'Piano-Molini d  Isola': 1702,
    'Piasco': 1702,
    'Piatto': 1702,
    'Piazza': 1702,
    'Piea': 1702,
    'Piedicavallo': 1702,
    'Piedimulera': 1702,
    'Pietra Marazzi': 1702,
    'Pietraporzio': 1702,
    'Pieve Vergonte': 1702,
    'Pila': 1702,
    'Pinasca-Dubbione': 1702,
    'Pinerolo': 1702,
    'Pino d  Asti': 1702,
    'Pino Torinese': 1702,
    'Piobesi d  Alba': 1702,
    'Piobesi Torinese': 1702,
    'Piode': 1702,
    'Piossasco': 1702,
    'Piovà Massaia': 1702,
    'Piovera': 1702,
    'Piozzo': 1702,
    'Pisano': 1702,
    'Piscina': 1702,
    'Piverone': 1702,
    'Pleyne': 1702,
    'Pocapaglia': 1702,
    'Pogno': 1702,
    'Poirino': 1702,
    'Pollone': 1702,
    'Polonghera': 1702,
    'Pomaretto': 1702,
    'Pomaro Monferrato': 1702,
    'Pombia': 1702,
    'Ponderano': 1702,
    'Pont-Canavese': 1702,
    'Pontechianale': 1702,
    'Pontecurone': 1702,
    'Pontestura': 1702,
    'Ponti': 1702,
    'Ponzano Monferrato': 1702,
    'Ponzone': 1702,
    'Portacomaro': 1702,
    'Porte': 1702,
    'Portula': 1702,
    'Postua': 1702,
    'Pozzol Groppo': 1702,
    'Pozzolo Formigaro': 1702,
    'Pradleves': 1702,
    'Pragelato-Ruà': 1702,
    'Prali': 1702,
    'Pralormo': 1702,
    'Pralungo': 1702,
    'Pramollo': 1702,
    'Prarolo': 1702,
    'Prarostino': 1702,
    'Prasco': 1702,
    'Prascorsano': 1702,
    'Pratiglione': 1702,
    'Prato Sesia': 1702,
    'Pray': 1702,
    'Prazzo Superiore': 1702,
    'Predosa': 1702,
    'Premeno': 1702,
    'Premia': 1702,
    'Premosello-Chiovenda': 1702,
    'Priero': 1702,
    'Priocca': 1702,
    'Priola': 1702,
    'Provincia di Alessandria': 1702,
    'Provincia di Asti': 1702,
    'Provincia di Biella': 1702,
    'Provincia di Cuneo': 1702,
    'Provincia di Novara': 1702,
    'Provincia di Vercelli': 1702,
    'Provincia Verbano-Cusio-Ossola': 1702,
    'Prunetto': 1702,
    'Quagliuzzo': 1702,
    'Quaranti': 1702,
    'Quaregna': 1702,
    'Quargnento': 1702,
    'Quarna Sopra': 1702,
    'Quarna Sotto': 1702,
    'Quarona': 1702,
    'Quassolo': 1702,
    'Quattordio': 1702,
    'Quincinetto': 1702,
    'Quinto Vercellese': 1702,
    'Racconigi': 1702,
    'Rassa': 1702,
    'Re': 1702,
    'Reano': 1702,
    'Recetto': 1702,
    'Refrancore': 1702,
    'Revello': 1702,
    'Revigliasco': 1702,
    'Revigliasco d  Asti': 1702,
    'Ribordone': 1702,
    'Ricaldone': 1702,
    'Ricca': 1702,
    'Rifreddo': 1702,
    'Rima': 1702,
    'Rimasco': 1702,
    'Rimella': 1702,
    'Rittana': 1702,
    'Riva': 1702,
    'Riva Presso Chieri': 1702,
    'Riva Valdobbia': 1702,
    'Rivalba': 1702,
    'Rivalta Bormida': 1702,
    'Rivalta di Torino': 1702,
    'Rivara': 1702,
    'Rivarolo Canavese': 1702,
    'Rivarone': 1702,
    'Rivarossa': 1702,
    'Rive': 1702,
    'Rivoli': 1702,
    'Roapiana': 1702,
    'Roaschia': 1702,
    'Roascio': 1702,
    'Roasio': 1702,
    'Roata Rossi': 1702,
    'Roatto': 1702,
    'Robassomero': 1702,
    'Robella': 1702,
    'Robilante': 1702,
    'Roburent': 1702,
    'Rocca Canavese': 1702,
    'Rocca Cigliè': 1702,
    'Rocca d  Arazzo': 1702,
    'Rocca De   Baldi': 1702,
    'Rocca Grimalda': 1702,
    'Roccabruna': 1702,
    'Roccaforte Ligure': 1702,
    'Roccaforte Mondovì': 1702,
    'Roccasparvera': 1702,
    'Roccaverano': 1702,
    'Roccavione': 1702,
    'Rocchetta Belbo': 1702,
    'Rocchetta Ligure': 1702,
    'Rocchetta Palafea': 1702,
    'Rocchetta Tanaro': 1702,
    'Roddi': 1702,
    'Roddino': 1702,
    'Rodello': 1702,
    'Roletto': 1702,
    'Romagnano Sesia': 1702,
    'Romano Canavese': 1702,
    'Romentino': 1702,
    'Ronco Biellese': 1702,
    'Ronco Canavese': 1702,
    'Rondissone': 1702,
    'Ronsecco': 1702,
    'Roppolo': 1702,
    'Rorà': 1702,
    'Roreto': 1702,
    'Rosazza': 1702,
    'Rosignano Monferrato': 1702,
    'Rossa': 1702,
    'Rossana': 1702,
    'Rosta': 1702,
    'Roure': 1702,
    'Rovasenda': 1702,
    'Rubiana': 1702,
    'Rueglio': 1702,
    'Ruffia': 1702,
    'Sabbia': 1702,
    'Sagliano Micca': 1702,
    'Sala Biellese': 1702,
    'Sala Monferrato': 1702,
    'Salasco': 1702,
    'Salassa': 1702,
    'Salbertrand': 1702,
    'Sale': 1702,
    'Sale delle Langhe': 1702,
    'Sale San Giovanni': 1702,
    'Salerano Canavese': 1702,
    'Sali Vercellese': 1702,
    'Saliceto': 1702,
    'Salmour': 1702,
    'Saluggia': 1702,
    'Salussola': 1702,
    'Saluzzo': 1702,
    'Salza di Pinerolo': 1702,
    'Sambuco': 1702,
    'Samone': 1702,
    'Sampeyre': 1702,
    'San Benedetto Belbo': 1702,
    'San Benigno Canavese': 1702,
    'San Bernardino Verbano': 1702,
    'San Carlo Canavese': 1702,
    'San Colombano Belmonte': 1702,
    'San Cristoforo': 1702,
    'San Damiano d  Asti': 1702,
    'San Damiano Macra': 1702,
    'San Defendente': 1702,
    'San Didero': 1702,
    'San Francesco al Campo': 1702,
    'San Germano Chisone': 1702,
    'San Germano Vercellese': 1702,
    'San Giacomo Vercellese': 1702,
    'San Gillio': 1702,
    'San Giorgio': 1702,
    'San Giorgio Canavese': 1702,
    'San Giorgio Monferrato': 1702,
    'San Giorgio Scarampi': 1702,
    'San Giorio': 1702,
    'San Giuliano Vecchio': 1702,
    'San Giuseppe': 1702,
    'San Giusto Canavese': 1702,
    'San Lorenzo': 1702,
    'San Martino Alfieri': 1702,
    'San Martino Canavese': 1702,
    'San Marzano Oliveto': 1702,
    'San Maurizio': 1702,
    'San Maurizio D  Opaglio': 1702,
    'San Mauro Torinese': 1702,
    'San Michele Mondovì': 1702,
    'San Nazzaro Sesia': 1702,
    'San Paolo Solbrito': 1702,
    'San Pietro Mosezzo': 1702,
    'San Pietro Val Lemina': 1702,
    'San Ponso': 1702,
    'San Raffaele Cimena': 1702,
    'San Rocco': 1702,
    'San Salvatore Monferrato': 1702,
    'San Sebastiano Curone': 1702,
    'San Sebastiano da Po': 1702,
    'San Secondo di Pinerolo': 1702,
    'Sandigliano': 1702,
    'Sanfrè': 1702,
    'Sanfront': 1702,
    'Sangano': 1702,
    'Sant  Agata Fossili': 1702,
    'Sant  Albano Stura': 1702,
    'Sant  Ambrogio di Torino': 1702,
    'Sant  Antonino di Susa': 1702,
    'Sant  Antonio': 1702,
    'Santa Maria Maggiore': 1702,
    'Santa Vittoria d  Alba': 1702,
    'Santena': 1702,
    'Santhià': 1702,
    'Santino': 1702,
    'Santo Stefano Belbo': 1702,
    'Santo Stefano Roero': 1702,
    'Sardigliano': 1702,
    'Sarezzano': 1702,
    'Sauze d  Oulx': 1702,
    'Sauze di Cesana': 1702,
    'Savigliano': 1702,
    'Savonera': 1702,
    'Scagnello': 1702,
    'Scalenghe': 1702,
    'Scarmagno': 1702,
    'Scarnafigi': 1702,
    'Sciolze': 1702,
    'Scopa': 1702,
    'Scopello': 1702,
    'Scurzolengo': 1702,
    'Selve Marcone': 1702,
    'Seppiana': 1702,
    'Serole': 1702,
    'Serralunga d  Alba': 1702,
    'Serralunga di Crea': 1702,
    'Serravalle Langhe': 1702,
    'Serravalle Scrivia': 1702,
    'Serravalle Sesia': 1702,
    'Serre': 1702,
    'Sessame': 1702,
    'Sestriere': 1702,
    'Settime': 1702,
    'Settimo Rottaro': 1702,
    'Settimo Torinese': 1702,
    'Settimo Vittone': 1702,
    'Sezzadio': 1702,
    'Sillavengo': 1702,
    'Silvano d  Orba': 1702,
    'Sinio': 1702,
    'Sizzano': 1702,
    'Soglio': 1702,
    'Solero': 1702,
    'Solonghello': 1702,
    'Somano': 1702,
    'Sommariva del Bosco': 1702,
    'Sommariva Perno': 1702,
    'Soprana': 1702,
    'Sordevolo': 1702,
    'Soriso': 1702,
    'Sostegno': 1702,
    'Sozzago': 1702,
    'Sparone': 1702,
    'Spigno Monferrato': 1702,
    'Spineto Scrivia': 1702,
    'Spinetta Marengo': 1702,
    'Staffa': 1702,
    'Stazzano': 1702,
    'Strambinello': 1702,
    'Strambino': 1702,
    'Stresa': 1702,
    'Strevi': 1702,
    'Stroppiana': 1702,
    'Stroppo': 1702,
    'Suno': 1702,
    'Susa': 1702,
    'Tagliaferro': 1702,
    'Tagliolo Monferrato': 1702,
    'Tarantasca': 1702,
    'Tassarolo': 1702,
    'Tavagnasco': 1702,
    'Tavigliano': 1702,
    'Terdobbiate': 1702,
    'Ternengo': 1702,
    'Terruggia': 1702,
    'Terzo': 1702,
    'Tetti Neirotti': 1702,
    'Tettorosso': 1702,
    'Ticineto': 1702,
    'Tigliole': 1702,
    'Toceno': 1702,
    'Tollegno': 1702,
    'Tonco': 1702,
    'Tonengo': 1702,
    'Tonengo-Casale': 1702,
    'Torino': 1702,
    'Tornaco': 1702,
    'Torrazza Piemonte': 1702,
    'Torrazzo': 1702,
    'Torre Bormida': 1702,
    'Torre Canavese': 1702,
    'Torre Mondovì': 1702,
    'Torre Pellice': 1702,
    'Torre San Giorgio': 1702,
    'Torresina': 1702,
    'Torrion Quartara': 1702,
    'Tortona': 1702,
    'Trana': 1702,
    'Trarego': 1702,
    'Trasquera': 1702,
    'Trausella': 1702,
    'Traversella': 1702,
    'Traves': 1702,
    'Trecate': 1702,
    'Treiso': 1702,
    'Treville': 1702,
    'Trezzo Tinella': 1702,
    'Tricerro': 1702,
    'Trinità': 1702,
    'Trino': 1702,
    'Trisobbio': 1702,
    'Trivero-Prativero-Ponzone': 1702,
    'Trofarello': 1702,
    'Trontano': 1702,
    'Tronzano Vercellese': 1702,
    'Turin': 1702,
    'Usseaux': 1702,
    'Usseglio': 1702,
    'Vaccheria': 1702,
    'Vaglio Serra': 1702,
    'Vaie': 1702,
    'Val della Torre': 1702,
    'Valdengo': 1702,
    'Valdieri': 1702,
    'Valduggia': 1702,
    'Valentino': 1702,
    'Valenza': 1702,
    'Valfenera': 1702,
    'Valgioie': 1702,
    'Valgrana': 1702,
    'Vallanzengo': 1702,
    'Valle Mosso': 1702,
    'Valle San Bartolomeo': 1702,
    'Valle San Nicolao': 1702,
    'Vallo Torinese': 1702,
    'Valloriate': 1702,
    'Valmacca': 1702,
    'Valmadonna': 1702,
    'Valmala': 1702,
    'Valperga': 1702,
    'Valprato Soana': 1702,
    'Valstrona': 1702,
    'Vanzone': 1702,
    'Vaprio D  Agogna': 1702,
    'Varallo': 1702,
    'Varallo Pombia': 1702,
    'Varisella': 1702,
    'Varzo': 1702,
    'Vauda Canavese Superiore': 1702,
    'Veglio': 1702,
    'Venaria Reale': 1702,
    'Venasca': 1702,
    'Venaus': 1702,
    'Verbania': 1702,
    'Vercelli': 1702,
    'Verduno': 1702,
    'Vergnasco': 1702,
    'Vernante': 1702,
    'Verolengo': 1702,
    'Verrone': 1702,
    'Verrua Savoia': 1702,
    'Veruno': 1702,
    'Verzuolo': 1702,
    'Vesime': 1702,
    'Vespolate': 1702,
    'Vestignè': 1702,
    'Vezza d  Alba': 1702,
    'Viale': 1702,
    'Vialfrè': 1702,
    'Viarigi': 1702,
    'Vico Canavese': 1702,
    'Vicoforte': 1702,
    'Vicolungo': 1702,
    'Vidracco': 1702,
    'Viganella': 1702,
    'Vigliano Biellese': 1702,
    'Vigliano d  Asti': 1702,
    'Vignale Monferrato': 1702,
    'Vignole Borbera': 1702,
    'Vignolo': 1702,
    'Vignone': 1702,
    'Vigone': 1702,
    'Viguzzolo': 1702,
    'Villa': 1702,
    'Villa del Bosco': 1702,
    'Villa San Secondo': 1702,
    'Villa-Borgo': 1702,
    'Villadeati': 1702,
    'Villadossola': 1702,
    'Villafalletto': 1702,
    'Villafranca d  Asti': 1702,
    'Villafranca Piemonte': 1702,
    'Villalvernia': 1702,
    'Villamiroglio': 1702,
    'Villanova Biellese': 1702,
    'Villanova Canavese': 1702,
    'Villanova d  Asti': 1702,
    'Villanova Mondovì': 1702,
    'Villanova Monferrato': 1702,
    'Villanova Solaro': 1702,
    'Villar Dora': 1702,
    'Villar Focchiardo': 1702,
    'Villar Pellice': 1702,
    'Villar Perosa': 1702,
    'Villar San Costanzo': 1702,
    'Villarbasse': 1702,
    'Villarboit': 1702,
    'Villareggia': 1702,
    'Villaromagnano': 1702,
    'Villastellone': 1702,
    'Villata': 1702,
    'Villette': 1702,
    'Vinadio': 1702,
    'Vinchio': 1702,
    'Vinovo': 1702,
    'Vinzaglio': 1702,
    'Viola': 1702,
    'Virle Piemonte': 1702,
    'Vische': 1702,
    'Visone': 1702,
    'Vistrorio': 1702,
    'Viù': 1702,
    'Viverone': 1702,
    'Vocca': 1702,
    'Vogogna': 1702,
    'Volpedo': 1702,
    'Volpeglino': 1702,
    'Volpiano': 1702,
    'Voltaggio': 1702,
    'Volvera': 1702,
    'Vottignasco': 1702,
    'Zimone': 1702,
    'Zubiena': 1702,
    'Zumaglia': 1702,
    'Abbasanta': 1715,
    'Aggius': 1715,
    'Aglientu': 1715,
    'Aidomaggiore': 1715,
    'Alà dei Sardi': 1715,
    'Albagiara': 1715,
    'Ales': 1715,
    'Alghero': 1715,
    'Allai': 1715,
    'Anela': 1715,
    'Arbatax': 1715,
    'Arborea': 1715,
    'Arbus': 1715,
    'Ardara': 1715,
    'Ardauli': 1715,
    'Aritzo': 1715,
    'Armungia': 1715,
    'Arzachena': 1715,
    'Arzana': 1715,
    'Assemini': 1715,
    'Assolo': 1715,
    'Asuni': 1715,
    'Atzara': 1715,
    'Austis': 1715,
    'Bacu Abis': 1715,
    'Badesi': 1715,
    'Ballao': 1715,
    'Banari': 1715,
    'Bancali': 1715,
    'Baradili': 1715,
    'Baratili San Pietro': 1715,
    'Baressa': 1715,
    'Bari Sardo': 1715,
    'Barrali': 1715,
    'Barumini': 1715,
    'Bauladu': 1715,
    'Baunei': 1715,
    'Belvì': 1715,
    'Benetutti': 1715,
    'Berchidda': 1715,
    'Bessude': 1715,
    'Bidonì': 1715,
    'Birori': 1715,
    'Bitti': 1715,
    'Bolotana': 1715,
    'Bonarcado': 1715,
    'Bonnanaro': 1715,
    'Bono': 1715,
    'Bonorva': 1715,
    'Boroneddu': 1715,
    'Borore': 1715,
    'Bortigali': 1715,
    'Bortigiadas': 1715,
    'Borutta': 1715,
    'Bosa': 1715,
    'Bottidda': 1715,
    'Buddusò': 1715,
    'Budoni': 1715,
    'Buggerru': 1715,
    'Bultei': 1715,
    'Bulzi': 1715,
    'Burcei': 1715,
    'Burgos': 1715,
    'Busachi': 1715,
    'Cabras': 1715,
    'Cagliari': 1715,
    'Cala Gonone': 1715,
    'Calangianus': 1715,
    'Calasetta': 1715,
    'Capoterra': 1715,
    'Carbonia': 1715,
    'Cardedu': 1715,
    'Cargeghe': 1715,
    'Carloforte': 1715,
    'Castelsardo': 1715,
    'Castiadas': 1715,
    'Cheremule': 1715,
    'Chiaramonti': 1715,
    'Codaruina': 1715,
    'Codrongianos': 1715,
    'Collinas': 1715,
    'Cortoghiana': 1715,
    'Cossoine': 1715,
    'Cuglieri': 1715,
    'Curcuris': 1715,
    'Decimomannu': 1715,
    'Decimoputzu': 1715,
    'Desulo': 1715,
    'Dolianova': 1715,
    'Domus de Maria': 1715,
    'Domusnovas': 1715,
    'Donigala Fenugheddu': 1715,
    'Donorì': 1715,
    'Dorgali': 1715,
    'Dualchi': 1715,
    'Elini': 1715,
    'Elmas': 1715,
    'Erula': 1715,
    'Escalaplano': 1715,
    'Escolca': 1715,
    'Esporlatu': 1715,
    'Esterzili': 1715,
    'Florinas': 1715,
    'Fluminimaggiore': 1715,
    'Flussio': 1715,
    'Fonni': 1715,
    'Fordongianus': 1715,
    'Furtei': 1715,
    'Gadoni': 1715,
    'Gairo Sant  Elena': 1715,
    'Galtellì': 1715,
    'Gavoi': 1715,
    'Genoni': 1715,
    'Genuri': 1715,
    'Gergei': 1715,
    'Gesico': 1715,
    'Gesturi': 1715,
    'Ghilarza': 1715,
    'Giave': 1715,
    'Giba': 1715,
    'Girasole': 1715,
    'Golfo Aranci': 1715,
    'Goni': 1715,
    'Gonnesa': 1715,
    'Gonnoscodina': 1715,
    'Gonnosfanadiga': 1715,
    'Gonnosnò': 1715,
    'Gonnostramatza': 1715,
    'Guamaggiore': 1715,
    'Guasila': 1715,
    'Guspini': 1715,
    'Iglesias': 1715,
    'Ilbono': 1715,
    'Illorai': 1715,
    'Irgoli': 1715,
    'Isili': 1715,
    'Ittireddu': 1715,
    'Ittiri': 1715,
    'Jerzu': 1715,
    'La Caletta': 1715,
    'La Maddalena': 1715,
    'Laconi': 1715,
    'Laerru': 1715,
    'Lanusei': 1715,
    'Las Plassas': 1715,
    'Lei': 1715,
    'Li Punti-San Giovanni': 1715,
    'Loceri': 1715,
    'Loculi': 1715,
    'Lodè': 1715,
    'Lodine': 1715,
    'Loiri Porto San Paolo': 1715,
    'Lotzorai': 1715,
    'Lu Bagnu': 1715,
    'Lula': 1715,
    'Lunamatrona': 1715,
    'Luogosanto': 1715,
    'Luras': 1715,
    'Macomer': 1715,
    'Magomadas': 1715,
    'Mamoiada': 1715,
    'Mandas': 1715,
    'Mara': 1715,
    'Maracalagonis': 1715,
    'Marrubiu': 1715,
    'Martis': 1715,
    'Masainas': 1715,
    'Masullas': 1715,
    'Meana Sardo': 1715,
    'Milis': 1715,
    'Modolo': 1715,
    'Mogorella': 1715,
    'Mogoro': 1715,
    'Monastir': 1715,
    'Monserrato': 1715,
    'Monteleone Rocca Doria': 1715,
    'Monti': 1715,
    'Montresta': 1715,
    'Mores': 1715,
    'Morgongiori': 1715,
    'Muravera': 1715,
    'Muros': 1715,
    'Musei': 1715,
    'Narbolia': 1715,
    'Narcao': 1715,
    'Neoneli': 1715,
    'Noragugume': 1715,
    'Norbello': 1715,
    'Nughedu San Nicolò': 1715,
    'Nughedu Santa Vittoria': 1715,
    'Nule': 1715,
    'Nulvi': 1715,
    'Nuoro': 1715,
    'Nurachi': 1715,
    'Nuragus': 1715,
    'Nurallao': 1715,
    'Nuraminis': 1715,
    'Nuraxinieddu': 1715,
    'Nureci': 1715,
    'Nurri': 1715,
    'Nuxis': 1715,
    'Olbia': 1715,
    'Olia Speciosa': 1715,
    'Oliena': 1715,
    'Ollastra': 1715,
    'Ollolai': 1715,
    'Olmedo': 1715,
    'Olzai': 1715,
    'Onanì': 1715,
    'Onifai': 1715,
    'Oniferi': 1715,
    'Orani': 1715,
    'Orgosolo': 1715,
    'Oristano': 1715,
    'Orosei': 1715,
    'Orotelli': 1715,
    'Orroli': 1715,
    'Ortacesus': 1715,
    'Ortueri': 1715,
    'Orune': 1715,
    'Oschiri': 1715,
    'Osidda': 1715,
    'Osilo': 1715,
    'Osini': 1715,
    'Ossi': 1715,
    'Ottana': 1715,
    'Ottava': 1715,
    'Ovodda': 1715,
    'Ozieri': 1715,
    'Pabillonis': 1715,
    'Padria': 1715,
    'Padru': 1715,
    'Palau': 1715,
    'Palmas Arborea': 1715,
    'Pattada': 1715,
    'Pau': 1715,
    'Pauli Arbarei': 1715,
    'Paulilatino': 1715,
    'Perdasdefogu': 1715,
    'Perdaxius': 1715,
    'Perfugas': 1715,
    'Pimentel': 1715,
    'Piscinas': 1715,
    'Ploaghe': 1715,
    'Poggio dei Pini': 1715,
    'Pompu': 1715,
    'Porto Cervo': 1715,
    'Porto Torres': 1715,
    'Portoscuso': 1715,
    'Posada': 1715,
    'Pozzomaggiore': 1715,
    'Provincia di Cagliari': 1715,
    'Provincia di Nuoro': 1715,
    'Provincia di Oristano': 1715,
    'Provincia di Sassari': 1715,
    'Pula': 1715,
    'Putifigari': 1715,
    'Quartu Sant  Elena': 1715,
    'Quartucciu': 1715,
    'Riola Sardo': 1715,
    'Romana': 1715,
    'Ruinas': 1715,
    'Sadali': 1715,
    'Sagama': 1715,
    'Samassi': 1715,
    'Samatzai': 1715,
    'Samugheo': 1715,
    'San Basilio': 1715,
    'San Gavino Monreale': 1715,
    'San Giovanni Suergiu': 1715,
    'San Nicola': 1715,
    'San Nicolò d  Arcidano': 1715,
    'San Nicolo  Gerrei': 1715,
    'San Sperate': 1715,
    'San Teodoro': 1715,
    'San Vero Milis': 1715,
    'San Vito': 1715,
    'Sanluri': 1715,
    'Sant  Andrea Frius': 1715,
    'Sant  Anna Arresi': 1715,
    'Sant  Antioco': 1715,
    'Sant  Antonio di Gallura': 1715,
    'Santa Giusta': 1715,
    'Santa Maria Coghinas': 1715,
    'Santa Maria Navarrese': 1715,
    'Santa Teresa Gallura': 1715,
    'Santadi': 1715,
    'Santu Lussurgiu': 1715,
    'Sardara': 1715,
    'Sarroch': 1715,
    'Sarule': 1715,
    'Sassari': 1715,
    'Scano di Montiferro': 1715,
    'Sedilo': 1715,
    'Sedini': 1715,
    'Segariu': 1715,
    'Selargius': 1715,
    'Selegas': 1715,
    'Semestene': 1715,
    'Seneghe': 1715,
    'Senis': 1715,
    'Sennariolo': 1715,
    'Sennori': 1715,
    'Senorbì': 1715,
    'Serdiana': 1715,
    'Serramanna': 1715,
    'Serrenti': 1715,
    'Serri': 1715,
    'Sestu': 1715,
    'Settimo San Pietro': 1715,
    'Setzu': 1715,
    'Seui': 1715,
    'Seulo': 1715,
    'Siamaggiore': 1715,
    'Siamanna': 1715,
    'Siapiccia': 1715,
    'Siddi': 1715,
    'Silanus': 1715,
    'Silì': 1715,
    'Siligo': 1715,
    'Siliqua': 1715,
    'Silius': 1715,
    'Simala': 1715,
    'Simaxis': 1715,
    'Sindia': 1715,
    'Sini': 1715,
    'Siniscola': 1715,
    'Sinnai': 1715,
    'Siris': 1715,
    'Siurgus Donigala': 1715,
    'Soddì': 1715,
    'Solanas': 1715,
    'Solarussa': 1715,
    'Soleminis': 1715,
    'Sorgono': 1715,
    'Sorradile': 1715,
    'Sorso': 1715,
    'Stintino': 1715,
    'Su Planu': 1715,
    'Suelli': 1715,
    'Suni': 1715,
    'Tadasuni': 1715,
    'Talana': 1715,
    'Telti': 1715,
    'Tempio Pausania': 1715,
    'Tergu': 1715,
    'Terralba': 1715,
    'Tertenia': 1715,
    'Teti': 1715,
    'Teulada': 1715,
    'Thiesi': 1715,
    'Tiana': 1715,
    'Tinnura': 1715,
    'Tissi': 1715,
    'Tonara': 1715,
    'Torpè': 1715,
    'Torralba': 1715,
    'Tortolì': 1715,
    'Tramatza': 1715,
    'Tratalias': 1715,
    'Tresnuraghes': 1715,
    'Triei': 1715,
    'Trinità d  Agultu': 1715,
    'Trinità d  Agultu e Vignola': 1715,
    'Tuili': 1715,
    'Tula': 1715,
    'Turri': 1715,
    'Ulà Tirso': 1715,
    'Ulassai': 1715,
    'Uras': 1715,
    'Uri': 1715,
    'Urzulei': 1715,
    'Usellus': 1715,
    'Usini': 1715,
    'Ussana': 1715,
    'Ussaramanna': 1715,
    'Ussassai': 1715,
    'Uta': 1715,
    'Valledoria': 1715,
    'Vallermosa': 1715,
    'Viddalba': 1715,
    'Villa San Pietro': 1715,
    'Villa Sant  Antonio': 1715,
    'Villa Verde': 1715,
    'Villacidro': 1715,
    'Villagrande Strisaili': 1715,
    'Villamar': 1715,
    'Villamassargia': 1715,
    'Villanova Monteleone': 1715,
    'Villanova Truschedu': 1715,
    'Villanova Tulo': 1715,
    'Villanovaforru': 1715,
    'Villanovafranca': 1715,
    'Villaperuccio': 1715,
    'Villaputzu': 1715,
    'Villasalto': 1715,
    'Villasimius': 1715,
    'Villasor': 1715,
    'Villaspeciosa': 1715,
    'Villaurbana': 1715,
    'Zeddiani': 1715,
    'Zerfaliu': 1715,
    'Acate': 1709,
    'Aci Bonaccorsi': 1709,
    'Aci Castello': 1709,
    'Aci Catena': 1709,
    'Aci Sant  Antonio': 1709,
    'Aci Trezza': 1709,
    'Acireale': 1709,
    'Acitrezza': 1709,
    'Acquaviva Platani': 1709,
    'Acquedolci': 1709,
    'Adrano': 1709,
    'Agira': 1709,
    'Agliandroni-Paternella': 1709,
    'Agrigento': 1709,
    'Aidone': 1709,
    'Alcamo': 1709,
    'Alcara Li Fusi': 1709,
    'Alessandria della Rocca': 1709,
    'Alì': 1709,
    'Alì Terme': 1709,
    'Alia': 1709,
    'Alimena': 1709,
    'Aliminusa': 1709,
    'Altavilla Milicia': 1709,
    'Altofonte': 1709,
    'Antillo': 1709,
    'Aragona': 1709,
    'Assoro': 1709,
    'Augusta': 1709,
    'Avola': 1709,
    'Bagheria': 1709,
    'Balestrate': 1709,
    'Barcellona Pozzo di Gotto': 1709,
    'Barrafranca': 1709,
    'Basicò': 1709,
    'Baucina': 1709,
    'Belmonte Mezzagno': 1709,
    'Belpasso': 1709,
    'Belvedere': 1709,
    'Belvedere-Piano Tavola': 1709,
    'Biancavilla': 1709,
    'Bisacquino': 1709,
    'Bivona': 1709,
    'Blufi': 1709,
    'Bolognetta': 1709,
    'Bompensiere': 1709,
    'Bompietro': 1709,
    'Borgetto': 1709,
    'Brolo': 1709,
    'Bronte': 1709,
    'Brucoli': 1709,
    'Buccheri': 1709,
    'Burgio': 1709,
    'Buscemi': 1709,
    'Buseto Palizzolo': 1709,
    'Butera': 1709,
    'Caccamo': 1709,
    'Calamonaci': 1709,
    'Calascibetta': 1709,
    'Calatabiano': 1709,
    'Calatafimi': 1709,
    'Caltabellotta': 1709,
    'Caltagirone': 1709,
    'Caltanissetta': 1709,
    'Caltavuturo': 1709,
    'Camastra': 1709,
    'Cammarata': 1709,
    'Campanella-Gianforma': 1709,
    'Campobello di Licata': 1709,
    'Campobello di Mazara': 1709,
    'Campofelice di Fitalia': 1709,
    'Campofelice di Roccella': 1709,
    'Campofiorito': 1709,
    'Campofranco': 1709,
    'Camporeale': 1709,
    'Camporotondo Etneo': 1709,
    'Canalicchio': 1709,
    'Canicattì': 1709,
    'Canicattini Bagni': 1709,
    'Canneto': 1709,
    'Cannizzaro-Favara': 1709,
    'Capaci': 1709,
    'Capizzi': 1709,
    'Capo d  Orlando': 1709,
    'Capri Leone': 1709,
    'Carini': 1709,
    'Carlentini': 1709,
    'Caronia': 1709,
    'Carrozziere': 1709,
    'Carruba': 1709,
    'Carrubazza-Motta': 1709,
    'Casa Santa': 1709,
    'Casalvecchio Siculo': 1709,
    'Cassaro': 1709,
    'Cassibile': 1709,
    'Castanea delle Furie': 1709,
    'Castel di Judica': 1709,
    'Castel di Lucio': 1709,
    'Castelbuono': 1709,
    'Casteldaccia': 1709,
    'Castell  Umberto': 1709,
    'Castellammare del Golfo': 1709,
    'Castellana Sicula': 1709,
    'Castelluzzo': 1709,
    'Castelmola': 1709,
    'Casteltermini': 1709,
    'Castelvetrano': 1709,
    'Castiglione di Sicilia': 1709,
    'Castrofilippo': 1709,
    'Castronuovo di Sicilia': 1709,
    'Castroreale': 1709,
    'Catania': 1709,
    'Catenanuova': 1709,
    'Cattolica Eraclea': 1709,
    'Cava d  Aliga': 1709,
    'Cefalà Diana': 1709,
    'Cefalù': 1709,
    'Centuripe': 1709,
    'Cerami': 1709,
    'Cerda': 1709,
    'Cerza': 1709,
    'Cesarò': 1709,
    'Chianchitta-Pallio': 1709,
    'Chianchitta-Trappitello': 1709,
    'Chiaramonte Gulfi': 1709,
    'Chiusa Sclafani': 1709,
    'Ciaculli': 1709,
    'Cianciana': 1709,
    'Ciavolo': 1709,
    'Ciminna': 1709,
    'Cinisi': 1709,
    'Città Giardino': 1709,
    'Collesano': 1709,
    'Comiso': 1709,
    'Comitini': 1709,
    'Condrò': 1709,
    'Contessa Entellina': 1709,
    'Corleone': 1709,
    'Custonaci': 1709,
    'Delia': 1709,
    'Donnalucata': 1709,
    'Enna': 1709,
    'Erice': 1709,
    'Evangelisti-Rubino': 1709,
    'Falcone': 1709,
    'Faro Superiore': 1709,
    'Favara': 1709,
    'Favignana': 1709,
    'Ferla': 1709,
    'Ficarazzi': 1709,
    'Ficarra': 1709,
    'Finale': 1709,
    'Fiumedinisi': 1709,
    'Fiumefreddo Sicilia': 1709,
    'Floresta': 1709,
    'Floridia': 1709,
    'Fondachelli-Fantina': 1709,
    'Forza d  Agrò': 1709,
    'Francavilla di Sicilia': 1709,
    'Francofonte': 1709,
    'Frazzanò': 1709,
    'Fulgatore-Torretta': 1709,
    'Furci Siculo': 1709,
    'Furnari': 1709,
    'Gaggi': 1709,
    'Gagliano Castelferrato': 1709,
    'Galati Mamertino': 1709,
    'Gallodoro': 1709,
    'Gangi': 1709,
    'Gela': 1709,
    'Geraci Siculo': 1709,
    'Giacalone': 1709,
    'Giammoro': 1709,
    'Giardina Gallotti': 1709,
    'Giardinello': 1709,
    'Giardini-Naxos': 1709,
    'Giarratana': 1709,
    'Giarre': 1709,
    'Gioiosa Marea': 1709,
    'Giuliana': 1709,
    'Gliaca': 1709,
    'Godrano': 1709,
    'Grammichele': 1709,
    'Graniti': 1709,
    'Gratteri': 1709,
    'Gravina di Catania': 1709,
    'Grotte': 1709,
    'Gualtieri Sicaminò': 1709,
    'Guidomandri Marina': 1709,
    'Isnello': 1709,
    'Isola delle Femmine': 1709,
    'Ispica': 1709,
    'Itala': 1709,
    'Joppolo Giancaxio': 1709,
    'Kamma': 1709,
    'Lampedusa': 1709,
    'Larderia': 1709,
    'Lascari': 1709,
    'Lavinaio-Monterosso': 1709,
    'Leni': 1709,
    'Lentini': 1709,
    'Leonforte': 1709,
    'Lercara Friddi': 1709,
    'Letojanni': 1709,
    'Librizzi': 1709,
    'Licata': 1709,
    'Licodia Eubea': 1709,
    'Limina': 1709,
    'Linera': 1709,
    'Linguaglossa': 1709,
    'Lipari': 1709,
    'Longi': 1709,
    'Lucca Sicula': 1709,
    'Maletto': 1709,
    'Malfa': 1709,
    'Malvagna': 1709,
    'Mandanici': 1709,
    'Maniace': 1709,
    'Marausa': 1709,
    'Marianopoli': 1709,
    'Marina di Caronia': 1709,
    'Marina di Ragusa': 1709,
    'Marinella': 1709,
    'Marineo': 1709,
    'Marsala': 1709,
    'Mascali': 1709,
    'Mascalucia': 1709,
    'Maugeri': 1709,
    'Mazara del Vallo': 1709,
    'Mazara II': 1709,
    'Mazzarino': 1709,
    'Mazzarrà Sant  Andrea': 1709,
    'Mazzarrone': 1709,
    'Melia': 1709,
    'Melilli': 1709,
    'Menfi': 1709,
    'Merì': 1709,
    'Messina': 1709,
    'Mezzojuso': 1709,
    'Milazzo': 1709,
    'Milena': 1709,
    'Militello in Val di Catania': 1709,
    'Militello Rosmarino': 1709,
    'Milo': 1709,
    'Mineo': 1709,
    'Mirabella Imbaccari': 1709,
    'Mirto': 1709,
    'Misilmeri': 1709,
    'Misterbianco': 1709,
    'Mistretta': 1709,
    'Modica': 1709,
    'Moio Alcantara': 1709,
    'Monforte San Giorgio': 1709,
    'Mongiuffi Melia': 1709,
    'Monreale': 1709,
    'Montagnareale': 1709,
    'Montalbano Elicona': 1709,
    'Montallegro': 1709,
    'Montedoro': 1709,
    'Montelepre': 1709,
    'Montemaggiore Belsito': 1709,
    'Monterosso Almo': 1709,
    'Montevago': 1709,
    'Motta Camastra': 1709,
    'Motta d  Affermo': 1709,
    'Motta Sant  Anastasia': 1709,
    'Mussomeli': 1709,
    'Naro': 1709,
    'Naso': 1709,
    'Nicolosi': 1709,
    'Nicosia': 1709,
    'Niscemi': 1709,
    'Nissoria': 1709,
    'Nizza di Sicilia': 1709,
    'Noto': 1709,
    'Novara di Sicilia': 1709,
    'Nunziata': 1709,
    'Nuova Gibellina': 1709,
    'Nuovo Centro Urbano Poggioreale': 1709,
    'Oliveri': 1709,
    'Pace del Mela': 1709,
    'Paceco': 1709,
    'Pachino': 1709,
    'Pagliara': 1709,
    'Palagonia': 1709,
    'Palazzo Adriano': 1709,
    'Palazzolo': 1709,
    'Palazzolo Acreide': 1709,
    'Palermo': 1709,
    'Palma di Montechiaro': 1709,
    'Pantelleria': 1709,
    'Partanna': 1709,
    'Partinico': 1709,
    'Pasteria-Lapide': 1709,
    'Paternò': 1709,
    'Patti': 1709,
    'Pedagaggi': 1709,
    'Pedalino': 1709,
    'Pedara': 1709,
    'Pennisi': 1709,
    'Petralia Soprana': 1709,
    'Petralia Sottana': 1709,
    'Petrosino': 1709,
    'Pettineo': 1709,
    'Piana degli Albanesi': 1709,
    'Piano dei Geli': 1709,
    'Piano Maglio-Blandino': 1709,
    'Pianoconte': 1709,
    'Piazza Armerina': 1709,
    'Piedimonte Etneo': 1709,
    'Pietraperzia': 1709,
    'Pioppo': 1709,
    'Piraino': 1709,
    'Polizzi Generosa': 1709,
    'Pollina': 1709,
    'Portella di Mare': 1709,
    'Porto Empedocle': 1709,
    'Portopalo di Capo Passero': 1709,
    'Pozzallo': 1709,
    'Priolo Gargallo': 1709,
    'Prizzi': 1709,
    'Provincia di Caltanissetta': 1709,
    'Provincia di Siracusa': 1709,
    'Racalmuto': 1709,
    'Raccuja': 1709,
    'Raddusa': 1709,
    'Raffadali': 1709,
    'Ragalna': 1709,
    'Ragusa': 1709,
    'Ramacca': 1709,
    'Randazzo': 1709,
    'Ravanusa': 1709,
    'Realmonte': 1709,
    'Regalbuto': 1709,
    'Reitano': 1709,
    'Resuttano': 1709,
    'Ribera': 1709,
    'Riesi': 1709,
    'Rilievo': 1709,
    'Riposto': 1709,
    'Rocca di Capri Leone': 1709,
    'Roccafiorita': 1709,
    'Roccalumera': 1709,
    'Roccamena': 1709,
    'Roccapalumba': 1709,
    'Roccavaldina': 1709,
    'Roccella Valdemone': 1709,
    'Rodì': 1709,
    'Rometta': 1709,
    'Rometta Marea': 1709,
    'Rosolini': 1709,
    'Salaparuta': 1709,
    'Salemi': 1709,
    'Sambuca di Sicilia': 1709,
    'San Biagio Platani': 1709,
    'San Cataldo': 1709,
    'San Cipirello': 1709,
    'San Ciro-Ulmi-Filci': 1709,
    'San Cono': 1709,
    'San Filippo del Mela': 1709,
    'San Fratello': 1709,
    'San Giorgio': 1709,
    'San Giovanni': 1709,
    'San Giovanni Gemini': 1709,
    'San Giovanni la Punta': 1709,
    'San Giuseppe Jato': 1709,
    'San Gregorio di Catania': 1709,
    'San Leone Mosè': 1709,
    'San Marco d  Alunzio': 1709,
    'San Mauro Castelverde': 1709,
    'San Michele di Ganzaria': 1709,
    'San Pier Niceto': 1709,
    'San Piero Patti': 1709,
    'San Pietro Clarenza': 1709,
    'San Salvatore di Fitalia': 1709,
    'San Teodoro': 1709,
    'San Vito Lo Capo': 1709,
    'Sant  Agata di Militello': 1709,
    'Sant  Agata Li Battiati': 1709,
    'Sant  Alessio Siculo': 1709,
    'Sant  Alfio': 1709,
    'Sant  Angelo di Brolo': 1709,
    'Sant  Angelo Muxaro': 1709,
    'Santa Caterina Villarmosa': 1709,
    'Santa Cristina Gela': 1709,
    'Santa Croce Camerina': 1709,
    'Santa Domenica Vittoria': 1709,
    'Santa Elisabetta': 1709,
    'Santa Flavia': 1709,
    'Santa Lucia del Mela': 1709,
    'Santa Margherita di Belice': 1709,
    'Santa Maria di Licodia': 1709,
    'Santa Maria la Stella': 1709,
    'Santa Marina Salina': 1709,
    'Santa Ninfa': 1709,
    'Santa Teresa di Riva': 1709,
    'Santa Venerina': 1709,
    'Santo Stefano di Camastra': 1709,
    'Santo Stefano Quisquina': 1709,
    'Saponara': 1709,
    'Saponara Marittima': 1709,
    'Sasi': 1709,
    'Savoca': 1709,
    'Scaletta Zanclea': 1709,
    'Sciacca': 1709,
    'Sciara': 1709,
    'Scicli': 1709,
    'Scillato': 1709,
    'Sclafani Bagni': 1709,
    'Scoglitti': 1709,
    'Scordia': 1709,
    'Serradifalco': 1709,
    'Sfaranda': 1709,
    'Sferracavallo': 1709,
    'Siculiana': 1709,
    'Sinagra': 1709,
    'Siracusa': 1709,
    'Solarino': 1709,
    'Sommatino': 1709,
    'Sortino': 1709,
    'Spadafora': 1709,
    'Sparta  ': 1709,
    'Sperlinga': 1709,
    'Sutera': 1709,
    'Taormina': 1709,
    'Terme': 1709,
    'Termini Imerese': 1709,
    'Terrasini': 1709,
    'Tonnara di Bonagia': 1709,
    'Tonnarella': 1709,
    'Torre Colonna-Sperone': 1709,
    'Torregrotta': 1709,
    'Torrenova': 1709,
    'Torretta': 1709,
    'Tortorici': 1709,
    'Trabia': 1709,
    'Trapani': 1709,
    'Trappeto': 1709,
    'Tre Fontane': 1709,
    'Trecastagni': 1709,
    'Tremestieri Etneo': 1709,
    'Tripi': 1709,
    'Triscina': 1709,
    'Troina': 1709,
    'Tusa': 1709,
    'Ucria': 1709,
    'Ustica': 1709,
    'Valderice': 1709,
    'Valdina': 1709,
    'Valguarnera Caropepe': 1709,
    'Valledolmo': 1709,
    'Vallelunga Pratameno': 1709,
    'Valverde': 1709,
    'Vambolieri': 1709,
    'Venetico Marina': 1709,
    'Venetico Superiore': 1709,
    'Ventimiglia di Sicilia': 1709,
    'Viagrande': 1709,
    'Vicari': 1709,
    'Vigliatore 2': 1709,
    'Villa Ciambra': 1709,
    'Villabate': 1709,
    'Villafranca Sicula': 1709,
    'Villafranca Tirrena': 1709,
    'Villafrati': 1709,
    'Villaggio del Pino-Le Ginestre': 1709,
    'Villalba': 1709,
    'Villarosa': 1709,
    'Villaseta': 1709,
    'Villasmundo': 1709,
    'Vita': 1709,
    'Vittoria': 1709,
    'Vizzini': 1709,
    'Zafferana Etnea': 1709,
    'Ala': 1725,
    'Albiano': 1725,
    'Aldeno': 1725,
    'Aldino': 1725,
    'Amblar': 1725,
    'Andalo': 1725,
    'Andriano': 1725,
    'Anterivo': 1725,
    'Arco': 1725,
    'Avelengo': 1725,
    'Avio': 1725,
    'Badia': 1725,
    'Barbiano - Barbian': 1725,
    'Baselga di Pinè': 1725,
    'Bersone': 1725,
    'Besenello': 1725,
    'Bieno': 1725,
    'Bleggio Superiore': 1725,
    'Bocenago': 1725,
    'Bolbeno': 1725,
    'Bolognano-Vignole': 1725,
    'Bolzano': 1725,
    'Bondo': 1725,
    'Bondone': 1725,
    'Borgo Valsugana': 1725,
    'Bosentino-Migazzone': 1725,
    'Braies': 1725,
    'Breguzzo': 1725,
    'Brennero - Brenner': 1725,
    'Brentonico': 1725,
    'Bresimo': 1725,
    'Bressanone': 1725,
    'Brez': 1725,
    'Brione': 1725,
    'Bronzolo': 1725,
    'Brunico': 1725,
    'Caderzone Terme': 1725,
    'Cadine': 1725,
    'Cadipietra': 1725,
    'Cagnò': 1725,
    'Caines': 1725,
    'Caines - Kuens': 1725,
    'Calavino': 1725,
    'Calceranica al Lago': 1725,
    'Caldaro sulla Strada del Vino': 1725,
    'Caldes': 1725,
    'Caldonazzo': 1725,
    'Calliano': 1725,
    'Campitello di Fassa': 1725,
    'Campo di Trens': 1725,
    'Campo Tures': 1725,
    'Campodenno': 1725,
    'Canal San Bovo': 1725,
    'Canazei': 1725,
    'Capriana': 1725,
    'Carano': 1725,
    'Cardano': 1725,
    'Carisolo': 1725,
    'Carzano': 1725,
    'Castel Condino': 1725,
    'Castelbello': 1725,
    'Castelbello-Ciardes - Kastelbell-Tschars': 1725,
    'Castelfondo': 1725,
    'Castello Molina di Fiemme': 1725,
    'Castello Tesino': 1725,
    'Castelnuovo': 1725,
    'Castelrotto': 1725,
    'Cavalese': 1725,
    'Cavareno': 1725,
    'Cavedago': 1725,
    'Cavedine': 1725,
    'Cavizzana': 1725,
    'Cembra': 1725,
    'Centa San Nicolò': 1725,
    'Centrale': 1725,
    'Cermes': 1725,
    'Certosa': 1725,
    'Chienes': 1725,
    'Chiusa': 1725,
    'Cimego': 1725,
    'Cimoneri': 1725,
    'Cinte Tesino': 1725,
    'Cis': 1725,
    'Civezzano': 1725,
    'Cles': 1725,
    'Cloz': 1725,
    'Cogolo': 1725,
    'Collalbo': 1725,
    'Colle Isarco': 1725,
    'Commezzadura': 1725,
    'Condino': 1725,
    'Coredo': 1725,
    'Cornaiano': 1725,
    'Cornedo All  Isarco': 1725,
    'Cortaccia sulla Strada del Vino': 1725,
    'Cortina sulla Strada del Vino': 1725,
    'Corvara in Badia': 1725,
    'Covelo': 1725,
    'Creto': 1725,
    'Croviana': 1725,
    'Cunevo': 1725,
    'Curon Venosta': 1725,
    'Daiano': 1725,
    'Dambel': 1725,
    'Daone': 1725,
    'Denno': 1725,
    'Dimaro': 1725,
    'Dobbiaco': 1725,
    'Don': 1725,
    'Dorsino': 1725,
    'Drena': 1725,
    'Dro': 1725,
    'Egna': 1725,
    'Faedo': 1725,
    'Fai della Paganella': 1725,
    'Falzes': 1725,
    'Faver': 1725,
    'Fiavè': 1725,
    'Fiè Allo Sciliar': 1725,
    'Fiera di Primiero': 1725,
    'Fierozzo': 1725,
    'Flavon': 1725,
    'Folgaria': 1725,
    'Fondo': 1725,
    'Fontana Nuova-Bevia': 1725,
    'Fornace': 1725,
    'Fortezza': 1725,
    'Frassilongo': 1725,
    'Fraviano': 1725,
    'Funes - Villnoess': 1725,
    'Gais': 1725,
    'Ganda': 1725,
    'Gargazzone': 1725,
    'Garniga Nuova': 1725,
    'Gionghi-Cappella': 1725,
    'Giovo': 1725,
    'Giustino': 1725,
    'Glorenza': 1725,
    'Grauno': 1725,
    'Grigno': 1725,
    'Grumes': 1725,
    'Imer': 1725,
    'Isera': 1725,
    'La Valle - Wengen': 1725,
    'Laces': 1725,
    'Laghetti': 1725,
    'Lagundo': 1725,
    'Laion': 1725,
    'Laives': 1725,
    'Lana': 1725,
    'Lardaro': 1725,
    'Lasa': 1725,
    'Lases': 1725,
    'Lasino': 1725,
    'Lauregno': 1725,
    'Lavis': 1725,
    'Leone-Santa Elisabetta': 1725,
    'Levico Terme': 1725,
    'Lisignago': 1725,
    'Livo': 1725,
    'Lodrone-Darzo': 1725,
    'Lona-Lases': 1725,
    'Luserna': 1725,
    'Luson - Luesen': 1725,
    'Madrano-Canzolino': 1725,
    'Magrè sulla Strada del Vino': 1725,
    'Malè': 1725,
    'Malles Venosta': 1725,
    'Malosco': 1725,
    'Marco': 1725,
    'Marlengo': 1725,
    'Massimeno': 1725,
    'Mattarello': 1725,
    'Mazzin': 1725,
    'Meano': 1725,
    'Meiern': 1725,
    'Meltina': 1725,
    'Merano': 1725,
    'Mezzana': 1725,
    'Mezzano': 1725,
    'Mezzocorona': 1725,
    'Mezzolombardo': 1725,
    'Miola di Pinè': 1725,
    'Moena': 1725,
    'Molina di Ledro': 1725,
    'Molveno': 1725,
    'Monclassico': 1725,
    'Monguelfo': 1725,
    'Montagna': 1725,
    'Montagne': 1725,
    'Mori': 1725,
    'Moscheri': 1725,
    'Moso in Passiria': 1725,
    'Mühlen in Taufers': 1725,
    'Nago-Torbole': 1725,
    'Nalles': 1725,
    'Nanno': 1725,
    'Naturno': 1725,
    'Nave San Rocco': 1725,
    'Naz-Sciaves - Natz-Schabs': 1725,
    'Nogaredo': 1725,
    'Nomi': 1725,
    'Nova Levante': 1725,
    'Nova Ponente': 1725,
    'Novaledo': 1725,
    'Ora': 1725,
    'Ortisei': 1725,
    'Ospedaletto': 1725,
    'Ossana': 1725,
    'Padergnone': 1725,
    'Palù del Fersina': 1725,
    'Panchià': 1725,
    'Parcines': 1725,
    'Pellizzano': 1725,
    'Pelugo': 1725,
    'Perca': 1725,
    'Pergine Valsugana': 1725,
    'Piazza': 1725,
    'Pietramurata': 1725,
    'Pieve di Bono': 1725,
    'Pieve di Ledro': 1725,
    'Pieve Tesino': 1725,
    'Pineta': 1725,
    'Pinzolo': 1725,
    'Plaus': 1725,
    'Pomarolo': 1725,
    'Ponte Arche': 1725,
    'Ponte Gardena': 1725,
    'Postal': 1725,
    'Povo': 1725,
    'Pozza di Fassa': 1725,
    'Praso': 1725,
    'Prati': 1725,
    'Prato Allo Stelvio': 1725,
    'Predazzo': 1725,
    'Predoi': 1725,
    'Preore': 1725,
    'Prezzo': 1725,
    'Proves - Proveis': 1725,
    'Provincia autonoma di Trento': 1725,
    'Rabbi Fonti': 1725,
    'Rablà': 1725,
    'Racines': 1725,
    'Racines - Ratschings': 1725,
    'Ragoli': 1725,
    'Rasun Anterselva - Rasen-Antholz': 1725,
    'Ravina': 1725,
    'Renon - Ritten': 1725,
    'Revò': 1725,
    'Ried': 1725,
    'Rifiano': 1725,
    'Rio di Pusteria': 1725,
    'Riscone': 1725,
    'Riva del Garda': 1725,
    'Rodengo': 1725,
    'Rodengo - Rodeneck': 1725,
    'Romagnano': 1725,
    'Romallo': 1725,
    'Romeno': 1725,
    'Roncegno': 1725,
    'Ronchi Valsugana': 1725,
    'Roncone': 1725,
    'Ronzo-Chienis': 1725,
    'Ronzone': 1725,
    'Roverè della Luna': 1725,
    'Rovereto': 1725,
    'Ruffrè': 1725,
    'Rumo': 1725,
    'Sabbionara': 1725,
    'Sagron Mis': 1725,
    'Salorno': 1725,
    'Samone': 1725,
    'San Bernardo': 1725,
    'San Candido': 1725,
    'San Felice': 1725,
    'San Genesio': 1725,
    'San Genesio Atesino': 1725,
    'San Giacomo': 1725,
    'San Leonardo in Passiria': 1725,
    'San Lorenzo di Sebato': 1725,
    'San Lorenzo in Banale': 1725,
    'San Martino': 1725,
    'San Martino in Badia': 1725,
    'San Martino in Passiria': 1725,
    'San Michele': 1725,
    'San Michele All  Adige': 1725,
    'San Pancrazio': 1725,
    'San Paolo': 1725,
    'San Pietro': 1725,
    'San Vigilio': 1725,
    'Sant  Orsola': 1725,
    'Sant  Orsola Terme': 1725,
    'Santa Cristina Valgardena': 1725,
    'Santa Valburga': 1725,
    'Sanzeno': 1725,
    'Sarentino': 1725,
    'Sarnonico': 1725,
    'Scena': 1725,
    'Scena - Schenna': 1725,
    'Sciaves': 1725,
    'Scurelle': 1725,
    'Segonzano': 1725,
    'Selva': 1725,
    'Selva dei Molini': 1725,
    'Senale': 1725,
    'Senales': 1725,
    'Sesto': 1725,
    'Sfruz': 1725,
    'Silandro': 1725,
    'Sinigo': 1725,
    'Siror': 1725,
    'Siusi': 1725,
    'Sluderno': 1725,
    'Smarano': 1725,
    'Soprabolzano': 1725,
    'Soraga': 1725,
    'Sover': 1725,
    'Spera': 1725,
    'Spiazzo': 1725,
    'Spormaggiore': 1725,
    'Sporminore': 1725,
    'Stanghe': 1725,
    'Stelvio': 1725,
    'Stenico': 1725,
    'Storo': 1725,
    'Strembo': 1725,
    'Strigno': 1725,
    'Taio': 1725,
    'Tassullo': 1725,
    'Telve': 1725,
    'Telve di Sopra': 1725,
    'Tenna': 1725,
    'Tenno': 1725,
    'Terento': 1725,
    'Terlago': 1725,
    'Terlano': 1725,
    'Termeno': 1725,
    'Terragnolo': 1725,
    'Terres': 1725,
    'Terzolas': 1725,
    'Tesero': 1725,
    'Tesimo': 1725,
    'Tione di Trento': 1725,
    'Tires': 1725,
    'Tirolo': 1725,
    'Tonadico': 1725,
    'Torcegno': 1725,
    'Trambileno': 1725,
    'Transacqua': 1725,
    'Trento': 1725,
    'Tres': 1725,
    'Trodena': 1725,
    'Tubre': 1725,
    'Tuenno': 1725,
    'Ultimo - Ulten': 1725,
    'Vadena': 1725,
    'Valda': 1725,
    'Valdaora di Mezzo': 1725,
    'Valfloriana': 1725,
    'Vallarsa': 1725,
    'Valle Aurina - Ahrntal': 1725,
    'Valle di Casies - Gsies': 1725,
    'Vandoies - Vintl': 1725,
    'Vandoies di Sotto': 1725,
    'Varena': 1725,
    'Varna': 1725,
    'Vattaro': 1725,
    'Velturno': 1725,
    'Verano': 1725,
    'Verano - Voeran': 1725,
    'Verla': 1725,
    'Vermiglio': 1725,
    'Vervò': 1725,
    'Vezzano': 1725,
    'Vignola': 1725,
    'Vigo di Fassa': 1725,
    'Vigo di Ton': 1725,
    'Vigolo Vattaro': 1725,
    'Villa': 1725,
    'Villa Lagarina': 1725,
    'Villa Rendena': 1725,
    'Villa-Agnedo': 1725,
    'Villabassa': 1725,
    'Villandro': 1725,
    'Villandro - Villanders': 1725,
    'Vipiteno': 1725,
    'Volano': 1725,
    'Zambana': 1725,
    'Ziano di Fiemme': 1725,
    'Zuclo': 1725,
    'Abbadia San Salvatore': 1664,
    'Abetone': 1664,
    'Acquaviva': 1664,
    'Agliana': 1664,
    'Albiano Magra': 1664,
    'Albinia': 1664,
    'Altopascio': 1664,
    'Ambra': 1664,
    'Anchione': 1664,
    'Anghiari': 1664,
    'Antella': 1664,
    'Arbia': 1664,
    'Arcidosso': 1664,
    'Arezzo': 1664,
    'Asciano': 1664,
    'Aulla': 1664,
    'Avane': 1664,
    'Badia al Pino': 1664,
    'Badia Tedalda': 1664,
    'Bagni di Lucca': 1664,
    'Bagno a Ripoli': 1664,
    'Bagno Roselle': 1664,
    'Bagnone': 1664,
    'Barba': 1664,
    'Barbarasco': 1664,
    'Barberino di Mugello': 1664,
    'Barberino Val d  Elsa': 1664,
    'Barga': 1664,
    'Battifolle-Ruscello-Poggiola': 1664,
    'Bellavista': 1664,
    'Belverde': 1664,
    'Bettolle': 1664,
    'Bibbiena': 1664,
    'Bibbona': 1664,
    'Bientina': 1664,
    'Borghetto-Melara': 1664,
    'Borgo a Buggiano': 1664,
    'Borgo a Mozzano': 1664,
    'Borgo San Lorenzo': 1664,
    'Braccagni': 1664,
    'Bucine': 1664,
    'Buonconvento': 1664,
    'Buti': 1664,
    'Calci': 1664,
    'Calcinaia': 1664,
    'Caldine': 1664,
    'Calenzano': 1664,
    'Camaiore': 1664,
    'Campagnatico': 1664,
    'Campi Bisenzio': 1664,
    'Campiglia Marittima': 1664,
    'Campo': 1664,
    'Campo nell  Elba': 1664,
    'Campo Tizzoro': 1664,
    'Camporgiano': 1664,
    'Camucia-Monsigliolo': 1664,
    'Cantagallo': 1664,
    'Cantagrillo-Casalguidi': 1664,
    'Capalbio': 1664,
    'Capanne-Prato-Cinquale': 1664,
    'Capannoli': 1664,
    'Capannori': 1664,
    'Capoliveri': 1664,
    'Capolona': 1664,
    'Capraia e Limite': 1664,
    'Capraia Isola': 1664,
    'Caprese Michelangelo': 1664,
    'Careggine': 1664,
    'Carmignano': 1664,
    'Carraia': 1664,
    'Carrara': 1664,
    'Casale Marittimo': 1664,
    'Casciana Terme': 1664,
    'Cascina': 1664,
    'Cascine-La Croce': 1664,
    'Casola in Lunigiana': 1664,
    'Casole d  Elsa': 1664,
    'Castagneto Carducci': 1664,
    'Castel del Piano': 1664,
    'Castel San Niccolò': 1664,
    'Castelfiorentino': 1664,
    'Castelfranco di Sopra': 1664,
    'Castelfranco di Sotto': 1664,
    'Castell  Azzara': 1664,
    'Castellina in Chianti': 1664,
    'Castellina Marittima': 1664,
    'Castellina Scalo': 1664,
    'Castelnuovo Berardenga': 1664,
    'Castelnuovo dei Sabbioni': 1664,
    'Castelnuovo di Garfagnana': 1664,
    'Castelnuovo di Val di Cecina': 1664,
    'Castiglion Fibocchi': 1664,
    'Castiglion Fiorentino': 1664,
    'Castiglione d  Orcia': 1664,
    'Castiglione della Pescaia': 1664,
    'Castiglione di Garfagnana': 1664,
    'Catena': 1664,
    'Cavallina': 1664,
    'Cavriglia-Monastero': 1664,
    'Cecina': 1664,
    'Cenaia': 1664,
    'Cerbaia': 1664,
    'Cerreto Guidi': 1664,
    'Certaldo': 1664,
    'Cesa': 1664,
    'Cetona': 1664,
    'Chianciano Terme': 1664,
    'Chianni': 1664,
    'Chiassa-Tregozzano': 1664,
    'Chiesina Uzzanese': 1664,
    'Chiesino-Collodi': 1664,
    'Chitignano': 1664,
    'Chiusdino': 1664,
    'Chiusi': 1664,
    'Chiusi della Verna': 1664,
    'Chiusi Scalo': 1664,
    'Cinigiano': 1664,
    'Cintolese': 1664,
    'Civitella in Val di Chiana': 1664,
    'Civitella Marittima': 1664,
    'Colle di Val d  Elsa': 1664,
    'Collesalvetti': 1664,
    'Comano': 1664,
    'Comeana': 1664,
    'Compiobbi': 1664,
    'Coreglia Antelminelli': 1664,
    'Corsanico-Bargecchia': 1664,
    'Cortona': 1664,
    'Crespina': 1664,
    'Cutigliano': 1664,
    'Dicomano': 1664,
    'Donoratico': 1664,
    'Empoli': 1664,
    'Fabbriche di Vallico': 1664,
    'Faella': 1664,
    'Fauglia': 1664,
    'Fiesole': 1664,
    'Figline Valdarno': 1664,
    'Filattiera': 1664,
    'Filettole': 1664,
    'Firenzuola': 1664,
    'Fivizzano': 1664,
    'Florence': 1664,
    'Foiano della Chiana': 1664,
    'Follonica': 1664,
    'Fontana delle Monache': 1664,
    'Fonteblanda': 1664,
    'Forcoli': 1664,
    'Fornacelle': 1664,
    'Fornacette': 1664,
    'Fornaci di Barga': 1664,
    'Forte dei Marmi': 1664,
    'Fosciandora': 1664,
    'Fosdinovo': 1664,
    'Fucecchio': 1664,
    'Gabella': 1664,
    'Gaiole in Chianti': 1664,
    'Galliano': 1664,
    'Gallicano': 1664,
    'Gambassi Terme': 1664,
    'Gavorrano': 1664,
    'Gello': 1664,
    'Giglio Castello': 1664,
    'Ginestra Fiorentina': 1664,
    'Giovi-Ponte alla Chiassa': 1664,
    'Giuncugnano': 1664,
    'Grassina Ponte a Ema': 1664,
    'Greve in Chianti': 1664,
    'Grosseto': 1664,
    'Guardistallo': 1664,
    'Guasticce': 1664,
    'Guazzino': 1664,
    'Il Romito': 1664,
    'Impruneta': 1664,
    'Incisa in Val d  Arno': 1664,
    'Isola D  Arbia': 1664,
    'Isola del Giglio': 1664,
    'Istia D  Ombrone-Le Stiacciole': 1664,
    'La California': 1664,
    'La Pieve-Molino': 1664,
    'La Rotta': 1664,
    'Lajatico': 1664,
    'Lamporecchio': 1664,
    'Larciano': 1664,
    'Lari': 1664,
    'Lastra a Signa': 1664,
    'Laterina': 1664,
    'Lazzeretto': 1664,
    'Le Casine-Perignano-Spinelli': 1664,
    'Licciana Nardi': 1664,
    'Limite': 1664,
    'Livorno': 1664,
    'Londa': 1664,
    'Lorenzana': 1664,
    'Loro Ciuffenna': 1664,
    'Lucca': 1664,
    'Lucignano': 1664,
    'Luco Mugello': 1664,
    'Madonna dell  Acqua': 1664,
    'Magliano in Toscana': 1664,
    'Malmantile': 1664,
    'Manciano': 1664,
    'Mantignano-Ugnano': 1664,
    'Marciana': 1664,
    'Marciana Marina': 1664,
    'Marciano': 1664,
    'Marciano della Chiana': 1664,
    'Marcignana': 1664,
    'Margine Coperta-Traversagna': 1664,
    'Marina di Campo': 1664,
    'Marina di Carrara': 1664,
    'Marina di Grosseto': 1664,
    'Marina di Massa': 1664,
    'Marina di Pisa': 1664,
    'Marina di Pisa-Tirrenia-Calambrone': 1664,
    'Marliana': 1664,
    'Marradi': 1664,
    'Marti': 1664,
    'Massa': 1664,
    'Massa Marittima': 1664,
    'Massarosa': 1664,
    'Mercatale': 1664,
    'Mercatale-San Quirico': 1664,
    'Metato': 1664,
    'Migliarino': 1664,
    'Minucciano': 1664,
    'Molazzana': 1664,
    'Molino del Piano': 1664,
    'Monsummano Terme': 1664,
    'Montaione': 1664,
    'Montalcino': 1664,
    'Montale': 1664,
    'Montalto': 1664,
    'Monte Argentario': 1664,
    'Monte San Savino': 1664,
    'Montebonello': 1664,
    'Montecalvoli': 1664,
    'Montecarlo': 1664,
    'Montecatini Val di Cecina': 1664,
    'Montecatini-Terme': 1664,
    'Montelupo Fiorentino': 1664,
    'Montemignaio': 1664,
    'Montemurlo': 1664,
    'Montepulciano': 1664,
    'Montepulciano Stazione': 1664,
    'Monterchi': 1664,
    'Monteriggioni': 1664,
    'Monteroni d  Arbia': 1664,
    'Monterotondo Marittimo': 1664,
    'Montescudaio': 1664,
    'Montespertoli': 1664,
    'Montevarchi': 1664,
    'Monteverdi Marittimo': 1664,
    'Monti': 1664,
    'Monticiano': 1664,
    'Montieri': 1664,
    'Montignoso': 1664,
    'Montopoli': 1664,
    'Mulazzo': 1664,
    'Murlo': 1664,
    'Orbetello': 1664,
    'Orbetello Scalo': 1664,
    'Orciano Pisano': 1664,
    'Orentano': 1664,
    'Ortignano Raggiolo': 1664,
    'Orzignano': 1664,
    'Palaia': 1664,
    'Palazzuolo sul Senio': 1664,
    'Pallerone': 1664,
    'Panzano in Chianti': 1664,
    'Patigno': 1664,
    'Peccioli': 1664,
    'Pelago': 1664,
    'Pergine Valdarno': 1664,
    'Pescaglia': 1664,
    'Pescia': 1664,
    'Pian di Mugnone': 1664,
    'Pian di Scò': 1664,
    'Piancastagnaio': 1664,
    'Piano di Conca': 1664,
    'Piano di Coreglia-Ghivizzano': 1664,
    'Piano di Mommio': 1664,
    'Piazza al Serchio-San Michele': 1664,
    'Pienza': 1664,
    'Pietrasanta': 1664,
    'Pieve a Nievole': 1664,
    'Pieve al Toppo': 1664,
    'Pieve Fosciana': 1664,
    'Pieve Santo Stefano': 1664,
    'Piombino': 1664,
    'Pisa': 1664,
    'Pistoia': 1664,
    'Piteglio': 1664,
    'Pitigliano': 1664,
    'Podenzana': 1664,
    'Poggetto': 1664,
    'Poggibonsi': 1664,
    'Poggio A Caiano': 1664,
    'Pomarance': 1664,
    'Ponsacco': 1664,
    'Pontasserchio': 1664,
    'Pontassieve': 1664,
    'Ponte A Elsa': 1664,
    'Ponte a Poppi': 1664,
    'Ponte A Tressa': 1664,
    'Ponte Buggianese': 1664,
    'Pontedera': 1664,
    'Pontestazzemese': 1664,
    'Ponticino': 1664,
    'Pontremoli': 1664,
    'Poppi': 1664,
    'Porcari': 1664,
    'Porto Azzurro': 1664,
    'Porto Ercole': 1664,
    'Porto Santo Stefano': 1664,
    'Portoferraio': 1664,
    'Pozzale-Case Nuove': 1664,
    'Pratantico-Indicatore': 1664,
    'Prato': 1664,
    'Pratovecchio': 1664,
    'Province of Arezzo': 1664,
    'Province of Florence': 1664,
    'Province of Pisa': 1664,
    'Provincia di Grosseto': 1664,
    'Provincia di Livorno': 1664,
    'Provincia di Lucca': 1664,
    'Provincia di Massa-Carrara': 1664,
    'Provincia di Pistoia': 1664,
    'Provincia di Prato': 1664,
    'Provincia di Siena': 1664,
    'Quarata': 1664,
    'Quarrata': 1664,
    'Quattro Strade': 1664,
    'Quercianella': 1664,
    'Radda in Chianti': 1664,
    'Radicofani': 1664,
    'Radicondoli': 1664,
    'Rapolano Terme': 1664,
    'Rassina': 1664,
    'Reggello': 1664,
    'Ribolla': 1664,
    'Rignano sull  Arno': 1664,
    'Rio Marina': 1664,
    'Rio nell  Elba': 1664,
    'Ripa-Pozzi-Querceta-Ponterosso': 1664,
    'Riparbella': 1664,
    'Roccalbegna': 1664,
    'Roccastrada': 1664,
    'Ronta': 1664,
    'Rosia': 1664,
    'Rosignano Marittimo': 1664,
    'Rosignano Solvay-Castiglioncello': 1664,
    'Rufina': 1664,
    'S.P. in Palazzi': 1664,
    'Saline': 1664,
    'Sambuca': 1664,
    'Sambuca Pistoiese': 1664,
    'San Casciano dei Bagni': 1664,
    'San Casciano in Val di Pesa': 1664,
    'San Cipriano-S.Barbara-Centinale': 1664,
    'San Donato': 1664,
    'San Donnino': 1664,
    'San Francesco': 1664,
    'San Gimignano': 1664,
    'San Giovanni d  Asso': 1664,
    'San Giovanni Valdarno': 1664,
    'San Giuliano Terme': 1664,
    'San Giustino Valdarno': 1664,
    'San Godenzo': 1664,
    'San Jacopo al Girone': 1664,
    'San Marcello Pistoiese': 1664,
    'San Martino in Freddana-Monsagrati': 1664,
    'San Mauro': 1664,
    'San Miniato': 1664,
    'San Miniato Basso': 1664,
    'San Pierino': 1664,
    'San Piero a Sieve': 1664,
    'San Pietro Belvedere': 1664,
    'San Quirico d  Orcia': 1664,
    'San Rocco': 1664,
    'San Romano': 1664,
    'San Romano in Garfagnana': 1664,
    'San Vincenzo': 1664,
    'San Vito-Cerreto': 1664,
    'Sansepolcro': 1664,
    'Sant  Albino': 1664,
    'Santa Croce sull  Arno': 1664,
    'Santa Fiora': 1664,
    'Santa Luce': 1664,
    'Santa Lucia': 1664,
    'Santa Maria a Monte': 1664,
    'Sarteano': 1664,
    'Sassetta': 1664,
    'Scandicci': 1664,
    'Scansano': 1664,
    'Scarlino': 1664,
    'Scarlino Scalo': 1664,
    'Scarperia': 1664,
    'Seano': 1664,
    'Seggiano': 1664,
    'Selvatelle': 1664,
    'Semproniano': 1664,
    'Seravezza': 1664,
    'Serravalle Pistoiese': 1664,
    'Serre di Rapolano': 1664,
    'Sestino': 1664,
    'Sesto Fiorentino': 1664,
    'Sieci': 1664,
    'Siena': 1664,
    'Signa': 1664,
    'Sillano': 1664,
    'Sinalunga': 1664,
    'Soci': 1664,
    'Sorano': 1664,
    'Sovicille': 1664,
    'Spianate': 1664,
    'Spicchio-Sovigliana': 1664,
    'Stabbia': 1664,
    'Staggia': 1664,
    'Stazione': 1664,
    'Stazione Masotti': 1664,
    'Stia': 1664,
    'Stiava': 1664,
    'Strada': 1664,
    'Strada in Chianti': 1664,
    'Strettoia': 1664,
    'Subbiano': 1664,
    'Suvereto': 1664,
    'Talla': 1664,
    'Tavarnelle Val di Pesa': 1664,
    'Tavarnuzze': 1664,
    'Taverne D  Arbia': 1664,
    'Tegoleto': 1664,
    'Terontola': 1664,
    'Terranuova Bracciolini': 1664,
    'Terrarossa': 1664,
    'Terricciola': 1664,
    'Torre del Lago Puccini': 1664,
    'Torrenieri': 1664,
    'Torrita di Siena': 1664,
    'Treggiaia': 1664,
    'Trequanda': 1664,
    'Tresana': 1664,
    'Uggia-Pazzera-Bizzarrino': 1664,
    'Uliveto Terme': 1664,
    'Uzzano': 1664,
    'Vacchereccia': 1664,
    'Vada': 1664,
    'Vaglia': 1664,
    'Vaiano': 1664,
    'Val di Cava': 1664,
    'Valdottavo': 1664,
    'Valenzatico': 1664,
    'Vecchiano-Nodica': 1664,
    'Venturina': 1664,
    'Vernio': 1664,
    'Vescovado': 1664,
    'Viareggio': 1664,
    'Vicarello': 1664,
    'Vicchio': 1664,
    'Viciomaggio': 1664,
    'Vicopisano': 1664,
    'Vignale Riotorto': 1664,
    'Vignole-Olmi': 1664,
    'Villa Basilica': 1664,
    'Villa Collemandina': 1664,
    'Villafranca in Lunigiana': 1664,
    'Vinci': 1664,
    'Volterra': 1664,
    'Zeri': 1664,
    'Acquasparta': 1683,
    'Allerona': 1683,
    'Alviano': 1683,
    'Amelia': 1683,
    'Arrone': 1683,
    'Assisi': 1683,
    'Attigliano': 1683,
    'Avigliano Umbro': 1683,
    'Baschi': 1683,
    'Bastardo': 1683,
    'Bastia umbra': 1683,
    'Bettona': 1683,
    'Bevagna': 1683,
    'Bruna': 1683,
    'Calvi dell  Umbria': 1683,
    'Campello sul Clitunno': 1683,
    'Cannara': 1683,
    'Capitan Loreto': 1683,
    'Casa del Diavolo': 1683,
    'Cascia': 1683,
    'Castel Giorgio': 1683,
    'Castel Ritaldi': 1683,
    'Castel Viscardo': 1683,
    'Casteltodino': 1683,
    'Castiglione del Lago': 1683,
    'Cerbara': 1683,
    'Cerreto di Spoleto': 1683,
    'Chiugiana-La Commenda': 1683,
    'Ciconia': 1683,
    'Cipolleto': 1683,
    'Citerna': 1683,
    'Città della Pieve': 1683,
    'Città di Castello': 1683,
    'Collazzone': 1683,
    'Collepepe': 1683,
    'Colombella': 1683,
    'Corciano': 1683,
    'Costacciaro': 1683,
    'Costano': 1683,
    'Deruta': 1683,
    'Fabro': 1683,
    'Fabro Scalo': 1683,
    'Ferentillo': 1683,
    'Ficulle': 1683,
    'Foligno': 1683,
    'Fontignano': 1683,
    'Fornole': 1683,
    'Fossato di Vico': 1683,
    'Fratta Todina': 1683,
    'Giano dell  Umbria': 1683,
    'Giove': 1683,
    'Gualdo Cattaneo': 1683,
    'Gualdo Tadino': 1683,
    'Guardea': 1683,
    'Gubbio': 1683,
    'Lacugnano': 1683,
    'Lisciano Niccone': 1683,
    'Lugnano in Teverina': 1683,
    'Magione': 1683,
    'Mantignana': 1683,
    'Marsciano': 1683,
    'Massa Martana': 1683,
    'Meggiano': 1683,
    'Monte Castello di Vibio': 1683,
    'Monte Grimano': 1683,
    'Monte Santa Maria Tiberina': 1683,
    'Montecastrilli': 1683,
    'Montecchio': 1683,
    'Montefalco': 1683,
    'Montefranco': 1683,
    'Montegabbione': 1683,
    'Monteleone d  Orvieto': 1683,
    'Monteleone di Spoleto': 1683,
    'Montone': 1683,
    'Narni': 1683,
    'Narni Scalo': 1683,
    'Nocera Umbra': 1683,
    'Norcia': 1683,
    'Orvieto': 1683,
    'Orvieto Scalo': 1683,
    'Oscano': 1683,
    'Ospedalicchio': 1683,
    'Osteria del Gatto': 1683,
    'Otricoli': 1683,
    'Paciano': 1683,
    'Padule-San Marco': 1683,
    'Palazzo': 1683,
    'Panicale': 1683,
    'Pantalla': 1683,
    'Parrano': 1683,
    'Passaggio': 1683,
    'Passignano sul Trasimeno': 1683,
    'Penna in Teverina': 1683,
    'Perugia': 1683,
    'Petrignano': 1683,
    'Piegaro': 1683,
    'Pietralunga': 1683,
    'Pila': 1683,
    'Pistrino': 1683,
    'Poggiodomo': 1683,
    'Polino': 1683,
    'Ponte Felcino': 1683,
    'Ponte Pattoli': 1683,
    'Ponterio-Pian di Porto': 1683,
    'Porano': 1683,
    'Pozzuolo': 1683,
    'Preci': 1683,
    'Provincia di Perugia': 1683,
    'Provincia di Terni': 1683,
    'Rivotorto': 1683,
    'San Gemini': 1683,
    'San Giacomo': 1683,
    'San Giustino': 1683,
    'San Martino in Campo': 1683,
    'San Martino in Trignano': 1683,
    'San Niccolò di Celle': 1683,
    'San Secondo': 1683,
    'San Terenziano': 1683,
    'San Venanzo': 1683,
    'Sant  Anatolia di Narco': 1683,
    'Santa Maria degli Angeli': 1683,
    'Scheggia': 1683,
    'Scheggino': 1683,
    'Selci-Lama': 1683,
    'Sellano': 1683,
    'Semonte-Casamorcia': 1683,
    'Sferracavallo': 1683,
    'Sigillo': 1683,
    'Spello': 1683,
    'Spoleto': 1683,
    'Stazione di Allerona': 1683,
    'Stazione di Padule': 1683,
    'Stroncone': 1683,
    'Tavernelle': 1683,
    'Terni': 1683,
    'Todi': 1683,
    'Torgiano': 1683,
    'Trestina': 1683,
    'Trevi': 1683,
    'Tuoro sul Trasimeno': 1683,
    'Umbertide': 1683,
    'Valfabbrica': 1683,
    'Valtopina': 1683,
    'Via Lippia': 1683,
    'Abano Terme': 1753,
    'Abbazia Pisani': 1753,
    'Adria': 1753,
    'Affi': 1753,
    'Agna': 1753,
    'Agordo': 1753,
    'Agugliaro': 1753,
    'Alano di Piave': 1753,
    'Albaredo': 1753,
    'Albaredo d  Adige': 1753,
    'Albettone': 1753,
    'Albignasego': 1753,
    'Alleghe': 1753,
    'Alonte': 1753,
    'Alpo': 1753,
    'Altavilla Vicentina': 1753,
    'Altissimo': 1753,
    'Altivole': 1753,
    'Angiari': 1753,
    'Anguillara Veneta': 1753,
    'Annone Veneto': 1753,
    'Arbizzano-Santa Maria': 1753,
    'Arcade': 1753,
    'Arcole': 1753,
    'Arcugnano-Torri': 1753,
    'Ariano': 1753,
    'Arino': 1753,
    'Arlesega': 1753,
    'Arquà Petrarca': 1753,
    'Arquà Polesine': 1753,
    'Arre': 1753,
    'Arsiè': 1753,
    'Arsiero': 1753,
    'Arzergrande': 1753,
    'Arzignano': 1753,
    'Asiago': 1753,
    'Asigliano Veneto': 1753,
    'Asolo': 1753,
    'Asparetto': 1753,
    'Auronzo': 1753,
    'Badia Calavena': 1753,
    'Badia Polesine': 1753,
    'Badoere': 1753,
    'Bagnoli di Sopra': 1753,
    'Bagnolo': 1753,
    'Bagnolo di Po': 1753,
    'Ballò': 1753,
    'Baone': 1753,
    'Barbarano Vicentino': 1753,
    'Barbisano': 1753,
    'Barbona': 1753,
    'Bardolino': 1753,
    'Baruchella': 1753,
    'Bassano del Grappa': 1753,
    'Bassone': 1753,
    'Bastia': 1753,
    'Battaglia Terme': 1753,
    'Beccacivetta-Azzano': 1753,
    'Belfiore': 1753,
    'Belluno': 1753,
    'Belvedere': 1753,
    'Bergantino': 1753,
    'Bertipaglia': 1753,
    'Bevilacqua': 1753,
    'Biancade': 1753,
    'Bibano': 1753,
    'Bibione': 1753,
    'Bigolino': 1753,
    'Boara Pisani': 1753,
    'Bojon-Lova': 1753,
    'Bolzano Vicentino': 1753,
    'Bonavicina': 1753,
    'Bonavigo': 1753,
    'Bonferraro': 1753,
    'Borca': 1753,
    'Borgoricco-San Michele delle Badesse-Sant  Eufemia': 1753,
    'Borso del Grappa': 1753,
    'Bortolot': 1753,
    'Bosaro': 1753,
    'Boschi Sant  Anna': 1753,
    'Bosco': 1753,
    'Bosco Chiesanuova': 1753,
    'Boscochiaro': 1753,
    'Bottrighe': 1753,
    'Bovolenta': 1753,
    'Bovolone': 1753,
    'Braglia': 1753,
    'Breda': 1753,
    'Breganze': 1753,
    'Brendola': 1753,
    'Brentino Belluno': 1753,
    'Brenzone': 1753,
    'Bressanvido': 1753,
    'Brogliano': 1753,
    'Brognoligo-Costalunga': 1753,
    'Brugine': 1753,
    'Burano': 1753,
    'Buso': 1753,
    'Bussolengo': 1753,
    'Buttapietra': 1753,
    'Ca   degli Oppi': 1753,
    'Ca   Rainati': 1753,
    'Ca   Savio': 1753,
    'Ca   Tiepolo': 1753,
    'Cadoneghe': 1753,
    'Caerano di San Marco': 1753,
    'Calalzo di Cadore': 1753,
    'Caldierino-Rota': 1753,
    'Caldiero': 1753,
    'Caldogno-Rettorgole-Cresole': 1753,
    'Calmasino': 1753,
    'Caltana': 1753,
    'Calto': 1753,
    'Caltrano': 1753,
    'Calvene': 1753,
    'Camalò': 1753,
    'Camisano Vicentino': 1753,
    'Campagna Lupia': 1753,
    'Campagnola': 1753,
    'Campalto': 1753,
    'Campese': 1753,
    'Campiglia dei Berici': 1753,
    'Campo San Martino': 1753,
    'Campodarsego': 1753,
    'Campodoro': 1753,
    'Campolongo Maggiore Liettoli': 1753,
    'Campolongo sul Brenta': 1753,
    'Camponogara': 1753,
    'Camposampiero': 1753,
    'Canale d  Agordo': 1753,
    'Canaro': 1753,
    'Canda': 1753,
    'Candelù': 1753,
    'Candiana': 1753,
    'Candide': 1753,
    'Caniezza': 1753,
    'Canove di Roana': 1753,
    'Caorle': 1753,
    'Capitello': 1753,
    'Cappella Maggiore': 1753,
    'Caprino Veronese': 1753,
    'Carbonera': 1753,
    'Carceri': 1753,
    'Carmignano di Brenta': 1753,
    'Carpesica': 1753,
    'Carrara San Giorgio': 1753,
    'Carrè': 1753,
    'Cartigliano': 1753,
    'Cartura': 1753,
    'Casacorba': 1753,
    'Casale di Scodosia': 1753,
    'Casale sul Sile': 1753,
    'Casaleone': 1753,
    'Casalserugo': 1753,
    'Caselle': 1753,
    'Casier': 1753,
    'Casoni': 1753,
    'Cassola': 1753,
    'Castagnaro': 1753,
    'Castagnole': 1753,
    'Castelbaldo': 1753,
    'Castelcucco': 1753,
    'Castelfranco Veneto': 1753,
    'Castelgomberto': 1753,
    'Castelguglielmo': 1753,
    'Castello di Godego': 1753,
    'Castelmassa': 1753,
    'Castelminio': 1753,
    'Castelnovo': 1753,
    'Castelnovo Bariano': 1753,
    'Castelnuovo del Garda': 1753,
    'Castion': 1753,
    'Cavajon Veronese': 1753,
    'Cavalcaselle': 1753,
    'Cavallino': 1753,
    'Cavarzere': 1753,
    'Cavaso del Tomba': 1753,
    'Cavino': 1753,
    'Cazzago-Ex Polo': 1753,
    'Cazzano di Tramigna': 1753,
    'Ceggia': 1753,
    'Celat-San Tomaso Agordino': 1753,
    'Cellore': 1753,
    'Cencenighe Agordino': 1753,
    'Cendon': 1753,
    'Ceneselli': 1753,
    'Centrale': 1753,
    'Cerea': 1753,
    'Cereda-Cozza Cornedo': 1753,
    'Ceregnano': 1753,
    'Cerro Veronese': 1753,
    'Cervarese Santa Croce': 1753,
    'Cesarolo': 1753,
    'Cesiomaggiore': 1753,
    'Cessalto': 1753,
    'Chiampo': 1753,
    'Chiarano': 1753,
    'Chioggia': 1753,
    'Chiuppano': 1753,
    'Ciano': 1753,
    'Cibiana': 1753,
    'Cimadolmo': 1753,
    'Cinto Caomaggiore': 1753,
    'Cismon del Grappa': 1753,
    'Cison di Valmarino': 1753,
    'Cittadella': 1753,
    'Codevigo': 1753,
    'Codiverno': 1753,
    'Codognè': 1753,
    'Cogollo del Cengio': 1753,
    'Col San Martino': 1753,
    'Colà': 1753,
    'Colle Santa Lucia': 1753,
    'Colle Umberto': 1753,
    'Cologna Veneta': 1753,
    'Colognola ai Colli': 1753,
    'Conco': 1753,
    'Concordia Sagittaria': 1753,
    'Conegliano': 1753,
    'Conscio': 1753,
    'Conselve': 1753,
    'Corbanese': 1753,
    'Corbola': 1753,
    'Corbolone': 1753,
    'Cordignano': 1753,
    'Cornedo Vicentino': 1753,
    'Cornuda': 1753,
    'Correzzola': 1753,
    'Corrubbio': 1753,
    'Corte': 1753,
    'Cortina d  Ampezzo': 1753,
    'Cosniga-Zoppè': 1753,
    'Costa di Rovigo': 1753,
    'Costabissara': 1753,
    'Costermano': 1753,
    'Covolo-Levada': 1753,
    'Creazzo': 1753,
    'Crepaldo': 1753,
    'Crespadoro': 1753,
    'Crespano del Grappa': 1753,
    'Crespino': 1753,
    'Crispi Cavour': 1753,
    'Crocetta del Montello': 1753,
    'Crocetta-Nogarè': 1753,
    'Curtarolo': 1753,
    'Danta': 1753,
    'Dese': 1753,
    'Dolcè': 1753,
    'Dolo': 1753,
    'Domegge di Cadore': 1753,
    'Don': 1753,
    'Donzella': 1753,
    'Dossobuono': 1753,
    'Dosson': 1753,
    'Due Carrare': 1753,
    'Dueville': 1753,
    'Enego': 1753,
    'Eraclea': 1753,
    'Erbè': 1753,
    'Erbezzo': 1753,
    'Este': 1753,
    'Falcade Alto': 1753,
    'Falzè di Piave': 1753,
    'Falzè-Signoressa': 1753,
    'Fane': 1753,
    'Fanzolo': 1753,
    'Fara Vicentino': 1753,
    'Farra d  Alpago': 1753,
    'Farra di Soligo': 1753,
    'Feltre': 1753,
    'Ferrara di Monte Baldo': 1753,
    'Ficarolo': 1753,
    'Fiesso': 1753,
    'Fiesso d  Artico': 1753,
    'Fiesso Umbertiano': 1753,
    'Follina': 1753,
    'Fontanafredda': 1753,
    'Fontanelle': 1753,
    'Fontaniva': 1753,
    'Fonzaso': 1753,
    'Forette': 1753,
    'Fornaci': 1753,
    'Fornase': 1753,
    'Forno di Zoldo': 1753,
    'Fossalta di Piave': 1753,
    'Fossalta di Portogruaro': 1753,
    'Fossalunga': 1753,
    'Fossò': 1753,
    'Foza': 1753,
    'Francenigo': 1753,
    'Frassinelle Polesine': 1753,
    'Fratta Polesine': 1753,
    'Fregona': 1753,
    'Frescada': 1753,
    'Fumane': 1753,
    'Fusine': 1753,
    'Gaiarine': 1753,
    'Gaiba': 1753,
    'Galliera Veneta': 1753,
    'Gallio': 1753,
    'Galta': 1753,
    'Galzignano': 1753,
    'Gambellara': 1753,
    'Gambugliano': 1753,
    'Garda': 1753,
    'Gavello': 1753,
    'Gazzo': 1753,
    'Gazzolo-Volpino': 1753,
    'Giacciano con Baruchella': 1753,
    'Giavenale': 1753,
    'Giavera del Montello': 1753,
    'Giudecca': 1753,
    'Godega': 1753,
    'Gorgo al Monticano': 1753,
    'Gorgo della Chiesa': 1753,
    'Gosaldo': 1753,
    'Grancona': 1753,
    'Grantorto': 1753,
    'Granze': 1753,
    'Grezzana': 1753,
    'Grignano Polesine': 1753,
    'Grisignano di Zocco': 1753,
    'Gruaro': 1753,
    'Gruaro-Bagnara': 1753,
    'Grumolo delle Abbadesse': 1753,
    'Grumolo Pedemonte': 1753,
    'Guarda Veneta': 1753,
    'Iesolo': 1753,
    'Illasi': 1753,
    'Isola della Scala': 1753,
    'Isola Rizza': 1753,
    'Isola Vicentina': 1753,
    'Istrana': 1753,
    'La Salute di Livenza': 1753,
    'La Valle Agordina': 1753,
    'Laghi': 1753,
    'Lama Pezzoli': 1753,
    'Lamon': 1753,
    'Lamosano': 1753,
    'Lancenigo-Villorba': 1753,
    'Lastebasse': 1753,
    'Lavagno': 1753,
    'Lazise': 1753,
    'Legnago': 1753,
    'Legnaro': 1753,
    'Lendinara': 1753,
    'Lentiai': 1753,
    'Levada': 1753,
    'Lido': 1753,
    'Lido di Jesolo': 1753,
    'Limana': 1753,
    'Limena': 1753,
    'Lisiera': 1753,
    'Livinallongo del Col di Lana': 1753,
    'Locara': 1753,
    'Longare': 1753,
    'Longarone': 1753,
    'Longhi': 1753,
    'Lonigo': 1753,
    'Loreggia': 1753,
    'Loreggiola': 1753,
    'Lorenzago di Cadore': 1753,
    'Loreo': 1753,
    'Loria Bessica': 1753,
    'Lozzo Atestino': 1753,
    'Lozzo di Cadore': 1753,
    'Lugagnano': 1753,
    'Lughignano': 1753,
    'Lugo di Vicenza': 1753,
    'Lugugnana': 1753,
    'Lumignano': 1753,
    'Lusia': 1753,
    'Lusiana': 1753,
    'Lutrano': 1753,
    'Maccacari': 1753,
    'Maerne': 1753,
    'Magugnano': 1753,
    'Malcesine': 1753,
    'Malo': 1753,
    'Mandriola-Sant  Agostino': 1753,
    'Mansuè': 1753,
    'Marano': 1753,
    'Marano di Valpolicella': 1753,
    'Marano Vicentino': 1753,
    'Marchesino-Bovo': 1753,
    'Marcon-Gaggio-Colmello': 1753,
    'Mardimago': 1753,
    'Mareno di Piave': 1753,
    'Marola': 1753,
    'Marostica': 1753,
    'Martellago': 1753,
    'Maser': 1753,
    'Maserà di Padova': 1753,
    'Maserada sul Piave': 1753,
    'Masi': 1753,
    'Mason Vicentino': 1753,
    'Massanzago-Ca   Baglioni-San Dono': 1753,
    'Meduna di Livenza': 1753,
    'Megliadino San Fidenzio': 1753,
    'Megliadino San Vitale': 1753,
    'Mejaniga': 1753,
    'Mel': 1753,
    'Melara': 1753,
    'Meledo': 1753,
    'Meolo': 1753,
    'Merlara': 1753,
    'Mestre': 1753,
    'Mestrino': 1753,
    'Mezzane di Sotto': 1753,
    'Miane': 1753,
    'Mignagola': 1753,
    'Minerbe': 1753,
    'Mira Taglio': 1753,
    'Mirano': 1753,
    'Mogliano Veneto': 1753,
    'Molino-Mozzi-Bittarelli': 1753,
    'Monastier di Treviso': 1753,
    'Monfumo': 1753,
    'Moniego': 1753,
    'Monselice': 1753,
    'Montagnana': 1753,
    'Monte di Malo': 1753,
    'Montebello Vicentino': 1753,
    'Montebelluna': 1753,
    'Montecchia di Crosara': 1753,
    'Montecchio Maggiore-Alte Ceccato': 1753,
    'Montecchio Precalcino': 1753,
    'Monteforte d  Alpone': 1753,
    'Montegalda': 1753,
    'Montegaldella': 1753,
    'Montegrotto Terme': 1753,
    'Montemerlo': 1753,
    'Monteviale': 1753,
    'Monticelli-Fontana': 1753,
    'Monticello Conte Otto': 1753,
    'Monticello di Fara': 1753,
    'Montorio': 1753,
    'Montorso Vicentino': 1753,
    'Moriago della Battaglia': 1753,
    'Mosnigo': 1753,
    'Mossano': 1753,
    'Motta': 1753,
    'Motta di Livenza': 1753,
    'Mozzecane': 1753,
    'Murano': 1753,
    'Mure': 1753,
    'Musano': 1753,
    'Musestre': 1753,
    'Musile di Piave': 1753,
    'Mussolente': 1753,
    'Negrar': 1753,
    'Nervesa della Battaglia': 1753,
    'Noale': 1753,
    'Nogara': 1753,
    'Nogarole Rocca': 1753,
    'Nogarole Vicentino': 1753,
    'Nove': 1753,
    'Noventa': 1753,
    'Noventa di Piave': 1753,
    'Noventa Vicentina': 1753,
    'Novoledo': 1753,
    'Occhiobello': 1753,
    'Oderzo': 1753,
    'Oltre Brenta': 1753,
    'Onè': 1753,
    'Onigo': 1753,
    'Oppeano': 1753,
    'Orgiano': 1753,
    'Ormelle': 1753,
    'Orsago': 1753,
    'Ospedaletto': 1753,
    'Ospedaletto Euganeo': 1753,
    'Ospitale di Cadore': 1753,
    'Padernello': 1753,
    'Paderno': 1753,
    'Paderno del Grappa': 1753,
    'Padova': 1753,
    'Paese': 1753,
    'Palù': 1753,
    'Papozze': 1753,
    'Parona': 1753,
    'Passarella': 1753,
    'Pastrengo': 1753,
    'Pedavena': 1753,
    'Pedemonte': 1753,
    'Pederiva': 1753,
    'Pederobba': 1753,
    'Pegolotte': 1753,
    'Pellestrina': 1753,
    'Perarolo di Cadore': 1753,
    'Pernumia': 1753,
    'Pero': 1753,
    'Pescantina': 1753,
    'Peschiera del Garda': 1753,
    'Peseggia-Gardigiano': 1753,
    'Pettorazza Grimani': 1753,
    'Pezzan': 1753,
    'Piacenza d  Adige': 1753,
    'Pianezze': 1753,
    'Pianiga': 1753,
    'Piavon': 1753,
    'Piazza': 1753,
    'Piazzola sul Brenta': 1753,
    'Pie   Falcade': 1753,
    'Pieve': 1753,
    'Pieve d  Alpago': 1753,
    'Pieve di Cadore': 1753,
    'Pieve di Soligo': 1753,
    'Pincara': 1753,
    'Piombino Dese': 1753,
    'Pionca': 1753,
    'Piove di Sacco-Piovega': 1753,
    'Piovene Rocchette': 1753,
    'Poiana Maggiore': 1753,
    'Poianella': 1753,
    'Poiano': 1753,
    'Polesella': 1753,
    'Polverara': 1753,
    'Ponso': 1753,
    'Ponte di Barbarano': 1753,
    'Ponte di Castegnero': 1753,
    'Ponte di Nanto': 1753,
    'Ponte di Piave': 1753,
    'Ponte nelle Alpi': 1753,
    'Ponte nelle Alpi-Polpet': 1753,
    'Ponte San Nicolò': 1753,
    'Pontecchio Polesine': 1753,
    'Pontelongo': 1753,
    'Ponzano': 1753,
    'Porcellengo': 1753,
    'Porto Tolle': 1753,
    'Porto Viro': 1753,
    'Portobuffolè': 1753,
    'Portogruaro': 1753,
    'Posina': 1753,
    'Possagno': 1753,
    'Postioma': 1753,
    'Pove del Grappa': 1753,
    'Povegliano': 1753,
    'Povegliano Veronese': 1753,
    'Pozzoleone': 1753,
    'Pozzonovo': 1753,
    'Pradelle': 1753,
    'Pramaggiore': 1753,
    'Pramaggiore Blessaglia': 1753,
    'Preara-Moraro-Levà Nord': 1753,
    'Preganziol': 1753,
    'Pressana': 1753,
    'Priula-Colfosco': 1753,
    'Provincia di Belluno': 1753,
    'Provincia di Padova': 1753,
    'Provincia di Rovigo': 1753,
    'Provincia di Treviso': 1753,
    'Provincia di Venezia': 1753,
    'Provincia di Verona': 1753,
    'Provincia di Vicenza': 1753,
    'Puos d  Alpago': 1753,
    'Quaderni': 1753,
    'Quarto d  Altino': 1753,
    'Quero': 1753,
    'Quinto di Treviso': 1753,
    'Quinto Vicentino': 1753,
    'Raldon': 1753,
    'Ramon': 1753,
    'Recoaro Terme': 1753,
    'Refrontolo': 1753,
    'Resana': 1753,
    'Revine': 1753,
    'Riese Pio X': 1753,
    'Rivalta': 1753,
    'Rivamonte Agordino': 1753,
    'Rivoli Veronese': 1753,
    'Roana': 1753,
    'Robegano': 1753,
    'Rocca Pietore': 1753,
    'Romano d  Ezzelino': 1753,
    'Roncà': 1753,
    'Roncade': 1753,
    'Roncanova': 1753,
    'Ronchi di Campanile': 1753,
    'Ronco All  Adige': 1753,
    'Rosà': 1753,
    'Rosegaferro': 1753,
    'Rosolina': 1753,
    'Rossano Veneto': 1753,
    'Rottanova': 1753,
    'Rotzo': 1753,
    'Roverchiara': 1753,
    'Roverè Veronese': 1753,
    'Roveredo di Guà': 1753,
    'Rovigo': 1753,
    'Rubano': 1753,
    'Saccolongo': 1753,
    'Sachet': 1753,
    'Salara': 1753,
    'Salcedo': 1753,
    'Saletto': 1753,
    'Saletto-San Bartolomeo': 1753,
    'Salgareda': 1753,
    'Salionze': 1753,
    'Salizzole': 1753,
    'Salvatronda': 1753,
    'Salzano': 1753,
    'Sambruson': 1753,
    'Sambughe': 1753,
    'San Bellino': 1753,
    'San Biagio': 1753,
    'San Biagio di Callalta': 1753,
    'San Bonifacio': 1753,
    'San Donà di Piave': 1753,
    'San Fidenzio': 1753,
    'San Fior di Sopra': 1753,
    'San Floriano': 1753,
    'San Floriano-Olmi': 1753,
    'San Germano dei Berici': 1753,
    'San Giorgio al Tagliamento-Pozzi': 1753,
    'San Giorgio delle Pertiche': 1753,
    'San Giorgio di Livenza': 1753,
    'San Giorgio in Bosco': 1753,
    'San Giorgio in Salici': 1753,
    'San Giovanni Ilarione': 1753,
    'San Giovanni Lupatoto': 1753,
    'San Gregorio': 1753,
    'San Gregorio nelle Alpi': 1753,
    'San Liberale': 1753,
    'San Martino': 1753,
    'San Martino Buon Albergo': 1753,
    'San Martino di Lupari': 1753,
    'San Martino di Venezze': 1753,
    'San Mauro di Saline': 1753,
    'San Michele al Tagliamento': 1753,
    'San Michele di Piave': 1753,
    'San Nazario': 1753,
    'San Nicolò Comelico': 1753,
    'San Pietro': 1753,
    'San Pietro di Cadore': 1753,
    'San Pietro di Feletto': 1753,
    'San Pietro di Morubio': 1753,
    'San Pietro in Cariano': 1753,
    'San Pietro in Gu': 1753,
    'San Pietro in Volta': 1753,
    'San Pietro Mussolino': 1753,
    'San Pietro Valdastico': 1753,
    'San Pietro Viminario': 1753,
    'San Polo di Piave': 1753,
    'San Quirico': 1753,
    'San Stino di Livenza': 1753,
    'San Vendemiano': 1753,
    'San Vendemiano-Fossamerlo': 1753,
    'San Vito': 1753,
    'San Vito al Mantico': 1753,
    'San Vito di Leguzzano': 1753,
    'San Zeno': 1753,
    'San Zeno di Montagna': 1753,
    'San Zeno-San Giuseppe': 1753,
    'San Zenone degli Ezzelini': 1753,
    'Sandrà': 1753,
    'Sandrigo': 1753,
    'Sanguinetto': 1753,
    'Sant  Alberto': 1753,
    'Sant  Ambrogio di Valpollicella': 1753,
    'Sant  Andrea': 1753,
    'Sant  Angelo': 1753,
    'Sant  Angelo di Piove di Sacco': 1753,
    'Sant  Anna': 1753,
    'Sant  Anna d  Alfaedo': 1753,
    'Sant  Apollinare': 1753,
    'Sant  Elena': 1753,
    'Santa Cristina': 1753,
    'Santa Giustina': 1753,
    'Santa Giustina in Colle': 1753,
    'Santa Lucia di Piave': 1753,
    'Santa Margherita d  Adige': 1753,
    'Santa Maria': 1753,
    'Santa Maria di Non': 1753,
    'Santa Maria di Sala': 1753,
    'Santa Maria Maddalena': 1753,
    'Santandrà': 1753,
    'Santo Stefano di Cadore': 1753,
    'Santo Stefano-Bonaldo': 1753,
    'Santorso': 1753,
    'Saonara': 1753,
    'Sarcedo': 1753,
    'Sarego': 1753,
    'Sarmede': 1753,
    'Sarmeola': 1753,
    'Scaltenigo': 1753,
    'Scardovari': 1753,
    'Schiavon': 1753,
    'Schio': 1753,
    'Scomigo': 1753,
    'Scorzè': 1753,
    'Sedico': 1753,
    'Segusino': 1753,
    'Selva del Montello': 1753,
    'Selva di Cadore': 1753,
    'Selva di Progno': 1753,
    'Selvazzano Dentro': 1753,
    'Seren del Grappa': 1753,
    'Sernaglia della Battaglia': 1753,
    'Servo': 1753,
    'Settimo': 1753,
    'Silea': 1753,
    'Soave': 1753,
    'Soccher-Paiane-Casan-Arsie': 1753,
    'Solagna': 1753,
    'Solesino': 1753,
    'Sommacampagna': 1753,
    'Sona': 1753,
    'Sorgà': 1753,
    'Sospirolo': 1753,
    'Sossano': 1753,
    'Soverzene': 1753,
    'Sovizzo': 1753,
    'Sovramonte': 1753,
    'Spinea-Orgnano': 1753,
    'Spinimbecco': 1753,
    'Spresiano': 1753,
    'Stallavena-Lugo': 1753,
    'Stanghella': 1753,
    'Stienta': 1753,
    'Stigliano': 1753,
    'Stra': 1753,
    'Strà-Montanara-Pieve': 1753,
    'Summaga': 1753,
    'Susegana': 1753,
    'Taggì': 1753,
    'Taglio': 1753,
    'Taglio di Po': 1753,
    'Taibon Agordino': 1753,
    'Talponada': 1753,
    'Tambre': 1753,
    'Tarzo': 1753,
    'Tavernelle': 1753,
    'Tavo': 1753,
    'Teglio Veneto': 1753,
    'Tencarola': 1753,
    'Terradura': 1753,
    'Terrassa Padovana': 1753,
    'Terrazzo': 1753,
    'Terrossa': 1753,
    'Tessera': 1753,
    'Tezze': 1753,
    'Thiene': 1753,
    'Tombelle': 1753,
    'Tombolo': 1753,
    'Tonezza del Cimone': 1753,
    'Torre di Mosto': 1753,
    'Torrebelvicino': 1753,
    'Torreglia': 1753,
    'Torreselle': 1753,
    'Torri del Benaco': 1753,
    'Torri di Quartesolo': 1753,
    'Trebaseleghe': 1753,
    'Trecenta': 1753,
    'Tregnago': 1753,
    'Tremignon': 1753,
    'Treponti': 1753,
    'Trevenzuolo': 1753,
    'Trevignano': 1753,
    'Treviso': 1753,
    'Tribano': 1753,
    'Trichiana': 1753,
    'Trissino': 1753,
    'Trivignano': 1753,
    'Urbana': 1753,
    'Vago': 1753,
    'Valdagno': 1753,
    'Valdobbiadene': 1753,
    'Valeggio sul Mincio': 1753,
    'Valgatara': 1753,
    'Vallà': 1753,
    'Vallada Agordina': 1753,
    'Valle di Cadore': 1753,
    'Vallese': 1753,
    'Valli': 1753,
    'Valli del Pasubio': 1753,
    'Vallonga': 1753,
    'Valstagna': 1753,
    'Varago': 1753,
    'Vas': 1753,
    'Vazzola': 1753,
    'Vedelago': 1753,
    'Veggiano': 1753,
    'Velo': 1753,
    'Velo d  Astico': 1753,
    'Velo Veronese': 1753,
    'Venegazzù': 1753,
    'Venice': 1753,
    'Verona': 1753,
    'Veronella': 1753,
    'Vescovana': 1753,
    'Vestenanova': 1753,
    'Veternigo': 1753,
    'Vicenza': 1753,
    'Vidor': 1753,
    'Vigardolo': 1753,
    'Vigasio': 1753,
    'Vighizzolo d  Este': 1753,
    'Vigo di Cadore': 1753,
    'Vigodarzere': 1753,
    'Vigonovo': 1753,
    'Vigonza': 1753,
    'Villa Bartolomea': 1753,
    'Villa d  Asolo': 1753,
    'Villa del Conte': 1753,
    'Villa Estense': 1753,
    'Villabruna-Umin': 1753,
    'Villadose': 1753,
    'Villafranca di Verona': 1753,
    'Villafranca Padovana': 1753,
    'Villaga': 1753,
    'Villaganzerla': 1753,
    'Villaggio Montegrappa': 1753,
    'Villaguattera': 1753,
    'Villamarzana': 1753,
    'Villanova': 1753,
    'Villanova del Ghebbo Canton': 1753,
    'Villanova Marchesana': 1753,
    'Villatora': 1753,
    'Villaverla': 1753,
    'Vittorio Veneto': 1753,
    'Vò': 1753,
    'Vodo': 1753,
    'Volargne': 1753,
    'Volpago del Montello': 1753,
    'Voltago': 1753,
    'Voltago Agordino': 1753,
    'Zanè': 1753,
    'Zelarino': 1753,
    'Zenson di Piave': 1753,
    'Zermeghedo': 1753,
    'Zero Branco': 1753,
    'Zevio': 1753,
    'Zoppè di Cadore': 1753,
    'Zovencedo': 1753,
    'Zugliano': 1753,
    'Aenon Town': 3753,
    'Alley': 3753,
    'Alston': 3753,
    'Ashley': 3753,
    'Banks': 3753,
    'Beckford Kraal': 3753,
    'Brandon Hill': 3753,
    'Brixton Hill': 3753,
    'Bucknor': 3753,
    'Bucks Common': 3753,
    'Bushy Park': 3753,
    'Chapelton': 3753,
    'Chateau': 3753,
    'Cockpit': 3753,
    'Coffee Piece': 3753,
    'Colonels Ridge': 3753,
    'Coxswain': 3753,
    'Crofts Hill': 3753,
    'Crooked River': 3753,
    'Cumberland': 3753,
    'Curatoe Hill': 3753,
    'Dawkins': 3753,
    'Effortville': 3753,
    'Four Paths': 3753,
    'Frankfield': 3753,
    'Freetown': 3753,
    'Gimme-me-bit': 3753,
    'Glenmuir': 3753,
    'Grantham': 3753,
    'Gravel Hill': 3753,
    'Hayes': 3753,
    'Hazard': 3753,
    'Inverness': 3753,
    'James Hill': 3753,
    'Johns Hall': 3753,
    'Kellits': 3753,
    'Limit': 3753,
    'Lionel Town': 3753,
    'Longville Park': 3753,
    'Longwood': 3753,
    'May Pen': 3753,
    'May Pen Proper': 3753,
    'Milk River': 3753,
    'Mineral Heights': 3753,
    'Mitchell Town': 3753,
    'Moores': 3753,
    'Morgans Forest': 3753,
    'Morgans Pass': 3753,
    'Mount Airy': 3753,
    'New Denbigh': 3753,
    'New Longsville': 3753,
    'Old Denbigh': 3753,
    'Orange Hill': 3753,
    'Osbourne Store': 3753,
    'Paisley': 3753,
    'Palmers Cross': 3753,
    'Part of Banana Ground': 3753,
    'Part of Douglas Castle': 3753,
    'Part of Kellits': 3753,
    'Peckham': 3753,
    'Pennants': 3753,
    'Pleasant Valley': 3753,
    'Portland Cottage': 3753,
    'Porus': 3753,
    'Race Course': 3753,
    'Richmond Park': 3753,
    'Ritchies': 3753,
    'Rock': 3753,
    'Rock River': 3753,
    'Rocky Point': 3753,
    'Salt River': 3753,
    'Sandy Bay': 3753,
    'Sanguinetti': 3753,
    'Sedgepond': 3753,
    'Silent Hill': 3753,
    'Smithville': 3753,
    'Spaldings': 3753,
    'Summerfield': 3753,
    'Thompson Town': 3753,
    'Tollgate': 3753,
    'Treadlight': 3753,
    'Trout Hall': 3753,
    'Turners': 3753,
    'Water Lane': 3753,
    'Woodhall': 3753,
    'York Town': 3753,
    'Askenish': 3749,
    'Cacoon': 3749,
    'Cacoon Castle': 3749,
    'Cascade': 3749,
    'Cash Hill': 3749,
    'Cauldwell': 3749,
    'Chambers Pen': 3749,
    'Chester Castle': 3749,
    'Copse': 3749,
    'Cousins Cove': 3749,
    'Dias': 3749,
    'Great Valley': 3749,
    'Green Island': 3749,
    'Haddington': 3749,
    'Hopewell': 3749,
    'Jericho': 3749,
    'Kendal': 3749,
    'Kingsvale': 3749,
    'Lances Bay': 3749,
    'Logwood': 3749,
    'Lucea': 3749,
    'Lucea East': 3749,
    'Lucea West': 3749,
    'March Town': 3749,
    'Maryland': 3749,
    'Mount Peto': 3749,
    'Orange Bay': 3749,
    'Pell River': 3749,
    'Pondside': 3749,
    'Ramble': 3749,
    'Rock Spring': 3749,
    'Sandy Bay': 3749,
    'Santoy': 3749,
    'Success': 3749,
    'Woodlands': 3749,
    'Woodsville': 3749,
    'Allman Town': 3748,
    'Bournemouth Gardens': 3748,
    'Campbell Town': 3748,
    'Central Down Town': 3748,
    'D  Aguilar Town/ Rennock Lodge': 3748,
    'Denham Town': 3748,
    'East Down Town': 3748,
    'Fletchers Land': 3748,
    'Franklyn Town': 3748,
    'Hannah Town/ Craig Town': 3748,
    'Johnson Town': 3748,
    'Kingston': 3748,
    'Kingston Gardens': 3748,
    'Manley Meadows': 3748,
    'Newport East': 3748,
    'Newton Square': 3748,
    'Norman Gardens': 3748,
    'Passmore Town': 3748,
    'Port Royal': 3748,
    'Rae Town': 3748,
    'Rollington Town': 3748,
    'Southside': 3748,
    'Springfield': 3748,
    'Tivoli Gardens': 3748,
    'West Down Town': 3748,
    'Alligator Pond': 3754,
    'Asia/Pratville': 3754,
    'Auchtembeddie': 3754,
    'Bellefield': 3754,
    'Bethany': 3754,
    'Blue Mountain': 3754,
    'Bombay': 3754,
    'Butt-Up': 3754,
    'Cedar Grove': 3754,
    'Chantilly': 3754,
    'Christiana': 3754,
    'Chudleigh': 3754,
    'Cobbla': 3754,
    'Coleyville': 3754,
    'Comfort': 3754,
    'Comfort Hall': 3754,
    'Content': 3754,
    'Craig Head': 3754,
    'Cross Keys': 3754,
    'Devon': 3754,
    'Downs': 3754,
    'Ellen Street': 3754,
    'Evergreen': 3754,
    'Fairfield': 3754,
    'French Park': 3754,
    'George  s Valley': 3754,
    'Good Intent': 3754,
    'Greenvale': 3754,
    'Grove Place': 3754,
    'Grove Town': 3754,
    'Harmons': 3754,
    'Harry Watch': 3754,
    'Hatfield': 3754,
    'Heartease': 3754,
    'Hibernia': 3754,
    'Huntley': 3754,
    'Kendal': 3754,
    'Knockpatrick': 3754,
    'Lancaster': 3754,
    'Litchfield': 3754,
    'Maidstone': 3754,
    'Mandeville': 3754,
    'Mandeville Proper': 3754,
    'Marlie Hill': 3754,
    'May Day': 3754,
    'Medina': 3754,
    'Mike Town': 3754,
    'Mile Gully': 3754,
    'New Forest': 3754,
    'New Green': 3754,
    'Newport': 3754,
    'Old England': 3754,
    'Part Of Banana Ground': 3754,
    'Part Of Gutters': 3754,
    'Part of Spaldings': 3754,
    'Pike': 3754,
    'Plowden': 3754,
    'Porus': 3754,
    'Prospect': 3754,
    'Richmond': 3754,
    'Robins Hall': 3754,
    'Rose Hill': 3754,
    'Royal Flat': 3754,
    'Scotts Pass': 3754,
    'Snowdon': 3754,
    'Somerset': 3754,
    'Spring Ground': 3754,
    'Spur Tree': 3754,
    'St. Paul  s': 3754,
    'Swaby  s Hope': 3754,
    'Top Hill': 3754,
    'Victoria Town': 3754,
    'Walderston': 3754,
    'Watham': 3754,
    'Williamsfield': 3754,
    'Balcarres': 3752,
    'Bangor Ridge': 3752,
    'Belvedere': 3752,
    'Black Hill': 3752,
    'Boundbrook': 3752,
    'Breastworks': 3752,
    'Buff Bay': 3752,
    'Bybrook': 3752,
    'Cascade': 3752,
    'Castle Comfort': 3752,
    'Central Port Antonio': 3752,
    'Charles Town': 3752,
    'Claverty Cottage': 3752,
    'Comfort Castle': 3752,
    'Drapers': 3752,
    'Durham': 3752,
    'Fairy Hill': 3752,
    'Fellowship': 3752,
    'Fruitfulvale': 3752,
    'Hectors River': 3752,
    'Hope Bay': 3752,
    'Kensington': 3752,
    'Long Bay': 3752,
    'Long Road': 3752,
    'Manchioneal': 3752,
    'Moore Town': 3752,
    'Mount Pleasant': 3752,
    'Nonsuch': 3752,
    'Norwich': 3752,
    'Orange Bay': 3752,
    'Port Antonio': 3752,
    'Prospect': 3752,
    'Rock Hall': 3752,
    'Sherwood Forest': 3752,
    'Shirley Castle': 3752,
    'Skibo': 3752,
    'Snow Hill': 3752,
    'Spring Hill': 3752,
    'St. Margaret  s Bay': 3752,
    'Swift River': 3752,
    'Tranquility': 3752,
    'Windsor': 3752,
    'Windsor Castle': 3752,
    'Windsor Forest': 3752,
    'Arcadia': 3751,
    'Arlene Gardens': 3751,
    'Arnett Gardens': 3751,
    'August Town': 3751,
    'Barbican': 3751,
    'Beverley Hills': 3751,
    'Bito': 3751,
    'Bloxborough': 3751,
    'Boucher Park': 3751,
    'Brandon Hill': 3751,
    'Bull Bay/ Seven Mile': 3751,
    'Cane River': 3751,
    'Cassia Park': 3751,
    'Cavaliers': 3751,
    'Cherry Gardens': 3751,
    'Cockburn Gardens': 3751,
    'Constant Spring': 3751,
    'Constant Spring Gardens': 3751,
    'Constitution Hill': 3751,
    'Content Gap': 3751,
    'Cooreville Gardens': 3751,
    'Cross Roads': 3751,
    'Dallas': 3751,
    'Delacree Park/ Union Gardens': 3751,
    'Delacree Pen': 3751,
    'Drewsland': 3751,
    'Drumblair': 3751,
    'Duhaney Park': 3751,
    'Eastwood Park Gardens': 3751,
    'Elleston Flats/ Mona Commons': 3751,
    'Ferry': 3751,
    'Forest Hills Gardens': 3751,
    'Forest Hills/ Plantation Height': 3751,
    'Golden Spring': 3751,
    'Gordon Town': 3751,
    'Grants Pen': 3751,
    'Greenwich Town/ Newport West': 3751,
    'Half Way Tree': 3751,
    'Half-Way-Tree': 3751,
    'Harbour View': 3751,
    'Havendale': 3751,
    'Hermitage': 3751,
    'Hope Pastures/ UTECH': 3751,
    'Hope Tavern': 3751,
    'Hughenden': 3751,
    'Irish Town': 3751,
    'Jacks Hill': 3751,
    'Jones Town': 3751,
    'Kencot': 3751,
    'Kintyre': 3751,
    'Lawrence Tavern': 3751,
    'Liguanea': 3751,
    'Majestic Gardens': 3751,
    'Mannings Hill': 3751,
    'Mannings Hill (Rural)': 3751,
    'Marverley': 3751,
    'Maryland': 3751,
    'Mavis Bank': 3751,
    'Maxfield Park': 3751,
    'Meadowbrook': 3751,
    'Meadowbrook Estate': 3751,
    'Molynes Four Roads': 3751,
    'Molynes Gardens': 3751,
    'Mona Heights': 3751,
    'Mount James': 3751,
    'Mountain View Gardens': 3751,
    'Nannyville': 3751,
    'New Haven': 3751,
    'New Kingston': 3751,
    'Norbrook': 3751,
    'Oaklands': 3751,
    'Papine': 3751,
    'Parks Road': 3751,
    'Patrick City': 3751,
    'Pembroke Hall': 3751,
    'Penwood': 3751,
    'Queensborough/ Tunbridge': 3751,
    'Red Hills (Rural)': 3751,
    'Red Hills Gardens': 3751,
    'Red Hills/ Sterling Castle': 3751,
    'Richmond Park': 3751,
    'Riverton City': 3751,
    'Roehampton': 3751,
    'Rose Town': 3751,
    'Seaview Gardens': 3751,
    'Seaward Pen': 3751,
    'Seymour Lands': 3751,
    'St. Peter  s': 3751,
    'Stadium Gardens': 3751,
    'Stony Hill': 3751,
    'Swallowfield': 3751,
    'Temple Hall': 3751,
    'Three Oaks/ Glendale': 3751,
    'Tower Hill': 3751,
    'Trafalgar Park': 3751,
    'Trench Town': 3751,
    'University': 3751,
    'Vineyard Town': 3751,
    'Waltham Gardens': 3751,
    'Washington Gardens': 3751,
    'Waterhouse': 3751,
    'Whitehall': 3751,
    'Whitfield Town': 3751,
    'Wilton Gardens/ Rema': 3751,
    'Woodford': 3751,
    'Woodford Park': 3751,
    'Zaidie Gardens': 3751,
    'Aboukir': 3744,
    'Alderton': 3744,
    'Alexandria': 3744,
    'Alva': 3744,
    'Bamboo': 3744,
    'Beecher Town': 3744,
    'Bensonton': 3744,
    'Blackstonedge': 3744,
    'Bohemia': 3744,
    'Borobridge': 3744,
    'Browns Town': 3744,
    'Calderwood': 3744,
    'Cascade': 3744,
    'Cave Valley': 3744,
    'Chalky Hill': 3744,
    'Chester': 3744,
    'Claremont': 3744,
    'Cole Gate': 3744,
    'Content Garden': 3744,
    'Cuffie Ridge': 3744,
    'Discovery Bay': 3744,
    'Epworth': 3744,
    'Exchange': 3744,
    'Faith  s Pen': 3744,
    'Gibraltar': 3744,
    'Golden Grove': 3744,
    'Great Pond': 3744,
    'Higgin Land': 3744,
    'Hinds Town': 3744,
    'Inverness': 3744,
    'Lime Hall': 3744,
    'Lime Tree Gardens': 3744,
    'Linton Park': 3744,
    'Macknie': 3744,
    'Madras': 3744,
    'Mansfield': 3744,
    'Moneague': 3744,
    'Mount Moriah': 3744,
    'Mount Zion': 3744,
    'Ocho Rios': 3744,
    'Parry Town': 3744,
    'Philadelphia': 3744,
    'Pimento Walk': 3744,
    'Priory': 3744,
    'River Head': 3744,
    'Runaway Bay': 3744,
    'Saint Ann’s Bay': 3744,
    'St. Ann  s Bay': 3744,
    'St. D  Acre': 3744,
    'Steer Town': 3744,
    'Sturge Town': 3744,
    'Walkers Wood': 3744,
    'Watt Town': 3744,
    'York Castle': 3744,
    'Above Rocks': 3746,
    'Bartons': 3746,
    'Bellas Gate': 3746,
    'Bog Walk': 3746,
    'Bogwalk': 3746,
    'Braeton': 3746,
    'Bridgeport': 3746,
    'Browns Hill': 3746,
    'Caymanas': 3746,
    'Central Village': 3746,
    'Crescent': 3746,
    'Cromarty': 3746,
    'Cumberland': 3746,
    'Edgewater': 3746,
    'Ensom': 3746,
    'Ewarton': 3746,
    'Ginger Ridge': 3746,
    'Glengoffe': 3746,
    'Golden Acres': 3746,
    'Greater Portmore': 3746,
    'Greendale': 3746,
    'Gregory Park': 3746,
    'Hampton Green': 3746,
    'Harewood': 3746,
    'Harkers Hall': 3746,
    'Hellshire': 3746,
    'Independence City': 3746,
    'Keystone': 3746,
    'Kitson Town': 3746,
    'Linstead': 3746,
    'Lluidas Vale': 3746,
    'Naggo Head': 3746,
    'Old Harbour': 3746,
    'Old Harbour Bay': 3746,
    'Old Harbour Road': 3746,
    'Part of Guys Hill': 3746,
    'Part of Keystone': 3746,
    'Part of Rock Hall': 3746,
    'Passage Fort': 3746,
    'Pear Tree Grove': 3746,
    'Point Hill': 3746,
    'Portmore': 3746,
    'Redwood': 3746,
    'Riversdale': 3746,
    'Sligoville': 3746,
    'Spanish Town': 3746,
    'Spanish Town Central': 3746,
    'St. John  s East': 3746,
    'St. John  s West': 3746,
    'Troja': 3746,
    'Waterford': 3746,
    'Watermount': 3746,
    'West Cumberland': 3746,
    'Willowdene': 3746,
    'Aberdeen': 3743,
    'Accompong': 3743,
    'Alligator Pond': 3743,
    'Balaclava': 3743,
    'Ballards  Valley': 3743,
    'Barbary Hall': 3743,
    'Bigwoods': 3743,
    'Black River': 3743,
    'Bogue': 3743,
    'Braes River': 3743,
    'Brighton': 3743,
    'Brompton': 3743,
    'Bull Savanna': 3743,
    'Bull Savannah': 3743,
    'Burnt Savannah': 3743,
    'Carisbrook': 3743,
    'Cotterwood': 3743,
    'Crawford': 3743,
    'Elderslie': 3743,
    'Fullerswood': 3743,
    'Giddy Hall': 3743,
    'Ginger Hill': 3743,
    'Goshen': 3743,
    'Holland': 3743,
    'Hopeton': 3743,
    'Junction': 3743,
    'Lacovia': 3743,
    'Leeds': 3743,
    'Lititz': 3743,
    'Maggotty': 3743,
    'Malvern': 3743,
    'Merrywood': 3743,
    'Middle Quarters': 3743,
    'Mountainside': 3743,
    'Mulgrave': 3743,
    'Myersville': 3743,
    'Nain': 3743,
    'New Market': 3743,
    'Newell': 3743,
    'Newton': 3743,
    'Parottee': 3743,
    'Pedro Plains': 3743,
    'Pepper': 3743,
    'Pisgah': 3743,
    'Pondside': 3743,
    'Potsdam': 3743,
    'Quick Step': 3743,
    'Red Bank': 3743,
    'Retirement': 3743,
    'Rose Hall': 3743,
    'Russels': 3743,
    'Santa Cruz': 3743,
    'Scholefield': 3743,
    'Siloah': 3743,
    'Slipe': 3743,
    'Southfield': 3743,
    'Springfield': 3743,
    'Thornton': 3743,
    'Top Hill': 3743,
    'Treasure Beach': 3743,
    'Warminister': 3743,
    'Watchwell': 3743,
    'White Hill': 3743,
    'Adelphi': 3745,
    'Albion': 3745,
    'Anchovy': 3745,
    'Arcadia': 3745,
    'Barrett Town': 3745,
    'Bickersteth': 3745,
    'Bogue': 3745,
    'Brandon Hill': 3745,
    'Cambridge': 3745,
    'Canterbury': 3745,
    'Catadupa': 3745,
    'Catherine Hall': 3745,
    'Catherine Mount': 3745,
    'Comfort Hall': 3745,
    'Coral Gardens': 3745,
    'Cornwall': 3745,
    'Down Town Montego Bay': 3745,
    'Dumfries': 3745,
    'Fairfield': 3745,
    'Farm Heights': 3745,
    'Flagstaff': 3745,
    'Flamstead': 3745,
    'Flankers': 3745,
    'Flower Hill': 3745,
    'Friendship': 3745,
    'Garlands': 3745,
    'Glendevon': 3745,
    'Goodwill': 3745,
    'Granville': 3745,
    'Green Pond': 3745,
    'Greenwood': 3745,
    'Hopeton': 3745,
    'Ironshore': 3745,
    'Irwin': 3745,
    'John  s Hall': 3745,
    'Lilliput': 3745,
    'Lottery': 3745,
    'Maldon': 3745,
    'Maroon Town': 3745,
    'Mocho': 3745,
    'Montego Bay': 3745,
    'Montego Hill': 3745,
    'Montpelier': 3745,
    'Mount Carey': 3745,
    'Mount Horeb': 3745,
    'Mount Salem': 3745,
    'Niagara': 3745,
    'Norwood': 3745,
    'Orange': 3745,
    'Paradise': 3745,
    'Pitfour': 3745,
    'Porto Bello': 3745,
    'Reading': 3745,
    'Retirement': 3745,
    'Retrieve': 3745,
    'Roehampton': 3745,
    'Rose Hall': 3745,
    'Rose Heights': 3745,
    'Rose Mount Garden': 3745,
    'Rosemount': 3745,
    'Salt Spring': 3745,
    'Seven Rivers': 3745,
    'Somerton': 3745,
    'Spot Valley': 3745,
    'Stonehenge': 3745,
    'Summer Hill': 3745,
    'Sunderland': 3745,
    'Tangle River': 3745,
    'Tower Hill/Moy Hall': 3745,
    'Tucker': 3745,
    'Unity Hall': 3745,
    'Vaughnsfield': 3745,
    'Welcome Hall': 3745,
    'West Green': 3745,
    'Amiel Town': 3747,
    'Annotto Bay': 3747,
    'Baileys Vale': 3747,
    'Baxter Mountain': 3747,
    'Belfield': 3747,
    'Bonnygate': 3747,
    'Brainerd': 3747,
    'Broadgate': 3747,
    'Camberwell': 3747,
    'Carron Hall': 3747,
    'Castleton': 3747,
    'Charles Town': 3747,
    'Clonmel': 3747,
    'Devon Pen': 3747,
    'Dover': 3747,
    'Enfield': 3747,
    'Epson': 3747,
    'Flint River': 3747,
    'Free Hill': 3747,
    'Friendship Gap': 3747,
    'Galina': 3747,
    'Gayle': 3747,
    'Guys Hill': 3747,
    'Hampstead': 3747,
    'Highgate': 3747,
    'Islington': 3747,
    'Jacks River': 3747,
    'Jeffery Town': 3747,
    'Labyrinth': 3747,
    'Long Road': 3747,
    'Lucky Hill': 3747,
    'Mango Valley': 3747,
    'Martin': 3747,
    'Mason Hall': 3747,
    'Mile Gully': 3747,
    'Oracabessa': 3747,
    'Port Maria': 3747,
    'Prospect': 3747,
    'Retreat': 3747,
    'Richmond': 3747,
    'Robins Bay': 3747,
    'Sandside': 3747,
    'Scotts Hall': 3747,
    'Woodpark': 3747,
    'Woodside': 3747,
    'Airy Castle': 3750,
    'Albion': 3750,
    'Amity Hall': 3750,
    'Arcadia': 3750,
    'Baptist': 3750,
    'Barking Lodge': 3750,
    'Bath': 3750,
    'Bethel': 3750,
    'Cedar Valley': 3750,
    'Church Corner': 3750,
    'Dalvey': 3750,
    'Danvers Pen': 3750,
    'Duckenfield': 3750,
    'Duhaney Pen': 3750,
    'Easington': 3750,
    'Eleven Miles': 3750,
    'Font Hill': 3750,
    'Golden Grove': 3750,
    'Hagley Gap': 3750,
    'Hampton Court': 3750,
    'Hayfield': 3750,
    'Hillside': 3750,
    'Johns Town': 3750,
    'Johnson Mountain': 3750,
    'Jones Pen': 3750,
    'Leith Hall': 3750,
    'Llandewey': 3750,
    'Lloyds': 3750,
    'Lyssons': 3750,
    'Middleton': 3750,
    'Morant Bay': 3750,
    'Mount Lebanus': 3750,
    'New Monkland': 3750,
    'Pamphret': 3750,
    'Pear Tree': 3750,
    'Poormans Corner': 3750,
    'Port Morant': 3750,
    'Prospect': 3750,
    'Ramble': 3750,
    'Retreat': 3750,
    'Richmond Vale': 3750,
    'River Head': 3750,
    'Rowlandsfield': 3750,
    'Seaforth': 3750,
    'Somerset': 3750,
    'Spring Bank': 3750,
    'Spring Garden': 3750,
    'Sunning Hill': 3750,
    'Trinity Ville': 3750,
    'Trinityville': 3750,
    'Wheelerfield': 3750,
    'White Hall': 3750,
    'White Horses': 3750,
    'Wilmington': 3750,
    'Winchester': 3750,
    'Yallahs': 3750,
    'York': 3750,
    'Albert Town': 3755,
    'Alps': 3755,
    'Bounty Hall': 3755,
    'Brampton': 3755,
    'Bunkers Hill': 3755,
    'Clarks Town': 3755,
    'Daniel Town': 3755,
    'Deeside': 3755,
    'Duanvale': 3755,
    'Duncans': 3755,
    'Falmouth': 3755,
    'Freemans Hall': 3755,
    'Granville': 3755,
    'Hampden': 3755,
    'Jackson Town': 3755,
    'Joe Hut': 3755,
    'Kinloss': 3755,
    'Lorrimers': 3755,
    'Martha Brae': 3755,
    'Perth Town': 3755,
    'Refuge': 3755,
    'Rio Bueno': 3755,
    'Salt Marsh': 3755,
    'Samuels Prospect': 3755,
    'Sawyers': 3755,
    'Sherwood Content': 3755,
    'Spicy Hill': 3755,
    'Spring Garden': 3755,
    'St. Vincent': 3755,
    'Stettin': 3755,
    'Stewart Town': 3755,
    'Troy': 3755,
    'Ulster Spring': 3755,
    'Wait-A-Bit': 3755,
    'Wakefield': 3755,
    'Warsop': 3755,
    'Wilson  s Run': 3755,
    'Wirefence': 3755,
    '12th Street': 3742,
    'Amity': 3742,
    'Ashton': 3742,
    'Barneyside': 3742,
    'Bath': 3742,
    'Bath Mountain': 3742,
    'Beaufort': 3742,
    'Beeston Spring': 3742,
    'Belvedere': 3742,
    'Berkshire': 3742,
    'Bethel Town': 3742,
    'Big Bridge': 3742,
    'Bluefields': 3742,
    'Bog': 3742,
    'Broughton': 3742,
    'Burnt Savannah': 3742,
    'Cairn Curran': 3742,
    'Caledonia': 3742,
    'Carmel': 3742,
    'Cave': 3742,
    'Chantilly': 3742,
    'Content': 3742,
    'Cornwall Mountian': 3742,
    'Darliston': 3742,
    'Delveland': 3742,
    'Dillion Bigwoods': 3742,
    'Dundee': 3742,
    'Enfield': 3742,
    'Ferris': 3742,
    'Fort Williams': 3742,
    'Frome': 3742,
    'Fullersfield': 3742,
    'Georges Plain': 3742,
    'Gooden  s River': 3742,
    'Grange': 3742,
    'Grange Hill': 3742,
    'Haddo': 3742,
    'Harmony Town': 3742,
    'Hertford': 3742,
    'Jerusalem': 3742,
    'Kentucky': 3742,
    'Kilmarnoch': 3742,
    'Lambs River': 3742,
    'Leamington': 3742,
    'Lenox Bigwoods': 3742,
    'Little London': 3742,
    'Llandilo': 3742,
    'Mackfield': 3742,
    'Mearnsville': 3742,
    'Mount Airy': 3742,
    'Mount Stewart': 3742,
    'Negril': 3742,
    'New Market Oval': 3742,
    'New Roads': 3742,
    'New Works': 3742,
    'Orange Hill': 3742,
    'Paul Island': 3742,
    'Petersfield': 3742,
    'Petersville': 3742,
    'Porters Mountain': 3742,
    'Red Hills': 3742,
    'Revival': 3742,
    'Roaring River': 3742,
    'Russia': 3742,
    'Savanna-la-Mar': 3742,
    'Savannah-la-mar Business Dist.': 3742,
    'Seaford Town': 3742,
    'Seaton Crescent': 3742,
    'Shefield': 3742,
    'Shoalin Grotto': 3742,
    'Smithfield': 3742,
    'St. Leonards': 3742,
    'Strathbogie': 3742,
    'Strawberry': 3742,
    'Struie': 3742,
    'Three Mile River': 3742,
    'Town Head': 3742,
    'Truro': 3742,
    'Water Works': 3742,
    'Whitehouse': 3742,
    'Whithorn': 3742,
    'Williamsfield': 3742,
    'Aisai-shi': 827,
    'Ama-gun': 827,
    'Anjō-shi': 827,
    'Chiryū': 827,
    'Chiryū-shi': 827,
    'Chita': 827,
    'Chita-shi': 827,
    'Gamagōri': 827,
    'Gamagōri-shi': 827,
    'Handa': 827,
    'Handa-shi': 827,
    'Hekinan': 827,
    'Hekinan-shi': 827,
    'Ichinomiya': 827,
    'Ichinomiya-shi': 827,
    'Inazawa': 827,
    'Inazawa-shi': 827,
    'Inuyama': 827,
    'Inuyama-shi': 827,
    'Ishiki': 827,
    'Iwakura': 827,
    'Iwakura-shi': 827,
    'Kanie': 827,
    'Kariya': 827,
    'Kariya-shi': 827,
    'Kasugai': 827,
    'Kasugai-shi': 827,
    'Kitanagoya': 827,
    'Kitanagoya-shi': 827,
    'Kiyosu-shi': 827,
    'Komaki': 827,
    'Komaki-shi': 827,
    'Kōnan': 827,
    'Kōnan-shi': 827,
    'Kozakai-chō': 827,
    'Kuroda': 827,
    'Miyoshi-shi': 827,
    'Nagakute-shi': 827,
    'Nagoya': 827,
    'Nishio': 827,
    'Nishio-shi': 827,
    'Nisshin-shi': 827,
    'Ōbu': 827,
    'Ōbu-shi': 827,
    'Okazaki-shi': 827,
    'Owariasahi-shi': 827,
    'Seto': 827,
    'Seto-shi': 827,
    'Shinshiro': 827,
    'Shinshiro-shi': 827,
    'Sobue': 827,
    'Tahara': 827,
    'Tahara-shi': 827,
    'Takahama': 827,
    'Takahama-shi': 827,
    'Taketoyo': 827,
    'Tōkai-shi': 827,
    'Tokoname': 827,
    'Tokoname-shi': 827,
    'Toyoake': 827,
    'Toyoake-shi': 827,
    'Toyohama': 827,
    'Toyohashi': 827,
    'Toyohashi-shi': 827,
    'Toyokawa': 827,
    'Toyokawa-shi': 827,
    'Toyota-shi': 827,
    'Tsushima': 827,
    'Tsushima-shi': 827,
    'Yatomi-shi': 827,
    'Akita': 829,
    'Akita Shi': 829,
    'Daisen': 829,
    'Daisen-shi': 829,
    'Hanawa': 829,
    'Kakunodatemachi': 829,
    'Katagami': 829,
    'Katagami-shi': 829,
    'Kazuno Shi': 829,
    'Kitaakita-shi': 829,
    'Nikaho-shi': 829,
    'Noshiro': 829,
    'Noshiro Shi': 829,
    'Ōdate': 829,
    'Ōdate-shi': 829,
    'Oga': 829,
    'Oga-shi': 829,
    'Ōmagari': 829,
    'Semboku-shi': 829,
    'Takanosu': 829,
    'Tennō': 829,
    'Yokote': 829,
    'Yokote-shi': 829,
    'Yurihonjō': 829,
    'Yurihonjō-shi': 829,
    'Yuzawa': 829,
    'Yuzawa-shi': 829,
    'Aomori': 839,
    'Aomori Shi': 839,
    'Goshogawara': 839,
    'Goshogawara Shi': 839,
    'Hachinohe': 839,
    'Hachinohe Shi': 839,
    'Hirakawa': 839,
    'Hirakawa Shi': 839,
    'Hirosaki': 839,
    'Hirosaki Shi': 839,
    'Kuroishi': 839,
    'Kuroishi Shi': 839,
    'Misawa': 839,
    'Misawa Shi': 839,
    'Mutsu': 839,
    'Mutsu-shi': 839,
    'Namioka': 839,
    'Shimokizukuri': 839,
    'Towada Shi': 839,
    'Tsugaru': 839,
    'Tsugaru Shi': 839,
    'Abiko': 821,
    'Abiko-shi': 821,
    'Asahi-shi': 821,
    'Chiba': 821,
    'Chōshi-shi': 821,
    'Funabashi-shi': 821,
    'Futtsu': 821,
    'Hasaki': 821,
    'Honchō': 821,
    'Ichihara': 821,
    'Ichihara Shi': 821,
    'Ichikawa Shi': 821,
    'Inzai-shi': 821,
    'Isumi': 821,
    'Isumi Shi': 821,
    'Kamagaya Shi': 821,
    'Kamogawa': 821,
    'Kamogawa-shi': 821,
    'Kashiwa': 821,
    'Kashiwa Shi': 821,
    'Katori-shi': 821,
    'Katsuura': 821,
    'Katsuura-shi': 821,
    'Kimitsu': 821,
    'Kisarazu': 821,
    'Kisarazu Shi': 821,
    'Matsudo Shi': 821,
    'Minamibōsō Shi': 821,
    'Mobara': 821,
    'Mobara Shi': 821,
    'Nagareyama': 821,
    'Nagareyama-shi': 821,
    'Narashino-shi': 821,
    'Narita': 821,
    'Narita-shi': 821,
    'Narutō': 821,
    'Noda': 821,
    'Noda-shi': 821,
    'Ōami': 821,
    'Ōamishirasato-shi': 821,
    'Ōhara': 821,
    'Omigawa': 821,
    'Sakura': 821,
    'Sakura-shi': 821,
    'Sambu-gun': 821,
    'Sanmu': 821,
    'Sanmu-shi': 821,
    'Sawara': 821,
    'Shiroi': 821,
    'Shiroi-shi': 821,
    'Shisui': 821,
    'Sodegaura-shi': 821,
    'Sōsa-shi': 821,
    'Tateyama': 821,
    'Tateyama-shi': 821,
    'Tōgane': 821,
    'Tōgane-shi': 821,
    'Tomisato-shi': 821,
    'Urayasu-shi': 821,
    'Yachimata': 821,
    'Yachimata-shi': 821,
    'Yachiyo-shi': 821,
    'Yōkaichiba': 821,
    'Yotsukaidō': 821,
    'Yotsukaidō-shi': 821,
    'Hōjō': 865,
    'Imabari-shi': 865,
    'Iyo': 865,
    'Iyo-shi': 865,
    'Kawanoechō': 865,
    'Kihoku-chō': 865,
    'Masaki-chō': 865,
    'Matsuyama-shi': 865,
    'Niihama': 865,
    'Niihama-shi': 865,
    'Ōzu': 865,
    'Ōzu-shi': 865,
    'Saijō': 865,
    'Saijō-shi': 865,
    'Seiyo-shi': 865,
    'Shikoku-chūō Shi': 865,
    'Tōon-shi': 865,
    'Uwajima': 865,
    'Uwajima-shi': 865,
    'Yawatahama-shi': 865,
    'Asahi': 848,
    'Awara-shi': 848,
    'Echizen-shi': 848,
    'Fukui-shi': 848,
    'Katsuyama': 848,
    'Katsuyama Shi': 848,
    'Maruoka': 848,
    'Mikuni': 848,
    'Obama': 848,
    'Obama-shi': 848,
    'Ōi-gun': 848,
    'Ono': 848,
    'Ōno-shi': 848,
    'Sabae': 848,
    'Sabae-shi': 848,
    'Sakai-shi': 848,
    'Takefu': 848,
    'Tsuruga': 848,
    'Tsuruga-shi': 848,
    'Amagi': 861,
    'Asakura Shi': 861,
    'Buzen': 861,
    'Buzen-shi': 861,
    'Chikugo Shi': 861,
    'Chikushino-shi': 861,
    'Dazaifu-shi': 861,
    'Fukuoka': 861,
    'Fukuoka-shi': 861,
    'Fukutsu Shi': 861,
    'Iizuka': 861,
    'Iizuka Shi': 861,
    'Itoshima-shi': 861,
    'Kama Shi': 861,
    'Kanda': 861,
    'Kasuga Shi': 861,
    'Kawasaki': 861,
    'Kitakyushu': 861,
    'Koga': 861,
    'Koga-shi': 861,
    'Kurume': 861,
    'Kurume Shi': 861,
    'Maebaru-chūō': 861,
    'Miyama Shi': 861,
    'Miyata': 861,
    'Miyawaka Shi': 861,
    'Munakata-shi': 861,
    'Nakama': 861,
    'Nakama Shi': 861,
    'Nishifukuma': 861,
    'Nōgata': 861,
    'Nōgata Shi': 861,
    'Ogōri-shi': 861,
    'Ōkawa': 861,
    'Ōkawa-shi': 861,
    'Ōmuta': 861,
    'Ōmuta Shi': 861,
    'Onga-gun': 861,
    'Ōnojō': 861,
    'Ōnojō Shi': 861,
    'Sasaguri': 861,
    'Setakamachi-takayanagi': 861,
    'Shiida': 861,
    'Shingū': 861,
    'Tagawa': 861,
    'Tagawa Shi': 861,
    'Tanushimarumachi-toyoki': 861,
    'Tsuiki': 861,
    'Ukiha Shi': 861,
    'Umi': 861,
    'Yame Shi': 861,
    'Yanagawa': 861,
    'Yanagawa Shi': 861,
    'Yoshitomi': 861,
    'Yukuhashi': 861,
    'Yukuhashi Shi': 861,
    'Aizu-wakamatsu Shi': 847,
    'Date-shi': 847,
    'Fukushima': 847,
    'Fukushima Shi': 847,
    'Funehikimachi-funehiki': 847,
    'Hobaramachi': 847,
    'Inawashiro': 847,
    'Ishikawa': 847,
    'Iwaki': 847,
    'Iwaki-shi': 847,
    'Kitakata': 847,
    'Kitakata-shi': 847,
    'Kōriyama': 847,
    'Kōriyama Shi': 847,
    'Miharu': 847,
    'Minami-Sōma': 847,
    'Minamisōma Shi': 847,
    'Motomiya': 847,
    'Motomiya-shi': 847,
    'Namie': 847,
    'Nihommatsu': 847,
    'Nihonmatsu Shi': 847,
    'Shirakawa Shi': 847,
    'Sōma': 847,
    'Sōma Shi': 847,
    'Sukagawa': 847,
    'Sukagawa Shi': 847,
    'Tamura': 847,
    'Tamura-shi': 847,
    'Yanagawamachi-saiwaichō': 847,
    'Ena-shi': 858,
    'Gero': 858,
    'Gero-shi': 858,
    'Gifu-shi': 858,
    'Gōdo': 858,
    'Gujō': 858,
    'Gujō-shi': 858,
    'Hashima': 858,
    'Hashima-shi': 858,
    'Hida': 858,
    'Hida Shi': 858,
    'Kaizu-shi': 858,
    'Kakamigahara-shi': 858,
    'Kani-shi': 858,
    'Kasamatsuchō': 858,
    'Mino': 858,
    'Mino-shi': 858,
    'Minokamo': 858,
    'Minokamo-shi': 858,
    'Mitake': 858,
    'Mizuho-shi': 858,
    'Mizunami': 858,
    'Mizunami-shi': 858,
    'Motosu-shi': 858,
    'Nakatsugawa': 858,
    'Nakatsugawa-shi': 858,
    'Ōgaki-shi': 858,
    'Seki-shi': 858,
    'Tajimi': 858,
    'Tajimi-shi': 858,
    'Takayama Shi': 858,
    'Tarui': 858,
    'Toki': 858,
    'Toki-shi': 858,
    'Yamagata-shi': 858,
    'Agatsuma-gun': 862,
    'Annaka': 862,
    'Annaka Shi': 862,
    'Fujioka': 862,
    'Fujioka Shi': 862,
    'Isesaki': 862,
    'Isesaki Shi': 862,
    'Kanekomachi': 862,
    'Kiryū': 862,
    'Kiryū Shi': 862,
    'Maebashi': 862,
    'Maebashi Shi': 862,
    'Midori': 862,
    'Midori Shi': 862,
    'Nakanojōmachi': 862,
    'Numata': 862,
    'Numata Shi': 862,
    'Ōmamachō-ōmama': 862,
    'Ōta': 862,
    'Ōta-shi': 862,
    'Sakai-nakajima': 862,
    'Shibukawa': 862,
    'Shibukawa-shi': 862,
    'Takasaki': 862,
    'Takasaki Shi': 862,
    'Tamamura': 862,
    'Tatebayashi': 862,
    'Tatebayashi-shi': 862,
    'Tomioka': 862,
    'Tomioka-shi': 862,
    'Yoshii': 862,
    'Aki-takata Shi': 828,
    'Etajima-shi': 828,
    'Fuchū-shi': 828,
    'Fuchūchō': 828,
    'Fukuyama': 828,
    'Fukuyama Shi': 828,
    'Hatsukaichi': 828,
    'Hatsukaichi-shi': 828,
    'Higashihiroshima Shi': 828,
    'Hiroshima': 828,
    'Hiroshima-shi': 828,
    'Innoshima': 828,
    'Kannabechō-yahiro': 828,
    'Kure': 828,
    'Kure-shi': 828,
    'Mihara': 828,
    'Mihara Shi': 828,
    'Miyajima': 828,
    'Miyoshi': 828,
    'Miyoshi Shi': 828,
    'Ōno-hara': 828,
    'Onomichi': 828,
    'Onomichi-shi': 828,
    'Ōtake': 828,
    'Ōtake-shi': 828,
    'Shin’ichi': 828,
    'Shōbara': 828,
    'Shōbara-shi': 828,
    'Takehara': 828,
    'Takehara-shi': 828,
    'Abashiri': 832,
    'Abashiri Shi': 832,
    'Akabira': 832,
    'Akabira-shi': 832,
    'Asahikawa': 832,
    'Ashibetsu': 832,
    'Ashibetsu-shi': 832,
    'Bibai': 832,
    'Chitose': 832,
    'Chitose Shi': 832,
    'Date': 832,
    'Date-shi': 832,
    'Ebetsu': 832,
    'Eniwa-shi': 832,
    'Fukagawa': 832,
    'Fukagawa-shi': 832,
    'Furano-shi': 832,
    'Hakodate': 832,
    'Hakodate Shi': 832,
    'Hokuto': 832,
    'Hokuto-shi': 832,
    'Honchō': 832,
    'Ishikari': 832,
    'Ishikari-shi': 832,
    'Iwamizawa': 832,
    'Iwamizawa-shi': 832,
    'Iwanai': 832,
    'Kamiiso': 832,
    'Kamikawa': 832,
    'Kitahiroshima': 832,
    'Kitahiroshima-shi': 832,
    'Kitami': 832,
    'Ktiami Shi': 832,
    'Kushiro': 832,
    'Kushiro Shi': 832,
    'Makubetsu': 832,
    'Mikasa': 832,
    'Mikasa-shi': 832,
    'Mombetsu': 832,
    'Monbetsu Shi': 832,
    'Motomachi': 832,
    'Muroran': 832,
    'Muroran-shi': 832,
    'Nayoro': 832,
    'Nayoro Shi': 832,
    'Nemuro': 832,
    'Nemuro-shi': 832,
    'Niseko Town': 832,
    'Noboribetsu': 832,
    'Noboribetsu-shi': 832,
    'Obihiro': 832,
    'Obihiro Shi': 832,
    'Otaru': 832,
    'Otaru-shi': 832,
    'Otofuke': 832,
    'Rebun Gun': 832,
    'Rishiri Gun': 832,
    'Rishiri Town': 832,
    'Rumoi': 832,
    'Rumoi-shi': 832,
    'Sapporo': 832,
    'Sapporo-shi': 832,
    'Shibetsu': 832,
    'Shibetsu Shi': 832,
    'Shimo-furano': 832,
    'Shiraoi': 832,
    'Shizunai-furukawachō': 832,
    'Sunagawa': 832,
    'Sunagawa-shi': 832,
    'Takikawa': 832,
    'Takikawa-shi': 832,
    'Tōbetsu': 832,
    'Tomakomai': 832,
    'Tomakomai Shi': 832,
    'Utashinai': 832,
    'Utashinai-shi': 832,
    'Wakkanai': 832,
    'Wakkanai Shi': 832,
    'Yoichi': 832,
    'Yūbari': 832,
    'Yūbari-shi': 832,
    'Aioi': 831,
    'Aioi Shi': 831,
    'Akashi': 831,
    'Akashi Shi': 831,
    'Amagasaki Shi': 831,
    'Asago-shi': 831,
    'Ashiya': 831,
    'Ashiya Shi': 831,
    'Awaji Shi': 831,
    'Fukura': 831,
    'Himeji': 831,
    'Himeji Shi': 831,
    'Itami': 831,
    'Itami Shi': 831,
    'Kakogawa Shi': 831,
    'Kakogawachō-honmachi': 831,
    'Kariya': 831,
    'Kasai Shi': 831,
    'Katō Shi': 831,
    'Kawanishi': 831,
    'Kawanishi Shi': 831,
    'Kobe': 831,
    'Miki': 831,
    'Miki Shi': 831,
    'Minamiawaji Shi': 831,
    'Nishinomiya Shi': 831,
    'Nishinomiya-hama': 831,
    'Nishiwaki': 831,
    'Nishiwaki-shi': 831,
    'Ono': 831,
    'Ono Shi': 831,
    'Sanda Shi': 831,
    'Sandachō': 831,
    'Sasayama': 831,
    'Sasayama-shi': 831,
    'Shirahamachō-usazakiminami': 831,
    'Shisō-shi': 831,
    'Sumoto': 831,
    'Sumoto Shi': 831,
    'Taka-gun': 831,
    'Takarazuka': 831,
    'Takarazuka Shi': 831,
    'Takasago Shi': 831,
    'Tanba-shi': 831,
    'Tatsuno-shi': 831,
    'Tatsunochō-tominaga': 831,
    'Toyooka': 831,
    'Toyooka-shi': 831,
    'Yabu': 831,
    'Yabu-shi': 831,
    'Yamazakichō-nakabirose': 831,
    'Yashiro': 831,
    'Yonedacho Sendo': 831,
    'Ami': 851,
    'Bandō': 851,
    'Bandō-shi': 851,
    'Chikusei': 851,
    'Chikusei-shi': 851,
    'Daigo': 851,
    'Edosaki': 851,
    'Fujishiro': 851,
    'Funaishikawa': 851,
    'Hitachi': 851,
    'Hitachi-Naka': 851,
    'Hitachi-ota': 851,
    'Hitachi-shi': 851,
    'Hitachinaka-shi': 851,
    'Hitachiōmiya-shi': 851,
    'Hitachiōta-shi': 851,
    'Hokota-shi': 851,
    'Inashiki': 851,
    'Inashiki-shi': 851,
    'Ishige': 851,
    'Ishioka': 851,
    'Ishioka-shi': 851,
    'Itako': 851,
    'Itako-shi': 851,
    'Iwai': 851,
    'Iwase': 851,
    'Jōsō-shi': 851,
    'Kamisu-shi': 851,
    'Kasama': 851,
    'Kasama-shi': 851,
    'Kashima-shi': 851,
    'Kasumigaura': 851,
    'Kasumigaura-shi': 851,
    'Katsuta': 851,
    'Kitaibaraki': 851,
    'Kitaibaraki-shi': 851,
    'Koga': 851,
    'Koga-shi': 851,
    'Makabe': 851,
    'Mito': 851,
    'Mito-shi': 851,
    'Mitsukaidō': 851,
    'Moriya': 851,
    'Moriya-shi': 851,
    'Naka': 851,
    'Naka-gun': 851,
    'Namegata': 851,
    'Namegata-shi': 851,
    'Ōarai': 851,
    'Okunoya': 851,
    'Omitama-shi': 851,
    'Ōmiya': 851,
    'Ryūgasaki': 851,
    'Ryūgasaki-shi': 851,
    'Sakai': 851,
    'Sakuragawa': 851,
    'Sakuragawa-shi': 851,
    'Shimodate': 851,
    'Shimotsuma-shi': 851,
    'Takahagi': 851,
    'Tomobe': 851,
    'Toride': 851,
    'Toride-shi': 851,
    'Tsuchiura-shi': 851,
    'Tsukuba': 851,
    'Tsukuba-shi': 851,
    'Tsukubamirai': 851,
    'Tsukubamirai-shi': 851,
    'Ushiku': 851,
    'Ushiku-shi': 851,
    'Yūki': 851,
    'Yūki-shi': 851,
    'Hakui': 830,
    'Hakui Shi': 830,
    'Hakusan Shi': 830,
    'Kaga Shi': 830,
    'Kahoku Shi': 830,
    'Kanazawa': 830,
    'Kanazawa-shi': 830,
    'Komatsu': 830,
    'Matsutō': 830,
    'Nanao Shi': 830,
    'Nomi Shi': 830,
    'Nonoichi': 830,
    'Nonoichi-shi': 830,
    'Suzu': 830,
    'Suzu Shi': 830,
    'Tsubata': 830,
    'Tsurugi-asahimachi': 830,
    'Wajima Shi': 830,
    'Hachimantai': 856,
    'Hachimantai Shi': 856,
    'Hanamaki': 856,
    'Hanamaki Shi': 856,
    'Ichinohe': 856,
    'Ichinoseki': 856,
    'Ichinoseki-shi': 856,
    'Iwate-gun': 856,
    'Kamaishi': 856,
    'Kamaishi-shi': 856,
    'Kitakami': 856,
    'Kitakami-shi': 856,
    'Kuji': 856,
    'Kuji-shi': 856,
    'Miyako': 856,
    'Miyako-shi': 856,
    'Mizusawa': 856,
    'Morioka': 856,
    'Morioka-shi': 856,
    'Ninohe': 856,
    'Ninohe Shi': 856,
    'Ōfunato': 856,
    'Ōfunato-shi': 856,
    'Ōshū': 856,
    'Ōshū-shi': 856,
    'Ōtsuchi': 856,
    'Rikuzentakata-shi': 856,
    'Shizukuishi': 856,
    'Takizawa-shi': 856,
    'Tōno': 856,
    'Tōno-shi': 856,
    'Yamada': 856,
    'Higashikagawa Shi': 864,
    'Kan’onji Shi': 864,
    'Kan’onjichō': 864,
    'Marugame': 864,
    'Marugame Shi': 864,
    'Mitoyo Shi': 864,
    'Sakaide Shi': 864,
    'Sakaidechō': 864,
    'Sanuki-shi': 864,
    'Shido': 864,
    'Tadotsu': 864,
    'Takamatsu': 864,
    'Takamatsu Shi': 864,
    'Tonoshō': 864,
    'Zentsūji Shi': 864,
    'Aira Shi': 840,
    'Akune': 840,
    'Akune Shi': 840,
    'Amami': 840,
    'Amami Shi': 840,
    'Hamanoichi': 840,
    'Hioki': 840,
    'Hioki Shi': 840,
    'Ibusuki': 840,
    'Ibusuki Shi': 840,
    'Ichikikushikino Shi': 840,
    'Ijūin': 840,
    'Isa Shi': 840,
    'Izumi': 840,
    'Izumi Shi': 840,
    'Kagoshima': 840,
    'Kagoshima Shi': 840,
    'Kajiki': 840,
    'Kanoya': 840,
    'Kanoya Shi': 840,
    'Kaseda-shirakame': 840,
    'Kirishima Shi': 840,
    'Kokubu-matsuki': 840,
    'Koseda': 840,
    'Koshima': 840,
    'Kurio': 840,
    'Kushikino': 840,
    'Makurazaki': 840,
    'Makurazaki Shi': 840,
    'Minamikyushu Shi': 840,
    'Minamisatsuma Shi': 840,
    'Miyanoura': 840,
    'Nagata': 840,
    'Naze': 840,
    'Nishinoomote': 840,
    'Nishinoomote Shi': 840,
    'Ōkuchi-shinohara': 840,
    'Satsumasendai': 840,
    'Satsumasendai Shi': 840,
    'Shibushi': 840,
    'Shibushi-shi': 840,
    'Soo Shi': 840,
    'Sueyoshichō-ninokata': 840,
    'Tarumizu': 840,
    'Tarumizu Shi': 840,
    'Yudomari': 840,
    'Atsugi': 842,
    'Atsugi Shi': 842,
    'Ayase Shi': 842,
    'Chigasaki': 842,
    'Chigasaki Shi': 842,
    'Ebina Shi': 842,
    'Fujisawa': 842,
    'Fujisawa Shi': 842,
    'Hadano': 842,
    'Hadano-shi': 842,
    'Hakone': 842,
    'Hayama': 842,
    'Hiratsuka': 842,
    'Hiratsuka Shi': 842,
    'Isehara': 842,
    'Isehara Shi': 842,
    'Kamakura': 842,
    'Kamakura Shi': 842,
    'Kawasaki': 842,
    'Kawasaki-shi': 842,
    'Minamiashigara': 842,
    'Minamiashigara Shi': 842,
    'Minamirinkan': 842,
    'Miura': 842,
    'Miura Shi': 842,
    'Ninomiya': 842,
    'Odawara': 842,
    'Odawara-shi': 842,
    'Ōiso': 842,
    'Sagamihara-shi': 842,
    'Yamakita': 842,
    'Yamato-shi': 842,
    'Yokohama': 842,
    'Yokohama Shi': 842,
    'Yokosuka': 842,
    'Yokosuka Shi': 842,
    'Yugawara': 842,
    'Zama': 842,
    'Zama Shi': 842,
    'Zushi Shi': 842,
    'Achi': 4924,
    'Agematsu': 4924,
    'Aki': 4924,
    'Anan': 4924,
    'Aoki': 4924,
    'Asahi': 4924,
    'Azumino': 4924,
    'Chikuhoku': 4924,
    'Chikuma': 4924,
    'Chino': 4924,
    'Fujimi': 4924,
    'Geisei': 4924,
    'Hakuba': 4924,
    'Hara': 4924,
    'Hidaka': 4924,
    'Hiraya': 4924,
    'Iida': 4924,
    'Iijima': 4924,
    'Iiyama': 4924,
    'Iizuna': 4924,
    'Ikeda': 4924,
    'Ikusaka': 4924,
    'Ina': 4924,
    'Ino': 4924,
    'Karuizawa': 4924,
    'Kawakami': 4924,
    'Kijimadaira': 4924,
    'Kiso': 4924,
    'Kitaaiki': 4924,
    'Kitagawa': 4924,
    'Kōchi': 4924,
    'Komoro': 4924,
    'Kōnan': 4924,
    'Kuroshio': 4924,
    'Matsukawa': 4924,
    'Matsumoto': 4924,
    'Mihara': 4924,
    'Minamiaiki': 4924,
    'Minamimaki': 4924,
    'Minamiminowa': 4924,
    'Minowa': 4924,
    'Mitsushima': 4924,
    'Miyada': 4924,
    'Miyota': 4924,
    'Motoyama': 4924,
    'Muroto': 4924,
    'Nagano': 4924,
    'Nagawa': 4924,
    'Nagiso': 4924,
    'Nahari': 4924,
    'Nakagawa': 4924,
    'Nakajō': 4924,
    'Nakamura': 4924,
    'Nakano': 4924,
    'Nakatosa': 4924,
    'Nankoku': 4924,
    'Neba': 4924,
    'Niyodogawa': 4924,
    'Nozawaonsen': 4924,
    'Obuse': 4924,
    'Ochi': 4924,
    'Ogawa': 4924,
    'Ōkawa': 4924,
    'Okaya': 4924,
    'Ōkuwa': 4924,
    'Ōmachi': 4924,
    'Omi': 4924,
    'Ōshika': 4924,
    'Ōtaki': 4924,
    'Otari': 4924,
    'Ōtoyo': 4924,
    'Ōtsuki': 4924,
    'Sakae': 4924,
    'Sakaki': 4924,
    'Sakawa': 4924,
    'Saku': 4924,
    'Sakuho': 4924,
    'Sanada': 4924,
    'Shimanto': 4924,
    'Shimojō': 4924,
    'Shimosuwa': 4924,
    'Shinano': 4924,
    'Shiojiri': 4924,
    'Sukumo': 4924,
    'Susaki': 4924,
    'Suwa': 4924,
    'Suzaka': 4924,
    'Takagi': 4924,
    'Takamori': 4924,
    'Takayama': 4924,
    'Tano': 4924,
    'Tateshina': 4924,
    'Tatsuno': 4924,
    'Togari': 4924,
    'Tōmi': 4924,
    'Tosa': 4924,
    'Tosashimizu': 4924,
    'Tōyō': 4924,
    'Toyooka': 4924,
    'Tsuno': 4924,
    'Ueda': 4924,
    'Umaji': 4924,
    'Urugi': 4924,
    'Usa': 4924,
    'Yamagata': 4924,
    'Yamanouchi': 4924,
    'Yasuda': 4924,
    'Yasuoka': 4924,
    'Yawata': 4924,
    'Yusuhara': 4924,
    'Amakusa Gun': 846,
    'Amakusa Shi': 846,
    'Arao Shi': 846,
    'Aso-shi': 846,
    'Hitoyoshi': 846,
    'Hitoyoshi Shi': 846,
    'Hondomachi-hondo': 846,
    'Honmachi': 846,
    'Kamiamakusa Shi': 846,
    'Kikuchi': 846,
    'Kikuchi Shi': 846,
    'Kōshi Shi': 846,
    'Kumamoto': 846,
    'Kumamoto Shi': 846,
    'Matsubase': 846,
    'Minamata': 846,
    'Minamata Shi': 846,
    'Ōzu': 846,
    'Tamana': 846,
    'Tamana Shi': 846,
    'Uekimachi-mōno': 846,
    'Uki Shi': 846,
    'Ushibukamachi': 846,
    'Uto': 846,
    'Uto Shi': 846,
    'Yamaga Shi': 846,
    'Yatsushiro Shi': 846,
    'Arashiyama': 834,
    'Ayabe': 834,
    'Ayabe-shi': 834,
    'Fukuchiyama-shi': 834,
    'Jōyō Shi': 834,
    'Kameoka': 834,
    'Kameoka-shi': 834,
    'Kamigyō-ku': 834,
    'Kizugawa-shi': 834,
    'Kyōtanabe Shi': 834,
    'Kyōtango-shi': 834,
    'Kyoto': 834,
    'Maizuru': 834,
    'Maizuru-shi': 834,
    'Miyazu': 834,
    'Miyazu-shi': 834,
    'Mukō Shi': 834,
    'Nagaokakyō Shi': 834,
    'Nantan-shi': 834,
    'Tanabe': 834,
    'Uji': 834,
    'Uji Shi': 834,
    'Yawata': 834,
    'Yawata Shi': 834,
    'Hisai-motomachi': 833,
    'Iga-shi': 833,
    'Inabe': 833,
    'Inabe-shi': 833,
    'Ise': 833,
    'Ise-shi': 833,
    'Kameyama': 833,
    'Kameyama Shi': 833,
    'Kawage': 833,
    'Komono': 833,
    'Kumano': 833,
    'Kumano-shi': 833,
    'Kuwana': 833,
    'Kuwana-shi': 833,
    'Matsuzaka-shi': 833,
    'Nabari': 833,
    'Nabari-shi': 833,
    'Owase': 833,
    'Shima': 833,
    'Shima-shi': 833,
    'Suzuka': 833,
    'Suzuka-shi': 833,
    'Toba': 833,
    'Toba-shi': 833,
    'Tsu': 833,
    'Tsu-shi': 833,
    'Ueno-ebisumachi': 833,
    'Yokkaichi-shi': 833,
    'Furukawa': 857,
    'Higashimatshushima Shi': 857,
    'Higashimatsushima': 857,
    'Ishinomaki': 857,
    'Ishinomaki Shi': 857,
    'Iwanuma': 857,
    'Iwanuma-shi': 857,
    'Kakuda': 857,
    'Kakuda Shi': 857,
    'Kesennuma': 857,
    'Kesennuma Shi': 857,
    'Kogota': 857,
    'Kurihara': 857,
    'Kurihara Shi': 857,
    'Marumori': 857,
    'Matsushima': 857,
    'Natori Shi': 857,
    'Ōkawara': 857,
    'Onagawa Chō': 857,
    'Ōsaki': 857,
    'Ōsaki Shi': 857,
    'Rifu': 857,
    'Sendai': 857,
    'Shiogama': 857,
    'Shiroishi': 857,
    'Shiroishi Shi': 857,
    'Tagajō Shi': 857,
    'Tome Shi': 857,
    'Tomiya': 857,
    'Wakuya': 857,
    'Watari': 857,
    'Watari-gun': 857,
    'Yamoto': 857,
    'Ebino-shi': 855,
    'Hyūga-shi': 855,
    'Kobayashi': 855,
    'Kobayashi Shi': 855,
    'Kushima': 855,
    'Kushima Shi': 855,
    'Miyakonojō': 855,
    'Miyakonojō Shi': 855,
    'Miyazaki': 855,
    'Miyazaki-shi': 855,
    'Nichinan': 855,
    'Nichinan Shi': 855,
    'Nobeoka': 855,
    'Nobeoka-shi': 855,
    'Saito-shi': 855,
    'Takanabe': 855,
    'Tsuma': 855,
    'Azumino-Shi': 843,
    'Chikuma Shi': 843,
    'Chino': 843,
    'Chino Shi': 843,
    'Hakuba': 843,
    'Hotaka': 843,
    'Iida': 843,
    'Iida-shi': 843,
    'Iiyama': 843,
    'Iiyama Shi': 843,
    'Ina': 843,
    'Ina-shi': 843,
    'Kamimaruko': 843,
    'Komagane': 843,
    'Komagane-shi': 843,
    'Komoro': 843,
    'Komoro Shi': 843,
    'Matsumoto': 843,
    'Matsumoto Shi': 843,
    'Nagano': 843,
    'Nagano Shi': 843,
    'Nakano Shi': 843,
    'Okaya': 843,
    'Okaya Shi': 843,
    'Ōmachi': 843,
    'Ōmachi-shi': 843,
    'Saku': 843,
    'Saku Shi': 843,
    'Shiojiri': 843,
    'Shiojiri-shi': 843,
    'Suwa': 843,
    'Suwa Shi': 843,
    'Suzaka': 843,
    'Suzaka-shi': 843,
    'Tatsuno': 843,
    'Tōmi': 843,
    'Tōmi Shi': 843,
    'Toyoshina': 843,
    'Ueda': 843,
    'Ueda Shi': 843,
    'Fukuechō': 849,
    'Gotō Shi': 849,
    'Hirado': 849,
    'Hirado Shi': 849,
    'Iki Shi': 849,
    'Isahaya': 849,
    'Isahaya-shi': 849,
    'Matsuura': 849,
    'Matsuura Shi': 849,
    'Minamishimabara-shi': 849,
    'Nagasaki': 849,
    'Nagasaki-shi': 849,
    'Obita': 849,
    'Ōmura': 849,
    'Ōmura-shi': 849,
    'Saikai-shi': 849,
    'Sasebo Shi': 849,
    'Shimabara': 849,
    'Shimabara-shi': 849,
    'Togitsu': 849,
    'Tsushima Shi': 849,
    'Unzen-shi': 849,
    'Gojō': 824,
    'Gojō-shi': 824,
    'Gose': 824,
    'Haibara-akanedai': 824,
    'Hōryūji': 824,
    'Ikoma': 824,
    'Ikoma-shi': 824,
    'Kashiba-shi': 824,
    'Kashihara-shi': 824,
    'Katsuragi': 824,
    'Katsuragi Shi': 824,
    'Nara-shi': 824,
    'Sakurai': 824,
    'Sakurai-shi': 824,
    'Tawaramoto': 824,
    'Tenri': 824,
    'Tenri-shi': 824,
    'Uda Shi': 824,
    'Yamatokōriyama-shi': 824,
    'Yamatotakada-shi': 824,
    'Yoshino-chō': 824,
    'Agano Shi': 841,
    'Arai': 841,
    'Gosen': 841,
    'Gosen Shi': 841,
    'Itoigawa': 841,
    'Itoigawa Shi': 841,
    'Jōetsu': 841,
    'Jōetsu Shi': 841,
    'Kameda-honchō': 841,
    'Kamo': 841,
    'Kamo Shi': 841,
    'Kashiwazaki': 841,
    'Kashiwazaki Shi': 841,
    'Maki': 841,
    'Minamiuonuma Shi': 841,
    'Mitsuke': 841,
    'Mitsuke Shi': 841,
    'Muikamachi': 841,
    'Murakami Shi': 841,
    'Muramatsu': 841,
    'Myoko-shi': 841,
    'Nagaoka': 841,
    'Nagaoka Shi': 841,
    'Niigata': 841,
    'Niigata Shi': 841,
    'Niitsu-honchō': 841,
    'Ojiya': 841,
    'Ojiya-shi': 841,
    'Ryōtsu-minato': 841,
    'Sado Shi': 841,
    'Sanjō Shi': 841,
    'Shibata': 841,
    'Shibata Shi': 841,
    'Shiozawa': 841,
    'Shirone': 841,
    'Suibara': 841,
    'Tainai': 841,
    'Tainai Shi': 841,
    'Tochio-honchō': 841,
    'Tōkamachi': 841,
    'Tōkamachi-shi': 841,
    'Tsubame': 841,
    'Tsubame Shi': 841,
    'Uonuma': 841,
    'Uonuma Shi': 841,
    'Yoshida-kasugachō': 841,
    'Beppu': 822,
    'Beppu Shi': 822,
    'Bungo-ōno Shi': 822,
    'Bungo-Takada-shi': 822,
    'Hiji': 822,
    'Hita': 822,
    'Hita Shi': 822,
    'Kitsuki': 822,
    'Kitsuki Shi': 822,
    'Kunisaki-shi': 822,
    'Nakatsu': 822,
    'Nakatsu Shi': 822,
    'Ōita': 822,
    'Ōita-shi': 822,
    'Saiki': 822,
    'Saiki-shi': 822,
    'Takedamachi': 822,
    'Taketa-shi': 822,
    'Tsukawaki': 822,
    'Tsukumi-shi': 822,
    'Tsukumiura': 822,
    'Tsurusaki': 822,
    'Usa Shi': 822,
    'Usuki': 822,
    'Usuki Shi': 822,
    'Yufu-shi': 822,
    'Akaiwa Shi': 820,
    'Asakuchi Shi': 820,
    'Bizen Shi': 820,
    'Ibara': 820,
    'Ibara Shi': 820,
    'Kamogatachō-kamogata': 820,
    'Kasaoka': 820,
    'Kasaoka Shi': 820,
    'Kurashiki': 820,
    'Kurashiki Shi': 820,
    'Maniwa-shi': 820,
    'Mimasaka-shi': 820,
    'Niimi': 820,
    'Niimi Shi': 820,
    'Okayama': 820,
    'Okayama Shi': 820,
    'Setouchi Shi': 820,
    'Sōja': 820,
    'Sōja Shi': 820,
    'Takahashi': 820,
    'Takahashi Shi': 820,
    'Tamano': 820,
    'Tamano Shi': 820,
    'Tsuyama': 820,
    'Tsuyama-shi': 820,
    'Chatan': 853,
    'Ginowan Shi': 853,
    'Gushikawa': 853,
    'Hirara': 853,
    'Ishigaki': 853,
    'Ishigaki-shi': 853,
    'Ishikawa': 853,
    'Itoman': 853,
    'Itoman Shi': 853,
    'Katsuren-haebaru': 853,
    'Miyakojima Shi': 853,
    'Nago': 853,
    'Nago Shi': 853,
    'Naha': 853,
    'Naha Shi': 853,
    'Nakagami-gun': 853,
    'Nanjō': 853,
    'Nanjō Shi': 853,
    'Okinawa': 853,
    'Okinawa Shi': 853,
    'Tomigusuku': 853,
    'Tomigusuku-shi': 853,
    'Urasoe Shi': 853,
    'Uruma Shi': 853,
    'Yonakuni': 853,
    'Daitō Shi': 859,
    'Daitōchō': 859,
    'Fujiidera-shi': 859,
    'Habikino-shi': 859,
    'Hannan': 859,
    'Hannan Shi': 859,
    'Higashiōsaka Shi': 859,
    'Hirakata': 859,
    'Hirakata Shi': 859,
    'Ibaraki': 859,
    'Ibaraki Shi': 859,
    'Ikeda': 859,
    'Ikeda Shi': 859,
    'Izumi': 859,
    'Izumi Shi': 859,
    'Izumiōtsu': 859,
    'Izumiōtsu Shi': 859,
    'Izumisano': 859,
    'Izumisano Shi': 859,
    'Kadoma': 859,
    'Kadoma Shi': 859,
    'Kaizuka': 859,
    'Kaizuka Shi': 859,
    'Kashihara': 859,
    'Kashiwara-shi': 859,
    'Katano Shi': 859,
    'Kawachi-nagano Shi': 859,
    'Kishiwada': 859,
    'Kishiwada Shi': 859,
    'Matsubara': 859,
    'Matsubara-shi': 859,
    'Mino': 859,
    'Minoo Shi': 859,
    'Mishima-gun': 859,
    'Moriguchi': 859,
    'Neyagawa': 859,
    'Neyagawa Shi': 859,
    'Osaka': 859,
    'Ōsaka-sayama Shi': 859,
    'Sakai': 859,
    'Sakai Shi': 859,
    'Sennan Shi': 859,
    'Settsu Shi': 859,
    'Shijonawate Shi': 859,
    'Suita': 859,
    'Suita Shi': 859,
    'Tajiri': 859,
    'Takaishi': 859,
    'Takaishi Shi': 859,
    'Takatsuki': 859,
    'Takatsuki Shi': 859,
    'Tondabayashi Shi': 859,
    'Tondabayashichō': 859,
    'Toyonaka': 859,
    'Toyonaka Shi': 859,
    'Yao': 859,
    'Yao-shi': 859,
    'Imari Shi': 863,
    'Imarichō-kō': 863,
    'Kanzaki Shi': 863,
    'Karatsu': 863,
    'Karatsu Shi': 863,
    'Kashima': 863,
    'Kashima Shi': 863,
    'Miyaki-gun': 863,
    'Ogi-shi': 863,
    'Saga': 863,
    'Saga Shi': 863,
    'Takeo Shi': 863,
    'Takeochō-takeo': 863,
    'Taku Shi': 863,
    'Tosu Shi': 863,
    'Ureshino Shi': 863,
    'Ageo Shi': 860,
    'Ageoshimo': 860,
    'Asaka': 860,
    'Asaka-shi': 860,
    'Chichibu': 860,
    'Chichibu-shi': 860,
    'Fujimi-shi': 860,
    'Fujimino Shi': 860,
    'Fukaya-shi': 860,
    'Fukayachō': 860,
    'Fukiage-fujimi': 860,
    'Gyōda': 860,
    'Hannō': 860,
    'Hannō-shi': 860,
    'Hanyū': 860,
    'Hanyū-shi': 860,
    'Hasuda': 860,
    'Hasuda-shi': 860,
    'Hatogaya-honchō': 860,
    'Hidaka-shi': 860,
    'Higashimatsuyama Shi': 860,
    'Hiki-gun': 860,
    'Honjō': 860,
    'Honjō Shi': 860,
    'Iruma-shi': 860,
    'Iwatsuki': 860,
    'Kamifukuoka': 860,
    'Kasukabe': 860,
    'Kasukabe Shi': 860,
    'Kawagoe': 860,
    'Kawagoe-shi': 860,
    'Kawaguchi': 860,
    'Kawaguchi-shi': 860,
    'Kazo': 860,
    'Kazo-shi': 860,
    'Kisai': 860,
    'Kitamoto Shi': 860,
    'Kodamachō-kodamaminami': 860,
    'Kōnosu': 860,
    'Konosu-Shi': 860,
    'Koshigaya Shi': 860,
    'Kuki-shi': 860,
    'Kukichūō': 860,
    'Kumagaya Shi': 860,
    'Kurihashi': 860,
    'Menuma': 860,
    'Misato Shi': 860,
    'Morohongō': 860,
    'Niiza-shi': 860,
    'Ogawa': 860,
    'Ōi': 860,
    'Okegawa': 860,
    'Okegawa-shi': 860,
    'Ōsato-gun': 860,
    'Saitama': 860,
    'Saitama-shi': 860,
    'Sakado': 860,
    'Sakado-shi': 860,
    'Satte': 860,
    'Satte Shi': 860,
    'Sayama': 860,
    'Shiki': 860,
    'Shiki Shi': 860,
    'Shimotoda': 860,
    'Shiraoka': 860,
    'Shiraoka-shi': 860,
    'Shōbu': 860,
    'Sōka': 860,
    'Sōka Shi': 860,
    'Sugito': 860,
    'Toda-shi': 860,
    'Tokorozawa': 860,
    'Tokorozawa-shi': 860,
    'Tsurugashima-shi': 860,
    'Wako': 860,
    'Wakō-shi': 860,
    'Warabi-shi': 860,
    'Yashio': 860,
    'Yashio-shi': 860,
    'Yono': 860,
    'Yorii': 860,
    'Yoshikawa': 860,
    'Yoshikawa Shi': 860,
    'Higashiōmi-shi': 845,
    'Hikone': 845,
    'Hikone-shi': 845,
    'Hino': 845,
    'Kitahama': 845,
    'Kōka-shi': 845,
    'Konan-shi': 845,
    'Kusatsu': 845,
    'Kusatsu-shi': 845,
    'Maibara': 845,
    'Maibara-shi': 845,
    'Minakuchichō-matoba': 845,
    'Moriyama': 845,
    'Moriyama-shi': 845,
    'Nagahama': 845,
    'Nagahama-shi': 845,
    'Ōmihachiman': 845,
    'Ōmihachiman-shi': 845,
    'Ōtsu-shi': 845,
    'Rittō-shi': 845,
    'Takashima': 845,
    'Takashima-shi': 845,
    'Yasu-shi': 845,
    'Youkaichi': 845,
    'Gōtsu Shi': 826,
    'Gōtsuchō': 826,
    'Hamada': 826,
    'Hamada Shi': 826,
    'Hiratachō': 826,
    'Izumo': 826,
    'Izumo Shi': 826,
    'Masuda': 826,
    'Masuda Shi': 826,
    'Matsue': 826,
    'Matsue Shi': 826,
    'Ōda Shi': 826,
    'Ōdachō-ōda': 826,
    'Unnan Shi': 826,
    'Yasugi Shi': 826,
    'Yasugichō': 826,
    'Atami': 825,
    'Atami-shi': 825,
    'Fuji Shi': 825,
    'Fujieda Shi': 825,
    'Fujinomiya': 825,
    'Fujinomiya Shi': 825,
    'Fukuroi': 825,
    'Fukuroi-shi': 825,
    'Gotenba Shi': 825,
    'Hamakita': 825,
    'Hamamatsu': 825,
    'Hamamatsu-shi': 825,
    'Heda': 825,
    'Itō': 825,
    'Itō Shi': 825,
    'Iwata': 825,
    'Iwata-shi': 825,
    'Izu': 825,
    'Izu-shi': 825,
    'Izunokuni-shi': 825,
    'Kakegawa': 825,
    'Kakegawa Shi': 825,
    'Kanaya': 825,
    'Kikugawa-shi': 825,
    'Kosai': 825,
    'Kosai-shi': 825,
    'Makinohara Shi': 825,
    'Mishima': 825,
    'Mishima Shi': 825,
    'Mori': 825,
    'Numazu-shi': 825,
    'Omaezaki-shi': 825,
    'Ōyama': 825,
    'Sagara': 825,
    'Shimada': 825,
    'Shimada-shi': 825,
    'Shimoda': 825,
    'Shimoda-shi': 825,
    'Shizuoka': 825,
    'Shizuoka-shi': 825,
    'Susono': 825,
    'Susono Shi': 825,
    'Yaizu': 825,
    'Yaizu Shi': 825,
    'Ashikaga': 854,
    'Fujioka': 854,
    'Imaichi': 854,
    'Kaminokawa': 854,
    'Kanuma': 854,
    'Kanuma-shi': 854,
    'Karasuyama': 854,
    'Kuroiso': 854,
    'Mashiko': 854,
    'Mibu': 854,
    'Mooka': 854,
    'Mooka-shi': 854,
    'Motegi': 854,
    'Nasukarasuyama': 854,
    'Nasukarasuyama-shi': 854,
    'Nasushiobara-shi': 854,
    'Nikkō': 854,
    'Nikko-shi': 854,
    'Ōtawara': 854,
    'Ōtawara-shi': 854,
    'Oyama': 854,
    'Oyama-shi': 854,
    'Sakura-shi': 854,
    'Sano': 854,
    'Sano-shi': 854,
    'Shimotsuke-shi': 854,
    'Tanuma': 854,
    'Tochigi-shi': 854,
    'Ujiie': 854,
    'Utsunomiya': 854,
    'Utsunomiya-shi': 854,
    'Yaita': 854,
    'Yaita-shi': 854,
    'Anan Shi': 836,
    'Awa-shi': 836,
    'Ikedachō': 836,
    'Ishii': 836,
    'Kamojimachō-jōgejima': 836,
    'Katsuura Gun': 836,
    'Komatsushima Shi': 836,
    'Komatsushimachō': 836,
    'Mima Shi': 836,
    'Miyoshi Shi': 836,
    'Miyoshi-gun': 836,
    'Naruto-shi': 836,
    'Narutochō-mitsuishi': 836,
    'Tokushima': 836,
    'Tokushima Shi': 836,
    'Wakimachi': 836,
    'Yoshinogawa Shi': 836,
    'Adachi Ku': 823,
    'Akiruno-shi': 823,
    'Akishima-shi': 823,
    'Arakawa Ku': 823,
    'Bunkyō-ku': 823,
    'Chiyoda-ku': 823,
    'Chōfu': 823,
    'Chōfu-shi': 823,
    'Chūō Ku': 823,
    'Edogawa Ku': 823,
    'Fuchū-shi': 823,
    'Fussa': 823,
    'Hachiōji': 823,
    'Hamura-shi': 823,
    'Higashi-murayama-shi': 823,
    'Higashikurume-shi': 823,
    'Higashimurayama': 823,
    'Higashiyamato': 823,
    'Higashiyamato-shi': 823,
    'Hino': 823,
    'Hino-shi': 823,
    'Inagi-shi': 823,
    'Itabashi-ku': 823,
    'Itsukaichi': 823,
    'Kamirenjaku': 823,
    'Katsushika Ku': 823,
    'Kita-ku': 823,
    'Kiyose-shi': 823,
    'Kodaira-shi': 823,
    'Koganei-shi': 823,
    'Kokubunji': 823,
    'Kokubunji-shi': 823,
    'Komae-shi': 823,
    'Kōtō-ku': 823,
    'Kunitachi-shi': 823,
    'Machida': 823,
    'Machida-shi': 823,
    'Meguro-ku': 823,
    'Minato-ku': 823,
    'Mitaka-shi': 823,
    'Musashimurayama-shi': 823,
    'Musashino': 823,
    'Musashino-shi': 823,
    'Nakano-ku': 823,
    'Nerima-ku': 823,
    'Nishi-Tokyo-shi': 823,
    'Nishitōkyō-shi': 823,
    'Ōme': 823,
    'Ōme-shi': 823,
    'Ōta-ku': 823,
    'Setagaya-ku': 823,
    'Shibuya-ku': 823,
    'Shinagawa-ku': 823,
    'Shinjuku-ku': 823,
    'Suginami-ku': 823,
    'Sumida-ku': 823,
    'Tachikawa-shi': 823,
    'Taitō-ku': 823,
    'Tama-shi': 823,
    'Tanashichō': 823,
    'Tokyo': 823,
    'Toshima-ku': 823,
    'Urayasu': 823,
    'Kurayoshi-shi': 850,
    'Sakaiminato': 850,
    'Sakaiminato Shi': 850,
    'Tottori-shi': 850,
    'Yonago Shi': 850,
    'Fukumitsu': 838,
    'Himi Shi': 838,
    'Himimachi': 838,
    'Imizu Shi': 838,
    'Kamiichi': 838,
    'Kurobe-shi': 838,
    'Namerikawa': 838,
    'Namerikawa-shi': 838,
    'Nanto Shi': 838,
    'Nanto-shi': 838,
    'Nishishinminato': 838,
    'Nyūzen': 838,
    'Oyabe': 838,
    'Oyabe Shi': 838,
    'Takaoka': 838,
    'Takaoka Shi': 838,
    'Tonami Shi': 838,
    'Toyama Shi': 838,
    'Uozu': 838,
    'Uozu Shi': 838,
    'Yatsuomachi-higashikumisaka': 838,
    'Arida Shi': 844,
    'Gobō': 844,
    'Hashimoto': 844,
    'Hashimoto Shi': 844,
    'Iwade': 844,
    'Iwade Shi': 844,
    'Kainan': 844,
    'Kainan Shi': 844,
    'Kinokawa Shi': 844,
    'Kōya': 844,
    'Minato': 844,
    'Shingū': 844,
    'Shingū-shi': 844,
    'Tanabe-shi': 844,
    'Wakayama': 844,
    'Wakayama Shi': 844,
    'Higashine': 837,
    'Higashine Shi': 837,
    'Kaminoyama': 837,
    'Kaminoyama-shi': 837,
    'Murayama': 837,
    'Murayama Shi': 837,
    'Nagai': 837,
    'Nagai-shi': 837,
    'Nanyō Shi': 837,
    'Obanazawa': 837,
    'Obanazawa Shi': 837,
    'Sagae': 837,
    'Sagae-shi': 837,
    'Sakata': 837,
    'Sakata Shi': 837,
    'Shinjō': 837,
    'Shinjō Shi': 837,
    'Takahata': 837,
    'Tendō': 837,
    'Tendō Shi': 837,
    'Tsuruoka': 837,
    'Tsuruoka Shi': 837,
    'Yamagata': 837,
    'Yamagata Shi': 837,
    'Yonezawa': 837,
    'Yonezawa Shi': 837,
    'Yuza': 837,
    'Hagi': 835,
    'Hagi Shi': 835,
    'Hikari': 835,
    'Hikari Shi': 835,
    'Hōfu': 835,
    'Hofu Shi': 835,
    'Iwakuni Shi': 835,
    'Kudamatsu': 835,
    'Kudamatsu Shi': 835,
    'Mine Shi': 835,
    'Nagato': 835,
    'Nagato Shi': 835,
    'Ogōri-shimogō': 835,
    'Onoda': 835,
    'Sanyōonoda Shi': 835,
    'Shimonoseki Shi': 835,
    'Shūnan Shi': 835,
    'Tokuyama': 835,
    'Ube': 835,
    'Yamaguchi Shi': 835,
    'Yanai Shi': 835,
    'Chūō-shi': 852,
    'Enzan': 852,
    'Fuefuki-shi': 852,
    'Fuji-yoshida Shi': 852,
    'Fujikawaguchiko': 852,
    'Fujiyoshida': 852,
    'Hokuto': 852,
    'Hokuto-shi': 852,
    'Isawa': 852,
    'Kai-shi': 852,
    'Kōfu': 852,
    'Kōfu-shi': 852,
    'Kōshū-shi': 852,
    'Minami Alps-shi': 852,
    'Nirasaki': 852,
    'Nirasaki-shi': 852,
    'Ōtsuki': 852,
    'Ōtsuki-shi': 852,
    'Ryūō': 852,
    'Tsuru-shi': 852,
    'Uenohara': 852,
    'Uenohara-shi': 852,
    'Yamanashi-shi': 852,
    '‘Ajlūn': 963,
    '‘Anjarah': 963,
    '‘Ayn Jannah': 963,
    'Ḩalāwah': 963,
    'Şakhrah': 963,
    'Al Jīzah': 965,
    'Al Jubayhah': 965,
    'Amman': 965,
    'Ḩayy al Bunayyāt': 965,
    'Ḩayy al Quwaysimah': 965,
    'Jāwā': 965,
    'Saḩāb': 965,
    'Umm as Summāq': 965,
    'Wādī as Sīr': 965,
    'Aqaba': 959,
    'Tala Bay': 959,
    'Al Karāmah': 961,
    'As Salţ': 961,
    'Yarqā': 961,
    'Ar Ramthā': 960,
    'Ash Shajarah': 960,
    'Aţ Ţayyibah': 960,
    'Aţ Ţurrah': 960,
    'Aydūn': 960,
    'Bayt Īdis': 960,
    'Bayt Yāfā': 960,
    'Dayr Yūsuf': 960,
    'Ḩakamā': 960,
    'Ḩātim': 960,
    'Irbid': 960,
    'Judita': 960,
    'Kafr Abīl': 960,
    'Kafr Asad': 960,
    'Kafr Sawm': 960,
    'Kharjā': 960,
    'Kitim': 960,
    'Kurayyimah': 960,
    'Malkā': 960,
    'Qumaym': 960,
    'Saḩam al Kaffārāt': 960,
    'Sāl': 960,
    'Şammā': 960,
    'Tibnah': 960,
    'Umm Qays': 960,
    'Waqqāş': 960,
    'Zaḩar': 960,
    'Al Kittah': 966,
    'Balīlā': 966,
    'Burmā': 966,
    'Jarash': 966,
    'Qafqafā': 966,
    'Raymūn': 966,
    'Sakib': 966,
    'Sūf': 966,
    '‘Ayy': 956,
    '‘Izrā': 956,
    'Adir': 956,
    'Al Khinzīrah': 956,
    'Al Mazār al Janūbī': 956,
    'Al Qaşr': 956,
    'Ar Rabbah': 956,
    'Karak City': 956,
    'Safi': 956,
    'Al Jafr': 964,
    'Al Quwayrah': 964,
    'Ash Shawbak': 964,
    'Aţ Ţayyibah': 964,
    'Ma  an': 964,
    'Petra': 964,
    'Qīr Moāv': 964,
    'Mādabā': 958,
    'Al Ḩamrā’': 962,
    'Mafraq': 962,
    'Rehab': 962,
    'Rukban': 962,
    'Şabḩā': 962,
    'Umm al Qiţţayn': 962,
    'Aţ Ţafīlah': 957,
    'Buşayrā': 957,
    'Al Azraq ash Shamālī': 967,
    'Russeifa': 967,
    'Zarqa': 967,
    'Akkol': 145,
    'Akkol’': 145,
    'Aksu': 145,
    'Astrakhan': 145,
    'Atbasar': 145,
    'Balkashino': 145,
    'Bestobe': 145,
    'Derzhavīnsk': 145,
    'Egindiköl': 145,
    'Esil': 145,
    'Kokshetau': 145,
    'Krasnogorskiy': 145,
    'Makinsk': 145,
    'Shantobe': 145,
    'Shchuchinsk': 145,
    'Shortandy': 145,
    'Stepnogorsk': 145,
    'Stepnyak': 145,
    'Yermentau': 145,
    'Zavodskoy': 145,
    'Zhaqsy': 145,
    'Zholymbet': 145,
    'Aktobe': 151,
    'Batamshinskiy': 151,
    'Bayganin': 151,
    'Embi': 151,
    'Kandyagash': 151,
    'Khromtau': 151,
    'Martuk': 151,
    'Shalqar': 151,
    'Shubarkuduk': 151,
    'Shubarshi': 151,
    'Temir': 151,
    'Yrghyz': 151,
    'Almaty': 152,
    'Bakanas': 143,
    'Balpyk Bī': 143,
    'Burunday': 143,
    'Chemolgan': 143,
    'Druzhba': 143,
    'Esik': 143,
    'Kapshagay': 143,
    'Kegen': 143,
    'Lepsy': 143,
    'Matay': 143,
    'Otegen Batyra': 143,
    'Pervomayka': 143,
    'Sarkand': 143,
    'Saryozek': 143,
    'Taldykorgan': 143,
    'Talghar': 143,
    'Tekeli': 143,
    'Turgen': 143,
    'Ülken': 143,
    'Ush-Tyube': 143,
    'Zharkent': 143,
    'Akkol’': 153,
    'Atyrau': 153,
    'Balykshi': 153,
    'Bayshonas': 153,
    'Dossor': 153,
    'Inderbor': 153,
    'Makhambet': 153,
    'Maloye Ganyushkino': 153,
    'Maqat': 153,
    'Miyaly': 153,
    'Qaraton': 153,
    'Qulsary': 153,
    'Shalkar': 153,
    'Baikonur': 155,
    'Altayskiy': 154,
    'Aqtoghay': 154,
    'Asūbulaq': 154,
    'Auezov': 154,
    'Ayagoz': 154,
    'Belogorskīy': 154,
    'Belousovka': 154,
    'Borodulikha': 154,
    'Georgīevka': 154,
    'Glubokoye': 154,
    'Kurchatov': 154,
    'Kurchum': 154,
    'Maleyevsk': 154,
    'Ognevka': 154,
    'Priisk Boko': 154,
    'Qaraūyl': 154,
    'Ridder': 154,
    'Semey': 154,
    'Shar': 154,
    'Shemonaīkha': 154,
    'Suykbulak': 154,
    'Tūghyl': 154,
    'Urzhar': 154,
    'Ust-Kamenogorsk': 154,
    'Ūst’-Talovka': 154,
    'Zaysan': 154,
    'Zhalghyztobe': 154,
    'Zhanga Buqtyrma': 154,
    'Zhezkent': 154,
    'Zyryanovsk': 154,
    'Aqbaqay': 147,
    'Chu': 147,
    'Georgiyevka': 147,
    'Granitogorsk': 147,
    'Karatau': 147,
    'Khantaū': 147,
    'Lugovoy': 147,
    'Lugovoye': 147,
    'Merke': 147,
    'Moyynkum': 147,
    'Mynaral': 147,
    'Oytal': 147,
    'Sarykemer': 147,
    'Shyghanaq': 147,
    'Taraz': 147,
    'Zhangatas': 147,
    'Abay': 150,
    'Abay Qalasy': 150,
    'Aksu-Ayuly': 150,
    'Aktas': 150,
    'Aktau': 150,
    'Aktogay': 150,
    'Aqadyr': 150,
    'Aqshataū': 150,
    'Aqtoghay Aūdany': 150,
    'Atasū': 150,
    'Balqash': 150,
    'Bukhar-Zhyrau': 150,
    'Dolinka': 150,
    'Karagandy': 150,
    'Kīevka': 150,
    'Koktal': 150,
    'Kushoky': 150,
    'Kyzylzhar': 150,
    'Moyynty': 150,
    'Novodolinskiy': 150,
    'Osakarovka': 150,
    'Prigorodnoye': 150,
    'Priozersk': 150,
    'Qarazhal': 150,
    'Qarqaraly': 150,
    'Saryshaghan': 150,
    'Sayaq': 150,
    'Shakhan': 150,
    'Shakhtinsk': 150,
    'Shashūbay': 150,
    'Shubarköl': 150,
    'Soran': 150,
    'Temirtau': 150,
    'Tokarevka': 150,
    'Verkhniye Kayrakty': 150,
    'Zhambyl': 150,
    'Zharyk': 150,
    'Zhezqazghan': 150,
    'Arkalyk': 157,
    'Ayat': 157,
    'Borovskoy': 157,
    'Dzhetygara': 157,
    'Fyodorovka': 157,
    'Karasu': 157,
    'Komsomolets': 157,
    'Kostanay': 157,
    'Lisakovsk': 157,
    'Ordzhonikidze': 157,
    'Qashar': 157,
    'Qusmuryn': 157,
    'Rudnyy': 157,
    'Tobol': 157,
    'Torghay': 157,
    'Troyebratskiy': 157,
    'Aral': 142,
    'Ayteke Bi': 142,
    'Belköl': 142,
    'Dzhalagash': 142,
    'Kyzylorda': 142,
    'Qazaly': 142,
    'Sekseūil': 142,
    'Shalqīya': 142,
    'Shīeli': 142,
    'Tasböget': 142,
    'Terenozek': 142,
    'Yanykurgan': 142,
    'Zhosaly': 142,
    'Aktau': 141,
    'Baūtīno': 141,
    'Beyneu': 141,
    'Fort-Shevchenko': 141,
    'Munayshy': 141,
    'Ömirzaq': 141,
    'Sayötesh': 141,
    'Shetpe': 141,
    'Taūshyq': 141,
    'Yeraliyev': 141,
    'Zhanaozen': 141,
    'Zhetibay': 141,
    'Birlestik': 144,
    'Bishkul': 144,
    'Bulayevo': 144,
    'Būrabay': 144,
    'Kzyltu': 144,
    'Novoishimskiy': 144,
    'Petropavl': 144,
    'Sergeyevka': 144,
    'Smirnovo': 144,
    'Taiynsha': 144,
    'Talshik': 144,
    'Timiryazevo': 144,
    'Volodarskoye': 144,
    'Yavlenka': 144,
    'Nur-Sultan': 156,
    'Aksu': 146,
    'Bayanaul': 146,
    'Belogor’ye': 146,
    'Ekibastuz': 146,
    'Irtyshsk': 146,
    'Kalkaman': 146,
    'Leninskiy': 146,
    'Mayqayyng': 146,
    'Pavlodar': 146,
    'Qashyr': 146,
    'Zhelezinka': 146,
    'Arys': 149,
    'Ashchysay': 149,
    'Asyqata': 149,
    'Atakent': 149,
    'Bayzhansay': 149,
    'Belyye Vody': 149,
    'Chardara': 149,
    'Chayan': 149,
    'Chulakkurgan': 149,
    'Kantagi': 149,
    'Kentau': 149,
    'Kokterek': 149,
    'Lenger': 149,
    'Leninskoye': 149,
    'Maqtaaral Aūdany': 149,
    'Myrzakent': 149,
    'Qogham': 149,
    'Saryaghash': 149,
    'Saryaghash Aūdany': 149,
    'Sastobe': 149,
    'Sozaq Aūdany': 149,
    'Temirlanovka': 149,
    'Turar Ryskulov': 149,
    'Turkestan': 149,
    'Tyul’kubas': 149,
    'Zhabagly': 149,
    'Aqsay': 148,
    'Burlin': 148,
    'Chapaev': 148,
    'Chingirlau': 148,
    'Dzhambeyty': 148,
    'Fedorovka': 148,
    'Kaztalovka': 148,
    'Krūgloozernoe': 148,
    'Oral': 148,
    'Peremetnoe': 148,
    'Saykhin': 148,
    'Tasqala': 148,
    'Zhänibek': 148,
    'Baringo': 181,
    'Eldama Ravine': 181,
    'Kabarnet': 181,
    'Sotik': 210,
    'Sotik Post': 210,
    'Bungoma': 168,
    'Malikisi': 168,
    'Webuye': 168,
    'Busia': 161,
    'Luanda': 161,
    'Lugulu': 161,
    'Malaba': 161,
    'Nambare': 161,
    'Port Victoria': 161,
    'Embu': 163,
    'Garissa': 196,
    'Homa Bay': 195,
    'Oyugis': 195,
    'Rachuonyo District': 195,
    'Isiolo': 170,
    'Kajiado': 197,
    'Magadi': 197,
    'Ngong': 197,
    'Butere': 158,
    'Kakamega': 158,
    'Mumias': 158,
    'Kericho': 193,
    'Kipkelion': 193,
    'Litein': 193,
    'Londiani': 193,
    'Kiambu': 199,
    'Kikuyu': 199,
    'Limuru': 199,
    'Iten': 174,
    'Kapsowar': 174,
    'Kilifi': 174,
    'Malindi': 174,
    'Mariakani': 174,
    'Takaungu': 174,
    'Kerugoya': 167,
    'Sagana': 167,
    'Kisii': 159,
    'Ogembo': 159,
    'Ahero': 171,
    'Kisumu': 171,
    'Muhoroni': 171,
    'Kitui': 211,
    'Mwingi': 211,
    'Gazi': 173,
    'Kinango': 173,
    'Kwale': 173,
    'Sawa Sawa': 173,
    'Shimoni': 173,
    'Nanyuki': 164,
    'Nyahururu': 164,
    'Rumuruti': 164,
    'Lamu': 166,
    'Witu': 166,
    'Athi River': 184,
    'Kangundo': 184,
    'Konza': 184,
    'Machakos': 184,
    'Makueni Boma': 188,
    'Mtito Andei': 188,
    'Wote': 188,
    'Mandera': 187,
    'Marsabit': 194,
    'Moyale': 194,
    'Maua': 198,
    'Meru': 198,
    'Kihancha': 190,
    'Migori': 190,
    'Mombasa': 200,
    'Kangema': 178,
    'Karuri': 178,
    'Maragua': 178,
    'Murang’a': 178,
    'Nairobi': 191,
    'Pumwani': 191,
    'Thika': 191,
    'Kijabe': 203,
    'Molo': 203,
    'Naivasha': 203,
    'Nakuru': 203,
    'Rongai': 203,
    'Kapsabet': 165,
    'Nandi Hills': 165,
    'Narok': 175,
    'Keroka': 209,
    'Nyamira': 209,
    'Ol Kalou': 192,
    'Naro Moru': 180,
    'Nyeri': 180,
    'Othaya': 180,
    'Maralal': 207,
    'Bondo': 186,
    'Siaya': 186,
    'Yala': 186,
    'Hola': 205,
    'Kipini': 205,
    'Chuka': 185,
    'Kitale': 183,
    'Lodwar': 206,
    'Eldoret': 169,
    'Wajir': 182,
    'Chepareria': 208,
    'Kapenguria': 208,
    'Taveta': 208,
    'Voi': 208,
    'Wundanyi': 208,
    'Abaiang': 1831,
    'Abemama': 1831,
    'Ambo Village': 1831,
    'Aranuka': 1831,
    'Arorae': 1831,
    'Bairiki Village': 1831,
    'Banaba': 1831,
    'Banraeaba Village': 1831,
    'Beru': 1831,
    'Betio Village': 1831,
    'Bikenibeu Village': 1831,
    'Bonriki Village': 1831,
    'Buota Village': 1831,
    'Butaritari': 1831,
    'Eita Village': 1831,
    'Kuria': 1831,
    'Maiana': 1831,
    'Makin': 1831,
    'Makin Village': 1831,
    'Marakei': 1831,
    'Nawerewere Village': 1831,
    'Nikunau': 1831,
    'Nonouti': 1831,
    'Onotoa': 1831,
    'Rawannawi Village': 1831,
    'Tabiteuea': 1831,
    'Tamana': 1831,
    'Tarawa': 1831,
    'Teaoraereke Village': 1831,
    'Temaiku Village': 1831,
    'Banana Village': 1832,
    'Kiritimati': 1832,
    'London Village': 1832,
    'Napari Village': 1832,
    'Tabuaeran': 1832,
    'Tabwakea Village': 1832,
    'Teraina': 1832,
    'Kanton': 1830,
    'Changgang-gun': 3998,
    'Chasŏng': 3998,
    'Kanggye': 3998,
    'Manp’o': 3998,
    'Anbyŏn-ŭp': 3999,
    'Hoeyang': 3999,
    'Kosan': 3999,
    'T’ongch’ŏn-ŭp': 3999,
    'Wŏnsan': 3999,
    'Aoji': 3995,
    'Chongjin': 3995,
    'Hau-ri': 3995,
    'Hoemul-li': 3995,
    'Hoeryŏng': 3995,
    'Kilju': 3995,
    'Kimch’aek-si': 3995,
    'Komusan Il-tong': 3995,
    'Kyŏngsŏng': 3995,
    'Kyŏngwŏn': 3995,
    'Musan-ŭp': 3995,
    'Namyang-dong': 3995,
    'Nanam': 3995,
    'Onsŏng': 3995,
    'Puryŏng': 3995,
    'Sŭngam-nodongjagu': 3995,
    'Anak': 4004,
    'Hŭkkyo-ri': 4004,
    'Hwangju-ŭp': 4004,
    'Koksan': 4004,
    'Sariwŏn': 4004,
    'Sinmak': 4004,
    'Songnim': 4004,
    'Chŏngju': 4002,
    'Chŏngju-gun': 4002,
    'Kujang-ŭp': 4002,
    'Kusŏng': 4002,
    'Kwaksan': 4002,
    'Panghyŏn-dong': 4002,
    'Pukchil-lodongjagu': 4002,
    'Sakchu-ŭp': 4002,
    'Sinŭiju': 4002,
    'Ŭiju': 4002,
    'Yŏmju-ŭp': 4002,
    'Yŏngbyŏn': 4002,
    'Chunghwa': 4005,
    'Kangdong-ŭp': 4005,
    'Pyongyang': 4005,
    'Sunan': 4005,
    'Sŭngho 1-tong': 4005,
    'Sŏnbong': 4001,
    'Ungsang-nodongjagu': 4001,
    'Hyesan': 3996,
    'Hyesan-dong': 3996,
    'Kapsan-ŭp': 3996,
    'Sŭngjibaegam': 3996,
    'Hamhŭng': 4000,
    'Hongwŏn': 4000,
    'Hŭngnam': 4000,
    'Iwŏn-ŭp': 4000,
    'Kowŏn-ŭp': 4000,
    'Samho-rodongjagu': 4000,
    'Sinsang-ni': 4000,
    'Yŏnggwang-ŭp': 4000,
    'Yuktae-dong': 4000,
    'Ayang-ni': 4003,
    'Chaeryŏng-ŭp': 4003,
    'Changyŏn': 4003,
    'Haeju': 4003,
    'Kaesŏng': 4003,
    'Ongjin': 4003,
    'Pyŏksŏng-ŭp': 4003,
    'Yŏnan-ŭp': 4003,
    'Anju': 3997,
    'Namp’o': 3997,
    'P’yŏngsŏng': 3997,
    'Sangsŏng-ni': 3997,
    'Sil-li': 3997,
    'Sinanju': 3997,
    'Sunch’ŏn': 3997,
    'Yonggang-ŭp': 3997,
    'Buk-gu': 3860,
    'Busan': 3860,
    'Dongnae-gu': 3860,
    'Gangseo-gu': 3860,
    'Geumjeong-gu': 3860,
    'Gijang': 3860,
    'Gijang-gun': 3860,
    'Haeundae-gu': 3860,
    'Jung-gu': 3860,
    'Nam-gu': 3860,
    'Saha-gu': 3860,
    'Sasang-gu': 3860,
    'Seo-gu': 3860,
    'Suyeong-gu': 3860,
    'Yeongdo-gu': 3860,
    'Daegu': 3846,
    'Dalseo-gu': 3846,
    'Dalseong-gun': 3846,
    'Dong-gu': 3846,
    'Hwawŏn': 3846,
    'Jung-gu': 3846,
    'Seo-gu': 3846,
    'Suseong-gu': 3846,
    'Daejeon': 3850,
    'Seo-gu': 3850,
    'Sintansin': 3850,
    'Songgangdong': 3850,
    'Yuseong-gu': 3850,
    'Cheorwon-gun': 3862,
    'Chuncheon': 3862,
    'Chuncheon-si': 3862,
    'Donghae-si': 3862,
    'Gangneung': 3862,
    'Gangneung-si': 3862,
    'Goseong-gun': 3862,
    'Hoengseong-gun': 3862,
    'Hongch’ŏn': 3862,
    'Hongcheon-gun': 3862,
    'Hwacheon': 3862,
    'Hwacheon-gun': 3862,
    'Inje-gun': 3862,
    'Jeongseon-gun': 3862,
    'Kosong': 3862,
    'Neietsu': 3862,
    'Pyeongchang': 3862,
    'Pyeongchang-gun': 3862,
    'Samcheok-si': 3862,
    'Santyoku': 3862,
    'Sokcho': 3862,
    'Sokcho-si': 3862,
    'T’aebaek': 3862,
    'Taebaek-si': 3862,
    'Tonghae': 3862,
    'Wŏnju': 3862,
    'Wonju-si': 3862,
    'Yanggu': 3862,
    'Yanggu-gun': 3862,
    'Yangyang-gun': 3862,
    'Gwangju': 3858,
    'Gwangsan-gu': 3858,
    'Masan': 3858,
    'Ansan-si': 3847,
    'Anseong': 3847,
    'Anyang-si': 3847,
    'Bucheon-si': 3847,
    'Dongducheon': 3847,
    'Gapyeong': 3847,
    'Gapyeong-gun': 3847,
    'Gimpo-si': 3847,
    'Goyang-si': 3847,
    'Gunpo-si': 3847,
    'Guri-si': 3847,
    'Gwacheon-si': 3847,
    'Gwangju': 3847,
    'Gwangju-si': 3847,
    'Gwangmyeong-si': 3847,
    'Hanam': 3847,
    'Hanam-si': 3847,
    'Hwado': 3847,
    'Hwaseong-si': 3847,
    'Icheon-si': 3847,
    'Kwangmyŏng': 3847,
    'Munsan': 3847,
    'Namyangju': 3847,
    'Namyangju-si': 3847,
    'Osan': 3847,
    'Osan-si': 3847,
    'Paju-si': 3847,
    'Pocheon-si': 3847,
    'Pubal': 3847,
    'Pyeongtaek': 3847,
    'Seongnam-si': 3847,
    'Siheung': 3847,
    'Suwon': 3847,
    'Uijeongbu-si': 3847,
    'Uiwang-si': 3847,
    'Wabu': 3847,
    'Yangju': 3847,
    'Yangju-si': 3847,
    'Yangp  yŏng': 3847,
    'Yangpyeong-gun': 3847,
    'Yeoju-si': 3847,
    'Yeoncheon-gun': 3847,
    'Yongin-si': 3847,
    'Bupyeong-gu': 3848,
    'Incheon': 3848,
    'Michuhol': 3848,
    'Yeonsu-gu': 3848,
    'Gaigeturi': 3853,
    'Jeju City': 3853,
    'Jeju-si': 3853,
    'Seogwipo': 3853,
    'Boeun-gun': 3854,
    'Cheongju-si': 3854,
    'Chinch  ŏn': 3854,
    'Chungju-si': 3854,
    'Danyang-gun': 3854,
    'Eumseong-gun': 3854,
    'Goesan-gun': 3854,
    'Jecheon-si': 3854,
    'Jeungpyeong-gun': 3854,
    'Jincheon-gun': 3854,
    'Koesan': 3854,
    'Okcheon': 3854,
    'Okcheon-gun': 3854,
    'Yeongdong': 3854,
    'Yeongdong-gun': 3854,
    'Andong': 3855,
    'Andong-si': 3855,
    'Bonghwa-gun': 3855,
    'Cheongdo-gun': 3855,
    'Cheongsong gun': 3855,
    'Cheongsong-gun': 3855,
    'Chilgok-gun': 3855,
    'Eisen': 3855,
    'Gimcheon': 3855,
    'Gimcheon-si': 3855,
    'Goryeong-gun': 3855,
    'Gumi-si': 3855,
    'Gunwi-gun': 3855,
    'Gyeongju': 3855,
    'Gyeongsan-si': 3855,
    'Hayang': 3855,
    'Heunghae': 3855,
    'Jenzan': 3855,
    'Kunwi': 3855,
    'Mungyeong': 3855,
    'Mungyeong-si': 3855,
    'Pohang': 3855,
    'Pohang-si': 3855,
    'Sangju': 3855,
    'Seongju-gun': 3855,
    'Uiseong-gun': 3855,
    'Uljin-gun': 3855,
    'Ulleung-gun': 3855,
    'Waegwan': 3855,
    'Yecheon-gun': 3855,
    'Yeongcheon-si': 3855,
    'Yeongdeok-gun': 3855,
    'Yeongju-si': 3855,
    'Yeongyang-gun': 3855,
    'Yeonil': 3855,
    'Buan-gun': 3851,
    'Changsu': 3851,
    'Gimje-si': 3851,
    'Gochang-gun': 3851,
    'Gunsan': 3851,
    'Gunsan-si': 3851,
    'Iksan': 3851,
    'Iksan-si': 3851,
    'Imsil': 3851,
    'Imsil-gun': 3851,
    'Jangsu-gun': 3851,
    'Jeongeup': 3851,
    'Jeongeup-si': 3851,
    'Jeonju': 3851,
    'Jeonju-si': 3851,
    'Jinan-gun': 3851,
    'Kimje': 3851,
    'Koch  ang': 3851,
    'Muju-gun': 3851,
    'Namwon': 3851,
    'Nangen': 3851,
    'Puan': 3851,
    'Sunchang-gun': 3851,
    'Wanju': 3851,
    'Sejong': 3861,
    'Dobong-gu': 3849,
    'Dongdaemun-gu': 3849,
    'Dongjak-gu': 3849,
    'Eunpyeong-gu': 3849,
    'Gangbuk-gu': 3849,
    'Gangdong-gu': 3849,
    'Gangnam-gu': 3849,
    'Gangseo-gu': 3849,
    'Geumcheon-gu': 3849,
    'Guro-gu': 3849,
    'Gwanak-gu': 3849,
    'Gwangjin-gu': 3849,
    'Jongno-gu': 3849,
    'Jung-gu': 3849,
    'Jungnang-gu': 3849,
    'Mapo-gu': 3849,
    'Nowon-gu': 3849,
    'Seocho-gu': 3849,
    'Seodaemun-gu': 3849,
    'Seongbuk-gu': 3849,
    'Seongdong-gu': 3849,
    'Seoul': 3849,
    'Songpa-gu': 3849,
    'Yangcheon-gu': 3849,
    'Yeongdeungpo-gu': 3849,
    'Yongsan-dong': 3849,
    'Yongsan-gu': 3849,
    'Asan': 3859,
    'Asan-si': 3859,
    'Boryeong': 3859,
    'Boryeong-si': 3859,
    'Buyeo': 3859,
    'Buyeo-gun': 3859,
    'Cheonan': 3859,
    'Cheonan-si': 3859,
    'Cheongyang-gun': 3859,
    'Geumsan-gun': 3859,
    'Gongju': 3859,
    'Gongju-si': 3859,
    'Gyeryong-si': 3859,
    'Hongseong': 3859,
    'Hongseong-gun': 3859,
    'Kinzan': 3859,
    'Nonsan': 3859,
    'Nonsan-si': 3859,
    'Seocheon-gun': 3859,
    'Seonghwan': 3859,
    'Seosan': 3859,
    'Seosan-si': 3859,
    'Taean-gun': 3859,
    'Taesal-li': 3859,
    'Tangjin': 3859,
    'Yesan': 3859,
    'Yesan-gun': 3859,
    'Yŏnmu': 3859,
    'Changnyeong': 3857,
    'Changnyeong-gun': 3857,
    'Changwon': 3857,
    'Changwon-si': 3857,
    'Chinju': 3857,
    'Geochang-gun': 3857,
    'Geoje-si': 3857,
    'Gimhae-si': 3857,
    'Goseong': 3857,
    'Goseong-gun': 3857,
    'Hadong-gun': 3857,
    'Haman-gun': 3857,
    'Hapcheon-gun': 3857,
    'Jinju-si': 3857,
    'Kimhae': 3857,
    'Kyosai': 3857,
    'Miryang': 3857,
    'Miryang-si': 3857,
    'Naesŏ': 3857,
    'Namhae-gun': 3857,
    'Sacheon-si': 3857,
    'Sancheong-gun': 3857,
    'Sinhyeon': 3857,
    'Uiryeong-gun': 3857,
    'Ungsang': 3857,
    'Yangsan': 3857,
    'Beolgyo': 3856,
    'Boseong-gun': 3856,
    'Damyang-gun': 3856,
    'Gangjin-gun': 3856,
    'Goheung-gun': 3856,
    'Gokseong-gun': 3856,
    'Gurye-gun': 3856,
    'Gwangyang': 3856,
    'Gwangyang-si': 3856,
    'Haenam': 3856,
    'Haenam-gun': 3856,
    'Hampyeong-gun': 3856,
    'Hwasun': 3856,
    'Hwasun-gun': 3856,
    'Jangheung-gun': 3856,
    'Jangseong-gun': 3856,
    'Jindo-gun': 3856,
    'Kurye': 3856,
    'Kwangyang': 3856,
    'Mokpo': 3856,
    'Mokpo-si': 3856,
    'Muan': 3856,
    'Muan-gun': 3856,
    'Naju': 3856,
    'Naju-si': 3856,
    'Sinan': 3856,
    'Sinan-gun': 3856,
    'Suncheon': 3856,
    'Suncheon-si': 3856,
    'Wando-gun': 3856,
    'Yeongam': 3856,
    'Yeongam-gun': 3856,
    'Yeonggwang': 3856,
    'Yeonggwang-gun': 3856,
    'Yeosu': 3856,
    'Buk-gu': 3852,
    'Dong-gu': 3852,
    'Jung-gu': 3852,
    'Nam-gu': 3852,
    'Ulju-gun': 3852,
    'Ulsan': 3852,
    'Al Aḩmadī': 977,
    'Al Faḩāḩīl': 977,
    'Al Finţās': 977,
    'Al Mahbūlah': 977,
    'Al Manqaf': 977,
    'Al Wafrah': 977,
    'Ar Riqqah': 977,
    'Al Farwānīyah': 975,
    'Janūb as Surrah': 975,
    'Al Jahrā’': 972,
    'Ad Dasmah': 976,
    'Ar Rābiyah': 976,
    'Ash Shāmīyah': 976,
    'Az Zawr': 976,
    'Kuwait City': 976,
    'Ar Rumaythīyah': 973,
    'As Sālimīyah': 973,
    'Bayān': 973,
    'Ḩawallī': 973,
    'Salwá': 973,
    'Abu Al Hasaniya': 974,
    'Abu Fatira': 974,
    'Al Funayţīs': 974,
    'Al-Masayel': 974,
    'Şabāḩ as Sālim': 974,
    'Aydarken': 998,
    'Batken': 998,
    'Iradan': 998,
    'Isfana': 998,
    'Karavan': 998,
    'Kyzyl-Kyya': 998,
    'Suluktu': 998,
    'Bishkek': 1001,
    'Alamudunskiy Rayon': 1004,
    'Belovodskoye': 1004,
    'Chuyskiy Rayon': 1004,
    'Ivanovka': 1004,
    'Kaindy': 1004,
    'Kant': 1004,
    'Kara-Balta': 1004,
    'Kemin': 1004,
    'Lebedinovka': 1004,
    'Sokulukskiy Rayon': 1004,
    'Sosnovka': 1004,
    'Tokmok': 1004,
    'Ysyk-Ata': 1004,
    'Ak-Suu': 1002,
    'Balykchy': 1002,
    'Cholpon-Ata': 1002,
    'Kadzhi-Say': 1002,
    'Karakol': 1002,
    'Kyzyl-Suu': 1002,
    'Tyup': 1002,
    'Ala-Buka': 1000,
    'Bazar-Korgon': 1000,
    'Jalal-Abad': 1000,
    'Kazarman': 1000,
    'Kerben': 1000,
    'Kochkor-Ata': 1000,
    'Suzak': 1000,
    'Tash-Kumyr': 1000,
    'Toktogul': 1000,
    'Toktogul District': 1000,
    'At-Bashi': 999,
    'Jumgal': 999,
    'Naryn': 999,
    'Chong-Alay District': 1005,
    'Daroot-Korgon': 1005,
    'Kara Kulja': 1005,
    'Kara Suu': 1005,
    'Kyzyl-Eshme': 1005,
    'Nookat': 1005,
    'Osh': 1005,
    'Uzgen': 1005,
    'Uzgen District': 1005,
    'Kara-Buurinskiy Rayon': 997,
    'Talas': 997,
    'Talasskiy Rayon': 997,
    'Attapeu': 982,
    'Muang Phouvong': 982,
    'Muang Samakhixai': 982,
    'Muang Sanamxai': 982,
    'Muang Sanxai': 982,
    'Muang Xaiséttha': 982,
    'Ban Houakhoua': 991,
    'Ban Houayxay': 991,
    'Muang Houayxay': 991,
    'Muang Meung': 991,
    'Muang Paktha': 991,
    'Muang Pha Oudôm': 991,
    'Muang Tônpheung': 991,
    'Ban Nahin': 985,
    'Pakxan': 985,
    'Champasak': 996,
    'Muang Bachiangchaleunsook': 996,
    'Muang Champasak': 996,
    'Muang Không': 996,
    'Muang Mounlapamôk': 996,
    'Muang Pakxé': 996,
    'Muang Pakxong': 996,
    'Muang Pathoumphon': 996,
    'Muang Phônthong': 996,
    'Muang Soukhouma': 996,
    'Muang Xanasômboun': 996,
    'Pakse': 996,
    'Pakxong': 996,
    'Xam Neua': 989,
    'Xam Nua': 989,
    'Muang Thakhèk': 986,
    'Thakhèk': 986,
    'Luang Namtha': 992,
    'Muang Louang Namtha': 992,
    'Luang Prabang': 978,
    'Muang Xay': 988,
    'Phôngsali': 987,
    'Sainyabuli': 993,
    'Muang Khôngxédôn': 981,
    'Muang Lakhonphéng': 981,
    'Muang Laongam': 981,
    'Muang Samouay': 981,
    'Muang Saravan': 981,
    'Muang Ta-Ôy': 981,
    'Muang Toumlan': 981,
    'Muang Vapi': 981,
    'Salavan': 981,
    'Kaysone Phomvihane': 990,
    'Muang Alsaphangthong': 990,
    'Muang Atsaphan': 990,
    'Muang Champhon': 990,
    'Muang Nong': 990,
    'Muang Outhoumphon': 990,
    'Muang Phin': 990,
    'Muang Songkhon': 990,
    'Muang Thapangthong': 990,
    'Muang Vilabouli': 990,
    'Muang Xaibouli': 990,
    'Muang Xayphoothong': 990,
    'Muang Xônbouli': 990,
    'Savannakhet': 990,
    'Thaphalanxay': 990,
    'Ban Thatèng': 984,
    'Lamam': 984,
    'Muang Dakchung': 984,
    'Muang Khaleum': 984,
    'Muang Laman': 984,
    'Muang Thatèng': 984,
    'Vientiane': 979,
    'Muang Phôn-Hông': 980,
    'Vangviang': 980,
    'Anouvong district': 983,
    'Longchaeng': 983,
    'Muang Longxan': 983,
    'Muang Thathôm': 983,
    'Muang Phônsavan': 995,
    'Aglona': 4445,
    'Aizkraukle': 4472,
    'Aizpute': 4496,
    'Aknīste': 4499,
    'Aloja': 4484,
    'Staicele': 4484,
    'Alsunga': 4485,
    'Alūksne': 4487,
    'Ape': 4457,
    'Auce': 4481,
    'Piņķi': 4427,
    'Baldone': 4482,
    'Baltinava': 4498,
    'Balvi': 4505,
    'Bauska': 4465,
    'Mūrmuiža': 4471,
    'Brocēni': 4468,
    'Carnikava': 4454,
    'Cēsis': 4469,
    'Cesvaine': 4414,
    'Cibla': 4410,
    'Dagda': 4504,
    'Daugavpils': 4492,
    'Dobele': 4437,
    'Dundaga': 4428,
    'Lieģi': 4458,
    'Smārde': 4448,
    'Ērgļi': 4444,
    'Garkalne': 4510,
    'Grobiņa': 4470,
    'Gulbene': 4400,
    'Iecava': 4441,
    'Ikšķile': 4511,
    'Ilūkste': 4399,
    'Inčukalns': 4449,
    'Vangaži': 4449,
    'Jaunjelgava': 4475,
    'Jaunpils': 4489,
    'Jēkabpils': 4438,
    'Krustpils': 4438,
    'Jelgava': 4500,
    'Tīreļi': 4424,
    'Jūrmala': 4446,
    'Kandava': 4420,
    'Kārsava': 4453,
    'Ķegums': 4412,
    'Baloži': 4435,
    'Ķekava': 4435,
    'Kocēni': 4495,
    'Koknese': 4452,
    'Krāslava': 4474,
    'Kuldīga': 4490,
    'Lielvārde': 4512,
    'Karosta': 4460,
    'Liepāja': 4460,
    'Līgatne': 4488,
    'Limbaži': 4418,
    'Līvāni': 4401,
    'Lubāna': 4419,
    'Ludza': 4501,
    'Madona': 4433,
    'Mālpils': 4461,
    'Mārupe': 4450,
    'Mazsalaca': 4513,
    'Naukšēni': 4398,
    'Nereta': 4432,
    'Nīca': 4436,
    'Jumprava': 4416,
    'Ogre': 4416,
    'Olaine': 4417,
    'Ozolnieki': 4442,
    'Stalbe': 4507,
    'Pāvilosta': 4467,
    'Pļaviņas': 4405,
    'Jaunaglona': 4483,
    'Preiļi': 4483,
    'Priekule': 4429,
    'Priekuļi': 4506,
    'Rauna': 4479,
    'Rēzekne': 4509,
    'Riebiņi': 4502,
    'Bolderaja': 4491,
    'Daugavgrīva': 4491,
    'Jaunciems': 4491,
    'Mežaparks': 4491,
    'Riga': 4491,
    'Roja': 4440,
    'Ropaži': 4493,
    'Rucava': 4503,
    'Rugāji': 4423,
    'Rūjiena': 4426,
    'Pilsrundāle': 4404,
    'Ainaži': 4396,
    'Salacgrīva': 4396,
    'Salaspils': 4402,
    'Saldus': 4439,
    'Saulkrasti': 4443,
    'Engure': 4408,
    'Tukums': 4408,
    'Sigulda': 4476,
    'Skrīveri': 4415,
    'Skrunda': 4447,
    'Smiltene': 4462,
    'Ulbroka': 4478,
    'Seda': 4494,
    'Strenči': 4494,
    'Sabile': 4459,
    'Stende': 4459,
    'Talsi': 4459,
    'Valdemārpils': 4459,
    'Tērvete': 4480,
    'Zelmeņi': 4480,
    'Vaiņode': 4508,
    'Valka': 4425,
    'Valmiera': 4473,
    'Varakļāni': 4431,
    'Vecvārkava': 4406,
    'Vecpiebalga': 4466,
    'Vecumnieki': 4397,
    'Ventspils': 4421,
    'Piltene': 4403,
    'Viesīte': 4456,
    'Viļaka': 4477,
    'Viļāni': 4486,
    'Zilupe': 4430,
    'Caza de Aakkar': 2285,
    'Baalbek': 2283,
    'Caza de Baalbek': 2283,
    'Beirut': 2286,
    'Ra’s Bayrūt': 2286,
    'Aanjar': 2287,
    'Zahlé': 2287,
    'Baabda': 2282,
    'Bhamdoun': 2282,
    'Bhamdoûn el Mhatta': 2282,
    'Caza de Baabda': 2282,
    'Jbaïl': 2282,
    'Jounieh': 2282,
    'Ain Ebel': 2288,
    'Caza de Bent Jbaïl': 2288,
    'Caza de Nabatîyé': 2288,
    'Habboûch': 2288,
    'Marjayoûn': 2288,
    'Nabatîyé et Tahta': 2288,
    'Batroûn': 2284,
    'Bcharré': 2284,
    'Tripoli': 2284,
    'En Nâqoûra': 2281,
    'Ghazieh': 2281,
    'Sidon': 2281,
    'Tyre': 2281,
    'Teyateyaneng': 3030,
    'Butha-Buthe': 3029,
    'Leribe': 3026,
    'Maputsoe': 3026,
    'Mafeteng': 3022,
    'Maseru': 3028,
    'Nako': 3028,
    'Mohale’s Hoek': 3023,
    'Mokhotlong': 3024,
    'Qacha’s Nek': 3025,
    'Quthing': 3027,
    'Thaba-Tseka': 3031,
    'Tubmanburg': 3041,
    'Gbarnga': 3034,
    'Bopolu': 3044,
    'Buchanan': 3040,
    'Robertsport': 3036,
    'Zwedru': 3039,
    'Barclayville': 3045,
    'Voinjama': 3037,
    'Kakata': 3043,
    'Harper': 3042,
    'Bensonville': 3032,
    'Monrovia': 3032,
    'Ganta': 3046,
    'New Yekepa': 3046,
    'Sanniquellie': 3046,
    'Cestos City': 3033,
    'Fish Town': 3038,
    'Greenville': 3035,
    'Ajdabiya': 2964,
    'Al Burayqah': 2964,
    'Awjilah': 2964,
    'Az Zuwaytīnah': 2964,
    'Gialo': 2964,
    'Marādah': 2964,
    'Benghazi': 2981,
    'Qaryat Sulūq': 2981,
    'Al Qubbah': 2966,
    'Darnah': 2966,
    'Ghat': 2969,
    'Al Bayḑā’': 2980,
    'Gharyan': 2974,
    'Giado': 2974,
    'Mizdah': 2974,
    'Yafran': 2974,
    'Zintan': 2974,
    'Al ‘Azīzīyah': 2979,
    'Hūn': 2970,
    'Waddān': 2970,
    'Al Jawf': 2972,
    'At Tāj': 2972,
    'Al Abyār': 2968,
    'Al Marj': 2968,
    'Tūkrah': 2968,
    'Bani Walid': 2978,
    'Mişrātah': 2978,
    'Zliten': 2978,
    'Al Khums': 2961,
    'Masallātah': 2961,
    'Tarhuna': 2961,
    'Al Qaţrūn': 2967,
    'Murzuq': 2967,
    'Ghadāmis': 2976,
    'Nālūt': 2976,
    'Al Ajaylat': 2962,
    'Zalţan': 2962,
    'Zuwārah': 2962,
    'Al Jadīd': 2965,
    'Sabhā': 2965,
    'Qasr Abu Hadi': 2977,
    'Sirte': 2977,
    'Tagiura': 2971,
    'Tripoli': 2971,
    'Ubari': 2973,
    'Brak': 2975,
    'Idrī': 2975,
    'Az Zāwīyah': 2963,
    'Şabrātah': 2963,
    'Şurmān': 2963,
    'Zawiya': 2963,
    'Balzers': 458,
    'Eschen': 451,
    'Gamprin': 457,
    'Mauren': 455,
    'Planken': 454,
    'Ruggell': 453,
    'Schaan': 450,
    'Schellenberg': 449,
    'Triesen': 459,
    'Triesenberg': 456,
    'Vaduz': 452,
    'Alytus': 1605,
    'Daugai': 1605,
    'Druskininkai': 1605,
    'Lazdijai': 1605,
    'Simnas': 1605,
    'Varėna': 1605,
    'Veisiejai': 1605,
    'Akademija (Kaunas)': 1580,
    'Aleksotas': 1580,
    'Ariogala': 1580,
    'Birštonas': 1580,
    'Dainava (Kaunas)': 1580,
    'Eiguliai': 1580,
    'Ežerėlis': 1580,
    'Garliava': 1580,
    'Jieznas': 1580,
    'Jonava': 1580,
    'Kaišiadorys': 1580,
    'Karmėlava': 1580,
    'Kaunas': 1580,
    'Kėdainiai': 1580,
    'Kulautuva': 1580,
    'Mastaiciai': 1580,
    'Prienai': 1580,
    'Ramučiai': 1580,
    'Raseiniai': 1580,
    'Sargėnai': 1580,
    'Šilainiai': 1580,
    'Vilkija': 1580,
    'Žiežmariai': 1580,
    'Gargždai': 1604,
    'Klaipėda': 1604,
    'Kretinga': 1604,
    'Neringa': 1604,
    'Nida': 1604,
    'Palanga': 1604,
    'Priekulė': 1604,
    'Rusnė': 1604,
    'Salantai': 1604,
    'Silute': 1604,
    'Skuodas': 1604,
    'Šventoji': 1604,
    'Žemaičių Naumiestis': 1604,
    'Gelgaudiškis': 1610,
    'Kalvarija': 1610,
    'Kalvarija Municipality': 1610,
    'Kazlų Rūda': 1610,
    'Kazlų Rūda Municipality': 1610,
    'Kudirkos Naumiestis': 1610,
    'Kybartai': 1610,
    'Marijampolė': 1610,
    'Marijampolė Municipality': 1610,
    'Sakiai': 1610,
    'Vilkaviskis': 1610,
    'Vilkaviškis District Municipality': 1610,
    'Virbalis': 1610,
    'Birzai': 1614,
    'Juodupė': 1614,
    'Kupiskis': 1614,
    'Obeliai': 1614,
    'Panevėžys': 1614,
    'Panevėžys City': 1614,
    'Panevėžys District Municipality': 1614,
    'Pasvalys': 1614,
    'Ramygala': 1614,
    'Rokiškis': 1614,
    'Vabalninkas': 1614,
    'Akmenė': 1609,
    'Joniškis': 1609,
    'Kelmė': 1609,
    'Kuršėnai': 1609,
    'Linkuva': 1609,
    'Naujoji Akmene': 1609,
    'Pakruojis': 1609,
    'Radviliskis': 1609,
    'Šeduva': 1609,
    'Šiauliai': 1609,
    'Tytuvėnėliai': 1609,
    'Venta': 1609,
    'Žagarė': 1609,
    'Būgai': 1573,
    'Jurbarkas': 1573,
    'Pagėgiai': 1573,
    'Šilalė': 1573,
    'Taurage': 1573,
    'Mazeikiai': 1608,
    'Plateliai': 1608,
    'Plunge': 1608,
    'Rietavas': 1608,
    'Seda': 1608,
    'Telsiai': 1608,
    'Varniai': 1608,
    'Viekšniai': 1608,
    'Anyksciai': 1621,
    'Dūkštas': 1621,
    'Ignalina': 1621,
    'Moletai': 1621,
    'Utena': 1621,
    'Visaginas': 1621,
    'Zarasai': 1621,
    'Aukstadvaris': 1606,
    'Baltoji Vokė': 1606,
    'Eišiškės': 1606,
    'Elektrėnai': 1606,
    'Fabijoniškės': 1606,
    'Grigiškės': 1606,
    'Justiniškės': 1606,
    'Lazdynai': 1606,
    'Lentvaris': 1606,
    'Naujamiestis': 1606,
    'Nemenčinė': 1606,
    'Pabradė': 1606,
    'Pašilaičiai': 1606,
    'Pilaitė': 1606,
    'Rasos': 1606,
    'Rūdiškės': 1606,
    'Šalčininkai': 1606,
    'Senieji Trakai': 1606,
    'Šeškinė': 1606,
    'Sirvintos': 1606,
    'Skaidiškės': 1606,
    'Švenčionėliai': 1606,
    'Svencionys': 1606,
    'Trakai': 1606,
    'Ukmerge': 1606,
    'Vievis': 1606,
    'Vilkpėdė': 1606,
    'Vilnius': 1606,
    'Vilnius District Municipality': 1606,
    'Bascharage': 1518,
    'Bridel': 1518,
    'Capellen': 1518,
    'Clemency': 1518,
    'Dippach': 1518,
    'Eischen': 1518,
    'Garnich': 1518,
    'Hautcharage': 1518,
    'Hobscheid': 1518,
    'Käerjeng': 1518,
    'Kehlen': 1518,
    'Koerich': 1518,
    'Kopstal': 1518,
    'Mamer': 1518,
    'Schouweiler': 1518,
    'Septfontaines': 1518,
    'Steinfort': 1518,
    'Clervaux': 1521,
    'Hosingen': 1521,
    'Parc Hosingen': 1521,
    'Troisvierges': 1521,
    'Weiswampach': 1521,
    'Wincrange': 1521,
    'Bettendorf': 1513,
    'Bourscheid': 1513,
    'Commune de la Vallée de l’Ernz': 1513,
    'Diekirch': 1513,
    'Erpeldange': 1513,
    'Ettelbruck': 1513,
    'Feulen': 1513,
    'Medernach': 1513,
    'Mertzig': 1513,
    'Niederfeulen': 1513,
    'Reisdorf': 1513,
    'Schieren': 1513,
    'Warken': 1513,
    'Beaufort': 1515,
    'Bech': 1515,
    'Berdorf': 1515,
    'Consdorf': 1515,
    'Echternach': 1515,
    'Mompach': 1515,
    'Rosport': 1515,
    'Waldbillig': 1515,
    'Aspelt': 1517,
    'Belvaux': 1517,
    'Bergem': 1517,
    'Bettembourg': 1517,
    'Crauthem': 1517,
    'Differdange': 1517,
    'Dudelange': 1517,
    'Esch-sur-Alzette': 1517,
    'Frisange': 1517,
    'Kayl': 1517,
    'Lamadelaine': 1517,
    'Leudelange': 1517,
    'Mondercange': 1517,
    'Niedercorn': 1517,
    'Obercorn': 1517,
    'Pétange': 1517,
    'Pontpierre': 1517,
    'Reckange-sur-Mess': 1517,
    'Rodange': 1517,
    'Roeser': 1517,
    'Rumelange': 1517,
    'Sanem': 1517,
    'Schifflange': 1517,
    'Soleuvre': 1517,
    'Tétange': 1517,
    'Betzdorf': 1525,
    'Biwer': 1525,
    'Flaxweiler': 1525,
    'Gonderange': 1525,
    'Grevenmacher': 1525,
    'Junglinster': 1525,
    'Manternach': 1525,
    'Mertert': 1525,
    'Wasserbillig': 1525,
    'Wormeldange': 1525,
    'Alzingen': 1527,
    'Béreldange': 1527,
    'Bertrange': 1527,
    'Contern': 1527,
    'Fentange': 1527,
    'Heisdorf': 1527,
    'Helmsange': 1527,
    'Hesperange': 1527,
    'Itzig': 1527,
    'Kirchberg': 1527,
    'Luxembourg': 1527,
    'Moutfort': 1527,
    'Müllendorf': 1527,
    'Niederanven': 1527,
    'Olm': 1527,
    'Sandweiler': 1527,
    'Schrassig': 1527,
    'Schuttrange': 1527,
    'Steinsel': 1527,
    'Strassen': 1527,
    'Walferdange': 1527,
    'Weiler-la-Tour': 1527,
    'Bissen': 1522,
    'Boevange-sur-Attert': 1522,
    'Colmar': 1522,
    'Fischbach': 1522,
    'Heffingen': 1522,
    'Larochette': 1522,
    'Lintgen': 1522,
    'Lorentzweiler': 1522,
    'Mersch': 1522,
    'Nommern': 1522,
    'Rollingen': 1522,
    'Tuntange': 1522,
    'Beckerich': 1516,
    'Bettborn': 1516,
    'Commune de Préizerdaul': 1516,
    'Ell': 1516,
    'Grosbous': 1516,
    'Rambrouch': 1516,
    'Redange-sur-Attert': 1516,
    'Saeul': 1516,
    'Useldange': 1516,
    'Vichten': 1516,
    'Wahl': 1516,
    'Bous': 1519,
    'Dalheim': 1519,
    'Lenningen': 1519,
    'Mondorf-les-Bains': 1519,
    'Remich': 1519,
    'Schengen': 1519,
    'Stadtbredimus': 1519,
    'Waldbredimus': 1519,
    'Putscheid': 1523,
    'Tandel': 1523,
    'Vianden': 1523,
    'Bavigne': 1526,
    'Boulaide': 1526,
    'Esch-sur-Sûre': 1526,
    'Goesdorf': 1526,
    'Kiischpelt': 1526,
    'Lac de la Haute-Sûre': 1526,
    'Wiltz': 1526,
    'Wilwerwiltz': 1526,
    'Winseler': 1526,
    'Арачиново': 656,
    'Berovo': 716,
    'Rusinovo': 716,
    'Vladimirovo': 716,
    'Bistrica': 679,
    'Bitola': 679,
    'Capari': 679,
    'Dolno Orizari': 679,
    'Gorno Orizari': 679,
    'Kukurečani': 679,
    'Logovardi': 679,
    'Bogdanci': 649,
    'Stojakovo': 649,
    'Bogovinje': 721,
    'Dolno Palčište': 721,
    'Gradec': 721,
    'Kamenjane': 721,
    'Bosilovo': 652,
    'Ilovica': 652,
    'Sekirnik': 652,
    'Brvenica': 660,
    'Čelopek': 660,
    'Gurgurnica': 660,
    'Miletino': 660,
    'Butel': 694,
    'Radishani': 694,
    'Čair': 704,
    'Bogomila': 676,
    'Čaška': 676,
    'Centar Župa': 720,
    'Češinovo': 644,
    'Oblesevo': 644,
    'Чучер - Сандево': 715,
    'Belčišta': 645,
    'Mešeišta': 645,
    'Delcevo': 695,
    'Demir Hisar': 687,
    'Slepče': 687,
    'Sopotnica': 687,
    'Demir Kapija': 655,
    'Star Dojran': 697,
    'Crnilište': 675,
    'Desovo': 675,
    'Dolneni': 675,
    'Lažani': 675,
    'Žitoše': 675,
    'Cresevo': 707,
    'Gevgelija': 648,
    'Miravci': 648,
    'Gjorče Petro': 722,
    'Cegrane': 693,
    'Dolna Banjica': 693,
    'Forino': 693,
    'Gostivar': 693,
    'Srbinovo': 693,
    'Vrutok': 693,
    'Gradsko': 708,
    'Bojane': 684,
    'Dračevo': 684,
    'Ljubin': 684,
    'Saraj': 684,
    'Usje': 684,
    'Ilinden': 690,
    'Kadino': 690,
    'Marino': 690,
    'Miladinovci': 690,
    'Идризово': 690,
    'Јурумлери': 690,
    'Jegunovce': 678,
    'Vratnica': 678,
    'Karbinci': 674,
    'Skopje': 681,
    'Kavadarci': 713,
    'Vataša': 713,
    'Drugovo': 688,
    'Kičevo': 688,
    'Srbica': 688,
    'Strelci': 688,
    'Tuin': 688,
    'Vraneštica': 688,
    'Zajas': 688,
    'Kisela Voda': 686,
    'Kochani': 723,
    'Orizari': 723,
    'Konče': 665,
    'Kratovo': 641,
    'Kriva Palanka': 677,
    'Krivogashtani': 647,
    'Obršani': 647,
    'Клечовце': 647,
    'Bučin': 714,
    'Krusevo': 714,
    'Bedinje': 683,
    'Kumanovo': 683,
    'Romanovci': 683,
    'Izvor': 659,
    'Lipkovo': 659,
    'Lojane': 659,
    'Matejce': 659,
    'Otlja': 659,
    'Vaksince': 659,
    'Слупчане': 659,
    'Lozovo': 705,
    'Makedonska Kamenica': 701,
    'Makedonski Brod': 692,
    'Samokov': 692,
    'Rostusa': 669,
    'Beranci': 653,
    'Dobruševo': 653,
    'Dolno Srpci': 653,
    'Mogila': 653,
    'Negotino': 664,
    'Novaci': 696,
    'Novo Selo': 718,
    'Sušica': 718,
    'Kosel': 699,
    'Ohrid': 699,
    'Ohrid Opština': 699,
    'Pehčevo': 685,
    'Sredno Konjare': 698,
    'Петровец': 698,
    'Lisičani': 670,
    'Plasnica': 670,
    'Kanatlarci': 666,
    'Markov Grad': 666,
    'Prilep': 666,
    'Topolčani': 666,
    'Probishtip': 646,
    'Zletovo': 646,
    'Oraovica': 709,
    'Podareš': 709,
    'Radovis': 709,
    'Ранковце': 717,
    'Grnčari': 712,
    'Jankovec': 712,
    'Krani': 712,
    'Resen': 712,
    'Rosoman': 691,
    'Bukovik': 667,
    'Grčec': 667,
    'Kondovo': 667,
    'Krušopek': 667,
    'Сопиште': 719,
    'Старо Нагоричане': 643,
    'Shtip': 661,
    'Delogožda': 700,
    'Labunista': 700,
    'Lukovo': 700,
    'Mislesevo': 700,
    'Oktisi': 700,
    'Radolista': 700,
    'Struga': 700,
    'Velesta': 700,
    'Kuklis': 710,
    'Murtino': 710,
    'Strumica': 710,
    'Veljusa': 710,
    'Batinci': 711,
    'Dolno Količani': 711,
    'Morani': 711,
    'Studeničane': 711,
    'Šuto Orizare': 680,
    'Gorobinci': 640,
    'Sveti Nikole': 640,
    'Nerašte': 654,
    'Orashac': 654,
    'Pršovce': 654,
    'Slatino': 654,
    'Tearce': 654,
    'Dobrošte': 663,
    'Džepčište': 663,
    'Golema Rečica': 663,
    'Pirok': 663,
    'Poroj': 663,
    'Selce': 663,
    'Šipkovica': 663,
    'Tetovo': 663,
    'Pirava': 671,
    'Valandovo': 671,
    'Vasilevo': 658,
    'Gorno Orizari': 651,
    'Ivankovci': 651,
    'Veles': 651,
    'Vevčani': 662,
    'Blatec': 672,
    'Gradec': 672,
    'Vinica': 672,
    'Dobri Dol': 689,
    'Negotino': 689,
    'Vrapčište': 689,
    'Zelenikovo': 706,
    'Dobarce': 668,
    'Larce': 668,
    'Sedlarevo': 668,
    'Strimnica': 668,
    'Zelino': 668,
    'Zrnovci': 673,
    'Ampasimanolotra': 2950,
    'Andovoranto': 2950,
    'Mahanoro': 2950,
    'Mahavelona': 2950,
    'Marolambo': 2950,
    'Toamasina': 2950,
    'Toamasina I': 2950,
    'Toamasina II': 2950,
    'Vohibinany': 2950,
    'Chipoka': 3092,
    'Dedza': 3092,
    'Dedza District': 3092,
    'Dowa': 3092,
    'Dowa District': 3092,
    'Kasungu': 3092,
    'Kasungu District': 3092,
    'Lilongwe': 3092,
    'Lilongwe District': 3092,
    'Mchinji': 3092,
    'Mchinji District': 3092,
    'Mponela': 3092,
    'Nkhotakota': 3092,
    'Nkhotakota District': 3092,
    'Ntcheu': 3092,
    'Ntcheu District': 3092,
    'Ntchisi': 3092,
    'Ntchisi District': 3092,
    'Salima': 3092,
    'Salima District': 3092,
    'Chitipa': 3105,
    'Chitipa District': 3105,
    'Karonga': 3105,
    'Karonga District': 3105,
    'Likoma District': 3105,
    'Livingstonia': 3105,
    'Mzimba': 3105,
    'Mzimba District': 3105,
    'Mzuzu': 3105,
    'Nkhata Bay': 3105,
    'Nkhata Bay District': 3105,
    'Rumphi': 3105,
    'Rumphi District': 3105,
    'Balaka': 3106,
    'Balaka District': 3106,
    'Blantyre': 3106,
    'Blantyre District': 3106,
    'Chikwawa': 3106,
    'Chikwawa District': 3106,
    'Chiradzulu': 3106,
    'Chiradzulu District': 3106,
    'Liwonde': 3106,
    'Luchenza': 3106,
    'Machinga': 3106,
    'Machinga District': 3106,
    'Mangochi': 3106,
    'Mangochi District': 3106,
    'Monkey Bay': 3106,
    'Mulanje': 3106,
    'Mulanje District': 3106,
    'Mwanza': 3106,
    'Mwanza District': 3106,
    'Neno District': 3106,
    'Nsanje': 3106,
    'Nsanje District': 3106,
    'Phalombe': 3106,
    'Phalombe District': 3106,
    'Thyolo': 3106,
    'Thyolo District': 3106,
    'Zomba': 3106,
    'Zomba District': 3106,
    'Bakri': 1950,
    'Batu Pahat': 1950,
    'Buloh Kasap': 1950,
    'Chaah': 1950,
    'Daerah Batu Pahat': 1950,
    'Daerah Johor Baharu': 1950,
    'Daerah Keluang': 1950,
    'Daerah Kota Tinggi': 1950,
    'Daerah Mersing': 1950,
    'Daerah Muar': 1950,
    'Daerah Pontian': 1950,
    'Daerah Segamat': 1950,
    'Johor Bahru': 1950,
    'Kampung Pasir Gudang Baru': 1950,
    'Kampung Simpang Renggam': 1950,
    'Kelapa Sawit': 1950,
    'Kluang': 1950,
    'Kota Tinggi': 1950,
    'Kulai': 1950,
    'Labis': 1950,
    'Mersing': 1950,
    'Muar': 1950,
    'Parit Raja': 1950,
    'Pekan Nenas': 1950,
    'Pontian Kechil': 1950,
    'Segamat': 1950,
    'Skudai': 1950,
    'Taman Senai': 1950,
    'Tangkak': 1950,
    'Ulu Tiram': 1950,
    'Yong Peng': 1950,
    'Alor Setar': 1947,
    'Ayer Hangat': 1947,
    'Bedong': 1947,
    'Gurun': 1947,
    'Jitra': 1947,
    'Kampung Kilim': 1947,
    'Kampung Kok': 1947,
    'Kuah': 1947,
    'Kuala Kedah': 1947,
    'Kuala Teriang': 1947,
    'Kulim': 1947,
    'Langkawi': 1947,
    'Padang Mat Sirat': 1947,
    'Sungai Petani': 1947,
    'Gua Musang': 1946,
    'Kampong Kadok': 1946,
    'Kampong Pangkal Kalong': 1946,
    'Kota Bharu': 1946,
    'Pasir Mas': 1946,
    'Peringat': 1946,
    'Tumpat': 1946,
    'Kuala Lumpur': 1949,
    'Labuan': 1935,
    'Alor Gajah': 1941,
    'Batu Berendam': 1941,
    'Bemban': 1941,
    'Bukit Rambai': 1941,
    'Kampong Masjid Tanah': 1941,
    'Kampung Ayer Keroh': 1941,
    'Kampung Ayer Molek': 1941,
    'Kampung Bukit Baharu': 1941,
    'Klebang Besar': 1941,
    'Kuala Sungai Baru': 1941,
    'Malacca': 1941,
    'Pulau Sebang': 1941,
    'Sungai Udang': 1941,
    'Bahau': 1948,
    'Kampung Baharu Nilai': 1948,
    'Kuala Pilah': 1948,
    'Port Dickson': 1948,
    'Seremban': 1948,
    'Tampin': 1948,
    'Bentong Town': 1940,
    'Jerantut': 1940,
    'Kampung Bukit Tinggi, Bentong': 1940,
    'Kampung Tekek': 1940,
    'Kuala Lipis': 1940,
    'Kuantan': 1940,
    'Mentekab': 1940,
    'Pekan': 1940,
    'Raub': 1940,
    'Tanah Rata': 1940,
    'Temerluh': 1940,
    'Batu Feringgi': 1939,
    'Bukit Mertajam': 1939,
    'Butterworth': 1939,
    'George Town': 1939,
    'Juru': 1939,
    'Kampung Batu Feringgi': 1939,
    'Kampung Sungai Ara': 1939,
    'Kepala Batas': 1939,
    'Nibong Tebal': 1939,
    'Perai': 1939,
    'Permatang Kuching': 1939,
    'Seberang Perai': 1939,
    'Tanjung Tokong': 1939,
    'Tasek Glugor': 1939,
    'Telaga Batu': 1939,
    'Bagan Serai': 1943,
    'Batu Gajah': 1943,
    'Bidur': 1943,
    'Ipoh': 1943,
    'Kampar': 1943,
    'Kampong Dungun': 1943,
    'Kuala Kangsar': 1943,
    'Lumut': 1943,
    'Pantai Remis': 1943,
    'Parit Buntar': 1943,
    'Simpang Empat': 1943,
    'Taiping': 1943,
    'Tapah Road': 1943,
    'Teluk Intan': 1943,
    'Kangar': 1938,
    'Kuala Perlis': 1938,
    'Pantai Cenang': 1938,
    'Putrajaya': 1945,
    'Bandar Labuan': 1936,
    'Beaufort': 1936,
    'Donggongon': 1936,
    'Keningau': 1936,
    'Kinarut': 1936,
    'Kota Belud': 1936,
    'Kota Kinabalu': 1936,
    'Kudat': 1936,
    'Lahad Datu': 1936,
    'Papar': 1936,
    'Putatan': 1936,
    'Ranau': 1936,
    'Sandakan': 1936,
    'Semporna': 1936,
    'Taman Rajawali': 1936,
    'Tawau': 1936,
    'Bintulu': 1937,
    'Data Kakus': 1937,
    'Kapit': 1937,
    'Kuching': 1937,
    'Lawas': 1937,
    'Lidung Jelo': 1937,
    'Limbang': 1937,
    'Long Ampan Aing or Abanang': 1937,
    'Marudi': 1937,
    'Miri': 1937,
    'Mukah': 1937,
    'Sarikei': 1937,
    'Sibu': 1937,
    'Simanggang': 1937,
    'Ampang': 1944,
    'Bagan Pulau Ketam': 1944,
    'Banting': 1944,
    'Batang Berjuntai': 1944,
    'Batu Arang': 1944,
    'Jenjarum': 1944,
    'Kampong Baharu Balakong': 1944,
    'Kampung Baru Subang': 1944,
    'Kampung Tanjung Karang': 1944,
    'Klang': 1944,
    'Kuala Selangor': 1944,
    'Kuang': 1944,
    'Ladang Seri Kundang': 1944,
    'Pelabuhan Klang': 1944,
    'Petaling Jaya': 1944,
    'Putra Heights': 1944,
    'Rawang': 1944,
    'Semenyih': 1944,
    'Serendah': 1944,
    'Shah Alam': 1944,
    'Subang Jaya': 1944,
    'Sungai Besar': 1944,
    'Sungai Pelek New Village': 1944,
    'Tanjung Sepat': 1944,
    'Cukai': 1942,
    'Jertih': 1942,
    'Kampung Kuala Besut': 1942,
    'Kertih': 1942,
    'Kuala Besut': 1942,
    'Kuala Terengganu': 1942,
    'Marang': 1942,
    'Paka': 1942,
    'Hithadhoo': 2594,
    'Meedhoo': 2594,
    'Kudahuvadhoo': 2590,
    'Viligili': 2598,
    'Thinadhoo': 2603,
    'Fuvahmulah': 2595,
    'Dhidhdhoo': 2586,
    'Kulhudhuffushi': 2597,
    'Guraidhoo': 2596,
    'Hulhumale': 2596,
    'Maafushi': 2596,
    'Male': 2596,
    'Fonadhoo': 2601,
    'Naifaru': 2607,
    'Muli': 2608,
    'Manadhoo': 2592,
    'Ugoofaaru': 2602,
    'Funadhoo': 2585,
    'Mahibadhoo': 2606,
    'Veymandoo': 2591,
    'Felidhoo': 2584,
    'Bamako': 253,
    'Ansongo': 258,
    'Cercle de Bourem': 258,
    'Gao': 258,
    'Gao Cercle': 258,
    'Inékar': 258,
    'Bafoulabé': 252,
    'Kayes': 252,
    'Kita': 252,
    'Kokofata': 252,
    'Sagalo': 252,
    'Abeïbara': 257,
    'Cercle d’Abeïbara': 257,
    'Kidal': 257,
    'Banamba': 250,
    'Kangaba': 250,
    'Kati': 250,
    'Kolokani': 250,
    'Koulikoro': 250,
    'Bandiagara': 255,
    'Djénné': 255,
    'Douentza': 255,
    'Douentza Cercle': 255,
    'Mopti': 255,
    'Ténenkou': 255,
    'Baroueli': 249,
    'Cercle de San': 249,
    'Ké-Macina': 249,
    'Kinmparana': 249,
    'Markala': 249,
    'San': 249,
    'Ségou': 249,
    'Sokolo': 249,
    'Bougouni': 254,
    'Kolondiéba': 254,
    'Koutiala': 254,
    'Koutiala Cercle': 254,
    'Ntossoni': 254,
    'Sikasso': 254,
    'Yorosso': 254,
    'Araouane': 248,
    'Cercle de Goundam': 248,
    'Dire': 248,
    'Goundam': 248,
    'Gourma-Rharous Cercle': 248,
    'Niafunké': 248,
    'Timbuktu': 248,
    'Attard': 110,
    'Balzan': 108,
    'Vittoriosa': 107,
    'Birkirkara': 97,
    'Birżebbuġa': 88,
    'Cospicua': 138,
    'Dingli': 117,
    'Fgura': 129,
    'Floriana': 84,
    'Fontana': 134,
    'Għajnsielem': 130,
    'Hal Gharghur': 130,
    'Għaxaq': 120,
    'Gudja': 106,
    'Gżira': 124,
    'Għarb': 113,
    'Ħamrun': 105,
    'L-Iklin': 93,
    'Imġarr': 99,
    'Mġarr': 99,
    'Mqabba': 91,
    'Imsida': 82,
    'Kirkop': 82,
    'Imtarfa': 126,
    'Lija': 126,
    'Luqa': 77,
    'Senglea': 77,
    'Kalkara': 128,
    'Kerċem': 137,
    'Marsaskala': 137,
    'Marsaxlokk': 78,
    'Marsa': 109,
    'Mellieħa': 96,
    'Munxar': 132,
    'Nadur': 133,
    'Naxxar': 112,
    'Paola': 115,
    'Pembroke': 125,
    'Pietà': 127,
    'Qala': 79,
    'Qormi': 119,
    'Qrendi': 111,
    'San Lawrenz': 87,
    'San Ġiljan': 75,
    'San Pawl il-Baħar': 116,
    'Sannat': 116,
    'Santa Venera': 94,
    'Imdina': 136,
    'Siġġiewi': 98,
    'Sliema': 104,
    'Saint John': 100,
    'Mosta': 139,
    'Saint Lucia': 139,
    'Santa Luċija': 139,
    'Swieqi': 86,
    'Ta’ Xbiex': 122,
    'Tarxien': 103,
    'Valletta': 95,
    'Rabat': 101,
    'Victoria': 101,
    'Xagħra': 114,
    'Xewkija': 121,
    'Xgħajra': 81,
    'Żabbar': 123,
    'Haz-Zebbug': 85,
    'Żebbuġ': 80,
    'Żejtun': 135,
    'Żurrieq': 76,
    'Atar': 3344,
    'Chingueṭṭi': 3344,
    'Barkéwol': 3349,
    'Kiffa': 3349,
    '’Elb el Jmel': 3339,
    'Aleg': 3339,
    'Nouadhibou': 3346,
    'Kaédi': 3341,
    'Sélibaby': 3350,
    'Néma': 3338,
    'Aioun': 3351,
    'Akjoujt': 3342,
    'Zouerate': 3340,
    'Rosso': 3348,
    'Tékane': 3348,
    'Vingt Cinq': 3248,
    'Cargados Carajos': 3251,
    'Bel Air Rivière Sèche': 3254,
    'Bon Accueil': 3254,
    'Brisée Verdière': 3254,
    'Camp de Masque': 3254,
    'Camp Ithier': 3254,
    'Centre de Flacq': 3254,
    'Clémencia': 3254,
    'Ecroignard': 3254,
    'Grande Rivière Sud Est': 3254,
    'Lalmatie': 3254,
    'Laventure': 3254,
    'Mare La Chaux': 3254,
    'Olivia': 3254,
    'Poste de Flacq': 3254,
    'Quatre Cocos': 3254,
    'Quatre Soeurs': 3254,
    'Queen Victoria': 3254,
    'Saint Julien': 3254,
    'Sebastopol': 3254,
    'Bambous Virieux': 3264,
    'Beau Vallon': 3264,
    'Bois des Amourettes': 3264,
    'Cluny': 3264,
    'Grand Sable': 3264,
    'Mahébourg': 3264,
    'New Grove': 3264,
    'Nouvelle France': 3264,
    'Plaine Magnien': 3264,
    'Rose Belle': 3264,
    'Saint Hubert': 3264,
    'Camp Thorel': 3253,
    'Dagotière': 3253,
    'Dubreuil': 3253,
    'Melrose': 3253,
    'Moka': 3253,
    'Pailles': 3253,
    'Providence': 3253,
    'Quartier Militaire': 3253,
    'Saint Pierre': 3253,
    'Verdun': 3253,
    'Arsenal': 3250,
    'Calebasses': 3250,
    'Congomah': 3250,
    'Crève Coeur': 3250,
    'Fond du Sac': 3250,
    'Le Hochet': 3250,
    'Long Mountain': 3250,
    'Morcellement Saint André': 3250,
    'Notre Dame': 3250,
    'Pamplemousses': 3250,
    'Plaine des Papayes': 3250,
    'Pointe aux Piments': 3250,
    'Terre Rouge': 3250,
    'Triolet': 3250,
    'Beau Bassin-Rose Hill': 3263,
    'Curepipe': 3263,
    'Ebene': 3263,
    'Midlands': 3263,
    'Quatre Bornes': 3263,
    'Vacoas': 3263,
    'Port Louis': 3260,
    'Amaury': 3261,
    'Cap Malheureux': 3261,
    'Cottage': 3261,
    'Espérance Trébuchet': 3261,
    'Goodlands': 3261,
    'Grand Baie': 3261,
    'Grand Gaube': 3261,
    'Mapou': 3261,
    'Petit Raffray': 3261,
    'Piton': 3261,
    'Plaines des Roches': 3261,
    'Rivière du Rempart': 3261,
    'Roche Terre': 3261,
    'Roches Noire': 3261,
    'The Vale': 3261,
    'Albion': 3259,
    'Bambous': 3259,
    'Cascavelle': 3259,
    'Flic en Flac': 3259,
    'Grande Rivière Noire': 3259,
    'Gros Cailloux': 3259,
    'Petite Case Noyale': 3259,
    'Petite Rivière': 3259,
    'Tamarin': 3259,
    'Baie aux Huîtres': 3249,
    'Port Mathurin': 3249,
    'Camp Diable': 3257,
    'Chamouny': 3257,
    'Chemin Grenier': 3257,
    'Grand Bois': 3257,
    'Rivière des Anguilles': 3257,
    'Saint Aubin': 3257,
    'Souillac': 3257,
    'Surinam': 3257,
    'Aguascalientes': 3456,
    'Arboledas Paso Blanco [Fraccionamiento]': 3456,
    'Arellano': 3456,
    'Asientos': 3456,
    'Bimbaletes Aguascalientes (El Álamo)': 3456,
    'Calvillo': 3456,
    'Carboneras': 3456,
    'Cartagena [Fraccionamiento]': 3456,
    'Centro de Arriba': 3456,
    'Ciénega Grande': 3456,
    'Corral de Barrancos': 3456,
    'Cosío': 3456,
    'Cotorina': 3456,
    'Crucero Las Pilas': 3456,
    'Cumbres III': 3456,
    'Ejido la Guayana (Rancho Seco)': 3456,
    'El Bajío': 3456,
    'El Chayote': 3456,
    'El Cuervero (Cuerveros)': 3456,
    'El Llano': 3456,
    'El Refugio de Peñuelas': 3456,
    'El Refugio de Providencia (Providencia)': 3456,
    'El Salero': 3456,
    'El Tule': 3456,
    'Emiliano Zapata': 3456,
    'Escaleras': 3456,
    'Ex-Viñedos Guadalupe': 3456,
    'General Ignacio Zaragoza': 3456,
    'Guadalupe de Atlas': 3456,
    'Jaltiche de Arriba': 3456,
    'Jaltomate': 3456,
    'Jarillas': 3456,
    'Jesús Gómez Portugal': 3456,
    'Jesús Gómez Portugal (Margaritas)': 3456,
    'Jesús María': 3456,
    'La Concepción': 3456,
    'La Escondida': 3456,
    'La Labor': 3456,
    'La Loma de los Negritos': 3456,
    'La Panadera': 3456,
    'La Punta': 3456,
    'Las Ánimas': 3456,
    'Las Norias de Ojocaliente': 3456,
    'Lázaro Cárdenas': 3456,
    'Los Arquitos': 3456,
    'Los Caños': 3456,
    'Los Conos': 3456,
    'Macario J. Gómez [Colonia]': 3456,
    'Malpaso': 3456,
    'Maravillas': 3456,
    'Mesa Grande': 3456,
    'Miravalle': 3456,
    'Molinos': 3456,
    'Montoro': 3456,
    'Noria del Borrego (Norias)': 3456,
    'Norias del Paso Hondo': 3456,
    'Ojo de Agua de Crucitas': 3456,
    'Ojocaliente': 3456,
    'Pabellón de Arteaga': 3456,
    'Pabellón de Hidalgo': 3456,
    'Palo Alto': 3456,
    'Paredes': 3456,
    'Paseos de la Providencia [Fraccionamiento]': 3456,
    'Paseos de las Haciendas [Fraccionamiento]': 3456,
    'Paso Blanco': 3456,
    'Peñuelas (El Cienegal)': 3456,
    'Pilotos': 3456,
    'Pocitos': 3456,
    'Puertecito de la Virgen': 3456,
    'Rincón de Romos': 3456,
    'San Antonio': 3456,
    'San Antonio de los Horcones': 3456,
    'San Antonio de los Ríos': 3456,
    'San Antonio de Peñuelas': 3456,
    'San Francisco de los Romo': 3456,
    'San Ignacio': 3456,
    'San Jacinto': 3456,
    'San José de Gracia': 3456,
    'San Luis de Letras': 3456,
    'San Sebastián [Fraccionamiento]': 3456,
    'San Tadeo': 3456,
    'Santa Isabel [Fraccionamiento]': 3456,
    'Santa María de la Paz': 3456,
    'Santa Rosa (El Huizache)': 3456,
    'Santiago': 3456,
    'Tepetates': 3456,
    'Tepezalá': 3456,
    'Valladolid': 3456,
    'Valle Huejúcar (Fraccionamiento Popular) [Fraccionamiento]': 3456,
    'Villa Juárez': 3456,
    'Villa Licenciado Jesús Terán (Calvillito)': 3456,
    'Alfonso Garzón [Granjas Familiares]': 3457,
    'Benito García (El Zorrillo)': 3457,
    'Benito Juárez': 3457,
    'Buenos Aires': 3457,
    'Camalú': 3457,
    'Cereso del Hongo': 3457,
    'Ciudad Coahuila (Kilómetro Cincuenta y Siete)': 3457,
    'Ciudad Morelos': 3457,
    'Colinas del Sol': 3457,
    'Colonia Gómez Morín': 3457,
    'Colonia Lomas de San Ramón (Triquis)': 3457,
    'Colonia Nueva Era': 3457,
    'Colonia Venustiano Carranza': 3457,
    'Delta': 3457,
    'Doctor Alberto Oviedo Mota': 3457,
    'Durango': 3457,
    'Ejido Doctor Alberto Oviedo Mota (El Indiviso)': 3457,
    'Ejido General Leandro Valle': 3457,
    'Ejido Javier Rojo Gómez': 3457,
    'Ejido Jiquilpan': 3457,
    'Ejido Lázaro Cárdenas': 3457,
    'Ejido Michoacán de Ocampo': 3457,
    'Ejido Netzahualcóyotl': 3457,
    'Ejido Nuevo León': 3457,
    'Ejido Ojo de Agua': 3457,
    'Ejido Pátzcuaro': 3457,
    'Ejido Plan de Ayala': 3457,
    'Ejido Quintana Roo': 3457,
    'Ejido Saltillo': 3457,
    'Ejido Sinaloa': 3457,
    'Ejido Sonora': 3457,
    'Ejido Tabasco': 3457,
    'Ejido Toluca': 3457,
    'Ejido Vicente Guerrero': 3457,
    'Ejido Yucatán': 3457,
    'El Niño': 3457,
    'El Porvenir': 3457,
    'El Rosario de Arriba': 3457,
    'El Sauzal de Rodríguez': 3457,
    'Emiliano Zapata': 3457,
    'Ensenada': 3457,
    'Estación Coahuila': 3457,
    'Estación Pescaderos': 3457,
    'Fraccionamiento del Valle': 3457,
    'Francisco Zarco': 3457,
    'Guadalupe Victoria': 3457,
    'Guerrero Negro': 3457,
    'Hacienda Tecate': 3457,
    'Hermosillo': 3457,
    'Islas Agrarias Grupo A': 3457,
    'Islas Agrarias Grupo B': 3457,
    'La Esperanza [Granjas Familiares]': 3457,
    'La Providencia': 3457,
    'Las Brisas': 3457,
    'Las Delicias': 3457,
    'Lázaro Cárdenas': 3457,
    'Licenciado Gustavo Díaz Ordaz': 3457,
    'Lomas de Santa Anita': 3457,
    'Lomas de Tlatelolco': 3457,
    'Los Algodones': 3457,
    'Los Valles': 3457,
    'Luis Rodríguez (El Vergel)': 3457,
    'Maclovio Herrera (Colonia Aviación)': 3457,
    'Maclovio Rojas': 3457,
    'Mexicali': 3457,
    'Playas de Rosarito': 3457,
    'Poblado Chulavista (El Chorizo)': 3457,
    'Poblado Lázaro Cárdenas (La Veintiocho)': 3457,
    'Pórticos de San Antonio': 3457,
    'Primo Tapia': 3457,
    'Progreso': 3457,
    'Puebla': 3457,
    'Pueblo Benito García': 3457,
    'Quinta del Cedro': 3457,
    'Rancho La Gloria': 3457,
    'Rancho Verde': 3457,
    'Rodolfo Sánchez Taboada': 3457,
    'Rosarito': 3457,
    'Rumorosa': 3457,
    'Salvador Rosas Magallón': 3457,
    'San Felipe': 3457,
    'San Luis': 3457,
    'San Quintín': 3457,
    'San Vicente': 3457,
    'Santa Anita': 3457,
    'Santa Fé': 3457,
    'Santa Isabel': 3457,
    'Tecate': 3457,
    'Tecolots': 3457,
    'Terrazas del Valle': 3457,
    'Tijuana': 3457,
    'Vicente Guerrero': 3457,
    'Villa del Campo': 3457,
    'Villa del Prado 2da Sección': 3457,
    'Viñas del Sol': 3457,
    'Bahía Asunción': 3460,
    'Bahía Tortugas': 3460,
    'Cabo San Lucas': 3460,
    'Chametla': 3460,
    'Ciudad Constitución': 3460,
    'Ciudad Insurgentes': 3460,
    'Colonia del Sol': 3460,
    'Comondú': 3460,
    'El Centenario': 3460,
    'El Pescadero': 3460,
    'El Silencio': 3460,
    'La Paz': 3460,
    'La Playa': 3460,
    'La Rivera': 3460,
    'Las Margaritas': 3460,
    'Las Palmas': 3460,
    'Las Veredas': 3460,
    'Loreto': 3460,
    'Los Barriles': 3460,
    'Los Cabos': 3460,
    'Melitón Albáñez Domínguez': 3460,
    'Miraflores': 3460,
    'Mulegé': 3460,
    'Puerto Adolfo Lopez Mateos': 3460,
    'Puerto San Carlos': 3460,
    'San Francisco': 3460,
    'San José del Cabo': 3460,
    'Santa Rosalía': 3460,
    'Todos Santos': 3460,
    'Villa Alberto Andrés Alvarado Arámburo': 3460,
    'Villa Morelos': 3460,
    'Abelardo L. Rodríguez': 3475,
    'Alfredo V. Bonfil': 3475,
    'Altamira de Zináparo': 3475,
    'Atasta': 3475,
    'Bacabchén': 3475,
    'Bécal': 3475,
    'Benito Juárez Uno': 3475,
    'Bolonchén de Rejón': 3475,
    'Calakmul': 3475,
    'Calkiní': 3475,
    'Campeche': 3475,
    'Candelaria': 3475,
    'Carmen': 3475,
    'Carrillo Puerto': 3475,
    'Castamay': 3475,
    'Champotón': 3475,
    'Chicbul': 3475,
    'Chiná': 3475,
    'Chunchintok': 3475,
    'Ciudad del Carmen': 3475,
    'Colonia Emiliano Zapata': 3475,
    'Constitución': 3475,
    'Cumpich': 3475,
    'División del Norte': 3475,
    'Don Samuel': 3475,
    'Dzibalchén': 3475,
    'Dzitbalché': 3475,
    'El Aguacatal': 3475,
    'El Naranjo': 3475,
    'Escárcega': 3475,
    'Hampolol': 3475,
    'Haro': 3475,
    'Hecelchakán': 3475,
    'Hool': 3475,
    'Hopelchén': 3475,
    'Isla Aguada': 3475,
    'Iturbide': 3475,
    'La Joya': 3475,
    'La Libertad': 3475,
    'Lerma': 3475,
    'Licenciado Gustavo Díaz Ordaz (18 de Marzo)': 3475,
    'Los Laureles': 3475,
    'Mamantel': 3475,
    'Matamoros': 3475,
    'Maya Tecún I': 3475,
    'Nuevo Progreso': 3475,
    'Nunkiní': 3475,
    'Palizada': 3475,
    'Pich': 3475,
    'Pocboc': 3475,
    'Pomuch': 3475,
    'Sabancuy': 3475,
    'San Antonio Cárdenas': 3475,
    'San Antonio Sahcabchén': 3475,
    'San Francisco Kobén': 3475,
    'San Pablo Pixtún': 3475,
    'Santa Cruz': 3475,
    'Santa Cruz Ex-Hacienda': 3475,
    'Santa Cruz Pueblo': 3475,
    'Santo Domingo Kesté': 3475,
    'Seybaplaya': 3475,
    'Sihochac': 3475,
    'Suc-Tuc': 3475,
    'Tankuché': 3475,
    'Tenabo': 3475,
    'Tepakán': 3475,
    'Tikinmul': 3475,
    'Ukúm': 3475,
    'Villa Madero': 3475,
    'Xbacab': 3475,
    'Xmabén': 3475,
    'Xpujil': 3475,
    'Zoh-Laguna': 3475,
    '1ra. Sección de Izapa': 3451,
    '2da. Sección de Medio Monte': 3451,
    'Abasolo': 3451,
    'Acacoyagua': 3451,
    'Acala': 3451,
    'Acapetahua': 3451,
    'Adolfo López Mateos': 3451,
    'Agrónomos Mexicanos': 3451,
    'Agua Azul': 3451,
    'Agua Blanca Serranía': 3451,
    'Agua Zarca': 3451,
    'Aguacatenango': 3451,
    'Agustín de Iturbide': 3451,
    'Ahuacatlán': 3451,
    'Ajilhó': 3451,
    'Aldama': 3451,
    'Alfonso Moguel': 3451,
    'Altamirano': 3451,
    'Álvaro Obregón': 3451,
    'Amado Nervo': 3451,
    'Amatán': 3451,
    'Amatenango de la Frontera': 3451,
    'Amatenango del Valle': 3451,
    'América Libre': 3451,
    'Ángel Albino Corzo': 3451,
    'Ángel Albino Corzo (Guadalupe)': 3451,
    'Apas': 3451,
    'Aquiles Serdán': 3451,
    'Arimatea': 3451,
    'Arriaga': 3451,
    'Arroyo Grande': 3451,
    'Arroyo Granizo': 3451,
    'Arroyo Palenque': 3451,
    'Arvenza Uno': 3451,
    'Aurora Ermita': 3451,
    'Aurora Esquipulas': 3451,
    'Azteca': 3451,
    'Aztlán': 3451,
    'Bachajón': 3451,
    'Bajucu': 3451,
    'Bautista Chico': 3451,
    'Bejucal de Ocampo': 3451,
    'Belisario Domínguez': 3451,
    'Bella Vista': 3451,
    'Benemérito de las Américas': 3451,
    'Benito Juárez': 3451,
    'Berriozábal': 3451,
    'Betania': 3451,
    'Bochil': 3451,
    'Bochojbo Alto': 3451,
    'Brisas Barra de Suchiate': 3451,
    'Buenavista': 3451,
    'Buenavista (Matasanos)': 3451,
    'Buenos Aires': 3451,
    'Cabeza de Toro': 3451,
    'Cacahoatán': 3451,
    'Cacaté': 3451,
    'Cálido': 3451,
    'Calzada Larga': 3451,
    'Cancuc': 3451,
    'Cantioc': 3451,
    'Cantón las Delicias': 3451,
    'Cantón Rancho Nuevo': 3451,
    'Cantón Villaflor': 3451,
    'Carmen Yalchuch': 3451,
    'Carmen Zacatal': 3451,
    'Carrillo Puerto': 3451,
    'Cash': 3451,
    'Catazajá': 3451,
    'Catishtic': 3451,
    'Cebadilla 1ra. Sección': 3451,
    'Cenobio Aguilar (La Trinidad)': 3451,
    'Cereso 14 (El Amate)': 3451,
    'Chacaljocóm': 3451,
    'Chacoma': 3451,
    'Chalam': 3451,
    'Chalchihuitán': 3451,
    'Chamula': 3451,
    'Chamulapita': 3451,
    'Chanal': 3451,
    'Chapallal Grande': 3451,
    'Chapultenango': 3451,
    'Chiapa de Corzo': 3451,
    'Chiapilla': 3451,
    'Chicoasén': 3451,
    'Chicomuselo': 3451,
    'Chicumtantic': 3451,
    'Chihuahua': 3451,
    'Chiloljá': 3451,
    'Chilón': 3451,
    'Chimhucum': 3451,
    'Chiquinival': 3451,
    'Chiquinshulum': 3451,
    'Chixtontic': 3451,
    'Chulum Cárdenas': 3451,
    'Chulum Juárez': 3451,
    'Cintalapa': 3451,
    'Cintalapa de Figueroa': 3451,
    'Ciudad Cuauhtémoc': 3451,
    'Ciudad Hidalgo': 3451,
    'Coapilla': 3451,
    'Colonia Obrera': 3451,
    'Comalapa': 3451,
    'Comitán': 3451,
    'Concepción': 3451,
    'Congregación Reforma': 3451,
    'Constitución': 3451,
    'Copainalá': 3451,
    'Copoya': 3451,
    'Cristóbal Colón': 3451,
    'Cristóbal Obregón': 3451,
    'Cruztón': 3451,
    'Cuauhtémoc': 3451,
    'Cuchulumtic': 3451,
    'Damasco': 3451,
    'Diamante de Echeverría': 3451,
    'Dieciséis de Septiembre': 3451,
    'Doctor Belisario Domínguez (La Barra)': 3451,
    'Doctor Domingo Chanona': 3451,
    'Doctor Rodulfo Figueroa (Tierra Blanca)': 3451,
    'Doctor Samuel León Brindis': 3451,
    'Dolores Jaltenango': 3451,
    'Efraín A. Gutiérrez': 3451,
    'Egipto': 3451,
    'El Águila': 3451,
    'El Ámbar': 3451,
    'El Ámbar (El Ámbar de Echeverría)': 3451,
    'El Arenal': 3451,
    'El Bosque': 3451,
    'El Calvario': 3451,
    'El Carmen': 3451,
    'El Carmen (El Limón)': 3451,
    'El Censo': 3451,
    'El Consuelo Ulapa': 3451,
    'El Copal': 3451,
    'El Copalar': 3451,
    'El Edén': 3451,
    'El Encanto': 3451,
    'El Espinal': 3451,
    'El Jardín': 3451,
    'El Jobo': 3451,
    'El Limar': 3451,
    'El Limonar': 3451,
    'El Mango': 3451,
    'El Nopal': 3451,
    'El Pacayal': 3451,
    'El Palmar (San Gabriel)': 3451,
    'El Palmar Grande': 3451,
    'El Palmarcito': 3451,
    'El Paraíso': 3451,
    'El Parral': 3451,
    'El Portal': 3451,
    'El Porvenir': 3451,
    'El Porvenir Agrarista': 3451,
    'El Porvenir de Velasco Suárez': 3451,
    'El Pozo': 3451,
    'El Progreso': 3451,
    'El Puerto': 3451,
    'El Ramal (Porvenir)': 3451,
    'El Sacrificio': 3451,
    'El Sibal': 3451,
    'El Triunfo': 3451,
    'El Triunfo 1ra. Sección (Cardona)': 3451,
    'El Triunfo de las Tres Maravillas': 3451,
    'El Tumbo': 3451,
    'El Tzay': 3451,
    'El Vergel': 3451,
    'El Zapotal': 3451,
    'El Zapotillo': 3451,
    'Emiliano Zapata': 3451,
    'Escuintla': 3451,
    'Estación Huehuetán': 3451,
    'Estación San Manuel': 3451,
    'Estación Tuzantán': 3451,
    'Estrella de Belén': 3451,
    'Faja de Oro': 3451,
    'Flor de Cacao': 3451,
    'Francisco I. Madero': 3451,
    'Francisco León': 3451,
    'Francisco Sarabia': 3451,
    'Francisco Villa': 3451,
    'Frontera Corozal': 3451,
    'Frontera Hidalgo': 3451,
    'Gabriel Esquinca': 3451,
    'Galecio Narcia': 3451,
    'Guadalupe': 3451,
    'Guadalupe Grijalva': 3451,
    'Guadalupe Victoria': 3451,
    'Guaquitepec': 3451,
    'Hermenegildo Galeana': 3451,
    'Héroes de Chapultepec': 3451,
    'Hidalgo': 3451,
    'Hidalgo Joshil': 3451,
    'Huehuetán': 3451,
    'Huitiupán': 3451,
    'Huixtla': 3451,
    'Huizachal': 3451,
    'Ignacio Allende': 3451,
    'Ignacio López Rayón': 3451,
    'Ignacio Ramírez': 3451,
    'Ignacio Zaragoza': 3451,
    'Ignacio Zaragoza (El Morro)': 3451,
    'Independencia': 3451,
    'Ixhuatán': 3451,
    'Ixtacomitán': 3451,
    'Ixtapa': 3451,
    'Ixtapangajoya': 3451,
    'Jalisco': 3451,
    'Jaltenango de la Paz': 3451,
    'Jardínes del Grijalva': 3451,
    'Jericó': 3451,
    'Jerusalén': 3451,
    'Jesús María Garza': 3451,
    'Jiquilpan (Estación Bonanza)': 3451,
    'Jiquipilas': 3451,
    'Jitotol': 3451,
    'Joaquín Miguel Gutiérrez': 3451,
    'Joaquín Miguel Gutiérrez (Margaritas)': 3451,
    'Joljá': 3451,
    'Jolsibaquil': 3451,
    'Joltealal': 3451,
    'Jomanichim': 3451,
    'José María Morelos': 3451,
    'José María Morelos y Pavón': 3451,
    'José María Pino Suárez': 3451,
    'Joshil': 3451,
    'Juan Aldama': 3451,
    'Juan del Grijalva': 3451,
    'Juárez': 3451,
    'Julián Grajales': 3451,
    'Justo Sierra': 3451,
    'Kotolte': 3451,
    'La Candelaria': 3451,
    'La Ceiba': 3451,
    'La Competencia': 3451,
    'La Concordia': 3451,
    'La Esperanza': 3451,
    'La Esperanza (El Zapotal)': 3451,
    'La Floresta': 3451,
    'La Gloria': 3451,
    'La Grandeza': 3451,
    'La Independencia': 3451,
    'La Independencia (Las Pilas)': 3451,
    'La Laguna': 3451,
    'La Libertad': 3451,
    'La Línea': 3451,
    'La Patria': 3451,
    'La Pimienta': 3451,
    'La Rinconada': 3451,
    'La Tigrilla': 3451,
    'La Trinitaria': 3451,
    'Lacandón': 3451,
    'Laguna del Cofre': 3451,
    'Larráinzar': 3451,
    'Las Brisas': 3451,
    'Las Chicharras': 3451,
    'Las Delicias': 3451,
    'Las Flechas': 3451,
    'Las Maravillas': 3451,
    'Las Margaritas': 3451,
    'Las Ollas': 3451,
    'Las Rosas': 3451,
    'Las Tazas': 3451,
    'Lázaro Cárdenas': 3451,
    'Lázaro Cárdenas (Chilil)': 3451,
    'Libertad Melchor Ocampo': 3451,
    'Libertad Ventanas': 3451,
    'Llano de la Lima': 3451,
    'Loma Bonita': 3451,
    'Lomantán': 3451,
    'Los Cafetales': 3451,
    'Los Corazones': 3451,
    'Los Naranjos': 3451,
    'Los Palacios': 3451,
    'Los Pinos': 3451,
    'Los Plátanos': 3451,
    'Los Pozos': 3451,
    'Los Riegos': 3451,
    'Luis Espinoza': 3451,
    'Macvilhó': 3451,
    'Majastic': 3451,
    'Majomut': 3451,
    'Manuel Ávila Camacho': 3451,
    'Manuel Ávila Camacho (Ponte Duro)': 3451,
    'Manuel Lazos': 3451,
    'Mapastepec': 3451,
    'Maravilla Tenejapa': 3451,
    'Maravillas': 3451,
    'Mariano Matamoros': 3451,
    'Mariscal Subikuski': 3451,
    'Marqués de Comillas': 3451,
    'Marte R. Gómez': 3451,
    'Matzam': 3451,
    'Mazapa': 3451,
    'Mazapa de Madero': 3451,
    'Mazatán': 3451,
    'Mérida': 3451,
    'Metapa': 3451,
    'Miguel Hidalgo': 3451,
    'Miguel Utrilla (Los Chorros)': 3451,
    'Misija': 3451,
    'Mitontic': 3451,
    'Mitzitón': 3451,
    'Mixcum': 3451,
    'Monte Redondo': 3451,
    'Montecristo': 3451,
    'Montecristo de Guerrero': 3451,
    'Monterrey': 3451,
    'Morelia (Victórico Rodolfo Grajales)': 3451,
    'Motozintla': 3451,
    'Moyos': 3451,
    'Muquén': 3451,
    'Nachig': 3451,
    'Narciso Mendoza': 3451,
    'Navenchauc': 3451,
    'Nicolás Bravo': 3451,
    'Nicolás Ruiz': 3451,
    'Nueva América': 3451,
    'Nueva Colombia': 3451,
    'Nueva Esperanza': 3451,
    'Nueva Independencia': 3451,
    'Nueva Libertad': 3451,
    'Nueva Libertad (El Colorado)': 3451,
    'Nueva Morelia': 3451,
    'Nueva Palestina': 3451,
    'Nueva Sesecapa': 3451,
    'Nueva Tenochtitlán (Rizo de Oro)': 3451,
    'Nuevo Amatenango': 3451,
    'Nuevo Carmen Tonapac': 3451,
    'Nuevo Francisco León': 3451,
    'Nuevo Juan del Grijalva': 3451,
    'Nuevo León': 3451,
    'Nuevo Limar': 3451,
    'Nuevo México': 3451,
    'Nuevo Milenio Valdivia': 3451,
    'Nuevo Naranjo': 3451,
    'Nuevo Nicapa': 3451,
    'Nuevo Pacayal': 3451,
    'Nuevo Pumpuapa (Cereso)': 3451,
    'Nuevo San Juan Chamula': 3451,
    'Nuevo Sitalá': 3451,
    'Nuevo Vicente Guerrero': 3451,
    'Nuevo Vicente Guerrero (El Chichonal)': 3451,
    'Nuevo Volcán Chichonal': 3451,
    'Nuevo Xochimilco': 3451,
    'Ochusjob': 3451,
    'Ococh': 3451,
    'Ocosingo': 3451,
    'Ocotepec': 3451,
    'Ocozocoautla de Espinosa': 3451,
    'Octavio Paz': 3451,
    'Ojo de Agua': 3451,
    'Omoa': 3451,
    'Once de Abril': 3451,
    'Ostuacán': 3451,
    'Osumacinta': 3451,
    'Oxchuc': 3451,
    'Oxinam': 3451,
    'Pablo L. Sidar': 3451,
    'Pacú': 3451,
    'Palenque': 3451,
    'Pantelhó': 3451,
    'Pantepec': 3451,
    'Paraíso del Grijalva': 3451,
    'Paredón': 3451,
    'Paso Hondo': 3451,
    'Pasté': 3451,
    'Patosil': 3451,
    'Patria Nueva (San José el Contento)': 3451,
    'Pavencul': 3451,
    'Perla de Acapulco': 3451,
    'Petalcingo': 3451,
    'Pichucalco': 3451,
    'Piedra Labrada': 3451,
    'Pijijiapan': 3451,
    'Plan de Agua Prieta': 3451,
    'Plan de Ayala': 3451,
    'Pomposo Castellanos': 3451,
    'Potrerillo': 3451,
    'Presidente Echeverría (Laja Tendida)': 3451,
    'Primer Cantón': 3451,
    'Profresor Roberto Barrios': 3451,
    'Puebla': 3451,
    'Pueblo Nuevo': 3451,
    'Puerto Madero': 3451,
    'Pugchén Mumuntic': 3451,
    'Punta Arena': 3451,
    'Querétaro': 3451,
    'Quintana Roo': 3451,
    'Rafael Pascacio Gamboa': 3451,
    'Rafael Ramírez': 3451,
    'Ramón F. Balboa': 3451,
    'Raudales Malpaso': 3451,
    'Raymundo Enríquez': 3451,
    'Rayón': 3451,
    'Reforma': 3451,
    'Reforma y Planada': 3451,
    'Revolución Mexicana': 3451,
    'Ricardo Flores Magón': 3451,
    'Rincón Chamula': 3451,
    'Río Blanco': 3451,
    'Río Chancalá': 3451,
    'Río Jordán': 3451,
    'Rizo de Oro': 3451,
    'Roblada Grande': 3451,
    'Rodulfo Figueroa': 3451,
    'Romerillo': 3451,
    'Sabanilla': 3451,
    'Sabinalito': 3451,
    'Saclamantón': 3451,
    'Saltillo': 3451,
    'Salto de Agua': 3451,
    'Salvador Urbina': 3451,
    'San Andrés Duraznal': 3451,
    'San Antonio Buenavista': 3451,
    'San Antonio Bulujib': 3451,
    'San Antonio del Monte': 3451,
    'San Caralampio': 3451,
    'San Cayetano': 3451,
    'San Cristóbal de las Casas': 3451,
    'San Felipe Tizapa': 3451,
    'San Fernando': 3451,
    'San Francisco (El Calvito)': 3451,
    'San Francisco Jaconá': 3451,
    'San Francisco Pujiltic': 3451,
    'San Isidro': 3451,
    'San Isidro las Banderas': 3451,
    'San Jerónimo Tulijá': 3451,
    'San José Chapayal': 3451,
    'San José las Chicharras': 3451,
    'San José Yashitinín': 3451,
    'San José Yocnajab': 3451,
    'San Lucas': 3451,
    'San Luqueño': 3451,
    'San Miguel': 3451,
    'San Miguel la Sardina': 3451,
    'San Pablo Huacano': 3451,
    'San Pedro Buenavista': 3451,
    'San Pedro Nichtalucum': 3451,
    'San Quintín': 3451,
    'San Vicente la Mesilla': 3451,
    'Santa Elena': 3451,
    'Santa María': 3451,
    'Santa Rita': 3451,
    'Santiago el Pinar': 3451,
    'Santiago Pojcol': 3451,
    'Santo Domingo': 3451,
    'Santo Domingo de las Palmas': 3451,
    'Señor del Pozo': 3451,
    'Shoctic': 3451,
    'Sibacá': 3451,
    'Sibaniljá Pocolum': 3451,
    'Siltepec': 3451,
    'Simojovel': 3451,
    'Simojovel de Allende': 3451,
    'Sinaloa': 3451,
    'Sitalá': 3451,
    'Socoltenango': 3451,
    'Soconusco': 3451,
    'Solosuchiapa': 3451,
    'Soyaló': 3451,
    'Soyatitán': 3451,
    'Suchiapa': 3451,
    'Suchiate': 3451,
    'Tacuba Nueva': 3451,
    'Talismán': 3451,
    'Tamaulipas': 3451,
    'Taniperla': 3451,
    'Tapachula': 3451,
    'Tapalapa': 3451,
    'Tapilula': 3451,
    'Tecpatán': 3451,
    'Tenango': 3451,
    'Tentic': 3451,
    'Teopisca': 3451,
    'Texcaltic': 3451,
    'Tierra y Libertad': 3451,
    'Tila': 3451,
    'Tiltepec': 3451,
    'Tinajas 1ra. Sección': 3451,
    'Tocob Leglemal': 3451,
    'Tonalá': 3451,
    'Totolapa': 3451,
    'Tres Cerros': 3451,
    'Tres Picos': 3451,
    'Tumbala': 3451,
    'Tuxtla': 3451,
    'Tuxtla Chico': 3451,
    'Tuzantán': 3451,
    'Tz  Aquiviljok': 3451,
    'Tzajalá': 3451,
    'Tzajalchén': 3451,
    'Tzeltal': 3451,
    'Tzimol': 3451,
    'Tzinil': 3451,
    'Tziscao': 3451,
    'Tzoeptic': 3451,
    'Tzontehuitz': 3451,
    'Tzopilja': 3451,
    'Ubilio García': 3451,
    'Unión Buena Vista': 3451,
    'Unión Buenavista': 3451,
    'Unión Juárez': 3451,
    'Unión Roja': 3451,
    'Usipa': 3451,
    'Vega del Rosario': 3451,
    'Veinte de Noviembre': 3451,
    'Venustiano Carranza': 3451,
    'Veracruz': 3451,
    'Verapaz': 3451,
    'Vicente Guerrero': 3451,
    'Vida Mejor I': 3451,
    'Villa Comaltitlán': 3451,
    'Villa Corzo': 3451,
    'Villa Hidalgo': 3451,
    'Villaflores': 3451,
    'Villahermosa Yaluma': 3451,
    'Villamorelos': 3451,
    'Viva Cárdenas': 3451,
    'Viva México': 3451,
    'Xochiltepec': 3451,
    'Yajalón': 3451,
    'Yaltem': 3451,
    'Yasha': 3451,
    'Yashanal': 3451,
    'Yibeljoj': 3451,
    'Yoshib': 3451,
    'Zacatonal de Juárez': 3451,
    'Zacualpa': 3451,
    'Zamora Pico de Oro': 3451,
    'Zaragoza': 3451,
    'Zaragoza la Montaña': 3451,
    'Zequentic': 3451,
    'Zinacantán': 3451,
    'Abdenago C. García': 3447,
    'Adolfo López Mateos': 3447,
    'Ahumada': 3447,
    'Aldama': 3447,
    'Allende': 3447,
    'Ampliación Colonia Lázaro Cárdenas': 3447,
    'Anáhuac': 3447,
    'Aquiles Serdán': 3447,
    'Ascensión': 3447,
    'Baborigame': 3447,
    'Bachíniva': 3447,
    'Bahuichivo': 3447,
    'Balleza': 3447,
    'Basaseachic': 3447,
    'Basúchil': 3447,
    'Batopilas': 3447,
    'Benito Juárez': 3447,
    'Bocoyna': 3447,
    'Boquilla de Babisas (La Boquilla de Conchos)': 3447,
    'Buenaventura': 3447,
    'Camargo': 3447,
    'Carichí': 3447,
    'Carretas': 3447,
    'Casas Grandes': 3447,
    'Cerocahui': 3447,
    'Chihuahua': 3447,
    'Chínipas': 3447,
    'Ciudad Delicias': 3447,
    'Colonia Abraham González (La Quemada)': 3447,
    'Colonia Campesina': 3447,
    'Colonia Felipe Ángeles': 3447,
    'Colonia Nicolás Bravo (Kilómetro Noventa y Dos)': 3447,
    'Colonia Revolución': 3447,
    'Colonia Terrazas': 3447,
    'Conchos': 3447,
    'Congregación Ortíz': 3447,
    'Coyame': 3447,
    'Coyame del Sotol': 3447,
    'Creel': 3447,
    'Cuauhtémoc': 3447,
    'Cusihuiriachi': 3447,
    'Delicias': 3447,
    'Dr. Belisario Domínguez': 3447,
    'Ejido Benito Juárez': 3447,
    'Ejido El Largo': 3447,
    'Ejido El Vergel': 3447,
    'Ejido La Quemada': 3447,
    'El Molino': 3447,
    'El Oro': 3447,
    'El Sauz': 3447,
    'El Terrero': 3447,
    'El Tule': 3447,
    'Estación Conchos': 3447,
    'Estación Consuelo': 3447,
    'Galeana': 3447,
    'Gómez Farías': 3447,
    'Gran Morelos': 3447,
    'Guachochi': 3447,
    'Guadalupe': 3447,
    'Guadalupe Victoria': 3447,
    'Guadalupe y Calvo': 3447,
    'Guazapares': 3447,
    'Guerrero': 3447,
    'Hidalgo del Parral': 3447,
    'Huejotitán': 3447,
    'Ignacio Zaragoza': 3447,
    'Independencia': 3447,
    'Janos': 3447,
    'Jiménez': 3447,
    'José Esteban Coronado': 3447,
    'José Mariano Jiménez': 3447,
    'Juárez': 3447,
    'Julimes': 3447,
    'Kilómetro Noventa y Nueve': 3447,
    'La Constitución': 3447,
    'La Cruz': 3447,
    'La Junta': 3447,
    'Las Cruces': 3447,
    'Las Varas': 3447,
    'Las Varas (Estación Babícora)': 3447,
    'Las Yerbitas [Aserradero]': 3447,
    'Lázaro Cárdenas': 3447,
    'Loma Blanca': 3447,
    'Madera': 3447,
    'Maguarichi': 3447,
    'Manuel Benavides': 3447,
    'Manuel Ojinaga': 3447,
    'Mariano Balleza': 3447,
    'Matachí': 3447,
    'Matamoros': 3447,
    'Meoqui': 3447,
    'Mesa del Huracán': 3447,
    'Miguel Ahumada': 3447,
    'Miguel Hidalgo': 3447,
    'Monte Verde': 3447,
    'Morelos': 3447,
    'Moris': 3447,
    'Naica': 3447,
    'Namiquipa': 3447,
    'Nicolas Bravo': 3447,
    'Ninguno [CERESO]': 3447,
    'Nonoava': 3447,
    'Nuevo Casas Grandes': 3447,
    'Ocampo': 3447,
    'Octaviano López': 3447,
    'Ojinaga': 3447,
    'Orranteño': 3447,
    'Oscar Soto Maynez': 3447,
    'Parral': 3447,
    'Peña Blanca': 3447,
    'Porvenir': 3447,
    'Praxedis G. Guerrero': 3447,
    'Praxédis Guerrero': 3447,
    'Pueblito de Allende': 3447,
    'Puerto Palomas': 3447,
    'Riva Palacio': 3447,
    'Rodrígo M. Quevedo': 3447,
    'Rosales': 3447,
    'Rosario': 3447,
    'Samachique': 3447,
    'Samalayuca': 3447,
    'San Agustín': 3447,
    'San Andrés': 3447,
    'San Buenaventura': 3447,
    'San Carlos': 3447,
    'San Francisco de Borja': 3447,
    'San Francisco de Conchos': 3447,
    'San Francisco del Oro': 3447,
    'San Isidro': 3447,
    'San Juanito': 3447,
    'San Lorenzo': 3447,
    'San Rafael': 3447,
    'Santa Bárbara': 3447,
    'Santa Eulalia': 3447,
    'Santa Isabel': 3447,
    'Saucillo': 3447,
    'Sisoguichi': 3447,
    'Témoris': 3447,
    'Temósachic': 3447,
    'Tomochic': 3447,
    'Turuachi': 3447,
    'Urique': 3447,
    'Uruachi': 3447,
    'Valentín Gómez Farías': 3447,
    'Valle de Allende': 3447,
    'Valle de Zaragoza': 3447,
    'Valle del Rosario': 3447,
    'Yécora': 3447,
    'Abasolo': 3471,
    'Agua Nueva': 3471,
    'Albia': 3471,
    'Alejo González (Bilbao)': 3471,
    'Arteaga': 3471,
    'Boquilla de las Perlas': 3471,
    'Candela': 3471,
    'Castaños': 3471,
    'Chula Vista': 3471,
    'Ciudad Acuña': 3471,
    'Ciudad Melchor Múzquiz': 3471,
    'Cloete': 3471,
    'Colonia Diana Laura Riojas de Colosio': 3471,
    'Colonia Venustiano Carranza': 3471,
    'Compuertas': 3471,
    'Concordia': 3471,
    'Coyote': 3471,
    'Cuatro Ciénegas de Carranza': 3471,
    'Ejido Venustiano Carranza': 3471,
    'El Cambio': 3471,
    'El Consuelo': 3471,
    'El Cuije': 3471,
    'El Lequeitio': 3471,
    'El Nilo': 3471,
    'El Perú': 3471,
    'El Porvenir (El Porvenir de Arriba)': 3471,
    'El Retiro': 3471,
    'Escuadrón Doscientos Uno': 3471,
    'Fraccionamiento la Noria': 3471,
    'Frontera': 3471,
    'General Cepeda': 3471,
    'Gilita': 3471,
    'Granada': 3471,
    'Guerrero': 3471,
    'Hércules': 3471,
    'Hidalgo': 3471,
    'Hormiguero': 3471,
    'Huachichil': 3471,
    'Huitrón': 3471,
    'Jaboncillo': 3471,
    'Jiménez': 3471,
    'La Concha': 3471,
    'La Cruz': 3471,
    'La Esmeralda': 3471,
    'La Esperanza': 3471,
    'La Fe': 3471,
    'La Florida': 3471,
    'La Luz': 3471,
    'La Palma': 3471,
    'La Partida': 3471,
    'La Paz': 3471,
    'La Perla': 3471,
    'La Pinta': 3471,
    'Laguna del Rey (Químicas del Rey)': 3471,
    'Lamadrid': 3471,
    'Las Mieleras': 3471,
    'Los Álamos': 3471,
    'Luchanas': 3471,
    'Maravillas': 3471,
    'Matamoros': 3471,
    'Mayran': 3471,
    'Minas de Barroterán': 3471,
    'Monclova': 3471,
    'Nadadores': 3471,
    'Nava': 3471,
    'Nueva Rosita': 3471,
    'Nuevo León': 3471,
    'Ocampo': 3471,
    'Ocho de Enero': 3471,
    'Palau': 3471,
    'Paredón': 3471,
    'Parras de la Fuente': 3471,
    'Piedras Negras': 3471,
    'Primero de Mayo': 3471,
    'Progreso': 3471,
    'Purísima': 3471,
    'Ramos Arizpe': 3471,
    'Rancho Alegre': 3471,
    'Río Bravo': 3471,
    'Sabinas': 3471,
    'Sacramento': 3471,
    'Saltillo': 3471,
    'San Antonio de las Alazanas': 3471,
    'San Antonio del Coyote': 3471,
    'San Buenaventura': 3471,
    'San Carlos': 3471,
    'San Esteban de Abajo': 3471,
    'San Francisco de Arriba': 3471,
    'San Ignacio': 3471,
    'San José de Aura': 3471,
    'San Juan de la Vaquería': 3471,
    'San Juan de Sabinas': 3471,
    'San Lorenzo': 3471,
    'San Marcos': 3471,
    'San Miguel': 3471,
    'Santa Ana del Pilar': 3471,
    'Santa Fé': 3471,
    'Santo Niño Aguanaval': 3471,
    'Seis de Octubre (Santo Niño)': 3471,
    'Sierra Mojada': 3471,
    'Solima': 3471,
    'Tacubaya': 3471,
    'Torreón': 3471,
    'Veinte de Noviembre': 3471,
    'Veinte de Noviembre (Santa Lucía)': 3471,
    'Ventana': 3471,
    'Viesca': 3471,
    'Villa Las Esperanzas': 3471,
    'Villa Union': 3471,
    'Virginias': 3471,
    'Zaragoza': 3471,
    'Alcaraces': 3472,
    'Armería': 3472,
    'Augusto Gómez Villanueva': 3472,
    'Caleras': 3472,
    'Camotlán de Miraflores': 3472,
    'Cerro de Ortega': 3472,
    'Ciudad de Armería': 3472,
    'Ciudad de Villa de Álvarez': 3472,
    'Cofradía': 3472,
    'Cofradía de Juárez': 3472,
    'Cofradía de Morelos': 3472,
    'Cofradía de Suchitlán': 3472,
    'Comala': 3472,
    'Coquimatlán': 3472,
    'Cuauhtémoc': 3472,
    'Cuyutlán': 3472,
    'El Colomo': 3472,
    'El Naranjo': 3472,
    'El Trapiche': 3472,
    'Ixtlahuacán': 3472,
    'Jalipa': 3472,
    'La Central': 3472,
    'Los Tepames': 3472,
    'Madrid': 3472,
    'Minatitlán': 3472,
    'Piscila': 3472,
    'Pueblo Juárez (La Magdalena)': 3472,
    'Quesería': 3472,
    'Rincón de López': 3472,
    'Suchitlán': 3472,
    'Tecomán': 3472,
    'Venustiano Carranza': 3472,
    'Zacualpan': 3472,
    'Abasolo': 3453,
    'Álvaro Obregón': 3453,
    'Antonio Amaro': 3453,
    'Arcinas': 3453,
    'Arturo Martínez Adame': 3453,
    'Banco Nacional': 3453,
    'Banderas del Águila': 3453,
    'Bermejillo': 3453,
    'California': 3453,
    'Canatlán': 3453,
    'Ceballos': 3453,
    'Ciénega de Nuestra Señora de Guadalupe': 3453,
    'Cieneguillas': 3453,
    'Cinco de Febrero': 3453,
    'Cinco de Mayo': 3453,
    'Ciudad Guadalupe Victoria': 3453,
    'Ciudad Lerdo': 3453,
    'Cuauhtemoc': 3453,
    'Cuencamé': 3453,
    'Cuencamé de Ceniceros': 3453,
    'Diez de Octubre': 3453,
    'El Arenal': 3453,
    'El Durazno': 3453,
    'El Huarache (El Guarache)': 3453,
    'El Lucero': 3453,
    'El Nayar': 3453,
    'El Rayo': 3453,
    'El Salto': 3453,
    'El Vergel': 3453,
    'Emiliano Zapata': 3453,
    'Esfuerzos Unidos': 3453,
    'Esmeralda': 3453,
    'Eureka de Media Luna (Eureka)': 3453,
    'Felipe Carrillo Puerto': 3453,
    'Francisco I. Madero': 3453,
    'Gabriel Hernández (Mancinas)': 3453,
    'General Calixto Contreras': 3453,
    'General Lázaro Cárdenas': 3453,
    'Gómez Palacio': 3453,
    'Guanaceví': 3453,
    'Guatimapé': 3453,
    'Hidalgo': 3453,
    'Ignacio Allende': 3453,
    'Ignacio Ramírez': 3453,
    'Ignacio Zaragoza': 3453,
    'Indé': 3453,
    'Jauja': 3453,
    'Jiménez': 3453,
    'José Guadalupe Aguilera (Santa Lucía)': 3453,
    'José Guadalupe Rodríguez': 3453,
    'José María Morelos': 3453,
    'José María Pino Suárez': 3453,
    'José Refugio Salcido': 3453,
    'Juan E. García': 3453,
    'La Ciudad': 3453,
    'La Flor': 3453,
    'La Goma': 3453,
    'La Joya': 3453,
    'La Loma': 3453,
    'La Luz': 3453,
    'La Popular': 3453,
    'Las Cuevas': 3453,
    'León Guzmán': 3453,
    'Llano Grande': 3453,
    'Los Angeles': 3453,
    'Mapimí': 3453,
    'Mezquital': 3453,
    'Narciso Mendoza': 3453,
    'Nazareno': 3453,
    'Nazas': 3453,
    'Nicolás Bravo': 3453,
    'Nogales': 3453,
    'Nombre de Dios': 3453,
    'Nuevo Ideal': 3453,
    'Ocampo': 3453,
    'Orizaba': 3453,
    'Pánuco de Coronado': 3453,
    'Pasaje': 3453,
    'Paso Nacional': 3453,
    'Pastor Rovaix': 3453,
    'Pedriceña': 3453,
    'Picardías': 3453,
    'Pueblo Nuevo': 3453,
    'Ramón Corona': 3453,
    'Ricardo Flores Magón': 3453,
    'Rodeo': 3453,
    'San Atenógenes (La Villita)': 3453,
    'San Bernardino de Milpillas Chico': 3453,
    'San Bernardo': 3453,
    'San Felipe': 3453,
    'San Francisco de Horizonte (Horizonte)': 3453,
    'San Francisco del Mezquital': 3453,
    'San Francisco Javier': 3453,
    'San Jacinto': 3453,
    'San José de Gracia': 3453,
    'San José de Viñedo': 3453,
    'San José de Zaragoza': 3453,
    'San Juan de Guadalupe': 3453,
    'San Juan del Rio del Centauro del Norte': 3453,
    'San Julio': 3453,
    'San Miguel de Cruces': 3453,
    'San Pedro del Gallo': 3453,
    'Santa Catarina de Tepehuanes': 3453,
    'Santa Clara': 3453,
    'Santa Cruz Luján': 3453,
    'Santa María del Oro': 3453,
    'Santiago Bayacora': 3453,
    'Santiago Papasquiaro': 3453,
    'Sapioris': 3453,
    'Sebastián Lerdo de Tejada': 3453,
    'Seis de Enero': 3453,
    'Seis de Octubre': 3453,
    'Súchil': 3453,
    'Tamazula': 3453,
    'Tamazula de Victoria': 3453,
    'Tayoltita': 3453,
    'Tepehuanes': 3453,
    'Tlahualilo de Zaragoza': 3453,
    'Topia': 3453,
    'Transporte': 3453,
    'Velardeña': 3453,
    'Venecia': 3453,
    'Venustiano Carranza': 3453,
    'Vicente Guerrero': 3453,
    'Victoria de Durango': 3453,
    'Villa de Guadalupe': 3453,
    'Villa Gregorio García': 3453,
    'Villa Hidalgo': 3453,
    'Villa Juárez': 3453,
    'Villa Montemorelos': 3453,
    'Villa Ocampo': 3453,
    'Villa Unión': 3453,
    'Abasolo': 3469,
    'Acámbaro': 3469,
    'Adjuntas del Río': 3469,
    'Aguas Buenas': 3469,
    'Alcocer': 3469,
    'Aldama': 3469,
    'Alfaro': 3469,
    'Ameche': 3469,
    'Apaseo el Alto': 3469,
    'Apaseo el Grande': 3469,
    'Arandas': 3469,
    'Atarjea': 3469,
    'Bajío de Bonillas': 3469,
    'Barretos': 3469,
    'Barrio de Guadalupe del Mezquitillo': 3469,
    'Barrón': 3469,
    'Boquillas': 3469,
    'Buenavista de Cortés': 3469,
    'Caleras de Ameche': 3469,
    'Calzada del Tepozán': 3469,
    'Cañada de Bustos': 3469,
    'Cañada de Caracheo': 3469,
    'Cañada de Negros': 3469,
    'Capulín de Bustos': 3469,
    'Cárdenas': 3469,
    'Carrizal Grande': 3469,
    'Celaya': 3469,
    'Centro Familiar la Soledad': 3469,
    'Cerano': 3469,
    'Cerrito de Gasca': 3469,
    'Cerritos': 3469,
    'Cerro Colorado': 3469,
    'Cerro Gordo': 3469,
    'Chamácuaro': 3469,
    'Charco de Pantoja': 3469,
    'Chichimequillas': 3469,
    'Chirimoya (Estación Chirimoya)': 3469,
    'Churipitzeo': 3469,
    'Cieneguilla': 3469,
    'Ciudad Manuel Doblado': 3469,
    'Coachiti': 3469,
    'Colonia 18 de Marzo': 3469,
    'Colonia Fraccionamiento el Puente': 3469,
    'Colonia Francisco Javier Mina': 3469,
    'Colonia la Calzada (La Olla)': 3469,
    'Colonia Latinoamericana': 3469,
    'Colonia Morelos': 3469,
    'Colonia Morelos de Guadalupe de Rivera': 3469,
    'Colonia Nuevo México': 3469,
    'Colonia Padre Hidalgo': 3469,
    'Colonia Patria Nueva': 3469,
    'Colonia Pedro María Anaya': 3469,
    'Colonia Rafael Corrales Ayala': 3469,
    'Colonia San Luis Rey': 3469,
    'Comanjilla': 3469,
    'Comonfort': 3469,
    'Coroneo': 3469,
    'Corral de Piedras de Arriba': 3469,
    'Corralejo de Arriba': 3469,
    'Cortazar': 3469,
    'Cuarta Brigada': 3469,
    'Cuchicuato': 3469,
    'Cuerámaro': 3469,
    'Cuesta de Peñones': 3469,
    'Cupareo': 3469,
    'De Parral': 3469,
    'Delgado de Abajo': 3469,
    'Delgado de Arriba': 3469,
    'Derramadero Segundo (Infiernillo)': 3469,
    'Doctor Mora': 3469,
    'Dolores Hidalgo Cuna de la Independencia Nacional': 3469,
    'Don Diego': 3469,
    'Don Francisco': 3469,
    'Duarte': 3469,
    'Ejido Jesús María': 3469,
    'Ejido la Joya': 3469,
    'El Acebuche': 3469,
    'El Canario': 3469,
    'El Capulín': 3469,
    'El Caracol': 3469,
    'El Carmen': 3469,
    'El Carretón': 3469,
    'El Carrizalito': 3469,
    'El Castillo': 3469,
    'El CERESO': 3469,
    'El Chinaco (El Pujido)': 3469,
    'El Coecillo': 3469,
    'El Copalillo': 3469,
    'El Divisador': 3469,
    'El Escoplo': 3469,
    'El Espejo': 3469,
    'El Fresno': 3469,
    'El Gallinero': 3469,
    'El Huizache': 3469,
    'El Jaguey': 3469,
    'El Llanito': 3469,
    'El Maguey': 3469,
    'El Mezquitillo': 3469,
    'El Moral': 3469,
    'El Nacimiento': 3469,
    'El Naranjillo': 3469,
    'El Picacho': 3469,
    'El Puesto': 3469,
    'El Recuerdo de Ancón (Xoconoxtle de Arriba)': 3469,
    'El Refugio de los Sauces': 3469,
    'El Sabino': 3469,
    'El Salto de Espejo': 3469,
    'El Salvador (Ranchito San José del Carmen)': 3469,
    'El Sauz (El Sauz de Villaseñor)': 3469,
    'El Tecolote': 3469,
    'El Tejocote (El Domingo)': 3469,
    'El Tunal': 3469,
    'El Varal': 3469,
    'El Vicarlo': 3469,
    'El Zapote': 3469,
    'Empalme Escobedo': 3469,
    'Estación Corralejo': 3469,
    'Estación de San Francisco': 3469,
    'Estación Joaquín': 3469,
    'Estación la Piedad': 3469,
    'Estación Pénjamo': 3469,
    'Estancia del Llano': 3469,
    'Estanzuela de Romero': 3469,
    'Estrada': 3469,
    'Ex-Hacienda del Copal': 3469,
    'Fábrica de Melchor': 3469,
    'Fraccionamiento la Mezquitera': 3469,
    'Fraccionamiento las Liebres': 3469,
    'Fraccionamiento Paraíso Real': 3469,
    'Fraccionamiento Paseo de las Torres': 3469,
    'Fraccionamiento Praderas de la Venta': 3469,
    'Fraccionamiento Villa Jardín': 3469,
    'Fraccionamiento Villas de Guanajuato': 3469,
    'Franco': 3469,
    'Franco Tavera': 3469,
    'Frías': 3469,
    'Gachupines': 3469,
    'Galerade Panales': 3469,
    'Gasca': 3469,
    'Gavia de Rionda': 3469,
    'Guadalupe': 3469,
    'Guadalupe de Paso Blanco': 3469,
    'Guadalupe de Rivera': 3469,
    'Guadalupe de Tambula': 3469,
    'Guadalupe del Monte': 3469,
    'Guanajuato': 3469,
    'Hacienda Arriba': 3469,
    'Hacienda de la Bolsa': 3469,
    'Hacienda de Márquez': 3469,
    'Hoya de Cintora (La Hoya de Arriba)': 3469,
    'Huanímaro': 3469,
    'Huitzatarito': 3469,
    'Ibarrilla': 3469,
    'Irámuco': 3469,
    'Irapuato': 3469,
    'Jalpilla': 3469,
    'Jamaica': 3469,
    'Jaral del Progreso': 3469,
    'Jauregui': 3469,
    'Jerécuaro': 3469,
    'Jesús del Monte': 3469,
    'Jícamas': 3469,
    'Jocoqui': 3469,
    'Juan Martín': 3469,
    'Juventino Rosas': 3469,
    'La Aldea': 3469,
    'La Angostura': 3469,
    'La Aurora': 3469,
    'La Caja': 3469,
    'La Calera': 3469,
    'La Capilla': 3469,
    'La Ciénega': 3469,
    'La Cieneguita': 3469,
    'La Compañía': 3469,
    'La Cruz': 3469,
    'La Cruz del Palmar': 3469,
    'La Cuevita': 3469,
    'La Ermita': 3469,
    'La Escondida': 3469,
    'La Estancia de San José del Carmen': 3469,
    'La Estrella': 3469,
    'La Gavia': 3469,
    'La Joya de Calvillo': 3469,
    'La Labor': 3469,
    'La Laborcita': 3469,
    'La Laja': 3469,
    'La Lobera': 3469,
    'La Luz': 3469,
    'La Moncada': 3469,
    'La Norita': 3469,
    'La Ordeña': 3469,
    'La Ortiga': 3469,
    'La Palma': 3469,
    'La Palmita (La Palmita de San Gabriel)': 3469,
    'La Purísima': 3469,
    'La Sabana': 3469,
    'La Sardina': 3469,
    'La Sauceda': 3469,
    'La Soledad': 3469,
    'La Tinaja': 3469,
    'La Trinidad': 3469,
    'Labor de Peralta': 3469,
    'Ladrilleras del Refugio': 3469,
    'Laguna de Guadalupe': 3469,
    'Laguna Larga': 3469,
    'Laguna Larga de Cortés': 3469,
    'Landín': 3469,
    'Las Ánimas': 3469,
    'Las Cañas': 3469,
    'Las Huertas Tercera Sección': 3469,
    'Las Liebres': 3469,
    'Las Masas (La Luz Masas)': 3469,
    'Las Trojas': 3469,
    'León': 3469,
    'León de los Aldama': 3469,
    'Lo de Juárez': 3469,
    'Loma de la Esperanza': 3469,
    'Loma de Yerbabuena': 3469,
    'Loma de Zempoala': 3469,
    'Loma Pelada': 3469,
    'Loma Tendida': 3469,
    'Los Ángeles': 3469,
    'Los Arcos': 3469,
    'Los Desmontes': 3469,
    'Los Dolores (Las Quince Letras)': 3469,
    'Los Dulces Nombres': 3469,
    'Los Fierros': 3469,
    'Los Galvan': 3469,
    'Los Lorenzos': 3469,
    'Los Mancera': 3469,
    'Los Medranos': 3469,
    'Los Nicolases': 3469,
    'Los Ocotes': 3469,
    'Los Prietos': 3469,
    'Los Ramírez': 3469,
    'Los Remedios': 3469,
    'Los Rodríguez': 3469,
    'Loza de Barrera': 3469,
    'Loza de los Padres': 3469,
    'Lucio Blanco (Los Gavilanes)': 3469,
    'Magdalena de Araceo': 3469,
    'Malagana (San Antonio del Monte)': 3469,
    'Manuel Doblado': 3469,
    'Maravatío del Encinal': 3469,
    'Marfil': 3469,
    'Marroquín': 3469,
    'Medina': 3469,
    'Medio Sitio': 3469,
    'Menores': 3469,
    'Merino': 3469,
    'Mexicanos': 3469,
    'Mezquite de Luna': 3469,
    'Mezquite de Sotelo': 3469,
    'Mezquite Gordo': 3469,
    'Mineral de la Luz': 3469,
    'Misión de Chichimecas': 3469,
    'Molino de San José': 3469,
    'Molino de Santa Ana': 3469,
    'Monte Blanco': 3469,
    'Monte del Coecillo': 3469,
    'Monte Grande': 3469,
    'Morales': 3469,
    'Moroleón': 3469,
    'Neutla': 3469,
    'Nigromante': 3469,
    'Obrajuelo': 3469,
    'Ocampo': 3469,
    'Ojo de Agua de Ballesteros': 3469,
    'Ojo de Agua de la Trinidad': 3469,
    'Ojo de Agua de Mendoza': 3469,
    'Ojo de Agua del Refugio': 3469,
    'Ojo Seco': 3469,
    'Orduña de Abajo': 3469,
    'Orduña de Arriba': 3469,
    'Otates': 3469,
    'Palmillas de San Juan': 3469,
    'Palo Colorado': 3469,
    'Panales Jamaica (Cañones)': 3469,
    'Parácuaro': 3469,
    'Parangarico': 3469,
    'Paso de Pirules': 3469,
    'Paxtle': 3469,
    'Pénjamo': 3469,
    'Peñuelas': 3469,
    'Peralta': 3469,
    'Piedras de Lumbre': 3469,
    'Piedras Negras': 3469,
    'Piñícuaro': 3469,
    'Plan de Ayala': 3469,
    'Plan Guanajuato (La Sandía)': 3469,
    'Plancarte': 3469,
    'Potrerillos (Guanajal)': 3469,
    'Pozos': 3469,
    'Prados del Rosario': 3469,
    'Presa Blanca': 3469,
    'Primera Fracción de Crespo': 3469,
    'Providencia': 3469,
    'Providencia de Nápoles': 3469,
    'Pueblo Nuevo': 3469,
    'Puentecillas': 3469,
    'Puerta del Monte': 3469,
    'Purísima de Bustos': 3469,
    'Purísima de Covarrubias': 3469,
    'Purísima del Progreso': 3469,
    'Purísima del Rincón': 3469,
    'Puroagua': 3469,
    'Rancho de Guadalupe': 3469,
    'Rancho Nuevo de la Cruz': 3469,
    'Rancho Nuevo de la Luz': 3469,
    'Rancho Nuevo del Llanito': 3469,
    'Rancho Viejo': 3469,
    'Rincón de Cano': 3469,
    'Rincón de Parangueo': 3469,
    'Rincón de Tamayo': 3469,
    'Rincón del Centeno': 3469,
    'Rinconadas del Bosque': 3469,
    'Río Laja': 3469,
    'Rizos de la Joya (Rizos del Saucillo)': 3469,
    'Romita': 3469,
    'Roque': 3469,
    'Salamanca': 3469,
    'Salitrillo': 3469,
    'Salvatierra': 3469,
    'San Agustín de las Flores': 3469,
    'San Andrés Enguaro': 3469,
    'San Ángel': 3469,
    'San Antonio Calichar': 3469,
    'San Antonio de Corrales': 3469,
    'San Antonio de los Morales': 3469,
    'San Antonio de los Tepetates': 3469,
    'San Antonio de Romerillo': 3469,
    'San Antonio el Chico': 3469,
    'San Antonio el Rico': 3469,
    'San Antonio Eménguaro': 3469,
    'San Antonio Gallardo': 3469,
    'San Antonio Primero': 3469,
    'San Antonio Texas': 3469,
    'San Bartolo de Berrios': 3469,
    'San Bartolomé Aguas Calientes': 3469,
    'San Bernardo': 3469,
    'San Bernardo Peña Blanca': 3469,
    'San Cayetano': 3469,
    'San Cristóbal': 3469,
    'San Diego': 3469,
    'San Diego de Alcalá': 3469,
    'San Diego de la Unión': 3469,
    'San Elías': 3469,
    'San Felipe': 3469,
    'San Felipe Quiriceo': 3469,
    'San Francisco': 3469,
    'San Francisco (Baños de Agua Caliente)': 3469,
    'San Francisco de Durán': 3469,
    'San Francisco del Rincón': 3469,
    'San Gabriel': 3469,
    'San Gabriel y San Ignacio': 3469,
    'San Gregorio': 3469,
    'San Ignacio': 3469,
    'San Ignacio de Hidalgo': 3469,
    'San Ignacio de Rivera (Ojo de Agua)': 3469,
    'San Ignacio de San José Parangueo': 3469,
    'San Isidro de Gamboa': 3469,
    'San Isidro de la Concepción': 3469,
    'San Isidro de la Estacada': 3469,
    'San Isidro de los Sauces': 3469,
    'San Javier': 3469,
    'San Jerónimo de Araceo': 3469,
    'San José de Agua Azul': 3469,
    'San José de Ayala': 3469,
    'San José de Bernalejo (El Guayabo)': 3469,
    'San José de Cervera': 3469,
    'San José de Durán (Los Troncoso)': 3469,
    'San José de Guanajuato': 3469,
    'San José de la Montaña': 3469,
    'San José de las Pilas': 3469,
    'San José de Llanos': 3469,
    'San José de Mendoza': 3469,
    'San José de Rancho Nuevo (Los Arrieros)': 3469,
    'San José del Cerrito de Camargo': 3469,
    'San José del Potrero': 3469,
    'San José del Rodeo': 3469,
    'San José del Torreón': 3469,
    'San José el Nuevo': 3469,
    'San José Iturbide': 3469,
    'San José Temascatío': 3469,
    'San José Viborillas': 3469,
    'San Juan Bautista Cacalote': 3469,
    'San Juan de Abajo': 3469,
    'San Juan de la Vega': 3469,
    'San Juan de Otates': 3469,
    'San Juan del Llanito': 3469,
    'San Juan Grande': 3469,
    'San Juan Jaripeo': 3469,
    'San Juan Pan de Arriba': 3469,
    'San Lorenzo': 3469,
    'San Lucas': 3469,
    'San Luis de la Paz': 3469,
    'San Miguel de Allende': 3469,
    'San Miguel del Arenal': 3469,
    'San Miguel Eménguaro': 3469,
    'San Miguel Octopan': 3469,
    'San Nicolás de la Condesa': 3469,
    'San Nicolás de los Agustinos': 3469,
    'San Nicolás del Cármen': 3469,
    'San Nicolás Parangueo': 3469,
    'San Nicolás Temascatío': 3469,
    'San Pablo Pejo': 3469,
    'San Pedro de Ibarra': 3469,
    'San Pedro de los Naranjos': 3469,
    'San Pedro Tenango': 3469,
    'San Roque': 3469,
    'San Roque de Montes': 3469,
    'San Roque de Torres': 3469,
    'San Salvador Torrecillas': 3469,
    'San Sebastián de Salitre': 3469,
    'San Vicente': 3469,
    'San Vicente de Flores': 3469,
    'Santa Ana': 3469,
    'Santa Ana Pacueco': 3469,
    'Santa Anita': 3469,
    'Santa Bárbara': 3469,
    'Santa Catarina': 3469,
    'Santa Clara': 3469,
    'Santa Cruz de Juventino Rosas': 3469,
    'Santa Fe de la Purísima': 3469,
    'Santa María del Refugio': 3469,
    'Santa Rosa': 3469,
    'Santa Rosa de Lima': 3469,
    'Santa Rosa de Rivas': 3469,
    'Santa Rosa los Angeles': 3469,
    'Santa Teresa': 3469,
    'Santa Teresita de Don Diego': 3469,
    'Santiago Capitiro': 3469,
    'Santiago de Cuenda': 3469,
    'Santiago Maravatío': 3469,
    'Santo Tomás Huatzindeo': 3469,
    'Sarabia': 3469,
    'Serrano': 3469,
    'Silao': 3469,
    'Silao de la Victoria': 3469,
    'Silva': 3469,
    'Soledad Nueva': 3469,
    'Sotelo': 3469,
    'Suchitlán': 3469,
    'Tamazula': 3469,
    'Tarandacuao': 3469,
    'Taretán': 3469,
    'Tarimoro': 3469,
    'Tejocote de Calera': 3469,
    'Tenango el Nuevo': 3469,
    'Tenería del Santuario': 3469,
    'Tequisquiapan': 3469,
    'Tierra Fría': 3469,
    'Tomelopitos': 3469,
    'Tulillos de Abajo': 3469,
    'Tupataro': 3469,
    'Uriangato': 3469,
    'Urireo': 3469,
    'Valencia de Cerro Gordo': 3469,
    'Valencianita': 3469,
    'Valle de Santiago': 3469,
    'Valtierrilla': 3469,
    'Victoria': 3469,
    'Victoria de Cortazar': 3469,
    'Villagrán': 3469,
    'Villas de Irapuato': 3469,
    'Xichú': 3469,
    'Xoconoxtle el Grande': 3469,
    'Yerbabuena': 3469,
    'Yuriria': 3469,
    'Yustis': 3469,
    'Zangarro (Zangarro Nuevo)': 3469,
    'Zapote de Barajas': 3469,
    'Zapote de Cestao': 3469,
    'Zapote de Palomas': 3469,
    'Zapote de Peralta': 3469,
    'Zapotillo de Mogotes': 3469,
    '10 de Abril': 3459,
    'Acahuizotla': 3459,
    'Acalco': 3459,
    'Acamixtla': 3459,
    'Acapetlahuaya': 3459,
    'Acapulco de Juárez': 3459,
    'Acatempa': 3459,
    'Acatempan': 3459,
    'Acatepec': 3459,
    'Acatlán': 3459,
    'Acuitlapan': 3459,
    'Agua Zarca': 3459,
    'Aguas Blancas': 3459,
    'Aguas Calientes': 3459,
    'Ahuacachahue (Ndog  yo Itún Tichi)': 3459,
    'Ahuacuotzingo': 3459,
    'Ahuajutla': 3459,
    'Ahuatepec Pueblo': 3459,
    'Ahuehuepan': 3459,
    'Ahuexotitlán': 3459,
    'Ahuihuiyuco': 3459,
    'Ajuchitlán del Progreso': 3459,
    'Alcholoa': 3459,
    'Alcozacán': 3459,
    'Alcozauca de Guerrero': 3459,
    'Almolonga': 3459,
    'Alpoyeca': 3459,
    'Alpuyecancingo de las Montañas': 3459,
    'Amatillo': 3459,
    'Amuco de la Reforma': 3459,
    'Apalani': 3459,
    'Apango': 3459,
    'Apantla': 3459,
    'Apaxtla': 3459,
    'Apaxtla de Castrejón': 3459,
    'Apipilulco': 3459,
    'Aratichanguío': 3459,
    'Arcelia': 3459,
    'Arcelia de Rodríguez': 3459,
    'Arenal de Álvarez (Arenal de Paco)': 3459,
    'Atenango del Río': 3459,
    'Atenxoxola': 3459,
    'Atlamajac': 3459,
    'Atlamajalcingo del Río': 3459,
    'Atliaca': 3459,
    'Atlixtac': 3459,
    'Atoyac de Álvarez': 3459,
    'Atzacoaloya': 3459,
    'Axaxacualco': 3459,
    'Axixintla': 3459,
    'Ayahualulco': 3459,
    'Ayotzinapa': 3459,
    'Ayutla de los Libres': 3459,
    'Azoyú': 3459,
    'Bajos del Ejido': 3459,
    'Barra de Tecoanapa': 3459,
    'Barrio Nuevo': 3459,
    'Barrio Nuevo de los Muertos': 3459,
    'Buena Vista': 3459,
    'Buena Vista de la Salud': 3459,
    'Buenavista de Allende': 3459,
    'Buenavista de Cuéllar': 3459,
    'Cacalotenango': 3459,
    'Cacalutla': 3459,
    'Cahuatache': 3459,
    'Cajelitos': 3459,
    'Campanario': 3459,
    'Carrizalillo': 3459,
    'Caxitepec': 3459,
    'Cayaco': 3459,
    'Cerro de Piedra': 3459,
    'Chacalapa': 3459,
    'Changata': 3459,
    'Chaucingo': 3459,
    'Chiaucingo': 3459,
    'Chichihualco': 3459,
    'Chiepetepec': 3459,
    'Chilacachapa': 3459,
    'Chilapa de Álvarez': 3459,
    'Chilpancingo': 3459,
    'Chontalcoatlán': 3459,
    'Ciudad Altamirano': 3459,
    'Ciudad de Huitzuco': 3459,
    'Coachimalco': 3459,
    'Coacoyula de Álvarez': 3459,
    'Coacoyulichán': 3459,
    'Coacoyulillo': 3459,
    'Coahuayutla de Guerrero': 3459,
    'Coahuayutla de José María Izazaga': 3459,
    'Coatepec Costales': 3459,
    'Cochoapa': 3459,
    'Cochoapa el Grande': 3459,
    'Cocula': 3459,
    'Colombia de Guadalupe': 3459,
    'Colonia Aeropuerto': 3459,
    'Colonia Alborada': 3459,
    'Colonia Constitución': 3459,
    'Colonia el Pedregal': 3459,
    'Colonia Luces en el Mar': 3459,
    'Colonia Nueva Revolución': 3459,
    'Colonia Renacimiento': 3459,
    'Colotepec': 3459,
    'Colotlipa': 3459,
    'Copala': 3459,
    'Copalillo': 3459,
    'Copanatoyac': 3459,
    'Corral Falso': 3459,
    'Coyahualco': 3459,
    'Coyuca de Benítez': 3459,
    'Coyuca de Catalán': 3459,
    'Coyuquilla Norte': 3459,
    'Cruz Grande': 3459,
    'Cruz Quemada': 3459,
    'Cuajinicuilapa': 3459,
    'Cualác': 3459,
    'Cuanacaxtitlán': 3459,
    'Cuatzoquitengo': 3459,
    'Cuauhtenango': 3459,
    'Cuautepec': 3459,
    'Cuetzala del Progreso': 3459,
    'Cumbre de Barranca Honda': 3459,
    'Cuonetzingo': 3459,
    'Cutzamala de Pinzón': 3459,
    'Cuyuxtlahuaca': 3459,
    'Dos Arroyos': 3459,
    'Ejido Nuevo': 3459,
    'Ejido Viejo': 3459,
    'El Bejuco': 3459,
    'El Carmen': 3459,
    'El Coacoyul': 3459,
    'El Conchero': 3459,
    'El Cortés': 3459,
    'El Cortijo': 3459,
    'El Durazno': 3459,
    'El Embarcadero': 3459,
    'El Escondido': 3459,
    'El Espinalillo': 3459,
    'El Fresno': 3459,
    'El Jicaral': 3459,
    'El Limón': 3459,
    'El Mesón': 3459,
    'El Metlapil': 3459,
    'El Naranjito': 3459,
    'El Ocotito': 3459,
    'El Papayo': 3459,
    'El Paraíso': 3459,
    'El Pericón': 3459,
    'El Pitahayo': 3459,
    'El Polvorín': 3459,
    'El Potrerillo (Potrerillo del Rincón)': 3459,
    'El Refugio': 3459,
    'El Rincón (Santa Cruz del Rincón)': 3459,
    'El Salto': 3459,
    'El Súchil': 3459,
    'El Tejocote': 3459,
    'El Terrero': 3459,
    'El Ticui': 3459,
    'Escalerilla Lagunas': 3459,
    'Fermín Rabadán Cervantes': 3459,
    'Florencio Villarreal': 3459,
    'General Canuto A. Neri': 3459,
    'General Heliodoro Castillo': 3459,
    'Guadalupe Victoria': 3459,
    'Guayameo': 3459,
    'Hacienda de Cabañas': 3459,
    'Horcasitas': 3459,
    'Huamuchapa': 3459,
    'Huamuxtitlán': 3459,
    'Huehuetán': 3459,
    'Hueycantenango': 3459,
    'Hueyitlalpan': 3459,
    'Huitziltepec': 3459,
    'Huitzuco de los Figueroa': 3459,
    'Huixtepec': 3459,
    'Iguala de la Independencia': 3459,
    'Igualapa': 3459,
    'Iliatenco': 3459,
    'Ixcamilpa': 3459,
    'Ixcapuzalco': 3459,
    'Ixcateopan': 3459,
    'Ixcateopan de Cuauhtémoc': 3459,
    'Ixcatla': 3459,
    'Ixtapa': 3459,
    'Ixtapa-Zihuatanejo': 3459,
    'Izotepec': 3459,
    'Jalapa': 3459,
    'Jaleaca de Catalán': 3459,
    'Jicayán de Tovar': 3459,
    'Jolotichán': 3459,
    'Julián Blanco (Dos Caminos)': 3459,
    'Kilómetro 30': 3459,
    'Kilómetro 40': 3459,
    'La Azozuca': 3459,
    'La Concepción': 3459,
    'La Concepción (La Concha)': 3459,
    'La Concordia': 3459,
    'La Dicha': 3459,
    'La Esperanza': 3459,
    'La Estación': 3459,
    'La Guadalupe': 3459,
    'La Luz de Juárez': 3459,
    'La Mohonera': 3459,
    'La Palma': 3459,
    'La Soledad': 3459,
    'La Unión': 3459,
    'Lagunillas': 3459,
    'Las Ánimas': 3459,
    'Las Compuertas': 3459,
    'Las Cruces': 3459,
    'Las Lomas': 3459,
    'Las Mesas': 3459,
    'Las Plazuelas': 3459,
    'Las Tunas': 3459,
    'Las Vigas': 3459,
    'Llano de la Puerta': 3459,
    'Llano Grande': 3459,
    'Lodo Grande': 3459,
    'Lomas de Chapultepec': 3459,
    'Lomas de San Juan': 3459,
    'Los Achotes': 3459,
    'Los Almendros': 3459,
    'Los Liros': 3459,
    'Los Mogotes': 3459,
    'Los Órganos de San Agustín': 3459,
    'Los Sauces': 3459,
    'Los Saucitos': 3459,
    'Los Tepetates': 3459,
    'Malinaltepec': 3459,
    'Marquelia': 3459,
    'Maxela': 3459,
    'Mayanalán': 3459,
    'Mazatlán': 3459,
    'Melchor Ocampo': 3459,
    'Metlapa': 3459,
    'Metlatónoc': 3459,
    'Mezcala': 3459,
    'Mochitlán': 3459,
    'Mohoneras': 3459,
    'Montecillos': 3459,
    'Morelita': 3459,
    'Moyotepec (Moyotepec de Juárez)': 3459,
    'Nejapa': 3459,
    'Nueva Frontera': 3459,
    'Nuevo Balsas': 3459,
    'Nuevo Guerrero': 3459,
    'Nuxco': 3459,
    'Ocotequila': 3459,
    'Ocotillo': 3459,
    'Ocotlán': 3459,
    'Olinalá': 3459,
    'Ometepec': 3459,
    'Oxtotitlán': 3459,
    'Oztotitlán': 3459,
    'Paintla': 3459,
    'Palmillas': 3459,
    'Palo Blanco': 3459,
    'Palos Blancos': 3459,
    'Pantitlán': 3459,
    'Pantla': 3459,
    'Papanoa': 3459,
    'Paraje Montero (Paraje Montero de Zaragoza)': 3459,
    'Pascala del Oro': 3459,
    'Paso de Arena': 3459,
    'Paso Morelos': 3459,
    'Patambó': 3459,
    'Patlicha': 3459,
    'Petacalco': 3459,
    'Petaquillas': 3459,
    'Petatlán': 3459,
    'Pilcaya': 3459,
    'Placeres del Oro': 3459,
    'Platanillo': 3459,
    'Pochahuizco': 3459,
    'Pochotillo': 3459,
    'Pochutla': 3459,
    'Pololcingo': 3459,
    'Pueblo Hidalgo': 3459,
    'Pueblo Madero (El Playón)': 3459,
    'Pueblo Viejo': 3459,
    'Pungarabato': 3459,
    'Quechultenango': 3459,
    'Querendas': 3459,
    'Quetzalapa': 3459,
    'Rancho de las Lomas': 3459,
    'Rancho Viejo': 3459,
    'Rancho Viejo (Rancho Nuevo de la Democracia)': 3459,
    'Sabanillas': 3459,
    'San Agustín Oapan': 3459,
    'San Andrés Playa Encantada (El Podrido)': 3459,
    'San Ángel': 3459,
    'San Antonio Coyahuacán': 3459,
    'San Antonio de las Huertas': 3459,
    'San Cristóbal': 3459,
    'San Francisco': 3459,
    'San Francisco Oxtutla': 3459,
    'San Francisco Ozomatlán': 3459,
    'San Gregorio': 3459,
    'San Isidro Gallinero (El Gallinero)': 3459,
    'San Jeronimito': 3459,
    'San Jerónimo de Juárez': 3459,
    'San Jerónimo el Grande': 3459,
    'San José Ixtapa (Barrio Viejo)': 3459,
    'San José la Hacienda': 3459,
    'San José Poliutla': 3459,
    'San Juan de Dios (Naranjas de Dios)': 3459,
    'San Juan Totolcintla': 3459,
    'San Lorenzo': 3459,
    'San Luis Acatlán': 3459,
    'San Luis de La Loma': 3459,
    'San Luis San Pedro': 3459,
    'San Marcos': 3459,
    'San Martín': 3459,
    'San Martín Pachivia (Pachivia)': 3459,
    'San Martín Peras': 3459,
    'San Miguel Axoxuca': 3459,
    'San Miguel de las Palmas': 3459,
    'San Miguel el Grande': 3459,
    'San Miguel Tecuiciapan': 3459,
    'San Miguel Totolapan': 3459,
    'San Miguelito': 3459,
    'San Nicolás': 3459,
    'San Pablo Atzompa': 3459,
    'San Pedro Cacahuatepec': 3459,
    'San Pedro Cuitlapan': 3459,
    'San Pedro Huitzapula Norte (Huitzapula Norte)': 3459,
    'San Pedro las Playas': 3459,
    'San Rafael': 3459,
    'San Vicente': 3459,
    'San Vicente Palapa': 3459,
    'San Vicente Zoyatlán': 3459,
    'Santa Anita': 3459,
    'Santa Bárbara': 3459,
    'Santa Catarina': 3459,
    'Santa Cruz': 3459,
    'Santa Cruz Yucucani': 3459,
    'Santa Fe Tepetlapa': 3459,
    'Santa María': 3459,
    'Santa Teresa': 3459,
    'Santiago Tilapa': 3459,
    'Sinahua': 3459,
    'Tanganhuato': 3459,
    'Taxco el Viejo': 3459,
    'Tecalpulco': 3459,
    'Tecolcuautla': 3459,
    'Técpan de Galeana': 3459,
    'Tecuexcontitlán (Tecoescontitlán)': 3459,
    'Tehuilotepec': 3459,
    'Tehuixtla': 3459,
    'Teloloapan': 3459,
    'Tenexpa': 3459,
    'Tepechicotlán': 3459,
    'Tepecoacuilco de Trujano': 3459,
    'Tepetixtla': 3459,
    'Tepozcuautla': 3459,
    'Tequicuilco': 3459,
    'Tetipac': 3459,
    'Tetitlán': 3459,
    'Texca': 3459,
    'Tierra Colorada': 3459,
    'Tixtla de Guerrero': 3459,
    'Tixtlancingo': 3459,
    'Tlacoachistlahuaca': 3459,
    'Tlacoapa': 3459,
    'Tlacoaxtla': 3459,
    'Tlacotepec': 3459,
    'Tlahuapa': 3459,
    'Tlalchapa': 3459,
    'Tlalcozotitlán': 3459,
    'Tlalixtaquilla': 3459,
    'Tlaltempanapa': 3459,
    'Tlamacazapa': 3459,
    'Tlamixtlahuacan': 3459,
    'Tlanipatla': 3459,
    'Tlapa de Comonfort': 3459,
    'Tlapehuala': 3459,
    'Tlaquiltepec': 3459,
    'Tlatlauquitepec': 3459,
    'Tlatzala': 3459,
    'Tlaxcalixtlahuaca': 3459,
    'Tlaxinga (Clatzinga)': 3459,
    'Tlaxmalac': 3459,
    'Tonalá': 3459,
    'Tonalapa': 3459,
    'Tonalapa del Sur': 3459,
    'Topiltepec': 3459,
    'Totomixtlahuaca': 3459,
    'Tototepec': 3459,
    'Tres Palos': 3459,
    'Tulimán': 3459,
    'Tuncingo': 3459,
    'Tutepec': 3459,
    'Tuxpan': 3459,
    'Valle del Río': 3459,
    'Valle Luz': 3459,
    'Vallecitos de Zaragoza': 3459,
    'Villa Hermosa': 3459,
    'Villa Hermosa (Las Pozas)': 3459,
    'Villa Hidalgo (El Cubo)': 3459,
    'Villa Madero': 3459,
    'Villa Nicolás Bravo': 3459,
    'Villa Rotaria': 3459,
    'Xalatzala': 3459,
    'Xalitla': 3459,
    'Xalpatlahuac': 3459,
    'Xaltianguis': 3459,
    'Xochapa': 3459,
    'Xochihuehuetlán': 3459,
    'Xochipala': 3459,
    'Xochistlahuaca': 3459,
    'Xochitepec': 3459,
    'Xocoyolzintla': 3459,
    'Yetla': 3459,
    'Yetlancingo': 3459,
    'Yextla': 3459,
    'Yoloxóchitl': 3459,
    'Zacacoyuca': 3459,
    'Zacapuato': 3459,
    'Zacatula': 3459,
    'Zacoalpan': 3459,
    'Zacualpan': 3459,
    'Zapotitlán Tablas': 3459,
    'Zelocotitlán': 3459,
    'Zilacayotitlán': 3459,
    'Zirándaro': 3459,
    'Zirándaro de los Chávez': 3459,
    'Zitlala': 3459,
    'Zizicazapa': 3459,
    'Zoquiapa': 3459,
    'Zoquitlán': 3459,
    'Zotoltitlán': 3459,
    'Zoyatlán': 3459,
    'Zumpango': 3459,
    'Zumpango del Río': 3459,
    'Acahuasco': 3470,
    'Acatepec': 3470,
    'Acatlán': 3470,
    'Acaxochitlán': 3470,
    'Acayuca': 3470,
    'Acoapa': 3470,
    'Acoxcatlán': 3470,
    'Acoyotla': 3470,
    'Actopan': 3470,
    'Ahuatitla': 3470,
    'Ahuehuetitla': 3470,
    'Ajacuba': 3470,
    'Alfajayucan': 3470,
    'Almoloya': 3470,
    'Álvaro Obregón': 3470,
    'Apan': 3470,
    'Atengo': 3470,
    'Atitalaquia': 3470,
    'Atlapexco': 3470,
    'Atotonilco de Tula': 3470,
    'Atotonilco el Grande': 3470,
    'Bangandhó': 3470,
    'Barrio Aztlán': 3470,
    'Benito Juárez': 3470,
    'Bomanxotha': 3470,
    'Bomintzha': 3470,
    'Bondojito': 3470,
    'Calnali': 3470,
    'Caltimacan': 3470,
    'Camelia (Barrio la Camelia)': 3470,
    'Campestre Villas del Álamo': 3470,
    'Cañada': 3470,
    'Cañada de Madero': 3470,
    'Cantera de Villagrán': 3470,
    'Cantinela': 3470,
    'Carboneras': 3470,
    'Cardonal': 3470,
    'Carpinteros': 3470,
    'Caxuxi': 3470,
    'Cerritos': 3470,
    'Chachahuantla': 3470,
    'Chalahuiyapa': 3470,
    'Chapantongo': 3470,
    'Chapulhuacán': 3470,
    'Chapulhuacanito': 3470,
    'Chatipán': 3470,
    'Chicavasco': 3470,
    'Chichatla': 3470,
    'Chilcuautla': 3470,
    'Chililico': 3470,
    'Chimalapa': 3470,
    'Chimalpa y Tlalayote': 3470,
    'Cipreses': 3470,
    'Ciudad Sahagun': 3470,
    'Coacuilco': 3470,
    'Colinas de Plata': 3470,
    'Colonia 28 de Mayo (Santa Rosa)': 3470,
    'Colonia Benito Juárez': 3470,
    'Colonia Felipe Ángeles': 3470,
    'Colonia Guadalupe': 3470,
    'Colonia Militar': 3470,
    'Colonia Morelos': 3470,
    'Colonia Morelos (El Nueve)': 3470,
    'Colonia San Juan': 3470,
    'Colonia Sesenta y Dos': 3470,
    'Colonia Teñhe': 3470,
    'Conejos': 3470,
    'Coyotillos': 3470,
    'Cuapaxtitla': 3470,
    'Cuatolol': 3470,
    'Cuautepec de Hinojosa': 3470,
    'Cuazahuatl': 3470,
    'Dajiedi': 3470,
    'Dantzibojay': 3470,
    'Daxtha': 3470,
    'Dengantzha': 3470,
    'Déxtho de Victoria': 3470,
    'Dios Padre': 3470,
    'Don Antonio': 3470,
    'Doxey': 3470,
    'El Arenal': 3470,
    'El Barrido': 3470,
    'El Bingú': 3470,
    'El Boxtha': 3470,
    'El Capulín': 3470,
    'El Cid': 3470,
    'El Colorado': 3470,
    'El Huaxtho': 3470,
    'El Huixmí': 3470,
    'El Jagüey': 3470,
    'El Jiadi': 3470,
    'El Llano': 3470,
    'El Mirador': 3470,
    'El Moreno (San Miguel Moreno)': 3470,
    'El Nith': 3470,
    'El Olvera': 3470,
    'El Paredón': 3470,
    'El Pintor': 3470,
    'El Refugio': 3470,
    'El Rincón': 3470,
    'El Roble': 3470,
    'El Rosario': 3470,
    'El Saucillo': 3470,
    'El Saucillo (Fraccionamiento)': 3470,
    'El Susto': 3470,
    'El Tepeyac': 3470,
    'El Tephé': 3470,
    'El Venado': 3470,
    'Eloxochitlán': 3470,
    'Emiliano Zapata': 3470,
    'Epazoyucan': 3470,
    'Estación de Apulco': 3470,
    'Fontezuelas': 3470,
    'Forjadores de Pachuca': 3470,
    'Fraccionamiento Carlos Salinas de Gortari': 3470,
    'Fraccionamiento del Magisterio Tulancinguense': 3470,
    'Gandhó': 3470,
    'General Felipe Ángeles (Los Ángeles)': 3470,
    'General Pedro María Anaya': 3470,
    'Guadalupe Minerva': 3470,
    'Guadalupe Victoria': 3470,
    'Hualula': 3470,
    'Huasca de Ocampo': 3470,
    'Huautla': 3470,
    'Huehuetla': 3470,
    'Huejutla de Reyes': 3470,
    'Hueyapa': 3470,
    'Huichapan': 3470,
    'Huitel': 3470,
    'Huitepec': 3470,
    'Huitzila': 3470,
    'Huitzitzilingo': 3470,
    'Ignacio Zaragoza': 3470,
    'Irolo': 3470,
    'Ixcatlán': 3470,
    'Ixcuinquitlapilco': 3470,
    'Ixmiquilpan': 3470,
    'Ixtlahuaca': 3470,
    'Jacala': 3470,
    'Jagüey Blanco': 3470,
    'Jagüey de Téllez (Estación Téllez)': 3470,
    'Jalpa': 3470,
    'Jaltepec': 3470,
    'Jaltocan': 3470,
    'Javier Rojo Gómez': 3470,
    'Jonacapa': 3470,
    'José María Morelos (San José)': 3470,
    'José María Pino Suárez': 3470,
    'Julián Villagrán': 3470,
    'La Amistad': 3470,
    'La Boveda': 3470,
    'La Colonia': 3470,
    'La Cruz': 3470,
    'La Esquina': 3470,
    'La Estancia': 3470,
    'La Estanzuela': 3470,
    'La Laguna': 3470,
    'La Lagunilla': 3470,
    'La Loma': 3470,
    'La Mesilla': 3470,
    'La Providencia Siglo XXI': 3470,
    'La Reforma': 3470,
    'La Sabinita': 3470,
    'La Sala (La Caliente)': 3470,
    'La Trinidad': 3470,
    'Lagunilla': 3470,
    'Lázaro Cárdenas': 3470,
    'Lindavista': 3470,
    'Llano Largo': 3470,
    'Lolotla': 3470,
    'Los Cides': 3470,
    'Los Reyes': 3470,
    'Los Romeros': 3470,
    'Los Sabinos': 3470,
    'Los Tuzos': 3470,
    'Machetla': 3470,
    'Magisterio Digno': 3470,
    'Maguey Blanco': 3470,
    'Mamithi': 3470,
    'Mangas': 3470,
    'Manuel Ávila Camacho': 3470,
    'Mecatlán': 3470,
    'Melchor Ocampo': 3470,
    'Metepec': 3470,
    'Metepec Primero': 3470,
    'Metztitlán': 3470,
    'Mezquititlán': 3470,
    'Michimaloya': 3470,
    'Mineral del Monte': 3470,
    'Mixquiahuala de Juarez': 3470,
    'Molango': 3470,
    'Monte Alegre': 3470,
    'Motovatha': 3470,
    'Munitepec de Madero': 3470,
    'Nantzha': 3470,
    'Nopala de Villagran': 3470,
    'Nuevo Centro de Población Agrícola el Chacón': 3470,
    'Ocampo': 3470,
    'Ohuatipa': 3470,
    'Ojo de Agua': 3470,
    'Omitlán de Juárez': 3470,
    'Oriental de Zapata': 3470,
    'Orizabita': 3470,
    'Oxeloco': 3470,
    'Pachiquita': 3470,
    'Pachuca de Soto': 3470,
    'Pachuquilla': 3470,
    'Pahactla': 3470,
    'Pahuatlán': 3470,
    'Palmillas': 3470,
    'Panales': 3470,
    'Pañhé': 3470,
    'Panuaya': 3470,
    'Papatlatla': 3470,
    'Parque de Poblamiento Solidaridad': 3470,
    'Parque Urbano Napateco': 3470,
    'Paseos de la Pradera': 3470,
    'Patria Nueva': 3470,
    'Paxtepec': 3470,
    'Pedregal de San José': 3470,
    'Pino Suárez': 3470,
    'Pisaflores': 3470,
    'Portezuelo': 3470,
    'Pozuelos': 3470,
    'Praderas del Potrero': 3470,
    'Presas': 3470,
    'PRI Chacón': 3470,
    'Privada del Álamo': 3470,
    'Progreso': 3470,
    'Progreso de Alvaro Obregon': 3470,
    'Pueblo Hidalgo': 3470,
    'Pueblo Nuevo': 3470,
    'Residencial Arboledas': 3470,
    'Rinconada de los Ángeles': 3470,
    'Rinconadas de San Francisco': 3470,
    'Rinconadas del Venado I': 3470,
    'Rincones de la Hacienda': 3470,
    'Rio de la Soledad': 3470,
    'Río Seco Puente de Doria': 3470,
    'Salitrillo': 3470,
    'San Agustín Tlaxiaca': 3470,
    'San Agustín Zapotlán': 3470,
    'San Andrés': 3470,
    'San Andrés (San Andrés Chichayotla)': 3470,
    'San Antonio': 3470,
    'San Antonio el Grande': 3470,
    'San Antonio el Paso': 3470,
    'San Antonio Zaragoza': 3470,
    'San Bartolo Ozocalpan': 3470,
    'San Bartolo Tutotepec': 3470,
    'San Buenaventura': 3470,
    'San Cristóbal Chacón': 3470,
    'San Esteban': 3470,
    'San Felipe Orizatlán': 3470,
    'San Francisco': 3470,
    'San Francisco Atotonilco': 3470,
    'San Gabriel': 3470,
    'San Gabriel Azteca': 3470,
    'San Gregorio': 3470,
    'San Ignacio Nopala': 3470,
    'San Ildefonso': 3470,
    'San Isidro': 3470,
    'San Joaquín': 3470,
    'San José': 3470,
    'San José Atlán': 3470,
    'San José Boxay': 3470,
    'San José Corral Blanco': 3470,
    'San José Ocotillos': 3470,
    'San Juan Achichilco': 3470,
    'San Juan Ahuehueco': 3470,
    'San Juan Hueyapan': 3470,
    'San Juan Solís': 3470,
    'San Juan Tepa': 3470,
    'San Juan Tilcuautla': 3470,
    'San Juan Tizahuapán': 3470,
    'San Lorenzo Achiotepec': 3470,
    'San Lorenzo Sayula': 3470,
    'San Luis Tecuhautitlán': 3470,
    'San Marcos': 3470,
    'San Marcos Guaquilpan': 3470,
    'San Mateo': 3470,
    'San Miguel Cerezo (El Cerezo)': 3470,
    'San Miguel Vindho': 3470,
    'San Nicolás': 3470,
    'San Nicolás el Chico': 3470,
    'San Nicolás Tecomatlán': 3470,
    'San Pablo el Grande': 3470,
    'San Pedro Tlachichilco': 3470,
    'San Pedro Tlaquilpan': 3470,
    'San Salvador': 3470,
    'San Sebastián Tenochtitlán': 3470,
    'Santa Ana Ahuehuepan': 3470,
    'Santa Ana Azcapotzaltongo': 3470,
    'Santa Ana Batha': 3470,
    'Santa Ana de Allende': 3470,
    'Santa Ana Hueytlalpan': 3470,
    'Santa Ana Tlachiahualpa': 3470,
    'Santa Ana Tzacuala': 3470,
    'Santa Bárbara': 3470,
    'Santa Catarina': 3470,
    'Santa Clara': 3470,
    'Santa Cruz': 3470,
    'Santa Elena Paliseca': 3470,
    'Santa María Actipac': 3470,
    'Santa María Amajac': 3470,
    'Santa Maria Amealco': 3470,
    'Santa María Apaxco': 3470,
    'Santa María Asunción': 3470,
    'Santa María Batha': 3470,
    'Santa María Ilucan': 3470,
    'Santa María la Calera': 3470,
    'Santa Maria Macua': 3470,
    'Santa María Magdalena': 3470,
    'Santa María Nativitas': 3470,
    'Santa María Quelites': 3470,
    'Santa María Xigui': 3470,
    'Santa Mónica': 3470,
    'Santa Teresa': 3470,
    'Santa Úrsula': 3470,
    'Santiago Acayutlán': 3470,
    'Santiago Cuaula': 3470,
    'Santiago de Anaya': 3470,
    'Santiago Jaltepec': 3470,
    'Santiago Tepeyahualco': 3470,
    'Santiago Tezontlale': 3470,
    'Santiago Tlaltepoxco': 3470,
    'Santiago Tlapacoya': 3470,
    'Santiago Tlapanaloya': 3470,
    'Santiago Tlautla': 3470,
    'Santiago Tulantepec': 3470,
    'Santo Tomas': 3470,
    'Singuilucan': 3470,
    'Talol': 3470,
    'Tancazahuela': 3470,
    'Tasquillo': 3470,
    'Taxadho': 3470,
    'Teacalco': 3470,
    'Tecacahuaco': 3470,
    'Tecozautla': 3470,
    'Tehuetlán': 3470,
    'Teltipán de Juárez': 3470,
    'Temango': 3470,
    'Tenango': 3470,
    'Tenango de Doria': 3470,
    'Teocalco': 3470,
    'Teofani': 3470,
    'Tepatepec': 3470,
    'Tepeapulco': 3470,
    'Tepehuacán de Guerrero': 3470,
    'Tepeitic': 3470,
    'Tepeji del Río de Ocampo': 3470,
    'Tepepa': 3470,
    'Tepetitla': 3470,
    'Tepetitlán': 3470,
    'Tepexititla': 3470,
    'Tepojaco': 3470,
    'Tetepango': 3470,
    'Texcaco': 3470,
    'Texcaltepec': 3470,
    'Texcapa': 3470,
    'Texcatepec': 3470,
    'Tezapotla': 3470,
    'Tezontepec': 3470,
    'Tezontepec de Aldama': 3470,
    'Tezoquipa': 3470,
    'Tianguistengo': 3470,
    'Tianguistengo (La Romera)': 3470,
    'Tiltepec': 3470,
    'Tinajas': 3470,
    'Tizayuca': 3470,
    'Tlacomulco': 3470,
    'Tlacpac': 3470,
    'Tlahuelilpan': 3470,
    'Tlahuelompa (San Francisco Tlahuelompa)': 3470,
    'Tlahuiltepa': 3470,
    'Tlalchiyahualica': 3470,
    'Tlalminulpa': 3470,
    'Tlamaco (San Gerónimo Tlamaco)': 3470,
    'Tlanalapa': 3470,
    'Tlanchinol': 3470,
    'Tlaxcalilla': 3470,
    'Tlaxcoapan': 3470,
    'Tlaxocoyucan': 3470,
    'Tolcayuca': 3470,
    'Tula de Allende': 3470,
    'Tulancingo': 3470,
    'Tulancingo de Bravo': 3470,
    'Tulipanes': 3470,
    'Tunititlán': 3470,
    'Unidad Habitacional Antonio Osorio de León (Bojay)': 3470,
    'Unidad Minera 11 de Julio': 3470,
    'Unidades Habitacionales': 3470,
    'Ventoquipa': 3470,
    'Vicente Guerrero': 3470,
    'Vicente Guerrero (San Javier)': 3470,
    'Vito': 3470,
    'Xiquila': 3470,
    'Xiteje de Zapata': 3470,
    'Xochiatipan': 3470,
    'Xochiatipan de Castillo': 3470,
    'Xochicoatlán': 3470,
    'Xochitlán': 3470,
    'Xolostitla de Morelos (Xolostitla)': 3470,
    'Xoxolpa': 3470,
    'Yahualica': 3470,
    'Yolotepec': 3470,
    'Zacacuautla': 3470,
    'Zacamulpa': 3470,
    'Zacualtipán': 3470,
    'Zacualtipanito': 3470,
    'Zapotlán de Juárez': 3470,
    'Zempoala': 3470,
    'Zimapan': 3470,
    'Zocea': 3470,
    'Zoquitipán': 3470,
    'Zothé': 3470,
    'Zotoluca': 3470,
    'Acatlán de Juárez Municipality': 4857,
    'Acueducto Fraccionamiento': 4857,
    'Agua Bermeja': 4857,
    'Agua Caliente': 4857,
    'Agua Colorada': 4857,
    'Agua Delgada': 4857,
    'Agua Escondida': 4857,
    'Agua Fría': 4857,
    'Agua Nueva': 4857,
    'Agua Paloma': 4857,
    'Agua Prieta': 4857,
    'Agua Rica': 4857,
    'Agua Salada': 4857,
    'Agua Zarca': 4857,
    'Ahualulco de Mercado Municipality': 4857,
    'Ahuatlán': 4857,
    'Ahuetita de Abajo': 4857,
    'Ahuisculco': 4857,
    'Ajijic': 4857,
    'Alfredo V. Bonfil': 4857,
    'Alista': 4857,
    'Allende': 4857,
    'Altavista de Ramos': 4857,
    'Altus Bosques': 4857,
    'Amacuautitlán': 4857,
    'Amacueca Municipality': 4857,
    'Amajaquillo': 4857,
    'Amatanejo': 4857,
    'Amatitán': 4857,
    'Ambrosio': 4857,
    'Ameca': 4857,
    'Ameca Municipality': 4857,
    'Analco': 4857,
    'Anoca': 4857,
    'Antonio Escobedo': 4857,
    'Apazulco': 4857,
    'Apozol de Gutiérrez': 4857,
    'Aquiles Serdán': 4857,
    'Arandas': 4857,
    'Arroyo Hondo': 4857,
    'Atacco': 4857,
    'Atarjeas de Covarrubias': 4857,
    'Atemanica': 4857,
    'Atengo': 4857,
    'Atenguillo': 4857,
    'Atequiza': 4857,
    'Atotonilco el Alto Municipality': 4857,
    'Atotonilco el Bajo': 4857,
    'Atotonilquillo': 4857,
    'Atoyac': 4857,
    'Atzcatlán': 4857,
    'Autlán de Navarro': 4857,
    'Ayotitlán': 4857,
    'Ayotlán Municipality': 4857,
    'Ayuquila': 4857,
    'Ayutla, Jalisco': 4857,
    'Bajío de Ratones': 4857,
    'Bajío de San José': 4857,
    'Bajío Seco': 4857,
    'Balcones de la Calera': 4857,
    'Banus Vallarta': 4857,
    'Barra de Navidad': 4857,
    'Barranca de los Laureles': 4857,
    'Barranca de Otates': 4857,
    'Barranca de Santa Clara': 4857,
    'Barranca del Tule': 4857,
    'Barrio de Reyes': 4857,
    'Barrio de Tapias': 4857,
    'Belem del Refugio': 4857,
    'Bellavista': 4857,
    'Benito Juárez': 4857,
    'Betania': 4857,
    'Betulia': 4857,
    'Bicercio': 4857,
    'Boca de Tomatlán': 4857,
    'Bolaños Municipality': 4857,
    'Bosque Real Fraccionamiento': 4857,
    'Brisas de la Navidad': 4857,
    'Brizuela': 4857,
    'Buena Vista': 4857,
    'Buenavista': 4857,
    'Buenos Aires': 4857,
    'Cabo Corrientes': 4857,
    'Cajititlán': 4857,
    'Calerita': 4857,
    'Callejones': 4857,
    'Camajapita': 4857,
    'Campestre las Palomas Fraccionamiento': 4857,
    'Campo Acosta': 4857,
    'Campo Real': 4857,
    'Cañada de Islas': 4857,
    'Cañada de Ricos': 4857,
    'Cañada de San Ignacio': 4857,
    'Cañada Grande': 4857,
    'Cañadas de Obregón': 4857,
    'Canchol': 4857,
    'Canoas': 4857,
    'Canoas de Arriba': 4857,
    'Capilla de Guadalupe': 4857,
    'Capilla de Milpillas': 4857,
    'Capulín': 4857,
    'Caquixtle de Arriba': 4857,
    'Carrasqueño': 4857,
    'Carretas': 4857,
    'Carrizal': 4857,
    'Casa de Teja': 4857,
    'Casas Viejas': 4857,
    'Casimiro Castillo': 4857,
    'Castro Urdiales': 4857,
    'Ceja de Bolitas': 4857,
    'Centro de Readaptación Social': 4857,
    'Cerrito de Buenos Aires': 4857,
    'Cerrito de Tierra': 4857,
    'Cerro Santa Fe': 4857,
    'Chacala': 4857,
    'Chachahuatlán': 4857,
    'Chalpicote': 4857,
    'Chamacuero': 4857,
    'Chamela, Jalisco': 4857,
    'Chancol': 4857,
    'Chancuellar': 4857,
    'Chapala': 4857,
    'Chayotillo': 4857,
    'Chilacayote': 4857,
    'Chimaltitán': 4857,
    'Chinampas': 4857,
    'Chipinque de Arriba': 4857,
    'Chiquihuitillo': 4857,
    'Chiquilistlán': 4857,
    'Chiquilistlán, Jalisco': 4857,
    'Chome': 4857,
    'Churintzio': 4857,
    'Ciénega de Galvanes': 4857,
    'Ciénega del Pastor': 4857,
    'Cihuatlán, Jalisco': 4857,
    'Cima del Sol': 4857,
    'Citala': 4857,
    'Ciudad Guzmán': 4857,
    'Ciudad Guzmán CERESO': 4857,
    'Coamostita': 4857,
    'Coatlancillo': 4857,
    'Cocuasco': 4857,
    'Cocula, Jalisco': 4857,
    'Codorniz': 4857,
    'Cofradía': 4857,
    'Cofradía Santa Cruz de Camotlán': 4857,
    'Colinas del Roble': 4857,
    'Colinas del Valle': 4857,
    'Colonia Agua Caliente': 4857,
    'Colonia Bosques del Sol': 4857,
    'Colonia Capilla de Rayo': 4857,
    'Colonia Cuisillos': 4857,
    'Colonia Ganadera': 4857,
    'Colonia Guadalupana': 4857,
    'Colonia Hatmasie': 4857,
    'Colonia la Granjena': 4857,
    'Colonia las Granjas': 4857,
    'Colonia las Palmas Campamento SAGAR': 4857,
    'Colonia Lázaro Cárdenas': 4857,
    'Colonia Lázaro Cárdenas del Río': 4857,
    'Colonia Loma Alta': 4857,
    'Colonia los Robles': 4857,
    'Colonia los Sauces': 4857,
    'Colonia Madero': 4857,
    'Colonia Niños Héroes': 4857,
    'Colonia Paso del Molino': 4857,
    'Colonia Pinal Villa': 4857,
    'Colonia Santa Cecilia': 4857,
    'Colonia Trigomil': 4857,
    'Colonia Veintitrés de Mayo': 4857,
    'Colonia Veintiuno de Marzo': 4857,
    'Colotlán': 4857,
    'Colotlán Municipality': 4857,
    'Comanja de Corona': 4857,
    'Concepción de Buenos Aires': 4857,
    'Concepción del Bramador': 4857,
    'Cópala': 4857,
    'Copales': 4857,
    'Corral Blanco': 4857,
    'Corral de Piedra': 4857,
    'Corralillos': 4857,
    'Coyamel': 4857,
    'Coyula': 4857,
    'Cristeros Fraccionamiento': 4857,
    'Cuauhtémoc': 4857,
    'Cuautitlán': 4857,
    'Cuautitlán de García Barragán': 4857,
    'Cuautla': 4857,
    'Cuesta Chica': 4857,
    'Cuesta de Ovejas': 4857,
    'Cuexcomatitlán': 4857,
    'Cuisillos': 4857,
    'Cuitzeo': 4857,
    'Cuquío': 4857,
    'Custique': 4857,
    'Cuxpala': 4857,
    'Degollado': 4857,
    'Dieciocho de Marzo': 4857,
    'Ejido Agua Zarca': 4857,
    'Ejido Ciénega de Mora': 4857,
    'Ejido Modelo': 4857,
    'Ejido Poblado Atenquique': 4857,
    'Ejido Uno de Febrero': 4857,
    'Ejutla': 4857,
    'El Agostadero': 4857,
    'El Aguacate': 4857,
    'El Alto': 4857,
    'El Amarillo': 4857,
    'El Amarradero': 4857,
    'El Arca': 4857,
    'El Arco, Ameca': 4857,
    'El Arenal': 4857,
    'El Asoleadero': 4857,
    'El Bajío': 4857,
    'El Bajío de Plascencia': 4857,
    'El Bajío Lomas del Club': 4857,
    'El Banco del Venado': 4857,
    'El Barrio': 4857,
    'El Brillante Fraccionamiento': 4857,
    'El Briseño': 4857,
    'El Cabezón': 4857,
    'El Cacalote': 4857,
    'El Camichín': 4857,
    'El Cantón': 4857,
    'El Capulín de Chávez': 4857,
    'El Carmen': 4857,
    'El Carricillo': 4857,
    'El Carrizal': 4857,
    'El Cedazo': 4857,
    'El Cerrito': 4857,
    'El Cerro, Jalisco': 4857,
    'El Chalate': 4857,
    'El Chante': 4857,
    'El Chaveño': 4857,
    'El Chilar': 4857,
    'El Chispeadero': 4857,
    'El Chivatillo': 4857,
    'El Coahuayote': 4857,
    'El Colomo': 4857,
    'El Colorado': 4857,
    'El Columpio': 4857,
    'El Conde': 4857,
    'El Conejo': 4857,
    'El Corcovado': 4857,
    'El Cortijo': 4857,
    'El Coyotillo': 4857,
    'El Crucero': 4857,
    'El Crucero de Arandas': 4857,
    'El Crucero de Santa María': 4857,
    'El Cuatro': 4857,
    'El Derramadero': 4857,
    'El Dique': 4857,
    'El Fraile': 4857,
    'El Gato': 4857,
    'El Grullo Municipality': 4857,
    'El Hollejo': 4857,
    'El Huiroche': 4857,
    'El Jabalí': 4857,
    'El Jagüey': 4857,
    'El Jaral': 4857,
    'El Jaralillo': 4857,
    'El Jaralito': 4857,
    'El Jardín': 4857,
    'El Jazmín': 4857,
    'El Jomate': 4857,
    'El Laurel': 4857,
    'El Lazo': 4857,
    'El Limón': 4857,
    'El Llano de Barajas': 4857,
    'El Llano de los Toros': 4857,
    'El Llano de Plascencia': 4857,
    'El Lobo': 4857,
    'El Lopeño': 4857,
    'El Loreto Occidental': 4857,
    'El Macuchi': 4857,
    'El Maestranzo': 4857,
    'El Manantial': 4857,
    'El Mastranzo': 4857,
    'El Mayoral de Abajo': 4857,
    'El Medineño': 4857,
    'El Mentidero': 4857,
    'El Mesoncito': 4857,
    'El Mezquite Grande': 4857,
    'El Mirador': 4857,
    'El Molino': 4857,
    'El Moral': 4857,
    'El Muey': 4857,
    'El Nacimiento': 4857,
    'El Nacimiento de Abajo': 4857,
    'El Naranjo': 4857,
    'El Nuevo Poblado': 4857,
    'El Ocote': 4857,
    'El Ocotillo': 4857,
    'El Ojo de Agua': 4857,
    'El Ojuelo': 4857,
    'El Olvido': 4857,
    'El Padre Juan': 4857,
    'El Palmar de San Antonio': 4857,
    'El Pantano': 4857,
    'El Paso Real': 4857,
    'El Pino': 4857,
    'El Plan': 4857,
    'El Platanar': 4857,
    'El Pochote': 4857,
    'El Popote': 4857,
    'El Portezuelo': 4857,
    'El Porvenir': 4857,
    'El Pozo Santo': 4857,
    'El Pueblito': 4857,
    'El Puente': 4857,
    'El Puerto': 4857,
    'El Puerto de Amolero': 4857,
    'El Puesto': 4857,
    'El Quemado': 4857,
    'El Quince': 4857,
    'El Ranchito': 4857,
    'El Rayo': 4857,
    'El Rebalse': 4857,
    'El Rebalsito de Apazulco': 4857,
    'El Refugio': 4857,
    'El Refugio de los Altos': 4857,
    'El Refugio de Vázquez': 4857,
    'El Refugio Suchitlán': 4857,
    'El Refugio, Jalisco': 4857,
    'El Reparo': 4857,
    'El Rincón': 4857,
    'El Rodeo': 4857,
    'El Rodeo Viejo': 4857,
    'El Romereño': 4857,
    'El Rosario': 4857,
    'El Sabino': 4857,
    'El Sacrificio': 4857,
    'El Salero': 4857,
    'El Salitre': 4857,
    'El Salto': 4857,
    'El Salto de las Peñas': 4857,
    'El Salto de San Antonio': 4857,
    'El Salto Municipality': 4857,
    'El Salvador': 4857,
    'El Salvial': 4857,
    'El Saucillo': 4857,
    'El Saucillo de los Pérez': 4857,
    'El Saucito': 4857,
    'El Sauz de Ibarra': 4857,
    'El Seminario': 4857,
    'El Sitio': 4857,
    'El Sopial': 4857,
    'El Soyate': 4857,
    'El Tablero': 4857,
    'El Taray': 4857,
    'El Tarengo Nuevo': 4857,
    'El Tecolote': 4857,
    'El Tecuán': 4857,
    'El Tempisque': 4857,
    'El Tepame': 4857,
    'El Tepec': 4857,
    'El Tepetate': 4857,
    'El Tepozán': 4857,
    'El Tequesquite': 4857,
    'El Terrerito de Sevilla': 4857,
    'El Terrero': 4857,
    'El Torque': 4857,
    'El Torreón': 4857,
    'El Tuito': 4857,
    'El Tule': 4857,
    'El Tulillo': 4857,
    'El Uvalano': 4857,
    'El Verde': 4857,
    'El Vergel': 4857,
    'El Vigía': 4857,
    'El Zancudo': 4857,
    'El Zapatero': 4857,
    'El Zapote': 4857,
    'El Zapote de Chávez': 4857,
    'El Zapotillo': 4857,
    'Emiliano Zapata': 4857,
    'Emiliano Zapata, Jalisco': 4857,
    'Encarnación de Díaz': 4857,
    'Epeche Chico': 4857,
    'Epeche Grande': 4857,
    'Estancia de San Nicolás': 4857,
    'Estancia Vieja de Arriba': 4857,
    'Estanzuela': 4857,
    'Estipa': 4857,
    'Etzatlán Municipality': 4857,
    'Ferrería de Tula': 4857,
    'Francisco I. Madero': 4857,
    'Francisco Primo de Verdad': 4857,
    'Galaxia Bonito Jalisco': 4857,
    'Galaxia la Noria': 4857,
    'Gargantillo': 4857,
    'General Andrés Figueroa': 4857,
    'General Gabriel Leyva': 4857,
    'Geovillas Laureles del Campanario': 4857,
    'Getsemaní': 4857,
    'Gómez Farías Municipality': 4857,
    'Granadillas': 4857,
    'Guachinango': 4857,
    'Guadalajara': 4857,
    'Guadalupe de Lerma': 4857,
    'Guadalupe Victoria': 4857,
    'Guamuchilillo': 4857,
    'Guayabitos': 4857,
    'Hacienda de la Labor': 4857,
    'Hacienda los Fresnos': 4857,
    'Hacienda Santa Fe': 4857,
    'Hacienda Vieja': 4857,
    'Hacienda Vieja del Castillo': 4857,
    'Haciendas la Herradura': 4857,
    'Hierba de Matancillas': 4857,
    'Higueras San Miguel': 4857,
    'Higuerillas': 4857,
    'Hostotipaquillo': 4857,
    'Huajacatlán': 4857,
    'Huascato': 4857,
    'Huaxtla': 4857,
    'Huejotitán': 4857,
    'Huejotitlán': 4857,
    'Huejúcar': 4857,
    'Huejuquilla El Alto Municipality': 4857,
    'Huertas Familiares San Pedro': 4857,
    'Huisichi': 4857,
    'Huitzometl': 4857,
    'Huizaches': 4857,
    'Ipazoltic': 4857,
    'IPROVIPE I': 4857,
    'Ixtapa': 4857,
    'Ixtlahuacán de los Membrillos': 4857,
    'Ixtlahuacán del Río': 4857,
    'Izolta': 4857,
    'J. Isabel Flores': 4857,
    'Jalostotitlan': 4857,
    'Jaluco': 4857,
    'Jamay': 4857,
    'Jardines de San Sebastián': 4857,
    'Jardines del Edén': 4857,
    'Jaritas': 4857,
    'Jesús María': 4857,
    'Jesús María, Nayarit': 4857,
    'Jilotlán de los Dolores': 4857,
    'Jiquilpan': 4857,
    'Joconoxtle': 4857,
    'Jocotepec': 4857,
    'José de Jesús Aguirre, Teocaltiche': 4857,
    'José María Pino Suárez': 4857,
    'Josefa Ortíz de Domínguez': 4857,
    'Juan Gil Preciado': 4857,
    'Juanacatlán': 4857,
    'La Alameda': 4857,
    'La Aldaba': 4857,
    'La Angostura de los Altos': 4857,
    'La Arbolada Plus': 4857,
    'La Barca': 4857,
    'La Barranca': 4857,
    'La Calera': 4857,
    'La Calera Fraccionamiento': 4857,
    'La Cañada': 4857,
    'La Cañada Tezcaltilti': 4857,
    'La Cantera': 4857,
    'La Cebadilla': 4857,
    'La Ceja de la Capilla': 4857,
    'La Cerca Blanca': 4857,
    'La Ciénega': 4857,
    'La Ciénega de Huaistita': 4857,
    'La Cieneguita': 4857,
    'La Cofradía': 4857,
    'La Cofradía del Rosario': 4857,
    'La Colonia San Juanito': 4857,
    'La Concepción': 4857,
    'La Conchilla': 4857,
    'La Conchita': 4857,
    'La Constancia': 4857,
    'La Cruz': 4857,
    'La Cruz de Loreto': 4857,
    'La Cuchilla': 4857,
    'La Cuesta de Medina': 4857,
    'La Cueva': 4857,
    'La Estacada': 4857,
    'La Estancia': 4857,
    'La Estancia de Arriba': 4857,
    'La Estancia de Ayones': 4857,
    'La Estancia de Navarro': 4857,
    'La Estancita': 4857,
    'La Estanzuela': 4857,
    'La Floreña': 4857,
    'La Fortuna Fraccionamiento': 4857,
    'La Frontera': 4857,
    'La Garita, Jalisco': 4857,
    'La Garruña': 4857,
    'La Gila': 4857,
    'La Gloria': 4857,
    'La Granjena': 4857,
    'La Guadalupe': 4857,
    'La Guásima': 4857,
    'La Hermosura': 4857,
    'La Higuera': 4857,
    'La Huerta': 4857,
    'La Huevera': 4857,
    'La Isla': 4857,
    'La Jarrilla': 4857,
    'La Joya': 4857,
    'La Joya Chica': 4857,
    'La Joya Grande': 4857,
    'La Labor de la Concepción': 4857,
    'La Labor de los Alba': 4857,
    'La Ladera': 4857,
    'La Ladera Grande': 4857,
    'La Laguna': 4857,
    'La Laguna Grande': 4857,
    'La Laja': 4857,
    'La Lobera': 4857,
    'La Loma': 4857,
    'La Loma de los Rizo': 4857,
    'La Loma del Nopal': 4857,
    'La Lomita': 4857,
    'La Luz': 4857,
    'La Manzanilla': 4857,
    'La Manzanilla de La Paz': 4857,
    'La Mata': 4857,
    'La Merced': 4857,
    'La Mesa': 4857,
    'La Mesa del Guitarrero': 4857,
    'La Mesita': 4857,
    'La Mezcalilla': 4857,
    'La Mezquitera': 4857,
    'La Mial': 4857,
    'La Mina': 4857,
    'La Mojarra': 4857,
    'La Mora': 4857,
    'La Mota': 4857,
    'La Nance': 4857,
    'La Nopalera': 4857,
    'La Noria': 4857,
    'La Orilla de la Cerca': 4857,
    'La Orilla del Agua': 4857,
    'La Palma': 4857,
    'La Palmita': 4857,
    'La Parota': 4857,
    'La Parrita': 4857,
    'La Paz': 4857,
    'La Paz de Ordaz': 4857,
    'La Peña, Jalisco': 4857,
    'La Playa': 4857,
    'La Presa': 4857,
    'La Primavera': 4857,
    'La Providencia': 4857,
    'La Puerta de San Pedro': 4857,
    'La Puerta del Coche': 4857,
    'La Punta': 4857,
    'La Purísima': 4857,
    'La Quemada': 4857,
    'La Rana': 4857,
    'La Raya': 4857,
    'La Red': 4857,
    'La Resolana': 4857,
    'La Ribera': 4857,
    'La Roca': 4857,
    'La Rosa': 4857,
    'La Rosa Amarilla': 4857,
    'La Rosa de Castilla': 4857,
    'La Rueda': 4857,
    'La Sanguijuela': 4857,
    'La Sauceda': 4857,
    'La Soledad': 4857,
    'La Teja': 4857,
    'La Tijera': 4857,
    'La Tinajera': 4857,
    'La Tiricia': 4857,
    'La Tortuga': 4857,
    'La Trinidad': 4857,
    'La Unión del Cuatro': 4857,
    'La Vaquera': 4857,
    'La Vega': 4857,
    'La Venta del Astillero': 4857,
    'La Verdolaga': 4857,
    'La Villa': 4857,
    'La Virgencita': 4857,
    'La Yerbabuena': 4857,
    'La Zapotera': 4857,
    'Labor de Medina': 4857,
    'Labor de Montoya': 4857,
    'Ladera Chica': 4857,
    'Lagos de Moreno': 4857,
    'Laguna Colorada': 4857,
    'Laguna de Piedra': 4857,
    'Lagunillas, Jalisco': 4857,
    'Las Alfalfas': 4857,
    'Las Amarillas': 4857,
    'Las Asturias Fraccionamiento': 4857,
    'Las Azules': 4857,
    'Las Calaveras': 4857,
    'Las Cañadas': 4857,
    'Las Canoas': 4857,
    'Las Carretas': 4857,
    'Las Golondrinas [Fraccionamiento]': 4857,
    'Las Juntas': 4857,
    'Las Pintas de Arriba': 4857,
    'Las Pintitas': 4857,
    'Las Primaveras [Invernadero]': 4857,
    'Lázaro Cárdenas, Jalisco': 4857,
    'Lo Arado': 4857,
    'Lomas de San Agustín': 4857,
    'Lomas de Tejeda': 4857,
    'Lomas del Sur': 4857,
    'Los Azulitos': 4857,
    'Los Cedros': 4857,
    'Los Gavilanes': 4857,
    'Los Guerrero': 4857,
    'Los Mazos': 4857,
    'Los Olivos': 4857,
    'Los Pocitos': 4857,
    'Los Ruiseñores': 4857,
    'Los Sauces': 4857,
    'Los Vergara': 4857,
    'Magdalena': 4857,
    'Manalisco': 4857,
    'Margaritas': 4857,
    'Mascota Municipality': 4857,
    'Matancillas (San Isidro Matancillas)': 4857,
    'Matanzas': 4857,
    'Matatlán': 4857,
    'Mazamitlongo': 4857,
    'Mechoacanejo': 4857,
    'Mexticacán': 4857,
    'Mezcala': 4857,
    'Mezquitic de la Magdalena': 4857,
    'Mezquitic Municipality': 4857,
    'Milpillas': 4857,
    'Misión San Luis Gonzaga Chiriyaqui': 4857,
    'Mismaloya': 4857,
    'Mismaloya (Fraccionamiento Pedregal de Santa Martha)': 4857,
    'Mixtlán': 4857,
    'Moras': 4857,
    'Navajas': 4857,
    'Nextipac': 4857,
    'Nicolás R Casillas': 4857,
    'Nuevo México, Jalisco': 4857,
    'Nuevo Refugio de Afuera (Nuevo Refugio)': 4857,
    'Oconahua, Jalisco': 4857,
    'Ocotlán, Jalisco': 4857,
    'Ojo de Agua de Morán, Jalisco': 4857,
    'Ojuelos de Jalisco': 4857,
    'Otatlán': 4857,
    'Palomar': 4857,
    'Palos Altos': 4857,
    'Paseo de las Cañadas': 4857,
    'Paseo del Prado': 4857,
    'Paseo Puente Viejo': 4857,
    'Paseos del Valle [Fraccionamiento]': 4857,
    'Paso de Cuarenta': 4857,
    'Pegueros': 4857,
    'Piedra Pesada': 4857,
    'Pihuamo': 4857,
    'Pochotita': 4857,
    'Poncitlán Municipality': 4857,
    'Portes Gil': 4857,
    'Potrerillos': 4857,
    'Primero de Mayo (Chupaderos)': 4857,
    'Puente de Camotlán': 4857,
    'Puente Grande': 4857,
    'Puerta del Llano': 4857,
    'Puerto Vallarta': 4857,
    'Punta de la Vega': 4857,
    'Purificación': 4857,
    'Quitupan': 4857,
    'Rancho Alegre [Fraccionamiento]': 4857,
    'Real del Valle (El Paraíso) [Fraccionamiento]': 4857,
    'Rinconada la Loma [Fraccionamiento]': 4857,
    'San Agustín': 4857,
    'San Andrés Cohamiata': 4857,
    'San Andrés Ixtlán': 4857,
    'San Antonio': 4857,
    'San Antonio de los Barrera': 4857,
    'San Antonio de los Vázquez': 4857,
    'San Antonio de Rivas': 4857,
    'San Clemente': 4857,
    'San Cristóbal': 4857,
    'San Cristóbal de la Barranca Municipality': 4857,
    'San Cristóbal Zapotitlán': 4857,
    'San Diego de Alejandría': 4857,
    'San Esteban (San Miguel Tateposco)': 4857,
    'San Felipe de Híjar': 4857,
    'San Francisco de Asís': 4857,
    'San Francisco de Rivas': 4857,
    'San Gabriel,  Jalisco': 4857,
    'San Gaspar': 4857,
    'San Gaspar de los Reyes': 4857,
    'San Ignacio Municipality, Jalisco': 4857,
    'San Isidro': 4857,
    'San Isidro Mazatepec': 4857,
    'San Jacintito': 4857,
    'San Jacinto, Jalisco': 4857,
    'San Joaquín Zorrillos': 4857,
    'San Jorge': 4857,
    'San José Casas Caídas': 4857,
    'San Jose de Gracia, Jalisco': 4857,
    'San José de la Paz': 4857,
    'San José de las Flores': 4857,
    'San José de los Reynoso': 4857,
    'San José del Caliche (El Caliche)': 4857,
    'San José del Castillo': 4857,
    'San José del Valle': 4857,
    'San Juan Cosalá': 4857,
    'San Juan de los Arcos': 4857,
    'San Juan de los Lagos': 4857,
    'San Juan Tecomatlán': 4857,
    'San Juanito de Escobedo': 4857,
    'San Julián': 4857,
    'San Lucas Evangelista': 4857,
    'San Luciano': 4857,
    'San Luis de Agua Caliente': 4857,
    'San Luis Soyatlán': 4857,
    'San Marcos': 4857,
    'San Martín de Bolaños': 4857,
    'San Martín de Hidalgo': 4857,
    'San Miguel Cuyutlán': 4857,
    'San Miguel de la Paz': 4857,
    'San Miguel de la Sierra, Ayutla': 4857,
    'San Miguel el Alto': 4857,
    'San Miguel Zapotitlán': 4857,
    'San Nicolas': 4857,
    'San Nicolás de las Flores': 4857,
    'San Patricio, Jalisco': 4857,
    'San Pedro': 4857,
    'San Pedro Itzicán': 4857,
    'San Sebastian': 4857,
    'San Sebastián del Oeste': 4857,
    'San Vicente': 4857,
    'Santa Anita': 4857,
    'Santa Cruz de Barcenas': 4857,
    'Santa Cruz de la Loma (Tepetates)': 4857,
    'Santa Cruz de la Soledad': 4857,
    'Santa Cruz de las Flores, Tlajomulco de Zúñiga, Jalisco': 4857,
    'Santa Cruz del Astillero': 4857,
    'Santa Cruz del Valle': 4857,
    'Santa Cruz el Grande': 4857,
    'Santa María de Enmedio': 4857,
    'Santa María de los Ángeles': 4857,
    'Santa María del Valle': 4857,
    'Santa María Transpontina': 4857,
    'Santa Rita': 4857,
    'Santa Rosa': 4857,
    'Santa Rosalía, Jalisco': 4857,
    'Santa Teresa': 4857,
    'Santiaguito': 4857,
    'Santiaguito (Santiaguito de Velázquez)': 4857,
    'Sayula': 4857,
    'Soyatlán de Afuera': 4857,
    'Soyatlán del Oro': 4857,
    'Talpa de Allende': 4857,
    'Tamazula de Gordiano': 4857,
    'Tamazulita, Jalisco': 4857,
    'Tapalpa': 4857,
    'Tazumbo': 4857,
    'Tecalitlán Municipality': 4857,
    'Techaluta de Montenegro': 4857,
    'Tecolotlán': 4857,
    'Tecomates': 4857,
    'Tecuattitan': 4857,
    'Telcruz': 4857,
    'Temastián (La Cantera)': 4857,
    'Tenamaxtlán': 4857,
    'Tenayuca': 4857,
    'Teocaltiche': 4857,
    'Teocuitatlán de Corona': 4857,
    'Tepatitlán de Morelos': 4857,
    'Tepec': 4857,
    'Tepusco': 4857,
    'Tequesquitlán': 4857,
    'Tequila': 4857,
    'Tesistán': 4857,
    'Teuchitlán, Jalisco': 4857,
    'Tierras Coloradas': 4857,
    'Tizapán el Alto': 4857,
    'Tlacuitapan': 4857,
    'Tlajomulco de Zuñiga': 4857,
    'Tlaquepaque': 4857,
    'Tolimán Municipality': 4857,
    'Tomatlán': 4857,
    'Tonalá': 4857,
    'Tonaya': 4857,
    'Tonila': 4857,
    'Totatiche Municipality': 4857,
    'Tototlán': 4857,
    'Trapiche de Abra': 4857,
    'Trejos': 4857,
    'Tultitlán': 4857,
    'Tuxcacuesco': 4857,
    'Tuxcueca': 4857,
    'Tuxpan': 4857,
    'Tuxpan de Bolaños': 4857,
    'Unión de San Antonio': 4857,
    'Unión de Tula': 4857,
    'Usmajac': 4857,
    'Valle de Guadalupe Municipality': 4857,
    'Valle de Juárez': 4857,
    'Valle de los Girasoles [Fraccionamiento]': 4857,
    'Valle Dorado Inn': 4857,
    'Vaquerias': 4857,
    'Villa Corona': 4857,
    'Villa de los Niños': 4857,
    'Villa Emiliano Zapata': 4857,
    'Villa Guerrero Municipality': 4857,
    'Villa Hidalgo': 4857,
    'Villa las Flores': 4857,
    'Villa Purificación': 4857,
    'Villas de la Hacienda [Fraccionamiento]': 4857,
    'Vista Hermosa': 4857,
    'Vistas del Maguey [Fraccionamiento]': 4857,
    'Xochitlán': 4857,
    'Yahualica de González Gallo': 4857,
    'Zacoalco de Torres': 4857,
    'Zalamea': 4857,
    'Zapopan': 4857,
    'Zapotiltic': 4857,
    'Zapotitán de Hidalgo': 4857,
    'Zapotitlán de Vadillo': 4857,
    'Zapotlán del Rey': 4857,
    'Zapotlán El Grande Municipality': 4857,
    'Zapotlanejo': 4857,
    'Zula': 4857,
    'Acachuén': 3450,
    'Acalpican de Morelos': 3450,
    'Acambay': 3450,
    'Acatzingo (Acatzingo de la Piedra)': 3450,
    'Acolman de Netzahualcóyotl': 3450,
    'Acuitlapilco': 3450,
    'Aculco de Espinoza': 3450,
    'Agua Blanca': 3450,
    'Agua Caliente': 3450,
    'Agua Escondida': 3450,
    'Ahuacatitlán': 3450,
    'Ahuacatitlán Cuarto y Quinto Cuartel': 3450,
    'Alborada Jaltenco': 3450,
    'Almoloya de Alquisiras': 3450,
    'Almoloya del Río': 3450,
    'Amanalco de Becerra': 3450,
    'Amatepec': 3450,
    'Amecameca': 3450,
    'Ampliación San Mateo (Colonia Solidaridad)': 3450,
    'Ampliación Tezoyuca': 3450,
    'Apaxco de Ocampo': 3450,
    'Arbolada los Sauces': 3450,
    'Arenal': 3450,
    'Atenguillo': 3450,
    'Atizapán': 3450,
    'Atlacomulco de Fabela': 3450,
    'Atlatongo': 3450,
    'Atlautla': 3450,
    'Axapusco': 3450,
    'Axotlán': 3450,
    'Ayapango': 3450,
    'Barrio Bordo Nuevo': 3450,
    'Barrio Chiquichuca': 3450,
    'Barrio Cuarto (La Loma)': 3450,
    'Barrio de Arriba de San Juan Xoconusco': 3450,
    'Barrio de Boyecha': 3450,
    'Barrio de Canales': 3450,
    'Barrio de Centro del Cerrillo': 3450,
    'Barrio de Ensido': 3450,
    'Barrio de España': 3450,
    'Barrio de Guadalupe': 3450,
    'Barrio de Jesús Fracción Primera': 3450,
    'Barrio de la Barranca': 3450,
    'Barrio de México': 3450,
    'Barrio de Puentecillas': 3450,
    'Barrio de San Isidro': 3450,
    'Barrio de San Juan': 3450,
    'Barrio de San Miguel': 3450,
    'Barrio de San Pedro la Cabecera': 3450,
    'Barrio de San Ramón': 3450,
    'Barrio del Cajón': 3450,
    'Barrio el Boncho': 3450,
    'Barrio el Vivero': 3450,
    'Barrio la Joya': 3450,
    'Barrio la Tenería': 3450,
    'Barrio los Tules': 3450,
    'Barrio San Joaquín el Junco': 3450,
    'Barrio San Miguel Dorami': 3450,
    'Barrio Santa Cruz': 3450,
    'Barrio Tepetitlán Emilio Portes Gil': 3450,
    'Barrio Tlatenco': 3450,
    'Bejucos': 3450,
    'Belem': 3450,
    'Benito Juárez': 3450,
    'Bobashi de Guadalupe': 3450,
    'Boshindó': 3450,
    'Bosques de la Magdalena': 3450,
    'Buenavista': 3450,
    'Buenos Aires': 3450,
    'Cabecera de Indígenas': 3450,
    'Cacalomacan': 3450,
    'Caja de Agua': 3450,
    'Calimaya': 3450,
    'Calixtlahuaca': 3450,
    'Calvario Buenavista': 3450,
    'Calvario del Carmen': 3450,
    'Cañada de Cisneros': 3450,
    'Cañada de Guadarrama': 3450,
    'Canalejas': 3450,
    'Capula': 3450,
    'Capulhuac de Mirafuentes': 3450,
    'Carapán': 3450,
    'Casa Nueva': 3450,
    'Casas Viejas': 3450,
    'Caserío de Cortés': 3450,
    'Cedro de la Manzana': 3450,
    'Celayita': 3450,
    'Cerritos del Pilar': 3450,
    'Cerro de San Francisco': 3450,
    'Cerro del Murciélago': 3450,
    'Cerro La Calera': 3450,
    'Chalchihuapan': 3450,
    'Chalco': 3450,
    'Chalma': 3450,
    'Chalmita': 3450,
    'Chapa de Mota': 3450,
    'Chaparaco': 3450,
    'Chapultepec': 3450,
    'Chiautla': 3450,
    'Chicoloapan': 3450,
    'Chiconcuac': 3450,
    'Chilchota': 3450,
    'Chiltepec de Hidalgo': 3450,
    'Chimalpa Viejo': 3450,
    'Chosto de los Jarros': 3450,
    'Ciudad López Mateos': 3450,
    'Ciudad Nezahualcoyotl': 3450,
    'Coacalco': 3450,
    'Coamilpa de Juárez': 3450,
    'Coatepec': 3450,
    'Coatepec Harinas': 3450,
    'Cocotitlán': 3450,
    'Colonia 2 de Septiembre': 3450,
    'Colonia 3 de Mayo': 3450,
    'Colonia 3 de Mayo (La Cruz)': 3450,
    'Colonia Adolfo López Mateos': 3450,
    'Colonia Agrícola Álvaro Obregón': 3450,
    'Colonia Agrícola Analco': 3450,
    'Colonia Álvaro Obregón': 3450,
    'Colonia Arboledas (San Andrés)': 3450,
    'Colonia Aviación Autopan': 3450,
    'Colonia Azteca': 3450,
    'Colonia Bellavista': 3450,
    'Colonia Cuauhtémoc': 3450,
    'Colonia Doctor Gustavo Baz': 3450,
    'Colonia el Mirador': 3450,
    'Colonia el Pirame': 3450,
    'Colonia el Refugio': 3450,
    'Colonia el Salado': 3450,
    'Colonia Emiliano Zapata': 3450,
    'Colonia Emiliano Zapata Ejido de Tenancingo': 3450,
    'Colonia Guadalupe': 3450,
    'Colonia Guadalupe Victoria': 3450,
    'Colonia Guadalupe Victoria (La Capilla)': 3450,
    'Colonia Gustavo Baz Prada': 3450,
    'Colonia Isidro Fabela': 3450,
    'Colonia Juárez': 3450,
    'Colonia la Asunción': 3450,
    'Colonia la Libertad': 3450,
    'Colonia Lázaro Cárdenas': 3450,
    'Colonia Lázaro Cárdenas (Los Hornos)': 3450,
    'Colonia Lindavista': 3450,
    'Colonia los Aguiluchos': 3450,
    'Colonia los Cedros': 3450,
    'Colonia los Remedios': 3450,
    'Colonia Morelos': 3450,
    'Colonia Palma': 3450,
    'Colonia Reforma Tlalmimilolpan': 3450,
    'Colonia Ricardo Flores Magón': 3450,
    'Colonia Rincón Villa del Valle': 3450,
    'Colonia Río Lerma (Tic Ti)': 3450,
    'Colonia San Francisco de Asís': 3450,
    'Colonia San Ramón': 3450,
    'Colonia Tulteca Teopan': 3450,
    'Colonia Wenceslao Labra': 3450,
    'Colorines': 3450,
    'Concepción Caro': 3450,
    'Concepción del Monte': 3450,
    'Concepción Jolalpan': 3450,
    'Concepción la Venta': 3450,
    'Conejeras': 3450,
    'Conjunto Habitacional Ecológico SUTEYM': 3450,
    'Conjunto Urbano la Loma I': 3450,
    'Contepec': 3450,
    'Coscomate del Progreso': 3450,
    'Coyotepec': 3450,
    'Cozotlán Norte': 3450,
    'Cuadrilla de Dolores': 3450,
    'Cuadrilla Vieja': 3450,
    'Cuautitlán': 3450,
    'Cuautlacingo': 3450,
    'Cuecuecuatitla': 3450,
    'Cuendo': 3450,
    'Denjhi': 3450,
    'Detiña (San Antonio Detiña)': 3450,
    'Dios Padre': 3450,
    'Dolores': 3450,
    'Dolores Hidalgo': 3450,
    'Dongu': 3450,
    'Dotegiare': 3450,
    'Doxhicho': 3450,
    'Doxteje Barrio Primero': 3450,
    'Doxteje Centro': 3450,
    'Ecatepec de Morelos': 3450,
    'Ecatzingo de Hidalgo': 3450,
    'Ejido de Coscomate del Progreso': 3450,
    'Ejido de Dolores': 3450,
    'Ejido de Guadalupe': 3450,
    'Ejido de la Finca': 3450,
    'Ejido de Mozoquilpan': 3450,
    'Ejido de San Mateo Coapexco': 3450,
    'Ejido del Tejocote': 3450,
    'Ejido del Tunal Nenaxi': 3450,
    'Ejido el Castillo': 3450,
    'Ejido la Soledad': 3450,
    'Ejido Loma de Malacota': 3450,
    'Ejido Miraflores': 3450,
    'Ejido Palma (Ejido San Francisco)': 3450,
    'Ejido San Cristóbal': 3450,
    'Ejido San Diego': 3450,
    'Ejido San Lorenzo Cuauhtenco': 3450,
    'El Águila (La Mesa)': 3450,
    'El Arco': 3450,
    'El Cabi': 3450,
    'El Carmen (El Desierto del Carmen)': 3450,
    'El Carmen Ocotepec': 3450,
    'El Cerrillo Vista Hermosa': 3450,
    'El Cerrito': 3450,
    'El Colorado': 3450,
    'El Coloso': 3450,
    'El Contadero de Matamoros': 3450,
    'El Cóporo': 3450,
    'El Curtidor': 3450,
    'El Esclavo': 3450,
    'El Espinal': 3450,
    'El Habillal': 3450,
    'El Hielo': 3450,
    'El Jacal': 3450,
    'El Llano del Compromiso': 3450,
    'El Llano Santa María': 3450,
    'El Magueyal': 3450,
    'El Mogote': 3450,
    'El Obraje': 3450,
    'El Ocotal': 3450,
    'El Oro de Hidalgo': 3450,
    'El Palacio': 3450,
    'El Palmito': 3450,
    'El Pedregal de Guadalupe Hidalgo': 3450,
    'El Pilar': 3450,
    'El Pino': 3450,
    'El Plan': 3450,
    'El Porvenir I': 3450,
    'El Potrero': 3450,
    'El Potrero de San Diego': 3450,
    'El Progreso Hidalgo': 3450,
    'El Puerto Magú': 3450,
    'El Recodo de San José Axalco': 3450,
    'El Rincón de la Candelaria': 3450,
    'El Rincón de los Perales': 3450,
    'El Rosal': 3450,
    'El Salitre': 3450,
    'El Tepetatal': 3450,
    'El Terrero': 3450,
    'Emiliano Zapata': 3450,
    'Emiliano Zapata (San José Bata)': 3450,
    'Emiliano Zapata (Santo Domingo)': 3450,
    'Emilio Portes Gil': 3450,
    'Epitacio Huerta': 3450,
    'Etúcuaro': 3450,
    'Ex-Hacienda de Guadalupe': 3450,
    'Ex-hacienda de Xalpa': 3450,
    'Ex-Hacienda Santa Inés': 3450,
    'Ex-Rancho San Dimas': 3450,
    'Fábrica Concepción': 3450,
    'Fracción San Roque (El Prieto)': 3450,
    'Fraccionamiento Colinas del Sol': 3450,
    'Fraccionamiento Hacienda del Bosque': 3450,
    'Fraccionamiento la Trinidad': 3450,
    'Fraccionamiento Real de San Pablo': 3450,
    'Fraccionamiento Rinconada del Valle': 3450,
    'Fraccionamiento y Club de Golf los Encinos': 3450,
    'Fresno Nichi': 3450,
    'Fuentes del Valle': 3450,
    'Galaxias Toluca': 3450,
    'Galeana': 3450,
    'Ganzda': 3450,
    'Granjas Ampliación Santa Rosa': 3450,
    'Guadalupe Buenavista': 3450,
    'Guadalupe Cachi': 3450,
    'Guadalupe Coté': 3450,
    'Guadalupe Totoltepec': 3450,
    'Gunyo Poniente (San José Gunyo)': 3450,
    'Huancito': 3450,
    'Huehuetoca': 3450,
    'Huemetla': 3450,
    'Hueypoxtla': 3450,
    'Huilango': 3450,
    'Huitzoltepec': 3450,
    'Huixquilucan': 3450,
    'Huixquilucan de Degollado': 3450,
    'Ignacio Allende': 3450,
    'Ixtapaluca': 3450,
    'Ixtapan de la Sal': 3450,
    'Ixtapan del Oro': 3450,
    'Ixtlahuaca de Cuauhtémoc': 3450,
    'Ixtlahuaca de Rayón': 3450,
    'Jacona de Plancarte': 3450,
    'Jalmolonga (La Hacienda)': 3450,
    'Jalpa de los Baños': 3450,
    'Jaltenco': 3450,
    'Janambo': 3450,
    'Janamuato': 3450,
    'Jesús Carranza (Rancho de Jesús)': 3450,
    'Jesús del Monte': 3450,
    'Jesús María': 3450,
    'Jicaltepec Autopan': 3450,
    'Jicaltepec Cuexcontitlán': 3450,
    'Jilotepec de Molina Enríquez': 3450,
    'Jilotzingo': 3450,
    'Jiquipilco': 3450,
    'Jocotitlán': 3450,
    'Joquicingo': 3450,
    'Jorge Jiménez Cantú': 3450,
    'Jorobas': 3450,
    'Juchitepec': 3450,
    'La Albarrada (San Francisco la Albarrada)': 3450,
    'La Cabecera': 3450,
    'La Cabecera Concepción': 3450,
    'La Calle': 3450,
    'La Campanilla': 3450,
    'La Cañada': 3450,
    'La Candelaria Tlapala': 3450,
    'La Cantera': 3450,
    'La Caridad': 3450,
    'La Ciénega': 3450,
    'La Colonia': 3450,
    'La Compañía': 3450,
    'La Comunidad': 3450,
    'La Concepción': 3450,
    'La Concepción Coatipac (La Conchita)': 3450,
    'La Concepción de Hidalgo': 3450,
    'La Concepción de los Baños': 3450,
    'La Concepción Enyege': 3450,
    'La Concepción Xochicuautla': 3450,
    'La Constitución Toltepec': 3450,
    'La Cruz y Carrizal': 3450,
    'La Esperanza': 3450,
    'La Estancia Sector Uno (La Estancia)': 3450,
    'La Finca': 3450,
    'La Glorieta': 3450,
    'La Guadalupana': 3450,
    'La Herradura': 3450,
    'La Joya': 3450,
    'La Loma': 3450,
    'La Loma Cuexcontitlán': 3450,
    'La Magdalena': 3450,
    'La Magdalena Chichicaspa': 3450,
    'La Magdalena de los Reyes (La Magdalena)': 3450,
    'La Magdalena Tenexpan': 3450,
    'La Mesa': 3450,
    'La Mesa de Chosto': 3450,
    'La Pastoría': 3450,
    'La Planada': 3450,
    'La Planada (El Arenal)': 3450,
    'La Presita Segundo Cuartel': 3450,
    'La Providencia': 3450,
    'La Puerta del Pilar': 3450,
    'La Purificación': 3450,
    'La Rosa': 3450,
    'La Soledad Barrio': 3450,
    'La Trinidad': 3450,
    'La Y': 3450,
    'Laguna de Tabernillas (El Resbaloso)': 3450,
    'Las Huertas': 3450,
    'Las Manzanas': 3450,
    'Las Peñas': 3450,
    'Las Pintas': 3450,
    'Las Ranas': 3450,
    'Las Rosas': 3450,
    'Lerma de Villada': 3450,
    'Llano de la Y': 3450,
    'Llano de las Flores (Barrio del Hueso)': 3450,
    'Llano de Zacapexco': 3450,
    'Llano Grande': 3450,
    'Loma Alta': 3450,
    'Loma Alta Taxhimay': 3450,
    'Loma Bonita': 3450,
    'Loma de Guadalupe (La Biznaga)': 3450,
    'Loma de Juárez': 3450,
    'Loma de San Francisco': 3450,
    'Loma de San José': 3450,
    'Loma de San Miguel': 3450,
    'Loma del Río': 3450,
    'Loma Larga': 3450,
    'Loma Larga (Barrio de Loma Larga)': 3450,
    'Lomas de Altavista': 3450,
    'Lomas de San Pablo': 3450,
    'Lomas de San Sebastián': 3450,
    'Lomas de Tenopalco': 3450,
    'Los Arana': 3450,
    'Los Berros': 3450,
    'Los Hucuares': 3450,
    'Los Nogales': 3450,
    'Los Remedios': 3450,
    'Los Reyes': 3450,
    'Los Reyes Acaquilpan': 3450,
    'Los Reyes Acatlixhuayán': 3450,
    'Los Saucos': 3450,
    'Luvianos': 3450,
    'Magdalena Atlicpac': 3450,
    'Malinalco': 3450,
    'Manto del Río Ejido': 3450,
    'Manzana Quinta (La Cañada)': 3450,
    'Manzana Segunda': 3450,
    'Manzana Sexta Parte Centro': 3450,
    'Manzana Tercera de Santa Cruz Tepexpan': 3450,
    'Mavoro': 3450,
    'Maxtleca de Galeana': 3450,
    'Mayorazgo de León': 3450,
    'Melchor Ocampo': 3450,
    'Metepec': 3450,
    'Mexicaltzingo': 3450,
    'Mextepec': 3450,
    'Mezapa la Fábrica': 3450,
    'Mina México': 3450,
    'Mina Vieja': 3450,
    'Miraflores': 3450,
    'Molino Abajo': 3450,
    'Monte Calvario': 3450,
    'Montecillo': 3450,
    'Morelos': 3450,
    'Naucalpan de Juárez': 3450,
    'Nepantla de Sor Juana Inés de la Cruz': 3450,
    'Nicolás Romero': 3450,
    'Nopaltepec': 3450,
    'Nueva Ameyalco': 3450,
    'Nueva Santa Rosa': 3450,
    'Ocopulco': 3450,
    'Ocoyoacac': 3450,
    'Octeyuco Dos Mil': 3450,
    'Ocuilan': 3450,
    'Ocuilan de Arteaga': 3450,
    'Ocumicho': 3450,
    'Ojo de Agua': 3450,
    'Otumba': 3450,
    'Otzoloapan': 3450,
    'Otzolotepec': 3450,
    'Oxtotipac': 3450,
    'Ozumba de Alzate': 3450,
    'Palizada': 3450,
    'Palmar Chico': 3450,
    'Palmillas': 3450,
    'Palos Amarillos (Palos Amarillos Yebuciví)': 3450,
    'Papalotla': 3450,
    'Paraje el Mirador': 3450,
    'Paraje la Pera': 3450,
    'Paraje Trejo (El Chaparral)': 3450,
    'Paseos de San Juan': 3450,
    'Pathé': 3450,
    'Playa Azul': 3450,
    'Plaza Nueva': 3450,
    'Polígonos': 3450,
    'Polotitlán': 3450,
    'Polotitlán de la Ilustración': 3450,
    'Polvillos (San Bartolo Quinta Sección)': 3450,
    'Popo Park': 3450,
    'Porfirío Díaz': 3450,
    'Pothé': 3450,
    'Potreros': 3450,
    'Prados de San Juan': 3450,
    'Prados San Francisco': 3450,
    'Primera de Analco': 3450,
    'Profesor Carlos Hank González': 3450,
    'Progreso Industrial': 3450,
    'Pueblo Nuevo': 3450,
    'Pueblo Nuevo Tlalmimilolpan': 3450,
    'Puentecillas': 3450,
    'Puerto Escondido (Tepeolulco Puerto Escondido)': 3450,
    'Purísima Concepción Mayorazgo': 3450,
    'Quetzalapa': 3450,
    'Quinto Barrio (Ejido Cahuacán)': 3450,
    'Rancho Alegre': 3450,
    'Rincón de Aguirre': 3450,
    'Rincón de Guadalupe': 3450,
    'Rincón de Jaimes': 3450,
    'Rincón de los Pirules': 3450,
    'Rincón Verde': 3450,
    'Río Frío de Juárez': 3450,
    'Salitrillo': 3450,
    'San Agustín': 3450,
    'San Agustín Berros': 3450,
    'San Agustín Buenavista': 3450,
    'San Agustín Citlali': 3450,
    'San Agustín de las Palmas': 3450,
    'San Agustín Huitzizilapan': 3450,
    'San Agustín Mextepec': 3450,
    'San Agustín Mimbres': 3450,
    'San Alejo': 3450,
    'San Andrés de las Peras': 3450,
    'San Andrés de los Gama': 3450,
    'San Andrés del Pedregal': 3450,
    'San Andrés Metla': 3450,
    'San Andrés Nicolás Bravo': 3450,
    'San Andrés Ocotepec': 3450,
    'San Andrés Tepetitlán': 3450,
    'San Andrés Timilpan': 3450,
    'San Andrés Tlalamac': 3450,
    'San Antonio Acahualco': 3450,
    'San Antonio Bonixi': 3450,
    'San Antonio Buenavista': 3450,
    'San Antonio de la Laguna': 3450,
    'San Antonio de las Huertas': 3450,
    'San Antonio de las Palmas': 3450,
    'San Antonio del Rosario': 3450,
    'San Antonio el Llanito': 3450,
    'San Antonio Enchisi': 3450,
    'San Antonio Guaracha': 3450,
    'San Antonio la Isla': 3450,
    'San Antonio Nixini': 3450,
    'San Antonio Solís': 3450,
    'San Antonio Tlaltecahuacán': 3450,
    'San Antonio Xahuento': 3450,
    'San Bartolito Tlaltelolco': 3450,
    'San Bartolo': 3450,
    'San Bartolo Cuautlalpan': 3450,
    'San Bartolo del Llano': 3450,
    'San Bartolo del Llano (San Isidro)': 3450,
    'San Bartolo del Progreso': 3450,
    'San Bartolo el Viejo': 3450,
    'San Bartolo Lanzados': 3450,
    'San Bartolo Morelos': 3450,
    'San Bartolo Oxtotitlán': 3450,
    'San Bartolomé': 3450,
    'San Bartolomé Atlatlahuca': 3450,
    'San Bartolomé Coatepec': 3450,
    'San Bernardino': 3450,
    'San Bernardo Tlalmimilolpan': 3450,
    'San Buenaventura': 3450,
    'San Carlos Autopan': 3450,
    'San Cristóbal Nexquipayac': 3450,
    'San Diego': 3450,
    'San Diego del Cerrito': 3450,
    'San Diego Huehuecalco': 3450,
    'San Diego la Huerta': 3450,
    'San Diego los Padres Cuexcontitlán Sección 5 B': 3450,
    'San Felipe Coamango': 3450,
    'San Felipe del Progreso': 3450,
    'San Felipe el Mirasol': 3450,
    'San Felipe Pueblo Nuevo': 3450,
    'San Felipe Santiago': 3450,
    'San Felipe Tlalmimilolpan': 3450,
    'San Francisco': 3450,
    'San Francisco Acuautla': 3450,
    'San Francisco Ayotuzco': 3450,
    'San Francisco Chalchihuapan': 3450,
    'San Francisco Chejé': 3450,
    'San Francisco Cuaxusco': 3450,
    'San Francisco de Guzmán': 3450,
    'San Francisco Magú': 3450,
    'San Francisco Mazapa': 3450,
    'San Francisco Mihualtepec': 3450,
    'San Francisco Oxtotilpan': 3450,
    'San Francisco Putla': 3450,
    'San Francisco Solis': 3450,
    'San Francisco Tenopalco': 3450,
    'San Francisco Tepeolulco': 3450,
    'San Francisco Tepexoxica': 3450,
    'San Francisco Tlalcilalcalpan': 3450,
    'San Francisco Tlaltica': 3450,
    'San Francisco Xochicuautla': 3450,
    'San Gabriel Ixtla': 3450,
    'San Gabriel Zepayautla': 3450,
    'San Gaspar': 3450,
    'San Gaspar Tonatico': 3450,
    'San Gregorio Cuautzingo': 3450,
    'San Ildefonso': 3450,
    'San Isidro': 3450,
    'San Isidro (El Reservado)': 3450,
    'San Isidro Boxipe': 3450,
    'San Jacinto': 3450,
    'San Jerónimo': 3450,
    'San Jerónimo Amanalco': 3450,
    'San Jerónimo Bonchete': 3450,
    'San Jerónimo Cuatro Vientos': 3450,
    'San Jerónimo de los Jarros': 3450,
    'San Jerónimo Ixtapantongo': 3450,
    'San Jerónimo Totoltepec': 3450,
    'San Joaquín Coapango': 3450,
    'San Joaquín del Monte': 3450,
    'San Jorge Pueblo Nuevo': 3450,
    'San José Barbabosa': 3450,
    'San José Boqui': 3450,
    'San José Chalmita': 3450,
    'San José del Progreso': 3450,
    'San José del Puente': 3450,
    'San José del Rincón Centro': 3450,
    'San José del Sitio': 3450,
    'San José del Tunal': 3450,
    'San José el Cuartel': 3450,
    'San José el Vidrio': 3450,
    'San José Guadalupe Otzacatipan': 3450,
    'San José las Lomas': 3450,
    'San José las Palmas': 3450,
    'San José Mezapa Sección I': 3450,
    'San José Pathé': 3450,
    'San José Tejamanil': 3450,
    'San José Tenería (Tenería)': 3450,
    'San José Tlacotitlán': 3450,
    'San José Toxi': 3450,
    'San José Villa de Allende': 3450,
    'San Juan': 3450,
    'San Juan Atezcapan': 3450,
    'San Juan Coajomulco': 3450,
    'San Juan Cote Ejido': 3450,
    'San Juan Coxtocan': 3450,
    'San Juan Daxthi': 3450,
    'San Juan de las Huertas': 3450,
    'San Juan de las Manzanas': 3450,
    'San Juan de los Jarros': 3450,
    'San Juan Jalpa Centro': 3450,
    'San Juan Tehuixtitlán': 3450,
    'San Juan Tepecoculco': 3450,
    'San Juan Tlacotompa (Tlacotompa)': 3450,
    'San Juan Tomasquillo Herradura': 3450,
    'San Juan Totolapan': 3450,
    'San Juan Tuxtepec': 3450,
    'San Juan Xoconusco': 3450,
    'San Juan y San Pedro Tezompa': 3450,
    'San Juanico Sector Uno': 3450,
    'San Lorenzo Huehuetitlán': 3450,
    'San Lorenzo Malacota': 3450,
    'San Lorenzo Tlacotepec': 3450,
    'San Lorenzo Tlalmimilolpan': 3450,
    'San Lorenzo Toxico': 3450,
    'San Lucas': 3450,
    'San Lucas Amalinalco': 3450,
    'San Lucas del Pulque': 3450,
    'San Lucas Huitzilhuacán': 3450,
    'San Lucas Ocotepec': 3450,
    'San Lucas Texcaltitlán': 3450,
    'San Lucas Totolmaloya': 3450,
    'San Luis Anáhuac (Toriles)': 3450,
    'San Luis Boro': 3450,
    'San Luis Taxhimay': 3450,
    'San Marcos de la Loma': 3450,
    'San Marcos Huixtoco': 3450,
    'San Marcos Nepantla': 3450,
    'San Marcos Tecomaxusco': 3450,
    'San Marcos Tlazalpan': 3450,
    'San Marcos Yachihuacaltepec': 3450,
    'San Martín': 3450,
    'San Martín Ahuatepec': 3450,
    'San Martín Azcatepec': 3450,
    'San Martín Cachihuapan': 3450,
    'San Martín Coapaxtongo': 3450,
    'San Martín Cuautlalpan': 3450,
    'San Martín de las Pirámides': 3450,
    'San Martín Ejido': 3450,
    'San Martín Obispo (San Martín San Pedro)': 3450,
    'San Martín Tequesquipan (Tequesquipan)': 3450,
    'San Martín Toltepec': 3450,
    'San Martín Tuchicuitlapilco': 3450,
    'San Mateo': 3450,
    'San Mateo Almomoloha': 3450,
    'San Mateo Atenco': 3450,
    'San Mateo Capulhuac': 3450,
    'San Mateo Coapexco': 3450,
    'San Mateo el Viejo': 3450,
    'San Mateo Huitzilzingo': 3450,
    'San Mateo Ixtacalco': 3450,
    'San Mateo Ixtlahuaca': 3450,
    'San Mateo Mozoquilpan': 3450,
    'San Mateo Tecalco': 3450,
    'San Mateo Texcalyacac': 3450,
    'San Mateo Tlalchichilpan': 3450,
    'San Mateo Xoloc': 3450,
    'San Matías Cuijingo': 3450,
    'San Miguel': 3450,
    'San Miguel Agua Bendita': 3450,
    'San Miguel Almoloyan': 3450,
    'San Miguel Atlamajac': 3450,
    'San Miguel Coatlinchán': 3450,
    'San Miguel del Centro': 3450,
    'San Miguel Enyege': 3450,
    'San Miguel Hila': 3450,
    'San Miguel Ixtapan': 3450,
    'San Miguel Jaltocan': 3450,
    'San Miguel Laderas': 3450,
    'San Miguel Mimlapan': 3450,
    'San Miguel Oxtotilpan': 3450,
    'San Miguel Tecomatlán': 3450,
    'San Miguel Tecpan': 3450,
    'San Miguel Tenochtitlán': 3450,
    'San Miguel Tlaixpan': 3450,
    'San Miguel Totocuitlapilco': 3450,
    'San Miguel Xoltepec': 3450,
    'San Miguel Yuxtepec': 3450,
    'San Nicolás': 3450,
    'San Nicolás Amealco': 3450,
    'San Nicolás el Oro': 3450,
    'San Nicolás Guadalupe': 3450,
    'San Nicolás Tolentino': 3450,
    'San Pablo Atlazalpan': 3450,
    'San Pablo Atotonilco': 3450,
    'San Pablo de las Salinas': 3450,
    'San Pablo de los Gallos': 3450,
    'San Pablo de los Remedios': 3450,
    'San Pablo Huantepec': 3450,
    'San Pablo Ixayoc': 3450,
    'San Pablo Ixquitlán': 3450,
    'San Pablo Tecalco': 3450,
    'San Pablo Tejalpa': 3450,
    'San Pedro Abajo': 3450,
    'San Pedro Arriba': 3450,
    'San Pedro Arriba 3ra. Sección': 3450,
    'San Pedro Chiautzingo': 3450,
    'San Pedro Cholula': 3450,
    'San Pedro de la Hortaliza (Ejido Almoloyán)': 3450,
    'San Pedro de los Baños': 3450,
    'San Pedro de los Metates': 3450,
    'San Pedro del Rincón': 3450,
    'San Pedro del Rosal': 3450,
    'San Pedro Denxhi Centro': 3450,
    'San Pedro el Alto': 3450,
    'San Pedro Huitzizilapan': 3450,
    'San Pedro Limón': 3450,
    'San Pedro Los Baños': 3450,
    'San Pedro Nexapa': 3450,
    'San Pedro Tarímbaro': 3450,
    'San Pedro Tejalpa': 3450,
    'San Pedro Tenayac': 3450,
    'San Pedro Tepetitlán': 3450,
    'San Rafael': 3450,
    'San Roque': 3450,
    'San Salvador Atenco': 3450,
    'San Salvador Tizatlalli': 3450,
    'San Sebastián': 3450,
    'San Sebastián Buenos Aires': 3450,
    'San Sebastián Chimalpa': 3450,
    'San Sebastián Xolalpa': 3450,
    'San Simón de Guerrero': 3450,
    'San Simón de la Laguna': 3450,
    'San Simón el Alto': 3450,
    'San Simonito': 3450,
    'San Vicente (Calpulalpan Tercera Manzana)': 3450,
    'San Vicente Chimalhuacán': 3450,
    'Sanata Lucía': 3450,
    'Santa Ana': 3450,
    'Santa Ana Ixtlahuaca (Santa Ana Ixtlahuacingo)': 3450,
    'Santa Ana Ixtlahuatzingo (Santa Ana)': 3450,
    'Santa Ana Jilotzingo': 3450,
    'Santa Ana la Ladera': 3450,
    'Santa Ana Mayorazgo': 3450,
    'Santa Ana Nextlalpan': 3450,
    'Santa Ana Nichi Ejido': 3450,
    'Santa Ana Zicatecoyan': 3450,
    'Santa Catarina': 3450,
    'Santa Catarina Ayotzingo': 3450,
    'Santa Catarina del Monte': 3450,
    'Santa Clara de Juárez': 3450,
    'Santa Cruz': 3450,
    'Santa Cruz Atizapán': 3450,
    'Santa Cruz Bombatevi': 3450,
    'Santa Cruz Cuauhtenco': 3450,
    'Santa Cruz del Monte': 3450,
    'Santa Cruz Huitzizilapan': 3450,
    'Santa Cruz Mextepec': 3450,
    'Santa Cruz Pueblo Nuevo (Pueblo Nuevo)': 3450,
    'Santa Gertrudis': 3450,
    'Santa Isabel Chalma': 3450,
    'Santa Isabel Ixtapan': 3450,
    'Santa Juana Centro': 3450,
    'Santa Juana Primera Sección': 3450,
    'Santa Lucía': 3450,
    'Santa María': 3450,
    'Santa María Ajoloapan': 3450,
    'Santa María Aranzazú (Santa María)': 3450,
    'Santa María Canchesdá': 3450,
    'Santa María Chimalhuacán': 3450,
    'Santa María Citendejé': 3450,
    'Santa María Cuevas': 3450,
    'Santa María de Guadalupe': 3450,
    'Santa María del Llano': 3450,
    'Santa María del Monte': 3450,
    'Santa María Endare': 3450,
    'Santa María Huecatitla': 3450,
    'Santa María Huexoculco': 3450,
    'Santa María Jajalpa': 3450,
    'Santa María la Asunción': 3450,
    'Santa María Magdalena Cahuacán': 3450,
    'Santa María Mazatla': 3450,
    'Santa María Nativitas': 3450,
    'Santa María Palapa': 3450,
    'Santa María Pipioltepec (Pipioltepec)': 3450,
    'Santa María Rayón': 3450,
    'Santa María Tecuanulco': 3450,
    'Santa María Tlalmimilolpan': 3450,
    'Santa María Totoltepec': 3450,
    'Santa María Zolotepec': 3450,
    'Santa Martha': 3450,
    'Santa Mónica': 3450,
    'Santa Rosa de Lima': 3450,
    'Santa Teresa': 3450,
    'Santiago Acutzilapan': 3450,
    'Santiago Casandeje': 3450,
    'Santiago Chimalpa (Chimalpa)': 3450,
    'Santiago Citendejé': 3450,
    'Santiago Coachochitlan': 3450,
    'Santiago Cuautenco': 3450,
    'Santiago Cuautlalpan': 3450,
    'Santiago del Monte': 3450,
    'Santiago Huitlapaltepec': 3450,
    'Santiago Mamalhuazuca': 3450,
    'Santiago Oxtempan': 3450,
    'Santiago Oxtotitlán': 3450,
    'Santiago Tejocotillos': 3450,
    'Santiago Tepetitlán': 3450,
    'Santiago Tepopula': 3450,
    'Santiago Teyahualco': 3450,
    'Santiago Tianguistenco de Galeana': 3450,
    'Santiago Tlacotepec': 3450,
    'Santiago Tolman': 3450,
    'Santiago Yancuitlalpan': 3450,
    'Santiago Yeché': 3450,
    'Santiago Zacualuca': 3450,
    'Santiaguito Tlalcilalcalli': 3450,
    'Santo Domingo de Guzmán': 3450,
    'Santo Domingo Shomege (Shomege)': 3450,
    'Santo Tomás': 3450,
    'Santo Tomás Atzingo': 3450,
    'Santo Tomás Chiconautla': 3450,
    'Santo Tomás de los Plátanos': 3450,
    'Sebastián Lerdo de Tejada': 3450,
    'Sección del Cerrito': 3450,
    'Sierra de Guadalupe': 3450,
    'Sitio Ejido': 3450,
    'Solalpan 1ra. Sección': 3450,
    'Soyaniquilpan': 3450,
    'Sultepec': 3450,
    'Tamándaro': 3450,
    'Tanaquillo': 3450,
    'Tangancícuaro de Arista': 3450,
    'Tarécuato': 3450,
    'Tecámac': 3450,
    'Tecámac de Felipe Villanueva': 3450,
    'Tecamachalco': 3450,
    'Tecaxic': 3450,
    'Techachaltitla': 3450,
    'Techichili': 3450,
    'Tecoac (Santa María Nativitas)': 3450,
    'Tecomatepec': 3450,
    'Tejalpa': 3450,
    'Tejupilco': 3450,
    'Tejupilco de Hidalgo': 3450,
    'Temamatla': 3450,
    'Temascalapa': 3450,
    'Temascalcingo': 3450,
    'Temascaltepec': 3450,
    'Temascaltepec de González': 3450,
    'Temoaya': 3450,
    'Tenancingo': 3450,
    'Tenancingo de Degollado': 3450,
    'Tenango de Arista': 3450,
    'Tenango del Aire': 3450,
    'Tenería': 3450,
    'Tengüecho': 3450,
    'Tenjay': 3450,
    'Teoloyucan': 3450,
    'Teotihuacán': 3450,
    'Teotihuacán de Arista': 3450,
    'Tepalcatepec': 3450,
    'Tepetitlán': 3450,
    'Tepetlaoxtoc': 3450,
    'Tepetlaoxtoc de Hidalgo': 3450,
    'Tepetlixpa': 3450,
    'Tepetongo': 3450,
    'Tepetzingo': 3450,
    'Tepexpan': 3450,
    'Tepotzotlán': 3450,
    'Tepoxtepec': 3450,
    'Tequexquináhuac': 3450,
    'Tequisistlán': 3450,
    'Tequisistlán Primero': 3450,
    'Tequixquiac': 3450,
    'Tercera Manzana de Zaragoza': 3450,
    'Teremendo': 3450,
    'Texcaltitlán': 3450,
    'Texcalyacac': 3450,
    'Texcapilla': 3450,
    'Texcoco de Mora': 3450,
    'Tezoyuca': 3450,
    'Tierra Blanca': 3450,
    'Timilpan': 3450,
    'Tixmadeje Chiquito': 3450,
    'Tlacomulco': 3450,
    'Tlalmanalco': 3450,
    'Tlalnepantla': 3450,
    'Tlalnepantla de Baz': 3450,
    'Tlaltecahuacán': 3450,
    'Tlaminca': 3450,
    'Tlatlaya': 3450,
    'Tlazala de Fabela': 3450,
    'Tlazazalca': 3450,
    'Toluca': 3450,
    'Tonanitla': 3450,
    'Tonatico': 3450,
    'Totolmajac': 3450,
    'Transfiguración': 3450,
    'Tres Estrellas': 3450,
    'Tres Mezquites': 3450,
    'Tulantongo': 3450,
    'Tultepec': 3450,
    'Tultitlán de Mariano Escobedo': 3450,
    'Tupátaro': 3450,
    'Turcio Segunda Sección': 3450,
    'Unidad Acaquilpan': 3450,
    'Unidad Habitacional Santa Teresa': 3450,
    'Unidad San Miguel Jagüeyes': 3450,
    'Urén': 3450,
    'Valle de Bravo': 3450,
    'Vare Chiquichuca': 3450,
    'Veintidós de Febrero': 3450,
    'Venta de Bravo': 3450,
    'Venta de Ocotillos': 3450,
    'Villa Cuauhtémoc': 3450,
    'Villa de Almoloya de Juárez': 3450,
    'Villa del Carbón': 3450,
    'Villa Guerrero': 3450,
    'Villa Luvianos': 3450,
    'Villa Morelos': 3450,
    'Villa URBI del Rey': 3450,
    'Villa Victoria': 3450,
    'Villachuato': 3450,
    'Villas de la Laguna': 3450,
    'Villas del Campo': 3450,
    'Vista Hermosa': 3450,
    'Xalatlaco': 3450,
    'Xhimojay': 3450,
    'Xhitey': 3450,
    'Xhixhata': 3450,
    'Xico': 3450,
    'Xochimanca': 3450,
    'Xocotlán': 3450,
    'Xometla': 3450,
    'Xonacatlan': 3450,
    'Zacamulpa': 3450,
    'Zacamulpa Tlalmimilolpan': 3450,
    'Zacango': 3450,
    'Zacazonapan': 3450,
    'Zacualpan': 3450,
    'Zapotlán': 3450,
    'Zentlalpan': 3450,
    'Zinacantepec': 3450,
    'Zipiajo': 3450,
    'Zopoco': 3450,
    'Zoquiapan': 3450,
    'Zoyatzingo': 3450,
    'Zula': 3450,
    'Zumpahuacán': 3450,
    'Zumpango': 3450,
    'Álvaro Obregón': 3473,
    'Azcapotzalco': 3473,
    'Benito Juarez': 3473,
    'Col. Bosques de las Lomas': 3473,
    'Colonia del Valle': 3473,
    'Colonia Nativitas': 3473,
    'Coyoacán': 3473,
    'Cuauhtémoc': 3473,
    'Delegación Cuajimalpa de Morelos': 3473,
    'Gustavo A. Madero': 3473,
    'Gustavo Adolfo Madero': 3473,
    'Iztacalco': 3473,
    'Iztapalapa': 3473,
    'Lomas de Tepemecatl': 3473,
    'Magdalena Contreras': 3473,
    'Mexico City': 3473,
    'Miguel Hidalgo': 3473,
    'Milpa Alta': 3473,
    'Polanco': 3473,
    'San Andrés Mixquic': 3473,
    'San Antonio Tecómitl': 3473,
    'San Bartolome Xicomulco': 3473,
    'San Francisco Tecoxpa': 3473,
    'San Juan Ixtayopan': 3473,
    'San Lorenzo Acopilco': 3473,
    'San Lorenzo Tlacoyucan': 3473,
    'San Miguel Ajusco': 3473,
    'San Miguel Topilejo': 3473,
    'San Pablo Oztotepec': 3473,
    'San Pedro Atocpan': 3473,
    'San Salvador Cuauhtenco': 3473,
    'Santa Ana Tlacotenco': 3473,
    'Santa Catarina Yecahuizotl': 3473,
    'Tetelco': 3473,
    'Tláhuac': 3473,
    'Tlalpan': 3473,
    'Venustiano Carranza': 3473,
    'Xochimilco': 3473,
    'Acuitzio': 3474,
    'Acuítzio del Canje': 3474,
    'Agostitlán': 3474,
    'Agua Caliente': 3474,
    'Aguililla': 3474,
    'Ahuirán': 3474,
    'Ajuno': 3474,
    'Álvaro Obregón': 3474,
    'Angahuán': 3474,
    'Angamacutiro de la Unión': 3474,
    'Ansihuacuaro': 3474,
    'Antúnez': 3474,
    'Antúnez (Morelos)': 3474,
    'Apatzingán': 3474,
    'Apeo': 3474,
    'Apo': 3474,
    'Aporo': 3474,
    'Aputzio de Juárez': 3474,
    'Aquila': 3474,
    'Aquiles Córdova Morán': 3474,
    'Aquiles Serdán': 3474,
    'Arantepacua': 3474,
    'Aranza': 3474,
    'Araró': 3474,
    'Ario de Rayón': 3474,
    'Ario de Rosales': 3474,
    'Arteaga': 3474,
    'Atacheo de Regalado': 3474,
    'Atapán': 3474,
    'Atapaneo': 3474,
    'Atecucario de la Constitución (Atecuario)': 3474,
    'Atotonilco': 3474,
    'Belisario Domínguez': 3474,
    'Bellas Fuentes': 3474,
    'Benito Juárez': 3474,
    'Bocaneo (San Pedro)': 3474,
    'Bonifacio Moreno (El Aguaje)': 3474,
    'Briseñas': 3474,
    'Briseñas de Matamoros': 3474,
    'Buenavista': 3474,
    'Buenavista de Benito Juárez': 3474,
    'Buenavista Tomatlán': 3474,
    'Buenos Aires': 3474,
    'Caltzontzín': 3474,
    'Camémbaro': 3474,
    'Campestre Tarímbaro': 3474,
    'Cañada de Ramírez': 3474,
    'Cantabria': 3474,
    'Capacho': 3474,
    'Capácuaro': 3474,
    'Capula': 3474,
    'Carácuaro': 3474,
    'Carona': 3474,
    'Caurio de Guadalupe': 3474,
    'Cenobio Moreno': 3474,
    'Cerro Colorado de Ocampo': 3474,
    'Chapa': 3474,
    'Charapán': 3474,
    'Charapendo': 3474,
    'Charo': 3474,
    'Chavinda': 3474,
    'Cherán': 3474,
    'Cherán Atzicuirín (Cheranástico)': 3474,
    'Chilchota': 3474,
    'Chiquimitío': 3474,
    'Chitejé de Garabato': 3474,
    'Chucándiro': 3474,
    'Chupio': 3474,
    'Churintzio': 3474,
    'Churumuco de Morelos': 3474,
    'Ciudad Hidalgo': 3474,
    'Ciudad Lázaro Cárdenas': 3474,
    'Coahuayana de Hidalgo': 3474,
    'Coahuayana Viejo': 3474,
    'Coajomulco': 3474,
    'Coalcomán de Vázquez Pallares': 3474,
    'Cocucho': 3474,
    'Coeneo de la Libertad': 3474,
    'Cojumatlán de Régules': 3474,
    'Colonia Antorcha Campesina (Santa Rosa)': 3474,
    'Colonia Aquiles Córdoba Morán': 3474,
    'Colonia de Guadalupe': 3474,
    'Colonia Ecológica Asociación de Lucha Social (Lucha Social)': 3474,
    'Colonia el Mirador (Llano del Ejido)': 3474,
    'Colonia Emiliano Zapata (San Juan Zitácuaro)': 3474,
    'Colonia Independencia': 3474,
    'Colonia las Malvinas (Colonia Antorcha)': 3474,
    'Colonia Miguel Hidalgo': 3474,
    'Colonia Vista Bella (Lomas del Peaje)': 3474,
    'Comachuén': 3474,
    'Comanjá': 3474,
    'Condémbaro': 3474,
    'Conjunto Habitacional el Trébol': 3474,
    'Conjunto Habitacional Villas del Pedregal': 3474,
    'Copándaro (Copándaro del Cuatro)': 3474,
    'Copándaro de Galeana': 3474,
    'Corupo': 3474,
    'Cotija de la Paz': 3474,
    'Crescencio Morales (San Mateo)': 3474,
    'Cuamio': 3474,
    'Cuanajo': 3474,
    'Cuaracurío': 3474,
    'Cuartel la Mesa (El Asoleadero)': 3474,
    'Cuatro Caminos': 3474,
    'Cucuchucho': 3474,
    'Cuitzeo del Porvenir': 3474,
    'Cumuato': 3474,
    'Cuparátaro': 3474,
    'Cupuán del Río': 3474,
    'Curimeo': 3474,
    'Curungueo': 3474,
    'Cuto de la Esperanza': 3474,
    'Cuto del Porvenir': 3474,
    'Dieciocho de Marzo': 3474,
    'División del Norte': 3474,
    'Doctor Miguel Silva (San Guillermo)': 3474,
    'Donaciano Ojeda': 3474,
    'Ecuandureo': 3474,
    'Ejido el Rosario': 3474,
    'El Alvareño': 3474,
    'El Cahulote de Santa Ana': 3474,
    'El Capulín': 3474,
    'El Ceñidor': 3474,
    'El Césped': 3474,
    'El Chauz': 3474,
    'El Colegio': 3474,
    'El Cuenqueño': 3474,
    'El Cuitzillo Grande': 3474,
    'El Durazno': 3474,
    'El Fortín': 3474,
    'El Fuerte': 3474,
    'El Guaco': 3474,
    'El Letrero': 3474,
    'El Maluco': 3474,
    'El Paracho': 3474,
    'El Pedregal': 3474,
    'El Platanal': 3474,
    'El Rincón de San Felipe (Tercera Manzana San Felipe)': 3474,
    'El Sauz de Abajo': 3474,
    'El Tequesquite': 3474,
    'El Tigre (Segunda Manzana de Crescencio Morales)': 3474,
    'Emiliano Zapata': 3474,
    'Erongarícuaro': 3474,
    'Estación Queréndaro': 3474,
    'Etúcuaro': 3474,
    'Ex-Hacienda de Guadalupe': 3474,
    'Felipe Carrillo Puerto': 3474,
    'Fraccionamiento Colinas Universidad': 3474,
    'Fraccionamiento Ex-Hacienda el Refugio': 3474,
    'Fraccionamiento Galaxia Tarímbaro': 3474,
    'Fraccionamiento Laureles Eréndira': 3474,
    'Fraccionamiento Metrópolis II': 3474,
    'Fraccionamiento Misión del Valle': 3474,
    'Fraccionamiento Monte Olivo': 3474,
    'Fraccionamiento Privadas del Sol': 3474,
    'Fraccionamiento San Miguel': 3474,
    'Francisco Sarabia (Cerrito Pelón)': 3474,
    'Francisco Serrato (San Bartolo)': 3474,
    'Francisco Villa': 3474,
    'Gambara': 3474,
    'General Francisco Villa': 3474,
    'Gildardo Magaña (Los Ángeles)': 3474,
    'Guacamayas': 3474,
    'Guándaro': 3474,
    'Héroes de Chapultepec (Rodeo de San Antonio)': 3474,
    'Heróica Zitácuaro': 3474,
    'Huajúmbaro': 3474,
    'Huandacareo': 3474,
    'Huanguitío': 3474,
    'Huaniqueo de Morales': 3474,
    'Huetamo de Núñez': 3474,
    'Huiramba': 3474,
    'Ibarra': 3474,
    'Ichán': 3474,
    'Ihuatzio': 3474,
    'Indaparapeo': 3474,
    'Infiernillo (Morelos de Infiernillo)': 3474,
    'Irapeo': 3474,
    'Iratzio': 3474,
    'Irimbo': 3474,
    'Isaac Arriaga (Santa Ana Mancera)': 3474,
    'Istaro': 3474,
    'Ixtlán de los Hervores': 3474,
    'J. Jesús Díaz Tzirio': 3474,
    'Jacona': 3474,
    'Jamaica': 3474,
    'Janitzio': 3474,
    'Jarácuaro': 3474,
    'Jaripeo': 3474,
    'Jesús del Monte': 3474,
    'Jiquílpan de Juárez': 3474,
    'José María Morelos (La Yegüería)': 3474,
    'Jucutacato': 3474,
    'Jungapeo de Juárez': 3474,
    'La Aldea': 3474,
    'La Angostura': 3474,
    'La Calera (Nacimientos)': 3474,
    'La Encarnación': 3474,
    'La Ermita (Nueva Jerusalén)': 3474,
    'La Esperanza': 3474,
    'La Estancia de Amezcua': 3474,
    'La Fundición (Quinta Manzana)': 3474,
    'La Goleta': 3474,
    'La Higuerilla (Los Lirios)': 3474,
    'La Higuerita (Colonia San Rafael)': 3474,
    'La Huacana': 3474,
    'La Ibérica (La Gotera)': 3474,
    'La Luz': 3474,
    'La Mesa (La Mesa de Cedano)': 3474,
    'La Mintzita (Piedra Dura)': 3474,
    'La Mira': 3474,
    'La Mira Tumbiscatio': 3474,
    'La Mojonera': 3474,
    'La Orilla': 3474,
    'La Palma': 3474,
    'La Palma (Las Palmas)': 3474,
    'La Piedad': 3474,
    'La Placita de Morelos': 3474,
    'La Plaza del Limón': 3474,
    'La Rinconoda': 3474,
    'La Sauceda': 3474,
    'La Soledad': 3474,
    'Lagunillas': 3474,
    'Las Cañadas': 3474,
    'Las Cañas': 3474,
    'Las Cieneguitas': 3474,
    'Las Letras': 3474,
    'Lázaro Cárdenas (La Purísima)': 3474,
    'Loma de los Hoyos': 3474,
    'Lomas de la Maestranza': 3474,
    'Lombardía': 3474,
    'Los Cajones': 3474,
    'Los Charcos': 3474,
    'Los Guajes': 3474,
    'Los Limones': 3474,
    'Los Pilares': 3474,
    'Los Reyes de Salgado': 3474,
    'Macho de Agua': 3474,
    'Macutzio': 3474,
    'Manuel Villalongín': 3474,
    'Manzana de San Luis': 3474,
    'Manzana la Cofradía': 3474,
    'Maravatío de Ocampo': 3474,
    'Mariano Escobedo': 3474,
    'Mesón Nuevo (Cañada de la Magdalena)': 3474,
    'Mineral de Angangueo': 3474,
    'Montaña Monarca (Punta Altozano)': 3474,
    'Monteleón': 3474,
    'Morelia': 3474,
    'Morelos': 3474,
    'Nahuatzén': 3474,
    'Naranja de Tapia': 3474,
    'Nocupétaro': 3474,
    'Nueva Italia de Ruiz': 3474,
    'Nuevo San Juan Parangaricutiro': 3474,
    'Nuevo Urecho': 3474,
    'Nuevo Zirosto': 3474,
    'Numarán': 3474,
    'Nurío': 3474,
    'Ocampo': 3474,
    'Ocurio': 3474,
    'Ojo de Agua de Bucio': 3474,
    'Ojo de Rana': 3474,
    'Opopeo': 3474,
    'Pajacuarán': 3474,
    'Pamatácuaro': 3474,
    'Panindícuaro': 3474,
    'Paracho de Verduzco': 3474,
    'Paracuaro': 3474,
    'Parahuén': 3474,
    'Páreo': 3474,
    'Parícuaro': 3474,
    'Paso de Hidalgo (Paso de Álamos)': 3474,
    'Paso de Núñez (Buenavista)': 3474,
    'Pastor Ortíz': 3474,
    'Patuán': 3474,
    'Pátzcuaro': 3474,
    'Pedernales': 3474,
    'Peña del Panal': 3474,
    'Penjamillo de Degollado': 3474,
    'Peribán de Ramos': 3474,
    'Pichátaro': 3474,
    'Pinzándaro': 3474,
    'Pomacuarán': 3474,
    'Poturo': 3474,
    'Presa del Rosario': 3474,
    'Puácuaro': 3474,
    'Pueblo Viejo': 3474,
    'Puentecillas (Tercera Manzana de Zirahuato)': 3474,
    'Puerto de Buenavista (Lázaro Cárdenas)': 3474,
    'Purechucho': 3474,
    'Purépero de Echáiz': 3474,
    'Puruándiro': 3474,
    'Puruarán': 3474,
    'Queréndaro': 3474,
    'Quinceo': 3474,
    'Quiroga': 3474,
    'Rancho Viejo': 3474,
    'Real Hacienda (Metrópolis)': 3474,
    'Rincón de Cedeños (Rincón de Dolores)': 3474,
    'Rincón de Curungueo': 3474,
    'Rincón de Nicolás Romero (Cedros Tercera Manzana)': 3474,
    'Río Grande': 3474,
    'Romero de Guzmán': 3474,
    'Romero de Torres': 3474,
    'Sahuayo': 3474,
    'Sahuayo de Morelos': 3474,
    'Salguero': 3474,
    'San Agustín del Maíz': 3474,
    'San Agustín del Pulque': 3474,
    'San Andrés Coru': 3474,
    'San Andrés Ziróndaro': 3474,
    'San Antonio': 3474,
    'San Antonio la Labor': 3474,
    'San Antonio Molinos': 3474,
    'San Antonio Tariácuri': 3474,
    'San Antonio Villalongín': 3474,
    'San Bartolo Cuitareo': 3474,
    'San Benito': 3474,
    'San Bernabé de las Canteras': 3474,
    'San Felipe de los Herreros': 3474,
    'San Felipe los Alzati (Colonia Nueva)': 3474,
    'San Francisco Peribán': 3474,
    'San Gregorio': 3474,
    'San Isidro': 3474,
    'San Isidro Alta Huerta': 3474,
    'San Jerónimo Purenchecuaro': 3474,
    'San José Cuaro': 3474,
    'San José de Gracia': 3474,
    'San José Huipana': 3474,
    'San Juan Benito Juárez (San Juan Tararameo)': 3474,
    'San Juan De Los Platanos': 3474,
    'San Juan de Viña': 3474,
    'San Juanito Itzícuaro': 3474,
    'San Lorenzo': 3474,
    'San Lorenzo Itzícuaro': 3474,
    'San Lorenzo Queréndaro': 3474,
    'San Lucas': 3474,
    'San Lucas Huarirapeo (La Mesa de San Lucas)': 3474,
    'San Lucas Pío': 3474,
    'San Martín Totolán': 3474,
    'San Miguel Epejan': 3474,
    'San Nicolás Obispo': 3474,
    'San Pedro de los Sauces': 3474,
    'San Pedro Jácuaro': 3474,
    'San Rafael Tecario': 3474,
    'San Sebastián': 3474,
    'Santa Ana': 3474,
    'Santa Ana Amatlán': 3474,
    'Santa Ana Chapitiro': 3474,
    'Santa Ana de Guerrero (El Cascabel)': 3474,
    'Santa Ana Maya': 3474,
    'Santa Ana Zirosto': 3474,
    'Santa Casilda': 3474,
    'Santa Catarina': 3474,
    'Santa Clara de Valladares': 3474,
    'Santa Clara del Cobre': 3474,
    'Santa Clara del Tule': 3474,
    'Santa Elena': 3474,
    'Santa Fé de la Laguna': 3474,
    'Santa Fe del Río': 3474,
    'Santa Juana': 3474,
    'Santa María de los Ángeles': 3474,
    'Santa María Huiramangaro (San Juan Tumbio)': 3474,
    'Santa María Urapicho': 3474,
    'Santa Rosa (Santa Bárbara)': 3474,
    'Santiago Azajo': 3474,
    'Santiago Puriatzícuaro': 3474,
    'Santiago Tangamandapio': 3474,
    'Santiago Undameo': 3474,
    'Senguio': 3474,
    'Sevina': 3474,
    'Susupuato de Guerrero': 3474,
    'Tacámbaro': 3474,
    'Tacámbaro de Codallos': 3474,
    'Tacátzcuaro': 3474,
    'Tacicuaro': 3474,
    'Tacuro (Santa María Tacuro)': 3474,
    'Tafetán': 3474,
    'Tanaco': 3474,
    'Tancítaro': 3474,
    'Tangancícuaro': 3474,
    'Tanhuato de Guerrero': 3474,
    'Tarejero': 3474,
    'Taretán': 3474,
    'Tarímbaro': 3474,
    'Tavera': 3474,
    'Tecario': 3474,
    'Tecomatán': 3474,
    'Tepalcatepec': 3474,
    'Tepuxtepec': 3474,
    'Tierras Coloradas (San Pedro)': 3474,
    'Tingambato': 3474,
    'Tingüindín': 3474,
    'Tiquicheo': 3474,
    'Tiquicheo de Nicolás Romero': 3474,
    'Tiríndaro': 3474,
    'Tirípetio': 3474,
    'Tlalpujahua de Rayón': 3474,
    'Tocumbo': 3474,
    'Tomendán': 3474,
    'Toreo Bajo (El Toreo Bajo)': 3474,
    'Tumbiscatío de Ruiz': 3474,
    'Tungareo': 3474,
    'Turicato': 3474,
    'Turícuaro': 3474,
    'Tuxpan': 3474,
    'Tuzantla': 3474,
    'Tzintzimeo': 3474,
    'Tzintzingareo': 3474,
    'Tzintzuntzan': 3474,
    'Tziritzícuaro': 3474,
    'Tzitzio': 3474,
    'Ucareo': 3474,
    'Uricho': 3474,
    'Uripitio': 3474,
    'Uruapan': 3474,
    'Uruétaro': 3474,
    'Uspero': 3474,
    'Valle Verde': 3474,
    'Venustiano Carranza': 3474,
    'Villa Jiménez': 3474,
    'Villa Madero': 3474,
    'Villa Magna': 3474,
    'Villa Victoria': 3474,
    'Villamar': 3474,
    'Villas de la Loma': 3474,
    'Vista Hermosa de Negrete': 3474,
    'Yurécuaro': 3474,
    'Zacapu': 3474,
    'Zamora': 3474,
    'Zaragoza (Puerto de Medina)': 3474,
    'Zicuicho': 3474,
    'Zicuirán': 3474,
    'Zináparo': 3474,
    'Zinapécuaro': 3474,
    'Ziquítaro': 3474,
    'Ziracuaretiro': 3474,
    'Zirahuato de los Bernal': 3474,
    'Zirahuén': 3474,
    'Zirimícuaro': 3474,
    'Zurumbeneo': 3474,
    'Abelardo L. Rodríguez': 3465,
    'Acamilpa': 3465,
    'Achichipico': 3465,
    'Adolfo López Mateos': 3465,
    'Ahuehuetzingo': 3465,
    'Alfredo V. Bonfil (Chacampalco)': 3465,
    'Alpuyeca': 3465,
    'Amacuitlapilco': 3465,
    'Amacuzac': 3465,
    'Amatlán de Quetzalcoatl': 3465,
    'Amayuca': 3465,
    'Amilcingo': 3465,
    'Anenecuilco': 3465,
    'Arroyo Vista Hermosa': 3465,
    'Atlacahualoya': 3465,
    'Atlacholoaya': 3465,
    'Atlatlahucan': 3465,
    'Atotonilco': 3465,
    'Axochiapan': 3465,
    'Ayala': 3465,
    'Bonifacio García': 3465,
    'Buenavista': 3465,
    'Calera Chica': 3465,
    'Calle Real': 3465,
    'Casahuatlán': 3465,
    'Cerritos de Cárdenas': 3465,
    'Chalcatzingo': 3465,
    'Chavarría': 3465,
    'Chiconcuac': 3465,
    'Chinameca': 3465,
    'Ciudad Ayala': 3465,
    'Cliserio Alanís (San Gaspar)': 3465,
    'Coahuixtla': 3465,
    'Coatetelco': 3465,
    'Coatlán del Río': 3465,
    'Cocoyoc': 3465,
    'Cocoyotla': 3465,
    'Colonia Ángel Bocanegra (Adolfo López Mateos)': 3465,
    'Colonia el Florido': 3465,
    'Colonia las Arboledas': 3465,
    'Colonia los Cerritos': 3465,
    'Colonia Obrera': 3465,
    'Colonia Palo Prieto (Chipitongo)': 3465,
    'Colonia San Francisco': 3465,
    'Constancio Farfán (La Pascuala)': 3465,
    'Cuauchichinola': 3465,
    'Cuauhtempan (San Andrés Cuauhtempan)': 3465,
    'Cuautla': 3465,
    'Cuentepec': 3465,
    'Cuernavaca': 3465,
    'Dos Ríos': 3465,
    'El Carmen': 3465,
    'El Estudiante': 3465,
    'El Mirador': 3465,
    'El Rodeo': 3465,
    'El Salitre': 3465,
    'Emiliano Zapata': 3465,
    'Emiliano Zapata (Casahuates)': 3465,
    'Enthavi': 3465,
    'Ex-Hacienda el Hospital': 3465,
    'Felipe Neri (Cuatepec)': 3465,
    'Fraccionamiento Lomas de Ahuatlán': 3465,
    'Fraccionamiento Universo': 3465,
    'Galeana': 3465,
    'Higuerón': 3465,
    'Huajintlán': 3465,
    'Huatecalco': 3465,
    'Huazulco': 3465,
    'Huecahuasco': 3465,
    'Huepalcalco (San Miguel)': 3465,
    'Hueyapan': 3465,
    'Huitchila': 3465,
    'Huitzilac': 3465,
    'Huitzililla': 3465,
    'Ignacio Bastida (Santa Catarina Tlayca)': 3465,
    'Independencia': 3465,
    'Itzamatitlán': 3465,
    'Ixtlilco el Chico': 3465,
    'Jaltepec': 3465,
    'Jantetelco': 3465,
    'Jicarero': 3465,
    'Jiutepec': 3465,
    'Jojutla': 3465,
    'Jonacatepec': 3465,
    'Juan Morales': 3465,
    'Jumiltepec': 3465,
    'La Huanica': 3465,
    'La Joya': 3465,
    'La Unidad Huitzizilapan': 3465,
    'Lázaro Cárdenas (El Empalme)': 3465,
    'Loma Bonita': 3465,
    'Lomas de Ahuatepec': 3465,
    'Los Arcos': 3465,
    'Marcelino Rodríguez (San Ignacio)': 3465,
    'Mazatepec': 3465,
    'Metepec': 3465,
    'Miacatlán': 3465,
    'Michapa': 3465,
    'Miguel Bocanegra': 3465,
    'Mixtlalcingo': 3465,
    'Moyotepec': 3465,
    'Narciso Mendoza': 3465,
    'Nepopualco': 3465,
    'Ninguno [Centro de Readaptación Social de Atlacholoaya]': 3465,
    'Oacalco': 3465,
    'Oaxtepec': 3465,
    'Ocoxaltepec': 3465,
    'Ocuituco': 3465,
    'Olintepec': 3465,
    'Pedro Amaro': 3465,
    'Peña Flores (Palo Verde)': 3465,
    'Pérez de Galeana': 3465,
    'Progreso': 3465,
    'Pueblo Nuevo': 3465,
    'Pueblo Nuevo de Morelos': 3465,
    'Puente de Ixtla': 3465,
    'Puxtla': 3465,
    'Quebrantadero': 3465,
    'Reyes Acozac': 3465,
    'Salazar': 3465,
    'San Andrés Cuexcontitlán': 3465,
    'San Andrés de la Cal': 3465,
    'San Andrés Ocotlán': 3465,
    'San Antonio Chiverías': 3465,
    'San Antonio del Puente': 3465,
    'San Bartolomé Tlaltelulco': 3465,
    'San Diego': 3465,
    'San Diego Alcalá': 3465,
    'San Diego Linares': 3465,
    'San Felipe Teotitlán': 3465,
    'San Francisco Chimalpa': 3465,
    'San Francisco Tetetla': 3465,
    'San Francisco Zacacalco': 3465,
    'San Gabriel las Palmas': 3465,
    'San Gaspar Tlahuelilpan': 3465,
    'San Jerónimo Acazulco': 3465,
    'San Jerónimo Chicahualco': 3465,
    'San José': 3465,
    'San José Buenavista el Grande': 3465,
    'San José Comalco': 3465,
    'San José el Llanito': 3465,
    'San Jose Solís': 3465,
    'San José Vista Hermosa': 3465,
    'San Juan Ahuehueyo': 3465,
    'San Juan la Isla': 3465,
    'San Juan Pueblo Nuevo': 3465,
    'San Juan Texcalpan': 3465,
    'San Juan Tilapa': 3465,
    'San Juan Xochiaca': 3465,
    'San Juan Yautepec': 3465,
    'San Juan Zitlaltepec': 3465,
    'San Lorenzo Cuauhtenco': 3465,
    'San Lorenzo Nenamicoyan': 3465,
    'San Lorenzo Oyamel': 3465,
    'San Luis Ayucán': 3465,
    'San Marcos de la Cruz': 3465,
    'San Mateo Atarasquíllo': 3465,
    'San Mateo Otzacatipan': 3465,
    'San Miguel': 3465,
    'San Miguel Almaya': 3465,
    'San Miguel Ameyalco': 3465,
    'San Miguel Atepoxco': 3465,
    'San Miguel Balderas': 3465,
    'San Miguel de La Victoria': 3465,
    'San Miguel Totoltepec': 3465,
    'San Nicolás Coatepec': 3465,
    'San Nicolás Peralta': 3465,
    'San Nicolás Solís': 3465,
    'San Nicolás Tlazala': 3465,
    'San Pablo Autopan': 3465,
    'San Pedro Apatlaco': 3465,
    'San Pedro Atlapulco': 3465,
    'San Pedro Chochula': 3465,
    'San Pedro Huaquilpan': 3465,
    'San Pedro Techuchulco': 3465,
    'San Pedro Tlaltizapan': 3465,
    'San Pedro Totoltepec': 3465,
    'San Pedro Tultepec': 3465,
    'San Pedro Xalpa': 3465,
    'San Pedro Zictepec': 3465,
    'San Rafael Zaragoza': 3465,
    'San Sebastián': 3465,
    'San Vicente de Juárez': 3465,
    'Santa Ana Jilotzingo': 3465,
    'Santa Catarina': 3465,
    'Santa Cruz Ayotuxco': 3465,
    'Santa Cruz Pueblo Nuevo': 3465,
    'Santa María Ajoloapan': 3465,
    'Santa María Atarasquillo': 3465,
    'Santa María Magdalena Ocotitlán': 3465,
    'Santa Martha': 3465,
    'Santa Rosa Treinta': 3465,
    'Santiago Analco': 3465,
    'Santiago Oxthoc': 3465,
    'Santiago Tepatlaxco': 3465,
    'Santiago Tílapa': 3465,
    'Santo Domingo Aztacameca': 3465,
    'Santo Domingo Ocotitlán': 3465,
    'Tecajec': 3465,
    'Tehuixtla': 3465,
    'Telixtac': 3465,
    'Temimilcingo': 3465,
    'Temixco': 3465,
    'Temoac': 3465,
    'Tenango': 3465,
    'Tenextepango': 3465,
    'Tepalcingo': 3465,
    'Tepetzingo': 3465,
    'Tepoztlán': 3465,
    'Tequesquitengo': 3465,
    'Tetecala': 3465,
    'Tetecalita': 3465,
    'Tetecolala': 3465,
    'Tetela del Volcán': 3465,
    'Tetelilla': 3465,
    'Texcala': 3465,
    'Tezoyuca': 3465,
    'Tianguistongo': 3465,
    'Ticumán': 3465,
    'Tierra Larga (Campo Nuevo)': 3465,
    'Tilzapotla': 3465,
    'Tlachaloya': 3465,
    'Tlacotepec': 3465,
    'Tlacuitlapa': 3465,
    'Tlalnepantla': 3465,
    'Tlaltenanguito': 3465,
    'Tlaltetelco (San Miguel Tlaltetelco)': 3465,
    'Tlaltizapán': 3465,
    'Tlapanaloya': 3465,
    'Tlaquiltenango': 3465,
    'Tlatenchi': 3465,
    'Tlayacapan': 3465,
    'Totolapan': 3465,
    'Tres de Mayo': 3465,
    'Tres Marías': 3465,
    'Unidad Habitacional 10 de Abril': 3465,
    'Unidad Habitacional José María Morelos y Pavón': 3465,
    'Unidad Habitacional Mariano Matamoros': 3465,
    'Unidad Habitacional Rinconada Acolapa': 3465,
    'Valle de Vázquez': 3465,
    'Villa Santiago': 3465,
    'Xalostoc': 3465,
    'Xochicalco (Cirenio Longares)': 3465,
    'Xochitepec': 3465,
    'Xochitlán': 3465,
    'Xoxocotla': 3465,
    'Yautepec': 3465,
    'Yecapixteca': 3465,
    'Yecapixtla': 3465,
    'Zacapalco': 3465,
    'Zacatepec': 3465,
    'Zacualpan de Amilpas': 3465,
    'Zaragoza de Guadalupe': 3465,
    'Acaponeta': 3477,
    'Ahuacatlán': 3477,
    'Amapa': 3477,
    'Amatlán de Cañas': 3477,
    'Aquiles Serdán': 3477,
    'Aticama': 3477,
    'Atonalisco': 3477,
    'Aután': 3477,
    'Bellavista': 3477,
    'Bucerías': 3477,
    'Camalotita': 3477,
    'Camichín de Jauja': 3477,
    'Cañada del Tabaco': 3477,
    'Chapalilla': 3477,
    'Chilapa': 3477,
    'Colonia Emiliano Zapata': 3477,
    'Colonia Paraíso Escondido': 3477,
    'Compostela': 3477,
    'Corral del Risco (Punta de Mita)': 3477,
    'Cruz de Huanacaxtle': 3477,
    'Cuamiles': 3477,
    'El Ahuacate': 3477,
    'El Botadero': 3477,
    'El Capomal': 3477,
    'El Corte': 3477,
    'El Jicote': 3477,
    'El Llano': 3477,
    'El Rosario': 3477,
    'El Tamarindo': 3477,
    'El Tizate': 3477,
    'El Vado de San Pedro': 3477,
    'El Venado': 3477,
    'Emiliano Zapata': 3477,
    'Felipe Carrillo Puerto': 3477,
    'Francisco I. Madero': 3477,
    'Guadalupe Victoria': 3477,
    'Higuera Blanca': 3477,
    'Huajicori': 3477,
    'Huajimic': 3477,
    'Ixtapan de la Concepción': 3477,
    'Ixtlán del Río': 3477,
    'Jalcocotán': 3477,
    'Jesús María': 3477,
    'Jomulco': 3477,
    'Juan Escutia (Borbollón)': 3477,
    'La Cantera': 3477,
    'La Corregidora': 3477,
    'La Fortuna': 3477,
    'La Guásima': 3477,
    'La Labor': 3477,
    'La Libertad': 3477,
    'La Palma': 3477,
    'La Peñita de Jaltomba': 3477,
    'La Presa': 3477,
    'La Yesca': 3477,
    'Las Jarretaderas': 3477,
    'Las Varas': 3477,
    'Llano del Tigre': 3477,
    'Lo de Lamedo': 3477,
    'Lo de Marcos': 3477,
    'Los Aguajes': 3477,
    'Los Otates': 3477,
    'Mecatán': 3477,
    'Mesa del Nayar': 3477,
    'Méxpan': 3477,
    'Mezcales': 3477,
    'Milpas Viejas': 3477,
    'Mojarras': 3477,
    'Monteón': 3477,
    'Navarrete': 3477,
    'Nuevo Vallarta': 3477,
    'Palma Grande': 3477,
    'Palmar de Cuautla': 3477,
    'Pantanal': 3477,
    'Pericos': 3477,
    'Pozo de Ibarra': 3477,
    'Puerta de Mangos': 3477,
    'Puga': 3477,
    'Quimichis': 3477,
    'Rincón de Guayabitos': 3477,
    'Rosa Blanca': 3477,
    'Rosamorada': 3477,
    'Ruíz': 3477,
    'San Blas': 3477,
    'San Cayetano': 3477,
    'San Clemente de Lima': 3477,
    'San Felipe Aztatán': 3477,
    'San Francisco': 3477,
    'San José de Gracia': 3477,
    'San Juan Bautista': 3477,
    'San Juan de Abajo': 3477,
    'San Luis de Lozada': 3477,
    'San Pedro Lagunillas': 3477,
    'San Vicente': 3477,
    'Santa Cruz': 3477,
    'Santa Isabel': 3477,
    'Santa María del Oro': 3477,
    'Santa Teresa': 3477,
    'Santiago de Pochotitán': 3477,
    'Santiago Ixcuintla': 3477,
    'Sauta': 3477,
    'Sayulilla': 3477,
    'Sayulita': 3477,
    'Sentispac': 3477,
    'Tecuala': 3477,
    'Tepic': 3477,
    'Tequepexpan': 3477,
    'Testerazo': 3477,
    'Tetitlán': 3477,
    'Unión de Corrientes': 3477,
    'Uzeta': 3477,
    'Valle de Banderas': 3477,
    'Villa Hidalgo': 3477,
    'Villa Juárez': 3477,
    'Yago': 3477,
    'Zacualpan': 3477,
    'Zapotán': 3477,
    'Zapotanito': 3477,
    'Agualeguas': 3452,
    'Alianza Real': 3452,
    'Anáhuac': 3452,
    'Aramberri': 3452,
    'Artemio Treviño': 3452,
    'Bosques de San Pedro': 3452,
    'Buena Vista': 3452,
    'Bustamante': 3452,
    'Cadereyta': 3452,
    'Cadereyta Jiménez': 3452,
    'Carmen': 3452,
    'Catarino Rodríguez': 3452,
    'Cerralvo': 3452,
    'China': 3452,
    'Ciénega de Flores': 3452,
    'Ciudad Apodaca': 3452,
    'Ciudad Benito Juárez': 3452,
    'Ciudad Cerralvo': 3452,
    'Ciudad de Allende': 3452,
    'Ciudad de Villaldama': 3452,
    'Ciudad General Escobedo': 3452,
    'Ciudad General Terán': 3452,
    'Ciudad Sabinas Hidalgo': 3452,
    'Ciudad Satélite del Norte': 3452,
    'Colinas del Aeropuerto': 3452,
    'Congregación Calles': 3452,
    'Doctor Arroyo': 3452,
    'Doctor Coss': 3452,
    'Doctor González': 3452,
    'Emiliano Zapata': 3452,
    'Entronque Laredo-Salinas Victoria': 3452,
    'Fraccionamiento Cosmópolis Octavo Sector': 3452,
    'Fraccionamiento Misión de San Javier': 3452,
    'Fraccionamiento Real Palmas': 3452,
    'Galeana': 3452,
    'García': 3452,
    'General Bravo': 3452,
    'General Zuazua': 3452,
    'Guadalupe': 3452,
    'Hacienda San Pedro': 3452,
    'Héctor Caballero': 3452,
    'Hualahuises': 3452,
    'Iturbide': 3452,
    'Jardines de la Silla (Jardines)': 3452,
    'La Ascensión': 3452,
    'Ladrillera (Entronque Pesquería)': 3452,
    'Lampazos de Naranjo': 3452,
    'Las Torres': 3452,
    'Las Torres de Guadalupe': 3452,
    'Linares': 3452,
    'Loma la Paz': 3452,
    'Lomas de San Martín': 3452,
    'Los Parques': 3452,
    'Los Ramones': 3452,
    'Marín': 3452,
    'Mazatlan': 3452,
    'Mina': 3452,
    'Misión San Pablo': 3452,
    'Mitras Poniente': 3452,
    'Monclova Primer Sector': 3452,
    'Monclova Segundo Sector': 3452,
    'Monte Kristal': 3452,
    'Montemorelos': 3452,
    'Monterrey': 3452,
    'Parás': 3452,
    'Parque Industrial Ciudad Mitras': 3452,
    'Paseo de San Javier': 3452,
    'Pesquería': 3452,
    'Portal de las Salinas': 3452,
    'Praderas de San Francisco': 3452,
    'Prados de Santa Rosa': 3452,
    'Pueblo Nuevo': 3452,
    'Rayones': 3452,
    'Real del Sol': 3452,
    'Río Verde': 3452,
    'Salinas Victoria': 3452,
    'San Antonio': 3452,
    'San José de Raíces': 3452,
    'San Juan': 3452,
    'San Nicolás de los Garza': 3452,
    'San Pedro Garza Garcia': 3452,
    'San Rafael': 3452,
    'Santa Rosa': 3452,
    'Santiago': 3452,
    'Simeprodeso (Colectivo Nuevo)': 3452,
    'Unión Agropecuarios Lázaro Cárdenas del Norte': 3452,
    'Valle de Juárez': 3452,
    'Valle de Lincoln': 3452,
    'Valle de Vaquerías': 3452,
    'Vallecillo': 3452,
    'Villaldama': 3452,
    'Villas de Alcalá': 3452,
    'Acatlán de Pérez Figueroa': 3448,
    'Agua del Espino': 3448,
    'Ahuehuetitlán': 3448,
    'Albarradas': 3448,
    'Almolonga': 3448,
    'Álvaro Obregón': 3448,
    'Animas Trujano': 3448,
    'Apoala': 3448,
    'Arroyo Chical (Nuevo Arroyo Chicali)': 3448,
    'Arroyo Choápam': 3448,
    'Arroyo de Banco': 3448,
    'Arroyo de Enmedio': 3448,
    'Arroyo Limón': 3448,
    'Arroyo Zapotillo': 3448,
    'Asunción Cacalotepec': 3448,
    'Asunción Ixtaltepec': 3448,
    'Asunción Nochixtlán': 3448,
    'Asunción Ocotlán': 3448,
    'Asunción Tlacolulita': 3448,
    'Ayoquezco de Aldama': 3448,
    'Ayotzintepec': 3448,
    'Ayutla': 3448,
    'Bajos de Chila': 3448,
    'Barrio San Diego': 3448,
    'Benemérito Juárez': 3448,
    'Benito Juárez': 3448,
    'Benito Juárez II (San Martín)': 3448,
    'Bethania': 3448,
    'Brisas de Zicatela': 3448,
    'Cajonos': 3448,
    'Calihualá': 3448,
    'Camelia Roja': 3448,
    'Camotinchan': 3448,
    'Candelaria Loxicha': 3448,
    'Capulálpam de Méndez': 3448,
    'Cerro Armadillo Grande': 3448,
    'Cerro del Aire': 3448,
    'Cerro Quemado': 3448,
    'Chahuite': 3448,
    'Chahuites': 3448,
    'Chicahua': 3448,
    'Chicapa de Castro': 3448,
    'Ciénega de Zimatlán': 3448,
    'Cieneguilla': 3448,
    'Ciudad de Huajuapan de León': 3448,
    'Coatecas Altas': 3448,
    'Coixtlahuaca': 3448,
    'Collantes': 3448,
    'Colonia Jordán': 3448,
    'Colonia Juárez': 3448,
    'Colonia la Central': 3448,
    'Colonia Progreso': 3448,
    'Colonia Rincón Viejo': 3448,
    'Colonia San Luis': 3448,
    'Concepción Pápalo': 3448,
    'Constancia del Rosario': 3448,
    'Constitución Mexicana': 3448,
    'Corral de Piedra': 3448,
    'Corralero': 3448,
    'Cosoltepec': 3448,
    'Crucecita': 3448,
    'Cuapinolito (Azulillo)': 3448,
    'Cuauhtémoc': 3448,
    'Cuicatlan': 3448,
    'Cuilapan de Guerrero': 3448,
    'Donaji': 3448,
    'Ejutla de Crespo': 3448,
    'El Arador': 3448,
    'El Bajío': 3448,
    'El Barrio de la Soledad': 3448,
    'El Camalote': 3448,
    'El Camarón': 3448,
    'El Capulín (La Nueva Pochota)': 3448,
    'El Carrizal': 3448,
    'El Carrizo': 3448,
    'El Chocolate': 3448,
    'El Ciruelo': 3448,
    'El Coyul': 3448,
    'El Espinal': 3448,
    'El Molino': 3448,
    'El Morro': 3448,
    'El Porvenir': 3448,
    'El Rosario': 3448,
    'Eloxochitlán de Flores Magón': 3448,
    'Encinal Colorado': 3448,
    'Estación Mogoñé': 3448,
    'Flor Batavia': 3448,
    'Fraccionamiento Ciudad Yagul': 3448,
    'Fraccionamiento Ex-Hacienda Catano': 3448,
    'Fraccionamiento Real del Valle': 3448,
    'Fraccionamiento Riberas de San Jerónimo': 3448,
    'Fraccionamiento San Miguel': 3448,
    'Francisco Ibarra Ramos': 3448,
    'General Pascual Fentes': 3448,
    'Guadalupe Etla': 3448,
    'Guadalupe Hidalgo': 3448,
    'Guadalupe Victoria': 3448,
    'Guamúchil': 3448,
    'Guixé': 3448,
    'Hacienda Blanca': 3448,
    'Heroica Ciudad de Ejutla de Crespo': 3448,
    'Heroica Ciudad de Juchitán de Zaragoza': 3448,
    'Heroica Ciudad de Tlaxiaco': 3448,
    'Huajintepec': 3448,
    'Huautepec': 3448,
    'Huazantlán del Río': 3448,
    'Ignacio Zaragoza': 3448,
    'Isla Soyaltepec': 3448,
    'Ixpantepec Nieves': 3448,
    'Ixtepec': 3448,
    'Ixtlán de Juárez': 3448,
    'Jalapa': 3448,
    'Jaltepec de Candayoc': 3448,
    'Jaltepetongo': 3448,
    'Jamiltepec': 3448,
    'José María Morelos': 3448,
    'Juchitán de Zaragoza': 3448,
    'La Barra de Colotepec': 3448,
    'La Blanca': 3448,
    'La Cañada': 3448,
    'La Candelaria': 3448,
    'La Erradura': 3448,
    'La Humedad': 3448,
    'La Junta': 3448,
    'La Luz': 3448,
    'La Mina': 3448,
    'La Noria': 3448,
    'La Pe': 3448,
    'La Reforma': 3448,
    'La Tabaquera': 3448,
    'La Venta': 3448,
    'La Ventosa': 3448,
    'Las Amilpas': 3448,
    'Las Margaritas': 3448,
    'Lázaro Cárdenas': 3448,
    'Llano de Agua': 3448,
    'Llano Suchiapa': 3448,
    'Loma Bonita': 3448,
    'Loma Chapultepec': 3448,
    'Los Ángeles': 3448,
    'Los Naranjos Esquipulas': 3448,
    'Macín Chico': 3448,
    'Macuilxóchitl de Artigas Carranza': 3448,
    'Magdalena Apasco': 3448,
    'Magdalena Jaltepec': 3448,
    'Magdalena Mixtepec': 3448,
    'Magdalena Ocotlán': 3448,
    'Magdalena Teitipac': 3448,
    'Magdalena Tequisistlán': 3448,
    'Magdalena Tlacotepec': 3448,
    'Magdalena Yodocono de Porfirio Díaz': 3448,
    'Magdalena Zahuatlán': 3448,
    'Mancuernas': 3448,
    'María Lombardo de Caso': 3448,
    'Mariscala de Juárez': 3448,
    'Matías Romero': 3448,
    'Mazatlán Villa de Flores': 3448,
    'Mazín Grande': 3448,
    'Merced del Potrero': 3448,
    'Mesones Hidalgo': 3448,
    'Miahuatlán de Porfirio Díaz': 3448,
    'Mixistlán de la Reforma': 3448,
    'Montenegro la Lana': 3448,
    'Nanahuatípam': 3448,
    'Nativitas': 3448,
    'Nazareno Etla': 3448,
    'Nejapa de Madero': 3448,
    'Niltepec': 3448,
    'Nuevo Ixcatlán': 3448,
    'Nuevo Paso Nazareno (Chichicazapa)': 3448,
    'Nuevo San Martín': 3448,
    'Oaxaca': 3448,
    'Oaxaca de Juárez': 3448,
    'Ocotlán de Morelos': 3448,
    'Palomares': 3448,
    'Papaloapan': 3448,
    'Paso Canoa': 3448,
    'Paso Real de Sarabia': 3448,
    'Peña Colorada': 3448,
    'Piedra Blanca': 3448,
    'Piedra de Amolar': 3448,
    'Pinotepa de Don Luis': 3448,
    'Pinotepa Nacional': 3448,
    'Playa Chica': 3448,
    'Pluma Hidalgo': 3448,
    'Praxedis de Guerrero': 3448,
    'Progreso': 3448,
    'Pueblo Viejo': 3448,
    'Puerto Ángel': 3448,
    'Puerto Escondido': 3448,
    'Putla Villa de Guerrero': 3448,
    'Quelové': 3448,
    'Quinicuena': 3448,
    'Rancho Grande': 3448,
    'Rancho Viejo': 3448,
    'Reforma de Pineda': 3448,
    'Reyes Etla': 3448,
    'Reyes Mantecón': 3448,
    'Rincón Moreno': 3448,
    'Río Chiquito': 3448,
    'Río Grande': 3448,
    'Río Pachiñe': 3448,
    'Rojas de Cuauhtémoc': 3448,
    'Salina Cruz': 3448,
    'San Agustín Amatengo': 3448,
    'San Agustín Atenango': 3448,
    'San Agustín Chayuco': 3448,
    'San Agustin de las Juntas': 3448,
    'San Agustín Etla': 3448,
    'San Agustín Loxicha': 3448,
    'San Agustín Yatareni': 3448,
    'San Andrés Chicahuaxtla': 3448,
    'San Andrés Dinicuiti': 3448,
    'San Andrés Hidalgo': 3448,
    'San Andrés Huaxpaltepec': 3448,
    'San Andrés Huayápam': 3448,
    'San Andrés Ixtlahuaca': 3448,
    'San Andrés Paxtlán': 3448,
    'San Andrés Sinaxtla': 3448,
    'San Andrés Solaga': 3448,
    'San Andrés Teotilalpam': 3448,
    'San Andrés Yaá': 3448,
    'San Andrés Zabache': 3448,
    'San Andrés Zautla': 3448,
    'San Antonino Castillo Velasco': 3448,
    'San Antonino el Alto': 3448,
    'San Antonino Monte Verde': 3448,
    'San Antonio Arrazola': 3448,
    'San Antonio de la Cal': 3448,
    'San Antonio Huitepec': 3448,
    'San Antonio las Palmas': 3448,
    'San Antonio Ocotlán': 3448,
    'San Antonio Tepetlapa': 3448,
    'San Baltazar Chichicapam': 3448,
    'San Baltazar Guelavila': 3448,
    'San Baltazar Loxicha': 3448,
    'San Baltazar Yatzachi el Bajo': 3448,
    'San Bartolo': 3448,
    'San Bartolo Coyotepec': 3448,
    'San Bartolo Coyotespec': 3448,
    'San Bartolo Yautepec': 3448,
    'San Bartolomé Ayautla': 3448,
    'San Bartolomé Loxícha': 3448,
    'San Bartolomé Quialana': 3448,
    'San Benito Encinal': 3448,
    'San Bernardo Mixtepec': 3448,
    'San Blas Atempa': 3448,
    'San Carlos Yautepec': 3448,
    'San Cristóbal Amatlán': 3448,
    'San Cristóbal Amoltepec': 3448,
    'San Cristóbal Honduras': 3448,
    'San Cristóbal Lachirioag': 3448,
    'San Dionisio del Mar': 3448,
    'San Dionisio Ocotepec': 3448,
    'San Dionisio Ocotlán': 3448,
    'San Esteban Atatlahuca': 3448,
    'San Felipe Cihualtepec': 3448,
    'San Felipe Jalapa de Díaz': 3448,
    'San Felipe Tejalápam': 3448,
    'San Felipe Tílpam': 3448,
    'San Felipe Usila': 3448,
    'San Francisco Cajonos': 3448,
    'San Francisco Chindúa': 3448,
    'San Francisco Coatlán': 3448,
    'San Francisco Cozoaltepec': 3448,
    'San Francisco del Mar': 3448,
    'San Francisco del Mar Viejo': 3448,
    'San Francisco Huehuetlán': 3448,
    'San Francisco Ixhuatan': 3448,
    'San Francisco Jaltepetongo': 3448,
    'San Francisco Javier': 3448,
    'San Francisco Lachigoló': 3448,
    'San Francisco Logueche': 3448,
    'San Francisco Loxicha': 3448,
    'San Francisco Nuxaño': 3448,
    'San Francisco Ozolotepec': 3448,
    'San Francisco Sola': 3448,
    'San Francisco Telixtlahuaca': 3448,
    'San Francisco Tutla': 3448,
    'San Gabriel Mixtepec': 3448,
    'San Ildefonso Amatlán': 3448,
    'San Ildefonso Villa Alta': 3448,
    'San Isidro Apango': 3448,
    'San Isidro Monjas': 3448,
    'San Jacinto Amilpas': 3448,
    'San Jerónimo Coatlán': 3448,
    'San Jerónimo Silacayoapilla': 3448,
    'San Jerónimo Sosola': 3448,
    'San Jerónimo Taviche': 3448,
    'San Jerónimo Tecóatl': 3448,
    'San Jerónimo Tlacochahuaya': 3448,
    'San Jerónimo Yahuiche': 3448,
    'San Jorge Nuchita': 3448,
    'San José Ayuquila': 3448,
    'San José Chacalapa': 3448,
    'San José Chiltepec': 3448,
    'San José de las Flores': 3448,
    'San José del Progreso': 3448,
    'San José Estancia Grande': 3448,
    'San José la Garzona': 3448,
    'San José Lachiguirí': 3448,
    'San José Piedras Negras': 3448,
    'San José Río Manzo': 3448,
    'San José Tenango': 3448,
    'San Juan Achiutla': 3448,
    'San Juan Atepec': 3448,
    'San Juan Bautista Jayacatlán': 3448,
    'San Juan Bautista la Raya': 3448,
    'San Juan Bautista Lo de Soto': 3448,
    'San Juan Cabeza del Río': 3448,
    'San Juan Cacahuatepec': 3448,
    'San Juan Chilateca': 3448,
    'San Juan Coatzospam': 3448,
    'San Juan Colorado': 3448,
    'San Juan Cotzocón': 3448,
    'San Juan de los Cues': 3448,
    'San Juan del Estado': 3448,
    'San Juan del Río': 3448,
    'San Juan Diuxi': 3448,
    'San Juan Guelavía': 3448,
    'San Juan Guichicovi': 3448,
    'San Juan Ihualtepec': 3448,
    'San Juan Jaltepec': 3448,
    'San Juan Jicayán': 3448,
    'San Juan Juquila': 3448,
    'San Juan Juquila Vijanos': 3448,
    'San Juan Lachao': 3448,
    'San Juán Lachigalla': 3448,
    'San Juan Lagunas': 3448,
    'San Juan Mazatlán': 3448,
    'San Juan Metaltepec': 3448,
    'San Juan Mixtepec -Dto. 08 -': 3448,
    'San Juan Mixtepec -Dto. 26 -': 3448,
    'San Juan Ñumí': 3448,
    'San Juan Ozolotepec': 3448,
    'San Juan Petlapa': 3448,
    'San Juan Quiahue': 3448,
    'San Juan Quiotepec': 3448,
    'San Juan Sautla': 3448,
    'San Juan Sayultepec': 3448,
    'San Juan Tabaá': 3448,
    'San Juan Teitipac': 3448,
    'San Juan Teposcolula': 3448,
    'San Juan Yaee': 3448,
    'San Juan Yatzona': 3448,
    'San Juan Zapotitlán': 3448,
    'San Lorenzo': 3448,
    'San Lorenzo Albarradas': 3448,
    'San Lorenzo Cacaotepec': 3448,
    'San Lorenzo Cuaunecuiltitla': 3448,
    'San Lorenzo Victoria': 3448,
    'San Lorenzo Vista Hermosa': 3448,
    'San Lucas Camotlán': 3448,
    'San Lucas Ojitlán': 3448,
    'San Lucas Quiavini': 3448,
    'San Lucas Zoquiápam': 3448,
    'San Luis Amatlán': 3448,
    'San Marcial Ozolotepec': 3448,
    'San Marcos Arteaga': 3448,
    'San Marcos Zacatepec': 3448,
    'San Martín de los Canseco': 3448,
    'San Martín Itunyoso': 3448,
    'San Martín Lachila': 3448,
    'San Martín Tilcajete': 3448,
    'San Mateo Cajonos': 3448,
    'San Mateo del Mar': 3448,
    'San Mateo Etlatongo': 3448,
    'San Mateo Nejápam': 3448,
    'San Mateo Piñas': 3448,
    'San Mateo Sindihui': 3448,
    'San Mateo Yoloxochitlán': 3448,
    'San Melchor Betaza': 3448,
    'San Miguel Abejones': 3448,
    'San Miguel Achiutla': 3448,
    'San Miguel Aloápam': 3448,
    'San Miguel Chimalapa': 3448,
    'San Miguel Coatlán': 3448,
    'San Miguel del Puerto': 3448,
    'San Miguel del Valle': 3448,
    'San Miguel Ejutla': 3448,
    'San Miguel el Grande': 3448,
    'San Miguel el Grande Villa Juárez': 3448,
    'San Miguel Figueroa': 3448,
    'San Miguel Mixtepec': 3448,
    'San Miguel Monteverde': 3448,
    'San Miguel Panixtlahuaca': 3448,
    'San Miguel Peras': 3448,
    'San Miguel Quetzaltepec': 3448,
    'San Miguel Suchixtepec': 3448,
    'San Miguel Tecomatlán': 3448,
    'San Miguel Tenango': 3448,
    'San Miguel Tetepelcingo': 3448,
    'San Miguel Tilquiapam': 3448,
    'San Miguel Tlacamama': 3448,
    'San Miguel Tlacotepec': 3448,
    'San Nicolás': 3448,
    'San Nicolás Quialana': 3448,
    'San Pablo Coatlán': 3448,
    'San Pablo Cuatro Venados': 3448,
    'San Pablo Etla': 3448,
    'San Pablo Güilá': 3448,
    'San Pablo Huitzo': 3448,
    'San Pablo Huixtepec': 3448,
    'San Pablo Macuiltianguis': 3448,
    'San Pablo Villa de Mitla': 3448,
    'San Pedro Amuzgos': 3448,
    'San Pedro Apóstol': 3448,
    'San Pedro Atoyac': 3448,
    'San Pedro Comitancillo': 3448,
    'San Pedro Coxcaltepec Cántaros': 3448,
    'San Pedro el Alto': 3448,
    'San Pedro Huamelula': 3448,
    'San Pedro Huilotepec': 3448,
    'San Pedro Ixcatlán': 3448,
    'San Pedro Ixtlahuaca': 3448,
    'San Pedro Jicayán': 3448,
    'San Pedro Juchaltengo': 3448,
    'San Pedro Martir': 3448,
    'San Pedro Mártir Quiechapa': 3448,
    'San Pedro Mártir Yucuxaco': 3448,
    'San Pedro Mixtepec': 3448,
    'San Pedro Ocopetatillo': 3448,
    'San Pedro Ocotepec': 3448,
    'San Pedro Ozumacín': 3448,
    'San Pedro Pochutla': 3448,
    'San Pedro Quiatoni': 3448,
    'San Pedro Sochiápam': 3448,
    'San Pedro Tapanatepec': 3448,
    'San Pedro Taviche': 3448,
    'San Pedro Teutila': 3448,
    'San Pedro Tidaá': 3448,
    'San Pedro Topiltepec': 3448,
    'San Pedro Totolápam': 3448,
    'San Pedro Tulixtlahuaca': 3448,
    'San Pedro y San Pablo Teposcolula': 3448,
    'San Pedro y San Pablo Tequistepec': 3448,
    'San Pedro Yólox': 3448,
    'San Raymundo Jalpam': 3448,
    'San Roque': 3448,
    'San Sebastián Abasolo': 3448,
    'San Sebastián Coatlán': 3448,
    'San Sebastián del Monte': 3448,
    'San Sebastián Etla': 3448,
    'San Sebastián Ixcapa': 3448,
    'San Sebastián Nicananduta': 3448,
    'San Sebastián Nopalera': 3448,
    'San Sebastián Río Hondo': 3448,
    'San Sebastián Tecomaxtlahuaca': 3448,
    'San Sebastian Teitipac': 3448,
    'San Sebastián Tutla': 3448,
    'San Simón Almolongas': 3448,
    'San Vicente Coatlán': 3448,
    'San Vicente Lachixío': 3448,
    'San Vicente Nuñu': 3448,
    'San Vicente Piñas': 3448,
    'Santa Ana': 3448,
    'Santa Ana del Valle': 3448,
    'Santa Ana Tlapacoyan': 3448,
    'Santa Ana Zegache': 3448,
    'Santa Catalina Quierí': 3448,
    'Santa Catarina Cuixtla': 3448,
    'Santa Catarina Juquila': 3448,
    'Santa Catarina Loxicha': 3448,
    'Santa Catarina Mechoacán': 3448,
    'Santa Catarina Minas': 3448,
    'Santa Catarina Quiané': 3448,
    'Santa Catarina Roatina': 3448,
    'Santa Catarina Tayata': 3448,
    'Santa Cecilia Jalieza': 3448,
    'Santa Cruz Acatepec': 3448,
    'Santa Cruz Amilpas': 3448,
    'Santa Cruz de Bravo': 3448,
    'Santa Cruz de Juárez': 3448,
    'Santa Cruz Lachixolana': 3448,
    'Santa Cruz Mixtepec': 3448,
    'Santa Cruz Nundaco': 3448,
    'Santa Cruz Ozolotepec': 3448,
    'Santa Cruz Papalutla': 3448,
    'Santa Cruz Tacache de Mina': 3448,
    'Santa Cruz Tayata': 3448,
    'Santa Cruz Xitla': 3448,
    'Santa Cruz Xoxocotlán': 3448,
    'Santa Fe y la Mar': 3448,
    'Santa Gertrudis': 3448,
    'Santa Inés de Zaragoza': 3448,
    'Santa Inés del Monte': 3448,
    'Santa Inés Yatzeche': 3448,
    'Santa Lucía': 3448,
    'Santa Lucía del Camino': 3448,
    'Santa Lucía Ocotlán': 3448,
    'Santa Lucía Teotepec': 3448,
    'Santa Magdalena Jicotlán': 3448,
    'Santa Margarita Huitepec': 3448,
    'Santa María Acatepec': 3448,
    'Santa María Alotepec': 3448,
    'Santa María Apazco': 3448,
    'Santa María Atzompa': 3448,
    'Santa María Camotlán': 3448,
    'Santa María Chachoápam': 3448,
    'Santa María Chico Ometepec': 3448,
    'Santa María Chilapa de Díaz': 3448,
    'Santa María Chimalapa': 3448,
    'Santa María Colotepec': 3448,
    'Santa María Cortijo': 3448,
    'Santa María Coyotepec': 3448,
    'Santa María del Rosario': 3448,
    'Santa María del Tule': 3448,
    'Santa María Ecatepec': 3448,
    'Santa María Guelacé': 3448,
    'Santa María Guenagati': 3448,
    'Santa María Huamelula': 3448,
    'Santa María Huatulco': 3448,
    'Santa María Huazolotitlán': 3448,
    'Santa María Ipalapa': 3448,
    'Santa María Jacatepec': 3448,
    'Santa María Jalapa del Marqués': 3448,
    'Santa María Jicaltepec': 3448,
    'Santa María la Asunción': 3448,
    'Santa María Lachixío': 3448,
    'Santa María Mixtequilla': 3448,
    'Santa María Nativitas': 3448,
    'Santa María Nduayaco': 3448,
    'Santa María Pápalo': 3448,
    'Santa María Petapa': 3448,
    'Santa María Puxmetacán': 3448,
    'Santa María Quiegolani': 3448,
    'Santa María Sola': 3448,
    'Santa María Temaxcalapa': 3448,
    'Santa María Temaxcaltepec': 3448,
    'Santa María Teopoxco': 3448,
    'Santa María Tepantlali': 3448,
    'Santa María Tiltepec': 3448,
    'Santa María Tlalixtac': 3448,
    'Santa María Tonameca': 3448,
    'Santa María Velato': 3448,
    'Santa María Xadani': 3448,
    'Santa María Yavesía': 3448,
    'Santa María Yolotepec': 3448,
    'Santa María Yucuhiti': 3448,
    'Santa María Yucunicoco': 3448,
    'Santa María Zacatepec': 3448,
    'Santa María Zaniza': 3448,
    'Santa María Zapotitlán': 3448,
    'Santa María Zoquitlán': 3448,
    'Santa Martha Chichihualtepec': 3448,
    'Santa Rosa': 3448,
    'Santa Rosa Caxtlahuaca': 3448,
    'Santa Rosa de Lima': 3448,
    'Santiago Amoltepec': 3448,
    'Santiago Apostol': 3448,
    'Santiago Astata': 3448,
    'Santiago Ayuquililla': 3448,
    'Santiago Cacaloxtepec': 3448,
    'Santiago Chazumba': 3448,
    'Santiago Chilixtlahuaca': 3448,
    'Santiago Choápam': 3448,
    'Santiago Comaltepec': 3448,
    'Santiago Cuixtla': 3448,
    'Santiago del Río': 3448,
    'Santiago Etla': 3448,
    'Santiago Huajolotitlán': 3448,
    'Santiago Huauclilla': 3448,
    'Santiago Huaxolotipac': 3448,
    'Santiago Ihuitlán Plumas': 3448,
    'Santiago Ixcuintepec': 3448,
    'Santiago Ixtaltepec': 3448,
    'Santiago Ixtayutla': 3448,
    'Santiago Jicayán': 3448,
    'Santiago Jocotepec': 3448,
    'Santiago Juxtlahuaca': 3448,
    'Santiago Lachiguiri': 3448,
    'Santiago Lalopa': 3448,
    'Santiago Laollaga': 3448,
    'Santiago Laxopa': 3448,
    'Santiago Llano Grande': 3448,
    'Santiago Malacatepec': 3448,
    'Santiago Matatlán': 3448,
    'Santiago Miltepec': 3448,
    'Santiago Nejapilla': 3448,
    'Santiago Niltepec': 3448,
    'Santiago Nundiche': 3448,
    'Santiago Nuyoó': 3448,
    'Santiago Quiavicuzas': 3448,
    'Santiago Suchilquitongo': 3448,
    'Santiago Tenango': 3448,
    'Santiago Tepextla': 3448,
    'Santiago Tetepec': 3448,
    'Santiago Texcalcingo': 3448,
    'Santiago Textitlán': 3448,
    'Santiago Tilantongo': 3448,
    'Santiago Tillo': 3448,
    'Santiago Xanica': 3448,
    'Santiago Yaitepec': 3448,
    'Santiago Yogana': 3448,
    'Santiago Yolomécatl': 3448,
    'Santiago Yosondúa': 3448,
    'Santiago Zacatepec': 3448,
    'Santiago Zoochila': 3448,
    'Santo Domingo Albarradas': 3448,
    'Santo Domingo Armenta': 3448,
    'Santo Domingo Chihuitán': 3448,
    'Santo Domingo de Morelos': 3448,
    'Santo Domingo Jalieza': 3448,
    'Santo Domingo Ozolotepec': 3448,
    'Santo Domingo Petapa': 3448,
    'Santo Domingo Roayaga': 3448,
    'Santo Domingo Tehuantepec': 3448,
    'Santo Domingo Tepuxtepec': 3448,
    'Santo Domingo Tlatayapam': 3448,
    'Santo Domingo Tomaltepec': 3448,
    'Santo Domingo Tonalá': 3448,
    'Santo Domingo Yanhuitlán': 3448,
    'Santo Domingo Yodohino': 3448,
    'Santo Domingo Zanatepec': 3448,
    'Santo Tomás': 3448,
    'Santo Tomás Jalieza': 3448,
    'Santo Tomás Mazaltepec': 3448,
    'Santo Tomás Ocotepec': 3448,
    'Santo Tomás Tamazulapam': 3448,
    'Santos Reyes Nopala': 3448,
    'Santos Reyes Pápalo': 3448,
    'Santos Reyes Tepejillo': 3448,
    'Santos Reyes Yucuná': 3448,
    'Sector H Tres': 3448,
    'Silacayoápam': 3448,
    'Sitio de Xitlapehua': 3448,
    'Soledad Etla': 3448,
    'Soledad Salinas': 3448,
    'Tamazola': 3448,
    'Tamazulápam del Espíritu Santo': 3448,
    'Tamazulapam Villa del Progreso': 3448,
    'Tanetze de Zaragoza': 3448,
    'Taniche': 3448,
    'Tapanalá': 3448,
    'Tatahuicapá': 3448,
    'Tataltepec de Valdés': 3448,
    'Tecomavaca': 3448,
    'Temascal': 3448,
    'Teococuilco de Marcos Pérez': 3448,
    'Teojomulco': 3448,
    'Teotitlán': 3448,
    'Teotitlán del Valle': 3448,
    'Tepenixtlahuaca': 3448,
    'Tepetlapa': 3448,
    'Tetela': 3448,
    'Texcatitlán': 3448,
    'Texmelucan': 3448,
    'Tezoatlán de Segura y Luna': 3448,
    'Tlachichilco': 3448,
    'Tlacoatzintepec': 3448,
    'Tlacolula de Matamoros': 3448,
    'Tlahuitoltepec': 3448,
    'Tlalixtac de Cabrera': 3448,
    'Totontepec Villa de Morelos': 3448,
    'Toxpalan': 3448,
    'Trinidad de Viguera': 3448,
    'Trinidad Zaachila': 3448,
    'Tuxtepec': 3448,
    'Unión Hidalgo': 3448,
    'Valdeflores': 3448,
    'Valerio Trujano': 3448,
    'Valle Nacional': 3448,
    'Vega del Sol': 3448,
    'Vicente Camalote': 3448,
    'Vicente Guerrero': 3448,
    'Villa Chalcatongo de Hidalgo': 3448,
    'Villa de Etla': 3448,
    'Villa de Tamazulápam del Progreso': 3448,
    'Villa de Tututepec de Melchor Ocampo': 3448,
    'Villa de Zaachila': 3448,
    'Villa Díaz Ordaz': 3448,
    'Villa Hidalgo': 3448,
    'Villa Sola de Vega': 3448,
    'Villa Talea de Castro': 3448,
    'Villa Tejúpam de la Unión': 3448,
    'Vista Hermosa': 3448,
    'Xaaga': 3448,
    'Xagacía': 3448,
    'Xochitonalco': 3448,
    'Yaganiza': 3448,
    'Yalina': 3448,
    'Yatzachi': 3448,
    'Yaxe': 3448,
    'Yetla de Juárez': 3448,
    'Yucuita': 3448,
    'Yutanduchi de Guerrero': 3448,
    'Zacatal': 3448,
    'Zapotitlán Lagunas': 3448,
    'Zapotitlán Palmas': 3448,
    'Zaragoza': 3448,
    'Zarzal': 3448,
    'Zimatlán de Álvarez': 3448,
    'Zocoteaca de León': 3448,
    'Zona Urbana Ejidal': 3448,
    '18 de Marzo': 3476,
    'Acajete': 3476,
    'Acateno': 3476,
    'Acatepec': 3476,
    'Acatlán': 3476,
    'Acatlán de Osorio': 3476,
    'Acatzingo': 3476,
    'Acaxtlahuacán de Albino Zertuche': 3476,
    'Acteopan': 3476,
    'Actipan': 3476,
    'Actipan de Morelos': 3476,
    'Acuaco': 3476,
    'Acuexcomac': 3476,
    'Ahuacatlán': 3476,
    'Ahuatempan': 3476,
    'Ahuatepec': 3476,
    'Ahuatepec de Camino': 3476,
    'Ahuatlán': 3476,
    'Ahuaxintitla': 3476,
    'Ahuazotepec': 3476,
    'Ahuehuetitla': 3476,
    'Ajalpan': 3476,
    'Álamos Tepetitlán': 3476,
    'Albino Zertuche': 3476,
    'Alhuaca': 3476,
    'Aljojuca': 3476,
    'Allende': 3476,
    'Almecatla': 3476,
    'Almolonga': 3476,
    'Altepexi': 3476,
    'Álvaro Obregón': 3476,
    'Amatitlán de Azueta': 3476,
    'Ameluca': 3476,
    'Amixtlán': 3476,
    'Amozoc': 3476,
    'Amozoc de Mota': 3476,
    'Analco de Ponciano Arriaga (Santa Cruz Analco)': 3476,
    'Apango de Zaragoza': 3476,
    'Apantéopan': 3476,
    'Apapantilla': 3476,
    'Apatauyan': 3476,
    'Aquixtla': 3476,
    'Artículo Primero Constitucional': 3476,
    'Atalpan': 3476,
    'Atecax': 3476,
    'Atempan': 3476,
    'Atencingo': 3476,
    'Atexcal': 3476,
    'Atla': 3476,
    'Atlequizayan': 3476,
    'Atlixco': 3476,
    'Atoluca': 3476,
    'Atoyatempan': 3476,
    'Atzala': 3476,
    'Atzalán': 3476,
    'Atzingo (La Cumbre)': 3476,
    'Atzitzintla': 3476,
    'Axutla': 3476,
    'Axuxco': 3476,
    'Ayotla': 3476,
    'Ayotoxco de Guerrero': 3476,
    'Ayutla': 3476,
    'Azumbilla': 3476,
    'Barrio de Nuevo León': 3476,
    'Bellavista de Victoria (San José Bellavista)': 3476,
    'Benito Juárez': 3476,
    'Beristain': 3476,
    'Bienvenido': 3476,
    'Buena Vista': 3476,
    'Buenavista': 3476,
    'Buenavista de Juárez': 3476,
    'Buenavista Tetela': 3476,
    'Buenos Aires': 3476,
    'Cacaloxúchitl': 3476,
    'Cala Norte': 3476,
    'Cala Sur': 3476,
    'Calipan': 3476,
    'Calmeca': 3476,
    'Calpanería Atezquilla': 3476,
    'Camocuautla': 3476,
    'Camotepec': 3476,
    'Candelaria Portezuelo': 3476,
    'Carmen Serdán': 3476,
    'Casa Blanca': 3476,
    'Caxhuacán': 3476,
    'Chalchihuapan': 3476,
    'Chapulco': 3476,
    'Chiautla de Tapia': 3476,
    'Chicahuaxtla': 3476,
    'Chichicapa': 3476,
    'Chichicuautla': 3476,
    'Chichiquila': 3476,
    'Chiconcuautla': 3476,
    'Chicontla': 3476,
    'Chietla': 3476,
    'Chigmecatitlán': 3476,
    'Chignahuapan': 3476,
    'Chila': 3476,
    'Chila de Juárez': 3476,
    'Chila de la Sal': 3476,
    'Chilchotla': 3476,
    'Chinantla': 3476,
    'Chinautla': 3476,
    'Chipahuatlán': 3476,
    'Chipilo de Francisco Javier Mina': 3476,
    'Cholula': 3476,
    'Cinco de Mayo': 3476,
    'Ciudad de Chiautla de Tapia': 3476,
    'Ciudad de Cuetzalan': 3476,
    'Ciudad de Tlatlauquitepec': 3476,
    'Ciudad Serdán': 3476,
    'Coahuixco': 3476,
    'Coatepec': 3476,
    'Coatzingo': 3476,
    'Cohuecán': 3476,
    'Colonia Agrícola de Ocotepec (Colonia San José)': 3476,
    'Colonia Obrera': 3476,
    'Colucan': 3476,
    'Comaltepec': 3476,
    'Concepción Capulac (La Ex-Hacienda)': 3476,
    'Concepción Cuautla': 3476,
    'Copila': 3476,
    'Coronango': 3476,
    'Coronel Tito Hernández': 3476,
    'Coxcatlán': 3476,
    'Coxolico': 3476,
    'Coyomeapan': 3476,
    'Coyotepec': 3476,
    'Cuacnopalan': 3476,
    'Cuacuila': 3476,
    'Cuanalá': 3476,
    'Cuapiaxtla de Madero': 3476,
    'Cuatro Caminos': 3476,
    'Cuauhtamazaco': 3476,
    'Cuauhtémoc': 3476,
    'Cuautempan': 3476,
    'Cuautinchán': 3476,
    'Cuautlancingo': 3476,
    'Cuautotola': 3476,
    'Cuautotolapan (San José)': 3476,
    'Cuaxoxpan': 3476,
    'Cuaxuxpa': 3476,
    'Cuayuca de Andrade': 3476,
    'Cuesta Blanca': 3476,
    'Cuetzalan': 3476,
    'Cuetzalan del Progreso': 3476,
    'Cuexpala': 3476,
    'Cuyoaco': 3476,
    'Dolores Hidalgo': 3476,
    'Domingo Arenas': 3476,
    'El Capulo (La Quebradora)': 3476,
    'El Carmen': 3476,
    'El Chacal': 3476,
    'El Encanto del Cerril': 3476,
    'El Fuerte de la Unión': 3476,
    'El Mirador': 3476,
    'El Molino': 3476,
    'El Palmar': 3476,
    'El Progreso': 3476,
    'El Rincón Citlaltépetl': 3476,
    'El Veladero': 3476,
    'Eloxochitlán': 3476,
    'Emancipación Quetzalapa': 3476,
    'Emilio Carranza (Santa Cruz)': 3476,
    'Emilio Portes Gil': 3476,
    'Esperanza': 3476,
    'Francisco I. Madero': 3476,
    'Francisco Ignacio Madero': 3476,
    'Francisco Sarabia': 3476,
    'Francisco Villa': 3476,
    'Francisco Z. Mena': 3476,
    'Galaxia la Calera': 3476,
    'General Felipe Ángeles': 3476,
    'Gilberto Camacho': 3476,
    'Gómez Poniente': 3476,
    'González Ortega': 3476,
    'Grajales': 3476,
    'Guadalupe': 3476,
    'Guadalupe Calderón': 3476,
    'Guadalupe Enríquez': 3476,
    'Guadalupe Libertad': 3476,
    'Guadalupe Morelos': 3476,
    'Guadalupe Santa Ana': 3476,
    'Guadalupe Sarabia': 3476,
    'Guadalupe Victoria': 3476,
    'Guadalupe Zaragoza': 3476,
    'Hermenegildo Galeana': 3476,
    'Honey': 3476,
    'Huachinantla': 3476,
    'Huahuaxtla': 3476,
    'Huajoyuca': 3476,
    'Huaquechula': 3476,
    'Huatlatlauca': 3476,
    'Huauchinango': 3476,
    'Huautla de Jiménez': 3476,
    'Huaxcaleca': 3476,
    'Huehuetla': 3476,
    'Huehuetlan el Chico': 3476,
    'Huehuetlán el Grande': 3476,
    'Huejonapan': 3476,
    'Huejotzingo': 3476,
    'Hueyapan': 3476,
    'Hueyotlipan': 3476,
    'Hueytamalco': 3476,
    'Hueytlalpan': 3476,
    'Huilacapixtla': 3476,
    'Huitzilac': 3476,
    'Huitzilan': 3476,
    'Huitziltepec': 3476,
    'Huitzmaloc': 3476,
    'Huixcolotla': 3476,
    'Icxotitla': 3476,
    'Ignacio Allende (Concepción)': 3476,
    'Ignacio Manuel Altamirano': 3476,
    'Ignacio Zaragoza': 3476,
    'Itzoteno': 3476,
    'Ixcamilpa de Guerrero': 3476,
    'Ixcaquixtla': 3476,
    'Ixtacamaxtitlán': 3476,
    'Ixtahuiata (La Legua)': 3476,
    'Ixtepec': 3476,
    'Ixticpan': 3476,
    'Ixtlahuaca': 3476,
    'Ixtlahuaca Barrio': 3476,
    'Ixtolco de Morelos': 3476,
    'Izúcar de Matamoros': 3476,
    'Jalpan': 3476,
    'Jaltocan': 3476,
    'Jesús Nazareno': 3476,
    'Jicolapa': 3476,
    'Jilotzingo': 3476,
    'Jolalpan': 3476,
    'Jonotla': 3476,
    'Jopala': 3476,
    'José María Morelos': 3476,
    'Juan C. Bonilla': 3476,
    'Juan de la Granja': 3476,
    'Juan Galindo': 3476,
    'Juan N. Méndez': 3476,
    'Juárez Coronaco': 3476,
    'La Cañada': 3476,
    'La Compañia': 3476,
    'La Galarza': 3476,
    'La Gloria': 3476,
    'La Laguna': 3476,
    'La Libertad': 3476,
    'La Magdalena': 3476,
    'La Magdalena Tetela Morelos': 3476,
    'La Magdalena Yancuitlalpan': 3476,
    'La Pahua': 3476,
    'La Purísima': 3476,
    'La Resurrección': 3476,
    'La Soledad': 3476,
    'La Trinidad Chautenco': 3476,
    'La Trinidad Tianguismanalco': 3476,
    'La Unión': 3476,
    'La Unión Ejido Mexcaltepec': 3476,
    'Lacapan Camallagne': 3476,
    'Lafragua': 3476,
    'Las Colonias de Hidalgo': 3476,
    'Las Lajas': 3476,
    'Las Lomas': 3476,
    'Las Nieves': 3476,
    'Lázaro Cárdenas': 3476,
    'Leacaman': 3476,
    'Libertad Álvaro Obregón': 3476,
    'Libres': 3476,
    'Lipuntahuaca': 3476,
    'Loma Alta': 3476,
    'Loma Bonita': 3476,
    'Lomas de Romero': 3476,
    'Lomas de Santa Cruz': 3476,
    'Los Ángeles Tetela': 3476,
    'Los Cristales (Talican)': 3476,
    'Los Encinos': 3476,
    'Los Garcías': 3476,
    'Los Reyes de Juárez': 3476,
    'Los Reyes Tlanechicolpan': 3476,
    'Magdalena Cuayucatepec': 3476,
    'Manantiales': 3476,
    'Manuel Edgardo Ávalos (San Isidro)': 3476,
    'Matlahuacales Aquiles Serdán': 3476,
    'Matzaco': 3476,
    'Máximo Serdán': 3476,
    'Mazapiltepec': 3476,
    'Mazatepec': 3476,
    'Mecapalapa': 3476,
    'Metlaltoyuca': 3476,
    'Mexcalcuautla': 3476,
    'Miahuatlán': 3476,
    'Michac': 3476,
    'Miguel Hidalgo': 3476,
    'Miguel Negrete': 3476,
    'Miravalles': 3476,
    'Mixtla': 3476,
    'Morelos': 3476,
    'Morelos Cañada': 3476,
    'Moyotzingo': 3476,
    'Nanacatlán': 3476,
    'Naupan': 3476,
    'Nauzontla': 3476,
    'Nealtican': 3476,
    'Necaxa': 3476,
    'Necoxcalco': 3476,
    'Necoxtla': 3476,
    'Nenehuaca': 3476,
    'Nexpan': 3476,
    'Nexticapan': 3476,
    'Nicolás Bravo': 3476,
    'Nopalucan': 3476,
    'Nuevo México': 3476,
    'Nuevo Necaxa': 3476,
    'Ocotepec': 3476,
    'Ocotlamanic': 3476,
    'Ocotlán de Betancourt': 3476,
    'Ocotlán de Venustiano Carranza': 3476,
    'Olintla': 3476,
    'Ometepec': 3476,
    'Oriental': 3476,
    'Oyameles de Hidalgo': 3476,
    'Ozelonacaxtla': 3476,
    'Pahuatlán de Valle': 3476,
    'Palmar de Bravo': 3476,
    'Palmarito Tochapan': 3476,
    'Pantepec': 3476,
    'Papaloctipan': 3476,
    'Papatlazolco': 3476,
    'Paso Carretas': 3476,
    'Paso Nacional': 3476,
    'Patla': 3476,
    'Pericotepec': 3476,
    'Petlalcingo': 3476,
    'Pezmatlán': 3476,
    'Piaxtla': 3476,
    'Pochálcatl': 3476,
    'Primero de Mayo': 3476,
    'Progreso': 3476,
    'Progreso de Juárez': 3476,
    'Puebla': 3476,
    'Putlunichuchut (Vista Hermosa)': 3476,
    'Quamila': 3476,
    'Quecholac': 3476,
    'Quechulac': 3476,
    'Rafael J. García': 3476,
    'Rancho Chico': 3476,
    'Rancho Nuevo': 3476,
    'Ricardo Flores Magón': 3476,
    'Rincón de los Reyes': 3476,
    'Saltillo': 3476,
    'San Acateno': 3476,
    'San Agustín Atzompa': 3476,
    'San Agustín Huixaxtla': 3476,
    'San Agustín Tlaxco': 3476,
    'San Andrés': 3476,
    'San Andrés Azumiatla': 3476,
    'San Andrés Cacaloapan': 3476,
    'San Andrés Calpan': 3476,
    'San Andrés Cholula': 3476,
    'San Andrés Hueyacatitla': 3476,
    'San Andrés Payuca': 3476,
    'San Andrés Yahuitlalpan': 3476,
    'San Antonio': 3476,
    'San Antonio Alpanocan': 3476,
    'San Antonio Atotonilco': 3476,
    'San Antonio Cañada': 3476,
    'San Antonio Juárez': 3476,
    'San Antonio la Portilla': 3476,
    'San Antonio Matlahuacales': 3476,
    'San Antonio Mihuacán': 3476,
    'San Antonio Ocopetlatlán': 3476,
    'San Antonio Portezuelo': 3476,
    'San Antonio Soledad': 3476,
    'San Antonio Tecolco': 3476,
    'San Antonio Tlacamilco': 3476,
    'San Antonio Tlatenco': 3476,
    'San Antonio Virreyes': 3476,
    'San Baltazar Atlimeyaya': 3476,
    'San Bartolomé': 3476,
    'San Bartolomé Hueyapan': 3476,
    'San Bernabé Temoxtitla': 3476,
    'San Bernardino Lagunas': 3476,
    'San Bernardino Tlaxcalancingo': 3476,
    'San Buenaventura': 3476,
    'San Buenaventura Tecaltzingo': 3476,
    'San Carlos': 3476,
    'San Cristóbal Hidalgo': 3476,
    'San Cristóbal los Nava': 3476,
    'San Cristóbal Xochimilpa': 3476,
    'San Diego': 3476,
    'San Diego el Organal': 3476,
    'San Diego la Mesa Tochimiltzingo': 3476,
    'San Felipe Hidalgo': 3476,
    'San Felipe Otlaltepec': 3476,
    'San Félix Hidalgo': 3476,
    'San Félix Rijo': 3476,
    'San Francisco': 3476,
    'San Francisco Cuapa': 3476,
    'San Francisco Cuautlancingo': 3476,
    'San Francisco Independencia (Santa María Aserradero)': 3476,
    'San Francisco Ocotlán': 3476,
    'San Francisco Tepeyecac': 3476,
    'San Francisco Tláloc': 3476,
    'San Francisco Zacapexpan': 3476,
    'San Gabriel Chilac': 3476,
    'San Gabriel Tetzoyocán': 3476,
    'San Gregorio Atzompa': 3476,
    'San Gregorio Aztotoacan': 3476,
    'San Hipólito': 3476,
    'San Isidro': 3476,
    'San Isidro Huilotepec': 3476,
    'San Isidro Monterrosas': 3476,
    'San Isidro Vista Hermosa': 3476,
    'San Jerónimo Almoloya': 3476,
    'San Jerónimo Axochitlán': 3476,
    'San Jerónimo Coaltepec': 3476,
    'San Jerónimo Coyula': 3476,
    'San Jerónimo Ocotitla': 3476,
    'San Jerónimo Xayacatlán': 3476,
    'San José': 3476,
    'San José Acateno': 3476,
    'San José Alchichica': 3476,
    'San José Buena Vista': 3476,
    'San José Buenavista': 3476,
    'San José Carpinteros': 3476,
    'San José Chiapa': 3476,
    'San José Cuyachapa': 3476,
    'San José de Gracia': 3476,
    'San José Ixtapa': 3476,
    'San José Manzanitos': 3476,
    'San José Monte Chiquito': 3476,
    'San José Ozumba': 3476,
    'San José Tilapa': 3476,
    'San José Tlacuitlapan': 3476,
    'San Juan Amecac': 3476,
    'San Juan Atenco': 3476,
    'San Juan Atzingo': 3476,
    'San Juan Atzompa': 3476,
    'San Juan Colón': 3476,
    'San Juan Cuauhtémoc': 3476,
    'San Juan Cuautla': 3476,
    'San Juan Epatlán': 3476,
    'San Juan Huiluco': 3476,
    'San Juan Ixcaquixtla': 3476,
    'San Juan Pancoac': 3476,
    'San Juan Raboso': 3476,
    'San Juan Tahitic': 3476,
    'San Juan Tejaluca': 3476,
    'San Juan Tejupa': 3476,
    'San Juan Tepulco': 3476,
    'San Juan Tetla': 3476,
    'San Juan Tezongo': 3476,
    'San Juan Tuxco': 3476,
    'San Juan Xiutetelco': 3476,
    'San Lorenzo': 3476,
    'San Lorenzo Chiautzingo': 3476,
    'San Lorenzo Joya de Rodríguez': 3476,
    'San Lorenzo Tlaxipehuala': 3476,
    'San Lucas Atzala': 3476,
    'San Lucas el Grande': 3476,
    'San Lucas el Viejo': 3476,
    'San Luis Ajajalpan': 3476,
    'San Luis Coyotzingo': 3476,
    'San Luis Tehuiloyocan': 3476,
    'San Marcos Eloxochitlán': 3476,
    'San Marcos Tlacoyalco': 3476,
    'San Martín': 3476,
    'San Martín Caltenco': 3476,
    'San Martín Esperilla': 3476,
    'San Martín Mazateopan': 3476,
    'San Martín Ojo de Agua': 3476,
    'San Martín Texmelucan': 3476,
    'San Martin Texmelucan de Labastida': 3476,
    'San Martín Tlamapa': 3476,
    'San Martín Tlapala': 3476,
    'San Mateo Ayecac': 3476,
    'San Mateo Capultitlán': 3476,
    'San Mateo Ozolco': 3476,
    'San Mateo Parra': 3476,
    'San Mateo Soltepec': 3476,
    'San Matías Atzala': 3476,
    'San Matias Tlalancaleca': 3476,
    'San Miguel Aguacomulican': 3476,
    'San Miguel Analco': 3476,
    'San Miguel Atlapulco': 3476,
    'San Miguel Canoa': 3476,
    'San Miguel del Milagro': 3476,
    'San Miguel del Progreso': 3476,
    'San Miguel Espejo': 3476,
    'San Miguel Ixitlán': 3476,
    'San Miguel Ocotenco': 3476,
    'San Miguel Papaxtla': 3476,
    'San Miguel Tecuitlapa': 3476,
    'San Miguel Tenango': 3476,
    'San Miguel Tenextatiloyan': 3476,
    'San Miguel Tianguistenco': 3476,
    'San Miguel Tianguizolco': 3476,
    'San Miguel Xoxtla': 3476,
    'San Miguel Zoapan': 3476,
    'San Miguel Zozutla': 3476,
    'San Nicolas Buenos Aires': 3476,
    'San Nicolás de los Ranchos': 3476,
    'San Nicolás Tolentino': 3476,
    'San Nicolás Zecalacoayan': 3476,
    'San Nicolás Zoyapetlayoca': 3476,
    'San Pablito': 3476,
    'San Pablo Ahuatempa': 3476,
    'San Pablo Anicano': 3476,
    'San Pablo de las Tunas': 3476,
    'San Pedro Atmatla': 3476,
    'San Pedro Benito Juárez': 3476,
    'San Pedro Cholula': 3476,
    'San Pedro Cuayuca': 3476,
    'San Pedro Itztla': 3476,
    'San Pedro la Joya': 3476,
    'San Pedro Matamoros': 3476,
    'San Pedro Petlacotla': 3476,
    'San Pedro Temamatla': 3476,
    'San Pedro Tetitlán': 3476,
    'San Pedro Tlaolantongo': 3476,
    'San Pedro Yancuitlalpan': 3476,
    'San Pedro Yeloixtlahuaca': 3476,
    'San Pedro Zacachimalpa': 3476,
    'San Rafael Ixtapalucan': 3476,
    'San Rafael Tlanalapan': 3476,
    'San Salvador (Naranjillo)': 3476,
    'San Salvador El Seco': 3476,
    'San Salvador el Verde': 3476,
    'San Sebastián': 3476,
    'San Sebastián Alcomunga': 3476,
    'San Sebastián de Aparicio': 3476,
    'San Sebastián Teteles': 3476,
    'San Sebastián Tlacotepec': 3476,
    'San Sebastián Villanueva': 3476,
    'San Simón': 3476,
    'San Simón Atzitzintla': 3476,
    'San Vicente Boquerón': 3476,
    'San Vicente Coyotepec': 3476,
    'Sanctorum': 3476,
    'Santa Ana Acozautla': 3476,
    'Santa Ana Coapan': 3476,
    'Santa Ana Coatepec': 3476,
    'Santa Ana Necoxtla': 3476,
    'Santa Ana Xalmimilulco': 3476,
    'Santa Catarina Tlaltempan': 3476,
    'Santa Catarina Villanueva': 3476,
    'Santa Cecilia Tepetitlán': 3476,
    'Santa Clara': 3476,
    'Santa Clara Huitziltepec': 3476,
    'Santa Cruz Acapa': 3476,
    'Santa Cruz Ajajalpan': 3476,
    'Santa Cruz Cuautomatitla': 3476,
    'Santa Cruz el Porvenir': 3476,
    'Santa Cruz Huitziltepec': 3476,
    'Santa Cruz Otlatla': 3476,
    'Santa Cruz Texmalaquilla': 3476,
    'Santa Cruz Xaltetela': 3476,
    'Santa Inés Tecuexcomac': 3476,
    'Santa Inés Varela la Luz': 3476,
    'Santa Isabel Cholula': 3476,
    'Santa Lucía Cosamaloapan': 3476,
    'Santa María Atexcac': 3476,
    'Santa Maria Coatepec': 3476,
    'Santa María del Monte': 3476,
    'Santa María Guadalupe Tecola': 3476,
    'Santa María Ixtiyucán': 3476,
    'Santa María la Alta': 3476,
    'Santa María Malacatepec': 3476,
    'Santa María Nenetzintla': 3476,
    'Santa María Nepopualco': 3476,
    'Santa María Techachalco': 3476,
    'Santa María Texmelucan': 3476,
    'Santa María Xonacatepec': 3476,
    'Santa María Zacatepec': 3476,
    'Santa Martha Hidalgo': 3476,
    'Santa Rita': 3476,
    'Santa Rita Tlahuapan': 3476,
    'Santa Rosa': 3476,
    'Santiago': 3476,
    'Santiago Acatlán': 3476,
    'Santiago Acozac': 3476,
    'Santiago Alseseca': 3476,
    'Santiago Atzitzihuacán': 3476,
    'Santiago Coltzingo': 3476,
    'Santiago Momoxpan': 3476,
    'Santiago Tenango': 3476,
    'Santiago Yancuictlalpan': 3476,
    'Santo Domingo Atoyatempan': 3476,
    'Santo Domingo Huehuetlán': 3476,
    'Santo Nombre': 3476,
    'Santo Tomás Chautla': 3476,
    'Sección 23': 3476,
    'Soledad Morelos': 3476,
    'Soltepec': 3476,
    'Sosa': 3476,
    'Tacopan': 3476,
    'Tanamacoyan': 3476,
    'Tanhuixco': 3476,
    'Tatauzoquico': 3476,
    'Tatoxcac': 3476,
    'Teacalco de Dorantes (San José Teacalco)': 3476,
    'Tecali': 3476,
    'Tecamachalco': 3476,
    'Tecoltepec': 3476,
    'Tecomatlán': 3476,
    'Tecpantzacoalco': 3476,
    'Tecuanipa': 3476,
    'Tecuanipan': 3476,
    'Tehuacán': 3476,
    'Tehuitzingo': 3476,
    'Tejaluca': 3476,
    'Teloxtoc': 3476,
    'Telpatlán': 3476,
    'Temalacayuca': 3476,
    'Temextla': 3476,
    'Tenampulco': 3476,
    'Tenango de las Flores': 3476,
    'Teontepec': 3476,
    'Teopantlán': 3476,
    'Teotlalco': 3476,
    'Teotlaltzingo': 3476,
    'Tepanco': 3476,
    'Tepango': 3476,
    'Tepango de Rodríguez': 3476,
    'Tepapayeca': 3476,
    'Tepatlaxco de Hidalgo': 3476,
    'Tepazolco': 3476,
    'Tepeaca': 3476,
    'Tepeojuma': 3476,
    'Tepeteno de Iturbide': 3476,
    'Tepetitán Reyeshogpan de Hidalgo': 3476,
    'Tepetlacolco': 3476,
    'Tepetzingo': 3476,
    'Tepetzintla': 3476,
    'Tepetzitzintla': 3476,
    'Tepexco': 3476,
    'Tepexi de Rodríguez': 3476,
    'Tepeyahualco': 3476,
    'Tepeyahualco de Cuauhtémoc': 3476,
    'Tepeyehualco de Hidalgo': 3476,
    'Tetela de Ocampo': 3476,
    'Tetelcingo': 3476,
    'Teteles de Avila Castillo': 3476,
    'Texcala': 3476,
    'Texcalapa de Juárez': 3476,
    'Texocoyohuac': 3476,
    'Tezhuatepec': 3476,
    'Teziutlan': 3476,
    'Tezonteopan de Bonilla': 3476,
    'Tianguismanalco': 3476,
    'Tierra Negra': 3476,
    'Tilapa': 3476,
    'Tlacamilco': 3476,
    'Tlachichuca': 3476,
    'Tlacotepec': 3476,
    'Tlacotepec (San Mateo)': 3476,
    'Tlacotepec de Benito Juárez': 3476,
    'Tlacotepec de José Manzo': 3476,
    'Tlacuilotepec': 3476,
    'Tlahuapan': 3476,
    'Tlaixpan': 3476,
    'Tlaltenango': 3476,
    'Tlaltepango': 3476,
    'Tlamanca': 3476,
    'Tlamanca de Hernández': 3476,
    'Tlamaya Grande': 3476,
    'Tlanalapan': 3476,
    'Tlancualpican': 3476,
    'Tlanepantla': 3476,
    'Tlaola': 3476,
    'Tlapacoya': 3476,
    'Tlapanalá': 3476,
    'Tlatempa': 3476,
    'Tlatlauquitepec': 3476,
    'Tlaxcalantongo': 3476,
    'Tlaxco': 3476,
    'Tlaxco (Santiago Tlaxco)': 3476,
    'Tlaxpanaloya': 3476,
    'Tlayehualancingo': 3476,
    'Tlixco': 3476,
    'Tochimilco': 3476,
    'Tochtepec': 3476,
    'Tomaquilapa': 3476,
    'Tomatlán': 3476,
    'Tonalixco': 3476,
    'Totoltepec': 3476,
    'Totoltepec de Guerrero': 3476,
    'Toxtla': 3476,
    'Tozihuic': 3476,
    'Tronconal': 3476,
    'Tula': 3476,
    'Tulcingo': 3476,
    'Tulcingo de Valle': 3476,
    'Tulimanca': 3476,
    'Tuxtla': 3476,
    'Tuzamapan': 3476,
    'Tuzuapan': 3476,
    'Tzicatlacoyan': 3476,
    'Tzicatlán': 3476,
    'Tzinacapan': 3476,
    'Unidad Grajales INFONAVIT': 3476,
    'Venta Grande': 3476,
    'Venustiano Carranza': 3476,
    'Vicente Guerrero': 3476,
    'Villa Avila Camacho': 3476,
    'Villa Cuauhtémoc': 3476,
    'Villa Mariano Matamoros': 3476,
    'Xacaxomulco': 3476,
    'Xalacapan de Lucke': 3476,
    'Xalitzintla': 3476,
    'Xaltepec': 3476,
    'Xaltepuxtla': 3476,
    'Xalticpac': 3476,
    'Xayacatlán de Bravo': 3476,
    'Xicotepec': 3476,
    'Xicotepec de Juárez': 3476,
    'Xicotlán': 3476,
    'Xilocuautla': 3476,
    'Xiloxochico de Rafael Ávila Camacho': 3476,
    'Xitlama': 3476,
    'Xiutetelco': 3476,
    'Xochiapulco': 3476,
    'Xochicuautla': 3476,
    'Xochiltepec': 3476,
    'Xochimilco': 3476,
    'Xochinanacatlán': 3476,
    'Xochitepec': 3476,
    'Xochitlán de Vicente Suárez': 3476,
    'Xochitlan Todos Santos': 3476,
    'Xochitlaxco (San Baltazar)': 3476,
    'Xocotepéc': 3476,
    'Xoloateno': 3476,
    'Xolotla': 3476,
    'Xonalpu': 3476,
    'Xonocuautla': 3476,
    'Xoxonacatla': 3476,
    'Xuchapa': 3476,
    'Yaonahuac': 3476,
    'Yehualtepec': 3476,
    'Yopi': 3476,
    'Zacaola': 3476,
    'Zacapala': 3476,
    'Zacapechpan': 3476,
    'Zacapoaxtla': 3476,
    'Zacatepec': 3476,
    'Zacatlán': 3476,
    'Zahuatlán de Morelos (San José)': 3476,
    'Zapotitlán': 3476,
    'Zaragoza': 3476,
    'Zautla': 3476,
    'Zempoala': 3476,
    'Zihuateutla': 3476,
    'Zinacatepec': 3476,
    'Zoatecpan': 3476,
    'Zolonquiapa': 3476,
    'Zongozotla': 3476,
    'Zoquiapan': 3476,
    'Zoquiopan': 3476,
    'Zoquitlán': 3476,
    'Zoyatitla': 3476,
    'Zoyotla': 3476,
    'Zozocolco de Guerrero': 3476,
    'Agua Azul': 3455,
    'Agua Fría': 3455,
    'Agua Zarca': 3455,
    'Ahuacatlán de Guadalupe': 3455,
    'Ajuchitlán': 3455,
    'Ajuchitlancito': 3455,
    'Alfajayucan': 3455,
    'Amazcala': 3455,
    'Amealco': 3455,
    'Apapátaro': 3455,
    'Arcila': 3455,
    'Arroyo Seco': 3455,
    'Atongo': 3455,
    'Bella Vista del Río': 3455,
    'Bernal': 3455,
    'Bordo Blanco': 3455,
    'Boxasní': 3455,
    'Boyé': 3455,
    'Bravo': 3455,
    'Buenavista': 3455,
    'Cadereyta': 3455,
    'Calamanda': 3455,
    'Carbonera': 3455,
    'Casa Blanca': 3455,
    'Ceja de Bravo': 3455,
    'Cerro de la Cruz': 3455,
    'Cerro Gordo': 3455,
    'Charco Blanco': 3455,
    'Chichimequillas': 3455,
    'Colinas de Santa Cruz Segunda Sección': 3455,
    'Colón': 3455,
    'Colonia los Ángeles': 3455,
    'Concá': 3455,
    'Coyotillos': 3455,
    'Dolores Cuadrilla de Enmedio': 3455,
    'El Blanco': 3455,
    'El Carmen': 3455,
    'El Cazadero': 3455,
    'El Ciervo': 3455,
    'El Colorado': 3455,
    'El Coto': 3455,
    'El Gallo': 3455,
    'El Jaral': 3455,
    'El Jazmín': 3455,
    'El Lobo': 3455,
    'El Milagro': 3455,
    'El Nabo': 3455,
    'El Organal': 3455,
    'El Palmar': 3455,
    'El Paraíso': 3455,
    'El Pinto': 3455,
    'El Pozo': 3455,
    'El Pueblito': 3455,
    'El Rincón': 3455,
    'El Rosario': 3455,
    'El Salitre': 3455,
    'El Saúz': 3455,
    'El Tejocote': 3455,
    'El Vegil': 3455,
    'Epigmenio González': 3455,
    'Escolasticas': 3455,
    'Esperanza': 3455,
    'Ezequiel Montes': 3455,
    'Fuentezuelas': 3455,
    'Galeras': 3455,
    'Galindo': 3455,
    'General Lázaro Cárdenas (El Colorado)': 3455,
    'Guadalupe Septién': 3455,
    'Hacienda la Cruz [Fraccionamiento]': 3455,
    'Higuerillas': 3455,
    'Huimilpan': 3455,
    'Ignacio Pérez': 3455,
    'Jalpan': 3455,
    'Jesús María': 3455,
    'Joaquín Herrera': 3455,
    'Jofrito': 3455,
    'Juriquilla': 3455,
    'La Barreta': 3455,
    'La Cañada': 3455,
    'La Ceja': 3455,
    'La D': 3455,
    'La Estacada': 3455,
    'La Estancia': 3455,
    'La Fuente': 3455,
    'La Gotera': 3455,
    'La Griega': 3455,
    'La Laja': 3455,
    'La Lira': 3455,
    'La Llave': 3455,
    'La Loma': 3455,
    'La Luz': 3455,
    'La Monja': 3455,
    'La Negreta': 3455,
    'La Noria': 3455,
    'La Palma': 3455,
    'La Piedad': 3455,
    'La Pradera': 3455,
    'La Solana': 3455,
    'La Soledad': 3455,
    'La Tortuga': 3455,
    'La Trinidad': 3455,
    'La Valla': 3455,
    'La Versolilla': 3455,
    'Laguna de Vaquerías': 3455,
    'Lagunillas': 3455,
    'Landa de Matamoros': 3455,
    'Las Lajitas': 3455,
    'Las Taponas': 3455,
    'Loma Linda': 3455,
    'Los Baños': 3455,
    'Los Benitos': 3455,
    'Los Cerritos': 3455,
    'Los Cues': 3455,
    'Los Olvera': 3455,
    'Los Pocitos': 3455,
    'Lourdes': 3455,
    'Mesillas': 3455,
    'Mompaní': 3455,
    'Montenegro': 3455,
    'Ninguno [CERESO]': 3455,
    'Noria Nueva': 3455,
    'Ojo de Agua': 3455,
    'Palmillas': 3455,
    'Palo Alto': 3455,
    'Paseos del Marqués': 3455,
    'Paseos del Pedregal': 3455,
    'Paso de Mata': 3455,
    'Patria Nueva': 3455,
    'Pedro Escobedo': 3455,
    'Peñamiller': 3455,
    'Pie de Gallo': 3455,
    'Pinal de Amoles': 3455,
    'Pintillo': 3455,
    'Presa de Rayas': 3455,
    'Puerta de Palmillas': 3455,
    'Puerto de Aguirre': 3455,
    'Puerto de Carroza': 3455,
    'Puerto de Chiquihuite': 3455,
    'Puerto de Nieto': 3455,
    'Purísima de Arista': 3455,
    'Purísima de Cubos (La Purísima)': 3455,
    'Querétaro': 3455,
    'Rancho Bellavista [Fraccionamiento]': 3455,
    'Saldarriaga': 3455,
    'San Antonio de la Cal': 3455,
    'San Clemente': 3455,
    'San Francisco de la Palma': 3455,
    'San Ildefonso': 3455,
    'San Ildefonso Tultepec': 3455,
    'San Isidro Buenavista': 3455,
    'San Isidro Miranda': 3455,
    'San Javier': 3455,
    'San José Buenavista': 3455,
    'San José de la Laja': 3455,
    'San Jose de los Olvera': 3455,
    'San José del Jagüey': 3455,
    'San José el Alto': 3455,
    'San José Itho': 3455,
    'San José Tepuzas': 3455,
    'San Juan del Río': 3455,
    'San Martín': 3455,
    'San Martín Florida': 3455,
    'San Miguel Amazcala': 3455,
    'San Miguelito': 3455,
    'San Nicolás': 3455,
    'San Nicolás de la Torre': 3455,
    'San Pablo Tolimán': 3455,
    'San Pedro': 3455,
    'San Pedro Mártir': 3455,
    'San Rafael': 3455,
    'San Sebastián de Las Barrancas Sur': 3455,
    'San Vicente el Alto': 3455,
    'San Vicente Ferrer': 3455,
    'Santa Bárbara de La Cueva': 3455,
    'Santa Catarina': 3455,
    'Santa Cruz': 3455,
    'Santa Cruz Escandón': 3455,
    'Santa María Begoña': 3455,
    'Santa María Magdalena': 3455,
    'Santa Matilde': 3455,
    'Santa Rosa de Lima': 3455,
    'Santa Rosa Jauregui': 3455,
    'Santa Rosa Xajay': 3455,
    'Santiago de Querétaro': 3455,
    'Santiago Mexquititlán Barrio 4to.': 3455,
    'Santillán': 3455,
    'Sergio Villaseñor': 3455,
    'Tequisquiapan': 3455,
    'Tierra Blanca': 3455,
    'Tlacote el Bajo': 3455,
    'Tolimán': 3455,
    'Urecho': 3455,
    'Venceremos': 3455,
    'Viborillas': 3455,
    'Villa Guerrero': 3455,
    'Villa Progreso': 3455,
    'Villas Fundadores [Fraccionamiento]': 3455,
    'Vista Hermosa (Cuasinada)': 3455,
    'Visthá': 3455,
    'Vizarrón': 3455,
    'Yosphí': 3455,
    'Zamorano': 3455,
    'Akumal': 3467,
    'Alfredo V. Bonfil': 3467,
    'Álvaro Obregón': 3467,
    'Bacalar': 3467,
    'Benito Juárez': 3467,
    'Cacao': 3467,
    'Calderitas': 3467,
    'Cancún': 3467,
    'Caobas': 3467,
    'Carlos A. Madrazo': 3467,
    'Chetumal': 3467,
    'Chiquilá': 3467,
    'Chunhuhub': 3467,
    'Ciudad Chemuyil': 3467,
    'Coba': 3467,
    'Cocoyol': 3467,
    'Cozumel': 3467,
    'Dziuche': 3467,
    'Dzula': 3467,
    'El Tintal': 3467,
    'Felipe Carrillo Puerto': 3467,
    'Huay Max': 3467,
    'Huay-Pix': 3467,
    'Ignacio Zaragoza': 3467,
    'Isla Holbox': 3467,
    'Isla Mujeres': 3467,
    'Javier Rojo Gómez': 3467,
    'José María Morelos': 3467,
    'Jose Narciso Rovirosa': 3467,
    'Juan Sarabia': 3467,
    'Kancabchén': 3467,
    'Kantunilkín': 3467,
    'La Presumida': 3467,
    'La Unión': 3467,
    'Lázaro Cárdenas': 3467,
    'Leona Vicario': 3467,
    'Los Divorciados': 3467,
    'Maya Balam': 3467,
    'Morocoy': 3467,
    'Nicolás Bravo': 3467,
    'Nuevo Xcán': 3467,
    'Othón P. Blanco': 3467,
    'Playa del Carmen': 3467,
    'Polyuc': 3467,
    'Presidente Juárez': 3467,
    'Pucté': 3467,
    'Puerto Aventuras': 3467,
    'Saban': 3467,
    'Sabidos': 3467,
    'San Angel': 3467,
    'Santa Rosa Segundo': 3467,
    'Señor': 3467,
    'Sergio Butrón Casas': 3467,
    'Subteniente López': 3467,
    'Tepich': 3467,
    'Tihosuco': 3467,
    'Tulum': 3467,
    'Ucum': 3467,
    'X Cabil': 3467,
    'X-Hazil Sur': 3467,
    'X-pichil': 3467,
    'Xul-Ha': 3467,
    'Zacalaca': 3467,
    'Zona Urbana Ejido Isla Mujeres': 3467,
    'Agua Buena': 3461,
    'Agua Señora': 3461,
    'Ahualulco del Sonido Trece': 3461,
    'Ahuehueyo Primero Centro': 3461,
    'Alaquines': 3461,
    'Alberto Carrera Torres': 3461,
    'Ampliación la Hincada': 3461,
    'Aquismón': 3461,
    'Arroyos': 3461,
    'Aurelio Manrique': 3461,
    'Axtla de Terrazas': 3461,
    'Bledos': 3461,
    'Bocas': 3461,
    'Cárdenas': 3461,
    'Carranco': 3461,
    'Cerrito de Jaral': 3461,
    'Cerrito de Zavala': 3461,
    'Cerritos': 3461,
    'Cerro de San Pedro': 3461,
    'Cerro Gordo': 3461,
    'Chalchocoyo': 3461,
    'Charcas': 3461,
    'Chiconamel': 3461,
    'Ciudad del Maíz': 3461,
    'Ciudad Fernández': 3461,
    'Ciudad Satélite': 3461,
    'Ciudad Valles': 3461,
    'Colonia Álvaro Obregón': 3461,
    'Colonia Veinte de Noviembre': 3461,
    'Corcovado': 3461,
    'Damian Carmona': 3461,
    'Derramaderos': 3461,
    'Dulce Grande': 3461,
    'Ébano': 3461,
    'Ejido el Saucillo': 3461,
    'Ejido Gogorrón (Ex-Hacienda de Gogorrón)': 3461,
    'Ejido la Pitahaya (Santo Domingo)': 3461,
    'Ejido los Huastecos': 3461,
    'Ejido San José Xilatzén': 3461,
    'El Barril': 3461,
    'El Capulín': 3461,
    'El Carrizal': 3461,
    'El Fuerte': 3461,
    'El Huexco': 3461,
    'El Jabalí': 3461,
    'El Jaralito': 3461,
    'El Naranjo': 3461,
    'El Peñasco': 3461,
    'El Rosario': 3461,
    'El Tepetate': 3461,
    'Emiliano Zapata': 3461,
    'Enramadas': 3461,
    'Enrique Estrada': 3461,
    'Entronque de Matehuala (El Huizache)': 3461,
    'Escalerillas': 3461,
    'Estación Tamuín': 3461,
    'Estanzuela': 3461,
    'Fracción Milpillas': 3461,
    'Granjenal': 3461,
    'Guadalcázar': 3461,
    'Guadalupe Victoria': 3461,
    'Hernández': 3461,
    'Higinio Olivo': 3461,
    'Huichihuayan': 3461,
    'Illescas': 3461,
    'Iztacapa': 3461,
    'Jalpilla': 3461,
    'Jesús María': 3461,
    'La Esperanza': 3461,
    'La Herradura': 3461,
    'La Lima': 3461,
    'La Mesa de los Conejos': 3461,
    'La Pila': 3461,
    'La Reforma': 3461,
    'La Subida': 3461,
    'La Ventilla': 3461,
    'Laguna de San Vicente': 3461,
    'Laguna de Santa Rita': 3461,
    'Laguna del Mante': 3461,
    'Las Armas': 3461,
    'Las Palmas': 3461,
    'Los Zacatones': 3461,
    'Maitinez': 3461,
    'Maravillas': 3461,
    'Martinez': 3461,
    'Matehuala': 3461,
    'Moctezuma': 3461,
    'Monte Obscuro': 3461,
    'Negritas': 3461,
    'Norias del Refugio': 3461,
    'Nueva Primavera': 3461,
    'Nuevo Crucitas': 3461,
    'Nuevo Tampaón': 3461,
    'Ocuiltzapoyo': 3461,
    'Ojo Caliente': 3461,
    'Ojo de Agua': 3461,
    'Ojo de Agua de Solano': 3461,
    'Paisanos': 3461,
    'Palma Pegada': 3461,
    'Palmar Primero': 3461,
    'Palo Seco (El Gato)': 3461,
    'Palomas': 3461,
    'Pardo': 3461,
    'Paso Blanco': 3461,
    'Paso Bonito': 3461,
    'Pastora': 3461,
    'Pemucho': 3461,
    'Peotillos': 3461,
    'Picholco': 3461,
    'Ponciano Arriaga': 3461,
    'Portezuelo': 3461,
    'Pozuelos': 3461,
    'Progreso': 3461,
    'Puerto de Providencia': 3461,
    'Pujal-Coy': 3461,
    'Rancho Nuevo': 3461,
    'Rascón': 3461,
    'Rayón': 3461,
    'Real de Catorce': 3461,
    'Rincón del Porvenir': 3461,
    'Rinconada': 3461,
    'Rioverde': 3461,
    'Rodrigo': 3461,
    'Salinas de Hidalgo': 3461,
    'Salitral de Carrera': 3461,
    'San Ciro de Acosta': 3461,
    'San Diego': 3461,
    'San Francisco': 3461,
    'San Francisco Cuayalab': 3461,
    'San Isidro': 3461,
    'San José del Tapanco': 3461,
    'San Luis Potosí': 3461,
    'San Martín Chalchicuautla': 3461,
    'San Nicolás': 3461,
    'San Nicolás Tolentino': 3461,
    'San Vicente Tancuayalab': 3461,
    'Sánchez': 3461,
    'Santa Catarina': 3461,
    'Santa María del Río': 3461,
    'Santa Martha': 3461,
    'Santa Rita': 3461,
    'Santiago Centro': 3461,
    'Santo Domingo': 3461,
    'Sauz de Calera': 3461,
    'Socavón (El Carmen)': 3461,
    'Soledad de Graciano Sánchez': 3461,
    'Suspiro Picacho': 3461,
    'Tamán': 3461,
    'Tamápatz': 3461,
    'Tamasopo': 3461,
    'Tamazunchale': 3461,
    'Tambaca': 3461,
    'Tamcuime': 3461,
    'Tampacán': 3461,
    'Tampate': 3461,
    'Tampemoche': 3461,
    'Tamuín': 3461,
    'Tancanhuitz': 3461,
    'Tandzumadz': 3461,
    'Tanquián de Escobedo': 3461,
    'Tanute': 3461,
    'Tazaquil': 3461,
    'Temalacaco': 3461,
    'Tenexio': 3461,
    'Tierra Nueva': 3461,
    'Tlacuilola': 3461,
    'Tlalnepantla': 3461,
    'Tocoy': 3461,
    'Vanegas': 3461,
    'Villa de Ramos': 3461,
    'Villa de Reyes': 3461,
    'Villa Hidalgo': 3461,
    'Villa Zaragoza': 3461,
    'Xilitla': 3461,
    'Yerbabuena': 3461,
    'Zamachihue': 3461,
    'Zapuyo': 3461,
    'Adolfo López Mateos (El Tamarindo)': 3449,
    'Adolfo Ruiz Cortines': 3449,
    'Agua Caliente Grande (De Gastélum)': 3449,
    'Agua Verde': 3449,
    'Ahome': 3449,
    'Alfonso G. Calderón (Poblado Siete)': 3449,
    'Alfonso G. Calderón Velarde': 3449,
    'Alfredo V. Bonfil (Siete Ejidos)': 3449,
    'Alhuey': 3449,
    'Altata': 3449,
    'Angostura': 3449,
    'Apoderado': 3449,
    'Baburia': 3449,
    'Bachigualatito': 3449,
    'Bachoco': 3449,
    'Bachomobampo Número Dos': 3449,
    'Bacorehuis': 3449,
    'Bacubirito': 3449,
    'Badiraguato': 3449,
    'Bagojo Colectivo': 3449,
    'Bamoa': 3449,
    'Bariometo': 3449,
    'Barrón': 3449,
    'Bellavista': 3449,
    'Benito Juárez': 3449,
    'Benito Juárez (Vinatería)': 3449,
    'Buen Retiro (El Retiro)': 3449,
    'Cacalotán': 3449,
    'Caimanero': 3449,
    'Campo Balbuena': 3449,
    'Campo la Arrocera': 3449,
    'Campo Pesquero el Colorado': 3449,
    'Carricitos': 3449,
    'Casa Blanca': 3449,
    'CERESO Nuevo': 3449,
    'Cerrillos (Campo 35)': 3449,
    'Cerro Agudo': 3449,
    'Ceuta': 3449,
    'Chametla': 3449,
    'Charay': 3449,
    'Chihuahuita': 3449,
    'Chinobampo': 3449,
    'Choix': 3449,
    'Cofradía de Navolato (Cofradía de los Rocha)': 3449,
    'Colonia 24 de Febrero': 3449,
    'Colonia Agrícola México (Palmitas)': 3449,
    'Colonia Michoacana': 3449,
    'Concentración 5 de Febrero': 3449,
    'Concordia': 3449,
    'Constancia': 3449,
    'Corerepe': 3449,
    'Cosalá': 3449,
    'Costa Azul': 3449,
    'Coyotitán': 3449,
    'Cristo Rey': 3449,
    'Cruz Blanca': 3449,
    'Cubiri de Portelas': 3449,
    'Culiacán': 3449,
    'Culiacancito': 3449,
    'Dautillos': 3449,
    'Dos de Abril': 3449,
    'Ejido Cajón Ojo de Agua Número Dos': 3449,
    'Ejido Cinco de Mayo': 3449,
    'Ejido Cohuibampo': 3449,
    'Ejido Mayocoba': 3449,
    'Ejido Ohuira': 3449,
    'Ejido Tosalibampo': 3449,
    'El Aguajito': 3449,
    'El Burrión': 3449,
    'El Carrizo': 3449,
    'El Castillo': 3449,
    'El Diez': 3449,
    'El Dorado': 3449,
    'El Fuerte': 3449,
    'El Guayabo': 3449,
    'El Habal': 3449,
    'El Higueral': 3449,
    'El Huajote': 3449,
    'El Huitusi': 3449,
    'El Limón de los Ramos': 3449,
    'El Molino': 3449,
    'El Nio': 3449,
    'El Pochotal': 3449,
    'El Potrero de Sataya': 3449,
    'El Pozole': 3449,
    'El Progreso': 3449,
    'El Quelite': 3449,
    'El Refugio': 3449,
    'El Roble': 3449,
    'El Rosario': 3449,
    'El Sabino': 3449,
    'El Saladito': 3449,
    'El Varal (San Sebastián Número Uno)': 3449,
    'El Verde': 3449,
    'El Walamo': 3449,
    'Eldorado': 3449,
    'Elota': 3449,
    'Empaque Tarriba': 3449,
    'Escamillas': 3449,
    'Escuinapa': 3449,
    'Estacion Bamoa': 3449,
    'Estación Capomas': 3449,
    'Estación Chimaneco': 3449,
    'Estación Obispo': 3449,
    'Estación Rosales': 3449,
    'Felipe Angeles': 3449,
    'Flor Azul': 3449,
    'Fraccionamiento los Ángeles': 3449,
    'Gabriel Leyva Solano (Zapotillo Dos)': 3449,
    'Gabriel Leyva Velázquez': 3449,
    'Gambino': 3449,
    'Genaro Estrada': 3449,
    'General Ángel Flores (La Palma)': 3449,
    'Goros Número Dos': 3449,
    'Guadalupe Victoria': 3449,
    'Guamúchil': 3449,
    'Guasave': 3449,
    'Guasavito': 3449,
    'Gustavo Díaz Ordaz': 3449,
    'Heriberto Valdez Romero (El Guayabo)': 3449,
    'Higuera de los Vega': 3449,
    'Higuera de Zaragoza': 3449,
    'Higueras de Abuya': 3449,
    'Higueras de los Natoches': 3449,
    'Huepaco': 3449,
    'Isla del Bosque': 3449,
    'Javier Rojo Gómez': 3449,
    'Jesús María': 3449,
    'Jitzamuri': 3449,
    'Juan Aldama (El Tigre)': 3449,
    'Juan José Ríos': 3449,
    'La Brecha': 3449,
    'La Concepción': 3449,
    'La Concha (La Concepción)': 3449,
    'La Cruz': 3449,
    'La Despensa': 3449,
    'La Esperanza': 3449,
    'La Loma (La Loma de Quila)': 3449,
    'La Noria': 3449,
    'La Noria de San Antonio': 3449,
    'La Palma': 3449,
    'La Palmita y Anexos (La Presita)': 3449,
    'La Presita': 3449,
    'La Reforma': 3449,
    'La Trinidad': 3449,
    'Ladrilleras de Ocoro': 3449,
    'Las Aguamitas': 3449,
    'Las Arenitas': 3449,
    'Las Brisas': 3449,
    'Las Compuertas': 3449,
    'Las Grullas Margen Derecha': 3449,
    'Las Grullas Margen Izquierda': 3449,
    'Las Moras': 3449,
    'Las Quemazones': 3449,
    'Lázaro Cárdenas': 3449,
    'Leyva Solano': 3449,
    'Licenciado Benito Juárez (Campo Gobierno)': 3449,
    'Llano Grande': 3449,
    'Lo de Jesús (Campo Romero)': 3449,
    'Los Angeles': 3449,
    'Los Mochis': 3449,
    'Los Pozos': 3449,
    'Macapule': 3449,
    'Marcol': 3449,
    'Maripa': 3449,
    'Mazatlán': 3449,
    'Melchor Ocampo': 3449,
    'Mesillas': 3449,
    'Miguel Valdez Quintero (El Corazón)': 3449,
    'Mochicahui': 3449,
    'Mochis': 3449,
    'Mocorito': 3449,
    'Naranjo': 3449,
    'Navolato': 3449,
    'Nuevo San Miguel': 3449,
    'Ojo de Agua de Palmillas': 3449,
    'Orba (Infiernito)': 3449,
    'Oso Viejo': 3449,
    'Palmito del Verde': 3449,
    'Palos Verdes': 3449,
    'Pericos': 3449,
    'Piaxtla de Abajo': 3449,
    'Pitahayal': 3449,
    'Plan de Ayala (Campo Cinco)': 3449,
    'Poblado Número Cinco': 3449,
    'Portaceli': 3449,
    'Portugués de Gálvez': 3449,
    'Potrerillo del Norote': 3449,
    'Potrero de los Sánchez (Estación Techa)': 3449,
    'Primero de Mayo': 3449,
    'Pueblo Nuevo': 3449,
    'Pueblo Viejo': 3449,
    'Pueblos Unidos': 3449,
    'Quila': 3449,
    'Ranchito de Castro': 3449,
    'Recoveco': 3449,
    'Ricardo Flores Magón': 3449,
    'Salado': 3449,
    'San Blas': 3449,
    'San Diego': 3449,
    'San Francisco de Tacuichamona': 3449,
    'San Ignacio': 3449,
    'San Isidro': 3449,
    'San Miguel Zapotitlan': 3449,
    'San Pedro Guasave': 3449,
    'San Rafael': 3449,
    'Sanalona': 3449,
    'Sinaloa': 3449,
    'Sinaloa de Leyva': 3449,
    'Siqueros': 3449,
    'Tamazula': 3449,
    'Tanques': 3449,
    'Taxtes': 3449,
    'Teacapan': 3449,
    'Tecualilla': 3449,
    'Topolobampo': 3449,
    'Tres Garantías': 3449,
    'Vallejo (Porvenir Vallejo)': 3449,
    'Villa de Costa Rica': 3449,
    'Villa Juárez': 3449,
    'Villa Unión': 3449,
    '31 de Octubre': 3468,
    'Aconchi': 3468,
    'Aduana del Sásabe': 3468,
    'Agiabampo Uno': 3468,
    'Agua Prieta': 3468,
    'Álamos': 3468,
    'Allende': 3468,
    'Antonio Rosales': 3468,
    'Arivechi': 3468,
    'Arizpe': 3468,
    'Atil': 3468,
    'Bacabachi': 3468,
    'Bacadéhuachi': 3468,
    'Bacame Nuevo': 3468,
    'Bacanora': 3468,
    'Bacerac': 3468,
    'Bacobampo': 3468,
    'Bácum': 3468,
    'Bahía de Kino': 3468,
    'Bahía de Lobos': 3468,
    'Basconcobe': 3468,
    'Bavispe': 3468,
    'Benjamín Hill': 3468,
    'Buaysiacobe': 3468,
    'Buayums': 3468,
    'Campo Carretero': 3468,
    'Campo Sesenta': 3468,
    'Cananea': 3468,
    'Carbo': 3468,
    'Centro de Readaptación Social Nuevo': 3468,
    'Chucarit': 3468,
    'Ciudad Obregón': 3468,
    'Cocorit': 3468,
    'Cuauhtémoc (Campo Cinco)': 3468,
    'Cucurpe': 3468,
    'Cumpas': 3468,
    'Ejido Lagunitas': 3468,
    'El Coyote': 3468,
    'El Diamante (La Retranca)': 3468,
    'El Paredoncito': 3468,
    'El Recodo': 3468,
    'El Rodeo': 3468,
    'El Sahuaral': 3468,
    'El Saneal': 3468,
    'El Siviral (Jigica)': 3468,
    'El Tobarito': 3468,
    'Empalme': 3468,
    'Esperanza': 3468,
    'Esqueda': 3468,
    'Estación Corral': 3468,
    'Estación Llano': 3468,
    'Estación Zamora': 3468,
    'Etchojoa': 3468,
    'Etchoropo': 3468,
    'Francisco Javier Mina': 3468,
    'Fronteras': 3468,
    'Golfo de Santa Clara': 3468,
    'Granados': 3468,
    'Guásimas (De Belem)': 3468,
    'Guaymitas': 3468,
    'Hermosillo': 3468,
    'Heroica Caborca': 3468,
    'Heroica Guaymas': 3468,
    'Huachinera': 3468,
    'Huásabas': 3468,
    'Huatabampo': 3468,
    'Huépac': 3468,
    'Imuris': 3468,
    'Islita': 3468,
    'Jecopaco': 3468,
    'Jitonhueca': 3468,
    'José María Morelos y Pavón': 3468,
    'Juraré': 3468,
    'La Bocana': 3468,
    'La Colorada': 3468,
    'La Libertad': 3468,
    'La Mesa': 3468,
    'La Palma': 3468,
    'La Providencia': 3468,
    'La Unión': 3468,
    'La Victoria': 3468,
    'Las Bocas': 3468,
    'Las Mercedes [Agropecuaria]': 3468,
    'Loma de Bácum': 3468,
    'Loma de Guamúchil': 3468,
    'Loma del Refugio': 3468,
    'Los Bahuises': 3468,
    'Los Hoyos': 3468,
    'Magdalena de Kino': 3468,
    'Marte R. Gómez (Tobarito)': 3468,
    'Masiaca': 3468,
    'Mazatán': 3468,
    'Mi Patria es Primero': 3468,
    'Miguel Alemán (La Doce)': 3468,
    'Milpillas': 3468,
    'Moctezuma': 3468,
    'Molino de Camou': 3468,
    'Mora Villalobos': 3468,
    'Moroncarit': 3468,
    'Naco': 3468,
    'Nácori Chico': 3468,
    'Nacozari de García': 3468,
    'Nacozari Viejo': 3468,
    'Navojoa': 3468,
    'Nogales': 3468,
    'Nuevo Michoacán (Estación Riíto)': 3468,
    'Onavas': 3468,
    'Opodepe': 3468,
    'Oquitoa': 3468,
    'Ortiz': 3468,
    'Paredón Colorado': 3468,
    'Pesqueira': 3468,
    'Pitiquito': 3468,
    'Plutarco Elías Calles (La Y Griega)': 3468,
    'Potam': 3468,
    'Pozo Dulce': 3468,
    'Primero de Mayo': 3468,
    'Progreso (Campo 47)': 3468,
    'Providencia': 3468,
    'Pueblo Mayo': 3468,
    'Pueblo Yaqui': 3468,
    'Puerto Peñasco': 3468,
    'Querobabi': 3468,
    'Quetchehueca': 3468,
    'Quiriego': 3468,
    'Rayón': 3468,
    'Rosales': 3468,
    'Rosario': 3468,
    'Sahuaral de Otero': 3468,
    'Sahuaripa': 3468,
    'San Bernardo': 3468,
    'San Carlos': 3468,
    'San Ignacio': 3468,
    'San Ignacio Río Muerto': 3468,
    'San José de Bacum': 3468,
    'San José de Guaymas': 3468,
    'San Luis Río Colorado': 3468,
    'San Miguel de Horcasitas': 3468,
    'San Pedro de la Cueva': 3468,
    'San Pedro Río Mayo': 3468,
    'Santa Ana': 3468,
    'Santa Cruz': 3468,
    'Santa María de Guaymas': 3468,
    'Santa María del Buáraje': 3468,
    'Sáric': 3468,
    'Sinahuiza': 3468,
    'Siviral': 3468,
    'Sonoita': 3468,
    'Soyopa': 3468,
    'Suaqui Grande': 3468,
    'Tepache': 3468,
    'Tierra Blanca': 3468,
    'Tubutama': 3468,
    'Ures': 3468,
    'Vicam': 3468,
    'Vicente Guerrero': 3468,
    'Villa Hidalgo': 3468,
    'Villa Juárez': 3468,
    'Villa Pesqueira': 3468,
    'Yavaros': 3468,
    'Acachapan y Colmena 3ra. Sección': 3454,
    'Álvaro Obregón (Santa Cruz)': 3454,
    'Álvaro Obregón 2da. Sección (El Lechugal)': 3454,
    'Amado Gómez': 3454,
    'Anacleto Canabal 2da. Sección': 3454,
    'Anacleto Canabal 3ra. Sección': 3454,
    'Andrés Quintana Roo': 3454,
    'Apatzingán': 3454,
    'Aquiles Serdán': 3454,
    'Aquiles Serdán (San Fernando)': 3454,
    'Aquiles Serdán 1ra. Sección': 3454,
    'Arena 1ra. Sección': 3454,
    'Arena 6ta. Sección (La Bolsa)': 3454,
    'Arena de Hidalgo': 3454,
    'Arroyo Hondo Abejonal': 3454,
    'Astapa': 3454,
    'Ayapa': 3454,
    'Balancán': 3454,
    'Banderas (Guatacalca 2da. Sección)': 3454,
    'Barrancas y Amate 3ra. Sección': 3454,
    'Belén': 3454,
    'Benito González': 3454,
    'Benito Juárez': 3454,
    'Benito Juárez (La Playita)': 3454,
    'Betania': 3454,
    'Blasillo 1ra. Sección (Nicolás Bravo)': 3454,
    'Boquiapa': 3454,
    'Bosque de Saloya': 3454,
    'Brisas del Carrizal': 3454,
    'Buena Vista (Apasco)': 3454,
    'Buena Vista (Puxcatán)': 3454,
    'Buena Vista 1ra. Sección': 3454,
    'Buenavista': 3454,
    'C-32 (Licenciado Francisco Trujillo Gurría)': 3454,
    'C-41 (Licenciado Carlos A. Madrazo)': 3454,
    'Caobanal 1ra. Sección (Mezcalapa)': 3454,
    'Caparroso': 3454,
    'Capitán Felipe Castellanos Díaz (San Pedro)': 3454,
    'Cárdenas': 3454,
    'Ceiba 1ra. Sección (Jahuactal)': 3454,
    'Centro': 3454,
    'Chablé': 3454,
    'Chichicapa': 3454,
    'Chichicastle 1ra. Sección': 3454,
    'Chontalpa': 3454,
    'Ciudad Pemex': 3454,
    'Cocohital': 3454,
    'Comalcalco': 3454,
    'Constitución': 3454,
    'Corriente 1ra. Sección': 3454,
    'Cucuyulapa Primera Sección': 3454,
    'Cúlico 2da. Sección': 3454,
    'Cumuapa 1ra. Sección': 3454,
    'Cunduacán': 3454,
    'Cupilco': 3454,
    'Dos Ceibas': 3454,
    'Dos Montes': 3454,
    'El Bajío': 3454,
    'El Bellote (Miguel de la Madrid)': 3454,
    'El Cedro': 3454,
    'El Congo': 3454,
    'El Dorado': 3454,
    'El Escribano': 3454,
    'El Recreo': 3454,
    'El Tigre': 3454,
    'El Tortuguero': 3454,
    'El Triunfo': 3454,
    'El Tular': 3454,
    'Encrucijada 3ra. Sección (Las Calzadas)': 3454,
    'Estapilla': 3454,
    'Eureka y Belén': 3454,
    'Fraccionamiento Ocuiltzapotlán Dos': 3454,
    'Francisco I. Madero': 3454,
    'Francisco Rueda': 3454,
    'Frontera': 3454,
    'Gaviotas Sur (El Cedral)': 3454,
    'General Luis Felipe Domínguez Suárez': 3454,
    'Gobernador Cruz': 3454,
    'Gobernadores': 3454,
    'Gregorio Méndez': 3454,
    'Guatacalca': 3454,
    'Guaytalpa': 3454,
    'Habanero 1ra. Sección (Venustiano Carranza)': 3454,
    'Hermenegildo Galeana 2da. Sección': 3454,
    'Huacapa y Amestoy': 3454,
    'Huapacal 1ra. Sección': 3454,
    'Huapacal 2da. Sección (Punta Brava)': 3454,
    'Huapinol': 3454,
    'Huimanguillo': 3454,
    'Ignacio Allende': 3454,
    'Ignacio Gutiérrez Gómez': 3454,
    'Ignacio Zaragoza': 3454,
    'Ignacio Zaragoza 1ra. Sección': 3454,
    'Iquinuapa': 3454,
    'Jalapa': 3454,
    'Jalapita': 3454,
    'Jalpa de Méndez': 3454,
    'Jalupa': 3454,
    'Jiménez': 3454,
    'Jolochero 2da. Sección': 3454,
    'Jonuta': 3454,
    'José Colomo': 3454,
    'La Ceiba': 3454,
    'La Curva': 3454,
    'La Escalera': 3454,
    'La Estrella': 3454,
    'La Lima': 3454,
    'La Lucha': 3454,
    'La Manga 2da. Sección (El Jobal)': 3454,
    'La Palma': 3454,
    'La Península': 3454,
    'La Sábana': 3454,
    'La Selva': 3454,
    'La Unión': 3454,
    'La Victoria': 3454,
    'Lagartera 1ra. Sección': 3454,
    'Libertad': 3454,
    'Lomitas': 3454,
    'Los Cenotes': 3454,
    'Los Sauces': 3454,
    'Luis Gil Pérez': 3454,
    'Mactún': 3454,
    'Macultepec': 3454,
    'Macuspana': 3454,
    'Mazateupa': 3454,
    'Mecatepec': 3454,
    'Mecoacán': 3454,
    'Mecoacán 2da. Sección (San Lorenzo)': 3454,
    'Medellín y Madero Segunda Sección': 3454,
    'Melchor Ocampo': 3454,
    'Miguel Hidalgo': 3454,
    'Miguel Hidalgo 2da. Sección B (La Natividad)': 3454,
    'Monte Grande': 3454,
    'Monte Largo 1ra. Sección': 3454,
    'Morelos Piedra 3ra. Sección': 3454,
    'Multé': 3454,
    'Nacajuca': 3454,
    'Netzahualcóyotl': 3454,
    'Nicolás Bravo': 3454,
    'Nicolás Bravo 5ta. Sección (Punta Brava)': 3454,
    'Norte 1ra. Sección (San Julián)': 3454,
    'Nueva División del Bayo (Guatemala)': 3454,
    'Nuevo Torno Largo': 3454,
    'Occidente (San Francisco)': 3454,
    'Occidente 4ta. Sección': 3454,
    'Ocuapan': 3454,
    'Olcuatitán': 3454,
    'Oriente 1ra. Sección': 3454,
    'Oriente 1ra. Sección (Santo Domingo)': 3454,
    'Oriente 2da. Sección (Palma Huaca)': 3454,
    'Oriente 2da. Sección (San Cayetano)': 3454,
    'Oriente 6ta. Sección (Los Mulatos)': 3454,
    'Oxiacaque': 3454,
    'Oxolotán': 3454,
    'Pablo L. Sidar': 3454,
    'Palo Mulato': 3454,
    'Palomas': 3454,
    'Paraiso': 3454,
    'Parilla': 3454,
    'Parrilla II': 3454,
    'Paso de la Mina 2da. Sección (Barrial)': 3454,
    'Paso de la Mina 3ra. Sección': 3454,
    'Pechucalco': 3454,
    'Pechucalco 2da. Sección (Las Cruces)': 3454,
    'Pejelagartero 1ra. Sección (Plataforma)': 3454,
    'Pénjamo': 3454,
    'Playas del Rosario': 3454,
    'Poblado C-11 José María Morelos y Pavón': 3454,
    'Poblado C-21 Licenciado Benito Juárez García': 3454,
    'Poblado C-33 20 de Noviembre': 3454,
    'Pomoca': 3454,
    'Potrerillo': 3454,
    'Pueblo Nuevo de las Raíces': 3454,
    'Pueblo Viejo': 3454,
    'Puerto Ceiba': 3454,
    'Puxcatán': 3454,
    'Quintín Arauz': 3454,
    'Rancho Nuevo': 3454,
    'Raya Zaragoza': 3454,
    'Reforma': 3454,
    'Río de Teapa': 3454,
    'Río Seco 2da. Sección (Santiaguito)': 3454,
    'Río Viejo Primera Sección': 3454,
    'Saloya 2da. Sección': 3454,
    'Samarkanda': 3454,
    'San Carlos': 3454,
    'San Simón': 3454,
    'Santa Rosalía': 3454,
    'Santana 2da. Sección B (La Palma)': 3454,
    'Sargento López 2da. Sección (El Chuzo)': 3454,
    'Simón Sarlat': 3454,
    'Soyataco': 3454,
    'Tacotalpa': 3454,
    'Tamulte de las Sabanas': 3454,
    'Tapijulapa': 3454,
    'Tapotzingo': 3454,
    'Taxco': 3454,
    'Teapa': 3454,
    'Tecoluta 2da. Sección': 3454,
    'Tecominoacán': 3454,
    'Tenosique': 3454,
    'Tepetitán': 3454,
    'Tequila 1ra. Sección (La Aurora)': 3454,
    'Transito Tular': 3454,
    'Tres Bocas 1ra. Sección': 3454,
    'Tucta': 3454,
    'Veinte de Noviembre': 3454,
    'Vicente Guerrero': 3454,
    'Villa Aldama': 3454,
    'Villa la Venta': 3454,
    'Villa Tecolutilla': 3454,
    'Villa Vicente Guerrero': 3454,
    'Villahermosa': 3454,
    'Xicoténcatl': 3454,
    'Abasolo': 3463,
    'Aldama': 3463,
    'Alfredo V. Bonfil': 3463,
    'Altamira': 3463,
    'Anáhuac': 3463,
    'Antiguo Morelos': 3463,
    'Barretal': 3463,
    'Burgos': 3463,
    'Bustamante': 3463,
    'Camargo': 3463,
    'Carboneras': 3463,
    'Carrillo Puerto': 3463,
    'Casas': 3463,
    'CEFERESO Número 3': 3463,
    'Ciudad Camargo': 3463,
    'Ciudad Gustavo Díaz Ordaz': 3463,
    'Ciudad Madero': 3463,
    'Ciudad Mante': 3463,
    'Ciudad Miguel Alemán': 3463,
    'Ciudad Río Bravo': 3463,
    'Ciudad Tula': 3463,
    'Ciudad Victoria': 3463,
    'Comales': 3463,
    'Cruillas': 3463,
    'Cuauhtémoc': 3463,
    'El Abra': 3463,
    'El Barrancón del Tío Blas (El Barrancón)': 3463,
    'El Campanario y Oradel': 3463,
    'El Control': 3463,
    'El Galaneño': 3463,
    'El Limón': 3463,
    'El Mante': 3463,
    'El Realito': 3463,
    'Estación Santa Engracia': 3463,
    'Esteros': 3463,
    'González': 3463,
    'Graciano Sánchez': 3463,
    'Guadalupe Victoria': 3463,
    'Guemes': 3463,
    'Güémez': 3463,
    'Guerrero': 3463,
    'Guillermo Zúñiga': 3463,
    'Gustavo Díaz Ordaz': 3463,
    'Heroica Matamoros': 3463,
    'Hidalgo': 3463,
    'Jaumave': 3463,
    'Jiménez': 3463,
    'La Colonia': 3463,
    'La Libertad': 3463,
    'La Misión': 3463,
    'La Pedrera': 3463,
    'La Pesca': 3463,
    'Las Higuerillas': 3463,
    'Lázaro Cárdenas': 3463,
    'Llera': 3463,
    'Llera de Canales': 3463,
    'Lomas del Real': 3463,
    'López Rayón': 3463,
    'Los Aztecas': 3463,
    'Los Cavazos': 3463,
    'Los Guerra': 3463,
    'Maclovio Herrera': 3463,
    'Magdaleno Cedillo': 3463,
    'Mainero': 3463,
    'Matamoros': 3463,
    'Méndez': 3463,
    'Mier': 3463,
    'Miguel Alemán': 3463,
    'Miquihuana': 3463,
    'Miramar': 3463,
    'Nicolás Bravo': 3463,
    'Nueva Apolonia': 3463,
    'Nueva Ciudad Guerrero': 3463,
    'Nueva Villa de Padilla': 3463,
    'Nuevo Cereso Regional de Altamira': 3463,
    'Nuevo Laredo': 3463,
    'Nuevo Morelos': 3463,
    'Nuevo Progreso': 3463,
    'Nuevo Tantoán': 3463,
    'Ocampo': 3463,
    'Padilla': 3463,
    'Palmillas': 3463,
    'Plan de Ayala': 3463,
    'Primero de Mayo': 3463,
    'Quintero': 3463,
    'Ramírez': 3463,
    'Reynosa': 3463,
    'Ricardo Flores Magón': 3463,
    'Río Bravo': 3463,
    'San Antonio Rayón': 3463,
    'San Carlos': 3463,
    'San Fernando': 3463,
    'San Germán': 3463,
    'San Nicolás': 3463,
    'Santa Adelaida': 3463,
    'Santa Apolonia': 3463,
    'Santander Jiménez': 3463,
    'Soto la Marina': 3463,
    'Tampico': 3463,
    'Tampiquito': 3463,
    'Tula': 3463,
    'Unidos Avanzamos': 3463,
    'Ursulo Galván': 3463,
    'Valadeces': 3463,
    'Valle Hermoso': 3463,
    'Vamos Tamaulipas': 3463,
    'Victoria': 3463,
    'Villa Mainero': 3463,
    'Villagrán': 3463,
    'Xicoténcatl': 3463,
    'Acopinalco del Peñón': 3458,
    'Acuamanala': 3458,
    'Acuitlapilco': 3458,
    'Acxotla del Monte': 3458,
    'Acxotla del Río': 3458,
    'Altzayanca': 3458,
    'Amaxac de Guerrero': 3458,
    'Apetatitlán Antonio Carbajal': 3458,
    'Apizaco': 3458,
    'Atexcatzingo': 3458,
    'Atlangatepec': 3458,
    'Atlzayanca': 3458,
    'Ayometitla': 3458,
    'Belén Atzitzimititlán': 3458,
    'Benito Juárez': 3458,
    'Calpulalpan': 3458,
    'Capula': 3458,
    'Ciudad de Nanacamilpa': 3458,
    'Colonia Cuauhtémoc': 3458,
    'Colonia Ignacio Allende': 3458,
    'Colonia San Isidro': 3458,
    'Colonia Venustiano Carranza': 3458,
    'Concepción Chimalpa': 3458,
    'Concepción Hidalgo': 3458,
    'Contla': 3458,
    'Cuapiaxtla': 3458,
    'Cuaxomulco': 3458,
    'El Carmen Aztama': 3458,
    'El Carmen Xalpatlahuaya': 3458,
    'El Rosario': 3458,
    'Emiliano Zapata': 3458,
    'Felipe Carrillo Puerto': 3458,
    'Fraccionamiento la Virgen': 3458,
    'Francisco I. Madero': 3458,
    'Francisco Villa': 3458,
    'Guadalupe Hidalgo': 3458,
    'Guadalupe Texcalac': 3458,
    'Guadalupe Tlachco': 3458,
    'Hualcaltzinco': 3458,
    'Huamantla': 3458,
    'Hueyotlipan': 3458,
    'Huiloapan': 3458,
    'Ignacio Zaragoza': 3458,
    'Jesús Huitznahuac': 3458,
    'Jesús Tepactepec': 3458,
    'José María Morelos': 3458,
    'La Aurora': 3458,
    'La Candelaria Teotlalpan': 3458,
    'La Magdalena Tlaltelulco': 3458,
    'La Soledad': 3458,
    'La Trinidad Chimalpa': 3458,
    'La Trinidad Tenexyecac': 3458,
    'Lagunilla': 3458,
    'Lázaro Cárdenas': 3458,
    'Los Pilares': 3458,
    'Los Reyes Quiahuixtlan': 3458,
    'Mazapa': 3458,
    'Mazatecochco': 3458,
    'Muñoz': 3458,
    'Natívitas': 3458,
    'Nicolás Bravo': 3458,
    'Panotla': 3458,
    'Papalotla': 3458,
    'Ranchería de Pocitos': 3458,
    'San Andrés Ahuashuatepec': 3458,
    'San Bartolomé Cuahuixmatlac': 3458,
    'San Bartolomé Tenango': 3458,
    'San Benito Xaltocan': 3458,
    'San Buenaventura Atempan': 3458,
    'San Cosme Atlamaxac': 3458,
    'San Damián Texoloc': 3458,
    'San Esteban Tizatlán': 3458,
    'San Felipe Sultepec': 3458,
    'San Francisco Cuexcontzi': 3458,
    'San Francisco Temetzontla': 3458,
    'San Francisco Tlacuilohcan': 3458,
    'San Hipólito Chimalpa': 3458,
    'San Isidro Buen Suceso': 3458,
    'San Jerónimo Zacualpan': 3458,
    'San Jorge Tezoquipan': 3458,
    'San José Atoyatenco': 3458,
    'San José Aztatla': 3458,
    'San José Cuamantzingo': 3458,
    'San José Teacalco': 3458,
    'San José Tepeyahualco': 3458,
    'San José Tetel': 3458,
    'San José Villarreal': 3458,
    'San José Xicohténcatl': 3458,
    'San Juan Quetzalcoapan': 3458,
    'San Lorenzo Axocomanitla': 3458,
    'San Lorenzo Sóltepec': 3458,
    'San Lorenzo Xaltelulco': 3458,
    'San Lucas Tecopilco': 3458,
    'San Lucas Tlacochcalco': 3458,
    'San Luis Apizaquito': 3458,
    'San Marcos Contla': 3458,
    'San Matías Tepetomatitlán': 3458,
    'San Miguel Contla': 3458,
    'San Miguel Tlamahuco': 3458,
    'San Miguel Xochitecatitla': 3458,
    'San Pedro Ecatepec': 3458,
    'San Pedro Muñoztla': 3458,
    'San Pedro Tlacotepec': 3458,
    'San Pedro Xalcaltzinco': 3458,
    'San Pedro Xochiteotla': 3458,
    'San Rafael Tenanyecac': 3458,
    'San Rafael Tepatlaxco': 3458,
    'San Simeón Xipetzingo': 3458,
    'San Simón Tlatlahuquitepec': 3458,
    'Sanctórum': 3458,
    'Santa Ana Chiautempan': 3458,
    'Santa Anita Huiloac': 3458,
    'Santa Apolonia Teacalco': 3458,
    'Santa Catarina Ayometla': 3458,
    'Santa Cruz Aquiahuac': 3458,
    'Santa Cruz Pocitos': 3458,
    'Santa Cruz Tetela': 3458,
    'Santa Isabel Xiloxoxtla': 3458,
    'Santa Justina Ecatepec': 3458,
    'Santa María Atlihuetzian': 3458,
    'Santa María Ixtulco': 3458,
    'Santa María Texcalac': 3458,
    'Santiago Michac': 3458,
    'Santiago Tepeticpac': 3458,
    'Santiago Tlacochcalco': 3458,
    'Santo Tomás la Concordia': 3458,
    'Tenancingo': 3458,
    'Teolocholco': 3458,
    'Tepetitla': 3458,
    'Tepeyanco': 3458,
    'Terrenate': 3458,
    'Tetla': 3458,
    'Tetlanohcán': 3458,
    'Tlatempan': 3458,
    'Tlaxcala': 3458,
    'Tlaxco': 3458,
    'Tocatlán': 3458,
    'Toluca de Guadalupe': 3458,
    'Topilco de Juárez': 3458,
    'Totolac': 3458,
    'Tzompantepec': 3458,
    'Unión Ejidal Tierra y Libertad': 3458,
    'Villa Alta': 3458,
    'Villa de El Carmen Tequexquitla': 3458,
    'Villa Vicente Guerrero': 3458,
    'Xaltocan': 3458,
    'Xicohtzinco': 3458,
    'Xocoyucan': 3458,
    'Yauhquemehcan': 3458,
    'Zacatelco': 3458,
    'Zaragoza': 3458,
    'Zimatepec': 3458,
    'Zitlaltépec': 3458,
    'Zumpango': 3458,
    'Abasolo del Valle': 3464,
    'Abrevadero': 3464,
    'Acajete': 3464,
    'Acatla': 3464,
    'Acatlán': 3464,
    'Acayucan': 3464,
    'Acazónica': 3464,
    'Achotal de Moreno': 3464,
    'Acontitla': 3464,
    'Actopan': 3464,
    'Acula': 3464,
    'Acultzingo': 3464,
    'Adolfo Moreno': 3464,
    'Adolfo Ruíz Cortines': 3464,
    'Agrícola Lázaro Cárdenas': 3464,
    'Agua Dulce': 3464,
    'Aguilera': 3464,
    'Ahuacatán': 3464,
    'Ahuateno': 3464,
    'Ahueyahualco': 3464,
    'Álamo': 3464,
    'Álamo Temapache': 3464,
    'Alborada': 3464,
    'Allende': 3464,
    'Almagres': 3464,
    'Alpatláhuac': 3464,
    'Alto Lucero': 3464,
    'Altotonga': 3464,
    'Alvarado': 3464,
    'Amatitlán': 3464,
    'Amatlán': 3464,
    'Amatlán de los Reyes': 3464,
    'Anahuac': 3464,
    'Angel R. Cabada': 3464,
    'Antón Lizardo': 3464,
    'Antonio J Bermúdez': 3464,
    'Apazapan': 3464,
    'Aquila': 3464,
    'Aquiles Serdán': 3464,
    'Arboledas': 3464,
    'Arbolillo': 3464,
    'Arenal Santa Ana': 3464,
    'Arroyo del Maíz Uno': 3464,
    'Arroyo del Potrero': 3464,
    'Arroyo Hondo': 3464,
    'Arroyo San Isidro': 3464,
    'Astacinga': 3464,
    'Atlahuilco': 3464,
    'Atoyac': 3464,
    'Atzacan': 3464,
    'Atzalan': 3464,
    'Axochío': 3464,
    'Ayahualulco': 3464,
    'Ayotuxtla': 3464,
    'Banderas': 3464,
    'Banderilla': 3464,
    'Bara de Chachalacas': 3464,
    'Barra de Cazones': 3464,
    'Barrancas': 3464,
    'Barrio de San Miguel': 3464,
    'Bella Esperanza': 3464,
    'Benito Juárez': 3464,
    'Blanca Espuma': 3464,
    'Boca del Monte': 3464,
    'Boca del Rio': 3464,
    'Buena Vista': 3464,
    'Buenavista': 3464,
    'Buenos Aires': 3464,
    'Buenos Aires (San Isidro)': 3464,
    'Cabezas': 3464,
    'Cacahuatal': 3464,
    'Calcahualco': 3464,
    'Calería': 3464,
    'Calichar Palma Sola': 3464,
    'Calzadas': 3464,
    'Camarón de Tejeda': 3464,
    'Camerino Z. Mendoza': 3464,
    'Campo Chico': 3464,
    'Campo de Águila': 3464,
    'Campo Grande': 3464,
    'Capoluca': 3464,
    'Caristay': 3464,
    'Carlos A. Carrillo': 3464,
    'Carrillo Puerto': 3464,
    'Carrizal': 3464,
    'Casitas': 3464,
    'Castillo de Teayo': 3464,
    'Catemaco': 3464,
    'Caxapa': 3464,
    'Cazones de Herrera': 3464,
    'Cecilio Terán': 3464,
    'Cementeras del Pital': 3464,
    'Centro de Readaptación Social': 3464,
    'Cerritos': 3464,
    'Cerro Azul': 3464,
    'Cerro Gordo': 3464,
    'Cerro Grande': 3464,
    'Cerro Guzmán': 3464,
    'Cerro las Iguanas': 3464,
    'Chacalapa': 3464,
    'Chacaltianguis': 3464,
    'Chalma': 3464,
    'Chapopote Chico': 3464,
    'Chapopote Núñez': 3464,
    'Chavarrillo': 3464,
    'Chavaxtla': 3464,
    'Chichicaxtle': 3464,
    'Chicola': 3464,
    'Chiconamel': 3464,
    'Chiconquiaco': 3464,
    'Chicontepec': 3464,
    'Chicualoque': 3464,
    'Chicuasen': 3464,
    'Chilapa': 3464,
    'Chiltoyac': 3464,
    'Chinameca': 3464,
    'Chinampa de Gorostiza': 3464,
    'Chocamán': 3464,
    'Chonegal': 3464,
    'Chontla': 3464,
    'Chumatlán': 3464,
    'Chuniapan de Arriba': 3464,
    'Citlaltépec': 3464,
    'Citlaltépetl': 3464,
    'Ciudad Cuauhtémoc': 3464,
    'Ciudad Mendoza': 3464,
    'Coacoatzintla': 3464,
    'Coacotla': 3464,
    'Coahuitlán': 3464,
    'Coatepec': 3464,
    'Coatzacoalcos': 3464,
    'Coatzintla': 3464,
    'Coetzala': 3464,
    'Colatlán': 3464,
    'Colipa': 3464,
    'Colonia Adolfo Ruiz Cortines (Colonia Obrera)': 3464,
    'Colonia Chalchihuecan': 3464,
    'Colonia el Renacimiento': 3464,
    'Colonia las Flores': 3464,
    'Colonia Lealtad': 3464,
    'Colonia Libertad': 3464,
    'Colonia Manuel González': 3464,
    'Colonia Santa Bárbara': 3464,
    'Colonia Úrsulo Galván': 3464,
    'Colonias Pedernales': 3464,
    'Colorines': 3464,
    'Comapa': 3464,
    'Comején': 3464,
    'Comoapan': 3464,
    'Congregación el Tajín': 3464,
    'Congregación Hidalgo': 3464,
    'Córdoba': 3464,
    'Córdoba (Santa Leticia)': 3464,
    'Corozal': 3464,
    'Corral Nuevo': 3464,
    'Corralillos': 3464,
    'Cosamaloapan': 3464,
    'Cosamaloapan de Carpio': 3464,
    'Cosautlán': 3464,
    'Cosautlán de Carvajal': 3464,
    'Coscomatepec de Bravo': 3464,
    'Cosolapa': 3464,
    'Cosoleacaque': 3464,
    'Cotaxtla': 3464,
    'Coxquihui': 3464,
    'Coyolito': 3464,
    'Coyutla': 3464,
    'Cruz Blanca': 3464,
    'Cruz del Milagro': 3464,
    'Cruz Verde': 3464,
    'Cuauhtamingo': 3464,
    'Cuautlapán': 3464,
    'Cucharas': 3464,
    'Cuesta Amarilla': 3464,
    'Cuesta de Laja': 3464,
    'Cuichapa': 3464,
    'Cuitláhuac': 3464,
    'Cuiyachapa': 3464,
    'Dehesa': 3464,
    'Delfino Victoria (Santa Fe)': 3464,
    'Doctor Montes de Oca': 3464,
    'Dos Bocas': 3464,
    'Dos Ríos': 3464,
    'Ejidal': 3464,
    'Ejido la Piña (Maromilla)': 3464,
    'El Aguacate': 3464,
    'El Águila': 3464,
    'El Anono': 3464,
    'El Arenal': 3464,
    'El Blanco': 3464,
    'El Castillo': 3464,
    'El Chico': 3464,
    'El Chote': 3464,
    'El Cocuite': 3464,
    'El Conejo': 3464,
    'El Corte': 3464,
    'El Escobillo': 3464,
    'El Espinal': 3464,
    'El Hatito': 3464,
    'El Higo': 3464,
    'El Huérfano': 3464,
    'El Huidero': 3464,
    'El Humo': 3464,
    'El Jobo': 3464,
    'El Juile': 3464,
    'El Laurel': 3464,
    'El Lencero': 3464,
    'El Lindero': 3464,
    'El Maguey': 3464,
    'El Mirador': 3464,
    'El Molino': 3464,
    'El Mollejon': 3464,
    'El Naranjito': 3464,
    'El Nigromante': 3464,
    'El Palmar': 3464,
    'El Panorama': 3464,
    'El Paraíso': 3464,
    'El Paraíso (La Charca)': 3464,
    'El Pital': 3464,
    'El Porvenir': 3464,
    'El Potrero': 3464,
    'El Progreso': 3464,
    'El Pueblito': 3464,
    'El Pueblito (Garbanzal)': 3464,
    'El Remolino': 3464,
    'El Salto de Eyipantla': 3464,
    'El Tejar': 3464,
    'El Terrero': 3464,
    'El Triunfo': 3464,
    'El Tulín': 3464,
    'El Volador': 3464,
    'El Zapote Bravo': 3464,
    'Elotepec': 3464,
    'Emiliano Zapata': 3464,
    'Entabladero': 3464,
    'Escolín de Olarte': 3464,
    'Espinal': 3464,
    'Estación Dobladero': 3464,
    'Estación Juanita': 3464,
    'Estanzuela': 3464,
    'Estero de Milpas': 3464,
    'Estero del Ídolo': 3464,
    'Ex-hacienda la Concepción': 3464,
    'Excola': 3464,
    'Felipe Carrillo Puerto': 3464,
    'Filomeno Mata': 3464,
    'Fortín de las Flores': 3464,
    'Fraccionamiento Arboledas San Ramón': 3464,
    'Fraccionamiento Ciudad Olmeca': 3464,
    'Fraccionamiento Costa Dorada': 3464,
    'Fraccionamiento Geovillas los Pinos': 3464,
    'Fraccionamiento la Florida': 3464,
    'Fraccionamiento las Fuentes': 3464,
    'Fraccionamiento los Álamos': 3464,
    'Fraccionamiento los Prados': 3464,
    'Fraccionamiento Santa Cruz': 3464,
    'Fraccionamiento Valle Dorado': 3464,
    'Fraccionamiento Villas de la Llave': 3464,
    'Francisco I. Madero': 3464,
    'Fredepo': 3464,
    'Gabino Barreda': 3464,
    'General Alatriste (San Joaquín)': 3464,
    'General Juan José Baz (San José del Corral)': 3464,
    'General Miguel Alemán': 3464,
    'Guadalupe': 3464,
    'Guadalupe Victoria': 3464,
    'Guayalejo': 3464,
    'Guillermo Prieto': 3464,
    'Gutiérrez Zamora': 3464,
    'Hacienda Sotavento': 3464,
    'Helio García Alfaro': 3464,
    'Hermanos Cedillo': 3464,
    'Hermenegildo J. Aldana': 3464,
    'Hidalgo Amajac': 3464,
    'Hidalgotitlán': 3464,
    'Hornitos': 3464,
    'Huatusco': 3464,
    'Huayacanes': 3464,
    'Huayacocotla': 3464,
    'Huazuntlán': 3464,
    'Huexotitla': 3464,
    'Hueyapan de Ocampo': 3464,
    'Hueytepec': 3464,
    'Huiloapan': 3464,
    'Huiloapan de Cuauhtémoc': 3464,
    'Huitzila': 3464,
    'Huixcolotla': 3464,
    'Ignacio de la Llave': 3464,
    'Ignacio Zaragoza': 3464,
    'Ilamatlán': 3464,
    'Isla': 3464,
    'Ixcapantla': 3464,
    'Ixcatepec': 3464,
    'Ixcatla': 3464,
    'Ixhuacán de los Reyes': 3464,
    'Ixhuapan': 3464,
    'Ixhuatlán de Madero': 3464,
    'Ixhuatlán del Café': 3464,
    'Ixhuatlán del Sureste': 3464,
    'Ixhuatlancillo': 3464,
    'Ixpila': 3464,
    'Ixtacapa el Chico': 3464,
    'Ixtaczoquitlán': 3464,
    'Jacarandas': 3464,
    'Jalacingo': 3464,
    'Jalapilla': 3464,
    'Jalcomulco': 3464,
    'Jáltipan de Morelos': 3464,
    'Jamapa': 3464,
    'Jesús Carranza': 3464,
    'Jilotepec': 3464,
    'Joachín': 3464,
    'José Cardel': 3464,
    'José F. Gutiérrez': 3464,
    'Juan Díaz Covarrubias': 3464,
    'Juan Jacobo Torres [Bodega de Totontepec]': 3464,
    'Juan Marcos (San José Buenavista)': 3464,
    'Juan Rodríguez Clara': 3464,
    'Juchique de Ferrer': 3464,
    'La Antigua': 3464,
    'La Camelia': 3464,
    'La Capilla': 3464,
    'La Cerquilla': 3464,
    'La Colonia Guadalupe': 3464,
    'La Concepción': 3464,
    'La Cuesta': 3464,
    'La Defensa': 3464,
    'La Gloria': 3464,
    'La Guadalupe': 3464,
    'La Guásima': 3464,
    'La Horqueta (Poblado Doce)': 3464,
    'La Isla': 3464,
    'La Isla (Kilómetro 10)': 3464,
    'La Isla de Chapachapa': 3464,
    'La Isla Km 10': 3464,
    'La Joya': 3464,
    'La Junta': 3464,
    'La Laguna': 3464,
    'La Laguna y Monte del Castillo': 3464,
    'La Libertad': 3464,
    'La Nueva Era': 3464,
    'La Nueva Victoria': 3464,
    'La Orduña': 3464,
    'La Palma': 3464,
    'La Palmilla': 3464,
    'La Perla': 3464,
    'La Providencia': 3464,
    'La Reforma': 3464,
    'La Sidra': 3464,
    'La Sombra': 3464,
    'La Tinaja': 3464,
    'La Toma': 3464,
    'La Unión': 3464,
    'La Unión Paso Largo': 3464,
    'La Victoria': 3464,
    'La Victoria (La Peñita)': 3464,
    'Laguna Chica (Pueblo Nuevo)': 3464,
    'Laguna de Farfán': 3464,
    'Laguneta': 3464,
    'Landero y Coss': 3464,
    'Las Amapolas': 3464,
    'Las Barrillas': 3464,
    'Las Choapas': 3464,
    'Las Higueras': 3464,
    'Las Lomas': 3464,
    'Las Lomas de Tacamichapan': 3464,
    'Las Minas': 3464,
    'Las Sabanetas': 3464,
    'Las Sabinas': 3464,
    'Las Trancas': 3464,
    'Las Vigas de Ramírez': 3464,
    'Lázaro Cárdenas (Santana)': 3464,
    'Lerdo de Tejada': 3464,
    'Limones': 3464,
    'Lindavista': 3464,
    'Llano de Enmedio': 3464,
    'Llano Grande': 3464,
    'Loma Angosta': 3464,
    'Loma de los Carmona': 3464,
    'Loma de Sogotegoyo': 3464,
    'Loma Grande': 3464,
    'Lomas de Barrillas': 3464,
    'Lomas de Río Medio Cuatro': 3464,
    'Lomas Verdes': 3464,
    'Los Altos': 3464,
    'Los Cerritos': 3464,
    'Los Ídolos': 3464,
    'Los Lirios': 3464,
    'Los Mangos': 3464,
    'Los Molinos': 3464,
    'Los Naranjos': 3464,
    'Los Pescados': 3464,
    'Los Pinos': 3464,
    'Los Reyes': 3464,
    'Los Robles': 3464,
    'Los Tigres (San Marcos)': 3464,
    'Los Torrentes': 3464,
    'Macedonio Alonso': 3464,
    'Mafafas': 3464,
    'Magdalena': 3464,
    'Mahuixtlan': 3464,
    'Maltrata': 3464,
    'Manuel León': 3464,
    'Manuel María Contreras': 3464,
    'Mapachapa': 3464,
    'María de la Torre': 3464,
    'Mariano Escobedo': 3464,
    'Martínez de la Torre': 3464,
    'Mata de Indio': 3464,
    'Mata Loma': 3464,
    'Mata Naranjo': 3464,
    'Mata Obscura': 3464,
    'Mata Tenatito (Casco Hacienda)': 3464,
    'Matacapan': 3464,
    'Mazumiapam': 3464,
    'Mecatlán': 3464,
    'Mecayapan': 3464,
    'Medellín': 3464,
    'Medellín de Bravo': 3464,
    'Medias Aguas': 3464,
    'Melchor Ocampo': 3464,
    'Mequetla': 3464,
    'Mesa de Guadalupe': 3464,
    'Metlac Hernández (Metlac Primero)': 3464,
    'Mexcala': 3464,
    'Miahuatlán': 3464,
    'Miguel Hidalgo': 3464,
    'Minatitlán': 3464,
    'Minzapan': 3464,
    'Misantla': 3464,
    'Mixquiapan': 3464,
    'Mixtla de Altamirano': 3464,
    'Moloacán': 3464,
    'Monte Blanco': 3464,
    'Monte Salas': 3464,
    'Monte Verde Chivería': 3464,
    'Moralillo': 3464,
    'Morelos': 3464,
    'Motzorongo': 3464,
    'Moyoapan': 3464,
    'Mozomboa': 3464,
    'Mundo Nuevo': 3464,
    'Nanchital de Lázaro Cárdenas del Río': 3464,
    'Naolinco': 3464,
    'Naolinco de Victoria': 3464,
    'Naranjal': 3464,
    'Naranjos': 3464,
    'Naranjos Amatlán': 3464,
    'Nautla': 3464,
    'Necoxtla': 3464,
    'Nicolás Blanco (San Pancho)': 3464,
    'Niños Héroes': 3464,
    'Nogales': 3464,
    'Nopalapan': 3464,
    'Nopaltepec': 3464,
    'Novara': 3464,
    'Nuevo Progreso': 3464,
    'Nuevo San José Independencia': 3464,
    'Oasis': 3464,
    'Ocelota': 3464,
    'Ocotal Chico': 3464,
    'Ocotitlán': 3464,
    'Ocozotepec': 3464,
    'Ohuapan': 3464,
    'Ohuilapam': 3464,
    'Ojite Rancho Nuevo': 3464,
    'Oluta': 3464,
    'Omealca': 3464,
    'Orilla del Monte': 3464,
    'Orizaba': 3464,
    'Otates': 3464,
    'Otatitlán': 3464,
    'Oteapan': 3464,
    'Otilpan': 3464,
    'Oviedo': 3464,
    'Ozuluama de Mascareñas': 3464,
    'Pacho Nuevo': 3464,
    'Pacho Viejo': 3464,
    'Pahua Hueca': 3464,
    'Pajapan': 3464,
    'Palma Sola': 3464,
    'Palmar Grande': 3464,
    'Palmas de Abajo': 3464,
    'Palmillas': 3464,
    'Palmira': 3464,
    'Palo Bendito': 3464,
    'Palo Gacho': 3464,
    'Pánuco': 3464,
    'Papantla de Olarte': 3464,
    'Paraiso Novillero': 3464,
    'Paraje Nuevo': 3464,
    'Paso de Ovejas': 3464,
    'Paso de Valencia': 3464,
    'Paso del Correo': 3464,
    'Paso del Macho': 3464,
    'Paso del Toro': 3464,
    'Paso Nacional': 3464,
    'Peñuela': 3464,
    'Perote': 3464,
    'Perseverancia': 3464,
    'Piedra Parada': 3464,
    'Piedra Pinta': 3464,
    'Piedras Negras': 3464,
    'Piletas': 3464,
    'Pisaflores': 3464,
    'Plan de Arroyos': 3464,
    'Plan de Ayala': 3464,
    'Plan de Iguala': 3464,
    'Plan de las Hayas': 3464,
    'Plan del Río': 3464,
    'Platón Sánchez': 3464,
    'Playa de Chachalacas': 3464,
    'Playa de la Libertad': 3464,
    'Playa Vicente': 3464,
    'Poblado 10': 3464,
    'Poblado Cinco': 3464,
    'Poblado Dos': 3464,
    'Poblado Tres': 3464,
    'Polutla': 3464,
    'Potrero del Llano': 3464,
    'Potrero Viejo': 3464,
    'Poza Rica de Hidalgo': 3464,
    'Presidio': 3464,
    'Presidio (Plan de Libres)': 3464,
    'Primero de Mayo': 3464,
    'Progreso de Zaragoza': 3464,
    'Pueblillo': 3464,
    'Pueblo Viejo': 3464,
    'Puente de Piedra': 3464,
    'Puente Jula': 3464,
    'Puerto Esmeralda': 3464,
    'Puntilla Aldama': 3464,
    'Quiamoloapan': 3464,
    'Rafael Delgado': 3464,
    'Rafael Lucio': 3464,
    'Rancho Alegre': 3464,
    'Rancho del Padre': 3464,
    'Rancho Nuevo': 3464,
    'Rancho Viejo': 3464,
    'Residencial las Olas': 3464,
    'Residencial Tajín': 3464,
    'Ricardo Flores Magón': 3464,
    'Rincón de Barrabás': 3464,
    'Rincón de Buena Vista': 3464,
    'Rinconada': 3464,
    'Río Blanco': 3464,
    'Río Medio [Granja]': 3464,
    'Rodríguez Tejeda': 3464,
    'Sabanas de Xalostoc': 3464,
    'Sabaneta': 3464,
    'Saladero': 3464,
    'Salinas': 3464,
    'Saltabarranca': 3464,
    'Saltillo': 3464,
    'Salvador Díaz Mirón': 3464,
    'San Agustín del Palmar': 3464,
    'San Andrés Tenejapan': 3464,
    'San Andrés Tuxtla': 3464,
    'San Antonio Xoquitla': 3464,
    'San Cristóbal': 3464,
    'San Fernando': 3464,
    'San Francisco': 3464,
    'San Francisco (Mata Clara)': 3464,
    'San Francisco Nacaxtle': 3464,
    'San Isidro': 3464,
    'San Isidro el Berro': 3464,
    'San Isidro Palotal': 3464,
    'San Isidro Xoteapan (San Isidro Texcaltitán)': 3464,
    'San José de Abajo': 3464,
    'San José de Abajo [Unidad Habitacional]': 3464,
    'San José de Tapia': 3464,
    'San José Independencia': 3464,
    'San José Neria': 3464,
    'San José Súchil': 3464,
    'San José Tenejapa': 3464,
    'San Juan de los Reyes (Luis Valenzuela)': 3464,
    'San Juan Evangelista': 3464,
    'San Juan Seco de Valencia': 3464,
    'San Juan Volador': 3464,
    'San Julián': 3464,
    'San Leoncio Jamaya': 3464,
    'San Lorenzo Tenochtitlán': 3464,
    'San Marcos Atesquilapan': 3464,
    'San Marcos de León': 3464,
    'San Miguel Mecatepec': 3464,
    'San Miguel Tlalpoalán': 3464,
    'San Pedro Coyutla': 3464,
    'San Pedro Mártir': 3464,
    'San Pedro Tlanixco': 3464,
    'San Pedro Tlapacoyan': 3464,
    'San Rafael': 3464,
    'San Rafael Calería': 3464,
    'San Rafael Río Seco': 3464,
    'San Sebastián': 3464,
    'Santa Catalina': 3464,
    'Santa Isabel': 3464,
    'Santa Lucía Potrerillo': 3464,
    'Santa María Tatetla': 3464,
    'Santa Rita': 3464,
    'Santa Rosa': 3464,
    'Santa Rosa Abata': 3464,
    'Santa Rosa Loma Larga': 3464,
    'Santa Teresa': 3464,
    'Santiago': 3464,
    'Santiago de la Peña': 3464,
    'Santiago Sochiapan': 3464,
    'Santiago Tuxtla': 3464,
    'Sasaltitla': 3464,
    'Sayula de Alemán': 3464,
    'Sierra de Agua': 3464,
    'Sihuapan': 3464,
    'Sinapan': 3464,
    'Sochiapa': 3464,
    'Soconusco': 3464,
    'Soledad Atzompa': 3464,
    'Soledad de Doblado': 3464,
    'Sonora': 3464,
    'Sontecomapan': 3464,
    'Soteapan': 3464,
    'Soyata': 3464,
    'Suchilapan del Río': 3464,
    'Sumidero': 3464,
    'Tamalín': 3464,
    'Tamiahua': 3464,
    'Tampico Alto': 3464,
    'Tancoco': 3464,
    'Tantima': 3464,
    'Tantoyuca': 3464,
    'Tapalapan': 3464,
    'Tatahuicapan': 3464,
    'Tatatila': 3464,
    'Taza de Agua Ojo Zarco': 3464,
    'Teayo': 3464,
    'Tecama': 3464,
    'Tecamalucan': 3464,
    'Tecolapan': 3464,
    'Tecolutla': 3464,
    'Tehuipango': 3464,
    'Temapache': 3464,
    'Tempoal': 3464,
    'Tempoal de Sánchez': 3464,
    'Tenampa': 3464,
    'Tenantitla': 3464,
    'Tenenexpan': 3464,
    'Tenixtepec': 3464,
    'Tenochtitlán': 3464,
    'Teocelo': 3464,
    'Tepatlaxco': 3464,
    'Tepetlán': 3464,
    'Tepetzintla': 3464,
    'Tequila': 3464,
    'Tesechoacan': 3464,
    'Teteltzingo': 3464,
    'Tetla': 3464,
    'Tetlatzinga': 3464,
    'Tetlaxco': 3464,
    'Tetzacual': 3464,
    'Texalpan de Abajo': 3464,
    'Texalpan de Arriba': 3464,
    'Texcaltitán Xoteapan (Texcaltitán)': 3464,
    'Texcatepec': 3464,
    'Texhuacán': 3464,
    'Texin': 3464,
    'Texistepec': 3464,
    'Texmola': 3464,
    'Tezonapa': 3464,
    'Tierra Blanca': 3464,
    'Tierra Blanca Booxter': 3464,
    'Tierra Colorada': 3464,
    'Tierra Nueva': 3464,
    'Tihuatlan': 3464,
    'Tilapan': 3464,
    'Tinajitas': 3464,
    'Tincontlán': 3464,
    'Tlachichilco': 3464,
    'Tlacojalpan': 3464,
    'Tlacolula': 3464,
    'Tlacolulan': 3464,
    'Tlacotalpan': 3464,
    'Tlacotepec de Mejía': 3464,
    'Tlacuilolapan': 3464,
    'Tlalconteno': 3464,
    'Tlalixcoyan': 3464,
    'Tlalnelhuayocan': 3464,
    'Tlaltetela': 3464,
    'Tlamatoca': 3464,
    'Tlapacoyan': 3464,
    'Tlapala': 3464,
    'Tlatilpa': 3464,
    'Tlatzala': 3464,
    'Tlilapan': 3464,
    'Tocuila': 3464,
    'Tolome': 3464,
    'Tomatlán': 3464,
    'Tonalá': 3464,
    'Tonalaco': 3464,
    'Tonalapan': 3464,
    'Tonalixco': 3464,
    'Tonayán': 3464,
    'Totolapa': 3464,
    'Totutla': 3464,
    'Tozongo': 3464,
    'Trapiche del Rosario': 3464,
    'Trapiche Viejo': 3464,
    'Tres Valles': 3464,
    'Tres Zapotes': 3464,
    'Tronconal': 3464,
    'Tula': 3464,
    'Tulapam': 3464,
    'Túxpam de Rodríguez Cano': 3464,
    'Tuxpan': 3464,
    'Tuxpanguillo': 3464,
    'Tuxtilla': 3464,
    'Tuzamapan': 3464,
    'Tzicatlán': 3464,
    'Unión y Progreso': 3464,
    'Úrsulo Galván': 3464,
    'Uxpanapa': 3464,
    'Valente Diaz': 3464,
    'Vargas': 3464,
    'Vega de Alatorre': 3464,
    'Vegas de la Soledad y Soledad Dos': 3464,
    'Veinte de Noviembre': 3464,
    'Veintiuno de Agosto': 3464,
    'Venustiano Carranza (Peña Blanca)': 3464,
    'Veracruz': 3464,
    'Vicente Guerrero': 3464,
    'Vicente Herrera': 3464,
    'Villa Aldama': 3464,
    'Villa Azueta': 3464,
    'Villa de las Flores': 3464,
    'Villa Emiliano Zapata': 3464,
    'Villa Emilio Carranza': 3464,
    'Villa Independencia': 3464,
    'Villa Lázaro Cárdenas': 3464,
    'Villa Unión': 3464,
    'Villa Zempoala': 3464,
    'Vista Hermosa': 3464,
    'Xalapa de Enríquez': 3464,
    'Xalatlaco': 3464,
    'Xico': 3464,
    'Xoampolco': 3464,
    'Xochimilco': 3464,
    'Xococapa': 3464,
    'Xocotla': 3464,
    'Xopilapa': 3464,
    'Xoteapan': 3464,
    'Xoxocotla': 3464,
    'Yanga': 3464,
    'Yecuatla': 3464,
    'Zacamixtle': 3464,
    'Zacate Colorado': 3464,
    'Zacate Colorado Segundo (Fraternidad)': 3464,
    'Zacatla': 3464,
    'Zacualpan': 3464,
    'Zamora': 3464,
    'Zapoapan': 3464,
    'Zapoapan de Amapan': 3464,
    'Zapoapan de Cabañas': 3464,
    'Zaragoza': 3464,
    'Zentla': 3464,
    'Zongolica': 3464,
    'Zontecomatlán de López y Fuentes': 3464,
    'Zozocolco de Hidalgo': 3464,
    'Abala': 3466,
    'Acanceh': 3466,
    'Akil': 3466,
    'Baca': 3466,
    'Becanchén': 3466,
    'Bokoba': 3466,
    'Bolón': 3466,
    'Buctzotz': 3466,
    'Cacalchen': 3466,
    'Calcehtoc': 3466,
    'Calotmul': 3466,
    'Campestre Flamboyanes': 3466,
    'Cansahcab': 3466,
    'Cantamayec': 3466,
    'Caucel': 3466,
    'Celestún': 3466,
    'Cenotillo': 3466,
    'Cepeda': 3466,
    'Chablekal': 3466,
    'Chacsinkin': 3466,
    'Chan Cenote': 3466,
    'Chankom': 3466,
    'Chapab': 3466,
    'Chelem': 3466,
    'Chemax': 3466,
    'Chichén-Itzá': 3466,
    'Chichimila': 3466,
    'Chicxulub Pueblo': 3466,
    'Chicxulub Puerto': 3466,
    'Chikindzonot': 3466,
    'Chochola': 3466,
    'Cholul': 3466,
    'Cholul Cantón': 3466,
    'Chuburná': 3466,
    'Chumayel': 3466,
    'Chunchucmil': 3466,
    'Citilcum': 3466,
    'Colonia Yucatán': 3466,
    'Conkal': 3466,
    'Cuch Holoch': 3466,
    'Cuncunul': 3466,
    'Cuzama': 3466,
    'Dzan': 3466,
    'Dzemul': 3466,
    'Dzibikak': 3466,
    'Dzidzantun': 3466,
    'Dzilam de Bravo': 3466,
    'Dzilam González': 3466,
    'Dzitás': 3466,
    'Dzitnup': 3466,
    'Dzityá': 3466,
    'Dzoncauich': 3466,
    'Dzonot Carretero': 3466,
    'Dzununcan': 3466,
    'Ekmul': 3466,
    'Ekpedz': 3466,
    'El Cuyo': 3466,
    'Emiliano Zapata': 3466,
    'Espita': 3466,
    'Euan': 3466,
    'Halachó': 3466,
    'Hocaba': 3466,
    'Hoctun': 3466,
    'Holca': 3466,
    'Homun': 3466,
    'Huhi': 3466,
    'Hunucmá': 3466,
    'Hunuku': 3466,
    'Itzincab Palomeque': 3466,
    'Ixil': 3466,
    'Izamal': 3466,
    'Kanasín': 3466,
    'Kancab': 3466,
    'Kantunil': 3466,
    'Kanxoc': 3466,
    'Kaua': 3466,
    'Kimbila': 3466,
    'Kinchil': 3466,
    'Kini': 3466,
    'Kinil': 3466,
    'Kochol': 3466,
    'Komchén': 3466,
    'Kopoma': 3466,
    'Las Coloradas': 3466,
    'Leona Vicario': 3466,
    'Lepan': 3466,
    'Libre Unión': 3466,
    'Loché': 3466,
    'Mama': 3466,
    'Mani': 3466,
    'Maxcanú': 3466,
    'Mayapan': 3466,
    'Mérida': 3466,
    'Mococha': 3466,
    'Molas': 3466,
    'Motul': 3466,
    'Muna': 3466,
    'Muxupip': 3466,
    'Nacuche': 3466,
    'Nolo': 3466,
    'Opichen': 3466,
    'Oxcum': 3466,
    'Oxkutzcab': 3466,
    'Oxkutzkab': 3466,
    'Panabá': 3466,
    'Pencuyut': 3466,
    'Peto': 3466,
    'Piste': 3466,
    'Pixoy': 3466,
    'Popola': 3466,
    'Popolnah': 3466,
    'Progreso': 3466,
    'Pustunich': 3466,
    'Quintana Roo': 3466,
    'Río Lagartos': 3466,
    'Sacalum': 3466,
    'Sahcaba': 3466,
    'Samahil': 3466,
    'San Antonio Tedzidz': 3466,
    'San Francisco Grande': 3466,
    'San José Tzal': 3466,
    'San Pedro Chimay': 3466,
    'San Rafael': 3466,
    'Sanahcat': 3466,
    'Santa Elena': 3466,
    'Santa María Acú': 3466,
    'Santo Domingo': 3466,
    'Seyé': 3466,
    'Sierra Papacal': 3466,
    'Sinanche': 3466,
    'Sisal': 3466,
    'Sitilpech': 3466,
    'Sitpach': 3466,
    'Sotuta': 3466,
    'Sucilá': 3466,
    'Sucopó': 3466,
    'Sudzal': 3466,
    'Suma': 3466,
    'Suma de Hidalgo': 3466,
    'Tahdzibichen': 3466,
    'Tahdziu': 3466,
    'Tahmek': 3466,
    'Teabo': 3466,
    'Tecax': 3466,
    'Tecoh': 3466,
    'Tekal de Venegas': 3466,
    'Tekanto': 3466,
    'Tekax': 3466,
    'Tekik de Regil': 3466,
    'Tekit': 3466,
    'Tekoh': 3466,
    'Tekom': 3466,
    'Telchac Pueblo': 3466,
    'Telchac Puerto': 3466,
    'Telchaquillo': 3466,
    'Temax': 3466,
    'Temozon': 3466,
    'Tepakan': 3466,
    'Tesoco': 3466,
    'Tetiz': 3466,
    'Texan de Palomeque': 3466,
    'Teya': 3466,
    'Tibolón': 3466,
    'Ticopó': 3466,
    'Ticul': 3466,
    'Tiholop': 3466,
    'Tikuch': 3466,
    'Timucuy': 3466,
    'Tinum': 3466,
    'Tixcacalcupul': 3466,
    'Tixcacaltuyub': 3466,
    'Tixcancal': 3466,
    'Tixhualactún': 3466,
    'Tixkokob': 3466,
    'Tixmehuac': 3466,
    'Tixpehual': 3466,
    'Tizimín': 3466,
    'Tunkas': 3466,
    'Tzucacab': 3466,
    'Uayalceh de Peón': 3466,
    'Uayma': 3466,
    'Uci': 3466,
    'Ucú': 3466,
    'Uman': 3466,
    'Valladolid': 3466,
    'X-Cán': 3466,
    'Xanabá': 3466,
    'Xaya': 3466,
    'Xcanatún': 3466,
    'Xocchel': 3466,
    'Xocen': 3466,
    'Xohuayan': 3466,
    'Xul': 3466,
    'Yalkoba': 3466,
    'Yaxcabá': 3466,
    'Yaxcopoil': 3466,
    'Yaxhachen': 3466,
    'Yaxkukul': 3466,
    'Yobain': 3466,
    'Yotholin': 3466,
    'Agua Gorda': 3462,
    'Altamira': 3462,
    'Apozol': 3462,
    'Atolinga': 3462,
    'Bajío de San Nicolás': 3462,
    'Buenavista de Trujillo': 3462,
    'Cañas': 3462,
    'Cañitas de Felipe Pescador': 3462,
    'Cantuna': 3462,
    'Carrillo': 3462,
    'Casa de Cerros': 3462,
    'Cedros': 3462,
    'Cerrito de la Cruz': 3462,
    'Chalchihuites': 3462,
    'Chaparrosa': 3462,
    'Charco Blanco': 3462,
    'Chichimequillas': 3462,
    'Chupaderos': 3462,
    'Cicacalco': 3462,
    'Cieneguillas': 3462,
    'Cieneguitas': 3462,
    'Colonia Felipe Ángeles': 3462,
    'Colonia Francisco García Salinas': 3462,
    'Colonia Hidalgo (El Tecolote)': 3462,
    'Colonia José María Morelos': 3462,
    'Colonia Montemariana (Colonia Mariana)': 3462,
    'Colonia Plenitud': 3462,
    'Colonia San Francisco (San Francisco)': 3462,
    'Concepción del Oro': 3462,
    'Crisóstomos': 3462,
    'Ejido Hidalgo': 3462,
    'Ejido Zaragoza': 3462,
    'El Cazadero': 3462,
    'El Copetillo': 3462,
    'El Fuerte': 3462,
    'El Lampotal': 3462,
    'El Nigromante': 3462,
    'El Obraje': 3462,
    'El Plateado de Joaquín Amaro': 3462,
    'El Refugio': 3462,
    'El Rucio': 3462,
    'El Salto': 3462,
    'El Salvador': 3462,
    'El Saucito (El Horno)': 3462,
    'El Sitio': 3462,
    'El Tule': 3462,
    'Emiliano Zapata': 3462,
    'Emilio Carranza': 3462,
    'Ermita de Guadalupe': 3462,
    'Estación Camacho': 3462,
    'Estación San José': 3462,
    'Estancia de Ánimas': 3462,
    'Estancia de Guadalupe': 3462,
    'Florencia': 3462,
    'Fresnillo': 3462,
    'General Enrique Estrada': 3462,
    'General Juan José Ríos': 3462,
    'General Lauro G. Caloca (El Rascón)': 3462,
    'General Pánfilo Natera': 3462,
    'Gonzales Ortega': 3462,
    'Granadas': 3462,
    'Guadalupe': 3462,
    'Guanajuatillo': 3462,
    'Hacienda Nueva': 3462,
    'Hacienda Toribio': 3462,
    'Hidalgo': 3462,
    'Ignacio Zaragoza': 3462,
    'J. Jesús González Ortega (San Mateo)': 3462,
    'Jalpa': 3462,
    'Jaula de Abajo': 3462,
    'Jerez de García Salinas': 3462,
    'Jiménez del Teul': 3462,
    'José María Morelos': 3462,
    'José María Morelos (Santa Mónica)': 3462,
    'José María Morelos y Pavón': 3462,
    'Juan Aldama': 3462,
    'Juchipila': 3462,
    'La Ballena': 3462,
    'La Capilla': 3462,
    'La Concepción': 3462,
    'La Florida': 3462,
    'La Laguna': 3462,
    'La Luz': 3462,
    'La Pendencia': 3462,
    'La Tesorera': 3462,
    'La Victoria': 3462,
    'La Zacatecana': 3462,
    'Laguna Seca': 3462,
    'Las Catarinas': 3462,
    'Las Esperanzas (El Ranchito)': 3462,
    'Las Pilas': 3462,
    'Lázaro Cárdenas (Rancho Grande)': 3462,
    'Lobatos': 3462,
    'Loreto': 3462,
    'Los Condes': 3462,
    'Los Ramírez': 3462,
    'Luis Moya': 3462,
    'Malpaso': 3462,
    'Maravillas': 3462,
    'Martínez Domínguez': 3462,
    'Mazapil': 3462,
    'Mezquital del Oro': 3462,
    'Miguel Auza': 3462,
    'Milagros': 3462,
    'Momax': 3462,
    'Monte Escobedo': 3462,
    'Morelos': 3462,
    'Moyahua de Estrada': 3462,
    'Nieves': 3462,
    'Nochistlán de Mejía': 3462,
    'Noria de Ángeles': 3462,
    'Ojitos': 3462,
    'Paso de Méndez': 3462,
    'Pastelera': 3462,
    'Pastoría': 3462,
    'Pedregoso': 3462,
    'Piedra Gorda': 3462,
    'Pinos': 3462,
    'Plateros': 3462,
    'Pozo de Jarillas': 3462,
    'Pozos de Gamboa': 3462,
    'Rafael Yáñez Sosa (El Mezquite)': 3462,
    'Rancho Nuevo': 3462,
    'Rancho Nuevo de Morelos (De Guadalupe)': 3462,
    'Rio de Medina': 3462,
    'Río Florido': 3462,
    'Río Grande': 3462,
    'Sain Alto': 3462,
    'Sain Bajo': 3462,
    'San Antonio del Cipres': 3462,
    'San Blas': 3462,
    'San Cristóbal': 3462,
    'San Jerónimo': 3462,
    'San José de Castellanos': 3462,
    'San José de la Era': 3462,
    'San Jose de Lourdes': 3462,
    'San José el Saladillo (El Saladillo)': 3462,
    'San Marcos': 3462,
    'San Martin': 3462,
    'San Pablo': 3462,
    'San Pedro Piedra Gorda': 3462,
    'Santa Ana': 3462,
    'Santa Elena': 3462,
    'Santa María de los Ángeles': 3462,
    'Santa Mónica': 3462,
    'Santa Rita': 3462,
    'Santiago': 3462,
    'Santiaguillo': 3462,
    'Sauceda de La Borda': 3462,
    'Seis de Enero': 3462,
    'Susticacán': 3462,
    'Tabasco': 3462,
    'Tacoaleche': 3462,
    'Tapias de Santa Cruz (Pedro Ruiz González)': 3462,
    'Tayahua': 3462,
    'Tepechitlán': 3462,
    'Tepetongo': 3462,
    'Terminal de Providencia': 3462,
    'Tetillas': 3462,
    'Teúl de González Ortega': 3462,
    'Tierra Blanca': 3462,
    'Tlachichila': 3462,
    'Tlaltenango de Sánchez Román': 3462,
    'Trancoso': 3462,
    'Trinidad García de la Cadena': 3462,
    'Valparaíso': 3462,
    'Víctor Rosales': 3462,
    'Villa de Cos': 3462,
    'Villa García': 3462,
    'Villa González Ortega': 3462,
    'Villa Hidalgo': 3462,
    'Villa Insurgentes (El Calabazal)': 3462,
    'Villanueva': 3462,
    'Zacatecas': 3462,
    'Zoquite': 3462,
    'Eot Municipality': 2580,
    'Ettal Municipality': 2580,
    'Fananu Municipality': 2580,
    'Fanapanges Municipality': 2580,
    'Fefen Municipality': 2580,
    'Fonoton Municipality': 2580,
    'Houk Municipality': 2580,
    'Kuttu Municipality': 2580,
    'Lekinioch Municipality': 2580,
    'Losap Municipality': 2580,
    'Makur Municipality': 2580,
    'Moch Municipality': 2580,
    'Murilo': 2580,
    'Murilo Municipality': 2580,
    'Namoluk Municipality': 2580,
    'Nema': 2580,
    'Nema Municipality': 2580,
    'Nomwin Municipality': 2580,
    'Oneop Municipality': 2580,
    'Onou Municipality': 2580,
    'Onoun Municipality': 2580,
    'Paata-Tupunion Municipality': 2580,
    'Parem Municipality': 2580,
    'Piherarh Municipality': 2580,
    'Piis-Emwar Municipality': 2580,
    'Piis-Panewu Municipality': 2580,
    'Pollap Municipality': 2580,
    'Polowat Municipality': 2580,
    'Pwene Municipality': 2580,
    'Ramanum Municipality': 2580,
    'Ruo Municipality': 2580,
    'Satowan Municipality': 2580,
    'Siis Municipality': 2580,
    'Ta Municipality': 2580,
    'Tamatam Municipality': 2580,
    'Tolensom Municipality': 2580,
    'Tonoas Municipality': 2580,
    'Udot-Fonuweisom Municipality': 2580,
    'Uman-Fonuweisom Municipality': 2580,
    'Unanu Municipality': 2580,
    'Weno': 2580,
    'Weno Municipality': 2580,
    'Wonei Municipality': 2580,
    'Lelu Municipality': 2583,
    'Malem Municipality': 2583,
    'Tafunsak Municipality': 2583,
    'Tofol': 2583,
    'Utwe Municipality': 2583,
    'Kitti Municipality': 2581,
    'Kolonia': 2581,
    'Kolonia Municipality': 2581,
    'Kolonia Town': 2581,
    'Madolenihm Municipality': 2581,
    'Mokil Municipality': 2581,
    'Nett Municipality': 2581,
    'Ngatik': 2581,
    'Nukuoro Municipality': 2581,
    'Palikir - National Government Center': 2581,
    'Pingelap Municipality': 2581,
    'Sapwuahfik Municipality': 2581,
    'Sokehs Municipality': 2581,
    'U Municipality': 2581,
    'Colonia': 2582,
    'Dalipebinaw Municipality': 2582,
    'Fais': 2582,
    'Fais Municipality': 2582,
    'Fanif Municipality': 2582,
    'Faraulep Municipality': 2582,
    'Gagil Municipality': 2582,
    'Gilman Municipality': 2582,
    'Kanifay Municipality': 2582,
    'Lamotrek Municipality': 2582,
    'Maap Municipality': 2582,
    'Ngulu Municipality': 2582,
    'Rull Municipality': 2582,
    'Rumung Municipality': 2582,
    'Satawal Municipality': 2582,
    'Tomil Municipality': 2582,
    'Ulithi Municipality': 2582,
    'Weloy Municipality': 2582,
    'Woleai Municipality': 2582,
    'Anenii Noi': 4368,
    'Varniţa': 4368,
    'Bălţi': 4393,
    'Basarabeasca': 4379,
    'Bender': 4362,
    'Briceni': 4375,
    'Cahul': 4391,
    'Giurgiuleşti': 4391,
    'Călăraşi': 4366,
    'Cantemir': 4380,
    'Iargara': 4380,
    'Vişniovca': 4380,
    'Căuşeni': 4365,
    'Chiţcani': 4365,
    'Chisinau': 4373,
    'Ciorescu': 4373,
    'Cricova': 4373,
    'Sîngera': 4373,
    'Stăuceni': 4373,
    'Vadul lui Vodă': 4373,
    'Vatra': 4373,
    'Cimişlia': 4360,
    'Criuleni': 4390,
    'Briceni': 4384,
    'Donduşeni': 4384,
    'Drochia': 4392,
    'Cocieri': 4383,
    'Ustia': 4383,
    'Edineţ': 4387,
    'Fălești': 4381,
    'Floreşti': 4370,
    'Ghindești': 4370,
    'Mărculeşti': 4370,
    'Bugeac': 4385,
    'Ceadîr-Lunga': 4385,
    'Comrat': 4385,
    'Vulcăneşti': 4385,
    'Glodeni': 4367,
    'Dancu': 4382,
    'Hînceşti': 4382,
    'Ialoveni': 4369,
    'Nisporeni': 4363,
    'Ocniţa': 4389,
    'Otaci': 4389,
    'Orhei': 4361,
    'Rezina': 4394,
    'Saharna': 4394,
    'Rîşcani': 4376,
    'Bilicenii Vechi': 4364,
    'Biruinţa': 4364,
    'Sîngerei': 4364,
    'Şoldăneşti': 4388,
    'Soroca': 4374,
    'Ştefan Vodă': 4378,
    'Bucovăţ': 4377,
    'Strășeni': 4377,
    'Taraclia': 4372,
    'Tvardița': 4372,
    'Mîndreşti': 4371,
    'Teleneşti': 4371,
    'Camenca': 4395,
    'Crasnoe': 4395,
    'Dnestrovsc': 4395,
    'Dubăsari': 4395,
    'Hryhoriopol': 4395,
    'Maiac': 4395,
    'Pervomaisc': 4395,
    'Rîbniţa': 4395,
    'Slobozia': 4395,
    'Tiraspol': 4395,
    'Tiraspolul Nou': 4395,
    'Ungheni': 4386,
    'Tsetserleg': 1973,
    'Altay': 1969,
    'Ölgii': 1969,
    'Tsengel': 1969,
    'Bayanhongor': 1976,
    'Bulgan': 1961,
    'Darhan': 1962,
    'Choibalsan': 1963,
    'Ereencav': 1963,
    'Mandalgovi': 1970,
    'Altai': 1972,
    'Choyr': 1978,
    'Undurkhaan': 1974,
    'Khovd': 1964,
    'Möst': 1964,
    'Üyönch': 1964,
    'Hanh': 1975,
    'Murun-kuren': 1975,
    'Tsengel': 1975,
    'Turt': 1975,
    'Dalandzadgad': 1967,
    'Hanhongor': 1967,
    'Nomgon Sum': 1967,
    'Erdenet': 1966,
    'Arvayheer': 1965,
    'Harhorin': 1965,
    'Hovd': 1965,
    'Kharkhorin': 1965,
    'Nariynteel': 1965,
    'Dzüünharaa': 1980,
    'Sühbaatar': 1980,
    'Baruun-Urt': 1977,
    'Dzuunmod': 1968,
    'Möngönmorĭt': 1968,
    'Ulaanhudag': 1968,
    'Зуунмод': 1968,
    'Ulaangom': 1971,
    'Uliastay': 1979,
    'Andrijevica': 23,
    'Bar': 13,
    'Stari Bar': 13,
    'Šušanj': 13,
    'Sutomore': 13,
    'Berane': 21,
    'Bijelo Polje': 25,
    'Budva': 30,
    'Petrovac na Moru': 30,
    'Danilovgrad': 14,
    'Spuž': 14,
    'Gusinje': 24,
    'Kolašin': 31,
    'Dobrota': 26,
    'Kotor': 26,
    'Prčanj': 26,
    'Risan': 26,
    'Mojkovac': 22,
    'Nikšić': 17,
    'Cetinje': 28,
    'Plav': 19,
    'Pljevlja': 20,
    'Plužine': 16,
    'Golubovci': 27,
    'Goričani': 27,
    'Mataguži': 27,
    'Mojanovići': 27,
    'Podgorica': 27,
    'Tuzi': 27,
    'Rožaje': 15,
    'Šavnik': 18,
    'Tivat': 29,
    'Ulcinj': 33,
    'Žabljak': 32,
    'Agadir': 3297,
    'Agadir Melloul': 3297,
    'Agadir-Ida-ou-Tnan': 3297,
    'Aoulouz': 3297,
    'Aourir': 3297,
    'Arazane': 3297,
    'Argana': 3297,
    'Bigoudine': 3297,
    'Chtouka-Ait-Baha': 3297,
    'Inezgane': 3297,
    'Inezgane-Ait Melloul': 3297,
    'Ouijjane': 3297,
    'Oulad Teïma': 3297,
    'Reggada': 3297,
    'Sidi Ifni': 3297,
    'Tadrart': 3297,
    'Tafraout': 3297,
    'Taghazout': 3297,
    'Taliouine': 3297,
    'Tamri': 3297,
    'Tanalt': 3297,
    'Taroudannt': 3297,
    'Taroudant': 3297,
    'Tarsouat': 3297,
    'Tata': 3297,
    'Tiznit': 3297,
    'Azemmour': 3303,
    'Benslimane': 3303,
    'Berrechid': 3303,
    'Berrechid Province': 3303,
    'Boulaouane': 3303,
    'Bouskoura': 3303,
    'Bouznika': 3303,
    'Casablanca': 3303,
    'El Jadid': 3303,
    'El-Jadida': 3303,
    'Mediouna': 3303,
    'Mohammedia': 3303,
    'Nouaceur': 3303,
    'Oualidia': 3303,
    'Oulad Frej': 3303,
    'Settat': 3303,
    'Settat Province': 3303,
    'Sidi Bennour': 3303,
    'Sidi Smai’il': 3303,
    'Tit Mellil': 3303,
    'Zawyat an Nwaçer': 3303,
    'Agdz': 3290,
    'Alnif': 3290,
    'Aoufous': 3290,
    'Arfoud': 3290,
    'Errachidia': 3290,
    'Imilchil': 3290,
    'Jebel Tiskaouine': 3290,
    'Jorf': 3290,
    'Kelaat Mgouna': 3290,
    'Mhamid': 3290,
    'Midelt': 3290,
    'Ouarzazat': 3290,
    'Ouarzazate': 3290,
    'Reçani': 3290,
    'Taznakht': 3290,
    'Telouet': 3290,
    'Tinghir': 3290,
    'Tinghir Province': 3290,
    'Zagora': 3290,
    'Aïn Leuh': 3313,
    'Aknoul': 3313,
    'Almis Marmoucha': 3313,
    'Azrou': 3313,
    'Bhalil': 3313,
    'Bouarouss': 3313,
    'Boulemane': 3313,
    'El Hajeb': 3313,
    'El-Hajeb': 3313,
    'Fes': 3313,
    'Fès al Bali': 3313,
    'Galaz': 3313,
    'Ghouazi': 3313,
    'Guercif': 3313,
    'Ifrane': 3313,
    'Meknes': 3313,
    'Missour': 3313,
    'Moulay Bouchta': 3313,
    'Moulay-Yacoub': 3313,
    'Oued Amlil': 3313,
    'Oulad Tayeb': 3313,
    'Ourtzagh': 3313,
    'Sefrou': 3313,
    'Tahla': 3313,
    'Talzemt': 3313,
    'Taounate': 3313,
    'Taza': 3313,
    'Tmourghout': 3313,
    'Assa-Zag': 3305,
    'Guelmim': 3305,
    'Sidi Ifni': 3305,
    'Tan-Tan': 3305,
    'Arbaoua': 3308,
    'Had Kourt': 3308,
    'Kenitra': 3308,
    'Kenitra Province': 3308,
    'Khemisset': 3308,
    'Mechraa Bel Ksiri': 3308,
    'Oulmes': 3308,
    'Rabat': 3308,
    'Sale': 3308,
    'Sidi Bousber': 3308,
    'Sidi Qacem': 3308,
    'Sidi Redouane': 3308,
    'Sidi Slimane': 3308,
    'Sidi Yahia El Gharb': 3308,
    'Sidi-Kacem': 3308,
    'Skhirate': 3308,
    'Skhirate-Temara': 3308,
    'Souq Larb’a al Gharb': 3308,
    'Temara': 3308,
    'Teroual': 3308,
    'Tiflet': 3308,
    'Aguelmous': 3317,
    'Al Fqih Ben Çalah': 3317,
    'Azilal': 3317,
    'Azilal Province': 3317,
    'Beni Mellal': 3317,
    'Beni-Mellal': 3317,
    'Boujniba': 3317,
    'Bzou': 3317,
    'Dar Ould Zidouh': 3317,
    'Demnate': 3317,
    'El Ksiba': 3317,
    'Fquih Ben Salah Province': 3317,
    'Ifrane': 3317,
    'Isseksi': 3317,
    'Itzer': 3317,
    'Kasba Tadla': 3317,
    'Kerrouchen': 3317,
    'Khenifra': 3317,
    'Khouribga': 3317,
    'Khouribga Province': 3317,
    'Midelt': 3317,
    'Ouaoula': 3317,
    'Oued Zem': 3317,
    'Sidi Jaber': 3317,
    'Timoulilt': 3317,
    'Zawyat ech Cheïkh': 3317,
    'Akhfennir': 3298,
    'Boujdour': 3298,
    'Es-Semara': 3298,
    'Gueltat Zemmour': 3298,
    'Laayoune': 3298,
    'Smara': 3298,
    'Tarfaya': 3298,
    'Ahfir': 3271,
    'Aïn Beni Mathar': 3271,
    'Al Aaroui': 3271,
    'Berkane': 3271,
    'Bouarfa': 3271,
    'Debdou': 3271,
    'Driouch Province': 3271,
    'El Aïoun': 3271,
    'Figuig': 3271,
    'Guercif Province': 3271,
    'Jerada': 3271,
    'Madagh': 3271,
    'Midar': 3271,
    'Nador': 3271,
    'Oujda-Angad': 3271,
    'Saidia': 3271,
    'Selouane': 3271,
    'Taourirt': 3271,
    'Tiztoutine': 3271,
    'Zaïo': 3271,
    'Aousserd': 3319,
    'Imlili': 3319,
    'Oued-Ed-Dahab': 3319,
    'Abadou': 3311,
    'Adassil': 3311,
    'Al-Haouz': 3311,
    'Bouabout': 3311,
    'Chichaoua': 3311,
    'Essaouira': 3311,
    'Kelaa-Des-Sraghna': 3311,
    'Marrakech': 3311,
    'Marrakesh': 3311,
    'Oukaïmedene': 3311,
    'Rehamna': 3311,
    'Safi': 3311,
    'Setti Fatma': 3311,
    'Sidi Rahhal': 3311,
    'Smimou': 3311,
    'Tamanar': 3311,
    'Taouloukoult': 3311,
    'Tidili Mesfioua': 3311,
    'Timezgadiouine': 3311,
    'Youssoufia': 3311,
    'Zerkten': 3311,
    'Al Hoceïma': 3324,
    'Al-Hoceima': 3324,
    'Asilah': 3324,
    'Bni Bouayach': 3324,
    'Brikcha': 3324,
    'Cap Negro II': 3324,
    'Chefchaouen Province': 3324,
    'Chefchaouene': 3324,
    'Derdara': 3324,
    'Fahs-Anjra': 3324,
    'Fnidek': 3324,
    'Imzouren': 3324,
    'Ksar El Kebir': 3324,
    'Larache': 3324,
    'M  Diq-Fnideq': 3324,
    'Martil': 3324,
    'Oued Laou': 3324,
    'Ouezzane': 3324,
    'Ouezzane Province': 3324,
    'Senada': 3324,
    'Tamorot': 3324,
    'Tanger-Assilah': 3324,
    'Tangier': 3324,
    'Targuist': 3324,
    'Tetouan': 3324,
    'Tirhanimîne': 3324,
    'Zinat': 3324,
    'Zoumi': 3324,
    'Chiure': 3327,
    'Mocímboa': 3327,
    'Montepuez': 3327,
    'Pemba': 3327,
    'Chibuto': 3329,
    'Chokwé': 3329,
    'Macia': 3329,
    'Xai-Xai': 3329,
    'Inhambane': 3330,
    'Maxixe': 3330,
    'Chimoio': 3337,
    'KaTembe': 3335,
    'Maputo': 3335,
    'Boane District': 3332,
    'Concelho de Matola': 3332,
    'Magude District': 3332,
    'Manhica': 3332,
    'Marracuene District': 3332,
    'Matola': 3332,
    'Matutiune District': 3332,
    'Moamba District': 3332,
    'Namaacha District': 3332,
    'Ressano Garcia': 3332,
    'António Enes': 3336,
    'Ilha de Moçambique': 3336,
    'Mutuáli': 3336,
    'Nacala': 3336,
    'Nampula': 3336,
    'Cuamba': 3333,
    'Lichinga': 3333,
    'Mandimba': 3333,
    'Beira': 3331,
    'Dondo': 3331,
    'Nhamatanda District': 3331,
    'Tete': 3334,
    'Alto Molócuè': 3328,
    'Chinde': 3328,
    'Quelimane': 3328,
    'Bogale': 2142,
    'Hinthada': 2142,
    'Kyaiklat': 2142,
    'Maubin': 2142,
    'Mawlamyinegyunn': 2142,
    'Myanaung': 2142,
    'Nyaungdon': 2142,
    'Pathein': 2142,
    'Pyapon': 2142,
    'Wakema': 2142,
    'Bago': 2141,
    'Letpandan': 2141,
    'Nyaunglebin': 2141,
    'Paungde': 2141,
    'Pyay': 2141,
    'Pyu': 2141,
    'Taungoo': 2141,
    'Thanatpin': 2141,
    'Tharyarwady': 2141,
    'Falam': 2137,
    'Hakha': 2137,
    'Bhamo': 2143,
    'Myitkyina': 2143,
    'Loikaw': 2144,
    'Dellok': 2133,
    'Hpa-An': 2133,
    'Klonhtoug': 2133,
    'Kyain Seikgyi Township': 2133,
    'Mikenaungea': 2133,
    'Myawadi': 2133,
    'Pulei': 2133,
    'Tagondaing': 2133,
    'Tamoowoug': 2133,
    'Chauk': 2136,
    'Magway': 2136,
    'Minbu': 2136,
    'Myaydo': 2136,
    'Pakokku': 2136,
    'Taungdwingyi': 2136,
    'Thayetmyo': 2136,
    'Yenangyaung': 2136,
    'Kyaukse': 2134,
    'Mandalay': 2134,
    'Meiktila': 2134,
    'Mogok': 2134,
    'Myingyan': 2134,
    'Nyaungshwe': 2134,
    'Pyin Oo Lwin': 2134,
    'Yamethin': 2134,
    'Kyaikkami': 2147,
    'Kyaikto': 2147,
    'Martaban': 2147,
    'Mawlamyine': 2147,
    'Mudon': 2147,
    'Thaton': 2147,
    'Nay Pyi Taw': 2146,
    'Pyinmana': 2146,
    'Sittwe': 2138,
    'Mawlaik': 2145,
    'Monywa': 2145,
    'Sagaing': 2145,
    'Shwebo': 2145,
    'Lashio': 2139,
    'Tachilek': 2139,
    'Taunggyi': 2139,
    'Dawei': 2140,
    'Kawthoung': 2140,
    'Myeik': 2140,
    'Kanbe': 2135,
    'Kayan': 2135,
    'Syriam': 2135,
    'Thongwa': 2135,
    'Twante': 2135,
    'Yangon': 2135,
    'Arandis': 43,
    'Hentiesbaai': 43,
    'Karibib': 43,
    'Omaruru': 43,
    'Otjimbingwe': 43,
    'Swakopmund': 43,
    'Swakopmund Constituency': 43,
    'Usakos': 43,
    'Walvis Bay': 43,
    'Aranos': 38,
    'Hoachanas': 38,
    'Maltahöhe': 38,
    'Mariental': 38,
    'Rehoboth': 38,
    'Bethanie': 45,
    'Karasburg': 45,
    'Keetmanshoop': 45,
    'Lüderitz': 45,
    'Oranjemund': 45,
    'Tses': 45,
    'Warmbad': 45,
    'Rundu': 36,
    'Katutura': 44,
    'Windhoek': 44,
    'Epupa Constituency': 34,
    'Khorixas': 34,
    'Khorixas Constituency': 34,
    'Opuwo': 34,
    'Opuwo Constituency': 34,
    'Outjo': 34,
    'Sesfontein Constituency': 34,
    'Oshikango': 40,
    'Gobabis': 41,
    'Okahao': 39,
    'Ongandjera': 39,
    'Outapi': 39,
    'Ondangwa': 37,
    'Ongwediva': 37,
    'Oshakati': 37,
    'Omuthiya': 42,
    'Tsumeb': 42,
    'Grootfontein': 46,
    'Okahandja': 46,
    'Okakarara': 46,
    'Otavi': 46,
    'Otjiwarongo': 46,
    'Bagani': 47,
    'Katima Mulilo': 47,
    'Arijejen': 4656,
    'Anabar': 4658,
    'Baiti': 4660,
    'Ijuw': 4661,
    'Menen': 4657,
    'Uaboe': 4655,
    'Yaren': 4664,
    'Banepā': 2073,
    'Bharatpur': 2073,
    'Birgañj': 2073,
    'Dhulikhel': 2073,
    'Gaur': 2073,
    'Hari Bdr Tamang House': 2073,
    'Hetauda': 2073,
    'Jaleshwar': 2073,
    'Janakpur': 2073,
    'kankrabari Dovan': 2073,
    'Kathmandu': 2073,
    'Kirtipur': 2073,
    'Madhyapur Thimi': 2073,
    'Malaṅgawā': 2073,
    'Nagarkot': 2073,
    'Panauti': 2073,
    'Pātan': 2073,
    'Rāmechhāp': 2073,
    'Bhadrapur': 2069,
    'Bhojpur': 2069,
    'Biratnagar': 2069,
    'Dhankutā': 2069,
    'Dharān': 2069,
    'Ilām': 2069,
    'Inaruwa': 2069,
    'Khanbari': 2069,
    'Lahān': 2069,
    'Lobujya': 2069,
    'Namche Bazar': 2069,
    'Rājbirāj': 2069,
    'Siraha': 2069,
    'Titahari': 2069,
    'Triyuga': 2069,
    'Dadeldhurā': 2068,
    'Dhangaḍhi̇̄': 2068,
    'Dipayal': 2068,
    'Mahendranagar': 2068,
    'Ṭikāpur': 2068,
    'Bhojpur': 2066,
    'Birendranagar': 2066,
    'Dailekh': 2066,
    'Gulariyā': 2066,
    'Jumla': 2066,
    'Nepalgunj': 2066,
    'Tulsīpur': 2066,
    'Bāglung': 2067,
    'Bhattarai Danda': 2067,
    'Butwāl': 2067,
    'Chitre': 2067,
    'Dārchulā': 2067,
    'Dihi': 2067,
    'Pokhara': 2067,
    'Siddharthanagar': 2067,
    'Tānsen': 2067,
    'Wāliṅ': 2067,
    'Aalden': 2613,
    'Angelslo': 2613,
    'Annen': 2613,
    'Assen': 2613,
    'Ballast': 2613,
    'Barger-Oosterveld': 2613,
    'Bargeres': 2613,
    'Beilen': 2613,
    'Borger': 2613,
    'Coevorden': 2613,
    'Dalen': 2613,
    'De Loo': 2613,
    'De Wijk': 2613,
    'Diever': 2613,
    'Dwingeloo': 2613,
    'Eelde': 2613,
    'Eext': 2613,
    'Emmen': 2613,
    'Emmer-Compascuum': 2613,
    'Emmer-Erfscheidenveen': 2613,
    'Emmerhout': 2613,
    'Emmermeer': 2613,
    'Emmerschans': 2613,
    'Exloo': 2613,
    'Gasselte': 2613,
    'Gasselternijveen': 2613,
    'Gemeente Aa en Hunze': 2613,
    'Gemeente Assen': 2613,
    'Gemeente Borger-Odoorn': 2613,
    'Gemeente Coevorden': 2613,
    'Gemeente De Wolden': 2613,
    'Gemeente Emmen': 2613,
    'Gemeente Hoogeveen': 2613,
    'Gemeente Meppel': 2613,
    'Gemeente Midden-Drenthe': 2613,
    'Gemeente Noordenveld': 2613,
    'Gemeente Tynaarlo': 2613,
    'Gemeente Westerveld': 2613,
    'Gieten': 2613,
    'Havelte': 2613,
    'Hoogeveen': 2613,
    'Klazienaveen': 2613,
    'Koekange': 2613,
    'Krakeel': 2613,
    'Marsdijk': 2613,
    'Meppel': 2613,
    'Nieuw-Buinen': 2613,
    'Nieuw-Dordrecht': 2613,
    'Nieuw-Roden': 2613,
    'Noordbarge': 2613,
    'Norg': 2613,
    'Odoorn': 2613,
    'Oosterhesselen': 2613,
    'Paterswolde': 2613,
    'Peelo': 2613,
    'Peize': 2613,
    'Roden': 2613,
    'Rolde': 2613,
    'Ruinen': 2613,
    'Ruinerwold': 2613,
    'Schoonebeek': 2613,
    'Schoonoord': 2613,
    'Sleen': 2613,
    'Tweede Exloërmond': 2613,
    'Tynaarlo': 2613,
    'Uffelte': 2613,
    'Valthe': 2613,
    'Valthermond': 2613,
    'Veenoord': 2613,
    'Vledder': 2613,
    'Vries': 2613,
    'Weiteveen': 2613,
    'Westerbork': 2613,
    'Westlaren': 2613,
    'Wolfsbos': 2613,
    'Zuidlaren': 2613,
    'Almere Stad': 2619,
    'Biddinghuizen': 2619,
    'Dronten': 2619,
    'Emmeloord': 2619,
    'Ens': 2619,
    'Gemeente Almere': 2619,
    'Gemeente Dronten': 2619,
    'Gemeente Lelystad': 2619,
    'Gemeente Noordoostpolder': 2619,
    'Gemeente Urk': 2619,
    'Gemeente Zeewolde': 2619,
    'Lelystad': 2619,
    'Urk': 2619,
    'Zeewolde': 2619,
    'Akkrum': 2622,
    'Aldeboarn': 2622,
    'Aldlân-Oost': 2622,
    'Appelscha': 2622,
    'Augustinusga': 2622,
    'Bakhuizen': 2622,
    'Bakkeveen': 2622,
    'Balk': 2622,
    'Beetsterzwaag': 2622,
    'Berltsum': 2622,
    'Bilgaard': 2622,
    'Bolsward': 2622,
    'Boornbergum': 2622,
    'Broeksterwâld': 2622,
    'Buitenpost': 2622,
    'Burdaard': 2622,
    'Burgum': 2622,
    'Camminghaburen': 2622,
    'Damwâld': 2622,
    'De Domp': 2622,
    'De Drait': 2622,
    'De Fryske Marren': 2622,
    'De Greiden': 2622,
    'De Knipe': 2622,
    'De Westereen': 2622,
    'Dokkum': 2622,
    'Donkerbroek': 2622,
    'Drachten': 2622,
    'Drachtstercompagnie': 2622,
    'Drogeham': 2622,
    'Dronryp': 2622,
    'Eastermar': 2622,
    'Echtenerbrug': 2622,
    'Feanwâlden': 2622,
    'Ferwert': 2622,
    'Franeker': 2622,
    'Garyp': 2622,
    'Gemeente Achtkarspelen': 2622,
    'Gemeente Ameland': 2622,
    'Gemeente Dantumadiel': 2622,
    'Gemeente Harlingen': 2622,
    'Gemeente Heerenveen': 2622,
    'Gemeente Leeuwarden': 2622,
    'Gemeente Ooststellingwerf': 2622,
    'Gemeente Opsterland': 2622,
    'Gemeente Schiermonnikoog': 2622,
    'Gemeente Smallingerland': 2622,
    'Gemeente Terschelling': 2622,
    'Gemeente Tytsjerksteradiel': 2622,
    'Gemeente Vlieland': 2622,
    'Gemeente Weststellingwerf': 2622,
    'Gorredijk': 2622,
    'Goutum': 2622,
    'Grou': 2622,
    'Gytsjerk': 2622,
    'Hallum': 2622,
    'Harkema': 2622,
    'Harlingen': 2622,
    'Haulerwijk': 2622,
    'Heechterp': 2622,
    'Heeg': 2622,
    'Heerenveen': 2622,
    'Hoek': 2622,
    'Hollum': 2622,
    'Holwerd': 2622,
    'Huizum': 2622,
    'Hurdegaryp': 2622,
    'IJlst': 2622,
    'Jirnsum': 2622,
    'Joure': 2622,
    'Jubbega': 2622,
    'Kollum': 2622,
    'Kollumerzwaag': 2622,
    'Kootstertille': 2622,
    'Koudum': 2622,
    'Langweer': 2622,
    'Leeuwarden': 2622,
    'Lemmer': 2622,
    'Makkum': 2622,
    'Mantgum': 2622,
    'Marrum': 2622,
    'Marsum': 2622,
    'Menaam': 2622,
    'Minnertsga': 2622,
    'Nes': 2622,
    'Nieuwehorne': 2622,
    'Noardburgum': 2622,
    'Noordwolde': 2622,
    'Oentsjerk': 2622,
    'Oldeberkoop': 2622,
    'Oost-Vlieland': 2622,
    'Oosterwolde': 2622,
    'Opeinde': 2622,
    'Oppenhuizen': 2622,
    'Oranjewoud': 2622,
    'Oudega': 2622,
    'Oudehaske': 2622,
    'Oudemirdum': 2622,
    'Oudeschoot': 2622,
    'Rottevalle': 2622,
    'Scharnegoutum': 2622,
    'Schiermonnikoog': 2622,
    'Sexbierum': 2622,
    'Sint Annaparochie': 2622,
    'Sint Jacobiparochie': 2622,
    'Sint Nicolaasga': 2622,
    'Sintjohannesga': 2622,
    'Sneek': 2622,
    'Stadsfenne': 2622,
    'Stiens': 2622,
    'Sûdwest Fryslân': 2622,
    'Surhuisterveen': 2622,
    'Ternaard': 2622,
    'Tijnje': 2622,
    'Tinga': 2622,
    'Twijzel': 2622,
    'Twijzelerheide': 2622,
    'Tytsjerk': 2622,
    'Tzummarum': 2622,
    'Ureterp': 2622,
    'Waadhoeke': 2622,
    'Warga': 2622,
    'West-Terschelling': 2622,
    'Westeinde': 2622,
    'Wijnjewoude': 2622,
    'Wirdum': 2622,
    'Witmarsum': 2622,
    'Wolvega': 2622,
    'Wommels': 2622,
    'Workum': 2622,
    'Woudsend': 2622,
    'Zuiderburen': 2622,
    '  s-Heerenberg': 2611,
    'Aalst': 2611,
    'Aalten': 2611,
    'Afferden': 2611,
    'Alverna': 2611,
    'Ammerzoden': 2611,
    'Andelst': 2611,
    'Angeren': 2611,
    'Angerlo': 2611,
    'Anklaar': 2611,
    'Apeldoorn': 2611,
    'Arnhem': 2611,
    'Babberich': 2611,
    'Bakenberg': 2611,
    'Barneveld': 2611,
    'Beek gem Montferland': 2611,
    'Beekbergen': 2611,
    'Beesd': 2611,
    'Beltrum': 2611,
    'Bemmel': 2611,
    'Bennekom': 2611,
    'Berg en Bos': 2611,
    'Berg en Dal': 2611,
    'Bergharen': 2611,
    'Berkelland': 2611,
    'Beuningen': 2611,
    'Beusichem': 2611,
    'Binnenstad': 2611,
    'Borculo': 2611,
    'Brakel': 2611,
    'Brakkenstein': 2611,
    'Bredevoort': 2611,
    'Bredeweg': 2611,
    'Brinkhorst': 2611,
    'Bruchem': 2611,
    'Brummelhof': 2611,
    'Brummen': 2611,
    'Burgemeesterswijk': 2611,
    'Componistenkwartier': 2611,
    'Corlaer': 2611,
    'Culemborg': 2611,
    'De Bouwhof': 2611,
    'De Haven': 2611,
    'De Heeze': 2611,
    'De Mheen': 2611,
    'Deest': 2611,
    'Deil': 2611,
    'Dieren': 2611,
    'Doesburg': 2611,
    'Doetinchem': 2611,
    'Doornenburg': 2611,
    'Doornspijk': 2611,
    'Doornsteeg': 2611,
    'Doorwerth': 2611,
    'Driehuizen': 2611,
    'Driel': 2611,
    'Drumpt': 2611,
    'Druten': 2611,
    'Duiven': 2611,
    'Ede': 2611,
    'Ederveen': 2611,
    'Eefde': 2611,
    'Eerbeek': 2611,
    'Eibergen': 2611,
    'Elburg': 2611,
    'Elden': 2611,
    'Ellecom': 2611,
    'Elspeet': 2611,
    'Elst': 2611,
    'Emst': 2611,
    'Epe': 2611,
    'Epse': 2611,
    'Ermelo': 2611,
    'Etten': 2611,
    'Gameren': 2611,
    'Garderen': 2611,
    'Geitenkamp': 2611,
    'Geldermalsen': 2611,
    'Geldermalsen-West': 2611,
    'Gemeente Aalten': 2611,
    'Gemeente Apeldoorn': 2611,
    'Gemeente Arnhem': 2611,
    'Gemeente Barneveld': 2611,
    'Gemeente Berg en Dal': 2611,
    'Gemeente Beuningen': 2611,
    'Gemeente Bronckhorst': 2611,
    'Gemeente Brummen': 2611,
    'Gemeente Buren': 2611,
    'Gemeente Culemborg': 2611,
    'Gemeente Doesburg': 2611,
    'Gemeente Doetinchem': 2611,
    'Gemeente Druten': 2611,
    'Gemeente Duiven': 2611,
    'Gemeente Ede': 2611,
    'Gemeente Elburg': 2611,
    'Gemeente Epe': 2611,
    'Gemeente Ermelo': 2611,
    'Gemeente Harderwijk': 2611,
    'Gemeente Hattem': 2611,
    'Gemeente Heerde': 2611,
    'Gemeente Heumen': 2611,
    'Gemeente Lingewaard': 2611,
    'Gemeente Lochem': 2611,
    'Gemeente Maasdriel': 2611,
    'Gemeente Montferland': 2611,
    'Gemeente Neder-Betuwe': 2611,
    'Gemeente Nijkerk': 2611,
    'Gemeente Nijmegen': 2611,
    'Gemeente Nunspeet': 2611,
    'Gemeente Oldebroek': 2611,
    'Gemeente Overbetuwe': 2611,
    'Gemeente Putten': 2611,
    'Gemeente Renkum': 2611,
    'Gemeente Rheden': 2611,
    'Gemeente Rozendaal': 2611,
    'Gemeente Scherpenzeel': 2611,
    'Gemeente Tiel': 2611,
    'Gemeente Voorst': 2611,
    'Gemeente Wageningen': 2611,
    'Gemeente West Maas en Waal': 2611,
    'Gemeente Westervoort': 2611,
    'Gemeente Wijchen': 2611,
    'Gemeente Winterswijk': 2611,
    'Gemeente Zaltbommel': 2611,
    'Gemeente Zevenaar': 2611,
    'Gemeente Zutphen': 2611,
    'Gendringen': 2611,
    'Gendt': 2611,
    'Giesbeek': 2611,
    'Gorssel': 2611,
    'Groesbeek': 2611,
    'Groessen': 2611,
    'Gulden Bodem': 2611,
    'Haaften': 2611,
    'Haalderen': 2611,
    'Harderwijk': 2611,
    'Harskamp': 2611,
    'Hatert': 2611,
    'Hattem': 2611,
    'Hattemerbroek': 2611,
    'Hedel': 2611,
    'Heelsum': 2611,
    'Heerde': 2611,
    'Heerewaarden': 2611,
    'Hees': 2611,
    'Hengelo': 2611,
    'Hengstdal': 2611,
    'Herwijnen': 2611,
    'Het Loo': 2611,
    'Heteren': 2611,
    'Heumen': 2611,
    'Hoogkamp': 2611,
    'Horssen': 2611,
    'Hummelo': 2611,
    'Hunnerberg': 2611,
    'Ingen': 2611,
    'Keijenborg': 2611,
    'Kerkdriel': 2611,
    'Kerschoten': 2611,
    'Kesteren': 2611,
    'Kootwijkerbroek': 2611,
    'Laren': 2611,
    'Leesten': 2611,
    'Lent': 2611,
    'Leuth': 2611,
    'Lichtenvoorde': 2611,
    'Lienden': 2611,
    'Lindenholt': 2611,
    'Lobith': 2611,
    'Lochem': 2611,
    'Loenen': 2611,
    'Lunteren': 2611,
    'Malburgen West': 2611,
    'Matendonk': 2611,
    'Matendreef': 2611,
    'Matengaarde': 2611,
    'Matenhoeve': 2611,
    'Matenhorst': 2611,
    'Matenveld': 2611,
    'Maurik': 2611,
    'Meteren': 2611,
    'Nederhemert': 2611,
    'Nederhemert-Noord': 2611,
    'Nederwoud': 2611,
    'Neede': 2611,
    'Nijkerk': 2611,
    'Nijkerkerveen': 2611,
    'Nijmegen': 2611,
    'Nunspeet': 2611,
    'Ochten': 2611,
    'Oldebroek': 2611,
    'Ooij': 2611,
    'Oost Gelre': 2611,
    'Oostendorp': 2611,
    'Oosterbeek': 2611,
    'Oosterhout': 2611,
    'Ooy': 2611,
    'Ooyerhoek': 2611,
    'Ophemert': 2611,
    'Opheusden': 2611,
    'Opijnen': 2611,
    'Orden': 2611,
    'Otterlo': 2611,
    'Oude IJsselstreek': 2611,
    'Overasselt': 2611,
    'Pannerden': 2611,
    'Puiflijk': 2611,
    'Putten': 2611,
    'Renkum': 2611,
    'Rheden': 2611,
    'Rivierenkwartier': 2611,
    'Rossum': 2611,
    'Rozendaal': 2611,
    'Ruurlo': 2611,
    'Scherpenzeel': 2611,
    'Silvolde': 2611,
    'Sluisoord': 2611,
    'Spainkbos': 2611,
    'Sprengenbos': 2611,
    'Sprengenweg-Noord': 2611,
    'Sprenkelaar': 2611,
    'Staatsliedenkwartier': 2611,
    'Steenderen': 2611,
    'Stegeslag': 2611,
    'Sterrenberg': 2611,
    'Terborg': 2611,
    'Terschuur': 2611,
    'Terwolde': 2611,
    'Tiel': 2611,
    'Tolkamer': 2611,
    'Tricht': 2611,
    'Twello': 2611,
    'Uddel': 2611,
    'Ugchelen': 2611,
    'Vaassen': 2611,
    'Valburg': 2611,
    'Valendries': 2611,
    'Varsseveld': 2611,
    'Velp': 2611,
    'Vogelkwartier': 2611,
    'Voorst': 2611,
    'Voorthuizen': 2611,
    'Vorden': 2611,
    'Vredenburg': 2611,
    'Vuren': 2611,
    'Waardenburg': 2611,
    'Wageningen': 2611,
    'Wapenveld': 2611,
    'Warnsveld': 2611,
    'Wekerom': 2611,
    'Welgelegen': 2611,
    'West Betuwe': 2611,
    'Westeneng': 2611,
    'Westenenk': 2611,
    'Westervoort': 2611,
    'Wijchen': 2611,
    'Winkewijert': 2611,
    'Winterswijk': 2611,
    'Wisch': 2611,
    'Wolfheze': 2611,
    'Woudhuis': 2611,
    'Zaltbommel': 2611,
    'Zeddam': 2611,
    'Zelhem': 2611,
    'Zetten': 2611,
    'Zevenaar': 2611,
    'Zevenhuizen': 2611,
    'Zoelen': 2611,
    'Zonnemaat': 2611,
    'Zuilichem': 2611,
    'Zutphen': 2611,
    'Aduard': 2617,
    'Appingedam': 2617,
    'Baflo': 2617,
    'Bedum': 2617,
    'Bellingwolde': 2617,
    'Blijham': 2617,
    'Coendersborg': 2617,
    'De Wijert': 2617,
    'Delfzijl': 2617,
    'Eenrum': 2617,
    'Eexta': 2617,
    'Farmsum': 2617,
    'Gemeente  Oldambt': 2617,
    'Gemeente Appingedam': 2617,
    'Gemeente Delfzijl': 2617,
    'Gemeente Groningen': 2617,
    'Gemeente Loppersum': 2617,
    'Gemeente Pekela': 2617,
    'Gemeente Stadskanaal': 2617,
    'Gemeente Veendam': 2617,
    'Glimmen': 2617,
    'Grijpskerk': 2617,
    'Groningen': 2617,
    'Grootegast': 2617,
    'Haren': 2617,
    'Harkstede': 2617,
    'Heiligerlee': 2617,
    'Helpman': 2617,
    'Hoogezand': 2617,
    'Korrewegwijk': 2617,
    'Leek': 2617,
    'Leens': 2617,
    'Loppersum': 2617,
    'Marum': 2617,
    'Meeden': 2617,
    'Middelstum': 2617,
    'Midden-Groningen': 2617,
    'Midwolda': 2617,
    'Niekerk': 2617,
    'Nieuwe Pekela': 2617,
    'Nieuwolda': 2617,
    'Noordbroek': 2617,
    'Noordhorn': 2617,
    'Obergum': 2617,
    'Oldehove': 2617,
    'Ommelanderwijk': 2617,
    'Onstwedde': 2617,
    'Oosterhoogebrug': 2617,
    'Oosterpark': 2617,
    'Oude Pekela': 2617,
    'Reitdiep': 2617,
    'Sappemeer': 2617,
    'Scheemda': 2617,
    'Schildwolde': 2617,
    'Sellingen': 2617,
    'Selwerd': 2617,
    'Siddeburen': 2617,
    'Slochteren': 2617,
    'Spijk': 2617,
    'Stadskanaal': 2617,
    'Ten Boer': 2617,
    'Ter Apel': 2617,
    'Tuikwerd': 2617,
    'Uithuizen': 2617,
    'Uithuizermeeden': 2617,
    'Ulrum': 2617,
    'Usquert': 2617,
    'Veendam': 2617,
    'Vlagtwedde': 2617,
    'Wagenborgen': 2617,
    'Warffum': 2617,
    'Westerlee': 2617,
    'Westerwolde': 2617,
    'Wildervank': 2617,
    'Winschoten': 2617,
    'Winsum': 2617,
    'Zoutkamp': 2617,
    'Zuidbroek': 2617,
    'Zuidhorn': 2617,
    'Abdissenbosch': 2615,
    'Amby': 2615,
    'America': 2615,
    'Amstenrade': 2615,
    'Arcen': 2615,
    'Baarlo': 2615,
    'Banholt': 2615,
    'Beegden': 2615,
    'Beek': 2615,
    'Beersdal': 2615,
    'Beesel': 2615,
    'Belfort': 2615,
    'Berg': 2615,
    'Beringe': 2615,
    'Biesland': 2615,
    'Bleijerheide': 2615,
    'Blitterswijck': 2615,
    'Bocholtz': 2615,
    'Borgharen': 2615,
    'Born': 2615,
    'Brabander': 2615,
    'Brachterbeek': 2615,
    'Broekhem': 2615,
    'Broeksittard': 2615,
    'Brukske': 2615,
    'Brunssum': 2615,
    'Buchten': 2615,
    'Budschop': 2615,
    'Bunde': 2615,
    'Chevremont': 2615,
    'De Heeg': 2615,
    'Doenrade': 2615,
    'Egchel': 2615,
    'Eijsden': 2615,
    'Eijsden-Margraten': 2615,
    'Einighausen': 2615,
    'Elsloo': 2615,
    'Geleen': 2615,
    'Gemeente Beek': 2615,
    'Gemeente Beesel': 2615,
    'Gemeente Bergen': 2615,
    'Gemeente Brunssum': 2615,
    'Gemeente Echt-Susteren': 2615,
    'Gemeente Gennep': 2615,
    'Gemeente Heerlen': 2615,
    'Gemeente Horst aan de Maas': 2615,
    'Gemeente Kerkrade': 2615,
    'Gemeente Leudal': 2615,
    'Gemeente Maasgouw': 2615,
    'Gemeente Maastricht': 2615,
    'Gemeente Meerssen': 2615,
    'Gemeente Mook en Middelaar': 2615,
    'Gemeente Nederweert': 2615,
    'Gemeente Peel en Maas': 2615,
    'Gemeente Roerdalen': 2615,
    'Gemeente Roermond': 2615,
    'Gemeente Simpelveld': 2615,
    'Gemeente Sittard-Geleen': 2615,
    'Gemeente Stein': 2615,
    'Gemeente Vaals': 2615,
    'Gemeente Venlo': 2615,
    'Gemeente Venray': 2615,
    'Gemeente Voerendaal': 2615,
    'Gemeente Weert': 2615,
    'Gennep': 2615,
    'Gracht': 2615,
    'Grashoek': 2615,
    'Grevenbicht': 2615,
    'Gronsveld': 2615,
    'Gulpen': 2615,
    'Gulpen-Wittem': 2615,
    'Guttecoven': 2615,
    'Haanrade': 2615,
    'Haelen': 2615,
    'Heel': 2615,
    'Heer': 2615,
    'Heerlen': 2615,
    'Hegelsom': 2615,
    'Heide': 2615,
    'Heijen': 2615,
    'Heksenberg': 2615,
    'Helden': 2615,
    'Herkenbosch': 2615,
    'Herten': 2615,
    'Heugem': 2615,
    'Heythuysen': 2615,
    'Hoensbroek': 2615,
    'Holtum': 2615,
    'Holz': 2615,
    'Hopel': 2615,
    'Houthem': 2615,
    'Hulsberg': 2615,
    'Husken': 2615,
    'Kaalheide': 2615,
    'Kakert': 2615,
    'Keent': 2615,
    'Kelpen-Oler': 2615,
    'Kerensheide': 2615,
    'Kerkrade': 2615,
    'Kessel': 2615,
    'Klimmen': 2615,
    'Koningsbosch': 2615,
    'Koningslust': 2615,
    'Kruisberg': 2615,
    'Kunrade': 2615,
    'Landgraaf': 2615,
    'Lauradorp': 2615,
    'Leeuwen': 2615,
    'Leuken': 2615,
    'Leunen': 2615,
    'Lichtenberg': 2615,
    'Limbricht': 2615,
    'Limmel': 2615,
    'Lindenheuvel': 2615,
    'Linne': 2615,
    'Lottum': 2615,
    'Maasbracht': 2615,
    'Maasbree': 2615,
    'Maastricht': 2615,
    'Malberg': 2615,
    'Margraten': 2615,
    'Mariarade': 2615,
    'Mechelen': 2615,
    'Meerlo': 2615,
    'Meerssen': 2615,
    'Meezenbroek': 2615,
    'Meijel': 2615,
    'Melderslo': 2615,
    'Melick': 2615,
    'Merkelbeek': 2615,
    'Merselo': 2615,
    'Merum': 2615,
    'Milsbeek': 2615,
    'Moesel': 2615,
    'Montfort': 2615,
    'Mook': 2615,
    'Munstergeleen': 2615,
    'Nederweert': 2615,
    'Neerbeek': 2615,
    'Nieuw-Lotbroek': 2615,
    'Nieuwdorp': 2615,
    'Nuth': 2615,
    'Obbicht': 2615,
    'Offenbeek': 2615,
    'Oirlo': 2615,
    'Oirsbeek': 2615,
    'Oostrum': 2615,
    'Ophoven': 2615,
    'Ospel': 2615,
    'Ottersum': 2615,
    'Oud-Caberg': 2615,
    'Overhoven': 2615,
    'Panningen': 2615,
    'Passart': 2615,
    'Peij': 2615,
    'Posterholt': 2615,
    'Pottenberg': 2615,
    'Puth': 2615,
    'Reuver': 2615,
    'Roermond': 2615,
    'Roosteren': 2615,
    'Rothem': 2615,
    'Scharn': 2615,
    'Schilberg': 2615,
    'Schimmert': 2615,
    'Schinnen': 2615,
    'Schinveld': 2615,
    'Sevenum': 2615,
    'Sibbe': 2615,
    'Simpelveld': 2615,
    'Sint Joost': 2615,
    'Sint Odiliënberg': 2615,
    'Sittard': 2615,
    'Spaubeek': 2615,
    'Spekholzerheide': 2615,
    'Stadbroek': 2615,
    'Stein': 2615,
    'Stramproy': 2615,
    'Tegelen': 2615,
    'Terwinselen': 2615,
    'Thorn': 2615,
    'Tienray': 2615,
    'Ubachsberg': 2615,
    'Ulestraten': 2615,
    'Urmond': 2615,
    'Vaals': 2615,
    'Valkenburg': 2615,
    'Valkenburg aan de Geul': 2615,
    'Veltum': 2615,
    'Venlo': 2615,
    'Venray': 2615,
    'Vijlen': 2615,
    'Vlodrop': 2615,
    'Voerendaal': 2615,
    'Vrieheide': 2615,
    'Wanssum': 2615,
    'Waubach': 2615,
    'Weert': 2615,
    'Well': 2615,
    'Wellerlooi': 2615,
    'Wessem': 2615,
    'Wijlre': 2615,
    'Wijnandsrade': 2615,
    'Wolder': 2615,
    'Ysselsteyn': 2615,
    '  s Gravenmoer': 2623,
    '  s-Hertogenbosch': 2623,
    '  t Hofke': 2623,
    'Aalburg': 2623,
    'Aalst': 2623,
    'Aarle-Rixtel': 2623,
    'Abcoven': 2623,
    'Akert': 2623,
    'Almkerk': 2623,
    'Alphen': 2623,
    'Andel': 2623,
    'Asten': 2623,
    'Baardwijk': 2623,
    'Baarle-Nassau': 2623,
    'Bakel': 2623,
    'Bavel': 2623,
    'Beek': 2623,
    'Beers': 2623,
    'Belcrum': 2623,
    'Bergeijk': 2623,
    'Bergen op Zoom': 2623,
    'Berghem': 2623,
    'Berlicum': 2623,
    'Besoijen': 2623,
    'Best': 2623,
    'Besterd': 2623,
    'Biesdonk': 2623,
    'Bitswijk': 2623,
    'Blaarthem': 2623,
    'Bladel': 2623,
    'Boekel': 2623,
    'Bosschenhoofd': 2623,
    'Boxmeer': 2623,
    'Boxtel': 2623,
    'Brand': 2623,
    'Breda': 2623,
    'Breugel': 2623,
    'Broekhoven': 2623,
    'Budel': 2623,
    'Budel-Dorplein': 2623,
    'Budel-Schoot': 2623,
    'Chaam': 2623,
    'Coevering': 2623,
    'Cranendonck': 2623,
    'Cuijk': 2623,
    'De Blaak': 2623,
    'De Doornakkers': 2623,
    'De Kruiskamp': 2623,
    'De Reit': 2623,
    'De Rompert': 2623,
    'Den Dungen': 2623,
    'Deuteren': 2623,
    'Diessen': 2623,
    'Dinteloord': 2623,
    'Dommelen': 2623,
    'Dongen': 2623,
    'Donk': 2623,
    'Doonheide': 2623,
    'Dorst': 2623,
    'Duizel': 2623,
    'Eckart': 2623,
    'Eerde': 2623,
    'Eerschot': 2623,
    'Eersel': 2623,
    'Eindhoven': 2623,
    'Ekenrooi': 2623,
    'Elshout': 2623,
    'Enschot': 2623,
    'Erp': 2623,
    'Esch': 2623,
    'Fijnaart': 2623,
    'Gageldonk': 2623,
    'Geenhoven': 2623,
    'Geertruidenberg': 2623,
    'Geffen': 2623,
    'Geldrop': 2623,
    'Gemeente   s-Hertogenbosch': 2623,
    'Gemeente Alphen-Chaam': 2623,
    'Gemeente Asten': 2623,
    'Gemeente Baarle-Nassau': 2623,
    'Gemeente Bergen op Zoom': 2623,
    'Gemeente Bernheze': 2623,
    'Gemeente Best': 2623,
    'Gemeente Bladel': 2623,
    'Gemeente Boekel': 2623,
    'Gemeente Boxmeer': 2623,
    'Gemeente Boxtel': 2623,
    'Gemeente Breda': 2623,
    'Gemeente Cranendonck': 2623,
    'Gemeente Cuijk': 2623,
    'Gemeente Deurne': 2623,
    'Gemeente Dongen': 2623,
    'Gemeente Drimmelen': 2623,
    'Gemeente Eersel': 2623,
    'Gemeente Eindhoven': 2623,
    'Gemeente Etten-Leur': 2623,
    'Gemeente Geertruidenberg': 2623,
    'Gemeente Geldrop-Mierlo': 2623,
    'Gemeente Gemert-Bakel': 2623,
    'Gemeente Gilze en Rijen': 2623,
    'Gemeente Goirle': 2623,
    'Gemeente Grave': 2623,
    'Gemeente Haaren': 2623,
    'Gemeente Halderberge': 2623,
    'Gemeente Heeze-Leende': 2623,
    'Gemeente Helmond': 2623,
    'Gemeente Heusden': 2623,
    'Gemeente Hilvarenbeek': 2623,
    'Gemeente Laarbeek': 2623,
    'Gemeente Landerd': 2623,
    'Gemeente Loon op Zand': 2623,
    'Gemeente Mill en Sint Hubert': 2623,
    'Gemeente Moerdijk': 2623,
    'Gemeente Nuenen, Gerwen en Nederwetten': 2623,
    'Gemeente Oirschot': 2623,
    'Gemeente Oisterwijk': 2623,
    'Gemeente Oosterhout': 2623,
    'Gemeente Oss': 2623,
    'Gemeente Reusel-De Mierden': 2623,
    'Gemeente Rucphen': 2623,
    'Gemeente Sint Anthonis': 2623,
    'Gemeente Sint-Michielsgestel': 2623,
    'Gemeente Someren': 2623,
    'Gemeente Son en Breugel': 2623,
    'Gemeente Steenbergen': 2623,
    'Gemeente Tilburg': 2623,
    'Gemeente Uden': 2623,
    'Gemeente Valkenswaard': 2623,
    'Gemeente Veldhoven': 2623,
    'Gemeente Vught': 2623,
    'Gemeente Waalre': 2623,
    'Gemeente Waalwijk': 2623,
    'Gemeente Woensdrecht': 2623,
    'Gemeente Zundert': 2623,
    'Gemert': 2623,
    'Gemonde': 2623,
    'Genderen': 2623,
    'Genoenhuis': 2623,
    'Gerwen': 2623,
    'Giessen': 2623,
    'Gilze': 2623,
    'Ginneken': 2623,
    'Goirle': 2623,
    'Grasrijk': 2623,
    'Grave': 2623,
    'Groenewoud': 2623,
    'Haaren': 2623,
    'Haarsteeg': 2623,
    'Halsteren': 2623,
    'Handel': 2623,
    'Hapert': 2623,
    'Haps': 2623,
    'Heerle': 2623,
    'Heesch': 2623,
    'Heesterakker': 2623,
    'Heeswijk-Dinther': 2623,
    'Heeze': 2623,
    'Helmond': 2623,
    'Helvoirt': 2623,
    'Herpen': 2623,
    'Heusden': 2623,
    'Heusdenhout': 2623,
    'Hilvarenbeek': 2623,
    'Hoeven': 2623,
    'Hoge Vucht': 2623,
    'Hooge Mierde': 2623,
    'Hooge Zwaluwe': 2623,
    'Hoogeloon': 2623,
    'Hoogerheide': 2623,
    'Huijbergen': 2623,
    'Jagershoef': 2623,
    'Kalsdonk': 2623,
    'Klein-Zundert': 2623,
    'Klundert': 2623,
    'Korvel': 2623,
    'Krooswijk': 2623,
    'Kruisland': 2623,
    'Lage Mierde': 2623,
    'Lakerlopen': 2623,
    'Langenboom': 2623,
    'Leende': 2623,
    'Leest': 2623,
    'Lepelstraat': 2623,
    'Liempde': 2623,
    'Lierop': 2623,
    'Lieshout': 2623,
    'Liessel': 2623,
    'Lievendaal': 2623,
    'Lith': 2623,
    'Loon op Zand': 2623,
    'Loosbroek': 2623,
    'Loven': 2623,
    'Luyksgestel': 2623,
    'Maarheeze': 2623,
    'Maaskantje': 2623,
    'Made': 2623,
    'Mariahout': 2623,
    'Meerhoven': 2623,
    'Meerveldhoven': 2623,
    'Megen': 2623,
    'Meierijstad': 2623,
    'Melle': 2623,
    'Middelbeers': 2623,
    'Middelrode': 2623,
    'Mierlo': 2623,
    'Milheeze': 2623,
    'Moerdijk': 2623,
    'Molenhoek': 2623,
    'Muschberg en Geestenberg': 2623,
    'Naastenbest': 2623,
    'Neerkant': 2623,
    'Nieuw-Vossemeer': 2623,
    'Nieuwkuijk': 2623,
    'Nijnsel': 2623,
    'Nispen': 2623,
    'Nistelrode': 2623,
    'Noordgeest': 2623,
    'Noordhoek': 2623,
    'Nuenen': 2623,
    'Odiliapeel': 2623,
    'Oerle': 2623,
    'Oirschot': 2623,
    'Oisterwijk': 2623,
    'Oosteind': 2623,
    'Oostelbeers': 2623,
    'Oosterhout': 2623,
    'Orthen': 2623,
    'Oss': 2623,
    'Ossendrecht': 2623,
    'Oud Gastel': 2623,
    'Overloon': 2623,
    'Princenhage': 2623,
    'Prinsenbeek': 2623,
    'Putte': 2623,
    'Quirijnstok': 2623,
    'Raam': 2623,
    'Raamsdonk': 2623,
    'Raamsdonksveer': 2623,
    'Ravenstein': 2623,
    'Reek': 2623,
    'Reusel': 2623,
    'Riethoven': 2623,
    'Rijen': 2623,
    'Rijsbergen': 2623,
    'Rijswijk': 2623,
    'Roosendaal': 2623,
    'Rucphen': 2623,
    'Salderes': 2623,
    'Schaijk': 2623,
    'Schijndel': 2623,
    'Schutsboom': 2623,
    'Sint Anthonis': 2623,
    'Sint Willebrord': 2623,
    'Sint-Michielsgestel': 2623,
    'Sint-Oedenrode': 2623,
    'Soerendonk': 2623,
    'Someren': 2623,
    'Someren-Eind': 2623,
    'Son': 2623,
    'Speelheide': 2623,
    'Sprundel': 2623,
    'Stampersgat': 2623,
    'Standdaarbuiten': 2623,
    'Steenbergen': 2623,
    'Steensel': 2623,
    'Stepekolk': 2623,
    'Terheijden': 2623,
    'Teteringen': 2623,
    'Theereheide': 2623,
    'Tilburg': 2623,
    'Tivoli': 2623,
    'Tongelre': 2623,
    'Uden': 2623,
    'Udenhout': 2623,
    'Ulvenhout': 2623,
    'Vaartbroek': 2623,
    'Valkenswaard': 2623,
    'Veen': 2623,
    'Veghel': 2623,
    'Veldhoven': 2623,
    'Ven': 2623,
    'Venhorst': 2623,
    'Villapark': 2623,
    'Vliedberg': 2623,
    'Vlierden': 2623,
    'Vlokhoven': 2623,
    'Voldijn': 2623,
    'Volkel': 2623,
    'Vorstenbosch': 2623,
    'Vught': 2623,
    'Waalre': 2623,
    'Waalwijk': 2623,
    'Wagenberg': 2623,
    'Wanroij': 2623,
    'Waspik': 2623,
    'Waterdonken': 2623,
    'Welberg': 2623,
    'Werkendam': 2623,
    'Wernhout': 2623,
    'Westerhoven': 2623,
    'Wijbosch': 2623,
    'Wilhelminadorp': 2623,
    'Wisselaar': 2623,
    'Woensdrecht': 2623,
    'Woenselse Heide': 2623,
    'Woudrichem': 2623,
    'Wouw': 2623,
    'Zandberg': 2623,
    'Zeeland': 2623,
    'Zeelst': 2623,
    'Zegge': 2623,
    'Zeilberg': 2623,
    'Zesgehuchten': 2623,
    'Zevenbergschen Hoek': 2623,
    'Zijtaart': 2623,
    'Zonderwijk': 2623,
    'Zundert': 2623,
    '  t Zand': 2612,
    'Aalsmeer': 2612,
    'Abbekerk': 2612,
    'Alkmaar': 2612,
    'Amstelveen': 2612,
    'Amsterdam': 2612,
    'Amsterdam-Zuidoost': 2612,
    'Ankeveense Rade': 2612,
    'Avenhorn': 2612,
    'Bangert': 2612,
    'Bennebroek': 2612,
    'Berkhout': 2612,
    'Beverwijk': 2612,
    'Bijvanck': 2612,
    'Blaricum': 2612,
    'Bloemendaal': 2612,
    'Breezand': 2612,
    'Broek in Waterland': 2612,
    'Broek op Langedijk': 2612,
    'Bussum': 2612,
    'Callantsoog': 2612,
    'Castricum': 2612,
    'Commandeurs': 2612,
    'De Glip': 2612,
    'De Goorn': 2612,
    'De Koog': 2612,
    'De Maer': 2612,
    'De Noord': 2612,
    'De Rijp': 2612,
    'Den Burg': 2612,
    'Den Helder': 2612,
    'Den Oever': 2612,
    'Diemen': 2612,
    'Dirkshorn': 2612,
    'Driemond': 2612,
    'Duivendrecht': 2612,
    'Edam': 2612,
    'Egmond aan Zee': 2612,
    'Egmond-Binnen': 2612,
    'Enkhuizen': 2612,
    'Gemeente Aalsmeer': 2612,
    'Gemeente Alkmaar': 2612,
    'Gemeente Amstelveen': 2612,
    'Gemeente Amsterdam': 2612,
    'Gemeente Beemster': 2612,
    'Gemeente Bergen': 2612,
    'Gemeente Beverwijk': 2612,
    'Gemeente Blaricum': 2612,
    'Gemeente Bloemendaal': 2612,
    'Gemeente Castricum': 2612,
    'Gemeente Den Helder': 2612,
    'Gemeente Diemen': 2612,
    'Gemeente Drechterland': 2612,
    'Gemeente Edam-Volendam': 2612,
    'Gemeente Enkhuizen': 2612,
    'Gemeente Haarlem': 2612,
    'Gemeente Haarlemmermeer': 2612,
    'Gemeente Heemskerk': 2612,
    'Gemeente Heemstede': 2612,
    'Gemeente Heerhugowaard': 2612,
    'Gemeente Heiloo': 2612,
    'Gemeente Hilversum': 2612,
    'Gemeente Hollands Kroon': 2612,
    'Gemeente Hoorn': 2612,
    'Gemeente Huizen': 2612,
    'Gemeente Koggenland': 2612,
    'Gemeente Landsmeer': 2612,
    'Gemeente Langedijk': 2612,
    'Gemeente Laren': 2612,
    'Gemeente Medemblik': 2612,
    'Gemeente Oostzaan': 2612,
    'Gemeente Opmeer': 2612,
    'Gemeente Ouder-Amstel': 2612,
    'Gemeente Purmerend': 2612,
    'Gemeente Schagen': 2612,
    'Gemeente Stede Broec': 2612,
    'Gemeente Texel': 2612,
    'Gemeente Uitgeest': 2612,
    'Gemeente Uithoorn': 2612,
    'Gemeente Velsen': 2612,
    'Gemeente Waterland': 2612,
    'Gemeente Weesp': 2612,
    'Gemeente Wijdemeren': 2612,
    'Gemeente Wormerland': 2612,
    'Gemeente Zaanstad': 2612,
    'Gemeente Zandvoort': 2612,
    'Gooise Meren': 2612,
    'Haarlem': 2612,
    'Halfweg': 2612,
    'Harenkarspel': 2612,
    'Heemskerk': 2612,
    'Heemstede': 2612,
    'Heerhugowaard': 2612,
    'Heiloo': 2612,
    'Hem': 2612,
    'Hensbroek': 2612,
    'Hilversum': 2612,
    'Hilversumse Meent': 2612,
    'Hippolytushoef': 2612,
    'Hofgeest': 2612,
    'Hoofddorp': 2612,
    'Hoogwoud': 2612,
    'Hoorn': 2612,
    'Huizen': 2612,
    'Ilpendam': 2612,
    'Kadoelen': 2612,
    'Kerkelanden': 2612,
    'Kortenhoef': 2612,
    'Kudelstaart': 2612,
    'Landsmeer': 2612,
    'Langeheit': 2612,
    'Laren': 2612,
    'Limmen': 2612,
    'Lisserbroek': 2612,
    'Lutjebroek': 2612,
    'Marken': 2612,
    'Medemblik': 2612,
    'Middenbeemster': 2612,
    'Middenmeer': 2612,
    'Midwoud': 2612,
    'Monnickendam': 2612,
    'Muiden': 2612,
    'Muiderberg': 2612,
    'Naarden': 2612,
    'Nieuw-Loosdrecht': 2612,
    'Nieuwe-Niedorp': 2612,
    'Noord-Scharwoude': 2612,
    'Obdam': 2612,
    'Onderdijk': 2612,
    'Oosteinde': 2612,
    'Oosterblokker': 2612,
    'Oosterzij': 2612,
    'Oosthuizen': 2612,
    'Opmeer': 2612,
    'Opperdoes': 2612,
    'Oud-Loosdrecht': 2612,
    'Ouderkerk aan de Amstel': 2612,
    'Oudeschild': 2612,
    'Oudkarspel': 2612,
    'Overveen': 2612,
    'Petten': 2612,
    'Purmerend': 2612,
    'Schagen': 2612,
    'Schagerbrug': 2612,
    'Schermerhorn': 2612,
    'Sint Pancras': 2612,
    'Slootdorp': 2612,
    'Spierdijk': 2612,
    'Stompetoren': 2612,
    'Twisk': 2612,
    'Uitgeest': 2612,
    'Uithoorn': 2612,
    'Velsen-Zuid': 2612,
    'Vijfhuizen': 2612,
    'Vogelenzang': 2612,
    'Volendam': 2612,
    'Waarland': 2612,
    'Waterakkers': 2612,
    'Weesp': 2612,
    'Westwoud': 2612,
    'Wieringerwaard': 2612,
    'Wieringerwerf': 2612,
    'Wijdenes': 2612,
    'Wijk aan Zee': 2612,
    'Winkel': 2612,
    'Zaandam': 2612,
    'Zaandijk': 2612,
    'Zaanstad': 2612,
    'Zandvoort': 2612,
    'Zuid-Scharwoude': 2612,
    'Zwaagdijk-Oost': 2612,
    'Zwaanshoek': 2612,
    'Almelo': 2618,
    'Baalder': 2618,
    'Berkum': 2618,
    'Blokzijl': 2618,
    'Borgele': 2618,
    'Borne': 2618,
    'Boskamp': 2618,
    'Brunnepe': 2618,
    'Dalfsen': 2618,
    'De Hoven': 2618,
    'Delden': 2618,
    'Den Ham': 2618,
    'Deventer': 2618,
    'Diepenheim': 2618,
    'Enschede': 2618,
    'Flevowijk': 2618,
    'Frankhuis': 2618,
    'Gemeente Almelo': 2618,
    'Gemeente Borne': 2618,
    'Gemeente Dalfsen': 2618,
    'Gemeente Deventer': 2618,
    'Gemeente Dinkelland': 2618,
    'Gemeente Enschede': 2618,
    'Gemeente Haaksbergen': 2618,
    'Gemeente Hardenberg': 2618,
    'Gemeente Hellendoorn': 2618,
    'Gemeente Hengelo': 2618,
    'Gemeente Hof van Twente': 2618,
    'Gemeente Kampen': 2618,
    'Gemeente Losser': 2618,
    'Gemeente Oldenzaal': 2618,
    'Gemeente Olst-Wijhe': 2618,
    'Gemeente Ommen': 2618,
    'Gemeente Raalte': 2618,
    'Gemeente Rijssen-Holten': 2618,
    'Gemeente Staphorst': 2618,
    'Gemeente Steenwijkerland': 2618,
    'Gemeente Tubbergen': 2618,
    'Gemeente Twenterand': 2618,
    'Gemeente Wierden': 2618,
    'Gemeente Zwartewaterland': 2618,
    'Gemeente Zwolle': 2618,
    'Giethoorn': 2618,
    'Goor': 2618,
    'Grafhorst': 2618,
    'Haaksbergen': 2618,
    'Haarle': 2618,
    'Hardenberg': 2618,
    'Hasselt': 2618,
    'Heino': 2618,
    'Hengelo': 2618,
    'Hengevelde': 2618,
    'Het Oostrik': 2618,
    'Kampen': 2618,
    'Klein Driene': 2618,
    'Losser': 2618,
    'Magele': 2618,
    'Markelo': 2618,
    'Mekkelholt': 2618,
    'Nieuwoord': 2618,
    'Nijrees': 2618,
    'Oldemarkt': 2618,
    'Oldenzaal': 2618,
    'Olst': 2618,
    'Ommen': 2618,
    'Oosterholt': 2618,
    'Oostermeenthe': 2618,
    'Ootmarsum': 2618,
    'Pathmos': 2618,
    'Pierik': 2618,
    'Raalte': 2618,
    'Rossum': 2618,
    'Schalkhaar': 2618,
    'Sint Jansklooster': 2618,
    'Staphorst': 2618,
    'Steenwijk': 2618,
    'Steenwijkerwold': 2618,
    'Tubbergen': 2618,
    'Tuk': 2618,
    'Twekkelerveld': 2618,
    'Vollenhove': 2618,
    'Vriezenveen': 2618,
    'Vroomshoop': 2618,
    'Westerhaar-Vriezenveensewijk': 2618,
    'Wierden': 2618,
    'Wijhe': 2618,
    'Woolde': 2618,
    'Wooldrik': 2618,
    'Zuid-Berghuizen': 2618,
    'Zwolle': 2618,
    '  s-Gravenland': 2614,
    '  s-Gravenzande': 2614,
    'Adegeest': 2614,
    'Alblasserdam': 2614,
    'Alphen aan den Rijn': 2614,
    'Ammerstol': 2614,
    'Arkel': 2614,
    'Barendrecht': 2614,
    'Benthuizen': 2614,
    'Bergschenhoek': 2614,
    'Bergstoep': 2614,
    'Berkel en Rodenrijs': 2614,
    'Berkenwoude': 2614,
    'Binnenhof': 2614,
    'Bleiswijk': 2614,
    'Bleskensgraaf': 2614,
    'Bloemendaal': 2614,
    'Bloemhof': 2614,
    'Bodegraven': 2614,
    'Bodegraven-Reeuwijk': 2614,
    'Bolnes': 2614,
    'Boskoop': 2614,
    'Boven-Hardinxveld': 2614,
    'Brielle': 2614,
    'Capelle aan den IJssel': 2614,
    'Capelle-West': 2614,
    'Carnisse': 2614,
    'De Kieviet': 2614,
    'De Lier': 2614,
    'De Peulen': 2614,
    'Delfshaven': 2614,
    'Delft': 2614,
    'Dirksland': 2614,
    'Dordrecht': 2614,
    'Driebruggen': 2614,
    'Driemanspolder': 2614,
    'Duindorp': 2614,
    'Duinzigt': 2614,
    'Essesteijn': 2614,
    'Feijenoord': 2614,
    'Gemeente Alblasserdam': 2614,
    'Gemeente Albrandswaard': 2614,
    'Gemeente Alphen aan den Rijn': 2614,
    'Gemeente Barendrecht': 2614,
    'Gemeente Brielle': 2614,
    'Gemeente Capelle aan den IJssel': 2614,
    'Gemeente Delft': 2614,
    'Gemeente Den Haag': 2614,
    'Gemeente Dordrecht': 2614,
    'Gemeente Goeree-Overflakkee': 2614,
    'Gemeente Gorinchem': 2614,
    'Gemeente Gouda': 2614,
    'Gemeente Hardinxveld-Giessendam': 2614,
    'Gemeente Hellevoetsluis': 2614,
    'Gemeente Hendrik-Ido-Ambacht': 2614,
    'Gemeente Hillegom': 2614,
    'Gemeente Kaag en Braassem': 2614,
    'Gemeente Katwijk': 2614,
    'Gemeente Krimpen aan den IJssel': 2614,
    'Gemeente Lansingerland': 2614,
    'Gemeente Leiden': 2614,
    'Gemeente Leiderdorp': 2614,
    'Gemeente Leidschendam-Voorburg': 2614,
    'Gemeente Lisse': 2614,
    'Gemeente Maassluis': 2614,
    'Gemeente Midden-Delfland': 2614,
    'Gemeente Nieuwkoop': 2614,
    'Gemeente Noordwijk': 2614,
    'Gemeente Oegstgeest': 2614,
    'Gemeente Papendrecht': 2614,
    'Gemeente Pijnacker-Nootdorp': 2614,
    'Gemeente Ridderkerk': 2614,
    'Gemeente Rijswijk': 2614,
    'Gemeente Rotterdam': 2614,
    'Gemeente Schiedam': 2614,
    'Gemeente Sliedrecht': 2614,
    'Gemeente Teylingen': 2614,
    'Gemeente Vlaardingen': 2614,
    'Gemeente Voorschoten': 2614,
    'Gemeente Waddinxveen': 2614,
    'Gemeente Wassenaar': 2614,
    'Gemeente Westland': 2614,
    'Gemeente Westvoorne': 2614,
    'Gemeente Zoetermeer': 2614,
    'Gemeente Zoeterwoude': 2614,
    'Gemeente Zuidplas': 2614,
    'Gemeente Zwijndrecht': 2614,
    'Giessenburg': 2614,
    'Giessendam': 2614,
    'Goedereede': 2614,
    'Gorinchem': 2614,
    'Gouda': 2614,
    'Gouderak': 2614,
    'Goudswaard': 2614,
    'Gouwsluis': 2614,
    'Groenswaard': 2614,
    'Groot IJsselmonde': 2614,
    'Groot-Ammers': 2614,
    'Haastrecht': 2614,
    'Heerjansdam': 2614,
    'Heijplaat': 2614,
    'Hellevoetsluis': 2614,
    'Hendrik-Ido-Ambacht': 2614,
    'Hillegom': 2614,
    'Hoek van Holland': 2614,
    'Hoge Mors': 2614,
    'Honselersdijk': 2614,
    'Hoogblokland': 2614,
    'Hoogmade': 2614,
    'Katendrecht': 2614,
    'Katwijk aan den Rijn': 2614,
    'Katwijk aan Zee': 2614,
    'Kerkehout': 2614,
    'Kijkduin': 2614,
    'Klaaswaal': 2614,
    'Kop van Zuid': 2614,
    'Krimpen aan den IJssel': 2614,
    'Krimpenerwaard': 2614,
    'Kwintsheul': 2614,
    'Leiden': 2614,
    'Leiderdorp': 2614,
    'Leimuiden': 2614,
    'Liesveld': 2614,
    'Lisse': 2614,
    'Lombardijen': 2614,
    'Maasdijk': 2614,
    'Maasland': 2614,
    'Maassluis': 2614,
    'Merenwijk': 2614,
    'Middelharnis': 2614,
    'Middelsluis': 2614,
    'Mijnsheerenland': 2614,
    'Monster': 2614,
    'Naaldwijk': 2614,
    'Neder-Hardinxveld': 2614,
    'Nieuw-Beijerland': 2614,
    'Nieuw-Lekkerland': 2614,
    'Nieuwenhoorn': 2614,
    'Nieuwkoop': 2614,
    'Nieuwpoort': 2614,
    'Nieuwveen': 2614,
    'Nissewaard': 2614,
    'Noord-Hofland': 2614,
    'Noordeinde': 2614,
    'Noordeloos': 2614,
    'Noordwijk-Binnen': 2614,
    'Noordwijkerhout': 2614,
    'Numansdorp': 2614,
    'Oegstgeest': 2614,
    'Ommoord': 2614,
    'Oostdorp': 2614,
    'Oostvoorne': 2614,
    'Oranjewijk': 2614,
    'Oud-Beijerland': 2614,
    'Ouddorp': 2614,
    'Oude Wetering': 2614,
    'Ouderkerk aan den IJssel': 2614,
    'Overschie': 2614,
    'Palenstein': 2614,
    'Papendrecht': 2614,
    'Papenveer': 2614,
    'Pendrecht': 2614,
    'Pernis': 2614,
    'Piershil': 2614,
    'Pijnacker': 2614,
    'Poeldijk': 2614,
    'Reeuwijk': 2614,
    'Rhoon': 2614,
    'Ridderkerk': 2614,
    'Rijnsaterwoude': 2614,
    'Rijnsburg': 2614,
    'Rijpwetering': 2614,
    'Rijsoord': 2614,
    'Rijswijk': 2614,
    'Rockanje': 2614,
    'Roelofarendsveen': 2614,
    'Rotterdam': 2614,
    'Rozenburg': 2614,
    'Rugge': 2614,
    'Sassenheim': 2614,
    'Schelluinen': 2614,
    'Scheveningen': 2614,
    'Schiebroek': 2614,
    'Schiedam': 2614,
    'Schipluiden': 2614,
    'Schoonhoven': 2614,
    'Sliedrecht': 2614,
    'Slikkerveer': 2614,
    'Spangen': 2614,
    'Spijkenisse': 2614,
    'Spoorwijk': 2614,
    'Statenkwartier': 2614,
    'Stein': 2614,
    'Stolwijk': 2614,
    'Strijen': 2614,
    'Strijp': 2614,
    'Terbregge': 2614,
    'The Hague': 2614,
    'Tuindorp': 2614,
    'Valkenburg': 2614,
    'Vierpolders': 2614,
    'Vlaardingen': 2614,
    'Vlietwijk': 2614,
    'Vogelwijk': 2614,
    'Vondelwijk': 2614,
    'Voorburg': 2614,
    'Voorhout': 2614,
    'Voorschoten': 2614,
    'Vreewijk': 2614,
    'Waarder': 2614,
    'Waddinxveen': 2614,
    'Warmond': 2614,
    'Wassenaar': 2614,
    'Weerestein': 2614,
    'Weijpoort': 2614,
    'Westmaas': 2614,
    'Woubrugge': 2614,
    'Ypenburg': 2614,
    'Zevenhoven': 2614,
    'Zoetermeer': 2614,
    'Zoeterwoude-Dorp': 2614,
    'Zuid-Beijerland': 2614,
    'Zuidwijk': 2614,
    'Zwijndrecht': 2614,
    'Abcoude': 2610,
    'Amerongen': 2610,
    'Amersfoort': 2610,
    'Austerlitz': 2610,
    'Baambrugge': 2610,
    'Baarn': 2610,
    'Bosch en Duin': 2610,
    'Breukelen': 2610,
    'Bunnik': 2610,
    'Bunschoten': 2610,
    'Cothen': 2610,
    'De Bilt': 2610,
    'De Hagen': 2610,
    'De Uithof': 2610,
    'Doorn': 2610,
    'Driebergen-Rijsenburg': 2610,
    'Gemeente Amersfoort': 2610,
    'Gemeente Baarn': 2610,
    'Gemeente Bunnik': 2610,
    'Gemeente Bunschoten': 2610,
    'Gemeente De Bilt': 2610,
    'Gemeente De Ronde Venen': 2610,
    'Gemeente Eemnes': 2610,
    'Gemeente Houten': 2610,
    'Gemeente IJsselstein': 2610,
    'Gemeente Leusden': 2610,
    'Gemeente Lopik': 2610,
    'Gemeente Montfoort': 2610,
    'Gemeente Nieuwegein': 2610,
    'Gemeente Oudewater': 2610,
    'Gemeente Renswoude': 2610,
    'Gemeente Rhenen': 2610,
    'Gemeente Soest': 2610,
    'Gemeente Utrecht': 2610,
    'Gemeente Utrechtse Heuvelrug': 2610,
    'Gemeente Veenendaal': 2610,
    'Gemeente Wijk bij Duurstede': 2610,
    'Gemeente Woerden': 2610,
    'Gemeente Woudenberg': 2610,
    'Gemeente Zeist': 2610,
    'Groenekan': 2610,
    'Hollandsche Rading': 2610,
    'Houten': 2610,
    'IJsselstein': 2610,
    'Kedichem': 2610,
    'Kerckebosch': 2610,
    'Langenoord': 2610,
    'Leerdam': 2610,
    'Leersum': 2610,
    'Leusden': 2610,
    'Linschoten': 2610,
    'Lopik': 2610,
    'Lunetten': 2610,
    'Maarn': 2610,
    'Maarssen': 2610,
    'Maartensdijk': 2610,
    'Mijdrecht': 2610,
    'Montfoort': 2610,
    'Nieuwegein': 2610,
    'Nijenheim': 2610,
    'Odijk': 2610,
    'Oog in Al': 2610,
    'Oudewater': 2610,
    'Overberg': 2610,
    'Randenbroek': 2610,
    'Renswoude': 2610,
    'Rhenen': 2610,
    'Schoonrewoerd': 2610,
    'Soest': 2610,
    'Soestdijk': 2610,
    'Spakenburg': 2610,
    'Spechtenkamp': 2610,
    'Stichtse Vecht': 2610,
    'Utrecht': 2610,
    'Veenendaal': 2610,
    'Veldhuizen': 2610,
    'Vianen': 2610,
    'Vinkeveen': 2610,
    'Vleuten': 2610,
    'Vreeswijk': 2610,
    'Werkhoven': 2610,
    'Wijk bij Duurstede': 2610,
    'Wilnis': 2610,
    'Woerden': 2610,
    'Woudenberg': 2610,
    'Zeist': 2610,
    'Aagtekerke': 2620,
    'Aardenburg': 2620,
    'Arnemuiden': 2620,
    'Axel': 2620,
    'Borssele': 2620,
    'Breskens': 2620,
    'Brouwershaven': 2620,
    'Bruinisse': 2620,
    'Colijnsplaat': 2620,
    'Dauwendaele': 2620,
    'Domburg': 2620,
    'Gemeente Borsele': 2620,
    'Gemeente Goes': 2620,
    'Gemeente Hulst': 2620,
    'Gemeente Kapelle': 2620,
    'Gemeente Middelburg': 2620,
    'Gemeente Noord-Beveland': 2620,
    'Gemeente Reimerswaal': 2620,
    'Gemeente Sluis': 2620,
    'Gemeente Terneuzen': 2620,
    'Gemeente Tholen': 2620,
    'Gemeente Veere': 2620,
    'Gemeente Vlissingen': 2620,
    'Goes': 2620,
    'Griffioen': 2620,
    'Grijpskerke': 2620,
    'Haamstede': 2620,
    'Hulst': 2620,
    'IJzendijke': 2620,
    'Kamperland': 2620,
    'Kapelle': 2620,
    'Klarenbeek': 2620,
    'Kloetinge': 2620,
    'Koewacht': 2620,
    'Koudekerke': 2620,
    'Lammerenburg': 2620,
    'Malta': 2620,
    'Meliskerke': 2620,
    'Middelburg': 2620,
    'Nieuw- en Sint Joosland': 2620,
    'Nieuwerkerk': 2620,
    'Oostburg': 2620,
    'Oosterland': 2620,
    'Oostkapelle': 2620,
    'Othene': 2620,
    'Oud-Vossemeer': 2620,
    'Poortvliet': 2620,
    'Renesse': 2620,
    'Sas van Gent': 2620,
    'Scharendijke': 2620,
    'Scherpenisse': 2620,
    'Schoondijke': 2620,
    'Schouwen-Duiveland': 2620,
    'Serooskerke': 2620,
    'Sint Laurens': 2620,
    'Sint Philipsland': 2620,
    'Sluis': 2620,
    'Sluiskil': 2620,
    'Stavenisse': 2620,
    'Terneuzen': 2620,
    'Tholen': 2620,
    'Vlissingen': 2620,
    'Waarde': 2620,
    'Wemeldinge': 2620,
    'West-Souburg': 2620,
    'Westdorpe': 2620,
    'Westerzicht': 2620,
    'Westkapelle': 2620,
    'Wissenkerke': 2620,
    'Wolphaartsdijk': 2620,
    'Yerseke': 2620,
    'Zaamslag': 2620,
    'Zierikzee': 2620,
    'Zoutelande': 2620,
    'Auckland': 4072,
    'Mangere': 4072,
    'Manukau City': 4072,
    'Muriwai Beach': 4072,
    'Murrays Bay': 4072,
    'North Shore': 4072,
    'Pakuranga': 4072,
    'Papakura': 4072,
    'Parakai': 4072,
    'Pukekohe East': 4072,
    'Red Hill': 4072,
    'Rosebank': 4072,
    'Rothesay Bay': 4072,
    'Takanini': 4072,
    'Tamaki': 4072,
    'Titirangi': 4072,
    'Waitakere': 4072,
    'Waiuku': 4072,
    'Warkworth': 4072,
    'Wellsford': 4072,
    'Wiri': 4072,
    'Edgecumbe': 4074,
    'Katikati': 4074,
    'Kawerau': 4074,
    'Maketu': 4074,
    'Murupara': 4074,
    'Opotiki': 4074,
    'Rotorua': 4074,
    'Tauranga': 4074,
    'Waihi Beach': 4074,
    'Whakatane': 4074,
    'Amberley': 4066,
    'Ashburton': 4066,
    'Ashburton District': 4066,
    'Burnham': 4066,
    'Christchurch': 4066,
    'Christchurch City': 4066,
    'Darfield': 4066,
    'Geraldine': 4066,
    'Kaiapoi': 4066,
    'Leeston': 4066,
    'Lincoln': 4066,
    'Mackenzie District': 4066,
    'Methven': 4066,
    'Oxford': 4066,
    'Pleasant Point': 4066,
    'Prebbleton': 4066,
    'Rakaia': 4066,
    'Rolleston': 4066,
    'Selwyn District': 4066,
    'Timaru': 4066,
    'Timaru District': 4066,
    'Tinwald': 4066,
    'Waimakariri District': 4066,
    'Woodend': 4066,
    'Waitangi': 4067,
    'Gisborne': 4068,
    'Hastings': 4075,
    'Napier': 4075,
    'Taradale': 4075,
    'Wairoa': 4075,
    'Bulls': 4060,
    'Foxton': 4060,
    'Horowhenua District': 4060,
    'Levin': 4060,
    'Manawatu District': 4060,
    'Palmerston North': 4060,
    'Waiouru': 4060,
    'Wanganui': 4060,
    'Blenheim': 4063,
    'Picton': 4063,
    'Nelson': 4070,
    'Ahipara': 4059,
    'Dargaville': 4059,
    'Far North District': 4059,
    'Kaipara District': 4059,
    'Kaitaia': 4059,
    'Kawakawa': 4059,
    'Kerikeri': 4059,
    'Maungatapere': 4059,
    'Moerewa': 4059,
    'Ngunguru': 4059,
    'Paihia': 4059,
    'Ruakaka': 4059,
    'Taipa': 4059,
    'Waimate North': 4059,
    'Whangarei': 4059,
    'Arrowtown': 4062,
    'Balclutha': 4062,
    'Clutha District': 4062,
    'Cromwell': 4062,
    'Dunedin': 4062,
    'Kingston': 4062,
    'Milton': 4062,
    'Oamaru': 4062,
    'Papatowai': 4062,
    'Portobello': 4062,
    'Queenstown': 4062,
    'Wanaka': 4062,
    'Bluff': 4071,
    'Gore': 4071,
    'Invercargill': 4071,
    'Riverton': 4071,
    'Southland District': 4071,
    'Te Anau': 4071,
    'Winton': 4071,
    'Eltham': 4069,
    'Hawera': 4069,
    'New Plymouth': 4069,
    'New Plymouth District': 4069,
    'Opunake': 4069,
    'Patea': 4069,
    'South Taranaki District': 4069,
    'Waitara': 4069,
    'Brightwater': 4073,
    'Mapua': 4073,
    'Motueka': 4073,
    'Richmond': 4073,
    'Takaka': 4073,
    'Wakefield': 4073,
    'Cambridge': 4061,
    'Coromandel': 4061,
    'Hamilton': 4061,
    'Matamata': 4061,
    'Ngaruawahia': 4061,
    'Ngatea': 4061,
    'Otorohanga': 4061,
    'Paeroa': 4061,
    'Raglan': 4061,
    'South Waikato District': 4061,
    'Tairua': 4061,
    'Taupo': 4061,
    'Te Kauwhata': 4061,
    'Thames': 4061,
    'Tokoroa': 4061,
    'Turangi': 4061,
    'Waihi': 4061,
    'Whangamata': 4061,
    'Whitianga': 4061,
    'Brooklyn': 4065,
    'Castlepoint': 4065,
    'Kapiti Coast District': 4065,
    'Kelburn': 4065,
    'Khandallah': 4065,
    'Lower Hutt': 4065,
    'Masterton': 4065,
    'Otaki': 4065,
    'Paraparaumu': 4065,
    'Petone': 4065,
    'Porirua': 4065,
    'South Wairarapa District': 4065,
    'Upper Hutt': 4065,
    'Waipawa': 4065,
    'Wellington': 4065,
    'Wellington City': 4065,
    'Greymouth': 4064,
    'Hokitika': 4064,
    'Westport': 4064,
    'Boaco': 946,
    'Camoapa': 946,
    'San José de los Remates': 946,
    'San Lorenzo': 946,
    'Santa Lucía': 946,
    'Teustepe': 946,
    'Diriamba': 950,
    'Dolores': 950,
    'El Rosario': 950,
    'Jinotepe': 950,
    'La Conquista': 950,
    'La Paz de Carazo': 950,
    'Municipio de San Marcos': 950,
    'San Marcos': 950,
    'Santa Teresa': 950,
    'Chichigalpa': 954,
    'Chinandega': 954,
    'Cinco Pinos': 954,
    'Corinto': 954,
    'El Realejo': 954,
    'El Viejo': 954,
    'Jiquilillo': 954,
    'Municipio de San Francisco del Norte': 954,
    'Posoltega': 954,
    'Puerto Morazán': 954,
    'Santo Tomás del Norte': 954,
    'Somotillo': 954,
    'Acoyapa': 940,
    'Comalapa': 940,
    'Cuapa': 940,
    'El Ayote': 940,
    'Juigalpa': 940,
    'La Libertad': 940,
    'Santo Domingo': 940,
    'Santo Tomás': 940,
    'Villa Sandino': 940,
    'Condega': 945,
    'Estelí': 945,
    'La Trinidad': 945,
    'Pueblo Nuevo': 945,
    'San Juan de Limay': 945,
    'Diriá': 943,
    'Diriomo': 943,
    'Granada': 943,
    'Nandaime': 943,
    'El Cuá': 955,
    'Jinotega': 955,
    'La Concordia': 955,
    'Las Praderas': 955,
    'LLano de La Cruz': 955,
    'San José de Bocay': 955,
    'San Rafael del Norte': 955,
    'San Sebastián de Yalí': 955,
    'Achuapa': 944,
    'El Jicaral': 944,
    'El Sauce': 944,
    'La Paz Centro': 944,
    'Larreynaga': 944,
    'León': 944,
    'Nagarote': 944,
    'Quezalguaque': 944,
    'Santa Rosa del Peñón': 944,
    'Telica': 944,
    'Las Sabanas': 948,
    'Palacagüina': 948,
    'San José de Cusmapa': 948,
    'San Juan de Río Coco': 948,
    'San Lucas': 948,
    'Somoto': 948,
    'Telpaneca': 948,
    'Totogalpa': 948,
    'Yalagüina': 948,
    'Ciudad Sandino': 941,
    'El Crucero': 941,
    'Managua': 941,
    'Masachapa': 941,
    'San Rafael del Sur': 941,
    'Ticuantepe': 941,
    'Tipitapa': 941,
    'Valle San Francisco': 941,
    'Villa El Carmen': 941,
    'Catarina': 953,
    'La Concepción': 953,
    'Masatepe': 953,
    'Masaya': 953,
    'Municipio de Masatepe': 953,
    'Municipio de Nandasmo': 953,
    'Municipio de Niquinohomo': 953,
    'Municipio de San Juan de Oriente': 953,
    'Nandasmo': 953,
    'Nindirí': 953,
    'Niquinohomo': 953,
    'San Juan de Oriente': 953,
    'Tisma': 953,
    'Ciudad Darío': 947,
    'Matagalpa': 947,
    'Matiguás': 947,
    'Muy Muy': 947,
    'Río Blanco': 947,
    'San Dionisio': 947,
    'San Ramón': 947,
    'Terrabona': 947,
    'Bonanza': 951,
    'Prinzapolka': 951,
    'Puerto Cabezas': 951,
    'Siuna': 951,
    'Waslala': 951,
    'Waspán': 951,
    'El Almendro': 949,
    'Greytown': 949,
    'Morrito': 949,
    'San Carlos': 949,
    'San Miguelito': 949,
    'Altagracia': 942,
    'Belén': 942,
    'Buenos Aires': 942,
    'Cárdenas': 942,
    'Moyogalpa': 942,
    'Municipio de Altagracia': 942,
    'Municipio de Belén': 942,
    'Municipio de Buenos Aires': 942,
    'Municipio de Cárdenas': 942,
    'Municipio de Moyogalpa': 942,
    'Municipio de Potosí': 942,
    'Municipio de Rivas': 942,
    'Municipio de San Jorge': 942,
    'Municipio de San Juan del Sur': 942,
    'Municipio de Tola': 942,
    'Potosí': 942,
    'Rivas': 942,
    'San Jorge': 942,
    'San Juan del Sur': 942,
    'Tola': 942,
    'Bluefields': 952,
    'Bocana de Paiwas': 952,
    'Corn Island': 952,
    'El Rama': 952,
    'El Tortuguero': 952,
    'Kukrahill': 952,
    'La Cruz de Río Grande': 952,
    'Laguna de Perlas': 952,
    'Muelle de los Bueyes': 952,
    'Nueva Guinea': 952,
    'Agadez': 71,
    'Alaghsas': 71,
    'Arlit': 71,
    'Bilma': 71,
    'Département de Bilma': 71,
    'Département de Tchirozérine': 71,
    'Département de Diffa': 72,
    'Département de Maïné-Soroa': 72,
    'Département de Nguigmi': 72,
    'Diffa': 72,
    'Maïné Soroa': 72,
    'Nguigmi': 72,
    'Boboye Department': 68,
    'Département de Dogondoutchi': 68,
    'Département de Dosso': 68,
    'Département de Gaya': 68,
    'Département de Loga': 68,
    'Dogondoutchi': 68,
    'Dosso': 68,
    'Gaya': 68,
    'Aguié': 70,
    'Dakoro': 70,
    'Département d’Aguié': 70,
    'Département de Dakoro': 70,
    'Département de Mayahi': 70,
    'Département de Tessaoua': 70,
    'Guidan Roumdji Department': 70,
    'Madarounfa': 70,
    'Maradi': 70,
    'Mayahi': 70,
    'Tessaoua': 70,
    'Tibiri': 70,
    'Abalak': 73,
    'Birni N Konni': 73,
    'Bouza': 73,
    'Département d   Illéla': 73,
    'Département de Birni Nkonni': 73,
    'Département de Bouza': 73,
    'Département de Keïta': 73,
    'Département de Madaoua': 73,
    'Département de Tahoua': 73,
    'Département de Tchin-Tabaraden': 73,
    'Illéla': 73,
    'Keïta': 73,
    'Madaoua': 73,
    'Tahoua': 73,
    'Tchintabaraden': 73,
    'Ayorou': 67,
    'Balleyara': 67,
    'Département de Filingué': 67,
    'Département de Ouallam': 67,
    'Département de Say': 67,
    'Filingué': 67,
    'Kollo': 67,
    'Kollo Department': 67,
    'Ouallam': 67,
    'Say': 67,
    'Téra': 67,
    'Tera Department': 67,
    'Tillabéri': 67,
    'Tillaberi Department': 67,
    'Département de Gouré': 69,
    'Département de Kantché': 69,
    'Département de Tânout': 69,
    'Gouré': 69,
    'Magaria': 69,
    'Matamey': 69,
    'Mirriah': 69,
    'Tanout': 69,
    'Zinder': 69,
    'Aba': 303,
    'Amaigbo': 303,
    'Arochukwu': 303,
    'Bende': 303,
    'Ohafia-Ifigh': 303,
    'Umuahia': 303,
    'Ganye': 320,
    'Gombi': 320,
    'Holma': 320,
    'Jimeta': 320,
    'Madagali': 320,
    'Mayo-Belwa': 320,
    'Mubi': 320,
    'Ngurore': 320,
    'Numan': 320,
    'Toungo': 320,
    'Yola': 320,
    'Eket': 304,
    'Esuk Oron': 304,
    'Ikot Ekpene': 304,
    'Itu': 304,
    'Uyo': 304,
    'Agulu': 315,
    'Atani': 315,
    'Awka': 315,
    'Enugu-Ukwu': 315,
    'Igbo-Ukwu': 315,
    'Ihiala': 315,
    'Nkpor': 315,
    'Nnewi': 315,
    'Onitsha': 315,
    'Ozubulu': 315,
    'Uga': 315,
    'Uruobo-Okija': 315,
    'Azare': 312,
    'Bauchi': 312,
    'Boi': 312,
    'Bununu': 312,
    'Darazo': 312,
    'Dass': 312,
    'Dindima': 312,
    'Disina': 312,
    'Gabarin': 312,
    'Gwaram': 312,
    'Kari': 312,
    'Lame': 312,
    'Lere': 312,
    'Madara': 312,
    'Misau': 312,
    'Sade': 312,
    'Yamrat': 312,
    'Yanda Bayo': 312,
    'Yuli': 312,
    'Zadawa': 312,
    'Zalanga': 312,
    'Amassoma': 305,
    'Twon-Brass': 305,
    'Yenagoa': 305,
    'Aliade': 291,
    'Boju': 291,
    'Igbor': 291,
    'Makurdi': 291,
    'Ochobo': 291,
    'Otukpa': 291,
    'Takum': 291,
    'Ugbokpo': 291,
    'Yandev': 291,
    'Zaki Biam': 291,
    'Bama': 307,
    'Benisheikh': 307,
    'Biu': 307,
    'Bornu Yassu': 307,
    'Damasak': 307,
    'Damboa': 307,
    'Dikwa': 307,
    'Gamboru': 307,
    'Gwoza': 307,
    'Kukawa': 307,
    'Magumeri': 307,
    'Maiduguri': 307,
    'Marte': 307,
    'Miringa': 307,
    'Monguno': 307,
    'Ngala': 307,
    'Shaffa': 307,
    'Shani': 307,
    'Tokombere': 307,
    'Uba': 307,
    'Wuyo': 307,
    'Yajiwa': 307,
    'Akankpa': 314,
    'Calabar': 314,
    'Gakem': 314,
    'Ikang': 314,
    'Ugep': 314,
    'Abraka': 316,
    'Agbor': 316,
    'Asaba': 316,
    'Bomadi': 316,
    'Burutu': 316,
    'Kwale': 316,
    'Obiaruku': 316,
    'Ogwashi-Uku': 316,
    'Orerokpe': 316,
    'Patani': 316,
    'Sapele': 316,
    'Ughelli': 316,
    'Umunede': 316,
    'Warri': 316,
    'Abakaliki': 311,
    'Afikpo': 311,
    'Effium': 311,
    'Ezza-Ohu': 311,
    'Isieke': 311,
    'Agenebode': 318,
    'Auchi': 318,
    'Benin City': 318,
    'Ekpoma': 318,
    'Igarra': 318,
    'Illushi': 318,
    'Siluko': 318,
    'Ubiaja': 318,
    'Uromi': 318,
    'Ado-Ekiti': 309,
    'Aramoko-Ekiti': 309,
    'Efon-Alaaye': 309,
    'Emure-Ekiti': 309,
    'Ifaki': 309,
    'Igbara-Odo': 309,
    'Igede-Ekiti': 309,
    'Ijero-Ekiti': 309,
    'Ikere-Ekiti': 309,
    'Ipoti': 309,
    'Ise-Ekiti': 309,
    'Oke Ila': 309,
    'Omuo-Ekiti': 309,
    'Adani': 289,
    'Ake-Eze': 289,
    'Aku': 289,
    'Amagunze': 289,
    'Awgu': 289,
    'Eha Amufu': 289,
    'Enugu': 289,
    'Enugu-Ezike': 289,
    'Ete': 289,
    'Ikem': 289,
    'Mberubu': 289,
    'Nsukka': 289,
    'Obolo-Eke (1)': 289,
    'Opi': 289,
    'Udi': 289,
    'Abuja': 293,
    'Kuje': 293,
    'Kwali': 293,
    'Madala': 293,
    'Akko': 310,
    'Bara': 310,
    'Billiri': 310,
    'Dadiya': 310,
    'Deba': 310,
    'Dukku': 310,
    'Garko': 310,
    'Gombe': 310,
    'Hinna': 310,
    'Kafarati': 310,
    'Kaltungo': 310,
    'Kumo': 310,
    'Nafada': 310,
    'Pindiga': 310,
    'Iho': 308,
    'Oguta': 308,
    'Okigwe': 308,
    'Orlu': 308,
    'Orodo': 308,
    'Owerri': 308,
    'Babura': 288,
    'Birnin Kudu': 288,
    'Birniwa': 288,
    'Dutse': 288,
    'Gagarawa': 288,
    'Gumel': 288,
    'Gwaram': 288,
    'Hadejia': 288,
    'Kafin Hausa': 288,
    'Kazaure': 288,
    'Kiyawa': 288,
    'Mallammaduri': 288,
    'Ringim': 288,
    'Samamiya': 288,
    'Anchau': 294,
    'Burumburum': 294,
    'Dutsen Wai': 294,
    'Hunkuyi': 294,
    'Kachia': 294,
    'Kaduna': 294,
    'Kafanchan': 294,
    'Kagoro': 294,
    'Kajuru': 294,
    'Kujama': 294,
    'Lere': 294,
    'Mando': 294,
    'Saminaka': 294,
    'Soba': 294,
    'Sofo-Birnin-Gwari': 294,
    'Zaria': 294,
    'Dan Gora': 300,
    'Gaya': 300,
    'Kano': 300,
    'Danja': 313,
    'Dankama': 313,
    'Daura': 313,
    'Dutsin-Ma': 313,
    'Funtua': 313,
    'Gora': 313,
    'Jibia': 313,
    'Jikamshi': 313,
    'Kankara': 313,
    'Katsina': 313,
    'Mashi': 313,
    'Ruma': 313,
    'Runka': 313,
    'Wagini': 313,
    'Argungu': 290,
    'Bagudo': 290,
    'Bena': 290,
    'Bin Yauri': 290,
    'Birnin Kebbi': 290,
    'Dabai': 290,
    'Dakingari': 290,
    'Gulma': 290,
    'Gwandu': 290,
    'Jega': 290,
    'Kamba': 290,
    'Kangiwa': 290,
    'Kende': 290,
    'Mahuta': 290,
    'Maiyama': 290,
    'Shanga': 290,
    'Wasagu': 290,
    'Zuru': 290,
    'Abocho': 298,
    'Adoru': 298,
    'Ankpa': 298,
    'Bugana': 298,
    'Dekina': 298,
    'Egbe': 298,
    'Icheu': 298,
    'Idah': 298,
    'Isanlu-Itedoijowa': 298,
    'Kabba': 298,
    'Koton-Karfe': 298,
    'Lokoja': 298,
    'Ogaminana': 298,
    'Ogurugu': 298,
    'Okene': 298,
    'Ajasse Ipo': 295,
    'Bode Saadu': 295,
    'Gwasero': 295,
    'Ilorin': 295,
    'Jebba': 295,
    'Kaiama': 295,
    'Lafiagi': 295,
    'Offa': 295,
    'Okuta': 295,
    'Omu-Aran': 295,
    'Patigi': 295,
    'Suya': 295,
    'Yashikera': 295,
    'Apapa': 306,
    'Badagry': 306,
    'Ebute Ikorodu': 306,
    'Ejirin': 306,
    'Epe': 306,
    'Ikeja': 306,
    'Lagos': 306,
    'Makoko': 306,
    'Buga': 301,
    'Doma': 301,
    'Keffi': 301,
    'Lafia': 301,
    'Nasarawa': 301,
    'Wamba': 301,
    'Auna': 317,
    'Babana': 317,
    'Badeggi': 317,
    'Baro': 317,
    'Bokani': 317,
    'Duku': 317,
    'Ibeto': 317,
    'Konkwesso': 317,
    'Kontagora': 317,
    'Kusheriki': 317,
    'Kuta': 317,
    'Lapai': 317,
    'Minna': 317,
    'New Shagunnu': 317,
    'Suleja': 317,
    'Tegina': 317,
    'Ukata': 317,
    'Wawa': 317,
    'Zungeru': 317,
    'Abeokuta': 323,
    'Ado Odo': 323,
    'Idi Iroko': 323,
    'Ifo': 323,
    'Ijebu-Ife': 323,
    'Ijebu-Igbo': 323,
    'Ijebu-Ode': 323,
    'Ilaro': 323,
    'Imeko': 323,
    'Iperu': 323,
    'Isara': 323,
    'Owode': 323,
    'Agbabu': 321,
    'Akure': 321,
    'Idanre': 321,
    'Ifon': 321,
    'Ilare': 321,
    'Ode': 321,
    'Ondo': 321,
    'Ore': 321,
    'Owo': 321,
    'Apomu': 322,
    'Ejigbo': 322,
    'Gbongan': 322,
    'Ijebu-Jesa': 322,
    'Ikire': 322,
    'Ikirun': 322,
    'Ila Orangun': 322,
    'Ile-Ife': 322,
    'Ilesa': 322,
    'Ilobu': 322,
    'Inisa': 322,
    'Iwo': 322,
    'Modakeke': 322,
    'Oke Mesi': 322,
    'Olupona': 322,
    'Osogbo': 322,
    'Otan Ayegbaju': 322,
    'Oyan': 322,
    'Ago Are': 296,
    'Alapa': 296,
    'Fiditi': 296,
    'Ibadan': 296,
    'Igbeti': 296,
    'Igbo-Ora': 296,
    'Igboho': 296,
    'Kisi': 296,
    'Lalupon': 296,
    'Ogbomoso': 296,
    'Okeho': 296,
    'Orita Eruwa': 296,
    'Oyo': 296,
    'Saki': 296,
    'Amper': 302,
    'Bukuru': 302,
    'Dengi': 302,
    'Jos': 302,
    'Kwolla': 302,
    'Langtang': 302,
    'Pankshin': 302,
    'Panyam': 302,
    'Vom': 302,
    'Yelwa': 302,
    'Binji': 292,
    'Dange': 292,
    'Gandi': 292,
    'Goronyo': 292,
    'Gwadabawa': 292,
    'Illela': 292,
    'Rabah': 292,
    'Sokoto': 292,
    'Tambuwal': 292,
    'Wurno': 292,
    'Baissa': 319,
    'Beli': 319,
    'Gassol': 319,
    'Gembu': 319,
    'Ibi': 319,
    'Jalingo': 319,
    'Lau': 319,
    'Mutum Biyu': 319,
    'Riti': 319,
    'Wukari': 319,
    'Damaturu': 297,
    'Dankalwa': 297,
    'Dapchi': 297,
    'Daura': 297,
    'Fika': 297,
    'Gashua': 297,
    'Geidam': 297,
    'Goniri': 297,
    'Gorgoram': 297,
    'Gujba': 297,
    'Gwio Kura': 297,
    'Kumagunnam': 297,
    'Lajere': 297,
    'Machina': 297,
    'Nguru': 297,
    'Potiskum': 297,
    'Anka': 299,
    'Dan Sadau': 299,
    'Gummi': 299,
    'Gusau': 299,
    'Kaura Namoda': 299,
    'Kwatarkwashi': 299,
    'Maru': 299,
    'Moriki': 299,
    'Sauri': 299,
    'Tsafe': 299,
    'Åneby': 1017,
    'Årnes': 1017,
    'Ås': 1017,
    'Ask': 1017,
    'Asker': 1017,
    'Auli': 1017,
    'Aurskog-Høland': 1017,
    'Aursmoen': 1017,
    'Bærum': 1017,
    'Billingstad': 1017,
    'Bjørkelangen': 1017,
    'Blakstad': 1017,
    'Drøbak': 1017,
    'Eidsvoll': 1017,
    'Enebakk': 1017,
    'Fagerstrand': 1017,
    'Fet': 1017,
    'Fetsund': 1017,
    'Fjellfoten': 1017,
    'Flateby': 1017,
    'Frogn': 1017,
    'Frogner': 1017,
    'Gjerdrum': 1017,
    'Hurdal': 1017,
    'Jessheim': 1017,
    'Kløfta': 1017,
    'Leirsund': 1017,
    'Lillestrøm': 1017,
    'Lørenskog': 1017,
    'Lysaker': 1017,
    'Maura': 1017,
    'Nannestad': 1017,
    'Nes': 1017,
    'Neskollen': 1017,
    'Nesodden': 1017,
    'Nesoddtangen': 1017,
    'Nittedal': 1017,
    'Oppegård': 1017,
    'Råholt': 1017,
    'Rælingen': 1017,
    'Rotnes': 1017,
    'Skedsmo': 1017,
    'Ski': 1017,
    'Skui': 1017,
    'Sørum': 1017,
    'Sørumsand': 1017,
    'Ullensaker': 1017,
    'Vestby': 1017,
    'Ål': 1011,
    'Åros': 1011,
    'Drammen': 1011,
    'Flå': 1011,
    'Flesberg': 1011,
    'Geilo': 1011,
    'Gol': 1011,
    'Hemsedal': 1011,
    'Hol': 1011,
    'Hole': 1011,
    'Hønefoss': 1011,
    'Hurum': 1011,
    'Hvittingfoss': 1011,
    'Kongsberg': 1011,
    'Krødsherad': 1011,
    'Lier': 1011,
    'Modum': 1011,
    'Nedre Eiker': 1011,
    'Nes': 1011,
    'Nesbyen': 1011,
    'Nore og Uvdal': 1011,
    'Noresund': 1011,
    'Øvre Eiker': 1011,
    'Prestfoss': 1011,
    'Ringerike': 1011,
    'Rollag': 1011,
    'Røyken': 1011,
    'Sætre': 1011,
    'Sigdal': 1011,
    'Skoger': 1011,
    'Tofte': 1011,
    'Tranby': 1011,
    'Vikersund': 1011,
    'Alta': 1016,
    'Ávanuorri': 1016,
    'Båtsfjord': 1016,
    'Berlevåg': 1016,
    'Bjørnevatn': 1016,
    'Gamvik': 1016,
    'Hammerfest': 1016,
    'Hasvik': 1016,
    'Honningsvåg': 1016,
    'Kárášjohka': 1016,
    'Karasjok': 1016,
    'Kautokeino': 1016,
    'Kirkenes': 1016,
    'Kjøllefjord': 1016,
    'Kvalsund': 1016,
    'Lakselv': 1016,
    'Lebesby': 1016,
    'Loppa': 1016,
    'Måsøy': 1016,
    'Mehamn': 1016,
    'Nesseby': 1016,
    'Nordkapp': 1016,
    'Øksfjord': 1016,
    'Porsanger': 1016,
    'Rypefjord': 1016,
    'Sør-Varanger': 1016,
    'Tana': 1016,
    'Vadsø': 1016,
    'Vardø': 1016,
    'Alvdal': 1019,
    'Åmot': 1019,
    'Åsnes': 1019,
    'Brumunddal': 1019,
    'Eidskog': 1019,
    'Elverum': 1019,
    'Engerdal': 1019,
    'Folldal': 1019,
    'Grue': 1019,
    'Hamar': 1019,
    'Innbygda': 1019,
    'Kirkenær': 1019,
    'Kongsvinger': 1019,
    'Koppang': 1019,
    'Løten': 1019,
    'Moelv': 1019,
    'Nord-Odal': 1019,
    'Os': 1019,
    'Rena': 1019,
    'Rendalen': 1019,
    'Ringsaker': 1019,
    'Sand': 1019,
    'Skarnes': 1019,
    'Skotterud': 1019,
    'Sør-Odal': 1019,
    'Spetalen': 1019,
    'Stange': 1019,
    'Stor-Elvdal': 1019,
    'Tolga': 1019,
    'Trysil': 1019,
    'Tynset': 1019,
    'Våler': 1019,
    'Ågotnes': 1023,
    'Askøy': 1023,
    'Austevoll': 1023,
    'Austrheim': 1023,
    'Bergen': 1023,
    'Bømlo': 1023,
    'Dale': 1023,
    'Eidfjord': 1023,
    'Eikelandsosen': 1023,
    'Espeland': 1023,
    'Etne': 1023,
    'Fedje': 1023,
    'Fitjar': 1023,
    'Fjell': 1023,
    'Frekhaug': 1023,
    'Fusa': 1023,
    'Granvin': 1023,
    'Hagavik': 1023,
    'Hylkje': 1023,
    'Indre Arna': 1023,
    'Jondal': 1023,
    'Kinsarvik': 1023,
    'Knappskog': 1023,
    'Knarvik': 1023,
    'Kvam': 1023,
    'Kvinnherad': 1023,
    'Leirvik': 1023,
    'Lindås': 1023,
    'Lonevåg': 1023,
    'Manger': 1023,
    'Masfjorden': 1023,
    'Meland': 1023,
    'Modalen': 1023,
    'Mosterhamn': 1023,
    'Norheimsund': 1023,
    'Odda': 1023,
    'Os': 1023,
    'Osterøy': 1023,
    'Øygarden': 1023,
    'Øystese': 1023,
    'Radøy': 1023,
    'Rosendal': 1023,
    'Rubbestadneset': 1023,
    'Sagvåg': 1023,
    'Samnanger': 1023,
    'Sandsli': 1023,
    'Skogsvågen': 1023,
    'Stord': 1023,
    'Storebø': 1023,
    'Straume': 1023,
    'Sund': 1023,
    'Sveio': 1023,
    'Syfteland': 1023,
    'Tysnes': 1023,
    'Ullensvang': 1023,
    'Ulvik': 1023,
    'Vaksdal': 1023,
    'Voss': 1023,
    'Ytre Arna': 1023,
    'Ytrebygda': 1023,
    'Ålesund': 1020,
    'Åndalsnes': 1020,
    'Aukra': 1020,
    'Aure': 1020,
    'Averøy': 1020,
    'Batnfjordsøra': 1020,
    'Brattvåg': 1020,
    'Eide': 1020,
    'Eidsvåg': 1020,
    'Elnesvågen': 1020,
    'Fræna': 1020,
    'Giske': 1020,
    'Gjemnes': 1020,
    'Halsa': 1020,
    'Haram': 1020,
    'Hareid': 1020,
    'Herøy': 1020,
    'Hjelset': 1020,
    'Hopen': 1020,
    'Kristiansund': 1020,
    'Larsnes': 1020,
    'Midsund': 1020,
    'Molde': 1020,
    'Nesset': 1020,
    'Norddal': 1020,
    'Nordstranda': 1020,
    'Ørskog': 1020,
    'Ørsta': 1020,
    'Rauma': 1020,
    'Rensvik': 1020,
    'Sande': 1020,
    'Sandøy': 1020,
    'Sjøholt': 1020,
    'Skodje': 1020,
    'Smøla': 1020,
    'Steinshamn': 1020,
    'Stordal': 1020,
    'Stranda': 1020,
    'Sula': 1020,
    'Sunndal': 1020,
    'Sunndalsøra': 1020,
    'Surnadal': 1020,
    'Sykkylven': 1020,
    'Tingvoll': 1020,
    'Tomra': 1020,
    'Ulstein': 1020,
    'Ulsteinvik': 1020,
    'Ulsteinvik weather pws station': 1020,
    'Vanylven': 1020,
    'Vatne': 1020,
    'Vestnes': 1020,
    'Volda': 1020,
    'Alstahaug': 1025,
    'Andenes': 1025,
    'Andøy': 1025,
    'Ballangen': 1025,
    'Beiarn': 1025,
    'Bindal': 1025,
    'Bjerkvik': 1025,
    'Bodø': 1025,
    'Bogen': 1025,
    'Bø': 1025,
    'Brønnøy': 1025,
    'Brønnøysund': 1025,
    'Dønna': 1025,
    'Evenes': 1025,
    'Evjen': 1025,
    'Fauske': 1025,
    'Flakstad': 1025,
    'Gildeskål': 1025,
    'Gladstad': 1025,
    'Glomfjord': 1025,
    'Grane': 1025,
    'Gravdal': 1025,
    'Hadsel': 1025,
    'Hamarøy': 1025,
    'Hattfjelldal': 1025,
    'Hauknes': 1025,
    'Hemnes': 1025,
    'Hemnesberget': 1025,
    'Herøy': 1025,
    'Inndyr': 1025,
    'Kabelvåg': 1025,
    'Kjøpsvik': 1025,
    'Korgen': 1025,
    'Leirfjord': 1025,
    'Leknes': 1025,
    'Leland': 1025,
    'Løding': 1025,
    'Lødingen': 1025,
    'Løpsmarka': 1025,
    'Lurøy': 1025,
    'Melbu': 1025,
    'Meløy': 1025,
    'Mo i Rana': 1025,
    'Mosjøen': 1025,
    'Moskenes': 1025,
    'Myre': 1025,
    'Narvik': 1025,
    'Nesna': 1025,
    'Øksnes': 1025,
    'Ørnes': 1025,
    'Rana': 1025,
    'Reine': 1025,
    'Rognan': 1025,
    'Rødøy': 1025,
    'Røst': 1025,
    'Saltdal': 1025,
    'Sandnessjøen': 1025,
    'Sortland': 1025,
    'Sømna': 1025,
    'Sørfold': 1025,
    'Sørland': 1025,
    'Steigen': 1025,
    'Stokmarknes': 1025,
    'Straume': 1025,
    'Straumen': 1025,
    'Svolvær': 1025,
    'Terråk': 1025,
    'Tjeldsund': 1025,
    'Træna': 1025,
    'Trofors': 1025,
    'Tysfjord': 1025,
    'Vågan': 1025,
    'Værøy': 1025,
    'Vefsn': 1025,
    'Vega': 1025,
    'Vestvågøy': 1025,
    'Vevelstad': 1025,
    'Vik': 1025,
    'Bagn': 1009,
    'Dokka': 1009,
    'Dombås': 1009,
    'Dovre': 1009,
    'Etnedal': 1009,
    'Fagernes': 1009,
    'Fossbergom': 1009,
    'Gausdal': 1009,
    'Gjøvik': 1009,
    'Gran': 1009,
    'Grua': 1009,
    'Hov': 1009,
    'Hundorp': 1009,
    'Jevnaker': 1009,
    'Lena': 1009,
    'Lesja': 1009,
    'Lillehammer': 1009,
    'Lom': 1009,
    'Lunner': 1009,
    'Nord-Aurdal': 1009,
    'Nord-Fron': 1009,
    'Nordre Land': 1009,
    'Otta': 1009,
    'Østre Toten': 1009,
    'Øyer': 1009,
    'Øystre Slidre': 1009,
    'Raufoss': 1009,
    'Reinsvoll': 1009,
    'Ringebu': 1009,
    'Sel': 1009,
    'Skjåk': 1009,
    'Skreia': 1009,
    'Søndre Land': 1009,
    'Sør-Aurdal': 1009,
    'Sør-Fron': 1009,
    'Tretten': 1009,
    'Vågå': 1009,
    'Vågåmo': 1009,
    'Vang': 1009,
    'Vestre Slidre': 1009,
    'Vestre Toten': 1009,
    'Vinstra': 1009,
    'Oslo': 1007,
    'Sjølyststranda': 1007,
    'Aremark': 1022,
    'Askim': 1022,
    'Eidsberg': 1022,
    'Fossby': 1022,
    'Fredrikstad': 1022,
    'Halden': 1022,
    'Hobøl': 1022,
    'Hvaler': 1022,
    'Karlshus': 1022,
    'Knappstad': 1022,
    'Larkollen': 1022,
    'Lervik': 1022,
    'Marker': 1022,
    'Moss': 1022,
    'Mysen': 1022,
    'Ørje': 1022,
    'Råde': 1022,
    'Rakkestad': 1022,
    'Rømskog': 1022,
    'Rygge': 1022,
    'Ryggebyen': 1022,
    'Sarpsborg': 1022,
    'Skiptvet': 1022,
    'Skjærhalden': 1022,
    'Skjeberg': 1022,
    'Spydeberg': 1022,
    'Tomter': 1022,
    'Trøgstad': 1022,
    'Våler': 1022,
    'Åkrehamn': 1021,
    'Bjerkreim': 1021,
    'Bokn': 1021,
    'Bryne': 1021,
    'Egersund': 1021,
    'Eigersund': 1021,
    'Eike': 1021,
    'Finnøy': 1021,
    'Forsand': 1021,
    'Gjesdal': 1021,
    'Hå': 1021,
    'Hauge i Dalane': 1021,
    'Haugesund': 1021,
    'Hjelmeland': 1021,
    'Hommersåk': 1021,
    'Jørpeland': 1021,
    'Judaberg': 1021,
    'Karmøy': 1021,
    'Klepp': 1021,
    'Kopervik': 1021,
    'Kvitsøy': 1021,
    'Lund': 1021,
    'Lyefjell': 1021,
    'Moi': 1021,
    'Nærbø': 1021,
    'Ølen': 1021,
    'Randaberg': 1021,
    'Rennesøy': 1021,
    'Sandnes': 1021,
    'Sauda': 1021,
    'Sæveland': 1021,
    'Skudeneshavn': 1021,
    'Sokndal': 1021,
    'Sola': 1021,
    'Stavanger': 1021,
    'Strand': 1021,
    'Suldal': 1021,
    'Tananger': 1021,
    'Tau': 1021,
    'Time': 1021,
    'Tysvær': 1021,
    'Utsira': 1021,
    'Varhaug': 1021,
    'Vedavågen': 1021,
    'Vigrestad': 1021,
    'Vikeså': 1021,
    'Vikevåg': 1021,
    'Vindafjord': 1021,
    'Vormedal': 1021,
    'Årdal': 1018,
    'Årdalstangen': 1018,
    'Askvoll': 1018,
    'Aurland': 1018,
    'Balestrand': 1018,
    'Bremanger': 1018,
    'Dale': 1018,
    'Eid': 1018,
    'Eivindvik': 1018,
    'Farnes': 1018,
    'Fjaler': 1018,
    'Flora': 1018,
    'Florø': 1018,
    'Førde': 1018,
    'Gaular': 1018,
    'Gaupne': 1018,
    'Gloppen': 1018,
    'Gulen': 1018,
    'Hardbakke': 1018,
    'Hermansverk': 1018,
    'Hornindal': 1018,
    'Høyanger': 1018,
    'Hyllestad': 1018,
    'Jølster': 1018,
    'Lærdal': 1018,
    'Lærdalsøyri': 1018,
    'Leikanger': 1018,
    'Luster': 1018,
    'Måløy': 1018,
    'Naustdal': 1018,
    'Nordfjordeid': 1018,
    'Sandane': 1018,
    'Sande': 1018,
    'Selje': 1018,
    'Sogndal': 1018,
    'Solund': 1018,
    'Stryn': 1018,
    'Svelgen': 1018,
    'Vågsøy': 1018,
    'Vik': 1018,
    'Bamble': 1024,
    'Bø': 1024,
    'Dalen': 1024,
    'Drangedal': 1024,
    'Fyresdal': 1024,
    'Gvarv': 1024,
    'Herre': 1024,
    'Hjartdal': 1024,
    'Kragerø': 1024,
    'Kviteseid': 1024,
    'Lunde': 1024,
    'Nissedal': 1024,
    'Nome': 1024,
    'Notodden': 1024,
    'Porsgrunn': 1024,
    'Prestestranda': 1024,
    'Rjukan': 1024,
    'Sauherad': 1024,
    'Seljord': 1024,
    'Siljan': 1024,
    'Skien': 1024,
    'Tinn': 1024,
    'Tokke': 1024,
    'Ulefoss': 1024,
    'Vinje': 1024,
    'Balsfjord': 1015,
    'Bardu': 1015,
    'Berg': 1015,
    'Borkenes': 1015,
    'Dyrøy': 1015,
    'Evenskjer': 1015,
    'Finnsnes': 1015,
    'Gratangen': 1015,
    'Gryllefjord': 1015,
    'Hansnes': 1015,
    'Harstad': 1015,
    'Ibestad': 1015,
    'Kåfjord': 1015,
    'Karlsøy': 1015,
    'Kvæfjord': 1015,
    'Kvænangen': 1015,
    'Lavangen': 1015,
    'Lenvik': 1015,
    'Lyngen': 1015,
    'Lyngseidet': 1015,
    'Målselv': 1015,
    'Moen': 1015,
    'Nordreisa': 1015,
    'Olderdalen': 1015,
    'Salangen': 1015,
    'Setermoen': 1015,
    'Sjøvegan': 1015,
    'Skånland': 1015,
    'Skjervøy': 1015,
    'Sørreisa': 1015,
    'Storfjord': 1015,
    'Storslett': 1015,
    'Storsteinnes': 1015,
    'Torsken': 1015,
    'Tranøy': 1015,
    'Tromsø': 1015,
    'Å i Åfjord': 1006,
    'Åfjord': 1006,
    'Agdenes': 1006,
    'Berkåk': 1006,
    'Bjugn': 1006,
    'Botngård': 1006,
    'Brekstad': 1006,
    'Fillan': 1006,
    'Flatanger': 1006,
    'Fosnes': 1006,
    'Frosta': 1006,
    'Frøya': 1006,
    'Grong': 1006,
    'Hemne': 1006,
    'Hitra': 1006,
    'Holtålen': 1006,
    'Hommelvik': 1006,
    'Høylandet': 1006,
    'Inderøy': 1006,
    'Indre Fosen': 1006,
    'Klæbu': 1006,
    'Kolvereid': 1006,
    'Kyrksæterøra': 1006,
    'Lauvsnes': 1006,
    'Leka': 1006,
    'Levanger': 1006,
    'Lierne': 1006,
    'Lundamo': 1006,
    'Malm': 1006,
    'Malvik': 1006,
    'Meldal': 1006,
    'Melhus': 1006,
    'Meråker': 1006,
    'Midtre Gauldal': 1006,
    'Namdalseid': 1006,
    'Namsos': 1006,
    'Namsskogan': 1006,
    'Nærøy': 1006,
    'Oppdal': 1006,
    'Orkdal': 1006,
    'Osen': 1006,
    'Overhalla': 1006,
    'Ørland': 1006,
    'Raarvihke - Røyrvik': 1006,
    'Ranemsletta': 1006,
    'Rennebu': 1006,
    'Rindal': 1006,
    'Roan': 1006,
    'Røros': 1006,
    'Rørvik': 1006,
    'Røyrvik': 1006,
    'Selbu': 1006,
    'Sistranda': 1006,
    'Skaun': 1006,
    'Skogn': 1006,
    'Snåase': 1006,
    'Snåase - Snåsa': 1006,
    'Snillfjord': 1006,
    'Steinkjer': 1006,
    'Stjørdal': 1006,
    'Stjørdalshalsen': 1006,
    'Straumen': 1006,
    'Trondheim': 1006,
    'Tydal': 1006,
    'Verdal': 1006,
    'Verran': 1006,
    'Vikna': 1006,
    'Åseral': 1014,
    'Audnedal': 1014,
    'Farsund': 1014,
    'Flekkefjord': 1014,
    'Hægebostad': 1014,
    'Justvik': 1014,
    'Kristiansand': 1014,
    'Kvinesdal': 1014,
    'Liknes': 1014,
    'Lindesnes': 1014,
    'Lyngdal': 1014,
    'Mandal': 1014,
    'Marnardal': 1014,
    'Nodeland': 1014,
    'Sirdal': 1014,
    'Skålevik': 1014,
    'Songdalen': 1014,
    'Søgne': 1014,
    'Strai': 1014,
    'Tonstad': 1014,
    'Tveit': 1014,
    'Vanse': 1014,
    'Vennesla': 1014,
    'Vestbygd': 1014,
    'Vigeland': 1014,
    'Årøysund': 1008,
    'Åsgårdstrand': 1008,
    'Barkåker': 1008,
    'Berger': 1008,
    'Færder': 1008,
    'Gullhaug': 1008,
    'Holmestrand': 1008,
    'Horten': 1008,
    'Larvik': 1008,
    'Melsomvik': 1008,
    'Re': 1008,
    'Sande': 1008,
    'Sandefjord': 1008,
    'Selvik': 1008,
    'Sem': 1008,
    'Skoppum': 1008,
    'Stavern': 1008,
    'Stokke': 1008,
    'Svelvik': 1008,
    'Tjøme': 1008,
    'Tønsberg': 1008,
    'Adam': 3058,
    'Bahlā’': 3058,
    'Bidbid': 3058,
    'Izkī': 3058,
    'Nizwá': 3058,
    'Sufālat Samā’il': 3058,
    '‘Ibrī': 3047,
    'Yanqul': 3047,
    'Al Khābūrah': 3048,
    'As Suwayq': 3048,
    'Liwá': 3048,
    'Şaḩam': 3048,
    'Shināş': 3048,
    'Sohar': 3048,
    'Barkā’': 3050,
    'Bayt al ‘Awābī': 3050,
    'Oman Smart Future City': 3050,
    'Rustaq': 3050,
    'Al Buraymī': 3059,
    'Haymā’': 3056,
    'Sur': 3051,
    'Şalālah': 3057,
    'Dib Dibba': 3052,
    'Khasab': 3052,
    'Madḩā’ al Jadīdah': 3052,
    'Bawshar': 3055,
    'Muscat': 3055,
    'Seeb': 3055,
    'Bhimbar': 3172,
    'Kotli': 3172,
    'Kotli District': 3172,
    'Mirpur District': 3172,
    'Muzaffarābād': 3172,
    'New Mirpur': 3172,
    'Rawala Kot': 3172,
    'Alik Ghund': 3174,
    'Awārān District': 3174,
    'Barkhan': 3174,
    'Bārkhān District': 3174,
    'Bela': 3174,
    'Bhag': 3174,
    'Chāgai District': 3174,
    'Chaman': 3174,
    'Chowki Jamali': 3174,
    'Dadhar': 3174,
    'Dalbandin': 3174,
    'Dera Bugti': 3174,
    'Dera Bugti District': 3174,
    'Duki': 3174,
    'Gadani': 3174,
    'Garhi Khairo': 3174,
    'Gwadar': 3174,
    'Harnai': 3174,
    'Jāfarābād District': 3174,
    'Jhal Magsi District': 3174,
    'Jiwani': 3174,
    'Kalat': 3174,
    'Kalāt District': 3174,
    'Khadan Khak': 3174,
    'Kharan': 3174,
    'Khārān District': 3174,
    'Khuzdar': 3174,
    'Khuzdār District': 3174,
    'Kohlu': 3174,
    'Kot Malik Barkhurdar': 3174,
    'Lasbela District': 3174,
    'Loralai': 3174,
    'Loralai District': 3174,
    'Mach': 3174,
    'Mastung': 3174,
    'Mastung District': 3174,
    'Mehrabpur': 3174,
    'Mūsa Khel District': 3174,
    'Nasīrābād District': 3174,
    'Nushki': 3174,
    'Ormara': 3174,
    'Panjgūr District': 3174,
    'Pasni': 3174,
    'Pishin': 3174,
    'Qila Saifullāh District': 3174,
    'Quetta': 3174,
    'Quetta District': 3174,
    'Sibi': 3174,
    'Sohbatpur': 3174,
    'Surab': 3174,
    'Turbat': 3174,
    'Usta Muhammad': 3174,
    'Uthal': 3174,
    'Zhob': 3174,
    'Zhob District': 3174,
    'Ziarat': 3174,
    'Ziārat District': 3174,
    'Alizai': 3173,
    'Gulishah Kach': 3173,
    'Landi Kotal': 3173,
    'Miran Shah': 3173,
    'North Wazīristān Agency': 3173,
    'Shinpokh': 3173,
    'South Wazīristān Agency': 3173,
    'Wana': 3173,
    'Barishal': 3170,
    'Gilgit': 3170,
    'Skardu': 3170,
    'Islamabad': 3169,
    'Abbottabad': 3171,
    'Akora': 3171,
    'Aman Garh': 3171,
    'Amirabad': 3171,
    'Ashanagro Koto': 3171,
    'Baffa': 3171,
    'Bannu': 3171,
    'Bat Khela': 3171,
    'Battagram': 3171,
    'Battagram District': 3171,
    'Buner District': 3171,
    'Charsadda': 3171,
    'Cherat Cantonement': 3171,
    'Chitral': 3171,
    'Dera Ismail Khan': 3171,
    'Dera Ismāīl Khān District': 3171,
    'Doaba': 3171,
    'Hangu': 3171,
    'Haripur': 3171,
    'Havelian': 3171,
    'Kakad Wari Dir Upper': 3171,
    'Karak': 3171,
    'Khalabat': 3171,
    'Kohat': 3171,
    'Kulachi': 3171,
    'Lachi': 3171,
    'Lakki': 3171,
    'Mansehra': 3171,
    'Mardan': 3171,
    'Mingora': 3171,
    'Noorabad': 3171,
    'Nowshera': 3171,
    'Nowshera Cantonment': 3171,
    'Pabbi': 3171,
    'Paharpur': 3171,
    'Peshawar': 3171,
    'Risalpur Cantonment': 3171,
    'Sarai Naurang': 3171,
    'Shabqadar': 3171,
    'Shingli Bala': 3171,
    'Shorkot': 3171,
    'Swabi': 3171,
    'Tangi': 3171,
    'Tank': 3171,
    'Thal': 3171,
    'Topi': 3171,
    'Upper Dir': 3171,
    'Utmanzai': 3171,
    'Zaida': 3171,
    'Ahmadpur Sial': 3176,
    'Ahmedpur East': 3176,
    'Alipur Chatha': 3176,
    'Arifwala': 3176,
    'Attock Tehsil': 3176,
    'Baddomalhi': 3176,
    'Bahawalnagar': 3176,
    'Bahawalpur': 3176,
    'Bakhri Ahmad Khan': 3176,
    'Basirpur': 3176,
    'Basti Dosa': 3176,
    'Begowala': 3176,
    'Bhakkar': 3176,
    'Bhalwal': 3176,
    'Bhawana': 3176,
    'Bhera': 3176,
    'Bhopalwala': 3176,
    'Burewala': 3176,
    'Chak Azam Saffo': 3176,
    'Chak Jhumra': 3176,
    'Chak One Hundred Twenty Nine Left': 3176,
    'Chak Thirty-one -Eleven Left': 3176,
    'Chak Two Hundred Forty-Nine TDA': 3176,
    'Chakwal': 3176,
    'Chawinda': 3176,
    'Chichawatni': 3176,
    'Chiniot': 3176,
    'Chishtian': 3176,
    'Choa Saidanshah': 3176,
    'Chuhar Kana': 3176,
    'Chunian': 3176,
    'Daira Din Panah': 3176,
    'Dajal': 3176,
    'Dandot RS': 3176,
    'Darya Khan': 3176,
    'Daska': 3176,
    'Daud Khel': 3176,
    'Daultala': 3176,
    'Dera Ghazi Khan': 3176,
    'Dhanot': 3176,
    'Dhaunkal': 3176,
    'Dhok Awan': 3176,
    'Dijkot': 3176,
    'Dinan Bashnoian Wala': 3176,
    'Dinga': 3176,
    'Dipalpur': 3176,
    'Dullewala': 3176,
    'Dunga Bunga': 3176,
    'Dunyapur': 3176,
    'Eminabad': 3176,
    'Faisalabad': 3176,
    'Faqirwali': 3176,
    'Faruka': 3176,
    'Fazilpur': 3176,
    'Ferozewala': 3176,
    'Fort Abbas': 3176,
    'Garh Maharaja': 3176,
    'Gojra': 3176,
    'Gujar Khan': 3176,
    'Gujranwala': 3176,
    'Gujranwala Division': 3176,
    'Gujrat': 3176,
    'Hadali': 3176,
    'Hafizabad': 3176,
    'Harnoli': 3176,
    'Harunabad': 3176,
    'Hasan Abdal': 3176,
    'Hasilpur': 3176,
    'Haveli Lakha': 3176,
    'Hazro': 3176,
    'Hujra Shah Muqeem': 3176,
    'Jahanian Shah': 3176,
    'Jalalpur Jattan': 3176,
    'Jalalpur Pirwala': 3176,
    'Jampur': 3176,
    'Jand': 3176,
    'Jandiala Sher Khan': 3176,
    'Jaranwala': 3176,
    'Jatoi Shimali': 3176,
    'Jauharabad': 3176,
    'Jhang': 3176,
    'Jhang Sadar': 3176,
    'Jhawarian': 3176,
    'Jhelum': 3176,
    'Kabirwala': 3176,
    'Kahna Nau': 3176,
    'Kahuta': 3176,
    'Kalabagh': 3176,
    'Kalaswala': 3176,
    'Kaleke Mandi': 3176,
    'Kallar Kahar': 3176,
    'Kalur Kot': 3176,
    'Kamalia': 3176,
    'Kamar Mushani': 3176,
    'Kamoke': 3176,
    'Kamra': 3176,
    'Kanganpur': 3176,
    'Karor': 3176,
    'Kasur': 3176,
    'Keshupur': 3176,
    'Khairpur Tamiwali': 3176,
    'Khandowa': 3176,
    'Khanewal': 3176,
    'Khanga Dogran': 3176,
    'Khangarh': 3176,
    'Khanpur': 3176,
    'Kharian': 3176,
    'Khewra': 3176,
    'Khurrianwala': 3176,
    'Khushab': 3176,
    'Kohror Pakka': 3176,
    'Kot Addu Tehsil': 3176,
    'Kot Ghulam Muhammad': 3176,
    'Kot Mumin': 3176,
    'Kot Radha Kishan': 3176,
    'Kot Rajkour': 3176,
    'Kot Samaba': 3176,
    'Kot Sultan': 3176,
    'Kotli Loharan': 3176,
    'Kundian': 3176,
    'Kunjah': 3176,
    'Ladhewala Waraich': 3176,
    'Lahore': 3176,
    'Lala Musa': 3176,
    'Lalian': 3176,
    'Layyah': 3176,
    'Layyah District': 3176,
    'Liliani': 3176,
    'Lodhran': 3176,
    'Mailsi': 3176,
    'Malakwal': 3176,
    'Malakwal City': 3176,
    'Mamu Kanjan': 3176,
    'Mananwala': 3176,
    'Mandi Bahauddin': 3176,
    'Mandi Bahauddin District': 3176,
    'Mangla': 3176,
    'Mankera': 3176,
    'Mehmand Chak': 3176,
    'Mian Channun': 3176,
    'Mianke Mor': 3176,
    'Mianwali': 3176,
    'Minchinabad': 3176,
    'Mitha Tiwana': 3176,
    'Moza Shahwala': 3176,
    'Multan': 3176,
    'Multan District': 3176,
    'Muridke': 3176,
    'Murree': 3176,
    'Mustafabad': 3176,
    'Muzaffargarh': 3176,
    'Nankana Sahib': 3176,
    'Narang Mandi': 3176,
    'Narowal': 3176,
    'Naushahra Virkan': 3176,
    'Nazir Town': 3176,
    'Okara': 3176,
    'Pakki Shagwanwali': 3176,
    'Pakpattan': 3176,
    'Pasrur': 3176,
    'Pattoki': 3176,
    'Phalia': 3176,
    'Pind Dadan Khan': 3176,
    'Pindi Bhattian': 3176,
    'Pindi Gheb': 3176,
    'Pir Mahal': 3176,
    'Qadirpur Ran': 3176,
    'Qila Didar Singh': 3176,
    'Rabwah': 3176,
    'Rahim Yar Khan': 3176,
    'Rahimyar Khan District': 3176,
    'Raiwind': 3176,
    'Raja Jang': 3176,
    'Rajanpur': 3176,
    'Rasulnagar': 3176,
    'Rawalpindi': 3176,
    'Rawalpindi District': 3176,
    'Renala Khurd': 3176,
    'Rojhan': 3176,
    'Sadiqabad': 3176,
    'Sahiwal': 3176,
    'Sambrial': 3176,
    'Sangla Hill': 3176,
    'Sanjwal': 3176,
    'Sarai Alamgir': 3176,
    'Sarai Sidhu': 3176,
    'Sargodha': 3176,
    'Shahkot Tehsil': 3176,
    'Shahpur': 3176,
    'Shahr Sultan': 3176,
    'Shakargarh': 3176,
    'Sharqpur': 3176,
    'Sheikhupura': 3176,
    'Shorkot': 3176,
    'Shujaabad': 3176,
    'Sialkot': 3176,
    'Sillanwali': 3176,
    'Sodhra': 3176,
    'Sukheke Mandi': 3176,
    'Surkhpur': 3176,
    'Talagang': 3176,
    'Talamba': 3176,
    'Tandlianwala': 3176,
    'Taunsa': 3176,
    'Toba Tek Singh': 3176,
    'Umerkot': 3176,
    'Vihari': 3176,
    'Wah': 3176,
    'Warburton': 3176,
    'Wazirabad': 3176,
    'West Punjab': 3176,
    'Yazman': 3176,
    'Zafarwal': 3176,
    'Zahir Pir': 3176,
    'Adilpur': 3175,
    'Badin': 3175,
    'Bagarji': 3175,
    'Bandhi': 3175,
    'Berani': 3175,
    'Bhan': 3175,
    'Bhiria': 3175,
    'Bhit Shah': 3175,
    'Bozdar Wada': 3175,
    'Bulri': 3175,
    'Chak': 3175,
    'Chamber': 3175,
    'Chhor': 3175,
    'Chuhar Jamali': 3175,
    'Dadu': 3175,
    'Daromehar': 3175,
    'Darya Khan Marri': 3175,
    'Daulatpur': 3175,
    'Daur': 3175,
    'Dhoro Naro': 3175,
    'Digri': 3175,
    'Diplo': 3175,
    'Dokri': 3175,
    'Gambat': 3175,
    'Garhiyasin': 3175,
    'Gharo': 3175,
    'Ghauspur': 3175,
    'Ghotki': 3175,
    'Goth Garelo': 3175,
    'Goth Phulji': 3175,
    'Goth Radhan': 3175,
    'Hala': 3175,
    'Hingorja': 3175,
    'Hyderabad': 3175,
    'Islamkot': 3175,
    'Jacobabad': 3175,
    'Jām Sāhib': 3175,
    'Jamshoro': 3175,
    'Jati': 3175,
    'Jhol': 3175,
    'Johi': 3175,
    'Kadhan': 3175,
    'Kambar': 3175,
    'Kandhkot': 3175,
    'Kandiari': 3175,
    'Kandiaro': 3175,
    'Karachi': 3175,
    'Karaundi': 3175,
    'Kario Ghanwar': 3175,
    'Kashmor': 3175,
    'Keti Bandar': 3175,
    'Khadro': 3175,
    'Khairpur': 3175,
    'Khairpur Mir’s': 3175,
    'Khairpur Nathan Shah': 3175,
    'Khanpur Mahar': 3175,
    'Kot Diji': 3175,
    'Kotri': 3175,
    'Kunri': 3175,
    'Lakhi': 3175,
    'Larkana': 3175,
    'Madeji': 3175,
    'Malir Cantonment': 3175,
    'Matiari': 3175,
    'Matli': 3175,
    'Mehar': 3175,
    'Miro Khan': 3175,
    'Mirpur Bhtoro': 3175,
    'Mirpur Khas': 3175,
    'Mirpur Mathelo': 3175,
    'Mirpur Sakro': 3175,
    'Mirwah Gorchani': 3175,
    'Mithi': 3175,
    'Moro': 3175,
    'Nabisar': 3175,
    'Nasirabad': 3175,
    'Naudero': 3175,
    'Naukot': 3175,
    'Naushahro Firoz': 3175,
    'Nawabshah': 3175,
    'New Bādāh': 3175,
    'Pad Idan': 3175,
    'Pano Aqil': 3175,
    'Pir Jo Goth': 3175,
    'Pithoro': 3175,
    'Rajo Khanani': 3175,
    'Ranipur': 3175,
    'Ratodero': 3175,
    'Rohri': 3175,
    'Rustam': 3175,
    'Sakrand': 3175,
    'Samaro': 3175,
    'Sanghar': 3175,
    'Sann': 3175,
    'Sehwan': 3175,
    'Setharja Old': 3175,
    'Shahdad Kot': 3175,
    'Shahdadpur': 3175,
    'Shahpur Chakar': 3175,
    'Shikarpur': 3175,
    'Sinjhoro': 3175,
    'Sīta Road': 3175,
    'Sobhodero': 3175,
    'Sukkur': 3175,
    'Talhar': 3175,
    'Tando Adam': 3175,
    'Tando Allahyar': 3175,
    'Tando Bago': 3175,
    'Tando Jam': 3175,
    'Tando Mitha Khan': 3175,
    'Tando Muhammad Khan': 3175,
    'Tangwani': 3175,
    'Tharu Shah': 3175,
    'Thatta': 3175,
    'Thul': 3175,
    'Ubauro': 3175,
    'Umarkot': 3175,
    'Umerkot District': 3175,
    'Warah': 3175,
    'Ngchemiangel': 4540,
    'Ngetkib': 4528,
    'Angaur State': 4538,
    'Tobi Village': 4529,
    'Kayangel': 4539,
    'Koror': 4532,
    'Koror Town': 4532,
    'Melekeok Village': 4530,
    'Ulimang': 4537,
    'Mengellang': 4533,
    'Ngardmau': 4527,
    'Ngchesar Hamlet': 4536,
    'Imeong Hamlet': 4541,
    'Ngerkeai': 4534,
    'Kloulklubed': 4526,
    'Sonsorol Village': 4535,
    'Almirante': 1393,
    'Barranco': 1393,
    'Barranco Adentro': 1393,
    'Bastimentos': 1393,
    'Bocas del Toro': 1393,
    'Cauchero': 1393,
    'Changuinola': 1393,
    'Chiriquí Grande': 1393,
    'Distrito Chiriquí Grande': 1393,
    'Distrito de Bocas del Toro': 1393,
    'Distrito de Changuinola': 1393,
    'El Empalme': 1393,
    'El Silencio': 1393,
    'Guabito': 1393,
    'Isla Bastimentos': 1393,
    'La Mesa': 1393,
    'Miramar': 1393,
    'Punta Laurel': 1393,
    'Punta Peña': 1393,
    'Punta Róbalo': 1393,
    'Valle del Risco': 1393,
    'Alanje': 1397,
    'Algarrobos Arriba': 1397,
    'Alto Boquete': 1397,
    'Aserrío de Gariché': 1397,
    'Bágala': 1397,
    'Bajo Boquete': 1397,
    'Barrio Guadalupe': 1397,
    'Bijagual': 1397,
    'Boca Chica': 1397,
    'Boca del Monte': 1397,
    'Boquerón': 1397,
    'Boquete': 1397,
    'Breñón': 1397,
    'Bugaba': 1397,
    'Bugabita Arriba': 1397,
    'Caimito': 1397,
    'Caldera': 1397,
    'Cañas Gordas': 1397,
    'Celmira': 1397,
    'Cerro Punta': 1397,
    'Cerro Viejo': 1397,
    'Chiriquí': 1397,
    'Cochea Abajo': 1397,
    'Cordillera': 1397,
    'David': 1397,
    'Distrito de Alanje': 1397,
    'Distrito de Barú': 1397,
    'Distrito de Boquerón': 1397,
    'Distrito de Boquete': 1397,
    'Distrito de Bugaba': 1397,
    'Distrito de David': 1397,
    'Distrito de Dolega': 1397,
    'Distrito de Gualaca': 1397,
    'Distrito de Remedios': 1397,
    'Distrito de Renacimiento': 1397,
    'Distrito de San Félix': 1397,
    'Distrito de San Lorenzo': 1397,
    'Distrito de Tolé': 1397,
    'Divalá': 1397,
    'Dolega District': 1397,
    'El Nancito': 1397,
    'El Palmar': 1397,
    'El Porvenir': 1397,
    'El Tejar': 1397,
    'Finca Blanco': 1397,
    'Gómez': 1397,
    'Guabal': 1397,
    'Guaca Arriba': 1397,
    'Gualaca': 1397,
    'Guarumal': 1397,
    'Guayabal': 1397,
    'Horconcitos': 1397,
    'La Concepción': 1397,
    'La Esperanza': 1397,
    'La Estrella': 1397,
    'Lajas Adentro': 1397,
    'Lajas de Tolé': 1397,
    'Las Lajas': 1397,
    'Las Lomas': 1397,
    'Limones': 1397,
    'Los Algarrobos': 1397,
    'Los Anastacios': 1397,
    'Los Ángeles': 1397,
    'Los Naranjos': 1397,
    'Manaca Civil': 1397,
    'Manaca Norte': 1397,
    'Mata del Nance': 1397,
    'Monte Lirio': 1397,
    'Nuevo San Carlitos': 1397,
    'Paja de Sombrero': 1397,
    'Palmira': 1397,
    'Palmira Centro': 1397,
    'Paraíso': 1397,
    'Paso Canoas Arriba': 1397,
    'Pedregal': 1397,
    'Plaza de Caisán': 1397,
    'Potrerillos Abajo': 1397,
    'Potrerillos Arriba': 1397,
    'Progreso': 1397,
    'Pueblo Viejo': 1397,
    'Puerto Armuelles': 1397,
    'Punta de Burica': 1397,
    'Quebrada de Piedra': 1397,
    'Querévalo': 1397,
    'Quinteño': 1397,
    'Remedios': 1397,
    'Rincón': 1397,
    'Río Sereno': 1397,
    'Rovira Arriba': 1397,
    'San Andrés': 1397,
    'San Carlos': 1397,
    'San Félix': 1397,
    'San Isidro': 1397,
    'San Lorenzo': 1397,
    'San Pablo Nuevo Abajo': 1397,
    'San Pablo Viejo Abajo': 1397,
    'Santa Clara': 1397,
    'Santa Cruz': 1397,
    'Santa Marta': 1397,
    'Santa Rosa': 1397,
    'Santo Domingo': 1397,
    'Santo Tomás': 1397,
    'Sioguí Abajo': 1397,
    'Sioguí Arriba': 1397,
    'Sortova': 1397,
    'Tijeras': 1397,
    'Tinajas': 1397,
    'Tolé': 1397,
    'Veladero': 1397,
    'Volcán': 1397,
    'Aguadulce': 1387,
    'Aguas Blancas': 1387,
    'Alto de La Estancia': 1387,
    'Antón': 1387,
    'Barranco Colorado': 1387,
    'Caballero': 1387,
    'Cabuya': 1387,
    'Caimito': 1387,
    'Cañaveral': 1387,
    'Capellanía': 1387,
    'Chigoré': 1387,
    'Chiguirí Arriba': 1387,
    'Churuquita Chiquita': 1387,
    'Churuquita Grande': 1387,
    'Coclé': 1387,
    'Distrito de Aguadulce': 1387,
    'Distrito de Antón': 1387,
    'Distrito de La Pintada': 1387,
    'Distrito de Natá': 1387,
    'Distrito de Olá': 1387,
    'Distrito Penonomé': 1387,
    'El Caño': 1387,
    'El Chirú': 1387,
    'El Coco': 1387,
    'El Copé': 1387,
    'El Cortezo': 1387,
    'El Cristo': 1387,
    'El Potrero': 1387,
    'El Retiro': 1387,
    'El Roble': 1387,
    'Entradero': 1387,
    'Farallón': 1387,
    'Guzman': 1387,
    'Juan Díaz': 1387,
    'La Loma': 1387,
    'La Pintada': 1387,
    'Las Huacas del Quije': 1387,
    'Las Marias': 1387,
    'Las Minas': 1387,
    'Las Sabanas': 1387,
    'Llano Grande': 1387,
    'Llano Marín': 1387,
    'Los Pollos': 1387,
    'Natá': 1387,
    'Nuestro Amo': 1387,
    'Olá': 1387,
    'Penonomé': 1387,
    'Piedras Gordas': 1387,
    'Pocrí': 1387,
    'Río Grande': 1387,
    'Río Hato': 1387,
    'San Juan de Dios': 1387,
    'Toabré': 1387,
    'Toza': 1387,
    'Vista Hermosa': 1387,
    'Buena Vista': 1386,
    'Cativá': 1386,
    'Coclé del Norte': 1386,
    'Colón': 1386,
    'Cristóbal': 1386,
    'Distrito de Chagres': 1386,
    'Distrito de Colón': 1386,
    'Distrito de Donoso': 1386,
    'Distrito de Portobelo': 1386,
    'Distrito de Santa Isabel': 1386,
    'El Giral': 1386,
    'El Guabo': 1386,
    'El Valle de la Unión': 1386,
    'Escobal': 1386,
    'Gatún': 1386,
    'Gatuncillo': 1386,
    'Las Margaritas': 1386,
    'María Chiquita': 1386,
    'Miramar': 1386,
    'Nombre de Dios': 1386,
    'Nuevo Chagres': 1386,
    'Nuevo San Juan': 1386,
    'Nuevo Vigía': 1386,
    'Palenque': 1386,
    'Palmas Bellas': 1386,
    'Palmira': 1386,
    'Playa Chiquita': 1386,
    'Portobelo': 1386,
    'Puerto Escondido': 1386,
    'Puerto Pilón': 1386,
    'Quebrada Bonita Adentro': 1386,
    'Río Alejandro': 1386,
    'Río Duque': 1386,
    'Río Rita': 1386,
    'Sabanitas': 1386,
    'Santa Isabel': 1386,
    'Santa Rita Arriba': 1386,
    'Viento Frío': 1386,
    'Boca de Cupé': 1385,
    'Camogantí': 1385,
    'Cucunatí': 1385,
    'Distrito de Chepigana': 1385,
    'Distrito de Pinogana': 1385,
    'Garachiné': 1385,
    'Jaqué': 1385,
    'La Palma': 1385,
    'Metetí': 1385,
    'Mortí': 1385,
    'Púcuro': 1385,
    'Santa Fé': 1385,
    'Yaviza': 1385,
    'Bayamón': 1396,
    'Cémaco': 1396,
    'Corozal': 1396,
    'Sambú': 1396,
    'Unión Chocó': 1396,
    'Yape': 1396,
    'Achutupo': 1388,
    'Ailigandí': 1388,
    'Cartí Sugdup': 1388,
    'El Porvenir': 1388,
    'Mulatupo': 1388,
    'Narganá': 1388,
    'Playón Chico': 1388,
    'Puerto Obaldía': 1388,
    'San Ignacio de Tupile': 1388,
    'Tubualá': 1388,
    'Ustupo': 1388,
    'Boca de Parita': 1389,
    'Cabuya': 1389,
    'Cerro Largo': 1389,
    'Chitré': 1389,
    'Chumical': 1389,
    'Chupampa': 1389,
    'Distrito de Chitré': 1389,
    'Distrito de Las Minas': 1389,
    'Distrito de Los Pozos': 1389,
    'Distrito de Ocú': 1389,
    'Distrito de Parita': 1389,
    'Distrito de Pesé': 1389,
    'Distrito de Santa María': 1389,
    'El Calabacito': 1389,
    'El Capurí': 1389,
    'El Cedro': 1389,
    'El Pedregoso': 1389,
    'El Rincón': 1389,
    'El Toro': 1389,
    'La Arena': 1389,
    'La Trinidad': 1389,
    'Las Guabas': 1389,
    'Las Minas': 1389,
    'Leones Arriba': 1389,
    'Limón de Tijeras': 1389,
    'Llano Bonito': 1389,
    'Llano de La Cruz': 1389,
    'Llano Grande': 1389,
    'Los Canelos': 1389,
    'Los Castillos': 1389,
    'Los Cerritos': 1389,
    'Los Cerros de Paja': 1389,
    'Los Pozos': 1389,
    'Monagrillo': 1389,
    'Ocú': 1389,
    'París': 1389,
    'Parita': 1389,
    'Peñas Chatas': 1389,
    'Pesé': 1389,
    'Pitaloza Arriba': 1389,
    'Portobelillo': 1389,
    'Potuga': 1389,
    'Quebrada del Rosario': 1389,
    'Rincón Hondo': 1389,
    'Sabana Grande': 1389,
    'San Juan Bautista': 1389,
    'Santa María': 1389,
    'Agua Buena': 1390,
    'Ave María': 1390,
    'Bahía Honda': 1390,
    'Bajo Corral': 1390,
    'Bayano': 1390,
    'Bella Vista': 1390,
    'Cambutal': 1390,
    'Cañas': 1390,
    'Cedro Arriba': 1390,
    'Distrito de Guararé': 1390,
    'Distrito de Las Tablas': 1390,
    'Distrito de Los Santos': 1390,
    'Distrito de Macaracas': 1390,
    'Distrito de Pedasí': 1390,
    'Distrito de Pocrí': 1390,
    'Distrito de Tonosí': 1390,
    'El Cacao': 1390,
    'El Cañafístulo': 1390,
    'El Carate': 1390,
    'El Cocal': 1390,
    'El Cortezo': 1390,
    'El Ejido': 1390,
    'El Guásimo': 1390,
    'El Hato': 1390,
    'El Macano': 1390,
    'El Manantial': 1390,
    'El Muñoz': 1390,
    'El Pedregoso': 1390,
    'Flores': 1390,
    'Guararé': 1390,
    'Guararé Arriba': 1390,
    'La Colorada': 1390,
    'La Enea': 1390,
    'La Espigadilla': 1390,
    'La Laja': 1390,
    'La Mesa': 1390,
    'La Miel': 1390,
    'La Palma': 1390,
    'La Pasera': 1390,
    'La Tiza': 1390,
    'La Tronosa': 1390,
    'Lajamina': 1390,
    'Las Cruces': 1390,
    'Las Guabas': 1390,
    'Las Palmas': 1390,
    'Las Palmitas': 1390,
    'Las Tablas': 1390,
    'Las Trancas': 1390,
    'Llano Abajo': 1390,
    'Llano de Piedra': 1390,
    'Llano Largo': 1390,
    'Los Ángeles': 1390,
    'Los Asientos': 1390,
    'Los Olivos': 1390,
    'Los Santos': 1390,
    'Macaracas': 1390,
    'Mogollón': 1390,
    'Nuario': 1390,
    'Oria Arriba': 1390,
    'Palmira': 1390,
    'Paraíso': 1390,
    'Paritilla': 1390,
    'Pedasí': 1390,
    'Peña Blanca': 1390,
    'Perales': 1390,
    'Pocrí': 1390,
    'Río Hondo': 1390,
    'Sabana Grande': 1390,
    'San José': 1390,
    'Santa Ana Arriba': 1390,
    'Santo Domingo': 1390,
    'Sesteadero': 1390,
    'Tonosí': 1390,
    'Tres Quebradas': 1390,
    'Valle Rico': 1390,
    'Vallerriquito': 1390,
    'Villa Lourdes': 1390,
    'Bahía Azul': 1391,
    'Besiko': 1391,
    'Bisira': 1391,
    'Boca de Balsa': 1391,
    'Buenos Aires': 1391,
    'Camarón Arriba': 1391,
    'Cerro Banco': 1391,
    'Cerro Caña': 1391,
    'Cerro Plata': 1391,
    'Chichica': 1391,
    'Guayabito': 1391,
    'Hato Chami': 1391,
    'Hato Corotú': 1391,
    'Kankintú': 1391,
    'Kusapín': 1391,
    'Lajero Arriba': 1391,
    'Llano Ñopo': 1391,
    'Loma Yuca': 1391,
    'Mirono': 1391,
    'Müna': 1391,
    'Nole Düima': 1391,
    'Nurun': 1391,
    'Oma': 1391,
    'Paredón Arriba': 1391,
    'Plan de Chorcha': 1391,
    'Pueblo Nuevo': 1391,
    'Quebrada Canoa': 1391,
    'Quebrada Tula': 1391,
    'Sitio Prado': 1391,
    'Tobobe': 1391,
    'Alto del Espino': 1394,
    'Altos de San Francisco': 1394,
    'Arenosa': 1394,
    'Arosemena': 1394,
    'Arraiján': 1394,
    'Bejuco': 1394,
    'Buenos Aires': 1394,
    'Cabuya': 1394,
    'Caimito': 1394,
    'Capira': 1394,
    'Cermeño': 1394,
    'Cerro Cama': 1394,
    'Chame': 1394,
    'Cirí de Los Sotos': 1394,
    'Distrito Arraiján': 1394,
    'Distrito de Capira': 1394,
    'Distrito de Chame': 1394,
    'Distrito de La Chorrera': 1394,
    'Distrito de San Carlos': 1394,
    'El Cacao': 1394,
    'El Coco': 1394,
    'El Espavé': 1394,
    'El Espino': 1394,
    'El Higo': 1394,
    'El Líbano': 1394,
    'Guadalupe': 1394,
    'Guayabito': 1394,
    'Hato Montaña': 1394,
    'La Chorrera': 1394,
    'La Ermita': 1394,
    'La Herradura': 1394,
    'La Laguna': 1394,
    'La Mitra': 1394,
    'La Pesa': 1394,
    'Las Colinas': 1394,
    'Las Lajas': 1394,
    'Las Uvas': 1394,
    'Llano Largo': 1394,
    'Los Llanitos': 1394,
    'Mendoza': 1394,
    'Nueva Arenosa': 1394,
    'Nueva Gorgona': 1394,
    'Nuevo Arraiján': 1394,
    'Nuevo Emperador': 1394,
    'Playa Leona': 1394,
    'Potrero Grande': 1394,
    'Puerto Caimito': 1394,
    'Río Congo': 1394,
    'San Carlos': 1394,
    'San José': 1394,
    'San Vicente de Bique': 1394,
    'Santa Clara': 1394,
    'Santa Rita': 1394,
    'Sorá': 1394,
    'Veracruz': 1394,
    'Villa Carmen': 1394,
    'Villa Rosario': 1394,
    'Vista Alegre': 1394,
    'Alcalde Díaz': 1395,
    'Ancón': 1395,
    'Brujas': 1395,
    'Cabra Número Uno': 1395,
    'Caimitillo': 1395,
    'Calzada Larga': 1395,
    'Cañita': 1395,
    'Cerro Azul': 1395,
    'Chepillo': 1395,
    'Chepo': 1395,
    'Chilibre': 1395,
    'Chimán': 1395,
    'Contadora': 1395,
    'Curundú': 1395,
    'Distrito de Balboa': 1395,
    'Distrito de Chepo': 1395,
    'Distrito de Chimán': 1395,
    'Distrito de Panamá': 1395,
    'Distrito de Taboga': 1395,
    'El Chorrillo': 1395,
    'El Progreso': 1395,
    'Gonzalillo': 1395,
    'Howard': 1395,
    'Juan Díaz': 1395,
    'La Cabima': 1395,
    'La Colorada': 1395,
    'La Ensenada': 1395,
    'La Esmeralda': 1395,
    'La Guinea': 1395,
    'La Mesa': 1395,
    'Las Cumbres': 1395,
    'Las Margaritas': 1395,
    'Lídice': 1395,
    'Los Lotes': 1395,
    'Nueva Esperanza': 1395,
    'Nuevo Belén': 1395,
    'Pacora': 1395,
    'Panamá': 1395,
    'Parque Lefevre': 1395,
    'Pásiga': 1395,
    'Pedregal': 1395,
    'Río Abajo': 1395,
    'San Antonio': 1395,
    'San Felipe': 1395,
    'San Miguel': 1395,
    'San Miguelito': 1395,
    'San Vicente': 1395,
    'Tocumen': 1395,
    'Tortí': 1395,
    'Unión de Azuero': 1395,
    'Unión Santeña': 1395,
    'Villa Unida': 1395,
    'Vista Hermosa': 1395,
    'Alto de Jesús': 1392,
    'Arenas': 1392,
    'Atalaya': 1392,
    'Barnizal': 1392,
    'Bisvalles': 1392,
    'Boquerón': 1392,
    'Boró': 1392,
    'Calabacito': 1392,
    'Calidonia': 1392,
    'Calobre': 1392,
    'Calovébora': 1392,
    'Cañazas': 1392,
    'Canto del Llano': 1392,
    'Corozal': 1392,
    'Distrito de Atalaya': 1392,
    'Distrito de Calobre': 1392,
    'Distrito de Cañazas': 1392,
    'Distrito de La Mesa': 1392,
    'Distrito de Las Palmas': 1392,
    'Distrito de Montijo': 1392,
    'Distrito de Río de Jesús': 1392,
    'Distrito de San Francisco': 1392,
    'Distrito de Santa Fé': 1392,
    'Distrito de Santiago': 1392,
    'Distrito de Soná': 1392,
    'El Alto': 1392,
    'El Barrito': 1392,
    'El Coclá': 1392,
    'El Espino de Santa Rosa': 1392,
    'El Marañón': 1392,
    'El María': 1392,
    'El Pájaro': 1392,
    'El Pantano': 1392,
    'El Peñón': 1392,
    'El Picador': 1392,
    'El Potrero': 1392,
    'El Rincón': 1392,
    'El Uvito': 1392,
    'Guarumal': 1392,
    'La Colorada': 1392,
    'La Garceana': 1392,
    'La Laguna': 1392,
    'La Loma': 1392,
    'La Mesa': 1392,
    'La Montañuela': 1392,
    'La Peña': 1392,
    'La Raya de Calobre': 1392,
    'La Raya de Santa María': 1392,
    'La Yeguada': 1392,
    'Las Guías Abajo': 1392,
    'Las Huacas': 1392,
    'Las Palmas': 1392,
    'Leones Arriba': 1392,
    'Llano Grande': 1392,
    'Lolá': 1392,
    'Los Algarrobos': 1392,
    'Los Castillos': 1392,
    'Los Higos': 1392,
    'Mariato District': 1392,
    'Monjarás': 1392,
    'Montijo': 1392,
    'Piedras Gordas': 1392,
    'Ponuga': 1392,
    'Puerto Vidal': 1392,
    'Quebrada de Oro': 1392,
    'Río de Jesús': 1392,
    'Río Grande': 1392,
    'Rodeo Viejo': 1392,
    'San Bartolo': 1392,
    'San Francisco': 1392,
    'San José': 1392,
    'San Juan': 1392,
    'San Marcelo': 1392,
    'San Pedro del Espino': 1392,
    'Santa Fé': 1392,
    'Santiago de Veraguas': 1392,
    'Soná': 1392,
    'Tebario': 1392,
    'Utirá': 1392,
    'Viguí': 1392,
    'Zapotillo': 1392,
    'Arawa': 4831,
    'Central Bougainville': 4831,
    'Kieta': 4831,
    'North Bougainville': 4831,
    'Panguna': 4831,
    'South Bougainville': 4831,
    'Abau': 4847,
    'Goilala': 4847,
    'Kairuku-Hiri': 4847,
    'Rigo': 4847,
    'Chuave': 4846,
    'Gumine': 4846,
    'Karimui Nomane': 4846,
    'Kerowagi': 4846,
    'Kundiawa': 4846,
    'Sinasina Yonggamugl': 4846,
    'Gazelle': 4834,
    'Kokopo': 4834,
    'Pomio': 4834,
    'Rabaul': 4834,
    'Daulo': 4845,
    'Goroka': 4845,
    'Henganofi': 4845,
    'Kainantu': 4845,
    'Lufa': 4845,
    'Obura Wonenara': 4845,
    'Okapa': 4845,
    'Unggai Bena': 4845,
    'Kandep': 4848,
    'Kompiam Ambum': 4848,
    'Lagaip Porgera': 4848,
    'Porgera': 4848,
    'Wabag': 4848,
    'Wapenamanda': 4848,
    'Kerema': 4839,
    'Kikori': 4839,
    'Komo Margarima': 4833,
    'Koroba-Lake Kopiago': 4833,
    'Tari': 4833,
    'Tari Pori': 4833,
    'Angalimp South Wahgi': 4832,
    'Jimi': 4832,
    'North Wahgi': 4832,
    'Bogia': 4843,
    'Madang': 4843,
    'Middle Ramu': 4843,
    'Rai Coast': 4843,
    'Sumkar': 4843,
    'Usino Bundi': 4843,
    'Lorengau': 4842,
    'Manus': 4842,
    'Alotau': 4849,
    'Esa’ala': 4849,
    'Kiriwina Goodenough': 4849,
    'Samarai': 4849,
    'Samarai Murua': 4849,
    'Bulolo': 4835,
    'Finschhafen': 4835,
    'Huon Gulf': 4835,
    'Kabwum': 4835,
    'Lae': 4835,
    'Markham': 4835,
    'Menyamya': 4835,
    'Nawae': 4835,
    'Tewai Siassi': 4835,
    'Wau': 4835,
    'Kavieng': 4841,
    'Namatanai': 4841,
    'Ijivitari': 4838,
    'Kokoda': 4838,
    'Popondetta': 4838,
    'Sohe': 4838,
    'National Capital District': 4837,
    'Port Moresby': 4837,
    'Aitape': 4836,
    'Aitape Lumi': 4836,
    'Nuku': 4836,
    'Telefomin': 4836,
    'Vanimo': 4836,
    'Vanimo Green': 4836,
    'Ialibu': 4844,
    'Ialibu Pangia': 4844,
    'Imbonggu': 4844,
    'Kagua Erave': 4844,
    'Mendi': 4844,
    'Nipa Kutubu': 4844,
    'Kandrian': 4830,
    'Kandrian Gloucester': 4830,
    'Kimbe': 4830,
    'Talasea': 4830,
    'Baiyer Mul': 4840,
    'Dei': 4840,
    'Hagen': 4840,
    'Mount Hagen': 4840,
    'Tambul Nebilyer': 4840,
    'Daru': 4850,
    'Kiunga': 4850,
    'Middle Fly': 4850,
    'Morehead': 4850,
    'North Fly': 4850,
    'South Fly': 4850,
    'Capitán Pablo Lagerenza': 2785,
    'Fuerte Olimpo': 2785,
    'Cedrales': 2784,
    'Ciudad del Este': 2784,
    'Colonia Minga Porá': 2784,
    'Doctor Juan León Mallorquín': 2784,
    'Itakyry': 2784,
    'Naranjal': 2784,
    'Presidente Franco': 2784,
    'San Alberto': 2784,
    'Santa Rita': 2784,
    'Yguazú': 2784,
    'Bella Vista': 2782,
    'Capitán Bado': 2782,
    'Pedro Juan Caballero': 2782,
    'Colonia Menno': 2780,
    'Colonia Neuland': 2780,
    'Filadelfia': 2780,
    'Caaguazú': 2773,
    'Carayaó': 2773,
    'Cecilio Baez': 2773,
    'Colonia General Alfredo Stroessner': 2773,
    'Coronel Oviedo': 2773,
    'Doctor Juan Manuel Frutos': 2773,
    'Mbutuý': 2773,
    'Repatriación': 2773,
    'San Joaquín': 2773,
    'San José': 2773,
    'Yhú': 2773,
    'Abaí': 2775,
    'Buena Vista': 2775,
    'Caazapá': 2775,
    'General Higinio Morínigo': 2775,
    'San Juan Nepomuceno': 2775,
    'Yegros': 2775,
    'Yuty': 2775,
    'Colonia Catuete': 2771,
    'Corpus Christi': 2771,
    'La Paloma': 2771,
    'Nueva Esperanza': 2771,
    'Salto del Guairá': 2771,
    'San Isidro de Curuguaty': 2771,
    'Ygatimi': 2771,
    'Areguá': 2777,
    'Capiatá': 2777,
    'Fernando de la Mora': 2777,
    'Guarambaré': 2777,
    'Itá': 2777,
    'Itauguá': 2777,
    'Lambaré': 2777,
    'Limpio': 2777,
    'Mariano Roque Alonso': 2777,
    'Nemby': 2777,
    'Nueva Italia': 2777,
    'San Antonio': 2777,
    'San Lorenzo': 2777,
    'Villa Elisa': 2777,
    'Ypacarai': 2777,
    'Belén': 2779,
    'Concepción': 2779,
    'Horqueta': 2779,
    'Altos': 2783,
    'Arroyos y Esteros': 2783,
    'Atyrá': 2783,
    'Caacupé': 2783,
    'Caraguatay': 2783,
    'Emboscada': 2783,
    'Eusebio Ayala': 2783,
    'Isla Pucú': 2783,
    'Itacurubí de la Cordillera': 2783,
    'Piribebuy': 2783,
    'San Bernardino': 2783,
    'Santa Elena': 2783,
    'Tobatí': 2783,
    'Colonia Mauricio José Troche': 2772,
    'Coronel Martínez': 2772,
    'Independencia': 2772,
    'Itapé': 2772,
    'Iturbe': 2772,
    'Mbocayaty': 2772,
    'Natalicio Talavera': 2772,
    'Villarrica': 2772,
    'Arquitecto Tomás Romero Pereira': 2778,
    'Bella Vista': 2778,
    'Capitán Miranda': 2778,
    'Carmen del Paraná': 2778,
    'Colonia Carlos Antonio López': 2778,
    'Colonia San Lorenzo': 2778,
    'Edelira': 2778,
    'Encarnación': 2778,
    'Fram': 2778,
    'General Artigas': 2778,
    'General Delgado': 2778,
    'Hohenau': 2778,
    'Jesús': 2778,
    'Natalio': 2778,
    'Obligado': 2778,
    'Pirapó': 2778,
    'Puerto Mayor Otaño': 2778,
    'San Cosme y Damián': 2778,
    'San Juan del Paraná': 2778,
    'San Pedro del Paraná': 2778,
    'Trinidad': 2778,
    'Juan de Ayolas': 2786,
    'San Juan Bautista': 2786,
    'San Miguel': 2786,
    'San Patricio': 2786,
    'Santa María': 2786,
    'Santa Rosa Misiones': 2786,
    'Santiago': 2786,
    'Villa Florida': 2786,
    'Cerrito': 2781,
    'General José Eduvigis Díaz': 2781,
    'Pilar': 2781,
    'Acahay': 2774,
    'Caapucú': 2774,
    'Carapeguá': 2774,
    'La Colmena': 2774,
    'Mbuyapey': 2774,
    'Paraguarí': 2774,
    'Pirayú': 2774,
    'Quiindy': 2774,
    'Sapucaí': 2774,
    'Yaguarón': 2774,
    'Ybycuí': 2774,
    'Benjamín Aceval': 2770,
    'Nanawua': 2770,
    'Villa Hayes': 2770,
    'Antequera': 2776,
    'Capiíbary': 2776,
    'Colonia Nueva Germania': 2776,
    'General Elizardo Aquino': 2776,
    'Guayaybi': 2776,
    'Itacurubí del Rosario': 2776,
    'Lima': 2776,
    'Puerto Rosario': 2776,
    'San Pedro de Ycuamandiyú': 2776,
    'Tacuatí': 2776,
    'Unión': 2776,
    'Yataity del Norte': 2776,
    'Bagua Grande': 3685,
    'Cajaruro': 3685,
    'Chachapoyas': 3685,
    'Condorcanqui': 3685,
    'La Peca': 3685,
    'Provincia de Bagua': 3685,
    'Provincia de Bongará': 3685,
    'Provincia de Chachapoyas': 3685,
    'Provincia de Luya': 3685,
    'Provincia de Rodríguez de Mendoza': 3685,
    'Utcubamba': 3685,
    'Asuncion': 3680,
    'Carás': 3680,
    'Carhuaz': 3680,
    'Carlos Fermin Fitzcarrald': 3680,
    'Chimbote': 3680,
    'Coishco': 3680,
    'Huaraz': 3680,
    'Huarmey': 3680,
    'Pomabamba': 3680,
    'Provincia de Aija': 3680,
    'Provincia de Antonio Raymondi': 3680,
    'Provincia de Bolognesi': 3680,
    'Provincia de Carhuaz': 3680,
    'Provincia de Casma': 3680,
    'Provincia de Corongo': 3680,
    'Provincia de Huaraz': 3680,
    'Provincia de Huari': 3680,
    'Provincia de Huarmey': 3680,
    'Provincia de Huaylas': 3680,
    'Provincia de Mariscal Luzuriaga': 3680,
    'Provincia de Pallasca': 3680,
    'Provincia de Pomabamba': 3680,
    'Provincia de Recuay': 3680,
    'Provincia de Santa': 3680,
    'Provincia de Sihuas': 3680,
    'Provincia de Yungay': 3680,
    'Puerto Santa': 3680,
    'Yauya': 3680,
    'Yungay': 3680,
    'Abancay': 3699,
    'Andahuaylas': 3699,
    'Provincia de Abancay': 3699,
    'Provincia de Andahuaylas': 3699,
    'Provincia de Antabamba': 3699,
    'Provincia de Aymaraes': 3699,
    'Provincia de Cotabambas': 3699,
    'Provincia de Grau': 3699,
    'San Jerónimo': 3699,
    'Talavera': 3699,
    'Acarí': 3681,
    'Arequipa': 3681,
    'Camaná': 3681,
    'Ccolo': 3681,
    'Chivay': 3681,
    'Cocachacra': 3681,
    'Cotahuasi': 3681,
    'Huarancante': 3681,
    'Huarichancara': 3681,
    'Jatun Orcochiri': 3681,
    'Jayune': 3681,
    'Llongasora': 3681,
    'Lluta': 3681,
    'Mollendo': 3681,
    'Orcopampa': 3681,
    'Provincia de Arequipa': 3681,
    'Provincia de Camaná': 3681,
    'Provincia de Caravelí': 3681,
    'Provincia de Castilla': 3681,
    'Provincia de Caylloma': 3681,
    'Provincia de Condesuyos': 3681,
    'Provincia de Islay': 3681,
    'Provincia de La Unión': 3681,
    'Pucara': 3681,
    'Punta de Bombón': 3681,
    'Vizcachane': 3681,
    'Yura': 3681,
    'Ayacucho': 3692,
    'Ayna': 3692,
    'Coracora': 3692,
    'Huanta': 3692,
    'Paucar Del Sara Sara': 3692,
    'Provincia de Cangallo': 3692,
    'Provincia de Huamanga': 3692,
    'Provincia de Huanca Sancos': 3692,
    'Provincia de Huanta': 3692,
    'Provincia de La Mar': 3692,
    'Provincia de Lucanas': 3692,
    'Provincia de Parinacochas': 3692,
    'Provincia de Sucre': 3692,
    'Provincia de Víctor Fajardo': 3692,
    'Provincia de Vilcas Huamán': 3692,
    'Puquio': 3692,
    'San Miguel': 3692,
    'Tambo': 3692,
    'Bambamarca': 3688,
    'Bellavista': 3688,
    'Cajabamba': 3688,
    'Cajamarca': 3688,
    'Celendín': 3688,
    'Chota': 3688,
    'Jaén': 3688,
    'Provincia de Cajabamba': 3688,
    'Provincia de Cajamarca': 3688,
    'Provincia de Celendín': 3688,
    'Provincia de Chota': 3688,
    'Provincia de Contumazá': 3688,
    'Provincia de Cutervo': 3688,
    'Provincia de Hualgayoc': 3688,
    'Provincia de Jaén': 3688,
    'Provincia de San Ignacio': 3688,
    'Provincia de San Miguel': 3688,
    'Provincia de Santa Cruz': 3688,
    'San Ignacio': 3688,
    'Callao': 3701,
    'Anta': 3691,
    'Cahuanuyo': 3691,
    'Calca': 3691,
    'Callanca': 3691,
    'Ccaquiracunca': 3691,
    'Ccuntuma': 3691,
    'Checacupe': 3691,
    'Checca': 3691,
    'Chignayhua': 3691,
    'Chinchero': 3691,
    'Combapata': 3691,
    'Conchopata': 3691,
    'Cullcuyre': 3691,
    'Cusco': 3691,
    'Huayna Alcalde': 3691,
    'Jayobamba': 3691,
    'Langui': 3691,
    'Layo': 3691,
    'Lima Pampa': 3691,
    'Maranganí': 3691,
    'Maras': 3691,
    'Mosoc Cancha': 3691,
    'Mosoc Llacta': 3691,
    'Ollantaytambo': 3691,
    'Oropesa': 3691,
    'Pampamarca': 3691,
    'Pangoa': 3691,
    'Paucartambo': 3691,
    'Pisac': 3691,
    'Provincia de Acomayo': 3691,
    'Provincia de Anta': 3691,
    'Provincia de Calca': 3691,
    'Provincia de Canas': 3691,
    'Provincia de Canchis': 3691,
    'Provincia de Chumbivilcas': 3691,
    'Provincia de Cusco': 3691,
    'Provincia de Espinar': 3691,
    'Provincia de La Convención': 3691,
    'Provincia de Paruro': 3691,
    'Provincia de Paucartambo': 3691,
    'Provincia de Quispicanchis': 3691,
    'Provincia de Urubamba': 3691,
    'Qquea': 3691,
    'Queromarca': 3691,
    'San Pablo': 3691,
    'Santa Ana': 3691,
    'Santo Tomas': 3691,
    'Sicuani': 3691,
    'Tinta': 3691,
    'Tungasuca': 3691,
    'Urcos': 3691,
    'Urubamba': 3691,
    'Yanaoca': 3691,
    'Huancavelica': 3679,
    'Huaytara': 3679,
    'Pampas': 3679,
    'Provincia de Acobamba': 3679,
    'Provincia de Angaraes': 3679,
    'Provincia de Castrovirreyna': 3679,
    'Provincia de Churcampa': 3679,
    'Provincia de Huancavelica': 3679,
    'Provincia de Tayacaja': 3679,
    'Ambo': 3687,
    'Huacaybamba': 3687,
    'Huánuco': 3687,
    'La Unión': 3687,
    'Lauricocha': 3687,
    'Llata': 3687,
    'Provincia de Ambo': 3687,
    'Provincia de Dos de Mayo': 3687,
    'Provincia de Huamalíes': 3687,
    'Provincia de Huánuco': 3687,
    'Provincia de Leoncio Prado': 3687,
    'Provincia de Marañón': 3687,
    'Provincia de Pachitea': 3687,
    'Puerto Inca': 3687,
    'San Miguel de Cauri': 3687,
    'Tingo María': 3687,
    'Yanacancha': 3687,
    'Yarowilca': 3687,
    'Chincha Alta': 3700,
    'Ica': 3700,
    'Los Aquijes': 3700,
    'Minas de Marcona': 3700,
    'Nazca': 3700,
    'Palpa': 3700,
    'Paracas': 3700,
    'Pisco': 3700,
    'Provincia de Chincha': 3700,
    'Provincia de Ica': 3700,
    'Provincia de Nazca': 3700,
    'Provincia de Palpa': 3700,
    'Provincia de Pisco': 3700,
    'Río Grande': 3700,
    'San Clemente': 3700,
    'San Juan Bautista': 3700,
    'Santiago': 3700,
    'Subtanjalla': 3700,
    'Villa Tupac Amaru': 3700,
    'Acolla': 3693,
    'Carhuamayo': 3693,
    'Chanchamayo': 3693,
    'Chupaca': 3693,
    'Concepción': 3693,
    'Huancayo': 3693,
    'Huasahuasi': 3693,
    'Huayucachi': 3693,
    'Jauja': 3693,
    'Junín': 3693,
    'La Oroya': 3693,
    'Mazamari': 3693,
    'Morococha': 3693,
    'Orcotuna': 3693,
    'Perené': 3693,
    'Pilcomay': 3693,
    'Provincia de Chupaca': 3693,
    'Provincia de Concepción': 3693,
    'Provincia de Huancayo': 3693,
    'Provincia de Jauja': 3693,
    'Provincia de Junín': 3693,
    'Provincia de Satipo': 3693,
    'Provincia de Tarma': 3693,
    'Provincia de Yauli': 3693,
    'San Agustin': 3693,
    'San Jerónimo': 3693,
    'San Pedro de Cajas': 3693,
    'San Ramón': 3693,
    'Satipo': 3693,
    'Sicaya': 3693,
    'Tarma': 3693,
    'Ascope': 3683,
    'Cascas': 3683,
    'Chepen': 3683,
    'Chicama': 3683,
    'Chocope': 3683,
    'Gran Chimu': 3683,
    'Guadalupe': 3683,
    'Huamachuco': 3683,
    'Julcan': 3683,
    'Laredo': 3683,
    'Moche': 3683,
    'Otuzco': 3683,
    'Pacanga': 3683,
    'Pacasmayo': 3683,
    'Paiján': 3683,
    'Provincia de Bolívar': 3683,
    'Provincia de Otuzco': 3683,
    'Provincia de Pacasmayo': 3683,
    'Provincia de Pataz': 3683,
    'Provincia de Santiago de Chuco': 3683,
    'Provincia de Trujillo': 3683,
    'Pueblo Nuevo': 3683,
    'Quiruvilca': 3683,
    'Salaverry': 3683,
    'San Pedro de Lloc': 3683,
    'Sanchez Carrion': 3683,
    'Santiago de Cao': 3683,
    'Santiago de Chuco': 3683,
    'Trujillo': 3683,
    'Viru': 3683,
    'Chiclayo': 3702,
    'Chongoyape': 3702,
    'Eten': 3702,
    'Ferreñafe': 3702,
    'Jayanca': 3702,
    'Lambayeque': 3702,
    'Mochumí': 3702,
    'Monsefú': 3702,
    'Mórrope': 3702,
    'Motupe': 3702,
    'Olmos': 3702,
    'Oyotún': 3702,
    'Picsi': 3702,
    'Pimentel': 3702,
    'Provincia de Chiclayo': 3702,
    'Provincia de Ferreñafe': 3702,
    'Provincia de Lambayeque': 3702,
    'Pueblo Nuevo': 3702,
    'Reque': 3702,
    'San José': 3702,
    'Saña': 3702,
    'Santa Rosa': 3702,
    'Túcume': 3702,
    'Asentamiento Humano Nicolas de Pierola': 3695,
    'Barranca': 3695,
    'Caleta de Carquín': 3695,
    'Chancay': 3695,
    'Chilca': 3695,
    'Chosica': 3695,
    'Huacho': 3695,
    'Hualmay': 3695,
    'Huaral': 3695,
    'Huaura': 3695,
    'Imperial': 3695,
    'Independencia': 3695,
    'Lima': 3695,
    'Mala': 3695,
    'Matucana': 3695,
    'Nuevo Imperial': 3695,
    'Oyon': 3695,
    'Paramonga': 3695,
    'Pativilca': 3695,
    'Provincia de Cajatambo': 3695,
    'Provincia de Cañete': 3695,
    'Provincia de Canta': 3695,
    'Provincia de Huaral': 3695,
    'Provincia de Huarochirí': 3695,
    'Provincia de Yauyos': 3695,
    'Puerto Supe': 3695,
    'Quilmaná': 3695,
    'Ricardo Palma': 3695,
    'San Bartolo': 3695,
    'San Isidro': 3695,
    'San Luis': 3695,
    'San Vicente de Cañete': 3695,
    'Santa María': 3695,
    'Santiago de Surco': 3695,
    'Sayán': 3695,
    'Supe': 3695,
    'Urb. Santo Domingo': 3695,
    'Végueta': 3695,
    'Andoas': 4922,
    'Borja': 4922,
    'Caballococha': 4922,
    'Cape Pantoja': 4922,
    'Indiana': 4922,
    'Iquitos': 4922,
    'Jeberos': 4922,
    'Jenaro Herrera': 4922,
    'Lagunas': 4922,
    'Nauta': 4922,
    'Punchana': 4922,
    'Requena': 4922,
    'San Antonio del Estrecho': 4922,
    'San Lorenzo': 4922,
    'San Pablo': 4922,
    'Tamshiyacu': 4922,
    'Yurimaguas': 4922,
    'Iberia': 3678,
    'Provincia de Manú': 3678,
    'Provincia de Tahuamanú': 3678,
    'Provincia de Tambopata': 3678,
    'Puerto Maldonado': 3678,
    'Salvación': 3678,
    'Tambopata': 3678,
    'Ilo': 3698,
    'Moquegua': 3698,
    'Pacocha': 3698,
    'Provincia de General Sánchez Cerro': 3698,
    'Provincia de Ilo': 3698,
    'Provincia de Mariscal Nieto': 3698,
    'Torata': 3698,
    'Cerro de Pasco': 3686,
    'Chaupimarca': 3686,
    'Oxapampa': 3686,
    'Paucartambo': 3686,
    'Provincia de Daniel Carrión': 3686,
    'Provincia de Oxapampa': 3686,
    'Provincia de Pasco': 3686,
    'Tinyahuarco': 3686,
    'Villa Rica': 3686,
    'Ayabaca': 3697,
    'Bernal': 3697,
    'Buenos Aires': 3697,
    'Catacaos': 3697,
    'Chulucanas': 3697,
    'El Alto': 3697,
    'Huancabamba': 3697,
    'La Breita': 3697,
    'La Huaca': 3697,
    'La Unión': 3697,
    'Las Lomas': 3697,
    'Máncora': 3697,
    'Marcavelica': 3697,
    'Morropon': 3697,
    'Paita': 3697,
    'Piura': 3697,
    'Provincia de Ayabaca': 3697,
    'Provincia de Huancabamba': 3697,
    'Provincia de Paita': 3697,
    'Provincia de Piura': 3697,
    'Provincia de Sullana': 3697,
    'Provincia de Talara': 3697,
    'Querecotillo': 3697,
    'Salinera Colán': 3697,
    'Salitral': 3697,
    'Sechura': 3697,
    'Sullana': 3697,
    'Talara': 3697,
    'Tambo Grande': 3697,
    'Vice': 3697,
    'Atuncolla': 3682,
    'Ayaviri': 3682,
    'Azángaro': 3682,
    'Desaguadero': 3682,
    'El Collao': 3682,
    'Hacienda Huancane': 3682,
    'Ilave': 3682,
    'Juli': 3682,
    'Juliaca': 3682,
    'La Rinconada': 3682,
    'Lampa': 3682,
    'Macusani': 3682,
    'Ñuñoa': 3682,
    'Provincia de Azángaro': 3682,
    'Provincia de Carabaya': 3682,
    'Provincia de Chucuito': 3682,
    'Provincia de Huancané': 3682,
    'Provincia de Lampa': 3682,
    'Provincia de Melgar': 3682,
    'Provincia de Puno': 3682,
    'Provincia de Sandia': 3682,
    'Puno': 3682,
    'San Antonio De Putina': 3682,
    'San Roman': 3682,
    'Santa Lucía': 3682,
    'Taquile': 3682,
    'Yunguyo': 3682,
    'Bellavista': 3694,
    'Chazuta': 3694,
    'El Dorado': 3694,
    'Huicungo': 3694,
    'Jepelacio': 3694,
    'Juanjuí': 3694,
    'Lamas': 3694,
    'Moyobamba': 3694,
    'Picota': 3694,
    'Provincia de Huallaga': 3694,
    'Provincia de Lamas': 3694,
    'Provincia de Mariscal Cáceres': 3694,
    'Provincia de Moyobamba': 3694,
    'Provincia de Rioja': 3694,
    'Provincia de San Martín': 3694,
    'Rioja': 3694,
    'Saposoa': 3694,
    'Sisa': 3694,
    'Soritor': 3694,
    'Tabalosos': 3694,
    'Tarapoto': 3694,
    'Tocache': 3694,
    'Uchiza': 3694,
    'Calana': 3696,
    'Candarave': 3696,
    'Chipispaya': 3696,
    'Chuquitira': 3696,
    'Ilabaya': 3696,
    'Jorge Basadre': 3696,
    'Provincia de Tacna': 3696,
    'Provincia de Tarata': 3696,
    'Pujocucho': 3696,
    'Quilahuani': 3696,
    'Tacna': 3696,
    'Tarata': 3696,
    'Totoral': 3696,
    'Aguas Verdes': 3689,
    'Papayal': 3689,
    'Provincia de Contralmirante Villar': 3689,
    'Provincia de Tumbes': 3689,
    'Provincia de Zarumilla': 3689,
    'Tumbes': 3689,
    'Zarumilla': 3689,
    'Zorritos': 3689,
    'Atalaya': 3684,
    'Campoverde': 3684,
    'Padre Abad': 3684,
    'Provincia de Coronel Portillo': 3684,
    'Pucallpa': 3684,
    'Purus': 3684,
    'Adams': 1324,
    'Agno': 1324,
    'Agoo': 1324,
    'Aguilar': 1324,
    'Alac': 1324,
    'Alaminos': 1324,
    'Alcala': 1324,
    'Alilem': 1324,
    'Allangigan Primero': 1324,
    'Aloleng': 1324,
    'Amagbagan': 1324,
    'Anambongan': 1324,
    'Anda': 1324,
    'Angatel': 1324,
    'Anulid': 1324,
    'Aringay': 1324,
    'Asingan': 1324,
    'Baay': 1324,
    'Bacag': 1324,
    'Bacarra': 1324,
    'Bacnar': 1324,
    'Bacnotan': 1324,
    'Bactad Proper': 1324,
    'Bacundao Weste': 1324,
    'Badoc': 1324,
    'Bagulin': 1324,
    'Bail': 1324,
    'Balaoan': 1324,
    'Balingasay': 1324,
    'Balingueo': 1324,
    'Balogo': 1324,
    'Balungao': 1324,
    'Baluyot': 1324,
    'Banayoyo': 1324,
    'Bangan-Oda': 1324,
    'Bangar': 1324,
    'Bangui': 1324,
    'Bani': 1324,
    'Banna': 1324,
    'Banog Sur': 1324,
    'Bantay': 1324,
    'Bantog': 1324,
    'Barangobong': 1324,
    'Baro': 1324,
    'Barong': 1324,
    'Basing': 1324,
    'Basista': 1324,
    'Batac': 1324,
    'Bataquil': 1324,
    'Bauang': 1324,
    'Bautista': 1324,
    'Bayambang': 1324,
    'Bayaoas': 1324,
    'Bical Norte': 1324,
    'Bil-Loca': 1324,
    'Binabalian': 1324,
    'Binalonan': 1324,
    'Binday': 1324,
    'Binmaley': 1324,
    'Bobonan': 1324,
    'Bogtong': 1324,
    'Bolaoit': 1324,
    'Bolinao': 1324,
    'Bolingit': 1324,
    'Bolo': 1324,
    'Boñgalon': 1324,
    'Botao': 1324,
    'Bued': 1324,
    'Buenlag': 1324,
    'Bugallon': 1324,
    'Bulog': 1324,
    'Burgos': 1324,
    'Butubut Norte': 1324,
    'Caabiangan': 1324,
    'Caba': 1324,
    'Cabalaoangan': 1324,
    'Cabalitian': 1324,
    'Cabittaogan': 1324,
    'Cabugao': 1324,
    'Cabungan': 1324,
    'Calasiao': 1324,
    'Calepaan': 1324,
    'Callaguip': 1324,
    'Calomboyan': 1324,
    'Calongbuyan': 1324,
    'Calsib': 1324,
    'Camaley': 1324,
    'Canan Norte': 1324,
    'Canaoalan': 1324,
    'Candon': 1324,
    'Cantoria': 1324,
    'Caoayan': 1324,
    'Capandanan': 1324,
    'Capulaan': 1324,
    'Caramutan': 1324,
    'Carasi': 1324,
    'Carmen': 1324,
    'Caronoan West': 1324,
    'Carot': 1324,
    'Carriedo': 1324,
    'Carusucan': 1324,
    'Caterman': 1324,
    'Cato': 1324,
    'Catuday': 1324,
    'Cayanga': 1324,
    'Cayungnan': 1324,
    'Cervantes': 1324,
    'City of Batac': 1324,
    'City of Candon': 1324,
    'City of Urdaneta': 1324,
    'City of Vigan': 1324,
    'Comillas Norte': 1324,
    'Corrooy': 1324,
    'Currimao': 1324,
    'Dagup': 1324,
    'Dagupan': 1324,
    'Damortis': 1324,
    'Darapidap': 1324,
    'Dasol': 1324,
    'Davila': 1324,
    'Diaz': 1324,
    'Dilan': 1324,
    'Dingras': 1324,
    'Domalanoan': 1324,
    'Domampot': 1324,
    'Don Pedro': 1324,
    'Dorongan Punta': 1324,
    'Doyong': 1324,
    'Dulig': 1324,
    'Dumalneg': 1324,
    'Dumpay': 1324,
    'Eguia': 1324,
    'Esmeralda': 1324,
    'Fuerte': 1324,
    'Galimuyod': 1324,
    'Gayaman': 1324,
    'Gregorio del Pilar': 1324,
    'Guiling': 1324,
    'Guiset East': 1324,
    'Hacienda': 1324,
    'Halog West': 1324,
    'Ilioilio': 1324,
    'Inabaan Sur': 1324,
    'Infanta': 1324,
    'Isla': 1324,
    'Labayug': 1324,
    'Labney': 1324,
    'Labrador': 1324,
    'Lagasit': 1324,
    'Laguit Centro': 1324,
    'Laoac': 1324,
    'Laoag': 1324,
    'Leones East': 1324,
    'Lepa': 1324,
    'Libas': 1324,
    'Lidlidda': 1324,
    'Lingayen': 1324,
    'Linmansangan': 1324,
    'Lloren': 1324,
    'Lobong': 1324,
    'Longos': 1324,
    'Loqueb Este': 1324,
    'Lucap': 1324,
    'Lucero': 1324,
    'Luna': 1324,
    'Lunec': 1324,
    'Lungog': 1324,
    'Lusong': 1324,
    'Mabilao': 1324,
    'Mabilbila Sur': 1324,
    'Mabini': 1324,
    'Mabusag': 1324,
    'Macabuboni': 1324,
    'Macalong': 1324,
    'Macalva Norte': 1324,
    'Macayug': 1324,
    'Magallanes': 1324,
    'Magsingal': 1324,
    'Magtaking': 1324,
    'Malabago': 1324,
    'Malanay': 1324,
    'Malasiqui': 1324,
    'Malawa': 1324,
    'Malibong East': 1324,
    'Manaoag': 1324,
    'Mangaldan': 1324,
    'Mangatarem': 1324,
    'Mapandan': 1324,
    'Mapolopolo': 1324,
    'Marcos': 1324,
    'Maticmatic': 1324,
    'Minien East': 1324,
    'Nagbacalan': 1324,
    'Nagbukel': 1324,
    'Nagsaing': 1324,
    'Naguelguel': 1324,
    'Naguilayan': 1324,
    'Naguilian': 1324,
    'Nalsian Norte': 1324,
    'Nama': 1324,
    'Namboongan': 1324,
    'Nancalobasaan': 1324,
    'Narvacan': 1324,
    'Natividad': 1324,
    'Navatat': 1324,
    'Nibaliw Central': 1324,
    'Nilombot': 1324,
    'Ninoy': 1324,
    'Nueva Era': 1324,
    'Oaqui': 1324,
    'Olea': 1324,
    'Padong': 1324,
    'Pagsanahan Norte': 1324,
    'Pagudpud': 1324,
    'Paitan Este': 1324,
    'Palacpalac': 1324,
    'Palguyod': 1324,
    'Panganiban': 1324,
    'Pangapisan': 1324,
    'Pangascasan': 1324,
    'Pangpang': 1324,
    'Paoay': 1324,
    'Paringao': 1324,
    'Parioc Segundo': 1324,
    'Pasibi West': 1324,
    'Pasuquin': 1324,
    'Patayac': 1324,
    'Patpata Segundo': 1324,
    'Payocpoc Sur': 1324,
    'Piddig': 1324,
    'Pindangan Centro': 1324,
    'Pinili': 1324,
    'Pogonsili': 1324,
    'Polo': 1324,
    'Polong': 1324,
    'Polong Norte': 1324,
    'Pozorrubio': 1324,
    'Pudoc': 1324,
    'Pudoc North': 1324,
    'Puelay': 1324,
    'Pugo': 1324,
    'Puro Pinget': 1324,
    'Quiling': 1324,
    'Quinarayan': 1324,
    'Quintong': 1324,
    'Quirino': 1324,
    'Ranao': 1324,
    'Real': 1324,
    'Rimus': 1324,
    'Rissing': 1324,
    'Rosales': 1324,
    'Rosario': 1324,
    'Sablig': 1324,
    'Sagud-Bahley': 1324,
    'Sagunto': 1324,
    'Salcedo': 1324,
    'Samon': 1324,
    'San Carlos': 1324,
    'San Emilio': 1324,
    'San Esteban': 1324,
    'San Eugenio': 1324,
    'San Fabian': 1324,
    'San Fernando': 1324,
    'San Fernando Poblacion': 1324,
    'San Gabriel': 1324,
    'San Gabriel First': 1324,
    'San Ildefonso': 1324,
    'San Jacinto': 1324,
    'San Juan': 1324,
    'San Lorenzo': 1324,
    'San Manuel': 1324,
    'San Nicolas': 1324,
    'San Pedro Apartado': 1324,
    'San Quintin': 1324,
    'San Sebastian': 1324,
    'San Vicente': 1324,
    'Sanlibo': 1324,
    'Santa': 1324,
    'Santa Barbara': 1324,
    'Santa Catalina': 1324,
    'Santa Cruz': 1324,
    'Santa Lucia': 1324,
    'Santa Maria': 1324,
    'Santiago': 1324,
    'Santo Domingo': 1324,
    'Santo Tomas': 1324,
    'Santol': 1324,
    'Sarrat': 1324,
    'Sigay': 1324,
    'Sinait': 1324,
    'Sison': 1324,
    'Solsona': 1324,
    'Sonquil': 1324,
    'Sual': 1324,
    'Subusub': 1324,
    'Sudipen': 1324,
    'Sugpon': 1324,
    'Sumabnit': 1324,
    'Suso': 1324,
    'Suyo': 1324,
    'Tablac': 1324,
    'Tabug': 1324,
    'Tagudin': 1324,
    'Talospatang': 1324,
    'Taloy': 1324,
    'Tamayo': 1324,
    'Tamorong': 1324,
    'Tandoc': 1324,
    'Tanolong': 1324,
    'Tayug': 1324,
    'Tebag East': 1324,
    'Telbang': 1324,
    'Tiep': 1324,
    'Toboy': 1324,
    'Tobuan': 1324,
    'Tococ East': 1324,
    'Tocok': 1324,
    'Tombod': 1324,
    'Tondol': 1324,
    'Toritori': 1324,
    'Tubao': 1324,
    'Umanday Centro': 1324,
    'Umingan': 1324,
    'Unzad': 1324,
    'Urbiztondo': 1324,
    'Urdaneta': 1324,
    'Uyong': 1324,
    'Vigan': 1324,
    'Villasis': 1324,
    'Vintar': 1324,
    'Zaragoza': 1324,
    'Abut': 1323,
    'Accusilian': 1323,
    'Afusing Centro': 1323,
    'Aglipay': 1323,
    'Alabug': 1323,
    'Alannay': 1323,
    'Alcala': 1323,
    'Alfonso Castañeda': 1323,
    'Alicia': 1323,
    'Allacapan': 1323,
    'Almaguer North': 1323,
    'Ambaguio': 1323,
    'Amulung': 1323,
    'Angadanan': 1323,
    'Antagan Segunda': 1323,
    'Aparri': 1323,
    'Aritao': 1323,
    'Atulayan': 1323,
    'Aurora': 1323,
    'Awallan': 1323,
    'Bacnor East': 1323,
    'Bagabag': 1323,
    'Baggabag B': 1323,
    'Baggao': 1323,
    'Bagong Tanza': 1323,
    'Bagu': 1323,
    'Bagumbayan': 1323,
    'Ballesteros': 1323,
    'Bambang': 1323,
    'Bangad': 1323,
    'Banganan': 1323,
    'Banquero': 1323,
    'Barucboc Norte': 1323,
    'Basco': 1323,
    'Batal': 1323,
    'Battung': 1323,
    'Bauan': 1323,
    'Bayombong': 1323,
    'Belance': 1323,
    'Benito Soliven': 1323,
    'Binalan': 1323,
    'Binguang': 1323,
    'Bintawan': 1323,
    'Bitag Grande': 1323,
    'Bone South': 1323,
    'Buguey': 1323,
    'Buliwao': 1323,
    'Bulu': 1323,
    'Burgos': 1323,
    'Busilak': 1323,
    'Cabagan': 1323,
    'Cabannungan Second': 1323,
    'Cabaritan East': 1323,
    'Cabarroguis': 1323,
    'Cabatuan': 1323,
    'Cabiraoan': 1323,
    'Calamagui East': 1323,
    'Calantac': 1323,
    'Calaoagan': 1323,
    'Calayan': 1323,
    'Calinaoan Malasin': 1323,
    'Calog Norte': 1323,
    'Camalaniugan': 1323,
    'Capissayan Sur': 1323,
    'Carig': 1323,
    'Casambalangan': 1323,
    'Catayauan': 1323,
    'Cauayan': 1323,
    'Claveria': 1323,
    'Cordon': 1323,
    'Cullalabo del Sur': 1323,
    'Dagupan': 1323,
    'Dalaoig': 1323,
    'Daragutan': 1323,
    'Dassun': 1323,
    'Delfin Albano': 1323,
    'Diadi': 1323,
    'Diamantina': 1323,
    'Dibuluan': 1323,
    'Dicabisagan': 1323,
    'Dicamay': 1323,
    'Diffun': 1323,
    'Dinapigui': 1323,
    'Divilican': 1323,
    'Divisoria': 1323,
    'Dodan': 1323,
    'Dumabato': 1323,
    'Dupax del Norte': 1323,
    'Dupax del Sur': 1323,
    'Echague (town)': 1323,
    'Eden': 1323,
    'Enrile': 1323,
    'Esperanza East': 1323,
    'Estefania': 1323,
    'Furao': 1323,
    'Gadu': 1323,
    'Gammad': 1323,
    'Gamu': 1323,
    'Ganapi': 1323,
    'Gappal': 1323,
    'Gattaran': 1323,
    'Gonzaga': 1323,
    'Guiddam': 1323,
    'Ibung': 1323,
    'Iguig': 1323,
    'Ilagan': 1323,
    'Ineangan': 1323,
    'Itbayat': 1323,
    'Ivana': 1323,
    'Jones': 1323,
    'Kasibu': 1323,
    'Kayapa': 1323,
    'La Paz': 1323,
    'Lal-lo': 1323,
    'Lallayug': 1323,
    'Lanna': 1323,
    'Lapi': 1323,
    'Larion Alto': 1323,
    'Lasam': 1323,
    'Lucban': 1323,
    'Luna': 1323,
    'Mabasa': 1323,
    'Mabini': 1323,
    'Mabuttal East': 1323,
    'Maconacon': 1323,
    'Maddarulug Norte': 1323,
    'Maddela': 1323,
    'Magalalag': 1323,
    'Magdalena': 1323,
    'Maguilling': 1323,
    'Mahatao': 1323,
    'Malasin': 1323,
    'Mallig': 1323,
    'Maluno Sur': 1323,
    'Manaring': 1323,
    'Manga': 1323,
    'Masaya Sur': 1323,
    'Masipi West': 1323,
    'Maxingal': 1323,
    'Minallo': 1323,
    'Minanga Norte': 1323,
    'Minante Segundo': 1323,
    'Minuri': 1323,
    'Mozzozzin Sur': 1323,
    'Mungo': 1323,
    'Municipality of Delfin Albano': 1323,
    'Muñoz East': 1323,
    'Nabannagan West': 1323,
    'Nagrumbuan': 1323,
    'Nagtipunan': 1323,
    'Naguilian': 1323,
    'Namuac': 1323,
    'Nattapian': 1323,
    'Paddaya': 1323,
    'Palagao Norte': 1323,
    'Palanan': 1323,
    'Pamplona': 1323,
    'Pangal Sur': 1323,
    'Pata': 1323,
    'Pattao': 1323,
    'Peñablanca': 1323,
    'Piat': 1323,
    'Pinoma': 1323,
    'Quezon': 1323,
    'Quibal': 1323,
    'Quirino': 1323,
    'Ragan Norte': 1323,
    'Ramon (municipal capital)': 1323,
    'Ramos West': 1323,
    'Reina Mercedes': 1323,
    'Rizal': 1323,
    'Roxas': 1323,
    'Sabtang': 1323,
    'Saguday': 1323,
    'Salinas': 1323,
    'Salinungan Proper': 1323,
    'San Agustin': 1323,
    'San Antonio': 1323,
    'San Bernardo': 1323,
    'San Fernando': 1323,
    'San Guillermo': 1323,
    'San Isidro': 1323,
    'San Jose': 1323,
    'San Luis': 1323,
    'San Manuel': 1323,
    'San Mariano': 1323,
    'San Mateo': 1323,
    'San Pablo': 1323,
    'San Pedro': 1323,
    'San Vicente': 1323,
    'Sanchez Mira': 1323,
    'Sandiat Centro': 1323,
    'Santa Ana': 1323,
    'Santa Cruz': 1323,
    'Santa Fe': 1323,
    'Santa Maria': 1323,
    'Santa Praxedes': 1323,
    'Santa Teresita': 1323,
    'Santiago': 1323,
    'Santo Domingo': 1323,
    'Santo Niño': 1323,
    'Santo Tomas': 1323,
    'Siempre Viva': 1323,
    'Sillawit': 1323,
    'Simanu Sur': 1323,
    'Simimbaan': 1323,
    'Sinamar': 1323,
    'Sindon': 1323,
    'Solana': 1323,
    'Solano': 1323,
    'Soyung': 1323,
    'Taguing': 1323,
    'Tapel': 1323,
    'Tuao': 1323,
    'Tuguegarao': 1323,
    'Tuguegarao City': 1323,
    'Tumauini': 1323,
    'Tupang': 1323,
    'Uddiawan': 1323,
    'Ugac Sur': 1323,
    'Ugad': 1323,
    'Upi': 1323,
    'Uyugan': 1323,
    'Villaverde': 1323,
    'Yeban Norte': 1323,
    'Acli': 1326,
    'Agbannawag': 1326,
    'Akle': 1326,
    'Aliaga': 1326,
    'Alua': 1326,
    'Amacalan': 1326,
    'Amucao': 1326,
    'Amuñgan': 1326,
    'Anao': 1326,
    'Angat': 1326,
    'Angeles': 1326,
    'Antipolo': 1326,
    'Apalit': 1326,
    'Arayat': 1326,
    'Arenas': 1326,
    'Arminia': 1326,
    'Bacabac': 1326,
    'Bacolor': 1326,
    'Bacsay': 1326,
    'Bagac': 1326,
    'Bagong Barrio': 1326,
    'Bagong-Sikat': 1326,
    'Bahay Pare': 1326,
    'Bakulong': 1326,
    'Balagtas': 1326,
    'Balanga': 1326,
    'Balaoang': 1326,
    'Balas': 1326,
    'Balasing': 1326,
    'Balayang': 1326,
    'Baler': 1326,
    'Balingcanaway': 1326,
    'Balite': 1326,
    'Baliuag': 1326,
    'Baloc': 1326,
    'Baloy': 1326,
    'Balsic': 1326,
    'Balucuc': 1326,
    'Balut': 1326,
    'Balutu': 1326,
    'Bamban': 1326,
    'Banawang': 1326,
    'Bani': 1326,
    'Baquero Norte': 1326,
    'Batasan Bata': 1326,
    'Batitang': 1326,
    'Bayanan': 1326,
    'Beddeng': 1326,
    'Biay': 1326,
    'Bibiclat': 1326,
    'Bicos': 1326,
    'Biga': 1326,
    'Bilad': 1326,
    'Bobon Second': 1326,
    'Bocaue': 1326,
    'Bodega': 1326,
    'Bolitoc': 1326,
    'Bongabon': 1326,
    'Botolan': 1326,
    'Buenlag': 1326,
    'Buensuseso': 1326,
    'Bulakan': 1326,
    'Bulaon': 1326,
    'Bularit': 1326,
    'Bulawin': 1326,
    'Bulihan': 1326,
    'Buliran': 1326,
    'Buliran Segundo': 1326,
    'Bulualto': 1326,
    'Bundoc': 1326,
    'Bunol': 1326,
    'Burgos': 1326,
    'Bustos': 1326,
    'Cabanatuan': 1326,
    'Cabangan': 1326,
    'Cabayaoasan': 1326,
    'Cabcaben': 1326,
    'Cabiao': 1326,
    'Cabog': 1326,
    'Cafe': 1326,
    'Calaba': 1326,
    'Calancuasan Norte': 1326,
    'Calangain': 1326,
    'Calantas': 1326,
    'Calayaan': 1326,
    'Calibungan': 1326,
    'Calibutbut': 1326,
    'Calingcuan': 1326,
    'Calumpang': 1326,
    'Calumpit': 1326,
    'Cama Juan': 1326,
    'Camachile': 1326,
    'Camias': 1326,
    'Camiling': 1326,
    'Candaba': 1326,
    'Candating': 1326,
    'Candelaria': 1326,
    'Capas': 1326,
    'Cardona': 1326,
    'Carmen': 1326,
    'Carranglan': 1326,
    'Casiguran': 1326,
    'Castillejos': 1326,
    'Cauayan': 1326,
    'Cavite': 1326,
    'Cawayan Bugtong': 1326,
    'City of Balanga': 1326,
    'City of Gapan': 1326,
    'City of Malolos': 1326,
    'City of Meycauayan': 1326,
    'City of San Fernando': 1326,
    'City of San Jose del Monte': 1326,
    'Comillas': 1326,
    'Communal': 1326,
    'Concepcion': 1326,
    'Conversion': 1326,
    'Culianin': 1326,
    'Culubasa': 1326,
    'Cut-cut Primero': 1326,
    'Cuyapo': 1326,
    'Dampol': 1326,
    'Del Carmen': 1326,
    'Del Pilar': 1326,
    'Digdig': 1326,
    'Dilasag': 1326,
    'Diliman Primero': 1326,
    'Dinalongan': 1326,
    'Dinalupihan': 1326,
    'Dingalan': 1326,
    'Dipaculao': 1326,
    'Dolores': 1326,
    'Doña Remedios Trinidad': 1326,
    'Entablado': 1326,
    'Estipona': 1326,
    'Estrella': 1326,
    'Floridablanca': 1326,
    'Gabaldon': 1326,
    'Gapan': 1326,
    'General Luna': 1326,
    'General Mamerto Natividad': 1326,
    'General Tinio': 1326,
    'Gerona': 1326,
    'Guagua': 1326,
    'Gueset': 1326,
    'Guiguinto': 1326,
    'Guimba': 1326,
    'Guisguis': 1326,
    'Guyong': 1326,
    'Hagonoy': 1326,
    'Hermosa': 1326,
    'Iba': 1326,
    'Jaen': 1326,
    'La Paz': 1326,
    'Lambakin': 1326,
    'Lanat': 1326,
    'Laug': 1326,
    'Laur': 1326,
    'Lawang Kupang': 1326,
    'Lennec': 1326,
    'Licab': 1326,
    'Ligaya': 1326,
    'Limay': 1326,
    'Liozon': 1326,
    'Lipay': 1326,
    'Llanera': 1326,
    'Lomboy': 1326,
    'Lourdes': 1326,
    'Lubao': 1326,
    'Lucapon': 1326,
    'Lupao': 1326,
    'Maasim': 1326,
    'Mabalacat': 1326,
    'Mabayo': 1326,
    'Mabilang': 1326,
    'Mabilog': 1326,
    'Mabini': 1326,
    'Macabebe': 1326,
    'Macapsing': 1326,
    'Macarse': 1326,
    'Macatbong': 1326,
    'Magalang': 1326,
    'Magliman': 1326,
    'Magtangol': 1326,
    'Maguinao': 1326,
    'Malabon': 1326,
    'Malacampa': 1326,
    'Maligaya': 1326,
    'Malino': 1326,
    'Malolos': 1326,
    'Maloma': 1326,
    'Maluid': 1326,
    'Malusac': 1326,
    'Mambog': 1326,
    'Mamonit': 1326,
    'Manacsac': 1326,
    'Manatal': 1326,
    'Mandili': 1326,
    'Mangga': 1326,
    'Manibaug Pasig': 1326,
    'Manogpi': 1326,
    'Mapalacsiao': 1326,
    'Mapalad': 1326,
    'Mapaniqui': 1326,
    'Maquiapo': 1326,
    'Marawa': 1326,
    'Maria Aurora': 1326,
    'Marilao': 1326,
    'Mariveles': 1326,
    'Masalipit': 1326,
    'Masantol': 1326,
    'Masinloc': 1326,
    'Matayumtayum': 1326,
    'Maturanoc': 1326,
    'Mayantoc': 1326,
    'Mexico': 1326,
    'Meycauayan': 1326,
    'Minalin': 1326,
    'Moncada': 1326,
    'Moriones': 1326,
    'Morong': 1326,
    'Motrico': 1326,
    'Muñoz': 1326,
    'Murcia': 1326,
    'Nagpandayan': 1326,
    'Nambalan': 1326,
    'Nampicuan': 1326,
    'Nancamarinan': 1326,
    'Nieves': 1326,
    'Niugan': 1326,
    'Norzagaray': 1326,
    'Obando': 1326,
    'Olongapo': 1326,
    'Orani': 1326,
    'Orion': 1326,
    'Paco Roman': 1326,
    'Padapada': 1326,
    'Paitan Norte': 1326,
    'Palauig': 1326,
    'Palayan': 1326,
    'Palusapis': 1326,
    'Pamatawan': 1326,
    'Panabingan': 1326,
    'Panan': 1326,
    'Pance': 1326,
    'Pandacaqui': 1326,
    'Pandi': 1326,
    'Pando': 1326,
    'Paniqui': 1326,
    'Pantabangan': 1326,
    'Pantubig': 1326,
    'Paombong': 1326,
    'Papaya': 1326,
    'Parang': 1326,
    'Parista': 1326,
    'Pau': 1326,
    'Peñaranda': 1326,
    'Pias': 1326,
    'Pilar': 1326,
    'Piñahan': 1326,
    'Pinambaran': 1326,
    'Pio': 1326,
    'Plaridel': 1326,
    'Porac': 1326,
    'Porais': 1326,
    'Prado Siongco': 1326,
    'Pulilan': 1326,
    'Pulo': 1326,
    'Pulong Gubat': 1326,
    'Pulong Sampalok': 1326,
    'Pulung Santol': 1326,
    'Pulungmasle': 1326,
    'Puncan': 1326,
    'Pura': 1326,
    'Purac': 1326,
    'Putlod': 1326,
    'Quezon': 1326,
    'Rajal Norte': 1326,
    'Ramos': 1326,
    'Rizal': 1326,
    'Sabang': 1326,
    'Sagana': 1326,
    'Salapungan': 1326,
    'Salaza': 1326,
    'Salcedo': 1326,
    'Salvacion I': 1326,
    'Samal': 1326,
    'Sampaloc': 1326,
    'San Agustin': 1326,
    'San Alejandro': 1326,
    'San Andres': 1326,
    'San Anton': 1326,
    'San Antonio': 1326,
    'San Basilio': 1326,
    'San Benito': 1326,
    'San Carlos': 1326,
    'San Casimiro': 1326,
    'San Clemente': 1326,
    'San Cristobal': 1326,
    'San Fabian': 1326,
    'San Felipe': 1326,
    'San Felipe Old': 1326,
    'San Fernando': 1326,
    'San Francisco': 1326,
    'San Ildefonso': 1326,
    'San Isidro': 1326,
    'San Jacinto': 1326,
    'San Jose': 1326,
    'San Jose del Monte': 1326,
    'San Juan': 1326,
    'San Juan de Mata': 1326,
    'San Leonardo': 1326,
    'San Lorenzo': 1326,
    'San Luis': 1326,
    'San Manuel': 1326,
    'San Marcelino': 1326,
    'San Mariano': 1326,
    'San Mateo': 1326,
    'San Miguel': 1326,
    'San Narciso': 1326,
    'San Nicolas': 1326,
    'San Pascual': 1326,
    'San Patricio': 1326,
    'San Rafael': 1326,
    'San Ricardo': 1326,
    'San Roque': 1326,
    'San Roque Dau First': 1326,
    'San Simon': 1326,
    'San Vicente': 1326,
    'San Vincente': 1326,
    'Santa Ana': 1326,
    'Santa Barbara': 1326,
    'Santa Cruz': 1326,
    'Santa Fe': 1326,
    'Santa Ignacia': 1326,
    'Santa Ines West': 1326,
    'Santa Juliana': 1326,
    'Santa Lucia': 1326,
    'Santa Maria': 1326,
    'Santa Monica': 1326,
    'Santa Rita': 1326,
    'Santa Rosa': 1326,
    'Santa Teresa First': 1326,
    'Santiago': 1326,
    'Santo Cristo': 1326,
    'Santo Domingo': 1326,
    'Santo Niño': 1326,
    'Santo Rosario': 1326,
    'Santo Tomas': 1326,
    'Santol': 1326,
    'Sapang': 1326,
    'Sapang Buho': 1326,
    'Sapol': 1326,
    'Sasmuan': 1326,
    'Saysain': 1326,
    'Sibul': 1326,
    'Siclong': 1326,
    'Sinait': 1326,
    'Sinilian First': 1326,
    'Soledad': 1326,
    'Subic': 1326,
    'Suklayin': 1326,
    'Sula': 1326,
    'Sulucan': 1326,
    'Tabacao': 1326,
    'Tabon': 1326,
    'Tabuating': 1326,
    'Tal I Mun Doc': 1326,
    'Talaga': 1326,
    'Talang': 1326,
    'Talavera': 1326,
    'Taltal': 1326,
    'Talugtug': 1326,
    'Tariji': 1326,
    'Tarlac City': 1326,
    'Tayabo': 1326,
    'Telabastagan': 1326,
    'Tikiw': 1326,
    'Tinang': 1326,
    'Tondod': 1326,
    'Uacon': 1326,
    'Umiray': 1326,
    'Upig': 1326,
    'Vargas': 1326,
    'Victoria': 1326,
    'Villa Aglipay': 1326,
    'Villa Isla': 1326,
    'Vizal San Pablo': 1326,
    'Vizal Santo Niño': 1326,
    'Zaragoza': 1326,
    'Aanislag': 1337,
    'Abucay': 1337,
    'Abuyog': 1337,
    'Agos': 1337,
    'Aguada': 1337,
    'Agupit': 1337,
    'Alayao': 1337,
    'Antipolo': 1337,
    'Anuling': 1337,
    'Apad': 1337,
    'Apud': 1337,
    'Armenia': 1337,
    'Aroroy': 1337,
    'Ayugan': 1337,
    'Baao': 1337,
    'Bacacay': 1337,
    'Bacolod': 1337,
    'Bacon': 1337,
    'Badian': 1337,
    'Bagacay': 1337,
    'Bagahanlad': 1337,
    'Bagamanoc': 1337,
    'Bagumbayan': 1337,
    'Bahay': 1337,
    'Balading': 1337,
    'Balaogan': 1337,
    'Balatan': 1337,
    'Baleno': 1337,
    'Balete': 1337,
    'Baligang': 1337,
    'Balinad': 1337,
    'Baliuag Nuevo': 1337,
    'Balogo': 1337,
    'Balucawi': 1337,
    'Balud': 1337,
    'Banag': 1337,
    'Bangad': 1337,
    'Bangkirohan': 1337,
    'Banocboc': 1337,
    'Bao': 1337,
    'Baras': 1337,
    'Barayong': 1337,
    'Barcelona': 1337,
    'Bariw': 1337,
    'Barra': 1337,
    'Bascaron': 1337,
    'Basiad': 1337,
    'Basicao Coastal': 1337,
    'Basud': 1337,
    'Batana': 1337,
    'Bato': 1337,
    'Batobalane': 1337,
    'Batuan': 1337,
    'Baybay': 1337,
    'Beberon': 1337,
    'Bigaa': 1337,
    'Binanwanaan': 1337,
    'Binitayan': 1337,
    'Binodegahan': 1337,
    'Bolo': 1337,
    'Bombon': 1337,
    'Bonga': 1337,
    'Boton': 1337,
    'Buang': 1337,
    'Buenavista': 1337,
    'Buga': 1337,
    'Buhatan': 1337,
    'Buhi': 1337,
    'Bula': 1337,
    'Bulan': 1337,
    'Bulo': 1337,
    'Buluang': 1337,
    'Bulusan': 1337,
    'Burabod': 1337,
    'Buracan': 1337,
    'Busing': 1337,
    'Butag': 1337,
    'Buyo': 1337,
    'Cabcab': 1337,
    'Cabiguan': 1337,
    'Cabitan': 1337,
    'Cabognon': 1337,
    'Cabugao': 1337,
    'Cabusao': 1337,
    'Caditaan': 1337,
    'Cadlan': 1337,
    'Cagmanaba': 1337,
    'Calabaca': 1337,
    'Calabanga': 1337,
    'Calachuchi': 1337,
    'Calasgasan': 1337,
    'Calatagan': 1337,
    'Calolbon': 1337,
    'Camalig': 1337,
    'Camaligan': 1337,
    'Canaman': 1337,
    'Canomoy': 1337,
    'Capalonga': 1337,
    'Capucnasan': 1337,
    'Capuy': 1337,
    'Caramoan': 1337,
    'Caramoran': 1337,
    'Caranan': 1337,
    'Caraycayon': 1337,
    'Carriedo': 1337,
    'Casiguran': 1337,
    'Castilla': 1337,
    'Castillo': 1337,
    'Catabangan': 1337,
    'Cataingan': 1337,
    'Causip': 1337,
    'Claveria': 1337,
    'Cotmon': 1337,
    'Culacling': 1337,
    'Cumadcad': 1337,
    'Curry': 1337,
    'Daet': 1337,
    'Daguit': 1337,
    'Dalupaon': 1337,
    'Dangcalan': 1337,
    'Dapdap': 1337,
    'Daraga': 1337,
    'Del Gallego': 1337,
    'Del Rosario': 1337,
    'Dimasalang': 1337,
    'Donsol': 1337,
    'Dugcal': 1337,
    'Dugongan': 1337,
    'Esperanza': 1337,
    'Estancia': 1337,
    'Fabrica': 1337,
    'Gabao': 1337,
    'Gainza': 1337,
    'Gambalidio': 1337,
    'Garchitorena': 1337,
    'Gatbo': 1337,
    'Gibgos': 1337,
    'Gigmoto': 1337,
    'Goa': 1337,
    'Gubat': 1337,
    'Guijalo': 1337,
    'Guinacotan': 1337,
    'Guinobatan': 1337,
    'Gumaus': 1337,
    'Guruyan': 1337,
    'Hamoraon': 1337,
    'Herrera': 1337,
    'Himaao': 1337,
    'Hobo': 1337,
    'Imelda': 1337,
    'Inapatan': 1337,
    'Iraya': 1337,
    'Iriga City': 1337,
    'Irosin': 1337,
    'Joroan': 1337,
    'Jose Pañganiban': 1337,
    'Jovellar': 1337,
    'Juban': 1337,
    'Kaliliog': 1337,
    'Kinalansan': 1337,
    'Labnig': 1337,
    'Labo': 1337,
    'Lacag': 1337,
    'Lagonoy': 1337,
    'Lajong': 1337,
    'Lanigay': 1337,
    'Lantangan': 1337,
    'Larap': 1337,
    'Legaspi': 1337,
    'Libmanan': 1337,
    'Libog': 1337,
    'Libon': 1337,
    'Liboro': 1337,
    'Ligao': 1337,
    'Limbuhan': 1337,
    'Lourdes': 1337,
    'Lubigan': 1337,
    'Lugui': 1337,
    'Luklukan': 1337,
    'Luna': 1337,
    'Lupi': 1337,
    'Lupi Viejo': 1337,
    'Maagnas': 1337,
    'Mabiton': 1337,
    'Macabugos': 1337,
    'Macalaya': 1337,
    'Magallanes': 1337,
    'Magarao': 1337,
    'Magsalangi': 1337,
    'Mahaba': 1337,
    'Malabog': 1337,
    'Malasugui': 1337,
    'Malatap': 1337,
    'Malawag': 1337,
    'Malbug': 1337,
    'Malidong': 1337,
    'Malilipot': 1337,
    'Malinao': 1337,
    'Malinta': 1337,
    'Mambulo': 1337,
    'Mampurog': 1337,
    'Manamrag': 1337,
    'Mandaon': 1337,
    'Manga': 1337,
    'Manito': 1337,
    'Manquiring': 1337,
    'Maonon': 1337,
    'Marintoc': 1337,
    'Marupit': 1337,
    'Masaraway': 1337,
    'Masbate': 1337,
    'Maslog': 1337,
    'Masoli': 1337,
    'Matacon': 1337,
    'Matnog': 1337,
    'Mauraro': 1337,
    'Mayngaran': 1337,
    'Mercedes': 1337,
    'Miaga': 1337,
    'Milagros': 1337,
    'Milaor': 1337,
    'Miliroc': 1337,
    'Minalabac': 1337,
    'Mobo': 1337,
    'Monbon': 1337,
    'Monreal': 1337,
    'Muladbucad': 1337,
    'Naagas': 1337,
    'Nabangig': 1337,
    'Nabua': 1337,
    'Naga': 1337,
    'Naro': 1337,
    'Nato': 1337,
    'Ocampo': 1337,
    'Odicon': 1337,
    'Ogod': 1337,
    'Osiao': 1337,
    'Osmeña': 1337,
    'Padang': 1337,
    'Palali': 1337,
    'Palanas': 1337,
    'Palestina': 1337,
    'Palsong': 1337,
    'Pambuhan': 1337,
    'Pamplona': 1337,
    'Pandan': 1337,
    'Panganiban': 1337,
    'Panguiranan': 1337,
    'Paniqui': 1337,
    'Pantao': 1337,
    'Parabcan': 1337,
    'Paracale': 1337,
    'Pasacao': 1337,
    'Paulba': 1337,
    'Pawa': 1337,
    'Pawican': 1337,
    'Pawili': 1337,
    'Peña': 1337,
    'Pilar': 1337,
    'Pili': 1337,
    'Pinit': 1337,
    'Pio Duran': 1337,
    'Placer': 1337,
    'Polangui': 1337,
    'Ponso': 1337,
    'Potot': 1337,
    'Prieto Diaz': 1337,
    'Province of Albay': 1337,
    'Province of Camarines Norte': 1337,
    'Province of Camarines Sur': 1337,
    'Province of Catanduanes': 1337,
    'Province of Masbate': 1337,
    'Province of Sorsogon': 1337,
    'Puro': 1337,
    'Putiao': 1337,
    'Quezon': 1337,
    'Quitang': 1337,
    'Ragay': 1337,
    'Rapu-Rapu': 1337,
    'Recodo': 1337,
    'Rizal': 1337,
    'Sabang': 1337,
    'Sabang Indan': 1337,
    'Sagnay': 1337,
    'Sagpon': 1337,
    'Sagrada': 1337,
    'Sagrada Familia': 1337,
    'Sagurong': 1337,
    'Salingogan': 1337,
    'Salogon': 1337,
    'Salvacion': 1337,
    'San Agustin': 1337,
    'San Andres': 1337,
    'San Antonio': 1337,
    'San Felipe': 1337,
    'San Fernando': 1337,
    'San Francisco': 1337,
    'San Gabriel': 1337,
    'San Isidro': 1337,
    'San Jacinto': 1337,
    'San Jose': 1337,
    'San Lucas': 1337,
    'San Miguel': 1337,
    'San Pascual': 1337,
    'San Pedro': 1337,
    'San Rafael': 1337,
    'San Ramon': 1337,
    'San Roque': 1337,
    'San Sebastian': 1337,
    'San Vicente': 1337,
    'Santa Cruz': 1337,
    'Santa Elena': 1337,
    'Santa Justina': 1337,
    'Santa Magdalena': 1337,
    'Santa Rosa Sur': 1337,
    'Santa Teresita': 1337,
    'Santo Domingo': 1337,
    'Santo Niño': 1337,
    'Sinuknipan': 1337,
    'Sipocot': 1337,
    'Siruma': 1337,
    'Sogod': 1337,
    'Sorsogon': 1337,
    'Sugcad': 1337,
    'Sugod': 1337,
    'Tabaco': 1337,
    'Tagas': 1337,
    'Tagoytoy': 1337,
    'Talisay': 1337,
    'Talubatib': 1337,
    'Tambo': 1337,
    'Tara': 1337,
    'Tariric': 1337,
    'Tigaon': 1337,
    'Tigbao': 1337,
    'Tigbaw': 1337,
    'Tigbinan': 1337,
    'Tinago': 1337,
    'Tinalmud': 1337,
    'Tinambac': 1337,
    'Tinampo': 1337,
    'Tinawagan': 1337,
    'Tiwi': 1337,
    'Tubli': 1337,
    'Tuburan': 1337,
    'Tugos': 1337,
    'Tulay na Lupa': 1337,
    'Tumalaytay': 1337,
    'Umabay': 1337,
    'Usab': 1337,
    'Uson': 1337,
    'Utabi': 1337,
    'Viga': 1337,
    'Villahermosa': 1337,
    'Vinzons': 1337,
    'Virac': 1337,
    'Abaca': 1336,
    'Abangay': 1336,
    'Abiera': 1336,
    'Abilay': 1336,
    'Ag-ambulong': 1336,
    'Aganan': 1336,
    'Aglalana': 1336,
    'Agpangi': 1336,
    'Aguisan': 1336,
    'Ajuy': 1336,
    'Alacaygan': 1336,
    'Alegria': 1336,
    'Alibunan': 1336,
    'Alicante': 1336,
    'Alijis': 1336,
    'Alim': 1336,
    'Alimodian': 1336,
    'Alimono': 1336,
    'Altavas': 1336,
    'Ambulong': 1336,
    'Andres Bonifacio': 1336,
    'Anini-y': 1336,
    'Anoring': 1336,
    'Antipolo': 1336,
    'Aquino': 1336,
    'Araal': 1336,
    'Aranas Sur': 1336,
    'Aranda': 1336,
    'Arcangel': 1336,
    'Asia': 1336,
    'Asturga': 1336,
    'Atabayan': 1336,
    'Atipuluhan': 1336,
    'Aurelliana': 1336,
    'Avila': 1336,
    'Bacalan': 1336,
    'Bacolod City': 1336,
    'Bacuyangan': 1336,
    'Badiangan': 1336,
    'Badlan': 1336,
    'Bago City': 1336,
    'Bagroy': 1336,
    'Bagumbayan': 1336,
    'Bailan': 1336,
    'Balabag': 1336,
    'Balasan': 1336,
    'Balete': 1336,
    'Balibagan Oeste': 1336,
    'Baliwagan': 1336,
    'Bancal': 1336,
    'Banga': 1336,
    'Barbaza': 1336,
    'Barotac Nuevo': 1336,
    'Barotac Viejo': 1336,
    'Basiao': 1336,
    'Batad': 1336,
    'Batan': 1336,
    'Bato': 1336,
    'Bay-ang': 1336,
    'Bayas': 1336,
    'Belison': 1336,
    'Biao': 1336,
    'Bilao': 1336,
    'Binabaan': 1336,
    'Binalbagan': 1336,
    'Binantocan': 1336,
    'Bingawan': 1336,
    'Binon-an': 1336,
    'Binonga': 1336,
    'Bitadtun': 1336,
    'Bocana': 1336,
    'Bolanon': 1336,
    'Bolilao': 1336,
    'Bolo': 1336,
    'Bolong': 1336,
    'Brgy. Bachaw Norte, Kalibo': 1336,
    'Brgy. Bulwang, Numancia': 1336,
    'Brgy. Mabilo, New Washington': 1336,
    'Brgy. Nalook, kalibo': 1336,
    'Brgy. New Buswang, Kalibo': 1336,
    'Brgy. Tinigao, Kalibo': 1336,
    'Buenavista': 1336,
    'Buga': 1336,
    'Bugang': 1336,
    'Bugasong': 1336,
    'Bula': 1336,
    'Bulad': 1336,
    'Bulata': 1336,
    'Buluangan': 1336,
    'Bungsuan': 1336,
    'Buray': 1336,
    'Burias': 1336,
    'Buruanga': 1336,
    'Busay': 1336,
    'Buyuan': 1336,
    'Cabacungan': 1336,
    'Cabadiangan': 1336,
    'Cabanbanan': 1336,
    'Cabano': 1336,
    'Cabatuan': 1336,
    'Cabilao': 1336,
    'Cabilauan': 1336,
    'Cabugao': 1336,
    'Cadagmayan Norte': 1336,
    'Cadiz': 1336,
    'Cagbang': 1336,
    'Calampisauan': 1336,
    'Calape': 1336,
    'Calatrava': 1336,
    'Calaya': 1336,
    'Calinog': 1336,
    'Calizo': 1336,
    'Caluya': 1336,
    'Camalobalo': 1336,
    'Camandag': 1336,
    'Camangcamang': 1336,
    'Camindangan': 1336,
    'Camingawan': 1336,
    'Candelaria': 1336,
    'Candoni': 1336,
    'Caningay': 1336,
    'Canroma': 1336,
    'Cansilayan': 1336,
    'Cansolungon': 1336,
    'Canturay': 1336,
    'Capaga': 1336,
    'Capitan Ramon': 1336,
    'Carabalan': 1336,
    'Caridad': 1336,
    'Carles': 1336,
    'Carmelo': 1336,
    'Carmen Grande': 1336,
    'Cartagena': 1336,
    'Cassanayan': 1336,
    'Caticlan': 1336,
    'Catungan': 1336,
    'Cawayan': 1336,
    'Cayanguan': 1336,
    'Cayhagan': 1336,
    'Cervantes': 1336,
    'Chambrey': 1336,
    'Codcod': 1336,
    'Cogon': 1336,
    'Colipapa': 1336,
    'Concepcion': 1336,
    'Concordia': 1336,
    'Consolacion': 1336,
    'Constancia': 1336,
    'Consuelo': 1336,
    'Cordova': 1336,
    'Cortez': 1336,
    'Cuartero': 1336,
    'Culasi': 1336,
    'Da-an Sur': 1336,
    'Daliciasao': 1336,
    'Damayan': 1336,
    'Dancalan': 1336,
    'Dao': 1336,
    'Dapdapan': 1336,
    'De la Paz': 1336,
    'Dian-ay': 1336,
    'Dingle': 1336,
    'Dos Hermanas': 1336,
    'Dueñas': 1336,
    'Dulangan': 1336,
    'Dulao': 1336,
    'Dumalag': 1336,
    'Dumangas': 1336,
    'Dumarao': 1336,
    'Dungon': 1336,
    'Duran': 1336,
    'East Valencia': 1336,
    'Egaña': 1336,
    'Ermita': 1336,
    'Escalante': 1336,
    'Estancia': 1336,
    'Eustaquio Lopez': 1336,
    'Feliciano': 1336,
    'Gabi': 1336,
    'Getulio': 1336,
    'Gibato': 1336,
    'Gibong': 1336,
    'Gines-Patay': 1336,
    'Granada': 1336,
    'Guadalupe': 1336,
    'Guiljungan': 1336,
    'Guimbal': 1336,
    'Guinoaliuan': 1336,
    'Guinticgan': 1336,
    'Guintubhan': 1336,
    'Guisijan': 1336,
    'Hacienda Refugio': 1336,
    'Hacienda Santa Rosa': 1336,
    'Haguimit': 1336,
    'Hamtic': 1336,
    'Himamaylan': 1336,
    'Himaya': 1336,
    'Hinigaran': 1336,
    'Hipona': 1336,
    'Ibajay': 1336,
    'Idio': 1336,
    'Igang': 1336,
    'Igbaras': 1336,
    'Igbon': 1336,
    'Igcocolo': 1336,
    'Igmaya-an': 1336,
    'Ilog': 1336,
    'Iloilo': 1336,
    'Imbang': 1336,
    'Inayauan': 1336,
    'Intampilan': 1336,
    'Isabela': 1336,
    'Ivisan': 1336,
    'Jaena': 1336,
    'Jaguimitan': 1336,
    'Jalaud': 1336,
    'Jamabalod': 1336,
    'Jamindan': 1336,
    'Janiuay': 1336,
    'Japitan': 1336,
    'Jarigue': 1336,
    'Jayubó': 1336,
    'Jibao-an': 1336,
    'Jordan': 1336,
    'Kabankalan': 1336,
    'Kabilauan': 1336,
    'Kalibo (poblacion)': 1336,
    'Kaliling': 1336,
    'Kumalisquis': 1336,
    'La Carlota': 1336,
    'La Castellana': 1336,
    'La Granja': 1336,
    'La Paz': 1336,
    'Lacaron': 1336,
    'Lalab': 1336,
    'Lalagsan': 1336,
    'Lambunao': 1336,
    'Lañgub': 1336,
    'Lanot': 1336,
    'Lantangan': 1336,
    'Lawigan': 1336,
    'Leganes': 1336,
    'Lemery': 1336,
    'Leon': 1336,
    'Libacao': 1336,
    'Libertad': 1336,
    'Linabuan': 1336,
    'Linabuan Sur': 1336,
    'Linaon': 1336,
    'Locmayan': 1336,
    'Lono': 1336,
    'Lonoy': 1336,
    'Lopez Jaena': 1336,
    'Lucena': 1336,
    'Lucero': 1336,
    'Luna': 1336,
    'Lupo': 1336,
    'Maao': 1336,
    'Maasin': 1336,
    'Maayon': 1336,
    'Mabini': 1336,
    'Madalag': 1336,
    'Magallon Cadre': 1336,
    'Magdalena': 1336,
    'Makato': 1336,
    'Malabonot': 1336,
    'Malabor': 1336,
    'Malangabang': 1336,
    'Malay': 1336,
    'Malayo-an': 1336,
    'Malinao': 1336,
    'Malocloc': 1336,
    'Maloco': 1336,
    'Mambagatan': 1336,
    'Mambusao': 1336,
    'Manalad': 1336,
    'Manapla': 1336,
    'Mangoso': 1336,
    'Manika': 1336,
    'Manjoy': 1336,
    'Manlucahoc': 1336,
    'Manoc-Manoc': 1336,
    'Mansilingan': 1336,
    'Manup': 1336,
    'Mapili': 1336,
    'Maquiling': 1336,
    'Marawis': 1336,
    'Maribong': 1336,
    'Maricalom': 1336,
    'Masaling': 1336,
    'Masonogan': 1336,
    'Miagao': 1336,
    'Mianay': 1336,
    'Mina': 1336,
    'Minapasoc': 1336,
    'Minuyan': 1336,
    'Miranda': 1336,
    'Moises Padilla': 1336,
    'Monpon': 1336,
    'Montilla': 1336,
    'Morales': 1336,
    'Morobuan': 1336,
    'Murcia': 1336,
    'Nabas': 1336,
    'Nabulao': 1336,
    'Naili': 1336,
    'Naisud': 1336,
    'Nangka': 1336,
    'Napnapan': 1336,
    'Napoles': 1336,
    'Nato': 1336,
    'New Pandanon': 1336,
    'New Washington': 1336,
    'Nueva Valencia': 1336,
    'Numancia': 1336,
    'Ochanado': 1336,
    'Odiong': 1336,
    'Ogtongon': 1336,
    'Ondoy': 1336,
    'Oracon': 1336,
    'Orong': 1336,
    'Oton': 1336,
    'Pacol': 1336,
    'Pakiad': 1336,
    'Palampas': 1336,
    'Panay': 1336,
    'Panayacan': 1336,
    'Pandan': 1336,
    'Panitan': 1336,
    'Paraiso': 1336,
    'Parion': 1336,
    'Pasil': 1336,
    'Passi': 1336,
    'Patique': 1336,
    'Patnongon': 1336,
    'Patonan': 1336,
    'Patria': 1336,
    'Pavia': 1336,
    'Pawa': 1336,
    'Payao': 1336,
    'Piape I': 1336,
    'Pilar': 1336,
    'Pili': 1336,
    'Piña': 1336,
    'Plaridel': 1336,
    'Platagata': 1336,
    'Polo': 1336,
    'Polopina': 1336,
    'Ponong': 1336,
    'Pontevedra': 1336,
    'Pototan': 1336,
    'President Roxas': 1336,
    'Prosperidad': 1336,
    'Province of Aklan': 1336,
    'Province of Antique': 1336,
    'Province of Capiz': 1336,
    'Province of Guimaras': 1336,
    'Province of Iloilo': 1336,
    'Province of Negros Occidental': 1336,
    'Pulupandan': 1336,
    'Punao': 1336,
    'Quezon': 1336,
    'Quinagaringan': 1336,
    'Quipot': 1336,
    'Rizal': 1336,
    'Rosario': 1336,
    'Roxas City': 1336,
    'Sagang': 1336,
    'Sagasa': 1336,
    'Sagay': 1336,
    'Salamanca': 1336,
    'Salvacion': 1336,
    'San Antonio': 1336,
    'San Dionisio': 1336,
    'San Enrique': 1336,
    'San Fernando': 1336,
    'San Francisco': 1336,
    'San Joaquin': 1336,
    'San Jose': 1336,
    'San Juan': 1336,
    'San Miguel': 1336,
    'San Nicolas': 1336,
    'San Pablo': 1336,
    'San Pedro': 1336,
    'San Rafael': 1336,
    'San Remigio': 1336,
    'San Salvador': 1336,
    'Santa Angel': 1336,
    'Santa Barbara': 1336,
    'Santa Cruz': 1336,
    'Santa Monica': 1336,
    'Santa Teresa': 1336,
    'Santiago': 1336,
    'Santol': 1336,
    'Sapian': 1336,
    'Sara': 1336,
    'Saravia': 1336,
    'Sebaste': 1336,
    'Semirara': 1336,
    'Sibaguan': 1336,
    'Sibalom': 1336,
    'Sibucao': 1336,
    'Sigma': 1336,
    'Sipalay': 1336,
    'Suay': 1336,
    'Sulangan': 1336,
    'Sumag': 1336,
    'Tabu': 1336,
    'Tabuc Pontevedra': 1336,
    'Talaban': 1336,
    'Talisay': 1336,
    'Taloc': 1336,
    'Talokgañgan': 1336,
    'Talon': 1336,
    'Tambac': 1336,
    'Tambalisa': 1336,
    'Tamlang': 1336,
    'Tangalan': 1336,
    'Tanza': 1336,
    'Tapas': 1336,
    'Tarong': 1336,
    'Tibiao': 1336,
    'Tigbauan': 1336,
    'Tiglauigan': 1336,
    'Tigum': 1336,
    'Tiling': 1336,
    'Timpas': 1336,
    'Tinogboc': 1336,
    'Tinongan': 1336,
    'Tiring': 1336,
    'Tiwi': 1336,
    'Tobias Fornier': 1336,
    'Toboso': 1336,
    'Tortosa': 1336,
    'Trapiche': 1336,
    'Tugas': 1336,
    'Tumcon Ilawod': 1336,
    'Tuyum': 1336,
    'Ualog': 1336,
    'Ungca': 1336,
    'Unidos': 1336,
    'Union': 1336,
    'Valderrama': 1336,
    'Valladolid': 1336,
    'Victorias': 1336,
    'Viejo Daan Banua': 1336,
    'Vista Alegre': 1336,
    'Vito': 1336,
    'Yapak': 1336,
    'Yubo': 1336,
    'Zarraga': 1336,
    'Ampatuan': 1316,
    'Andalan': 1316,
    'Anuling': 1316,
    'Awang': 1316,
    'Bacayawan': 1316,
    'Badak': 1316,
    'Bagan': 1316,
    'Baka': 1316,
    'Bakung': 1316,
    'Balabagan': 1316,
    'Balas': 1316,
    'Balimbing': 1316,
    'Balindong': 1316,
    'Bangkal': 1316,
    'Bankaw': 1316,
    'Barira': 1316,
    'Barurao': 1316,
    'Bato Bato': 1316,
    'Baunu-Timbangan': 1316,
    'Bawison': 1316,
    'Bayang': 1316,
    'Bayanga': 1316,
    'Begang': 1316,
    'Binidayan': 1316,
    'Binuang': 1316,
    'Blinsung': 1316,
    'Bongao': 1316,
    'Bongued': 1316,
    'Buadiposo-Buntong': 1316,
    'Bualan': 1316,
    'Buan': 1316,
    'Buansa': 1316,
    'Budta': 1316,
    'Bugasan': 1316,
    'Buldon': 1316,
    'Buliok': 1316,
    'Bulit': 1316,
    'Buluan': 1316,
    'Bumbaran': 1316,
    'Butig': 1316,
    'Calanogas': 1316,
    'City of Isabela': 1316,
    'Colonia': 1316,
    'Cotabato': 1316,
    'Dado': 1316,
    'Dadus': 1316,
    'Dalican': 1316,
    'Dalumangcob': 1316,
    'Damabalas': 1316,
    'Damatulan': 1316,
    'Datu Paglas': 1316,
    'Datu Piang': 1316,
    'Digal': 1316,
    'Dinaig': 1316,
    'Dinganen': 1316,
    'Ebcor Town': 1316,
    'Gadung': 1316,
    'Ganassi': 1316,
    'Gang': 1316,
    'Guiong': 1316,
    'Idtig': 1316,
    'Jolo': 1316,
    'Kabasalan': 1316,
    'Kagay': 1316,
    'Kajatian': 1316,
    'Kalang': 1316,
    'Kalbugan': 1316,
    'Kambing': 1316,
    'Kanlagay': 1316,
    'Kansipati': 1316,
    'Karungdong': 1316,
    'Katico': 1316,
    'Katidtuan': 1316,
    'Katuli': 1316,
    'Kauran': 1316,
    'Kitango': 1316,
    'Kitapak': 1316,
    'Kolape': 1316,
    'Kulase': 1316,
    'Kulay-Kulay': 1316,
    'Kulempang': 1316,
    'Kungtad': 1316,
    'Labuñgan': 1316,
    'Laminusa': 1316,
    'Lamitan City': 1316,
    'Langpas': 1316,
    'Languyan': 1316,
    'Larap': 1316,
    'Latung': 1316,
    'Layog': 1316,
    'Ligayan': 1316,
    'Limbo': 1316,
    'Litayan': 1316,
    'Lookan': 1316,
    'Lu-uk': 1316,
    'Lugus': 1316,
    'Lumbac': 1316,
    'Lumbatan': 1316,
    'Lumbayanague': 1316,
    'Lutayan': 1316,
    'Luuk Datan': 1316,
    'Madalum': 1316,
    'Madamba': 1316,
    'Maganoy': 1316,
    'Mahala': 1316,
    'Maimbung': 1316,
    'Makir': 1316,
    'Malabang': 1316,
    'Maluso': 1316,
    'Manubul': 1316,
    'Manuk Mangkaw': 1316,
    'Marantao': 1316,
    'Marawi City': 1316,
    'Marogong': 1316,
    'Marsada': 1316,
    'Masiu': 1316,
    'Matanog': 1316,
    'Mataya': 1316,
    'Mauboh': 1316,
    'Mileb': 1316,
    'New Batu Batu': 1316,
    'Nuyo': 1316,
    'Pagalungan': 1316,
    'Pagatin': 1316,
    'Paitan': 1316,
    'Panabuan': 1316,
    'Panadtaban': 1316,
    'Pandakan': 1316,
    'Pandan Niog': 1316,
    'Pang': 1316,
    'Parang': 1316,
    'Parangan': 1316,
    'Parian Dakula': 1316,
    'Patikul': 1316,
    'Pawak': 1316,
    'Payuhan': 1316,
    'Pidsandawan': 1316,
    'Pinaring': 1316,
    'Pitogo': 1316,
    'Polloc': 1316,
    'Province of Basilan': 1316,
    'Province of Lanao del Sur': 1316,
    'Province of Maguindanao': 1316,
    'Province of Sulu': 1316,
    'Province of Tawi-Tawi': 1316,
    'Pualas': 1316,
    'Punay': 1316,
    'Ramain': 1316,
    'Rimpeso': 1316,
    'Rominimbang': 1316,
    'Sagasa': 1316,
    'Saguiaran': 1316,
    'Sambuluan': 1316,
    'Sanga-Sanga': 1316,
    'Santa Clara': 1316,
    'Sapa': 1316,
    'Sapadun': 1316,
    'Satan': 1316,
    'Semut': 1316,
    'Siasi': 1316,
    'Simbahan': 1316,
    'Simuay': 1316,
    'Simunul': 1316,
    'Sionogan': 1316,
    'Sitangkai': 1316,
    'South Upi': 1316,
    'Sultan Kudarat': 1316,
    'Sumisip': 1316,
    'Tabiauan': 1316,
    'Tablas': 1316,
    'Taganak': 1316,
    'Tairan Camp': 1316,
    'Talayan': 1316,
    'Talipao': 1316,
    'Talipaw': 1316,
    'Talitay': 1316,
    'Tampakan': 1316,
    'Tamparan': 1316,
    'Tapayan': 1316,
    'Tapikan': 1316,
    'Taungoh': 1316,
    'Taviran': 1316,
    'Tinutulan': 1316,
    'Tipo-Tipo': 1316,
    'Tongouson': 1316,
    'Tubaran': 1316,
    'Tuburan': 1316,
    'Tugaya': 1316,
    'Tumbagaan': 1316,
    'Tunggol': 1316,
    'Tungol': 1316,
    'Ungus-Ungus': 1316,
    'Upi': 1316,
    'Uyaan': 1316,
    'Wao': 1316,
    'Abaca': 1344,
    'Abis': 1344,
    'Abucayan': 1344,
    'Adlaon': 1344,
    'Agsungot': 1344,
    'Aguining': 1344,
    'Alangilan': 1344,
    'Alangilanan': 1344,
    'Alburquerque': 1344,
    'Alcantara': 1344,
    'Alcoy': 1344,
    'Alegria': 1344,
    'Alicia': 1344,
    'Aloguinsan': 1344,
    'Alpaco': 1344,
    'Amdos': 1344,
    'Amio': 1344,
    'Anda': 1344,
    'Anonang': 1344,
    'Anopog': 1344,
    'Antequera': 1344,
    'Antipolo': 1344,
    'Apas': 1344,
    'Apoya': 1344,
    'Argao': 1344,
    'Asturias': 1344,
    'Atop-atop': 1344,
    'Ayungon': 1344,
    'Azagra': 1344,
    'Bachauan': 1344,
    'Baclayon': 1344,
    'Bacong': 1344,
    'Badian': 1344,
    'Bagacay': 1344,
    'Bagay': 1344,
    'Bagtic': 1344,
    'Bairan': 1344,
    'Bais': 1344,
    'Bal-os': 1344,
    'Balamban': 1344,
    'Balayong': 1344,
    'Balilihan': 1344,
    'Balogo': 1344,
    'Balud': 1344,
    'Banhigan': 1344,
    'Banilad': 1344,
    'Bantayan': 1344,
    'Barili': 1344,
    'Basak': 1344,
    'Basay': 1344,
    'Basdiot': 1344,
    'Bateria': 1344,
    'Batuan': 1344,
    'Baud': 1344,
    'Baugo': 1344,
    'Bayawan': 1344,
    'Becerril': 1344,
    'Biabas': 1344,
    'Biasong': 1344,
    'Bien Unido': 1344,
    'Biking': 1344,
    'Bilar': 1344,
    'Binlod': 1344,
    'Biton': 1344,
    'Bitoon': 1344,
    'Bogo': 1344,
    'Bohol': 1344,
    'Bolisong': 1344,
    'Boljoon': 1344,
    'Bonawon': 1344,
    'Bonbon': 1344,
    'Bood': 1344,
    'Borbon': 1344,
    'Botigues': 1344,
    'Buagsong': 1344,
    'Buanoy': 1344,
    'Buenavista': 1344,
    'Bugas': 1344,
    'Bugsoc': 1344,
    'Bulasa': 1344,
    'Bulod': 1344,
    'Cabalawan': 1344,
    'Cabangahan': 1344,
    'Cabul-an': 1344,
    'Calamba': 1344,
    'Calape': 1344,
    'Calero': 1344,
    'Calidñgan': 1344,
    'Calituban': 1344,
    'Calumboyan': 1344,
    'Camambugan': 1344,
    'Cambanay': 1344,
    'Campoyo': 1344,
    'Campusong': 1344,
    'Can-asujan': 1344,
    'Canauay': 1344,
    'Candabong': 1344,
    'Candijay': 1344,
    'Canhaway': 1344,
    'Canjulao': 1344,
    'Canlaon': 1344,
    'Canmaya Diot': 1344,
    'Cansuje': 1344,
    'Cantao-an': 1344,
    'Carcar': 1344,
    'Carmelo': 1344,
    'Carmen': 1344,
    'Casala-an': 1344,
    'Casay': 1344,
    'Catarman': 1344,
    'Caticugan': 1344,
    'Catigbian': 1344,
    'Catmon': 1344,
    'Catmondaan': 1344,
    'Catungawan Sur': 1344,
    'Cawayan': 1344,
    'Cayang': 1344,
    'Cebu City': 1344,
    'Clarin': 1344,
    'Cogan': 1344,
    'Cogon': 1344,
    'Cogon Cruz': 1344,
    'Cogtong': 1344,
    'Colonia': 1344,
    'Compostela': 1344,
    'Consolacion': 1344,
    'Consuelo': 1344,
    'Cordova': 1344,
    'Corella': 1344,
    'Cortes': 1344,
    'Daanbantayan': 1344,
    'Dagohoy': 1344,
    'Dalaguete': 1344,
    'Damolog': 1344,
    'Danao': 1344,
    'Dapitan': 1344,
    'Datagon': 1344,
    'Dauin': 1344,
    'Dauis': 1344,
    'De la Paz': 1344,
    'Dimiao': 1344,
    'Doljo': 1344,
    'Doong': 1344,
    'Duero': 1344,
    'Dumaguete': 1344,
    'Dumanjog': 1344,
    'El Pardo': 1344,
    'Enrique Villanueva': 1344,
    'Esperanza': 1344,
    'Estaca': 1344,
    'Gabi': 1344,
    'Garcia Hernandez': 1344,
    'Giawang': 1344,
    'Ginatilan': 1344,
    'Guadalupe': 1344,
    'Guba': 1344,
    'Guibodangan': 1344,
    'Guihulñgan': 1344,
    'Guindarohan': 1344,
    'Guindulman': 1344,
    'Guiwanon': 1344,
    'Hagdan': 1344,
    'Hagnaya': 1344,
    'Hibaiyo': 1344,
    'Hilantagaan': 1344,
    'Hilotongan': 1344,
    'Himensulan': 1344,
    'Hinlayagan Ilaud': 1344,
    'Ilihan': 1344,
    'Inabanga': 1344,
    'Inayagan': 1344,
    'Jaclupan': 1344,
    'Jagna': 1344,
    'Jampang': 1344,
    'Jandayan Norte': 1344,
    'Jantianon': 1344,
    'Japitan': 1344,
    'Jetafe': 1344,
    'Jimalalud': 1344,
    'Jugno': 1344,
    'Kabac': 1344,
    'Kabungahan': 1344,
    'Kandabong': 1344,
    'Kaongkod': 1344,
    'Kauit': 1344,
    'Kotkot': 1344,
    'Kuanos': 1344,
    'La Hacienda': 1344,
    'La Libertad': 1344,
    'Lanao': 1344,
    'Lanas': 1344,
    'Langob': 1344,
    'Langtad': 1344,
    'Lapaz': 1344,
    'Lapu-Lapu City': 1344,
    'Lazi': 1344,
    'Lepanto': 1344,
    'Libertad': 1344,
    'Lila': 1344,
    'Liloan': 1344,
    'Lipayran': 1344,
    'Loay': 1344,
    'Loboc': 1344,
    'Logon': 1344,
    'Lombog': 1344,
    'Loon': 1344,
    'Lugo': 1344,
    'Lunas': 1344,
    'Lut-od': 1344,
    'Maayong Tubig': 1344,
    'Mabinay': 1344,
    'Mabini': 1344,
    'Macaas': 1344,
    'Madridejos': 1344,
    'Magay': 1344,
    'Mahayag': 1344,
    'Mainit': 1344,
    'Malabugas': 1344,
    'Malabuyoc': 1344,
    'Malaiba': 1344,
    'Malbug': 1344,
    'Malhiao': 1344,
    'Malingin': 1344,
    'Maloh': 1344,
    'Malusay': 1344,
    'Malway': 1344,
    'Manalongon': 1344,
    'Mancilang': 1344,
    'Mandaue City': 1344,
    'Maninihon': 1344,
    'Maño': 1344,
    'Mantalongon': 1344,
    'Mantiquil': 1344,
    'Maravilla': 1344,
    'Maribojoc': 1344,
    'Maricaban': 1344,
    'Masaba': 1344,
    'Maya': 1344,
    'Mayabon': 1344,
    'Mayana': 1344,
    'Mayapusi': 1344,
    'McKinley': 1344,
    'Medellin': 1344,
    'Mercedes': 1344,
    'Minglanilla': 1344,
    'Minolos': 1344,
    'Moalboal': 1344,
    'Montaneza': 1344,
    'Naga': 1344,
    'Nagbalaye': 1344,
    'Nahawan': 1344,
    'Nailong': 1344,
    'Nalundan': 1344,
    'Nangka': 1344,
    'Novallas': 1344,
    'Nueva Fuerza': 1344,
    'Nueva Vida Sur': 1344,
    'Nugas': 1344,
    'Obong': 1344,
    'Ocaña': 1344,
    'Ocoy': 1344,
    'Okiot': 1344,
    'Oslob': 1344,
    'Owak': 1344,
    'Padre Zamora': 1344,
    'Pajo': 1344,
    'Pamplona': 1344,
    'Panalipan': 1344,
    'Panaytayon': 1344,
    'Pangdan': 1344,
    'Panglao': 1344,
    'Panognawan': 1344,
    'Patao': 1344,
    'Payabon': 1344,
    'Paypay': 1344,
    'Perrelos': 1344,
    'Pilar': 1344,
    'Pinamungahan': 1344,
    'Pinayagan Norte': 1344,
    'Pinokawan': 1344,
    'Pitogo': 1344,
    'Polo': 1344,
    'Poro': 1344,
    'Province of Cebu': 1344,
    'Province of Negros Oriental': 1344,
    'Province of Siquijor': 1344,
    'Putat': 1344,
    'Ronda': 1344,
    'Saavedra': 1344,
    'Sagbayan': 1344,
    'Samboan': 1344,
    'San Agustin': 1344,
    'San Fernando': 1344,
    'San Francisco': 1344,
    'San Isidro': 1344,
    'San Jose': 1344,
    'San Miguel': 1344,
    'San Pascual': 1344,
    'San Remigio': 1344,
    'San Roque': 1344,
    'Sandayong Sur': 1344,
    'Sandolot': 1344,
    'Sangat': 1344,
    'Santa Catalina': 1344,
    'Santa Cruz': 1344,
    'Santa Fe': 1344,
    'Santa Filomena': 1344,
    'Santa Nino': 1344,
    'Santander Poblacion': 1344,
    'Santiago': 1344,
    'Sevilla': 1344,
    'Siaton': 1344,
    'Sibonga': 1344,
    'Sibulan': 1344,
    'Sierra Bullones': 1344,
    'Sikatuna': 1344,
    'Silab': 1344,
    'Sillon': 1344,
    'Simala': 1344,
    'Siquijor': 1344,
    'Sogod': 1344,
    'Songculan': 1344,
    'Sulangan': 1344,
    'Tabalong': 1344,
    'Tabogon': 1344,
    'Tabon': 1344,
    'Tabonok': 1344,
    'Tabuan': 1344,
    'Tabuelan': 1344,
    'Tabunok': 1344,
    'Tagbilaran City': 1344,
    'Tagum Norte': 1344,
    'Tajao': 1344,
    'Talangnan': 1344,
    'Talibon': 1344,
    'Talisay': 1344,
    'Tambalan': 1344,
    'Tambo': 1344,
    'Tambongon': 1344,
    'Tamiso': 1344,
    'Tampocon': 1344,
    'Tandayag': 1344,
    'Tangke': 1344,
    'Tangnan': 1344,
    'Tanjay': 1344,
    'Tapilon': 1344,
    'Tapon': 1344,
    'Tawala': 1344,
    'Tayasan': 1344,
    'Taytayan': 1344,
    'Tayud': 1344,
    'Tibigan': 1344,
    'Tiguib': 1344,
    'Tinaan': 1344,
    'Tinaogan': 1344,
    'Tindog': 1344,
    'Tinubuan': 1344,
    'Tipolo': 1344,
    'Toledo': 1344,
    'Tominhao': 1344,
    'Totolan': 1344,
    'Trinidad': 1344,
    'Tubigagmanoc': 1344,
    'Tubod': 1344,
    'Tubod-dugoan': 1344,
    'Tuburan': 1344,
    'Tudela': 1344,
    'Tutay': 1344,
    'Ubay': 1344,
    'Uling': 1344,
    'Union': 1344,
    'Valencia': 1344,
    'Valle Hermoso': 1344,
    'Vallehermoso': 1344,
    'Zamboanguita': 1344,
    'Abuyog': 1352,
    'Alangalang': 1352,
    'Albuera': 1352,
    'Alegria': 1352,
    'Allen': 1352,
    'Almagro': 1352,
    'Almeria': 1352,
    'Alugan': 1352,
    'Anahawan': 1352,
    'Anito': 1352,
    'Arteche': 1352,
    'Babatngon': 1352,
    'Balagtas': 1352,
    'Balagui': 1352,
    'Balangiga': 1352,
    'Balangkayan': 1352,
    'Balinsacayao': 1352,
    'Balocawehay': 1352,
    'Balogo': 1352,
    'Balud': 1352,
    'Bantayan': 1352,
    'Bantiqui': 1352,
    'Baras': 1352,
    'Barugo': 1352,
    'Basey': 1352,
    'Basud': 1352,
    'Bato': 1352,
    'Baybay': 1352,
    'Biliran': 1352,
    'Bilwang': 1352,
    'Biri': 1352,
    'Bislig': 1352,
    'Bitanjuan': 1352,
    'Bobon': 1352,
    'Bontoc': 1352,
    'Borongan': 1352,
    'Bugho': 1352,
    'Bugko': 1352,
    'Bunga': 1352,
    'Burauen': 1352,
    'Butazon': 1352,
    'Cabacuñgan': 1352,
    'Cabatuan': 1352,
    'Cabay': 1352,
    'Cabodiongan': 1352,
    'Cabucgayan': 1352,
    'Cagamotan': 1352,
    'Caibiran': 1352,
    'Calape': 1352,
    'Calbayog City': 1352,
    'Calbiga': 1352,
    'Calubian': 1352,
    'Can-Avid': 1352,
    'Canhandugan': 1352,
    'Capoocan': 1352,
    'Capul': 1352,
    'Caraycaray': 1352,
    'Caridad': 1352,
    'Carigara': 1352,
    'Catarman': 1352,
    'Catbalogan': 1352,
    'Catmon': 1352,
    'Catubig': 1352,
    'Cervantes': 1352,
    'Concepcion': 1352,
    'Consuegra': 1352,
    'Culaba': 1352,
    'Culasian': 1352,
    'Dagami': 1352,
    'Dao': 1352,
    'Dapdap': 1352,
    'Daram': 1352,
    'Dolores': 1352,
    'Doos': 1352,
    'Dulag': 1352,
    'Erenas': 1352,
    'Gabas': 1352,
    'Gamay': 1352,
    'Gandara': 1352,
    'General MacArthur': 1352,
    'Ginabuyan': 1352,
    'Giporlos': 1352,
    'Guindapunan': 1352,
    'Guirang': 1352,
    'Guiuan': 1352,
    'Hernani': 1352,
    'Hilongos': 1352,
    'Hindang': 1352,
    'Hingatungan': 1352,
    'Hinunangan': 1352,
    'Hinundayan': 1352,
    'Hipadpad': 1352,
    'Hipasngo': 1352,
    'Ibarra': 1352,
    'Ichon': 1352,
    'Inangatan': 1352,
    'Ipil': 1352,
    'Isabel': 1352,
    'Jaro': 1352,
    'Jiabong': 1352,
    'Jubasan': 1352,
    'Julita': 1352,
    'Kabuynan': 1352,
    'Kampokpok': 1352,
    'Kananya': 1352,
    'Kilim': 1352,
    'La Paz': 1352,
    'Lalauigan': 1352,
    'Lamak': 1352,
    'Lao': 1352,
    'Laoang': 1352,
    'Lapinig': 1352,
    'Las Navas': 1352,
    'Lavezares': 1352,
    'Leyte': 1352,
    'Libagon': 1352,
    'Libas': 1352,
    'Libertad': 1352,
    'Liberty': 1352,
    'Liloan': 1352,
    'Lim-oo': 1352,
    'Limon': 1352,
    'Llorente': 1352,
    'Looc': 1352,
    'Lope de Vega': 1352,
    'Maasin': 1352,
    'Mabini': 1352,
    'MacArthur': 1352,
    'Macrohon': 1352,
    'Mahaplag': 1352,
    'Makiwalo': 1352,
    'Malaga': 1352,
    'Malajog': 1352,
    'Malilinao': 1352,
    'Malitbog': 1352,
    'Mantang': 1352,
    'Mapanas': 1352,
    'Margen': 1352,
    'Maripipi': 1352,
    'Masarayao': 1352,
    'Maslog': 1352,
    'Matalom': 1352,
    'Matlang': 1352,
    'Maydolong': 1352,
    'Mayorga': 1352,
    'Maypangdan': 1352,
    'Merida': 1352,
    'Mondragon': 1352,
    'Motiong': 1352,
    'Naghalin': 1352,
    'Napuro': 1352,
    'Naval': 1352,
    'Nena': 1352,
    'Nenita': 1352,
    'Oras': 1352,
    'Ormoc': 1352,
    'Padre Burgos': 1352,
    'Pagsanghan': 1352,
    'Palanit': 1352,
    'Palapag': 1352,
    'Palaroo': 1352,
    'Palhi': 1352,
    'Palo': 1352,
    'Palompon': 1352,
    'Pambujan': 1352,
    'Panalanoy': 1352,
    'Pangdan': 1352,
    'Pangpang': 1352,
    'Pastrana': 1352,
    'Patong': 1352,
    'Pawing': 1352,
    'Piña': 1352,
    'Pinamopoan': 1352,
    'Pintuyan': 1352,
    'Plaridel': 1352,
    'Polahongon': 1352,
    'Polañge': 1352,
    'Province of Eastern Samar': 1352,
    'Province of Leyte': 1352,
    'Province of Northern Samar': 1352,
    'Province of Samar': 1352,
    'Province of Southern Leyte': 1352,
    'Puerto Bello': 1352,
    'Quinapundan': 1352,
    'Rizal': 1352,
    'Rosario': 1352,
    'Sabang': 1352,
    'Saint Bernard': 1352,
    'Salcedo': 1352,
    'Salvacion': 1352,
    'San Antonio': 1352,
    'San Eduardo': 1352,
    'San Francisco': 1352,
    'San Isidro': 1352,
    'San Joaquin': 1352,
    'San Jorge': 1352,
    'San Jose': 1352,
    'San Jose de Buan': 1352,
    'San Juan': 1352,
    'San Julian': 1352,
    'San Miguel': 1352,
    'San Pedro': 1352,
    'San Policarpio': 1352,
    'San Policarpo': 1352,
    'San Ricardo': 1352,
    'San Roque': 1352,
    'San Sebastian': 1352,
    'San Vicente': 1352,
    'Santa Fe': 1352,
    'Santa Margarita': 1352,
    'Santa Paz': 1352,
    'Santa Rita': 1352,
    'Santo Niño': 1352,
    'Siguinon': 1352,
    'Silago': 1352,
    'Silanga': 1352,
    'Silvino Lobos': 1352,
    'Sogod': 1352,
    'Sulangan': 1352,
    'Sulat': 1352,
    'Tabango': 1352,
    'Tabing': 1352,
    'Tabonoc': 1352,
    'Tabontabon': 1352,
    'Tacloban': 1352,
    'Taft': 1352,
    'Tagapul-an': 1352,
    'Tagbubungang Diot': 1352,
    'Talalora': 1352,
    'Talisayan': 1352,
    'Tanauan': 1352,
    'Tarangnan': 1352,
    'Tinambacan': 1352,
    'Tolosa': 1352,
    'Tomas Oppus': 1352,
    'Tucdao': 1352,
    'Tugbong': 1352,
    'Tunga': 1352,
    'Tutubigan': 1352,
    'Umaganhan': 1352,
    'Valencia': 1352,
    'Victoria': 1352,
    'Viga': 1352,
    'Villaba': 1352,
    'Villareal': 1352,
    'Viriato': 1352,
    'Wright': 1352,
    'Zumarraga': 1352,
    'Adtugan': 1363,
    'Aglayan': 1363,
    'Agusan': 1363,
    'Alae': 1363,
    'Alanib': 1363,
    'Alubijid': 1363,
    'Anakan': 1363,
    'Ani-e': 1363,
    'Aplaya': 1363,
    'Aumbay': 1363,
    'Bacolod': 1363,
    'Bagakay': 1363,
    'Baikingon': 1363,
    'Balila': 1363,
    'Balili': 1363,
    'Balingasag': 1363,
    'Balingoan': 1363,
    'Baliwagan': 1363,
    'Bangahan': 1363,
    'Bantuanon': 1363,
    'Baroy': 1363,
    'Barra': 1363,
    'Basak': 1363,
    'Bato': 1363,
    'Biga': 1363,
    'Binitinan': 1363,
    'Binuangan': 1363,
    'Bolo Bolo': 1363,
    'Bonbon': 1363,
    'Bonifacio': 1363,
    'Boroon': 1363,
    'Bugcaon': 1363,
    'Bugo': 1363,
    'Busdi': 1363,
    'Cabangahan': 1363,
    'Cabanglasan': 1363,
    'Cagayan de Oro': 1363,
    'Calabugao': 1363,
    'Calamba': 1363,
    'Canayan': 1363,
    'Candiis': 1363,
    'Caromatan': 1363,
    'Casisang': 1363,
    'Catarman': 1363,
    'Clarin': 1363,
    'Claveria': 1363,
    'Consuelo': 1363,
    'Cosina': 1363,
    'Dagumba-an': 1363,
    'Dalipuga': 1363,
    'Dalirig': 1363,
    'Dalorong': 1363,
    'Dalwangan': 1363,
    'Damilag': 1363,
    'Damulog': 1363,
    'Dancagan': 1363,
    'Dimaluna': 1363,
    'Dimayon': 1363,
    'Dologon': 1363,
    'Don Carlos': 1363,
    'Dorsalanam': 1363,
    'Dumalaguing': 1363,
    'El Salvador': 1363,
    'Esperanza': 1363,
    'Gimampang': 1363,
    'Gitagum': 1363,
    'Guinisiliban': 1363,
    'Halapitan': 1363,
    'Hinapalanan': 1363,
    'Igpit': 1363,
    'Imbatug': 1363,
    'Imelda': 1363,
    'Impalutao': 1363,
    'Indulang': 1363,
    'Initao': 1363,
    'Inobulan': 1363,
    'Jasaan': 1363,
    'Jimenez': 1363,
    'Kabalantian': 1363,
    'Kabulohan': 1363,
    'Kadingilan': 1363,
    'Kalanganan': 1363,
    'Kalilangan': 1363,
    'Kalugmanan': 1363,
    'Kapatagan': 1363,
    'Kauswagan': 1363,
    'Kawit': 1363,
    'Kibangay': 1363,
    'Kibawe': 1363,
    'Kibonsod': 1363,
    'Kibureau': 1363,
    'Kimanuit': 1363,
    'Kimaya': 1363,
    'Kisolon': 1363,
    'Kitaotao': 1363,
    'Kitobo': 1363,
    'Kolambugan': 1363,
    'La Fortuna': 1363,
    'La Roxas': 1363,
    'Lagindingan': 1363,
    'Lagonglong': 1363,
    'Laguitas': 1363,
    'Langcangan': 1363,
    'Lanipao': 1363,
    'Lantapan': 1363,
    'Lapase': 1363,
    'Lapining': 1363,
    'Libertad': 1363,
    'Libona': 1363,
    'Liboran': 1363,
    'Limbaan': 1363,
    'Linabo': 1363,
    'Linamon': 1363,
    'Lingating': 1363,
    'Lingion': 1363,
    'Little Baguio': 1363,
    'Looc': 1363,
    'Lopez Jaena': 1363,
    'Lourdes': 1363,
    'Lugait': 1363,
    'Lumbayao': 1363,
    'Lumbia': 1363,
    'Lunao': 1363,
    'Lurugan': 1363,
    'Maanas': 1363,
    'Maglamin': 1363,
    'Magsaysay': 1363,
    'Mahinog': 1363,
    'Maigo': 1363,
    'Mailag': 1363,
    'Malaybalay': 1363,
    'Malinaw': 1363,
    'Malitbog': 1363,
    'Maluko': 1363,
    'Mambajao': 1363,
    'Mambatangan': 1363,
    'Mambayaan': 1363,
    'Mamungan': 1363,
    'Managok': 1363,
    'Mananum': 1363,
    'Mandangoa': 1363,
    'Manolo Fortich': 1363,
    'Mantampay': 1363,
    'Manticao': 1363,
    'Maputi': 1363,
    'Maramag': 1363,
    'Maranding': 1363,
    'Maria Cristina': 1363,
    'Mariano': 1363,
    'Mat-i': 1363,
    'Matangad': 1363,
    'Matungao': 1363,
    'Medina': 1363,
    'Miaray': 1363,
    'Minlagas': 1363,
    'Molugan': 1363,
    'Moog': 1363,
    'Munai': 1363,
    'Naawan': 1363,
    'Nañgka': 1363,
    'Napalitan': 1363,
    'Natalungan': 1363,
    'NIA Valencia': 1363,
    'Odiongan': 1363,
    'Opol': 1363,
    'Oroquieta': 1363,
    'Ozamiz City': 1363,
    'Pan-an': 1363,
    'Panalo-on': 1363,
    'Pandan': 1363,
    'Pangabuan': 1363,
    'Pantao-Ragat': 1363,
    'Pantar': 1363,
    'Patrocinio': 1363,
    'Pines': 1363,
    'Plaridel': 1363,
    'Pongol': 1363,
    'Pontian': 1363,
    'Province of Bukidnon': 1363,
    'Province of Camiguin': 1363,
    'Province of Lanao del Norte': 1363,
    'Province of Misamis Occidental': 1363,
    'Province of Misamis Oriental': 1363,
    'Pualas': 1363,
    'Punta Silum': 1363,
    'Quezon': 1363,
    'Rebe': 1363,
    'Sagay': 1363,
    'Salawagan': 1363,
    'Salay': 1363,
    'Salimbalan': 1363,
    'Sampagar': 1363,
    'San Carlos': 1363,
    'San Isidro': 1363,
    'San Jose': 1363,
    'San Luis': 1363,
    'San Martin': 1363,
    'San Miguel': 1363,
    'San Vicente': 1363,
    'Sankanan': 1363,
    'Santa Ana': 1363,
    'Santa Fe': 1363,
    'Sapang Dalaga': 1363,
    'Silae': 1363,
    'Sinacaban': 1363,
    'Sinonoc': 1363,
    'Solana': 1363,
    'Sugbongkogon': 1363,
    'Sumilao': 1363,
    'Sumpong': 1363,
    'Sungai': 1363,
    'Tabid': 1363,
    'Taboc': 1363,
    'Tacub': 1363,
    'Tagoloan': 1363,
    'Talakag': 1363,
    'Talisayan': 1363,
    'Tangub': 1363,
    'Taypano': 1363,
    'Taytay': 1363,
    'Ticala-an': 1363,
    'Tignapalan': 1363,
    'Tubao': 1363,
    'Tubigan': 1363,
    'Tubod': 1363,
    'Tudela': 1363,
    'Tuod': 1363,
    'Tupsan': 1363,
    'Unidos': 1363,
    'Villanueva': 1363,
    'Yumbing': 1363,
    'Andili': 1272,
    'Andop': 1272,
    'Anibongan': 1272,
    'Astorga': 1272,
    'Asuncion': 1272,
    'Babag': 1272,
    'Baculin': 1272,
    'Baganga': 1272,
    'Balagunan': 1272,
    'Balangonan': 1272,
    'Balutakay': 1272,
    'Banaybanay': 1272,
    'Bansalan': 1272,
    'Bantacan': 1272,
    'Baon': 1272,
    'Baracatan': 1272,
    'Basiawan': 1272,
    'Batiano': 1272,
    'Bato': 1272,
    'Batobato': 1272,
    'Baylo': 1272,
    'Biao': 1272,
    'Bincoñgan': 1272,
    'Bitaogan': 1272,
    'Bobon': 1272,
    'Bolila': 1272,
    'Boston': 1272,
    'Buclad': 1272,
    'Buhangin': 1272,
    'Bukid': 1272,
    'Bulacan': 1272,
    'Bungabon': 1272,
    'Butulan': 1272,
    'Cabayangan': 1272,
    'Cabinuangan': 1272,
    'Caburan': 1272,
    'Cambanugoy': 1272,
    'Camudmud': 1272,
    'Caraga': 1272,
    'Carmen': 1272,
    'Cateel': 1272,
    'City of Mati': 1272,
    'City of Panabo': 1272,
    'City of Tagum': 1272,
    'Cogon': 1272,
    'Concepcion': 1272,
    'Corocotan': 1272,
    'Coronon': 1272,
    'Cuambog': 1272,
    'Culaman': 1272,
    'Dacudao': 1272,
    'Dakbayan sa Digos': 1272,
    'Davan': 1272,
    'Davao City': 1272,
    'Del Pilar': 1272,
    'Digos': 1272,
    'Dolo': 1272,
    'Don Marcelino': 1272,
    'Dumlan': 1272,
    'Esperanza': 1272,
    'Gabi': 1272,
    'Gabuyan': 1272,
    'Goma': 1272,
    'Governor Generoso': 1272,
    'Guihing Proper': 1272,
    'Gumalang': 1272,
    'Gupitan': 1272,
    'Hagonoy': 1272,
    'Ignit': 1272,
    'Ilangay': 1272,
    'Inawayan': 1272,
    'Island Garden City of Samal': 1272,
    'Jose Abad Santos': 1272,
    'Jovellar': 1272,
    'Kalbay': 1272,
    'Kalian': 1272,
    'Kaligutan': 1272,
    'Kapalong': 1272,
    'Katipunan': 1272,
    'Kiblawan': 1272,
    'Kinablangan': 1272,
    'Kinamayan': 1272,
    'Kinangan': 1272,
    'La Libertad': 1272,
    'La Paz': 1272,
    'La Union': 1272,
    'Lacaron': 1272,
    'Lacson': 1272,
    'Lais': 1272,
    'Lamitan': 1272,
    'Lapuan': 1272,
    'Lasang': 1272,
    'Libuganon': 1272,
    'Limao': 1272,
    'Limot': 1272,
    'Linao': 1272,
    'Linoan': 1272,
    'Lukatan': 1272,
    'Luna': 1272,
    'Lungaog': 1272,
    'Lupon': 1272,
    'Luzon': 1272,
    'Mabini': 1272,
    'Mabuhay': 1272,
    'Maco': 1272,
    'Maduao': 1272,
    'Magatos': 1272,
    'Magdug': 1272,
    'Magnaga': 1272,
    'Magsaysay': 1272,
    'Mahanob': 1272,
    'Mahayag': 1272,
    'Malagos': 1272,
    'Malalag': 1272,
    'Malinao': 1272,
    'Malita': 1272,
    'Mambago': 1272,
    'Managa': 1272,
    'Manaloal': 1272,
    'Manat': 1272,
    'Manay': 1272,
    'Mangili': 1272,
    'Manikling': 1272,
    'Matanao': 1272,
    'Mati': 1272,
    'Matiao': 1272,
    'Matti': 1272,
    'Mawab': 1272,
    'Mayo': 1272,
    'Monkayo': 1272,
    'Montevista': 1272,
    'Nabunturan': 1272,
    'Nangan': 1272,
    'Nanyo': 1272,
    'New Baclayon': 1272,
    'New Bataan': 1272,
    'New Bohol': 1272,
    'New Corella': 1272,
    'New Leyte': 1272,
    'New Sibonga': 1272,
    'New Visayas': 1272,
    'Nuing': 1272,
    'Padada': 1272,
    'Pag-asa': 1272,
    'Pagsabangan': 1272,
    'Palma Gil': 1272,
    'Panabo': 1272,
    'Pandasan': 1272,
    'Pangian': 1272,
    'Panikian': 1272,
    'Pantukan': 1272,
    'Pasian': 1272,
    'Pondaguitan': 1272,
    'Pung-Pang': 1272,
    'Samal': 1272,
    'Sampao': 1272,
    'San Alfonso': 1272,
    'San Antonio': 1272,
    'San Ignacio': 1272,
    'San Isidro': 1272,
    'San Luis': 1272,
    'San Mariano': 1272,
    'San Miguel': 1272,
    'San Pedro': 1272,
    'San Rafael': 1272,
    'San Remigio': 1272,
    'San Vicente': 1272,
    'Santa Cruz': 1272,
    'Santa Maria': 1272,
    'Santiago': 1272,
    'Santo Niño': 1272,
    'Santo Tomas': 1272,
    'Sarangani': 1272,
    'Sibulan': 1272,
    'Sigaboy': 1272,
    'Simod': 1272,
    'Sinawilan': 1272,
    'Sinayawan': 1272,
    'Sirib': 1272,
    'Sugal': 1272,
    'Sulop': 1272,
    'Surup': 1272,
    'Suz-on': 1272,
    'Tagakpan': 1272,
    'Tagdanua': 1272,
    'Tagnanan': 1272,
    'Tagum': 1272,
    'Takub': 1272,
    'Talagutong': 1272,
    'Talisay': 1272,
    'Talomo': 1272,
    'Tamayong': 1272,
    'Tambo': 1272,
    'Tamisan': 1272,
    'Tamugan': 1272,
    'Tanlad': 1272,
    'Tapia': 1272,
    'Tarragona': 1272,
    'Tawan tawan': 1272,
    'Taytayan': 1272,
    'Tibagon': 1272,
    'Tibanbang': 1272,
    'Tiblawan': 1272,
    'Tombongon': 1272,
    'Tubalan': 1272,
    'Tuban': 1272,
    'Tubod': 1272,
    'Tuganay': 1272,
    'Tuli': 1272,
    'Ula': 1272,
    'Wañgan': 1272,
    'Wines': 1272,
    'Alamada': 1270,
    'Amas': 1270,
    'Bagontapay': 1270,
    'Baguer': 1270,
    'Bagumbayan': 1270,
    'Baliton': 1270,
    'Balogo': 1270,
    'Banawa': 1270,
    'Banga': 1270,
    'Banisilan': 1270,
    'Bantogon': 1270,
    'Barongis': 1270,
    'Basak': 1270,
    'Batasan': 1270,
    'Batutitik': 1270,
    'Bau': 1270,
    'Bayasong': 1270,
    'Bialong': 1270,
    'Biwang': 1270,
    'Blingkong': 1270,
    'Buadtasan': 1270,
    'Bual': 1270,
    'Buayan': 1270,
    'Bucay Pait': 1270,
    'Bulatukan': 1270,
    'Burias': 1270,
    'Busok': 1270,
    'Carmen': 1270,
    'Carpenter': 1270,
    'Cebuano': 1270,
    'City of Kidapawan': 1270,
    'City of Koronadal': 1270,
    'City of Tacurong': 1270,
    'Colongolo': 1270,
    'Columbio': 1270,
    'Cotabato City': 1270,
    'Dahay': 1270,
    'Daliao': 1270,
    'Damawato': 1270,
    'Dansuli': 1270,
    'Datu Montawal': 1270,
    'Dualing': 1270,
    'Dukay': 1270,
    'Dunguan': 1270,
    'Esperanza': 1270,
    'Gansing': 1270,
    'General Santos': 1270,
    'Glad': 1270,
    'Glamang': 1270,
    'Glan': 1270,
    'Glan Peidu': 1270,
    'Gocoton': 1270,
    'Guinsang-an': 1270,
    'Ilaya': 1270,
    'Isulan': 1270,
    'Kabacan': 1270,
    'Kabalen': 1270,
    'Kablalan': 1270,
    'Kalaisan': 1270,
    'Kalamangog': 1270,
    'Kalamansig': 1270,
    'Kamanga': 1270,
    'Kapatan': 1270,
    'Kapaya': 1270,
    'Kapinkong': 1270,
    'Katubao': 1270,
    'Kiamba': 1270,
    'Kidapawan': 1270,
    'Kipalbig': 1270,
    'Kisante': 1270,
    'Kiupo': 1270,
    'Klinan': 1270,
    'Kling': 1270,
    'Koronadal': 1270,
    'Kudanding': 1270,
    'Kulaman': 1270,
    'Labu-o': 1270,
    'Laguilayan': 1270,
    'Lambayong': 1270,
    'Lambo': 1270,
    'Lambontong': 1270,
    'Lamian': 1270,
    'Lampari': 1270,
    'Lampitak': 1270,
    'Lapuz': 1270,
    'Lebak': 1270,
    'Libas': 1270,
    'Liberty': 1270,
    'Libungan': 1270,
    'Liliongan': 1270,
    'Limbalod': 1270,
    'Limulan': 1270,
    'Linan (town)': 1270,
    'Linao': 1270,
    'Lopez Jaena': 1270,
    'Lumatil': 1270,
    'Lumazal': 1270,
    'Lumuyon': 1270,
    'Lun Pequeño': 1270,
    'Lunen': 1270,
    'Lutayan': 1270,
    'M  lang': 1270,
    'Maan': 1270,
    'Maasim': 1270,
    'Mabay': 1270,
    'Mabini': 1270,
    'Magpet': 1270,
    'Maguling': 1270,
    'Maibu': 1270,
    'Maindang': 1270,
    'Maitum': 1270,
    'Makilala': 1270,
    'Malamote': 1270,
    'Malandag': 1270,
    'Malapag': 1270,
    'Malapatan': 1270,
    'Malasila': 1270,
    'Malbang': 1270,
    'Malingao': 1270,
    'Malisbeng': 1270,
    'Malitubog': 1270,
    'Maltana': 1270,
    'Malungon': 1270,
    'Mamali': 1270,
    'Manaulanan': 1270,
    'Manga': 1270,
    'Manuangan': 1270,
    'Manuel Roxas': 1270,
    'Marbel': 1270,
    'Mariano Marcos': 1270,
    'Matalam': 1270,
    'Matinao': 1270,
    'Midsayap': 1270,
    'Minapan': 1270,
    'Mindupok': 1270,
    'Nalus': 1270,
    'New Cebu': 1270,
    'New Iloilo': 1270,
    'New Lagao': 1270,
    'New Panay': 1270,
    'Noling': 1270,
    'Norala': 1270,
    'Nunguan': 1270,
    'Osias': 1270,
    'Paatan': 1270,
    'Pagalungan': 1270,
    'Pagangan': 1270,
    'Palian': 1270,
    'Palimbang': 1270,
    'Palkan': 1270,
    'Pamantingan': 1270,
    'Panay': 1270,
    'Pangyan': 1270,
    'Paraiso': 1270,
    'Patindeguen': 1270,
    'Pedtad': 1270,
    'Pigcawayan': 1270,
    'Pikit': 1270,
    'Pimbalayan': 1270,
    'Polo': 1270,
    'Polomolok': 1270,
    'Polonoling': 1270,
    'President Quirino': 1270,
    'President Roxas': 1270,
    'Puloypuloy': 1270,
    'Punolu': 1270,
    'Puricay': 1270,
    'Ragandang': 1270,
    'Rotunda': 1270,
    'Sadsalan': 1270,
    'Saguing': 1270,
    'Salimbao': 1270,
    'Salunayan': 1270,
    'Sampao': 1270,
    'San Miguel': 1270,
    'San Vicente': 1270,
    'Sañgay': 1270,
    'Santo Niño': 1270,
    'Sapad': 1270,
    'Sapu Padidu': 1270,
    'Sebu': 1270,
    'Silway 7': 1270,
    'Sinolon': 1270,
    'Sulit': 1270,
    'Surallah': 1270,
    'Suyan': 1270,
    'T  boli': 1270,
    'Tacurong': 1270,
    'Taguisa': 1270,
    'Taluya': 1270,
    'Tambak': 1270,
    'Tambilil': 1270,
    'Tamnag': 1270,
    'Tampakan': 1270,
    'Tañgo': 1270,
    'Tantangan': 1270,
    'Telafas': 1270,
    'Teresita': 1270,
    'Tinoto': 1270,
    'Tomado': 1270,
    'Tran': 1270,
    'Tuka': 1270,
    'Tulunan': 1270,
    'Tupi': 1270,
    'Tuyan': 1270,
    'Upper Klinan': 1270,
    'Upper San Mateo': 1270,
    'Villamor': 1270,
    'Agay': 1278,
    'Alegria': 1278,
    'Alubijid': 1278,
    'Amaga': 1278,
    'Anticala': 1278,
    'Aras-asan': 1278,
    'Aurora': 1278,
    'Bacolod': 1278,
    'Bacuag': 1278,
    'Bah-Bah': 1278,
    'Balangbalang': 1278,
    'Bancasi': 1278,
    'Bangonay': 1278,
    'Barcelona': 1278,
    'Barobo': 1278,
    'Basa': 1278,
    'Basilisa': 1278,
    'Bayabas': 1278,
    'Bayugan': 1278,
    'Bigaan': 1278,
    'Binucayan': 1278,
    'Bislig': 1278,
    'Borbon': 1278,
    'Buenavista': 1278,
    'Bunawan': 1278,
    'Burgos': 1278,
    'Butuan': 1278,
    'Cabadbaran': 1278,
    'Cagdianao': 1278,
    'Cagwait': 1278,
    'Calamba': 1278,
    'Caloc-an': 1278,
    'Cantapoy': 1278,
    'Cantilan': 1278,
    'Capalayan': 1278,
    'Carmen': 1278,
    'Carrascal': 1278,
    'Causwagan': 1278,
    'City of Cabadbaran': 1278,
    'Claver': 1278,
    'Comagascas': 1278,
    'Cortes': 1278,
    'Cuevas': 1278,
    'Culit': 1278,
    'Dakbayan sa Bislig': 1278,
    'Dapa': 1278,
    'Del Carmen': 1278,
    'Del Pilar': 1278,
    'Dinagat': 1278,
    'Esperanza': 1278,
    'Gamut': 1278,
    'General Luna': 1278,
    'Gigaquit': 1278,
    'Guadalupe': 1278,
    'Guinabsan': 1278,
    'Hinatuan': 1278,
    'Ipil': 1278,
    'Jabonga': 1278,
    'Jagupit': 1278,
    'Javier': 1278,
    'Kauswagan': 1278,
    'Kinabhangan': 1278,
    'Kitcharao': 1278,
    'La Paz': 1278,
    'La Union': 1278,
    'Lanuza': 1278,
    'Lapinigan': 1278,
    'Las Nieves': 1278,
    'Lianga': 1278,
    'Libas': 1278,
    'Libertad': 1278,
    'Libjo': 1278,
    'Lingig': 1278,
    'Loreto': 1278,
    'Los Angeles': 1278,
    'Los Arcos': 1278,
    'Loyola': 1278,
    'Luna': 1278,
    'Mabahin': 1278,
    'Mabua': 1278,
    'Madrid': 1278,
    'Magallanes': 1278,
    'Mainit': 1278,
    'Malimono': 1278,
    'Manapa': 1278,
    'Marihatag': 1278,
    'Matabao': 1278,
    'Mati': 1278,
    'Maygatasan': 1278,
    'Nasipit': 1278,
    'Panikian': 1278,
    'Parang': 1278,
    'Patin-ay': 1278,
    'Pilar': 1278,
    'Placer': 1278,
    'Prosperidad': 1278,
    'Punta': 1278,
    'Rizal': 1278,
    'Rosario': 1278,
    'Salvacion': 1278,
    'San Agustin': 1278,
    'San Benito': 1278,
    'San Francisco': 1278,
    'San Isidro': 1278,
    'San Luis': 1278,
    'San Miguel': 1278,
    'Sanghan': 1278,
    'Santa Ana': 1278,
    'Santa Josefa': 1278,
    'Santa Maria': 1278,
    'Santa Monica': 1278,
    'Santiago': 1278,
    'Santo Tomas': 1278,
    'Sibagat': 1278,
    'Sinubong': 1278,
    'Sison': 1278,
    'Socorro': 1278,
    'Surigao City': 1278,
    'Tagana-an': 1278,
    'Tagbina': 1278,
    'Tagcatong': 1278,
    'Tago': 1278,
    'Talacogon': 1278,
    'Taligaman': 1278,
    'Talisay': 1278,
    'Tandag': 1278,
    'Tidman': 1278,
    'Tigao': 1278,
    'Trento': 1278,
    'Tubajon': 1278,
    'Tubay': 1278,
    'Tubod': 1278,
    'Unidad': 1278,
    'Union': 1278,
    'Veruela': 1278,
    'Ampatuan': 1279,
    'Andalan': 1279,
    'Anuling': 1279,
    'Awang': 1279,
    'Bacayawan': 1279,
    'Bacolod Grande': 1279,
    'Bacolod-Kalawi': 1279,
    'Badak': 1279,
    'Bagan': 1279,
    'Baka': 1279,
    'Bakung': 1279,
    'Balabagan': 1279,
    'Balas': 1279,
    'Balindong': 1279,
    'Bangkal': 1279,
    'Banguingui': 1279,
    'Bankaw': 1279,
    'Barira': 1279,
    'Barurao': 1279,
    'Bato Bato': 1279,
    'Baunu-Timbangan': 1279,
    'Bawison': 1279,
    'Bayang': 1279,
    'Bayanga': 1279,
    'Begang': 1279,
    'Binidayan': 1279,
    'Binuang': 1279,
    'Blinsung': 1279,
    'Bongao': 1279,
    'Bongued': 1279,
    'Buadiposo-Buntong': 1279,
    'Bualan': 1279,
    'Buan': 1279,
    'Buansa': 1279,
    'Bubong': 1279,
    'Budta': 1279,
    'Bugasan': 1279,
    'Buldon': 1279,
    'Buliok': 1279,
    'Bulit': 1279,
    'Buluan': 1279,
    'Butig': 1279,
    'Cagayan de Tawi-Tawi': 1279,
    'Calanogas': 1279,
    'Colonia': 1279,
    'Dado': 1279,
    'Dadus': 1279,
    'Dalican': 1279,
    'Dalumangcob': 1279,
    'Damabalas': 1279,
    'Damatulan': 1279,
    'Datu Blah T. Sinsuat': 1279,
    'Datu Odin Sinsuat': 1279,
    'Datu Paglas': 1279,
    'Datu Piang': 1279,
    'Digal': 1279,
    'Dinaig': 1279,
    'Dinganen': 1279,
    'Ditsaan-Ramain': 1279,
    'Ebcor Town': 1279,
    'Gadung': 1279,
    'Ganassi': 1279,
    'Gang': 1279,
    'Guiong': 1279,
    'Idtig': 1279,
    'Indanan': 1279,
    'Isabela': 1279,
    'Jolo': 1279,
    'Kabasalan': 1279,
    'Kabuntalan': 1279,
    'Kagay': 1279,
    'Kajatian': 1279,
    'Kalang': 1279,
    'Kalbugan': 1279,
    'Kalingalan Caluang': 1279,
    'Kambing': 1279,
    'Kanlagay': 1279,
    'Kansipati': 1279,
    'Kapai': 1279,
    'Karungdong': 1279,
    'Katico': 1279,
    'Katidtuan': 1279,
    'Katuli': 1279,
    'Kauran': 1279,
    'Kitango': 1279,
    'Kitapak': 1279,
    'Kolape': 1279,
    'Kulase': 1279,
    'Kulay-Kulay': 1279,
    'Kulempang': 1279,
    'Kungtad': 1279,
    'Labuñgan': 1279,
    'Laminusa': 1279,
    'Lamitan': 1279,
    'Lamitan City': 1279,
    'Langpas': 1279,
    'Languyan': 1279,
    'Larap': 1279,
    'Latung': 1279,
    'Layog': 1279,
    'Ligayan': 1279,
    'Limbo': 1279,
    'Litayan': 1279,
    'Lookan': 1279,
    'Lugus': 1279,
    'Lumba-a-Bayabao': 1279,
    'Lumbac': 1279,
    'Lumbatan': 1279,
    'Lumbayanague': 1279,
    'Lutayan': 1279,
    'Luuk': 1279,
    'Luuk Datan': 1279,
    'Madalum': 1279,
    'Madamba': 1279,
    'Maguing': 1279,
    'Mahala': 1279,
    'Maimbung': 1279,
    'Makir': 1279,
    'Malabang': 1279,
    'Maluso': 1279,
    'Manubul': 1279,
    'Manuk Mangkaw': 1279,
    'Marantao': 1279,
    'Marawi': 1279,
    'Marogong': 1279,
    'Marsada': 1279,
    'Marunggas': 1279,
    'Masiu': 1279,
    'Matanog': 1279,
    'Mataya': 1279,
    'Mauboh': 1279,
    'Mileb': 1279,
    'Molundo': 1279,
    'Municipality of Indanan': 1279,
    'Municipality of Lantawan': 1279,
    'Municipality of Pangutaran': 1279,
    'Municipality of Sultan Gumander': 1279,
    'Municipality of Tongkil': 1279,
    'New Batu Batu': 1279,
    'New Panamao': 1279,
    'Nuyo': 1279,
    'Old Panamao': 1279,
    'Pagalungan': 1279,
    'Pagatin': 1279,
    'Pagayawan': 1279,
    'Paglat': 1279,
    'Paitan': 1279,
    'Panabuan': 1279,
    'Panadtaban': 1279,
    'Pandag': 1279,
    'Pandakan': 1279,
    'Pandami': 1279,
    'Pandan Niog': 1279,
    'Pang': 1279,
    'Panglima Sugala': 1279,
    'Pangutaran': 1279,
    'Parang': 1279,
    'Parangan': 1279,
    'Parian Dakula': 1279,
    'Pata': 1279,
    'Patikul': 1279,
    'Pawak': 1279,
    'Payuhan': 1279,
    'Piagapo': 1279,
    'Pidsandawan': 1279,
    'Pinaring': 1279,
    'Pitogo': 1279,
    'Poon-a-Bayabao': 1279,
    'Pualas': 1279,
    'Punay': 1279,
    'Rimpeso': 1279,
    'Rominimbang': 1279,
    'Sagasa': 1279,
    'Saguiaran': 1279,
    'Sambuluan': 1279,
    'Sanga-Sanga': 1279,
    'Santa Clara': 1279,
    'Sapa': 1279,
    'Sapa-Sapa': 1279,
    'Sapadun': 1279,
    'Satan': 1279,
    'Semut': 1279,
    'Shariff Aguak': 1279,
    'Siasi': 1279,
    'Simuay': 1279,
    'Simunul': 1279,
    'Sionogan': 1279,
    'Sitangkai': 1279,
    'South Ubian': 1279,
    'South Upi': 1279,
    'Sultan Kudarat': 1279,
    'Sultan sa Barongis': 1279,
    'Sultan Sumagka': 1279,
    'Sumisip': 1279,
    'Tabiauan': 1279,
    'Tablas': 1279,
    'Tairan Camp': 1279,
    'Talayan': 1279,
    'Talipao': 1279,
    'Talipaw': 1279,
    'Tampakan': 1279,
    'Tamparan': 1279,
    'Tandubas': 1279,
    'Tapayan': 1279,
    'Tapikan': 1279,
    'Tapul': 1279,
    'Taraka': 1279,
    'Taungoh': 1279,
    'Taviran': 1279,
    'Tinutulan': 1279,
    'Tipo-Tipo': 1279,
    'Tongouson': 1279,
    'Tubaran': 1279,
    'Tuburan': 1279,
    'Tugaya': 1279,
    'Tumbagaan': 1279,
    'Tunggol': 1279,
    'Tungol': 1279,
    'Turtle Islands': 1279,
    'Ungus-Ungus': 1279,
    'Upi': 1279,
    'Uyaan': 1279,
    'Wao': 1279,
    'Abulug': 1342,
    'Abut': 1342,
    'Accusilian': 1342,
    'Afusing Centro': 1342,
    'Aggugaddah': 1342,
    'Alabug': 1342,
    'Alannay': 1342,
    'Alcala': 1342,
    'Alibago': 1342,
    'Alicia': 1342,
    'Allacapan': 1342,
    'Almaguer North': 1342,
    'Amulung': 1342,
    'Angadanan': 1342,
    'Antagan Segunda': 1342,
    'Aparri': 1342,
    'Aritao': 1342,
    'Atulayan': 1342,
    'Aurora': 1342,
    'Awallan': 1342,
    'Bacnor East': 1342,
    'Baggabag B': 1342,
    'Bagong Tanza': 1342,
    'Bagu': 1342,
    'Bagumbayan': 1342,
    'Ballesteros': 1342,
    'Bambang': 1342,
    'Bangad': 1342,
    'Banganan': 1342,
    'Banquero': 1342,
    'Barucboc Norte': 1342,
    'Basco': 1342,
    'Batal': 1342,
    'Battung': 1342,
    'Bauan': 1342,
    'Bayombong': 1342,
    'Belance': 1342,
    'Benito Soliven': 1342,
    'Binalan': 1342,
    'Binguang': 1342,
    'Bintawan': 1342,
    'Bitag Grande': 1342,
    'Bone South': 1342,
    'Buguey': 1342,
    'Buliwao': 1342,
    'Bulu': 1342,
    'Burgos': 1342,
    'Busilak': 1342,
    'Cabagan': 1342,
    'Cabannungan Second': 1342,
    'Cabaritan East': 1342,
    'Cabarroguis': 1342,
    'Cabatuan': 1342,
    'Cabiraoan': 1342,
    'Cabulay': 1342,
    'Calamagui East': 1342,
    'Calantac': 1342,
    'Calaoagan': 1342,
    'Calinaoan Malasin': 1342,
    'Calog Norte': 1342,
    'Camalaniugan': 1342,
    'Capissayan Sur': 1342,
    'Carig': 1342,
    'Casambalangan': 1342,
    'Catayauan': 1342,
    'Claveria': 1342,
    'Cordon': 1342,
    'Cullalabo del Sur': 1342,
    'Dagupan': 1342,
    'Dalaoig': 1342,
    'Daragutan': 1342,
    'Dassun': 1342,
    'Diadi': 1342,
    'Diamantina': 1342,
    'Dibuluan': 1342,
    'Dicabisagan': 1342,
    'Dicamay': 1342,
    'Diffun': 1342,
    'Divisoria': 1342,
    'Dodan': 1342,
    'Dumabato': 1342,
    'Echague': 1342,
    'Eden': 1342,
    'Enrile': 1342,
    'Esperanza East': 1342,
    'Estefania': 1342,
    'Furao': 1342,
    'Gadu': 1342,
    'Gammad': 1342,
    'Gamu': 1342,
    'Ganapi': 1342,
    'Gappal': 1342,
    'Gattaran': 1342,
    'Gonzaga': 1342,
    'Guiddam': 1342,
    'Ibung': 1342,
    'Iguig': 1342,
    'Ilagan': 1342,
    'Ineangan': 1342,
    'Iraga': 1342,
    'Itbayat': 1342,
    'Ivana': 1342,
    'Jones': 1342,
    'Kasibu': 1342,
    'La Paz': 1342,
    'Lallayug': 1342,
    'Lanna': 1342,
    'Lapi': 1342,
    'Larion Alto': 1342,
    'Lasam': 1342,
    'Lucban': 1342,
    'Luna': 1342,
    'Mabasa': 1342,
    'Mabini': 1342,
    'Mabuttal East': 1342,
    'Maddarulug Norte': 1342,
    'Maddela': 1342,
    'Magalalag': 1342,
    'Magapit': 1342,
    'Magapit, Aguiguican': 1342,
    'Magdalena': 1342,
    'Magsaysay': 1342,
    'Maguilling': 1342,
    'Mahatao': 1342,
    'Malasin': 1342,
    'Mallig': 1342,
    'Maluno Sur': 1342,
    'Manaring': 1342,
    'Manga': 1342,
    'Masaya Sur': 1342,
    'Masipi West': 1342,
    'Maxingal': 1342,
    'Minallo': 1342,
    'Minanga Norte': 1342,
    'Minante Segundo': 1342,
    'Minuri': 1342,
    'Mozzozzin Sur': 1342,
    'Mungo': 1342,
    'Muñoz East': 1342,
    'Nabannagan West': 1342,
    'Nagrumbuan': 1342,
    'Nagtipunan': 1342,
    'Naguilian': 1342,
    'Namuac': 1342,
    'Nattapian': 1342,
    'Paddaya': 1342,
    'Palagao Norte': 1342,
    'Pamplona': 1342,
    'Pangal Sur': 1342,
    'Pata': 1342,
    'Pattao': 1342,
    'Peñablanca': 1342,
    'Piat': 1342,
    'Pilig': 1342,
    'Pinoma': 1342,
    'Province of Batanes': 1342,
    'Province of Cagayan': 1342,
    'Province of Isabela': 1342,
    'Province of Nueva Vizcaya': 1342,
    'Province of Quirino': 1342,
    'Quezon': 1342,
    'Quibal': 1342,
    'Quirino': 1342,
    'Ragan Norte': 1342,
    'Ramon': 1342,
    'Ramos West': 1342,
    'Reina Mercedes': 1342,
    'Rizal': 1342,
    'Roxas': 1342,
    'Sabtang': 1342,
    'Saguday': 1342,
    'Salinas': 1342,
    'Salinungan Proper': 1342,
    'San Antonio': 1342,
    'San Bernardo': 1342,
    'San Fernando': 1342,
    'San Guillermo': 1342,
    'San Jose': 1342,
    'San Juan': 1342,
    'San Luis': 1342,
    'San Manuel': 1342,
    'San Mariano': 1342,
    'San Mateo': 1342,
    'San Pedro': 1342,
    'San Vicente': 1342,
    'Sandiat Centro': 1342,
    'Santa Ana': 1342,
    'Santa Cruz': 1342,
    'Santa Fe': 1342,
    'Santa Maria': 1342,
    'Santa Praxedes': 1342,
    'Santa Teresita': 1342,
    'Santiago': 1342,
    'Santo Domingo': 1342,
    'Santo Niño': 1342,
    'Santo Tomas': 1342,
    'Siempre Viva': 1342,
    'Sillawit': 1342,
    'Simanu Sur': 1342,
    'Simimbaan': 1342,
    'Sinamar': 1342,
    'Sindon': 1342,
    'Solana': 1342,
    'Solano': 1342,
    'Soyung': 1342,
    'Taguing': 1342,
    'Tapel': 1342,
    'Tuao': 1342,
    'Tuguegarao': 1342,
    'Tumauini': 1342,
    'Tupang': 1342,
    'Uddiawan': 1342,
    'Ugac Sur': 1342,
    'Ugad': 1342,
    'Upi': 1342,
    'Uyugan': 1342,
    'Yeban Norte': 1342,
    'Ambuclao': 1283,
    'Amlimay': 1283,
    'Ampusungan': 1283,
    'Angad': 1283,
    'Atok': 1283,
    'Baculongan': 1283,
    'Baguinge': 1283,
    'Baguio': 1283,
    'Bakun': 1283,
    'Balbalan': 1283,
    'Banaue': 1283,
    'Bangao': 1283,
    'Bangued': 1283,
    'Bantay': 1283,
    'Barlig': 1283,
    'Bauko': 1283,
    'Bayabas': 1283,
    'Besao': 1283,
    'Betwagan': 1283,
    'Bocos': 1283,
    'Bokod': 1283,
    'Boliney': 1283,
    'Bontoc': 1283,
    'Bucay': 1283,
    'Bucloc': 1283,
    'Buguias': 1283,
    'Bulalacao': 1283,
    'Butigui': 1283,
    'Calaba': 1283,
    'Calanasan': 1283,
    'Conner': 1283,
    'Daguioman': 1283,
    'Dalipey': 1283,
    'Dalupirip': 1283,
    'Danglas': 1283,
    'Dolores': 1283,
    'Flora': 1283,
    'Gambang': 1283,
    'Guinsadan': 1283,
    'Hapao': 1283,
    'Hingyon': 1283,
    'Hungduan': 1283,
    'Itogon': 1283,
    'Kabayan': 1283,
    'Kabugao': 1283,
    'Kapangan': 1283,
    'Kiangan': 1283,
    'Kibungan': 1283,
    'La Paz': 1283,
    'La Trinidad': 1283,
    'Lacub': 1283,
    'Lagangilang': 1283,
    'Lagawe': 1283,
    'Lagayan': 1283,
    'Lamut': 1283,
    'Langiden': 1283,
    'Laya': 1283,
    'Licuan': 1283,
    'Licuan-Baay': 1283,
    'Liwan': 1283,
    'Loacan': 1283,
    'Luba': 1283,
    'Lubuagan': 1283,
    'Luna': 1283,
    'Malibcong': 1283,
    'Manabo': 1283,
    'Mankayan': 1283,
    'Mayoyao': 1283,
    'Monamon': 1283,
    'Nangalisan': 1283,
    'Natonin': 1283,
    'Natubleng': 1283,
    'Paracelis': 1283,
    'Pasil': 1283,
    'Peñarrubia': 1283,
    'Pidigan': 1283,
    'Pilar': 1283,
    'Pinukpuk': 1283,
    'Potia': 1283,
    'Pudtol': 1283,
    'Rizal': 1283,
    'Sabangan': 1283,
    'Sablan': 1283,
    'Sadanga': 1283,
    'Sadsadan': 1283,
    'Sagada': 1283,
    'Sal-Lapadan': 1283,
    'San Isidro': 1283,
    'San Juan': 1283,
    'San Quintin': 1283,
    'San Ramon': 1283,
    'Santa Marcela': 1283,
    'Tabaan': 1283,
    'Tabio': 1283,
    'Tabuk': 1283,
    'Tacadang': 1283,
    'Tadian': 1283,
    'Taloy': 1283,
    'Tanudan': 1283,
    'Tayum': 1283,
    'Tineg': 1283,
    'Tinglayan': 1283,
    'Tinoc': 1283,
    'Topdac': 1283,
    'Tuba': 1283,
    'Tublay': 1283,
    'Tubo': 1283,
    'Tuding': 1283,
    'Villarosa': 1283,
    'Villaviciosa': 1283,
    'Adlay': 1314,
    'Agay': 1314,
    'Alegria': 1314,
    'Alubijid': 1314,
    'Amaga': 1314,
    'Anticala': 1314,
    'Aras-asan': 1314,
    'Aurora': 1314,
    'Bacolod': 1314,
    'Bacuag': 1314,
    'Bah-Bah': 1314,
    'Balangbalang': 1314,
    'Bancasi': 1314,
    'Bangonay': 1314,
    'Barcelona': 1314,
    'Barobo': 1314,
    'Basa': 1314,
    'Basag': 1314,
    'Basilisa': 1314,
    'Bayabas': 1314,
    'Bayugan': 1314,
    'Bigaan': 1314,
    'Binucayan': 1314,
    'Bislig': 1314,
    'Borbon': 1314,
    'Buenavista': 1314,
    'Bunawan': 1314,
    'Burgos': 1314,
    'Butuan': 1314,
    'Cabadbaran': 1314,
    'Cagdianao': 1314,
    'Cagwait': 1314,
    'Calamba': 1314,
    'Caloc-an': 1314,
    'Cantapoy': 1314,
    'Cantilan': 1314,
    'Capalayan': 1314,
    'Carmen': 1314,
    'Carrascal': 1314,
    'Causwagan': 1314,
    'Claver': 1314,
    'Comagascas': 1314,
    'Cortes': 1314,
    'Cuevas': 1314,
    'Culit': 1314,
    'Dapa': 1314,
    'Del Carmen, Surigao del Norte': 1314,
    'Del Pilar': 1314,
    'Dinagat': 1314,
    'Dinagat Islands': 1314,
    'Esperanza': 1314,
    'Gamut': 1314,
    'General Luna': 1314,
    'Gigaquit': 1314,
    'Guadalupe': 1314,
    'Guinabsan': 1314,
    'Hinatuan': 1314,
    'Ipil': 1314,
    'Jabonga': 1314,
    'Jagupit': 1314,
    'Javier': 1314,
    'Kauswagan': 1314,
    'Kinabhangan': 1314,
    'Kitcharao': 1314,
    'La Paz': 1314,
    'La Union': 1314,
    'Lanuza': 1314,
    'Lapinigan': 1314,
    'Lianga': 1314,
    'Libas': 1314,
    'Libertad': 1314,
    'Libjo': 1314,
    'Lingig': 1314,
    'Lombocan': 1314,
    'Loreto': 1314,
    'Los Angeles': 1314,
    'Los Arcos': 1314,
    'Loyola': 1314,
    'Luna': 1314,
    'Mabahin': 1314,
    'Mabua': 1314,
    'Madrid': 1314,
    'Magallanes': 1314,
    'Mainit': 1314,
    'Malimono': 1314,
    'Manapa': 1314,
    'Marihatag': 1314,
    'Matabao': 1314,
    'Mati': 1314,
    'Maygatasan': 1314,
    'Nasipit': 1314,
    'Panikian': 1314,
    'Parang': 1314,
    'Patin-ay': 1314,
    'Pilar': 1314,
    'Placer': 1314,
    'Prosperidad': 1314,
    'Province of Agusan del Norte': 1314,
    'Province of Agusan del Sur': 1314,
    'Province of Surigao del Norte': 1314,
    'Province of Surigao del Sur': 1314,
    'Punta': 1314,
    'Rizal': 1314,
    'Salvacion': 1314,
    'San Benito': 1314,
    'San Francisco': 1314,
    'San Isidro': 1314,
    'San Luis': 1314,
    'San Miguel': 1314,
    'Sanghan': 1314,
    'Santa Ana': 1314,
    'Santa Josefa': 1314,
    'Santa Maria': 1314,
    'Santa Monica': 1314,
    'Santo Tomas': 1314,
    'Sibagat': 1314,
    'Sinubong': 1314,
    'Sison': 1314,
    'Socorro': 1314,
    'Surigao': 1314,
    'Tagana-an': 1314,
    'Tagbina': 1314,
    'Tagcatong': 1314,
    'Tago': 1314,
    'Talacogon': 1314,
    'Taligaman': 1314,
    'Talisay': 1314,
    'Tandag': 1314,
    'Tidman': 1314,
    'Tigao': 1314,
    'Trento': 1314,
    'Tubajon': 1314,
    'Tubay': 1314,
    'Tubod': 1314,
    'Tungao': 1314,
    'Unidad': 1314,
    'Union': 1314,
    'Veruela': 1314,
    'Abucay': 1345,
    'Acli': 1345,
    'Agbannawag': 1345,
    'Akle': 1345,
    'Aliaga': 1345,
    'Almendras': 1345,
    'Alua': 1345,
    'Amacalan': 1345,
    'Amucao': 1345,
    'Amuñgan': 1345,
    'Anao': 1345,
    'Angat': 1345,
    'Angeles City': 1345,
    'Antipolo': 1345,
    'Apalit': 1345,
    'Arayat': 1345,
    'Arenas': 1345,
    'Arminia': 1345,
    'Babo-Pangulo': 1345,
    'Bacabac': 1345,
    'Bacolor': 1345,
    'Bacsay': 1345,
    'Bagac': 1345,
    'Bagong Barrio': 1345,
    'Bagong-Sikat': 1345,
    'Bahay Pare': 1345,
    'Bakulong': 1345,
    'Balagtas': 1345,
    'Balanga': 1345,
    'Balaoang': 1345,
    'Balas': 1345,
    'Balasing': 1345,
    'Balayang': 1345,
    'Baler': 1345,
    'Balibago': 1345,
    'Balingcanaway': 1345,
    'Balite': 1345,
    'Baliuag': 1345,
    'Baloc': 1345,
    'Baloy': 1345,
    'Balsic': 1345,
    'Balucuc': 1345,
    'Balut': 1345,
    'Balutu': 1345,
    'Bamban': 1345,
    'Banawang': 1345,
    'Bangad': 1345,
    'Bani': 1345,
    'Baquero Norte': 1345,
    'Batasan Bata': 1345,
    'Batitang': 1345,
    'Bayanan': 1345,
    'Beddeng': 1345,
    'Biay': 1345,
    'Bibiclat': 1345,
    'Bicos': 1345,
    'Biga': 1345,
    'Bilad': 1345,
    'Bituñgol': 1345,
    'Bobon Second': 1345,
    'Bocaue': 1345,
    'Bodega': 1345,
    'Bolitoc': 1345,
    'Bongabon': 1345,
    'Botolan': 1345,
    'Buenlag': 1345,
    'Buensuseso': 1345,
    'Bulaon': 1345,
    'Bularit': 1345,
    'Bulawin': 1345,
    'Bulihan': 1345,
    'Buliran': 1345,
    'Buliran Segundo': 1345,
    'Bulualto': 1345,
    'Bundoc': 1345,
    'Bunol': 1345,
    'Burgos': 1345,
    'Bustos': 1345,
    'Cabanatuan City': 1345,
    'Cabangan': 1345,
    'Cabayaoasan': 1345,
    'Cabcaben': 1345,
    'Cabiao': 1345,
    'Cabog': 1345,
    'Cafe': 1345,
    'Calaba': 1345,
    'Calancuasan Norte': 1345,
    'Calangain': 1345,
    'Calantas': 1345,
    'Calayaan': 1345,
    'Calibungan': 1345,
    'Calibutbut': 1345,
    'Calingcuan': 1345,
    'Calumpang': 1345,
    'Calumpit': 1345,
    'Cama Juan': 1345,
    'Camachile': 1345,
    'Camias': 1345,
    'Camiling': 1345,
    'Candaba': 1345,
    'Candating': 1345,
    'Capas': 1345,
    'Cardona': 1345,
    'Carmen': 1345,
    'Carranglan': 1345,
    'Castillejos': 1345,
    'Cauayan': 1345,
    'Cavite': 1345,
    'Cawayan Bugtong': 1345,
    'Comillas': 1345,
    'Communal': 1345,
    'Concepcion': 1345,
    'Conversion': 1345,
    'Culianin': 1345,
    'Culubasa': 1345,
    'Cut-cut Primero': 1345,
    'Cuyapo': 1345,
    'Dampol': 1345,
    'Del Carmen': 1345,
    'Del Pilar': 1345,
    'Digdig': 1345,
    'Diliman Primero': 1345,
    'Dinalupihan': 1345,
    'Dingalan': 1345,
    'Dolores': 1345,
    'Doña Remedios Trinidad': 1345,
    'Dumarais': 1345,
    'Entablado': 1345,
    'Estacion': 1345,
    'Estipona': 1345,
    'Estrella': 1345,
    'Floridablanca': 1345,
    'Gabaldon': 1345,
    'Gapan': 1345,
    'General Luna': 1345,
    'General Mamerto Natividad': 1345,
    'General Tinio': 1345,
    'Gerona': 1345,
    'Guagua': 1345,
    'Gueset': 1345,
    'Guiguinto': 1345,
    'Guimba': 1345,
    'Guisguis': 1345,
    'Gutad': 1345,
    'Guyong': 1345,
    'Hagonoy': 1345,
    'Hermosa': 1345,
    'Iba': 1345,
    'Jaen': 1345,
    'La Paz': 1345,
    'Lambakin': 1345,
    'Lanat': 1345,
    'Laug': 1345,
    'Laur': 1345,
    'Lawang Kupang': 1345,
    'Lennec': 1345,
    'Licab': 1345,
    'Liciada': 1345,
    'Ligaya': 1345,
    'Limay': 1345,
    'Liozon': 1345,
    'Lipay': 1345,
    'Llanera': 1345,
    'Loma de Gato': 1345,
    'Lomboy': 1345,
    'Lourdes': 1345,
    'Lubao': 1345,
    'Lucapon': 1345,
    'Lupao': 1345,
    'Maasim': 1345,
    'Mababanaba': 1345,
    'Mabalacat City': 1345,
    'Mabayo': 1345,
    'Mabilang': 1345,
    'Mabilog': 1345,
    'Mabini': 1345,
    'Macabebe': 1345,
    'Macapsing': 1345,
    'Macarse': 1345,
    'Macatbong': 1345,
    'Magalang': 1345,
    'Magliman': 1345,
    'Magtangol': 1345,
    'Maguinao': 1345,
    'Malabon': 1345,
    'Malacampa': 1345,
    'Maligaya': 1345,
    'Malino': 1345,
    'Malolos': 1345,
    'Maloma': 1345,
    'Maluid': 1345,
    'Malusac': 1345,
    'Mambog': 1345,
    'Mamonit': 1345,
    'Manacsac': 1345,
    'Manatal': 1345,
    'Mandili': 1345,
    'Mangga': 1345,
    'Manibaug Pasig': 1345,
    'Manogpi': 1345,
    'Mapalacsiao': 1345,
    'Mapalad': 1345,
    'Mapaniqui': 1345,
    'Maquiapo': 1345,
    'Marawa': 1345,
    'Maria Aurora': 1345,
    'Marilao': 1345,
    'Mariveles': 1345,
    'Masalipit': 1345,
    'Masantol': 1345,
    'Masinloc': 1345,
    'Matayumtayum': 1345,
    'Maturanoc': 1345,
    'Mayantoc': 1345,
    'Mexico': 1345,
    'Meycauayan': 1345,
    'Minalin': 1345,
    'Moncada': 1345,
    'Moriones': 1345,
    'Morong': 1345,
    'Motrico': 1345,
    'Muñoz': 1345,
    'Murcia': 1345,
    'Nagpandayan': 1345,
    'Nambalan': 1345,
    'Nampicuan': 1345,
    'Nancamarinan': 1345,
    'Nieves': 1345,
    'Niugan': 1345,
    'Norzagaray': 1345,
    'Obando': 1345,
    'Olongapo': 1345,
    'Orani': 1345,
    'Orion': 1345,
    'Paco Roman': 1345,
    'Padapada': 1345,
    'Paitan Norte': 1345,
    'Palauig': 1345,
    'Palayan City': 1345,
    'Palusapis': 1345,
    'Pamatawan': 1345,
    'Panabingan': 1345,
    'Panan': 1345,
    'Pance': 1345,
    'Pandacaqui': 1345,
    'Pandi': 1345,
    'Pando': 1345,
    'Paniqui': 1345,
    'Panlinlang': 1345,
    'Pantabangan': 1345,
    'Pantubig': 1345,
    'Paombong': 1345,
    'Papaya': 1345,
    'Parang': 1345,
    'Parista': 1345,
    'Pau': 1345,
    'Peñaranda': 1345,
    'Pias': 1345,
    'Pilar': 1345,
    'Piñahan': 1345,
    'Pinambaran': 1345,
    'Pio': 1345,
    'Plaridel': 1345,
    'Poblacion, San Felipe': 1345,
    'Porac': 1345,
    'Porais': 1345,
    'Prado Siongco': 1345,
    'Province of Aurora': 1345,
    'Province of Bataan': 1345,
    'Province of Bulacan': 1345,
    'Province of Nueva Ecija': 1345,
    'Province of Pampanga': 1345,
    'Province of Tarlac': 1345,
    'Province of Zambales': 1345,
    'Pulilan': 1345,
    'Pulo': 1345,
    'Pulong Gubat': 1345,
    'Pulong Sampalok': 1345,
    'Pulung Santol': 1345,
    'Pulungmasle': 1345,
    'Puncan': 1345,
    'Pura': 1345,
    'Purac': 1345,
    'Putlod': 1345,
    'Quezon': 1345,
    'Rajal Norte': 1345,
    'Ramos': 1345,
    'Rizal': 1345,
    'Sabang': 1345,
    'Sagana': 1345,
    'Salapungan': 1345,
    'Salaza': 1345,
    'Salcedo': 1345,
    'Salvacion I': 1345,
    'Samal': 1345,
    'Sampaloc': 1345,
    'San Agustin': 1345,
    'San Alejandro': 1345,
    'San Andres': 1345,
    'San Anton': 1345,
    'San Antonio': 1345,
    'San Basilio': 1345,
    'San Benito': 1345,
    'San Carlos': 1345,
    'San Casimiro': 1345,
    'San Clemente': 1345,
    'San Cristobal': 1345,
    'San Fabian': 1345,
    'San Felipe': 1345,
    'San Felipe Old': 1345,
    'San Fernando': 1345,
    'San Francisco': 1345,
    'San Ildefonso': 1345,
    'San Isidro': 1345,
    'San Jacinto': 1345,
    'San Jose': 1345,
    'San Jose del Monte': 1345,
    'San Juan': 1345,
    'San Juan de Mata': 1345,
    'San Leonardo': 1345,
    'San Lorenzo': 1345,
    'San Luis': 1345,
    'San Manuel': 1345,
    'San Marcelino': 1345,
    'San Mariano': 1345,
    'San Mateo': 1345,
    'San Miguel': 1345,
    'San Narciso': 1345,
    'San Nicolas': 1345,
    'San Pascual': 1345,
    'San Patricio': 1345,
    'San Rafael': 1345,
    'San Ricardo': 1345,
    'San Roque': 1345,
    'San Roque Dau First': 1345,
    'San Simon': 1345,
    'San Vicente': 1345,
    'San Vincente': 1345,
    'Santa Ana': 1345,
    'Santa Barbara': 1345,
    'Santa Cruz': 1345,
    'Santa Fe': 1345,
    'Santa Ignacia': 1345,
    'Santa Ines West': 1345,
    'Santa Juliana': 1345,
    'Santa Lucia': 1345,
    'Santa Maria': 1345,
    'Santa Monica': 1345,
    'Santa Rita': 1345,
    'Santa Rosa': 1345,
    'Santa Teresa First': 1345,
    'Santiago': 1345,
    'Santo Cristo': 1345,
    'Santo Domingo': 1345,
    'Santo Niño': 1345,
    'Santo Rosario': 1345,
    'Santo Tomas': 1345,
    'Santol': 1345,
    'Sapang': 1345,
    'Sapang Buho': 1345,
    'Sapol': 1345,
    'Saysain': 1345,
    'Sexmoan': 1345,
    'Sibul': 1345,
    'Siclong': 1345,
    'Sinait': 1345,
    'Sinilian First': 1345,
    'Soledad': 1345,
    'Subic': 1345,
    'Suklayin': 1345,
    'Sula': 1345,
    'Sulucan': 1345,
    'Tabacao': 1345,
    'Tabon': 1345,
    'Tabuating': 1345,
    'Tal I Mun Doc': 1345,
    'Talaga': 1345,
    'Talang': 1345,
    'Talavera': 1345,
    'Taltal': 1345,
    'Talugtug': 1345,
    'Tariji': 1345,
    'Tarlac City': 1345,
    'Tartaro': 1345,
    'Tayabo': 1345,
    'Telabastagan': 1345,
    'Tikiw': 1345,
    'Tinang': 1345,
    'Tondod': 1345,
    'Uacon': 1345,
    'Umiray': 1345,
    'Upig': 1345,
    'Vargas': 1345,
    'Villa Aglipay': 1345,
    'Villa Isla': 1345,
    'Vizal San Pablo': 1345,
    'Vizal Santo Niño': 1345,
    'Agbannawag': 1335,
    'Ambuclao': 1335,
    'Amlimay': 1335,
    'Ampusungan': 1335,
    'Angad': 1335,
    'Apayao': 1335,
    'Atok': 1335,
    'Baculongan': 1335,
    'Baguinge': 1335,
    'Baguio': 1335,
    'Bakun': 1335,
    'Balbalan': 1335,
    'Banaue': 1335,
    'Bangao': 1335,
    'Bangued': 1335,
    'Bantay': 1335,
    'Barlig': 1335,
    'Bauko': 1335,
    'Bayabas': 1335,
    'Besao': 1335,
    'Betwagan': 1335,
    'Bocos': 1335,
    'Bokod': 1335,
    'Boliney': 1335,
    'Bontoc': 1335,
    'Bucay': 1335,
    'Buguias': 1335,
    'Bulalacao': 1335,
    'Butigui': 1335,
    'Calaba': 1335,
    'Calanasan': 1335,
    'Conner': 1335,
    'Daguioman': 1335,
    'Dalipey': 1335,
    'Dalupirip': 1335,
    'Danglas': 1335,
    'Dolores': 1335,
    'Flora': 1335,
    'Gambang': 1335,
    'Guinsadan': 1335,
    'Hapao': 1335,
    'Itogon': 1335,
    'Kabugao': 1335,
    'Kalinga': 1335,
    'Kapangan': 1335,
    'Kiangan': 1335,
    'Kibungan': 1335,
    'La Paz': 1335,
    'La Trinidad': 1335,
    'Lagangilang': 1335,
    'Lagawe': 1335,
    'Lagayan': 1335,
    'Lamut': 1335,
    'Laya': 1335,
    'Licuan': 1335,
    'Liwan': 1335,
    'Loacan': 1335,
    'Luba': 1335,
    'Lubuagan': 1335,
    'Luna': 1335,
    'Manabo': 1335,
    'Mankayan': 1335,
    'Monamon': 1335,
    'Mountain Province': 1335,
    'Nangalisan': 1335,
    'Natonin': 1335,
    'Natubleng': 1335,
    'Paracelis': 1335,
    'Pasil': 1335,
    'Peñarrubia': 1335,
    'Pidigan': 1335,
    'Pilar': 1335,
    'Pinukpuk': 1335,
    'Province of Abra': 1335,
    'Province of Benguet': 1335,
    'Province of Ifugao': 1335,
    'Sablan': 1335,
    'Sadanga': 1335,
    'Sadsadan': 1335,
    'Sagada': 1335,
    'San Juan': 1335,
    'San Ramon': 1335,
    'Santa Marcela': 1335,
    'Tabaan': 1335,
    'Tabio': 1335,
    'Tabuk': 1335,
    'Tacadang': 1335,
    'Tadian': 1335,
    'Taloy': 1335,
    'Tanudan': 1335,
    'Tayum': 1335,
    'Tinglayan': 1335,
    'Topdac': 1335,
    'Tublay': 1335,
    'Tuding': 1335,
    'Villarosa': 1335,
    'Villaviciosa': 1335,
    'Andili': 1309,
    'Andop': 1309,
    'Anibongan': 1309,
    'Astorga': 1309,
    'Asuncion': 1309,
    'Babag': 1309,
    'Baculin': 1309,
    'Baganga': 1309,
    'Balagunan': 1309,
    'Balangonan': 1309,
    'Balutakay': 1309,
    'Banaybanay': 1309,
    'Bansalan': 1309,
    'Bantacan': 1309,
    'Baon': 1309,
    'Baracatan': 1309,
    'Basiawan': 1309,
    'Batiano': 1309,
    'Bato': 1309,
    'Batobato': 1309,
    'Baylo': 1309,
    'Biao': 1309,
    'Bincoñgan': 1309,
    'Bitaogan': 1309,
    'Bobon': 1309,
    'Bolila': 1309,
    'Boston': 1309,
    'Buclad': 1309,
    'Buhangin': 1309,
    'Bukid': 1309,
    'Bulacan': 1309,
    'Bungabon': 1309,
    'Butulan': 1309,
    'Cabayangan': 1309,
    'Cabinuangan': 1309,
    'Caburan': 1309,
    'Cambanugoy': 1309,
    'Camudmud': 1309,
    'Caraga': 1309,
    'Carmen': 1309,
    'Cateel': 1309,
    'City of Mati': 1309,
    'City of Panabo': 1309,
    'City of Tagum': 1309,
    'Cogon': 1309,
    'Concepcion': 1309,
    'Corocotan': 1309,
    'Coronon': 1309,
    'Cuambog': 1309,
    'Culaman': 1309,
    'Dacudao': 1309,
    'Dakbayan sa Digos': 1309,
    'Davan': 1309,
    'Davao City': 1309,
    'Del Pilar': 1309,
    'Digos': 1309,
    'Dolo': 1309,
    'Don Marcelino': 1309,
    'Dumlan': 1309,
    'Esperanza': 1309,
    'Gabi': 1309,
    'Gabuyan': 1309,
    'Goma': 1309,
    'Governor Generoso': 1309,
    'Guihing Proper': 1309,
    'Gumalang': 1309,
    'Gupitan': 1309,
    'Hagonoy': 1309,
    'Ignit': 1309,
    'Ilangay': 1309,
    'Inawayan': 1309,
    'Island Garden City of Samal': 1309,
    'Jose Abad Santos': 1309,
    'Jovellar': 1309,
    'Kalbay': 1309,
    'Kalian': 1309,
    'Kaligutan': 1309,
    'Kapalong': 1309,
    'Katipunan': 1309,
    'Kiblawan': 1309,
    'Kinablangan': 1309,
    'Kinamayan': 1309,
    'Kinangan': 1309,
    'La Libertad': 1309,
    'La Paz': 1309,
    'La Union': 1309,
    'Lacaron': 1309,
    'Lacson': 1309,
    'Lais': 1309,
    'Lamitan': 1309,
    'Lapuan': 1309,
    'Lasang': 1309,
    'Libuganon': 1309,
    'Limao': 1309,
    'Limot': 1309,
    'Linao': 1309,
    'Linoan': 1309,
    'Lukatan': 1309,
    'Luna': 1309,
    'Lungaog': 1309,
    'Lupon': 1309,
    'Luzon': 1309,
    'Mabini': 1309,
    'Mabuhay': 1309,
    'Maco': 1309,
    'Maduao': 1309,
    'Magatos': 1309,
    'Magdug': 1309,
    'Magnaga': 1309,
    'Magsaysay': 1309,
    'Mahanob': 1309,
    'Mahayag': 1309,
    'Malagos': 1309,
    'Malalag': 1309,
    'Malinao': 1309,
    'Malita': 1309,
    'Mambago': 1309,
    'Managa': 1309,
    'Manaloal': 1309,
    'Manat': 1309,
    'Manay': 1309,
    'Mangili': 1309,
    'Manikling': 1309,
    'Matanao': 1309,
    'Mati': 1309,
    'Matiao': 1309,
    'Matti': 1309,
    'Mawab': 1309,
    'Mayo': 1309,
    'Monkayo': 1309,
    'Montevista': 1309,
    'Nabunturan': 1309,
    'Nangan': 1309,
    'Nanyo': 1309,
    'New Baclayon': 1309,
    'New Bataan': 1309,
    'New Bohol': 1309,
    'New Corella': 1309,
    'New Leyte': 1309,
    'New Sibonga': 1309,
    'New Visayas': 1309,
    'Nuing': 1309,
    'Padada': 1309,
    'Pag-asa': 1309,
    'Pagsabangan': 1309,
    'Palma Gil': 1309,
    'Panabo': 1309,
    'Pandasan': 1309,
    'Pangian': 1309,
    'Panikian': 1309,
    'Pantukan': 1309,
    'Pasian': 1309,
    'Pondaguitan': 1309,
    'Pung-Pang': 1309,
    'Samal': 1309,
    'Sampao': 1309,
    'San Alfonso': 1309,
    'San Antonio': 1309,
    'San Ignacio': 1309,
    'San Isidro': 1309,
    'San Luis': 1309,
    'San Mariano': 1309,
    'San Miguel': 1309,
    'San Pedro': 1309,
    'San Rafael': 1309,
    'San Remigio': 1309,
    'San Vicente': 1309,
    'Santa Cruz': 1309,
    'Santa Maria': 1309,
    'Santiago': 1309,
    'Santo Niño': 1309,
    'Santo Tomas': 1309,
    'Sarangani': 1309,
    'Sibulan': 1309,
    'Sigaboy': 1309,
    'Simod': 1309,
    'Sinawilan': 1309,
    'Sinayawan': 1309,
    'Sirib': 1309,
    'Sugal': 1309,
    'Sulop': 1309,
    'Surup': 1309,
    'Suz-on': 1309,
    'Tagakpan': 1309,
    'Tagdanua': 1309,
    'Tagnanan': 1309,
    'Tagum': 1309,
    'Takub': 1309,
    'Talagutong': 1309,
    'Talisay': 1309,
    'Talomo': 1309,
    'Tamayong': 1309,
    'Tambo': 1309,
    'Tamisan': 1309,
    'Tamugan': 1309,
    'Tanlad': 1309,
    'Tapia': 1309,
    'Tarragona': 1309,
    'Tawan tawan': 1309,
    'Taytayan': 1309,
    'Tibagon': 1309,
    'Tibanbang': 1309,
    'Tiblawan': 1309,
    'Tombongon': 1309,
    'Tubalan': 1309,
    'Tuban': 1309,
    'Tubod': 1309,
    'Tuganay': 1309,
    'Tuli': 1309,
    'Ula': 1309,
    'Wañgan': 1309,
    'Wines': 1309,
    'Alejal': 1340,
    'Andili': 1340,
    'Andop': 1340,
    'Anibongan': 1340,
    'Astorga': 1340,
    'Babag': 1340,
    'Baculin': 1340,
    'Baganga': 1340,
    'Balagunan': 1340,
    'Balangonan': 1340,
    'Balutakay': 1340,
    'Bansalan': 1340,
    'Bantacan': 1340,
    'Baon': 1340,
    'Baracatan': 1340,
    'Basiawan': 1340,
    'Batiano': 1340,
    'Bato': 1340,
    'Batobato': 1340,
    'Baylo': 1340,
    'Biao': 1340,
    'Bincoñgan': 1340,
    'Bitaogan': 1340,
    'Bobon': 1340,
    'Bolila': 1340,
    'Boston': 1340,
    'Buclad': 1340,
    'Buhangin': 1340,
    'Bukid': 1340,
    'Bulacan': 1340,
    'Bungabon': 1340,
    'Butulan': 1340,
    'Cabayangan': 1340,
    'Cabinuangan': 1340,
    'Caburan': 1340,
    'Cambanugoy': 1340,
    'Camudmud': 1340,
    'Caraga': 1340,
    'Carmen': 1340,
    'Cateel': 1340,
    'Cogon': 1340,
    'Compostela': 1340,
    'Compostela Valley': 1340,
    'Concepcion': 1340,
    'Corocotan': 1340,
    'Coronon': 1340,
    'Cuambog': 1340,
    'Culaman': 1340,
    'Dacudao': 1340,
    'Davan': 1340,
    'Davao': 1340,
    'Del Pilar': 1340,
    'Digos': 1340,
    'Dolo': 1340,
    'Dumlan': 1340,
    'Esperanza': 1340,
    'Gabi': 1340,
    'Gabuyan': 1340,
    'Goma': 1340,
    'Guihing Proper': 1340,
    'Gumalang': 1340,
    'Gupitan': 1340,
    'Hagonoy': 1340,
    'Hiju, Maco': 1340,
    'Ignit': 1340,
    'Ilangay': 1340,
    'Inawayan': 1340,
    'Jovellar': 1340,
    'Kalbay': 1340,
    'Kalian': 1340,
    'Kaligutan': 1340,
    'Kapalong': 1340,
    'Katipunan': 1340,
    'Kiblawan': 1340,
    'Kinablangan': 1340,
    'Kinamayan': 1340,
    'Kinangan': 1340,
    'La Libertad': 1340,
    'La Paz': 1340,
    'La Union': 1340,
    'Lacaron': 1340,
    'Lacson': 1340,
    'Lais': 1340,
    'Lamitan': 1340,
    'Lapuan': 1340,
    'Lasang': 1340,
    'Libuganon': 1340,
    'Limao': 1340,
    'Limot': 1340,
    'Linao': 1340,
    'Linoan': 1340,
    'Lukatan': 1340,
    'Luna': 1340,
    'Lungaog': 1340,
    'Lupon': 1340,
    'Luzon': 1340,
    'Mabini': 1340,
    'Mabuhay': 1340,
    'Maco': 1340,
    'Maduao': 1340,
    'Magatos': 1340,
    'Magdug': 1340,
    'Magnaga': 1340,
    'Magsaysay': 1340,
    'Magugpo Poblacion': 1340,
    'Mahanob': 1340,
    'Mahayag': 1340,
    'Malagos': 1340,
    'Malalag': 1340,
    'Malinao': 1340,
    'Malita': 1340,
    'Mambago': 1340,
    'Managa': 1340,
    'Manaloal': 1340,
    'Manat': 1340,
    'Manay': 1340,
    'Mangili': 1340,
    'Manikling': 1340,
    'Matanao': 1340,
    'Mati': 1340,
    'Matiao': 1340,
    'Matti': 1340,
    'Mawab': 1340,
    'Mayo': 1340,
    'Monkayo': 1340,
    'Montevista': 1340,
    'Nabunturan': 1340,
    'Nangan': 1340,
    'Nanyo': 1340,
    'New Baclayon': 1340,
    'New Bohol': 1340,
    'New Corella': 1340,
    'New Leyte': 1340,
    'New Sibonga': 1340,
    'New Visayas': 1340,
    'Nuing': 1340,
    'Padada': 1340,
    'Pag-asa': 1340,
    'Pagsabangan': 1340,
    'Palma Gil': 1340,
    'Panabo': 1340,
    'Pandasan': 1340,
    'Pangian': 1340,
    'Panikian': 1340,
    'Pantukan': 1340,
    'Pasian': 1340,
    'Pondaguitan': 1340,
    'Province of Davao del Norte': 1340,
    'Province of Davao del Sur': 1340,
    'Province of Davao Oriental': 1340,
    'Pung-Pang': 1340,
    'Samal': 1340,
    'Sampao': 1340,
    'San Alfonso': 1340,
    'San Antonio': 1340,
    'San Ignacio': 1340,
    'San Luis': 1340,
    'San Mariano': 1340,
    'San Miguel': 1340,
    'San Pedro': 1340,
    'San Rafael': 1340,
    'San Remigio': 1340,
    'Santa Cruz': 1340,
    'Santa Maria': 1340,
    'Santiago': 1340,
    'Santo Niño': 1340,
    'Sarangani': 1340,
    'Sibulan': 1340,
    'Sigaboy': 1340,
    'Simod': 1340,
    'Sinawilan': 1340,
    'Sinayawan': 1340,
    'Sirib': 1340,
    'Sugal': 1340,
    'Sulop': 1340,
    'Surup': 1340,
    'Suz-on': 1340,
    'Tagakpan': 1340,
    'Tagdanua': 1340,
    'Tagnanan': 1340,
    'Takub': 1340,
    'Talagutong': 1340,
    'Talisay': 1340,
    'Talomo': 1340,
    'Tamayong': 1340,
    'Tambo': 1340,
    'Tamisan': 1340,
    'Tamugan': 1340,
    'Tanlad': 1340,
    'Tapia': 1340,
    'Tarragona': 1340,
    'Tawan tawan': 1340,
    'Taytayan': 1340,
    'Tibagon': 1340,
    'Tibanbang': 1340,
    'Tiblawan': 1340,
    'Tombongon': 1340,
    'Tubalan': 1340,
    'Tuban': 1340,
    'Tubod': 1340,
    'Tuganay': 1340,
    'Tuli': 1340,
    'Ula': 1340,
    'Wañgan': 1340,
    'Wines': 1340,
    'Abuyog': 1322,
    'Alangalang': 1322,
    'Albuera': 1322,
    'Allen': 1322,
    'Almagro': 1322,
    'Almeria': 1322,
    'Alugan': 1322,
    'Anahawan': 1322,
    'Anito': 1322,
    'Arteche': 1322,
    'Babatngon': 1322,
    'Balagtas': 1322,
    'Balagui': 1322,
    'Balangiga': 1322,
    'Balangkayan': 1322,
    'Balinsacayao': 1322,
    'Balite': 1322,
    'Balocawehay': 1322,
    'Balogo': 1322,
    'Balud': 1322,
    'Bantayan': 1322,
    'Bantiqui': 1322,
    'Baras': 1322,
    'Barugo': 1322,
    'Basey': 1322,
    'Basud': 1322,
    'Bato': 1322,
    'Baybay': 1322,
    'Bilwang': 1322,
    'Biri': 1322,
    'Bislig': 1322,
    'Bitanjuan': 1322,
    'Bobon': 1322,
    'Bontoc': 1322,
    'Borongan': 1322,
    'Bugho': 1322,
    'Bugko': 1322,
    'Bunga': 1322,
    'Burauen': 1322,
    'Butazon': 1322,
    'Cabacuñgan': 1322,
    'Cabay': 1322,
    'Cabodiongan': 1322,
    'Cabucgayan': 1322,
    'Cagamotan': 1322,
    'Caibiran': 1322,
    'Calape': 1322,
    'Calbayog': 1322,
    'Calbiga': 1322,
    'Calubian': 1322,
    'Can-avid': 1322,
    'Canhandugan': 1322,
    'Capoocan': 1322,
    'Capul': 1322,
    'Caraycaray': 1322,
    'Caridad': 1322,
    'Carigara': 1322,
    'Catarman': 1322,
    'Catbalogan': 1322,
    'Catmon': 1322,
    'Catubig': 1322,
    'Cavite': 1322,
    'Cervantes': 1322,
    'Concepcion': 1322,
    'Consuegra': 1322,
    'Culaba': 1322,
    'Culasian': 1322,
    'Dagami': 1322,
    'Dao': 1322,
    'Dapdap': 1322,
    'Daram': 1322,
    'Dolores': 1322,
    'Doos': 1322,
    'Dulag': 1322,
    'Erenas': 1322,
    'Gabas': 1322,
    'Gamay': 1322,
    'Gandara': 1322,
    'General MacArthur': 1322,
    'Ginabuyan': 1322,
    'Giporlos': 1322,
    'Gubang': 1322,
    'Guindapunan': 1322,
    'Guirang': 1322,
    'Guiuan': 1322,
    'Hernani': 1322,
    'Hilongos': 1322,
    'Hinabangan': 1322,
    'Hindang': 1322,
    'Hingatungan': 1322,
    'Hinunangan': 1322,
    'Hinundayan': 1322,
    'Hipadpad': 1322,
    'Hipasngo': 1322,
    'Ibarra': 1322,
    'Inangatan': 1322,
    'Inopacan': 1322,
    'Ipil': 1322,
    'Isabel': 1322,
    'Jaro': 1322,
    'Javier': 1322,
    'Jiabong': 1322,
    'Jipapad': 1322,
    'Jubasan': 1322,
    'Julita': 1322,
    'Kabuynan': 1322,
    'Kampokpok': 1322,
    'Kananga': 1322,
    'Kawayan': 1322,
    'Kilim': 1322,
    'La Paz': 1322,
    'Lalauigan': 1322,
    'Lamak': 1322,
    'Lao': 1322,
    'Laoang': 1322,
    'Lapinig': 1322,
    'Las Navas': 1322,
    'Lavezares': 1322,
    'Leyte': 1322,
    'Libagon': 1322,
    'Libas': 1322,
    'Libertad': 1322,
    'Liberty': 1322,
    'Liloan': 1322,
    'Lim-oo': 1322,
    'Limasawa': 1322,
    'Limon': 1322,
    'Llorente': 1322,
    'Looc': 1322,
    'Lope de Vega': 1322,
    'Lubi': 1322,
    'Maasin': 1322,
    'Mabini': 1322,
    'MacArthur': 1322,
    'Macrohon': 1322,
    'Mahagnao': 1322,
    'Mahaplag': 1322,
    'Makiwalo': 1322,
    'Malaga': 1322,
    'Malajog': 1322,
    'Mantang': 1322,
    'Mapanas': 1322,
    'Marabut': 1322,
    'Margen': 1322,
    'Maripipi': 1322,
    'Masarayao': 1322,
    'Maslog': 1322,
    'Matag-ob': 1322,
    'Matalom': 1322,
    'Matlang': 1322,
    'Matuguinao': 1322,
    'Maydolong': 1322,
    'Mayorga': 1322,
    'Maypangdan': 1322,
    'Mercedes': 1322,
    'Merida': 1322,
    'Mondragon': 1322,
    'Motiong': 1322,
    'Naghalin': 1322,
    'Napuro': 1322,
    'Naval': 1322,
    'Nena': 1322,
    'Nenita': 1322,
    'Oras': 1322,
    'Ormoc': 1322,
    'Padre Burgos': 1322,
    'Pagsanghan': 1322,
    'Palanit': 1322,
    'Palapag': 1322,
    'Palaroo': 1322,
    'Palhi': 1322,
    'Palo': 1322,
    'Palompon': 1322,
    'Pambujan': 1322,
    'Panalanoy': 1322,
    'Pangdan': 1322,
    'Pangpang': 1322,
    'Pasay': 1322,
    'Pastrana': 1322,
    'Patong': 1322,
    'Pawing': 1322,
    'Piña': 1322,
    'Pinabacdao': 1322,
    'Pinamopoan': 1322,
    'Pinangomhan': 1322,
    'Pintuyan': 1322,
    'Plaridel': 1322,
    'Polahongon': 1322,
    'Polañge': 1322,
    'Puerto Bello': 1322,
    'Quinapondan': 1322,
    'Rizal': 1322,
    'Rosario': 1322,
    'Sabang': 1322,
    'Saint Bernard': 1322,
    'Salcedo': 1322,
    'Salvacion': 1322,
    'San Antonio': 1322,
    'San Eduardo': 1322,
    'San Francisco': 1322,
    'San Isidro': 1322,
    'San Joaquin': 1322,
    'San Jorge': 1322,
    'San Jose': 1322,
    'San Jose de Buan': 1322,
    'San Juan': 1322,
    'San Julian': 1322,
    'San Miguel': 1322,
    'San Pedro': 1322,
    'San Policarpio': 1322,
    'San Policarpo': 1322,
    'San Ricardo': 1322,
    'San Roque': 1322,
    'San Sebastian': 1322,
    'San Vicente': 1322,
    'Santa Cruz': 1322,
    'Santa Fe': 1322,
    'Santa Margarita': 1322,
    'Santa Rita': 1322,
    'Santo Niño': 1322,
    'Siguinon': 1322,
    'Silago': 1322,
    'Silanga': 1322,
    'Silvino Lobos': 1322,
    'Sogod': 1322,
    'Sulangan': 1322,
    'Sulat': 1322,
    'Tabango': 1322,
    'Tabing': 1322,
    'Tabontabon': 1322,
    'Tacloban': 1322,
    'Taft': 1322,
    'Tagapul-an': 1322,
    'Tagbubungang Diot': 1322,
    'Talalora': 1322,
    'Talisayan': 1322,
    'Tanauan': 1322,
    'Tarangnan': 1322,
    'Tibur': 1322,
    'Tinambacan': 1322,
    'Tolosa': 1322,
    'Tomas Oppus': 1322,
    'Tucdao': 1322,
    'Tugbong': 1322,
    'Tunga': 1322,
    'Tutubigan': 1322,
    'Umaganhan': 1322,
    'Valencia': 1322,
    'Victoria': 1322,
    'Viga': 1322,
    'Villaba': 1322,
    'Villareal': 1322,
    'Viriato': 1322,
    'Wright': 1322,
    'Zumarraga': 1322,
    'Acao': 1355,
    'Agno': 1355,
    'Agoo': 1355,
    'Aguilar': 1355,
    'Alac': 1355,
    'Alaminos': 1355,
    'Alcala': 1355,
    'Alilem': 1355,
    'Allangigan Primero': 1355,
    'Aloleng': 1355,
    'Amagbagan': 1355,
    'Anambongan': 1355,
    'Anda': 1355,
    'Angatel': 1355,
    'Anulid': 1355,
    'Aringay': 1355,
    'Asingan': 1355,
    'Baay': 1355,
    'Bacag': 1355,
    'Bacarra': 1355,
    'Bacnar': 1355,
    'Bacnotan': 1355,
    'Bactad Proper': 1355,
    'Bacundao Weste': 1355,
    'Badoc': 1355,
    'Bagulin': 1355,
    'Bail': 1355,
    'Balaoan': 1355,
    'Balingasay': 1355,
    'Balingueo': 1355,
    'Balogo': 1355,
    'Balungao': 1355,
    'Baluyot': 1355,
    'Banayoyo': 1355,
    'Bangan-Oda': 1355,
    'Bangar': 1355,
    'Bangui': 1355,
    'Bani': 1355,
    'Banog Sur': 1355,
    'Bantay': 1355,
    'Bantog': 1355,
    'Barangobong': 1355,
    'Baro': 1355,
    'Barong': 1355,
    'Basing': 1355,
    'Basista': 1355,
    'Batac City': 1355,
    'Bataquil': 1355,
    'Bauang': 1355,
    'Bautista': 1355,
    'Bayambang': 1355,
    'Bayaoas': 1355,
    'Bical Norte': 1355,
    'Bil-Loca': 1355,
    'Binabalian': 1355,
    'Binalonan': 1355,
    'Binday': 1355,
    'Binmaley': 1355,
    'Bobonan': 1355,
    'Bogtong': 1355,
    'Bolaoit': 1355,
    'Bolingit': 1355,
    'Bolo': 1355,
    'Boñgalon': 1355,
    'Botao': 1355,
    'Bued': 1355,
    'Buenlag': 1355,
    'Bugallon': 1355,
    'Bulog': 1355,
    'Burgos': 1355,
    'Butubut Norte': 1355,
    'Caabiangan': 1355,
    'Caba': 1355,
    'Cabalaoangan': 1355,
    'Cabalitian': 1355,
    'Cabittaogan': 1355,
    'Cabugao': 1355,
    'Cabungan': 1355,
    'Calasiao': 1355,
    'Calepaan': 1355,
    'Callaguip': 1355,
    'Calomboyan': 1355,
    'Calongbuyan': 1355,
    'Calsib': 1355,
    'Camaley': 1355,
    'Canan Norte': 1355,
    'Canaoalan': 1355,
    'Candon': 1355,
    'Cantoria': 1355,
    'Caoayan': 1355,
    'Capandanan': 1355,
    'Capulaan': 1355,
    'Caramutan': 1355,
    'Carasi': 1355,
    'Carmen': 1355,
    'Caronoan West': 1355,
    'Carot': 1355,
    'Carriedo': 1355,
    'Carusucan': 1355,
    'Catablan': 1355,
    'Caterman': 1355,
    'Cato': 1355,
    'Catuday': 1355,
    'Cayanga': 1355,
    'Cayungnan': 1355,
    'Cervantes': 1355,
    'Comillas Norte': 1355,
    'Corrooy': 1355,
    'Currimao': 1355,
    'Dagup': 1355,
    'Dagupan': 1355,
    'Damortis': 1355,
    'Darapidap': 1355,
    'Dasol': 1355,
    'Davila': 1355,
    'Diaz': 1355,
    'Dilan': 1355,
    'Dingras': 1355,
    'Domalanoan': 1355,
    'Domampot': 1355,
    'Don Pedro': 1355,
    'Dorongan Punta': 1355,
    'Doyong': 1355,
    'Dulig': 1355,
    'Dumalneg': 1355,
    'Dumpay': 1355,
    'Eguia': 1355,
    'Esmeralda': 1355,
    'Espiritu': 1355,
    'Fuerte': 1355,
    'Galimuyod': 1355,
    'Gayaman': 1355,
    'Gregorio del Pilar': 1355,
    'Guiling': 1355,
    'Guiset East': 1355,
    'Hacienda': 1355,
    'Halog West': 1355,
    'Ilioilio': 1355,
    'Inabaan Sur': 1355,
    'Infanta': 1355,
    'Isla': 1355,
    'Labayug': 1355,
    'Labney': 1355,
    'Labrador': 1355,
    'Lagasit': 1355,
    'Laguit Centro': 1355,
    'Laoag': 1355,
    'Leones East': 1355,
    'Lepa': 1355,
    'Libas': 1355,
    'Lidlidda': 1355,
    'Lingayen': 1355,
    'Linmansangan': 1355,
    'Lloren': 1355,
    'Lobong': 1355,
    'Longos': 1355,
    'Loqueb Este': 1355,
    'Lucap': 1355,
    'Lucero': 1355,
    'Luna': 1355,
    'Lunec': 1355,
    'Lungog': 1355,
    'Lusong': 1355,
    'Mabilao': 1355,
    'Mabilbila Sur': 1355,
    'Mabini': 1355,
    'Mabusag': 1355,
    'Macabuboni': 1355,
    'Macalong': 1355,
    'Macalva Norte': 1355,
    'Macayug': 1355,
    'Magallanes': 1355,
    'Magsingal': 1355,
    'Magtaking': 1355,
    'Malabago': 1355,
    'Malanay': 1355,
    'Malasiqui': 1355,
    'Malawa': 1355,
    'Malibong East': 1355,
    'Manaoag': 1355,
    'Mangaldan': 1355,
    'Mangatarem': 1355,
    'Mapandan': 1355,
    'Mapolopolo': 1355,
    'Marcos': 1355,
    'Maticmatic': 1355,
    'Minien East': 1355,
    'Nagbacalan': 1355,
    'Nagsaing': 1355,
    'Naguelguel': 1355,
    'Naguilayan': 1355,
    'Naguilian': 1355,
    'Nalsian Norte': 1355,
    'Nama': 1355,
    'Namboongan': 1355,
    'Nancalobasaan': 1355,
    'Narvacan': 1355,
    'Natividad': 1355,
    'Navatat': 1355,
    'Nibaliw Central': 1355,
    'Nilombot': 1355,
    'Ninoy': 1355,
    'Nueva Era': 1355,
    'Oaqui': 1355,
    'Olea': 1355,
    'Padong': 1355,
    'Pagsanahan Norte': 1355,
    'Pagudpud': 1355,
    'Paitan Este': 1355,
    'Palacpalac': 1355,
    'Paldit': 1355,
    'Palguyod': 1355,
    'Panganiban': 1355,
    'Pangapisan': 1355,
    'Pangascasan': 1355,
    'Pangpang': 1355,
    'Paoay': 1355,
    'Paringao': 1355,
    'Parioc Segundo': 1355,
    'Pasibi West': 1355,
    'Pasuquin': 1355,
    'Patayac': 1355,
    'Patpata Segundo': 1355,
    'Payocpoc Sur': 1355,
    'Piddig': 1355,
    'Pindangan Centro': 1355,
    'Pinili': 1355,
    'Pogonsili': 1355,
    'Polo': 1355,
    'Polong': 1355,
    'Polong Norte': 1355,
    'Pozorrubio': 1355,
    'Province of Ilocos Norte': 1355,
    'Province of Ilocos Sur': 1355,
    'Province of La Union': 1355,
    'Province of Pangasinan': 1355,
    'Pudoc': 1355,
    'Pudoc North': 1355,
    'Puelay': 1355,
    'Pugo': 1355,
    'Puro Pinget': 1355,
    'Quiling': 1355,
    'Quinarayan': 1355,
    'Quintong': 1355,
    'Quirino': 1355,
    'Ranao': 1355,
    'Real': 1355,
    'Rimus': 1355,
    'Rissing': 1355,
    'Rosales': 1355,
    'Rosario': 1355,
    'Sablig': 1355,
    'Sagud-Bahley': 1355,
    'Sagunto': 1355,
    'Salcedo': 1355,
    'Samon': 1355,
    'San Emilio': 1355,
    'San Esteban': 1355,
    'San Eugenio': 1355,
    'San Fabian': 1355,
    'San Fernando': 1355,
    'San Gabriel': 1355,
    'San Gabriel First': 1355,
    'San Ildefonso': 1355,
    'San Jacinto': 1355,
    'San Juan': 1355,
    'San Lorenzo': 1355,
    'San Manuel': 1355,
    'San Nicolas': 1355,
    'San Pedro Apartado': 1355,
    'San Quintin': 1355,
    'San Sebastian': 1355,
    'San Vicente': 1355,
    'Sanlibo': 1355,
    'Santa': 1355,
    'Santa Barbara': 1355,
    'Santa Catalina': 1355,
    'Santa Cruz': 1355,
    'Santa Lucia': 1355,
    'Santa Maria': 1355,
    'Santiago': 1355,
    'Santo Domingo': 1355,
    'Santo Tomas': 1355,
    'Santol': 1355,
    'Sarrat': 1355,
    'Sigay': 1355,
    'Sinait': 1355,
    'Sison': 1355,
    'Solsona': 1355,
    'Sonquil': 1355,
    'Sual': 1355,
    'Subusub': 1355,
    'Sudipen': 1355,
    'Sugpon': 1355,
    'Sumabnit': 1355,
    'Suso': 1355,
    'Suyo': 1355,
    'Tablac': 1355,
    'Tabug': 1355,
    'Tagudin': 1355,
    'Talospatang': 1355,
    'Taloy': 1355,
    'Tamayo': 1355,
    'Tamorong': 1355,
    'Tandoc': 1355,
    'Tanolong': 1355,
    'Tayug': 1355,
    'Tebag East': 1355,
    'Telbang': 1355,
    'Tiep': 1355,
    'Toboy': 1355,
    'Tobuan': 1355,
    'Tococ East': 1355,
    'Tocok': 1355,
    'Tombod': 1355,
    'Tondol': 1355,
    'Toritori': 1355,
    'Tubao': 1355,
    'Umanday Centro': 1355,
    'Umingan': 1355,
    'Unzad': 1355,
    'Urbiztondo': 1355,
    'Urdaneta': 1355,
    'Uyong': 1355,
    'Vigan': 1355,
    'Villanueva': 1355,
    'Villasis': 1355,
    'Vintar': 1355,
    'Zaragoza': 1355,
    'Caloocan': 1347,
    'Calumpang': 1347,
    'Dasmariñas Village': 1347,
    'Ermita': 1347,
    'Intramuros': 1347,
    'Las Piñas': 1347,
    'Makati': 1347,
    'Malabon': 1347,
    'Malate': 1347,
    'Mandaluyong': 1347,
    'Manila': 1347,
    'Marikina': 1347,
    'Muntinlupa': 1347,
    'National Capital Region': 1347,
    'Navotas': 1347,
    'Niugan': 1347,
    'Paco': 1347,
    'Pandacan': 1347,
    'Parañaque': 1347,
    'Pasay': 1347,
    'Pasig': 1347,
    'Pateros': 1347,
    'Port Area': 1347,
    'Quezon City': 1347,
    'Quiapo': 1347,
    'Sambayanihan People  s Village': 1347,
    'San Juan': 1347,
    'San Miguel': 1347,
    'Santa Ana': 1347,
    'Santa Cruz': 1347,
    'Singkamas': 1347,
    'Taguig': 1347,
    'Tanza': 1347,
    'Tondo': 1347,
    'Valenzuela': 1347,
    'Abuyon': 1356,
    'Aga': 1356,
    'Agdangan': 1356,
    'Agoncillo': 1356,
    'Alabat': 1356,
    'Alaminos': 1356,
    'Alfonso': 1356,
    'Aliang': 1356,
    'Alitagtag': 1356,
    'Alupay': 1356,
    'Amadeo': 1356,
    'Ambulong': 1356,
    'Angono': 1356,
    'Antipolo': 1356,
    'Anuling': 1356,
    'Aplaya': 1356,
    'Atimonan': 1356,
    'Aurora': 1356,
    'Aya': 1356,
    'Ayusan Uno': 1356,
    'Bacoor': 1356,
    'Bagalangit': 1356,
    'Bagombong': 1356,
    'Bagong Pagasa': 1356,
    'Bagupaye': 1356,
    'Balagtasin': 1356,
    'Balayan': 1356,
    'Balele': 1356,
    'Balete': 1356,
    'Balibago': 1356,
    'Balite Segundo': 1356,
    'Balitoc': 1356,
    'Banaba': 1356,
    'Banalo': 1356,
    'Banaybanay': 1356,
    'Banilad': 1356,
    'Bantilan': 1356,
    'Banugao': 1356,
    'Baras': 1356,
    'Batangas': 1356,
    'Batas': 1356,
    'Bauan': 1356,
    'Bautista': 1356,
    'Bay': 1356,
    'Baybayin': 1356,
    'Biga': 1356,
    'Bignay Uno': 1356,
    'Bilaran': 1356,
    'Bilog-Bilog': 1356,
    'Binahaan': 1356,
    'Biñan': 1356,
    'Binangonan': 1356,
    'Binay': 1356,
    'Binubusan': 1356,
    'Binulasan': 1356,
    'Bitangan': 1356,
    'Bitin': 1356,
    'Bolboc': 1356,
    'Bolo': 1356,
    'Boot': 1356,
    'Bosdak': 1356,
    'Bugaan': 1356,
    'Bukal': 1356,
    'Bukal Sur': 1356,
    'Bulacnin': 1356,
    'Bulihan': 1356,
    'Bungahan': 1356,
    'Bungoy': 1356,
    'Burdeos': 1356,
    'Cabanbanan': 1356,
    'Cabatang': 1356,
    'Cabay': 1356,
    'Cabuyao': 1356,
    'Cagsiay': 1356,
    'Cainta': 1356,
    'Calaca': 1356,
    'Calamba': 1356,
    'Calantas': 1356,
    'Calatagan': 1356,
    'Calauag': 1356,
    'Calauan': 1356,
    'Calilayan': 1356,
    'Calubcub Dos': 1356,
    'Calumpang': 1356,
    'Cambuga': 1356,
    'Camohaguin': 1356,
    'Camp Flora': 1356,
    'Candelaria': 1356,
    'Capuluan': 1356,
    'Cardona': 1356,
    'Carmona': 1356,
    'Casay': 1356,
    'Castañas': 1356,
    'Casuguran': 1356,
    'Catanauan': 1356,
    'Cavinti': 1356,
    'Cavite City': 1356,
    'Cawayan': 1356,
    'Cigaras': 1356,
    'Concepcion Ibaba': 1356,
    'Cuenca': 1356,
    'Dagatan': 1356,
    'Daraitan': 1356,
    'Dasmariñas': 1356,
    'Dayap': 1356,
    'Dayapan': 1356,
    'Del Monte': 1356,
    'Dinahican': 1356,
    'Dolores': 1356,
    'Famy': 1356,
    'General Emilio Aguinaldo': 1356,
    'General Luna': 1356,
    'General Nakar': 1356,
    'General Trias': 1356,
    'Guinayangan, Fourth District of Quezon': 1356,
    'Guisguis': 1356,
    'Gulod': 1356,
    'Gumaca': 1356,
    'Gumian': 1356,
    'Guyam Malaki': 1356,
    'Halayhay': 1356,
    'Halayhayin': 1356,
    'Haligue': 1356,
    'Hondagua': 1356,
    'Hukay': 1356,
    'Ibaan': 1356,
    'Ibabang Tayuman': 1356,
    'Ilihan': 1356,
    'Imus': 1356,
    'Indang': 1356,
    'Infanta': 1356,
    'Inicbulan': 1356,
    'Isabang': 1356,
    'Jalajala': 1356,
    'Janagdong': 1356,
    'Janopol': 1356,
    'Javalera': 1356,
    'Kabulusan': 1356,
    'Kalayaan': 1356,
    'Kanluran': 1356,
    'Kapatalan': 1356,
    'Karligan': 1356,
    'Kawit': 1356,
    'Kaytitinga': 1356,
    'Kiloloran': 1356,
    'Kinalaglagan': 1356,
    'Kinatakutan': 1356,
    'Lacdayan': 1356,
    'Laiya': 1356,
    'Lalig': 1356,
    'Lapolapo': 1356,
    'Laurel': 1356,
    'Lemery': 1356,
    'Lian': 1356,
    'Libato': 1356,
    'Lilio': 1356,
    'Lipa City': 1356,
    'Lipahan': 1356,
    'Lobo': 1356,
    'Looc': 1356,
    'Lopez': 1356,
    'Los Baños': 1356,
    'Lucban': 1356,
    'Lucena': 1356,
    'Lucsuhin': 1356,
    'Luisiana': 1356,
    'Luksuhin': 1356,
    'Lumbang': 1356,
    'Lumbangan': 1356,
    'Lumil': 1356,
    'Luntal': 1356,
    'Lusacan': 1356,
    'Mabini': 1356,
    'Mabitac': 1356,
    'Mabunga': 1356,
    'Macalamcam A': 1356,
    'Macalelon': 1356,
    'Madulao': 1356,
    'Magallanes': 1356,
    'Magdalena': 1356,
    'Magsaysay': 1356,
    'Maguyam': 1356,
    'Mahabang Parang': 1356,
    'Mainit Norte': 1356,
    'Majayjay': 1356,
    'Malabag': 1356,
    'Malabanan': 1356,
    'Malabanban Norte': 1356,
    'Malainen Luma': 1356,
    'Malanday': 1356,
    'Malaruhatan': 1356,
    'Malaya': 1356,
    'Malicboy': 1356,
    'Malinao Ilaya': 1356,
    'Malvar': 1356,
    'Mamala': 1356,
    'Mamatid': 1356,
    'Mangas': 1356,
    'Mangero': 1356,
    'Manggahan': 1356,
    'Mapulo': 1356,
    'Mapulot': 1356,
    'Maragondon': 1356,
    'Marao': 1356,
    'Masalukot Uno': 1356,
    'Masapang': 1356,
    'Masaya': 1356,
    'Mataas Na Kahoy': 1356,
    'Matagbak': 1356,
    'Matala': 1356,
    'Mataywanac': 1356,
    'Matingain': 1356,
    'Mauban': 1356,
    'Maugat West': 1356,
    'Maulawin': 1356,
    'Mendez-Nuñez': 1356,
    'Montecillo': 1356,
    'Morong': 1356,
    'Mozon': 1356,
    'Mulanay': 1356,
    'Mulauin': 1356,
    'Nagcarlan': 1356,
    'Naic': 1356,
    'Nasugbu': 1356,
    'Navotas': 1356,
    'Noveleta': 1356,
    'Paagahan': 1356,
    'Padre Burgos': 1356,
    'Padre Garcia': 1356,
    'Paete': 1356,
    'Pagbilao': 1356,
    'Pagsañgahan': 1356,
    'Pagsanjan': 1356,
    'Paiisa': 1356,
    'Pakil': 1356,
    'Palahanan Uno': 1356,
    'Palangue': 1356,
    'Pangao': 1356,
    'Pangil': 1356,
    'Panikihan': 1356,
    'Pansol': 1356,
    'Pansoy': 1356,
    'Pantay Na Matanda': 1356,
    'Pantijan No 2': 1356,
    'Panukulan': 1356,
    'Paradahan': 1356,
    'Pasong Kawayan Primero': 1356,
    'Patabog': 1356,
    'Patnanungan': 1356,
    'Patuto': 1356,
    'Payapa': 1356,
    'Perez': 1356,
    'Pila': 1356,
    'Pililla': 1356,
    'Pinagsibaan': 1356,
    'Pinugay': 1356,
    'Pitogo': 1356,
    'Plaridel': 1356,
    'Poctol': 1356,
    'Polillo': 1356,
    'Prinza': 1356,
    'Progreso': 1356,
    'Province of Batangas': 1356,
    'Province of Cavite': 1356,
    'Province of Laguna': 1356,
    'Province of Quezon': 1356,
    'Province of Rizal': 1356,
    'Pulangbato': 1356,
    'Pulo': 1356,
    'Pulong Santa Cruz': 1356,
    'Punta': 1356,
    'Puting Kahoy': 1356,
    'Putingkahoy': 1356,
    'Putol': 1356,
    'Quezon': 1356,
    'Quilo-quilo': 1356,
    'Quipot': 1356,
    'Quisao': 1356,
    'Real': 1356,
    'Rizal': 1356,
    'Rodriguez': 1356,
    'Rosario': 1356,
    'Sabang': 1356,
    'Sampaloc': 1356,
    'Sampiro': 1356,
    'San Andres': 1356,
    'San Antonio': 1356,
    'San Carlos': 1356,
    'San Celestio': 1356,
    'San Diego': 1356,
    'San Francisco': 1356,
    'San Gregorio': 1356,
    'San Isidro': 1356,
    'San Joaquin': 1356,
    'San Jose': 1356,
    'San Juan': 1356,
    'San Luis': 1356,
    'San Mateo': 1356,
    'San Miguel': 1356,
    'San Narciso': 1356,
    'San Nicolas': 1356,
    'San Pablo': 1356,
    'San Pascual': 1356,
    'San Pedro': 1356,
    'San Pedro One': 1356,
    'San Rafael': 1356,
    'San Vicente': 1356,
    'Santa Catalina': 1356,
    'Santa Catalina Norte': 1356,
    'Santa Catalina Sur': 1356,
    'Santa Cecilia': 1356,
    'Santa Clara': 1356,
    'Santa Cruz': 1356,
    'Santa Lucia': 1356,
    'Santa Maria': 1356,
    'Santa Rita Aplaya': 1356,
    'Santa Rosa': 1356,
    'Santa Teresita': 1356,
    'Santiago': 1356,
    'Santisimo Rosario': 1356,
    'Santo Niño': 1356,
    'Santo Tomas': 1356,
    'Santor': 1356,
    'Sariaya': 1356,
    'Sibulan': 1356,
    'Sico Uno': 1356,
    'Silang': 1356,
    'Silongin': 1356,
    'Sinala': 1356,
    'Siniloan': 1356,
    'Sinisian': 1356,
    'Solo': 1356,
    'Subic': 1356,
    'Taal': 1356,
    'Tagaytay': 1356,
    'Tagbacan Ibaba': 1356,
    'Tagkawayan Sabang': 1356,
    'Tala': 1356,
    'Talaga': 1356,
    'Talahib Payap': 1356,
    'Talahiban I': 1356,
    'Talaibon': 1356,
    'Talipan': 1356,
    'Talisay': 1356,
    'Tanauan': 1356,
    'Tanay': 1356,
    'Tanza': 1356,
    'Tayabas': 1356,
    'Tayabas Ibaba': 1356,
    'Taysan': 1356,
    'Taytay': 1356,
    'Taywanak Ilaya': 1356,
    'Teresa': 1356,
    'Ternate': 1356,
    'Tiaong': 1356,
    'Tignoan': 1356,
    'Tingloy': 1356,
    'Tipaz': 1356,
    'Toong': 1356,
    'Tranca': 1356,
    'Tuhian': 1356,
    'Tulay': 1356,
    'Tumalim': 1356,
    'Tuy': 1356,
    'Unisan': 1356,
    'Victoria': 1356,
    'Wawa': 1356,
    'Yuni': 1356,
    'Aborlan': 1354,
    'Abra de Ilog': 1354,
    'Adela': 1354,
    'Agcogon': 1354,
    'Agutaya': 1354,
    'Alad': 1354,
    'Alcantara': 1354,
    'Alemanguan': 1354,
    'Algeciras': 1354,
    'Alibug': 1354,
    'Anilao': 1354,
    'Apitong': 1354,
    'Apurawan': 1354,
    'Araceli': 1354,
    'Aramawayan': 1354,
    'Aramayuan': 1354,
    'Aurora': 1354,
    'Babug': 1354,
    'Baco': 1354,
    'Bacungan': 1354,
    'Bagong Sikat': 1354,
    'Baheli': 1354,
    'Balabac': 1354,
    'Balanacan': 1354,
    'Balatero': 1354,
    'Balugo': 1354,
    'Banos': 1354,
    'Bansud': 1354,
    'Barahan': 1354,
    'Barong Barong': 1354,
    'Batarasa': 1354,
    'Batasan': 1354,
    'Bato': 1354,
    'Bayuin': 1354,
    'Bintacay': 1354,
    'Boac': 1354,
    'Buenavista': 1354,
    'Bulalacao': 1354,
    'Buluang': 1354,
    'Bunog': 1354,
    'Burirao': 1354,
    'Buyabod': 1354,
    'Cabacao': 1354,
    'Cabra': 1354,
    'Cagayan': 1354,
    'Cagayancillo': 1354,
    'Caigangan': 1354,
    'Cajidiocan': 1354,
    'Cajimos': 1354,
    'Calamundingan': 1354,
    'Calapan': 1354,
    'Calatrava': 1354,
    'Calatugas': 1354,
    'Calauag': 1354,
    'Calintaan': 1354,
    'Caminauit': 1354,
    'Cantel': 1354,
    'Canubing No 2': 1354,
    'Caramay': 1354,
    'Carmen': 1354,
    'Caruray': 1354,
    'Casian': 1354,
    'Cawayan': 1354,
    'Concepcion': 1354,
    'Conduaga': 1354,
    'Corcuera': 1354,
    'Coron': 1354,
    'Cuyo': 1354,
    'Dapawan': 1354,
    'Daykitin': 1354,
    'Dobdoban': 1354,
    'Dulangan': 1354,
    'Dumaran': 1354,
    'El Nido': 1354,
    'Eraan': 1354,
    'España': 1354,
    'Estrella': 1354,
    'Evangelista': 1354,
    'Ferrol': 1354,
    'Gabawan': 1354,
    'Gasan': 1354,
    'Gloria': 1354,
    'Guinlo': 1354,
    'Harrison': 1354,
    'Ipil': 1354,
    'Ipilan': 1354,
    'Irahuan': 1354,
    'Iraray': 1354,
    'Irirum': 1354,
    'Isugod': 1354,
    'La Curva': 1354,
    'Labasan': 1354,
    'Labog': 1354,
    'Lanas': 1354,
    'Laylay': 1354,
    'Leuteboro': 1354,
    'Libas': 1354,
    'Libertad': 1354,
    'Ligaya': 1354,
    'Limanancong': 1354,
    'Limon': 1354,
    'Looc': 1354,
    'Lubang': 1354,
    'Lumangbayan': 1354,
    'Magbay': 1354,
    'Magdiwang': 1354,
    'Magsaysay': 1354,
    'Mainit': 1354,
    'Malamig': 1354,
    'Malibago': 1354,
    'Maliig': 1354,
    'Malitbog': 1354,
    'Maluanluan': 1354,
    'Mamburao': 1354,
    'Manaul': 1354,
    'Mangarine': 1354,
    'Mansalay': 1354,
    'Masaguisi': 1354,
    'Masiga': 1354,
    'Mauhao': 1354,
    'Mogpog': 1354,
    'Nagiba': 1354,
    'Narra': 1354,
    'Naujan': 1354,
    'New Agutaya': 1354,
    'Odala': 1354,
    'Odiong': 1354,
    'Odiongan': 1354,
    'Osmeña': 1354,
    'Paclolo': 1354,
    'Paluan': 1354,
    'Pambisan': 1354,
    'Panacan': 1354,
    'Panalingaan': 1354,
    'Pancol': 1354,
    'Pañgobilian': 1354,
    'Pangulayan': 1354,
    'Panique': 1354,
    'Panitian': 1354,
    'Panlaitan': 1354,
    'Pato-o': 1354,
    'Pili': 1354,
    'Pinagsabangan': 1354,
    'Pinamalayan': 1354,
    'Pitogo': 1354,
    'Plaridel': 1354,
    'Pola': 1354,
    'Port Barton': 1354,
    'Province of Marinduque': 1354,
    'Province of Mindoro Occidental': 1354,
    'Province of Mindoro Oriental': 1354,
    'Province of Palawan': 1354,
    'Province of Romblon': 1354,
    'Puerto Galera': 1354,
    'Puerto Princesa': 1354,
    'Punang': 1354,
    'Punta': 1354,
    'Quezon': 1354,
    'Quinabigan': 1354,
    'Ransang': 1354,
    'Rio Tuba': 1354,
    'Rizal': 1354,
    'Romblon': 1354,
    'Roxas': 1354,
    'Saaban': 1354,
    'Sabang': 1354,
    'Sablayan': 1354,
    'Sagana': 1354,
    'Salcedo': 1354,
    'Salvacion': 1354,
    'San Agustin': 1354,
    'San Aquilino': 1354,
    'San Fernando': 1354,
    'San Jose': 1354,
    'San Mariano': 1354,
    'San Miguel': 1354,
    'San Pedro': 1354,
    'San Teodoro': 1354,
    'San Vicente': 1354,
    'Santa Brigida': 1354,
    'Santa Cruz': 1354,
    'Santa Fe': 1354,
    'Santa Maria': 1354,
    'Santa Teresa': 1354,
    'Saraza': 1354,
    'Socorro': 1354,
    'Sogod': 1354,
    'Suba': 1354,
    'Sumagui': 1354,
    'Tabinay': 1354,
    'Tabon': 1354,
    'Tacligan': 1354,
    'Taclobo': 1354,
    'Tagbak': 1354,
    'Tagbita': 1354,
    'Tagburos': 1354,
    'Tagusao': 1354,
    'Tambong': 1354,
    'Tampayan': 1354,
    'Tangal': 1354,
    'Tarusan': 1354,
    'Tayaman': 1354,
    'Taytay': 1354,
    'Tigui': 1354,
    'Tiguion': 1354,
    'Tiguisan': 1354,
    'Tilik': 1354,
    'Tiniguiban': 1354,
    'Tomingad': 1354,
    'Torrijos': 1354,
    'Tuban': 1354,
    'Tugdan': 1354,
    'Tumarbong': 1354,
    'Victoria': 1354,
    'Vigo': 1354,
    'Wawa': 1354,
    'Yook': 1354,
    'Alabel': 1310,
    'Alamada': 1310,
    'Amas': 1310,
    'Bagontapay': 1310,
    'Baguer': 1310,
    'Baliton': 1310,
    'Balogo': 1310,
    'Banawa': 1310,
    'Bañga': 1310,
    'Banisilan': 1310,
    'Bantogon': 1310,
    'Barongis': 1310,
    'Basak': 1310,
    'Batasan': 1310,
    'Batutitik': 1310,
    'Bau': 1310,
    'Bayasong': 1310,
    'Bialong': 1310,
    'Biwang': 1310,
    'Blingkong': 1310,
    'Buadtasan': 1310,
    'Bual': 1310,
    'Buawan': 1310,
    'Buayan': 1310,
    'Bukay Pait': 1310,
    'Bulatukan': 1310,
    'Burias': 1310,
    'Busok': 1310,
    'Carmen': 1310,
    'Carpenter Hill': 1310,
    'Cebuano': 1310,
    'Colongulo': 1310,
    'Columbio': 1310,
    'Conel': 1310,
    'Cotabato City': 1310,
    'Daguma': 1310,
    'Dahay': 1310,
    'Daliao': 1310,
    'Damawato': 1310,
    'Dansuli': 1310,
    'Digkilaan': 1310,
    'Dualing': 1310,
    'Dukay': 1310,
    'Dumaguil': 1310,
    'Dunguan': 1310,
    'Esperanza': 1310,
    'Gansing': 1310,
    'General Santos': 1310,
    'Glad': 1310,
    'Glamang': 1310,
    'Glan': 1310,
    'Glan Peidu': 1310,
    'Gocoton': 1310,
    'Guinsang-an': 1310,
    'Ilaya': 1310,
    'Iligan City': 1310,
    'Isulan': 1310,
    'Kabacan': 1310,
    'Kabalen': 1310,
    'Kablalan': 1310,
    'Kalaisan': 1310,
    'Kalamangog': 1310,
    'Kalamansig': 1310,
    'Kalandagan': 1310,
    'Kamanga': 1310,
    'Kapatan': 1310,
    'Kapaya': 1310,
    'Kapingkong': 1310,
    'Katangawan': 1310,
    'Katubao': 1310,
    'Kiamba': 1310,
    'Kidapawan': 1310,
    'Kipalbig': 1310,
    'Kisante': 1310,
    'Kiupo': 1310,
    'Klinan': 1310,
    'Kolumbug': 1310,
    'Koronadal': 1310,
    'Kudanding': 1310,
    'Kulaman': 1310,
    'Labu-o': 1310,
    'Laguilayan': 1310,
    'Lake Sebu': 1310,
    'Lala': 1310,
    'Lamba': 1310,
    'Lambayong': 1310,
    'Lambontong': 1310,
    'Lamian': 1310,
    'Lampari': 1310,
    'Lampitak': 1310,
    'Lapuz': 1310,
    'Libas': 1310,
    'Liberty': 1310,
    'Libungan': 1310,
    'Liliongan': 1310,
    'Limbalod': 1310,
    'Limulan': 1310,
    'Linan': 1310,
    'Linao': 1310,
    'Lopez Jaena': 1310,
    'Lumatil': 1310,
    'Lumazal': 1310,
    'Lumuyon': 1310,
    'Lun Pequeño': 1310,
    'Lunen': 1310,
    'Maan': 1310,
    'Maasim': 1310,
    'Mabay': 1310,
    'Mabini': 1310,
    'Magpet': 1310,
    'Maguling': 1310,
    'Maibu': 1310,
    'Maindang': 1310,
    'Maitum': 1310,
    'Makilala': 1310,
    'Malamote': 1310,
    'Malandag': 1310,
    'Malapag': 1310,
    'Malapatan': 1310,
    'Malasila': 1310,
    'Malbang': 1310,
    'Malinao': 1310,
    'Malingao': 1310,
    'Malisbeng': 1310,
    'Malitubog': 1310,
    'Maltana': 1310,
    'Maluñgun': 1310,
    'Mamali': 1310,
    'Manaulanan': 1310,
    'Manga': 1310,
    'Manuangan': 1310,
    'Manuel Roxas': 1310,
    'Marbel': 1310,
    'Mariano Marcos': 1310,
    'Matalam': 1310,
    'Matiompong': 1310,
    'Midsayap': 1310,
    'Minapan': 1310,
    'Mindupok': 1310,
    'Nalus': 1310,
    'New Cebu': 1310,
    'New Iloilo': 1310,
    'New Lagao': 1310,
    'New Panay': 1310,
    'Noling': 1310,
    'Norala': 1310,
    'Nunguan': 1310,
    'Osias': 1310,
    'Paatan': 1310,
    'Pagalungan': 1310,
    'Pagangan': 1310,
    'Palian': 1310,
    'Palimbang': 1310,
    'Palkan': 1310,
    'Pamantingan': 1310,
    'Panay': 1310,
    'Pangyan': 1310,
    'Paraiso': 1310,
    'Patindeguen': 1310,
    'Pedtad': 1310,
    'Pigcawayan': 1310,
    'Pikit': 1310,
    'Pimbalayan': 1310,
    'Polo': 1310,
    'Polomolok': 1310,
    'Polonoling': 1310,
    'President Quirino': 1310,
    'President Roxas': 1310,
    'Province of Cotabato': 1310,
    'Province of Sarangani': 1310,
    'Province of South Cotabato': 1310,
    'Province of Sultan Kudarat': 1310,
    'Puloypuloy': 1310,
    'Punolu': 1310,
    'Puricay': 1310,
    'Ragandang': 1310,
    'Rogongon': 1310,
    'Rotonda': 1310,
    'Sadsalan': 1310,
    'Saguing': 1310,
    'Salimbao': 1310,
    'Salunayan': 1310,
    'Salvador': 1310,
    'Sampao': 1310,
    'San Miguel': 1310,
    'San Vicente': 1310,
    'Sangay': 1310,
    'Santo Niño': 1310,
    'Sapad': 1310,
    'Sapu Padidu': 1310,
    'Silway 7': 1310,
    'Sinolon': 1310,
    'Sulit': 1310,
    'Surallah': 1310,
    'Suyan': 1310,
    'Tacurong': 1310,
    'Taguisa': 1310,
    'Taluya': 1310,
    'Tambak': 1310,
    'Tambilil': 1310,
    'Tamnag': 1310,
    'Tamontaka': 1310,
    'Tampakan': 1310,
    'Tañgo': 1310,
    'Tantangan': 1310,
    'Telafas': 1310,
    'Teresita': 1310,
    'Tinagacan': 1310,
    'Tinoto': 1310,
    'Tomado': 1310,
    'Tran': 1310,
    'Tuka': 1310,
    'Tulunan': 1310,
    'Tupi': 1310,
    'Tuyan': 1310,
    'Upper Klinan': 1310,
    'Upper San Mateo': 1310,
    'Villamor': 1310,
    'Alicia': 1362,
    'Aurora': 1362,
    'Balagon': 1362,
    'Barcelona': 1362,
    'Batu': 1362,
    'Bayog': 1362,
    'Binuatan': 1362,
    'Bolong': 1362,
    'Buenavista': 1362,
    'Bunguiao': 1362,
    'Buug': 1362,
    'Cabaluay': 1362,
    'Calabasa': 1362,
    'Caracal': 1362,
    'Culianan': 1362,
    'Curuan': 1362,
    'Dalangin': 1362,
    'Danlugan': 1362,
    'Dapitan': 1362,
    'Dawa-Dawa': 1362,
    'Del Monte': 1362,
    'Dicayong': 1362,
    'Dimataling': 1362,
    'Diplahan': 1362,
    'Dipolo': 1362,
    'Dipolog': 1362,
    'Disod': 1362,
    'Dulian': 1362,
    'Dumalinao': 1362,
    'Dumingag': 1362,
    'East Migpulao': 1362,
    'Ganyangan': 1362,
    'Gubaan': 1362,
    'Guiniculalay': 1362,
    'Guipos': 1362,
    'Gutalac': 1362,
    'Ilaya': 1362,
    'Ipil': 1362,
    'Irasan': 1362,
    'Josefina': 1362,
    'Kabasalan': 1362,
    'Kagawasan': 1362,
    'Kalawit': 1362,
    'Kalian': 1362,
    'Katipunan': 1362,
    'Kawayan': 1362,
    'Kipit': 1362,
    'Kumalarang': 1362,
    'La Dicha': 1362,
    'Labangan': 1362,
    'Labason': 1362,
    'Labuan': 1362,
    'Lamisahan': 1362,
    'Landang Laum': 1362,
    'Langatian': 1362,
    'Laparay': 1362,
    'Lapuyan': 1362,
    'Legrada': 1362,
    'Leon Postigo': 1362,
    'Liloy': 1362,
    'Limaong': 1362,
    'Limpapa': 1362,
    'Linay': 1362,
    'Lingasan': 1362,
    'Lintangan': 1362,
    'Lumbayan': 1362,
    'Lumbog': 1362,
    'Mabuhay': 1362,
    'Mahayag': 1362,
    'Malangas': 1362,
    'Malayal': 1362,
    'Malim': 1362,
    'Mandih': 1362,
    'Mangusu': 1362,
    'Manicahan': 1362,
    'Manukan': 1362,
    'Margos': 1362,
    'Margosatubig': 1362,
    'Mercedes': 1362,
    'Midsalip': 1362,
    'Molave': 1362,
    'Monching': 1362,
    'Muricay': 1362,
    'Muti': 1362,
    'Naga': 1362,
    'Olingan': 1362,
    'Olutanga': 1362,
    'Pagadian': 1362,
    'Palomoc': 1362,
    'Panubigan': 1362,
    'Patawag': 1362,
    'Payao': 1362,
    'Pitogo': 1362,
    'Polanco': 1362,
    'Ponot': 1362,
    'Province of  Zamboanga del Sur': 1362,
    'Province of Zamboanga del Norte': 1362,
    'Province of Zamboanga Sibugay': 1362,
    'Quinipot': 1362,
    'Ramon Magsaysay': 1362,
    'Rancheria Payau': 1362,
    'Recodo': 1362,
    'Robonkon': 1362,
    'Sagacad': 1362,
    'Salug': 1362,
    'San Miguel': 1362,
    'San Pablo': 1362,
    'Sangali': 1362,
    'Seres': 1362,
    'Sergio Osmeña Sr': 1362,
    'Siari': 1362,
    'Siay': 1362,
    'Siayan': 1362,
    'Sibuco': 1362,
    'Sibulao': 1362,
    'Sibutao': 1362,
    'Sindangan': 1362,
    'Sinubong': 1362,
    'Siocon': 1362,
    'Siraway': 1362,
    'Sumalig': 1362,
    'Tabina': 1362,
    'Tagasilay': 1362,
    'Taguitic': 1362,
    'Talabaan': 1362,
    'Talisayan': 1362,
    'Taluksangay': 1362,
    'Talusan': 1362,
    'Tambulig': 1362,
    'Tampilisan': 1362,
    'Tawagan': 1362,
    'Tigbao': 1362,
    'Tigpalay': 1362,
    'Tigtabon': 1362,
    'Tiguha': 1362,
    'Timonan': 1362,
    'Tiparak': 1362,
    'Titay': 1362,
    'Tolosa': 1362,
    'Tucuran': 1362,
    'Tungawan': 1362,
    'Vitali': 1362,
    'Zamboanga': 1362,
    'Babiak': 1634,
    'Baranów': 1634,
    'Baranowo': 1634,
    'Białośliwie': 1634,
    'Blizanów': 1634,
    'Bojanowo': 1634,
    'Bojanowo Stare': 1634,
    'Borek Wielkopolski': 1634,
    'Bralin': 1634,
    'Brdów': 1634,
    'Brenno': 1634,
    'Brodnica': 1634,
    'Broniszewice': 1634,
    'Brudzew': 1634,
    'Budzyń': 1634,
    'Buk': 1634,
    'Chocz': 1634,
    'Chodów': 1634,
    'Chodzież': 1634,
    'Chrzypsko Wielkie': 1634,
    'Czajków': 1634,
    'Czapury': 1634,
    'Czarnków': 1634,
    'Czempiń': 1634,
    'Czermin': 1634,
    'Czerniejewo': 1634,
    'Czerwonak': 1634,
    'Dąbie': 1634,
    'Damasławek': 1634,
    'Daszewice': 1634,
    'Dobra': 1634,
    'Dobrzyca': 1634,
    'Dolsk': 1634,
    'Dominowo': 1634,
    'Dopiewo': 1634,
    'Doruchów': 1634,
    'Drawsko': 1634,
    'Duszniki': 1634,
    'Gizałki': 1634,
    'Gniezno': 1634,
    'Golina': 1634,
    'Gołańcz': 1634,
    'Gołuchów': 1634,
    'Gorzyce Wielkie': 1634,
    'Gostyń': 1634,
    'Grabów nad Prosną': 1634,
    'Granowo': 1634,
    'Grodziec': 1634,
    'Grodzisk Wielkopolski': 1634,
    'Grzegorzew': 1634,
    'Jabłonna': 1634,
    'Janków Przygodzki': 1634,
    'Jaraczewo': 1634,
    'Jarocin': 1634,
    'Jastrowie': 1634,
    'Jutrosin': 1634,
    'Kaczory': 1634,
    'Kalisz': 1634,
    'Kamieniec': 1634,
    'Kawęczyn': 1634,
    'Kazimierz Biskupi': 1634,
    'Kaźmierz': 1634,
    'Kępno': 1634,
    'Kiszkowo': 1634,
    'Kleczew': 1634,
    'Kleszczewo': 1634,
    'Kłecko': 1634,
    'Kłodawa': 1634,
    'Kobyla Góra': 1634,
    'Kobylin': 1634,
    'Kobylnica': 1634,
    'Kołaczkowo': 1634,
    'Koło': 1634,
    'Komorniki': 1634,
    'Konary': 1634,
    'Konin': 1634,
    'Kórnik': 1634,
    'Kościan': 1634,
    'Kościelec': 1634,
    'Kostrzyn': 1634,
    'Kotlin': 1634,
    'Koźmin Wielkopolski': 1634,
    'Koźminek': 1634,
    'Krajenka': 1634,
    'Kramsk': 1634,
    'Kraszewice': 1634,
    'Krobia': 1634,
    'Krosno': 1634,
    'Krotoszyn': 1634,
    'Krzemieniewo': 1634,
    'Krzykosy': 1634,
    'Krzywiń': 1634,
    'Krzyż Wielkopolski': 1634,
    'Książ Wielkopolski': 1634,
    'Kuślin': 1634,
    'Kwilcz': 1634,
    'Lądek': 1634,
    'Leszno': 1634,
    'Licheń Stary': 1634,
    'Lipka': 1634,
    'Lipno': 1634,
    'Lisków': 1634,
    'Lubasz': 1634,
    'Luboń': 1634,
    'Lwówek': 1634,
    'Łęka Opatowska': 1634,
    'Łobżenica': 1634,
    'Łubowo': 1634,
    'Malanów': 1634,
    'Margonin': 1634,
    'Miasteczko Krajeńskie': 1634,
    'Miedzichowo': 1634,
    'Międzychód': 1634,
    'Miejska Górka': 1634,
    'Mieleszyn': 1634,
    'Mieścisko': 1634,
    'Mikstat': 1634,
    'Miłosław': 1634,
    'Mosina': 1634,
    'Murowana Goślina': 1634,
    'Nekla': 1634,
    'Niechanowo': 1634,
    'Nowe Miasto nad Wartą': 1634,
    'Nowe Skalmierzyce': 1634,
    'Nowy Tomyśl': 1634,
    'Oborniki': 1634,
    'Obrzycko': 1634,
    'Odolanów': 1634,
    'Okonek': 1634,
    'Olszówka': 1634,
    'Opalenica': 1634,
    'Opatów': 1634,
    'Opatówek': 1634,
    'Orchowo': 1634,
    'Osieczna': 1634,
    'Osiek Mały': 1634,
    'Osiek nad Notecią': 1634,
    'Ostroróg': 1634,
    'Ostrów Wielkopolski': 1634,
    'Ostrowite': 1634,
    'Ostrzeszów': 1634,
    'Pakosław': 1634,
    'Pamiątkowo': 1634,
    'Pecna': 1634,
    'Pępowo': 1634,
    'Perzów': 1634,
    'Piaski': 1634,
    'Piła': 1634,
    'Pleszew': 1634,
    'Plewiska': 1634,
    'Pniewy': 1634,
    'Pobiedziska': 1634,
    'Pogorzela': 1634,
    'Połajewo': 1634,
    'Poniec': 1634,
    'Powiat chodzieski': 1634,
    'Powiat czarnkowsko-trzcianecki': 1634,
    'Powiat gnieźnieński': 1634,
    'Powiat gostyński': 1634,
    'Powiat grodziski': 1634,
    'Powiat jarociński': 1634,
    'Powiat kaliski': 1634,
    'Powiat kępiński': 1634,
    'Powiat kolski': 1634,
    'Powiat koniński': 1634,
    'Powiat kościański': 1634,
    'Powiat krotoszyński': 1634,
    'Powiat leszczyński': 1634,
    'Powiat międzychodzki': 1634,
    'Powiat nowotomyski': 1634,
    'Powiat obornicki': 1634,
    'Powiat ostrowski': 1634,
    'Powiat ostrzeszowski': 1634,
    'Powiat pilski': 1634,
    'Powiat pleszewski': 1634,
    'Powiat poznański': 1634,
    'Powiat rawicki': 1634,
    'Powiat słupecki': 1634,
    'Powiat średzki': 1634,
    'Powiat śremski': 1634,
    'Powiat szamotulski': 1634,
    'Powiat turecki': 1634,
    'Powiat wągrowiecki': 1634,
    'Powiat wolsztyński': 1634,
    'Powiat wrzesiński': 1634,
    'Powiat złotowski': 1634,
    'Powidz': 1634,
    'Poznań': 1634,
    'Przedecz': 1634,
    'Przemęt': 1634,
    'Przygodzice': 1634,
    'Przykona': 1634,
    'Puszczykowo': 1634,
    'Pyzdry': 1634,
    'Rakoniewice': 1634,
    'Raszków': 1634,
    'Rawicz': 1634,
    'Rogalinek': 1634,
    'Rogoźno': 1634,
    'Rokietnica': 1634,
    'Rozdrażew': 1634,
    'Rychtal': 1634,
    'Rychwał': 1634,
    'Ryczywół': 1634,
    'Rydzyna': 1634,
    'Rzgów Pierwszy': 1634,
    'Siedlec': 1634,
    'Sieraków': 1634,
    'Sieroszewice': 1634,
    'Skalmierzyce': 1634,
    'Skoki': 1634,
    'Skulsk': 1634,
    'Ślesin': 1634,
    'Słupca': 1634,
    'Słupia pod Kępnem': 1634,
    'Śmigiel': 1634,
    'Śmiłowo': 1634,
    'Sompolno': 1634,
    'Sośnie': 1634,
    'Śrem': 1634,
    'Środa Wielkopolska': 1634,
    'Stare Miasto': 1634,
    'Stawiszyn': 1634,
    'Stęszew': 1634,
    'Strzałkowo': 1634,
    'Suchy Las': 1634,
    'Sulmierzyce': 1634,
    'Swarzędz': 1634,
    'Święciechowa': 1634,
    'Sypniewo': 1634,
    'Szamocin': 1634,
    'Szamotuły': 1634,
    'Szydłowo': 1634,
    'Tarnówka': 1634,
    'Tarnowo Podgórne': 1634,
    'Trzcianka': 1634,
    'Trzcinica': 1634,
    'Trzemeszno': 1634,
    'Tuliszków': 1634,
    'Turek': 1634,
    'Ujście': 1634,
    'Wągrowiec': 1634,
    'Wapno': 1634,
    'Wieleń': 1634,
    'Wielichowo': 1634,
    'Wierzbinek': 1634,
    'Wijewo': 1634,
    'Wilczogóra': 1634,
    'Wilczyn': 1634,
    'Wilkowice': 1634,
    'Witaszyce': 1634,
    'Witkowo': 1634,
    'Władysławów': 1634,
    'Włoszakowice': 1634,
    'Wolsztyn': 1634,
    'Wronki': 1634,
    'Września': 1634,
    'Wyrzysk': 1634,
    'Wysoka': 1634,
    'Zagórów': 1634,
    'Zakrzewo': 1634,
    'Zaniemyśl': 1634,
    'Zbąszyń': 1634,
    'Zduny': 1634,
    'Żelazków': 1634,
    'Żerków': 1634,
    'Złotniki': 1634,
    'Złotów': 1634,
    'Aleksandrów Kujawski': 1625,
    'Barcin': 1625,
    'Bartniczka': 1625,
    'Baruchowo': 1625,
    'Białe Błota': 1625,
    'Bobrowniki': 1625,
    'Bobrowo': 1625,
    'Boniewo': 1625,
    'Brodnica': 1625,
    'Brześć Kujawski': 1625,
    'Brzozie': 1625,
    'Brzuze': 1625,
    'Bukowiec': 1625,
    'Bydgoszcz': 1625,
    'Bytoń': 1625,
    'Cekcyn': 1625,
    'Chełmno': 1625,
    'Chełmża': 1625,
    'Choceń': 1625,
    'Chodecz': 1625,
    'Chrostkowo': 1625,
    'Ciechocin': 1625,
    'Ciechocinek': 1625,
    'Czerniewice': 1625,
    'Czernikowo': 1625,
    'Dąbrowa': 1625,
    'Dąbrowa Chełmińska': 1625,
    'Dębowa Łąka': 1625,
    'Dobre': 1625,
    'Dobrzyń nad Wisłą': 1625,
    'Drzycim': 1625,
    'Fabianki': 1625,
    'Fordon': 1625,
    'Gąsawa': 1625,
    'Gniewkowo': 1625,
    'Golub-Dobrzyń': 1625,
    'Górzno': 1625,
    'Gostycyn': 1625,
    'Grudziądz': 1625,
    'Gruta': 1625,
    'Inowrocław': 1625,
    'Izbica Kujawska': 1625,
    'Jabłonowo Pomorskie': 1625,
    'Janikowo': 1625,
    'Janowiec Wielkopolski': 1625,
    'Jeżewo': 1625,
    'Jeziora Wielkie': 1625,
    'Kamień Krajeński': 1625,
    'Kcynia': 1625,
    'Kęsowo': 1625,
    'Kikół': 1625,
    'Koronowo': 1625,
    'Kowal': 1625,
    'Kowalewo Pomorskie': 1625,
    'Kruszwica': 1625,
    'Lipno': 1625,
    'Lisewo': 1625,
    'Lniano': 1625,
    'Lubanie': 1625,
    'Lubicz Dolny': 1625,
    'Lubicz Górny': 1625,
    'Lubień Kujawski': 1625,
    'Lubiewo': 1625,
    'Lubraniec': 1625,
    'Łabiszyn': 1625,
    'Łasin': 1625,
    'Łubianka': 1625,
    'Łysomice': 1625,
    'Mogilno': 1625,
    'Mrocza': 1625,
    'Nakło nad Notecią': 1625,
    'Nieszawa': 1625,
    'Nowa Wieś Wielka': 1625,
    'Nowe': 1625,
    'Obrowo': 1625,
    'Osie': 1625,
    'Osięciny': 1625,
    'Osiek': 1625,
    'Osielsko': 1625,
    'Ostrowite': 1625,
    'Pakość': 1625,
    'Piotrków Kujawski': 1625,
    'Płużnica': 1625,
    'Powiat aleksandrowski': 1625,
    'Powiat brodnicki': 1625,
    'Powiat bydgoski': 1625,
    'Powiat chełmiński': 1625,
    'Powiat golubsko-dobrzyński': 1625,
    'Powiat grudziądzki': 1625,
    'Powiat inowrocławski': 1625,
    'Powiat lipnowski': 1625,
    'Powiat mogileński': 1625,
    'Powiat nakielski': 1625,
    'Powiat radziejowski': 1625,
    'Powiat rypiński': 1625,
    'Powiat sępoleński': 1625,
    'Powiat świecki': 1625,
    'Powiat toruński': 1625,
    'Powiat tucholski': 1625,
    'Powiat wąbrzeski': 1625,
    'Powiat włocławski': 1625,
    'Powiat żniński': 1625,
    'Pruszcz': 1625,
    'Raciążek': 1625,
    'Radomin': 1625,
    'Radziejów': 1625,
    'Radzyń Chełmiński': 1625,
    'Rogowo': 1625,
    'Rogóźno': 1625,
    'Rypin': 1625,
    'Sadki': 1625,
    'Sępólno Krajeńskie': 1625,
    'Sicienko': 1625,
    'Skępe': 1625,
    'Skrwilno': 1625,
    'Ślesin': 1625,
    'Śliwice': 1625,
    'Solec Kujawski': 1625,
    'Sośno': 1625,
    'Strzelno': 1625,
    'Świecie': 1625,
    'Świecie nad Osą': 1625,
    'Świedziebnia': 1625,
    'Świekatowo': 1625,
    'Sypniewo': 1625,
    'Szubin': 1625,
    'Tłuchowo': 1625,
    'Topólka': 1625,
    'Toruń': 1625,
    'Tuchola': 1625,
    'Unisław': 1625,
    'Wąbrzeźno': 1625,
    'Waganiec': 1625,
    'Wąpielsk': 1625,
    'Warlubie': 1625,
    'Więcbork': 1625,
    'Wielgie': 1625,
    'Wielka Nieszawka': 1625,
    'Wierzchosławice': 1625,
    'Włocławek': 1625,
    'Zbójno': 1625,
    'Zławieś Wielka': 1625,
    'Złotniki Kujawskie': 1625,
    'Żnin': 1625,
    'Alwernia': 1635,
    'Andrychów': 1635,
    'Babice': 1635,
    'Bachowice': 1635,
    'Balice': 1635,
    'Balin': 1635,
    'Barwałd Górny': 1635,
    'Barwałd Średni': 1635,
    'Bębło': 1635,
    'Bęczarka': 1635,
    'Białka': 1635,
    'Białka Tatrzańska': 1635,
    'Biały Dunajec': 1635,
    'Biecz': 1635,
    'Bieńkówka': 1635,
    'Binarowa': 1635,
    'Biskupice Radłowskie': 1635,
    'Bobowa': 1635,
    'Bochnia': 1635,
    'Bolechowice': 1635,
    'Bolęcin': 1635,
    'Bolesław': 1635,
    'Borek': 1635,
    'Borzęcin': 1635,
    'Borzęta': 1635,
    'Brodła': 1635,
    'Brody': 1635,
    'Brzączowice': 1635,
    'Brzesko': 1635,
    'Brzeszcze': 1635,
    'Brzezinka': 1635,
    'Brzeźnica': 1635,
    'Budzów': 1635,
    'Bukowina Tatrzańska': 1635,
    'Bukowno': 1635,
    'Bulowice': 1635,
    'Bystra': 1635,
    'Chełmek': 1635,
    'Chełmiec': 1635,
    'Chocznia': 1635,
    'Chrzanów': 1635,
    'Chyżne': 1635,
    'Ciężkowice': 1635,
    'Czarna Góra': 1635,
    'Czarnochowice': 1635,
    'Czarny Dunajec': 1635,
    'Czchów': 1635,
    'Czernichów': 1635,
    'Czułów': 1635,
    'Dąbrowa Tarnowska': 1635,
    'Dębno': 1635,
    'Dobczyce': 1635,
    'Dobra': 1635,
    'Dziewin': 1635,
    'Filipowice': 1635,
    'Frydman': 1635,
    'Frydrychowice': 1635,
    'Gdów': 1635,
    'Giebułtów': 1635,
    'Gierałtowice': 1635,
    'Głogoczów': 1635,
    'Gnojnik': 1635,
    'Gorenice': 1635,
    'Gorlice': 1635,
    'Gręboszów': 1635,
    'Grojec': 1635,
    'Gromnik': 1635,
    'Gruszów Wielki': 1635,
    'Grybów': 1635,
    'Grzechynia': 1635,
    'Harbutowice': 1635,
    'Inwałd': 1635,
    'Iwkowa': 1635,
    'Izdebnik': 1635,
    'Jabłonka': 1635,
    'Jadowniki': 1635,
    'Jadowniki Mokre': 1635,
    'Janowice': 1635,
    'Jaroszowice': 1635,
    'Jasień': 1635,
    'Jasienica': 1635,
    'Jastrzębia': 1635,
    'Jawornik': 1635,
    'Jerzmanowice': 1635,
    'Jodłówka': 1635,
    'Jodłówka-Wałki': 1635,
    'Jordanów': 1635,
    'Juszczyn': 1635,
    'Kalwaria Zebrzydowska': 1635,
    'Kamień': 1635,
    'Kamienica': 1635,
    'Kamionka Wielka': 1635,
    'Kasina Wielka': 1635,
    'Kaszów': 1635,
    'Kęty': 1635,
    'Klecza Dolna': 1635,
    'Klikuszowa': 1635,
    'Klimontów': 1635,
    'Klucze': 1635,
    'Kluszkowce': 1635,
    'Kłaj': 1635,
    'Kobylanka': 1635,
    'Kokotów': 1635,
    'Korzenna': 1635,
    'Kościelisko': 1635,
    'Koszyce': 1635,
    'Koszyce Wielkie': 1635,
    'Kozłów': 1635,
    'Kraków': 1635,
    'Krościenko nad Dunajcem': 1635,
    'Krośnica': 1635,
    'Krynica-Zdrój': 1635,
    'Kryspinów': 1635,
    'Krzczonów': 1635,
    'Krzęcin': 1635,
    'Krzeczów': 1635,
    'Krzeszów': 1635,
    'Krzeszowice': 1635,
    'Krzyszkowice': 1635,
    'Krzywaczka': 1635,
    'Kuków': 1635,
    'Kwaczała': 1635,
    'Lachowice': 1635,
    'Lanckorona': 1635,
    'Lasek': 1635,
    'Laskowa': 1635,
    'Leńcze': 1635,
    'Leśnica': 1635,
    'Libertów': 1635,
    'Libiąż': 1635,
    'Limanowa': 1635,
    'Lipinki': 1635,
    'Lipnica Mała': 1635,
    'Lipnica Wielka': 1635,
    'Lipnik': 1635,
    'Lisia Góra': 1635,
    'Liszki': 1635,
    'Lubień': 1635,
    'Lubomierz': 1635,
    'Ludźmierz': 1635,
    'Luszowice': 1635,
    'Łabowa': 1635,
    'Łącko': 1635,
    'Łączany': 1635,
    'Łapanów': 1635,
    'Łapczyca': 1635,
    'Łapsze Niżne': 1635,
    'Łętownia': 1635,
    'Łopuszna': 1635,
    'Łososina Dolna': 1635,
    'Łukowa': 1635,
    'Łukowica': 1635,
    'Łużna': 1635,
    'Maków Podhalański': 1635,
    'Malec': 1635,
    'Maniowy': 1635,
    'Maszkienice': 1635,
    'Mędrzechów': 1635,
    'Mętków': 1635,
    'Michałowice': 1635,
    'Miechów': 1635,
    'Miechów Charsznica': 1635,
    'Miękinia': 1635,
    'Mników': 1635,
    'Modlnica': 1635,
    'Modlniczka': 1635,
    'Mogilany': 1635,
    'Moszczenica': 1635,
    'Mszana Dolna': 1635,
    'Mszana Górna': 1635,
    'Muszyna': 1635,
    'Myślachowice': 1635,
    'Myślenice': 1635,
    'Naprawa': 1635,
    'Nawojowa': 1635,
    'Nidek': 1635,
    'Niedomice': 1635,
    'Niedzica': 1635,
    'Niedźwiedź': 1635,
    'Niepołomice': 1635,
    'Nowa Góra': 1635,
    'Nowa Wieś': 1635,
    'Nowe Brzesko': 1635,
    'Nowy Sącz': 1635,
    'Nowy Targ': 1635,
    'Nowy Wiśnicz': 1635,
    'Ochojno': 1635,
    'Ochotnica Dolna': 1635,
    'Okocim': 1635,
    'Olesno': 1635,
    'Olkusz': 1635,
    'Olszana': 1635,
    'Olszówka': 1635,
    'Ołpiny': 1635,
    'Osieczany': 1635,
    'Osiek': 1635,
    'Osielec': 1635,
    'Ostrężnica': 1635,
    'Ostrowsko': 1635,
    'Oświęcim': 1635,
    'Palcza': 1635,
    'Pawlikowice': 1635,
    'Pcim': 1635,
    'Piekary': 1635,
    'Piekielnik': 1635,
    'Piwniczna-Zdrój': 1635,
    'Pleśna': 1635,
    'Płaza': 1635,
    'Podegrodzie': 1635,
    'Podłęże': 1635,
    'Podwilk': 1635,
    'Pogórska Wola': 1635,
    'Pogorzyce': 1635,
    'Polanka Wielka': 1635,
    'Ponikiew': 1635,
    'Porąbka Uszewska': 1635,
    'Poręba': 1635,
    'Poręba Spytkowska': 1635,
    'Poręba Wielka': 1635,
    'Poronin': 1635,
    'Powiat bocheński': 1635,
    'Powiat brzeski': 1635,
    'Powiat chrzanowski': 1635,
    'Powiat dąbrowski': 1635,
    'Powiat gorlicki': 1635,
    'Powiat krakowski': 1635,
    'Powiat limanowski': 1635,
    'Powiat miechowski': 1635,
    'Powiat myślenicki': 1635,
    'Powiat nowosądecki': 1635,
    'Powiat nowotarski': 1635,
    'Powiat olkuski': 1635,
    'Powiat oświęcimski': 1635,
    'Powiat proszowicki': 1635,
    'Powiat suski': 1635,
    'Powiat tarnowski': 1635,
    'Powiat tatrzański': 1635,
    'Powiat wadowicki': 1635,
    'Powiat wielicki': 1635,
    'Proszowice': 1635,
    'Przecieszyn': 1635,
    'Przeciszów': 1635,
    'Przeginia': 1635,
    'Przyborów': 1635,
    'Przytkowice': 1635,
    'Psary': 1635,
    'Raba Wyżna': 1635,
    'Rabka-Zdrój': 1635,
    'Racławice': 1635,
    'Rączna': 1635,
    'Radgoszcz': 1635,
    'Radłów': 1635,
    'Radocza': 1635,
    'Radziszów': 1635,
    'Rajsko': 1635,
    'Regulice': 1635,
    'Roczyny': 1635,
    'Rokiciny': 1635,
    'Ropa': 1635,
    'Rudawa': 1635,
    'Rudnik': 1635,
    'Rusocice': 1635,
    'Ryczów': 1635,
    'Ryglice': 1635,
    'Rytro': 1635,
    'Rząska': 1635,
    'Rzepiennik Strzyżewski': 1635,
    'Rzeszotary': 1635,
    'Rzezawa': 1635,
    'Rzozów': 1635,
    'Rzyki': 1635,
    'Sanka': 1635,
    'Sąspów': 1635,
    'Sękowa': 1635,
    'Sidzina': 1635,
    'Siedliska': 1635,
    'Siemiechów': 1635,
    'Sieniawa': 1635,
    'Siepraw': 1635,
    'Sieradza': 1635,
    'Skała': 1635,
    'Skawica': 1635,
    'Skawina': 1635,
    'Skawinki': 1635,
    'Skomielna Czarna': 1635,
    'Skrzydlna': 1635,
    'Skrzyszów': 1635,
    'Słomniki': 1635,
    'Słopnice': 1635,
    'Słupiec': 1635,
    'Smęgorzów': 1635,
    'Sosnowice': 1635,
    'Spytkowice': 1635,
    'Stanisław Dolny': 1635,
    'Stanisław Górny': 1635,
    'Stanisławice': 1635,
    'Stary Sącz': 1635,
    'Stary Wiśnicz': 1635,
    'Stronie': 1635,
    'Stróża': 1635,
    'Stryszawa': 1635,
    'Stryszów': 1635,
    'Sucha Beskidzka': 1635,
    'Sulęcin': 1635,
    'Sułkowice': 1635,
    'Sułoszowa': 1635,
    'Świątniki Górne': 1635,
    'Szaflary': 1635,
    'Szarów': 1635,
    'Szczawnica': 1635,
    'Szczucin': 1635,
    'Szczurowa': 1635,
    'Szerzyny': 1635,
    'Szynwałd': 1635,
    'Targanice': 1635,
    'Targowisko': 1635,
    'Tarnów': 1635,
    'Tarnowiec': 1635,
    'Tenczynek': 1635,
    'Tokarnia': 1635,
    'Tomaszkowice': 1635,
    'Tomice': 1635,
    'Toporzysko': 1635,
    'Trąbki': 1635,
    'Trzciana': 1635,
    'Trzebinia': 1635,
    'Trzebunia': 1635,
    'Trzemeśnia': 1635,
    'Tuchów': 1635,
    'Tylicz': 1635,
    'Tymbark': 1635,
    'Uście Gorlickie': 1635,
    'Wadowice': 1635,
    'Waksmund': 1635,
    'Wawrzeńczyce': 1635,
    'Węglówka': 1635,
    'Węgrzce Wielkie': 1635,
    'Wieliczka': 1635,
    'Wielka Wieś': 1635,
    'Wieprz': 1635,
    'Wierzchosławice': 1635,
    'Wietrzychowice': 1635,
    'Wiśniowa': 1635,
    'Witanowice': 1635,
    'Witkowice': 1635,
    'Włosienica': 1635,
    'Wojnicz': 1635,
    'Wola Batorska': 1635,
    'Wola Dębińska': 1635,
    'Wola Filipowska': 1635,
    'Wola Radziszowska': 1635,
    'Wola Zabierzowska': 1635,
    'Wolbrom': 1635,
    'Wołowice': 1635,
    'Woźniki': 1635,
    'Wrząsowice': 1635,
    'Wysoka': 1635,
    'Zabierzów': 1635,
    'Zabierzów Bocheński': 1635,
    'Żabno': 1635,
    'Zaborze': 1635,
    'Zagórnik': 1635,
    'Zagórze': 1635,
    'Zakliczyn': 1635,
    'Zakopane': 1635,
    'Zakrzów': 1635,
    'Zalas': 1635,
    'Żarki': 1635,
    'Zarzecze': 1635,
    'Zator': 1635,
    'Zawoja': 1635,
    'Zebrzydowice': 1635,
    'Żegocina': 1635,
    'Zembrzyce': 1635,
    'Zielonki': 1635,
    'Złota': 1635,
    'Zubrzyca Dolna': 1635,
    'Zubrzyca Górna': 1635,
    'Żurowa': 1635,
    'Bardo': 1629,
    'Bielany Wrocławskie': 1629,
    'Bielawa': 1629,
    'Bierutów': 1629,
    'Biskupin': 1629,
    'Bogatynia': 1629,
    'Boguszów-Gorce': 1629,
    'Bolesławiec': 1629,
    'Bolków': 1629,
    'Bozkow': 1629,
    'Brzeg Dolny': 1629,
    'Budzów': 1629,
    'Bystrzyca': 1629,
    'Bystrzyca Kłodzka': 1629,
    'Chocianów': 1629,
    'Chojnów': 1629,
    'Ciechów': 1629,
    'Cieplice Śląskie Zdrój': 1629,
    'Ciepłowody': 1629,
    'Cieszków': 1629,
    'Czarny Bór': 1629,
    'Czernica': 1629,
    'Dąbie': 1629,
    'Długołęka': 1629,
    'Dobroszyce': 1629,
    'Duszniki-Zdrój': 1629,
    'Dziadowa Kłoda': 1629,
    'Dziećmorowice': 1629,
    'Dzierżoniów': 1629,
    'Gądów Mały': 1629,
    'Gaworzyce': 1629,
    'Głogów': 1629,
    'Głuszyca': 1629,
    'Głuszyca Górna': 1629,
    'Gniechowice': 1629,
    'Góra': 1629,
    'Grabiszyn': 1629,
    'Grabiszynek': 1629,
    'Grębocice': 1629,
    'Gromadka': 1629,
    'Gryfów Śląski': 1629,
    'Henryków': 1629,
    'Janowice Wielkie': 1629,
    'Jarnołtów-Jerzmanowo': 1629,
    'Jawor': 1629,
    'Jaworzyna Śląska': 1629,
    'Jedlina-Zdrój': 1629,
    'Jelcz': 1629,
    'Jelcz Laskowice': 1629,
    'Jelenia Góra': 1629,
    'Jeżów Sudecki': 1629,
    'Jordanów Śląski': 1629,
    'Jugów': 1629,
    'Kamieniec Wrocławski': 1629,
    'Kamieniec Ząbkowicki': 1629,
    'Kamienna Góra': 1629,
    'Karłowice': 1629,
    'Karpacz': 1629,
    'Kąty Wrocławskie': 1629,
    'Kiełczów': 1629,
    'Kłodzko': 1629,
    'Kobierzyce': 1629,
    'Kotla': 1629,
    'Kowale-Popiele': 1629,
    'Kowary': 1629,
    'Kozanów': 1629,
    'Krośnice': 1629,
    'Krzeszów': 1629,
    'Krzyki': 1629,
    'Księże Małe-Księże Wielkie': 1629,
    'Kudowa-Zdrój': 1629,
    'Kunice': 1629,
    'Lądek-Zdrój': 1629,
    'Legnica': 1629,
    'Legnickie Pole': 1629,
    'Leśna': 1629,
    'Leśnica-Ratyń-Pustki': 1629,
    'Lewin Kłodzki': 1629,
    'Lubań': 1629,
    'Lubawka': 1629,
    'Lubin': 1629,
    'Lubomierz': 1629,
    'Ludwikowice Kłodzkie': 1629,
    'Lwówek Śląski': 1629,
    'Łagiewniki': 1629,
    'Łagów': 1629,
    'Malczyce': 1629,
    'Marciszów': 1629,
    'Marszowice': 1629,
    'Maślice Małe': 1629,
    'Maślice Wielkie': 1629,
    'Międzybórz': 1629,
    'Międzylesie': 1629,
    'Miękinia': 1629,
    'Mieroszów': 1629,
    'Milicz': 1629,
    'Miłkowice': 1629,
    'Mirków': 1629,
    'Mirsk': 1629,
    'Mrozów': 1629,
    'Mysłakowice': 1629,
    'Niemcza': 1629,
    'Nowa Ruda': 1629,
    'Nowogrodziec': 1629,
    'Oborniki Śląskie': 1629,
    'Oleśnica': 1629,
    'Olszanica': 1629,
    'Olszyna': 1629,
    'Oława': 1629,
    'Osiedle Henrykowskie': 1629,
    'Osiedle Kosmonautów': 1629,
    'Osiek': 1629,
    'Ostroszowice': 1629,
    'Partynice': 1629,
    'Paszowice': 1629,
    'Pawłowice-Kłokoczyce': 1629,
    'Piechowice': 1629,
    'Pieńsk': 1629,
    'Pieszyce': 1629,
    'Pilczyce': 1629,
    'Piława Górna': 1629,
    'Pisarzowice': 1629,
    'Podgórzyn': 1629,
    'Polanica-Zdrój': 1629,
    'Polkowice': 1629,
    'Poświętne': 1629,
    'Powiat bolesławiecki': 1629,
    'Powiat dzierżoniowski': 1629,
    'Powiat głogowski': 1629,
    'Powiat górowski': 1629,
    'Powiat jaworski': 1629,
    'Powiat jeleniogórski': 1629,
    'Powiat kamiennogórski': 1629,
    'Powiat kłodzki': 1629,
    'Powiat legnicki': 1629,
    'Powiat lubański': 1629,
    'Powiat lubiński': 1629,
    'Powiat lwówecki': 1629,
    'Powiat milicki': 1629,
    'Powiat oleśnicki': 1629,
    'Powiat oławski': 1629,
    'Powiat polkowicki': 1629,
    'Powiat średzki': 1629,
    'Powiat strzeliński': 1629,
    'Powiat świdnicki': 1629,
    'Powiat trzebnicki': 1629,
    'Powiat wałbrzyski': 1629,
    'Powiat wołowski': 1629,
    'Powiat wrocławski': 1629,
    'Powiat ząbkowicki': 1629,
    'Powiat zgorzelecki': 1629,
    'Powiat złotoryjski': 1629,
    'Powstańców Śląskich Wschód': 1629,
    'Powstańców Śląskich Zachód-Centrum Południow': 1629,
    'Pracze Odrzańskie-Janówek': 1629,
    'Prochowice': 1629,
    'Prusice': 1629,
    'Przemków': 1629,
    'Przeworno': 1629,
    'Psary': 1629,
    'Psie Pole Południe-Kiełczów': 1629,
    'Radków': 1629,
    'Radwanice': 1629,
    'Ratowice': 1629,
    'Rejon alei Kromera': 1629,
    'Rejon placu Grunwaldzkiego': 1629,
    'Rejon placu Świętego Macieja': 1629,
    'Rejon ulicy Borowskiej-Południe': 1629,
    'Rejon ulicy Klęczkowskiej': 1629,
    'Rejon ulicy Mieleckiej': 1629,
    'Rejon ulicy Saperów': 1629,
    'Rejon ulicy Traugutta': 1629,
    'Różanka-Polanka': 1629,
    'Rudna': 1629,
    'Ścinawa': 1629,
    'Sępolno': 1629,
    'Siechnice': 1629,
    'Siekierczyn': 1629,
    'Smolec': 1629,
    'Sobótka': 1629,
    'Sołtysowice': 1629,
    'Sosnówka': 1629,
    'Środa Śląska': 1629,
    'Stabłowice': 1629,
    'Stanowice': 1629,
    'Stara Kamienica': 1629,
    'Stare Bogaczowice': 1629,
    'Stoszowice': 1629,
    'Strachocin-Wojnów': 1629,
    'Stronie Śląskie': 1629,
    'Strzegom': 1629,
    'Strzelin': 1629,
    'Sulików': 1629,
    'Sułów': 1629,
    'Świdnica': 1629,
    'Świebodzice': 1629,
    'Świeradów-Zdrój': 1629,
    'Świerzawa': 1629,
    'Święta Katarzyna': 1629,
    'Swojczyce': 1629,
    'Syców': 1629,
    'Szczawno-Zdrój': 1629,
    'Szczepanów': 1629,
    'Szczytna': 1629,
    'Szklarska Poręba': 1629,
    'Trzebnica': 1629,
    'Twardogóra': 1629,
    'Tyniec Mały': 1629,
    'Uciechów': 1629,
    'Udanin': 1629,
    'Walim': 1629,
    'Wałbrzych': 1629,
    'Wąsosz': 1629,
    'Węgliniec': 1629,
    'Wiązów': 1629,
    'Widawa-Lipa Piotrowska-Polanowice': 1629,
    'Wierzbno': 1629,
    'Wilczyce': 1629,
    'Wilków': 1629,
    'Wińsko': 1629,
    'Wleń': 1629,
    'Wojcieszów': 1629,
    'Wojszyce': 1629,
    'Wołów': 1629,
    'Wrocław': 1629,
    'Ząbkowice Śląskie': 1629,
    'Zacisze': 1629,
    'Zagrodno': 1629,
    'Zakrzów': 1629,
    'Zalesie i Stadion': 1629,
    'Żarów': 1629,
    'Żerniki Wrocławskie': 1629,
    'Zgorzelec': 1629,
    'Zgorzelisko': 1629,
    'Ziębice': 1629,
    'Złotniki': 1629,
    'Złotoryja': 1629,
    'Złoty Stok': 1629,
    'Żmigród': 1629,
    'Żórawina': 1629,
    'Abramów': 1638,
    'Adamów': 1638,
    'Aleksandrów': 1638,
    'Annopol': 1638,
    'Baranów': 1638,
    'Batorz': 1638,
    'Bełżec': 1638,
    'Bełżyce': 1638,
    'Biała Podlaska': 1638,
    'Biłgoraj': 1638,
    'Biszcza': 1638,
    'Borki': 1638,
    'Borzechów': 1638,
    'Bychawa': 1638,
    'Chełm': 1638,
    'Chodel': 1638,
    'Chrzanów': 1638,
    'Cyców': 1638,
    'Czemierniki': 1638,
    'Dęblin': 1638,
    'Dębowa Kłoda': 1638,
    'Dołhobyczów': 1638,
    'Dorohusk': 1638,
    'Drelów': 1638,
    'Dubienka': 1638,
    'Dzwola': 1638,
    'Fajsławice': 1638,
    'Firlej': 1638,
    'Frampol': 1638,
    'Garbów': 1638,
    'Godziszów': 1638,
    'Godziszów Pierwszy': 1638,
    'Goraj': 1638,
    'Gorzków': 1638,
    'Gościeradów': 1638,
    'Grabowiec': 1638,
    'Hańsk': 1638,
    'Horodło': 1638,
    'Hrubieszów': 1638,
    'Izbica': 1638,
    'Jabłoń': 1638,
    'Jabłonna': 1638,
    'Jakubowice Murowane': 1638,
    'Janów Lubelski': 1638,
    'Janów Podlaski': 1638,
    'Janowiec': 1638,
    'Jarczów': 1638,
    'Jastków': 1638,
    'Jeziorzany': 1638,
    'Józefów': 1638,
    'Józefów nad Wisłą': 1638,
    'Kamionka': 1638,
    'Karczmiska': 1638,
    'Kazimierz Dolny': 1638,
    'Kłoczew': 1638,
    'Kock': 1638,
    'Kodeń': 1638,
    'Komarów-Osada': 1638,
    'Komarówka Podlaska': 1638,
    'Końskowola': 1638,
    'Konstantynów': 1638,
    'Kozubszczyzna': 1638,
    'Kraśniczyn': 1638,
    'Kraśnik': 1638,
    'Krasnobród': 1638,
    'Krasnystaw': 1638,
    'Krynice': 1638,
    'Krzczonów': 1638,
    'Krzywda': 1638,
    'Księżomierz': 1638,
    'Księżpol': 1638,
    'Kurów': 1638,
    'Leśna Podlaska': 1638,
    'Lubartów': 1638,
    'Lublin': 1638,
    'Lubycza Królewska': 1638,
    'Ludwin': 1638,
    'Łabunie': 1638,
    'Łaszczów': 1638,
    'Łaziska': 1638,
    'Łęczna': 1638,
    'Łomazy': 1638,
    'Łopiennik Górny': 1638,
    'Łuków': 1638,
    'Łukowa': 1638,
    'Markuszów': 1638,
    'Mełgiew': 1638,
    'Miączyn': 1638,
    'Michałów': 1638,
    'Michów': 1638,
    'Międzyrzec Podlaski': 1638,
    'Milanów': 1638,
    'Milejów': 1638,
    'Mircze': 1638,
    'Modliborzyce': 1638,
    'Nałęczów': 1638,
    'Niedrzwica Duża': 1638,
    'Niedźwiada': 1638,
    'Nielisz': 1638,
    'Niemce': 1638,
    'Nowodwór': 1638,
    'Obsza': 1638,
    'Opole Lubelskie': 1638,
    'Ostrów Lubelski': 1638,
    'Ostrówek': 1638,
    'Parczew': 1638,
    'Piaski': 1638,
    'Piszczac': 1638,
    'Podedwórze': 1638,
    'Pokrówka': 1638,
    'Poniatowa': 1638,
    'Potok Górny': 1638,
    'Potok Wielki': 1638,
    'Powiat bialski': 1638,
    'Powiat biłgorajski': 1638,
    'Powiat chełmski': 1638,
    'Powiat hrubieszowski': 1638,
    'Powiat janowski': 1638,
    'Powiat kraśnicki': 1638,
    'Powiat krasnostawski': 1638,
    'Powiat lubartowski': 1638,
    'Powiat lubelski': 1638,
    'Powiat łęczyński': 1638,
    'Powiat łukowski': 1638,
    'Powiat opolski': 1638,
    'Powiat parczewski': 1638,
    'Powiat puławski': 1638,
    'Powiat radzyński': 1638,
    'Powiat rycki': 1638,
    'Powiat świdnicki': 1638,
    'Powiat tomaszowski': 1638,
    'Powiat włodawski': 1638,
    'Powiat zamojski': 1638,
    'Przytoczno': 1638,
    'Puchaczów': 1638,
    'Puławy': 1638,
    'Rachanie': 1638,
    'Radecznica': 1638,
    'Radzyń Podlaski': 1638,
    'Rejowiec': 1638,
    'Rejowiec Fabryczny': 1638,
    'Rogóźno': 1638,
    'Rokitno': 1638,
    'Ruda-Huta': 1638,
    'Rudnik': 1638,
    'Rybczewice': 1638,
    'Ryki': 1638,
    'Rzeczyca': 1638,
    'Sawin': 1638,
    'Serniki': 1638,
    'Serokomla': 1638,
    'Siemień': 1638,
    'Siennica Różana': 1638,
    'Sitno': 1638,
    'Skierbieszów': 1638,
    'Sławatycze': 1638,
    'Sosnowica': 1638,
    'Sosnówka': 1638,
    'Spiczyn': 1638,
    'Stary Zamość': 1638,
    'Stężyca': 1638,
    'Stoczek Łukowski': 1638,
    'Strzyżowice': 1638,
    'Sułów': 1638,
    'Susiec': 1638,
    'Świdnik': 1638,
    'Szastarka': 1638,
    'Szczebrzeszyn': 1638,
    'Tarnawatka': 1638,
    'Tarnogród': 1638,
    'Telatyn': 1638,
    'Terespol': 1638,
    'Tereszpol': 1638,
    'Tomaszów Lubelski': 1638,
    'Trawniki': 1638,
    'Trzebieszów': 1638,
    'Trzydnik Duży': 1638,
    'Turobin': 1638,
    'Tyszowce': 1638,
    'Ulhówek': 1638,
    'Ułęż': 1638,
    'Urszulin': 1638,
    'Urzędów': 1638,
    'Uścimów Stary': 1638,
    'Wąwolnica': 1638,
    'Werbkowice': 1638,
    'Wilkołaz': 1638,
    'Wilków': 1638,
    'Wisznice': 1638,
    'Włodawa': 1638,
    'Wohyń': 1638,
    'Wojciechów': 1638,
    'Wojcieszków': 1638,
    'Wojsławice': 1638,
    'Wola Sernicka': 1638,
    'Wola Uhruska': 1638,
    'Wysokie': 1638,
    'Zakrzew': 1638,
    'Zakrzówek': 1638,
    'Zamch': 1638,
    'Zamość': 1638,
    'Żółkiewka': 1638,
    'Zwierzyniec': 1638,
    'Żyrzyn': 1638,
    'Babimost': 1631,
    'Bledzew': 1631,
    'Bobrowice': 1631,
    'Bogdaniec': 1631,
    'Bojadła': 1631,
    'Brody': 1631,
    'Brójce': 1631,
    'Bytnica': 1631,
    'Bytom Odrzański': 1631,
    'Cybinka': 1631,
    'Czerwieńsk': 1631,
    'Dąbie': 1631,
    'Deszczno': 1631,
    'Dobiegniew': 1631,
    'Drezdenko': 1631,
    'Gorzów Wielkopolski': 1631,
    'Górzyca': 1631,
    'Gozdnica': 1631,
    'Gubin': 1631,
    'Iłowa': 1631,
    'Jasień': 1631,
    'Jenin': 1631,
    'Kargowa': 1631,
    'Klenica': 1631,
    'Kłodawa': 1631,
    'Kolsko': 1631,
    'Konotop': 1631,
    'Kostrzyn nad Odrą': 1631,
    'Kożuchów': 1631,
    'Krosno Odrzańskie': 1631,
    'Krzeszyce': 1631,
    'Kunice Żarskie': 1631,
    'Lipinki Łużyckie': 1631,
    'Lubiszyn': 1631,
    'Lubniewice': 1631,
    'Lubrza': 1631,
    'Lubsko': 1631,
    'Łagów': 1631,
    'Łęknica': 1631,
    'Małomice': 1631,
    'Maszewo': 1631,
    'Międzylesie': 1631,
    'Międzyrzecz': 1631,
    'Nowa Sól': 1631,
    'Nowe Miasteczko': 1631,
    'Nowogród Bobrzański': 1631,
    'Ochla': 1631,
    'Ośno Lubuskie': 1631,
    'Otyń': 1631,
    'Powiat gorzowski': 1631,
    'Powiat krośnieński': 1631,
    'Powiat międzyrzecki': 1631,
    'Powiat nowosolski': 1631,
    'Powiat słubicki': 1631,
    'Powiat strzelecko-drezdenecki': 1631,
    'Powiat sulęciński': 1631,
    'Powiat świebodziński': 1631,
    'Powiat wschowski': 1631,
    'Powiat żagański': 1631,
    'Powiat żarski': 1631,
    'Powiat zielonogórski': 1631,
    'Przewóz': 1631,
    'Przyborów': 1631,
    'Przytoczna': 1631,
    'Pszczew': 1631,
    'Rzepin': 1631,
    'Santok': 1631,
    'Siedlisko': 1631,
    'Sieniawa': 1631,
    'Sieniawa Żarska': 1631,
    'Skąpe': 1631,
    'Skwierzyna': 1631,
    'Sława': 1631,
    'Słońsk': 1631,
    'Słubice': 1631,
    'Stare Kurowo': 1631,
    'Strzelce Krajeńskie': 1631,
    'Sulechów': 1631,
    'Świdnica': 1631,
    'Świebodzin': 1631,
    'Szczaniec': 1631,
    'Szlichtyngowa': 1631,
    'Szprotawa': 1631,
    'Torzym': 1631,
    'Trzciel': 1631,
    'Trzebiechów': 1631,
    'Trzebiel': 1631,
    'Tuplice': 1631,
    'Witnica': 1631,
    'Wschowa': 1631,
    'Wymiarki': 1631,
    'Zabór': 1631,
    'Żagań': 1631,
    'Żary': 1631,
    'Zbąszynek': 1631,
    'Zielona Góra': 1631,
    'Zwierzyń': 1631,
    'Aleksandrów': 1636,
    'Aleksandrów Łódzki': 1636,
    'Andrespol': 1636,
    'Będków': 1636,
    'Bedlno': 1636,
    'Bełchatów': 1636,
    'Biała Rawska': 1636,
    'Białaczów': 1636,
    'Bielawy': 1636,
    'Błaszki': 1636,
    'Bobrowniki': 1636,
    'Bolesławiec': 1636,
    'Bolimów': 1636,
    'Brąszewice': 1636,
    'Brójce': 1636,
    'Brzeziny': 1636,
    'Brzeźnio': 1636,
    'Buczek': 1636,
    'Budziszewice': 1636,
    'Burzenin': 1636,
    'Chąśno': 1636,
    'Cielądz': 1636,
    'Czarnocin': 1636,
    'Czarnożyły': 1636,
    'Czastary': 1636,
    'Czerniewice': 1636,
    'Dąbrowice': 1636,
    'Dalików': 1636,
    'Daszyna': 1636,
    'Dłutów': 1636,
    'Dmosin': 1636,
    'Dobroń': 1636,
    'Domaniewice': 1636,
    'Drużbice': 1636,
    'Drzewica': 1636,
    'Działoszyn': 1636,
    'Galewice': 1636,
    'Gałków Mały': 1636,
    'Gidle': 1636,
    'Głowno': 1636,
    'Głuchów': 1636,
    'Godzianów': 1636,
    'Gomunice': 1636,
    'Góra Świętej Małgorzaty': 1636,
    'Gorzkowice': 1636,
    'Goszczanów': 1636,
    'Grabica': 1636,
    'Grabów': 1636,
    'Inowłódz': 1636,
    'Jeżów': 1636,
    'Kamieńsk': 1636,
    'Kawęczyn Nowy': 1636,
    'Kiernozia': 1636,
    'Kleszczów': 1636,
    'Klonowa': 1636,
    'Kluki': 1636,
    'Kocierzew Południowy': 1636,
    'Koluszki': 1636,
    'Konstantynów Łódzki': 1636,
    'Kowiesy': 1636,
    'Krośniewice': 1636,
    'Krzyżanów': 1636,
    'Ksawerów': 1636,
    'Kutno': 1636,
    'Lgota Wielka': 1636,
    'Lipce Reymontowskie': 1636,
    'Lubochnia': 1636,
    'Lutomiersk': 1636,
    'Lututów': 1636,
    'Łanięta': 1636,
    'Łask': 1636,
    'Łęczyca': 1636,
    'Łęki Szlacheckie': 1636,
    'Łódź': 1636,
    'Łowicz': 1636,
    'Łubnice': 1636,
    'Łyszkowice': 1636,
    'Maków': 1636,
    'Mniszków': 1636,
    'Mokrsko': 1636,
    'Moszczenica': 1636,
    'Nieborów': 1636,
    'Nowe Ostrowy': 1636,
    'Opoczno': 1636,
    'Oporów': 1636,
    'Osjaków': 1636,
    'Ostrowy': 1636,
    'Ożarów': 1636,
    'Ozorków': 1636,
    'Pabianice': 1636,
    'Pajęczno': 1636,
    'Paradyż': 1636,
    'Parzęczew': 1636,
    'Pątnów': 1636,
    'Pęczniew': 1636,
    'Piątek': 1636,
    'Piotrków Trybunalski': 1636,
    'Pławno': 1636,
    'Poddębice': 1636,
    'Poświętne': 1636,
    'Powiat bełchatowski': 1636,
    'Powiat brzeziński': 1636,
    'Powiat kutnowski': 1636,
    'Powiat łaski': 1636,
    'Powiat łęczycki': 1636,
    'Powiat łódzki wschodni': 1636,
    'powiat Łowicki': 1636,
    'Powiat opoczyński': 1636,
    'Powiat pabianicki': 1636,
    'Powiat pajęczański': 1636,
    'Powiat piotrkowski': 1636,
    'Powiat poddębicki': 1636,
    'Powiat radomszczański': 1636,
    'Powiat rawski': 1636,
    'Powiat sieradzki': 1636,
    'Powiat skierniewicki': 1636,
    'Powiat tomaszowski': 1636,
    'Powiat wieluński': 1636,
    'Powiat wieruszowski': 1636,
    'Powiat zduńskowolski': 1636,
    'Powiat zgierski': 1636,
    'Przedbórz': 1636,
    'Radomsko': 1636,
    'Rawa Mazowiecka': 1636,
    'Ręczno': 1636,
    'Regnów': 1636,
    'Rogów': 1636,
    'Rokiciny': 1636,
    'Rokiciny-Kolonia': 1636,
    'Rozprza': 1636,
    'Rusiec': 1636,
    'Rzeczyca': 1636,
    'Rzgów': 1636,
    'Sadkowice': 1636,
    'Sędziejowice': 1636,
    'Siemkowice': 1636,
    'Sieradz': 1636,
    'Skierniewice': 1636,
    'Skomlin': 1636,
    'Sławno': 1636,
    'Słupia': 1636,
    'Sokolniki': 1636,
    'Starowa Góra': 1636,
    'Stryków': 1636,
    'Strzelce': 1636,
    'Strzelce Wielkie': 1636,
    'Sulejów': 1636,
    'Sulmierzyce': 1636,
    'Świnice Warckie': 1636,
    'Szadek': 1636,
    'Szczerców': 1636,
    'Tomaszów Mazowiecki': 1636,
    'Tuszyn': 1636,
    'Ujazd': 1636,
    'Uniejów': 1636,
    'Walichnowy': 1636,
    'Warta': 1636,
    'Wartkowice': 1636,
    'Widawa': 1636,
    'Wieluń': 1636,
    'Wieruszów': 1636,
    'Wierzchlas': 1636,
    'Witonia': 1636,
    'Wodzierady': 1636,
    'Wola Krzysztoporska': 1636,
    'Wolbórz': 1636,
    'Wróblew': 1636,
    'Zadzim': 1636,
    'Zapolice': 1636,
    'Żarnów': 1636,
    'Zduńska Wola': 1636,
    'Żelechlinek': 1636,
    'Zelów': 1636,
    'Zgierz': 1636,
    'Złoczew': 1636,
    'Żychlin': 1636,
    'Baboszewo': 1637,
    'Baniocha': 1637,
    'Baranowo': 1637,
    'Bemowo': 1637,
    'Biała': 1637,
    'Białobrzegi': 1637,
    'Białołeka': 1637,
    'Bielany': 1637,
    'Bielsk': 1637,
    'Bieniewice': 1637,
    'Bieżuń': 1637,
    'Błędów': 1637,
    'Błonie': 1637,
    'Bodzanów': 1637,
    'Boguty-Pianki': 1637,
    'Borkowice': 1637,
    'Borowie': 1637,
    'Brańszczyk': 1637,
    'Brochów': 1637,
    'Brody-Parcele': 1637,
    'Brok': 1637,
    'Brudzeń Duży': 1637,
    'Brwinów': 1637,
    'Bulkowo': 1637,
    'Cegłów': 1637,
    'Celestynów': 1637,
    'Ceranów': 1637,
    'Chlewiska': 1637,
    'Chorzele': 1637,
    'Chotcza': 1637,
    'Chynów': 1637,
    'Ciechanów': 1637,
    'Ciepielów': 1637,
    'Czarnia': 1637,
    'Czernice Borowe': 1637,
    'Czerwin': 1637,
    'Czerwińsk Nad Wisłą': 1637,
    'Czerwonka': 1637,
    'Dąbrówka': 1637,
    'Dębe Wielkie': 1637,
    'Długosiodło': 1637,
    'Dobre': 1637,
    'Domanice': 1637,
    'Drobin': 1637,
    'Duczki': 1637,
    'Dziekanów Leśny': 1637,
    'Dzierzążnia': 1637,
    'Dzierzgowo': 1637,
    'Gąbin': 1637,
    'Garbatka-Letnisko': 1637,
    'Garwolin': 1637,
    'Gąsocin': 1637,
    'Gielniów': 1637,
    'Glinojeck': 1637,
    'Gołymin-Ośrodek': 1637,
    'Góra Kalwaria': 1637,
    'Górzno': 1637,
    'Gostynin': 1637,
    'Goszczyn': 1637,
    'Goworowo': 1637,
    'Gózd': 1637,
    'Gozdowo': 1637,
    'Granica': 1637,
    'Grębków': 1637,
    'Grodzisk Mazowiecki': 1637,
    'Grójec': 1637,
    'Grudusk': 1637,
    'Guzów': 1637,
    'Gzy': 1637,
    'Halinów': 1637,
    'Hornówek': 1637,
    'Iłów': 1637,
    'Iłża': 1637,
    'Izabelin': 1637,
    'Jabłonna': 1637,
    'Jabłonna Lacka': 1637,
    'Jadów': 1637,
    'Jakubów': 1637,
    'Jasienica': 1637,
    'Jasieniec': 1637,
    'Jastrząb': 1637,
    'Jastrzębia': 1637,
    'Jedlińsk': 1637,
    'Jedlnia-Letnisko': 1637,
    'Jednorożec': 1637,
    'Joniec': 1637,
    'Józefosław': 1637,
    'Józefów': 1637,
    'Kabaty': 1637,
    'Kadzidło': 1637,
    'Kałuszyn': 1637,
    'Kampinos': 1637,
    'Karczew': 1637,
    'Karniewo': 1637,
    'Kazanów': 1637,
    'Kiełpin': 1637,
    'Klembów': 1637,
    'Klwów': 1637,
    'Kobyłka': 1637,
    'Kołbiel': 1637,
    'Komorów': 1637,
    'Konstancin-Jeziorna': 1637,
    'Korczew': 1637,
    'Korytnica': 1637,
    'Kosów Lacki': 1637,
    'Kotuń': 1637,
    'Kowala': 1637,
    'Kozienice': 1637,
    'Krasne': 1637,
    'Krasnosielc': 1637,
    'Krzynowłoga Mała': 1637,
    'Kuczbork-Osada': 1637,
    'Latowicz': 1637,
    'Legionowo': 1637,
    'Leszno': 1637,
    'Lesznowola': 1637,
    'Lipowiec Kościelny': 1637,
    'Lipsko': 1637,
    'Lubowidz': 1637,
    'Lutocin': 1637,
    'Łąck': 1637,
    'Łajski': 1637,
    'Łaskarzew': 1637,
    'Łochów': 1637,
    'Łomianki': 1637,
    'Łosice': 1637,
    'Łyse': 1637,
    'Maciejowice': 1637,
    'Maków Mazowiecki': 1637,
    'Mała Wieś': 1637,
    'Małkinia Górna': 1637,
    'Marki': 1637,
    'Maszewo Duże': 1637,
    'Miastków Kościelny': 1637,
    'Michałów-Reginów': 1637,
    'Michałowice': 1637,
    'Miedzna': 1637,
    'Miętne': 1637,
    'Milanówek': 1637,
    'Mińsk Mazowiecki': 1637,
    'Mława': 1637,
    'Młodzieszyn': 1637,
    'Młynarze': 1637,
    'Mochowo': 1637,
    'Mogielnica': 1637,
    'Mokobody': 1637,
    'Mokotów': 1637,
    'Mordy': 1637,
    'Mrozy': 1637,
    'Mszczonów': 1637,
    'Mysiadło': 1637,
    'Myszyniec': 1637,
    'Nadarzyn': 1637,
    'Naruszewo': 1637,
    'Nasielsk': 1637,
    'Nieporęt': 1637,
    'Nowe Grocholice': 1637,
    'Nowe Lipiny': 1637,
    'Nowe Miasto': 1637,
    'Nowe Miasto nad Pilicą': 1637,
    'Nowy Duninów': 1637,
    'Nowy Dwór Mazowiecki': 1637,
    'Nur': 1637,
    'Obryte': 1637,
    'Ochota': 1637,
    'Odrzywół': 1637,
    'Ojrzeń': 1637,
    'Opinogóra Górna': 1637,
    'Orońsko': 1637,
    'Osieck': 1637,
    'Ostrołęka': 1637,
    'Ostrów Mazowiecka': 1637,
    'Ostrówek': 1637,
    'Otrębusy': 1637,
    'Otwock': 1637,
    'Owczarnia': 1637,
    'Ożarów Mazowiecki': 1637,
    'Pacyna': 1637,
    'Paprotnia': 1637,
    'Parysów': 1637,
    'Piaseczno': 1637,
    'Piastów': 1637,
    'Pilawa': 1637,
    'Pionki': 1637,
    'Płock': 1637,
    'Płoniawy-Bramura': 1637,
    'Płońsk': 1637,
    'Podebłocie': 1637,
    'Podkowa Leśna': 1637,
    'Pokrzywnica': 1637,
    'Policzna': 1637,
    'Pomiechówek': 1637,
    'Poświętne': 1637,
    'Potworów': 1637,
    'Powiat białobrzeski': 1637,
    'Powiat ciechanowski': 1637,
    'Powiat garwoliński': 1637,
    'Powiat gostyniński': 1637,
    'Powiat grodziski': 1637,
    'Powiat grójecki': 1637,
    'Powiat kozienicki': 1637,
    'Powiat legionowski': 1637,
    'Powiat lipski': 1637,
    'Powiat łosicki': 1637,
    'Powiat makowski': 1637,
    'Powiat miński': 1637,
    'Powiat mławski': 1637,
    'Powiat nowodworski': 1637,
    'Powiat ostrołęcki': 1637,
    'Powiat ostrowski': 1637,
    'Powiat otwocki': 1637,
    'Powiat piaseczyński': 1637,
    'Powiat płocki': 1637,
    'Powiat płoński': 1637,
    'Powiat pruszkowski': 1637,
    'Powiat przasnyski': 1637,
    'Powiat przysuski': 1637,
    'Powiat pułtuski': 1637,
    'Powiat radomski': 1637,
    'Powiat siedlecki': 1637,
    'Powiat sierpecki': 1637,
    'Powiat sochaczewski': 1637,
    'Powiat sokołowski': 1637,
    'Powiat szydłowiecki': 1637,
    'Powiat warszawski zachodni': 1637,
    'Powiat węgrowski': 1637,
    'Powiat wołomiński': 1637,
    'Powiat wyszkowski': 1637,
    'Powiat żuromiński': 1637,
    'Powiat zwoleński': 1637,
    'Powiat żyrardowski': 1637,
    'Praga Północ': 1637,
    'Praga Południe': 1637,
    'Prażmów': 1637,
    'Promna': 1637,
    'Pruszków': 1637,
    'Przasnysz': 1637,
    'Przesmyki': 1637,
    'Przyłęk': 1637,
    'Przysucha': 1637,
    'Przytyk': 1637,
    'Pułtusk': 1637,
    'Puszcza Mariańska': 1637,
    'Raciąż': 1637,
    'Radom': 1637,
    'Radzanów': 1637,
    'Radzanowo': 1637,
    'Radziejowice': 1637,
    'Radzymin': 1637,
    'Raszyn': 1637,
    'Regimin': 1637,
    'Rembertów': 1637,
    'Repki': 1637,
    'Rościszewo': 1637,
    'Różan': 1637,
    'Rusinów': 1637,
    'Rybie': 1637,
    'Rybno': 1637,
    'Ryczywół': 1637,
    'Rząśnik': 1637,
    'Rzeczniów': 1637,
    'Rzekuń': 1637,
    'Rzewnie': 1637,
    'Sabnie': 1637,
    'Sadowne': 1637,
    'Sanniki': 1637,
    'Sarnaki': 1637,
    'Serock': 1637,
    'Siedlce': 1637,
    'Siemiątkowo': 1637,
    'Siennica': 1637,
    'Sienno': 1637,
    'Sierpc': 1637,
    'Skaryszew': 1637,
    'Skórzec': 1637,
    'Słubice': 1637,
    'Słupno': 1637,
    'Sobienie Jeziory': 1637,
    'Sobolew': 1637,
    'Sochaczew': 1637,
    'Sochocin': 1637,
    'Sokołów Podlaski': 1637,
    'Solec Nad Wisłą': 1637,
    'Sońsk': 1637,
    'Śródmieście': 1637,
    'Stanisławów': 1637,
    'Stanisławów Pierwszy': 1637,
    'Stara Błotnica': 1637,
    'Stara Kornica': 1637,
    'Stare Babice': 1637,
    'Staroźreby': 1637,
    'Sterdyń': 1637,
    'Stoczek': 1637,
    'Strachówka': 1637,
    'Stromiec': 1637,
    'Strzegowo': 1637,
    'Stupsk': 1637,
    'Sucha': 1637,
    'Suchożebry': 1637,
    'Sulbiny Górne': 1637,
    'Sulejówek': 1637,
    'Sułkowice': 1637,
    'Świercze': 1637,
    'Sypniewo': 1637,
    'Szczutowo': 1637,
    'Szelków': 1637,
    'Szreńsk': 1637,
    'Szydłowiec': 1637,
    'Szydłowo': 1637,
    'Tarczyn': 1637,
    'Targówek': 1637,
    'Tczów': 1637,
    'Teresin': 1637,
    'Tłuszcz': 1637,
    'Trąbki': 1637,
    'Trablice': 1637,
    'Trojanów': 1637,
    'Troszyn': 1637,
    'Truskaw': 1637,
    'Ursus': 1637,
    'Ursynów': 1637,
    'Warka': 1637,
    'Warsaw': 1637,
    'Warszawa': 1637,
    'Wawer': 1637,
    'Węgrów': 1637,
    'Wesoła': 1637,
    'Wieczfnia Kościelna': 1637,
    'Wieliszew': 1637,
    'Wieniawa': 1637,
    'Wierzbica': 1637,
    'Wierzbno': 1637,
    'Wilanów': 1637,
    'Wilga': 1637,
    'Winnica': 1637,
    'Wiskitki': 1637,
    'Wiśniew': 1637,
    'Wiśniewo': 1637,
    'Włochy': 1637,
    'Wodynie': 1637,
    'Wola': 1637,
    'Wola Rębkowska': 1637,
    'Wolanów': 1637,
    'Wołomin': 1637,
    'Wyśmierzyce': 1637,
    'Wyszków': 1637,
    'Wyszogród': 1637,
    'Żabia Wola': 1637,
    'Żabieniec': 1637,
    'Ząbki': 1637,
    'Zakroczym': 1637,
    'Zakrzew': 1637,
    'Zalesie Górne': 1637,
    'Załuski': 1637,
    'Zatory': 1637,
    'Zawidz': 1637,
    'Zbuczyn': 1637,
    'Żelechów': 1637,
    'Zielonka': 1637,
    'Żoliborz': 1637,
    'Żuromin': 1637,
    'Zwoleń': 1637,
    'Żyrardów': 1637,
    'Baborów': 1622,
    'Biała': 1622,
    'Bierawa': 1622,
    'Bierdzany': 1622,
    'Branice': 1622,
    'Brzeg': 1622,
    'Byczyna': 1622,
    'Chmielowice': 1622,
    'Chróścice': 1622,
    'Chrościna': 1622,
    'Chrząstowice': 1622,
    'Cisek': 1622,
    'Czarnowąsy': 1622,
    'Dąbrowa': 1622,
    'Dalachów': 1622,
    'Długomiłowice': 1622,
    'Dobrodzień': 1622,
    'Dobrzeń Wielki': 1622,
    'Domaszowice': 1622,
    'Dziergowice': 1622,
    'Głogówek': 1622,
    'Głubczyce': 1622,
    'Głuchołazy': 1622,
    'Gogolin': 1622,
    'Górażdże': 1622,
    'Gorzów Śląski': 1622,
    'Grodków': 1622,
    'Izbicko': 1622,
    'Januszkowice': 1622,
    'Jemielnica': 1622,
    'Kamienica': 1622,
    'Kamiennik': 1622,
    'Kędzierzyn-Koźle': 1622,
    'Kietrz': 1622,
    'Kluczbork': 1622,
    'Kolonowskie': 1622,
    'Komprachcice': 1622,
    'Korfantów': 1622,
    'Koźle': 1622,
    'Krapkowice': 1622,
    'Kup': 1622,
    'Leśnica': 1622,
    'Lewin Brzeski': 1622,
    'Lubrza': 1622,
    'Lubsza': 1622,
    'Łąka Prudnicka': 1622,
    'Łambinowice': 1622,
    'Łosiów': 1622,
    'Łubniany': 1622,
    'Moszczanka': 1622,
    'Murów': 1622,
    'Nakło': 1622,
    'Namysłów': 1622,
    'Niemodlin': 1622,
    'Nysa': 1622,
    'Olesno': 1622,
    'Olszanka': 1622,
    'Opole': 1622,
    'Otmuchów': 1622,
    'Ozimek': 1622,
    'Paczków': 1622,
    'Pakosławice': 1622,
    'Pawłowiczki': 1622,
    'Pokój': 1622,
    'Polska Cerekiew': 1622,
    'Popielów': 1622,
    'Powiat brzeski': 1622,
    'Powiat głubczycki': 1622,
    'Powiat kędzierzyńsko-kozielski': 1622,
    'Powiat kluczborski': 1622,
    'Powiat krapkowicki': 1622,
    'Powiat namysłowski': 1622,
    'Powiat nyski': 1622,
    'Powiat oleski': 1622,
    'Powiat opolski': 1622,
    'Powiat prudnicki': 1622,
    'Powiat strzelecki': 1622,
    'Praszka': 1622,
    'Prószków': 1622,
    'Prudnik': 1622,
    'Racławice Śląskie': 1622,
    'Raszowa': 1622,
    'Reńska Wieś': 1622,
    'Rozwadza': 1622,
    'Sidzina': 1622,
    'Skarbimierz Osiedle': 1622,
    'Skorogoszcz': 1622,
    'Skoroszyce': 1622,
    'Sławięcice': 1622,
    'Strzelce Opolskie': 1622,
    'Strzeleczki': 1622,
    'Świerczów': 1622,
    'Tarnów Opolski': 1622,
    'Tułowice': 1622,
    'Twardawa': 1622,
    'Ujazd': 1622,
    'Walce': 1622,
    'Węgry': 1622,
    'Większyce': 1622,
    'Wilków': 1622,
    'Wołczyn': 1622,
    'Zawadzkie': 1622,
    'Zdzieszowice': 1622,
    'Zębowice': 1622,
    'Adamówka': 1626,
    'Albigowa': 1626,
    'Babica': 1626,
    'Baligród': 1626,
    'Baranów Sandomierski': 1626,
    'Besko': 1626,
    'Białobrzegi': 1626,
    'Bieliny': 1626,
    'Bircza': 1626,
    'Blizne': 1626,
    'Błażowa': 1626,
    'Boguchwała': 1626,
    'Bojanów': 1626,
    'Borowa': 1626,
    'Brzeźnica': 1626,
    'Brzostek': 1626,
    'Brzóza Królewska': 1626,
    'Brzóza Stadnicka': 1626,
    'Brzozów': 1626,
    'Brzyska': 1626,
    'Bukowsko': 1626,
    'Chmielnik': 1626,
    'Chorkówka': 1626,
    'Chwałowice': 1626,
    'Cieszanów': 1626,
    'Cisna': 1626,
    'Cmolas': 1626,
    'Czarna': 1626,
    'Czaszyn': 1626,
    'Czermin': 1626,
    'Czudec': 1626,
    'Dębica': 1626,
    'Dębno': 1626,
    'Dębów': 1626,
    'Dębowiec': 1626,
    'Długie': 1626,
    'Domaradz': 1626,
    'Dubiecko': 1626,
    'Dukla': 1626,
    'Dydnia': 1626,
    'Dynów': 1626,
    'Dzików Stary': 1626,
    'Dzikowiec': 1626,
    'Fredropol': 1626,
    'Frysztak': 1626,
    'Gać': 1626,
    'Giedlarowa': 1626,
    'Głogów Małopolski': 1626,
    'Głuchów': 1626,
    'Golcowa': 1626,
    'Górki': 1626,
    'Gorliczyna': 1626,
    'Górno': 1626,
    'Gorzyce': 1626,
    'Grabownica Starzeńska': 1626,
    'Grębów': 1626,
    'Grodzisko Dolne': 1626,
    'Grodzisko Górne': 1626,
    'Grzęska': 1626,
    'Gwoźnica Górna': 1626,
    'Haczów': 1626,
    'Handzlówka': 1626,
    'Harasiuki': 1626,
    'Horyniec-Zdrój': 1626,
    'Humniska': 1626,
    'Husów': 1626,
    'Hyżne': 1626,
    'Iwierzyce': 1626,
    'Iwonicz-Zdrój': 1626,
    'Jabłonica Polska': 1626,
    'Jabłonka': 1626,
    'Jagiełła': 1626,
    'Jarocin': 1626,
    'Jarosław': 1626,
    'Jasienica Rosielna': 1626,
    'Jasionów': 1626,
    'Jaśliska': 1626,
    'Jasło': 1626,
    'Jawornik': 1626,
    'Jawornik Polski': 1626,
    'Jedlicze': 1626,
    'Jeżowe': 1626,
    'Jodłowa': 1626,
    'Jodłówka': 1626,
    'Kamień': 1626,
    'Kańczuga': 1626,
    'Kępie Żaleszańskie': 1626,
    'Kielanówka': 1626,
    'Kolbuszowa': 1626,
    'Kołaczyce': 1626,
    'Komańcza': 1626,
    'Kończyce': 1626,
    'Konieczkowa': 1626,
    'Korczyna': 1626,
    'Kosina': 1626,
    'Kraczkowa': 1626,
    'Kramarzówka': 1626,
    'Krasiczyn': 1626,
    'Krasne': 1626,
    'Krempna': 1626,
    'Krościenko Wyżne': 1626,
    'Krosno': 1626,
    'Krzeczowice': 1626,
    'Krzemienica': 1626,
    'Krzeszów': 1626,
    'Krzywcza': 1626,
    'Kuryłówka': 1626,
    'Laszki': 1626,
    'Lecka': 1626,
    'Lesko': 1626,
    'Leżajsk': 1626,
    'Lipnica': 1626,
    'Lubaczów': 1626,
    'Lubenia': 1626,
    'Lutoryż': 1626,
    'Lutowiska': 1626,
    'Łańcut': 1626,
    'Łęki': 1626,
    'Łęki Dolne': 1626,
    'Łęki Górne': 1626,
    'Łętownia': 1626,
    'Łopuszka Wielka': 1626,
    'Majdan Królewski': 1626,
    'Manasterz': 1626,
    'Markowa': 1626,
    'Medyka': 1626,
    'Miejsce Piastowe': 1626,
    'Mielec': 1626,
    'Mirocin': 1626,
    'Narol': 1626,
    'Niebieszczany': 1626,
    'Niebocko': 1626,
    'Niebylec': 1626,
    'Niechobrz': 1626,
    'Niedźwiada': 1626,
    'Nienadowa': 1626,
    'Nisko': 1626,
    'Niwiska': 1626,
    'Nowa Dęba': 1626,
    'Nowa Sarzyna': 1626,
    'Nowe Sioło': 1626,
    'Nowosielce': 1626,
    'Nowosielce-Gniewosz': 1626,
    'Nowy Żmigród': 1626,
    'Nozdrzec': 1626,
    'Odrzykoń': 1626,
    'Oleszyce': 1626,
    'Olszanica': 1626,
    'Orły': 1626,
    'Orzechówka': 1626,
    'Osiek Jasielski': 1626,
    'Ostrów': 1626,
    'Padew Narodowa': 1626,
    'Pantalowice': 1626,
    'Pawłosiów': 1626,
    'Pilzno': 1626,
    'Piskorowice': 1626,
    'Polańczyk': 1626,
    'Połomia': 1626,
    'Poraż': 1626,
    'Powiat bieszczadzki': 1626,
    'Powiat brzozowski': 1626,
    'Powiat dębicki': 1626,
    'Powiat jarosławski': 1626,
    'Powiat jasielski': 1626,
    'Powiat kolbuszowski': 1626,
    'Powiat krośnieński': 1626,
    'Powiat leski': 1626,
    'Powiat leżajski': 1626,
    'Powiat lubaczowski': 1626,
    'Powiat łańcucki': 1626,
    'Powiat mielecki': 1626,
    'Powiat niżański': 1626,
    'Powiat przemyski': 1626,
    'Powiat przeworski': 1626,
    'Powiat ropczycko-sędziszowski': 1626,
    'Powiat rzeszowski': 1626,
    'Powiat sanocki': 1626,
    'Powiat stalowowolski': 1626,
    'Powiat strzyżowski': 1626,
    'Powiat tarnobrzeski': 1626,
    'Pruchnik': 1626,
    'Przecław': 1626,
    'Przedmieście Dubieckie': 1626,
    'Przędzel': 1626,
    'Przemyśl': 1626,
    'Przeworsk': 1626,
    'Przybyszówka': 1626,
    'Pysznica': 1626,
    'Racławice': 1626,
    'Radomyśl': 1626,
    'Radomyśl Wielki': 1626,
    'Radymno': 1626,
    'Rakszawa': 1626,
    'Raniżów': 1626,
    'Rogóźno': 1626,
    'Rokietnica': 1626,
    'Ropczyce': 1626,
    'Rozbórz': 1626,
    'Rudna Mała': 1626,
    'Rudna Wielka': 1626,
    'Rudnik nad Sanem': 1626,
    'Rymanów': 1626,
    'Rzepedź': 1626,
    'Rzeszów': 1626,
    'Sanok': 1626,
    'Sędziszów Małopolski': 1626,
    'Siedleczka': 1626,
    'Siedliska': 1626,
    'Sieniawa': 1626,
    'Sietesz': 1626,
    'Skołyszyn': 1626,
    'Słotowa': 1626,
    'Sokolniki': 1626,
    'Sokołów Małopolski': 1626,
    'Sonina': 1626,
    'Sośnica': 1626,
    'Stalowa Wola': 1626,
    'Stara Wieś': 1626,
    'Stare Miasto': 1626,
    'Strachocina': 1626,
    'Straszydle': 1626,
    'Strzyżów': 1626,
    'Stubno': 1626,
    'Świlcza': 1626,
    'Tarnobrzeg': 1626,
    'Tarnowiec': 1626,
    'Tryńcza': 1626,
    'Trzciana': 1626,
    'Trzcinica': 1626,
    'Trzebownisko': 1626,
    'Turze Pole': 1626,
    'Tyczyn': 1626,
    'Tyrawa Wołoska': 1626,
    'Uherce Mineralne': 1626,
    'Ulanów': 1626,
    'Urzejowice': 1626,
    'Ustrzyki Dolne': 1626,
    'Wadowice Górne': 1626,
    'Wesoła': 1626,
    'Wiązownica': 1626,
    'Wielkie Oczy': 1626,
    'Wielopole Skrzyńskie': 1626,
    'Wierzawice': 1626,
    'Wierzbna': 1626,
    'Wiśniowa': 1626,
    'Wojaszówka': 1626,
    'Wola Żarczycka': 1626,
    'Wólka Niedźwiedzka': 1626,
    'Wólka Pełkińska': 1626,
    'Wólka Podleśna': 1626,
    'Wólka Tanewska': 1626,
    'Wysoka': 1626,
    'Wysoka Głogowska': 1626,
    'Wysoka Strzyżowska': 1626,
    'Zagórz': 1626,
    'Zagórzyce': 1626,
    'Zaklików': 1626,
    'Zalesie': 1626,
    'Zaleszany': 1626,
    'Zarszyn': 1626,
    'Zarzecze': 1626,
    'Zdziechowice Drugie': 1626,
    'Zgłobień': 1626,
    'Zmiennica': 1626,
    'Żołynia': 1626,
    'Żurawica': 1626,
    'Żurawiczki': 1626,
    'Żyraków': 1626,
    'Augustów': 1632,
    'Białowieża': 1632,
    'Białystok': 1632,
    'Bielsk Podlaski': 1632,
    'Boćki': 1632,
    'Brańsk': 1632,
    'Choroszcz': 1632,
    'Ciechanowiec': 1632,
    'Czarna Białostocka': 1632,
    'Czeremcha': 1632,
    'Czyżew': 1632,
    'Dąbrowa Białostocka': 1632,
    'Dobrzyniewo Duże': 1632,
    'Drohiczyn': 1632,
    'Filipów': 1632,
    'Goniadz': 1632,
    'Grajewo': 1632,
    'Grudki': 1632,
    'Hajnówka': 1632,
    'Jedwabne': 1632,
    'Kleszczele': 1632,
    'Knyszyn': 1632,
    'Kolno': 1632,
    'Krasnopol': 1632,
    'Krynki': 1632,
    'Kuźnica': 1632,
    'Lipsk': 1632,
    'Łapy': 1632,
    'Łomża': 1632,
    'Mały Płock': 1632,
    'Michałowo': 1632,
    'Milejczyce': 1632,
    'Mońki': 1632,
    'Narew': 1632,
    'Nowogród': 1632,
    'Nurzec-Stacja': 1632,
    'Orla': 1632,
    'Piątnica': 1632,
    'Powiat augustowski': 1632,
    'Powiat białostocki': 1632,
    'Powiat bielski': 1632,
    'Powiat grajewski': 1632,
    'Powiat hajnowski': 1632,
    'Powiat kolneński': 1632,
    'Powiat łomżyński': 1632,
    'Powiat moniecki': 1632,
    'Powiat sejneński': 1632,
    'Powiat siemiatycki': 1632,
    'Powiat sokólski': 1632,
    'Powiat suwalski': 1632,
    'Powiat wysokomazowiecki': 1632,
    'Powiat zambrowski': 1632,
    'Puńsk': 1632,
    'Raczki': 1632,
    'Radziłów': 1632,
    'Rajgród': 1632,
    'Rudka': 1632,
    'Rutki-Kossaki': 1632,
    'Sejny': 1632,
    'Siemiatycze': 1632,
    'Śniadowo': 1632,
    'Sokółka': 1632,
    'Sokoły': 1632,
    'Stawiski': 1632,
    'Suchowola': 1632,
    'Supraśl': 1632,
    'Suraż': 1632,
    'Suwałki': 1632,
    'Szczuczyn': 1632,
    'Szepietowo': 1632,
    'Szumowo': 1632,
    'Turośń Kościelna': 1632,
    'Tykocin': 1632,
    'Wasilków': 1632,
    'Wąsosz': 1632,
    'Wizna': 1632,
    'Wysokie Mazowieckie': 1632,
    'Wyszki': 1632,
    'Zabłudów': 1632,
    'Zambrów': 1632,
    'Zbójna': 1632,
    'Banino': 1624,
    'Bobowo': 1624,
    'Bojano': 1624,
    'Bolszewo': 1624,
    'Borzytuchom': 1624,
    'Brusy': 1624,
    'Bytów': 1624,
    'Cedry Wielkie': 1624,
    'Cewice': 1624,
    'Chłapowo': 1624,
    'Chmielno': 1624,
    'Choczewo': 1624,
    'Chojnice': 1624,
    'Chwaszczyno': 1624,
    'Czarna Dąbrówka': 1624,
    'Czarna Woda': 1624,
    'Czarne': 1624,
    'Czersk': 1624,
    'Człuchów': 1624,
    'Damnica': 1624,
    'Dębnica Kaszubska': 1624,
    'Debrzno': 1624,
    'Dziemiany': 1624,
    'Dzierzgoń': 1624,
    'Garcz': 1624,
    'Gardeja': 1624,
    'Gdańsk': 1624,
    'Gdynia': 1624,
    'Główczyce': 1624,
    'Gniew': 1624,
    'Gniewino': 1624,
    'Gnieżdżewo': 1624,
    'Gościcino': 1624,
    'Grabowo Kościerskie': 1624,
    'Hel': 1624,
    'Jastarnia': 1624,
    'Jastrzębia Góra': 1624,
    'Kaliska': 1624,
    'Karsin': 1624,
    'Kartuzy': 1624,
    'Kępice': 1624,
    'Kobylnica': 1624,
    'Koczała': 1624,
    'Kolbudy': 1624,
    'Koleczkowo': 1624,
    'Kołczygłowy': 1624,
    'Kosakowo': 1624,
    'Kościerzyna': 1624,
    'Kowale': 1624,
    'Krokowa': 1624,
    'Krynica Morska': 1624,
    'Kwidzyn': 1624,
    'Lębork': 1624,
    'Linia': 1624,
    'Liniewo': 1624,
    'Lipnica': 1624,
    'Lipusz': 1624,
    'Lisewo Malborskie': 1624,
    'Lubichowo': 1624,
    'Lublewo Gdańskie': 1624,
    'Luzino': 1624,
    'Łeba': 1624,
    'Łęczyce': 1624,
    'Łęgowo': 1624,
    'Malbork': 1624,
    'Miastko': 1624,
    'Mikołajki Pomorskie': 1624,
    'Miłoradz': 1624,
    'Mosty': 1624,
    'Mrzezino': 1624,
    'Nowa Wieś Lęborska': 1624,
    'Nowy Dwór Gdański': 1624,
    'Nowy Staw': 1624,
    'Orle': 1624,
    'Osiek': 1624,
    'Ostaszewo': 1624,
    'Parchowo': 1624,
    'Pelplin': 1624,
    'Pogórze': 1624,
    'Potęgowo': 1624,
    'Powiat bytowski': 1624,
    'Powiat chojnicki': 1624,
    'Powiat człuchowski': 1624,
    'Powiat gdański': 1624,
    'Powiat kartuski': 1624,
    'Powiat kościerski': 1624,
    'Powiat kwidzyński': 1624,
    'Powiat lęborski': 1624,
    'Powiat malborski': 1624,
    'Powiat nowodworski': 1624,
    'Powiat pucki': 1624,
    'Powiat słupski': 1624,
    'Powiat starogardzki': 1624,
    'Powiat sztumski': 1624,
    'Powiat tczewski': 1624,
    'Powiat wejherowski': 1624,
    'Prabuty': 1624,
    'Pruszcz Gdański': 1624,
    'Przechlewo': 1624,
    'Przodkowo': 1624,
    'Przywidz': 1624,
    'Pszczółki': 1624,
    'Puck': 1624,
    'Reda': 1624,
    'Rekowo Dolne': 1624,
    'Rotmanka': 1624,
    'Rumia': 1624,
    'Ryjewo': 1624,
    'Rzeczenica': 1624,
    'Sadlinki': 1624,
    'Sierakowice': 1624,
    'Skarszewy': 1624,
    'Skórcz': 1624,
    'Słupsk': 1624,
    'Smętowo Graniczne': 1624,
    'Smołdzino': 1624,
    'Somonino': 1624,
    'Sopot': 1624,
    'Stara Kiszewa': 1624,
    'Stare Pole': 1624,
    'Starogard Gdański': 1624,
    'Stary Targ': 1624,
    'Stegna': 1624,
    'Stężyca': 1624,
    'Straszyn': 1624,
    'Strzelno': 1624,
    'Studzienice': 1624,
    'Subkowy': 1624,
    'Suchy Dąb': 1624,
    'Sulęczyno': 1624,
    'Szemud': 1624,
    'Szlachta': 1624,
    'Sztum': 1624,
    'Sztutowo': 1624,
    'Tczew': 1624,
    'Trąbki Wielkie': 1624,
    'Trzebielino': 1624,
    'Tuchomie': 1624,
    'Ustka': 1624,
    'Wejherowo': 1624,
    'Wielki Kack': 1624,
    'Wierzchucino': 1624,
    'Władysławowo': 1624,
    'Zblewo': 1624,
    'Żelistrzewo': 1624,
    'Żukowo': 1624,
    'Bąków': 1623,
    'Bażanowice': 1623,
    'Będzin': 1623,
    'Bełk': 1623,
    'Bełsznica': 1623,
    'Bestwina': 1623,
    'Bestwinka': 1623,
    'Bielsko-Biala': 1623,
    'Bielsko-Biała': 1623,
    'Bieruń': 1623,
    'Blachownia': 1623,
    'Bobrowniki': 1623,
    'Bojszowy': 1623,
    'Bojszowy Nowe': 1623,
    'Boronów': 1623,
    'Borowno': 1623,
    'Borucin': 1623,
    'Brenna': 1623,
    'Bronów': 1623,
    'Brzeziny Śląskie': 1623,
    'Buczkowice': 1623,
    'Bujaków': 1623,
    'Bystra': 1623,
    'Bytom': 1623,
    'Chałupki': 1623,
    'Chełm Śląski': 1623,
    'Choroń': 1623,
    'Chorzów': 1623,
    'Chruszczobród': 1623,
    'Chybie': 1623,
    'Ciasna': 1623,
    'Cięcina': 1623,
    'Cielmice': 1623,
    'Cieszyn': 1623,
    'Cisiec': 1623,
    'Cisownica': 1623,
    'Cynków': 1623,
    'Czaniec': 1623,
    'Czarków': 1623,
    'Czechowice-Dziedzice': 1623,
    'Czeladź': 1623,
    'Czernica': 1623,
    'Czernichów': 1623,
    'Czerwionka-Leszczyny': 1623,
    'Częstochowa': 1623,
    'Czyżowice': 1623,
    'Dąbrowa Górnicza': 1623,
    'Dąbrowa Zielona': 1623,
    'Dębowiec': 1623,
    'Dobieszowice': 1623,
    'Drogomyśl': 1623,
    'Dzięgielów': 1623,
    'Frydek': 1623,
    'Gardawice': 1623,
    'Gaszowice': 1623,
    'Gierałtowice': 1623,
    'Gilowice': 1623,
    'Gliwice': 1623,
    'Gniazdów': 1623,
    'Goczałkowice Zdrój': 1623,
    'Godów': 1623,
    'Godziszka': 1623,
    'Goleszów': 1623,
    'Gołkowice': 1623,
    'Góra': 1623,
    'Górki Wielkie': 1623,
    'Gorzyce': 1623,
    'Gorzyczki': 1623,
    'Gostyń': 1623,
    'Hażlach': 1623,
    'Herby': 1623,
    'Huta Stara B': 1623,
    'Imielin': 1623,
    'Istebna': 1623,
    'Jankowice': 1623,
    'Jankowice Rybnickie': 1623,
    'Janowice': 1623,
    'Jasienica': 1623,
    'Jastrząb': 1623,
    'Jastrzębie Zdrój': 1623,
    'Jastrzębie-Zdrój': 1623,
    'Jaworze': 1623,
    'Jaworzno': 1623,
    'Jaworzynka': 1623,
    'Jejkowice': 1623,
    'Jeleśnia': 1623,
    'Juszczyna': 1623,
    'Kaczyce': 1623,
    'Kalej': 1623,
    'Kalety': 1623,
    'Kamesznica': 1623,
    'Kamienica Polska': 1623,
    'Kamyk': 1623,
    'Kaniów': 1623,
    'Katowice': 1623,
    'Kłobuck': 1623,
    'Kłomnice': 1623,
    'Knurów': 1623,
    'Kobiernice': 1623,
    'Kobiór': 1623,
    'Kochanowice': 1623,
    'Kończyce Małe': 1623,
    'Kończyce Wielkie': 1623,
    'Koniaków': 1623,
    'Koniecpol': 1623,
    'Konopiska': 1623,
    'Korbielów': 1623,
    'Kornowac': 1623,
    'Kościelec': 1623,
    'Koszarawa': 1623,
    'Koszęcin': 1623,
    'Koziegłowy': 1623,
    'Kozy': 1623,
    'Kroczyce': 1623,
    'Krupski Młyn': 1623,
    'Kryry': 1623,
    'Krzanowice': 1623,
    'Krzepice': 1623,
    'Krzyżanowice': 1623,
    'Krzyżowa': 1623,
    'Krzyżowice': 1623,
    'Książenice': 1623,
    'Kuźnia Raciborska': 1623,
    'Laliki': 1623,
    'Lędziny': 1623,
    'Lelów': 1623,
    'Leśna': 1623,
    'Ligota': 1623,
    'Lipie': 1623,
    'Lipowa': 1623,
    'Lubliniec': 1623,
    'Lubomia': 1623,
    'Lyski': 1623,
    'Łaziska': 1623,
    'Łaziska Górne': 1623,
    'Łazy': 1623,
    'Łękawica': 1623,
    'Łobodno': 1623,
    'Łodygowice': 1623,
    'Markłowice': 1623,
    'Mazańcowice': 1623,
    'Miasteczko Śląskie': 1623,
    'Miedźna': 1623,
    'Miedzno': 1623,
    'Międzybrodzie Bialskie': 1623,
    'Mierzęcice': 1623,
    'Mikołów': 1623,
    'Milówka': 1623,
    'Mnich': 1623,
    'Mstów': 1623,
    'Mszana': 1623,
    'Murcki': 1623,
    'Mykanów': 1623,
    'Mysłowice': 1623,
    'Myszków': 1623,
    'Nakło': 1623,
    'Nędza': 1623,
    'Niedobczyce': 1623,
    'Niegowonice': 1623,
    'Ochaby': 1623,
    'Ogrodzieniec': 1623,
    'Olsztyn': 1623,
    'Olza': 1623,
    'Opatów': 1623,
    'Ornontowice': 1623,
    'Orzech': 1623,
    'Orzesze': 1623,
    'Ostrowy nad Okszą': 1623,
    'Ożarowice': 1623,
    'Paniówki': 1623,
    'Panki': 1623,
    'Pawłowice': 1623,
    'Pawonków': 1623,
    'Pewel Ślemieńska': 1623,
    'Pewel Wielka': 1623,
    'Piasek': 1623,
    'Piekary Śląskie': 1623,
    'Pielgrzymowice': 1623,
    'Pierściec': 1623,
    'Pietrowice Wielkie': 1623,
    'Pietrzykowice': 1623,
    'Pilchowice': 1623,
    'Pilica': 1623,
    'Pisarzowice': 1623,
    'Pogórze': 1623,
    'Pogrzebień': 1623,
    'Pogwizdów': 1623,
    'Połomia': 1623,
    'Porąbka': 1623,
    'Poraj': 1623,
    'Poręba': 1623,
    'Potok Złoty': 1623,
    'Powiat będziński': 1623,
    'Powiat bielski': 1623,
    'Powiat bieruńsko-lędziński': 1623,
    'Powiat cieszyński': 1623,
    'Powiat częstochowski': 1623,
    'Powiat gliwicki': 1623,
    'Powiat kłobucki': 1623,
    'Powiat lubliniecki': 1623,
    'Powiat mikołowski': 1623,
    'Powiat myszkowski': 1623,
    'Powiat pszczyński': 1623,
    'Powiat raciborski': 1623,
    'Powiat rybnicki': 1623,
    'Powiat tarnogórski': 1623,
    'Powiat wodzisławski': 1623,
    'Powiat zawierciański': 1623,
    'Powiat żywiecki': 1623,
    'Pruchna': 1623,
    'Przyborów': 1623,
    'Przyrów': 1623,
    'Przystajń': 1623,
    'Przyszowice': 1623,
    'Psary': 1623,
    'Pszczyna': 1623,
    'Pszów': 1623,
    'Puńców': 1623,
    'Pyskowice': 1623,
    'Racibórz': 1623,
    'Radlin': 1623,
    'Radostowice': 1623,
    'Radziechowy': 1623,
    'Radzionków': 1623,
    'Rajcza': 1623,
    'Raszczyce': 1623,
    'Rędziny': 1623,
    'Rogów': 1623,
    'Rogoźnik': 1623,
    'Rokitno Szlacheckie': 1623,
    'Ruda Śląska': 1623,
    'Rudnik': 1623,
    'Rudniki': 1623,
    'Rudy': 1623,
    'Rudziczka': 1623,
    'Rudziniec': 1623,
    'Rybarzowice': 1623,
    'Rybnik': 1623,
    'Rycerka Dolna': 1623,
    'Rycerka Górna': 1623,
    'Rydułtowy': 1623,
    'Sączów': 1623,
    'Sarnów': 1623,
    'Siemianowice Śląskie': 1623,
    'Sieraków Śląski': 1623,
    'Siewierz': 1623,
    'Skoczów': 1623,
    'Skrzyszów': 1623,
    'Ślemień': 1623,
    'Sławków': 1623,
    'Sól': 1623,
    'Sopotnia Wielka': 1623,
    'Sośnicowice': 1623,
    'Sosnowiec': 1623,
    'Stanowice': 1623,
    'Starcza': 1623,
    'Starokrzepice': 1623,
    'Strumień': 1623,
    'Strzebiń': 1623,
    'Strzyżowice': 1623,
    'Studzionka': 1623,
    'Suszec': 1623,
    'Świerklaniec': 1623,
    'Świerklany Dolne': 1623,
    'Świerklany Górne': 1623,
    'Świętochłowice': 1623,
    'Świnna': 1623,
    'Syrynia': 1623,
    'Szczekociny': 1623,
    'Szczerbice': 1623,
    'Szczyrk': 1623,
    'Tarnowskie Góry': 1623,
    'Toszek': 1623,
    'Truskolasy': 1623,
    'Trzebinia': 1623,
    'Turza Śląska': 1623,
    'Tworków': 1623,
    'Tworóg': 1623,
    'Tychy': 1623,
    'Ujsoły': 1623,
    'Ustroń': 1623,
    'Węgierska Górka': 1623,
    'Wielowieś': 1623,
    'Wieprz': 1623,
    'Wieszowa': 1623,
    'Wilamowice': 1623,
    'Wilcza': 1623,
    'Wilkowice': 1623,
    'Wisła': 1623,
    'Włodowice': 1623,
    'Wodzisław Śląski': 1623,
    'Wojkowice': 1623,
    'Wola': 1623,
    'Wożniki': 1623,
    'Wręczyca Wielka': 1623,
    'Wyry': 1623,
    'Wysoka': 1623,
    'Zabłocie': 1623,
    'Żabnica': 1623,
    'Zaborze': 1623,
    'Zabrze': 1623,
    'Zabrzeg': 1623,
    'Zamarski': 1623,
    'Żarki': 1623,
    'Żarki-Letnisko': 1623,
    'Zarzecze': 1623,
    'Zawidów': 1623,
    'Zawiercie': 1623,
    'Zbrosławice': 1623,
    'Zbytków': 1623,
    'Zebrzydowice': 1623,
    'Żernica': 1623,
    'Żory': 1623,
    'Żywiec': 1623,
    'Baćkowice': 1630,
    'Bałtów': 1630,
    'Bejsce': 1630,
    'Bieliny': 1630,
    'Bliżyn': 1630,
    'Bodzentyn': 1630,
    'Bogoria': 1630,
    'Brody': 1630,
    'Brzeziny': 1630,
    'Busko-Zdrój': 1630,
    'Chęciny': 1630,
    'Chmielnik': 1630,
    'Ćmielów': 1630,
    'Czarnocin': 1630,
    'Daleszyce': 1630,
    'Domaszowice': 1630,
    'Dwikozy': 1630,
    'Działoszyce': 1630,
    'Fałków': 1630,
    'Gnojno': 1630,
    'Górno': 1630,
    'Gowarczów': 1630,
    'Grzybowa Góra': 1630,
    'Imielno': 1630,
    'Iwaniska': 1630,
    'Jędrzejów': 1630,
    'Kaniów': 1630,
    'Kazimierza Wielka': 1630,
    'Kielce': 1630,
    'Kije': 1630,
    'Klimontów': 1630,
    'Końskie': 1630,
    'Koprzywnica': 1630,
    'Kostomłoty Drugie': 1630,
    'Kostomłoty Pierwsze': 1630,
    'Krasocin': 1630,
    'Kunów': 1630,
    'Lipnik': 1630,
    'Łączna': 1630,
    'Łagów': 1630,
    'Łoniów': 1630,
    'Łopuszno': 1630,
    'Łubnice': 1630,
    'Małogoszcz': 1630,
    'Masłów': 1630,
    'Michałów': 1630,
    'Miedziana Góra': 1630,
    'Mirzec': 1630,
    'Mniów': 1630,
    'Morawica': 1630,
    'Nagłowice': 1630,
    'Nowa Słupia': 1630,
    'Nowy Korczyn': 1630,
    'Obrazów': 1630,
    'Oksa': 1630,
    'Oleśnica': 1630,
    'Opatów': 1630,
    'Opatowiec': 1630,
    'Osiedle-Nowiny': 1630,
    'Osiek': 1630,
    'Ostrowiec Świętokrzyski': 1630,
    'Ożarów': 1630,
    'Pacanów': 1630,
    'Pawłów': 1630,
    'Piekoszów': 1630,
    'Pierzchnica': 1630,
    'Pińczów': 1630,
    'Połaniec': 1630,
    'Powiat buski': 1630,
    'Powiat jędrzejowski': 1630,
    'Powiat kazimierski': 1630,
    'Powiat kielecki': 1630,
    'Powiat konecki': 1630,
    'Powiat opatowski': 1630,
    'Powiat ostrowiecki': 1630,
    'Powiat pińczowski': 1630,
    'Powiat sandomierski': 1630,
    'Powiat skarżyski': 1630,
    'Powiat starachowicki': 1630,
    'Powiat staszowski': 1630,
    'Powiat włoszczowski': 1630,
    'Radoszyce': 1630,
    'Raków': 1630,
    'Rogów': 1630,
    'Ruda Maleniecka': 1630,
    'Rytwiany': 1630,
    'Sadowie': 1630,
    'Samborzec': 1630,
    'Sandomierz': 1630,
    'Secemin': 1630,
    'Sędziszów': 1630,
    'Skalbmierz': 1630,
    'Skarżysko Kościelne': 1630,
    'Skarżysko-Kamienna': 1630,
    'Słupia': 1630,
    'Smyków': 1630,
    'Sobków': 1630,
    'Solec-Zdrój': 1630,
    'Stąporków': 1630,
    'Starachowice': 1630,
    'Staszów': 1630,
    'Stopnica': 1630,
    'Strawczyn': 1630,
    'Suchedniów': 1630,
    'Szydłów': 1630,
    'Tarłów': 1630,
    'Tuczępy': 1630,
    'Wąchock': 1630,
    'Waśniów': 1630,
    'Wielka Wieś': 1630,
    'Wilczyce': 1630,
    'Wiślica': 1630,
    'Włoszczowa': 1630,
    'Wodzisław': 1630,
    'Wojciechowice': 1630,
    'Wola Jachowa': 1630,
    'Zagnańsk': 1630,
    'Zawichost': 1630,
    'Złota': 1630,
    'Banie Mazurskie': 1628,
    'Barciany': 1628,
    'Barczewo': 1628,
    'Bartoszyce': 1628,
    'Biała Piska': 1628,
    'Biskupiec': 1628,
    'Bisztynek': 1628,
    'Braniewo': 1628,
    'Czerwonka': 1628,
    'Dąbrówno': 1628,
    'Dobre Miasto': 1628,
    'Dywity': 1628,
    'Działdowo': 1628,
    'Dźwierzuty': 1628,
    'Elbląg': 1628,
    'Ełk': 1628,
    'Frombork': 1628,
    'Gierłoż': 1628,
    'Gierzwałd': 1628,
    'Gietrzwałd': 1628,
    'Giżycko': 1628,
    'Gołdap': 1628,
    'Górowo Iławeckie': 1628,
    'Gronowo Elbląskie': 1628,
    'Gronowo Górne': 1628,
    'Iława': 1628,
    'Iłowo -Osada': 1628,
    'Jedwabno': 1628,
    'Jeziorany': 1628,
    'Jonkowo': 1628,
    'Kętrzyn': 1628,
    'Kisielice': 1628,
    'Korsze': 1628,
    'Kortowo': 1628,
    'Kowale Oleckie': 1628,
    'Kozłowo': 1628,
    'Kruklanki': 1628,
    'Kurzętnik': 1628,
    'Lelkowo': 1628,
    'Lidzbark': 1628,
    'Lidzbark Warmiński': 1628,
    'Lubawa': 1628,
    'Lubomino': 1628,
    'Małdyty': 1628,
    'Mikołajki': 1628,
    'Miłakowo': 1628,
    'Miłomłyn': 1628,
    'Młynary': 1628,
    'Morąg': 1628,
    'Mrągowo': 1628,
    'Nidzica': 1628,
    'Nowe Miasto Lubawskie': 1628,
    'Olecko': 1628,
    'Olsztyn': 1628,
    'Olsztynek': 1628,
    'Orneta': 1628,
    'Orzysz': 1628,
    'Ostróda': 1628,
    'Pasłęk': 1628,
    'Pasym': 1628,
    'Piecki': 1628,
    'Pieniężno': 1628,
    'Pisz': 1628,
    'Powiat bartoszycki': 1628,
    'Powiat braniewski': 1628,
    'Powiat działdowski': 1628,
    'Powiat elbląski': 1628,
    'Powiat ełcki': 1628,
    'Powiat giżycki': 1628,
    'Powiat gołdapski': 1628,
    'Powiat iławski': 1628,
    'Powiat kętrzyński': 1628,
    'Powiat lidzbarski': 1628,
    'Powiat mrągowski': 1628,
    'Powiat nidzicki': 1628,
    'Powiat nowomiejski': 1628,
    'Powiat olecki': 1628,
    'Powiat olsztyński': 1628,
    'Powiat ostródzki': 1628,
    'Powiat piski': 1628,
    'Powiat szczycieński': 1628,
    'Powiat węgorzewski': 1628,
    'Pozezdrze': 1628,
    'Prostki': 1628,
    'Purda': 1628,
    'Reszel': 1628,
    'Rozogi': 1628,
    'Ruciane-Nida': 1628,
    'Rybno': 1628,
    'Ryn': 1628,
    'Sępopol': 1628,
    'Srokowo': 1628,
    'Stare Juchy': 1628,
    'Stawiguda': 1628,
    'Susz': 1628,
    'Swiętajno': 1628,
    'Szczytno': 1628,
    'Tolkmicko': 1628,
    'Węgorzewo': 1628,
    'Wielbark': 1628,
    'Wydminy': 1628,
    'Zalewo': 1628,
    'Banie': 1633,
    'Barlinek': 1633,
    'Barwice': 1633,
    'Białogard': 1633,
    'Biały Bór': 1633,
    'Bielice': 1633,
    'Bierzwnik': 1633,
    'Bobolice': 1633,
    'Boleszkowice': 1633,
    'Borne Sulinowo': 1633,
    'Brojce': 1633,
    'Cedynia': 1633,
    'Cerkwica': 1633,
    'Chociwel': 1633,
    'Chojna': 1633,
    'Choszczno': 1633,
    'Czaplinek': 1633,
    'Człopa': 1633,
    'Darłowo': 1633,
    'Dębno': 1633,
    'Dobra': 1633,
    'Dobrzany': 1633,
    'Dolice': 1633,
    'Drawno': 1633,
    'Drawsko Pomorskie': 1633,
    'Dygowo': 1633,
    'Dziwnów': 1633,
    'Golczewo': 1633,
    'Goleniów': 1633,
    'Gościno': 1633,
    'Gryfice': 1633,
    'Gryfino': 1633,
    'Grzmiąca': 1633,
    'Grzybowo': 1633,
    'Ińsko': 1633,
    'Kalisz Pomorski': 1633,
    'Kamień Pomorski': 1633,
    'Karlino': 1633,
    'Karnice': 1633,
    'Kobylanka': 1633,
    'Kołbaskowo': 1633,
    'Kołobrzeg': 1633,
    'Koszalin': 1633,
    'Krzęcin': 1633,
    'Lipiany': 1633,
    'Łobez': 1633,
    'Łubowo': 1633,
    'Marianowo': 1633,
    'Maszewo': 1633,
    'Międzyzdroje': 1633,
    'Mielno': 1633,
    'Mieszkowice': 1633,
    'Mirosławiec': 1633,
    'Moryń': 1633,
    'Mosty': 1633,
    'Mrzeżyno': 1633,
    'Myślibórz': 1633,
    'Nowe Warpno': 1633,
    'Nowogard': 1633,
    'Nowogródek Pomorski': 1633,
    'Osina': 1633,
    'Pełczyce': 1633,
    'Płoty': 1633,
    'Pobierowo': 1633,
    'Polanów': 1633,
    'Police': 1633,
    'Połczyn-Zdrój': 1633,
    'Powiat białogardzki': 1633,
    'Powiat choszczeński': 1633,
    'Powiat drawski': 1633,
    'Powiat goleniowski': 1633,
    'Powiat gryficki': 1633,
    'Powiat gryfiński': 1633,
    'Powiat kamieński': 1633,
    'Powiat kołobrzeski': 1633,
    'Powiat koszaliński': 1633,
    'Powiat łobeski': 1633,
    'Powiat myśliborski': 1633,
    'Powiat policki': 1633,
    'Powiat pyrzycki': 1633,
    'Powiat sławieński': 1633,
    'Powiat stargardzki': 1633,
    'Powiat świdwiński': 1633,
    'Powiat szczecinecki': 1633,
    'Powiat wałecki': 1633,
    'Przecław': 1633,
    'Przelewice': 1633,
    'Przybiernów': 1633,
    'Pyrzyce': 1633,
    'Rąbino': 1633,
    'Radowo Małe': 1633,
    'Recz': 1633,
    'Resko': 1633,
    'Rewal': 1633,
    'Rymań': 1633,
    'Sianów': 1633,
    'Sławno': 1633,
    'Sławoborze': 1633,
    'Stara Dąbrowa': 1633,
    'Stare Czarnowo': 1633,
    'Stargard': 1633,
    'Stepnica': 1633,
    'Suchań': 1633,
    'Świdwin': 1633,
    'Świerzno': 1633,
    'Świnoujście': 1633,
    'Szczecin': 1633,
    'Szczecinek': 1633,
    'Trzcińsko Zdrój': 1633,
    'Trzebiatów': 1633,
    'Tuczno': 1633,
    'Tychowo': 1633,
    'Ustronie Morskie': 1633,
    'Wałcz': 1633,
    'Warnice': 1633,
    'Węgorzyno': 1633,
    'Widuchowa': 1633,
    'Wierzchowo': 1633,
    'Wolin': 1633,
    'Złocieniec': 1633,
    'Água de Pau': 2233,
    'Angra do Heroísmo': 2233,
    'Angústias': 2233,
    'Arrifes': 2233,
    'Biscoitos': 2233,
    'Cabouco': 2233,
    'Cais do Pico': 2233,
    'Calheta': 2233,
    'Calheta de São Jorge': 2233,
    'Castelo Branco': 2233,
    'Corvo': 2233,
    'Fajã de Baixo': 2233,
    'Fenais da Ajuda': 2233,
    'Fenais da Luz': 2233,
    'Feteira': 2233,
    'Fonte Bastardo': 2233,
    'Furnas': 2233,
    'Horta': 2233,
    'Lagoa': 2233,
    'Lajes': 2233,
    'Lajes das Flores': 2233,
    'Lajes do Pico': 2233,
    'Madalena': 2233,
    'Maia': 2233,
    'Mosteiros': 2233,
    'Nordeste': 2233,
    'Ponta Delgada': 2233,
    'Ponta Garça': 2233,
    'Porto Judeu': 2233,
    'Porto Martins': 2233,
    'Povoação': 2233,
    'Praia da Vitória': 2233,
    'Rabo de Peixe': 2233,
    'Relva': 2233,
    'Ribeira Grande': 2233,
    'Ribeira Seca': 2233,
    'Ribeirinha': 2233,
    'Rosto de Cão': 2233,
    'Santa Bárbara': 2233,
    'Santa Cruz da Graciosa': 2233,
    'Santa Cruz das Flores': 2233,
    'São Bartolomeu': 2233,
    'São Mateus': 2233,
    'São Roque': 2233,
    'São Roque do Pico': 2233,
    'São Sebastião': 2233,
    'São Vicente': 2233,
    'Senhora do Rosário': 2233,
    'Velas': 2233,
    'Vila do Porto': 2233,
    'Vila Franca do Campo': 2233,
    'Aguada de Cima': 2235,
    'Águeda': 2235,
    'Albergaria-a-Velha': 2235,
    'Anadia': 2235,
    'Aradas': 2235,
    'Arcos': 2235,
    'Argoncilhe': 2235,
    'Arouca': 2235,
    'Arrifana': 2235,
    'Avanca': 2235,
    'Aveiro': 2235,
    'Beduido': 2235,
    'Branca': 2235,
    'Castelo de Paiva': 2235,
    'Cortegaça': 2235,
    'Cucujães': 2235,
    'Eixo': 2235,
    'Esgueira': 2235,
    'Esmoriz': 2235,
    'Espargo': 2235,
    'Espinho': 2235,
    'Estarreja': 2235,
    'Feira': 2235,
    'Fiães': 2235,
    'Gafanha da Encarnação': 2235,
    'Gafanha da Nazaré': 2235,
    'Ílhavo': 2235,
    'Lamas': 2235,
    'Lobão': 2235,
    'Lourosa': 2235,
    'Luso': 2235,
    'Macieira de Cambra': 2235,
    'Mealhada': 2235,
    'Milheirós de Poiares': 2235,
    'Mosteirô': 2235,
    'Murtosa': 2235,
    'Nogueira da Regedoura': 2235,
    'Oiã': 2235,
    'Oliveira de Azemeis': 2235,
    'Oliveira do Bairro': 2235,
    'Oliveirinha': 2235,
    'Ovar': 2235,
    'Paços de Brandão': 2235,
    'Pampilhosa do Botão': 2235,
    'Pardilhó': 2235,
    'Rio Meão': 2235,
    'Salreu': 2235,
    'Sanfins': 2235,
    'Sangalhos': 2235,
    'Santa Maria da Feira': 2235,
    'São João': 2235,
    'São João da Madeira': 2235,
    'São João de Ver': 2235,
    'São Roque': 2235,
    'Sever do Vouga': 2235,
    'Silvalde': 2235,
    'Souto': 2235,
    'Vagos': 2235,
    'Vale de Cambra': 2235,
    'Válega': 2235,
    'Valongo': 2235,
    'Vila Chã': 2235,
    'Aldeia Nova de São Bento': 2230,
    'Aljustrel': 2230,
    'Almodôvar': 2230,
    'Almograve': 2230,
    'Alvito': 2230,
    'Barrancos': 2230,
    'Beja': 2230,
    'Beringel': 2230,
    'Boavista dos Pinheiros': 2230,
    'Cabeça Gorda': 2230,
    'Castro Verde': 2230,
    'Cuba': 2230,
    'Ferreira do Alentejo': 2230,
    'Mértola': 2230,
    'Minas de São Domingos': 2230,
    'Moura': 2230,
    'Neves': 2230,
    'Odemira': 2230,
    'Ourique': 2230,
    'Penedo Gordo': 2230,
    'Salvada': 2230,
    'São Luis': 2230,
    'São Teotónio': 2230,
    'Serpa': 2230,
    'Vidigueira': 2230,
    'Vila Nova de Milfontes': 2230,
    'Zambujeira do Mar': 2230,
    'Adaúfe': 2244,
    'Amares': 2244,
    'Apúlia': 2244,
    'Apúlia e Fão': 2244,
    'Arcos': 2244,
    'Arões': 2244,
    'Aveleda': 2244,
    'Barcelos': 2244,
    'Barqueiros': 2244,
    'Braga': 2244,
    'Brito': 2244,
    'Cabeceiras de Basto': 2244,
    'Caldas das Taipas': 2244,
    'Caldas de Vizela': 2244,
    'Caldelas': 2244,
    'Calendário': 2244,
    'Candoso': 2244,
    'Celorico de Basto': 2244,
    'Chavão': 2244,
    'Cristelo': 2244,
    'Esporões': 2244,
    'Esposende': 2244,
    'Fafe': 2244,
    'Faria': 2244,
    'Ferreiros': 2244,
    'Fradelos': 2244,
    'Frossos': 2244,
    'Galegos': 2244,
    'Gandra': 2244,
    'Gondizalves': 2244,
    'Gueral': 2244,
    'Guimarães': 2244,
    'Guisande': 2244,
    'Joane': 2244,
    'Lago': 2244,
    'Lordelo': 2244,
    'Macieira de Rates': 2244,
    'Marinhas': 2244,
    'Mariz': 2244,
    'Merelim': 2244,
    'Moreira de Conegos': 2244,
    'Negreiros': 2244,
    'Oliveira': 2244,
    'Perelhal': 2244,
    'Ponte': 2244,
    'Póvoa de Lanhoso': 2244,
    'Prado': 2244,
    'Real': 2244,
    'Refojos de Basto': 2244,
    'Rendufe': 2244,
    'Riba de Ave': 2244,
    'Ribeirão': 2244,
    'Ronfe': 2244,
    'Sande': 2244,
    'Selho': 2244,
    'Semelhe': 2244,
    'Serzedelo': 2244,
    'Serzedo': 2244,
    'Tebosa': 2244,
    'Terras de Bouro': 2244,
    'Urgeses': 2244,
    'Várzea': 2244,
    'Vieira do Minho': 2244,
    'Vila Cova': 2244,
    'Vila Frescainha': 2244,
    'Vila Nova de Famalicão': 2244,
    'Vila Seca': 2244,
    'Vila Verde': 2244,
    'Vilaça': 2244,
    'Vilar de Figos': 2244,
    'Vizela': 2244,
    'Alfândega da Fé': 2229,
    'Belver': 2229,
    'Bragança Municipality': 2229,
    'Carrazeda de Anciães': 2229,
    'Carrazeda de Ansiães': 2229,
    'Freixo de Espada à Cinta': 2229,
    'Macedo de Cavaleiros': 2229,
    'Miranda do Douro': 2229,
    'Mirandela': 2229,
    'Mogadouro': 2229,
    'Samil': 2229,
    'Sendim': 2229,
    'Torre de Moncorvo': 2229,
    'Vila Flor': 2229,
    'Vimioso': 2229,
    'Vinhais': 2229,
    'Aldeia de Joanes': 2241,
    'Belmonte': 2241,
    'Castelo Branco': 2241,
    'Covilhã': 2241,
    'Fundão': 2241,
    'Idanha-A-Nova': 2241,
    'Oleiros': 2241,
    'Penamacor': 2241,
    'Proença-a-Nova': 2241,
    'Sertã': 2241,
    'Teixoso': 2241,
    'Vila de Rei': 2241,
    'Vila Velha de Ródão': 2241,
    'Alfarelos': 2246,
    'Alhadas': 2246,
    'Ançã': 2246,
    'Arazede': 2246,
    'Arganil': 2246,
    'Assafarge': 2246,
    'Buarcos': 2246,
    'Cantanhede': 2246,
    'Carapinheira': 2246,
    'Coimbra': 2246,
    'Condeixa-a-Nova': 2246,
    'Figueira da Foz': 2246,
    'Góis': 2246,
    'Lavos': 2246,
    'Lorvão': 2246,
    'Lousã': 2246,
    'Mira': 2246,
    'Miranda do Corvo': 2246,
    'Montemor-o-Velho': 2246,
    'Oliveira do Hospital': 2246,
    'Oliveira do Mondego': 2246,
    'Pampilhosa da Serra': 2246,
    'Penacova': 2246,
    'Penela': 2246,
    'Poiares': 2246,
    'Santa Clara': 2246,
    'Santo António dos Olivais': 2246,
    'São Pedro de Alva': 2246,
    'Soure': 2246,
    'Tábua': 2246,
    'Tavarede': 2246,
    'Tocha': 2246,
    'Travanca': 2246,
    'Vila Nova de Poiares': 2246,
    'Alandroal': 2236,
    'Arraiolos': 2236,
    'Borba': 2236,
    'Estremoz': 2236,
    'Évora': 2236,
    'Montemor-o-Novo': 2236,
    'Mora': 2236,
    'Mourão': 2236,
    'Portel': 2236,
    'Redondo': 2236,
    'Reguengos de Monsaraz': 2236,
    'Vendas Novas': 2236,
    'Viana do Alentejo': 2236,
    'Vila Viçosa': 2236,
    'Albufeira': 2239,
    'Alcantarilha': 2239,
    'Alcoutim': 2239,
    'Algoz': 2239,
    'Aljezur': 2239,
    'Almancil': 2239,
    'Altura': 2239,
    'Alvor': 2239,
    'Armação de Pêra': 2239,
    'Bensafrim': 2239,
    'Boliqueime': 2239,
    'Cabanas de Tavira': 2239,
    'Carvoeiro': 2239,
    'Castro Marim': 2239,
    'Conceição': 2239,
    'Estói': 2239,
    'Estômbar': 2239,
    'Faro': 2239,
    'Ferragudo': 2239,
    'Ferreiras': 2239,
    'Fuzeta': 2239,
    'Guia': 2239,
    'Lagoa': 2239,
    'Lagos': 2239,
    'Laranjeiro': 2239,
    'Loulé': 2239,
    'Luz': 2239,
    'Manta Rota': 2239,
    'Marmelete': 2239,
    'Mexilhoeira Grande': 2239,
    'Moncarapacho': 2239,
    'Monchique': 2239,
    'Monte Gordo': 2239,
    'Olhão': 2239,
    'Olhos de Água': 2239,
    'Paderne': 2239,
    'Parchal': 2239,
    'Pêra': 2239,
    'Porches': 2239,
    'Portimão': 2239,
    'Quarteira': 2239,
    'Sagres': 2239,
    'Santa Bárbara de Nexe': 2239,
    'Santa Luzia': 2239,
    'São Bartolomeu de Messines': 2239,
    'São Brás de Alportel': 2239,
    'São Marcos da Serra': 2239,
    'Senhora da Luz': 2239,
    'Silves': 2239,
    'Tavira': 2239,
    'Tunes': 2239,
    'Vila do Bispo': 2239,
    'Vila Nova De Cacela': 2239,
    'Vila Real de Santo António': 2239,
    'Vilamoura': 2239,
    'Abrigada': 4859,
    'Açores': 4859,
    'Adão': 4859,
    'Agualva': 4859,
    'Águas Belas': 4859,
    'Aguiar da Beira': 4859,
    'Ajuda': 4859,
    'Albardo': 4859,
    'Alcabideche': 4859,
    'Alcains': 4859,
    'Alcântara': 4859,
    'Alcoentre': 4859,
    'Aldeia da Ponte': 4859,
    'Aldeia da Ribeira': 4859,
    'Aldeia de Santo António': 4859,
    'Aldeia do Bispo': 4859,
    'Aldeia Galega da Merceana': 4859,
    'Aldeia Gavinha': 4859,
    'Aldeia Nova, Miranda do Douro': 4859,
    'Aldeia Velha': 4859,
    'Aldeia Viçosa': 4859,
    'Aldeias': 4859,
    'Alenquer': 4859,
    'Alfaiates': 4859,
    'Alfornelos': 4859,
    'Alfragide': 4859,
    'Algés': 4859,
    'Algodres': 4859,
    'Alguber': 4859,
    'Algueirão': 4859,
    'Algueirão–Mem Martins': 4859,
    'Alhandra': 4859,
    'Almargem': 4859,
    'Almargem do Bispo': 4859,
    'Almeida': 4859,
    'Almendra': 4859,
    'Almofala': 4859,
    'Alto do Pina': 4859,
    'Alvalade': 4859,
    'Alvendre': 4859,
    'Alverca da Beira': 4859,
    'Alverca do Ribatejo': 4859,
    'Alvoco da Serra': 4859,
    'Amadora': 4859,
    'Ameixoeira': 4859,
    'Amoreira': 4859,
    'Anjos': 4859,
    'Apelação': 4859,
    'Arcozelo': 4859,
    'Arranhó': 4859,
    'Arrifana': 4859,
    'Arruda Dos Vinhos': 4859,
    'Atalaia': 4859,
    'Aveiras de Baixo': 4859,
    'Aveiras de Cima': 4859,
    'Avelãs da Ribeira': 4859,
    'Aveloso': 4859,
    'Azambuja': 4859,
    'Azambuja (town)': 4859,
    'Azenhas do Mar': 4859,
    'Azevo': 4859,
    'Azueira': 4859,
    'Baraçal': 4859,
    'Barcarena': 4859,
    'Barreira': 4859,
    'Beato': 4859,
    'Belas': 4859,
    'Bendada': 4859,
    'Benespera': 4859,
    'Benfica': 4859,
    'Bismula': 4859,
    'Bobadela': 4859,
    'Bouça Cova': 4859,
    'Brandoa': 4859,
    'Bucelas': 4859,
    'Buraca': 4859,
    'Cabanas de Torres': 4859,
    'Cabeça': 4859,
    'Cacem': 4859,
    'Cachoeiras': 4859,
    'Cadafais': 4859,
    'Cadafaz': 4859,
    'Cadaval': 4859,
    'Calhandriz': 4859,
    'Camarate': 4859,
    'Campelos': 4859,
    'Campo Grande': 4859,
    'Campolide': 4859,
    'Caneças': 4859,
    'Carapito': 4859,
    'Carcavelos': 4859,
    'Cardosas': 4859,
    'Carmões': 4859,
    'Carnaxide': 4859,
    'Carnicães': 4859,
    'Carnide': 4859,
    'Carnota': 4859,
    'Carragozela': 4859,
    'Carrapichana': 4859,
    'Carregado': 4859,
    'Carvalhal': 4859,
    'Carvoeira': 4859,
    'Casal de Cambra': 4859,
    'Casal de Cinza': 4859,
    'Casal Vasco': 4859,
    'Casas do Soeiro': 4859,
    'Cascais': 4859,
    'Castanheira': 4859,
    'Castanheira do Ribatejo': 4859,
    'Casteição': 4859,
    'Casteleiro': 4859,
    'Castelo (Lisbon)': 4859,
    'Castelo Bom': 4859,
    'Castelo Melhor': 4859,
    'Castelo Rodrigo': 4859,
    'Cativelos': 4859,
    'Cavadoude': 4859,
    'Caxias': 4859,
    'Cedovim': 4859,
    'Celorico da Beira': 4859,
    'Cercal': 4859,
    'Cerdeira': 4859,
    'Cerejo': 4859,
    'Charneca': 4859,
    'Chãs': 4859,
    'Cheleiros': 4859,
    'Codesseiro': 4859,
    'Cogula': 4859,
    'Colares': 4859,
    'Coração de Jesus': 4859,
    'Coriscada': 4859,
    'Cortiçada': 4859,
    'Cortiçô': 4859,
    'Cortiçô da Serra': 4859,
    'Coruche': 4859,
    'Corujeira': 4859,
    'Cótimos': 4859,
    'Cruz Quebrada - Dafundo': 4859,
    'Custoias': 4859,
    'Damaia': 4859,
    'Dois Portos': 4859,
    'Dornelas': 4859,
    'Eirado': 4859,
    'Encarnação': 4859,
    'Enxara do Bispo': 4859,
    'Ericeira': 4859,
    'Ervas Tenras': 4859,
    'Ervedosa': 4859,
    'Escalhão': 4859,
    'Estoril': 4859,
    'Faia': 4859,
    'Falagueira': 4859,
    'Famalicão': 4859,
    'Famões': 4859,
    'Fanhões': 4859,
    'Fernão Joanes': 4859,
    'Fiães': 4859,
    'Figueira de Castelo Rodrigo': 4859,
    'Figueira do Guincho': 4859,
    'Figueiró da Granja': 4859,
    'Figueiró da Serra': 4859,
    'Figueiros': 4859,
    'Fóios': 4859,
    'Folgosinho': 4859,
    'Folhadosa': 4859,
    'Fontanelas': 4859,
    'Fonte Longa': 4859,
    'Forninhos': 4859,
    'Forno Telheiro': 4859,
    'Fornos de Algodres': 4859,
    'Forte da Casa': 4859,
    'Freches': 4859,
    'Freineda': 4859,
    'Freiria': 4859,
    'Freixeda do Torrão': 4859,
    'Freixedas': 4859,
    'Freixo': 4859,
    'Freixo de Numão': 4859,
    'Frielas': 4859,
    'Gagos': 4859,
    'Girabolhos': 4859,
    'Gonçalo': 4859,
    'Gonçalo Bocas': 4859,
    'Gouveia': 4859,
    'Graça': 4859,
    'Gradil': 4859,
    'Gradiz': 4859,
    'Granja': 4859,
    'Guarda': 4859,
    'Guilheiro': 4859,
    'Horta': 4859,
    'Igreja Nova': 4859,
    'Infias': 4859,
    'Jarmelo São Miguel': 4859,
    'Jarmelo São Pedro': 4859,
    'João Antão': 4859,
    'Junça': 4859,
    'Juncais': 4859,
    'Lagarinhos': 4859,
    'Lajeosa': 4859,
    'Lajeosa do Mondego': 4859,
    'Lajes': 4859,
    'Lamas': 4859,
    'Lamegal': 4859,
    'Lameiras': 4859,
    'Lapa': 4859,
    'Lapa Dos Dinheiros': 4859,
    'Leomil': 4859,
    'Linda-a-Velha': 4859,
    'Linhares (Celorico da Beira)': 4859,
    'Lisbon': 4859,
    'Longroiva': 4859,
    'Loriga': 4859,
    'Loures': 4859,
    'Lourinhã': 4859,
    'Lousa': 4859,
    'Lumiar': 4859,
    'Maçainhas': 4859,
    'Maçal do Chão': 4859,
    'Maceira': 4859,
    'Maçussa': 4859,
    'Madalena': 4859,
    'Mafra': 4859,
    'Malcata': 4859,
    'Malhada Sorda': 4859,
    'Malpartida': 4859,
    'Malveira': 4859,
    'Mangualde da Serra': 4859,
    'Manigoto': 4859,
    'Manique do Intendente': 4859,
    'Manteigas': 4859,
    'Manteigas (São Pedro)': 4859,
    'Marialva': 4859,
    'Marmeleiro': 4859,
    'Marteleira': 4859,
    'Mártires': 4859,
    'Marvila': 4859,
    'Massamá': 4859,
    'Mata de Lobos': 4859,
    'Matacães': 4859,
    'Matança': 4859,
    'Maxial': 4859,
    'Meca': 4859,
    'Mêda': 4859,
    'Meios': 4859,
    'Melo': 4859,
    'Mem Martins': 4859,
    'Mercês': 4859,
    'Mesquitela': 4859,
    'Milharado': 4859,
    'Mina': 4859,
    'Minhocal': 4859,
    'Mira-Sintra': 4859,
    'Miragaia': 4859,
    'Miuzela': 4859,
    'Mizarela': 4859,
    'Moimenta da Serra': 4859,
    'Moimentinha': 4859,
    'Moita': 4859,
    'Moita dos Ferreiros': 4859,
    'Moledo': 4859,
    'Monte Abraão': 4859,
    'Monte Estoril': 4859,
    'Monte Redondo': 4859,
    'Montelavar': 4859,
    'Moreira de Rei': 4859,
    'Mós': 4859,
    'Moscavide': 4859,
    'Murça': 4859,
    'Muxagata': 4859,
    'Nabais': 4859,
    'Nave': 4859,
    'Nave de Haver': 4859,
    'Nespereira': 4859,
    'Nossa Senhora de Fátima': 4859,
    'Numão': 4859,
    'Odivelas': 4859,
    'Odivelas Municipality': 4859,
    'Oeiras': 4859,
    'Oeiras e São Julião da Barra': 4859,
    'Olhalvo': 4859,
    'Olival de Basto': 4859,
    'Olival do Basto': 4859,
    'Ota': 4859,
    'Outeiro da Cabeça': 4859,
    'Outeiro de Gatos': 4859,
    'Paço de Arcos': 4859,
    'Paços da Serra': 4859,
    'Painho': 4859,
    'Pala': 4859,
    'Palhais': 4859,
    'Panoias de Cima': 4859,
    'Parada': 4859,
    'Paranhos': 4859,
    'Parede': 4859,
    'Pega': 4859,
    'Pena': 4859,
    'Pena Lobo': 4859,
    'Pena Verde': 4859,
    'Penha de Águia': 4859,
    'Penha de França': 4859,
    'Pêra do Moço': 4859,
    'Peral': 4859,
    'Pereiro': 4859,
    'Pereiro de Palhacana': 4859,
    'Pêro Moniz': 4859,
    'Pero Pinheiro': 4859,
    'Peva': 4859,
    'Pinhanços': 4859,
    'Pinheiro': 4859,
    'Pinhel': 4859,
    'Pínzio': 4859,
    'Poço do Canto': 4859,
    'Pomares': 4859,
    'Ponte do Rol': 4859,
    'Pontinha': 4859,
    'Portela': 4859,
    'Porto da Carne': 4859,
    'Porto Salvo': 4859,
    'Pousada': 4859,
    'Pousafoles do Bispo': 4859,
    'Póvoa de Santa Iria': 4859,
    'Póvoa de Santo Adrião': 4859,
    'Póvoa do Concelho': 4859,
    'Prados': 4859,
    'Prazeres': 4859,
    'Prior Velho': 4859,
    'Prova': 4859,
    'Quadrazais': 4859,
    'Queijas': 4859,
    'Queiriz': 4859,
    'Queluz': 4859,
    'Quintã de Pêro Martins': 4859,
    'Quintas de São Bartolomeu': 4859,
    'Rabaçal': 4859,
    'Ramada': 4859,
    'Ramalhal': 4859,
    'Ramela': 4859,
    'Ranhados': 4859,
    'Rapa': 4859,
    'Rapoula do Côa': 4859,
    'Ratoeira': 4859,
    'Reboleira': 4859,
    'Reboleiro': 4859,
    'Rebolosa': 4859,
    'Reguengo Grande': 4859,
    'Reigada': 4859,
    'Rendo': 4859,
    'Ribafria': 4859,
    'Ribamar': 4859,
    'Ribamondego': 4859,
    'Ribeira Dos Carinhos': 4859,
    'Rio de Mel': 4859,
    'Rio de Mouro': 4859,
    'Rio Torto': 4859,
    'Rochoso': 4859,
    'Runa': 4859,
    'Ruvina': 4859,
    'Sabugal': 4859,
    'Sabugueiro': 4859,
    'Sacavém': 4859,
    'Sacramento': 4859,
    'Safurdão': 4859,
    'Salgueirais': 4859,
    'Sameice': 4859,
    'Sameiro': 4859,
    'Sandomil': 4859,
    'Santa Bárbara': 4859,
    'Santa Catarina': 4859,
    'Santa Comba': 4859,
    'Santa Engrácia': 4859,
    'Santa Eufémia': 4859,
    'Santa Eulália': 4859,
    'Santa Iria da Azóia': 4859,
    'Santa Iria de Azoia': 4859,
    'Santa Isabel': 4859,
    'Santa Justa': 4859,
    'Santa Maria': 4859,
    'Santa Maria de Belém': 4859,
    'Santa Maria do Castelo e São Miguel': 4859,
    'Santa Maria dos Olivais': 4859,
    'Santa Maria e São Miguel': 4859,
    'Santa Marinha': 4859,
    'Santana da Azinha': 4859,
    'Santiago': 4859,
    'Santiago dos Velhos': 4859,
    'Santo Antão do Tojal': 4859,
    'Santo António dos Cavaleiros': 4859,
    'Santo Condestável': 4859,
    'Santo Estêvão': 4859,
    'Santo Estêvão das Galés': 4859,
    'Santo Isidoro': 4859,
    'Santo Quintino': 4859,
    'Santos-o-Velho': 4859,
    'São Bartolomeu': 4859,
    'São Bartolomeu dos Galegos': 4859,
    'São Brás': 4859,
    'São Cristóvão e São Lourenço': 4859,
    'São Domingos de Benfica': 4859,
    'São Domingos de Rana': 4859,
    'São Francisco Xavier': 4859,
    'São João': 4859,
    'São João da Talha': 4859,
    'São João das Lampas': 4859,
    'São João de Brito': 4859,
    'São João de Deus': 4859,
    'São João Dos Montes': 4859,
    'São Jorge de Arroios': 4859,
    'São José': 4859,
    'São Julião': 4859,
    'São Julião do Tojal': 4859,
    'São Mamede': 4859,
    'São Marcos': 4859,
    'São Martinho': 4859,
    'São Miguel': 4859,
    'São Miguel da Guarda': 4859,
    'São Miguel de Alcainça': 4859,
    'São Nicolau': 4859,
    'São Paio': 4859,
    'São Paulo': 4859,
    'São Pedro': 4859,
    'São Pedro da Cadeira': 4859,
    'São Pedro de Penaferrim': 4859,
    'São Pedro de Rio Seco': 4859,
    'São Romão': 4859,
    'São Sebastião da Pedreira': 4859,
    'São Vicente': 4859,
    'São Vicente de Fora': 4859,
    'Sapataria': 4859,
    'Sazes da Beira': 4859,
    'Sé': 4859,
    'Sebadelhe': 4859,
    'Sebadelhe da Serra': 4859,
    'Seia': 4859,
    'Seixas': 4859,
    'Seixo do Côa': 4859,
    'Sequeira': 4859,
    'Sequeiros': 4859,
    'Silveira': 4859,
    'Sintra': 4859,
    'Sintra (town)': 4859,
    'Sobral da Abelheira': 4859,
    'Sobral da Serra': 4859,
    'Sobral de Monte Agraço': 4859,
    'Sobral Pichorro': 4859,
    'Sobralinho': 4859,
    'Socorro': 4859,
    'Sortelha': 4859,
    'Souro Pires': 4859,
    'Souto': 4859,
    'Souto de Aguiar da Beira': 4859,
    'Souto Maior': 4859,
    'Tamanhos': 4859,
    'Teixeira': 4859,
    'Terrenho': 4859,
    'Terrugem': 4859,
    'Torre do Terrenho': 4859,
    'Torres': 4859,
    'Torres Vedras': 4859,
    'Torrozelo': 4859,
    'Touça': 4859,
    'Tourais': 4859,
    'Trancoso': 4859,
    'Travancinha': 4859,
    'Triana': 4859,
    'Trinta': 4859,
    'Turcifal': 4859,
    'Unhos': 4859,
    'Valbom': 4859,
    'Valdujo': 4859,
    'Vale da Mula': 4859,
    'Vale de Amoreira': 4859,
    'Vale de Azares': 4859,
    'Vale de Espinho': 4859,
    'Vale de Estrela': 4859,
    'Vale do Paraíso': 4859,
    'Vale do Seixo': 4859,
    'Vale Flor': 4859,
    'Valezim': 4859,
    'Valhelhas': 4859,
    'Valverde': 4859,
    'Várzea de Meruge': 4859,
    'Vascoveiro': 4859,
    'Vela': 4859,
    'Velosa': 4859,
    'Venda do Pinheiro': 4859,
    'Venda Nova': 4859,
    'Venteira': 4859,
    'Ventosa': 4859,
    'Vermelha': 4859,
    'Vermiosa': 4859,
    'Vialonga': 4859,
    'Vide': 4859,
    'Vide Entre Vinhas': 4859,
    'Videmonte': 4859,
    'Vila Boa': 4859,
    'Vila Boa do Mondego': 4859,
    'Vila Cortês da Serra': 4859,
    'Vila Cortês do Mondego': 4859,
    'Vila Cova à Coelheira': 4859,
    'Vila do Touro': 4859,
    'Vila Fernando': 4859,
    'Vila Franca da Serra': 4859,
    'Vila Franca das Naves': 4859,
    'Vila Franca de Xira': 4859,
    'Vila Franca do Deão': 4859,
    'Vila Franca do Rosário': 4859,
    'Vila Garcia': 4859,
    'Vila Nova da Rainha': 4859,
    'Vila Nova de Foz Côa': 4859,
    'Vila Nova de São Pedro': 4859,
    'Vila Nova de Tazem': 4859,
    'Vila Ruiva': 4859,
    'Vila Soeiro do Chão': 4859,
    'Vila Verde': 4859,
    'Vila Verde dos Francos': 4859,
    'Vilar': 4859,
    'Vilar de Amargo': 4859,
    'Vilar Formoso': 4859,
    'Vilar Maior': 4859,
    'Vilar Torpim': 4859,
    'Vilares': 4859,
    'Vimeiro': 4859,
    'Vinhó': 4859,
    'A dos Francos': 2240,
    'Alcobaça': 2240,
    'Alfeizerão': 2240,
    'Aljubarrota': 2240,
    'Alvaiázere': 2240,
    'Alvorninha': 2240,
    'Amor': 2240,
    'Ansião': 2240,
    'Atouguia da Baleia': 2240,
    'Batalha': 2240,
    'Benedita': 2240,
    'Bombarral': 2240,
    'Caldas da Rainha': 2240,
    'Caranguejeira': 2240,
    'Castanheira de Pêra': 2240,
    'Cela': 2240,
    'Famalicão': 2240,
    'Ferrel': 2240,
    'Figueiró Dos Vinhos': 2240,
    'Foz do Arelho': 2240,
    'Leiria': 2240,
    'Louriçal': 2240,
    'Maceira': 2240,
    'Marinha Grande': 2240,
    'Mira': 2240,
    'Monte Real': 2240,
    'Monte Redondo': 2240,
    'Nadadouro': 2240,
    'Nazaré': 2240,
    'Óbidos': 2240,
    'Pataias': 2240,
    'Pedrógão Grande': 2240,
    'Peniche': 2240,
    'Pombal': 2240,
    'Porto de Mós': 2240,
    'Salir de Matos': 2240,
    'Santa Catarina da Serra': 2240,
    'São Martinho do Porto': 2240,
    'Serra de El-Rei': 2240,
    'Souto da Carpalhosa': 2240,
    'Turquel': 2240,
    'Valado de Frades': 2240,
    'Vestiaria': 2240,
    'Vidais': 2240,
    'Vieira de Leiria': 2240,
    'A dos Cunhados': 2228,
    'Abrigada': 2228,
    'Alcabideche': 2228,
    'Alcoentre': 2228,
    'Alenquer': 2228,
    'Alfragide': 2228,
    'Algés': 2228,
    'Algueirão': 2228,
    'Alhandra': 2228,
    'Almargem': 2228,
    'Alvalade': 2228,
    'Amadora': 2228,
    'Apelação': 2228,
    'Arranhó': 2228,
    'Arruda dos Vinhos': 2228,
    'Aveiras de Cima': 2228,
    'Azambuja': 2228,
    'Barcarena': 2228,
    'Beato António': 2228,
    'Belas': 2228,
    'Benfica': 2228,
    'Bobadela': 2228,
    'Bucelas': 2228,
    'Cabanas de Torres': 2228,
    'Cacém': 2228,
    'Cadafais': 2228,
    'Cadaval': 2228,
    'Camarate': 2228,
    'Campelos': 2228,
    'Caneças': 2228,
    'Carcavelos': 2228,
    'Carnaxide': 2228,
    'Carregado': 2228,
    'Cascais': 2228,
    'Castanheira do Ribatejo': 2228,
    'Caxias': 2228,
    'Charneca': 2228,
    'Colares': 2228,
    'Ericeira': 2228,
    'Estoril': 2228,
    'Famões': 2228,
    'Fontanelas': 2228,
    'Linda-a-Velha': 2228,
    'Lisbon': 2228,
    'Loures': 2228,
    'Lourinhã': 2228,
    'Mafra': 2228,
    'Malveira': 2228,
    'Massamá': 2228,
    'Meca': 2228,
    'Mem Martins': 2228,
    'Milharado': 2228,
    'Moita dos Ferreiros': 2228,
    'Monte Estoril': 2228,
    'Moscavide e Portela': 2228,
    'Odivelas': 2228,
    'Oeiras': 2228,
    'Olivais': 2228,
    'Olival do Basto': 2228,
    'Ota': 2228,
    'Paço de Arcos': 2228,
    'Parede': 2228,
    'Pero Pinheiro': 2228,
    'Pontinha': 2228,
    'Porto Salvo': 2228,
    'Póvoa de Santa Iria': 2228,
    'Póvoa de Santo Adrião': 2228,
    'Prior Velho': 2228,
    'Queijas': 2228,
    'Queluz': 2228,
    'Ramada': 2228,
    'Rio de Mouro': 2228,
    'Sacavém': 2228,
    'Santa Iria da Azóia': 2228,
    'Santo Antão do Tojal': 2228,
    'Santo Isidoro': 2228,
    'Santos-o-Velho': 2228,
    'São Bartolomeu': 2228,
    'São Domingos de Rana': 2228,
    'São João da Talha': 2228,
    'São João das Lampas': 2228,
    'São João dos Montes': 2228,
    'São Pedro da Cadeira': 2228,
    'Silveira': 2228,
    'Sintra': 2228,
    'Sobral de Monte Agraço': 2228,
    'Sobralinho': 2228,
    'Terrugem': 2228,
    'Torres Vedras': 2228,
    'Unhos': 2228,
    'Venda do Pinheiro': 2228,
    'Ventosa': 2228,
    'Vialonga': 2228,
    'Vila Franca de Xira': 2228,
    'Vila Verde': 2228,
    'Vimeiro': 2228,
    'Água de Pena': 2231,
    'Arco da Calheta': 2231,
    'Boaventura': 2231,
    'Calheta': 2231,
    'Camacha': 2231,
    'Câmara de Lobos': 2231,
    'Campanário': 2231,
    'Canhas': 2231,
    'Caniçal': 2231,
    'Caniço': 2231,
    'Curral das Freiras': 2231,
    'Estreito da Calheta': 2231,
    'Faial': 2231,
    'Fajã da Ovelha': 2231,
    'Funchal': 2231,
    'Machico': 2231,
    'Nossa Senhora do Monte': 2231,
    'Ponta do Sol': 2231,
    'Porto da Cruz': 2231,
    'Porto Moniz': 2231,
    'Porto Santo': 2231,
    'Ribeira Brava': 2231,
    'Santa Cruz': 2231,
    'Santana': 2231,
    'São Jorge': 2231,
    'São Martinho': 2231,
    'São Roque': 2231,
    'São Vicente': 2231,
    'Alter do Chão': 2232,
    'Arronches': 2232,
    'Atalaia': 2232,
    'Avis': 2232,
    'Campo Maior': 2232,
    'Castelo de Vide': 2232,
    'Crato': 2232,
    'Elvas': 2232,
    'Fronteira': 2232,
    'Gavião': 2232,
    'Marvão': 2232,
    'Monforte': 2232,
    'Montargil': 2232,
    'Nisa': 2232,
    'Ponte de Sor': 2232,
    'Portalegre': 2232,
    'Santo André': 2232,
    'Sousel': 2232,
    'Vale da Amoreira': 2232,
    'Água Longa': 2243,
    'Águas Santas': 2243,
    'Aguçadoura': 2243,
    'Alfena': 2243,
    'Alpendurada': 2243,
    'Amarante': 2243,
    'Amorim': 2243,
    'Anta': 2243,
    'Arcozelo': 2243,
    'Argivai': 2243,
    'Árvore': 2243,
    'Aver-o-Mar': 2243,
    'Aves': 2243,
    'Avintes': 2243,
    'Azenha': 2243,
    'Baguim do Monte': 2243,
    'Baião': 2243,
    'Balazar': 2243,
    'Baltar': 2243,
    'Barrosas': 2243,
    'Beiriz de Baixo': 2243,
    'Bougado': 2243,
    'Campo': 2243,
    'Canelas': 2243,
    'Canidelo': 2243,
    'Carvalhosa': 2243,
    'Castelões de Cepeda': 2243,
    'Ermesinde': 2243,
    'Estela': 2243,
    'Fânzeres': 2243,
    'Felgueiras': 2243,
    'Ferreira': 2243,
    'Figueiró': 2243,
    'Foz do Douro': 2243,
    'Foz do Sousa': 2243,
    'Frazão': 2243,
    'Freamunde': 2243,
    'Gandra': 2243,
    'Gemunde': 2243,
    'Gondomar': 2243,
    'Grijó': 2243,
    'Guifões': 2243,
    'Gulpilhares': 2243,
    'Jovim': 2243,
    'Lavra': 2243,
    'Leça da Palmeira': 2243,
    'Leça do Bailio': 2243,
    'Lordelo': 2243,
    'Lousada': 2243,
    'Lustosa': 2243,
    'Madalena': 2243,
    'Maia': 2243,
    'Marco de Canaveses': 2243,
    'Marco de Canavezes': 2243,
    'Margaride': 2243,
    'Matosinhos': 2243,
    'Meinedo': 2243,
    'Melres': 2243,
    'Milheirós': 2243,
    'Mindelo': 2243,
    'Moreira': 2243,
    'Negrelos': 2243,
    'Nogueira': 2243,
    'Olival': 2243,
    'Oliveira do Douro': 2243,
    'Paços de Ferreira': 2243,
    'Paredes': 2243,
    'Pedroso': 2243,
    'Pedrouços': 2243,
    'Penafiel': 2243,
    'Perafita': 2243,
    'Perozinho': 2243,
    'Porto': 2243,
    'Póvoa de Varzim': 2243,
    'Rebordões': 2243,
    'Rebordosa': 2243,
    'Recarei': 2243,
    'Rio Mau': 2243,
    'Rio Tinto': 2243,
    'Sandim': 2243,
    'Santa Cruz do Bispo': 2243,
    'Santo Izidoro': 2243,
    'Santo Tirso': 2243,
    'São Félix da Marinha': 2243,
    'São Mamede de Infesta': 2243,
    'São Miguel do Couto': 2243,
    'São Pedro da Cova': 2243,
    'São Romão do Coronado': 2243,
    'Senhora da Hora': 2243,
    'Sermonde': 2243,
    'Seroa': 2243,
    'Serzedo': 2243,
    'Sobrado': 2243,
    'Telões': 2243,
    'Trofa': 2243,
    'Vairão': 2243,
    'Valadares': 2243,
    'Valbom': 2243,
    'Valongo': 2243,
    'Vila do Conde': 2243,
    'Vila Meã': 2243,
    'Vila Nova da Telha': 2243,
    'Vila Nova de Gaia': 2243,
    'Vilar de Andorinho': 2243,
    'Vilar do Paraíso': 2243,
    'Vilarinho': 2243,
    'Vizela': 2243,
    'Abrantes': 2238,
    'Alcanede': 2238,
    'Alcanena': 2238,
    'Alcanhões': 2238,
    'Alferrarede': 2238,
    'Almeirim': 2238,
    'Alpiarça': 2238,
    'Amiães de Baixo': 2238,
    'Azinhaga': 2238,
    'Bemfica': 2238,
    'Benavente': 2238,
    'Cartaxo': 2238,
    'Chamusca': 2238,
    'Constância': 2238,
    'Coruche': 2238,
    'Entroncamento': 2238,
    'Fátima': 2238,
    'Fazendas de Almeirim': 2238,
    'Ferreira do Zêzere': 2238,
    'Golegã': 2238,
    'Mação': 2238,
    'Madalena': 2238,
    'Marinhais': 2238,
    'Minde': 2238,
    'Monsanto': 2238,
    'Montalvinho': 2238,
    'Montalvo': 2238,
    'Ourém': 2238,
    'Paialvo': 2238,
    'Pego': 2238,
    'Pinheiro Grande': 2238,
    'Poceirão': 2238,
    'Pontével': 2238,
    'Praia do Ribatejo': 2238,
    'Riachos': 2238,
    'Rio Maior': 2238,
    'Salvaterra de Magos': 2238,
    'Samora Correia': 2238,
    'Santa Margarida da Coutada': 2238,
    'Santarém': 2238,
    'São Miguel de Rio Torto': 2238,
    'São Vicente do Paul': 2238,
    'Sardoal': 2238,
    'Tomar': 2238,
    'Torres Novas': 2238,
    'Tramagal': 2238,
    'Vale de Figueira': 2238,
    'Vale de Santarém': 2238,
    'Vila Chã de Ourique': 2238,
    'Vila Nova da Barquinha': 2238,
    'Alcácer do Sal': 2242,
    'Alcochete': 2242,
    'Aldeia de Paio Pires': 2242,
    'Alhos Vedros': 2242,
    'Almada': 2242,
    'Amora': 2242,
    'Arrentela': 2242,
    'Azeitão': 2242,
    'Barreiro': 2242,
    'Cacilhas': 2242,
    'Caparica': 2242,
    'Cercal': 2242,
    'Charneca de Caparica': 2242,
    'Comporta': 2242,
    'Corroios': 2242,
    'Costa de Caparica': 2242,
    'Grândola': 2242,
    'Laranjeira': 2242,
    'Lavradio': 2242,
    'Moita': 2242,
    'Montijo': 2242,
    'Palmela': 2242,
    'Piedade': 2242,
    'Pinhal Novo': 2242,
    'Porto Covo': 2242,
    'Pragal': 2242,
    'Quinta do Anjo': 2242,
    'Quinta Do Conde': 2242,
    'Rosairinho': 2242,
    'Samouco': 2242,
    'Santiago do Cacém': 2242,
    'Santo André': 2242,
    'Santo António da Charneca': 2242,
    'Sarilhos Pequenos': 2242,
    'Seixal': 2242,
    'Sesimbra': 2242,
    'Setúbal': 2242,
    'Sines': 2242,
    'Sobreda': 2242,
    'Trafaria': 2242,
    'Arcos de Valdevez': 2245,
    'Caminha': 2245,
    'Melgaço': 2245,
    'Monção': 2245,
    'Paredes de Coura': 2245,
    'Ponte da Barca': 2245,
    'Ponte de Lima': 2245,
    'Valença': 2245,
    'Viana do Castelo': 2245,
    'Alijó': 2234,
    'Boticas': 2234,
    'Chaves': 2234,
    'Favaios': 2234,
    'Godim': 2234,
    'Mesão Frio': 2234,
    'Mondim de Basto': 2234,
    'Montalegre': 2234,
    'Murça': 2234,
    'Peso da Régua': 2234,
    'Ribeira de Pena': 2234,
    'Sabrosa': 2234,
    'Santa Cruz': 2234,
    'Santa Marta de Penaguião': 2234,
    'Sobreira': 2234,
    'Valpaços': 2234,
    'Vila Pouca de Aguiar': 2234,
    'Vila Real': 2234,
    'Vilela': 2234,
    'Abraveses': 2237,
    'Armamar': 2237,
    'Cabanas de Viriato': 2237,
    'Campo': 2237,
    'Campo de Besteiros': 2237,
    'Canas de Senhorim': 2237,
    'Carregal do Sal': 2237,
    'Castro Daire': 2237,
    'Cinfães': 2237,
    'Ervedosa do Douro': 2237,
    'Lamego': 2237,
    'Mangualde': 2237,
    'Moimenta da Beira': 2237,
    'Mortágua': 2237,
    'Nelas': 2237,
    'Oliveira de Frades': 2237,
    'Penalva do Castelo': 2237,
    'Penedono': 2237,
    'Resende': 2237,
    'Rio de Loba': 2237,
    'Santa Comba Dão': 2237,
    'São João da Pesqueira': 2237,
    'São João de Areias': 2237,
    'São Pedro do Sul': 2237,
    'Sátão': 2237,
    'Sernancelhe': 2237,
    'Tabuaço': 2237,
    'Tarouca': 2237,
    'Tondela': 2237,
    'Treixedo': 2237,
    'Vila Nova de Paiva': 2237,
    'Viseu': 2237,
    'Vouzela': 2237,
    'Al Ghuwayrīyah': 3183,
    'Al Khawr': 3183,
    'Ar Rayyān': 3177,
    'Umm Bāb': 3177,
    'Al Wakrah': 3179,
    'Al Wukayr': 3179,
    'Musay‘īd': 3179,
    'Al Jumaylīyah': 3178,
    'Ash Shīḩānīyah': 3178,
    'Dukhān': 3178,
    'Doha': 3181,
    'Ar Ruways': 3180,
    'Fuwayriţ': 3180,
    'Madīnat ash Shamāl': 3180,
    'Umm Şalāl Muḩammad': 3184,
    'Abrud': 4724,
    'Abrud-Sat': 4724,
    'Aiud': 4724,
    'Aiudul de Sus': 4724,
    'Alba Iulia': 4724,
    'Albac': 4724,
    'Almaşu Mare': 4724,
    'Arieşeni': 4724,
    'Avram Iancu': 4724,
    'Baia de Arieş': 4724,
    'Bălcaciu': 4724,
    'Bărăbanț': 4724,
    'Berghin': 4724,
    'Biia': 4724,
    'Bistra': 4724,
    'Blaj': 4724,
    'Blândiana': 4724,
    'Blideşti': 4724,
    'Bucerdea-Grânoasă': 4724,
    'Bucium': 4724,
    'Câlnic': 4724,
    'Câmpeni': 4724,
    'Cenade': 4724,
    'Cergău Mare': 4724,
    'Cergău Mic': 4724,
    'Ceru-Băcăinţi': 4724,
    'Cetatea de Baltă': 4724,
    'Ciugud': 4724,
    'Ciumbrud': 4724,
    'Ciuruleasa': 4724,
    'Comuna Albac': 4724,
    'Comuna Almaşu Mare': 4724,
    'Comuna Arieşeni': 4724,
    'Comuna Avram Iancu': 4724,
    'Comuna Berghin': 4724,
    'Comuna Bistra': 4724,
    'Comuna Blândiana': 4724,
    'Comuna Bucerdea-Grânoasă': 4724,
    'Comuna Bucium': 4724,
    'Comuna Câlnic': 4724,
    'Comuna Cenade': 4724,
    'Comuna Cergău': 4724,
    'Comuna Ceru-Băcăinţi': 4724,
    'Comuna Cetatea de Baltă': 4724,
    'Comuna Ciugud': 4724,
    'Comuna Ciuruleasa': 4724,
    'Comuna Crăciunelu de Jos': 4724,
    'Comuna Cricău': 4724,
    'Comuna Cut': 4724,
    'Comuna Daia Română': 4724,
    'Comuna Doştat': 4724,
    'Comuna Fărău': 4724,
    'Comuna Galda de Jos': 4724,
    'Comuna Gârbova': 4724,
    'Comuna Gârda de Sus': 4724,
    'Comuna Hopârta': 4724,
    'Comuna Horea': 4724,
    'Comuna Ighiu': 4724,
    'Comuna Întregalde': 4724,
    'Comuna Jidvei': 4724,
    'Comuna Livezile': 4724,
    'Comuna Lopadea Nouă': 4724,
    'Comuna Lunca Mureşului': 4724,
    'Comuna Lupşa': 4724,
    'Comuna Meteş': 4724,
    'Comuna Mihalţ': 4724,
    'Comuna Mirăslău': 4724,
    'Comuna Mogoş': 4724,
    'Comuna Noşlac': 4724,
    'Comuna Ocoliş': 4724,
    'Comuna Ohaba': 4724,
    'Comuna Pianu': 4724,
    'Comuna Poiana Vadului': 4724,
    'Comuna Ponor': 4724,
    'Comuna Poşaga': 4724,
    'Comuna Rădeşti': 4724,
    'Comuna Râmeţ': 4724,
    'Comuna Râmetea': 4724,
    'Comuna Roşia de Secaş': 4724,
    'Comuna Roşia Montană': 4724,
    'Comuna Sălciua': 4724,
    'Comuna Sãliştea': 4724,
    'Comuna Sâncel': 4724,
    'Comuna Sântimbru': 4724,
    'Comuna Săsciori': 4724,
    'Comuna Scărişoara': 4724,
    'Comuna Şibot': 4724,
    'Comuna Sohodol': 4724,
    'Comuna Şona': 4724,
    'Comuna Şpring': 4724,
    'Comuna Stremţ': 4724,
    'Comuna Şugag': 4724,
    'Comuna Unirea': 4724,
    'Comuna Vadu Moţilor': 4724,
    'Comuna Valea Lungă': 4724,
    'Comuna Vidra': 4724,
    'Comuna Vinţu de Jos': 4724,
    'Crăciunelu de Jos': 4724,
    'Cricău': 4724,
    'Cugir': 4724,
    'Cut': 4724,
    'Daia Română': 4724,
    'Dobra': 4724,
    'Doştat': 4724,
    'Fărău': 4724,
    'Feisa': 4724,
    'Feneș': 4724,
    'Galda de Jos': 4724,
    'Gârbova': 4724,
    'Gârda de Sus': 4724,
    'Hopârta': 4724,
    'Horea': 4724,
    'Ighiel': 4724,
    'Ighiu': 4724,
    'Intregalde': 4724,
    'Izvoarele': 4724,
    'Jidvei': 4724,
    'Lancrăm': 4724,
    'Livezile': 4724,
    'Lopadea Nouă': 4724,
    'Lunca Mureşului': 4724,
    'Lupşa': 4724,
    'Meteş': 4724,
    'Micești': 4724,
    'Mihalţ': 4724,
    'Mirăslău': 4724,
    'Mogoș': 4724,
    'Municipiul Aiud': 4724,
    'Municipiul Alba Iulia': 4724,
    'Municipiul Blaj': 4724,
    'Municipiul Sebeş': 4724,
    'Noşlac': 4724,
    'Oarda': 4724,
    'Ocna Mureş': 4724,
    'Ocoliş': 4724,
    'Ohaba': 4724,
    'Oraş abrud': 4724,
    'Oraş Baia de Arieş': 4724,
    'Oraş Câmpeni': 4724,
    'Oraş Cugir': 4724,
    'Oraş Ocna Mureş': 4724,
    'Oraş Teiuş': 4724,
    'Oraş Zlatna': 4724,
    'Petrești': 4724,
    'Pianu de Jos': 4724,
    'Pianu de Sus': 4724,
    'Poiana Vadului': 4724,
    'Poienile-Mogoş': 4724,
    'Ponor': 4724,
    'Poşaga de Sus': 4724,
    'Rădeşti': 4724,
    'Războieni-Cetate': 4724,
    'Rimetea': 4724,
    'Roşia de Secaş': 4724,
    'Roșia Montană': 4724,
    'Sălciua de Sus': 4724,
    'Săliștea': 4724,
    'Săliştea-Deal': 4724,
    'Sâncel': 4724,
    'Sâncrai': 4724,
    'Sântimbru': 4724,
    'Șard': 4724,
    'Săsciori': 4724,
    'Scărişoara': 4724,
    'Sebeş': 4724,
    'Sebeșel': 4724,
    'Şibot': 4724,
    'Sohodol': 4724,
    'Şona': 4724,
    'Şpring': 4724,
    'Stremţ': 4724,
    'Şugag': 4724,
    'Teiuş': 4724,
    'Țelna': 4724,
    'Tiur': 4724,
    'Uioara de Jos': 4724,
    'Unirea': 4724,
    'Vadu Moţilor': 4724,
    'Valea Lungă Alba Romania': 4724,
    'Veseuș': 4724,
    'Veza': 4724,
    'Vidra': 4724,
    'Vinerea': 4724,
    'Vințu de Jos': 4724,
    'Vurpăr': 4724,
    'Zlatna': 4724,
    'Adea': 4739,
    'Agrișu Mare': 4739,
    'Almaş': 4739,
    'Andrei Șaguna': 4739,
    'Apateu': 4739,
    'Arad': 4739,
    'Archiş': 4739,
    'Bârsa': 4739,
    'Bârzava': 4739,
    'Bata': 4739,
    'Beliu': 4739,
    'Birchiş': 4739,
    'Bocsig': 4739,
    'Brazii': 4739,
    'Buteni': 4739,
    'Caporal Alexa': 4739,
    'Cărand': 4739,
    'Cermei': 4739,
    'Chereluș': 4739,
    'Chesinț': 4739,
    'Chier': 4739,
    'Chisindia': 4739,
    'Chişineu-Criş': 4739,
    'Cintei': 4739,
    'Comuna Almaş': 4739,
    'Comuna Apateu': 4739,
    'Comuna Archiş': 4739,
    'Comuna Bârsa': 4739,
    'Comuna Bârzava': 4739,
    'Comuna Bata': 4739,
    'Comuna Beliu': 4739,
    'Comuna Birchiş': 4739,
    'Comuna Bocsig': 4739,
    'Comuna Brazii': 4739,
    'Comuna Buteni': 4739,
    'Comuna Cărand': 4739,
    'Comuna Cermei': 4739,
    'Comuna Chisindia': 4739,
    'Comuna Conop': 4739,
    'Comuna Covăsinţ': 4739,
    'Comuna Craiva': 4739,
    'Comuna Dezna': 4739,
    'Comuna Dieci': 4739,
    'Comuna Dorobanți': 4739,
    'Comuna Fântânele': 4739,
    'Comuna Felnac': 4739,
    'Comuna Frumuşeni': 4739,
    'Comuna Ghioroc': 4739,
    'Comuna Grăniceri': 4739,
    'Comuna Gurahonţ': 4739,
    'Comuna Hălmăgel': 4739,
    'Comuna Hălmagiu': 4739,
    'Comuna Hăşmaş': 4739,
    'Comuna Igneşti': 4739,
    'Comuna Iratoşu': 4739,
    'Comuna Livada': 4739,
    'Comuna Macea': 4739,
    'Comuna Mişca': 4739,
    'Comuna Moneasa': 4739,
    'Comuna Olari': 4739,
    'Comuna Păuliş': 4739,
    'Comuna Peregu Mare': 4739,
    'Comuna Petriş': 4739,
    'Comuna Pilu': 4739,
    'Comuna Pleşcuţa': 4739,
    'Comuna Şagu': 4739,
    'Comuna Săvârşin': 4739,
    'Comuna Secusigiu': 4739,
    'Comuna Şeitin': 4739,
    'Comuna Seleuş': 4739,
    'Comuna Semlac': 4739,
    'Comuna Şepreuş': 4739,
    'Comuna Şicula': 4739,
    'Comuna Şilindia': 4739,
    'Comuna Şimand': 4739,
    'Comuna Sintea Mare': 4739,
    'Comuna Şiria': 4739,
    'Comuna Şiştarovăţ': 4739,
    'Comuna Socodor': 4739,
    'Comuna Şofronea': 4739,
    'Comuna Târnova': 4739,
    'Comuna Tauţ': 4739,
    'Comuna Ususău': 4739,
    'Comuna Vărădia de Mureş': 4739,
    'Comuna Vârfurile': 4739,
    'Comuna Vinga': 4739,
    'Comuna Vladimirescu': 4739,
    'Comuna Zăbrani': 4739,
    'Comuna Zădăreni': 4739,
    'Comuna Zărand': 4739,
    'Comuna Zerind': 4739,
    'Comuna Zimandu Nou': 4739,
    'Conop': 4739,
    'Covăsinţ': 4739,
    'Craiva': 4739,
    'Curtici': 4739,
    'Cuvin': 4739,
    'Dezna': 4739,
    'Dieci': 4739,
    'Dorgoş': 4739,
    'Dorobanți': 4739,
    'Drauț': 4739,
    'Fântânele': 4739,
    'Felnac': 4739,
    'Frumușeni': 4739,
    'Galșa': 4739,
    'Ghioroc': 4739,
    'Grăniceri': 4739,
    'Gurahonţ': 4739,
    'Gurba': 4739,
    'Hălmăgel': 4739,
    'Hălmagiu': 4739,
    'Hășmaș': 4739,
    'Horia': 4739,
    'Igneşti': 4739,
    'Ineu': 4739,
    'Iratoşu': 4739,
    'Lipova': 4739,
    'Livada': 4739,
    'Macea': 4739,
    'Măderat': 4739,
    'Mailat': 4739,
    'Mănăștur': 4739,
    'Mândruloc': 4739,
    'Mâsca': 4739,
    'Mișca': 4739,
    'Moneasa': 4739,
    'Municipiul Arad': 4739,
    'Nadab': 4739,
    'Nadăș': 4739,
    'Nădlac': 4739,
    'Neudorf': 4739,
    'Olari': 4739,
    'Oraş Chişineu-Criş': 4739,
    'Oraş Curtici': 4739,
    'Oraş Ineu': 4739,
    'Oraş Lipova': 4739,
    'Oraş Nãdlac': 4739,
    'Oraş Pâncota': 4739,
    'Oraş Sântana': 4739,
    'Oraş Sebiş': 4739,
    'Pâncota': 4739,
    'Păuliş': 4739,
    'Pecica': 4739,
    'Peregu Mare': 4739,
    'Peregu Mic': 4739,
    'Petriş': 4739,
    'Pilu': 4739,
    'Pleşcuţa': 4739,
    'Radna': 4739,
    'Şagu': 4739,
    'Sâmbăteni': 4739,
    'Sânmartin': 4739,
    'Sânpetru German': 4739,
    'Sântana': 4739,
    'Satu Mare': 4739,
    'Săvârşin': 4739,
    'Sebiş': 4739,
    'Secusigiu': 4739,
    'Şeitin': 4739,
    'Seleuş': 4739,
    'Semlac': 4739,
    'Șepreuș': 4739,
    'Șiclău': 4739,
    'Şicula': 4739,
    'Şilindia': 4739,
    'Şimand': 4739,
    'Sintea Mare': 4739,
    'Şiria': 4739,
    'Şiştarovăţ': 4739,
    'Socodor': 4739,
    'Şofronea': 4739,
    'Târnova': 4739,
    'Tauţ': 4739,
    'Țipar': 4739,
    'Turnu': 4739,
    'Ususău': 4739,
    'Vânători': 4739,
    'Vărădia de Mureş': 4739,
    'Vârfurile': 4739,
    'Vinga': 4739,
    'Vladimirescu': 4739,
    'Zăbrani': 4739,
    'Zădăreni': 4739,
    'Zărand': 4739,
    'Zerind': 4739,
    'Zimandu Nou': 4739,
    'Albeşti': 4722,
    'Albeştii Pământeni': 4722,
    'Albeștii Ungureni': 4722,
    'Albota': 4722,
    'Albota de Jos': 4722,
    'Aninoasa': 4722,
    'Arefu': 4722,
    'Argeșelu': 4722,
    'Băbana': 4722,
    'Bădești': 4722,
    'Băiculeşti': 4722,
    'Băjești': 4722,
    'Bălileşti': 4722,
    'Bărăști': 4722,
    'Bârla': 4722,
    'Bârlogu': 4722,
    'Başcov': 4722,
    'Beleţi': 4722,
    'Berevoeşti': 4722,
    'Bogaţi': 4722,
    'Boteni': 4722,
    'Boţeşti': 4722,
    'Bradu': 4722,
    'Brăduleţ': 4722,
    'Bucșenești': 4722,
    'Bucșenești-Lotași': 4722,
    'Budeasa Mică': 4722,
    'Bughea de Jos': 4722,
    'Bughea de Sus': 4722,
    'Buzoeşti': 4722,
    'Căldăraru': 4722,
    'Călineşti': 4722,
    'Câmpulung': 4722,
    'Căpățânenii Pământeni': 4722,
    'Capu Piscului': 4722,
    'Căteasca': 4722,
    'Ceparii Pământeni': 4722,
    'Cetăţeni': 4722,
    'Cicănești': 4722,
    'Ciofrângeni': 4722,
    'Ciomăgeşti': 4722,
    'Ciulnița': 4722,
    'Cocu': 4722,
    'Colibași': 4722,
    'Comuna Albeștii de Argeș': 4722,
    'Comuna Albeștii de Muscel': 4722,
    'Comuna Albota': 4722,
    'Comuna Aninoasa': 4722,
    'Comuna Arefu': 4722,
    'Comuna Băbana': 4722,
    'Comuna Băiculeşti': 4722,
    'Comuna Bălileşti': 4722,
    'Comuna Bârla': 4722,
    'Comuna Başcov': 4722,
    'Comuna Beleţi-Negreşti': 4722,
    'Comuna Berevoeşti': 4722,
    'Comuna Bogaţi': 4722,
    'Comuna Boteni': 4722,
    'Comuna Boţeşti': 4722,
    'Comuna Bradu': 4722,
    'Comuna Brăduleţ': 4722,
    'Comuna Budeasa': 4722,
    'Comuna Bughea de Jos': 4722,
    'Comuna Bughea de Sus': 4722,
    'Comuna Buzoeşti': 4722,
    'Comuna Căldăraru': 4722,
    'Comuna Călineşti': 4722,
    'Comuna Căteasca': 4722,
    'Comuna Cepari': 4722,
    'Comuna Cetăţeni': 4722,
    'Comuna Cicănești': 4722,
    'Comuna Ciofrângeni': 4722,
    'Comuna Ciomăgeşti': 4722,
    'Comuna Cocu': 4722,
    'Comuna Corbeni': 4722,
    'Comuna Corbi': 4722,
    'Comuna Coşeşti': 4722,
    'Comuna Cotmeana': 4722,
    'Comuna Cuca': 4722,
    'Comuna Dâmbovicioara': 4722,
    'Comuna Dărmăneşti': 4722,
    'Comuna Davideşti': 4722,
    'Comuna Dobreşti': 4722,
    'Comuna Domneşti': 4722,
    'Comuna Drăganu': 4722,
    'Comuna Dragoslavele': 4722,
    'Comuna Godeni': 4722,
    'Comuna Hârseşti': 4722,
    'Comuna Hârtieşti': 4722,
    'Comuna Izvoru': 4722,
    'Comuna Leordeni': 4722,
    'Comuna Lereşti': 4722,
    'Comuna Lunca Corbului': 4722,
    'Comuna Mălureni': 4722,
    'Comuna Mărăcineni': 4722,
    'Comuna Merișani': 4722,
    'Comuna Miceşti': 4722,
    'Comuna Mihăeşti': 4722,
    'Comuna Mioarele': 4722,
    'Comuna Miroşi': 4722,
    'Comuna Morărești': 4722,
    'Comuna Moşoaia': 4722,
    'Comuna Mozăceni': 4722,
    'Comuna Mușătești': 4722,
    'Comuna Negraşi': 4722,
    'Comuna Nucşoara': 4722,
    'Comuna Oarja': 4722,
    'Comuna Pietroşani': 4722,
    'Comuna Poiana Lacului': 4722,
    'Comuna Poienarii de Argeş': 4722,
    'Comuna Poienarii de Muscel': 4722,
    'Comuna Popeşti': 4722,
    'Comuna Priboieni': 4722,
    'Comuna Râca': 4722,
    'Comuna Răteşti': 4722,
    'Comuna Recea': 4722,
    'Comuna Rociu': 4722,
    'Comuna Rucăr': 4722,
    'Comuna Sălătrucu': 4722,
    'Comuna Săpata': 4722,
    'Comuna Schitu-Goleşti': 4722,
    'Comuna Slobozia': 4722,
    'Comuna Stâlpeni': 4722,
    'Comuna Ştefan cel Mare': 4722,
    'Comuna Stoeneşti': 4722,
    'Comuna Stolnici': 4722,
    'Comuna Şuici': 4722,
    'Comuna Suseni': 4722,
    'Comuna Teiu': 4722,
    'Comuna Tigveni': 4722,
    'Comuna Țițești': 4722,
    'Comuna Uda': 4722,
    'Comuna Ungheni': 4722,
    'Comuna Valea Danului': 4722,
    'Comuna Valea Iaşului': 4722,
    'Comuna Valea Mare-Pravăţ': 4722,
    'Comuna Vedea': 4722,
    'Comuna Vlădeşti': 4722,
    'Comuna Vultureşti': 4722,
    'Conțești': 4722,
    'Corbeni': 4722,
    'Corbi': 4722,
    'Cornățel': 4722,
    'Coşeşti': 4722,
    'Costeşti': 4722,
    'Cotenești': 4722,
    'Cotmeana': 4722,
    'Cuca': 4722,
    'Curtea de Argeş': 4722,
    'Dâmbovicioara': 4722,
    'Dărmăneşti': 4722,
    'Davideşti': 4722,
    'Dobreşti': 4722,
    'Dobrotu': 4722,
    'Domneşti': 4722,
    'Drăganu-Olteni': 4722,
    'Drăghici': 4722,
    'Dragoslavele': 4722,
    'Făgetu': 4722,
    'Gălășești': 4722,
    'Geamăna': 4722,
    'Godeni': 4722,
    'Golești': 4722,
    'Gorganu': 4722,
    'Hârseşti': 4722,
    'Hârtieşti': 4722,
    'Humele': 4722,
    'Izvoru': 4722,
    'Izvoru de Sus': 4722,
    'Jugur': 4722,
    'Jupânești': 4722,
    'Lăzărești': 4722,
    'Leicești': 4722,
    'Leordeni': 4722,
    'Lereşti': 4722,
    'Livezeni': 4722,
    'Lunca Corbului': 4722,
    'Mălureni': 4722,
    'Mărăcineni': 4722,
    'Mareș': 4722,
    'Mârţeşti': 4722,
    'Mățău': 4722,
    'Merişani': 4722,
    'Miceşti': 4722,
    'Mihăeşti': 4722,
    'Mioveni': 4722,
    'Miroşi': 4722,
    'Morăreşti': 4722,
    'Moşoaia': 4722,
    'Mozăceni': 4722,
    'Municipiul Câmpulung': 4722,
    'Municipiul Curtea de Argeș': 4722,
    'Municipiul Piteşti': 4722,
    'Mușătești': 4722,
    'Negraşi': 4722,
    'Nucşoara': 4722,
    'Oarja': 4722,
    'Oarja Sat': 4722,
    'Oeștii Pământeni': 4722,
    'Oraș Costeşti': 4722,
    'Oraș Mioveni': 4722,
    'Oraș Ştefăneşti': 4722,
    'Oraș Topoloveni': 4722,
    'Pătuleni': 4722,
    'Petrești': 4722,
    'Pietroşani': 4722,
    'Piteşti': 4722,
    'Poiana Lacului': 4722,
    'Poienari': 4722,
    'Popeşti': 4722,
    'Priboieni': 4722,
    'Purcăreni': 4722,
    'Râca': 4722,
    'Racovița': 4722,
    'Rădești': 4722,
    'Răteşti': 4722,
    'Recea': 4722,
    'Retevoiești': 4722,
    'Rociu': 4722,
    'Rucăr': 4722,
    'Sălătrucu': 4722,
    'Schitu-Goleşti': 4722,
    'Șerbănești': 4722,
    'Șerboeni': 4722,
    'Slănic': 4722,
    'Slobozia': 4722,
    'Stâlpeni': 4722,
    'Ştefan cel Mare': 4722,
    'Ştefăneşti': 4722,
    'Stoeneşti': 4722,
    'Stolnici': 4722,
    'Strâmbeni': 4722,
    'Stroești': 4722,
    'Şuici': 4722,
    'Surdulești': 4722,
    'Suseni': 4722,
    'Teiu': 4722,
    'Tigveni': 4722,
    'Titeşti': 4722,
    'Toplița': 4722,
    'Topoloveni': 4722,
    'Tutana': 4722,
    'Uda': 4722,
    'Ungheni': 4722,
    'Valea Caselor': 4722,
    'Valea Danului': 4722,
    'Valea Iaşului': 4722,
    'Valea Mare Pravăț': 4722,
    'Valea Mare-Podgoria': 4722,
    'Valea Popii': 4722,
    'Văleni-Podgoria': 4722,
    'Vedea': 4722,
    'Vlădești': 4722,
    'Voinești': 4722,
    'Vrănești': 4722,
    'Vulturești': 4722,
    'Agăş': 4744,
    'Apa Asău': 4744,
    'Ardeoani': 4744,
    'Arini': 4744,
    'Asău': 4744,
    'Bacău': 4744,
    'Băcioiu': 4744,
    'Balcani': 4744,
    'Barați': 4744,
    'Bârsăneşti': 4744,
    'Berești': 4744,
    'Bereşti-Bistriţa': 4744,
    'Bereşti-Tazlău': 4744,
    'Berzunţi': 4744,
    'Bijghir': 4744,
    'Blăgeşti': 4744,
    'Bogdăneşti': 4744,
    'Bolătău': 4744,
    'Bolovăniș': 4744,
    'Brătești': 4744,
    'Brătila': 4744,
    'Brusturoasa': 4744,
    'Buciumi': 4744,
    'Bucșești': 4744,
    'Buda': 4744,
    'Buhoci': 4744,
    'Buhuşi': 4744,
    'Căiuți': 4744,
    'Căiuţi-Sat': 4744,
    'Caraclău': 4744,
    'Cârligi': 4744,
    'Caşin': 4744,
    'Cerdac': 4744,
    'Cernu': 4744,
    'Cireșoaia': 4744,
    'Ciugheș': 4744,
    'Cleja': 4744,
    'Coloneşti': 4744,
    'Comăneşti': 4744,
    'Comuna Agăş': 4744,
    'Comuna Ardeoani': 4744,
    'Comuna Asău': 4744,
    'Comuna Balcani': 4744,
    'Comuna Bârsăneşti': 4744,
    'Comuna Bereşti-Bistriţa': 4744,
    'Comuna Bereşti-Tazlău': 4744,
    'Comuna Berzunţi': 4744,
    'Comuna Blăgeşti': 4744,
    'Comuna Bogdăneşti': 4744,
    'Comuna Brusturoasa': 4744,
    'Comuna Buciumi': 4744,
    'Comuna Buhoci': 4744,
    'Comuna Căiuţi': 4744,
    'Comuna Cleja': 4744,
    'Comuna Coloneşti': 4744,
    'Comuna Corbasca': 4744,
    'Comuna Coţofăneşti': 4744,
    'Comuna Dămieneşti': 4744,
    'Comuna Dealu Morii': 4744,
    'Comuna Dofteana': 4744,
    'Comuna Faraoani': 4744,
    'Comuna Filipeni': 4744,
    'Comuna Filipeşti': 4744,
    'Comuna Găiceana': 4744,
    'Comuna Gârleni': 4744,
    'Comuna Ghimeş-Făget': 4744,
    'Comuna Gioseni': 4744,
    'Comuna Glăvăneşti': 4744,
    'Comuna Gura Văii': 4744,
    'Comuna Helegiu': 4744,
    'Comuna Hemeiuşi': 4744,
    'Comuna Horgeşti': 4744,
    'Comuna Huruieşti': 4744,
    'Comuna Iteşti': 4744,
    'Comuna Izvoru Berheciului': 4744,
    'Comuna Letea Veche': 4744,
    'Comuna Lipova': 4744,
    'Comuna Livezi': 4744,
    'Comuna Luizi-Cãlugãra': 4744,
    'Comuna Măgireşti': 4744,
    'Comuna Măgura': 4744,
    'Comuna Mânăstirea Caşin': 4744,
    'Comuna Mărgineni': 4744,
    'Comuna Motoşeni': 4744,
    'Comuna Negri': 4744,
    'Comuna Nicolae Bălcescu': 4744,
    'Comuna Odobeşti': 4744,
    'Comuna Oituz': 4744,
    'Comuna Onceşti': 4744,
    'Comuna Orbeni': 4744,
    'Comuna Palanca': 4744,
    'Comuna Pănceşti': 4744,
    'Comuna Parava': 4744,
    'Comuna Pârgăreşti': 4744,
    'Comuna Parincea': 4744,
    'Comuna Pârjol': 4744,
    'Comuna Plopana': 4744,
    'Comuna Podu Turcului': 4744,
    'Comuna Poduri': 4744,
    'Comuna Prăjeşti': 4744,
    'Comuna Răcăciuni': 4744,
    'Comuna Răchitoasa': 4744,
    'Comuna Racova': 4744,
    'Comuna Roşiori': 4744,
    'Comuna Sănduleni': 4744,
    'Comuna Sărata': 4744,
    'Comuna Sascut': 4744,
    'Comuna Săuceşti': 4744,
    'Comuna Scorţeni': 4744,
    'Comuna Secuieni': 4744,
    'Comuna Solonţ': 4744,
    'Comuna Stănişeşti': 4744,
    'Comuna Ştefan Cel Mare': 4744,
    'Comuna Strugari': 4744,
    'Comuna Tamaşi': 4744,
    'Comuna Târgu Trotuş': 4744,
    'Comuna Tătărăşti': 4744,
    'Comuna Traian': 4744,
    'Comuna Ungureni': 4744,
    'Comuna Urecheşti': 4744,
    'Comuna Valea Seacă': 4744,
    'Comuna Vultureni': 4744,
    'Comuna Zemeş': 4744,
    'Corbasca': 4744,
    'Coţofăneşti': 4744,
    'Cotumba': 4744,
    'Cucuieți': 4744,
    'Dămieneşti': 4744,
    'Dămoc': 4744,
    'Dărmăneşti': 4744,
    'Dealu Morii': 4744,
    'Deleni': 4744,
    'Dofteana': 4744,
    'Dragomir': 4744,
    'Drăgugești': 4744,
    'Dumbrava': 4744,
    'Făget': 4744,
    'Faraoaní': 4744,
    'Ferestrău-Oituz': 4744,
    'Filipeni': 4744,
    'Filipeşti': 4744,
    'Frumoasa': 4744,
    'Frumușelu': 4744,
    'Fundu Răcăciuni': 4744,
    'Găiceana': 4744,
    'Galbeni': 4744,
    'Gârleni': 4744,
    'Gârlenii de Sus': 4744,
    'Găzărie': 4744,
    'Gheorghe Doja': 4744,
    'Ghimeș': 4744,
    'Ghimeş-Făget': 4744,
    'Gioseni': 4744,
    'Glăvăneşti': 4744,
    'Gura Văii': 4744,
    'Hăghiac': 4744,
    'Helegiu': 4744,
    'Hemeiuș': 4744,
    'Holt': 4744,
    'Horgeşti': 4744,
    'Huruiești': 4744,
    'Iteşti': 4744,
    'Izvoru Berheciului': 4744,
    'Lăpoș': 4744,
    'Larga': 4744,
    'Lespezi': 4744,
    'Letea Veche': 4744,
    'Lilieci': 4744,
    'Lipova': 4744,
    'Livezi': 4744,
    'Livezi-Vale': 4744,
    'Ludași': 4744,
    'Luizi-Călugăra': 4744,
    'Măgireşti': 4744,
    'Măgura': 4744,
    'Mânăstirea Caşin': 4744,
    'Mărgineni': 4744,
    'Mărgineni-Munteni': 4744,
    'Moineşti': 4744,
    'Motoşeni': 4744,
    'Municipiul Bacãu': 4744,
    'Municipiul Moineşti': 4744,
    'Municipiul Oneşti': 4744,
    'Negri': 4744,
    'Nicolae Bălcescu': 4744,
    'Odobești': 4744,
    'Oituz': 4744,
    'Onceşti': 4744,
    'Onesti': 4744,
    'Oraş Buhuşi': 4744,
    'Oraş Comãneşti': 4744,
    'Oraş Dãrmãneşti': 4744,
    'Oraş Slãnic-Moldova': 4744,
    'Oraş Târgu Ocna': 4744,
    'Orășa': 4744,
    'Orbeni': 4744,
    'Osebiți': 4744,
    'Palanca': 4744,
    'Pănceşti': 4744,
    'Parava': 4744,
    'Pârgăreşti': 4744,
    'Parincea': 4744,
    'Pârjol': 4744,
    'Plopana': 4744,
    'Plopu': 4744,
    'Podu Turcului': 4744,
    'Poduri': 4744,
    'Poiana': 4744,
    'Prăjești': 4744,
    'Prohozești': 4744,
    'Pustiana': 4744,
    'Răcăciuni': 4744,
    'Răcăuți': 4744,
    'Răchitoasa': 4744,
    'Racova': 4744,
    'Roşiori': 4744,
    'Sănduleni': 4744,
    'Sărata': 4744,
    'Sascut': 4744,
    'Sascut-Sat': 4744,
    'Satu Nou': 4744,
    'Săucești': 4744,
    'Schitu Frumoasa': 4744,
    'Scorţeni': 4744,
    'Scurta': 4744,
    'Secuieni': 4744,
    'Slănic-Moldova': 4744,
    'Slobozia': 4744,
    'Solonţ': 4744,
    'Somușca': 4744,
    'Stănişeşti': 4744,
    'Ştefan Cel Mare': 4744,
    'Ștefan Vodă': 4744,
    'Straja': 4744,
    'Strugari': 4744,
    'Tamaşi': 4744,
    'Târgu Ocna': 4744,
    'Târgu Trotuş': 4744,
    'Tătărăști': 4744,
    'Traian': 4744,
    'Turluianu': 4744,
    'Tuta': 4744,
    'Ungureni': 4744,
    'Urecheşti': 4744,
    'Vâlcele': 4744,
    'Valea Arinilor': 4744,
    'Valea lui Ion': 4744,
    'Valea Seacă': 4744,
    'Valea Șoșii': 4744,
    'Vermești': 4744,
    'Verșești': 4744,
    'Viișoara': 4744,
    'Vultureni': 4744,
    'Zemeş': 4744,
    'Abram': 4723,
    'Abrămuţ': 4723,
    'Albiș': 4723,
    'Aleşd': 4723,
    'Alparea': 4723,
    'Aştileu': 4723,
    'Auşeu': 4723,
    'Avram Iancu': 4723,
    'Balc': 4723,
    'Bălnaca': 4723,
    'Batăr': 4723,
    'Beiuş': 4723,
    'Beznea': 4723,
    'Biharia': 4723,
    'Bistra': 4723,
    'Bogei': 4723,
    'Boianu Mare': 4723,
    'Borod': 4723,
    'Borş': 4723,
    'Bratca': 4723,
    'Brusturi': 4723,
    'Budureasa': 4723,
    'Buduslău': 4723,
    'Bulz': 4723,
    'Bunteşti': 4723,
    'Burzuc': 4723,
    'Căbeşti': 4723,
    'Cadea': 4723,
    'Călacea': 4723,
    'Câmpani': 4723,
    'Câmpani de Pomezeu': 4723,
    'Căpâlna': 4723,
    'Cărpinet': 4723,
    'Cefa': 4723,
    'Ceica': 4723,
    'Cetariu': 4723,
    'Cherechiu': 4723,
    'Cheșereu': 4723,
    'Cheț': 4723,
    'Chişlaz': 4723,
    'Ciumeghiu': 4723,
    'Ciutelec': 4723,
    'Cociuba Mare': 4723,
    'Comuna Abram': 4723,
    'Comuna Abrămuţ': 4723,
    'Comuna Aştileu': 4723,
    'Comuna Auşeu': 4723,
    'Comuna Avram Iancu': 4723,
    'Comuna Balc': 4723,
    'Comuna Batăr': 4723,
    'Comuna Biharia': 4723,
    'Comuna Boianu Mare': 4723,
    'Comuna Borod': 4723,
    'Comuna Borş': 4723,
    'Comuna Bratca': 4723,
    'Comuna Brusturi': 4723,
    'Comuna Budureasa': 4723,
    'Comuna Buduslău': 4723,
    'Comuna Bulz': 4723,
    'Comuna Bunteşti': 4723,
    'Comuna Căbeşti': 4723,
    'Comuna Câmpani': 4723,
    'Comuna Cãpâlna': 4723,
    'Comuna Cărpinet': 4723,
    'Comuna Cefa': 4723,
    'Comuna Ceica': 4723,
    'Comuna Cetariu': 4723,
    'Comuna Cherechiu': 4723,
    'Comuna Chişlaz': 4723,
    'Comuna Ciumeghiu': 4723,
    'Comuna Cociuba Mare': 4723,
    'Comuna Copăcel': 4723,
    'Comuna Criștioru de Jos': 4723,
    'Comuna Curăţele': 4723,
    'Comuna Curtuişeni': 4723,
    'Comuna Derna': 4723,
    'Comuna Diosig': 4723,
    'Comuna Dobreşti': 4723,
    'Comuna Drăgăneşti': 4723,
    'Comuna Drăgeşti': 4723,
    'Comuna Finiş': 4723,
    'Comuna Gepiu': 4723,
    'Comuna Girişu de Criş': 4723,
    'Comuna Hidişelu De Sus': 4723,
    'Comuna Holod': 4723,
    'Comuna Husasău de Tinca': 4723,
    'Comuna Ineu': 4723,
    'Comuna Lăzăreni': 4723,
    'Comuna Lazuri de Beiuş': 4723,
    'Comuna Lugaşu De Jos': 4723,
    'Comuna Lunca': 4723,
    'Comuna Mădăraş': 4723,
    'Comuna Măgeşti': 4723,
    'Comuna Nojorid': 4723,
    'Comuna Olcea': 4723,
    'Comuna Oşorhei': 4723,
    'Comuna Paleu': 4723,
    'Comuna Pietroasa': 4723,
    'Comuna Pocola': 4723,
    'Comuna Pomezeu': 4723,
    'Comuna Popeşti': 4723,
    'Comuna Răbăgani': 4723,
    'Comuna Remetea': 4723,
    'Comuna Rieni': 4723,
    'Comuna Roşia': 4723,
    'Comuna Roşiori': 4723,
    'Comuna Săcădat': 4723,
    'Comuna Sălacea': 4723,
    'Comuna Sălard': 4723,
    'Comuna Sâmbăta': 4723,
    'Comuna Sâniob': 4723,
    'Comuna Sânmartin': 4723,
    'Comuna Sânnicolau-Român': 4723,
    'Comuna Sântandrei': 4723,
    'Comuna Sârbi': 4723,
    'Comuna Şimian': 4723,
    'Comuna Şinteu': 4723,
    'Comuna Şoimi': 4723,
    'Comuna Spinuş': 4723,
    'Comuna Şuncuiuş': 4723,
    'Comuna Suplacu de Barcău': 4723,
    'Comuna Tãmãşeu': 4723,
    'Comuna Tărcaia': 4723,
    'Comuna Tarcea': 4723,
    'Comuna Tăuteu': 4723,
    'Comuna Ţeţchea': 4723,
    'Comuna Tileagd': 4723,
    'Comuna Tinca': 4723,
    'Comuna Toboliu': 4723,
    'Comuna Tulca': 4723,
    'Comuna Uileacu de Beiuş': 4723,
    'Comuna Vadu Crişului': 4723,
    'Comuna Vârciorog': 4723,
    'Comuna Viişoara': 4723,
    'Copăcel': 4723,
    'Cordău': 4723,
    'Criştioru de Jos': 4723,
    'Cubulcut': 4723,
    'Curăţele': 4723,
    'Curtuișeni': 4723,
    'Cuzap': 4723,
    'Derna': 4723,
    'Diosig': 4723,
    'Dobreşti': 4723,
    'Drăgăneşti': 4723,
    'Drăgeşti': 4723,
    'Finiş': 4723,
    'Foglaş': 4723,
    'Forău': 4723,
    'Galoșpetreu': 4723,
    'Gepiu': 4723,
    'Ghighișeni': 4723,
    'Ghiorac': 4723,
    'Girișu de Criș': 4723,
    'Groși': 4723,
    'Gurbediu': 4723,
    'Hidişelu de Sus': 4723,
    'Holod': 4723,
    'Hotar': 4723,
    'Husasău de Tinca': 4723,
    'Ianoșda': 4723,
    'Ineu': 4723,
    'Lăzăreni': 4723,
    'Lazuri de Beiuş': 4723,
    'Livada de Bihor': 4723,
    'Lugaşu de Jos': 4723,
    'Lunca': 4723,
    'Luncșoara': 4723,
    'Mădăraş': 4723,
    'Măgeşti': 4723,
    'Marghita': 4723,
    'Meziad': 4723,
    'Mihai Bravu': 4723,
    'Mișca': 4723,
    'Municipiul Beiuş': 4723,
    'Municipiul Marghita': 4723,
    'Municipiul Oradea': 4723,
    'Municipiul Salonta': 4723,
    'Nojorid': 4723,
    'Nucet': 4723,
    'Olcea': 4723,
    'Oradea': 4723,
    'Oraș Aleşd': 4723,
    'Oraş Nucet': 4723,
    'Oraş Sãcueni': 4723,
    'Oraş Ştei': 4723,
    'Oraş Valea Lui Mihai': 4723,
    'Oraş Vaşcãu': 4723,
    'Oșorhei': 4723,
    'Pădurea Neagră': 4723,
    'Paleu': 4723,
    'Peștiș': 4723,
    'Petreu': 4723,
    'Pietroasa': 4723,
    'Pocola': 4723,
    'Pomezeu': 4723,
    'Popeşti': 4723,
    'Răbăgani': 4723,
    'Remetea': 4723,
    'Rieni': 4723,
    'Roşia': 4723,
    'Roșiori': 4723,
    'Săcădat': 4723,
    'Săcueni': 4723,
    'Sălacea': 4723,
    'Sălard': 4723,
    'Salonta': 4723,
    'Sâmbăta': 4723,
    'Sâniob': 4723,
    'Sânnicolau Român': 4723,
    'Sântandrei': 4723,
    'Sântion': 4723,
    'Sârbi': 4723,
    'Șilindru': 4723,
    'Şimian': 4723,
    'Sînmartin': 4723,
    'Şinteu': 4723,
    'Şoimi': 4723,
    'Spinuş': 4723,
    'Ștei': 4723,
    'Şuncuiuş': 4723,
    'Suplacu de Barcău': 4723,
    'Talpoș': 4723,
    'Tămașda': 4723,
    'Tămășeu': 4723,
    'Tărcaia': 4723,
    'Tarcea': 4723,
    'Tășad': 4723,
    'Tăut': 4723,
    'Tăuteu': 4723,
    'Tileagd': 4723,
    'Tinca': 4723,
    'Toboliu': 4723,
    'Tulca': 4723,
    'Uileacu de Beiuș': 4723,
    'Vadu Crişului': 4723,
    'Valea lui Mihai': 4723,
    'Vârciorog': 4723,
    'Vășad': 4723,
    'Vaşcău': 4723,
    'Viişoara': 4723,
    'Vintere': 4723,
    'Voivozi': 4723,
    'Agrieș': 4733,
    'Anieș': 4733,
    'Beclean': 4733,
    'Bichigiu': 4733,
    'Bistriţa': 4733,
    'Bistriţa Bârgăului': 4733,
    'Braniştea': 4733,
    'Breaza': 4733,
    'Budacu de Jos': 4733,
    'Budacu de Sus': 4733,
    'Budeşti': 4733,
    'Căianu Mic': 4733,
    'Chiochiş': 4733,
    'Chiuza': 4733,
    'Ciceu-Giurgeşti': 4733,
    'Ciceu-Mihăiești': 4733,
    'Cireșoaia': 4733,
    'Cociu': 4733,
    'Comuna Bistriţa Bârgăului': 4733,
    'Comuna Braniştea': 4733,
    'Comuna Budacu de Jos': 4733,
    'Comuna Budeşti': 4733,
    'Comuna Căianu': 4733,
    'Comuna Chiochiş': 4733,
    'Comuna Chiuza': 4733,
    'Comuna Ciceu-Giurgeşti': 4733,
    'Comuna Ciceu-Mihăieşti': 4733,
    'Comuna Coşbuc': 4733,
    'Comuna Dumitra': 4733,
    'Comuna Dumitriţa': 4733,
    'Comuna Feldru': 4733,
    'Comuna Galaţii Bistriţei': 4733,
    'Comuna Ilva Mare': 4733,
    'Comuna Ilva Mică': 4733,
    'Comuna Josenii Bârgăului': 4733,
    'Comuna Lechinţa': 4733,
    'Comuna Leşu': 4733,
    'Comuna Livezile': 4733,
    'Comuna Lunca Ilvei': 4733,
    'Comuna Măgura Ilvei': 4733,
    'Comuna Măieru': 4733,
    'Comuna Mărişelu': 4733,
    'Comuna Matei': 4733,
    'Comuna Miceştii de Câmpie': 4733,
    'Comuna Milas': 4733,
    'Comuna Monor': 4733,
    'Comuna Negrileşti': 4733,
    'Comuna Nimigea': 4733,
    'Comuna Nuşeni': 4733,
    'Comuna Parva': 4733,
    'Comuna Petru Rareş': 4733,
    'Comuna Poiana  Ilvei': 4733,
    'Comuna Prundu Bârgăului': 4733,
    'Comuna Războeni-Cetate': 4733,
    'Comuna Rebra': 4733,
    'Comuna Rebrişoara': 4733,
    'Comuna Rodna': 4733,
    'Comuna Romuli': 4733,
    'Comuna Runcu Salvei': 4733,
    'Comuna Salva': 4733,
    'Comuna Sânmihaiu de Câmpie': 4733,
    'Comuna Şanţ': 4733,
    'Comuna Şieu': 4733,
    'Comuna Şieu-Măgheruş': 4733,
    'Comuna Şieu-Odorhei': 4733,
    'Comuna Şieuţ': 4733,
    'Comuna Silivaşu De Câmpie': 4733,
    'Comuna Şintereag': 4733,
    'Comuna Spermezeu': 4733,
    'Comuna Târlişua': 4733,
    'Comuna Teaca': 4733,
    'Comuna Telciu': 4733,
    'Comuna Tiha Bârgăului': 4733,
    'Comuna Uriu': 4733,
    'Comuna Urmeniş': 4733,
    'Comuna Zagra': 4733,
    'Cormaia': 4733,
    'Coşbuc': 4733,
    'Cristeștii Ciceului': 4733,
    'Dobric': 4733,
    'Dumitra': 4733,
    'Dumitrița': 4733,
    'Feldru': 4733,
    'Frunzi': 4733,
    'Galaţii Bistriţei': 4733,
    'Ilva Mare': 4733,
    'Ilva Mică': 4733,
    'Josenii Bârgăului': 4733,
    'Lechinţa': 4733,
    'Leşu': 4733,
    'Livezile': 4733,
    'Lunca Ilvei': 4733,
    'Lunca Leșului': 4733,
    'Măgura Ilvei': 4733,
    'Măieru': 4733,
    'Mărişelu': 4733,
    'Matei': 4733,
    'Miceştii de Câmpie': 4733,
    'Mijlocenii Bârgăului': 4733,
    'Milaş': 4733,
    'Monor': 4733,
    'Municipiul Bistriţa': 4733,
    'Năsăud': 4733,
    'Negrilești': 4733,
    'Nepos': 4733,
    'Nimigea de Jos': 4733,
    'Nimigea de Sus': 4733,
    'Nuşeni': 4733,
    'Ocnița': 4733,
    'Oraş Beclean': 4733,
    'Oraş Nãsãud': 4733,
    'Oraş Sângeorz-Bãi': 4733,
    'Parva': 4733,
    'Petriș': 4733,
    'Poiana Ilvei': 4733,
    'Prundu Bârgăului': 4733,
    'Răpănaşu': 4733,
    'Rebra': 4733,
    'Rebrişoara': 4733,
    'Reteag': 4733,
    'Rodna': 4733,
    'Romuli': 4733,
    'Runcu Salvei': 4733,
    'Salva': 4733,
    'Sângeorz-Băi': 4733,
    'Sânmihaiu de Câmpie': 4733,
    'Șanț': 4733,
    'Şieu': 4733,
    'Şieu-Măgheruş': 4733,
    'Şieu-Odorhei': 4733,
    'Şieuţ': 4733,
    'Silivașu de Câmpie': 4733,
    'Şintereag': 4733,
    'Spermezeu': 4733,
    'Susenii Bârgăului': 4733,
    'Târlişua': 4733,
    'Teaca': 4733,
    'Telcișor': 4733,
    'Telciu': 4733,
    'Tiha Bârgăului': 4733,
    'Tureac': 4733,
    'Unirea': 4733,
    'Uriu': 4733,
    'Urmeniş': 4733,
    'Valea Borcutului': 4733,
    'Viile Tecii': 4733,
    'Viișoara': 4733,
    'Zagra': 4733,
    'Adășeni': 4740,
    'Alba': 4740,
    'Albeşti': 4740,
    'Avrămeni': 4740,
    'Bajura': 4740,
    'Băluşeni': 4740,
    'Baranca': 4740,
    'Blândeşti': 4740,
    'Bobulești': 4740,
    'Botoşani': 4740,
    'Brăeşti': 4740,
    'Brehuiești': 4740,
    'Broscăuţi': 4740,
    'Bucecea': 4740,
    'Bucovineni': 4740,
    'Călăraşi': 4740,
    'Cândeşti': 4740,
    'Cătămărești-Deal': 4740,
    'Comuna Adăşeni': 4740,
    'Comuna Albeşti': 4740,
    'Comuna Avrămeni': 4740,
    'Comuna Băluşeni': 4740,
    'Comuna Blândeşti': 4740,
    'Comuna Brăeşti': 4740,
    'Comuna Broscăuţi': 4740,
    'Comuna Călăraşi': 4740,
    'Comuna Cândeşti': 4740,
    'Comuna Conceşti': 4740,
    'Comuna Copălău': 4740,
    'Comună Cordăreni': 4740,
    'Comuna Corlăteni': 4740,
    'Comuna Corni': 4740,
    'Comuna Coşula': 4740,
    'Comuna Coţuşca': 4740,
    'Comuna Cristeşti': 4740,
    'Comuna Cristineşti': 4740,
    'Comuna Curteşti': 4740,
    'Comuna Dângeni': 4740,
    'Comuna Dersca': 4740,
    'Comuna Dimăcheni': 4740,
    'Comuna Dobârceni': 4740,
    'Comuna Drăguşeni': 4740,
    'Comuna Durneşti': 4740,
    'Comuna Frumuşica': 4740,
    'Comuna George Enescu': 4740,
    'Comuna Gorbăneşti': 4740,
    'Comuna Hăneşti': 4740,
    'Comuna Havârna': 4740,
    'Comuna Hilişeu-Horia': 4740,
    'Comuna Hlipiceni': 4740,
    'Comuna Hudeşti': 4740,
    'Comuna Ibăneşti': 4740,
    'Comuna Leorda': 4740,
    'Comuna Lozna': 4740,
    'Comuna Lunca': 4740,
    'Comuna Manoleasa': 4740,
    'Comuna Mihai Eminescu': 4740,
    'Comuna Mihăileni': 4740,
    'Comuna Mihălăşeni': 4740,
    'Comuna Mileanca': 4740,
    'Comuna Mitoc': 4740,
    'Comuna Nicşeni': 4740,
    'Comuna Păltiniş': 4740,
    'Comuna Pomârla': 4740,
    'Comuna Prăjeni': 4740,
    'Comuna Răchiţi': 4740,
    'Comuna Rãdãuţi-Prut': 4740,
    'Comuna Răuseni': 4740,
    'Comuna Ripiceni': 4740,
    'Comuna Roma': 4740,
    'Comuna Româneşti': 4740,
    'Comuna Santa Mare': 4740,
    'Comuna Şendriceni': 4740,
    'Comuna Stăuceni': 4740,
    'Comuna Ştiubieni': 4740,
    'Comuna Suharău': 4740,
    'Comuna Suliţa': 4740,
    'Comuna Todireni': 4740,
    'Comuna Truşeşti': 4740,
    'Comuna Tudora': 4740,
    'Comuna Ungureni': 4740,
    'Comuna Unţeni': 4740,
    'Comuna Văculeşti': 4740,
    'Comuna Vârfu Câmpului': 4740,
    'Comuna Viişoara': 4740,
    'Comuna Vlădeni': 4740,
    'Comuna Vlăsineşti': 4740,
    'Comuna Vorniceni': 4740,
    'Comuna Vorona': 4740,
    'Conceşti': 4740,
    'Copălău': 4740,
    'Cordăreni': 4740,
    'Corlăteni': 4740,
    'Corni': 4740,
    'Costești': 4740,
    'Coșula': 4740,
    'Coţuşca': 4740,
    'Cristeşti': 4740,
    'Cristineşti': 4740,
    'Curteşti': 4740,
    'Dângeni': 4740,
    'Darabani': 4740,
    'Dersca': 4740,
    'Dimăcheni': 4740,
    'Dobârceni': 4740,
    'Dorobanți': 4740,
    'Dorohoi': 4740,
    'Dracșani': 4740,
    'Drăguşeni': 4740,
    'Draxini': 4740,
    'Dumbrăvița': 4740,
    'Dumeni': 4740,
    'Durneşti': 4740,
    'Flămânzi': 4740,
    'Frumuşica': 4740,
    'George Enescu': 4740,
    'Gorbăneşti': 4740,
    'Hăneşti': 4740,
    'Havârna': 4740,
    'Hilişeu-Horia': 4740,
    'Hlipiceni': 4740,
    'Hudeşti': 4740,
    'Iacobeni': 4740,
    'Ibăneşti': 4740,
    'Icușeni': 4740,
    'Ionășeni': 4740,
    'Joldești': 4740,
    'Leorda': 4740,
    'Lozna': 4740,
    'Lunca': 4740,
    'Mândrești': 4740,
    'Manoleasa': 4740,
    'Mihăileni': 4740,
    'Mihălăşeni': 4740,
    'Mileanca': 4740,
    'Miorcani': 4740,
    'Mitoc': 4740,
    'Mlenăuți': 4740,
    'Municipiul Botoşani': 4740,
    'Municipiul Dorohoi': 4740,
    'Nicolae Bălcescu': 4740,
    'Nicşeni': 4740,
    'Oneaga': 4740,
    'Oraş Bucecea': 4740,
    'Oraş Darabani': 4740,
    'Oraş Flãmânzi': 4740,
    'Oraş Sãveni': 4740,
    'Oraş Ştefãneşti': 4740,
    'Orășeni-Deal': 4740,
    'Oroftiana': 4740,
    'Pădureni': 4740,
    'Păltiniş': 4740,
    'Poiana': 4740,
    'Pomârla': 4740,
    'Prăjeni': 4740,
    'Răchiţi': 4740,
    'Rădăuți-Prut': 4740,
    'Rădeni': 4740,
    'Răuseni': 4740,
    'Rediu': 4740,
    'Ripiceni': 4740,
    'Roma': 4740,
    'Românești': 4740,
    'Roșiori': 4740,
    'Santa Mare': 4740,
    'Sarafinești': 4740,
    'Sârbi': 4740,
    'Săveni': 4740,
    'Şendriceni': 4740,
    'Stâncești': 4740,
    'Stăuceni': 4740,
    'Ștefănești': 4740,
    'Ştefăneşti-Sat': 4740,
    'Ştiubieni': 4740,
    'Storeşti': 4740,
    'Străteni': 4740,
    'Stroiești': 4740,
    'Suharău': 4740,
    'Sulița': 4740,
    'Șupitca': 4740,
    'Tocileni': 4740,
    'Todireni': 4740,
    'Truşeşti': 4740,
    'Tudor Vladimirescu': 4740,
    'Tudora': 4740,
    'Ungureni': 4740,
    'Ungureni-Jianu': 4740,
    'Unţeni': 4740,
    'Văculeşti': 4740,
    'Vârfu Câmpului': 4740,
    'Victoria': 4740,
    'Viişoara': 4740,
    'Vlădeni': 4740,
    'Vlădeni-Deal': 4740,
    'Vlăsineşti': 4740,
    'Vorniceni': 4740,
    'Vorona': 4740,
    'Vorona Teodoru': 4740,
    'Zlătunoaia': 4740,
    'Baldovinești': 4736,
    'Bărăganul': 4736,
    'Berteştii de Jos': 4736,
    'Bordei Verde': 4736,
    'Brăila': 4736,
    'Cazasu': 4736,
    'Chiscani': 4736,
    'Ciocile': 4736,
    'Cireşu': 4736,
    'Comuna Bărăganul': 4736,
    'Comuna Berteştii de Jos': 4736,
    'Comuna Bordei Verde': 4736,
    'Comuna Cazasu': 4736,
    'Comuna Chiscani': 4736,
    'Comuna Ciocile': 4736,
    'Comuna Cireşu': 4736,
    'Comuna Dudeşti': 4736,
    'Comuna Frecãţei': 4736,
    'Comuna Galbenu': 4736,
    'Comuna Gemenele': 4736,
    'Comuna Grădiştea': 4736,
    'Comuna Gropeni': 4736,
    'Comuna Jirlău': 4736,
    'Comuna Măraşu': 4736,
    'Comuna Măxineni': 4736,
    'Comuna Mircea Vodă': 4736,
    'Comuna Movila Miresii': 4736,
    'Comuna Racoviţa': 4736,
    'Comuna Râmnicelu': 4736,
    'Comuna Romanu': 4736,
    'Comuna Roşiori': 4736,
    'Comuna Salcia Tudor': 4736,
    'Comuna Scorţaru Nou': 4736,
    'Comuna Siliştea': 4736,
    'Comuna Stăncuţa': 4736,
    'Comuna Surdila-Găiseanca': 4736,
    'Comuna Surdila-Greci': 4736,
    'Comuna Şuţeşti': 4736,
    'Comuna Tichileşti': 4736,
    'Comuna Traian': 4736,
    'Comuna Tudor Vladimirescu': 4736,
    'Comuna Tufeşti': 4736,
    'Comuna Ulmu': 4736,
    'Comuna Unirea': 4736,
    'Comuna Vădeni': 4736,
    'Comuna Victoria': 4736,
    'Comuna Vişani': 4736,
    'Comuna Viziru': 4736,
    'Comuna Zăvoaia': 4736,
    'Corbu Nou': 4736,
    'Cuza Vodă': 4736,
    'Dedulești': 4736,
    'Dudeşti': 4736,
    'Făurei': 4736,
    'Frecăţei': 4736,
    'Galbenu': 4736,
    'Gemenele': 4736,
    'Grădiştea': 4736,
    'Gropeni': 4736,
    'Ianca': 4736,
    'Însurăţei': 4736,
    'Jirlău': 4736,
    'Jugureanu': 4736,
    'Lacu Sărat': 4736,
    'Lanurile': 4736,
    'Măraşu': 4736,
    'Măxineni': 4736,
    'Mihai Bravu': 4736,
    'Mircea Vodă': 4736,
    'Movila Miresii': 4736,
    'Municipiul Brãila': 4736,
    'Oraş Fãurei': 4736,
    'Oraş Ianca': 4736,
    'Oraş Însurãţei': 4736,
    'Perișoru': 4736,
    'Plopu': 4736,
    'Racoviţa': 4736,
    'Râmnicelu': 4736,
    'Romanu': 4736,
    'Roşiori': 4736,
    'Salcia Tudor': 4736,
    'Scorţaru Nou': 4736,
    'Scorțaru Vechi': 4736,
    'Siliştea': 4736,
    'Spiru Haret': 4736,
    'Stăncuţa': 4736,
    'Surdila-Găiseanca': 4736,
    'Surdila-Greci': 4736,
    'Şuţeşti': 4736,
    'Tătaru': 4736,
    'Tichileşti': 4736,
    'Traian': 4736,
    'Tudor Vladimirescu': 4736,
    'Tufeşti': 4736,
    'Ulmu': 4736,
    'Unirea': 4736,
    'Urleasca': 4736,
    'Vădeni': 4736,
    'Valea Cânepii': 4736,
    'Victoria': 4736,
    'Vişani': 4736,
    'Viziru': 4736,
    'Zăvoaia': 4736,
    'Acriș': 4759,
    'Apaţa': 4759,
    'Augustin': 4759,
    'Beclean': 4759,
    'Bod': 4759,
    'Bran': 4759,
    'Braşov': 4759,
    'Budila': 4759,
    'Buneşti': 4759,
    'Caţa': 4759,
    'Cincu': 4759,
    'Codlea': 4759,
    'Colonia Bod': 4759,
    'Comana de Jos': 4759,
    'Comana de Sus': 4759,
    'Comuna Apaţa': 4759,
    'Comuna Augustin': 4759,
    'Comuna Beclean': 4759,
    'Comuna Bod': 4759,
    'Comuna Bran': 4759,
    'Comuna Budila': 4759,
    'Comuna Buneşti': 4759,
    'Comuna Caţa': 4759,
    'Comuna Cincu': 4759,
    'Comuna Comana': 4759,
    'Comuna Cristian': 4759,
    'Comuna Crizbav': 4759,
    'Comuna Drăguş': 4759,
    'Comuna Dumbrăviţa': 4759,
    'Comuna Feldioara': 4759,
    'Comuna Fundata': 4759,
    'Comuna Hălchiu': 4759,
    'Comuna Hărman': 4759,
    'Comuna Hârşeni': 4759,
    'Comuna Hoghiz': 4759,
    'Comuna Holbav': 4759,
    'Comuna Homorod': 4759,
    'Comuna Jibert': 4759,
    'Comuna Lisa': 4759,
    'Comuna Mãieruş': 4759,
    'Comuna Mândra': 4759,
    'Comuna Moieciu': 4759,
    'Comuna Ormeniş': 4759,
    'Comuna Pârâu': 4759,
    'Comuna Poiana Mărului (Brașov)': 4759,
    'Comuna Prejmer': 4759,
    'Comuna Racoş': 4759,
    'Comuna Recea': 4759,
    'Comuna Sâmbăta de Sus': 4759,
    'Comuna Sânpetru': 4759,
    'Comuna Şercaia': 4759,
    'Comuna Şinca Nouă': 4759,
    'Comuna Şinca Veche': 4759,
    'Comuna Şoarş': 4759,
    'Comuna Tărlungeni': 4759,
    'Comuna Teliu': 4759,
    'Comuna Ticuşu Vechi': 4759,
    'Comuna Ucea de Jos': 4759,
    'Comuna Ungra': 4759,
    'Comuna Vama Buzăului': 4759,
    'Comuna Viştea de Jos': 4759,
    'Comuna Voila': 4759,
    'Comuna Vulcan': 4759,
    'Cristian': 4759,
    'Crivina': 4759,
    'Crizbav': 4759,
    'Cuciulata': 4759,
    'Drăguș': 4759,
    'Dumbrăviţa': 4759,
    'Făgăraș': 4759,
    'Feldioara': 4759,
    'Fișer': 4759,
    'Fundata': 4759,
    'Ghimbav': 4759,
    'Hălchiu': 4759,
    'Hărman': 4759,
    'Hârşeni': 4759,
    'Hoghiz': 4759,
    'Holbav': 4759,
    'Homorod': 4759,
    'Jibert': 4759,
    'Lisa': 4759,
    'Măieruş': 4759,
    'Mândra': 4759,
    'Moeciu de Jos': 4759,
    'Moieciu de Jos': 4759,
    'Municipiul  Codlea': 4759,
    'Municipiul Braşov': 4759,
    'Municipiul Fãgãraş': 4759,
    'Municipiul Sãcele': 4759,
    'Oraş Ghimbav': 4759,
    'Oraş Predeal': 4759,
    'Oraş Râşnov': 4759,
    'Oraş Rupea': 4759,
    'Oraş Victoria': 4759,
    'Oraş Zãrneşti': 4759,
    'Ormeniş': 4759,
    'Pârâu': 4759,
    'Perșani': 4759,
    'Poiana Mărului (Brașov)': 4759,
    'Predeal': 4759,
    'Prejmer': 4759,
    'Purcăreni': 4759,
    'Racoș': 4759,
    'Râşnov': 4759,
    'Recea': 4759,
    'Rupea': 4759,
    'Săcele': 4759,
    'Sâmbăta de Sus': 4759,
    'Sânpetru': 4759,
    'Şercaia': 4759,
    'Șimon': 4759,
    'Șinca Nouă': 4759,
    'Şinca Veche': 4759,
    'Şoarş': 4759,
    'Sohodol': 4759,
    'Tărlungeni': 4759,
    'Teliu': 4759,
    'Ticuşu Vechi': 4759,
    'Tohanu Nou': 4759,
    'Tunelu-Teliu': 4759,
    'Ucea de Jos': 4759,
    'Ucea de Sus': 4759,
    'Ungra': 4759,
    'Vama Buzăului': 4759,
    'Victoria': 4759,
    'Viscri': 4759,
    'Viștea de Jos': 4759,
    'Voila': 4759,
    'Vulcan': 4759,
    'Zărnești': 4759,
    'Zizin': 4759,
    'Bucharest': 4730,
    'Sector 1': 4730,
    'Sector 2': 4730,
    'Sector 3': 4730,
    'Sector 4': 4730,
    'Sector 5': 4730,
    'Sector 6': 4730,
    'Amara': 4756,
    'Amaru': 4756,
    'Băbeni': 4756,
    'Babeţi': 4756,
    'Bălăceanu': 4756,
    'Balta Albă': 4756,
    'Bâsca Chiojdului': 4756,
    'Bâsca Rozilei': 4756,
    'Bâscenii de Jos': 4756,
    'Bâscenii de Sus': 4756,
    'Beceni': 4756,
    'Berca': 4756,
    'Bisoca': 4756,
    'Blăjani': 4756,
    'Boboc': 4756,
    'Boldu': 4756,
    'Bozioru': 4756,
    'Brădeanu': 4756,
    'Brăeşti': 4756,
    'Breaza': 4756,
    'Buda': 4756,
    'Burueneşti': 4756,
    'Buzău': 4756,
    'C.a. Rosetti': 4756,
    'Căldărăști': 4756,
    'Calvini': 4756,
    'Căneşti': 4756,
    'Căpățânești': 4756,
    'Cașoca': 4756,
    'Cătina': 4756,
    'Cernăteşti': 4756,
    'Chiliile': 4756,
    'Chiojdu': 4756,
    'Cilibia': 4756,
    'Cislău': 4756,
    'Cochirleanca': 4756,
    'Colţi': 4756,
    'Comuna Amaru': 4756,
    'Comuna Bălăceanu': 4756,
    'Comuna Balta Albă': 4756,
    'Comuna Beceni': 4756,
    'Comuna Berca': 4756,
    'Comuna Bisoca': 4756,
    'Comuna Blăjani': 4756,
    'Comuna Boldu': 4756,
    'Comuna Bozioru': 4756,
    'Comuna Brădeanu': 4756,
    'Comuna Brăeşti': 4756,
    'Comuna Breaza': 4756,
    'Comuna Buda': 4756,
    'Comuna C.A. Rosetti': 4756,
    'Comuna Calvini': 4756,
    'Comuna Căneşti': 4756,
    'Comuna Cătina': 4756,
    'Comuna Cernăteşti': 4756,
    'Comuna Chiliile': 4756,
    'Comuna Chiojdu': 4756,
    'Comuna Cilibia': 4756,
    'Comuna Cislău': 4756,
    'Comuna Cochirleanca': 4756,
    'Comuna Colţi': 4756,
    'Comuna Costeşti': 4756,
    'Comuna Cozieni': 4756,
    'Comuna Florica': 4756,
    'Comuna Gălbinaşi': 4756,
    'Comuna Gherăseni': 4756,
    'Comuna Ghergheasa': 4756,
    'Comuna Glodeanu-Sărat': 4756,
    'Comuna Glodeanu-Siliştea': 4756,
    'Comuna Greabănu': 4756,
    'Comuna Gura Teghii': 4756,
    'Comuna Largu': 4756,
    'Comuna Lopătari': 4756,
    'Comuna Luciu': 4756,
    'Comuna Măgura': 4756,
    'Comuna Mânzăleşti': 4756,
    'Comuna Mărăcineni': 4756,
    'Comuna Mărgăriteşti': 4756,
    'Comuna Merei': 4756,
    'Comuna Mihăileşti': 4756,
    'Comuna Movila Banului': 4756,
    'Comuna Murgeşti': 4756,
    'Comuna Năeni': 4756,
    'Comuna Odăile': 4756,
    'Comuna Padina': 4756,
    'Comuna Pănătău': 4756,
    'Comuna Pardoşi': 4756,
    'Comuna Pârscov': 4756,
    'Comuna Pietroasele': 4756,
    'Comuna Podgoria': 4756,
    'Comuna Poşta Câlnãu': 4756,
    'Comuna Puieşti': 4756,
    'Comuna Racoviţeni': 4756,
    'Comuna Râmnicelu': 4756,
    'Comuna Robeasca': 4756,
    'Comuna Ruşeţu': 4756,
    'Comuna Săgeata': 4756,
    'Comuna Săhăteni': 4756,
    'Comuna Săpoca': 4756,
    'Comuna Săruleşti': 4756,
    'Comuna Scorţoasa': 4756,
    'Comuna Scutelnici': 4756,
    'Comuna Siriu': 4756,
    'Comuna Smeeni': 4756,
    'Comuna Stâlpu': 4756,
    'Comuna Ţinteşti': 4756,
    'Comuna Tisãu': 4756,
    'Comuna Topliceni': 4756,
    'Comuna Ulmeni': 4756,
    'Comuna Unguriu': 4756,
    'Comuna Vadu Paşii': 4756,
    'Comuna Vâlcelele': 4756,
    'Comuna Valea Râmnicului': 4756,
    'Comuna Valea Sălciei': 4756,
    'Comuna Verneşti': 4756,
    'Comuna Vintilă Vodă': 4756,
    'Comuna Vipereşti': 4756,
    'Comuna Zărneşti': 4756,
    'Comuna Ziduri': 4756,
    'Corbu': 4756,
    'Costeşti': 4756,
    'Coțatcu': 4756,
    'Cozieni': 4756,
    'Dâmbroca': 4756,
    'Dăscălești': 4756,
    'Dedulești': 4756,
    'Florica': 4756,
    'Fundeni': 4756,
    'Furtunești': 4756,
    'Gălbinaşi': 4756,
    'Găvănești': 4756,
    'Gherăseni': 4756,
    'Ghergheasa': 4756,
    'Glodeanu-Sărat': 4756,
    'Glodeanu-Siliştea': 4756,
    'Grăjdana': 4756,
    'Grebănu': 4756,
    'Greceanca': 4756,
    'Grunji': 4756,
    'Gura Teghii': 4756,
    'Izvoru': 4756,
    'Izvoru Dulce': 4756,
    'Largu': 4756,
    'Limpeziș': 4756,
    'Lipia': 4756,
    'Lopătari': 4756,
    'Luciu': 4756,
    'Lunca': 4756,
    'Lunca Jariștei': 4756,
    'Lunca Priporului': 4756,
    'Măgura': 4756,
    'Mânzăleşti': 4756,
    'Mărăcineni': 4756,
    'Mărgăriteşti': 4756,
    'Mărunțișu': 4756,
    'Mătești': 4756,
    'Maxenu': 4756,
    'Merei': 4756,
    'Mihăileşti': 4756,
    'Movila Banului': 4756,
    'Municipiul Buzău': 4756,
    'Municipiul Râmnicu Sãrat': 4756,
    'Murgeşti': 4756,
    'Năeni': 4756,
    'Nehoiu': 4756,
    'Odăile': 4756,
    'Oraş Nehoiu': 4756,
    'Oraş Pãtârlagele': 4756,
    'Oraş Pogoanele': 4756,
    'Oreavu': 4756,
    'Padina': 4756,
    'Păltineni': 4756,
    'Pănătău': 4756,
    'Pardoşi': 4756,
    'Pârscov': 4756,
    'Pătârlagele': 4756,
    'Pietroasele': 4756,
    'Pietrosu': 4756,
    'Plevna': 4756,
    'Podgoria': 4756,
    'Pogoanele': 4756,
    'Pogonele': 4756,
    'Poşta Câlnău': 4756,
    'Potoceni': 4756,
    'Puieștii de Jos': 4756,
    'Racoviţeni': 4756,
    'Râmnicelu': 4756,
    'Râmnicu Sărat': 4756,
    'Robeasca': 4756,
    'Rubla': 4756,
    'Ruşeţu': 4756,
    'Săgeata': 4756,
    'Săhăteni': 4756,
    'Sălcioara': 4756,
    'Săpoca': 4756,
    'Săruleşti': 4756,
    'Scorţoasa': 4756,
    'Scurtești': 4756,
    'Scutelnici': 4756,
    'Smeeni': 4756,
    'Stâlpu': 4756,
    'Stăncești': 4756,
    'Sudiți': 4756,
    'Tăbărăști': 4756,
    'Ţinteşti': 4756,
    'Topliceni': 4756,
    'Ulmeni': 4756,
    'Unguriu': 4756,
    'Vadu Paşii': 4756,
    'Vadu Sorești': 4756,
    'Vâlcele': 4756,
    'Vâlcelele': 4756,
    'Valea Părului': 4756,
    'Valea Râmnicului': 4756,
    'Valea Salciei': 4756,
    'Verneşti': 4756,
    'Vintilă Vodă': 4756,
    'Vintileanca': 4756,
    'Vipereşti': 4756,
    'Zărneşti': 4756,
    'Zărneștii de Slănic': 4756,
    'Ziduri': 4756,
    'Zorești': 4756,
    'Alexandru Odobescu': 4732,
    'Aprozi': 4732,
    'Belciugatele': 4732,
    'Borcea': 4732,
    'Budeşti': 4732,
    'Călăraşi': 4732,
    'Căscioarele': 4732,
    'Ceacu': 4732,
    'Chirnogi': 4732,
    'Chiselet': 4732,
    'Ciocăneşti': 4732,
    'Coconi': 4732,
    'Comuna Alexandru Odobescu': 4732,
    'Comuna Belciugatele': 4732,
    'Comuna Borcea': 4732,
    'Comuna Căscioarele': 4732,
    'Comuna Chirnogi': 4732,
    'Comuna Chiselet': 4732,
    'Comuna Ciocăneşti': 4732,
    'Comuna Crivăţ': 4732,
    'Comuna Curcani': 4732,
    'Comuna Cuza Vodă': 4732,
    'Comuna Dichiseni': 4732,
    'Comuna Dor Mărunt': 4732,
    'Comuna Dorobanţu': 4732,
    'Comuna Dragalina': 4732,
    'Comuna Dragoş Vodă': 4732,
    'Comuna Frăsinet': 4732,
    'Comuna Frumuşani': 4732,
    'Comuna Fundeni': 4732,
    'Comuna Gălbinaşi': 4732,
    'Comuna Grădiştea': 4732,
    'Comuna Gurbăneşti': 4732,
    'Comuna Ileana': 4732,
    'Comuna Independenţa': 4732,
    'Comuna Jegălia': 4732,
    'Comuna Lehliu': 4732,
    'Comuna Luica': 4732,
    'Comuna Lupşanu': 4732,
    'Comuna Mânăstirea': 4732,
    'Comuna Mitreni': 4732,
    'Comuna Modelu': 4732,
    'Comuna Nana': 4732,
    'Comuna Nicolae Bălcescu': 4732,
    'Comuna Perişoru': 4732,
    'Comuna Plătăreşti': 4732,
    'Comuna Radovanu': 4732,
    'Comuna Roseţi': 4732,
    'Comuna Săruleşti': 4732,
    'Comuna Sohatu': 4732,
    'Comuna Şoldanu': 4732,
    'Comuna Spanţov': 4732,
    'Comuna Ştefan Cel Mare': 4732,
    'Comuna Ştefan Vodă': 4732,
    'Comuna Tãmãdãu Mare': 4732,
    'Comuna Ulmeni': 4732,
    'Comuna Ulmu': 4732,
    'Comuna Unirea': 4732,
    'Comuna Vâlcelele': 4732,
    'Comuna Valea Argovei': 4732,
    'Comuna Vasilaţi': 4732,
    'Comuna Vlad Ţepeş': 4732,
    'Constantin Brâncoveanu': 4732,
    'Crivăț': 4732,
    'Curcani': 4732,
    'Cuza Vodă': 4732,
    'Dâlga-Gară': 4732,
    'Dichiseni': 4732,
    'Dor Mărunt': 4732,
    'Dorobanţu': 4732,
    'Dragalina': 4732,
    'Dragoș Vodă': 4732,
    'Drajna Nouă': 4732,
    'Frăsinet': 4732,
    'Frumuşani': 4732,
    'Fundeni': 4732,
    'Fundulea': 4732,
    'Gălbinași': 4732,
    'Gâldău': 4732,
    'Grădiştea': 4732,
    'Gurbăneşti': 4732,
    'Iezeru': 4732,
    'Ileana': 4732,
    'Independenţa': 4732,
    'Jegălia': 4732,
    'Lehliu': 4732,
    'Lehliu-Gară': 4732,
    'Luica': 4732,
    'Lupşanu': 4732,
    'Mânăstirea': 4732,
    'Mihai Viteazu': 4732,
    'Mitreni': 4732,
    'Modelu': 4732,
    'Municipiul Călăraşi': 4732,
    'Municipiul Olteniţa': 4732,
    'Nana': 4732,
    'Negoești': 4732,
    'Nicolae Bălcescu': 4732,
    'Olteniţa': 4732,
    'Oraş Budeşti': 4732,
    'Oraş Fundulea': 4732,
    'Oraş Lehliu Garã': 4732,
    'Perişoru': 4732,
    'Plătăreşti': 4732,
    'Potcoava': 4732,
    'Progresu': 4732,
    'Radovanu': 4732,
    'Radu Vodă': 4732,
    'Rasa': 4732,
    'Răzvani': 4732,
    'Roseţi': 4732,
    'Săpunari': 4732,
    'Săruleşti': 4732,
    'Sărulești-Gară': 4732,
    'Sohatu': 4732,
    'Şoldanu': 4732,
    'Spanţov': 4732,
    'Stancea': 4732,
    'Ștefan cel Mare': 4732,
    'Ştefan Vodă': 4732,
    'Sultana': 4732,
    'Tămădău Mare': 4732,
    'Ulmeni': 4732,
    'Ulmu': 4732,
    'Unirea': 4732,
    'Vâlcelele': 4732,
    'Valea Argovei': 4732,
    'Valea Roșie': 4732,
    'Vărăști': 4732,
    'Vasilaţi': 4732,
    'Vlad Ţepeş': 4732,
    'Anina': 4753,
    'Armeniş': 4753,
    'Băile Herculane': 4753,
    'Bănia': 4753,
    'Băuţar': 4753,
    'Berlişte': 4753,
    'Berzasca': 4753,
    'Berzovia': 4753,
    'Bocşa': 4753,
    'Bolvașnița': 4753,
    'Borlova': 4753,
    'Bozovici': 4753,
    'Brebu': 4753,
    'Brebu Nou': 4753,
    'Buchin': 4753,
    'Bucoşniţa': 4753,
    'Bucova': 4753,
    'Câlnic': 4753,
    'Caransebeş': 4753,
    'Caraşova': 4753,
    'Cărbunari': 4753,
    'Ciclova-Română': 4753,
    'Ciuchici': 4753,
    'Ciudanoviţa': 4753,
    'Clocotici': 4753,
    'Comuna Armeniş': 4753,
    'Comuna Bănia': 4753,
    'Comuna Băuţar': 4753,
    'Comuna Berlişte': 4753,
    'Comuna Berzasca': 4753,
    'Comuna Berzovia': 4753,
    'Comuna Bolvaşniţa': 4753,
    'Comuna Bozovici': 4753,
    'Comuna Brebu': 4753,
    'Comuna Brebu Nou': 4753,
    'Comuna Buchin': 4753,
    'Comuna Bucoşniţa': 4753,
    'Comuna Caraşova': 4753,
    'Comuna Cărbunari': 4753,
    'Comuna Ciclova Română': 4753,
    'Comuna Ciuchici': 4753,
    'Comuna Ciudanoviţa': 4753,
    'Comuna Constantin Daicoviciu': 4753,
    'Comuna Copăcele': 4753,
    'Comuna Cornea': 4753,
    'Comuna Cornereva': 4753,
    'Comuna Dalboşeţ': 4753,
    'Comuna Doclin': 4753,
    'Comuna Dognecea': 4753,
    'Comuna Domaşnea': 4753,
    'Comuna Eftimie Murgu': 4753,
    'Comuna Ezeriş': 4753,
    'Comuna Fârliug': 4753,
    'Comuna Forotic': 4753,
    'Comuna Gârnic': 4753,
    'Comuna Glimboca': 4753,
    'Comuna Goruia': 4753,
    'Comuna Grădinari': 4753,
    'Comuna Iablaniţa': 4753,
    'Comuna Lăpuşnicel': 4753,
    'Comuna Lăpuşnicu Mare': 4753,
    'Comuna Luncaviţa': 4753,
    'Comuna Lupac': 4753,
    'Comuna Marga': 4753,
    'Comuna Măureni': 4753,
    'Comuna Mehadia': 4753,
    'Comuna Mehadica': 4753,
    'Comuna Naidaş': 4753,
    'Comuna Obreja': 4753,
    'Comuna Ocna De Fier': 4753,
    'Comuna Pãltiniş': 4753,
    'Comuna Pescari': 4753,
    'Comuna Pojejena': 4753,
    'Comuna Prigor': 4753,
    'Comuna Răcăşdia': 4753,
    'Comuna Ramna': 4753,
    'Comuna Rusca Montană': 4753,
    'Comuna Sacu': 4753,
    'Comuna Sasca Montană': 4753,
    'Comuna Sicheviţa': 4753,
    'Comuna Slatina-Timiş': 4753,
    'Comuna Socol': 4753,
    'Comuna Şopotu Nou': 4753,
    'Comuna Târnova': 4753,
    'Comuna Teregova': 4753,
    'Comuna Ticvaniu Mare': 4753,
    'Comuna Topleţ': 4753,
    'Comuna Turnu Rueni': 4753,
    'Comuna Văliug': 4753,
    'Comuna Vărădia': 4753,
    'Comuna Vermeş': 4753,
    'Comuna Vrani': 4753,
    'Comuna Zăvoi': 4753,
    'Comuna Zorlenţu Mare': 4753,
    'Constantin Daicoviciu': 4753,
    'Copăcele': 4753,
    'Cornea': 4753,
    'Cornereva': 4753,
    'Cornuțel': 4753,
    'Coronini': 4753,
    'Dalboșeț': 4753,
    'Doclin': 4753,
    'Dognecea': 4753,
    'Domaşnea': 4753,
    'Eftimie Murgu': 4753,
    'Ezeriş': 4753,
    'Fârliug': 4753,
    'Fizeș': 4753,
    'Forotic': 4753,
    'Gârnic': 4753,
    'Gherteniș': 4753,
    'Glimboca': 4753,
    'Goruia': 4753,
    'Grădinari': 4753,
    'Greoni': 4753,
    'Iablaniţa': 4753,
    'Lăpuşnicel': 4753,
    'Lăpuşnicu Mare': 4753,
    'Liubcova': 4753,
    'Luncaviţa': 4753,
    'Lupac': 4753,
    'Marga': 4753,
    'Măru': 4753,
    'Măureni': 4753,
    'Mehadia': 4753,
    'Mehadica': 4753,
    'Moldova Nouă': 4753,
    'Municipiul Caransebeş': 4753,
    'Municipiul Reşiţa': 4753,
    'Muntele Mic': 4753,
    'Naidăș': 4753,
    'Obreja': 4753,
    'Oraş Anina': 4753,
    'Oraş Bãile Herculane': 4753,
    'Oraş Bocşa': 4753,
    'Oraş Oraviţa': 4753,
    'Oraş Oţelu Roşu': 4753,
    'Oraviţa': 4753,
    'Oţelu Roşu': 4753,
    'Padina Matei': 4753,
    'Păltiniş': 4753,
    'Petroșnița': 4753,
    'Plugova': 4753,
    'Pojejena': 4753,
    'Prigor': 4753,
    'Răcăşdia': 4753,
    'Ramna': 4753,
    'Reşiţa': 4753,
    'Rusca': 4753,
    'Rusca Montană': 4753,
    'Sacu': 4753,
    'Sasca Montană': 4753,
    'Sicheviţa': 4753,
    'Slatina-Timiş': 4753,
    'Socol': 4753,
    'Şopotu Nou': 4753,
    'Steierdorf': 4753,
    'Târnova': 4753,
    'Teregova': 4753,
    'Ticvaniu Mare': 4753,
    'Topleţ': 4753,
    'Turnu Ruieni': 4753,
    'Valea Bolvașnița': 4753,
    'Văliug': 4753,
    'Vărădia': 4753,
    'Vârciorova': 4753,
    'Verendin': 4753,
    'Vermeş': 4753,
    'Vrani': 4753,
    'Zăvoi': 4753,
    'Zorlenţu Mare': 4753,
    'Aghireșu': 4734,
    'Aghireșu-Fabrici': 4734,
    'Aiton': 4734,
    'Aluniş': 4734,
    'Apahida': 4734,
    'Aşchileu Dorna': 4734,
    'Baciu': 4734,
    'Băişoara': 4734,
    'Beliş': 4734,
    'Bobâlna': 4734,
    'Bogdăneşti': 4734,
    'Bonțida': 4734,
    'Borşa': 4734,
    'Buza': 4734,
    'Căianu': 4734,
    'Căianu Mic': 4734,
    'Călăraşi': 4734,
    'Călăţele': 4734,
    'Cămăraşu': 4734,
    'Câmpia Turzii': 4734,
    'Căpuşu Mare': 4734,
    'Căşeiu': 4734,
    'Câţcău': 4734,
    'Cătina': 4734,
    'Ceanu Mare': 4734,
    'Chinteni': 4734,
    'Chiuiești': 4734,
    'Ciucea': 4734,
    'Ciurila': 4734,
    'Cluj-Napoca': 4734,
    'Cojocna': 4734,
    'Comuna Aghireşu': 4734,
    'Comuna Aiton': 4734,
    'Comuna Aluniş': 4734,
    'Comuna Apahida': 4734,
    'Comuna Aşchileu': 4734,
    'Comuna Baciu': 4734,
    'Comuna Băişoara': 4734,
    'Comuna Beliş': 4734,
    'Comuna Bobâlna': 4734,
    'Comuna Bonţida': 4734,
    'Comuna Borşa': 4734,
    'Comuna Buza': 4734,
    'Comuna Căianu': 4734,
    'Comuna Călăraşi': 4734,
    'Comuna Călăţele': 4734,
    'Comuna Cămăraşu': 4734,
    'Comuna Cãpuşu Mare': 4734,
    'Comuna Căşeiu': 4734,
    'Comuna Câţcău': 4734,
    'Comuna Cătina': 4734,
    'Comuna Ceanu Mare': 4734,
    'Comuna Chinteni': 4734,
    'Comuna Chiuieşti': 4734,
    'Comuna Ciucea': 4734,
    'Comuna Ciurila': 4734,
    'Comuna Cojocna': 4734,
    'Comuna Corneşti': 4734,
    'Comuna Cuzdrioara': 4734,
    'Comuna Dăbâca': 4734,
    'Comuna Feleacu': 4734,
    'Comuna Fizeşu Gherlii': 4734,
    'Comuna Floreşti': 4734,
    'Comuna Frata': 4734,
    'Comuna Gârbãu': 4734,
    'Comuna Geaca': 4734,
    'Comuna Gilău': 4734,
    'Comuna Iara': 4734,
    'Comuna Iclod': 4734,
    'Comuna Izvoru Crişului': 4734,
    'Comuna Jichişu De Jos': 4734,
    'Comuna Jucu': 4734,
    'Comuna Luna': 4734,
    'Comuna Mãguri-Rãcãtãu': 4734,
    'Comuna Mânăstireni': 4734,
    'Comuna Mărgău': 4734,
    'Comuna Mărişel': 4734,
    'Comuna Mica': 4734,
    'Comuna Mihai Viteazu': 4734,
    'Comuna Mintiu Gherlii': 4734,
    'Comuna Mociu': 4734,
    'Comuna Moldoveneşti': 4734,
    'Comuna Negreni': 4734,
    'Comuna Pãlatca': 4734,
    'Comuna Panticeu': 4734,
    'Comuna Petreştii De Jos': 4734,
    'Comuna Ploscoş': 4734,
    'Comuna Poieni': 4734,
    'Comuna Râșca': 4734,
    'Comuna Recea Cristur': 4734,
    'Comuna Sãcuieu': 4734,
    'Comuna Sâncraiu': 4734,
    'Comuna Sănduleşti': 4734,
    'Comuna Sânmărtin': 4734,
    'Comuna Sânpaul': 4734,
    'Comuna Săvădisla': 4734,
    'Comuna Sic': 4734,
    'Comuna Suatu': 4734,
    'Comuna Ţaga': 4734,
    'Comuna Tritenii De Jos': 4734,
    'Comuna Tureni': 4734,
    'Comuna Unguraş': 4734,
    'Comuna Vad': 4734,
    'Comuna Valea Ierii': 4734,
    'Comuna Viişoara': 4734,
    'Comuna Vultureni': 4734,
    'Copăceni': 4734,
    'Corneşti': 4734,
    'Cuzdrioara': 4734,
    'Dăbâca': 4734,
    'Dej': 4734,
    'Dezmir': 4734,
    'Feleacu': 4734,
    'Fizeşu Gherlii': 4734,
    'Floreşti': 4734,
    'Frata': 4734,
    'Gârbău': 4734,
    'Geaca': 4734,
    'Gheorghieni': 4734,
    'Gherla': 4734,
    'Gilău': 4734,
    'Huedin': 4734,
    'Iara': 4734,
    'Iclod': 4734,
    'Izvoru Crişului': 4734,
    'Jichişu de Jos': 4734,
    'Luna': 4734,
    'Luna de Sus': 4734,
    'Luncani': 4734,
    'Măguri': 4734,
    'Măguri-Răcătău': 4734,
    'Mânăstireni': 4734,
    'Mărgău': 4734,
    'Mărişel': 4734,
    'Mera': 4734,
    'Mica': 4734,
    'Mihai Viteazu': 4734,
    'Mintiu Gherlii': 4734,
    'Mociu': 4734,
    'Moldoveneşti': 4734,
    'Municipiul Câmpia Turzii': 4734,
    'Municipiul Cluj-Napoca': 4734,
    'Municipiul Dej': 4734,
    'Municipiul Gherla': 4734,
    'Municipiul Turda': 4734,
    'Negreni': 4734,
    'Nireș': 4734,
    'Ocna Dejului': 4734,
    'Oraş Huedin': 4734,
    'Pădureni': 4734,
    'Pălatca': 4734,
    'Panticeu': 4734,
    'Petreştii de Jos': 4734,
    'Ploscoș': 4734,
    'Poieni': 4734,
    'Râșca': 4734,
    'Răscruci': 4734,
    'Recea Cristur': 4734,
    'Rugășești': 4734,
    'Săcuieu': 4734,
    'Sâncraiu': 4734,
    'Sănduleşti': 4734,
    'Sânmărghita': 4734,
    'Sânmărtin': 4734,
    'Sânnicoară': 4734,
    'Sânpaul': 4734,
    'Săvădisla': 4734,
    'Sic': 4734,
    'Soporu de Câmpie': 4734,
    'Suatu': 4734,
    'Suceagu': 4734,
    'Ţaga': 4734,
    'Tritenii de Jos': 4734,
    'Tritenii de Sus': 4734,
    'Turda': 4734,
    'Tureni': 4734,
    'Unguraş': 4734,
    'Urca': 4734,
    'Vad': 4734,
    'Vaida-Cămăraș': 4734,
    'Valea Ierii': 4734,
    'Viişoara': 4734,
    'Vultureni': 4734,
    '44796': 4737,
    'Adamclisi': 4737,
    'Agigea': 4737,
    'Albeşti': 4737,
    'Aliman': 4737,
    'Amzacea': 4737,
    'Băneasa': 4737,
    'Bărăganu': 4737,
    'Castelu': 4737,
    'Cerchezu': 4737,
    'Cernavodă': 4737,
    'Chirnogeni': 4737,
    'Ciobanu': 4737,
    'Ciocârlia': 4737,
    'Ciocârlia de Sus': 4737,
    'Cobadin': 4737,
    'Cogealac': 4737,
    'Comana': 4737,
    'Comuna 23 August': 4737,
    'Comuna Adamclisi': 4737,
    'Comuna Agigea': 4737,
    'Comuna Albeşti': 4737,
    'Comuna Aliman': 4737,
    'Comuna Amzacea': 4737,
    'Comuna Bărăganu': 4737,
    'Comuna Castelu': 4737,
    'Comuna Cerchezu': 4737,
    'Comuna Chirnogeni': 4737,
    'Comuna Ciobanu': 4737,
    'Comuna Ciocârlia': 4737,
    'Comuna Cobadin': 4737,
    'Comuna Cogealac': 4737,
    'Comuna Comana': 4737,
    'Comuna Corbu': 4737,
    'Comuna Costineşti': 4737,
    'Comuna Crucea': 4737,
    'Comuna Cumpăna': 4737,
    'Comuna Cuza Voda': 4737,
    'Comuna Deleni': 4737,
    'Comună Dobromir': 4737,
    'Comuna Dumbrăveni': 4737,
    'Comuna Fântânele': 4737,
    'Comuna Gârliciu': 4737,
    'Comuna Ghindăreşti': 4737,
    'Comuna Grădina': 4737,
    'Comuna Horia': 4737,
    'Comuna Independenţa': 4737,
    'Comuna Ion Corvin': 4737,
    'Comuna Istria': 4737,
    'Comuna Limanu': 4737,
    'Comuna Lipniţa': 4737,
    'Comuna Lumina': 4737,
    'Comuna Mereni': 4737,
    'Comuna Mihai Viteazu': 4737,
    'Comuna Mihail Kogălniceanu': 4737,
    'Comuna Mircea Vodă': 4737,
    'Comuna Nicolae Bălcescu': 4737,
    'Comuna Oltina': 4737,
    'Comuna Ostrov': 4737,
    'Comuna Pantelimon': 4737,
    'Comuna Pecineaga': 4737,
    'Comuna Peştera': 4737,
    'Comuna Poarta Albă': 4737,
    'Comuna Rasova': 4737,
    'Comuna Săcele': 4737,
    'Comuna Saligny': 4737,
    'Comuna Saraiu': 4737,
    'Comuna Seimeni': 4737,
    'Comuna Siliştea': 4737,
    'Comuna Târguşor': 4737,
    'Comuna Topalu': 4737,
    'Comuna Topraisar': 4737,
    'Comuna Tortoman': 4737,
    'Comuna Tuzla': 4737,
    'Comuna Valu lui Traian': 4737,
    'Comuna Vulturu': 4737,
    'Constanţa': 4737,
    'Corbu': 4737,
    'Costineşti': 4737,
    'Crucea': 4737,
    'Culmea': 4737,
    'Cumpăna': 4737,
    'Cuza Vodă': 4737,
    'Deleni': 4737,
    'Dobromir': 4737,
    'Dorobanțu': 4737,
    'Dulcești': 4737,
    'Dumbrăveni': 4737,
    'Dunăreni': 4737,
    'Eforie Nord': 4737,
    'Eforie Sud': 4737,
    'Făclia': 4737,
    'Fântânele': 4737,
    'Gârliciu': 4737,
    'Ghindăreşti': 4737,
    'Grădina': 4737,
    'Hârşova': 4737,
    'Horia': 4737,
    'Independenţa': 4737,
    'Ion Corvin': 4737,
    'Istria': 4737,
    'Limanu': 4737,
    'Lipniţa': 4737,
    'Lumina': 4737,
    'Mamaia-Sat': 4737,
    'Mangalia': 4737,
    'Medgidia': 4737,
    'Mereni': 4737,
    'Mihai Viteazu': 4737,
    'Mihail Kogălniceanu': 4737,
    'Mircea Vodă': 4737,
    'Moșneni': 4737,
    'Municipiul Constanţa': 4737,
    'Municipiul Mangalia': 4737,
    'Municipiul Medgidia': 4737,
    'Murfatlar': 4737,
    'Năvodari': 4737,
    'Negru Vodă': 4737,
    'Nicolae Bălcescu': 4737,
    'Nisipari': 4737,
    'Nuntași': 4737,
    'Oltina': 4737,
    'Oraş Bãneasa': 4737,
    'Oraş Cernavodã': 4737,
    'Oraş Eforie': 4737,
    'Oraş Hârşova': 4737,
    'Oraş Murfatlar': 4737,
    'Oraş Nãvodari': 4737,
    'Oraş Negru Vodã': 4737,
    'Oraş Ovidiu': 4737,
    'Oraş Techirghiol': 4737,
    'Ostrov': 4737,
    'Ovidiu': 4737,
    'Palazu Mare': 4737,
    'Pantelimon': 4737,
    'Pantelimon de Jos': 4737,
    'Pecineaga': 4737,
    'Peştera': 4737,
    'Piatra': 4737,
    'Pietreni': 4737,
    'Plopeni': 4737,
    'Poarta Albă': 4737,
    'Rasova': 4737,
    'Săcele': 4737,
    'Saligny': 4737,
    'Saraiu': 4737,
    'Satu Nou': 4737,
    'Schitu': 4737,
    'Seimeni': 4737,
    'Siliştea': 4737,
    'Siminoc': 4737,
    'Târguşor': 4737,
    'Tariverde': 4737,
    'Techirghiol': 4737,
    'Topalu': 4737,
    'Topraisar': 4737,
    'Tortoman': 4737,
    'Tuzla': 4737,
    'Valea Dacilor': 4737,
    'Valu lui Traian': 4737,
    'Viile': 4737,
    'Viișoara': 4737,
    'Vulturu': 4737,
    'Aita Mare': 4754,
    'Araci': 4754,
    'Arcuș': 4754,
    'Baraolt': 4754,
    'Barcani': 4754,
    'Băţanii Mari': 4754,
    'Belin': 4754,
    'Belin-Vale': 4754,
    'Bixad': 4754,
    'Bodoc': 4754,
    'Boroşneu Mare': 4754,
    'Brăduţ': 4754,
    'Brateş': 4754,
    'Breţcu': 4754,
    'Căpeni': 4754,
    'Catalina': 4754,
    'Cernat': 4754,
    'Chichiş': 4754,
    'Comandău': 4754,
    'Comuna Aita Mare': 4754,
    'Comuna Arcuş': 4754,
    'Comuna Barcani': 4754,
    'Comuna Băţani': 4754,
    'Comuna Belin': 4754,
    'Comuna Bixad': 4754,
    'Comuna Bodoc': 4754,
    'Comuna Boroşneu Mare': 4754,
    'Comuna Brăduţ': 4754,
    'Comuna Brateş': 4754,
    'Comuna Breţcu': 4754,
    'Comuna Catalina': 4754,
    'Comună Cernat': 4754,
    'Comuna Chichiş': 4754,
    'Comuna Comandău': 4754,
    'Comuna Dalnic': 4754,
    'Comuna Dobârlău': 4754,
    'Comuna Estelnic': 4754,
    'Comuna Ghelinţa': 4754,
    'Comuna Ghidfalău': 4754,
    'Comuna Hăghig': 4754,
    'Comuna Ilieni': 4754,
    'Comuna Lemnia': 4754,
    'Comuna Malnaş': 4754,
    'Comuna Mereni': 4754,
    'Comuna Micfalău': 4754,
    'Comuna Moacşa': 4754,
    'Comuna Ojdula': 4754,
    'Comuna Ozun': 4754,
    'Comuna Poian': 4754,
    'Comuna Reci': 4754,
    'Comuna Sânzieni': 4754,
    'Comuna Sita Buzăului': 4754,
    'Comuna Turia': 4754,
    'Comuna Vâlcele': 4754,
    'Comuna Valea Crişului': 4754,
    'Comuna Valea Mare': 4754,
    'Comuna Vârghiş': 4754,
    'Comuna Zăbala': 4754,
    'Comuna Zagon': 4754,
    'Covasna': 4754,
    'Dalnic': 4754,
    'Dobârlău': 4754,
    'Doboșeni': 4754,
    'Estelnic': 4754,
    'Filia': 4754,
    'Floroaia': 4754,
    'Ghelinţa': 4754,
    'Ghidfalău': 4754,
    'Hăghig': 4754,
    'Herculian': 4754,
    'Ilieni': 4754,
    'Întorsura Buzăului': 4754,
    'Lemnia': 4754,
    'Lunga': 4754,
    'Malnaş': 4754,
    'Mereni': 4754,
    'Micfalău': 4754,
    'Moacşa': 4754,
    'Municipiul Sfântu Gheorghe': 4754,
    'Municipiul Târgu Secuiesc': 4754,
    'Ojdula': 4754,
    'Oraş Baraolt': 4754,
    'Oraş Covasna': 4754,
    'Oraş Întorsura Buzãului': 4754,
    'Ozun': 4754,
    'Păpăuți': 4754,
    'Poian': 4754,
    'Reci': 4754,
    'Sânzieni': 4754,
    'Sfântu Gheorghe': 4754,
    'Sita Buzăului': 4754,
    'Târgu Secuiesc': 4754,
    'Turia': 4754,
    'Vâlcele': 4754,
    'Valea Crişului': 4754,
    'Valea Mare': 4754,
    'Vârghiş': 4754,
    'Zăbala': 4754,
    'Zagon': 4754,
    'Adânca': 4745,
    'Aninoasa': 4745,
    'Bădeni': 4745,
    'Bâldana': 4745,
    'Băleni Sârbi': 4745,
    'Bălteni': 4745,
    'Bărbuleţu': 4745,
    'Bezdead': 4745,
    'Bilciureşti': 4745,
    'Brăneşti': 4745,
    'Braniştea': 4745,
    'Brezoaele': 4745,
    'Brezoaia': 4745,
    'Broșteni': 4745,
    'Buciumeni': 4745,
    'Bucşani': 4745,
    'Bungetu': 4745,
    'Butimanu': 4745,
    'Cândeşti Vale': 4745,
    'Căprioru': 4745,
    'Cazaci': 4745,
    'Ciocănari': 4745,
    'Ciocăneşti': 4745,
    'Cojasca': 4745,
    'Colacu': 4745,
    'Comişani': 4745,
    'Comuna Aninoasa': 4745,
    'Comuna Băleni Sârbi': 4745,
    'Comuna Bărbuleţu': 4745,
    'Comuna Bezdead': 4745,
    'Comuna Bilciureşti': 4745,
    'Comuna Brăneşti': 4745,
    'Comuna Braniştea': 4745,
    'Comuna Brezoaele': 4745,
    'Comuna Buciumeni': 4745,
    'Comuna Bucşani': 4745,
    'Comuna Butimanu': 4745,
    'Comuna Cândeşti Vale': 4745,
    'Comuna Ciocăneşti': 4745,
    'Comuna Cobia de Sus': 4745,
    'Comuna Cojasca': 4745,
    'Comuna Comişani': 4745,
    'Comuna Conţeşti': 4745,
    'Comuna Corbii Mari': 4745,
    'Comuna Cornăţelu': 4745,
    'Comuna Corneşti': 4745,
    'Comuna Costeştii din Vale': 4745,
    'Comuna Crângurile': 4745,
    'Comuna Crevedia': 4745,
    'Comuna Dărmăneşti': 4745,
    'Comuna Dobra': 4745,
    'Comuna Doiceşti': 4745,
    'Comuna Dragodana': 4745,
    'Comuna Dragomireşti': 4745,
    'Comuna Finta': 4745,
    'Comuna Glodeni': 4745,
    'Comuna Gura Foii': 4745,
    'Comuna Gura Ocniţei': 4745,
    'Comuna Gura Şuţii': 4745,
    'Comuna Hulubeşti': 4745,
    'Comuna I. L. Caragiale': 4745,
    'Comuna Iedera': 4745,
    'Comuna Lucieni': 4745,
    'Comuna Ludeşti': 4745,
    'Comuna Lunguleţu': 4745,
    'Comuna Malu cu Flori': 4745,
    'Comuna Măneşti': 4745,
    'Comuna Mătăsaru': 4745,
    'Comuna Mogoşani': 4745,
    'Comuna Moroeni': 4745,
    'Comuna Morteni': 4745,
    'Comuna Moţãieni': 4745,
    'Comuna Niculeşti': 4745,
    'Comuna Nucet': 4745,
    'Comuna Ocniţa': 4745,
    'Comuna Odobeşti': 4745,
    'Comuna Perşinari': 4745,
    'Comuna Petreşti': 4745,
    'Comuna Pietrari': 4745,
    'Comuna Pietroşiţa': 4745,
    'Comuna Poiana': 4745,
    'Comuna Potlogi': 4745,
    'Comuna Produleşti': 4745,
    'Comuna Pucheni': 4745,
    'Comuna Raciu': 4745,
    'Comuna Răscăeţi': 4745,
    'Comuna Râu Alb': 4745,
    'Comuna Rãzvad': 4745,
    'Comuna Runcu': 4745,
    'Comuna Sălcioara': 4745,
    'Comuna Şelaru': 4745,
    'Comuna Slobozia Moara': 4745,
    'Comuna Şotânga': 4745,
    'Comuna Tărtăşeşti': 4745,
    'Comuna Tătărani': 4745,
    'Comuna Ulieşti': 4745,
    'Comuna Ulmi': 4745,
    'Comuna Văcăreşti': 4745,
    'Comuna Valea Lungă': 4745,
    'Comuna Valea Mare': 4745,
    'Comuna Văleni-Dâmboviţa': 4745,
    'Comuna Vârfuri': 4745,
    'Comuna Vişina': 4745,
    'Comuna Vişineşti': 4745,
    'Comuna Vlădeni': 4745,
    'Comuna Voineşti': 4745,
    'Comuna Vulcana Băi': 4745,
    'Comuna Vulcana-Pandele': 4745,
    'Conțești': 4745,
    'Corbii Mari': 4745,
    'Cornăţelu': 4745,
    'Corneşti': 4745,
    'Costeștii din Deal': 4745,
    'Costeştii din Vale': 4745,
    'Crângurile de Sus': 4745,
    'Crevedia': 4745,
    'Croitori': 4745,
    'Crovu': 4745,
    'Dâmbovicioara': 4745,
    'Dărmăneşti': 4745,
    'Dârza': 4745,
    'Dealu Frumos': 4745,
    'Dealu Mare': 4745,
    'Decindeni': 4745,
    'Dobra': 4745,
    'Doiceşti': 4745,
    'Drăgăești Ungureni': 4745,
    'Dragodana': 4745,
    'Dragodănești': 4745,
    'Dragomireşti': 4745,
    'Fântânele': 4745,
    'Fieni': 4745,
    'Fierbinți': 4745,
    'Finta Mare': 4745,
    'Găeşti': 4745,
    'Gemenea Brătulești': 4745,
    'Gheboaia': 4745,
    'Gheboieni': 4745,
    'Ghinești': 4745,
    'Ghirdoveni': 4745,
    'Glod': 4745,
    'Glodeni': 4745,
    'Gorgota': 4745,
    'Greci': 4745,
    'Grozăvești': 4745,
    'Gulia': 4745,
    'Gura Foii': 4745,
    'Gura Ocniței': 4745,
    'Gura Şuţii': 4745,
    'Gura Vulcanei': 4745,
    'Hăbeni': 4745,
    'Hulubeşti': 4745,
    'I. L. Caragiale': 4745,
    'Iazu': 4745,
    'Ibrianu': 4745,
    'Iedera de Jos': 4745,
    'Iedera de Sus': 4745,
    'Ionești': 4745,
    'Izvoarele': 4745,
    'Lăculețe': 4745,
    'Lazuri': 4745,
    'Lucieni': 4745,
    'Ludeşti': 4745,
    'Lunca': 4745,
    'Lunguleţu': 4745,
    'Malu cu Flori': 4745,
    'Măneşti': 4745,
    'Mărcești': 4745,
    'Mărginenii de Sus': 4745,
    'Mătăsaru': 4745,
    'Mavrodin': 4745,
    'Mija': 4745,
    'Mogoşani': 4745,
    'Moreni': 4745,
    'Moroeni': 4745,
    'Morteni': 4745,
    'Moțăieni': 4745,
    'Municipiul Moreni': 4745,
    'Municipiul Târgovişte': 4745,
    'Niculești': 4745,
    'Nucet': 4745,
    'Ocniţa': 4745,
    'Odaia Turcului': 4745,
    'Odobeşti': 4745,
    'Oraş Fieni': 4745,
    'Oraş Gãeşti': 4745,
    'Oraş Pucioasa': 4745,
    'Oraş Rãcari': 4745,
    'Oraş Titu': 4745,
    'Perșinari': 4745,
    'Petreşti': 4745,
    'Picior de Munte': 4745,
    'Pietrari': 4745,
    'Pietroşiţa': 4745,
    'Pitaru': 4745,
    'Poiana': 4745,
    'Potlogi': 4745,
    'Priboiu': 4745,
    'Produleşti': 4745,
    'Pucheni': 4745,
    'Pucioasa': 4745,
    'Răcari': 4745,
    'Raciu': 4745,
    'Racovița': 4745,
    'Râncăciov': 4745,
    'Răscăeți': 4745,
    'Râu Alb de Jos': 4745,
    'Răzvad': 4745,
    'Românești': 4745,
    'Runcu': 4745,
    'Săcueni': 4745,
    'Sălcioara': 4745,
    'Sălcuța': 4745,
    'Săteni': 4745,
    'Scheiu de Jos': 4745,
    'Scheiu de Sus': 4745,
    'Şelaru': 4745,
    'Serdanu': 4745,
    'Slobozia Moara': 4745,
    'Şotânga': 4745,
    'Speriețeni': 4745,
    'Suseni Socetu': 4745,
    'Târgovişte': 4745,
    'Tărtăşeşti': 4745,
    'Tătărani': 4745,
    'Teiș': 4745,
    'Tețcoiu': 4745,
    'Titu': 4745,
    'Ulieşti': 4745,
    'Ulmi': 4745,
    'Ungureni': 4745,
    'Urziceanca': 4745,
    'Văcăreşti': 4745,
    'Valea Leurzii': 4745,
    'Valea Lungă Ogrea': 4745,
    'Valea Lungă-Cricov': 4745,
    'Valea Mare': 4745,
    'Valea Voievozilor': 4745,
    'Văleni-Dâmbovița': 4745,
    'Vârfuri': 4745,
    'Viforâta': 4745,
    'Viișoara': 4745,
    'Vişina': 4745,
    'Vişineşti': 4745,
    'Vizurești': 4745,
    'Vlădeni': 4745,
    'Vlăsceni': 4745,
    'Voineşti': 4745,
    'Vulcana Băi': 4745,
    'Vulcana de Sus': 4745,
    'Vulcana-Pandele': 4745,
    'Zăvoiu': 4745,
    'Zidurile': 4745,
    'Afumaţi': 4742,
    'Almăj': 4742,
    'Amărăştii de Jos': 4742,
    'Amărăştii de Sus': 4742,
    'Apele Vii': 4742,
    'Argetoaia': 4742,
    'Bădoși': 4742,
    'Băileşti': 4742,
    'Bâlta': 4742,
    'Bârca': 4742,
    'Basarabi': 4742,
    'Bechet': 4742,
    'Bistreț': 4742,
    'Botoşeşti-Paia': 4742,
    'Boureni': 4742,
    'Brabeți': 4742,
    'Brabova': 4742,
    'Brădeşti': 4742,
    'Braloştiţa': 4742,
    'Bratovoești': 4742,
    'Breasta': 4742,
    'Bucovăţ': 4742,
    'Bulzeşti': 4742,
    'Calafat': 4742,
    'Călăraşi': 4742,
    'Calopăr': 4742,
    'Caraula': 4742,
    'Cârcea': 4742,
    'Cârna': 4742,
    'Carpen': 4742,
    'Castranova': 4742,
    'Castrele Traiane': 4742,
    'Catane': 4742,
    'Celaru': 4742,
    'Cerăt': 4742,
    'Cernăteşti': 4742,
    'Cernele': 4742,
    'Cetate': 4742,
    'Cioroiași': 4742,
    'Ciupercenii Noi': 4742,
    'Ciupercenii Vechi': 4742,
    'Cleanov': 4742,
    'Comoșteni': 4742,
    'Comuna Afumaţi': 4742,
    'Comuna Almãj': 4742,
    'Comuna Amărăştii de Jos': 4742,
    'Comuna Amărăştii de Sus': 4742,
    'Comuna Apele Vii': 4742,
    'Comuna Argetoaia': 4742,
    'Comuna Bârca': 4742,
    'Comuna Bistreţ': 4742,
    'Comuna Botoşeşti-Paia': 4742,
    'Comuna Brabova': 4742,
    'Comuna Brădeşti': 4742,
    'Comuna Braloştiţa': 4742,
    'Comuna Bratovoeşti': 4742,
    'Comuna Breasta': 4742,
    'Comuna Bucovăţ': 4742,
    'Comuna Bulzeşti': 4742,
    'Comuna Călăraşi': 4742,
    'Comuna Calopăru': 4742,
    'Comuna Caraula': 4742,
    'Comuna Cârcea': 4742,
    'Comuna Cârna': 4742,
    'Comuna Carpen': 4742,
    'Comuna Castranova': 4742,
    'Comuna Catane': 4742,
    'Comuna Celaru': 4742,
    'Comuna Cerãt': 4742,
    'Comuna Cernăteşti': 4742,
    'Comuna Cetate': 4742,
    'Comuna Cioroiaşi': 4742,
    'Comuna Ciupercenii Noi': 4742,
    'Comuna Coşoveni': 4742,
    'Comuna Coţofenii din Dos': 4742,
    'Comuna Coţofenii din Faţă': 4742,
    'Comuna Dăneţi': 4742,
    'Comuna Desa': 4742,
    'Comuna Dioşti': 4742,
    'Comuna Dobreşti': 4742,
    'Comuna Dobroteşti': 4742,
    'Comuna Drăgoteşti': 4742,
    'Comuna Drãnic': 4742,
    'Comuna Fãrcaş': 4742,
    'Comuna Galicea Mare': 4742,
    'Comuna Galiciuica': 4742,
    'Comuna Gângiova': 4742,
    'Comuna Gherceşti': 4742,
    'Comuna Ghidici': 4742,
    'Comuna Ghindeni': 4742,
    'Comuna Gighera': 4742,
    'Comuna Giubega': 4742,
    'Comuna Giurgiţa': 4742,
    'Comuna Gogoşu': 4742,
    'Comuna Goicea': 4742,
    'Comuna Goieşti': 4742,
    'Comuna Greceşti': 4742,
    'Comuna Întorsura': 4742,
    'Comuna Işalniţa': 4742,
    'Comuna Izvoare': 4742,
    'Comuna Leu': 4742,
    'Comuna Lipovu': 4742,
    'Comuna Măceşu de Jos': 4742,
    'Comuna Măceşu de Sus': 4742,
    'Comuna Maglavit': 4742,
    'Comuna Malu Mare': 4742,
    'Comuna Mârşani': 4742,
    'Comuna Melineşti': 4742,
    'Comuna Mischii': 4742,
    'Comuna Moţăţei': 4742,
    'Comuna Murgaşi': 4742,
    'Comuna Negoi': 4742,
    'Comuna Orodel': 4742,
    'Comuna Ostroveni': 4742,
    'Comuna Perişor': 4742,
    'Comuna Pieleşti': 4742,
    'Comuna Piscu Vechi': 4742,
    'Comuna Pleniţa': 4742,
    'Comuna Pleşoi': 4742,
    'Comuna Podari': 4742,
    'Comuna Poiana Mare': 4742,
    'Comuna Predeşti': 4742,
    'Comuna Radovan': 4742,
    'Comuna Rast': 4742,
    'Comuna Robăneşti': 4742,
    'Comuna Rojişte': 4742,
    'Comuna Sadova': 4742,
    'Comuna Sălcuţa': 4742,
    'Comuna Scăeşti': 4742,
    'Comuna Seaca de Câmp': 4742,
    'Comuna Seaca de Pădure': 4742,
    'Comuna Secu': 4742,
    'Comuna Siliştea Crucii': 4742,
    'Comuna Şimnicu de Sus': 4742,
    'Comuna Sopot': 4742,
    'Comuna Tãlpaş': 4742,
    'Comuna Teasc': 4742,
    'Comuna Terpeziţa': 4742,
    'Comuna Teslui': 4742,
    'Comuna Ţuglui': 4742,
    'Comuna Unirea': 4742,
    'Comuna Urzicuţa': 4742,
    'Comuna Valea Stanciului': 4742,
    'Comuna Vârtop': 4742,
    'Comuna Vârvoru de Jos': 4742,
    'Comuna Vela': 4742,
    'Comuna Verbiţa': 4742,
    'Coşoveni': 4742,
    'Coţofenii din Dos': 4742,
    'Coțofenii din Față': 4742,
    'Craiova': 4742,
    'Dăbuleni': 4742,
    'Dăneţi': 4742,
    'Desa': 4742,
    'Dioşti': 4742,
    'Dobreşti': 4742,
    'Dobridor': 4742,
    'Dobrotești': 4742,
    'Drăgoteşti': 4742,
    'Drănic': 4742,
    'Făcăi': 4742,
    'Fărcaș': 4742,
    'Filiaşi': 4742,
    'Fratoștița': 4742,
    'Galicea Mare': 4742,
    'Galiciuica': 4742,
    'Gângiova': 4742,
    'Ghidici': 4742,
    'Ghindeni': 4742,
    'Ghizdăvești': 4742,
    'Gighera': 4742,
    'Giubega': 4742,
    'Giurgiţa': 4742,
    'Gogoşu': 4742,
    'Goicea': 4742,
    'Goiești': 4742,
    'Greceşti': 4742,
    'Hunia': 4742,
    'Întorsura': 4742,
    'Işalniţa': 4742,
    'Izvoare': 4742,
    'Leu': 4742,
    'Lipovu': 4742,
    'Lișteava': 4742,
    'Măceşu de Jos': 4742,
    'Măceşu de Sus': 4742,
    'Maglavit': 4742,
    'Malu Mare': 4742,
    'Mârşani': 4742,
    'Melineşti': 4742,
    'Mischii': 4742,
    'Mofleni': 4742,
    'Moţăţei': 4742,
    'Municipiul Bãileşti': 4742,
    'Municipiul Calafat': 4742,
    'Municipiul Craiova': 4742,
    'Murgași': 4742,
    'Nedeia': 4742,
    'Negoi': 4742,
    'Negoiești': 4742,
    'Ocolna': 4742,
    'Oraş Bechet': 4742,
    'Oraş Dãbuleni': 4742,
    'Oraş Filiaşi': 4742,
    'Oraş Segarcea': 4742,
    'Orodel': 4742,
    'Ostroveni': 4742,
    'Padea': 4742,
    'Perișor': 4742,
    'Pieleştí': 4742,
    'Piscu Nou': 4742,
    'Piscu Sadovei': 4742,
    'Piscu Vechi': 4742,
    'Pleniţa': 4742,
    'Pleșoi': 4742,
    'Podari': 4742,
    'Poiana Mare': 4742,
    'Popoveni': 4742,
    'Prapor': 4742,
    'Preajba': 4742,
    'Predeşti': 4742,
    'Puțuri': 4742,
    'Răcarii de Sus': 4742,
    'Radomir': 4742,
    'Radovan': 4742,
    'Rast': 4742,
    'Rojiște': 4742,
    'Sadova': 4742,
    'Salcia': 4742,
    'Sălcuţa': 4742,
    'Sărata': 4742,
    'Satu Nou Calopăr': 4742,
    'Scăeşti': 4742,
    'Seaca de Câmp': 4742,
    'Seaca de Pădure': 4742,
    'Secu': 4742,
    'Secui': 4742,
    'Segarcea': 4742,
    'Sfârcea': 4742,
    'Siliştea Crucii': 4742,
    'Şimnicu de Sus': 4742,
    'Smârdan': 4742,
    'Sopot': 4742,
    'Tălpaș': 4742,
    'Teasc': 4742,
    'Terpeziţa': 4742,
    'Teslui': 4742,
    'Ţuglui': 4742,
    'Unirea': 4742,
    'Urzicuţa': 4742,
    'Valea Stanciului': 4742,
    'Vârtop': 4742,
    'Vârvoru de Jos': 4742,
    'Vela': 4742,
    'Verbiţa': 4742,
    'Zănoaga': 4742,
    'Zăval': 4742,
    'Bălăbănești': 4747,
    'Bălăşeşti': 4747,
    'Băleni': 4747,
    'Balintești': 4747,
    'Băneasa': 4747,
    'Barcea': 4747,
    'Berești': 4747,
    'Bereşti-Sat': 4747,
    'Blânzi': 4747,
    'Brăhășești': 4747,
    'Brăhăşeştii de Sus': 4747,
    'Braniştea': 4747,
    'Bucești': 4747,
    'Buciumeni': 4747,
    'Cavadineşti': 4747,
    'Cerţeşti': 4747,
    'Chiraftei': 4747,
    'Ciorăști': 4747,
    'Cișmele': 4747,
    'Comuna Bălăbăneşti': 4747,
    'Comuna Bălăşeşti': 4747,
    'Comuna Băleni': 4747,
    'Comuna Băneasa': 4747,
    'Comuna Barcea': 4747,
    'Comuna Bereşti-Meria': 4747,
    'Comuna Brăhăşeşti': 4747,
    'Comuna Braniştea': 4747,
    'Comuna Buciumeni': 4747,
    'Comuna Cavadineşti': 4747,
    'Comuna Cerţeşti': 4747,
    'Comuna Corni': 4747,
    'Comuna Corod': 4747,
    'Comuna Cosmeşti': 4747,
    'Comuna Costache Negri': 4747,
    'Comuna Cuca': 4747,
    'Comuna Cudalbi': 4747,
    'Comuna Cuza Vodă': 4747,
    'Comuna Drăgăneşti': 4747,
    'Comuna Drăguşeni': 4747,
    'Comuna Fârţăneşti': 4747,
    'Comuna Folteşti': 4747,
    'Comuna Frumuşiţa': 4747,
    'Comuna Fundeni': 4747,
    'Comuna Ghidigeni': 4747,
    'Comuna Gohor': 4747,
    'Comuna Griviţa': 4747,
    'Comuna Independenţa': 4747,
    'Comuna Iveşti': 4747,
    'Comuna Jorăşti': 4747,
    'Comuna Lieşti': 4747,
    'Comuna Măstăcani': 4747,
    'Comuna Matca': 4747,
    'Comuna Movileni': 4747,
    'Comuna Munteni': 4747,
    'Comuna Nămoloasa': 4747,
    'Comuna Negrileşti': 4747,
    'Comuna Nicoreşti': 4747,
    'Comuna Oancea': 4747,
    'Comuna Pechea': 4747,
    'Comuna Piscu': 4747,
    'Comuna Poiana': 4747,
    'Comuna Priponeşti': 4747,
    'Comuna Rădeşti': 4747,
    'Comuna Rediu': 4747,
    'Comuna Scânteieşti': 4747,
    'Comuna Schela': 4747,
    'Comuna Şendreni': 4747,
    'Comuna Slobozia Conachi': 4747,
    'Comuna Smârdan': 4747,
    'Comuna Smulţi': 4747,
    'Comuna Suceveni': 4747,
    'Comuna Suhurlui': 4747,
    'Comuna Ţepu': 4747,
    'Comuna Tudor Vladimirescu': 4747,
    'Comuna Tuluceşti': 4747,
    'Comuna Umbrăreşti': 4747,
    'Comuna Valea Mãrului': 4747,
    'Comuna Vânători': 4747,
    'Comuna Vârlezi': 4747,
    'Comuna Vlădeşti': 4747,
    'Corni': 4747,
    'Corod': 4747,
    'Cosmeşti': 4747,
    'Cosmeștii-Vale': 4747,
    'Costache Negri': 4747,
    'Crăiești': 4747,
    'Cuca': 4747,
    'Cudalbi': 4747,
    'Cuza Vodă': 4747,
    'Drăgăneşti': 4747,
    'Drăguşeni': 4747,
    'Fântânele': 4747,
    'Fârţăneşti': 4747,
    'Folteşti': 4747,
    'Frumuşiţa': 4747,
    'Fundeni': 4747,
    'Furcenii Noi': 4747,
    'Galaţi': 4747,
    'Gănești': 4747,
    'Ghidigeni': 4747,
    'Gohor': 4747,
    'Griviţa': 4747,
    'Hanu Conachi': 4747,
    'Independenţa': 4747,
    'Iveşti': 4747,
    'Jorăşti': 4747,
    'Lieşti': 4747,
    'Mândrești': 4747,
    'Măstăcani': 4747,
    'Matca': 4747,
    'Moscu': 4747,
    'Movileni': 4747,
    'Municipiul Galaţi': 4747,
    'Municipiul Tecuci': 4747,
    'Munteni': 4747,
    'Nămoloasa': 4747,
    'Nămoloasa-Sat': 4747,
    'Negrilești': 4747,
    'Nicoreşti': 4747,
    'Oancea': 4747,
    'Odaia Manolache': 4747,
    'Oraş Bereşti': 4747,
    'Oraș Târgu Bujor': 4747,
    'Pechea': 4747,
    'Piscu': 4747,
    'Podoleni': 4747,
    'Poiana': 4747,
    'Priponeşti': 4747,
    'Rădești': 4747,
    'Rediu': 4747,
    'Scânteiești': 4747,
    'Schela': 4747,
    'Şendreni': 4747,
    'Șivița': 4747,
    'Slobozia Conachi': 4747,
    'Smârdan': 4747,
    'Smulţi': 4747,
    'Suceveni': 4747,
    'Suhurlui': 4747,
    'Tălpigi': 4747,
    'Târgu Bujor': 4747,
    'Tecuci': 4747,
    'Ţepu': 4747,
    'Toflea': 4747,
    'Tudor Vladimirescu': 4747,
    'Tuluceşti': 4747,
    'Umbrăreşti': 4747,
    'Umbrărești-Deal': 4747,
    'Ungureni': 4747,
    'Valea Mărului': 4747,
    'Vânători': 4747,
    'Vârlezi': 4747,
    'Viile': 4747,
    'Vlădeşti': 4747,
    'Adunații-Copăceni': 4726,
    'Bâcu': 4726,
    'Băneasa': 4726,
    'Bolintin Deal': 4726,
    'Bolintin Vale': 4726,
    'Brăniștari': 4726,
    'Braniștea': 4726,
    'Bucşani': 4726,
    'Bulbucata': 4726,
    'Buturugeni': 4726,
    'Călugăreni': 4726,
    'Câmpurelu': 4726,
    'Cartojani': 4726,
    'Căscioarele': 4726,
    'Cetatea': 4726,
    'Chiriacu': 4726,
    'Clejani': 4726,
    'Colibaşi': 4726,
    'Comana': 4726,
    'Comuna Adunaţii-Copăceni': 4726,
    'Comuna Băneasa': 4726,
    'Comuna Bolintin Deal': 4726,
    'Comuna Bucşani': 4726,
    'Comuna Bulbucata': 4726,
    'Comuna Buturugeni': 4726,
    'Comuna Călugăreni': 4726,
    'Comuna Clejani': 4726,
    'Comuna Colibaşi': 4726,
    'Comuna Comana': 4726,
    'Comuna Cosoba': 4726,
    'Comuna Crevedia Mare': 4726,
    'Comuna Daia': 4726,
    'Comună Floreşti-Stoeneşti': 4726,
    'Comuna Frăteşti': 4726,
    'Comuna Gãiseni': 4726,
    'Comuna Găujani': 4726,
    'Comuna Ghimpaţi': 4726,
    'Comuna Gogoşari': 4726,
    'Comuna Goştinari': 4726,
    'Comuna Gostinu': 4726,
    'Comuna Grădinari': 4726,
    'Comuna Greaca': 4726,
    'Comuna Herăşti': 4726,
    'Comuna Hotarele': 4726,
    'Comuna Iepureşti': 4726,
    'Comuna Isvoarele': 4726,
    'Comuna Izvoarele': 4726,
    'Comuna Joiţa': 4726,
    'Comuna Letca Nouă': 4726,
    'Comuna Malu': 4726,
    'Comuna Mârşa': 4726,
    'Comuna Mihai Bravu': 4726,
    'Comuna Ogrezeni': 4726,
    'Comuna Oinacu': 4726,
    'Comuna Prundu': 4726,
    'Comuna Putineiu': 4726,
    'Comuna Răsuceni': 4726,
    'Comuna Roata De Jos': 4726,
    'Comuna Săbăreni': 4726,
    'Comuna Schitu': 4726,
    'Comuna Singureni': 4726,
    'Comuna Slobozia': 4726,
    'Comuna Stăneşti': 4726,
    'Comuna Stoeneşti': 4726,
    'Comuna Toporu': 4726,
    'Comuna Ulmi': 4726,
    'Comuna Valea Dragului': 4726,
    'Comuna Vânătorii Mici': 4726,
    'Comuna Vărăşti': 4726,
    'Comuna Vedea': 4726,
    'Copaciu': 4726,
    'Cosoba': 4726,
    'Crevedia Mare': 4726,
    'Cucuruzu': 4726,
    'Daia': 4726,
    'Dărăști-Vlașca': 4726,
    'Dealu': 4726,
    'Dobreni': 4726,
    'Falaștoaca': 4726,
    'Florești': 4726,
    'Frăteşti': 4726,
    'Găiseni': 4726,
    'Găujani': 4726,
    'Ghimpați': 4726,
    'Giurgiu': 4726,
    'Gogoşari': 4726,
    'Gostinari': 4726,
    'Goştinari-Văcăreşti': 4726,
    'Gostinu': 4726,
    'Grădinari': 4726,
    'Grădiștea': 4726,
    'Greaca': 4726,
    'Herăști': 4726,
    'Hotarele': 4726,
    'Hulubești': 4726,
    'Icoana': 4726,
    'Iepureşti': 4726,
    'Izvoarele': 4726,
    'Joiţa': 4726,
    'Letca Nouă': 4726,
    'Letca Veche': 4726,
    'Malu': 4726,
    'Malu Spart': 4726,
    'Mârşa': 4726,
    'Mihai Bravu': 4726,
    'Mihai Vodă': 4726,
    'Mihăileşti': 4726,
    'Milcovățu': 4726,
    'Municipiul Giurgiu': 4726,
    'Naipu': 4726,
    'Novaci': 4726,
    'Ogrezeni': 4726,
    'Oinacu': 4726,
    'Oraş Bolintin-Vale': 4726,
    'Oraş Mihãileşti': 4726,
    'Pădureni': 4726,
    'Palanca': 4726,
    'Pietrele': 4726,
    'Pietrișu': 4726,
    'Plopșoru': 4726,
    'Popești': 4726,
    'Prundu': 4726,
    'Puieni': 4726,
    'Putineiu': 4726,
    'Răsuceni': 4726,
    'Remuș': 4726,
    'Roata de Jos': 4726,
    'Săbăreni': 4726,
    'Sadina': 4726,
    'Schitu': 4726,
    'Singureni': 4726,
    'Slobozia': 4726,
    'Stăneşti': 4726,
    'Stoeneşti': 4726,
    'Tântava': 4726,
    'Toporu': 4726,
    'Trestieni': 4726,
    'Ulmi': 4726,
    'Uzunu': 4726,
    'Valea Dragului': 4726,
    'Vânătorii Mari': 4726,
    'Vânătorii Mici': 4726,
    'Vărăşti': 4726,
    'Vedea': 4726,
    'Vieru': 4726,
    'Vlad Țepeș': 4726,
    'Zorile': 4726,
    'Albeni': 4750,
    'Alimpeşti': 4750,
    'Andreești': 4750,
    'Aninoasa': 4750,
    'Arcani': 4750,
    'Baia de Fier': 4750,
    'Bălcești': 4750,
    'Băleşti': 4750,
    'Bâlta': 4750,
    'Bâlteni': 4750,
    'Bărbăteşti': 4750,
    'Bengești': 4750,
    'Berleşti': 4750,
    'Bolboşi': 4750,
    'Borăscu': 4750,
    'Brăneşti': 4750,
    'Bumbeşti-Jiu': 4750,
    'Bustuchin': 4750,
    'Câlnic': 4750,
    'Căpreni': 4750,
    'Cărpiniș': 4750,
    'Cătunele': 4750,
    'Ceauru': 4750,
    'Ciuperceni': 4750,
    'Cloșani': 4750,
    'Cocoreni': 4750,
    'Comuna Albeni': 4750,
    'Comuna Alimpeşti': 4750,
    'Comuna Aninoasa': 4750,
    'Comuna Arcani': 4750,
    'Comuna Baia de Fier': 4750,
    'Comuna Bălăneşti': 4750,
    'Comuna Băleşti': 4750,
    'Comuna Bâlteni': 4750,
    'Comuna Bărbăteşti': 4750,
    'Comuna Bengeşti-Ciocadia': 4750,
    'Comuna Bolboşi': 4750,
    'Comuna Borăscu': 4750,
    'Comuna Brăneşti': 4750,
    'Comuna Bumbeşti-Piţic': 4750,
    'Comuna Bustuchin': 4750,
    'Comuna Câlnic': 4750,
    'Comuna Căpreni': 4750,
    'Comuna Cătunele': 4750,
    'Comuna Ciuperceni': 4750,
    'Comuna Crasna': 4750,
    'Comuna Crușeț': 4750,
    'Comuna Dănciuleşti': 4750,
    'Comuna Dăneşti': 4750,
    'Comuna Drăgoteşti': 4750,
    'Comuna Drãguţeşti': 4750,
    'Comuna Fãrcãşeşti': 4750,
    'Comuna Glogova': 4750,
    'Comuna Godineşti': 4750,
    'Comuna Hurezani': 4750,
    'Comuna Ioneşti': 4750,
    'Comuna Jupâneşti': 4750,
    'Comuna Leleşti': 4750,
    'Comuna Licurici': 4750,
    'Comuna Logreşti-Moşteni': 4750,
    'Comuna Mătăsari': 4750,
    'Comuna Muşeteşti': 4750,
    'Comuna Negomir': 4750,
    'Comuna Padeş': 4750,
    'Comuna Peştişani': 4750,
    'Comuna Plopşoru': 4750,
    'Comuna Polovragi': 4750,
    'Comuna Prigoria': 4750,
    'Comuna Roşia De Amaradia': 4750,
    'Comuna Runcu': 4750,
    'Comuna Săcelu': 4750,
    'Comuna Samarineşti': 4750,
    'Comuna Săuleşti': 4750,
    'Comuna Schela': 4750,
    'Comuna Scoarţa': 4750,
    'Comuna Slivileşti': 4750,
    'Comuna Stăneşti': 4750,
    'Comuna Stejari': 4750,
    'Comuna Stoina': 4750,
    'Comuna Ţânţăreni': 4750,
    'Comuna Teleşti': 4750,
    'Comuna Turburea': 4750,
    'Comuna Turcineşti': 4750,
    'Comuna Urdari': 4750,
    'Comuna Văgiuleşti': 4750,
    'Comuna Vladimir': 4750,
    'Costești': 4750,
    'Covrigi': 4750,
    'Crasna': 4750,
    'Crușeț': 4750,
    'Dănciuleşti': 4750,
    'Dăneşti': 4750,
    'Dobrița': 4750,
    'Drăgoteşti': 4750,
    'Drăguţeşti': 4750,
    'Fărcăşeşti': 4750,
    'Florești': 4750,
    'Glogova': 4750,
    'Godineşti': 4750,
    'Hurezani': 4750,
    'Ioneşti': 4750,
    'Jupâneşti': 4750,
    'Leleşti': 4750,
    'Licurici': 4750,
    'Logreşti': 4750,
    'Logrești Moșteni': 4750,
    'Mătăsari': 4750,
    'Motru': 4750,
    'Municipiul Motru': 4750,
    'Municipiul Târgu Jiu': 4750,
    'Muşeteşti': 4750,
    'Negomir': 4750,
    'Novaci': 4750,
    'Oraş Bumbeşti-Jiu': 4750,
    'Oraş Rovinari': 4750,
    'Oraş Târgu Cãrbuneşti': 4750,
    'Oraş Ţicleni': 4750,
    'Oraş Tismana': 4750,
    'Oraş Turceni': 4750,
    'Padeş': 4750,
    'Peșteana de Jos': 4750,
    'Peșteana Jiu': 4750,
    'Peştişani': 4750,
    'Piscoiu': 4750,
    'Plopşoru': 4750,
    'Ploștina': 4750,
    'Pociovaliștea': 4750,
    'Pocruia': 4750,
    'Poiana': 4750,
    'Pojogeni': 4750,
    'Polovragi': 4750,
    'Prigoria': 4750,
    'Roşia de Amaradia': 4750,
    'Rovinari': 4750,
    'Runcu': 4750,
    'Săcelu': 4750,
    'Samarineşti': 4750,
    'Sâmbotin': 4750,
    'Săuleşti': 4750,
    'Scoarţa': 4750,
    'Slivileşti': 4750,
    'Sohodol': 4750,
    'Stăneşti': 4750,
    'Stejari': 4750,
    'Sterpoaia': 4750,
    'Stoina': 4750,
    'Ţânţăreni': 4750,
    'Târgu Cărbuneşti': 4750,
    'Târgu Jiu': 4750,
    'Teleşti': 4750,
    'Ţicleni': 4750,
    'Tismana': 4750,
    'Turburea': 4750,
    'Turceni': 4750,
    'Turcineşti': 4750,
    'Urdari': 4750,
    'Văgiuleşti': 4750,
    'Valea Mare': 4750,
    'Vierșani': 4750,
    'Vlăduleni': 4750,
    'Voiteștii din Vale': 4750,
    'Atid': 4749,
    'Avrămeşti': 4749,
    'Băile Tuşnad': 4749,
    'Bălan': 4749,
    'Bancu': 4749,
    'Bilbor': 4749,
    'Borsec': 4749,
    'Brădeşti': 4749,
    'Căpâlniţa': 4749,
    'Cârţa': 4749,
    'Ciceu': 4749,
    'Ciucani': 4749,
    'Ciucsângeorgiu': 4749,
    'Ciumani': 4749,
    'Comuna Atid': 4749,
    'Comuna Avrămeşti': 4749,
    'Comuna Bilbor': 4749,
    'Comuna Brădeşti': 4749,
    'Comuna Căpâlniţa': 4749,
    'Comuna Cârţa': 4749,
    'Comuna Ciceu': 4749,
    'Comuna Ciucsângeorgiu': 4749,
    'Comuna Ciumani': 4749,
    'Comuna Corbu': 4749,
    'Comuna Corund': 4749,
    'Comuna Cozmeni': 4749,
    'Comuna Dăneşti': 4749,
    'Comuna Dârjiu': 4749,
    'Comuna Dealu': 4749,
    'Comuna Ditrău': 4749,
    'Comuna Feliceni': 4749,
    'Comuna Frumoasa': 4749,
    'Comuna Gălăuţaş': 4749,
    'Comuna Joseni': 4749,
    'Comuna Lăzarea': 4749,
    'Comuna Leliceni': 4749,
    'Comuna Lueta': 4749,
    'Comuna Lunca de Jos': 4749,
    'Comuna Lunca de Sus': 4749,
    'Comuna Lupeni': 4749,
    'Comuna Mădăraş': 4749,
    'Comuna Mărtiniş': 4749,
    'Comuna Mereşti': 4749,
    'Comuna Mihăileni': 4749,
    'Comuna Mugeni': 4749,
    'Comuna Ocland': 4749,
    'Comuna Pãuleni-Ciuc': 4749,
    'Comuna Plãieşii De Jos': 4749,
    'Comuna Porumbeni': 4749,
    'Comuna Praid': 4749,
    'Comuna Racu': 4749,
    'Comuna Remetea': 4749,
    'Comuna Săcel': 4749,
    'Comuna Sâncrăieni': 4749,
    'Comuna Sândominic': 4749,
    'Comuna Sânmartin': 4749,
    'Comuna Sânsimion': 4749,
    'Comuna Sântimbru': 4749,
    'Comuna Sărmaş': 4749,
    'Comuna Satu Mare': 4749,
    'Comuna Secuieni': 4749,
    'Comuna Siculeni': 4749,
    'Comuna Simoneşti': 4749,
    'Comuna Subcetate': 4749,
    'Comuna Suseni': 4749,
    'Comuna Tomeşti': 4749,
    'Comuna Tulgheş': 4749,
    'Comuna Tuşnad': 4749,
    'Comuna Ulieş': 4749,
    'Comuna Vărşag': 4749,
    'Comuna Voşlãbeni': 4749,
    'Comuna Zetea': 4749,
    'Corbu': 4749,
    'Corund': 4749,
    'Cozmeni': 4749,
    'Cristuru Secuiesc': 4749,
    'Dăneşti': 4749,
    'Dârjiu': 4749,
    'Dealu': 4749,
    'Ditrău': 4749,
    'Eliseni': 4749,
    'Feliceni': 4749,
    'Filiaș': 4749,
    'Frumoasa': 4749,
    'Gălăuţaş': 4749,
    'Gheorgheni': 4749,
    'Hodoșa': 4749,
    'Imper': 4749,
    'Ineu': 4749,
    'Joseni': 4749,
    'Lăzarea': 4749,
    'Leliceni': 4749,
    'Lueta': 4749,
    'Lunca de Jos': 4749,
    'Lunca de Sus': 4749,
    'Lupeni': 4749,
    'Mădăraș': 4749,
    'Mărtiniş': 4749,
    'Mereşti': 4749,
    'Miercurea-Ciuc': 4749,
    'Mihăileni': 4749,
    'Misentea': 4749,
    'Mugeni': 4749,
    'Municipiul  Topliţa': 4749,
    'Municipiul Gheorgheni': 4749,
    'Municipiul Miercurea Ciuc': 4749,
    'Municipiul Odorheiu Secuiesc': 4749,
    'Nicolești': 4749,
    'Ocland': 4749,
    'Ocna de Jos': 4749,
    'Ocna de Sus': 4749,
    'Odorheiu Secuiesc': 4749,
    'Oraş Bãile Tuşnad': 4749,
    'Oraş Bãlan': 4749,
    'Oraş Borsec': 4749,
    'Oraş Cristuru Secuiesc': 4749,
    'Oraş Vlãhiţa': 4749,
    'Păuleni-Ciuc': 4749,
    'Porumbenii Mari': 4749,
    'Praid': 4749,
    'Racu': 4749,
    'Remetea': 4749,
    'Săcel': 4749,
    'Sâncrai': 4749,
    'Sâncrăieni': 4749,
    'Sândominic': 4749,
    'Sânmartin': 4749,
    'Sânsimion': 4749,
    'Sântimbru': 4749,
    'Sărmaş': 4749,
    'Satu Mare': 4749,
    'Siculeni': 4749,
    'Simoneşti': 4749,
    'Subcetate': 4749,
    'Suseni': 4749,
    'Tăureni': 4749,
    'Tomești': 4749,
    'Tulgheş': 4749,
    'Tuşnad': 4749,
    'Tușnadu Nou': 4749,
    'Vale': 4749,
    'Valea Rece': 4749,
    'Valea Strâmbă': 4749,
    'Vărşag': 4749,
    'Vlăhiţa': 4749,
    'Voșlăbeni': 4749,
    'Zencani': 4749,
    'Zetea': 4749,
    'Aninoasa': 4721,
    'Băcia': 4721,
    'Baia de Criş': 4721,
    'Băiţa': 4721,
    'Balşa': 4721,
    'Băniţa': 4721,
    'Baru': 4721,
    'Baru Mic': 4721,
    'Barza': 4721,
    'Bătrâna': 4721,
    'Beriu': 4721,
    'Blăjeni': 4721,
    'Boşorod': 4721,
    'Brad': 4721,
    'Brănişca': 4721,
    'Brazi': 4721,
    'Bretea Română': 4721,
    'Buceş': 4721,
    'Bucium-Orlea': 4721,
    'Bucureşci': 4721,
    'Bulzeștii de Sus': 4721,
    'Bunila': 4721,
    'Burjuc': 4721,
    'Călan': 4721,
    'Cârjiţi': 4721,
    'Căstău': 4721,
    'Cerbăl': 4721,
    'Certeju de Sus': 4721,
    'Comuna Băcia': 4721,
    'Comuna Baia de Criş': 4721,
    'Comuna Băiţa': 4721,
    'Comuna Balşa': 4721,
    'Comuna Băniţa': 4721,
    'Comuna Baru': 4721,
    'Comuna Bătrâna': 4721,
    'Comuna Beriu': 4721,
    'Comuna Blăjeni': 4721,
    'Comuna Boşorod': 4721,
    'Comuna Brănişca': 4721,
    'Comuna Bretea Română': 4721,
    'Comuna Buceş': 4721,
    'Comuna Bucureşci': 4721,
    'Comuna Bulzeştii De Sus': 4721,
    'Comuna Bunila': 4721,
    'Comuna Burjuc': 4721,
    'Comuna Cârjiţi': 4721,
    'Comuna Cerbăl': 4721,
    'Comuna Certeju de Sus': 4721,
    'Comuna Crişcior': 4721,
    'Comuna Densuş': 4721,
    'Comuna Dobra': 4721,
    'Comuna General Berthelot': 4721,
    'Comuna Ghelari': 4721,
    'Comuna Gurasada': 4721,
    'Comuna Hărău': 4721,
    'Comuna Ilia': 4721,
    'Comuna Lãpugiu De Jos': 4721,
    'Comuna Lelese': 4721,
    'Comuna Lunca Cernii De Jos': 4721,
    'Comuna Luncoiu De Jos': 4721,
    'Comuna Mărtineşti': 4721,
    'Comuna Orăştioara de Sus': 4721,
    'Comuna Pestişu Mic': 4721,
    'Comuna Pui': 4721,
    'Comuna Răchitova': 4721,
    'Comuna Rapoltu Mare': 4721,
    'Comuna Râu de Mori': 4721,
    'Comuna Ribiţa': 4721,
    'Comuna Romos': 4721,
    'Comuna Sãlaşu De Sus': 4721,
    'Comuna Sântămăria-Orlea': 4721,
    'Comuna Sarmizegetusa': 4721,
    'Comuna Şoimuş': 4721,
    'Comuna Teliucu Inferior': 4721,
    'Comuna Tomeşti': 4721,
    'Comuna Topliţa': 4721,
    'Comuna Toteşti': 4721,
    'Comuna Turdaş': 4721,
    'Comuna Vălişoara': 4721,
    'Comuna Vaţa De Jos': 4721,
    'Comuna Veţel': 4721,
    'Comuna Vorţa': 4721,
    'Comuna Zam': 4721,
    'Crişcior': 4721,
    'Cristur': 4721,
    'Densuş': 4721,
    'Deva': 4721,
    'Dobra': 4721,
    'General Berthelot': 4721,
    'Geoagiu': 4721,
    'Ghelari': 4721,
    'Gurasada': 4721,
    'Hărău': 4721,
    'Hărțăgani': 4721,
    'Haţeg': 4721,
    'Hunedoara': 4721,
    'Ilia': 4721,
    'Iscroni': 4721,
    'Jiu-Paroșeni': 4721,
    'Lăpugiu de Jos': 4721,
    'Lelese': 4721,
    'Lunca Cernii de Jos': 4721,
    'Luncoiu de Jos': 4721,
    'Lupeni': 4721,
    'Mărtineşti': 4721,
    'Municipiul  Lupeni': 4721,
    'Municipiul  Vulcan': 4721,
    'Municipiul Brad': 4721,
    'Municipiul Deva': 4721,
    'Municipiul Hunedoara': 4721,
    'Municipiul Orãştie': 4721,
    'Municipiul Petroşani': 4721,
    'Oraş Aninoasa': 4721,
    'Oraş Cãlan': 4721,
    'Oraş Geoagiu': 4721,
    'Oraş Haţeg': 4721,
    'Oraş Petrila': 4721,
    'Oraş Simeria': 4721,
    'Oraş Uricani': 4721,
    'Orăştie': 4721,
    'Orăştioara de Sus': 4721,
    'Peștișu Mare': 4721,
    'Peştişu Mic': 4721,
    'Petrila': 4721,
    'Petroşani': 4721,
    'Pricaz': 4721,
    'Pui': 4721,
    'Răchitova': 4721,
    'Rapoltu Mare': 4721,
    'Râu de Mori': 4721,
    'Ribiţa': 4721,
    'Romos': 4721,
    'Sălaşu de Sus': 4721,
    'Sântămăria-Orlea': 4721,
    'Sarmizegetusa': 4721,
    'Simeria': 4721,
    'Şoimuş': 4721,
    'Teliucu Inferior': 4721,
    'Tomeşti': 4721,
    'Topliţa': 4721,
    'Toteşti': 4721,
    'Turdaş': 4721,
    'Uricani': 4721,
    'Vălişoara': 4721,
    'Vaţa de Jos': 4721,
    'Veţel': 4721,
    'Vorţa': 4721,
    'Vulcan': 4721,
    'Zam': 4721,
    'Zdrapți': 4721,
    'Adâncata': 4743,
    'Albeşti': 4743,
    'Alexeni': 4743,
    'Amara': 4743,
    'Andrăşeşti': 4743,
    'Armăşeşti': 4743,
    'Axintele': 4743,
    'Balaciu': 4743,
    'Bărbulești': 4743,
    'Bărcăneşti': 4743,
    'Borănești': 4743,
    'Borduşani': 4743,
    'Broșteni': 4743,
    'Bucu': 4743,
    'Buești': 4743,
    'Buliga': 4743,
    'Căzăneşti': 4743,
    'Cegani': 4743,
    'Ciocârlia': 4743,
    'Ciochina': 4743,
    'Ciulniţa': 4743,
    'Cocora': 4743,
    'Colelia': 4743,
    'Comuna Adâncata': 4743,
    'Comuna Albeşti': 4743,
    'Comuna Alexeni': 4743,
    'Comuna Andrăşeşti': 4743,
    'Comuna Armăşeşti': 4743,
    'Comuna Axintele': 4743,
    'Comuna Balaciu': 4743,
    'Comuna Bărbuleşti': 4743,
    'Comuna Bărcăneşti': 4743,
    'Comuna Borăneşti': 4743,
    'Comuna Borduşani': 4743,
    'Comuna Bucu': 4743,
    'Comuna Bueşti': 4743,
    'Comuna Ciocârlia': 4743,
    'Comuna Ciochina': 4743,
    'Comuna Ciulniţa': 4743,
    'Comuna Cocora': 4743,
    'Comuna Colelia': 4743,
    'Comuna Cosâmbeşti': 4743,
    'Comuna Coşereni': 4743,
    'Comuna Drăgoeşti': 4743,
    'Comuna Dridu': 4743,
    'Comuna Făcăeni': 4743,
    'Comuna Gârbovi': 4743,
    'Comuna Gheorghe Doja': 4743,
    'Comuna Gheorghe Lazăr': 4743,
    'Comuna Giurgeni': 4743,
    'Comuna Grindu': 4743,
    'Comuna Griviţa': 4743,
    'Comuna Gura Ialomiţei': 4743,
    'Comuna Ion Roată': 4743,
    'Comuna Jilavele': 4743,
    'Comuna Maia': 4743,
    'Comuna Manasia': 4743,
    'Comuna Mărculeşti': 4743,
    'Comuna Mihail Kogălniceanu': 4743,
    'Comuna Miloşeşti': 4743,
    'Comuna Moldoveni': 4743,
    'Comuna Movila': 4743,
    'Comuna Moviliţa': 4743,
    'Comuna Munteni Buzău': 4743,
    'Comuna Ograda': 4743,
    'Comuna Perieţi': 4743,
    'Comuna Platoneşti': 4743,
    'Comuna Răduleşti': 4743,
    'Comuna Reviga': 4743,
    'Comuna Roşiori': 4743,
    'Comuna Sălcioara': 4743,
    'Comuna Sărăţeni': 4743,
    'Comuna Săveni': 4743,
    'Comuna Scânteia': 4743,
    'Comuna Sfântu Gheorghe': 4743,
    'Comuna Sinteşti': 4743,
    'Comuna Slobozia': 4743,
    'Comuna Stelnica': 4743,
    'Comuna Sudiţi': 4743,
    'Comuna Traian': 4743,
    'Comuna Valea Ciorii': 4743,
    'Comuna Valea Măcrişului': 4743,
    'Comuna Vlădeni': 4743,
    'Condeești': 4743,
    'Cosâmbeşti': 4743,
    'Coşereni': 4743,
    'Drăgoești': 4743,
    'Drăgoeşti-Snagov': 4743,
    'Dridu': 4743,
    'Făcăeni': 4743,
    'Feteşti': 4743,
    'Fetești-Gară': 4743,
    'Fierbinţi-Târg': 4743,
    'Fierbinții de Jos': 4743,
    'Fierbinții de Sus': 4743,
    'Gârbovi': 4743,
    'Gheorghe Doja': 4743,
    'Gheorghe Lazăr': 4743,
    'Giurgeni': 4743,
    'Grindu': 4743,
    'Griviţa': 4743,
    'Gura Ialomiței': 4743,
    'Iazu': 4743,
    'Ion Roată': 4743,
    'Jilavele': 4743,
    'Lăcusteni': 4743,
    'Luciu': 4743,
    'Maia': 4743,
    'Malu Roșu': 4743,
    'Manasia': 4743,
    'Mărculești': 4743,
    'Mihail Kogălniceanu': 4743,
    'Miloşeşti': 4743,
    'Moldoveni': 4743,
    'Movila': 4743,
    'Moviliţa': 4743,
    'Municipiul Feteşti': 4743,
    'Municipiul Urziceni': 4743,
    'Munteni Buzău': 4743,
    'Ograda': 4743,
    'Oraş Amara': 4743,
    'Oraş Cãzãneşti': 4743,
    'Oraş Fierbinţi-Târg': 4743,
    'Oraş Ţãndãrei': 4743,
    'Patru Frați': 4743,
    'Perieţi': 4743,
    'Platonești': 4743,
    'Rădulești': 4743,
    'Rași': 4743,
    'Reviga': 4743,
    'Roșiori': 4743,
    'Rovine': 4743,
    'Sălcioara': 4743,
    'Sărățeni': 4743,
    'Săveni': 4743,
    'Scânteia': 4743,
    'Sfântu Gheorghe': 4743,
    'Sineşti': 4743,
    'Slobozia': 4743,
    'Smirna': 4743,
    'Stelnica': 4743,
    'Sudiţi': 4743,
    'Ţăndărei': 4743,
    'Traian': 4743,
    'Urziceni': 4743,
    'Valea Ciorii': 4743,
    'Valea Măcrișului': 4743,
    'Vlădeni': 4743,
    'Vlașca': 4743,
    'Alexandru I. Cuza': 4735,
    'Andrieşeni': 4735,
    'Aroneanu': 4735,
    'Bădeni': 4735,
    'Balș': 4735,
    'Bălţaţi': 4735,
    'Bârnova': 4735,
    'Belceşti': 4735,
    'Bivolari': 4735,
    'Bohotin': 4735,
    'Borosoaia': 4735,
    'Bosia': 4735,
    'Boureni': 4735,
    'Brădicești': 4735,
    'Brăeşti': 4735,
    'Brătești': 4735,
    'Breazu': 4735,
    'Buda': 4735,
    'Budăi': 4735,
    'Buhalnița': 4735,
    'Butea': 4735,
    'Buznea': 4735,
    'Cârjoaia': 4735,
    'Cârniceni': 4735,
    'Cepleniţa': 4735,
    'Chișcăreni': 4735,
    'Ciohorăni': 4735,
    'Ciorteşti': 4735,
    'Ciurea': 4735,
    'Coarnele Caprei': 4735,
    'Cogeasca': 4735,
    'Comarna': 4735,
    'Comuna Alexandru I. Cuza': 4735,
    'Comuna Andrieşeni': 4735,
    'Comuna Aroneanu': 4735,
    'Comuna Balş': 4735,
    'Comuna Bălţaţi': 4735,
    'Comuna Bârnova': 4735,
    'Comuna Belceşti': 4735,
    'Comuna Bivolari': 4735,
    'Comuna Brăeşti': 4735,
    'Comuna Butea': 4735,
    'Comuna Cepleniţa': 4735,
    'Comuna Ciohorãni': 4735,
    'Comuna Ciorteşti': 4735,
    'Comuna Ciurea': 4735,
    'Comuna Coarnele Caprei': 4735,
    'Comuna Comarna': 4735,
    'Comuna Costeşti': 4735,
    'Comuna Costuleni': 4735,
    'Comuna Cotnari': 4735,
    'Comuna Cozmeşti': 4735,
    'Comuna Cristeşti': 4735,
    'Comuna Cucuteni': 4735,
    'Comuna Dagâţa': 4735,
    'Comuna Deleni': 4735,
    'Comuna Dobrovăţ': 4735,
    'Comuna Dolheşti': 4735,
    'Comuna Drăguşeni': 4735,
    'Comuna Dumeşti': 4735,
    'Comuna Erbiceni': 4735,
    'Comuna Fântânele': 4735,
    'Comuna Focuri': 4735,
    'Comuna Golãieşti': 4735,
    'Comuna Gorban': 4735,
    'Comuna Grajduri': 4735,
    'Comuna Gropniţa': 4735,
    'Comuna Grozeşti': 4735,
    'Comuna Hălăuceşti': 4735,
    'Comuna Hărmăneşti': 4735,
    'Comuna Heleşteni': 4735,
    'Comuna Holboca': 4735,
    'Comuna Horleşti': 4735,
    'Comuna Ion Neculce': 4735,
    'Comuna Ipatele': 4735,
    'Comuna Lespezi': 4735,
    'Comuna Leţcani': 4735,
    'Comuna Lungani': 4735,
    'Comuna Mădârjac': 4735,
    'Comuna Mirceşti': 4735,
    'Comuna Mironeasa': 4735,
    'Comuna Miroslava': 4735,
    'Comuna Mirosloveşti': 4735,
    'Comuna Mogoşeşti': 4735,
    'Comuna Mogoşeşti-Siret': 4735,
    'Comuna Moşna': 4735,
    'Comuna Moţca': 4735,
    'Comuna Movileni': 4735,
    'Comuna Oţeleni': 4735,
    'Comuna Plugari': 4735,
    'Comuna Popeşti': 4735,
    'Comuna Popricani': 4735,
    'Comuna Prisăcani': 4735,
    'Comuna Probota': 4735,
    'Comuna Răchiţeni': 4735,
    'Comuna Răducăneni': 4735,
    'Comuna Rediu-Tătar': 4735,
    'Comuna Româneşti': 4735,
    'Comuna Roşcani': 4735,
    'Comuna Ruginoasa': 4735,
    'Comuna Scânteia': 4735,
    'Comuna Scheia': 4735,
    'Comuna Schitu-Duca': 4735,
    'Comuna Scobinţi': 4735,
    'Comuna Sineşti': 4735,
    'Comuna Şipote': 4735,
    'Comuna Sireţel': 4735,
    'Comuna Stolniceni-Prăjescu': 4735,
    'Comuna Strunga': 4735,
    'Comuna Tansa': 4735,
    'Comuna Tătăruşi': 4735,
    'Comuna Ţibana': 4735,
    'Comuna Ţibăneşti': 4735,
    'Comuna Ţigănaşi': 4735,
    'Comuna Todireşti': 4735,
    'Comuna Tomeşti': 4735,
    'Comuna Trifeşti': 4735,
    'Comuna Ţuţora': 4735,
    'Comuna Ungheni': 4735,
    'Comuna Valea Lupului': 4735,
    'Comuna Valea Seacă': 4735,
    'Comuna Vânãtori': 4735,
    'Comuna Victoria': 4735,
    'Comuna Vlădeni': 4735,
    'Comuna Voineşti': 4735,
    'Conțești': 4735,
    'Coropceni': 4735,
    'Costești': 4735,
    'Costuleni': 4735,
    'Cotnari': 4735,
    'Covasna': 4735,
    'Cozia': 4735,
    'Cozmeşti': 4735,
    'Cristeşti': 4735,
    'Crivești': 4735,
    'Crucea': 4735,
    'Cucuteni': 4735,
    'Dagâţa': 4735,
    'Dancu': 4735,
    'Deleni': 4735,
    'Dobrovăţ': 4735,
    'Dolheşti': 4735,
    'Domnița': 4735,
    'Drăgușeni': 4735,
    'Dumbrava': 4735,
    'Dumbrăvița': 4735,
    'Dumeşti': 4735,
    'Erbiceni': 4735,
    'Fântânele': 4735,
    'Fărcășeni': 4735,
    'Fetești': 4735,
    'Focuri': 4735,
    'Gârbești': 4735,
    'Glodenii Gândului': 4735,
    'Goești': 4735,
    'Golăiești': 4735,
    'Gorban': 4735,
    'Goruni': 4735,
    'Grajduri': 4735,
    'Gropniţa': 4735,
    'Grozeşti': 4735,
    'Hălăuceşti': 4735,
    'Hârlău': 4735,
    'Hărmăneasa': 4735,
    'Hărmăneștii Vechi': 4735,
    'Hârtoape': 4735,
    'Heci': 4735,
    'Heleșteni': 4735,
    'Hodora': 4735,
    'Holboca': 4735,
    'Horleşti': 4735,
    'Iaşi': 4735,
    'Iazu Nou': 4735,
    'Ion Neculce': 4735,
    'Ipatele': 4735,
    'Iugani': 4735,
    'Izvoarele': 4735,
    'Lespezi': 4735,
    'Leţcani': 4735,
    'Liteni': 4735,
    'Lunca': 4735,
    'Lunca Cetățuii': 4735,
    'Lungani': 4735,
    'Mădârjac': 4735,
    'Mănăstirea': 4735,
    'Maxut': 4735,
    'Mirceşti': 4735,
    'Mironeasa': 4735,
    'Miroslava': 4735,
    'Miroslovești': 4735,
    'Mogoşeşti': 4735,
    'Mogoşeşti-Siret': 4735,
    'Moşna': 4735,
    'Moţca': 4735,
    'Movileni': 4735,
    'Muncelu de Sus': 4735,
    'Municipiul Iaşi': 4735,
    'Municipiul Paşcani': 4735,
    'Munteni': 4735,
    'Oraş Hârlãu': 4735,
    'Oraş Podu Iloaiei': 4735,
    'Oraş Târgu Frumos': 4735,
    'Osoi': 4735,
    'Oţeleni': 4735,
    'Pârcovaci': 4735,
    'Paşcani': 4735,
    'Păușești': 4735,
    'Perieni': 4735,
    'Pietrosu': 4735,
    'Plugari': 4735,
    'Podolenii de Sus': 4735,
    'Podu Iloaiei': 4735,
    'Poiana': 4735,
    'Poiana Mărului': 4735,
    'Poienile': 4735,
    'Popeşti': 4735,
    'Popricani': 4735,
    'Prisăcani': 4735,
    'Probota': 4735,
    'Răchiteni': 4735,
    'Răducăneni': 4735,
    'Războieni': 4735,
    'Rediu': 4735,
    'Româneşti': 4735,
    'Roșcani': 4735,
    'Ruginoasa': 4735,
    'Satu Nou': 4735,
    'Scânteia': 4735,
    'Scheia': 4735,
    'Schitu-Duca': 4735,
    'Scobinţi': 4735,
    'Sineşti': 4735,
    'Şipote': 4735,
    'Sireţel': 4735,
    'Slobozia': 4735,
    'Soci': 4735,
    'Sodomeni': 4735,
    'Sticlăria': 4735,
    'Stolniceni-Prăjescu': 4735,
    'Stornești': 4735,
    'Strunga': 4735,
    'Suhuleț': 4735,
    'Tabăra': 4735,
    'Tansa': 4735,
    'Târgu Frumos': 4735,
    'Tătăruşi': 4735,
    'Ţibana': 4735,
    'Ţibăneşti': 4735,
    'Ţigănaşi': 4735,
    'Todireşti': 4735,
    'Tomeşti': 4735,
    'Topile': 4735,
    'Totoești': 4735,
    'Trifeşti': 4735,
    'Ţuţora': 4735,
    'Uda': 4735,
    'Valea Lupului': 4735,
    'Valea Seacă': 4735,
    'Vânători': 4735,
    'Verșeni': 4735,
    'Victoria': 4735,
    'Vlădeni': 4735,
    'Voineşti': 4735,
    'Zagavia': 4735,
    'Zmeu': 4735,
    '1 Decembrie': 4725,
    'Afumaţi': 4725,
    'Alunișu': 4725,
    'Bălăceanca': 4725,
    'Baloteşti': 4725,
    'Berceni': 4725,
    'Bragadiru': 4725,
    'Brăneşti': 4725,
    'Buciumeni': 4725,
    'Buftea': 4725,
    'Buriaș': 4725,
    'Căciulați': 4725,
    'Căldăraru': 4725,
    'Cățelu': 4725,
    'Cernica': 4725,
    'Chiajna': 4725,
    'Chitila': 4725,
    'Ciofliceni': 4725,
    'Ciolpani': 4725,
    'Ciorogârla': 4725,
    'Clinceni': 4725,
    'Comuna 1 Decembrie': 4725,
    'Comuna Afumaţi': 4725,
    'Comuna Baloteşti': 4725,
    'Comuna Berceni': 4725,
    'Comuna Brăneşti': 4725,
    'Comuna Cernica': 4725,
    'Comuna Chiajna': 4725,
    'Comuna Ciolpani': 4725,
    'Comuna Ciorogârla': 4725,
    'Comuna Clinceni': 4725,
    'Comuna Copăceni': 4725,
    'Comuna Corbeanca': 4725,
    'Comuna Cornetu': 4725,
    'Comuna Dărăşti-Ilfov': 4725,
    'Comuna Dascălu Creaţa': 4725,
    'Comuna Domneşti': 4725,
    'Comuna Dragomireşti-Vale': 4725,
    'Comuna Fundeni-Dobroeşti': 4725,
    'Comuna Găneasa': 4725,
    'Comuna Glina': 4725,
    'Comuna Grădiştea': 4725,
    'Comuna Gruiu': 4725,
    'Comuna Jilava': 4725,
    'Comuna Moara Vlãsiei': 4725,
    'Comuna Mogoşoaia': 4725,
    'Comuna Nuci': 4725,
    'Comuna Periş': 4725,
    'Comuna Petrăchioaia': 4725,
    'Comuna Snagov': 4725,
    'Comuna Ştefãneştii De Jos': 4725,
    'Comuna Tunari': 4725,
    'Comuna Vidra': 4725,
    'Copăceni': 4725,
    'Corbeanca': 4725,
    'Cornetu': 4725,
    'Crețești': 4725,
    'Dărăşti-Ilfov': 4725,
    'Dârvari': 4725,
    'Dascălu': 4725,
    'Dobroeşti': 4725,
    'Domnești': 4725,
    'Domneşti-Sârbi': 4725,
    'Dragomirești-Deal': 4725,
    'Dragomireşti-Vale': 4725,
    'Fundeni': 4725,
    'Găneasa': 4725,
    'Ghermănești': 4725,
    'Glina': 4725,
    'Grădiştea': 4725,
    'Gruiu': 4725,
    'Islaz': 4725,
    'Jilava': 4725,
    'Lipia': 4725,
    'Măgurele': 4725,
    'Merii Petchii': 4725,
    'Moara Vlăsiei': 4725,
    'Mogoşoaia': 4725,
    'Nuci': 4725,
    'Olteni': 4725,
    'Oraş Bragadiru': 4725,
    'Oraş Buftea': 4725,
    'Oraş Chitila': 4725,
    'Oraş Mãgurele': 4725,
    'Oraş Otopeni': 4725,
    'Oraş Pantelimon': 4725,
    'Oraş Popeşti Leordeni': 4725,
    'Otopeni': 4725,
    'Pantelimon': 4725,
    'Periş': 4725,
    'Petrăchioaia': 4725,
    'Piteasca': 4725,
    'Popeşti-Leordeni': 4725,
    'Roșu': 4725,
    'Rudeni': 4725,
    'Siliștea Snagovului': 4725,
    'Sintești': 4725,
    'Snagov': 4725,
    'Ştefăneştii de Jos': 4725,
    'Tamași': 4725,
    'Tânganu': 4725,
    'Tunari': 4725,
    'Vârteju': 4725,
    'Vidra': 4725,
    'Voluntari': 4725,
    'Voluntari City': 4725,
    'Ardusat': 4760,
    'Arduzel': 4760,
    'Arieșu de Câmp': 4760,
    'Arieșu de Pădure': 4760,
    'Ariniş': 4760,
    'Aspra': 4760,
    'Asuaju de Jos': 4760,
    'Asuaju de Sus': 4760,
    'Baba': 4760,
    'Baia Mare': 4760,
    'Baia Sprie': 4760,
    'Băile Borșa': 4760,
    'Băița': 4760,
    'Băiţa de sub Codru': 4760,
    'Băiuţ': 4760,
    'Bârgău': 4760,
    'Bârsana': 4760,
    'Băseşti': 4760,
    'Berbești': 4760,
    'Berchez': 4760,
    'Berința': 4760,
    'Bicaz': 4760,
    'Bistra': 4760,
    'Blidari': 4760,
    'Bocicoel': 4760,
    'Bocicoiu Mare': 4760,
    'Bogdan Vodă': 4760,
    'Boiereni': 4760,
    'Boiu Mare': 4760,
    'Bontăieni': 4760,
    'Borcut': 4760,
    'Borşa': 4760,
    'Botiza': 4760,
    'Bozânta Mare': 4760,
    'Bozânta Mică': 4760,
    'Breb': 4760,
    'Brebeni': 4760,
    'Buciumi': 4760,
    'Budeşti': 4760,
    'Bușag': 4760,
    'Buteasa': 4760,
    'Buzești': 4760,
    'Călineşti': 4760,
    'Câmpulung la Tisa': 4760,
    'Cărbunari': 4760,
    'Cărpiniș': 4760,
    'Cătălina': 4760,
    'Cavnic': 4760,
    'Cerneşti': 4760,
    'Cetățele': 4760,
    'Chechiș': 4760,
    'Chelința': 4760,
    'Chiuzbaia': 4760,
    'Cicârlău': 4760,
    'Ciocotiș': 4760,
    'Ciolt': 4760,
    'Ciuta': 4760,
    'Coaș': 4760,
    'Codru Butesii': 4760,
    'Coltău': 4760,
    'Colțirea': 4760,
    'Comuna Ardusat': 4760,
    'Comuna Ariniş': 4760,
    'Comuna Asuaju de Sus': 4760,
    'Comuna Băiţa de sub Codru': 4760,
    'Comuna Băiuţ': 4760,
    'Comuna Bârsana': 4760,
    'Comuna Băseşti': 4760,
    'Comuna Bicaz': 4760,
    'Comuna Bistra': 4760,
    'Comuna Bocicoiu Mare': 4760,
    'Comuna Bogdan Vodă': 4760,
    'Comuna Boiu Mare': 4760,
    'Comuna Botiza': 4760,
    'Comuna Budeşti': 4760,
    'Comuna Călineşti': 4760,
    'Comuna Câmpulung la Tisa': 4760,
    'Comuna Cerneşti': 4760,
    'Comuna Cicârlău': 4760,
    'Comuna Coaş': 4760,
    'Comuna Coltãu': 4760,
    'Comuna Copalnic Mănăştur': 4760,
    'Comuna Coroieni': 4760,
    'Comuna Cupşeni': 4760,
    'Comuna Deseşti': 4760,
    'Comuna Dumbrăviţa': 4760,
    'Comuna Fărcaşa': 4760,
    'Comuna Gârdani': 4760,
    'Comuna Giuleşti': 4760,
    'Comuna Groşi': 4760,
    'Comuna Groşii Ţibleşului': 4760,
    'Comuna Ieud': 4760,
    'Comuna Lăpuş': 4760,
    'Comuna Leordina': 4760,
    'Comuna Mireşu Mare': 4760,
    'Comuna Moisei': 4760,
    'Comuna Oarţa De Jos': 4760,
    'Comuna Ocna Şugatag': 4760,
    'Comuna Onceşti': 4760,
    'Comuna Petrova': 4760,
    'Comuna Poienile de sub Munte': 4760,
    'Comuna Poienile Izei': 4760,
    'Comuna Recea': 4760,
    'Comuna Remetea Chioarului': 4760,
    'Comuna Remeţi': 4760,
    'Comuna Repedea': 4760,
    'Comuna Rona de Jos': 4760,
    'Comuna Rona de Sus': 4760,
    'Comuna Rozavlea': 4760,
    'Comuna Ruşcova': 4760,
    'Comuna Săcălăşeni': 4760,
    'Comuna Săcel': 4760,
    'Comuna Sălsig': 4760,
    'Comuna Sãpânţa': 4760,
    'Comuna Sărăsău': 4760,
    'Comuna Satulung': 4760,
    'Comuna Şieu': 4760,
    'Comuna Şişeşti': 4760,
    'Comuna Strâmtura': 4760,
    'Comuna Suciu de Sus': 4760,
    'Comuna Vadu Izei': 4760,
    'Comuna Valea Chioarului': 4760,
    'Comuna Vima Micã': 4760,
    'Comuna Vişeu de Jos': 4760,
    'Copalnic': 4760,
    'Copalnic Mănăştur': 4760,
    'Cornești': 4760,
    'Corni': 4760,
    'Coroieni': 4760,
    'Coruia': 4760,
    'Costeni': 4760,
    'Coștiui': 4760,
    'Crăciunești': 4760,
    'Crasna Vișeului': 4760,
    'Cufoaia': 4760,
    'Culcea': 4760,
    'Cupşeni': 4760,
    'Curtuiușu Mare': 4760,
    'Curtuiușu Mic': 4760,
    'Dămăcușeni': 4760,
    'Dănești': 4760,
    'Dăneștii Chioarului': 4760,
    'Dealu Corbului': 4760,
    'Dealu Mare': 4760,
    'Deseşti': 4760,
    'Dobricu Lăpușului': 4760,
    'Drăghia': 4760,
    'Dragomireşti': 4760,
    'Dumbrava': 4760,
    'Dumbrăviţa': 4760,
    'Durușa': 4760,
    'Fânațe': 4760,
    'Fântânele': 4760,
    'Fărcaşa': 4760,
    'Făurești': 4760,
    'Ferești': 4760,
    'Fericea': 4760,
    'Fersig': 4760,
    'Finteușu Mare': 4760,
    'Finteușu Mic': 4760,
    'Firiza': 4760,
    'Frâncenii Boiului': 4760,
    'Gârdani': 4760,
    'Giuleşti': 4760,
    'Glod': 4760,
    'Groape': 4760,
    'Groşi': 4760,
    'Groșii Țibleșului': 4760,
    'Handalu Ilbei': 4760,
    'Hărnicești': 4760,
    'Hideaga': 4760,
    'Hoteni': 4760,
    'Hovrila': 4760,
    'Iadăra': 4760,
    'Iapa': 4760,
    'Ieud': 4760,
    'Ilba': 4760,
    'Inău': 4760,
    'Întrerâuri': 4760,
    'Izvoarele': 4760,
    'Jugăstreni': 4760,
    'Lăpuş': 4760,
    'Lăpușel': 4760,
    'Larga': 4760,
    'Lăschia': 4760,
    'Lazu Baciului': 4760,
    'Leordina': 4760,
    'Libotin': 4760,
    'Lucăcești': 4760,
    'Lunca la Tisa': 4760,
    'Măgureni': 4760,
    'Mănăstirea': 4760,
    'Mânău': 4760,
    'Mara': 4760,
    'Merișor': 4760,
    'Mesteacăn': 4760,
    'Mireşu Mare': 4760,
    'Mocira': 4760,
    'Mogoșești': 4760,
    'Moisei': 4760,
    'Nănești': 4760,
    'Negreia': 4760,
    'Nistru': 4760,
    'Oarţa de Jos': 4760,
    'Oarța de Sus': 4760,
    'Ocna Şugatag': 4760,
    'Ocoliș': 4760,
    'Odești': 4760,
    'Oncești': 4760,
    'Orțița': 4760,
    'Peteritea': 4760,
    'Petrova': 4760,
    'Piatra': 4760,
    'Plopiș': 4760,
    'Poiana Botizii': 4760,
    'Poienile de sub Munte': 4760,
    'Poienile Izei': 4760,
    'Posta': 4760,
    'Preluca Nouă': 4760,
    'Preluca Veche': 4760,
    'Pribilești': 4760,
    'Prislop': 4760,
    'Răzoare': 4760,
    'Recea': 4760,
    'Remecioara': 4760,
    'Remetea Chioarului': 4760,
    'Remeţi': 4760,
    'Remeți pe Someș': 4760,
    'Repedea': 4760,
    'Rodina': 4760,
    'Rogoz': 4760,
    'Rohia': 4760,
    'Românești': 4760,
    'Rona de Jos': 4760,
    'Rona de Sus': 4760,
    'Rozavlea': 4760,
    'Rus': 4760,
    'Ruscova': 4760,
    'Rușor': 4760,
    'Săbișa': 4760,
    'Săcălășeni': 4760,
    'Săcel': 4760,
    'Săliște': 4760,
    'Săliştea de Sus': 4760,
    'Sălnița': 4760,
    'Sălsig': 4760,
    'Sâlța': 4760,
    'Săpânţa': 4760,
    'Sarasău': 4760,
    'Sârbi': 4760,
    'Săsar': 4760,
    'Sat-Șugatag': 4760,
    'Satu Nou de Jos': 4760,
    'Satu Nou de Sus': 4760,
    'Satulung': 4760,
    'Seini': 4760,
    'Șieu': 4760,
    'Sighetu Marmaţiei': 4760,
    'Șindrești': 4760,
    'Șisești': 4760,
    'Slătioara': 4760,
    'Şomcuta Mare': 4760,
    'Someș-Uileac': 4760,
    'Stejera': 4760,
    'Stoiceni': 4760,
    'Strâmbu-Băiuț': 4760,
    'Strâmtura': 4760,
    'Stremț': 4760,
    'Suciu de Jos': 4760,
    'Suciu de Sus': 4760,
    'Șugău': 4760,
    'Șurdești': 4760,
    'Tămaia': 4760,
    'Tămășești': 4760,
    'Târgu Lăpuş': 4760,
    'Tăuții de Sus': 4760,
    'Tăuţii-Măgherăuş': 4760,
    'Teceu Mic': 4760,
    'Țicău': 4760,
    'Tisa': 4760,
    'tohat': 4760,
    'Trestia': 4760,
    'Tulghieș': 4760,
    'Ulmeni': 4760,
    'Ulmoasa': 4760,
    'Unguraș': 4760,
    'Ungureni': 4760,
    'Urmeniș': 4760,
    'Vad': 4760,
    'Vadu Izei': 4760,
    'Valea Chioarului': 4760,
    'Valea Cufundoasă': 4760,
    'Valea Hotarului': 4760,
    'Valea Neagră': 4760,
    'Valea Stejarului': 4760,
    'Valea Vișeului': 4760,
    'Văleni': 4760,
    'Vălenii Lăpușului': 4760,
    'Vălenii Șomcutei': 4760,
    'Vărai': 4760,
    'Vicea': 4760,
    'Viile Apei': 4760,
    'Vima Mare': 4760,
    'Vima Mică': 4760,
    'Vişeu de Jos': 4760,
    'Vișeu de Mijloc': 4760,
    'Vişeu De Sus': 4760,
    'Bâcleș': 4751,
    'Baia de Aramă': 4751,
    'Bala': 4751,
    'Bălăciţa': 4751,
    'Balta': 4751,
    'Balta Verde': 4751,
    'Bâlvăneşti': 4751,
    'Bistrița': 4751,
    'Braniștea': 4751,
    'Brezniţa Ocol': 4751,
    'Brezniţa-Motru': 4751,
    'Broşteni': 4751,
    'Burila Mare': 4751,
    'Butoieşti': 4751,
    'Căzăneşti': 4751,
    'Cerneți': 4751,
    'Ciochiuța': 4751,
    'Cioroboreni': 4751,
    'Cireşu': 4751,
    'Comanda': 4751,
    'Comuna Bâcleş': 4751,
    'Comuna Bala': 4751,
    'Comuna Bălăciţa': 4751,
    'Comuna Balta': 4751,
    'Comuna Bâlvăneşti': 4751,
    'Comuna Braniştea': 4751,
    'Comuna Brezniţa Ocol': 4751,
    'Comuna Brezniţa-Motru': 4751,
    'Comuna Broşteni': 4751,
    'Comuna Burila Mare': 4751,
    'Comuna Butoieşti': 4751,
    'Comuna Căzăneşti': 4751,
    'Comuna Cireşu': 4751,
    'Comuna Corcova': 4751,
    'Comuna Corlăţel': 4751,
    'Comuna Cujmir': 4751,
    'Comuna Dârvari': 4751,
    'Comuna Devesel': 4751,
    'Comuna Dubova': 4751,
    'Comuna Dumbrava': 4751,
    'Comuna Eşelniţa': 4751,
    'Comuna Floreşti': 4751,
    'Comuna Gârla Mare': 4751,
    'Comuna Godeanu': 4751,
    'Comuna Gogoşu': 4751,
    'Comuna Greci': 4751,
    'Comuna Grozeşti': 4751,
    'Comuna Gruia': 4751,
    'Comuna Hinova': 4751,
    'Comuna Husnicioara': 4751,
    'Comuna Ilovăt': 4751,
    'Comuna Iloviţa': 4751,
    'Comuna Isverna': 4751,
    'Comuna Izvoru Bârzii': 4751,
    'Comuna Jiana': 4751,
    'Comuna Livezile': 4751,
    'Comuna Malovãţ': 4751,
    'Comuna Obârşia De Câmp': 4751,
    'Comuna Obârşia-Cloşani': 4751,
    'Comuna Oprişor': 4751,
    'Comuna Padina': 4751,
    'Comuna Pătulele': 4751,
    'Comuna Podeni': 4751,
    'Comuna Ponoarele': 4751,
    'Comuna Poroina Mare': 4751,
    'Comuna Pristol': 4751,
    'Comuna Prunişor': 4751,
    'Comuna Punghina': 4751,
    'Comuna Rogova': 4751,
    'Comuna Salcia': 4751,
    'Comuna Simian': 4751,
    'Comuna Siseşti': 4751,
    'Comuna Sovarna': 4751,
    'Comuna Stângăceaua': 4751,
    'Comuna Sviniţa': 4751,
    'Comuna Tâmna': 4751,
    'Comuna Vânători': 4751,
    'Comuna Vânjuleţ': 4751,
    'Comuna Vlădaia': 4751,
    'Comuna Voloiac': 4751,
    'Comuna Vrata': 4751,
    'Corcova': 4751,
    'Corlăţel': 4751,
    'Cujmir': 4751,
    'Dănceu': 4751,
    'Dârvari': 4751,
    'Devesel': 4751,
    'Drobeta-Turnu Severin': 4751,
    'Dubova': 4751,
    'Dudașu': 4751,
    'Dumbrava de Sus': 4751,
    'Eşelniţa': 4751,
    'Floreşti': 4751,
    'Gârla-Mare': 4751,
    'Gemeni': 4751,
    'Godeanu': 4751,
    'Gogoşu': 4751,
    'Greci': 4751,
    'Grozeşti': 4751,
    'Gruia': 4751,
    'Gura Văii': 4751,
    'Gvardinița': 4751,
    'Halânga': 4751,
    'Hinova': 4751,
    'Husnicioara': 4751,
    'Ilovăț': 4751,
    'Iloviţa': 4751,
    'Isverna': 4751,
    'Izimșa': 4751,
    'Izvoarele': 4751,
    'Izvoru Bârzii': 4751,
    'Jiana': 4751,
    'Jiana Veche': 4751,
    'Jidoștița': 4751,
    'Jirov': 4751,
    'Livezile': 4751,
    'Malovăţ': 4751,
    'Municipiul Drobeta-Turnu Severin': 4751,
    'Municipiul Orşova': 4751,
    'Nicolae Bălcescu': 4751,
    'Obârșia de Câmp': 4751,
    'Obârşia-Cloşani': 4751,
    'Oprişor': 4751,
    'Oraş Baia De Aramã': 4751,
    'Oraş Strehaia': 4751,
    'Oraş Vânju Mare': 4751,
    'Orevița Mare': 4751,
    'Orşova': 4751,
    'Ostrovu Mare': 4751,
    'Padina Mică': 4751,
    'Pătulele': 4751,
    'Podeni': 4751,
    'Ponoarele': 4751,
    'Poroina Mare': 4751,
    'Pristol': 4751,
    'Prunişor': 4751,
    'Punghina': 4751,
    'Recea': 4751,
    'Rogova': 4751,
    'Roșiori': 4751,
    'Salcia': 4751,
    'Şimian': 4751,
    'Şişeşti': 4751,
    'Şovarna': 4751,
    'Stângăceaua': 4751,
    'Strehaia': 4751,
    'Sviniţa': 4751,
    'Tâmna': 4751,
    'Vânători': 4751,
    'Vânju-Mare': 4751,
    'Vânjuleţ': 4751,
    'Vlădaia': 4751,
    'Voloiac': 4751,
    'Vrata': 4751,
    'Abuș': 4915,
    'Acățari': 4915,
    'Adămuș': 4915,
    'Adrian': 4915,
    'Adrianu Mare': 4915,
    'Adrianu Mic': 4915,
    'Agrișteu': 4915,
    'Albești': 4915,
    'Aluniș': 4915,
    'Andreneasa': 4915,
    'Angofa': 4915,
    'Apalina': 4915,
    'Apold': 4915,
    'Archita': 4915,
    'Arșița': 4915,
    'Ațintiș': 4915,
    'Aurel Vlaicu': 4915,
    'Avrămești': 4915,
    'Băgaciu': 4915,
    'Bahnea': 4915,
    'Băița': 4915,
    'Băla': 4915,
    'Bălăușeri': 4915,
    'Balda': 4915,
    'Band': 4915,
    'Bâra': 4915,
    'Bărboși': 4915,
    'Bărdești': 4915,
    'Bârlibaș': 4915,
    'Bârlibășoaia': 4915,
    'Bârza': 4915,
    'Batoș': 4915,
    'Bedeni': 4915,
    'Beica de Jos': 4915,
    'Beica de Sus': 4915,
    'Bereni': 4915,
    'Berghia': 4915,
    'Bernadea': 4915,
    'Beu': 4915,
    'Bezid': 4915,
    'Bicașu': 4915,
    'Bichiș': 4915,
    'Bistra Mureșului': 4915,
    'Blidireasa': 4915,
    'Bobohalma': 4915,
    'Bogata': 4915,
    'Boiu': 4915,
    'Bolintineni': 4915,
    'Bologaia': 4915,
    'Bord': 4915,
    'Bordoșiu': 4915,
    'Borzia': 4915,
    'Botei': 4915,
    'Botez': 4915,
    'Botorca': 4915,
    'Bozed': 4915,
    'Bozeni': 4915,
    'Brădețelu': 4915,
    'Brâncovenești': 4915,
    'Breaza': 4915,
    'Budiu Mic': 4915,
    'Bujor': 4915,
    'Bujor-Hodaie': 4915,
    'Căciulata': 4915,
    'Căcuciu': 4915,
    'Călimănești': 4915,
    'Călugăreni': 4915,
    'Călușeri': 4915,
    'Câmpenița': 4915,
    'Câmpu Cetății': 4915,
    'Cându': 4915,
    'Căpâlna de Sus': 4915,
    'Căpeți': 4915,
    'Căpușu de Câmpie': 4915,
    'Cașva': 4915,
    'Cecălaca': 4915,
    'Ceie': 4915,
    'Cerghid': 4915,
    'Cerghizel': 4915,
    'Ceuaș': 4915,
    'Ceuașu de Câmpie': 4915,
    'Chendu': 4915,
    'Chețani': 4915,
    'Chibed': 4915,
    'Chiheru de Jos': 4915,
    'Chiheru de Sus': 4915,
    'Chinari': 4915,
    'Chinciuș': 4915,
    'Chirileu': 4915,
    'Chisălița': 4915,
    'Ciba': 4915,
    'Cibu': 4915,
    'Cinta': 4915,
    'Cioarga': 4915,
    'Ciobotani': 4915,
    'Cipaieni': 4915,
    'Cipău': 4915,
    'Ciretea': 4915,
    'Cirhagău': 4915,
    'Ciulea': 4915,
    'Ciurgău': 4915,
    'Cloașterf': 4915,
    'Coasta Grindului': 4915,
    'Coasta Mare': 4915,
    'Comori': 4915,
    'Comuna Coroisânmartin': 4915,
    'Comuna Ibănești Pădure': 4915,
    'Comuna Rușii Munți': 4915,
    'Corbești': 4915,
    'Cordoș': 4915,
    'Cornești': 4915,
    'Coroi': 4915,
    'Corunca': 4915,
    'Cotorinau': 4915,
    'Cotuș': 4915,
    'Cozma': 4915,
    'Crăciunești': 4915,
    'Crăiești': 4915,
    'Criș': 4915,
    'Cristești': 4915,
    'Cucerdea': 4915,
    'Cuci': 4915,
    'Cuieșd': 4915,
    'Culpiu': 4915,
    'Cund': 4915,
    'Curețe': 4915,
    'Curteni': 4915,
    'Cuștelnic': 4915,
    'Daia': 4915,
    'Dalu': 4915,
    'Dâmbău': 4915,
    'Dâmbu': 4915,
    'Dămieni': 4915,
    'Daneș': 4915,
    'Dătășeni': 4915,
    'Deag': 4915,
    'Deaj': 4915,
    'Deda': 4915,
    'Dedrad': 4915,
    'Deleni': 4915,
    'Dileu Nou': 4915,
    'Dileu Vechi': 4915,
    'Dobra': 4915,
    'Drăculea Bandului': 4915,
    'Drojdii': 4915,
    'Dubiștea de Pădure': 4915,
    'Dulcea': 4915,
    'Dumbrava': 4915,
    'Dumbrăvioara': 4915,
    'Dumitreni': 4915,
    'Dumitreștii': 4915,
    'După Deal': 4915,
    'Ercea': 4915,
    'Eremieni': 4915,
    'Eremitu': 4915,
    'Ernei': 4915,
    'Fânațe': 4915,
    'Fânațele Mădărașului': 4915,
    'Fânațele Socolului': 4915,
    'Fântâna Babii': 4915,
    'Fântânele': 4915,
    'Fărăgău': 4915,
    'Feleag': 4915,
    'Filea': 4915,
    'Filitelnic': 4915,
    'Filpișu Mare': 4915,
    'Filpișu Mic': 4915,
    'Fițcău': 4915,
    'Foi': 4915,
    'Frunzeni': 4915,
    'Fundătura': 4915,
    'Fundoaia': 4915,
    'Găiești': 4915,
    'Gălăoaia': 4915,
    'Gălățeni': 4915,
    'Gălești': 4915,
    'Gâmbuț': 4915,
    'Gănești': 4915,
    'Gaura Sângerului': 4915,
    'Gheja': 4915,
    'Gheorghe Doja': 4915,
    'Ghidașteu': 4915,
    'Ghindari': 4915,
    'Ghinești': 4915,
    'Giuluș': 4915,
    'Giurgiș': 4915,
    'Glăjărie': 4915,
    'Glodeni': 4915,
    'Gogan': 4915,
    'Goreni': 4915,
    'Gornești': 4915,
    'Grădini': 4915,
    'Grâușorul': 4915,
    'Grebenișu de Câmpie': 4915,
    'Grindeni': 4915,
    'Groapa Rădăii': 4915,
    'Gruișor': 4915,
    'Gurghiu': 4915,
    'Habic': 4915,
    'Hădăreni': 4915,
    'Hagău': 4915,
    'Hărănglab': 4915,
    'Hărțău': 4915,
    'Herepea': 4915,
    'Herghelia': 4915,
    'Hetiur': 4915,
    'Hodac': 4915,
    'Hodaia': 4915,
    'Hodoșa': 4915,
    'Iara de Mureș': 4915,
    'Ibănești-Pădure': 4915,
    'Icland': 4915,
    'Iclandu Mare': 4915,
    'Iclănzel': 4915,
    'Ideciu de Jos': 4915,
    'Idicel-Pădure': 4915,
    'Idiciu': 4915,
    'Idrifaia': 4915,
    'Iernut': 4915,
    'Iernuțeni': 4915,
    'Ihod': 4915,
    'Ilieni': 4915,
    'Ilieși': 4915,
    'Ilioara': 4915,
    'Iod': 4915,
    'Isla': 4915,
    'Iștan-Tău': 4915,
    'Iștihaza': 4915,
    'Ivănești': 4915,
    'Jabenița': 4915,
    'Jacodu': 4915,
    'Jacu': 4915,
    'Lăpușna': 4915,
    'Larga': 4915,
    'Lăscud': 4915,
    'Laslău Mare': 4915,
    'Laslău Mic': 4915,
    'Lăureni': 4915,
    'Lechincioara': 4915,
    'Lechința': 4915,
    'Lefaia': 4915,
    'Leniș': 4915,
    'Leordeni': 4915,
    'Leorința': 4915,
    'Leorința-Șăulia': 4915,
    'Lepindea': 4915,
    'Linț': 4915,
    'Livezeni': 4915,
    'Logig': 4915,
    'Loțu': 4915,
    'Luduș': 4915,
    'Luieriu': 4915,
    'Lunca': 4915,
    'Lunca Bradului': 4915,
    'Lunca Mureșului': 4915,
    'Măcicășești': 4915,
    'Mădăraș': 4915,
    'Mădărășeni': 4915,
    'Măgherani': 4915,
    'Măgheruș': 4915,
    'Maia': 4915,
    'Maiad': 4915,
    'Maiorești': 4915,
    'Mălăești': 4915,
    'Maldaoci': 4915,
    'Malea': 4915,
    'Mărășești': 4915,
    'Mărculeni': 4915,
    'Mătrici': 4915,
    'Merișor': 4915,
    'Merișoru': 4915,
    'Meștera': 4915,
    'Mica': 4915,
    'Miercurea Nirajului': 4915,
    'Mihai Viteazu': 4915,
    'Miheșu de Câmpie': 4915,
    'Milășel': 4915,
    'Mirigioaia': 4915,
    'Mitrești': 4915,
    'Moara de Jos': 4915,
    'Mogoaia': 4915,
    'Moișa': 4915,
    'Morăreni': 4915,
    'Morești': 4915,
    'Moruț': 4915,
    'Moșuni': 4915,
    'Mura Mare': 4915,
    'Mura Mică': 4915,
    'Mureni': 4915,
    'Mureșeni': 4915,
    'Murgești': 4915,
    'Nadășa': 4915,
    'Nadeș': 4915,
    'Nandra': 4915,
    'Nazna': 4915,
    'Neagra': 4915,
    'Neaua': 4915,
    'Negrenii de Câmpie': 4915,
    'Nicolești': 4915,
    'Nima Milășelului': 4915,
    'Nima Râciului': 4915,
    'Oarba de Mureș': 4915,
    'Obârșie': 4915,
    'Odrihei': 4915,
    'Ogra': 4915,
    'Onuca': 4915,
    'Ormeniș': 4915,
    'Oroiu': 4915,
    'Orosia': 4915,
    'Orșova': 4915,
    'Ozd': 4915,
    'Păcureni': 4915,
    'Pădurea': 4915,
    'Pădureni': 4915,
    'Păingeni': 4915,
    'Pănet': 4915,
    'Papiu Ilarian': 4915,
    'Pârâu Crucii': 4915,
    'Pârâu Mare': 4915,
    'Păsăreni': 4915,
    'Păucișoara': 4915,
    'Păuloaia': 4915,
    'Periș': 4915,
    'Petea': 4915,
    'Petelea': 4915,
    'Petrilaca': 4915,
    'Petrilaca de Mureș': 4915,
    'Pietriș': 4915,
    'Pipea': 4915,
    'Poarta': 4915,
    'Poduri': 4915,
    'Pogăceaua': 4915,
    'Poienița': 4915,
    'Porumbac': 4915,
    'Porumbeni': 4915,
    'Pripoare': 4915,
    'Pusta': 4915,
    'Racameț': 4915,
    'Râciu': 4915,
    'Ranta': 4915,
    'Râpa de Jos': 4915,
    'Răstolița': 4915,
    'Răzoare': 4915,
    'Recea': 4915,
    'Reghin': 4915,
    'Remetea': 4915,
    'Rigmani': 4915,
    'Rora': 4915,
    'Roșiori': 4915,
    'Roteni': 4915,
    'Roua': 4915,
    'Rușii-Munți': 4915,
    'Săbed': 4915,
    'Săcădat': 4915,
    'Săcalu de Pădure': 4915,
    'Săcăreni': 4915,
    'Șaeș': 4915,
    'Sălard': 4915,
    'Sălașuri': 4915,
    'Sălcud': 4915,
    'Sâmbriaș': 4915,
    'Sâncraiu de Mureș': 4915,
    'Șandru': 4915,
    'Sângeorgiu de Câmpie': 4915,
    'Sângeorgiu de Mureș': 4915,
    'Sângeorgiu de Pădure': 4915,
    'Sânger': 4915,
    'Sângeru de Pădure': 4915,
    'Sâniacob': 4915,
    'Sânișor': 4915,
    'Sânmărghita': 4915,
    'Sânmărtinu de Câmpie': 4915,
    'Sânmihai de Pădure': 4915,
    'Sânpaul': 4915,
    'Sânpetru de Câmpie': 4915,
    'Sânsimion': 4915,
    'Sântana de Mureș': 4915,
    'Sântioana': 4915,
    'Sântioana de Mureș': 4915,
    'Sântu': 4915,
    'Sânvăsii': 4915,
    'Șapartoc': 4915,
    'Sărățeni': 4915,
    'Șardu Nirajului': 4915,
    'Sărmășel': 4915,
    'Sărmașu': 4915,
    'Saschiz': 4915,
    'Satu Nou': 4915,
    'Șăulia': 4915,
    'Șăulița': 4915,
    'Șăușa': 4915,
    'Scurta': 4915,
    'Sebeș': 4915,
    'Seleuș': 4915,
    'Senereuș': 4915,
    'Șerbeni': 4915,
    'Seuca': 4915,
    'Șeulia de Mureș': 4915,
    'Sfântu Gheorghe': 4915,
    'Sicele': 4915,
    'Sighișoara': 4915,
    'Șilea Nirajului': 4915,
    'Șincai': 4915,
    'Șincai-Fânațe': 4915,
    'Șoard': 4915,
    'Socolu de Câmpie': 4915,
    'Șoimuș': 4915,
    'Solocma': 4915,
    'Solovăstru': 4915,
    'Șomoștelnic': 4915,
    'Șoromiclea': 4915,
    'Sovata': 4915,
    'Stânceni': 4915,
    'Ștefanca': 4915,
    'Ștefăneaca': 4915,
    'Stejărenii': 4915,
    'Stejeriș': 4915,
    'Sub Pădure': 4915,
    'Suplac': 4915,
    'Suseni': 4915,
    'Suveica': 4915,
    'Tăblășeni': 4915,
    'Tâmpa': 4915,
    'Târgu Mureș': 4915,
    'Târnăveni': 4915,
    'Tău': 4915,
    'Tăureni': 4915,
    'Teleac': 4915,
    'Țigmandru': 4915,
    'Țiptelnic': 4915,
    'Tireu': 4915,
    'Tirimia': 4915,
    'Tirimioara': 4915,
    'Tisieu': 4915,
    'Titiana': 4915,
    'Toaca': 4915,
    'Tofalău': 4915,
    'Toldal': 4915,
    'Tonciu': 4915,
    'Țopa': 4915,
    'Torba': 4915,
    'Trei Sate': 4915,
    'Troița': 4915,
    'Tușinu': 4915,
    'Uila': 4915,
    'Ulieș': 4915,
    'Ungheni': 4915,
    'Uricea': 4915,
    'Urisiu de Jos': 4915,
    'Urisiu de Sus': 4915,
    'Ursoaia': 4915,
    'Vădaș': 4915,
    'Vadu': 4915,
    'Vaidacuta': 4915,
    'Vaideiu': 4915,
    'Valea': 4915,
    'Valea Albeștiului': 4915,
    'Valea Dăii': 4915,
    'Valea Frăției': 4915,
    'Valea Glodului': 4915,
    'Valea Iclandului': 4915,
    'Valea Izvoarelor': 4915,
    'Valea Largă': 4915,
    'Valea Mare': 4915,
    'Valea Pădurii': 4915,
    'Valea Rece': 4915,
    'Valea Sânmărtinului': 4915,
    'Valea Sânpetrului': 4915,
    'Valea Șapartocului': 4915,
    'Valea Sasului': 4915,
    'Valea Seacă': 4915,
    'Valea Șurii': 4915,
    'Valea Ulieșului': 4915,
    'Valea Ungurului': 4915,
    'Valea Urieșului': 4915,
    'Văleni': 4915,
    'Vălenii': 4915,
    'Vălenii de Mureș': 4915,
    'Vălișoara': 4915,
    'Vălureni': 4915,
    'Vânători': 4915,
    'Vărgata': 4915,
    'Vătava': 4915,
    'Venchi': 4915,
    'Veța': 4915,
    'Vețca': 4915,
    'Vidrasău': 4915,
    'Viforoasa': 4915,
    'Viilor': 4915,
    'Viișoara': 4915,
    'Vișinelu': 4915,
    'Voiniceni': 4915,
    'Voivodeni': 4915,
    'Vulcan': 4915,
    'Zagăr': 4915,
    'Zăpodea': 4915,
    'Zau de Câmpie': 4915,
    'Zimți': 4915,
    'Adjudeni': 4731,
    'Agapia': 4731,
    'Bahna': 4731,
    'Bălţăteşti': 4731,
    'Bălușești': 4731,
    'Bâra': 4731,
    'Bărcăneşti': 4731,
    'Bârgăuani': 4731,
    'Barticești': 4731,
    'Bicaz': 4731,
    'Bicaz-Chei': 4731,
    'Bicazu Ardelean': 4731,
    'Bistrița': 4731,
    'Boboiești': 4731,
    'Bodeşti': 4731,
    'Bodeștii de Jos': 4731,
    'Boghicea': 4731,
    'Borca': 4731,
    'Borleşti': 4731,
    'Botești': 4731,
    'Bozieni': 4731,
    'Brusturi': 4731,
    'Budești': 4731,
    'Buruienești': 4731,
    'Cândeşti': 4731,
    'Ceahlău': 4731,
    'Chilii': 4731,
    'Chintinici': 4731,
    'Comuna Agapia': 4731,
    'Comuna Alexandru Cel Bun': 4731,
    'Comuna Bahna': 4731,
    'Comuna Bălţăteşti': 4731,
    'Comuna Bâra': 4731,
    'Comuna Bârgãuani': 4731,
    'Comuna Bicaz Chei': 4731,
    'Comuna Bicazu Ardelean': 4731,
    'Comuna Bodeşti-Precista': 4731,
    'Comuna Boghicea': 4731,
    'Comuna Borca': 4731,
    'Comuna Borleşti': 4731,
    'Comuna Boteşti': 4731,
    'Comuna Bozieni': 4731,
    'Comuna Brusturi-Drăgăneşti': 4731,
    'Comuna Cândeşti': 4731,
    'Comuna Ceahlău': 4731,
    'Comuna Cordun': 4731,
    'Comuna Costişa': 4731,
    'Comuna Crăcăoani': 4731,
    'Comuna Dămuc': 4731,
    'Comuna Dobreni': 4731,
    'Comuna Dochia': 4731,
    'Comuna Doljeşti': 4731,
    'Comuna Drăgăneşti': 4731,
    'Comuna Dragomireşti': 4731,
    'Comuna Dulceşti': 4731,
    'Comuna Dumbrava Roşie': 4731,
    'Comuna Fărcaşa': 4731,
    'Comuna Fãurei': 4731,
    'Comuna Gâdinţi': 4731,
    'Comuna Gârcina': 4731,
    'Comuna Gherăeşti': 4731,
    'Comuna Ghindăoani': 4731,
    'Comuna Girov': 4731,
    'Comuna Grinţieş': 4731,
    'Comuna Grumăzeşti': 4731,
    'Comuna Hangu': 4731,
    'Comuna Horia': 4731,
    'Comuna Icuseşti': 4731,
    'Comuna Ion Creangă': 4731,
    'Comuna Mărgineni': 4731,
    'Comuna Moldoveni': 4731,
    'Comuna Negreşti': 4731,
    'Comuna Oniceni': 4731,
    'Comuna Pânceşti': 4731,
    'Comuna Pângăraţi': 4731,
    'Comuna Păstrăveni': 4731,
    'Comuna Petricani': 4731,
    'Comuna Piatra Şoimului': 4731,
    'Comuna Pipirig': 4731,
    'Comuna Podoleni': 4731,
    'Comuna Poiana Teiului': 4731,
    'Comuna Poienari': 4731,
    'Comuna Răuceşti': 4731,
    'Comuna Rãzboieni': 4731,
    'Comuna Rediu': 4731,
    'Comuna Români': 4731,
    'Comuna Ruginoasa': 4731,
    'Comuna Săbăoani': 4731,
    'Comuna Sagna': 4731,
    'Comuna Săvineşti': 4731,
    'Comuna Secuieni': 4731,
    'Comuna Stăniţa': 4731,
    'Comuna Ştefan cel Mare': 4731,
    'Comuna Tămăşeni': 4731,
    'Comuna Tarcău': 4731,
    'Comuna Taşca': 4731,
    'Comuna Tazlău': 4731,
    'Comuna Ţibucani': 4731,
    'Comuna Timişeşti': 4731,
    'Comuna Trifeşti': 4731,
    'Comuna Tupilaţi': 4731,
    'Comuna Urecheni': 4731,
    'Comuna Valea Ursului': 4731,
    'Comuna Văleni': 4731,
    'Comuna Vânători Neamţ': 4731,
    'Comuna Zăneşti': 4731,
    'Cordun': 4731,
    'Costişa': 4731,
    'Cotu Vameș': 4731,
    'Crăcăoani': 4731,
    'Cracăul Negru': 4731,
    'Cuejdiu': 4731,
    'Cut': 4731,
    'Dămuc': 4731,
    'Dobreni': 4731,
    'Dochia': 4731,
    'Dodeni': 4731,
    'Dolhești': 4731,
    'Doljeşti': 4731,
    'Drăgănești': 4731,
    'Dragomireşti': 4731,
    'Dulceşti': 4731,
    'Dumbrava': 4731,
    'Dumbrava Roşie': 4731,
    'Fărcaşa': 4731,
    'Făurei': 4731,
    'Filioara': 4731,
    'Gâdinţi': 4731,
    'Gârcina': 4731,
    'Gherăeşti': 4731,
    'Ghindăoani': 4731,
    'Girov': 4731,
    'Goșmani': 4731,
    'Grinţieş': 4731,
    'Grumăzeşti': 4731,
    'Hangu': 4731,
    'Hlăpești': 4731,
    'Hociungi': 4731,
    'Horia': 4731,
    'Humulești': 4731,
    'Icuseşti': 4731,
    'Ion Creangă': 4731,
    'Izvoare': 4731,
    'Luminiș': 4731,
    'Lunca': 4731,
    'Mărgineni': 4731,
    'Mastacăn': 4731,
    'Miron Costin': 4731,
    'Moldoveni': 4731,
    'Municipiul Piatra-Neamţ': 4731,
    'Municipiul Roman': 4731,
    'Negrești': 4731,
    'Nemțișor': 4731,
    'Nisiporești': 4731,
    'Oanțu': 4731,
    'Oglinzi': 4731,
    'Oniceni': 4731,
    'Oraş Bicaz': 4731,
    'Oraş Roznov': 4731,
    'Oraş Târgu Neamţ': 4731,
    'Pânceşti': 4731,
    'Pângărăcior': 4731,
    'Pângăraţi': 4731,
    'Păstrăveni': 4731,
    'Petricani': 4731,
    'Piatra Neamţ': 4731,
    'Piatra Şoimului': 4731,
    'Pildești': 4731,
    'Pipirig': 4731,
    'Podoleni': 4731,
    'Poiana Crăcăoani': 4731,
    'Poiana Teiului': 4731,
    'Poienari': 4731,
    'Poieni': 4731,
    'Poloboc': 4731,
    'Rădeni': 4731,
    'Răuceşti': 4731,
    'Războienii de Jos': 4731,
    'Rediu': 4731,
    'Roman': 4731,
    'Români': 4731,
    'Rotunda': 4731,
    'Roznov': 4731,
    'Ruginoasa': 4731,
    'Ruseni': 4731,
    'Săbăoani': 4731,
    'Sabasa': 4731,
    'Sagna': 4731,
    'Săvineşti': 4731,
    'Secuieni': 4731,
    'Siliștea': 4731,
    'Slobozia': 4731,
    'Stânca': 4731,
    'Stăniţa': 4731,
    'Ştefan cel Mare': 4731,
    'Stejaru': 4731,
    'Tămăşeni': 4731,
    'Tarcău': 4731,
    'Târgu Neamţ': 4731,
    'Taşca': 4731,
    'Tazlău': 4731,
    'Telec': 4731,
    'Ţibucani': 4731,
    'Timişeşti': 4731,
    'Țolici': 4731,
    'Topolița': 4731,
    'Traian': 4731,
    'Trifeşti': 4731,
    'Tupilaţi': 4731,
    'Urecheni': 4731,
    'Vădurele': 4731,
    'Valea Arini': 4731,
    'Valea Seacă': 4731,
    'Valea Ursului': 4731,
    'Văleni': 4731,
    'Vânători-Neamţ': 4731,
    'Viişoara': 4731,
    'Zăneşti': 4731,
    'Alimănești': 4738,
    'Alunișu': 4738,
    'Băbiciu': 4738,
    'Bacea': 4738,
    'Bălănești': 4738,
    'Baldovineşti': 4738,
    'Balş': 4738,
    'Bălteni': 4738,
    'Bărăștii de Vede': 4738,
    'Barza': 4738,
    'Bircii': 4738,
    'Bobiceşti': 4738,
    'Brâncoveni': 4738,
    'Braneț': 4738,
    'Brastavățu': 4738,
    'Brebeni': 4738,
    'Bucinişu': 4738,
    'Bușca': 4738,
    'Călinești': 4738,
    'Călui': 4738,
    'Caracal': 4738,
    'Cârlogani': 4738,
    'Catanele': 4738,
    'Cepari': 4738,
    'Cezieni': 4738,
    'Cilieni': 4738,
    'Coloneşti': 4738,
    'Comanca': 4738,
    'Comani': 4738,
    'Comuna Băbiciu': 4738,
    'Comuna Baldovineşti': 4738,
    'Comuna Bălteni': 4738,
    'Comuna Bãrãşti': 4738,
    'Comuna Bârza': 4738,
    'Comuna Bobiceşti': 4738,
    'Comuna Brâncoveni': 4738,
    'Comuna Brastavăţu': 4738,
    'Comuna Brebeni': 4738,
    'Comuna Bucinişu': 4738,
    'Comuna Cãlui': 4738,
    'Comuna Cârlogani': 4738,
    'Comuna Cezieni': 4738,
    'Comuna Cilieni': 4738,
    'Comuna Coloneşti': 4738,
    'Comuna Corbu': 4738,
    'Comuna Coteana': 4738,
    'Comuna Crâmpoia': 4738,
    'Comuna Cungrea': 4738,
    'Comuna Curtişoara': 4738,
    'Comuna Dăneasa': 4738,
    'Comuna Deveselu': 4738,
    'Comuna Dobreţu': 4738,
    'Comuna Dobrosloveni': 4738,
    'Comuna Dobroteasa': 4738,
    'Comuna Dobrun': 4738,
    'Comuna Drăghiceni': 4738,
    'Comuna Făgeţelu': 4738,
    'Comuna Fălcoiu': 4738,
    'Comuna Fărcaşele': 4738,
    'Comuna Găneasa': 4738,
    'Comuna Gârcov': 4738,
    'Comuna Găvăneşti': 4738,
    'Comuna Ghimpeţeni': 4738,
    'Comuna Giuvărăşti': 4738,
    'Comuna Gostavăţu': 4738,
    'Comuna Grãdinari': 4738,
    'Comuna Grãdinile': 4738,
    'Comuna Grojdibodu': 4738,
    'Comuna Gura Padinii': 4738,
    'Comuna Ianca': 4738,
    'Comuna Iancu Jianu': 4738,
    'Comuna Icoana': 4738,
    'Comuna Ipotesti': 4738,
    'Comuna Izbiceni': 4738,
    'Comuna Izvoarele': 4738,
    'Comuna Leleasca': 4738,
    'Comuna Mărunţei': 4738,
    'Comuna Mihăeşti': 4738,
    'Comuna Milcov': 4738,
    'Comuna Morunglav': 4738,
    'Comuna Movileni': 4738,
    'Comuna Nicolae Titulescu': 4738,
    'Comuna Obârşia': 4738,
    'Comuna Oboga': 4738,
    'Comuna Oporelu': 4738,
    'Comuna Optaşi-Mãgura': 4738,
    'Comuna Orlea': 4738,
    'Comuna Osica de Jos': 4738,
    'Comuna Osica de Sus': 4738,
    'Comuna Pârşcoveni': 4738,
    'Comuna Perieţi': 4738,
    'Comuna Pleşoiu': 4738,
    'Comuna Poboru': 4738,
    'Comuna Priseaca': 4738,
    'Comuna Radomireşti': 4738,
    'Comuna Redea': 4738,
    'Comuna Rotunda': 4738,
    'Comuna Rusăneşti': 4738,
    'Comuna Sâmbureşti': 4738,
    'Comuna Sârbii-Măgura': 4738,
    'Comuna Scărişoara': 4738,
    'Comuna Schitu': 4738,
    'Comuna Seaca': 4738,
    'Comuna Şerbăneşti': 4738,
    'Comuna Slătioara': 4738,
    'Comuna Şopârliţa': 4738,
    'Comuna Spineni': 4738,
    'Comuna Sprâncenata': 4738,
    'Comuna Ştefan cel Mare': 4738,
    'Comuna Stoeneşti': 4738,
    'Comuna Stoicăneşti': 4738,
    'Comuna Strejeşti': 4738,
    'Comuna Studina': 4738,
    'Comuna Tătuleşti': 4738,
    'Comuna Teslui': 4738,
    'Comuna Tia Mare': 4738,
    'Comuna Topana': 4738,
    'Comuna Traian': 4738,
    'Comuna Tufeni': 4738,
    'Comuna Urzica': 4738,
    'Comuna Vădastra': 4738,
    'Comuna Vădăstriţa': 4738,
    'Comuna Vâlcele': 4738,
    'Comuna Valea Mare': 4738,
    'Comuna Văleni': 4738,
    'Comuna Verguleasa': 4738,
    'Comuna Vişina': 4738,
    'Comuna Vişina Nouă': 4738,
    'Comuna Vitomireşti': 4738,
    'Comuna Vlădila': 4738,
    'Comuna Voineasa': 4738,
    'Comuna Vulpeni': 4738,
    'Comuna Vultureşti': 4738,
    'Corabia': 4738,
    'Corbu': 4738,
    'Coteana': 4738,
    'Crăciunei': 4738,
    'Crâmpoia': 4738,
    'Crușovu': 4738,
    'Cungrea': 4738,
    'Curtişoara': 4738,
    'Dăneasa': 4738,
    'Dejești': 4738,
    'Deveselu': 4738,
    'Doanca': 4738,
    'Dobreţu': 4738,
    'Dobrosloveni': 4738,
    'Dobroteasa': 4738,
    'Dobrotinet': 4738,
    'Dobrun': 4738,
    'Drăgăneşti-Olt': 4738,
    'Drăghiceni': 4738,
    'Dranovățu': 4738,
    'Dumitrești': 4738,
    'Făgeţelu': 4738,
    'Fălcoiu': 4738,
    'Fărcaşele': 4738,
    'Fărcașu de Jos': 4738,
    'Găneasa': 4738,
    'Gârcov': 4738,
    'Găvănești': 4738,
    'Ghimpați': 4738,
    'Ghimpețeni': 4738,
    'Ghioca': 4738,
    'Giuvărăşti': 4738,
    'Gostavăţu': 4738,
    'Grădinari': 4738,
    'Greci': 4738,
    'Grojdibodu': 4738,
    'Gropșani': 4738,
    'Gura Căluiu': 4738,
    'Gura Padinii': 4738,
    'Ianca': 4738,
    'Iancu Jianu': 4738,
    'Icoana': 4738,
    'Ipotești': 4738,
    'Izbiceni': 4738,
    'Izvoarele': 4738,
    'Jieni': 4738,
    'Leleasca': 4738,
    'Mărgineni Slobozia': 4738,
    'Mărunţei': 4738,
    'Mihăeşti': 4738,
    'Morunglav': 4738,
    'Movileni': 4738,
    'Municipiul Caracal': 4738,
    'Municipiul Slatina': 4738,
    'Negreni': 4738,
    'Nicolae Titulescu': 4738,
    'Obârşia': 4738,
    'Oboga': 4738,
    'Olari': 4738,
    'Oporelu': 4738,
    'Optaşi': 4738,
    'Oraş Balş': 4738,
    'Oraş Corabia': 4738,
    'Oraş Drãgãneşti-Olt': 4738,
    'Oraş Piatra-Olt': 4738,
    'Oraş Potcoava': 4738,
    'Oraş Scorniceşti': 4738,
    'Orlea': 4738,
    'Orlea Nouă': 4738,
    'Osica de Jos': 4738,
    'Osica de Sus': 4738,
    'Pârşcoveni': 4738,
    'Perieţi': 4738,
    'Piatra': 4738,
    'Piatra Olt': 4738,
    'Pleşoiu': 4738,
    'Poboru': 4738,
    'Potcoava': 4738,
    'Potcoava Fălcoeni': 4738,
    'Potelu': 4738,
    'Potlogeni': 4738,
    'Priseaca': 4738,
    'Radomireşti': 4738,
    'Redea': 4738,
    'Rotunda': 4738,
    'Rusăneşti': 4738,
    'Sâmbureşti': 4738,
    'Scărişoara': 4738,
    'Schitu': 4738,
    'Scorniceşti': 4738,
    'Seaca': 4738,
    'Şerbăneşti': 4738,
    'Sinești': 4738,
    'Slatina': 4738,
    'Slătioara': 4738,
    'Slăveni': 4738,
    'Şopârliţa': 4738,
    'Sprâncenata': 4738,
    'Stăvaru': 4738,
    'Ştefan cel Mare': 4738,
    'Stoeneşti': 4738,
    'Stoicăneşti': 4738,
    'Strejeşti': 4738,
    'Strejeștii de Sus': 4738,
    'Studina': 4738,
    'Studinița': 4738,
    'Tătuleşti': 4738,
    'Teslui': 4738,
    'Tia Mare': 4738,
    'Topana': 4738,
    'Traian': 4738,
    'Tufeni': 4738,
    'Ursa': 4738,
    'Urzica': 4738,
    'Vădastra': 4738,
    'Vădăstriţa': 4738,
    'Vâlcele': 4738,
    'Vâlcelele de Sus': 4738,
    'Valea Mare': 4738,
    'Valea Merilor': 4738,
    'Văleni': 4738,
    'Verguleasa': 4738,
    'Vişina': 4738,
    'Vișina Nouă': 4738,
    'Vitănești': 4738,
    'Vitomireşti': 4738,
    'Vlădila': 4738,
    'Voineasa': 4738,
    'Vulpeni': 4738,
    'Vultureşti': 4738,
    'Adunaţi': 4729,
    'Albești-Muru': 4729,
    'Albeşti-Paleologu': 4729,
    'Aluniş': 4729,
    'Apostolache': 4729,
    'Ariceștii Zeletin': 4729,
    'Ariceştii-Rahtivani': 4729,
    'Azuga': 4729,
    'Baba Ana': 4729,
    'Băicoi': 4729,
    'Balta Doamnei': 4729,
    'Bălțești': 4729,
    'Băneşti': 4729,
    'Bărcăneşti': 4729,
    'Bătești': 4729,
    'Bătrâni': 4729,
    'Berceni': 4729,
    'Bertea': 4729,
    'Blejoi': 4729,
    'Bobolia': 4729,
    'Boldeşti': 4729,
    'Boldeşti-Scăeni': 4729,
    'Bordenii Mari': 4729,
    'Brazii de Sus': 4729,
    'Breaza': 4729,
    'Breaza de Jos': 4729,
    'Breaza de Sus': 4729,
    'Brebu Mânăstirei': 4729,
    'Brebu Megieșesc': 4729,
    'Bucov': 4729,
    'Buda': 4729,
    'Bughea de Jos': 4729,
    'Buşteni': 4729,
    'Călugăreni': 4729,
    'Câmpina': 4729,
    'Cărbuneşti': 4729,
    'Cătina': 4729,
    'Ceptura de Jos': 4729,
    'Ceptura de Sus': 4729,
    'Ceraşu': 4729,
    'Chiojdeanca': 4729,
    'Chițorani': 4729,
    'Cioranii de Jos': 4729,
    'Cioranii de Sus': 4729,
    'Coada Izvorului': 4729,
    'Cocorăștii Colț': 4729,
    'Cocorăștii Mislii': 4729,
    'Colceag': 4729,
    'Comarnic': 4729,
    'Comuna Adunaţi': 4729,
    'Comuna Albeşti-Paleologu': 4729,
    'Comuna Aluniş': 4729,
    'Comuna Apostolache': 4729,
    'Comuna Ariceştii Zeletin': 4729,
    'Comuna Ariceştii-Rahtivani': 4729,
    'Comuna Baba Ana': 4729,
    'Comuna Balta Doamnei': 4729,
    'Comuna Bălţeşti': 4729,
    'Comuna Băneşti': 4729,
    'Comuna Bărcăneşti': 4729,
    'Comuna Bătrâni': 4729,
    'Comuna Berceni': 4729,
    'Comuna Bertea': 4729,
    'Comuna Blejoi': 4729,
    'Comuna Boldeşti-Gradiştea': 4729,
    'Comuna Brazi': 4729,
    'Comuna Brebu': 4729,
    'Comuna Bucov': 4729,
    'Comuna Călugăreni': 4729,
    'Comuna Cărbuneşti': 4729,
    'Comuna Ceptura': 4729,
    'Comuna Ceraşu': 4729,
    'Comuna Chiojdeanca': 4729,
    'Comuna Ciorani': 4729,
    'Comuna Cocorãştii Mislii': 4729,
    'Comuna Cocorăştii-Colţ': 4729,
    'Comuna Colceag': 4729,
    'Comuna Cornu': 4729,
    'Comuna Cosminele': 4729,
    'Comuna Drăgăneşti': 4729,
    'Comuna Drajna de Sus': 4729,
    'Comuna Dumbrava': 4729,
    'Comuna Dumbrãveşti': 4729,
    'Comuna Fântânele': 4729,
    'Comuna Filipeştii de Pădure': 4729,
    'Comuna Filipeştii de Târg': 4729,
    'Comuna Floreşti': 4729,
    'Comuna Fulga': 4729,
    'Comuna Gherghiţa': 4729,
    'Comuna Gorgota': 4729,
    'Comuna Gornet': 4729,
    'Comuna Gornet-Cricov': 4729,
    'Comuna Gura Vadului': 4729,
    'Comuna Gura Vitioarei': 4729,
    'Comuna Iordãcheanu': 4729,
    'Comuna Izvoarele': 4729,
    'Comuna Jugureni': 4729,
    'Comuna Lapoş': 4729,
    'Comuna Lipăneşti': 4729,
    'Comuna Măgurele': 4729,
    'Comuna Măgureni': 4729,
    'Comuna Măneciu': 4729,
    'Comuna Măneşti': 4729,
    'Comuna Olari': 4729,
    'Comuna Păcureţi': 4729,
    'Comuna Păuleşti': 4729,
    'Comuna Plopu': 4729,
    'Comuna Podenii Noi': 4729,
    'Comuna Poiana Câmpina': 4729,
    'Comuna Poienarii Burchii': 4729,
    'Comuna Poseşti': 4729,
    'Comuna Predeal-Sărari': 4729,
    'Comuna Proviţa de Jos': 4729,
    'Comuna Proviţa de Sus': 4729,
    'Comuna Puchenii Mari': 4729,
    'Comuna Râfov': 4729,
    'Comuna Salcia': 4729,
    'Comuna Sălciile': 4729,
    'Comuna Sângeru': 4729,
    'Comuna Scorţeni': 4729,
    'Comuna Secăria': 4729,
    'Comuna Şirna': 4729,
    'Comuna Şoimari': 4729,
    'Comuna Şotrile': 4729,
    'Comuna Starchiojd': 4729,
    'Comuna Ştefeşti': 4729,
    'Comuna Surani': 4729,
    'Comuna Talea': 4729,
    'Comuna Târgşoru Vechi': 4729,
    'Comuna Tătaru': 4729,
    'Comuna Teişani': 4729,
    'Comuna Telega': 4729,
    'Comuna Tinosu': 4729,
    'Comuna Tomşani': 4729,
    'Comuna Vadu Săpat': 4729,
    'Comuna Vâlcăneşti': 4729,
    'Comuna Valea Călugărească': 4729,
    'Comuna Valea Doftanei': 4729,
    'Comuna Vărbilău': 4729,
    'Conduratu': 4729,
    'Corlătești': 4729,
    'Cornu de Jos': 4729,
    'Cornu de Sus': 4729,
    'Cosmina de Jos': 4729,
    'Crivina': 4729,
    'Dâmbu': 4729,
    'Dițești': 4729,
    'Doftana': 4729,
    'Drăgăneasa': 4729,
    'Drăgăneşti': 4729,
    'Drajna de Jos': 4729,
    'Drajna de Sus': 4729,
    'Dumbrava': 4729,
    'Dumbrăveşti': 4729,
    'Făgetu': 4729,
    'Fântânele': 4729,
    'Filipeştii de Pădure': 4729,
    'Filipeştii de Târg': 4729,
    'Floreşti': 4729,
    'Fulga de Jos': 4729,
    'Fulga de Sus': 4729,
    'Găgeni': 4729,
    'Gheaba': 4729,
    'Gherghiţa': 4729,
    'Ghioșești': 4729,
    'Gorgota': 4729,
    'Gornet': 4729,
    'Gornet-Cricov': 4729,
    'Goruna': 4729,
    'Gradiștea': 4729,
    'Groșani': 4729,
    'Gura Beliei': 4729,
    'Gura Vadului': 4729,
    'Gura Viţioarei': 4729,
    'Homorâciu': 4729,
    'Inotești': 4729,
    'Iordăcheanu': 4729,
    'Izvoarele': 4729,
    'Jugureni': 4729,
    'Lapoș': 4729,
    'Liliești': 4729,
    'Lipăneşti': 4729,
    'Livadea': 4729,
    'Loloiasca': 4729,
    'Lunca Prahovei': 4729,
    'Magula': 4729,
    'Măgurele': 4729,
    'Măgureni': 4729,
    'Măneciu-Ungureni': 4729,
    'Măneşti': 4729,
    'Mărginenii de Jos': 4729,
    'Meri': 4729,
    'Minieri': 4729,
    'Mireșu Mare': 4729,
    'Miroslăvești': 4729,
    'Mislea': 4729,
    'Mizil': 4729,
    'Moceşti': 4729,
    'Municipiul Câmpina': 4729,
    'Municipiul Ploieşti': 4729,
    'Nedelea': 4729,
    'Negoiești': 4729,
    'Ocina de Sus': 4729,
    'Ogretin': 4729,
    'Olari': 4729,
    'Ologeni': 4729,
    'Oraş Azuga': 4729,
    'Oraş Bãicoi': 4729,
    'Oraş Boldeşti-Scãeni': 4729,
    'Oraş Breaza': 4729,
    'Oraş Buşteni': 4729,
    'Oraş Comarnic': 4729,
    'Oraş Mizil': 4729,
    'Oraş Plopeni': 4729,
    'Oraş Sinaia': 4729,
    'Oraş Slãnic': 4729,
    'Oraş Urlaţi': 4729,
    'Oraş Vãlenii De Munte': 4729,
    'Ostrovu': 4729,
    'Păcureţi': 4729,
    'Palanca': 4729,
    'Pantazi': 4729,
    'Parepa-Rușani': 4729,
    'Păuleşti': 4729,
    'Perșunari': 4729,
    'Pietriceaua': 4729,
    'Pietroșani': 4729,
    'Pleașa': 4729,
    'Ploieşti': 4729,
    'Ploieștiori': 4729,
    'Plopeni': 4729,
    'Plopu': 4729,
    'Podenii Noi': 4729,
    'Podenii Vechi': 4729,
    'Poiana': 4729,
    'Poiana Câmpina': 4729,
    'Poiana Copăceni': 4729,
    'Poiana Țapului': 4729,
    'Poienarii Apostoli': 4729,
    'Poienarii Burchii': 4729,
    'Popești': 4729,
    'Posada': 4729,
    'Poseștii-Pământeni': 4729,
    'Potigrafu': 4729,
    'Prăjani': 4729,
    'Proviţa de Jos': 4729,
    'Proviţa de Sus': 4729,
    'Puchenii Mari': 4729,
    'Puchenii Moșneni': 4729,
    'Rachieri': 4729,
    'Râfov': 4729,
    'Românești': 4729,
    'Salcia': 4729,
    'Sălciile': 4729,
    'Sângeru': 4729,
    'Schiulești': 4729,
    'Scorţeni': 4729,
    'Scurtești': 4729,
    'Secăria': 4729,
    'Seciu': 4729,
    'Sinaia': 4729,
    'Șipotu': 4729,
    'Şirna': 4729,
    'Slănic': 4729,
    'Slon': 4729,
    'Şoimari': 4729,
    'Şotrile': 4729,
    'Starchiojd': 4729,
    'Ştefeşti': 4729,
    'Stoenești': 4729,
    'Strejnicu': 4729,
    'Surani': 4729,
    'Talea': 4729,
    'Târgşoru Vechi': 4729,
    'Tăriceni': 4729,
    'Tătărani': 4729,
    'Tătaru': 4729,
    'Teişani': 4729,
    'Telega': 4729,
    'Teșila': 4729,
    'Tinosu': 4729,
    'Țintea': 4729,
    'Tomşani': 4729,
    'Trăisteni': 4729,
    'Tufeni': 4729,
    'Urlaţi': 4729,
    'Urleta': 4729,
    'Vadu Părului': 4729,
    'Vadu Săpat': 4729,
    'Vâlcăneşti': 4729,
    'Valea Călugărească': 4729,
    'Valea Cucului': 4729,
    'Valea Dulce': 4729,
    'Valea Târsei': 4729,
    'Vălenii de Munte': 4729,
    'Vărbila': 4729,
    'Vărbilău': 4729,
    'Varnița': 4729,
    'Aghireș': 4741,
    'Agrij': 4741,
    'Almaşu': 4741,
    'Băbeni': 4741,
    'Bălan': 4741,
    'Bănişor': 4741,
    'Benesat': 4741,
    'Bobota': 4741,
    'Bocşa': 4741,
    'Boghiș': 4741,
    'Buciumi': 4741,
    'Camăr': 4741,
    'Carastelec': 4741,
    'Cehei': 4741,
    'Cehu Silvaniei': 4741,
    'Chendrea': 4741,
    'Chieşd': 4741,
    'Cizer': 4741,
    'Comuna Agrij': 4741,
    'Comuna Almaşu': 4741,
    'Comuna Băbeni': 4741,
    'Comuna Bălan': 4741,
    'Comuna Bănişor': 4741,
    'Comuna Benesat': 4741,
    'Comuna Bobota': 4741,
    'Comuna Bocşa': 4741,
    'Comuna Boghiş': 4741,
    'Comuna Buciumi': 4741,
    'Comuna Camăr': 4741,
    'Comuna Carastelec': 4741,
    'Comuna Cehu Silvaniei': 4741,
    'Comuna Chieşd': 4741,
    'Comuna Cizer': 4741,
    'Comuna Coşeiu': 4741,
    'Comuna Crasna': 4741,
    'Comuna Creaca': 4741,
    'Comuna Crişeni': 4741,
    'Comuna Cristolţ': 4741,
    'Comuna Cuzăplac': 4741,
    'Comuna Dobrin': 4741,
    'Comuna Dragu': 4741,
    'Comuna Fildu De Jos': 4741,
    'Comuna Gâlgău': 4741,
    'Comuna Gârbou': 4741,
    'Comuna Halmăşd': 4741,
    'Comuna Hereclean': 4741,
    'Comuna Hida': 4741,
    'Comuna Horoatu Crasnei': 4741,
    'Comuna Ileanda': 4741,
    'Comuna Ip': 4741,
    'Comuna Letca': 4741,
    'Comuna Lozna': 4741,
    'Comuna Măerişte': 4741,
    'Comuna Marca': 4741,
    'Comună Meseşenii de Jos': 4741,
    'Comuna Mirşid': 4741,
    'Comuna Năpradea': 4741,
    'Comuna Nuşfalău': 4741,
    'Comuna Pericei': 4741,
    'Comuna Plopiş': 4741,
    'Comuna Poiana Blenchii': 4741,
    'Comuna Românaşi': 4741,
    'Comuna Rus': 4741,
    'Comuna Sâg': 4741,
    'Comuna Sălăţig': 4741,
    'Comuna Şamşud': 4741,
    'Comuna Sânmihaiu Almaşului': 4741,
    'Comuna Şărmăşag': 4741,
    'Comuna Şimişna': 4741,
    'Comuna Someş-Odorhei': 4741,
    'Comuna Surduc': 4741,
    'Comuna Treznea': 4741,
    'Comuna Valcãu De Jos': 4741,
    'Comuna Vârşolţ': 4741,
    'Comuna Zalha': 4741,
    'Comuna Zimbor': 4741,
    'Coşeiu': 4741,
    'Crasna': 4741,
    'Creaca': 4741,
    'Crişeni': 4741,
    'Cristolţ': 4741,
    'Cuzăplac': 4741,
    'Deja': 4741,
    'Derșida': 4741,
    'Dioșod': 4741,
    'Dobrin': 4741,
    'Dragu': 4741,
    'Fildu de Jos': 4741,
    'Gâlgău': 4741,
    'Gâlgău Almaşului': 4741,
    'Gârbou': 4741,
    'Giurtelecu Șimleului': 4741,
    'Halmăşd': 4741,
    'Hereclean': 4741,
    'Hida': 4741,
    'Horoatu Crasnei': 4741,
    'Ileanda': 4741,
    'Ip': 4741,
    'Jibou': 4741,
    'Letca': 4741,
    'Lozna': 4741,
    'Măerişte': 4741,
    'Mal': 4741,
    'Marca': 4741,
    'Marin': 4741,
    'Meseşenii de Jos': 4741,
    'Mirşid': 4741,
    'Municipiul Zalãu': 4741,
    'Năpradea': 4741,
    'Nuşfalău': 4741,
    'Oraș Jibou': 4741,
    'Oraş Şimleu Silvaniei': 4741,
    'Peceiu': 4741,
    'Pericei': 4741,
    'Plopiş': 4741,
    'Poiana Blenchii': 4741,
    'Pusta': 4741,
    'Românaşi': 4741,
    'Rus': 4741,
    'Sâg': 4741,
    'Sălăţig': 4741,
    'Şamşud': 4741,
    'Sânmihaiu Almaşului': 4741,
    'Şărmăşag': 4741,
    'Șimișna': 4741,
    'Şimleu Silvaniei': 4741,
    'Someş-Odorhei': 4741,
    'Stârciu': 4741,
    'Surduc': 4741,
    'Tihău': 4741,
    'Treznea': 4741,
    'Valcău de Jos': 4741,
    'Vârşolţ': 4741,
    'Zalău': 4741,
    'Zalha': 4741,
    'Zăuan': 4741,
    'Zimbor': 4741,
    'Acâş': 4746,
    'Agriș': 4746,
    'Andrid': 4746,
    'Apa': 4746,
    'Ardud': 4746,
    'Babța': 4746,
    'Bârsău de Sus': 4746,
    'Bătarci': 4746,
    'Beltiug': 4746,
    'Berveni': 4746,
    'Bixad': 4746,
    'Bogdand': 4746,
    'Boinești': 4746,
    'Botiz': 4746,
    'Călineşti-Oaş': 4746,
    'Cămărzana': 4746,
    'Cămin': 4746,
    'Căpleni': 4746,
    'Cărășeu': 4746,
    'Carei': 4746,
    'Căuaş': 4746,
    'Cehal': 4746,
    'Certeze': 4746,
    'Cidreag': 4746,
    'Ciumești': 4746,
    'Comuna Acâş': 4746,
    'Comuna Agriş': 4746,
    'Comuna Andrid': 4746,
    'Comuna Apa': 4746,
    'Comuna Bârsău': 4746,
    'Comuna Bătarci': 4746,
    'Comuna Beltiug': 4746,
    'Comuna Berveni': 4746,
    'Comuna Bixad': 4746,
    'Comuna Bogdand': 4746,
    'Comuna Botiz': 4746,
    'Comuna Cãlineşti-Oaş': 4746,
    'Comuna Cămărzana': 4746,
    'Comuna Cãmin': 4746,
    'Comuna Căpleni': 4746,
    'Comuna Căuaş': 4746,
    'Comuna Cehal': 4746,
    'Comuna Certeze': 4746,
    'Comuna Ciumeşti': 4746,
    'Comuna Craidorolţ': 4746,
    'Comuna Crucişor': 4746,
    'Comuna Culciu': 4746,
    'Comuna Doba': 4746,
    'Comuna Dorolţ': 4746,
    'Comuna Foieni': 4746,
    'Comuna Gherţa Mică': 4746,
    'Comuna Halmeu': 4746,
    'Comuna Hodod': 4746,
    'Comuna Homoroade': 4746,
    'Comuna Lazuri': 4746,
    'Comuna Medieşu Aurit': 4746,
    'Comuna Micula': 4746,
    'Comuna Moftinu': 4746,
    'Comuna Odoreu': 4746,
    'Comuna Oraşu Nou': 4746,
    'Comuna Pãuleşti': 4746,
    'Comuna Petreşti': 4746,
    'Comuna Pir': 4746,
    'Comuna Pişcolt': 4746,
    'Comuna Pomi': 4746,
    'Comuna Porumbeşti': 4746,
    'Comuna Racşa': 4746,
    'Comuna Săcăşeni': 4746,
    'Comuna Sanislău': 4746,
    'Comuna Santău': 4746,
    'Comuna Săuca': 4746,
    'Comuna Socond': 4746,
    'Comuna Supur': 4746,
    'Comuna Tarna Mare': 4746,
    'Comuna Târşolţ': 4746,
    'Comuna Terebeşti': 4746,
    'Comuna Tiream': 4746,
    'Comuna Turţ': 4746,
    'Comuna Turulung': 4746,
    'Comuna Urziceni': 4746,
    'Comuna Valea Vinului': 4746,
    'Comuna Vama': 4746,
    'Comuna Vetiş': 4746,
    'Comuna Viile Satu Mare': 4746,
    'Craidorolţ': 4746,
    'Crucişor': 4746,
    'Culciu Mic': 4746,
    'Dara': 4746,
    'Decebal': 4746,
    'Doba': 4746,
    'Dobra': 4746,
    'Domănești': 4746,
    'Dorolţ': 4746,
    'Drăgușeni': 4746,
    'Dumbrava': 4746,
    'Foieni': 4746,
    'Gherța Mare': 4746,
    'Gherţa Mică': 4746,
    'Halmeu': 4746,
    'Hodod': 4746,
    'Homorodu de Jos': 4746,
    'Huta Certeze': 4746,
    'Iojib': 4746,
    'Lazuri': 4746,
    'Livada': 4746,
    'Lucăceni': 4746,
    'Mădăras': 4746,
    'Medieşu Aurit': 4746,
    'Micula': 4746,
    'Moftinu Mare': 4746,
    'Moftinu Mic': 4746,
    'Moișeni': 4746,
    'Municipiul Carei': 4746,
    'Municipiul Satu Mare': 4746,
    'Negreşti-Oaş': 4746,
    'Oar': 4746,
    'Odoreu': 4746,
    'Oraș Ardud': 4746,
    'Oraș Livada': 4746,
    'Oraş Negreşti-Oaş': 4746,
    'Oraș Tăşnad': 4746,
    'Oraşu Nou': 4746,
    'Păuleşti': 4746,
    'Petreşti': 4746,
    'Pir': 4746,
    'Pişcolt': 4746,
    'Poiana Codrului': 4746,
    'Pomi': 4746,
    'Porumbești': 4746,
    'Potău': 4746,
    'Racşa': 4746,
    'Săcăşeni': 4746,
    'Sanislău': 4746,
    'Santău': 4746,
    'Sărăuad': 4746,
    'Sătmărel': 4746,
    'Satu Mare': 4746,
    'Săuca': 4746,
    'Socond': 4746,
    'Stâna': 4746,
    'Supuru de Jos': 4746,
    'Tarna Mare': 4746,
    'Târşolţ': 4746,
    'Târşolţel': 4746,
    'Tăşnad': 4746,
    'Terebeşti': 4746,
    'Tiream': 4746,
    'Trip': 4746,
    'Tur': 4746,
    'Turţ': 4746,
    'Turulung': 4746,
    'Urziceni': 4746,
    'Valea Seacă': 4746,
    'Valea Vinului': 4746,
    'Vama': 4746,
    'Vetiş': 4746,
    'Viile Satu Mare': 4746,
    'Agârbiciu': 4755,
    'Agnita': 4755,
    'Alămor': 4755,
    'Alma': 4755,
    'Alțâna': 4755,
    'Apoldu de Jos': 4755,
    'Arpaşu de Jos': 4755,
    'Arpașu de Sus': 4755,
    'Aţel': 4755,
    'Avrig': 4755,
    'Axente Sever': 4755,
    'Bârghiş': 4755,
    'Bazna': 4755,
    'Biertan': 4755,
    'Blăjel': 4755,
    'Boian': 4755,
    'Boița': 4755,
    'Brădeni': 4755,
    'Bradu': 4755,
    'Brateiu': 4755,
    'Bruiu': 4755,
    'Cârţa': 4755,
    'Cârţişoara': 4755,
    'Chirpăr': 4755,
    'Cisnădie': 4755,
    'Comuna Alma': 4755,
    'Comuna Alțâna': 4755,
    'Comuna Apoldu de Jos': 4755,
    'Comuna Arpaşu de Jos': 4755,
    'Comuna Aţel': 4755,
    'Comuna Axente Sever': 4755,
    'Comuna Bârghiş': 4755,
    'Comuna Bazna': 4755,
    'Comuna Biertan': 4755,
    'Comuna Blăjel': 4755,
    'Comuna Boiţa': 4755,
    'Comuna Brădeni': 4755,
    'Comuna Brateiu': 4755,
    'Comuna Bruiu': 4755,
    'Comuna Cârţa': 4755,
    'Comuna Cârţişoara': 4755,
    'Comuna Chirpăr': 4755,
    'Comuna Cristian': 4755,
    'Comuna Dârlos': 4755,
    'Comuna Gura Râului': 4755,
    'Comuna Hoghilag': 4755,
    'Comuna Iacobeni': 4755,
    'Comuna Jina': 4755,
    'Comuna Laslea': 4755,
    'Comuna Loamneş': 4755,
    'Comuna Ludoş': 4755,
    'Comuna Marpod': 4755,
    'Comuna Merghindeal': 4755,
    'Comuna Micăsasa': 4755,
    'Comuna Mihăileni': 4755,
    'Comuna Moşna': 4755,
    'Comuna Nocrich': 4755,
    'Comuna Orlat': 4755,
    'Comuna Păuca': 4755,
    'Comuna Poiana Sibiului': 4755,
    'Comuna Poplaca': 4755,
    'Comuna Porumbacu de Jos': 4755,
    'Comuna Racoviţa': 4755,
    'Comuna Răşinari': 4755,
    'Comuna Râu Sadului': 4755,
    'Comuna Roşia': 4755,
    'Comuna Sadu': 4755,
    'Comuna Şeica Mare': 4755,
    'Comuna Şeica Mică': 4755,
    'Comuna Şelimbăr': 4755,
    'Comuna Slimnic': 4755,
    'Comuna Şura Mare': 4755,
    'Comuna Şura Mică': 4755,
    'Comuna Târnava': 4755,
    'Comuna Tilişca': 4755,
    'Comuna Turnu Roşu': 4755,
    'Comuna Valea Viilor': 4755,
    'Comuna Vurpăr': 4755,
    'Copşa Mică': 4755,
    'Cristian': 4755,
    'Dârlos': 4755,
    'Dumbrăveni': 4755,
    'Gura Râului': 4755,
    'Hoghilag': 4755,
    'Iacobeni': 4755,
    'Ighișu Nou': 4755,
    'Jina': 4755,
    'Laslea': 4755,
    'Loamneş': 4755,
    'Ludoş': 4755,
    'Mălâncrav': 4755,
    'Marpod': 4755,
    'Mârșa': 4755,
    'Mediaş': 4755,
    'Merghindeal': 4755,
    'Micăsasa': 4755,
    'Miercurea Sibiului': 4755,
    'Mihăileni': 4755,
    'Moşna': 4755,
    'Municipiul Mediaş': 4755,
    'Municipiul Sibiu': 4755,
    'Nocrich': 4755,
    'Nou': 4755,
    'Ocna Sibiului': 4755,
    'Oraş Agnita': 4755,
    'Oraş Avrig': 4755,
    'Oraş Cisnãdie': 4755,
    'Oraş Copşa Micã': 4755,
    'Oraş Dumbrãveni': 4755,
    'Oraş Miercurea Sibiului': 4755,
    'Oraş Ocna Sibiului': 4755,
    'Oraş Sãlişte': 4755,
    'Oraş Tãlmaciu': 4755,
    'Orlat': 4755,
    'Păuca': 4755,
    'Poiana Sibiului': 4755,
    'Poplaca': 4755,
    'Porumbacu de Jos': 4755,
    'Racoviţa': 4755,
    'Răşinari': 4755,
    'Râu Sadului': 4755,
    'Roşia': 4755,
    'Ruja': 4755,
    'Sadu': 4755,
    'Sălişte': 4755,
    'Șaroș pe Târnave': 4755,
    'Şeíca Mare': 4755,
    'Şeica Mică': 4755,
    'Şelimbăr': 4755,
    'Sibiu': 4755,
    'Slimnic': 4755,
    'Şura Mare': 4755,
    'Şura Mică': 4755,
    'Tălmăcel': 4755,
    'Tălmaciu': 4755,
    'Târnava': 4755,
    'Tilişca': 4755,
    'Turnu Roşu': 4755,
    'Valea Viilor': 4755,
    'Veștem': 4755,
    'Vurpăr': 4755,
    'Adâncata': 4720,
    'Arbore': 4720,
    'Argel': 4720,
    'Arghira': 4720,
    'Bădeuți': 4720,
    'Baia': 4720,
    'Băișești': 4720,
    'Bălăceana': 4720,
    'Bălcăuţi': 4720,
    'Bănești': 4720,
    'Basarabi': 4720,
    'Berchișești': 4720,
    'Bilca': 4720,
    'Bivolărie': 4720,
    'Bogata': 4720,
    'Bogdăneşti': 4720,
    'Boroaia': 4720,
    'Bosanci': 4720,
    'Botoşana': 4720,
    'Brăiești': 4720,
    'Breaza': 4720,
    'Brodina': 4720,
    'Broşteni': 4720,
    'Bucșoaia': 4720,
    'Budeni': 4720,
    'Bunești': 4720,
    'Burla': 4720,
    'Bursuceni': 4720,
    'Cacica': 4720,
    'Cajvana': 4720,
    'Calafindeşti': 4720,
    'Călinești': 4720,
    'Câmpulung Moldovenesc': 4720,
    'Capu Câmpului': 4720,
    'Capu Codrului': 4720,
    'Cârlibaba': 4720,
    'Ciocănești': 4720,
    'Ciprian Porumbescu': 4720,
    'Clit': 4720,
    'Colacu': 4720,
    'Comănești': 4720,
    'Comuna Adâncata': 4720,
    'Comuna Arbore': 4720,
    'Comuna Baia': 4720,
    'Comuna Bălăceana': 4720,
    'Comuna Bălcăuţi': 4720,
    'Comuna Berchişeşti': 4720,
    'Comuna Bilca': 4720,
    'Comuna Bogdăneşti': 4720,
    'Comuna Boroaia': 4720,
    'Comuna Bosanci': 4720,
    'Comuna Botoşana': 4720,
    'Comuna Breaza': 4720,
    'Comuna Brodina': 4720,
    'Comuna Buneşti': 4720,
    'Comuna Burla': 4720,
    'Comuna Cacica': 4720,
    'Comuna Calafindeşti': 4720,
    'Comuna Capu Câmpului': 4720,
    'Comuna Cârlibaba': 4720,
    'Comuna Ciocăneşti': 4720,
    'Comuna Ciprian Porumbescu': 4720,
    'Comuna Comăneşti': 4720,
    'Comuna Cornu Luncii': 4720,
    'Comuna Coşna': 4720,
    'Comuna Crucea': 4720,
    'Comuna Dărmăneşti': 4720,
    'Comuna Dolheşti': 4720,
    'Comuna Dorna Cândrenilor': 4720,
    'Comuna Dorna-Arini': 4720,
    'Comuna Dorneşti': 4720,
    'Comuna Drãgoieşti': 4720,
    'Comuna Drăguşeni': 4720,
    'Comuna Dumbrăveni': 4720,
    'Comuna Fântâna Mare': 4720,
    'Comuna Fântânele': 4720,
    'Comuna Forăşti': 4720,
    'Comuna Frătăuţii Noi': 4720,
    'Comuna Frătăuţii Vechi': 4720,
    'Comuna Frumosu': 4720,
    'Comuna Fundu Moldovei': 4720,
    'Comuna Gălăneşti': 4720,
    'Comuna Grămeşti': 4720,
    'Comuna Grăniceşti': 4720,
    'Comuna Hănţeşti': 4720,
    'Comuna Hârtop': 4720,
    'Comuna Horodnic De Jos': 4720,
    'Comuna Horodnic De Sus': 4720,
    'Comuna Horodniceni': 4720,
    'Comuna Iacobeni': 4720,
    'Comuna Iaslovăţ': 4720,
    'Comuna Ilişeşti': 4720,
    'Comuna Ipoteşti': 4720,
    'Comuna Izvoarele Sucevei': 4720,
    'Comuna Mălini': 4720,
    'Comuna Mânăstirea Humorului': 4720,
    'Comuna Marginea': 4720,
    'Comuna Mitocu Dragomirnei': 4720,
    'Comuna Moara': 4720,
    'Comuna Moldova Suliţa': 4720,
    'Comuna Moldoviţa': 4720,
    'Comuna Muşeniţa': 4720,
    'Comuna Ostra': 4720,
    'Comuna Păltinoasa': 4720,
    'Comuna Panaci': 4720,
    'Comuna Părteştii de Jos': 4720,
    'Comuna Pătrăuţi': 4720,
    'Comuna Poiana Stampei': 4720,
    'Comuna Poieni-Solca': 4720,
    'Comuna Pojorâta': 4720,
    'Comuna Preuteşti': 4720,
    'Comuna Putna': 4720,
    'Comuna Rădăşeni': 4720,
    'Comuna Râşca': 4720,
    'Comuna Sadova': 4720,
    'Comuna Şaru Dornei': 4720,
    'Comuna Satu Mare': 4720,
    'Comuna Scheia': 4720,
    'Comuna Şerbăuţi': 4720,
    'Comuna Siminicea': 4720,
    'Comuna Slatina': 4720,
    'Comuna Straja': 4720,
    'Comuna Stroieşti': 4720,
    'Comuna Stulpicani': 4720,
    'Comuna Suceviţa': 4720,
    'Comuna Todireşti': 4720,
    'Comuna Udeşti': 4720,
    'Comuna Ulma': 4720,
    'Comuna Vadu Moldovei': 4720,
    'Comuna Valea Moldovei': 4720,
    'Comuna Vama': 4720,
    'Comuna Vatra Moldoviţei': 4720,
    'Comuna Vereşti': 4720,
    'Comuna Vicovu de Jos': 4720,
    'Comuna Voitinel': 4720,
    'Comuna Volovăţ': 4720,
    'Comuna Vultureşti': 4720,
    'Comuna Zamostea': 4720,
    'Comuna Zvoriştea': 4720,
    'Cornu Luncii': 4720,
    'Corocăiești': 4720,
    'Coșna': 4720,
    'Costâna': 4720,
    'Crucea': 4720,
    'Dărmăneşti': 4720,
    'Demacușa': 4720,
    'Dolhasca': 4720,
    'Dolheștii Mici': 4720,
    'Dolheștii-Mari': 4720,
    'Dorna Cândrenilor': 4720,
    'Dorna-Arini': 4720,
    'Dorneşti': 4720,
    'Drăgoiești': 4720,
    'Drăguşeni': 4720,
    'Dumbrăveni': 4720,
    'Dumbrăvița': 4720,
    'Falcău': 4720,
    'Fălticeni': 4720,
    'Fântâna Mare': 4720,
    'Fântânele': 4720,
    'Forăşti': 4720,
    'Frasin': 4720,
    'Frătăuţii Noi': 4720,
    'Frătăuţii Vechi': 4720,
    'Frumosu': 4720,
    'Fundu Moldovei': 4720,
    'Găinești': 4720,
    'Gălăneşti': 4720,
    'Grămeşti': 4720,
    'Grăniceşti': 4720,
    'Gulia': 4720,
    'Gura Humorului': 4720,
    'Gura Putnei': 4720,
    'Hănțești': 4720,
    'Hârtop': 4720,
    'Herla': 4720,
    'Horodnic de Jos': 4720,
    'Horodnic de Sus': 4720,
    'Horodniceni': 4720,
    'Humoreni': 4720,
    'Hurjuieni': 4720,
    'Iacobeni': 4720,
    'Iaslovăț': 4720,
    'Ilișești': 4720,
    'Ipoteşti': 4720,
    'Izvoarele Sucevei': 4720,
    'Lămășeni': 4720,
    'Liteni': 4720,
    'Mălini': 4720,
    'Mănăstirea Humorului': 4720,
    'Marginea': 4720,
    'Măriței': 4720,
    'Măzănăești': 4720,
    'Mihăiești': 4720,
    'Mihoveni': 4720,
    'Milişăuţi': 4720,
    'Mironu': 4720,
    'Mitocu Dragomirnei': 4720,
    'Moara Carp': 4720,
    'Moara Nica': 4720,
    'Moldova Suliţa': 4720,
    'Moldoviţa': 4720,
    'Municipiul Câmpulung Moldovenesc': 4720,
    'Municipiul Fãlticeni': 4720,
    'Municipiul Rãdãuţi': 4720,
    'Municipiul Suceava': 4720,
    'Municipiul Vatra Dornei': 4720,
    'Muşeniţa': 4720,
    'Neagra Șarului': 4720,
    'Negostina': 4720,
    'Negrileasa': 4720,
    'Oniceni': 4720,
    'Oraş Broşteni': 4720,
    'Oraş Cajvana': 4720,
    'Oraş Dolhasca': 4720,
    'Oraş Frasin': 4720,
    'Oraş Gura Humorului': 4720,
    'Oraş Liteni': 4720,
    'Oraş Milişãuţi': 4720,
    'Oraş Salcea': 4720,
    'Oraş Siret': 4720,
    'Oraş Solca': 4720,
    'Oraş Vicovu De Sus': 4720,
    'Ostra': 4720,
    'Păltinoasa': 4720,
    'Panaci': 4720,
    'Pâraie': 4720,
    'Părhăuți': 4720,
    'Părteştii de Jos': 4720,
    'Pătrăuţi': 4720,
    'Plăvălari': 4720,
    'Plopeni': 4720,
    'Plutonița': 4720,
    'Poiana Mărului': 4720,
    'Poiana Stampei': 4720,
    'Poieni-Solca': 4720,
    'Pojorâta': 4720,
    'Prelipca': 4720,
    'Preuteşti': 4720,
    'Probota': 4720,
    'Putna': 4720,
    'Rădăşeni': 4720,
    'Rădăuți': 4720,
    'Râşca': 4720,
    'Roșcani': 4720,
    'Rotunda': 4720,
    'Sadova': 4720,
    'Salcea': 4720,
    'Şaru Dornei': 4720,
    'Satu Mare': 4720,
    'Scheia': 4720,
    'Șerbănești': 4720,
    'Șerbăuți': 4720,
    'Siminicea': 4720,
    'Siret': 4720,
    'Slatina': 4720,
    'Solca': 4720,
    'Soloneț': 4720,
    'Stamate': 4720,
    'Straja': 4720,
    'Strâmtura': 4720,
    'Stroiești': 4720,
    'Stulpicani': 4720,
    'Suceava': 4720,
    'Suceviţa': 4720,
    'Țibeni': 4720,
    'Todireşti': 4720,
    'Udeşti': 4720,
    'Ulma': 4720,
    'Vadu Moldovei': 4720,
    'Valea Moldovei': 4720,
    'Vama': 4720,
    'Văratec': 4720,
    'Vatra Dornei': 4720,
    'Vatra Moldoviţei': 4720,
    'Vereşti': 4720,
    'Vicovu de Jos': 4720,
    'Vicovu de Sus': 4720,
    'Voievodeasa': 4720,
    'Voitinel': 4720,
    'Volovăţ': 4720,
    'Vultureşti': 4720,
    'Zamostea': 4720,
    'Zvoriştea': 4720,
    'Alexandria': 4728,
    'Băbăiţa': 4728,
    'Băcălești': 4728,
    'Baciu': 4728,
    'Băduleasa': 4728,
    'Balaci': 4728,
    'Băneasa': 4728,
    'Bâscoveni': 4728,
    'Beciu': 4728,
    'Beiu': 4728,
    'Beuca': 4728,
    'Blejeşti': 4728,
    'Bogdana': 4728,
    'Botoroaga': 4728,
    'Bragadiru': 4728,
    'Brânceni': 4728,
    'Brâncoveanca': 4728,
    'Bujoreni': 4728,
    'Bujoru': 4728,
    'Buzescu': 4728,
    'Călineşti': 4728,
    'Călmăţuiu': 4728,
    'Călmățuiu de Sus': 4728,
    'Calomfirești': 4728,
    'Cernetu': 4728,
    'Cervenia': 4728,
    'Ciuani': 4728,
    'Ciuperceni': 4728,
    'Comuna Băbăiţa': 4728,
    'Comuna Balaci': 4728,
    'Comuna Beciu': 4728,
    'Comuna Beuca': 4728,
    'Comuna Blejeşti': 4728,
    'Comuna Bogdana': 4728,
    'Comuna Botoroaga': 4728,
    'Comuna Bragadiru': 4728,
    'Comuna Brânceni': 4728,
    'Comuna Bujoreni': 4728,
    'Comuna Bujoru': 4728,
    'Comuna Buzescu': 4728,
    'Comuna Călineşti': 4728,
    'Comuna Cãlmãţuiu': 4728,
    'Comuna Cãlmãţuiu De Sus': 4728,
    'Comuna Cervenia': 4728,
    'Comuna Ciolăneşti': 4728,
    'Comuna Ciuperceni': 4728,
    'Comuna Conţeşti': 4728,
    'Comuna Cosmeşti': 4728,
    'Comuna Crângeni': 4728,
    'Comuna Crângu': 4728,
    'Comuna Crevenicu': 4728,
    'Comuna Dideşti': 4728,
    'Comuna Dobroteşti': 4728,
    'Comuna Dracea': 4728,
    'Comuna Drãcşenei': 4728,
    'Comuna Drăgăneşti de Vede': 4728,
    'Comuna Drăgăneşti-Vlaşca': 4728,
    'Comuna Fântânele': 4728,
    'Comuna Frăsinet': 4728,
    'Comuna Frumoasa': 4728,
    'Comuna Furculeşti': 4728,
    'Comuna Gălăţeni': 4728,
    'Comuna Gratia': 4728,
    'Comuna Islaz': 4728,
    'Comuna Izvoarele': 4728,
    'Comuna Lisa': 4728,
    'Comuna Liţa': 4728,
    'Comuna Lunca': 4728,
    'Comuna Măgura': 4728,
    'Comuna Măldăeni': 4728,
    'Comuna Mârzãneşti': 4728,
    'Comuna Mavrodin': 4728,
    'Comuna Mereni': 4728,
    'Comuna Moşteni': 4728,
    'Comuna Nanov': 4728,
    'Comuna Năsturelu': 4728,
    'Comuna Necşeşti': 4728,
    'Comuna Nenciuleşti': 4728,
    'Comuna Olteni': 4728,
    'Comuna Orbeasca': 4728,
    'Comuna Peretu': 4728,
    'Comuna Piatra': 4728,
    'Comuna Pietroşani': 4728,
    'Comuna Plopii Slăviţeşti': 4728,
    'Comuna Plosca': 4728,
    'Comuna Poeni': 4728,
    'Comuna Poroschia': 4728,
    'Comuna Purani': 4728,
    'Comuna Putineiu': 4728,
    'Comuna Rãdoieşti': 4728,
    'Comuna Răsmireşti': 4728,
    'Comuna Săceni': 4728,
    'Comuna Saelele': 4728,
    'Comuna Salcia': 4728,
    'Comuna Sârbeni': 4728,
    'Comuna Scrioaştea': 4728,
    'Comuna Scurtu Mare': 4728,
    'Comuna Seaca': 4728,
    'Comuna Segarcea Vale': 4728,
    'Comuna Sfinţeşti': 4728,
    'Comuna Siliştea': 4728,
    'Comuna Siliștea Gumești': 4728,
    'Comuna Slobozia-Mândra': 4728,
    'Comuna Smârdioasa': 4728,
    'Comuna Stejaru': 4728,
    'Comuna Storobăneasa': 4728,
    'Comuna Suhaia': 4728,
    'Comuna Talpa': 4728,
    'Comuna Tătărăştii de Jos': 4728,
    'Comuna Tătărăştii de Sus': 4728,
    'Comuna Ţigăneşti': 4728,
    'Comuna Traian': 4728,
    'Comuna Trivalea-Moşteni': 4728,
    'Comuna Troianul': 4728,
    'Comuna Uda-Clocociov': 4728,
    'Comuna Vârtoape': 4728,
    'Comuna Vedea': 4728,
    'Comuna Viişoara': 4728,
    'Comuna Vităneşti': 4728,
    'Comuna Zâmbreasca': 4728,
    'Conţeşti': 4728,
    'Cosmeşti': 4728,
    'Crângeni': 4728,
    'Crângu': 4728,
    'Crevenicu': 4728,
    'Cucueți': 4728,
    'Deparați': 4728,
    'Didești': 4728,
    'Dobreni': 4728,
    'Dobroteşti': 4728,
    'Dracea': 4728,
    'Drăcşani': 4728,
    'Drăcșenei': 4728,
    'Drăgăneşti de Vede': 4728,
    'Drăgăneşti-Vlaşca': 4728,
    'Drăghinești': 4728,
    'Dudu': 4728,
    'Fântânele': 4728,
    'Frăsinet': 4728,
    'Frumoasa': 4728,
    'Furculești': 4728,
    'Gălăţeni': 4728,
    'Gărăgău': 4728,
    'Gratia': 4728,
    'Guruieni': 4728,
    'Islaz': 4728,
    'Izvoarele': 4728,
    'Lăceni': 4728,
    'Lisa': 4728,
    'Liţa': 4728,
    'Lunca': 4728,
    'Măgura': 4728,
    'Măldăeni': 4728,
    'Mârzăneşti': 4728,
    'Mavrodin': 4728,
    'Merenii de Jos': 4728,
    'Merenii de Sus': 4728,
    'Merișani': 4728,
    'Moldoveni': 4728,
    'Moșteni': 4728,
    'Municipiul Alexandria': 4728,
    'Municipiul Roșiorii de Vede': 4728,
    'Municipiul Turnu Magurele': 4728,
    'Nanov': 4728,
    'Năsturelu': 4728,
    'Năvodari': 4728,
    'Necşeşti': 4728,
    'Nenciulești': 4728,
    'Olteanca': 4728,
    'Olteni': 4728,
    'Oraş Videle': 4728,
    'Oraş Zimnicea': 4728,
    'Orbeasca de Jos': 4728,
    'Orbeasca de Sus': 4728,
    'Peretu': 4728,
    'Perii Broșteni': 4728,
    'Piatra': 4728,
    'Pietroşani': 4728,
    'Pleașov': 4728,
    'Plopii Slăviţeştí': 4728,
    'Plosca': 4728,
    'Poeni': 4728,
    'Poiana': 4728,
    'Poporogi': 4728,
    'Poroschia': 4728,
    'Prundu': 4728,
    'Purani': 4728,
    'Putineiu': 4728,
    'Rădoiești-Deal': 4728,
    'Răsmireşti': 4728,
    'Roșiorii de Vede': 4728,
    'Săceni': 4728,
    'Saelele': 4728,
    'Salcia': 4728,
    'Sârbeni': 4728,
    'Scrioaştea': 4728,
    'Scurtu Mare': 4728,
    'Seaca': 4728,
    'Segarcea Vale': 4728,
    'Segarcea-Deal': 4728,
    'Sfinţeşti': 4728,
    'Siliştea': 4728,
    'Siliștea Gumești': 4728,
    'Slobozia-Mândra': 4728,
    'Smârdioasa': 4728,
    'Socetu': 4728,
    'Spătărei': 4728,
    'Stejaru': 4728,
    'Storobăneasa': 4728,
    'Suhaia': 4728,
    'Talpa-Ogrăzile': 4728,
    'Talpa-Trivalea': 4728,
    'Târnava': 4728,
    'Tătărăștii de Jos': 4728,
    'Tătărăștii de Sus': 4728,
    'Tecuci': 4728,
    'Teleormanu': 4728,
    'Ţigăneşti': 4728,
    'Traian': 4728,
    'Trivalea-Moşteni': 4728,
    'Troianul': 4728,
    'Tudor Vladimirescu': 4728,
    'Turnu Măgurele': 4728,
    'Uda-Clocociov': 4728,
    'Uda-Paciurea': 4728,
    'Udupu': 4728,
    'Valea Cireșului': 4728,
    'Valea Părului': 4728,
    'Vârtoapele de Jos': 4728,
    'Vârtoapele de Sus': 4728,
    'Vedea': 4728,
    'Videle': 4728,
    'Viişoara': 4728,
    'Vităneşti': 4728,
    'Zâmbreasca': 4728,
    'Zimnicea': 4728,
    'Zimnicele': 4728,
    'Bacova': 4748,
    'Balinţ': 4748,
    'Banloc': 4748,
    'Bara': 4748,
    'Bârna': 4748,
    'Beba Veche': 4748,
    'Becicherecu Mic': 4748,
    'Belinţ': 4748,
    'Beregsău Mare': 4748,
    'Bethausen': 4748,
    'Biled': 4748,
    'Birda': 4748,
    'Bogda': 4748,
    'Boldur': 4748,
    'Brestovăț': 4748,
    'Bucovăț': 4748,
    'Bulgăruș': 4748,
    'Buziaş': 4748,
    'Carani': 4748,
    'Cărpiniş': 4748,
    'Cenad': 4748,
    'Cenei': 4748,
    'Checea': 4748,
    'Chevereşu Mare': 4748,
    'Chișoda': 4748,
    'Ciacova': 4748,
    'Colonia Fabricii': 4748,
    'Comloşu Mare': 4748,
    'Comuna Balinţ': 4748,
    'Comuna Banloc': 4748,
    'Comuna Bara': 4748,
    'Comuna Bârna': 4748,
    'Comuna Beba Veche': 4748,
    'Comuna Becicherecu Mic': 4748,
    'Comuna Belinţ': 4748,
    'Comuna Bethausen': 4748,
    'Comuna Biled': 4748,
    'Comuna Birda': 4748,
    'Comuna Bogda': 4748,
    'Comuna Boldur': 4748,
    'Comuna Brestovăţ': 4748,
    'Comuna Bucovăţ': 4748,
    'Comuna Cărpiniş': 4748,
    'Comuna Cenad': 4748,
    'Comuna Cenei': 4748,
    'Comuna Checea': 4748,
    'Comuna Chevereşu Mare': 4748,
    'Comuna Comloşu Mare': 4748,
    'Comuna Coşteiu': 4748,
    'Comuna Criciova': 4748,
    'Comuna Curtea': 4748,
    'Comuna Darova': 4748,
    'Comuna Denta': 4748,
    'Comuna Dudeştii Noi': 4748,
    'Comuna Dudeştii Vechi': 4748,
    'Comuna Dumbrava': 4748,
    'Comuna Dumbrăviţa': 4748,
    'Comuna Fârdea': 4748,
    'Comuna Fibiş': 4748,
    'Comuna Foeni': 4748,
    'Comuna Gavojdia': 4748,
    'Comuna Ghilad': 4748,
    'Comuna Ghiroda': 4748,
    'Comuna Ghizela': 4748,
    'Comuna Giarmata': 4748,
    'Comuna Giera': 4748,
    'Comuna Giroc': 4748,
    'Comuna Giulvăz': 4748,
    'Comuna Gottlob': 4748,
    'Comuna Iecea Mare': 4748,
    'Comuna Jamu Mare': 4748,
    'Comuna Jebel': 4748,
    'Comuna Lenauheim': 4748,
    'Comuna Liebling': 4748,
    'Comuna Livezile': 4748,
    'Comuna Lovrin': 4748,
    'Comuna Mănăştiur': 4748,
    'Comuna Margina': 4748,
    'Comuna Maşloc': 4748,
    'Comuna Moraviţa': 4748,
    'Comuna Moşniţa Nouã': 4748,
    'Comuna Nădrag': 4748,
    'Comuna Niţchidorf': 4748,
    'Comuna Ohaba Lungă': 4748,
    'Comuna Orţişoara': 4748,
    'Comuna Otelec': 4748,
    'Comuna Pădureni': 4748,
    'Comuna Parţa': 4748,
    'Comuna Peciu Nou': 4748,
    'Comuna Periam': 4748,
    'Comuna Pesac': 4748,
    'Comuna Pietroasa': 4748,
    'Comuna Pişchia': 4748,
    'Comuna Racoviţa': 4748,
    'Comuna Remetea Mare': 4748,
    'Comuna Săcălaz': 4748,
    'Comuna Sacoşu Turcesc': 4748,
    'Comuna Şag': 4748,
    'Comuna Sânandrei': 4748,
    'Comuna Şandra': 4748,
    'Comuna Sânmihaiu Român': 4748,
    'Comuna Sânpetru Mare': 4748,
    'Comuna Saravale': 4748,
    'Comuna Satchinez': 4748,
    'Comuna Secaş': 4748,
    'Comuna Ştiuca': 4748,
    'Comuna Teremia Mare': 4748,
    'Comuna Tomeşti': 4748,
    'Comuna Tomnatic': 4748,
    'Comuna Topolovãţu Mare': 4748,
    'Comuna Tormac': 4748,
    'Comuna Traian Vuia': 4748,
    'Comuna Uivar': 4748,
    'Comuna Vălcani': 4748,
    'Comuna Variaş': 4748,
    'Comuna Victor Vlad Delamarina': 4748,
    'Comuna Voiteg': 4748,
    'Coşteiu': 4748,
    'Criciova': 4748,
    'Curtea': 4748,
    'Darova': 4748,
    'Darova Nouă': 4748,
    'Denta': 4748,
    'Deta': 4748,
    'Dudeștii Noi': 4748,
    'Dudeştii Vechi': 4748,
    'Dumbrava': 4748,
    'Dumbrăviţa': 4748,
    'Făget': 4748,
    'Fârdea': 4748,
    'Fibiș': 4748,
    'Foeni': 4748,
    'Gătaia': 4748,
    'Gavojdia': 4748,
    'Gelu': 4748,
    'Ghilad': 4748,
    'Ghiroda': 4748,
    'Ghizela': 4748,
    'Giarmata': 4748,
    'Giarmata-Vii': 4748,
    'Giera': 4748,
    'Giroc': 4748,
    'Giulvăz': 4748,
    'Gottlob': 4748,
    'Grabăț': 4748,
    'Hodoni': 4748,
    'Iecea Mare': 4748,
    'Iecea Mică': 4748,
    'Igriș': 4748,
    'Izvin': 4748,
    'Jamu Mare': 4748,
    'Jebel': 4748,
    'Jimbolia': 4748,
    'Lenauheim': 4748,
    'Liebling': 4748,
    'Livezile': 4748,
    'Lovrin': 4748,
    'Lugoj': 4748,
    'Mănăştiur': 4748,
    'Margina': 4748,
    'Maşloc': 4748,
    'Moraviţa': 4748,
    'Moşniţa Nouă': 4748,
    'Moșnița Veche': 4748,
    'Municipiul Lugoj': 4748,
    'Municipiul Timişoara': 4748,
    'Nădrag': 4748,
    'Nerău': 4748,
    'Niţchidorf': 4748,
    'Ohaba Lungă': 4748,
    'Oraş Buziaş': 4748,
    'Oraş Ciacova': 4748,
    'Oraş Deta': 4748,
    'Oraş Fãget': 4748,
    'Oraş Gãtaia': 4748,
    'Oraş Jimbolia': 4748,
    'Oraş Recaş': 4748,
    'Oraş Sânnicolau Mare': 4748,
    'Orţişoara': 4748,
    'Otelec': 4748,
    'Pădureni': 4748,
    'Parța': 4748,
    'Peciu Nou': 4748,
    'Periam': 4748,
    'Pesac': 4748,
    'Pietroasa': 4748,
    'Pişchia': 4748,
    'Răchita': 4748,
    'Racoviţa': 4748,
    'Recaş': 4748,
    'Remetea Mare': 4748,
    'Săcălaz': 4748,
    'Sacoșu Mare': 4748,
    'Sacoşu Turcesc': 4748,
    'Şag': 4748,
    'Sânandrei': 4748,
    'Şandra': 4748,
    'Sânmihaiu Român': 4748,
    'Sânnicolau Mare': 4748,
    'Sânpetru Mare': 4748,
    'Saravale': 4748,
    'Satchinez': 4748,
    'Sculia': 4748,
    'Secaş': 4748,
    'Ştiuca': 4748,
    'Teremia Mare': 4748,
    'Timişoara': 4748,
    'Tomeşti': 4748,
    'Tomnatic': 4748,
    'Topolovăţu Mare': 4748,
    'Tormac': 4748,
    'Traian Vuia': 4748,
    'Uivar': 4748,
    'Urseni': 4748,
    'Utvin': 4748,
    'Vălcani': 4748,
    'Variaş': 4748,
    'Victor Vlad Delamarina': 4748,
    'Voiteg': 4748,
    'Agighiol': 4727,
    'Babadag': 4727,
    'Baia': 4727,
    'Beidaud': 4727,
    'Beștepe': 4727,
    'C.A. Rosetti': 4727,
    'Carcaliu': 4727,
    'Casimcea': 4727,
    'Cataloi': 4727,
    'Ceamurlia de Jos': 4727,
    'Ceamurlia de Sus': 4727,
    'Ceatalchioi': 4727,
    'Cerna': 4727,
    'Chilia Veche': 4727,
    'Ciucurova': 4727,
    'Comuna Baia': 4727,
    'Comuna Beidaud': 4727,
    'Comuna Beştepe': 4727,
    'Comuna C.A. Rosetti': 4727,
    'Comuna Carcaliu': 4727,
    'Comuna Casimcea': 4727,
    'Comuna Ceamurlia de Jos': 4727,
    'Comuna Ceatalchioi': 4727,
    'Comuna Cerna': 4727,
    'Comuna Chilia Veche': 4727,
    'Comuna Ciucurova': 4727,
    'Comuna Crişan': 4727,
    'Comuna Dăeni': 4727,
    'Comuna Dorobanţu': 4727,
    'Comuna Frecăţei': 4727,
    'Comuna Greci': 4727,
    'Comuna Grindu': 4727,
    'Comuna Hamcearca': 4727,
    'Comuna Horia': 4727,
    'Comuna I.C.Brãtianu': 4727,
    'Comuna Izvoarele': 4727,
    'Comuna Jijila': 4727,
    'Comuna Jurilovca': 4727,
    'Comuna Luncaviţa': 4727,
    'Comuna Mahmudia': 4727,
    'Comuna Maliuc': 4727,
    'Comuna Mihai Bravu': 4727,
    'Comuna Mihail Kogălniceanu': 4727,
    'Comuna Murighiol': 4727,
    'Comuna Nalbant': 4727,
    'Comuna Niculiţel': 4727,
    'Comuna Nufăru': 4727,
    'Comuna Ostrov': 4727,
    'Comuna Pardina': 4727,
    'Comuna Peceneaga': 4727,
    'Comuna Sarichioi': 4727,
    'Comuna Sfântu Gheorghe': 4727,
    'Comuna Slava Cercheză': 4727,
    'Comuna Smârdan': 4727,
    'Comuna Somova': 4727,
    'Comuna Stejaru': 4727,
    'Comuna Topolog': 4727,
    'Comuna Turcoaia': 4727,
    'Comuna Văcăreni': 4727,
    'Comuna Valea Nucarilor': 4727,
    'Comuna Valea Teilor': 4727,
    'Crişan': 4727,
    'Dăeni': 4727,
    'Dorobanţu': 4727,
    'Enisala': 4727,
    'Frecăţei': 4727,
    'Garvăn': 4727,
    'Greci': 4727,
    'Grindu': 4727,
    'Hamcearca': 4727,
    'Horia': 4727,
    'I. C. Brătianu': 4727,
    'Iazurile': 4727,
    'Isaccea': 4727,
    'Izvoarele': 4727,
    'Jijila': 4727,
    'Jurilovca': 4727,
    'Lunca': 4727,
    'Luncaviţa': 4727,
    'Măcin': 4727,
    'Mahmudia': 4727,
    'Malcoci': 4727,
    'Maliuc': 4727,
    'Mihai Bravu': 4727,
    'Mihail Kogălniceanu': 4727,
    'Mineri': 4727,
    'Municipiul Tulcea': 4727,
    'Murighiol': 4727,
    'Nalbant': 4727,
    'Nicolae Bălcescu': 4727,
    'Niculiţel': 4727,
    'Nufăru': 4727,
    'Oraş Babadag': 4727,
    'Oraş Isaccea': 4727,
    'Oraş Mãcin': 4727,
    'Oraş Sulina': 4727,
    'Ostrov': 4727,
    'Pardina': 4727,
    'Peceneaga': 4727,
    'Rachelu': 4727,
    'Sălcioara': 4727,
    'Sarichioi': 4727,
    'Sfântu Gheorghe': 4727,
    'Slava Cercheză': 4727,
    'Slava Rusă': 4727,
    'Smârdan': 4727,
    'Somova': 4727,
    'Stejaru': 4727,
    'Sulina': 4727,
    'Topolog': 4727,
    'Traian': 4727,
    'Tulcea': 4727,
    'Turcoaia': 4727,
    'Turda': 4727,
    'Văcăreni': 4727,
    'Valea Nucarilor': 4727,
    'Valea Teilor': 4727,
    'Zebil': 4727,
    'Alunu': 4757,
    'Amărăşti': 4757,
    'Băbeni': 4757,
    'Băbeni-Oltețu': 4757,
    'Băile Govora': 4757,
    'Băile Olăneşti': 4757,
    'Bălceşti': 4757,
    'Bărbăteşti': 4757,
    'Batârăşti': 4757,
    'Berbeşti': 4757,
    'Berislăveşti': 4757,
    'Bistrița': 4757,
    'Bodești': 4757,
    'Boişoara': 4757,
    'Brezoi': 4757,
    'Budeşti': 4757,
    'Bujoreni': 4757,
    'Buneşti': 4757,
    'Câinenii Mici': 4757,
    'Călimăneşti': 4757,
    'Călina': 4757,
    'Cernişoara': 4757,
    'Cheia': 4757,
    'Comuna Alunu': 4757,
    'Comuna Amărăşti': 4757,
    'Comuna Bărbăteşti': 4757,
    'Comuna Berislăveşti': 4757,
    'Comuna Boişoara': 4757,
    'Comuna Budeşti': 4757,
    'Comuna Bujoreni': 4757,
    'Comuna Buneşti': 4757,
    'Comuna Câineni': 4757,
    'Comuna Cernişoara': 4757,
    'Comuna Copăceni': 4757,
    'Comuna Costeşti': 4757,
    'Comuna Creţeni': 4757,
    'Comuna Dăeşti': 4757,
    'Comuna Dănicei': 4757,
    'Comuna Diculeşti': 4757,
    'Comuna Drăgoeşti': 4757,
    'Comuna Fârtãţeşti': 4757,
    'Comuna Făureşti': 4757,
    'Comuna Frânceşti-Mânăstireni': 4757,
    'Comuna Galicea': 4757,
    'Comuna Ghioroiu': 4757,
    'Comuna Glăvile': 4757,
    'Comuna Goleşti': 4757,
    'Comuna Grădiştea': 4757,
    'Comuna Guşoeni': 4757,
    'Comuna Ioneşti': 4757,
    'Comuna Lăcusteni': 4757,
    'Comuna Lădeşti': 4757,
    'Comuna Laloşu': 4757,
    'Comuna Lăpuşata': 4757,
    'Comuna Livezi': 4757,
    'Comuna Lungeşti': 4757,
    'Comuna Măciuca': 4757,
    'Comuna Mădulari-Beica': 4757,
    'Comuna Malaia': 4757,
    'Comuna Măldăreşti': 4757,
    'Comuna Mateeşti': 4757,
    'Comuna Mihăeşti': 4757,
    'Comuna Milcoiu': 4757,
    'Comuna Mitrofani': 4757,
    'Comuna Muereasca': 4757,
    'Comuna Nicolae Bãlcescu': 4757,
    'Comuna Olanu': 4757,
    'Comuna Orleşti': 4757,
    'Comuna Oteşani': 4757,
    'Comuna Păuşeşti': 4757,
    'Comuna Păuşeşti-Măglaşi': 4757,
    'Comuna Perişani': 4757,
    'Comuna Pesceana': 4757,
    'Comuna Pietrari': 4757,
    'Comuna Popeşti': 4757,
    'Comuna Prundeni': 4757,
    'Comuna Racoviţa': 4757,
    'Comuna Roeşti': 4757,
    'Comuna Roşiile': 4757,
    'Comuna Runcu': 4757,
    'Comuna Sălătrucel': 4757,
    'Comuna Scundu': 4757,
    'Comuna Sineşti': 4757,
    'Comuna Şirineasa': 4757,
    'Comuna Slătioara': 4757,
    'Comuna Stăneşti': 4757,
    'Comuna Ştefăneşti': 4757,
    'Comuna Stoeneşti': 4757,
    'Comuna Stoileşti': 4757,
    'Comuna Stroeşti': 4757,
    'Comuna Şuşani': 4757,
    'Comuna Suteşti': 4757,
    'Comuna Tetoiu': 4757,
    'Comuna Titeşti': 4757,
    'Comuna Tomşani': 4757,
    'Comuna Vaideeni': 4757,
    'Comuna Valea Mare': 4757,
    'Comuna Vlădeşti': 4757,
    'Comuna Voiceşti': 4757,
    'Comuna Voineasa': 4757,
    'Comuna Zătreni': 4757,
    'Copăcelu': 4757,
    'Copăceni': 4757,
    'Coșani': 4757,
    'Costeşti': 4757,
    'Creţeni': 4757,
    'Dăeşti': 4757,
    'Dealu Aluniș': 4757,
    'Dealu Dănicei': 4757,
    'Diculești': 4757,
    'Dobrușa': 4757,
    'Drăgăşani': 4757,
    'Drăgoeşti': 4757,
    'Fărtăţeşti': 4757,
    'Făureşti': 4757,
    'Galicea': 4757,
    'Ghioroiu': 4757,
    'Glăvile': 4757,
    'Goranu': 4757,
    'Grădiştea': 4757,
    'Greblești': 4757,
    'Gura Suhașului': 4757,
    'Guşoeni': 4757,
    'Horezu': 4757,
    'Igoiu': 4757,
    'Ioneşti': 4757,
    'Jiblea Veche': 4757,
    'Lăcusteni': 4757,
    'Lădeşti': 4757,
    'Laloşu': 4757,
    'Livadia': 4757,
    'Livezi': 4757,
    'Lungeşti': 4757,
    'Mădulari': 4757,
    'Malaia': 4757,
    'Măldăreşti': 4757,
    'Mănăilești': 4757,
    'Mateeşti': 4757,
    'Mihăeşti': 4757,
    'Milcoiu': 4757,
    'Mitrofani': 4757,
    'Mogești': 4757,
    'Muereasca': 4757,
    'Municipiul Drãgãşani': 4757,
    'Municipiul Râmnicu Vâlcea': 4757,
    'Olănești': 4757,
    'Olanu': 4757,
    'Oraş Bãbeni': 4757,
    'Oraş Bãile Govora': 4757,
    'Oraş Bãile Olãneşti': 4757,
    'Oraş Bãlceşti': 4757,
    'Oraş Berbeşti': 4757,
    'Oraş Brezoi': 4757,
    'Oraş Cãlimãneşti': 4757,
    'Oraş Horezu': 4757,
    'Oraş Ocnele Mari': 4757,
    'Orleşti': 4757,
    'Oteşani': 4757,
    'Otetelișu': 4757,
    'Oveselu': 4757,
    'Păuşeşti': 4757,
    'Păuşeşti-Măglaşi': 4757,
    'Perişani': 4757,
    'Pesceana': 4757,
    'Pietrari': 4757,
    'Pietrarii de Sus': 4757,
    'Popeşti': 4757,
    'Prajila': 4757,
    'Prundeni': 4757,
    'Racoviţa': 4757,
    'Râmești': 4757,
    'Râmnicu Vâlcea': 4757,
    'Roeşti': 4757,
    'Români': 4757,
    'Roşiile': 4757,
    'Runcu': 4757,
    'Sălătrucel': 4757,
    'Scundu': 4757,
    'Sineşti': 4757,
    'Şirineasa': 4757,
    'Stănești': 4757,
    'Ştefăneşti': 4757,
    'Stoeneşti': 4757,
    'Stoileşti': 4757,
    'Stolniceni': 4757,
    'Stroeşti': 4757,
    'Şuşani': 4757,
    'Sutești': 4757,
    'Tetoiu': 4757,
    'Titești': 4757,
    'Tomşani': 4757,
    'Turcești': 4757,
    'Urși': 4757,
    'Vaideeni': 4757,
    'Valea Mare': 4757,
    'Vlădeşti': 4757,
    'Voiceşti': 4757,
    'Voineasa': 4757,
    'Zătreni': 4757,
    'Zlătărei': 4757,
    'Albeşti': 4752,
    'Alexandru Vlăhuţă': 4752,
    'Arsura': 4752,
    'Băcani': 4752,
    'Băceşti': 4752,
    'Bădeana': 4752,
    'Băile Drânceni': 4752,
    'Bălteni': 4752,
    'Banca': 4752,
    'Bârlad': 4752,
    'Bârzești': 4752,
    'Berezeni': 4752,
    'Blăgeşti': 4752,
    'Bogdana': 4752,
    'Bogdănești': 4752,
    'Bogdăniţa': 4752,
    'Boţeşti': 4752,
    'Brodoc': 4752,
    'Buda': 4752,
    'Buneşti': 4752,
    'Cârja': 4752,
    'Chircești': 4752,
    'Chițoc': 4752,
    'Ciocani': 4752,
    'Codăeşti': 4752,
    'Comuna Albeşti': 4752,
    'Comuna Alexandru Vlăhuţă': 4752,
    'Comuna Arsura': 4752,
    'Comuna Băcani': 4752,
    'Comuna Băceşti': 4752,
    'Comuna Bălteni': 4752,
    'Comuna Banca': 4752,
    'Comuna Berezeni': 4752,
    'Comuna Blăgeşti': 4752,
    'Comuna Bogdana': 4752,
    'Comuna Bogdăneşti': 4752,
    'Comuna Bogdăniţa': 4752,
    'Comuna Boţeşti': 4752,
    'Comuna Bunești Averești': 4752,
    'Comuna Ciocani': 4752,
    'Comuna Codăeşti': 4752,
    'Comuna Coroieşti': 4752,
    'Comuna Costeşti': 4752,
    'Comuna Cozmeşti': 4752,
    'Comuna Creţeşti': 4752,
    'Comuna Dăneşti': 4752,
    'Comuna Deleni': 4752,
    'Comuna Deleşti': 4752,
    'Comuna Dimitrie Cantemir': 4752,
    'Comuna Dodeşti': 4752,
    'Comuna Dragomireşti': 4752,
    'Comuna Drânceni': 4752,
    'Comuna Duda  Epureni': 4752,
    'Comuna Dumeşti': 4752,
    'Comuna Epureni': 4752,
    'Comuna Fălciu': 4752,
    'Comuna Fereşti': 4752,
    'Comuna Fruntişeni': 4752,
    'Comuna Găgeşti': 4752,
    'Comuna Gârceni': 4752,
    'Comuna Ghergheşti': 4752,
    'Comuna Griviţa': 4752,
    'Comuna Hoceni': 4752,
    'Comuna Iana': 4752,
    'Comuna Ibăneşti': 4752,
    'Comuna Ivăneşti': 4752,
    'Comuna Iveşti': 4752,
    'Comuna Laza': 4752,
    'Comuna Lipovăţ': 4752,
    'Comuna Lunca Banului': 4752,
    'Comuna Măluşteni': 4752,
    'Comuna Micleşti': 4752,
    'Comuna Muntenii de Jos': 4752,
    'Comuna Muntenii de Sus': 4752,
    'Comuna Olteneşti': 4752,
    'Comuna Oşeşti': 4752,
    'Comuna Pădureni': 4752,
    'Comuna Perieni': 4752,
    'Comuna Pochidia': 4752,
    'Comuna Pogana': 4752,
    'Comuna Pogoneşti': 4752,
    'Comuna Poieneşti': 4752,
    'Comuna Puieşti': 4752,
    'Comuna Pungeşti': 4752,
    'Comuna Puşcaşi': 4752,
    'Comuna Rafaila': 4752,
    'Comuna Rebricea': 4752,
    'Comuna Roşieşti': 4752,
    'Comuna Soleşti': 4752,
    'Comuna Stănileşti': 4752,
    'Comuna Ştefan cel Mare': 4752,
    'Comuna Şuletea': 4752,
    'Comuna Tăcuta': 4752,
    'Comuna Tanacu': 4752,
    'Comuna Tătărăni': 4752,
    'Comuna Todireşti': 4752,
    'Comuna Tutova': 4752,
    'Comuna Vãleni': 4752,
    'Comuna Vetrişoaia': 4752,
    'Comuna Viişoara': 4752,
    'Comuna Vinderei': 4752,
    'Comuna Voineşti': 4752,
    'Comuna Vultureşti': 4752,
    'Comuna Vutcani': 4752,
    'Comuna Zăpodeni': 4752,
    'Comuna Zorleni': 4752,
    'Corni-Albești': 4752,
    'Coroieşti': 4752,
    'Costeşti': 4752,
    'Cozmești': 4752,
    'Crețești': 4752,
    'Dăneşti': 4752,
    'Deleni': 4752,
    'Deleşti': 4752,
    'Dodești': 4752,
    'Dragomireşti': 4752,
    'Drânceni': 4752,
    'Duda': 4752,
    'Dumeşti': 4752,
    'Emil Racoviță': 4752,
    'Epureni': 4752,
    'Fălciu': 4752,
    'Fâstâci': 4752,
    'Ferești': 4752,
    'Fruntișeni': 4752,
    'Găgeşti': 4752,
    'Gârceni': 4752,
    'Ghergheşti': 4752,
    'Ghermănești': 4752,
    'Griviţa': 4752,
    'Gugești': 4752,
    'Hălărești': 4752,
    'Hoceni': 4752,
    'Hurdugi': 4752,
    'Huşi': 4752,
    'Iana': 4752,
    'Ibănești': 4752,
    'Ivănești': 4752,
    'Iveşti': 4752,
    'Laza': 4752,
    'Lipovăţ': 4752,
    'Lunca Banului': 4752,
    'Măluşteni': 4752,
    'Micleşti': 4752,
    'Moara Domnească': 4752,
    'Moara Grecilor': 4752,
    'Municipiul Bârlad': 4752,
    'Municipiul Huşi': 4752,
    'Municipiul Vaslui': 4752,
    'Muntenii de Jos': 4752,
    'Muntenii de Sus': 4752,
    'Murgeni': 4752,
    'Negreşti': 4752,
    'Olteneşti': 4752,
    'Oraș Murgeni': 4752,
    'Oraş Negreşti': 4752,
    'Oşeşti': 4752,
    'Pădureni': 4752,
    'Perieni': 4752,
    'Pochidia': 4752,
    'Pogana': 4752,
    'Pogănești': 4752,
    'Pogonești': 4752,
    'Poieneşti': 4752,
    'Popeni': 4752,
    'Pribești': 4752,
    'Puieşti': 4752,
    'Pungeşti': 4752,
    'Pușcași': 4752,
    'Rafaila': 4752,
    'Rânzești': 4752,
    'Râșești': 4752,
    'Rebricea': 4752,
    'Rediu': 4752,
    'Roşieşti': 4752,
    'Satu Nou': 4752,
    'Sauca': 4752,
    'Soleşti': 4752,
    'Stănileşti': 4752,
    'Ştefan cel Mare': 4752,
    'Şuletea': 4752,
    'Tăcuta': 4752,
    'Tanacu': 4752,
    'Tătărăni': 4752,
    'Todireşti': 4752,
    'Trestiana': 4752,
    'Tutova': 4752,
    'Valea Grecului': 4752,
    'Văleni': 4752,
    'Vaslui': 4752,
    'Vetrişoaia': 4752,
    'Viişoara': 4752,
    'Vinderei': 4752,
    'Voineşti': 4752,
    'Vultureşti': 4752,
    'Vutcani': 4752,
    'Zăpodeni': 4752,
    'Zorleni': 4752,
    'Adjud': 4758,
    'Adjudu Vechi': 4758,
    'Andreiaşu de Jos': 4758,
    'Anghelești': 4758,
    'Băleşti': 4758,
    'Bârseşti': 4758,
    'Biliești': 4758,
    'Bogheşti': 4758,
    'Bogza': 4758,
    'Boloteşti': 4758,
    'Bordeasca Veche': 4758,
    'Bordeşti': 4758,
    'Broşteni': 4758,
    'Budești': 4758,
    'Burca': 4758,
    'Câmpineanca': 4758,
    'Câmpuri': 4758,
    'Câmpurile de Jos': 4758,
    'Cârligele': 4758,
    'Ceardac': 4758,
    'Chiojdeni': 4758,
    'Ciorani': 4758,
    'Ciorăşti': 4758,
    'Ciușlea': 4758,
    'Comuna Andreiaşu de Jos': 4758,
    'Comuna Băleşti': 4758,
    'Comuna Bârseşti': 4758,
    'Comuna Bilieşti': 4758,
    'Comuna Bogheşti': 4758,
    'Comuna Boloteşti': 4758,
    'Comuna Bordeşti': 4758,
    'Comuna Broşteni': 4758,
    'Comuna Câmpineanca': 4758,
    'Comuna Câmpuri': 4758,
    'Comuna Cârligele': 4758,
    'Comuna Chiojdeni': 4758,
    'Comuna Ciorăşti': 4758,
    'Comuna Corbiţa': 4758,
    'Comuna Coteşti': 4758,
    'Comună Dumbrăveni': 4758,
    'Comuna Dumitreşti': 4758,
    'Comuna Fitioneşti': 4758,
    'Comuna Garoafa': 4758,
    'Comuna Goleşti': 4758,
    'Comuna Gologanu': 4758,
    'Comuna Gugeşti': 4758,
    'Comuna Gura Caliţei': 4758,
    'Comuna Homocea': 4758,
    'Comuna Jariştea': 4758,
    'Comuna Jitia': 4758,
    'Comuna Măicăneşti': 4758,
    'Comuna Mera': 4758,
    'Comuna Milcovul': 4758,
    'Comuna Moviliţa': 4758,
    'Comuna Năneşti': 4758,
    'Comuna Năruja': 4758,
    'Comuna Negrileşti': 4758,
    'Comuna Nereju': 4758,
    'Comuna Nistoreşti': 4758,
    'Comuna Obrejiţa': 4758,
    'Comuna Paltin': 4758,
    'Comuna Păuleşti': 4758,
    'Comuna Păuneşti': 4758,
    'Comuna Ploscuţeni': 4758,
    'Comuna Poiana Cristei': 4758,
    'Comuna Popeşti': 4758,
    'Comuna Pufeşti': 4758,
    'Comuna Răcoasa': 4758,
    'Comuna Răstoaca': 4758,
    'Comuna Reghiu': 4758,
    'Comuna Rugineşti': 4758,
    'Comuna Sihlea': 4758,
    'Comuna Slobozia Bradului': 4758,
    'Comuna Slobozia-Ciorăşti': 4758,
    'Comuna Soveja': 4758,
    'Comuna Spulber': 4758,
    'Comuna Străoane': 4758,
    'Comuna Suraia': 4758,
    'Comuna Tâmboeşti': 4758,
    'Comuna Tănăsoaia': 4758,
    'Comuna Tãtãranu': 4758,
    'Comuna Ţifeşti': 4758,
    'Comuna Tulnici': 4758,
    'Comuna Urecheşti': 4758,
    'Comuna Valea Sării': 4758,
    'Comuna Vânători': 4758,
    'Comuna Vârteşcoiu': 4758,
    'Comuna Vidra': 4758,
    'Comuna Vintileasca': 4758,
    'Comuna Vizantea-Livezi': 4758,
    'Comuna Vrâncioaia': 4758,
    'Comuna Vulturu': 4758,
    'Corbiţa': 4758,
    'Cornetu': 4758,
    'Coteşti': 4758,
    'Domnești-Târg': 4758,
    'Dragosloveni': 4758,
    'Dumbrăveni': 4758,
    'Dumitrești': 4758,
    'Fitioneşti': 4758,
    'Focșani': 4758,
    'Găgești': 4758,
    'Garoafa': 4758,
    'Goleşti': 4758,
    'Gologanu': 4758,
    'Gugeşti': 4758,
    'Gura Caliţei': 4758,
    'Homocea': 4758,
    'Igești': 4758,
    'Irești': 4758,
    'Jariştea': 4758,
    'Jitia': 4758,
    'Jorăști': 4758,
    'Lespezi': 4758,
    'Măicăneşti': 4758,
    'Mărășești': 4758,
    'Mătăcina': 4758,
    'Mera': 4758,
    'Mihălceni': 4758,
    'Milcovul': 4758,
    'Mirceștii Noi': 4758,
    'Moviliţa': 4758,
    'Municipiul  Adjud': 4758,
    'Municipiul Focşani': 4758,
    'Năneşti': 4758,
    'Năruja': 4758,
    'Negrilești': 4758,
    'Nereju': 4758,
    'Nereju Mic': 4758,
    'Nistoreşti': 4758,
    'Obrejița': 4758,
    'Odobeşti': 4758,
    'Oleșești': 4758,
    'Oraş Mãrãşeşti': 4758,
    'Oraş Odobeşti': 4758,
    'Oraş Panciu': 4758,
    'Paltin': 4758,
    'Panciu': 4758,
    'Păulești': 4758,
    'Păuneşti': 4758,
    'Ploscuțeni': 4758,
    'Poiana Cristei': 4758,
    'Popești': 4758,
    'Pufeşti': 4758,
    'Răcoasa': 4758,
    'Râmniceni': 4758,
    'Răstoaca': 4758,
    'Reghiu': 4758,
    'Rucăreni': 4758,
    'Rugineşti': 4758,
    'Sihlea': 4758,
    'Șindrilari': 4758,
    'Slobozia Bradului': 4758,
    'Slobozia-Câmpineanca': 4758,
    'Slobozia-Ciorăşti': 4758,
    'Spulber': 4758,
    'Străoane': 4758,
    'Suraia': 4758,
    'Tâmboeşti': 4758,
    'Tănăsoaia': 4758,
    'Tătăranu': 4758,
    'Ţifeşti': 4758,
    'Tulnici': 4758,
    'Unirea': 4758,
    'Urecheşti': 4758,
    'Vadu Roșca': 4758,
    'Valea Sării': 4758,
    'Vânători': 4758,
    'Vârteşcoiu': 4758,
    'Vidra': 4758,
    'Viișoara': 4758,
    'Vintileasca': 4758,
    'Vizantea-Mânăstirească': 4758,
    'Voetin': 4758,
    'Vrâncioaia': 4758,
    'Vulcăneasa': 4758,
    'Vulturu': 4758,
    'Aleysk': 1911,
    'Aleyskiy Rayon': 1911,
    'Altayskoye': 1911,
    'Anton’yevka': 1911,
    'Aya': 1911,
    'Barnaul': 1911,
    'Barnaul Urban Okrug': 1911,
    'Bastan': 1911,
    'Bayevo': 1911,
    'Bayunovskiye Klyuchi': 1911,
    'Belokurikha': 1911,
    'Beloyarsk': 1911,
    'Berëzovka': 1911,
    'Biysk': 1911,
    'Blagoveshchenka': 1911,
    'Bobrovka': 1911,
    'Borovikha': 1911,
    'Burla': 1911,
    'Bystryanka': 1911,
    'Bystryy Istok': 1911,
    'Charyshskoye': 1911,
    'Cheremnoye': 1911,
    'Gal’bshtadt': 1911,
    'Gon’ba': 1911,
    'Gornyak': 1911,
    'Grishkovka': 1911,
    'Inya': 1911,
    'Kalmanka': 1911,
    'Kalmanskiy Rayon': 1911,
    'Kamen’-na-Obi': 1911,
    'Khabarskiy Rayon': 1911,
    'Khabary': 1911,
    'Klyuchi': 1911,
    'Kosikha': 1911,
    'Kosikhinskiy Rayon': 1911,
    'Krasnogorskoye': 1911,
    'Krasnoshchekovo': 1911,
    'Krasnyy Yar': 1911,
    'Krutikha': 1911,
    'Kulunda': 1911,
    'Kulundinskiy Rayon': 1911,
    'Kur’inskiy Rayon': 1911,
    'Kusak': 1911,
    'Kytmanovo': 1911,
    'Lebyazh’ye': 1911,
    'Lesnoye': 1911,
    'Logovskoye': 1911,
    'Loktevskiy Rayon': 1911,
    'Malinovoye Ozero': 1911,
    'Malougrenevo': 1911,
    'Mamontovo': 1911,
    'Mamontovskiy Rayon': 1911,
    'Martynovo': 1911,
    'Mikhaylovskiy Rayon': 1911,
    'Mikhaylovskoye': 1911,
    'Nagornyy': 1911,
    'Nalobikha': 1911,
    'Natsional’nyy Rayon Nemetskiy': 1911,
    'Nauchnyy Gorodok': 1911,
    'Nikolayevka': 1911,
    'Novichikha': 1911,
    'Novichikhinskiy Rayon': 1911,
    'Novikovo': 1911,
    'Novoaltaysk': 1911,
    'Novosilikatnyy': 1911,
    'Novotyryshkino': 1911,
    'Novyye Zori': 1911,
    'Pankrushikha': 1911,
    'Pankrushikhinskiy Rayon': 1911,
    'Pavlovsk': 1911,
    'Pavlovskiy Rayon': 1911,
    'Pervomayskiy': 1911,
    'Pervomayskiy Rayon': 1911,
    'Pervomayskoye': 1911,
    'Petropavlovskiy Rayon': 1911,
    'Petropavlovskoye': 1911,
    'Podsosnovo': 1911,
    'Pospelikha': 1911,
    'Pospelikhinskiy Rayon': 1911,
    'Rebrikha': 1911,
    'Rebrikhinskiy Rayon': 1911,
    'Rodino': 1911,
    'Rodinskiy Rayon': 1911,
    'Romanovo': 1911,
    'Romanovskiy Rayon': 1911,
    'Rubtsovsk': 1911,
    'Rubtsovskiy Rayon': 1911,
    'Sannikovo': 1911,
    'Severka': 1911,
    'Shakhi': 1911,
    'Shelabolikha': 1911,
    'Shelabolikhinskiy Rayon': 1911,
    'Shipunovo': 1911,
    'Shipunovskiy Rayon': 1911,
    'Shubenka': 1911,
    'Shul’gin Log': 1911,
    'Sibirskiy': 1911,
    'Slavgorod': 1911,
    'Slavgorodskoye': 1911,
    'Smolenskiy Rayon': 1911,
    'Smolenskoye': 1911,
    'Sokolovo': 1911,
    'Soloneshenskiy Rayon': 1911,
    'Soloneshnoye': 1911,
    'Solton': 1911,
    'Soltonskiy Rayon': 1911,
    'Sorokino': 1911,
    'Sovetskoye': 1911,
    'Srostki': 1911,
    'Stan-Bekhtemir': 1911,
    'Staroaleyskoye': 1911,
    'Starobelokurikha': 1911,
    'Staryy Togul': 1911,
    'Stukovo': 1911,
    'Sychëvka': 1911,
    'Tabuny': 1911,
    'Tal’menka': 1911,
    'Tal’menskiy Rayon': 1911,
    'Togul': 1911,
    'Togul’skiy Rayon': 1911,
    'Topchikha': 1911,
    'Topol’noye': 1911,
    'Troitskoye': 1911,
    'Tselinnoye': 1911,
    'Tyumentsevo': 1911,
    'Tyumentsevskiy Rayon': 1911,
    'Uglovskoye': 1911,
    'Ust’-Charyshskaya Pristan’': 1911,
    'Ust’-Isha': 1911,
    'Ust’-Kalmanka': 1911,
    'Ust’yanka': 1911,
    'Usyatskoye': 1911,
    'Verkh-Katunskoye': 1911,
    'Verkh-Suetka': 1911,
    'Verkhniy Bekhtemir': 1911,
    'Veseloyarsk': 1911,
    'Vlasikha': 1911,
    'Volchikha': 1911,
    'Volchikhinskiy Rayon': 1911,
    'Yarovoye': 1911,
    'Yegor’yevskiy Rayon': 1911,
    'Yel’tsovskiy Rayon': 1911,
    'Yuzhnyy': 1911,
    'Zalesovo': 1911,
    'Zarinsk': 1911,
    'Zarinskiy Rayon': 1911,
    'Zarya': 1911,
    'Zaton': 1911,
    'Zav’yalovo': 1911,
    'Zav’yalovskiy Rayon': 1911,
    'Zmeinogorsk': 1911,
    'Zonal’noye': 1911,
    'Zudilovo': 1911,
    'Aktash': 1876,
    'Artybash': 1876,
    'Belyashi': 1876,
    'Chemal': 1876,
    'Cherga': 1876,
    'Choya': 1876,
    'Elekmonar': 1876,
    'Gorno-Altaysk': 1876,
    'Inya': 1876,
    'Iogach': 1876,
    'Kosh-Agach': 1876,
    'Manzherok': 1876,
    'Mayma': 1876,
    'Mayminskiy Rayon': 1876,
    'Onguday': 1876,
    'Ongudayskiy Rayon': 1876,
    'Shebalino': 1876,
    'Shebalinskiy Rayon': 1876,
    'Souzga': 1876,
    'Tashanta': 1876,
    'Turochak': 1876,
    'Ust’-Kan': 1876,
    'Ust’-Koksa': 1876,
    'Ust’-Koksinskiy Rayon': 1876,
    'Ust’-Ulagan': 1876,
    'Arkhara': 1858,
    'Arkharinskiy Rayon': 1858,
    'Belogorsk': 1858,
    'Belogorskiy Rayon': 1858,
    'Blagoveshchensk': 1858,
    'Blagoveshchenskiy Rayon': 1858,
    'Bureya': 1858,
    'Bureyskiy Rayon': 1858,
    'Ekimchan': 1858,
    'Gorod Blagoveshchensk': 1858,
    'Gorod Raychikhinsk': 1858,
    'Ivanovskiy Rayon': 1858,
    'Konstantinovka': 1858,
    'Konstantinovskiy Rayon': 1858,
    'Magdagachi': 1858,
    'Magdagachinskiy Rayon': 1858,
    'Mazanovskiy Rayon': 1858,
    'Mikhaylovskiy Rayon': 1858,
    'Never': 1858,
    'Novobureyskiy': 1858,
    'Novoraychikhinsk': 1858,
    'Oktyabr’skiy Rayon': 1858,
    'Ovsyanka': 1858,
    'Poyarkovo': 1858,
    'Raychikhinsk': 1858,
    'Romnenskiy Rayon': 1858,
    'Romny': 1858,
    'Selemdzhinskiy Rayon': 1858,
    'Seryshevo': 1858,
    'Seryshevskiy Rayon': 1858,
    'Shimanovsk': 1858,
    'Shimanovskiy Rayon': 1858,
    'Shirokiy': 1858,
    'Sivaki': 1858,
    'Skovorodino': 1858,
    'Skovorodinskiy Rayon': 1858,
    'Solovjevsk': 1858,
    'Srednebelaya': 1858,
    'Svobodnenskiy Rayon': 1858,
    'Svobodnyy': 1858,
    'Takhtamygda': 1858,
    'Talakan': 1858,
    'Taldan': 1858,
    'Tambovka': 1858,
    'Tambovskiy Rayon': 1858,
    'Tokur': 1858,
    'Tsiolkovskiy': 1858,
    'Tygda': 1858,
    'Tynda': 1858,
    'Tyndinskiy Rayon': 1858,
    'Urusha': 1858,
    'Ushumun': 1858,
    'Yekaterinoslavka': 1858,
    'Yerofey Pavlovich': 1858,
    'Zavitinsk': 1858,
    'Zavitinskiy Rayon': 1858,
    'Zeya': 1858,
    'Zeyskiy Rayon': 1858,
    'Arkhangel’sk': 1849,
    'Berëznik': 1849,
    'Cheremushskiy': 1849,
    'Dvinskoy': 1849,
    'Il’insko-Podomskoye': 1849,
    'Ileza': 1849,
    'Isakogorka': 1849,
    'Kamenka': 1849,
    'Kargopol’': 1849,
    'Kargopol’skiy Rayon': 1849,
    'Karpogory': 1849,
    'Katunino': 1849,
    'Kharitonovo': 1849,
    'Kholmogorskiy Rayon': 1849,
    'Kholmogory': 1849,
    'Kizema': 1849,
    'Kodino': 1849,
    'Konëvo': 1849,
    'Konosha': 1849,
    'Koryazhma': 1849,
    'Kotlas': 1849,
    'Kotlasskiy Rayon': 1849,
    'Krasnoborsk': 1849,
    'Kuloy': 1849,
    'Lenskiy Rayon': 1849,
    'Leshukonskiy Rayon': 1849,
    'Leshukonskoye': 1849,
    'Loyga': 1849,
    'Lukovetskiy': 1849,
    'Mezen’': 1849,
    'Mezenskiy Rayon': 1849,
    'Mirnyy': 1849,
    'Novodvinsk': 1849,
    'Nyandoma': 1849,
    'Nyandomskiy Rayon': 1849,
    'Oksovskiy': 1849,
    'Onega': 1849,
    'Onezhskiy Rayon': 1849,
    'Pinega': 1849,
    'Pinezhskiy Rayon': 1849,
    'Plesetsk': 1849,
    'Podyuga': 1849,
    'Primorskiy Rayon': 1849,
    'Privodino': 1849,
    'Puksoozero': 1849,
    'Rochegda': 1849,
    'Samoded': 1849,
    'Severodvinsk': 1849,
    'Shalakusha': 1849,
    'Shangaly': 1849,
    'Shenkursk': 1849,
    'Shenkurskiy Rayon': 1849,
    'Shipitsyno': 1849,
    'Sol’vychegodsk': 1849,
    'Solginskiy': 1849,
    'Udimskiy': 1849,
    'Urdoma': 1849,
    'Ust’-Shonosha': 1849,
    'Ust’yanskiy Rayon': 1849,
    'Uyemskiy': 1849,
    'Vas’kovo': 1849,
    'Vel’sk': 1849,
    'Vel’skiy Rayon': 1849,
    'Verkhnyaya Toyma': 1849,
    'Voloshka': 1849,
    'Vychegodskiy': 1849,
    'Yagry': 1849,
    'Yarensk': 1849,
    'Yemetsk': 1849,
    'Yemtsa': 1849,
    'Yertsevo': 1849,
    'Akhtubinsk': 1866,
    'Akhtubinskiy Rayon': 1866,
    'Aksarayskiy': 1866,
    'Astrakhan': 1866,
    'Chyorny Yar': 1866,
    'Ikryanoye': 1866,
    'Kamyzyak': 1866,
    'Kapustin Yar': 1866,
    'Karalat': 1866,
    'Kharabali': 1866,
    'Kirovskiy': 1866,
    'Krasnoyarskiy Rayon': 1866,
    'Krasnyy Yar': 1866,
    'Krasnyye Barrikady': 1866,
    'Liman': 1866,
    'Limanskiy Rayon': 1866,
    'Marfino': 1866,
    'Mumra': 1866,
    'Nachalovo': 1866,
    'Narimanov': 1866,
    'Nizhniy Baskunchak': 1866,
    'Oranzherei': 1866,
    'Poldnëvoye': 1866,
    'Privolzhskiy Rayon': 1866,
    'Sasykoli': 1866,
    'Sizyy Bugor': 1866,
    'Solyanka': 1866,
    'Starokucherganovka': 1866,
    'Tambovka': 1866,
    'Trudfront': 1866,
    'Tumak': 1866,
    'Uvary': 1866,
    'Verkhniy Baskunchak': 1866,
    'Volgo-Kaspiyskiy': 1866,
    'Volodarskiy': 1866,
    'Yaksatovo': 1866,
    'Yandyki': 1866,
    'Yenotayevka': 1866,
    'Zenzeli': 1866,
    'Znamensk': 1866,
    'Alekseyevka': 1903,
    'Bekhteyevka': 1903,
    'Belgorod': 1903,
    'Belgorodskiy Rayon': 1903,
    'Bessonovka': 1903,
    'Borisovka': 1903,
    'Borisovskiy Rayon': 1903,
    'Chernyanka': 1903,
    'Chernyanskiy Rayon': 1903,
    'Golovchino': 1903,
    'Grayvoron': 1903,
    'Grayvoronskiy Rayon': 1903,
    'Gubkin': 1903,
    'Ilovka': 1903,
    'Ivnya': 1903,
    'Ivnyanskiy Rayon': 1903,
    'Korocha': 1903,
    'Korochanskiy Rayon': 1903,
    'Krasnaya Yaruga': 1903,
    'Krasnenskiy Rayon': 1903,
    'Krasnogvardeyskiy Rayon': 1903,
    'Krasnogvardeyskoye': 1903,
    'Krasnoyaruzhskiy Rayon': 1903,
    'Krasnoye': 1903,
    'Livenka': 1903,
    'Maslova Pristan’': 1903,
    'Mayskiy': 1903,
    'Novaya Tavolzhanka': 1903,
    'Novooskol’skiy Rayon': 1903,
    'Novyy Oskol': 1903,
    'Prokhorovka': 1903,
    'Prokhorovskiy Rayon': 1903,
    'Proletarskiy': 1903,
    'Pyatnitskoye': 1903,
    'Rakitnoye': 1903,
    'Rakityanskiy Rayon': 1903,
    'Razumnoye': 1903,
    'Rogovatoye': 1903,
    'Roven’skiy Rayon': 1903,
    'Severnyy': 1903,
    'Shebekino': 1903,
    'Shebekinskiy Rayon': 1903,
    'Skorodnoye': 1903,
    'Staryy Oskol': 1903,
    'Stroitel’': 1903,
    'Tomarovka': 1903,
    'Troitskiy': 1903,
    'Urazovo': 1903,
    'Valuyki': 1903,
    'Valuyskiy Rayon': 1903,
    'Veydelevka': 1903,
    'Volokonovka': 1903,
    'Volokonovskiy Rayon': 1903,
    'Yakovlevo': 1903,
    'Yakovlevskiy Rayon': 1903,
    'Zasosna': 1903,
    'Altukhovo': 1867,
    'Ardon’': 1867,
    'Belyye Berega': 1867,
    'Brasovskiy Rayon': 1867,
    'Bryansk': 1867,
    'Bryanskiy Rayon': 1867,
    'Bytosh’': 1867,
    'Churovichi': 1867,
    'Dobrun’': 1867,
    'Dubrovka': 1867,
    'Dubrovskiy Rayon': 1867,
    'Dyat’kovo': 1867,
    'Dyat’kovskiy Rayon': 1867,
    'Glinishchevo': 1867,
    'Gordeyevka': 1867,
    'Gordeyevskiy Rayon': 1867,
    'Gorod Bryansk': 1867,
    'Gorod Dyat’kovo': 1867,
    'Ivanovka': 1867,
    'Ivot': 1867,
    'Karachev': 1867,
    'Kletnya': 1867,
    'Kletnyanskiy Rayon': 1867,
    'Klimovo': 1867,
    'Klimovskiy Rayon': 1867,
    'Klintsovskiy Rayon': 1867,
    'Klintsy': 1867,
    'Kokino': 1867,
    'Kokorevka': 1867,
    'Komarichi': 1867,
    'Komarichskiy Rayon': 1867,
    'Korzhovka-Golubovka': 1867,
    'Krasnaya Gora': 1867,
    'Krasnogorskiy Rayon': 1867,
    'Lokot’': 1867,
    'Lopandino': 1867,
    'Lyubokhna': 1867,
    'Lyubovsho': 1867,
    'Mglin': 1867,
    'Mglinskiy Rayon': 1867,
    'Navlinskiy Rayon': 1867,
    'Navlya': 1867,
    'Net’inka': 1867,
    'Novozybkov': 1867,
    'Novozybkovskiy Rayon': 1867,
    'Novyy Ropsk': 1867,
    'Pochep': 1867,
    'Pochepskiy Rayon': 1867,
    'Pogar': 1867,
    'Pogarskiy Rayon': 1867,
    'Putëvka': 1867,
    'Raditsa-Krylovka': 1867,
    'Rognedino': 1867,
    'Rognedinskiy Rayon': 1867,
    'Rzhanitsa': 1867,
    'Sachkovichi': 1867,
    'Sel’tso': 1867,
    'Seshcha': 1867,
    'Sevsk': 1867,
    'Sevskiy Rayon': 1867,
    'Star’': 1867,
    'Starodub': 1867,
    'Starodubskiy Rayon': 1867,
    'Suponevo': 1867,
    'Surazh': 1867,
    'Surazhskiy Rayon': 1867,
    'Suzëmka': 1867,
    'Suzemskiy Rayon': 1867,
    'Sven’': 1867,
    'Trubchevsk': 1867,
    'Trubchevskiy Rayon': 1867,
    'Unecha': 1867,
    'Unechskiy Rayon': 1867,
    'Vygonichi': 1867,
    'Vygonichskiy Rayon': 1867,
    'Vyshkov': 1867,
    'Zamishevo': 1867,
    'Zaymishche': 1867,
    'Zhiryatino': 1867,
    'Zhiryatinskiy Rayon': 1867,
    'Zhukovka': 1867,
    'Zhukovskiy Rayon': 1867,
    'Zlynka': 1867,
    'Achkhoy-Martan': 1893,
    'Achkhoy-Martanovskiy Rayon': 1893,
    'Alkhan-Kala': 1893,
    'Alkhan-Yurt': 1893,
    'Alkhazurovo': 1893,
    'Alleroy': 1893,
    'Alpatovo': 1893,
    'Argun': 1893,
    'Assinovskaya': 1893,
    'Avtury': 1893,
    'Bachi-Yurt': 1893,
    'Bamut': 1893,
    'Belgatoy': 1893,
    'Benoy-Yurt': 1893,
    'Borzoy': 1893,
    'Chechen-Aul': 1893,
    'Chiri-Yurt': 1893,
    'Duba-Yurt': 1893,
    'Dyshne-Vedeno': 1893,
    'Dzhalka': 1893,
    'Elin-Yurt': 1893,
    'Engel’-Yurt': 1893,
    'Gekhi': 1893,
    'Germenchuk': 1893,
    'Gerzel’-Aul': 1893,
    'Goragorskiy': 1893,
    'Goyty': 1893,
    'Grebenskaya': 1893,
    'Groznenskiy Rayon': 1893,
    'Groznyy': 1893,
    'Gudermes': 1893,
    'Gudermesskiy Rayon': 1893,
    'Ilaskhan-Yurt': 1893,
    'Ishcherskaya': 1893,
    'Ishkhoy-Yurt': 1893,
    'Itum-Kali': 1893,
    'Itum-Kalinskiy Rayon': 1893,
    'Kalinovskaya': 1893,
    'Kargalinskaya': 1893,
    'Katyr-Yurt': 1893,
    'Khambi-Irze': 1893,
    'Khankala': 1893,
    'Kulary': 1893,
    'Kurchaloy': 1893,
    'Lakha Nëvre': 1893,
    'Lakkha Nëvre': 1893,
    'Martan-Chu': 1893,
    'Mayrtup': 1893,
    'Mesker-Yurt': 1893,
    'Nadterechnyy Rayon': 1893,
    'Naurskaya': 1893,
    'Naurskiy Rayon': 1893,
    'Nogamerzin-Yurt': 1893,
    'Novyye Atagi': 1893,
    'Nozhay-Yurt': 1893,
    'Nozhay-Yurtovskiy Rayon': 1893,
    'Oyskhara': 1893,
    'Pervomayskaya': 1893,
    'Petropavlovskaya': 1893,
    'Prigorodnoye': 1893,
    'Roshni-Chu': 1893,
    'Samashki': 1893,
    'Sernovodsk': 1893,
    'Serzhen’-Yurt': 1893,
    'Shaami-Yurt': 1893,
    'Shalazhi': 1893,
    'Shali': 1893,
    'Shalinskiy Rayon': 1893,
    'Shatoy': 1893,
    'Shëlkovskaya': 1893,
    'Shelkovskiy Rayon': 1893,
    'Staraya Sunzha': 1893,
    'Staryye Atagi': 1893,
    'Tolstoy-Yurt': 1893,
    'Tsentoroy': 1893,
    'Tsotsin-Yurt': 1893,
    'Urus-Martan': 1893,
    'Urus-Martanovskiy Rayon': 1893,
    'Valerik': 1893,
    'Vedeno': 1893,
    'Vedenskiy Rayon': 1893,
    'Yalkhoy-Mokhk': 1893,
    'Zakan-Yurt': 1893,
    'Zandak': 1893,
    'Znamenskoye': 1893,
    'Agapovka': 1845,
    'Agapovskiy Rayon': 1845,
    'Argayash': 1845,
    'Asha': 1845,
    'Ashinskiy Rayon': 1845,
    'Bakal': 1845,
    'Bazhovo': 1845,
    'Berdyaush': 1845,
    'Bobrovka': 1845,
    'Bredy': 1845,
    'Brodokalmak': 1845,
    'Chebarkul’': 1845,
    'Chebarkul’skiy Rayon': 1845,
    'Chelyabinsk': 1845,
    'Cheremshanka': 1845,
    'Chesma': 1845,
    'Dolgoderevenskoye': 1845,
    'Fershampenuaz': 1845,
    'Gornyak': 1845,
    'Gorod Chelyabinsk': 1845,
    'Kanashevo': 1845,
    'Karabash': 1845,
    'Kartalinskiy Rayon': 1845,
    'Kartaly': 1845,
    'Kasli': 1845,
    'Katav-Ivanovsk': 1845,
    'Katav-Ivanovskiy Rayon': 1845,
    'Kizil’skiy Rayon': 1845,
    'Kopeysk': 1845,
    'Korkino': 1845,
    'Koyelga': 1845,
    'Krasnogorskiy': 1845,
    'Kropachëvo': 1845,
    'Kunashak': 1845,
    'Kusa': 1845,
    'Kyshtym': 1845,
    'Leninsk': 1845,
    'Lokomotivnyy': 1845,
    'Magnitka': 1845,
    'Magnitogorsk': 1845,
    'Mezhevoy': 1845,
    'Miass': 1845,
    'Miasskoye': 1845,
    'Muslyumovo': 1845,
    'Nagaybakskiy Rayon': 1845,
    'Nizhniy Ufaley': 1845,
    'Novogornyy': 1845,
    'Novosineglazovskiy': 1845,
    'Nyazepetrovsk': 1845,
    'Nyazepetrovskiy Rayon': 1845,
    'Oktyabr’skiy Rayon': 1845,
    'Ozersk': 1845,
    'Parizh': 1845,
    'Plast': 1845,
    'Poletayevo': 1845,
    'Potanino': 1845,
    'Roshchino': 1845,
    'Roza': 1845,
    'Sargazy': 1845,
    'Satka': 1845,
    'Satkinskiy Rayon': 1845,
    'Selezyan': 1845,
    'Sim': 1845,
    'Snezhinsk': 1845,
    'Sosnovskiy Rayon': 1845,
    'Starokamyshinsk': 1845,
    'Syrostan': 1845,
    'Tayginka': 1845,
    'Timiryazevskiy': 1845,
    'Trëkhgornyy': 1845,
    'Troitsk': 1845,
    'Turgoyak': 1845,
    'Tyubuk': 1845,
    'Ust’-Bagaryak': 1845,
    'Ust’-Katav': 1845,
    'Uvel’skiy': 1845,
    'Uvel’skiy Rayon': 1845,
    'Uyskiy Rayon': 1845,
    'Uyskoye': 1845,
    'Vakhrushevo': 1845,
    'Varna': 1845,
    'Varnenskiy Rayon': 1845,
    'Velikopetrovka': 1845,
    'Verkhneural’sk': 1845,
    'Verkhniy Ufaley': 1845,
    'Vishnëvogorsk': 1845,
    'Vyazovaya': 1845,
    'Yemanzhelinka': 1845,
    'Yemanzhelinsk': 1845,
    'Yetkul’': 1845,
    'Yuryuzan’': 1845,
    'Yuzhnoural’sk': 1845,
    'Zheleznodorozhnyy': 1845,
    'Zlatoust': 1845,
    'Anadyr': 1859,
    'Anadyrskiy Rayon': 1859,
    'Beringovskiy': 1859,
    'Bilibino': 1859,
    'Egvekinot': 1859,
    'Lavrentiya': 1859,
    'Lorino': 1859,
    'Pevek': 1859,
    'Provideniya': 1859,
    'Providenskiy Rayon': 1859,
    'Alatyr’': 1914,
    'Alatyrskiy Rayon': 1914,
    'Alikovo': 1914,
    'Alikovskiy Rayon': 1914,
    'Atlashevo': 1914,
    'Batyrevo': 1914,
    'Batyrevskiy Rayon': 1914,
    'Buinsk': 1914,
    'Cheboksarskiy Rayon': 1914,
    'Cheboksary': 1914,
    'Ibresi': 1914,
    'Ibresinskiy Rayon': 1914,
    'Ishley': 1914,
    'Kanash': 1914,
    'Kanashskiy Rayon': 1914,
    'Kirya': 1914,
    'Komsomol  skoye': 1914,
    'Komsomol’skiy Rayon': 1914,
    'Kozlovka': 1914,
    'Kozlovskiy Rayon': 1914,
    'Krasnoarmeyskiy Rayon': 1914,
    'Krasnoarmeyskoye': 1914,
    'Krasnochetayskiy Rayon': 1914,
    'Krasnyye Chetai': 1914,
    'Kugesi': 1914,
    'Mariinskiy Posad': 1914,
    'Mariinsko-Posadskiy Rayon': 1914,
    'Morgaushi': 1914,
    'Morgaushskiy Rayon': 1914,
    'Novocheboksarsk': 1914,
    'Novyye Lapsary': 1914,
    'Poretskiy Rayon': 1914,
    'Poretskoye': 1914,
    'Shemursha': 1914,
    'Shemurshinskiy Rayon': 1914,
    'Shikhazany': 1914,
    'Shumerlinskiy Rayon': 1914,
    'Shumerlya': 1914,
    'Tsivil’sk': 1914,
    'Tsivil’skiy Rayon': 1914,
    'Urmarskiy Rayon': 1914,
    'Urmary': 1914,
    'Vurnarskiy Rayon': 1914,
    'Vurnary': 1914,
    'Yadrin': 1914,
    'Yadrinskiy Rayon': 1914,
    'Yal’chikskiy Rayon': 1914,
    'Yantikovo': 1914,
    'Yantikovskiy Rayon': 1914,
    'Arkhipovka': 1864,
    'Bogorodskoye': 1864,
    'Dulyapino': 1864,
    'Furmanov': 1864,
    'Gavrilov Posad': 1864,
    'Il’inskoye-Khovanskoye': 1864,
    'Ivanovo': 1864,
    'Ivanovskiy Rayon': 1864,
    'Kamenka': 1864,
    'Kaminskiy': 1864,
    'Kineshemskiy Rayon': 1864,
    'Kineshma': 1864,
    'Kitovo': 1864,
    'Kokhma': 1864,
    'Kolobovo': 1864,
    'Komsomol’sk': 1864,
    'Komsomol’skiy Rayon': 1864,
    'Kuznechikha': 1864,
    'Lezhnevo': 1864,
    'Lezhnevskiy Rayon': 1864,
    'Lukh': 1864,
    'Lukhskiy Rayon': 1864,
    'Markovo': 1864,
    'Navoloki': 1864,
    'Nerl’': 1864,
    'Novo-Talitsy': 1864,
    'Novopistsovo': 1864,
    'Novoye Leushino': 1864,
    'Novyye Gorki': 1864,
    'Palekh': 1864,
    'Palekhskiy Rayon': 1864,
    'Pestyaki': 1864,
    'Pestyakovskiy Rayon': 1864,
    'Petrovskiy': 1864,
    'Pistsovo': 1864,
    'Plës': 1864,
    'Privolzhsk': 1864,
    'Privolzhskiy Rayon': 1864,
    'Puchezh': 1864,
    'Puchezhskiy Rayon': 1864,
    'Rodniki': 1864,
    'Rodnikovskiy Rayon': 1864,
    'Savino': 1864,
    'Savinskiy Rayon': 1864,
    'Shuya': 1864,
    'Shuyskiy Rayon': 1864,
    'Staraya Vichuga': 1864,
    'Talitsy': 1864,
    'Teykovo': 1864,
    'Teykovskiy Rayon': 1864,
    'Verkhnelandekhovskiy Rayon': 1864,
    'Verkhniy Landekh': 1864,
    'Vichuga': 1864,
    'Vichugskiy Rayon': 1864,
    'Yur’yevets': 1864,
    'Yur’yevetskiy Rayon': 1864,
    'Yuzha': 1864,
    'Yuzhskiy Rayon': 1864,
    'Zarechnyy': 1864,
    'Zavolzhsk': 1864,
    'Zavolzhskiy Rayon': 1864,
    'Amurzet': 1835,
    'Babstovo': 1835,
    'Bira': 1835,
    'Birakan': 1835,
    'Birobidzhan': 1835,
    'Birobidzhanskiy Rayon': 1835,
    'Izvestkovyy': 1835,
    'Khingansk': 1835,
    'Kul’dur': 1835,
    'Leninskiy Rayon': 1835,
    'Leninskoye': 1835,
    'Londoko': 1835,
    'Obluchenskiy Rayon': 1835,
    'Oktyabr’skiy Rayon': 1835,
    'Priamurskiy': 1835,
    'Smidovich': 1835,
    'Smidovichskiy Rayon': 1835,
    'Volochayevka Vtoraya': 1835,
    'Aleksandrovskaya': 1892,
    'Altud': 1892,
    'Argudan': 1892,
    'Aushiger': 1892,
    'Babugent': 1892,
    'Baksan': 1892,
    'Baksanenok': 1892,
    'Baksanskiy Rayon': 1892,
    'Belaya Rechka': 1892,
    'Bylym': 1892,
    'Chegem': 1892,
    'Chegem Vtoroy': 1892,
    'Cherekskiy Rayon': 1892,
    'Deyskoye': 1892,
    'Dugulubgey': 1892,
    'El’brus': 1892,
    'El’brusskiy Rayon': 1892,
    'Germenchik': 1892,
    'Gundelen': 1892,
    'Islamey': 1892,
    'Kakhun': 1892,
    'Kamenka': 1892,
    'Kamennomostskoye': 1892,
    'Karagach': 1892,
    'Kashkhatau': 1892,
    'Kenzhe': 1892,
    'Kishpek': 1892,
    'Kotlyarevskaya': 1892,
    'Kuba-Taba': 1892,
    'Kyzburun Pervyy': 1892,
    'Lechinkay': 1892,
    'Leskenskiy rayon': 1892,
    'Malka': 1892,
    'Mayskiy Rayon': 1892,
    'Nal’chik': 1892,
    'Nartan': 1892,
    'Nartkala': 1892,
    'Nizhniy Cherek': 1892,
    'Nizhniy Kurkuzhin': 1892,
    'Planovskoye': 1892,
    'Primalkinskoye': 1892,
    'Prokhladnenskiy Rayon': 1892,
    'Prokhladnyy': 1892,
    'Psygansu': 1892,
    'Sarmakovo': 1892,
    'Shalushka': 1892,
    'Soldatskaya': 1892,
    'Staryy Cherek': 1892,
    'Staryy Urukh': 1892,
    'Terek': 1892,
    'Terskiy Rayon': 1892,
    'Terskol': 1892,
    'Tyrnyauz': 1892,
    'Urukh': 1892,
    'Urvan’': 1892,
    'Urvanskiy Rayon': 1892,
    'Verkhniy Kurkuzhin': 1892,
    'Verkhnyaya Balkariya': 1892,
    'Vol’nyy Aul': 1892,
    'Zalukokoazhe': 1892,
    'Zayukovo': 1892,
    'Zhankhoteko': 1892,
    'Zhemtala': 1892,
    'Zol’skiy Rayon': 1892,
    'Bagrationovsk': 1902,
    'Baltiysk': 1902,
    'Bol  shoe Isakovo': 1902,
    'Chernyakhovsk': 1902,
    'Donskoye': 1902,
    'Gur’yevsk': 1902,
    'Gusev': 1902,
    'Gvardeysk': 1902,
    'Kaliningrad': 1902,
    'Khrabrovo': 1902,
    'Krasnoznamensk': 1902,
    'Ladushkin': 1902,
    'Maloye Isakovo': 1902,
    'Mamonovo': 1902,
    'Neman': 1902,
    'Nemanskiy Rayon': 1902,
    'Nesterov': 1902,
    'Nesterovskiy Rayon': 1902,
    'Otradnoye': 1902,
    'Ozërsk': 1902,
    'Ozerskiy Rayon': 1902,
    'Pionerskiy': 1902,
    'Polessk': 1902,
    'Pravdinsk': 1902,
    'Primorsk': 1902,
    'Slavsk': 1902,
    'Slavskiy Rayon': 1902,
    'Sovetsk': 1902,
    'Svetlogorsk': 1902,
    'Svetlyy': 1902,
    'Volochayevskoye': 1902,
    'Vzmorye': 1902,
    'Yantarnyy': 1902,
    'Yuzhnyy': 1902,
    'Zelenogradsk': 1902,
    'Zheleznodorozhnyy': 1902,
    'Znamensk': 1902,
    'Babynino': 1844,
    'Babyninskiy Rayon': 1844,
    'Balabanovo': 1844,
    'Baryatino': 1844,
    'Baryatinskiy Rayon': 1844,
    'Belousovo': 1844,
    'Betlitsa': 1844,
    'Borovsk': 1844,
    'Borovskiy Rayon': 1844,
    'Deshovki': 1844,
    'Detchino': 1844,
    'Duminichi': 1844,
    'Duminichskiy Rayon': 1844,
    'Dvortsy': 1844,
    'Dzerzhinskiy Rayon': 1844,
    'Ferzikovo': 1844,
    'Ferzikovskiy Rayon': 1844,
    'Iznoski': 1844,
    'Iznoskovskiy rayon': 1844,
    'Kaluga': 1844,
    'Khvastovichi': 1844,
    'Khvastovichskiy Rayon': 1844,
    'Kirov': 1844,
    'Kirovskiy Rayon': 1844,
    'Kondrovo': 1844,
    'Kozel’sk': 1844,
    'Kozel’skiy Rayon': 1844,
    'Kremenki': 1844,
    'Kudinovo': 1844,
    'Kurovskoye': 1844,
    'Kuybyshevskiy Rayon': 1844,
    'Lyudinovo': 1844,
    'Lyudinovskiy Rayon': 1844,
    'Maloyaroslavets': 1844,
    'Maloyaroslavetskiy Rayon': 1844,
    'Medyn’': 1844,
    'Medynskiy Rayon': 1844,
    'Meshchovsk': 1844,
    'Meshchovskiy Rayon': 1844,
    'Mosal’sk': 1844,
    'Mosal’skiy Rayon': 1844,
    'Mstikhino': 1844,
    'Myatlevo': 1844,
    'Nikola-Lenivets': 1844,
    'Obninsk': 1844,
    'Peremyshl’': 1844,
    'Peremyshl’skiy Rayon': 1844,
    'Polotnyanyy Zavod': 1844,
    'Pyatovskiy': 1844,
    'Seredeyskiy': 1844,
    'Sosenskiy': 1844,
    'Spas-Demensk': 1844,
    'Spas-Demenskiy Rayon': 1844,
    'Sukhinichi': 1844,
    'Sukhinichskiy Rayon': 1844,
    'Tarusa': 1844,
    'Tarusskiy Rayon': 1844,
    'Tovarkovo': 1844,
    'Ul’yanovskiy Rayon': 1844,
    'Vorotynsk': 1844,
    'Vysokinichi': 1844,
    'Yermolino': 1844,
    'Yukhnov': 1844,
    'Yukhnovskiy Rayon': 1844,
    'Zarech’ye': 1844,
    'Zhiletovo': 1844,
    'Zhizdra': 1844,
    'Zhizdrinskiy Rayon': 1844,
    'Zhukovo': 1844,
    'Zhukovskiy Rayon': 1844,
    'Aleutskiy Rayon': 1865,
    'Atlasovo': 1865,
    'Bystrinskiy Rayon': 1865,
    'Esso': 1865,
    'Karaginsky District': 1865,
    'Klyuchi': 1865,
    'Kozyrëvsk': 1865,
    'Mil’kovo': 1865,
    'Mil’kovskiy Rayon': 1865,
    'Olyutorskiy Rayon': 1865,
    'Ossora': 1865,
    'Ozernovskiy': 1865,
    'Palana': 1865,
    'Paratunka': 1865,
    'Penzhinskiy Rayon': 1865,
    'Petropavlovsk-Kamchatsky': 1865,
    'Sobolevskiy Rayon': 1865,
    'Tigil’': 1865,
    'Tigil’skiy Rayon': 1865,
    'Tilichiki': 1865,
    'Ust’-Bol’sheretskiy Rayon': 1865,
    'Ust’-Kamchatsk Staryy': 1865,
    'Ust’-Kamchatskiy Rayon': 1865,
    'Vilyuchinsk': 1865,
    'Yelizovo': 1865,
    'Yelizovskiy Rayon': 1865,
    'Adyge-Khabl’': 1869,
    'Ali-Berdukovskiy': 1869,
    'Besleney': 1869,
    'Cherkessk': 1869,
    'Druzhba': 1869,
    'Ikon-Khalk': 1869,
    'Ispravnaya': 1869,
    'Karachayevsk': 1869,
    'Karachayevskiy Rayon': 1869,
    'Kardonikskaya': 1869,
    'Kavkazskiy': 1869,
    'Khabez': 1869,
    'Krasnyy Kurgan': 1869,
    'Kumysh': 1869,
    'Kurdzhinovo': 1869,
    'Kyzyl-Oktyabr’skiy': 1869,
    'Malokarachayevskiy Rayon': 1869,
    'Mednogorskiy': 1869,
    'Nizhniy Arkhyz': 1869,
    'Novyy Karachay': 1869,
    'Ordzhonikidzevskiy': 1869,
    'Pervomayskoye': 1869,
    'Pravokubanskiy': 1869,
    'Pregradnaya': 1869,
    'Prikubanskiy Rayon': 1869,
    'Psyzh': 1869,
    'Sary-Tyuz': 1869,
    'Storozhevaya': 1869,
    'Tereze': 1869,
    'Uchkeken': 1869,
    'Uchkulan': 1869,
    'Udarnyy': 1869,
    'Ust’-Dzheguta': 1869,
    'Zelenchukskaya': 1869,
    'Abagur': 1897,
    'Anzhero-Sudzhensk': 1897,
    'Artyshta': 1897,
    'Bachatskiy': 1897,
    'Barzas': 1897,
    'Belogorsk': 1897,
    'Belovo': 1897,
    'Berëzovskiy': 1897,
    'Borovoy': 1897,
    'Chistogorskiy': 1897,
    'Gramoteino': 1897,
    'Gur’yevsk': 1897,
    'Inskoy': 1897,
    'Itatskiy': 1897,
    'Izhmorskiy': 1897,
    'Kaltan': 1897,
    'Karagayla': 1897,
    'Kaz': 1897,
    'Kedrovka': 1897,
    'Kemerovo': 1897,
    'Kiselëvsk': 1897,
    'Krapivinskiy': 1897,
    'Krasnobrodskiy': 1897,
    'Krasnogorskiy': 1897,
    'Krasnoye': 1897,
    'Kuzedeyevo': 1897,
    'Leninsk-Kuznetskiy Rayon': 1897,
    'Leninsk-Kuznetsky': 1897,
    'Listvyagi': 1897,
    'Mariinsk': 1897,
    'Mezhdurechensk': 1897,
    'Mundybash': 1897,
    'Myski': 1897,
    'Nikitinskiy': 1897,
    'Novokuznetsk': 1897,
    'Novokuznetskiy Rayon': 1897,
    'Novopesterevo': 1897,
    'Osinniki': 1897,
    'Pioner': 1897,
    'Plotnikovo': 1897,
    'Polysayevo': 1897,
    'Pritomskiy': 1897,
    'Prokop’yevsk': 1897,
    'Prokop’yevskiy Rayon': 1897,
    'Promyshlennaya': 1897,
    'Promyshlennovskiy': 1897,
    'Rudnichnyy': 1897,
    'Salair': 1897,
    'Sheregesh': 1897,
    'Sosnovka': 1897,
    'Spassk': 1897,
    'Starobachaty': 1897,
    'Staropesterevo': 1897,
    'Suslovo': 1897,
    'Tashtagol': 1897,
    'Tashtagol’skiy Rayon': 1897,
    'Tayga': 1897,
    'Tayzhina': 1897,
    'Temirtau': 1897,
    'Tisul’': 1897,
    'Topki': 1897,
    'Trudarmeyskiy': 1897,
    'Tyazhinskiy': 1897,
    'Ursk': 1897,
    'Verkh-Chebula': 1897,
    'Yagunovo': 1897,
    'Yagunovskiy': 1897,
    'Yashkino': 1897,
    'Yaya': 1897,
    'Yurga': 1897,
    'Yurginskiy Rayon': 1897,
    'Zelenogorskiy': 1897,
    'Amursk': 1873,
    'Ayan': 1873,
    'Berëzovyy': 1873,
    'Bikin': 1873,
    'Bikinskiy Rayon': 1873,
    'Bogorodskoye': 1873,
    'Chegdomyn': 1873,
    'Chumikan': 1873,
    'De-Kastri': 1873,
    'Gorin': 1873,
    'Gornyy': 1873,
    'Imeni Poliny Osipenko': 1873,
    'Khabarovsk': 1873,
    'Khabarovsk Vtoroy': 1873,
    'Khor': 1873,
    'Khurba': 1873,
    'Knyaze-Volkonskoye': 1873,
    'Komsomolsk-on-Amur': 1873,
    'Korfovskiy': 1873,
    'Lazarev': 1873,
    'Lermontovka': 1873,
    'Litovko': 1873,
    'Lososina': 1873,
    'Mago': 1873,
    'Mayskiy': 1873,
    'Mnogovershinnyy': 1873,
    'Mukhen': 1873,
    'Nekrasovka': 1873,
    'Nikolayevsk-on-Amure': 1873,
    'Novyy Urgal': 1873,
    'Okhotsk': 1873,
    'Selikhino': 1873,
    'Solnechnyy': 1873,
    'Sovetskaya Gavan’': 1873,
    'Topolëvo': 1873,
    'Tyrma': 1873,
    'Vanino': 1873,
    'Vyazemskiy': 1873,
    'Vysokogornyy': 1873,
    'Zavety Il’icha': 1873,
    'Agirish': 1838,
    'Andra': 1838,
    'Barsovo': 1838,
    'Beloyarskiy': 1838,
    'Berëzovo': 1838,
    'Fedorovskiy': 1838,
    'Gornopravdinsk': 1838,
    'Igrim': 1838,
    'Izluchinsk': 1838,
    'Khanty-Mansiysk': 1838,
    'Khulimsunt': 1838,
    'Kogalym': 1838,
    'Kommunisticheskiy': 1838,
    'Kondinskoye': 1838,
    'Kuminskiy': 1838,
    'Langepas': 1838,
    'Lokosovo': 1838,
    'Lyantor': 1838,
    'Malinovskiy': 1838,
    'Megion': 1838,
    'Mezhdurechenskiy': 1838,
    'Mortka': 1838,
    'Nefteyugansk': 1838,
    'Nizhnesortymskiy': 1838,
    'Nizhnevartovsk': 1838,
    'Novoagansk': 1838,
    'Nyagan': 1838,
    'Pionerskiy': 1838,
    'Pokachi': 1838,
    'Poykovskiy': 1838,
    'Priob’ye': 1838,
    'Pyt-Yakh': 1838,
    'Raduzhny': 1838,
    'Russkinskiye': 1838,
    'Salym': 1838,
    'Saranpaul’': 1838,
    'Sherkaly': 1838,
    'Singapay': 1838,
    'Solnechniy': 1838,
    'Sovetskiy': 1838,
    'Surgut': 1838,
    'Uray': 1838,
    'Yugorsk': 1838,
    'Zelenoborsk': 1838,
    'Afanas’yevskiy Rayon': 1890,
    'Arbazh': 1890,
    'Arkul’': 1890,
    'Belaya Kholunitsa': 1890,
    'Belorechensk': 1890,
    'Bogorodskoye': 1890,
    'Chërnaya Kholunitsa': 1890,
    'Darovskoy': 1890,
    'Dem’yanovo': 1890,
    'Dubrovka': 1890,
    'Falënki': 1890,
    'Falënskiy Rayon': 1890,
    'Kiknur': 1890,
    'Kiknurskiy Rayon': 1890,
    'Kil’mez’': 1890,
    'Kirov': 1890,
    'Kirovo-Chepetsk': 1890,
    'Kirovo-Chepetskiy Rayon': 1890,
    'Kirs': 1890,
    'Kosino': 1890,
    'Kostino': 1890,
    'Kotel’nich': 1890,
    'Krasnaya Polyana': 1890,
    'Kumëny': 1890,
    'Lal’sk': 1890,
    'Leninskoye': 1890,
    'Lesnoy': 1890,
    'Lesnyye Polyany': 1890,
    'Luza': 1890,
    'Luzskiy Rayon': 1890,
    'Lyangasovo': 1890,
    'Malmyzh': 1890,
    'Malmyzhskiy Rayon': 1890,
    'Maromitsa': 1890,
    'Medvedok': 1890,
    'Mirnyy': 1890,
    'Murashi': 1890,
    'Murashinskiy Rayon': 1890,
    'Murygino': 1890,
    'Nagorsk': 1890,
    'Nagorskiy Rayon': 1890,
    'Nema': 1890,
    'Nemskiy Rayon': 1890,
    'Nizhneivkino': 1890,
    'Nolinsk': 1890,
    'Nolinskiy Rayon': 1890,
    'Omutninsk': 1890,
    'Oparino': 1890,
    'Orichevskiy Rayon': 1890,
    'Orichi': 1890,
    'Orlov': 1890,
    'Orlovskiy Rayon': 1890,
    'Pasegovo': 1890,
    'Peskovka': 1890,
    'Pinyug': 1890,
    'Pizhanka': 1890,
    'Pizhanskiy Rayon': 1890,
    'Podosinovets': 1890,
    'Podosinovskiy Rayon': 1890,
    'Raduzhnyy': 1890,
    'Rudnichnyy': 1890,
    'Sanchursk': 1890,
    'Sanchurskiy Rayon': 1890,
    'Shabalinskiy Rayon': 1890,
    'Slobodskoy': 1890,
    'Slobodskoy Rayon': 1890,
    'Sosnovka': 1890,
    'Sovetsk': 1890,
    'Sovetskiy Rayon': 1890,
    'Sozimskiy': 1890,
    'Strizhi': 1890,
    'Stulovo': 1890,
    'Suna': 1890,
    'Sunskiy Rayon': 1890,
    'Svecha': 1890,
    'Svetlopolyansk': 1890,
    'Torfyanoy': 1890,
    'Tuzha': 1890,
    'Uni': 1890,
    'Uninskiy Rayon': 1890,
    'Urzhum': 1890,
    'Vakhrushi': 1890,
    'Verkhoshizhem’ye': 1890,
    'Vostochnyy': 1890,
    'Vyatskiye Polyany': 1890,
    'Yaransk': 1890,
    'Yaranskiy Rayon': 1890,
    'Yur’yanskiy Rayon': 1890,
    'Zuyevka': 1890,
    'Aykino': 1899,
    'Blagoyevo': 1899,
    'Borovoy': 1899,
    'Ezhva': 1899,
    'Inta': 1899,
    'Izhma': 1899,
    'Knyazhpogostskiy Rayon': 1899,
    'Kortkeros': 1899,
    'Koslan': 1899,
    'Koygorodok': 1899,
    'Koygorodskiy Rayon': 1899,
    'Kozhva': 1899,
    'Letka': 1899,
    'Mezhdurechensk': 1899,
    'Mikun’': 1899,
    'Mordino': 1899,
    'Nizhniy Odes': 1899,
    'Parma': 1899,
    'Pechora': 1899,
    'Priluzskiy Rayon': 1899,
    'Promyshlennyy': 1899,
    'Puteyets': 1899,
    'Sedkyrkeshch': 1899,
    'Shudayag': 1899,
    'Sindor': 1899,
    'Sosnogorsk': 1899,
    'Sovetskiy': 1899,
    'Syktyvkar': 1899,
    'Synya': 1899,
    'Troitsko-Pechorsk': 1899,
    'Troitsko-Pechorskiy Rayon': 1899,
    'Tsementnozavodskiy': 1899,
    'Ukhta': 1899,
    'Usinsk': 1899,
    'Usogorsk': 1899,
    'Ust-Tsilma': 1899,
    'Ust’-Kulom': 1899,
    'Ust’-Tsilemskiy Rayon': 1899,
    'Verkhnyaya Inta': 1899,
    'Verkhnyaya Maksakovka': 1899,
    'Vizinga': 1899,
    'Vodnyy': 1899,
    'Vorgashor': 1899,
    'Vorkuta': 1899,
    'Voyvozh': 1899,
    'Vuktyl': 1899,
    'Yarega': 1899,
    'Yemva': 1899,
    'Zapolyarnyy': 1899,
    'Zelenets': 1899,
    'Zheshart': 1899,
    'Antropovo': 1910,
    'Antropovskiy Rayon': 1910,
    'Bogovarovo': 1910,
    'Buy': 1910,
    'Buyskiy Rayon': 1910,
    'Chistyye Bory': 1910,
    'Chukhloma': 1910,
    'Galich': 1910,
    'Galichskiy Rayon': 1910,
    'Georgiyevskoye': 1910,
    'Kadyy': 1910,
    'Kadyyskiy Rayon': 1910,
    'Kologriv': 1910,
    'Kosmynino': 1910,
    'Kostroma': 1910,
    'Kostromskoy Rayon': 1910,
    'Krasnosel’skiy Rayon': 1910,
    'Krasnoye-na-Volge': 1910,
    'Makar’yev': 1910,
    'Manturovo': 1910,
    'Manturovskiy Rayon': 1910,
    'Nerekhta': 1910,
    'Nerekhtskiy Rayon': 1910,
    'Neya': 1910,
    'Neyskiy Rayon': 1910,
    'Ostrovskiy Rayon': 1910,
    'Ostrovskoye': 1910,
    'Parfen’yevo': 1910,
    'Parfen’yevskiy Rayon': 1910,
    'Pavino': 1910,
    'Ponazyrevo': 1910,
    'Ponazyrevskiy Rayon': 1910,
    'Pyshchug': 1910,
    'Shar’inskiy Rayon': 1910,
    'Shar’ya': 1910,
    'Soligalich': 1910,
    'Soligalichskiy Rayon': 1910,
    'Sudislavl’': 1910,
    'Sudislavskiy Rayon': 1910,
    'Susanino': 1910,
    'Vetluzhskiy': 1910,
    'Vokhma': 1910,
    'Vokhomskiy Rayon': 1910,
    'Volgorechensk': 1910,
    'Abinsk': 1891,
    'Abinskiy Rayon': 1891,
    'Abrau-Dyurso': 1891,
    'Adler': 1891,
    'Afipskiy': 1891,
    'Agoy': 1891,
    'Agronom': 1891,
    'Akhtanizovskaya': 1891,
    'Akhtyrskiy': 1891,
    'Alekseyevskaya': 1891,
    'Anapa': 1891,
    'Anapskaya': 1891,
    'Anastasiyevskaya': 1891,
    'Apsheronsk': 1891,
    'Arkhipo-Osipovka': 1891,
    'Armavir': 1891,
    'Atamanskaya': 1891,
    'Azovskaya': 1891,
    'Baturinskaya': 1891,
    'Belaya Glina': 1891,
    'Belorechensk': 1891,
    'Belorechenskiy Rayon': 1891,
    'Belozërnyy': 1891,
    'Berezanskaya': 1891,
    'Besleneyevskaya': 1891,
    'Besskorbnaya': 1891,
    'Blagovetschenskaya': 1891,
    'Bryukhovetskaya': 1891,
    'Chamlykskaya': 1891,
    'Chelbasskaya': 1891,
    'Chernomorskiy': 1891,
    'Chernoyerkovskaya': 1891,
    'Dagomys': 1891,
    'Dinskaya': 1891,
    'Divnomorskoye': 1891,
    'Dmitriyevskaya': 1891,
    'Dneprovskaya': 1891,
    'Dolzhanskaya': 1891,
    'Dvubratskiy': 1891,
    'Dzhiginka': 1891,
    'Dzhubga': 1891,
    'Fastovetskaya': 1891,
    'Gayduk': 1891,
    'Gelendzhik': 1891,
    'Girey': 1891,
    'Glafirovka': 1891,
    'Glubokiy': 1891,
    'Golubitskaya': 1891,
    'Gornoye Loo': 1891,
    'Goryachiy Klyuch': 1891,
    'Gostagayevskaya': 1891,
    'Grivenskaya': 1891,
    'Gubskaya': 1891,
    'Gul’kevichi': 1891,
    'Il’ich': 1891,
    'Il’skiy': 1891,
    'Irkliyevskaya': 1891,
    'Kabardinka': 1891,
    'Kalininskaya': 1891,
    'Kalnibolotskaya': 1891,
    'Kamyshevatskaya': 1891,
    'Kanelovskaya': 1891,
    'Kanevskaya': 1891,
    'Kavkazskaya': 1891,
    'Kazanskaya': 1891,
    'Khadyzhensk': 1891,
    'Kholmskiy': 1891,
    'Khosta': 1891,
    'Kislyakovskaya': 1891,
    'Kiyevskoye': 1891,
    'Konokovo': 1891,
    'Konstantinovskaya': 1891,
    'Kontenko': 1891,
    'Kopanskaya': 1891,
    'Korenovsk': 1891,
    'Korzhevskiy': 1891,
    'Kovalevskoye': 1891,
    'Krasnaya Polyana': 1891,
    'Krasnoarmeyskaya': 1891,
    'Krasnodar': 1891,
    'Krasnoye': 1891,
    'Kropotkin': 1891,
    'Krylovskaya': 1891,
    'Krylovskiy Rayon': 1891,
    'Krymsk': 1891,
    'Krymskiy Rayon': 1891,
    'Kubanskiy': 1891,
    'Kuchugury': 1891,
    'Kudepsta': 1891,
    'Kurganinsk': 1891,
    'Kushchëvskaya': 1891,
    'Labinsk': 1891,
    'Ladozhskaya': 1891,
    'Lazarevskoye': 1891,
    'Lenina': 1891,
    'Leningradskaya': 1891,
    'Lermontovo': 1891,
    'Lovlinskaya': 1891,
    'Maykopskoye': 1891,
    'Medvedovskaya': 1891,
    'Mikhaylovskaya': 1891,
    'Mirskoy': 1891,
    'Mostovskiy Rayon': 1891,
    'Mostovskoy': 1891,
    'Myskhako': 1891,
    'Nebug': 1891,
    'Neftegorsk': 1891,
    'Nekrasovskaya': 1891,
    'Novoalekseyevskaya': 1891,
    'Novobeysugskaya': 1891,
    'Novoderevyankovskaya': 1891,
    'Novodmitriyevskaya': 1891,
    'Novodzhereliyevskaya': 1891,
    'Novokorsunskaya': 1891,
    'Novokubansk': 1891,
    'Novokubanskiy Rayon': 1891,
    'Novolabinskaya': 1891,
    'Novoleushkovskaya': 1891,
    'Novomalorossiyskaya': 1891,
    'Novomikhaylovskiy': 1891,
    'Novominskaya': 1891,
    'Novomyshastovskaya': 1891,
    'Novoplatnirovskaya': 1891,
    'Novopokrovskaya': 1891,
    'Novorossiysk': 1891,
    'Novorozhdestvenskaya': 1891,
    'Novoshcherbinovskaya': 1891,
    'Novotitarovskaya': 1891,
    'Novoukrainskiy': 1891,
    'Novoukrainskoye': 1891,
    'Novovelichkovskaya': 1891,
    'Ol’ginka': 1891,
    'Ol’ginskaya': 1891,
    'Orël-Izumrud': 1891,
    'Otradnaya': 1891,
    'Otrado-Kubanskoye': 1891,
    'Parkovyy': 1891,
    'Pashkovskiy': 1891,
    'Pavlovskaya': 1891,
    'Pavlovskiy Rayon': 1891,
    'Peredovaya': 1891,
    'Perepravnaya': 1891,
    'Pereyaslovskaya': 1891,
    'Petropavlovskaya': 1891,
    'Petrovskaya': 1891,
    'Plastunovskaya': 1891,
    'Platnirovskaya': 1891,
    'Poputnaya': 1891,
    'Prikubanskiy': 1891,
    'Primorsko-Akhtarsk': 1891,
    'Prochnookopskaya': 1891,
    'Psebay': 1891,
    'Pshada': 1891,
    'Pshekhskaya': 1891,
    'Rayevskaya': 1891,
    'Rodnikovskaya': 1891,
    'Rogovskaya': 1891,
    'Ryazanskaya': 1891,
    'Saratovskaya': 1891,
    'Severskaya': 1891,
    'Shabel’skoye': 1891,
    'Shedok': 1891,
    'Shepsi': 1891,
    'Shirochanka': 1891,
    'Shkurinskaya': 1891,
    'Slavyansk-na-Kubani': 1891,
    'Slavyansky rayon': 1891,
    'Smolenskaya': 1891,
    'Sochi': 1891,
    'Sokolovskoye': 1891,
    'Sovetskaya': 1891,
    'Sovkhoznyy': 1891,
    'Spokoynaya': 1891,
    'Staraya Stanitsa': 1891,
    'Staroderevyankovskaya': 1891,
    'Starodzhereliyevskaya': 1891,
    'Starokorsunskaya': 1891,
    'Staroleushkovskaya': 1891,
    'Starominskaya': 1891,
    'Staromyshastovskaya': 1891,
    'Staronizhestebliyevskaya': 1891,
    'Staroshcherbinovskaya': 1891,
    'Starotitarovskaya': 1891,
    'Starovelichkovskaya': 1891,
    'Strelka': 1891,
    'Sukko': 1891,
    'Taman’': 1891,
    'Tbilisskaya': 1891,
    'Temirgoyevskaya': 1891,
    'Temizhbekskaya': 1891,
    'Temruksky rayon': 1891,
    'Temryuk': 1891,
    'Ternevskaya': 1891,
    'Tikhoretsk': 1891,
    'Tikhoretskiy Rayon': 1891,
    'Timashëvsk': 1891,
    'Troitskaya': 1891,
    'Trudobelikovskiy': 1891,
    'Tsibanobalka': 1891,
    'Tuapse': 1891,
    'Tuapsinskiy Rayon': 1891,
    'Udobnaya': 1891,
    'Upornaya': 1891,
    'Uspenskaya': 1891,
    'Uspenskoye': 1891,
    'Ust’-Labinsk': 1891,
    'Vardane': 1891,
    'Varenikovskaya': 1891,
    'Vasyurinskaya': 1891,
    'Velikovechnoye': 1891,
    'Ventsy': 1891,
    'Verkhnebakanskiy': 1891,
    'Vinogradnyy': 1891,
    'Vityazevo': 1891,
    'Vladimirskaya': 1891,
    'Vorontsovka': 1891,
    'Voznesenskaya': 1891,
    'Vyselki': 1891,
    'Vyselkovskiy Rayon': 1891,
    'Vyshestebliyevskaya': 1891,
    'Vysokoye': 1891,
    'Yaroslavskaya': 1891,
    'Yasenskaya': 1891,
    'Yelizavetinskaya': 1891,
    'Yeysk': 1891,
    'Yeyskoye Ukrepleniye': 1891,
    'Yurovka': 1891,
    'Yuzhnyy': 1891,
    'Zavetnyy': 1891,
    'Aban': 1840,
    'Abanskiy Rayon': 1840,
    'Achinsk': 1840,
    'Achinskiy Rayon': 1840,
    'Aginskoye': 1840,
    'Balakhta': 1840,
    'Balakhtinskiy Rayon': 1840,
    'Baykit': 1840,
    'Berëzovka': 1840,
    'Berezovskiy rayon': 1840,
    'Birilyusskiy Rayon': 1840,
    'Bogotol': 1840,
    'Bogotol’skiy Rayon': 1840,
    'Boguchanskiy Rayon': 1840,
    'Boguchany': 1840,
    'Bol’shemurtinskiy Rayon': 1840,
    'Bol’sheuluyskiy Rayon': 1840,
    'Bor': 1840,
    'Borodino': 1840,
    'Chunoyar': 1840,
    'Dikson': 1840,
    'Divnogorsk': 1840,
    'Dubinino': 1840,
    'Dudinka': 1840,
    'Dzerzhinskoye': 1840,
    'Evenkiyskiy District': 1840,
    'Filimonovo': 1840,
    'Idrinskiy Rayon': 1840,
    'Idrinskoye': 1840,
    'Igarka': 1840,
    'Il’ichevo': 1840,
    'Ilanskiy': 1840,
    'Ilanskiy Rayon': 1840,
    'Irbeyskiy Rayon': 1840,
    'Irbeyskoye': 1840,
    'Irsha': 1840,
    'Kansk': 1840,
    'Kanskiy Rayon': 1840,
    'Karatuzskoye': 1840,
    'Kayyerkan': 1840,
    'Kazachinskiy Rayon': 1840,
    'Kazachinskoye': 1840,
    'Kedrovyy': 1840,
    'Khatanga': 1840,
    'Kodinsk': 1840,
    'Koshurnikovo': 1840,
    'Krasnokamensk': 1840,
    'Krasnoturansk': 1840,
    'Krasnoyarsk': 1840,
    'Kuragino': 1840,
    'Lesosibirsk': 1840,
    'Manskiy Rayon': 1840,
    'Manzya': 1840,
    'Minusinsk': 1840,
    'Minusinskiy Rayon': 1840,
    'Motygino': 1840,
    'Nazarovo': 1840,
    'Nazarovskiy Rayon': 1840,
    'Nizhneingashskiy Rayon': 1840,
    'Nizhniy Ingash': 1840,
    'Nizhnyaya Poyma': 1840,
    'Norilsk': 1840,
    'Novobirilyussy': 1840,
    'Novochernorechenskiy': 1840,
    'Ovsyanka': 1840,
    'Partizanskiy Rayon': 1840,
    'Partizanskoye': 1840,
    'Pirovskoye': 1840,
    'Predivinsk': 1840,
    'Rassvet': 1840,
    'Razdolinsk': 1840,
    'Sayansk': 1840,
    'Sayanskiy Rayon': 1840,
    'Severo-Yeniseyskiy': 1840,
    'Severo-Yeniseyskiy Rayon': 1840,
    'Shalinskoye': 1840,
    'Sharypovo': 1840,
    'Sharypovskiy Rayon': 1840,
    'Shushenskiy Rayon': 1840,
    'Shushenskoye': 1840,
    'Snezhnogorsk': 1840,
    'Solnechnyy': 1840,
    'Sosnovoborsk': 1840,
    'Strelka': 1840,
    'Sukhobuzimskoye': 1840,
    'Svetlogorsk': 1840,
    'Talnakh': 1840,
    'Tanzybey': 1840,
    'Taseyevo': 1840,
    'Taymyrsky Dolgano-Nenetsky District': 1840,
    'Teya': 1840,
    'Tinskoy': 1840,
    'Tura': 1840,
    'Turukhansk': 1840,
    'Tyukhtet': 1840,
    'Ural': 1840,
    'Uyar': 1840,
    'Uyarskiy Rayon': 1840,
    'Uzhur': 1840,
    'Vanavara': 1840,
    'Yemel’yanovo': 1840,
    'Yemel’yanovskiy Rayon': 1840,
    'Yeniseysk': 1840,
    'Yeniseyskiy Rayon': 1840,
    'Yermakovskoye': 1840,
    'Zelenogorsk': 1840,
    'Zelënyy Bor': 1840,
    'Zheleznogorsk': 1840,
    'Zykovo': 1840,
    'Belozërskoye': 1915,
    'Dalmatovo': 1915,
    'Glyadyanskoye': 1915,
    'Ikovka': 1915,
    'Kataysk': 1915,
    'Ketovo': 1915,
    'Krasnyy Oktyabr’': 1915,
    'Kurgan': 1915,
    'Kurtamysh': 1915,
    'Lebyazh’ye': 1915,
    'Lebyazh’yevskiy Rayon': 1915,
    'Lesnikovo': 1915,
    'Makushino': 1915,
    'Mishkino': 1915,
    'Mokrousovo': 1915,
    'Mokrousovskiy Rayon': 1915,
    'Petukhovo': 1915,
    'Polovinnoye': 1915,
    'Prosvet': 1915,
    'Safakulevo': 1915,
    'Safakulevskiy Rayon': 1915,
    'Shadrinsk': 1915,
    'Shatrovo': 1915,
    'Shumikha': 1915,
    'Shumikhinskiy Rayon': 1915,
    'Tselinnoye': 1915,
    'Vargashi': 1915,
    'Vargashinskiy Rayon': 1915,
    'Vvedenskoye': 1915,
    'Yurgamysh': 1915,
    'Zverinogolovskoye': 1915,
    'Belaya': 1855,
    'Cheremisinovo': 1855,
    'Cheremisinovskiy Rayon': 1855,
    'Chernitsyno': 1855,
    'Fatezh': 1855,
    'Fatezhskiy Rayon': 1855,
    'Glushkovo': 1855,
    'Gorshechenskiy Rayon': 1855,
    'Gorshechnoye': 1855,
    'Ivanino': 1855,
    'Kastorenskiy Rayon': 1855,
    'Kastornoye': 1855,
    'Khomutovka': 1855,
    'Kirovskiy': 1855,
    'Konyshevka': 1855,
    'Korenëvo': 1855,
    'Kshenskiy': 1855,
    'Kurchatov': 1855,
    'Kursk': 1855,
    'Kurskiy Rayon': 1855,
    'L’govskiy': 1855,
    'L’govskiy Rayon': 1855,
    'Manturovo': 1855,
    'Medvenskiy Rayon': 1855,
    'Mikhaylovka': 1855,
    'Oboyan’': 1855,
    'Oboyanskiy Rayon': 1855,
    'Olym': 1855,
    'Pervoavgustovskiy': 1855,
    'Ponyri Vtoryye': 1855,
    'Pristen’': 1855,
    'Pristenskiy Rayon': 1855,
    'Pryamitsyno': 1855,
    'Ryl’sk': 1855,
    'Shchigry': 1855,
    'Solntsevo': 1855,
    'Solntsevskiy Rayon': 1855,
    'Sovetskiy Rayon': 1855,
    'Sudzha': 1855,
    'Tim': 1855,
    'Voroshnëvo': 1855,
    'Zheleznogorsk': 1855,
    'Zolotukhino': 1855,
    'Zolotukhinskiy Rayon': 1855,
    'Agalatovo': 1896,
    'Akademicheskoe': 1896,
    'Annino': 1896,
    'Begunitsy': 1896,
    'Boksitogorsk': 1896,
    'Boksitogorskiy Rayon': 1896,
    'Bol’shaya Izhora': 1896,
    'Borisova Griva': 1896,
    'Budogoshch’': 1896,
    'Bugry': 1896,
    'Chernaya Rechka': 1896,
    'Druzhnaya Gorka': 1896,
    'Fëdorovskoye': 1896,
    'Finlyandskiy': 1896,
    'Fornosovo': 1896,
    'Garbolovo': 1896,
    'Gatchina': 1896,
    'Glebychevo': 1896,
    'Gorbunki': 1896,
    'Gorskaya': 1896,
    'Gostilitsy': 1896,
    'Il’ichëvo': 1896,
    'Imeni Morozova': 1896,
    'Imeni Sverdlova': 1896,
    'Issad': 1896,
    'Ivangorod': 1896,
    'Kalininskiy': 1896,
    'Kamenka': 1896,
    'Kamennogorsk': 1896,
    'Khvalovo': 1896,
    'Kikerino': 1896,
    'Kingisepp': 1896,
    'Kingiseppskiy Rayon': 1896,
    'Kipen’': 1896,
    'Kirishi': 1896,
    'Kirovsk': 1896,
    'Kirovskiy Rayon': 1896,
    'Kobrinskoye': 1896,
    'Kolchanovo': 1896,
    'Koltushi': 1896,
    'Kommunar': 1896,
    'Kopor’ye': 1896,
    'Korobitsyno': 1896,
    'Krasnyy Bor': 1896,
    'Krestovskiy ostrov': 1896,
    'Kurortnyy': 1896,
    'Kuyvozi': 1896,
    'Kuznechnoye': 1896,
    'Lebyazh’ye': 1896,
    'Leninskoye': 1896,
    'Leskolovo': 1896,
    'Lesogorskiy': 1896,
    'Lodeynopol’skiy Rayon': 1896,
    'Lodeynoye Pole': 1896,
    'Lomonosovskiy Rayon': 1896,
    'Luga': 1896,
    'Luppolovo': 1896,
    'Luzhskiy Rayon': 1896,
    'Lyuban’': 1896,
    'Maloye Verevo': 1896,
    'Mariyenburg': 1896,
    'Mga': 1896,
    'Michurinskoye': 1896,
    'Mshinskaya': 1896,
    'Murino': 1896,
    'Nikol’skoye': 1896,
    'Novaya Ladoga': 1896,
    'Novoye Devyatkino': 1896,
    'Nurma': 1896,
    'Ol’gino': 1896,
    'Osel’ki': 1896,
    'Otradnoye': 1896,
    'Parnas': 1896,
    'Pasha': 1896,
    'Pavlovo': 1896,
    'Pervomayskoye': 1896,
    'Pikalëvo': 1896,
    'Podporozh’ye': 1896,
    'Podporozhskiy Rayon': 1896,
    'Priladozhskiy': 1896,
    'Primorsk': 1896,
    'Priozërsk': 1896,
    'Priozersky': 1896,
    'Romanovka': 1896,
    'Roshchino': 1896,
    'Rozhdestveno': 1896,
    'Russko-Vysotskoye': 1896,
    'Ryabovo': 1896,
    'Rybatskoye': 1896,
    'Sampsonievskiy': 1896,
    'Sapernoye': 1896,
    'Semiozerje': 1896,
    'Sertolovo': 1896,
    'Shcheglovo': 1896,
    'Shlissel’burg': 1896,
    'Sinyavino': 1896,
    'Sista-Palkino': 1896,
    'Siverskiy': 1896,
    'Slantsevskiy Rayon': 1896,
    'Slantsy': 1896,
    'Sosnovo': 1896,
    'Sosnovyy Bor': 1896,
    'Sovetskiy': 1896,
    'Staraya': 1896,
    'Staraya Ladoga': 1896,
    'Svetlanovskiy': 1896,
    'Svetogorsk': 1896,
    'Syas’stroy': 1896,
    'Taytsy': 1896,
    'Tikhvin': 1896,
    'Tikhvinskiy Rayon': 1896,
    'Toksovo': 1896,
    'Tolmachevo': 1896,
    'Tosnenskiy Rayon': 1896,
    'Tosno': 1896,
    'Udel’naya': 1896,
    'Ul’yanovka': 1896,
    'Untolovo': 1896,
    'Ust’-Luga': 1896,
    'Vaskelovo': 1896,
    'Vazhiny': 1896,
    'Verkhniye Osel’ki': 1896,
    'Veshchevo': 1896,
    'Vinnitsy': 1896,
    'Vistino': 1896,
    'Volkhov': 1896,
    'Volkhovskiy rayon': 1896,
    'Volosovo': 1896,
    'Volosovskiy Rayon': 1896,
    'Voyskovitsy': 1896,
    'Voznesen’ye': 1896,
    'Vsevolozhsk': 1896,
    'Vsevolozhskiy Rayon': 1896,
    'Vyborg': 1896,
    'Vyritsa': 1896,
    'Vysotsk': 1896,
    'Yakovlevo': 1896,
    'Yanino-1': 1896,
    'Yaroslavichi': 1896,
    'Yefimovskiy': 1896,
    'Yelizavetino': 1896,
    'Bol’shoy Khomutets': 1889,
    'Borinskoye': 1889,
    'Chaplygin': 1889,
    'Chaplyginskiy Rayon': 1889,
    'Dankov': 1889,
    'Dankovskiy Rayon': 1889,
    'Dobrinka': 1889,
    'Dobrinskiy Rayon': 1889,
    'Dobrovskiy Rayon': 1889,
    'Dobroye': 1889,
    'Dolgorukovo': 1889,
    'Dolgorukovskiy Rayon': 1889,
    'Donskoye': 1889,
    'Gryazi': 1889,
    'Gryazinskiy Rayon': 1889,
    'Izmalkovo': 1889,
    'Izmalkovskiy Rayon': 1889,
    'Kalikino': 1889,
    'Kazaki': 1889,
    'Kazinka': 1889,
    'Khlevenskiy Rayon': 1889,
    'Khlevnoye': 1889,
    'Krasninskiy Rayon': 1889,
    'Krasnoye': 1889,
    'Kuz’minskiye Otverzhki': 1889,
    'Lebedyan’': 1889,
    'Lebedyanskiy Rayon': 1889,
    'Lev Tolstoy': 1889,
    'Lev-Tolstovskiy Rayon': 1889,
    'Lipetsk': 1889,
    'Lipetskiy Rayon': 1889,
    'Petrovskiy': 1889,
    'Plekhanovo': 1889,
    'Prigorodka': 1889,
    'Stanovlyanskiy Rayon': 1889,
    'Stanovoye': 1889,
    'Syrskoye': 1889,
    'Terbunskiy Rayon': 1889,
    'Terbuny': 1889,
    'Usman’': 1889,
    'Usmanskiy Rayon': 1889,
    'Volovo': 1889,
    'Volovskiy Rayon': 1889,
    'Yelets': 1889,
    'Yeletskiy Rayon': 1889,
    'Zadonsk': 1889,
    'Zadonskiy Rayon': 1889,
    'Arman’': 1839,
    'Dukat': 1839,
    'Evensk': 1839,
    'Gorod Magadan': 1839,
    'Kholodnyy': 1839,
    'Magadan': 1839,
    'Myaundzha': 1839,
    'Ol’skiy Rayon': 1839,
    'Ola': 1839,
    'Omsukchan': 1839,
    'Omsukchanskiy Rayon': 1839,
    'Orotukan': 1839,
    'Palatka': 1839,
    'Seymchan': 1839,
    'Sinegor  ye': 1839,
    'Sokol': 1839,
    'Srednekanskiy Rayon': 1839,
    'Susuman': 1839,
    'Talaya': 1839,
    'Uptar': 1839,
    'Ust’-Omchug': 1839,
    'Yagodnoye': 1839,
    'Gornomariyskiy Rayon': 1870,
    'Kilemarskiy Rayon': 1870,
    'Kilemary': 1870,
    'Koz’modem’yansk': 1870,
    'Krasnogorskiy': 1870,
    'Kuzhener': 1870,
    'Kuzhenerskiy Rayon': 1870,
    'Mari-Turek': 1870,
    'Mari-Turekskiy Rayon': 1870,
    'Medvedevo': 1870,
    'Medvedevskiy Rayon': 1870,
    'Mochalishche': 1870,
    'Morki': 1870,
    'Morkinskiy Rayon': 1870,
    'Novotor”yal’skiy Rayon': 1870,
    'Orshanka': 1870,
    'Orshanskiy Rayon': 1870,
    'Paran’ga': 1870,
    'Paran’ginskiy Rayon': 1870,
    'Pomary': 1870,
    'Privolzhskiy': 1870,
    'Sernur': 1870,
    'Sernurskiy Rayon': 1870,
    'Sovetskiy': 1870,
    'Sovetskiy Rayon': 1870,
    'Surok': 1870,
    'Suslonger': 1870,
    'Vilovatovo': 1870,
    'Volzhsk': 1870,
    'Volzhskiy Rayon': 1870,
    'Yoshkar-Ola': 1870,
    'Yurino': 1870,
    'Yurinskiy Rayon': 1870,
    'Zvenigovo': 1870,
    'Zvenigovskiy Rayon': 1870,
    'Altuf’yevskiy': 1901,
    'Amin’yevo': 1901,
    'Annino': 1901,
    'Babushkin': 1901,
    'Bibirevo': 1901,
    'Biryulëvo': 1901,
    'Bogorodskoye': 1901,
    'Brateyevo': 1901,
    'Cherëmushki': 1901,
    'Chertanovo Yuzhnoye': 1901,
    'Davydkovo': 1901,
    'Gol’yanovo': 1901,
    'Golovinskiy': 1901,
    'Ivanovskoye': 1901,
    'Kapotnya': 1901,
    'Kokoshkino': 1901,
    'Kolomenskoye': 1901,
    'Kotlovka': 1901,
    'Kozeyevo': 1901,
    'Krasnaya Pahra': 1901,
    'Kur’yanovo': 1901,
    'Kuz’minki': 1901,
    'Lefortovo': 1901,
    'Leonovo': 1901,
    'Lianozovo': 1901,
    'Likhobory': 1901,
    'LMS': 1901,
    'Luzhniki': 1901,
    'Lyublino': 1901,
    'Mar’ino': 1901,
    'Matveyevskoye': 1901,
    'Metrogorodok': 1901,
    'Mikhalkovo': 1901,
    'Moscow': 1901,
    'Moskovskiy': 1901,
    'Nagornyy': 1901,
    'Nekrasovka': 1901,
    'Nikol’skoye': 1901,
    'Nikulino': 1901,
    'Novo-Peredelkino': 1901,
    'Novogireyevo': 1901,
    'Novokhovrino': 1901,
    'Novokuz’minki': 1901,
    'Novovladykino': 1901,
    'Novyye Cherëmushki': 1901,
    'Novyye Kuz’minki': 1901,
    'Ochakovo-Matveyevskoye': 1901,
    'Orekhovo-Borisovo': 1901,
    'Orekhovo-Borisovo Severnoye': 1901,
    'Ostankinskiy': 1901,
    'Otradnoye': 1901,
    'Pokrovskoye-Streshnëvo': 1901,
    'Presnenskiy': 1901,
    'Ramenki': 1901,
    'Rostokino': 1901,
    'Rublëvo': 1901,
    'Ryazanskiy': 1901,
    'Setun’': 1901,
    'Severnyy': 1901,
    'Shcherbinka': 1901,
    'Shchukino': 1901,
    'Slobodka': 1901,
    'Sokol': 1901,
    'Sokol’niki': 1901,
    'Solntsevo': 1901,
    'Strogino': 1901,
    'Sviblovo': 1901,
    'Taganskiy': 1901,
    'Tekstil’shchiki': 1901,
    'Tolstopal’tsevo': 1901,
    'Troitsk': 1901,
    'Troparëvo': 1901,
    'Tsaritsyno': 1901,
    'Tsentral’nyy Administrativnyy Okrug': 1901,
    'Tyoply Stan': 1901,
    'Vagonoremont': 1901,
    'Vatutinki': 1901,
    'Vatutino': 1901,
    'Veshnyaki': 1901,
    'Vnukovo': 1901,
    'Vorob’yovo': 1901,
    'Vostochnoe Degunino': 1901,
    'Vostochnyy': 1901,
    'Vykhino-Zhulebino': 1901,
    'Yaroslavskiy': 1901,
    'Yasenevo': 1901,
    'Zagor’ye': 1901,
    'Zamoskvorech’ye': 1901,
    'Zelenograd': 1901,
    'Zhulebino': 1901,
    'Zyablikovo': 1901,
    'Zyuzino': 1901,
    'Alabushevo': 1882,
    'Alekseyevka': 1882,
    'Andreyevka': 1882,
    'Andreyevskoye': 1882,
    'Annino': 1882,
    'Aprelevka': 1882,
    'Arkhangel’skoye': 1882,
    'Ashitkovo': 1882,
    'Ashukino': 1882,
    'Ateptsevo': 1882,
    'Avsyunino': 1882,
    'Avtopoligon': 1882,
    'Bakhchivandzhi': 1882,
    'Baksheyevo': 1882,
    'Balashikha': 1882,
    'Balashikha Urban Okrug': 1882,
    'Barvikha': 1882,
    'Barybino': 1882,
    'Beloomut': 1882,
    'Beloozërskiy': 1882,
    'Belyye Stolby': 1882,
    'Biorki': 1882,
    'Biryulëvo Zapadnoye': 1882,
    'Bogorodskoye': 1882,
    'Bol  shiye Vyazëmy': 1882,
    'Bol’shaya Setun’': 1882,
    'Bol’shoye Gryzlovo': 1882,
    'Bolshevo': 1882,
    'Bratovshchina': 1882,
    'Bronnitsy': 1882,
    'Budenovetc': 1882,
    'Bykovo': 1882,
    'Chashnikovo': 1882,
    'Chekhov': 1882,
    'Cherkizovo': 1882,
    'Chernogolovka': 1882,
    'Cherusti': 1882,
    'Chkalovskiy': 1882,
    'Chornoye': 1882,
    'Chupryakovo': 1882,
    'Danki': 1882,
    'Davydovo': 1882,
    'Dedenëvo': 1882,
    'Dedovsk': 1882,
    'Demikhovo': 1882,
    'Dmitrov': 1882,
    'Dmitrovskiy Pogost': 1882,
    'Dmitrovskiy Rayon': 1882,
    'Dolgoprudnyy': 1882,
    'Domodedovo': 1882,
    'Domodedovskiy Rayon': 1882,
    'Dorogomilovo': 1882,
    'Dorokhovo': 1882,
    'Drezna': 1882,
    'Druzhba': 1882,
    'Dubna': 1882,
    'Dubrovitsy': 1882,
    'Dzerzhinskiy': 1882,
    'Dzerzhinsky': 1882,
    'Elektrogorsk': 1882,
    'Elektrostal’': 1882,
    'Elektrougli': 1882,
    'Fedurnovo': 1882,
    'Fili': 1882,
    'Filimonki': 1882,
    'Firsanovka': 1882,
    'Fosforitnyy': 1882,
    'Fryanovo': 1882,
    'Fryazevo': 1882,
    'Fryazino': 1882,
    'Golitsyno': 1882,
    'Gorki Vtoryye': 1882,
    'Gorki-Leninskiye': 1882,
    'Gorshkovo': 1882,
    'Grebnevo': 1882,
    'Gzhel’': 1882,
    'Iksha': 1882,
    'Il’inskiy': 1882,
    'Il’inskiy Pogost': 1882,
    'Il’inskoye': 1882,
    'Imeni Tsyurupy': 1882,
    'Imeni Vorovskogo': 1882,
    'Istra': 1882,
    'Istrinskiy Rayon': 1882,
    'Ivanteyevka': 1882,
    'Izmaylovo': 1882,
    'Kabanovo': 1882,
    'Karinskoye': 1882,
    'Kashira': 1882,
    'Kastanayevo': 1882,
    'Kerva': 1882,
    'Khimki': 1882,
    'Khimki Urban Okrug': 1882,
    'Khorlovo': 1882,
    'Khoroshëvo-Mnevniki': 1882,
    'Khot  kovo': 1882,
    'Kievskiy': 1882,
    'Klimovsk': 1882,
    'Klin': 1882,
    'Klinskiy Rayon': 1882,
    'Kolomenskiy Rayon': 1882,
    'Kolomna': 1882,
    'Kolyubakino': 1882,
    'Kommunarka': 1882,
    'Konobeyevo': 1882,
    'Konstantinovo': 1882,
    'Koptëvo': 1882,
    'Korenëvo': 1882,
    'Korolev': 1882,
    'Korolyov': 1882,
    'Kostino': 1882,
    'Kostrovo': 1882,
    'Kotel’niki': 1882,
    'Kozhukhovo': 1882,
    'Kraskovo': 1882,
    'Krasnaya Poyma': 1882,
    'Krasnoarmeysk': 1882,
    'Krasnogorsk': 1882,
    'Krasnogorskiy Rayon': 1882,
    'Krasnozavodsk': 1882,
    'Krasnoznamensk': 1882,
    'Krasnyy Tkach': 1882,
    'Kratovo': 1882,
    'Krivtsovo': 1882,
    'Kubinka': 1882,
    'Kupavna': 1882,
    'Kurilovo': 1882,
    'Kurovskoye': 1882,
    'Kuskovo': 1882,
    'L’vovskiy': 1882,
    'Leninskiy Rayon': 1882,
    'Leninskiye Gory': 1882,
    'Lesnoy': 1882,
    'Lesnoy Gorodok': 1882,
    'Lesnyye Polyany': 1882,
    'Levoberezhnyy': 1882,
    'Likino-Dulevo': 1882,
    'Lobnya': 1882,
    'Lopatinskiy': 1882,
    'Losino-Petrovskiy': 1882,
    'Lotoshino': 1882,
    'Lotoshinskiy Rayon': 1882,
    'Lozhki': 1882,
    'Lugovaya': 1882,
    'Lukhovitsy': 1882,
    'Lytkarino': 1882,
    'Lyuberetskiy Rayon': 1882,
    'Lyubertsy': 1882,
    'Lyubuchany': 1882,
    'Malakhovka': 1882,
    'Malaya Dubna': 1882,
    'Malino': 1882,
    'Malyshevo': 1882,
    'Mamontovka': 1882,
    'Marfino': 1882,
    'Medvezh’i Ozëra': 1882,
    'Melikhovo': 1882,
    'Mendeleyevo': 1882,
    'Meshcherino': 1882,
    'Mikhnëvo': 1882,
    'Mikulino': 1882,
    'Misheronskiy': 1882,
    'Molokovo': 1882,
    'Monino': 1882,
    'Mosrentgen': 1882,
    'Mozhaysk': 1882,
    'Mozhayskiy Rayon': 1882,
    'Muranovo': 1882,
    'Mytishchi': 1882,
    'Mytishchi Urban Okrug': 1882,
    'Nakhabino': 1882,
    'Naro-Fominsk': 1882,
    'Nazar  yevo': 1882,
    'Nekrasovskiy': 1882,
    'Nemchinovka': 1882,
    'Nikolina Gora': 1882,
    'Noginsk': 1882,
    'Noginsk-9': 1882,
    'Noginskiy Rayon': 1882,
    'Novo-Nikol’skoye': 1882,
    'Novoivanovskoye': 1882,
    'Novopetrovskoye': 1882,
    'Novopodrezkovo': 1882,
    'Novosin’kovo': 1882,
    'Novoye': 1882,
    'Novyy Byt': 1882,
    'Nudol’': 1882,
    'Obolensk': 1882,
    'Obukhovo': 1882,
    'Odintsovo': 1882,
    'Odintsovskiy Rayon': 1882,
    'Oktyabr’skiy': 1882,
    'Ol  yavidovo': 1882,
    'Opalikha': 1882,
    'Orekhovo-Zuyevo': 1882,
    'Orekhovo-Zuyevskiy Rayon': 1882,
    'Orud’yevo': 1882,
    'Ostashëvo': 1882,
    'Ostrovtsy': 1882,
    'Ozëry': 1882,
    'Ozherel’ye': 1882,
    'Ozyory Urban Okrug': 1882,
    'Pavlovskaya Sloboda': 1882,
    'Pavlovskiy Posad': 1882,
    'Pavshino': 1882,
    'Peresvet': 1882,
    'Pervomayskiy': 1882,
    'Peshki': 1882,
    'Peski': 1882,
    'Petrovo-Dal’neye': 1882,
    'Petrovskaya': 1882,
    'Pirogovskiy': 1882,
    'Podolsk': 1882,
    'Podosinki': 1882,
    'Popovo': 1882,
    'Posëlok Mar’ino': 1882,
    'Poselok Turisticheskogo pansionata "Klyazminskoe vodohranilische"': 1882,
    'Povarovo': 1882,
    'Povedniki': 1882,
    'Pravdinskiy': 1882,
    'Proletarskiy': 1882,
    'Protvino': 1882,
    'Purshevo': 1882,
    'Pushchino': 1882,
    'Pushkino': 1882,
    'Pushkinskiy Rayon': 1882,
    'Radovitskiy': 1882,
    'Radumlya': 1882,
    'Raduzhnyy': 1882,
    'Rakhmanovo': 1882,
    'Ramenskiy Rayon': 1882,
    'Ramenskoye': 1882,
    'Razvilka': 1882,
    'Rechitsy': 1882,
    'Reshetnikovo': 1882,
    'Reutov': 1882,
    'Rodniki': 1882,
    'Rogachëvo': 1882,
    'Roshal’': 1882,
    'Rozhdestveno': 1882,
    'Rumyantsevo': 1882,
    'Ruza': 1882,
    'Ruzskiy Rayon': 1882,
    'Rybnoye': 1882,
    'Rzhavki': 1882,
    'Saltykovka': 1882,
    'Savinskaya': 1882,
    'Savvinskaya Sloboda': 1882,
    'Selyatino': 1882,
    'Semënovskoye': 1882,
    'Semkhoz': 1882,
    'Serebryano-Prudskiy Rayon': 1882,
    'Serebryanyye Prudy': 1882,
    'Sergiyev Posad': 1882,
    'Sergiyevo-Posadskiy Rayon': 1882,
    'Serpukhov': 1882,
    'Serpukhovskiy Rayon': 1882,
    'Severnyy': 1882,
    'Shaburnovo': 1882,
    'Shakhovskaya': 1882,
    'Shakhovskoy Rayon': 1882,
    'Shatura': 1882,
    'Shaturtorf': 1882,
    'Shchelkovo': 1882,
    'Shchëlkovskiy Rayon': 1882,
    'Shemëtovo': 1882,
    'Sheremet’yevskiy': 1882,
    'Shevlyakovo': 1882,
    'Shishkin Les': 1882,
    'Skhodnya': 1882,
    'Skolkovo': 1882,
    'Skoropuskovskiy': 1882,
    'Snegiri': 1882,
    'Sofrino': 1882,
    'Solnechnogorsk': 1882,
    'Solnechnogorskiy Rayon': 1882,
    'Spas-Zaulok': 1882,
    'Staraya Kupavna': 1882,
    'Stolbovaya': 1882,
    'Stromyn’': 1882,
    'Stupino': 1882,
    'Stupinskiy Rayon': 1882,
    'Svatkovo': 1882,
    'Sverdlovskiy': 1882,
    'Sychëvo': 1882,
    'Taldom': 1882,
    'Taldomskiy Rayon': 1882,
    'Tarasovka': 1882,
    'Teryayevo': 1882,
    'Tomilino': 1882,
    'Troitskoye': 1882,
    'Tuchkovo': 1882,
    'Tugolesskiy Bor': 1882,
    'Udel’naya': 1882,
    'Uvarovka': 1882,
    'Uzunovo': 1882,
    'Verbilki': 1882,
    'Vereya': 1882,
    'Vidnoye': 1882,
    'Vinogradovo': 1882,
    'Vishnyakovskiye Dachi': 1882,
    'Vniissok': 1882,
    'Volodarskogo': 1882,
    'Volokolamsk': 1882,
    'Volokolamskiy Rayon': 1882,
    'Voskresensk': 1882,
    'Voskresenskiy Rayon': 1882,
    'Vostryakovo': 1882,
    'Vysokovsk': 1882,
    'Yakhroma': 1882,
    'Yakovlevskoye': 1882,
    'Yam': 1882,
    'Yamkino': 1882,
    'Yaropolets': 1882,
    'Yegor’yevsk': 1882,
    'Yel’digino': 1882,
    'Yermolino': 1882,
    'Yershovo': 1882,
    'Yubileyny': 1882,
    'Zagoryanskiy': 1882,
    'Zaprudnya': 1882,
    'Zaraysk': 1882,
    'Zarech’ye': 1882,
    'Zarya': 1882,
    'Zelenogradskiy': 1882,
    'Zhavoronki': 1882,
    'Zheleznodorozhnyy': 1882,
    'Zhilëvo': 1882,
    'Zhitnevo': 1882,
    'Zhukovka': 1882,
    'Zhukovskiy': 1882,
    'Zvenigorod': 1882,
    'Abram Mys': 1843,
    'Afrikanda': 1843,
    'Alakurtti': 1843,
    'Apatity': 1843,
    'Gadzhiyevo': 1843,
    'Kandalaksha': 1843,
    'Kandalakshskiy rayon': 1843,
    'Kirovsk': 1843,
    'Kol’skiy Rayon': 1843,
    'Kola': 1843,
    'Kovdor': 1843,
    'Kovdorskiy Rayon': 1843,
    'Lovozero': 1843,
    'Lovozerskiy Rayon': 1843,
    'Molochnyy': 1843,
    'Monchegorsk': 1843,
    'Murmansk': 1843,
    'Murmashi': 1843,
    'Nikel': 1843,
    'Olenegorsk': 1843,
    'Ostrovnoy': 1843,
    'Pechenga': 1843,
    'Pechengskiy Rayon': 1843,
    'Polyarnyy': 1843,
    'Polyarnyye Zori': 1843,
    'Poyakonda': 1843,
    'Revda': 1843,
    'Roslyakovo': 1843,
    'Safonovo': 1843,
    'Severomorsk': 1843,
    'Severomorsk-3': 1843,
    'Shonguy': 1843,
    'Snezhnogorsk': 1843,
    'Teribërka': 1843,
    'Terskiy Rayon': 1843,
    'Umba': 1843,
    'Verkhnetulomskiy': 1843,
    'Vidyayevo': 1843,
    'Vysokiy': 1843,
    'Zaozërsk': 1843,
    'Zapolyarnyy': 1843,
    'Zelenoborskiy': 1843,
    'Iskateley': 1836,
    'Nar  yan-Mar': 1836,
    'Afonino': 1857,
    'Ar’ya': 1857,
    'Ardatov': 1857,
    'Ardatovskiy Rayon': 1857,
    'Arzamas': 1857,
    'Arzamasskiy Rayon': 1857,
    'Babino': 1857,
    'Blizhne-Pesochnoye': 1857,
    'Bogorodsk': 1857,
    'Bogorodskiy Rayon': 1857,
    'Bol’sheboldinskiy Rayon': 1857,
    'Bol’shoye Boldino': 1857,
    'Bol’shoye Kozino': 1857,
    'Bol’shoye Murashkino': 1857,
    'Bor': 1857,
    'Bor Urban Okrug': 1857,
    'Burevestnik': 1857,
    'Buturlino': 1857,
    'Chernukha': 1857,
    'Chistoye': 1857,
    'Chkalovsk': 1857,
    'Dal’nekonstantinovskiy Rayon': 1857,
    'Dal’neye Konstantinovo': 1857,
    'Diveyevo': 1857,
    'Doschatoye': 1857,
    'Dzerzhinsk': 1857,
    'Frolishchi': 1857,
    'Gagino': 1857,
    'Gidrotorf': 1857,
    'Gorbatov': 1857,
    'Gorbatovka': 1857,
    'Gorodets': 1857,
    'Gorodetskiy Rayon': 1857,
    'Gremyachevo': 1857,
    'Ilyinogorsk': 1857,
    'Imeni M. I. Kalinina': 1857,
    'Imeni Stepana Razina': 1857,
    'Knyaginino': 1857,
    'Kovernino': 1857,
    'Krasnaya Gorka': 1857,
    'Krasnooktyabr’skiy Rayon': 1857,
    'Krasnyye Baki': 1857,
    'Kstovo': 1857,
    'Kulebaki': 1857,
    'Linda': 1857,
    'Lukino': 1857,
    'Lukoyanov': 1857,
    'Lukoyanovskiy Rayon': 1857,
    'Lyskovo': 1857,
    'Mukhtolovo': 1857,
    'Mulino': 1857,
    'Navashino': 1857,
    'Neklyudovo': 1857,
    'Nizhniy Novgorod': 1857,
    'Novaya Balakhna': 1857,
    'Novosmolinskiy': 1857,
    'Pamyat’ Parizhskoy Kommuny': 1857,
    'Pavlovo': 1857,
    'Pavlovskiy Rayon': 1857,
    'Perevoz': 1857,
    'Pervomaysk': 1857,
    'Pervomayskiy': 1857,
    'Pervoye Maya': 1857,
    'Pil’na': 1857,
    'Pil’ninskiy Rayon': 1857,
    'Pizhma': 1857,
    'Pochinki': 1857,
    'Pochinkovskiy Rayon': 1857,
    'Podnov’ye': 1857,
    'Prudy': 1857,
    'Purekh': 1857,
    'Pyra': 1857,
    'Rabotki': 1857,
    'Reshetikha': 1857,
    'Sarov': 1857,
    'Satis': 1857,
    'Savasleyka': 1857,
    'Sechenovo': 1857,
    'Sechenovskiy Rayon': 1857,
    'Semënov': 1857,
    'Sergach': 1857,
    'Sergachskiy Rayon': 1857,
    'Shakhun’ya': 1857,
    'Sharanga': 1857,
    'Sharangskiy Rayon': 1857,
    'Shatki': 1857,
    'Shimorskoye': 1857,
    'Sitniki': 1857,
    'Smolino': 1857,
    'Sokol’skoye': 1857,
    'Sosnovskiy Rayon': 1857,
    'Sosnovskoye': 1857,
    'Spasskiy Rayon': 1857,
    'Spasskoye': 1857,
    'Sukhobezvodnoye': 1857,
    'Surovatikha': 1857,
    'Syava': 1857,
    'Taremskoye': 1857,
    'Tonkino': 1857,
    'Tonshayevo': 1857,
    'Tsentral’nyy': 1857,
    'Tumbotino': 1857,
    'Urazovka': 1857,
    'Uren’': 1857,
    'Vacha': 1857,
    'Vachskiy Rayon': 1857,
    'Vad': 1857,
    'Vakhtan': 1857,
    'Varnavino': 1857,
    'Varnavinskiy Rayon': 1857,
    'Vasil’sursk': 1857,
    'Vetluga': 1857,
    'Vetluzhskiy': 1857,
    'Vetluzhskiy Rayon': 1857,
    'Vilya': 1857,
    'Vladimirskoye': 1857,
    'Volodarsk': 1857,
    'Vorotynets': 1857,
    'Vorsma': 1857,
    'Voskresenskiy Rayon': 1857,
    'Voskresenskoye': 1857,
    'Voznesenskoye': 1857,
    'Vyksa': 1857,
    'Vyyezdnoye': 1857,
    'Yuganets': 1857,
    'Zavolzh’ye': 1857,
    'Zelyony Gorod': 1857,
    'Batetskiy': 1834,
    'Batetskiy Rayon': 1834,
    'Borovichi': 1834,
    'Borovichskiy Rayon': 1834,
    'Chudovo': 1834,
    'Chudovskiy Rayon': 1834,
    'Demyansk': 1834,
    'Demyanskiy Rayon': 1834,
    'Kholm': 1834,
    'Kholmskiy Rayon': 1834,
    'Khvoynaya': 1834,
    'Khvoyninskiy Rayon': 1834,
    'Krasnofarfornyy': 1834,
    'Krechevitsy': 1834,
    'Krestetskiy Rayon': 1834,
    'Kresttsy': 1834,
    'Kulotino': 1834,
    'Lyubytino': 1834,
    'Lyubytinskiy Rayon': 1834,
    'Malaya Vishera': 1834,
    'Malovisherskiy Rayon': 1834,
    'Marevo': 1834,
    'Marevskiy Rayon': 1834,
    'Moshenskoy Rayon': 1834,
    'Moshenskoye': 1834,
    'Nebolchi': 1834,
    'Novgorodskiy Rayon': 1834,
    'Okulovka': 1834,
    'Okulovskiy Rayon': 1834,
    'Opechenskiy Posad': 1834,
    'Pankovka': 1834,
    'Parfino': 1834,
    'Parfinskiy Rayon': 1834,
    'Pestovo': 1834,
    'Pestovskiy Rayon': 1834,
    'Poddor’ye': 1834,
    'Poddorskiy Rayon': 1834,
    'Proletariy': 1834,
    'Shimsk': 1834,
    'Shimskiy Rayon': 1834,
    'Sol’tsy': 1834,
    'Soletskiy Rayon': 1834,
    'Staraya Russa': 1834,
    'Starorusskiy Rayon': 1834,
    'Uglovka': 1834,
    'Utorgosh': 1834,
    'Valday': 1834,
    'Valdayskiy Rayon': 1834,
    'Velikiy Novgorod': 1834,
    'Volkhovskiy': 1834,
    'Volot': 1834,
    'Volotovskiy Rayon': 1834,
    'Yedrovo': 1834,
    'Zarubino': 1834,
    'Akademgorodok': 1888,
    'Bagan': 1888,
    'Barabinsk': 1888,
    'Baryshevo': 1888,
    'Berdsk': 1888,
    'Bolotnoye': 1888,
    'Chany': 1888,
    'Cherepanovo': 1888,
    'Chulym': 1888,
    'Dorogino': 1888,
    'Dubrovino': 1888,
    'Gornyy': 1888,
    'Iskitim': 1888,
    'Iskitimskiy Rayon': 1888,
    'Karasuk': 1888,
    'Kargat': 1888,
    'Kochenëvo': 1888,
    'Kochki': 1888,
    'Kol’tsovo': 1888,
    'Kolyvan’': 1888,
    'Krasnoobsk': 1888,
    'Krasnozërskoye': 1888,
    'Krivodanovka': 1888,
    'Kudryashovskiy': 1888,
    'Kupino': 1888,
    'Kuybyshev': 1888,
    'Kyshtovka': 1888,
    'Linëvo': 1888,
    'Listvyanskiy': 1888,
    'Maslyanino': 1888,
    'Maslyaninskiy Rayon': 1888,
    'Mochishche': 1888,
    'Moshkovo': 1888,
    'Moshkovskiy Rayon': 1888,
    'Novolugovoye': 1888,
    'Novosibirsk': 1888,
    'Novosibirskiy Rayon': 1888,
    'Ob’': 1888,
    'Ordynskiy Rayon': 1888,
    'Ordynskoye': 1888,
    'Polovinnoye': 1888,
    'Prokudskoye': 1888,
    'Sadovyy': 1888,
    'Severnoye': 1888,
    'Severnyy Rayon': 1888,
    'Sokur': 1888,
    'Stantsionno-Oyashinskiy': 1888,
    'Suzun': 1888,
    'Tashara': 1888,
    'Tatarsk': 1888,
    'Tatarskiy Rayon': 1888,
    'Toguchin': 1888,
    'Tolmachëvo': 1888,
    'Ubinskoye': 1888,
    'Ust’-Tarka': 1888,
    'Vengerovo': 1888,
    'Verkh-Tula': 1888,
    'Yarkovo': 1888,
    'Zdvinsk': 1888,
    'Azovo': 1846,
    'Beregovoy': 1846,
    'Bol’sherech’ye': 1846,
    'Cherlak': 1846,
    'Irtyshskiy': 1846,
    'Kalachinsk': 1846,
    'Kolosovka': 1846,
    'Kormilovka': 1846,
    'Krasnoyarka': 1846,
    'Krasnyy Yar': 1846,
    'Krutaya Gorka': 1846,
    'Krutinka': 1846,
    'Luzino': 1846,
    'Lyubinskiy': 1846,
    'Muromtsevo': 1846,
    'Muromtsevskiy Rayon': 1846,
    'Nazyvayevsk': 1846,
    'Nizhnyaya Omka': 1846,
    'Novoomskiy': 1846,
    'Novovarshavka': 1846,
    'Odesskoye': 1846,
    'Okoneshnikovo': 1846,
    'Omsk': 1846,
    'Pavlogradka': 1846,
    'Poltavka': 1846,
    'Rostovka': 1846,
    'Russkaya Polyana': 1846,
    'Sargatskoye': 1846,
    'Sedel’nikovskiy Rayon': 1846,
    'Sherbakul’': 1846,
    'Tara': 1846,
    'Tavricheskoye': 1846,
    'Tevriz': 1846,
    'Troitsk': 1846,
    'Tyukalinsk': 1846,
    'Ust’-Ishim': 1846,
    'Vkhodnoy': 1846,
    'Znamenskoye': 1846,
    'Abdulino': 1886,
    'Adamovka': 1886,
    'Alandskoye': 1886,
    'Asekeyevo': 1886,
    'Belyayevka': 1886,
    'Buguruslan': 1886,
    'Buzuluk': 1886,
    'Dombarovskiy': 1886,
    'Energetik': 1886,
    'Gay': 1886,
    'Ilek': 1886,
    'Kardailovo': 1886,
    'Koltubanovskiy': 1886,
    'Krasnogvardeyets': 1886,
    'Krasnokholm': 1886,
    'Krasnoyarskiy': 1886,
    'Krasnyy Kommunar': 1886,
    'Kurmanayevka': 1886,
    'Kuvandyk': 1886,
    'Kvarkeno': 1886,
    'Kvarkenskiy Rayon': 1886,
    'Matveyevka': 1886,
    'Mednogorsk': 1886,
    'Nezhinka': 1886,
    'Nizhnepavlovka': 1886,
    'Novoorsk': 1886,
    'Novoorskiy Rayon': 1886,
    'Novorudnyy': 1886,
    'Novosergiyevka': 1886,
    'Novotroitsk': 1886,
    'Orenburg': 1886,
    'Orsk': 1886,
    'Perevolotskiy': 1886,
    'Pleshanovo': 1886,
    'Podgorodnyaya Pokrovka': 1886,
    'Prigorodnyy': 1886,
    'Sakmara': 1886,
    'Saraktash': 1886,
    'Saraktashskiy Rayon': 1886,
    'Severnoye': 1886,
    'Sharlyk': 1886,
    'Shil’da': 1886,
    'Sol’-Iletsk': 1886,
    'Sorochinsk': 1886,
    'Staraya Akkermanovka': 1886,
    'Svetlyy': 1886,
    'Tashla': 1886,
    'Tatarskaya Kargala': 1886,
    'Totskoye': 1886,
    'Tyul’gan': 1886,
    'Yasnyy': 1886,
    'Yelizavetinka': 1886,
    'Bolkhov': 1908,
    'Bolkhovskiy Rayon': 1908,
    'Dmitrovsk-Orlovskiy': 1908,
    'Glazunovka': 1908,
    'Khomutovo': 1908,
    'Khotynets': 1908,
    'Kolpny': 1908,
    'Korsakovo': 1908,
    'Krasnaya Zarya': 1908,
    'Kromy': 1908,
    'Livny': 1908,
    'Maloarkhangel’sk': 1908,
    'Mtsensk': 1908,
    'Naryshkino': 1908,
    'Novosil’': 1908,
    'Orël': 1908,
    'Pokrovskoye': 1908,
    'Shablykino': 1908,
    'Soskovo': 1908,
    'Spasskoye-Lutovinovo': 1908,
    'Trosna': 1908,
    'Verkhov’ye': 1908,
    'Vyshneye Dolgoye': 1908,
    'Zalegoshch’': 1908,
    'Zmiyëvka': 1908,
    'Znamenka': 1908,
    'Znamenskoye': 1908,
    'Bashmakovo': 1909,
    'Bekovo': 1909,
    'Belinskiy': 1909,
    'Bessonovka': 1909,
    'Bogoslovka': 1909,
    'Chaadayevka': 1909,
    'Chemodanovka': 1909,
    'Gorodishche': 1909,
    'Grabovo': 1909,
    'Inderka': 1909,
    'Issa': 1909,
    'Issinskiy Rayon': 1909,
    'Kanayevka': 1909,
    'Kolyshley': 1909,
    'Kondol’': 1909,
    'Kuznetsk': 1909,
    'Kuznetskiy Rayon': 1909,
    'Lermontovo': 1909,
    'Lopatino': 1909,
    'Lunino': 1909,
    'Makhalino': 1909,
    'Malaya Serdoba': 1909,
    'Mokshan': 1909,
    'Narovchat': 1909,
    'Neverkino': 1909,
    'Neverkinskiy Rayon': 1909,
    'Nikol’sk': 1909,
    'Nizhniy Lomov': 1909,
    'Pachelma': 1909,
    'Penza': 1909,
    'Poim': 1909,
    'Poselki': 1909,
    'Russkiy Kameshkir': 1909,
    'Serdobsk': 1909,
    'Shemysheyka': 1909,
    'Sosnovoborsk': 1909,
    'Spassk': 1909,
    'Srednyaya Yelyuzan’': 1909,
    'Sura': 1909,
    'Sursk': 1909,
    'Tamala': 1909,
    'Vadinsk': 1909,
    'Verkhniy Lomov': 1909,
    'Verkhozim': 1909,
    'Zarechnyy': 1909,
    'Zasechnoye': 1909,
    'Zemetchino': 1909,
    'Aleksandrovsk': 1871,
    'Barda': 1871,
    'Berezniki': 1871,
    'Bershet’': 1871,
    'Chastyye': 1871,
    'Chaykovskaya': 1871,
    'Chaykovskiy': 1871,
    'Cherdyn’': 1871,
    'Chermoz': 1871,
    'Chernushka': 1871,
    'Chusovoy': 1871,
    'Dobryanka': 1871,
    'Ferma': 1871,
    'Filippovka': 1871,
    'Foki': 1871,
    'Froly': 1871,
    'Gamovo': 1871,
    'Gayny': 1871,
    'Gornozavodsk': 1871,
    'Gremyachinsk': 1871,
    'Gubakha': 1871,
    'Kalino': 1871,
    'Karagay': 1871,
    'Kerchevskiy': 1871,
    'Kizel': 1871,
    'Kochevo': 1871,
    'Komarikhinskiy': 1871,
    'Kondratovo': 1871,
    'Kosa': 1871,
    'Krasnokamsk': 1871,
    'Krasnovishersk': 1871,
    'Kudymkar': 1871,
    'Kukushtan': 1871,
    'Kultayevo': 1871,
    'Kungur': 1871,
    'Kuyeda': 1871,
    'Lobanovo': 1871,
    'Lyamino': 1871,
    'Lys’va': 1871,
    'Maykor': 1871,
    'Mendeleyevo': 1871,
    'Novyye Lyady': 1871,
    'Nyrob': 1871,
    'Nytva': 1871,
    'Ochër': 1871,
    'Okhansk': 1871,
    'Orda': 1871,
    'Osa': 1871,
    'Overyata': 1871,
    'Pashiya': 1871,
    'Pavlovskiy': 1871,
    'Perm': 1871,
    'Polazna': 1871,
    'Pozhva': 1871,
    'Sarany': 1871,
    'Sars': 1871,
    'Severnyy-Kospashskiy': 1871,
    'Shakhta': 1871,
    'Shirokovskiy': 1871,
    'Shumikhinskiy': 1871,
    'Siva': 1871,
    'Sokol': 1871,
    'Solikamsk': 1871,
    'Suksun': 1871,
    'Sylva': 1871,
    'Tëplaya Gora': 1871,
    'Ugleural’skiy': 1871,
    'Uinskoye': 1871,
    'Usol’ye': 1871,
    'Ust’-Kachka': 1871,
    'Ust’-Kishert’': 1871,
    'Vereshchagino': 1871,
    'Verkhnechusovskiye Gorodki': 1871,
    'Yayva': 1871,
    'Yelovo': 1871,
    'Yubileynyy': 1871,
    'Yug': 1871,
    'Yugo-Kamskiy': 1871,
    'Yurla': 1871,
    'Yus’vinskiy Rayon': 1871,
    'Yuzhnyy-Kospashskiy': 1871,
    'Zvëzdnyy': 1871,
    'Zyukayka': 1871,
    'Anuchino': 1833,
    'Anuchinskiy Rayon': 1833,
    'Arsen’yev': 1833,
    'Artëm': 1833,
    'Barabash': 1833,
    'Blagodatnoye': 1833,
    'Bol’shoy Kamen’': 1833,
    'Chernigovka': 1833,
    'Chernigovskiy Rayon': 1833,
    'Chernyshëvka': 1833,
    'Chkalovskoye': 1833,
    'Chuguyevka': 1833,
    'Dalnegorsk': 1833,
    'Dalnerechensk': 1833,
    'Dostoyevka': 1833,
    'Dunay': 1833,
    'Emar': 1833,
    'Fokino': 1833,
    'Gornorechenskiy': 1833,
    'Gornyye Klyuchi': 1833,
    'Grazhdanka': 1833,
    'Ivanovka': 1833,
    'Kamen’-Rybolov': 1833,
    'Kamenka': 1833,
    'Kavalerovo': 1833,
    'Khasanskiy Rayon': 1833,
    'Khorol’': 1833,
    'Kirovskiy': 1833,
    'Knevichi': 1833,
    'Kraskino': 1833,
    'Krasnorechenskiy': 1833,
    'Lazo': 1833,
    'Lazovskiy Rayon': 1833,
    'Lesozavodsk': 1833,
    'Lipovtsy': 1833,
    'Livadiya': 1833,
    'Luchegorsk': 1833,
    'Luchki': 1833,
    'Lyalichi': 1833,
    'Mikhaylovka': 1833,
    'Mikhaylovskiy Rayon': 1833,
    'Mnogoudobnoye': 1833,
    'Monastyrishche': 1833,
    'Nadezhdinskiy Rayon': 1833,
    'Nakhodka': 1833,
    'Novonikol’sk': 1833,
    'Novopokrovka': 1833,
    'Novoshakhtinskiy': 1833,
    'Novosysoyevka': 1833,
    'Novyy': 1833,
    'Ol’ga': 1833,
    'Partizansk': 1833,
    'Plastun': 1833,
    'Pogranichnyy': 1833,
    'Pokrovka': 1833,
    'Popova': 1833,
    'Preobrazheniye': 1833,
    'Primorskiy': 1833,
    'Putyatin': 1833,
    'Razdol’noye': 1833,
    'Rettikhovka': 1833,
    'Romanovka': 1833,
    'Rudnaya Pristan’': 1833,
    'Rudnyy': 1833,
    'Russkiy': 1833,
    'Sergeyevka': 1833,
    'Shkotovo': 1833,
    'Shkotovskiy Rayon': 1833,
    'Shtykovo': 1833,
    'Sivakovka': 1833,
    'Slavyanka': 1833,
    'Smolyaninovo': 1833,
    'Spassk-Dal’niy': 1833,
    'Spasskiy Rayon': 1833,
    'Spasskoye': 1833,
    'Svetlaya': 1833,
    'Tavrichanka': 1833,
    'Terney': 1833,
    'Trudovoye': 1833,
    'Uglekamensk': 1833,
    'Uglovoye': 1833,
    'Ussuriysk': 1833,
    'Vladimiro-Aleksandrovskoye': 1833,
    'Vladivostok': 1833,
    'Vol’no-Nadezhdinskoye': 1833,
    'Volchanets': 1833,
    'Vostok': 1833,
    'Vozdvizhenka': 1833,
    'Vrangel’': 1833,
    'Yakovlevka': 1833,
    'Yakovlevskiy Rayon': 1833,
    'Yaroslavskiy': 1833,
    'Yekaterinovka': 1833,
    'Zarubino': 1833,
    'Zavodskoy': 1833,
    'Zharikovo': 1833,
    'Bezhanitsy': 1863,
    'Dedovichi': 1863,
    'Dno': 1863,
    'Gdov': 1863,
    'Gdovskiy Rayon': 1863,
    'Idritsa': 1863,
    'Izborsk': 1863,
    'Krasnogorodsk': 1863,
    'Krasnyy Luch': 1863,
    'Kun’inskiy Rayon': 1863,
    'Loknya': 1863,
    'Nevel’': 1863,
    'Novorzhev': 1863,
    'Novorzhevskiy Rayon': 1863,
    'Novosokol’nicheskiy Rayon': 1863,
    'Novosokol’niki': 1863,
    'Novyy Izborsk': 1863,
    'Opochka': 1863,
    'Ostrov': 1863,
    'Ostrovskiy Rayon': 1863,
    'Palkino': 1863,
    'Palkinskiy Rayon': 1863,
    'Pechorskiy Rayon': 1863,
    'Pechory': 1863,
    'Plyussa': 1863,
    'Porkhov': 1863,
    'Porkhovskiy Rayon': 1863,
    'Pskov': 1863,
    'Pskovskiy Rayon': 1863,
    'Pushkino-Gorskiy Rayon': 1863,
    'Pushkinskiye Gory': 1863,
    'Pustoshka': 1863,
    'Pustoshkinskiy Rayon': 1863,
    'Pytalovo': 1863,
    'Pytalovskiy Rayon': 1863,
    'Sebezh': 1863,
    'Sebezhskiy Rayon': 1863,
    'Serëdka': 1863,
    'Strugi-Krasnyye': 1863,
    'Strugo-Krasnenskiy Rayon': 1863,
    'Usvyaty': 1863,
    'Velikiye Luki': 1863,
    'Velikolukskiy Rayon': 1863,
    'Vybor': 1863,
    'Abadzekhskaya': 1852,
    'Adygeysk': 1852,
    'Adygeysk Republican Urban Okrug': 1852,
    'Beloye': 1852,
    'Blechepsin': 1852,
    'Dondukovskaya': 1852,
    'Enem': 1852,
    'Giaginskaya': 1852,
    'Giaginskiy Rayon': 1852,
    'Kamennomostskiy': 1852,
    'Khanskaya': 1852,
    'Khatukay': 1852,
    'Khodz’': 1852,
    'Koshekhabl’': 1852,
    'Koshekhabl’skiy Rayon': 1852,
    'Krasnogvardeyskiy Rayon': 1852,
    'Krasnogvardeyskoye': 1852,
    'Kuzhorskaya': 1852,
    'Maykop': 1852,
    'Maykop Republican Urban Okrug': 1852,
    'Maykopskiy Rayon': 1852,
    'Natyrbovo': 1852,
    'Ponezhukay': 1852,
    'Shovgenovskiy': 1852,
    'Shovgenovskiy Rayon': 1852,
    'Takhtamukay': 1852,
    'Takhtamukayskiy Rayon': 1852,
    'Teuchezhskiy Rayon': 1852,
    'Tlyustenkhabl’': 1852,
    'Yablonovskiy': 1852,
    'Abzakovo': 1854,
    'Abzelilovskiy Rayon': 1854,
    'Agidel’': 1854,
    'Ak”yar': 1854,
    'Akhunovo': 1854,
    'Aksakovo': 1854,
    'Al’sheyevskiy Rayon': 1854,
    'Alekseyevka': 1854,
    'Amzya': 1854,
    'Asanovo': 1854,
    'Askino': 1854,
    'Askinskiy Rayon': 1854,
    'Avdon': 1854,
    'Bakalinskiy Rayon': 1854,
    'Bakaly': 1854,
    'Baymak': 1854,
    'Bedeyeva Polyana': 1854,
    'Belebey': 1854,
    'Beloretsk': 1854,
    'Beloretskiy Rayon': 1854,
    'Birsk': 1854,
    'Bizhbulyak': 1854,
    'Blagoveshchensk': 1854,
    'Bulgakovo': 1854,
    'Burayevo': 1854,
    'Buribay': 1854,
    'Buzdyak': 1854,
    'Buzdyakskiy Rayon': 1854,
    'Chekmagush': 1854,
    'Chesnokovka': 1854,
    'Chishmy': 1854,
    'Davlekanovo': 1854,
    'Dmitriyevka': 1854,
    'Duvan': 1854,
    'Dyurtyuli': 1854,
    'Fëdorovskiy Rayon': 1854,
    'Iglino': 1854,
    'Inzer': 1854,
    'Ishimbay': 1854,
    'Isyangulovo': 1854,
    'Kabakovo': 1854,
    'Kaltasy': 1854,
    'Kandry': 1854,
    'Karaidel’': 1854,
    'Karmaskaly': 1854,
    'Khaybullinskiy Rayon': 1854,
    'Kirgiz-Miyaki': 1854,
    'Krasnaya Gorka': 1854,
    'Krasnokholmskiy': 1854,
    'Krasnyy Klyuch': 1854,
    'Kudeyevskiy': 1854,
    'Kumertau': 1854,
    'Kushnarënkovo': 1854,
    'Lomovka': 1854,
    'Maginsk': 1854,
    'Mayachnyy': 1854,
    'Mechetlinskiy Rayon': 1854,
    'Meleuz': 1854,
    'Mesyagutovo': 1854,
    'Mezgor  e': 1854,
    'Mikhaylovka': 1854,
    'Mindyak': 1854,
    'Mishkino': 1854,
    'Mishkinskiy Rayon': 1854,
    'Miyakinskiy Rayon': 1854,
    'Mrakovo': 1854,
    'Mursalimkino': 1854,
    'Neftekamsk': 1854,
    'Nikolo-Berëzovka': 1854,
    'Nizhnetroitskiy': 1854,
    'Novobelokatay': 1854,
    'Nurimanovskiy Rayon': 1854,
    'Oktyabr’skiy': 1854,
    'Pavlovka': 1854,
    'Priyutovo': 1854,
    'Rayevskiy': 1854,
    'Salavat': 1854,
    'Semiletka': 1854,
    'Serafimovskiy': 1854,
    'Shafranovo': 1854,
    'Sharan': 1854,
    'Sibay': 1854,
    'Starobaltachevo': 1854,
    'Starosubkhangulovo': 1854,
    'Sterlibashevo': 1854,
    'Sterlitamak': 1854,
    'Subkhankulovo': 1854,
    'Temyasovo': 1854,
    'Tirlyanskiy': 1854,
    'Tolbazy': 1854,
    'Tubinskiy': 1854,
    'Tukan': 1854,
    'Tuymazy': 1854,
    'Uchaly': 1854,
    'Ufa': 1854,
    'Ulu-Telyak': 1854,
    'Ulukulevo': 1854,
    'Urman': 1854,
    'Verkhneyarkeyevo': 1854,
    'Verkhniy Avzyan': 1854,
    'Verkhniye Kigi': 1854,
    'Verkhniye Tatyshly': 1854,
    'Voskresenskoye': 1854,
    'Yanaul': 1854,
    'Yanaul’skiy Rayon': 1854,
    'Yazykovo': 1854,
    'Yermekeyevo': 1854,
    'Yermolayevo': 1854,
    'Yumaguzino': 1854,
    'Zilair': 1854,
    'Zirgan': 1854,
    'Babushkin': 1842,
    'Bagdarin': 1842,
    'Barguzin': 1842,
    'Bayangol': 1842,
    'Bichura': 1842,
    'Bol’shoy Kunaley': 1842,
    'Dzhida': 1842,
    'Gusinoozyorsk': 1842,
    'Gusinoye Ozero': 1842,
    'Il’inka': 1842,
    'Il’ka': 1842,
    'Istok': 1842,
    'Ivolginsk': 1842,
    'Ivolginskiy Rayon': 1842,
    'Kabansk': 1842,
    'Kholtoson': 1842,
    'Khonkholoy': 1842,
    'Khorinsk': 1842,
    'Kichera': 1842,
    'Kizhinga': 1842,
    'Kudara-Somon': 1842,
    'Kurumkan': 1842,
    'Kyakhta': 1842,
    'Kyren': 1842,
    'Mukhorshibir’': 1842,
    'Mukhorshibirskiy Rayon': 1842,
    'Muyskiy Rayon': 1842,
    'Naushki': 1842,
    'Nikol’sk': 1842,
    'Nikolayevskiy': 1842,
    'Nizhneangarsk': 1842,
    'Nizhniy Sayantuy': 1842,
    'Novaya Bryan’': 1842,
    'Novokizhinginsk': 1842,
    'Novyy Uoyan': 1842,
    'Novyy Zagan': 1842,
    'Okino-Klyuchi': 1842,
    'Okinskiy Rayon': 1842,
    'Onokhoy': 1842,
    'Orlik': 1842,
    'Petropavlovka': 1842,
    'Pribaykal’skiy Rayon': 1842,
    'Rechka-Vydrino': 1842,
    'Sagan-Nur': 1842,
    'Selenduma': 1842,
    'Selenginskiy Rayon': 1842,
    'Severo-Baykal’skiy Rayon': 1842,
    'Severobaykal’sk': 1842,
    'Severomuysk': 1842,
    'Sharalday': 1842,
    'Sokol': 1842,
    'Sosnovo-Ozerskoye': 1842,
    'Sotnikovo': 1842,
    'Taksimo': 1842,
    'Tankhoy': 1842,
    'Tarbagatay': 1842,
    'Tataurovo': 1842,
    'Turka': 1842,
    'Turuntayevo': 1842,
    'Ulan-Ude': 1842,
    'Ust’-Barguzin': 1842,
    'Vydrino': 1842,
    'Zaigrayevo': 1842,
    'Zakamensk': 1842,
    'Zarechnyy': 1842,
    'Zhemchug': 1842,
    'Achisu': 1850,
    'Adil’-Yangiyurt': 1850,
    'Agul’skiy Rayon': 1850,
    'Agvali': 1850,
    'Akhty': 1850,
    'Akhtynskiy Rayon': 1850,
    'Akhvakhskiy Rayon': 1850,
    'Aknada': 1850,
    'Aksay': 1850,
    'Akusha': 1850,
    'Akushinskiy Rayon': 1850,
    'Andi': 1850,
    'Ansalta': 1850,
    'Ashil’ta': 1850,
    'Babayurt': 1850,
    'Babayurtovskiy Rayon': 1850,
    'Bammatyurt': 1850,
    'Bavtugay': 1850,
    'Belidzhi': 1850,
    'Bezhta': 1850,
    'Botashyurt': 1850,
    'Botayurt': 1850,
    'Botlikhskiy Rayon': 1850,
    'Burtunay': 1850,
    'Buynaksk': 1850,
    'Buynakskiy Rayon': 1850,
    'Charodinskiy Rayon': 1850,
    'Chinar': 1850,
    'Chirkey': 1850,
    'Chontaul': 1850,
    'Dagestanskiye Ogni': 1850,
    'Dakhadayevskiy Rayon': 1850,
    'Derbent': 1850,
    'Derbentskiy Rayon': 1850,
    'Dokuzparinskiy Rayon': 1850,
    'Dorgeli': 1850,
    'Druzhba': 1850,
    'Dubki': 1850,
    'Dylym': 1850,
    'Endirey': 1850,
    'Erpeli': 1850,
    'Gagatli': 1850,
    'Gaptsakh': 1850,
    'Gedzhukh': 1850,
    'Gereykhanovskoye': 1850,
    'Gerga': 1850,
    'Gergebil’': 1850,
    'Gergebil’skiy Rayon': 1850,
    'Gimry': 1850,
    'Gubden': 1850,
    'Gumbetovskiy Rayon': 1850,
    'Gunib': 1850,
    'Gunibskiy Rayon': 1850,
    'Gurbuki': 1850,
    'Irganay': 1850,
    'Izberbash': 1850,
    'Kafyr-Kumukh': 1850,
    'Kalininaul': 1850,
    'Karabudakhkent': 1850,
    'Karabudakhkentskiy Rayon': 1850,
    'Karamakhi': 1850,
    'Karata': 1850,
    'Kaspiysk': 1850,
    'Kasumkent': 1850,
    'Kayakent': 1850,
    'Kayakentskiy Rayon': 1850,
    'Kaytagskiy Rayon': 1850,
    'Kazbekovskiy Rayon': 1850,
    'Khadzhalmakhi': 1850,
    'Khamamatyurt': 1850,
    'Khasavyurt': 1850,
    'Khasavyurtovskiy Rayon': 1850,
    'Khazar': 1850,
    'Khebda': 1850,
    'Khiv': 1850,
    'Khivskiy Rayon': 1850,
    'Khuchni': 1850,
    'Khunzakh': 1850,
    'Khunzakhskiy Rayon': 1850,
    'Kishcha': 1850,
    'Kizilyurt': 1850,
    'Kizilyurtovskiy Rayon': 1850,
    'Kizlyar': 1850,
    'Kizlyarskiy Rayon': 1850,
    'Kochubey': 1850,
    'Kokrek': 1850,
    'Komsomol’skiy': 1850,
    'Korkmaskala': 1850,
    'Kostek': 1850,
    'Kubachi': 1850,
    'Kuli': 1850,
    'Kulinskiy Rayon': 1850,
    'Kumukh': 1850,
    'Kurakh': 1850,
    'Kurakhskiy Rayon': 1850,
    'Kurkent': 1850,
    'Kurush': 1850,
    'Kyakhulay': 1850,
    'Lakskiy Rayon': 1850,
    'Leninaul': 1850,
    'Leninkent': 1850,
    'Levashi': 1850,
    'Levashinskiy Rayon': 1850,
    'Lutkun': 1850,
    'Madzhalis': 1850,
    'Magaramkent': 1850,
    'Magaramkentskiy Rayon': 1850,
    'Makhachkala': 1850,
    'Mamedkala': 1850,
    'Manas': 1850,
    'Manaskent': 1850,
    'Miatli': 1850,
    'Miskindzha': 1850,
    'Mugi': 1850,
    'Mutsalaul': 1850,
    'Myurego': 1850,
    'Nizhneye Kazanishche': 1850,
    'Nizhniy Dzhengutay': 1850,
    'Nogayskiy Rayon': 1850,
    'Novaya Maka': 1850,
    'Novogagatli': 1850,
    'Novokayakent': 1850,
    'Novolakskiy Rayon': 1850,
    'Novolakskoye': 1850,
    'Novyy Chirkey': 1850,
    'Novyy Khushet': 1850,
    'Novyy Kostek': 1850,
    'Novyy Sulak': 1850,
    'Nuradilovo': 1850,
    'Paraul': 1850,
    'Pervomayskoye': 1850,
    'Pokrovskoye': 1850,
    'Rutul': 1850,
    'Rutul’skiy Rayon': 1850,
    'Sabnova': 1850,
    'Samur': 1850,
    'Sergokala': 1850,
    'Sergokalinskiy Rayon': 1850,
    'Shamil’kala': 1850,
    'Shamil’skiy Rayon': 1850,
    'Shamkhal': 1850,
    'Shamkhal-Termen': 1850,
    'Siukh': 1850,
    'Solnechnoye': 1850,
    'Sulak': 1850,
    'Suleyman-Stal’skiy Rayon': 1850,
    'Syrtych': 1850,
    'Tabasaranskiy Rayon': 1850,
    'Tarki': 1850,
    'Tarumovka': 1850,
    'Tarumovskiy Rayon': 1850,
    'Temiraul': 1850,
    'Terekli-Mekteb': 1850,
    'Tlyarata': 1850,
    'Tlyaratinskiy Rayon': 1850,
    'Tpig': 1850,
    'Tsumadinskiy Rayon': 1850,
    'Tsuntinskiy Rayon': 1850,
    'Tsurib': 1850,
    'Tyube': 1850,
    'Uchkent': 1850,
    'Ulluaya': 1850,
    'Ullubiyaul': 1850,
    'Untsukul’': 1850,
    'Untsukul’skiy Rayon': 1850,
    'Urkarakh': 1850,
    'Urma': 1850,
    'Usisha': 1850,
    'Usukhchay': 1850,
    'Utamysh': 1850,
    'Utsmiyurt': 1850,
    'Velikent': 1850,
    'Verkhneye Kazanishche': 1850,
    'Yuzhno-Sukhokumsk': 1850,
    'Zubutli-Miatli': 1850,
    'Ali-Yurt': 1884,
    'Alkhan-Churt': 1884,
    'Dalakovo': 1884,
    'Dzhayrakh': 1884,
    'Dzhayrakhskiy Rayon': 1884,
    'Ekazhevo': 1884,
    'Galashki': 1884,
    'Kantyshevo': 1884,
    'Karabulak': 1884,
    'Magas': 1884,
    'Malgobek': 1884,
    'Malgobekskiy Rayon': 1884,
    'Nazran’': 1884,
    'Nazranovskiy Rayon': 1884,
    'Nesterovskaya': 1884,
    'Nizhniye Achaluki': 1884,
    'Psedakh': 1884,
    'Sagopshi': 1884,
    'Staryy Malgobek': 1884,
    'Sunzha': 1884,
    'Sunzhenskiy Rayon': 1884,
    'Surkhakhi': 1884,
    'Troitskaya': 1884,
    'Verkhniye Achaluki': 1884,
    'Voznesenskaya': 1884,
    'Arshan’': 1883,
    'Elista': 1883,
    'Gorodoviki': 1883,
    'Gorodovikovsk': 1883,
    'Iki-Burul': 1883,
    'Iki-Burul’skiy Rayon': 1883,
    'Kaspiyskiy': 1883,
    'Komsomol’skiy': 1883,
    'Malyye Derbety': 1883,
    'Priyutnoye': 1883,
    'Sadovoye': 1883,
    'Sarpinskiy Rayon': 1883,
    'Sovetskoye': 1883,
    'Troitskoye': 1883,
    'Tsagan Aman': 1883,
    'Yashalta': 1883,
    'Yashaltinskiy Rayon': 1883,
    'Yashkul’': 1883,
    'Belomorsk': 1841,
    'Borovoy': 1841,
    'Chupa': 1841,
    'Essoyla': 1841,
    'Girvas': 1841,
    'Kalevala': 1841,
    'Kem’': 1841,
    'Kharlu': 1841,
    'Khelyulya': 1841,
    'Kondopoga': 1841,
    'Kostomuksha': 1841,
    'Kvartsitnyy': 1841,
    'Lakhdenpokh’ya': 1841,
    'Ledmozero': 1841,
    'Loukhi': 1841,
    'Lyaskelya': 1841,
    'Matrosy': 1841,
    'Medvezh’yegorsk': 1841,
    'Muyezerskiy': 1841,
    'Nadvoitsy': 1841,
    'Olonets': 1841,
    'Petrozavodsk': 1841,
    'Pindushi': 1841,
    'Pitkyaranta': 1841,
    'Pitkyarantskiy Rayon': 1841,
    'Porosozero': 1841,
    'Povenets': 1841,
    'Prionezhskiy Rayon': 1841,
    'Pryazha': 1841,
    'Pudozh': 1841,
    'Pyaozerskiy': 1841,
    'Rabocheostrovsk': 1841,
    'Ruskeala': 1841,
    'Salmi': 1841,
    'Segezha': 1841,
    'Shal’skiy': 1841,
    'Sheltozero': 1841,
    'Shun’ga': 1841,
    'Shuya': 1841,
    'Sortaval’skiy Rayon': 1841,
    'Sortavala': 1841,
    'Sukkozero': 1841,
    'Suoyarvi': 1841,
    'Suoyarvskiy Rayon': 1841,
    'Vyartsilya': 1841,
    'Yanis’yarvi': 1841,
    'Yanishpole': 1841,
    'Yushkozero': 1841,
    'Abakan': 1877,
    'Abakan Gorod': 1877,
    'Abaza': 1877,
    'Altayskiy Rayon': 1877,
    'Askiz': 1877,
    'Belyy Yar': 1877,
    'Beya': 1877,
    'Biskamzha': 1877,
    'Bograd': 1877,
    'Bogradskiy Rayon': 1877,
    'Cherëmushki': 1877,
    'Chernogorsk': 1877,
    'Kommunar': 1877,
    'Mayna': 1877,
    'Podsineye': 1877,
    'Sayanogorsk': 1877,
    'Shira': 1877,
    'Sorsk': 1877,
    'Tashtyp': 1877,
    'Tuim': 1877,
    'Ust’-Abakan': 1877,
    'Vershina Tei': 1877,
    'Ardatov': 1898,
    'Ardatovskiy Rayon': 1898,
    'Atemar': 1898,
    'Atyashevo': 1898,
    'Atyashevskiy Rayon': 1898,
    'Atyur’yevskiy Rayon': 1898,
    'Barashevo': 1898,
    'Bol’shebereznikovskiy Rayon': 1898,
    'Chamzinka': 1898,
    'Chamzinskiy Rayon': 1898,
    'Insar': 1898,
    'Insarskiy Rayon': 1898,
    'Kadoshkino': 1898,
    'Kemlya': 1898,
    'Kochkurovo': 1898,
    'Kochkurovskiy Rayon': 1898,
    'Kovylkino': 1898,
    'Krasnoslobodsk': 1898,
    'Krasnoslobodskiy Rayon': 1898,
    'Lepley': 1898,
    'Lukhovka': 1898,
    'Lyambir’': 1898,
    'Lyambirskiy Rayon': 1898,
    'Nikolayevka': 1898,
    'Partsa': 1898,
    'Romodanovo': 1898,
    'Romodanovskiy Rayon': 1898,
    'Ruzayevka': 1898,
    'Ruzayevskiy Rayon': 1898,
    'Saransk': 1898,
    'Shiringushi': 1898,
    'Sosnovka': 1898,
    'Staroshaygovskiy Rayon': 1898,
    'Tatarskaya Pishlya': 1898,
    'Temnikov': 1898,
    'Temnikovskiy Rayon': 1898,
    'Ten’gushevo': 1898,
    'Ten’gushevskiy Rayon': 1898,
    'Torbeyevo': 1898,
    'Turgenevo': 1898,
    'Udarnyy': 1898,
    'Umet': 1898,
    'Vysha': 1898,
    'Yavas': 1898,
    'Yel’nikovskiy Rayon': 1898,
    'Zubova Polyana': 1898,
    'Zubovo-Polyanskiy Rayon': 1898,
    'Zykovo': 1898,
    'Alagir': 1853,
    'Alagirskiy Rayon': 1853,
    'Ardon': 1853,
    'Arkhonskaya': 1853,
    'Beslan': 1853,
    'Chermen': 1853,
    'Chikola': 1853,
    'Dachnoye': 1853,
    'Digora': 1853,
    'Digorskiy Rayon': 1853,
    'Gizel’': 1853,
    'Irafskiy Rayon': 1853,
    'Kambileyevskoye': 1853,
    'Kardzhin': 1853,
    'Khumalag': 1853,
    'Kirovskiy Rayon': 1853,
    'Kizlyar': 1853,
    'Mikhaylovskoye': 1853,
    'Mizur': 1853,
    'Mozdok': 1853,
    'Nogir': 1853,
    'Pavlodol’skaya': 1853,
    'Pravoberezhnyy Rayon': 1853,
    'Prigorodnyy Rayon': 1853,
    'Sunzha': 1853,
    'Tarskoye': 1853,
    'Terskaya': 1853,
    'Troitskoye': 1853,
    'Verkhniy Fiagdon': 1853,
    'Vladikavkaz': 1853,
    'Zmeyskaya': 1853,
    'Agryz': 1861,
    'Agryzskiy Rayon': 1861,
    'Aksubayevskiy Rayon': 1861,
    'Aktanysh': 1861,
    'Aktanyshskiy Rayon': 1861,
    'Aktyubinskiy': 1861,
    'Al’keyevskiy Rayon': 1861,
    'Al’met’yevsk': 1861,
    'Al’met’yevskiy Rayon': 1861,
    'Alekseyevskiy Rayon': 1861,
    'Alekseyevskoye': 1861,
    'Apastovo': 1861,
    'Apastovskiy Rayon': 1861,
    'Arsk': 1861,
    'Arskiy Rayon': 1861,
    'Atninskiy Rayon': 1861,
    'Aysha': 1861,
    'Aznakayevskiy Rayon': 1861,
    'Baltasi': 1861,
    'Baltasinskiy Rayon': 1861,
    'Bavlinskiy Rayon': 1861,
    'Bavly': 1861,
    'Bazarnyye Mataki': 1861,
    'Bilyarsk': 1861,
    'Bogatyye Saby': 1861,
    'Bolgar': 1861,
    'Bugul’ma': 1861,
    'Bugul’minskiy Rayon': 1861,
    'Buinsk': 1861,
    'Buinskiy Rayon': 1861,
    'Cheremshan': 1861,
    'Cheremshanskiy Rayon': 1861,
    'Chistopol’': 1861,
    'Chistopol’skiy Rayon': 1861,
    'Drozhzhanovskiy Rayon': 1861,
    'Dzhalil’': 1861,
    'Gorod Buinsk': 1861,
    'Gorod Kazan’': 1861,
    'Gorod Nizhnekamsk': 1861,
    'Gorod Yelabuga': 1861,
    'Gorod Zainsk': 1861,
    'Gorod Zelënodol’sk': 1861,
    'Kamsko-Ust’inskiy Rayon': 1861,
    'Karabash': 1861,
    'Kaybitskiy Rayon': 1861,
    'Kazan': 1861,
    'Kukmor': 1861,
    'Kukmorskiy Rayon': 1861,
    'Kuybyshevskiy Zaton': 1861,
    'Laishevo': 1861,
    'Laishevskiy Rayon': 1861,
    'Leninogorsk': 1861,
    'Leninogorskiy Rayon': 1861,
    'Lubyany': 1861,
    'Mamadysh': 1861,
    'Mamadyshskiy Rayon': 1861,
    'Mendeleyevsk': 1861,
    'Mendeleyevskiy Rayon': 1861,
    'Menzelinsk': 1861,
    'Menzelinskiy Rayon': 1861,
    'Muslyumovo': 1861,
    'Muslyumovskiy Rayon': 1861,
    'Naberezhnyye Chelny': 1861,
    'Nizhnekamsk': 1861,
    'Nizhnekamskiy Rayon': 1861,
    'Nizhniye Vyazovyye': 1861,
    'Nizhnyaya Maktama': 1861,
    'Novosheshminsk': 1861,
    'Novosheshminskiy Rayon': 1861,
    'Nurlat': 1861,
    'Nurlatskiy rayon': 1861,
    'Osinovo': 1861,
    'Pestrechinskiy Rayon': 1861,
    'Pestretsy': 1861,
    'Russkiy Aktash': 1861,
    'Rybnaya Sloboda': 1861,
    'Rybno-Slobodskiy Rayon': 1861,
    'Sabinskiy Rayon': 1861,
    'Sarmanovo': 1861,
    'Sarmanovskiy Rayon': 1861,
    'Shemordan': 1861,
    'Shugurovo': 1861,
    'Spasskiy Rayon': 1861,
    'Staroye Arakchino': 1861,
    'Staroye Drozhzhanoye': 1861,
    'Stolbishchi': 1861,
    'Sviyazhsk': 1861,
    'Tetyushi': 1861,
    'Tetyushskiy Rayon': 1861,
    'Tukayevskiy Rayon': 1861,
    'Tyulyachi': 1861,
    'Tyulyachinskiy Rayon': 1861,
    'Urussu': 1861,
    'Vasil’yevo': 1861,
    'Verkhneuslonskiy Rayon': 1861,
    'Verkhniy Uslon': 1861,
    'Vysokaya Gora': 1861,
    'Vysokogorskiy Rayon': 1861,
    'Yelabuga': 1861,
    'Yelabuzhskiy Rayon': 1861,
    'Yutazinskiy Rayon': 1861,
    'Zainsk': 1861,
    'Zainskiy Rayon': 1861,
    'Zelenodol’skiy Rayon': 1861,
    'Zelenodolsk': 1861,
    'Aksay': 1837,
    'Almaznyy': 1837,
    'Anastasiyevka': 1837,
    'Ayutinskiy': 1837,
    'Azov': 1837,
    'Bagayevskaya': 1837,
    'Bataysk': 1837,
    'Belaya Kalitva': 1837,
    'Bokovskaya': 1837,
    'Bystrogorskiy': 1837,
    'Chaltyr': 1837,
    'Chertkovo': 1837,
    'Donetsk': 1837,
    'Donskoy': 1837,
    'Dubovskoye': 1837,
    'Gigant': 1837,
    'Glubokiy': 1837,
    'Gornyatskiy': 1837,
    'Gornyy': 1837,
    'Grushevskaya': 1837,
    'Gukovo': 1837,
    'Gundorovskiy': 1837,
    'Kagal’nitskaya': 1837,
    'Kalinin': 1837,
    'Kamenolomni': 1837,
    'Kamensk-Shakhtinskiy': 1837,
    'Kashary': 1837,
    'Kazanskaya': 1837,
    'Kirovskaya': 1837,
    'Koksovyy': 1837,
    'Konstantinovsk': 1837,
    'Krasnaya Polyana': 1837,
    'Krasnoarmeyskiy': 1837,
    'Krasnovka': 1837,
    'Krasnoyarskaya': 1837,
    'Krasnyy Sulin': 1837,
    'Krasyukovskaya': 1837,
    'Krivyanskaya': 1837,
    'Krym': 1837,
    'Kuleshovka': 1837,
    'Kuybyshevo': 1837,
    'Letnik': 1837,
    'Likhovskoy': 1837,
    'Likhoy': 1837,
    'Martynovskiy Rayon': 1837,
    'Masalovka': 1837,
    'Matveyev Kurgan': 1837,
    'Mayskiy': 1837,
    'Mechetinskaya': 1837,
    'Melikhovskaya': 1837,
    'Millerovo': 1837,
    'Milyutinskaya': 1837,
    'Milyutinskiy Rayon': 1837,
    'Morozovsk': 1837,
    'Nedvigovka': 1837,
    'Nikolayevskaya': 1837,
    'Novobataysk': 1837,
    'Novobessergenovka': 1837,
    'Novocherkassk': 1837,
    'Novoshakhtinsk': 1837,
    'Novyy Yegorlyk': 1837,
    'Oblivskaya': 1837,
    'Ol’ginskaya': 1837,
    'Orlovskiy': 1837,
    'Persianovka': 1837,
    'Peschanokopskoye': 1837,
    'Peshkovo': 1837,
    'Pokrovskoye': 1837,
    'Port-Katon': 1837,
    'Primorka': 1837,
    'Proletarsk': 1837,
    'Rassvet': 1837,
    'Remontnoye': 1837,
    'Rodionovo-Nesvetaiskoye': 1837,
    'Rodionovo-Nesvetayskaya': 1837,
    'Romanovskaya': 1837,
    'Rostov-na-Donu': 1837,
    'Sal’sk': 1837,
    'Samarskoye': 1837,
    'Sambek': 1837,
    'Sandata': 1837,
    'Semikarakorsk': 1837,
    'Shakhty': 1837,
    'Sholokhovskiy': 1837,
    'Sinegorskiy': 1837,
    'Sinyavskoye': 1837,
    'Sokolovo-Kundryuchenskiy': 1837,
    'Sovetskaya': 1837,
    'Staraya Stanitsa': 1837,
    'Starocherkasskaya': 1837,
    'Taganrog': 1837,
    'Talovyy': 1837,
    'Tanais': 1837,
    'Tarasovskiy': 1837,
    'Tatsinskiy': 1837,
    'Tselina': 1837,
    'Tsimlyansk': 1837,
    'Uglegorskiy': 1837,
    'Uglerodovskiy': 1837,
    'Ust’-Donetskiy': 1837,
    'Vesëlyy': 1837,
    'Veshenskaya': 1837,
    'Volgodonsk': 1837,
    'Yegorlykskaya': 1837,
    'Zavetnoye': 1837,
    'Zernograd': 1837,
    'Zhirnov': 1837,
    'Zimovniki': 1837,
    'Zverevo': 1837,
    'Aleksandro-Nevskiy': 1905,
    'Bagramovo': 1905,
    'Chuchkovo': 1905,
    'Gus’-Zheleznyy': 1905,
    'Izhevskoye': 1905,
    'Kadom': 1905,
    'Kasimov': 1905,
    'Kasimovskiy Rayon': 1905,
    'Kiritsy': 1905,
    'Klepikovskiy Rayon': 1905,
    'Korablino': 1905,
    'Lashma': 1905,
    'Lesnoy': 1905,
    'Mikhaylov': 1905,
    'Miloslavskoye': 1905,
    'Murmino': 1905,
    'Novomichurinsk': 1905,
    'Oktyabr’skiy': 1905,
    'Pavelets': 1905,
    'Pitelino': 1905,
    'Polyany': 1905,
    'Pronsk': 1905,
    'Putyatino': 1905,
    'Ryazan’': 1905,
    'Ryazanskiy Rayon': 1905,
    'Ryazhsk': 1905,
    'Rybnovskiy Rayon': 1905,
    'Rybnoye': 1905,
    'Sapozhok': 1905,
    'Sarai': 1905,
    'Sasovo': 1905,
    'Shatsk': 1905,
    'Shilovo': 1905,
    'Skopin': 1905,
    'Solotcha': 1905,
    'Spas-Klepiki': 1905,
    'Spassk-Ryazanskiy': 1905,
    'Starozhilovo': 1905,
    'Syntul': 1905,
    'Tuma': 1905,
    'Ukholovo': 1905,
    'Vilenka': 1905,
    'Yambirno': 1905,
    'Yelat’ma': 1905,
    'Yermish’': 1905,
    'Zakharovo': 1905,
    'Zakharovskiy Rayon': 1905,
    'Zarechnyy': 1905,
    'Admiralteisky': 1879,
    'Aleksandrovskaya': 1879,
    'Avtovo': 1879,
    'Beloostrov': 1879,
    'Centralniy': 1879,
    'Dachnoye': 1879,
    'Frunzenskiy Rayon': 1879,
    'Gorelovo': 1879,
    'Grazhdanka': 1879,
    'Kolomyagi': 1879,
    'Kolpino': 1879,
    'Komarovo': 1879,
    'Komendantsky aerodrom': 1879,
    'Krasnogvargeisky': 1879,
    'Krasnoye Selo': 1879,
    'Kronshtadt': 1879,
    'Kupchino': 1879,
    'Kurortnyy Rayon': 1879,
    'Kushelevka': 1879,
    'Lakhtinskiy': 1879,
    'Levashovo': 1879,
    'Lisiy Nos': 1879,
    'Lomonosov': 1879,
    'Metallostroy': 1879,
    'Molodezhnoye': 1879,
    'Novaya Derevnya': 1879,
    'Obukhovo': 1879,
    'Ol’gino': 1879,
    'Ozerki': 1879,
    'Pargolovo': 1879,
    'Pavlovsk': 1879,
    'Pesochnyy': 1879,
    'Peterhof': 1879,
    'Petro-Slavyanka': 1879,
    'Petrodvorets': 1879,
    'Petrogradka': 1879,
    'Pontonnyy': 1879,
    'Primorskiy Rayon': 1879,
    'Pushkin': 1879,
    'Razliv': 1879,
    'Repino': 1879,
    'Saint Petersburg': 1879,
    'Sapërnyy': 1879,
    'Sestroretsk': 1879,
    'Shushary': 1879,
    'Shuvalovo': 1879,
    'Solnechnoye': 1879,
    'Sosnovaya Polyana': 1879,
    'Sosnovka': 1879,
    'Staraya Derevnya': 1879,
    'Strel  na': 1879,
    'Tarkhovka': 1879,
    'Tyarlevo': 1879,
    'Uritsk': 1879,
    'Ust’-Izhora': 1879,
    'Vasileostrovskiy Rayon': 1879,
    'Vasyl  evsky Ostrov': 1879,
    'Zelenogorsk': 1879,
    'Abyysky District': 1848,
    'Aldan': 1848,
    'Allaikhovskiy Rayon': 1848,
    'Almaznyy': 1848,
    'Amga': 1848,
    'Anabarskiy Rayon': 1848,
    'Aykhal': 1848,
    'Batagay': 1848,
    'Batagay-Alyta': 1848,
    'Belaya Gora': 1848,
    'Berdigestyakh': 1848,
    'Berkakit': 1848,
    'Borogontsy': 1848,
    'Bulunskiy Rayon': 1848,
    'Chernyshevskiy': 1848,
    'Cherskiy': 1848,
    'Chokurdakh': 1848,
    'Chul’man': 1848,
    'Churapcha': 1848,
    'Deputatsky': 1848,
    'Dzhebariki-Khaya': 1848,
    'Eveno-Bytantaysky National District': 1848,
    'Gornyy Rayon': 1848,
    'Kangalassy': 1848,
    'Khandyga': 1848,
    'Khatassy': 1848,
    'Kobyayskiy Rayon': 1848,
    'Kysyl-Syr': 1848,
    'Lebedinyy': 1848,
    'Leninskiy': 1848,
    'Lensk': 1848,
    'Magan': 1848,
    'Markha': 1848,
    'Mayya': 1848,
    'Mirninskiy Rayon': 1848,
    'Mirny': 1848,
    'Mokhsogollokh': 1848,
    'Momsky District': 1848,
    'Namskiy Rayon': 1848,
    'Namtsy': 1848,
    'Neryungri': 1848,
    'Nezhdaninskoe': 1848,
    'Nizhniy Bestyakh': 1848,
    'Nizhniy Kuranakh': 1848,
    'Nyurba': 1848,
    'Nyurbinsky District': 1848,
    'Olenyok': 1848,
    'Olenyoksky District': 1848,
    'Olyokminsk': 1848,
    'Olyokminsky District': 1848,
    'Oymyakonskiy District': 1848,
    'Peleduy': 1848,
    'Pokrovsk': 1848,
    'Sangar': 1848,
    'Saskylakh': 1848,
    'Serebryanyy Bor': 1848,
    'Solnechnyy': 1848,
    'Srednekolymsk': 1848,
    'Srednekolymsky District': 1848,
    'Suntar': 1848,
    'Suntarskiy District': 1848,
    'Tabaga': 1848,
    'Tiksi': 1848,
    'Tommot': 1848,
    'Tomponskiy District': 1848,
    'Udachny': 1848,
    'Ust-Aldansky District': 1848,
    'Ust-Kuyga': 1848,
    'Ust-Maya': 1848,
    'Ust-Nera': 1848,
    'Ust’-Yanskiy Rayon': 1848,
    'Verkhnekolymsky District': 1848,
    'Verkhnevilyuysk': 1848,
    'Verkhnevilyuysky District': 1848,
    'Verkhoyansk': 1848,
    'Verkhoyansky District': 1848,
    'Vilyuysk': 1848,
    'Vilyuyskiy Rayon': 1848,
    'Vilyuysky District': 1848,
    'Vitim': 1848,
    'Yakutsk': 1848,
    'Ytyk-Kyuyël’': 1848,
    'Zhatay': 1848,
    'Zhigansk': 1848,
    'Zhigansky District': 1848,
    'Zyryanka': 1848,
    'Aleksandrovsk-Sakhalinskiy': 1875,
    'Aniva': 1875,
    'Boshnyakovo': 1875,
    'Bykov': 1875,
    'Chekhov': 1875,
    'Dolinsk': 1875,
    'Gastello': 1875,
    'Gornozavodsk': 1875,
    'Kholmsk': 1875,
    'Korsakov': 1875,
    'Krasnogorsk': 1875,
    'Kuril’sk': 1875,
    'Kurilsky District': 1875,
    'Leonidovo': 1875,
    'Makarov': 1875,
    'Mgachi': 1875,
    'Nevel’sk': 1875,
    'Nogliki': 1875,
    'Okha': 1875,
    'Otrada': 1875,
    'Poronaysk': 1875,
    'Pravda': 1875,
    'Severo-Kuril’sk': 1875,
    'Shakhtersk': 1875,
    'Shebunino': 1875,
    'Shikotan': 1875,
    'Sinegorsk': 1875,
    'Smirnykh': 1875,
    'Sokol': 1875,
    'Tomari': 1875,
    'Troitskoye': 1875,
    'Tungor': 1875,
    'Tymovskoye': 1875,
    'Uglegorsk': 1875,
    'Uglegorskiy Rayon': 1875,
    'Uglezavodsk': 1875,
    'Vakhrushev': 1875,
    'Vostok': 1875,
    'Yablochnyy': 1875,
    'Yuzhno-Kurilsk': 1875,
    'Yuzhno-Kurilsky District': 1875,
    'Yuzhno-Sakhalinsk': 1875,
    'Bakhilovo': 1862,
    'Balasheyka': 1862,
    'Berëza': 1862,
    'Bezenchuk': 1862,
    'Bogatoye': 1862,
    'Bogatyr’': 1862,
    'Borskoye': 1862,
    'Chapayevsk': 1862,
    'Chelno-Vershiny': 1862,
    'Dubovyy Umët': 1862,
    'Georgiyevka': 1862,
    'Isakly': 1862,
    'Kamyshla': 1862,
    'Khryashchevka': 1862,
    'Khvorostyanka': 1862,
    'Kinel’': 1862,
    'Kinel’-Cherkassy': 1862,
    'Kinel’skiy Rayon': 1862,
    'Klyavlino': 1862,
    'Koshki': 1862,
    'Krasnoarmeyskoye': 1862,
    'Krasnoyarskiy Rayon': 1862,
    'Krasnyy Yar': 1862,
    'Krotovka': 1862,
    'Kurumoch': 1862,
    'Mayskoye': 1862,
    'Mezhdurechensk': 1862,
    'Mirnyy': 1862,
    'Neftegorsk': 1862,
    'Neftegorskiy Rayon': 1862,
    'Novokuybyshevsk': 1862,
    'Novosemeykino': 1862,
    'Novyy Buyan': 1862,
    'Obsharovka': 1862,
    'Oktyabr’sk': 1862,
    'Osinki': 1862,
    'Otradnyy': 1862,
    'Perevoloki': 1862,
    'Pestravka': 1862,
    'Petra-Dubrava': 1862,
    'Podsolnechnoye': 1862,
    'Podstepki': 1862,
    'Pokhvistnevo': 1862,
    'Povolzhskiy': 1862,
    'Pribrezhnyy': 1862,
    'Roschinskiy': 1862,
    'Rozhdestveno': 1862,
    'Samara': 1862,
    'Sernovodsk': 1862,
    'Shentala': 1862,
    'Shigony': 1862,
    'Smyshlyayevka': 1862,
    'Spiridonovka': 1862,
    'Sukhodol': 1862,
    'Surgut': 1862,
    'Syzran’': 1862,
    'Tol’yatti': 1862,
    'Utevka': 1862,
    'Varlamovo': 1862,
    'Volzhskiy': 1862,
    'Yelkhovka': 1862,
    'Zhigulevsk': 1862,
    'Zhiguli': 1862,
    'Zol’noye': 1862,
    'Aleksandrov Gay': 1887,
    'Alekseyevka': 1887,
    'Arkadak': 1887,
    'Atkarsk': 1887,
    'Atkarskiy Rayon': 1887,
    'Balakovo': 1887,
    'Balashov': 1887,
    'Baltay': 1887,
    'Baltayskiy Rayon': 1887,
    'Bazarno-Karabulakskiy Rayon': 1887,
    'Bazarnyy Karabulak': 1887,
    'Bol’shoy Karay': 1887,
    'Cherkasskoye': 1887,
    'Dergachi': 1887,
    'Dukhovnitskoye': 1887,
    'Engel’s': 1887,
    'Engel’sskiy Rayon': 1887,
    'Gornyy': 1887,
    'Ivanteyevka': 1887,
    'Kalininsk': 1887,
    'Kamenskiy': 1887,
    'Khvalynsk': 1887,
    'Khvalynskiy Rayon': 1887,
    'Khvatovka': 1887,
    'Kormëzhka': 1887,
    'Krasnoarmeysk': 1887,
    'Krasnyy Kut': 1887,
    'Krasnyy Oktyabr’': 1887,
    'Krasnyy Yar': 1887,
    'Lysyye Gory': 1887,
    'Marks': 1887,
    'Marksovskiy Rayon': 1887,
    'Mokrous': 1887,
    'Novouzensk': 1887,
    'Novyye Burasy': 1887,
    'Perelyub': 1887,
    'Petrovsk': 1887,
    'Pinerovka': 1887,
    'Piterka': 1887,
    'Podlesnoye': 1887,
    'Privolzhskiy': 1887,
    'Privolzhskoye': 1887,
    'Pugachev': 1887,
    'Pushkino': 1887,
    'Repnoye': 1887,
    'Romanovka': 1887,
    'Rovnoye': 1887,
    'Rtishchevo': 1887,
    'Samoylovka': 1887,
    'Saratov': 1887,
    'Saratovskiy Rayon': 1887,
    'Sennoy': 1887,
    'Shikhany': 1887,
    'Shumeyka': 1887,
    'Sinodskoye': 1887,
    'Sokolovyy': 1887,
    'Sovetskoye': 1887,
    'Staryye Ozinki': 1887,
    'Stepnoye': 1887,
    'Svetlyy': 1887,
    'Svobodnyy': 1887,
    'Tatishchevo': 1887,
    'Tersa': 1887,
    'Turki': 1887,
    'Vol’sk': 1887,
    'Vol’skiy Rayon': 1887,
    'Voskresenskiy Rayon': 1887,
    'Voskresenskoye': 1887,
    'Yekaterinovka': 1887,
    'Yelshanka': 1887,
    'Yershov': 1887,
    'Afanas’yeva': 1912,
    'Alarskiy Rayon': 1912,
    'Alekseyevskaya': 1912,
    'Algatuy': 1912,
    'Alzamay': 1912,
    'Andreyevka': 1912,
    'Angarsk': 1912,
    'Angarskiy Rayon': 1912,
    'Atagay': 1912,
    'Badar': 1912,
    'Baklashi': 1912,
    'Balagansk': 1912,
    'Balaganskiy Rayon': 1912,
    'Balakhninskiy': 1912,
    'Balaklava': 1912,
    'Balaklava District': 1912,
    'Bayanday': 1912,
    'Bayandayevskiy Rayon': 1912,
    'Baykal’sk': 1912,
    'Bereznyaki': 1912,
    'Biryusinsk': 1912,
    'Bodaybo': 1912,
    'Bokhan': 1912,
    'Bokhanskiy Rayon': 1912,
    'Bol’shaya Rechka': 1912,
    'Bratsk': 1912,
    'Bratskiy Rayon': 1912,
    'Budagovo': 1912,
    'Cheremkhovo': 1912,
    'Cheremkhovskiy Rayon': 1912,
    'Chunskiy': 1912,
    'Dzerzhinsk': 1912,
    'Ekhirit-Bulagatskiy Rayon': 1912,
    'Frontovoye': 1912,
    'Gadaley': 1912,
    'Guran': 1912,
    'Haharinskyi District': 1912,
    'Ikey': 1912,
    'Ilir': 1912,
    'Inkerman': 1912,
    'Irkutsk': 1912,
    'Irkutskiy Rayon': 1912,
    'Kacha': 1912,
    'Kachug': 1912,
    'Kachugskiy Rayon': 1912,
    'Katangskiy Rayon': 1912,
    'Kazachinsko-Lenskiy Rayon': 1912,
    'Kazachinskoye': 1912,
    'Khomutovo': 1912,
    'Khrebtovaya': 1912,
    'Khudoyelanskoye': 1912,
    'Khuzhir': 1912,
    'Kimil’tey': 1912,
    'Kirensk': 1912,
    'Kirenskiy Rayon': 1912,
    'Kitoy': 1912,
    'Kotik': 1912,
    'Kropotkin': 1912,
    'Kultuk': 1912,
    'Kutulik': 1912,
    'Kuytun': 1912,
    'Kuytunskiy Rayon': 1912,
    'Kvitok': 1912,
    'Lenin District': 1912,
    'Lesogorsk': 1912,
    'Listvyanka': 1912,
    'Magistral’nyy': 1912,
    'Mama': 1912,
    'Mamakan': 1912,
    'Mamsko-Chuyskiy Rayon': 1912,
    'Manzurka': 1912,
    'Markova': 1912,
    'Markovo': 1912,
    'Meget': 1912,
    'Mishelevka': 1912,
    'Mugun': 1912,
    'Nakhimovskiy rayon': 1912,
    'Nizhneilimskiy Rayon': 1912,
    'Nizhneudinsk': 1912,
    'Nizhneudinskiy Rayon': 1912,
    'Novaya Igirma': 1912,
    'Novobiryusinskiy': 1912,
    'Novonukutskiy': 1912,
    'Ol’khonskiy Rayon': 1912,
    'Orlinoye': 1912,
    'Osa': 1912,
    'Oyëk': 1912,
    'Pad’ Mel’nichnaya': 1912,
    'Perfilovo': 1912,
    'Pivovarikha': 1912,
    'Pokosnoye': 1912,
    'Polyushko': 1912,
    'Rudnogorsk': 1912,
    'Sakharnaya Golovka': 1912,
    'Sayansk': 1912,
    'Sevastopol': 1912,
    'Shamanka': 1912,
    'Sheberta': 1912,
    'Shelekhov': 1912,
    'Sheragul': 1912,
    'Shestakovo': 1912,
    'Shitkino': 1912,
    'Shturmovoye': 1912,
    'Shumskiy': 1912,
    'Slyudyanka': 1912,
    'Slyudyanskiy Rayon': 1912,
    'Smolenshchina': 1912,
    'Solnechniy': 1912,
    'Sredniy': 1912,
    'Svirsk': 1912,
    'Tayshet': 1912,
    'Tayshetskiy Rayon': 1912,
    'Tayturka': 1912,
    'Tel’ma': 1912,
    'Ternovka': 1912,
    'Tulun': 1912,
    'Tulunskiy Rayon': 1912,
    'Tulyushka': 1912,
    'Tyret’ Pervaya': 1912,
    'Uk': 1912,
    'Urik': 1912,
    'Usol’skiy Rayon': 1912,
    'Usol’ye-Sibirskoye': 1912,
    'Ust’-Ilimsk': 1912,
    'Ust’-Kut': 1912,
    'Ust’-Kutskiy Rayon': 1912,
    'Ust’-Ordynskiy': 1912,
    'Ust’-Uda': 1912,
    'Ust’-Udinskiy Rayon': 1912,
    'Verkhnesadovoye': 1912,
    'Vidim': 1912,
    'Vikhorevka': 1912,
    'Voznesenskiy': 1912,
    'Yantal’': 1912,
    'Yedogon': 1912,
    'Yelantsy': 1912,
    'Yerbogachen': 1912,
    'Yurty': 1912,
    'Zalari': 1912,
    'Zalarinskiy Rayon': 1912,
    'Zheleznodorozhnyy': 1912,
    'Zheleznogorsk-Ilimskiy': 1912,
    'Zhigalovo': 1912,
    'Zhigalovskiy Rayon': 1912,
    'Zima': 1912,
    'Ziminskiy Rayon': 1912,
    'Zvëzdnyy': 1912,
    'Demidov': 1885,
    'Desnogorsk': 1885,
    'Dorogobuzh': 1885,
    'Dukhovshchina': 1885,
    'Gagarin': 1885,
    'Glinka': 1885,
    'Golynki': 1885,
    'Kardymovo': 1885,
    'Katyn  ': 1885,
    'Khislavichi': 1885,
    'Kholm-Zhirkovskiy': 1885,
    'Monastyrshchina': 1885,
    'Novodugino': 1885,
    'Oster': 1885,
    'Ozërnyy': 1885,
    'Pechersk': 1885,
    'Pochinok': 1885,
    'Roslavl’': 1885,
    'Rudnya': 1885,
    'Safonovo': 1885,
    'Safonovskiy Rayon': 1885,
    'Shatalovo': 1885,
    'Shumyachi': 1885,
    'Smolensk': 1885,
    'Stodolishche': 1885,
    'Sychëvka': 1885,
    'Talashkino': 1885,
    'Tëmkino': 1885,
    'Ugra': 1885,
    'Velizh': 1885,
    'Verkhnedneprovskiy': 1885,
    'Vyaz’ma': 1885,
    'Yartsevo': 1885,
    'Yekimovichi': 1885,
    'Yel’nya': 1885,
    'Yershichi': 1885,
    'Achikulak': 1868,
    'Aleksandriya': 1868,
    'Aleksandriyskaya': 1868,
    'Aleksandrovskoye': 1868,
    'Andzhiyevskiy': 1868,
    'Arzgir': 1868,
    'Balakhonovskoye': 1868,
    'Barsukovskaya': 1868,
    'Bekeshevskaya': 1868,
    'Beshpagir': 1868,
    'Bezopasnoye': 1868,
    'Blagodarnyy': 1868,
    'Blagodatnoye': 1868,
    'Borgustanskaya': 1868,
    'Budënnovsk': 1868,
    'Burlatskoye': 1868,
    'Chernolesskoye': 1868,
    'Divnoye': 1868,
    'Dmitriyevskoye': 1868,
    'Donskoye': 1868,
    'Edissiya': 1868,
    'Etoka': 1868,
    'Galyugayevskaya': 1868,
    'Georgiyevsk': 1868,
    'Georgiyevskaya': 1868,
    'Gofitskoye': 1868,
    'Goryachevodskiy': 1868,
    'Grazhdanskoye': 1868,
    'Grecheskoye': 1868,
    'Grigoropolisskaya': 1868,
    'Inozemtsevo': 1868,
    'Ipatovo': 1868,
    'Irgakly': 1868,
    'Ivanovskoye': 1868,
    'Izobil’nyy': 1868,
    'Kalinovskoye': 1868,
    'Kangly': 1868,
    'Kara-Tyube': 1868,
    'Kayasula': 1868,
    'Kevsala': 1868,
    'Kislovodsk': 1868,
    'Kochubeyevskoye': 1868,
    'Komsomolets': 1868,
    'Konstantinovskaya': 1868,
    'Konstantinovskoye': 1868,
    'Krasnogvardeyskoye': 1868,
    'Krasnokumskoye': 1868,
    'Kruglolesskoye': 1868,
    'Kursavka': 1868,
    'Kurskaya': 1868,
    'Ladovskaya Balka': 1868,
    'Lermontov': 1868,
    'Letnyaya Stavka': 1868,
    'Levokumka': 1868,
    'Levokumskoye': 1868,
    'Lysogorskaya': 1868,
    'Mikhaylovsk': 1868,
    'Mineralnye Vody': 1868,
    'Moskovskoye': 1868,
    'Nadezhda': 1868,
    'Nagutskoye': 1868,
    'Neftekumsk': 1868,
    'Nevinnomyssk': 1868,
    'Nezhinskiy': 1868,
    'Nezlobnaya': 1868,
    'Niny': 1868,
    'Novoaleksandrovsk': 1868,
    'Novoblagodarnoye': 1868,
    'Novopavlovsk': 1868,
    'Novoselitskoye': 1868,
    'Novoterskiy': 1868,
    'Novotroitskaya': 1868,
    'Novozavedennoye': 1868,
    'Orlovka': 1868,
    'Otkaznoye': 1868,
    'Ovoshchi': 1868,
    'Pelagiada': 1868,
    'Podgornaya': 1868,
    'Podkumskiy': 1868,
    'Pokoynoye': 1868,
    'Praskoveya': 1868,
    'Pravokumskoye': 1868,
    'Pregradnoye': 1868,
    'Pyatigorsk': 1868,
    'Pyatigorskiy': 1868,
    'Rasshevatskaya': 1868,
    'Ryzdvyanyy': 1868,
    'Severnoye': 1868,
    'Soldato-Aleksandrovskoye': 1868,
    'Solomenskoye': 1868,
    'Soluno-Dmitriyevskoye': 1868,
    'Sotnikovskoye': 1868,
    'Sovetskaya': 1868,
    'Spitsevka': 1868,
    'Starodubskoye': 1868,
    'Staropavlovskaya': 1868,
    'Stavropol’': 1868,
    'Stepnoye': 1868,
    'Sukhaya Buyvola': 1868,
    'Suvorovskaya': 1868,
    'Svetlograd': 1868,
    'Svobody': 1868,
    'Tatarka': 1868,
    'Temizhbekskaya': 1868,
    'Tishchenskoye': 1868,
    'Trunovskoye': 1868,
    'Urozhaynoye': 1868,
    'Vinsady': 1868,
    'Vostochny': 1868,
    'Vysotskoye': 1868,
    'Yasnaya Polyana': 1868,
    'Yelizavetinskoye': 1868,
    'Yessentuki': 1868,
    'Yessentukskaya': 1868,
    'Yutsa': 1868,
    'Zaterechnyy': 1868,
    'Zavetnoye': 1868,
    'Zelenokumsk': 1868,
    'Zheleznovodsk': 1868,
    'Zmeyka': 1868,
    'Achit': 1894,
    'Alapayevsk': 1894,
    'Aramil': 1894,
    'Artëmovskiy': 1894,
    'Arti': 1894,
    'Asbest': 1894,
    'Asbestovskiy': 1894,
    'Atig': 1894,
    'Azanka': 1894,
    'Baraba': 1894,
    'Baranchinskiy': 1894,
    'Baykalovo': 1894,
    'Beloyarskiy': 1894,
    'Beryozovsky': 1894,
    'Bilimbay': 1894,
    'Bisert’': 1894,
    'Bobrovskiy': 1894,
    'Bogdanovich': 1894,
    'Bulanash': 1894,
    'Butka': 1894,
    'Cherëmukhovo': 1894,
    'Chernoistochinsk': 1894,
    'Degtyarsk': 1894,
    'Druzhinino': 1894,
    'Dvurechensk': 1894,
    'Gari': 1894,
    'Gornyy Shchit': 1894,
    'Gryaznovskoye': 1894,
    'Irbit': 1894,
    'Is': 1894,
    'Iset’': 1894,
    'Istok': 1894,
    'Ivdel’': 1894,
    'Izumrud': 1894,
    'Kachkanar': 1894,
    'Kalinovo': 1894,
    'Kamensk-Ural’skiy': 1894,
    'Kamyshlov': 1894,
    'Karpinsk': 1894,
    'Karpushikha': 1894,
    'Kedrovoye': 1894,
    'Kirovgrad': 1894,
    'Klyuchevsk': 1894,
    'Krasnogvardeyskiy': 1894,
    'Krasnotur’insk': 1894,
    'Krasnoufimsk': 1894,
    'Krasnoural’sk': 1894,
    'Kushva': 1894,
    'Kuzino': 1894,
    'Lesnoy': 1894,
    'Levikha': 1894,
    'Lobva': 1894,
    'Losinyy': 1894,
    'Lugovskoy': 1894,
    'Malysheva': 1894,
    'Martyush': 1894,
    'Mikhaylovsk': 1894,
    'Monetnyy': 1894,
    'Nev’yansk': 1894,
    'Neyvo-Rudyanka': 1894,
    'Neyvo-Shaytanskiy': 1894,
    'Nikolo-Pavlovskoye': 1894,
    'Nizhniye Sergi': 1894,
    'Nizhny Tagil': 1894,
    'Nizhnyaya Irga': 1894,
    'Nizhnyaya Salda': 1894,
    'Nizhnyaya Tura': 1894,
    'Novaya Lyalya': 1894,
    'Novoasbest': 1894,
    'Novoural’sk': 1894,
    'Novoutkinsk': 1894,
    'Obukhovskoye': 1894,
    'Ous': 1894,
    'Pelym': 1894,
    'Pervoural’sk': 1894,
    'Petrokamenskoye': 1894,
    'Pokrovskoye': 1894,
    'Polevskoy': 1894,
    'Polunochnoye': 1894,
    'Pyshma': 1894,
    'Reftinskiy': 1894,
    'Revda': 1894,
    'Rezh': 1894,
    'Rudnichnyy': 1894,
    'Sarana': 1894,
    'Serov': 1894,
    'Severka': 1894,
    'Severoural’sk': 1894,
    'Shabrovskiy': 1894,
    'Shalya': 1894,
    'Shamary': 1894,
    'Shchelkun': 1894,
    'Shirokaya Rechka': 1894,
    'Shuvakish': 1894,
    'Sos’va': 1894,
    'Sovkhoznyy': 1894,
    'Sredneuralsk': 1894,
    'Staropyshminsk': 1894,
    'Staroutkinsk': 1894,
    'Sukhoy Log': 1894,
    'Svobodnyy': 1894,
    'Sylva': 1894,
    'Sysert’': 1894,
    'Sysertskiy Rayon': 1894,
    'Tabory': 1894,
    'Talitsa': 1894,
    'Tavda': 1894,
    'Tretiy Severnyy': 1894,
    'Troitskiy': 1894,
    'Tsementnyy': 1894,
    'Tugulym': 1894,
    'Turinsk': 1894,
    'Turinskaya Sloboda': 1894,
    'Ufimskiy': 1894,
    'Uralets': 1894,
    'Valerianovsk': 1894,
    'Verkh-Neyvinskiy': 1894,
    'Verkhneye Dubrovo': 1894,
    'Verkhniy Tagil': 1894,
    'Verkhniye Sergi': 1894,
    'Verkhnyaya Pyshma': 1894,
    'Verkhnyaya Salda': 1894,
    'Verkhnyaya Sinyachikha': 1894,
    'Verkhnyaya Sysert’': 1894,
    'Verkhnyaya Tura': 1894,
    'Verkhotur’ye': 1894,
    'Visim': 1894,
    'Volchansk': 1894,
    'Vostochnyy': 1894,
    'Yekaterinburg': 1894,
    'Yelanskiy': 1894,
    'Yëlkino': 1894,
    'Yertarskiy': 1894,
    'Yushala': 1894,
    'Zarechnyy': 1894,
    'Zavodouspenskoye': 1894,
    'Zaykovo': 1894,
    'Zyuzel’skiy': 1894,
    'Bokino': 1878,
    'Bondarskiy Rayon': 1878,
    'Donskoye': 1878,
    'Gavrilovka Vtoraya': 1878,
    'Goreloye': 1878,
    'Inzhavino': 1878,
    'Kirsanov': 1878,
    'Kirsanovskiy Rayon': 1878,
    'Komsomolets': 1878,
    'Kotovsk': 1878,
    'Michurinsk': 1878,
    'Mordovo': 1878,
    'Morshansk': 1878,
    'Muchkapskiy': 1878,
    'Novaya Lyada': 1878,
    'Novopokrovka': 1878,
    'Pervomayskiy': 1878,
    'Pichayevo': 1878,
    'Platonovka': 1878,
    'Pokrovo-Prigorodnoye': 1878,
    'Rasskazovo': 1878,
    'Rasskazovskiy Rayon': 1878,
    'Rzhaksa': 1878,
    'Sampurskiy Rayon': 1878,
    'Satinka': 1878,
    'Selezni': 1878,
    'Sosnovka': 1878,
    'Staroyur’yevo': 1878,
    'Tambov': 1878,
    'Tokarevskiy Rayon': 1878,
    'Uvarovo': 1878,
    'Zavoronezhskoye': 1878,
    'Zherdevka': 1878,
    'Znamenka': 1878,
    'Znamenskiy Rayon': 1878,
    'Aleksandrovskoye': 1872,
    'Asino': 1872,
    'Bakchar': 1872,
    'Belyy Yar': 1872,
    'Bogashevo': 1872,
    'Kaltay': 1872,
    'Kargasok': 1872,
    'Kedrovyy': 1872,
    'Kolpashevo': 1872,
    'Kozhevnikovo': 1872,
    'Krasnyy Yar': 1872,
    'Krivosheino': 1872,
    'Mel’nikovo': 1872,
    'Molchanovo': 1872,
    'Molchanovskiy Rayon': 1872,
    'Moryakovskiy Zaton': 1872,
    'Parabel’': 1872,
    'Pervomayskoye': 1872,
    'Podgornoye': 1872,
    'Samus’': 1872,
    'Seversk': 1872,
    'Strezhevoy': 1872,
    'Svetlyy': 1872,
    'Tegul’det': 1872,
    'Tegul’detskiy Rayon': 1872,
    'Timiryazevskoye': 1872,
    'Togur': 1872,
    'Tomsk': 1872,
    'Tomskiy Rayon': 1872,
    'Zyryanskoye': 1872,
    'Ageyevo': 1895,
    'Aleksin': 1895,
    'Aleksinskiy Rayon': 1895,
    'Arsen’yevo': 1895,
    'Barsuki': 1895,
    'Begichevskiy': 1895,
    'Belëv': 1895,
    'Bogoroditsk': 1895,
    'Bol’shoye Skuratovo': 1895,
    'Bolokhovo': 1895,
    'Borodinskiy': 1895,
    'Brusyanka': 1895,
    'Chekalin': 1895,
    'Cherepet’': 1895,
    'Chern’': 1895,
    'Donskoy': 1895,
    'Dubna': 1895,
    'Dubovka': 1895,
    'Gorelki': 1895,
    'Gritsovskiy': 1895,
    'Kamenetskiy': 1895,
    'Kazachka': 1895,
    'Khanino': 1895,
    'Kimovsk': 1895,
    'Kireyevsk': 1895,
    'Kosaya Gora': 1895,
    'Krapivna': 1895,
    'Kurkino': 1895,
    'Leninskiy': 1895,
    'Lomintsevskiy': 1895,
    'Mayskiy': 1895,
    'Mendeleyevskiy': 1895,
    'Novogurovskiy': 1895,
    'Novomoskovsk': 1895,
    'Odoyev': 1895,
    'Partizan': 1895,
    'Pervomayskiy': 1895,
    'Plavsk': 1895,
    'Podlesnyy': 1895,
    'Priupskiy': 1895,
    'Revyakino': 1895,
    'Rozhdestvenskiy': 1895,
    'Severo-Zadonsk': 1895,
    'Shchëkino': 1895,
    'Shvartsevskiy': 1895,
    'Skuratovskiy': 1895,
    'Slavniy': 1895,
    'Suvorov': 1895,
    'Suvorovskiy Rayon': 1895,
    'Tëplo-Ogarëvskiy Rayon': 1895,
    'Tëploye': 1895,
    'Tovarkovskiy': 1895,
    'Tula': 1895,
    'Uzlovaya': 1895,
    'Venëv': 1895,
    'Venevskiy Rayon': 1895,
    'Volovo': 1895,
    'Yasnogorsk': 1895,
    'Yasnogorskiy Rayon': 1895,
    'Yefremov': 1895,
    'Yepifan’': 1895,
    'Zaokskiy': 1895,
    'Zaokskiy Rayon': 1895,
    'Zhdankovskiy': 1895,
    'Ak-Dovurak': 1900,
    'Balgazyn': 1900,
    'Bay-Khaak': 1900,
    'Chadan': 1900,
    'Erzin': 1900,
    'Kaa-Khem': 1900,
    'Khandagayty': 1900,
    'Khovu-Aksy': 1900,
    'Kungurtug': 1900,
    'Kyzyl': 1900,
    'Kyzyl-Khaya': 1900,
    'Kyzyl-Mazhalyk': 1900,
    'Mugur-Aksy': 1900,
    'Samagaltay': 1900,
    'Saryg-Sep': 1900,
    'Shagonar': 1900,
    'Sukpak': 1900,
    'Sut-Khol’': 1900,
    'Teeli': 1900,
    'Toora-Khem': 1900,
    'Turan': 1900,
    'Andreapol’': 1860,
    'Andreapol’skiy Rayon': 1860,
    'Bel’skiy Rayon': 1860,
    'Belyy': 1860,
    'Belyy Gorodok': 1860,
    'Berezayka': 1860,
    'Bernovo': 1860,
    'Bezhetsk': 1860,
    'Bezhetskiy Rayon': 1860,
    'Bologovskiy Rayon': 1860,
    'Bologoye': 1860,
    'Bologoye-4': 1860,
    'Emmaus': 1860,
    'Firovo': 1860,
    'Firovskiy Rayon': 1860,
    'Gorodnya': 1860,
    'Il’inskoye': 1860,
    'Izoplit': 1860,
    'Kalashnikovo': 1860,
    'Kalininskiy Rayon': 1860,
    'Kalyazin': 1860,
    'Kalyazinskiy Rayon': 1860,
    'Kashin': 1860,
    'Kashinskiy Rayon': 1860,
    'Kesova Gora': 1860,
    'Kesovogorskiy Rayon': 1860,
    'Kimrskiy Rayon': 1860,
    'Kimry': 1860,
    'Konakovo': 1860,
    'Konakovskiy Rayon': 1860,
    'Kozlovo': 1860,
    'Krasnokholmskiy Rayon': 1860,
    'Krasnomayskiy': 1860,
    'Krasnyy Kholm': 1860,
    'Kuvshinovo': 1860,
    'Kuvshinovskiy Rayon': 1860,
    'Kuzhenkino': 1860,
    'Lesnoy Rayon': 1860,
    'Lesnoye': 1860,
    'Likhoslavl’': 1860,
    'Likhoslavl’skiy Rayon': 1860,
    'Maksatikha': 1860,
    'Maksatikhinskiy Rayon': 1860,
    'Molokovo': 1860,
    'Nelidovo': 1860,
    'Novozavidovskiy': 1860,
    'Olenino': 1860,
    'Orsha': 1860,
    'Ostashkov': 1860,
    'Ostashkovskiy Rayon': 1860,
    'Peno': 1860,
    'Penovskiy Rayon': 1860,
    'Radchenko': 1860,
    'Rameshki': 1860,
    'Rameshkovskiy Rayon': 1860,
    'Redkino': 1860,
    'Rzhev': 1860,
    'Sakharovo': 1860,
    'Sandovo': 1860,
    'Sandovskiy Rayon': 1860,
    'Selishche': 1860,
    'Selizharovo': 1860,
    'Selizharovskiy Rayon': 1860,
    'Sonkovo': 1860,
    'Spirovo': 1860,
    'Spirovskiy Rayon': 1860,
    'Stantsia Staritsa': 1860,
    'Staraya Toropa': 1860,
    'Staritsa': 1860,
    'Staritskiy Rayon': 1860,
    'Toropets': 1860,
    'Toropetskiy Rayon': 1860,
    'Torzhok': 1860,
    'Torzhokskiy Rayon': 1860,
    'Tver': 1860,
    'Udomlya': 1860,
    'Vasil’yevskiy Mokh': 1860,
    'Velikooktyabr’skiy': 1860,
    'Ves’yegonsk': 1860,
    'Ves’yegonskiy Rayon': 1860,
    'Vydropuzhsk': 1860,
    'Vypolzovo': 1860,
    'Vyshnevolotskiy Rayon': 1860,
    'Vyshniy Volochëk': 1860,
    'Zapadnaya Dvina': 1860,
    'Zavidovo': 1860,
    'Zharkovskiy': 1860,
    'Zubtsov': 1860,
    'Zubtsovskiy Rayon': 1860,
    'Abalak': 1907,
    'Abatskoye': 1907,
    'Antipino': 1907,
    'Armizonskiy Rayon': 1907,
    'Armizonskoye': 1907,
    'Aromashevo': 1907,
    'Berdyuzhskiy Rayon': 1907,
    'Bogandinskiy': 1907,
    'Borovskiy': 1907,
    'Chervishevo': 1907,
    'Golyshmanovo': 1907,
    'Golyshmanovskiy Rayon': 1907,
    'Irtyshskiy': 1907,
    'Isetskiy Rayon': 1907,
    'Isetskoye': 1907,
    'Ishim': 1907,
    'Ishimskiy Rayon': 1907,
    'Kazanskiy Rayon': 1907,
    'Kazanskoye': 1907,
    'Melioratorov': 1907,
    'Mendeleyevo': 1907,
    'Moskovskiy': 1907,
    'Nizhnyaya Tavda': 1907,
    'Novaya Zaimka': 1907,
    'Novoseleznëvo': 1907,
    'Omutinskiy': 1907,
    'Omutinskiy Rayon': 1907,
    'Onokhino': 1907,
    'Sladkovo': 1907,
    'Sladkovskiy Rayon': 1907,
    'Sumkino': 1907,
    'Tobol’sk': 1907,
    'Turtas': 1907,
    'Tyumen': 1907,
    'Uporovo': 1907,
    'Uporovskiy Rayon': 1907,
    'Uspenka': 1907,
    'Uvat': 1907,
    'Vagay': 1907,
    'Vagayskiy Rayon': 1907,
    'Vikulovo': 1907,
    'Vinzili': 1907,
    'Yalutorovsk': 1907,
    'Yarkovo': 1907,
    'Yurginskoye': 1907,
    'Zavodoukovsk': 1907,
    'Alnashi': 1913,
    'Balezino': 1913,
    'Debesy': 1913,
    'Fakel': 1913,
    'Glazov': 1913,
    'Grakhovo': 1913,
    'Igra': 1913,
    'Izhevsk': 1913,
    'Kama': 1913,
    'Kambarka': 1913,
    'Karakulino': 1913,
    'Karakulinskiy Rayon': 1913,
    'Kez': 1913,
    'Khokhryaki': 1913,
    'Kiyasovo': 1913,
    'Kiyasovskiy Rayon': 1913,
    'Kizner': 1913,
    'Krasnogorskoye': 1913,
    'Malaya Purga': 1913,
    'Malopurginskiy Rayon': 1913,
    'Mozhga': 1913,
    'Pirogovo': 1913,
    'Pudem': 1913,
    'Pychas': 1913,
    'Sarapul': 1913,
    'Sarapul’skiy Rayon': 1913,
    'Selty': 1913,
    'Sharkan': 1913,
    'Sigayevo': 1913,
    'Syumsi': 1913,
    'Uva': 1913,
    'Vavozh': 1913,
    'Votkinsk': 1913,
    'Yar': 1913,
    'Yukamenskoye': 1913,
    'Zav’yalovskiy Rayon': 1913,
    'Barysh': 1856,
    'Bazarnyy Syzgan': 1856,
    'Cherdaklinskiy Rayon': 1856,
    'Cherdakly': 1856,
    'Chufarovo': 1856,
    'Dimitrovgrad': 1856,
    'Glotovka': 1856,
    'Ignatovka': 1856,
    'Imeni Vladimira Il’icha Lenina': 1856,
    'Inza': 1856,
    'Isheyevka': 1856,
    'Izmaylovo': 1856,
    'Kanadey': 1856,
    'Karsun': 1856,
    'Krasnyy Gulyay': 1856,
    'Kuzovatovo': 1856,
    'Mayna': 1856,
    'Maynskiy Rayon': 1856,
    'Mirnyy': 1856,
    'Mullovka': 1856,
    'Nikolayevka': 1856,
    'Novaya Malykla': 1856,
    'Novaya Mayna': 1856,
    'Novocheremshansk': 1856,
    'Novospasskoye': 1856,
    'Novoul’yanovsk': 1856,
    'Pavlovka': 1856,
    'Pavlovskiy Rayon': 1856,
    'Radishchevo': 1856,
    'Sengiley': 1856,
    'Silikatnyy': 1856,
    'Staraya Kulatka': 1856,
    'Staraya Mayna': 1856,
    'Starokulatkinskiy Rayon': 1856,
    'Starotimoshkino': 1856,
    'Surskoye': 1856,
    'Teren’gul’skiy Rayon': 1856,
    'Tsil’na': 1856,
    'Tsil’ninskiy Rayon': 1856,
    'Ulyanovsk': 1856,
    'Ulyanovskiy Rayon': 1856,
    'Undory': 1856,
    'Veshkayma': 1856,
    'Yazykovo': 1856,
    'Zhadovka': 1856,
    'Aleksandrov': 1881,
    'Aleksandrovskiy Rayon': 1881,
    'Andreyevo': 1881,
    'Anopino': 1881,
    'Arsaki': 1881,
    'Balakirevo': 1881,
    'Bavleny': 1881,
    'Belaya Rechka': 1881,
    'Bogolyubovo': 1881,
    'Dobryatino': 1881,
    'Galitsy': 1881,
    'Golovino': 1881,
    'Gorokhovets': 1881,
    'Gus’-Khrustal’nyy': 1881,
    'Gusevskiy': 1881,
    'Imeni Vorovskogo': 1881,
    'Ivanishchi': 1881,
    'Kameshkovo': 1881,
    'Karabanovo': 1881,
    'Kideksha': 1881,
    'Kirzhach': 1881,
    'Kol’chugino': 1881,
    'Kol’chuginskiy Rayon': 1881,
    'Kommunar': 1881,
    'Kosterevo': 1881,
    'Kovrov': 1881,
    'Krasnaya Gorbatka': 1881,
    'Krasnyy Bogatyr’': 1881,
    'Krasnyy Oktyabr’': 1881,
    'Kurlovo': 1881,
    'Lakinsk': 1881,
    'Luknovo': 1881,
    'Melekhovo': 1881,
    'Melenki': 1881,
    'Melenkovskiy Rayon': 1881,
    'Mezinovskiy': 1881,
    'Mstera': 1881,
    'Murom': 1881,
    'Muromskiy Rayon': 1881,
    'Muromtsevo': 1881,
    'Nikologory': 1881,
    'Novki': 1881,
    'Novovyazniki': 1881,
    'Oktyabr’skiy': 1881,
    'Orgtrud': 1881,
    'Petushinskiy Rayon': 1881,
    'Petushki': 1881,
    'Pokrov': 1881,
    'Raduzhnyy': 1881,
    'Sobinka': 1881,
    'Sobinskiy Rayon': 1881,
    'Stavrovo': 1881,
    'Stepantsevo': 1881,
    'Strunino': 1881,
    'Sudogda': 1881,
    'Sudogodskiy Rayon': 1881,
    'Suzdal’': 1881,
    'Suzdal’skiy Rayon': 1881,
    'Urshel’skiy': 1881,
    'Velikodvorskiy': 1881,
    'Vladimir': 1881,
    'Vorsha': 1881,
    'Vyazniki': 1881,
    'Yur’yev-Pol’skiy': 1881,
    'Yur’yev-Pol’skiy Rayon': 1881,
    'Zolotkovo': 1881,
    'Alimov-Lyubimovsky': 4916,
    'Amochayevsky': 4916,
    'Antipovka': 4916,
    'Archedino-Chernushinsky': 4916,
    'Archedinskaya': 4916,
    'Beluzhino-Koldairov': 4916,
    'Bereslavka': 4916,
    'Bobrovsky 2-y': 4916,
    'Burnatsky': 4916,
    'Buyerak-Popovsky': 4916,
    'Bykovo, Volgograd Oblast': 4916,
    'Chernyshkovsky': 4916,
    'Chiganaki 1-ye': 4916,
    'Chiganaki 2-ye': 4916,
    'Danilovka, Volgograd Oblast': 4916,
    'Dubovka': 4916,
    'Elton': 4916,
    'Fastov': 4916,
    'Frolovo': 4916,
    'Glubokovsky': 4916,
    'Gorbatovsky': 4916,
    'Gornaya Polyana': 4916,
    'Gorodishche, Volgograd Oblast': 4916,
    'Ilovlya': 4916,
    'Kalach-na-Donu': 4916,
    'Kamyshin': 4916,
    'Karpovsky': 4916,
    'Kletskaya': 4916,
    'Kolotsky': 4916,
    'Kotelnikovo': 4916,
    'Kotovo': 4916,
    'Krasnoslobodsk': 4916,
    'Krasny Yar, Volgograd Oblast': 4916,
    'Kumylzhenskaya': 4916,
    'Leninsk': 4916,
    'Log': 4916,
    'Medveditsky': 4916,
    'Mikhaylovka': 4916,
    'Mikhaylovka Urban Okrug': 4916,
    'Mitkin': 4916,
    'Nekhayevskaya': 4916,
    'Nikolayevsk': 4916,
    'Nizhny Chir': 4916,
    'Novoanninsky': 4916,
    'Novonikolayevsky, Volgograd Oblast': 4916,
    'Novonikolskoe': 4916,
    'Novy Rogachik': 4916,
    'Oktyabrsky': 4916,
    'Olkhovka, Volgograd Oblast': 4916,
    'Otruba': 4916,
    'Pallasovka': 4916,
    'Petrov Val': 4916,
    'Poltavsky': 4916,
    'Popovsky': 4916,
    'Preobrazhenskaya, Volgograd Oblast': 4916,
    'Primorsk': 4916,
    'Repino': 4916,
    'Rogachev': 4916,
    'Rubyozhny': 4916,
    'Rudnya, Volgograd Oblast': 4916,
    'Russko-Osinovsky': 4916,
    'Samoylovsky': 4916,
    'Satarovsky': 4916,
    'Savinka': 4916,
    'Sebrovo': 4916,
    'Serafimovich': 4916,
    'Shirokov': 4916,
    'Slashchyovskaya': 4916,
    'Sovetsky District': 4916,
    'Srednyaya Akhtuba': 4916,
    'Staraya Poltavka': 4916,
    'Surovikino': 4916,
    'Svetly Yar': 4916,
    'Tochilkin': 4916,
    'Uryupinsk': 4916,
    'Vodstroy': 4916,
    'Volgograd': 4916,
    'Volzhsky': 4916,
    'Voroshilovskiy District, Volgograd': 4916,
    'Yelan': 4916,
    'Zaplavnoye': 4916,
    'Zhirnovsk': 4916,
    'Babayevo': 1874,
    'Babayevskiy Rayon': 1874,
    'Belozërsk': 1874,
    'Belozërskiy Rayon': 1874,
    'Chagoda': 1874,
    'Chagodoshchenskiy Rayon': 1874,
    'Chebsara': 1874,
    'Cherepovets': 1874,
    'Cherepovetskiy Rayon': 1874,
    'Ferapontovo': 1874,
    'Goritsy': 1874,
    'Gryazovets': 1874,
    'Gryazovetskiy Rayon': 1874,
    'Imeni Babushkina': 1874,
    'Imeni Zhelyabova': 1874,
    'Kadnikov': 1874,
    'Kaduy': 1874,
    'Kaduyskiy Rayon': 1874,
    'Kharovsk': 1874,
    'Kharovskiy Rayon': 1874,
    'Khokhlovo': 1874,
    'Kichmengskiy Gorodok': 1874,
    'Kichmengsko-Gorodetskiy Rayon': 1874,
    'Kirillov': 1874,
    'Kirillovskiy Rayon': 1874,
    'Krasavino': 1874,
    'Kuzino': 1874,
    'Lipin Bor': 1874,
    'Mezhdurechenskiy Rayon': 1874,
    'Molochnoye': 1874,
    'Myaksa': 1874,
    'Nelazskoye': 1874,
    'Nikol’sk': 1874,
    'Nyuksenitsa': 1874,
    'Sazonovo': 1874,
    'Sheksna': 1874,
    'Sheksninskiy Rayon': 1874,
    'Shuyskoye': 1874,
    'Sokol': 1874,
    'Sokol’skiy Rayon': 1874,
    'Suda': 1874,
    'Syamzha': 1874,
    'Syamzhenskiy Rayon': 1874,
    'Tarnogskiy Gorodok': 1874,
    'Tarnogskiy Rayon': 1874,
    'Tonshalovo': 1874,
    'Tot’ma': 1874,
    'Totemskiy Rayon': 1874,
    'Ust’-Kubinskiy Rayon': 1874,
    'Ust’ye': 1874,
    'Ustyuzhenskiy Rayon': 1874,
    'Ustyuzhna': 1874,
    'Velikiy Ustyug': 1874,
    'Velikoustyugskiy Rayon': 1874,
    'Verkhovazh’ye': 1874,
    'Verkhovazhskiy Rayon': 1874,
    'Vokhtoga': 1874,
    'Vologda': 1874,
    'Vologodskiy Rayon': 1874,
    'Vozhega': 1874,
    'Vytegorskiy Rayon': 1874,
    'Vytegra': 1874,
    'Zubovo': 1874,
    'Abramovka': 1906,
    'Anna': 1906,
    'Anninskiy Rayon': 1906,
    'Belogor’ye': 1906,
    'Bobrov': 1906,
    'Bobrovskiy Rayon': 1906,
    'Boguchar': 1906,
    'Borisoglebsk': 1906,
    'Buturlinovka': 1906,
    'Buturlinovskiy Rayon': 1906,
    'Davydovka': 1906,
    'Devitsa': 1906,
    'Drakino': 1906,
    'Ertil’': 1906,
    'Ertil’skiy Rayon': 1906,
    'Gribanovskiy': 1906,
    'Gribanovskiy Rayon': 1906,
    'Imeni Pervogo Maya': 1906,
    'Kalach': 1906,
    'Kamenka': 1906,
    'Kamenskiy Rayon': 1906,
    'Kantemirovka': 1906,
    'Kashirskiy Rayon': 1906,
    'Kashirskoye': 1906,
    'Khrenovoye': 1906,
    'Kolodeznyy': 1906,
    'Korotoyak': 1906,
    'Kozlovka': 1906,
    'Krasnolesnyy': 1906,
    'Latnaya': 1906,
    'Liski': 1906,
    'Liskinskiy Rayon': 1906,
    'Losevo': 1906,
    'Maslovka': 1906,
    'Mitrofanovka': 1906,
    'Nizhnedevitsk': 1906,
    'Nizhnedevitskiy Rayon': 1906,
    'Nizhniy Kislyay': 1906,
    'Nizhniy Mamon': 1906,
    'Novaya Chigla': 1906,
    'Novaya Usman’': 1906,
    'Novokhopërsk': 1906,
    'Novokhoperskiy Rayon': 1906,
    'Novousmanskiy Rayon': 1906,
    'Novovoronezh': 1906,
    'Novozhivotinnoye': 1906,
    'Ol’khovatka': 1906,
    'Ol’khovatskiy Rayon': 1906,
    'Orlovo': 1906,
    'Ostrogozhsk': 1906,
    'Panino': 1906,
    'Paninskiy Rayon': 1906,
    'Pavlovsk': 1906,
    'Pavlovskiy Rayon': 1906,
    'Pereleshino': 1906,
    'Pereleshinskiy': 1906,
    'Podgorenskiy': 1906,
    'Podgorenskiy Rayon': 1906,
    'Podgornoye': 1906,
    'Podkletnoye': 1906,
    'Povorino': 1906,
    'Povorinskiy Rayon': 1906,
    'Pridonskoy': 1906,
    'Ramon’': 1906,
    'Ramonskiy Rayon': 1906,
    'Rep’yevskiy Rayon': 1906,
    'Rossosh’': 1906,
    'Rossoshanskiy Rayon': 1906,
    'Sadovoye': 1906,
    'Semiluki': 1906,
    'Semilukskiy Rayon': 1906,
    'Shilovo': 1906,
    'Sloboda': 1906,
    'Somovo': 1906,
    'Sredniy Ikorets': 1906,
    'Strelitsa': 1906,
    'Talovaya': 1906,
    'Ternovka': 1906,
    'Ternovskiy Rayon': 1906,
    'Uglyanets': 1906,
    'Uryv-Pokrovka': 1906,
    'Verkhniy Mamon': 1906,
    'Verkhnyaya Khava': 1906,
    'Verkhnyaya Tishanka': 1906,
    'Volya': 1906,
    'Vorob’yevskiy Rayon': 1906,
    'Voronezh': 1906,
    'Vorontsovka': 1906,
    'Yelan’-Koleno': 1906,
    'Yelan’-Kolenovskiy': 1906,
    'Zabolotovka': 1906,
    'Zemlyansk': 1906,
    'Aksarka': 1847,
    'Gubkinskiy': 1847,
    'Kharp': 1847,
    'Korotchaevo': 1847,
    'Labytnangi': 1847,
    'Muravlenko': 1847,
    'Muzhi': 1847,
    'Mys-Kamennyy': 1847,
    'Nadym': 1847,
    'Nadymskiy Rayon': 1847,
    'Novyy Urengoy': 1847,
    'Noyabrsk': 1847,
    'Nyda': 1847,
    'Pangody': 1847,
    'Priural’skiy Rayon': 1847,
    'Purovskiy Rayon': 1847,
    'Purpe': 1847,
    'Salekhard': 1847,
    'Shuryshkarskiy Rayon': 1847,
    'Staryy Nadym': 1847,
    'Tarko-Sale': 1847,
    'Tazovskiy': 1847,
    'Urengoy': 1847,
    'Yar-Sale': 1847,
    'Berendeyevo': 1851,
    'Bol’shesel’skiy Rayon': 1851,
    'Bol’shoye Selo': 1851,
    'Borisoglebskiy': 1851,
    'Borisoglebskiy Rayon': 1851,
    'Borok': 1851,
    'Breytovo': 1851,
    'Breytovskiy Rayon': 1851,
    'Burmakino': 1851,
    'Danilov': 1851,
    'Danilovskiy Rayon': 1851,
    'Dubki': 1851,
    'Gavrilov-Yam': 1851,
    'Gavrilov-Yamskiy Rayon': 1851,
    'Ishnya': 1851,
    'Konstantinovskiy': 1851,
    'Krasnyy Profintern': 1851,
    'Krasnyye Tkachi': 1851,
    'Kukoboy': 1851,
    'Kurba': 1851,
    'Levashevo': 1851,
    'Lyubim': 1851,
    'Lyubimskiy Rayon': 1851,
    'Myshkin': 1851,
    'Myshkinskiy Rayon': 1851,
    'Nekouzskiy Rayon': 1851,
    'Nekrasovskiy Rayon': 1851,
    'Nekrasovskoye': 1851,
    'Novyy Nekouz': 1851,
    'Pereslavl’-Zalesskiy': 1851,
    'Pereslavskiy Rayon': 1851,
    'Pervomayskiy Rayon': 1851,
    'Pesochnoye': 1851,
    'Petrovsk': 1851,
    'Porech’ye-Rybnoye': 1851,
    'Poshekhon’ye': 1851,
    'Poshekhonskiy Rayon': 1851,
    'Prechistoye': 1851,
    'Rostov': 1851,
    'Rostovskiy Rayon': 1851,
    'Rybinsk': 1851,
    'Rybinskiy Rayon': 1851,
    'Semibratovo': 1851,
    'Sudoverf’': 1851,
    'Tunoshna': 1851,
    'Tutayev': 1851,
    'Tutayevskiy Rayon': 1851,
    'Uglich': 1851,
    'Uglichskiy Rayon': 1851,
    'Volga': 1851,
    'Yaroslavl': 1851,
    'Yaroslavskiy Rayon': 1851,
    'Aginskoye': 1904,
    'Aksha': 1904,
    'Akshinskiy Rayon': 1904,
    'Aleksandrovo-Zavodskiy Rayon': 1904,
    'Aleksandrovskiy Zavod': 1904,
    'Amazar': 1904,
    'Atamanovka': 1904,
    'Bada': 1904,
    'Baley': 1904,
    'Baleyskiy Rayon': 1904,
    'Balyaga': 1904,
    'Bol’shaya Tura': 1904,
    'Borzinskiy Rayon': 1904,
    'Borzya': 1904,
    'Bukachacha': 1904,
    'Chara': 1904,
    'Chernyshevsk': 1904,
    'Chita': 1904,
    'Chitinskiy Rayon': 1904,
    'Darasun': 1904,
    'Dauriya': 1904,
    'Domna': 1904,
    'Drovyanaya': 1904,
    'Dul’durga': 1904,
    'Gazimuro-Zavodskiy Rayon': 1904,
    'Gazimurskiy Zavod': 1904,
    'Kadaya': 1904,
    'Kalanguy': 1904,
    'Kalga': 1904,
    'Karymskoye': 1904,
    'Khilok': 1904,
    'Kholbon': 1904,
    'Klichka': 1904,
    'Klyuchevskiy': 1904,
    'Kokuy': 1904,
    'Krasnokamensk': 1904,
    'Krasnyy Chikoy': 1904,
    'Kuanda': 1904,
    'Kurort-Darasun': 1904,
    'Kyra': 1904,
    'Makkaveyevo': 1904,
    'Mogocha': 1904,
    'Mogoytuy': 1904,
    'Mogzon': 1904,
    'Nerchinsk': 1904,
    'Nerchinskiy Zavod': 1904,
    'Nerchinsko-Zavodskiy Rayon': 1904,
    'Nizhniy Tsasuchey': 1904,
    'Novaya Chara': 1904,
    'Novokruchininskiy': 1904,
    'Novoorlovsk': 1904,
    'Novopavlovka': 1904,
    'Olovyannaya': 1904,
    'Olovyanninskiy Rayon': 1904,
    'Orlovskiy': 1904,
    'Pervomayskiy': 1904,
    'Petrovsk-Zabaykal’skiy': 1904,
    'Priargunsk': 1904,
    'Priargunskiy Rayon': 1904,
    'Priiskovyy': 1904,
    'Shelopugino': 1904,
    'Shilka': 1904,
    'Shilkinskiy Rayon': 1904,
    'Smolenka': 1904,
    'Sretensk': 1904,
    'Sretenskiy Rayon': 1904,
    'Tarbagatay': 1904,
    'Uletovskiy Rayon': 1904,
    'Ulety': 1904,
    'Ust’-Karsk': 1904,
    'Verkh-Usugli': 1904,
    'Vershino-Darasunskiy': 1904,
    'Vershino-Shakhtaminskiy': 1904,
    'Zabaykal’sk': 1904,
    'Zhireken': 1904,
    'Kibungo': 261,
    'Rwamagana': 261,
    'Kigali': 262,
    'Byumba': 263,
    'Musanze': 263,
    'Butare': 259,
    'Eglise Catholique, Centrale GIKO': 259,
    'Gitarama': 259,
    'Nzega': 259,
    'Cyangugu': 260,
    'Gisenyi': 260,
    'Kibuye': 260,
    'Nicola Town': 3833,
    'Sandy Point Town': 3836,
    'Market Shop': 3837,
    'Newcastle': 3835,
    'Dieppe Bay Town': 3845,
    'Fig Tree': 3840,
    'Cayon': 3844,
    'Saint Paul’s': 3834,
    'Charlestown': 3838,
    'Monkey Hill': 3831,
    'Cotton Ground': 3839,
    'Middle Island': 3842,
    'Trinity': 3843,
    'Anse La Raye': 3757,
    'Au Tabor': 3757,
    'Au Tabor Hill': 3757,
    'Bois D  Inde': 3757,
    'Caico/Millet': 3757,
    'Canaries': 3757,
    'Champen Estate': 3757,
    'Derriere Lagoon': 3757,
    'Durandeau': 3757,
    'Enbar Pwin': 3757,
    'Jacmel': 3757,
    'Jean Baptiste': 3757,
    'La Trielle': 3757,
    'Massacre': 3757,
    'Millet': 3757,
    'Morne Ciseaux': 3757,
    'Morne D  Or': 3757,
    'Roseau Valley': 3757,
    'St Lawrence': 3757,
    'St Lawrence Estate': 3757,
    'Tete Chemin': 3757,
    'Vanard': 3757,
    'Venus': 3757,
    'Village': 3757,
    'Village/Petite Bourgh': 3757,
    'Anse Cochon': 3761,
    'Anse Galet': 3761,
    'Anse La Verdue': 3761,
    'Belvedere': 3761,
    'Chalon': 3761,
    'Riverside Road': 3761,
    'Theodrine': 3761,
    'Village': 3761,
    'Active Hill': 3758,
    'Agard Lands/Morne Dudon': 3758,
    'Almondale': 3758,
    'Aurendel Hill': 3758,
    'Babonneau Proper': 3758,
    'Bagatelle': 3758,
    'Balata': 3758,
    'Banannes Bay': 3758,
    'Barnard Hill': 3758,
    'Barre Denis': 3758,
    'Barre Duchaussee': 3758,
    'Barre St.Joseph': 3758,
    'Belair': 3758,
    'Bella Rosa': 3758,
    'Bexon': 3758,
    'Bisee': 3758,
    'Bishop  S Gap': 3758,
    'Bissee': 3758,
    'Black Mallet': 3758,
    'Bocage': 3758,
    'Bois Catchet': 3758,
    'Bois Patat': 3758,
    'Cabiche/Babonneau': 3758,
    'Cacoa/Babonneau': 3758,
    'Calvary': 3758,
    'Capital Hill': 3758,
    'Carellie': 3758,
    'Castries': 3758,
    'Cedars': 3758,
    'Chase Gardens': 3758,
    'Chassin/Babonneau': 3758,
    'Choppin Ridge/Sarot': 3758,
    'Ciceron': 3758,
    'City': 3758,
    'City Gate/La Clery': 3758,
    'Conway/Waterfront': 3758,
    'Coolie Town': 3758,
    'Coubaril': 3758,
    'Crownlands/Marc': 3758,
    'Cul De Sac': 3758,
    'Darling Road': 3758,
    'Deglos': 3758,
    'Derierre Fort/Old Victoria Road': 3758,
    'Dubrassay': 3758,
    'En Pois Doux/Babonneau': 3758,
    'Entrepot': 3758,
    'Faux A Chaud': 3758,
    'Ferrand': 3758,
    'Floissac/Marc': 3758,
    'Fond Assau/Babonneau': 3758,
    'Fond Canie': 3758,
    'Forestiere': 3758,
    'George Charles Boulevard': 3758,
    'Girard': 3758,
    'Goergeville': 3758,
    'Goodlands': 3758,
    'Grass Street': 3758,
    'Green Gold': 3758,
    'Guesneau': 3758,
    'Hill 20/Babonneau': 3758,
    'Hillcrest Gardens': 3758,
    'Hospital Road': 3758,
    'Independence City': 3758,
    'John Compton Highway': 3758,
    'L  Anse Road': 3758,
    'La Carierre': 3758,
    'La Clery': 3758,
    'La Croix Maingot': 3758,
    'La Pansee': 3758,
    'La Toc': 3758,
    'Labayee': 3758,
    'Lastic Hill': 3758,
    'Leslie Land': 3758,
    'Marc': 3758,
    'Marchand': 3758,
    'Marigot': 3758,
    'Maynard Hill': 3758,
    'Miami/Bexon': 3758,
    'Monkey Town Ciceron': 3758,
    'Morne Assau/Babonneau': 3758,
    'Morne Dudon': 3758,
    'Morne Road': 3758,
    'Morne Rouge/Marc': 3758,
    'Mount Pleasant': 3758,
    'New Village': 3758,
    'Odsan': 3758,
    'Parker  S Hill': 3758,
    'Patterson  S Gap': 3758,
    'Pavee': 3758,
    'Peart  S Gap': 3758,
    'Perou': 3758,
    'Pointe Seraphine': 3758,
    'Quarte Chemins': 3758,
    'Ravine Chabot': 3758,
    'Ravine Poisson': 3758,
    'Ravine Touterelle': 3758,
    'Resinard/Babonneau': 3758,
    'Rock Hall': 3758,
    'Rose Hill': 3758,
    'San Soucis': 3758,
    'Sand De Feu': 3758,
    'Sarot': 3758,
    'Summersdale': 3758,
    'Sunbilt': 3758,
    'Sunny Acres': 3758,
    'Talvern': 3758,
    'Tapion': 3758,
    'The Morne': 3758,
    'Ti Colon': 3758,
    'Ti Rocher': 3758,
    'Trois Piton': 3758,
    'Trou Cochan/Marc': 3758,
    'Trou Florent/Marc': 3758,
    'Trou Rouge': 3758,
    'Vide Bouteille': 3758,
    'Vigie': 3758,
    'Waterworks': 3758,
    'Wilton  S Yard/Grave Yard': 3758,
    'Yorke Hill': 3758,
    'Belle Vue': 3760,
    'Bois Dinde': 3760,
    'Caffiere': 3760,
    'Cedars/Chu Tigre': 3760,
    'Choiseul': 3760,
    'Christian Hill': 3760,
    'Dacretin': 3760,
    'Debreuil': 3760,
    'Delcer': 3760,
    'Derriere Morne': 3760,
    'Dugard': 3760,
    'Dupre': 3760,
    'Esperance': 3760,
    'Franciou': 3760,
    'Industry': 3760,
    'La Fargue': 3760,
    'La Pointe': 3760,
    'Lamaze': 3760,
    'Le Riche': 3760,
    'Mailly Motete': 3760,
    'Martin': 3760,
    'Mongouge': 3760,
    'Monzie': 3760,
    'Morne Jacques': 3760,
    'Morne Sion': 3760,
    'Motete': 3760,
    'New Field/Fiette': 3760,
    'Ponyon': 3760,
    'Raveneau': 3760,
    'Reunion': 3760,
    'River Doree': 3760,
    'Roblot': 3760,
    'Sauzay': 3760,
    'Savannesgeorge/Constitution': 3760,
    'Victoria': 3760,
    'Village': 3760,
    'Anse Canot': 3756,
    'Athens': 3756,
    'Au Leon': 3756,
    'Bara Bara': 3756,
    'Bazile': 3756,
    'Belmont': 3756,
    'Bois Joli': 3756,
    'Bordelais': 3756,
    'Bosquet D  Or': 3756,
    'Delaide': 3756,
    'Dennery': 3756,
    'Dennery By Pass': 3756,
    'Dennery By Pass/Green Mountain': 3756,
    'Dennery By Pass/Rocky Lane': 3756,
    'Dennery By Pass/White Rock Gardens': 3756,
    'Dennery Village': 3756,
    'Derniere Riviere': 3756,
    'Derniere Riviere/Fond Maricient': 3756,
    'Derniere Riviere/Mardi Gras/Morne Caca Cochon': 3756,
    'Derniere Riviere/Morne Panache': 3756,
    'Despinoze': 3756,
    'Dubonnaire': 3756,
    'Gadette': 3756,
    'Grande Ravine': 3756,
    'Grande Riviere': 3756,
    'Grande Riviere/Des Branch': 3756,
    'Grande Riviere/En Leur Morne/Discompere': 3756,
    'Grande Riviere/Funier': 3756,
    'Grande Riviere/Morne Caca Cochon': 3756,
    'La Caye': 3756,
    'La Pelle': 3756,
    'La Pointe': 3756,
    'La Ressource': 3756,
    'Lumiere': 3756,
    'Morne Panache': 3756,
    'Riche Fond': 3756,
    'Riche Fond/La Belle Vie': 3756,
    'Riche Fond/New Village': 3756,
    'St. Joseph Estate': 3756,
    'Thamazo': 3756,
    'Beausejour': 3766,
    'Beausejour/Fostin  S Development': 3766,
    'Beausejour/Ndc': 3766,
    'Bella Rosa': 3766,
    'Belle Vue': 3766,
    'Belle Vue Estate': 3766,
    'Boguis': 3766,
    'Boguis/Desa Blond': 3766,
    'Bois D  Orange': 3766,
    'Bois D  Ornange/Trouya': 3766,
    'Bonneterre': 3766,
    'Bonneterre Gardens': 3766,
    'Cap Estate': 3766,
    'Cap Estate/Becune Point': 3766,
    'Cap Estate/Caribbean Park': 3766,
    'Cap Estate/Golf Park': 3766,
    'Cap Estate/Lower Saline Point': 3766,
    'Cap Estate/Mon Du Cap': 3766,
    'Cap Estate/Ranch Site': 3766,
    'Cap Estate/Saddlec Back': 3766,
    'Cap Estate/Upper Saline Point': 3766,
    'Cas En Bas': 3766,
    'Caye Manje  ': 3766,
    'Corinth': 3766,
    'Corinth Estate': 3766,
    'Corinth/La Bel Lair': 3766,
    'Dauphin': 3766,
    'Des Barras': 3766,
    'Des Barras/Cacolie': 3766,
    'Desrameaux': 3766,
    'Garrand': 3766,
    'Grand Riviere': 3766,
    'Grande Riviere/Assou Canal': 3766,
    'Grande Riviere/Ingle Woods': 3766,
    'Grande Riviere/Morne Serpent': 3766,
    'Grande Riviere/Norbert': 3766,
    'Grande Riviere/Piat': 3766,
    'Grande Riviere/White Rock': 3766,
    'Gros Islet': 3766,
    'Gros Islet Town': 3766,
    'Gros Islet/Edge Water': 3766,
    'L  Hermitage': 3766,
    'La Croix Chabourgh': 3766,
    'La Guerre': 3766,
    'La Guerre/Chicken Back Street': 3766,
    'Marisule': 3766,
    'Marisule/Bon Air': 3766,
    'Marisule/East Winds': 3766,
    'Marisule/La Brellotte': 3766,
    'Marisule/Top Of The World': 3766,
    'Marquis Estate': 3766,
    'Massade': 3766,
    'Monchy': 3766,
    'Monchy/Careffe': 3766,
    'Monchy/Cletus Village': 3766,
    'Monchy/La Borne': 3766,
    'Monchy/La Borne/Sans Souci': 3766,
    'Monchy/La Retraite': 3766,
    'Monchy/Lafeuillee': 3766,
    'Monchy/Lawi Fwen': 3766,
    'Monchy/Malgretoute': 3766,
    'Monchy/Moulin A Vent': 3766,
    'Monchy/Ravine Macock': 3766,
    'Monchy/Riviere Mitan': 3766,
    'Monchy/Ti Dauphin': 3766,
    'Monchy/Vieux Sucreic': 3766,
    'Monchy/Vieux Sucreic/Bois D  Inde': 3766,
    'Monchy/Vieux Sucreic/Careffe': 3766,
    'Mongiraud': 3766,
    'Monier': 3766,
    'Morne Citon': 3766,
    'Paix Bouche': 3766,
    'Pigeon Island': 3766,
    'Plateau': 3766,
    'Reduit': 3766,
    'Reduit Orchard': 3766,
    'Reduit Park': 3766,
    'Rodney Bay': 3766,
    'Rodney Heights': 3766,
    'Union': 3766,
    'Union Terrace': 3766,
    'Union/Ti Morne': 3766,
    'Annus': 3759,
    'Balca': 3759,
    'Balca/En Leur Ba': 3759,
    'Balembouche': 3759,
    'Banse': 3759,
    'Banse La Grace': 3759,
    'Bongalo': 3759,
    'Daban': 3759,
    'Fond Berange': 3759,
    'Gayabois': 3759,
    'Gentil': 3759,
    'Getrine': 3759,
    'Giraud': 3759,
    'H  Erelle': 3759,
    'Kennedy Highway/Laborie Bypass': 3759,
    'La Haut': 3759,
    'La Perle': 3759,
    'Laborie': 3759,
    'Londonderry': 3759,
    'Macdomel': 3759,
    'Morne Gomier': 3759,
    'Morne Le Blanc': 3759,
    'Olibo': 3759,
    'Parc Estate': 3759,
    'Piaye': 3759,
    'Saltibus': 3759,
    'Saphire': 3759,
    'Tete Morne': 3759,
    'Village': 3759,
    'Warwick/Daban': 3759,
    'Anse Ger': 3762,
    'Beauchamp': 3762,
    'Blanchard': 3762,
    'Canelles': 3762,
    'Cazuca': 3762,
    'Chique/Blanchard': 3762,
    'Coolie Town': 3762,
    'Delomel': 3762,
    'Des Blanchard': 3762,
    'Desruisseaux': 3762,
    'Dugard': 3762,
    'Escap': 3762,
    'Fond D  Lor/Dugard': 3762,
    'Fond Estate': 3762,
    'Fond/Desruisseaux': 3762,
    'Gomier': 3762,
    'Gouette': 3762,
    'Honeymoon Beach': 3762,
    'L  Eau Mineau': 3762,
    'La Courville': 3762,
    'La Pointe': 3762,
    'La Tille': 3762,
    'Lezy': 3762,
    'Lombard': 3762,
    'Mahaut': 3762,
    'Malgretoute': 3762,
    'Mamiku': 3762,
    'Micoud': 3762,
    'Mon Repos': 3762,
    'Moreau': 3762,
    'Morne Vient': 3762,
    'Myette Gardens': 3762,
    'New Village': 3762,
    'Paix Bouche': 3762,
    'Palmiste Estate': 3762,
    'Patience': 3762,
    'Planard': 3762,
    'Praslin': 3762,
    'Rameau': 3762,
    'Saint Marie Road': 3762,
    'Saut': 3762,
    'Savannes': 3762,
    'St Helen Estate': 3762,
    'Ti Riviere': 3762,
    'Ti Rocher': 3762,
    'Troumassee': 3762,
    'Village': 3762,
    'Volet': 3762,
    'Anse Chastanet': 3764,
    'Barons Drive/Coin De L  Anse': 3764,
    'Beasejour/Myers Bridge': 3764,
    'Belle Plaine': 3764,
    'Bellefond': 3764,
    'Belvedere': 3764,
    'Bois D  Inde': 3764,
    'Bouton': 3764,
    'Calvary/Calvaire': 3764,
    'Cenac': 3764,
    'Chateau Belair': 3764,
    'Colombette': 3764,
    'Compar': 3764,
    'Cresslands': 3764,
    'Deville': 3764,
    'Diamond/Diamond Estate': 3764,
    'Esperance': 3764,
    'Etangs': 3764,
    'Fond Bernier': 3764,
    'Fond Cacoa': 3764,
    'Fond Doux': 3764,
    'Fond Gens Libre': 3764,
    'Jalousie': 3764,
    'La Haut': 3764,
    'La Pearle': 3764,
    'Lenny Hill': 3764,
    'Malgretoute': 3764,
    'Migny': 3764,
    'Mocha': 3764,
    'Morne Bonin': 3764,
    'Morne La Croix': 3764,
    'Morne Lastic/Desruisseaux': 3764,
    'New Development': 3764,
    'Palmiste': 3764,
    'Plat Pays': 3764,
    'Rabot': 3764,
    'Ravine Claire': 3764,
    'Ruby Estate': 3764,
    'Soufrière': 3764,
    'Soufriere Estate': 3764,
    'St Phillip': 3764,
    'Stonefield': 3764,
    'Sulphur Springs': 3764,
    'Ti Boug': 3764,
    'Ti Delcer': 3764,
    'Toraille': 3764,
    'Town': 3764,
    'Union Vale': 3764,
    'Zenon': 3764,
    'Augier': 3763,
    'Beane Field': 3763,
    'Beausejour': 3763,
    'Belle Vue': 3763,
    'Black Bay': 3763,
    'Bruceville/Shanty Town': 3763,
    'Cacoa': 3763,
    'Cantonement': 3763,
    'Carierre': 3763,
    'Catin': 3763,
    'Cocoa Dan': 3763,
    'Coolie Town': 3763,
    'De Mailly': 3763,
    'Derierre Bois': 3763,
    'Derierre Morne': 3763,
    'Docamel/La Resource': 3763,
    'Eau Piquant/St Urbain': 3763,
    'En Bamboo': 3763,
    'Esperance': 3763,
    'Fond Capeche': 3763,
    'Fond Sabot': 3763,
    'Grace': 3763,
    'Hewanorra Orchard': 3763,
    'Hope Estate': 3763,
    'Industrial Estate': 3763,
    'Joyeux': 3763,
    'La Resource': 3763,
    'La Retraite': 3763,
    'La Tourney/Cedar Heights': 3763,
    'Maganier': 3763,
    'Morne Cayenne': 3763,
    'Morne Vert': 3763,
    'Moule A Chique': 3763,
    'Obrier': 3763,
    'Pierrot': 3763,
    'Plut': 3763,
    'Pomme': 3763,
    'St Jude  S Highway': 3763,
    'Tete Morne/Morne Andrew': 3763,
    'Town': 3763,
    'Vieux Fort': 3763,
    'Vieux Fort/Laborie Highway': 3763,
    'Vige  ': 3763,
    'Westall Group/The Mangue': 3763,
    'Biabou': 3389,
    'Byera Village': 3389,
    'Georgetown': 3389,
    'Port Elizabeth': 3388,
    'Layou': 3386,
    'Chateaubelair': 3387,
    'Kingstown': 3384,
    'Kingstown Park': 3384,
    'Barrouallie': 3385,
    'Fasito‘outa': 4763,
    'Leulumoega': 4763,
    'Nofoali‘i': 4763,
    'Satapuala': 4763,
    'Mulifanua': 4761,
    'Falefa': 4765,
    'Lotofagā': 4765,
    'Lufilufi': 4765,
    'Solosolo': 4765,
    'Matavai': 4771,
    'Safotu': 4771,
    'Gataivai': 4767,
    'Vailoa': 4767,
    'Afega': 4770,
    'Apia': 4770,
    'Malie': 4770,
    'Vailima': 4770,
    'Vaiusu': 4770,
    'Samamea': 4768,
    'Asau': 4766,
    'Acquaviva': 59,
    'Borgo Maggiore': 61,
    'Poggio di Chiesanuova': 60,
    'Domagnano': 64,
    'Faetano': 62,
    'Fiorentino': 66,
    'Monte Giardino': 63,
    'San Marino': 58,
    'Serravalle': 65,
    'Santo António': 270,
    'Cantagalo District': 271,
    'Caué District': 271,
    'São Tomé': 271,
    'Trindade': 271,
    'Abha': 2853,
    'Al Majāridah': 2853,
    'An Nimāş': 2853,
    'Khamis Mushait': 2853,
    'Qal‘at Bīshah': 2853,
    'Sabt Al Alayah': 2853,
    'Tabālah': 2853,
    'Al Bahah': 2859,
    'Al Mindak': 2859,
    'Qurayyat': 2857,
    'Sakakah': 2857,
    'Şuwayr': 2857,
    'Ţubarjal': 2857,
    'Al-`Ula': 2851,
    'Badr Ḩunayn': 2851,
    'Medina': 2851,
    'Sulţānah': 2851,
    'Yanbu': 2851,
    'Adh Dhibiyah': 2861,
    'Al Bukayrīyah': 2861,
    'Al Fuwayliq': 2861,
    'Al Mithnab': 2861,
    'Ar Rass': 2861,
    'Buraydah': 2861,
    'Tanūmah': 2861,
    'Wed Alnkil': 2861,
    'Abqaiq': 2856,
    'Al Awjām': 2856,
    'Al Baţţālīyah': 2856,
    'Al Hufūf': 2856,
    'Al Jafr': 2856,
    'Al Jubayl': 2856,
    'Al Khafjī': 2856,
    'Al Markaz': 2856,
    'Al Mubarraz': 2856,
    'Al Munayzilah': 2856,
    'Al Muţayrifī': 2856,
    'Al Qārah': 2856,
    'Al Qaţīf': 2856,
    'Al Qurayn': 2856,
    'As Saffānīyah': 2856,
    'Aţ Ţaraf': 2856,
    'At Tūbī': 2856,
    'Dammam': 2856,
    'Dhahran': 2856,
    'Hafar Al-Batin': 2856,
    'Julayjilah': 2856,
    'Khobar': 2856,
    'Mulayjah': 2856,
    'Qaisumah': 2856,
    'Raḩīmah': 2856,
    'Şafwá': 2856,
    'Sayhāt': 2856,
    'Tārūt': 2856,
    'Umm as Sāhik': 2856,
    'Ha  il': 2855,
    'Abū ‘Arīsh': 2858,
    'Ad Darb': 2858,
    'Al Jarādīyah': 2858,
    'Farasān': 2858,
    'Jizan': 2858,
    'Mislīyah': 2858,
    'Mizhirah': 2858,
    'Şabyā': 2858,
    'Şāmitah': 2858,
    'Al Hadā': 2850,
    'Al Jumūm': 2850,
    'Al Muwayh': 2850,
    'Ash Shafā': 2850,
    'Ghran': 2850,
    'Jeddah': 2850,
    'Mecca': 2850,
    'Rābigh': 2850,
    'Ta’if': 2850,
    'Turabah': 2850,
    'Najrān': 2860,
    'Arar': 2854,
    'Turaif': 2854,
    'Ad Dawādimī': 2849,
    'Ad Dilam': 2849,
    'Afif': 2849,
    'Ain AlBaraha': 2849,
    'Al Arţāwīyah': 2849,
    'Al Kharj': 2849,
    'As Sulayyil': 2849,
    'Az Zulfī': 2849,
    'Marāt': 2849,
    'Riyadh': 2849,
    'Sājir': 2849,
    'shokhaibٍ': 2849,
    'Tumayr': 2849,
    'Al Wajh': 2852,
    'Duba': 2852,
    'Tabuk': 2852,
    'Umm Lajj': 2852,
    'Dakar': 473,
    'Dakar Department': 473,
    'Guédiawaye Department': 473,
    'Mermoz Boabab': 473,
    'N’diareme limamoulaye': 473,
    'Pikine': 473,
    'Pikine Department': 473,
    'Rufisque Department': 473,
    'Mbacké': 480,
    'Mbaké': 480,
    'Tiébo': 480,
    'Touba': 480,
    'Diofior': 479,
    'Fatick Department': 479,
    'Foundiougne': 479,
    'Guinguinéo': 479,
    'Passi': 479,
    'Pourham': 479,
    'Sokone': 479,
    'Kaffrine': 475,
    'Koungheul': 475,
    'Gandiaye': 483,
    'Kaolack': 483,
    'Ndofane': 483,
    'Nioro du Rip': 483,
    'Département de Salémata': 481,
    'Kédougou': 481,
    'Kédougou Department': 481,
    'Saraya': 481,
    'Kolda': 474,
    'Kolda Department': 474,
    'Marsassoum': 474,
    'Vélingara': 474,
    'Dara': 485,
    'Guéoul': 485,
    'Linguere Department': 485,
    'Louga': 485,
    'Ndibène Dahra': 485,
    'Diawara': 476,
    'Kanel': 476,
    'Matam': 476,
    'Matam Department': 476,
    'Ouro Sogui': 476,
    'Ranérou': 476,
    'Sémé': 476,
    'Waoundé': 476,
    'Goléré': 477,
    'Ndioum': 477,
    'Polel Diaoubé': 477,
    'Richard-Toll': 477,
    'Rosso': 477,
    'Saint-Louis': 477,
    'Goudomp Department': 482,
    'Sédhiou': 482,
    'Tambacounda': 486,
    'Tambacounda Department': 486,
    'Joal-Fadiout': 484,
    'Kayar': 484,
    'Khombole': 484,
    'Mbour': 484,
    'Mékhé': 484,
    'Nguékhokh': 484,
    'Pout': 484,
    'Thiès': 484,
    'Thiès Nones': 484,
    'Tiadiaye': 484,
    'Tivaouane': 484,
    'Warang': 484,
    'Adéane': 478,
    'Bignona': 478,
    'Oussouye': 478,
    'Tionk Essil': 478,
    'Ziguinchor': 478,
    'Aranđelovac': 3716,
    'Arilje': 3716,
    'Badovinci': 3716,
    'Bajina Bašta': 3716,
    'Banovo Polje': 3716,
    'Barič': 3716,
    'Bečmen': 3716,
    'Belgrade': 3716,
    'Belotić': 3716,
    'Biljača': 3716,
    'Bogatić': 3716,
    'Bogosavac': 3716,
    'Boljevci': 3716,
    'Bor': 3716,
    'Brdarica': 3716,
    'Bukor': 3716,
    'Čačak': 3716,
    'Čajetina': 3716,
    'Ćićevac': 3716,
    'Čokešina': 3716,
    'Crna Bara': 3716,
    'Ćuprija': 3716,
    'Dobanovci': 3716,
    'Dobrić': 3716,
    'Donji Dobrić': 3716,
    'Donji Milanovac': 3716,
    'Draginje': 3716,
    'Drenovac': 3716,
    'Dublje': 3716,
    'Duboka': 3716,
    'Ðurići': 3716,
    'Glušci': 3716,
    'Golubac': 3716,
    'Gornja Bukovica': 3716,
    'Gornji Milanovac': 3716,
    'Grabovac': 3716,
    'Jablanica': 3716,
    'Jadranska Lešnica': 3716,
    'Jagodina': 3716,
    'Jarebice': 3716,
    'Jelenča': 3716,
    'Jevremovac': 3716,
    'Joševa': 3716,
    'Kamenica': 3716,
    'Klenje': 3716,
    'Knjazevac': 3716,
    'Kolubara': 3716,
    'Kozjak': 3716,
    'Kragujevac': 3716,
    'Kraljevo': 3716,
    'Krivaja': 3716,
    'Krupanj': 3716,
    'Kruševac': 3716,
    'Lagja e Poshtme': 3716,
    'Lapovo': 3716,
    'Lazarevac': 3716,
    'Leskovac': 3716,
    'Lešnica': 3716,
    'Lipnički Šor': 3716,
    'Lipolist': 3716,
    'Ljig': 3716,
    'Ljubovija': 3716,
    'Lugavčina': 3716,
    'Mačva': 3716,
    'Majdanpek': 3716,
    'Majur': 3716,
    'Mala Moštanica': 3716,
    'Mali Zvornik': 3716,
    'Metković': 3716,
    'Miratovac': 3716,
    'Morava': 3716,
    'Negotin': 3716,
    'Niš': 3716,
    'Nišava': 3716,
    'Novi Pazar': 3716,
    'Novo Selo': 3716,
    'Obrenovac': 3716,
    'Osečina': 3716,
    'Osječenik': 3716,
    'Ostružnica': 3716,
    'Ovča': 3716,
    'Paraćin': 3716,
    'Pčinja': 3716,
    'Petkovica': 3716,
    'Pirot': 3716,
    'Pocerski Pričinović': 3716,
    'Podunavlje District': 3716,
    'Pomoravlje': 3716,
    'Požarevac': 3716,
    'Priboj': 3716,
    'Prijepolje': 3716,
    'Prislonica': 3716,
    'Prnjavor': 3716,
    'Prokuplje': 3716,
    'Radenka': 3716,
    'Radovnica': 3716,
    'Rajince': 3716,
    'Rasina': 3716,
    'Raška': 3716,
    'Ribari': 3716,
    'Rumska': 3716,
    'Rušanj': 3716,
    'Šabac': 3716,
    'Salaš Crnobarski': 3716,
    'Samoljica': 3716,
    'Ševarice': 3716,
    'Ševica': 3716,
    'Sinošević': 3716,
    'Sjenica': 3716,
    'Smederevo': 3716,
    'Smederevska Palanka': 3716,
    'Sokolovica': 3716,
    'Sokolovo Brdo': 3716,
    'Sremčica': 3716,
    'Stepojevac': 3716,
    'Štitar': 3716,
    'Stubline': 3716,
    'Šumadija': 3716,
    'Sumulicë': 3716,
    'Surčin': 3716,
    'Tabanović': 3716,
    'Toplica': 3716,
    'Tršić': 3716,
    'Trstenik': 3716,
    'Turija': 3716,
    'Tutin': 3716,
    'Ugrinovci': 3716,
    'Umka': 3716,
    'Užice': 3716,
    'Uzveće': 3716,
    'Valjevo': 3716,
    'Varna': 3716,
    'Velika Moštanica': 3716,
    'Vladimirci': 3716,
    'Vranić': 3716,
    'Vranje': 3716,
    'Vrnjačka Banja': 3716,
    'Zaječar': 3716,
    'Zemun': 3716,
    'Zlatibor': 3716,
    'Zminjak': 3716,
    'Žujince': 3716,
    'Zvečka': 3716,
    'Adorjan': 3733,
    'Aleksandrovo': 3733,
    'Alibunar': 3733,
    'Apatin': 3733,
    'Aradac': 3733,
    'Bačka Palanka': 3733,
    'Bačka Topola': 3733,
    'Bački Breg': 3733,
    'Bački Petrovac': 3733,
    'Bačko Gradište': 3733,
    'Bačko Petrovo Selo': 3733,
    'Banatska Topola': 3733,
    'Banatski Despotovac': 3733,
    'Banatski Dvor': 3733,
    'Banatski Karlovac': 3733,
    'Banatsko Karađorđevo': 3733,
    'Banatsko Veliko Selo': 3733,
    'Baranda': 3733,
    'Barice': 3733,
    'Bašaid': 3733,
    'Bečej': 3733,
    'Bela Crkva': 3733,
    'Belegiš': 3733,
    'Belo Blato': 3733,
    'Beška': 3733,
    'Bočar': 3733,
    'Bogojevo': 3733,
    'Boka': 3733,
    'Bosut': 3733,
    'Botoš': 3733,
    'Buđanovci': 3733,
    'Čelarevo': 3733,
    'Čenta': 3733,
    'Čestereg': 3733,
    'Čoka': 3733,
    'Čortanovci': 3733,
    'Crepaja': 3733,
    'Čurug': 3733,
    'Debeljača': 3733,
    'Despotovo': 3733,
    'Dobrica': 3733,
    'Doroslovo': 3733,
    'Ðurđevo': 3733,
    'Ečka': 3733,
    'Elemir': 3733,
    'Farkaždin': 3733,
    'Gakovo': 3733,
    'Gardinovci': 3733,
    'Gložan': 3733,
    'Golubinci': 3733,
    'Gornji Breg': 3733,
    'Grabovci': 3733,
    'Gudurica': 3733,
    'Hajdučica': 3733,
    'Hetin': 3733,
    'Hrtkovci': 3733,
    'Idvor': 3733,
    'Ilandža': 3733,
    'Inđija': 3733,
    'Irig': 3733,
    'Izbište': 3733,
    'Janošik': 3733,
    'Jarak': 3733,
    'Jarkovac': 3733,
    'Jaša Tomić': 3733,
    'Jazovo': 3733,
    'Jermenovci': 3733,
    'Kanjiža': 3733,
    'Kikinda': 3733,
    'Kisač': 3733,
    'Klek': 3733,
    'Klenak': 3733,
    'Knićanin': 3733,
    'Kolut': 3733,
    'Konak': 3733,
    'Kovačica': 3733,
    'Kovilj': 3733,
    'Kovin': 3733,
    'Kozjak': 3733,
    'Krajišnik': 3733,
    'Krčedin': 3733,
    'Kulpin': 3733,
    'Kumane': 3733,
    'Kupinovo': 3733,
    'Kupusina': 3733,
    'Kuštilj': 3733,
    'Lazarevo': 3733,
    'Ljukovo': 3733,
    'Lok': 3733,
    'Lokve': 3733,
    'Lukićevo': 3733,
    'Mačvanska Mitrovica': 3733,
    'Maglić': 3733,
    'Margita': 3733,
    'Međa': 3733,
    'Melenci': 3733,
    'Mihajlovo': 3733,
    'Mokrin': 3733,
    'Mol': 3733,
    'Mošorin': 3733,
    'Nakovo': 3733,
    'Neuzina': 3733,
    'Nikinci': 3733,
    'Nikolinci': 3733,
    'Nova Crnja': 3733,
    'Nova Pazova': 3733,
    'Novi Banovci': 3733,
    'Novi Itebej': 3733,
    'Novi Karlovci': 3733,
    'Novi Kneževac': 3733,
    'Novi Kozarci': 3733,
    'Novi Sad': 3733,
    'Novi Slankamen': 3733,
    'Novo Miloševo': 3733,
    'Obrovac': 3733,
    'Opovo': 3733,
    'Orlovat': 3733,
    'Ostojićevo': 3733,
    'Padej': 3733,
    'Padina': 3733,
    'Pančevo': 3733,
    'Pavliš': 3733,
    'Pećinci': 3733,
    'Perlez': 3733,
    'Petrovaradin': 3733,
    'Plandište': 3733,
    'Platičevo': 3733,
    'Prigrevica': 3733,
    'Putinci': 3733,
    'Radenković': 3733,
    'Radojevo': 3733,
    'Ravni Topolovac': 3733,
    'Ravnje': 3733,
    'Ravno Selo': 3733,
    'Riđica': 3733,
    'Ruma': 3733,
    'Rumenka': 3733,
    'Rusko Selo': 3733,
    'Sajan': 3733,
    'Šajkaš': 3733,
    'Sakule': 3733,
    'Salaš Noćajski': 3733,
    'Samoš': 3733,
    'Sanad': 3733,
    'Šašinci': 3733,
    'Sečanj': 3733,
    'Sefkerin': 3733,
    'Seleuš': 3733,
    'Senta': 3733,
    'Šimanovci': 3733,
    'Sombor': 3733,
    'Sonta': 3733,
    'Sremska Mitrovica': 3733,
    'Sremski Karlovci': 3733,
    'Srpska Crnja': 3733,
    'Srpski Itebej': 3733,
    'Stajićevo': 3733,
    'Stanišić': 3733,
    'Stara Pazova': 3733,
    'Stari Banovci': 3733,
    'Stari Lec': 3733,
    'Stepanovićevo': 3733,
    'Subotica': 3733,
    'Surduk': 3733,
    'Sutjeska': 3733,
    'Taraš': 3733,
    'Tiszahegyes': 3733,
    'Titel': 3733,
    'Toba': 3733,
    'Tomaševac': 3733,
    'Torak': 3733,
    'Torda': 3733,
    'Uljma': 3733,
    'Uzdin': 3733,
    'Velika Greda': 3733,
    'Veliki Gaj': 3733,
    'Veliko Središte': 3733,
    'Veternik': 3733,
    'Vilovo': 3733,
    'Višnjićevo': 3733,
    'Vladimirovac': 3733,
    'Vlajkovac': 3733,
    'Vojka': 3733,
    'Vojvoda Stepa': 3733,
    'Vrbas': 3733,
    'Vrdnik': 3733,
    'Vršac': 3733,
    'Žabalj': 3733,
    'Žitište': 3733,
    'Zmajevo': 3733,
    'Zrenjanin': 3733,
    'Anse Boileau': 513,
    'Anse Royale': 502,
    'Beau Vallon': 512,
    'Bel Ombre': 505,
    'Cascade': 517,
    'Victoria': 516,
    'Port Glaud': 507,
    'Takamaka': 509,
    'Barma': 914,
    'Blama': 914,
    'Boajibu': 914,
    'Buedu': 914,
    'Bunumbu': 914,
    'Daru': 914,
    'Giehun': 914,
    'Gorahun': 914,
    'Hangha': 914,
    'Jojoima': 914,
    'Kailahun': 914,
    'Kailahun District': 914,
    'Kayima': 914,
    'Kenema': 914,
    'Kenema District': 914,
    'Koidu': 914,
    'Kono District': 914,
    'Koyima': 914,
    'Manowa': 914,
    'Mobai': 914,
    'Motema': 914,
    'Panguma': 914,
    'Pendembu': 914,
    'Segbwema': 914,
    'Simbakoro': 914,
    'Tefeya': 914,
    'Tombodu': 914,
    'Tombu': 914,
    'Wima': 914,
    'Yengema': 914,
    'Alikalia': 911,
    'Bindi': 911,
    'Binkolo': 911,
    'Bombali District': 911,
    'Bumbuna': 911,
    'Gberia Fotombu': 911,
    'Kabala': 911,
    'Kamakwie': 911,
    'Kambia': 911,
    'Kassiri': 911,
    'Koinadugu District': 911,
    'Konakridee': 911,
    'Kukuna': 911,
    'Loma': 911,
    'Lunsar': 911,
    'Magburaka': 911,
    'Makali': 911,
    'Makeni': 911,
    'Mambolo': 911,
    'Mange': 911,
    'Masaka': 911,
    'Masingbi': 911,
    'Masoyila': 911,
    'Pepel': 911,
    'Rokupr': 911,
    'Sawkta': 911,
    'Seidu': 911,
    'Tintafor': 911,
    'Tonkolili District': 911,
    'Yonibana': 911,
    'Baiima': 912,
    'Baoma': 912,
    'Bo': 912,
    'Bo District': 912,
    'Bomi': 912,
    'Bonthe': 912,
    'Bonthe District': 912,
    'Bumpe': 912,
    'Foindu': 912,
    'Gandorhun': 912,
    'Gbewebu': 912,
    'Koribundu': 912,
    'Largo': 912,
    'Mamboma': 912,
    'Mogbwemo': 912,
    'Moyamba': 912,
    'Moyamba District': 912,
    'Palima': 912,
    'Potoru': 912,
    'Pujehun': 912,
    'Pujehun District': 912,
    'Rotifunk': 912,
    'Serabu': 912,
    'Sumbuya': 912,
    'Tongole': 912,
    'Zimmi': 912,
    'Freetown': 913,
    'Hastings': 913,
    'Kent': 913,
    'Waterloo': 913,
    'Singapore': 4651,
    'Woodlands': 4653,
    'Banská Bystrica': 4352,
    'Banská Štiavnica': 4352,
    'Brezno': 4352,
    'Čierny Balog': 4352,
    'Detva': 4352,
    'Dudince': 4352,
    'Fiľakovo': 4352,
    'Hriňová': 4352,
    'Hrochoť,Slovakia': 4352,
    'Kováčová': 4352,
    'Kremnica': 4352,
    'Krupina': 4352,
    'Lučenec': 4352,
    'Nová Baňa': 4352,
    'Okres Banská Bystrica': 4352,
    'Okres Banská Štiavnica': 4352,
    'Okres Brezno': 4352,
    'Okres Detva': 4352,
    'Okres Krupina': 4352,
    'Okres Lučenec': 4352,
    'Okres Poltár': 4352,
    'Okres Revúca': 4352,
    'Okres Veľký Krtíš': 4352,
    'Okres Žarnovica': 4352,
    'Okres Žiar nad Hronom': 4352,
    'Okres Zvolen': 4352,
    'Poltár': 4352,
    'Revúca': 4352,
    'Rimavská Sobota': 4352,
    'Svätý Anton': 4352,
    'Tisovec': 4352,
    'Veľký Krtíš': 4352,
    'Žarnovica': 4352,
    'Žiar nad Hronom': 4352,
    'Zvolen': 4352,
    'Bratislava': 4356,
    'Bratislava - Vajnory': 4356,
    'Dunajská Lužná': 4356,
    'Ivanka pri Dunaji': 4356,
    'Malacky': 4356,
    'Marianka': 4356,
    'Modra': 4356,
    'Okres Bratislava I': 4356,
    'Okres Bratislava II': 4356,
    'Okres Bratislava III': 4356,
    'Okres Bratislava IV': 4356,
    'Okres Bratislava V': 4356,
    'Okres Malacky': 4356,
    'Okres Pezinok': 4356,
    'Okres Senec': 4356,
    'Pezinok': 4356,
    'Senec': 4356,
    'Stupava': 4356,
    'Svätý Jur': 4356,
    'Vinosady': 4356,
    'Čierna nad Tisou': 4353,
    'Dobšiná': 4353,
    'Gelnica': 4353,
    'Kavečany': 4353,
    'Košice': 4353,
    'Košice I': 4353,
    'Košice II': 4353,
    'Košice III': 4353,
    'Košice IV': 4353,
    'Krompachy': 4353,
    'Medzev': 4353,
    'Michalovce': 4353,
    'Moldava nad Bodvou': 4353,
    'Okres Gelnica': 4353,
    'Okres Kosice-okolie': 4353,
    'Okres Michalovce': 4353,
    'Okres Rožňava': 4353,
    'Okres Sobrance': 4353,
    'Okres Spišská Nová Ves': 4353,
    'Okres Trebišov': 4353,
    'Rožňava': 4353,
    'Sečovce': 4353,
    'Sobrance': 4353,
    'Spišská Nová Ves': 4353,
    'Strážske': 4353,
    'Trebišov': 4353,
    'Vinné': 4353,
    'Žehra': 4353,
    'Hurbanovo': 4357,
    'Kolárovo': 4357,
    'Komárno': 4357,
    'Levice': 4357,
    'Nitra': 4357,
    'Nové Zámky': 4357,
    'Okres Komárno': 4357,
    'Okres Levice': 4357,
    'Okres Nitra': 4357,
    'Okres Nové Zámky': 4357,
    'Okres Šaľa': 4357,
    'Okres Topoľčany': 4357,
    'Okres Zlaté Moravce': 4357,
    'Šahy': 4357,
    'Šaľa': 4357,
    'Štúrovo': 4357,
    'Šurany': 4357,
    'Svodín': 4357,
    'Tlmače': 4357,
    'Topoľčany': 4357,
    'Vráble': 4357,
    'Želiezovce': 4357,
    'Zlaté Moravce': 4357,
    'Bardejov': 4354,
    'Chlmec': 4354,
    'Giraltovce': 4354,
    'Humenné': 4354,
    'Kežmarok': 4354,
    'Levoča': 4354,
    'Lipany': 4354,
    'Ľubica': 4354,
    'Medzilaborce': 4354,
    'Nová Lesná': 4354,
    'Okres Bardejov': 4354,
    'Okres Humenné': 4354,
    'Okres Kežmarok': 4354,
    'Okres Levoča': 4354,
    'Okres Medzilaborce': 4354,
    'Okres Poprad': 4354,
    'Okres Prešov': 4354,
    'Okres Sabinov': 4354,
    'Okres Snina': 4354,
    'Okres Stará Ĺubovňa': 4354,
    'Okres Stropkov': 4354,
    'Okres Svidník': 4354,
    'Okres Vranov nad Topľou': 4354,
    'Podolínec': 4354,
    'Poprad': 4354,
    'Prešov': 4354,
    'Sabinov': 4354,
    'Snina': 4354,
    'Spišská Belá': 4354,
    'Spišské Podhradie': 4354,
    'Stará Ľubovňa': 4354,
    'Štrba': 4354,
    'Stropkov': 4354,
    'Svidník': 4354,
    'Svit': 4354,
    'Vranov nad Topľou': 4354,
    'Vrbov': 4354,
    'Vyšné Ružbachy': 4354,
    'Vysoké Tatry': 4354,
    'Ždiar': 4354,
    'Bánovce nad Bebravou': 4358,
    'Bojnice': 4358,
    'Brezová pod Bradlom': 4358,
    'Čachtice': 4358,
    'Dubnica nad Váhom': 4358,
    'Handlová': 4358,
    'Ilava': 4358,
    'Lehota pod Vtáčnikom': 4358,
    'Myjava': 4358,
    'Nemšová': 4358,
    'Nová Dubnica': 4358,
    'Nováky': 4358,
    'Nové Mesto nad Váhom': 4358,
    'Okres Bánovce nad Bebravou': 4358,
    'Okres Ilava': 4358,
    'Okres Myjava': 4358,
    'Okres Nové Mesto nad Váhom': 4358,
    'Okres Partizánske': 4358,
    'Okres Považská Bystrica': 4358,
    'Okres Prievidza': 4358,
    'Okres Púchov': 4358,
    'Okres Trenčín': 4358,
    'Partizánske': 4358,
    'Považská Bystrica': 4358,
    'Prievidza': 4358,
    'Púchov': 4358,
    'Stará Turá': 4358,
    'Trenčianske Teplice': 4358,
    'Trenčín': 4358,
    'Dunajská Streda': 4355,
    'Gabčíkovo': 4355,
    'Galanta': 4355,
    'Gbely': 4355,
    'Hlohovec': 4355,
    'Holíč': 4355,
    'Leopoldov': 4355,
    'Okres Dunajská Streda': 4355,
    'Okres Galanta': 4355,
    'Okres Hlohovec': 4355,
    'Okres Piešťany': 4355,
    'Okres Senica': 4355,
    'Okres Skalica': 4355,
    'Okres Trnava': 4355,
    'Piešťany': 4355,
    'Šamorín': 4355,
    'Senica': 4355,
    'Skalica': 4355,
    'Sládkovičovo': 4355,
    'Smolenice': 4355,
    'Trnava': 4355,
    'Veľký Meder': 4355,
    'Vrbové': 4355,
    'Bytča': 4359,
    'Čadca': 4359,
    'Dolný Kubín': 4359,
    'Hybe': 4359,
    'Krasňany': 4359,
    'Kysucké Nové Mesto': 4359,
    'Liptovský Hrádok': 4359,
    'Liptovský Mikuláš': 4359,
    'Lúčky': 4359,
    'Martin': 4359,
    'Námestovo': 4359,
    'Nižná': 4359,
    'Okres Bytča': 4359,
    'Okres Čadca': 4359,
    'Okres Dolný Kubín': 4359,
    'Okres Kysucké Nové Mesto': 4359,
    'Okres Liptovský Mikuláš': 4359,
    'Okres Martin': 4359,
    'Okres Namestovo': 4359,
    'Okres Ružomberok': 4359,
    'Okres Turčianske Teplice': 4359,
    'Okres Tvrdošín': 4359,
    'Okres Žilina': 4359,
    'Oravská Lesná': 4359,
    'Oravský Podzámok': 4359,
    'Pribylina': 4359,
    'Rajec': 4359,
    'Ružomberok': 4359,
    'Terchová': 4359,
    'Trstená': 4359,
    'Turčianske Teplice': 4359,
    'Turzovka': 4359,
    'Tvrdošín': 4359,
    'Vrútky': 4359,
    'Žilina': 4359,
    'Ajdovščina': 4183,
    'Cirkulane': 4183,
    'Lokavec': 4183,
    'Ankaran': 4326,
    'Beltinci': 4301,
    'Gančani': 4301,
    'Lipovci': 4301,
    'Zgornje Gorje': 4301,
    'Benedikt': 4166,
    'Bistrica ob Sotli': 4179,
    'Bled': 4202,
    'Kostanjevica na Krki': 4202,
    'Zasip': 4202,
    'Nova Vas': 4278,
    'Bohinjska Bistrica': 4282,
    'Dragomer': 4282,
    'Log pri Brezovici': 4282,
    'Borovnica': 4200,
    'Makole': 4200,
    'Bovec': 4181,
    'Mirna': 4181,
    'Braslovče': 4141,
    'Dobrovo': 4240,
    'Mokronog': 4240,
    'Brežice': 4215,
    'Poljčane': 4215,
    'Brezovica pri Ljubljani': 4165,
    'Notranje Gorice': 4165,
    'Opština Ljubljana-Vič-Rudnik': 4165,
    'Rečica ob Savinji': 4165,
    'Vnanje Gorice': 4165,
    'Cankova': 4147,
    'Cerklje na Gorenjskem': 4310,
    'Cerknica': 4162,
    'Rakek': 4162,
    'Cerkno': 4178,
    'Cerkvenjak': 4176,
    'Celje': 4191,
    'Ljubečna': 4191,
    'Trnovlje pri Celju': 4191,
    'Novo Mesto': 4236,
    'Črenšovci': 4151,
    'Črna na Koroškem': 4232,
    'Črnomelj': 4291,
    'Destrnik': 4304,
    'Divača': 4167,
    'Dobje pri Planini': 4295,
    'Videm': 4216,
    'Dobrna': 4252,
    'Dobrova': 4308,
    'Dobrovnik': 4189,
    'Dol pri Ljubljani': 4173,
    'Dolenjske Toplice': 4281,
    'Dob': 4159,
    'Domžale': 4159,
    'Radomlje': 4159,
    'Vir': 4159,
    'Dornava': 4290,
    'Dravograd': 4345,
    'Spodnji Duplek': 4213,
    'Zgornji Duplek': 4213,
    'Gorenja Vas': 4293,
    'Gorišnica': 4210,
    'Gornja Radgona': 4343,
    'Gornji Grad': 4339,
    'Gornji Petrovci': 4271,
    'Grad': 4217,
    'Grosuplje': 4336,
    'Šmarje-Sap': 4336,
    'Spodnja Hajdina': 4145,
    'Hotinja Vas': 4175,
    'Radizel': 4175,
    'Rogoza': 4175,
    'Spodnje Hoče': 4175,
    'Hodoš': 4327,
    'Horjul': 4193,
    'Dol pri Hrastniku': 4341,
    'Hrastnik': 4341,
    'Kozina': 4321,
    'Idrija': 4152,
    'Spodnja Idrija': 4152,
    'Ig': 4286,
    'Ivančna Gorica': 4305,
    'Šentvid pri Stični': 4305,
    'Izola': 4322,
    'Jagodje': 4322,
    'Hrušica': 4337,
    'Jesenice': 4337,
    'Koroška Bela': 4337,
    'Slovenski Javornik': 4337,
    'Zgornje Jezersko': 4203,
    'Juršinci': 4266,
    'Kamnik': 4180,
    'Mekinje': 4180,
    'Šmarca': 4180,
    'Deskle': 4227,
    'Kanal': 4227,
    'Kidričevo': 4150,
    'Kobarid': 4243,
    'Kobilje': 4325,
    'Kočevje': 4335,
    'Komen': 4315,
    'Komenda': 4283,
    'Moste': 4283,
    'Dekani': 4319,
    'Hrvatini': 4319,
    'Koper': 4319,
    'Pobegi': 4319,
    'Prade': 4319,
    'Spodnje Škofije': 4319,
    'Sv. Anton': 4319,
    'Kostel': 4331,
    'Kozje': 4186,
    'Britof': 4287,
    'Golnik': 4287,
    'Kokrica': 4287,
    'Kranj': 4287,
    'Mlaka pri Kranju': 4287,
    'Zgornje Bitnje': 4287,
    'Kranjska Gora': 4340,
    'Mojstrana': 4340,
    'Križevci pri Ljutomeru': 4238,
    'Zgornja Kungota': 4197,
    'Kuzma': 4211,
    'Laško': 4338,
    'Lenart v Slov. Goricah': 4142,
    'Lendava': 4225,
    'Litija': 4347,
    'Dravlje District': 4270,
    'Jarše District': 4270,
    'Ljubljana': 4270,
    'Opčina Ljubljana-Bežigrad': 4270,
    'Opština Ljubljana-Center': 4270,
    'Opština Ljubljana-Moste-Polje': 4270,
    'Rožnik District': 4270,
    'Šentvid District': 4270,
    'Sostro District': 4270,
    'Trnovo District': 4270,
    'Vič District': 4270,
    'Ljubno ob Savinji': 4294,
    'Ljutomer': 4351,
    'Logatec': 4350,
    'Leskova Dolina': 4174,
    'Hrib-Loški Potok': 4158,
    'Lovrenc na Pohorju': 4156,
    'Luče': 4219,
    'Lukovica pri Domžalah': 4302,
    'Majšperk': 4157,
    'Bresternica': 4242,
    'Kamnica': 4242,
    'Limbuš': 4242,
    'Maribor': 4242,
    'Pekre': 4242,
    'Razvanje': 4242,
    'Markovci': 4244,
    'Medvode': 4349,
    'Opština [historical] Ljubljana-Šiška': 4349,
    'Zgornje Pirniče': 4349,
    'Mengeš': 4348,
    'Preserje pri Radomljah': 4348,
    'Metlika': 4323,
    'Mežica': 4265,
    'Miklavž na Dravskem Polju': 4223,
    'Bilje': 4220,
    'Miren': 4220,
    'Mirna Peč': 4237,
    'Mislinja': 4212,
    'Moravče': 4168,
    'Moravske Toplice': 4218,
    'Mozirje': 4190,
    'Apače': 4318,
    'Ilirska Bistrica': 4344,
    'Krško': 4314,
    'Leskovec pri Krškem': 4314,
    'Senovo': 4314,
    'Lavrica': 4187,
    'Škofljica': 4187,
    'Bakovci': 4313,
    'Černelavci': 4313,
    'Krog': 4313,
    'Murska Sobota': 4313,
    'Rakičan': 4313,
    'Muta': 4208,
    'Naklo': 4177,
    'Nazarje': 4329,
    'Kromberk': 4205,
    'Nova Gorica': 4205,
    'Prvačina': 4205,
    'Šempas': 4205,
    'Solkan': 4205,
    'Odranci': 4320,
    'Oplotnica': 4143,
    'Ormož': 4221,
    'Osilnica': 4199,
    'Pesnica pri Mariboru': 4172,
    'Lucija': 4201,
    'Piran': 4201,
    'Portorož': 4201,
    'Seča': 4201,
    'Pivka': 4184,
    'Podčetrtek': 4146,
    'Podlehnik': 4161,
    'Podvelka': 4234,
    'Polzela': 4272,
    'Postojna': 4330,
    'Prebold': 4188,
    'Preddvor': 4303,
    'Prevalje': 4274,
    'Ptuj': 4228,
    'Puconci': 4288,
    'Fram': 4204,
    'Morje': 4204,
    'Rače': 4204,
    'Radeče': 4195,
    'Radenci': 4292,
    'Radlje ob Dravi': 4275,
    'Lesce': 4231,
    'Radovljica': 4231,
    'Kotlje': 4155,
    'Ravne na Koroškem': 4155,
    'Razkrižje': 4206,
    'Renče': 4253,
    'Volčja Draga': 4253,
    'Ribnica': 4235,
    'Ribnica na Pohorju': 4207,
    'Rogaška Slatina': 4233,
    'Rogašovci': 4264,
    'Rogatec': 4209,
    'Bistrica ob Dravi': 4280,
    'Ruše': 4280,
    'Šalovci': 4222,
    'Selnica ob Dravi': 4230,
    'Semič': 4346,
    'Rožna Dolina': 4317,
    'Šempeter pri Gorici': 4317,
    'Vrtojba': 4317,
    'Hrastje': 4299,
    'Šenčur': 4299,
    'Selnica ob Muri': 4324,
    'Šentilj v Slov. Goricah': 4324,
    'Šentjernej': 4241,
    'Šentjur': 4171,
    'Šentrupert': 4311,
    'Sevnica': 4268,
    'Sežana': 4149,
    'Škocjan': 4170,
    'Škofja Loka': 4316,
    'Sv. Duh': 4316,
    'Legen': 4169,
    'Pameče': 4169,
    'Slovenj Gradec': 4169,
    'Šmartno pri Slovenj Gradcu': 4169,
    'Pragersko': 4332,
    'Slovenska Bistrica': 4332,
    'Zgornja Polskava': 4332,
    'Slovenske Konjice': 4198,
    'Šmarje pri Jelšah': 4285,
    'Šmarjeta': 4289,
    'Šmartno ob Paki': 4296,
    'Šmartno pri Litiji': 4279,
    'Sodražica': 4277,
    'Solčava': 4261,
    'Ravne': 4248,
    'Šoštanj': 4248,
    'Topolšica': 4248,
    'Središče ob Dravi': 4263,
    'Starše': 4259,
    'Štore': 4185,
    'Straža': 4333,
    'Sv. Ana v Slov. Goricah': 4164,
    'Sv. Trojica v Slov. Goricah': 4260,
    'Vitomarci': 4229,
    'Sveti Jurij ob Ščavnici': 4328,
    'Sveti Tomaž': 4273,
    'Tabor': 4194,
    'Tišina': 4312,
    'Tolmin': 4247,
    'Trbovlje': 4246,
    'Trebnje': 4214,
    'Trnovska Vas': 4153,
    'Bistrica pri Tržiču': 4250,
    'Tržič': 4250,
    'Črnuče District': 4334,
    'Trzin': 4334,
    'Turnišče': 4251,
    'Velika Polana': 4267,
    'Velike Lašče': 4144,
    'Veržej': 4257,
    'Videm pri Ptuju': 4300,
    'Vipava': 4196,
    'Vitanje': 4148,
    'Vodice': 4154,
    'Vojnik': 4245,
    'Vransko': 4163,
    'Verd': 4262,
    'Vrhnika': 4262,
    'Vuzenica': 4226,
    'Izlake': 4269,
    'Kisovec': 4269,
    'Zagorje ob Savi': 4269,
    'Gotovlje': 4258,
    'Šempeter v Savinj. Dolini': 4258,
    'Zabukovica': 4258,
    'Žalec': 4258,
    'Zavrč': 4182,
    'Železniki': 4256,
    'Žetale': 4249,
    'Žiri': 4192,
    'Žirovnica': 4276,
    'Zreče': 4342,
    'Žužemberk': 4307,
    'Tulagi': 4784,
    'Honiara': 4785,
    'Buala': 4780,
    'Kirakira': 4782,
    'Auki': 4783,
    'Lata': 4779,
    'Gizo': 4786,
    'Tayeeglow': 917,
    'Waajid': 917,
    'Xuddur': 917,
    'Yeed': 917,
    'Mogadishu': 927,
    'Bandarbeyla': 930,
    'Bargaal': 930,
    'Bereeda': 930,
    'Bosaso': 930,
    'Caluula': 930,
    'Iskushuban': 930,
    'Qandala': 930,
    'Baidoa': 926,
    'Buurhakaba': 926,
    'Ceelbuur': 918,
    'Ceeldheer': 918,
    'Dhuusamarreeb': 918,
    'Baardheere': 928,
    'Garbahaarrey': 928,
    'Luuq': 928,
    'Beledweyne': 915,
    'Buulobarde': 915,
    'Jalalaqsi': 915,
    'Buur Gaabo': 924,
    'Jamaame': 924,
    'Kismayo': 924,
    'Afgooye': 921,
    'Marka': 921,
    'Qoryooley': 921,
    'Wanlaweyn': 921,
    'Dujuuma': 922,
    'Jilib': 922,
    'Saacow': 922,
    'Cadale': 923,
    'Jawhar': 923,
    'Mahaddayweyne': 923,
    'Gaalkacyo': 916,
    'Hobyo': 916,
    'Xarardheere': 916,
    'Eyl': 920,
    'Garoowe': 920,
    'Ceerigaabo': 919,
    'Las Khorey': 919,
    'Burao': 929,
    'Ceek': 929,
    'Oodweyne': 929,
    'Adelaide': 938,
    'Alfred Nzo District Municipality': 938,
    'Alice': 938,
    'Aliwal North': 938,
    'Amathole District Municipality': 938,
    'Bhisho': 938,
    'Buffalo City Metropolitan Municipality': 938,
    'Burgersdorp': 938,
    'Butterworth': 938,
    'Cacadu District Municipality': 938,
    'Chris Hani District Municipality': 938,
    'Cradock': 938,
    'Dordrecht': 938,
    'East London': 938,
    'Elliot': 938,
    'Fort Beaufort': 938,
    'Graaff-Reinet': 938,
    'Grahamstown': 938,
    'Ilinge': 938,
    'Joe Gqabi District Municipality': 938,
    'Kirkwood': 938,
    'Kruisfontein': 938,
    'Lady Frere': 938,
    'Middelburg': 938,
    'Molteno': 938,
    'Mthatha': 938,
    'Nelson Mandela Bay Metropolitan Municipality': 938,
    'OR Tambo District Municipality': 938,
    'Port Alfred': 938,
    'Port Elizabeth': 938,
    'Port Saint John’s': 938,
    'Queensdale': 938,
    'Queenstown': 938,
    'Somerset East': 938,
    'Stutterheim': 938,
    'Uitenhage': 938,
    'Whittlesea': 938,
    'Willowmore': 938,
    'Allanridge': 932,
    'Bethlehem': 932,
    'Bloemfontein': 932,
    'Bothaville': 932,
    'Botshabelo': 932,
    'Brandfort': 932,
    'Clocolan': 932,
    'Deneysville': 932,
    'Fezile Dabi District Municipality': 932,
    'Frankfort': 932,
    'Harrismith': 932,
    'Heilbron': 932,
    'Hennenman': 932,
    'Hoopstad': 932,
    'Koppies': 932,
    'Kroonstad': 932,
    'Kutloanong': 932,
    'Ladybrand': 932,
    'Lejweleputswa District Municipality': 932,
    'Lindley': 932,
    'Mangaung Metropolitan Municipality': 932,
    'Marquard': 932,
    'Parys': 932,
    'Phuthaditjhaba': 932,
    'Reitz': 932,
    'Sasolburg': 932,
    'Senekal': 932,
    'Thaba Nchu': 932,
    'Thabo Mofutsanyana District Municipality': 932,
    'Theunissen': 932,
    'Ventersburg': 932,
    'Viljoenskroon': 932,
    'Villiers': 932,
    'Virginia': 932,
    'Vrede': 932,
    'Vredefort': 932,
    'Welkom': 932,
    'Wesselsbron': 932,
    'Winburg': 932,
    'Xhariep District Municipality': 932,
    'Zastron': 932,
    'Alberton': 936,
    'Benoni': 936,
    'Boksburg': 936,
    'Brakpan': 936,
    'Bronkhorstspruit': 936,
    'Carletonville': 936,
    'Centurion': 936,
    'City of Johannesburg Metropolitan Municipality': 936,
    'City of Tshwane Metropolitan Municipality': 936,
    'Cullinan': 936,
    'Diepsloot': 936,
    'Eastleigh': 936,
    'Eden Glen': 936,
    'Eden Glen Ext 60': 936,
    'Edenvale': 936,
    'Ekangala': 936,
    'Ekurhuleni Metropolitan Municipality': 936,
    'Heidelberg': 936,
    'Johannesburg': 936,
    'Krugersdorp': 936,
    'Mabopane': 936,
    'Midrand': 936,
    'Midstream': 936,
    'Modderfontein': 936,
    'Muldersdriseloop': 936,
    'Nigel': 936,
    'Orange Farm': 936,
    'Pretoria': 936,
    'Randburg': 936,
    'Randfontein': 936,
    'Roodepoort': 936,
    'Sedibeng District Municipality': 936,
    'Soweto': 936,
    'Springs': 936,
    'Tembisa': 936,
    'Vanderbijlpark': 936,
    'Vereeniging': 936,
    'West Rand District Municipality': 936,
    'Westonaria': 936,
    'Amajuba District Municipality': 935,
    'Ballito': 935,
    'Berea': 935,
    'Dundee': 935,
    'Durban': 935,
    'Ekuvukeni': 935,
    'eMkhomazi': 935,
    'Empangeni': 935,
    'Eshowe': 935,
    'eSikhaleni': 935,
    'eThekwini Metropolitan Municipality': 935,
    'Glencoe': 935,
    'Greytown': 935,
    'Hluhluwe': 935,
    'Howick': 935,
    'iLembe District Municipality': 935,
    'Kokstad': 935,
    'KwaDukuza': 935,
    'Margate': 935,
    'Mondlo': 935,
    'Mooirivier': 935,
    'Mpophomeni': 935,
    'Mpumalanga': 935,
    'Mtubatuba': 935,
    'Ndwedwe': 935,
    'Newcastle': 935,
    'Pietermaritzburg': 935,
    'Port Shepstone': 935,
    'Richards Bay': 935,
    'Richmond': 935,
    'Scottburgh': 935,
    'Sisonke District Municipality': 935,
    'Sundumbili': 935,
    'Ugu District Municipality': 935,
    'Ulundi': 935,
    'uMgungundlovu District Municipality': 935,
    'uMkhanyakude District Municipality': 935,
    'uMzinyathi District Municipality': 935,
    'uThukela District Municipality': 935,
    'uThungulu District Municipality': 935,
    'Utrecht': 935,
    'Vryheid': 935,
    'Zululand District Municipality': 935,
    'Bochum': 933,
    'Capricorn District Municipality': 933,
    'Duiwelskloof': 933,
    'Ga-Kgapane': 933,
    'Giyani': 933,
    'Lebowakgomo': 933,
    'Louis Trichardt': 933,
    'Mankoeng': 933,
    'Modimolle': 933,
    'Mokopane': 933,
    'Mopani District Municipality': 933,
    'Musina': 933,
    'Nkowakowa': 933,
    'Phalaborwa': 933,
    'Polokwane': 933,
    'Sekhukhune District Municipality': 933,
    'Thabazimbi': 933,
    'Thohoyandou': 933,
    'Thulamahashi': 933,
    'Tzaneen': 933,
    'Vhembe District Municipality': 933,
    'Warmbaths': 933,
    'Waterberg District Municipality': 933,
    'Balfour': 937,
    'Barberton': 937,
    'Belfast': 937,
    'Bethal': 937,
    'Breyten': 937,
    'Carolina': 937,
    'Delmas': 937,
    'Driefontein': 937,
    'Ehlanzeni District': 937,
    'eMbalenhle': 937,
    'Ermelo': 937,
    'Gert Sibande District Municipality': 937,
    'Hendrina': 937,
    'Komatipoort': 937,
    'Kriel': 937,
    'Lydenburg': 937,
    'Middelburg': 937,
    'Nelspruit': 937,
    'Nkangala District Municipality': 937,
    'Piet Retief': 937,
    'Secunda': 937,
    'Siyabuswa': 937,
    'Standerton': 937,
    'Volksrust': 937,
    'White River': 937,
    'Witbank': 937,
    'Bloemhof': 934,
    'Bojanala Platinum District Municipality': 934,
    'Brits': 934,
    'Christiana': 934,
    'Dr Kenneth Kaunda District Municipality': 934,
    'Dr Ruth Segomotsi Mompati District Municipality': 934,
    'Fochville': 934,
    'Ga-Rankuwa': 934,
    'Jan Kempdorp': 934,
    'Klerksdorp': 934,
    'Koster': 934,
    'Lichtenburg': 934,
    'Mahikeng': 934,
    'Maile': 934,
    'Mmabatho': 934,
    'Ngaka Modiri Molema District Municipality': 934,
    'Orkney': 934,
    'Potchefstroom': 934,
    'Rustenburg': 934,
    'Schweizer-Reneke': 934,
    'Stilfontein': 934,
    'Vryburg': 934,
    'Wolmaransstad': 934,
    'Zeerust': 934,
    'Barkly West': 931,
    'Brandvlei': 931,
    'Calvinia': 931,
    'Carnarvon': 931,
    'Colesberg': 931,
    'Daniëlskuil': 931,
    'De Aar': 931,
    'Frances Baard District Municipality': 931,
    'Fraserburg': 931,
    'John Taolo Gaetsewe District Municipality': 931,
    'Kathu': 931,
    'Kenhardt': 931,
    'Kimberley': 931,
    'Kuruman': 931,
    'Namakwa District Municipality': 931,
    'Noupoort': 931,
    'Orania': 931,
    'Pampierstad': 931,
    'Pixley ka Seme District Municipality': 931,
    'Pofadder': 931,
    'Postmasburg': 931,
    'Prieska': 931,
    'Ritchie': 931,
    'Siyanda District Municipality': 931,
    'Springbok': 931,
    'Upington': 931,
    'Van Wyksvlei': 931,
    'Warrenton': 931,
    'Albertina': 939,
    'Arniston': 939,
    'Atlantis': 939,
    'Beaufort West': 939,
    'Bergvliet': 939,
    'Bredasdorp': 939,
    'Caledon': 939,
    'Calitzdorp': 939,
    'Cape Town': 939,
    'Cape Winelands District Municipality': 939,
    'Central Karoo District Municipality': 939,
    'Ceres': 939,
    'City of Cape Town': 939,
    'Clanwilliam': 939,
    'Claremont': 939,
    'Constantia': 939,
    'De Rust': 939,
    'Eden District Municipality': 939,
    'George': 939,
    'Grabouw': 939,
    'Hardys Memories of Africa': 939,
    'Hermanus': 939,
    'Knysna': 939,
    'Kraaifontein': 939,
    'Ladismith': 939,
    'Lansdowne': 939,
    'Malmesbury': 939,
    'Montagu': 939,
    'Moorreesburg': 939,
    'Mossel Bay': 939,
    'Newlands': 939,
    'Oudtshoorn': 939,
    'Overberg District Municipality': 939,
    'Paarl': 939,
    'Piketberg': 939,
    'Plettenberg Bay': 939,
    'Prince Albert': 939,
    'Retreat': 939,
    'Riversdale': 939,
    'Robertson': 939,
    'Rondebosch': 939,
    'Rosebank': 939,
    'Saldanha': 939,
    'Stellenbosch': 939,
    'Sunset Beach': 939,
    'Swellendam': 939,
    'Vredenburg': 939,
    'Vredendal': 939,
    'Wellington': 939,
    'West Coast District Municipality': 939,
    'Worcester': 939,
    'Zoar': 939,
    'Yirol': 2090,
    'Abla': 1193,
    'Abrucena': 1193,
    'Adamuz': 1193,
    'Adra': 1193,
    'Agrón': 1193,
    'Aguadulce': 1193,
    'Aguilar': 1193,
    'Alájar': 1193,
    'Alameda': 1193,
    'Alamedilla': 1193,
    'Alanís': 1193,
    'Albaida del Aljarafe': 1193,
    'Albánchez': 1193,
    'Albolote': 1193,
    'Albondón': 1193,
    'Albox': 1193,
    'Albuñán': 1193,
    'Albuñol': 1193,
    'Albuñuelas': 1193,
    'Alcalá de Guadaira': 1193,
    'Alcalá de los Gazules': 1193,
    'Alcalá del Río': 1193,
    'Alcalá del Valle': 1193,
    'Alcalá la Real': 1193,
    'Alcaracejos': 1193,
    'Alcaucín': 1193,
    'Alcaudete': 1193,
    'Alcolea': 1193,
    'Alcolea del Río': 1193,
    'Alcóntar': 1193,
    'Alcudia de Monteagud': 1193,
    'Aldeaquemada': 1193,
    'Aldeire': 1193,
    'Alfacar': 1193,
    'Alfarnate': 1193,
    'Alfarnatejo': 1193,
    'Algámitas': 1193,
    'Algar': 1193,
    'Algarinejo': 1193,
    'Algarrobo': 1193,
    'Algatocín': 1193,
    'Algeciras': 1193,
    'Algodonales': 1193,
    'Alhabia': 1193,
    'Alhama de Almería': 1193,
    'Alhama de Granada': 1193,
    'Alhaurín de la Torre': 1193,
    'Alhaurín el Grande': 1193,
    'Alhendín': 1193,
    'Alicún': 1193,
    'Alicún de Ortega': 1193,
    'Aljaraque': 1193,
    'Almáchar': 1193,
    'Almadén de la Plata': 1193,
    'Almargen': 1193,
    'Almedinilla': 1193,
    'Almegíjar': 1193,
    'Almensilla': 1193,
    'Almería': 1193,
    'Almócita': 1193,
    'Almodóvar del Río': 1193,
    'Almogía': 1193,
    'Almonte': 1193,
    'Almuñécar': 1193,
    'Alora': 1193,
    'Alosno': 1193,
    'Alozaina': 1193,
    'Alpandeire': 1193,
    'Alquife': 1193,
    'Alsodux': 1193,
    'Andújar': 1193,
    'Añora': 1193,
    'Antas': 1193,
    'Antequera': 1193,
    'Aracena': 1193,
    'Arboleas': 1193,
    'Árchez': 1193,
    'Archidona': 1193,
    'Arcos de la Frontera': 1193,
    'Ardales': 1193,
    'Arenas': 1193,
    'Arenas del Rey': 1193,
    'Arjona': 1193,
    'Arjonilla': 1193,
    'Armilla': 1193,
    'Armuña de Almanzora': 1193,
    'Aroche': 1193,
    'Arquillos': 1193,
    'Arriate': 1193,
    'Arroyo del Ojanco': 1193,
    'Arroyomolinos de León': 1193,
    'Atajate': 1193,
    'Atarfe': 1193,
    'Ayamonte': 1193,
    'Aznalcázar': 1193,
    'Aznalcóllar': 1193,
    'Badolatosa': 1193,
    'Baena': 1193,
    'Baeza': 1193,
    'Bailén': 1193,
    'Baños de la Encina': 1193,
    'Barbate': 1193,
    'Bayárcal': 1193,
    'Bayarque': 1193,
    'Baza': 1193,
    'Beas': 1193,
    'Beas de Granada': 1193,
    'Beas de Guadix': 1193,
    'Beas de Segura': 1193,
    'Bédar': 1193,
    'Begíjar': 1193,
    'Beires': 1193,
    'Belalcázar': 1193,
    'Bélmez': 1193,
    'Bélmez de la Moraleda': 1193,
    'Benacazón': 1193,
    'Benahadux': 1193,
    'Benahavís': 1193,
    'Benalauría': 1193,
    'Benalmádena': 1193,
    'Benalúa de Guadix': 1193,
    'Benalúa de las Villas': 1193,
    'Benalup-Casas Viejas': 1193,
    'Benamargosa': 1193,
    'Benamaurel': 1193,
    'Benamejí': 1193,
    'Benamocarra': 1193,
    'Benaocaz': 1193,
    'Benaoján': 1193,
    'Benarrabá': 1193,
    'Benatae': 1193,
    'Benitagla': 1193,
    'Benizalón': 1193,
    'Bentarique': 1193,
    'Bérchules': 1193,
    'Berja': 1193,
    'Berrocal': 1193,
    'Bollullos de la Mitación': 1193,
    'Bollullos par del Condado': 1193,
    'Bonares': 1193,
    'Bormujos': 1193,
    'Bornos': 1193,
    'Brenes': 1193,
    'Bubión': 1193,
    'Bujalance': 1193,
    'Burguillos': 1193,
    'Busquístar': 1193,
    'Cabezas Rubias': 1193,
    'Cabra': 1193,
    'Cabra del Santo Cristo': 1193,
    'Cádiar': 1193,
    'Cadiz': 1193,
    'Cájar': 1193,
    'Cala': 1193,
    'Calañas': 1193,
    'Calicasas': 1193,
    'Camas': 1193,
    'Cambil': 1193,
    'Campillo de Arenas': 1193,
    'Campillos': 1193,
    'Campiña': 1193,
    'Campotéjar': 1193,
    'Cañada Rosal': 1193,
    'Cáñar': 1193,
    'Cañaveral de León': 1193,
    'Canena': 1193,
    'Cañete de las Torres': 1193,
    'Cañete la Real': 1193,
    'Caniles': 1193,
    'Canillas de Aceituno': 1193,
    'Canillas de Albaida': 1193,
    'Canjáyar': 1193,
    'Cantillana': 1193,
    'Cantoria': 1193,
    'Capileira': 1193,
    'Carataunas': 1193,
    'Carboneras': 1193,
    'Carboneros': 1193,
    'Carcabuey': 1193,
    'Cardeña': 1193,
    'Carmona': 1193,
    'Carratraca': 1193,
    'Carrión de los Céspedes': 1193,
    'Cartajima': 1193,
    'Cártama': 1193,
    'Cartaya': 1193,
    'Casabermeja': 1193,
    'Casarabonela': 1193,
    'Casares': 1193,
    'Casariche': 1193,
    'Cástaras': 1193,
    'Castell de Ferro': 1193,
    'Castellar de la Frontera': 1193,
    'Castellar de Santisteban': 1193,
    'Castilblanco de los Arroyos': 1193,
    'Castilleja de Guzmán': 1193,
    'Castilleja de la Cuesta': 1193,
    'Castilleja del Campo': 1193,
    'Castilléjar': 1193,
    'Castillo de Locubín': 1193,
    'Castril': 1193,
    'Castro de Filabres': 1193,
    'Castro del Río': 1193,
    'Cazalilla': 1193,
    'Cazalla de la Sierra': 1193,
    'Cazorla': 1193,
    'Cenes de la Vega': 1193,
    'Chauchina': 1193,
    'Chercos': 1193,
    'Chiclana de la Frontera': 1193,
    'Chiclana de Segura': 1193,
    'Chilluévar': 1193,
    'Chimeneas': 1193,
    'Chipiona': 1193,
    'Chirivel': 1193,
    'Chucena': 1193,
    'Churriana de la Vega': 1193,
    'Cijuela': 1193,
    'Cóbdar': 1193,
    'Cogollos de Guadix': 1193,
    'Coín': 1193,
    'Colmenar': 1193,
    'Colomera': 1193,
    'Comares': 1193,
    'Cómpeta': 1193,
    'Conil de la Frontera': 1193,
    'Conquista': 1193,
    'Constantina': 1193,
    'Córdoba': 1193,
    'Coria del Río': 1193,
    'Coripe': 1193,
    'Corteconcepción': 1193,
    'Cortelazor': 1193,
    'Cortes de Baza': 1193,
    'Cortes de la Frontera': 1193,
    'Costacabana': 1193,
    'Cuevas Bajas': 1193,
    'Cuevas de San Marcos': 1193,
    'Cuevas del Almanzora': 1193,
    'Cuevas del Becerro': 1193,
    'Cuevas del Campo': 1193,
    'Cúllar-Vega': 1193,
    'Cumbres Mayores': 1193,
    'Cútar': 1193,
    'Dalías': 1193,
    'Darro': 1193,
    'Dehesas de Guadix': 1193,
    'Deifontes': 1193,
    'Diezma': 1193,
    'Dílar': 1193,
    'Domingo Pérez': 1193,
    'Doña Mencía': 1193,
    'Dos Hermanas': 1193,
    'Dos Torres': 1193,
    'Dúdar': 1193,
    'Dúrcal': 1193,
    'Écija': 1193,
    'El Arahal': 1193,
    'El Bosque': 1193,
    'El Carpio': 1193,
    'El Castillo de las Guardas': 1193,
    'El Cerro de Andévalo': 1193,
    'El Coronil': 1193,
    'El Cuervo': 1193,
    'El Ejido': 1193,
    'El Gastor': 1193,
    'El Puerto de Santa María': 1193,
    'El Rompido': 1193,
    'El Ronquillo': 1193,
    'El Rubio': 1193,
    'El Saucejo': 1193,
    'El Varadero': 1193,
    'El Viso': 1193,
    'El Viso del Alcor': 1193,
    'Encinas Reales': 1193,
    'Encinasola': 1193,
    'Enix': 1193,
    'Escacena del Campo': 1193,
    'Escañuela': 1193,
    'Escúzar': 1193,
    'Espartinas': 1193,
    'Espejo': 1193,
    'Espelúy': 1193,
    'Espera': 1193,
    'Espiel': 1193,
    'Estación de Cártama': 1193,
    'Estepa': 1193,
    'Estepona': 1193,
    'Faraján': 1193,
    'Fernán-Núñez': 1193,
    'Ferreira': 1193,
    'Fiñana': 1193,
    'Fines': 1193,
    'Fondón': 1193,
    'Fonelas': 1193,
    'Frailes': 1193,
    'Freila': 1193,
    'Frigiliana': 1193,
    'Fuengirola': 1193,
    'Fuensanta de Martos': 1193,
    'Fuente de Piedra': 1193,
    'Fuente la Lancha': 1193,
    'Fuente Obejuna': 1193,
    'Fuente Palmera': 1193,
    'Fuente Vaqueros': 1193,
    'Fuente-Tójar': 1193,
    'Fuenteheridos': 1193,
    'Fuentes de Andalucía': 1193,
    'Fuerte del Rey': 1193,
    'Gádor': 1193,
    'Galaroza': 1193,
    'Galera': 1193,
    'Garrucha': 1193,
    'Gaucín': 1193,
    'Gelves': 1193,
    'Genalguacil': 1193,
    'Génave': 1193,
    'Gerena': 1193,
    'Gérgal': 1193,
    'Gibraleón': 1193,
    'Gilena': 1193,
    'Gines': 1193,
    'Gobernador': 1193,
    'Gójar': 1193,
    'Gor': 1193,
    'Granada': 1193,
    'Grazalema': 1193,
    'Guadahortuna': 1193,
    'Guadalcanal': 1193,
    'Guadalcázar': 1193,
    'Guadix': 1193,
    'Gualchos': 1193,
    'Guaro': 1193,
    'Guarromán': 1193,
    'Güéjar-Sierra': 1193,
    'Güevéjar': 1193,
    'Guillena': 1193,
    'Herrera': 1193,
    'Higuera de Arjona': 1193,
    'Higuera de Calatrava': 1193,
    'Higuera de la Sierra': 1193,
    'Hinojales': 1193,
    'Hinojares': 1193,
    'Hinojos': 1193,
    'Hinojosa del Duque': 1193,
    'Hornachuelos': 1193,
    'Huécija': 1193,
    'Huélago': 1193,
    'Huelma': 1193,
    'Huelva': 1193,
    'Huéneja': 1193,
    'Huércal de Almería': 1193,
    'Huércal-Overa': 1193,
    'Huesa': 1193,
    'Huéscar': 1193,
    'Huétor Santillán': 1193,
    'Huétor Vega': 1193,
    'Huétor-Tájar': 1193,
    'Humilladero': 1193,
    'Ibros': 1193,
    'Igualeja': 1193,
    'Illar': 1193,
    'Illora': 1193,
    'Instinción': 1193,
    'Isla Cristina': 1193,
    'Islantilla': 1193,
    'Istán': 1193,
    'Ítrabo': 1193,
    'Iznájar': 1193,
    'Iznalloz': 1193,
    'Iznate': 1193,
    'Iznatoraf': 1193,
    'Jabalquinto': 1193,
    'Jabugo': 1193,
    'Jaén': 1193,
    'Jamilena': 1193,
    'Jayena': 1193,
    'Jerez de la Frontera': 1193,
    'Jérez del Marquesado': 1193,
    'Jimena': 1193,
    'Jimena de la Frontera': 1193,
    'Jimera de Líbar': 1193,
    'Jódar': 1193,
    'Jubrique': 1193,
    'Jun': 1193,
    'Juviles': 1193,
    'Júzcar': 1193,
    'La Algaba': 1193,
    'La Antilla': 1193,
    'La Campana': 1193,
    'La Carlota': 1193,
    'La Carolina': 1193,
    'La Gangosa Vistasol': 1193,
    'La Guardia de Jaén': 1193,
    'La Herradura': 1193,
    'La Iruela': 1193,
    'La Lantejuela': 1193,
    'La Línea de la Concepción': 1193,
    'La Luisiana': 1193,
    'La Mojonera': 1193,
    'La Palma del Condado': 1193,
    'La Puebla de Cazalla': 1193,
    'La Puebla de los Infantes': 1193,
    'La Puebla del Río': 1193,
    'La Puerta de Segura': 1193,
    'La Rambla': 1193,
    'La Rinconada': 1193,
    'La Roda de Andalucía': 1193,
    'La Victoria': 1193,
    'Láchar': 1193,
    'Lanjarón': 1193,
    'Lanteira': 1193,
    'Laroya': 1193,
    'Larva': 1193,
    'Las Cabezas de San Juan': 1193,
    'Las Gabias': 1193,
    'Laujar de Andarax': 1193,
    'Lebrija': 1193,
    'Lentegí': 1193,
    'Lepe': 1193,
    'Linares': 1193,
    'Linares de la Sierra': 1193,
    'Lobras': 1193,
    'Loja': 1193,
    'Lopera': 1193,
    'Lora de Estepa': 1193,
    'Lora del Río': 1193,
    'Los Barrios': 1193,
    'Los Corrales': 1193,
    'Los Molares': 1193,
    'Los Palacios y Villafranca': 1193,
    'Los Villares': 1193,
    'Lubrín': 1193,
    'Lucainena de las Torres': 1193,
    'Lúcar': 1193,
    'Lucena': 1193,
    'Lucena del Puerto': 1193,
    'Lugros': 1193,
    'Lújar': 1193,
    'Lupión': 1193,
    'Luque': 1193,
    'Macael': 1193,
    'Mairena del Alcor': 1193,
    'Mairena del Aljarafe': 1193,
    'Málaga': 1193,
    'Mancha Real': 1193,
    'Manilva': 1193,
    'Manzanilla': 1193,
    'Maracena': 1193,
    'Marbella': 1193,
    'Marchal': 1193,
    'Marchena': 1193,
    'María': 1193,
    'Marinaleda': 1193,
    'Marmolejo': 1193,
    'Martín de la Jara': 1193,
    'Martos': 1193,
    'Mazagón': 1193,
    'Medina Sidonia': 1193,
    'Mengibar': 1193,
    'Mijas': 1193,
    'Moclín': 1193,
    'Moclinejo': 1193,
    'Moguer': 1193,
    'Mojacar': 1193,
    'Mollina': 1193,
    'Molvízar': 1193,
    'Monachil': 1193,
    'Monda': 1193,
    'Montalbán de Córdoba': 1193,
    'Montefrío': 1193,
    'Montejaque': 1193,
    'Montejicar': 1193,
    'Montellano': 1193,
    'Montemayor': 1193,
    'Montilla': 1193,
    'Montillana': 1193,
    'Montizón': 1193,
    'Montoro': 1193,
    'Monturque': 1193,
    'Moraleda de Zafayona': 1193,
    'Moriles': 1193,
    'Morón de la Frontera': 1193,
    'Motril': 1193,
    'Murtas': 1193,
    'Navas de San Juan': 1193,
    'Nerja': 1193,
    'Nerva': 1193,
    'Niebla': 1193,
    'Nigüelas': 1193,
    'Níjar': 1193,
    'Nívar': 1193,
    'Noalejo': 1193,
    'Nueva-Carteya': 1193,
    'Obejo': 1193,
    'Ogíjares': 1193,
    'Ohanes': 1193,
    'Ojén': 1193,
    'Olivares': 1193,
    'Olula de Castro': 1193,
    'Olula del Río': 1193,
    'Olvera': 1193,
    'Orce': 1193,
    'Orcera': 1193,
    'Órgiva': 1193,
    'Oria': 1193,
    'Osuna': 1193,
    'Otívar': 1193,
    'Otura': 1193,
    'Padul': 1193,
    'Padules': 1193,
    'Palenciana': 1193,
    'Palma del Río': 1193,
    'Palomares del Río': 1193,
    'Palos de la Frontera': 1193,
    'Pampaneira': 1193,
    'Paradas': 1193,
    'Parauta': 1193,
    'Partaloa': 1193,
    'Paterna de Rivera': 1193,
    'Paterna del Campo': 1193,
    'Paterna del Río': 1193,
    'Paymogo': 1193,
    'Peal de Becerro': 1193,
    'Pedrera': 1193,
    'Pedro Abad': 1193,
    'Pedro Martínez': 1193,
    'Pedroche': 1193,
    'Pegalajar': 1193,
    'Peligros': 1193,
    'Peñaflor': 1193,
    'Peñarroya-Pueblonuevo': 1193,
    'Periana': 1193,
    'Pilas': 1193,
    'Piñar': 1193,
    'Pinos Genil': 1193,
    'Pinos Puente': 1193,
    'Pizarra': 1193,
    'Polícar': 1193,
    'Polopos': 1193,
    'Porcuna': 1193,
    'Pórtugos': 1193,
    'Posadas': 1193,
    'Pozo Alcón': 1193,
    'Pozoblanco': 1193,
    'Prado del Rey': 1193,
    'Priego de Córdoba': 1193,
    'Province of Córdoba': 1193,
    'Provincia de Cádiz': 1193,
    'Provincia de Granada': 1193,
    'Provincia de Huelva': 1193,
    'Provincia de Jaén': 1193,
    'Provincia de Málaga': 1193,
    'Provincia de Sevilla': 1193,
    'Pruna': 1193,
    'Puebla de Don Fadrique': 1193,
    'Puebla de Guzmán': 1193,
    'Puente de Génave': 1193,
    'Puente-Genil': 1193,
    'Puerto Real': 1193,
    'Puerto Serrano': 1193,
    'Pujerra': 1193,
    'Pulianas': 1193,
    'Pulpí': 1193,
    'Punta Umbría': 1193,
    'Purchena': 1193,
    'Purullena': 1193,
    'Quéntar': 1193,
    'Quesada': 1193,
    'Rágol': 1193,
    'Retamar': 1193,
    'Rincón de la Victoria': 1193,
    'Ríogordo': 1193,
    'Rioja': 1193,
    'Ronda': 1193,
    'Roquetas de Mar': 1193,
    'Rosal de la Frontera': 1193,
    'Rota': 1193,
    'Rubite': 1193,
    'Rus': 1193,
    'Rute': 1193,
    'Sabiote': 1193,
    'Salar': 1193,
    'Salares': 1193,
    'Salobreña': 1193,
    'Salteras': 1193,
    'San Bartolomé de la Torre': 1193,
    'San Enrique de Guadiaro': 1193,
    'San Fernando': 1193,
    'San José': 1193,
    'San José del Valle': 1193,
    'San Juan de Aznalfarache': 1193,
    'San Juan del Puerto': 1193,
    'San Nicolás del Puerto': 1193,
    'San Pedro de Alcántara': 1193,
    'San Roque': 1193,
    'San Sebastián de los Ballesteros': 1193,
    'San Silvestre de Guzmán': 1193,
    'Sanlúcar de Barrameda': 1193,
    'Sanlúcar de Guadiana': 1193,
    'Sanlúcar la Mayor': 1193,
    'Santa Ana la Real': 1193,
    'Santa Bárbara de Casa': 1193,
    'Santa Elena': 1193,
    'Santa Eufemia': 1193,
    'Santa Fe de Mondújar': 1193,
    'Santa Olalla del Cala': 1193,
    'Santaella': 1193,
    'Santafé': 1193,
    'Santiago de Calatrava': 1193,
    'Santiponce': 1193,
    'Santisteban del Puerto': 1193,
    'Santo Tomé': 1193,
    'Sayalonga': 1193,
    'Sedella': 1193,
    'Segura de la Sierra': 1193,
    'Serón': 1193,
    'Setenil de las Bodegas': 1193,
    'Sevilla': 1193,
    'Sierra de Yeguas': 1193,
    'Sierro': 1193,
    'Siles': 1193,
    'Somontín': 1193,
    'Soportújar': 1193,
    'Sorbas': 1193,
    'Sorihuela del Guadalimar': 1193,
    'Sorvilán': 1193,
    'Suflí': 1193,
    'Tabernas': 1193,
    'Tahal': 1193,
    'Tarifa': 1193,
    'Teba': 1193,
    'Terque': 1193,
    'Tíjola': 1193,
    'Tocina': 1193,
    'Tolox': 1193,
    'Tomares': 1193,
    'Torre Alháquime': 1193,
    'Torre del Campo': 1193,
    'Torre del Mar': 1193,
    'Torre-Cardela': 1193,
    'Torreblascopedro': 1193,
    'Torrecampo': 1193,
    'Torredonjimeno': 1193,
    'Torremolinos': 1193,
    'Torrenueva': 1193,
    'Torreperogil': 1193,
    'Torres': 1193,
    'Torrox': 1193,
    'Totalán': 1193,
    'Trebujena': 1193,
    'Trevélez': 1193,
    'Trigueros': 1193,
    'Turre': 1193,
    'Turrillas': 1193,
    'Úbeda': 1193,
    'Ubrique': 1193,
    'Ugíjar': 1193,
    'Uleila del Campo': 1193,
    'Umbrete': 1193,
    'Urrácal': 1193,
    'Utrera': 1193,
    'Valdelarco': 1193,
    'Valdepeñas de Jaén': 1193,
    'Valderrubio': 1193,
    'Valdés': 1193,
    'Valdezorras': 1193,
    'Valencina de la Concepción': 1193,
    'Valenzuela': 1193,
    'Válor': 1193,
    'Valverde del Camino': 1193,
    'Vejer de la Frontera': 1193,
    'Velefique': 1193,
    'Vélez de Benaudalla': 1193,
    'Velez Rubio': 1193,
    'Vélez-Blanco': 1193,
    'Vélez-Málaga': 1193,
    'Ventas de Huelma': 1193,
    'Vera': 1193,
    'Viator': 1193,
    'Vícar': 1193,
    'Vilches': 1193,
    'Villa del Río': 1193,
    'Villablanca': 1193,
    'Villacarrillo': 1193,
    'Villafranca de Córdoba': 1193,
    'Villaharta': 1193,
    'Villalba del Alcor': 1193,
    'Villaluenga del Rosario': 1193,
    'Villamanrique de la Condesa': 1193,
    'Villamartín': 1193,
    'Villanueva de Algaidas': 1193,
    'Villanueva de Córdoba': 1193,
    'Villanueva de la Reina': 1193,
    'Villanueva de las Cruces': 1193,
    'Villanueva de las Torres': 1193,
    'Villanueva de los Castillejos': 1193,
    'Villanueva de San Juan': 1193,
    'Villanueva de Tapia': 1193,
    'Villanueva del Ariscal': 1193,
    'Villanueva del Arzobispo': 1193,
    'Villanueva del Duque': 1193,
    'Villanueva del Río y Minas': 1193,
    'Villanueva del Rosario': 1193,
    'Villanueva del Trabuco': 1193,
    'Villaralto': 1193,
    'Villardompardo': 1193,
    'Villarrasa': 1193,
    'Villarrubia': 1193,
    'Villaverde del Río': 1193,
    'Villaviciosa de Córdoba': 1193,
    'Viñuela': 1193,
    'Víznar': 1193,
    'Yunquera': 1193,
    'Zafarraya': 1193,
    'Zagra': 1193,
    'Zahara': 1193,
    'Zahara de los Atunes': 1193,
    'Zalamea la Real': 1193,
    'Zubia': 1193,
    'Zufre': 1193,
    'Zuheros': 1193,
    'Zújar': 1193,
    'Zurgena': 1193,
    'Ababuj': 1177,
    'Abanto': 1177,
    'Abiego': 1177,
    'Abizanda': 1177,
    'Adahuesca': 1177,
    'Agón': 1177,
    'Aguarón': 1177,
    'Aguatón': 1177,
    'Aguaviva': 1177,
    'Agüero': 1177,
    'Aguilar del Alfambra': 1177,
    'Aguilón': 1177,
    'Aínsa': 1177,
    'Ainzón': 1177,
    'Alacón': 1177,
    'Aladrén': 1177,
    'Alagón': 1177,
    'Alarba': 1177,
    'Alba': 1177,
    'Albalate de Cinca': 1177,
    'Albalate del Arzobispo': 1177,
    'Albalatillo': 1177,
    'Albarracín': 1177,
    'Albelda': 1177,
    'Alberite de San Juan': 1177,
    'Albero Alto': 1177,
    'Albero Bajo': 1177,
    'Alberuela de Tubo': 1177,
    'Albeta': 1177,
    'Alborge': 1177,
    'Alcaine': 1177,
    'Alcalá de Ebro': 1177,
    'Alcalá de Gurrea': 1177,
    'Alcalá de la Selva': 1177,
    'Alcalá de Moncayo': 1177,
    'Alcalá del Obispo': 1177,
    'Alcañiz': 1177,
    'Alcolea de Cinca': 1177,
    'Alconchel de Ariza': 1177,
    'Alcorisa': 1177,
    'Alcubierre': 1177,
    'Aldehuela de Liestos': 1177,
    'Alerre': 1177,
    'Alfajarín': 1177,
    'Alfambra': 1177,
    'Alfamén': 1177,
    'Alfántega': 1177,
    'Alforque': 1177,
    'Alhama de Aragón': 1177,
    'Aliaga': 1177,
    'Allepuz': 1177,
    'Alloza': 1177,
    'Allueva': 1177,
    'Almochuel': 1177,
    'Almohaja': 1177,
    'Almonacid de la Cuba': 1177,
    'Almonacid de la Sierra': 1177,
    'Almozara': 1177,
    'Almudévar': 1177,
    'Almuniente': 1177,
    'Alobras': 1177,
    'Alpartir': 1177,
    'Alpeñés': 1177,
    'Alquézar': 1177,
    'Ambel': 1177,
    'Anadón': 1177,
    'Andorra': 1177,
    'Anento': 1177,
    'Angüés': 1177,
    'Ansó': 1177,
    'Antillón': 1177,
    'Aragüés del Puerto': 1177,
    'Aranda de Moncayo': 1177,
    'Arándiga': 1177,
    'Arcos de las Salinas': 1177,
    'Arenys de Lledó / Arens de Lledó': 1177,
    'Argavieso': 1177,
    'Argente': 1177,
    'Arguis': 1177,
    'Ariño': 1177,
    'Ariza': 1177,
    'Artieda': 1177,
    'Atea': 1177,
    'Ateca': 1177,
    'Ayerbe': 1177,
    'Azara': 1177,
    'Azlor': 1177,
    'Azuara': 1177,
    'Bádenas': 1177,
    'Badules': 1177,
    'Baells': 1177,
    'Báguena': 1177,
    'Bagüés': 1177,
    'Bailo': 1177,
    'Balconchán': 1177,
    'Ballobar': 1177,
    'Banastás': 1177,
    'Bañón': 1177,
    'Barbastro': 1177,
    'Bárboles': 1177,
    'Barbués': 1177,
    'Barbuñales': 1177,
    'Bárcabo': 1177,
    'Bardallur': 1177,
    'Barrachina': 1177,
    'Bea': 1177,
    'Belchite': 1177,
    'Bello': 1177,
    'Belver de Cinca': 1177,
    'Benasque': 1177,
    'Benavarri / Benabarre': 1177,
    'Berbegal': 1177,
    'Berdejo': 1177,
    'Berge': 1177,
    'Bezas': 1177,
    'Biel': 1177,
    'Bielsa': 1177,
    'Bierge': 1177,
    'Bijuesca': 1177,
    'Binaced': 1177,
    'Binéfar': 1177,
    'Biota': 1177,
    'Bisaurri': 1177,
    'Biscarrués': 1177,
    'Bisimbre': 1177,
    'Blancas': 1177,
    'Blesa': 1177,
    'Boltaña': 1177,
    'Bonansa': 1177,
    'Boquiñeni': 1177,
    'Borau': 1177,
    'Bordalba': 1177,
    'Bordón': 1177,
    'Borja': 1177,
    'Botorrita': 1177,
    'Brea de Aragón': 1177,
    'Bronchales': 1177,
    'Broto': 1177,
    'Bubierca': 1177,
    'Bueña': 1177,
    'Bujaraloz': 1177,
    'Bulbuente': 1177,
    'Burbáguena': 1177,
    'Bureta': 1177,
    'Cabañas de Ebro': 1177,
    'Cabolafuente': 1177,
    'Cabra de Mora': 1177,
    'Cadrete': 1177,
    'Calaceite': 1177,
    'Calamocha': 1177,
    'Calanda': 1177,
    'Calatayud': 1177,
    'Calatorao': 1177,
    'Calcena': 1177,
    'Calmarza': 1177,
    'Calomarde': 1177,
    'Camañas': 1177,
    'Camarena de la Sierra': 1177,
    'Camarillas': 1177,
    'Caminreal': 1177,
    'Campillo de Aragón': 1177,
    'Camporrells': 1177,
    'Cañada de Benatanduz': 1177,
    'Cañada Vellida': 1177,
    'Candasnos': 1177,
    'Canfranc': 1177,
    'Cañizar del Olivar': 1177,
    'Cantavieja': 1177,
    'Capdesaso': 1177,
    'Capella': 1177,
    'Carenas': 1177,
    'Cariñena': 1177,
    'Casbas de Huesca': 1177,
    'Cascante del Río': 1177,
    'Caspe': 1177,
    'Castejón de Alarba': 1177,
    'Castejón de las Armas': 1177,
    'Castejón de Monegros': 1177,
    'Castejón de Sos': 1177,
    'Castejón de Tornos': 1177,
    'Castejón de Valdejasa': 1177,
    'Castejón del Puente': 1177,
    'Castel de Cabra': 1177,
    'Castelflorite': 1177,
    'Castellote': 1177,
    'Castelnou': 1177,
    'Castelserás': 1177,
    'Castiello de Jaca': 1177,
    'Castigaleu': 1177,
    'Castiliscar': 1177,
    'Castillazuelo': 1177,
    'Cedrillas': 1177,
    'Cella': 1177,
    'Cervera de la Cañada': 1177,
    'Cerveruela': 1177,
    'Cetina': 1177,
    'Chalamera': 1177,
    'Chía': 1177,
    'Chimillas': 1177,
    'Chiprana': 1177,
    'Chodes': 1177,
    'Cimballa': 1177,
    'Cinco Olivas': 1177,
    'Clarés de Ribota': 1177,
    'Codos': 1177,
    'Colungo': 1177,
    'Contamina': 1177,
    'Corbalán': 1177,
    'Cortes de Aragón': 1177,
    'Cosa': 1177,
    'Cosuenda': 1177,
    'Crivillén': 1177,
    'Cuarte de Huerva': 1177,
    'Cubel': 1177,
    'Cubla': 1177,
    'Cucalón': 1177,
    'Cuevas de Almudén': 1177,
    'Cuevas Labradas': 1177,
    'Daroca': 1177,
    'Delicias': 1177,
    'Echo': 1177,
    'Ejea de los Caballeros': 1177,
    'Ejulve': 1177,
    'El Burgo de Ebro': 1177,
    'El Castellar': 1177,
    'el Torricó / Altorricon': 1177,
    'Embid de Ariza': 1177,
    'Encinacorba': 1177,
    'Épila': 1177,
    'Erla': 1177,
    'Escatrón': 1177,
    'Escorihuela': 1177,
    'Escucha': 1177,
    'Esplús': 1177,
    'Estada': 1177,
    'Estadilla': 1177,
    'Estercuel': 1177,
    'Fabara': 1177,
    'Fago': 1177,
    'Farlete': 1177,
    'Fayón': 1177,
    'Ferreruela de Huerva': 1177,
    'Figueruelas': 1177,
    'Fiscal': 1177,
    'Fombuena': 1177,
    'Fonfría': 1177,
    'Fonz': 1177,
    'Formiche Alto': 1177,
    'Fórnoles': 1177,
    'Fortanete': 1177,
    'Foz-Calanda': 1177,
    'Fraga': 1177,
    'Fréscano': 1177,
    'Frías de Albarracín': 1177,
    'Fuendejalón': 1177,
    'Fuendetodos': 1177,
    'Fuenferrada': 1177,
    'Fuentes Calientes': 1177,
    'Fuentes Claras': 1177,
    'Fuentes de Ebro': 1177,
    'Fuentes de Jiloca': 1177,
    'Fuentes de Rubielos': 1177,
    'Fuentespalda': 1177,
    'Gallocanta': 1177,
    'Gallur': 1177,
    'Galve': 1177,
    'Gargallo': 1177,
    'Gea de Albarracín': 1177,
    'Gelsa': 1177,
    'Gistaín': 1177,
    'Godojos': 1177,
    'Gotor': 1177,
    'Grañén': 1177,
    'Graus': 1177,
    'Griegos': 1177,
    'Grisel': 1177,
    'Grisén': 1177,
    'Guadalaviar': 1177,
    'Gúdar': 1177,
    'Gurrea de Gállego': 1177,
    'Herrera de los Navarros': 1177,
    'Híjar': 1177,
    'Hinojosa de Jarque': 1177,
    'Hoz de Jaca': 1177,
    'Huerto': 1177,
    'Huesa del Común': 1177,
    'Huesca': 1177,
    'Ibdes': 1177,
    'Ibieca': 1177,
    'Igriés': 1177,
    'Ilche': 1177,
    'Illueca': 1177,
    'Isuerre': 1177,
    'Jabaloyas': 1177,
    'Jaca': 1177,
    'Jaraba': 1177,
    'Jarque': 1177,
    'Jarque de la Val': 1177,
    'Jasa': 1177,
    'Jatiel': 1177,
    'Jaulín': 1177,
    'Jorcas': 1177,
    'Josa': 1177,
    'La Almunia de Doña Godina': 1177,
    'La Ginebrosa': 1177,
    'La Iglesuela del Cid': 1177,
    'Labuerda': 1177,
    'Lagata': 1177,
    'Lagueruela': 1177,
    'Laluenga': 1177,
    'Lalueza': 1177,
    'Lanaja': 1177,
    'Langa del Castillo': 1177,
    'Lanzuela': 1177,
    'Laperdiguera': 1177,
    'Lascuarre': 1177,
    'Laspaúles': 1177,
    'Laspuña': 1177,
    'Layana': 1177,
    'Lécera': 1177,
    'Lechón': 1177,
    'Leciñena': 1177,
    'Letux': 1177,
    'Libros': 1177,
    'Lidón': 1177,
    'Linares de Mora': 1177,
    'Litago': 1177,
    'Lituénigo': 1177,
    'Lledó': 1177,
    'Loarre': 1177,
    'Lobera de Onsella': 1177,
    'Longares': 1177,
    'Longás': 1177,
    'Loporzano': 1177,
    'Loscorrales': 1177,
    'Loscos': 1177,
    'Lucena de Jalón': 1177,
    'Luceni': 1177,
    'Luesia': 1177,
    'Luesma': 1177,
    'Lumpiaque': 1177,
    'Luna': 1177,
    'Maella': 1177,
    'Magallón': 1177,
    'Maicas': 1177,
    'Mainar': 1177,
    'Malanquilla': 1177,
    'Maleján': 1177,
    'Mallén': 1177,
    'Malón': 1177,
    'Maluenda': 1177,
    'Manchones': 1177,
    'Manzanera': 1177,
    'Mara': 1177,
    'María de Huerva': 1177,
    'Marracos': 1177,
    'Martín del Río': 1177,
    'Mas de las Matas': 1177,
    'Mazaleón': 1177,
    'Mequinensa / Mequinenza': 1177,
    'Mesones de Isuela': 1177,
    'Mezalocha': 1177,
    'Mezquita de Jarque': 1177,
    'Mianos': 1177,
    'Mirambel': 1177,
    'Molinos': 1177,
    'Monegrillo': 1177,
    'Moneva': 1177,
    'Monforte de Moyuela': 1177,
    'Monreal de Ariza': 1177,
    'Monreal del Campo': 1177,
    'Monroyo': 1177,
    'Montalbán': 1177,
    'Monteagudo del Castillo': 1177,
    'Montecanal': 1177,
    'Monterde': 1177,
    'Monterde de Albarracín': 1177,
    'Montón': 1177,
    'Monzón': 1177,
    'Mora de Rubielos': 1177,
    'Morata de Jalón': 1177,
    'Morata de Jiloca': 1177,
    'Morés': 1177,
    'Moros': 1177,
    'Moscardón': 1177,
    'Mosqueruela': 1177,
    'Moyuela': 1177,
    'Mozota': 1177,
    'Muel': 1177,
    'Munébrega': 1177,
    'Muniesa': 1177,
    'Murero': 1177,
    'Naval': 1177,
    'Navardún': 1177,
    'Nigüella': 1177,
    'Nogueras': 1177,
    'Nogueruelas': 1177,
    'Nombrevilla': 1177,
    'Nonaspe': 1177,
    'Novales': 1177,
    'Novallas': 1177,
    'Novillas': 1177,
    'Nueno': 1177,
    'Nuévalos': 1177,
    'Nuez de Ebro': 1177,
    'Obón': 1177,
    'Odón': 1177,
    'Ojos Negros': 1177,
    'Olba': 1177,
    'Oliete': 1177,
    'Oliver-Valdefierro': 1177,
    'Olvés': 1177,
    'Ontiñena': 1177,
    'Orcajo': 1177,
    'Orera': 1177,
    'Orés': 1177,
    'Orihuela del Tremedal': 1177,
    'Orrios': 1177,
    'Oseja': 1177,
    'Palo': 1177,
    'Palomar de Arroyos': 1177,
    'Pancrudo': 1177,
    'Paniza': 1177,
    'Paracuellos de Jiloca': 1177,
    'Paracuellos de la Ribera': 1177,
    'Pedrola': 1177,
    'Peñalba': 1177,
    'Peracense': 1177,
    'Peralejos': 1177,
    'Perales del Alfambra': 1177,
    'Peraltilla': 1177,
    'Perdiguera': 1177,
    'Pertusa': 1177,
    'Piedratajada': 1177,
    'Pina de Ebro': 1177,
    'Pinseque': 1177,
    'Piracés': 1177,
    'Pitarque': 1177,
    'Plan': 1177,
    'Plasencia de Jalón': 1177,
    'Pleitas': 1177,
    'Plenas': 1177,
    'Plou': 1177,
    'Poleñino': 1177,
    'Pomer': 1177,
    'Pozán de Vero': 1177,
    'Pozondón': 1177,
    'Pozuel de Ariza': 1177,
    'Pozuel del Campo': 1177,
    'Pozuelo de Aragón': 1177,
    'Pradilla de Ebro': 1177,
    'Provincia de Huesca': 1177,
    'Provincia de Teruel': 1177,
    'Provincia de Zaragoza': 1177,
    'Puebla de Albortón': 1177,
    'Puebla de Alfindén': 1177,
    'Puente la Reina de Jaca': 1177,
    'Puértolas': 1177,
    'Puertomingalvo': 1177,
    'Pueyo de Santa Cruz': 1177,
    'Purujosa': 1177,
    'Quicena': 1177,
    'Quinto': 1177,
    'Ráfales': 1177,
    'Remolinos': 1177,
    'Retascón': 1177,
    'Ricla': 1177,
    'Rillo': 1177,
    'Riodeva': 1177,
    'Robres': 1177,
    'Ródenas': 1177,
    'Romanos': 1177,
    'Royuela': 1177,
    'Rubiales': 1177,
    'Rubielos de la Cérida': 1177,
    'Rubielos de Mora': 1177,
    'Ruesca': 1177,
    'Sabiñánigo': 1177,
    'Sádaba': 1177,
    'Sahún': 1177,
    'Salas Altas': 1177,
    'Salas Bajas': 1177,
    'Salcedillo': 1177,
    'Saldón': 1177,
    'Salillas': 1177,
    'Salillas de Jalón': 1177,
    'Sallent de Gállego': 1177,
    'Salvatierra de Esca': 1177,
    'Samper de Calanda': 1177,
    'Samper del Salz': 1177,
    'San Agustín': 1177,
    'San Martín del Río': 1177,
    'San Mateo de Gállego': 1177,
    'Sangarrén': 1177,
    'Santa Cruz de Grío': 1177,
    'Santa Cruz de la Serós': 1177,
    'Santa Cruz de Moncayo': 1177,
    'Santa Cruz de Nogueras': 1177,
    'Santa Eulalia': 1177,
    'Santa Eulalia de Gállego': 1177,
    'Santed': 1177,
    'Sariñena': 1177,
    'Sarrión': 1177,
    'Sástago': 1177,
    'Secastilla': 1177,
    'Sediles': 1177,
    'Segura de los Baños': 1177,
    'Seira': 1177,
    'Senés de Alcubierre': 1177,
    'Seno': 1177,
    'Sesa': 1177,
    'Sestrica': 1177,
    'Sesué': 1177,
    'Sierra de Luna': 1177,
    'Siétamo': 1177,
    'Sigüés': 1177,
    'Singra': 1177,
    'Sisamón': 1177,
    'Sobradiel': 1177,
    'Sos del Rey Católico': 1177,
    'Tabuenca': 1177,
    'Talamantes': 1177,
    'Tamarit de Llitera / Tamarite de Litera': 1177,
    'Tarazona': 1177,
    'Tardienta': 1177,
    'Tauste': 1177,
    'Terrer': 1177,
    'Terriente': 1177,
    'Teruel': 1177,
    'Tierz': 1177,
    'Tobed': 1177,
    'Tormón': 1177,
    'Tornos': 1177,
    'Torralba de Aragón': 1177,
    'Torralba de los Frailes': 1177,
    'Torralba de los Sisones': 1177,
    'Torralba de Ribota': 1177,
    'Torralbilla': 1177,
    'Torre de Arcas': 1177,
    'Torre de las Arcas': 1177,
    'Torre del Compte': 1177,
    'Torre los Negros': 1177,
    'Torrecilla de Alcañiz': 1177,
    'Torrecilla del Rebollar': 1177,
    'Torrehermosa': 1177,
    'Torrelapaja': 1177,
    'Torrellas': 1177,
    'Torres de Albarracín': 1177,
    'Torres de Alcanadre': 1177,
    'Torres de Barbués': 1177,
    'Torres de Berrellén': 1177,
    'Torrevelilla': 1177,
    'Torrijas': 1177,
    'Torrijo de la Cañada': 1177,
    'Torrijo del Campo': 1177,
    'Tosos': 1177,
    'Tramacastiel': 1177,
    'Tramacastilla': 1177,
    'Tramaced': 1177,
    'Trasmoz': 1177,
    'Trasobares': 1177,
    'Tronchón': 1177,
    'Uncastillo': 1177,
    'Undués de Lerda': 1177,
    'Urrea de Jalón': 1177,
    'Urriés': 1177,
    'Used': 1177,
    'Utebo': 1177,
    'Utrillas': 1177,
    'Val de San Martín': 1177,
    'Valacloche': 1177,
    'Valbona': 1177,
    'Valdealgorfa': 1177,
    'Valdecuenca': 1177,
    'Valdehorna': 1177,
    'Valdelinares': 1177,
    'Valdeltormo': 1177,
    'Valderrobres': 1177,
    'Valfarta': 1177,
    'Valjunquera': 1177,
    'Valmadrid': 1177,
    'Valpalmas': 1177,
    'Valtorres': 1177,
    'Veguillas de la Sierra': 1177,
    'Velilla de Ebro': 1177,
    'Velilla de Jiloca': 1177,
    'Vera de Moncayo': 1177,
    'Vierlas': 1177,
    'Villadoz': 1177,
    'Villafeliche': 1177,
    'Villafranca de Ebro': 1177,
    'Villafranca del Campo': 1177,
    'Villahermosa del Campo': 1177,
    'Villalba de Perejil': 1177,
    'Villalengua': 1177,
    'Villamayor de Gállego': 1177,
    'Villanúa': 1177,
    'Villanueva de Gállego': 1177,
    'Villanueva de Jiloca': 1177,
    'Villanueva de Sigena': 1177,
    'Villanueva del Rebollar de la Sierra': 1177,
    'Villar de los Navarros': 1177,
    'Villar del Cobo': 1177,
    'Villar del Salz': 1177,
    'Villarluengo': 1177,
    'Villarquemado': 1177,
    'Villarreal de Huerva': 1177,
    'Villarroya de la Sierra': 1177,
    'Villarroya de los Pinares': 1177,
    'Villarroya del Campo': 1177,
    'Villastar': 1177,
    'Villel': 1177,
    'Visiedo': 1177,
    'Vistabella': 1177,
    'Vivel del Río Martín': 1177,
    'Yebra de Basa': 1177,
    'Yésero': 1177,
    'Zaidín': 1177,
    'Zaragoza': 1177,
    'Zuera': 1177,
    'Alaior': 1174,
    'Alaró': 1174,
    'Alcúdia': 1174,
    'Algaida': 1174,
    'Andratx': 1174,
    'Ariany': 1174,
    'Artà': 1174,
    'Banyalbufar': 1174,
    'Binissalem': 1174,
    'Búger': 1174,
    'Bunyola': 1174,
    'Cala d  Or': 1174,
    'Cala Rajada': 1174,
    'Calonge': 1174,
    'Calvià': 1174,
    'Camp de Mar': 1174,
    'Campanet': 1174,
    'Campos': 1174,
    'Can Pastilla': 1174,
    'Can Picafort': 1174,
    'Capdepera': 1174,
    'Ciutadella': 1174,
    'Coll d  en Rabassa': 1174,
    'Colònia de Sant Jordi': 1174,
    'Consell': 1174,
    'Costitx': 1174,
    'Deià': 1174,
    'El Toro': 1174,
    'Es Castell': 1174,
    'Es Molinar': 1174,
    'Escorca': 1174,
    'Esporles': 1174,
    'Estellencs': 1174,
    'Felanitx': 1174,
    'Ferreries': 1174,
    'Fornalutx': 1174,
    'Ibiza': 1174,
    'Illes Balears': 1174,
    'Inca': 1174,
    'Lloret de Vistalegre': 1174,
    'Lloseta': 1174,
    'Llubí': 1174,
    'Llucmajor': 1174,
    'Magaluf': 1174,
    'Manacor': 1174,
    'Mancor de la Vall': 1174,
    'Maó': 1174,
    'Maria de la Salut': 1174,
    'Marratxí': 1174,
    'Mercadal': 1174,
    'Montuïri': 1174,
    'Muro': 1174,
    'Palma': 1174,
    'Palmanova': 1174,
    'Peguera': 1174,
    'Petra': 1174,
    'Pollença': 1174,
    'Porreres': 1174,
    'Port d  Alcúdia': 1174,
    'Port de Pollença': 1174,
    'Port de Sóller': 1174,
    'Porto Cristo': 1174,
    'Portocolom': 1174,
    'Puigpunyent': 1174,
    's  Arenal': 1174,
    'sa Pobla': 1174,
    'Sant Antoni de Portmany': 1174,
    'Sant Francesc de Formentera': 1174,
    'Sant Joan': 1174,
    'Sant Joan de Labritja': 1174,
    'Sant Josep de sa Talaia': 1174,
    'Sant Llorenç des Cardassar': 1174,
    'Sant Lluís': 1174,
    'Santa Eugènia': 1174,
    'Santa Eulària des Riu': 1174,
    'Santa Margalida': 1174,
    'Santa Ponsa': 1174,
    'Santanyí': 1174,
    'Selva': 1174,
    'Sencelles': 1174,
    'Ses Salines': 1174,
    'Sineu': 1174,
    'Sóller': 1174,
    'Son Ferrer': 1174,
    'Son Servera': 1174,
    'Valldemossa': 1174,
    'Vilafranca de Bonany': 1174,
    'Aduna': 1191,
    'Agurain / Salvatierra': 1191,
    'Aia': 1191,
    'Aizarnazabal': 1191,
    'Albiztur': 1191,
    'Algorta': 1191,
    'Alkiza': 1191,
    'Alonsotegi': 1191,
    'Amezketa': 1191,
    'Amorebieta': 1191,
    'Amoroto': 1191,
    'Amurrio': 1191,
    'Andoain': 1191,
    'Anoeta': 1191,
    'Antzuola': 1191,
    'Araba / Álava': 1191,
    'Araia': 1191,
    'Arama': 1191,
    'Aretxabaleta': 1191,
    'Armiñón': 1191,
    'Arrasate / Mondragón': 1191,
    'Arrigorriaga': 1191,
    'Asteasu': 1191,
    'Astigarraga': 1191,
    'Ataun': 1191,
    'Aulesti': 1191,
    'Avellaneda': 1191,
    'Axpe de Busturia': 1191,
    'Azkoitia': 1191,
    'Azpeitia': 1191,
    'Bakio': 1191,
    'Baliarrain': 1191,
    'Barakaldo': 1191,
    'Basauri': 1191,
    'Bastida / Labastida': 1191,
    'Beasain': 1191,
    'Beizama': 1191,
    'Berango': 1191,
    'Berantevilla': 1191,
    'Bergara': 1191,
    'Bermeo': 1191,
    'Berriatua': 1191,
    'Berriz': 1191,
    'Berrobi': 1191,
    'Bilbao': 1191,
    'Bizkaia': 1191,
    'Deba': 1191,
    'Derio': 1191,
    'Donostia / San Sebastián': 1191,
    'Durango': 1191,
    'Ea': 1191,
    'Eibar': 1191,
    'Elciego': 1191,
    'Elexalde': 1191,
    'Elgoibar': 1191,
    'Elorrio': 1191,
    'Erandio': 1191,
    'Ermua': 1191,
    'Errenteria': 1191,
    'Errigoiti': 1191,
    'Eskoriatza': 1191,
    'Eskuernaga / Villabuena de Álava': 1191,
    'Forua': 1191,
    'Galdakao': 1191,
    'Gasteiz / Vitoria': 1191,
    'Gatika': 1191,
    'Gaztelu': 1191,
    'Gernika-Lumo': 1191,
    'Getaria': 1191,
    'Getxo': 1191,
    'Gipuzkoa': 1191,
    'Güeñes': 1191,
    'Hernani': 1191,
    'Hernialde': 1191,
    'Hondarribia': 1191,
    'Ibarrangelu': 1191,
    'Idiazabal': 1191,
    'Irun': 1191,
    'Irura': 1191,
    'Izurtza': 1191,
    'Lagrán': 1191,
    'Laguardia': 1191,
    'Landa': 1191,
    'Lanestosa': 1191,
    'Lapuebla de Labarca': 1191,
    'Larraul': 1191,
    'Lasarte': 1191,
    'Laudio / Llodio': 1191,
    'Leaburu': 1191,
    'Legorreta': 1191,
    'Leintz-Gatzaga': 1191,
    'Leioa': 1191,
    'Lekeitio': 1191,
    'Leza': 1191,
    'Lezama': 1191,
    'Lezo': 1191,
    'Lizartza': 1191,
    'Mañaria': 1191,
    'Markina-Xemein': 1191,
    'Mendaro': 1191,
    'Mendexa': 1191,
    'Moreda Araba / Moreda de Álava': 1191,
    'Mundaka': 1191,
    'Mungia': 1191,
    'Murgia': 1191,
    'Mutiloa': 1191,
    'Mutriku': 1191,
    'Navaridas': 1191,
    'Oion / Oyón': 1191,
    'Olaberria': 1191,
    'Oñati': 1191,
    'Ondarroa': 1191,
    'Ordizia': 1191,
    'Orendain': 1191,
    'Orexa': 1191,
    'Oria': 1191,
    'Orio': 1191,
    'Ormaiztegi': 1191,
    'Ortuella': 1191,
    'Pasaia': 1191,
    'Plentzia': 1191,
    'Portugalete': 1191,
    'Samaniego': 1191,
    'Santurtzi': 1191,
    'Santutxu': 1191,
    'Segura': 1191,
    'Sestao': 1191,
    'Sondika': 1191,
    'Sopela': 1191,
    'Sopuerta': 1191,
    'Tolosa': 1191,
    'Urduña / Orduña': 1191,
    'Urnieta': 1191,
    'Usurbil': 1191,
    'Villabona': 1191,
    'Villanueva de Valdegovía': 1191,
    'Zaldibar': 1191,
    'Zalla': 1191,
    'Zambrana': 1191,
    'Zamudio': 1191,
    'Zaratamo': 1191,
    'Zarautz': 1191,
    'Zeanuri': 1191,
    'Zegama': 1191,
    'Zestoa': 1191,
    'Zierbena': 1191,
    'Zizurkil': 1191,
    'Zumaia': 1191,
    'Zumarraga': 1191,
    'Adeje': 1185,
    'Agaete': 1185,
    'Agüimes': 1185,
    'Agulo': 1185,
    'Alajeró': 1185,
    'Antigua': 1185,
    'Arafo': 1185,
    'Arona': 1185,
    'Arrecife': 1185,
    'Artenara': 1185,
    'Arucas': 1185,
    'Barlovento': 1185,
    'Breña Alta': 1185,
    'Breña Baja': 1185,
    'Buenavista del Norte': 1185,
    'Candelaria': 1185,
    'Carrizal': 1185,
    'Corralejo': 1185,
    'Costa Calma': 1185,
    'Costa Teguise': 1185,
    'Cruce de Arinaga': 1185,
    'El Cotillo': 1185,
    'El Paso': 1185,
    'Fasnia': 1185,
    'Firgas': 1185,
    'Frontera': 1185,
    'Fuencaliente de la Palma': 1185,
    'Gáldar': 1185,
    'Garachico': 1185,
    'Garafía': 1185,
    'Granadilla de Abona': 1185,
    'Guia': 1185,
    'Guía de Isora': 1185,
    'Güimar': 1185,
    'Haría': 1185,
    'Hermigua': 1185,
    'Icod de los Vinos': 1185,
    'Ingenio': 1185,
    'La Guancha': 1185,
    'La Laguna': 1185,
    'La Matanza de Acentejo': 1185,
    'La Oliva': 1185,
    'La Orotava': 1185,
    'La Victoria de Acentejo': 1185,
    'Las Palmas de Gran Canaria': 1185,
    'Las Rosas': 1185,
    'Lomo de Arico': 1185,
    'Los Gigantes': 1185,
    'Los Llanos de Aridane': 1185,
    'Los Realejos': 1185,
    'Los Silos': 1185,
    'Mácher': 1185,
    'Maspalomas': 1185,
    'Mazo': 1185,
    'Mogán': 1185,
    'Moya': 1185,
    'Nazaret': 1185,
    'Pájara': 1185,
    'Playa Blanca': 1185,
    'Playa de las Américas': 1185,
    'Playa del Ingles': 1185,
    'Provincia de Las Palmas': 1185,
    'Provincia de Santa Cruz de Tenerife': 1185,
    'Puerto de la Cruz': 1185,
    'Puerto del Carmen': 1185,
    'Puerto del Rosario': 1185,
    'Puerto Rico': 1185,
    'Punta de Mujeres': 1185,
    'Puntagorda': 1185,
    'Puntallana': 1185,
    'Realejo Alto': 1185,
    'San Bartolomé': 1185,
    'San Bartolomé de Tirajana': 1185,
    'San Isidro': 1185,
    'San Juan de la Rambla': 1185,
    'San Miguel De Abona': 1185,
    'San Nicolás': 1185,
    'San Sebastián de la Gomera': 1185,
    'Santa Brígida': 1185,
    'Santa Cruz de la Palma': 1185,
    'Santa Cruz de Tenerife': 1185,
    'Santa Lucía': 1185,
    'Santa Úrsula': 1185,
    'Santiago del Teide': 1185,
    'Sauzal': 1185,
    'Tacoronte': 1185,
    'Tanque': 1185,
    'Tazacorte': 1185,
    'Tegueste': 1185,
    'Teguise': 1185,
    'Tejeda': 1185,
    'Telde': 1185,
    'Teror': 1185,
    'Tías': 1185,
    'Tijarafe': 1185,
    'Tinajo': 1185,
    'Tuineje': 1185,
    'Vallehermosa': 1185,
    'Valleseco': 1185,
    'Valsequillo de Gran Canaria': 1185,
    'Valverde': 1185,
    'Vecindario': 1185,
    'Vega de San Mateo': 1185,
    'Vilaflor': 1185,
    'Yaiza': 1185,
    'Ampuero': 1170,
    'Arenas de Iguña': 1170,
    'Argoños': 1170,
    'Arnuero': 1170,
    'Arredondo': 1170,
    'Bárcena de Cicero': 1170,
    'Bárcena de Pie de Concha': 1170,
    'Bareyo': 1170,
    'Cabezón de la Sal': 1170,
    'Cabezón de Liébana': 1170,
    'Camargo': 1170,
    'Cartes': 1170,
    'Castro-Urdiales': 1170,
    'Colindres': 1170,
    'Comillas': 1170,
    'El Astillero': 1170,
    'Escalante': 1170,
    'Hazas de Cesto': 1170,
    'Laredo': 1170,
    'Limpias': 1170,
    'Los Corrales de Buelna': 1170,
    'Luzmela': 1170,
    'Mataporquera': 1170,
    'Miengo': 1170,
    'Molledo': 1170,
    'Noja': 1170,
    'Penagos': 1170,
    'Pesquera': 1170,
    'Polanco': 1170,
    'Potes': 1170,
    'Provincia de Cantabria': 1170,
    'Puente Viesgo': 1170,
    'Ramales de la Victoria': 1170,
    'Rasines': 1170,
    'Reinosa': 1170,
    'Reocín': 1170,
    'Ruente': 1170,
    'San Martín de Elines': 1170,
    'San Miguel de Aguayo': 1170,
    'San Miguel de Meruelo': 1170,
    'San Pedro del Romeral': 1170,
    'San Vicente de la Barquera': 1170,
    'Santa Cruz de Bezana': 1170,
    'Santa María de Cayón': 1170,
    'Santander': 1170,
    'Santillana': 1170,
    'Santiurde de Reinosa': 1170,
    'Santiurde de Toranzo': 1170,
    'Santoña': 1170,
    'Saro': 1170,
    'Selaya': 1170,
    'Solórzano': 1170,
    'Suances': 1170,
    'Torrelavega': 1170,
    'Vega de Pas': 1170,
    'Villacarriedo': 1170,
    'Villaescusa': 1170,
    'Villafufre': 1170,
    'Abánades': 1205,
    'Abengibre': 1205,
    'Abenójar': 1205,
    'Ablanque': 1205,
    'Adobes': 1205,
    'Agudo': 1205,
    'Ajofrín': 1205,
    'Alamillo': 1205,
    'Alaminos': 1205,
    'Alarcón': 1205,
    'Alarilla': 1205,
    'Alatoz': 1205,
    'Albacete': 1205,
    'Albaladejo': 1205,
    'Albaladejo del Cuende': 1205,
    'Albalate de las Nogueras': 1205,
    'Albalate de Zorita': 1205,
    'Albares': 1205,
    'Albarreal de Tajo': 1205,
    'Albatana': 1205,
    'Albendea': 1205,
    'Albendiego': 1205,
    'Alborea': 1205,
    'Alcabón': 1205,
    'Alcadozo': 1205,
    'Alcalá de la Vega': 1205,
    'Alcalá del Júcar': 1205,
    'Alcañizo': 1205,
    'Alcantud': 1205,
    'Alcaraz': 1205,
    'Alcázar de San Juan': 1205,
    'Alcázar del Rey': 1205,
    'Alcoba': 1205,
    'Alcocer': 1205,
    'Alcohujate': 1205,
    'Alcolea de Calatrava': 1205,
    'Alcolea de las Peñas': 1205,
    'Alcolea de Tajo': 1205,
    'Alcolea del Pinar': 1205,
    'Alconchel de la Estrella': 1205,
    'Alcoroches': 1205,
    'Alcubillas': 1205,
    'Aldea del Rey': 1205,
    'Aldeanueva de Barbarroya': 1205,
    'Aldeanueva de Guadalajara': 1205,
    'Aldeanueva de San Bartolomé': 1205,
    'Algar de Mesa': 1205,
    'Algarra': 1205,
    'Algora': 1205,
    'Alhambra': 1205,
    'Alhóndiga': 1205,
    'Aliaguilla': 1205,
    'Alique': 1205,
    'Almadén': 1205,
    'Almadrones': 1205,
    'Almagro': 1205,
    'Almansa': 1205,
    'Almedina': 1205,
    'Almendral de la Cañada': 1205,
    'Almodóvar del Campo': 1205,
    'Almodóvar del Pinar': 1205,
    'Almoguera': 1205,
    'Almonacid de Zorita': 1205,
    'Almonacid del Marquesado': 1205,
    'Almorox': 1205,
    'Alocén': 1205,
    'Alovera': 1205,
    'Alpera': 1205,
    'Altarejos': 1205,
    'Alustante': 1205,
    'Anchuras': 1205,
    'Anguita': 1205,
    'Añover de Tajo': 1205,
    'Anquela del Ducado': 1205,
    'Anquela del Pedregal': 1205,
    'Arandilla del Arroyo': 1205,
    'Aranzueque': 1205,
    'Arbancón': 1205,
    'Arbeteta': 1205,
    'Arcicóllar': 1205,
    'Arcos de la Sierra': 1205,
    'Arenas de San Juan': 1205,
    'Argamasilla de Alba': 1205,
    'Argamasilla de Calatrava': 1205,
    'Argecilla': 1205,
    'Arguisuelas': 1205,
    'Armallones': 1205,
    'Armuña de Tajuña': 1205,
    'Arrancacepas': 1205,
    'Arroyo de las Fraguas': 1205,
    'Atalaya del Cañavate': 1205,
    'Atanzón': 1205,
    'Atienza': 1205,
    'Auñón': 1205,
    'Ayna': 1205,
    'Azuqueca de Henares': 1205,
    'Azután': 1205,
    'Baides': 1205,
    'Balazote': 1205,
    'Ballesteros de Calatrava': 1205,
    'Balsa de Ves': 1205,
    'Baños de Tajo': 1205,
    'Bañuelos': 1205,
    'Barajas de Melo': 1205,
    'Barchín del Hoyo': 1205,
    'Barcience': 1205,
    'Bargas': 1205,
    'Barrax': 1205,
    'Barriopedro': 1205,
    'Bascuñana de San Pedro': 1205,
    'Beamud': 1205,
    'Belinchón': 1205,
    'Belmonte': 1205,
    'Belmontejo': 1205,
    'Belvis de la Jara': 1205,
    'Berninches': 1205,
    'Beteta': 1205,
    'Bienservida': 1205,
    'Bogarra': 1205,
    'Bolaños de Calatrava': 1205,
    'Bonete': 1205,
    'Boniches': 1205,
    'Borox': 1205,
    'Brazatortas': 1205,
    'Brihuega': 1205,
    'Buciegas': 1205,
    'Budia': 1205,
    'Buenache de Alarcón': 1205,
    'Buenaventura': 1205,
    'Buendía': 1205,
    'Bujalaro': 1205,
    'Burguillos de Toledo': 1205,
    'Burujón': 1205,
    'Bustares': 1205,
    'Cabañas de la Sagra': 1205,
    'Cabañas de Yepes': 1205,
    'Cabanillas del Campo': 1205,
    'Cabezamesada': 1205,
    'Cabezarados': 1205,
    'Calera y Chozas': 1205,
    'Caleruela': 1205,
    'Camarena': 1205,
    'Camarenilla': 1205,
    'Campillo de Altobuey': 1205,
    'Campillo de Dueñas': 1205,
    'Campillo de Ranas': 1205,
    'Campillos-Paravientos': 1205,
    'Campillos-Sierra': 1205,
    'Campisábalos': 1205,
    'Campo de Criptana': 1205,
    'Camuñas': 1205,
    'Cañada de Calatrava': 1205,
    'Cañada del Hoyo': 1205,
    'Canalejas del Arroyo': 1205,
    'Cañamares': 1205,
    'Cañaveras': 1205,
    'Cañaveruelas': 1205,
    'Cañete': 1205,
    'Cañizar': 1205,
    'Cañizares': 1205,
    'Canredondo': 1205,
    'Cantalojas': 1205,
    'Carboneras de Guadazaón': 1205,
    'Carcelén': 1205,
    'Cardenete': 1205,
    'Cardiel de los Montes': 1205,
    'Carmena': 1205,
    'Carranque': 1205,
    'Carrascosa': 1205,
    'Carrascosa de Haro': 1205,
    'Carriches': 1205,
    'Carrión de Calatrava': 1205,
    'Carrizosa': 1205,
    'Casa de Uceda': 1205,
    'Casarrubios del Monte': 1205,
    'Casas de Benítez': 1205,
    'Casas de Fernando Alonso': 1205,
    'Casas de Garcimolina': 1205,
    'Casas de Guijarro': 1205,
    'Casas de Haro': 1205,
    'Casas de Juan Núñez': 1205,
    'Casas de Lázaro': 1205,
    'Casas de los Pinos': 1205,
    'Casas de San Galindo': 1205,
    'Casas de Ves': 1205,
    'Casas Ibáñez': 1205,
    'Casasbuenas': 1205,
    'Casasimarro': 1205,
    'Caspueñas': 1205,
    'Castejón de Henares': 1205,
    'Castellar de la Muela': 1205,
    'Castellar de Santiago': 1205,
    'Castilforte': 1205,
    'Castillejo de Iniesta': 1205,
    'Castillejo-Sierra': 1205,
    'Castillo de Bayuela': 1205,
    'Castillo de Garcimuñoz': 1205,
    'Castillo-Albaráñez': 1205,
    'Castilnuevo': 1205,
    'Caudete': 1205,
    'Cazalegas': 1205,
    'Cebolla': 1205,
    'Cedillo del Condado': 1205,
    'Cendejas de la Torre': 1205,
    'Cenizate': 1205,
    'Centenera': 1205,
    'Cervera de los Montes': 1205,
    'Cervera del Llano': 1205,
    'Checa': 1205,
    'Chequilla': 1205,
    'Chillarón de Cuenca': 1205,
    'Chillarón del Rey': 1205,
    'Chiloeches': 1205,
    'Chinchilla de Monte Aragón': 1205,
    'Chozas de Canales': 1205,
    'Chueca': 1205,
    'Chumillas': 1205,
    'Cifuentes': 1205,
    'Cincovillas': 1205,
    'Ciruelas': 1205,
    'Ciruelos': 1205,
    'Ciudad Real': 1205,
    'Cobeja': 1205,
    'Cobeta': 1205,
    'Cobisa': 1205,
    'Cogollor': 1205,
    'Cogolludo': 1205,
    'Condemios de Abajo': 1205,
    'Condemios de Arriba': 1205,
    'Congostrina': 1205,
    'Consuegra': 1205,
    'Copernal': 1205,
    'Corduente': 1205,
    'Corral de Almaguer': 1205,
    'Corral de Calatrava': 1205,
    'Corral-Rubio': 1205,
    'Cotillas': 1205,
    'Cózar': 1205,
    'Cuenca': 1205,
    'Cuerva': 1205,
    'Cueva del Hierro': 1205,
    'Daimiel': 1205,
    'Domingo Pérez': 1205,
    'Dosbarrios': 1205,
    'Driebes': 1205,
    'Durón': 1205,
    'El Bonillo': 1205,
    'El Carpio de Tajo': 1205,
    'El Casar de Escalona': 1205,
    'El Pedernoso': 1205,
    'El Provencio': 1205,
    'El Puente del Arzobispo': 1205,
    'El Real de San Vicente': 1205,
    'El Robledo': 1205,
    'El Toboso': 1205,
    'El Viso de San Juan': 1205,
    'Elche de la Sierra': 1205,
    'Embid': 1205,
    'Enguídanos': 1205,
    'Erustes': 1205,
    'Escalona': 1205,
    'Escalonilla': 1205,
    'Escamilla': 1205,
    'Escariche': 1205,
    'Escopete': 1205,
    'Espinosa de Henares': 1205,
    'Espinoso del Rey': 1205,
    'Esplegares': 1205,
    'Esquivias': 1205,
    'Establés': 1205,
    'Estriégana': 1205,
    'Férez': 1205,
    'Fontanar': 1205,
    'Fontanarejo': 1205,
    'Fresneda de Altarejos': 1205,
    'Fresneda de la Sierra': 1205,
    'Fuembellida': 1205,
    'Fuencaliente': 1205,
    'Fuencemillán': 1205,
    'Fuenllana': 1205,
    'Fuensalida': 1205,
    'Fuensanta': 1205,
    'Fuente de Pedro Naharro': 1205,
    'Fuente el Fresno': 1205,
    'Fuente-Álamo': 1205,
    'Fuentealbilla': 1205,
    'Fuentelahiguera de Albatages': 1205,
    'Fuentelencina': 1205,
    'Fuentelespino de Haro': 1205,
    'Fuentelespino de Moya': 1205,
    'Fuentelsaz': 1205,
    'Fuentelviejo': 1205,
    'Fuentenovilla': 1205,
    'Fuentes': 1205,
    'Fuertescusa': 1205,
    'Gabaldón': 1205,
    'Gajanejos': 1205,
    'Galápagos': 1205,
    'Galve de Sorbe': 1205,
    'Gálvez': 1205,
    'Garaballa': 1205,
    'Gascueña': 1205,
    'Gascueña de Bornova': 1205,
    'Gerindote': 1205,
    'Golosalvo': 1205,
    'Graja de Campalbo': 1205,
    'Graja de Iniesta': 1205,
    'Guadalajara': 1205,
    'Guadalmez': 1205,
    'Guadamur': 1205,
    'Hellín': 1205,
    'Henarejos': 1205,
    'Henche': 1205,
    'Herencia': 1205,
    'Herrería': 1205,
    'Herreruela de Oropesa': 1205,
    'Hiendelaencina': 1205,
    'Higueruela': 1205,
    'Hijes': 1205,
    'Hinojosa de San Vicente': 1205,
    'Hinojosas de Calatrava': 1205,
    'Hita': 1205,
    'Hombrados': 1205,
    'Honrubia': 1205,
    'Hontanar': 1205,
    'Hontanaya': 1205,
    'Hontecillas': 1205,
    'Hontoba': 1205,
    'Horcajo de Santiago': 1205,
    'Horche': 1205,
    'Hormigos': 1205,
    'Hoya-Gonzalo': 1205,
    'Huecas': 1205,
    'Huélamo': 1205,
    'Huelves': 1205,
    'Huérguina': 1205,
    'Huérmeces del Cerro': 1205,
    'Huerta de la Obispalía': 1205,
    'Huerta de Valdecarábanos': 1205,
    'Huerta del Marquesado': 1205,
    'Huertahernando': 1205,
    'Huete': 1205,
    'Hueva': 1205,
    'Humanes': 1205,
    'Illán de Vacas': 1205,
    'Illana': 1205,
    'Illescas': 1205,
    'Infantes': 1205,
    'Iniesta': 1205,
    'Iniéstola': 1205,
    'Irueste': 1205,
    'Jadraque': 1205,
    'Jirueque': 1205,
    'Jorquera': 1205,
    'La Alameda de la Sagra': 1205,
    'La Alberca de Záncara': 1205,
    'La Calzada de Calatrava': 1205,
    'La Frontera': 1205,
    'La Gineta': 1205,
    'La Guardia': 1205,
    'La Puebla de Almoradiel': 1205,
    'La Puebla de Montalbán': 1205,
    'La Pueblanueva': 1205,
    'La Roda': 1205,
    'La Solana': 1205,
    'La Torre de Esteban Hambrán': 1205,
    'Lagartera': 1205,
    'Laguna del Marquesado': 1205,
    'Lagunaseca': 1205,
    'Landete': 1205,
    'Las Mesas': 1205,
    'Las Pedroñeras': 1205,
    'Las Ventas de Retamosa': 1205,
    'Layos': 1205,
    'Ledaña': 1205,
    'Ledanca': 1205,
    'Leganiel': 1205,
    'Letur': 1205,
    'Lezuza': 1205,
    'Liétor': 1205,
    'Lillo': 1205,
    'Lominchar': 1205,
    'Loranca de Tajuña': 1205,
    'Los Hinojosos': 1205,
    'Los Navalmorales': 1205,
    'Los Navalucillos': 1205,
    'Los Yébenes': 1205,
    'Lucillos': 1205,
    'Lupiana': 1205,
    'Luzaga': 1205,
    'Luzón': 1205,
    'Madridejos': 1205,
    'Madrigueras': 1205,
    'Magán': 1205,
    'Mahora': 1205,
    'Majaelrayo': 1205,
    'Málaga del Fresno': 1205,
    'Malagón': 1205,
    'Malaguilla': 1205,
    'Malpica': 1205,
    'Mandayona': 1205,
    'Mantiel': 1205,
    'Manzanares': 1205,
    'Manzaneque': 1205,
    'Maqueda': 1205,
    'Maranchón': 1205,
    'Marchamalo': 1205,
    'Mariana': 1205,
    'Marjaliza': 1205,
    'Mascaraque': 1205,
    'Masegosa': 1205,
    'Masegoso': 1205,
    'Masegoso de Tajuña': 1205,
    'Matarrubia': 1205,
    'Matillas': 1205,
    'Mazarambroz': 1205,
    'Mazarete': 1205,
    'Mazuecos': 1205,
    'Medranda': 1205,
    'Megina': 1205,
    'Mejorada': 1205,
    'Membrilla': 1205,
    'Membrillera': 1205,
    'Menasalbas': 1205,
    'Méntrida': 1205,
    'Mestanza': 1205,
    'Miedes de Atienza': 1205,
    'Miguel Esteban': 1205,
    'Miguelturra': 1205,
    'Millana': 1205,
    'Milmarcos': 1205,
    'Minaya': 1205,
    'Minglanilla': 1205,
    'Mira': 1205,
    'Mirabueno': 1205,
    'Miralrío': 1205,
    'Mocejón': 1205,
    'Mochales': 1205,
    'Mohedas de la Jara': 1205,
    'Mohernando': 1205,
    'Molina de Aragón': 1205,
    'Molinicos': 1205,
    'Monasterio': 1205,
    'Mondéjar': 1205,
    'Monreal del Llano': 1205,
    'Montalbanejo': 1205,
    'Montalbo': 1205,
    'Montalvos': 1205,
    'Montarrón': 1205,
    'Monteagudo de las Salinas': 1205,
    'Montealegre del Castillo': 1205,
    'Montearagón': 1205,
    'Montesclaros': 1205,
    'Montiel': 1205,
    'Mora': 1205,
    'Moral de Calatrava': 1205,
    'Moratilla de los Meleros': 1205,
    'Morenilla': 1205,
    'Mota de Altarejos': 1205,
    'Mota del Cuervo': 1205,
    'Motilla del Palancar': 1205,
    'Motilleja': 1205,
    'Moya': 1205,
    'Muduex': 1205,
    'Munera': 1205,
    'Nambroca': 1205,
    'Narboneta': 1205,
    'Navahermosa': 1205,
    'Navalcán': 1205,
    'Navalmoralejo': 1205,
    'Navalpino': 1205,
    'Navamorcuende': 1205,
    'Navas de Estena': 1205,
    'Navas de Jorquera': 1205,
    'Negredo': 1205,
    'Nerpio': 1205,
    'Noblejas': 1205,
    'Noez': 1205,
    'Nombela': 1205,
    'Novés': 1205,
    'Numancia de la Sagra': 1205,
    'Nuño Gómez': 1205,
    'Ocaña': 1205,
    'Ocentejo': 1205,
    'Olías del Rey': 1205,
    'Olivares de Júcar': 1205,
    'Olmeda de Cobeta': 1205,
    'Olmeda de la Cuesta': 1205,
    'Olmeda del Rey': 1205,
    'Olmedilla de Alarcón': 1205,
    'Olmedilla de Eliz': 1205,
    'Ontígola': 1205,
    'Ontur': 1205,
    'Orea': 1205,
    'Orgaz': 1205,
    'Oropesa': 1205,
    'Osa de la Vega': 1205,
    'Ossa de Montiel': 1205,
    'Otero': 1205,
    'Pajarón': 1205,
    'Pajaroncillo': 1205,
    'Pálmaces de Jadraque': 1205,
    'Palomares del Campo': 1205,
    'Palomeque': 1205,
    'Pantoja': 1205,
    'Paracuellos': 1205,
    'Paredes': 1205,
    'Paredes de Escalona': 1205,
    'Paredes de Sigüenza': 1205,
    'Pareja': 1205,
    'Parrillas': 1205,
    'Pastrana': 1205,
    'Paterna del Madera': 1205,
    'Pedro Muñoz': 1205,
    'Pelahustán': 1205,
    'Peñalén': 1205,
    'Peñalver': 1205,
    'Peñas de San Pedro': 1205,
    'Peralejos de las Truchas': 1205,
    'Peralveche': 1205,
    'Pétrola': 1205,
    'Picón': 1205,
    'Piedrabuena': 1205,
    'Pinarejo': 1205,
    'Pineda de Gigüela': 1205,
    'Pinilla de Jadraque': 1205,
    'Pinilla de Molina': 1205,
    'Pioz': 1205,
    'Piqueras': 1205,
    'Piqueras del Castillo': 1205,
    'Poblete': 1205,
    'Polán': 1205,
    'Portilla': 1205,
    'Portillo de Toledo': 1205,
    'Porzuna': 1205,
    'Poveda de la Sierra': 1205,
    'Povedilla': 1205,
    'Pozo de Almoguera': 1205,
    'Pozo de Guadalajara': 1205,
    'Pozo-Cañada': 1205,
    'Pozoamargo': 1205,
    'Pozohondo': 1205,
    'Pozorrubio': 1205,
    'Pozuelo': 1205,
    'Pozuelo de Calatrava': 1205,
    'Prádena de Atienza': 1205,
    'Prados Redondos': 1205,
    'Priego': 1205,
    'Province of Toledo': 1205,
    'Provincia de Albacete': 1205,
    'Provincia de Ciudad Real': 1205,
    'Provincia de Cuenca': 1205,
    'Provincia de Guadalajara': 1205,
    'Puebla de Almenara': 1205,
    'Puebla de Beleña': 1205,
    'Puebla de Don Rodrigo': 1205,
    'Puebla del Príncipe': 1205,
    'Puebla del Salvador': 1205,
    'Puerto de San Vicente': 1205,
    'Puerto Lápice': 1205,
    'Puertollano': 1205,
    'Pulgar': 1205,
    'Quer': 1205,
    'Quero': 1205,
    'Quintanar de la Orden': 1205,
    'Quintanar del Rey': 1205,
    'Quismondo': 1205,
    'Rada de Haro': 1205,
    'Rebollosa de Jadraque': 1205,
    'Recas': 1205,
    'Reíllo': 1205,
    'Renera': 1205,
    'Retiendas': 1205,
    'Retuerta de Bullaque': 1205,
    'Riba de Saelices': 1205,
    'Rielves': 1205,
    'Rillo de Gallo': 1205,
    'Riópar': 1205,
    'Robledillo de Mohernando': 1205,
    'Robledo': 1205,
    'Robledo de Corpes': 1205,
    'Romanillos de Atienza': 1205,
    'Romanones': 1205,
    'Rozalén del Monte': 1205,
    'Rueda de la Sierra': 1205,
    'Ruidera': 1205,
    'Sacecorbo': 1205,
    'Saceda-Trasierra': 1205,
    'Sacedón': 1205,
    'Saceruela': 1205,
    'Saelices': 1205,
    'Saelices de la Sal': 1205,
    'Salinas del Manzano': 1205,
    'Salmerón': 1205,
    'Salobre': 1205,
    'Salvacañete': 1205,
    'San Andrés del Congosto': 1205,
    'San Andrés del Rey': 1205,
    'San Bartolomé de las Abiertas': 1205,
    'San Carlos del Valle': 1205,
    'San Clemente': 1205,
    'San Lorenzo de Calatrava': 1205,
    'San Lorenzo de la Parrilla': 1205,
    'San Martín de Boniches': 1205,
    'San Martín de Montalbán': 1205,
    'San Martín de Pusa': 1205,
    'San Pedro': 1205,
    'San Pedro Palmiches': 1205,
    'Santa Ana de Pusa': 1205,
    'Santa Cruz de la Zarza': 1205,
    'Santa Cruz de los Cáñamos': 1205,
    'Santa Cruz de Moya': 1205,
    'Santa Cruz de Mudela': 1205,
    'Santa Cruz del Retamar': 1205,
    'Santa María del Campo Rus': 1205,
    'Santa María del Val': 1205,
    'Santa Olalla': 1205,
    'Santiuste': 1205,
    'Sartajada': 1205,
    'Saúca': 1205,
    'Sayatón': 1205,
    'Segurilla': 1205,
    'Selas': 1205,
    'Semillas': 1205,
    'Seseña': 1205,
    'Setiles': 1205,
    'Sevilleja de la Jara': 1205,
    'Sienes': 1205,
    'Sigüenza': 1205,
    'Sisante': 1205,
    'Socovos': 1205,
    'Socuéllamos': 1205,
    'Solanillos del Extremo': 1205,
    'Somolinos': 1205,
    'Sonseca': 1205,
    'Sotillo de las Palomas': 1205,
    'Sotodosos': 1205,
    'Talavera de la Reina': 1205,
    'Tamajón': 1205,
    'Taragudo': 1205,
    'Tarancón': 1205,
    'Taravilla': 1205,
    'Tarazona de la Mancha': 1205,
    'Tartanedo': 1205,
    'Tébar': 1205,
    'Tejadillos': 1205,
    'Tembleque': 1205,
    'Tendilla': 1205,
    'Terrinches': 1205,
    'Terzaga': 1205,
    'Tierzo': 1205,
    'Tinajas': 1205,
    'Tobarra': 1205,
    'Toledo': 1205,
    'Tomelloso': 1205,
    'Tordellego': 1205,
    'Tordelrábano': 1205,
    'Tordesilos': 1205,
    'Torija': 1205,
    'Torralba': 1205,
    'Torralba de Calatrava': 1205,
    'Torralba de Oropesa': 1205,
    'Torre de Juan Abad': 1205,
    'Torre del Burgo': 1205,
    'Torrecilla de la Jara': 1205,
    'Torrecuadrada de Molina': 1205,
    'Torrecuadradilla': 1205,
    'Torrejón del Rey': 1205,
    'Torrejoncillo del Rey': 1205,
    'Torremocha de Jadraque': 1205,
    'Torremocha del Campo': 1205,
    'Torremocha del Pinar': 1205,
    'Torremochuela': 1205,
    'Torrenueva': 1205,
    'Torrico': 1205,
    'Torrijos': 1205,
    'Torrubia': 1205,
    'Torrubia del Campo': 1205,
    'Torrubia del Castillo': 1205,
    'Tórtola de Henares': 1205,
    'Tortuera': 1205,
    'Tortuero': 1205,
    'Totanés': 1205,
    'Tresjuncos': 1205,
    'Tribaldos': 1205,
    'Trijueque': 1205,
    'Trillo': 1205,
    'Turleque': 1205,
    'Uceda': 1205,
    'Uclés': 1205,
    'Ugena': 1205,
    'Ujados': 1205,
    'Uña': 1205,
    'Urda': 1205,
    'Utande': 1205,
    'Valdarachas': 1205,
    'Valdearenas': 1205,
    'Valdeavellano': 1205,
    'Valdeaveruelo': 1205,
    'Valdeconcha': 1205,
    'Valdeganga': 1205,
    'Valdegrudas': 1205,
    'Valdelcubo': 1205,
    'Valdemanco del Esteras': 1205,
    'Valdemeca': 1205,
    'Valdemorillo de la Sierra': 1205,
    'Valdemoro-Sierra': 1205,
    'Valdeolivas': 1205,
    'Valdepeñas': 1205,
    'Valdepeñas de la Sierra': 1205,
    'Valderrebollo': 1205,
    'Valdesotos': 1205,
    'Valdeverdeja': 1205,
    'Valenzuela de Calatrava': 1205,
    'Valfermoso de Tajuña': 1205,
    'Valhermoso': 1205,
    'Valhermoso de la Fuente': 1205,
    'Valmojado': 1205,
    'Valsalobre': 1205,
    'Valtablado del Río': 1205,
    'Valverde de Júcar': 1205,
    'Valverde de los Arroyos': 1205,
    'Valverdejo': 1205,
    'Vara de Rey': 1205,
    'Vega del Codorno': 1205,
    'Velada': 1205,
    'Vellisca': 1205,
    'Ventas con Peña Aguilera': 1205,
    'Viana de Jadraque': 1205,
    'Villa de Don Fadrique': 1205,
    'Villa de Ves': 1205,
    'Villacañas': 1205,
    'Villaconejos de Trabaque': 1205,
    'Villafranca de los Caballeros': 1205,
    'Villagarcía del Llano': 1205,
    'Villagordo del Júcar': 1205,
    'Villahermosa': 1205,
    'Villalba de la Sierra': 1205,
    'Villalba del Rey': 1205,
    'Villalgordo del Marquesado': 1205,
    'Villalpardo': 1205,
    'Villamalea': 1205,
    'Villamanrique': 1205,
    'Villamayor de Calatrava': 1205,
    'Villamayor de Santiago': 1205,
    'Villamiel de Toledo': 1205,
    'Villaminaya': 1205,
    'Villamuelas': 1205,
    'Villanueva de Alcardete': 1205,
    'Villanueva de Alcorón': 1205,
    'Villanueva de Argecilla': 1205,
    'Villanueva de Bogas': 1205,
    'Villanueva de la Fuente': 1205,
    'Villanueva de la Jara': 1205,
    'Villanueva de la Torre': 1205,
    'Villanueva de San Carlos': 1205,
    'Villapalacios': 1205,
    'Villar de Cañas': 1205,
    'Villar de Domingo García': 1205,
    'Villar de la Encina': 1205,
    'Villar de Olalla': 1205,
    'Villar del Humo': 1205,
    'Villar del Infantado': 1205,
    'Villar del Pozo': 1205,
    'Villarejo de Fuentes': 1205,
    'Villarejo de la Peñuela': 1205,
    'Villarejo de Montalbán': 1205,
    'Villarejo-Periesteban': 1205,
    'Villares de Jadraque': 1205,
    'Villares del Saz': 1205,
    'Villarrobledo': 1205,
    'Villarrubia de los Ojos': 1205,
    'Villarrubia de Santiago': 1205,
    'Villarrubio': 1205,
    'Villarta': 1205,
    'Villarta de San Juan': 1205,
    'Villaseca de Henares': 1205,
    'Villaseca de la Sagra': 1205,
    'Villaseca de Uceda': 1205,
    'Villasequilla de Yepes': 1205,
    'Villatobas': 1205,
    'Villatoya': 1205,
    'Villavaliente': 1205,
    'Villaverde de Guadalimar': 1205,
    'Villaverde y Pasaconsol': 1205,
    'Villel de Mesa': 1205,
    'Vindel': 1205,
    'Viñuelas': 1205,
    'Viso del Marqués': 1205,
    'Viveros': 1205,
    'Yebes': 1205,
    'Yebra': 1205,
    'Yélamos de Abajo': 1205,
    'Yélamos de Arriba': 1205,
    'Yeles': 1205,
    'Yémeda': 1205,
    'Yepes': 1205,
    'Yeste': 1205,
    'Yuncler': 1205,
    'Yuncos': 1205,
    'Yunquera de Henares': 1205,
    'Zafra de Záncara': 1205,
    'Zafrilla': 1205,
    'Zaorejas': 1205,
    'Zarza de Tajo': 1205,
    'Zarzuela': 1205,
    'Zarzuela de Jadraque': 1205,
    'Zorita de los Canes': 1205,
    'Abrera': 1203,
    'Àger': 1203,
    'Agramunt': 1203,
    'Aguilar de Segarra': 1203,
    'Agullana': 1203,
    'Aiguafreda': 1203,
    'Aiguaviva': 1203,
    'Aitona': 1203,
    'Alàs i Cerc': 1203,
    'Albesa': 1203,
    'Albons': 1203,
    'Alcanar': 1203,
    'Alcanó': 1203,
    'Alcarràs': 1203,
    'Alcoletge': 1203,
    'Alcover': 1203,
    'Alella': 1203,
    'Alfarràs': 1203,
    'Alfés': 1203,
    'Alforja': 1203,
    'Algerri': 1203,
    'Alguaire': 1203,
    'Alió': 1203,
    'Almacelles': 1203,
    'Almenar': 1203,
    'Almoster': 1203,
    'Alpens': 1203,
    'Alpicat': 1203,
    'Altafulla': 1203,
    'Amposta': 1203,
    'Anglès': 1203,
    'Anglesola': 1203,
    'Arbeca': 1203,
    'Arbúcies': 1203,
    'Arenys de Mar': 1203,
    'Arenys de Munt': 1203,
    'Argelaguer': 1203,
    'Argentona': 1203,
    'Artés': 1203,
    'Artesa de Segre': 1203,
    'Ascó': 1203,
    'Aspa': 1203,
    'Avinyó': 1203,
    'Badalona': 1203,
    'Bagà': 1203,
    'Balaguer': 1203,
    'Balsareny': 1203,
    'Banyoles': 1203,
    'Barberà del Vallès': 1203,
    'Barcelona': 1203,
    'Baró de Viver': 1203,
    'Barri de les Corts': 1203,
    'Barri de Sant Andreu': 1203,
    'Barri Gòtic': 1203,
    'Bàscara': 1203,
    'Batea': 1203,
    'Begues': 1203,
    'Begur': 1203,
    'Bellcaire d  Urgell': 1203,
    'Bellprat': 1203,
    'Bellpuig': 1203,
    'Bellver de Cerdanya': 1203,
    'Bellvís': 1203,
    'Benifallet': 1203,
    'Berga': 1203,
    'Besalú': 1203,
    'Bescanó': 1203,
    'Beuda': 1203,
    'Bigues i Riells': 1203,
    'Blancafort': 1203,
    'Blanes': 1203,
    'Bolvir': 1203,
    'Bonastre': 1203,
    'Bordils': 1203,
    'Borrassà': 1203,
    'Bot': 1203,
    'Botarell': 1203,
    'Breda': 1203,
    'Cabanelles': 1203,
    'Cabrera de Mar': 1203,
    'Cabrils': 1203,
    'Cadaqués': 1203,
    'Calaf': 1203,
    'Calafell': 1203,
    'Caldes de Montbui': 1203,
    'Calella': 1203,
    'Calonge': 1203,
    'Camarasa': 1203,
    'Cambrils': 1203,
    'Camós': 1203,
    'Campdevànol': 1203,
    'Campins': 1203,
    'Camprodon': 1203,
    'Can Baró': 1203,
    'Can Peguera': 1203,
    'Canet de Mar': 1203,
    'Canovelles': 1203,
    'Cànoves i Samalús': 1203,
    'Cantallops': 1203,
    'Canyelles': 1203,
    'Capafonts': 1203,
    'Capellades': 1203,
    'Capmany': 1203,
    'Cardedeu': 1203,
    'Cardona': 1203,
    'Cassà de la Selva': 1203,
    'Castell-Platja d  Aro': 1203,
    'Castellar del Vallès': 1203,
    'Castellbisbal': 1203,
    'Castellcir': 1203,
    'Castelldefels': 1203,
    'Castellet': 1203,
    'Castellfollit de Riubregós': 1203,
    'Castellnou de Seana': 1203,
    'Castelló d  Empúries': 1203,
    'Castellolí': 1203,
    'Castellserà': 1203,
    'Castellví de Rosanes': 1203,
    'Celrà': 1203,
    'Centelles': 1203,
    'Cerdanyola del Vallès': 1203,
    'Cervelló': 1203,
    'Cervera': 1203,
    'Cervià de Ter': 1203,
    'Cistella': 1203,
    'Ciutadilla': 1203,
    'Ciutat Meridiana': 1203,
    'Ciutat Vella': 1203,
    'Colera': 1203,
    'Coll de Nargó': 1203,
    'Collbató': 1203,
    'Colldejou': 1203,
    'Collsuspina': 1203,
    'Coma-ruga': 1203,
    'Conesa': 1203,
    'Constantí': 1203,
    'Corbera de Llobregat': 1203,
    'Corbins': 1203,
    'Cornellà de Llobregat': 1203,
    'Cornellà del Terri': 1203,
    'Creixell': 1203,
    'Crespià': 1203,
    'Cruïlles': 1203,
    'Cubelles': 1203,
    'Cubells': 1203,
    'Cunit': 1203,
    'Darnius': 1203,
    'Deltebre': 1203,
    'Diagonal Mar': 1203,
    'Dosrius': 1203,
    'Dreta de l  Eixample': 1203,
    'Eixample': 1203,
    'el Baix Guinardó': 1203,
    'el Besòs i el Maresme': 1203,
    'el Bon Pastor': 1203,
    'el Camp d  en Grassot i Gràcia Nova': 1203,
    'el Camp de l  Arpa del Clot': 1203,
    'El Carmel': 1203,
    'el Catllar': 1203,
    'el Clot': 1203,
    'el Cogul': 1203,
    'el Coll': 1203,
    'el Congrés i els Indians': 1203,
    'el Guinardó': 1203,
    'El Masnou': 1203,
    'el Masroig': 1203,
    'el Parc i la Llacuna del Poblenou': 1203,
    'El Perelló': 1203,
    'El Pla de Santa Maria': 1203,
    'el Poblenou': 1203,
    'El Prat de Llobregat': 1203,
    'el Putxet i el Farró': 1203,
    'el Raval': 1203,
    'el Turó de la Peira': 1203,
    'El Vendrell': 1203,
    'Éller': 1203,
    'els Pallaresos': 1203,
    'Empuriabrava': 1203,
    'Esparreguera': 1203,
    'Esplugues de Llobregat': 1203,
    'Espolla': 1203,
    'Esponellà': 1203,
    'Espot': 1203,
    'Falset': 1203,
    'Farrera': 1203,
    'Figaró': 1203,
    'Figueres': 1203,
    'Flix': 1203,
    'Foixà': 1203,
    'Fonollosa': 1203,
    'Forès': 1203,
    'Fornells de la Selva': 1203,
    'Fort Pienc': 1203,
    'Fortià': 1203,
    'Fulleda': 1203,
    'Gallifa': 1203,
    'Gandesa': 1203,
    'Garcia': 1203,
    'Garrigàs': 1203,
    'Garriguella': 1203,
    'Gavà': 1203,
    'Gelida': 1203,
    'Ginestar': 1203,
    'Girona': 1203,
    'Gironella': 1203,
    'Golmés': 1203,
    'Gósol': 1203,
    'Gràcia': 1203,
    'Granera': 1203,
    'Granollers': 1203,
    'Gualta': 1203,
    'Guimerà': 1203,
    'Gurb': 1203,
    'Horta': 1203,
    'Horta-Guinardó': 1203,
    'Hostafrancs': 1203,
    'Hostalric': 1203,
    'Igualada': 1203,
    'Ivorra': 1203,
    'Jafre': 1203,
    'Jorba': 1203,
    'Juià': 1203,
    'Juncosa': 1203,
    'Juneda': 1203,
    'L  Ametlla del Vallès': 1203,
    'L  Ampolla': 1203,
    'l  Antiga Esquerra de l  Eixample': 1203,
    'l  Escala': 1203,
    'l  Estartit': 1203,
    'L  Hospitalet de Llobregat': 1203,
    'la Barceloneta': 1203,
    'la Bisbal d  Empordà': 1203,
    'La Bonanova': 1203,
    'La Bordeta': 1203,
    'La Canonja': 1203,
    'la Cellera de Ter': 1203,
    'la Fatarella': 1203,
    'la Font d  en Fargues': 1203,
    'la Font de la Guatlla': 1203,
    'La Fuliola': 1203,
    'la Garriga': 1203,
    'La Granada': 1203,
    'la Granadella': 1203,
    'la Granja d  Escarp': 1203,
    'la Guineueta': 1203,
    'La Llagosta': 1203,
    'la Marina de Port': 1203,
    'la Marina del Prat Vermell': 1203,
    'la Maternitat i Sant Ramon': 1203,
    'la Morera de Montsant': 1203,
    'la Nova Esquerra de l  Eixample': 1203,
    'La Pineda': 1203,
    'La Pobla de Claramunt': 1203,
    'la Pobla de Mafumet': 1203,
    'la Prosperitat': 1203,
    'La Roca del Vallès': 1203,
    'La Sagrera': 1203,
    'la Salut': 1203,
    'La Secuita': 1203,
    'La Selva del Camp': 1203,
    'La Seu d  Urgell': 1203,
    'la Teixonera': 1203,
    'la Trinitat Nova': 1203,
    'la Trinitat Vella': 1203,
    'la Vall d  Hebron': 1203,
    'la Verneda i la Pau': 1203,
    'la Vila de Gràcia': 1203,
    'la Vila Olímpica del Poblenou': 1203,
    'Las Tres Torres': 1203,
    'Les': 1203,
    'les Borges del Camp': 1203,
    'Les Cases d  Alcanar': 1203,
    'Les Corts': 1203,
    'les Llosses': 1203,
    'les Planes d  Hostoles': 1203,
    'les Roquetes': 1203,
    'Linyola': 1203,
    'Lladó': 1203,
    'Llagostera': 1203,
    'Llambilles': 1203,
    'Llançà': 1203,
    'Llefià': 1203,
    'Lleida': 1203,
    'Llers': 1203,
    'Lles de Cerdanya': 1203,
    'Lliçà d  Amunt': 1203,
    'Llimiana': 1203,
    'Llinars del Vallès': 1203,
    'Lloret de Mar': 1203,
    'Madremanya': 1203,
    'Malgrat de Mar': 1203,
    'Manlleu': 1203,
    'Manresa': 1203,
    'Margalef': 1203,
    'Martorell': 1203,
    'Mas de Barberans': 1203,
    'Masdenverge': 1203,
    'Masquefa': 1203,
    'Massanes': 1203,
    'Matadepera': 1203,
    'Mataró': 1203,
    'Mediona': 1203,
    'Miralcamp': 1203,
    'Miravet': 1203,
    'Moià': 1203,
    'Molins de Rei': 1203,
    'Mollerussa': 1203,
    'Mollet de Peralada': 1203,
    'Mollet del Vallès': 1203,
    'Monistrol de Montserrat': 1203,
    'Mont-roig del Camp': 1203,
    'Montbau': 1203,
    'Montblanc': 1203,
    'Montcada i Reixac': 1203,
    'Montferri': 1203,
    'Montgat': 1203,
    'Montmeló': 1203,
    'Montornès del Vallès': 1203,
    'Mura': 1203,
    'Navarcles': 1203,
    'Navas': 1203,
    'Navata': 1203,
    'Nou Barris': 1203,
    'Òdena': 1203,
    'Olesa de Bonesvalls': 1203,
    'Olesa de Montserrat': 1203,
    'Oliana': 1203,
    'Olius': 1203,
    'Olivella': 1203,
    'Olost': 1203,
    'Olot': 1203,
    'Ordis': 1203,
    'Orís': 1203,
    'Oristà': 1203,
    'Orpí': 1203,
    'Òrrius': 1203,
    'Os de Balaguer': 1203,
    'Ossó de Sió': 1203,
    'Palafrugell': 1203,
    'Palamós': 1203,
    'Palau-sator': 1203,
    'Pallejà': 1203,
    'Palol de Revardit': 1203,
    'Pals': 1203,
    'Parets del Vallès': 1203,
    'Pau': 1203,
    'Pedralbes': 1203,
    'Piera': 1203,
    'Pineda de Mar': 1203,
    'Planoles': 1203,
    'Poble Sec': 1203,
    'Polinyà': 1203,
    'Pont de Molins': 1203,
    'Pontils': 1203,
    'Pontós': 1203,
    'Ponts': 1203,
    'Porta': 1203,
    'Portbou': 1203,
    'Premià de Mar': 1203,
    'Provenals del Poblenou': 1203,
    'Província de Barcelona': 1203,
    'Província de Girona': 1203,
    'Província de Lleida': 1203,
    'Província de Tarragona': 1203,
    'Puigcerdà': 1203,
    'Puigpelat': 1203,
    'Pujalt': 1203,
    'Quart d’Onyar': 1203,
    'Querol': 1203,
    'Rabós': 1203,
    'Rajadell': 1203,
    'Rasquera': 1203,
    'Regencós': 1203,
    'Renau': 1203,
    'Reus': 1203,
    'Ribes de Freser': 1203,
    'Ripoll': 1203,
    'Ripollet': 1203,
    'Riudecanyes': 1203,
    'Riudecols': 1203,
    'Riudellots de la Selva': 1203,
    'Riudoms': 1203,
    'Riumors': 1203,
    'Roda de Barà': 1203,
    'Roses': 1203,
    'Rubí': 1203,
    'Rupià': 1203,
    'S  Agaró': 1203,
    'Sabadell': 1203,
    'Sagàs': 1203,
    'Sagrada Família': 1203,
    'Sales de Llierca': 1203,
    'Sallent': 1203,
    'Salomó': 1203,
    'Salou': 1203,
    'Salt': 1203,
    'Sant Adrià de Besòs': 1203,
    'Sant Andreu': 1203,
    'Sant Andreu de la Barca': 1203,
    'Sant Andreu de Llavaneres': 1203,
    'Sant Andreu Salou': 1203,
    'Sant Antoni': 1203,
    'Sant Boi de Llobregat': 1203,
    'Sant Carles de la Ràpita': 1203,
    'Sant Celoni': 1203,
    'Sant Cristòfol de les Fonts': 1203,
    'Sant Cugat del Vallès': 1203,
    'Sant Esteve d  en Bas': 1203,
    'Sant Feliu de Guíxols': 1203,
    'Sant Feliu de Llobregat': 1203,
    'Sant Ferriol': 1203,
    'Sant Genís dels Agudells': 1203,
    'Sant Gervasi - Galvany': 1203,
    'Sant Hilari Sacalm': 1203,
    'Sant Joan de les Abadesses': 1203,
    'Sant Joan de Mediona': 1203,
    'Sant Joan de Vilatorrada': 1203,
    'Sant Joan Despí': 1203,
    'Sant Joan les Fonts': 1203,
    'Sant Jordi Desvalls': 1203,
    'Sant Julià de Cerdanyola': 1203,
    'Sant Just Desvern': 1203,
    'Sant Martí': 1203,
    'Sant Martí de Centelles': 1203,
    'Sant Martí de Provençals': 1203,
    'Sant Martí de Tous': 1203,
    'Sant Martí Sarroca': 1203,
    'Sant Martí Vell': 1203,
    'Sant Miquel de Campmajor': 1203,
    'Sant Pere de Ribes': 1203,
    'Sant Pere de Riudebitlles': 1203,
    'Sant Pere Pescador': 1203,
    'Sant Pere, Santa Caterina i La Ribera': 1203,
    'Sant Pol de Mar': 1203,
    'Sant Quirze del Vallès': 1203,
    'Sant Sadurní d  Anoia': 1203,
    'Sant Salvador de Guardiola': 1203,
    'Sant Vicenç de Castellet': 1203,
    'Sant Vicenç de Montalt': 1203,
    'Sant Vicenç dels Horts': 1203,
    'Santa Bárbara': 1203,
    'Santa Cecília de Voltregà': 1203,
    'Santa Coloma de Cervelló': 1203,
    'Santa Coloma de Farners': 1203,
    'Santa Coloma de Gramenet': 1203,
    'Santa Cristina d  Aro': 1203,
    'Santa Eugènia de Berga': 1203,
    'Santa Eulàlia de Ronçana': 1203,
    'Santa Maria d  Oló': 1203,
    'Santa Maria de Corcó': 1203,
    'Santa Maria de Palautordera': 1203,
    'Santa Oliva': 1203,
    'Santa Pau': 1203,
    'Santa Perpètua de Mogoda': 1203,
    'Santa Susanna': 1203,
    'Sants': 1203,
    'Sants - Badal': 1203,
    'Sants-Montjuïc': 1203,
    'Sarrià': 1203,
    'Sarrià de Ter': 1203,
    'Sarrià-Sant Gervasi': 1203,
    'Sentmenat': 1203,
    'Seròs': 1203,
    'Serra de Daró': 1203,
    'Seva': 1203,
    'Sidamon': 1203,
    'Sils': 1203,
    'Sitges': 1203,
    'Siurana': 1203,
    'Solsona': 1203,
    'Sort': 1203,
    'Soses': 1203,
    'Subirats': 1203,
    'Súria': 1203,
    'Susqueda': 1203,
    'Tagamanent': 1203,
    'Talamanca': 1203,
    'Taradell': 1203,
    'Tarragona': 1203,
    'Tàrrega': 1203,
    'Tavertet': 1203,
    'Teià': 1203,
    'Térmens': 1203,
    'Terrassa': 1203,
    'Tiana': 1203,
    'Tiurana': 1203,
    'Tivenys': 1203,
    'Toloriu': 1203,
    'Tona': 1203,
    'Torà de Riubregós': 1203,
    'Tordera': 1203,
    'Torelló': 1203,
    'Tornabous': 1203,
    'Torre Baró': 1203,
    'Torredembarra': 1203,
    'Torrefarrera': 1203,
    'Torrelles de Llobregat': 1203,
    'Torrent': 1203,
    'Torres de Segre': 1203,
    'Torroella de Fluvià': 1203,
    'Torroella de Montgrí': 1203,
    'Tortosa': 1203,
    'Tossa de Mar': 1203,
    'Tremp': 1203,
    'Ullà': 1203,
    'Ullastrell': 1203,
    'Ulldecona': 1203,
    'Ulldemolins': 1203,
    'Ultramort': 1203,
    'Urús': 1203,
    'Vallbona': 1203,
    'Vallbona de les Monges': 1203,
    'Vallcarca': 1203,
    'Vallclara': 1203,
    'Vallfogona de Balaguer': 1203,
    'Vallgorguina': 1203,
    'Vallirana': 1203,
    'Vallmoll': 1203,
    'Valls': 1203,
    'Vallvidrera, el Tibidabo i les Planes': 1203,
    'Ventalló': 1203,
    'Verdú': 1203,
    'Verdun': 1203,
    'Verges': 1203,
    'Vic': 1203,
    'Vidrà': 1203,
    'Vidreres': 1203,
    'Vielha': 1203,
    'Vila-seca': 1203,
    'Vilabella': 1203,
    'Vilabertran': 1203,
    'Vilablareix': 1203,
    'Viladasens': 1203,
    'Viladecans': 1203,
    'Vilademuls': 1203,
    'Viladrau': 1203,
    'Vilafant': 1203,
    'Vilafranca del Penedès': 1203,
    'Vilajuïga': 1203,
    'Vilamacolum': 1203,
    'Vilamalla': 1203,
    'Vilamaniscle': 1203,
    'Vilanant': 1203,
    'Vilanova d  Escornalbou': 1203,
    'Vilanova de Bellpuig': 1203,
    'Vilanova de Prades': 1203,
    'Vilanova de Sau': 1203,
    'Vilanova del Camí': 1203,
    'Vilanova i la Geltrú': 1203,
    'Vilapicina i la Torre Llobeta': 1203,
    'Vilaplana': 1203,
    'Vilaseca': 1203,
    'Vilassar de Mar': 1203,
    'Vilopriu': 1203,
    'Vimbodí': 1203,
    'Vinaixa': 1203,
    'Vinebre': 1203,
    'Benzú': 1206,
    'Ceuta': 1206,
    'Abadía': 1190,
    'Abertura': 1190,
    'Acebo': 1190,
    'Acedera': 1190,
    'Aceituna': 1190,
    'Aceuchal': 1190,
    'Ahigal': 1190,
    'Ahillones': 1190,
    'Alange': 1190,
    'Albalá': 1190,
    'Alburquerque': 1190,
    'Alcántara': 1190,
    'Alcollarín': 1190,
    'Alconchel': 1190,
    'Alconera': 1190,
    'Alcuéscar': 1190,
    'Aldea del Cano': 1190,
    'Aldeacentenera': 1190,
    'Aldeanueva de la Vera': 1190,
    'Aldeanueva del Camino': 1190,
    'Alía': 1190,
    'Aliseda': 1190,
    'Aljucén': 1190,
    'Almaraz': 1190,
    'Almendral': 1190,
    'Almendralejo': 1190,
    'Almoharín': 1190,
    'Arroyo de la Luz': 1190,
    'Arroyo de San Serván': 1190,
    'Arroyomolinos de la Vera': 1190,
    'Atalaya': 1190,
    'Azuaga': 1190,
    'Badajoz': 1190,
    'Baños de Montemayor': 1190,
    'Barcarrota': 1190,
    'Barrado': 1190,
    'Baterno': 1190,
    'Benquerencia': 1190,
    'Berlanga': 1190,
    'Berrocalejo': 1190,
    'Bienvenida': 1190,
    'Bodonal de la Sierra': 1190,
    'Bohonal de Ibor': 1190,
    'Botija': 1190,
    'Brozas': 1190,
    'Burguillos del Cerro': 1190,
    'Cabañas del Castillo': 1190,
    'Cabeza del Buey': 1190,
    'Cabeza la Vaca': 1190,
    'Cabezabellosa': 1190,
    'Cabezuela del Valle': 1190,
    'Cabrero': 1190,
    'Cáceres': 1190,
    'Cachorrilla': 1190,
    'Cadalso': 1190,
    'Calamonte': 1190,
    'Calera de León': 1190,
    'Calzadilla': 1190,
    'Caminomorisco': 1190,
    'Campanario': 1190,
    'Campillo de Deleitosa': 1190,
    'Campillo de Llerena': 1190,
    'Cañamero': 1190,
    'Cañaveral': 1190,
    'Capilla': 1190,
    'Carbajo': 1190,
    'Carcaboso': 1190,
    'Carmonita': 1190,
    'Carrascalejo': 1190,
    'Casar de Cáceres': 1190,
    'Casar de Palomero': 1190,
    'Casares de las Hurdes': 1190,
    'Casas de Don Antonio': 1190,
    'Casas de Don Gómez': 1190,
    'Casas de Don Pedro': 1190,
    'Casas de Millán': 1190,
    'Casas de Miravete': 1190,
    'Casas de Reina': 1190,
    'Casas del Castañar': 1190,
    'Casas del Monte': 1190,
    'Casatejada': 1190,
    'Casillas de Coria': 1190,
    'Castañar de Ibor': 1190,
    'Castilblanco': 1190,
    'Castuera': 1190,
    'Ceclavín': 1190,
    'Cedillo': 1190,
    'Cerezo': 1190,
    'Cheles': 1190,
    'Cilleros': 1190,
    'Collado': 1190,
    'Conquista de la Sierra': 1190,
    'Cordobilla de Lácara': 1190,
    'Coria': 1190,
    'Corte de Peleas': 1190,
    'Cristina': 1190,
    'Deleitosa': 1190,
    'Descargamaría': 1190,
    'Don Álvaro': 1190,
    'Don Benito': 1190,
    'El Casar': 1190,
    'Eljas': 1190,
    'Entrín Bajo': 1190,
    'Escurial': 1190,
    'Esparragalejo': 1190,
    'Esparragosa de la Serena': 1190,
    'Esparragosa de Lares': 1190,
    'Feria': 1190,
    'Fregenal de la Sierra': 1190,
    'Fresnedoso de Ibor': 1190,
    'Fuenlabrada de los Montes': 1190,
    'Fuente de Cantos': 1190,
    'Fuente del Maestre': 1190,
    'Fuentes de León': 1190,
    'Galisteo': 1190,
    'Garbayuela': 1190,
    'Garganta la Olla': 1190,
    'Gargantilla': 1190,
    'Gargüera': 1190,
    'Garlitos': 1190,
    'Garrovillas': 1190,
    'Garvín': 1190,
    'Gata': 1190,
    'Granja de Torrehermosa': 1190,
    'Guadalupe': 1190,
    'Guareña': 1190,
    'Guijo de Coria': 1190,
    'Guijo de Galisteo': 1190,
    'Guijo de Granadilla': 1190,
    'Guijo de Santa Bárbara': 1190,
    'Herguijuela': 1190,
    'Hernán-Pérez': 1190,
    'Herrera de Alcántara': 1190,
    'Herrera del Duque': 1190,
    'Hervás': 1190,
    'Higuera': 1190,
    'Higuera de la Serena': 1190,
    'Higuera de Vargas': 1190,
    'Higuera la Real': 1190,
    'Hinojal': 1190,
    'Hinojosa del Valle': 1190,
    'Holguera': 1190,
    'Hornachos': 1190,
    'Hoyos': 1190,
    'Huélaga': 1190,
    'Ibahernando': 1190,
    'Jaraicejo': 1190,
    'Jaraíz de la Vera': 1190,
    'Jarandilla de la Vera': 1190,
    'Jerez de los Caballeros': 1190,
    'Jerte': 1190,
    'La Albuera': 1190,
    'La Codosera': 1190,
    'La Coronada': 1190,
    'La Cumbre': 1190,
    'La Garrovilla': 1190,
    'La Haba': 1190,
    'La Nava de Santiago': 1190,
    'La Parra': 1190,
    'La Pesga': 1190,
    'La Roca de la Sierra': 1190,
    'Ladrillar': 1190,
    'Llera': 1190,
    'Llerena': 1190,
    'Lobón': 1190,
    'Logrosán': 1190,
    'Los Santos de Maimona': 1190,
    'Losar de la Vera': 1190,
    'Madrigal de la Vera': 1190,
    'Madrigalejo': 1190,
    'Madroñera': 1190,
    'Magacela': 1190,
    'Maguilla': 1190,
    'Majadas': 1190,
    'Malcocinado': 1190,
    'Malpartida de Cáceres': 1190,
    'Malpartida de la Serena': 1190,
    'Malpartida de Plasencia': 1190,
    'Manchita': 1190,
    'Marchagaz': 1190,
    'Mata de Alcántara': 1190,
    'Medellín': 1190,
    'Medina de las Torres': 1190,
    'Mengabril': 1190,
    'Mérida': 1190,
    'Mesas de Ibor': 1190,
    'Miajadas': 1190,
    'Millanes': 1190,
    'Mirabel': 1190,
    'Mirandilla': 1190,
    'Monesterio': 1190,
    'Montánchez': 1190,
    'Montehermoso': 1190,
    'Montemolín': 1190,
    'Monterrubio de la Serena': 1190,
    'Montijo': 1190,
    'Moraleja': 1190,
    'Morcillo': 1190,
    'Navaconcejo': 1190,
    'Navalmoral de la Mata': 1190,
    'Navalvillar de Ibor': 1190,
    'Navalvillar de Pela': 1190,
    'Navas del Madroño': 1190,
    'Navezuelas': 1190,
    'Nogales': 1190,
    'Nuñomoral': 1190,
    'Oliva de la Frontera': 1190,
    'Oliva de Mérida': 1190,
    'Oliva de Plasencia': 1190,
    'Olivenza': 1190,
    'Orellana la Vieja': 1190,
    'Palomas': 1190,
    'Palomero': 1190,
    'Pedroso de Acim': 1190,
    'Peñalsordo': 1190,
    'Peraleda de la Mata': 1190,
    'Peraleda de San Román': 1190,
    'Perales del Puerto': 1190,
    'Pescueza': 1190,
    'Piedras Albas': 1190,
    'Pinofranqueado': 1190,
    'Piornal': 1190,
    'Plasencia': 1190,
    'Plasenzuela': 1190,
    'Portaje': 1190,
    'Portezuelo': 1190,
    'Pozuelo de Zarzón': 1190,
    'Provincia de Badajoz': 1190,
    'Provincia de Cáceres': 1190,
    'Puebla de Alcocer': 1190,
    'Puebla de la Calzada': 1190,
    'Puebla de la Reina': 1190,
    'Puebla de Obando': 1190,
    'Puebla de Sancho Pérez': 1190,
    'Puebla del Maestre': 1190,
    'Puebla del Prior': 1190,
    'Puerto de Santa Cruz': 1190,
    'Quintana de la Serena': 1190,
    'Reina': 1190,
    'Rena': 1190,
    'Ribera del Fresno': 1190,
    'Ríolobos': 1190,
    'Robledillo de Gata': 1190,
    'Robledillo de la Vera': 1190,
    'Robledillo de Trujillo': 1190,
    'Robledollano': 1190,
    'Romangordo': 1190,
    'Rosalejo': 1190,
    'Ruanes': 1190,
    'Salorino': 1190,
    'Salvaleón': 1190,
    'Salvatierra de los Barros': 1190,
    'Salvatierra de Santiago': 1190,
    'San Martín de Trevejo': 1190,
    'San Pedro de Mérida': 1190,
    'San Vicente de Alcántara': 1190,
    'Santa Amalia': 1190,
    'Santa Ana': 1190,
    'Santa Cruz de la Sierra': 1190,
    'Santa Cruz de Paniagua': 1190,
    'Santa Marta': 1190,
    'Santa Marta de Magasca': 1190,
    'Santiago del Campo': 1190,
    'Santibáñez el Alto': 1190,
    'Santibáñez el Bajo': 1190,
    'Saucedilla': 1190,
    'Segura de León': 1190,
    'Segura de Toro': 1190,
    'Serradilla': 1190,
    'Serrejón': 1190,
    'Sierra de Fuentes': 1190,
    'Siruela': 1190,
    'Solana de los Barros': 1190,
    'Talarrubias': 1190,
    'Talaván': 1190,
    'Talavera La Real': 1190,
    'Talayuela': 1190,
    'Táliga': 1190,
    'Tamurejo': 1190,
    'Tejeda de Tiétar': 1190,
    'Toril': 1190,
    'Tornavacas': 1190,
    'Torre de Don Miguel': 1190,
    'Torre de Miguel Sesmero': 1190,
    'Torre de Santa María': 1190,
    'Torrecilla de los Ángeles': 1190,
    'Torrecillas de la Tiesa': 1190,
    'Torrejón el Rubio': 1190,
    'Torrejoncillo': 1190,
    'Torremayor': 1190,
    'Torremegía': 1190,
    'Torremenga': 1190,
    'Torremocha': 1190,
    'Torreorgaz': 1190,
    'Torrequemada': 1190,
    'Trasierra': 1190,
    'Trujillanos': 1190,
    'Trujillo': 1190,
    'Usagre': 1190,
    'Valdastillas': 1190,
    'Valdecaballeros': 1190,
    'Valdecañas de Tajo': 1190,
    'Valdefuentes': 1190,
    'Valdehúncar': 1190,
    'Valdelacalzada': 1190,
    'Valdelacasa de Tajo': 1190,
    'Valdemorales': 1190,
    'Valdeobispo': 1190,
    'Valdetorres': 1190,
    'Valencia de Alcántara': 1190,
    'Valencia de las Torres': 1190,
    'Valencia del Mombuey': 1190,
    'Valencia del Ventoso': 1190,
    'Valle de la Serena': 1190,
    'Valle de Matamoros': 1190,
    'Valle de Santa Ana': 1190,
    'Valverde de Burguillos': 1190,
    'Valverde de la Vera': 1190,
    'Valverde de Leganés': 1190,
    'Valverde de Llerena': 1190,
    'Valverde de Mérida': 1190,
    'Valverde del Fresno': 1190,
    'Viandar de la Vera': 1190,
    'Villa del Campo': 1190,
    'Villa del Rey': 1190,
    'Villafranca de los Barros': 1190,
    'Villagarcía de la Torre': 1190,
    'Villagonzalo': 1190,
    'Villalba de los Barros': 1190,
    'Villamesías': 1190,
    'Villamiel': 1190,
    'Villanueva de la Serena': 1190,
    'Villanueva de la Sierra': 1190,
    'Villanueva de la Vera': 1190,
    'Villanueva del Fresno': 1190,
    'Villar de Plasencia': 1190,
    'Villar de Rena': 1190,
    'Villar del Pedroso': 1190,
    'Villar del Rey': 1190,
    'Villarta de los Montes': 1190,
    'Villasbuenas de Gata': 1190,
    'Zafra': 1190,
    'Zahinos': 1190,
    'Zalamea de la Serena': 1190,
    'Zarza de Alange': 1190,
    'Zarza de Granadilla': 1190,
    'Zarza de Montánchez': 1190,
    'Zarza la Mayor': 1190,
    'Zorita': 1190,
    'A Baña': 1167,
    'A Coruña': 1167,
    'A Estrada': 1167,
    'A Pobra do Brollon': 1167,
    'A Pobra do Caramiñal': 1167,
    'A Rúa': 1167,
    'Abadín': 1167,
    'Abegondo': 1167,
    'Allariz': 1167,
    'Ambía': 1167,
    'Amés': 1167,
    'Amoeiro': 1167,
    'Aranga': 1167,
    'Arbo': 1167,
    'Ares': 1167,
    'Arteixo': 1167,
    'Arzúa': 1167,
    'As Pontes de García Rodríguez': 1167,
    'Avión': 1167,
    'Baiona': 1167,
    'Baltar': 1167,
    'Bande': 1167,
    'Baños de Molgas': 1167,
    'Baralla': 1167,
    'Barreiros': 1167,
    'Beade': 1167,
    'Becerreá': 1167,
    'Begonte': 1167,
    'Bergondo': 1167,
    'Betanzos': 1167,
    'Blancos': 1167,
    'Boborás': 1167,
    'Boimorto': 1167,
    'Boiro': 1167,
    'Bueu': 1167,
    'Burela de Cabo': 1167,
    'Caldas de Reis': 1167,
    'Calvos': 1167,
    'Camariñas': 1167,
    'Cambados': 1167,
    'Cambre': 1167,
    'Cangas do Morrazo': 1167,
    'Carballeda de Avia': 1167,
    'Carballo': 1167,
    'Cariño': 1167,
    'Carnota': 1167,
    'Carral': 1167,
    'Cartelle': 1167,
    'Castrelo de Miño': 1167,
    'Castro': 1167,
    'Castro Caldelas': 1167,
    'Castro de Rei': 1167,
    'Castroverde': 1167,
    'Catoira': 1167,
    'Cea': 1167,
    'Cedeira': 1167,
    'Cee': 1167,
    'Celanova': 1167,
    'Cenlle': 1167,
    'Cerdido': 1167,
    'Cervo': 1167,
    'Cesuras': 1167,
    'Chantada': 1167,
    'Coirós': 1167,
    'Coles': 1167,
    'Combarro': 1167,
    'Corcubión': 1167,
    'Corgo': 1167,
    'Coristanco': 1167,
    'Covelo': 1167,
    'Cualedro': 1167,
    'Culleredo': 1167,
    'Cuntis': 1167,
    'Curtis': 1167,
    'Dozón': 1167,
    'Dumbría': 1167,
    'Entrimo': 1167,
    'Esgos': 1167,
    'Fene': 1167,
    'Ferrol': 1167,
    'Fisterra': 1167,
    'Fonsagrada': 1167,
    'Fornelos de Montes': 1167,
    'Foz': 1167,
    'Friol': 1167,
    'Gondomar': 1167,
    'Guitiriz': 1167,
    'Illa de Arousa': 1167,
    'Irixoa': 1167,
    'Lalín': 1167,
    'Lama': 1167,
    'Láncara': 1167,
    'Laracha': 1167,
    'Laza': 1167,
    'Lobios': 1167,
    'Lousame': 1167,
    'Lugo': 1167,
    'Luintra': 1167,
    'Maceda': 1167,
    'Malpica': 1167,
    'Manzaneda': 1167,
    'Marín': 1167,
    'Maside': 1167,
    'Mazaricos': 1167,
    'Meaño': 1167,
    'Meira': 1167,
    'Meis': 1167,
    'Melón': 1167,
    'Mesia': 1167,
    'Miño': 1167,
    'Moaña': 1167,
    'Moeche': 1167,
    'Mondariz': 1167,
    'Mondariz-Balneario': 1167,
    'Mondoñedo': 1167,
    'Monfero': 1167,
    'Monforte de Lemos': 1167,
    'Montederramo': 1167,
    'Monterroso': 1167,
    'Moraña': 1167,
    'Mos': 1167,
    'Mugardos': 1167,
    'Mugia': 1167,
    'Muiños': 1167,
    'Muras': 1167,
    'Muros': 1167,
    'Narón': 1167,
    'Navia de Suarna': 1167,
    'Negreira': 1167,
    'Nigrán': 1167,
    'Nogueira de Ramuín': 1167,
    'O Barco de Valdeorras': 1167,
    'O Carballiño': 1167,
    'O Grove': 1167,
    'O Incio': 1167,
    'O Páramo': 1167,
    'O Rosal': 1167,
    'Oia': 1167,
    'Oimbra': 1167,
    'Oleiros': 1167,
    'Ordes': 1167,
    'Oroso': 1167,
    'Ourense': 1167,
    'Ourol': 1167,
    'Outeiro': 1167,
    'Outeiro de Rei': 1167,
    'Outes': 1167,
    'Padrenda': 1167,
    'Padrón': 1167,
    'Palmeira': 1167,
    'Pantón': 1167,
    'Paradela': 1167,
    'Pastoriza': 1167,
    'Pereiro de Aguiar': 1167,
    'Petín': 1167,
    'Piñor': 1167,
    'Pobra de Trives': 1167,
    'Poio': 1167,
    'Ponteareas': 1167,
    'Pontevedra': 1167,
    'Porqueira': 1167,
    'Porriño': 1167,
    'Portas': 1167,
    'Porto do Son': 1167,
    'Portomarín': 1167,
    'Portonovo': 1167,
    'Provincia da Coruña': 1167,
    'Provincia de Lugo': 1167,
    'Provincia de Ourense': 1167,
    'Provincia de Pontevedra': 1167,
    'Puente Nuevo': 1167,
    'Quintela de Leirado': 1167,
    'Quiroga': 1167,
    'Rábade': 1167,
    'Rairiz de Veiga': 1167,
    'Redondela': 1167,
    'Rianxo': 1167,
    'Ribadavia': 1167,
    'Ribadeo': 1167,
    'Ribadumia': 1167,
    'Ribeira': 1167,
    'Riós': 1167,
    'Ríotorto': 1167,
    'Rodeiro': 1167,
    'Sada': 1167,
    'Salvatierra de Miño': 1167,
    'Samos': 1167,
    'San Amaro': 1167,
    'San Román': 1167,
    'Santa Comba': 1167,
    'Santa Marta de Ortigueira': 1167,
    'Santa Uxía de Ribeira': 1167,
    'Santiago de Compostela': 1167,
    'Santiso': 1167,
    'Sanxenxo': 1167,
    'Sarreaus': 1167,
    'Sarria': 1167,
    'Silleda': 1167,
    'Sober': 1167,
    'Sobradelo': 1167,
    'Sobrado': 1167,
    'Somozas': 1167,
    'Taboada': 1167,
    'Taboadela': 1167,
    'Teo': 1167,
    'Toén': 1167,
    'Tomiño': 1167,
    'Touro': 1167,
    'Trabada': 1167,
    'Trasmiras': 1167,
    'Trazo': 1167,
    'Tui': 1167,
    'Valdoviño': 1167,
    'Valga': 1167,
    'Vedra': 1167,
    'Verea': 1167,
    'Verín': 1167,
    'Vigo': 1167,
    'Vilagarcía de Arousa': 1167,
    'Vilalba': 1167,
    'Vilanova de Arousa': 1167,
    'Vilasantar': 1167,
    'Vilaxoán': 1167,
    'Vimianzo': 1167,
    'Viveiro': 1167,
    'Xinzo de Limia': 1167,
    'Zas': 1167,
    'Ábalos': 1171,
    'Agoncillo': 1171,
    'Aguilar del Río Alhama': 1171,
    'Ajamil': 1171,
    'Albelda de Iregua': 1171,
    'Alberite': 1171,
    'Alcanadre': 1171,
    'Aldeanueva de Ebro': 1171,
    'Alesanco': 1171,
    'Alesón': 1171,
    'Alfaro': 1171,
    'Almarza de Cameros': 1171,
    'Anguciana': 1171,
    'Anguiano': 1171,
    'Arenzana de Abajo': 1171,
    'Arenzana de Arriba': 1171,
    'Arnedillo': 1171,
    'Arnedo': 1171,
    'Arrúbal': 1171,
    'Ausejo': 1171,
    'Autol': 1171,
    'Azofra': 1171,
    'Badarán': 1171,
    'Bañares': 1171,
    'Baños de Río Tobía': 1171,
    'Baños de Rioja': 1171,
    'Berceo': 1171,
    'Bergasa': 1171,
    'Bergasillas Bajera': 1171,
    'Bobadilla': 1171,
    'Brieva de Cameros': 1171,
    'Briñas': 1171,
    'Briones': 1171,
    'Cabezón de Cameros': 1171,
    'Calahorra': 1171,
    'Canales de la Sierra': 1171,
    'Cañas': 1171,
    'Canillas de Río Tuerto': 1171,
    'Cárdenas': 1171,
    'Casalarreina': 1171,
    'Castañares de Rioja': 1171,
    'Cellorigo': 1171,
    'Cenicero': 1171,
    'Cervera del Río Alhama': 1171,
    'Cidamón': 1171,
    'Cihuri': 1171,
    'Cirueña': 1171,
    'Clavijo': 1171,
    'Cordovín': 1171,
    'Corera': 1171,
    'Cornago': 1171,
    'Corporales': 1171,
    'Enciso': 1171,
    'Entrena': 1171,
    'Estollo': 1171,
    'Ezcaray': 1171,
    'Foncea': 1171,
    'Fonzaleche': 1171,
    'Fuenmayor': 1171,
    'Galbárruli': 1171,
    'Galilea': 1171,
    'Gallinero de Cameros': 1171,
    'Gimileo': 1171,
    'Grañón': 1171,
    'Grávalos': 1171,
    'Haro': 1171,
    'Herce': 1171,
    'Herramélluri': 1171,
    'Hervías': 1171,
    'Hormilla': 1171,
    'Hormilleja': 1171,
    'Hornillos de Cameros': 1171,
    'Hornos de Moncalvillo': 1171,
    'Huércanos': 1171,
    'Igea': 1171,
    'Jalón de Cameros': 1171,
    'Laguna de Cameros': 1171,
    'Lagunilla del Jubera': 1171,
    'Lardero': 1171,
    'Ledesma de la Cogolla': 1171,
    'Leiva': 1171,
    'Leza de Río Leza': 1171,
    'Logroño': 1171,
    'Lumbreras': 1171,
    'Manjarrés': 1171,
    'Manzanares de Rioja': 1171,
    'Matute': 1171,
    'Medrano': 1171,
    'Munilla': 1171,
    'Murillo de Río Leza': 1171,
    'Muro de Aguas': 1171,
    'Muro en Cameros': 1171,
    'Nájera': 1171,
    'Nalda': 1171,
    'Navajún': 1171,
    'Nieva de Cameros': 1171,
    'Ochánduri': 1171,
    'Ocón': 1171,
    'Ojacastro': 1171,
    'Ollauri': 1171,
    'Pazuengos': 1171,
    'Pedroso': 1171,
    'Pinillos': 1171,
    'Pradejón': 1171,
    'Pradillo': 1171,
    'Préjano': 1171,
    'Provincia de La Rioja': 1171,
    'Quel': 1171,
    'Rabanera': 1171,
    'Rincón de Soto': 1171,
    'Robres del Castillo': 1171,
    'Rodezno': 1171,
    'Sajazarra': 1171,
    'San Asensio': 1171,
    'San Millán de la Cogolla': 1171,
    'San Millán de Yécora': 1171,
    'San Román de Cameros': 1171,
    'San Torcuato': 1171,
    'San Vicente de la Sonsierra': 1171,
    'Santa Coloma': 1171,
    'Santa Eulalia Bajera': 1171,
    'Santo Domingo de la Calzada': 1171,
    'Santurdejo': 1171,
    'Sojuela': 1171,
    'Sorzano': 1171,
    'Soto en Cameros': 1171,
    'Terroba': 1171,
    'Tirgo': 1171,
    'Tobía': 1171,
    'Tormantos': 1171,
    'Torre en Cameros': 1171,
    'Torrecilla en Cameros': 1171,
    'Torrecilla sobre Alesanco': 1171,
    'Torremontalbo': 1171,
    'Treviana': 1171,
    'Tricio': 1171,
    'Tudelilla': 1171,
    'Uruñuela': 1171,
    'Valdemadera': 1171,
    'Valgañón': 1171,
    'Ventosa': 1171,
    'Ventrosa': 1171,
    'Viguera': 1171,
    'Villalba de Rioja': 1171,
    'Villalobar de Rioja': 1171,
    'Villamediana de Iregua': 1171,
    'Villanueva de Cameros': 1171,
    'Villar de Torre': 1171,
    'Villarejo': 1171,
    'Villarroya': 1171,
    'Villarta-Quintana': 1171,
    'Villavelayo': 1171,
    'Villaverde de Rioja': 1171,
    'Villoslada de Cameros': 1171,
    'Viniegra de Abajo': 1171,
    'Viniegra de Arriba': 1171,
    'Zarratón': 1171,
    'Zarzosa': 1171,
    'Zorraquín': 1171,
    'Abades': 1200,
    'Abajas': 1200,
    'Abejar': 1200,
    'Abezames': 1200,
    'Abia de las Torres': 1200,
    'Abusejo': 1200,
    'Acebedo': 1200,
    'Adalia': 1200,
    'Adanero': 1200,
    'Adrada de Haza': 1200,
    'Adrada de Pirón': 1200,
    'Adradas': 1200,
    'Adrados': 1200,
    'Agallas': 1200,
    'Ágreda': 1200,
    'Aguasal': 1200,
    'Aguilafuente': 1200,
    'Aguilar de Bureba': 1200,
    'Aguilar de Campoo': 1200,
    'Aguilar de Campos': 1200,
    'Ahigal de los Aceiteros': 1200,
    'Ahigal de Villarino': 1200,
    'Alaejos': 1200,
    'Alar del Rey': 1200,
    'Alaraz': 1200,
    'Alba de Cerrato': 1200,
    'Alba de Tormes': 1200,
    'Alba de Yeltes': 1200,
    'Albillos': 1200,
    'Albornos': 1200,
    'Alcañices': 1200,
    'Alcazarén': 1200,
    'Alcocero de Mola': 1200,
    'Alconaba': 1200,
    'Alconada': 1200,
    'Alconada de Maderuelo': 1200,
    'Alcubilla de Avellaneda': 1200,
    'Alcubilla de las Peñas': 1200,
    'Alcubilla de Nogales': 1200,
    'Aldea de San Miguel': 1200,
    'Aldea del Obispo': 1200,
    'Aldea Real': 1200,
    'Aldeacipreste': 1200,
    'Aldeadávila de la Ribera': 1200,
    'Aldealafuente': 1200,
    'Aldealcorvo': 1200,
    'Aldealengua': 1200,
    'Aldealengua de Santa María': 1200,
    'Aldealpozo': 1200,
    'Aldealseñor': 1200,
    'Aldeamayor de San Martín': 1200,
    'Aldeanueva de Figueroa': 1200,
    'Aldeanueva de la Sierra': 1200,
    'Aldeanueva de Santa Cruz': 1200,
    'Aldeanueva del Codonal': 1200,
    'Aldearrodrigo': 1200,
    'Aldearrubia': 1200,
    'Aldeaseca': 1200,
    'Aldeaseca de Alba': 1200,
    'Aldeaseca de la Frontera': 1200,
    'Aldeasoña': 1200,
    'Aldeatejada': 1200,
    'Aldeavieja de Tormes': 1200,
    'Aldehuela de la Bóveda': 1200,
    'Aldehuela de Yeltes': 1200,
    'Aldehuela del Codonal': 1200,
    'Aldeonte': 1200,
    'Alentisque': 1200,
    'Algadefe': 1200,
    'Algodre': 1200,
    'Aliud': 1200,
    'Almajano': 1200,
    'Almaluez': 1200,
    'Almanza': 1200,
    'Almaraz de Duero': 1200,
    'Almarza': 1200,
    'Almazán': 1200,
    'Almazul': 1200,
    'Almenar de Soria': 1200,
    'Almenara de Adaja': 1200,
    'Almenara de Tormes': 1200,
    'Almendra': 1200,
    'Alpanseque': 1200,
    'Altable': 1200,
    'Amavida': 1200,
    'Amayuelas de Arriba': 1200,
    'Ameyugo': 1200,
    'Ampudia': 1200,
    'Amusco': 1200,
    'Amusquillo': 1200,
    'Anaya': 1200,
    'Anaya de Alba': 1200,
    'Añe': 1200,
    'Anguix': 1200,
    'Añover de Tormes': 1200,
    'Antigüedad': 1200,
    'Arahuetes': 1200,
    'Arancón': 1200,
    'Aranda de Duero': 1200,
    'Arandilla': 1200,
    'Arapiles': 1200,
    'Arauzo de Miel': 1200,
    'Arauzo de Salce': 1200,
    'Arauzo de Torre': 1200,
    'Arcediano': 1200,
    'Arcenillas': 1200,
    'Arconada': 1200,
    'Arcones': 1200,
    'Arcos': 1200,
    'Arcos de Jalón': 1200,
    'Arcos de la Polvorosa': 1200,
    'Ardón': 1200,
    'Arenas de San Pedro': 1200,
    'Arenillas': 1200,
    'Arevalillo de Cega': 1200,
    'Arévalo': 1200,
    'Arévalo de la Sierra': 1200,
    'Argañín': 1200,
    'Arganza': 1200,
    'Argujillo': 1200,
    'Arija': 1200,
    'Arlanzón': 1200,
    'Armenteros': 1200,
    'Armuña': 1200,
    'Arquillinos': 1200,
    'Arrabalde': 1200,
    'Arraya de Oca': 1200,
    'Arroyo de la Encomienda': 1200,
    'Aspariegos': 1200,
    'Astorga': 1200,
    'Astudillo': 1200,
    'Asturianos': 1200,
    'Atapuerca': 1200,
    'Ataquines': 1200,
    'Ausejo de la Sierra': 1200,
    'Autilla del Pino': 1200,
    'Autillo de Campos': 1200,
    'Aveinte': 1200,
    'Avellaneda': 1200,
    'Avellanosa de Muñó': 1200,
    'Ávila': 1200,
    'Ayllón': 1200,
    'Ayoó de Vidriales': 1200,
    'Ayuela': 1200,
    'Babilafuente': 1200,
    'Bahabón': 1200,
    'Bahabón de Esgueva': 1200,
    'Balboa': 1200,
    'Baltanás': 1200,
    'Bañobárez': 1200,
    'Baños de Valdearados': 1200,
    'Bañuelos de Bureba': 1200,
    'Baquerín de Campos': 1200,
    'Barbadillo de Herreros': 1200,
    'Barbadillo del Mercado': 1200,
    'Barbadillo del Pez': 1200,
    'Barbalos': 1200,
    'Barbolla': 1200,
    'Barca': 1200,
    'Bárcena de Campos': 1200,
    'Barceo': 1200,
    'Barcial de la Loma': 1200,
    'Barcial del Barco': 1200,
    'Barcones': 1200,
    'Barjas': 1200,
    'Barraco': 1200,
    'Barrio de Muñó': 1200,
    'Barrios de Colina': 1200,
    'Barromán': 1200,
    'Barruecopardo': 1200,
    'Barruelo de Santullán': 1200,
    'Basardilla': 1200,
    'Basconcillos del Tozo': 1200,
    'Báscones de Ojeda': 1200,
    'Bascuñana': 1200,
    'Bayubas de Abajo': 1200,
    'Bayubas de Arriba': 1200,
    'Becedas': 1200,
    'Becedillas': 1200,
    'Becerril de Campos': 1200,
    'Becilla de Valderaduey': 1200,
    'Béjar': 1200,
    'Belbimbre': 1200,
    'Beleña': 1200,
    'Belmonte de Campos': 1200,
    'Belorado': 1200,
    'Belver de los Montes': 1200,
    'Bembibre': 1200,
    'Benafarces': 1200,
    'Benavente': 1200,
    'Benavides': 1200,
    'Benegiles': 1200,
    'Benuza': 1200,
    'Beratón': 1200,
    'Berberana': 1200,
    'Bercero': 1200,
    'Berceruelo': 1200,
    'Bercial': 1200,
    'Bercial de Zapardiel': 1200,
    'Bercianos del Páramo': 1200,
    'Bercianos del Real Camino': 1200,
    'Bercimuel': 1200,
    'Berlanga de Duero': 1200,
    'Berlanga del Bierzo': 1200,
    'Berlangas de Roa': 1200,
    'Bermellar': 1200,
    'Bermillo de Sayago': 1200,
    'Bernardos': 1200,
    'Berrocal de Huebra': 1200,
    'Berrocal de Salvatierra': 1200,
    'Berrocalejo de Aragona': 1200,
    'Berrueces': 1200,
    'Berzosa de Bureba': 1200,
    'Berzosilla': 1200,
    'Blacos': 1200,
    'Blascomillán': 1200,
    'Blascosancho': 1200,
    'Bliecos': 1200,
    'Boada': 1200,
    'Boada de Campos': 1200,
    'Boadilla del Camino': 1200,
    'Bobadilla del Campo': 1200,
    'Boca de Huérgano': 1200,
    'Boceguillas': 1200,
    'Bocigas': 1200,
    'Bocos de Duero': 1200,
    'Boecillo': 1200,
    'Bogajo': 1200,
    'Bohoyo': 1200,
    'Bolaños de Campos': 1200,
    'Boñar': 1200,
    'Bonilla de la Sierra': 1200,
    'Borjabad': 1200,
    'Borobia': 1200,
    'Borrenes': 1200,
    'Bóveda del Río Almar': 1200,
    'Brabos': 1200,
    'Brañosera': 1200,
    'Brazacorta': 1200,
    'Brazuelo': 1200,
    'Bretó': 1200,
    'Bretocino': 1200,
    'Brieva': 1200,
    'Brime de Sog': 1200,
    'Brime de Urz': 1200,
    'Brincones': 1200,
    'Briviesca': 1200,
    'Buberos': 1200,
    'Buenamadre': 1200,
    'Buenavista': 1200,
    'Buenavista de Valdavia': 1200,
    'Bugedo': 1200,
    'Buitrago': 1200,
    'Buniel': 1200,
    'Burganes de Valverde': 1200,
    'Burgohondo': 1200,
    'Burgos': 1200,
    'Burón': 1200,
    'Bustillo de Chaves': 1200,
    'Bustillo de la Vega': 1200,
    'Bustillo del Oro': 1200,
    'Bustillo del Páramo': 1200,
    'Bustillo del Páramo de Carrión': 1200,
    'Busto de Bureba': 1200,
    'Caballar': 1200,
    'Cabañas de Polendos': 1200,
    'Cabañas de Sayago': 1200,
    'Cabañes de Esgueva': 1200,
    'Cabeza del Caballo': 1200,
    'Cabezabellosa de la Calzada': 1200,
    'Cabezas de Alambre': 1200,
    'Cabezas del Pozo': 1200,
    'Cabezas del Villar': 1200,
    'Cabezón': 1200,
    'Cabezón de la Sierra': 1200,
    'Cabezón de Valderaduey': 1200,
    'Cabezuela': 1200,
    'Cabizuela': 1200,
    'Cabrejas del Campo': 1200,
    'Cabrejas del Pinar': 1200,
    'Cabrerizos': 1200,
    'Cabreros del Monte': 1200,
    'Cabreros del Río': 1200,
    'Cabrillanes': 1200,
    'Cabrillas': 1200,
    'Cacabelos': 1200,
    'Calahorra de Boedo': 1200,
    'Calatañazor': 1200,
    'Caleruega': 1200,
    'Caltojar': 1200,
    'Calvarrasa de Abajo': 1200,
    'Calvarrasa de Arriba': 1200,
    'Calzada de Don Diego': 1200,
    'Calzada de los Molinos': 1200,
    'Calzada de Valdunciel': 1200,
    'Calzada del Coto': 1200,
    'Calzadilla de Tera': 1200,
    'Camarzana de Tera': 1200,
    'Campaspero': 1200,
    'Campazas': 1200,
    'Campillo de Aranda': 1200,
    'Campillo de Azaba': 1200,
    'Campo de San Pedro': 1200,
    'Campo de Villavidel': 1200,
    'Campolara': 1200,
    'Camponaraya': 1200,
    'Camporredondo': 1200,
    'Canalejas de Peñafiel': 1200,
    'Canales': 1200,
    'Cañamaque': 1200,
    'Candelario': 1200,
    'Candeleda': 1200,
    'Candilichera': 1200,
    'Candín': 1200,
    'Canicosa de la Sierra': 1200,
    'Canillas de Abajo': 1200,
    'Canillas de Esgueva': 1200,
    'Cañizal': 1200,
    'Cañizo': 1200,
    'Cantabrana': 1200,
    'Cantagallo': 1200,
    'Cantalapiedra': 1200,
    'Cantalejo': 1200,
    'Cantalpino': 1200,
    'Cantaracillo': 1200,
    'Cantimpalos': 1200,
    'Cantiveros': 1200,
    'Capillas': 1200,
    'Carabantes': 1200,
    'Caracena': 1200,
    'Carazo': 1200,
    'Carbajales de Alba': 1200,
    'Carbajosa de la Sagrada': 1200,
    'Carbellino': 1200,
    'Carbonero el Mayor': 1200,
    'Carcedo de Bureba': 1200,
    'Carcedo de Burgos': 1200,
    'Cardeñadijo': 1200,
    'Cardeñajimeno': 1200,
    'Cardeñosa': 1200,
    'Cardeñosa de Volpejera': 1200,
    'Cármenes': 1200,
    'Carpio': 1200,
    'Carpio de Azaba': 1200,
    'Carracedelo': 1200,
    'Carrascal de Barregas': 1200,
    'Carrascal del Obispo': 1200,
    'Carrascosa de Abajo': 1200,
    'Carrascosa de la Sierra': 1200,
    'Carrias': 1200,
    'Carrión de los Condes': 1200,
    'Carrizo de la Ribera': 1200,
    'Carrocera': 1200,
    'Carucedo': 1200,
    'Casafranca': 1200,
    'Casarejos': 1200,
    'Casaseca de Campeán': 1200,
    'Casaseca de las Chanas': 1200,
    'Casasola de Arión': 1200,
    'Casavieja': 1200,
    'Cascajares de Bureba': 1200,
    'Cascajares de la Sierra': 1200,
    'Casillas': 1200,
    'Casillas de Flores': 1200,
    'Casla': 1200,
    'Castellanos de Castro': 1200,
    'Castellanos de Moriscos': 1200,
    'Castellanos de Villiquera': 1200,
    'Castellanos de Zapardiel': 1200,
    'Castil de Peones': 1200,
    'Castil de Vela': 1200,
    'Castildelgado': 1200,
    'Castilfalé': 1200,
    'Castilfrío de la Sierra': 1200,
    'Castillejo de Martín Viejo': 1200,
    'Castillejo de Mesleón': 1200,
    'Castillejo de Robledo': 1200,
    'Castilruiz': 1200,
    'Castraz': 1200,
    'Castrejón de la Peña': 1200,
    'Castrillo de Cabrera': 1200,
    'Castrillo de Don Juan': 1200,
    'Castrillo de Duero': 1200,
    'Castrillo de la Guareña': 1200,
    'Castrillo de la Reina': 1200,
    'Castrillo de la Valduerna': 1200,
    'Castrillo de la Vega': 1200,
    'Castrillo de Onielo': 1200,
    'Castrillo de Villavega': 1200,
    'Castrillo del Val': 1200,
    'Castrillo-Tejeriego': 1200,
    'Castro de Fuentidueña': 1200,
    'Castrobol': 1200,
    'Castrocalbón': 1200,
    'Castrocontrigo': 1200,
    'Castrodeza': 1200,
    'Castrogonzalo': 1200,
    'Castrojimeno': 1200,
    'Castromembibre': 1200,
    'Castromocho': 1200,
    'Castromonte': 1200,
    'Castronuevo': 1200,
    'Castronuevo de Esgueva': 1200,
    'Castronuño': 1200,
    'Castropodame': 1200,
    'Castroponce': 1200,
    'Castroserna de Abajo': 1200,
    'Castroserracín': 1200,
    'Castroverde de Campos': 1200,
    'Castroverde de Cerrato': 1200,
    'Cayuela': 1200,
    'Cazurra': 1200,
    'Cebanico': 1200,
    'Cebrecos': 1200,
    'Cebreros': 1200,
    'Cebrones del Río': 1200,
    'Cedillo de la Torre': 1200,
    'Ceinos de Campos': 1200,
    'Celada del Camino': 1200,
    'Centenera de Andaluz': 1200,
    'Cepeda': 1200,
    'Cepeda la Mora': 1200,
    'Cerbón': 1200,
    'Cereceda de la Sierra': 1200,
    'Cerecinos de Campos': 1200,
    'Cerecinos del Carrizal': 1200,
    'Cerezal de Peñahorcada': 1200,
    'Cerezo de Abajo': 1200,
    'Cerezo de Arriba': 1200,
    'Cernadilla': 1200,
    'Cerralbo': 1200,
    'Cerratón de Juarros': 1200,
    'Cervatos de la Cueza': 1200,
    'Cervera de Pisuerga': 1200,
    'Cervillego de la Cruz': 1200,
    'Cevico de la Torre': 1200,
    'Cevico Navero': 1200,
    'Chamartín': 1200,
    'Chañe': 1200,
    'Chozas de Abajo': 1200,
    'Ciadoncha': 1200,
    'Cidones': 1200,
    'Cigales': 1200,
    'Cigudosa': 1200,
    'Ciguñuela': 1200,
    'Cihuela': 1200,
    'Cillán': 1200,
    'Cilleros de la Bastida': 1200,
    'Cilleruelo de Abajo': 1200,
    'Cilleruelo de Arriba': 1200,
    'Cilleruelo de San Mamés': 1200,
    'Cimanes de la Vega': 1200,
    'Cimanes del Tejar': 1200,
    'Cipérez': 1200,
    'Ciria': 1200,
    'Ciruelos de Cervera': 1200,
    'Cirujales del Río': 1200,
    'Cisla': 1200,
    'Cisneros': 1200,
    'Cistérniga': 1200,
    'Cistierna': 1200,
    'Ciudad Rodrigo': 1200,
    'Cobos de Cerrato': 1200,
    'Cobos de Fuentidueña': 1200,
    'Cobreros': 1200,
    'Coca': 1200,
    'Coca de Alba': 1200,
    'Codorniz': 1200,
    'Cogeces del Monte': 1200,
    'Cogollos': 1200,
    'Collado de Contreras': 1200,
    'Collado del Mirón': 1200,
    'Collado Hermoso': 1200,
    'Collazos de Boedo': 1200,
    'Colmenar de Montemayor': 1200,
    'Congosto': 1200,
    'Congosto de Valdavia': 1200,
    'Constanzana': 1200,
    'Contreras': 1200,
    'Coomonte': 1200,
    'Corbillos de los Oteros': 1200,
    'Corcos': 1200,
    'Cordovilla': 1200,
    'Cordovilla la Real': 1200,
    'Coreses': 1200,
    'Corral de Ayllón': 1200,
    'Corrales': 1200,
    'Corrales de Duero': 1200,
    'Corullón': 1200,
    'Coruña del Conde': 1200,
    'Coscurita': 1200,
    'Covaleda': 1200,
    'Covarrubias': 1200,
    'Cozuelos de Fuentidueña': 1200,
    'Crémenes': 1200,
    'Crespos': 1200,
    'Cristóbal': 1200,
    'Cuadros': 1200,
    'Cubilla': 1200,
    'Cubillas de Cerrato': 1200,
    'Cubillas de los Oteros': 1200,
    'Cubillas de Rueda': 1200,
    'Cubillas de Santa Marta': 1200,
    'Cubillo': 1200,
    'Cubillo del Campo': 1200,
    'Cubillos': 1200,
    'Cubillos del Sil': 1200,
    'Cubo de Benavente': 1200,
    'Cubo de Bureba': 1200,
    'Cubo de la Solana': 1200,
    'Cuelgamures': 1200,
    'Cuéllar': 1200,
    'Cuenca de Campos': 1200,
    'Cueva de Ágreda': 1200,
    'Cuevas de Provanco': 1200,
    'Cuevas de San Clemente': 1200,
    'Cuevas del Valle': 1200,
    'Curiel de Duero': 1200,
    'Dehesa de Montejo': 1200,
    'Dehesa de Romanos': 1200,
    'Destriana': 1200,
    'Deza': 1200,
    'Dios le Guarde': 1200,
    'Domingo García': 1200,
    'Donhierro': 1200,
    'Doñinos de Ledesma': 1200,
    'Doñinos de Salamanca': 1200,
    'Donjimeno': 1200,
    'Donvidas': 1200,
    'Dueñas': 1200,
    'Duruelo': 1200,
    'Duruelo de la Sierra': 1200,
    'Ejeme': 1200,
    'El Barco de Ávila': 1200,
    'El Burgo de Osma': 1200,
    'El Hoyo de Pinares': 1200,
    'El Tiemblo': 1200,
    'Encina de San Silvestre': 1200,
    'Encinas': 1200,
    'Encinas de Abajo': 1200,
    'Encinas de Arriba': 1200,
    'Encinas de Esgueva': 1200,
    'Encinasola de los Comendadores': 1200,
    'Encinedo': 1200,
    'Encinillas': 1200,
    'Encío': 1200,
    'Endrinal': 1200,
    'Entrala': 1200,
    'Escalona del Prado': 1200,
    'Escarabajosa de Cabezas': 1200,
    'Escobar de Campos': 1200,
    'Escobar de Polendos': 1200,
    'Escobosa de Almazán': 1200,
    'Escurial de la Sierra': 1200,
    'Esguevillas de Esgueva': 1200,
    'Espadaña': 1200,
    'Espadañedo': 1200,
    'Espeja': 1200,
    'Espeja de San Marcelino': 1200,
    'Espejón': 1200,
    'Espino de la Orbada': 1200,
    'Espinosa de Cerrato': 1200,
    'Espinosa de Cervera': 1200,
    'Espinosa de los Monteros': 1200,
    'Espinosa de Villagonzalo': 1200,
    'Espinosa del Camino': 1200,
    'Espirdo': 1200,
    'Estepa de San Juan': 1200,
    'Estépar': 1200,
    'Fabero': 1200,
    'Faramontanos de Tábara': 1200,
    'Fariza': 1200,
    'Fermoselle': 1200,
    'Ferreras de Abajo': 1200,
    'Ferreras de Arriba': 1200,
    'Ferreruela': 1200,
    'Figueruela de Arriba': 1200,
    'Flores de Ávila': 1200,
    'Florida de Liébana': 1200,
    'Folgoso de la Ribera': 1200,
    'Fombellida': 1200,
    'Fompedraza': 1200,
    'Fonfría': 1200,
    'Fontihoyuelo': 1200,
    'Fontioso': 1200,
    'Fontiveros': 1200,
    'Forfoleda': 1200,
    'Frades de la Sierra': 1200,
    'Frandovínez': 1200,
    'Frechilla': 1200,
    'Frechilla de Almazán': 1200,
    'Fresneda de Cuéllar': 1200,
    'Fresneda de la Sierra Tirón': 1200,
    'Fresnedilla': 1200,
    'Fresnedoso': 1200,
    'Fresneña': 1200,
    'Fresnillo de las Dueñas': 1200,
    'Fresno de Cantespino': 1200,
    'Fresno de Caracena': 1200,
    'Fresno de la Fuente': 1200,
    'Fresno de la Polvorosa': 1200,
    'Fresno de la Ribera': 1200,
    'Fresno de la Vega': 1200,
    'Fresno de Rodilla': 1200,
    'Fresno de Sayago': 1200,
    'Fresno del Río': 1200,
    'Fresno El Viejo': 1200,
    'Frías': 1200,
    'Friera de Valverde': 1200,
    'Frómista': 1200,
    'Frumales': 1200,
    'Fuensaldaña': 1200,
    'Fuente de Santa Cruz': 1200,
    'Fuente el Olmo de Fuentidueña': 1200,
    'Fuente el Sol': 1200,
    'Fuente Encalada': 1200,
    'Fuentearmegil': 1200,
    'Fuentebureba': 1200,
    'Fuentecambrón': 1200,
    'Fuentecantos': 1200,
    'Fuentecén': 1200,
    'Fuenteguinaldo': 1200,
    'Fuentelapeña': 1200,
    'Fuentelcésped': 1200,
    'Fuentelisendo': 1200,
    'Fuentelsaz de Soria': 1200,
    'Fuentemolinos': 1200,
    'Fuentenebro': 1200,
    'Fuentepelayo': 1200,
    'Fuentepiñel': 1200,
    'Fuentepinilla': 1200,
    'Fuenterroble de Salvatierra': 1200,
    'Fuentes de Año': 1200,
    'Fuentes de Béjar': 1200,
    'Fuentes de Carbajal': 1200,
    'Fuentes de Magaña': 1200,
    'Fuentes de Nava': 1200,
    'Fuentes de Oñoro': 1200,
    'Fuentes de Ropel': 1200,
    'Fuentes de Valdepero': 1200,
    'Fuentesaúco': 1200,
    'Fuentesaúco de Fuentidueña': 1200,
    'Fuentesecas': 1200,
    'Fuentesoto': 1200,
    'Fuentespina': 1200,
    'Fuentespreadas': 1200,
    'Fuentestrún': 1200,
    'Fuentidueña': 1200,
    'Gajates': 1200,
    'Galbarros': 1200,
    'Galende': 1200,
    'Galindo y Perahuy': 1200,
    'Galinduste': 1200,
    'Galisancho': 1200,
    'Gallegos': 1200,
    'Gallegos de Argañán': 1200,
    'Gallegos de Hornija': 1200,
    'Gallegos de Sobrinos': 1200,
    'Gallegos del Pan': 1200,
    'Gallegos del Río': 1200,
    'Gamonal': 1200,
    'Gamones': 1200,
    'Garcibuey': 1200,
    'Garcihernández': 1200,
    'Garcillán': 1200,
    'Garcirrey': 1200,
    'Garganta del Villar': 1200,
    'Garrafe de Torío': 1200,
    'Garray': 1200,
    'Gatón de Campos': 1200,
    'Gavilanes': 1200,
    'Gejuelo del Barro': 1200,
    'Gema': 1200,
    'Gemuño': 1200,
    'Geria': 1200,
    'Gimialcón': 1200,
    'Golmayo': 1200,
    'Golpejas': 1200,
    'Gómara': 1200,
    'Gomecello': 1200,
    'Gordaliza del Pino': 1200,
    'Gordoncillo': 1200,
    'Gormaz': 1200,
    'Gotarrendura': 1200,
    'Gradefes': 1200,
    'Grajal de Campos': 1200,
    'Grajera': 1200,
    'Granja de Moreruela': 1200,
    'Granucillo': 1200,
    'Grijalba': 1200,
    'Grijota': 1200,
    'Grisaleña': 1200,
    'Guadramiro': 1200,
    'Guardo': 1200,
    'Guaza de Campos': 1200,
    'Guijo de Ávila': 1200,
    'Guijuelo': 1200,
    'Guisando': 1200,
    'Gusendos de los Oteros': 1200,
    'Gutierre-Muñoz': 1200,
    'Hacinas': 1200,
    'Haza': 1200,
    'Herguijuela de Ciudad Rodrigo': 1200,
    'Herguijuela de la Sierra': 1200,
    'Herguijuela del Campo': 1200,
    'Hérmedes de Cerrato': 1200,
    'Hermisende': 1200,
    'Hernansancho': 1200,
    'Herrera de Pisuerga': 1200,
    'Herrera de Soria': 1200,
    'Herrera de Valdecañas': 1200,
    'Herreros de Suso': 1200,
    'Herrín de Campos': 1200,
    'Higuera de las Dueñas': 1200,
    'Hinojosa de Duero': 1200,
    'Hinojosa del Campo': 1200,
    'Honrubia de la Cuesta': 1200,
    'Hontalbilla': 1200,
    'Hontanares de Eresma': 1200,
    'Hontanas': 1200,
    'Hontangas': 1200,
    'Hontoria de Cerrato': 1200,
    'Hontoria de la Cantera': 1200,
    'Hontoria de Valdearados': 1200,
    'Hontoria del Pinar': 1200,
    'Horcajo de las Torres': 1200,
    'Horcajo de Montemayor': 1200,
    'Hornillos de Cerrato': 1200,
    'Hornillos del Camino': 1200,
    'Hortigüela': 1200,
    'Hospital de Órbigo': 1200,
    'Hoyales de Roa': 1200,
    'Hoyocasero': 1200,
    'Hoyorredondo': 1200,
    'Hoyos de Miguel Muñoz': 1200,
    'Hoyos del Collado': 1200,
    'Hoyos del Espino': 1200,
    'Huérmeces': 1200,
    'Huerta de Arriba': 1200,
    'Huerta del Rey': 1200,
    'Humada': 1200,
    'Hurones': 1200,
    'Hurtumpascual': 1200,
    'Husillos': 1200,
    'Ibeas de Juarros': 1200,
    'Ibrillos': 1200,
    'Iglesiarrubia': 1200,
    'Iglesias': 1200,
    'Igüeña': 1200,
    'Iruelos': 1200,
    'Isar': 1200,
    'Iscar': 1200,
    'Itero de la Vega': 1200,
    'Itero del Castillo': 1200,
    'Ituero de Azaba': 1200,
    'Ituero y Lama': 1200,
    'Izagre': 1200,
    'Jambrina': 1200,
    'Jaramillo de la Fuente': 1200,
    'Jaramillo Quemado': 1200,
    'Joarilla de las Matas': 1200,
    'Juarros de Voltoya': 1200,
    'Junciana': 1200,
    'Justel': 1200,
    'Juzbado': 1200,
    'La Adrada': 1200,
    'La Alberca': 1200,
    'La Bañeza': 1200,
    'La Bouza': 1200,
    'La Carrera': 1200,
    'La Cuesta': 1200,
    'La Fuente de San Esteban': 1200,
    'La Lastrilla': 1200,
    'La Pedraja de Portillo': 1200,
    'La Pola de Gordón': 1200,
    'La Robla': 1200,
    'La Seca': 1200,
    'Labajos': 1200,
    'Lagartos': 1200,
    'Laguna Dalga': 1200,
    'Laguna de Contreras': 1200,
    'Laguna de Duero': 1200,
    'Laguna de Negrillos': 1200,
    'Lagunilla': 1200,
    'Langa': 1200,
    'Langa de Duero': 1200,
    'Langayo': 1200,
    'Languilla': 1200,
    'Lantadilla': 1200,
    'Larrodrigo': 1200,
    'Las Navas del Marqués': 1200,
    'Lastras de Cuéllar': 1200,
    'Lastras del Pozo': 1200,
    'Ledesma': 1200,
    'Ledigos': 1200,
    'Ledrada': 1200,
    'León': 1200,
    'Lerma': 1200,
    'Liceras': 1200,
    'Linares de Riofrío': 1200,
    'Llamas de la Ribera': 1200,
    'Llano de Bureba': 1200,
    'Llano de Olmedo': 1200,
    'Lomas': 1200,
    'Losacino': 1200,
    'Losacio': 1200,
    'Lubián': 1200,
    'Luelmo': 1200,
    'Lumbrales': 1200,
    'Luyego': 1200,
    'Machacón': 1200,
    'Macotera': 1200,
    'Maderuelo': 1200,
    'Madridanos': 1200,
    'Madrigal de las Altas Torres': 1200,
    'Madrigal del Monte': 1200,
    'Madrigalejo del Monte': 1200,
    'Madroñal': 1200,
    'Maello': 1200,
    'Magaña': 1200,
    'Magaz de Cepeda': 1200,
    'Mahamud': 1200,
    'Mahide': 1200,
    'Maire de Castroponce': 1200,
    'Maján': 1200,
    'Malpartida': 1200,
    'Malpartida de Corneja': 1200,
    'Malva': 1200,
    'Mamblas': 1200,
    'Mambrilla de Castrejón': 1200,
    'Mambrillas de Lara': 1200,
    'Mamolar': 1200,
    'Mancera de Abajo': 1200,
    'Mancera de Arriba': 1200,
    'Manciles': 1200,
    'Manganeses de la Lampreana': 1200,
    'Manganeses de la Polvorosa': 1200,
    'Manjabálago': 1200,
    'Manquillos': 1200,
    'Mansilla de las Mulas': 1200,
    'Mansilla Mayor': 1200,
    'Mantinos': 1200,
    'Manzanal de Arriba': 1200,
    'Manzanal de los Infantes': 1200,
    'Manzanal del Barco': 1200,
    'Manzanillo': 1200,
    'Maraña': 1200,
    'Marazoleja': 1200,
    'Marazuela': 1200,
    'Marcilla de Campos': 1200,
    'Martiago': 1200,
    'Martiherrero': 1200,
    'Martín de Yeltes': 1200,
    'Martín Miguel': 1200,
    'Martín Muñoz de la Dehesa': 1200,
    'Martín Muñoz de las Posadas': 1200,
    'Martinamor': 1200,
    'Martínez': 1200,
    'Marugán': 1200,
    'Marzales': 1200,
    'Masueco': 1200,
    'Mata de Cuéllar': 1200,
    'Matabuena': 1200,
    'Matadeón de los Oteros': 1200,
    'Matalebreras': 1200,
    'Matallana de Torío': 1200,
    'Matamala de Almazán': 1200,
    'Matapozuelos': 1200,
    'Matilla de Arzón': 1200,
    'Matilla de los Caños': 1200,
    'Matilla de los Caños del Río': 1200,
    'Matilla la Seca': 1200,
    'Mayalde': 1200,
    'Mayorga': 1200,
    'Mazariegos': 1200,
    'Mazuecos de Valdeginate': 1200,
    'Mazuela': 1200,
    'Mecerreyes': 1200,
    'Mediana de Voltoya': 1200,
    'Medina de Pomar': 1200,
    'Medina de Ríoseco': 1200,
    'Medina del Campo': 1200,
    'Medinaceli': 1200,
    'Medinilla': 1200,
    'Megeces': 1200,
    'Melgar de Abajo': 1200,
    'Melgar de Arriba': 1200,
    'Melgar de Fernamental': 1200,
    'Melgar de Tera': 1200,
    'Melgar de Yuso': 1200,
    'Membibre de la Hoz': 1200,
    'Membribe de la Sierra': 1200,
    'Meneses de Campos': 1200,
    'Mengamuñoz': 1200,
    'Mesegar de Corneja': 1200,
    'Micereces de Tera': 1200,
    'Micieces de Ojeda': 1200,
    'Mieza': 1200,
    'Migueláñez': 1200,
    'Mijares': 1200,
    'Milagros': 1200,
    'Milles de la Polvorosa': 1200,
    'Mingorría': 1200,
    'Miño de San Esteban': 1200,
    'Miranda de Azán': 1200,
    'Miranda de Ebro': 1200,
    'Miranda del Castañar': 1200,
    'Miraveche': 1200,
    'Mironcillo': 1200,
    'Modúbar de la Emparedada': 1200,
    'Mogarraz': 1200,
    'Mojados': 1200,
    'Molacillos': 1200,
    'Molezuelas de la Carballeda': 1200,
    'Molinaseca': 1200,
    'Molinillo': 1200,
    'Molinos de Duero': 1200,
    'Mombeltrán': 1200,
    'Momblona': 1200,
    'Mombuey': 1200,
    'Monasterio de la Sierra': 1200,
    'Monasterio de Rodilla': 1200,
    'Monasterio de Vega': 1200,
    'Moncalvillo': 1200,
    'Monfarracinos': 1200,
    'Monforte de la Sierra': 1200,
    'Monleón': 1200,
    'Monleras': 1200,
    'Monsagro': 1200,
    'Monsalupe': 1200,
    'Montamarta': 1200,
    'Monteagudo de las Vicarías': 1200,
    'Montejo': 1200,
    'Montejo de Arévalo': 1200,
    'Montejo de la Vega de la Serrezuela': 1200,
    'Montejo de Tiermes': 1200,
    'Montemayor de Pililla': 1200,
    'Montemayor del Río': 1200,
    'Montenegro de Cameros': 1200,
    'Monterrubio': 1200,
    'Monterrubio de Armuña': 1200,
    'Monterrubio de la Sierra': 1200,
    'Montorio': 1200,
    'Monzón de Campos': 1200,
    'Moradillo de Roa': 1200,
    'Moral de la Reina': 1200,
    'Moral de Sayago': 1200,
    'Moraleja de las Panaderas': 1200,
    'Moraleja de Matacabras': 1200,
    'Moraleja de Sayago': 1200,
    'Moraleja del Vino': 1200,
    'Morales de Campos': 1200,
    'Morales de Toro': 1200,
    'Morales de Valverde': 1200,
    'Morales del Vino': 1200,
    'Moralina': 1200,
    'Morasverdes': 1200,
    'Moratinos': 1200,
    'Moreruela de los Infanzones': 1200,
    'Moreruela de Tábara': 1200,
    'Morille': 1200,
    'Moriscos': 1200,
    'Morón de Almazán': 1200,
    'Moronta': 1200,
    'Mota del Marqués': 1200,
    'Mozárbez': 1200,
    'Mozoncillo': 1200,
    'Mucientes': 1200,
    'Mudá': 1200,
    'Muelas de los Caballeros': 1200,
    'Muga de Sayago': 1200,
    'Muñana': 1200,
    'Muñico': 1200,
    'Muñogalindo': 1200,
    'Muñogrande': 1200,
    'Muñomer del Peco': 1200,
    'Muñopedro': 1200,
    'Muñopepe': 1200,
    'Muñosancho': 1200,
    'Muñotello': 1200,
    'Muñoveros': 1200,
    'Murias de Paredes': 1200,
    'Muriel de la Fuente': 1200,
    'Muriel Viejo': 1200,
    'Nafría de Ucero': 1200,
    'Narrillos del Álamo': 1200,
    'Narrillos del Rebollar': 1200,
    'Narros': 1200,
    'Narros de Matalayegua': 1200,
    'Narros de Saldueña': 1200,
    'Narros del Castillo': 1200,
    'Narros del Puerto': 1200,
    'Nava de Arévalo': 1200,
    'Nava de Béjar': 1200,
    'Nava de Francia': 1200,
    'Nava de la Asunción': 1200,
    'Nava de Roa': 1200,
    'Nava de Sotrobal': 1200,
    'Nava del Barco': 1200,
    'Nava del Rey': 1200,
    'Navacarros': 1200,
    'Navacepedilla de Corneja': 1200,
    'Navadijos': 1200,
    'Navaescurial': 1200,
    'Navafría': 1200,
    'Navahondilla': 1200,
    'Navalacruz': 1200,
    'Navaleno': 1200,
    'Navales': 1200,
    'Navalilla': 1200,
    'Navalmanzano': 1200,
    'Navalmoral': 1200,
    'Navalmoral de Béjar': 1200,
    'Navalosa': 1200,
    'Navalperal de Pinares': 1200,
    'Navalperal de Tormes': 1200,
    'Navaluenga': 1200,
    'Navamorales': 1200,
    'Navaquesera': 1200,
    'Navares de Ayuso': 1200,
    'Navares de Enmedio': 1200,
    'Navares de las Cuevas': 1200,
    'Navarredonda de Gredos': 1200,
    'Navarredonda de la Rinconada': 1200,
    'Navarredondilla': 1200,
    'Navarrevisca': 1200,
    'Navas de Bureba': 1200,
    'Navas de Oro': 1200,
    'Navasfrías': 1200,
    'Navatalgordo': 1200,
    'Navatejares': 1200,
    'Navianos de Valverde': 1200,
    'Nebreda': 1200,
    'Negrilla de Palencia': 1200,
    'Neila': 1200,
    'Neila de San Miguel': 1200,
    'Nepas': 1200,
    'Nieva': 1200,
    'Niharra': 1200,
    'Nogal de las Huertas': 1200,
    'Nolay': 1200,
    'Noviercas': 1200,
    'Nueva Villa de las Torres': 1200,
    'Oencia': 1200,
    'Ojos-Albos': 1200,
    'Olea de Boedo': 1200,
    'Olivares de Duero': 1200,
    'Olmedillo de Roa': 1200,
    'Olmedo': 1200,
    'Olmedo de Camaces': 1200,
    'Olmillos de Castro': 1200,
    'Olmillos de Muñó': 1200,
    'Olmos de Esgueva': 1200,
    'Olmos de Ojeda': 1200,
    'Olmos de Peñafiel': 1200,
    'Olombrada': 1200,
    'Olvega': 1200,
    'Oña': 1200,
    'Oncala': 1200,
    'Onzonilla': 1200,
    'Oquillas': 1200,
    'Orbita': 1200,
    'Ortigosa de Pestaño': 1200,
    'Ortigosa del Monte': 1200,
    'Oseja de Sajambre': 1200,
    'Osornillo': 1200,
    'Otero de Bodas': 1200,
    'Otero de Herreros': 1200,
    'Padiernos': 1200,
    'Padilla de Abajo': 1200,
    'Padilla de Arriba': 1200,
    'Padrones de Bureba': 1200,
    'Pajarejos': 1200,
    'Pajares de Adaja': 1200,
    'Pajares de la Laguna': 1200,
    'Pajares de la Lampreana': 1200,
    'Pajares de los Oteros': 1200,
    'Palacios de Goda': 1200,
    'Palacios de la Sierra': 1200,
    'Palacios de la Valduerna': 1200,
    'Palacios de Sanabria': 1200,
    'Palacios del Arzobispo': 1200,
    'Palacios del Pan': 1200,
    'Palacios del Sil': 1200,
    'Palaciosrubios': 1200,
    'Palazuelo de Vedija': 1200,
    'Palazuelos de Eresma': 1200,
    'Palazuelos de la Sierra': 1200,
    'Palazuelos de Muñó': 1200,
    'Palencia': 1200,
    'Palencia de Negrilla': 1200,
    'Palenzuela': 1200,
    'Pampliega': 1200,
    'Pancorbo': 1200,
    'Papatrigo': 1200,
    'Parada de Arriba': 1200,
    'Parada de Rubiales': 1200,
    'Paradinas de San Juan': 1200,
    'Páramo de Boedo': 1200,
    'Páramo del Sil': 1200,
    'Pardilla': 1200,
    'Paredes de Nava': 1200,
    'Pastores': 1200,
    'Payo de Ojeda': 1200,
    'Pedrajas de San Esteban': 1200,
    'Pedraza': 1200,
    'Pedraza de Alba': 1200,
    'Pedraza de Campos': 1200,
    'Pedro Bernardo': 1200,
    'Pedrosa de Duero': 1200,
    'Pedrosa de la Vega': 1200,
    'Pedrosa del Páramo': 1200,
    'Pedrosa del Príncipe': 1200,
    'Pedrosa del Rey': 1200,
    'Pedrosillo de Alba': 1200,
    'Pedrosillo de los Aires': 1200,
    'Pedrosillo el Ralo': 1200,
    'Peguerinos': 1200,
    'Pelabravo': 1200,
    'Pelarrodríguez': 1200,
    'Pelayos': 1200,
    'Pelayos del Arroyo': 1200,
    'Peleagonzalo': 1200,
    'Peleas de Abajo': 1200,
    'Peñacaballera': 1200,
    'Peñafiel': 1200,
    'Peñaflor de Hornija': 1200,
    'Peñalba de Ávila': 1200,
    'Peñaparda': 1200,
    'Peñaranda de Bracamonte': 1200,
    'Peñaranda de Duero': 1200,
    'Peñarandilla': 1200,
    'Peñausende': 1200,
    'Peque': 1200,
    'Peral de Arlanza': 1200,
    'Peralejos de Abajo': 1200,
    'Peralejos de Arriba': 1200,
    'Perales': 1200,
    'Peranzanes': 1200,
    'Pereruela': 1200,
    'Perilla de Castro': 1200,
    'Peromingo': 1200,
    'Perosillo': 1200,
    'Pías': 1200,
    'Piedrahita de Castro': 1200,
    'Piedralaves': 1200,
    'Piérnigas': 1200,
    'Piña de Campos': 1200,
    'Piña de Esgueva': 1200,
    'Pinarejos': 1200,
    'Pinarnegrillo': 1200,
    'Pineda de la Sierra': 1200,
    'Pinedas': 1200,
    'Piñel de Abajo': 1200,
    'Piñel de Arriba': 1200,
    'Pinilla de los Barruecos': 1200,
    'Pinilla de los Moros': 1200,
    'Pinilla de Toro': 1200,
    'Pinilla del Campo': 1200,
    'Pino del Río': 1200,
    'Pitiegua': 1200,
    'Pizarral': 1200,
    'Población de Arroyo': 1200,
    'Población de Campos': 1200,
    'Población de Cerrato': 1200,
    'Pobladura de Pelayo García': 1200,
    'Pobladura de Valderaduey': 1200,
    'Pobladura del Valle': 1200,
    'Polentinos': 1200,
    'Pollos': 1200,
    'Pomar de Valdivia': 1200,
    'Ponferrada': 1200,
    'Portillo': 1200,
    'Portillo de Soria': 1200,
    'Porto': 1200,
    'Posada de Valdeón': 1200,
    'Poveda': 1200,
    'Poveda de las Cintas': 1200,
    'Poyales del Hoyo': 1200,
    'Poza de la Sal': 1200,
    'Poza de la Vega': 1200,
    'Pozal de Gallinas': 1200,
    'Pozalmuro': 1200,
    'Pozanco': 1200,
    'Pozo de Urama': 1200,
    'Pozoantiguo': 1200,
    'Pozos de Hinojo': 1200,
    'Pozuelo de la Orden': 1200,
    'Pozuelo de Tábara': 1200,
    'Pozuelo del Páramo': 1200,
    'Pradales': 1200,
    'Prádanos de Bureba': 1200,
    'Prádanos de Ojeda': 1200,
    'Prádena': 1200,
    'Prado': 1200,
    'Prado de la Guzpeña': 1200,
    'Pradoluengo': 1200,
    'Pradosegar': 1200,
    'Presencio': 1200,
    'Priaranza del Bierzo': 1200,
    'Prioro': 1200,
    'Provincia de Ávila': 1200,
    'Provincia de Burgos': 1200,
    'Provincia de León': 1200,
    'Provincia de Palencia': 1200,
    'Provincia de Salamanca': 1200,
    'Provincia de Segovia': 1200,
    'Provincia de Soria': 1200,
    'Provincia de Valladolid': 1200,
    'Provincia de Zamora': 1200,
    'Puebla de Azaba': 1200,
    'Puebla de Lillo': 1200,
    'Puebla de Pedraza': 1200,
    'Puebla de San Medel': 1200,
    'Puebla de Sanabria': 1200,
    'Puebla de Yeltes': 1200,
    'Pueblica de Valverde': 1200,
    'Puente de Domingo Flórez': 1200,
    'Puente del Congosto': 1200,
    'Puentedura': 1200,
    'Puertas': 1200,
    'Puerto Castilla': 1200,
    'Puerto de Béjar': 1200,
    'Puerto Seguro': 1200,
    'Puras': 1200,
    'Quemada': 1200,
    'Quintana del Castillo': 1200,
    'Quintana del Marco': 1200,
    'Quintana del Pidio': 1200,
    'Quintana del Puente': 1200,
    'Quintana Redonda': 1200,
    'Quintana y Congosto': 1200,
    'Quintanabureba': 1200,
    'Quintanaélez': 1200,
    'Quintanaortuño': 1200,
    'Quintanapalla': 1200,
    'Quintanar de la Sierra': 1200,
    'Quintanas de Gormaz': 1200,
    'Quintanavides': 1200,
    'Quintanilla de Arriba': 1200,
    'Quintanilla de la Mata': 1200,
    'Quintanilla de Onésimo': 1200,
    'Quintanilla de Onsoña': 1200,
    'Quintanilla de Trigueros': 1200,
    'Quintanilla de Urz': 1200,
    'Quintanilla del Coco': 1200,
    'Quintanilla del Molar': 1200,
    'Quintanilla del Monte': 1200,
    'Quintanilla del Olmo': 1200,
    'Quiruelas de Vidriales': 1200,
    'Rabanales': 1200,
    'Rabanera del Pinar': 1200,
    'Rábano': 1200,
    'Rábano de Aliste': 1200,
    'Rábanos': 1200,
    'Rabé de las Calzadas': 1200,
    'Rágama': 1200,
    'Ramiro': 1200,
    'Rapariegos': 1200,
    'Rasueros': 1200,
    'Rebolledo de la Torre': 1200,
    'Rebollo': 1200,
    'Recuerda': 1200,
    'Redecilla del Camino': 1200,
    'Redecilla del Campo': 1200,
    'Regueras de Arriba': 1200,
    'Regumiel de la Sierra': 1200,
    'Reinoso': 1200,
    'Reinoso de Cerrato': 1200,
    'Rello': 1200,
    'Remondo': 1200,
    'Renedo de la Vega': 1200,
    'Renieblas': 1200,
    'Requena de Campos': 1200,
    'Respenda de la Peña': 1200,
    'Retortillo': 1200,
    'Retortillo de Soria': 1200,
    'Retuerta': 1200,
    'Revellinos': 1200,
    'Revenga de Campos': 1200,
    'Revilla de Collazos': 1200,
    'Revilla del Campo': 1200,
    'Revillarruz': 1200,
    'Reyero': 1200,
    'Rezmondo': 1200,
    'Reznos': 1200,
    'Riaguas de San Bartolomé': 1200,
    'Riaño': 1200,
    'Riaza': 1200,
    'Ribas de Campos': 1200,
    'Riberos de la Cueza': 1200,
    'Ribota': 1200,
    'Riego de la Vega': 1200,
    'Riocavado de la Sierra': 1200,
    'Ríofrío de Aliste': 1200,
    'Rivilla de Barajas': 1200,
    'Roa': 1200,
    'Roales': 1200,
    'Robladillo': 1200,
    'Robleda': 1200,
    'Robleda-Cervantes': 1200,
    'Robliza de Cojos': 1200,
    'Roda de Eresma': 1200,
    'Rojas': 1200,
    'Rollamienta': 1200,
    'Rollán': 1200,
    'Roperuelos del Páramo': 1200,
    'Roturas': 1200,
    'Royuela de Río Franco': 1200,
    'Rubena': 1200,
    'Rubí de Bracamonte': 1200,
    'Rublacedo de Abajo': 1200,
    'Rucandio': 1200,
    'Rueda': 1200,
    'Ruesga': 1200,
    'Sabero': 1200,
    'Sacramenia': 1200,
    'Saelices de Mayorga': 1200,
    'Saelices el Chico': 1200,
    'Sahagún': 1200,
    'Salamanca': 1200,
    'Salas de Bureba': 1200,
    'Salas de los Infantes': 1200,
    'Salce': 1200,
    'Saldaña': 1200,
    'Saldaña de Burgos': 1200,
    'Saldeana': 1200,
    'Salduero': 1200,
    'Salinas de Pisuerga': 1200,
    'Salinillas de Bureba': 1200,
    'Salmoral': 1200,
    'Salobral': 1200,
    'Salvadiós': 1200,
    'Salvador de Zapardiel': 1200,
    'Salvatierra de Tormes': 1200,
    'Samboal': 1200,
    'Samir de los Caños': 1200,
    'San Adrián de Juarros': 1200,
    'San Adrián del Valle': 1200,
    'San Agustín del Pozo': 1200,
    'San Andrés del Rabanedo': 1200,
    'San Bartolomé de Béjar': 1200,
    'San Bartolomé de Corneja': 1200,
    'San Cebrián de Campos': 1200,
    'San Cebrián de Castro': 1200,
    'San Cebrián de Mazote': 1200,
    'San Cebrián de Mudá': 1200,
    'San Cristóbal de Boedo': 1200,
    'San Cristóbal de Cuéllar': 1200,
    'San Cristóbal de Entreviñas': 1200,
    'San Cristóbal de la Cuesta': 1200,
    'San Cristóbal de la Polantera': 1200,
    'San Cristóbal de la Vega': 1200,
    'San Cristóbal de Segovia': 1200,
    'San Emiliano': 1200,
    'San Esteban de Gormaz': 1200,
    'San Esteban de la Sierra': 1200,
    'San Esteban de los Patos': 1200,
    'San Esteban de Nogales': 1200,
    'San Esteban del Molar': 1200,
    'San Esteban del Valle': 1200,
    'San Felices de los Gallegos': 1200,
    'San García de Ingelmos': 1200,
    'San Ildefonso': 1200,
    'San Juan de la Encinilla': 1200,
    'San Juan de la Nava': 1200,
    'San Juan del Molinillo': 1200,
    'San Juan del Monte': 1200,
    'San Justo': 1200,
    'San Justo de la Vega': 1200,
    'San Leonardo de Yagüe': 1200,
    'San Llorente': 1200,
    'San Lorenzo de Tormes': 1200,
    'San Mamés de Burgos': 1200,
    'San Mamés de Campos': 1200,
    'San Martín de la Vega del Alberche': 1200,
    'San Martín de Rubiales': 1200,
    'San Martín de Valderaduey': 1200,
    'San Martín de Valvení': 1200,
    'San Martín del Castañar': 1200,
    'San Martín del Pimpollar': 1200,
    'San Miguel de Corneja': 1200,
    'San Miguel de la Ribera': 1200,
    'San Miguel de Serrezuela': 1200,
    'San Miguel de Valero': 1200,
    'San Miguel del Arroyo': 1200,
    'San Miguel del Pino': 1200,
    'San Millán de Lara': 1200,
    'San Millán de los Caballeros': 1200,
    'San Morales': 1200,
    'San Muñoz': 1200,
    'San Pablo de la Moraleja': 1200,
    'San Pascual': 1200,
    'San Pedro Bercianos': 1200,
    'San Pedro de Ceque': 1200,
    'San Pedro de Gaíllos': 1200,
    'San Pedro de Latarce': 1200,
    'San Pedro de Rozados': 1200,
    'San Pedro del Arroyo': 1200,
    'San Pedro del Valle': 1200,
    'San Pedro Manrique': 1200,
    'San Pelayo': 1200,
    'San Pelayo de Guareña': 1200,
    'San Román de Hornija': 1200,
    'San Román de la Cuba': 1200,
    'San Vicente de Arévalo': 1200,
    'San Vicente de la Cabeza': 1200,
    'San Vicente del Palacio': 1200,
    'San Vicente del Valle': 1200,
    'San Vitero': 1200,
    'Sancedo': 1200,
    'Sanchidrián': 1200,
    'Sanchón de la Ribera': 1200,
    'Sanchón de la Sagrada': 1200,
    'Sanchonuño': 1200,
    'Sanchorreja': 1200,
    'Sanchotello': 1200,
    'Sando': 1200,
    'Sangarcía': 1200,
    'Santa Cecilia': 1200,
    'Santa Cecilia del Alcor': 1200,
    'Santa Clara de Avedillo': 1200,
    'Santa Colomba de Curueño': 1200,
    'Santa Colomba de las Monjas': 1200,
    'Santa Colomba de Somoza': 1200,
    'Santa Cristina de la Polvorosa': 1200,
    'Santa Cristina de Valmadrigal': 1200,
    'Santa Croya de Tera': 1200,
    'Santa Cruz de Boedo': 1200,
    'Santa Cruz de la Salceda': 1200,
    'Santa Cruz de Pinares': 1200,
    'Santa Cruz de Yanguas': 1200,
    'Santa Cruz del Valle': 1200,
    'Santa Cruz del Valle Urbión': 1200,
    'Santa Elena de Jamuz': 1200,
    'Santa Eufemia del Arroyo': 1200,
    'Santa Eufemia del Barco': 1200,
    'Santa Gadea del Cid': 1200,
    'Santa Inés': 1200,
    'Santa María de Huerta': 1200,
    'Santa María de la Isla': 1200,
    'Santa María de la Vega': 1200,
    'Santa María de las Hoyas': 1200,
    'Santa María de los Caballeros': 1200,
    'Santa María de Ordás': 1200,
    'Santa María de Sando': 1200,
    'Santa María de Valverde': 1200,
    'Santa María del Berrocal': 1200,
    'Santa María del Campo': 1200,
    'Santa María del Invierno': 1200,
    'Santa María del Monte de Cea': 1200,
    'Santa María del Páramo': 1200,
    'Santa María la Real de Nieva': 1200,
    'Santa Marina del Rey': 1200,
    'Santa Marta de Tormes': 1200,
    'Santa Marta del Cerro': 1200,
    'Santa Olalla de Bureba': 1200,
    'Santas Martas': 1200,
    'Santervás de Campos': 1200,
    'Santervás de la Vega': 1200,
    'Santiago de la Puebla': 1200,
    'Santiago del Collado': 1200,
    'Santiago Millas': 1200,
    'Santibáñez de Béjar': 1200,
    'Santibáñez de Ecla': 1200,
    'Santibáñez de la Peña': 1200,
    'Santibáñez de la Sierra': 1200,
    'Santibáñez de Tera': 1200,
    'Santibáñez de Valcorba': 1200,
    'Santibáñez de Vidriales': 1200,
    'Santibáñez del Val': 1200,
    'Santiuste de San Juan Bautista': 1200,
    'Santiz': 1200,
    'Santo Domingo de las Posadas': 1200,
    'Santo Domingo de Pirón': 1200,
    'Santo Domingo de Silos': 1200,
    'Santo Tomé de Zabarcos': 1200,
    'Santovenia': 1200,
    'Santovenia de Pisuerga': 1200,
    'Santoyo': 1200,
    'Sanzoles': 1200,
    'Sardón de Duero': 1200,
    'Sardón de los Frailes': 1200,
    'Sargentes de la Lora': 1200,
    'Sariegos': 1200,
    'Sarracín': 1200,
    'Sasamón': 1200,
    'Saucelle': 1200,
    'Sauquillo de Cabezas': 1200,
    'Sebúlcor': 1200,
    'Segovia': 1200,
    'Sena de Luna': 1200,
    'Sepúlveda': 1200,
    'Sequera de Fresno': 1200,
    'Sequeros': 1200,
    'Serrada': 1200,
    'Serradilla del Arroyo': 1200,
    'Serradilla del Llano': 1200,
    'Serranillos': 1200,
    'Siete Iglesias de Trabancos': 1200,
    'Sigeres': 1200,
    'Simancas': 1200,
    'Sinlabajos': 1200,
    'Sobradillo': 1200,
    'Sobrado': 1200,
    'Solarana': 1200,
    'Soliedra': 1200,
    'Solosancho': 1200,
    'Sordillos': 1200,
    'Soria': 1200,
    'Sorihuela': 1200,
    'Sotalbo': 1200,
    'Sotillo': 1200,
    'Sotillo de la Adrada': 1200,
    'Sotillo de la Ribera': 1200,
    'Sotillo del Rincón': 1200,
    'Soto de Cerrato': 1200,
    'Soto de la Vega': 1200,
    'Soto y Amío': 1200,
    'Sotobañado y Priorato': 1200,
    'Sotosalbos': 1200,
    'Sotoserrano': 1200,
    'Sotragero': 1200,
    'Sotresgudo': 1200,
    'Suellacabras': 1200,
    'Susinos del Páramo': 1200,
    'Tabanera de Cerrato': 1200,
    'Tabanera de Valdavia': 1200,
    'Tabanera la Luenga': 1200,
    'Tábara': 1200,
    'Tabera de Abajo': 1200,
    'Tajahuerce': 1200,
    'Tajueco': 1200,
    'Tamames': 1200,
    'Tamariz de Campos': 1200,
    'Tamarón': 1200,
    'Tapioles': 1200,
    'Tarazona de Guareña': 1200,
    'Tardáguila': 1200,
    'Tardajos': 1200,
    'Tardelcuende': 1200,
    'Taroda': 1200,
    'Tejada': 1200,
    'Tejado': 1200,
    'Tejeda y Segoyuela': 1200,
    'Tenebrón': 1200,
    'Terradillos': 1200,
    'Terradillos de Esgueva': 1200,
    'Tiedra': 1200,
    'Tiñosillos': 1200,
    'Tobar': 1200,
    'Tolbaños': 1200,
    'Tolocirio': 1200,
    'Topas': 1200,
    'Toral de los Guzmanes': 1200,
    'Tordehumos': 1200,
    'Tordesillas': 1200,
    'Tordillos': 1200,
    'Tordómar': 1200,
    'Toreno': 1200,
    'Torlengua': 1200,
    'Tormellas': 1200,
    'Tornadizos de Ávila': 1200,
    'Toro': 1200,
    'Torquemada': 1200,
    'Torre de Esgueva': 1200,
    'Torre de Peñafiel': 1200,
    'Torre del Bierzo': 1200,
    'Torre Val de San Pedro': 1200,
    'Torreadrada': 1200,
    'Torreblacos': 1200,
    'Torrecaballeros': 1200,
    'Torrecilla de la Abadesa': 1200,
    'Torrecilla de la Orden': 1200,
    'Torrecilla de la Torre': 1200,
    'Torrecilla del Monte': 1200,
    'Torrecilla del Pinar': 1200,
    'Torregalindo': 1200,
    'Torregamones': 1200,
    'Torreiglesias': 1200,
    'Torrelara': 1200,
    'Torrelobatón': 1200,
    'Torremormojón': 1200,
    'Torres del Carrizal': 1200,
    'Torresandino': 1200,
    'Torrescárcela': 1200,
    'Torresmenudas': 1200,
    'Torrubia de Soria': 1200,
    'Tórtoles': 1200,
    'Tosantos': 1200,
    'Trabadelo': 1200,
    'Trabanca': 1200,
    'Trabazos': 1200,
    'Traspinedo': 1200,
    'Trefacio': 1200,
    'Tremedal de Tormes': 1200,
    'Trescasas': 1200,
    'Trespaderne': 1200,
    'Trigueros del Valle': 1200,
    'Triollo': 1200,
    'Truchas': 1200,
    'Tubilla del Agua': 1200,
    'Tubilla del Lago': 1200,
    'Tudela de Duero': 1200,
    'Turcia': 1200,
    'Turégano': 1200,
    'Ucero': 1200,
    'Umbrías': 1200,
    'Uña de Quintana': 1200,
    'Urdiales del Páramo': 1200,
    'Urones de Castroponce': 1200,
    'Urueña': 1200,
    'Urueñas': 1200,
    'Vadillo': 1200,
    'Vadillo de la Guareña': 1200,
    'Vadillo de la Sierra': 1200,
    'Vadocondes': 1200,
    'Val de San Lorenzo': 1200,
    'Valbuena de Duero': 1200,
    'Valbuena de Pisuerga': 1200,
    'Valcabado': 1200,
    'Valdeande': 1200,
    'Valdearcos de la Vega': 1200,
    'Valdeavellano de Tera': 1200,
    'Valdecarros': 1200,
    'Valdecasa': 1200,
    'Valdefinjas': 1200,
    'Valdefresno': 1200,
    'Valdefuentes de Sangusín': 1200,
    'Valdefuentes del Páramo': 1200,
    'Valdehijaderos': 1200,
    'Valdelacasa': 1200,
    'Valdelageve': 1200,
    'Valdelagua del Cerro': 1200,
    'Valdelosa': 1200,
    'Valdemaluque': 1200,
    'Valdemierque': 1200,
    'Valdemora': 1200,
    'Valdenebro': 1200,
    'Valdenebro de los Valles': 1200,
    'Valdeolmillos': 1200,
    'Valdepiélago': 1200,
    'Valdepolo': 1200,
    'Valdeprado': 1200,
    'Valdeprados': 1200,
    'Valderas': 1200,
    'Valderrábano': 1200,
    'Valderrey': 1200,
    'Valderrodilla': 1200,
    'Valderrodrigo': 1200,
    'Valderrueda': 1200,
    'Valdesamario': 1200,
    'Valdescorriel': 1200,
    'Valdestillas': 1200,
    'Valdevacas de Montejo': 1200,
    'Valdevimbre': 1200,
    'Valdezate': 1200,
    'Valdorros': 1200,
    'Valdunciel': 1200,
    'Valdunquillo': 1200,
    'Valencia de Don Juan': 1200,
    'Valero': 1200,
    'Valladolid': 1200,
    'Vallarta de Bureba': 1200,
    'Valle de Cerrato': 1200,
    'Valle de Tabladillo': 1200,
    'Vallecillo': 1200,
    'Vallejera de Riofrío': 1200,
    'Vallelado': 1200,
    'Valleruela de Pedraza': 1200,
    'Valleruela de Sepúlveda': 1200,
    'Valles de Palenzuela': 1200,
    'Vallesa de la Guareña': 1200,
    'Valluércanes': 1200,
    'Valmala': 1200,
    'Valoria la Buena': 1200,
    'Valsalabroso': 1200,
    'Valseca': 1200,
    'Valtajeros': 1200,
    'Valtiendas': 1200,
    'Valverde de Campos': 1200,
    'Valverde de la Virgen': 1200,
    'Valverde de Valdelacasa': 1200,
    'Valverde del Majano': 1200,
    'Valverde-Enrique': 1200,
    'Valverdón': 1200,
    'Vecinos': 1200,
    'Vega de Espinareda': 1200,
    'Vega de Infanzones': 1200,
    'Vega de Ruiponce': 1200,
    'Vega de Santa María': 1200,
    'Vega de Tera': 1200,
    'Vega de Tirados': 1200,
    'Vega de Valcarce': 1200,
    'Vega de Valdetronco': 1200,
    'Vega de Villalobos': 1200,
    'Vegacervera': 1200,
    'Vegalatrave': 1200,
    'Veganzones': 1200,
    'Vegaquemada': 1200,
    'Vegas de Matute': 1200,
    'Velamazán': 1200,
    'Velascálvaro': 1200,
    'Velayos': 1200,
    'Velilla': 1200,
    'Velilla de los Ajos': 1200,
    'Velilla del Río Carrión': 1200,
    'Velliza': 1200,
    'Venialbo': 1200,
    'Venta de Baños': 1200,
    'Ventosa de la Cuesta': 1200,
    'Ventosa del Río Almar': 1200,
    'Vertavillo': 1200,
    'Vezdemarbán': 1200,
    'Viana de Cega': 1200,
    'Viana de Duero': 1200,
    'Vidayanes': 1200,
    'Videmala': 1200,
    'Vileña': 1200,
    'Villabáñez': 1200,
    'Villabaruz de Campos': 1200,
    'Villablino': 1200,
    'Villabrágima': 1200,
    'Villabraz': 1200,
    'Villabrázaro': 1200,
    'Villabuena del Puente': 1200,
    'Villacarralón': 1200,
    'Villacastín': 1200,
    'Villacid de Campos': 1200,
    'Villacidaler': 1200,
    'Villaciervos': 1200,
    'Villaco': 1200,
    'Villaconancio': 1200,
    'Villada': 1200,
    'Villadangos del Páramo': 1200,
    'Villadecanes': 1200,
    'Villademor de la Vega': 1200,
    'Villadepera': 1200,
    'Villadiego': 1200,
    'Villaeles de Valdavia': 1200,
    'Villaescusa': 1200,
    'Villaescusa de Roa': 1200,
    'Villaescusa la Sombría': 1200,
    'Villaespasa': 1200,
    'Villafáfila': 1200,
    'Villaferrueña': 1200,
    'Villaflor': 1200,
    'Villaflores': 1200,
    'Villafrades de Campos': 1200,
    'Villafranca de Duero': 1200,
    'Villafranca de la Sierra': 1200,
    'Villafranca del Bierzo': 1200,
    'Villafrechós': 1200,
    'Villafruela': 1200,
    'Villafuerte': 1200,
    'Villagalijo': 1200,
    'Villagarcía de Campos': 1200,
    'Villagatón': 1200,
    'Villageriz': 1200,
    'Villagómez la Nueva': 1200,
    'Villagonzalo de Tormes': 1200,
    'Villagonzalo-Pedernales': 1200,
    'Villahán': 1200,
    'Villaherreros': 1200,
    'Villahoz': 1200,
    'Villalaco': 1200,
    'Villalán de Campos': 1200,
    'Villalar de los Comuneros': 1200,
    'Villalazán': 1200,
    'Villalba de Duero': 1200,
    'Villalba de Guardo': 1200,
    'Villalba de la Lampreana': 1200,
    'Villalba de la Loma': 1200,
    'Villalba de los Alcores': 1200,
    'Villalba de los Llanos': 1200,
    'Villalbarba': 1200,
    'Villalbilla de Burgos': 1200,
    'Villalbilla de Gumiel': 1200,
    'Villalcampo': 1200,
    'Villalcázar de Sirga': 1200,
    'Villalcón': 1200,
    'Villaldemiro': 1200,
    'Villalmanzo': 1200,
    'Villalobón': 1200,
    'Villalobos': 1200,
    'Villalón de Campos': 1200,
    'Villalonso': 1200,
    'Villalpando': 1200,
    'Villalube': 1200,
    'Villaluenga de la Vega': 1200,
    'Villamañán': 1200,
    'Villamandos': 1200,
    'Villamanín': 1200,
    'Villamartín de Campos': 1200,
    'Villamartín de Don Sancho': 1200,
    'Villamayor': 1200,
    'Villamayor de Campos': 1200,
    'Villamayor de los Montes': 1200,
    'Villamayor de Treviño': 1200,
    'Villamediana': 1200,
    'Villamedianilla': 1200,
    'Villamejil': 1200,
    'Villameriel': 1200,
    'Villamiel de la Sierra': 1200,
    'Villamol': 1200,
    'Villamontán de la Valduerna': 1200,
    'Villamor de los Escuderos': 1200,
    'Villamoratiel de las Matas': 1200,
    'Villamoronta': 1200,
    'Villamuera de la Cueza': 1200,
    'Villamuriel de Campos': 1200,
    'Villamuriel de Cerrato': 1200,
    'Villán de Tordesillas': 1200,
    'Villanázar': 1200,
    'Villangómez': 1200,
    'Villanubla': 1200,
    'Villanueva de Argaño': 1200,
    'Villanueva de Azoague': 1200,
    'Villanueva de Campeán': 1200,
    'Villanueva de Carazo': 1200,
    'Villanueva de Duero': 1200,
    'Villanueva de Gómez': 1200,
    'Villanueva de Gormaz': 1200,
    'Villanueva de Gumiel': 1200,
    'Villanueva de la Condesa': 1200,
    'Villanueva de las Manzanas': 1200,
    'Villanueva de las Peras': 1200,
    'Villanueva de los Caballeros': 1200,
    'Villanueva de San Mancio': 1200,
    'Villanueva de Teba': 1200,
    'Villanueva del Aceral': 1200,
    'Villanueva del Campillo': 1200,
    'Villanueva del Campo': 1200,
    'Villanueva del Conde': 1200,
    'Villanueva del Rebollar': 1200,
    'Villanuño de Valdavia': 1200,
    'Villaobispo de Otero': 1200,
    'Villaprovedo': 1200,
    'Villaquejida': 1200,
    'Villaquilambre': 1200,
    'Villaquirán de la Puebla': 1200,
    'Villaquirán de los Infantes': 1200,
    'Villar de Ciervo': 1200,
    'Villar de Corneja': 1200,
    'Villar de Fallaves': 1200,
    'Villar de Gallimazo': 1200,
    'Villar de la Yegua': 1200,
    'Villar de Peralonso': 1200,
    'Villar de Samaniego': 1200,
    'Villar del Ala': 1200,
    'Villar del Buey': 1200,
    'Villar del Campo': 1200,
    'Villar del Río': 1200,
    'Villaralbo': 1200,
    'Villardeciervos': 1200,
    'Villardefrades': 1200,
    'Villardiegua de la Ribera': 1200,
    'Villárdiga': 1200,
    'Villardondiego': 1200,
    'Villarejo de Órbigo': 1200,
    'Villarejo del Valle': 1200,
    'Villares de la Reina': 1200,
    'Villares de Órbigo': 1200,
    'Villares de Yeltes': 1200,
    'Villariezo': 1200,
    'Villarino de los Aires': 1200,
    'Villarmayor': 1200,
    'Villarmentero de Campos': 1200,
    'Villarmentero de Esgueva': 1200,
    'Villarmuerto': 1200,
    'Villarrabé': 1200,
    'Villarramiel': 1200,
    'Villarrín de Campos': 1200,
    'Villasabariego': 1200,
    'Villasana de Mena': 1200,
    'Villasandino': 1200,
    'Villasarracino': 1200,
    'Villasayas': 1200,
    'Villasbuenas': 1200,
    'Villasdardo': 1200,
    'Villaseca de Arciel': 1200,
    'Villaseco de los Gamitos': 1200,
    'Villaseco de los Reyes': 1200,
    'Villaselán': 1200,
    'Villasexmir': 1200,
    'Villasila de Valdavia': 1200,
    'Villasrubias': 1200,
    'Villatoro': 1200,
    'Villatuelda': 1200,
    'Villaturde': 1200,
    'Villaturiel': 1200,
    'Villaumbrales': 1200,
    'Villavaquerín': 1200,
    'Villavellid': 1200,
    'Villavendimio': 1200,
    'Villaverde de Guareña': 1200,
    'Villaverde de Medina': 1200,
    'Villaverde de Montejo': 1200,
    'Villaverde del Monte': 1200,
    'Villaverde-Mogina': 1200,
    'Villaveza de Valverde': 1200,
    'Villaveza del Agua': 1200,
    'Villavicencio de los Caballeros': 1200,
    'Villavieja de Yeltes': 1200,
    'Villaviudas': 1200,
    'Villazala': 1200,
    'Villazanzo de Valderaduey': 1200,
    'Villazopeque': 1200,
    'Villegas': 1200,
    'Villeguillo': 1200,
    'Villodre': 1200,
    'Villodrigo': 1200,
    'Villoldo': 1200,
    'Villoría': 1200,
    'Villoruebo': 1200,
    'Villoruela': 1200,
    'Villota del Páramo': 1200,
    'Villovieco': 1200,
    'Viloria': 1200,
    'Viloria de Rioja': 1200,
    'Vilvestre': 1200,
    'Vilviestre del Pinar': 1200,
    'Viñegra de Moraña': 1200,
    'Vinuesa': 1200,
    'Vita': 1200,
    'Vitigudino': 1200,
    'Vizcaínos': 1200,
    'Vizmanos': 1200,
    'Vozmediano': 1200,
    'Wamba': 1200,
    'Yanguas': 1200,
    'Yanguas de Eresma': 1200,
    'Yecla de Yeltes': 1200,
    'Yelo': 1200,
    'Zael': 1200,
    'Zamarra': 1200,
    'Zamayón': 1200,
    'Zamora': 1200,
    'Zapardiel de la Cañada': 1200,
    'Zapardiel de la Ribera': 1200,
    'Zarapicos': 1200,
    'Zaratán': 1200,
    'Zarzuela del Monte': 1200,
    'Zarzuela del Pinar': 1200,
    'Zazuar': 1200,
    'Zorita de la Frontera': 1200,
    'Zotes del Páramo': 1200,
    'Zuñeda': 1200,
    'Ajalvir': 1158,
    'Alameda del Valle': 1158,
    'Alcalá de Henares': 1158,
    'Alcobendas': 1158,
    'Alcorcón': 1158,
    'Aldea del Fresno': 1158,
    'Algete': 1158,
    'Alpedrete': 1158,
    'Ambite': 1158,
    'Anchuelo': 1158,
    'Aranjuez': 1158,
    'Arganda': 1158,
    'Arganzuela': 1158,
    'Arroyomolinos': 1158,
    'Barajas de Madrid': 1158,
    'Batres': 1158,
    'Becerril de la Sierra': 1158,
    'Belmonte de Tajo': 1158,
    'Berzosa del Lozoya': 1158,
    'Boadilla del Monte': 1158,
    'Boalo': 1158,
    'Braojos': 1158,
    'Brea de Tajo': 1158,
    'Brunete': 1158,
    'Buitrago del Lozoya': 1158,
    'Bustarviejo': 1158,
    'Cabanillas de la Sierra': 1158,
    'Cadalso de los Vidrios': 1158,
    'Camarma de Esteruelas': 1158,
    'Campo Real': 1158,
    'Canencia': 1158,
    'Carabaña': 1158,
    'Carabanchel': 1158,
    'Casarrubuelos': 1158,
    'Cenicientos': 1158,
    'Cercedilla': 1158,
    'Cervera de Buitrago': 1158,
    'Chamartín': 1158,
    'Chamberí': 1158,
    'Chapinería': 1158,
    'Chinchón': 1158,
    'Ciempozuelos': 1158,
    'City Center': 1158,
    'Ciudad Lineal': 1158,
    'Cobeña': 1158,
    'Collado Mediano': 1158,
    'Collado-Villalba': 1158,
    'Colmenar de Oreja': 1158,
    'Colmenar del Arroyo': 1158,
    'Colmenar Viejo': 1158,
    'Colmenarejo': 1158,
    'Corpa': 1158,
    'Coslada': 1158,
    'Cubas': 1158,
    'Daganzo de Arriba': 1158,
    'El Álamo': 1158,
    'El Escorial': 1158,
    'El Molar': 1158,
    'El Pardo': 1158,
    'El Vellón': 1158,
    'Estremera': 1158,
    'Fresnedillas': 1158,
    'Fresno de Torote': 1158,
    'Fuencarral-El Pardo': 1158,
    'Fuenlabrada': 1158,
    'Fuente el Saz': 1158,
    'Fuentidueña de Tajo': 1158,
    'Galapagar': 1158,
    'Garganta de los Montes': 1158,
    'Gascones': 1158,
    'Getafe': 1158,
    'Griñón': 1158,
    'Guadalix de la Sierra': 1158,
    'Guadarrama': 1158,
    'Horcajo de la Sierra': 1158,
    'Horcajuelo de la Sierra': 1158,
    'Hortaleza': 1158,
    'Hoyo de Manzanares': 1158,
    'Humanes de Madrid': 1158,
    'La Cabrera': 1158,
    'Las Matas': 1158,
    'Las Rozas de Madrid': 1158,
    'Las Tablas': 1158,
    'Latina': 1158,
    'Leganés': 1158,
    'Loeches': 1158,
    'Los Molinos': 1158,
    'Los Santos de la Humosa': 1158,
    'Lozoya': 1158,
    'Madrid': 1158,
    'Majadahonda': 1158,
    'Manzanares el Real': 1158,
    'Meco': 1158,
    'Mejorada del Campo': 1158,
    'Miraflores de la Sierra': 1158,
    'Moncloa-Aravaca': 1158,
    'Montejo de la Sierra': 1158,
    'Moraleja de Enmedio': 1158,
    'Moralzarzal': 1158,
    'Morata de Tajuña': 1158,
    'Moratalaz': 1158,
    'Móstoles': 1158,
    'Navacerrada': 1158,
    'Navalafuente': 1158,
    'Navalagamella': 1158,
    'Navalcarnero': 1158,
    'Navalquejigo': 1158,
    'Navas del Rey': 1158,
    'Nuevo Baztán': 1158,
    'Paracuellos de Jarama': 1158,
    'Parla': 1158,
    'Patones': 1158,
    'Pedrezuela': 1158,
    'Pelayos de la Presa': 1158,
    'Perales de Tajuña': 1158,
    'Pezuela de las Torres': 1158,
    'Pinar de Chamartín': 1158,
    'Pinilla del Valle': 1158,
    'Pinto': 1158,
    'Pozuelo de Alarcón': 1158,
    'Pozuelo del Rey': 1158,
    'Prádena del Rincón': 1158,
    'Provincia de Madrid': 1158,
    'Puente de Vallecas': 1158,
    'Quijorna': 1158,
    'Rascafría': 1158,
    'Redueña': 1158,
    'Retiro': 1158,
    'Ribatejada': 1158,
    'Rivas-Vaciamadrid': 1158,
    'Robledillo de la Jara': 1158,
    'Robledo de Chavela': 1158,
    'Rozas de Puerto Real': 1158,
    'Salamanca': 1158,
    'San Agustín del Guadalix': 1158,
    'San Blas-Canillejas': 1158,
    'San Fernando de Henares': 1158,
    'San Lorenzo de El Escorial': 1158,
    'San Martín de la Vega': 1158,
    'San Martín de Valdeiglesias': 1158,
    'San Sebastián de los Reyes': 1158,
    'Santa María de la Alameda': 1158,
    'Santorcaz': 1158,
    'Serranillos del Valle': 1158,
    'Sevilla La Nueva': 1158,
    'Talamanca de Jarama': 1158,
    'Tetuán de las Victorias': 1158,
    'Tielmes': 1158,
    'Titulcia': 1158,
    'Torrejón de Ardoz': 1158,
    'Torrejón de la Calzada': 1158,
    'Torrejón de Velasco': 1158,
    'Torrelaguna': 1158,
    'Torrelodones': 1158,
    'Torremocha de Jarama': 1158,
    'Torres de la Alameda': 1158,
    'Tres Cantos': 1158,
    'Usera': 1158,
    'Valdaracete': 1158,
    'Valdeavero': 1158,
    'Valdemanco': 1158,
    'Valdemaqueda': 1158,
    'Valdemorillo': 1158,
    'Valdemoro': 1158,
    'Valdeolmos': 1158,
    'Valdepiélagos': 1158,
    'Valdetorres de Jarama': 1158,
    'Valdilecha': 1158,
    'Valverde de Alcalá': 1158,
    'Velilla de San Antonio': 1158,
    'Venturada': 1158,
    'Vicálvaro': 1158,
    'Villa de Vallecas': 1158,
    'Villa del Prado': 1158,
    'Villaconejos': 1158,
    'Villalbilla': 1158,
    'Villamanrique de Tajo': 1158,
    'Villamanta': 1158,
    'Villamantilla': 1158,
    'Villanueva de la Cañada': 1158,
    'Villanueva de Perales': 1158,
    'Villanueva del Pardillo': 1158,
    'Villar del Olmo': 1158,
    'Villarejo de Salvanés': 1158,
    'Villaverde': 1158,
    'Villaviciosa de Odón': 1158,
    'Villavieja del Lozoya': 1158,
    'Zarzalejo': 1158,
    'Melilla': 1159,
    'Abanilla': 1176,
    'Abarán': 1176,
    'Águilas': 1176,
    'Albudeite': 1176,
    'Alcantarilla': 1176,
    'Aledo': 1176,
    'Alguazas': 1176,
    'Alhama de Murcia': 1176,
    'Archena': 1176,
    'Atamaría': 1176,
    'Barrio de la Concepción': 1176,
    'Beniel': 1176,
    'Blanca': 1176,
    'Bullas': 1176,
    'Calasparra': 1176,
    'Campos del Río': 1176,
    'Caravaca': 1176,
    'Cartagena': 1176,
    'Cehegín': 1176,
    'Ceuti': 1176,
    'Cieza': 1176,
    'Cobatillas': 1176,
    'El Plan': 1176,
    'Esparragal': 1176,
    'Fortuna': 1176,
    'Fuente-Álamo de Murcia': 1176,
    'Jumilla': 1176,
    'La Manga del Mar Menor': 1176,
    'La Torrecilla': 1176,
    'La Unión': 1176,
    'Las Torres de Cotillas': 1176,
    'Librilla': 1176,
    'Lo Pagán': 1176,
    'Lorca': 1176,
    'Lorquí': 1176,
    'Los Alcázares': 1176,
    'Los Gabatos': 1176,
    'Los Martínez': 1176,
    'Mazarrón': 1176,
    'Molina de Segura': 1176,
    'Moratalla': 1176,
    'Mula': 1176,
    'Murcia': 1176,
    'Ojós': 1176,
    'Pliego': 1176,
    'Puerto Lumbreras': 1176,
    'Ricote': 1176,
    'San Javier': 1176,
    'San Pedro del Pinatar': 1176,
    'Sangonera la Verde': 1176,
    'Santa Cruz': 1176,
    'Santiago de la Ribera': 1176,
    'Santomera': 1176,
    'Torre-Pacheco': 1176,
    'Totana': 1176,
    'Ulea': 1176,
    'Yecla': 1176,
    'Abáigar': 1204,
    'Adiós': 1204,
    'Aguilar de Codés': 1204,
    'Allo': 1204,
    'Altsasu': 1204,
    'Ancín': 1204,
    'Andosilla': 1204,
    'Añorbe': 1204,
    'Aoiz': 1204,
    'Aranarache': 1204,
    'Arano': 1204,
    'Aras': 1204,
    'Arbizu': 1204,
    'Arellano': 1204,
    'Areso': 1204,
    'Arguedas': 1204,
    'Aria': 1204,
    'Armañanzas': 1204,
    'Arróniz': 1204,
    'Arruazu': 1204,
    'Artajona': 1204,
    'Artazu': 1204,
    'Atarrabia': 1204,
    'Ayegui': 1204,
    'Azagra': 1204,
    'Azuelo': 1204,
    'Bakaiku': 1204,
    'Barañáin': 1204,
    'Barásoain': 1204,
    'Bargota': 1204,
    'Barillas': 1204,
    'Beire': 1204,
    'Belascoáin': 1204,
    'Bera': 1204,
    'Berbinzana': 1204,
    'Beriáin': 1204,
    'Berriobeiti': 1204,
    'Berriozar': 1204,
    'Betelu': 1204,
    'Buñuel': 1204,
    'Burlata': 1204,
    'Cabredo': 1204,
    'Cadreita': 1204,
    'Caparroso': 1204,
    'Cárcar': 1204,
    'Carcastillo': 1204,
    'Cascante': 1204,
    'Cáseda': 1204,
    'Castejón': 1204,
    'Castillonuevo': 1204,
    'Cintruénigo': 1204,
    'Cirauqui': 1204,
    'Ciriza': 1204,
    'Cizur Mayor': 1204,
    'Corella': 1204,
    'Desojo': 1204,
    'Dicastillo': 1204,
    'Doneztebe': 1204,
    'Echarri': 1204,
    'Echarri-Aranaz': 1204,
    'Elgorriaga': 1204,
    'Elorz': 1204,
    'Enériz': 1204,
    'Ermitagaña': 1204,
    'Eslava': 1204,
    'Espronceda': 1204,
    'Estella-Lizarra': 1204,
    'Etayo': 1204,
    'Eulate': 1204,
    'Falces': 1204,
    'Fitero': 1204,
    'Fontellas': 1204,
    'Funes': 1204,
    'Fustiñana': 1204,
    'Galar': 1204,
    'Gallipienzo': 1204,
    'Garde': 1204,
    'Garínoain': 1204,
    'Garralda': 1204,
    'Genevilla': 1204,
    'Goizueta': 1204,
    'Goñi': 1204,
    'Guirguillano': 1204,
    'Huarte-Uharte': 1204,
    'Igantzi': 1204,
    'Igúzquiza': 1204,
    'Irañeta': 1204,
    'Ituren': 1204,
    'Iturmendi': 1204,
    'Iturrama': 1204,
    'Javier': 1204,
    'Lapoblación': 1204,
    'Larraga': 1204,
    'Larraona': 1204,
    'Lazagurría': 1204,
    'Leache': 1204,
    'Legarda': 1204,
    'Legaria': 1204,
    'Lerga': 1204,
    'Lerín': 1204,
    'Lezáun': 1204,
    'Liédena': 1204,
    'Lizoáin': 1204,
    'Lodosa': 1204,
    'Los Arcos': 1204,
    'Lumbier': 1204,
    'Mañeru': 1204,
    'Marañón': 1204,
    'Marcilla': 1204,
    'Mélida': 1204,
    'Mendavia': 1204,
    'Mendigorría': 1204,
    'Metauten': 1204,
    'Milagro': 1204,
    'Mirafuentes': 1204,
    'Miranda de Arga': 1204,
    'Monreal': 1204,
    'Morentin': 1204,
    'Murchante': 1204,
    'Murieta': 1204,
    'Murillo el Fruto': 1204,
    'Muruzábal': 1204,
    'Navascués': 1204,
    'Nazar': 1204,
    'Obanos': 1204,
    'Oco': 1204,
    'Olazagutía': 1204,
    'Olejua': 1204,
    'Olite': 1204,
    'Olóriz': 1204,
    'Oltza': 1204,
    'Orbara': 1204,
    'Orísoain': 1204,
    'Orkoien': 1204,
    'Oronz': 1204,
    'Oteiza': 1204,
    'Pamplona': 1204,
    'Peralta': 1204,
    'Petilla de Aragón': 1204,
    'Piedramillera': 1204,
    'Pitillas': 1204,
    'Primer Ensanche': 1204,
    'Provincia de Navarra': 1204,
    'Puente la Reina': 1204,
    'Pueyo': 1204,
    'Ribaforada': 1204,
    'Saldías': 1204,
    'Salinas de Oro': 1204,
    'San Adrián': 1204,
    'San Martín de Unx': 1204,
    'Sangüesa/Zangoza': 1204,
    'Sansol': 1204,
    'Santacara': 1204,
    'Sarriguren': 1204,
    'Sartaguda': 1204,
    'Segundo Ensanche': 1204,
    'Sesma': 1204,
    'Sorlada': 1204,
    'Tafalla': 1204,
    'Tirapu': 1204,
    'Torres del Río': 1204,
    'Tudela': 1204,
    'Tulebras': 1204,
    'Ujué': 1204,
    'Unzué': 1204,
    'Urzainqui': 1204,
    'Uterga': 1204,
    'Valtierra': 1204,
    'Villafranca': 1204,
    'Villamayor de Monjardín': 1204,
    'Villatuerta': 1204,
    'Yerri': 1204,
    'Yesa': 1204,
    'Zubieta': 1204,
    'Zugarramurdi': 1204,
    'Zúñiga': 1204,
    'Ademuz': 1175,
    'Ador': 1175,
    'Adsubia': 1175,
    'Adzaneta': 1175,
    'Agost': 1175,
    'Agres': 1175,
    'Agullent': 1175,
    'Aielo de Malferit': 1175,
    'Alaquàs': 1175,
    'Albaida': 1175,
    'Albal': 1175,
    'Albalat de la Ribera': 1175,
    'Albalat dels Sorells': 1175,
    'Albalat dels Tarongers': 1175,
    'Albatera': 1175,
    'Alberic': 1175,
    'Albocàsser': 1175,
    'Alborache': 1175,
    'Alboraya': 1175,
    'Albuixech': 1175,
    'Alcalà de Xivert': 1175,
    'Alcalalí': 1175,
    'Alcàntera de Xúquer': 1175,
    'Alcàsser': 1175,
    'Alcocéber': 1175,
    'Alcocer de Planes': 1175,
    'Alcoy': 1175,
    'Alcublas': 1175,
    'Alcudia de Veo': 1175,
    'Aldaia': 1175,
    'Alfafar': 1175,
    'Alfafara': 1175,
    'Alfara de Algimia': 1175,
    'Alfara del Patriarca': 1175,
    'Alfarp': 1175,
    'Alfarrasí': 1175,
    'Alfauir': 1175,
    'Alfondeguilla': 1175,
    'Algemesí': 1175,
    'Algimia de Alfara': 1175,
    'Algimia de Almonacid': 1175,
    'Alginet': 1175,
    'Algorfa': 1175,
    'Algueña': 1175,
    'Alicante': 1175,
    'Almassora': 1175,
    'Almedíjar': 1175,
    'Almenara': 1175,
    'Almiserà': 1175,
    'Almoines': 1175,
    'Almoradí': 1175,
    'Almudaina': 1175,
    'Almussafes': 1175,
    'Alpuente': 1175,
    'Alquerías del Niño Perdido': 1175,
    'Altea': 1175,
    'Altura': 1175,
    'Alzira': 1175,
    'Andilla': 1175,
    'Anna': 1175,
    'Antella': 1175,
    'Arañuel': 1175,
    'Ares del Maestre': 1175,
    'Argelita': 1175,
    'Artana': 1175,
    'Aspe': 1175,
    'Atzeneta d  Albaida': 1175,
    'Ayódar': 1175,
    'Ayora': 1175,
    'Azuébar': 1175,
    'Balones': 1175,
    'Barracas': 1175,
    'Barx': 1175,
    'Barxeta': 1175,
    'Bèlgida': 1175,
    'Bellreguard': 1175,
    'Bellús': 1175,
    'Benafigos': 1175,
    'Benagéber': 1175,
    'Benaguasil': 1175,
    'Benasau': 1175,
    'Benassal': 1175,
    'Benavites': 1175,
    'Beneixama': 1175,
    'Beneixida': 1175,
    'Benejúzar': 1175,
    'Benetússer': 1175,
    'Benferri': 1175,
    'Beniarbeig': 1175,
    'Beniardá': 1175,
    'Beniarjó': 1175,
    'Beniarrés': 1175,
    'Beniatjar': 1175,
    'Benicarló': 1175,
    'Benicàssim': 1175,
    'Benicolet': 1175,
    'Benidoleig': 1175,
    'Benidorm': 1175,
    'Benifaió': 1175,
    'Benifairó de les Valls': 1175,
    'Benifallim': 1175,
    'Beniflá': 1175,
    'Benigànim': 1175,
    'Benijofar': 1175,
    'Benilloba': 1175,
    'Benillup': 1175,
    'Benimantell': 1175,
    'Benimarfull': 1175,
    'Benimassot': 1175,
    'Benimeli': 1175,
    'Benimodo': 1175,
    'Benimuslem': 1175,
    'Beniparrell': 1175,
    'Benirredrà': 1175,
    'Benisanó': 1175,
    'Benissa': 1175,
    'Benissoda': 1175,
    'Benisuera': 1175,
    'Benitachell': 1175,
    'Benlloch': 1175,
    'Bétera': 1175,
    'Betxí': 1175,
    'Biar': 1175,
    'Bicorp': 1175,
    'Bigastro': 1175,
    'Bocairent': 1175,
    'Bolbaite': 1175,
    'Bolulla': 1175,
    'Borriol': 1175,
    'Bufali': 1175,
    'Bugarra': 1175,
    'Buñol': 1175,
    'Burjassot': 1175,
    'Burriana': 1175,
    'Busot': 1175,
    'Cabanes': 1175,
    'Càlig': 1175,
    'Calles': 1175,
    'Callosa de Segura': 1175,
    'Calp': 1175,
    'Camporrobles': 1175,
    'Canals': 1175,
    'Canet d  En Berenguer': 1175,
    'Canet lo Roig': 1175,
    'Carcaixent': 1175,
    'Càrcer': 1175,
    'Carlet': 1175,
    'Carrícola': 1175,
    'Casas Altas': 1175,
    'Casas Bajas': 1175,
    'Casinos': 1175,
    'Castalla': 1175,
    'Castell de Cabres': 1175,
    'Castell de Castells': 1175,
    'Castellfort': 1175,
    'Castellnovo': 1175,
    'Castelló de la Plana': 1175,
    'Castelló de Rugat': 1175,
    'Castellonet de la Conquesta': 1175,
    'Castielfabib': 1175,
    'Castillo de Villamalefa': 1175,
    'Catadau': 1175,
    'Catarroja': 1175,
    'Catí': 1175,
    'Catral': 1175,
    'Caudete de las Fuentes': 1175,
    'Cerdà': 1175,
    'Cervera del Maestre': 1175,
    'Chella': 1175,
    'Chelva': 1175,
    'Chera': 1175,
    'Chert/Xert': 1175,
    'Cheste': 1175,
    'Chilches': 1175,
    'Chiva': 1175,
    'Chóvar': 1175,
    'Chulilla': 1175,
    'Cinctorres': 1175,
    'Cirat': 1175,
    'Cocentaina': 1175,
    'Cofrentes': 1175,
    'Confrides': 1175,
    'Corbera': 1175,
    'Cortes de Arenoso': 1175,
    'Cortes de Pallás': 1175,
    'Costur': 1175,
    'Cotes': 1175,
    'Cox': 1175,
    'Crevillente': 1175,
    'Cuevas de Vinromá': 1175,
    'Culla': 1175,
    'Cullera': 1175,
    'Daimús': 1175,
    'Daya Nueva': 1175,
    'Daya Vieja': 1175,
    'Denia': 1175,
    'Dolores': 1175,
    'Domeño': 1175,
    'Dos Aguas': 1175,
    'El Campello': 1175,
    'El Grao': 1175,
    'El Perelló': 1175,
    'Elche': 1175,
    'Elda': 1175,
    'els Poblets': 1175,
    'Emperador': 1175,
    'Enguera': 1175,
    'Eslida': 1175,
    'Espadilla': 1175,
    'Estivella': 1175,
    'Estubeny': 1175,
    'Facheca': 1175,
    'Famorca': 1175,
    'Fanzara': 1175,
    'Faura': 1175,
    'Favara': 1175,
    'Figueroles': 1175,
    'Finestrat': 1175,
    'Foios': 1175,
    'Fontanars dels Alforins': 1175,
    'Forcall': 1175,
    'Formentera de Segura': 1175,
    'Fortaleny': 1175,
    'Fuente la Reina': 1175,
    'Fuenterrobles': 1175,
    'Fuentes de Ayódar': 1175,
    'Gaibiel': 1175,
    'Gandia': 1175,
    'Gata de Gorgos': 1175,
    'Gavarda': 1175,
    'Geldo': 1175,
    'Genovés': 1175,
    'Gestalgar': 1175,
    'Gilet': 1175,
    'Godella': 1175,
    'Godelleta': 1175,
    'Gorga': 1175,
    'Granja de Rocamora': 1175,
    'Grao de Murviedro': 1175,
    'Guadasequies': 1175,
    'Guadassuar': 1175,
    'Guardamar del Segura': 1175,
    'Herbés': 1175,
    'Higueras': 1175,
    'Higueruelas': 1175,
    'Hondón de las Nieves': 1175,
    'Hondón de los Frailes': 1175,
    'Ibi': 1175,
    'Jacarilla': 1175,
    'Jalance': 1175,
    'Jalón': 1175,
    'Jarafuel': 1175,
    'Javea': 1175,
    'Jérica': 1175,
    'Jijona': 1175,
    'l  Alcora': 1175,
    'L  Alcúdia': 1175,
    'L  Alcúdia de Crespìns': 1175,
    'l  Alfàs del Pi': 1175,
    'L  Alqueria de la Comtessa': 1175,
    'L  Eliana': 1175,
    'L  Olleria': 1175,
    'La Font de la Figuera': 1175,
    'la Nucia': 1175,
    'La Pobla de Farnals': 1175,
    'La Pobla de Vallbona': 1175,
    'La Pobla Llarga': 1175,
    'La Romana': 1175,
    'La Yesa': 1175,
    'Llanera de Ranes': 1175,
    'Llaurí': 1175,
    'Llíria': 1175,
    'Llocnou de Sant Jeroni': 1175,
    'Llombai': 1175,
    'Llosa de Ranes': 1175,
    'Llutxent': 1175,
    'Loriguilla': 1175,
    'Los Montesinos': 1175,
    'Losa del Obispo': 1175,
    'Lucena del Cid': 1175,
    'Ludiente': 1175,
    'Macastre': 1175,
    'Manises': 1175,
    'Manuel': 1175,
    'Marines': 1175,
    'Masalavés': 1175,
    'Massamagrell': 1175,
    'Matet': 1175,
    'Meliana': 1175,
    'Millares': 1175,
    'Millena': 1175,
    'Miramar': 1175,
    'Mislata': 1175,
    'Mogente': 1175,
    'Moncada': 1175,
    'Moncofa': 1175,
    'Monforte del Cid': 1175,
    'Monóvar': 1175,
    'Monserrat': 1175,
    'Montaberner': 1175,
    'Montán': 1175,
    'Montanejos': 1175,
    'Montesa': 1175,
    'Montichelvo': 1175,
    'Montroy': 1175,
    'Moraira': 1175,
    'Morella': 1175,
    'Murla': 1175,
    'Muro del Alcoy': 1175,
    'Museros': 1175,
    'Mutxamel': 1175,
    'Náquera': 1175,
    'Navajas': 1175,
    'Navarrés': 1175,
    'Novelda': 1175,
    'Nules': 1175,
    'Oliva': 1175,
    'Olocau': 1175,
    'Olocau del Rey': 1175,
    'Onda': 1175,
    'Ondara': 1175,
    'Onil': 1175,
    'Ontinyent': 1175,
    'Orba': 1175,
    'Orcheta': 1175,
    'Orihuela': 1175,
    'Oropesa del Mar': 1175,
    'Otos': 1175,
    'Paiporta': 1175,
    'Palanques': 1175,
    'Palma de Gandía': 1175,
    'Palmera': 1175,
    'Parcent': 1175,
    'Paterna': 1175,
    'Pavías': 1175,
    'Pedralba': 1175,
    'Pedreguer': 1175,
    'Pego': 1175,
    'Peníscola': 1175,
    'Petrés': 1175,
    'Picanya': 1175,
    'Picassent': 1175,
    'Pilar de la Horadada': 1175,
    'Piles': 1175,
    'Pina de Montalgrao': 1175,
    'Pinet': 1175,
    'Pinoso': 1175,
    'Planes': 1175,
    'Polinyà de Xúquer': 1175,
    'Polop': 1175,
    'Portell de Morella': 1175,
    'Potríes': 1175,
    'Provincia de Alicante': 1175,
    'Província de Castelló': 1175,
    'Província de València': 1175,
    'Puçol': 1175,
    'Puebla de Arenoso': 1175,
    'Puebla de San Miguel': 1175,
    'Puig': 1175,
    'Quart de les Valls': 1175,
    'Quart de Poblet': 1175,
    'Quartell': 1175,
    'Quatretonda': 1175,
    'Quesa': 1175,
    'Rafal': 1175,
    'Rafelcofer': 1175,
    'Rafelguaraf': 1175,
    'Ráfol de Salem': 1175,
    'Real de Gandía': 1175,
    'Real de Montroi': 1175,
    'Redován': 1175,
    'Relleu': 1175,
    'Requena': 1175,
    'Ribarroja del Turia': 1175,
    'Ribesalbes': 1175,
    'Riola': 1175,
    'Rocafort': 1175,
    'Rojales': 1175,
    'Rosell': 1175,
    'Rotglá y Corbera': 1175,
    'Rótova': 1175,
    'Rugat': 1175,
    'Sacañet': 1175,
    'Sagra': 1175,
    'Sagunto': 1175,
    'Salinas': 1175,
    'San Juan de Alicante': 1175,
    'San Juan de Énova': 1175,
    'San Juan de Moró': 1175,
    'San Miguel de Salinas': 1175,
    'San Vicent del Raspeig': 1175,
    'Sanet y Negrals': 1175,
    'Sant Jordi': 1175,
    'Santa Magdalena de Pulpis': 1175,
    'Santa Pola': 1175,
    'Sarratella': 1175,
    'Sax': 1175,
    'Sedaví': 1175,
    'Segart': 1175,
    'Segorbe': 1175,
    'Sella': 1175,
    'Sellent': 1175,
    'Sempere': 1175,
    'Senija': 1175,
    'Senyera': 1175,
    'Serra': 1175,
    'Sierra-Engarcerán': 1175,
    'Siete Aguas': 1175,
    'Silla': 1175,
    'Simat de la Valldigna': 1175,
    'Sinarcas': 1175,
    'Sollana': 1175,
    'Soneja': 1175,
    'Sot de Chera': 1175,
    'Sot de Ferrer': 1175,
    'Sueca': 1175,
    'Sumacàrcer': 1175,
    'Tales': 1175,
    'Tavernes Blanques': 1175,
    'Tavernes de la Valldigna': 1175,
    'Terrateig': 1175,
    'Teulada': 1175,
    'Tibi': 1175,
    'Titaguas': 1175,
    'Todolella': 1175,
    'Toga': 1175,
    'Tollos': 1175,
    'Torás': 1175,
    'Tormos': 1175,
    'Torralba del Pinar': 1175,
    'Torre de la Horadada': 1175,
    'Torreblanca': 1175,
    'Torrechiva': 1175,
    'Torrella': 1175,
    'Torrent': 1175,
    'Torrevieja': 1175,
    'Tous': 1175,
    'Traiguera': 1175,
    'Tuéjar': 1175,
    'Turís': 1175,
    'Utiel': 1175,
    'Valencia': 1175,
    'Vall de Almonacid': 1175,
    'Vall de Ebo': 1175,
    'Vallada': 1175,
    'Vallanca': 1175,
    'Vallés': 1175,
    'Vallibona': 1175,
    'Venta del Moro': 1175,
    'Vergel': 1175,
    'Vila-real': 1175,
    'Vilamarxant': 1175,
    'Villafamés': 1175,
    'Villafranca del Cid': 1175,
    'Villahermosa del Río': 1175,
    'Villajoyosa': 1175,
    'Villalonga': 1175,
    'Villamalur': 1175,
    'Villanueva de Castellón': 1175,
    'Villanueva de Viver': 1175,
    'Villar del Arzobispo': 1175,
    'Villargordo del Cabriel': 1175,
    'Villavieja': 1175,
    'Villena': 1175,
    'Villores': 1175,
    'Vinalesa': 1175,
    'Vinaròs': 1175,
    'Vistabella del Maestrazgo': 1175,
    'Viver': 1175,
    'Xàtiva': 1175,
    'Xeraco,Jaraco': 1175,
    'Xeresa': 1175,
    'Xirivella': 1175,
    'Yátova': 1175,
    'Zarra': 1175,
    'Zorita del Maestrazgo': 1175,
    'Zucaina': 1175,
    'Dambulla': 2798,
    'Hatton': 2798,
    'Kandy': 2798,
    'Kandy District': 2798,
    'Matale': 2798,
    'Matale District': 2798,
    'Nuwara Eliya': 2798,
    'Nuwara Eliya District': 2798,
    'Sigiriya': 2798,
    'Talawakele': 2798,
    'Dehiwala-Mount Lavinia': 2815,
    'Homagama': 2815,
    'Kaduwela': 2815,
    'Kesbewa': 2815,
    'Kolonnawa': 2815,
    'Kotte': 2815,
    'Maharagama': 2815,
    'Moratuwa': 2815,
    'Padukka': 2815,
    'Ratmalana': 2815,
    'Seethawaka': 2815,
    'Thimbirigasyaya': 2815,
    'Ampara': 2808,
    'Ampara District': 2808,
    'Batticaloa': 2808,
    'Batticaloa District': 2808,
    'Eravur Town': 2808,
    'Kalmunai': 2808,
    'Trincomalee': 2808,
    'Trincomalee District': 2808,
    'Vakarai': 2808,
    'Akurana': 2788,
    'Alawatugoda': 2788,
    'Ambatenna': 2788,
    'Daskara': 2788,
    'Daulagala': 2788,
    'Delthota': 2788,
    'Doluwa': 2788,
    'Galagedara': 2788,
    'Galhinna': 2788,
    'Gampola': 2788,
    'Ganga Ihala Korale': 2788,
    'Hanguranketa': 2788,
    'Hapugastalawa': 2788,
    'Harispattuwa': 2788,
    'Hatharaliyadda': 2788,
    'Kadugannawa': 2788,
    'Kandy Four Gravets': 2788,
    'Katugastota': 2788,
    'Kundasale': 2788,
    'Madawala': 2788,
    'Medadumbara': 2788,
    'Menikdiwela': 2788,
    'Minipe': 2788,
    'Nawalapitiya': 2788,
    'Pallekele': 2788,
    'Panvila': 2788,
    'Pasbage Korale': 2788,
    'Pathadumbara': 2788,
    'Pathahewaheta': 2788,
    'Peradeniya': 2788,
    'Pilimathalawa': 2788,
    'Poojapitiya': 2788,
    'Pussellawa': 2788,
    'Talatuoya': 2788,
    'Teldeniya': 2788,
    'Thumpane': 2788,
    'Udadumbara': 2788,
    'Udapalatha': 2788,
    'Ududumbara': 2788,
    'Udunuwara': 2788,
    'Ulapane': 2788,
    'Watadeniya': 2788,
    'Wattegama': 2788,
    'Welamboda': 2788,
    'Yatinuwara': 2788,
    'Anuradhapura': 2800,
    'Anuradhapura District': 2800,
    'Mihintale': 2800,
    'Polonnaruwa': 2800,
    'Polonnaruwa District': 2800,
    'Chilaw': 2817,
    'Kuliyapitiya': 2817,
    'Kurunegala': 2817,
    'Kurunegala District': 2817,
    'Puttalam': 2817,
    'Puttalam District': 2817,
    'Jaffna': 2813,
    'Jaffna District': 2813,
    'Kilinochchi': 2813,
    'Kilinochchi District': 2813,
    'Point Pedro': 2813,
    'Valvedditturai': 2813,
    'Vavuniya': 2813,
    'Vavuniya District': 2813,
    'Kegalle': 2803,
    'Kegalle District': 2803,
    'Ratnapura': 2803,
    'Ratnapura District': 2803,
    'Ambalangoda': 2801,
    'Bentota': 2801,
    'Devinuwara': 2801,
    'Galle': 2801,
    'Galle District': 2801,
    'Hambantota District': 2801,
    'Hikkaduwa': 2801,
    'Koggala': 2801,
    'Matara': 2801,
    'Matara District': 2801,
    'Mirissa city': 2801,
    'Talpe': 2801,
    'Tangalle': 2801,
    'Unawatuna': 2801,
    'Weligama': 2801,
    'Badulla': 2811,
    'Badulla District': 2811,
    'Ella Town': 2811,
    'Haputale': 2811,
    'Kataragama': 2811,
    'Monaragala': 2811,
    'Moneragala District': 2811,
    'Wellawaya': 2811,
    'Battaramulla South': 2802,
    'Beruwala': 2802,
    'Colombo': 2802,
    'Colombo District': 2802,
    'Gampaha': 2802,
    'Gampaha District': 2802,
    'Hanwella Ihala': 2802,
    'Hendala': 2802,
    'Horana South': 2802,
    'Horawala Junction': 2802,
    'Ja Ela': 2802,
    'Kalutara': 2802,
    'Kandana': 2802,
    'Katunayaka': 2802,
    'Kelaniya': 2802,
    'Kotikawatta': 2802,
    'Minuwangoda': 2802,
    'Mulleriyawa': 2802,
    'Negombo': 2802,
    'Panadura': 2802,
    'Peliyagoda': 2802,
    'Pita Kotte': 2802,
    'Sri Jayewardenepura Kotte': 2802,
    'Wattala': 2802,
    'Welisara': 2802,
    'Al Hasaheisa': 885,
    'Al Hilāliyya': 885,
    'Al Kiremit al ‘Arakiyyīn': 885,
    'Al Manāqil': 885,
    'Al Masallamiyya': 885,
    'Wad Medani': 885,
    'Wad Rāwah': 885,
    'Al Ḩawātah': 886,
    'Al Qadarif': 886,
    'Doka': 886,
    'Ad-Damazin': 887,
    'Ar Ruseris': 887,
    'Kurmuk': 887,
    'Zalingei': 896,
    'El Daein': 892,
    'Aroma': 884,
    'Kassala': 884,
    'Wagar': 884,
    'Khartoum': 881,
    'Omdurman': 881,
    'El Fasher': 890,
    'Kutum': 890,
    'Umm Kaddadah': 890,
    'Ar Rahad': 893,
    'Bārah': 893,
    'El Obeid': 893,
    'Umm Ruwaba': 893,
    'Ad Dabbah': 895,
    'Argo': 895,
    'Dongola': 895,
    'Karmah an Nuzul': 895,
    'Kuraymah': 895,
    'Merowe': 895,
    'Gebeit': 880,
    'Port Sudan': 880,
    'Sawākin': 880,
    'Tokār': 880,
    'Atbara': 891,
    'Berber': 891,
    'Ed Damer': 891,
    'El Bauga': 891,
    'El Matama': 891,
    'Shendi': 891,
    'Ad Dindar': 882,
    'As Sūkī': 882,
    'Jalqani': 882,
    'Kināna': 882,
    'Maiurno': 882,
    'Singa': 882,
    'Sinnar': 882,
    'Gereida': 894,
    'Nyala': 894,
    'Abu Jibeha': 883,
    'Al Fūlah': 883,
    'Dilling': 883,
    'Kadugli': 883,
    'Talodi': 883,
    'Geneina': 888,
    'Abū Zabad': 889,
    'Al Lagowa': 889,
    'Al Mijlad': 889,
    'An Nuhūd': 889,
    'Ad Douiem': 879,
    'Al Kawa': 879,
    'Al Qiţena': 879,
    'Kosti': 879,
    'Marabba': 879,
    'Rabak': 879,
    'Tandaltī': 879,
    'Um Jar Al Gharbiyya': 879,
    'Wad az Zāki': 879,
    'Brokopondo': 2846,
    'Brownsweg': 2846,
    'Mariënburg': 2839,
    'Nieuw Amsterdam': 2839,
    'Totness': 2842,
    'Albina': 2845,
    'Moengo': 2845,
    'Nieuw Nickerie': 2840,
    'Wageningen': 2840,
    'Onverwacht': 2841,
    'Paramaribo': 2843,
    'Groningen': 2848,
    'Lelydorp': 2844,
    'Bulembu': 969,
    'Hhukwini': 969,
    'Lobamba': 969,
    'Mbabane': 969,
    'Nkhaba': 969,
    'Piggs Peak': 969,
    'Big Bend': 970,
    'Dvokodvweni Inkhundla': 970,
    'Lomashasha': 970,
    'Mhlume': 970,
    'Nsoko': 970,
    'Siteki': 970,
    'Tshaneni': 970,
    'Vuvulane': 970,
    'Bhunya': 968,
    'Ekukhanyeni': 968,
    'Kwaluseni': 968,
    'Malkerns': 968,
    'Manzini': 968,
    'Manzini South': 968,
    'Mhlambanyatsi': 968,
    'Ngwempisi': 968,
    'Ntondozi': 968,
    'Sidvokodvo': 968,
    'Hlatikulu': 971,
    'Hluti': 971,
    'Kubuta': 971,
    'Lavumisa': 971,
    'Matsanjeni': 971,
    'Ngudzeni': 971,
    'Nhlangano': 971,
    'Nkwene': 971,
    'Sigwe Inkhundla': 971,
    'Zombodze Ikhundla': 971,
    'Bräkne-Hoby': 1537,
    'Hällevik': 1537,
    'Hasslö': 1537,
    'Jämjö': 1537,
    'Jämshög': 1537,
    'Kallinge': 1537,
    'Karlshamn': 1537,
    'Karlshamns kommun': 1537,
    'Karlskrona': 1537,
    'Karlskrona Kommun': 1537,
    'Mjällby': 1537,
    'Mörrum': 1537,
    'Nättraby': 1537,
    'Olofström': 1537,
    'Olofströms Kommun': 1537,
    'Rödeby': 1537,
    'Ronneby': 1537,
    'Ronneby Kommun': 1537,
    'Sölvesborg': 1537,
    'Sölvesborgs kommun': 1537,
    'Sturkö': 1537,
    'Svängsta': 1537,
    'Abborrberget': 1534,
    'Älvdalen': 1534,
    'Älvdalens kommun': 1534,
    'Avesta': 1534,
    'Avesta Kommun': 1534,
    'Bjursås': 1534,
    'Borlänge': 1534,
    'Borlänge Kommun': 1534,
    'Brunna': 1534,
    'Djurås': 1534,
    'Enbacka': 1534,
    'Falu kommun': 1534,
    'Falun': 1534,
    'Gagnef': 1534,
    'Gagnefs Kommun': 1534,
    'Grängesberg': 1534,
    'Grycksbo': 1534,
    'Hedemora': 1534,
    'Hedemora Kommun': 1534,
    'Horndal': 1534,
    'Insjön': 1534,
    'Järna': 1534,
    'Krylbo': 1534,
    'Långshyttan': 1534,
    'Leksand': 1534,
    'Leksands kommun': 1534,
    'Ludvika': 1534,
    'Ludvika Kommun': 1534,
    'Malung': 1534,
    'Malung-Sälens kommun': 1534,
    'Mockfjärd': 1534,
    'Mora': 1534,
    'Mora Kommun': 1534,
    'Nyhammar': 1534,
    'Ornäs': 1534,
    'Orsa': 1534,
    'Orsa Kommun': 1534,
    'Rättvik': 1534,
    'Rättviks Kommun': 1534,
    'Romme': 1534,
    'Säter': 1534,
    'Säters Kommun': 1534,
    'Siljansnäs': 1534,
    'Smedby': 1534,
    'Smedjebacken': 1534,
    'Smedjebackens Kommun': 1534,
    'Svärdsjö': 1534,
    'Vansbro': 1534,
    'Vansbro Kommun': 1534,
    'Vikarbyn': 1534,
    'Alfta': 1533,
    'Arbrå': 1533,
    'Årsunda': 1533,
    'Bergby': 1533,
    'Bergsjö': 1533,
    'Bollnäs': 1533,
    'Bollnäs Kommun': 1533,
    'Delsbo': 1533,
    'Edsbyn': 1533,
    'Färila': 1533,
    'Forsbacka': 1533,
    'Gävle': 1533,
    'Gävle Kommun': 1533,
    'Gnarp': 1533,
    'Hedesunda': 1533,
    'Hofors': 1533,
    'Hofors Kommun': 1533,
    'Hudiksvall': 1533,
    'Hudiksvalls Kommun': 1533,
    'Iggesund': 1533,
    'Järbo': 1533,
    'Järvsö': 1533,
    'Kilafors': 1533,
    'Ljusdal': 1533,
    'Ljusdals Kommun': 1533,
    'Ljusne': 1533,
    'Nordanstigs kommun': 1533,
    'Ockelbo': 1533,
    'Ockelbo Kommun': 1533,
    'Ovanåkers Kommun': 1533,
    'Sandarne': 1533,
    'Sandviken': 1533,
    'Sandvikens Kommun': 1533,
    'Söderhamn': 1533,
    'Söderhamns Kommun': 1533,
    'Sörforsa': 1533,
    'Storvik': 1533,
    'Valbo': 1533,
    'Gotland': 1546,
    'Hemse': 1546,
    'Klintehamn': 1546,
    'Vibble': 1546,
    'Visby': 1546,
    'Åled': 1548,
    'Åsa': 1548,
    'Bua': 1548,
    'Falkenberg': 1548,
    'Falkenbergs Kommun': 1548,
    'Fjärås kyrkby': 1548,
    'Frillesås': 1548,
    'Frösakull': 1548,
    'Fyllinge': 1548,
    'Getinge': 1548,
    'Glommen': 1548,
    'Gullbrandstorp': 1548,
    'Halmstad': 1548,
    'Halmstads Kommun': 1548,
    'Harplinge': 1548,
    'Haverdal': 1548,
    'Hylte Kommun': 1548,
    'Hyltebruk': 1548,
    'Knäred': 1548,
    'Kungsbacka': 1548,
    'Kungsbacka Kommun': 1548,
    'Laholm': 1548,
    'Laholms Kommun': 1548,
    'Mellbystrand': 1548,
    'Mjällby': 1548,
    'Onsala': 1548,
    'Oskarström': 1548,
    'Särö': 1548,
    'Skrea': 1548,
    'Steninge': 1548,
    'Torup': 1548,
    'Träslövsläge': 1548,
    'Trönninge': 1548,
    'Tvååker': 1548,
    'Vallda': 1548,
    'Varberg': 1548,
    'Varbergs Kommun': 1548,
    'Västra Hagen': 1548,
    'Veddige': 1548,
    'Veinge': 1548,
    'Anderstorp': 1550,
    'Aneby': 1550,
    'Aneby Kommun': 1550,
    'Bankeryd': 1550,
    'Bodafors': 1550,
    'Bor': 1550,
    'Bredaryd': 1550,
    'Ekenässjön': 1550,
    'Eksjö': 1550,
    'Eksjö Kommun': 1550,
    'Forserum': 1550,
    'Forsheda': 1550,
    'Gislaved': 1550,
    'Gislaveds Kommun': 1550,
    'Gnosjö': 1550,
    'Gnosjö Kommun': 1550,
    'Gränna': 1550,
    'Habo': 1550,
    'Habo Kommun': 1550,
    'Hestra': 1550,
    'Hillerstorp': 1550,
    'Huskvarna': 1550,
    'Jönköping': 1550,
    'Jönköpings Kommun': 1550,
    'Kaxholmen': 1550,
    'Landsbro': 1550,
    'Malmbäck': 1550,
    'Mariannelund': 1550,
    'Marieholm': 1550,
    'Mullsjö': 1550,
    'Mullsjö kommun': 1550,
    'Nässjö': 1550,
    'Nässjö Kommun': 1550,
    'Odensjö': 1550,
    'Reftele': 1550,
    'Rydaholm': 1550,
    'Sävsjö': 1550,
    'Sävsjö Kommun': 1550,
    'Skillingaryd': 1550,
    'Smålandsstenar': 1550,
    'Stockaryd': 1550,
    'Taberg': 1550,
    'Tenhult': 1550,
    'Tranås': 1550,
    'Tranås Kommun': 1550,
    'Vaggeryd': 1550,
    'Vaggeryds Kommun': 1550,
    'Värnamo': 1550,
    'Värnamo Kommun': 1550,
    'Vetlanda': 1550,
    'Vetlanda kommun': 1550,
    'Vrigstad': 1550,
    'Ankarsrum': 1544,
    'Bergkvara': 1544,
    'Blomstermåla': 1544,
    'Borgholm': 1544,
    'Borgholms Kommun': 1544,
    'Emmaboda': 1544,
    'Emmaboda Kommun': 1544,
    'Färjestaden': 1544,
    'Gamleby': 1544,
    'Gunnebo': 1544,
    'Högsby': 1544,
    'Högsby Kommun': 1544,
    'Hultsfred': 1544,
    'Hultsfreds Kommun': 1544,
    'Kalmar': 1544,
    'Kalmar Kommun': 1544,
    'Kristdala': 1544,
    'Lindsdal': 1544,
    'Ljungbyholm': 1544,
    'Målilla': 1544,
    'Mönsterås': 1544,
    'Mönsterås Kommun': 1544,
    'Mörbylånga': 1544,
    'Mörbylånga Kommun': 1544,
    'Nybro': 1544,
    'Nybro Kommun': 1544,
    'Oskarshamn': 1544,
    'Oskarshamns Kommun': 1544,
    'Överum': 1544,
    'Påskallavik': 1544,
    'Rinkabyholm': 1544,
    'Smedby': 1544,
    'Södra Sandby': 1544,
    'Södra Vi': 1544,
    'Storebro': 1544,
    'Timmernabben': 1544,
    'Torsås': 1544,
    'Torsås Kommun': 1544,
    'Trekanten': 1544,
    'Västervik': 1544,
    'Västerviks Kommun': 1544,
    'Vimmerby': 1544,
    'Vimmerby Kommun': 1544,
    'Virserum': 1544,
    'Älmhult': 1542,
    'Älmhults Kommun': 1542,
    'Alvesta': 1542,
    'Alvesta Kommun': 1542,
    'Åseda': 1542,
    'Braås': 1542,
    'Gemla': 1542,
    'Hovmantorp': 1542,
    'Ingelstad': 1542,
    'Lagan': 1542,
    'Lammhult': 1542,
    'Lenhovda': 1542,
    'Lessebo': 1542,
    'Lessebo Kommun': 1542,
    'Ljungby': 1542,
    'Ljungby Kommun': 1542,
    'Markaryd': 1542,
    'Markaryds Kommun': 1542,
    'Moheda': 1542,
    'Rottne': 1542,
    'Ryd': 1542,
    'Strömsnäsbruk': 1542,
    'Tingsryd': 1542,
    'Tingsryds Kommun': 1542,
    'Uppvidinge Kommun': 1542,
    'Växjö': 1542,
    'Växjö Kommun': 1542,
    'Vislanda': 1542,
    'Älvsbyn': 1538,
    'Älvsbyns Kommun': 1538,
    'Arjeplog': 1538,
    'Arjeplogs Kommun': 1538,
    'Arvidsjaur': 1538,
    'Arvidsjaurs Kommun': 1538,
    'Bergnäset': 1538,
    'Bergsviken': 1538,
    'Björkskatan': 1538,
    'Boden': 1538,
    'Bodens Kommun': 1538,
    'Gällivare': 1538,
    'Gällivare kommun': 1538,
    'Gammelstad': 1538,
    'Haparanda': 1538,
    'Haparanda Kommun': 1538,
    'Hortlax': 1538,
    'Jokkmokk': 1538,
    'Jokkmokks Kommun': 1538,
    'Kalix': 1538,
    'Kalix Kommun': 1538,
    'Kiruna': 1538,
    'Kiruna Kommun': 1538,
    'Luleå': 1538,
    'Luleå kommun': 1538,
    'Malmberget': 1538,
    'Marielund': 1538,
    'Norrfjärden': 1538,
    'Överkalix': 1538,
    'Överkalix Kommun': 1538,
    'Övertorneå': 1538,
    'Övertorneå Kommun': 1538,
    'Pajala': 1538,
    'Pajala Kommun': 1538,
    'Piteå': 1538,
    'Piteå Kommun': 1538,
    'Råneå': 1538,
    'Roknäs': 1538,
    'Rolfs': 1538,
    'Rosvik': 1538,
    'Sävast': 1538,
    'Södra Sunderbyn': 1538,
    'Töre': 1538,
    'Åsbro': 1539,
    'Askersund': 1539,
    'Askersunds Kommun': 1539,
    'Degerfors': 1539,
    'Degerfors Kommun': 1539,
    'Ekeby-Almby': 1539,
    'Fellingsbro': 1539,
    'Fjugesta': 1539,
    'Frövi': 1539,
    'Garphyttan': 1539,
    'Hällabrottet': 1539,
    'Hällefors': 1539,
    'Hällefors Kommun': 1539,
    'Hallsberg': 1539,
    'Hallsbergs Kommun': 1539,
    'Hovsta': 1539,
    'Karlskoga': 1539,
    'Karlskoga Kommun': 1539,
    'Kopparberg': 1539,
    'Kumla': 1539,
    'Kumla Kommun': 1539,
    'Laxå': 1539,
    'Laxå Kommun': 1539,
    'Lekebergs Kommun': 1539,
    'Lindesberg': 1539,
    'Lindesbergs Kommun': 1539,
    'Ljusnarsbergs Kommun': 1539,
    'Nora': 1539,
    'Nora Kommun': 1539,
    'Odensbacken': 1539,
    'Örebro': 1539,
    'Örebro Kommun': 1539,
    'Pålsboda': 1539,
    'Sköllersta': 1539,
    'Storå': 1539,
    'Vintrosa': 1539,
    'Åby': 1536,
    'Åtvidaberg': 1536,
    'Åtvidabergs Kommun': 1536,
    'Berg': 1536,
    'Borensberg': 1536,
    'Boxholm': 1536,
    'Boxholms Kommun': 1536,
    'Ekängen': 1536,
    'Finspång': 1536,
    'Finspångs Kommun': 1536,
    'Grebo': 1536,
    'Gusum': 1536,
    'Herrestad': 1536,
    'Jursla': 1536,
    'Kimstad': 1536,
    'Kinda Kommun': 1536,
    'Kisa': 1536,
    'Krokek': 1536,
    'Lindö': 1536,
    'Linghem': 1536,
    'Linköping': 1536,
    'Linköpings Kommun': 1536,
    'Ljungsbro': 1536,
    'Malmslätt': 1536,
    'Mantorp': 1536,
    'Mjölby': 1536,
    'Mjölby Kommun': 1536,
    'Motala': 1536,
    'Motala Kommun': 1536,
    'Norrköping': 1536,
    'Norrköpings Kommun': 1536,
    'Ödeshög': 1536,
    'Ödeshögs Kommun': 1536,
    'Österbymo': 1536,
    'Rimforsa': 1536,
    'Ryd': 1536,
    'Skänninge': 1536,
    'Skärblacka': 1536,
    'Söderköping': 1536,
    'Söderköpings Kommun': 1536,
    'Stenstorp': 1536,
    'Sturefors': 1536,
    'Svärtinge': 1536,
    'Tallboda': 1536,
    'Vadstena': 1536,
    'Vadstena Kommun': 1536,
    'Valdemarsvik': 1536,
    'Valdemarsviks Kommun': 1536,
    'Vikingstad': 1536,
    'Ydre Kommun': 1536,
    'Åhus': 1541,
    'Åkarp': 1541,
    'Anderslöv': 1541,
    'Ängelholm': 1541,
    'Ängelholms Kommun': 1541,
    'Arlöv': 1541,
    'Asmundtorp': 1541,
    'Åstorp': 1541,
    'Åstorps Kommun': 1541,
    'Bara': 1541,
    'Bårslöv': 1541,
    'Båstad': 1541,
    'Båstads Kommun': 1541,
    'Billeberga': 1541,
    'Billesholm': 1541,
    'Bjärnum': 1541,
    'Bjärred': 1541,
    'Bjuv': 1541,
    'Bjuvs Kommun': 1541,
    'Blentarp': 1541,
    'Broby': 1541,
    'Bromölla': 1541,
    'Bromölla Kommun': 1541,
    'Bunkeflostrand': 1541,
    'Burlövs Kommun': 1541,
    'Dalby': 1541,
    'Degeberga': 1541,
    'Ekeby': 1541,
    'Eslöv': 1541,
    'Eslövs Kommun': 1541,
    'Färlöv': 1541,
    'Fjälkinge': 1541,
    'Förslöv': 1541,
    'Furulund': 1541,
    'Gantofta': 1541,
    'Gärsnäs': 1541,
    'Genarp': 1541,
    'Glimåkra': 1541,
    'Glumslöv': 1541,
    'Häljarp': 1541,
    'Hammar': 1541,
    'Hanaskog': 1541,
    'Hässleholm': 1541,
    'Hässleholms Kommun': 1541,
    'Hästveda': 1541,
    'Helsingborg': 1541,
    'Hittarp': 1541,
    'Hjärnarp': 1541,
    'Hjärup': 1541,
    'Hofterup': 1541,
    'Höganäs': 1541,
    'Höganäs Kommun': 1541,
    'Hököpinge': 1541,
    'Höllviken': 1541,
    'Höör': 1541,
    'Höörs Kommun': 1541,
    'Hörby': 1541,
    'Hörby Kommun': 1541,
    'Hyllinge': 1541,
    'Jonstorp': 1541,
    'Kågeröd': 1541,
    'Kävlinge': 1541,
    'Kävlinge Kommun': 1541,
    'Klågerup': 1541,
    'Klippan': 1541,
    'Klippans Kommun': 1541,
    'Knislinge': 1541,
    'Köpingebro': 1541,
    'Kristianstad': 1541,
    'Kristianstads kommun': 1541,
    'Kvidinge': 1541,
    'Landskrona': 1541,
    'Ljungbyhed': 1541,
    'Ljunghusen': 1541,
    'Löberöd': 1541,
    'Löddeköpinge': 1541,
    'Lomma': 1541,
    'Lomma Kommun': 1541,
    'Lönsboda': 1541,
    'Lund': 1541,
    'Lunds Kommun': 1541,
    'Malmö': 1541,
    'Marieholm': 1541,
    'Mörarp': 1541,
    'Munka-Ljungby': 1541,
    'Näsum': 1541,
    'Norra Åsum': 1541,
    'Ödåkra': 1541,
    'Önnestad': 1541,
    'Örkelljunga': 1541,
    'Örkelljunga Kommun': 1541,
    'Osby': 1541,
    'Osby kommun': 1541,
    'Östra Göinge Kommun': 1541,
    'Östra Ljungby': 1541,
    'Oxie': 1541,
    'Påarp': 1541,
    'Perstorp': 1541,
    'Perstorps Kommun': 1541,
    'Rydebäck': 1541,
    'Rydsgård': 1541,
    'Sätofta': 1541,
    'Saxtorpsskogen': 1541,
    'Sibbhult': 1541,
    'Simrishamn': 1541,
    'Simrishamns kommun': 1541,
    'Sjöbo': 1541,
    'Sjöbo Kommun': 1541,
    'Skanör med Falsterbo': 1541,
    'Skåre': 1541,
    'Skegrie': 1541,
    'Skivarp': 1541,
    'Skurup': 1541,
    'Skurups Kommun': 1541,
    'Smygehamn': 1541,
    'Södra Sandby': 1541,
    'Sösdala': 1541,
    'Staffanstorp': 1541,
    'Staffanstorps Kommun': 1541,
    'Stångby': 1541,
    'Stehag': 1541,
    'Strövelstorp': 1541,
    'Svalöv': 1541,
    'Svalövs Kommun': 1541,
    'Svedala': 1541,
    'Svedala Kommun': 1541,
    'Teckomatorp': 1541,
    'Tollarp': 1541,
    'Tollarp1': 1541,
    'Tomelilla': 1541,
    'Tomelilla Kommun': 1541,
    'Torekov': 1541,
    'Tormestorp': 1541,
    'Trelleborg': 1541,
    'Trelleborgs Kommun': 1541,
    'Tygelsjö': 1541,
    'Tyringe': 1541,
    'Valje': 1541,
    'Veberöd': 1541,
    'Vejbystrand': 1541,
    'Vellinge': 1541,
    'Vellinge Kommun': 1541,
    'Viken': 1541,
    'Vinslöv': 1541,
    'Vittsjö': 1541,
    'Ystad': 1541,
    'Ystads Kommun': 1541,
    'Åkers Styckebruk': 1540,
    'Ärla': 1540,
    'Arnö': 1540,
    'Bara': 1540,
    'Eskilstuna': 1540,
    'Eskilstuna Kommun': 1540,
    'Flen': 1540,
    'Flens Kommun': 1540,
    'Gnesta': 1540,
    'Gnesta Kommun': 1540,
    'Hällbybrunn': 1540,
    'Hälleforsnäs': 1540,
    'Katrineholm': 1540,
    'Katrineholms Kommun': 1540,
    'Kvicksund': 1540,
    'Malmköping': 1540,
    'Mariefred': 1540,
    'Nyköping': 1540,
    'Nyköpings Kommun': 1540,
    'Olstorp': 1540,
    'Oxelösund': 1540,
    'Oxelösunds Kommun': 1540,
    'Skogstorp': 1540,
    'Stallarholmen': 1540,
    'Stigtomta': 1540,
    'Strängnäs': 1540,
    'Strängnäs Kommun': 1540,
    'Svalsta': 1540,
    'Torshälla': 1540,
    'Trosa': 1540,
    'Trosa Kommun': 1540,
    'Vagnhärad': 1540,
    'Valla': 1540,
    'Vingåker': 1540,
    'Vingåkers Kommun': 1540,
    'Akalla': 1551,
    'Åkersberga': 1551,
    'Alby': 1551,
    'Älmsta': 1551,
    'Älta': 1551,
    'Arnö': 1551,
    'Årsta': 1551,
    'Bergshamra': 1551,
    'Bollmora': 1551,
    'Boo': 1551,
    'Botkyrka Kommun': 1551,
    'Brevik': 1551,
    'Bro': 1551,
    'Bromma': 1551,
    'Brunn': 1551,
    'Brunna': 1551,
    'Dalarö': 1551,
    'Danderyds Kommun': 1551,
    'Djurö': 1551,
    'Djursholm': 1551,
    'Ekerö': 1551,
    'Ekerö Kommun': 1551,
    'Enebyberg': 1551,
    'Eriksberg': 1551,
    'Fisksätra': 1551,
    'Fittja': 1551,
    'Gamla Stan': 1551,
    'Gustavsberg': 1551,
    'Hallstavik': 1551,
    'Handen': 1551,
    'Haninge': 1551,
    'Haninge Kommun': 1551,
    'Hemmesta': 1551,
    'Hölö': 1551,
    'Huddinge': 1551,
    'Huddinge Kommun': 1551,
    'Jakobsberg': 1551,
    'Järfälla kommun': 1551,
    'Järna': 1551,
    'Jordbro': 1551,
    'Kista': 1551,
    'Kopparmora': 1551,
    'Kummelnäs': 1551,
    'Kungsängen': 1551,
    'Kungsholmen': 1551,
    'Långvik': 1551,
    'Lidingö': 1551,
    'Lindholmen': 1551,
    'Märsta': 1551,
    'Mölnbo': 1551,
    'Mörtnäs': 1551,
    'Nacka': 1551,
    'Nacka Kommun': 1551,
    'Norrtälje': 1551,
    'Norrtälje Kommun': 1551,
    'Nykvarn': 1551,
    'Nykvarns Kommun': 1551,
    'Nynäshamn': 1551,
    'Nynäshamns kommun': 1551,
    'Ösmo': 1551,
    'Österåkers Kommun': 1551,
    'Östermalm': 1551,
    'Pershagen': 1551,
    'Råsunda': 1551,
    'Resarö': 1551,
    'Rimbo': 1551,
    'Rindö': 1551,
    'Rönninge': 1551,
    'Rosersberg': 1551,
    'Salems Kommun': 1551,
    'Saltsjöbaden': 1551,
    'Segeltorp': 1551,
    'Sigtuna': 1551,
    'Sigtuna Kommun': 1551,
    'Skänninge': 1551,
    'Södermalm': 1551,
    'Södertälje': 1551,
    'Södertälje Kommun': 1551,
    'Sollentuna': 1551,
    'Sollentuna Kommun': 1551,
    'Solna': 1551,
    'Solna Kommun': 1551,
    'Stavsnäs': 1551,
    'Stenhamra': 1551,
    'Stockholm': 1551,
    'Stockholms Kommun': 1551,
    'Strömma': 1551,
    'Sundbyberg': 1551,
    'Sundbybergs Kommun': 1551,
    'Täby Kommun': 1551,
    'Tensta': 1551,
    'Tullinge': 1551,
    'Tumba': 1551,
    'Tyresö Kommun': 1551,
    'Upplands Väsby': 1551,
    'Upplands Väsby kommun': 1551,
    'Upplands-Bro Kommun': 1551,
    'Vallentuna': 1551,
    'Vallentuna Kommun': 1551,
    'Vårby': 1551,
    'Värmdö Kommun': 1551,
    'Vårsta': 1551,
    'Vasastan': 1551,
    'Vaxholm': 1551,
    'Vaxholms Kommun': 1551,
    'Alsike': 1545,
    'Alunda': 1545,
    'Älvkarleby': 1545,
    'Älvkarleby Kommun': 1545,
    'Anneberg': 1545,
    'Bälinge': 1545,
    'Bålsta': 1545,
    'Björklinge': 1545,
    'Enköping': 1545,
    'Enköpings Kommun': 1545,
    'Gamla Uppsala': 1545,
    'Gimo': 1545,
    'Grillby': 1545,
    'Håbo kommun': 1545,
    'Heby': 1545,
    'Heby kommun': 1545,
    'Irsta': 1545,
    'Karlholmsbruk': 1545,
    'Knivsta': 1545,
    'Knivsta Kommun': 1545,
    'Lövstalöt': 1545,
    'Marieberg': 1545,
    'Morgongåva': 1545,
    'Örbyhus': 1545,
    'Öregrund': 1545,
    'Örsundsbro': 1545,
    'Österbybruk': 1545,
    'Östervåla': 1545,
    'Östhammar': 1545,
    'Östhammars Kommun': 1545,
    'Sävja': 1545,
    'Skutskär': 1545,
    'Söderfors': 1545,
    'Storvreta': 1545,
    'Tärnsjö': 1545,
    'Tierp': 1545,
    'Tierps kommun': 1545,
    'Uppsala': 1545,
    'Uppsala Kommun': 1545,
    'Vattholma': 1545,
    'Åmotfors': 1535,
    'Årjäng': 1535,
    'Årjängs Kommun': 1535,
    'Arvika': 1535,
    'Arvika Kommun': 1535,
    'Björneborg': 1535,
    'Charlottenberg': 1535,
    'Deje': 1535,
    'Eda kommun': 1535,
    'Ekshärad': 1535,
    'Filipstad': 1535,
    'Filipstads Kommun': 1535,
    'Forshaga': 1535,
    'Forshaga Kommun': 1535,
    'Grums': 1535,
    'Grums Kommun': 1535,
    'Hagfors': 1535,
    'Hagfors Kommun': 1535,
    'Hammarö Kommun': 1535,
    'Karlstad': 1535,
    'Karlstads Kommun': 1535,
    'Kil': 1535,
    'Kils Kommun': 1535,
    'Kristinehamn': 1535,
    'Kristinehamns Kommun': 1535,
    'Molkom': 1535,
    'Munkfors': 1535,
    'Munkfors Kommun': 1535,
    'Råtorp': 1535,
    'Säffle': 1535,
    'Säffle Kommun': 1535,
    'Skåre': 1535,
    'Skattkärr': 1535,
    'Skoghall': 1535,
    'Slottsbron': 1535,
    'Storfors': 1535,
    'Storfors Kommun': 1535,
    'Sunne': 1535,
    'Sunne Kommun': 1535,
    'Töcksfors': 1535,
    'Torsby': 1535,
    'Torsby Kommun': 1535,
    'Vålberg': 1535,
    'Åsele': 1543,
    'Åsele Kommun': 1543,
    'Backa': 1543,
    'Bjurholm': 1543,
    'Bjurholms Kommun': 1543,
    'Boliden': 1543,
    'Bureå': 1543,
    'Burträsk': 1543,
    'Byske': 1543,
    'Dorotea': 1543,
    'Dorotea Kommun': 1543,
    'Ersmark': 1543,
    'Forsbacka': 1543,
    'Holmsund': 1543,
    'Hörnefors': 1543,
    'Insjön': 1543,
    'Kåge': 1543,
    'Långsele': 1543,
    'Lycksele': 1543,
    'Lycksele kommun': 1543,
    'Malå': 1543,
    'Malå Kommun': 1543,
    'Nordmaling': 1543,
    'Nordmalings Kommun': 1543,
    'Norsjö': 1543,
    'Norsjö Kommun': 1543,
    'Obbola': 1543,
    'Röbäck': 1543,
    'Robertsfors': 1543,
    'Robertsfors Kommun': 1543,
    'Sävar': 1543,
    'Skellefteå': 1543,
    'Skellefteå Kommun': 1543,
    'Skelleftehamn': 1543,
    'Söderfors': 1543,
    'Sorsele': 1543,
    'Sorsele Kommun': 1543,
    'Storuman': 1543,
    'Storumans Kommun': 1543,
    'Storvik': 1543,
    'Täfteå': 1543,
    'Umeå': 1543,
    'Umeå Kommun': 1543,
    'Ursviken': 1543,
    'Vännäs': 1543,
    'Vännäs Kommun': 1543,
    'Vännäsby': 1543,
    'Viken': 1543,
    'Vilhelmina': 1543,
    'Vilhelmina Kommun': 1543,
    'Vindeln': 1543,
    'Vindelns Kommun': 1543,
    'Ånge': 1552,
    'Ånge kommun': 1552,
    'Ås': 1552,
    'Bergeforsen': 1552,
    'Bjästa': 1552,
    'Bollstabruk': 1552,
    'Bredbyn': 1552,
    'Fränsta': 1552,
    'Härnösand': 1552,
    'Härnösands Kommun': 1552,
    'Husum': 1552,
    'Johannedal': 1552,
    'Köpmanholmen': 1552,
    'Kramfors': 1552,
    'Kramfors Kommun': 1552,
    'Kvissleby': 1552,
    'Långsele': 1552,
    'Matfors': 1552,
    'Njurundabommen': 1552,
    'Nolby': 1552,
    'Örnsköldsvik': 1552,
    'Örnsköldsviks Kommun': 1552,
    'Skottsund': 1552,
    'Sollefteå': 1552,
    'Sollefteå Kommun': 1552,
    'Söråker': 1552,
    'Stockvik': 1552,
    'Sundsbruk': 1552,
    'Sundsvall': 1552,
    'Sundsvalls Kommun': 1552,
    'Timrå': 1552,
    'Timrå Kommun': 1552,
    'Vi': 1552,
    'Arboga': 1549,
    'Arboga Kommun': 1549,
    'Barkarö': 1549,
    'Dingtuna': 1549,
    'Enhagen-Ekbacken': 1549,
    'Fagersta': 1549,
    'Fagersta Kommun': 1549,
    'Hallstahammar': 1549,
    'Hallstahammars Kommun': 1549,
    'Hökåsen': 1549,
    'Irsta': 1549,
    'Kolbäck': 1549,
    'Kolsva': 1549,
    'Köping': 1549,
    'Köpings Kommun': 1549,
    'Kungsör': 1549,
    'Kungsörs kommun': 1549,
    'Norberg': 1549,
    'Norbergs Kommun': 1549,
    'Ramnäs': 1549,
    'Sala': 1549,
    'Sala kommun': 1549,
    'Skinnskatteberg': 1549,
    'Skinnskattebergs Kommun': 1549,
    'Skultuna': 1549,
    'Surahammar': 1549,
    'Surahammars Kommun': 1549,
    'Tillberga': 1549,
    'Västerås': 1549,
    'Åkarp': 1547,
    'Alafors': 1547,
    'Ale Kommun': 1547,
    'Alingsås': 1547,
    'Alingsås Kommun': 1547,
    'Älvängen': 1547,
    'Åmål': 1547,
    'Åmåls Kommun': 1547,
    'Andalen': 1547,
    'Anderstorp': 1547,
    'Angered': 1547,
    'Annelund': 1547,
    'Axvall': 1547,
    'Bengtsfors': 1547,
    'Bengtsfors Kommun': 1547,
    'Billdal': 1547,
    'Billingsfors': 1547,
    'Björboholm': 1547,
    'Björkö': 1547,
    'Björlanda': 1547,
    'Bollebygd': 1547,
    'Bollebygds Kommun': 1547,
    'Borås': 1547,
    'Brålanda': 1547,
    'Brastad': 1547,
    'Dals Långed': 1547,
    'Dals-Ed Kommun': 1547,
    'Dalsjöfors': 1547,
    'Diseröd': 1547,
    'Donsö': 1547,
    'Ed': 1547,
    'Ellös': 1547,
    'Eriksbo': 1547,
    'Essunga Kommun': 1547,
    'Falköping': 1547,
    'Falköpings Kommun': 1547,
    'Färgelanda': 1547,
    'Färgelanda Kommun': 1547,
    'Floby': 1547,
    'Fristad': 1547,
    'Fritsla': 1547,
    'Furulund': 1547,
    'Gånghester': 1547,
    'Gårdsten': 1547,
    'Göta': 1547,
    'Göteborg': 1547,
    'Göteborgs stad': 1547,
    'Götene': 1547,
    'Götene Kommun': 1547,
    'Gråbo': 1547,
    'Grästorp': 1547,
    'Grästorps Kommun': 1547,
    'Grebbestad': 1547,
    'Gullspång': 1547,
    'Gullspångs Kommun': 1547,
    'Hammarkullen': 1547,
    'Härryda Kommun': 1547,
    'Henån': 1547,
    'Herrljunga': 1547,
    'Herrljunga Kommun': 1547,
    'Hindås': 1547,
    'Hjo': 1547,
    'Hjo Kommun': 1547,
    'Hjuvik': 1547,
    'Hönö': 1547,
    'Horred': 1547,
    'Hova': 1547,
    'Höviksnäs': 1547,
    'Hunnebostrand': 1547,
    'Ingared': 1547,
    'Jonstorp': 1547,
    'Jörlanda': 1547,
    'Källby': 1547,
    'Kållered': 1547,
    'Karlsborg': 1547,
    'Karlsborgs Kommun': 1547,
    'Kinna': 1547,
    'Kode': 1547,
    'Kungälv': 1547,
    'Kungälvs Kommun': 1547,
    'Kungshamn': 1547,
    'Kvänum': 1547,
    'Landvetter': 1547,
    'Länghem': 1547,
    'Lerum': 1547,
    'Lerums Kommun': 1547,
    'Lidköping': 1547,
    'Lidköpings Kommun': 1547,
    'Lilla Edet': 1547,
    'Lilla Edets Kommun': 1547,
    'Limmared': 1547,
    'Lindome': 1547,
    'Ljungskile': 1547,
    'Lödöse': 1547,
    'Lövgärdet': 1547,
    'Lysekil': 1547,
    'Lysekils Kommun': 1547,
    'Majorna': 1547,
    'Mariestad': 1547,
    'Mariestads Kommun': 1547,
    'Marks Kommun': 1547,
    'Mellerud': 1547,
    'Melleruds Kommun': 1547,
    'Mölltorp': 1547,
    'Mölndal': 1547,
    'Mölndals kommun': 1547,
    'Mölnlycke': 1547,
    'Munkedal': 1547,
    'Munkedals Kommun': 1547,
    'Myggenäs': 1547,
    'Nolvik': 1547,
    'Nossebro': 1547,
    'Öckerö': 1547,
    'Öckerö Kommun': 1547,
    'Ödsmål': 1547,
    'Öjersjö': 1547,
    'Olofstorp': 1547,
    'Olstorp': 1547,
    'Orust': 1547,
    'Partille': 1547,
    'Partille Kommun': 1547,
    'Rannebergen': 1547,
    'Rävlanda': 1547,
    'Rönnäng': 1547,
    'Sålanda': 1547,
    'Sandared': 1547,
    'Sätila': 1547,
    'Sjömarken': 1547,
    'Sjuntorp': 1547,
    'Skara': 1547,
    'Skara Kommun': 1547,
    'Skärhamn': 1547,
    'Skepplanda': 1547,
    'Skövde': 1547,
    'Skövde Kommun': 1547,
    'Skultorp': 1547,
    'Smögen': 1547,
    'Sollebrunn': 1547,
    'Sotenäs Kommun': 1547,
    'Stenkullen': 1547,
    'Stenstorp': 1547,
    'Stenungsund': 1547,
    'Stenungsunds Kommun': 1547,
    'Stöpen': 1547,
    'Stora Höga': 1547,
    'Strömstad': 1547,
    'Strömstads Kommun': 1547,
    'Styrsö': 1547,
    'Surte': 1547,
    'Svanesund': 1547,
    'Svenljunga': 1547,
    'Svenljunga Kommun': 1547,
    'Tanums Kommun': 1547,
    'Tanumshede': 1547,
    'Tibro': 1547,
    'Tibro Kommun': 1547,
    'Tidaholm': 1547,
    'Tidaholms kommun': 1547,
    'Tjörns Kommun': 1547,
    'Tjuvkil': 1547,
    'Töreboda': 1547,
    'Töreboda Kommun': 1547,
    'Torslanda': 1547,
    'Tranemo': 1547,
    'Tranemo Kommun': 1547,
    'Trollhättan': 1547,
    'Uddevalla': 1547,
    'Uddevalla Kommun': 1547,
    'Ulricehamn': 1547,
    'Ulricehamns Kommun': 1547,
    'Vänersborg': 1547,
    'Vänersborgs Kommun': 1547,
    'Vara': 1547,
    'Vara Kommun': 1547,
    'Vårgårda': 1547,
    'Vårgårda Kommun': 1547,
    'Vargön': 1547,
    'Västra Frölunda': 1547,
    'Vinninga': 1547,
    'Viskafors': 1547,
    'Aarau': 1639,
    'Aarburg': 1639,
    'Aristau': 1639,
    'Auw': 1639,
    'Bad Zurzach': 1639,
    'Baden': 1639,
    'Berikon': 1639,
    'Besenbüren': 1639,
    'Bezirk Aarau': 1639,
    'Bezirk Baden': 1639,
    'Bezirk Bremgarten': 1639,
    'Bezirk Brugg': 1639,
    'Bezirk Kulm': 1639,
    'Bezirk Laufenburg': 1639,
    'Bezirk Lenzburg': 1639,
    'Bezirk Muri': 1639,
    'Bezirk Rheinfelden': 1639,
    'Bezirk Zofingen': 1639,
    'Bezirk Zurzach': 1639,
    'Birmenstorf': 1639,
    'Birr': 1639,
    'Boniswil': 1639,
    'Bremgarten': 1639,
    'Brittnau': 1639,
    'Brugg': 1639,
    'Buchs': 1639,
    'Buttwil': 1639,
    'Densbüren': 1639,
    'Dottikon': 1639,
    'Dürrenäsch': 1639,
    'Egliswil': 1639,
    'Ehrendingen': 1639,
    'Eiken': 1639,
    'Endingen': 1639,
    'Frick': 1639,
    'Gebenstorf': 1639,
    'Gipf-Oberfrick': 1639,
    'Gontenschwil': 1639,
    'Gränichen': 1639,
    'Hägglingen': 1639,
    'Hausen': 1639,
    'Jonen': 1639,
    'Kaiseraugst': 1639,
    'Kaisten': 1639,
    'Killwangen': 1639,
    'Klingnau': 1639,
    'Koblenz': 1639,
    'Kölliken': 1639,
    'Künten': 1639,
    'Küttigen': 1639,
    'Laufenburg': 1639,
    'Lauffohr (Brugg)': 1639,
    'Leibstadt': 1639,
    'Lenzburg': 1639,
    'Magden': 1639,
    'Meisterschwanden': 1639,
    'Mellingen': 1639,
    'Menziken': 1639,
    'Merenschwand': 1639,
    'Möhlin': 1639,
    'Muhen': 1639,
    'Mumpf': 1639,
    'Murgenthal': 1639,
    'Muri': 1639,
    'Neuenhof': 1639,
    'Niederlenz': 1639,
    'Niederrohrdorf': 1639,
    'Oberentfelden': 1639,
    'Oberlunkhofen': 1639,
    'Oberrohrdorf': 1639,
    'Oberrüti': 1639,
    'Obersiggenthal': 1639,
    'Oftringen': 1639,
    'Othmarsingen': 1639,
    'Reinach': 1639,
    'Rheinfelden': 1639,
    'Rothrist': 1639,
    'Rudolfstetten': 1639,
    'Rupperswil': 1639,
    'Safenwil': 1639,
    'Sarmenstorf': 1639,
    'Schafisheim': 1639,
    'Schinznach Bad': 1639,
    'Schinznach Dorf': 1639,
    'Schöftland': 1639,
    'Schwaderloch': 1639,
    'Seengen': 1639,
    'Seon': 1639,
    'Sins': 1639,
    'Spreitenbach': 1639,
    'Staffelbach': 1639,
    'Staufen': 1639,
    'Stein': 1639,
    'Strengelbach': 1639,
    'Suhr': 1639,
    'Sulz': 1639,
    'Tegerfelden': 1639,
    'Teufenthal': 1639,
    'Turgi': 1639,
    'Uerkheim': 1639,
    'Unterkulm': 1639,
    'Unterlunkhofen': 1639,
    'Untersiggenthal': 1639,
    'Veltheim': 1639,
    'Villigen': 1639,
    'Villmergen': 1639,
    'Villnachern': 1639,
    'Vordemwald': 1639,
    'Waltenschwil': 1639,
    'Wegenstetten': 1639,
    'Wettingen': 1639,
    'Windisch': 1639,
    'Wittnau': 1639,
    'Wohlen': 1639,
    'Wölflinswil': 1639,
    'Würenlingen': 1639,
    'Würenlos': 1639,
    'Zofingen': 1639,
    'Bezirk Hinterland': 1655,
    'Bezirk Mittelland': 1655,
    'Bezirk Vorderland': 1655,
    'Bühler': 1655,
    'Gais': 1655,
    'Heiden': 1655,
    'Herisau': 1655,
    'Rehetobel': 1655,
    'Schwellbrunn': 1655,
    'Speicher': 1655,
    'Teufen': 1655,
    'Trogen': 1655,
    'Urnäsch': 1655,
    'Waldstatt': 1655,
    'Walzenhausen': 1655,
    'Appenzell': 1649,
    'Gonten': 1649,
    'Haslen': 1649,
    'Oberegg': 1649,
    'Aesch': 1641,
    'Allschwil': 1641,
    'Arisdorf': 1641,
    'Arlesheim': 1641,
    'Bezirk Arlesheim': 1641,
    'Bezirk Laufen': 1641,
    'Bezirk Liestal': 1641,
    'Bezirk Sissach': 1641,
    'Bezirk Waldenburg': 1641,
    'Binningen': 1641,
    'Birsfelden': 1641,
    'Bottmingen': 1641,
    'Brislach': 1641,
    'Bubendorf': 1641,
    'Buus': 1641,
    'Diegten': 1641,
    'Ettingen': 1641,
    'Frenkendorf': 1641,
    'Füllinsdorf': 1641,
    'Gelterkinden': 1641,
    'Grellingen': 1641,
    'Hölstein': 1641,
    'Itingen': 1641,
    'Langenbruck': 1641,
    'Läufelfingen': 1641,
    'Laufen': 1641,
    'Lausen': 1641,
    'Liesberg': 1641,
    'Liestal': 1641,
    'Münchenstein': 1641,
    'Muttenz': 1641,
    'Oberdorf': 1641,
    'Oberwil': 1641,
    'Ormalingen': 1641,
    'Pfeffingen': 1641,
    'Pratteln': 1641,
    'Reigoldswil': 1641,
    'Röschenz': 1641,
    'Schönenbuch': 1641,
    'Seltisberg': 1641,
    'Sissach': 1641,
    'Therwil': 1641,
    'Wahlen': 1641,
    'Waldenburg': 1641,
    'Zunzgen': 1641,
    'Zwingen': 1641,
    'Aarberg': 1645,
    'Aarwangen': 1645,
    'Adelboden': 1645,
    'Aeschi b. Spiez': 1645,
    'Arch': 1645,
    'Attiswil': 1645,
    'Bäriswil': 1645,
    'Bätterkinden': 1645,
    'Beatenberg': 1645,
    'Bellmund': 1645,
    'Belp': 1645,
    'Bern': 1645,
    'Bern-Mittelland District': 1645,
    'Bévilard': 1645,
    'Biel/Bienne': 1645,
    'Biel/Bienne District': 1645,
    'Biglen': 1645,
    'Blumenstein': 1645,
    'Bolligen': 1645,
    'Boltigen': 1645,
    'Bönigen': 1645,
    'Bowil': 1645,
    'Brienz': 1645,
    'Brügg': 1645,
    'Buchholterberg': 1645,
    'Burgdorf': 1645,
    'Burgistein': 1645,
    'Corgémont': 1645,
    'Court': 1645,
    'Courtelary': 1645,
    'Därligen': 1645,
    'Diemtigen': 1645,
    'Dürrenroth': 1645,
    'Eggiwil': 1645,
    'Emmental District': 1645,
    'Eriswil': 1645,
    'Erlach': 1645,
    'Erlenbach im Simmental': 1645,
    'Ersigen': 1645,
    'Evilard': 1645,
    'Ferenbalm': 1645,
    'Fraubrunnen': 1645,
    'Frauenkappelen': 1645,
    'Frutigen': 1645,
    'Frutigen-Niedersimmental District': 1645,
    'Grindelwald': 1645,
    'Grossaffoltern': 1645,
    'Gstaad': 1645,
    'Guggisberg': 1645,
    'Heimberg': 1645,
    'Heimiswil': 1645,
    'Hermiswil': 1645,
    'Herzogenbuchsee': 1645,
    'Hilterfingen': 1645,
    'Hindelbank': 1645,
    'Huttwil': 1645,
    'Ins': 1645,
    'Interlaken': 1645,
    'Interlaken-Oberhasli District': 1645,
    'Jegenstorf': 1645,
    'Jura bernois': 1645,
    'Kallnach': 1645,
    'Kandersteg': 1645,
    'Kappelen': 1645,
    'Kehrsatz': 1645,
    'Kirchberg': 1645,
    'Kirchlindach': 1645,
    'Köniz': 1645,
    'Konolfingen': 1645,
    'Koppigen': 1645,
    'Krauchthal': 1645,
    'La Neuveville': 1645,
    'Langenthal': 1645,
    'Langnau': 1645,
    'Laupen': 1645,
    'Lauperswil': 1645,
    'Lauterbrunnen': 1645,
    'Lengnau': 1645,
    'Lenk': 1645,
    'Leuzigen': 1645,
    'Linden': 1645,
    'Lotzwil': 1645,
    'Lützelflüh': 1645,
    'Lyss': 1645,
    'Lyssach': 1645,
    'Madiswil': 1645,
    'Malleray': 1645,
    'Matten': 1645,
    'Meinisberg': 1645,
    'Meiringen': 1645,
    'Melchnau': 1645,
    'Moutier': 1645,
    'Mühleberg': 1645,
    'Mühlethurnen': 1645,
    'Münchenbuchsee': 1645,
    'Münchenwiler': 1645,
    'Münsingen': 1645,
    'Müntschemier': 1645,
    'Muri': 1645,
    'Nidau': 1645,
    'Niederbipp': 1645,
    'Oberaargau': 1645,
    'Oberbipp': 1645,
    'Oberburg': 1645,
    'Oberdiessbach': 1645,
    'Obersimmental-Saanen District': 1645,
    'Orpund': 1645,
    'Orvin': 1645,
    'Péry': 1645,
    'Pieterlen': 1645,
    'Radelfingen': 1645,
    'Reconvilier': 1645,
    'Riggisberg': 1645,
    'Ringgenberg': 1645,
    'Roggwil': 1645,
    'Rohrbach': 1645,
    'Rubigen': 1645,
    'Rüderswil': 1645,
    'Rüeggisberg': 1645,
    'Rüschegg': 1645,
    'Saanen': 1645,
    'Saint-Imier': 1645,
    'Sankt Stephan': 1645,
    'Schüpfen': 1645,
    'Seeberg': 1645,
    'Seedorf': 1645,
    'Seeland District': 1645,
    'Seftigen': 1645,
    'Signau': 1645,
    'Sigriswil': 1645,
    'Spiez': 1645,
    'Steffisburg': 1645,
    'Stettlen': 1645,
    'Sumiswald': 1645,
    'Täuffelen': 1645,
    'Tavannes': 1645,
    'Thierachern': 1645,
    'Thun': 1645,
    'Thun District': 1645,
    'Toffen': 1645,
    'Trachselwald': 1645,
    'Tramelan': 1645,
    'Trub': 1645,
    'Trubschachen': 1645,
    'Uetendorf': 1645,
    'Unterseen': 1645,
    'Urtenen': 1645,
    'Uttigen': 1645,
    'Utzenstorf': 1645,
    'Vechigen': 1645,
    'Walkringen': 1645,
    'Wangen an der Aare': 1645,
    'Wattenwil': 1645,
    'Wichtrach': 1645,
    'Wiedlisbach': 1645,
    'Wilderswil': 1645,
    'Wimmis': 1645,
    'Wohlen': 1645,
    'Worb': 1645,
    'Worben': 1645,
    'Wynau': 1645,
    'Wynigen': 1645,
    'Wyssachen': 1645,
    'Zäziwil': 1645,
    'Zollikofen': 1645,
    'Zweisimmen': 1645,
    'Alterswil': 1640,
    'Attalens': 1640,
    'Avry-sur-Matran': 1640,
    'Bas-Vully': 1640,
    'Belfaux': 1640,
    'Bösingen': 1640,
    'Broc': 1640,
    'Broye District': 1640,
    'Bulle': 1640,
    'Charmey': 1640,
    'Châtel-Saint-Denis': 1640,
    'Corminboeuf': 1640,
    'Courgevaux': 1640,
    'Cugy': 1640,
    'Domdidier': 1640,
    'Düdingen': 1640,
    'Ecublens': 1640,
    'Ependes': 1640,
    'Estavayer-le-Lac': 1640,
    'Flamatt': 1640,
    'Fribourg': 1640,
    'Giffers': 1640,
    'Givisiez': 1640,
    'Glâne District': 1640,
    'Grolley': 1640,
    'Gruyère District': 1640,
    'Gruyères': 1640,
    'Heitenried': 1640,
    'Kerzers': 1640,
    'La Roche': 1640,
    'La Tour-de-Trême': 1640,
    'Lake District': 1640,
    'Marly': 1640,
    'Marsens': 1640,
    'Mézières': 1640,
    'Murten/Morat': 1640,
    'Oberschrot': 1640,
    'Plaffeien': 1640,
    'Praroman': 1640,
    'Rechthalten': 1640,
    'Riaz': 1640,
    'Romont': 1640,
    'Rue': 1640,
    'Sâles': 1640,
    'Sankt Antoni': 1640,
    'Sarine District': 1640,
    'Schmitten': 1640,
    'Sense District': 1640,
    'Tafers': 1640,
    'Treyvaux': 1640,
    'Ueberstorf': 1640,
    'Veveyse District': 1640,
    'Villars-sur-Glâne': 1640,
    'Villaz-Saint-Pierre': 1640,
    'Vuadens': 1640,
    'Aire-la-Ville': 1647,
    'Anières': 1647,
    'Bellevue': 1647,
    'Bernex': 1647,
    'Carouge': 1647,
    'Chancy': 1647,
    'Chêne-Bougeries': 1647,
    'Chêne-Bourg': 1647,
    'Confignon': 1647,
    'Corsier': 1647,
    'Dardagny': 1647,
    'Genève': 1647,
    'Jussy': 1647,
    'Lancy': 1647,
    'Le Grand-Saconnex': 1647,
    'Les Avanchets': 1647,
    'Meinier': 1647,
    'Meyrin': 1647,
    'Onex': 1647,
    'Plan-les-Ouates': 1647,
    'Puplinge': 1647,
    'Satigny': 1647,
    'Thônex': 1647,
    'Troinex': 1647,
    'Vandœuvres': 1647,
    'Vernier': 1647,
    'Versoix': 1647,
    'Veyrier': 1647,
    'Alle': 1658,
    'Bassecourt': 1658,
    'Boncourt': 1658,
    'Courfaivre': 1658,
    'Courgenay': 1658,
    'Courrendlin': 1658,
    'Courroux': 1658,
    'Courtételle': 1658,
    'Delémont': 1658,
    'Delémont District': 1658,
    'Fontenais': 1658,
    'Franches-Montagnes District': 1658,
    'Glovelier': 1658,
    'Le Noirmont': 1658,
    'Les Bois': 1658,
    'Les Breuleux': 1658,
    'Porrentruy': 1658,
    'Porrentruy District': 1658,
    'Saignelégier': 1658,
    'Vicques': 1658,
    'Adligenswil': 1663,
    'Altishofen': 1663,
    'Ballwil': 1663,
    'Beromünster': 1663,
    'Buchrain': 1663,
    'Büron': 1663,
    'Buttisholz': 1663,
    'Dagmersellen': 1663,
    'Ebikon': 1663,
    'Eich': 1663,
    'Emmen': 1663,
    'Entlebuch': 1663,
    'Entlebuch District': 1663,
    'Escholzmatt': 1663,
    'Ettiswil': 1663,
    'Flühli': 1663,
    'Geuensee': 1663,
    'Grosswangen': 1663,
    'Gunzwil': 1663,
    'Hasle': 1663,
    'Hildisrieden': 1663,
    'Hitzkirch': 1663,
    'Hochdorf': 1663,
    'Hochdorf District': 1663,
    'Hohenrain': 1663,
    'Horw': 1663,
    'Inwil': 1663,
    'Knutwil': 1663,
    'Kriens': 1663,
    'Littau': 1663,
    'Lucerne-Land District': 1663,
    'Lucerne-Stadt District': 1663,
    'Luthern': 1663,
    'Luzern': 1663,
    'Malters': 1663,
    'Mauensee': 1663,
    'Meggen': 1663,
    'Meierskappel': 1663,
    'Menznau': 1663,
    'Nebikon': 1663,
    'Neudorf': 1663,
    'Neuenkirch': 1663,
    'Nottwil': 1663,
    'Oberkirch': 1663,
    'Pfaffnau': 1663,
    'Reiden': 1663,
    'Römerswil': 1663,
    'Root': 1663,
    'Ruswil': 1663,
    'Schenkon': 1663,
    'Schötz': 1663,
    'Schüpfheim': 1663,
    'Schwarzenberg': 1663,
    'Sempach': 1663,
    'Sursee': 1663,
    'Sursee District': 1663,
    'Triengen': 1663,
    'Udligenswil': 1663,
    'Vitznau': 1663,
    'Wauwil': 1663,
    'Weggis': 1663,
    'Werthenstein': 1663,
    'Wikon': 1663,
    'Willisau': 1663,
    'Willisau District': 1663,
    'Wolhusen': 1663,
    'Zell': 1663,
    'Auvernier': 1659,
    'Bevaix': 1659,
    'Boudry': 1659,
    'Boudry District': 1659,
    'Cernier': 1659,
    'Chézard-Saint-Martin': 1659,
    'Cornaux': 1659,
    'Cortaillod': 1659,
    'Couvet': 1659,
    'Dombresson': 1659,
    'Fleurier': 1659,
    'Fontainemelon': 1659,
    'Gorgier': 1659,
    'Grand-Savagnier': 1659,
    'La Chaux-de-Fonds': 1659,
    'La Chaux-de-Fonds District': 1659,
    'Le Landeron': 1659,
    'Le Locle': 1659,
    'Le Locle District': 1659,
    'Les Brenets': 1659,
    'Les Geneveys-sur-Coffrane': 1659,
    'Les Ponts-de-Martel': 1659,
    'Marin-Epagnier': 1659,
    'Neuchâtel': 1659,
    'Neuchâtel District': 1659,
    'Peseux': 1659,
    'Saint-Aubin-Sauges': 1659,
    'Saint-Blaise': 1659,
    'Travers': 1659,
    'Val-de-Ruz District': 1659,
    'Val-de-Travers District': 1659,
    'Beringen': 1654,
    'Bezirk Oberklettgau': 1654,
    'Bezirk Reiat': 1654,
    'Bezirk Schaffhausen': 1654,
    'Bezirk Schleitheim': 1654,
    'Bezirk Stein': 1654,
    'Bezirk Unterklettgau': 1654,
    'Hallau': 1654,
    'Löhningen': 1654,
    'Neuhausen': 1654,
    'Neunkirch': 1654,
    'Ramsen': 1654,
    'Schaffhausen': 1654,
    'Schleitheim': 1654,
    'Stein am Rhein': 1654,
    'Stetten': 1654,
    'Thayngen': 1654,
    'Wilchingen': 1654,
    'Balsthal': 1662,
    'Bettlach': 1662,
    'Bezirk Bucheggberg': 1662,
    'Bezirk Dorneck': 1662,
    'Bezirk Gäu': 1662,
    'Bezirk Gösgen': 1662,
    'Bezirk Lebern': 1662,
    'Bezirk Olten': 1662,
    'Bezirk Solothurn': 1662,
    'Bezirk Thal': 1662,
    'Bezirk Thierstein': 1662,
    'Bezirk Wasseramt': 1662,
    'Biberist': 1662,
    'Breitenbach': 1662,
    'Büsserach': 1662,
    'Deitingen': 1662,
    'Derendingen': 1662,
    'Dornach': 1662,
    'Egerkingen': 1662,
    'Erlinsbach': 1662,
    'Fulenbach': 1662,
    'Gerlafingen': 1662,
    'Grenchen': 1662,
    'Gunzgen': 1662,
    'Hägendorf': 1662,
    'Himmelried': 1662,
    'Hochwald': 1662,
    'Kleinlützel': 1662,
    'Kriegstetten': 1662,
    'Langendorf': 1662,
    'Laupersdorf': 1662,
    'Lostorf': 1662,
    'Luterbach': 1662,
    'Matzendorf': 1662,
    'Messen': 1662,
    'Niedergösgen': 1662,
    'Nunningen': 1662,
    'Oberbuchsiten': 1662,
    'Obergösgen': 1662,
    'Oensingen': 1662,
    'Olten': 1662,
    'Riedholz': 1662,
    'Rodersdorf': 1662,
    'Rohr': 1662,
    'Schönenwerd': 1662,
    'Selzach': 1662,
    'Solothurn': 1662,
    'Subingen': 1662,
    'Trimbach': 1662,
    'Wangen': 1662,
    'Welschenrohr': 1662,
    'Wolfwil': 1662,
    'Zuchwil': 1662,
    'Altstätten': 1644,
    'Amden': 1644,
    'Andwil': 1644,
    'Au': 1644,
    'Bad Ragaz': 1644,
    'Balgach': 1644,
    'Benken': 1644,
    'Bronschhofen': 1644,
    'Buchs': 1644,
    'Bütschwil': 1644,
    'Degersheim': 1644,
    'Diepoldsau': 1644,
    'Ebnat-Kappel': 1644,
    'Eggersriet': 1644,
    'Eichberg': 1644,
    'Eschenbach': 1644,
    'Flawil': 1644,
    'Flums': 1644,
    'Gams': 1644,
    'Ganterschwil': 1644,
    'Goldach': 1644,
    'Goldingen': 1644,
    'Gommiswald': 1644,
    'Gossau': 1644,
    'Grabs': 1644,
    'Haag (Rheintal)': 1644,
    'Häggenschwil': 1644,
    'Horn': 1644,
    'Jona': 1644,
    'Jonschwil': 1644,
    'Kaltbrunn': 1644,
    'Kirchberg': 1644,
    'Krummenau': 1644,
    'Lichtensteig': 1644,
    'Lütisburg': 1644,
    'Mogelsberg': 1644,
    'Mörschwil': 1644,
    'Mosnang': 1644,
    'Muolen': 1644,
    'Niederbüren': 1644,
    'Niederhelfenschwil': 1644,
    'Oberhelfenschwil': 1644,
    'Oberriet': 1644,
    'Oberuzwil': 1644,
    'Quarten': 1644,
    'Rapperswil': 1644,
    'Rebstein': 1644,
    'Rheineck': 1644,
    'Rorschach': 1644,
    'Rüthi': 1644,
    'Sankt Gallen': 1644,
    'Sankt Gallenkappel': 1644,
    'Sankt Margrethen': 1644,
    'Sankt Peterzell': 1644,
    'Sargans': 1644,
    'Schänis': 1644,
    'Schmerikon': 1644,
    'Sennwald': 1644,
    'Sevelen': 1644,
    'Thal': 1644,
    'Uznach': 1644,
    'Uzwil': 1644,
    'Wahlkreis Rheintal': 1644,
    'Wahlkreis Rorschach': 1644,
    'Wahlkreis Sarganserland': 1644,
    'Wahlkreis See-Gaster': 1644,
    'Wahlkreis St. Gallen': 1644,
    'Wahlkreis Toggenburg': 1644,
    'Wahlkreis Werdenberg': 1644,
    'Wahlkreis Wil': 1644,
    'Waldkirch': 1644,
    'Walenstadt': 1644,
    'Wattwil': 1644,
    'Weesen': 1644,
    'Wil': 1644,
    'Wildhaus': 1644,
    'Wittenbach': 1644,
    'Zuzwil': 1644,
    'Ardon': 1648,
    'Ayent': 1648,
    'Bagnes': 1648,
    'Baltschieder': 1648,
    'Basse-Nendaz': 1648,
    'Brig': 1648,
    'Brig District': 1648,
    'Chalais': 1648,
    'Chamoson': 1648,
    'Champéry': 1648,
    'Charrat': 1648,
    'Chermignon-d’en Haut': 1648,
    'Chippis': 1648,
    'Collombey': 1648,
    'Conthey': 1648,
    'Conthey District': 1648,
    'Entremont District': 1648,
    'Evionnaz': 1648,
    'Evolène': 1648,
    'Fiesch': 1648,
    'Fully': 1648,
    'Gampel': 1648,
    'Goms District': 1648,
    'Grächen': 1648,
    'Grimisuat': 1648,
    'Grône': 1648,
    'Hérémence': 1648,
    'Hérens District': 1648,
    'Lens': 1648,
    'Leuk': 1648,
    'Leuk District': 1648,
    'Leukerbad': 1648,
    'Leytron': 1648,
    'Martigny District': 1648,
    'Martigny-Combe': 1648,
    'Martigny-Ville': 1648,
    'Montana': 1648,
    'Monthey': 1648,
    'Monthey District': 1648,
    'Naters': 1648,
    'Orsières': 1648,
    'Randogne': 1648,
    'Raron': 1648,
    'Raron District': 1648,
    'Riddes': 1648,
    'Saas-Fee': 1648,
    'Saas-Grund': 1648,
    'Saillon': 1648,
    'Saint-Léonard': 1648,
    'Saint-Maurice': 1648,
    'Saint-Maurice District': 1648,
    'Salgesch': 1648,
    'Salvan': 1648,
    'Sankt Niklaus': 1648,
    'Savièse': 1648,
    'Saxon': 1648,
    'Sierre': 1648,
    'Sierre District': 1648,
    'Sion District': 1648,
    'Sitten': 1648,
    'Stalden': 1648,
    'Tanay': 1648,
    'Troistorrents': 1648,
    'Turtmann': 1648,
    'Varen': 1648,
    'Verbier': 1648,
    'Vernayaz': 1648,
    'Vétroz': 1648,
    'Vex': 1648,
    'Vionnaz': 1648,
    'Visp': 1648,
    'Visp District': 1648,
    'Visperterminen': 1648,
    'Vouvry': 1648,
    'Zermatt': 1648,
    'Aigle': 1651,
    'Aigle District': 1651,
    'Apples': 1651,
    'Arzier': 1651,
    'Aubonne': 1651,
    'Avenches': 1651,
    'Ballens': 1651,
    'Bavois': 1651,
    'Begnins': 1651,
    'Belmont-sur-Lausanne': 1651,
    'Berolle': 1651,
    'Bex': 1651,
    'Bière': 1651,
    'Blécherette': 1651,
    'Blonay': 1651,
    'Bottens': 1651,
    'Broye-Vully District': 1651,
    'Bussigny': 1651,
    'Caux': 1651,
    'Chardonne': 1651,
    'Château-d  Oex': 1651,
    'Chavannes': 1651,
    'Chavannes-le-Veyron': 1651,
    'Chavornay': 1651,
    'Cheseaux': 1651,
    'Chevilly': 1651,
    'Chexbres': 1651,
    'Colombier': 1651,
    'Coppet': 1651,
    'Cossonay': 1651,
    'Crissier': 1651,
    'Cuarnens': 1651,
    'Cully': 1651,
    'Echallens': 1651,
    'Ecublens': 1651,
    'Epalinges': 1651,
    'Ferreyres': 1651,
    'Founex': 1651,
    'Froideville': 1651,
    'Genolier': 1651,
    'Gimel': 1651,
    'Gland': 1651,
    'Grancy': 1651,
    'Grandson': 1651,
    'Gros-de-Vaud District': 1651,
    'Gryon': 1651,
    'Jongny': 1651,
    'Jura-Nord vaudois District': 1651,
    'La Sarraz': 1651,
    'La Tour-de-Peilz': 1651,
    'Lausanne': 1651,
    'Lausanne District': 1651,
    'Lavaux-Oron District': 1651,
    'Le Chenit': 1651,
    'Le Mont-sur-Lausanne': 1651,
    'Le Vaud': 1651,
    'Leysin': 1651,
    'Lucens': 1651,
    'Lutry': 1651,
    'Mauraz': 1651,
    'Mollens': 1651,
    'Montagny': 1651,
    'Montreux': 1651,
    'Morges': 1651,
    'Morges District': 1651,
    'Moudon': 1651,
    'Nyon': 1651,
    'Nyon District': 1651,
    'Ollon': 1651,
    'Orbe': 1651,
    'Oron-la-Ville': 1651,
    'Ouest Lausannois District': 1651,
    'Pailly': 1651,
    'Palézieux': 1651,
    'Pampigny': 1651,
    'Paudex': 1651,
    'Payerne': 1651,
    'Penthalaz': 1651,
    'Penthéréaz': 1651,
    'Perroy': 1651,
    'Pompaples': 1651,
    'Prangins': 1651,
    'Préverenges': 1651,
    'Prilly': 1651,
    'Puidoux': 1651,
    'Pully': 1651,
    'Renens': 1651,
    'Riviera-Pays-d  Enhaut District': 1651,
    'Rolle': 1651,
    'Romanel-sur-Lausanne': 1651,
    'Saint-Cergue': 1651,
    'Saint-Livres': 1651,
    'Saint-Prex': 1651,
    'Sainte-Croix': 1651,
    'Saubraz': 1651,
    'Savigny': 1651,
    'Sottens': 1651,
    'Trélex': 1651,
    'Vallorbe': 1651,
    'Vevey': 1651,
    'Villars-sur-Ollon': 1651,
    'Villeneuve': 1651,
    'Vuarrens': 1651,
    'Yverdon-les-Bains': 1651,
    'Yvonand': 1651,
    'Baar': 1646,
    'Cham': 1646,
    'Hünenberg': 1646,
    'Menzingen': 1646,
    'Rotkreuz': 1646,
    'Steinhausen': 1646,
    'Unterägeri': 1646,
    'Walchwil': 1646,
    'Zug': 1646,
    'Adliswil': 1656,
    'Adliswil / Adliswil (Stadtkern)': 1656,
    'Adliswil / Hündli-Zopf': 1656,
    'Adliswil / Oberleimbach': 1656,
    'Adliswil / Sonnenberg': 1656,
    'Adliswil / Sood': 1656,
    'Adliswil / Tal': 1656,
    'Aesch': 1656,
    'Affoltern / Hasenbüel': 1656,
    'Affoltern / Oberdorf': 1656,
    'Affoltern / Sonnenberg': 1656,
    'Affoltern / Unterdorf': 1656,
    'Affoltern am Albis': 1656,
    'Andelfingen': 1656,
    'Au': 1656,
    'Au / Mittel-Dorf': 1656,
    'Au / Unter-Dorf': 1656,
    'Bachenbülach': 1656,
    'Bäretswil': 1656,
    'Bassersdorf': 1656,
    'Bauma': 1656,
    'Benglen': 1656,
    'Bezirk Affoltern': 1656,
    'Bezirk Andelfingen': 1656,
    'Bezirk Bülach': 1656,
    'Bezirk Dielsdorf': 1656,
    'Bezirk Dietikon': 1656,
    'Bezirk Hinwil': 1656,
    'Bezirk Horgen': 1656,
    'Bezirk Meilen': 1656,
    'Bezirk Pfäffikon': 1656,
    'Bezirk Uster': 1656,
    'Bezirk Winterthur': 1656,
    'Bezirk Zürich': 1656,
    'Binz': 1656,
    'Binzikon': 1656,
    'Birchwil': 1656,
    'Birmensdorf': 1656,
    'Bonstetten': 1656,
    'Brütten': 1656,
    'Brüttisellen': 1656,
    'Bubikon': 1656,
    'Bülach': 1656,
    'Bülach / Gstückt': 1656,
    'Bülach / Seematt': 1656,
    'Bülach / Soligänter': 1656,
    'Dachsen': 1656,
    'Dällikon / Dällikon (Dorf)': 1656,
    'Dänikon': 1656,
    'Dielsdorf': 1656,
    'Dietikon': 1656,
    'Dietikon / Almend': 1656,
    'Dietikon / Guggenbühl': 1656,
    'Dietikon / Hofacker': 1656,
    'Dietikon / Kreuzacker': 1656,
    'Dietikon / Oberdorf': 1656,
    'Dietikon / Schönenwerd': 1656,
    'Dietikon / Vorstadt': 1656,
    'Dietlikon / Dietlikon (Dorf)': 1656,
    'Dietlikon / Eichwiesen': 1656,
    'Dorf': 1656,
    'Dübendorf': 1656,
    'Dübendorf / Kunklerstrasse': 1656,
    'Dübendorf / Sonnenberg': 1656,
    'Dübendorf / Vogelquartier': 1656,
    'Dübendorf / Wasserfurren': 1656,
    'Dürnten': 1656,
    'Ebmatingen': 1656,
    'Effretikon': 1656,
    'Effretikon / Rappenhalde-Bannhalde': 1656,
    'Effretikon / Rikon': 1656,
    'Effretikon / Watt': 1656,
    'Egg': 1656,
    'Elgg': 1656,
    'Elgg / Städtchen und Umgebung': 1656,
    'Elsau-Räterschen': 1656,
    'Elsau-Räterschen / Räterschen': 1656,
    'Embrach': 1656,
    'Embrach / Embrach (Dorfkern)': 1656,
    'Embrach / Kellersacker': 1656,
    'Erlenbach': 1656,
    'Erlenbach / links des Dorfbachs oberhalb Bahnlinie': 1656,
    'Erlenbach / rechts des Dorfbachs oberhalb Bahnlinie': 1656,
    'Esslingen': 1656,
    'Fällanden': 1656,
    'Fehraltorf': 1656,
    'Feldmeilen': 1656,
    'Feuerthalen': 1656,
    'Flurlingen': 1656,
    'Freienstein': 1656,
    'Gattikon': 1656,
    'Geroldswil': 1656,
    'Glattbrugg': 1656,
    'Glattbrugg / Rohr/Platten-Balsberg': 1656,
    'Glattbrugg / Wydacker/Bettacker/Lättenwiesen': 1656,
    'Glattfelden': 1656,
    'Gockhausen': 1656,
    'Gossau': 1656,
    'Greifensee': 1656,
    'Greifensee / Müllerwis / Seilerwis': 1656,
    'Greifensee / Pfisterhölzli': 1656,
    'Grossacker/Opfikon': 1656,
    'Grüt': 1656,
    'Gutenswil': 1656,
    'Hadlikon': 1656,
    'Hausen am Albis / Hausen (Dorf)': 1656,
    'Hedingen': 1656,
    'Hegnau': 1656,
    'Hegnau / Dammboden-Grindel': 1656,
    'Hegnau / Sunnebüel-Eich': 1656,
    'Henggart': 1656,
    'Herrliberg': 1656,
    'Hettlingen': 1656,
    'Hinteregg': 1656,
    'Hinwil': 1656,
    'Hittnau / Hittnau (Dorf)': 1656,
    'Hochfelden': 1656,
    'Hofstetten': 1656,
    'Hombrechtikon': 1656,
    'Horgen': 1656,
    'Horgen / Allmend': 1656,
    'Horgen / Horgen (Dorfkern)': 1656,
    'Horgen / Oberdorf': 1656,
    'Horgen / Scheller-Stockerstrasse': 1656,
    'Höri': 1656,
    'Illnau': 1656,
    'Illnau / Unter-Illnau': 1656,
    'Kilchberg': 1656,
    'Kilchberg / Bächler-Stocken': 1656,
    'Kilchberg / Kilchberg (Dorfkern)': 1656,
    'Kindhausen / Kindhausen (Dorf)': 1656,
    'Kleinandelfingen': 1656,
    'Kloten': 1656,
    'Kloten / Balsberg': 1656,
    'Kloten / Freienberg (Chanzler-Chlini Chaseren)': 1656,
    'Kloten / Geissberg': 1656,
    'Kloten / Holberg': 1656,
    'Kloten / Horainli': 1656,
    'Kloten / Hostrass': 1656,
    'Kloten / Kloten (Zentrum)': 1656,
    'Kloten / Rütlen': 1656,
    'Kloten / Spitz': 1656,
    'Knonau': 1656,
    'Kollbrunn': 1656,
    'Kollbrunn / Kollbrunn (Dorfkern)': 1656,
    'Küsnacht': 1656,
    'Küsnacht / Dorf': 1656,
    'Küsnacht / Goldbach': 1656,
    'Küsnacht / Heslibach': 1656,
    'Küsnacht / Itschnach': 1656,
    'Küsnacht / Schiedhalden': 1656,
    'Langnau / Langnau (Dorf)': 1656,
    'Langnau / Vitaquartier': 1656,
    'Langnau am Albis': 1656,
    'Männedorf': 1656,
    'Männedorf / Ausserfeld': 1656,
    'Männedorf / Dorfkern': 1656,
    'Marthalen': 1656,
    'Mattenbach (Kreis 7)': 1656,
    'Mattenbach (Kreis 7) / Deutweg': 1656,
    'Mattenbach (Kreis 7) / Endliker': 1656,
    'Mattenbach (Kreis 7) / Gutschick': 1656,
    'Meilen': 1656,
    'Mettmenstetten': 1656,
    'Mönchaltorf': 1656,
    'Mönchaltorf / Dorf': 1656,
    'Mühlehalde': 1656,
    'Nänikon': 1656,
    'Nänikon / Nänikon (Dorfkern)': 1656,
    'Neerach': 1656,
    'Neftenbach / Dorf Neftenbach': 1656,
    'Niederglatt': 1656,
    'Niederglatt / Niederglatt (Dorfkern)': 1656,
    'Niederhasli': 1656,
    'Nürensdorf': 1656,
    'Oberengstringen': 1656,
    'Oberengstringen / Rauchacher': 1656,
    'Oberengstringen / Sonnenberg': 1656,
    'Oberengstringen / Zentrum': 1656,
    'Oberglatt': 1656,
    'Oberglatt / Bahnhofquartier': 1656,
    'Oberglatt / Oberglatt (Dorfkern)': 1656,
    'Obermeilen': 1656,
    'Oberrieden': 1656,
    'Oberrieden / Berg': 1656,
    'Oberrieden / Mitte': 1656,
    'Oberwinterthur (Kreis 2)': 1656,
    'Oberwinterthur (Kreis 2) / Guggenbühl': 1656,
    'Oberwinterthur (Kreis 2) / Hegi': 1656,
    'Oberwinterthur (Kreis 2) / Talacker': 1656,
    'Oberwinterthur (Kreis 2) / Zinzikon': 1656,
    'Obfelden': 1656,
    'Obfelden / Oberlunnern': 1656,
    'Obfelden / Toussen': 1656,
    'Oetwil': 1656,
    'Opfikon': 1656,
    'Otelfingen': 1656,
    'Ottenbach': 1656,
    'Pfaffhausen': 1656,
    'Pfäffikon': 1656,
    'Pfäffikon / Irgenhausen': 1656,
    'Pfäffikon / Pfäffikon (Dorfkern)': 1656,
    'Pfungen': 1656,
    'Rafz': 1656,
    'Regensdorf': 1656,
    'Regensdorf / Feldblumen-Riedthofstrasse': 1656,
    'Regensdorf / Hofacher-Geeren': 1656,
    'Regensdorf / Obstgarten': 1656,
    'Richterswil': 1656,
    'Richterswil / Burghalde': 1656,
    'Richterswil / Dorfkern': 1656,
    'Richterswil / Richterswil (Dorfkern)': 1656,
    'Rorbas': 1656,
    'Rümlang': 1656,
    'Rümlang / Rümlang (Dorfkern)': 1656,
    'Rüschlikon': 1656,
    'Russikon': 1656,
    'Rüti': 1656,
    'Rüti / Dorfzentrum, Südl. Teil': 1656,
    'Rüti / Oberdorf': 1656,
    'Rüti / Westlicher Dorfteil': 1656,
    'Samstagern': 1656,
    'Schachen': 1656,
    'Schlieren': 1656,
    'Schlieren / Boden': 1656,
    'Schlieren / Engstingerquartier': 1656,
    'Schlieren / Freiestrasse': 1656,
    'Schlieren / Kamp': 1656,
    'Schlieren / Spital': 1656,
    'Schlieren / Zentrum': 1656,
    'Schwerzenbach': 1656,
    'Schwerzenbach / Blatten': 1656,
    'Schwerzenbach / Chimli': 1656,
    'Seen (Kreis 3)': 1656,
    'Seen (Kreis 3) / Büelwiesen': 1656,
    'Seen (Kreis 3) / Ganzenbühl': 1656,
    'Seen (Kreis 3) / Oberseen': 1656,
    'Seen (Kreis 3) / Waldegg': 1656,
    'Seen (Kreis 3) / Waser': 1656,
    'Seglingen': 1656,
    'Sellenbüren': 1656,
    'Seuzach / Seuzach (Dorf)': 1656,
    'Seuzach Dorf': 1656,
    'Seuzach Dorf / Breite-Weid': 1656,
    'Sonnhalde': 1656,
    'Stadt Winterthur (Kreis 1)': 1656,
    'Stadt Winterthur (Kreis 1) / Altstadt': 1656,
    'Stadt Winterthur (Kreis 1) / Brühlberg': 1656,
    'Stadt Winterthur (Kreis 1) / Heiligberg': 1656,
    'Stadt Winterthur (Kreis 1) / Lind': 1656,
    'Stadt Winterthur (Kreis 1) / Neuwiesen': 1656,
    'Stadt Winterthur (Kreis 1) / Tössfeld': 1656,
    'Stäfa': 1656,
    'Steinmaur': 1656,
    'Sulz': 1656,
    'Tagelswangen': 1656,
    'Tann': 1656,
    'Tann / Tann (Dorfkern)': 1656,
    'Thalwil': 1656,
    'Thalwil / Berg': 1656,
    'Thalwil / Dorfkern': 1656,
    'Thalwil / Nord': 1656,
    'Thalwil / See': 1656,
    'Thalwil / Süd': 1656,
    'Töss (Kreis 4)': 1656,
    'Töss (Kreis 4) / Eichliacker': 1656,
    'Töss (Kreis 4) / Schlosstal': 1656,
    'Töss (Kreis 4) / Vorder-Dättnau': 1656,
    'Turbenthal': 1656,
    'Uerikon': 1656,
    'Uetikon': 1656,
    'Uetikon / Grossdorf': 1656,
    'Uhwiesen': 1656,
    'Uitikon': 1656,
    'Unterengstringen': 1656,
    'Urdorf': 1656,
    'Urdorf / Bodenfeld': 1656,
    'Urdorf / Moos': 1656,
    'Urdorf / Oberurdorf': 1656,
    'Uster': 1656,
    'Uster / Gschwader': 1656,
    'Uster / Kirch-Uster': 1656,
    'Uster / Nieder-Uster': 1656,
    'Uster / Nossikon': 1656,
    'Uster / Ober-Uster': 1656,
    'Veltheim (Kreis 5)': 1656,
    'Veltheim (Kreis 5) / Blumenau': 1656,
    'Veltheim (Kreis 5) / Rosenberg': 1656,
    'Volketswil / Volketswil (Dorf)': 1656,
    'Wädenswil': 1656,
    'Wädenswil / Boller-Giessen': 1656,
    'Wädenswil / Büelen': 1656,
    'Wädenswil / Dorf (Wädenswil)': 1656,
    'Wädenswil / Eichweid': 1656,
    'Wädenswil / Hangenmoos': 1656,
    'Wädenswil / Leihof-Mühlebach': 1656,
    'Wädenswil / Untermosen-Fuhr': 1656,
    'Wald': 1656,
    'Wallisellen': 1656,
    'Wallisellen / Rieden': 1656,
    'Wallisellen / Wallisellen-Ost': 1656,
    'Wallisellen / Wallisellen-West': 1656,
    'Wangen': 1656,
    'Weiningen': 1656,
    'Weisslingen': 1656,
    'Wettswil / Ausser-Dorf': 1656,
    'Wettswil / Wettswil (Dorf)': 1656,
    'Wetzikon': 1656,
    'Wetzikon / Kempten': 1656,
    'Wetzikon / Ober-Wetzikon': 1656,
    'Wetzikon / Robenhausen': 1656,
    'Wetzikon / Unter-Wetzikon': 1656,
    'Wiesendangen / Wiesendangen (Dorf)': 1656,
    'Wila': 1656,
    'Winterthur': 1656,
    'Wolfhausen': 1656,
    'Wülflingen (Kreis 6)': 1656,
    'Wülflingen (Kreis 6) / Härti': 1656,
    'Wülflingen (Kreis 6) / Lindenplatz': 1656,
    'Wülflingen (Kreis 6) / Niederfeld': 1656,
    'Wülflingen (Kreis 6) / Oberfeld': 1656,
    'Zollikerberg': 1656,
    'Zollikon': 1656,
    'Zumikon': 1656,
    'Zürich': 1656,
    'Zürich (Kreis 1)': 1656,
    'Zürich (Kreis 1) / City': 1656,
    'Zürich (Kreis 1) / Lindenhof': 1656,
    'Zürich (Kreis 1) / Rathaus': 1656,
    'Zürich (Kreis 10)': 1656,
    'Zürich (Kreis 10) / Höngg': 1656,
    'Zürich (Kreis 10) / Rütihof': 1656,
    'Zürich (Kreis 10) / Wipkingen': 1656,
    'Zürich (Kreis 11)': 1656,
    'Zürich (Kreis 11) / Affoltern': 1656,
    'Zürich (Kreis 11) / Oerlikon': 1656,
    'Zürich (Kreis 11) / Schwandenholz': 1656,
    'Zürich (Kreis 11) / Seebach': 1656,
    'Zürich (Kreis 12)': 1656,
    'Zürich (Kreis 12) / Auzelg': 1656,
    'Zürich (Kreis 12) / Hirzenbach': 1656,
    'Zürich (Kreis 12) / Saatlen': 1656,
    'Zürich (Kreis 12) / Schwamendingen-Mitte': 1656,
    'Zürich (Kreis 2)': 1656,
    'Zürich (Kreis 2) / Enge': 1656,
    'Zürich (Kreis 2) / Mittel-Leimbach': 1656,
    'Zürich (Kreis 2) / Unter-Leimbach': 1656,
    'Zürich (Kreis 2) / Wollishofen': 1656,
    'Zürich (Kreis 3)': 1656,
    'Zürich (Kreis 3) / Alt-Wiedikon': 1656,
    'Zürich (Kreis 3) / Friesenberg': 1656,
    'Zürich (Kreis 3) / Sihlfeld': 1656,
    'Zürich (Kreis 4) / Aussersihl': 1656,
    'Zürich (Kreis 4) / Hard': 1656,
    'Zürich (Kreis 4) / Langstrasse': 1656,
    'Zürich (Kreis 4) / Werd': 1656,
    'Zürich (Kreis 5)': 1656,
    'Zürich (Kreis 5) / Escher-Wyss': 1656,
    'Zürich (Kreis 5) / Gewerbeschule': 1656,
    'Zürich (Kreis 6)': 1656,
    'Zürich (Kreis 6) / Oberstrass': 1656,
    'Zürich (Kreis 6) / Unterstrass': 1656,
    'Zürich (Kreis 7)': 1656,
    'Zürich (Kreis 7) / Fluntern': 1656,
    'Zürich (Kreis 7) / Hirslanden': 1656,
    'Zürich (Kreis 7) / Hottingen': 1656,
    'Zürich (Kreis 7) / Witikon': 1656,
    'Zürich (Kreis 8)': 1656,
    'Zürich (Kreis 8) / Mühlebach': 1656,
    'Zürich (Kreis 8) / Seefeld': 1656,
    'Zürich (Kreis 8) / Weinegg': 1656,
    'Zürich (Kreis 9)': 1656,
    'Zürich (Kreis 9) / Albisrieden': 1656,
    'Zürich (Kreis 9) / Altstetten': 1656,
    'Bilten': 1661,
    'Ennenda': 1661,
    'Glarus': 1661,
    'Linthal': 1661,
    'Luchsingen': 1661,
    'Mollis': 1661,
    'Näfels': 1661,
    'Netstal': 1661,
    'Niederurnen': 1661,
    'Oberurnen': 1661,
    'Schwanden': 1661,
    'Arosa': 1660,
    'Arvigo': 1660,
    'Bonaduz': 1660,
    'Breíl': 1660,
    'Brusio': 1660,
    'Buseno': 1660,
    'Cauco': 1660,
    'Cazis': 1660,
    'Celerina': 1660,
    'Chur': 1660,
    'Churwalden': 1660,
    'Davos': 1660,
    'Disentis': 1660,
    'Domat': 1660,
    'Felsberg': 1660,
    'Flims': 1660,
    'Grüsch': 1660,
    'Hinterrhein': 1660,
    'Igis': 1660,
    'Ilanz': 1660,
    'Jenaz': 1660,
    'Klosters Serneus': 1660,
    'La Punt Chamues-ch': 1660,
    'Laax': 1660,
    'Landquart': 1660,
    'Lenzerheide': 1660,
    'Luzein': 1660,
    'Maienfeld': 1660,
    'Malans': 1660,
    'Mesocco': 1660,
    'Pontresina': 1660,
    'Poschiavo': 1660,
    'Region Albula': 1660,
    'Region Bernina': 1660,
    'Region Engiadina Bassa / Val Müstair': 1660,
    'Region Imboden': 1660,
    'Region Landquart': 1660,
    'Region Maloja': 1660,
    'Region Moesa': 1660,
    'Region Plessur': 1660,
    'Region Prättigau / Davos': 1660,
    'Region Surselva': 1660,
    'Region Viamala': 1660,
    'Rhäzüns': 1660,
    'Samedan': 1660,
    'Scharans': 1660,
    'Schiers': 1660,
    'Scuol': 1660,
    'Silvaplana': 1660,
    'St. Moritz': 1660,
    'Tamins': 1660,
    'Thusis': 1660,
    'Tiefencastel': 1660,
    'Trimmis': 1660,
    'Trun': 1660,
    'Untervaz': 1660,
    'Vals Platz': 1660,
    'Zernez': 1660,
    'Zizers': 1660,
    'Zuoz': 1660,
    'Beckenried': 1652,
    'Buochs': 1652,
    'Dallenwil': 1652,
    'Emmetten': 1652,
    'Ennetbürgen': 1652,
    'Hergiswil': 1652,
    'Stans': 1652,
    'Stansstad': 1652,
    'Wolfenschiessen': 1652,
    'Alpnach': 1650,
    'Engelberg': 1650,
    'Giswil': 1650,
    'Kerns': 1650,
    'Lungern': 1650,
    'Sachseln': 1650,
    'Sarnen': 1650,
    'Altendorf': 1653,
    'Arth': 1653,
    'Bäch': 1653,
    'Bezirk Einsiedeln': 1653,
    'Bezirk Gersau': 1653,
    'Bezirk Höfe': 1653,
    'Bezirk Küssnacht': 1653,
    'Bezirk March': 1653,
    'Bezirk Schwyz': 1653,
    'Einsiedeln': 1653,
    'Feusisberg': 1653,
    'Freienbach': 1653,
    'Gersau': 1653,
    'Goldau': 1653,
    'Ibach': 1653,
    'Ingenbohl': 1653,
    'Küssnacht': 1653,
    'Lachen': 1653,
    'Muotathal': 1653,
    'Pfäffikon': 1653,
    'Reichenburg': 1653,
    'Rothenthurm': 1653,
    'Sattel': 1653,
    'Schindellegi': 1653,
    'Schübelbach': 1653,
    'Schwyz': 1653,
    'Siebnen': 1653,
    'Steinen': 1653,
    'Tuggen': 1653,
    'Unteriberg': 1653,
    'Vorderthal': 1653,
    'Wollerau': 1653,
    'Aadorf': 1657,
    'Affeltrangen': 1657,
    'Altnau': 1657,
    'Amriswil': 1657,
    'Arbon': 1657,
    'Arbon District': 1657,
    'Berg': 1657,
    'Bottighofen': 1657,
    'Bürglen': 1657,
    'Diessenhofen': 1657,
    'Egnach': 1657,
    'Erlen': 1657,
    'Ermatingen': 1657,
    'Eschenz': 1657,
    'Eschlikon': 1657,
    'Fischingen': 1657,
    'Frauenfeld': 1657,
    'Frauenfeld District': 1657,
    'Gachnang': 1657,
    'Gottlieben': 1657,
    'Güttingen': 1657,
    'Homburg': 1657,
    'Hüttwilen': 1657,
    'Kreuzlingen': 1657,
    'Kreuzlingen District': 1657,
    'Langrickenbach': 1657,
    'Märstetten-Dorf': 1657,
    'Matzingen': 1657,
    'Müllheim': 1657,
    'Münchwilen': 1657,
    'Münchwilen District': 1657,
    'Münsterlingen': 1657,
    'Pfyn': 1657,
    'Rickenbach bei Wil': 1657,
    'Roggwil': 1657,
    'Romanshorn': 1657,
    'Salmsach': 1657,
    'Sirnach': 1657,
    'Steckborn': 1657,
    'Stettfurt': 1657,
    'Sulgen': 1657,
    'Tägerwilen': 1657,
    'Thundorf': 1657,
    'Uttwil': 1657,
    'Wagenhausen': 1657,
    'Wängi': 1657,
    'Weinfelden': 1657,
    'Weinfelden District': 1657,
    'Wigoltingen': 1657,
    'Acquarossa': 1643,
    'Agno': 1643,
    'Airolo': 1643,
    'Arzo': 1643,
    'Ascona': 1643,
    'Balerna': 1643,
    'Bellinzona': 1643,
    'Bellinzona District': 1643,
    'Biasca': 1643,
    'Bioggio': 1643,
    'Blenio District': 1643,
    'Bodio': 1643,
    'Brissago': 1643,
    'Cadempino': 1643,
    'Cadenazzo': 1643,
    'Cadro': 1643,
    'Camorino': 1643,
    'Canobbio': 1643,
    'Caslano': 1643,
    'Castel San Pietro': 1643,
    'Cevio': 1643,
    'Chiasso': 1643,
    'Claro': 1643,
    'Comano': 1643,
    'Cugnasco': 1643,
    'Faido': 1643,
    'Giubiasco': 1643,
    'Gordola': 1643,
    'Gravesano': 1643,
    'Lavertezzo': 1643,
    'Leventina District': 1643,
    'Ligornetto': 1643,
    'Locarno': 1643,
    'Locarno District': 1643,
    'Lodrino': 1643,
    'Losone': 1643,
    'Lugano': 1643,
    'Lugano District': 1643,
    'Lumino': 1643,
    'Magadino': 1643,
    'Malvaglia': 1643,
    'Massagno': 1643,
    'Melano': 1643,
    'Melide': 1643,
    'Mendrisio': 1643,
    'Mendrisio District': 1643,
    'Minusio': 1643,
    'Montagnola': 1643,
    'Monte Carasso': 1643,
    'Morbio Inferiore': 1643,
    'Muralto': 1643,
    'Novazzano': 1643,
    'Pregassona': 1643,
    'Pura': 1643,
    'Riva San Vitale': 1643,
    'Riviera District': 1643,
    'Sementina': 1643,
    'Sorengo': 1643,
    'Stabio': 1643,
    'Tesserete': 1643,
    'Vallemaggia District': 1643,
    'Verscio': 1643,
    'Viganello': 1643,
    'Altdorf': 1642,
    'Andermatt': 1642,
    'Attinghausen': 1642,
    'Bauen': 1642,
    'Bürglen': 1642,
    'Erstfeld': 1642,
    'Flüelen': 1642,
    'Schattdorf': 1642,
    'Seedorf': 1642,
    'Silenen': 1642,
    'Ad Darbāsīyah': 2941,
    'Al Ḩasakah': 2941,
    'Al Mālikīyah': 2941,
    'Al Qāmishlī': 2941,
    'Al-Malikiyah District': 2941,
    'Amude': 2941,
    'Al-Thawrah District': 2944,
    'Ar Raqqah': 2944,
    'Ar-Raqqah District': 2944,
    'Ath Thawrah': 2944,
    'Tall Abyaḑ': 2944,
    'Tell Abyad District': 2944,
    '‘Afrīn': 2946,
    '‘Ayn al ‘Arab': 2946,
    'Afrin District': 2946,
    'Al Atārib': 2946,
    'Al Bāb': 2946,
    'Al-Bab District': 2946,
    'Aleppo': 2946,
    'As Safīrah': 2946,
    'Azaz District': 2946,
    'Dayr Ḩāfir': 2946,
    'I‘zāz': 2946,
    'Jarābulus': 2946,
    'Kafr Şaghīr': 2946,
    'Khanāşir': 2946,
    'Manbij': 2946,
    'Manbij District': 2946,
    'Mount Simeon District': 2946,
    'Nubl': 2946,
    'Şūrān': 2946,
    'Tādif': 2946,
    'Tall Rif‘at': 2946,
    'As-Suwayda': 2936,
    'As-Suwayda District': 2936,
    'Şalākhid': 2936,
    'Şalkhad': 2936,
    'Salkhad District': 2936,
    'Shahbā': 2936,
    'Shahba District': 2936,
    'Damascus': 2939,
    'Al Ḩarāk': 2945,
    'Al Muzayrīb': 2945,
    'Al-Sanamayn District': 2945,
    'Aş Şanamayn': 2945,
    'Ash Shaykh Miskīn': 2945,
    'Buşrá ash Shām': 2945,
    'Dar‘ā': 2945,
    'Ghabāghib': 2945,
    'Inkhil': 2945,
    'Izra District': 2945,
    'Izra‘': 2945,
    'Jāsim': 2945,
    'Nawá': 2945,
    'Ţafas': 2945,
    'Tasīl': 2945,
    'Al Mayādīn': 2937,
    'Ālbū Kamāl': 2937,
    'Deir ez-Zor': 2937,
    'Hajīn': 2937,
    'Subaykhān': 2937,
    'Al-Salamiyah District': 2934,
    'As Salamīyah': 2934,
    'As Suqaylibīyah': 2934,
    'Ḩalfāyā': 2934,
    'Hama District': 2934,
    'Ḩamāh': 2934,
    'Kafr Zaytā': 2934,
    'Maşyāf': 2934,
    'Masyaf District': 2934,
    'Mūrak': 2934,
    'Souran': 2934,
    'Tall Salḩab': 2934,
    'Ţayyibat al Imām': 2934,
    'Tremseh': 2934,
    'Al Ghanţū': 2942,
    'Al Qaryatayn': 2942,
    'Al Quşayr': 2942,
    'Al-Rastan District': 2942,
    'Ar Rastan': 2942,
    'Hisya': 2942,
    'Homs': 2942,
    'Kafr Lāhā': 2942,
    'Mukharram al Fawqānī': 2942,
    'Şadad': 2942,
    'Tadmur': 2942,
    'Tadmur District': 2942,
    'Tallbīsah': 2942,
    'Tallkalakh': 2942,
    'Ad Dānā': 2940,
    'Arīḩā': 2940,
    'Armanāz': 2940,
    'Binnish': 2940,
    'Darkūsh': 2940,
    'Harem District': 2940,
    'Ḩārim': 2940,
    'Idlib': 2940,
    'Jisr al-Shughur District': 2940,
    'Jisr ash Shughūr': 2940,
    'Kafr Takhārīm': 2940,
    'Kafranbel': 2940,
    'Khān Shaykhūn': 2940,
    'Ma‘arratmişrīn': 2940,
    'Maarrat al-Nu  man District': 2940,
    'Salqīn': 2940,
    'Sarāqib': 2940,
    'Sarmīn': 2940,
    'Taftanāz': 2940,
    'Al-Haffah District': 2938,
    'Jablah': 2938,
    'Jableh District': 2938,
    'Kassab': 2938,
    'Latakia': 2938,
    'Latakia District': 2938,
    'Qardaha District': 2938,
    'Şlinfah': 2938,
    'Al Qunayţirah': 2943,
    '‘Irbīn': 2935,
    'Al Kiswah': 2935,
    'Al Quţayfah': 2935,
    'Al-Zabadani District': 2935,
    'An Nabk': 2935,
    'At Tall': 2935,
    'Az Zabadānī': 2935,
    'Dārayyā': 2935,
    'Dayr al ‘Aşāfīr': 2935,
    'Douma': 2935,
    'Ḩarastā': 2935,
    'Jaramānā': 2935,
    'Jayrūd': 2935,
    'Ma‘lūlā': 2935,
    'Medaya': 2935,
    'Qārah': 2935,
    'Qaţanā': 2935,
    'Şaydnāyā': 2935,
    'Yabrūd': 2935,
    'Ad Duraykīsh': 2947,
    'Bāniyās': 2947,
    'Kaff al-Jaa': 2947,
    'Safita District': 2947,
    'Satita': 2947,
    'Tartouss': 2947,
    'Changhua': 3404,
    'Yuanlin': 3404,
    'Chiayi County': 3418,
    'Chiayi': 3408,
    'Pizitou': 3408,
    'Hsinchu County': 3417,
    'Hsinchu': 3423,
    'Hualien': 3411,
    'Hualien City': 3411,
    'Kaohsiung': 3412,
    'Jincheng': 3415,
    'Kinmen County': 3415,
    'Lienchiang': 3420,
    'Nangan': 3420,
    'Miaoli': 3413,
    'Lugu': 3407,
    'Nantou': 3407,
    'Puli': 3407,
    'Zhongxing New Village': 3407,
    'Magong': 3403,
    'Penghu County': 3403,
    'Donggang': 3405,
    'Hengchun': 3405,
    'Pingtung': 3405,
    'Taichung': 3406,
    'Taichung City': 3406,
    'Tainan': 3421,
    'Yujing': 3421,
    'Banqiao': 3422,
    'Jiufen': 3422,
    'Taipei': 3422,
    'Taipei City': 3422,
    'Taitung': 3410,
    'Taitung City': 3410,
    'Daxi': 3419,
    'Taoyuan': 3419,
    'Taoyuan City': 3419,
    'Yilan': 3402,
    'Douliu': 3416,
    'Yunlin': 3416,
    'Darband': 3397,
    'Hisor': 3397,
    'Karakenja': 3397,
    'Khodzha-Maston': 3397,
    'Novobod': 3397,
    'Obigarm': 3397,
    'Rasht': 3397,
    'Roghun': 3397,
    'Shahrinav': 3397,
    'Tagob': 3397,
    'Tursunzoda': 3397,
    'Vahdat': 3397,
    'Vahdat District': 3397,
    'Varzob': 3397,
    'Varzob District': 3397,
    'Ishqoshim': 3399,
    'Khorugh': 3399,
    'Murghob': 3399,
    'Nohiyai Shughnon': 3399,
    'Abdurahmoni Jomí': 3398,
    'Boshchorbogh': 3398,
    'Bŭstonqal’a': 3398,
    'Chubek': 3398,
    'Danghara': 3398,
    'Dŭstí': 3398,
    'Farkhor': 3398,
    'Gharavŭtí': 3398,
    'Jilikŭl': 3398,
    'Kirov': 3398,
    'Kolkhozobod': 3398,
    'Kŭlob': 3398,
    'Moskovskiy': 3398,
    'Mŭ’minobod': 3398,
    'Nohiyai Kolkhozobod': 3398,
    'Nohiyai Panj': 3398,
    'Nohiyai Vakhsh': 3398,
    'Norak': 3398,
    'Orzu': 3398,
    'Panj': 3398,
    'Qŭrghonteppa': 3398,
    'Shahritus': 3398,
    'Sovet': 3398,
    'Tartiki': 3398,
    'Vakhsh': 3398,
    'Vose’': 3398,
    'Yovon': 3398,
    'Adrasmon': 3400,
    'Ayní': 3400,
    'Bŭston': 3400,
    'Chkalov': 3400,
    'Ghafurov': 3400,
    'Isfara': 3400,
    'Istaravshan': 3400,
    'Khŭjand': 3400,
    'Kim': 3400,
    'Konibodom': 3400,
    'Konsoy': 3400,
    'Neftobod': 3400,
    'Nohiyai Konibodom': 3400,
    'Nov': 3400,
    'Oltintopkan': 3400,
    'Pakhtakoron': 3400,
    'Palos': 3400,
    'Panjakent': 3400,
    'Proletar': 3400,
    'Quruqsoy': 3400,
    'Shaydon': 3400,
    'Shŭrob': 3400,
    'Taboshar': 3400,
    'Vorukh': 3400,
    'Arusha': 1491,
    'Kingori': 1491,
    'Kiratu': 1491,
    'Longido': 1491,
    'Mbuguni': 1491,
    'Merelani': 1491,
    'Meru': 1491,
    'Monduli': 1491,
    'Mto wa Mbu': 1491,
    'Namanga': 1491,
    'Ngorongoro': 1491,
    'Nkoaranga': 1491,
    'Poli': 1491,
    'Usa River': 1491,
    'Dar es Salaam': 1490,
    'Magomeni': 1490,
    'Dodoma': 1466,
    'Kibakwe': 1466,
    'Kisasa': 1466,
    'Kondoa': 1466,
    'Kongwa': 1466,
    'Mpwapwa': 1466,
    'Msanga': 1466,
    'Buseresere': 1481,
    'Chato': 1481,
    'Geita': 1481,
    'Kasamwa': 1481,
    'Katoro': 1481,
    'Masumbwe': 1481,
    'Ushirombo': 1481,
    'Uyovu': 1481,
    'Ilula': 1489,
    'Iringa': 1489,
    'Izazi': 1489,
    'Mafinga': 1489,
    'Makungu': 1489,
    'Malangali': 1489,
    'Biharamulo': 1465,
    'Bugarama': 1465,
    'Bugene': 1465,
    'Bukoba': 1465,
    'Kabanga': 1465,
    'Kamachumu': 1465,
    'Katerero': 1465,
    'Katoro': 1465,
    'Kyaka': 1465,
    'Ngara': 1465,
    'Nshamba': 1465,
    'Nsunga': 1465,
    'Nyakahanga': 1465,
    'Rulenge': 1465,
    'Inyonga': 1482,
    'Karema': 1482,
    'Mpanda': 1482,
    'Usevia': 1482,
    'Kakonko': 1478,
    'Kasulu': 1478,
    'Kibondo': 1478,
    'Kigoma': 1478,
    'Mabamba': 1478,
    'Mwandiga': 1478,
    'Nguruka': 1478,
    'Uvinza': 1478,
    'Hedaru': 1467,
    'Kihurio': 1467,
    'Kisiwani': 1467,
    'Kwakoa': 1467,
    'Lembeni': 1467,
    'Makanya': 1467,
    'Moshi': 1467,
    'Mwembe': 1467,
    'Ndungu': 1467,
    'Same': 1467,
    'Lindi': 1483,
    'Mbekenyera': 1483,
    'Mingoyo': 1483,
    'Mtama': 1483,
    'Nachingwea': 1483,
    'Nyangao': 1483,
    'Ruangwa': 1483,
    'Babati': 1484,
    'Bashanet': 1484,
    'Basotu': 1484,
    'Dareda': 1484,
    'Dongobesh': 1484,
    'Endasak': 1484,
    'Galappo': 1484,
    'Katesh': 1484,
    'Kibaya': 1484,
    'Kirya': 1484,
    'Magugu': 1484,
    'Mbulu': 1484,
    'Naberera': 1484,
    'Nangwa': 1484,
    'Bukonyo': 1468,
    'Butiama': 1468,
    'Issenye': 1468,
    'Kibara': 1468,
    'Mugango': 1468,
    'Mugumu': 1468,
    'Muriti': 1468,
    'Musoma': 1468,
    'Nakatunguru': 1468,
    'Nansio': 1468,
    'Nyamuswa': 1468,
    'Sirari': 1468,
    'Geiro': 1470,
    'Ifakara': 1470,
    'Kidatu': 1470,
    'Kidodi': 1470,
    'Kilosa': 1470,
    'Kimamba': 1470,
    'Kisanga': 1470,
    'Lupiro': 1470,
    'Magole': 1470,
    'Mahenge': 1470,
    'Malinyi': 1470,
    'Mikumi': 1470,
    'Mlimba': 1470,
    'Morogoro': 1470,
    'Msowero': 1470,
    'Mtimbira': 1470,
    'Mvomero District': 1470,
    'Ngerengere': 1470,
    'Chiungutwa': 1476,
    'Kitama': 1476,
    'Kitangari': 1476,
    'Luchingu': 1476,
    'Lukuledi': 1476,
    'Lulindi': 1476,
    'Madimba': 1476,
    'Mahuta': 1476,
    'Masasi': 1476,
    'Masuguru': 1476,
    'Mtwara': 1476,
    'Namalenga': 1476,
    'Namikupa': 1476,
    'Nanganga': 1476,
    'Nangomba': 1476,
    'Nanhyanga': 1476,
    'Nanyamba': 1476,
    'Newala Kisimani': 1476,
    'Tandahimba': 1476,
    'Ilemela District': 1479,
    'Kihangara': 1479,
    'Malya': 1479,
    'Misasi': 1479,
    'Misungwi': 1479,
    'Mwanza': 1479,
    'Ngudu': 1479,
    'Nyanguge': 1479,
    'Usagara': 1479,
    'Ilembula': 1480,
    'Makumbako': 1480,
    'Manda': 1480,
    'Matamba': 1480,
    'Mlangali': 1480,
    'Mtwango': 1480,
    'Njombe': 1480,
    'Konde': 1488,
    'Micheweni': 1488,
    'Wete': 1488,
    'Bagamoyo': 1485,
    'Bungu': 1485,
    'Chalinze': 1485,
    'Ikwiriri': 1485,
    'Kibaha': 1485,
    'Kibiti': 1485,
    'Kilindoni': 1485,
    'Kisarawe': 1485,
    'Lugoba': 1485,
    'Maneromango': 1485,
    'Mbumi': 1485,
    'Mkuranga': 1485,
    'Mlandizi': 1485,
    'Mvomero': 1485,
    'Utete': 1485,
    'Vikindu': 1485,
    'Chala': 1477,
    'Kirando': 1477,
    'Laela': 1477,
    'Matai': 1477,
    'Namanyere': 1477,
    'Nkove': 1477,
    'Sumbawanga': 1477,
    'Kigonsera': 1486,
    'Liuli': 1486,
    'Mahanje': 1486,
    'Maposeni': 1486,
    'Matiri': 1486,
    'Mbamba Bay': 1486,
    'Mbinga': 1486,
    'Songea': 1486,
    'Tingi': 1486,
    'Isaka': 1463,
    'Kahama': 1463,
    'Kishapu': 1463,
    'Mhango': 1463,
    'Mwadui': 1463,
    'Old Shinyanga': 1463,
    'Shinyanga': 1463,
    'Songwa': 1463,
    'Tinde': 1463,
    'Bariadi': 1464,
    'Kisesa': 1464,
    'Lalago': 1464,
    'Malampaka': 1464,
    'Maswa': 1464,
    'Matonga': 1464,
    'Nyakabindi': 1464,
    'Nyalikungu': 1464,
    'Somanda': 1464,
    'Igugunu': 1474,
    'Ikungi': 1474,
    'Ilongero': 1474,
    'Itigi': 1474,
    'Kilimatinde': 1474,
    'Kintinku': 1474,
    'Kiomboi': 1474,
    'Mgandu': 1474,
    'Mtinko': 1474,
    'Mungaa': 1474,
    'Ndago': 1474,
    'Puma': 1474,
    'Sepuka': 1474,
    'Shelui': 1474,
    'Singida': 1474,
    'Chake Chake': 1472,
    'Mtambile': 1472,
    'Uwelini': 1472,
    'Bukene': 1469,
    'Igurubi': 1469,
    'Kaliua': 1469,
    'Mabama': 1469,
    'Sikonge': 1469,
    'Tabora': 1469,
    'Tumbi': 1469,
    'Usoke': 1469,
    'Chanika': 1487,
    'Lushoto': 1487,
    'Magomeni': 1487,
    'Majengo': 1487,
    'Makuyuni': 1487,
    'Maramba': 1487,
    'Matui': 1487,
    'Mazinde': 1487,
    'Mlalo': 1487,
    'Muheza': 1487,
    'Mwanga': 1487,
    'Pangani': 1487,
    'Soni': 1487,
    'Tanga': 1487,
    'Koani': 1471,
    'Koani Ndogo': 1471,
    'Mahonda': 1471,
    'Nganane': 1471,
    'Sokoni': 1471,
    'Gamba': 1473,
    'Kijini': 1473,
    'Kiwengwa': 1473,
    'Mkokotoni': 1473,
    'Nungwi': 1473,
    'Zanzibar': 1475,
    'Amnat Charoen': 3523,
    'Amphoe Chanuman': 3523,
    'Amphoe Hua Taphan': 3523,
    'Amphoe Lue Amnat': 3523,
    'Amphoe Mueang Amnat Charoen': 3523,
    'Amphoe Pathum Ratchawongsa': 3523,
    'Amphoe Phana': 3523,
    'Amphoe Senangkhanikhom': 3523,
    'Amphoe Chaiyo': 3519,
    'Amphoe Mueang Ang Thong': 3519,
    'Amphoe Pa Mok': 3519,
    'Amphoe Pho Thong': 3519,
    'Amphoe Sawaeng Ha': 3519,
    'Amphoe Wiset Chai Chan': 3519,
    'Ang Thong': 3519,
    'Ban Thai Tan': 3519,
    'Chaiyo': 3519,
    'Pho Thong': 3519,
    'Wiset Chaichan': 3519,
    'Bang Bon': 3554,
    'Bang Kapi': 3554,
    'Bang Khae': 3554,
    'Bang Khen': 3554,
    'Bang Kho laen': 3554,
    'Bang Khun thain': 3554,
    'Bang Na': 3554,
    'Bang Phlat': 3554,
    'Bang Rak': 3554,
    'Bang Sue': 3554,
    'Bangkok': 3554,
    'Bangkok Noi': 3554,
    'Bangkok Yai': 3554,
    'Bueng Kum': 3554,
    'Chatu Chak': 3554,
    'Chom Thong': 3554,
    'Din Daeng': 3554,
    'Don Mueang': 3554,
    'Dusit': 3554,
    'Huai Khwang': 3554,
    'Khan Na Yao': 3554,
    'Khlong Sam Wa': 3554,
    'Khlong San': 3554,
    'Khlong Toei': 3554,
    'Lak Si': 3554,
    'Lat Krabang': 3554,
    'Lat Phrao': 3554,
    'Min Buri': 3554,
    'Nong Chok': 3554,
    'Nong Khaem': 3554,
    'Parthum Wan': 3554,
    'Phasi Charoen': 3554,
    'Phaya Thai': 3554,
    'Phra Khanong': 3554,
    'Phra Nakhon': 3554,
    'Pom Prap Sattru Phai': 3554,
    'Pra Wet': 3554,
    'Rat Burana': 3554,
    'Ratchathewi': 3554,
    'Sai Mai': 3554,
    'Samphanthawong': 3554,
    'Saphan Sung': 3554,
    'Sathon': 3554,
    'Suanluang': 3554,
    'Taling Chan': 3554,
    'Thawi Watthana': 3554,
    'Thon buri': 3554,
    'Thung khru': 3554,
    'Vadhana': 3554,
    'Wang Thong Lang': 3554,
    'Yan na wa': 3554,
    'Amphoe Bueng Khong Long': 3533,
    'Amphoe Bung Khla': 3533,
    'Amphoe Mueang Bueng Kan': 3533,
    'Amphoe Pak Khat': 3533,
    'Amphoe Phon Charoen': 3533,
    'Amphoe Seka': 3533,
    'Amphoe Si Wilai': 3533,
    'Amphoe So Phisai': 3533,
    'Bueng Kan': 3533,
    'Seka': 3533,
    'Amphoe Ban Dan': 3534,
    'Amphoe Ban Kruat': 3534,
    'Amphoe Ban Mai Chaiyaphot': 3534,
    'Amphoe Chaloem Phra Kiat': 3534,
    'Amphoe Chamni': 3534,
    'Amphoe Huai Rat': 3534,
    'Amphoe Khaen Dong': 3534,
    'Amphoe Khu Mueang': 3534,
    'Amphoe Krasang': 3534,
    'Amphoe Lahan Sai': 3534,
    'Amphoe Lam Plai Mat': 3534,
    'Amphoe Mueang Buriram': 3534,
    'Amphoe Na Pho': 3534,
    'Amphoe Nang Rong': 3534,
    'Amphoe Non Din Daeng': 3534,
    'Amphoe Non Suwan': 3534,
    'Amphoe Nong Hong': 3534,
    'Amphoe Nong Ki': 3534,
    'Amphoe Pa Kham': 3534,
    'Amphoe Phlapphla Chai': 3534,
    'Amphoe Phutthaisong': 3534,
    'Amphoe Prakhon Chai': 3534,
    'Amphoe Satuek': 3534,
    'Buri Ram': 3534,
    'Nang Rong': 3534,
    'Nong Ki': 3534,
    'Prakhon Chai': 3534,
    'Satuek': 3534,
    'Amphoe Ban Pho': 3552,
    'Amphoe Bang Khla': 3552,
    'Amphoe Bang Nam Priao': 3552,
    'Amphoe Bang Pakong': 3552,
    'Amphoe Khlong Khuean': 3552,
    'Amphoe Mueang Chachoengsao': 3552,
    'Amphoe Phanom Sarakham': 3552,
    'Amphoe Plaeng Yao': 3552,
    'Amphoe Ratchasan': 3552,
    'Amphoe Sanam Chai Khet': 3552,
    'Amphoe Tha Takiap': 3552,
    'Bang Khla': 3552,
    'Bang Nam Priao': 3552,
    'Bang Pakong': 3552,
    'Chachoengsao': 3552,
    'Khlong Khuean': 3552,
    'Phanom Sarakham': 3552,
    'Amphoe Hankha': 3522,
    'Amphoe Manorom': 3522,
    'Amphoe Mueang Chainat': 3522,
    'Amphoe Noen Kham': 3522,
    'Amphoe Nong Mamong': 3522,
    'Amphoe Sankhaburi': 3522,
    'Amphoe Sapphaya': 3522,
    'Amphoe Wat Sing': 3522,
    'Chai Nat': 3522,
    'Amphoe Kaeng Hang Maeo': 3486,
    'Amphoe Khao Khitchakut': 3486,
    'Amphoe Khlung': 3486,
    'Amphoe Laem Sing': 3486,
    'Amphoe Makham': 3486,
    'Amphoe Mueang Chanthaburi': 3486,
    'Amphoe Na Yai Am': 3486,
    'Amphoe Pong Nam Ron': 3486,
    'Amphoe Soi Dao': 3486,
    'Amphoe Tha Mai': 3486,
    'Chanthaburi': 3486,
    'Khlung': 3486,
    'Laem Sing': 3486,
    'Pong Nam Ron': 3486,
    'Tha Mai': 3486,
    'Amphoe Chai Prakan': 3491,
    'Amphoe Chiang Dao': 3491,
    'Amphoe Chom Thong': 3491,
    'Amphoe Doi Lo': 3491,
    'Amphoe Doi Saket': 3491,
    'Amphoe Doi Tao': 3491,
    'Amphoe Fang': 3491,
    'Amphoe Galyani Vadhana': 3491,
    'Amphoe Hang Dong': 3491,
    'Amphoe Hot': 3491,
    'Amphoe Mae Ai': 3491,
    'Amphoe Mae Chaem': 3491,
    'Amphoe Mae On': 3491,
    'Amphoe Mae Rim': 3491,
    'Amphoe Mae Taeng': 3491,
    'Amphoe Mae Wang': 3491,
    'Amphoe Mueang Chiang Mai': 3491,
    'Amphoe Om Koi': 3491,
    'Amphoe Phrao': 3491,
    'Amphoe Samoeng': 3491,
    'Amphoe San Kamphaeng': 3491,
    'Amphoe San Pa Tong': 3491,
    'Amphoe San Sai': 3491,
    'Amphoe Saraphi': 3491,
    'Amphoe Wiang Haeng': 3491,
    'Chai Prakan': 3491,
    'Chiang Mai': 3491,
    'Chom Thong': 3491,
    'Fang': 3491,
    'Hang Dong': 3491,
    'Pai': 3491,
    'San Kamphaeng': 3491,
    'San Pa Tong': 3491,
    'Amphoe Chiang Khong': 3498,
    'Amphoe Chiang Saen': 3498,
    'Amphoe Doi Luang': 3498,
    'Amphoe Khun Tan': 3498,
    'Amphoe Mae Chan': 3498,
    'Amphoe Mae Fa Luang': 3498,
    'Amphoe Mae Lao': 3498,
    'Amphoe Mae Sai': 3498,
    'Amphoe Mae Suai': 3498,
    'Amphoe Mueang Chiang Rai': 3498,
    'Amphoe Pa Daet': 3498,
    'Amphoe Phan': 3498,
    'Amphoe Phaya Mengrai': 3498,
    'Amphoe Thoeng': 3498,
    'Amphoe Wiang Chai': 3498,
    'Amphoe Wiang Chiang Rung': 3498,
    'Amphoe Wiang Kaen': 3498,
    'Amphoe Wiang Pa Pao': 3498,
    'Chiang Khong': 3498,
    'Chiang Rai': 3498,
    'Chiang Saen': 3498,
    'Mae Chan': 3498,
    'Mae Sai': 3498,
    'Pa Daet': 3498,
    'Amphoe Ban Bueng': 3513,
    'Amphoe Bo Thong': 3513,
    'Amphoe Ko Chan': 3513,
    'Amphoe Ko Si Chang': 3513,
    'Amphoe Nong Yai': 3513,
    'Amphoe Phan Thong': 3513,
    'Amphoe Phanat Nikhom': 3513,
    'Amphoe Sattahip': 3513,
    'Amphoe Si Racha': 3513,
    'Ban Bueng': 3513,
    'Ban Ko Lan': 3513,
    'Ban Talat Bueng': 3513,
    'Bang Lamung': 3513,
    'Bang Lamung District': 3513,
    'Chon Buri': 3513,
    'Ko Si Chang': 3513,
    'Mueang Chonburi District': 3513,
    'Pattaya': 3513,
    'Phan Thong': 3513,
    'Phanat Nikhom': 3513,
    'Sattahip': 3513,
    'Si Racha': 3513,
    'Amphoe Lamae': 3526,
    'Amphoe Lang Suan': 3526,
    'Amphoe Mueang Chumphon': 3526,
    'Amphoe Pathio': 3526,
    'Amphoe Phato': 3526,
    'Amphoe Sawi': 3526,
    'Amphoe Tha Sae': 3526,
    'Amphoe Thung Tako': 3526,
    'Ban Nam Yuen': 3526,
    'Chumphon': 3526,
    'Lang Suan': 3526,
    'Amphoe Don Chan': 3550,
    'Amphoe Huai Mek': 3550,
    'Amphoe Huai Phueng': 3550,
    'Amphoe Kamalasai': 3550,
    'Amphoe Kham Muang': 3550,
    'Amphoe Khao Wong': 3550,
    'Amphoe Khong Chai': 3550,
    'Amphoe Kuchinarai': 3550,
    'Amphoe Mueang Kalasin': 3550,
    'Amphoe Na Khu': 3550,
    'Amphoe Na Mon': 3550,
    'Amphoe Nong Kung Si': 3550,
    'Amphoe Rong Kham': 3550,
    'Amphoe Sahatsakhan': 3550,
    'Amphoe Sam Chai': 3550,
    'Amphoe Somdet': 3550,
    'Amphoe Tha Khantho': 3550,
    'Amphoe Yang Talat': 3550,
    'Huai Mek': 3550,
    'Kalasin': 3550,
    'Kamalasai': 3550,
    'Khao Wong': 3550,
    'Kuchinarai': 3550,
    'Nong Kung Si': 3550,
    'Yang Talat': 3550,
    'Amphoe Bueng Samakkhi': 3516,
    'Amphoe Khanu Woralaksaburi': 3516,
    'Amphoe Khlong Khlung': 3516,
    'Amphoe Khlong Lan': 3516,
    'Amphoe Kosamphi Nakhon': 3516,
    'Amphoe Lan Krabue': 3516,
    'Amphoe Mueang Kamphaeng Phet': 3516,
    'Amphoe Pang Sila Thong': 3516,
    'Amphoe Phran Kratai': 3516,
    'Amphoe Sai Ngam': 3516,
    'Amphoe Sai Thong Watthana': 3516,
    'Kamphaeng Phet': 3516,
    'Khanu Woralaksaburi': 3516,
    'Lan Krabue': 3516,
    'Amphoe Bo Phloi': 3511,
    'Amphoe Dan Makham Tia': 3511,
    'Amphoe Huai Krachao': 3511,
    'Amphoe Lao Khwan': 3511,
    'Amphoe Mueang Kanchanaburi': 3511,
    'Amphoe Nong Prue': 3511,
    'Amphoe Phanom Thuan': 3511,
    'Amphoe Sai Yok': 3511,
    'Amphoe Sangkhla Buri': 3511,
    'Amphoe Si Sawat': 3511,
    'Amphoe Tha Maka': 3511,
    'Amphoe Tha Muang': 3511,
    'Amphoe Thong Pha Phum': 3511,
    'Bo Phloi': 3511,
    'Kanchanaburi': 3511,
    'Phanom Thuan': 3511,
    'Sangkhla Buri': 3511,
    'Tha Maka': 3511,
    'Tha Muang': 3511,
    'Amphoe Ban Fang': 3485,
    'Amphoe Ban Haet': 3485,
    'Amphoe Ban Phai': 3485,
    'Amphoe Chonnabot': 3485,
    'Amphoe Chum Phae': 3485,
    'Amphoe Khao Suan Kwang': 3485,
    'Amphoe Khok Pho Chai': 3485,
    'Amphoe Kranuan': 3485,
    'Amphoe Mancha Khiri': 3485,
    'Amphoe Mueang Khon Kaen': 3485,
    'Amphoe Nam Phong': 3485,
    'Amphoe Non Sila': 3485,
    'Amphoe Nong Na Kham': 3485,
    'Amphoe Nong Ruea': 3485,
    'Amphoe Nong Song Hong': 3485,
    'Amphoe Phon': 3485,
    'Amphoe Phra Yuen': 3485,
    'Amphoe Phu Pha Man': 3485,
    'Amphoe Phu Wiang': 3485,
    'Amphoe Pueai Noi': 3485,
    'Amphoe Sam Sung': 3485,
    'Amphoe Si Chom Phu': 3485,
    'Amphoe Ubolratana': 3485,
    'Amphoe Waeng Noi': 3485,
    'Amphoe Waeng Yai': 3485,
    'Amphoe Wiang Kao': 3485,
    'Ban Phai': 3485,
    'Chum Phae': 3485,
    'Khon Kaen': 3485,
    'Kranuan': 3485,
    'Phon': 3485,
    'Pueai Noi': 3485,
    'Amphoe Ao Luek': 3478,
    'Amphoe Khao Phanom': 3478,
    'Amphoe Khlong Thom': 3478,
    'Amphoe Ko Lanta': 3478,
    'Amphoe Lam Thap': 3478,
    'Amphoe Mueang Krabi': 3478,
    'Amphoe Nuea Khlong': 3478,
    'Amphoe Plai Phraya': 3478,
    'Ao Luek': 3478,
    'Ko Lanta': 3478,
    'Krabi': 3478,
    'Nuea Khlong': 3478,
    'Saladan': 3478,
    'Amphoe Chae Hom': 3544,
    'Amphoe Hang Chat': 3544,
    'Amphoe Ko Kha': 3544,
    'Amphoe Mae Mo': 3544,
    'Amphoe Mae Phrik': 3544,
    'Amphoe Mae Tha': 3544,
    'Amphoe Mueang Lampang': 3544,
    'Amphoe Mueang Pan': 3544,
    'Amphoe Ngao': 3544,
    'Amphoe Soem Ngam': 3544,
    'Amphoe Sop Prap': 3544,
    'Amphoe Thoen': 3544,
    'Amphoe Wang Nuea': 3544,
    'Lampang': 3544,
    'Thoen': 3544,
    'Wang Nuea': 3544,
    'Amphoe Ban Hong': 3483,
    'Amphoe Ban Thi': 3483,
    'Amphoe Li': 3483,
    'Amphoe Mae Tha': 3483,
    'Amphoe Mueang Lamphun': 3483,
    'Amphoe Pa Sang': 3483,
    'Amphoe Thung Hua Chang': 3483,
    'Amphoe Wiang Nong Long': 3483,
    'Lamphun': 3483,
    'Mae Tha': 3483,
    'Pa Sang': 3483,
    'Amphoe Chiang Khan': 3509,
    'Amphoe Dan Sai': 3509,
    'Amphoe Erawan': 3509,
    'Amphoe Mueang Loei': 3509,
    'Amphoe Na Duang': 3509,
    'Amphoe Na Haeo': 3509,
    'Amphoe Nong Hin': 3509,
    'Amphoe Pak Chom': 3509,
    'Amphoe Pha Khao': 3509,
    'Amphoe Phu Kradueng': 3509,
    'Amphoe Phu Luang': 3509,
    'Amphoe Phu Ruea': 3509,
    'Amphoe Tha Li': 3509,
    'Amphoe Wang Saphung': 3509,
    'Loei': 3509,
    'Phu Kradueng': 3509,
    'Wang Saphung': 3509,
    'Amphoe Ban Mi': 3543,
    'Amphoe Chai Badan': 3543,
    'Amphoe Khok Charoen': 3543,
    'Amphoe Khok Samrong': 3543,
    'Amphoe Lam Sonthi': 3543,
    'Amphoe Nong Muang': 3543,
    'Amphoe Phatthana Nikhom': 3543,
    'Amphoe Sa Bot': 3543,
    'Amphoe Tha Luang': 3543,
    'Amphoe Tha Wung': 3543,
    'Chai Badan': 3543,
    'Lop Buri': 3543,
    'Muang Lop Buri District': 3543,
    'Amphoe Khun Yuam': 3505,
    'Amphoe Mae La Noi': 3505,
    'Amphoe Mae Sariang': 3505,
    'Amphoe Mueang Mae Hong Son': 3505,
    'Amphoe Pai': 3505,
    'Amphoe Pang Mapha': 3505,
    'Amphoe Sop Moei': 3505,
    'Mae Hong Son': 3505,
    'Amphoe Borabue': 3517,
    'Amphoe Chiang Yuen': 3517,
    'Amphoe Chuen Chom': 3517,
    'Amphoe Kae Dam': 3517,
    'Amphoe Kantharawichai': 3517,
    'Amphoe Kosum Phisai': 3517,
    'Amphoe Kut Rang': 3517,
    'Amphoe Mueang Maha Sarakham': 3517,
    'Amphoe Na Chueak': 3517,
    'Amphoe Na Dun': 3517,
    'Amphoe Phayakkhaphum Phisai': 3517,
    'Amphoe Wapi Pathum': 3517,
    'Amphoe Yang Si Surat': 3517,
    'Kosum Phisai': 3517,
    'Maha Sarakham': 3517,
    'Phayakkhaphum Phisai': 3517,
    'Wapi Pathum': 3517,
    'Amphoe Don Tan': 3546,
    'Amphoe Dong Luang': 3546,
    'Amphoe Khamcha-i': 3546,
    'Amphoe Mueang Mukdahan': 3546,
    'Amphoe Nikhom Kham Soi': 3546,
    'Amphoe Nong Sung': 3546,
    'Amphoe Wan Yai': 3546,
    'Mukdahan': 3546,
    'Amphoe Ban Na': 3535,
    'Amphoe Mueang Nakhon Nayok': 3535,
    'Amphoe Ongkharak': 3535,
    'Amphoe Pak Phli': 3535,
    'Nakhon Nayok': 3535,
    'Amphoe Bang Len': 3503,
    'Amphoe Don Tum': 3503,
    'Amphoe Mueang Nakhon Pathom': 3503,
    'Amphoe Nakhon Chai Si': 3503,
    'Amphoe Phutthamonthon': 3503,
    'Amphoe Sam Phran': 3503,
    'Bang Len': 3503,
    'Kamphaeng Saen District': 3503,
    'Nakhon Pathom': 3503,
    'Sam Phran': 3503,
    'Amphoe Ban Phaeng': 3548,
    'Amphoe Mueang Nakhon Phanom': 3548,
    'Amphoe Na Kae': 3548,
    'Amphoe Na Thom': 3548,
    'Amphoe Na Wa': 3548,
    'Amphoe Phon Sawan': 3548,
    'Amphoe Pla Pak': 3548,
    'Amphoe Renu Nakhon': 3548,
    'Amphoe Si Songkhram': 3548,
    'Amphoe Tha Uthen': 3548,
    'Amphoe That Phanom': 3548,
    'Amphoe Wangyang': 3548,
    'Na Wa': 3548,
    'Nakhon Phanom': 3548,
    'That Phanom': 3548,
    'Amphoe Ban Lueam': 3497,
    'Amphoe Bua Lai': 3497,
    'Amphoe Bua Yai': 3497,
    'Amphoe Chakkarat': 3497,
    'Amphoe Chaloem Phra Kiat': 3497,
    'Amphoe Chok Chai': 3497,
    'Amphoe Chum Phuang': 3497,
    'Amphoe Dan Khun Thot': 3497,
    'Amphoe Huai Thalaeng': 3497,
    'Amphoe Kaeng Sanam Nang': 3497,
    'Amphoe Kham Sakae Saeng': 3497,
    'Amphoe Kham Thale So': 3497,
    'Amphoe Khon Buri': 3497,
    'Amphoe Khong': 3497,
    'Amphoe Lam Thamen Chai': 3497,
    'Amphoe Mueang Nakhon Ratchasima': 3497,
    'Amphoe Mueang Yang': 3497,
    'Amphoe Non Daeng': 3497,
    'Amphoe Non Sung': 3497,
    'Amphoe Non Thai': 3497,
    'Amphoe Nong Bun Mak': 3497,
    'Amphoe Pak Chong': 3497,
    'Amphoe Pak Thong Chai': 3497,
    'Amphoe Phimai': 3497,
    'Amphoe Phra Thong Kham': 3497,
    'Amphoe Prathai': 3497,
    'Amphoe Sida': 3497,
    'Amphoe Sikhio': 3497,
    'Amphoe Sikhiu': 3497,
    'Amphoe Soeng Sang': 3497,
    'Amphoe Sung Noen': 3497,
    'Amphoe Thepharak': 3497,
    'Amphoe Wang Nam Khiao': 3497,
    'Ban Huai Thalaeng': 3497,
    'Bua Yai': 3497,
    'Chok Chai': 3497,
    'Dan Khun Thot': 3497,
    'Kham Sakae Saeng': 3497,
    'Khon Buri': 3497,
    'Nakhon Ratchasima': 3497,
    'Non Sung': 3497,
    'Non Thai': 3497,
    'Pak Chong': 3497,
    'Pak Thong Chai': 3497,
    'Phimai': 3497,
    'Soeng Sang': 3497,
    'Sung Noen': 3497,
    'Amphoe Banphot Phisai': 3492,
    'Amphoe Chum Ta Bong': 3492,
    'Amphoe Chumsaeng': 3492,
    'Amphoe Kao Liao': 3492,
    'Amphoe Krok Phra': 3492,
    'Amphoe Lat Yao': 3492,
    'Amphoe Mae Poen': 3492,
    'Amphoe Mae Wong': 3492,
    'Amphoe Mueang Nakhon Sawan': 3492,
    'Amphoe Nong Bua': 3492,
    'Amphoe Phai Sali': 3492,
    'Amphoe Phayuha Khiri': 3492,
    'Amphoe Tak Fa': 3492,
    'Amphoe Takhli': 3492,
    'Amphoe Tha Tako': 3492,
    'Kao Liao': 3492,
    'Lat Yao': 3492,
    'Nakhon Sawan': 3492,
    'Nong Bua': 3492,
    'Phai Sali': 3492,
    'Phayuha Khiri': 3492,
    'Takhli': 3492,
    'Amphoe Bang Khan': 3520,
    'Amphoe Cha-uat': 3520,
    'Amphoe Chaloem Phra Kiat': 3520,
    'Amphoe Chang Klang': 3520,
    'Amphoe Chawang': 3520,
    'Amphoe Chian Yai': 3520,
    'Amphoe Chulabhorn': 3520,
    'Amphoe Hua Sai': 3520,
    'Amphoe Khanom': 3520,
    'Amphoe Lan Saka': 3520,
    'Amphoe Mueang Nakhon Si Thammarat': 3520,
    'Amphoe Na Bon': 3520,
    'Amphoe Nopphitam': 3520,
    'Amphoe Pak Phanang': 3520,
    'Amphoe Phipun': 3520,
    'Amphoe Phra Phrom': 3520,
    'Amphoe Phrom Khiri': 3520,
    'Amphoe Ron Phibun': 3520,
    'Amphoe Sichon': 3520,
    'Amphoe Tha Sala': 3520,
    'Amphoe Tham Phannara': 3520,
    'Amphoe Thung Song': 3520,
    'Amphoe Thung Yai': 3520,
    'Chawang': 3520,
    'Nakhon Si Thammarat': 3520,
    'Pak Phanang': 3520,
    'Ron Phibun': 3520,
    'Tham Phannara': 3520,
    'Thung Song': 3520,
    'Amphoe Ban Luang': 3530,
    'Amphoe Bo Kluea': 3530,
    'Amphoe Chaloem Phra Kiat': 3530,
    'Amphoe Chiang Klang': 3530,
    'Amphoe Mae Charim': 3530,
    'Amphoe Mueang Nan': 3530,
    'Amphoe Na Muen': 3530,
    'Amphoe Na Noi': 3530,
    'Amphoe Phu Phiang': 3530,
    'Amphoe Pua': 3530,
    'Amphoe Santi Suk': 3530,
    'Amphoe Song Khwae': 3530,
    'Amphoe Tha Wang Pha': 3530,
    'Amphoe Thung Chang': 3530,
    'Amphoe Wiang Sa': 3530,
    'Chiang Klang': 3530,
    'Nan': 3530,
    'Amphoe Ba Cho': 3553,
    'Amphoe Chanae': 3553,
    'Amphoe Cho-airong': 3553,
    'Amphoe Mueang Narathiwat': 3553,
    'Amphoe Ra-ngae': 3553,
    'Amphoe Rueso': 3553,
    'Amphoe Si Sakhon': 3553,
    'Amphoe Su-ngai Kolok': 3553,
    'Amphoe Su-ngai Padi': 3553,
    'Amphoe Sukhirin': 3553,
    'Amphoe Tak Bai': 3553,
    'Amphoe Waeng': 3553,
    'Amphoe Yi-ngo': 3553,
    'Ban Su-ngai Pa Di': 3553,
    'Narathiwat': 3553,
    'Ra-ngae': 3553,
    'Su-ngai Kolok': 3553,
    'Tak Bai': 3553,
    'Amphoe Mueang Nong Bua Lamphu': 3480,
    'Amphoe Na Klang': 3480,
    'Amphoe Na Wang': 3480,
    'Amphoe Non Sang': 3480,
    'Amphoe Si Bun Rueang': 3480,
    'Amphoe Suwannakhuha': 3480,
    'Na Klang': 3480,
    'Non Sang': 3480,
    'Nong Bua Lamphu': 3480,
    'Si Bun Rueang': 3480,
    'Amphoe Fao Rai': 3484,
    'Amphoe Mueang Nong Khai': 3484,
    'Amphoe Pho Tak': 3484,
    'Amphoe Phonphisai': 3484,
    'Amphoe Rattanawapi': 3484,
    'Amphoe Sa Khrai': 3484,
    'Amphoe Sangkhom': 3484,
    'Amphoe Si Chiang Mai': 3484,
    'Amphoe Tha Bo': 3484,
    'Nong Khai': 3484,
    'Phon Charoen': 3484,
    'Sangkhom': 3484,
    'Si Chiang Mai': 3484,
    'Tha Bo': 3484,
    'Amphoe Bang Bua Thong': 3495,
    'Amphoe Bang Kruai': 3495,
    'Amphoe Bang Yai': 3495,
    'Amphoe Mueang Nonthaburi': 3495,
    'Amphoe Pak Kret': 3495,
    'Amphoe Sai Noi': 3495,
    'Bang Bua Thong': 3495,
    'Bang Kruai': 3495,
    'Bang Yai': 3495,
    'Mueang Nonthaburi': 3495,
    'Pak Kret': 3495,
    'Amphoe Khlong Luang': 3500,
    'Amphoe Lam Luk Ka': 3500,
    'Amphoe Lat Lum Kaeo': 3500,
    'Amphoe Mueang Pathum Thani': 3500,
    'Amphoe Nong Suea': 3500,
    'Amphoe Thanyaburi': 3500,
    'Ban Lam Luk Ka': 3500,
    'Khlong Luang': 3500,
    'Pathum Thani': 3500,
    'Amphoe Kapho': 3540,
    'Amphoe Khok Pho': 3540,
    'Amphoe Mae Lan': 3540,
    'Amphoe Mai Kaen': 3540,
    'Amphoe Mayo': 3540,
    'Amphoe Mueang Pattani': 3540,
    'Amphoe Nong Chik': 3540,
    'Amphoe Panare': 3540,
    'Amphoe Sai Buri': 3540,
    'Amphoe Thung Yang Daeng': 3540,
    'Amphoe Yarang': 3540,
    'Amphoe Yaring': 3540,
    'Nong Chik': 3540,
    'Pattani': 3540,
    'Sai Buri': 3540,
    'Yaring': 3540,
    'Amphoe Kapong': 3549,
    'Amphoe Khura Buri': 3549,
    'Amphoe Ko Yao': 3549,
    'Amphoe Mueang Phangnga': 3549,
    'Amphoe Takua Pa': 3549,
    'Amphoe Takua Thung': 3549,
    'Amphoe Thai Mueang': 3549,
    'Amphoe Thap Put': 3549,
    'Ban Ao Nang': 3549,
    'Ban Khao Lak': 3549,
    'Ban Phru Nai': 3549,
    'Phang Nga': 3549,
    'Amphoe Bang Kaeo': 3488,
    'Amphoe Khao Chaison': 3488,
    'Amphoe Khuan Khanun': 3488,
    'Amphoe Kong Ra': 3488,
    'Amphoe Mueang Phatthalung': 3488,
    'Amphoe Pa Bon': 3488,
    'Amphoe Pa Phayom': 3488,
    'Amphoe Pak Phayun': 3488,
    'Amphoe Si Banphot': 3488,
    'Amphoe Srinagarindra': 3488,
    'Amphoe Tamot': 3488,
    'Phatthalung': 3488,
    'Amphoe Chiang Kham': 3538,
    'Amphoe Chiang Muan': 3538,
    'Amphoe Chun': 3538,
    'Amphoe Dok Kham Tai': 3538,
    'Amphoe Mae Chai': 3538,
    'Amphoe Mueang Phayao': 3538,
    'Amphoe Phu Kam Yao': 3538,
    'Amphoe Phu Sang': 3538,
    'Amphoe Pong': 3538,
    'Chiang Kham': 3538,
    'Dok Kham Tai': 3538,
    'Mae Chai': 3538,
    'Phayao': 3538,
    'Pong': 3538,
    'Amphoe Bueng Sam Phan': 3515,
    'Amphoe Chon Daen': 3515,
    'Amphoe Khao Kho': 3515,
    'Amphoe Lom Kao': 3515,
    'Amphoe Lom Sak': 3515,
    'Amphoe Mueang Phetchabun': 3515,
    'Amphoe Nam Nao': 3515,
    'Amphoe Nong Phai': 3515,
    'Amphoe Si Thep': 3515,
    'Amphoe Wang Pong': 3515,
    'Amphoe Wichian Buri': 3515,
    'Chon Daen': 3515,
    'Lom Sak': 3515,
    'Nong Phai': 3515,
    'Phetchabun': 3515,
    'Wichian Buri': 3515,
    'Amphoe Ban Laem': 3532,
    'Amphoe Ban Lat': 3532,
    'Amphoe Cha-am': 3532,
    'Amphoe Kaeng Krachan': 3532,
    'Amphoe Khao Yoi': 3532,
    'Amphoe Mueang Phetchaburi': 3532,
    'Amphoe Nong Ya Plong': 3532,
    'Amphoe Tha Yang': 3532,
    'Cha-am': 3532,
    'Khao Yoi': 3532,
    'Phetchaburi': 3532,
    'Tha Yang': 3532,
    'Amphoe Bang Mun Nak': 3514,
    'Amphoe Bueng Na Rang': 3514,
    'Amphoe Dong Charoen': 3514,
    'Amphoe Mueang Phichit': 3514,
    'Amphoe Pho Prathap Chang': 3514,
    'Amphoe Pho Thale': 3514,
    'Amphoe Sak Lek': 3514,
    'Amphoe Taphan Hin': 3514,
    'Amphoe Thap Khlo': 3514,
    'Amphoe Wachira Barami': 3514,
    'Amphoe Wang Sai Phun': 3514,
    'Bang Mun Nak': 3514,
    'Bueng Na Rang': 3514,
    'Phichit': 3514,
    'Taphan Hin': 3514,
    'Thap Khlo': 3514,
    'Wang Sai Phun': 3514,
    'Amphoe Bang Krathum': 3506,
    'Amphoe Bang Rakam': 3506,
    'Amphoe Chat Trakan': 3506,
    'Amphoe Mueang Phitsanulok': 3506,
    'Amphoe Nakhon Thai': 3506,
    'Amphoe Noen Maprang': 3506,
    'Amphoe Phrom Phiram': 3506,
    'Amphoe Wang Thong': 3506,
    'Amphoe Wat Bot': 3506,
    'Bang Krathum': 3506,
    'Bang Rakam': 3506,
    'Chat Trakan': 3506,
    'Nakhon Thai': 3506,
    'Phitsanulok': 3506,
    'Amphoe Ban Phraek': 3494,
    'Amphoe Bang Ban': 3494,
    'Amphoe Bang Pa-in': 3494,
    'Amphoe Bang Pahan': 3494,
    'Amphoe Bang Sai': 3494,
    'Amphoe Lat Bua Luang': 3494,
    'Amphoe Maha Rat': 3494,
    'Amphoe Nakhon Luang': 3494,
    'Amphoe Phachi': 3494,
    'Amphoe Phak Hai': 3494,
    'Amphoe Phra Nakhon Si Ayutthaya': 3494,
    'Amphoe Sena': 3494,
    'Amphoe Tha Ruea': 3494,
    'Amphoe Uthai': 3494,
    'Amphoe Wang Noi': 3494,
    'Ban Bang Kadi Pathum Thani': 3494,
    'Bang Ban': 3494,
    'Bang Pa-in': 3494,
    'Nakhon Luang': 3494,
    'Phak Hai': 3494,
    'Phra Nakhon Si Ayutthaya': 3494,
    'Tha Ruea': 3494,
    'Wang Noi': 3494,
    'Amphoe Den Chai': 3528,
    'Amphoe Long': 3528,
    'Amphoe Mueang Phrae': 3528,
    'Amphoe Nong Muang Khai': 3528,
    'Amphoe Rong Kwang': 3528,
    'Amphoe Song': 3528,
    'Amphoe Sung Men': 3528,
    'Amphoe Wang Chin': 3528,
    'Den Chai': 3528,
    'Long': 3528,
    'Phrae': 3528,
    'Rong Kwang': 3528,
    'Amphoe Kathu': 3536,
    'Amphoe Mueang Phuket': 3536,
    'Amphoe Thalang': 3536,
    'Ban Chalong': 3536,
    'Ban Karon': 3536,
    'Ban Kata': 3536,
    'Ban Ko Kaeo': 3536,
    'Ban Mai Khao': 3536,
    'Ban Ratsada': 3536,
    'Ban Talat Nua': 3536,
    'Ban Talat Yai': 3536,
    'Kathu': 3536,
    'Nai Harn': 3536,
    'Patong': 3536,
    'Phuket': 3536,
    'Rawai': 3536,
    'Wichit': 3536,
    'Amphoe Ban Sang': 3542,
    'Amphoe Kabin Buri': 3542,
    'Amphoe Mueang Prachin Buri': 3542,
    'Amphoe Na Di': 3542,
    'Amphoe Prachantakham': 3542,
    'Amphoe Si Maha Phot': 3542,
    'Amphoe Si Mahosot': 3542,
    'Kabin Buri': 3542,
    'Prachin Buri': 3542,
    'Amphoe Bang Saphan': 3508,
    'Amphoe Bang Saphan Noi': 3508,
    'Amphoe Hua Hin': 3508,
    'Amphoe Kui Buri': 3508,
    'Amphoe Mueang Prachuap Khiri Khan': 3508,
    'Amphoe Pran Buri': 3508,
    'Amphoe Sam Roi Yot': 3508,
    'Amphoe Thap Sakae': 3508,
    'Bang Saphan': 3508,
    'Hua Hin': 3508,
    'Kui Buri': 3508,
    'Prachuap Khiri Khan': 3508,
    'Pran Buri': 3508,
    'Sam Roi Yot': 3508,
    'Amphoe Kapoe': 3479,
    'Amphoe Kra Buri': 3479,
    'Amphoe La-un': 3479,
    'Amphoe Mueang Ranong': 3479,
    'Amphoe Suk Samran': 3479,
    'Ranong': 3479,
    'Amphoe Ban Kha': 3499,
    'Amphoe Ban Pong': 3499,
    'Amphoe Bang Phae': 3499,
    'Amphoe Chom Bueng': 3499,
    'Amphoe Damnoen Saduak': 3499,
    'Amphoe Mueang Ratchaburi': 3499,
    'Amphoe Pak Tho': 3499,
    'Amphoe Photharam': 3499,
    'Amphoe Suan Phueng': 3499,
    'Amphoe Wat Phleng': 3499,
    'Ban Pong': 3499,
    'Bang Phae': 3499,
    'Chom Bueng': 3499,
    'Damnoen Saduak': 3499,
    'Photharam': 3499,
    'Ratchaburi': 3499,
    'Amphoe Ban Chang': 3518,
    'Amphoe Ban Khai': 3518,
    'Amphoe Khao Chamao': 3518,
    'Amphoe Klaeng': 3518,
    'Amphoe Mueang Rayong': 3518,
    'Amphoe Nikhom Phattana': 3518,
    'Amphoe Pluak Daeng': 3518,
    'Amphoe Wang Chan': 3518,
    'Ban Chang': 3518,
    'Ban Phe': 3518,
    'Klaeng': 3518,
    'Rayong': 3518,
    'Amphoe At Samat': 3510,
    'Amphoe Changhan': 3510,
    'Amphoe Chaturaphak Phiman': 3510,
    'Amphoe Chiang Khwan': 3510,
    'Amphoe Kaset Wisai': 3510,
    'Amphoe Moeiwadi': 3510,
    'Amphoe Mueang Roi Et': 3510,
    'Amphoe Mueang Suang': 3510,
    'Amphoe Nong Hi': 3510,
    'Amphoe Nong Phok': 3510,
    'Amphoe Pathum Rat': 3510,
    'Amphoe Phanom Phrai': 3510,
    'Amphoe Pho Chai': 3510,
    'Amphoe Phon Sai': 3510,
    'Amphoe Phon Thong': 3510,
    'Amphoe Selaphum': 3510,
    'Amphoe Si Somdet': 3510,
    'Amphoe Suwannaphum': 3510,
    'Amphoe Thawatchaburi': 3510,
    'Amphoe Thung Khao Luang': 3510,
    'Ban Selaphum': 3510,
    'Kaset Wisai': 3510,
    'Moeiwadi': 3510,
    'Pho Chai': 3510,
    'Roi Et': 3510,
    'Suwannaphum': 3510,
    'Waeng': 3510,
    'Amphoe Aranyaprathet': 3529,
    'Amphoe Khao Chakan': 3529,
    'Amphoe Khlong Hat': 3529,
    'Amphoe Khok Sung': 3529,
    'Amphoe Mueang Sa Kaeo': 3529,
    'Amphoe Ta Phraya': 3529,
    'Amphoe Wang Nam Yen': 3529,
    'Amphoe Wang Sombun': 3529,
    'Amphoe Watthana Nakhon': 3529,
    'Aranyaprathet': 3529,
    'Sa Kaeo': 3529,
    'Wang Nam Yen': 3529,
    'Amphoe Akat Amnuai': 3501,
    'Amphoe Ban Muang': 3501,
    'Amphoe Charoen Sin': 3501,
    'Amphoe Kham Ta Kla': 3501,
    'Amphoe Khok Si Suphan': 3501,
    'Amphoe Kusuman': 3501,
    'Amphoe Kut Bak': 3501,
    'Amphoe Mueang Sakon Nakhon': 3501,
    'Amphoe Nikhom Nam Un': 3501,
    'Amphoe Phang Khon': 3501,
    'Amphoe Phanna Nikhom': 3501,
    'Amphoe Phon Na Kaeo': 3501,
    'Amphoe Phu Phan': 3501,
    'Amphoe Sawang Daen Din': 3501,
    'Amphoe Song Dao': 3501,
    'Amphoe Tao Ngoi': 3501,
    'Amphoe Wanon Niwat': 3501,
    'Amphoe Waritchaphum': 3501,
    'Sakon Nakhon': 3501,
    'Sawang Daen Din': 3501,
    'Amphoe Bang Bo': 3481,
    'Amphoe Bang Phli': 3481,
    'Amphoe Bang Sao Thong': 3481,
    'Amphoe Mueang Samut Prakan': 3481,
    'Amphoe Phra Pradaeng': 3481,
    'Amphoe Phra Samut Chedi': 3481,
    'Ban Khlong Bang Sao Thong': 3481,
    'Bang Bo District': 3481,
    'Phra Pradaeng': 3481,
    'Samut Prakan': 3481,
    'Amphoe Ban Phaeo': 3504,
    'Amphoe Krathum Baen': 3504,
    'Amphoe Mueang Samut Sakhon': 3504,
    'Ban Phaeo': 3504,
    'Krathum Baen': 3504,
    'Samut Sakhon': 3504,
    'Amphoe Amphawa': 3502,
    'Amphoe Bang Khonthi': 3502,
    'Amphoe Mueang Samut Songkhram': 3502,
    'Samut Songkhram': 3502,
    'Amphoe Ban Mo': 3487,
    'Amphoe Chaloem Phra Kiat': 3487,
    'Amphoe Don Phut': 3487,
    'Amphoe Kaeng Khoi': 3487,
    'Amphoe Muak Lek': 3487,
    'Amphoe Mueang Sara Buri': 3487,
    'Amphoe Nong Don': 3487,
    'Amphoe Nong Khae': 3487,
    'Amphoe Nong Saeng': 3487,
    'Amphoe Phra Phutthabat': 3487,
    'Amphoe Sao Hai': 3487,
    'Amphoe Wang Muang': 3487,
    'Amphoe Wihan Daeng': 3487,
    'Ban Mo': 3487,
    'Kaeng Khoi': 3487,
    'Nong Khae': 3487,
    'Phra Phutthabat': 3487,
    'Saraburi': 3487,
    'Amphoe Khuan Don': 3537,
    'Amphoe Khuan Kalong': 3537,
    'Amphoe La-Ngu': 3537,
    'Amphoe Manang': 3537,
    'Amphoe Mueang Satun': 3537,
    'Amphoe Tha Phae': 3537,
    'Amphoe Thung Wa': 3537,
    'Satun': 3537,
    'Amphoe Benchalak': 3547,
    'Amphoe Bueng Bun': 3547,
    'Amphoe Huai Thap Than': 3547,
    'Amphoe Kantharalak': 3547,
    'Amphoe Kanthararom': 3547,
    'Amphoe Khukhan': 3547,
    'Amphoe Khun Han': 3547,
    'Amphoe Mueang Chan': 3547,
    'Amphoe Mueang Sisaket': 3547,
    'Amphoe Nam Kliang': 3547,
    'Amphoe Non Khun': 3547,
    'Amphoe Phayu': 3547,
    'Amphoe Pho Si Suwan': 3547,
    'Amphoe Phrai Bueng': 3547,
    'Amphoe Phu Sing': 3547,
    'Amphoe Prang Ku': 3547,
    'Amphoe Rasi Salai': 3547,
    'Amphoe Si Rattana': 3547,
    'Amphoe Sila Lat': 3547,
    'Amphoe Uthumphon Phisai': 3547,
    'Amphoe Wang Hin': 3547,
    'Amphoe Yang Chum Noi': 3547,
    'Kantharalak': 3547,
    'Phrai Bueng': 3547,
    'Si Sa Ket': 3547,
    'Amphoe Bang Rachan': 3490,
    'Amphoe In Buri': 3490,
    'Amphoe Khai Bang Rachan': 3490,
    'Amphoe Mueang Sing Buri': 3490,
    'Amphoe Phrom Buri': 3490,
    'Amphoe Tha Chang': 3490,
    'Bang Racham': 3490,
    'In Buri': 3490,
    'Sing Buri': 3490,
    'Amphoe Bang Klam': 3539,
    'Amphoe Chana': 3539,
    'Amphoe Hat Yai': 3539,
    'Amphoe Khlong Hoi Khong': 3539,
    'Amphoe Khuan Niang': 3539,
    'Amphoe Krasae Sin': 3539,
    'Amphoe Mueang Songkhla': 3539,
    'Amphoe Na Mom': 3539,
    'Amphoe Na Thawi': 3539,
    'Amphoe Ranot': 3539,
    'Amphoe Rattaphum': 3539,
    'Amphoe Saba Yoi': 3539,
    'Amphoe Sadao': 3539,
    'Amphoe Sathing Phra': 3539,
    'Amphoe Singhanakhon': 3539,
    'Amphoe Thepha': 3539,
    'Ban Mai': 3539,
    'Hat Yai': 3539,
    'Na Mom': 3539,
    'Ranot': 3539,
    'Sadao': 3539,
    'Songkhla': 3539,
    'Amphoe Ban Dan Lan Hoi': 3545,
    'Amphoe Khiri Mat': 3545,
    'Amphoe Kong Krailat': 3545,
    'Amphoe Mueang Sukhothai': 3545,
    'Amphoe Sawankhalok': 3545,
    'Amphoe Si Nakhon': 3545,
    'Amphoe Si Samrong': 3545,
    'Amphoe Si Satchanalai': 3545,
    'Amphoe Thung Saliam': 3545,
    'Ban Dan Lan Hoi': 3545,
    'Ban Na': 3545,
    'Khiri Mat': 3545,
    'Sawankhalok': 3545,
    'Si Satchanalai': 3545,
    'Sukhothai': 3545,
    'Amphoe Bang Pla Ma': 3524,
    'Amphoe Dan Chang': 3524,
    'Amphoe Doembang Nangbuat': 3524,
    'Amphoe Don Chedi': 3524,
    'Amphoe Mueang Suphan Buri': 3524,
    'Amphoe Nong Yasai': 3524,
    'Amphoe Si Prachan': 3524,
    'Amphoe Song Phi Nong': 3524,
    'Amphoe U Thong': 3524,
    'Ban Sam Chuk': 3524,
    'Doembang Nangbuat': 3524,
    'Suphan Buri': 3524,
    'Amphoe Ban Na Doem': 3482,
    'Amphoe Ban Na San': 3482,
    'Amphoe Ban Takhun': 3482,
    'Amphoe Chai Buri': 3482,
    'Amphoe Chaiya': 3482,
    'Amphoe Don Sak': 3482,
    'Amphoe Kanchanadit': 3482,
    'Amphoe Khian Sa': 3482,
    'Amphoe Khiri Rat Nikhom': 3482,
    'Amphoe Ko Pha-ngan': 3482,
    'Amphoe Ko Samui': 3482,
    'Amphoe Mueang Surat Thani': 3482,
    'Amphoe Phanom': 3482,
    'Amphoe Phrasaeng': 3482,
    'Amphoe Phunphin': 3482,
    'Amphoe Tha Chana': 3482,
    'Amphoe Tha Chang': 3482,
    'Amphoe Wiang Sa': 3482,
    'Amphoe Wiphawadi': 3482,
    'Ban Na San': 3482,
    'Chai Buri': 3482,
    'Chaiya': 3482,
    'Don Sak': 3482,
    'Kanchanadit': 3482,
    'Ko Pha Ngan': 3482,
    'Ko Samui': 3482,
    'Koh Tao': 3482,
    'Surat Thani': 3482,
    'Tha Kham': 3482,
    'Tha Khanon': 3482,
    'Wiang Sa': 3482,
    'Amphoe Bua Chet': 3531,
    'Amphoe Chom Phra': 3531,
    'Amphoe Chumphon Buri': 3531,
    'Amphoe Kap Choeng': 3531,
    'Amphoe Khwao Sinarin': 3531,
    'Amphoe Lamduan': 3531,
    'Amphoe Mueang Surin': 3531,
    'Amphoe Non Narai': 3531,
    'Amphoe Phanom Dong Rak': 3531,
    'Amphoe Prasat': 3531,
    'Amphoe Rattanaburi': 3531,
    'Amphoe Samrong Thap': 3531,
    'Amphoe Sangkha': 3531,
    'Amphoe Sanom': 3531,
    'Amphoe Si Khoraphum': 3531,
    'Amphoe Si Narong': 3531,
    'Amphoe Tha Tum': 3531,
    'Surin': 3531,
    'Amphoe Ban Tak': 3525,
    'Amphoe Mae Ramat': 3525,
    'Amphoe Mae Sot': 3525,
    'Amphoe Mueang Tak': 3525,
    'Amphoe Phop Phra': 3525,
    'Amphoe Tha Song Yang': 3525,
    'Amphoe Umphang': 3525,
    'Amphoe Wang Chao': 3525,
    'Ban Tak': 3525,
    'Mae Ramat': 3525,
    'Mae Sot': 3525,
    'Tak': 3525,
    'Amphoe Hat Samran': 3541,
    'Amphoe Huai Yot': 3541,
    'Amphoe Kantang': 3541,
    'Amphoe Mueang Trang': 3541,
    'Amphoe Na Yong': 3541,
    'Amphoe Palian': 3541,
    'Amphoe Ratsada': 3541,
    'Amphoe Sikao': 3541,
    'Amphoe Wang Wiset': 3541,
    'Amphoe Yan Ta Khao': 3541,
    'Huai Yot': 3541,
    'Kantang': 3541,
    'Trang': 3541,
    'Amphoe Bo Rai': 3496,
    'Amphoe Khao Saming': 3496,
    'Amphoe Khlong Yai': 3496,
    'Amphoe Ko Chang': 3496,
    'Amphoe Ko Kut': 3496,
    'Amphoe Laem Ngop': 3496,
    'Amphoe Mueang Trat': 3496,
    'Bo Rai': 3496,
    'Khlong Yai': 3496,
    'Ko Chang Tai': 3496,
    'Trat': 3496,
    'Amphoe Buntharik': 3512,
    'Amphoe Det Udom': 3512,
    'Amphoe Don Mot Daeng': 3512,
    'Amphoe Khemmarat': 3512,
    'Amphoe Khong Chiam': 3512,
    'Amphoe Khueang Nai': 3512,
    'Amphoe Kut Khaopun': 3512,
    'Amphoe Lao Suea Kok': 3512,
    'Amphoe Muang Sam Sip': 3512,
    'Amphoe Mueang Ubon Ratchathani': 3512,
    'Amphoe Na Chaluai': 3512,
    'Amphoe Na Tan': 3512,
    'Amphoe Na Yia': 3512,
    'Amphoe Nam Khun': 3512,
    'Amphoe Nam Yuen': 3512,
    'Amphoe Phibun Mangsahan': 3512,
    'Amphoe Pho Sai': 3512,
    'Amphoe Samrong': 3512,
    'Amphoe Sawang Wirawong': 3512,
    'Amphoe Si Mueang Mai': 3512,
    'Amphoe Sirindhorn': 3512,
    'Amphoe Tan Sum': 3512,
    'Amphoe Thung Si Udom': 3512,
    'Amphoe Trakan Phuet Phon': 3512,
    'Amphoe Warin Chamrap': 3512,
    'Det Udom': 3512,
    'Phibun Mangsahan': 3512,
    'Sawang Wirawong': 3512,
    'Ubon Ratchathani': 3512,
    'Warin Chamrap': 3512,
    'Amphoe Ban Dung': 3527,
    'Amphoe Ban Phue': 3527,
    'Amphoe Chai Wan': 3527,
    'Amphoe Ku Kaeo': 3527,
    'Amphoe Kumphawapi': 3527,
    'Amphoe Kut Chap': 3527,
    'Amphoe Mueang Udon Thani': 3527,
    'Amphoe Na Yung': 3527,
    'Amphoe Nam Som': 3527,
    'Amphoe Non Sa-at': 3527,
    'Amphoe Nong Han': 3527,
    'Amphoe Nong Saeng': 3527,
    'Amphoe Nong Wua So': 3527,
    'Amphoe Phen': 3527,
    'Amphoe Phibun Rak': 3527,
    'Amphoe Prachak Sinlapakhom': 3527,
    'Amphoe Sang Khom': 3527,
    'Amphoe Si That': 3527,
    'Amphoe Thung Fon': 3527,
    'Amphoe Wang Sam Mo': 3527,
    'Ban Dung': 3527,
    'Ban Nong Wua So': 3527,
    'Ban Phan Don': 3527,
    'Kumphawapi': 3527,
    'Kut Chap': 3527,
    'Nam Som': 3527,
    'Nong Wua So': 3527,
    'Udon Thani': 3527,
    'Amphoe Ban Rai': 3551,
    'Amphoe Huai Khot': 3551,
    'Amphoe Lan Sak': 3551,
    'Amphoe Mueang Uthai Thani': 3551,
    'Amphoe Nong Chang': 3551,
    'Amphoe Nong Khayang': 3551,
    'Amphoe Sawang Arom': 3551,
    'Amphoe Thap Than': 3551,
    'Huai Khot': 3551,
    'Lan Sak': 3551,
    'Thap Than': 3551,
    'Uthai Thani': 3551,
    'Amphoe Ban Khok': 3489,
    'Amphoe Fak Tha': 3489,
    'Amphoe Lap Lae': 3489,
    'Amphoe Mueang Uttaradit': 3489,
    'Amphoe Nam Pat': 3489,
    'Amphoe Phichai': 3489,
    'Amphoe Tha Pla': 3489,
    'Amphoe Thong Saen Khan': 3489,
    'Amphoe Tron': 3489,
    'Uttaradit': 3489,
    'Amphoe Bannang Sata': 3493,
    'Amphoe Betong': 3493,
    'Amphoe Kabang': 3493,
    'Amphoe Krong Pinang': 3493,
    'Amphoe Mueang Yala': 3493,
    'Amphoe Raman': 3493,
    'Amphoe Than To': 3493,
    'Amphoe Yaha': 3493,
    'Betong': 3493,
    'Yala': 3493,
    'Amphoe Kham Khuan Kaeo': 3521,
    'Amphoe Kho Wang': 3521,
    'Amphoe Kut Chum': 3521,
    'Amphoe Loeng Nok Tha': 3521,
    'Amphoe Maha Chana Chai': 3521,
    'Amphoe Mueang Yasothon': 3521,
    'Amphoe Pa Tio': 3521,
    'Amphoe Sai Mun': 3521,
    'Amphoe Thai Charoen': 3521,
    'Yasothon': 3521,
    'Sokodé': 2575,
    'Sotouboua': 2575,
    'Tchamba': 2575,
    'Bafilo': 2579,
    'Bassar': 2579,
    'Kandé': 2579,
    'Kara': 2579,
    'Niamtougou': 2579,
    'Pagouda': 2579,
    'Préfecture de Bassar': 2579,
    'Aného': 2576,
    'Lomé': 2576,
    'Tabligbo': 2576,
    'Tsévié': 2576,
    'Vogan': 2576,
    'Amlamé': 2577,
    'Atakpamé': 2577,
    'Badou': 2577,
    'Kpalimé': 2577,
    'Notsé': 2577,
    'Dapaong': 2578,
    'Sansanné-Mango': 2578,
    'Pangai': 3913,
    '‘Ohonua': 3915,
    'Hihifo': 3914,
    'Haveluloto': 3912,
    'Kolonga': 3912,
    'Nuku‘alofa': 3912,
    'Vaini': 3912,
    'Neiafu': 3911,
    'Arima': 3362,
    'Chaguanas': 3366,
    'Ward of Chaguanas': 3366,
    'Couva': 3354,
    'Tabaquite': 3354,
    'Petit Valley': 3367,
    'Ward of Diego Martin': 3367,
    'Scarborough': 3355,
    'Debe': 3365,
    'Peñal': 3365,
    'Point Fortin': 3360,
    'Mucurapo': 3363,
    'Port of Spain': 3363,
    'Princes Town': 3368,
    'Marabella': 3359,
    'Mon Repos': 3359,
    'San Fernando': 3359,
    'Laventille': 3357,
    'Sangre Grande': 3361,
    'Siparia': 3364,
    'Ward of Siparia': 3364,
    'Arouca': 3358,
    'Paradise': 3358,
    'Tunapuna': 3358,
    'Rio Claro': 3353,
    'Ariana': 2550,
    'Galaat el Andeless': 2550,
    'Ben Arous': 2566,
    'Hammam Lif': 2566,
    'Hammam-Lif': 2566,
    'La Sebala du Mornag': 2566,
    'Radès': 2566,
    'Al Matlīn': 2551,
    'Bizerte': 2551,
    'Bizerte Sud': 2551,
    'Douar Tindja': 2551,
    'El Alia': 2551,
    'Mateur': 2551,
    'Menzel Abderhaman': 2551,
    'Menzel Bourguiba': 2551,
    'Menzel Jemil': 2551,
    'Rafrāf': 2551,
    'Rhar el Melah': 2551,
    'Sejenane': 2551,
    'Zahānah': 2551,
    'Bou Attouche': 2558,
    'El Hamma': 2558,
    'Gabès': 2558,
    'Matmata': 2558,
    'Ar Rudayyif': 2556,
    'As Sanad': 2556,
    'Gafsa': 2556,
    'Metlaoui': 2556,
    'Mu‘tamadīyat ar Rudayyif': 2556,
    'Fernana': 2552,
    'Jendouba': 2552,
    'Oued Meliz': 2552,
    'Tabarka': 2552,
    'Haffouz': 2564,
    'Kairouan': 2564,
    'Sbikha': 2564,
    'Kasserine': 2570,
    'Rohia': 2570,
    'Sbiba': 2570,
    'Thala': 2570,
    'Béja': 2572,
    'Délégation de Béja Nord': 2572,
    'Goubellat': 2572,
    'Medjez el Bab': 2572,
    'Tabursuq': 2572,
    'Testour': 2572,
    'Douz': 2562,
    'El Golaa': 2562,
    'Jemna': 2562,
    'Kebili': 2562,
    'As Sars': 2561,
    'El Kef': 2561,
    'El Ksour': 2561,
    'Menzel Salem': 2561,
    'Nibbar': 2561,
    'Sakiet Sidi Youssef': 2561,
    'Tajerouine': 2561,
    'Chebba': 2568,
    'Chorbane': 2568,
    'El Jem': 2568,
    'Ksour Essaf': 2568,
    'Mahdia': 2568,
    'Melloulèche': 2568,
    'Salakta': 2568,
    'Sidi Alouane': 2568,
    'Zouila': 2568,
    'El Battan': 2555,
    'Manouba': 2555,
    'Mu‘tamadīyat Manūbah': 2555,
    'Oued Lill': 2555,
    'Ben Gardane': 2560,
    'Beni Kheddache': 2560,
    'Erriadh': 2560,
    'Houmt El Souk': 2560,
    'Jerba Midoun': 2560,
    'Medenine': 2560,
    'Midoun': 2560,
    'Zarzis': 2560,
    'Banbalah': 2553,
    'Bekalta': 2553,
    'Beni Hassane': 2553,
    'Djemmal': 2553,
    'Ksar Hellal': 2553,
    'Ksibet el Mediouni': 2553,
    'Lemta': 2553,
    'Menzel Kamel': 2553,
    'Mesdour': 2553,
    'Monastir': 2553,
    'Ouardenine': 2553,
    'Sahline': 2553,
    'Seïada': 2553,
    'Sidi Ben Nour': 2553,
    'Skanes': 2553,
    'Touza': 2553,
    'Agareb': 2557,
    'Bir Ali Ben Khalifa': 2557,
    'Djebeniana': 2557,
    'Gremda': 2557,
    'Sfax': 2557,
    'Skhira': 2557,
    'Bir el Hafey': 2567,
    'Er Regueb': 2567,
    'Jilma': 2567,
    'Mezzouna': 2567,
    'Sidi Bouzid': 2567,
    'Bou Arada': 2563,
    'Gafour': 2563,
    'Kesra': 2563,
    'Le Krib': 2563,
    'Maktar': 2563,
    'Siliana': 2563,
    'Akouda': 2571,
    'Hammam Sousse': 2571,
    'Harqalah': 2571,
    'Msaken': 2571,
    'Port el Kantaoui': 2571,
    'Sidi Bou Ali': 2571,
    'Sidi el Hani': 2571,
    'Sousse': 2571,
    'Remada': 2559,
    'Tataouine': 2559,
    'Chebika': 2569,
    'Degache': 2569,
    'Nefta': 2569,
    'Tamaghzah': 2569,
    'Tozeur': 2569,
    'Al Marsá': 2554,
    'Carthage': 2554,
    'La Goulette': 2554,
    'La Mohammedia': 2554,
    'Sidi Bou Saïd': 2554,
    'Tunis': 2554,
    'El Fahs': 2565,
    'Zaghouan': 2565,
    'Adana': 2212,
    'Aladağ': 2212,
    'Bahçe': 2212,
    'Ceyhan': 2212,
    'Çukurova': 2212,
    'Feke': 2212,
    'İmamoğlu': 2212,
    'Karaisalı': 2212,
    'Karataş': 2212,
    'Kozan': 2212,
    'Pozantı': 2212,
    'Saimbeyli': 2212,
    'Sarıçam': 2212,
    'Seyhan': 2212,
    'Tufanbeyli': 2212,
    'Yumurtalık': 2212,
    'Yüreğir': 2212,
    'Adıyaman': 2155,
    'Aralık İlçesi': 2155,
    'Besni': 2155,
    'Çelikhan': 2155,
    'Gerger': 2155,
    'Gölbaşı': 2155,
    'Kâhta': 2155,
    'Merkez': 2155,
    'Samsat': 2155,
    'Sincik': 2155,
    'Tut': 2155,
    'Afyonkarahisar': 2179,
    'Başmakçı': 2179,
    'Bayat': 2179,
    'Bolvadin': 2179,
    'Çay': 2179,
    'Çobanlar İlçesi': 2179,
    'Dazkırı': 2179,
    'Dinar': 2179,
    'Emirdağ': 2179,
    'Evciler': 2179,
    'Hocalar': 2179,
    'İhsaniye': 2179,
    'İscehisar': 2179,
    'Işıklar': 2179,
    'Kızılören': 2179,
    'Merkez': 2179,
    'Sandıklı': 2179,
    'Sinanpaşa': 2179,
    'Şuhut': 2179,
    'Sultandağı': 2179,
    'Ağrı': 2193,
    'Diyadin': 2193,
    'Doğubayazıt': 2193,
    'Eleşkirt': 2193,
    'Hamur': 2193,
    'Patnos': 2193,
    'Taşlıçay': 2193,
    'Tutak İlçesi': 2193,
    'Ağaçören': 2210,
    'Aksaray': 2210,
    'Eskil İlçesi': 2210,
    'Gülağaç İlçesi': 2210,
    'Güzelyurt': 2210,
    'Merkez': 2210,
    'Ortaköy': 2210,
    'Sarıyahşi': 2210,
    'Sultanhanı': 2210,
    'Amasya': 2161,
    'Dedeköy': 2161,
    'Göynücek': 2161,
    'Gümüşhacıköy': 2161,
    'Hamamözü İlçesi': 2161,
    'Merkez': 2161,
    'Merzifon İlçesi': 2161,
    'Suluova': 2161,
    'Taşova': 2161,
    'Akyurt': 2217,
    'Altındağ': 2217,
    'Altpınar': 2217,
    'Ankara': 2217,
    'Ayaş': 2217,
    'Bala İlçesi': 2217,
    'Batikent': 2217,
    'Beypazari': 2217,
    'Beypazarı': 2217,
    'Çamlıdere': 2217,
    'Çankaya': 2217,
    'Çubuk': 2217,
    'Elmadağ': 2217,
    'Etimesgut İlçesi': 2217,
    'Evren': 2217,
    'Gölbaşı': 2217,
    'Güdül': 2217,
    'Haymana': 2217,
    'Kahramankazan': 2217,
    'Kalecik': 2217,
    'Kazan': 2217,
    'Keçiören': 2217,
    'Kızılcahamam': 2217,
    'Mamak İlçesi': 2217,
    'Nallıhan': 2217,
    'Polatlı': 2217,
    'Pursaklar': 2217,
    'Şereflikoçhisar': 2217,
    'Sincan İlçesi': 2217,
    'Yenimahalle': 2217,
    'Akseki': 2169,
    'Aksu': 2169,
    'Alanya': 2169,
    'Antalya': 2169,
    'Avsallar': 2169,
    'Belek': 2169,
    'Beykonak': 2169,
    'Boğazkent': 2169,
    'Demre': 2169,
    'Döşemealtı İlçesi': 2169,
    'Eksere': 2169,
    'Elmalı': 2169,
    'Evrenseki': 2169,
    'Finike': 2169,
    'Gazipaşa': 2169,
    'Göynük': 2169,
    'Gündoğmuş İlçesi': 2169,
    'İbradı': 2169,
    'Kalkan': 2169,
    'Kaş': 2169,
    'Kemer': 2169,
    'Kepez İlçesi': 2169,
    'Kızılağaç': 2169,
    'Konyaaltı': 2169,
    'Korkuteli': 2169,
    'Kumköy': 2169,
    'Kumluca': 2169,
    'Mahmutlar': 2169,
    'Manavgat': 2169,
    'Muratpaşa': 2169,
    'Okurcalar': 2169,
    'Payallar': 2169,
    'Serik': 2169,
    'Side': 2169,
    'Tekirova': 2169,
    'Türkler': 2169,
    'Turunçova': 2169,
    'Ardahan': 2185,
    'Çıldır İlçesi': 2185,
    'Damal': 2185,
    'Göle': 2185,
    'Hanak': 2185,
    'Posof': 2185,
    'Ardanuç': 2191,
    'Arhavi': 2191,
    'Artvin': 2191,
    'Borçka': 2191,
    'Hopa': 2191,
    'Kemalpaşa': 2191,
    'Murgul': 2191,
    'Şavşat': 2191,
    'Seyitler': 2191,
    'Yusufeli': 2191,
    'Acarlar': 2187,
    'Atça': 2187,
    'Aydın': 2187,
    'Bozdoğan': 2187,
    'Buharkent': 2187,
    'Çine': 2187,
    'Dalama': 2187,
    'Davutlar': 2187,
    'Didim': 2187,
    'Efeler': 2187,
    'Germencik': 2187,
    'İncirliova': 2187,
    'İsabeyli': 2187,
    'Karacasu': 2187,
    'Karpuzlu': 2187,
    'Koçarlı': 2187,
    'Köşk': 2187,
    'Kuşadası': 2187,
    'Kuyucak': 2187,
    'Nazilli': 2187,
    'Sarıkemer': 2187,
    'Söke': 2187,
    'Sultanhisar': 2187,
    'Tepecik': 2187,
    'Yenipazar': 2187,
    'Altıeylül İlçesi': 2175,
    'Altınoluk': 2175,
    'Ayvalık': 2175,
    'Balıkesir': 2175,
    'Balya': 2175,
    'Bandırma': 2175,
    'Bigadiç': 2175,
    'Burhaniye': 2175,
    'Dursunbey': 2175,
    'Edremit': 2175,
    'Erdek': 2175,
    'Gömeç': 2175,
    'Gönen': 2175,
    'Havran': 2175,
    'İvrindi': 2175,
    'Kepsut': 2175,
    'Manyas': 2175,
    'Marmara': 2175,
    'Savaştepe': 2175,
    'Sındırgı': 2175,
    'Susurluk': 2175,
    'Amasra': 2148,
    'Bartın': 2148,
    'Kurucaşile': 2148,
    'Ulus İlçesi': 2148,
    'Aviski': 2194,
    'Aydınkonak': 2194,
    'Balpınar': 2194,
    'Batman': 2194,
    'Beşiri': 2194,
    'Beşpınar': 2194,
    'Binatlı': 2194,
    'Çevrimova': 2194,
    'Demiryol': 2194,
    'Doğankavak': 2194,
    'Erköklü': 2194,
    'Gercüş İlçesi': 2194,
    'Gerdzhyush': 2194,
    'Hasankeyf': 2194,
    'Hisar': 2194,
    'Kayapınar': 2194,
    'Kozluk İlçesi': 2194,
    'Merkez': 2194,
    'Oymataş': 2194,
    'Sason': 2194,
    'Yeniköy': 2194,
    'Yenipınar': 2194,
    'Yolağzı': 2194,
    'Aydıntepe': 2177,
    'Bayburt': 2177,
    'Demirözü İlçesi': 2177,
    'Pulur': 2177,
    'Bilecik': 2221,
    'Bozüyük': 2221,
    'Dodurga': 2221,
    'Gölpazarı': 2221,
    'İnhisar': 2221,
    'Küplü': 2221,
    'Osmaneli': 2221,
    'Pazaryeri': 2221,
    'Söğüt İlçesi': 2221,
    'Vezirhan': 2221,
    'Yenipazar': 2221,
    'Adaklı İlçesi': 2153,
    'Bingöl': 2153,
    'Genç': 2153,
    'Karlıova': 2153,
    'Kiğı İlçesi': 2153,
    'Merkez': 2153,
    'Solhan': 2153,
    'Yayladere': 2153,
    'Yedisu': 2153,
    'Adilcevaz': 2215,
    'Ahlat': 2215,
    'Beğendik': 2215,
    'Bitlis': 2215,
    'Güroymak': 2215,
    'Hizan': 2215,
    'Merkez': 2215,
    'Mutki': 2215,
    'Tatvan': 2215,
    'Bolu': 2172,
    'Dörtdivan': 2172,
    'Gerede': 2172,
    'Göynük': 2172,
    'Kıbrıscık': 2172,
    'Mengen': 2172,
    'Mudurnu': 2172,
    'Seben': 2172,
    'Yeniçağa': 2172,
    'Ağlasun': 2209,
    'Altınyayla': 2209,
    'Bucak': 2209,
    'Burdur': 2209,
    'Çavdır İlçesi': 2209,
    'Çeltikçi İlçesi': 2209,
    'Gölhisar': 2209,
    'Karamanlı': 2209,
    'Kemer': 2209,
    'Kızılkaya': 2209,
    'Kocaaliler': 2209,
    'Merkez': 2209,
    'Tefenni': 2209,
    'Yeşilova': 2209,
    'Alanyurt': 2163,
    'Balarim': 2163,
    'Boyalıca': 2163,
    'Bursa': 2163,
    'Büyükorhan İlçesi': 2163,
    'Çakırca': 2163,
    'Çakırlı': 2163,
    'Cerrah': 2163,
    'Demirtaş': 2163,
    'Elbeyli': 2163,
    'Gemlik': 2163,
    'Gürsu': 2163,
    'Hamzabey': 2163,
    'Harmancık İlçesi': 2163,
    'İnegol': 2163,
    'İznik': 2163,
    'Karacabey': 2163,
    'Karıncalı': 2163,
    'Keles': 2163,
    'Kestel': 2163,
    'Kirazlı': 2163,
    'Küçükkumla': 2163,
    'Kurşunlu': 2163,
    'Mudanya': 2163,
    'Mustafakemalpaşa': 2163,
    'Narlıca': 2163,
    'Nilüfer': 2163,
    'Orhaneli': 2163,
    'Orhangazi': 2163,
    'Osmangazi': 2163,
    'Sölöz': 2163,
    'Tacir': 2163,
    'Tahtaköprü': 2163,
    'Tatkavaklı': 2163,
    'Umurbey': 2163,
    'Yenice': 2163,
    'Yeniköy': 2163,
    'Yenişehir': 2163,
    'Yıldırım İlçesi': 2163,
    'Ayvacık': 2216,
    'Bayramiç İlçesi': 2216,
    'Behram': 2216,
    'Biga': 2216,
    'Bozcaada': 2216,
    'Çan': 2216,
    'Çanakkale': 2216,
    'Eceabat': 2216,
    'Ezine': 2216,
    'Gelibolu': 2216,
    'Geyikli': 2216,
    'Gökçeada': 2216,
    'Hamdibey': 2216,
    'Kalkım': 2216,
    'Lapseki': 2216,
    'Lâpseki İlçesi': 2216,
    'Merkez': 2216,
    'Yenice': 2216,
    'Atkaracalar': 2168,
    'Bayramören': 2168,
    'Çerkeş': 2168,
    'Eldivan': 2168,
    'Ilgaz': 2168,
    'Khanjarah': 2168,
    'Kızılırmak': 2168,
    'Korgun': 2168,
    'Kurşunlu': 2168,
    'Orta': 2168,
    'Şabanözü': 2168,
    'Yapraklı': 2168,
    'Alaca': 2173,
    'Bayat': 2173,
    'Boğazkale İlçesi': 2173,
    'Çorum': 2173,
    'Dodurga': 2173,
    'İskilip': 2173,
    'Kargı İlçesi': 2173,
    'Laçin': 2173,
    'Mecitözü': 2173,
    'Merkez': 2173,
    'Oğuzlar İlçesi': 2173,
    'Ortaköy': 2173,
    'Osmancık': 2173,
    'Sungurlu': 2173,
    'Uğurludağ': 2173,
    'Acıpayam': 2157,
    'Akkent': 2157,
    'Akköy': 2157,
    'Babadağ': 2157,
    'Baklan': 2157,
    'Bekilli': 2157,
    'Beyağaç İlçesi': 2157,
    'Bozkurt': 2157,
    'Buldan': 2157,
    'Çal İlçesi': 2157,
    'Çameli İlçesi': 2157,
    'Çardak': 2157,
    'Çivril': 2157,
    'Denizli': 2157,
    'Gözler': 2157,
    'Güney İlçesi': 2157,
    'Honaz': 2157,
    'Kale': 2157,
    'Merkezefendi': 2157,
    'Nikfer': 2157,
    'Pamukkale': 2157,
    'Sarayköy': 2157,
    'Serinhisar': 2157,
    'Tavas': 2157,
    'Alacakaya İlçesi': 2226,
    'Altınkum': 2226,
    'Ambar': 2226,
    'Aralık': 2226,
    'Bağlar': 2226,
    'Bismil': 2226,
    'Çermik': 2226,
    'Çınar İlçesi': 2226,
    'Çüngüş': 2226,
    'Dicle': 2226,
    'Diyarbakır': 2226,
    'Eğil': 2226,
    'Ergani': 2226,
    'Hani': 2226,
    'Hazro': 2226,
    'Kayapınar': 2226,
    'Kazancı': 2226,
    'Kerh': 2226,
    'Kocaköy': 2226,
    'Köseli': 2226,
    'Kulp': 2226,
    'Lice': 2226,
    'Salat': 2226,
    'Silvan': 2226,
    'Sinanköy': 2226,
    'Sur': 2226,
    'Yaprakbaşı': 2226,
    'Yenişehir': 2226,
    'Akçakoca': 2202,
    'Çilimli İlçesi': 2202,
    'Cumayeri İlçesi': 2202,
    'Düzce': 2202,
    'Gölyaka İlçesi': 2202,
    'Gümüşova İlçesi': 2202,
    'Kaynaşlı İlçesi': 2202,
    'Yığılca İlçesi': 2202,
    'Edirne': 2151,
    'Enez': 2151,
    'Havsa': 2151,
    'İpsala': 2151,
    'Keşan': 2151,
    'Küplü': 2151,
    'Lâlapaşa': 2151,
    'Meriç': 2151,
    'Süloğlu İlçesi': 2151,
    'Uzunköprü': 2151,
    'Ağın': 2159,
    'Arıcak': 2159,
    'Baskil': 2159,
    'Elazığ': 2159,
    'Karakoçan': 2159,
    'Karakoçan / Elazığ': 2159,
    'Keban': 2159,
    'Kovancılar': 2159,
    'Maden İlçesi': 2159,
    'Merkez': 2159,
    'Palu': 2159,
    'Sivrice': 2159,
    'Turluk': 2159,
    'Çayırlı İlçesi': 2160,
    'Cimin': 2160,
    'Erzincan': 2160,
    'İliç İlçesi': 2160,
    'Kemah': 2160,
    'Kemaliye': 2160,
    'Otlukbeli': 2160,
    'Refahiye': 2160,
    'Tercan': 2160,
    'Üzümlü İlçesi': 2160,
    'Aşkale': 2165,
    'Aziziye': 2165,
    'Çat İlçesi': 2165,
    'Erzurum': 2165,
    'Hınıs': 2165,
    'Horasan': 2165,
    'Ilıca': 2165,
    'İspir': 2165,
    'Karaçoban': 2165,
    'Karayazı': 2165,
    'Köprüköy İlçesi': 2165,
    'Narman': 2165,
    'Oltu': 2165,
    'Olur': 2165,
    'Palandöken': 2165,
    'Pasinler': 2165,
    'Pazaryolu': 2165,
    'Şenkaya': 2165,
    'Tekman': 2165,
    'Tortum': 2165,
    'Uzundere': 2165,
    'Yakutiye': 2165,
    'Alpu': 2164,
    'Beylikova': 2164,
    'Bozan': 2164,
    'Çifteler': 2164,
    'Eskişehir': 2164,
    'Günyüzü İlçesi': 2164,
    'Han': 2164,
    'İnönü': 2164,
    'Kırka': 2164,
    'Mahmudiye İlçesi': 2164,
    'Mihalgazi': 2164,
    'Mihalıçcık İlçesi': 2164,
    'Odunpazarı': 2164,
    'Sarıcakaya İlçesi': 2164,
    'Sevinç': 2164,
    'Seyitgazi': 2164,
    'Sivrihisar': 2164,
    'Tepebaşı': 2164,
    'Araban': 2203,
    'Doğanpınar': 2203,
    'Gaziantep': 2203,
    'İkizce': 2203,
    'İslahiye İlçesi': 2203,
    'Karkamış': 2203,
    'Nizip': 2203,
    'Nurdağı': 2203,
    'Oğuzeli': 2203,
    'Şahinbey İlçesi': 2203,
    'Şehitkamil': 2203,
    'Sekili': 2203,
    'Uluyatır': 2203,
    'Yavuzeli': 2203,
    'Yeşildere': 2203,
    'Alucra İlçesi': 2186,
    'Bulancak': 2186,
    'Çamoluk İlçesi': 2186,
    'Çanakçı': 2186,
    'Dereli': 2186,
    'Doğankent': 2186,
    'Espiye': 2186,
    'Eynesil': 2186,
    'Giresun': 2186,
    'Görele': 2186,
    'Güce İlçesi': 2186,
    'Keşap': 2186,
    'Merkez': 2186,
    'Piraziz': 2186,
    'Şebin Karahisar': 2186,
    'Tirebolu': 2186,
    'Yağlıdere İlçesi': 2186,
    'Evren': 2204,
    'Gumushkhane': 2204,
    'Kelkit': 2204,
    'Köse': 2204,
    'Kürtün': 2204,
    'Merkez': 2204,
    'Şiran': 2204,
    'Torul': 2204,
    'Yağlıdere': 2204,
    'Çukurca': 2190,
    'Hakkâri': 2190,
    'Karsani': 2190,
    'Şemdinli': 2190,
    'Şemdinni İlçesi': 2190,
    'Yüksekova': 2190,
    'Açıkdere': 2211,
    'Altınözü': 2211,
    'Anayazı': 2211,
    'Antakya': 2211,
    'Arsuz': 2211,
    'Aşağı Karafakılı': 2211,
    'Aşağıokçular': 2211,
    'Balıklıdere': 2211,
    'Belen': 2211,
    'Boynuyoğun': 2211,
    'Büyük Dalyan': 2211,
    'Büyükçat': 2211,
    'Çırtıman': 2211,
    'Defne': 2211,
    'Denizciler': 2211,
    'Dörtyol': 2211,
    'Dursunlu': 2211,
    'Erzin': 2211,
    'Gümüşgöze': 2211,
    'Günyazı': 2211,
    'Hacıpaşa': 2211,
    'Hassa': 2211,
    'İskenderun': 2211,
    'Karasüleymanlı': 2211,
    'Kastal': 2211,
    'Kırıkhan': 2211,
    'Kışlak': 2211,
    'Kızkalesi': 2211,
    'Kumlu İlçesi': 2211,
    'Kuzeytepe': 2211,
    'Mahmutlar': 2211,
    'Payas': 2211,
    'Reyhanlı': 2211,
    'Samankaya': 2211,
    'Serinyol': 2211,
    'Tepehan': 2211,
    'Toygarlı': 2211,
    'Uzunbağ': 2211,
    'Uzunkavak': 2211,
    'Yayladağı': 2211,
    'Iğdır': 2166,
    'Karakoyunlu': 2166,
    'Tuzluca İlçesi': 2166,
    'Aksu': 2222,
    'Anamas': 2222,
    'Atabey': 2222,
    'Eğirdir': 2222,
    'Gelendost': 2222,
    'Gönen': 2222,
    'Isparta': 2222,
    'Keçiborlu': 2222,
    'Pavlu Cebel': 2222,
    'Şarkîkaraağaç': 2222,
    'Senirkent': 2222,
    'Sütçüler İlçesi': 2222,
    'Uluborlu': 2222,
    'Yalvaç': 2222,
    'Yenişarbademli': 2222,
    'Adalan': 2170,
    'Adalar': 2170,
    'Ağva': 2170,
    'Arıköy': 2170,
    'Arnavutköy': 2170,
    'Ataşehir': 2170,
    'Avcılar': 2170,
    'Bağcılar': 2170,
    'Bahçelievler': 2170,
    'Bakırköy': 2170,
    'Başakşehir': 2170,
    'Bayrampaşa': 2170,
    'Beşiktaş': 2170,
    'Beykoz': 2170,
    'Beylikdüzü': 2170,
    'Beyoğlu': 2170,
    'Boyalık': 2170,
    'Büyükçavuşlu': 2170,
    'Büyükçekmece': 2170,
    'Çanta': 2170,
    'Çatalca': 2170,
    'Çekmeköy': 2170,
    'Celâliye': 2170,
    'Durusu': 2170,
    'Eminönü': 2170,
    'Esenler': 2170,
    'Esenyurt': 2170,
    'Eyüpsultan': 2170,
    'Fatih': 2170,
    'Gaziosmanpaşa': 2170,
    'Güngören': 2170,
    'güngören merter': 2170,
    'Gürpınar': 2170,
    'İçmeler': 2170,
    'Istanbul': 2170,
    'Kadıköy': 2170,
    'Kâğıthane': 2170,
    'Karacaköy': 2170,
    'Kartal': 2170,
    'Kavaklı': 2170,
    'Kınalı': 2170,
    'Küçükçekmece': 2170,
    'Kumburgaz': 2170,
    'Maltepe': 2170,
    'Merter Keresteciler': 2170,
    'Mimarsinan': 2170,
    'Muratbey': 2170,
    'Ortaköy': 2170,
    'Pendik': 2170,
    'Sancaktepe': 2170,
    'Sarıyer': 2170,
    'Selimpaşa': 2170,
    'Şile': 2170,
    'Silivri': 2170,
    'Şişli': 2170,
    'Sultanbeyli': 2170,
    'Sultangazi': 2170,
    'Tepecik': 2170,
    'Tuzla': 2170,
    'Umraniye': 2170,
    'Üsküdar': 2170,
    'Yakuplu': 2170,
    'Zeytinburnu': 2170,
    'Alaçatı': 2205,
    'Aliağa': 2205,
    'Bademli': 2205,
    'Balçova': 2205,
    'Bayındır': 2205,
    'Bayraklı': 2205,
    'Belevi': 2205,
    'Bergama': 2205,
    'Beydağ': 2205,
    'Bornova': 2205,
    'Buca': 2205,
    'Çandarlı': 2205,
    'Çaylı': 2205,
    'Çeşme': 2205,
    'Çiğli': 2205,
    'Dikili': 2205,
    'Foça': 2205,
    'Gaziemir': 2205,
    'Gökçen': 2205,
    'Güzelbahçe': 2205,
    'İzmir': 2205,
    'Karabağlar': 2205,
    'Karaburun': 2205,
    'Karşıyaka İlçesi': 2205,
    'Kaymakçı': 2205,
    'Kemalpaşa': 2205,
    'Kiraz': 2205,
    'Kınık': 2205,
    'Konak': 2205,
    'Konaklı': 2205,
    'Menderes': 2205,
    'Menemen': 2205,
    'Narlıdere': 2205,
    'Ödemiş': 2205,
    'Özdere': 2205,
    'Seferihisar': 2205,
    'Selçuk': 2205,
    'Tire': 2205,
    'Torbalı': 2205,
    'Urla': 2205,
    'Yenifoça': 2205,
    'Yenişakran': 2205,
    'Zeytindağ': 2205,
    'Afşin': 2227,
    'Andırın': 2227,
    'Çağlayancerit': 2227,
    'Celeyke': 2227,
    'Dulkadiroğlu': 2227,
    'Ekinözü İlçesi': 2227,
    'Elbistan': 2227,
    'Göksun': 2227,
    'Kahramanmaraş': 2227,
    'Nurhak': 2227,
    'Onikişubat': 2227,
    'Pazarcık': 2227,
    'Süleymanlı': 2227,
    'Türkoğlu İlçesi': 2227,
    'Eflani': 2223,
    'Eskipazar İlçesi': 2223,
    'Gözyeri': 2223,
    'Karabük': 2223,
    'Ovacık İlçesi': 2223,
    'Safranbolu': 2223,
    'Yenice': 2223,
    'Ayrancı İlçesi': 2184,
    'Başyayla': 2184,
    'Ermenek': 2184,
    'İnönü': 2184,
    'Karaman': 2184,
    'Kazımkarabekir': 2184,
    'Sarıveliler': 2184,
    'Akyaka': 2208,
    'Arpaçay': 2208,
    'Digor İlçesi': 2208,
    'Kağızman': 2208,
    'Kars': 2208,
    'Sarıkamış': 2208,
    'Selim': 2208,
    'Susuz': 2208,
    'Abana': 2197,
    'Ağlı İlçesi': 2197,
    'Araç': 2197,
    'Azdavay': 2197,
    'Bozkurt': 2197,
    'Çatalzeytin İlçesi': 2197,
    'Cide': 2197,
    'Daday': 2197,
    'Devrekani': 2197,
    'Doğanyurt': 2197,
    'Hanönü': 2197,
    'İhsangazi': 2197,
    'İnebolu': 2197,
    'Kastamonu': 2197,
    'Küre': 2197,
    'Pınarbaşı': 2197,
    'Şenpazar İlçesi': 2197,
    'Seydiler': 2197,
    'Taşköprü': 2197,
    'Tosya': 2197,
    'Akkışla': 2200,
    'Bünyan': 2200,
    'Develi': 2200,
    'Felahiye': 2200,
    'Hacılar': 2200,
    'Incesu': 2200,
    'Kayseri': 2200,
    'Kocasinan': 2200,
    'Melikgazi': 2200,
    'Özvatan İlçesi': 2200,
    'Pınarbaşı': 2200,
    'Sarıoğlan': 2200,
    'Sarız': 2200,
    'Talas': 2200,
    'Tomarza': 2200,
    'Yahyali': 2200,
    'Yahyalı': 2200,
    'Yesilhisar': 2200,
    'Elbeyli': 2154,
    'Kilis': 2154,
    'Musabeyli': 2154,
    'Polateli İlçesi': 2154,
    'Bahşılı İlçesi': 2178,
    'Balışeyh': 2178,
    'Çelebi İlçesi': 2178,
    'Delice': 2178,
    'Karakeçili': 2178,
    'Keskin': 2178,
    'Kırıkkale': 2178,
    'Sulakyurt': 2178,
    'Yahşihan İlçesi': 2178,
    'Babaeski': 2176,
    'Demirköy İlçesi': 2176,
    'Kırklareli': 2176,
    'Kofçaz': 2176,
    'Lüleburgaz İlçesi': 2176,
    'Pehlivanköy': 2176,
    'Pınarhisar': 2176,
    'Üsküp': 2176,
    'Vize': 2176,
    'Akçakent İlçesi': 2180,
    'Akpınar': 2180,
    'Boztepe İlçesi': 2180,
    'Çiçekdağı': 2180,
    'Kaman': 2180,
    'Kırşehir': 2180,
    'Mucur': 2180,
    'Bahçecik': 2195,
    'Balçık': 2195,
    'Başiskele': 2195,
    'Çayırova': 2195,
    'Darıca': 2195,
    'Derince': 2195,
    'Dilovası': 2195,
    'Gebze': 2195,
    'Gölcük': 2195,
    'Halıdere': 2195,
    'İhsaniye': 2195,
    'İzmit': 2195,
    'Kandıra': 2195,
    'Karamürsel': 2195,
    'Karşıyaka': 2195,
    'Kartepe': 2195,
    'Kefken': 2195,
    'Körfez': 2195,
    'Köseköy': 2195,
    'Kullar': 2195,
    'Tavşancıl': 2195,
    'Tavşanlı': 2195,
    'Ulaşlı': 2195,
    'Yalakdere': 2195,
    'Yuvacık': 2195,
    'Ahırlı İlçesi': 2171,
    'Akören İlçesi': 2171,
    'Akşehir': 2171,
    'Altınekin': 2171,
    'Beyşehir': 2171,
    'Bozkır': 2171,
    'Çatalhöyük': 2171,
    'Çeltik İlçesi': 2171,
    'Cihanbeyli': 2171,
    'Cihanbeyli District': 2171,
    'Çumra': 2171,
    'Derbent': 2171,
    'Derebucak': 2171,
    'Doğanhisar': 2171,
    'Emirgazi': 2171,
    'Ereğli': 2171,
    'Güneysınır İlçesi': 2171,
    'Hadim': 2171,
    'Halkapınar': 2171,
    'Hüyük': 2171,
    'Ilgın': 2171,
    'Kadınhanı': 2171,
    'Karapınar': 2171,
    'Karatay': 2171,
    'Konya': 2171,
    'Kulu': 2171,
    'Kuyulusebil': 2171,
    'Meram İlçesi': 2171,
    'Ovakavağı': 2171,
    'Sarayönü': 2171,
    'Selcuklu': 2171,
    'Seydişehir': 2171,
    'Taşkent': 2171,
    'Tuzlukçu': 2171,
    'Yalıhüyük': 2171,
    'Yarma': 2171,
    'Yeniceoba': 2171,
    'Yunak': 2171,
    'Altıntaş İlçesi': 2149,
    'Aslanapa': 2149,
    'Çavdarhisar İlçesi': 2149,
    'Domaniç': 2149,
    'Dumlupınar': 2149,
    'Emet': 2149,
    'Gediz': 2149,
    'Hisarcık İlçesi': 2149,
    'Kütahya': 2149,
    'Pazarlar': 2149,
    'Şaphane': 2149,
    'Simav': 2149,
    'Tavşanlı': 2149,
    'Akçadağ': 2158,
    'Arapgir': 2158,
    'Arguvan': 2158,
    'Battalgazi': 2158,
    'Darende': 2158,
    'Doğanşehir': 2158,
    'Doğanyol': 2158,
    'Hekimhan': 2158,
    'Kale': 2158,
    'Kuluncak': 2158,
    'Malatya': 2158,
    'Pütürge': 2158,
    'Yazıhan': 2158,
    'Yeşilyurt': 2158,
    'Ahmetli': 2198,
    'Akhisar': 2198,
    'Alaşehir': 2198,
    'Demirci': 2198,
    'Gölmarmara': 2198,
    'Gördes': 2198,
    'Kırkağaç': 2198,
    'Köprübaşı': 2198,
    'Kula': 2198,
    'Manisa': 2198,
    'Salihli İlçesi': 2198,
    'Sarıgöl': 2198,
    'Saruhanlı': 2198,
    'Şehzadeler': 2198,
    'Selendi': 2198,
    'Soma': 2198,
    'Turgutlu İlçesi': 2198,
    'Yunusemre': 2198,
    'Zeytinliova': 2198,
    'Acırlı': 2224,
    'Akarsu': 2224,
    'Alakamış': 2224,
    'Aran': 2224,
    'Artuklu': 2224,
    'Avine': 2224,
    'Bağlıca': 2224,
    'Barıştepe': 2224,
    'Başkavak': 2224,
    'Çalpınar': 2224,
    'Çavuşlu': 2224,
    'Cinatamiho': 2224,
    'Çınaraltı': 2224,
    'Çıplak': 2224,
    'Dara': 2224,
    'Dargeçit': 2224,
    'Dereyanı': 2224,
    'Derik İlçesi': 2224,
    'Duruca': 2224,
    'Ebish': 2224,
    'Eşme': 2224,
    'Eymirli': 2224,
    'Gelinkaya': 2224,
    'Gülveren': 2224,
    'Gyundyukoru': 2224,
    'Haberli': 2224,
    'Kabala': 2224,
    'Karalar': 2224,
    'Kavsan': 2224,
    'Kayalıpınar': 2224,
    'Kaynakkaya': 2224,
    'Kindirip': 2224,
    'Kılavuz': 2224,
    'Kızıltepe': 2224,
    'Kumlu': 2224,
    'Kutlubey': 2224,
    'Mardin': 2224,
    'Mardin Merkez': 2224,
    'Mazıdağı': 2224,
    'Midyat': 2224,
    'Nusaybin': 2224,
    'Ömerli': 2224,
    'Ortaca': 2224,
    'Ortaköy': 2224,
    'Oyalı': 2224,
    'Özbek': 2224,
    'Pınardere': 2224,
    'Reshidi': 2224,
    'Savur': 2224,
    'Selah': 2224,
    'Şenocak': 2224,
    'Şenyurt': 2224,
    'Seri': 2224,
    'Serkan': 2224,
    'Sivrice': 2224,
    'Teffi': 2224,
    'Telminar': 2224,
    'Tepealtı': 2224,
    'Toptepe': 2224,
    'Yaylı': 2224,
    'Yayvantepe': 2224,
    'Yeşilalan': 2224,
    'Yeşilli': 2224,
    'Yolbaşı': 2224,
    'Akdeniz': 2156,
    'Akdere': 2156,
    'Anamur': 2156,
    'Aydıncık': 2156,
    'Bozyazı': 2156,
    'Çamlıyayla İlçesi': 2156,
    'Elvanlı': 2156,
    'Erdemli': 2156,
    'Gülnar': 2156,
    'Kocahasanlı': 2156,
    'Mersin': 2156,
    'Mezitli İlçesi': 2156,
    'Mut': 2156,
    'Pınarbaşı': 2156,
    'Silifke': 2156,
    'Tarsus': 2156,
    'Toroslar': 2156,
    'Yenişehir': 2156,
    'Bayır': 2182,
    'Bodrum': 2182,
    'Bozarmut': 2182,
    'Dalaman': 2182,
    'Dalyan': 2182,
    'Datça': 2182,
    'Fethiye': 2182,
    'Göcek': 2182,
    'Güvercinlik': 2182,
    'Kargı': 2182,
    'Kavaklıdere': 2182,
    'Köyceğiz İlçesi': 2182,
    'Marmaris': 2182,
    'Menteşe': 2182,
    'Milas': 2182,
    'Muğla': 2182,
    'Ölüdeniz': 2182,
    'Ortaca': 2182,
    'Sarigerme': 2182,
    'Seydikemer': 2182,
    'Turgut': 2182,
    'Turgutreis': 2182,
    'Ula': 2182,
    'Yalıkavak': 2182,
    'Yaniklar': 2182,
    'Yatağan': 2182,
    'Bulanık': 2162,
    'Hasköy': 2162,
    'Korkut': 2162,
    'Malazgirt': 2162,
    'Merkez': 2162,
    'Muş': 2162,
    'Varto': 2162,
    'Acıgöl': 2196,
    'Avanos': 2196,
    'Derinkuyu': 2196,
    'Göreme': 2196,
    'Gülşehir': 2196,
    'Hacıbektaş': 2196,
    'Kozaklı İlçesi': 2196,
    'Merkez': 2196,
    'Nevşehir': 2196,
    'Ürgüp': 2196,
    'Altunhisar': 2189,
    'Bor': 2189,
    'Çamardı İlçesi': 2189,
    'Çiftlik': 2189,
    'Niğde': 2189,
    'Ulukisla': 2189,
    'Akkuş': 2174,
    'Altınordu': 2174,
    'Aybastı İlçesi': 2174,
    'Çamaş': 2174,
    'Çatalpınar İlçesi': 2174,
    'Çaybaşı İlçesi': 2174,
    'Fatsa': 2174,
    'Gölköy İlçesi': 2174,
    'Gülyalı İlçesi': 2174,
    'Gürgentepe': 2174,
    'İkizce': 2174,
    'Kabadüz': 2174,
    'Kabataş': 2174,
    'Korgan': 2174,
    'Kumru': 2174,
    'Mesudiye': 2174,
    'Ordu': 2174,
    'Perşembe': 2174,
    'Piraziz İlçesi': 2174,
    'Ulubey': 2174,
    'Ünye İlçesi': 2174,
    'Bahçe İlçesi': 2214,
    'Düziçi İlçesi': 2214,
    'Hasanbeyli': 2214,
    'Kadirli': 2214,
    'Osmaniye': 2214,
    'Sumbas İlçesi': 2214,
    'Toprakkale': 2214,
    'Ardeşen': 2219,
    'Çayeli': 2219,
    'Fındıklı': 2219,
    'Güneysu': 2219,
    'Hemşin İlçesi': 2219,
    'İkizdere İlçesi': 2219,
    'İyidere': 2219,
    'Kalkandere': 2219,
    'Nurluca': 2219,
    'Pazar': 2219,
    'Rize': 2219,
    'Adapazarı': 2150,
    'Akyazı': 2150,
    'Arifiye': 2150,
    'Erenler': 2150,
    'Ferizli': 2150,
    'Geyve': 2150,
    'Hendek': 2150,
    'Karapürçek': 2150,
    'Karasu İlçesi': 2150,
    'Karasu Mahallesi': 2150,
    'Kaynarca': 2150,
    'Kocaali': 2150,
    'Pamukova': 2150,
    'Sapanca': 2150,
    'Serdivan': 2150,
    'Söğütlü': 2150,
    'Taraklı': 2150,
    'Alaçam İlçesi': 2220,
    'Asarcık': 2220,
    'Atakum': 2220,
    'Ayvacık': 2220,
    'Bafra': 2220,
    'Canik': 2220,
    'Çarşamba': 2220,
    'Havza': 2220,
    'İlkadım': 2220,
    'Kavak': 2220,
    'Ladik': 2220,
    'Ondokuzmayıs İlçesi': 2220,
    'Salıpazarı İlçesi': 2220,
    'Samsun': 2220,
    'Tekkeköy': 2220,
    'Terme': 2220,
    'Vezirköprü': 2220,
    'Yakakent': 2220,
    'Akçakale': 2183,
    'Akdiken': 2183,
    'Aşağı Beğdeş': 2183,
    'Birecik': 2183,
    'Bozova': 2183,
    'Bulutlu': 2183,
    'Ceylanpınar': 2183,
    'Dorumali': 2183,
    'Eyyübiye': 2183,
    'Güneren': 2183,
    'Halfeti': 2183,
    'Haliliye': 2183,
    'Hanköy': 2183,
    'Harran': 2183,
    'Hilvan': 2183,
    'Işıklar': 2183,
    'Karaköprü': 2183,
    'Koyunluca': 2183,
    'Küçükkendirci': 2183,
    'Mağaralı': 2183,
    'Meydankapı': 2183,
    'Minare': 2183,
    'Muratlı': 2183,
    'Öncül': 2183,
    'Pekmezli': 2183,
    'Şanlıurfa': 2183,
    'Seksenören': 2183,
    'Siverek': 2183,
    'Suruç': 2183,
    'Viranşehir': 2183,
    'Yukarı Taşyalak': 2183,
    'Baykan': 2207,
    'Civankan': 2207,
    'Doğanca': 2207,
    'Doğanköy': 2207,
    'Eruh': 2207,
    'Gökbudak': 2207,
    'Gökçekoru': 2207,
    'Gölgelikonak': 2207,
    'Gözpınar': 2207,
    'Güleçler': 2207,
    'Kayabağlar': 2207,
    'Kurtalan': 2207,
    'Lodi': 2207,
    'Okçular': 2207,
    'Palamutlu': 2207,
    'Pervari': 2207,
    'Serhatta': 2207,
    'Siirt': 2207,
    'Şirvan': 2207,
    'Taliban': 2207,
    'Taşlı': 2207,
    'Tillo': 2207,
    'Boyabat': 4854,
    'Dikmen': 4854,
    'Durağan': 4854,
    'Erfelek': 4854,
    'Gerze': 4854,
    'Merkez': 4854,
    'Saraydüzü': 4854,
    'Sinop': 4854,
    'Türkeli': 4854,
    'Akıncılar': 2181,
    'Aksu': 2181,
    'Altınyayla': 2181,
    'Divriği': 2181,
    'Doğanşar': 2181,
    'Gemerek': 2181,
    'Gölova': 2181,
    'Gürün': 2181,
    'Hafik': 2181,
    'İmranlı': 2181,
    'Kangal': 2181,
    'Koyulhisar': 2181,
    'Merkez': 2181,
    'Şarkışla': 2181,
    'Sivas': 2181,
    'Suşehri': 2181,
    'Ulaş': 2181,
    'Yıldızeli İlçesi': 2181,
    'Zara': 2181,
    'Andaç': 2225,
    'Bağlıca': 2225,
    'Ballı': 2225,
    'Balveren': 2225,
    'Baraniferho': 2225,
    'Başaran': 2225,
    'Becuh': 2225,
    'Beytüşşebap': 2225,
    'Bisbin': 2225,
    'Boğazören': 2225,
    'Bostancı': 2225,
    'Bozalan': 2225,
    'Çalışkan': 2225,
    'Çardaklı': 2225,
    'Cizre': 2225,
    'Dicle': 2225,
    'Doruklu': 2225,
    'Düzova': 2225,
    'Girikbedro': 2225,
    'Güçlükonak İlçesi': 2225,
    'Hilal': 2225,
    'İdil': 2225,
    'Kızılsu': 2225,
    'Kumçatı': 2225,
    'Mezraa': 2225,
    'Ortabağ': 2225,
    'Ortaköy': 2225,
    'Pınarbaşı': 2225,
    'Razvaliny Ayinvan': 2225,
    'Şenoba': 2225,
    'Silopi': 2225,
    'Şırnak': 2225,
    'Sulak': 2225,
    'Tililan': 2225,
    'Uludere': 2225,
    'Uzungeçit': 2225,
    'Yemişli': 2225,
    'Yeniköy': 2225,
    'Çerkezköy': 2167,
    'Çorlu': 2167,
    'Ergene': 2167,
    'Hayrabolu': 2167,
    'Kapaklı': 2167,
    'Kumbağ': 2167,
    'Malkara': 2167,
    'Marmara Ereğlisi': 2167,
    'Marmaracık': 2167,
    'Muratlı İlçesi': 2167,
    'Saray': 2167,
    'Şarköy İlçesi': 2167,
    'Süleymanpaşa': 2167,
    'Sultanköy': 2167,
    'Tekirdağ': 2167,
    'Velimeşe': 2167,
    'Almus': 2199,
    'Artova': 2199,
    'Başçiftlik': 2199,
    'Erbaa': 2199,
    'Niksar': 2199,
    'Pazar': 2199,
    'Reşadiye': 2199,
    'Sulusaray': 2199,
    'Tokat': 2199,
    'Turhal': 2199,
    'Yeşilyurt İlçesi': 2199,
    'Zile': 2199,
    'Akçaabat': 2206,
    'Araklı': 2206,
    'Arsin': 2206,
    'Beşikdüzü': 2206,
    'Çarşıbaşı': 2206,
    'Çaykara': 2206,
    'Dernekpazarı': 2206,
    'Düzköy': 2206,
    'Hayrat': 2206,
    'Köprübaşı': 2206,
    'Maçka': 2206,
    'Of': 2206,
    'Ortahisar': 2206,
    'Şalpazarı': 2206,
    'Sürmene': 2206,
    'Tonya': 2206,
    'Trabzon': 2206,
    'Vakfıkebir': 2206,
    'Yomra': 2206,
    'Çemişgezek İlçesi': 2192,
    'Hozat': 2192,
    'Mazgirt': 2192,
    'Merkez': 2192,
    'Nazimiye': 2192,
    'Nazımiye İlçesi': 2192,
    'Ovacık': 2192,
    'Pertek': 2192,
    'Pulumer': 2192,
    'Pülümür İlçesi': 2192,
    'Tunceli': 2192,
    'Banaz': 2201,
    'Bölme': 2201,
    'Eşme': 2201,
    'Güllü': 2201,
    'İlyaslı': 2201,
    'Karahallı': 2201,
    'Kızılcasöğüt': 2201,
    'Merkez': 2201,
    'Selçikler': 2201,
    'Sivaslı': 2201,
    'Ulubey': 2201,
    'Uşak': 2201,
    'Bahçesaray': 2152,
    'Başkale': 2152,
    'Çaldıran': 2152,
    'Çatak İlçesi': 2152,
    'Edremit': 2152,
    'Erciş': 2152,
    'Gevaş': 2152,
    'Gürpınar': 2152,
    'İpekyolu': 2152,
    'Konalga': 2152,
    'Muradiye': 2152,
    'Özalp': 2152,
    'Saray': 2152,
    'Tuşpa': 2152,
    'Van': 2152,
    'Altınova': 2218,
    'Armutlu İlçesi': 2218,
    'Çiftlikköy': 2218,
    'Çınarcık': 2218,
    'Kadıköy': 2218,
    'Kaytazdere': 2218,
    'Kılıç': 2218,
    'Kocadere': 2218,
    'Koruköy': 2218,
    'Taşköprü': 2218,
    'Termal İlçesi': 2218,
    'Yalova': 2218,
    'Akdağmadeni': 2188,
    'Aydıncık': 2188,
    'Boğazlıyan İlçesi': 2188,
    'Çandır İlçesi': 2188,
    'Çayıralan': 2188,
    'Çekerek': 2188,
    'Kadışehri': 2188,
    'Saraykent': 2188,
    'Sarıkaya': 2188,
    'Şefaatli İlçesi': 2188,
    'Şefaatlı': 2188,
    'Sorgun': 2188,
    'Yenifakılı': 2188,
    'Yerköy': 2188,
    'Yozgat': 2188,
    'Alaplı': 2213,
    'Çaycuma': 2213,
    'Devrek': 2213,
    'Ereğli': 2213,
    'Gökçebey İlçesi': 2213,
    'Kilimli': 2213,
    'Kozlu': 2213,
    'Zonguldak': 2213,
    'Abadan': 3374,
    'Annau': 3374,
    'Arçabil': 3374,
    'Baharly': 3374,
    'Kaka': 3374,
    'Tejen': 3374,
    'Ashgabat': 3371,
    'Balkanabat': 3372,
    'Bereket': 3372,
    'Gumdag': 3372,
    'Magtymguly': 3372,
    'Serdar': 3372,
    'Türkmenbaşy': 3372,
    'Akdepe': 3373,
    'Boldumsaz': 3373,
    'Daşoguz': 3373,
    'Köneürgench': 3373,
    'Tagta': 3373,
    'Yylanly': 3373,
    'Atamyrat': 3370,
    'Farap': 3370,
    'Gazojak': 3370,
    'Gowurdak': 3370,
    'Saýat': 3370,
    'Türkmenabat': 3370,
    'Bayramaly': 3369,
    'Mary': 3369,
    'Serhetabat': 3369,
    'Seydi': 3369,
    'Yolöten': 3369,
    'Alapi Village': 3951,
    'Fakaifou Village': 3951,
    'Funafuti': 3951,
    'Toga Village': 3947,
    'Kulia Village': 3946,
    'Niulakita': 3946,
    'Tanrake Village': 3948,
    'Savave Village': 3952,
    'Asau Village': 3950,
    'Bukomansimbi District': 396,
    'Buvuma District': 396,
    'Bweyogerere': 396,
    'Byakabanda': 396,
    'Entebbe': 396,
    'Gomba District': 396,
    'Kajansi': 396,
    'Kampala': 396,
    'Kampala District': 396,
    'Kanoni': 396,
    'Kayunga': 396,
    'Kiboga': 396,
    'Kireka': 396,
    'Kyotera': 396,
    'Lugazi': 396,
    'Luwero': 396,
    'Lyantonde': 396,
    'Masaka': 396,
    'Mityana': 396,
    'Mpigi': 396,
    'Mubende': 396,
    'Mubende District': 396,
    'Mukono': 396,
    'Nakasongola': 396,
    'Namasuba': 396,
    'Njeru': 396,
    'Sembabule': 396,
    'Wakiso': 396,
    'Wakiso District': 396,
    'Wobulenzi': 396,
    'Bugembe': 372,
    'Bugiri': 372,
    'Bukwa District': 372,
    'Bulambuli District': 372,
    'Busembatia': 372,
    'Busia': 372,
    'Buwenge': 372,
    'Iganga': 372,
    'Jinja': 372,
    'Kamuli': 372,
    'Kapchorwa': 372,
    'Kibuku District': 372,
    'Kumi': 372,
    'Mayuge': 372,
    'Mbale': 372,
    'Pallisa': 372,
    'Sironko': 372,
    'Soroti': 372,
    'Tororo': 372,
    'Adjumani': 332,
    'Amudat': 332,
    'Apac': 332,
    'Arua': 332,
    'Gulu': 332,
    'Kitgum': 332,
    'Kotido': 332,
    'Lira': 332,
    'Moroto': 332,
    'Moyo': 332,
    'Nebbi': 332,
    'Otuke District': 332,
    'Oyam District': 332,
    'Pader': 332,
    'Pader Palwo': 332,
    'Paidha': 332,
    'Yumbe': 332,
    'Bundibugyo': 370,
    'Bwizibwera': 370,
    'Fort Portal': 370,
    'Hoima': 370,
    'Ibanda': 370,
    'Ibanda District': 370,
    'Kabale': 370,
    'Kagadi': 370,
    'Kamwenge': 370,
    'Kanungu': 370,
    'Kasese': 370,
    'Kibale': 370,
    'Kigorobya': 370,
    'Kilembe': 370,
    'Kiruhura': 370,
    'Kisoro': 370,
    'Kyenjojo': 370,
    'Margherita': 370,
    'Masindi': 370,
    'Masindi Port': 370,
    'Mbarara': 370,
    'Muhororo': 370,
    'Ntungamo': 370,
    'Nyachera': 370,
    'Rukungiri': 370,
    'Abrikosovka': 4689,
    'Abrikosovo': 4689,
    'Aeroflotskiy': 4689,
    'Agrarnoye': 4689,
    'Akimovka': 4689,
    'Aleksandrovka': 4689,
    'Alekseyevka': 4689,
    'Alupka': 4689,
    'Alushta': 4689,
    'Amurskoye': 4689,
    'Armyansk': 4689,
    'Aromatnoye': 4689,
    'Azovskoye': 4689,
    'Baherove': 4689,
    'Bakhchysarai': 4689,
    'Bakhchysarai Raion': 4689,
    'Batal’noye': 4689,
    'Belinskoye': 4689,
    'Beloglinka': 4689,
    'Belogorskiy rayon': 4689,
    'Beregovoye': 4689,
    'Berezovka': 4689,
    'Bilohirsk': 4689,
    'Blizhneye': 4689,
    'Bogatoye': 4689,
    'Botanicheskoye': 4689,
    'Bratskoye': 4689,
    'Chapayevka': 4689,
    'Chaykino': 4689,
    'Chelyadinovo': 4689,
    'Chernomorskiy rayon': 4689,
    'Chernomorskoye': 4689,
    'Chernopolye': 4689,
    'Chernovo': 4689,
    'Chernozemnoye': 4689,
    'Chernyshevo': 4689,
    'Chervonoye': 4689,
    'Chisten’koye': 4689,
    'Chistopolye': 4689,
    'Chkalovo': 4689,
    'Dachnoye': 4689,
    'Dalekoye': 4689,
    'Dmitrovka': 4689,
    'Dneprovka': 4689,
    'Dobroye': 4689,
    'Dobrushino': 4689,
    'Dolinnoye': 4689,
    'Donskoye': 4689,
    'Drofino': 4689,
    'Dubki': 4689,
    'Dzhankoy': 4689,
    'Dzhankoyskiy rayon': 4689,
    'Feodosiya': 4689,
    'Filatovka': 4689,
    'Fontany': 4689,
    'Foros': 4689,
    'Frunze': 4689,
    'Gaspra': 4689,
    'Geroyskoye': 4689,
    'Glazovka': 4689,
    'Golubinka': 4689,
    'Gornostayevka': 4689,
    'Gorodskoy okrug Alushta': 4689,
    'Gorodskoy okrug Armyansk': 4689,
    'Gorodskoy okrug Dzhankoy': 4689,
    'Gorodskoy okrug Feodosiya': 4689,
    'Gorodskoy okrug Krasnoperekopsk': 4689,
    'Gorodskoy okrug Saki': 4689,
    'Gorodskoy okrug Simferopol': 4689,
    'Gorodskoy okrug Sudak': 4689,
    'Gorodskoy okrug Yalta': 4689,
    'Gorodskoy okrug Yevpatoriya': 4689,
    'Gresovskiy': 4689,
    'Grishino': 4689,
    'Grushevka': 4689,
    'Gurzuf': 4689,
    'Hvardiiske': 4689,
    'Ilychyovo': 4689,
    'Ilyichevo': 4689,
    'Ilyinka': 4689,
    'Ishun’': 4689,
    'Ivanovka': 4689,
    'Izobil’noye': 4689,
    'Izumrudnoye': 4689,
    'Izyumovka': 4689,
    'Kalinino': 4689,
    'Kalinovka': 4689,
    'Kamenolomnya': 4689,
    'Kashtanovoye': 4689,
    'Kashtany': 4689,
    'Kerch': 4689,
    'Kerchens  ka Mis  ka Rada': 4689,
    'Kholmovka': 4689,
    'Kirovo': 4689,
    'Kirovske Raion': 4689,
    'Kirovskoye': 4689,
    'Klepinino': 4689,
    'Koktebel': 4689,
    'Kol’chugino': 4689,
    'Kol’tsovo': 4689,
    'Kolodeznoye': 4689,
    'Koloski': 4689,
    'Komsomol’skoye': 4689,
    'Kondratyevo': 4689,
    'Konstantinovka': 4689,
    'Koreiz': 4689,
    'Kormovoye': 4689,
    'Kostochkovka': 4689,
    'Kotel’nikovo': 4689,
    'Kovyl’noye': 4689,
    'Krasna Zor  ka': 4689,
    'Krasnaya Polyana': 4689,
    'Krasnoarmeyskoye': 4689,
    'Krasnoflotskoye': 4689,
    'Krasnogorka': 4689,
    'Krasnogvardeyskoye': 4689,
    'Krasnohvardiiske Raion': 4689,
    'Krasnokamenka': 4689,
    'Krasnolesye': 4689,
    'Krasnoperekops’k': 4689,
    'Krasnoperekopsk Raion': 4689,
    'Krasnoyarskoye': 4689,
    'Krasnoye': 4689,
    'Krasnoznamenka': 4689,
    'Krasnyi Mak': 4689,
    'Krayneye': 4689,
    'Krest’yanovka': 4689,
    'Krinichnoye': 4689,
    'Krymka': 4689,
    'Krymskaya Roza': 4689,
    'Krymskoye': 4689,
    'Kuibyshevo': 4689,
    'Kukushkino': 4689,
    'Kurskoye': 4689,
    'L’govskoye': 4689,
    'Lekarstvennoye': 4689,
    'Lenine Raion': 4689,
    'Lenino': 4689,
    'Leninskoye': 4689,
    'Lesnovka': 4689,
    'Levadki': 4689,
    'Listvennoye': 4689,
    'Litvinenkovo': 4689,
    'Livadia': 4689,
    'Lobanovo': 4689,
    'Lozovoye': 4689,
    'Luchistoye': 4689,
    'Luganskoye': 4689,
    'Lugovoye': 4689,
    'Magazinka': 4689,
    'Malen’koye': 4689,
    'Malorechenskoye': 4689,
    'Maly Mayak': 4689,
    'Mar’yanovka': 4689,
    'Marfovka': 4689,
    'Maryevka': 4689,
    'Maslovo': 4689,
    'Massandra': 4689,
    'Mayskoye': 4689,
    'Mazanka': 4689,
    'Medvedevka': 4689,
    'Medvedevo': 4689,
    'Mel’nichnoye': 4689,
    'Mezhvodnoye': 4689,
    'Michurinskoye': 4689,
    'Mikhaylovka': 4689,
    'Mirnovka': 4689,
    'Mirnoye': 4689,
    'Mirny': 4689,
    'Mitrofanovka': 4689,
    'Mityayevo': 4689,
    'Molochnoye': 4689,
    'Molodyozhnoye': 4689,
    'Morskoye': 4689,
    'Muromskoye': 4689,
    'Muskatnoye': 4689,
    'Mysovoe': 4689,
    'Nasypnoe': 4689,
    'Naydenovka': 4689,
    'Nekrasovka': 4689,
    'Nekrasovo': 4689,
    'Nikita': 4689,
    'Nikolayevka': 4689,
    'Nizhnegorskiy': 4689,
    'Nizhnegorskiy rayon': 4689,
    'Novoandreyevka': 4689,
    'Novoestoniya': 4689,
    'Novofedorovka': 4689,
    'Novogrigoryevka': 4689,
    'Novoivanovka': 4689,
    'Novokrymskoye': 4689,
    'Novonikolayevka': 4689,
    'Novoozyornoye': 4689,
    'Novopavlovka': 4689,
    'Novopokrovka': 4689,
    'Novosel’skoye': 4689,
    'Novoselovskoye': 4689,
    'Novostepnoye': 4689,
    'Novosyolovka': 4689,
    'Novozhilovka': 4689,
    'Novy Svet': 4689,
    'Okhotnikovo': 4689,
    'Okhotskoye': 4689,
    'Oktyabr  skoye': 4689,
    'Oktyabr’skoye': 4689,
    'Okunevka': 4689,
    'Olenevka': 4689,
    'Ordzhonikidze': 4689,
    'Orekhovo': 4689,
    'Orlovskoye': 4689,
    'Ostanino': 4689,
    'Ostrovskoye': 4689,
    'Pakharevka': 4689,
    'Partenit': 4689,
    'Partizanskoye': 4689,
    'Partizany': 4689,
    'Pereval  noye': 4689,
    'Perovo': 4689,
    'Pervomayskiy rayon': 4689,
    'Pervomayskoye': 4689,
    'Peschanoye': 4689,
    'Petrovka': 4689,
    'Pionerskoye': 4689,
    'Plodovoye': 4689,
    'Pobednoye': 4689,
    'Pochetnoye': 4689,
    'Poltavka': 4689,
    'Poshtove': 4689,
    'Pozharskoye': 4689,
    'Pravda': 4689,
    'Primorskiy': 4689,
    'Priozyornoye': 4689,
    'Privetnoye': 4689,
    'Prostornoye': 4689,
    'Prudovoye': 4689,
    'Prudy': 4689,
    'Pshenichnoye': 4689,
    'Pushkino': 4689,
    'Pyatikhatka': 4689,
    'Razdol’nenskiy rayon': 4689,
    'Razdol’noye': 4689,
    'Rodnikovo': 4689,
    'Romashkino': 4689,
    'Roshchino': 4689,
    'Roskoshnoye': 4689,
    'Rovnoye': 4689,
    'Ruch’i': 4689,
    'Rusakovka': 4689,
    'Rybach  e': 4689,
    'Sadovoye': 4689,
    'Saki': 4689,
    'Sakskiy rayon': 4689,
    'Sary-Bash': 4689,
    'Semisotka': 4689,
    'Senokosnoye': 4689,
    'Serebryanka': 4689,
    'Shchebetovka': 4689,
    'Shchyolkino': 4689,
    'Shelkovichnoye': 4689,
    'Shirokoye': 4689,
    'Shkol’noye': 4689,
    'Shtormovoye': 4689,
    'Simeiz': 4689,
    'Simferopol': 4689,
    'Simferopol Raion': 4689,
    'Sinitsyno': 4689,
    'Sizovka': 4689,
    'Skalistoye': 4689,
    'Skvortsovo': 4689,
    'Slavnoye': 4689,
    'Slavyanskoye': 4689,
    'Sofiivka': 4689,
    'Sokolinoye': 4689,
    'Solnechnaya Dolina': 4689,
    'Solnechnogorskoye': 4689,
    'Solnechnoye': 4689,
    'Sovetskiy': 4689,
    'Sovietskyi Raion': 4689,
    'Sovkhoznoye': 4689,
    'Stakhanovka': 4689,
    'Stal’noye': 4689,
    'Stary Krym': 4689,
    'Stepnoye': 4689,
    'Stolbovoye': 4689,
    'Strogonovka': 4689,
    'Sudak': 4689,
    'Susanino': 4689,
    'Suvorovo': 4689,
    'Suvorovskoye': 4689,
    'Svetloye': 4689,
    'Tabachnoye': 4689,
    'Tankovoye': 4689,
    'Tenistoye': 4689,
    'Teplovka': 4689,
    'Tokarevo': 4689,
    'Trudovoye': 4689,
    'Tselinnoye': 4689,
    'Tsvetochnoye': 4689,
    'Turgenevka': 4689,
    'Uglovoye': 4689,
    'Ukrainka': 4689,
    'Ukromnoye': 4689,
    'Urozhaynoye': 4689,
    'Uvarovka': 4689,
    'Uvarovo': 4689,
    'Uyutnoye': 4689,
    'Vasilyevka': 4689,
    'Veresayevo': 4689,
    'Verkhorechye': 4689,
    'Vesele': 4689,
    'Veselovka': 4689,
    'Vilino': 4689,
    'Vinnitskoye': 4689,
    'Vinogradnoye': 4689,
    'Vinogradovo': 4689,
    'Vishennoye': 4689,
    'Vishnyovka': 4689,
    'Vladimirovka': 4689,
    'Vladislavovka': 4689,
    'Voinka': 4689,
    'Vol’noye': 4689,
    'Vorobyovo': 4689,
    'Voskhod': 4689,
    'Voykovo': 4689,
    'Yalta': 4689,
    'Yantarnoye': 4689,
    'Yarkoye': 4689,
    'Yarkoye Pole': 4689,
    'Yasnopolyanskoye': 4689,
    'Yemelyanovka': 4689,
    'Yermakovo': 4689,
    'Yevpatoriya': 4689,
    'Zalesye': 4689,
    'Zaozyornoye': 4689,
    'Zarechnoye': 4689,
    'Zavet-Leninskiy': 4689,
    'Zavetnoye': 4689,
    'Zelenogorskoye': 4689,
    'Zelyonoye': 4689,
    'Zemlyanichnoye': 4689,
    'Zernovoye': 4689,
    'Zheleznodorozhnoye': 4689,
    'Zhelyabovka': 4689,
    'Zhemchuzhina': 4689,
    'Zhuravki': 4689,
    'Zhuravli': 4689,
    'Zhuravlyovka': 4689,
    'Zimino': 4689,
    'Zolotoye Pole': 4689,
    'Zorkino': 4689,
    'Zuya': 4689,
    'Zybiny': 4689,
    'Babanka': 4680,
    'Buky': 4680,
    'Cherkasy': 4680,
    'Chervona Sloboda': 4680,
    'Chornobay': 4680,
    'Chyhyryn': 4680,
    'Drabiv': 4680,
    'Horodyshche': 4680,
    'Kamianka': 4680,
    'Kaniv': 4680,
    'Khrystynivka': 4680,
    'Korsun-Shevchenkivskyi': 4680,
    'Lebedyn': 4680,
    'Lysianka': 4680,
    'Mankivka': 4680,
    'Mliiv': 4680,
    'Monastyryshche': 4680,
    'Moshny': 4680,
    'Nove-Misto': 4680,
    'Ruska Poliana': 4680,
    'Shpola': 4680,
    'Smila': 4680,
    'Stebliv': 4680,
    'Talne': 4680,
    'Tsibulev': 4680,
    'Tsvitkove': 4680,
    'Uman': 4680,
    'Vatutine': 4680,
    'Verkhniachka': 4680,
    'Yerky': 4680,
    'Yurkivka': 4680,
    'Zhashkiv': 4680,
    'Zolotonosha': 4680,
    'Zvenihorodka': 4680,
    'Avdiyivka': 4692,
    'Bakhmach': 4692,
    'Baturyn': 4692,
    'Berezna': 4692,
    'Bobrovytsya': 4692,
    'Borzna': 4692,
    'Chernihiv': 4692,
    'Chernihiv Raion': 4692,
    'Chernihivs’ka Mis’krada': 4692,
    'Desna': 4692,
    'Dihtyari': 4692,
    'Dobryanka': 4692,
    'Horodnya': 4692,
    'Ichnya': 4692,
    'Kholmy': 4692,
    'Kobyzhcha': 4692,
    'Korop': 4692,
    'Koryukivka': 4692,
    'Kozelets’': 4692,
    'Kruty': 4692,
    'Kulykivka': 4692,
    'Ladan': 4692,
    'Lisovi Sorochyntsi': 4692,
    'Lyubech': 4692,
    'Mena': 4692,
    'Mens’kyy Rayon': 4692,
    'Nizhyn': 4692,
    'Nosivka': 4692,
    'Novhorod-Sivers’kyy': 4692,
    'Olyshivka': 4692,
    'Oster': 4692,
    'Pryluky': 4692,
    'Ripky': 4692,
    'Sedniv': 4692,
    'Semenivka': 4692,
    'Shchors': 4692,
    'Snovs  kyy Rayon': 4692,
    'Sosnytsya': 4692,
    'Sribne': 4692,
    'Varva': 4692,
    'Zhuravka': 4692,
    'Banyliv': 4678,
    'Berehomet': 4678,
    'Boyany': 4678,
    'Chernivtsi': 4678,
    'Chernivtsi Municipality': 4678,
    'Chudey': 4678,
    'Davydivka': 4678,
    'Hlyboka': 4678,
    'Ispas': 4678,
    'Kadubivtsi': 4678,
    'Khotyn': 4678,
    'Klishkivtsi': 4678,
    'Kostryzhivka': 4678,
    'Luzhany': 4678,
    'Marshintsy': 4678,
    'Molodiya': 4678,
    'Myhove': 4678,
    'Novodnistrovs’k': 4678,
    'Novoselytsya': 4678,
    'Nyzhni Petrivtsi': 4678,
    'Putyla Raion': 4678,
    'Ropcha': 4678,
    'Shypyntsi': 4678,
    'Sokyryany': 4678,
    'Vashkivtsi': 4678,
    'Verenchanka': 4678,
    'Voloka': 4678,
    'Vyzhnytsya': 4678,
    'Yizhivtsi': 4678,
    'Zastavna': 4678,
    'Apostolove': 4675,
    'Auly': 4675,
    'Aviatorske': 4675,
    'Chervonohryhorivka': 4675,
    'Chortomlyk': 4675,
    'Dnipro': 4675,
    'Dnipro Raion': 4675,
    'Dniprovs’ka Mis’ka Rada': 4675,
    'Hubynykha': 4675,
    'Hupalivka': 4675,
    'Kamianske': 4675,
    'Kaydaki': 4675,
    'Krynychky': 4675,
    'Kryvoriz’ka Mis’krada': 4675,
    'Kryvyi Rih': 4675,
    'Lykhivka': 4675,
    'Mahdalynivka': 4675,
    'Mahdalynivs’kyy Rayon': 4675,
    'Marhanets’': 4675,
    'Melioratyvne': 4675,
    'Mezhova': 4675,
    'Nikopol': 4675,
    'Novomoskovs’k': 4675,
    'Novomoskovsk Raion': 4675,
    'Novomykolayivka': 4675,
    'Novopokrovka': 4675,
    'Ordzhonikidze': 4675,
    'Partyzans’ke': 4675,
    'Pavlohrad': 4675,
    'Petropavlivka': 4675,
    'Petrykivka': 4675,
    'Petrykivs’kyy Rayon': 4675,
    'Piatykhatky': 4675,
    'Pidhorodne': 4675,
    'Sholokhove': 4675,
    'Shyroke': 4675,
    'Shyroke Raion': 4675,
    'Sofiyivka': 4675,
    'Solone': 4675,
    'Solone Raion': 4675,
    'Spas’ke': 4675,
    'Surs’ko-Mykhaylivka': 4675,
    'Synel’nykove': 4675,
    'Tomakivka': 4675,
    'Tsarychanka': 4675,
    'Tsarychans’kyy Rayon': 4675,
    'Verkhn’odniprovs’k': 4675,
    'Vil’ne': 4675,
    'Vyshchetarasivka': 4675,
    'Vyshneve': 4675,
    'Yur”yivs’kyy Rayon': 4675,
    'Zhovti Vody': 4675,
    'Amvrosiivka Raion': 4691,
    'Amvrosiyivka': 4691,
    'Avdiyivka': 4691,
    'Bakhmut': 4691,
    'Bakhmut Raion': 4691,
    'Bezimenne': 4691,
    'Bilyts’ke': 4691,
    'Blahodatne': 4691,
    'Boykivske': 4691,
    'Boykivske Raion': 4691,
    'Chasiv Yar': 4691,
    'Chystyakove': 4691,
    'Debal’tseve': 4691,
    'Dmytrivka': 4691,
    'Dobropillia Raion': 4691,
    'Dobropillya': 4691,
    'Dokuchayevs’k': 4691,
    'Donetsk': 4691,
    'Druzhkivka': 4691,
    'Horlivka': 4691,
    'Ilovays’k': 4691,
    'Khartsyz’k': 4691,
    'Kostiantynivka Raion': 4691,
    'Kostyantynivka': 4691,
    'Kramators’k': 4691,
    'Krasnotorka': 4691,
    'Krinichnaya': 4691,
    'Kurakhovo': 4691,
    'Kuteynykove': 4691,
    'Luhans’ke': 4691,
    'Lyman': 4691,
    'Lyman Raion': 4691,
    'Makiyivka': 4691,
    'Makiyivs’ka Mis’krada': 4691,
    'Manhush': 4691,
    'Mar  yinka': 4691,
    'Mariupol': 4691,
    'Melekyne': 4691,
    'Mospyne': 4691,
    'Myrnohrad': 4691,
    'Nikolske Raion': 4691,
    'Novoazovs  k': 4691,
    'Novodonets’ke': 4691,
    'Novyy Svit': 4691,
    'Nyzhnya Krynka': 4691,
    'P  yatypill  ya': 4691,
    'Pokrovsk': 4691,
    'Pokrovsk Raion': 4691,
    'Rodyns’ke': 4691,
    'Rozsypne': 4691,
    'Sartana': 4691,
    'Selydivs’ka Mis’krada': 4691,
    'Selydove': 4691,
    'Serebryanka': 4691,
    'Shakhtars’k': 4691,
    'Shcherbynivka': 4691,
    'Shyroke': 4691,
    'Sivers’k': 4691,
    'Sloviansk': 4691,
    'Sloviansk Raion': 4691,
    'Snizhne': 4691,
    'Soledar': 4691,
    'Starobesheve': 4691,
    'Staryy Krym': 4691,
    'Stizhkivs’ke': 4691,
    'Svetlodarsk': 4691,
    'Svyatogorsk': 4691,
    'Syedove': 4691,
    'Temriuk': 4691,
    'Toretsk': 4691,
    'Urzuf': 4691,
    'Volnovakha': 4691,
    'Vuhlehirs’k': 4691,
    'Yalta': 4691,
    'Yasynuvata': 4691,
    'Yasynuvata Raion': 4691,
    'Yenakiyeve': 4691,
    'Zuhres': 4691,
    'Bili Oslavy': 4682,
    'Bilshivtsi': 4682,
    'Bohorodchans’kyy Rayon': 4682,
    'Bohorodchany': 4682,
    'Bolekhiv': 4682,
    'Bolekhivs’ka Mis’krada': 4682,
    'Borshchevskiy Rayon': 4682,
    'Broshniv-Osada': 4682,
    'Burshtyn': 4682,
    'Burshtyns’ka Mis’krada': 4682,
    'Bystrytsya': 4682,
    'Bytkiv': 4682,
    'Chernelytsya': 4682,
    'Cherniyiv': 4682,
    'Chernyatyn': 4682,
    'Chornoliztsi': 4682,
    'Delyatyn': 4682,
    'Dolyna': 4682,
    'Dolyns’kyy Rayon': 4682,
    'Halych': 4682,
    'Halyts’kyy Rayon': 4682,
    'Horodenka': 4682,
    'Horodenkivs’kyy Rayon': 4682,
    'Hvizd': 4682,
    'Ivano-Frankivs’ka Mis’krada': 4682,
    'Ivano-Frankivsk': 4682,
    'Kalus’kyy Rayon': 4682,
    'Kalush': 4682,
    'Khymchyn': 4682,
    'Kolomyia': 4682,
    'Kolomyys’kyy Rayon': 4682,
    'Kosiv': 4682,
    'Kosivs’kyy Rayon': 4682,
    'Kosmach': 4682,
    'Krasna': 4682,
    'Kuty': 4682,
    'Lanchyn': 4682,
    'Manyava': 4682,
    'Markova': 4682,
    'Nadvirna': 4682,
    'Nadvirnyans’kyy Rayon': 4682,
    'Nebyliv': 4682,
    'Novytsya': 4682,
    'Obertyn': 4682,
    'Otyniya': 4682,
    'Pasichna': 4682,
    'Pechenizhyn': 4682,
    'Petranka': 4682,
    'Pniv': 4682,
    'Porohy': 4682,
    'Radcha': 4682,
    'Rechka': 4682,
    'Rohatyn': 4682,
    'Rohatyns’kyy Rayon': 4682,
    'Rozhniativ': 4682,
    'Rozhniv': 4682,
    'Rozhnyativs’kyy Rayon': 4682,
    'Sadzhavka': 4682,
    'Sheshory': 4682,
    'Sniatyn': 4682,
    'Snyatyns’kyy Rayon': 4682,
    'Solotvyn': 4682,
    'Spas': 4682,
    'Stari Bohorodchany': 4682,
    'Stari Kuty': 4682,
    'Stetseva': 4682,
    'Svarychiv': 4682,
    'Tatariv': 4682,
    'Tlumach': 4682,
    'Tlumats’kyy Rayon': 4682,
    'Tysmenychany': 4682,
    'Tysmenyts’kyy Rayon': 4682,
    'Tysmenytsya': 4682,
    'Uhryniv': 4682,
    'Velykyy Klyuchiv': 4682,
    'Verkhniy Yasenov': 4682,
    'Verkhovyna': 4682,
    'Vorokhta': 4682,
    'Yabluniv': 4682,
    'Yablunytsya': 4682,
    'Yamnytsya': 4682,
    'Yaremchans’ks Mis’krada': 4682,
    'Yaremche': 4682,
    'Zabolotiv': 4682,
    'Zahvizdya': 4682,
    'Zarichchya': 4682,
    'Balakliya': 4686,
    'Barvinkove': 4686,
    'Berezivka': 4686,
    'Bezlyudivka': 4686,
    'Blyznyuky': 4686,
    'Bohodukhiv': 4686,
    'Borova': 4686,
    'Budy': 4686,
    'Chapayeve': 4686,
    'Chervonyy Oskil': 4686,
    'Chuhuyiv': 4686,
    'Derhachi': 4686,
    'Dvorichna': 4686,
    'Eskhar': 4686,
    'Huty': 4686,
    'Izyum': 4686,
    'Kehychivka': 4686,
    'Kharkiv': 4686,
    'Khorosheve': 4686,
    'Kivsharivka': 4686,
    'Kochetok': 4686,
    'Kolomak': 4686,
    'Korotych': 4686,
    'Krasnohrad': 4686,
    'Krasnokuts’k': 4686,
    'Krasnopavlivka': 4686,
    'Kulynychi': 4686,
    'Kupjansk': 4686,
    'Lozova': 4686,
    'Lyman': 4686,
    'Lyubotyn': 4686,
    'Mala Danylivka': 4686,
    'Malynivka': 4686,
    'Manchenky': 4686,
    'Merefa': 4686,
    'Nova Vodolaha': 4686,
    'Novopokrovka': 4686,
    'Panyutyne': 4686,
    'Peresichna': 4686,
    'Pervomaiskyi Raion': 4686,
    'Pervomays’kyy': 4686,
    'Petrivs’ke': 4686,
    'Pivdenne': 4686,
    'Pokotylivka': 4686,
    'Prudyanka': 4686,
    'Prykolotne': 4686,
    'Sakhnovshchyna': 4686,
    'Savyntsi': 4686,
    'Shevchenkove': 4686,
    'Slatyne': 4686,
    'Slobozhans’ke': 4686,
    'Solonytsivka': 4686,
    'Staryy Merchyk': 4686,
    'Staryy Saltiv': 4686,
    'Studenok': 4686,
    'Utkivka': 4686,
    'Valky': 4686,
    'Vasyshcheve': 4686,
    'Velykyy Burluk': 4686,
    'Verbivka': 4686,
    'Vovchans’k': 4686,
    'Vvedenka': 4686,
    'Vysokyy': 4686,
    'Zachepylivka': 4686,
    'Zmiyiv': 4686,
    'Zolochiv': 4686,
    'Askaniya-Nova': 4684,
    'Bekhtery': 4684,
    'Beryslav': 4684,
    'Bilozerka': 4684,
    'Brylivka': 4684,
    'Chaplynka': 4684,
    'Chornobayivka': 4684,
    'Chornyanka': 4684,
    'Chulakivka': 4684,
    'Dnipryany': 4684,
    'Dudchany': 4684,
    'Heniches’k': 4684,
    'Heniches’kyy Rayon': 4684,
    'Hola Prystan’': 4684,
    'Hornostayivka': 4684,
    'Ivanivka': 4684,
    'Kakhovka': 4684,
    'Kalanchak': 4684,
    'Kayiry': 4684,
    'Kherson': 4684,
    'Komyshany': 4684,
    'Kozachi Laheri': 4684,
    'Krasne': 4684,
    'Lazurne': 4684,
    'Lyubymivka': 4684,
    'Malokakhovka': 4684,
    'Nova Kakhovka': 4684,
    'Nova Mayachka': 4684,
    'Novofedorivka': 4684,
    'Novooleksiyivka': 4684,
    'Novovorontsovka': 4684,
    'Nyzhni Sirohozy': 4684,
    'Partyzany': 4684,
    'Radens’k': 4684,
    'Skadovs’k': 4684,
    'Stanislav': 4684,
    'Tsyurupyns’k': 4684,
    'Velyka Lepetykha': 4684,
    'Velyka Oleksandrivka': 4684,
    'Velyki Kopani': 4684,
    'Verkhniy Rohachyk': 4684,
    'Vynohradove': 4684,
    'Vysokopillya': 4684,
    'Zaliznyy Port': 4684,
    'Antoniny': 4681,
    'Bazaliya': 4681,
    'Derazhnya': 4681,
    'Derazhnyans’kyy Rayon': 4681,
    'Dunaivtsi': 4681,
    'Horodok': 4681,
    'Izyaslav': 4681,
    'Kamianets-Podilskyi': 4681,
    'Khmel’nyts’kyy Rayon': 4681,
    'Khmelnytskyi': 4681,
    'Krasyliv': 4681,
    'Letychiv': 4681,
    'Makiv': 4681,
    'Medzhybizh': 4681,
    'Narkevychi': 4681,
    'Netishyn': 4681,
    'Nova Ushytsya': 4681,
    'Novoselytsya': 4681,
    'Polonne': 4681,
    'Poninka': 4681,
    'Shepetivka': 4681,
    'Slavuta': 4681,
    'Smotrych': 4681,
    'Smyga': 4681,
    'Stara Syniava': 4681,
    'Starokostiantyniv': 4681,
    'Yarmolyntsi': 4681,
    'Zakupne': 4681,
    'Zhvanets': 4681,
    'Darnytsia Raion': 4676,
    'Desnyans’kyy Rayon': 4676,
    'Dnipro Raion': 4676,
    'Holosiiv Raion': 4676,
    'Kotsyubyns’ke': 4676,
    'Kyiv': 4676,
    'Pechersk Raion': 4676,
    'Podil’s’kyy Rayon': 4676,
    'Prolisky': 4676,
    'Shevchenkivs’kyy Rayon': 4676,
    'Solomianka Rayon': 4676,
    'Svyatoshyns’kyy Rayon': 4676,
    'Adzhamka': 4677,
    'Blahovishchenske Raion': 4677,
    'Bobrynets': 4677,
    'Dmytrivka': 4677,
    'Dobrovelychkivka': 4677,
    'Dobrovelychkivs’kyy Rayon': 4677,
    'Dolyns  ka': 4677,
    'Dolyns’kyy Rayon': 4677,
    'Hayvoron': 4677,
    'Kapitanivka': 4677,
    'Kirovohrads’ka Mis’krada': 4677,
    'Kompaniyivka': 4677,
    'Kropyvnytskyi': 4677,
    'Lozuvatka': 4677,
    'Lypnyazhka': 4677,
    'Mala Vyska': 4677,
    'Malovyskivs’kyy Rayon': 4677,
    'Molodizhne': 4677,
    'Nova Praha': 4677,
    'Novhorodka': 4677,
    'Novoarkhanhel’s’k': 4677,
    'Novomyrhorod': 4677,
    'Novoukrayinka': 4677,
    'Novyy Starodub': 4677,
    'Oleksandrivka': 4677,
    'Oleksandriya': 4677,
    'Oleksandriys’ke': 4677,
    'Onufriyivka': 4677,
    'Pantayivka': 4677,
    'Pavlysh': 4677,
    'Perehonivka': 4677,
    'Petrove': 4677,
    'Pobugskoye': 4677,
    'Pomichna': 4677,
    'Pryyutivka': 4677,
    'Rivne': 4677,
    'Subottsi': 4677,
    'Svitlovods’k': 4677,
    'Tyshkivka': 4677,
    'Ustynivka': 4677,
    'Yelyzavethradka': 4677,
    'Zavallya': 4677,
    'Zlynka': 4677,
    'Znomenka': 4677,
    'Baryshivka': 4671,
    'Baryshivs’kyy Rayon': 4671,
    'Bila Tserkva': 4671,
    'Bila Tserkva Raion': 4671,
    'Bohuslav': 4671,
    'Borispol’skiy Rayon': 4671,
    'Borodianka': 4671,
    'Borodyans’kyy Rayon': 4671,
    'Borova': 4671,
    'Boryspil’': 4671,
    'Boyarka': 4671,
    'Brovary': 4671,
    'Brovary Raion': 4671,
    'Bucha': 4671,
    'Chabany': 4671,
    'Chernyakhivka': 4671,
    'Dymer': 4671,
    'Fastiv': 4671,
    'Fastiv Raion': 4671,
    'Hlevakha': 4671,
    'Hnidyn': 4671,
    'Horenka': 4671,
    'Hostomel': 4671,
    'Irpin': 4671,
    'Irpins’ka Mis’krada': 4671,
    'Ivankiv': 4671,
    'Ivankiv Raion': 4671,
    'Kaharlyk': 4671,
    'Kaharlyk Raion': 4671,
    'Kalynove': 4671,
    'Karapyshi': 4671,
    'Katyuzhanka': 4671,
    'Khotiv': 4671,
    'Kirove': 4671,
    'Klavdiyevo-Tarasove': 4671,
    'Knyazhichi': 4671,
    'Kodra': 4671,
    'Korniyivka': 4671,
    'Kozhanka': 4671,
    'Krasyatychi': 4671,
    'Kriukivschina': 4671,
    'Kryva Hora': 4671,
    'Kyyevo-Svyatoshyns’kyy Rayon': 4671,
    'Liutizh': 4671,
    'Makariv': 4671,
    'Makariv Raion': 4671,
    'Mirovka': 4671,
    'Motovylivka': 4671,
    'Myronivka': 4671,
    'Novi Petrivtsi': 4671,
    'Obukhiv': 4671,
    'Obukhiv Raion': 4671,
    'Obukhivs’ka Mis’krada': 4671,
    'Pereiaslav-Khmelnytskyi': 4671,
    'Pereiaslav-Khmelnytskyi Raion': 4671,
    'Piskivka': 4671,
    'Pohreby': 4671,
    'Pukhivka': 4671,
    'Rokytne': 4671,
    'Rokytne Raion': 4671,
    'Roskoshnyy': 4671,
    'Rzhyshchiv': 4671,
    'Semypolky': 4671,
    'Skvyra': 4671,
    'Skvyrs’kyy Rayon': 4671,
    'Slavutych': 4671,
    'Sofiyivska Borschagivka': 4671,
    'Stavyshche': 4671,
    'Stayki': 4671,
    'Tarashcha': 4671,
    'Tetiiv': 4671,
    'Trebukhiv': 4671,
    'Trypillia': 4671,
    'Tsybli': 4671,
    'Ukrainka': 4671,
    'Uzyn': 4671,
    'Vasylkiv': 4671,
    'Vasylkiv Raion': 4671,
    'Velyka Dymerka': 4671,
    'Volodarka': 4671,
    'Voronkov': 4671,
    'Vorzel’': 4671,
    'Vyshgorodskiy Rayon': 4671,
    'Vyshhorod': 4671,
    'Vyshneve': 4671,
    'Yahotyn': 4671,
    'Yahotyns’kyy Rayon': 4671,
    'Z·hurivs’kyy Rayon': 4671,
    'Zgurovka': 4671,
    'Alchevs’k': 4673,
    'Alchevs’ka Mis’krada': 4673,
    'Antratsyt': 4673,
    'Antratsytivs’kyy Rayon': 4673,
    'Artemivs’k': 4673,
    'Bayrachky': 4673,
    'Bile': 4673,
    'Bilohorivka': 4673,
    'Bilokurakyne': 4673,
    'Bilovods  k': 4673,
    'Biryukove': 4673,
    'Bryanka': 4673,
    'Buran': 4673,
    'Chervonopartyzans’k': 4673,
    'Chornukhyne': 4673,
    'Dovzhanskyy Rayon': 4673,
    'Hirs’ke': 4673,
    'Kadiyivka': 4673,
    'Kirovs’k': 4673,
    'Kirovs’ka Mis’krada': 4673,
    'Klenovyy': 4673,
    'Krasnyy Kut': 4673,
    'Krasnyy Luch': 4673,
    'Kreminna': 4673,
    'Kripens’kyy': 4673,
    'Lenina': 4673,
    'Lozno-Oleksandrivka': 4673,
    'Luhans’ka Mis’krada': 4673,
    'Luhansk': 4673,
    'Lutuhyne': 4673,
    'Lutuhyns’kyy Rayon': 4673,
    'Lysychans’k': 4673,
    'Makariv Yar': 4673,
    'Markivka': 4673,
    'Millerovo': 4673,
    'Milove': 4673,
    'Miusyns’k': 4673,
    'Molodohvardiys’k': 4673,
    'Novopskov': 4673,
    'Nyzhnya Duvanka': 4673,
    'Pavlivka': 4673,
    'Pereval’s’k': 4673,
    'Pervomays’k': 4673,
    'Popasna': 4673,
    'Pryvillya': 4673,
    'Roven’ky': 4673,
    'Rozkishne': 4673,
    'Rubizhans’ka Mis’krada': 4673,
    'Rubizhne': 4673,
    'Shchastya': 4673,
    'Simeykyne': 4673,
    'Slov`yanoserbsk': 4673,
    'Sorokyne': 4673,
    'Sorokyns  kyi Rayon': 4673,
    'Stanytsya Luhans’ka': 4673,
    'Starobil’s’k': 4673,
    'Svatove': 4673,
    'Sverdlovs’k': 4673,
    'Sverdlovs’ka Mis’krada': 4673,
    'Syevyerodonets’k': 4673,
    'Teple': 4673,
    'Toshkivka': 4673,
    'Tr’okhizbenka': 4673,
    'Uralo-Kavkaz': 4673,
    'Uspenka': 4673,
    'Zalesnoye': 4673,
    'Zoryns’k': 4673,
    'Zymohiria': 4673,
    'Belz': 4672,
    'Bibrka': 4672,
    'Boryslav': 4672,
    'Brody': 4672,
    'Bryukhovychi': 4672,
    'Chervonohrad': 4672,
    'Demnya': 4672,
    'Dobromyl’': 4672,
    'Drohobych': 4672,
    'Dublyany': 4672,
    'Duliby': 4672,
    'Dzvinogrud': 4672,
    'Hlyns  k': 4672,
    'Horodok': 4672,
    'Ivano-Frankove': 4672,
    'Khodoriv': 4672,
    'Khyriv': 4672,
    'Komarno': 4672,
    'Krasne': 4672,
    'Kulykiv': 4672,
    'Lopatyn': 4672,
    'Lviv': 4672,
    'Medenychi': 4672,
    'Morshyn': 4672,
    'Mostys  ka': 4672,
    'Murovane': 4672,
    'Mykolaiv': 4672,
    'Navaria': 4672,
    'Novoyavorivs  k': 4672,
    'Novyi Yarychiv': 4672,
    'Obroshyne': 4672,
    'Olesko': 4672,
    'Peremyshlyany': 4672,
    'Pidbuzh': 4672,
    'Pidhirtsi': 4672,
    'Pidkamin’': 4672,
    'Pnikut': 4672,
    'Pomoriany': 4672,
    'Potelych': 4672,
    'Pustomyty': 4672,
    'Ralivka': 4672,
    'Rava-Rus’ka': 4672,
    'Richky': 4672,
    'Rodatychi': 4672,
    'Rozdil': 4672,
    'Rozvadiv': 4672,
    'Rudky': 4672,
    'Rudne': 4672,
    'Rykhtychi': 4672,
    'Sambir': 4672,
    'Shchyrets': 4672,
    'Shklo': 4672,
    'Silets’': 4672,
    'Skelivka': 4672,
    'Skhidnytsya': 4672,
    'Skole': 4672,
    'Slavske': 4672,
    'Sokal’': 4672,
    'Solonka': 4672,
    'Staroye Selo': 4672,
    'Starychi': 4672,
    'Staryy Dobrotvir': 4672,
    'Staryy Sambir': 4672,
    'Stebnyk': 4672,
    'Stryi': 4672,
    'Sudova Vyshnya': 4672,
    'Sukhovolya': 4672,
    'Truskavets’': 4672,
    'Tukhlia': 4672,
    'Turka': 4672,
    'Uhniv': 4672,
    'Ulychne': 4672,
    'Verblyany': 4672,
    'Verchnia Rozhanka': 4672,
    'Verkhnie Synevydne': 4672,
    'Verkhnyaya Belka': 4672,
    'Voyutychi': 4672,
    'Yavoriv': 4672,
    'Zhovkva': 4672,
    'Zhydachiv': 4672,
    'Zolochiv': 4672,
    'Arbuzynka': 4679,
    'Bashtanka': 4679,
    'Berezanka': 4679,
    'Bereznehuvate': 4679,
    'Domanivka': 4679,
    'Kazanka': 4679,
    'Kobleve': 4679,
    'Kostyantynivka': 4679,
    'Kryve Ozero': 4679,
    'Lysa Hora': 4679,
    'Myhiya': 4679,
    'Mykolayiv': 4679,
    'Nova Odesa': 4679,
    'Novyy Buh': 4679,
    'Ochakiv': 4679,
    'Ol  shanskoye': 4679,
    'Oleksandrivka': 4679,
    'Parutyne': 4679,
    'Pervomays’k': 4679,
    'Pidhorodna': 4679,
    'Rybakivka': 4679,
    'Shevchenkove': 4679,
    'Snihurivka': 4679,
    'Veselynove': 4679,
    'Voznesensk': 4679,
    'Vradiyivka': 4679,
    'Yuzhnoukrains  k': 4679,
    'Artsyz': 4688,
    'Balta': 4688,
    'Balts’kyy Rayon': 4688,
    'Berezivka': 4688,
    'Berezyne': 4688,
    'Bilhorod-Dnistrovskyi': 4688,
    'Bilyayivka': 4688,
    'Bolhrad': 4688,
    'Borodino': 4688,
    'Broska': 4688,
    'Chornomors’k': 4688,
    'Dmytrivka': 4688,
    'Fontanka': 4688,
    'Horodnye': 4688,
    'Ivanivka': 4688,
    'Izmayil': 4688,
    'Kalcheva': 4688,
    'Kamyshevka Vtoraya': 4688,
    'Karolino-Buhaz': 4688,
    'Kiliya': 4688,
    'Kodyma': 4688,
    'Kodyms’kyy Rayon': 4688,
    'Krasnosilka': 4688,
    'Kremidivka': 4688,
    'Kryzhanivka': 4688,
    'Kuchurhan': 4688,
    'Kulevcha': 4688,
    'Kurisove': 4688,
    'Lymanske': 4688,
    'Lyubashivka': 4688,
    'Mayaky': 4688,
    'Mykolayivka': 4688,
    'Mykolayivs’kyy Rayon': 4688,
    'Novi Troyany': 4688,
    'Odessa': 4688,
    'Okny': 4688,
    'Oleksandrivka': 4688,
    'Ovidiopol': 4688,
    'Ozerne': 4688,
    'Peschana': 4688,
    'Plakhtiyivka': 4688,
    'Podil’s’k': 4688,
    'Podilsk Raion': 4688,
    'Reni': 4688,
    'Rozdil’na': 4688,
    'Salhany': 4688,
    'Sarata': 4688,
    'Savran': 4688,
    'Serhiyivka': 4688,
    'Serpneve': 4688,
    'Shabo': 4688,
    'Shevchenkove': 4688,
    'Shyryayeve': 4688,
    'Slobidka': 4688,
    'Starokozache': 4688,
    'Stepanivka': 4688,
    'Stepove': 4688,
    'Tarutyne': 4688,
    'Tatarbunary': 4688,
    'Tayirove': 4688,
    'Teplodar': 4688,
    'Usatove': 4688,
    'Velyka Mykhaylivka': 4688,
    'Velykoploske': 4688,
    'Vylkove': 4688,
    'Vynohradivka': 4688,
    'Yuzhne': 4688,
    'Zagnitkiv': 4688,
    'Zakharivka': 4688,
    'Zaliznychne': 4688,
    'Zatoka': 4688,
    'Zatyshshya': 4688,
    'Zorya': 4688,
    'Demydivs’kyy Rayon': 4683,
    'Dubno': 4683,
    'Dubrovytsya': 4683,
    'Horodyshche': 4683,
    'Hoshcha': 4683,
    'Klevan': 4683,
    'Kolodenka': 4683,
    'Koloniya Zastav’ye': 4683,
    'Korets': 4683,
    'Kostopil’': 4683,
    'Mizoch': 4683,
    'Mlyniv': 4683,
    'Mlynivs’kyy Rayon': 4683,
    'Orzhiv': 4683,
    'Ostroh': 4683,
    'Ostroz’ka Mis’krada': 4683,
    'Radyvyliv': 4683,
    'Rivne': 4683,
    'Rivnens’ka Mis’krada': 4683,
    'Rokytne': 4683,
    'Sarny': 4683,
    'Tarakaniv': 4683,
    'Tynne': 4683,
    'Varash': 4683,
    'Velyki Mezhyrichi': 4683,
    'Volodymyrets’': 4683,
    'Zarichne': 4683,
    'Zdolbuniv': 4683,
    'Zdovbytsya': 4683,
    'Bilopillya': 4685,
    'Boromlya': 4685,
    'Buryn’': 4685,
    'Druzhba': 4685,
    'Hlukhiv': 4685,
    'Konotop': 4685,
    'Krasnopillya': 4685,
    'Krolevets’': 4685,
    'Kyrykivka': 4685,
    'Lebedyn': 4685,
    'Lypova Dolyna': 4685,
    'Mezenivka': 4685,
    'Nedryhayliv': 4685,
    'Nyzy': 4685,
    'Okhtyrka': 4685,
    'Putyvl’': 4685,
    'Romens’ka Mis’krada': 4685,
    'Romny': 4685,
    'Seredyna-Buda': 4685,
    'Shalyhyne': 4685,
    'Shostka': 4685,
    'Sloboda': 4685,
    'Sumy': 4685,
    'Terny': 4685,
    'Trostyanets’': 4685,
    'Velyka Pysarivka': 4685,
    'Verkhnya Syrovatka': 4685,
    'Vorozhba': 4685,
    'Yampil’': 4685,
    'Yesman’': 4685,
    'Belaya': 4674,
    'Borshchiv': 4674,
    'Buchach': 4674,
    'Chortkiv': 4674,
    'Druzhba': 4674,
    'Hrymayliv': 4674,
    'Husiatyn': 4674,
    'Khorostkiv': 4674,
    'Kopychyntsi': 4674,
    'Kozova': 4674,
    'Kremenets': 4674,
    'Lanivtsi': 4674,
    'Mikulintsy': 4674,
    'Nyrkiv': 4674,
    'Ozerna': 4674,
    'Petrikov': 4674,
    'Pidhaytsi': 4674,
    'Pidvolochysk': 4674,
    'Pochaiv': 4674,
    'Skala-Podil’s’ka': 4674,
    'Skalat': 4674,
    'Terebovlya': 4674,
    'Ternopil': 4674,
    'Tovste': 4674,
    'Velikiye Borki': 4674,
    'Yahil’nytsya': 4674,
    'Zalishchyky': 4674,
    'Zaliztsi': 4674,
    'Zbarazh': 4674,
    'Zboriv': 4674,
    'Zolotyy Potik': 4674,
    'Бережани': 4674,
    'Bar': 4669,
    'Barskiy Rayon': 4669,
    'Bershad': 4669,
    'Brailiv': 4669,
    'Bratslav': 4669,
    'Chechelnyk': 4669,
    'Chernivets’kyy Rayon': 4669,
    'Chernivtsi': 4669,
    'Dashiv': 4669,
    'Haisyn': 4669,
    'Illintsi': 4669,
    'Kalynivka': 4669,
    'Khmilnyk': 4669,
    'Klembivka': 4669,
    'Kopayhorod': 4669,
    'Kozyatyn': 4669,
    'Kryzhopil’': 4669,
    'Ladyzhyn': 4669,
    'Lityn': 4669,
    'Lityns’kyy Rayon': 4669,
    'Lypovets’kyy Rayon': 4669,
    'Mohyliv-Podilskyi': 4669,
    'Murafa': 4669,
    'Murovani Kurylivtsi': 4669,
    'Nemyriv': 4669,
    'Nova Pryluka': 4669,
    'Obodivka': 4669,
    'Orativ': 4669,
    'Pavlivka': 4669,
    'Pohrebyshche': 4669,
    'Pohrebyshchens’kyy Rayon': 4669,
    'Serebriya': 4669,
    'Sharhorod': 4669,
    'Sobolivka': 4669,
    'Sutysky': 4669,
    'Teplyk': 4669,
    'Tomashpil’': 4669,
    'Torkanivka': 4669,
    'Tsybulevka': 4669,
    'Tulchyn': 4669,
    'Turbiv': 4669,
    'Tyvriv': 4669,
    'Ulaniv': 4669,
    'Vapnyarka': 4669,
    'Vendychany': 4669,
    'Vinnitskiy Rayon': 4669,
    'Vinnytsia': 4669,
    'Viytivka': 4669,
    'Voronovytsya': 4669,
    'Yampil’': 4669,
    'Zhmerynka': 4669,
    'Berestechko': 4690,
    'Blahodatne': 4690,
    'Hołoby': 4690,
    'Horokhiv': 4690,
    'Kamin-Kashyrskyi': 4690,
    'Kivertsi': 4690,
    'Kovel': 4690,
    'Kovel’s’ka Mis’krada': 4690,
    'Liuboml': 4690,
    'Lokachi': 4690,
    'Lukiv': 4690,
    'Lutsk': 4690,
    'Lyubeshivs’kyy Rayon': 4690,
    'Lyuboml’s’kyy Rayon': 4690,
    'Manevychi': 4690,
    'Manevyts’kyy Rayon': 4690,
    'Novovolyns’k': 4690,
    'Nuyno': 4690,
    'Olyka': 4690,
    'Pishcha': 4690,
    'Rakiv Lis': 4690,
    'Ratne': 4690,
    'Ratnivs’kyy Rayon': 4690,
    'Rozhyshche': 4690,
    'Shats’k': 4690,
    'Shats’kyy Rayon': 4690,
    'Stara Vyzhivka': 4690,
    'Svityaz’': 4690,
    'Volodymyr-Volynskyi': 4690,
    'Batiovo': 4670,
    'Berehivs’ka Mis’krada': 4670,
    'Berehove': 4670,
    'Berehove Raion': 4670,
    'Bereznyky': 4670,
    'Berezovo': 4670,
    'Bilky': 4670,
    'Bobovo': 4670,
    'Bohdan': 4670,
    'Bushtyno': 4670,
    'Chop': 4670,
    'Chynadiyovo': 4670,
    'Dovhe': 4670,
    'Drahovo': 4670,
    'Dubove': 4670,
    'Horinchovo': 4670,
    'Irshava': 4670,
    'Irshava Raion': 4670,
    'Iza': 4670,
    'Kalyny': 4670,
    'Kamianytsia': 4670,
    'Khust': 4670,
    'Khust Raion': 4670,
    'Khusts’ka Mis’krada': 4670,
    'Kolochava': 4670,
    'Kopashnovo': 4670,
    'Korolevo': 4670,
    'Koson’': 4670,
    'Kryva': 4670,
    'Kushnytsya': 4670,
    'Kvasy': 4670,
    'Lazeshchyna': 4670,
    'Lazi': 4670,
    'Lopukhiv': 4670,
    'Lypcha': 4670,
    'Lysychovo': 4670,
    'Mizhhirya': 4670,
    'Mizhhirya Raion': 4670,
    'Mukacheve': 4670,
    'Mukachevo Raion': 4670,
    'Muzhiyevo': 4670,
    'Mynay': 4670,
    'Nelipyno': 4670,
    'Neresnytsya': 4670,
    'Nevyts’ke': 4670,
    'Nove Davydkovo': 4670,
    'Nyzhnye Selyshche': 4670,
    'Oleshnyk': 4670,
    'Onokivtsi': 4670,
    'Perechyn': 4670,
    'Perechyn Raion': 4670,
    'Pidvynohradiv': 4670,
    'Poroshkovo': 4670,
    'Pylypets': 4670,
    'Rakhiv': 4670,
    'Rakhiv Raion': 4670,
    'Rakoshyno': 4670,
    'Rokosovo': 4670,
    'Serednye': 4670,
    'Serednye Vodyane': 4670,
    'Solotvyno': 4670,
    'Storozhnytsya': 4670,
    'Strabychovo': 4670,
    'Svaliava Raion': 4670,
    'Svalyava': 4670,
    'Synevyr': 4670,
    'Synevyrska Poliana': 4670,
    'Tiachiv Raion': 4670,
    'Tyachiv': 4670,
    'Uzhgorod': 4670,
    'Uzhhorod Raion': 4670,
    'Vary': 4670,
    'Velyki Berehy': 4670,
    'Velykyi Bereznyi': 4670,
    'Velykyi Bereznyi Raion': 4670,
    'Volovets': 4670,
    'Volovets Raion': 4670,
    'Vylok': 4670,
    'Vynohradiv': 4670,
    'Vynohradiv Raion': 4670,
    'Vyshkovo': 4670,
    'Yasinya': 4670,
    'Balky': 4687,
    'Berdiansk Raion': 4687,
    'Berdyans’ka Mis’krada': 4687,
    'Berdyansk': 4687,
    'Chapayevka': 4687,
    'Chernihivka Raion': 4687,
    'Dniprorudne': 4687,
    'Dniprovka': 4687,
    'Energodar': 4687,
    'Hulyaypole': 4687,
    'Kirove': 4687,
    'Komysh-Zorya': 4687,
    'Komyshuvakha': 4687,
    'Kostyantynivka': 4687,
    'Kushuhum': 4687,
    'Kuybysheve': 4687,
    'Kyrylivka': 4687,
    'Mala Bilozerka': 4687,
    'Mala Tokmachka': 4687,
    'Malokaterynivka': 4687,
    'Matviyivka': 4687,
    'Melitopol': 4687,
    'Melitopol’s’kyy Rayon': 4687,
    'Mykhaylivka': 4687,
    'Mykhaylivs’kyy Rayon': 4687,
    'Novobohdanivka': 4687,
    'Novomykolayivka': 4687,
    'Novovasylivka': 4687,
    'Orikhiv': 4687,
    'Osypenko': 4687,
    'Polohy': 4687,
    'Preobrazhenka': 4687,
    'Pryazovske Raion': 4687,
    'Prymors’k': 4687,
    'Prymorsk Raion': 4687,
    'Pryshyb': 4687,
    'Rozivka': 4687,
    'Rozivs’kyy Rayon': 4687,
    'Ternuvate': 4687,
    'Terpinnya': 4687,
    'Tokmak': 4687,
    'Tokmak Raion': 4687,
    'Tymoshivka': 4687,
    'Vasylivka': 4687,
    'Velyka Bilozerka': 4687,
    'Velykobilozers’kyy Rayon': 4687,
    'Vesele': 4687,
    'Vilniansk Raion': 4687,
    'Vodyane': 4687,
    'Voznesenka': 4687,
    'Yakymivka': 4687,
    'Yakymivka Raion': 4687,
    'Zaliznychne': 4687,
    'Zaporiz’ka Mis’krada': 4687,
    'Zaporizhia': 4687,
    'Andrushivka': 4668,
    'Andrushivs’kyy Rayon': 4668,
    'Baranivka': 4668,
    'Berdychiv': 4668,
    'Berdychivskyy Rayon': 4668,
    'Bila Krynytsya': 4668,
    'Brusyliv': 4668,
    'Brusylivs’kyy Rayon': 4668,
    'Bykivka': 4668,
    'Cherniakhiv': 4668,
    'Chernyakhivskyy Rayon': 4668,
    'Chervone': 4668,
    'Chopovychi': 4668,
    'Chornorudka': 4668,
    'Chudniv': 4668,
    'Chudniv Raion': 4668,
    'Denyshi': 4668,
    'Dovbysh': 4668,
    'Holovyne': 4668,
    'Horodnytsya': 4668,
    'Horodok': 4668,
    'Hryshkivtsi': 4668,
    'Khoroshiv Raion': 4668,
    'Kornyn': 4668,
    'Korosten’': 4668,
    'Korostyshiv': 4668,
    'Korostyshiv Raion': 4668,
    'Liubar': 4668,
    'Luhyny': 4668,
    'Luhyny Raion': 4668,
    'Malyn': 4668,
    'Malyn Raion': 4668,
    'Narodychi': 4668,
    'Narodychi Raion': 4668,
    'Nova Borova': 4668,
    'Novi Bilokorovychi': 4668,
    'Novohrad-Volynskyi': 4668,
    'Olevs’k': 4668,
    'Ovruch': 4668,
    'Ovruch Raion': 4668,
    'Ozerne': 4668,
    'Pershotravneve': 4668,
    'Polyanka': 4668,
    'Popilnia': 4668,
    'Popilnia Raion': 4668,
    'Pulyny Raion': 4668,
    'Radomyshl': 4668,
    'Radomyshl Raion': 4668,
    'Romaniv': 4668,
    'Ruzhyn': 4668,
    'Stavyshche': 4668,
    'Topory': 4668,
    'Yemil’chyne': 4668,
    'Zhytomyr': 4668,
    'Zhytomyr Raion': 4668,
    'Abu Dhabi Island and Internal Islands City': 3396,
    'Abu Dhabi Municipality': 3396,
    'Al Ain City': 3396,
    'Al Ain Municipality': 3396,
    'Al Dhafra': 3396,
    'Al Shamkhah City': 3396,
    'Ar Ruways': 3396,
    'Bani Yas City': 3396,
    'Khalifah A City': 3396,
    'Musaffah': 3396,
    'Muzayri‘': 3396,
    'Zayed City': 3396,
    'Ajman': 3395,
    'Ajman City': 3395,
    'Manama': 3395,
    'Masfout': 3395,
    'Dubai': 3391,
    'Al Fujairah City': 3393,
    'Al Fujairah Municipality': 3393,
    'Dibba Al Fujairah Municipality': 3393,
    'Dibba Al-Fujairah': 3393,
    'Dibba Al-Hisn': 3393,
    'Reef Al Fujairah City': 3393,
    'Ras Al Khaimah': 3394,
    'Ras Al Khaimah City': 3394,
    'Adh Dhayd': 3390,
    'Al Batayih': 3390,
    'Al Hamriyah': 3390,
    'Al Madam': 3390,
    'Dhaid': 3390,
    'Dibba Al Hesn': 3390,
    'Kalba': 3390,
    'Khawr Fakkān': 3390,
    'Khor Fakkan': 3390,
    'Milehah': 3390,
    'Murbaḩ': 3390,
    'Sharjah': 3390,
    'Umm AL Quwain': 3392,
    'Umm Al Quwain City': 3392,
    'Abbey Wood': 2336,
    'Abbots Bromley': 2336,
    'Abbots Langley': 2336,
    'Abbotskerswell': 2336,
    'Abbotts Ann': 2336,
    'Aberford': 2336,
    'Abingdon': 2336,
    'Abram': 2336,
    'Abridge': 2336,
    'Accrington': 2336,
    'Acklington': 2336,
    'Acle': 2336,
    'Acocks Green': 2336,
    'Acomb': 2336,
    'Acton': 2336,
    'Adderbury': 2336,
    'Addingham': 2336,
    'Addlestone': 2336,
    'Adlington': 2336,
    'Adwick le Street': 2336,
    'Airmyn': 2336,
    'Albrighton': 2336,
    'Alcester': 2336,
    'Alconbury': 2336,
    'Aldbourne': 2336,
    'Aldbrough': 2336,
    'Aldeburgh': 2336,
    'Alderbury': 2336,
    'Alderholt': 2336,
    'Alderley Edge': 2336,
    'Aldershot': 2336,
    'Aldford': 2336,
    'Aldridge': 2336,
    'Alford': 2336,
    'Alfreton': 2336,
    'Allhallows': 2336,
    'Almondsbury': 2336,
    'Alnwick': 2336,
    'Alresford': 2336,
    'Alrewas': 2336,
    'Alsager': 2336,
    'Alston': 2336,
    'Althorne': 2336,
    'Alton': 2336,
    'Altrincham': 2336,
    'Alvechurch': 2336,
    'Alveley': 2336,
    'Alveston': 2336,
    'Amble': 2336,
    'Ambleside': 2336,
    'Ambrosden': 2336,
    'Amersham': 2336,
    'Amersham on the Hill': 2336,
    'Amesbury': 2336,
    'Ampthill': 2336,
    'Ancaster': 2336,
    'Andover': 2336,
    'Anna Valley': 2336,
    'Annfield Plain': 2336,
    'Anstey': 2336,
    'Appleby': 2336,
    'Appleby-in-Westmorland': 2336,
    'Appledore': 2336,
    'Appleton Thorn': 2336,
    'Appley Bridge': 2336,
    'Archway': 2336,
    'Ardingly': 2336,
    'Arlesey': 2336,
    'Armitage': 2336,
    'Armthorpe': 2336,
    'Arnold': 2336,
    'Arnside': 2336,
    'Arundel': 2336,
    'Ascot': 2336,
    'Asfordby': 2336,
    'Ash': 2336,
    'Ashbourne': 2336,
    'Ashburton': 2336,
    'Ashby de la Zouch': 2336,
    'Ashford': 2336,
    'Ashill': 2336,
    'Ashington': 2336,
    'Ashtead': 2336,
    'Ashton in Makerfield': 2336,
    'Ashton Keynes': 2336,
    'Ashton-under-Lyne': 2336,
    'Ashurst': 2336,
    'Ashwell': 2336,
    'Askam in Furness': 2336,
    'Askern': 2336,
    'Aslockton': 2336,
    'Aspatria': 2336,
    'Aston': 2336,
    'Aston Clinton': 2336,
    'Aston-on-Trent': 2336,
    'Astwood Bank': 2336,
    'Atherstone': 2336,
    'Atherton': 2336,
    'Attleborough': 2336,
    'Atworth': 2336,
    'Auckley': 2336,
    'Audlem': 2336,
    'Audley': 2336,
    'Aveley': 2336,
    'Avening': 2336,
    'Awsworth': 2336,
    'Axbridge': 2336,
    'Axminster': 2336,
    'Aylesbury': 2336,
    'Aylesford': 2336,
    'Aylesham': 2336,
    'Aylsham': 2336,
    'Babworth': 2336,
    'Backworth': 2336,
    'Bacton': 2336,
    'Bacup': 2336,
    'Badsey': 2336,
    'Badsworth': 2336,
    'Bagshot': 2336,
    'Bagworth': 2336,
    'Baildon': 2336,
    'Bakewell': 2336,
    'Balcombe': 2336,
    'Baldock': 2336,
    'Balsall Common': 2336,
    'Balsham': 2336,
    'Bamburgh': 2336,
    'Bampton': 2336,
    'Banbury': 2336,
    'Banham': 2336,
    'Banks': 2336,
    'Banstead': 2336,
    'Banwell': 2336,
    'Bar Hill': 2336,
    'Barbican': 2336,
    'Bardney': 2336,
    'Bardsey': 2336,
    'Barham': 2336,
    'Barking': 2336,
    'Barkisland': 2336,
    'Barlaston': 2336,
    'Barlborough': 2336,
    'Barlby': 2336,
    'Barlestone': 2336,
    'Barmby on the Marsh': 2336,
    'Barnack': 2336,
    'Barnard Castle': 2336,
    'Barnburgh': 2336,
    'Barnet': 2336,
    'Barnetby le Wold': 2336,
    'Barnham': 2336,
    'Barnoldswick': 2336,
    'Barnsbury': 2336,
    'Barnsley': 2336,
    'Barnstaple': 2336,
    'Barnt Green': 2336,
    'Barnwood': 2336,
    'Barrow in Furness': 2336,
    'Barrow upon Humber': 2336,
    'Barrow upon Soar': 2336,
    'Barrowby': 2336,
    'Barrowford': 2336,
    'Bartley Green': 2336,
    'Barton under Needwood': 2336,
    'Barton upon Humber': 2336,
    'Barton-le-Clay': 2336,
    'Baschurch': 2336,
    'Basford': 2336,
    'Basildon': 2336,
    'Basingstoke': 2336,
    'Baslow': 2336,
    'Bassingbourn': 2336,
    'Bassingham': 2336,
    'Baston': 2336,
    'Bath': 2336,
    'Bath and North East Somerset': 2336,
    'Batley': 2336,
    'Battersea': 2336,
    'Battle': 2336,
    'Bawtry': 2336,
    'Bay Horse': 2336,
    'Bayston Hill': 2336,
    'Bayswater': 2336,
    'Beaconsfield': 2336,
    'Beadnell': 2336,
    'Beaminster': 2336,
    'Bebington': 2336,
    'Beccles': 2336,
    'Beckenham': 2336,
    'Beckingham': 2336,
    'Becontree': 2336,
    'Bedale': 2336,
    'Bedford': 2336,
    'Bedlington': 2336,
    'Bedworth': 2336,
    'Beeford': 2336,
    'Beighton': 2336,
    'Belbroughton': 2336,
    'Belford': 2336,
    'Bellingham': 2336,
    'Belmont': 2336,
    'Belper': 2336,
    'Belsize Park': 2336,
    'Belton': 2336,
    'Belvedere': 2336,
    'Bembridge': 2336,
    'Bempton': 2336,
    'Benson': 2336,
    'Bentley': 2336,
    'Benwell': 2336,
    'Bere Alston': 2336,
    'Bere Regis': 2336,
    'Berkeley': 2336,
    'Berkhamsted': 2336,
    'Berwick-Upon-Tweed': 2336,
    'Bethnal Green': 2336,
    'Betley': 2336,
    'Beverley': 2336,
    'Bewbush': 2336,
    'Bewdley': 2336,
    'Bexhill-on-Sea': 2336,
    'Bexley': 2336,
    'Bicester': 2336,
    'Bicknacre': 2336,
    'Bicton': 2336,
    'Biddenden': 2336,
    'Biddestone': 2336,
    'Biddulph': 2336,
    'Bideford': 2336,
    'Bidford-on-Avon': 2336,
    'Biggin Hill': 2336,
    'Biggleswade': 2336,
    'Bildeston': 2336,
    'Billericay': 2336,
    'Billingborough': 2336,
    'Billinge': 2336,
    'Billingham': 2336,
    'Billinghay': 2336,
    'Billingshurst': 2336,
    'Billington': 2336,
    'Bilsdale': 2336,
    'Bilsthorpe': 2336,
    'Bilston': 2336,
    'Bingham': 2336,
    'Bingley': 2336,
    'Birchington-on-Sea': 2336,
    'Bircotes': 2336,
    'Birdham': 2336,
    'Birdwell': 2336,
    'Birkenhead': 2336,
    'Birmingham': 2336,
    'Bishop Auckland': 2336,
    'Bishop Middleham': 2336,
    'Bishop Sutton': 2336,
    'Bishop  s Castle': 2336,
    'Bishops Cleeve': 2336,
    'Bishops Lydeard': 2336,
    'Bishops Stortford': 2336,
    'Bishops Waltham': 2336,
    'Bishopsteignton': 2336,
    'Bishopstoke': 2336,
    'Bishopstone': 2336,
    'Bishopsworth': 2336,
    'Bishopthorpe': 2336,
    'Bishopton': 2336,
    'Bitton': 2336,
    'Blaby': 2336,
    'Black Notley': 2336,
    'Blackburn': 2336,
    'Blackburn with Darwen': 2336,
    'Blackheath': 2336,
    'Blackley': 2336,
    'Blackmoorfoot': 2336,
    'Blackpool': 2336,
    'Blackrod': 2336,
    'Blackwell': 2336,
    'Blacon': 2336,
    'Blagdon': 2336,
    'Blandford Forum': 2336,
    'Blaydon-on-Tyne': 2336,
    'Bleadon': 2336,
    'Blean': 2336,
    'Bletchingley': 2336,
    'Bletchley': 2336,
    'Blewbury': 2336,
    'Blidworth': 2336,
    'Blindley Heath': 2336,
    'Blisworth': 2336,
    'Blockley': 2336,
    'Bloxham': 2336,
    'Bloxwich': 2336,
    'Blunham': 2336,
    'Bluntisham': 2336,
    'Blyth': 2336,
    'Blyton': 2336,
    'Bodle Street': 2336,
    'Bodmin': 2336,
    'Bognor Regis': 2336,
    'Bollington': 2336,
    'Bolsover': 2336,
    'Bolton': 2336,
    'Bolton le Sands': 2336,
    'Bolton upon Dearne': 2336,
    'Boosbeck': 2336,
    'Bootle': 2336,
    'Bordon': 2336,
    'Boreham': 2336,
    'Borehamwood': 2336,
    'Borough Green': 2336,
    'Borough of Bolton': 2336,
    'Borough of Bury': 2336,
    'Borough of Halton': 2336,
    'Borough of North Tyneside': 2336,
    'Borough of Oldham': 2336,
    'Borough of Rochdale': 2336,
    'Borough of Stockport': 2336,
    'Borough of Swindon': 2336,
    'Borough of Tameside': 2336,
    'Borough of Thurrock': 2336,
    'Borough of Torbay': 2336,
    'Borough of Wigan': 2336,
    'Boroughbridge': 2336,
    'Borrowash': 2336,
    'Bosham': 2336,
    'Boston': 2336,
    'Boston Spa': 2336,
    'Botesdale': 2336,
    'Botley': 2336,
    'Bottesford': 2336,
    'Bottisham': 2336,
    'Boughton': 2336,
    'Bourne': 2336,
    'Bourne End': 2336,
    'Bournemouth': 2336,
    'Bourton': 2336,
    'Bourton on the Water': 2336,
    'Bovey Tracey': 2336,
    'Bovingdon': 2336,
    'Bovington Camp': 2336,
    'Bow': 2336,
    'Bow Brickhill': 2336,
    'Bowburn': 2336,
    'Bowdon': 2336,
    'Bowthorpe': 2336,
    'Box': 2336,
    'Boxgrove': 2336,
    'Boxted': 2336,
    'Bozeat': 2336,
    'Bracebridge Heath': 2336,
    'Brackley': 2336,
    'Bracknell': 2336,
    'Bracknell Forest': 2336,
    'Bradfield': 2336,
    'Bradford': 2336,
    'Bradford-on-Avon': 2336,
    'Brading': 2336,
    'Bradley Cross': 2336,
    'Bradninch': 2336,
    'Bradwell': 2336,
    'Braintree': 2336,
    'Bramford': 2336,
    'Bramhall': 2336,
    'Bramham': 2336,
    'Bramhope': 2336,
    'Bramley': 2336,
    'Brampton': 2336,
    'Brandesburton': 2336,
    'Brandon': 2336,
    'Bransgore': 2336,
    'Branston': 2336,
    'Bratton': 2336,
    'Braunston': 2336,
    'Braunton': 2336,
    'Brayton': 2336,
    'Bream': 2336,
    'Bredbury': 2336,
    'Bredon': 2336,
    'Brenchley': 2336,
    'Brent': 2336,
    'Brent Knoll': 2336,
    'Brentford': 2336,
    'Brentwood': 2336,
    'Brewood': 2336,
    'Bridge': 2336,
    'Bridgnorth': 2336,
    'Bridgwater': 2336,
    'Bridlington': 2336,
    'Bridport': 2336,
    'Brierfield': 2336,
    'Brierley Hill': 2336,
    'Brigg': 2336,
    'Brighouse': 2336,
    'Brighstone': 2336,
    'Brightlingsea': 2336,
    'Brighton': 2336,
    'Brighton and Hove': 2336,
    'Brigstock': 2336,
    'Brill': 2336,
    'Brimscombe': 2336,
    'Brinklow': 2336,
    'Brinscall': 2336,
    'Bristol': 2336,
    'Briston': 2336,
    'Brixham': 2336,
    'Brixton': 2336,
    'Brixton Hill': 2336,
    'Brixworth': 2336,
    'Broad Blunsdon': 2336,
    'Broadfield': 2336,
    'Broadstairs': 2336,
    'Broadstone': 2336,
    'Broadwater': 2336,
    'Broadway': 2336,
    'Brockenhurst': 2336,
    'Brockley': 2336,
    'Bromborough': 2336,
    'Bromham': 2336,
    'Brompton': 2336,
    'Bromsgrove': 2336,
    'Bromyard': 2336,
    'Brooke': 2336,
    'Broseley': 2336,
    'Brotton': 2336,
    'Brough': 2336,
    'Broughton': 2336,
    'Broughton Astley': 2336,
    'Brownhills': 2336,
    'Broxbourne': 2336,
    'Brundall': 2336,
    'Bruton': 2336,
    'Bubwith': 2336,
    'Buckden': 2336,
    'Buckfastleigh': 2336,
    'Buckhurst Hill': 2336,
    'Buckingham': 2336,
    'Buckinghamshire': 2336,
    'Bucknell': 2336,
    'Bude': 2336,
    'Budleigh Salterton': 2336,
    'Bugbrooke': 2336,
    'Bugle': 2336,
    'Bulford': 2336,
    'Bulkington': 2336,
    'Bulphan': 2336,
    'Bunbury': 2336,
    'Bungay': 2336,
    'Buntingford': 2336,
    'Burbage': 2336,
    'Bures Saint Mary': 2336,
    'Burford': 2336,
    'Burgess Hill': 2336,
    'Burgh le Marsh': 2336,
    'Burham': 2336,
    'Burley': 2336,
    'Burley in Wharfedale': 2336,
    'Burnage': 2336,
    'Burneside': 2336,
    'Burngreave': 2336,
    'Burnham-on-Crouch': 2336,
    'Burnham-on-Sea': 2336,
    'Burniston': 2336,
    'Burnley': 2336,
    'Burnopfield': 2336,
    'Burntwood': 2336,
    'Burringham': 2336,
    'Burrington': 2336,
    'Burscough': 2336,
    'Bursledon': 2336,
    'Burstwick': 2336,
    'Burton': 2336,
    'Burton Joyce': 2336,
    'Burton Latimer': 2336,
    'Burton on the Wolds': 2336,
    'Burton Pidsea': 2336,
    'Burton upon Stather': 2336,
    'Burton upon Trent': 2336,
    'Burtonwood': 2336,
    'Burwash': 2336,
    'Burwell': 2336,
    'Bury': 2336,
    'Bury St Edmunds': 2336,
    'Bushey': 2336,
    'Butterwick': 2336,
    'Buxted': 2336,
    'Buxton': 2336,
    'Byfield': 2336,
    'Byram': 2336,
    'Caddington': 2336,
    'Cadnam': 2336,
    'Caister-on-Sea': 2336,
    'Caistor': 2336,
    'Calcot': 2336,
    'Calderdale': 2336,
    'Callington': 2336,
    'Calne': 2336,
    'Calverton': 2336,
    'Camber': 2336,
    'Camberley': 2336,
    'Camblesforth': 2336,
    'Camborne': 2336,
    'Cambourne': 2336,
    'Cambridge': 2336,
    'Cambridgeshire': 2336,
    'Camden Town': 2336,
    'Cameley': 2336,
    'Camelford': 2336,
    'Campsall': 2336,
    'Canary Wharf': 2336,
    'Canewdon': 2336,
    'Canford Heath': 2336,
    'Cannock': 2336,
    'Canterbury': 2336,
    'Canvey Island': 2336,
    'Capel': 2336,
    'Capel le Ferne': 2336,
    'Capel Saint Mary': 2336,
    'Carcroft': 2336,
    'Cardington': 2336,
    'Carlisle': 2336,
    'Carlton': 2336,
    'Carnforth': 2336,
    'Carshalton': 2336,
    'Carterton': 2336,
    'Castle Cary': 2336,
    'Castle Donington': 2336,
    'Castle Hedingham': 2336,
    'Castle Vale': 2336,
    'Castleford': 2336,
    'Castleside': 2336,
    'Castor': 2336,
    'Catcliffe': 2336,
    'Caterham': 2336,
    'Caton': 2336,
    'Catterall': 2336,
    'Catterick': 2336,
    'Catterick Garrison': 2336,
    'Cawood': 2336,
    'Cawston': 2336,
    'Cawthorne': 2336,
    'Caythorpe': 2336,
    'Central Bedfordshire': 2336,
    'Chacewater': 2336,
    'Chadwell Heath': 2336,
    'Chadwell St Mary': 2336,
    'Chafford Hundred': 2336,
    'Chagford': 2336,
    'Chalfont Saint Peter': 2336,
    'Chalfont St Giles': 2336,
    'Chalford': 2336,
    'Chalgrove': 2336,
    'Chalton': 2336,
    'Chapel Allerton': 2336,
    'Chapel en le Frith': 2336,
    'Chapel Saint Leonards': 2336,
    'Chapeltown': 2336,
    'Chapmanslade': 2336,
    'Chard': 2336,
    'Charfield': 2336,
    'Charing': 2336,
    'Charlbury': 2336,
    'Charlton Kings': 2336,
    'Charlton Marshall': 2336,
    'Charminster': 2336,
    'Charmouth': 2336,
    'Chartham': 2336,
    'Charvil': 2336,
    'Chasetown': 2336,
    'Chatburn': 2336,
    'Chatham': 2336,
    'Chatteris': 2336,
    'Cheadle': 2336,
    'Cheadle Heath': 2336,
    'Cheadle Hulme': 2336,
    'Cheam': 2336,
    'Cheddar': 2336,
    'Cheddington': 2336,
    'Cheddleton': 2336,
    'Cheetham Hill': 2336,
    'Chelford': 2336,
    'Chelmsford': 2336,
    'Chelmsley Wood': 2336,
    'Chelsea': 2336,
    'Cheltenham': 2336,
    'Cherry Burton': 2336,
    'Chertsey': 2336,
    'Chesham': 2336,
    'Cheshire East': 2336,
    'Cheshire West and Chester': 2336,
    'Cheshunt': 2336,
    'Chessington': 2336,
    'Chester': 2336,
    'Chester-le-Street': 2336,
    'Chesterfield': 2336,
    'Chew Magna': 2336,
    'Chichester': 2336,
    'Chickerell': 2336,
    'Chicksands': 2336,
    'Chiddingfold': 2336,
    'Chigwell': 2336,
    'Chilcompton': 2336,
    'Child Okeford': 2336,
    'Chilton Foliat': 2336,
    'Chilworth': 2336,
    'Chinley': 2336,
    'Chinnor': 2336,
    'Chippenham': 2336,
    'Chipping Campden': 2336,
    'Chipping Norton': 2336,
    'Chipping Ongar': 2336,
    'Chipping Sodbury': 2336,
    'Chiseldon': 2336,
    'Chislehurst': 2336,
    'Chobham': 2336,
    'Cholsey': 2336,
    'Choppington': 2336,
    'Chopwell': 2336,
    'Chorley': 2336,
    'Chorleywood': 2336,
    'Chorlton': 2336,
    'Chorlton cum Hardy': 2336,
    'Christchurch': 2336,
    'Chudleigh': 2336,
    'Chudleigh Knighton': 2336,
    'Chulmleigh': 2336,
    'Church': 2336,
    'Church Fenton': 2336,
    'Church Stretton': 2336,
    'Churchdown': 2336,
    'Churchill': 2336,
    'Churt': 2336,
    'Cinderford': 2336,
    'Cirencester': 2336,
    'City and Borough of Birmingham': 2336,
    'City and Borough of Leeds': 2336,
    'City and Borough of Salford': 2336,
    'City and Borough of Wakefield': 2336,
    'City of Bristol': 2336,
    'City of Kingston upon Hull': 2336,
    'City of Leicester': 2336,
    'City of London': 2336,
    'City of Westminster': 2336,
    'City of York': 2336,
    'Clacton-on-Sea': 2336,
    'Clapham': 2336,
    'Clarborough': 2336,
    'Clare': 2336,
    'Claydon': 2336,
    'Claypole': 2336,
    'Clayton le Moors': 2336,
    'Clayton West': 2336,
    'Clayton-le-Woods': 2336,
    'Cleator Moor': 2336,
    'Cleckheaton': 2336,
    'Cleethorpes': 2336,
    'Clenchwarton': 2336,
    'Cleobury Mortimer': 2336,
    'Clerkenwell': 2336,
    'Clevedon': 2336,
    'Cleveleys': 2336,
    'Cliffe': 2336,
    'Clifton': 2336,
    'Clitheroe': 2336,
    'Clive': 2336,
    'Clophill': 2336,
    'Clowne': 2336,
    'Clutton': 2336,
    'Coalville': 2336,
    'Coates': 2336,
    'Cobham': 2336,
    'Cockermouth': 2336,
    'Cockfield': 2336,
    'Cockington': 2336,
    'Codicote': 2336,
    'Codsall': 2336,
    'Cogenhoe': 2336,
    'Coggeshall': 2336,
    'Colchester': 2336,
    'Cold Ash': 2336,
    'Cold Norton': 2336,
    'Colden Common': 2336,
    'Coleford': 2336,
    'Colerne': 2336,
    'Collier Row': 2336,
    'Collingbourne Kingston': 2336,
    'Collingham': 2336,
    'Colnbrook': 2336,
    'Colne': 2336,
    'Colsterworth': 2336,
    'Coltishall': 2336,
    'Colwich': 2336,
    'Colyton': 2336,
    'Combe Martin': 2336,
    'Comberton': 2336,
    'Compton': 2336,
    'Compton Martin': 2336,
    'Congleton': 2336,
    'Congresbury': 2336,
    'Coningsby': 2336,
    'Conisbrough': 2336,
    'Consett': 2336,
    'Cookham': 2336,
    'Cookley': 2336,
    'Cople': 2336,
    'Copmanthorpe': 2336,
    'Copplestone': 2336,
    'Coppull': 2336,
    'Copthorne': 2336,
    'Corbridge': 2336,
    'Corby': 2336,
    'Corby Glen': 2336,
    'Corfe Castle': 2336,
    'Cornholme': 2336,
    'Cornwall': 2336,
    'Corse': 2336,
    'Corsham': 2336,
    'Corston': 2336,
    'Cosby': 2336,
    'Cosham': 2336,
    'Costessey': 2336,
    'Cotgrave': 2336,
    'Cottenham': 2336,
    'Cottesmore': 2336,
    'Cottingham': 2336,
    'Coulsdon': 2336,
    'Coundon': 2336,
    'Countesthorpe': 2336,
    'County Durham': 2336,
    'Coven': 2336,
    'Coventry': 2336,
    'Cowbit': 2336,
    'Cowes': 2336,
    'Cowfold': 2336,
    'Cowley': 2336,
    'Cowplain': 2336,
    'Coxhoe': 2336,
    'Cradley Heath': 2336,
    'Cramlington': 2336,
    'Cranbrook': 2336,
    'Cranfield': 2336,
    'Cranham': 2336,
    'Cranleigh': 2336,
    'Cranwell': 2336,
    'Craven Arms': 2336,
    'Crawley': 2336,
    'Crawley Down': 2336,
    'Credenhill': 2336,
    'Crediton': 2336,
    'Creech Saint Michael': 2336,
    'Creswell': 2336,
    'Crewe': 2336,
    'Crewkerne': 2336,
    'Crick': 2336,
    'Cricklade': 2336,
    'Cringleford': 2336,
    'Croft': 2336,
    'Crofton': 2336,
    'Cromer': 2336,
    'Cromford': 2336,
    'Crondall': 2336,
    'Crook': 2336,
    'Cropwell Bishop': 2336,
    'Crosby': 2336,
    'Cross Hills': 2336,
    'Croston': 2336,
    'Crouch End': 2336,
    'Crowborough': 2336,
    'Crowland': 2336,
    'Crowle': 2336,
    'Crowthorne': 2336,
    'Croydon': 2336,
    'Crumpsall': 2336,
    'Cuckfield': 2336,
    'Cuddington': 2336,
    'Cudworth': 2336,
    'Cuffley': 2336,
    'Culcheth': 2336,
    'Cullingworth': 2336,
    'Cullompton': 2336,
    'Culverstone Green': 2336,
    'Cumbria': 2336,
    'Curdworth': 2336,
    'Curry Rivel': 2336,
    'Cuxton': 2336,
    'Dagenham': 2336,
    'Dalston': 2336,
    'Dalton in Furness': 2336,
    'Danbury': 2336,
    'Danby': 2336,
    'Darenth': 2336,
    'Daresbury': 2336,
    'Darfield': 2336,
    'Darlaston': 2336,
    'Darlington': 2336,
    'Darras Hall': 2336,
    'Darrington': 2336,
    'Dartford': 2336,
    'Dartmouth': 2336,
    'Darton': 2336,
    'Darwen': 2336,
    'Datchet': 2336,
    'Datchworth': 2336,
    'Daventry': 2336,
    'Dawlish': 2336,
    'Deal': 2336,
    'Deanshanger': 2336,
    'Dearham': 2336,
    'Debenham': 2336,
    'Deddington': 2336,
    'Dedham': 2336,
    'Delabole': 2336,
    'Delph': 2336,
    'Denby Dale': 2336,
    'Denham': 2336,
    'Denholme': 2336,
    'Denmead': 2336,
    'Denton': 2336,
    'Denton Holme': 2336,
    'Derby': 2336,
    'Derbyshire': 2336,
    'Dersingham': 2336,
    'Desborough': 2336,
    'Desford': 2336,
    'Devizes': 2336,
    'Devon': 2336,
    'Dewsbury': 2336,
    'Dickens Heath': 2336,
    'Dickleburgh': 2336,
    'Didcot': 2336,
    'Didsbury': 2336,
    'Diggle': 2336,
    'Dinnington': 2336,
    'Dinton': 2336,
    'Disley': 2336,
    'Diss': 2336,
    'Distington': 2336,
    'District of Rutland': 2336,
    'Ditchingham': 2336,
    'Ditchling': 2336,
    'Ditton Hill': 2336,
    'Dobwalls': 2336,
    'Doddington': 2336,
    'Dodworth': 2336,
    'Doncaster': 2336,
    'Donington': 2336,
    'Donisthorpe': 2336,
    'Donnington': 2336,
    'Dorchester': 2336,
    'Dorking': 2336,
    'Dormansland': 2336,
    'Dorridge': 2336,
    'Dorset': 2336,
    'Dorstone': 2336,
    'Dove Holes': 2336,
    'Dover': 2336,
    'Dovercourt': 2336,
    'Doveridge': 2336,
    'Downham Market': 2336,
    'Downton': 2336,
    'Draycott': 2336,
    'Driffield': 2336,
    'Droitwich': 2336,
    'Dronfield': 2336,
    'Droylsden': 2336,
    'Drybrook': 2336,
    'Ducklington': 2336,
    'Dudley': 2336,
    'Duffield': 2336,
    'Dukinfield': 2336,
    'Dulverton': 2336,
    'Dunchurch': 2336,
    'Dundry': 2336,
    'Dunholme': 2336,
    'Dunkeswell': 2336,
    'Dunnington': 2336,
    'Dunstable': 2336,
    'Dunswell': 2336,
    'Durham': 2336,
    'Dursley': 2336,
    'Duxford': 2336,
    'Dymchurch': 2336,
    'Eaglescliffe': 2336,
    'Earby': 2336,
    'Earith': 2336,
    'Earl Shilton': 2336,
    'Earls Barton': 2336,
    'Earls Colne': 2336,
    'Earlsfield': 2336,
    'Easington': 2336,
    'Easingwold': 2336,
    'East Ayton': 2336,
    'East Bergholt': 2336,
    'East Boldon': 2336,
    'East Bridgford': 2336,
    'East Chevington': 2336,
    'East Cowes': 2336,
    'East Dean': 2336,
    'East Dereham': 2336,
    'East Grinstead': 2336,
    'East Hanney': 2336,
    'East Harling': 2336,
    'East Harptree': 2336,
    'East Horsley': 2336,
    'East Keswick': 2336,
    'East Leake': 2336,
    'East Markham': 2336,
    'East Molesey': 2336,
    'East Peckham': 2336,
    'East Rainton': 2336,
    'East Riding of Yorkshire': 2336,
    'East Sussex': 2336,
    'East Tilbury': 2336,
    'East Wittering': 2336,
    'Eastbourne': 2336,
    'Eastchurch': 2336,
    'Eastington': 2336,
    'Eastleigh': 2336,
    'Eastoft': 2336,
    'Easton': 2336,
    'Easton on the Hill': 2336,
    'Easton-in-Gordano': 2336,
    'Eastrington': 2336,
    'Eastry': 2336,
    'Eastwood': 2336,
    'Eaton Bray': 2336,
    'Eaton Socon': 2336,
    'Eattington': 2336,
    'Eccles': 2336,
    'Eccleshall': 2336,
    'Eccleston': 2336,
    'Eckington': 2336,
    'Edenbridge': 2336,
    'Edenfield': 2336,
    'Edgmond': 2336,
    'Edgware': 2336,
    'Edgworth': 2336,
    'Edington': 2336,
    'Edith Weston': 2336,
    'Edwinstowe': 2336,
    'Egham': 2336,
    'Egremont': 2336,
    'Eight Ash Green': 2336,
    'Elland': 2336,
    'Ellerker': 2336,
    'Ellerton': 2336,
    'Ellesmere': 2336,
    'Ellesmere Port': 2336,
    'Elm Park': 2336,
    'Elmstead Market': 2336,
    'Elmswell': 2336,
    'Elsenham': 2336,
    'Elstead': 2336,
    'Elstree': 2336,
    'Elswick': 2336,
    'Elvington': 2336,
    'Elwick': 2336,
    'Ely': 2336,
    'Emberton': 2336,
    'Embleton': 2336,
    'Embsay': 2336,
    'Emley': 2336,
    'Emneth': 2336,
    'Empingham': 2336,
    'Emsworth': 2336,
    'Enderby': 2336,
    'Enfield Town': 2336,
    'Epping': 2336,
    'Epsom': 2336,
    'Epworth': 2336,
    'Erith': 2336,
    'Esher': 2336,
    'Essendine': 2336,
    'Essex': 2336,
    'Essington': 2336,
    'Eton': 2336,
    'Eton Wick': 2336,
    'Etton': 2336,
    'Etwall': 2336,
    'Euxton': 2336,
    'Evenwood': 2336,
    'Evercreech': 2336,
    'Eversholt': 2336,
    'Eversley': 2336,
    'Everton': 2336,
    'Evesham': 2336,
    'Ewell': 2336,
    'Ewhurst': 2336,
    'Ewyas Harold': 2336,
    'Exeter': 2336,
    'Exhall': 2336,
    'Exminster': 2336,
    'Exmouth': 2336,
    'Exning': 2336,
    'Exton': 2336,
    'Eye': 2336,
    'Eynsford': 2336,
    'Eynsham': 2336,
    'Eythorne': 2336,
    'Failsworth': 2336,
    'Fairford': 2336,
    'Fairlands': 2336,
    'Fairlight': 2336,
    'Fakenham': 2336,
    'Fallowfield': 2336,
    'Falmouth': 2336,
    'Fareham': 2336,
    'Faringdon': 2336,
    'Farnborough': 2336,
    'Farndon': 2336,
    'Farnham': 2336,
    'Farnham Royal': 2336,
    'Farnsfield': 2336,
    'Farnworth': 2336,
    'Faversham': 2336,
    'Fazeley': 2336,
    'Featherstone': 2336,
    'Felixstowe': 2336,
    'Felling': 2336,
    'Feltham': 2336,
    'Felton': 2336,
    'Feltwell': 2336,
    'Fenstanton': 2336,
    'Ferndown': 2336,
    'Fernhill Heath': 2336,
    'Fernhurst': 2336,
    'Ferrybridge': 2336,
    'Ferryhill': 2336,
    'Filey': 2336,
    'Finchampstead': 2336,
    'Findern': 2336,
    'Findon': 2336,
    'Finedon': 2336,
    'Finningley': 2336,
    'Fishburn': 2336,
    'Fishtoft': 2336,
    'Fitzwilliam': 2336,
    'Five Oak Green': 2336,
    'Flamborough': 2336,
    'Fleckney': 2336,
    'Fleet': 2336,
    'Fleetwood': 2336,
    'Flexbury': 2336,
    'Flimby': 2336,
    'Flimwell': 2336,
    'Flitwick': 2336,
    'Flockton': 2336,
    'Flookburgh': 2336,
    'Flore': 2336,
    'Folkestone': 2336,
    'Fontwell': 2336,
    'Ford': 2336,
    'Fordham': 2336,
    'Fordingbridge': 2336,
    'Forest Row': 2336,
    'Formby': 2336,
    'Fortuneswell': 2336,
    'Foulridge': 2336,
    'Foulsham': 2336,
    'Four Lanes': 2336,
    'Four Marks': 2336,
    'Fowey': 2336,
    'Fowlmere': 2336,
    'Framlingham': 2336,
    'Frampton on Severn': 2336,
    'Freckleton': 2336,
    'Fremington': 2336,
    'Freshwater': 2336,
    'Frimley': 2336,
    'Frinton-on-Sea': 2336,
    'Friston': 2336,
    'Frizington': 2336,
    'Frodsham': 2336,
    'Frome': 2336,
    'Fulbourn': 2336,
    'Full Sutton': 2336,
    'Furnace Green': 2336,
    'Fylde': 2336,
    'Gainford': 2336,
    'Gainsborough': 2336,
    'Galgate': 2336,
    'Gamlingay': 2336,
    'Garforth': 2336,
    'Gargrave': 2336,
    'Garsington': 2336,
    'Garstang': 2336,
    'Gateshead': 2336,
    'Geddington': 2336,
    'Gedney Hill': 2336,
    'Germoe': 2336,
    'Gerrards Cross': 2336,
    'Gilberdyke': 2336,
    'Gillingham': 2336,
    'Girton': 2336,
    'Glapwell': 2336,
    'Glastonbury': 2336,
    'Glazebury': 2336,
    'Glemsford': 2336,
    'Glenfield': 2336,
    'Glinton': 2336,
    'Glossop': 2336,
    'Gloucester': 2336,
    'Gloucestershire': 2336,
    'Glusburn': 2336,
    'Gnosall': 2336,
    'Gobowen': 2336,
    'Godalming': 2336,
    'Godmanchester': 2336,
    'Godshill': 2336,
    'Godstone': 2336,
    'Golborne': 2336,
    'Goole': 2336,
    'Goosnargh': 2336,
    'Goostrey': 2336,
    'Goring': 2336,
    'Goring-by-Sea': 2336,
    'Gorleston-on-Sea': 2336,
    'Gosberton': 2336,
    'Gosfield': 2336,
    'Gosforth': 2336,
    'Gosport': 2336,
    'Gossops Green': 2336,
    'Gotham': 2336,
    'Goudhurst': 2336,
    'Goxhill': 2336,
    'Grain': 2336,
    'Grange Hill': 2336,
    'Grange-over-Sands': 2336,
    'Grantham': 2336,
    'Grappenhall': 2336,
    'Grassington': 2336,
    'Gravesend': 2336,
    'Grays': 2336,
    'Greasby': 2336,
    'Great Amwell': 2336,
    'Great Ayton': 2336,
    'Great Bardfield': 2336,
    'Great Barford': 2336,
    'Great Barton': 2336,
    'Great Bedwyn': 2336,
    'Great Bentley': 2336,
    'Great Bookham': 2336,
    'Great Chesterford': 2336,
    'Great Dunmow': 2336,
    'Great Eccleston': 2336,
    'Great Glen': 2336,
    'Great Gonerby': 2336,
    'Great Gransden': 2336,
    'Great Hanwood': 2336,
    'Great Harwood': 2336,
    'Great Haywood': 2336,
    'Great Horkesley': 2336,
    'Great Horwood': 2336,
    'Great Houghton': 2336,
    'Great Leighs': 2336,
    'Great Malvern': 2336,
    'Great Marton': 2336,
    'Great Missenden': 2336,
    'Great Paxton': 2336,
    'Great Sankey': 2336,
    'Great Torrington': 2336,
    'Great Wakering': 2336,
    'Great Waldingfield': 2336,
    'Great Wyrley': 2336,
    'Great Yarmouth': 2336,
    'Great Yeldham': 2336,
    'Greater London': 2336,
    'Greatham': 2336,
    'Greenfield': 2336,
    'Greenford': 2336,
    'Greenhill': 2336,
    'Greenhithe': 2336,
    'Greetham': 2336,
    'Grimethorpe': 2336,
    'Grimsby': 2336,
    'Grimston': 2336,
    'Griston': 2336,
    'Groombridge': 2336,
    'Grove': 2336,
    'Grundisburgh': 2336,
    'Guilden Sutton': 2336,
    'Guildford': 2336,
    'Guisborough': 2336,
    'Guiseley': 2336,
    'Gunness': 2336,
    'Gunnislake': 2336,
    'Hackleton': 2336,
    'Haddenham': 2336,
    'Hadleigh': 2336,
    'Hadley': 2336,
    'Hadley Wood': 2336,
    'Hadlow': 2336,
    'Hadston': 2336,
    'Hagley': 2336,
    'Hailsham': 2336,
    'Hainault': 2336,
    'Hale': 2336,
    'Halesowen': 2336,
    'Halesworth': 2336,
    'Halifax': 2336,
    'Halling': 2336,
    'Hallow': 2336,
    'Halstead': 2336,
    'Halton': 2336,
    'Haltwhistle': 2336,
    'Hamble-le-Rice': 2336,
    'Hambleton': 2336,
    'Hameldon Hill': 2336,
    'Hampshire': 2336,
    'Hampton': 2336,
    'Hampton in Arden': 2336,
    'Handcross': 2336,
    'Hannington': 2336,
    'Hanslope': 2336,
    'Hapton': 2336,
    'Harbury': 2336,
    'Hardingstone': 2336,
    'Hardwick Village': 2336,
    'Harefield': 2336,
    'Harleston': 2336,
    'Harlington': 2336,
    'Harlow': 2336,
    'Harold Wood': 2336,
    'Harpenden': 2336,
    'Harpole': 2336,
    'Harrietsham': 2336,
    'Harringay': 2336,
    'Harrogate': 2336,
    'Harrold': 2336,
    'Harrow on the Hill': 2336,
    'Harston': 2336,
    'Harthill': 2336,
    'Hartlebury': 2336,
    'Hartlepool': 2336,
    'Hartley': 2336,
    'Hartley Wintney': 2336,
    'Hartshill': 2336,
    'Hartwell': 2336,
    'Harvington': 2336,
    'Harwell': 2336,
    'Harwich': 2336,
    'Haslemere': 2336,
    'Haslingden': 2336,
    'Haslingfield': 2336,
    'Hassocks': 2336,
    'Hastings': 2336,
    'Haswell': 2336,
    'Hatfield': 2336,
    'Hatfield Heath': 2336,
    'Hatfield Peverel': 2336,
    'Hatherleigh': 2336,
    'Hathern': 2336,
    'Hathersage': 2336,
    'Hatton': 2336,
    'Haughley': 2336,
    'Haughton Green': 2336,
    'Havant': 2336,
    'Haverhill': 2336,
    'Haverigg': 2336,
    'Hawkhurst': 2336,
    'Hawkinge': 2336,
    'Haworth': 2336,
    'Hawthorn': 2336,
    'Haxby': 2336,
    'Haxey': 2336,
    'Haydock': 2336,
    'Haydon Bridge': 2336,
    'Hayes': 2336,
    'Hayfield': 2336,
    'Hayle': 2336,
    'Hayling Island': 2336,
    'Haynes': 2336,
    'Hayton': 2336,
    'Haywards Heath': 2336,
    'Hazel Grove': 2336,
    'Hazlerigg': 2336,
    'Heacham': 2336,
    'Headcorn': 2336,
    'Heage': 2336,
    'Healing': 2336,
    'Heanor': 2336,
    'Heath and Reach': 2336,
    'Heathfield': 2336,
    'Heaton Chapel': 2336,
    'Heavitree': 2336,
    'Hebburn': 2336,
    'Hebden Bridge': 2336,
    'Heckington': 2336,
    'Heckmondwike': 2336,
    'Heddon on the Wall': 2336,
    'Hedge End': 2336,
    'Hedon': 2336,
    'Heighington': 2336,
    'Hellaby': 2336,
    'Hellifield': 2336,
    'Helmsley': 2336,
    'Helpston': 2336,
    'Helsby': 2336,
    'Helston': 2336,
    'Hemel Hempstead': 2336,
    'Hemingbrough': 2336,
    'Hemingford Grey': 2336,
    'Hemsby': 2336,
    'Hemsworth': 2336,
    'Hemyock': 2336,
    'Henfield': 2336,
    'Henley in Arden': 2336,
    'Henley-on-Thames': 2336,
    'Henlow': 2336,
    'Henstridge': 2336,
    'Hereford': 2336,
    'Herefordshire': 2336,
    'Hermitage': 2336,
    'Herne Bay': 2336,
    'Herstmonceux': 2336,
    'Hertford': 2336,
    'Hertfordshire': 2336,
    'Heswall': 2336,
    'Hethersett': 2336,
    'Hetton-Le-Hole': 2336,
    'Hexham': 2336,
    'Heysham': 2336,
    'Heywood': 2336,
    'Hibaldstow': 2336,
    'High Barnet': 2336,
    'High Bentham': 2336,
    'High Etherley': 2336,
    'High Halden': 2336,
    'High Halstow': 2336,
    'High Legh': 2336,
    'High Ongar': 2336,
    'High Peak': 2336,
    'High Wycombe': 2336,
    'Higham Ferrers': 2336,
    'Highbridge': 2336,
    'Highclere': 2336,
    'Highley': 2336,
    'Hightown': 2336,
    'Highworth': 2336,
    'Hilton': 2336,
    'Hinchley Wood': 2336,
    'Hinckley': 2336,
    'Hindhead': 2336,
    'Hindley': 2336,
    'Hindon': 2336,
    'Hingham': 2336,
    'Hinton': 2336,
    'Histon': 2336,
    'Hitchin': 2336,
    'Hockley': 2336,
    'Hockley Heath': 2336,
    'Hockliffe': 2336,
    'Hockwold cum Wilton': 2336,
    'Hoddesdon': 2336,
    'Holbeach': 2336,
    'Holbeck': 2336,
    'Holbrook': 2336,
    'Hollingworth': 2336,
    'Holloway': 2336,
    'Hollym': 2336,
    'Holmes Chapel': 2336,
    'Holmfirth': 2336,
    'Holmwood': 2336,
    'Holsworthy': 2336,
    'Holt': 2336,
    'Holtby': 2336,
    'Holton le Clay': 2336,
    'Holywell Green': 2336,
    'Honeybourne': 2336,
    'Honiton': 2336,
    'Hoo': 2336,
    'Hook': 2336,
    'Hook Norton': 2336,
    'Hoole': 2336,
    'Hope Valley': 2336,
    'Hopton on Sea': 2336,
    'Horam': 2336,
    'Horbury': 2336,
    'Horley': 2336,
    'Horncastle': 2336,
    'Hornchurch': 2336,
    'Horndon on the Hill': 2336,
    'Horning': 2336,
    'Hornsea': 2336,
    'Horrabridge': 2336,
    'Horsford': 2336,
    'Horsforth': 2336,
    'Horsham': 2336,
    'Horsmonden': 2336,
    'Horsted Keynes': 2336,
    'Horton': 2336,
    'Horton Kirby': 2336,
    'Horwich': 2336,
    'Hotham': 2336,
    'Houghton': 2336,
    'Houghton Conquest': 2336,
    'Houghton on the Hill': 2336,
    'Houghton-Le-Spring': 2336,
    'Hounslow': 2336,
    'Hove': 2336,
    'Hoveton': 2336,
    'Howden': 2336,
    'Hoylake': 2336,
    'Hoyland Nether': 2336,
    'Hucknall': 2336,
    'Huddersfield': 2336,
    'Hugh Town': 2336,
    'Hulme': 2336,
    'Humberston': 2336,
    'Hungerford': 2336,
    'Hunmanby': 2336,
    'Hunstanton': 2336,
    'Huntingdon': 2336,
    'Huntley': 2336,
    'Huntspill': 2336,
    'Hunwick': 2336,
    'Hurst': 2336,
    'Hurstpierpoint': 2336,
    'Husbands Bosworth': 2336,
    'Husborne Crawley': 2336,
    'Huyton': 2336,
    'Hyde': 2336,
    'Hyde Heath': 2336,
    'Hythe': 2336,
    'Ibstock': 2336,
    'Ilchester': 2336,
    'Ilfracombe': 2336,
    'Ilkeston': 2336,
    'Ilkley': 2336,
    'Ilminster': 2336,
    'Immingham': 2336,
    'Ince Blundell': 2336,
    'Ince-in-Makerfield': 2336,
    'Ingatestone': 2336,
    'Ingleby Greenhow': 2336,
    'Ingleton': 2336,
    'Ingoldmells': 2336,
    'Ingrave': 2336,
    'Inkberrow': 2336,
    'Ipplepen': 2336,
    'Ipswich': 2336,
    'Irchester': 2336,
    'Irlam': 2336,
    'Ironbridge': 2336,
    'Irthlingborough': 2336,
    'Isle of Wight': 2336,
    'Isleham': 2336,
    'Isles of Scilly': 2336,
    'Isleworth': 2336,
    'Islington': 2336,
    'Iver': 2336,
    'Iver Heath': 2336,
    'Ivinghoe': 2336,
    'Ivybridge': 2336,
    'Iwade': 2336,
    'Ixworth': 2336,
    'Jarrow': 2336,
    'Keadby': 2336,
    'Kearsley': 2336,
    'Kedington': 2336,
    'Keelby': 2336,
    'Keele': 2336,
    'Kegworth': 2336,
    'Keighley': 2336,
    'Kelloe': 2336,
    'Kelsall': 2336,
    'Kelvedon': 2336,
    'Kelvedon Hatch': 2336,
    'Kempsey': 2336,
    'Kempston': 2336,
    'Kempston Hardwick': 2336,
    'Kemsing': 2336,
    'Kendal': 2336,
    'Kenilworth': 2336,
    'Kennington': 2336,
    'Kensworth': 2336,
    'Kent': 2336,
    'Kenton': 2336,
    'Keresley': 2336,
    'Kesgrave': 2336,
    'Kessingland': 2336,
    'Keswick': 2336,
    'Kettering': 2336,
    'Ketton': 2336,
    'Keyingham': 2336,
    'Keynsham': 2336,
    'Keyworth': 2336,
    'Kibworth Harcourt': 2336,
    'Kidderminster': 2336,
    'Kidlington': 2336,
    'Kidsgrove': 2336,
    'Kilburn': 2336,
    'Kilham': 2336,
    'Killamarsh': 2336,
    'Kilsby': 2336,
    'Kimberley': 2336,
    'Kimbolton': 2336,
    'Kimpton': 2336,
    'Kineton': 2336,
    'King  s Clipstone': 2336,
    'King  s Lynn': 2336,
    'Kings Hill': 2336,
    'Kings Langley': 2336,
    'Kings Sutton': 2336,
    'Kings Worthy': 2336,
    'Kingsbridge': 2336,
    'Kingsbury': 2336,
    'Kingsclere': 2336,
    'Kingskerswell': 2336,
    'Kingsland': 2336,
    'Kingsley': 2336,
    'Kingsteignton': 2336,
    'Kingston Bagpuize': 2336,
    'Kingston Seymour': 2336,
    'Kingston upon Hull': 2336,
    'Kingston upon Thames': 2336,
    'Kingswinford': 2336,
    'Kingswood': 2336,
    'Kington': 2336,
    'Kintbury': 2336,
    'Kinvere': 2336,
    'Kippax': 2336,
    'Kirby Muxloe': 2336,
    'Kirk Sandall': 2336,
    'Kirkburton': 2336,
    'Kirkby': 2336,
    'Kirkby in Ashfield': 2336,
    'Kirkby Lonsdale': 2336,
    'Kirkby Stephen': 2336,
    'Kirkbymoorside': 2336,
    'Kirkham': 2336,
    'Kirklees': 2336,
    'Kirton': 2336,
    'Kirton in Lindsey': 2336,
    'Kislingbury': 2336,
    'Kiveton Park': 2336,
    'Knaphill': 2336,
    'Knaresborough': 2336,
    'Knebworth': 2336,
    'Knottingley': 2336,
    'Knowle': 2336,
    'Knowsley': 2336,
    'Knutsford': 2336,
    'Laceby': 2336,
    'Lacock': 2336,
    'Lakenheath': 2336,
    'Lamberhurst': 2336,
    'Lambeth': 2336,
    'Lambourn': 2336,
    'Lamesley': 2336,
    'Lancashire': 2336,
    'Lancaster': 2336,
    'Lanchester': 2336,
    'Lancing': 2336,
    'Landrake': 2336,
    'Langford': 2336,
    'Langham': 2336,
    'Langho': 2336,
    'Langley Green': 2336,
    'Langley Park': 2336,
    'Langport': 2336,
    'Langtoft': 2336,
    'Lark Hill': 2336,
    'Larkfield': 2336,
    'Latchingdon and Snoreham': 2336,
    'Launceston': 2336,
    'Lavendon': 2336,
    'Lavenham': 2336,
    'Layer de la Haye': 2336,
    'Leasingham': 2336,
    'Leatherhead': 2336,
    'Lechlade': 2336,
    'Leconfield': 2336,
    'Ledbury': 2336,
    'Ledsham': 2336,
    'Lee-on-the-Solent': 2336,
    'Leeds': 2336,
    'Leek': 2336,
    'Leek Wootton': 2336,
    'Leicester': 2336,
    'Leicestershire': 2336,
    'Leigh': 2336,
    'Leighton Buzzard': 2336,
    'Leiston': 2336,
    'Lenham': 2336,
    'Leominster': 2336,
    'Lesbury': 2336,
    'Letchworth': 2336,
    'Letchworth Garden City': 2336,
    'Leven': 2336,
    'Lewes': 2336,
    'Leyburn': 2336,
    'Leyland': 2336,
    'Leysdown-on-Sea': 2336,
    'Lichfield': 2336,
    'Lidlington': 2336,
    'Lifton': 2336,
    'Lightwater': 2336,
    'Limpley Stoke': 2336,
    'Lincoln': 2336,
    'Lincolnshire': 2336,
    'Lingdale': 2336,
    'Lingfield': 2336,
    'Lingwood': 2336,
    'Linthwaite': 2336,
    'Linton': 2336,
    'Linton upon Ouse': 2336,
    'Liphook': 2336,
    'Liskeard': 2336,
    'Liss': 2336,
    'Litherland': 2336,
    'Little Amwell': 2336,
    'Little Chalfont': 2336,
    'Little Clacton': 2336,
    'Little Dunmow': 2336,
    'Little Eaton': 2336,
    'Little Hallingbury': 2336,
    'Little Hulton': 2336,
    'Little Lever': 2336,
    'Little Paxton': 2336,
    'Little Weighton': 2336,
    'Littleborough': 2336,
    'Littlebourne': 2336,
    'Littlehampton': 2336,
    'Littleport': 2336,
    'Liverpool': 2336,
    'Liversedge': 2336,
    'Locking': 2336,
    'Lockington': 2336,
    'Loddon': 2336,
    'Lofthouse': 2336,
    'Loftus': 2336,
    'London': 2336,
    'Long Ashton': 2336,
    'Long Bennington': 2336,
    'Long Buckby': 2336,
    'Long Clawson': 2336,
    'Long Crendon': 2336,
    'Long Eaton': 2336,
    'Long Itchington': 2336,
    'Long Lawford': 2336,
    'Long Melford': 2336,
    'Long Stratton': 2336,
    'Long Sutton': 2336,
    'Long Whatton': 2336,
    'Longdendale': 2336,
    'Longfield': 2336,
    'Longhope': 2336,
    'Longhorsley': 2336,
    'Longhoughton': 2336,
    'Longridge': 2336,
    'Longsight': 2336,
    'Longstanton': 2336,
    'Longton': 2336,
    'Longtown': 2336,
    'Longwick': 2336,
    'Looe': 2336,
    'Lostwithiel': 2336,
    'Loughborough': 2336,
    'Louth': 2336,
    'Low Ackworth': 2336,
    'Low Bradley': 2336,
    'Low Etherley': 2336,
    'Lowdham': 2336,
    'Lower Brailes': 2336,
    'Lower Broadheath': 2336,
    'Lower Earley': 2336,
    'Lower Halstow': 2336,
    'Lower Kingswood': 2336,
    'Lowestoft': 2336,
    'Lowick': 2336,
    'Luckington': 2336,
    'Luddenden Foot': 2336,
    'Ludgershall': 2336,
    'Ludlow': 2336,
    'Lugwardine': 2336,
    'Lund': 2336,
    'Luton': 2336,
    'Lutterworth': 2336,
    'Lydbrook': 2336,
    'Lydd': 2336,
    'Lydiard Millicent': 2336,
    'Lydney': 2336,
    'Lyme Regis': 2336,
    'Lyminge': 2336,
    'Lymington': 2336,
    'Lymm': 2336,
    'Lympne': 2336,
    'Lympstone': 2336,
    'Lyndhurst': 2336,
    'Lyneham': 2336,
    'Lynemouth': 2336,
    'Lynton': 2336,
    'Lytchett Matravers': 2336,
    'Lytham St Annes': 2336,
    'Mablethorpe': 2336,
    'Macclesfield': 2336,
    'Madeley': 2336,
    'Maghull': 2336,
    'Maiden Newton': 2336,
    'Maidenbower': 2336,
    'Maidenhead': 2336,
    'Maidstone': 2336,
    'Maldon': 2336,
    'Malmesbury': 2336,
    'Malpas': 2336,
    'Maltby': 2336,
    'Malton': 2336,
    'Manby': 2336,
    'Manchester': 2336,
    'Manea': 2336,
    'Mangotsfield': 2336,
    'Manningtree': 2336,
    'Mansfield': 2336,
    'Mansfield Woodhouse': 2336,
    'Manston': 2336,
    'Manton': 2336,
    'Maple Cross': 2336,
    'Marazion': 2336,
    'March': 2336,
    'Marcham': 2336,
    'Marchwood': 2336,
    'Marden': 2336,
    'Margate': 2336,
    'Market Bosworth': 2336,
    'Market Deeping': 2336,
    'Market Drayton': 2336,
    'Market Harborough': 2336,
    'Market Lavington': 2336,
    'Market Overton': 2336,
    'Market Rasen': 2336,
    'Market Warsop': 2336,
    'Market Weighton': 2336,
    'Markfield': 2336,
    'Marks Tey': 2336,
    'Markyate': 2336,
    'Marlborough': 2336,
    'Marldon': 2336,
    'Marlow': 2336,
    'Marnhull': 2336,
    'Marple': 2336,
    'Marr': 2336,
    'Marsden': 2336,
    'Marshfield': 2336,
    'Marske-by-the-Sea': 2336,
    'Marston Moretaine': 2336,
    'Martham': 2336,
    'Martock': 2336,
    'Maryport': 2336,
    'Masham': 2336,
    'Matlock': 2336,
    'Mattishall': 2336,
    'Maulden': 2336,
    'Mayfield': 2336,
    'Mayland': 2336,
    'Measham': 2336,
    'Medway': 2336,
    'Melbourn': 2336,
    'Melbourne': 2336,
    'Meldreth': 2336,
    'Melksham': 2336,
    'Meltham': 2336,
    'Meltham Mills': 2336,
    'Melton Mowbray': 2336,
    'Mendip': 2336,
    'Menston': 2336,
    'Meopham': 2336,
    'Meppershall': 2336,
    'Mere': 2336,
    'Meriden': 2336,
    'Merriott': 2336,
    'Messingham': 2336,
    'Metheringham': 2336,
    'Methley': 2336,
    'Metropolitan Borough of Wirral': 2336,
    'Mevagissey': 2336,
    'Mexborough': 2336,
    'Mickle Trafford': 2336,
    'Mickleton': 2336,
    'Middle Rasen': 2336,
    'Middle Winterslow': 2336,
    'Middlesbrough': 2336,
    'Middlestown': 2336,
    'Middleton': 2336,
    'Middlewich': 2336,
    'Midhurst': 2336,
    'Midsomer Norton': 2336,
    'Milborne Port': 2336,
    'Milborne St Andrew': 2336,
    'Mildenhall': 2336,
    'Milford': 2336,
    'Milford on Sea': 2336,
    'Millbrook': 2336,
    'Millom': 2336,
    'Milnrow': 2336,
    'Milnthorpe': 2336,
    'Milton Keynes': 2336,
    'Milverton': 2336,
    'Minchinhampton': 2336,
    'Minehead': 2336,
    'Minety': 2336,
    'Minster Lovell': 2336,
    'Minsterley': 2336,
    'Mirfield': 2336,
    'Misterton': 2336,
    'Mistley': 2336,
    'Mitcham': 2336,
    'Mitcheldean': 2336,
    'Mobberley': 2336,
    'Modbury': 2336,
    'Moira': 2336,
    'Mollington': 2336,
    'Monk Fryston': 2336,
    'Morchard Bishop': 2336,
    'Morcott': 2336,
    'Morden': 2336,
    'Morecambe': 2336,
    'Moreton': 2336,
    'Moreton in Marsh': 2336,
    'Moretonhampstead': 2336,
    'Morley': 2336,
    'Morpeth': 2336,
    'Moss': 2336,
    'Mossley': 2336,
    'Motcombe': 2336,
    'Mouldsworth': 2336,
    'Moulton Chapel': 2336,
    'Mount Hawke': 2336,
    'Mountsorrel': 2336,
    'Much Hadham': 2336,
    'Much Wenlock': 2336,
    'Mulbarton': 2336,
    'Mullion': 2336,
    'Mundesley': 2336,
    'Mundford': 2336,
    'Murton': 2336,
    'Mylor Bridge': 2336,
    'Mytholmroyd': 2336,
    'Nafferton': 2336,
    'Nailsea': 2336,
    'Nailsworth': 2336,
    'Nanpean': 2336,
    'Nantwich': 2336,
    'Narborough': 2336,
    'Navenby': 2336,
    'Necton': 2336,
    'Needham Market': 2336,
    'Needingworth': 2336,
    'Nelson': 2336,
    'Neston': 2336,
    'Nether Heyford': 2336,
    'Nether Poppleton': 2336,
    'Nether Stowey': 2336,
    'Netheravon': 2336,
    'Netherton': 2336,
    'Netley': 2336,
    'Nettleham': 2336,
    'New Alresford': 2336,
    'New Basford': 2336,
    'New Ferry': 2336,
    'New Malden': 2336,
    'New Marske': 2336,
    'New Mills': 2336,
    'New Milton': 2336,
    'New Romney': 2336,
    'Newark on Trent': 2336,
    'Newbiggin-by-the-Sea': 2336,
    'Newbold Verdon': 2336,
    'Newburn': 2336,
    'Newbury': 2336,
    'Newcastle under Lyme': 2336,
    'Newcastle upon Tyne': 2336,
    'Newchurch': 2336,
    'Newent': 2336,
    'Newhaven': 2336,
    'Newick': 2336,
    'Newington': 2336,
    'Newmarket': 2336,
    'Newport': 2336,
    'Newport Pagnell': 2336,
    'Newquay': 2336,
    'Newton Abbot': 2336,
    'Newton Aycliffe': 2336,
    'Newton Ferrers': 2336,
    'Newton Longville': 2336,
    'Newton Poppleford': 2336,
    'Newton-le-Willows': 2336,
    'Ninfield': 2336,
    'Niton': 2336,
    'Norfolk': 2336,
    'Normandy': 2336,
    'North Baddesley': 2336,
    'North Cave': 2336,
    'North Collingham': 2336,
    'North Duffield': 2336,
    'North East Lincolnshire': 2336,
    'North Elmham': 2336,
    'North Ferriby': 2336,
    'North Leigh': 2336,
    'North Lincolnshire': 2336,
    'North Luffenham': 2336,
    'North Newbald': 2336,
    'North Petherton': 2336,
    'North Shields': 2336,
    'North Somercotes': 2336,
    'North Somerset': 2336,
    'North Sunderland': 2336,
    'North Tawton': 2336,
    'North Thoresby': 2336,
    'North Walsham': 2336,
    'North Yorkshire': 2336,
    'Northallerton': 2336,
    'Northam': 2336,
    'Northampton': 2336,
    'Northamptonshire': 2336,
    'Northborough': 2336,
    'Northiam': 2336,
    'Northleach': 2336,
    'Northolt': 2336,
    'Northorpe': 2336,
    'Northumberland': 2336,
    'Northwich': 2336,
    'Norton': 2336,
    'Norton Canes': 2336,
    'Norwich': 2336,
    'Nottingham': 2336,
    'Nottinghamshire': 2336,
    'Notton': 2336,
    'Nuneaton': 2336,
    'Oadby': 2336,
    'Oakengates': 2336,
    'Oakham': 2336,
    'Oakington': 2336,
    'Oakley': 2336,
    'Odiham': 2336,
    'Okehampton': 2336,
    'Old Basing': 2336,
    'Old Harlow': 2336,
    'Old Leake': 2336,
    'Old Windsor': 2336,
    'Oldbury': 2336,
    'Oldham': 2336,
    'Olney': 2336,
    'Olveston': 2336,
    'Orleton': 2336,
    'Ormesby St Margaret': 2336,
    'Ormskirk': 2336,
    'Orpington': 2336,
    'Orsett': 2336,
    'Orwell': 2336,
    'Ossett': 2336,
    'Oswestry': 2336,
    'Otford': 2336,
    'Otley': 2336,
    'Otterburn': 2336,
    'Ottershaw': 2336,
    'Ottery St Mary': 2336,
    'Ottringham': 2336,
    'Oughtibridge': 2336,
    'Oundle': 2336,
    'Outwell': 2336,
    'Over': 2336,
    'Overcombe': 2336,
    'Overstrand': 2336,
    'Overton': 2336,
    'Owston Ferry': 2336,
    'Oxenhope': 2336,
    'Oxford': 2336,
    'Oxfordshire': 2336,
    'Oxted': 2336,
    'Paddock Wood': 2336,
    'Padiham': 2336,
    'Padstow': 2336,
    'Paignton': 2336,
    'Painswick': 2336,
    'Pangbourne': 2336,
    'Pannal': 2336,
    'Pant': 2336,
    'Papworth Everard': 2336,
    'Par': 2336,
    'Parbold': 2336,
    'Parkstone': 2336,
    'Partington': 2336,
    'Partridge Green': 2336,
    'Pateley Bridge': 2336,
    'Patrington': 2336,
    'Pattingham': 2336,
    'Paulton': 2336,
    'Peacehaven': 2336,
    'Peasedown Saint John': 2336,
    'Peaslake': 2336,
    'Peckham': 2336,
    'Pegswood': 2336,
    'Pelsall': 2336,
    'Pelton': 2336,
    'Pembury': 2336,
    'Penistone': 2336,
    'Penkridge': 2336,
    'Penrith': 2336,
    'Penryn': 2336,
    'Pensilva': 2336,
    'Penzance': 2336,
    'Perranporth': 2336,
    'Perranwell': 2336,
    'Pershore': 2336,
    'Peterborough': 2336,
    'Peterlee': 2336,
    'Petersfield': 2336,
    'Petworth': 2336,
    'Pevensey': 2336,
    'Pevensey Bay': 2336,
    'Pewsey': 2336,
    'Pickering': 2336,
    'Pilning': 2336,
    'Pilsley': 2336,
    'Pimperne': 2336,
    'Pinchbeck': 2336,
    'Pinner': 2336,
    'Pinxton': 2336,
    'Pirton': 2336,
    'Pitsea': 2336,
    'Pitstone': 2336,
    'Plumpton Green': 2336,
    'Plymouth': 2336,
    'Plympton': 2336,
    'Plymstock': 2336,
    'Pocklington': 2336,
    'Podington': 2336,
    'Polesworth': 2336,
    'Pollington': 2336,
    'Polperro': 2336,
    'Polzeath': 2336,
    'Pontefract': 2336,
    'Ponteland': 2336,
    'Pontesbury': 2336,
    'Pool': 2336,
    'Poole': 2336,
    'Poringland': 2336,
    'Porlock': 2336,
    'Porthleven': 2336,
    'Portishead': 2336,
    'Portland': 2336,
    'Portscatho': 2336,
    'Portslade': 2336,
    'Portsmouth': 2336,
    'Potterne': 2336,
    'Potters Bar': 2336,
    'Potterspury': 2336,
    'Potton': 2336,
    'Poulton-le-Fylde': 2336,
    'Poynton': 2336,
    'Prees': 2336,
    'Preesall': 2336,
    'Prenton': 2336,
    'Prescot': 2336,
    'Prestbury': 2336,
    'Preston': 2336,
    'Prestwich': 2336,
    'Princes Risborough': 2336,
    'Princetown': 2336,
    'Priston': 2336,
    'Probus': 2336,
    'Prudhoe': 2336,
    'Publow': 2336,
    'Puckeridge': 2336,
    'Pucklechurch': 2336,
    'Puddletown': 2336,
    'Pudsey': 2336,
    'Pulborough': 2336,
    'Pulloxhill': 2336,
    'Purfleet': 2336,
    'Puriton': 2336,
    'Purley': 2336,
    'Purton': 2336,
    'Queenborough': 2336,
    'Queensbury': 2336,
    'Queniborough': 2336,
    'Quorndon': 2336,
    'Rackheath': 2336,
    'Radcliffe': 2336,
    'Radcliffe on Trent': 2336,
    'Radlett': 2336,
    'Radley': 2336,
    'Radstock': 2336,
    'Rainford': 2336,
    'Rainham': 2336,
    'Rainworth': 2336,
    'Ramsbottom': 2336,
    'Ramsbury': 2336,
    'Ramsey': 2336,
    'Ramsgate': 2336,
    'Ranskill': 2336,
    'Rastrick': 2336,
    'Ratby': 2336,
    'Raunds': 2336,
    'Ravenshead': 2336,
    'Ravenstone': 2336,
    'Rawcliffe': 2336,
    'Rawmarsh': 2336,
    'Rawtenstall': 2336,
    'Rayleigh': 2336,
    'Rayne': 2336,
    'Reading': 2336,
    'Redbourn': 2336,
    'Redbourne': 2336,
    'Redcar': 2336,
    'Redcar and Cleveland': 2336,
    'Redditch': 2336,
    'Redhill': 2336,
    'Redlynch': 2336,
    'Redruth': 2336,
    'Reedham': 2336,
    'Reepham': 2336,
    'Reigate': 2336,
    'Rendlesham': 2336,
    'Repton': 2336,
    'Retford': 2336,
    'Riccall': 2336,
    'Richmond': 2336,
    'Rickinghall': 2336,
    'Rickmansworth': 2336,
    'Ridgmont': 2336,
    'Rillington': 2336,
    'Ringmer': 2336,
    'Ringstead': 2336,
    'Ringwood': 2336,
    'Ripley': 2336,
    'Ripon': 2336,
    'Ripponden': 2336,
    'Riseley': 2336,
    'Rishton': 2336,
    'Roade': 2336,
    'Robertsbridge': 2336,
    'Rochdale': 2336,
    'Roche': 2336,
    'Rochester': 2336,
    'Rochford': 2336,
    'Rock': 2336,
    'Rode': 2336,
    'Rode Heath': 2336,
    'Romford': 2336,
    'Romney Marsh': 2336,
    'Romsey': 2336,
    'Romsley': 2336,
    'Roos': 2336,
    'Ross on Wye': 2336,
    'Rossendale': 2336,
    'Rothbury': 2336,
    'Rotherfield Peppard': 2336,
    'Rotherham': 2336,
    'Rothley': 2336,
    'Rothwell': 2336,
    'Rottingdean': 2336,
    'Rowde': 2336,
    'Rowhedge': 2336,
    'Rowlands Gill': 2336,
    'Rowley Regis': 2336,
    'Roxton': 2336,
    'Royal Borough of Windsor and Maidenhead': 2336,
    'Royal Leamington Spa': 2336,
    'Royal Tunbridge Wells': 2336,
    'Royal Wootton Bassett': 2336,
    'Roydon': 2336,
    'Royston': 2336,
    'Royton': 2336,
    'Ruardean': 2336,
    'Ruddington': 2336,
    'Rudgwick': 2336,
    'Rufford': 2336,
    'Rugby': 2336,
    'Rugeley': 2336,
    'Ruislip': 2336,
    'Runcorn': 2336,
    'Rushden': 2336,
    'Ruskington': 2336,
    'Rusthall': 2336,
    'Rustington': 2336,
    'Ruyton-XI-Towns': 2336,
    'Ryde': 2336,
    'Rye': 2336,
    'Ryhall': 2336,
    'Ryhill': 2336,
    'Ryhope': 2336,
    'Ryton': 2336,
    'Ryton on Dunsmore': 2336,
    'Sabden': 2336,
    'Sacriston': 2336,
    'Saffron Walden': 2336,
    'Saint Agnes': 2336,
    'Saint Bees': 2336,
    'Saint Columb Major': 2336,
    'Saint Dennis': 2336,
    'Saint Leonards-on-Sea': 2336,
    'Saint Neots': 2336,
    'Saint Osyth': 2336,
    'Saint Peters': 2336,
    'Saint Stephen': 2336,
    'Salcombe': 2336,
    'Sale': 2336,
    'Salford': 2336,
    'Salfords': 2336,
    'Salisbury': 2336,
    'Saltash': 2336,
    'Saltburn-by-the-Sea': 2336,
    'Saltford': 2336,
    'Sampford Peverell': 2336,
    'Sandbach': 2336,
    'Sandford': 2336,
    'Sandown': 2336,
    'Sandwell': 2336,
    'Sandwich': 2336,
    'Sandy': 2336,
    'Sapcote': 2336,
    'Saughall': 2336,
    'Sawbridgeworth': 2336,
    'Sawston': 2336,
    'Sawtry': 2336,
    'Saxilby': 2336,
    'Saxmundham': 2336,
    'Scalby': 2336,
    'Scarborough': 2336,
    'Scarcroft': 2336,
    'Scawby': 2336,
    'Scholes': 2336,
    'Scole': 2336,
    'Scorton': 2336,
    'Scotby': 2336,
    'Scotter': 2336,
    'Scunthorpe': 2336,
    'Seaford': 2336,
    'Seaham': 2336,
    'Seahouses': 2336,
    'Seascale': 2336,
    'Seaton': 2336,
    'Seaton Delaval': 2336,
    'Seaview': 2336,
    'Sedbergh': 2336,
    'Sedgefield': 2336,
    'Seend': 2336,
    'Seer Green': 2336,
    'Sefton': 2336,
    'Seghill': 2336,
    'Selby': 2336,
    'Selsey': 2336,
    'Send': 2336,
    'Settle': 2336,
    'Sevenoaks': 2336,
    'Severn Beach': 2336,
    'Shadoxhurst': 2336,
    'Shadwell': 2336,
    'Shaftesbury': 2336,
    'Shalbourne': 2336,
    'Shalfleet': 2336,
    'Shanklin': 2336,
    'Shap': 2336,
    'Sharlston': 2336,
    'Sharnbrook': 2336,
    'Sharpness': 2336,
    'Shaw': 2336,
    'Shawbury': 2336,
    'Sheerness': 2336,
    'Sheffield': 2336,
    'Shefford': 2336,
    'Shelley': 2336,
    'Shenley': 2336,
    'Shenstone': 2336,
    'Shepherdswell': 2336,
    'Shepley': 2336,
    'Shepperton': 2336,
    'Shepshed': 2336,
    'Shepton Mallet': 2336,
    'Sherborne': 2336,
    'Sherborne St John': 2336,
    'Sherburn': 2336,
    'Sherburn Hill': 2336,
    'Sherburn in Elmet': 2336,
    'Sheriff Hutton': 2336,
    'Sheringham': 2336,
    'Sherington': 2336,
    'Sherston': 2336,
    'Shevington': 2336,
    'Shifnal': 2336,
    'Shilbottle': 2336,
    'Shildon': 2336,
    'Shillingstone': 2336,
    'Shillington': 2336,
    'Shipdham': 2336,
    'Shipham': 2336,
    'Shipley': 2336,
    'Shipston on Stour': 2336,
    'Shipton under Wychwood': 2336,
    'Shirebrook': 2336,
    'Shiremoor': 2336,
    'Shirland': 2336,
    'Shirley': 2336,
    'Shoreham-by-Sea': 2336,
    'Shortlands': 2336,
    'Shotley Gate': 2336,
    'Shrewsbury': 2336,
    'Shrewton': 2336,
    'Shrivenham': 2336,
    'Shropshire': 2336,
    'Shurdington': 2336,
    'Sible Hedingham': 2336,
    'Sibsey': 2336,
    'Sidcup': 2336,
    'Sidmouth': 2336,
    'Sileby': 2336,
    'Silkstone': 2336,
    'Silloth': 2336,
    'Silsden': 2336,
    'Silsoe': 2336,
    'Silver End': 2336,
    'Silverdale': 2336,
    'Silverstone': 2336,
    'Silverton': 2336,
    'Sinfin': 2336,
    'Sissinghurst': 2336,
    'Sittingbourne': 2336,
    'Skegness': 2336,
    'Skellingthorpe': 2336,
    'Skelmersdale': 2336,
    'Skelton': 2336,
    'Skidby': 2336,
    'Skinningrove': 2336,
    'Skipsea': 2336,
    'Skipton': 2336,
    'Slaley': 2336,
    'Sleaford': 2336,
    'Sleights': 2336,
    'Slinfold': 2336,
    'Slough': 2336,
    'Smethwick': 2336,
    'Snaith': 2336,
    'Snettisham': 2336,
    'Snodland': 2336,
    'Soham': 2336,
    'Solihull': 2336,
    'Somerset': 2336,
    'Somersham': 2336,
    'Somerton': 2336,
    'Sonning Common': 2336,
    'South Benfleet': 2336,
    'South Brent': 2336,
    'South Cave': 2336,
    'South Cerney': 2336,
    'South Chailey': 2336,
    'South Collingham': 2336,
    'South Croydon': 2336,
    'South Elmsall': 2336,
    'South Gloucestershire': 2336,
    'South Harting': 2336,
    'South Hayling': 2336,
    'South Hetton': 2336,
    'South Littleton': 2336,
    'South Luffenham': 2336,
    'South Milford': 2336,
    'South Molton': 2336,
    'South Nutfield': 2336,
    'South Ockendon': 2336,
    'South Petherton': 2336,
    'South Shields': 2336,
    'South Tyneside': 2336,
    'South Wingfield': 2336,
    'South Witham': 2336,
    'Southall': 2336,
    'Southam': 2336,
    'Southampton': 2336,
    'Southchurch Village': 2336,
    'Southend-on-Sea': 2336,
    'Southery': 2336,
    'Southminster': 2336,
    'Southowram': 2336,
    'Southport': 2336,
    'Southsea': 2336,
    'Southwater': 2336,
    'Southwell': 2336,
    'Southwick': 2336,
    'Southwold': 2336,
    'Sowerby Bridge': 2336,
    'Spalding': 2336,
    'Speldhurst': 2336,
    'Spennymoor': 2336,
    'Spilsby': 2336,
    'Spixworth': 2336,
    'Spofforth': 2336,
    'Spratton': 2336,
    'Sproatley': 2336,
    'St Albans': 2336,
    'St Austell': 2336,
    'St Helens': 2336,
    'St Ives': 2336,
    'St Just': 2336,
    'St Leonards': 2336,
    'St Mary  s': 2336,
    'St Mary  s Bay': 2336,
    'St. Day': 2336,
    'St. Helens': 2336,
    'Stafford': 2336,
    'Staffordshire': 2336,
    'Stagsden': 2336,
    'Stainburn': 2336,
    'Staindrop': 2336,
    'Staines': 2336,
    'Stainforth': 2336,
    'Stakeford': 2336,
    'Stalbridge': 2336,
    'Stalham': 2336,
    'Stallingborough': 2336,
    'Stalybridge': 2336,
    'Stamford': 2336,
    'Stamford Bridge': 2336,
    'Stanbridge': 2336,
    'Standlake': 2336,
    'Standon': 2336,
    'Stanford in the Vale': 2336,
    'Stanford-le-Hope': 2336,
    'Stanhope': 2336,
    'Stanley': 2336,
    'Stanmore': 2336,
    'Stannington': 2336,
    'Stansted Mountfitchet': 2336,
    'Stanwick': 2336,
    'Staplehurst': 2336,
    'Starcross': 2336,
    'Startforth': 2336,
    'Staveley': 2336,
    'Steeple Bumpstead': 2336,
    'Steeple Claydon': 2336,
    'Steeton': 2336,
    'Stevenage': 2336,
    'Steventon': 2336,
    'Stevington': 2336,
    'Stewartby': 2336,
    'Stewkley': 2336,
    'Steyning': 2336,
    'Stickney': 2336,
    'Stillington': 2336,
    'Stilton': 2336,
    'Stithians': 2336,
    'Stock': 2336,
    'Stockport': 2336,
    'Stocksbridge': 2336,
    'Stocksfield': 2336,
    'Stockton': 2336,
    'Stockton Heath': 2336,
    'Stockton-on-Tees': 2336,
    'Stoke Ferry': 2336,
    'Stoke Gabriel': 2336,
    'Stoke Gifford': 2336,
    'Stoke Golding': 2336,
    'Stoke Goldington': 2336,
    'Stoke Poges': 2336,
    'Stoke Prior': 2336,
    'Stoke-on-Trent': 2336,
    'Stoke-sub-Hamdon': 2336,
    'Stokenchurch': 2336,
    'Stokesley': 2336,
    'Stone': 2336,
    'Stonehouse': 2336,
    'Stonesfield': 2336,
    'Stoney Stanton': 2336,
    'Storrington': 2336,
    'Stotfold': 2336,
    'Stourbridge': 2336,
    'Stourport-on-Severn': 2336,
    'Stow on the Wold': 2336,
    'Stowmarket': 2336,
    'Stradbroke': 2336,
    'Stratfield Mortimer': 2336,
    'Stratford-upon-Avon': 2336,
    'Stratton': 2336,
    'Streatley': 2336,
    'Street': 2336,
    'Streetly': 2336,
    'Strensall': 2336,
    'Stretford': 2336,
    'Stretham': 2336,
    'Strood': 2336,
    'Stroud': 2336,
    'Studley': 2336,
    'Sturminster Marshall': 2336,
    'Sturminster Newton': 2336,
    'Sturry': 2336,
    'Sudbrooke': 2336,
    'Sudbury': 2336,
    'Suffolk': 2336,
    'Sunbury-on-Thames': 2336,
    'Sunderland': 2336,
    'Sundridge': 2336,
    'Surbiton': 2336,
    'Surrey': 2336,
    'Sutterton': 2336,
    'Sutton': 2336,
    'Sutton Benger': 2336,
    'Sutton Bonington': 2336,
    'Sutton Bridge': 2336,
    'Sutton Coldfield': 2336,
    'Sutton Courtenay': 2336,
    'Sutton in Ashfield': 2336,
    'Sutton on Trent': 2336,
    'Sutton upon Derwent': 2336,
    'Swadlincote': 2336,
    'Swaffham': 2336,
    'Swanage': 2336,
    'Swanley': 2336,
    'Swanmore': 2336,
    'Swanscombe': 2336,
    'Swarthmoor': 2336,
    'Swavesey': 2336,
    'Sway': 2336,
    'Swillington': 2336,
    'Swindon': 2336,
    'Swineshead': 2336,
    'Swinton': 2336,
    'Syston': 2336,
    'Sywell': 2336,
    'Tadcaster': 2336,
    'Tadley': 2336,
    'Tadworth': 2336,
    'Takeley': 2336,
    'Tamworth': 2336,
    'Tanfield': 2336,
    'Tangmere': 2336,
    'Tankerton': 2336,
    'Tansley': 2336,
    'Tarleton': 2336,
    'Tarporley': 2336,
    'Tarvin': 2336,
    'Tattenhall': 2336,
    'Taunton': 2336,
    'Tavistock': 2336,
    'Teddington': 2336,
    'Teignmouth': 2336,
    'Telford': 2336,
    'Telford and Wrekin': 2336,
    'Templecombe': 2336,
    'Tempsford': 2336,
    'Tenbury Wells': 2336,
    'Tenterden': 2336,
    'Terrington Saint John': 2336,
    'Terrington St Clement': 2336,
    'Tetbury': 2336,
    'Tetney': 2336,
    'Tewkesbury': 2336,
    'Teynham': 2336,
    'Thame': 2336,
    'Thames Ditton': 2336,
    'Thatcham': 2336,
    'Thaxted': 2336,
    'The Boldons': 2336,
    'Theale': 2336,
    'Thetford': 2336,
    'Theydon Bois': 2336,
    'Thirsk': 2336,
    'Thornaby-on-Tees': 2336,
    'Thornbury': 2336,
    'Thorne': 2336,
    'Thorner': 2336,
    'Thorney': 2336,
    'Thorngumbald': 2336,
    'Thornley': 2336,
    'Thornton Dale': 2336,
    'Thornton Heath': 2336,
    'Thornton-Cleveleys': 2336,
    'Thorp Arch': 2336,
    'Thorpe Hamlet': 2336,
    'Thorpe le Soken': 2336,
    'Thrapston': 2336,
    'Three Legged Cross': 2336,
    'Thurlby': 2336,
    'Thurlton': 2336,
    'Thurston': 2336,
    'Tibshelf': 2336,
    'Ticehurst': 2336,
    'Tickhill': 2336,
    'Tickton': 2336,
    'Tideswell': 2336,
    'Tidworth': 2336,
    'Tilbury': 2336,
    'Timperley': 2336,
    'Timsbury': 2336,
    'Tingewick': 2336,
    'Tintagel': 2336,
    'Tipton': 2336,
    'Tiptree': 2336,
    'Tisbury': 2336,
    'Tiverton': 2336,
    'Toddington': 2336,
    'Todmorden': 2336,
    'Todwick': 2336,
    'Tollesbury': 2336,
    'Tolleshunt Knights': 2336,
    'Tonbridge': 2336,
    'Topsham': 2336,
    'Torpoint': 2336,
    'Torquay': 2336,
    'Totnes': 2336,
    'Totternhoe': 2336,
    'Tottington': 2336,
    'Totton': 2336,
    'Tow Law': 2336,
    'Towcester': 2336,
    'Town Row': 2336,
    'Trafford': 2336,
    'Trafford Park': 2336,
    'Treeton': 2336,
    'Trimdon': 2336,
    'Trimdon Grange': 2336,
    'Tring': 2336,
    'Trowbridge': 2336,
    'Truro': 2336,
    'Tunstall': 2336,
    'Tuxford': 2336,
    'Twyford': 2336,
    'Tyldesley': 2336,
    'Tynemouth': 2336,
    'Tytherington': 2336,
    'Uckfield': 2336,
    'Uffculme': 2336,
    'Ulceby': 2336,
    'Ulrome': 2336,
    'Ulverston': 2336,
    'Uny Lelant': 2336,
    'Upchurch': 2336,
    'Upminster': 2336,
    'Upper Basildon': 2336,
    'Upper Langwith': 2336,
    'Upper Poppleton': 2336,
    'Uppingham': 2336,
    'Upton': 2336,
    'Upton Scudamore': 2336,
    'Upton upon Severn': 2336,
    'Upwell': 2336,
    'Urmston': 2336,
    'Ushaw Moor': 2336,
    'Uttoxeter': 2336,
    'Ventnor': 2336,
    'Verwood': 2336,
    'Virginia Water': 2336,
    'Waddesdon': 2336,
    'Waddington': 2336,
    'Wadebridge': 2336,
    'Wadhurst': 2336,
    'Wadworth': 2336,
    'Wainfleet All Saints': 2336,
    'Wakefield': 2336,
    'Walberton': 2336,
    'Wales': 2336,
    'Walkden': 2336,
    'Walkern': 2336,
    'Walkington': 2336,
    'Wallasey': 2336,
    'Wallingford': 2336,
    'Wallsend': 2336,
    'Walsall': 2336,
    'Walsden': 2336,
    'Waltham': 2336,
    'Waltham Abbey': 2336,
    'Waltham Cross': 2336,
    'Walton': 2336,
    'Walton-on-Thames': 2336,
    'Walton-on-the-Naze': 2336,
    'Wanborough': 2336,
    'Wansford': 2336,
    'Wantage': 2336,
    'Warboys': 2336,
    'Wardle': 2336,
    'Ware': 2336,
    'Wareham': 2336,
    'Wargrave': 2336,
    'Warkworth': 2336,
    'Warlingham': 2336,
    'Warminster': 2336,
    'Warnham': 2336,
    'Warrington': 2336,
    'Warsop': 2336,
    'Warton': 2336,
    'Warwick': 2336,
    'Warwickshire': 2336,
    'Washingborough': 2336,
    'Washington': 2336,
    'Washwood Heath': 2336,
    'Watchet': 2336,
    'Water Eaton': 2336,
    'Water Orton': 2336,
    'Waterbeach': 2336,
    'Wateringbury': 2336,
    'Waterloo': 2336,
    'Waterlooville': 2336,
    'Watford': 2336,
    'Wath upon Dearne': 2336,
    'Watlington': 2336,
    'Wattisham': 2336,
    'Watton': 2336,
    'Watton at Stone': 2336,
    'Weaverham': 2336,
    'Wedmore': 2336,
    'Wednesbury': 2336,
    'Wednesfield': 2336,
    'Weedon Beck': 2336,
    'Weeting': 2336,
    'Welford': 2336,
    'Wellesbourne': 2336,
    'Wellesbourne Mountford': 2336,
    'Welling': 2336,
    'Wellingborough': 2336,
    'Wellington': 2336,
    'Wellow': 2336,
    'Wells': 2336,
    'Wells-next-the-Sea': 2336,
    'Welwyn': 2336,
    'Welwyn Garden City': 2336,
    'Wem': 2336,
    'Wembley': 2336,
    'Wembury': 2336,
    'Wendover': 2336,
    'Wentworth': 2336,
    'Weobley': 2336,
    'West Bergholt': 2336,
    'West Berkshire': 2336,
    'West Bridgford': 2336,
    'West Bromwich': 2336,
    'West Byfleet': 2336,
    'West Clandon': 2336,
    'West Coker': 2336,
    'West Cornforth': 2336,
    'West Drayton': 2336,
    'West End': 2336,
    'West End of London': 2336,
    'West Haddon': 2336,
    'West Hallam': 2336,
    'West Horsley': 2336,
    'West Ilsley': 2336,
    'West Kingsdown': 2336,
    'West Kirby': 2336,
    'West Malling': 2336,
    'West Mersea': 2336,
    'West Molesey': 2336,
    'West Rainton': 2336,
    'West Sussex': 2336,
    'West Thurrock': 2336,
    'West Walton': 2336,
    'West Wellow': 2336,
    'West Wickham': 2336,
    'Westbury': 2336,
    'Westcliff-on-Sea': 2336,
    'Westcott': 2336,
    'Westergate': 2336,
    'Westerham': 2336,
    'Westfield': 2336,
    'Westgate on Sea': 2336,
    'Westhoughton': 2336,
    'Weston': 2336,
    'Weston Turville': 2336,
    'Weston-super-Mare': 2336,
    'Westoning': 2336,
    'Westonzoyland': 2336,
    'Westwood': 2336,
    'Wetheral': 2336,
    'Wetherby': 2336,
    'Wetwang': 2336,
    'Weybridge': 2336,
    'Weymouth': 2336,
    'Whaley Bridge': 2336,
    'Whalley': 2336,
    'Whaplode': 2336,
    'Wheathampstead': 2336,
    'Wheatley': 2336,
    'Wheaton Aston': 2336,
    'Wheldrake': 2336,
    'Whickham': 2336,
    'Whimple': 2336,
    'Whitburn': 2336,
    'Whitby': 2336,
    'Whitchurch': 2336,
    'White Waltham': 2336,
    'Whitefield': 2336,
    'Whitehaven': 2336,
    'Whiteparish': 2336,
    'Whitley': 2336,
    'Whitley Bay': 2336,
    'Whitstable': 2336,
    'Whittingham': 2336,
    'Whittington': 2336,
    'Whittlesey': 2336,
    'Whittlesford': 2336,
    'Whitwell': 2336,
    'Whitworth': 2336,
    'Whyteleafe': 2336,
    'Wick': 2336,
    'Wickford': 2336,
    'Wickham': 2336,
    'Wickham Bishops': 2336,
    'Wickham Market': 2336,
    'Wickwar': 2336,
    'Wideopen': 2336,
    'Widnes': 2336,
    'Wigan': 2336,
    'Wigmore': 2336,
    'Wigston Magna': 2336,
    'Wigton': 2336,
    'Wilberfoss': 2336,
    'Wilburton': 2336,
    'Willand': 2336,
    'Willaston': 2336,
    'Willenhall': 2336,
    'Willingham': 2336,
    'Willington': 2336,
    'Williton': 2336,
    'Wilmcote': 2336,
    'Wilmslow': 2336,
    'Wilsden': 2336,
    'Wilstead': 2336,
    'Wilton': 2336,
    'Wiltshire': 2336,
    'Wimblington': 2336,
    'Wimborne Minster': 2336,
    'Wincanton': 2336,
    'Winchcombe': 2336,
    'Winchelsea Beach': 2336,
    'Winchester': 2336,
    'Windermere': 2336,
    'Windlesham': 2336,
    'Windsor': 2336,
    'Winford': 2336,
    'Wing': 2336,
    'Wingate': 2336,
    'Wingerworth': 2336,
    'Wingham': 2336,
    'Wingrave': 2336,
    'Winkleigh': 2336,
    'Winscombe': 2336,
    'Winsford': 2336,
    'Winslow': 2336,
    'Winterbourne': 2336,
    'Winterton': 2336,
    'Winwick': 2336,
    'Wirksworth': 2336,
    'Wisbech': 2336,
    'Witchford': 2336,
    'Witham': 2336,
    'Witheridge': 2336,
    'Withernsea': 2336,
    'Witley': 2336,
    'Witney': 2336,
    'Wittering': 2336,
    'Witton Gilbert': 2336,
    'Wiveliscombe': 2336,
    'Wivelsfield Green': 2336,
    'Wivenhoe': 2336,
    'Woburn': 2336,
    'Woburn Sands': 2336,
    'Woking': 2336,
    'Wokingham': 2336,
    'Wold Newton': 2336,
    'Woldingham': 2336,
    'Wollaston': 2336,
    'Wolsingham': 2336,
    'Wolston': 2336,
    'Wolvercote': 2336,
    'Wolverhampton': 2336,
    'Wombourn': 2336,
    'Wombwell': 2336,
    'Wood Street Village': 2336,
    'Woodborough': 2336,
    'Woodbridge': 2336,
    'Woodbury': 2336,
    'Woodchurch': 2336,
    'Woodcote': 2336,
    'Woodford': 2336,
    'Woodford Green': 2336,
    'Woodhall Spa': 2336,
    'Woodsetts': 2336,
    'Woodstock': 2336,
    'Wool': 2336,
    'Woolavington': 2336,
    'Wooler': 2336,
    'Woolley': 2336,
    'Woolpit': 2336,
    'Wootton': 2336,
    'Worcester': 2336,
    'Worcester Park': 2336,
    'Worcestershire': 2336,
    'Workington': 2336,
    'Worksop': 2336,
    'Worlaby': 2336,
    'Wormley': 2336,
    'Worthing': 2336,
    'Worton': 2336,
    'Wotton-under-Edge': 2336,
    'Wouldham': 2336,
    'Wragby': 2336,
    'Wrawby': 2336,
    'Wrea Green': 2336,
    'Wrington': 2336,
    'Writtle': 2336,
    'Wrotham': 2336,
    'Wroughton': 2336,
    'Wroxall': 2336,
    'Wychbold': 2336,
    'Wye': 2336,
    'Wylam': 2336,
    'Wymeswold': 2336,
    'Yalding': 2336,
    'Yapton': 2336,
    'Yarm': 2336,
    'Yarmouth': 2336,
    'Yarnton': 2336,
    'Yate': 2336,
    'Yateley': 2336,
    'Yatton': 2336,
    'Yaxley': 2336,
    'Yeadon': 2336,
    'Yealmpton': 2336,
    'Yelverton': 2336,
    'Yeovil': 2336,
    'Yetminster': 2336,
    'York': 2336,
    'Youlgreave': 2336,
    'Yoxall': 2336,
    'Ahoghill': 2337,
    'Annahilt': 2337,
    'Annalong': 2337,
    'Antrim': 2337,
    'Antrim and Newtownabbey': 2337,
    'Ardglass': 2337,
    'Ards and North Down': 2337,
    'Armagh': 2337,
    'Armagh City Banbridge and Craigavon': 2337,
    'Ballinamallard': 2337,
    'Ballintoy Harbour': 2337,
    'Ballycastle': 2337,
    'Ballyclare': 2337,
    'Ballygowan': 2337,
    'Ballykelly': 2337,
    'Ballymena': 2337,
    'Ballymoney': 2337,
    'Ballynahinch': 2337,
    'Ballypatrick': 2337,
    'Ballywalter': 2337,
    'Banbridge': 2337,
    'Bangor': 2337,
    'Belfast': 2337,
    'Bellaghy': 2337,
    'Broughshane': 2337,
    'Bushmills': 2337,
    'Carnlough': 2337,
    'Carnmoney': 2337,
    'Carrickfergus': 2337,
    'Carryduff': 2337,
    'Castledawson': 2337,
    'Castlederg': 2337,
    'Castlereagh': 2337,
    'Castlerock': 2337,
    'Castlewellan': 2337,
    'Causeway Coast and Glens': 2337,
    'City of Belfast': 2337,
    'Coalisland': 2337,
    'Coleraine': 2337,
    'Comber': 2337,
    'Connor': 2337,
    'Cookstown': 2337,
    'Craigavon': 2337,
    'Crossgar': 2337,
    'Crossmaglen': 2337,
    'Crumlin': 2337,
    'Cullybackey': 2337,
    'Culmore': 2337,
    'Cushendall': 2337,
    'Derry': 2337,
    'Derry City and Strabane': 2337,
    'Doagh': 2337,
    'Donaghadee': 2337,
    'Downpatrick': 2337,
    'Draperstown': 2337,
    'Dromore': 2337,
    'Dundonald': 2337,
    'Dundrum': 2337,
    'Dungannon': 2337,
    'Dungiven': 2337,
    'Dunloy': 2337,
    'Eglinton': 2337,
    'Enniskillen': 2337,
    'Fermanagh and Omagh': 2337,
    'Fintona': 2337,
    'Fivemiletown': 2337,
    'Garvagh': 2337,
    'Gilford': 2337,
    'Glenariff': 2337,
    'Glenavy': 2337,
    'Greenisland': 2337,
    'Greyabbey': 2337,
    'Hillsborough': 2337,
    'Holywood': 2337,
    'Irvinestown': 2337,
    'Jordanstown': 2337,
    'Keady': 2337,
    'Kilkeel': 2337,
    'Killyleagh': 2337,
    'Kilrea': 2337,
    'Kircubbin': 2337,
    'Larne': 2337,
    'Limavady': 2337,
    'Lisburn': 2337,
    'Lisburn and Castlereagh': 2337,
    'Lisnaskea': 2337,
    'Londonderry County Borough': 2337,
    'Maghera': 2337,
    'Magherafelt': 2337,
    'Magheralin': 2337,
    'Mid and East Antrim': 2337,
    'Mid Ulster': 2337,
    'Millisle': 2337,
    'Moira': 2337,
    'Moneymore': 2337,
    'Moy': 2337,
    'Newcastle': 2337,
    'Newry': 2337,
    'Newry Mourne and Down': 2337,
    'Newtownabbey': 2337,
    'Newtownards': 2337,
    'Newtownstewart': 2337,
    'Omagh': 2337,
    'Portadown': 2337,
    'Portaferry': 2337,
    'Portavogie': 2337,
    'Portglenone': 2337,
    'Portrush': 2337,
    'Portstewart': 2337,
    'Randalstown': 2337,
    'Rathfriland': 2337,
    'Rostrevor': 2337,
    'Saintfield': 2337,
    'Sion Mills': 2337,
    'Strabane': 2337,
    'Tandragee': 2337,
    'Templepatrick': 2337,
    'Waringstown': 2337,
    'Warrenpoint': 2337,
    'Whitehead': 2337,
    'Aberchirder': 2335,
    'Aberdeen': 2335,
    'Aberdeen City': 2335,
    'Aberdeenshire': 2335,
    'Aberdour': 2335,
    'Aberfeldy': 2335,
    'Aberlady': 2335,
    'Abernethy': 2335,
    'Aboyne': 2335,
    'Addiebrownhill': 2335,
    'Airdrie': 2335,
    'Airth': 2335,
    'Alexandria': 2335,
    'Alford': 2335,
    'Allanton': 2335,
    'Alloa': 2335,
    'Almondbank': 2335,
    'Alness': 2335,
    'Alva': 2335,
    'Alyth': 2335,
    'Angus': 2335,
    'Annan': 2335,
    'Anstruther': 2335,
    'Arbroath': 2335,
    'Ardersier': 2335,
    'Ardrishaig': 2335,
    'Ardrossan': 2335,
    'Argyll and Bute': 2335,
    'Armadale': 2335,
    'Ashgill': 2335,
    'Auchinleck': 2335,
    'Auchterarder': 2335,
    'Auchtermuchty': 2335,
    'Aviemore': 2335,
    'Ayr': 2335,
    'Balerno': 2335,
    'Balfron': 2335,
    'Balintore': 2335,
    'Ballater': 2335,
    'Ballingry': 2335,
    'Balloch': 2335,
    'Balmedie': 2335,
    'Balmullo': 2335,
    'Banchory': 2335,
    'Banff': 2335,
    'Bankfoot': 2335,
    'Banknock': 2335,
    'Bannockburn': 2335,
    'Bargeddie': 2335,
    'Barra': 2335,
    'Barrhead': 2335,
    'Bathgate': 2335,
    'Bearsden': 2335,
    'Beauly': 2335,
    'Beith': 2335,
    'Bellsbank': 2335,
    'Bellshill': 2335,
    'Benbecula': 2335,
    'Biggar': 2335,
    'Bilston': 2335,
    'Bishopbriggs': 2335,
    'Bishopton': 2335,
    'Blackburn': 2335,
    'Blackridge': 2335,
    'Blackwood': 2335,
    'Blairgowrie': 2335,
    'Blantyre': 2335,
    'Bo’ness': 2335,
    'Boddam': 2335,
    'Bonhill': 2335,
    'Bonnybridge': 2335,
    'Bonnyrigg': 2335,
    'Bothwell': 2335,
    'Brechin': 2335,
    'Bridge of Allan': 2335,
    'Bridge of Earn': 2335,
    'Bridge of Weir': 2335,
    'Brightons': 2335,
    'Brora': 2335,
    'Broxburn': 2335,
    'Buckhaven': 2335,
    'Buckie': 2335,
    'Burghead': 2335,
    'Burntisland': 2335,
    'Busby': 2335,
    'Cairneyhill': 2335,
    'Cairnryan': 2335,
    'Calderbank': 2335,
    'Caldercruix': 2335,
    'Callander': 2335,
    'Cambuslang': 2335,
    'Campbeltown': 2335,
    'Caol': 2335,
    'Cardenden': 2335,
    'Cardross': 2335,
    'Carfin': 2335,
    'Carluke': 2335,
    'Carmunnock': 2335,
    'Carnoustie': 2335,
    'Carnwath': 2335,
    'Carron': 2335,
    'Carronshore': 2335,
    'Castle Douglas': 2335,
    'Catrine': 2335,
    'Chapelhall': 2335,
    'Charlestown of Aberlour': 2335,
    'Chirnside': 2335,
    'Chryston': 2335,
    'City of Edinburgh': 2335,
    'Clackmannan': 2335,
    'Clackmannanshire': 2335,
    'Clarkston': 2335,
    'Cleland': 2335,
    'Clydebank': 2335,
    'Coalburn': 2335,
    'Coaltown of Balgonie': 2335,
    'Coatbridge': 2335,
    'Cockenzie': 2335,
    'Coldstream': 2335,
    'Colinton': 2335,
    'Comrie': 2335,
    'Conon Bridge': 2335,
    'Coupar Angus': 2335,
    'Cove': 2335,
    'Cowdenbeath': 2335,
    'Cowie': 2335,
    'Coylton': 2335,
    'Crail': 2335,
    'Crieff': 2335,
    'Crossford': 2335,
    'Crossgates': 2335,
    'Crosshouse': 2335,
    'Croy': 2335,
    'Cruden Bay': 2335,
    'Cullen': 2335,
    'Culloden': 2335,
    'Cults': 2335,
    'Cumbernauld': 2335,
    'Cumnock': 2335,
    'Cupar': 2335,
    'Currie': 2335,
    'Dalbeattie': 2335,
    'Dalgety Bay': 2335,
    'Dalkeith': 2335,
    'Dalmellington': 2335,
    'Dalry': 2335,
    'Dalrymple': 2335,
    'Dalserf': 2335,
    'Danderhall': 2335,
    'Darvel': 2335,
    'Denny': 2335,
    'Dennyloanhead': 2335,
    'Dingwall': 2335,
    'Dollar': 2335,
    'Dornoch': 2335,
    'Douglas': 2335,
    'Doune': 2335,
    'Dreghorn': 2335,
    'Drongan': 2335,
    'Drumnadrochit': 2335,
    'Dufftown': 2335,
    'Dumbarton': 2335,
    'Dumfries': 2335,
    'Dumfries and Galloway': 2335,
    'Dunbar': 2335,
    'Dunblane': 2335,
    'Dundee': 2335,
    'Dundee City': 2335,
    'Dundonald': 2335,
    'Dunfermline': 2335,
    'Dunipace': 2335,
    'Dunlop': 2335,
    'Dunoon': 2335,
    'Duns': 2335,
    'Duntocher': 2335,
    'Dyce': 2335,
    'Eaglesham': 2335,
    'Earlston': 2335,
    'East Ayrshire': 2335,
    'East Calder': 2335,
    'East Dunbartonshire': 2335,
    'East Kilbride': 2335,
    'East Linton': 2335,
    'East Lothian': 2335,
    'East Renfrewshire': 2335,
    'East Wemyss': 2335,
    'East Whitburn': 2335,
    'Eastriggs': 2335,
    'Edinburgh': 2335,
    'Eilean Siar': 2335,
    'Elderslie': 2335,
    'Elgin': 2335,
    'Ellon': 2335,
    'Errol': 2335,
    'Erskine': 2335,
    'Evanton': 2335,
    'Eyemouth': 2335,
    'Faifley': 2335,
    'Fairlie': 2335,
    'Falkirk': 2335,
    'Falkland': 2335,
    'Fallin': 2335,
    'Fauldhouse': 2335,
    'Fenwick': 2335,
    'Fife': 2335,
    'Findochty': 2335,
    'Fochabers': 2335,
    'Forfar': 2335,
    'Forres': 2335,
    'Fort William': 2335,
    'Forth': 2335,
    'Fortrose': 2335,
    'Fraserburgh': 2335,
    'Freuchie': 2335,
    'Friockheim': 2335,
    'Galashiels': 2335,
    'Galston': 2335,
    'Garelochhead': 2335,
    'Gartcosh': 2335,
    'Giffnock': 2335,
    'Girvan': 2335,
    'Glasgow': 2335,
    'Glasgow City': 2335,
    'Glenboig': 2335,
    'Glenmavis': 2335,
    'Glenrothes': 2335,
    'Golspie': 2335,
    'Gorebridge': 2335,
    'Gourock': 2335,
    'Grangemouth': 2335,
    'Grantown on Spey': 2335,
    'Greenhill': 2335,
    'Greenock': 2335,
    'Gretna': 2335,
    'Gullane': 2335,
    'Haddington': 2335,
    'Halkirk': 2335,
    'Hallglen': 2335,
    'Hamilton': 2335,
    'Harthill': 2335,
    'Hawick': 2335,
    'Head of Muir': 2335,
    'Helensburgh': 2335,
    'High Blantyre': 2335,
    'High Valleyfield': 2335,
    'Highland': 2335,
    'Hillside': 2335,
    'Holytown': 2335,
    'Hopeman': 2335,
    'Houston': 2335,
    'Howwood': 2335,
    'Huntly': 2335,
    'Inchinnan': 2335,
    'Inchture': 2335,
    'Innerleithen': 2335,
    'Insch': 2335,
    'Inverbervie': 2335,
    'Inverclyde': 2335,
    'Invergordon': 2335,
    'Invergowrie': 2335,
    'Inverkeithing': 2335,
    'Inverkip': 2335,
    'Inverness': 2335,
    'Inverurie': 2335,
    'Irvine': 2335,
    'Isle of Arran': 2335,
    'Isle of Bute': 2335,
    'Isle of Cumbrae': 2335,
    'Isle of Islay': 2335,
    'Isle of Lewis': 2335,
    'Isle Of Mull': 2335,
    'Isle of North Uist': 2335,
    'Isle of South Uist': 2335,
    'Jedburgh': 2335,
    'Johnstone': 2335,
    'Keith': 2335,
    'Kelso': 2335,
    'Kelty': 2335,
    'Kemnay': 2335,
    'Kennoway': 2335,
    'Kilbarchan': 2335,
    'Kilbirnie': 2335,
    'Kilcreggan': 2335,
    'Killearn': 2335,
    'Kilmacolm': 2335,
    'Kilmarnock': 2335,
    'Kilmaurs': 2335,
    'Kilsyth': 2335,
    'Kilwinning': 2335,
    'Kincardine': 2335,
    'Kinghorn': 2335,
    'Kinglassie': 2335,
    'Kingskettle': 2335,
    'Kingswells': 2335,
    'Kingussie': 2335,
    'Kinloss': 2335,
    'Kinross': 2335,
    'Kintore': 2335,
    'Kippen': 2335,
    'Kirkcaldy': 2335,
    'Kirkconnel': 2335,
    'Kirkcudbright': 2335,
    'Kirkintilloch': 2335,
    'Kirkliston': 2335,
    'Kirknewton': 2335,
    'Kirkwall': 2335,
    'Kirriemuir': 2335,
    'Ladybank': 2335,
    'Lamlash': 2335,
    'Lanark': 2335,
    'Langholm': 2335,
    'Larbert': 2335,
    'Largs': 2335,
    'Larkhall': 2335,
    'Lauder': 2335,
    'Laurencekirk': 2335,
    'Laurieston': 2335,
    'Law': 2335,
    'Lennoxtown': 2335,
    'Lenzie': 2335,
    'Lerwick': 2335,
    'Leslie': 2335,
    'Lesmahagow': 2335,
    'Letham': 2335,
    'Leuchars': 2335,
    'Leven': 2335,
    'Lhanbryde': 2335,
    'Limekilns': 2335,
    'Linlithgow': 2335,
    'Linwood': 2335,
    'Livingston': 2335,
    'Loanhead': 2335,
    'Locharbriggs': 2335,
    'Lochgelly': 2335,
    'Lochgilphead': 2335,
    'Lochmaben': 2335,
    'Lochwinnoch': 2335,
    'Lockerbie': 2335,
    'Logan': 2335,
    'Longforgan': 2335,
    'Longniddry': 2335,
    'Lossiemouth': 2335,
    'Luncarty': 2335,
    'Lundin Links': 2335,
    'Macduff': 2335,
    'Macmerry': 2335,
    'Maddiston': 2335,
    'Markinch': 2335,
    'Maryburgh': 2335,
    'Mauchline': 2335,
    'Maxwellheugh': 2335,
    'Maybole': 2335,
    'Mayfield': 2335,
    'Melrose': 2335,
    'Menstrie': 2335,
    'Methil': 2335,
    'Methven': 2335,
    'Mid Calder': 2335,
    'Midlothian': 2335,
    'Millport': 2335,
    'Milltimber': 2335,
    'Milnathort': 2335,
    'Milngavie': 2335,
    'Milton of Campsie': 2335,
    'Milton of Leys': 2335,
    'Mintlaw': 2335,
    'Moffat': 2335,
    'Monifieth': 2335,
    'Montrose': 2335,
    'Moodiesburn': 2335,
    'Moray': 2335,
    'Mossblown': 2335,
    'Motherwell': 2335,
    'Muir of Ord': 2335,
    'Muirhead': 2335,
    'Muirkirk': 2335,
    'Musselburgh': 2335,
    'Nairn': 2335,
    'Neilston': 2335,
    'Netherlee': 2335,
    'New Cumnock': 2335,
    'New Pitsligo': 2335,
    'New Stevenston': 2335,
    'Newarthill': 2335,
    'Newbridge': 2335,
    'Newburgh': 2335,
    'Newmacher': 2335,
    'Newmains': 2335,
    'Newmilns': 2335,
    'Newport-on-Tay': 2335,
    'Newton Mearns': 2335,
    'Newton Stewart': 2335,
    'Newtonhill': 2335,
    'Newtonmore': 2335,
    'Newtown St Boswells': 2335,
    'North Ayrshire': 2335,
    'North Berwick': 2335,
    'North Lanarkshire': 2335,
    'North Queensferry': 2335,
    'Oakley': 2335,
    'Oban': 2335,
    'Ochiltree': 2335,
    'Old Kilpatrick': 2335,
    'Oldmeldrum': 2335,
    'Orkney': 2335,
    'Orkney Islands': 2335,
    'Ormiston': 2335,
    'Overtown': 2335,
    'Paisley': 2335,
    'Patna': 2335,
    'Peebles': 2335,
    'Pencaitland': 2335,
    'Penicuik': 2335,
    'Perth': 2335,
    'Perth and Kinross': 2335,
    'Peterculter': 2335,
    'Peterhead': 2335,
    'Pitlochry': 2335,
    'Pitmedden': 2335,
    'Pittenweem': 2335,
    'Plains': 2335,
    'Plean': 2335,
    'Polbeth': 2335,
    'Polmont': 2335,
    'Port Bannatyne': 2335,
    'Port Erroll': 2335,
    'Port Glasgow': 2335,
    'Portknockie': 2335,
    'Portlethen': 2335,
    'Portree': 2335,
    'Portsoy': 2335,
    'Prestonpans': 2335,
    'Prestwick': 2335,
    'Queensferry': 2335,
    'Ratho': 2335,
    'Ratho Station': 2335,
    'Redding': 2335,
    'Reddingmuirhead': 2335,
    'Renfrew': 2335,
    'Renfrewshire': 2335,
    'Renton': 2335,
    'Rhu': 2335,
    'Rosehearty': 2335,
    'Rosewell': 2335,
    'Roslin': 2335,
    'Rosneath': 2335,
    'Rosyth': 2335,
    'Rothes': 2335,
    'Rothesay': 2335,
    'Rothienorman': 2335,
    'Rutherglen': 2335,
    'Saint Andrews': 2335,
    'Saint Boswells': 2335,
    'Saint Cyrus': 2335,
    'Saint Monans': 2335,
    'Saline': 2335,
    'Salsburgh': 2335,
    'Saltcoats': 2335,
    'Sandbank': 2335,
    'Sandwick': 2335,
    'Sanquhar': 2335,
    'Scalloway': 2335,
    'Scone': 2335,
    'Seafield': 2335,
    'Selkirk': 2335,
    'Shetland Islands': 2335,
    'Shieldhill': 2335,
    'Shotts': 2335,
    'Skelmorlie': 2335,
    'Slamannan': 2335,
    'Smithton': 2335,
    'South Ayrshire': 2335,
    'South Lanarkshire': 2335,
    'Springside': 2335,
    'Stanley': 2335,
    'Stenhousemuir': 2335,
    'Stepps': 2335,
    'Stevenston': 2335,
    'Stewarton': 2335,
    'Stirling': 2335,
    'Stonehaven': 2335,
    'Stonehouse': 2335,
    'Stoneyburn': 2335,
    'Stornoway': 2335,
    'Stranraer': 2335,
    'Strathaven': 2335,
    'Strathblane': 2335,
    'Strathpeffer': 2335,
    'Stromness': 2335,
    'Symington': 2335,
    'Tain': 2335,
    'Tarbert': 2335,
    'Tarbolton': 2335,
    'Tayport': 2335,
    'The Scottish Borders': 2335,
    'Thornhill': 2335,
    'Thornliebank': 2335,
    'Thornton': 2335,
    'Thurso': 2335,
    'Tillicoultry': 2335,
    'Tobermory': 2335,
    'Torphins': 2335,
    'Torrance': 2335,
    'Townhill': 2335,
    'Tranent': 2335,
    'Troon': 2335,
    'Tullibody': 2335,
    'Turriff': 2335,
    'Twechar': 2335,
    'Tweedbank': 2335,
    'Uddingston': 2335,
    'Ullapool': 2335,
    'Vale of Leven': 2335,
    'Viewpark': 2335,
    'Waterfoot': 2335,
    'Wemyss Bay': 2335,
    'West Calder': 2335,
    'West Dunbartonshire': 2335,
    'West Kilbride': 2335,
    'West Linton': 2335,
    'West Lothian': 2335,
    'Westhill': 2335,
    'Westquarter': 2335,
    'Whitburn': 2335,
    'Whitecraig': 2335,
    'Whitehills': 2335,
    'Wick': 2335,
    'Winchburgh': 2335,
    'Windygates': 2335,
    'Wishaw': 2335,
    'Aberaeron': 2338,
    'Abercanaid': 2338,
    'Abercarn': 2338,
    'Abercynon': 2338,
    'Aberdare': 2338,
    'Aberfan': 2338,
    'Abergavenny': 2338,
    'Abergele': 2338,
    'Aberkenfig': 2338,
    'Aberporth': 2338,
    'Abertillery': 2338,
    'Abertridwr': 2338,
    'Aberystwyth': 2338,
    'Amlwch': 2338,
    'Ammanford': 2338,
    'Anglesey': 2338,
    'Bagillt': 2338,
    'Bala': 2338,
    'Bangor': 2338,
    'Bargoed': 2338,
    'Barmouth': 2338,
    'Barry': 2338,
    'Beaumaris': 2338,
    'Beddau': 2338,
    'Bedlinog': 2338,
    'Bedwas': 2338,
    'Benllech': 2338,
    'Bethesda': 2338,
    'Betws': 2338,
    'Betws-y-Coed': 2338,
    'Bishopston': 2338,
    'Blackwood': 2338,
    'Blaenau Gwent': 2338,
    'Blaenau-Ffestiniog': 2338,
    'Blaenavon': 2338,
    'Blaengwynfi': 2338,
    'Bodedern': 2338,
    'Bodelwyddan': 2338,
    'Borth': 2338,
    'Bow Street': 2338,
    'Brecon': 2338,
    'Bridgend': 2338,
    'Bridgend county borough': 2338,
    'Briton Ferry': 2338,
    'Broughton': 2338,
    'Brymbo': 2338,
    'Bryn': 2338,
    'Brynamman': 2338,
    'Brynmawr': 2338,
    'Brynna': 2338,
    'Buckley': 2338,
    'Builth Wells': 2338,
    'Burry Port': 2338,
    'Caergwrle': 2338,
    'Caerleon': 2338,
    'Caernarfon': 2338,
    'Caerphilly': 2338,
    'Caerphilly County Borough': 2338,
    'Caerwent': 2338,
    'Caldicot': 2338,
    'Cardiff': 2338,
    'Cardigan': 2338,
    'Carmarthen': 2338,
    'Carmarthenshire': 2338,
    'Cefn Cribwr': 2338,
    'Cemaes Bay': 2338,
    'Chepstow': 2338,
    'Chirk': 2338,
    'City and County of Swansea': 2338,
    'Clydach': 2338,
    'Coedpoeth': 2338,
    'Coity': 2338,
    'Colwyn Bay': 2338,
    'Conwy': 2338,
    'County of Ceredigion': 2338,
    'County of Flintshire': 2338,
    'Cowbridge': 2338,
    'Criccieth': 2338,
    'Crickhowell': 2338,
    'Croeserw': 2338,
    'Crofty': 2338,
    'Cross Hands': 2338,
    'Crumlin': 2338,
    'Crymych': 2338,
    'Crynant': 2338,
    'Cwm': 2338,
    'Cwmafan': 2338,
    'Cwmbach': 2338,
    'Cwmbran': 2338,
    'Deeside': 2338,
    'Deganwy': 2338,
    'Deiniolen': 2338,
    'Denbigh': 2338,
    'Denbighshire': 2338,
    'Deri': 2338,
    'Dinas Powys': 2338,
    'Dolgellau': 2338,
    'Dyffryn Ardudwy': 2338,
    'Dyserth': 2338,
    'Ebbw Vale': 2338,
    'Ferndale': 2338,
    'Fishguard': 2338,
    'Flint': 2338,
    'Fochriw': 2338,
    'Four Crosses': 2338,
    'Gaerwen': 2338,
    'Gelligaer': 2338,
    'Gilfach Goch': 2338,
    'Gilwern': 2338,
    'Glanamman': 2338,
    'Glandwr': 2338,
    'Glyn-neath': 2338,
    'Glyncorrwg': 2338,
    'Goodwick': 2338,
    'Gorseinon': 2338,
    'Govilon': 2338,
    'Greenfield': 2338,
    'Gresford': 2338,
    'Gronant': 2338,
    'Grovesend': 2338,
    'Guilsfield': 2338,
    'Gwynedd': 2338,
    'Harlech': 2338,
    'Haverfordwest': 2338,
    'Hawarden': 2338,
    'Hay': 2338,
    'Hengoed': 2338,
    'Henllan': 2338,
    'Hirwaun': 2338,
    'Holyhead': 2338,
    'Holywell': 2338,
    'Hook': 2338,
    'Hope': 2338,
    'Hundleton': 2338,
    'Johnston': 2338,
    'Kidwelly': 2338,
    'Kilgetty': 2338,
    'Knighton': 2338,
    'Lampeter': 2338,
    'Leeswood': 2338,
    'Letterston': 2338,
    'Llanarth': 2338,
    'Llanbedr': 2338,
    'Llanberis': 2338,
    'Llanbradach': 2338,
    'Llandeilo': 2338,
    'Llandovery': 2338,
    'Llandrindod Wells': 2338,
    'Llandudno': 2338,
    'Llandybie': 2338,
    'Llandysul': 2338,
    'Llanelli': 2338,
    'Llanerchymedd': 2338,
    'Llanfair Caereinion': 2338,
    'Llanfairfechan': 2338,
    'Llanfairpwllgwyngyll': 2338,
    'Llanfyllin': 2338,
    'Llangefni': 2338,
    'Llangoed': 2338,
    'Llangollen': 2338,
    'Llangwm': 2338,
    'Llangybi': 2338,
    'Llangynidr': 2338,
    'Llanharan': 2338,
    'Llanharry': 2338,
    'Llanidloes': 2338,
    'Llanilar': 2338,
    'Llanrhaeadr-ym-Mochnant': 2338,
    'Llanrug': 2338,
    'Llanrwst': 2338,
    'Llansantffraid Glan Conwy': 2338,
    'Llansteffan': 2338,
    'Llantrisant': 2338,
    'Llantwit Fardre': 2338,
    'Llantwit Major': 2338,
    'Llwynypia': 2338,
    'Machen': 2338,
    'Machynlleth': 2338,
    'Maesteg': 2338,
    'Magor': 2338,
    'Marchwiel': 2338,
    'Marshfield': 2338,
    'Menai Bridge': 2338,
    'Merthyr Tydfil': 2338,
    'Merthyr Tydfil County Borough': 2338,
    'Milford Haven': 2338,
    'Moelfre': 2338,
    'Mold': 2338,
    'Monmouth': 2338,
    'Monmouthshire': 2338,
    'Mostyn': 2338,
    'Mountain Ash': 2338,
    'Narberth': 2338,
    'Neath': 2338,
    'Neath Port Talbot': 2338,
    'Nefyn': 2338,
    'Nelson': 2338,
    'New Quay': 2338,
    'New Tredegar': 2338,
    'Newbridge': 2338,
    'Newcastle Emlyn': 2338,
    'Newport': 2338,
    'Newtown': 2338,
    'Neyland': 2338,
    'Northop': 2338,
    'Ogmore Vale': 2338,
    'Overton': 2338,
    'Pembroke': 2338,
    'Pembroke Dock': 2338,
    'Pembrokeshire': 2338,
    'Pen-clawdd': 2338,
    'Penally': 2338,
    'Penarth': 2338,
    'Pencader': 2338,
    'Pencoed': 2338,
    'Penmaenmawr': 2338,
    'Penparcau': 2338,
    'Penrhyndeudraeth': 2338,
    'Pentre': 2338,
    'Pentyrch': 2338,
    'Penyffordd': 2338,
    'Penygroes': 2338,
    'Pont Rhyd-y-cyff': 2338,
    'Pontarddulais': 2338,
    'Pontlliw': 2338,
    'Pontyates': 2338,
    'Pontyberem': 2338,
    'Pontyclun': 2338,
    'Pontycymer': 2338,
    'Pontypool': 2338,
    'Pontypridd': 2338,
    'Port Talbot': 2338,
    'Porthcawl': 2338,
    'Porthmadog': 2338,
    'Prestatyn': 2338,
    'Presteigne': 2338,
    'Price Town': 2338,
    'Pwllheli': 2338,
    'Pyle': 2338,
    'Radyr': 2338,
    'Raglan': 2338,
    'Resolven': 2338,
    'Rhayader': 2338,
    'Rhondda': 2338,
    'Rhondda Cynon Taf': 2338,
    'Rhoose': 2338,
    'Rhosllanerchrugog': 2338,
    'Rhosneigr': 2338,
    'Rhuddlan': 2338,
    'Rhyl': 2338,
    'Rhymney': 2338,
    'Risca': 2338,
    'Rogiet': 2338,
    'Rossett': 2338,
    'Ruabon': 2338,
    'Ruthin': 2338,
    'Saint Asaph': 2338,
    'Saint Clears': 2338,
    'Saint Davids': 2338,
    'Saundersfoot': 2338,
    'Seven Sisters': 2338,
    'Sir Powys': 2338,
    'Southgate': 2338,
    'Spittal': 2338,
    'Steynton': 2338,
    'Swansea': 2338,
    'Taibach': 2338,
    'Tal-y-bont': 2338,
    'Talgarth': 2338,
    'Talysarn': 2338,
    'Templeton': 2338,
    'Tenby': 2338,
    'Three Crosses': 2338,
    'Tintern': 2338,
    'Tonypandy': 2338,
    'Tonyrefail': 2338,
    'Torfaen County Borough': 2338,
    'Trawsfynydd': 2338,
    'Tredegar': 2338,
    'Trefnant': 2338,
    'Tregaron': 2338,
    'Tregarth': 2338,
    'Treharris': 2338,
    'Treorchy': 2338,
    'Treuddyn': 2338,
    'Trimsaran': 2338,
    'Tumble': 2338,
    'Tycroes': 2338,
    'Tywyn': 2338,
    'Undy': 2338,
    'Usk': 2338,
    'Vale of Glamorgan': 2338,
    'Valley': 2338,
    'Victoria': 2338,
    'Welshpool': 2338,
    'Wenvoe': 2338,
    'Whitland': 2338,
    'Wick': 2338,
    'Wrexham': 2338,
    'Y Felinheli': 2338,
    'Ynysybwl': 2338,
    'Ystalyfera': 2338,
    'Abbeville': 1456,
    'Adamsville': 1456,
    'Alabaster': 1456,
    'Albertville': 1456,
    'Alexander City': 1456,
    'Alexandria': 1456,
    'Aliceville': 1456,
    'Andalusia': 1456,
    'Anniston': 1456,
    'Arab': 1456,
    'Argo': 1456,
    'Ashford': 1456,
    'Ashland': 1456,
    'Ashville': 1456,
    'Athens': 1456,
    'Atmore': 1456,
    'Attalla': 1456,
    'Auburn': 1456,
    'Autauga County': 1456,
    'Baldwin County': 1456,
    'Ballplay': 1456,
    'Barbour County': 1456,
    'Bay Minette': 1456,
    'Bayou La Batre': 1456,
    'Bear Creek': 1456,
    'Berry': 1456,
    'Bessemer': 1456,
    'Bibb County': 1456,
    'Birmingham': 1456,
    'Blount County': 1456,
    'Blountsville': 1456,
    'Blue Ridge': 1456,
    'Boaz': 1456,
    'Brent': 1456,
    'Brewton': 1456,
    'Bridgeport': 1456,
    'Brighton': 1456,
    'Brook Highland': 1456,
    'Brookside': 1456,
    'Brookwood': 1456,
    'Brundidge': 1456,
    'Bullock County': 1456,
    'Butler': 1456,
    'Butler County': 1456,
    'Bynum': 1456,
    'Cahaba Heights': 1456,
    'Calera': 1456,
    'Calhoun County': 1456,
    'Camden': 1456,
    'Carbon Hill': 1456,
    'Carlisle-Rockledge': 1456,
    'Carrollton': 1456,
    'Cedar Bluff': 1456,
    'Center Point': 1456,
    'Centre': 1456,
    'Centreville': 1456,
    'Chalkville': 1456,
    'Chambers County': 1456,
    'Chatom': 1456,
    'Chelsea': 1456,
    'Cherokee': 1456,
    'Cherokee County': 1456,
    'Chickasaw': 1456,
    'Childersburg': 1456,
    'Chilton County': 1456,
    'Choccolocco': 1456,
    'Choctaw County': 1456,
    'Citronelle': 1456,
    'Clanton': 1456,
    'Clarke County': 1456,
    'Clay': 1456,
    'Clay County': 1456,
    'Clayton': 1456,
    'Cleburne County': 1456,
    'Cleveland': 1456,
    'Clio': 1456,
    'Coaling': 1456,
    'Coffee County': 1456,
    'Colbert County': 1456,
    'Collinsville': 1456,
    'Columbiana': 1456,
    'Concord': 1456,
    'Conecuh County': 1456,
    'Coosa County': 1456,
    'Coosada': 1456,
    'Cordova': 1456,
    'Cottonwood': 1456,
    'Covington County': 1456,
    'Cowarts': 1456,
    'Crenshaw County': 1456,
    'Creola': 1456,
    'Crossville': 1456,
    'Cullman': 1456,
    'Cullman County': 1456,
    'Dadeville': 1456,
    'Dale County': 1456,
    'Daleville': 1456,
    'Dallas County': 1456,
    'Danville': 1456,
    'Daphne': 1456,
    'Dauphin Island': 1456,
    'Deatsville': 1456,
    'Decatur': 1456,
    'DeKalb County': 1456,
    'Demopolis': 1456,
    'Dixiana': 1456,
    'Dora': 1456,
    'Dothan': 1456,
    'Double Springs': 1456,
    'East Brewton': 1456,
    'East Florence': 1456,
    'Eclectic': 1456,
    'Elba': 1456,
    'Elberta': 1456,
    'Elmore': 1456,
    'Elmore County': 1456,
    'Emerald Mountain': 1456,
    'Enterprise': 1456,
    'Escambia County': 1456,
    'Etowah County': 1456,
    'Eufaula': 1456,
    'Eutaw': 1456,
    'Evergreen': 1456,
    'Fairfield': 1456,
    'Fairhope': 1456,
    'Falkville': 1456,
    'Fayette': 1456,
    'Fayette County': 1456,
    'Fayetteville': 1456,
    'Flint City': 1456,
    'Flomaton': 1456,
    'Florala': 1456,
    'Florence': 1456,
    'Foley': 1456,
    'Forestdale': 1456,
    'Fort Deposit': 1456,
    'Fort Payne': 1456,
    'Fort Rucker': 1456,
    'Franklin County': 1456,
    'Frisco City': 1456,
    'Fultondale': 1456,
    'Fyffe': 1456,
    'Gadsden': 1456,
    'Gardendale': 1456,
    'Geneva': 1456,
    'Geneva County': 1456,
    'Georgiana': 1456,
    'Glencoe': 1456,
    'Good Hope': 1456,
    'Goodwater': 1456,
    'Gordo': 1456,
    'Grand Bay': 1456,
    'Grayson Valley': 1456,
    'Graysville': 1456,
    'Greene County': 1456,
    'Greensboro': 1456,
    'Greenville': 1456,
    'Grove Hill': 1456,
    'Guin': 1456,
    'Gulf Shores': 1456,
    'Guntersville': 1456,
    'Hackleburg': 1456,
    'Hale County': 1456,
    'Haleyville': 1456,
    'Hamilton': 1456,
    'Hanceville': 1456,
    'Harpersville': 1456,
    'Hartford': 1456,
    'Hartselle': 1456,
    'Harvest': 1456,
    'Hayden': 1456,
    'Hayneville': 1456,
    'Hazel Green': 1456,
    'Headland': 1456,
    'Heflin': 1456,
    'Helena': 1456,
    'Henagar': 1456,
    'Henry County': 1456,
    'Highland Lakes': 1456,
    'Hokes Bluff': 1456,
    'Holt': 1456,
    'Holtville': 1456,
    'Homewood': 1456,
    'Hoover': 1456,
    'Houston County': 1456,
    'Hueytown': 1456,
    'Huguley': 1456,
    'Huntsville': 1456,
    'Indian Springs Village': 1456,
    'Inverness': 1456,
    'Irondale': 1456,
    'Jackson': 1456,
    'Jackson County': 1456,
    'Jacksonville': 1456,
    'Jasper': 1456,
    'Jefferson County': 1456,
    'Jemison': 1456,
    'Kimberly': 1456,
    'Kinsey': 1456,
    'Ladonia': 1456,
    'Lafayette': 1456,
    'Lake Purdy': 1456,
    'Lake View': 1456,
    'Lamar County': 1456,
    'Lanett': 1456,
    'Lauderdale County': 1456,
    'Lawrence County': 1456,
    'Lee County': 1456,
    'Leeds': 1456,
    'Leesburg': 1456,
    'Level Plains': 1456,
    'Limestone County': 1456,
    'Lincoln': 1456,
    'Linden': 1456,
    'Lineville': 1456,
    'Lipscomb': 1456,
    'Livingston': 1456,
    'Locust Fork': 1456,
    'Lowndes County': 1456,
    'Loxley': 1456,
    'Luverne': 1456,
    'Macon County': 1456,
    'Madison': 1456,
    'Madison County': 1456,
    'Malvern': 1456,
    'Marbury': 1456,
    'Marengo County': 1456,
    'Margaret': 1456,
    'Marion': 1456,
    'Marion County': 1456,
    'Marshall County': 1456,
    'Meadowbrook': 1456,
    'Meridianville': 1456,
    'Midfield': 1456,
    'Midland City': 1456,
    'Mignon': 1456,
    'Millbrook': 1456,
    'Minor': 1456,
    'Mobile': 1456,
    'Mobile County': 1456,
    'Monroe County': 1456,
    'Monroeville': 1456,
    'Montevallo': 1456,
    'Montgomery': 1456,
    'Montgomery County': 1456,
    'Moody': 1456,
    'Moores Mill': 1456,
    'Morgan County': 1456,
    'Morris': 1456,
    'Moulton': 1456,
    'Moundville': 1456,
    'Mount Olive': 1456,
    'Mount Vernon': 1456,
    'Mountain Brook': 1456,
    'Munford': 1456,
    'Muscle Shoals': 1456,
    'New Brockton': 1456,
    'New Hope': 1456,
    'New Market': 1456,
    'Newton': 1456,
    'North Bibb': 1456,
    'Northport': 1456,
    'Odenville': 1456,
    'Ohatchee': 1456,
    'Oneonta': 1456,
    'Opelika': 1456,
    'Opp': 1456,
    'Orange Beach': 1456,
    'Owens Cross Roads': 1456,
    'Oxford': 1456,
    'Ozark': 1456,
    'Pelham': 1456,
    'Pell City': 1456,
    'Perry County': 1456,
    'Phenix City': 1456,
    'Phil Campbell': 1456,
    'Pickens County': 1456,
    'Piedmont': 1456,
    'Pike County': 1456,
    'Pike Road': 1456,
    'Pine Level': 1456,
    'Pinson': 1456,
    'Pleasant Grove': 1456,
    'Point Clear': 1456,
    'Prattville': 1456,
    'Priceville': 1456,
    'Prichard': 1456,
    'Ragland': 1456,
    'Rainbow City': 1456,
    'Rainsville': 1456,
    'Randolph County': 1456,
    'Red Bay': 1456,
    'Redstone Arsenal': 1456,
    'Reform': 1456,
    'Rehobeth': 1456,
    'Riverside': 1456,
    'Roanoke': 1456,
    'Robertsdale': 1456,
    'Rock Creek': 1456,
    'Rockford': 1456,
    'Rogersville': 1456,
    'Russell County': 1456,
    'Russellville': 1456,
    'Saint Clair County': 1456,
    'Saks': 1456,
    'Samson': 1456,
    'Saraland': 1456,
    'Sardis City': 1456,
    'Satsuma': 1456,
    'Scottsboro': 1456,
    'Selma': 1456,
    'Selmont-West Selmont': 1456,
    'Semmes': 1456,
    'Sheffield': 1456,
    'Shelby': 1456,
    'Shelby County': 1456,
    'Shoal Creek': 1456,
    'Slocomb': 1456,
    'Smiths Station': 1456,
    'Smoke Rise': 1456,
    'Southside': 1456,
    'Spanish Fort': 1456,
    'Springville': 1456,
    'Steele': 1456,
    'Stevenson': 1456,
    'Stewartville': 1456,
    'Sulligent': 1456,
    'Sumiton': 1456,
    'Summerdale': 1456,
    'Sumter County': 1456,
    'Sylacauga': 1456,
    'Sylvan Springs': 1456,
    'Sylvania': 1456,
    'Talladega': 1456,
    'Talladega County': 1456,
    'Tallapoosa County': 1456,
    'Tallassee': 1456,
    'Tarrant': 1456,
    'Taylor': 1456,
    'Theodore': 1456,
    'Thomasville': 1456,
    'Thorsby': 1456,
    'Tillmans Corner': 1456,
    'Town Creek': 1456,
    'Trinity': 1456,
    'Troy': 1456,
    'Trussville': 1456,
    'Tuscaloosa': 1456,
    'Tuscaloosa County': 1456,
    'Tuscumbia': 1456,
    'Tuskegee': 1456,
    'Underwood-Petersville': 1456,
    'Union Springs': 1456,
    'Uniontown': 1456,
    'Valley': 1456,
    'Valley Grande': 1456,
    'Vance': 1456,
    'Vandiver': 1456,
    'Vernon': 1456,
    'Vestavia Hills': 1456,
    'Vincent': 1456,
    'Walker County': 1456,
    'Warrior': 1456,
    'Washington County': 1456,
    'Weaver': 1456,
    'Webb': 1456,
    'Wedowee': 1456,
    'West Blocton': 1456,
    'West End-Cobb Town': 1456,
    'Westover': 1456,
    'Wetumpka': 1456,
    'Whitesboro': 1456,
    'Wilcox County': 1456,
    'Wilsonville': 1456,
    'Winfield': 1456,
    'Winston County': 1456,
    'Woodstock': 1456,
    'York': 1456,
    'Akutan': 1400,
    'Aleutians East Borough': 1400,
    'Aleutians West Census Area': 1400,
    'Anchor Point': 1400,
    'Anchorage': 1400,
    'Anchorage Municipality': 1400,
    'Badger': 1400,
    'Barrow': 1400,
    'Bear Creek': 1400,
    'Bethel': 1400,
    'Bethel Census Area': 1400,
    'Big Lake': 1400,
    'Bristol Bay Borough': 1400,
    'Butte': 1400,
    'Chevak': 1400,
    'City and Borough of Wrangell': 1400,
    'Cohoe': 1400,
    'College': 1400,
    'Cordova': 1400,
    'Craig': 1400,
    'Deltana': 1400,
    'Denali Borough': 1400,
    'Diamond Ridge': 1400,
    'Dillingham': 1400,
    'Dillingham Census Area': 1400,
    'Dutch Harbor': 1400,
    'Eagle River': 1400,
    'Eielson Air Force Base': 1400,
    'Elmendorf Air Force Base': 1400,
    'Ester': 1400,
    'Fairbanks': 1400,
    'Fairbanks North Star Borough': 1400,
    'Farm Loop': 1400,
    'Farmers Loop': 1400,
    'Fishhook': 1400,
    'Fritz Creek': 1400,
    'Gateway': 1400,
    'Girdwood': 1400,
    'Haines': 1400,
    'Haines Borough': 1400,
    'Healy': 1400,
    'Homer': 1400,
    'Hoonah-Angoon Census Area': 1400,
    'Hooper Bay': 1400,
    'Houston': 1400,
    'Juneau': 1400,
    'Kalifornsky': 1400,
    'Kenai': 1400,
    'Kenai Peninsula Borough': 1400,
    'Ketchikan': 1400,
    'Ketchikan Gateway Borough': 1400,
    'King Cove': 1400,
    'Knik-Fairview': 1400,
    'Kodiak': 1400,
    'Kodiak Island Borough': 1400,
    'Kodiak Station': 1400,
    'Kotzebue': 1400,
    'Lake and Peninsula Borough': 1400,
    'Lakes': 1400,
    'Lazy Mountain': 1400,
    'Matanuska-Susitna Borough': 1400,
    'Meadow Lakes': 1400,
    'Metlakatla': 1400,
    'Nikiski': 1400,
    'Nome': 1400,
    'Nome Census Area': 1400,
    'North Pole': 1400,
    'North Slope Borough': 1400,
    'Northwest Arctic Borough': 1400,
    'Palmer': 1400,
    'Petersburg': 1400,
    'Petersburg Borough': 1400,
    'Prudhoe Bay': 1400,
    'Ridgeway': 1400,
    'Salcha': 1400,
    'Sand Point': 1400,
    'Seward': 1400,
    'Sitka': 1400,
    'Sitka City and Borough': 1400,
    'Skagway Municipality': 1400,
    'Soldotna': 1400,
    'Southeast Fairbanks Census Area': 1400,
    'Sterling': 1400,
    'Sutton-Alpine': 1400,
    'Tanaina': 1400,
    'Tok': 1400,
    'Unalaska': 1400,
    'Valdez': 1400,
    'Valdez-Cordova Census Area': 1400,
    'Wasilla': 1400,
    'Willow': 1400,
    'Wrangell': 1400,
    'Y': 1400,
    'Yakutat City and Borough': 1400,
    'Yukon-Koyukuk Census Area': 1400,
    'Ahwatukee Foothills': 1434,
    'Ajo': 1434,
    'Alhambra': 1434,
    'Anthem': 1434,
    'Apache County': 1434,
    'Apache Junction': 1434,
    'Arivaca Junction': 1434,
    'Arizona City': 1434,
    'Avenue B and C': 1434,
    'Avondale': 1434,
    'Avra Valley': 1434,
    'Bagdad': 1434,
    'Beaver Dam': 1434,
    'Benson': 1434,
    'Big Park': 1434,
    'Bisbee': 1434,
    'Black Canyon City': 1434,
    'Blackwater': 1434,
    'Buckeye': 1434,
    'Bullhead City': 1434,
    'Bylas': 1434,
    'Cactus Flat': 1434,
    'Camp Verde': 1434,
    'Canyon Day': 1434,
    'Carefree': 1434,
    'Casa Blanca': 1434,
    'Casa Grande': 1434,
    'Casas Adobes': 1434,
    'Catalina': 1434,
    'Catalina Foothills': 1434,
    'Cave Creek': 1434,
    'Centennial Park': 1434,
    'Central City': 1434,
    'Central Heights-Midland City': 1434,
    'Chandler': 1434,
    'Chinle': 1434,
    'Chino Valley': 1434,
    'Cibecue': 1434,
    'Cienega Springs': 1434,
    'Citrus Park': 1434,
    'Clarkdale': 1434,
    'Claypool': 1434,
    'Clifton': 1434,
    'Cochise County': 1434,
    'Coconino County': 1434,
    'Colorado City': 1434,
    'Congress': 1434,
    'Coolidge': 1434,
    'Cordes Lakes': 1434,
    'Cornville': 1434,
    'Corona de Tucson': 1434,
    'Cottonwood': 1434,
    'Deer Valley': 1434,
    'Desert Hills': 1434,
    'Dewey-Humboldt': 1434,
    'Dilkon': 1434,
    'Dolan Springs': 1434,
    'Donovan Estates': 1434,
    'Douglas': 1434,
    'Drexel Heights': 1434,
    'Eagar': 1434,
    'East Sahuarita': 1434,
    'Ehrenberg': 1434,
    'El Mirage': 1434,
    'Eloy': 1434,
    'Encanto': 1434,
    'First Mesa': 1434,
    'Flagstaff': 1434,
    'Florence': 1434,
    'Flowing Wells': 1434,
    'Fort Defiance': 1434,
    'Fortuna Foothills': 1434,
    'Fountain Hills': 1434,
    'Fredonia': 1434,
    'Ganado': 1434,
    'Gila Bend': 1434,
    'Gila County': 1434,
    'Gilbert': 1434,
    'Glendale': 1434,
    'Globe': 1434,
    'Gold Camp': 1434,
    'Gold Canyon': 1434,
    'Golden Shores': 1434,
    'Golden Valley': 1434,
    'Goodyear': 1434,
    'Graham County': 1434,
    'Grand Canyon': 1434,
    'Grand Canyon Village': 1434,
    'Green Valley': 1434,
    'Greenlee County': 1434,
    'Guadalupe': 1434,
    'Heber-Overgaard': 1434,
    'Holbrook': 1434,
    'Houck': 1434,
    'Huachuca City': 1434,
    'Joseph City': 1434,
    'Kachina Village': 1434,
    'Kaibito': 1434,
    'Kayenta': 1434,
    'Kearny': 1434,
    'Kingman': 1434,
    'La Paz County': 1434,
    'Lake Havasu City': 1434,
    'Lake Montezuma': 1434,
    'Lake of the Woods': 1434,
    'Laveen': 1434,
    'LeChee': 1434,
    'Linden': 1434,
    'Litchfield Park': 1434,
    'Lukachukai': 1434,
    'Mammoth': 1434,
    'Many Farms': 1434,
    'Marana': 1434,
    'Maricopa': 1434,
    'Maricopa County': 1434,
    'Maryvale': 1434,
    'Mayer': 1434,
    'Meadview': 1434,
    'Mesa': 1434,
    'Mescal': 1434,
    'Miami': 1434,
    'Mohave County': 1434,
    'Mohave Valley': 1434,
    'Morenci': 1434,
    'Mountainaire': 1434,
    'Naco': 1434,
    'Navajo County': 1434,
    'New Kingman-Butler': 1434,
    'New River': 1434,
    'Nogales': 1434,
    'North Fork': 1434,
    'Oracle': 1434,
    'Oro Valley': 1434,
    'Page': 1434,
    'Paradise Valley': 1434,
    'Parker': 1434,
    'Parks': 1434,
    'Paulden': 1434,
    'Payson': 1434,
    'Peach Springs': 1434,
    'Peoria': 1434,
    'Peridot': 1434,
    'Phoenix': 1434,
    'Picture Rocks': 1434,
    'Pima': 1434,
    'Pima County': 1434,
    'Pinal County': 1434,
    'Pine': 1434,
    'Pinetop-Lakeside': 1434,
    'Pirtleville': 1434,
    'Prescott': 1434,
    'Prescott Valley': 1434,
    'Quartzsite': 1434,
    'Queen Creek': 1434,
    'Rio Rico': 1434,
    'Rio Verde': 1434,
    'Sacaton': 1434,
    'Saddle Brooke': 1434,
    'Safford': 1434,
    'Sahuarita': 1434,
    'Saint David': 1434,
    'Saint Johns': 1434,
    'Saint Michaels': 1434,
    'Salome': 1434,
    'San Carlos': 1434,
    'San Luis': 1434,
    'San Manuel': 1434,
    'San Tan Valley': 1434,
    'Santa Cruz County': 1434,
    'Scenic': 1434,
    'Scottsdale': 1434,
    'Sedona': 1434,
    'Sells': 1434,
    'Show Low': 1434,
    'Sierra Vista': 1434,
    'Sierra Vista Southeast': 1434,
    'Six Shooter Canyon': 1434,
    'Snowflake': 1434,
    'Somerton': 1434,
    'South Tucson': 1434,
    'Spring Valley': 1434,
    'Springerville': 1434,
    'Star Valley': 1434,
    'Summit': 1434,
    'Sun City': 1434,
    'Sun City West': 1434,
    'Sun Lakes': 1434,
    'Sun Valley': 1434,
    'Superior': 1434,
    'Surprise': 1434,
    'Swift Trail Junction': 1434,
    'Tanque Verde': 1434,
    'Taylor': 1434,
    'Tempe': 1434,
    'Tempe Junction': 1434,
    'Thatcher': 1434,
    'Three Points': 1434,
    'Tolleson': 1434,
    'Tombstone': 1434,
    'Tonto Basin': 1434,
    'Tortolita': 1434,
    'Tsaile': 1434,
    'Tuba City': 1434,
    'Tubac': 1434,
    'Tucson': 1434,
    'Tucson Estates': 1434,
    'Vail': 1434,
    'Valencia West': 1434,
    'Valle Vista': 1434,
    'Verde Village': 1434,
    'Village of Oak Creek (Big Park)': 1434,
    'Wellton': 1434,
    'West Sedona': 1434,
    'Whetstone': 1434,
    'White Mountain Lake': 1434,
    'Whiteriver': 1434,
    'Wickenburg': 1434,
    'Willcox': 1434,
    'Williams': 1434,
    'Williamson': 1434,
    'Willow Valley': 1434,
    'Window Rock': 1434,
    'Winslow': 1434,
    'Yavapai County': 1434,
    'Youngtown': 1434,
    'Yuma': 1434,
    'Yuma County': 1434,
    'Alexander': 1444,
    'Alma': 1444,
    'Arkadelphia': 1444,
    'Arkansas City': 1444,
    'Arkansas County': 1444,
    'Ash Flat': 1444,
    'Ashdown': 1444,
    'Ashley County': 1444,
    'Atkins': 1444,
    'Augusta': 1444,
    'Austin': 1444,
    'Bald Knob': 1444,
    'Barling': 1444,
    'Batesville': 1444,
    'Baxter County': 1444,
    'Bay': 1444,
    'Beebe': 1444,
    'Bella Vista': 1444,
    'Benton': 1444,
    'Benton County': 1444,
    'Bentonville': 1444,
    'Berryville': 1444,
    'Bethel Heights': 1444,
    'Blytheville': 1444,
    'Bono': 1444,
    'Boone County': 1444,
    'Booneville': 1444,
    'Bradley County': 1444,
    'Brinkley': 1444,
    'Brookland': 1444,
    'Bryant': 1444,
    'Bull Shoals': 1444,
    'Cabot': 1444,
    'Calhoun County': 1444,
    'Calico Rock': 1444,
    'Camden': 1444,
    'Caraway': 1444,
    'Carlisle': 1444,
    'Carroll County': 1444,
    'Cave City': 1444,
    'Cave Springs': 1444,
    'Cedarville': 1444,
    'Centerton': 1444,
    'Charleston': 1444,
    'Cherokee Village': 1444,
    'Chicot County': 1444,
    'Clarendon': 1444,
    'Clark County': 1444,
    'Clarksville': 1444,
    'Clay County': 1444,
    'Cleburne County': 1444,
    'Cleveland County': 1444,
    'Clinton': 1444,
    'Coal Hill': 1444,
    'Columbia County': 1444,
    'Conway': 1444,
    'Conway County': 1444,
    'Corning': 1444,
    'Craighead County': 1444,
    'Crawford County': 1444,
    'Crittenden County': 1444,
    'Cross County': 1444,
    'Crossett': 1444,
    'Dallas County': 1444,
    'Danville': 1444,
    'Dardanelle': 1444,
    'De Queen': 1444,
    'De Witt': 1444,
    'Decatur': 1444,
    'Dermott': 1444,
    'Des Arc': 1444,
    'Desha County': 1444,
    'Diaz': 1444,
    'Dierks': 1444,
    'Dover': 1444,
    'Drew County': 1444,
    'Dumas': 1444,
    'Earle': 1444,
    'East End': 1444,
    'El Dorado': 1444,
    'Elkins': 1444,
    'Elm Springs': 1444,
    'England': 1444,
    'Eudora': 1444,
    'Eureka Springs': 1444,
    'Fairfield Bay': 1444,
    'Farmington': 1444,
    'Faulkner County': 1444,
    'Fayetteville': 1444,
    'Flippin': 1444,
    'Fordyce': 1444,
    'Forrest City': 1444,
    'Fort Smith': 1444,
    'Franklin County': 1444,
    'Fulton County': 1444,
    'Garland County': 1444,
    'Gassville': 1444,
    'Gentry': 1444,
    'Gibson': 1444,
    'Glenwood': 1444,
    'Goshen': 1444,
    'Gosnell': 1444,
    'Grant County': 1444,
    'Gravel Ridge': 1444,
    'Gravette': 1444,
    'Green Forest': 1444,
    'Greenbrier': 1444,
    'Greene County': 1444,
    'Greenland': 1444,
    'Greenwood': 1444,
    'Gurdon': 1444,
    'Hamburg': 1444,
    'Hampton': 1444,
    'Harrisburg': 1444,
    'Harrison': 1444,
    'Haskell': 1444,
    'Hazen': 1444,
    'Heber Springs': 1444,
    'Helena': 1444,
    'Helena-West Helena': 1444,
    'Hempstead County': 1444,
    'Highland': 1444,
    'Holiday Island': 1444,
    'Hope': 1444,
    'Horatio': 1444,
    'Horseshoe Bend': 1444,
    'Hot Spring County': 1444,
    'Hot Springs': 1444,
    'Hot Springs National Park': 1444,
    'Hot Springs Village': 1444,
    'Howard County': 1444,
    'Hoxie': 1444,
    'Hughes': 1444,
    'Huntsville': 1444,
    'Independence County': 1444,
    'Izard County': 1444,
    'Jackson County': 1444,
    'Jacksonville': 1444,
    'Jasper': 1444,
    'Jefferson County': 1444,
    'Johnson': 1444,
    'Johnson County': 1444,
    'Jonesboro': 1444,
    'Judsonia': 1444,
    'Kensett': 1444,
    'Lafayette County': 1444,
    'Lake City': 1444,
    'Lake Hamilton': 1444,
    'Lake Village': 1444,
    'Lamar': 1444,
    'Landmark': 1444,
    'Lavaca': 1444,
    'Lawrence County': 1444,
    'Leachville': 1444,
    'Lee County': 1444,
    'Lepanto': 1444,
    'Lewisville': 1444,
    'Lincoln': 1444,
    'Lincoln County': 1444,
    'Little Flock': 1444,
    'Little River County': 1444,
    'Little Rock': 1444,
    'Little Rock Air Force Base': 1444,
    'Logan County': 1444,
    'London': 1444,
    'Lonoke': 1444,
    'Lonoke County': 1444,
    'Lowell': 1444,
    'Luxora': 1444,
    'Madison County': 1444,
    'Magnolia': 1444,
    'Malvern': 1444,
    'Manila': 1444,
    'Mansfield': 1444,
    'Marianna': 1444,
    'Marion': 1444,
    'Marion County': 1444,
    'Marked Tree': 1444,
    'Marmaduke': 1444,
    'Marshall': 1444,
    'Marvell': 1444,
    'Maumelle': 1444,
    'Mayflower': 1444,
    'McAlmont': 1444,
    'McCrory': 1444,
    'McGehee': 1444,
    'Melbourne': 1444,
    'Mena': 1444,
    'Midway': 1444,
    'Miller County': 1444,
    'Mineral Springs': 1444,
    'Mississippi County': 1444,
    'Monette': 1444,
    'Monroe County': 1444,
    'Montgomery County': 1444,
    'Monticello': 1444,
    'Morrilton': 1444,
    'Mount Ida': 1444,
    'Mountain Home': 1444,
    'Mountain View': 1444,
    'Mulberry': 1444,
    'Murfreesboro': 1444,
    'Nashville': 1444,
    'Nevada County': 1444,
    'Newark': 1444,
    'Newport': 1444,
    'Newton County': 1444,
    'North Crossett': 1444,
    'North Little Rock': 1444,
    'Ola': 1444,
    'Osceola': 1444,
    'Ouachita County': 1444,
    'Ozark': 1444,
    'Paragould': 1444,
    'Paris': 1444,
    'Parkin': 1444,
    'Pea Ridge': 1444,
    'Perry County': 1444,
    'Perryville': 1444,
    'Phillips County': 1444,
    'Piggott': 1444,
    'Pike County': 1444,
    'Pine Bluff': 1444,
    'Piney': 1444,
    'Pocahontas': 1444,
    'Poinsett County': 1444,
    'Polk County': 1444,
    'Pope County': 1444,
    'Pottsville': 1444,
    'Prairie County': 1444,
    'Prairie Creek': 1444,
    'Prairie Grove': 1444,
    'Prescott': 1444,
    'Pulaski County': 1444,
    'Randolph County': 1444,
    'Rector': 1444,
    'Redfield': 1444,
    'Rison': 1444,
    'Rockwell': 1444,
    'Rogers': 1444,
    'Russellville': 1444,
    'Saint Francis County': 1444,
    'Salem': 1444,
    'Saline County': 1444,
    'Scott County': 1444,
    'Searcy': 1444,
    'Searcy County': 1444,
    'Sebastian County': 1444,
    'Sevier County': 1444,
    'Shannon Hills': 1444,
    'Sharp County': 1444,
    'Sheridan': 1444,
    'Sherwood': 1444,
    'Siloam Springs': 1444,
    'Smackover': 1444,
    'Southside': 1444,
    'Springdale': 1444,
    'Stamps': 1444,
    'Star City': 1444,
    'Stone County': 1444,
    'Stuttgart': 1444,
    'Sulphur Springs': 1444,
    'Texarkana': 1444,
    'Tontitown': 1444,
    'Trumann': 1444,
    'Tuckerman': 1444,
    'Union County': 1444,
    'Van Buren': 1444,
    'Van Buren County': 1444,
    'Vilonia': 1444,
    'Waldo': 1444,
    'Waldron': 1444,
    'Walnut Ridge': 1444,
    'Ward': 1444,
    'Warren': 1444,
    'Washington County': 1444,
    'West Crossett': 1444,
    'West Fork': 1444,
    'West Helena': 1444,
    'West Memphis': 1444,
    'White County': 1444,
    'White Hall': 1444,
    'Woodruff County': 1444,
    'Wrightsville': 1444,
    'Wynne': 1444,
    'Yell County': 1444,
    'Yellville': 1444,
    'Acalanes Ridge': 1416,
    'Acton': 1416,
    'Adelanto': 1416,
    'Agoura': 1416,
    'Agoura Hills': 1416,
    'Agua Dulce': 1416,
    'Aguanga': 1416,
    'Ahwahnee': 1416,
    'Alameda': 1416,
    'Alameda County': 1416,
    'Alamo': 1416,
    'Albany': 1416,
    'Alhambra': 1416,
    'Aliso Viejo': 1416,
    'Allendale': 1416,
    'Alondra Park': 1416,
    'Alpaugh': 1416,
    'Alpine': 1416,
    'Alpine County': 1416,
    'Alta Sierra': 1416,
    'Altadena': 1416,
    'Alturas': 1416,
    'Alum Rock': 1416,
    'Amador County': 1416,
    'American Canyon': 1416,
    'Amesti': 1416,
    'Anaheim': 1416,
    'Anderson': 1416,
    'Angels Camp': 1416,
    'Angwin': 1416,
    'Antelope': 1416,
    'Antioch': 1416,
    'Anza': 1416,
    'Apple Valley': 1416,
    'Aptos': 1416,
    'Aptos Hills-Larkin Valley': 1416,
    'Arbuckle': 1416,
    'Arcadia': 1416,
    'Arcata': 1416,
    'Arden-Arcade': 1416,
    'Armona': 1416,
    'Arnold': 1416,
    'Aromas': 1416,
    'Arroyo Grande': 1416,
    'Artesia': 1416,
    'Arvin': 1416,
    'Ashland': 1416,
    'Atascadero': 1416,
    'Atherton': 1416,
    'Atwater': 1416,
    'Auberry': 1416,
    'Auburn': 1416,
    'Auburn Lake Trails': 1416,
    'August': 1416,
    'Avalon': 1416,
    'Avenal': 1416,
    'Avila Beach': 1416,
    'Avocado Heights': 1416,
    'Azusa': 1416,
    'Bakersfield': 1416,
    'Baldwin Park': 1416,
    'Banning': 1416,
    'Barstow': 1416,
    'Barstow Heights': 1416,
    'Bay Point': 1416,
    'Bayside': 1416,
    'Bayview': 1416,
    'Beale Air Force Base': 1416,
    'Bear Valley Springs': 1416,
    'Beaumont': 1416,
    'Bell': 1416,
    'Bell Gardens': 1416,
    'Bella Vista': 1416,
    'Bellflower': 1416,
    'Belmont': 1416,
    'Belvedere': 1416,
    'Ben Lomond': 1416,
    'Benicia': 1416,
    'Berkeley': 1416,
    'Bermuda Dunes': 1416,
    'Berry Creek': 1416,
    'Bertsch-Oceanview': 1416,
    'Bethel Island': 1416,
    'Beverly Hills': 1416,
    'Big Bear City': 1416,
    'Big Bear Lake': 1416,
    'Big Pine': 1416,
    'Big River': 1416,
    'Biggs': 1416,
    'Biola': 1416,
    'Bishop': 1416,
    'Black Point-Green Point': 1416,
    'Blackhawk': 1416,
    'Bloomington': 1416,
    'Blue Lake': 1416,
    'Blythe': 1416,
    'Bodega Bay': 1416,
    'Bodfish': 1416,
    'Bolinas': 1416,
    'Bonadelle Ranchos-Madera Ranchos': 1416,
    'Bonita': 1416,
    'Bonny Doon': 1416,
    'Bonsall': 1416,
    'Boonville': 1416,
    'Boron': 1416,
    'Boronda': 1416,
    'Borrego Springs': 1416,
    'Bostonia': 1416,
    'Boulder Creek': 1416,
    'Boyes Hot Springs': 1416,
    'Boyle Heights': 1416,
    'Bradbury': 1416,
    'Brawley': 1416,
    'Brea': 1416,
    'Brentwood': 1416,
    'Bret Harte': 1416,
    'Bridgeport': 1416,
    'Brisbane': 1416,
    'Broadmoor': 1416,
    'Brookdale': 1416,
    'Brooktrails': 1416,
    'Buckhorn': 1416,
    'Buellton': 1416,
    'Buena Park': 1416,
    'Buena Vista': 1416,
    'Burbank': 1416,
    'Burlingame': 1416,
    'Burney': 1416,
    'Butte County': 1416,
    'Buttonwillow': 1416,
    'Byron': 1416,
    'Bystrom': 1416,
    'Cabazon': 1416,
    'Calabasas': 1416,
    'Calaveras County': 1416,
    'Calexico': 1416,
    'California City': 1416,
    'Calimesa': 1416,
    'Calipatria': 1416,
    'Calistoga': 1416,
    'Callender': 1416,
    'Camarillo': 1416,
    'Cambria': 1416,
    'Cambrian Park': 1416,
    'Cameron Park': 1416,
    'Camino': 1416,
    'Camp Meeker': 1416,
    'Camp Pendleton North': 1416,
    'Camp Pendleton South': 1416,
    'Campbell': 1416,
    'Campo': 1416,
    'Canoga Park': 1416,
    'Canyon Lake': 1416,
    'Capitola': 1416,
    'Carlsbad': 1416,
    'Carmel Valley Village': 1416,
    'Carmel-by-the-Sea': 1416,
    'Carmichael': 1416,
    'Carpinteria': 1416,
    'Carson': 1416,
    'Caruthers': 1416,
    'Casa Conejo': 1416,
    'Casa de Oro-Mount Helix': 1416,
    'Castaic': 1416,
    'Castro Valley': 1416,
    'Castroville': 1416,
    'Cathedral City': 1416,
    'Cayucos': 1416,
    'Cedar Ridge': 1416,
    'Central Valley (historical)': 1416,
    'Century City': 1416,
    'Ceres': 1416,
    'Cerritos': 1416,
    'Challenge-Brownsville': 1416,
    'Channel Islands Beach': 1416,
    'Charter Oak': 1416,
    'Chatsworth': 1416,
    'Cherry Valley': 1416,
    'Cherryland': 1416,
    'Chester': 1416,
    'Chico': 1416,
    'China Lake Acres': 1416,
    'Chinatown': 1416,
    'Chino': 1416,
    'Chino Hills': 1416,
    'Chowchilla': 1416,
    'Chualar': 1416,
    'Chula Vista': 1416,
    'Citrus': 1416,
    'Citrus Heights': 1416,
    'City and County of San Francisco': 1416,
    'Claremont': 1416,
    'Clay': 1416,
    'Clayton': 1416,
    'Clear Lake Riviera': 1416,
    'Clearlake': 1416,
    'Clearlake Oaks': 1416,
    'Cloverdale': 1416,
    'Clovis': 1416,
    'Coachella': 1416,
    'Coalinga': 1416,
    'Coarsegold': 1416,
    'Cobb': 1416,
    'Colfax': 1416,
    'Collierville': 1416,
    'Colma': 1416,
    'Colton': 1416,
    'Columbia': 1416,
    'Colusa': 1416,
    'Colusa County': 1416,
    'Commerce': 1416,
    'Compton': 1416,
    'Concord': 1416,
    'Contra Costa Centre': 1416,
    'Contra Costa County': 1416,
    'Cool': 1416,
    'Copperopolis': 1416,
    'Corcoran': 1416,
    'Corning': 1416,
    'Corona': 1416,
    'Coronado': 1416,
    'Corralitos': 1416,
    'Corte Madera': 1416,
    'Costa Mesa': 1416,
    'Cotati': 1416,
    'Coto De Caza': 1416,
    'Cottonwood': 1416,
    'Country Club': 1416,
    'Covelo': 1416,
    'Covina': 1416,
    'Crescent City': 1416,
    'Crest': 1416,
    'Crestline': 1416,
    'Crockett': 1416,
    'Cudahy': 1416,
    'Culver City': 1416,
    'Cupertino': 1416,
    'Cutler': 1416,
    'Cutten': 1416,
    'Cypress': 1416,
    'Daly City': 1416,
    'Dana Point': 1416,
    'Danville': 1416,
    'Davis': 1416,
    'Day Valley': 1416,
    'Deer Park': 1416,
    'Del Aire': 1416,
    'Del Mar': 1416,
    'Del Monte Forest': 1416,
    'Del Norte County': 1416,
    'Del Rey': 1416,
    'Del Rey Oaks': 1416,
    'Del Rio': 1416,
    'Delano': 1416,
    'Delhi': 1416,
    'Denair': 1416,
    'Descanso': 1416,
    'Desert Edge': 1416,
    'Desert Hot Springs': 1416,
    'Desert Shores': 1416,
    'Desert View Highlands': 1416,
    'Diablo': 1416,
    'Diamond Bar': 1416,
    'Diamond Springs': 1416,
    'Dinuba': 1416,
    'Discovery Bay': 1416,
    'Dixon': 1416,
    'Dixon Lane-Meadow Creek': 1416,
    'Dogtown': 1416,
    'Dollar Point': 1416,
    'Dos Palos': 1416,
    'Downey': 1416,
    'Downieville': 1416,
    'Duarte': 1416,
    'Dublin': 1416,
    'Dunnigan': 1416,
    'Dunsmuir': 1416,
    'Durham': 1416,
    'Earlimart': 1416,
    'East Foothills': 1416,
    'East Hemet': 1416,
    'East La Mirada': 1416,
    'East Los Angeles': 1416,
    'East Oakdale': 1416,
    'East Palo Alto': 1416,
    'East Pasadena': 1416,
    'East Porterville': 1416,
    'East Quincy': 1416,
    'East Rancho Dominguez': 1416,
    'East Richmond Heights': 1416,
    'East San Gabriel': 1416,
    'East Sonora': 1416,
    'Easton': 1416,
    'Eastvale': 1416,
    'Echo Park': 1416,
    'Edwards Air Force Base': 1416,
    'El Cajon': 1416,
    'El Centro': 1416,
    'El Cerrito': 1416,
    'El Cerrito Corona': 1416,
    'El Dorado County': 1416,
    'El Dorado Hills': 1416,
    'El Granada': 1416,
    'El Monte': 1416,
    'El Rio': 1416,
    'El Segundo': 1416,
    'El Sobrante': 1416,
    'El Verano': 1416,
    'Eldridge': 1416,
    'Elk Grove': 1416,
    'Elkhorn': 1416,
    'Elverta': 1416,
    'Emerald Lake Hills': 1416,
    'Emeryville': 1416,
    'Empire': 1416,
    'Encinitas': 1416,
    'Encino': 1416,
    'Escalon': 1416,
    'Escondido': 1416,
    'Esparto': 1416,
    'Eucalyptus Hills': 1416,
    'Eureka': 1416,
    'Exeter': 1416,
    'Fair Oaks': 1416,
    'Fairbanks Ranch': 1416,
    'Fairfax': 1416,
    'Fairfield': 1416,
    'Fairmead': 1416,
    'Fairview': 1416,
    'Fallbrook': 1416,
    'Farmersville': 1416,
    'Felton': 1416,
    'Ferndale': 1416,
    'Fetters Hot Springs-Agua Caliente': 1416,
    'Fillmore': 1416,
    'Firebaugh': 1416,
    'Florence-Graham': 1416,
    'Florin': 1416,
    'Folsom': 1416,
    'Fontana': 1416,
    'Foothill Farms': 1416,
    'Foothill Ranch': 1416,
    'Ford City': 1416,
    'Forest Meadows': 1416,
    'Forest Ranch': 1416,
    'Foresthill': 1416,
    'Forestville': 1416,
    'Fort Bragg': 1416,
    'Fort Irwin': 1416,
    'Fortuna': 1416,
    'Foster City': 1416,
    'Fountain Valley': 1416,
    'Fowler': 1416,
    'Frazier Park': 1416,
    'Freedom': 1416,
    'Fremont': 1416,
    'French Camp': 1416,
    'Fresno': 1416,
    'Fresno County': 1416,
    'Fruitridge Pocket': 1416,
    'Fullerton': 1416,
    'Galt': 1416,
    'Garden Acres': 1416,
    'Garden Grove': 1416,
    'Gardena': 1416,
    'Garnet': 1416,
    'Georgetown': 1416,
    'Gerber': 1416,
    'Gilroy': 1416,
    'Glen Avon': 1416,
    'Glendale': 1416,
    'Glendora': 1416,
    'Glenn County': 1416,
    'Gold River': 1416,
    'Golden Hills': 1416,
    'Goleta': 1416,
    'Gonzales': 1416,
    'Good Hope': 1416,
    'Goshen': 1416,
    'Grand Terrace': 1416,
    'Granite Bay': 1416,
    'Granite Hills': 1416,
    'Grass Valley': 1416,
    'Graton': 1416,
    'Green Acres': 1416,
    'Green Valley': 1416,
    'Greenacres': 1416,
    'Greenfield': 1416,
    'Greenville': 1416,
    'Gridley': 1416,
    'Grover Beach': 1416,
    'Guadalupe': 1416,
    'Guerneville': 1416,
    'Gustine': 1416,
    'Hacienda Heights': 1416,
    'Half Moon Bay': 1416,
    'Hamilton City': 1416,
    'Hanford': 1416,
    'Happy Camp': 1416,
    'Harbison Canyon': 1416,
    'Hartley': 1416,
    'Hawaiian Gardens': 1416,
    'Hawthorne': 1416,
    'Hayfork': 1416,
    'Hayward': 1416,
    'Healdsburg': 1416,
    'Heber': 1416,
    'Hemet': 1416,
    'Herald': 1416,
    'Hercules': 1416,
    'Hermosa Beach': 1416,
    'Hesperia': 1416,
    'Hidden Hills': 1416,
    'Hidden Meadows': 1416,
    'Hidden Valley Lake': 1416,
    'Highgrove': 1416,
    'Highland': 1416,
    'Highlands-Baywood Park': 1416,
    'Hillsborough': 1416,
    'Hilmar-Irwin': 1416,
    'Hollister': 1416,
    'Hollywood': 1416,
    'Holtville': 1416,
    'Home Garden': 1416,
    'Home Gardens': 1416,
    'Homeland': 1416,
    'Hughson': 1416,
    'Humboldt County': 1416,
    'Humboldt Hill': 1416,
    'Huntington Beach': 1416,
    'Huntington Park': 1416,
    'Huron': 1416,
    'Hydesville': 1416,
    'Idyllwild': 1416,
    'Idyllwild-Pine Cove': 1416,
    'Imperial': 1416,
    'Imperial Beach': 1416,
    'Imperial County': 1416,
    'Indian Wells': 1416,
    'Indio': 1416,
    'Inglewood': 1416,
    'Interlaken': 1416,
    'Inverness': 1416,
    'Inyo County': 1416,
    'Inyokern': 1416,
    'Ione': 1416,
    'Irvine': 1416,
    'Irwindale': 1416,
    'Isla Vista': 1416,
    'Ivanhoe': 1416,
    'Jackson': 1416,
    'Jamestown': 1416,
    'Jamul': 1416,
    'Janesville': 1416,
    'Johnstonville': 1416,
    'Joshua Tree': 1416,
    'Julian': 1416,
    'Jurupa Valley': 1416,
    'Kelseyville': 1416,
    'Kennedy': 1416,
    'Kensington': 1416,
    'Kentfield': 1416,
    'Kenwood': 1416,
    'Kerman': 1416,
    'Kern County': 1416,
    'Kernville': 1416,
    'Kettleman City': 1416,
    'Keyes': 1416,
    'King City': 1416,
    'Kings Beach': 1416,
    'Kings County': 1416,
    'Kingsburg': 1416,
    'Knightsen': 1416,
    'Koreatown': 1416,
    'La Cañada Flintridge': 1416,
    'La Crescenta-Montrose': 1416,
    'La Habra': 1416,
    'La Habra Heights': 1416,
    'La Jolla': 1416,
    'La Mesa': 1416,
    'La Mirada': 1416,
    'La Palma': 1416,
    'La Presa': 1416,
    'La Puente': 1416,
    'La Quinta': 1416,
    'La Riviera': 1416,
    'La Selva Beach': 1416,
    'La Verne': 1416,
    'Ladera': 1416,
    'Ladera Heights': 1416,
    'Ladera Ranch': 1416,
    'Lafayette': 1416,
    'Laguna': 1416,
    'Laguna Beach': 1416,
    'Laguna Hills': 1416,
    'Laguna Niguel': 1416,
    'Laguna Woods': 1416,
    'Lagunitas-Forest Knolls': 1416,
    'Lake Arrowhead': 1416,
    'Lake County': 1416,
    'Lake Elsinore': 1416,
    'Lake Forest': 1416,
    'Lake Isabella': 1416,
    'Lake Los Angeles': 1416,
    'Lake Nacimiento': 1416,
    'Lake of the Pines': 1416,
    'Lake San Marcos': 1416,
    'Lake Wildwood': 1416,
    'Lakeland Village': 1416,
    'Lakeport': 1416,
    'Lakeside': 1416,
    'Lakeview': 1416,
    'Lakewood': 1416,
    'Lamont': 1416,
    'Lancaster': 1416,
    'Larkfield-Wikiup': 1416,
    'Larkspur': 1416,
    'Las Flores': 1416,
    'Las Lomas': 1416,
    'Lassen County': 1416,
    'Lathrop': 1416,
    'Laton': 1416,
    'Lawndale': 1416,
    'Laytonville': 1416,
    'Le Grand': 1416,
    'Lebec': 1416,
    'Lemon Grove': 1416,
    'Lemoore': 1416,
    'Lemoore Station': 1416,
    'Lennox': 1416,
    'Lenwood': 1416,
    'Leona Valley': 1416,
    'Lewiston': 1416,
    'Lexington Hills': 1416,
    'Lincoln': 1416,
    'Lincoln Village': 1416,
    'Linda': 1416,
    'Linden': 1416,
    'Lindsay': 1416,
    'Littlerock': 1416,
    'Live Oak': 1416,
    'Livermore': 1416,
    'Livingston': 1416,
    'Lockeford': 1416,
    'Lodi': 1416,
    'Loma Linda': 1416,
    'Loma Rica': 1416,
    'Lomita': 1416,
    'Lompico': 1416,
    'Lompoc': 1416,
    'London': 1416,
    'Lone Pine': 1416,
    'Long Beach': 1416,
    'Loomis': 1416,
    'Los Alamitos': 1416,
    'Los Alamos': 1416,
    'Los Altos': 1416,
    'Los Altos Hills': 1416,
    'Los Angeles': 1416,
    'Los Angeles County': 1416,
    'Los Banos': 1416,
    'Los Gatos': 1416,
    'Los Molinos': 1416,
    'Los Olivos': 1416,
    'Los Osos': 1416,
    'Los Serranos': 1416,
    'Lost Hills': 1416,
    'Lower Lake': 1416,
    'Loyola': 1416,
    'Lucas Valley-Marinwood': 1416,
    'Lucerne': 1416,
    'Lucerne Valley': 1416,
    'Lynwood': 1416,
    'Madera': 1416,
    'Madera Acres': 1416,
    'Madera County': 1416,
    'Magalia': 1416,
    'Malibu': 1416,
    'Mammoth Lakes': 1416,
    'Manhattan Beach': 1416,
    'Manteca': 1416,
    'March Air Force Base': 1416,
    'Maricopa': 1416,
    'Marin City': 1416,
    'Marin County': 1416,
    'Marina': 1416,
    'Marina del Rey': 1416,
    'Mariposa': 1416,
    'Mariposa County': 1416,
    'Martinez': 1416,
    'Marysville': 1416,
    'Matheny': 1416,
    'Maxwell': 1416,
    'Mayflower Village': 1416,
    'Maywood': 1416,
    'McCloud': 1416,
    'McFarland': 1416,
    'McKinleyville': 1416,
    'Mead Valley': 1416,
    'Meadow Vista': 1416,
    'Meadowbrook': 1416,
    'Mecca': 1416,
    'Meiners Oaks': 1416,
    'Mendocino County': 1416,
    'Mendota': 1416,
    'Menifee': 1416,
    'Menlo Park': 1416,
    'Mentone': 1416,
    'Merced': 1416,
    'Merced County': 1416,
    'Mesa Verde': 1416,
    'Middletown': 1416,
    'Midpines': 1416,
    'Midway City': 1416,
    'Mill Valley': 1416,
    'Millbrae': 1416,
    'Milpitas': 1416,
    'Minkler': 1416,
    'Mira Mesa': 1416,
    'Mira Monte': 1416,
    'Mission Canyon': 1416,
    'Mission District': 1416,
    'Mission Hills': 1416,
    'Mission Viejo': 1416,
    'Modesto': 1416,
    'Modoc County': 1416,
    'Mojave': 1416,
    'Mono County': 1416,
    'Mono Vista': 1416,
    'Monrovia': 1416,
    'Montague': 1416,
    'Montalvin': 1416,
    'Montara': 1416,
    'Montclair': 1416,
    'Monte Rio': 1416,
    'Monte Sereno': 1416,
    'Montebello': 1416,
    'Montecito': 1416,
    'Monterey': 1416,
    'Monterey County': 1416,
    'Monterey Park': 1416,
    'Monument Hills': 1416,
    'Moorpark': 1416,
    'Morada': 1416,
    'Moraga': 1416,
    'Moreno Valley': 1416,
    'Morgan Hill': 1416,
    'Morongo Valley': 1416,
    'Morro Bay': 1416,
    'Moss Beach': 1416,
    'Mount Hermon': 1416,
    'Mount Shasta': 1416,
    'Mountain House': 1416,
    'Mountain Ranch': 1416,
    'Mountain View': 1416,
    'Mountain View Acres': 1416,
    'Murphys': 1416,
    'Murrieta': 1416,
    'Murrieta Hot Springs': 1416,
    'Muscoy': 1416,
    'Myrtletown': 1416,
    'Napa': 1416,
    'Napa County': 1416,
    'National City': 1416,
    'Needles': 1416,
    'Nevada City': 1416,
    'Nevada County': 1416,
    'Newark': 1416,
    'Newcastle': 1416,
    'Newman': 1416,
    'Newport Beach': 1416,
    'Nice': 1416,
    'Niland': 1416,
    'Nipomo': 1416,
    'Noe Valley': 1416,
    'Norco': 1416,
    'North Auburn': 1416,
    'North Edwards': 1416,
    'North El Monte': 1416,
    'North Fair Oaks': 1416,
    'North Highlands': 1416,
    'North Hills': 1416,
    'North Hollywood': 1416,
    'North Lakeport': 1416,
    'North Richmond': 1416,
    'North Tustin': 1416,
    'Northridge': 1416,
    'Norwalk': 1416,
    'Novato': 1416,
    'Nuevo': 1416,
    'Oak Hills': 1416,
    'Oak Park': 1416,
    'Oak View': 1416,
    'Oakdale': 1416,
    'Oakhurst': 1416,
    'Oakland': 1416,
    'Oakley': 1416,
    'Oasis': 1416,
    'Occidental': 1416,
    'Oceano': 1416,
    'Oceanside': 1416,
    'Oildale': 1416,
    'Ojai': 1416,
    'Old Fig Garden': 1416,
    'Olivehurst': 1416,
    'Ontario': 1416,
    'Opal Cliffs': 1416,
    'Orange': 1416,
    'Orange County': 1416,
    'Orange Cove': 1416,
    'Orangevale': 1416,
    'Orcutt': 1416,
    'Orinda': 1416,
    'Orland': 1416,
    'Orosi': 1416,
    'Oroville': 1416,
    'Oroville East': 1416,
    'Oxnard': 1416,
    'Pacheco': 1416,
    'Pacific Grove': 1416,
    'Pacifica': 1416,
    'Pajaro': 1416,
    'Palermo': 1416,
    'Palm Desert': 1416,
    'Palm Springs': 1416,
    'Palmdale': 1416,
    'Palo Alto': 1416,
    'Palo Cedro': 1416,
    'Palos Verdes Estates': 1416,
    'Paradise': 1416,
    'Paramount': 1416,
    'Parksdale': 1416,
    'Parkway': 1416,
    'Parkwood': 1416,
    'Parlier': 1416,
    'Pasadena': 1416,
    'Pasatiempo': 1416,
    'Paso Robles': 1416,
    'Patterson': 1416,
    'Patterson Tract': 1416,
    'Pedley': 1416,
    'Penn Valley': 1416,
    'Penngrove': 1416,
    'Perris': 1416,
    'Petaluma': 1416,
    'Phelan': 1416,
    'Phoenix Lake': 1416,
    'Pico Rivera': 1416,
    'Piedmont': 1416,
    'Pine Grove': 1416,
    'Pine Hills': 1416,
    'Pine Mountain Club': 1416,
    'Pine Valley': 1416,
    'Pinole': 1416,
    'Piñon Hills': 1416,
    'Pioneer': 1416,
    'Piru': 1416,
    'Pismo Beach': 1416,
    'Pittsburg': 1416,
    'Pixley': 1416,
    'Placentia': 1416,
    'Placer County': 1416,
    'Placerville': 1416,
    'Planada': 1416,
    'Pleasant Hill': 1416,
    'Pleasanton': 1416,
    'Plumas County': 1416,
    'Plumas Lake': 1416,
    'Pollock Pines': 1416,
    'Pomona': 1416,
    'Poplar-Cotton Center': 1416,
    'Port Hueneme': 1416,
    'Porterville': 1416,
    'Portola': 1416,
    'Portola Hills': 1416,
    'Portola Valley': 1416,
    'Poway': 1416,
    'Prunedale': 1416,
    'Quail Valley': 1416,
    'Quartz Hill': 1416,
    'Quincy': 1416,
    'Rainbow': 1416,
    'Ramona': 1416,
    'Rancho Calaveras': 1416,
    'Rancho Cordova': 1416,
    'Rancho Cucamonga': 1416,
    'Rancho Mirage': 1416,
    'Rancho Murieta': 1416,
    'Rancho Palos Verdes': 1416,
    'Rancho Penasquitos': 1416,
    'Rancho San Diego': 1416,
    'Rancho Santa Fe': 1416,
    'Rancho Santa Margarita': 1416,
    'Rancho Tehama Reserve': 1416,
    'Red Bluff': 1416,
    'Red Corral': 1416,
    'Redding': 1416,
    'Redlands': 1416,
    'Redondo Beach': 1416,
    'Redway': 1416,
    'Redwood City': 1416,
    'Redwood Shores': 1416,
    'Redwood Valley': 1416,
    'Reedley': 1416,
    'Rialto': 1416,
    'Richgrove': 1416,
    'Richmond': 1416,
    'Ridgecrest': 1416,
    'Ridgemark': 1416,
    'Rio Del Mar': 1416,
    'Rio Dell': 1416,
    'Rio Linda': 1416,
    'Rio Vista': 1416,
    'Ripon': 1416,
    'Riverbank': 1416,
    'Riverdale': 1416,
    'Riverdale Park': 1416,
    'Riverside': 1416,
    'Riverside County': 1416,
    'Rocklin': 1416,
    'Rodeo': 1416,
    'Rohnert Park': 1416,
    'Rolling Hills': 1416,
    'Rolling Hills Estates': 1416,
    'Rollingwood': 1416,
    'Romoland': 1416,
    'Rosamond': 1416,
    'Rosedale': 1416,
    'Roseland': 1416,
    'Rosemead': 1416,
    'Rosemont': 1416,
    'Roseville': 1416,
    'Ross': 1416,
    'Rossmoor': 1416,
    'Rowland Heights': 1416,
    'Rubidoux': 1416,
    'Running Springs': 1416,
    'Sacramento': 1416,
    'Sacramento County': 1416,
    'Saint Helena': 1416,
    'Salida': 1416,
    'Salinas': 1416,
    'Salton City': 1416,
    'San Andreas': 1416,
    'San Anselmo': 1416,
    'San Antonio Heights': 1416,
    'San Benito County': 1416,
    'San Bernardino': 1416,
    'San Bernardino County': 1416,
    'San Bruno': 1416,
    'San Carlos': 1416,
    'San Clemente': 1416,
    'San Diego': 1416,
    'San Diego Country Estates': 1416,
    'San Diego County': 1416,
    'San Dimas': 1416,
    'San Fernando': 1416,
    'San Francisco': 1416,
    'San Gabriel': 1416,
    'San Jacinto': 1416,
    'San Joaquin': 1416,
    'San Joaquin County': 1416,
    'San Joaquin Hills': 1416,
    'San Jose': 1416,
    'San Juan Bautista': 1416,
    'San Juan Capistrano': 1416,
    'San Leandro': 1416,
    'San Lorenzo': 1416,
    'San Luis Obispo': 1416,
    'San Luis Obispo County': 1416,
    'San Marcos': 1416,
    'San Marino': 1416,
    'San Martin': 1416,
    'San Mateo': 1416,
    'San Mateo County': 1416,
    'San Miguel': 1416,
    'San Pablo': 1416,
    'San Pasqual': 1416,
    'San Pedro': 1416,
    'San Rafael': 1416,
    'San Ramon': 1416,
    'Sanger': 1416,
    'Santa Ana': 1416,
    'Santa Barbara': 1416,
    'Santa Barbara County': 1416,
    'Santa Clara': 1416,
    'Santa Clara County': 1416,
    'Santa Clarita': 1416,
    'Santa Cruz': 1416,
    'Santa Cruz County': 1416,
    'Santa Fe Springs': 1416,
    'Santa Margarita': 1416,
    'Santa Maria': 1416,
    'Santa Monica': 1416,
    'Santa Paula': 1416,
    'Santa Rosa': 1416,
    'Santa Susana': 1416,
    'Santa Venetia': 1416,
    'Santa Ynez': 1416,
    'Santee': 1416,
    'Saranap': 1416,
    'Saratoga': 1416,
    'Saticoy': 1416,
    'Sausalito': 1416,
    'Scotts Valley': 1416,
    'Sea Ranch': 1416,
    'Seacliff': 1416,
    'Seal Beach': 1416,
    'Searles Valley': 1416,
    'Seaside': 1416,
    'Sebastopol': 1416,
    'Sedco Hills': 1416,
    'Seeley': 1416,
    'Selma': 1416,
    'Seven Trees': 1416,
    'Shackelford': 1416,
    'Shadow Hills': 1416,
    'Shafter': 1416,
    'Shandon': 1416,
    'Shasta': 1416,
    'Shasta County': 1416,
    'Shasta Lake': 1416,
    'Sheridan': 1416,
    'Sherman Oaks': 1416,
    'Shingle Springs': 1416,
    'Shingletown': 1416,
    'Sierra County': 1416,
    'Sierra Madre': 1416,
    'Signal Hill': 1416,
    'Silver Lake': 1416,
    'Silver Lakes': 1416,
    'Simi Valley': 1416,
    'Siskiyou County': 1416,
    'Sky Valley': 1416,
    'Sleepy Hollow': 1416,
    'Soda Bay': 1416,
    'Solana Beach': 1416,
    'Solano County': 1416,
    'Soledad': 1416,
    'Solvang': 1416,
    'Sonoma': 1416,
    'Sonoma County': 1416,
    'Sonora': 1416,
    'Soquel': 1416,
    'Sorrento Valley': 1416,
    'Soulsbyville': 1416,
    'South Dos Palos': 1416,
    'South El Monte': 1416,
    'South Gate': 1416,
    'South Lake Tahoe': 1416,
    'South Oroville': 1416,
    'South Pasadena': 1416,
    'South San Francisco': 1416,
    'South San Gabriel': 1416,
    'South San Jose Hills': 1416,
    'South Taft': 1416,
    'South Whittier': 1416,
    'South Yuba City': 1416,
    'Spring Valley': 1416,
    'Spring Valley Lake': 1416,
    'Squaw Valley': 1416,
    'Stallion Springs': 1416,
    'Stanford': 1416,
    'Stanislaus County': 1416,
    'Stanton': 1416,
    'Stevenson Ranch': 1416,
    'Stockton': 1416,
    'Stratford': 1416,
    'Strathmore': 1416,
    'Strawberry': 1416,
    'Studio City': 1416,
    'Suisun': 1416,
    'Summerland': 1416,
    'Sun City': 1416,
    'Sun Village': 1416,
    'Sunland': 1416,
    'Sunnyside': 1416,
    'Sunnyside-Tahoe City': 1416,
    'Sunnyslope': 1416,
    'Sunnyvale': 1416,
    'Susanville': 1416,
    'Sutter': 1416,
    'Sutter County': 1416,
    'Sutter Creek': 1416,
    'Taft': 1416,
    'Taft Heights': 1416,
    'Taft Mosswood': 1416,
    'Tahoe Vista': 1416,
    'Tahoma': 1416,
    'Talmage': 1416,
    'Tamalpais Valley': 1416,
    'Tamalpais-Homestead Valley': 1416,
    'Tara Hills': 1416,
    'Tarpey Village': 1416,
    'Tehachapi': 1416,
    'Tehama County': 1416,
    'Temecula': 1416,
    'Temelec': 1416,
    'Temple City': 1416,
    'Templeton': 1416,
    'Terra Bella': 1416,
    'Teviston': 1416,
    'Thermal': 1416,
    'Thermalito': 1416,
    'Thornton': 1416,
    'Thousand Oaks': 1416,
    'Thousand Palms': 1416,
    'Three Rivers': 1416,
    'Tiburon': 1416,
    'Tierra Buena': 1416,
    'Tipton': 1416,
    'Topanga': 1416,
    'Toro Canyon': 1416,
    'Torrance': 1416,
    'Trabuco Canyon': 1416,
    'Tracy': 1416,
    'Trinity County': 1416,
    'Truckee': 1416,
    'Tujunga': 1416,
    'Tulare': 1416,
    'Tulare County': 1416,
    'Tuolumne City': 1416,
    'Tuolumne County': 1416,
    'Turlock': 1416,
    'Tustin': 1416,
    'Twain Harte': 1416,
    'Twentynine Palms': 1416,
    'Twin Lakes': 1416,
    'Ukiah': 1416,
    'Union City': 1416,
    'Universal City': 1416,
    'Upland': 1416,
    'Upper Lake': 1416,
    'Vacaville': 1416,
    'Val Verde': 1416,
    'Valencia': 1416,
    'Valinda': 1416,
    'Valle Vista': 1416,
    'Vallejo': 1416,
    'Valley Center': 1416,
    'Valley Glen': 1416,
    'Valley Springs': 1416,
    'Van Nuys': 1416,
    'Vandenberg Air Force Base': 1416,
    'Vandenberg Village': 1416,
    'Venice': 1416,
    'Ventura': 1416,
    'Ventura County': 1416,
    'Victorville': 1416,
    'View Park-Windsor Hills': 1416,
    'Villa Park': 1416,
    'Vincent': 1416,
    'Vine Hill': 1416,
    'Vineyard': 1416,
    'Visalia': 1416,
    'Visitacion Valley': 1416,
    'Vista': 1416,
    'Vista Santa Rosa': 1416,
    'Waldon': 1416,
    'Walnut': 1416,
    'Walnut Creek': 1416,
    'Walnut Grove': 1416,
    'Walnut Park': 1416,
    'Wasco': 1416,
    'Waterford': 1416,
    'Watsonville': 1416,
    'Weaverville': 1416,
    'Weed': 1416,
    'Weedpatch': 1416,
    'Weldon': 1416,
    'West Athens': 1416,
    'West Bishop': 1416,
    'West Carson': 1416,
    'West Covina': 1416,
    'West Hills': 1416,
    'West Hollywood': 1416,
    'West Menlo Park': 1416,
    'West Modesto': 1416,
    'West Park': 1416,
    'West Puente Valley': 1416,
    'West Rancho Dominguez': 1416,
    'West Sacramento': 1416,
    'West Whittier-Los Nietos': 1416,
    'Westhaven-Moonstone': 1416,
    'Westlake Village': 1416,
    'Westminster': 1416,
    'Westmont': 1416,
    'Westmorland': 1416,
    'Westwood': 1416,
    'Wheatland': 1416,
    'Whittier': 1416,
    'Wildomar': 1416,
    'Williams': 1416,
    'Willits': 1416,
    'Willow Creek': 1416,
    'Willowbrook': 1416,
    'Willows': 1416,
    'Wilton': 1416,
    'Winchester': 1416,
    'Windsor': 1416,
    'Winter Gardens': 1416,
    'Winters': 1416,
    'Winton': 1416,
    'Wofford Heights': 1416,
    'Woodacre': 1416,
    'Woodbridge': 1416,
    'Woodcrest': 1416,
    'Woodlake': 1416,
    'Woodland': 1416,
    'Woodland Hills': 1416,
    'Woodside': 1416,
    'Woodville': 1416,
    'Wrightwood': 1416,
    'Yolo County': 1416,
    'Yorba Linda': 1416,
    'Yosemite Lakes': 1416,
    'Yosemite Valley': 1416,
    'Yountville': 1416,
    'Yreka': 1416,
    'Yuba City': 1416,
    'Yuba County': 1416,
    'Yucaipa': 1416,
    'Yucca Valley': 1416,
    'Acres Green': 1450,
    'Adams County': 1450,
    'Air Force Academy': 1450,
    'Akron': 1450,
    'Alamosa': 1450,
    'Alamosa County': 1450,
    'Alamosa East': 1450,
    'Applewood': 1450,
    'Arapahoe County': 1450,
    'Archuleta County': 1450,
    'Aristocrat Ranchettes': 1450,
    'Arvada': 1450,
    'Aspen': 1450,
    'Ault': 1450,
    'Aurora': 1450,
    'Avon': 1450,
    'Baca County': 1450,
    'Basalt': 1450,
    'Battlement Mesa': 1450,
    'Bayfield': 1450,
    'Bennett': 1450,
    'Bent County': 1450,
    'Berkley': 1450,
    'Berthoud': 1450,
    'Black Forest': 1450,
    'Boulder': 1450,
    'Boulder County': 1450,
    'Breckenridge': 1450,
    'Brighton': 1450,
    'Broomfield': 1450,
    'Broomfield County': 1450,
    'Brush': 1450,
    'Buena Vista': 1450,
    'Burlington': 1450,
    'Byers': 1450,
    'Campion': 1450,
    'Cañon City': 1450,
    'Carbondale': 1450,
    'Carriage Club': 1450,
    'Cascade-Chipita Park': 1450,
    'Castle Pines': 1450,
    'Castle Pines North': 1450,
    'Castle Rock': 1450,
    'Castlewood': 1450,
    'Cedaredge': 1450,
    'Centennial': 1450,
    'Center': 1450,
    'Central City': 1450,
    'Chaffee County': 1450,
    'Cherry Creek': 1450,
    'Cherry Hills Village': 1450,
    'Cheyenne County': 1450,
    'Cheyenne Wells': 1450,
    'Cimarron Hills': 1450,
    'Clear Creek County': 1450,
    'Clifton': 1450,
    'Coal Creek': 1450,
    'Colorado City': 1450,
    'Colorado Springs': 1450,
    'Columbine': 1450,
    'Columbine Valley': 1450,
    'Commerce City': 1450,
    'Conejos': 1450,
    'Conejos County': 1450,
    'Cortez': 1450,
    'Costilla County': 1450,
    'Craig': 1450,
    'Creede': 1450,
    'Crested Butte': 1450,
    'Cripple Creek': 1450,
    'Crowley County': 1450,
    'Custer County': 1450,
    'Dacono': 1450,
    'Del Norte': 1450,
    'Delta': 1450,
    'Delta County': 1450,
    'Denver': 1450,
    'Denver County': 1450,
    'Derby': 1450,
    'Dolores County': 1450,
    'Douglas County': 1450,
    'Dove Creek': 1450,
    'Dove Valley': 1450,
    'Durango': 1450,
    'Eads': 1450,
    'Eagle': 1450,
    'Eagle County': 1450,
    'Eaton': 1450,
    'Edgewater': 1450,
    'Edwards': 1450,
    'El Jebel': 1450,
    'El Paso County': 1450,
    'Elbert County': 1450,
    'Elizabeth': 1450,
    'Ellicott': 1450,
    'Englewood': 1450,
    'Erie': 1450,
    'Estes Park': 1450,
    'Evans': 1450,
    'Evergreen': 1450,
    'Fairplay': 1450,
    'Federal Heights': 1450,
    'Firestone': 1450,
    'Florence': 1450,
    'Fort Carson': 1450,
    'Fort Collins': 1450,
    'Fort Lupton': 1450,
    'Fort Morgan': 1450,
    'Fountain': 1450,
    'Fowler': 1450,
    'Fraser': 1450,
    'Frederick': 1450,
    'Fremont County': 1450,
    'Frisco': 1450,
    'Fruita': 1450,
    'Fruitvale': 1450,
    'Garfield County': 1450,
    'Genesee': 1450,
    'Georgetown': 1450,
    'Gilcrest': 1450,
    'Gilpin County': 1450,
    'Glendale': 1450,
    'Gleneagle': 1450,
    'Glenwood Springs': 1450,
    'Golden': 1450,
    'Granby': 1450,
    'Grand County': 1450,
    'Grand Junction': 1450,
    'Greeley': 1450,
    'Greenwood Village': 1450,
    'Gunbarrel': 1450,
    'Gunnison': 1450,
    'Gunnison County': 1450,
    'Gypsum': 1450,
    'Hayden': 1450,
    'Highlands Ranch': 1450,
    'Hinsdale County': 1450,
    'Holly Hills': 1450,
    'Holyoke': 1450,
    'Hot Sulphur Springs': 1450,
    'Hudson': 1450,
    'Huerfano County': 1450,
    'Hugo': 1450,
    'Idaho Springs': 1450,
    'Indian Hills': 1450,
    'Inverness': 1450,
    'Jackson County': 1450,
    'Jefferson County': 1450,
    'Johnstown': 1450,
    'Julesburg': 1450,
    'Keenesburg': 1450,
    'Ken Caryl': 1450,
    'Kersey': 1450,
    'Keystone': 1450,
    'Kiowa': 1450,
    'Kiowa County': 1450,
    'Kit Carson County': 1450,
    'Kittredge': 1450,
    'Kremmling': 1450,
    'La Junta': 1450,
    'La Plata County': 1450,
    'La Salle': 1450,
    'Lafayette': 1450,
    'Lake City': 1450,
    'Lake County': 1450,
    'Lakewood': 1450,
    'Lamar': 1450,
    'Laporte': 1450,
    'Larimer County': 1450,
    'Las Animas': 1450,
    'Las Animas County': 1450,
    'Leadville': 1450,
    'Leadville North': 1450,
    'Limon': 1450,
    'Lincoln County': 1450,
    'Lincoln Park': 1450,
    'Littleton': 1450,
    'Lochbuie': 1450,
    'Logan County': 1450,
    'Loma': 1450,
    'Lone Tree': 1450,
    'Longmont': 1450,
    'Louisville': 1450,
    'Loveland': 1450,
    'Lyons': 1450,
    'Mancos': 1450,
    'Manitou Springs': 1450,
    'Mead': 1450,
    'Meeker': 1450,
    'Meridian': 1450,
    'Mesa County': 1450,
    'Milliken': 1450,
    'Mineral County': 1450,
    'Minturn': 1450,
    'Moffat County': 1450,
    'Monte Vista': 1450,
    'Montezuma County': 1450,
    'Montrose': 1450,
    'Montrose County': 1450,
    'Monument': 1450,
    'Morgan County': 1450,
    'Mountain Village': 1450,
    'Nederland': 1450,
    'New Castle': 1450,
    'Niwot': 1450,
    'Northglenn': 1450,
    'Olathe': 1450,
    'Orchard City': 1450,
    'Orchard Mesa': 1450,
    'Ordway': 1450,
    'Otero County': 1450,
    'Ouray': 1450,
    'Ouray County': 1450,
    'Pagosa Springs': 1450,
    'Palisade': 1450,
    'Palmer Lake': 1450,
    'Paonia': 1450,
    'Parachute': 1450,
    'Park County': 1450,
    'Parker': 1450,
    'Penrose': 1450,
    'Perry Park': 1450,
    'Phillips County': 1450,
    'Pitkin County': 1450,
    'Platteville': 1450,
    'Ponderosa Park': 1450,
    'Prowers County': 1450,
    'Pueblo': 1450,
    'Pueblo County': 1450,
    'Pueblo West': 1450,
    'Rangely': 1450,
    'Redlands': 1450,
    'Rifle': 1450,
    'Rio Blanco County': 1450,
    'Rio Grande County': 1450,
    'Rocky Ford': 1450,
    'Routt County': 1450,
    'Roxborough Park': 1450,
    'Saguache': 1450,
    'Saguache County': 1450,
    'Salida': 1450,
    'San Juan County': 1450,
    'San Luis': 1450,
    'San Miguel County': 1450,
    'Security-Widefield': 1450,
    'Sedgwick County': 1450,
    'Severance': 1450,
    'Shaw Heights': 1450,
    'Sheridan': 1450,
    'Sherrelwood': 1450,
    'Silt': 1450,
    'Silverthorne': 1450,
    'Silverton': 1450,
    'Snowmass Village': 1450,
    'Southglenn': 1450,
    'Springfield': 1450,
    'Steamboat Springs': 1450,
    'Sterling': 1450,
    'Stonegate': 1450,
    'Strasburg': 1450,
    'Stratmoor': 1450,
    'Summit County': 1450,
    'Superior': 1450,
    'Teller County': 1450,
    'Telluride': 1450,
    'The Pinery': 1450,
    'Thornton': 1450,
    'Todd Creek': 1450,
    'Towaoc': 1450,
    'Trinidad': 1450,
    'Twin Lakes': 1450,
    'Upper Bear Creek': 1450,
    'Vail': 1450,
    'Walden': 1450,
    'Walsenburg': 1450,
    'Washington County': 1450,
    'Welby': 1450,
    'Weld County': 1450,
    'Wellington': 1450,
    'West Pleasant View': 1450,
    'Westcliffe': 1450,
    'Westminster': 1450,
    'Wheat Ridge': 1450,
    'Windsor': 1450,
    'Woodland Park': 1450,
    'Woodmoor': 1450,
    'Wray': 1450,
    'Yuma': 1450,
    'Yuma County': 1450,
    'Ansonia': 1435,
    'Baltic': 1435,
    'Bethel': 1435,
    'Bethlehem Village': 1435,
    'Blue Hills': 1435,
    'Branford': 1435,
    'Branford Center': 1435,
    'Bridgeport': 1435,
    'Bristol': 1435,
    'Byram': 1435,
    'Canaan': 1435,
    'Canton Valley': 1435,
    'Central Waterford': 1435,
    'Cheshire': 1435,
    'Cheshire Village': 1435,
    'Chester Center': 1435,
    'City of Milford (balance)': 1435,
    'Clinton': 1435,
    'Colchester': 1435,
    'Collinsville': 1435,
    'Conning Towers-Nautilus Park': 1435,
    'Cos Cob': 1435,
    'Coventry Lake': 1435,
    'Cromwell': 1435,
    'Crystal Lake': 1435,
    'Danbury': 1435,
    'Danielson': 1435,
    'Darien': 1435,
    'Deep River Center': 1435,
    'Derby': 1435,
    'Durham': 1435,
    'East Brooklyn': 1435,
    'East Haddam': 1435,
    'East Hampton': 1435,
    'East Hartford': 1435,
    'East Haven': 1435,
    'East Norwalk': 1435,
    'East Windsor': 1435,
    'Easton': 1435,
    'Ellington': 1435,
    'Enfield': 1435,
    'Essex Village': 1435,
    'Fairfield': 1435,
    'Fairfield County': 1435,
    'Farmington': 1435,
    'Gales Ferry': 1435,
    'Georgetown': 1435,
    'Glastonbury': 1435,
    'Glastonbury Center': 1435,
    'Glenville': 1435,
    'Greenwich': 1435,
    'Groton': 1435,
    'Guilford': 1435,
    'Guilford Center': 1435,
    'Hamden': 1435,
    'Hartford': 1435,
    'Hartford County': 1435,
    'Hazardville': 1435,
    'Hebron': 1435,
    'Heritage Village': 1435,
    'Higganum': 1435,
    'Jewett City': 1435,
    'Kensington': 1435,
    'Kent': 1435,
    'Killingly Center': 1435,
    'Lake Pocotopaug': 1435,
    'Ledyard': 1435,
    'Lisbon': 1435,
    'Litchfield': 1435,
    'Litchfield County': 1435,
    'Long Hill': 1435,
    'Madison': 1435,
    'Madison Center': 1435,
    'Manchester': 1435,
    'Mansfield City': 1435,
    'Meriden': 1435,
    'Middlebury': 1435,
    'Middlesex County': 1435,
    'Middletown': 1435,
    'Milford': 1435,
    'Montville Center': 1435,
    'Moodus': 1435,
    'Moosup': 1435,
    'Mystic': 1435,
    'Naugatuck': 1435,
    'New Britain': 1435,
    'New Canaan': 1435,
    'New Fairfield': 1435,
    'New Hartford Center': 1435,
    'New Haven': 1435,
    'New Haven County': 1435,
    'New London': 1435,
    'New London County': 1435,
    'New Milford': 1435,
    'New Preston': 1435,
    'Newington': 1435,
    'Newtown': 1435,
    'Niantic': 1435,
    'Noank': 1435,
    'North Branford': 1435,
    'North Granby': 1435,
    'North Grosvenor Dale': 1435,
    'North Haven': 1435,
    'North Stamford': 1435,
    'Northwest Harwinton': 1435,
    'Norwalk': 1435,
    'Norwich': 1435,
    'Oakville': 1435,
    'Old Greenwich': 1435,
    'Old Mystic': 1435,
    'Old Saybrook': 1435,
    'Old Saybrook Center': 1435,
    'Orange': 1435,
    'Oxford': 1435,
    'Oxoboxo River': 1435,
    'Pawcatuck': 1435,
    'Pemberwick': 1435,
    'Plainfield': 1435,
    'Plainfield Village': 1435,
    'Plainville': 1435,
    'Plymouth': 1435,
    'Poquonock Bridge': 1435,
    'Portland': 1435,
    'Preston City': 1435,
    'Prospect': 1435,
    'Putnam': 1435,
    'Quinebaug': 1435,
    'Ridgefield': 1435,
    'Riverside': 1435,
    'Rockville': 1435,
    'Salem': 1435,
    'Salmon Brook': 1435,
    'Saybrook Manor': 1435,
    'Seymour': 1435,
    'Shelton': 1435,
    'Sherman': 1435,
    'Sherwood Manor': 1435,
    'Simsbury Center': 1435,
    'Somers': 1435,
    'South Coventry': 1435,
    'South Windham': 1435,
    'South Windsor': 1435,
    'South Woodstock': 1435,
    'Southbury': 1435,
    'Southport': 1435,
    'Southwood Acres': 1435,
    'Stafford': 1435,
    'Stafford Springs': 1435,
    'Stamford': 1435,
    'Storrs': 1435,
    'Stratford': 1435,
    'Suffield Depot': 1435,
    'Tariffville': 1435,
    'Terramuggus': 1435,
    'Terryville': 1435,
    'Thomaston': 1435,
    'Thompson': 1435,
    'Thompsonville': 1435,
    'Tolland': 1435,
    'Tolland County': 1435,
    'Torrington': 1435,
    'Trumbull': 1435,
    'Uncasville': 1435,
    'Wallingford': 1435,
    'Wallingford Center': 1435,
    'Washington': 1435,
    'Waterbury': 1435,
    'Waterford': 1435,
    'Watertown': 1435,
    'Wauregan': 1435,
    'Weatogue': 1435,
    'West Hartford': 1435,
    'West Haven': 1435,
    'West Simsbury': 1435,
    'West Torrington': 1435,
    'Westbrook Center': 1435,
    'Westport': 1435,
    'Wethersfield': 1435,
    'Willimantic': 1435,
    'Wilton': 1435,
    'Winchester Center': 1435,
    'Windham': 1435,
    'Windham County': 1435,
    'Windsor': 1435,
    'Windsor Locks': 1435,
    'Winsted': 1435,
    'Wolcott': 1435,
    'Woodbridge': 1435,
    'Woodbury': 1435,
    'Woodbury Center': 1435,
    'Woodmont': 1435,
    'Bear': 1399,
    'Bellefonte': 1399,
    'Bethany Beach': 1399,
    'Blades': 1399,
    'Bridgeville': 1399,
    'Brookside': 1399,
    'Camden': 1399,
    'Cheswold': 1399,
    'Claymont': 1399,
    'Clayton': 1399,
    'Delaware City': 1399,
    'Delmar': 1399,
    'Dover': 1399,
    'Dover Base Housing': 1399,
    'Edgemoor': 1399,
    'Elsmere': 1399,
    'Felton': 1399,
    'Georgetown': 1399,
    'Glasgow': 1399,
    'Greenville': 1399,
    'Greenwood': 1399,
    'Harrington': 1399,
    'Highland Acres': 1399,
    'Hockessin': 1399,
    'Kent Acres': 1399,
    'Kent County': 1399,
    'Laurel': 1399,
    'Lewes': 1399,
    'Long Neck': 1399,
    'Middletown': 1399,
    'Milford': 1399,
    'Millsboro': 1399,
    'Milton': 1399,
    'New Castle': 1399,
    'New Castle County': 1399,
    'Newark': 1399,
    'Newport': 1399,
    'North Star': 1399,
    'Ocean View': 1399,
    'Pike Creek': 1399,
    'Pike Creek Valley': 1399,
    'Rehoboth Beach': 1399,
    'Rising Sun-Lebanon': 1399,
    'Riverview': 1399,
    'Rodney Village': 1399,
    'Seaford': 1399,
    'Selbyville': 1399,
    'Smyrna': 1399,
    'Sussex County': 1399,
    'Townsend': 1399,
    'Wilmington': 1399,
    'Wilmington Manor': 1399,
    'Woodside East': 1399,
    'Wyoming': 1399,
    'Adams Morgan': 1437,
    'Bloomingdale': 1437,
    'Chevy Chase': 1437,
    'Shaw': 1437,
    'Washington, D.C.': 1437,
    'Aberdeen': 1436,
    'Alachua': 1436,
    'Alachua County': 1436,
    'Alafaya': 1436,
    'Allapattah': 1436,
    'Altamonte Springs': 1436,
    'Alturas': 1436,
    'Alva': 1436,
    'Andover': 1436,
    'Anna Maria': 1436,
    'Apalachicola': 1436,
    'Apollo Beach': 1436,
    'Apopka': 1436,
    'Arcadia': 1436,
    'Archer': 1436,
    'Asbury Lake': 1436,
    'Astatula': 1436,
    'Astor': 1436,
    'Atlantic Beach': 1436,
    'Atlantis': 1436,
    'Auburndale': 1436,
    'Aventura': 1436,
    'Avon Park': 1436,
    'Azalea Park': 1436,
    'Babson Park': 1436,
    'Bagdad': 1436,
    'Baker County': 1436,
    'Bal Harbour': 1436,
    'Baldwin': 1436,
    'Balm': 1436,
    'Bartow': 1436,
    'Bay County': 1436,
    'Bay Harbor Islands': 1436,
    'Bay Hill': 1436,
    'Bay Pines': 1436,
    'Bayonet Point': 1436,
    'Bayshore Gardens': 1436,
    'Beacon Square': 1436,
    'Bee Ridge': 1436,
    'Bellair-Meadowbrook Terrace': 1436,
    'Belle Glade': 1436,
    'Belle Glade Camp': 1436,
    'Belle Isle': 1436,
    'Belleair': 1436,
    'Belleair Beach': 1436,
    'Belleair Bluffs': 1436,
    'Belleview': 1436,
    'Bellview': 1436,
    'Beverly Hills': 1436,
    'Big Coppitt Key': 1436,
    'Big Pine Key': 1436,
    'Biscayne Park': 1436,
    'Bithlo': 1436,
    'Black Diamond': 1436,
    'Bloomingdale': 1436,
    'Blountstown': 1436,
    'Boca Del Mar': 1436,
    'Boca Pointe': 1436,
    'Boca Raton': 1436,
    'Bokeelia': 1436,
    'Bonifay': 1436,
    'Bonita Springs': 1436,
    'Boulevard Gardens': 1436,
    'Bowling Green': 1436,
    'Boyette': 1436,
    'Boynton Beach': 1436,
    'Bradenton': 1436,
    'Bradenton Beach': 1436,
    'Bradford County': 1436,
    'Brandon': 1436,
    'Brent': 1436,
    'Brevard County': 1436,
    'Bristol': 1436,
    'Broadview Park': 1436,
    'Bronson': 1436,
    'Brookridge': 1436,
    'Brooksville': 1436,
    'Broward County': 1436,
    'Broward Estates': 1436,
    'Brownsville': 1436,
    'Buckhead Ridge': 1436,
    'Buckingham': 1436,
    'Buenaventura Lakes': 1436,
    'Bunche Park': 1436,
    'Bunnell': 1436,
    'Burnt Store Marina': 1436,
    'Bushnell': 1436,
    'Butler Beach': 1436,
    'Calhoun County': 1436,
    'Callahan': 1436,
    'Callaway': 1436,
    'Campbell': 1436,
    'Cantonment': 1436,
    'Cape Canaveral': 1436,
    'Cape Coral': 1436,
    'Carol City': 1436,
    'Carrabelle': 1436,
    'Carrollwood': 1436,
    'Carrollwood Village': 1436,
    'Carver Ranches': 1436,
    'Casselberry': 1436,
    'Cedar Grove': 1436,
    'Celebration': 1436,
    'Center Hill': 1436,
    'Century': 1436,
    'Charlotte County': 1436,
    'Charlotte Harbor': 1436,
    'Charlotte Park': 1436,
    'Chattahoochee': 1436,
    'Cheval': 1436,
    'Chiefland': 1436,
    'Chipley': 1436,
    'Christmas': 1436,
    'Chuluota': 1436,
    'Citra': 1436,
    'Citrus County': 1436,
    'Citrus Hills': 1436,
    'Citrus Park': 1436,
    'Citrus Ridge': 1436,
    'Citrus Springs': 1436,
    'Clarcona': 1436,
    'Clay County': 1436,
    'Clearwater': 1436,
    'Clermont': 1436,
    'Cleveland': 1436,
    'Clewiston': 1436,
    'Cocoa': 1436,
    'Cocoa Beach': 1436,
    'Cocoa West': 1436,
    'Coconut Creek': 1436,
    'Coconut Grove': 1436,
    'Collier County': 1436,
    'Columbia County': 1436,
    'Combee Settlement': 1436,
    'Connerton': 1436,
    'Conway': 1436,
    'Cooper City': 1436,
    'Coral Gables': 1436,
    'Coral Springs': 1436,
    'Coral Terrace': 1436,
    'Cortez': 1436,
    'Country Club': 1436,
    'Country Walk': 1436,
    'Crawfordville': 1436,
    'Crescent City': 1436,
    'Crestview': 1436,
    'Crooked Lake Park': 1436,
    'Cross City': 1436,
    'Crystal Lake': 1436,
    'Crystal River': 1436,
    'Crystal Springs': 1436,
    'Cudjoe Key': 1436,
    'Cutler': 1436,
    'Cutler Bay': 1436,
    'Cutler Ridge': 1436,
    'Cypress Gardens': 1436,
    'Cypress Lake': 1436,
    'Cypress Quarters': 1436,
    'Dade City': 1436,
    'Dade City North': 1436,
    'Dania Beach': 1436,
    'Davenport': 1436,
    'Davie': 1436,
    'Daytona Beach': 1436,
    'Daytona Beach Shores': 1436,
    'De Land Southwest': 1436,
    'De Leon Springs': 1436,
    'DeBary': 1436,
    'Deerfield Beach': 1436,
    'DeFuniak Springs': 1436,
    'DeLand': 1436,
    'Delray Beach': 1436,
    'Deltona': 1436,
    'DeSoto County': 1436,
    'Desoto Lakes': 1436,
    'Destin': 1436,
    'Dixie County': 1436,
    'Doctor Phillips': 1436,
    'Doral': 1436,
    'Dover': 1436,
    'Dundee': 1436,
    'Dunedin': 1436,
    'Dunnellon': 1436,
    'Duval County': 1436,
    'Eagle Lake': 1436,
    'East Bronson': 1436,
    'East Lake': 1436,
    'East Lake-Orient Park': 1436,
    'East Milton': 1436,
    'East Naples': 1436,
    'East Palatka': 1436,
    'East Pensacola Heights': 1436,
    'East Perrine': 1436,
    'Eastpoint': 1436,
    'Eatonville': 1436,
    'Edgewater': 1436,
    'Edgewood': 1436,
    'Eglin Air Force Base': 1436,
    'Eglin Village': 1436,
    'Egypt Lake-Leto': 1436,
    'El Portal': 1436,
    'Elfers': 1436,
    'Ellenton': 1436,
    'Englewood': 1436,
    'Ensley': 1436,
    'Escambia County': 1436,
    'Estero': 1436,
    'Eustis': 1436,
    'Fairview Shores': 1436,
    'Feather Sound': 1436,
    'Fellsmere': 1436,
    'Fern Park': 1436,
    'Fernandina Beach': 1436,
    'Ferry Pass': 1436,
    'Fish Hawk': 1436,
    'Five Points': 1436,
    'Flagami': 1436,
    'Flagler Beach': 1436,
    'Flagler County': 1436,
    'Flagler Estates': 1436,
    'Fleming Island': 1436,
    'Floral City': 1436,
    'Florida City': 1436,
    'Florida Ridge': 1436,
    'Forest City': 1436,
    'Fort Lauderdale': 1436,
    'Fort Meade': 1436,
    'Fort Myers': 1436,
    'Fort Myers Beach': 1436,
    'Fort Myers Shores': 1436,
    'Fort Pierce': 1436,
    'Fort Pierce North': 1436,
    'Fort Pierce South': 1436,
    'Fort Walton Beach': 1436,
    'Fountainebleau': 1436,
    'Four Corners': 1436,
    'Franklin County': 1436,
    'Freeport': 1436,
    'Frostproof': 1436,
    'Fruit Cove': 1436,
    'Fruitland Park': 1436,
    'Fruitville': 1436,
    'Fuller Heights': 1436,
    'Fussels Corner': 1436,
    'Gadsden County': 1436,
    'Gainesville': 1436,
    'Gandy': 1436,
    'Gateway': 1436,
    'Geneva': 1436,
    'Gibsonia': 1436,
    'Gibsonton': 1436,
    'Gifford': 1436,
    'Gilchrist County': 1436,
    'Glades County': 1436,
    'Gladeview': 1436,
    'Glencoe': 1436,
    'Glenvar Heights': 1436,
    'Golden Gate': 1436,
    'Golden Glades': 1436,
    'Goldenrod': 1436,
    'Gonzalez': 1436,
    'Gotha': 1436,
    'Goulding': 1436,
    'Goulds': 1436,
    'Graceville': 1436,
    'Grant-Valkaria': 1436,
    'Greater Northdale': 1436,
    'Green Cove Springs': 1436,
    'Greenacres City': 1436,
    'Greenbriar': 1436,
    'Gretna': 1436,
    'Grove City': 1436,
    'Groveland': 1436,
    'Gulf Breeze': 1436,
    'Gulf County': 1436,
    'Gulf Gate Estates': 1436,
    'Gulfport': 1436,
    'Haines City': 1436,
    'Hallandale Beach': 1436,
    'Hamilton County': 1436,
    'Harbor Bluffs': 1436,
    'Harbour Heights': 1436,
    'Hardee County': 1436,
    'Harlem': 1436,
    'Harlem Heights': 1436,
    'Havana': 1436,
    'Haverhill': 1436,
    'Hawthorne': 1436,
    'Heathrow': 1436,
    'Hendry County': 1436,
    'Heritage Pines': 1436,
    'Hernando': 1436,
    'Hernando Beach': 1436,
    'Hernando County': 1436,
    'Hialeah': 1436,
    'Hialeah Gardens': 1436,
    'High Point': 1436,
    'High Springs': 1436,
    'Highland Beach': 1436,
    'Highland City': 1436,
    'Highlands County': 1436,
    'Hiland Park': 1436,
    'Hill   n Dale': 1436,
    'Hilliard': 1436,
    'Hillsboro Beach': 1436,
    'Hillsborough County': 1436,
    'Hobe Sound': 1436,
    'Holden Heights': 1436,
    'Holiday': 1436,
    'Holley': 1436,
    'Holly Hill': 1436,
    'Hollywood': 1436,
    'Holmes Beach': 1436,
    'Holmes County': 1436,
    'Homestead': 1436,
    'Homosassa': 1436,
    'Homosassa Springs': 1436,
    'Horizon West': 1436,
    'Howey-in-the-Hills': 1436,
    'Hudson': 1436,
    'Hunters Creek': 1436,
    'Hutchinson Island South': 1436,
    'Hypoluxo': 1436,
    'Immokalee': 1436,
    'Indialantic': 1436,
    'Indian Harbour Beach': 1436,
    'Indian River County': 1436,
    'Indian River Estates': 1436,
    'Indian River Shores': 1436,
    'Indian Rocks Beach': 1436,
    'Indian Shores': 1436,
    'Indiantown': 1436,
    'Inglis': 1436,
    'Interlachen': 1436,
    'Inverness': 1436,
    'Inverness Highlands North': 1436,
    'Inverness Highlands South': 1436,
    'Inwood': 1436,
    'Iona': 1436,
    'Islamorada': 1436,
    'Island Walk': 1436,
    'Isle of Normandy': 1436,
    'Ives Estates': 1436,
    'Jackson County': 1436,
    'Jacksonville': 1436,
    'Jacksonville Beach': 1436,
    'Jan-Phyl Village': 1436,
    'Jasmine Estates': 1436,
    'Jasper': 1436,
    'Jefferson County': 1436,
    'Jensen Beach': 1436,
    'June Park': 1436,
    'Juno Beach': 1436,
    'Jupiter': 1436,
    'Kathleen': 1436,
    'Kendale Lakes': 1436,
    'Kendall': 1436,
    'Kendall Green': 1436,
    'Kendall West': 1436,
    'Kenneth City': 1436,
    'Kensington Park': 1436,
    'Key Biscayne': 1436,
    'Key Largo': 1436,
    'Key Vista': 1436,
    'Key West': 1436,
    'Keystone': 1436,
    'Keystone Heights': 1436,
    'Kings Point': 1436,
    'Kissimmee': 1436,
    'LaBelle': 1436,
    'Lacoochee': 1436,
    'Lady Lake': 1436,
    'Lafayette County': 1436,
    'Laguna Beach': 1436,
    'Lake Alfred': 1436,
    'Lake Belvedere Estates': 1436,
    'Lake Butler': 1436,
    'Lake City': 1436,
    'Lake Clarke Shores': 1436,
    'Lake County': 1436,
    'Lake Forest': 1436,
    'Lake Hamilton': 1436,
    'Lake Helen': 1436,
    'Lake Lorraine': 1436,
    'Lake Lucerne': 1436,
    'Lake Mack-Forest Hills': 1436,
    'Lake Magdalene': 1436,
    'Lake Mary': 1436,
    'Lake Panasoffkee': 1436,
    'Lake Park': 1436,
    'Lake Placid': 1436,
    'Lake Sarasota': 1436,
    'Lake Wales': 1436,
    'Lake Worth': 1436,
    'Lake Worth Corridor': 1436,
    'Lakeland': 1436,
    'Lakeland Highlands': 1436,
    'Lakes by the Bay': 1436,
    'Lakeside': 1436,
    'Lakewood Park': 1436,
    'Land O   Lakes': 1436,
    'Lantana': 1436,
    'Largo': 1436,
    'Lauderdale Lakes': 1436,
    'Lauderdale-by-the-Sea': 1436,
    'Lauderhill': 1436,
    'Laurel': 1436,
    'Lealman': 1436,
    'Lecanto': 1436,
    'Lee County': 1436,
    'Leesburg': 1436,
    'Lehigh Acres': 1436,
    'Leisure City': 1436,
    'Lely': 1436,
    'Lely Resort': 1436,
    'Leon County': 1436,
    'Levy County': 1436,
    'Liberty County': 1436,
    'Lighthouse Point': 1436,
    'Limestone Creek': 1436,
    'Live Oak': 1436,
    'Lochmoor Waterway Estates': 1436,
    'Lockhart': 1436,
    'Longboat Key': 1436,
    'Longwood': 1436,
    'Loughman': 1436,
    'Lower Grand Lagoon': 1436,
    'Loxahatchee Groves': 1436,
    'Lutz': 1436,
    'Lynn Haven': 1436,
    'Macclenny': 1436,
    'Madeira Beach': 1436,
    'Madison': 1436,
    'Madison County': 1436,
    'Maitland': 1436,
    'Malabar': 1436,
    'Malone': 1436,
    'Manasota Key': 1436,
    'Manatee County': 1436,
    'Manatee Road': 1436,
    'Mango': 1436,
    'Mangonia Park': 1436,
    'Marathon': 1436,
    'Marco': 1436,
    'Marco Island': 1436,
    'Margate': 1436,
    'Marianna': 1436,
    'Marion County': 1436,
    'Martin County': 1436,
    'Mary Esther': 1436,
    'Masaryktown': 1436,
    'Mascotte': 1436,
    'Mayo': 1436,
    'McGregor': 1436,
    'Meadow Oaks': 1436,
    'Meadow Woods': 1436,
    'Medulla': 1436,
    'Melbourne': 1436,
    'Melbourne Beach': 1436,
    'Melrose Park': 1436,
    'Memphis': 1436,
    'Merritt Island': 1436,
    'Mexico Beach': 1436,
    'Miami': 1436,
    'Miami Beach': 1436,
    'Miami Gardens': 1436,
    'Miami Lakes': 1436,
    'Miami Shores': 1436,
    'Miami Springs': 1436,
    'Miami-Dade County': 1436,
    'Micco': 1436,
    'Middleburg': 1436,
    'Midway': 1436,
    'Milton': 1436,
    'Mims': 1436,
    'Minneola': 1436,
    'Miramar': 1436,
    'Miramar Beach': 1436,
    'Molino': 1436,
    'Monroe County': 1436,
    'Monticello': 1436,
    'Montverde': 1436,
    'Moore Haven': 1436,
    'Mount Dora': 1436,
    'Mount Plymouth': 1436,
    'Mulberry': 1436,
    'Myrtle Grove': 1436,
    'Naples': 1436,
    'Naples Manor': 1436,
    'Naples Park': 1436,
    'Naranja': 1436,
    'Nassau County': 1436,
    'Nassau Village-Ratliff': 1436,
    'Navarre': 1436,
    'Neptune Beach': 1436,
    'New Port Richey': 1436,
    'New Port Richey East': 1436,
    'New Smyrna Beach': 1436,
    'Newberry': 1436,
    'Niceville': 1436,
    'Nocatee': 1436,
    'Nokomis': 1436,
    'Norland': 1436,
    'North Andrews Gardens': 1436,
    'North Bay Village': 1436,
    'North Brooksville': 1436,
    'North DeLand': 1436,
    'North Fort Myers': 1436,
    'North Key Largo': 1436,
    'North Lauderdale': 1436,
    'North Miami': 1436,
    'North Miami Beach': 1436,
    'North Palm Beach': 1436,
    'North Port': 1436,
    'North Redington Beach': 1436,
    'North River Shores': 1436,
    'North Sarasota': 1436,
    'North Weeki Wachee': 1436,
    'Northdale': 1436,
    'Oak Hill': 1436,
    'Oak Ridge': 1436,
    'Oakland': 1436,
    'Oakland Park': 1436,
    'Oakleaf Plantation': 1436,
    'Ocala': 1436,
    'Ocean City': 1436,
    'Ocean Ridge': 1436,
    'Ocoee': 1436,
    'Odessa': 1436,
    'Ojus': 1436,
    'Okaloosa County': 1436,
    'Okeechobee': 1436,
    'Okeechobee County': 1436,
    'Oldsmar': 1436,
    'Olga': 1436,
    'Olympia Heights': 1436,
    'Opa-locka': 1436,
    'Orange City': 1436,
    'Orange County': 1436,
    'Orange Park': 1436,
    'Orangetree': 1436,
    'Oriole Beach': 1436,
    'Orlando': 1436,
    'Orlovista': 1436,
    'Ormond Beach': 1436,
    'Ormond-by-the-Sea': 1436,
    'Osceola County': 1436,
    'Osprey': 1436,
    'Oviedo': 1436,
    'Pace': 1436,
    'Pahokee': 1436,
    'Palatka': 1436,
    'Palm Aire': 1436,
    'Palm Bay': 1436,
    'Palm Beach': 1436,
    'Palm Beach County': 1436,
    'Palm Beach Gardens': 1436,
    'Palm Beach Shores': 1436,
    'Palm City': 1436,
    'Palm Coast': 1436,
    'Palm Harbor': 1436,
    'Palm River-Clair Mel': 1436,
    'Palm Springs': 1436,
    'Palm Springs North': 1436,
    'Palm Valley': 1436,
    'Palmetto': 1436,
    'Palmetto Bay': 1436,
    'Palmetto Estates': 1436,
    'Palmona Park': 1436,
    'Panama City': 1436,
    'Panama City Beach': 1436,
    'Paradise Heights': 1436,
    'Parker': 1436,
    'Parkland': 1436,
    'Pasadena Hills': 1436,
    'Pasco County': 1436,
    'Pebble Creek': 1436,
    'Pelican Bay': 1436,
    'Pembroke Park': 1436,
    'Pembroke Pines': 1436,
    'Pensacola': 1436,
    'Perry': 1436,
    'Pierson': 1436,
    'Pine Castle': 1436,
    'Pine Hills': 1436,
    'Pine Island Center': 1436,
    'Pine Island Ridge': 1436,
    'Pine Manor': 1436,
    'Pine Ridge': 1436,
    'Pinecrest': 1436,
    'Pinellas County': 1436,
    'Pinellas Park': 1436,
    'Pinewood': 1436,
    'Placid Lakes': 1436,
    'Plant City': 1436,
    'Plantation': 1436,
    'Plantation Mobile Home Park': 1436,
    'Poinciana': 1436,
    'Point Baker': 1436,
    'Polk City': 1436,
    'Polk County': 1436,
    'Pompano Beach': 1436,
    'Pompano Beach Highlands': 1436,
    'Ponce Inlet': 1436,
    'Ponte Vedra Beach': 1436,
    'Port Charlotte': 1436,
    'Port LaBelle': 1436,
    'Port Orange': 1436,
    'Port Richey': 1436,
    'Port Saint Joe': 1436,
    'Port Saint John': 1436,
    'Port Saint Lucie': 1436,
    'Port Salerno': 1436,
    'Pretty Bayou': 1436,
    'Princeton': 1436,
    'Progress Village': 1436,
    'Punta Gorda': 1436,
    'Punta Gorda Isles': 1436,
    'Punta Rassa': 1436,
    'Putnam County': 1436,
    'Quail Ridge': 1436,
    'Quincy': 1436,
    'Redington Beach': 1436,
    'Redington Shores': 1436,
    'Richmond Heights': 1436,
    'Richmond West': 1436,
    'Ridge Manor': 1436,
    'Ridge Wood Heights': 1436,
    'Ridgecrest': 1436,
    'River Park': 1436,
    'Riverview': 1436,
    'Riviera Beach': 1436,
    'Rock Island': 1436,
    'Rockledge': 1436,
    'Roosevelt Gardens': 1436,
    'Roseland': 1436,
    'Rotonda West': 1436,
    'Royal Palm Beach': 1436,
    'Royal Palm Estates': 1436,
    'Ruskin': 1436,
    'Safety Harbor': 1436,
    'Saint Augustine': 1436,
    'Saint Augustine Beach': 1436,
    'Saint Augustine Shores': 1436,
    'Saint Augustine South': 1436,
    'Saint Cloud': 1436,
    'Saint George': 1436,
    'Saint James City': 1436,
    'Saint Johns County': 1436,
    'Saint Leo': 1436,
    'Saint Lucie County': 1436,
    'Saint Pete Beach': 1436,
    'Samoset': 1436,
    'Samsula-Spruce Creek': 1436,
    'San Antonio': 1436,
    'San Carlos Park': 1436,
    'Sandalfoot Cove': 1436,
    'Sanford': 1436,
    'Sanibel': 1436,
    'Santa Rosa County': 1436,
    'Sarasota': 1436,
    'Sarasota County': 1436,
    'Sarasota Springs': 1436,
    'Satellite Beach': 1436,
    'Sawgrass': 1436,
    'Schall Circle': 1436,
    'Scott Lake': 1436,
    'Seaside': 1436,
    'Sebastian': 1436,
    'Sebring': 1436,
    'Seffner': 1436,
    'Seminole': 1436,
    'Seminole County': 1436,
    'Seminole Manor': 1436,
    'Sewall  s Point': 1436,
    'Shady Hills': 1436,
    'Sharpes': 1436,
    'Siesta Key': 1436,
    'Silver Lake': 1436,
    'Silver Springs': 1436,
    'Silver Springs Shores': 1436,
    'Sky Lake': 1436,
    'Sneads': 1436,
    'South Apopka': 1436,
    'South Bay': 1436,
    'South Beach': 1436,
    'South Bradenton': 1436,
    'South Brooksville': 1436,
    'South Daytona': 1436,
    'South Gate Ridge': 1436,
    'South Highpoint': 1436,
    'South Miami': 1436,
    'South Miami Heights': 1436,
    'South Palm Beach': 1436,
    'South Pasadena': 1436,
    'South Patrick Shores': 1436,
    'South Sarasota': 1436,
    'South Venice': 1436,
    'Southchase': 1436,
    'Southeast Arcadia': 1436,
    'Southgate': 1436,
    'Southwest Ranches': 1436,
    'Spring Hill': 1436,
    'Springfield': 1436,
    'St. Johns': 1436,
    'St. Petersburg': 1436,
    'Starke': 1436,
    'Steinhatchee': 1436,
    'Stock Island': 1436,
    'Stuart': 1436,
    'Sugarmill Woods': 1436,
    'Sumter County': 1436,
    'Sun City Center': 1436,
    'Suncoast Estates': 1436,
    'Sunny Isles Beach': 1436,
    'Sunrise': 1436,
    'Sunset': 1436,
    'Sunshine Ranches': 1436,
    'Surfside': 1436,
    'Suwannee County': 1436,
    'Sweetwater': 1436,
    'Taft': 1436,
    'Tallahassee': 1436,
    'Tamarac': 1436,
    'Tamiami': 1436,
    'Tampa': 1436,
    'Tangelo Park': 1436,
    'Tangerine': 1436,
    'Tarpon Springs': 1436,
    'Tavares': 1436,
    'Tavernier': 1436,
    'Taylor County': 1436,
    'Taylor Creek': 1436,
    'Tedder': 1436,
    'Temple Terrace': 1436,
    'Tequesta': 1436,
    'Terra Mar': 1436,
    'The Acreage': 1436,
    'The Crossings': 1436,
    'The Hammocks': 1436,
    'The Meadows': 1436,
    'The Villages': 1436,
    'Thonotosassa': 1436,
    'Three Lakes': 1436,
    'Three Oaks': 1436,
    'Tice': 1436,
    'Tierra Verde': 1436,
    'Tiger Point': 1436,
    'Timber Pines': 1436,
    'Titusville': 1436,
    'Town   n   Country': 1436,
    'Treasure Island': 1436,
    'Trenton': 1436,
    'Trinity': 1436,
    'Twin Lakes': 1436,
    'Tyndall Air Force Base': 1436,
    'Umatilla': 1436,
    'Union County': 1436,
    'Union Park': 1436,
    'University': 1436,
    'University Park': 1436,
    'Upper Grand Lagoon': 1436,
    'Valparaiso': 1436,
    'Valrico': 1436,
    'Vamo': 1436,
    'Venice': 1436,
    'Venice Gardens': 1436,
    'Vero Beach': 1436,
    'Vero Beach South': 1436,
    'Verona Walk': 1436,
    'Viera East': 1436,
    'Viera West': 1436,
    'Villages of Oriole': 1436,
    'Villano Beach': 1436,
    'Villas': 1436,
    'Vineyards': 1436,
    'Virginia Gardens': 1436,
    'Volusia County': 1436,
    'Wabasso Beach': 1436,
    'Wahneta': 1436,
    'Wakulla County': 1436,
    'Waldo': 1436,
    'Wallace': 1436,
    'Walton County': 1436,
    'Warm Mineral Springs': 1436,
    'Warrington': 1436,
    'Washington County': 1436,
    'Washington Park': 1436,
    'Watertown': 1436,
    'Wauchula': 1436,
    'Wedgefield': 1436,
    'Weeki Wachee Gardens': 1436,
    'Wekiwa Springs': 1436,
    'Wellborn': 1436,
    'Wellington': 1436,
    'Wesley Chapel': 1436,
    'West and East Lealman': 1436,
    'West Bradenton': 1436,
    'West DeLand': 1436,
    'West Gate': 1436,
    'West Hollywood': 1436,
    'West Little River': 1436,
    'West Melbourne': 1436,
    'West Miami': 1436,
    'West Palm Beach': 1436,
    'West Park': 1436,
    'West Pensacola': 1436,
    'West Perrine': 1436,
    'West Samoset': 1436,
    'West Vero Corridor': 1436,
    'Westchase': 1436,
    'Westchester': 1436,
    'Weston': 1436,
    'Westview': 1436,
    'Westwood Lake': 1436,
    'Wewahitchka': 1436,
    'Whiskey Creek': 1436,
    'White City': 1436,
    'Whitfield': 1436,
    'Wildwood': 1436,
    'Williamsburg': 1436,
    'Williston': 1436,
    'Williston Highlands': 1436,
    'Willow Oak': 1436,
    'Wilton Manors': 1436,
    'Wimauma': 1436,
    'Windermere': 1436,
    'Winston': 1436,
    'Winter Beach': 1436,
    'Winter Garden': 1436,
    'Winter Haven': 1436,
    'Winter Park': 1436,
    'Winter Springs': 1436,
    'Woodlawn Beach': 1436,
    'Woodville': 1436,
    'Wright': 1436,
    'Yalaha': 1436,
    'Youngstown': 1436,
    'Yulee': 1436,
    'Zellwood': 1436,
    'Zephyrhills': 1436,
    'Zephyrhills North': 1436,
    'Zephyrhills South': 1436,
    'Zephyrhills West': 1436,
    'Zolfo Springs': 1436,
    'Abbeville': 1455,
    'Acworth': 1455,
    'Adairsville': 1455,
    'Adel': 1455,
    'Alamo': 1455,
    'Albany': 1455,
    'Alma': 1455,
    'Alpharetta': 1455,
    'Alto': 1455,
    'Americus': 1455,
    'Appling County': 1455,
    'Aragon': 1455,
    'Arcade': 1455,
    'Arlington': 1455,
    'Ashburn': 1455,
    'Athens': 1455,
    'Atkinson County': 1455,
    'Atlanta': 1455,
    'Auburn': 1455,
    'Augusta': 1455,
    'Austell': 1455,
    'Avondale Estates': 1455,
    'Bacon County': 1455,
    'Bainbridge': 1455,
    'Baker County': 1455,
    'Baldwin': 1455,
    'Baldwin County': 1455,
    'Ball Ground': 1455,
    'Banks County': 1455,
    'Barnesville': 1455,
    'Barrow County': 1455,
    'Bartow County': 1455,
    'Baxley': 1455,
    'Belvedere Park': 1455,
    'Ben Hill County': 1455,
    'Berkeley Lake': 1455,
    'Berrien County': 1455,
    'Bibb County': 1455,
    'Blackshear': 1455,
    'Blairsville': 1455,
    'Blakely': 1455,
    'Bleckley County': 1455,
    'Bloomingdale': 1455,
    'Blue Ridge': 1455,
    'Bogart': 1455,
    'Bonanza': 1455,
    'Boston': 1455,
    'Bowdon': 1455,
    'Brantley County': 1455,
    'Braselton': 1455,
    'Bremen': 1455,
    'Brookhaven': 1455,
    'Brooklet': 1455,
    'Brooks County': 1455,
    'Broxton': 1455,
    'Brunswick': 1455,
    'Bryan County': 1455,
    'Buchanan': 1455,
    'Buena Vista': 1455,
    'Buford': 1455,
    'Bulloch County': 1455,
    'Burke County': 1455,
    'Butler': 1455,
    'Butts County': 1455,
    'Byron': 1455,
    'Cairo': 1455,
    'Calhoun': 1455,
    'Calhoun County': 1455,
    'Camden County': 1455,
    'Camilla': 1455,
    'Candler County': 1455,
    'Candler-McAfee': 1455,
    'Canton': 1455,
    'Carnesville': 1455,
    'Carroll County': 1455,
    'Carrollton': 1455,
    'Cartersville': 1455,
    'Catoosa County': 1455,
    'Cave Spring': 1455,
    'Cedartown': 1455,
    'Centerville': 1455,
    'Chamblee': 1455,
    'Charlton County': 1455,
    'Chatham County': 1455,
    'Chatsworth': 1455,
    'Chattahoochee County': 1455,
    'Chattahoochee Hills': 1455,
    'Chattanooga Valley': 1455,
    'Chattooga County': 1455,
    'Cherokee County': 1455,
    'Chester': 1455,
    'Chickamauga': 1455,
    'Clarke County': 1455,
    'Clarkesville': 1455,
    'Clarkston': 1455,
    'Claxton': 1455,
    'Clay County': 1455,
    'Clayton': 1455,
    'Clayton County': 1455,
    'Cleveland': 1455,
    'Clinch County': 1455,
    'Cobb County': 1455,
    'Cochran': 1455,
    'Coffee County': 1455,
    'College Park': 1455,
    'Colquitt': 1455,
    'Colquitt County': 1455,
    'Columbia County': 1455,
    'Columbus': 1455,
    'Comer': 1455,
    'Commerce': 1455,
    'Conley': 1455,
    'Conyers': 1455,
    'Cook County': 1455,
    'Cordele': 1455,
    'Cornelia': 1455,
    'Country Club Estates': 1455,
    'Covington': 1455,
    'Coweta County': 1455,
    'Crawford County': 1455,
    'Crawfordville': 1455,
    'Crisp County': 1455,
    'Cumming': 1455,
    'Cusseta': 1455,
    'Cuthbert': 1455,
    'Dacula': 1455,
    'Dade County': 1455,
    'Dahlonega': 1455,
    'Dallas': 1455,
    'Dalton': 1455,
    'Danielsville': 1455,
    'Darien': 1455,
    'Davisboro': 1455,
    'Dawson': 1455,
    'Dawson County': 1455,
    'Dawsonville': 1455,
    'Decatur': 1455,
    'Decatur County': 1455,
    'Deenwood': 1455,
    'DeKalb County': 1455,
    'Demorest': 1455,
    'Dock Junction': 1455,
    'Dodge County': 1455,
    'Donalsonville': 1455,
    'Dooly County': 1455,
    'Doraville': 1455,
    'Dougherty County': 1455,
    'Douglas': 1455,
    'Douglas County': 1455,
    'Douglasville': 1455,
    'Druid Hills': 1455,
    'Dublin': 1455,
    'Duluth': 1455,
    'Dunwoody': 1455,
    'Early County': 1455,
    'East Dublin': 1455,
    'East Griffin': 1455,
    'East Newnan': 1455,
    'East Point': 1455,
    'Eastman': 1455,
    'Eatonton': 1455,
    'Echols County': 1455,
    'Edison': 1455,
    'Effingham County': 1455,
    'Elbert County': 1455,
    'Elberton': 1455,
    'Ellaville': 1455,
    'Ellijay': 1455,
    'Emanuel County': 1455,
    'Emerson': 1455,
    'Enigma': 1455,
    'Euharlee': 1455,
    'Evans': 1455,
    'Evans County': 1455,
    'Experiment': 1455,
    'Fair Oaks': 1455,
    'Fairburn': 1455,
    'Fairview': 1455,
    'Fannin County': 1455,
    'Fayette County': 1455,
    'Fayetteville': 1455,
    'Firing Range': 1455,
    'Fitzgerald': 1455,
    'Flowery Branch': 1455,
    'Floyd County': 1455,
    'Folkston': 1455,
    'Forest Park': 1455,
    'Forsyth': 1455,
    'Forsyth County': 1455,
    'Fort Gaines': 1455,
    'Fort Oglethorpe': 1455,
    'Fort Stewart': 1455,
    'Fort Valley': 1455,
    'Franklin': 1455,
    'Franklin County': 1455,
    'Franklin Springs': 1455,
    'Fulton County': 1455,
    'Gainesville': 1455,
    'Garden City': 1455,
    'Georgetown': 1455,
    'Gibson': 1455,
    'Gilmer County': 1455,
    'Glascock County': 1455,
    'Glennville': 1455,
    'Glynn County': 1455,
    'Gordon': 1455,
    'Gordon County': 1455,
    'Grady County': 1455,
    'Grantville': 1455,
    'Gray': 1455,
    'Grayson': 1455,
    'Greene County': 1455,
    'Greensboro': 1455,
    'Greenville': 1455,
    'Gresham Park': 1455,
    'Griffin': 1455,
    'Grovetown': 1455,
    'Gumlog': 1455,
    'Guyton': 1455,
    'Gwinnett County': 1455,
    'Habersham County': 1455,
    'Hahira': 1455,
    'Hall County': 1455,
    'Hamilton': 1455,
    'Hampton': 1455,
    'Hancock County': 1455,
    'Hannahs Mill': 1455,
    'Hapeville': 1455,
    'Haralson County': 1455,
    'Hardwick': 1455,
    'Harlem': 1455,
    'Harris County': 1455,
    'Hart County': 1455,
    'Hartwell': 1455,
    'Hawkinsville': 1455,
    'Hazlehurst': 1455,
    'Heard County': 1455,
    'Helena': 1455,
    'Henderson': 1455,
    'Henry County': 1455,
    'Hephzibah': 1455,
    'Hiawassee': 1455,
    'Hinesville': 1455,
    'Hiram': 1455,
    'Hogansville': 1455,
    'Holly Springs': 1455,
    'Homer': 1455,
    'Homerville': 1455,
    'Hoschton': 1455,
    'Houston County': 1455,
    'Indian Springs': 1455,
    'Irondale': 1455,
    'Irwin County': 1455,
    'Irwinton': 1455,
    'Isle of Hope': 1455,
    'Jackson': 1455,
    'Jackson County': 1455,
    'Jasper': 1455,
    'Jasper County': 1455,
    'Jeff Davis County': 1455,
    'Jefferson': 1455,
    'Jefferson County': 1455,
    'Jeffersonville': 1455,
    'Jenkins County': 1455,
    'Jesup': 1455,
    'Johns Creek': 1455,
    'Johnson County': 1455,
    'Jones County': 1455,
    'Jonesboro': 1455,
    'Kennesaw': 1455,
    'Kings Bay Base': 1455,
    'Kingsland': 1455,
    'Knoxville': 1455,
    'LaFayette': 1455,
    'LaGrange': 1455,
    'Lake City': 1455,
    'Lakeland': 1455,
    'Lakeview': 1455,
    'Lakeview Estates': 1455,
    'Lamar County': 1455,
    'Lanier County': 1455,
    'Laurens County': 1455,
    'Lavonia': 1455,
    'Lawrenceville': 1455,
    'Lee County': 1455,
    'Leesburg': 1455,
    'Lexington': 1455,
    'Liberty County': 1455,
    'Lilburn': 1455,
    'Lincoln County': 1455,
    'Lincolnton': 1455,
    'Lindale': 1455,
    'Lithia Springs': 1455,
    'Lithonia': 1455,
    'Locust Grove': 1455,
    'Loganville': 1455,
    'Long County': 1455,
    'Lookout Mountain': 1455,
    'Louisville': 1455,
    'Lovejoy': 1455,
    'Lowndes County': 1455,
    'Ludowici': 1455,
    'Lula': 1455,
    'Lumber City': 1455,
    'Lumpkin': 1455,
    'Lumpkin County': 1455,
    'Lyons': 1455,
    'Mableton': 1455,
    'Macon': 1455,
    'Macon County': 1455,
    'Madison': 1455,
    'Madison County': 1455,
    'Manchester': 1455,
    'Marietta': 1455,
    'Marion County': 1455,
    'Marshallville': 1455,
    'Martinez': 1455,
    'Maysville': 1455,
    'McCaysville': 1455,
    'McDonough': 1455,
    'McDuffie County': 1455,
    'McIntosh County': 1455,
    'McRae': 1455,
    'Meigs': 1455,
    'Meriwether County': 1455,
    'Metter': 1455,
    'Midway': 1455,
    'Milledgeville': 1455,
    'Millen': 1455,
    'Miller County': 1455,
    'Milton': 1455,
    'Mitchell County': 1455,
    'Monroe': 1455,
    'Monroe County': 1455,
    'Montezuma': 1455,
    'Montgomery': 1455,
    'Montgomery County': 1455,
    'Monticello': 1455,
    'Morgan': 1455,
    'Morgan County': 1455,
    'Morrow': 1455,
    'Moultrie': 1455,
    'Mount Airy': 1455,
    'Mount Vernon': 1455,
    'Mount Zion': 1455,
    'Mountain City': 1455,
    'Mountain Park': 1455,
    'Murray County': 1455,
    'Muscogee County': 1455,
    'Nahunta': 1455,
    'Nashville': 1455,
    'Nelson': 1455,
    'Newnan': 1455,
    'Newton': 1455,
    'Newton County': 1455,
    'Nicholls': 1455,
    'Nicholson': 1455,
    'Norcross': 1455,
    'North Atlanta': 1455,
    'North Decatur': 1455,
    'North Druid Hills': 1455,
    'Oakwood': 1455,
    'Ocilla': 1455,
    'Oconee County': 1455,
    'Oglethorpe': 1455,
    'Oglethorpe County': 1455,
    'Omega': 1455,
    'Oxford': 1455,
    'Palmetto': 1455,
    'Panthersville': 1455,
    'Paulding County': 1455,
    'Peach County': 1455,
    'Peachtree City': 1455,
    'Peachtree Corners': 1455,
    'Pearson': 1455,
    'Pelham': 1455,
    'Pembroke': 1455,
    'Perry': 1455,
    'Pickens County': 1455,
    'Pierce County': 1455,
    'Pike County': 1455,
    'Pine Mountain': 1455,
    'Polk County': 1455,
    'Pooler': 1455,
    'Port Wentworth': 1455,
    'Porterdale': 1455,
    'Powder Springs': 1455,
    'Preston': 1455,
    'Pulaski County': 1455,
    'Putnam County': 1455,
    'Putney': 1455,
    'Quitman': 1455,
    'Quitman County': 1455,
    'Rabun County': 1455,
    'Randolph County': 1455,
    'Raoul': 1455,
    'Ray City': 1455,
    'Redan': 1455,
    'Reed Creek': 1455,
    'Reidsville': 1455,
    'Remerton': 1455,
    'Reynolds': 1455,
    'Richland': 1455,
    'Richmond County': 1455,
    'Richmond Hill': 1455,
    'Rincon': 1455,
    'Ringgold': 1455,
    'Riverdale': 1455,
    'Robins Air Force Base': 1455,
    'Rochelle': 1455,
    'Rockdale County': 1455,
    'Rockmart': 1455,
    'Rome': 1455,
    'Rossville': 1455,
    'Roswell': 1455,
    'Royston': 1455,
    'Russell': 1455,
    'Rydal': 1455,
    'Saint Simon Mills': 1455,
    'Saint Simons Island': 1455,
    'Sandersville': 1455,
    'Sandy Springs': 1455,
    'Sardis': 1455,
    'Savannah': 1455,
    'Schley County': 1455,
    'Scottdale': 1455,
    'Screven County': 1455,
    'Seminole County': 1455,
    'Senoia': 1455,
    'Shannon': 1455,
    'Skidaway Island': 1455,
    'Smyrna': 1455,
    'Snellville': 1455,
    'Social Circle': 1455,
    'Soperton': 1455,
    'Spalding County': 1455,
    'Sparks': 1455,
    'Sparta': 1455,
    'Springfield': 1455,
    'St. Marys': 1455,
    'Statenville': 1455,
    'Statesboro': 1455,
    'Statham': 1455,
    'Stephens County': 1455,
    'Stewart County': 1455,
    'Stockbridge': 1455,
    'Stone Mountain': 1455,
    'Stonecrest': 1455,
    'Sugar Hill': 1455,
    'Summerville': 1455,
    'Sumter County': 1455,
    'Sunnyside': 1455,
    'Suwanee': 1455,
    'Swainsboro': 1455,
    'Sylvania': 1455,
    'Sylvester': 1455,
    'Talbot County': 1455,
    'Talbotton': 1455,
    'Taliaferro County': 1455,
    'Tallapoosa': 1455,
    'Tattnall County': 1455,
    'Taylor County': 1455,
    'Telfair County': 1455,
    'Temple': 1455,
    'Tennille': 1455,
    'Terrell County': 1455,
    'Thomas County': 1455,
    'Thomaston': 1455,
    'Thomasville': 1455,
    'Thomson': 1455,
    'Thunderbolt': 1455,
    'Tift County': 1455,
    'Tifton': 1455,
    'Toccoa': 1455,
    'Toombs County': 1455,
    'Towns County': 1455,
    'Trenton': 1455,
    'Treutlen County': 1455,
    'Trion': 1455,
    'Troup County': 1455,
    'Tucker': 1455,
    'Turner County': 1455,
    'Twiggs County': 1455,
    'Twin City': 1455,
    'Tybee Island': 1455,
    'Tyrone': 1455,
    'Unadilla': 1455,
    'Union City': 1455,
    'Union County': 1455,
    'Union Point': 1455,
    'Unionville': 1455,
    'Upson County': 1455,
    'Valdosta': 1455,
    'Varnell': 1455,
    'Vidalia': 1455,
    'Vienna': 1455,
    'Villa Rica': 1455,
    'Vinings': 1455,
    'Wadley': 1455,
    'Walker County': 1455,
    'Walnut Grove': 1455,
    'Walthourville': 1455,
    'Walton County': 1455,
    'Ware County': 1455,
    'Warner Robins': 1455,
    'Warren County': 1455,
    'Warrenton': 1455,
    'Washington': 1455,
    'Washington County': 1455,
    'Watkinsville': 1455,
    'Waycross': 1455,
    'Wayne County': 1455,
    'Waynesboro': 1455,
    'Webster County': 1455,
    'West Point': 1455,
    'West Warrenton': 1455,
    'Wheeler County': 1455,
    'White County': 1455,
    'Whitemarsh Island': 1455,
    'Whitfield County': 1455,
    'Wilcox County': 1455,
    'Wilkes County': 1455,
    'Wilkinson County': 1455,
    'Willacoochee': 1455,
    'Wilmington Island': 1455,
    'Winder': 1455,
    'Winterville': 1455,
    'Woodbine': 1455,
    'Woodstock': 1455,
    'Worth County': 1455,
    'Wrens': 1455,
    'Wrightsville': 1455,
    'Young Harris': 1455,
    'Zebulon': 1455,
    '‘Āhuimanu': 1411,
    '‘Aiea': 1411,
    '‘Ele‘ele': 1411,
    '‘Ewa Beach': 1411,
    '‘Ewa Gentry': 1411,
    '‘Ewa Villages': 1411,
    '‘Ōma‘o': 1411,
    'Ainaloa': 1411,
    'Anahola': 1411,
    'Captain Cook': 1411,
    'East Honolulu': 1411,
    'Fern Acres': 1411,
    'Haiku-Pauwela': 1411,
    'Hālawa': 1411,
    'Hālawa Heights': 1411,
    'Hale‘iwa': 1411,
    'Hana': 1411,
    'Hanamā‘ulu': 1411,
    'Hanapēpē': 1411,
    'Hanapēpē Heights': 1411,
    'Hau‘ula': 1411,
    'Hawaii County': 1411,
    'Hawaiian Acres': 1411,
    'Hawaiian Beaches': 1411,
    'Hawaiian Ocean View': 1411,
    'Hawaiian Paradise Park': 1411,
    'Hāwī': 1411,
    'He‘eia': 1411,
    'Hickam Field': 1411,
    'Hilo': 1411,
    'Hōlualoa': 1411,
    'Honalo': 1411,
    'Honaunau-Napoopoo': 1411,
    'Honoka‘a': 1411,
    'Honolulu': 1411,
    'Honolulu County': 1411,
    'Iroquois Point': 1411,
    'Ka‘a‘awa': 1411,
    'Kā‘anapali': 1411,
    'Kaanapali Landing': 1411,
    'Kahalu‘u': 1411,
    'Kahaluu-Keauhou': 1411,
    'Kahuku': 1411,
    'Kahului': 1411,
    'Kailua': 1411,
    'Kailua-Kona': 1411,
    'Kalāheo': 1411,
    'Kalaoa': 1411,
    'Kalawao County': 1411,
    'Kaneohe': 1411,
    'Kapa‘a': 1411,
    'Kapaau': 1411,
    'Kapolei': 1411,
    'Kauai County': 1411,
    'Kaunakakai': 1411,
    'Kea‘au': 1411,
    'Kealakekua': 1411,
    'Kekaha': 1411,
    'Kēōkea': 1411,
    'Kīhei': 1411,
    'Kīlauea': 1411,
    'Ko Olina': 1411,
    'Koloa': 1411,
    'Kualapu‘u': 1411,
    'Kula': 1411,
    'Kurtistown': 1411,
    'Lā‘ie': 1411,
    'Lahaina': 1411,
    'Lanai City': 1411,
    'Lawai': 1411,
    'Leilani Estates': 1411,
    'Lihue': 1411,
    'Mā‘ili': 1411,
    'Mākaha': 1411,
    'Mākaha Valley': 1411,
    'Makakilo City': 1411,
    'Makawao': 1411,
    'Marine Corps Base Hawaii - MCBH': 1411,
    'Maui County': 1411,
    'Maunawili': 1411,
    'Mililani Town': 1411,
    'Mokulēia': 1411,
    'Mountain View': 1411,
    'Nānākuli': 1411,
    'Nanawale Estates': 1411,
    'Napili-Honokowai': 1411,
    'Ocean Pointe': 1411,
    'Orchidlands Estates': 1411,
    'Pāhala': 1411,
    'Paia': 1411,
    'Pāpa‘ikou': 1411,
    'Pearl City': 1411,
    'Pepeekeo': 1411,
    'Princeville': 1411,
    'Puhi': 1411,
    'Pukalani': 1411,
    'Punalu‘u': 1411,
    'Pupukea': 1411,
    'Royal Kunia': 1411,
    'Schofield Barracks': 1411,
    'Village Park': 1411,
    'Volcano': 1411,
    'Wahiawā': 1411,
    'Waialua': 1411,
    'Waianae': 1411,
    'Waihee-Waiehu': 1411,
    'Waikapū': 1411,
    'Waikoloa': 1411,
    'Wailea': 1411,
    'Wailua': 1411,
    'Wailua Homesteads': 1411,
    'Wailuku': 1411,
    'Waimalu': 1411,
    'Waimanalo': 1411,
    'Waimānalo Beach': 1411,
    'Waimea': 1411,
    'Wainaku': 1411,
    'Waipahu': 1411,
    'Waipi‘o Acres': 1411,
    'Waipio': 1411,
    'Whitmore Village': 1411,
    'Aberdeen': 1460,
    'Ada County': 1460,
    'Adams County': 1460,
    'American Falls': 1460,
    'Ammon': 1460,
    'Arco': 1460,
    'Ashton': 1460,
    'Bannock County': 1460,
    'Bear Lake County': 1460,
    'Bellevue': 1460,
    'Benewah County': 1460,
    'Bingham County': 1460,
    'Blackfoot': 1460,
    'Blaine County': 1460,
    'Boise': 1460,
    'Boise County': 1460,
    'Bonner County': 1460,
    'Bonners Ferry': 1460,
    'Bonneville County': 1460,
    'Boundary County': 1460,
    'Buhl': 1460,
    'Burley': 1460,
    'Butte County': 1460,
    'Caldwell': 1460,
    'Camas County': 1460,
    'Canyon County': 1460,
    'Caribou County': 1460,
    'Cascade': 1460,
    'Cassia County': 1460,
    'Challis': 1460,
    'Chubbuck': 1460,
    'Clark County': 1460,
    'Clearwater County': 1460,
    'Coeur d  Alene': 1460,
    'Council': 1460,
    'Custer County': 1460,
    'Dalton Gardens': 1460,
    'Driggs': 1460,
    'Dubois': 1460,
    'Eagle': 1460,
    'Elmore County': 1460,
    'Emmett': 1460,
    'Fairfield': 1460,
    'Filer': 1460,
    'Fort Hall': 1460,
    'Franklin County': 1460,
    'Fremont County': 1460,
    'Fruitland': 1460,
    'Garden City': 1460,
    'Gem County': 1460,
    'Glenns Ferry': 1460,
    'Gooding': 1460,
    'Gooding County': 1460,
    'Grangeville': 1460,
    'Hailey': 1460,
    'Hansen': 1460,
    'Hayden': 1460,
    'Heyburn': 1460,
    'Hidden Spring': 1460,
    'Homedale': 1460,
    'Idaho City': 1460,
    'Idaho County': 1460,
    'Idaho Falls': 1460,
    'Iona': 1460,
    'Jefferson County': 1460,
    'Jerome': 1460,
    'Jerome County': 1460,
    'Kamiah': 1460,
    'Kellogg': 1460,
    'Ketchum': 1460,
    'Kimberly': 1460,
    'Kootenai County': 1460,
    'Kuna': 1460,
    'Lapwai': 1460,
    'Latah County': 1460,
    'Lemhi County': 1460,
    'Lewis County': 1460,
    'Lewiston': 1460,
    'Lewiston Orchards': 1460,
    'Lincoln': 1460,
    'Lincoln County': 1460,
    'Madison County': 1460,
    'Malad City': 1460,
    'Marsing': 1460,
    'McCall': 1460,
    'Meridian': 1460,
    'Middleton': 1460,
    'Minidoka County': 1460,
    'Montpelier': 1460,
    'Moreland': 1460,
    'Moscow': 1460,
    'Mountain Home': 1460,
    'Murphy': 1460,
    'Nampa': 1460,
    'New Plymouth': 1460,
    'Nez Perce County': 1460,
    'Nezperce': 1460,
    'Oneida County': 1460,
    'Orofino': 1460,
    'Osburn': 1460,
    'Owyhee County': 1460,
    'Paris': 1460,
    'Parma': 1460,
    'Paul': 1460,
    'Payette': 1460,
    'Payette County': 1460,
    'Pinehurst': 1460,
    'Plummer': 1460,
    'Pocatello': 1460,
    'Ponderay': 1460,
    'Post Falls': 1460,
    'Power County': 1460,
    'Preston': 1460,
    'Priest River': 1460,
    'Rathdrum': 1460,
    'Rexburg': 1460,
    'Rigby': 1460,
    'Rupert': 1460,
    'Saint Anthony': 1460,
    'Saint Maries': 1460,
    'Salmon': 1460,
    'Sandpoint': 1460,
    'Shelley': 1460,
    'Shoshone': 1460,
    'Shoshone County': 1460,
    'Soda Springs': 1460,
    'Spirit Lake': 1460,
    'Star': 1460,
    'Sugar City': 1460,
    'Sun Valley': 1460,
    'Teton County': 1460,
    'Twin Falls': 1460,
    'Twin Falls County': 1460,
    'Tyhee': 1460,
    'Ucon': 1460,
    'Valley County': 1460,
    'Victor': 1460,
    'Wallace': 1460,
    'Washington County': 1460,
    'Weiser': 1460,
    'Wendell': 1460,
    'Wilder': 1460,
    'Abingdon': 1425,
    'Adams County': 1425,
    'Addison': 1425,
    'Albany Park': 1425,
    'Albers': 1425,
    'Albion': 1425,
    'Aledo': 1425,
    'Alexander County': 1425,
    'Algonquin': 1425,
    'Alorton': 1425,
    'Alsip': 1425,
    'Altamont': 1425,
    'Alton': 1425,
    'Amboy': 1425,
    'Andalusia': 1425,
    'Anna': 1425,
    'Antioch': 1425,
    'Arcola': 1425,
    'Arlington Heights': 1425,
    'Arthur': 1425,
    'Ashburn': 1425,
    'Ashland': 1425,
    'Assumption': 1425,
    'Astoria': 1425,
    'Athens': 1425,
    'Atlanta': 1425,
    'Atwood': 1425,
    'Auburn': 1425,
    'Auburn Gresham': 1425,
    'Aurora': 1425,
    'Aviston': 1425,
    'Avondale': 1425,
    'Bannockburn': 1425,
    'Barrington': 1425,
    'Barrington Hills': 1425,
    'Barry': 1425,
    'Bartlett': 1425,
    'Bartonville': 1425,
    'Batavia': 1425,
    'Beach Park': 1425,
    'Beardstown': 1425,
    'Beckemeyer': 1425,
    'Beecher': 1425,
    'Belleville': 1425,
    'Bellevue': 1425,
    'Bellwood': 1425,
    'Belmont Cragin': 1425,
    'Belvidere': 1425,
    'Bement': 1425,
    'Benld': 1425,
    'Bensenville': 1425,
    'Benton': 1425,
    'Berkeley': 1425,
    'Berwyn': 1425,
    'Bethalto': 1425,
    'Bethany': 1425,
    'Big Rock': 1425,
    'Bloomingdale': 1425,
    'Bloomington': 1425,
    'Blue Island': 1425,
    'Blue Mound': 1425,
    'Bolingbrook': 1425,
    'Bond County': 1425,
    'Boone County': 1425,
    'Boulder Hill': 1425,
    'Bourbonnais': 1425,
    'Bradley': 1425,
    'Braidwood': 1425,
    'Breese': 1425,
    'Bridgeport': 1425,
    'Bridgeview': 1425,
    'Brighton': 1425,
    'Brighton Park': 1425,
    'Broadview': 1425,
    'Brookfield': 1425,
    'Brown County': 1425,
    'Buffalo Grove': 1425,
    'Bull Valley': 1425,
    'Bunker Hill': 1425,
    'Burbank': 1425,
    'Bureau County': 1425,
    'Burnham': 1425,
    'Burr Ridge': 1425,
    'Bushnell': 1425,
    'Byron': 1425,
    'Cahokia': 1425,
    'Cairo': 1425,
    'Calhoun County': 1425,
    'Calumet City': 1425,
    'Calumet Park': 1425,
    'Cambria': 1425,
    'Cambridge': 1425,
    'Camp Point': 1425,
    'Canton': 1425,
    'Capron': 1425,
    'Carbon Cliff': 1425,
    'Carbondale': 1425,
    'Carlinville': 1425,
    'Carlyle': 1425,
    'Carmi': 1425,
    'Carol Stream': 1425,
    'Carpentersville': 1425,
    'Carrier Mills': 1425,
    'Carroll County': 1425,
    'Carrollton': 1425,
    'Carterville': 1425,
    'Carthage': 1425,
    'Cary': 1425,
    'Casey': 1425,
    'Caseyville': 1425,
    'Cass County': 1425,
    'Catlin': 1425,
    'Central City': 1425,
    'Centralia': 1425,
    'Centreville': 1425,
    'Cerro Gordo': 1425,
    'Champaign': 1425,
    'Champaign County': 1425,
    'Channahon': 1425,
    'Channel Lake': 1425,
    'Charleston': 1425,
    'Chatham': 1425,
    'Chatsworth': 1425,
    'Chebanse': 1425,
    'Chenoa': 1425,
    'Cherry Valley': 1425,
    'Chester': 1425,
    'Chicago': 1425,
    'Chicago Heights': 1425,
    'Chicago Lawn': 1425,
    'Chicago Loop': 1425,
    'Chicago Ridge': 1425,
    'Chillicothe': 1425,
    'Chrisman': 1425,
    'Christian County': 1425,
    'Christopher': 1425,
    'Cicero': 1425,
    'Clarendon Hills': 1425,
    'Clark County': 1425,
    'Clay County': 1425,
    'Clifton': 1425,
    'Clinton': 1425,
    'Clinton County': 1425,
    'Coal City': 1425,
    'Coal Valley': 1425,
    'Cobden': 1425,
    'Colchester': 1425,
    'Coles County': 1425,
    'Colfax': 1425,
    'Collinsville': 1425,
    'Colona': 1425,
    'Columbia': 1425,
    'Cook County': 1425,
    'Cortland': 1425,
    'Country Club Hills': 1425,
    'Countryside': 1425,
    'Crainville': 1425,
    'Crawford County': 1425,
    'Crest Hill': 1425,
    'Crestwood': 1425,
    'Crete': 1425,
    'Creve Coeur': 1425,
    'Crystal Lake': 1425,
    'Crystal Lawns': 1425,
    'Cuba': 1425,
    'Cumberland County': 1425,
    'Danvers': 1425,
    'Danville': 1425,
    'Darien': 1425,
    'Davis Junction': 1425,
    'De Soto': 1425,
    'De Witt County': 1425,
    'Decatur': 1425,
    'Deer Park': 1425,
    'Deerfield': 1425,
    'DeKalb': 1425,
    'DeKalb County': 1425,
    'Delavan': 1425,
    'Depue': 1425,
    'Des Plaines': 1425,
    'Diamond': 1425,
    'Divernon': 1425,
    'Dixmoor': 1425,
    'Dixon': 1425,
    'Dolton': 1425,
    'Douglas': 1425,
    'Douglas County': 1425,
    'Downers Grove': 1425,
    'Du Quoin': 1425,
    'Dunlap': 1425,
    'DuPage County': 1425,
    'Dupo': 1425,
    'Durand': 1425,
    'Dwight': 1425,
    'Earlville': 1425,
    'East Alton': 1425,
    'East Dubuque': 1425,
    'East Dundee': 1425,
    'East Garfield Park': 1425,
    'East Hazel Crest': 1425,
    'East Moline': 1425,
    'East Peoria': 1425,
    'East Saint Louis': 1425,
    'Edgar County': 1425,
    'Edgewater': 1425,
    'Edinburg': 1425,
    'Edwards County': 1425,
    'Edwardsville': 1425,
    'Effingham': 1425,
    'Effingham County': 1425,
    'El Paso': 1425,
    'Elburn': 1425,
    'Eldorado': 1425,
    'Elgin': 1425,
    'Elizabethtown': 1425,
    'Elk Grove Village': 1425,
    'Elmhurst': 1425,
    'Elmwood': 1425,
    'Elmwood Park': 1425,
    'Elwood': 1425,
    'Energy': 1425,
    'Englewood': 1425,
    'Erie': 1425,
    'Eureka': 1425,
    'Evanston': 1425,
    'Evergreen Park': 1425,
    'Fairbury': 1425,
    'Fairfield': 1425,
    'Fairmont': 1425,
    'Fairmont City': 1425,
    'Fairview Heights': 1425,
    'Farmer City': 1425,
    'Farmington': 1425,
    'Fayette County': 1425,
    'Fisher': 1425,
    'Flanagan': 1425,
    'Flora': 1425,
    'Flossmoor': 1425,
    'Ford County': 1425,
    'Ford Heights': 1425,
    'Forest Lake': 1425,
    'Forest Park': 1425,
    'Forrest': 1425,
    'Forreston': 1425,
    'Forsyth': 1425,
    'Fox Lake': 1425,
    'Fox Lake Hills': 1425,
    'Fox River Grove': 1425,
    'Frankfort': 1425,
    'Frankfort Square': 1425,
    'Franklin County': 1425,
    'Franklin Park': 1425,
    'Freeburg': 1425,
    'Freeport': 1425,
    'Fulton': 1425,
    'Fulton County': 1425,
    'Gage Park': 1425,
    'Gages Lake': 1425,
    'Galena': 1425,
    'Galesburg': 1425,
    'Gallatin County': 1425,
    'Galva': 1425,
    'Gardner': 1425,
    'Geneseo': 1425,
    'Geneva': 1425,
    'Genoa': 1425,
    'Georgetown': 1425,
    'Germantown': 1425,
    'Germantown Hills': 1425,
    'Gibson City': 1425,
    'Gifford': 1425,
    'Gilberts': 1425,
    'Gillespie': 1425,
    'Gilman': 1425,
    'Girard': 1425,
    'Glasford': 1425,
    'Glen Carbon': 1425,
    'Glen Ellyn': 1425,
    'Glencoe': 1425,
    'Glendale Heights': 1425,
    'Glenview': 1425,
    'Glenwood': 1425,
    'Godfrey': 1425,
    'Golconda': 1425,
    'Goodings Grove': 1425,
    'Goreville': 1425,
    'Grand Boulevard': 1425,
    'Grandview': 1425,
    'Grandwood Park': 1425,
    'Granite City': 1425,
    'Grant Park': 1425,
    'Granville': 1425,
    'Grayslake': 1425,
    'Grayville': 1425,
    'Greater Grand Crossing': 1425,
    'Green Oaks': 1425,
    'Green Rock': 1425,
    'Greene County': 1425,
    'Greenfield': 1425,
    'Greenup': 1425,
    'Greenville': 1425,
    'Gridley': 1425,
    'Griggsville': 1425,
    'Grundy County': 1425,
    'Gurnee': 1425,
    'Hainesville': 1425,
    'Hamilton': 1425,
    'Hamilton County': 1425,
    'Hampshire': 1425,
    'Hampton': 1425,
    'Hancock County': 1425,
    'Hanna City': 1425,
    'Hanover Park': 1425,
    'Hardin': 1425,
    'Hardin County': 1425,
    'Harrisburg': 1425,
    'Harristown': 1425,
    'Hartford': 1425,
    'Harvard': 1425,
    'Harvey': 1425,
    'Harwood Heights': 1425,
    'Havana': 1425,
    'Hawthorn Woods': 1425,
    'Hazel Crest': 1425,
    'Hebron': 1425,
    'Henderson County': 1425,
    'Hennepin': 1425,
    'Henry': 1425,
    'Henry County': 1425,
    'Heritage Lake': 1425,
    'Herrin': 1425,
    'Herscher': 1425,
    'Heyworth': 1425,
    'Hickory Hills': 1425,
    'Highland': 1425,
    'Highland Park': 1425,
    'Highwood': 1425,
    'Hillcrest': 1425,
    'Hillsboro': 1425,
    'Hillside': 1425,
    'Hinckley': 1425,
    'Hinsdale': 1425,
    'Hodgkins': 1425,
    'Hoffman Estates': 1425,
    'Holiday Shores': 1425,
    'Homer': 1425,
    'Homer Glen': 1425,
    'Hometown': 1425,
    'Homewood': 1425,
    'Hoopeston': 1425,
    'Hudson': 1425,
    'Huntley': 1425,
    'Hyde Park': 1425,
    'Ina': 1425,
    'Indian Head Park': 1425,
    'Ingalls Park': 1425,
    'Inverness': 1425,
    'Iroquois County': 1425,
    'Irving Park': 1425,
    'Island Lake': 1425,
    'Itasca': 1425,
    'Jackson County': 1425,
    'Jacksonville': 1425,
    'Jasper County': 1425,
    'Jefferson County': 1425,
    'Jerome': 1425,
    'Jersey County': 1425,
    'Jerseyville': 1425,
    'Jo Daviess County': 1425,
    'Johnsburg': 1425,
    'Johnson County': 1425,
    'Johnston City': 1425,
    'Joliet': 1425,
    'Jonesboro': 1425,
    'Justice': 1425,
    'Kane County': 1425,
    'Kankakee': 1425,
    'Kankakee County': 1425,
    'Kendall County': 1425,
    'Kenilworth': 1425,
    'Kenwood': 1425,
    'Kewanee': 1425,
    'Kildeer': 1425,
    'Kincaid': 1425,
    'Kingston': 1425,
    'Kirkland': 1425,
    'Knollwood': 1425,
    'Knox County': 1425,
    'Knoxville': 1425,
    'La Grange': 1425,
    'La Grange Park': 1425,
    'La Harpe': 1425,
    'La Salle': 1425,
    'Lacon': 1425,
    'Ladd': 1425,
    'Lake Barrington': 1425,
    'Lake Bluff': 1425,
    'Lake Camelot': 1425,
    'Lake Catherine': 1425,
    'Lake County': 1425,
    'Lake Forest': 1425,
    'Lake Holiday': 1425,
    'Lake in the Hills': 1425,
    'Lake of the Woods': 1425,
    'Lake Summerset': 1425,
    'Lake Villa': 1425,
    'Lake Zurich': 1425,
    'Lakemoor': 1425,
    'Lakewood': 1425,
    'Lakewood Shores': 1425,
    'Lanark': 1425,
    'Lansing': 1425,
    'LaSalle County': 1425,
    'Lawrence County': 1425,
    'Lawrenceville': 1425,
    'Le Roy': 1425,
    'Lebanon': 1425,
    'Lee County': 1425,
    'Leland Grove': 1425,
    'Lemont': 1425,
    'Lena': 1425,
    'Lewistown': 1425,
    'Lexington': 1425,
    'Libertyville': 1425,
    'Lily Lake': 1425,
    'Limestone': 1425,
    'Lincoln': 1425,
    'Lincoln Park': 1425,
    'Lincoln Square': 1425,
    'Lincolnshire': 1425,
    'Lincolnwood': 1425,
    'Lindenhurst': 1425,
    'Lisle': 1425,
    'Litchfield': 1425,
    'Livingston County': 1425,
    'Lockport': 1425,
    'Logan County': 1425,
    'Logan Square': 1425,
    'Lombard': 1425,
    'Long Creek': 1425,
    'Long Grove': 1425,
    'Long Lake': 1425,
    'Louisville': 1425,
    'Loves Park': 1425,
    'Lovington': 1425,
    'Lower West Side': 1425,
    'Lynwood': 1425,
    'Lyons': 1425,
    'Machesney Park': 1425,
    'Mackinaw': 1425,
    'Macomb': 1425,
    'Macon': 1425,
    'Macon County': 1425,
    'Macoupin County': 1425,
    'Madison': 1425,
    'Madison County': 1425,
    'Mahomet': 1425,
    'Malta': 1425,
    'Manhattan': 1425,
    'Manito': 1425,
    'Manteno': 1425,
    'Maple Park': 1425,
    'Marengo': 1425,
    'Marion': 1425,
    'Marion County': 1425,
    'Marissa': 1425,
    'Markham': 1425,
    'Maroa': 1425,
    'Marquette Heights': 1425,
    'Marseilles': 1425,
    'Marshall': 1425,
    'Marshall County': 1425,
    'Martinsville': 1425,
    'Maryville': 1425,
    'Mascoutah': 1425,
    'Mason City': 1425,
    'Mason County': 1425,
    'Massac County': 1425,
    'Matteson': 1425,
    'Mattoon': 1425,
    'Maywood': 1425,
    'McCullom Lake': 1425,
    'McDonough County': 1425,
    'McHenry': 1425,
    'McHenry County': 1425,
    'McKinley Park': 1425,
    'McLean County': 1425,
    'McLeansboro': 1425,
    'Melrose Park': 1425,
    'Menard County': 1425,
    'Mendota': 1425,
    'Mercer County': 1425,
    'Meredosia': 1425,
    'Merrionette Park': 1425,
    'Metamora': 1425,
    'Metropolis': 1425,
    'Midlothian': 1425,
    'Milan': 1425,
    'Milford': 1425,
    'Millstadt': 1425,
    'Minier': 1425,
    'Minonk': 1425,
    'Minooka': 1425,
    'Mitchell': 1425,
    'Mokena': 1425,
    'Moline': 1425,
    'Momence': 1425,
    'Monee': 1425,
    'Monmouth': 1425,
    'Monroe County': 1425,
    'Montgomery': 1425,
    'Montgomery County': 1425,
    'Monticello': 1425,
    'Morgan County': 1425,
    'Morgan Park': 1425,
    'Morris': 1425,
    'Morrison': 1425,
    'Morrisonville': 1425,
    'Morton': 1425,
    'Morton Grove': 1425,
    'Moultrie County': 1425,
    'Mound City': 1425,
    'Mount Carmel': 1425,
    'Mount Carroll': 1425,
    'Mount Greenwood': 1425,
    'Mount Morris': 1425,
    'Mount Olive': 1425,
    'Mount Prospect': 1425,
    'Mount Pulaski': 1425,
    'Mount Sterling': 1425,
    'Mount Vernon': 1425,
    'Mount Zion': 1425,
    'Moweaqua': 1425,
    'Mundelein': 1425,
    'Murphysboro': 1425,
    'Naperville': 1425,
    'Nashville': 1425,
    'Nauvoo': 1425,
    'Near North Side': 1425,
    'Near South Side': 1425,
    'Neoga': 1425,
    'New Athens': 1425,
    'New Baden': 1425,
    'New Berlin': 1425,
    'New City': 1425,
    'New Lenox': 1425,
    'Newark': 1425,
    'Newton': 1425,
    'Niles': 1425,
    'Nokomis': 1425,
    'Normal': 1425,
    'Norridge': 1425,
    'Norris City': 1425,
    'North Aurora': 1425,
    'North Barrington': 1425,
    'North Center': 1425,
    'North Chicago': 1425,
    'North Lawndale': 1425,
    'North Pekin': 1425,
    'North Peoria': 1425,
    'North Riverside': 1425,
    'Northbrook': 1425,
    'Northfield': 1425,
    'Northlake': 1425,
    'O  Fallon': 1425,
    'Oak Brook': 1425,
    'Oak Forest': 1425,
    'Oak Lawn': 1425,
    'Oak Park': 1425,
    'Oakbrook Terrace': 1425,
    'Oakwood': 1425,
    'Oakwood Hills': 1425,
    'Oblong': 1425,
    'Odin': 1425,
    'Ogle County': 1425,
    'Oglesby': 1425,
    'Okawville': 1425,
    'Olney': 1425,
    'Olympia Fields': 1425,
    'Onarga': 1425,
    'Oquawka': 1425,
    'Oregon': 1425,
    'Orion': 1425,
    'Orland Hills': 1425,
    'Orland Park': 1425,
    'Oswego': 1425,
    'Ottawa': 1425,
    'Palatine': 1425,
    'Palestine': 1425,
    'Palos Heights': 1425,
    'Palos Hills': 1425,
    'Palos Park': 1425,
    'Pana': 1425,
    'Paris': 1425,
    'Park City': 1425,
    'Park Forest': 1425,
    'Park Ridge': 1425,
    'Pawnee': 1425,
    'Paxton': 1425,
    'Payson': 1425,
    'Pecatonica': 1425,
    'Pekin': 1425,
    'Peoria': 1425,
    'Peoria County': 1425,
    'Peoria Heights': 1425,
    'Peotone': 1425,
    'Perry County': 1425,
    'Peru': 1425,
    'Petersburg': 1425,
    'Philo': 1425,
    'Phoenix': 1425,
    'Piatt County': 1425,
    'Pike County': 1425,
    'Pinckneyville': 1425,
    'Pingree Grove': 1425,
    'Pistakee Highlands': 1425,
    'Pittsfield': 1425,
    'Plainfield': 1425,
    'Plano': 1425,
    'Polo': 1425,
    'Pontiac': 1425,
    'Pontoon Beach': 1425,
    'Pope County': 1425,
    'Poplar Grove': 1425,
    'Port Barrington': 1425,
    'Port Byron': 1425,
    'Portage Park': 1425,
    'Posen': 1425,
    'Prairie Grove': 1425,
    'Prestbury': 1425,
    'Preston Heights': 1425,
    'Princeton': 1425,
    'Princeville': 1425,
    'Prophetstown': 1425,
    'Prospect Heights': 1425,
    'Pulaski County': 1425,
    'Putnam County': 1425,
    'Quincy': 1425,
    'Ramsey': 1425,
    'Randolph County': 1425,
    'Rantoul': 1425,
    'Red Bud': 1425,
    'Richland County': 1425,
    'Richmond': 1425,
    'Richton Park': 1425,
    'River Forest': 1425,
    'River Grove': 1425,
    'Riverdale': 1425,
    'Riverside': 1425,
    'Riverton': 1425,
    'Riverwoods': 1425,
    'Roanoke': 1425,
    'Robbins': 1425,
    'Robinson': 1425,
    'Rochelle': 1425,
    'Rochester': 1425,
    'Rock Falls': 1425,
    'Rock Island': 1425,
    'Rock Island County': 1425,
    'Rockdale': 1425,
    'Rockford': 1425,
    'Rockton': 1425,
    'Rogers Park': 1425,
    'Rolling Meadows': 1425,
    'Rome': 1425,
    'Romeoville': 1425,
    'Roodhouse': 1425,
    'Roscoe': 1425,
    'Roselle': 1425,
    'Rosemont': 1425,
    'Rosewood Heights': 1425,
    'Rosiclare': 1425,
    'Rossville': 1425,
    'Round Lake': 1425,
    'Round Lake Beach': 1425,
    'Round Lake Heights': 1425,
    'Round Lake Park': 1425,
    'Roxana': 1425,
    'Royalton': 1425,
    'Rushville': 1425,
    'Saint Anne': 1425,
    'Saint Charles': 1425,
    'Saint Clair County': 1425,
    'Saint Elmo': 1425,
    'Saint Jacob': 1425,
    'Saint Joseph': 1425,
    'Salem': 1425,
    'Saline County': 1425,
    'Sandoval': 1425,
    'Sandwich': 1425,
    'Sangamon County': 1425,
    'Sauk Village': 1425,
    'Savanna': 1425,
    'Savoy': 1425,
    'Schaumburg': 1425,
    'Schiller Park': 1425,
    'Schuyler County': 1425,
    'Scott Air Force Base': 1425,
    'Scott County': 1425,
    'Seneca': 1425,
    'Sesser': 1425,
    'Shawneetown': 1425,
    'Shelby County': 1425,
    'Shelbyville': 1425,
    'Sheldon': 1425,
    'Sheridan': 1425,
    'Sherman': 1425,
    'Shiloh': 1425,
    'Shorewood': 1425,
    'Sidney': 1425,
    'Silvis': 1425,
    'Skokie': 1425,
    'Sleepy Hollow': 1425,
    'Smithton': 1425,
    'Somonauk': 1425,
    'South Barrington': 1425,
    'South Beloit': 1425,
    'South Chicago': 1425,
    'South Chicago Heights': 1425,
    'South Elgin': 1425,
    'South Holland': 1425,
    'South Jacksonville': 1425,
    'South Lawndale': 1425,
    'South Pekin': 1425,
    'South Roxana': 1425,
    'South Shore': 1425,
    'Southern View': 1425,
    'Sparta': 1425,
    'Spring Grove': 1425,
    'Spring Valley': 1425,
    'Springfield': 1425,
    'Stark County': 1425,
    'Staunton': 1425,
    'Steeleville': 1425,
    'Steger': 1425,
    'Stephenson County': 1425,
    'Sterling': 1425,
    'Stickney': 1425,
    'Stillman Valley': 1425,
    'Stockton': 1425,
    'Stone Park': 1425,
    'Streamwood': 1425,
    'Streator': 1425,
    'Sugar Grove': 1425,
    'Sullivan': 1425,
    'Summit': 1425,
    'Sumner': 1425,
    'Swansea': 1425,
    'Sycamore': 1425,
    'Taylorville': 1425,
    'Tazewell County': 1425,
    'Teutopolis': 1425,
    'The Galena Territory': 1425,
    'Third Lake': 1425,
    'Thomasboro': 1425,
    'Thornton': 1425,
    'Tilton': 1425,
    'Tinley Park': 1425,
    'Toledo': 1425,
    'Tolono': 1425,
    'Toluca': 1425,
    'Toulon': 1425,
    'Tower Lake': 1425,
    'Tremont': 1425,
    'Trenton': 1425,
    'Troy': 1425,
    'Tuscola': 1425,
    'Twin Grove': 1425,
    'Union County': 1425,
    'University Park': 1425,
    'Upper Alton': 1425,
    'Uptown': 1425,
    'Urbana': 1425,
    'Valmeyer': 1425,
    'Vandalia': 1425,
    'Venetian Village': 1425,
    'Venice': 1425,
    'Vermilion County': 1425,
    'Vernon Hills': 1425,
    'Vienna': 1425,
    'Villa Grove': 1425,
    'Villa Park': 1425,
    'Village of Campton Hills': 1425,
    'Virden': 1425,
    'Virginia': 1425,
    'Volo': 1425,
    'Wabash County': 1425,
    'Wadsworth': 1425,
    'Walnut': 1425,
    'Wamac': 1425,
    'Warren': 1425,
    'Warren County': 1425,
    'Warrensburg': 1425,
    'Warrenville': 1425,
    'Warsaw': 1425,
    'Wasco': 1425,
    'Washburn': 1425,
    'Washington': 1425,
    'Washington County': 1425,
    'Washington Park': 1425,
    'Waterloo': 1425,
    'Waterman': 1425,
    'Watseka': 1425,
    'Wauconda': 1425,
    'Waukegan': 1425,
    'Waverly': 1425,
    'Wayne': 1425,
    'Wayne City': 1425,
    'Wayne County': 1425,
    'West Chicago': 1425,
    'West Dundee': 1425,
    'West Elsdon': 1425,
    'West Englewood': 1425,
    'West Frankfort': 1425,
    'West Garfield Park': 1425,
    'West Lawn': 1425,
    'West Peoria': 1425,
    'West Ridge': 1425,
    'West Town': 1425,
    'Westchester': 1425,
    'Western Springs': 1425,
    'Westmont': 1425,
    'Westville': 1425,
    'Wheaton': 1425,
    'Wheeling': 1425,
    'White County': 1425,
    'White Hall': 1425,
    'Whiteside County': 1425,
    'Will County': 1425,
    'Williamson County': 1425,
    'Williamsville': 1425,
    'Willow Springs': 1425,
    'Willowbrook': 1425,
    'Wilmette': 1425,
    'Wilmington': 1425,
    'Winchester': 1425,
    'Windsor': 1425,
    'Winfield': 1425,
    'Winnebago': 1425,
    'Winnebago County': 1425,
    'Winnetka': 1425,
    'Winthrop Harbor': 1425,
    'Wonder Lake': 1425,
    'Wood Dale': 1425,
    'Wood River': 1425,
    'Woodford County': 1425,
    'Woodlawn': 1425,
    'Woodridge': 1425,
    'Woodstock': 1425,
    'Worden': 1425,
    'Worth': 1425,
    'Wyoming': 1425,
    'Yorkville': 1425,
    'Zeigler': 1425,
    'Zion': 1425,
    'Aberdeen': 1440,
    'Adams County': 1440,
    'Akron': 1440,
    'Albany': 1440,
    'Albion': 1440,
    'Alexandria': 1440,
    'Allen County': 1440,
    'Anderson': 1440,
    'Andrews': 1440,
    'Angola': 1440,
    'Arcadia': 1440,
    'Argos': 1440,
    'Attica': 1440,
    'Auburn': 1440,
    'Aurora': 1440,
    'Austin': 1440,
    'Avilla': 1440,
    'Avon': 1440,
    'Bargersville': 1440,
    'Bartholomew County': 1440,
    'Bass Lake': 1440,
    'Batesville': 1440,
    'Battle Ground': 1440,
    'Bedford': 1440,
    'Beech Grove': 1440,
    'Benton County': 1440,
    'Berne': 1440,
    'Bicknell': 1440,
    'Blackford County': 1440,
    'Bloomfield': 1440,
    'Bloomington': 1440,
    'Bluffton': 1440,
    'Boone County': 1440,
    'Boonville': 1440,
    'Bourbon': 1440,
    'Brazil': 1440,
    'Bremen': 1440,
    'Bright': 1440,
    'Bristol': 1440,
    'Broad Ripple': 1440,
    'Brooklyn': 1440,
    'Brookston': 1440,
    'Brookville': 1440,
    'Brown County': 1440,
    'Brownsburg': 1440,
    'Brownstown': 1440,
    'Burns Harbor': 1440,
    'Butler': 1440,
    'Cambridge City': 1440,
    'Cannelton': 1440,
    'Carmel': 1440,
    'Carroll County': 1440,
    'Cass County': 1440,
    'Cayuga': 1440,
    'Cedar Lake': 1440,
    'Centerville': 1440,
    'Chandler': 1440,
    'Charlestown': 1440,
    'Chesterfield': 1440,
    'Chesterton': 1440,
    'Churubusco': 1440,
    'Cicero': 1440,
    'Clark County': 1440,
    'Clarksville': 1440,
    'Clay County': 1440,
    'Clermont': 1440,
    'Clinton': 1440,
    'Clinton County': 1440,
    'Cloverdale': 1440,
    'Columbia City': 1440,
    'Columbus': 1440,
    'Connersville': 1440,
    'Converse': 1440,
    'Cordry Sweetwater Lakes': 1440,
    'Corydon': 1440,
    'Country Squire Lakes': 1440,
    'Covington': 1440,
    'Crawford County': 1440,
    'Crawfordsville': 1440,
    'Crothersville': 1440,
    'Crown Point': 1440,
    'Culver': 1440,
    'Cumberland': 1440,
    'Dale': 1440,
    'Daleville': 1440,
    'Danville': 1440,
    'Darmstadt': 1440,
    'Daviess County': 1440,
    'Dayton': 1440,
    'Dearborn County': 1440,
    'Decatur': 1440,
    'Decatur County': 1440,
    'DeKalb County': 1440,
    'Delaware County': 1440,
    'Delphi': 1440,
    'DeMotte': 1440,
    'Dillsboro': 1440,
    'Dubois County': 1440,
    'Dunkirk': 1440,
    'Dunlap': 1440,
    'Dyer': 1440,
    'East Chicago': 1440,
    'Eaton': 1440,
    'Edgewood': 1440,
    'Edinburgh': 1440,
    'Elkhart': 1440,
    'Elkhart County': 1440,
    'Ellettsville': 1440,
    'Elwood': 1440,
    'English': 1440,
    'Evansville': 1440,
    'Fairfield Heights': 1440,
    'Fairmount': 1440,
    'Fairview Park': 1440,
    'Farmersburg': 1440,
    'Farmland': 1440,
    'Fayette County': 1440,
    'Ferdinand': 1440,
    'Fish Lake': 1440,
    'Fishers': 1440,
    'Flora': 1440,
    'Floyd County': 1440,
    'Fort Branch': 1440,
    'Fort Wayne': 1440,
    'Fortville': 1440,
    'Fountain County': 1440,
    'Fowler': 1440,
    'Frankfort': 1440,
    'Franklin': 1440,
    'Franklin County': 1440,
    'Frankton': 1440,
    'Fremont': 1440,
    'French Lick': 1440,
    'Fulton County': 1440,
    'Galena': 1440,
    'Galveston': 1440,
    'Garrett': 1440,
    'Gary': 1440,
    'Gas City': 1440,
    'Geneva': 1440,
    'Georgetown': 1440,
    'Gibson County': 1440,
    'Goodland': 1440,
    'Goshen': 1440,
    'Grabill': 1440,
    'Granger': 1440,
    'Grant County': 1440,
    'Greencastle': 1440,
    'Greendale': 1440,
    'Greene County': 1440,
    'Greenfield': 1440,
    'Greensburg': 1440,
    'Greentown': 1440,
    'Greenwood': 1440,
    'Griffith': 1440,
    'Grissom Air Force Base': 1440,
    'Gulivoire Park': 1440,
    'Hagerstown': 1440,
    'Hamilton': 1440,
    'Hamilton County': 1440,
    'Hammond': 1440,
    'Hancock County': 1440,
    'Hanover': 1440,
    'Harlan': 1440,
    'Harrison County': 1440,
    'Hartford City': 1440,
    'Haubstadt': 1440,
    'Hebron': 1440,
    'Hendricks County': 1440,
    'Henry County': 1440,
    'Henryville': 1440,
    'Heritage Lake': 1440,
    'Hidden Valley': 1440,
    'Highland': 1440,
    'Hobart': 1440,
    'Hope': 1440,
    'Howard County': 1440,
    'Hudson Lake': 1440,
    'Huntertown': 1440,
    'Huntingburg': 1440,
    'Huntington': 1440,
    'Huntington County': 1440,
    'Indian Heights': 1440,
    'Indianapolis': 1440,
    'Ingalls': 1440,
    'Jackson County': 1440,
    'Jasonville': 1440,
    'Jasper': 1440,
    'Jasper County': 1440,
    'Jay County': 1440,
    'Jefferson County': 1440,
    'Jeffersonville': 1440,
    'Jennings County': 1440,
    'Johnson County': 1440,
    'Jonesboro': 1440,
    'Kendallville': 1440,
    'Kentland': 1440,
    'Kingsford Heights': 1440,
    'Knightstown': 1440,
    'Knox': 1440,
    'Knox County': 1440,
    'Kokomo': 1440,
    'Koontz Lake': 1440,
    'Kosciusko County': 1440,
    'Kouts': 1440,
    'La Porte': 1440,
    'Lafayette': 1440,
    'Lagrange': 1440,
    'LaGrange County': 1440,
    'Lake County': 1440,
    'Lake Dalecarlia': 1440,
    'Lake Station': 1440,
    'Lakes of the Four Seasons': 1440,
    'Lapel': 1440,
    'LaPorte': 1440,
    'LaPorte County': 1440,
    'Lawrence': 1440,
    'Lawrence County': 1440,
    'Lawrenceburg': 1440,
    'Lebanon': 1440,
    'Leo-Cedarville': 1440,
    'Liberty': 1440,
    'Ligonier': 1440,
    'Linton': 1440,
    'Logansport': 1440,
    'Long Beach': 1440,
    'Loogootee': 1440,
    'Lowell': 1440,
    'Lynn': 1440,
    'Madison': 1440,
    'Madison County': 1440,
    'Marion': 1440,
    'Marion County': 1440,
    'Markle': 1440,
    'Marshall County': 1440,
    'Martin County': 1440,
    'Martinsville': 1440,
    'McCordsville': 1440,
    'Melody Hill': 1440,
    'Meridian Hills': 1440,
    'Merrillville': 1440,
    'Miami County': 1440,
    'Michigan City': 1440,
    'Middlebury': 1440,
    'Middletown': 1440,
    'Milan': 1440,
    'Milford': 1440,
    'Mishawaka': 1440,
    'Mitchell': 1440,
    'Monon': 1440,
    'Monroe County': 1440,
    'Monroeville': 1440,
    'Monrovia': 1440,
    'Montgomery County': 1440,
    'Monticello': 1440,
    'Montpelier': 1440,
    'Mooresville': 1440,
    'Morgan County': 1440,
    'Morocco': 1440,
    'Morristown': 1440,
    'Mount Vernon': 1440,
    'Mulberry': 1440,
    'Muncie': 1440,
    'Munster': 1440,
    'Nappanee': 1440,
    'Nashville': 1440,
    'New Albany': 1440,
    'New Carlisle': 1440,
    'New Castle': 1440,
    'New Chicago': 1440,
    'New Haven': 1440,
    'New Palestine': 1440,
    'New Paris': 1440,
    'New Pekin': 1440,
    'New Whiteland': 1440,
    'Newburgh': 1440,
    'Newport': 1440,
    'Newton County': 1440,
    'Noble County': 1440,
    'Noblesville': 1440,
    'North Judson': 1440,
    'North Liberty': 1440,
    'North Madison': 1440,
    'North Manchester': 1440,
    'North Terre Haute': 1440,
    'North Vernon': 1440,
    'North Webster': 1440,
    'Notre Dame': 1440,
    'Oak Park': 1440,
    'Oakland City': 1440,
    'Odon': 1440,
    'Ogden Dunes': 1440,
    'Ohio County': 1440,
    'Oolitic': 1440,
    'Orange County': 1440,
    'Orleans': 1440,
    'Osceola': 1440,
    'Osgood': 1440,
    'Ossian': 1440,
    'Otterbein': 1440,
    'Owen County': 1440,
    'Owensville': 1440,
    'Oxford': 1440,
    'Paoli': 1440,
    'Parke County': 1440,
    'Parker City': 1440,
    'Pendleton': 1440,
    'Perry County': 1440,
    'Peru': 1440,
    'Petersburg': 1440,
    'Pierceton': 1440,
    'Pike County': 1440,
    'Pittsboro': 1440,
    'Plainfield': 1440,
    'Plymouth': 1440,
    'Portage': 1440,
    'Porter': 1440,
    'Porter County': 1440,
    'Portland': 1440,
    'Posey County': 1440,
    'Poseyville': 1440,
    'Princes Lakes': 1440,
    'Princeton': 1440,
    'Pulaski County': 1440,
    'Putnam County': 1440,
    'Randolph County': 1440,
    'Redkey': 1440,
    'Remington': 1440,
    'Rensselaer': 1440,
    'Richmond': 1440,
    'Ripley County': 1440,
    'Rising Sun': 1440,
    'Roanoke': 1440,
    'Rochester': 1440,
    'Rockport': 1440,
    'Rockville': 1440,
    'Rome City': 1440,
    'Roselawn': 1440,
    'Rossville': 1440,
    'Rush County': 1440,
    'Rushville': 1440,
    'Russiaville': 1440,
    'Saint John': 1440,
    'Saint Joseph County': 1440,
    'Saint Paul': 1440,
    'Salem': 1440,
    'Santa Claus': 1440,
    'Schererville': 1440,
    'Scott County': 1440,
    'Scottsburg': 1440,
    'Seelyville': 1440,
    'Sellersburg': 1440,
    'Seymour': 1440,
    'Shadeland': 1440,
    'Shelburn': 1440,
    'Shelby County': 1440,
    'Shelbyville': 1440,
    'Sheridan': 1440,
    'Shoals': 1440,
    'Shorewood Forest': 1440,
    'Simonton Lake': 1440,
    'Smithville-Sanders': 1440,
    'South Bend': 1440,
    'South Haven': 1440,
    'South Whitley': 1440,
    'Southport': 1440,
    'Speedway': 1440,
    'Spencer': 1440,
    'Spencer County': 1440,
    'Starke County': 1440,
    'Steuben County': 1440,
    'Sullivan': 1440,
    'Sullivan County': 1440,
    'Sunman': 1440,
    'Sweetser': 1440,
    'Switzerland County': 1440,
    'Syracuse': 1440,
    'Tell City': 1440,
    'Terre Haute': 1440,
    'Thorntown': 1440,
    'Tippecanoe County': 1440,
    'Tipton': 1440,
    'Tipton County': 1440,
    'Topeka': 1440,
    'Trafalgar': 1440,
    'Trail Creek': 1440,
    'Tri-Lakes': 1440,
    'Union City': 1440,
    'Union County': 1440,
    'Upland': 1440,
    'Valparaiso': 1440,
    'Vanderburgh County': 1440,
    'Veedersburg': 1440,
    'Vermillion County': 1440,
    'Versailles': 1440,
    'Vevay': 1440,
    'Vigo County': 1440,
    'Vincennes': 1440,
    'Wabash': 1440,
    'Wabash County': 1440,
    'Wakarusa': 1440,
    'Walkerton': 1440,
    'Walton': 1440,
    'Wanatah': 1440,
    'Warren': 1440,
    'Warren County': 1440,
    'Warren Park': 1440,
    'Warrick County': 1440,
    'Warsaw': 1440,
    'Washington': 1440,
    'Washington County': 1440,
    'Waterloo': 1440,
    'Wayne County': 1440,
    'Wells County': 1440,
    'West Lafayette': 1440,
    'West Terre Haute': 1440,
    'Westfield': 1440,
    'Westport': 1440,
    'Westville': 1440,
    'White County': 1440,
    'Whiteland': 1440,
    'Whitestown': 1440,
    'Whiting': 1440,
    'Whitley County': 1440,
    'Williamsport': 1440,
    'Winamac': 1440,
    'Winchester': 1440,
    'Winfield': 1440,
    'Winona Lake': 1440,
    'Wolcottville': 1440,
    'Woodburn': 1440,
    'Worthington': 1440,
    'Yorktown': 1440,
    'Zionsville': 1440,
    'Ackley': 1459,
    'Adair County': 1459,
    'Adams County': 1459,
    'Adel': 1459,
    'Akron': 1459,
    'Albia': 1459,
    'Algona': 1459,
    'Allamakee County': 1459,
    'Allison': 1459,
    'Alta': 1459,
    'Alton': 1459,
    'Altoona': 1459,
    'Ames': 1459,
    'Anamosa': 1459,
    'Ankeny': 1459,
    'Aplington': 1459,
    'Appanoose County': 1459,
    'Arnolds Park': 1459,
    'Asbury': 1459,
    'Atkins': 1459,
    'Atlantic': 1459,
    'Audubon': 1459,
    'Audubon County': 1459,
    'Avoca': 1459,
    'Baxter': 1459,
    'Bedford': 1459,
    'Belle Plaine': 1459,
    'Bellevue': 1459,
    'Belmond': 1459,
    'Benton County': 1459,
    'Bettendorf': 1459,
    'Black Hawk County': 1459,
    'Bloomfield': 1459,
    'Blue Grass': 1459,
    'Bondurant': 1459,
    'Boone': 1459,
    'Boone County': 1459,
    'Bremer County': 1459,
    'Britt': 1459,
    'Brooklyn': 1459,
    'Buchanan County': 1459,
    'Buena Vista County': 1459,
    'Buffalo': 1459,
    'Buffalo (historical)': 1459,
    'Burlington': 1459,
    'Butler County': 1459,
    'Calhoun County': 1459,
    'Camanche': 1459,
    'Carlisle': 1459,
    'Carroll': 1459,
    'Carroll County': 1459,
    'Carter Lake': 1459,
    'Cascade': 1459,
    'Cass County': 1459,
    'Cedar County': 1459,
    'Cedar Falls': 1459,
    'Cedar Rapids': 1459,
    'Center Point': 1459,
    'Centerville': 1459,
    'Central City': 1459,
    'Cerro Gordo County': 1459,
    'Chariton': 1459,
    'Charles City': 1459,
    'Cherokee': 1459,
    'Cherokee County': 1459,
    'Chickasaw County': 1459,
    'Clarinda': 1459,
    'Clarion': 1459,
    'Clarke County': 1459,
    'Clarksville': 1459,
    'Clay County': 1459,
    'Clayton County': 1459,
    'Clear Lake': 1459,
    'Clinton': 1459,
    'Clinton County': 1459,
    'Clive': 1459,
    'Colfax': 1459,
    'Columbus Junction': 1459,
    'Conrad': 1459,
    'Coon Rapids': 1459,
    'Coralville': 1459,
    'Corning': 1459,
    'Corydon': 1459,
    'Council Bluffs': 1459,
    'Crawford County': 1459,
    'Cresco': 1459,
    'Creston': 1459,
    'Dakota City': 1459,
    'Dallas Center': 1459,
    'Dallas County': 1459,
    'Davenport': 1459,
    'Davis County': 1459,
    'De Soto': 1459,
    'De Witt': 1459,
    'Decatur County': 1459,
    'Decorah': 1459,
    'Delaware County': 1459,
    'Denison': 1459,
    'Denver': 1459,
    'Des Moines': 1459,
    'Des Moines County': 1459,
    'Dickinson County': 1459,
    'Dike': 1459,
    'Dubuque': 1459,
    'Dubuque County': 1459,
    'Durant': 1459,
    'Dyersville': 1459,
    'Dysart': 1459,
    'Eagle Grove': 1459,
    'Earlham': 1459,
    'Eddyville': 1459,
    'Eldora': 1459,
    'Eldridge': 1459,
    'Elk Run Heights': 1459,
    'Elkader': 1459,
    'Ely': 1459,
    'Emmet County': 1459,
    'Emmetsburg': 1459,
    'Epworth': 1459,
    'Estherville': 1459,
    'Evansdale': 1459,
    'Fairbank': 1459,
    'Fairfax': 1459,
    'Fairfield': 1459,
    'Farley': 1459,
    'Fayette': 1459,
    'Fayette County': 1459,
    'Floyd County': 1459,
    'Forest City': 1459,
    'Fort Dodge': 1459,
    'Fort Madison': 1459,
    'Franklin County': 1459,
    'Fremont County': 1459,
    'Garner': 1459,
    'George': 1459,
    'Gilbert': 1459,
    'Glenwood': 1459,
    'Glidden': 1459,
    'Granger': 1459,
    'Greene': 1459,
    'Greene County': 1459,
    'Greenfield': 1459,
    'Grimes': 1459,
    'Grinnell': 1459,
    'Grundy Center': 1459,
    'Grundy County': 1459,
    'Guthrie Center': 1459,
    'Guthrie County': 1459,
    'Guttenberg': 1459,
    'Hamburg': 1459,
    'Hamilton County': 1459,
    'Hampton': 1459,
    'Hancock County': 1459,
    'Hardin County': 1459,
    'Harlan': 1459,
    'Harrison County': 1459,
    'Hartley': 1459,
    'Hawarden': 1459,
    'Henry County': 1459,
    'Hiawatha': 1459,
    'Holstein': 1459,
    'Howard County': 1459,
    'Hudson': 1459,
    'Hull': 1459,
    'Humboldt': 1459,
    'Humboldt County': 1459,
    'Huxley': 1459,
    'Ida County': 1459,
    'Ida Grove': 1459,
    'Independence': 1459,
    'Indianola': 1459,
    'Iowa City': 1459,
    'Iowa County': 1459,
    'Iowa Falls': 1459,
    'Jackson County': 1459,
    'Jasper County': 1459,
    'Jefferson': 1459,
    'Jefferson County': 1459,
    'Jesup': 1459,
    'Jewell': 1459,
    'Johnson County': 1459,
    'Johnston': 1459,
    'Jones County': 1459,
    'Kalona': 1459,
    'Keokuk': 1459,
    'Keokuk County': 1459,
    'Keosauqua': 1459,
    'Kingsley': 1459,
    'Knoxville': 1459,
    'Kossuth County': 1459,
    'La Porte City': 1459,
    'Lake City': 1459,
    'Lake Mills': 1459,
    'Lake Panorama': 1459,
    'Lake Park': 1459,
    'Lake View': 1459,
    'Lamoni': 1459,
    'Laurens': 1459,
    'Le Claire': 1459,
    'Le Mars': 1459,
    'Lee County': 1459,
    'Lenox': 1459,
    'Leon': 1459,
    'Linn County': 1459,
    'Lisbon': 1459,
    'Logan': 1459,
    'Lone Tree': 1459,
    'Louisa County': 1459,
    'Lucas County': 1459,
    'Lyon County': 1459,
    'Madison County': 1459,
    'Madrid': 1459,
    'Maharishi Vedic City': 1459,
    'Mahaska County': 1459,
    'Malvern': 1459,
    'Manchester': 1459,
    'Manly': 1459,
    'Manning': 1459,
    'Manson': 1459,
    'Mapleton': 1459,
    'Maquoketa': 1459,
    'Marcus': 1459,
    'Marengo': 1459,
    'Marion': 1459,
    'Marion County': 1459,
    'Marshall County': 1459,
    'Marshalltown': 1459,
    'Mason City': 1459,
    'Mechanicsville': 1459,
    'Mediapolis': 1459,
    'Melcher-Dallas': 1459,
    'Milford': 1459,
    'Mills County': 1459,
    'Missouri Valley': 1459,
    'Mitchell County': 1459,
    'Mitchellville': 1459,
    'Monona': 1459,
    'Monona County': 1459,
    'Monroe': 1459,
    'Monroe County': 1459,
    'Montezuma': 1459,
    'Montgomery County': 1459,
    'Monticello': 1459,
    'Mount Ayr': 1459,
    'Mount Pleasant': 1459,
    'Mount Vernon': 1459,
    'Moville': 1459,
    'Muscatine': 1459,
    'Muscatine County': 1459,
    'Nashua': 1459,
    'Nevada': 1459,
    'New Hampton': 1459,
    'New London': 1459,
    'New Sharon': 1459,
    'Newton': 1459,
    'Nora Springs': 1459,
    'North English': 1459,
    'North Liberty': 1459,
    'Northwood': 1459,
    'Norwalk': 1459,
    'O  Brien County': 1459,
    'Oakland': 1459,
    'Oelwein': 1459,
    'Ogden': 1459,
    'Onawa': 1459,
    'Orange City': 1459,
    'Osage': 1459,
    'Osceola': 1459,
    'Osceola County': 1459,
    'Oskaloosa': 1459,
    'Ottumwa': 1459,
    'Page County': 1459,
    'Palo': 1459,
    'Palo Alto County': 1459,
    'Panora': 1459,
    'Park View': 1459,
    'Parkersburg': 1459,
    'Paullina': 1459,
    'Pella': 1459,
    'Peosta': 1459,
    'Perry': 1459,
    'Pleasant Hill': 1459,
    'Pleasantville': 1459,
    'Plymouth County': 1459,
    'Pocahontas': 1459,
    'Pocahontas County': 1459,
    'Polk City': 1459,
    'Polk County': 1459,
    'Postville': 1459,
    'Pottawattamie County': 1459,
    'Poweshiek County': 1459,
    'Prairie City': 1459,
    'Preston': 1459,
    'Primghar': 1459,
    'Red Oak': 1459,
    'Reinbeck': 1459,
    'Remsen': 1459,
    'Ringgold County': 1459,
    'Riverside': 1459,
    'Robins': 1459,
    'Rock Rapids': 1459,
    'Rock Valley': 1459,
    'Rockwell': 1459,
    'Rockwell City': 1459,
    'Roland': 1459,
    'Sac City': 1459,
    'Sac County': 1459,
    'Saint Ansgar': 1459,
    'Sanborn': 1459,
    'Saylorville': 1459,
    'Scott County': 1459,
    'Sergeant Bluff': 1459,
    'Sheffield': 1459,
    'Shelby County': 1459,
    'Sheldon': 1459,
    'Shell Rock': 1459,
    'Shenandoah': 1459,
    'Sibley': 1459,
    'Sidney': 1459,
    'Sigourney': 1459,
    'Sioux Center': 1459,
    'Sioux City': 1459,
    'Sioux County': 1459,
    'Slater': 1459,
    'Solon': 1459,
    'Spencer': 1459,
    'Spirit Lake': 1459,
    'Springville': 1459,
    'State Center': 1459,
    'Storm Lake': 1459,
    'Story City': 1459,
    'Story County': 1459,
    'Strawberry Point': 1459,
    'Stuart': 1459,
    'Sumner': 1459,
    'Tama': 1459,
    'Tama County': 1459,
    'Taylor County': 1459,
    'Tiffin': 1459,
    'Tipton': 1459,
    'Toledo': 1459,
    'Traer': 1459,
    'Tripoli': 1459,
    'Union County': 1459,
    'University Heights': 1459,
    'Urbana': 1459,
    'Urbandale': 1459,
    'Van Buren County': 1459,
    'Van Meter': 1459,
    'Villisca': 1459,
    'Vinton': 1459,
    'Walcott': 1459,
    'Walford': 1459,
    'Wapello': 1459,
    'Wapello County': 1459,
    'Warren County': 1459,
    'Washington': 1459,
    'Washington County': 1459,
    'Waterloo': 1459,
    'Waukee': 1459,
    'Waukon': 1459,
    'Waverly': 1459,
    'Wayne County': 1459,
    'Webster City': 1459,
    'Webster County': 1459,
    'Wellman': 1459,
    'West Branch': 1459,
    'West Burlington': 1459,
    'West Des Moines': 1459,
    'West Liberty': 1459,
    'West Union': 1459,
    'Williamsburg': 1459,
    'Wilton': 1459,
    'Windsor Heights': 1459,
    'Winfield': 1459,
    'Winnebago County': 1459,
    'Winneshiek County': 1459,
    'Winterset': 1459,
    'Woodbine': 1459,
    'Woodbury County': 1459,
    'Woodward': 1459,
    'Worth County': 1459,
    'Wright County': 1459,
    'Abilene': 1406,
    'Allen County': 1406,
    'Alma': 1406,
    'Altamont': 1406,
    'Anderson County': 1406,
    'Andover': 1406,
    'Anthony': 1406,
    'Arkansas City': 1406,
    'Arma': 1406,
    'Ashland': 1406,
    'Atchison': 1406,
    'Atchison County': 1406,
    'Atwood': 1406,
    'Auburn': 1406,
    'Augusta': 1406,
    'Baldwin City': 1406,
    'Barber County': 1406,
    'Barton County': 1406,
    'Basehor': 1406,
    'Baxter Springs': 1406,
    'Bellaire': 1406,
    'Belle Plaine': 1406,
    'Belleville': 1406,
    'Beloit': 1406,
    'Bonner Springs': 1406,
    'Bourbon County': 1406,
    'Brown County': 1406,
    'Buhler': 1406,
    'Burlington': 1406,
    'Butler County': 1406,
    'Caldwell': 1406,
    'Caney': 1406,
    'Carbondale': 1406,
    'Chanute': 1406,
    'Chapman': 1406,
    'Chase County': 1406,
    'Chautauqua County': 1406,
    'Cheney': 1406,
    'Cherokee County': 1406,
    'Cherryvale': 1406,
    'Chetopa': 1406,
    'Cheyenne County': 1406,
    'Cimarron': 1406,
    'Clark County': 1406,
    'Clay Center': 1406,
    'Clay County': 1406,
    'Clearwater': 1406,
    'Cloud County': 1406,
    'Coffey County': 1406,
    'Coffeyville': 1406,
    'Colby': 1406,
    'Coldwater': 1406,
    'Columbus': 1406,
    'Colwich': 1406,
    'Comanche County': 1406,
    'Concordia': 1406,
    'Conway Springs': 1406,
    'Cottonwood Falls': 1406,
    'Council Grove': 1406,
    'Cowley County': 1406,
    'Crawford County': 1406,
    'De Soto': 1406,
    'Decatur County': 1406,
    'Derby': 1406,
    'Dickinson County': 1406,
    'Dighton': 1406,
    'Dodge City': 1406,
    'Doniphan County': 1406,
    'Douglas County': 1406,
    'Douglass': 1406,
    'Edgerton': 1406,
    'Edwards County': 1406,
    'Edwardsville': 1406,
    'El Dorado': 1406,
    'Elk County': 1406,
    'Elkhart': 1406,
    'Ellinwood': 1406,
    'Ellis': 1406,
    'Ellis County': 1406,
    'Ellsworth': 1406,
    'Ellsworth County': 1406,
    'Elwood': 1406,
    'Emporia': 1406,
    'Erie': 1406,
    'Eudora': 1406,
    'Eureka': 1406,
    'Fairway': 1406,
    'Finney County': 1406,
    'Ford County': 1406,
    'Fort Riley North': 1406,
    'Fort Scott': 1406,
    'Franklin County': 1406,
    'Fredonia': 1406,
    'Frontenac': 1406,
    'Galena': 1406,
    'Garden City': 1406,
    'Gardner': 1406,
    'Garnett': 1406,
    'Geary County': 1406,
    'Girard': 1406,
    'Goddard': 1406,
    'Goodland': 1406,
    'Gove': 1406,
    'Gove County': 1406,
    'Graham County': 1406,
    'Grandview Plaza': 1406,
    'Grant County': 1406,
    'Gray County': 1406,
    'Great Bend': 1406,
    'Greeley County': 1406,
    'Greensburg': 1406,
    'Greenwood County': 1406,
    'Halstead': 1406,
    'Hamilton County': 1406,
    'Harper': 1406,
    'Harper County': 1406,
    'Harvey County': 1406,
    'Haskell County': 1406,
    'Haven': 1406,
    'Hays': 1406,
    'Haysville': 1406,
    'Herington': 1406,
    'Hesston': 1406,
    'Hiawatha': 1406,
    'Highland': 1406,
    'Hill City': 1406,
    'Hillsboro': 1406,
    'Hodgeman County': 1406,
    'Hoisington': 1406,
    'Holcomb': 1406,
    'Holton': 1406,
    'Horton': 1406,
    'Howard': 1406,
    'Hoxie': 1406,
    'Hugoton': 1406,
    'Humboldt': 1406,
    'Hutchinson': 1406,
    'Independence': 1406,
    'Inman': 1406,
    'Iola': 1406,
    'Jackson County': 1406,
    'Jefferson County': 1406,
    'Jetmore': 1406,
    'Jewell County': 1406,
    'Johnson': 1406,
    'Johnson County': 1406,
    'Junction City': 1406,
    'Kansas City': 1406,
    'Kearny County': 1406,
    'Kechi': 1406,
    'Kingman': 1406,
    'Kingman County': 1406,
    'Kinsley': 1406,
    'Kiowa': 1406,
    'Kiowa County': 1406,
    'La Crosse': 1406,
    'La Cygne': 1406,
    'Labette County': 1406,
    'Lakin': 1406,
    'Lane County': 1406,
    'Lansing': 1406,
    'Larned': 1406,
    'Lawrence': 1406,
    'Leavenworth': 1406,
    'Leavenworth County': 1406,
    'Leawood': 1406,
    'Lenexa': 1406,
    'Leoti': 1406,
    'Liberal': 1406,
    'Lincoln': 1406,
    'Lincoln County': 1406,
    'Lindsborg': 1406,
    'Linn County': 1406,
    'Logan County': 1406,
    'Louisburg': 1406,
    'Lyndon': 1406,
    'Lyon County': 1406,
    'Lyons': 1406,
    'Maize': 1406,
    'Manhattan': 1406,
    'Mankato': 1406,
    'Marion': 1406,
    'Marion County': 1406,
    'Marshall County': 1406,
    'Marysville': 1406,
    'McConnell AFB': 1406,
    'McPherson': 1406,
    'McPherson County': 1406,
    'Meade': 1406,
    'Meade County': 1406,
    'Medicine Lodge': 1406,
    'Merriam': 1406,
    'Miami County': 1406,
    'Minneapolis': 1406,
    'Mission': 1406,
    'Mission Hills': 1406,
    'Mitchell County': 1406,
    'Montgomery County': 1406,
    'Morris County': 1406,
    'Morton County': 1406,
    'Mound City': 1406,
    'Moundridge': 1406,
    'Mulvane': 1406,
    'Nemaha County': 1406,
    'Neodesha': 1406,
    'Neosho County': 1406,
    'Ness City': 1406,
    'Ness County': 1406,
    'New Century, KS': 1406,
    'Newton': 1406,
    'Nickerson': 1406,
    'North Newton': 1406,
    'Norton': 1406,
    'Norton County': 1406,
    'Oakley': 1406,
    'Oberlin': 1406,
    'Ogden': 1406,
    'Olathe': 1406,
    'Osage City': 1406,
    'Osage County': 1406,
    'Osawatomie': 1406,
    'Osborne': 1406,
    'Osborne County': 1406,
    'Oskaloosa': 1406,
    'Oswego': 1406,
    'Ottawa': 1406,
    'Ottawa County': 1406,
    'Overbrook': 1406,
    'Overland Park': 1406,
    'Oxford': 1406,
    'Paola': 1406,
    'Park City': 1406,
    'Parsons': 1406,
    'Pawnee County': 1406,
    'Peabody': 1406,
    'Phillips County': 1406,
    'Phillipsburg': 1406,
    'Pittsburg': 1406,
    'Plains': 1406,
    'Plainville': 1406,
    'Pleasanton': 1406,
    'Pottawatomie County': 1406,
    'Prairie Village': 1406,
    'Pratt': 1406,
    'Pratt County': 1406,
    'Rawlins County': 1406,
    'Reno County': 1406,
    'Republic County': 1406,
    'Rice County': 1406,
    'Riley County': 1406,
    'Roeland Park': 1406,
    'Rooks County': 1406,
    'Rose Hill': 1406,
    'Rossville': 1406,
    'Rush County': 1406,
    'Russell': 1406,
    'Russell County': 1406,
    'Sabetha': 1406,
    'Saint Francis': 1406,
    'Saint John': 1406,
    'Saint Marys': 1406,
    'Salina': 1406,
    'Saline County': 1406,
    'Satanta': 1406,
    'Scott City': 1406,
    'Scott County': 1406,
    'Sedan': 1406,
    'Sedgwick': 1406,
    'Sedgwick County': 1406,
    'Seneca': 1406,
    'Seward County': 1406,
    'Sharon Springs': 1406,
    'Shawnee': 1406,
    'Shawnee County': 1406,
    'Sheridan County': 1406,
    'Sherman County': 1406,
    'Silver Lake': 1406,
    'Smith Center': 1406,
    'Smith County': 1406,
    'Solomon': 1406,
    'South Hutchinson': 1406,
    'Spring Hill': 1406,
    'Stafford County': 1406,
    'Stanton County': 1406,
    'Sterling': 1406,
    'Stevens County': 1406,
    'Stockton': 1406,
    'Sublette': 1406,
    'Sumner County': 1406,
    'Syracuse': 1406,
    'Thomas County': 1406,
    'Tonganoxie': 1406,
    'Topeka': 1406,
    'Towanda': 1406,
    'Trego County': 1406,
    'Tribune': 1406,
    'Troy': 1406,
    'Ulysses': 1406,
    'Valley Center': 1406,
    'Valley Falls': 1406,
    'Victoria': 1406,
    'Wabaunsee County': 1406,
    'WaKeeney': 1406,
    'Wallace County': 1406,
    'Wamego': 1406,
    'Washington': 1406,
    'Washington County': 1406,
    'Wathena': 1406,
    'Wellington': 1406,
    'Wellsville': 1406,
    'Westmoreland': 1406,
    'Westwood': 1406,
    'Wichita': 1406,
    'Wichita County': 1406,
    'Wilson County': 1406,
    'Winfield': 1406,
    'Woodson County': 1406,
    'Wyandotte County': 1406,
    'Yates Center': 1406,
    'Adair County': 1419,
    'Albany': 1419,
    'Alexandria': 1419,
    'Allen County': 1419,
    'Anchorage': 1419,
    'Anderson County': 1419,
    'Annville': 1419,
    'Ashland': 1419,
    'Auburn': 1419,
    'Audubon Park': 1419,
    'Augusta': 1419,
    'Ballard County': 1419,
    'Barbourmeade': 1419,
    'Barbourville': 1419,
    'Bardstown': 1419,
    'Bardwell': 1419,
    'Barren County': 1419,
    'Bath County': 1419,
    'Beattyville': 1419,
    'Beaver Dam': 1419,
    'Bedford': 1419,
    'Beechwood Village': 1419,
    'Bell County': 1419,
    'Bellevue': 1419,
    'Benton': 1419,
    'Berea': 1419,
    'Bloomfield': 1419,
    'Boone County': 1419,
    'Booneville': 1419,
    'Bourbon County': 1419,
    'Bowling Green': 1419,
    'Boyd County': 1419,
    'Boyle County': 1419,
    'Bracken County': 1419,
    'Brandenburg': 1419,
    'Breathitt County': 1419,
    'Breckinridge Center': 1419,
    'Breckinridge County': 1419,
    'Brodhead': 1419,
    'Brooks': 1419,
    'Brooksville': 1419,
    'Brownsville': 1419,
    'Buckner': 1419,
    'Buechel': 1419,
    'Bullitt County': 1419,
    'Burkesville': 1419,
    'Burlington': 1419,
    'Butler County': 1419,
    'Cadiz': 1419,
    'Caldwell County': 1419,
    'Calhoun': 1419,
    'Calloway County': 1419,
    'Calvert City': 1419,
    'Camargo': 1419,
    'Campbell County': 1419,
    'Campbellsville': 1419,
    'Campton': 1419,
    'Carlisle': 1419,
    'Carlisle County': 1419,
    'Carroll County': 1419,
    'Carrollton': 1419,
    'Carter County': 1419,
    'Casey County': 1419,
    'Catlettsburg': 1419,
    'Cave City': 1419,
    'Central City': 1419,
    'Christian County': 1419,
    'Clark County': 1419,
    'Claryville': 1419,
    'Clay': 1419,
    'Clay City': 1419,
    'Clay County': 1419,
    'Clinton': 1419,
    'Clinton County': 1419,
    'Cloverport': 1419,
    'Coal Run Village': 1419,
    'Cold Spring': 1419,
    'Coldstream': 1419,
    'Columbia': 1419,
    'Corbin': 1419,
    'Covington': 1419,
    'Crescent Springs': 1419,
    'Crestview Hills': 1419,
    'Crestwood': 1419,
    'Crittenden': 1419,
    'Crittenden County': 1419,
    'Cumberland': 1419,
    'Cumberland County': 1419,
    'Cynthiana': 1419,
    'Danville': 1419,
    'Daviess County': 1419,
    'Dawson Springs': 1419,
    'Dayton': 1419,
    'Dixon': 1419,
    'Doe Valley': 1419,
    'Douglass Hills': 1419,
    'Dry Ridge': 1419,
    'Earlington': 1419,
    'Eddyville': 1419,
    'Edgewood': 1419,
    'Edmonson County': 1419,
    'Edmonton': 1419,
    'Elizabethtown': 1419,
    'Elk Creek': 1419,
    'Elkfork': 1419,
    'Elkton': 1419,
    'Elliott County': 1419,
    'Elsmere': 1419,
    'Eminence': 1419,
    'Erlanger': 1419,
    'Estill County': 1419,
    'Fairdale': 1419,
    'Falmouth': 1419,
    'Farley': 1419,
    'Fayette County': 1419,
    'Fern Creek': 1419,
    'Flatwoods': 1419,
    'Fleming County': 1419,
    'Flemingsburg': 1419,
    'Florence': 1419,
    'Floyd County': 1419,
    'Fort Campbell North': 1419,
    'Fort Knox': 1419,
    'Fort Mitchell': 1419,
    'Fort Thomas': 1419,
    'Fort Wright': 1419,
    'Francisville': 1419,
    'Frankfort': 1419,
    'Franklin': 1419,
    'Franklin County': 1419,
    'Frenchburg': 1419,
    'Fulton': 1419,
    'Fulton County': 1419,
    'Gallatin County': 1419,
    'Garrard County': 1419,
    'Georgetown': 1419,
    'Glasgow': 1419,
    'Grant County': 1419,
    'Graves County': 1419,
    'Graymoor-Devondale': 1419,
    'Grayson': 1419,
    'Grayson County': 1419,
    'Green County': 1419,
    'Greensburg': 1419,
    'Greenup': 1419,
    'Greenup County': 1419,
    'Greenville': 1419,
    'Guthrie': 1419,
    'Hancock County': 1419,
    'Hardin County': 1419,
    'Hardinsburg': 1419,
    'Harlan': 1419,
    'Harlan County': 1419,
    'Harrison County': 1419,
    'Harrodsburg': 1419,
    'Hart County': 1419,
    'Hartford': 1419,
    'Hawesville': 1419,
    'Hazard': 1419,
    'Hebron': 1419,
    'Hebron Estates': 1419,
    'Henderson': 1419,
    'Henderson County': 1419,
    'Hendron': 1419,
    'Henry County': 1419,
    'Heritage Creek': 1419,
    'Hickman': 1419,
    'Hickman County': 1419,
    'Highland Heights': 1419,
    'Highview': 1419,
    'Hillview': 1419,
    'Hindman': 1419,
    'Hodgenville': 1419,
    'Hopkins County': 1419,
    'Hopkinsville': 1419,
    'Horse Cave': 1419,
    'Hurstbourne': 1419,
    'Hurstbourne Acres': 1419,
    'Hyden': 1419,
    'Independence': 1419,
    'Indian Hills': 1419,
    'Indian Hills Cherokee Section': 1419,
    'Inez': 1419,
    'Ironville': 1419,
    'Irvine': 1419,
    'Irvington': 1419,
    'Jackson': 1419,
    'Jackson County': 1419,
    'Jamestown': 1419,
    'Jefferson County': 1419,
    'Jeffersontown': 1419,
    'Jeffersonville': 1419,
    'Jenkins': 1419,
    'Jessamine County': 1419,
    'Johnson County': 1419,
    'Junction City': 1419,
    'Kenton County': 1419,
    'Knott County': 1419,
    'Knottsville': 1419,
    'Knox County': 1419,
    'La Center': 1419,
    'La Grange': 1419,
    'Lakeside Park': 1419,
    'Lancaster': 1419,
    'Larue County': 1419,
    'Laurel County': 1419,
    'Lawrence County': 1419,
    'Lawrenceburg': 1419,
    'Lebanon': 1419,
    'Lebanon Junction': 1419,
    'Ledbetter': 1419,
    'Lee County': 1419,
    'Leitchfield': 1419,
    'Leslie County': 1419,
    'Letcher County': 1419,
    'Lewis County': 1419,
    'Lewisport': 1419,
    'Lexington': 1419,
    'Lexington-Fayette': 1419,
    'Liberty': 1419,
    'Lincoln County': 1419,
    'Livermore': 1419,
    'Livingston County': 1419,
    'Logan County': 1419,
    'London': 1419,
    'Louisa': 1419,
    'Louisville': 1419,
    'Ludlow': 1419,
    'Lyndon': 1419,
    'Lyon County': 1419,
    'Madison County': 1419,
    'Madisonville': 1419,
    'Magoffin County': 1419,
    'Manchester': 1419,
    'Marion': 1419,
    'Marion County': 1419,
    'Marshall County': 1419,
    'Martin County': 1419,
    'Mason County': 1419,
    'Masonville': 1419,
    'Massac': 1419,
    'Mayfield': 1419,
    'Maysville': 1419,
    'McCracken County': 1419,
    'McCreary County': 1419,
    'McKee': 1419,
    'McLean County': 1419,
    'Meade County': 1419,
    'Meads': 1419,
    'Menifee County': 1419,
    'Mercer County': 1419,
    'Metcalfe County': 1419,
    'Middlesboro': 1419,
    'Middletown': 1419,
    'Midway': 1419,
    'Monroe County': 1419,
    'Montgomery County': 1419,
    'Monticello': 1419,
    'Morehead': 1419,
    'Morgan County': 1419,
    'Morganfield': 1419,
    'Morgantown': 1419,
    'Mount Olivet': 1419,
    'Mount Sterling': 1419,
    'Mount Vernon': 1419,
    'Mount Washington': 1419,
    'Muhlenberg County': 1419,
    'Munfordville': 1419,
    'Murray': 1419,
    'Nelson County': 1419,
    'New Castle': 1419,
    'Newburg': 1419,
    'Newport': 1419,
    'Nicholas County': 1419,
    'Nicholasville': 1419,
    'North Corbin': 1419,
    'Northfield': 1419,
    'Nortonville': 1419,
    'Oak Grove': 1419,
    'Oakbrook': 1419,
    'Ohio County': 1419,
    'Okolona': 1419,
    'Oldham County': 1419,
    'Olive Hill': 1419,
    'Orchard Grass Hills': 1419,
    'Owen County': 1419,
    'Owensboro': 1419,
    'Owenton': 1419,
    'Owingsville': 1419,
    'Owsley County': 1419,
    'Paducah': 1419,
    'Paintsville': 1419,
    'Paris': 1419,
    'Park Hills': 1419,
    'Pendleton County': 1419,
    'Perry County': 1419,
    'Pewee Valley': 1419,
    'Pike County': 1419,
    'Pikeville': 1419,
    'Pine Knot': 1419,
    'Pineville': 1419,
    'Pioneer Village': 1419,
    'Plano': 1419,
    'Pleasure Ridge Park': 1419,
    'Powell County': 1419,
    'Prestonsburg': 1419,
    'Princeton': 1419,
    'Prospect': 1419,
    'Providence': 1419,
    'Pulaski County': 1419,
    'Raceland': 1419,
    'Radcliff': 1419,
    'Reidland': 1419,
    'Richmond': 1419,
    'Robertson County': 1419,
    'Rockcastle County': 1419,
    'Rowan County': 1419,
    'Russell': 1419,
    'Russell County': 1419,
    'Russell Springs': 1419,
    'Russellville': 1419,
    'Ryland Heights': 1419,
    'Saint Dennis': 1419,
    'Saint Matthews': 1419,
    'Saint Regis Park': 1419,
    'Salyersville': 1419,
    'Sandy Hook': 1419,
    'Scott County': 1419,
    'Scottsville': 1419,
    'Sebree': 1419,
    'Shelby County': 1419,
    'Shelbyville': 1419,
    'Shepherdsville': 1419,
    'Shively': 1419,
    'Silver Grove': 1419,
    'Simpson County': 1419,
    'Simpsonville': 1419,
    'Smithland': 1419,
    'Somerset': 1419,
    'South Shore': 1419,
    'Southgate': 1419,
    'Spencer County': 1419,
    'Springfield': 1419,
    'Stanford': 1419,
    'Stanton': 1419,
    'Stearns': 1419,
    'Sturgis': 1419,
    'Taylor County': 1419,
    'Taylor Mill': 1419,
    'Taylorsville': 1419,
    'Todd County': 1419,
    'Tompkinsville': 1419,
    'Trigg County': 1419,
    'Trimble County': 1419,
    'Union': 1419,
    'Union County': 1419,
    'Valley Station': 1419,
    'Van Lear': 1419,
    'Vanceburg': 1419,
    'Verona': 1419,
    'Versailles': 1419,
    'Villa Hills': 1419,
    'Vine Grove': 1419,
    'Walton': 1419,
    'Warren County': 1419,
    'Warsaw': 1419,
    'Washington County': 1419,
    'Watterson Park': 1419,
    'Wayne County': 1419,
    'Webster County': 1419,
    'West Buechel': 1419,
    'West Liberty': 1419,
    'Westwood': 1419,
    'Whitesburg': 1419,
    'Whitley City': 1419,
    'Whitley County': 1419,
    'Wickliffe': 1419,
    'Wilder': 1419,
    'Williamsburg': 1419,
    'Williamstown': 1419,
    'Wilmore': 1419,
    'Winchester': 1419,
    'Windy Hills': 1419,
    'Wolfe County': 1419,
    'Woodford County': 1419,
    'Worthington': 1419,
    'Worthington Hills': 1419,
    'Wurtland': 1419,
    'Abbeville': 1457,
    'Abita Springs': 1457,
    'Acadia Parish': 1457,
    'Addis': 1457,
    'Albany': 1457,
    'Alexandria': 1457,
    'Allen Parish': 1457,
    'Ama': 1457,
    'Amelia': 1457,
    'Amite': 1457,
    'Arabi': 1457,
    'Arcadia': 1457,
    'Arnaudville': 1457,
    'Ascension Parish': 1457,
    'Assumption Parish': 1457,
    'Avondale': 1457,
    'Avoyelles Parish': 1457,
    'Baker': 1457,
    'Baldwin': 1457,
    'Ball': 1457,
    'Banks Springs': 1457,
    'Barataria': 1457,
    'Basile': 1457,
    'Bastrop': 1457,
    'Baton Rouge': 1457,
    'Bawcomville': 1457,
    'Bayou Cane': 1457,
    'Bayou Gauche': 1457,
    'Bayou Vista': 1457,
    'Beauregard Parish': 1457,
    'Belle Chasse': 1457,
    'Belle Rose': 1457,
    'Benton': 1457,
    'Bernice': 1457,
    'Berwick': 1457,
    'Bienville Parish': 1457,
    'Blanchard': 1457,
    'Bogalusa': 1457,
    'Bossier City': 1457,
    'Bossier Parish': 1457,
    'Bourg': 1457,
    'Boutte': 1457,
    'Breaux Bridge': 1457,
    'Bridge City': 1457,
    'Broussard': 1457,
    'Brownsfield': 1457,
    'Brownsville': 1457,
    'Brusly': 1457,
    'Bunkie': 1457,
    'Caddo Parish': 1457,
    'Cade': 1457,
    'Calcasieu Parish': 1457,
    'Caldwell Parish': 1457,
    'Cameron': 1457,
    'Cameron Parish': 1457,
    'Campti': 1457,
    'Carencro': 1457,
    'Carlyss': 1457,
    'Carville': 1457,
    'Catahoula': 1457,
    'Catahoula Parish': 1457,
    'Cecilia': 1457,
    'Central': 1457,
    'Chackbay': 1457,
    'Chalmette': 1457,
    'Charenton': 1457,
    'Chauvin': 1457,
    'Church Point': 1457,
    'Claiborne': 1457,
    'Claiborne Parish': 1457,
    'Clarks': 1457,
    'Clinton': 1457,
    'Colfax': 1457,
    'Columbia': 1457,
    'Concordia Parish': 1457,
    'Convent': 1457,
    'Cottonport': 1457,
    'Coushatta': 1457,
    'Covington': 1457,
    'Crowley': 1457,
    'Cullen': 1457,
    'Cut Off': 1457,
    'De Soto Parish': 1457,
    'Delcambre': 1457,
    'Delhi': 1457,
    'Denham Springs': 1457,
    'DeQuincy': 1457,
    'DeRidder': 1457,
    'Des Allemands': 1457,
    'Destrehan': 1457,
    'Deville': 1457,
    'Donaldsonville': 1457,
    'Dulac': 1457,
    'Duson': 1457,
    'East Baton Rouge Parish': 1457,
    'East Carroll Parish': 1457,
    'East Feliciana Parish': 1457,
    'Eastwood': 1457,
    'Eden Isle': 1457,
    'Edgard': 1457,
    'Elmwood': 1457,
    'Elton': 1457,
    'Erath': 1457,
    'Erwinville': 1457,
    'Estelle': 1457,
    'Eunice': 1457,
    'Evangeline Parish': 1457,
    'Farmerville': 1457,
    'Ferriday': 1457,
    'Fort Polk North': 1457,
    'Fort Polk South': 1457,
    'Franklin': 1457,
    'Franklin Parish': 1457,
    'Franklinton': 1457,
    'French Settlement': 1457,
    'Galliano': 1457,
    'Gardere': 1457,
    'Garyville': 1457,
    'Glenmora': 1457,
    'Golden Meadow': 1457,
    'Gonzales': 1457,
    'Grambling': 1457,
    'Gramercy': 1457,
    'Grand Isle': 1457,
    'Grand Point': 1457,
    'Grant Parish': 1457,
    'Gray': 1457,
    'Greensburg': 1457,
    'Greenwood': 1457,
    'Gretna': 1457,
    'Gueydan': 1457,
    'Hackberry': 1457,
    'Hahnville': 1457,
    'Hammond': 1457,
    'Harahan': 1457,
    'Harrisonburg': 1457,
    'Harvey': 1457,
    'Haughton': 1457,
    'Haynesville': 1457,
    'Henderson': 1457,
    'Homer': 1457,
    'Houma': 1457,
    'Iberia Parish': 1457,
    'Iberville Parish': 1457,
    'Independence': 1457,
    'Inniswold': 1457,
    'Iota': 1457,
    'Iowa': 1457,
    'Jackson': 1457,
    'Jackson Parish': 1457,
    'Jean Lafitte': 1457,
    'Jeanerette': 1457,
    'Jefferson': 1457,
    'Jefferson Davis Parish': 1457,
    'Jefferson Parish': 1457,
    'Jena': 1457,
    'Jennings': 1457,
    'Jonesboro': 1457,
    'Jonesville': 1457,
    'Kaplan': 1457,
    'Kenner': 1457,
    'Kentwood': 1457,
    'Killian': 1457,
    'Kinder': 1457,
    'Krotz Springs': 1457,
    'La Salle Parish': 1457,
    'Labadieville': 1457,
    'Lacombe': 1457,
    'Lafayette': 1457,
    'Lafayette Parish': 1457,
    'Lafourche Parish': 1457,
    'Lake Arthur': 1457,
    'Lake Charles': 1457,
    'Lake Providence': 1457,
    'Lakeshore': 1457,
    'Laplace': 1457,
    'Larose': 1457,
    'Lawtell': 1457,
    'Lecompte': 1457,
    'Leesville': 1457,
    'Leonville': 1457,
    'Lincoln Parish': 1457,
    'Livingston': 1457,
    'Livingston Parish': 1457,
    'Livonia': 1457,
    'Lockport': 1457,
    'Lockport Heights': 1457,
    'Logansport': 1457,
    'Luling': 1457,
    'Lutcher': 1457,
    'Madison Parish': 1457,
    'Mamou': 1457,
    'Mandeville': 1457,
    'Mansfield': 1457,
    'Mansura': 1457,
    'Many': 1457,
    'Maringouin': 1457,
    'Marksville': 1457,
    'Marrero': 1457,
    'Mathews': 1457,
    'Maurice': 1457,
    'Melville': 1457,
    'Meraux': 1457,
    'Merrydale': 1457,
    'Merryville': 1457,
    'Metairie': 1457,
    'Metairie Terrace': 1457,
    'Midway': 1457,
    'Milton': 1457,
    'Minden': 1457,
    'Minorca': 1457,
    'Monroe': 1457,
    'Montegut': 1457,
    'Monticello': 1457,
    'Montz': 1457,
    'Morehouse Parish': 1457,
    'Morgan City': 1457,
    'Moss Bluff': 1457,
    'Napoleonville': 1457,
    'Natalbany': 1457,
    'Natchitoches': 1457,
    'Natchitoches Parish': 1457,
    'New Iberia': 1457,
    'New Llano': 1457,
    'New Orleans': 1457,
    'New Roads': 1457,
    'New Sarpy': 1457,
    'Newellton': 1457,
    'Norco': 1457,
    'North Vacherie': 1457,
    'Oak Grove': 1457,
    'Oak Hills Place': 1457,
    'Oakdale': 1457,
    'Oberlin': 1457,
    'Old Jefferson': 1457,
    'Olla': 1457,
    'Opelousas': 1457,
    'Orleans Parish': 1457,
    'Ossun': 1457,
    'Ouachita Parish': 1457,
    'Paradis': 1457,
    'Patterson': 1457,
    'Paulina': 1457,
    'Pearl River': 1457,
    'Pierre Part': 1457,
    'Pine Prairie': 1457,
    'Pineville': 1457,
    'Plaquemine': 1457,
    'Plaquemines Parish': 1457,
    'Pointe Coupee Parish': 1457,
    'Ponchatoula': 1457,
    'Port Allen': 1457,
    'Port Barre': 1457,
    'Port Sulphur': 1457,
    'Poydras': 1457,
    'Prairieville': 1457,
    'Presquille': 1457,
    'Prien': 1457,
    'Raceland': 1457,
    'Rapides Parish': 1457,
    'Rayne': 1457,
    'Rayville': 1457,
    'Red Chute': 1457,
    'Red River Parish': 1457,
    'Reserve': 1457,
    'Richland Parish': 1457,
    'Richwood': 1457,
    'Ringgold': 1457,
    'River Ridge': 1457,
    'Roseland': 1457,
    'Rosepine': 1457,
    'Ruston': 1457,
    'Sabine Parish': 1457,
    'Saint Bernard Parish': 1457,
    'Saint Charles Parish': 1457,
    'Saint Francisville': 1457,
    'Saint Gabriel': 1457,
    'Saint Helena Parish': 1457,
    'Saint James Parish': 1457,
    'Saint John the Baptist Parish': 1457,
    'Saint Joseph': 1457,
    'Saint Landry Parish': 1457,
    'Saint Martin Parish': 1457,
    'Saint Martinville': 1457,
    'Saint Mary Parish': 1457,
    'Saint Rose': 1457,
    'Saint Tammany Parish': 1457,
    'Schriever': 1457,
    'Scott': 1457,
    'Shenandoah': 1457,
    'Shreveport': 1457,
    'Sibley': 1457,
    'Simmesport': 1457,
    'Slidell': 1457,
    'Sorrento': 1457,
    'South Vacherie': 1457,
    'Springhill': 1457,
    'Sterlington': 1457,
    'Stonewall': 1457,
    'Sulphur': 1457,
    'Sunset': 1457,
    'Supreme': 1457,
    'Swartz': 1457,
    'Tallulah': 1457,
    'Tangipahoa Parish': 1457,
    'Tensas Parish': 1457,
    'Terrebonne Parish': 1457,
    'Terrytown': 1457,
    'Thibodaux': 1457,
    'Timberlane': 1457,
    'Union Parish': 1457,
    'Urania': 1457,
    'Vermilion Parish': 1457,
    'Vernon Parish': 1457,
    'Vidalia': 1457,
    'Vienna Bend': 1457,
    'Village Saint George': 1457,
    'Ville Platte': 1457,
    'Vinton': 1457,
    'Violet': 1457,
    'Vivian': 1457,
    'Waggaman': 1457,
    'Walker': 1457,
    'Washington Parish': 1457,
    'Watson': 1457,
    'Webster Parish': 1457,
    'Welsh': 1457,
    'West Baton Rouge Parish': 1457,
    'West Carroll Parish': 1457,
    'West Feliciana Parish': 1457,
    'West Ferriday': 1457,
    'West Monroe': 1457,
    'Westlake': 1457,
    'Westminster': 1457,
    'Westwego': 1457,
    'White Castle': 1457,
    'Winn Parish': 1457,
    'Winnfield': 1457,
    'Winnsboro': 1457,
    'Woodmere': 1457,
    'Woodworth': 1457,
    'Youngsville': 1457,
    'Zachary': 1457,
    'Zwolle': 1457,
    'Acton': 1453,
    'Addison': 1453,
    'Albion': 1453,
    'Alfred': 1453,
    'Androscoggin County': 1453,
    'Appleton': 1453,
    'Aroostook County': 1453,
    'Arundel': 1453,
    'Auburn': 1453,
    'Augusta': 1453,
    'Bangor': 1453,
    'Bar Harbor': 1453,
    'Bath': 1453,
    'Belfast': 1453,
    'Belgrade': 1453,
    'Benton': 1453,
    'Berwick': 1453,
    'Bethel': 1453,
    'Biddeford': 1453,
    'Boothbay': 1453,
    'Boothbay Harbor': 1453,
    'Bradford': 1453,
    'Bradley': 1453,
    'Brewer': 1453,
    'Bridgton': 1453,
    'Bristol': 1453,
    'Brooks': 1453,
    'Brownfield': 1453,
    'Brownville': 1453,
    'Brunswick': 1453,
    'Buckfield': 1453,
    'Bucksport': 1453,
    'Burnham': 1453,
    'Buxton': 1453,
    'Calais': 1453,
    'Camden': 1453,
    'Canaan': 1453,
    'Canton': 1453,
    'Cape Neddick': 1453,
    'Caribou': 1453,
    'Carmel': 1453,
    'Castine': 1453,
    'Charleston': 1453,
    'Chelsea': 1453,
    'Cherryfield': 1453,
    'Chesterville': 1453,
    'China': 1453,
    'Chisholm': 1453,
    'Clinton': 1453,
    'Corinna': 1453,
    'Cornish': 1453,
    'Cornville': 1453,
    'Cumberland Center': 1453,
    'Cumberland County': 1453,
    'Cushing': 1453,
    'Damariscotta': 1453,
    'Dayton': 1453,
    'Dedham': 1453,
    'Deer Isle': 1453,
    'Denmark': 1453,
    'Dexter': 1453,
    'Dixfield': 1453,
    'Dixmont': 1453,
    'Dover-Foxcroft': 1453,
    'East Machias': 1453,
    'East Millinocket': 1453,
    'Easton': 1453,
    'Eastport': 1453,
    'Eddington': 1453,
    'Edgecomb': 1453,
    'Eliot': 1453,
    'Ellsworth': 1453,
    'Enfield': 1453,
    'Etna': 1453,
    'Fairfield': 1453,
    'Falmouth': 1453,
    'Falmouth Foreside': 1453,
    'Farmingdale': 1453,
    'Farmington': 1453,
    'Fayette': 1453,
    'Fort Fairfield': 1453,
    'Fort Kent': 1453,
    'Frankfort': 1453,
    'Franklin': 1453,
    'Franklin County': 1453,
    'Freeport': 1453,
    'Frenchville': 1453,
    'Friendship': 1453,
    'Fryeburg': 1453,
    'Gardiner': 1453,
    'Garland': 1453,
    'Gorham': 1453,
    'Gouldsboro': 1453,
    'Greenbush': 1453,
    'Greene': 1453,
    'Greenville': 1453,
    'Hallowell': 1453,
    'Hampden': 1453,
    'Hancock': 1453,
    'Hancock County': 1453,
    'Harpswell Center': 1453,
    'Harrison': 1453,
    'Hartford': 1453,
    'Hebron': 1453,
    'Hermon': 1453,
    'Hiram': 1453,
    'Hodgdon': 1453,
    'Holden': 1453,
    'Hollis Center': 1453,
    'Hope': 1453,
    'Houlton': 1453,
    'Howland': 1453,
    'Hudson': 1453,
    'Jay': 1453,
    'Jefferson': 1453,
    'Jonesport': 1453,
    'Kenduskeag': 1453,
    'Kennebec County': 1453,
    'Kennebunk': 1453,
    'Kennebunkport': 1453,
    'Kingfield': 1453,
    'Kittery': 1453,
    'Kittery Point': 1453,
    'Knox County': 1453,
    'Lake Arrowhead': 1453,
    'Lebanon': 1453,
    'Leeds': 1453,
    'Levant': 1453,
    'Lewiston': 1453,
    'Limerick': 1453,
    'Limestone': 1453,
    'Limington': 1453,
    'Lincoln': 1453,
    'Lincoln County': 1453,
    'Lincolnville': 1453,
    'Lisbon': 1453,
    'Lisbon Falls': 1453,
    'Livermore': 1453,
    'Livermore Falls': 1453,
    'Lovell': 1453,
    'Machias': 1453,
    'Machiasport': 1453,
    'Madawaska': 1453,
    'Madison': 1453,
    'Manchester': 1453,
    'Mechanic Falls': 1453,
    'Medway': 1453,
    'Mexico': 1453,
    'Milbridge': 1453,
    'Milford': 1453,
    'Millinocket': 1453,
    'Milo': 1453,
    'Minot': 1453,
    'Monmouth': 1453,
    'Mount Vernon': 1453,
    'New Gloucester': 1453,
    'New Sharon': 1453,
    'Newfield': 1453,
    'Newport': 1453,
    'Nobleboro': 1453,
    'Norridgewock': 1453,
    'North Bath': 1453,
    'North Berwick': 1453,
    'North Windham': 1453,
    'Northport': 1453,
    'Norway': 1453,
    'Oakland': 1453,
    'Ogunquit': 1453,
    'Old Orchard Beach': 1453,
    'Old Town': 1453,
    'Orland': 1453,
    'Orono': 1453,
    'Orrington': 1453,
    'Owls Head': 1453,
    'Oxford': 1453,
    'Oxford County': 1453,
    'Palermo': 1453,
    'Palmyra': 1453,
    'Paris': 1453,
    'Parsonsfield': 1453,
    'Patten': 1453,
    'Penobscot': 1453,
    'Penobscot County': 1453,
    'Peru': 1453,
    'Phillips': 1453,
    'Phippsburg': 1453,
    'Piscataquis County': 1453,
    'Pittsfield': 1453,
    'Pittston': 1453,
    'Plymouth': 1453,
    'Poland': 1453,
    'Porter': 1453,
    'Portland': 1453,
    'Presque Isle': 1453,
    'Randolph': 1453,
    'Raymond': 1453,
    'Readfield': 1453,
    'Richmond': 1453,
    'Rockland': 1453,
    'Rockport': 1453,
    'Rome': 1453,
    'Rumford': 1453,
    'Sabattus': 1453,
    'Saco': 1453,
    'Sagadahoc County': 1453,
    'Saint Albans': 1453,
    'Saint George': 1453,
    'Sanford': 1453,
    'Sangerville': 1453,
    'Scarborough': 1453,
    'Searsmont': 1453,
    'Sedgwick': 1453,
    'Shapleigh': 1453,
    'Sidney': 1453,
    'Skowhegan': 1453,
    'Somerset County': 1453,
    'South Berwick': 1453,
    'South Eliot': 1453,
    'South Paris': 1453,
    'South Portland': 1453,
    'South Portland Gardens': 1453,
    'South Sanford': 1453,
    'South Thomaston': 1453,
    'South Windham': 1453,
    'Springvale': 1453,
    'Steep Falls': 1453,
    'Stetson': 1453,
    'Steuben': 1453,
    'Stockton Springs': 1453,
    'Stonington': 1453,
    'Strong': 1453,
    'Sullivan': 1453,
    'Surry': 1453,
    'Swanville': 1453,
    'Thomaston': 1453,
    'Topsham': 1453,
    'Tremont': 1453,
    'Trenton': 1453,
    'Troy': 1453,
    'Turner': 1453,
    'Union': 1453,
    'Van Buren': 1453,
    'Vassalboro': 1453,
    'Veazie': 1453,
    'Vinalhaven': 1453,
    'Waldo County': 1453,
    'Waldoboro': 1453,
    'Warren': 1453,
    'Washington': 1453,
    'Washington County': 1453,
    'Waterboro': 1453,
    'Waterville': 1453,
    'Wayne': 1453,
    'Wells Beach Station': 1453,
    'West Kennebunk': 1453,
    'West Paris': 1453,
    'West Scarborough': 1453,
    'Westbrook': 1453,
    'Whitefield': 1453,
    'Wilton': 1453,
    'Windsor': 1453,
    'Winslow': 1453,
    'Winterport': 1453,
    'Winthrop': 1453,
    'Wiscasset': 1453,
    'Woodstock': 1453,
    'Woolwich': 1453,
    'Yarmouth': 1453,
    'York Beach': 1453,
    'York County': 1453,
    'York Harbor': 1453,
    'Aberdeen': 1401,
    'Aberdeen Proving Ground': 1401,
    'Accokeek': 1401,
    'Adamstown': 1401,
    'Adelphi': 1401,
    'Algonquin': 1401,
    'Allegany County': 1401,
    'Andrews AFB': 1401,
    'Annapolis': 1401,
    'Anne Arundel County': 1401,
    'Arbutus': 1401,
    'Arden on the Severn': 1401,
    'Arlington': 1401,
    'Arnold': 1401,
    'Ashton-Sandy Spring': 1401,
    'Aspen Hill': 1401,
    'Baden': 1401,
    'Ballenger Creek': 1401,
    'Baltimore': 1401,
    'Baltimore County': 1401,
    'Baltimore Highlands': 1401,
    'Bartonsville': 1401,
    'Bel Air': 1401,
    'Bel Air North': 1401,
    'Bel Air South': 1401,
    'Beltsville': 1401,
    'Bennsville': 1401,
    'Berlin': 1401,
    'Berwyn Heights': 1401,
    'Bethesda': 1401,
    'Bladensburg': 1401,
    'Boonsboro': 1401,
    'Bowie': 1401,
    'Bowleys Quarters': 1401,
    'Bowling Green': 1401,
    'Braddock Heights': 1401,
    'Brandywine': 1401,
    'Brentwood': 1401,
    'Brock Hall': 1401,
    'Brooklyn Park': 1401,
    'Brookmont': 1401,
    'Brunswick': 1401,
    'Bryans Road': 1401,
    'Buckeystown': 1401,
    'Burtonsville': 1401,
    'Butcher  s Hill': 1401,
    'Cabin John': 1401,
    'California': 1401,
    'Calvert County': 1401,
    'Calverton': 1401,
    'Cambridge': 1401,
    'Camp Springs': 1401,
    'Cape Saint Claire': 1401,
    'Capitol Heights': 1401,
    'Carney': 1401,
    'Caroline County': 1401,
    'Carroll County': 1401,
    'Catonsville': 1401,
    'Cavetown': 1401,
    'Cecil County': 1401,
    'Centreville': 1401,
    'Charles County': 1401,
    'Charles Village': 1401,
    'Charlestown': 1401,
    'Charlotte Hall': 1401,
    'Chesapeake Beach': 1401,
    'Chesapeake Ranch Estates': 1401,
    'Chester': 1401,
    'Chestertown': 1401,
    'Cheverly': 1401,
    'Chevy Chase': 1401,
    'Chevy Chase Village': 1401,
    'Chillum': 1401,
    'City of Baltimore': 1401,
    'Clarksburg': 1401,
    'Clinton': 1401,
    'Clover Hill': 1401,
    'Cloverly': 1401,
    'Cobb Island': 1401,
    'Cockeysville': 1401,
    'Colesville': 1401,
    'College Park': 1401,
    'Colmar Manor': 1401,
    'Columbia': 1401,
    'Coral Hills': 1401,
    'Cottage City': 1401,
    'Cresaptown': 1401,
    'Crisfield': 1401,
    'Crofton': 1401,
    'Croom': 1401,
    'Crownsville': 1401,
    'Cumberland': 1401,
    'Damascus': 1401,
    'Darnestown': 1401,
    'Davidsonville': 1401,
    'Deale': 1401,
    'Denton': 1401,
    'Derwood': 1401,
    'District Heights': 1401,
    'Dorchester County': 1401,
    'Drum Point': 1401,
    'Dundalk': 1401,
    'Dunkirk': 1401,
    'Dunkirk Town Center': 1401,
    'East Riverdale': 1401,
    'Easton': 1401,
    'Edgemere': 1401,
    'Edgewater': 1401,
    'Edgewood': 1401,
    'Edmonston': 1401,
    'Eldersburg': 1401,
    'Elkridge': 1401,
    'Elkton': 1401,
    'Ellicott City': 1401,
    'Emmitsburg': 1401,
    'Essex': 1401,
    'Fairland': 1401,
    'Fairmount Heights': 1401,
    'Fairwood': 1401,
    'Fallston': 1401,
    'Federalsburg': 1401,
    'Ferndale': 1401,
    'Forest Glen': 1401,
    'Forest Heights': 1401,
    'Forestville': 1401,
    'Fort George G Mead Junction': 1401,
    'Fort Meade': 1401,
    'Fort Washington': 1401,
    'Fountainhead-Orchard Hills': 1401,
    'Four Corners': 1401,
    'Frederick': 1401,
    'Frederick County': 1401,
    'Friendly': 1401,
    'Friendship Village': 1401,
    'Frostburg': 1401,
    'Fruitland': 1401,
    'Fulton': 1401,
    'Gaithersburg': 1401,
    'Gambrills': 1401,
    'Garrett County': 1401,
    'Garrett Park': 1401,
    'Garrison': 1401,
    'Germantown': 1401,
    'Glassmanor': 1401,
    'Glen Burnie': 1401,
    'Glenarden': 1401,
    'Glenmont': 1401,
    'Glenn Dale': 1401,
    'Goddard': 1401,
    'Golden Beach': 1401,
    'Grasonville': 1401,
    'Greater Upper Marlboro': 1401,
    'Green Haven': 1401,
    'Green Valley': 1401,
    'Greenbelt': 1401,
    'Greensboro': 1401,
    'Hagerstown': 1401,
    'Halfway': 1401,
    'Hampstead': 1401,
    'Hampton': 1401,
    'Hancock': 1401,
    'Hanover': 1401,
    'Harford County': 1401,
    'Havre de Grace': 1401,
    'Hebron': 1401,
    'Herald Harbor': 1401,
    'Highfield-Cascade': 1401,
    'Highland': 1401,
    'Hillandale': 1401,
    'Hillcrest Heights': 1401,
    'Hillsmere Shores': 1401,
    'Howard County': 1401,
    'Hughesville': 1401,
    'Hunt Valley': 1401,
    'Huntingtown': 1401,
    'Huntingtown Town Center': 1401,
    'Hurlock': 1401,
    'Hyattsville': 1401,
    'Ilchester': 1401,
    'Indian Head': 1401,
    'Irvington': 1401,
    'Jarrettsville': 1401,
    'Jefferson': 1401,
    'Jessup': 1401,
    'Joppatowne': 1401,
    'Keedysville': 1401,
    'Kemp Mill': 1401,
    'Kensington': 1401,
    'Kent County': 1401,
    'Kettering': 1401,
    'Kingstown': 1401,
    'Kingsville': 1401,
    'La Plata': 1401,
    'La Vale': 1401,
    'Lake Arbor': 1401,
    'Lake Shore': 1401,
    'Landover': 1401,
    'Landover Hills': 1401,
    'Langley Park': 1401,
    'Lanham': 1401,
    'Lanham-Seabrook': 1401,
    'Lansdowne': 1401,
    'Largo': 1401,
    'Laurel': 1401,
    'Layhill': 1401,
    'Leisure World': 1401,
    'Leonardtown': 1401,
    'Lexington Park': 1401,
    'Linganore': 1401,
    'Linthicum': 1401,
    'Lochearn': 1401,
    'Lonaconing': 1401,
    'Londontowne': 1401,
    'Long Beach': 1401,
    'Lusby': 1401,
    'Lutherville': 1401,
    'Lutherville-Timonium': 1401,
    'Manchester': 1401,
    'Marlboro Meadows': 1401,
    'Marlboro Village': 1401,
    'Marlow Heights': 1401,
    'Marlton': 1401,
    'Maryland City': 1401,
    'Maugansville': 1401,
    'Mayo': 1401,
    'Mays Chapel': 1401,
    'Mechanicsville': 1401,
    'Mellwood': 1401,
    'Middle River': 1401,
    'Middletown': 1401,
    'Milford Mill': 1401,
    'Mitchellville': 1401,
    'Montgomery County': 1401,
    'Montgomery Village': 1401,
    'Morningside': 1401,
    'Mount Airy': 1401,
    'Mount Rainier': 1401,
    'Mountain Lake Park': 1401,
    'Myersville': 1401,
    'National Harbor': 1401,
    'Naval Academy': 1401,
    'New Carrollton': 1401,
    'New Windsor': 1401,
    'North Beach': 1401,
    'North Bel Air': 1401,
    'North Bethesda': 1401,
    'North East': 1401,
    'North Kensington': 1401,
    'North Laurel': 1401,
    'North Potomac': 1401,
    'Oakland': 1401,
    'Ocean City': 1401,
    'Ocean Pines': 1401,
    'Odenton': 1401,
    'Olney': 1401,
    'Overlea': 1401,
    'Owings': 1401,
    'Owings Mills': 1401,
    'Oxon Hill': 1401,
    'Oxon Hill-Glassmanor': 1401,
    'Paramount-Long Meadow': 1401,
    'Parkville': 1401,
    'Parole': 1401,
    'Pasadena': 1401,
    'Peppermill Village': 1401,
    'Perry Hall': 1401,
    'Perryman': 1401,
    'Perryville': 1401,
    'Pikesville': 1401,
    'Pittsville': 1401,
    'Pleasant Hills': 1401,
    'Pocomoke City': 1401,
    'Point of Rocks': 1401,
    'Poolesville': 1401,
    'Potomac': 1401,
    'Potomac Heights': 1401,
    'Potomac Park': 1401,
    'Prince Frederick': 1401,
    'Prince George  s County': 1401,
    'Princess Anne': 1401,
    'Pumphrey': 1401,
    'Queen Anne': 1401,
    'Queen Anne  s County': 1401,
    'Queenland': 1401,
    'Randallstown': 1401,
    'Redland': 1401,
    'Reisterstown': 1401,
    'Ridgely': 1401,
    'Rising Sun': 1401,
    'Riva': 1401,
    'Riverdale Park': 1401,
    'Riverside': 1401,
    'Riviera Beach': 1401,
    'Robinwood': 1401,
    'Rock Hall': 1401,
    'Rockville': 1401,
    'Rosaryville': 1401,
    'Rosedale': 1401,
    'Rossmoor': 1401,
    'Rossville': 1401,
    'Saint Charles': 1401,
    'Saint James': 1401,
    'Saint Mary  s County': 1401,
    'Saint Michaels': 1401,
    'Salisbury': 1401,
    'Savage': 1401,
    'Scaggsville': 1401,
    'Seabrook': 1401,
    'Seat Pleasant': 1401,
    'Selby-on-the-Bay': 1401,
    'Severn': 1401,
    'Severna Park': 1401,
    'Shady Side': 1401,
    'Silver Hill': 1401,
    'Silver Spring': 1401,
    'Smithsburg': 1401,
    'Snow Hill': 1401,
    'Solomons': 1401,
    'Somerset': 1401,
    'Somerset County': 1401,
    'South Bel Air': 1401,
    'South Gate': 1401,
    'South Kensington': 1401,
    'South Laurel': 1401,
    'Spencerville': 1401,
    'Spring Ridge': 1401,
    'Springdale': 1401,
    'Stevensville': 1401,
    'Suitland': 1401,
    'Suitland-Silver Hill': 1401,
    'Summerfield': 1401,
    'Sykesville': 1401,
    'Takoma Park': 1401,
    'Talbot County': 1401,
    'Taneytown': 1401,
    'Temple Hills': 1401,
    'Thurmont': 1401,
    'Timonium': 1401,
    'Towson': 1401,
    'Trappe': 1401,
    'Travilah': 1401,
    'University Park': 1401,
    'Upper Marlboro': 1401,
    'Urbana': 1401,
    'Waldorf': 1401,
    'Walker Mill': 1401,
    'Walkersville': 1401,
    'Washington County': 1401,
    'West Elkridge': 1401,
    'West Laurel': 1401,
    'West Ocean City': 1401,
    'Westernport': 1401,
    'Westminster': 1401,
    'Westphalia': 1401,
    'Wheaton': 1401,
    'White Marsh': 1401,
    'White Oak': 1401,
    'Wicomico County': 1401,
    'Williamsport': 1401,
    'Wilson-Conococheague': 1401,
    'Woodlawn': 1401,
    'Woodmore': 1401,
    'Woodsboro': 1401,
    'Worcester County': 1401,
    'Abington': 1433,
    'Acton': 1433,
    'Acushnet': 1433,
    'Acushnet Center': 1433,
    'Adams': 1433,
    'Agawam': 1433,
    'Amesbury': 1433,
    'Amherst': 1433,
    'Amherst Center': 1433,
    'Andover': 1433,
    'Arlington': 1433,
    'Ashburnham': 1433,
    'Ashby': 1433,
    'Ashfield': 1433,
    'Ashland': 1433,
    'Assonet': 1433,
    'Athol': 1433,
    'Attleboro': 1433,
    'Auburn': 1433,
    'Avon': 1433,
    'Ayer': 1433,
    'Baldwinville': 1433,
    'Barnstable': 1433,
    'Barnstable County': 1433,
    'Barre': 1433,
    'Becket': 1433,
    'Bedford': 1433,
    'Belchertown': 1433,
    'Bellingham': 1433,
    'Belmont': 1433,
    'Berkley': 1433,
    'Berkshire County': 1433,
    'Berlin': 1433,
    'Bernardston': 1433,
    'Beverly': 1433,
    'Beverly Cove': 1433,
    'Billerica': 1433,
    'Blackstone': 1433,
    'Bliss Corner': 1433,
    'Bolton': 1433,
    'Bondsville': 1433,
    'Boston': 1433,
    'Bourne': 1433,
    'Boxborough': 1433,
    'Boxford': 1433,
    'Boylston': 1433,
    'Braintree': 1433,
    'Brewster': 1433,
    'Bridgewater': 1433,
    'Brimfield': 1433,
    'Bristol County': 1433,
    'Brockton': 1433,
    'Brookline': 1433,
    'Buckland': 1433,
    'Burlington': 1433,
    'Buzzards Bay': 1433,
    'Cambridge': 1433,
    'Canton': 1433,
    'Carlisle': 1433,
    'Carver': 1433,
    'Centerville': 1433,
    'Charlemont': 1433,
    'Charlton': 1433,
    'Chatham': 1433,
    'Chelmsford': 1433,
    'Chelsea': 1433,
    'Chesterfield': 1433,
    'Chicopee': 1433,
    'Clinton': 1433,
    'Cochituate': 1433,
    'Cohasset': 1433,
    'Colrain': 1433,
    'Concord': 1433,
    'Conway': 1433,
    'Cordaville': 1433,
    'Cotuit': 1433,
    'Dalton': 1433,
    'Danvers': 1433,
    'Dedham': 1433,
    'Dennis': 1433,
    'Dennis Port': 1433,
    'Devens': 1433,
    'Dighton': 1433,
    'Douglas': 1433,
    'Dover': 1433,
    'Dracut': 1433,
    'Dudley': 1433,
    'Dukes County': 1433,
    'Dunstable': 1433,
    'Duxbury': 1433,
    'East Bridgewater': 1433,
    'East Brookfield': 1433,
    'East Dennis': 1433,
    'East Douglas': 1433,
    'East Falmouth': 1433,
    'East Harwich': 1433,
    'East Longmeadow': 1433,
    'East Pepperell': 1433,
    'East Sandwich': 1433,
    'Eastham': 1433,
    'Easthampton': 1433,
    'Easton': 1433,
    'Edgartown': 1433,
    'Erving': 1433,
    'Essex': 1433,
    'Essex County': 1433,
    'Everett': 1433,
    'Fairhaven': 1433,
    'Fall River': 1433,
    'Falmouth': 1433,
    'Fiskdale': 1433,
    'Fitchburg': 1433,
    'Forestdale': 1433,
    'Foxborough': 1433,
    'Framingham': 1433,
    'Framingham Center': 1433,
    'Franklin': 1433,
    'Franklin County': 1433,
    'Freetown': 1433,
    'Gardner': 1433,
    'Gill': 1433,
    'Gloucester': 1433,
    'Grafton': 1433,
    'Granby': 1433,
    'Granville': 1433,
    'Great Barrington': 1433,
    'Green Harbor-Cedar Crest': 1433,
    'Greenfield': 1433,
    'Groton': 1433,
    'Groveland': 1433,
    'Hadley': 1433,
    'Halifax': 1433,
    'Hamilton Worcester': 1433,
    'Hampden': 1433,
    'Hampden County': 1433,
    'Hampshire County': 1433,
    'Hanover': 1433,
    'Hanson': 1433,
    'Hardwick': 1433,
    'Harvard': 1433,
    'Harwich': 1433,
    'Harwich Center': 1433,
    'Harwich Port': 1433,
    'Hatfield': 1433,
    'Haverhill': 1433,
    'Head of Westport': 1433,
    'Hingham': 1433,
    'Hinsdale': 1433,
    'Holbrook': 1433,
    'Holden': 1433,
    'Holland': 1433,
    'Holliston': 1433,
    'Holyoke': 1433,
    'Hopedale': 1433,
    'Hopkinton': 1433,
    'Housatonic': 1433,
    'Hubbardston': 1433,
    'Hudson': 1433,
    'Hull': 1433,
    'Hyannis': 1433,
    'Ipswich': 1433,
    'Jamaica Plain': 1433,
    'Kingston': 1433,
    'Lancaster': 1433,
    'Lanesborough': 1433,
    'Lawrence': 1433,
    'Lee': 1433,
    'Leicester': 1433,
    'Lenox': 1433,
    'Leominster': 1433,
    'Leverett': 1433,
    'Lexington': 1433,
    'Lincoln': 1433,
    'Littleton Common': 1433,
    'Longmeadow': 1433,
    'Lowell': 1433,
    'Ludlow': 1433,
    'Lunenburg': 1433,
    'Lynn': 1433,
    'Lynnfield': 1433,
    'Malden': 1433,
    'Manchester-by-the-Sea': 1433,
    'Mansfield': 1433,
    'Mansfield Center': 1433,
    'Marblehead': 1433,
    'Marion': 1433,
    'Marion Center': 1433,
    'Marlborough': 1433,
    'Marshfield': 1433,
    'Marshfield Hills': 1433,
    'Marstons Mills': 1433,
    'Mashpee': 1433,
    'Mattapoisett': 1433,
    'Mattapoisett Center': 1433,
    'Maynard': 1433,
    'Medfield': 1433,
    'Medford': 1433,
    'Medway': 1433,
    'Melrose': 1433,
    'Mendon': 1433,
    'Merrimac': 1433,
    'Methuen': 1433,
    'Middleborough': 1433,
    'Middleborough Center': 1433,
    'Middlesex County': 1433,
    'Middleton': 1433,
    'Milford': 1433,
    'Millbury': 1433,
    'Millers Falls': 1433,
    'Millis': 1433,
    'Millis-Clicquot': 1433,
    'Millville': 1433,
    'Milton': 1433,
    'Monson': 1433,
    'Monson Center': 1433,
    'Montague': 1433,
    'Monument Beach': 1433,
    'Nahant': 1433,
    'Nantucket': 1433,
    'Nantucket County': 1433,
    'Natick': 1433,
    'Needham': 1433,
    'New Bedford': 1433,
    'New Marlborough': 1433,
    'Newburyport': 1433,
    'Newton': 1433,
    'Norfolk': 1433,
    'Norfolk County': 1433,
    'North Adams': 1433,
    'North Amherst': 1433,
    'North Andover': 1433,
    'North Attleborough Center': 1433,
    'North Brookfield': 1433,
    'North Chicopee': 1433,
    'North Eastham': 1433,
    'North Falmouth': 1433,
    'North Lakeville': 1433,
    'North Pembroke': 1433,
    'North Plymouth': 1433,
    'North Reading': 1433,
    'North Scituate': 1433,
    'North Seekonk': 1433,
    'North Westport': 1433,
    'Northampton': 1433,
    'Northborough': 1433,
    'Northbridge': 1433,
    'Northfield': 1433,
    'Northwest Harwich': 1433,
    'Norton': 1433,
    'Norton Center': 1433,
    'Norwell': 1433,
    'Norwood': 1433,
    'Oak Bluffs': 1433,
    'Oakham': 1433,
    'Ocean Bluff-Brant Rock': 1433,
    'Ocean Grove': 1433,
    'Onset': 1433,
    'Orange': 1433,
    'Orleans': 1433,
    'Osterville': 1433,
    'Otis': 1433,
    'Oxford': 1433,
    'Palmer': 1433,
    'Paxton': 1433,
    'Peabody': 1433,
    'Pelham': 1433,
    'Pepperell': 1433,
    'Phillipston': 1433,
    'Pinehurst': 1433,
    'Pittsfield': 1433,
    'Plainville': 1433,
    'Plymouth': 1433,
    'Plymouth County': 1433,
    'Plympton': 1433,
    'Pocasset': 1433,
    'Princeton': 1433,
    'Provincetown': 1433,
    'Quincy': 1433,
    'Randolph': 1433,
    'Raynham': 1433,
    'Raynham Center': 1433,
    'Reading': 1433,
    'Rehoboth': 1433,
    'Revere': 1433,
    'Richmond': 1433,
    'Rochester': 1433,
    'Rockland': 1433,
    'Rockport': 1433,
    'Rowley': 1433,
    'Royalston': 1433,
    'Rutland': 1433,
    'Sagamore': 1433,
    'Salem': 1433,
    'Salisbury': 1433,
    'Sandwich': 1433,
    'Saugus': 1433,
    'Scituate': 1433,
    'Seekonk': 1433,
    'Sharon': 1433,
    'Sheffield': 1433,
    'Shelburne': 1433,
    'Shelburne Falls': 1433,
    'Sherborn': 1433,
    'Shirley': 1433,
    'Shrewsbury': 1433,
    'Shutesbury': 1433,
    'Smith Mills': 1433,
    'Somerset': 1433,
    'Somerville': 1433,
    'South Amherst': 1433,
    'South Ashburnham': 1433,
    'South Boston': 1433,
    'South Deerfield': 1433,
    'South Dennis': 1433,
    'South Duxbury': 1433,
    'South Hadley': 1433,
    'South Lancaster': 1433,
    'South Peabody': 1433,
    'South Yarmouth': 1433,
    'Southampton': 1433,
    'Southborough': 1433,
    'Southbridge': 1433,
    'Southwick': 1433,
    'Spencer': 1433,
    'Springfield': 1433,
    'Sterling': 1433,
    'Stockbridge': 1433,
    'Stoneham': 1433,
    'Stoughton': 1433,
    'Stow': 1433,
    'Sturbridge': 1433,
    'Sudbury': 1433,
    'Suffolk County': 1433,
    'Sunderland': 1433,
    'Sutton': 1433,
    'Swampscott': 1433,
    'Swansea': 1433,
    'Taunton': 1433,
    'Teaticket': 1433,
    'Templeton': 1433,
    'Tewksbury': 1433,
    'Three Rivers': 1433,
    'Topsfield': 1433,
    'Townsend': 1433,
    'Truro': 1433,
    'Turners Falls': 1433,
    'Tyngsboro': 1433,
    'Upton': 1433,
    'Uxbridge': 1433,
    'Vineyard Haven': 1433,
    'Wakefield': 1433,
    'Wales': 1433,
    'Walpole': 1433,
    'Waltham': 1433,
    'Ware': 1433,
    'Wareham Center': 1433,
    'Warren': 1433,
    'Watertown': 1433,
    'Wayland': 1433,
    'Webster': 1433,
    'Wellesley': 1433,
    'Wellfleet': 1433,
    'Wendell': 1433,
    'Wenham': 1433,
    'West Barnstable': 1433,
    'West Boylston': 1433,
    'West Bridgewater': 1433,
    'West Brookfield': 1433,
    'West Chatham': 1433,
    'West Concord': 1433,
    'West Dennis': 1433,
    'West Falmouth': 1433,
    'West Newbury': 1433,
    'West Springfield': 1433,
    'West Stockbridge': 1433,
    'West Tisbury': 1433,
    'West Wareham': 1433,
    'West Yarmouth': 1433,
    'Westborough': 1433,
    'Westfield': 1433,
    'Westford': 1433,
    'Westhampton': 1433,
    'Westminster': 1433,
    'Weston': 1433,
    'Westwood': 1433,
    'Weweantic': 1433,
    'Weymouth': 1433,
    'Whately': 1433,
    'White Island Shores': 1433,
    'Whitinsville': 1433,
    'Whitman': 1433,
    'Wilbraham': 1433,
    'Williamsburg': 1433,
    'Williamstown': 1433,
    'Wilmington': 1433,
    'Winchendon': 1433,
    'Winchester': 1433,
    'Winthrop': 1433,
    'Woburn': 1433,
    'Worcester': 1433,
    'Worcester County': 1433,
    'Wrentham': 1433,
    'Yarmouth': 1433,
    'Yarmouth Port': 1433,
    'Adrian': 1426,
    'Albion': 1426,
    'Alcona County': 1426,
    'Alger County': 1426,
    'Algonac': 1426,
    'Allegan': 1426,
    'Allegan County': 1426,
    'Allen Park': 1426,
    'Allendale': 1426,
    'Alma': 1426,
    'Almont': 1426,
    'Alpena': 1426,
    'Alpena County': 1426,
    'Ann Arbor': 1426,
    'Antrim County': 1426,
    'Arenac County': 1426,
    'Argentine': 1426,
    'Armada': 1426,
    'Athens': 1426,
    'Atlanta': 1426,
    'Au Sable': 1426,
    'Auburn': 1426,
    'Auburn Hills': 1426,
    'Bad Axe': 1426,
    'Baldwin': 1426,
    'Bangor': 1426,
    'Baraga': 1426,
    'Baraga County': 1426,
    'Barnes Lake-Millers Lake': 1426,
    'Barry County': 1426,
    'Bath': 1426,
    'Battle Creek': 1426,
    'Bay City': 1426,
    'Bay County': 1426,
    'Bay Harbor': 1426,
    'Beaverton': 1426,
    'Beecher': 1426,
    'Beechwood': 1426,
    'Belding': 1426,
    'Bellaire': 1426,
    'Belleville': 1426,
    'Bellevue': 1426,
    'Benton Harbor': 1426,
    'Benton Heights': 1426,
    'Benzie County': 1426,
    'Berkley': 1426,
    'Berrien County': 1426,
    'Berrien Springs': 1426,
    'Bessemer': 1426,
    'Beulah': 1426,
    'Beverly Hills': 1426,
    'Big Rapids': 1426,
    'Bingham Farms': 1426,
    'Birch Run': 1426,
    'Birmingham': 1426,
    'Blissfield': 1426,
    'Bloomfield Hills': 1426,
    'Boyne City': 1426,
    'Branch County': 1426,
    'Breckenridge': 1426,
    'Bridgeport': 1426,
    'Bridgman': 1426,
    'Brighton': 1426,
    'Bronson': 1426,
    'Brooklyn': 1426,
    'Brown City': 1426,
    'Brownlee Park': 1426,
    'Buchanan': 1426,
    'Buena Vista': 1426,
    'Burt': 1426,
    'Burton': 1426,
    'Byron Center': 1426,
    'Cadillac': 1426,
    'Caledonia': 1426,
    'Calhoun County': 1426,
    'Canadian Lakes': 1426,
    'Canton': 1426,
    'Capac': 1426,
    'Carleton': 1426,
    'Caro': 1426,
    'Carrollton': 1426,
    'Carson City': 1426,
    'Cass City': 1426,
    'Cass County': 1426,
    'Cassopolis': 1426,
    'Cedar Springs': 1426,
    'Center Line': 1426,
    'Centreville': 1426,
    'Charlevoix': 1426,
    'Charlevoix County': 1426,
    'Charlotte': 1426,
    'Cheboygan': 1426,
    'Cheboygan County': 1426,
    'Chelsea': 1426,
    'Chesaning': 1426,
    'Chippewa County': 1426,
    'Clare': 1426,
    'Clare County': 1426,
    'Clarkston': 1426,
    'Clawson': 1426,
    'Clinton': 1426,
    'Clinton County': 1426,
    'Clinton Township': 1426,
    'Clio': 1426,
    'Coldwater': 1426,
    'Coleman': 1426,
    'Coloma': 1426,
    'Colon': 1426,
    'Comstock Northwest': 1426,
    'Comstock Park': 1426,
    'Concord': 1426,
    'Constantine': 1426,
    'Coopersville': 1426,
    'Corunna': 1426,
    'Crawford County': 1426,
    'Croswell': 1426,
    'Crystal Falls': 1426,
    'Cutlerville': 1426,
    'Davison': 1426,
    'Dearborn': 1426,
    'Dearborn Heights': 1426,
    'Decatur': 1426,
    'Delta County': 1426,
    'Detroit': 1426,
    'Detroit Beach': 1426,
    'DeWitt': 1426,
    'Dexter': 1426,
    'Dickinson County': 1426,
    'Dimondale': 1426,
    'Dollar Bay': 1426,
    'Douglas': 1426,
    'Dowagiac': 1426,
    'Dundee': 1426,
    'Durand': 1426,
    'Eagle River': 1426,
    'East Grand Rapids': 1426,
    'East Jordan': 1426,
    'East Lansing': 1426,
    'East Tawas': 1426,
    'Eastpointe': 1426,
    'Eastwood': 1426,
    'Eaton County': 1426,
    'Eaton Rapids': 1426,
    'Ecorse': 1426,
    'Edgemont Park': 1426,
    'Edmore': 1426,
    'Edwardsburg': 1426,
    'Elk Rapids': 1426,
    'Emmet County': 1426,
    'Escanaba': 1426,
    'Essexville': 1426,
    'Evart': 1426,
    'Fair Plain': 1426,
    'Farmington': 1426,
    'Farmington Hills': 1426,
    'Fennville': 1426,
    'Fenton': 1426,
    'Ferndale': 1426,
    'Ferrysburg': 1426,
    'Flat Rock': 1426,
    'Flint': 1426,
    'Flushing': 1426,
    'Forest Hills': 1426,
    'Fowler': 1426,
    'Fowlerville': 1426,
    'Frankenmuth': 1426,
    'Frankfort': 1426,
    'Franklin': 1426,
    'Fraser': 1426,
    'Freeland': 1426,
    'Fremont': 1426,
    'Fruitport': 1426,
    'Galesburg': 1426,
    'Garden City': 1426,
    'Gaylord': 1426,
    'Genesee County': 1426,
    'Gibraltar': 1426,
    'Gladstone': 1426,
    'Gladwin': 1426,
    'Gladwin County': 1426,
    'Gogebic County': 1426,
    'Goodrich': 1426,
    'Grand Blanc': 1426,
    'Grand Haven': 1426,
    'Grand Ledge': 1426,
    'Grand Rapids': 1426,
    'Grand Traverse County': 1426,
    'Grandville': 1426,
    'Grass Lake': 1426,
    'Gratiot County': 1426,
    'Grayling': 1426,
    'Greenville': 1426,
    'Greilickville': 1426,
    'Grosse Ile': 1426,
    'Grosse Pointe': 1426,
    'Grosse Pointe Farms': 1426,
    'Grosse Pointe Park': 1426,
    'Grosse Pointe Shores': 1426,
    'Grosse Pointe Woods': 1426,
    'Gwinn': 1426,
    'Hamtramck': 1426,
    'Hancock': 1426,
    'Harbor Beach': 1426,
    'Harbor Springs': 1426,
    'Harper Woods': 1426,
    'Harrison': 1426,
    'Harrisville': 1426,
    'Hart': 1426,
    'Hartford': 1426,
    'Harvey': 1426,
    'Haslett': 1426,
    'Hastings': 1426,
    'Hazel Park': 1426,
    'Hemlock': 1426,
    'Highland Park': 1426,
    'Hillsdale': 1426,
    'Hillsdale County': 1426,
    'Holland': 1426,
    'Holly': 1426,
    'Holt': 1426,
    'Homer': 1426,
    'Houghton': 1426,
    'Houghton County': 1426,
    'Houghton Lake': 1426,
    'Howard City': 1426,
    'Howell': 1426,
    'Hubbard Lake': 1426,
    'Hudson': 1426,
    'Hudsonville': 1426,
    'Huntington Woods': 1426,
    'Huron County': 1426,
    'Imlay City': 1426,
    'Indian River': 1426,
    'Ingham County': 1426,
    'Inkster': 1426,
    'Ionia': 1426,
    'Ionia County': 1426,
    'Iosco County': 1426,
    'Iron County': 1426,
    'Iron Mountain': 1426,
    'Iron River': 1426,
    'Ironwood': 1426,
    'Isabella County': 1426,
    'Ishpeming': 1426,
    'Ithaca': 1426,
    'Jackson': 1426,
    'Jackson County': 1426,
    'Jenison': 1426,
    'Jonesville': 1426,
    'K. I. Sawyer Air Force Base': 1426,
    'Kalamazoo': 1426,
    'Kalamazoo County': 1426,
    'Kalkaska': 1426,
    'Kalkaska County': 1426,
    'Keego Harbor': 1426,
    'Kent City': 1426,
    'Kent County': 1426,
    'Kentwood': 1426,
    'Keweenaw County': 1426,
    'Kilmanagh': 1426,
    'Kingsford': 1426,
    'Kingsley': 1426,
    'L  Anse': 1426,
    'Laingsburg': 1426,
    'Lake City': 1426,
    'Lake County': 1426,
    'Lake Fenton': 1426,
    'Lake Isabella': 1426,
    'Lake Michigan Beach': 1426,
    'Lake Odessa': 1426,
    'Lake Orion': 1426,
    'Lakeview': 1426,
    'Lakewood Club': 1426,
    'Lambertville': 1426,
    'Lansing': 1426,
    'Lapeer': 1426,
    'Lapeer County': 1426,
    'Lathrup Village': 1426,
    'Laurium': 1426,
    'Lawton': 1426,
    'Leelanau County': 1426,
    'Leland': 1426,
    'Lenawee County': 1426,
    'Leslie': 1426,
    'Level Park-Oak Park': 1426,
    'Lewiston': 1426,
    'Lexington': 1426,
    'Lincoln Park': 1426,
    'Linden': 1426,
    'Litchfield': 1426,
    'Livingston County': 1426,
    'Livonia': 1426,
    'Lowell': 1426,
    'Luce County': 1426,
    'Ludington': 1426,
    'Luna Pier': 1426,
    'Mackinac County': 1426,
    'Macomb County': 1426,
    'Madison Heights': 1426,
    'Mancelona': 1426,
    'Manchester': 1426,
    'Manistee': 1426,
    'Manistee County': 1426,
    'Manistique': 1426,
    'Manitou Beach-Devils Lake': 1426,
    'Manton': 1426,
    'Marcellus': 1426,
    'Marine City': 1426,
    'Marlette': 1426,
    'Marquette': 1426,
    'Marquette County': 1426,
    'Marshall': 1426,
    'Marysville': 1426,
    'Mason': 1426,
    'Mason County': 1426,
    'Mattawan': 1426,
    'Mecosta County': 1426,
    'Melvindale': 1426,
    'Memphis': 1426,
    'Menominee': 1426,
    'Menominee County': 1426,
    'Michigan Center': 1426,
    'Middleville': 1426,
    'Midland': 1426,
    'Midland County': 1426,
    'Milan': 1426,
    'Milford': 1426,
    'Millington': 1426,
    'Mio': 1426,
    'Missaukee County': 1426,
    'Monroe': 1426,
    'Monroe County': 1426,
    'Montague': 1426,
    'Montcalm County': 1426,
    'Montmorency County': 1426,
    'Montrose': 1426,
    'Morenci': 1426,
    'Mount Clemens': 1426,
    'Mount Morris': 1426,
    'Mount Pleasant': 1426,
    'Munising': 1426,
    'Muskegon': 1426,
    'Muskegon County': 1426,
    'Muskegon Heights': 1426,
    'Napoleon': 1426,
    'Nashville': 1426,
    'Negaunee': 1426,
    'New Baltimore': 1426,
    'New Buffalo': 1426,
    'New Haven': 1426,
    'Newaygo': 1426,
    'Newaygo County': 1426,
    'Newberry': 1426,
    'Niles': 1426,
    'North Branch': 1426,
    'North Muskegon': 1426,
    'Northview': 1426,
    'Northville': 1426,
    'Norton Shores': 1426,
    'Norway': 1426,
    'Novi': 1426,
    'Oak Park': 1426,
    'Oakland County': 1426,
    'Oceana County': 1426,
    'Ogemaw County': 1426,
    'Okemos': 1426,
    'Olivet': 1426,
    'Ontonagon': 1426,
    'Ontonagon County': 1426,
    'Orchard Lake': 1426,
    'Ortonville': 1426,
    'Osceola County': 1426,
    'Oscoda County': 1426,
    'Otsego': 1426,
    'Otsego County': 1426,
    'Ottawa County': 1426,
    'Ovid': 1426,
    'Owosso': 1426,
    'Oxford': 1426,
    'Parchment': 1426,
    'Paw Paw': 1426,
    'Paw Paw Lake': 1426,
    'Pearl Beach': 1426,
    'Perry': 1426,
    'Petersburg': 1426,
    'Petoskey': 1426,
    'Pigeon': 1426,
    'Pinckney': 1426,
    'Pinconning': 1426,
    'Plainwell': 1426,
    'Pleasant Ridge': 1426,
    'Plymouth': 1426,
    'Pontiac': 1426,
    'Port Huron': 1426,
    'Portage': 1426,
    'Portland': 1426,
    'Potterville': 1426,
    'Presque Isle County': 1426,
    'Prudenville': 1426,
    'Quincy': 1426,
    'Quinnesec': 1426,
    'Rapid City': 1426,
    'Ravenna': 1426,
    'Reading': 1426,
    'Redford': 1426,
    'Reed City': 1426,
    'Reese': 1426,
    'Richmond': 1426,
    'River Rouge': 1426,
    'Riverview': 1426,
    'Rochester': 1426,
    'Rochester Hills': 1426,
    'Rockford': 1426,
    'Rockwood': 1426,
    'Rogers City': 1426,
    'Romeo': 1426,
    'Romulus': 1426,
    'Roosevelt Park': 1426,
    'Roscommon': 1426,
    'Roscommon County': 1426,
    'Roseville': 1426,
    'Royal Oak': 1426,
    'Saginaw': 1426,
    'Saginaw County': 1426,
    'Saginaw Township North': 1426,
    'Saint Charles': 1426,
    'Saint Clair': 1426,
    'Saint Clair County': 1426,
    'Saint Clair Shores': 1426,
    'Saint Helen': 1426,
    'Saint Ignace': 1426,
    'Saint Johns': 1426,
    'Saint Joseph': 1426,
    'Saint Joseph County': 1426,
    'Saint Louis': 1426,
    'Saline': 1426,
    'Sand Lake': 1426,
    'Sandusky': 1426,
    'Sanilac County': 1426,
    'Saranac': 1426,
    'Sault Ste. Marie': 1426,
    'Schoolcraft': 1426,
    'Schoolcraft County': 1426,
    'Scottville': 1426,
    'Sebewaing': 1426,
    'Shelby': 1426,
    'Shepherd': 1426,
    'Shiawassee County': 1426,
    'Shields': 1426,
    'Shorewood-Tower Hills-Harbert': 1426,
    'Skidway Lake': 1426,
    'South Gull Lake': 1426,
    'South Haven': 1426,
    'South Lyon': 1426,
    'South Monroe': 1426,
    'South Rockwood': 1426,
    'Southfield': 1426,
    'Southgate': 1426,
    'Sparta': 1426,
    'Spring Arbor': 1426,
    'Spring Lake': 1426,
    'Springfield': 1426,
    'Stambaugh, Iron River': 1426,
    'Standish': 1426,
    'Stanton': 1426,
    'Sterling Heights': 1426,
    'Stevensville': 1426,
    'Stockbridge': 1426,
    'Stony Point': 1426,
    'Sturgis': 1426,
    'Swartz Creek': 1426,
    'Sylvan Lake': 1426,
    'Tawas City': 1426,
    'Taylor': 1426,
    'Tecumseh': 1426,
    'Temperance': 1426,
    'Three Oaks': 1426,
    'Three Rivers': 1426,
    'Traverse City': 1426,
    'Trenton': 1426,
    'Trowbridge Park': 1426,
    'Troy': 1426,
    'Tuscola County': 1426,
    'Twin Lake': 1426,
    'Union City': 1426,
    'Utica': 1426,
    'Van Buren County': 1426,
    'Vandercook Lake': 1426,
    'Vassar': 1426,
    'Vicksburg': 1426,
    'Wacousta': 1426,
    'Wakefield': 1426,
    'Walker': 1426,
    'Walled Lake': 1426,
    'Warren': 1426,
    'Washtenaw County': 1426,
    'Waterford': 1426,
    'Watervliet': 1426,
    'Waverly': 1426,
    'Wayland': 1426,
    'Wayne': 1426,
    'Wayne County': 1426,
    'Webberville': 1426,
    'West Bloomfield Township': 1426,
    'West Branch': 1426,
    'West Ishpeming': 1426,
    'West Monroe': 1426,
    'Westland': 1426,
    'Westwood': 1426,
    'Wexford County': 1426,
    'White Cloud': 1426,
    'White Pigeon': 1426,
    'Whitehall': 1426,
    'Whitmore Lake': 1426,
    'Williamston': 1426,
    'Wixom': 1426,
    'Wolf Lake': 1426,
    'Wolverine Lake': 1426,
    'Woodhaven': 1426,
    'Woodland Beach': 1426,
    'Wyandotte': 1426,
    'Wyoming': 1426,
    'Yale': 1426,
    'Ypsilanti': 1426,
    'Zeeland': 1426,
    'Zilwaukee': 1426,
    'Ada': 1420,
    'Adrian': 1420,
    'Afton': 1420,
    'Aitkin': 1420,
    'Aitkin County': 1420,
    'Albany': 1420,
    'Albert Lea': 1420,
    'Albertville': 1420,
    'Alexandria': 1420,
    'Andover': 1420,
    'Annandale': 1420,
    'Anoka': 1420,
    'Anoka County': 1420,
    'Apple Valley': 1420,
    'Appleton': 1420,
    'Arden Hills': 1420,
    'Arlington': 1420,
    'Arnold': 1420,
    'Atwater': 1420,
    'Aurora': 1420,
    'Austin': 1420,
    'Avon': 1420,
    'Babbitt': 1420,
    'Bagley': 1420,
    'Barnesville': 1420,
    'Baudette': 1420,
    'Baxter': 1420,
    'Bayport': 1420,
    'Becker': 1420,
    'Becker County': 1420,
    'Belle Plaine': 1420,
    'Beltrami County': 1420,
    'Bemidji': 1420,
    'Benson': 1420,
    'Benton County': 1420,
    'Big Lake': 1420,
    'Big Stone County': 1420,
    'Birchwood': 1420,
    'Blaine': 1420,
    'Blooming Prairie': 1420,
    'Bloomington': 1420,
    'Blue Earth': 1420,
    'Blue Earth County': 1420,
    'Braham': 1420,
    'Brainerd': 1420,
    'Branch': 1420,
    'Breckenridge': 1420,
    'Breezy Point': 1420,
    'Brooklyn Center': 1420,
    'Brooklyn Park': 1420,
    'Brown County': 1420,
    'Buffalo': 1420,
    'Burnsville': 1420,
    'Byron': 1420,
    'Caledonia': 1420,
    'Cambridge': 1420,
    'Canby': 1420,
    'Cannon Falls': 1420,
    'Carlton': 1420,
    'Carlton County': 1420,
    'Carver': 1420,
    'Carver County': 1420,
    'Cass County': 1420,
    'Center City': 1420,
    'Centerville': 1420,
    'Champlin': 1420,
    'Chanhassen': 1420,
    'Chaska': 1420,
    'Chatfield': 1420,
    'Chippewa County': 1420,
    'Chisago City': 1420,
    'Chisago County': 1420,
    'Chisholm': 1420,
    'Circle Pines': 1420,
    'Clara City': 1420,
    'Clay County': 1420,
    'Clearwater': 1420,
    'Clearwater County': 1420,
    'Cloquet': 1420,
    'Cohasset': 1420,
    'Cokato': 1420,
    'Cold Spring': 1420,
    'Coleraine': 1420,
    'Collegeville': 1420,
    'Cologne': 1420,
    'Columbia Heights': 1420,
    'Columbus': 1420,
    'Cook County': 1420,
    'Coon Rapids': 1420,
    'Corcoran': 1420,
    'Cottage Grove': 1420,
    'Cottonwood': 1420,
    'Cottonwood County': 1420,
    'Crookston': 1420,
    'Crosby': 1420,
    'Cross Lake': 1420,
    'Crow Wing County': 1420,
    'Crystal': 1420,
    'Dakota County': 1420,
    'Dassel': 1420,
    'Dawson': 1420,
    'Dayton': 1420,
    'Deephaven': 1420,
    'Delano': 1420,
    'Dellwood': 1420,
    'Detroit Lakes': 1420,
    'Dilworth': 1420,
    'Dodge Center': 1420,
    'Dodge County': 1420,
    'Douglas County': 1420,
    'Duluth': 1420,
    'Dundas': 1420,
    'Eagan': 1420,
    'Eagle Lake': 1420,
    'East Bethel': 1420,
    'East Grand Forks': 1420,
    'East Gull Lake': 1420,
    'Eden Prairie': 1420,
    'Eden Valley': 1420,
    'Edgerton': 1420,
    'Edina': 1420,
    'Elbow Lake': 1420,
    'Elgin': 1420,
    'Elk River': 1420,
    'Elko New Market': 1420,
    'Ely': 1420,
    'Esko': 1420,
    'Eveleth': 1420,
    'Excelsior': 1420,
    'Eyota': 1420,
    'Fairfax': 1420,
    'Fairmont': 1420,
    'Falcon Heights': 1420,
    'Faribault': 1420,
    'Faribault County': 1420,
    'Farmington': 1420,
    'Fergus Falls': 1420,
    'Fillmore County': 1420,
    'Foley': 1420,
    'Forest Lake': 1420,
    'Fosston': 1420,
    'Frazee': 1420,
    'Freeborn County': 1420,
    'Fridley': 1420,
    'Fulda': 1420,
    'Gaylord': 1420,
    'Gilbert': 1420,
    'Glencoe': 1420,
    'Glenwood': 1420,
    'Glyndon': 1420,
    'Golden Valley': 1420,
    'Goodhue': 1420,
    'Goodhue County': 1420,
    'Goodview': 1420,
    'Grand Marais': 1420,
    'Grand Meadow': 1420,
    'Grand Rapids': 1420,
    'Granite Falls': 1420,
    'Grant': 1420,
    'Grant County': 1420,
    'Greenfield': 1420,
    'Hallock': 1420,
    'Ham Lake': 1420,
    'Hanover': 1420,
    'Harris': 1420,
    'Hastings': 1420,
    'Hawley': 1420,
    'Hayfield': 1420,
    'Hector': 1420,
    'Hennepin County': 1420,
    'Hermantown': 1420,
    'Hibbing': 1420,
    'Hinckley': 1420,
    'Hopkins': 1420,
    'Houston County': 1420,
    'Howard Lake': 1420,
    'Hoyt Lakes': 1420,
    'Hubbard County': 1420,
    'Hugo': 1420,
    'Hutchinson': 1420,
    'Independence': 1420,
    'International Falls': 1420,
    'Inver Grove Heights': 1420,
    'Isanti': 1420,
    'Isanti County': 1420,
    'Itasca County': 1420,
    'Ivanhoe': 1420,
    'Jackson': 1420,
    'Jackson County': 1420,
    'Janesville': 1420,
    'Jordan': 1420,
    'Kanabec County': 1420,
    'Kandiyohi County': 1420,
    'Kasson': 1420,
    'Keewatin': 1420,
    'Kenyon': 1420,
    'Kittson County': 1420,
    'Koochiching County': 1420,
    'La Crescent': 1420,
    'Lac qui Parle County': 1420,
    'Lake City': 1420,
    'Lake County': 1420,
    'Lake Crystal': 1420,
    'Lake Elmo': 1420,
    'Lake of the Woods County': 1420,
    'Lake Saint Croix Beach': 1420,
    'Lake Shore': 1420,
    'Lakefield': 1420,
    'Lakeland': 1420,
    'Lakeville': 1420,
    'Lauderdale': 1420,
    'Le Center': 1420,
    'Le Sueur': 1420,
    'Le Sueur County': 1420,
    'Lester Prairie': 1420,
    'Lewiston': 1420,
    'Lexington': 1420,
    'Lincoln County': 1420,
    'Lindstrom': 1420,
    'Lino Lakes': 1420,
    'Litchfield': 1420,
    'Little Canada': 1420,
    'Little Falls': 1420,
    'Little Rock': 1420,
    'Long Lake': 1420,
    'Long Prairie': 1420,
    'Lonsdale': 1420,
    'Luverne': 1420,
    'Lyon County': 1420,
    'Madelia': 1420,
    'Madison': 1420,
    'Madison Lake': 1420,
    'Mahnomen': 1420,
    'Mahnomen County': 1420,
    'Mahtomedi': 1420,
    'Mankato': 1420,
    'Mantorville': 1420,
    'Maple Grove': 1420,
    'Maple Lake': 1420,
    'Maple Plain': 1420,
    'Mapleton': 1420,
    'Maplewood': 1420,
    'Marshall': 1420,
    'Marshall County': 1420,
    'Martin County': 1420,
    'Mayer': 1420,
    'McLeod County': 1420,
    'Medford': 1420,
    'Medina': 1420,
    'Meeker County': 1420,
    'Melrose': 1420,
    'Menahga': 1420,
    'Mendota Heights': 1420,
    'Milaca': 1420,
    'Mille Lacs County': 1420,
    'Minneapolis': 1420,
    'Minneota': 1420,
    'Minnetonka': 1420,
    'Minnetonka Mills': 1420,
    'Minnetrista': 1420,
    'Montevideo': 1420,
    'Montgomery': 1420,
    'Monticello': 1420,
    'Montrose': 1420,
    'Moorhead': 1420,
    'Moose Lake': 1420,
    'Mora': 1420,
    'Morris': 1420,
    'Morrison County': 1420,
    'Mound': 1420,
    'Mounds View': 1420,
    'Mountain Iron': 1420,
    'Mountain Lake': 1420,
    'Mower County': 1420,
    'Murray County': 1420,
    'New Brighton': 1420,
    'New Hope': 1420,
    'New London': 1420,
    'New Prague': 1420,
    'New Richland': 1420,
    'New Ulm': 1420,
    'New York Mills': 1420,
    'Newport': 1420,
    'Nicollet': 1420,
    'Nicollet County': 1420,
    'Nisswa': 1420,
    'Nobles County': 1420,
    'Norman County': 1420,
    'North Branch': 1420,
    'North Mankato': 1420,
    'North Oaks': 1420,
    'North Saint Paul': 1420,
    'Northfield': 1420,
    'Norwood (historical)': 1420,
    'Norwood Young America': 1420,
    'Nowthen': 1420,
    'Oak Grove': 1420,
    'Oak Park Heights': 1420,
    'Oakdale': 1420,
    'Oakport': 1420,
    'Olivia': 1420,
    'Olmsted County': 1420,
    'Orono': 1420,
    'Oronoco': 1420,
    'Ortonville': 1420,
    'Osakis': 1420,
    'Osseo': 1420,
    'Otsego': 1420,
    'Otter Tail County': 1420,
    'Owatonna': 1420,
    'Park Rapids': 1420,
    'Parkers Prairie': 1420,
    'Parkville': 1420,
    'Paynesville': 1420,
    'Pelican Rapids': 1420,
    'Pennington County': 1420,
    'Pequot Lakes': 1420,
    'Perham': 1420,
    'Pierz': 1420,
    'Pine City': 1420,
    'Pine County': 1420,
    'Pine Island': 1420,
    'Pipestone': 1420,
    'Pipestone County': 1420,
    'Plainview': 1420,
    'Plymouth': 1420,
    'Polk County': 1420,
    'Pope County': 1420,
    'Preston': 1420,
    'Princeton': 1420,
    'Prior Lake': 1420,
    'Proctor': 1420,
    'Ramsey': 1420,
    'Ramsey County': 1420,
    'Red Lake': 1420,
    'Red Lake County': 1420,
    'Red Lake Falls': 1420,
    'Red Wing': 1420,
    'Redby': 1420,
    'Redwood County': 1420,
    'Redwood Falls': 1420,
    'Renville': 1420,
    'Renville County': 1420,
    'Rice': 1420,
    'Rice County': 1420,
    'Richfield': 1420,
    'Richmond': 1420,
    'Robbinsdale': 1420,
    'Rochester': 1420,
    'Rock County': 1420,
    'Rock Creek': 1420,
    'Rockford': 1420,
    'Rockville': 1420,
    'Rogers': 1420,
    'Roseau': 1420,
    'Roseau County': 1420,
    'Rosemount': 1420,
    'Roseville': 1420,
    'Royalton': 1420,
    'Rush City': 1420,
    'Rushford': 1420,
    'Saint Anthony': 1420,
    'Saint Augusta': 1420,
    'Saint Bonifacius': 1420,
    'Saint Charles': 1420,
    'Saint Cloud': 1420,
    'Saint Francis': 1420,
    'Saint James': 1420,
    'Saint Joseph': 1420,
    'Saint Louis County': 1420,
    'Saint Louis Park': 1420,
    'Saint Michael': 1420,
    'Saint Paul': 1420,
    'Saint Paul Park': 1420,
    'Saint Peter': 1420,
    'Sandstone': 1420,
    'Sartell': 1420,
    'Sauk Centre': 1420,
    'Sauk Rapids': 1420,
    'Savage': 1420,
    'Scandia': 1420,
    'Scott County': 1420,
    'Shafer': 1420,
    'Shakopee': 1420,
    'Sherburn': 1420,
    'Sherburne County': 1420,
    'Shoreview': 1420,
    'Shorewood': 1420,
    'Sibley County': 1420,
    'Silver Bay': 1420,
    'Slayton': 1420,
    'Sleepy Eye': 1420,
    'South Saint Paul': 1420,
    'Spicer': 1420,
    'Spring Grove': 1420,
    'Spring Lake Park': 1420,
    'Spring Park': 1420,
    'Spring Valley': 1420,
    'Springfield': 1420,
    'Stacy': 1420,
    'Staples': 1420,
    'Starbuck': 1420,
    'Stearns County': 1420,
    'Steele County': 1420,
    'Stevens County': 1420,
    'Stewartville': 1420,
    'Stillwater': 1420,
    'Swift County': 1420,
    'Taylors Falls': 1420,
    'Thief River Falls': 1420,
    'Todd County': 1420,
    'Tonka Bay': 1420,
    'Tracy': 1420,
    'Traverse County': 1420,
    'Truman': 1420,
    'Two Harbors': 1420,
    'Tyler': 1420,
    'Vadnais Heights': 1420,
    'Victoria': 1420,
    'Vineland': 1420,
    'Virginia': 1420,
    'Wabasha': 1420,
    'Wabasha County': 1420,
    'Waconia': 1420,
    'Wadena': 1420,
    'Wadena County': 1420,
    'Waite Park': 1420,
    'Walker': 1420,
    'Wanamingo': 1420,
    'Warren': 1420,
    'Warroad': 1420,
    'Waseca': 1420,
    'Waseca County': 1420,
    'Washington County': 1420,
    'Watertown': 1420,
    'Waterville': 1420,
    'Watonwan County': 1420,
    'Waverly': 1420,
    'Wayzata': 1420,
    'Wells': 1420,
    'West Coon Rapids': 1420,
    'West Saint Paul': 1420,
    'Wheaton': 1420,
    'White Bear Lake': 1420,
    'Wilkin County': 1420,
    'Willmar': 1420,
    'Windom': 1420,
    'Winnebago': 1420,
    'Winona': 1420,
    'Winona County': 1420,
    'Winsted': 1420,
    'Winthrop': 1420,
    'Woodbury': 1420,
    'Worthington': 1420,
    'Wright County': 1420,
    'Wyoming': 1420,
    'Yellow Medicine County': 1420,
    'Young America (historical)': 1420,
    'Zimmerman': 1420,
    'Zumbrota': 1420,
    'Aberdeen': 1430,
    'Ackerman': 1430,
    'Adams County': 1430,
    'Alcorn County': 1430,
    'Amite County': 1430,
    'Amory': 1430,
    'Arnold Line': 1430,
    'Ashland': 1430,
    'Attala County': 1430,
    'Baldwyn': 1430,
    'Batesville': 1430,
    'Bay Saint Louis': 1430,
    'Bay Springs': 1430,
    'Beechwood': 1430,
    'Belmont': 1430,
    'Belzoni': 1430,
    'Benton County': 1430,
    'Biloxi': 1430,
    'Bolivar County': 1430,
    'Booneville': 1430,
    'Brandon': 1430,
    'Brookhaven': 1430,
    'Brooksville': 1430,
    'Bruce': 1430,
    'Bude': 1430,
    'Byhalia': 1430,
    'Byram': 1430,
    'Caledonia': 1430,
    'Calhoun City': 1430,
    'Calhoun County': 1430,
    'Canton': 1430,
    'Carriere': 1430,
    'Carroll County': 1430,
    'Carrollton': 1430,
    'Carthage': 1430,
    'Centreville': 1430,
    'Charleston': 1430,
    'Chickasaw County': 1430,
    'Choctaw County': 1430,
    'Claiborne County': 1430,
    'Clarke County': 1430,
    'Clarksdale': 1430,
    'Clay County': 1430,
    'Cleary': 1430,
    'Cleveland': 1430,
    'Clinton': 1430,
    'Coahoma County': 1430,
    'Coldwater': 1430,
    'Collins': 1430,
    'Collinsville': 1430,
    'Columbia': 1430,
    'Columbus': 1430,
    'Columbus Air Force Base': 1430,
    'Como': 1430,
    'Conehatta': 1430,
    'Copiah County': 1430,
    'Corinth': 1430,
    'Covington County': 1430,
    'Crystal Springs': 1430,
    'D  Iberville': 1430,
    'De Kalb': 1430,
    'De Lisle': 1430,
    'De Soto County': 1430,
    'Decatur': 1430,
    'Derma': 1430,
    'Diamondhead': 1430,
    'Drew': 1430,
    'Duck Hill': 1430,
    'Durant': 1430,
    'Edwards': 1430,
    'Ellisville': 1430,
    'Escatawpa': 1430,
    'Eupora': 1430,
    'Farmington': 1430,
    'Fayette': 1430,
    'Flora': 1430,
    'Florence': 1430,
    'Flowood': 1430,
    'Forest': 1430,
    'Forrest County': 1430,
    'Franklin County': 1430,
    'Friars Point': 1430,
    'Fulton': 1430,
    'Gautier': 1430,
    'George County': 1430,
    'Glendale': 1430,
    'Goodman': 1430,
    'Greene County': 1430,
    'Greenville': 1430,
    'Greenwood': 1430,
    'Grenada': 1430,
    'Grenada County': 1430,
    'Gulf Hills': 1430,
    'Gulf Park Estates': 1430,
    'Gulfport': 1430,
    'Guntown': 1430,
    'Hancock County': 1430,
    'Harrison County': 1430,
    'Hattiesburg': 1430,
    'Hazlehurst': 1430,
    'Helena': 1430,
    'Hernando': 1430,
    'Hickory Hills': 1430,
    'Hide-A-Way Lake': 1430,
    'Hillsboro': 1430,
    'Hinds County': 1430,
    'Hollandale': 1430,
    'Holly Springs': 1430,
    'Holmes County': 1430,
    'Horn Lake': 1430,
    'Houston': 1430,
    'Humphreys County': 1430,
    'Hurley': 1430,
    'Indianola': 1430,
    'Issaquena County': 1430,
    'Itawamba County': 1430,
    'Itta Bena': 1430,
    'Iuka': 1430,
    'Jackson': 1430,
    'Jackson County': 1430,
    'Jasper County': 1430,
    'Jefferson County': 1430,
    'Jefferson Davis County': 1430,
    'Jones County': 1430,
    'Jonestown': 1430,
    'Kearney Park': 1430,
    'Kemper County': 1430,
    'Kiln': 1430,
    'Kosciusko': 1430,
    'Lafayette County': 1430,
    'Lamar County': 1430,
    'Lambert': 1430,
    'Latimer': 1430,
    'Lauderdale County': 1430,
    'Laurel': 1430,
    'Lawrence County': 1430,
    'Leake County': 1430,
    'Leakesville': 1430,
    'Lee County': 1430,
    'Leflore County': 1430,
    'Leland': 1430,
    'Lexington': 1430,
    'Liberty': 1430,
    'Lincoln County': 1430,
    'Long Beach': 1430,
    'Louisville': 1430,
    'Lowndes County': 1430,
    'Lucedale': 1430,
    'Lumberton': 1430,
    'Lyman': 1430,
    'Lynchburg': 1430,
    'Macon': 1430,
    'Madison': 1430,
    'Madison County': 1430,
    'Magee': 1430,
    'Magnolia': 1430,
    'Mantachie': 1430,
    'Marion': 1430,
    'Marion County': 1430,
    'Marks': 1430,
    'Marshall County': 1430,
    'Mayersville': 1430,
    'McComb': 1430,
    'Meadville': 1430,
    'Mendenhall': 1430,
    'Meridian': 1430,
    'Meridian Station': 1430,
    'Metcalfe': 1430,
    'Mikoma': 1430,
    'Monroe County': 1430,
    'Montgomery County': 1430,
    'Monticello': 1430,
    'Moorhead': 1430,
    'Morgantown': 1430,
    'Morton': 1430,
    'Moss Point': 1430,
    'Mound Bayou': 1430,
    'Natchez': 1430,
    'Nellieburg': 1430,
    'Neshoba County': 1430,
    'Nettleton': 1430,
    'New Albany': 1430,
    'New Augusta': 1430,
    'New Hope': 1430,
    'Newton': 1430,
    'Newton County': 1430,
    'Nicholson': 1430,
    'North Tunica': 1430,
    'Noxubee County': 1430,
    'Ocean Springs': 1430,
    'Okolona': 1430,
    'Oktibbeha County': 1430,
    'Olive Branch': 1430,
    'Oxford': 1430,
    'Panola County': 1430,
    'Pascagoula': 1430,
    'Pass Christian': 1430,
    'Pearl': 1430,
    'Pearl River': 1430,
    'Pearl River County': 1430,
    'Pearlington': 1430,
    'Pelahatchie': 1430,
    'Perry County': 1430,
    'Petal': 1430,
    'Philadelphia': 1430,
    'Picayune': 1430,
    'Pickens': 1430,
    'Pike County': 1430,
    'Pittsboro': 1430,
    'Plantersville': 1430,
    'Pontotoc': 1430,
    'Pontotoc County': 1430,
    'Poplarville': 1430,
    'Port Gibson': 1430,
    'Prentiss': 1430,
    'Prentiss County': 1430,
    'Purvis': 1430,
    'Quitman': 1430,
    'Quitman County': 1430,
    'Raleigh': 1430,
    'Rankin County': 1430,
    'Rawls Springs': 1430,
    'Raymond': 1430,
    'Richland': 1430,
    'Richton': 1430,
    'Ridgeland': 1430,
    'Ripley': 1430,
    'Rolling Fork': 1430,
    'Rosedale': 1430,
    'Ruleville': 1430,
    'Saint Martin': 1430,
    'Saltillo': 1430,
    'Sardis': 1430,
    'Saucier': 1430,
    'Scott County': 1430,
    'Senatobia': 1430,
    'Shannon': 1430,
    'Sharkey County': 1430,
    'Sharon': 1430,
    'Shaw': 1430,
    'Shelby': 1430,
    'Simpson County': 1430,
    'Smith County': 1430,
    'Southaven': 1430,
    'Starkville': 1430,
    'Stone County': 1430,
    'Stonewall': 1430,
    'Summit': 1430,
    'Sumrall': 1430,
    'Sunflower': 1430,
    'Sunflower County': 1430,
    'Tallahatchie County': 1430,
    'Tate County': 1430,
    'Taylorsville': 1430,
    'Tchula': 1430,
    'Terry': 1430,
    'Tippah County': 1430,
    'Tishomingo County': 1430,
    'Tunica': 1430,
    'Tunica County': 1430,
    'Tunica Resorts': 1430,
    'Tupelo': 1430,
    'Tutwiler': 1430,
    'Tylertown': 1430,
    'Union': 1430,
    'Union County': 1430,
    'University': 1430,
    'Vancleave': 1430,
    'Vardaman': 1430,
    'Verona': 1430,
    'Vicksburg': 1430,
    'Wade': 1430,
    'Walls': 1430,
    'Walnut Grove': 1430,
    'Walthall': 1430,
    'Walthall County': 1430,
    'Warren County': 1430,
    'Washington County': 1430,
    'Water Valley': 1430,
    'Waveland': 1430,
    'Wayne County': 1430,
    'Waynesboro': 1430,
    'Webster County': 1430,
    'Wesson': 1430,
    'West Gulfport': 1430,
    'West Hattiesburg': 1430,
    'West Point': 1430,
    'Wiggins': 1430,
    'Wilkinson County': 1430,
    'Winona': 1430,
    'Winston County': 1430,
    'Woodville': 1430,
    'Yalobusha County': 1430,
    'Yazoo City': 1430,
    'Yazoo County': 1430,
    'Adair County': 1451,
    'Adrian': 1451,
    'Advance': 1451,
    'Affton': 1451,
    'Albany': 1451,
    'Alton': 1451,
    'Anderson': 1451,
    'Andrew County': 1451,
    'Appleton City': 1451,
    'Archie': 1451,
    'Arnold': 1451,
    'Ash Grove': 1451,
    'Ashland': 1451,
    'Atchison County': 1451,
    'Audrain County': 1451,
    'Aurora': 1451,
    'Ava': 1451,
    'Ballwin': 1451,
    'Barnhart': 1451,
    'Barry County': 1451,
    'Barton County': 1451,
    'Bates County': 1451,
    'Battlefield': 1451,
    'Bel-Nor': 1451,
    'Bel-Ridge': 1451,
    'Belle': 1451,
    'Bellefontaine Neighbors': 1451,
    'Belton': 1451,
    'Benton': 1451,
    'Benton County': 1451,
    'Berkeley': 1451,
    'Bernie': 1451,
    'Bethany': 1451,
    'Billings': 1451,
    'Bismarck': 1451,
    'Black Jack': 1451,
    'Bloomfield': 1451,
    'Blue Springs': 1451,
    'Bolivar': 1451,
    'Bollinger County': 1451,
    'Bonne Terre': 1451,
    'Boone County': 1451,
    'Boonville': 1451,
    'Bourbon': 1451,
    'Bowling Green': 1451,
    'Branson': 1451,
    'Breckenridge Hills': 1451,
    'Brentwood': 1451,
    'Bridgeton': 1451,
    'Brookfield': 1451,
    'Buchanan County': 1451,
    'Buckner': 1451,
    'Buffalo': 1451,
    'Butler': 1451,
    'Butler County': 1451,
    'Byrnes Mill': 1451,
    'Cabool': 1451,
    'Caldwell County': 1451,
    'California': 1451,
    'Callaway County': 1451,
    'Calverton Park': 1451,
    'Camden County': 1451,
    'Camdenton': 1451,
    'Cameron': 1451,
    'Campbell': 1451,
    'Canton': 1451,
    'Cape Girardeau': 1451,
    'Cape Girardeau County': 1451,
    'Carl Junction': 1451,
    'Carroll County': 1451,
    'Carrollton': 1451,
    'Carter County': 1451,
    'Carterville': 1451,
    'Carthage': 1451,
    'Caruthersville': 1451,
    'Cass County': 1451,
    'Cassville': 1451,
    'Castle Point': 1451,
    'Cedar County': 1451,
    'Cedar Hill': 1451,
    'Centerville': 1451,
    'Centralia': 1451,
    'Chaffee': 1451,
    'Chariton County': 1451,
    'Charlack': 1451,
    'Charleston': 1451,
    'Chesterfield': 1451,
    'Chillicothe': 1451,
    'Christian County': 1451,
    'City of Saint Louis': 1451,
    'Clark County': 1451,
    'Clarkson Valley': 1451,
    'Clarkton': 1451,
    'Clay County': 1451,
    'Claycomo': 1451,
    'Clayton': 1451,
    'Clever': 1451,
    'Clinton': 1451,
    'Clinton County': 1451,
    'Cole Camp': 1451,
    'Cole County': 1451,
    'Columbia': 1451,
    'Concord': 1451,
    'Concordia': 1451,
    'Cool Valley': 1451,
    'Cooper County': 1451,
    'Cottleville': 1451,
    'Country Club Hills': 1451,
    'Country Club Village': 1451,
    'Crane': 1451,
    'Crawford County': 1451,
    'Crestwood': 1451,
    'Creve Coeur': 1451,
    'Crocker': 1451,
    'Crystal City': 1451,
    'Cuba': 1451,
    'Dade County': 1451,
    'Dallas County': 1451,
    'Dardenne Prairie': 1451,
    'Daviess County': 1451,
    'De Soto': 1451,
    'DeKalb County': 1451,
    'Dellwood': 1451,
    'Dent County': 1451,
    'Des Peres': 1451,
    'Desloge': 1451,
    'Dexter': 1451,
    'Dixon': 1451,
    'Doniphan': 1451,
    'Douglas County': 1451,
    'Duenweg': 1451,
    'Dunklin County': 1451,
    'Duquesne': 1451,
    'East Independence': 1451,
    'East Prairie': 1451,
    'Edina': 1451,
    'El Dorado Springs': 1451,
    'Eldon': 1451,
    'Ellisville': 1451,
    'Elsberry': 1451,
    'Elvins': 1451,
    'Eminence': 1451,
    'Esther': 1451,
    'Eureka': 1451,
    'Excelsior Springs': 1451,
    'Fair Grove': 1451,
    'Farmington': 1451,
    'Fayette': 1451,
    'Fenton': 1451,
    'Ferguson': 1451,
    'Festus': 1451,
    'Flat River': 1451,
    'Florissant': 1451,
    'Forsyth': 1451,
    'Fort Leonard Wood': 1451,
    'Four Seasons': 1451,
    'Franklin County': 1451,
    'Fredericktown': 1451,
    'Frontenac': 1451,
    'Fulton': 1451,
    'Gainesville': 1451,
    'Galena': 1451,
    'Gallatin': 1451,
    'Garden City': 1451,
    'Gasconade County': 1451,
    'Gentry County': 1451,
    'Gerald': 1451,
    'Gideon': 1451,
    'Gladstone': 1451,
    'Glasgow': 1451,
    'Glasgow Village': 1451,
    'Glendale': 1451,
    'Goodman': 1451,
    'Gower': 1451,
    'Grain Valley': 1451,
    'Granby': 1451,
    'Grandview': 1451,
    'Grant City': 1451,
    'Gray Summit': 1451,
    'Green Park': 1451,
    'Greene County': 1451,
    'Greenfield': 1451,
    'Greenville': 1451,
    'Greenwood': 1451,
    'Grundy County': 1451,
    'Hallsville': 1451,
    'Hamilton': 1451,
    'Hanley Hills': 1451,
    'Hannibal': 1451,
    'Harrison County': 1451,
    'Harrisonville': 1451,
    'Hartville': 1451,
    'Hayti': 1451,
    'Hazelwood': 1451,
    'Henry County': 1451,
    'Herculaneum': 1451,
    'Hermann': 1451,
    'Hermitage': 1451,
    'Hickory County': 1451,
    'Higginsville': 1451,
    'High Ridge': 1451,
    'Hillsboro': 1451,
    'Hillsdale': 1451,
    'Holden': 1451,
    'Hollister': 1451,
    'Holt County': 1451,
    'Holts Summit': 1451,
    'Houston': 1451,
    'Howard County': 1451,
    'Howell County': 1451,
    'Humansville': 1451,
    'Huntsville': 1451,
    'Imperial': 1451,
    'Independence': 1451,
    'Iron County': 1451,
    'Ironton': 1451,
    'Jackson': 1451,
    'Jackson County': 1451,
    'Jasper County': 1451,
    'Jefferson City': 1451,
    'Jefferson County': 1451,
    'Jennings': 1451,
    'Johnson County': 1451,
    'Joplin': 1451,
    'Kahoka': 1451,
    'Kansas City': 1451,
    'Kearney': 1451,
    'Kennett': 1451,
    'Keytesville': 1451,
    'Kimberling City': 1451,
    'King City': 1451,
    'Kingston': 1451,
    'Kirksville': 1451,
    'Kirkwood': 1451,
    'Kissee Mills': 1451,
    'Knob Noster': 1451,
    'Knox County': 1451,
    'La Monte': 1451,
    'La Plata': 1451,
    'LaBarque Creek': 1451,
    'Laclede County': 1451,
    'Ladue': 1451,
    'Lafayette County': 1451,
    'Lake Lotawana': 1451,
    'Lake Ozark': 1451,
    'Lake Saint Louis': 1451,
    'Lake Winnebago': 1451,
    'Lakeshire': 1451,
    'Lamar': 1451,
    'Lancaster': 1451,
    'Lathrop': 1451,
    'Lawrence County': 1451,
    'Lawson': 1451,
    'Leadwood': 1451,
    'Lebanon': 1451,
    'Lee  s Summit': 1451,
    'Lemay': 1451,
    'Lewis County': 1451,
    'Lexington': 1451,
    'Liberty': 1451,
    'Licking': 1451,
    'Lilbourn': 1451,
    'Lincoln': 1451,
    'Lincoln County': 1451,
    'Linn': 1451,
    'Linn County': 1451,
    'Linneus': 1451,
    'Livingston County': 1451,
    'Lone Jack': 1451,
    'Louisiana': 1451,
    'Macon': 1451,
    'Macon County': 1451,
    'Madison County': 1451,
    'Malden': 1451,
    'Manchester': 1451,
    'Mansfield': 1451,
    'Maplewood': 1451,
    'Marble Hill': 1451,
    'Marceline': 1451,
    'Maries County': 1451,
    'Marion County': 1451,
    'Marionville': 1451,
    'Marlborough': 1451,
    'Marshall': 1451,
    'Marshfield': 1451,
    'Marthasville': 1451,
    'Maryland Heights': 1451,
    'Maryville': 1451,
    'Maysville': 1451,
    'McDonald County': 1451,
    'Mehlville': 1451,
    'Memphis': 1451,
    'Mercer County': 1451,
    'Merriam Woods': 1451,
    'Mexico': 1451,
    'Milan': 1451,
    'Miller County': 1451,
    'Mississippi County': 1451,
    'Moberly': 1451,
    'Moline Acres': 1451,
    'Monett': 1451,
    'Moniteau County': 1451,
    'Monroe City': 1451,
    'Monroe County': 1451,
    'Montgomery City': 1451,
    'Montgomery County': 1451,
    'Monticello': 1451,
    'Morgan County': 1451,
    'Moscow Mills': 1451,
    'Mound City': 1451,
    'Mount Vernon': 1451,
    'Mountain Grove': 1451,
    'Mountain View': 1451,
    'Murphy': 1451,
    'Neosho': 1451,
    'Nevada': 1451,
    'New Franklin': 1451,
    'New Haven': 1451,
    'New London': 1451,
    'New Madrid': 1451,
    'New Madrid County': 1451,
    'Newton County': 1451,
    'Nixa': 1451,
    'Nodaway County': 1451,
    'Noel': 1451,
    'Normandy': 1451,
    'North Kansas City': 1451,
    'Northwoods': 1451,
    'O  Fallon': 1451,
    'Oak Grove': 1451,
    'Oakland': 1451,
    'Oakville': 1451,
    'Odessa': 1451,
    'Old Jamestown': 1451,
    'Olivette': 1451,
    'Oran': 1451,
    'Oregon': 1451,
    'Oregon County': 1451,
    'Oronogo': 1451,
    'Osage Beach': 1451,
    'Osage County': 1451,
    'Osceola': 1451,
    'Overland': 1451,
    'Owensville': 1451,
    'Ozark': 1451,
    'Ozark County': 1451,
    'Pacific': 1451,
    'Pagedale': 1451,
    'Palmyra': 1451,
    'Paris': 1451,
    'Park Hills': 1451,
    'Parkville': 1451,
    'Peculiar': 1451,
    'Pemiscot County': 1451,
    'Perry County': 1451,
    'Perryville': 1451,
    'Pettis County': 1451,
    'Pevely': 1451,
    'Phelps County': 1451,
    'Piedmont': 1451,
    'Pierce City': 1451,
    'Pike County': 1451,
    'Pine Lawn': 1451,
    'Pineville': 1451,
    'Platte City': 1451,
    'Platte County': 1451,
    'Plattsburg': 1451,
    'Pleasant Hill': 1451,
    'Pleasant Valley': 1451,
    'Polk County': 1451,
    'Poplar Bluff': 1451,
    'Portageville': 1451,
    'Potosi': 1451,
    'Princeton': 1451,
    'Pulaski County': 1451,
    'Purdy': 1451,
    'Putnam County': 1451,
    'Ralls County': 1451,
    'Randolph County': 1451,
    'Ray County': 1451,
    'Raymore': 1451,
    'Raytown': 1451,
    'Republic': 1451,
    'Reynolds County': 1451,
    'Rich Hill': 1451,
    'Richland': 1451,
    'Richmond': 1451,
    'Richmond Heights': 1451,
    'Ripley County': 1451,
    'Riverside': 1451,
    'Riverview': 1451,
    'Rock Hill': 1451,
    'Rock Port': 1451,
    'Rogersville': 1451,
    'Rolla': 1451,
    'Saint Ann': 1451,
    'Saint Charles': 1451,
    'Saint Charles County': 1451,
    'Saint Clair': 1451,
    'Saint Clair County': 1451,
    'Saint Francois County': 1451,
    'Saint George': 1451,
    'Saint James': 1451,
    'Saint John': 1451,
    'Saint Johns': 1451,
    'Saint Joseph': 1451,
    'Saint Louis County': 1451,
    'Saint Martins': 1451,
    'Saint Paul': 1451,
    'Saint Peters': 1451,
    'Saint Robert': 1451,
    'Sainte Genevieve': 1451,
    'Sainte Genevieve County': 1451,
    'Salem': 1451,
    'Saline County': 1451,
    'Salisbury': 1451,
    'Sappington': 1451,
    'Sarcoxie': 1451,
    'Savannah': 1451,
    'Schuyler County': 1451,
    'Scotland County': 1451,
    'Scott City': 1451,
    'Scott County': 1451,
    'Sedalia': 1451,
    'Senath': 1451,
    'Seneca': 1451,
    'Seymour': 1451,
    'Shannon County': 1451,
    'Shelbina': 1451,
    'Shelby County': 1451,
    'Shelbyville': 1451,
    'Shell Knob': 1451,
    'Shrewsbury': 1451,
    'Sikeston': 1451,
    'Slater': 1451,
    'Smithville': 1451,
    'Spanish Lake': 1451,
    'Sparta': 1451,
    'Springfield': 1451,
    'St. Louis': 1451,
    'Stanberry': 1451,
    'Steele': 1451,
    'Steelville': 1451,
    'Stockton': 1451,
    'Stoddard County': 1451,
    'Stone County': 1451,
    'Stover': 1451,
    'Strafford': 1451,
    'Sugar Creek': 1451,
    'Sullivan': 1451,
    'Sullivan County': 1451,
    'Sunset Hills': 1451,
    'Sweet Springs': 1451,
    'Taney County': 1451,
    'Taos': 1451,
    'Tarkio': 1451,
    'Terre du Lac': 1451,
    'Terre Haute': 1451,
    'Texas County': 1451,
    'Thayer': 1451,
    'Tipton': 1451,
    'Town and Country': 1451,
    'Trenton': 1451,
    'Troy': 1451,
    'Tuscumbia': 1451,
    'Union': 1451,
    'Unionville': 1451,
    'University City': 1451,
    'Valley Park': 1451,
    'Van Buren': 1451,
    'Vandalia': 1451,
    'Velda Village': 1451,
    'Velda Village Hills': 1451,
    'Vernon County': 1451,
    'Versailles': 1451,
    'Vienna': 1451,
    'Villa Ridge': 1451,
    'Vinita Park': 1451,
    'Wardsville': 1451,
    'Warren County': 1451,
    'Warrensburg': 1451,
    'Warrenton': 1451,
    'Warsaw': 1451,
    'Warson Woods': 1451,
    'Washington': 1451,
    'Washington County': 1451,
    'Wayne County': 1451,
    'Waynesville': 1451,
    'Weatherby Lake': 1451,
    'Webb City': 1451,
    'Webster County': 1451,
    'Webster Groves': 1451,
    'Weldon Spring': 1451,
    'Wellston': 1451,
    'Wellsville': 1451,
    'Wentzville': 1451,
    'West Plains': 1451,
    'Weston': 1451,
    'Whiteman Air Force Base': 1451,
    'Wildwood': 1451,
    'Willard': 1451,
    'Willow Springs': 1451,
    'Winchester': 1451,
    'Windsor': 1451,
    'Winfield': 1451,
    'Winona': 1451,
    'Woodson Terrace': 1451,
    'Worth County': 1451,
    'Wright City': 1451,
    'Wright County': 1451,
    'Absarokee': 1446,
    'Anaconda': 1446,
    'Baker': 1446,
    'Beaverhead County': 1446,
    'Belgrade': 1446,
    'Big Horn County': 1446,
    'Big Sky': 1446,
    'Big Timber': 1446,
    'Bigfork': 1446,
    'Billings': 1446,
    'Blaine County': 1446,
    'Bonner-West Riverside': 1446,
    'Boulder': 1446,
    'Bozeman': 1446,
    'Broadus': 1446,
    'Broadwater County': 1446,
    'Browning': 1446,
    'Butte': 1446,
    'Butte-Silver Bow (Balance)': 1446,
    'Carbon County': 1446,
    'Carter County': 1446,
    'Cascade County': 1446,
    'Chester': 1446,
    'Chinook': 1446,
    'Choteau': 1446,
    'Chouteau County': 1446,
    'Circle': 1446,
    'Clancy': 1446,
    'Clinton': 1446,
    'Colstrip': 1446,
    'Columbia Falls': 1446,
    'Columbus': 1446,
    'Conrad': 1446,
    'Crow Agency': 1446,
    'Custer County': 1446,
    'Cut Bank': 1446,
    'Daniels County': 1446,
    'Dawson County': 1446,
    'Deer Lodge': 1446,
    'Deer Lodge County': 1446,
    'Dillon': 1446,
    'East Helena': 1446,
    'East Missoula': 1446,
    'Ekalaka': 1446,
    'Eureka': 1446,
    'Evergreen': 1446,
    'Fallon County': 1446,
    'Fergus County': 1446,
    'Flathead County': 1446,
    'Forsyth': 1446,
    'Fort Belknap Agency': 1446,
    'Fort Benton': 1446,
    'Four Corners': 1446,
    'Frenchtown': 1446,
    'Gallatin County': 1446,
    'Garfield County': 1446,
    'Glacier County': 1446,
    'Glasgow': 1446,
    'Glendive': 1446,
    'Golden Valley County': 1446,
    'Granite County': 1446,
    'Great Falls': 1446,
    'Hamilton': 1446,
    'Hardin': 1446,
    'Harlowton': 1446,
    'Havre': 1446,
    'Helena': 1446,
    'Helena Valley Northeast': 1446,
    'Helena Valley Northwest': 1446,
    'Helena Valley Southeast': 1446,
    'Helena Valley West Central': 1446,
    'Helena West Side': 1446,
    'Hill County': 1446,
    'Hysham': 1446,
    'Jefferson County': 1446,
    'Jordan': 1446,
    'Judith Basin County': 1446,
    'Kalispell': 1446,
    'Lake County': 1446,
    'Lakeside': 1446,
    'Lame Deer': 1446,
    'Laurel': 1446,
    'Lewis and Clark County': 1446,
    'Lewistown': 1446,
    'Libby': 1446,
    'Liberty County': 1446,
    'Lincoln': 1446,
    'Lincoln County': 1446,
    'Livingston': 1446,
    'Lockwood': 1446,
    'Lolo': 1446,
    'Madison County': 1446,
    'Malmstrom Air Force Base': 1446,
    'Malta': 1446,
    'Manhattan': 1446,
    'McCone County': 1446,
    'Meagher County': 1446,
    'Miles City': 1446,
    'Mineral County': 1446,
    'Missoula': 1446,
    'Missoula County': 1446,
    'Montana City': 1446,
    'Musselshell County': 1446,
    'North Browning': 1446,
    'Orchard Homes': 1446,
    'Pablo': 1446,
    'Park County': 1446,
    'Petroleum County': 1446,
    'Philipsburg': 1446,
    'Phillips County': 1446,
    'Plains': 1446,
    'Plentywood': 1446,
    'Polson': 1446,
    'Pondera County': 1446,
    'Powder River County': 1446,
    'Powell County': 1446,
    'Prairie County': 1446,
    'Ravalli County': 1446,
    'Red Lodge': 1446,
    'Richland County': 1446,
    'Ronan': 1446,
    'Roosevelt County': 1446,
    'Rosebud County': 1446,
    'Roundup': 1446,
    'Ryegate': 1446,
    'Sanders County': 1446,
    'Scobey': 1446,
    'Seeley Lake': 1446,
    'Shelby': 1446,
    'Sheridan County': 1446,
    'Sidney': 1446,
    'Silver Bow County': 1446,
    'Somers': 1446,
    'South Browning': 1446,
    'Stanford': 1446,
    'Stevensville': 1446,
    'Stillwater County': 1446,
    'Sun Prairie': 1446,
    'Superior': 1446,
    'Sweet Grass County': 1446,
    'Terry': 1446,
    'Teton County': 1446,
    'Thompson Falls': 1446,
    'Three Forks': 1446,
    'Toole County': 1446,
    'Townsend': 1446,
    'Treasure County': 1446,
    'Valley County': 1446,
    'Virginia City': 1446,
    'Warm Springs': 1446,
    'West Glendive': 1446,
    'West Yellowstone': 1446,
    'Wheatland County': 1446,
    'White Sulphur Springs': 1446,
    'Whitefish': 1446,
    'Whitehall': 1446,
    'Wibaux': 1446,
    'Wibaux County': 1446,
    'Winnett': 1446,
    'Wolf Point': 1446,
    'Yellowstone County': 1446,
    'Adams County': 1408,
    'Ainsworth': 1408,
    'Albion': 1408,
    'Alliance': 1408,
    'Alma': 1408,
    'Antelope County': 1408,
    'Arapahoe': 1408,
    'Arlington': 1408,
    'Arthur': 1408,
    'Arthur County': 1408,
    'Ashland': 1408,
    'Atkinson': 1408,
    'Auburn': 1408,
    'Aurora': 1408,
    'Banner County': 1408,
    'Bartlett': 1408,
    'Bassett': 1408,
    'Battle Creek': 1408,
    'Bayard': 1408,
    'Beatrice': 1408,
    'Beaver City': 1408,
    'Bellevue': 1408,
    'Benkelman': 1408,
    'Bennington': 1408,
    'Blaine County': 1408,
    'Blair': 1408,
    'Boone County': 1408,
    'Box Butte County': 1408,
    'Boyd County': 1408,
    'Brewster': 1408,
    'Bridgeport': 1408,
    'Broken Bow': 1408,
    'Brown County': 1408,
    'Buffalo County': 1408,
    'Burt County': 1408,
    'Burwell': 1408,
    'Butler County': 1408,
    'Butte': 1408,
    'Cambridge': 1408,
    'Cass County': 1408,
    'Cedar County': 1408,
    'Center': 1408,
    'Central City': 1408,
    'Chadron': 1408,
    'Chalco': 1408,
    'Chappell': 1408,
    'Chase County': 1408,
    'Cherry County': 1408,
    'Cheyenne County': 1408,
    'Clay Center': 1408,
    'Clay County': 1408,
    'Colfax County': 1408,
    'Columbus': 1408,
    'Cozad': 1408,
    'Creighton': 1408,
    'Crete': 1408,
    'Cuming County': 1408,
    'Custer County': 1408,
    'Dakota City': 1408,
    'Dakota County': 1408,
    'David City': 1408,
    'Dawes County': 1408,
    'Dawson County': 1408,
    'Deuel County': 1408,
    'Dixon County': 1408,
    'Dodge County': 1408,
    'Douglas County': 1408,
    'Dundy County': 1408,
    'Eagle': 1408,
    'Elkhorn': 1408,
    'Elwood': 1408,
    'Fairbury': 1408,
    'Falls City': 1408,
    'Fillmore County': 1408,
    'Franklin': 1408,
    'Franklin County': 1408,
    'Fremont': 1408,
    'Friend': 1408,
    'Frontier County': 1408,
    'Fullerton': 1408,
    'Furnas County': 1408,
    'Gage County': 1408,
    'Garden County': 1408,
    'Garfield County': 1408,
    'Geneva': 1408,
    'Gering': 1408,
    'Gibbon': 1408,
    'Gordon': 1408,
    'Gosper County': 1408,
    'Gothenburg': 1408,
    'Grand Island': 1408,
    'Grant': 1408,
    'Grant County': 1408,
    'Greeley': 1408,
    'Greeley County': 1408,
    'Gretna': 1408,
    'Hall County': 1408,
    'Hamilton County': 1408,
    'Harlan County': 1408,
    'Harrisburg': 1408,
    'Harrison': 1408,
    'Hartington': 1408,
    'Hastings': 1408,
    'Hayes Center': 1408,
    'Hayes County': 1408,
    'Hebron': 1408,
    'Hickman': 1408,
    'Hitchcock County': 1408,
    'Holdrege': 1408,
    'Holt County': 1408,
    'Hooker County': 1408,
    'Howard County': 1408,
    'Hyannis': 1408,
    'Imperial': 1408,
    'Jefferson County': 1408,
    'Johnson County': 1408,
    'Kearney': 1408,
    'Kearney County': 1408,
    'Keith County': 1408,
    'Keya Paha County': 1408,
    'Kimball': 1408,
    'Kimball County': 1408,
    'Knox County': 1408,
    'La Vista': 1408,
    'Lancaster County': 1408,
    'Lexington': 1408,
    'Lincoln': 1408,
    'Lincoln County': 1408,
    'Logan County': 1408,
    'Louisville': 1408,
    'Loup City': 1408,
    'Loup County': 1408,
    'Macy': 1408,
    'Madison': 1408,
    'Madison County': 1408,
    'McCook': 1408,
    'McPherson County': 1408,
    'Merrick County': 1408,
    'Milford': 1408,
    'Minden': 1408,
    'Mitchell': 1408,
    'Morrill County': 1408,
    'Mullen': 1408,
    'Nance County': 1408,
    'Nebraska City': 1408,
    'Neligh': 1408,
    'Nelson': 1408,
    'Nemaha County': 1408,
    'Norfolk': 1408,
    'North Bend': 1408,
    'North Platte': 1408,
    'Nuckolls County': 1408,
    'O  Neill': 1408,
    'Oakland': 1408,
    'Offutt Air Force Base': 1408,
    'Ogallala': 1408,
    'Omaha': 1408,
    'Ord': 1408,
    'Osceola': 1408,
    'Oshkosh': 1408,
    'Otoe County': 1408,
    'Papillion': 1408,
    'Pawnee City': 1408,
    'Pawnee County': 1408,
    'Pender': 1408,
    'Perkins County': 1408,
    'Phelps County': 1408,
    'Pierce': 1408,
    'Pierce County': 1408,
    'Plainview': 1408,
    'Platte County': 1408,
    'Plattsmouth': 1408,
    'Polk County': 1408,
    'Ponca': 1408,
    'Ralston': 1408,
    'Ravenna': 1408,
    'Red Cloud': 1408,
    'Red Willow County': 1408,
    'Richardson County': 1408,
    'Rock County': 1408,
    'Rushville': 1408,
    'Saint Paul': 1408,
    'Saline County': 1408,
    'Sarpy County': 1408,
    'Saunders County': 1408,
    'Schuyler': 1408,
    'Scotts Bluff County': 1408,
    'Scottsbluff': 1408,
    'Seward': 1408,
    'Seward County': 1408,
    'Shelton': 1408,
    'Sheridan County': 1408,
    'Sherman County': 1408,
    'Sidney': 1408,
    'Sioux County': 1408,
    'South Sioux City': 1408,
    'Springfield': 1408,
    'Springview': 1408,
    'Stanton': 1408,
    'Stanton County': 1408,
    'Stapleton': 1408,
    'Stockville': 1408,
    'Stromsburg': 1408,
    'Superior': 1408,
    'Sutherland': 1408,
    'Sutton': 1408,
    'Syracuse': 1408,
    'Taylor': 1408,
    'Tecumseh': 1408,
    'Tekamah': 1408,
    'Terrytown': 1408,
    'Thayer County': 1408,
    'Thedford': 1408,
    'Thomas County': 1408,
    'Thurston County': 1408,
    'Trenton': 1408,
    'Tryon': 1408,
    'Valentine': 1408,
    'Valley': 1408,
    'Valley County': 1408,
    'Wahoo': 1408,
    'Wakefield': 1408,
    'Washington County': 1408,
    'Waterloo': 1408,
    'Waverly': 1408,
    'Wayne': 1408,
    'Wayne County': 1408,
    'Webster County': 1408,
    'Weeping Water': 1408,
    'West Point': 1408,
    'Wheeler County': 1408,
    'Wilber': 1408,
    'Wisner': 1408,
    'Wood River': 1408,
    'Wymore': 1408,
    'York': 1408,
    'York County': 1408,
    'Yutan': 1408,
    'Alamo': 1458,
    'Battle Mountain': 1458,
    'Beatty': 1458,
    'Boulder City': 1458,
    'Bunkerville': 1458,
    'Caliente': 1458,
    'Carlin': 1458,
    'Carson City': 1458,
    'Churchill County': 1458,
    'Clark County': 1458,
    'Cold Springs': 1458,
    'Dayton': 1458,
    'Douglas County': 1458,
    'East Valley': 1458,
    'Elko': 1458,
    'Elko County': 1458,
    'Ely': 1458,
    'Enterprise': 1458,
    'Esmeralda County': 1458,
    'Eureka': 1458,
    'Eureka County': 1458,
    'Fallon': 1458,
    'Fernley': 1458,
    'Gardnerville': 1458,
    'Gardnerville Ranchos': 1458,
    'Golden Valley': 1458,
    'Goldfield': 1458,
    'Hawthorne': 1458,
    'Henderson': 1458,
    'Humboldt County': 1458,
    'Incline Village': 1458,
    'Indian Hills': 1458,
    'Jackpot': 1458,
    'Johnson Lane': 1458,
    'Kingsbury': 1458,
    'Lander County': 1458,
    'Las Vegas': 1458,
    'Laughlin': 1458,
    'Lemmon Valley': 1458,
    'Lincoln County': 1458,
    'Lovelock': 1458,
    'Lyon County': 1458,
    'McGill': 1458,
    'Mesquite': 1458,
    'Minden': 1458,
    'Mineral County': 1458,
    'Moapa Town': 1458,
    'Moapa Valley': 1458,
    'Mogul': 1458,
    'Nellis Air Force Base': 1458,
    'North Las Vegas': 1458,
    'Nye County': 1458,
    'Pahrump': 1458,
    'Paradise': 1458,
    'Pershing County': 1458,
    'Pioche': 1458,
    'Reno': 1458,
    'Sandy Valley': 1458,
    'Silver Springs': 1458,
    'Smith': 1458,
    'Smith Valley': 1458,
    'Spanish Springs': 1458,
    'Sparks': 1458,
    'Spring Creek': 1458,
    'Spring Valley': 1458,
    'Stagecoach': 1458,
    'Storey County': 1458,
    'Summerlin South': 1458,
    'Sun Valley': 1458,
    'Sunrise Manor': 1458,
    'Tonopah': 1458,
    'Topaz Ranch Estates': 1458,
    'Verdi': 1458,
    'Virginia City': 1458,
    'Washoe County': 1458,
    'Wells': 1458,
    'West Wendover': 1458,
    'White Pine County': 1458,
    'Whitney': 1458,
    'Winchester': 1458,
    'Winnemucca': 1458,
    'Yerington': 1458,
    'Alexandria': 1404,
    'Alstead': 1404,
    'Andover': 1404,
    'Antrim': 1404,
    'Ashland': 1404,
    'Atkinson': 1404,
    'Auburn': 1404,
    'Barnstead': 1404,
    'Barrington': 1404,
    'Bedford': 1404,
    'Belknap County': 1404,
    'Belmont': 1404,
    'Berlin': 1404,
    'Boscawen': 1404,
    'Bow Bog': 1404,
    'Brentwood': 1404,
    'Bridgewater': 1404,
    'Bristol': 1404,
    'Brookline': 1404,
    'Candia': 1404,
    'Canterbury': 1404,
    'Carroll County': 1404,
    'Center Harbor': 1404,
    'Charlestown': 1404,
    'Cheshire County': 1404,
    'Chester': 1404,
    'Chesterfield': 1404,
    'Chichester': 1404,
    'Claremont': 1404,
    'Colebrook': 1404,
    'Concord': 1404,
    'Contoocook': 1404,
    'Conway': 1404,
    'Coos County': 1404,
    'Danbury': 1404,
    'Danville': 1404,
    'Deerfield': 1404,
    'Deering': 1404,
    'Derry': 1404,
    'Derry Village': 1404,
    'Dover': 1404,
    'Dublin': 1404,
    'Durham': 1404,
    'East Concord': 1404,
    'East Kingston': 1404,
    'East Merrimack': 1404,
    'Effingham': 1404,
    'Enfield': 1404,
    'Epping': 1404,
    'Epsom': 1404,
    'Exeter': 1404,
    'Farmington': 1404,
    'Fitzwilliam': 1404,
    'Francestown': 1404,
    'Franklin': 1404,
    'Freedom': 1404,
    'Fremont': 1404,
    'Gilford': 1404,
    'Gilmanton': 1404,
    'Goffstown': 1404,
    'Gorham': 1404,
    'Grafton': 1404,
    'Grafton County': 1404,
    'Grantham': 1404,
    'Greenfield': 1404,
    'Greenland': 1404,
    'Greenville': 1404,
    'Groveton': 1404,
    'Hampstead': 1404,
    'Hampton': 1404,
    'Hampton Beach': 1404,
    'Hampton Falls': 1404,
    'Hanover': 1404,
    'Harrisville': 1404,
    'Haverhill': 1404,
    'Henniker': 1404,
    'Hill': 1404,
    'Hillsborough': 1404,
    'Hillsborough County': 1404,
    'Hinsdale': 1404,
    'Holderness': 1404,
    'Hollis': 1404,
    'Hooksett': 1404,
    'Hopkinton': 1404,
    'Hudson': 1404,
    'Jaffrey': 1404,
    'Jefferson': 1404,
    'Keene': 1404,
    'Kensington': 1404,
    'Kingston': 1404,
    'Laconia': 1404,
    'Lancaster': 1404,
    'Lebanon': 1404,
    'Lee': 1404,
    'Lempster': 1404,
    'Litchfield': 1404,
    'Littleton': 1404,
    'Londonderry': 1404,
    'Lyme': 1404,
    'Lyndeborough': 1404,
    'Madbury': 1404,
    'Madison': 1404,
    'Manchester': 1404,
    'Marlborough': 1404,
    'Mason': 1404,
    'Meredith': 1404,
    'Merrimack': 1404,
    'Merrimack County': 1404,
    'Milan': 1404,
    'Milford': 1404,
    'Mont Vernon': 1404,
    'Moultonborough': 1404,
    'Nashua': 1404,
    'New Boston': 1404,
    'New Castle': 1404,
    'New Durham': 1404,
    'New Ipswich': 1404,
    'New London': 1404,
    'Newbury': 1404,
    'Newmarket': 1404,
    'Newport': 1404,
    'Newton': 1404,
    'North Conway': 1404,
    'North Hampton': 1404,
    'Northfield': 1404,
    'Northumberland': 1404,
    'Northwood': 1404,
    'Nottingham': 1404,
    'Orford': 1404,
    'Ossipee': 1404,
    'Pelham': 1404,
    'Pembroke': 1404,
    'Peterborough': 1404,
    'Pinardville': 1404,
    'Pittsfield': 1404,
    'Plaistow': 1404,
    'Plymouth': 1404,
    'Portsmouth': 1404,
    'Raymond': 1404,
    'Richmond': 1404,
    'Rindge': 1404,
    'Rochester': 1404,
    'Rockingham County': 1404,
    'Rollinsford': 1404,
    'Rumney': 1404,
    'Rye': 1404,
    'Salem': 1404,
    'Salisbury': 1404,
    'Sanbornton': 1404,
    'Sanbornville': 1404,
    'Sandown': 1404,
    'Sandwich': 1404,
    'Seabrook': 1404,
    'Somersworth': 1404,
    'South Hooksett': 1404,
    'Springfield': 1404,
    'Strafford': 1404,
    'Strafford County': 1404,
    'Stratford': 1404,
    'Stratham Station': 1404,
    'Sullivan County': 1404,
    'Sunapee': 1404,
    'Suncook': 1404,
    'Sutton': 1404,
    'Swanzey': 1404,
    'Tamworth': 1404,
    'Temple': 1404,
    'Thornton': 1404,
    'Tilton': 1404,
    'Tilton-Northfield': 1404,
    'Troy': 1404,
    'Tuftonboro': 1404,
    'Unity': 1404,
    'Wakefield': 1404,
    'Weare': 1404,
    'Webster': 1404,
    'West Swanzey': 1404,
    'Westmoreland': 1404,
    'Whitefield': 1404,
    'Wilmot': 1404,
    'Wilton': 1404,
    'Winchester': 1404,
    'Windham': 1404,
    'Wolfeboro': 1404,
    'Woodstock': 1404,
    'Woodsville': 1404,
    'Absecon': 1417,
    'Allendale': 1417,
    'Allentown': 1417,
    'Alloway': 1417,
    'Alpha': 1417,
    'Alpine': 1417,
    'Annandale': 1417,
    'Asbury Park': 1417,
    'Ashland': 1417,
    'Atco': 1417,
    'Atlantic City': 1417,
    'Atlantic County': 1417,
    'Atlantic Highlands': 1417,
    'Audubon': 1417,
    'Audubon Park': 1417,
    'Avalon': 1417,
    'Avenel': 1417,
    'Avon-by-the-Sea': 1417,
    'Barnegat': 1417,
    'Barrington': 1417,
    'Bayonne': 1417,
    'Bayville': 1417,
    'Beach Haven': 1417,
    'Beach Haven West': 1417,
    'Beachwood': 1417,
    'Beattystown': 1417,
    'Beckett': 1417,
    'Bedminster': 1417,
    'Belford': 1417,
    'Belleville': 1417,
    'Bellmawr': 1417,
    'Belmar': 1417,
    'Belvidere': 1417,
    'Bergen County': 1417,
    'Bergenfield': 1417,
    'Berkeley Heights': 1417,
    'Berlin': 1417,
    'Bernardsville': 1417,
    'Beverly': 1417,
    'Blackwood': 1417,
    'Bloomfield': 1417,
    'Bloomingdale': 1417,
    'Bogota': 1417,
    'Boonton': 1417,
    'Bordentown': 1417,
    'Bound Brook': 1417,
    'Bradley Beach': 1417,
    'Bradley Gardens': 1417,
    'Brass Castle': 1417,
    'Bridgeton': 1417,
    'Bridgewater': 1417,
    'Brielle': 1417,
    'Brigantine': 1417,
    'Brookdale': 1417,
    'Brooklawn': 1417,
    'Browns Mills': 1417,
    'Brownville': 1417,
    'Budd Lake': 1417,
    'Buena': 1417,
    'Burlington': 1417,
    'Burlington County': 1417,
    'Butler': 1417,
    'Caldwell': 1417,
    'Califon': 1417,
    'Camden': 1417,
    'Camden County': 1417,
    'Cape May': 1417,
    'Cape May County': 1417,
    'Cape May Court House': 1417,
    'Carlstadt': 1417,
    'Carneys Point': 1417,
    'Carteret': 1417,
    'Cedar Glen Lakes': 1417,
    'Cedar Glen West': 1417,
    'Cedar Grove': 1417,
    'Chatham': 1417,
    'Cherry Hill': 1417,
    'Cherry Hill Mall': 1417,
    'Chesilhurst': 1417,
    'Chester': 1417,
    'Cinnaminson': 1417,
    'Clark': 1417,
    'Clayton': 1417,
    'Clearbrook Park': 1417,
    'Clementon': 1417,
    'Cliffside Park': 1417,
    'Cliffwood Beach': 1417,
    'Clifton': 1417,
    'Clinton': 1417,
    'Closter': 1417,
    'Collings Lakes': 1417,
    'Collingswood': 1417,
    'Colonia': 1417,
    'Concordia': 1417,
    'Country Lake Estates': 1417,
    'Cranbury': 1417,
    'Crandon Lakes': 1417,
    'Cranford': 1417,
    'Cresskill': 1417,
    'Crestwood Village': 1417,
    'Cumberland County': 1417,
    'Dayton': 1417,
    'Delanco': 1417,
    'Demarest': 1417,
    'Dover': 1417,
    'Dover Beaches North': 1417,
    'Dover Beaches South': 1417,
    'Dumont': 1417,
    'Dunellen': 1417,
    'East Brunswick': 1417,
    'East Franklin': 1417,
    'East Freehold': 1417,
    'East Hanover': 1417,
    'East Newark': 1417,
    'East Orange': 1417,
    'East Rutherford': 1417,
    'Eatontown': 1417,
    'Echelon': 1417,
    'Edgewater': 1417,
    'Edgewater Park': 1417,
    'Edison': 1417,
    'Egg Harbor City': 1417,
    'Elizabeth': 1417,
    'Ellisburg': 1417,
    'Elmer': 1417,
    'Elmwood Park': 1417,
    'Elwood': 1417,
    'Emerson': 1417,
    'Englewood': 1417,
    'Englewood Cliffs': 1417,
    'Englishtown': 1417,
    'Erma': 1417,
    'Essex County': 1417,
    'Essex Fells': 1417,
    'Estell Manor': 1417,
    'Ewing': 1417,
    'Fair Haven': 1417,
    'Fair Lawn': 1417,
    'Fairfield': 1417,
    'Fairton': 1417,
    'Fairview': 1417,
    'Fanwood': 1417,
    'Farmingdale': 1417,
    'Finderne': 1417,
    'Flemington': 1417,
    'Florence': 1417,
    'Florham Park': 1417,
    'Folsom': 1417,
    'Fords': 1417,
    'Forked River': 1417,
    'Fort Dix': 1417,
    'Fort Lee': 1417,
    'Franklin': 1417,
    'Franklin Center': 1417,
    'Franklin Lakes': 1417,
    'Franklin Park': 1417,
    'Freehold': 1417,
    'Frenchtown': 1417,
    'Garfield': 1417,
    'Garwood': 1417,
    'Gibbsboro': 1417,
    'Gibbstown': 1417,
    'Gladstone': 1417,
    'Glassboro': 1417,
    'Glen Gardner': 1417,
    'Glen Ridge': 1417,
    'Glen Rock': 1417,
    'Glendora': 1417,
    'Gloucester City': 1417,
    'Gloucester County': 1417,
    'Golden Triangle': 1417,
    'Green Knoll': 1417,
    'Greentree': 1417,
    'Groveville': 1417,
    'Guttenberg': 1417,
    'Hackensack': 1417,
    'Hackettstown': 1417,
    'Haddon Heights': 1417,
    'Haddonfield': 1417,
    'Haledon': 1417,
    'Hamburg': 1417,
    'Hamilton Square': 1417,
    'Hammonton': 1417,
    'Hampton': 1417,
    'Hanover': 1417,
    'Hardwick': 1417,
    'Harrington Park': 1417,
    'Harrison': 1417,
    'Hasbrouck Heights': 1417,
    'Haworth': 1417,
    'Hawthorne': 1417,
    'Heathcote': 1417,
    'Helmetta': 1417,
    'High Bridge': 1417,
    'Highland Lake': 1417,
    'Highland Park': 1417,
    'Highlands': 1417,
    'Hightstown': 1417,
    'Hillsdale': 1417,
    'Hillside': 1417,
    'Ho-Ho-Kus': 1417,
    'Hoboken': 1417,
    'Holiday City South': 1417,
    'Holiday City-Berkeley': 1417,
    'Holiday Heights': 1417,
    'Hopatcong': 1417,
    'Hopatcong Hills': 1417,
    'Hopewell': 1417,
    'Hudson County': 1417,
    'Hunterdon County': 1417,
    'Irvington': 1417,
    'Iselin': 1417,
    'Island Heights': 1417,
    'Jackson': 1417,
    'Jamesburg': 1417,
    'Jersey City': 1417,
    'Keansburg': 1417,
    'Kearny': 1417,
    'Kendall Park': 1417,
    'Kenilworth': 1417,
    'Kenvil': 1417,
    'Keyport': 1417,
    'Kingston': 1417,
    'Kingston Estates': 1417,
    'Kinnelon': 1417,
    'Lake Como': 1417,
    'Lake Mohawk': 1417,
    'Lake Telemark': 1417,
    'Lakehurst': 1417,
    'Lakewood': 1417,
    'Lambertville': 1417,
    'Landing': 1417,
    'Laurel Lake': 1417,
    'Laurel Springs': 1417,
    'Laurence Harbor': 1417,
    'Lavallette': 1417,
    'Lawnside': 1417,
    'Lawrenceville': 1417,
    'Lebanon': 1417,
    'Leisure Knoll': 1417,
    'Leisure Village': 1417,
    'Leisure Village East': 1417,
    'Leisure Village West-Pine Lake Park': 1417,
    'Leisuretowne': 1417,
    'Leonardo': 1417,
    'Leonia': 1417,
    'Lincoln Park': 1417,
    'Lincroft': 1417,
    'Linden': 1417,
    'Lindenwold': 1417,
    'Linwood': 1417,
    'Little Falls': 1417,
    'Little Ferry': 1417,
    'Little Silver': 1417,
    'Livingston': 1417,
    'Lodi': 1417,
    'Long Branch': 1417,
    'Long Valley': 1417,
    'Lyndhurst': 1417,
    'Madison': 1417,
    'Madison Park': 1417,
    'Magnolia': 1417,
    'Mahwah': 1417,
    'Manahawkin': 1417,
    'Manasquan': 1417,
    'Manville': 1417,
    'Maple Shade': 1417,
    'Maplewood': 1417,
    'Margate City': 1417,
    'Marlboro': 1417,
    'Marlton': 1417,
    'Martinsville': 1417,
    'Matawan': 1417,
    'Mays Landing': 1417,
    'Maywood': 1417,
    'McGuire AFB': 1417,
    'Medford Lakes': 1417,
    'Mendham': 1417,
    'Mercer County': 1417,
    'Mercerville': 1417,
    'Mercerville-Hamilton Square': 1417,
    'Merchantville': 1417,
    'Metuchen': 1417,
    'Middlebush': 1417,
    'Middlesex': 1417,
    'Middlesex County': 1417,
    'Midland Park': 1417,
    'Milford': 1417,
    'Milltown': 1417,
    'Millville': 1417,
    'Monmouth Beach': 1417,
    'Monmouth County': 1417,
    'Monmouth Junction': 1417,
    'Montclair': 1417,
    'Montvale': 1417,
    'Moonachie': 1417,
    'Moorestown-Lenola': 1417,
    'Morganville': 1417,
    'Morris County': 1417,
    'Morris Plains': 1417,
    'Morristown': 1417,
    'Mount Arlington': 1417,
    'Mount Ephraim': 1417,
    'Mount Holly': 1417,
    'Mount Laurel': 1417,
    'Mountain Lakes': 1417,
    'Mountainside': 1417,
    'Mullica Hill': 1417,
    'Mystic Island': 1417,
    'National Park': 1417,
    'Navesink': 1417,
    'Neptune City': 1417,
    'Netcong': 1417,
    'New Brunswick': 1417,
    'New Egypt': 1417,
    'New Milford': 1417,
    'New Providence': 1417,
    'Newark': 1417,
    'Newfield': 1417,
    'Newton': 1417,
    'North Arlington': 1417,
    'North Beach Haven': 1417,
    'North Bergen': 1417,
    'North Caldwell': 1417,
    'North Cape May': 1417,
    'North Haledon': 1417,
    'North Middletown': 1417,
    'North Plainfield': 1417,
    'North Wildwood': 1417,
    'Northfield': 1417,
    'Northvale': 1417,
    'Norwood': 1417,
    'Nutley': 1417,
    'Oak Valley': 1417,
    'Oakhurst': 1417,
    'Oakland': 1417,
    'Oaklyn': 1417,
    'Ocean Acres': 1417,
    'Ocean City': 1417,
    'Ocean County': 1417,
    'Ocean Gate': 1417,
    'Ocean Grove': 1417,
    'Oceanport': 1417,
    'Ogdensburg': 1417,
    'Old Bridge': 1417,
    'Old Tappan': 1417,
    'Olivet': 1417,
    'Oradell': 1417,
    'Orange': 1417,
    'Oxford': 1417,
    'Palisades Park': 1417,
    'Palmyra': 1417,
    'Paramus': 1417,
    'Park Ridge': 1417,
    'Parsippany': 1417,
    'Passaic': 1417,
    'Passaic County': 1417,
    'Paterson': 1417,
    'Paulsboro': 1417,
    'Peapack': 1417,
    'Pemberton': 1417,
    'Pemberton Heights': 1417,
    'Pennington': 1417,
    'Penns Grove': 1417,
    'Pennsauken': 1417,
    'Pennsville': 1417,
    'Perth Amboy': 1417,
    'Phillipsburg': 1417,
    'Pine Beach': 1417,
    'Pine Hill': 1417,
    'Pine Lake Park': 1417,
    'Pine Ridge at Crestwood': 1417,
    'Piscataway': 1417,
    'Pitman': 1417,
    'Plainfield': 1417,
    'Plainsboro Center': 1417,
    'Pleasantville': 1417,
    'Point Pleasant': 1417,
    'Point Pleasant Beach': 1417,
    'Pomona': 1417,
    'Pompton Lakes': 1417,
    'Port Monmouth': 1417,
    'Port Norris': 1417,
    'Port Reading': 1417,
    'Port Republic': 1417,
    'Presidential Lakes Estates': 1417,
    'Princeton': 1417,
    'Princeton Junction': 1417,
    'Princeton Meadows': 1417,
    'Prospect Park': 1417,
    'Rahway': 1417,
    'Ramblewood': 1417,
    'Ramsey': 1417,
    'Ramtown': 1417,
    'Randolph': 1417,
    'Raritan': 1417,
    'Red Bank': 1417,
    'Richwood': 1417,
    'Ridgefield': 1417,
    'Ridgefield Park': 1417,
    'Ridgewood': 1417,
    'Ringwood': 1417,
    'Rio Grande': 1417,
    'River Edge': 1417,
    'River Vale': 1417,
    'Riverdale': 1417,
    'Riverton': 1417,
    'Robbinsville': 1417,
    'Robertsville': 1417,
    'Rochelle Park': 1417,
    'Rockaway': 1417,
    'Roebling': 1417,
    'Roseland': 1417,
    'Roselle': 1417,
    'Roselle Park': 1417,
    'Rosenhayn': 1417,
    'Rossmoor': 1417,
    'Rumson': 1417,
    'Runnemede': 1417,
    'Rutherford': 1417,
    'Saddle Brook': 1417,
    'Saddle River': 1417,
    'Salem': 1417,
    'Salem County': 1417,
    'Sayreville': 1417,
    'Sayreville Junction': 1417,
    'Scotch Plains': 1417,
    'Sea Bright': 1417,
    'Sea Girt': 1417,
    'Sea Isle City': 1417,
    'Seabrook Farms': 1417,
    'Seaside Heights': 1417,
    'Seaside Park': 1417,
    'Secaucus': 1417,
    'Sewaren': 1417,
    'Shark River Hills': 1417,
    'Ship Bottom': 1417,
    'Short Hills': 1417,
    'Shrewsbury': 1417,
    'Sicklerville': 1417,
    'Silver Ridge': 1417,
    'Singac': 1417,
    'Sixmile Run': 1417,
    'Smithville': 1417,
    'Society Hill': 1417,
    'Somerdale': 1417,
    'Somers Point': 1417,
    'Somerset': 1417,
    'Somerset County': 1417,
    'Somerville': 1417,
    'South Amboy': 1417,
    'South Belmar': 1417,
    'South Bound Brook': 1417,
    'South Old Bridge': 1417,
    'South Orange': 1417,
    'South Plainfield': 1417,
    'South River': 1417,
    'South Toms River': 1417,
    'South Vineland': 1417,
    'Sparta': 1417,
    'Spotswood': 1417,
    'Spring Lake': 1417,
    'Spring Lake Heights': 1417,
    'Springdale': 1417,
    'Springfield': 1417,
    'Stanhope': 1417,
    'Stratford': 1417,
    'Strathmore': 1417,
    'Succasunna': 1417,
    'Summit': 1417,
    'Surf City': 1417,
    'Sussex': 1417,
    'Sussex County': 1417,
    'Swedesboro': 1417,
    'Teaneck': 1417,
    'Ten Mile Run': 1417,
    'Tenafly': 1417,
    'Tinton Falls': 1417,
    'Toms River': 1417,
    'Totowa': 1417,
    'Trenton': 1417,
    'Tuckerton': 1417,
    'Turnersville': 1417,
    'Twin Rivers': 1417,
    'Union': 1417,
    'Union Beach': 1417,
    'Union City': 1417,
    'Union County': 1417,
    'Upper Montclair': 1417,
    'Upper Pohatcong': 1417,
    'Upper Saddle River': 1417,
    'Ventnor City': 1417,
    'Vernon Center': 1417,
    'Vernon Valley': 1417,
    'Verona': 1417,
    'Victory Gardens': 1417,
    'Victory Lakes': 1417,
    'Villas': 1417,
    'Vincentown': 1417,
    'Vineland': 1417,
    'Vista Center': 1417,
    'Voorhees': 1417,
    'Waldwick': 1417,
    'Wallington': 1417,
    'Wanamassa': 1417,
    'Wanaque': 1417,
    'Waretown': 1417,
    'Warren County': 1417,
    'Warren Township': 1417,
    'Washington': 1417,
    'Watchung': 1417,
    'Wayne': 1417,
    'Weehawken': 1417,
    'Wenonah': 1417,
    'West Belmar': 1417,
    'West Cape May': 1417,
    'West Freehold': 1417,
    'West Long Branch': 1417,
    'West Milford': 1417,
    'West New York': 1417,
    'West Orange': 1417,
    'Westfield': 1417,
    'Weston': 1417,
    'Westville': 1417,
    'Westwood': 1417,
    'Wharton': 1417,
    'White Horse': 1417,
    'White Meadow Lake': 1417,
    'Whitehouse Station': 1417,
    'Whitesboro': 1417,
    'Whitesboro-Burleigh': 1417,
    'Whittingham': 1417,
    'Wildwood': 1417,
    'Wildwood Crest': 1417,
    'Williamstown': 1417,
    'Willingboro': 1417,
    'Winfield': 1417,
    'Wood-Lynne': 1417,
    'Wood-Ridge': 1417,
    'Woodbine': 1417,
    'Woodbridge': 1417,
    'Woodbury': 1417,
    'Woodbury Heights': 1417,
    'Woodcliff Lake': 1417,
    'Woodland Park': 1417,
    'Woodstown': 1417,
    'Wyckoff': 1417,
    'Yardville': 1417,
    'Yorketown': 1417,
    'Agua Fria': 1423,
    'Alamo': 1423,
    'Alamogordo': 1423,
    'Albuquerque': 1423,
    'Angel Fire': 1423,
    'Anthony': 1423,
    'Arenas Valley': 1423,
    'Arroyo Seco': 1423,
    'Artesia': 1423,
    'Atoka': 1423,
    'Aztec': 1423,
    'Bayard': 1423,
    'Belen': 1423,
    'Berino': 1423,
    'Bernalillo': 1423,
    'Bernalillo County': 1423,
    'Black Rock': 1423,
    'Bloomfield': 1423,
    'Boles Acres': 1423,
    'Bosque Farms': 1423,
    'Cannon Air Force Base': 1423,
    'Capitan': 1423,
    'Carlsbad': 1423,
    'Carnuel': 1423,
    'Carrizozo': 1423,
    'Catron County': 1423,
    'Chama': 1423,
    'Chaparral': 1423,
    'Chaves County': 1423,
    'Chimayo': 1423,
    'Church Rock': 1423,
    'Cibola County': 1423,
    'Clayton': 1423,
    'Clovis': 1423,
    'Colfax County': 1423,
    'Columbus': 1423,
    'Corrales': 1423,
    'Crownpoint': 1423,
    'Curry County': 1423,
    'De Baca County': 1423,
    'Deming': 1423,
    'Dexter': 1423,
    'Doña Ana': 1423,
    'Doña Ana County': 1423,
    'Dulce': 1423,
    'Eddy County': 1423,
    'Edgewood': 1423,
    'El Cerro': 1423,
    'El Cerro Mission': 1423,
    'El Rancho': 1423,
    'El Valle de Arroyo Seco': 1423,
    'Eldorado at Santa Fe': 1423,
    'Elephant Butte': 1423,
    'Enchanted Hills': 1423,
    'Española': 1423,
    'Estancia': 1423,
    'Eunice': 1423,
    'Farmington': 1423,
    'Flora Vista': 1423,
    'Fort Sumner': 1423,
    'Gallup': 1423,
    'Grant County': 1423,
    'Grants': 1423,
    'Guadalupe County': 1423,
    'Hagerman': 1423,
    'Harding County': 1423,
    'Hatch': 1423,
    'Hidalgo County': 1423,
    'Hobbs': 1423,
    'Holloman Air Force Base': 1423,
    'Hurley': 1423,
    'Jal': 1423,
    'Jarales': 1423,
    'Jemez Pueblo': 1423,
    'Keeler Farm': 1423,
    'Kirtland': 1423,
    'La Cienega': 1423,
    'La Huerta': 1423,
    'La Luz': 1423,
    'La Mesilla': 1423,
    'La Puebla': 1423,
    'La Union': 1423,
    'Laguna': 1423,
    'Las Cruces': 1423,
    'Las Maravillas': 1423,
    'Las Vegas': 1423,
    'Lea County': 1423,
    'Lee Acres': 1423,
    'Lincoln County': 1423,
    'Lordsburg': 1423,
    'Los Alamos': 1423,
    'Los Alamos County': 1423,
    'Los Chavez': 1423,
    'Los Lunas': 1423,
    'Los Ranchos de Albuquerque': 1423,
    'Loving': 1423,
    'Lovington': 1423,
    'Luna County': 1423,
    'McIntosh': 1423,
    'McKinley County': 1423,
    'Meadow Lake': 1423,
    'Mescalero': 1423,
    'Mesilla': 1423,
    'Mesquite': 1423,
    'Milan': 1423,
    'Monterey Park': 1423,
    'Mora': 1423,
    'Mora County': 1423,
    'Moriarty': 1423,
    'Mosquero': 1423,
    'Nambe': 1423,
    'Navajo': 1423,
    'North Valley': 1423,
    'Ohkay Owingeh': 1423,
    'Otero County': 1423,
    'Paradise Hills': 1423,
    'Pecos': 1423,
    'Peralta': 1423,
    'Placitas': 1423,
    'Pojoaque': 1423,
    'Ponderosa Pine': 1423,
    'Portales': 1423,
    'Quay County': 1423,
    'Questa': 1423,
    'Radium Springs': 1423,
    'Ranchos de Taos': 1423,
    'Raton': 1423,
    'Reserve': 1423,
    'Rio Arriba County': 1423,
    'Rio Communities': 1423,
    'Rio Rancho': 1423,
    'Roosevelt County': 1423,
    'Roswell': 1423,
    'Ruidoso': 1423,
    'Ruidoso Downs': 1423,
    'San Felipe Pueblo': 1423,
    'San Juan County': 1423,
    'San Miguel': 1423,
    'San Miguel County': 1423,
    'San Ysidro': 1423,
    'Sandia Heights': 1423,
    'Sandia Knolls': 1423,
    'Sandoval County': 1423,
    'Santa Clara': 1423,
    'Santa Clara Pueblo': 1423,
    'Santa Fe': 1423,
    'Santa Fe County': 1423,
    'Santa Rosa': 1423,
    'Santa Teresa': 1423,
    'Santo Domingo Pueblo': 1423,
    'Shiprock': 1423,
    'Sierra County': 1423,
    'Silver City': 1423,
    'Skyline-Ganipa': 1423,
    'Socorro': 1423,
    'Socorro County': 1423,
    'South Valley': 1423,
    'Spencerville': 1423,
    'Sunland Park': 1423,
    'Taos': 1423,
    'Taos County': 1423,
    'Taos Pueblo': 1423,
    'Texico': 1423,
    'Thoreau': 1423,
    'Tierra Amarilla': 1423,
    'Tome': 1423,
    'Torrance County': 1423,
    'Truth or Consequences': 1423,
    'Tucumcari': 1423,
    'Tularosa': 1423,
    'Twin Lakes': 1423,
    'Union County': 1423,
    'University Park': 1423,
    'Upper Fruitland': 1423,
    'Vado': 1423,
    'Valencia': 1423,
    'Valencia County': 1423,
    'Waterflow': 1423,
    'West Hammond': 1423,
    'White Rock': 1423,
    'White Sands': 1423,
    'Zuni Pueblo': 1423,
    'Adams': 1452,
    'Adams Center': 1452,
    'Addison': 1452,
    'Airmont': 1452,
    'Akron': 1452,
    'Alabama': 1452,
    'Albany': 1452,
    'Albany County': 1452,
    'Albertson': 1452,
    'Albion': 1452,
    'Alden': 1452,
    'Alexandria Bay': 1452,
    'Alfred': 1452,
    'Allegany': 1452,
    'Allegany County': 1452,
    'Altamont': 1452,
    'Amagansett': 1452,
    'Amherst': 1452,
    'Amityville': 1452,
    'Amsterdam': 1452,
    'Andover': 1452,
    'Angola': 1452,
    'Angola on the Lake': 1452,
    'Apalachin': 1452,
    'Aquebogue': 1452,
    'Arcade': 1452,
    'Ardsley': 1452,
    'Arlington': 1452,
    'Armonk': 1452,
    'Arrochar': 1452,
    'Arverne': 1452,
    'Astoria': 1452,
    'Athens': 1452,
    'Atlantic Beach': 1452,
    'Attica': 1452,
    'Auburn': 1452,
    'Augusta': 1452,
    'Averill Park': 1452,
    'Avon': 1452,
    'Babylon': 1452,
    'Bainbridge': 1452,
    'Baiting Hollow': 1452,
    'Baldwin': 1452,
    'Baldwin Harbor': 1452,
    'Baldwinsville': 1452,
    'Ballston Spa': 1452,
    'Balmville': 1452,
    'Bardonia': 1452,
    'Barnum Island': 1452,
    'Batavia': 1452,
    'Bath': 1452,
    'Bath Beach': 1452,
    'Baxter Estates': 1452,
    'Bay Park': 1452,
    'Bay Shore': 1452,
    'Bay Wood': 1452,
    'Baychester': 1452,
    'Bayport': 1452,
    'Bayside': 1452,
    'Bayville': 1452,
    'Beacon': 1452,
    'Beaver Dam Lake': 1452,
    'Beaverdam Lake-Salisbury Mills': 1452,
    'Bedford': 1452,
    'Bedford Hills': 1452,
    'Bellaire': 1452,
    'Belle Harbor': 1452,
    'Bellerose': 1452,
    'Bellerose Terrace': 1452,
    'Bellmore': 1452,
    'Bellport': 1452,
    'Belmont': 1452,
    'Bensonhurst': 1452,
    'Bergen': 1452,
    'Bergen Beach': 1452,
    'Bethpage': 1452,
    'Big Flats': 1452,
    'Billington Heights': 1452,
    'Binghamton': 1452,
    'Black River': 1452,
    'Blasdell': 1452,
    'Blauvelt': 1452,
    'Bloomfield': 1452,
    'Blue Point': 1452,
    'Bohemia': 1452,
    'Bolivar': 1452,
    'Boonville': 1452,
    'Borough Park': 1452,
    'Boston': 1452,
    'Brentwood': 1452,
    'Brewerton': 1452,
    'Brewster': 1452,
    'Brewster Hill': 1452,
    'Briarcliff Manor': 1452,
    'Briarwood': 1452,
    'Bridgehampton': 1452,
    'Bridgeport': 1452,
    'Brighton': 1452,
    'Brighton Beach': 1452,
    'Brightwaters': 1452,
    'Brinckerhoff': 1452,
    'Broad Channel': 1452,
    'Broadalbin': 1452,
    'Brockport': 1452,
    'Brocton': 1452,
    'Bronx': 1452,
    'Bronxville': 1452,
    'Brookhaven': 1452,
    'Brooklyn': 1452,
    'Brooklyn Heights': 1452,
    'Brookville': 1452,
    'Broome County': 1452,
    'Brownsville': 1452,
    'Brownville': 1452,
    'Buchanan': 1452,
    'Buffalo': 1452,
    'Bushwick': 1452,
    'Cairo': 1452,
    'Calcium': 1452,
    'Caledonia': 1452,
    'Calverton': 1452,
    'Cambria Heights': 1452,
    'Cambridge': 1452,
    'Camden': 1452,
    'Camillus': 1452,
    'Canajoharie': 1452,
    'Canandaigua': 1452,
    'Canarsie': 1452,
    'Canastota': 1452,
    'Canisteo': 1452,
    'Canton': 1452,
    'Carle Place': 1452,
    'Carmel': 1452,
    'Carmel Hamlet': 1452,
    'Carthage': 1452,
    'Castleton-on-Hudson': 1452,
    'Catskill': 1452,
    'Cattaraugus County': 1452,
    'Cayuga County': 1452,
    'Cayuga Heights': 1452,
    'Cazenovia': 1452,
    'Cedarhurst': 1452,
    'Celoron': 1452,
    'Center Moriches': 1452,
    'Centereach': 1452,
    'Centerport': 1452,
    'Central Islip': 1452,
    'Central Square': 1452,
    'Central Valley': 1452,
    'Chadwicks': 1452,
    'Champlain': 1452,
    'Chappaqua': 1452,
    'Charleston': 1452,
    'Chatham': 1452,
    'Chautauqua County': 1452,
    'Cheektowaga': 1452,
    'Chelsea': 1452,
    'Chemung County': 1452,
    'Chenango Bridge': 1452,
    'Chenango County': 1452,
    'Chester': 1452,
    'Chestnut Ridge': 1452,
    'Chittenango': 1452,
    'Churchville': 1452,
    'City Island': 1452,
    'Clarence': 1452,
    'Clarence Center': 1452,
    'Clark Mills': 1452,
    'Clarkson': 1452,
    'Clayton': 1452,
    'Clifton': 1452,
    'Clifton Springs': 1452,
    'Clinton': 1452,
    'Clinton County': 1452,
    'Clintondale': 1452,
    'Clyde': 1452,
    'Clymer': 1452,
    'Cobleskill': 1452,
    'Cohoes': 1452,
    'Cold Spring': 1452,
    'Cold Spring Harbor': 1452,
    'College Point': 1452,
    'Colonie': 1452,
    'Columbia County': 1452,
    'Commack': 1452,
    'Concord': 1452,
    'Coney Island': 1452,
    'Congers': 1452,
    'Constantia': 1452,
    'Cooperstown': 1452,
    'Copiague': 1452,
    'Coram': 1452,
    'Corinth': 1452,
    'Corning': 1452,
    'Cornwall': 1452,
    'Corona': 1452,
    'Cortland': 1452,
    'Cortland County': 1452,
    'Cortland West': 1452,
    'Country Knolls': 1452,
    'Coxsackie': 1452,
    'Crompond': 1452,
    'Croton-on-Hudson': 1452,
    'Crown Heights': 1452,
    'Crugers': 1452,
    'Cuba': 1452,
    'Cumberland Head': 1452,
    'Cutchogue': 1452,
    'Cypress Hills': 1452,
    'Dannemora': 1452,
    'Dansville': 1452,
    'Deer Park': 1452,
    'Delaware County': 1452,
    'Delevan': 1452,
    'Delhi': 1452,
    'Delmar': 1452,
    'Depew': 1452,
    'Deposit': 1452,
    'Dexter': 1452,
    'Dix Hills': 1452,
    'Dobbs Ferry': 1452,
    'Dolgeville': 1452,
    'Dongan Hills': 1452,
    'Douglaston': 1452,
    'Dover Plains': 1452,
    'Dryden': 1452,
    'Dundee': 1452,
    'Dunkirk': 1452,
    'Durham': 1452,
    'Dutchess County': 1452,
    'Dyker Heights': 1452,
    'East Atlantic Beach': 1452,
    'East Aurora': 1452,
    'East Elmhurst': 1452,
    'East Farmingdale': 1452,
    'East Flatbush': 1452,
    'East Garden City': 1452,
    'East Glenville': 1452,
    'East Greenbush': 1452,
    'East Hampton': 1452,
    'East Hampton North': 1452,
    'East Harlem': 1452,
    'East Hills': 1452,
    'East Islip': 1452,
    'East Ithaca': 1452,
    'East Massapequa': 1452,
    'East Meadow': 1452,
    'East Moriches': 1452,
    'East New York': 1452,
    'East Northport': 1452,
    'East Norwich': 1452,
    'East Patchogue': 1452,
    'East Quogue': 1452,
    'East Rochester': 1452,
    'East Rockaway': 1452,
    'East Setauket': 1452,
    'East Shoreham': 1452,
    'East Syracuse': 1452,
    'East Tremont': 1452,
    'East Village': 1452,
    'East Williston': 1452,
    'Eastchester': 1452,
    'Eastport': 1452,
    'Eatons Neck': 1452,
    'Eden': 1452,
    'Edgemere': 1452,
    'Edinburg': 1452,
    'Eggertsville': 1452,
    'Elbridge': 1452,
    'Elizabethtown': 1452,
    'Ellenville': 1452,
    'Elma Center': 1452,
    'Elmhurst': 1452,
    'Elmira': 1452,
    'Elmira Heights': 1452,
    'Elmont': 1452,
    'Elmsford': 1452,
    'Eltingville': 1452,
    'Elwood': 1452,
    'Emerson Hill': 1452,
    'Endicott': 1452,
    'Endwell': 1452,
    'Erie County': 1452,
    'Essex County': 1452,
    'Fairmount': 1452,
    'Fairport': 1452,
    'Fairview': 1452,
    'Falconer': 1452,
    'Fallsburg': 1452,
    'Far Rockaway': 1452,
    'Farmingdale': 1452,
    'Farmingville': 1452,
    'Fayetteville': 1452,
    'Financial District': 1452,
    'Firthcliffe': 1452,
    'Fishkill': 1452,
    'Flanders': 1452,
    'Flatbush': 1452,
    'Flatlands': 1452,
    'Floral Park': 1452,
    'Florida': 1452,
    'Flower Hill': 1452,
    'Fonda': 1452,
    'Fordham': 1452,
    'Forest Hills': 1452,
    'Fort Covington Hamlet': 1452,
    'Fort Drum': 1452,
    'Fort Edward': 1452,
    'Fort Hamilton': 1452,
    'Fort Montgomery': 1452,
    'Fort Plain': 1452,
    'Fort Salonga': 1452,
    'Fort Wadsworth': 1452,
    'Frankfort': 1452,
    'Franklin County': 1452,
    'Franklin Square': 1452,
    'Franklinville': 1452,
    'Fredonia': 1452,
    'Freeport': 1452,
    'Fresh Meadows': 1452,
    'Frewsburg': 1452,
    'Friendship': 1452,
    'Fulton': 1452,
    'Fulton County': 1452,
    'Galeville': 1452,
    'Gang Mills': 1452,
    'Garden City': 1452,
    'Garden City Park': 1452,
    'Garden City South': 1452,
    'Gardnertown': 1452,
    'Gasport': 1452,
    'Gates-North Gates': 1452,
    'Genesee County': 1452,
    'Geneseo': 1452,
    'Geneva': 1452,
    'Glasco': 1452,
    'Glen Cove': 1452,
    'Glen Head': 1452,
    'Glen Oaks': 1452,
    'Glendale': 1452,
    'Glens Falls': 1452,
    'Glens Falls North': 1452,
    'Glenwood Landing': 1452,
    'Gloversville': 1452,
    'Goldens Bridge': 1452,
    'Gordon Heights': 1452,
    'Goshen': 1452,
    'Gouverneur': 1452,
    'Gowanda': 1452,
    'Gramercy Park': 1452,
    'Grand Island': 1452,
    'Grandyle Village': 1452,
    'Graniteville': 1452,
    'Grant City': 1452,
    'Granville': 1452,
    'Gravesend': 1452,
    'Great Kills': 1452,
    'Great Neck': 1452,
    'Great Neck Estates': 1452,
    'Great Neck Gardens': 1452,
    'Great Neck Plaza': 1452,
    'Great River': 1452,
    'Greece': 1452,
    'Green Island': 1452,
    'Greenburgh': 1452,
    'Greene': 1452,
    'Greene County': 1452,
    'Greenlawn': 1452,
    'Greenpoint': 1452,
    'Greenport': 1452,
    'Greenport West': 1452,
    'Greenvale': 1452,
    'Greenville': 1452,
    'Greenwich': 1452,
    'Greenwood Lake': 1452,
    'Groton': 1452,
    'Grymes Hill': 1452,
    'Hadley': 1452,
    'Hagaman': 1452,
    'Halesite': 1452,
    'Hamburg': 1452,
    'Hamilton': 1452,
    'Hamilton County': 1452,
    'Hamlin': 1452,
    'Hampton Bays': 1452,
    'Hampton Manor': 1452,
    'Hannawa Falls': 1452,
    'Harbor Isle': 1452,
    'Harlem': 1452,
    'Harriman': 1452,
    'Harris Hill': 1452,
    'Harrison': 1452,
    'Hartford': 1452,
    'Hartsdale': 1452,
    'Hastings-on-Hudson': 1452,
    'Hauppauge': 1452,
    'Haverstraw': 1452,
    'Haviland': 1452,
    'Hawthorne': 1452,
    'Head of the Harbor': 1452,
    'Hell  s Kitchen': 1452,
    'Hempstead': 1452,
    'Heritage Hills': 1452,
    'Herkimer': 1452,
    'Herkimer County': 1452,
    'Herricks': 1452,
    'Hewlett': 1452,
    'Hewlett Harbor': 1452,
    'Hicksville': 1452,
    'Highland': 1452,
    'Highland Falls': 1452,
    'Highland Mills': 1452,
    'Hillcrest': 1452,
    'Hillside': 1452,
    'Hillside Lake': 1452,
    'Hilton': 1452,
    'Holbrook': 1452,
    'Holcomb': 1452,
    'Holland': 1452,
    'Holley': 1452,
    'Hollis': 1452,
    'Holtsville': 1452,
    'Homer': 1452,
    'Honeoye Falls': 1452,
    'Hoosick Falls': 1452,
    'Hornell': 1452,
    'Horseheads': 1452,
    'Horseheads North': 1452,
    'Houghton': 1452,
    'Howard Beach': 1452,
    'Hudson': 1452,
    'Hudson Falls': 1452,
    'Huguenot': 1452,
    'Huntington': 1452,
    'Huntington Bay': 1452,
    'Huntington Station': 1452,
    'Hunts Point': 1452,
    'Hurley': 1452,
    'Hyde Park': 1452,
    'Ilion': 1452,
    'Inwood': 1452,
    'Irondequoit': 1452,
    'Irvington': 1452,
    'Island Park': 1452,
    'Islandia': 1452,
    'Islip': 1452,
    'Islip Terrace': 1452,
    'Ithaca': 1452,
    'Jackson Heights': 1452,
    'Jamaica': 1452,
    'Jamesport': 1452,
    'Jamestown': 1452,
    'Jamestown West': 1452,
    'Jefferson County': 1452,
    'Jefferson Heights': 1452,
    'Jefferson Valley-Yorktown': 1452,
    'Jericho': 1452,
    'Johnson City': 1452,
    'Johnstown': 1452,
    'Jordan': 1452,
    'Kaser': 1452,
    'Katonah': 1452,
    'Keeseville': 1452,
    'Kenmore': 1452,
    'Kensington': 1452,
    'Kerhonkson': 1452,
    'Keuka Park': 1452,
    'Kew Gardens': 1452,
    'Kew Gardens Hills': 1452,
    'Kiantone': 1452,
    'Kinderhook': 1452,
    'Kings Bridge': 1452,
    'Kings County': 1452,
    'Kings Park': 1452,
    'Kings Point': 1452,
    'Kingston': 1452,
    'Kiryas Joel': 1452,
    'Lackawanna': 1452,
    'Lake Carmel': 1452,
    'Lake Erie Beach': 1452,
    'Lake Grove': 1452,
    'Lake Katrine': 1452,
    'Lake Luzerne': 1452,
    'Lake Mohegan': 1452,
    'Lake Placid': 1452,
    'Lake Pleasant': 1452,
    'Lake Ronkonkoma': 1452,
    'Lake Success': 1452,
    'Lakeland': 1452,
    'Lakeview': 1452,
    'Lakewood': 1452,
    'Lancaster': 1452,
    'Lansing': 1452,
    'Larchmont': 1452,
    'Latham': 1452,
    'Lattingtown': 1452,
    'Laurel': 1452,
    'Laurel Hollow': 1452,
    'Laurelton': 1452,
    'Lawrence': 1452,
    'Le Roy': 1452,
    'Levittown': 1452,
    'Lewis County': 1452,
    'Lewiston': 1452,
    'Liberty': 1452,
    'Lido Beach': 1452,
    'Lima': 1452,
    'Lincoln Park': 1452,
    'Lincolndale': 1452,
    'Lindenhurst': 1452,
    'Lindley': 1452,
    'Little Falls': 1452,
    'Little Neck': 1452,
    'Little Valley': 1452,
    'Liverpool': 1452,
    'Livingston County': 1452,
    'Livingston Manor': 1452,
    'Livonia': 1452,
    'Lloyd Harbor': 1452,
    'Lockport': 1452,
    'Locust Valley': 1452,
    'Long Beach': 1452,
    'Long Island City': 1452,
    'Lorenz Park': 1452,
    'Lowville': 1452,
    'Lynbrook': 1452,
    'Lyncourt': 1452,
    'Lyons': 1452,
    'Macedon': 1452,
    'Madison County': 1452,
    'Mahopac': 1452,
    'Malone': 1452,
    'Malverne': 1452,
    'Mamaroneck': 1452,
    'Manchester': 1452,
    'Manhasset': 1452,
    'Manhasset Hills': 1452,
    'Manhattan': 1452,
    'Manlius': 1452,
    'Manorhaven': 1452,
    'Manorville': 1452,
    'Marbletown': 1452,
    'Marcellus': 1452,
    'Mariners Harbor': 1452,
    'Marion': 1452,
    'Marlboro': 1452,
    'Maspeth': 1452,
    'Massapequa': 1452,
    'Massapequa Park': 1452,
    'Massena': 1452,
    'Mastic': 1452,
    'Mastic Beach': 1452,
    'Mattituck': 1452,
    'Mattydale': 1452,
    'Maybrook': 1452,
    'Mayville': 1452,
    'McGraw': 1452,
    'McKownville': 1452,
    'Mechanicstown': 1452,
    'Mechanicville': 1452,
    'Medford': 1452,
    'Medina': 1452,
    'Melrose': 1452,
    'Melrose Park': 1452,
    'Melville': 1452,
    'Menands': 1452,
    'Merrick': 1452,
    'Merritt Park': 1452,
    'Mexico': 1452,
    'Middle Island': 1452,
    'Middle Village': 1452,
    'Middleburgh': 1452,
    'Middleport': 1452,
    'Middletown': 1452,
    'Midland Beach': 1452,
    'Mill Neck': 1452,
    'Millbrook': 1452,
    'Miller Place': 1452,
    'Milton': 1452,
    'Mineola': 1452,
    'Minetto': 1452,
    'Mineville': 1452,
    'Minoa': 1452,
    'Mohawk': 1452,
    'Monroe': 1452,
    'Monroe County': 1452,
    'Monsey': 1452,
    'Montauk': 1452,
    'Montebello': 1452,
    'Montgomery': 1452,
    'Montgomery County': 1452,
    'Monticello': 1452,
    'Montour Falls': 1452,
    'Montrose': 1452,
    'Moravia': 1452,
    'Moriches': 1452,
    'Morningside Heights': 1452,
    'Morris Heights': 1452,
    'Morris Park': 1452,
    'Morrisania': 1452,
    'Morrisonville': 1452,
    'Morrisville': 1452,
    'Mott Haven': 1452,
    'Mount Ivy': 1452,
    'Mount Kisco': 1452,
    'Mount Morris': 1452,
    'Mount Sinai': 1452,
    'Mount Vernon': 1452,
    'Mountain Lodge Park': 1452,
    'Munsey Park': 1452,
    'Munsons Corners': 1452,
    'Muttontown': 1452,
    'Myers Corner': 1452,
    'Nanuet': 1452,
    'Napanoch': 1452,
    'Naples': 1452,
    'Nassau': 1452,
    'Nassau County': 1452,
    'Nedrow': 1452,
    'Neponsit': 1452,
    'Nesconset': 1452,
    'New Brighton': 1452,
    'New Cassel': 1452,
    'New City': 1452,
    'New Dorp': 1452,
    'New Dorp Beach': 1452,
    'New Hartford': 1452,
    'New Hempstead': 1452,
    'New Hyde Park': 1452,
    'New Paltz': 1452,
    'New Rochelle': 1452,
    'New Springville': 1452,
    'New Square': 1452,
    'New Windsor': 1452,
    'New York City': 1452,
    'New York County': 1452,
    'New York Mills': 1452,
    'Newark': 1452,
    'Newburgh': 1452,
    'Newfane': 1452,
    'Niagara County': 1452,
    'Niagara Falls': 1452,
    'Niskayuna': 1452,
    'Nissequogue': 1452,
    'Niverville': 1452,
    'Norfolk': 1452,
    'North Amityville': 1452,
    'North Babylon': 1452,
    'North Ballston Spa': 1452,
    'North Bay Shore': 1452,
    'North Bellmore': 1452,
    'North Bellport': 1452,
    'North Boston': 1452,
    'North Castle': 1452,
    'North Collins': 1452,
    'North Elba': 1452,
    'North Gates': 1452,
    'North Great River': 1452,
    'North Hills': 1452,
    'North Lindenhurst': 1452,
    'North Massapequa': 1452,
    'North Merrick': 1452,
    'North New Hyde Park': 1452,
    'North Patchogue': 1452,
    'North Sea': 1452,
    'North Syracuse': 1452,
    'North Tonawanda': 1452,
    'North Valley Stream': 1452,
    'North Wantagh': 1452,
    'Northeast Ithaca': 1452,
    'Northport': 1452,
    'Northumberland': 1452,
    'Northville': 1452,
    'Northwest Harbor': 1452,
    'Northwest Ithaca': 1452,
    'Norwich': 1452,
    'Norwood': 1452,
    'Noyack': 1452,
    'Nunda': 1452,
    'Nyack': 1452,
    'Oakdale': 1452,
    'Oakfield': 1452,
    'Oakwood': 1452,
    'Oceanside': 1452,
    'Ogdensburg': 1452,
    'Olcott': 1452,
    'Old Bethpage': 1452,
    'Old Brookville': 1452,
    'Old Westbury': 1452,
    'Olean': 1452,
    'Oneida': 1452,
    'Oneida County': 1452,
    'Oneonta': 1452,
    'Onondaga County': 1452,
    'Ontario': 1452,
    'Ontario County': 1452,
    'Orange County': 1452,
    'Orange Lake': 1452,
    'Orangeburg': 1452,
    'Orchard Park': 1452,
    'Oriskany': 1452,
    'Orleans County': 1452,
    'Ossining': 1452,
    'Oswego': 1452,
    'Oswego County': 1452,
    'Otisville': 1452,
    'Otsego County': 1452,
    'Owego': 1452,
    'Oxford': 1452,
    'Oyster Bay': 1452,
    'Oyster Bay Cove': 1452,
    'Ozone Park': 1452,
    'Painted Post': 1452,
    'Palenville': 1452,
    'Palmyra': 1452,
    'Park Slope': 1452,
    'Parkchester': 1452,
    'Patchogue': 1452,
    'Pawling': 1452,
    'Peach Lake': 1452,
    'Pearl River': 1452,
    'Peekskill': 1452,
    'Pelham': 1452,
    'Pelham Manor': 1452,
    'Penn Yan': 1452,
    'Perry': 1452,
    'Perth': 1452,
    'Peru': 1452,
    'Phelps': 1452,
    'Philadelphia': 1452,
    'Philmont': 1452,
    'Phoenix': 1452,
    'Piermont': 1452,
    'Pine Bush': 1452,
    'Pine Plains': 1452,
    'Pittsford': 1452,
    'Plainedge': 1452,
    'Plainview': 1452,
    'Plandome': 1452,
    'Plandome Heights': 1452,
    'Plattekill': 1452,
    'Plattsburgh': 1452,
    'Plattsburgh West': 1452,
    'Pleasant Valley': 1452,
    'Pleasantville': 1452,
    'Poestenkill': 1452,
    'Point Lookout': 1452,
    'Pomona': 1452,
    'Port Byron': 1452,
    'Port Chester': 1452,
    'Port Dickinson': 1452,
    'Port Ewen': 1452,
    'Port Henry': 1452,
    'Port Jefferson': 1452,
    'Port Jefferson Station': 1452,
    'Port Jervis': 1452,
    'Port Morris': 1452,
    'Port Richmond': 1452,
    'Port Washington': 1452,
    'Port Washington North': 1452,
    'Portland': 1452,
    'Potsdam': 1452,
    'Poughkeepsie': 1452,
    'Pound Ridge': 1452,
    'Pulaski': 1452,
    'Purchase': 1452,
    'Putnam County': 1452,
    'Putnam Lake': 1452,
    'Queens': 1452,
    'Queens County': 1452,
    'Queens Village': 1452,
    'Queensbury': 1452,
    'Randolph': 1452,
    'Ransomville': 1452,
    'Rapids': 1452,
    'Ravena': 1452,
    'Red Hook': 1452,
    'Red Oaks Mill': 1452,
    'Rego Park': 1452,
    'Remsenburg-Speonk': 1452,
    'Rensselaer': 1452,
    'Rensselaer County': 1452,
    'Rhinebeck': 1452,
    'Richfield Springs': 1452,
    'Richland': 1452,
    'Richmond County': 1452,
    'Richmond Hill': 1452,
    'Ridge': 1452,
    'Ridgewood': 1452,
    'Riverdale': 1452,
    'Riverhead': 1452,
    'Riverside': 1452,
    'Rochester': 1452,
    'Rock Hill': 1452,
    'Rockaway Point': 1452,
    'Rockland County': 1452,
    'Rockville Centre': 1452,
    'Rocky Point': 1452,
    'Roessleville': 1452,
    'Rome': 1452,
    'Ronkonkoma': 1452,
    'Roosevelt': 1452,
    'Rosebank': 1452,
    'Rosedale': 1452,
    'Rosendale Village': 1452,
    'Roslyn': 1452,
    'Roslyn Estates': 1452,
    'Roslyn Harbor': 1452,
    'Roslyn Heights': 1452,
    'Rossville': 1452,
    'Rotterdam': 1452,
    'Rouses Point': 1452,
    'Rye': 1452,
    'Rye Brook': 1452,
    'Sackets Harbor': 1452,
    'Sag Harbor': 1452,
    'Saint Bonaventure': 1452,
    'Saint James': 1452,
    'Saint Johnsville': 1452,
    'Salamanca': 1452,
    'Salisbury': 1452,
    'Sanborn': 1452,
    'Sands Point': 1452,
    'Saranac Lake': 1452,
    'Saratoga County': 1452,
    'Saratoga Springs': 1452,
    'Saugerties': 1452,
    'Saugerties South': 1452,
    'Sayville': 1452,
    'Scarsdale': 1452,
    'Schenectady': 1452,
    'Schenectady County': 1452,
    'Schoharie': 1452,
    'Schoharie County': 1452,
    'Schuyler County': 1452,
    'Schuylerville': 1452,
    'Scotchtown': 1452,
    'Scotia': 1452,
    'Scottsville': 1452,
    'Sea Cliff': 1452,
    'Seaford': 1452,
    'Searingtown': 1452,
    'Seaside': 1452,
    'Selden': 1452,
    'Seneca County': 1452,
    'Seneca Falls': 1452,
    'Seneca Knolls': 1452,
    'Setauket-East Setauket': 1452,
    'Sheepshead Bay': 1452,
    'Shelter Island': 1452,
    'Shelter Island Heights': 1452,
    'Shenorock': 1452,
    'Sherburne': 1452,
    'Sherrill': 1452,
    'Shinnecock Hills': 1452,
    'Shirley': 1452,
    'Shokan': 1452,
    'Shortsville': 1452,
    'Shrub Oak': 1452,
    'Sidney': 1452,
    'Silver Creek': 1452,
    'Skaneateles': 1452,
    'Sleepy Hollow': 1452,
    'Sloan': 1452,
    'Sloatsburg': 1452,
    'Smithtown': 1452,
    'Sodus': 1452,
    'Solvay': 1452,
    'Sound Beach': 1452,
    'South Beach': 1452,
    'South Blooming Grove': 1452,
    'South Corning': 1452,
    'South Fallsburg': 1452,
    'South Farmingdale': 1452,
    'South Floral Park': 1452,
    'South Glens Falls': 1452,
    'South Hempstead': 1452,
    'South Hill': 1452,
    'South Huntington': 1452,
    'South Lockport': 1452,
    'South Nyack': 1452,
    'South Valley Stream': 1452,
    'Southampton': 1452,
    'Southold': 1452,
    'Southport': 1452,
    'Spackenkill': 1452,
    'Sparkill': 1452,
    'Spencerport': 1452,
    'Spring Valley': 1452,
    'Springfield': 1452,
    'Springfield Gardens': 1452,
    'Springs': 1452,
    'Springville': 1452,
    'Spuyten Duyvil': 1452,
    'St. Lawrence County': 1452,
    'Stamford': 1452,
    'Stapleton': 1452,
    'Staten Island': 1452,
    'Steinway': 1452,
    'Steuben County': 1452,
    'Stewart Manor': 1452,
    'Stillwater': 1452,
    'Stone Ridge': 1452,
    'Stony Brook': 1452,
    'Stony Point': 1452,
    'Stottville': 1452,
    'Suffern': 1452,
    'Suffolk County': 1452,
    'Sullivan County': 1452,
    'Sunnyside': 1452,
    'Sunset Park': 1452,
    'Sylvan Beach': 1452,
    'Syosset': 1452,
    'Syracuse': 1452,
    'Tappan': 1452,
    'Tarrytown': 1452,
    'Terrace Heights': 1452,
    'Terryville': 1452,
    'The Bronx': 1452,
    'Thiells': 1452,
    'Thomaston': 1452,
    'Thornwood': 1452,
    'Throgs Neck': 1452,
    'Ticonderoga': 1452,
    'Tillson': 1452,
    'Tioga County': 1452,
    'Tivoli': 1452,
    'Tompkins County': 1452,
    'Tompkinsville': 1452,
    'Tonawanda': 1452,
    'Town Line': 1452,
    'Tremont': 1452,
    'Tribes Hill': 1452,
    'Troy': 1452,
    'Trumansburg': 1452,
    'Tuckahoe': 1452,
    'Tupper Lake': 1452,
    'Ulster County': 1452,
    'Unadilla': 1452,
    'Union Springs': 1452,
    'Uniondale': 1452,
    'Unionport': 1452,
    'University Gardens': 1452,
    'University Heights': 1452,
    'Upper Brookville': 1452,
    'Upper Nyack': 1452,
    'Utica': 1452,
    'Vails Gate': 1452,
    'Valatie': 1452,
    'Valhalla': 1452,
    'Valley Cottage': 1452,
    'Valley Stream': 1452,
    'Van Nest': 1452,
    'Vernon': 1452,
    'Verplanck': 1452,
    'Victor': 1452,
    'Village Green': 1452,
    'Village of the Branch': 1452,
    'Viola': 1452,
    'Volney': 1452,
    'Voorheesville': 1452,
    'Wading River': 1452,
    'Wakefield': 1452,
    'Walden': 1452,
    'Wallkill': 1452,
    'Walton': 1452,
    'Walton Park': 1452,
    'Wampsville': 1452,
    'Wanakah': 1452,
    'Wantagh': 1452,
    'Wappingers Falls': 1452,
    'Warren County': 1452,
    'Warrensburg': 1452,
    'Warsaw': 1452,
    'Warwick': 1452,
    'Washington County': 1452,
    'Washington Heights': 1452,
    'Washington Mills': 1452,
    'Washingtonville': 1452,
    'Watchtower': 1452,
    'Water Mill': 1452,
    'Waterford': 1452,
    'Waterloo': 1452,
    'Watertown': 1452,
    'Waterville': 1452,
    'Watervliet': 1452,
    'Watkins Glen': 1452,
    'Waverly': 1452,
    'Wawarsing': 1452,
    'Wayland': 1452,
    'Wayne County': 1452,
    'Webster': 1452,
    'Weedsport': 1452,
    'Wellsville': 1452,
    'Wesley Hills': 1452,
    'West Albany': 1452,
    'West Babylon': 1452,
    'West Bay Shore': 1452,
    'West Carthage': 1452,
    'West Elmira': 1452,
    'West End': 1452,
    'West Glens Falls': 1452,
    'West Haverstraw': 1452,
    'West Hempstead': 1452,
    'West Hills': 1452,
    'West Hurley': 1452,
    'West Islip': 1452,
    'West Nyack': 1452,
    'West Point': 1452,
    'West Sand Lake': 1452,
    'West Sayville': 1452,
    'West Seneca': 1452,
    'Westbury': 1452,
    'Westchester County': 1452,
    'Westerleigh': 1452,
    'Westfield': 1452,
    'Westhampton': 1452,
    'Westhampton Beach': 1452,
    'Westmere': 1452,
    'Weston Mills': 1452,
    'Westvale': 1452,
    'Wheatley Heights': 1452,
    'White Plains': 1452,
    'Whitehall': 1452,
    'Whitesboro': 1452,
    'Whitestone': 1452,
    'Williamsburg': 1452,
    'Williamson': 1452,
    'Williamsville': 1452,
    'Williston Park': 1452,
    'Willowbrook': 1452,
    'Wilson': 1452,
    'Wolcott': 1452,
    'Woodbury': 1452,
    'Woodhaven': 1452,
    'Woodlawn': 1452,
    'Woodmere': 1452,
    'Woodrow': 1452,
    'Woodside': 1452,
    'Woodstock': 1452,
    'Worcester': 1452,
    'Wurtsboro': 1452,
    'Wyandanch': 1452,
    'Wykagyl': 1452,
    'Wynantskill': 1452,
    'Wyoming County': 1452,
    'Yaphank': 1452,
    'Yates County': 1452,
    'Yonkers': 1452,
    'Yorkshire': 1452,
    'Yorktown Heights': 1452,
    'Yorkville': 1452,
    'Youngstown': 1452,
    'Zena': 1452,
    'Aberdeen': 1447,
    'Advance': 1447,
    'Ahoskie': 1447,
    'Alamance County': 1447,
    'Albemarle': 1447,
    'Alexander County': 1447,
    'Alleghany County': 1447,
    'Andrews': 1447,
    'Angier': 1447,
    'Anson County': 1447,
    'Apex': 1447,
    'Archdale': 1447,
    'Archer Lodge': 1447,
    'Ashe County': 1447,
    'Asheboro': 1447,
    'Asheville': 1447,
    'Atlantic Beach': 1447,
    'Avery County': 1447,
    'Avery Creek': 1447,
    'Ayden': 1447,
    'Badin': 1447,
    'Bakersville': 1447,
    'Balfour': 1447,
    'Banner Elk': 1447,
    'Barker Heights': 1447,
    'Bayboro': 1447,
    'Bayshore': 1447,
    'Beaufort': 1447,
    'Beaufort County': 1447,
    'Belhaven': 1447,
    'Belmont': 1447,
    'Belville': 1447,
    'Benson': 1447,
    'Bent Creek': 1447,
    'Bermuda Run': 1447,
    'Bertie County': 1447,
    'Bessemer City': 1447,
    'Bethel': 1447,
    'Bethlehem': 1447,
    'Beulaville': 1447,
    'Biltmore Forest': 1447,
    'Biscoe': 1447,
    'Black Mountain': 1447,
    'Bladen County': 1447,
    'Bladenboro': 1447,
    'Blowing Rock': 1447,
    'Boiling Spring Lakes': 1447,
    'Boiling Springs': 1447,
    'Bolivia': 1447,
    'Boone': 1447,
    'Boonville': 1447,
    'Brevard': 1447,
    'Brices Creek': 1447,
    'Broad Creek': 1447,
    'Broadway': 1447,
    'Brogden': 1447,
    'Brunswick': 1447,
    'Brunswick County': 1447,
    'Bryson City': 1447,
    'Buies Creek': 1447,
    'Buncombe County': 1447,
    'Burgaw': 1447,
    'Burke County': 1447,
    'Burlington': 1447,
    'Burnsville': 1447,
    'Butner': 1447,
    'Buxton': 1447,
    'Cabarrus County': 1447,
    'Cajahs Mountain': 1447,
    'Calabash': 1447,
    'Caldwell County': 1447,
    'Camden': 1447,
    'Camden County': 1447,
    'Canton': 1447,
    'Cape Carteret': 1447,
    'Carolina Beach': 1447,
    'Carolina Shores': 1447,
    'Carrboro': 1447,
    'Carteret County': 1447,
    'Carthage': 1447,
    'Cary': 1447,
    'Castle Hayne': 1447,
    'Caswell County': 1447,
    'Catawba County': 1447,
    'Cedar Point': 1447,
    'Chadbourn': 1447,
    'Chapel Hill': 1447,
    'Charlotte': 1447,
    'Chatham County': 1447,
    'Cherokee': 1447,
    'Cherokee County': 1447,
    'Cherryville': 1447,
    'China Grove': 1447,
    'Chowan County': 1447,
    'Claremont': 1447,
    'Clay County': 1447,
    'Clayton': 1447,
    'Clemmons': 1447,
    'Cleveland County': 1447,
    'Clinton': 1447,
    'Clyde': 1447,
    'Coats': 1447,
    'Columbia': 1447,
    'Columbus': 1447,
    'Columbus County': 1447,
    'Concord': 1447,
    'Connelly Springs': 1447,
    'Conover': 1447,
    'Cordova': 1447,
    'Cornelius': 1447,
    'Cove Creek': 1447,
    'Cramerton': 1447,
    'Craven County': 1447,
    'Creedmoor': 1447,
    'Cricket': 1447,
    'Cullowhee': 1447,
    'Cumberland County': 1447,
    'Currituck': 1447,
    'Currituck County': 1447,
    'Dallas': 1447,
    'Dana': 1447,
    'Danbury': 1447,
    'Dare County': 1447,
    'Davidson': 1447,
    'Davidson County': 1447,
    'Davie County': 1447,
    'Denton': 1447,
    'Denver': 1447,
    'Dobson': 1447,
    'Drexel': 1447,
    'Dunn': 1447,
    'Duplin County': 1447,
    'Durham': 1447,
    'Durham County': 1447,
    'East Flat Rock': 1447,
    'East Rockingham': 1447,
    'East Spencer': 1447,
    'Eastover': 1447,
    'Eden': 1447,
    'Edenton': 1447,
    'Edgecombe County': 1447,
    'Edneyville': 1447,
    'Elizabeth City': 1447,
    'Elizabethtown': 1447,
    'Elkin': 1447,
    'Ellerbe': 1447,
    'Elm City': 1447,
    'Elon': 1447,
    'Elroy': 1447,
    'Emerald Isle': 1447,
    'Enfield': 1447,
    'Enochville': 1447,
    'Erwin': 1447,
    'Etowah': 1447,
    'Fairfield Harbour': 1447,
    'Fairmont': 1447,
    'Fairplains': 1447,
    'Fairview': 1447,
    'Farmville': 1447,
    'Fayetteville': 1447,
    'Fearrington Village': 1447,
    'Flat Rock': 1447,
    'Fletcher': 1447,
    'Forest City': 1447,
    'Forest Oaks': 1447,
    'Forsyth County': 1447,
    'Fort Bragg': 1447,
    'Foscoe': 1447,
    'Four Oaks': 1447,
    'Franklin': 1447,
    'Franklin County': 1447,
    'Franklinton': 1447,
    'Franklinville': 1447,
    'Fremont': 1447,
    'Fruitland': 1447,
    'Fuquay-Varina': 1447,
    'Gamewell': 1447,
    'Garner': 1447,
    'Gaston': 1447,
    'Gaston County': 1447,
    'Gastonia': 1447,
    'Gates County': 1447,
    'Gatesville': 1447,
    'Gibsonville': 1447,
    'Glen Alpine': 1447,
    'Glen Raven': 1447,
    'Goldsboro': 1447,
    'Gorman': 1447,
    'Graham': 1447,
    'Graham County': 1447,
    'Granite Falls': 1447,
    'Granite Quarry': 1447,
    'Granville County': 1447,
    'Green Level': 1447,
    'Greene County': 1447,
    'Greensboro': 1447,
    'Greenville': 1447,
    'Grifton': 1447,
    'Guilford County': 1447,
    'Half Moon': 1447,
    'Halifax': 1447,
    'Halifax County': 1447,
    'Hamlet': 1447,
    'Hampstead': 1447,
    'Harkers Island': 1447,
    'Harnett County': 1447,
    'Harrisburg': 1447,
    'Havelock': 1447,
    'Haw River': 1447,
    'Hayesville': 1447,
    'Hays': 1447,
    'Haywood County': 1447,
    'Hazelwood': 1447,
    'Hemby Bridge': 1447,
    'Henderson': 1447,
    'Henderson County': 1447,
    'Hendersonville': 1447,
    'Hertford': 1447,
    'Hertford County': 1447,
    'Hickory': 1447,
    'High Point': 1447,
    'Hildebran': 1447,
    'Hillsborough': 1447,
    'Hoke County': 1447,
    'Holly Ridge': 1447,
    'Holly Springs': 1447,
    'Hoopers Creek': 1447,
    'Hope Mills': 1447,
    'Horse Shoe': 1447,
    'Hudson': 1447,
    'Huntersville': 1447,
    'Hyde County': 1447,
    'Icard': 1447,
    'Indian Trail': 1447,
    'Iredell County': 1447,
    'Jackson': 1447,
    'Jackson County': 1447,
    'Jacksonville': 1447,
    'James City': 1447,
    'Jamestown': 1447,
    'Jefferson': 1447,
    'Johnston County': 1447,
    'Jones County': 1447,
    'Jonesville': 1447,
    'Kannapolis': 1447,
    'Kenansville': 1447,
    'Kenly': 1447,
    'Kernersville': 1447,
    'Kill Devil Hills': 1447,
    'King': 1447,
    'Kings Grant': 1447,
    'Kings Mountain': 1447,
    'Kinston': 1447,
    'Kitty Hawk': 1447,
    'Knightdale': 1447,
    'Kure Beach': 1447,
    'La Grange': 1447,
    'Lake Junaluska': 1447,
    'Lake Lure': 1447,
    'Lake Norman of Catawba': 1447,
    'Lake Park': 1447,
    'Lake Waccamaw': 1447,
    'Landis': 1447,
    'Laurel Hill': 1447,
    'Laurel Park': 1447,
    'Laurinburg': 1447,
    'Lee County': 1447,
    'Leland': 1447,
    'Lenoir': 1447,
    'Lenoir County': 1447,
    'Lewisville': 1447,
    'Lexington': 1447,
    'Liberty': 1447,
    'Lillington': 1447,
    'Lincoln County': 1447,
    'Lincolnton': 1447,
    'Locust': 1447,
    'Long Beach': 1447,
    'Longview': 1447,
    'Louisburg': 1447,
    'Lowell': 1447,
    'Lowesville': 1447,
    'Lucama': 1447,
    'Lumberton': 1447,
    'Macon County': 1447,
    'Madison': 1447,
    'Madison County': 1447,
    'Maggie Valley': 1447,
    'Maiden': 1447,
    'Manteo': 1447,
    'Mar-Mac': 1447,
    'Marion': 1447,
    'Mars Hill': 1447,
    'Marshall': 1447,
    'Marshville': 1447,
    'Martin County': 1447,
    'Marvin': 1447,
    'Masonboro': 1447,
    'Matthews': 1447,
    'Maury': 1447,
    'Maxton': 1447,
    'Mayodan': 1447,
    'Maysville': 1447,
    'McDowell County': 1447,
    'McLeansville': 1447,
    'Mebane': 1447,
    'Mecklenburg County': 1447,
    'Midland': 1447,
    'Midway': 1447,
    'Millers Creek': 1447,
    'Mills River': 1447,
    'Mineral Springs': 1447,
    'Mint Hill': 1447,
    'Mitchell County': 1447,
    'Mocksville': 1447,
    'Monroe': 1447,
    'Montgomery County': 1447,
    'Moore County': 1447,
    'Mooresville': 1447,
    'Moravian Falls': 1447,
    'Morehead City': 1447,
    'Morganton': 1447,
    'Morrisville': 1447,
    'Mount Airy': 1447,
    'Mount Gilead': 1447,
    'Mount Holly': 1447,
    'Mount Olive': 1447,
    'Mount Pleasant': 1447,
    'Mountain Home': 1447,
    'Mountain View': 1447,
    'Moyock': 1447,
    'Mulberry': 1447,
    'Murfreesboro': 1447,
    'Murphy': 1447,
    'Murraysville': 1447,
    'Myrtle Grove': 1447,
    'Nags Head': 1447,
    'Nash County': 1447,
    'Nashville': 1447,
    'Navassa': 1447,
    'Neuse Forest': 1447,
    'New Bern': 1447,
    'New Hanover County': 1447,
    'Newland': 1447,
    'Newport': 1447,
    'Newton': 1447,
    'Norlina': 1447,
    'North Wilkesboro': 1447,
    'Northampton County': 1447,
    'Northchase': 1447,
    'Northlakes': 1447,
    'Norwood': 1447,
    'Oak Island': 1447,
    'Oak Ridge': 1447,
    'Oakboro': 1447,
    'Ogden': 1447,
    'Onslow County': 1447,
    'Orange County': 1447,
    'Oxford': 1447,
    'Pamlico County': 1447,
    'Pasquotank County': 1447,
    'Pembroke': 1447,
    'Pender County': 1447,
    'Perquimans County': 1447,
    'Person County': 1447,
    'Pilot Mountain': 1447,
    'Pine Knoll Shores': 1447,
    'Pine Level': 1447,
    'Pinebluff': 1447,
    'Pinehurst': 1447,
    'Pinetops': 1447,
    'Pineville': 1447,
    'Piney Green': 1447,
    'Pitt County': 1447,
    'Pittsboro': 1447,
    'Plain View': 1447,
    'Pleasant Garden': 1447,
    'Pleasant Hill': 1447,
    'Plymouth': 1447,
    'Polk County': 1447,
    'Polkton': 1447,
    'Princeton': 1447,
    'Princeville': 1447,
    'Pumpkin Center': 1447,
    'Raeford': 1447,
    'Raleigh': 1447,
    'Ramseur': 1447,
    'Randleman': 1447,
    'Randolph County': 1447,
    'Ranlo': 1447,
    'Red Oak': 1447,
    'Red Springs': 1447,
    'Reidsville': 1447,
    'Rhodhiss': 1447,
    'Richlands': 1447,
    'Richmond County': 1447,
    'River Bend': 1447,
    'River Road': 1447,
    'Roanoke Rapids': 1447,
    'Robbins': 1447,
    'Robbinsville': 1447,
    'Robersonville': 1447,
    'Robeson County': 1447,
    'Rockfish': 1447,
    'Rockingham': 1447,
    'Rockingham County': 1447,
    'Rockwell': 1447,
    'Rocky Mount': 1447,
    'Rocky Point': 1447,
    'Rolesville': 1447,
    'Rose Hill': 1447,
    'Roseboro': 1447,
    'Rowan County': 1447,
    'Rowland': 1447,
    'Roxboro': 1447,
    'Royal Pines': 1447,
    'Rural Hall': 1447,
    'Rutherford College': 1447,
    'Rutherford County': 1447,
    'Rutherfordton': 1447,
    'Saint James': 1447,
    'Saint Pauls': 1447,
    'Saint Stephens': 1447,
    'Salem': 1447,
    'Salisbury': 1447,
    'Sampson County': 1447,
    'Sanford': 1447,
    'Sawmills': 1447,
    'Saxapahaw': 1447,
    'Scotland County': 1447,
    'Scotland Neck': 1447,
    'Scotts Mill': 1447,
    'Sea Breeze': 1447,
    'Seagate': 1447,
    'Selma': 1447,
    'Seven Lakes': 1447,
    'Shallotte': 1447,
    'Sharpsburg': 1447,
    'Shelby': 1447,
    'Sherrills Ford': 1447,
    'Siler City': 1447,
    'Silver Lake': 1447,
    'Skippers Corner': 1447,
    'Smithfield': 1447,
    'Sneads Ferry': 1447,
    'Snow Hill': 1447,
    'South Gastonia': 1447,
    'South Henderson': 1447,
    'South Rosemary': 1447,
    'Southern Pines': 1447,
    'Southern Shores': 1447,
    'Southmont': 1447,
    'Southport': 1447,
    'Sparta': 1447,
    'Spencer': 1447,
    'Spindale': 1447,
    'Spring Hope': 1447,
    'Spring Lake': 1447,
    'Spruce Pine': 1447,
    'Stallings': 1447,
    'Stanfield': 1447,
    'Stanley': 1447,
    'Stanly County': 1447,
    'Statesville': 1447,
    'Stedman': 1447,
    'Stokes County': 1447,
    'Stokesdale': 1447,
    'Stoneville': 1447,
    'Stony Point': 1447,
    'Summerfield': 1447,
    'Sunset Beach': 1447,
    'Surf City': 1447,
    'Surry County': 1447,
    'Swain County': 1447,
    'Swannanoa': 1447,
    'Swanquarter': 1447,
    'Swansboro': 1447,
    'Swepsonville': 1447,
    'Sylva': 1447,
    'Tabor City': 1447,
    'Tarboro': 1447,
    'Taylorsville': 1447,
    'Thomasville': 1447,
    'Thurmond': 1447,
    'Toast': 1447,
    'Tobaccoville': 1447,
    'Transylvania County': 1447,
    'Trent Woods': 1447,
    'Trenton': 1447,
    'Trinity': 1447,
    'Troutman': 1447,
    'Troy': 1447,
    'Tryon': 1447,
    'Tyro': 1447,
    'Tyrrell County': 1447,
    'Union County': 1447,
    'Unionville': 1447,
    'Valdese': 1447,
    'Valley Hill': 1447,
    'Vance County': 1447,
    'Vander': 1447,
    'Wadesboro': 1447,
    'Wake County': 1447,
    'Wake Forest': 1447,
    'Walkertown': 1447,
    'Wallace': 1447,
    'Wallburg': 1447,
    'Walnut Cove': 1447,
    'Wanchese': 1447,
    'Warren County': 1447,
    'Warrenton': 1447,
    'Warsaw': 1447,
    'Washington': 1447,
    'Washington County': 1447,
    'Watauga County': 1447,
    'Waxhaw': 1447,
    'Wayne County': 1447,
    'Waynesville': 1447,
    'Weaverville': 1447,
    'Weddington': 1447,
    'Welcome': 1447,
    'Weldon': 1447,
    'Wendell': 1447,
    'Wentworth': 1447,
    'Wesley Chapel': 1447,
    'West Canton': 1447,
    'West Jefferson': 1447,
    'West Marion': 1447,
    'West Raleigh': 1447,
    'Westport': 1447,
    'Whispering Pines': 1447,
    'White Plains': 1447,
    'Whiteville': 1447,
    'Wilkes County': 1447,
    'Wilkesboro': 1447,
    'Williamston': 1447,
    'Wilmington': 1447,
    'Wilson': 1447,
    'Wilson County': 1447,
    'Wilsons Mills': 1447,
    'Windsor': 1447,
    'Wingate': 1447,
    'Winston-Salem': 1447,
    'Winterville': 1447,
    'Winton': 1447,
    'Woodfin': 1447,
    'Wrightsboro': 1447,
    'Wrightsville Beach': 1447,
    'Yadkin County': 1447,
    'Yadkinville': 1447,
    'Yancey County': 1447,
    'Yanceyville': 1447,
    'Youngsville': 1447,
    'Zebulon': 1447,
    'Adams County': 1418,
    'Amidon': 1418,
    'Ashley': 1418,
    'Barnes County': 1418,
    'Beach': 1418,
    'Belcourt': 1418,
    'Belfield': 1418,
    'Benson County': 1418,
    'Beulah': 1418,
    'Billings County': 1418,
    'Bismarck': 1418,
    'Bottineau': 1418,
    'Bottineau County': 1418,
    'Bowbells': 1418,
    'Bowman': 1418,
    'Bowman County': 1418,
    'Burke County': 1418,
    'Burleigh County': 1418,
    'Burlington': 1418,
    'Cando': 1418,
    'Carrington': 1418,
    'Carson': 1418,
    'Cass County': 1418,
    'Casselton': 1418,
    'Cavalier': 1418,
    'Cavalier County': 1418,
    'Center': 1418,
    'Cooperstown': 1418,
    'Crosby': 1418,
    'Devils Lake': 1418,
    'Dickey County': 1418,
    'Dickinson': 1418,
    'Divide County': 1418,
    'Dunn County': 1418,
    'Eddy County': 1418,
    'Ellendale': 1418,
    'Emmons County': 1418,
    'Fargo': 1418,
    'Fessenden': 1418,
    'Finley': 1418,
    'Forman': 1418,
    'Fort Totten': 1418,
    'Fort Yates': 1418,
    'Foster County': 1418,
    'Garrison': 1418,
    'Golden Valley County': 1418,
    'Grafton': 1418,
    'Grand Forks': 1418,
    'Grand Forks Air Force Base': 1418,
    'Grand Forks County': 1418,
    'Grant County': 1418,
    'Griggs County': 1418,
    'Harvey': 1418,
    'Hazen': 1418,
    'Hettinger': 1418,
    'Hettinger County': 1418,
    'Hillsboro': 1418,
    'Horace': 1418,
    'Jamestown': 1418,
    'Kenmare': 1418,
    'Kidder County': 1418,
    'Killdeer': 1418,
    'Lakota': 1418,
    'LaMoure County': 1418,
    'Langdon': 1418,
    'Larimore': 1418,
    'Lincoln': 1418,
    'Linton': 1418,
    'Lisbon': 1418,
    'Logan County': 1418,
    'Mandan': 1418,
    'Manning': 1418,
    'Mayville': 1418,
    'McClusky': 1418,
    'McHenry County': 1418,
    'McIntosh County': 1418,
    'McKenzie County': 1418,
    'McLean County': 1418,
    'Medora': 1418,
    'Mercer County': 1418,
    'Minnewaukan': 1418,
    'Minot': 1418,
    'Minot Air Force Base': 1418,
    'Mohall': 1418,
    'Morton County': 1418,
    'Mott': 1418,
    'Mountrail County': 1418,
    'Napoleon': 1418,
    'Nelson County': 1418,
    'New Rockford': 1418,
    'New Town': 1418,
    'Oakes': 1418,
    'Oliver County': 1418,
    'Park River': 1418,
    'Parshall': 1418,
    'Pembina County': 1418,
    'Pierce County': 1418,
    'Ramsey County': 1418,
    'Ransom County': 1418,
    'Renville County': 1418,
    'Richland County': 1418,
    'Rolette County': 1418,
    'Rolla': 1418,
    'Rugby': 1418,
    'Sargent County': 1418,
    'Sheldon': 1418,
    'Shell Valley': 1418,
    'Sheridan County': 1418,
    'Sioux County': 1418,
    'Slope County': 1418,
    'Stanley': 1418,
    'Stanton': 1418,
    'Stark County': 1418,
    'Steele': 1418,
    'Steele County': 1418,
    'Stutsman County': 1418,
    'Surrey': 1418,
    'Thompson': 1418,
    'Tioga': 1418,
    'Towner': 1418,
    'Towner County': 1418,
    'Traill County': 1418,
    'Valley City': 1418,
    'Velva': 1418,
    'Wahpeton': 1418,
    'Walsh County': 1418,
    'Ward County': 1418,
    'Washburn': 1418,
    'Watford City': 1418,
    'Wells County': 1418,
    'West Fargo': 1418,
    'Williams County': 1418,
    'Williston': 1418,
    'Ada': 4851,
    'Adams County': 4851,
    'Akron': 4851,
    'Allen County': 4851,
    'Alliance': 4851,
    'Amberley': 4851,
    'Amelia': 4851,
    'Amherst': 4851,
    'Andover': 4851,
    'Anna': 4851,
    'Ansonia': 4851,
    'Antwerp': 4851,
    'Apple Creek': 4851,
    'Apple Valley': 4851,
    'Arcanum': 4851,
    'Archbold': 4851,
    'Arlington': 4851,
    'Ashland': 4851,
    'Ashland County': 4851,
    'Ashley': 4851,
    'Ashtabula': 4851,
    'Ashtabula County': 4851,
    'Ashville': 4851,
    'Athens': 4851,
    'Athens County': 4851,
    'Auglaize County': 4851,
    'Aurora': 4851,
    'Austintown': 4851,
    'Avon': 4851,
    'Avon Center': 4851,
    'Avon Lake': 4851,
    'Bainbridge': 4851,
    'Ballville': 4851,
    'Baltimore': 4851,
    'Barberton': 4851,
    'Barnesville': 4851,
    'Batavia': 4851,
    'Bay Village': 4851,
    'Beach City': 4851,
    'Beachwood': 4851,
    'Beavercreek': 4851,
    'Beckett Ridge': 4851,
    'Bedford': 4851,
    'Bedford Heights': 4851,
    'Beechwood Trails': 4851,
    'Bellaire': 4851,
    'Bellbrook': 4851,
    'Bellefontaine': 4851,
    'Bellevue': 4851,
    'Bellville': 4851,
    'Belmont County': 4851,
    'Belpre': 4851,
    'Berea': 4851,
    'Bethel': 4851,
    'Bethesda': 4851,
    'Beverly': 4851,
    'Bexley': 4851,
    'Blacklick Estates': 4851,
    'Blanchester': 4851,
    'Blue Ash': 4851,
    'Bluffton': 4851,
    'Boardman': 4851,
    'Bolindale': 4851,
    'Boston Heights': 4851,
    'Botkins': 4851,
    'Bowling Green': 4851,
    'Bradford': 4851,
    'Bradner': 4851,
    'Bratenahl': 4851,
    'Brecksville': 4851,
    'Bremen': 4851,
    'Brewster': 4851,
    'Bridgeport': 4851,
    'Bridgetown': 4851,
    'Brilliant': 4851,
    'Brimfield': 4851,
    'Broadview Heights': 4851,
    'Brook Park': 4851,
    'Brookfield Center': 4851,
    'Brooklyn': 4851,
    'Brooklyn Heights': 4851,
    'Brookville': 4851,
    'Brown County': 4851,
    'Brunswick': 4851,
    'Bryan': 4851,
    'Buckeye Lake': 4851,
    'Bucyrus': 4851,
    'Burlington': 4851,
    'Burton': 4851,
    'Butler County': 4851,
    'Byesville': 4851,
    'Cadiz': 4851,
    'Calcutta': 4851,
    'Caldwell': 4851,
    'Cambridge': 4851,
    'Camden': 4851,
    'Campbell': 4851,
    'Canal Fulton': 4851,
    'Canal Winchester': 4851,
    'Canfield': 4851,
    'Canton': 4851,
    'Cardington': 4851,
    'Carey': 4851,
    'Carlisle': 4851,
    'Carroll County': 4851,
    'Carrollton': 4851,
    'Cedarville': 4851,
    'Celina': 4851,
    'Centerburg': 4851,
    'Centerville': 4851,
    'Chagrin Falls': 4851,
    'Champaign County': 4851,
    'Champion Heights': 4851,
    'Chardon': 4851,
    'Chauncey': 4851,
    'Cherry Grove': 4851,
    'Chesterland': 4851,
    'Cheviot': 4851,
    'Chillicothe': 4851,
    'Choctaw Lake': 4851,
    'Churchill': 4851,
    'Cincinnati': 4851,
    'Circleville': 4851,
    'Clark County': 4851,
    'Clark-Fulton': 4851,
    'Clayton': 4851,
    'Clermont County': 4851,
    'Cleveland': 4851,
    'Cleveland Heights': 4851,
    'Cleves': 4851,
    'Clinton': 4851,
    'Clinton County': 4851,
    'Clyde': 4851,
    'Coal Grove': 4851,
    'Coldwater': 4851,
    'Collinwood': 4851,
    'Columbiana': 4851,
    'Columbiana County': 4851,
    'Columbus': 4851,
    'Columbus Grove': 4851,
    'Commercial Point': 4851,
    'Conneaut': 4851,
    'Continental': 4851,
    'Convoy': 4851,
    'Copley': 4851,
    'Cortland': 4851,
    'Coshocton': 4851,
    'Coshocton County': 4851,
    'Covedale': 4851,
    'Covington': 4851,
    'Craig Beach': 4851,
    'Crawford County': 4851,
    'Crestline': 4851,
    'Creston': 4851,
    'Cridersville': 4851,
    'Crooksville': 4851,
    'Crystal Lakes': 4851,
    'Curtice': 4851,
    'Cuyahoga County': 4851,
    'Cuyahoga Falls': 4851,
    'Dalton': 4851,
    'Danville': 4851,
    'Darke County': 4851,
    'Day Heights': 4851,
    'Dayton': 4851,
    'De Graff': 4851,
    'Deer Park': 4851,
    'Defiance': 4851,
    'Defiance County': 4851,
    'Delaware': 4851,
    'Delaware County': 4851,
    'Delhi Hills': 4851,
    'Delphos': 4851,
    'Delta': 4851,
    'Dennison': 4851,
    'Dent': 4851,
    'Deshler': 4851,
    'Detroit-Shoreway': 4851,
    'Devola': 4851,
    'Dillonvale': 4851,
    'Dover': 4851,
    'Doylestown': 4851,
    'Dresden': 4851,
    'Drexel': 4851,
    'Dry Ridge': 4851,
    'Dry Run': 4851,
    'Dublin': 4851,
    'Dunlap': 4851,
    'East Canton': 4851,
    'East Cleveland': 4851,
    'East Liverpool': 4851,
    'East Palestine': 4851,
    'Eastlake': 4851,
    'Eaton': 4851,
    'Eaton Estates': 4851,
    'Edgerton': 4851,
    'Edgewood': 4851,
    'Elida': 4851,
    'Elmore': 4851,
    'Elmwood Place': 4851,
    'Elyria': 4851,
    'Englewood': 4851,
    'Enon': 4851,
    'Erie County': 4851,
    'Etna': 4851,
    'Euclid': 4851,
    'Evendale': 4851,
    'Fairborn': 4851,
    'Fairfax': 4851,
    'Fairfield': 4851,
    'Fairfield Beach': 4851,
    'Fairfield County': 4851,
    'Fairlawn': 4851,
    'Fairport Harbor': 4851,
    'Fairview Park': 4851,
    'Farmersville': 4851,
    'Fayette': 4851,
    'Fayette County': 4851,
    'Findlay': 4851,
    'Finneytown': 4851,
    'Five Points': 4851,
    'Forest': 4851,
    'Forest Park': 4851,
    'Forestville': 4851,
    'Fort Loramie': 4851,
    'Fort McKinley': 4851,
    'Fort Recovery': 4851,
    'Fort Shawnee': 4851,
    'Fostoria': 4851,
    'Frankfort': 4851,
    'Franklin': 4851,
    'Franklin County': 4851,
    'Franklin Furnace': 4851,
    'Frazeysburg': 4851,
    'Fredericktown': 4851,
    'Fremont': 4851,
    'Fruit Hill': 4851,
    'Fulton County': 4851,
    'Gahanna': 4851,
    'Galion': 4851,
    'Gallia County': 4851,
    'Gallipolis': 4851,
    'Gambier': 4851,
    'Garfield Heights': 4851,
    'Garrettsville': 4851,
    'Gates Mills': 4851,
    'Geauga County': 4851,
    'Geneva': 4851,
    'Geneva-on-the-Lake': 4851,
    'Genoa': 4851,
    'Georgetown': 4851,
    'Germantown': 4851,
    'Gibsonburg': 4851,
    'Girard': 4851,
    'Glandorf': 4851,
    'Glendale': 4851,
    'Glenmoor': 4851,
    'Glenville': 4851,
    'Glouster': 4851,
    'Gnadenhutten': 4851,
    'Golf Manor': 4851,
    'Goshen': 4851,
    'Grafton': 4851,
    'Grandview': 4851,
    'Grandview Heights': 4851,
    'Granville': 4851,
    'Granville South': 4851,
    'Green': 4851,
    'Green Meadows': 4851,
    'Green Springs': 4851,
    'Greene County': 4851,
    'Greenfield': 4851,
    'Greenhills': 4851,
    'Greensburg': 4851,
    'Greentown': 4851,
    'Greenville': 4851,
    'Greenwich': 4851,
    'Groesbeck': 4851,
    'Grove City': 4851,
    'Groveport': 4851,
    'Guernsey County': 4851,
    'Hamilton': 4851,
    'Hamilton County': 4851,
    'Hancock County': 4851,
    'Hanover': 4851,
    'Harbor Hills': 4851,
    'Hardin County': 4851,
    'Harrison': 4851,
    'Harrison County': 4851,
    'Hartville': 4851,
    'Haskins': 4851,
    'Heath': 4851,
    'Hebron': 4851,
    'Henry County': 4851,
    'Hicksville': 4851,
    'Highland County': 4851,
    'Highland Heights': 4851,
    'Highpoint': 4851,
    'Hilliard': 4851,
    'Hillsboro': 4851,
    'Hiram': 4851,
    'Hocking County': 4851,
    'Holgate': 4851,
    'Holiday Valley': 4851,
    'Holland': 4851,
    'Holmes County': 4851,
    'Hough': 4851,
    'Howland Center': 4851,
    'Hubbard': 4851,
    'Huber Heights': 4851,
    'Huber Ridge': 4851,
    'Hudson': 4851,
    'Hunter': 4851,
    'Huron': 4851,
    'Huron County': 4851,
    'Independence': 4851,
    'Ironton': 4851,
    'Jackson': 4851,
    'Jackson Center': 4851,
    'Jackson County': 4851,
    'Jamestown': 4851,
    'Jefferson': 4851,
    'Jefferson County': 4851,
    'Jeffersonville': 4851,
    'Johnstown': 4851,
    'Kalida': 4851,
    'Kent': 4851,
    'Kenton': 4851,
    'Kenwood': 4851,
    'Kettering': 4851,
    'Kings Mills': 4851,
    'Kingston': 4851,
    'Kirtland': 4851,
    'Knox County': 4851,
    'La Croft': 4851,
    'Lagrange': 4851,
    'Lake County': 4851,
    'Lake Darby': 4851,
    'Lake Lakengren': 4851,
    'Lake Mohawk': 4851,
    'Lakemore': 4851,
    'Lakeview': 4851,
    'Lakewood': 4851,
    'Lancaster': 4851,
    'Landen': 4851,
    'Lawrence County': 4851,
    'Leavittsburg': 4851,
    'Lebanon': 4851,
    'Leesburg': 4851,
    'Leetonia': 4851,
    'Leipsic': 4851,
    'Lewis Center': 4851,
    'Lewisburg': 4851,
    'Lexington': 4851,
    'Liberty Center': 4851,
    'Licking County': 4851,
    'Lima': 4851,
    'Lincoln Heights': 4851,
    'Lincoln Village': 4851,
    'Lisbon': 4851,
    'Lithopolis': 4851,
    'Lockland': 4851,
    'Lodi': 4851,
    'Logan': 4851,
    'Logan County': 4851,
    'Logan Elm Village': 4851,
    'London': 4851,
    'Lorain': 4851,
    'Lorain County': 4851,
    'Lordstown': 4851,
    'Loudonville': 4851,
    'Louisville': 4851,
    'Loveland': 4851,
    'Loveland Park': 4851,
    'Lowellville': 4851,
    'Lucas County': 4851,
    'Lucasville': 4851,
    'Luckey': 4851,
    'Lynchburg': 4851,
    'Lyndhurst': 4851,
    'Macedonia': 4851,
    'Mack': 4851,
    'Madeira': 4851,
    'Madison': 4851,
    'Madison County': 4851,
    'Mahoning County': 4851,
    'Malvern': 4851,
    'Manchester': 4851,
    'Mansfield': 4851,
    'Mantua': 4851,
    'Maple Heights': 4851,
    'Mariemont': 4851,
    'Marietta': 4851,
    'Marion': 4851,
    'Marion County': 4851,
    'Martins Ferry': 4851,
    'Marysville': 4851,
    'Mason': 4851,
    'Massillon': 4851,
    'Masury': 4851,
    'Maumee': 4851,
    'Mayfield': 4851,
    'Mayfield Heights': 4851,
    'McArthur': 4851,
    'McComb': 4851,
    'McConnelsville': 4851,
    'McDonald': 4851,
    'McKinley Heights': 4851,
    'Mechanicsburg': 4851,
    'Medina': 4851,
    'Medina County': 4851,
    'Meigs County': 4851,
    'Mentor': 4851,
    'Mentor-on-the-Lake': 4851,
    'Mercer County': 4851,
    'Miami County': 4851,
    'Miami Heights': 4851,
    'Miamisburg': 4851,
    'Miamitown': 4851,
    'Middleburg Heights': 4851,
    'Middlefield': 4851,
    'Middleport': 4851,
    'Middletown': 4851,
    'Milan': 4851,
    'Milford': 4851,
    'Millbury': 4851,
    'Millersburg': 4851,
    'Millersport': 4851,
    'Mineral Ridge': 4851,
    'Minerva': 4851,
    'Minerva Park': 4851,
    'Mingo Junction': 4851,
    'Minster': 4851,
    'Mogadore': 4851,
    'Monfort Heights': 4851,
    'Monroe': 4851,
    'Monroe County': 4851,
    'Monroeville': 4851,
    'Montgomery': 4851,
    'Montgomery County': 4851,
    'Montpelier': 4851,
    'Montrose-Ghent': 4851,
    'Moraine': 4851,
    'Moreland Hills': 4851,
    'Morgan County': 4851,
    'Morgandale': 4851,
    'Morrow': 4851,
    'Morrow County': 4851,
    'Mount Carmel': 4851,
    'Mount Gilead': 4851,
    'Mount Healthy': 4851,
    'Mount Healthy Heights': 4851,
    'Mount Orab': 4851,
    'Mount Repose': 4851,
    'Mount Sterling': 4851,
    'Mount Vernon': 4851,
    'Mulberry': 4851,
    'Munroe Falls': 4851,
    'Muskingum County': 4851,
    'Napoleon': 4851,
    'Navarre': 4851,
    'Nelsonville': 4851,
    'New Albany': 4851,
    'New Boston': 4851,
    'New Bremen': 4851,
    'New Burlington': 4851,
    'New California': 4851,
    'New Carlisle': 4851,
    'New Concord': 4851,
    'New Franklin': 4851,
    'New Lebanon': 4851,
    'New Lexington': 4851,
    'New London': 4851,
    'New Matamoras': 4851,
    'New Miami': 4851,
    'New Middletown': 4851,
    'New Paris': 4851,
    'New Philadelphia': 4851,
    'New Richmond': 4851,
    'New Vienna': 4851,
    'New Waterford': 4851,
    'Newark': 4851,
    'Newburgh Heights': 4851,
    'Newcomerstown': 4851,
    'Newport': 4851,
    'Newton Falls': 4851,
    'Newtown': 4851,
    'Niles': 4851,
    'Noble County': 4851,
    'North Baltimore': 4851,
    'North Canton': 4851,
    'North College Hill': 4851,
    'North Fork Village': 4851,
    'North Kingsville': 4851,
    'North Lewisburg': 4851,
    'North Madison': 4851,
    'North Olmsted': 4851,
    'North Randall': 4851,
    'North Ridgeville': 4851,
    'North Royalton': 4851,
    'North Zanesville': 4851,
    'Northbrook': 4851,
    'Northfield': 4851,
    'Northgate': 4851,
    'Northridge': 4851,
    'Northwood': 4851,
    'Norton': 4851,
    'Norwalk': 4851,
    'Norwood': 4851,
    'Oak Harbor': 4851,
    'Oak Hill': 4851,
    'Oakwood': 4851,
    'Oberlin': 4851,
    'Obetz': 4851,
    'Olmsted Falls': 4851,
    'Ontario': 4851,
    'Orange': 4851,
    'Oregon': 4851,
    'Orrville': 4851,
    'Orwell': 4851,
    'Ottawa': 4851,
    'Ottawa County': 4851,
    'Ottawa Hills': 4851,
    'Oxford': 4851,
    'Painesville': 4851,
    'Pandora': 4851,
    'Park Layne': 4851,
    'Parma': 4851,
    'Parma Heights': 4851,
    'Pataskala': 4851,
    'Paulding': 4851,
    'Paulding County': 4851,
    'Payne': 4851,
    'Peebles': 4851,
    'Pemberville': 4851,
    'Pepper Pike': 4851,
    'Perry': 4851,
    'Perry County': 4851,
    'Perry Heights': 4851,
    'Perrysburg': 4851,
    'Pickaway County': 4851,
    'Pickerington': 4851,
    'Pike County': 4851,
    'Piketon': 4851,
    'Pioneer': 4851,
    'Piqua': 4851,
    'Plain City': 4851,
    'Pleasant Grove': 4851,
    'Pleasant Hill': 4851,
    'Pleasant Run': 4851,
    'Pleasant Run Farm': 4851,
    'Plymouth': 4851,
    'Poland': 4851,
    'Pomeroy': 4851,
    'Port Clinton': 4851,
    'Portage County': 4851,
    'Portage Lakes': 4851,
    'Portsmouth': 4851,
    'Powell': 4851,
    'Powhatan Point': 4851,
    'Preble County': 4851,
    'Prospect': 4851,
    'Putnam County': 4851,
    'Ravenna': 4851,
    'Reading': 4851,
    'Reminderville': 4851,
    'Reno': 4851,
    'Reynoldsburg': 4851,
    'Richfield': 4851,
    'Richland County': 4851,
    'Richmond Heights': 4851,
    'Richville': 4851,
    'Richwood': 4851,
    'Ripley': 4851,
    'Rittman': 4851,
    'Riverside': 4851,
    'Roaming Shores': 4851,
    'Rockford': 4851,
    'Rocky River': 4851,
    'Rosemount': 4851,
    'Roseville': 4851,
    'Ross': 4851,
    'Ross County': 4851,
    'Rossford': 4851,
    'Rossmoyne': 4851,
    'Russells Point': 4851,
    'Sabina': 4851,
    'Saint Bernard': 4851,
    'Saint Clairsville': 4851,
    'Saint Henry': 4851,
    'Saint Marys': 4851,
    'Saint Paris': 4851,
    'Salem': 4851,
    'Salem Heights': 4851,
    'Salineville': 4851,
    'Sandusky': 4851,
    'Sandusky County': 4851,
    'Sawyerwood': 4851,
    'Scioto County': 4851,
    'Sciotodale': 4851,
    'Sebring': 4851,
    'Seneca County': 4851,
    'Seven Hills': 4851,
    'Seville': 4851,
    'Shadyside': 4851,
    'Shaker Heights': 4851,
    'Sharonville': 4851,
    'Shawnee Hills': 4851,
    'Sheffield': 4851,
    'Sheffield Lake': 4851,
    'Shelby': 4851,
    'Shelby County': 4851,
    'Sherwood': 4851,
    'Shiloh': 4851,
    'Shreve': 4851,
    'Sidney': 4851,
    'Silver Lake': 4851,
    'Silverton': 4851,
    'Sixteen Mile Stand': 4851,
    'Skyline Acres': 4851,
    'Smithville': 4851,
    'Solon': 4851,
    'Somerset': 4851,
    'South Amherst': 4851,
    'South Bloomfield': 4851,
    'South Canal': 4851,
    'South Charleston': 4851,
    'South Euclid': 4851,
    'South Lebanon': 4851,
    'South Point': 4851,
    'South Russell': 4851,
    'South Zanesville': 4851,
    'Spencerville': 4851,
    'Springboro': 4851,
    'Springdale': 4851,
    'Springfield': 4851,
    'Stark County': 4851,
    'Steubenville': 4851,
    'Stony Prairie': 4851,
    'Stow': 4851,
    'Strasburg': 4851,
    'Streetsboro': 4851,
    'Strongsville': 4851,
    'Struthers': 4851,
    'Stryker': 4851,
    'Sugarcreek': 4851,
    'Sugarcreek Police Dept': 4851,
    'Summerside': 4851,
    'Summit County': 4851,
    'Sunbury': 4851,
    'Swanton': 4851,
    'Sylvania': 4851,
    'Tallmadge': 4851,
    'Terrace Park': 4851,
    'The Plains': 4851,
    'The Village of Indian Hill': 4851,
    'Thornport': 4851,
    'Tiffin': 4851,
    'Tiltonsville': 4851,
    'Tipp City': 4851,
    'Toledo': 4851,
    'Toronto': 4851,
    'Trenton': 4851,
    'Trotwood': 4851,
    'Troy': 4851,
    'Trumbull County': 4851,
    'Turpin Hills': 4851,
    'Tuscarawas': 4851,
    'Tuscarawas County': 4851,
    'Twinsburg': 4851,
    'Uhrichsville': 4851,
    'Union': 4851,
    'Union City': 4851,
    'Union County': 4851,
    'Uniontown': 4851,
    'University Heights': 4851,
    'Upper Arlington': 4851,
    'Upper Sandusky': 4851,
    'Urbana': 4851,
    'Urbancrest': 4851,
    'Utica': 4851,
    'Valley View': 4851,
    'Van Wert': 4851,
    'Van Wert County': 4851,
    'Vandalia': 4851,
    'Vermilion': 4851,
    'Vermilion-on-the-Lake': 4851,
    'Versailles': 4851,
    'Vinton County': 4851,
    'Wadsworth': 4851,
    'Wakeman': 4851,
    'Walbridge': 4851,
    'Walton Hills': 4851,
    'Wapakoneta': 4851,
    'Warren': 4851,
    'Warren County': 4851,
    'Warrensville Heights': 4851,
    'Washington County': 4851,
    'Washington Court House': 4851,
    'Waterville': 4851,
    'Wauseon': 4851,
    'Waverly': 4851,
    'Wayne County': 4851,
    'Waynesville': 4851,
    'Wellington': 4851,
    'Wellston': 4851,
    'Wellsville': 4851,
    'West Alexandria': 4851,
    'West Carrollton City': 4851,
    'West Hill': 4851,
    'West Jefferson': 4851,
    'West Lafayette': 4851,
    'West Liberty': 4851,
    'West Milton': 4851,
    'West Portsmouth': 4851,
    'West Salem': 4851,
    'West Union': 4851,
    'West Unity': 4851,
    'Westerville': 4851,
    'Westfield Center': 4851,
    'Westlake': 4851,
    'Weston': 4851,
    'Wetherington': 4851,
    'Wheelersburg': 4851,
    'White Oak': 4851,
    'Whitehall': 4851,
    'Whitehouse': 4851,
    'Wickliffe': 4851,
    'Wilberforce': 4851,
    'Willard': 4851,
    'Williams County': 4851,
    'Williamsburg': 4851,
    'Williamsport': 4851,
    'Willoughby': 4851,
    'Willoughby Hills': 4851,
    'Willowick': 4851,
    'Wilmington': 4851,
    'Winchester': 4851,
    'Windham': 4851,
    'Wintersville': 4851,
    'Withamsville': 4851,
    'Wolfhurst': 4851,
    'Wood County': 4851,
    'Woodlawn': 4851,
    'Woodsfield': 4851,
    'Woodville': 4851,
    'Wooster': 4851,
    'Worthington': 4851,
    'Wright-Patterson AFB': 4851,
    'Wyandot County': 4851,
    'Wyoming': 4851,
    'Xenia': 4851,
    'Yellow Springs': 4851,
    'Yorkville': 4851,
    'Youngstown': 4851,
    'Zanesville': 4851,
    'Ada': 1421,
    'Adair County': 1421,
    'Afton': 1421,
    'Alfalfa County': 1421,
    'Altus': 1421,
    'Alva': 1421,
    'Anadarko': 1421,
    'Antlers': 1421,
    'Apache': 1421,
    'Arapaho': 1421,
    'Ardmore': 1421,
    'Arkoma': 1421,
    'Arnett': 1421,
    'Atoka': 1421,
    'Atoka County': 1421,
    'Barnsdall': 1421,
    'Bartlesville': 1421,
    'Beaver': 1421,
    'Beaver County': 1421,
    'Beckham County': 1421,
    'Beggs': 1421,
    'Bethany': 1421,
    'Bethel Acres': 1421,
    'Bixby': 1421,
    'Blackwell': 1421,
    'Blaine County': 1421,
    'Blanchard': 1421,
    'Boise City': 1421,
    'Boley': 1421,
    'Bray': 1421,
    'Bristow': 1421,
    'Broken Arrow': 1421,
    'Broken Bow': 1421,
    'Bryan County': 1421,
    'Buffalo': 1421,
    'Burns Flat': 1421,
    'Bushyhead': 1421,
    'Byng': 1421,
    'Cache': 1421,
    'Caddo': 1421,
    'Caddo County': 1421,
    'Calera': 1421,
    'Canadian County': 1421,
    'Carnegie': 1421,
    'Carter County': 1421,
    'Catoosa': 1421,
    'Central High': 1421,
    'Chandler': 1421,
    'Checotah': 1421,
    'Chelsea': 1421,
    'Cherokee': 1421,
    'Cherokee County': 1421,
    'Cheyenne': 1421,
    'Chickasha': 1421,
    'Choctaw': 1421,
    'Choctaw County': 1421,
    'Chouteau': 1421,
    'Cimarron County': 1421,
    'Claremore': 1421,
    'Cleora': 1421,
    'Cleveland': 1421,
    'Cleveland County': 1421,
    'Clinton': 1421,
    'Coal County': 1421,
    'Coalgate': 1421,
    'Colbert': 1421,
    'Collinsville': 1421,
    'Comanche': 1421,
    'Comanche County': 1421,
    'Commerce': 1421,
    'Copeland': 1421,
    'Cordell': 1421,
    'Cotton County': 1421,
    'Coweta': 1421,
    'Craig County': 1421,
    'Creek County': 1421,
    'Crescent': 1421,
    'Cushing': 1421,
    'Custer County': 1421,
    'Cyril': 1421,
    'Davis': 1421,
    'Del City': 1421,
    'Delaware County': 1421,
    'Dewey': 1421,
    'Dewey County': 1421,
    'Dickson': 1421,
    'Drumright': 1421,
    'Duncan': 1421,
    'Durant': 1421,
    'Edmond': 1421,
    'El Reno': 1421,
    'Elgin': 1421,
    'Elk City': 1421,
    'Ellis County': 1421,
    'Enid': 1421,
    'Erick': 1421,
    'Eufaula': 1421,
    'Fairfax': 1421,
    'Fairland': 1421,
    'Fairview': 1421,
    'Fletcher': 1421,
    'Forest Park': 1421,
    'Fort Gibson': 1421,
    'Frederick': 1421,
    'Garfield County': 1421,
    'Garvin County': 1421,
    'Geary': 1421,
    'Geronimo': 1421,
    'Glenpool': 1421,
    'Goldsby': 1421,
    'Goodwell': 1421,
    'Grady County': 1421,
    'Granite': 1421,
    'Grant County': 1421,
    'Greer County': 1421,
    'Grove': 1421,
    'Guthrie': 1421,
    'Guymon': 1421,
    'Hall Park': 1421,
    'Harmon County': 1421,
    'Harper County': 1421,
    'Harrah': 1421,
    'Hartshorne': 1421,
    'Haskell': 1421,
    'Haskell County': 1421,
    'Healdton': 1421,
    'Heavener': 1421,
    'Helena': 1421,
    'Hennessey': 1421,
    'Henryetta': 1421,
    'Hinton': 1421,
    'Hobart': 1421,
    'Holdenville': 1421,
    'Hollis': 1421,
    'Hominy': 1421,
    'Hooker': 1421,
    'Hughes County': 1421,
    'Hugo': 1421,
    'Idabel': 1421,
    'Inola': 1421,
    'Jackson County': 1421,
    'Jay': 1421,
    'Jefferson County': 1421,
    'Jenks': 1421,
    'Johnston County': 1421,
    'Jones': 1421,
    'Justice': 1421,
    'Kay County': 1421,
    'Kellyville': 1421,
    'Kenwood': 1421,
    'Kiefer': 1421,
    'Kingfisher': 1421,
    'Kingfisher County': 1421,
    'Kingston': 1421,
    'Kiowa County': 1421,
    'Konawa': 1421,
    'Krebs': 1421,
    'Langston': 1421,
    'Latimer County': 1421,
    'Laverne': 1421,
    'Lawton': 1421,
    'Le Flore County': 1421,
    'Lexington': 1421,
    'Lincoln County': 1421,
    'Lindsay': 1421,
    'Locust Grove': 1421,
    'Logan County': 1421,
    'Lone Grove': 1421,
    'Longtown': 1421,
    'Love County': 1421,
    'Luther': 1421,
    'Madill': 1421,
    'Major County': 1421,
    'Mangum': 1421,
    'Mannford': 1421,
    'Marietta': 1421,
    'Marlow': 1421,
    'Marshall County': 1421,
    'Maud': 1421,
    'Mayes County': 1421,
    'Maysville': 1421,
    'McAlester': 1421,
    'McClain County': 1421,
    'McCord': 1421,
    'McCurtain County': 1421,
    'McIntosh County': 1421,
    'McLoud': 1421,
    'Medford': 1421,
    'Meeker': 1421,
    'Meridian': 1421,
    'Miami': 1421,
    'Midwest City': 1421,
    'Minco': 1421,
    'Moore': 1421,
    'Mooreland': 1421,
    'Morris': 1421,
    'Mounds': 1421,
    'Muldrow': 1421,
    'Murray County': 1421,
    'Muskogee': 1421,
    'Muskogee County': 1421,
    'Mustang': 1421,
    'Newcastle': 1421,
    'Newkirk': 1421,
    'Nichols Hills': 1421,
    'Nicoma Park': 1421,
    'Ninnekah': 1421,
    'Noble': 1421,
    'Noble County': 1421,
    'Norman': 1421,
    'Nowata': 1421,
    'Nowata County': 1421,
    'Oakhurst': 1421,
    'Oakland': 1421,
    'Oilton': 1421,
    'Okarche': 1421,
    'Okeene': 1421,
    'Okemah': 1421,
    'Okfuskee County': 1421,
    'Oklahoma City': 1421,
    'Oklahoma County': 1421,
    'Okmulgee': 1421,
    'Okmulgee County': 1421,
    'Oologah': 1421,
    'Osage County': 1421,
    'Ottawa County': 1421,
    'Owasso': 1421,
    'Panama': 1421,
    'Park Hill': 1421,
    'Pauls Valley': 1421,
    'Pawhuska': 1421,
    'Pawnee': 1421,
    'Pawnee County': 1421,
    'Payne County': 1421,
    'Perkins': 1421,
    'Perry': 1421,
    'Piedmont': 1421,
    'Pink': 1421,
    'Pittsburg County': 1421,
    'Pocola': 1421,
    'Ponca City': 1421,
    'Pontotoc County': 1421,
    'Poteau': 1421,
    'Pottawatomie County': 1421,
    'Prague': 1421,
    'Pryor': 1421,
    'Pryor Creek': 1421,
    'Purcell': 1421,
    'Pushmataha County': 1421,
    'Quinton': 1421,
    'Ringling': 1421,
    'Roger Mills County': 1421,
    'Rogers County': 1421,
    'Roland': 1421,
    'Rush Springs': 1421,
    'Salina': 1421,
    'Sallisaw': 1421,
    'Sand Springs': 1421,
    'Sapulpa': 1421,
    'Sayre': 1421,
    'Seminole': 1421,
    'Seminole County': 1421,
    'Sequoyah County': 1421,
    'Shattuck': 1421,
    'Shawnee': 1421,
    'Skiatook': 1421,
    'Slaughterville': 1421,
    'Snyder': 1421,
    'Spencer': 1421,
    'Sperry': 1421,
    'Spiro': 1421,
    'Stephens County': 1421,
    'Stigler': 1421,
    'Stillwater': 1421,
    'Stilwell': 1421,
    'Stratford': 1421,
    'Stroud': 1421,
    'Sulphur': 1421,
    'Tahlequah': 1421,
    'Talihina': 1421,
    'Taloga': 1421,
    'Tecumseh': 1421,
    'Texanna': 1421,
    'Texas County': 1421,
    'The Village': 1421,
    'Thomas': 1421,
    'Tillman County': 1421,
    'Tishomingo': 1421,
    'Tonkawa': 1421,
    'Tulsa': 1421,
    'Tulsa County': 1421,
    'Turley': 1421,
    'Tuttle': 1421,
    'Union City': 1421,
    'Verdigris': 1421,
    'Vian': 1421,
    'Vinita': 1421,
    'Wagoner': 1421,
    'Wagoner County': 1421,
    'Walters': 1421,
    'Warner': 1421,
    'Warr Acres': 1421,
    'Washington County': 1421,
    'Washita County': 1421,
    'Watonga': 1421,
    'Waukomis': 1421,
    'Waurika': 1421,
    'Weatherford': 1421,
    'Westville': 1421,
    'Wetumka': 1421,
    'Wewoka': 1421,
    'Wilburton': 1421,
    'Wilson': 1421,
    'Wister': 1421,
    'Woods County': 1421,
    'Woodward': 1421,
    'Woodward County': 1421,
    'Wynnewood': 1421,
    'Yale': 1421,
    'Yukon': 1421,
    'Albany': 1415,
    'Aloha': 1415,
    'Altamont': 1415,
    'Amity': 1415,
    'Ashland': 1415,
    'Astoria': 1415,
    'Athena': 1415,
    'Aumsville': 1415,
    'Baker City': 1415,
    'Baker County': 1415,
    'Bandon': 1415,
    'Banks': 1415,
    'Barview': 1415,
    'Bay City': 1415,
    'Beavercreek': 1415,
    'Beaverton': 1415,
    'Bend': 1415,
    'Benton County': 1415,
    'Bethany': 1415,
    'Boardman': 1415,
    'Brookings': 1415,
    'Brownsville': 1415,
    'Bunker Hill': 1415,
    'Burns': 1415,
    'Canby': 1415,
    'Cannon Beach': 1415,
    'Canyon City': 1415,
    'Canyonville': 1415,
    'Carlton': 1415,
    'Cascade Locks': 1415,
    'Cave Junction': 1415,
    'Cedar Hills': 1415,
    'Cedar Mill': 1415,
    'Central Point': 1415,
    'Chenoweth': 1415,
    'Clackamas': 1415,
    'Clackamas County': 1415,
    'Clatskanie': 1415,
    'Clatsop County': 1415,
    'Coburg': 1415,
    'Columbia City': 1415,
    'Columbia County': 1415,
    'Condon': 1415,
    'Coos Bay': 1415,
    'Coos County': 1415,
    'Coquille': 1415,
    'Cornelius': 1415,
    'Corvallis': 1415,
    'Cottage Grove': 1415,
    'Creswell': 1415,
    'Crook County': 1415,
    'Culp Creek': 1415,
    'Culver': 1415,
    'Curry County': 1415,
    'Dallas': 1415,
    'Damascus': 1415,
    'Dayton': 1415,
    'Depoe Bay': 1415,
    'Deschutes County': 1415,
    'Deschutes River Woods': 1415,
    'Donald': 1415,
    'Douglas County': 1415,
    'Drain': 1415,
    'Dundee': 1415,
    'Dunes City': 1415,
    'Durham': 1415,
    'Eagle Point': 1415,
    'Elgin': 1415,
    'Enterprise': 1415,
    'Estacada': 1415,
    'Eugene': 1415,
    'Fairview': 1415,
    'Florence': 1415,
    'Forest Grove': 1415,
    'Fossil': 1415,
    'Four Corners': 1415,
    'Fruitdale': 1415,
    'Garden Home-Whitford': 1415,
    'Gearhart': 1415,
    'Gervais': 1415,
    'Gilliam County': 1415,
    'Gladstone': 1415,
    'Glide': 1415,
    'Gold Beach': 1415,
    'Gold Hill': 1415,
    'Grand Ronde': 1415,
    'Grant County': 1415,
    'Grants Pass': 1415,
    'Green': 1415,
    'Gresham': 1415,
    'Happy Valley': 1415,
    'Harbor': 1415,
    'Harney County': 1415,
    'Harrisburg': 1415,
    'Hayesville': 1415,
    'Heppner': 1415,
    'Hermiston': 1415,
    'Hillsboro': 1415,
    'Hines': 1415,
    'Hood River': 1415,
    'Hood River County': 1415,
    'Hubbard': 1415,
    'Independence': 1415,
    'Irrigon': 1415,
    'Island City': 1415,
    'Jackson County': 1415,
    'Jacksonville': 1415,
    'Jefferson': 1415,
    'Jefferson County': 1415,
    'Jennings Lodge': 1415,
    'John Day': 1415,
    'Joseph': 1415,
    'Josephine County': 1415,
    'Junction City': 1415,
    'Keizer': 1415,
    'Kenton': 1415,
    'King City': 1415,
    'Klamath County': 1415,
    'Klamath Falls': 1415,
    'La Grande': 1415,
    'La Pine': 1415,
    'Lafayette': 1415,
    'Lake County': 1415,
    'Lake Oswego': 1415,
    'Lakeside': 1415,
    'Lakeview': 1415,
    'Lane County': 1415,
    'Lebanon': 1415,
    'Lents': 1415,
    'Lincoln Beach': 1415,
    'Lincoln City': 1415,
    'Lincoln County': 1415,
    'Linn County': 1415,
    'Lowell': 1415,
    'Lyons': 1415,
    'Madras': 1415,
    'Malheur County': 1415,
    'Marion County': 1415,
    'McMinnville': 1415,
    'Medford': 1415,
    'Merlin': 1415,
    'Metzger': 1415,
    'Mill City': 1415,
    'Millersburg': 1415,
    'Milton-Freewater': 1415,
    'Milwaukie': 1415,
    'Mission': 1415,
    'Molalla': 1415,
    'Monmouth': 1415,
    'Moro': 1415,
    'Morrow County': 1415,
    'Mount Angel': 1415,
    'Mount Hood Village': 1415,
    'Mulino': 1415,
    'Multnomah County': 1415,
    'Myrtle Creek': 1415,
    'Myrtle Point': 1415,
    'New Hope': 1415,
    'Newberg': 1415,
    'Newport': 1415,
    'North Bend': 1415,
    'North Plains': 1415,
    'North Portland': 1415,
    'Nyssa': 1415,
    'Oak Grove': 1415,
    'Oak Hills': 1415,
    'Oakridge': 1415,
    'Oatfield': 1415,
    'Odell': 1415,
    'Ontario': 1415,
    'Oregon City': 1415,
    'Pacific City': 1415,
    'Pendleton': 1415,
    'Philomath': 1415,
    'Phoenix': 1415,
    'Pilot Rock': 1415,
    'Polk County': 1415,
    'Port Orford': 1415,
    'Portland': 1415,
    'Prineville': 1415,
    'Rainier': 1415,
    'Raleigh Hills': 1415,
    'Redmond': 1415,
    'Redwood': 1415,
    'Reedsport': 1415,
    'Riddle': 1415,
    'Rockaway Beach': 1415,
    'Rockcreek': 1415,
    'Rogue River': 1415,
    'Rose Lodge': 1415,
    'Roseburg': 1415,
    'Roseburg North': 1415,
    'Saint Helens': 1415,
    'Salem': 1415,
    'Sandy': 1415,
    'Scappoose': 1415,
    'Seaside': 1415,
    'Shady Cove': 1415,
    'Sheridan': 1415,
    'Sherman County': 1415,
    'Sherwood': 1415,
    'Siletz': 1415,
    'Silverton': 1415,
    'Sisters': 1415,
    'South Lebanon': 1415,
    'Springfield': 1415,
    'Stafford': 1415,
    'Stanfield': 1415,
    'Stayton': 1415,
    'Sublimity': 1415,
    'Sunriver': 1415,
    'Sutherlin': 1415,
    'Sweet Home': 1415,
    'Talent': 1415,
    'Tangent': 1415,
    'Terrebonne': 1415,
    'The Dalles': 1415,
    'Three Rivers': 1415,
    'Tigard': 1415,
    'Tillamook': 1415,
    'Tillamook County': 1415,
    'Toledo': 1415,
    'Tri-City': 1415,
    'Troutdale': 1415,
    'Tualatin': 1415,
    'Turner': 1415,
    'Umatilla': 1415,
    'Umatilla County': 1415,
    'Union': 1415,
    'Union County': 1415,
    'Vale': 1415,
    'Veneta': 1415,
    'Vernonia': 1415,
    'Waldport': 1415,
    'Wallowa County': 1415,
    'Warm Springs': 1415,
    'Warren': 1415,
    'Warrenton': 1415,
    'Wasco County': 1415,
    'Washington County': 1415,
    'West Haven': 1415,
    'West Haven-Sylvan': 1415,
    'West Linn': 1415,
    'West Slope': 1415,
    'Wheeler County': 1415,
    'White City': 1415,
    'Willamina': 1415,
    'Williams': 1415,
    'Wilsonville': 1415,
    'Winston': 1415,
    'Wood Village': 1415,
    'Woodburn': 1415,
    'Yamhill': 1415,
    'Yamhill County': 1415,
    'Yoncalla': 1415,
    'Abbottstown': 1422,
    'Adams County': 1422,
    'Adamstown': 1422,
    'Akron': 1422,
    'Albion': 1422,
    'Alburtis': 1422,
    'Aldan': 1422,
    'Aliquippa': 1422,
    'Allegheny County': 1422,
    'Alleghenyville': 1422,
    'Allentown': 1422,
    'Allison Park': 1422,
    'Almedia': 1422,
    'Altoona': 1422,
    'Ambler': 1422,
    'Ambridge': 1422,
    'Amity Gardens': 1422,
    'Ancient Oaks': 1422,
    'Annville': 1422,
    'Apollo': 1422,
    'Archbald': 1422,
    'Ardmore': 1422,
    'Arlington Heights': 1422,
    'Armstrong County': 1422,
    'Arnold': 1422,
    'Ashland': 1422,
    'Ashley': 1422,
    'Aspinwall': 1422,
    'Atglen': 1422,
    'Athens': 1422,
    'Audubon': 1422,
    'Avalon': 1422,
    'Avis': 1422,
    'Avoca': 1422,
    'Avon': 1422,
    'Avondale': 1422,
    'Avonia': 1422,
    'Back Mountain': 1422,
    'Baden': 1422,
    'Baidland': 1422,
    'Bainbridge': 1422,
    'Bakerstown': 1422,
    'Bala-Cynwyd': 1422,
    'Baldwin': 1422,
    'Bally': 1422,
    'Bangor': 1422,
    'Barnesboro': 1422,
    'Bath': 1422,
    'Bear Rocks': 1422,
    'Beaver': 1422,
    'Beaver County': 1422,
    'Beaver Falls': 1422,
    'Beaverdale': 1422,
    'Bedford': 1422,
    'Bedford County': 1422,
    'Bedminster': 1422,
    'Beech Mountain Lakes': 1422,
    'Belfast': 1422,
    'Bell Acres': 1422,
    'Belle Vernon': 1422,
    'Bellefonte': 1422,
    'Belleville': 1422,
    'Bellevue': 1422,
    'Bellwood': 1422,
    'Belmont': 1422,
    'Ben Avon': 1422,
    'Bentleyville': 1422,
    'Berks County': 1422,
    'Berlin': 1422,
    'Berwick': 1422,
    'Berwyn': 1422,
    'Bessemer': 1422,
    'Bethel Park': 1422,
    'Bethlehem': 1422,
    'Big Bass Lake': 1422,
    'Big Beaver': 1422,
    'Biglerville': 1422,
    'Birchwood Lakes': 1422,
    'Birdsboro': 1422,
    'Black Lick': 1422,
    'Blair County': 1422,
    'Blairsville': 1422,
    'Blakely': 1422,
    'Blandon': 1422,
    'Blawnox': 1422,
    'Bloomfield': 1422,
    'Bloomsburg': 1422,
    'Blossburg': 1422,
    'Blue Ball': 1422,
    'Blue Bell': 1422,
    'Boalsburg': 1422,
    'Boiling Springs': 1422,
    'Bonneauville': 1422,
    'Boothwyn': 1422,
    'Boswell': 1422,
    'Bowmansville': 1422,
    'Boyertown': 1422,
    'Brackenridge': 1422,
    'Braddock': 1422,
    'Braddock Hills': 1422,
    'Bradford': 1422,
    'Bradford County': 1422,
    'Bradford Woods': 1422,
    'Breinigsville': 1422,
    'Brentwood': 1422,
    'Bressler': 1422,
    'Brickerville': 1422,
    'Bridgeport': 1422,
    'Bridgeville': 1422,
    'Bristol': 1422,
    'Brittany Farms-Highlands': 1422,
    'Brockway': 1422,
    'Brodheadsville': 1422,
    'Brookhaven': 1422,
    'Brookville': 1422,
    'Broomall': 1422,
    'Brownstown': 1422,
    'Brownsville': 1422,
    'Browntown': 1422,
    'Bryn Athyn': 1422,
    'Bryn Mawr': 1422,
    'Bucks County': 1422,
    'Burgettstown': 1422,
    'Burnham': 1422,
    'Butler': 1422,
    'Butler County': 1422,
    'California': 1422,
    'Caln': 1422,
    'Calumet': 1422,
    'Cambria County': 1422,
    'Cambridge Springs': 1422,
    'Cameron County': 1422,
    'Camp Hill': 1422,
    'Campbelltown': 1422,
    'Canonsburg': 1422,
    'Canton': 1422,
    'Carbon County': 1422,
    'Carbondale': 1422,
    'Carlisle': 1422,
    'Carnegie': 1422,
    'Carnot-Moon': 1422,
    'Carroll Valley': 1422,
    'Castanea': 1422,
    'Castle Shannon': 1422,
    'Catasauqua': 1422,
    'Catawissa': 1422,
    'Cecil-Bishop': 1422,
    'Cementon': 1422,
    'Center City': 1422,
    'Centerville': 1422,
    'Central City': 1422,
    'Centre County': 1422,
    'Centre Hall': 1422,
    'Cetronia': 1422,
    'Chalfont': 1422,
    'Chambersburg': 1422,
    'Charleroi': 1422,
    'Cherryville': 1422,
    'Chester': 1422,
    'Chester County': 1422,
    'Chester Heights': 1422,
    'Chester Springs': 1422,
    'Chesterbrook': 1422,
    'Cheswick': 1422,
    'Chevy Chase Heights': 1422,
    'Chicora': 1422,
    'Chinchilla': 1422,
    'Christiana': 1422,
    'Church Hill': 1422,
    'Churchill': 1422,
    'Churchville': 1422,
    'Clairton': 1422,
    'Clarion': 1422,
    'Clarion County': 1422,
    'Clarks Green': 1422,
    'Clarks Summit': 1422,
    'Clay': 1422,
    'Claysburg': 1422,
    'Clearfield': 1422,
    'Clearfield County': 1422,
    'Cleona': 1422,
    'Clifton Heights': 1422,
    'Clinton County': 1422,
    'Clymer': 1422,
    'Coaldale': 1422,
    'Coatesville': 1422,
    'Cochranton': 1422,
    'Collegeville': 1422,
    'Collingdale': 1422,
    'Collinsburg': 1422,
    'Colonial Park': 1422,
    'Colony Park': 1422,
    'Columbia': 1422,
    'Columbia County': 1422,
    'Colwyn': 1422,
    'Conashaugh Lakes': 1422,
    'Conemaugh': 1422,
    'Conestoga': 1422,
    'Conneaut Lakeshore': 1422,
    'Connellsville': 1422,
    'Conshohocken': 1422,
    'Conway': 1422,
    'Conyngham': 1422,
    'Coopersburg': 1422,
    'Coplay': 1422,
    'Coraopolis': 1422,
    'Cornwall': 1422,
    'Cornwells Heights': 1422,
    'Corry': 1422,
    'Coudersport': 1422,
    'Crafton': 1422,
    'Cranberry Township': 1422,
    'Crawford County': 1422,
    'Cresson': 1422,
    'Cressona': 1422,
    'Croydon': 1422,
    'Cumberland County': 1422,
    'Curtisville': 1422,
    'Curwensville': 1422,
    'Dale': 1422,
    'Dallas': 1422,
    'Dallastown': 1422,
    'Dalton': 1422,
    'Danville': 1422,
    'Darby': 1422,
    'Dauphin County': 1422,
    'Davidsville': 1422,
    'Delaware County': 1422,
    'Delmont': 1422,
    'Denver': 1422,
    'Derry': 1422,
    'Devon': 1422,
    'Dewart': 1422,
    'Dickson City': 1422,
    'Dillsburg': 1422,
    'Donora': 1422,
    'Dormont': 1422,
    'Dorneyville': 1422,
    'Dover': 1422,
    'Downingtown': 1422,
    'Doylestown': 1422,
    'Dravosburg': 1422,
    'Dresher': 1422,
    'Drexel Hill': 1422,
    'Dublin': 1422,
    'DuBois': 1422,
    'Duboistown': 1422,
    'Dunbar': 1422,
    'Duncannon': 1422,
    'Duncansville': 1422,
    'Dunmore': 1422,
    'Dunnstown': 1422,
    'Dupont': 1422,
    'Duquesne': 1422,
    'Duryea': 1422,
    'Eagleview': 1422,
    'Eagleville': 1422,
    'Earlston': 1422,
    'East Bangor': 1422,
    'East Berlin': 1422,
    'East Berwick': 1422,
    'East Conemaugh': 1422,
    'East Earl': 1422,
    'East Greenville': 1422,
    'East Lansdowne': 1422,
    'East McKeesport': 1422,
    'East Petersburg': 1422,
    'East Pittsburgh': 1422,
    'East Stroudsburg': 1422,
    'East Uniontown': 1422,
    'East Washington': 1422,
    'East York': 1422,
    'Eastlawn Gardens': 1422,
    'Easton': 1422,
    'Ebensburg': 1422,
    'Economy': 1422,
    'Eddington': 1422,
    'Eddystone': 1422,
    'Edgewood': 1422,
    'Edgeworth': 1422,
    'Edinboro': 1422,
    'Edwardsville': 1422,
    'Effort': 1422,
    'Egypt': 1422,
    'Elim': 1422,
    'Elizabeth': 1422,
    'Elizabethtown': 1422,
    'Elizabethville': 1422,
    'Elk County': 1422,
    'Elkland': 1422,
    'Ellport': 1422,
    'Ellwood City': 1422,
    'Elverson': 1422,
    'Elysburg': 1422,
    'Emerald Lakes': 1422,
    'Emigsville': 1422,
    'Emmaus': 1422,
    'Emporium': 1422,
    'Emsworth': 1422,
    'Enhaut': 1422,
    'Enlow': 1422,
    'Enola': 1422,
    'Ephrata': 1422,
    'Erie': 1422,
    'Erie County': 1422,
    'Espy': 1422,
    'Etna': 1422,
    'Evans City': 1422,
    'Evansburg': 1422,
    'Everett': 1422,
    'Exeter': 1422,
    'Exton': 1422,
    'Factoryville': 1422,
    'Fairchance': 1422,
    'Fairdale': 1422,
    'Fairhope': 1422,
    'Fairless Hills': 1422,
    'Fairview': 1422,
    'Fairview-Ferndale': 1422,
    'Falls Creek': 1422,
    'Farrell': 1422,
    'Faxon': 1422,
    'Fayette County': 1422,
    'Fayetteville': 1422,
    'Feasterville': 1422,
    'Fellsburg': 1422,
    'Ferndale': 1422,
    'Fernway': 1422,
    'Fivepointville': 1422,
    'Fleetwood': 1422,
    'Flemington': 1422,
    'Flourtown': 1422,
    'Flying Hills': 1422,
    'Folcroft': 1422,
    'Folsom': 1422,
    'Ford City': 1422,
    'Forest City': 1422,
    'Forest County': 1422,
    'Forest Hills': 1422,
    'Fort Washington': 1422,
    'Forty Fort': 1422,
    'Foster Brook': 1422,
    'Fountain Hill': 1422,
    'Fox Chapel': 1422,
    'Fox Chase': 1422,
    'Fox Run': 1422,
    'Frackville': 1422,
    'Franklin': 1422,
    'Franklin County': 1422,
    'Franklin Park': 1422,
    'Fredericksburg': 1422,
    'Freedom': 1422,
    'Freeland': 1422,
    'Freemansburg': 1422,
    'Freeport': 1422,
    'Friedens': 1422,
    'Fullerton': 1422,
    'Fulton County': 1422,
    'Galeton': 1422,
    'Gallitzin': 1422,
    'Gap': 1422,
    'Garden View': 1422,
    'Gastonville': 1422,
    'Geistown': 1422,
    'Georgetown': 1422,
    'Gettysburg': 1422,
    'Gibsonia': 1422,
    'Gilbertsville': 1422,
    'Girard': 1422,
    'Girardville': 1422,
    'Glassport': 1422,
    'Glen Lyon': 1422,
    'Glen Rock': 1422,
    'Glenolden': 1422,
    'Glenshaw': 1422,
    'Glenside': 1422,
    'Gold Key Lake': 1422,
    'Grantley': 1422,
    'Green Tree': 1422,
    'Greencastle': 1422,
    'Greene County': 1422,
    'Greenfields': 1422,
    'Greenock': 1422,
    'Greensburg': 1422,
    'Greenville': 1422,
    'Greenwood': 1422,
    'Grill': 1422,
    'Grove City': 1422,
    'Guilford': 1422,
    'Guilford Siding': 1422,
    'Halfway House': 1422,
    'Hallam': 1422,
    'Hallstead': 1422,
    'Hamburg': 1422,
    'Hanover': 1422,
    'Harleigh': 1422,
    'Harleysville': 1422,
    'Harrisburg': 1422,
    'Harveys Lake': 1422,
    'Hasson Heights': 1422,
    'Hastings': 1422,
    'Hatboro': 1422,
    'Hatfield': 1422,
    'Hawley': 1422,
    'Hazleton': 1422,
    'Hebron': 1422,
    'Heidelberg': 1422,
    'Hellertown': 1422,
    'Hemlock Farms': 1422,
    'Hermitage': 1422,
    'Hershey': 1422,
    'Highland Park': 1422,
    'Highspire': 1422,
    'Hilldale': 1422,
    'Hiller': 1422,
    'Hokendauqua': 1422,
    'Hollidaysburg': 1422,
    'Homeacre-Lyndora': 1422,
    'Homer City': 1422,
    'Homestead': 1422,
    'Hometown': 1422,
    'Honesdale': 1422,
    'Honey Brook': 1422,
    'Hopwood': 1422,
    'Horsham': 1422,
    'Houserville': 1422,
    'Houston': 1422,
    'Hudson': 1422,
    'Hughestown': 1422,
    'Hughesville': 1422,
    'Hummels Wharf': 1422,
    'Hummelstown': 1422,
    'Huntingdon': 1422,
    'Huntingdon County': 1422,
    'Hyde': 1422,
    'Hyde Park': 1422,
    'Imperial': 1422,
    'Indian Mountain Lake': 1422,
    'Indiana': 1422,
    'Indiana County': 1422,
    'Industry': 1422,
    'Ingram': 1422,
    'Inkerman': 1422,
    'Intercourse': 1422,
    'Irwin': 1422,
    'Ivyland': 1422,
    'Jacksonwald': 1422,
    'Jacobus': 1422,
    'Jeannette': 1422,
    'Jefferson County': 1422,
    'Jefferson Hills': 1422,
    'Jenkintown': 1422,
    'Jermyn': 1422,
    'Jerome': 1422,
    'Jersey Shore': 1422,
    'Jessup': 1422,
    'Jim Thorpe': 1422,
    'Johnsonburg': 1422,
    'Johnstown': 1422,
    'Jonestown': 1422,
    'Juniata County': 1422,
    'Kane': 1422,
    'Kenhorst': 1422,
    'Kenilworth': 1422,
    'Kenmar': 1422,
    'Kennett Square': 1422,
    'King of Prussia': 1422,
    'Kingston': 1422,
    'Kittanning': 1422,
    'Knox': 1422,
    'Kulpmont': 1422,
    'Kulpsville': 1422,
    'Kutztown': 1422,
    'Lackawanna County': 1422,
    'Lafayette Hill': 1422,
    'Laflin': 1422,
    'Lake City': 1422,
    'Lake Heritage': 1422,
    'Lake Latonka': 1422,
    'Lake Meade': 1422,
    'Lake Wynonah': 1422,
    'Lakemont': 1422,
    'Lampeter': 1422,
    'Lancaster': 1422,
    'Lancaster County': 1422,
    'Landisville': 1422,
    'Langhorne': 1422,
    'Langhorne Manor': 1422,
    'Lansdale': 1422,
    'Lansdowne': 1422,
    'Lansford': 1422,
    'Laporte': 1422,
    'Larksville': 1422,
    'Latrobe': 1422,
    'Laureldale': 1422,
    'Laurys Station': 1422,
    'Lawnton': 1422,
    'Lawrence County': 1422,
    'Lawrence Park': 1422,
    'Lawson Heights': 1422,
    'Lebanon': 1422,
    'Lebanon County': 1422,
    'Lebanon South': 1422,
    'Leechburg': 1422,
    'Leesport': 1422,
    'Leetsdale': 1422,
    'Lehigh County': 1422,
    'Lehighton': 1422,
    'Leith-Hatfield': 1422,
    'Lemont': 1422,
    'Lemoyne': 1422,
    'Lenape Heights': 1422,
    'Leola': 1422,
    'Level Green': 1422,
    'Levittown': 1422,
    'Lewisburg': 1422,
    'Lewistown': 1422,
    'Liberty': 1422,
    'Light Street': 1422,
    'Ligonier': 1422,
    'Lima': 1422,
    'Limerick': 1422,
    'Lincoln': 1422,
    'Lincoln Park': 1422,
    'Linglestown': 1422,
    'Linntown': 1422,
    'Linwood': 1422,
    'Lionville': 1422,
    'Lititz': 1422,
    'Littlestown': 1422,
    'Lock Haven': 1422,
    'Loganville': 1422,
    'Lorane': 1422,
    'Loretto': 1422,
    'Lower Allen': 1422,
    'Lower Burrell': 1422,
    'Loyalhanna': 1422,
    'Luzerne': 1422,
    'Luzerne County': 1422,
    'Lycoming County': 1422,
    'Lykens': 1422,
    'Lynnwood-Pricedale': 1422,
    'Macungie': 1422,
    'Mahanoy City': 1422,
    'Malvern': 1422,
    'Manchester': 1422,
    'Manheim': 1422,
    'Manor': 1422,
    'Mansfield': 1422,
    'Maple Glen': 1422,
    'Marcus Hook': 1422,
    'Marianne': 1422,
    'Marienville': 1422,
    'Marietta': 1422,
    'Mars': 1422,
    'Marshallton': 1422,
    'Martinsburg': 1422,
    'Marysville': 1422,
    'Masontown': 1422,
    'Matamoras': 1422,
    'Mayfield': 1422,
    'Maytown': 1422,
    'McAdoo': 1422,
    'McConnellsburg': 1422,
    'McConnellstown': 1422,
    'McDonald': 1422,
    'McGovern': 1422,
    'McKean County': 1422,
    'McKees Rocks': 1422,
    'McKeesport': 1422,
    'McMurray': 1422,
    'McSherrystown': 1422,
    'Meadowood': 1422,
    'Meadville': 1422,
    'Mechanicsburg': 1422,
    'Mechanicsville': 1422,
    'Media': 1422,
    'Mercer': 1422,
    'Mercer County': 1422,
    'Mercersburg': 1422,
    'Meridian': 1422,
    'Meyersdale': 1422,
    'Middleburg': 1422,
    'Middletown': 1422,
    'Midland': 1422,
    'Midway': 1422,
    'Mifflin County': 1422,
    'Mifflinburg': 1422,
    'Mifflintown': 1422,
    'Mifflinville': 1422,
    'Milesburg': 1422,
    'Milford': 1422,
    'Mill Hall': 1422,
    'Millbourne': 1422,
    'Millersburg': 1422,
    'Millersville': 1422,
    'Millvale': 1422,
    'Milroy': 1422,
    'Milton': 1422,
    'Minersville': 1422,
    'Mohnton': 1422,
    'Monaca': 1422,
    'Monessen': 1422,
    'Monongahela': 1422,
    'Monroe County': 1422,
    'Monroeville': 1422,
    'Mont Alto': 1422,
    'Montgomery': 1422,
    'Montgomery County': 1422,
    'Montgomeryville': 1422,
    'Montour County': 1422,
    'Montoursville': 1422,
    'Montrose': 1422,
    'Moosic': 1422,
    'Morrisville': 1422,
    'Morton': 1422,
    'Moscow': 1422,
    'Mount Carmel': 1422,
    'Mount Cobb': 1422,
    'Mount Holly Springs': 1422,
    'Mount Joy': 1422,
    'Mount Lebanon': 1422,
    'Mount Oliver': 1422,
    'Mount Penn': 1422,
    'Mount Pleasant': 1422,
    'Mount Pocono': 1422,
    'Mount Union': 1422,
    'Mount Wolf': 1422,
    'Mountain Top': 1422,
    'Mountainhome': 1422,
    'Mountville': 1422,
    'Muhlenberg Park': 1422,
    'Muncy': 1422,
    'Mundys Corner': 1422,
    'Munhall': 1422,
    'Murrysville': 1422,
    'Muse': 1422,
    'Myerstown': 1422,
    'Nanticoke': 1422,
    'Nanty Glo': 1422,
    'Narberth': 1422,
    'Nazareth': 1422,
    'Nescopeck': 1422,
    'Nesquehoning': 1422,
    'New Beaver': 1422,
    'New Berlinville': 1422,
    'New Bloomfield': 1422,
    'New Brighton': 1422,
    'New Britain': 1422,
    'New Castle': 1422,
    'New Castle Northwest': 1422,
    'New Columbia': 1422,
    'New Cumberland': 1422,
    'New Eagle': 1422,
    'New Freedom': 1422,
    'New Holland': 1422,
    'New Hope': 1422,
    'New Kensington': 1422,
    'New Oxford': 1422,
    'New Philadelphia': 1422,
    'New Stanton': 1422,
    'New Wilmington': 1422,
    'Newmanstown': 1422,
    'Newport': 1422,
    'Newtown': 1422,
    'Newtown Grant': 1422,
    'Newville': 1422,
    'Nixon': 1422,
    'Norristown': 1422,
    'North Apollo': 1422,
    'North Belle Vernon': 1422,
    'North Braddock': 1422,
    'North Catasauqua': 1422,
    'North Charleroi': 1422,
    'North East': 1422,
    'North Versailles': 1422,
    'North Wales': 1422,
    'North Warren': 1422,
    'North York': 1422,
    'Northampton': 1422,
    'Northampton County': 1422,
    'Northern Cambria': 1422,
    'Northumberland': 1422,
    'Northumberland County': 1422,
    'Northwest Harborcreek': 1422,
    'Norwood': 1422,
    'Oak Hills': 1422,
    'Oakdale': 1422,
    'Oakland': 1422,
    'Oakmont': 1422,
    'Oakwood': 1422,
    'Ohioville': 1422,
    'Oil City': 1422,
    'Old Forge': 1422,
    'Old Orchard': 1422,
    'Oley': 1422,
    'Oliver': 1422,
    'Olyphant': 1422,
    'Orchard Hills': 1422,
    'Oreland': 1422,
    'Orwigsburg': 1422,
    'Osceola Mills': 1422,
    'Oxford': 1422,
    'Palmdale': 1422,
    'Palmer Heights': 1422,
    'Palmerton': 1422,
    'Palmyra': 1422,
    'Palo Alto': 1422,
    'Paoli': 1422,
    'Paradise': 1422,
    'Park Forest Village': 1422,
    'Parkesburg': 1422,
    'Parkside': 1422,
    'Parkville': 1422,
    'Patton': 1422,
    'Paxtang': 1422,
    'Paxtonia': 1422,
    'Pen Argyl': 1422,
    'Penbrook': 1422,
    'Penn Estates': 1422,
    'Penn Hills': 1422,
    'Penn Wynne': 1422,
    'Penndel': 1422,
    'Pennsburg': 1422,
    'Pennside': 1422,
    'Pennsport': 1422,
    'Pennville': 1422,
    'Penryn': 1422,
    'Perkasie': 1422,
    'Perry County': 1422,
    'Perryopolis': 1422,
    'Philadelphia': 1422,
    'Philadelphia County': 1422,
    'Philipsburg': 1422,
    'Phoenixville': 1422,
    'Pike County': 1422,
    'Pine Grove': 1422,
    'Pine Grove Mills': 1422,
    'Pine Ridge': 1422,
    'Pitcairn': 1422,
    'Pittsburgh': 1422,
    'Pittston': 1422,
    'Plains': 1422,
    'Pleasant Gap': 1422,
    'Pleasant Hill': 1422,
    'Pleasant Hills': 1422,
    'Plum': 1422,
    'Plumsteadville': 1422,
    'Plymouth': 1422,
    'Plymouth Meeting': 1422,
    'Pocono Pines': 1422,
    'Pocono Ranch Lands': 1422,
    'Point Marion': 1422,
    'Port Allegany': 1422,
    'Port Carbon': 1422,
    'Port Vue': 1422,
    'Portage': 1422,
    'Potter County': 1422,
    'Pottsgrove': 1422,
    'Pottstown': 1422,
    'Pottsville': 1422,
    'Progress': 1422,
    'Prospect': 1422,
    'Prospect Park': 1422,
    'Punxsutawney': 1422,
    'Pymatuning Central': 1422,
    'Quakertown': 1422,
    'Quarryville': 1422,
    'Radnor': 1422,
    'Rankin': 1422,
    'Raubsville': 1422,
    'Reading': 1422,
    'Reamstown': 1422,
    'Red Hill': 1422,
    'Red Lion': 1422,
    'Reiffton': 1422,
    'Reinholds': 1422,
    'Rennerdale': 1422,
    'Renovo': 1422,
    'Republic': 1422,
    'Reynolds Heights': 1422,
    'Reynoldsville': 1422,
    'Rheems': 1422,
    'Richboro': 1422,
    'Richland': 1422,
    'Richlandtown': 1422,
    'Ridgway': 1422,
    'Ridley Park': 1422,
    'River View Park': 1422,
    'Riverside': 1422,
    'Roaring Spring': 1422,
    'Robesonia': 1422,
    'Rochester': 1422,
    'Rockledge': 1422,
    'Roseto': 1422,
    'Rothsville': 1422,
    'Royalton': 1422,
    'Royersford': 1422,
    'Russell': 1422,
    'Russellton': 1422,
    'Rutherford': 1422,
    'Saint Clair': 1422,
    'Saint Lawrence': 1422,
    'Saint Marys': 1422,
    'Salix': 1422,
    'Salunga': 1422,
    'Sanatoga': 1422,
    'Sand Hill': 1422,
    'Sandy': 1422,
    'Saw Creek': 1422,
    'Saxonburg': 1422,
    'Saylorsburg': 1422,
    'Sayre': 1422,
    'Schlusser': 1422,
    'Schnecksville': 1422,
    'Schoeneck': 1422,
    'Schuylkill County': 1422,
    'Schuylkill Haven': 1422,
    'Schwenksville': 1422,
    'Scotland': 1422,
    'Scottdale': 1422,
    'Scranton': 1422,
    'Selinsgrove': 1422,
    'Sellersville': 1422,
    'Seneca': 1422,
    'Seven Fields': 1422,
    'Sewickley': 1422,
    'Shamokin': 1422,
    'Shamokin Dam': 1422,
    'Shanor-Northvue': 1422,
    'Sharon': 1422,
    'Sharon Hill': 1422,
    'Sharpsburg': 1422,
    'Sharpsville': 1422,
    'Shavertown': 1422,
    'Sheffield': 1422,
    'Shenandoah': 1422,
    'Shenandoah Heights': 1422,
    'Shillington': 1422,
    'Shiloh': 1422,
    'Shinglehouse': 1422,
    'Shippensburg': 1422,
    'Shiremanstown': 1422,
    'Shoemakersville': 1422,
    'Shrewsbury': 1422,
    'Sierra View': 1422,
    'Simpson': 1422,
    'Sinking Spring': 1422,
    'Skippack': 1422,
    'Skyline View': 1422,
    'Slatington': 1422,
    'Slippery Rock': 1422,
    'Smethport': 1422,
    'Snyder County': 1422,
    'Somerset': 1422,
    'Somerset County': 1422,
    'Souderton': 1422,
    'South Coatesville': 1422,
    'South Connellsville': 1422,
    'South Greensburg': 1422,
    'South Park Township': 1422,
    'South Pottstown': 1422,
    'South Temple': 1422,
    'South Uniontown': 1422,
    'South Waverly': 1422,
    'South Williamsport': 1422,
    'Southmont': 1422,
    'Southwest Greensburg': 1422,
    'Spangler': 1422,
    'Speers': 1422,
    'Spinnerstown': 1422,
    'Spring City': 1422,
    'Spring Grove': 1422,
    'Spring House': 1422,
    'Spring Mount': 1422,
    'Spring Ridge': 1422,
    'Springdale': 1422,
    'Springfield': 1422,
    'Spry': 1422,
    'State College': 1422,
    'State Line': 1422,
    'Steelton': 1422,
    'Stewartstown': 1422,
    'Stiles': 1422,
    'Stoneboro': 1422,
    'Stony Creek Mills': 1422,
    'Stonybrook': 1422,
    'Stormstown': 1422,
    'Stowe': 1422,
    'Strasburg': 1422,
    'Stroudsburg': 1422,
    'Sturgeon': 1422,
    'Sugarcreek': 1422,
    'Sullivan County': 1422,
    'Summit Hill': 1422,
    'Sun Valley': 1422,
    'Sunbury': 1422,
    'Sunrise Lake': 1422,
    'Susquehanna': 1422,
    'Susquehanna County': 1422,
    'Susquehanna Trails': 1422,
    'Swarthmore': 1422,
    'Swartzville': 1422,
    'Swissvale': 1422,
    'Swoyersville': 1422,
    'Sykesville': 1422,
    'Tacony': 1422,
    'Tamaqua': 1422,
    'Tannersville': 1422,
    'Tarentum': 1422,
    'Tatamy': 1422,
    'Taylor': 1422,
    'Telford': 1422,
    'Temple': 1422,
    'Terre Hill': 1422,
    'The Hideout': 1422,
    'Thompsonville': 1422,
    'Thorndale': 1422,
    'Throop': 1422,
    'Tinicum': 1422,
    'Tioga County': 1422,
    'Tionesta': 1422,
    'Tipton': 1422,
    'Titusville': 1422,
    'Toftrees': 1422,
    'Topton': 1422,
    'Toughkenamon': 1422,
    'Towamensing Trails': 1422,
    'Towanda': 1422,
    'Tower City': 1422,
    'Trafford': 1422,
    'Trainer': 1422,
    'Trappe': 1422,
    'Treasure Lake': 1422,
    'Tremont': 1422,
    'Trevorton': 1422,
    'Trevose': 1422,
    'Trexlertown': 1422,
    'Trooper': 1422,
    'Troy': 1422,
    'Trucksville': 1422,
    'Tullytown': 1422,
    'Tunkhannock': 1422,
    'Turtle Creek': 1422,
    'Tyrone': 1422,
    'Union City': 1422,
    'Union County': 1422,
    'Uniontown': 1422,
    'Upland': 1422,
    'Upper Saint Clair': 1422,
    'Valley Green': 1422,
    'Valley View': 1422,
    'Vandergrift': 1422,
    'Venango County': 1422,
    'Verona': 1422,
    'Versailles': 1422,
    'Village Green-Green Ridge': 1422,
    'Village Shires': 1422,
    'Vinco': 1422,
    'Wallenpaupack Lake Estates': 1422,
    'Walnutport': 1422,
    'Warminster Heights': 1422,
    'Warren': 1422,
    'Warren County': 1422,
    'Washington': 1422,
    'Washington County': 1422,
    'Waterford': 1422,
    'Watsontown': 1422,
    'Waymart': 1422,
    'Wayne': 1422,
    'Wayne County': 1422,
    'Wayne Heights': 1422,
    'Waynesboro': 1422,
    'Waynesburg': 1422,
    'Weatherly': 1422,
    'Weigelstown': 1422,
    'Weissport East': 1422,
    'Wellsboro': 1422,
    'Wernersville': 1422,
    'Wescosville': 1422,
    'Wesleyville': 1422,
    'West Chester': 1422,
    'West Conshohocken': 1422,
    'West Easton': 1422,
    'West Fairview': 1422,
    'West Grove': 1422,
    'West Hamburg': 1422,
    'West Hazleton': 1422,
    'West Hills': 1422,
    'West Homestead': 1422,
    'West Kittanning': 1422,
    'West Lawn': 1422,
    'West Leechburg': 1422,
    'West Mayfield': 1422,
    'West Mifflin': 1422,
    'West Newton': 1422,
    'West Norriton': 1422,
    'West Pittston': 1422,
    'West Reading': 1422,
    'West View': 1422,
    'West Wyoming': 1422,
    'West Wyomissing': 1422,
    'West York': 1422,
    'Westfield': 1422,
    'Westmont': 1422,
    'Westmoreland County': 1422,
    'Wharton': 1422,
    'Whitaker': 1422,
    'White Haven': 1422,
    'White Oak': 1422,
    'Whitehall': 1422,
    'Whitehall Township': 1422,
    'Whitfield': 1422,
    'Whitman': 1422,
    'Wickerham Manor-Fisher': 1422,
    'Wilkes-Barre': 1422,
    'Wilkinsburg': 1422,
    'Williamsburg': 1422,
    'Williamsport': 1422,
    'Williamstown': 1422,
    'Willow Grove': 1422,
    'Willow Street': 1422,
    'Wilmerding': 1422,
    'Wilson': 1422,
    'Wind Gap': 1422,
    'Windber': 1422,
    'Windsor': 1422,
    'Wolfdale': 1422,
    'Womelsdorf': 1422,
    'Woodbourne': 1422,
    'Woodland Heights': 1422,
    'Woodlyn': 1422,
    'Woodside': 1422,
    'Wormleysburg': 1422,
    'Woxall': 1422,
    'Wrightsville': 1422,
    'Wyncote': 1422,
    'Wyndmoor': 1422,
    'Wyoming': 1422,
    'Wyoming County': 1422,
    'Wyomissing': 1422,
    'Wyomissing Hills': 1422,
    'Yardley': 1422,
    'Yeadon': 1422,
    'Yeagertown': 1422,
    'Yoe': 1422,
    'York': 1422,
    'York County': 1422,
    'Yorklyn': 1422,
    'Youngsville': 1422,
    'Youngwood': 1422,
    'Zelienople': 1422,
    'Zion': 1422,
    'Adjuntas': 1449,
    'Aguada': 1449,
    'Aguadilla': 1449,
    'Aguas Buenas': 1449,
    'Aguas Claras': 1449,
    'Aguilita': 1449,
    'Aibonito': 1449,
    'Añasco': 1449,
    'Animas': 1449,
    'Antón Ruiz': 1449,
    'Arecibo': 1449,
    'Arroyo': 1449,
    'Bairoa': 1449,
    'Bajadero': 1449,
    'Bajandas': 1449,
    'Barahona': 1449,
    'Barceloneta': 1449,
    'Barranquitas': 1449,
    'Bartolo': 1449,
    'Bayamon': 1449,
    'Benitez': 1449,
    'Betances': 1449,
    'Boqueron': 1449,
    'Boquerón, Cabo Rojo': 1449,
    'Brenas': 1449,
    'Buena Vista': 1449,
    'Bufalo': 1449,
    'Caban': 1449,
    'Cabo Rojo': 1449,
    'Cabo Rojo Municipio': 1449,
    'Cacao': 1449,
    'Caguas': 1449,
    'Campanilla': 1449,
    'Campo Rico': 1449,
    'Camuy': 1449,
    'Candelaria': 1449,
    'Candelaria Arenas': 1449,
    'Candelero Arriba': 1449,
    'Canóvanas': 1449,
    'Capitanejo': 1449,
    'Carolina': 1449,
    'Carrizales': 1449,
    'Cataño': 1449,
    'Cayey': 1449,
    'Cayuco': 1449,
    'Ceiba': 1449,
    'Ceiba Municipio': 1449,
    'Celada': 1449,
    'Central Aguirre': 1449,
    'Ciales': 1449,
    'Ciales Municipio': 1449,
    'Cidra': 1449,
    'Coamo': 1449,
    'Coco': 1449,
    'Comerío': 1449,
    'Comerío Municipio': 1449,
    'Comunas': 1449,
    'Coquí': 1449,
    'Corazón': 1449,
    'Corcovado': 1449,
    'Corozal': 1449,
    'Corozal Municipio': 1449,
    'Coto Laurel': 1449,
    'Coto Norte': 1449,
    'Culebra': 1449,
    'Culebra barrio-pueblo': 1449,
    'Daguao': 1449,
    'Dorado': 1449,
    'Dorado Municipio': 1449,
    'El Mangó': 1449,
    'El Negro': 1449,
    'El Ojo': 1449,
    'Emajagua': 1449,
    'Esperanza': 1449,
    'Espino': 1449,
    'Estancias de Florida': 1449,
    'Fajardo': 1449,
    'Fajardo Municipio': 1449,
    'Florida': 1449,
    'Franquez': 1449,
    'Fuig': 1449,
    'G. L. Garcia': 1449,
    'Galateo': 1449,
    'Garrochales': 1449,
    'Guánica': 1449,
    'Guánica Municipio': 1449,
    'Guayabal': 1449,
    'Guayama': 1449,
    'Guayama Municipio': 1449,
    'Guayanilla': 1449,
    'Guaynabo': 1449,
    'Gurabo': 1449,
    'Gurabo Municipio': 1449,
    'H. Rivera Colon': 1449,
    'Hatillo': 1449,
    'Hatillo Municipio': 1449,
    'Hato Arriba': 1449,
    'Hato Candal': 1449,
    'Hormigueros': 1449,
    'Hormigueros Municipio': 1449,
    'Humacao': 1449,
    'Imbery': 1449,
    'Indios': 1449,
    'Ingenio': 1449,
    'Isabel Segunda': 1449,
    'Isabela': 1449,
    'Jagual': 1449,
    'Jauca': 1449,
    'Jayuya': 1449,
    'Jobos': 1449,
    'Juana Díaz': 1449,
    'Juana Díaz Municipio': 1449,
    'Juncal': 1449,
    'Juncos': 1449,
    'La Alianza': 1449,
    'La Dolores': 1449,
    'La Fermina': 1449,
    'La Luisa': 1449,
    'La Parguera': 1449,
    'La Playa': 1449,
    'La Plena': 1449,
    'Lajas': 1449,
    'Lajas Municipio': 1449,
    'Lamboglia': 1449,
    'Lares': 1449,
    'Las Marias': 1449,
    'Las Marías Municipio': 1449,
    'Las Ochenta': 1449,
    'Las Ollas': 1449,
    'Las Piedras': 1449,
    'Las Piedras Municipio': 1449,
    'Levittown': 1449,
    'Liborio Negron Torres': 1449,
    'Lluveras': 1449,
    'Loíza': 1449,
    'Lomas': 1449,
    'Los Llanos': 1449,
    'Luis Lloréns Torres': 1449,
    'Luis M. Cintron': 1449,
    'Luquillo': 1449,
    'Luquillo Municipio': 1449,
    'Luyando': 1449,
    'Magas Arriba': 1449,
    'Manatí': 1449,
    'Maria Antonia': 1449,
    'Mariano Colón': 1449,
    'Maricao': 1449,
    'Maricao Municipio': 1449,
    'Martorell': 1449,
    'Marueño': 1449,
    'Maunabo': 1449,
    'Maunabo Municipio': 1449,
    'Mayagüez': 1449,
    'Miranda': 1449,
    'Moca': 1449,
    'Moca Municipio': 1449,
    'Monserrate': 1449,
    'Monte Grande': 1449,
    'Mora': 1449,
    'Morovis': 1449,
    'Mucarabones': 1449,
    'Naguabo': 1449,
    'Naguabo Municipio': 1449,
    'Naranjito': 1449,
    'Naranjito Municipio': 1449,
    'Olimpo': 1449,
    'Orocovis': 1449,
    'Orocovis Municipio': 1449,
    'Pájaros': 1449,
    'Pajonal': 1449,
    'Palmarejo': 1449,
    'Palmas': 1449,
    'Palmer': 1449,
    'Palo Seco': 1449,
    'Palomas': 1449,
    'Parcelas La Milagrosa': 1449,
    'Parcelas Nuevas': 1449,
    'Parcelas Peñuelas': 1449,
    'Pastos': 1449,
    'Patillas': 1449,
    'Patillas Municipio': 1449,
    'Peña Pobre': 1449,
    'Peñuelas': 1449,
    'Piedra Gorda': 1449,
    'Playa Fortuna': 1449,
    'Playita': 1449,
    'Playita Cortada': 1449,
    'Pole Ojea': 1449,
    'Ponce': 1449,
    'Potala Pastillo': 1449,
    'Pueblito del Rio': 1449,
    'Puerto Real': 1449,
    'Punta Santiago': 1449,
    'Quebrada': 1449,
    'Quebradillas': 1449,
    'Quebradillas Municipio': 1449,
    'Rafael Capo': 1449,
    'Rafael Gonzalez': 1449,
    'Rafael Hernandez': 1449,
    'Ramos': 1449,
    'Rincón': 1449,
    'Rincón Municipio': 1449,
    'Río Blanco': 1449,
    'Río Cañas Abajo': 1449,
    'Río Grande': 1449,
    'Río Grande Municipio': 1449,
    'Rio Lajas': 1449,
    'Río Piedras': 1449,
    'Rosa Sanchez': 1449,
    'Sabana': 1449,
    'Sabana Eneas': 1449,
    'Sabana Grande': 1449,
    'Sabana Grande Municipio': 1449,
    'Sabana Hoyos': 1449,
    'Sabana Seca': 1449,
    'Salinas': 1449,
    'San Antonio': 1449,
    'San Germán Municipio': 1449,
    'San Isidro': 1449,
    'San José': 1449,
    'San Juan': 1449,
    'San Lorenzo': 1449,
    'San Sebastián': 1449,
    'Santa Barbara': 1449,
    'Santa Clara': 1449,
    'Santa Isabel': 1449,
    'Santa Isabel Municipio': 1449,
    'Santo Domingo': 1449,
    'Santurce': 1449,
    'Stella': 1449,
    'Suárez': 1449,
    'Sumidero': 1449,
    'Tallaboa': 1449,
    'Tallaboa Alta': 1449,
    'Tiburones': 1449,
    'Tierras Nuevas Poniente': 1449,
    'Toa Alta': 1449,
    'Toa Alta Municipio': 1449,
    'Toa Baja': 1449,
    'Trujillo Alto': 1449,
    'Trujillo Alto Municipio': 1449,
    'Utuado': 1449,
    'Utuado barrio-pueblo': 1449,
    'Vázquez': 1449,
    'Vega Alta': 1449,
    'Vega Alta Municipio': 1449,
    'Vega Baja': 1449,
    'Vieques': 1449,
    'Vieques Municipality': 1449,
    'Villalba': 1449,
    'Villalba Municipio': 1449,
    'Yabucoa': 1449,
    'Yabucoa Municipio': 1449,
    'Yauco': 1449,
    'Yaurel': 1449,
    'Ashaway': 1461,
    'Barrington': 1461,
    'Bradford': 1461,
    'Bristol': 1461,
    'Bristol County': 1461,
    'Central Falls': 1461,
    'Charlestown': 1461,
    'Chepachet': 1461,
    'Coventry': 1461,
    'Cranston': 1461,
    'Cumberland': 1461,
    'Cumberland Hill': 1461,
    'East Greenwich': 1461,
    'East Providence': 1461,
    'Exeter': 1461,
    'Foster': 1461,
    'Greenville': 1461,
    'Harrisville': 1461,
    'Hope Valley': 1461,
    'Hopkinton': 1461,
    'Jamestown': 1461,
    'Johnston': 1461,
    'Kent County': 1461,
    'Kingston': 1461,
    'Lincoln': 1461,
    'Melville': 1461,
    'Middletown': 1461,
    'Narragansett': 1461,
    'Narragansett Pier': 1461,
    'New Shoreham': 1461,
    'Newport': 1461,
    'Newport County': 1461,
    'Newport East': 1461,
    'North Kingstown': 1461,
    'North Providence': 1461,
    'North Scituate': 1461,
    'North Smithfield': 1461,
    'Pascoag': 1461,
    'Pawtucket': 1461,
    'Portsmouth': 1461,
    'Providence': 1461,
    'Providence County': 1461,
    'Smithfield': 1461,
    'South Kingstown': 1461,
    'Tiverton': 1461,
    'Valley Falls': 1461,
    'Wakefield-Peacedale': 1461,
    'Warren': 1461,
    'Warwick': 1461,
    'Washington County': 1461,
    'West Greenwich': 1461,
    'West Warwick': 1461,
    'Westerly': 1461,
    'Woonsocket': 1461,
    'Abbeville': 1443,
    'Abbeville County': 1443,
    'Aiken': 1443,
    'Aiken County': 1443,
    'Allendale': 1443,
    'Allendale County': 1443,
    'Anderson': 1443,
    'Anderson County': 1443,
    'Andrews': 1443,
    'Arcadia': 1443,
    'Arial': 1443,
    'Awendaw': 1443,
    'Bamberg': 1443,
    'Bamberg County': 1443,
    'Barnwell': 1443,
    'Barnwell County': 1443,
    'Batesburg': 1443,
    'Batesburg-Leesville': 1443,
    'Beaufort': 1443,
    'Beaufort County': 1443,
    'Belton': 1443,
    'Belvedere': 1443,
    'Bennettsville': 1443,
    'Berea': 1443,
    'Berkeley County': 1443,
    'Bishopville': 1443,
    'Blacksburg': 1443,
    'Blackville': 1443,
    'Bluffton': 1443,
    'Blythewood': 1443,
    'Boiling Springs': 1443,
    'Bonneau Beach': 1443,
    'Brookdale': 1443,
    'Buffalo': 1443,
    'Burnettown': 1443,
    'Burton': 1443,
    'Calhoun County': 1443,
    'Calhoun Falls': 1443,
    'Camden': 1443,
    'Cane Savannah': 1443,
    'Catawba': 1443,
    'Cayce': 1443,
    'Centerville': 1443,
    'Central': 1443,
    'Chapin': 1443,
    'Charleston': 1443,
    'Charleston County': 1443,
    'Cheraw': 1443,
    'Cherokee County': 1443,
    'Cherryvale': 1443,
    'Chester': 1443,
    'Chester County': 1443,
    'Chesterfield': 1443,
    'Chesterfield County': 1443,
    'City View': 1443,
    'Clarendon County': 1443,
    'Clearwater': 1443,
    'Clemson': 1443,
    'Clinton': 1443,
    'Clover': 1443,
    'Colleton County': 1443,
    'Columbia': 1443,
    'Conway': 1443,
    'Cowpens': 1443,
    'Dalzell': 1443,
    'Darlington': 1443,
    'Darlington County': 1443,
    'Denmark': 1443,
    'Dentsville': 1443,
    'Dillon': 1443,
    'Dillon County': 1443,
    'Dorchester County': 1443,
    'Due West': 1443,
    'Duncan': 1443,
    'Dunean': 1443,
    'Easley': 1443,
    'East Gaffney': 1443,
    'East Sumter': 1443,
    'Edgefield': 1443,
    'Edgefield County': 1443,
    'Edisto': 1443,
    'Elgin': 1443,
    'Estill': 1443,
    'Eureka Mill': 1443,
    'Fairfax': 1443,
    'Fairfield County': 1443,
    'Fairforest': 1443,
    'Five Forks': 1443,
    'Florence': 1443,
    'Florence County': 1443,
    'Folly Beach': 1443,
    'Forest Acres': 1443,
    'Forestbrook': 1443,
    'Fort Mill': 1443,
    'Fountain Inn': 1443,
    'Gadsden': 1443,
    'Gaffney': 1443,
    'Gantt': 1443,
    'Garden City': 1443,
    'Gaston': 1443,
    'Georgetown': 1443,
    'Georgetown County': 1443,
    'Gloverville': 1443,
    'Golden Grove': 1443,
    'Goose Creek': 1443,
    'Graniteville': 1443,
    'Great Falls': 1443,
    'Greenville': 1443,
    'Greenville County': 1443,
    'Greenwood': 1443,
    'Greenwood County': 1443,
    'Greer': 1443,
    'Hampton': 1443,
    'Hampton County': 1443,
    'Hanahan': 1443,
    'Hardeeville': 1443,
    'Hartsville': 1443,
    'Hilton Head': 1443,
    'Hilton Head Island': 1443,
    'Holly Hill': 1443,
    'Hollywood': 1443,
    'Homeland Park': 1443,
    'Honea Path': 1443,
    'Hopkins': 1443,
    'Horry County': 1443,
    'India Hook': 1443,
    'Inman': 1443,
    'Inman Mills': 1443,
    'Irmo': 1443,
    'Irwin': 1443,
    'Isle of Palms': 1443,
    'Iva': 1443,
    'Jackson': 1443,
    'James Island': 1443,
    'Jasper County': 1443,
    'Joanna': 1443,
    'Johnsonville': 1443,
    'Johnston': 1443,
    'Judson': 1443,
    'Kershaw': 1443,
    'Kershaw County': 1443,
    'Kiawah Island': 1443,
    'Kingstree': 1443,
    'Ladson': 1443,
    'Lake City': 1443,
    'Lake Murray of Richland': 1443,
    'Lake Secession': 1443,
    'Lake Wylie': 1443,
    'Lakewood': 1443,
    'Lancaster': 1443,
    'Lancaster County': 1443,
    'Lancaster Mill': 1443,
    'Landrum': 1443,
    'Langley': 1443,
    'Latta': 1443,
    'Laurel Bay': 1443,
    'Laurens': 1443,
    'Laurens County': 1443,
    'Lee County': 1443,
    'Leesville': 1443,
    'Lesslie': 1443,
    'Lexington': 1443,
    'Lexington County': 1443,
    'Liberty': 1443,
    'Lincolnville': 1443,
    'Little River': 1443,
    'Loris': 1443,
    'Lugoff': 1443,
    'Lyman': 1443,
    'Manning': 1443,
    'Marion': 1443,
    'Marion County': 1443,
    'Marlboro County': 1443,
    'Mauldin': 1443,
    'Mayo': 1443,
    'McColl': 1443,
    'McCormick': 1443,
    'McCormick County': 1443,
    'Meggett': 1443,
    'Monarch Mill': 1443,
    'Moncks Corner': 1443,
    'Mount Pleasant': 1443,
    'Mullins': 1443,
    'Murphys Estates': 1443,
    'Murrells Inlet': 1443,
    'Myrtle Beach': 1443,
    'New Ellenton': 1443,
    'Newberry': 1443,
    'Newberry County': 1443,
    'Newport': 1443,
    'Ninety Six': 1443,
    'North Augusta': 1443,
    'North Charleston': 1443,
    'North Hartsville': 1443,
    'North Myrtle Beach': 1443,
    'Northlake': 1443,
    'Oak Grove': 1443,
    'Oakland': 1443,
    'Oconee County': 1443,
    'Orangeburg': 1443,
    'Orangeburg County': 1443,
    'Pacolet': 1443,
    'Pageland': 1443,
    'Pamplico': 1443,
    'Parker': 1443,
    'Pendleton': 1443,
    'Pickens': 1443,
    'Pickens County': 1443,
    'Piedmont': 1443,
    'Pineridge': 1443,
    'Port Royal': 1443,
    'Powdersville': 1443,
    'Privateer': 1443,
    'Prosperity': 1443,
    'Ravenel': 1443,
    'Red Bank': 1443,
    'Red Hill': 1443,
    'Richland County': 1443,
    'Ridgeland': 1443,
    'Ridgeville': 1443,
    'Rock Hill': 1443,
    'Roebuck': 1443,
    'Saint Andrews': 1443,
    'Saint George': 1443,
    'Saint Matthews': 1443,
    'Saint Stephen': 1443,
    'Saluda': 1443,
    'Saluda County': 1443,
    'Sangaree': 1443,
    'Sans Souci': 1443,
    'Saxon': 1443,
    'Seabrook Island': 1443,
    'Seneca': 1443,
    'Seven Oaks': 1443,
    'Shell Point': 1443,
    'Simpsonville': 1443,
    'Slater-Marietta': 1443,
    'Socastee': 1443,
    'South Congaree': 1443,
    'South Sumter': 1443,
    'Southern Shops': 1443,
    'Spartanburg': 1443,
    'Spartanburg County': 1443,
    'Springdale': 1443,
    'Stateburg': 1443,
    'Sullivans Island': 1443,
    'Summerville': 1443,
    'Sumter': 1443,
    'Sumter County': 1443,
    'Surfside Beach': 1443,
    'Taylors': 1443,
    'Tega Cay': 1443,
    'Tigerville': 1443,
    'Timmonsville': 1443,
    'Travelers Rest': 1443,
    'Union': 1443,
    'Union County': 1443,
    'Utica': 1443,
    'Valley Falls': 1443,
    'Varnville': 1443,
    'Wade Hampton': 1443,
    'Walhalla': 1443,
    'Walterboro': 1443,
    'Ware Shoals': 1443,
    'Warrenville': 1443,
    'Watts Mills': 1443,
    'Wedgefield': 1443,
    'Wedgewood': 1443,
    'Welcome': 1443,
    'Wellford': 1443,
    'West Columbia': 1443,
    'Westminster': 1443,
    'Whitmire': 1443,
    'Wilkinson Heights': 1443,
    'Williamsburg County': 1443,
    'Williamston': 1443,
    'Williston': 1443,
    'Winnsboro': 1443,
    'Winnsboro Mills': 1443,
    'Woodfield': 1443,
    'Woodruff': 1443,
    'York': 1443,
    'York County': 1443,
    'Aberdeen': 1445,
    'Alexandria': 1445,
    'Armour': 1445,
    'Aurora County': 1445,
    'Baltic': 1445,
    'Beadle County': 1445,
    'Belle Fourche': 1445,
    'Bennett County': 1445,
    'Beresford': 1445,
    'Bison': 1445,
    'Blackhawk': 1445,
    'Bon Homme County': 1445,
    'Box Elder': 1445,
    'Brandon': 1445,
    'Britton': 1445,
    'Brookings': 1445,
    'Brookings County': 1445,
    'Brown County': 1445,
    'Brule County': 1445,
    'Buffalo': 1445,
    'Buffalo County': 1445,
    'Burke': 1445,
    'Butte County': 1445,
    'Campbell County': 1445,
    'Canton': 1445,
    'Chamberlain': 1445,
    'Charles Mix County': 1445,
    'Clark': 1445,
    'Clark County': 1445,
    'Clay County': 1445,
    'Clear Lake': 1445,
    'Codington County': 1445,
    'Colonial Pine Hills': 1445,
    'Corson County': 1445,
    'Crooks': 1445,
    'Custer': 1445,
    'Custer County': 1445,
    'Dakota Dunes': 1445,
    'Davison County': 1445,
    'Day County': 1445,
    'De Smet': 1445,
    'Deadwood': 1445,
    'Dell Rapids': 1445,
    'Deuel County': 1445,
    'Dewey County': 1445,
    'Douglas County': 1445,
    'Dupree': 1445,
    'Eagle Butte': 1445,
    'Edmunds County': 1445,
    'Elk Point': 1445,
    'Fall River County': 1445,
    'Faulk County': 1445,
    'Faulkton': 1445,
    'Flandreau': 1445,
    'Fort Pierre': 1445,
    'Fort Thompson': 1445,
    'Freeman': 1445,
    'Garretson': 1445,
    'Gettysburg': 1445,
    'Grant County': 1445,
    'Gregory': 1445,
    'Gregory County': 1445,
    'Groton': 1445,
    'Haakon County': 1445,
    'Hamlin County': 1445,
    'Hand County': 1445,
    'Hanson County': 1445,
    'Harding County': 1445,
    'Harrisburg': 1445,
    'Hartford': 1445,
    'Hayti': 1445,
    'Highmore': 1445,
    'Hot Springs': 1445,
    'Howard': 1445,
    'Hughes County': 1445,
    'Huron': 1445,
    'Hutchinson County': 1445,
    'Hyde County': 1445,
    'Ipswich': 1445,
    'Jackson County': 1445,
    'Jerauld County': 1445,
    'Jones County': 1445,
    'Kadoka': 1445,
    'Kennebec': 1445,
    'Kingsbury County': 1445,
    'Lake Andes': 1445,
    'Lake County': 1445,
    'Lawrence County': 1445,
    'Lead': 1445,
    'Lemmon': 1445,
    'Lennox': 1445,
    'Leola': 1445,
    'Lincoln County': 1445,
    'Lyman County': 1445,
    'Madison': 1445,
    'Marshall County': 1445,
    'Martin': 1445,
    'McCook County': 1445,
    'McIntosh': 1445,
    'McPherson County': 1445,
    'Meade County': 1445,
    'Mellette County': 1445,
    'Milbank': 1445,
    'Miller': 1445,
    'Miner County': 1445,
    'Minnehaha County': 1445,
    'Mission': 1445,
    'Mitchell': 1445,
    'Mobridge': 1445,
    'Moody County': 1445,
    'Mound City': 1445,
    'Murdo': 1445,
    'North Eagle Butte': 1445,
    'North Sioux City': 1445,
    'North Spearfish': 1445,
    'Oglala': 1445,
    'Oglala Lakota County': 1445,
    'Olivet': 1445,
    'Onida': 1445,
    'Parker': 1445,
    'Parkston': 1445,
    'Pennington County': 1445,
    'Perkins County': 1445,
    'Philip': 1445,
    'Pierre': 1445,
    'Pine Ridge': 1445,
    'Plankinton': 1445,
    'Platte': 1445,
    'Porcupine': 1445,
    'Potter County': 1445,
    'Rapid City': 1445,
    'Rapid Valley': 1445,
    'Redfield': 1445,
    'Roberts County': 1445,
    'Rosebud': 1445,
    'Salem': 1445,
    'Sanborn County': 1445,
    'Selby': 1445,
    'Sioux Falls': 1445,
    'Sisseton': 1445,
    'Spearfish': 1445,
    'Spink County': 1445,
    'Springfield': 1445,
    'Stanley County': 1445,
    'Sturgis': 1445,
    'Sully County': 1445,
    'Summerset': 1445,
    'Tea': 1445,
    'Timber Lake': 1445,
    'Todd County': 1445,
    'Tripp County': 1445,
    'Turner County': 1445,
    'Tyndall': 1445,
    'Union County': 1445,
    'Vermillion': 1445,
    'Volga': 1445,
    'Wagner': 1445,
    'Walworth County': 1445,
    'Watertown': 1445,
    'Webster': 1445,
    'Wessington Springs': 1445,
    'White River': 1445,
    'Winner': 1445,
    'Woonsocket': 1445,
    'Yankton': 1445,
    'Yankton County': 1445,
    'Ziebach County': 1445,
    'Adamsville': 1454,
    'Alamo': 1454,
    'Alcoa': 1454,
    'Algood': 1454,
    'Altamont': 1454,
    'Anderson County': 1454,
    'Apison': 1454,
    'Ardmore': 1454,
    'Arlington': 1454,
    'Ashland City': 1454,
    'Athens': 1454,
    'Atoka': 1454,
    'Banner Hill': 1454,
    'Bartlett': 1454,
    'Baxter': 1454,
    'Bean Station': 1454,
    'Bedford County': 1454,
    'Belle Meade': 1454,
    'Bells': 1454,
    'Benton': 1454,
    'Benton County': 1454,
    'Blaine': 1454,
    'Bledsoe County': 1454,
    'Bloomingdale': 1454,
    'Blount County': 1454,
    'Blountville': 1454,
    'Bluff City': 1454,
    'Bolivar': 1454,
    'Bon Aqua Junction': 1454,
    'Bradford': 1454,
    'Bradley County': 1454,
    'Brentwood': 1454,
    'Brentwood Estates': 1454,
    'Brighton': 1454,
    'Bristol': 1454,
    'Brownsville': 1454,
    'Bruceton': 1454,
    'Burns': 1454,
    'Byrdstown': 1454,
    'Camden': 1454,
    'Campbell County': 1454,
    'Cannon County': 1454,
    'Carroll County': 1454,
    'Carter County': 1454,
    'Carthage': 1454,
    'Caryville': 1454,
    'Celina': 1454,
    'Centerville': 1454,
    'Central': 1454,
    'Chapel Hill': 1454,
    'Charlotte': 1454,
    'Chattanooga': 1454,
    'Cheatham County': 1454,
    'Chester County': 1454,
    'Christiana': 1454,
    'Church Hill': 1454,
    'Claiborne County': 1454,
    'Clarksville': 1454,
    'Clay County': 1454,
    'Cleveland': 1454,
    'Clifton': 1454,
    'Clinton': 1454,
    'Coalfield': 1454,
    'Cocke County': 1454,
    'Coffee County': 1454,
    'Collegedale': 1454,
    'Collierville': 1454,
    'Colonial Heights': 1454,
    'Columbia': 1454,
    'Condon': 1454,
    'Cookeville': 1454,
    'Coopertown': 1454,
    'Cornersville': 1454,
    'Covington': 1454,
    'Cowan': 1454,
    'Crockett County': 1454,
    'Cross Plains': 1454,
    'Crossville': 1454,
    'Crump': 1454,
    'Cumberland County': 1454,
    'Dandridge': 1454,
    'Davidson County': 1454,
    'Dayton': 1454,
    'Decatur': 1454,
    'Decatur County': 1454,
    'Decaturville': 1454,
    'Decherd': 1454,
    'DeKalb County': 1454,
    'Dickson': 1454,
    'Dickson County': 1454,
    'Dodson Branch': 1454,
    'Dover': 1454,
    'Dresden': 1454,
    'Dunlap': 1454,
    'Dyer': 1454,
    'Dyer County': 1454,
    'Dyersburg': 1454,
    'Eagleton Village': 1454,
    'East Brainerd': 1454,
    'East Chattanooga': 1454,
    'East Cleveland': 1454,
    'East Ridge': 1454,
    'Elizabethton': 1454,
    'Englewood': 1454,
    'Erin': 1454,
    'Erwin': 1454,
    'Estill Springs': 1454,
    'Etowah': 1454,
    'Fairfield Glade': 1454,
    'Fairmount': 1454,
    'Fairview': 1454,
    'Fall Branch': 1454,
    'Falling Water': 1454,
    'Farragut': 1454,
    'Fayette County': 1454,
    'Fayetteville': 1454,
    'Fentress County': 1454,
    'Fincastle': 1454,
    'Forest Hills': 1454,
    'Franklin': 1454,
    'Franklin County': 1454,
    'Gainesboro': 1454,
    'Gallatin': 1454,
    'Gatlinburg': 1454,
    'Germantown': 1454,
    'Gibson County': 1454,
    'Giles County': 1454,
    'Gleason': 1454,
    'Goodlettsville': 1454,
    'Gordonsville': 1454,
    'Grainger County': 1454,
    'Gray': 1454,
    'Graysville': 1454,
    'Green Hill': 1454,
    'Greenback': 1454,
    'Greenbrier': 1454,
    'Greene County': 1454,
    'Greeneville': 1454,
    'Greenfield': 1454,
    'Grimsley': 1454,
    'Gruetli-Laager': 1454,
    'Grundy County': 1454,
    'Halls': 1454,
    'Hamblen County': 1454,
    'Hamilton County': 1454,
    'Hancock County': 1454,
    'Hardeman County': 1454,
    'Hardin County': 1454,
    'Harriman': 1454,
    'Harrison': 1454,
    'Harrogate': 1454,
    'Hartsville': 1454,
    'Hawkins County': 1454,
    'Haywood County': 1454,
    'Henderson': 1454,
    'Henderson County': 1454,
    'Hendersonville': 1454,
    'Henry County': 1454,
    'Hickman County': 1454,
    'Hickory Withe': 1454,
    'Hohenwald': 1454,
    'Hopewell': 1454,
    'Houston County': 1454,
    'Humboldt': 1454,
    'Humphreys County': 1454,
    'Hunter': 1454,
    'Huntingdon': 1454,
    'Huntsville': 1454,
    'Jacksboro': 1454,
    'Jackson': 1454,
    'Jackson County': 1454,
    'Jamestown': 1454,
    'Jasper': 1454,
    'Jefferson City': 1454,
    'Jefferson County': 1454,
    'Jellico': 1454,
    'Johnson City': 1454,
    'Johnson County': 1454,
    'Johnsonville': 1454,
    'Jonesborough': 1454,
    'Kenton': 1454,
    'Kimball': 1454,
    'Kingsport': 1454,
    'Kingston': 1454,
    'Kingston Springs': 1454,
    'Knox County': 1454,
    'Knoxville': 1454,
    'La Vergne': 1454,
    'Lafayette': 1454,
    'LaFollette': 1454,
    'Lake County': 1454,
    'Lake Tansi': 1454,
    'Lakeland': 1454,
    'Lakesite': 1454,
    'Lakewood': 1454,
    'Lauderdale County': 1454,
    'Lawrence County': 1454,
    'Lawrenceburg': 1454,
    'Lebanon': 1454,
    'Lenoir City': 1454,
    'Lewis County': 1454,
    'Lewisburg': 1454,
    'Lexington': 1454,
    'Lincoln County': 1454,
    'Linden': 1454,
    'Livingston': 1454,
    'Lone Oak': 1454,
    'Lookout Mountain': 1454,
    'Loretto': 1454,
    'Loudon': 1454,
    'Loudon County': 1454,
    'Louisville': 1454,
    'Luttrell': 1454,
    'Lynchburg': 1454,
    'Macon County': 1454,
    'Madison County': 1454,
    'Madisonville': 1454,
    'Manchester': 1454,
    'Marion County': 1454,
    'Marshall County': 1454,
    'Martin': 1454,
    'Maryville': 1454,
    'Mascot': 1454,
    'Mason': 1454,
    'Maury County': 1454,
    'Maynardville': 1454,
    'McEwen': 1454,
    'McKenzie': 1454,
    'McMinn County': 1454,
    'McMinnville': 1454,
    'McNairy County': 1454,
    'Medina': 1454,
    'Meigs County': 1454,
    'Memphis': 1454,
    'Middle Valley': 1454,
    'Midtown': 1454,
    'Midway': 1454,
    'Milan': 1454,
    'Millersville': 1454,
    'Millington': 1454,
    'Monroe County': 1454,
    'Monteagle': 1454,
    'Monterey': 1454,
    'Montgomery County': 1454,
    'Moore County': 1454,
    'Morgan County': 1454,
    'Morristown': 1454,
    'Mosheim': 1454,
    'Mount Carmel': 1454,
    'Mount Juliet': 1454,
    'Mount Pleasant': 1454,
    'Mountain City': 1454,
    'Mowbray Mountain': 1454,
    'Munford': 1454,
    'Murfreesboro': 1454,
    'Nashville': 1454,
    'New Hope': 1454,
    'New Johnsonville': 1454,
    'New Market': 1454,
    'New South Memphis': 1454,
    'New Tazewell': 1454,
    'New Union': 1454,
    'Newbern': 1454,
    'Newport': 1454,
    'Nolensville': 1454,
    'Norris': 1454,
    'Oak Grove': 1454,
    'Oak Hill': 1454,
    'Oak Ridge': 1454,
    'Oakland': 1454,
    'Obion': 1454,
    'Obion County': 1454,
    'Oliver Springs': 1454,
    'Olivet': 1454,
    'Oneida': 1454,
    'Overton County': 1454,
    'Paris': 1454,
    'Park City': 1454,
    'Parsons': 1454,
    'Pegram': 1454,
    'Perry County': 1454,
    'Pickett County': 1454,
    'Pigeon Forge': 1454,
    'Pikeville': 1454,
    'Pine Crest': 1454,
    'Piperton': 1454,
    'Plainview': 1454,
    'Pleasant View': 1454,
    'Polk County': 1454,
    'Portland': 1454,
    'Powells Crossroads': 1454,
    'Pulaski': 1454,
    'Putnam County': 1454,
    'Red Bank': 1454,
    'Red Boiling Springs': 1454,
    'Rhea County': 1454,
    'Ridgely': 1454,
    'Ridgetop': 1454,
    'Ripley': 1454,
    'Roan Mountain': 1454,
    'Roane County': 1454,
    'Robertson County': 1454,
    'Rockwood': 1454,
    'Rocky Top': 1454,
    'Rogersville': 1454,
    'Rural Hill': 1454,
    'Rutherford': 1454,
    'Rutherford County': 1454,
    'Rutledge': 1454,
    'Sale Creek': 1454,
    'Savannah': 1454,
    'Scott County': 1454,
    'Selmer': 1454,
    'Sequatchie County': 1454,
    'Sevier County': 1454,
    'Sevierville': 1454,
    'Sewanee': 1454,
    'Seymour': 1454,
    'Shackle Island': 1454,
    'Shelby County': 1454,
    'Shelbyville': 1454,
    'Signal Mountain': 1454,
    'Smith County': 1454,
    'Smithville': 1454,
    'Smyrna': 1454,
    'Sneedville': 1454,
    'Soddy-Daisy': 1454,
    'Somerville': 1454,
    'South Carthage': 1454,
    'South Cleveland': 1454,
    'South Fulton': 1454,
    'South Pittsburg': 1454,
    'Sparta': 1454,
    'Spencer': 1454,
    'Spring City': 1454,
    'Spring Hill': 1454,
    'Springfield': 1454,
    'Spurgeon': 1454,
    'Stewart County': 1454,
    'Sullivan County': 1454,
    'Sumner County': 1454,
    'Surgoinsville': 1454,
    'Sweetwater': 1454,
    'Tazewell': 1454,
    'Tellico Village': 1454,
    'Tennessee Ridge': 1454,
    'Thompson  s Station': 1454,
    'Three Way': 1454,
    'Tipton County': 1454,
    'Tiptonville': 1454,
    'Tracy City': 1454,
    'Trenton': 1454,
    'Trousdale County': 1454,
    'Troy': 1454,
    'Tullahoma': 1454,
    'Tusculum': 1454,
    'Unicoi': 1454,
    'Unicoi County': 1454,
    'Union City': 1454,
    'Union County': 1454,
    'Unionville': 1454,
    'Van Buren County': 1454,
    'Vonore': 1454,
    'Walden': 1454,
    'Walnut Hill': 1454,
    'Warren County': 1454,
    'Wartburg': 1454,
    'Washington County': 1454,
    'Watertown': 1454,
    'Waverly': 1454,
    'Wayne County': 1454,
    'Waynesboro': 1454,
    'Weakley County': 1454,
    'Westmoreland': 1454,
    'White Bluff': 1454,
    'White County': 1454,
    'White House': 1454,
    'White Pine': 1454,
    'Whiteville': 1454,
    'Whitwell': 1454,
    'Wildwood': 1454,
    'Wildwood Lake': 1454,
    'Williamson County': 1454,
    'Wilson County': 1454,
    'Winchester': 1454,
    'Woodbury': 1454,
    'Abernathy': 1407,
    'Abilene': 1407,
    'Abram': 1407,
    'Addison': 1407,
    'Agua Dulce': 1407,
    'Alamo': 1407,
    'Alamo Heights': 1407,
    'Albany': 1407,
    'Aldine': 1407,
    'Aledo': 1407,
    'Alice': 1407,
    'Alief': 1407,
    'Allen': 1407,
    'Alpine': 1407,
    'Alto': 1407,
    'Alton': 1407,
    'Alton North (historical)': 1407,
    'Alvarado': 1407,
    'Alvin': 1407,
    'Alvord': 1407,
    'Amarillo': 1407,
    'Ames': 1407,
    'Anahuac': 1407,
    'Anderson': 1407,
    'Anderson County': 1407,
    'Anderson Mill': 1407,
    'Andrews': 1407,
    'Andrews County': 1407,
    'Angelina County': 1407,
    'Angleton': 1407,
    'Anna': 1407,
    'Annetta': 1407,
    'Anson': 1407,
    'Anthony': 1407,
    'Anton': 1407,
    'Aransas County': 1407,
    'Aransas Pass': 1407,
    'Archer City': 1407,
    'Archer County': 1407,
    'Arcola': 1407,
    'Argyle': 1407,
    'Arlington': 1407,
    'Armstrong County': 1407,
    'Asherton': 1407,
    'Aspermont': 1407,
    'Atascocita': 1407,
    'Atascosa County': 1407,
    'Athens': 1407,
    'Atlanta': 1407,
    'Aubrey': 1407,
    'Aurora': 1407,
    'Austin': 1407,
    'Austin County': 1407,
    'Azle': 1407,
    'Bacliff': 1407,
    'Bailey County': 1407,
    'Baird': 1407,
    'Balch Springs': 1407,
    'Balcones Heights': 1407,
    'Ballinger': 1407,
    'Bandera': 1407,
    'Bandera County': 1407,
    'Bangs': 1407,
    'Barrett': 1407,
    'Bartlett': 1407,
    'Barton Creek': 1407,
    'Bartonville': 1407,
    'Bastrop': 1407,
    'Bastrop County': 1407,
    'Batesville': 1407,
    'Bay City': 1407,
    'Baylor County': 1407,
    'Bayou Vista': 1407,
    'Baytown': 1407,
    'Beach City': 1407,
    'Beaumont': 1407,
    'Bedford': 1407,
    'Bee Cave': 1407,
    'Bee County': 1407,
    'Beeville': 1407,
    'Bell County': 1407,
    'Bellaire': 1407,
    'Bellmead': 1407,
    'Bells': 1407,
    'Bellville': 1407,
    'Belton': 1407,
    'Benavides': 1407,
    'Benbrook': 1407,
    'Benjamin': 1407,
    'Berryville': 1407,
    'Bertram': 1407,
    'Beverly': 1407,
    'Beverly Hills': 1407,
    'Bevil Oaks': 1407,
    'Bexar County': 1407,
    'Big Lake': 1407,
    'Big Sandy': 1407,
    'Big Spring': 1407,
    'Bishop': 1407,
    'Blanco': 1407,
    'Blanco County': 1407,
    'Bloomington': 1407,
    'Blossom': 1407,
    'Blue Mound': 1407,
    'Boerne': 1407,
    'Bogata': 1407,
    'Boling': 1407,
    'Bolivar Peninsula': 1407,
    'Bonham': 1407,
    'Booker': 1407,
    'Borden County': 1407,
    'Borger': 1407,
    'Bosque County': 1407,
    'Bovina': 1407,
    'Bowie': 1407,
    'Bowie County': 1407,
    'Boyd': 1407,
    'Brackettville': 1407,
    'Brady': 1407,
    'Brazoria': 1407,
    'Brazoria County': 1407,
    'Brazos County': 1407,
    'Breckenridge': 1407,
    'Brenham': 1407,
    'Brewster County': 1407,
    'Briar': 1407,
    'Briarcliff': 1407,
    'Bridge City': 1407,
    'Bridgeport': 1407,
    'Briscoe County': 1407,
    'Brooks County': 1407,
    'Brookshire': 1407,
    'Brookside Village': 1407,
    'Brown County': 1407,
    'Brownfield': 1407,
    'Brownsboro': 1407,
    'Brownsville': 1407,
    'Brownwood': 1407,
    'Bruceville-Eddy': 1407,
    'Brushy Creek': 1407,
    'Bryan': 1407,
    'Buchanan Dam': 1407,
    'Buda': 1407,
    'Buffalo': 1407,
    'Bullard': 1407,
    'Bulverde': 1407,
    'Buna': 1407,
    'Bunker Hill Village': 1407,
    'Burkburnett': 1407,
    'Burleson': 1407,
    'Burleson County': 1407,
    'Burnet': 1407,
    'Burnet County': 1407,
    'Bushland': 1407,
    'Cactus': 1407,
    'Caddo Mills': 1407,
    'Caldwell': 1407,
    'Caldwell County': 1407,
    'Calhoun County': 1407,
    'Callahan County': 1407,
    'Calvert': 1407,
    'Cameron': 1407,
    'Cameron County': 1407,
    'Cameron Park': 1407,
    'Cameron Park Colonia': 1407,
    'Camp County': 1407,
    'Camp Swift': 1407,
    'Canadian': 1407,
    'Canton': 1407,
    'Canutillo': 1407,
    'Canyon': 1407,
    'Canyon Lake': 1407,
    'Carrizo Springs': 1407,
    'Carrollton': 1407,
    'Carson County': 1407,
    'Carthage': 1407,
    'Cass County': 1407,
    'Castle Hills': 1407,
    'Castro County': 1407,
    'Castroville': 1407,
    'Cedar Hill': 1407,
    'Cedar Park': 1407,
    'Celina': 1407,
    'Center': 1407,
    'Centerville': 1407,
    'Central Gardens': 1407,
    'César Chávez': 1407,
    'Chambers County': 1407,
    'Chandler': 1407,
    'Channelview': 1407,
    'Channing': 1407,
    'Charlotte': 1407,
    'Cherokee County': 1407,
    'Chico': 1407,
    'Childress': 1407,
    'Childress County': 1407,
    'China': 1407,
    'China Grove': 1407,
    'Cibolo': 1407,
    'Cienegas Terrace': 1407,
    'Cinco Ranch': 1407,
    'Circle D-KC Estates': 1407,
    'Cisco': 1407,
    'Citrus City': 1407,
    'Clarendon': 1407,
    'Clarksville': 1407,
    'Claude': 1407,
    'Clay County': 1407,
    'Clear Lake Shores': 1407,
    'Cleburne': 1407,
    'Cleveland': 1407,
    'Clifton': 1407,
    'Clint': 1407,
    'Cloverleaf': 1407,
    'Clute': 1407,
    'Clyde': 1407,
    'Cochran County': 1407,
    'Cockrell Hill': 1407,
    'Coke County': 1407,
    'Coldspring': 1407,
    'Coleman': 1407,
    'Coleman County': 1407,
    'College Station': 1407,
    'Colleyville': 1407,
    'Collin County': 1407,
    'Collingsworth County': 1407,
    'Collinsville': 1407,
    'Colorado City': 1407,
    'Colorado County': 1407,
    'Columbus': 1407,
    'Comal County': 1407,
    'Comanche': 1407,
    'Comanche County': 1407,
    'Combes': 1407,
    'Combine': 1407,
    'Comfort': 1407,
    'Commerce': 1407,
    'Concho County': 1407,
    'Conroe': 1407,
    'Converse': 1407,
    'Cooke County': 1407,
    'Cooper': 1407,
    'Coppell': 1407,
    'Copper Canyon': 1407,
    'Copperas Cove': 1407,
    'Corinth': 1407,
    'Corpus Christi': 1407,
    'Corrigan': 1407,
    'Corsicana': 1407,
    'Coryell County': 1407,
    'Cottle County': 1407,
    'Cottonwood Shores': 1407,
    'Cotulla': 1407,
    'Crandall': 1407,
    'Crane': 1407,
    'Crane County': 1407,
    'Crockett': 1407,
    'Crockett County': 1407,
    'Crosby': 1407,
    'Crosby County': 1407,
    'Crosbyton': 1407,
    'Cross Mountain': 1407,
    'Crowell': 1407,
    'Crowley': 1407,
    'Crystal City': 1407,
    'Cuero': 1407,
    'Culberson County': 1407,
    'Cut and Shoot': 1407,
    'Cypress': 1407,
    'Daingerfield': 1407,
    'Dalhart': 1407,
    'Dallam County': 1407,
    'Dallas': 1407,
    'Dallas County': 1407,
    'Dalworthington Gardens': 1407,
    'Danbury': 1407,
    'Dawson County': 1407,
    'Dayton': 1407,
    'De Kalb': 1407,
    'De Leon': 1407,
    'Deaf Smith County': 1407,
    'Decatur': 1407,
    'DeCordova': 1407,
    'Deer Park': 1407,
    'Del Rio': 1407,
    'Delta County': 1407,
    'Denison': 1407,
    'Denton': 1407,
    'Denton County': 1407,
    'Denver City': 1407,
    'DeSoto': 1407,
    'Devine': 1407,
    'Deweyville': 1407,
    'DeWitt County': 1407,
    'Diboll': 1407,
    'Dickens': 1407,
    'Dickens County': 1407,
    'Dickinson': 1407,
    'Dilley': 1407,
    'Dimmit County': 1407,
    'Dimmitt': 1407,
    'Doffing': 1407,
    'Donley County': 1407,
    'Donna': 1407,
    'Doolittle': 1407,
    'Double Oak': 1407,
    'Dripping Springs': 1407,
    'Dublin': 1407,
    'Dumas': 1407,
    'Duncanville': 1407,
    'Duval County': 1407,
    'Eagle Lake': 1407,
    'Eagle Mountain': 1407,
    'Eagle Pass': 1407,
    'Early': 1407,
    'Earth': 1407,
    'East Bernard': 1407,
    'Eastland': 1407,
    'Eastland County': 1407,
    'Ector County': 1407,
    'Edcouch': 1407,
    'Eden': 1407,
    'Edgecliff Village': 1407,
    'Edgewood': 1407,
    'Edinburg': 1407,
    'Edna': 1407,
    'Edwards County': 1407,
    'Eidson Road': 1407,
    'El Campo': 1407,
    'El Cenizo': 1407,
    'El Lago': 1407,
    'El Paso': 1407,
    'El Paso County': 1407,
    'Eldorado': 1407,
    'Electra': 1407,
    'Elgin': 1407,
    'Elkhart': 1407,
    'Ellis County': 1407,
    'Elm Creek': 1407,
    'Elmendorf': 1407,
    'Elsa': 1407,
    'Emory': 1407,
    'Encantada-Ranchito-El Calaboz': 1407,
    'Ennis': 1407,
    'Erath County': 1407,
    'Escobares': 1407,
    'Euless': 1407,
    'Evadale': 1407,
    'Everman': 1407,
    'Fabens': 1407,
    'Fair Oaks Ranch': 1407,
    'Fairchilds': 1407,
    'Fairfield': 1407,
    'Fairview': 1407,
    'Falcon Lake Estates': 1407,
    'Falfurrias': 1407,
    'Falls County': 1407,
    'Fannett': 1407,
    'Fannin County': 1407,
    'Farmers Branch': 1407,
    'Farmersville': 1407,
    'Farwell': 1407,
    'Fate': 1407,
    'Fayette County': 1407,
    'Ferris': 1407,
    'Fifth Street': 1407,
    'Fisher County': 1407,
    'Flatonia': 1407,
    'Florence': 1407,
    'Floresville': 1407,
    'Flower Mound': 1407,
    'Floyd County': 1407,
    'Floydada': 1407,
    'Foard County': 1407,
    'Forest Hill': 1407,
    'Forney': 1407,
    'Fort Bend County': 1407,
    'Fort Bliss': 1407,
    'Fort Clark Springs': 1407,
    'Fort Davis': 1407,
    'Fort Hancock': 1407,
    'Fort Hood': 1407,
    'Fort Stockton': 1407,
    'Fort Worth': 1407,
    'Four Corners': 1407,
    'Franklin': 1407,
    'Franklin County': 1407,
    'Frankston': 1407,
    'Fredericksburg': 1407,
    'Freeport': 1407,
    'Freer': 1407,
    'Freestone County': 1407,
    'Fresno': 1407,
    'Friendswood': 1407,
    'Frio County': 1407,
    'Friona': 1407,
    'Frisco': 1407,
    'Fritch': 1407,
    'Fulshear': 1407,
    'Fulton': 1407,
    'Gail': 1407,
    'Gaines County': 1407,
    'Gainesville': 1407,
    'Galena Park': 1407,
    'Galveston': 1407,
    'Galveston County': 1407,
    'Ganado': 1407,
    'Garden City': 1407,
    'Garden Ridge': 1407,
    'Gardendale': 1407,
    'Garfield': 1407,
    'Garland': 1407,
    'Garza County': 1407,
    'Gatesville': 1407,
    'George West': 1407,
    'Georgetown': 1407,
    'Geronimo': 1407,
    'Gholson': 1407,
    'Giddings': 1407,
    'Gillespie County': 1407,
    'Gilmer': 1407,
    'Gladewater': 1407,
    'Glasscock County': 1407,
    'Glen Rose': 1407,
    'Glenn Heights': 1407,
    'Godley': 1407,
    'Goldthwaite': 1407,
    'Goliad': 1407,
    'Goliad County': 1407,
    'Gonzales': 1407,
    'Gonzales County': 1407,
    'Gorman': 1407,
    'Graham': 1407,
    'Granbury': 1407,
    'Grand Prairie': 1407,
    'Grand Saline': 1407,
    'Grandview': 1407,
    'Granger': 1407,
    'Granite Shoals': 1407,
    'Grape Creek': 1407,
    'Grapeland': 1407,
    'Grapevine': 1407,
    'Gray County': 1407,
    'Grayson County': 1407,
    'Greatwood': 1407,
    'Green Valley Farms': 1407,
    'Greenville': 1407,
    'Gregg County': 1407,
    'Gregory': 1407,
    'Grimes County': 1407,
    'Groesbeck': 1407,
    'Groves': 1407,
    'Groveton': 1407,
    'Gruver': 1407,
    'Guadalupe County': 1407,
    'Gun Barrel City': 1407,
    'Gunter': 1407,
    'Guthrie': 1407,
    'Hackberry': 1407,
    'Hale Center': 1407,
    'Hale County': 1407,
    'Hall County': 1407,
    'Hallettsville': 1407,
    'Hallsville': 1407,
    'Haltom City': 1407,
    'Hamilton': 1407,
    'Hamilton County': 1407,
    'Hamlin': 1407,
    'Hansford County': 1407,
    'Hardeman County': 1407,
    'Hardin County': 1407,
    'Harker Heights': 1407,
    'Harlingen': 1407,
    'Harper': 1407,
    'Harris County': 1407,
    'Harrison County': 1407,
    'Hart': 1407,
    'Hartley County': 1407,
    'Haskell': 1407,
    'Haskell County': 1407,
    'Haslet': 1407,
    'Hawkins': 1407,
    'Hays County': 1407,
    'Hearne': 1407,
    'Heath': 1407,
    'Hebbronville': 1407,
    'Hedwig Village': 1407,
    'Heidelberg': 1407,
    'Helotes': 1407,
    'Hemphill': 1407,
    'Hemphill County': 1407,
    'Hempstead': 1407,
    'Henderson': 1407,
    'Henderson County': 1407,
    'Henrietta': 1407,
    'Hereford': 1407,
    'Hewitt': 1407,
    'Hickory Creek': 1407,
    'Hico': 1407,
    'Hidalgo': 1407,
    'Hidalgo County': 1407,
    'Hideaway': 1407,
    'Highland Park': 1407,
    'Highland Village': 1407,
    'Highlands': 1407,
    'Hill Country Village': 1407,
    'Hill County': 1407,
    'Hillsboro': 1407,
    'Hilltop Lakes': 1407,
    'Hitchcock': 1407,
    'Hockley County': 1407,
    'Holiday Lakes': 1407,
    'Holland': 1407,
    'Holliday': 1407,
    'Holly Lake Ranch': 1407,
    'Hollywood Park': 1407,
    'Homestead Meadows North': 1407,
    'Homestead Meadows South': 1407,
    'Hondo': 1407,
    'Honey Grove': 1407,
    'Hood County': 1407,
    'Hooks': 1407,
    'Hopkins County': 1407,
    'Horizon City': 1407,
    'Hornsby Bend': 1407,
    'Horseshoe Bay': 1407,
    'Houston': 1407,
    'Houston County': 1407,
    'Howard County': 1407,
    'Howe': 1407,
    'Hubbard': 1407,
    'Hudson': 1407,
    'Hudson Bend': 1407,
    'Hudson Oaks': 1407,
    'Hudspeth County': 1407,
    'Hughes Springs': 1407,
    'Humble': 1407,
    'Hunt County': 1407,
    'Hunters Creek Village': 1407,
    'Huntington': 1407,
    'Huntsville': 1407,
    'Hurst': 1407,
    'Hutchins': 1407,
    'Hutchinson County': 1407,
    'Hutto': 1407,
    'Idalou': 1407,
    'Indian Hills': 1407,
    'Inez': 1407,
    'Ingleside': 1407,
    'Ingram': 1407,
    'Iowa Colony': 1407,
    'Iowa Park': 1407,
    'Iraan': 1407,
    'Irion County': 1407,
    'Irving': 1407,
    'Italy': 1407,
    'Itasca': 1407,
    'Jacinto City': 1407,
    'Jack County': 1407,
    'Jacksboro': 1407,
    'Jackson County': 1407,
    'Jacksonville': 1407,
    'Jamaica Beach': 1407,
    'Jarrell': 1407,
    'Jasper': 1407,
    'Jasper County': 1407,
    'Jayton': 1407,
    'Jeff Davis County': 1407,
    'Jefferson': 1407,
    'Jefferson County': 1407,
    'Jersey Village': 1407,
    'Jewett': 1407,
    'Jim Hogg County': 1407,
    'Jim Wells County': 1407,
    'Johnson City': 1407,
    'Johnson County': 1407,
    'Jollyville': 1407,
    'Jones County': 1407,
    'Jones Creek': 1407,
    'Jonestown': 1407,
    'Josephine': 1407,
    'Joshua': 1407,
    'Jourdanton': 1407,
    'Junction': 1407,
    'Justin': 1407,
    'Karnes City': 1407,
    'Karnes County': 1407,
    'Katy': 1407,
    'Kaufman': 1407,
    'Kaufman County': 1407,
    'Keene': 1407,
    'Keller': 1407,
    'Kemah': 1407,
    'Kemp': 1407,
    'Kempner': 1407,
    'Kendall County': 1407,
    'Kenedy': 1407,
    'Kenedy County': 1407,
    'Kennedale': 1407,
    'Kent County': 1407,
    'Kerens': 1407,
    'Kermit': 1407,
    'Kerr County': 1407,
    'Kerrville': 1407,
    'Kilgore': 1407,
    'Killeen': 1407,
    'Kimble County': 1407,
    'King County': 1407,
    'Kingsland': 1407,
    'Kingsville': 1407,
    'Kingwood Area': 1407,
    'Kinney County': 1407,
    'Kirby': 1407,
    'Kirbyville': 1407,
    'Kleberg County': 1407,
    'Knox City': 1407,
    'Knox County': 1407,
    'Kountze': 1407,
    'Krugerville': 1407,
    'Krum': 1407,
    'Kyle': 1407,
    'La Blanca': 1407,
    'La Coste': 1407,
    'La Feria': 1407,
    'La Grange': 1407,
    'La Grulla': 1407,
    'La Homa': 1407,
    'La Joya': 1407,
    'La Marque': 1407,
    'La Paloma': 1407,
    'La Porte': 1407,
    'La Pryor': 1407,
    'La Salle County': 1407,
    'La Vernia': 1407,
    'La Villa': 1407,
    'Lackland Air Force Base': 1407,
    'Lacy-Lakeview': 1407,
    'Lago Vista': 1407,
    'Laguna Heights': 1407,
    'Laguna Park': 1407,
    'Laguna Vista': 1407,
    'Lake Brownwood': 1407,
    'Lake Dallas': 1407,
    'Lake Dunlap': 1407,
    'Lake Jackson': 1407,
    'Lake Kiowa': 1407,
    'Lake Worth': 1407,
    'Lakehills': 1407,
    'Lakeside': 1407,
    'Lakeway': 1407,
    'Lamar County': 1407,
    'Lamb County': 1407,
    'Lamesa': 1407,
    'Lampasas': 1407,
    'Lampasas County': 1407,
    'Lancaster': 1407,
    'Lantana': 1407,
    'Laredo': 1407,
    'Las Lomas': 1407,
    'Las Palmas II': 1407,
    'Las Quintas Fronterizas': 1407,
    'Las Quintas Fronterizas Colonia': 1407,
    'Lasara': 1407,
    'Laughlin Air Force Base': 1407,
    'Laureles': 1407,
    'Lavaca County': 1407,
    'Lavon': 1407,
    'League City': 1407,
    'Leakey': 1407,
    'Leander': 1407,
    'Lee County': 1407,
    'Leon County': 1407,
    'Leon Valley': 1407,
    'Leonard': 1407,
    'Levelland': 1407,
    'Lewisville': 1407,
    'Lexington': 1407,
    'Liberty': 1407,
    'Liberty City': 1407,
    'Liberty County': 1407,
    'Liberty Hill': 1407,
    'Limestone County': 1407,
    'Lindale': 1407,
    'Linden': 1407,
    'Lindsay': 1407,
    'Lipscomb County': 1407,
    'Little Elm': 1407,
    'Little River-Academy': 1407,
    'Littlefield': 1407,
    'Live Oak': 1407,
    'Live Oak County': 1407,
    'Livingston': 1407,
    'Llano': 1407,
    'Llano County': 1407,
    'Llano Grande': 1407,
    'Lockhart': 1407,
    'Lockney': 1407,
    'Lone Star': 1407,
    'Longview': 1407,
    'Lopezville': 1407,
    'Lorena': 1407,
    'Lorenzo': 1407,
    'Los Fresnos': 1407,
    'Los Indios': 1407,
    'Lost Creek': 1407,
    'Loving County': 1407,
    'Lowry Crossing': 1407,
    'Lubbock': 1407,
    'Lubbock County': 1407,
    'Lucas': 1407,
    'Lufkin': 1407,
    'Luling': 1407,
    'Lumberton': 1407,
    'Lyford': 1407,
    'Lynn County': 1407,
    'Lytle': 1407,
    'Mabank': 1407,
    'Madison County': 1407,
    'Madisonville': 1407,
    'Magnolia': 1407,
    'Malakoff': 1407,
    'Manchaca': 1407,
    'Manor': 1407,
    'Mansfield': 1407,
    'Manvel': 1407,
    'Marble Falls': 1407,
    'Marfa': 1407,
    'Marion': 1407,
    'Marion County': 1407,
    'Markham': 1407,
    'Marlin': 1407,
    'Marshall': 1407,
    'Mart': 1407,
    'Martin County': 1407,
    'Martindale': 1407,
    'Mason': 1407,
    'Mason County': 1407,
    'Matador': 1407,
    'Matagorda County': 1407,
    'Mathis': 1407,
    'Maud': 1407,
    'Mauriceville': 1407,
    'Maverick County': 1407,
    'McAllen': 1407,
    'McCamey': 1407,
    'McCulloch County': 1407,
    'McGregor': 1407,
    'McKinney': 1407,
    'McLendon-Chisholm': 1407,
    'McLennan County': 1407,
    'McMullen County': 1407,
    'McQueeney': 1407,
    'Meadowlakes': 1407,
    'Meadows Place': 1407,
    'Medina': 1407,
    'Medina County': 1407,
    'Melissa': 1407,
    'Memphis': 1407,
    'Menard': 1407,
    'Menard County': 1407,
    'Mentone': 1407,
    'Mercedes': 1407,
    'Meridian': 1407,
    'Merkel': 1407,
    'Mertzon': 1407,
    'Mesquite': 1407,
    'Mexia': 1407,
    'Miami': 1407,
    'Midland': 1407,
    'Midland County': 1407,
    'Midlothian': 1407,
    'Midway North': 1407,
    'Midway South': 1407,
    'Mila Doce': 1407,
    'Milam': 1407,
    'Milam County': 1407,
    'Mills County': 1407,
    'Mineola': 1407,
    'Mineral Wells': 1407,
    'Mission': 1407,
    'Mission Bend': 1407,
    'Missouri City': 1407,
    'Mitchell County': 1407,
    'Monahans': 1407,
    'Mont Belvieu': 1407,
    'Montague': 1407,
    'Montague County': 1407,
    'Monte Alto': 1407,
    'Montgomery County': 1407,
    'Moody': 1407,
    'Moore County': 1407,
    'Morgans Point Resort': 1407,
    'Morris County': 1407,
    'Morton': 1407,
    'Motley County': 1407,
    'Mount Pleasant': 1407,
    'Mount Vernon': 1407,
    'Muenster': 1407,
    'Muleshoe': 1407,
    'Munday': 1407,
    'Muniz': 1407,
    'Murillo Colonia': 1407,
    'Murphy': 1407,
    'Nacogdoches': 1407,
    'Nacogdoches County': 1407,
    'Naples': 1407,
    'Nash': 1407,
    'Nassau Bay': 1407,
    'Natalia': 1407,
    'Navarro County': 1407,
    'Navasota': 1407,
    'Nederland': 1407,
    'Needville': 1407,
    'Nevada': 1407,
    'New Boston': 1407,
    'New Braunfels': 1407,
    'New Summerfield': 1407,
    'New Territory': 1407,
    'New Waverly': 1407,
    'Newark': 1407,
    'Newton': 1407,
    'Newton County': 1407,
    'Nixon': 1407,
    'Nocona': 1407,
    'Nolan County': 1407,
    'Nolanville': 1407,
    'North Alamo': 1407,
    'North Richland Hills': 1407,
    'Northcliff': 1407,
    'Northcrest': 1407,
    'Northlake': 1407,
    'Nueces County': 1407,
    'Nurillo': 1407,
    'Oak Cliff Place': 1407,
    'Oak Leaf': 1407,
    'Oak Point': 1407,
    'Oak Ridge North': 1407,
    'Oak Trail Shores': 1407,
    'Ochiltree County': 1407,
    'Odem': 1407,
    'Odessa': 1407,
    'Old River-Winfree': 1407,
    'Oldham County': 1407,
    'Olivarez': 1407,
    'Olmito': 1407,
    'Olmos Park': 1407,
    'Olney': 1407,
    'Olton': 1407,
    'Onalaska': 1407,
    'Onion Creek': 1407,
    'Orange': 1407,
    'Orange County': 1407,
    'Orange Grove': 1407,
    'Ore City': 1407,
    'Overton': 1407,
    'Ovilla': 1407,
    'Oyster Creek': 1407,
    'Ozona': 1407,
    'Paducah': 1407,
    'Paint Rock': 1407,
    'Palacios': 1407,
    'Palestine': 1407,
    'Palm Valley': 1407,
    'Palmer': 1407,
    'Palmhurst': 1407,
    'Palmview': 1407,
    'Palmview South': 1407,
    'Palo Pinto': 1407,
    'Palo Pinto County': 1407,
    'Paloma Creek': 1407,
    'Paloma Creek South': 1407,
    'Pampa': 1407,
    'Panhandle': 1407,
    'Panola County': 1407,
    'Panorama Village': 1407,
    'Pantego': 1407,
    'Paris': 1407,
    'Parker': 1407,
    'Parker County': 1407,
    'Parmer County': 1407,
    'Pasadena': 1407,
    'Patton Village': 1407,
    'Pearland': 1407,
    'Pearsall': 1407,
    'Pecan Acres': 1407,
    'Pecan Grove': 1407,
    'Pecan Plantation': 1407,
    'Pecos': 1407,
    'Pecos County': 1407,
    'Pelican Bay': 1407,
    'Penitas': 1407,
    'Perezville': 1407,
    'Perryton': 1407,
    'Petersburg': 1407,
    'Pflugerville': 1407,
    'Pharr': 1407,
    'Pilot Point': 1407,
    'Pine Island': 1407,
    'Pinehurst': 1407,
    'Pinewood Estates': 1407,
    'Piney Point Village': 1407,
    'Pittsburg': 1407,
    'Plains': 1407,
    'Plainview': 1407,
    'Plano': 1407,
    'Pleak': 1407,
    'Pleasanton': 1407,
    'Plum Grove': 1407,
    'Polk County': 1407,
    'Ponder': 1407,
    'Port Aransas': 1407,
    'Port Arthur': 1407,
    'Port Isabel': 1407,
    'Port Lavaca': 1407,
    'Port Neches': 1407,
    'Port O  Connor': 1407,
    'Porter Heights': 1407,
    'Portland': 1407,
    'Post': 1407,
    'Poteet': 1407,
    'Poth': 1407,
    'Potosi': 1407,
    'Potter County': 1407,
    'Pottsboro': 1407,
    'Powderly': 1407,
    'Prairie View': 1407,
    'Premont': 1407,
    'Presidio': 1407,
    'Presidio County': 1407,
    'Preston': 1407,
    'Primera': 1407,
    'Princeton': 1407,
    'Progreso': 1407,
    'Prosper': 1407,
    'Providence': 1407,
    'Quail Creek': 1407,
    'Quanah': 1407,
    'Queen City': 1407,
    'Quinlan': 1407,
    'Quitman': 1407,
    'Rains County': 1407,
    'Ralls': 1407,
    'Rancho Alegre': 1407,
    'Rancho Viejo': 1407,
    'Randall County': 1407,
    'Ranger': 1407,
    'Rankin': 1407,
    'Ransom Canyon': 1407,
    'Raymondville': 1407,
    'Reagan County': 1407,
    'Real County': 1407,
    'Red Lick': 1407,
    'Red Oak': 1407,
    'Red River County': 1407,
    'Redland': 1407,
    'Redwater': 1407,
    'Redwood': 1407,
    'Reeves County': 1407,
    'Refugio': 1407,
    'Refugio County': 1407,
    'Rendon': 1407,
    'Reno': 1407,
    'Rhome': 1407,
    'Ricardo': 1407,
    'Richardson': 1407,
    'Richland Hills': 1407,
    'Richmond': 1407,
    'Richwood': 1407,
    'Riesel': 1407,
    'Rio Bravo': 1407,
    'Rio Grande City': 1407,
    'Rio Hondo': 1407,
    'River Oaks': 1407,
    'Roanoke': 1407,
    'Robert Lee': 1407,
    'Roberts County': 1407,
    'Robertson County': 1407,
    'Robinson': 1407,
    'Robstown': 1407,
    'Roby': 1407,
    'Rockdale': 1407,
    'Rockport': 1407,
    'Rocksprings': 1407,
    'Rockwall': 1407,
    'Rockwall County': 1407,
    'Rogers': 1407,
    'Rollingwood': 1407,
    'Roma': 1407,
    'Roma-Los Saenz': 1407,
    'Roman Forest': 1407,
    'Roscoe': 1407,
    'Rosebud': 1407,
    'Rosenberg': 1407,
    'Rosharon': 1407,
    'Rosita North': 1407,
    'Rosita South': 1407,
    'Rotan': 1407,
    'Round Rock': 1407,
    'Rowlett': 1407,
    'Royse City': 1407,
    'Runaway Bay': 1407,
    'Runge': 1407,
    'Runnels County': 1407,
    'Rusk': 1407,
    'Rusk County': 1407,
    'Sabinal': 1407,
    'Sabine County': 1407,
    'Sachse': 1407,
    'Saginaw': 1407,
    'Saint Hedwig': 1407,
    'Saint Jo': 1407,
    'Saint Paul': 1407,
    'Salado': 1407,
    'Sam Rayburn': 1407,
    'San Angelo': 1407,
    'San Antonio': 1407,
    'San Augustine': 1407,
    'San Augustine County': 1407,
    'San Benito': 1407,
    'San Carlos': 1407,
    'San Diego': 1407,
    'San Elizario': 1407,
    'San Jacinto County': 1407,
    'San Juan': 1407,
    'San Leon': 1407,
    'San Marcos': 1407,
    'San Patricio County': 1407,
    'San Saba': 1407,
    'San Saba County': 1407,
    'Sanderson': 1407,
    'Sanger': 1407,
    'Sansom Park': 1407,
    'Santa Anna': 1407,
    'Santa Fe': 1407,
    'Santa Rosa': 1407,
    'Sarita': 1407,
    'Savannah': 1407,
    'Scenic Oaks': 1407,
    'Schertz': 1407,
    'Schleicher County': 1407,
    'Schulenburg': 1407,
    'Scissors': 1407,
    'Scurry County': 1407,
    'Seabrook': 1407,
    'Seadrift': 1407,
    'Seagoville': 1407,
    'Seagraves': 1407,
    'Sealy': 1407,
    'Sebastian': 1407,
    'Seguin': 1407,
    'Selma': 1407,
    'Seminole': 1407,
    'Serenada': 1407,
    'Seth Ward': 1407,
    'Seven Points': 1407,
    'Seymour': 1407,
    'Shackelford County': 1407,
    'Shady Hollow': 1407,
    'Shady Shores': 1407,
    'Shallowater': 1407,
    'Shamrock': 1407,
    'Shavano Park': 1407,
    'Shelby County': 1407,
    'Sheldon': 1407,
    'Shenandoah': 1407,
    'Shepherd': 1407,
    'Sherman': 1407,
    'Sherman County': 1407,
    'Sherwood Shores': 1407,
    'Shiner': 1407,
    'Shoreacres': 1407,
    'Sienna Plantation': 1407,
    'Sierra Blanca': 1407,
    'Siesta Acres': 1407,
    'Siesta Shores': 1407,
    'Silsbee': 1407,
    'Silverton': 1407,
    'Sinton': 1407,
    'Slaton': 1407,
    'Smith County': 1407,
    'Smithville': 1407,
    'Snyder': 1407,
    'Socorro': 1407,
    'Socorro Mission Number 1 Colonia': 1407,
    'Somerset': 1407,
    'Somervell County': 1407,
    'Somerville': 1407,
    'Sonora': 1407,
    'Sour Lake': 1407,
    'South Alamo': 1407,
    'South Houston': 1407,
    'South Padre Island': 1407,
    'South Point': 1407,
    'Southlake': 1407,
    'Southmayd': 1407,
    'Southside Place': 1407,
    'Sparks': 1407,
    'Spearman': 1407,
    'Splendora': 1407,
    'Spring': 1407,
    'Spring Valley': 1407,
    'Springtown': 1407,
    'Spur': 1407,
    'Stafford': 1407,
    'Stamford': 1407,
    'Stanton': 1407,
    'Starr County': 1407,
    'Stephens County': 1407,
    'Stephenville': 1407,
    'Sterling City': 1407,
    'Sterling County': 1407,
    'Stinnett': 1407,
    'Stockdale': 1407,
    'Stonewall County': 1407,
    'Stowell': 1407,
    'Stratford': 1407,
    'Sugar Land': 1407,
    'Sullivan City': 1407,
    'Sulphur Springs': 1407,
    'Sundown': 1407,
    'Sunnyvale': 1407,
    'Sunray': 1407,
    'Sutton County': 1407,
    'Sweeny': 1407,
    'Sweetwater': 1407,
    'Swisher County': 1407,
    'Taft': 1407,
    'Taft Southwest (historical)': 1407,
    'Tahoka': 1407,
    'Talty': 1407,
    'Tarrant County': 1407,
    'Tatum': 1407,
    'Taylor': 1407,
    'Taylor County': 1407,
    'Taylor Lake Village': 1407,
    'Teague': 1407,
    'Temple': 1407,
    'Tenaha': 1407,
    'Terrell': 1407,
    'Terrell County': 1407,
    'Terrell Hills': 1407,
    'Terry County': 1407,
    'Texarkana': 1407,
    'Texas City': 1407,
    'The Colony': 1407,
    'The Hills': 1407,
    'The Woodlands': 1407,
    'Thorndale': 1407,
    'Three Rivers': 1407,
    'Throckmorton': 1407,
    'Throckmorton County': 1407,
    'Tiki Island': 1407,
    'Tilden': 1407,
    'Timberwood Park': 1407,
    'Timpson': 1407,
    'Titus County': 1407,
    'Tom Bean': 1407,
    'Tom Green County': 1407,
    'Tomball': 1407,
    'Tool': 1407,
    'Tornillo': 1407,
    'Travis County': 1407,
    'Travis Ranch': 1407,
    'Trinity': 1407,
    'Trinity County': 1407,
    'Trophy Club': 1407,
    'Troup': 1407,
    'Troy': 1407,
    'Tulia': 1407,
    'Tye': 1407,
    'Tyler': 1407,
    'Tyler County': 1407,
    'Uhland': 1407,
    'Universal City': 1407,
    'University Park': 1407,
    'Upshur County': 1407,
    'Upton County': 1407,
    'Uvalde': 1407,
    'Uvalde County': 1407,
    'Uvalde Estates': 1407,
    'Val Verde County': 1407,
    'Val Verde Park': 1407,
    'Valley Mills': 1407,
    'Van': 1407,
    'Van Alstyne': 1407,
    'Van Horn': 1407,
    'Van Vleck': 1407,
    'Van Zandt County': 1407,
    'Vega': 1407,
    'Venus': 1407,
    'Vernon': 1407,
    'Victoria': 1407,
    'Victoria County': 1407,
    'Vidor': 1407,
    'Vinton': 1407,
    'Von Ormy': 1407,
    'Waco': 1407,
    'Wake Village': 1407,
    'Walker County': 1407,
    'Waller': 1407,
    'Waller County': 1407,
    'Wallis': 1407,
    'Ward County': 1407,
    'Washington County': 1407,
    'Waskom': 1407,
    'Watauga': 1407,
    'Waxahachie': 1407,
    'Weatherford': 1407,
    'Webb County': 1407,
    'Webster': 1407,
    'Weimar': 1407,
    'Wellington': 1407,
    'Wells Branch': 1407,
    'Weslaco': 1407,
    'West': 1407,
    'West Columbia': 1407,
    'West Lake Hills': 1407,
    'West Livingston': 1407,
    'West Odessa': 1407,
    'West Orange': 1407,
    'West Sharyland': 1407,
    'West Tawakoni': 1407,
    'West University Place': 1407,
    'Western Lake': 1407,
    'Westlake': 1407,
    'Weston Lakes': 1407,
    'Westway': 1407,
    'Westworth': 1407,
    'Wharton': 1407,
    'Wharton County': 1407,
    'Wheeler': 1407,
    'Wheeler County': 1407,
    'White Oak': 1407,
    'White Settlement': 1407,
    'Whitehouse': 1407,
    'Whitesboro': 1407,
    'Whitewright': 1407,
    'Whitney': 1407,
    'Wichita County': 1407,
    'Wichita Falls': 1407,
    'Wilbarger County': 1407,
    'Wild Peach Village': 1407,
    'Wildwood': 1407,
    'Willacy County': 1407,
    'Williamson County': 1407,
    'Willis': 1407,
    'Willow Park': 1407,
    'Wills Point': 1407,
    'Wilmer': 1407,
    'Wilson County': 1407,
    'Wimberley': 1407,
    'Windcrest': 1407,
    'Windemere': 1407,
    'Wink': 1407,
    'Winkler County': 1407,
    'Winnie': 1407,
    'Winnsboro': 1407,
    'Winters': 1407,
    'Wise County': 1407,
    'Wolfe City': 1407,
    'Wolfforth': 1407,
    'Wood County': 1407,
    'Woodbranch': 1407,
    'Woodcreek': 1407,
    'Woodsboro': 1407,
    'Woodville': 1407,
    'Woodway': 1407,
    'Wortham': 1407,
    'Wyldwood': 1407,
    'Wylie': 1407,
    'Yoakum': 1407,
    'Yoakum County': 1407,
    'Yorktown': 1407,
    'Young County': 1407,
    'Zapata': 1407,
    'Zapata County': 1407,
    'Zavala County': 1407,
    'Alpine': 1414,
    'American Fork': 1414,
    'Aurora': 1414,
    'Ballard': 1414,
    'Beaver': 1414,
    'Beaver County': 1414,
    'Benjamin': 1414,
    'Benson': 1414,
    'Blanding': 1414,
    'Bluffdale': 1414,
    'Bountiful': 1414,
    'Box Elder County': 1414,
    'Brigham City': 1414,
    'Cache County': 1414,
    'Canyon Rim': 1414,
    'Carbon County': 1414,
    'Carbonville': 1414,
    'Castle Dale': 1414,
    'Cedar City': 1414,
    'Cedar Hills': 1414,
    'Centerfield': 1414,
    'Centerville': 1414,
    'Clearfield': 1414,
    'Clinton': 1414,
    'Coalville': 1414,
    'Cottonwood Heights': 1414,
    'Daggett County': 1414,
    'Daniel': 1414,
    'Davis County': 1414,
    'Delta': 1414,
    'Draper': 1414,
    'Duchesne': 1414,
    'Duchesne County': 1414,
    'Eagle Mountain': 1414,
    'East Carbon City': 1414,
    'East Millcreek': 1414,
    'Elk Ridge': 1414,
    'Elwood': 1414,
    'Emery County': 1414,
    'Enoch': 1414,
    'Enterprise': 1414,
    'Ephraim': 1414,
    'Erda': 1414,
    'Fairview': 1414,
    'Farmington': 1414,
    'Farr West': 1414,
    'Ferron': 1414,
    'Fillmore': 1414,
    'Fountain Green': 1414,
    'Francis': 1414,
    'Fruit Heights': 1414,
    'Garfield County': 1414,
    'Garland': 1414,
    'Genola': 1414,
    'Grand County': 1414,
    'Granite': 1414,
    'Grantsville': 1414,
    'Gunnison': 1414,
    'Harrisville': 1414,
    'Heber City': 1414,
    'Helper': 1414,
    'Herriman': 1414,
    'Highland': 1414,
    'Hildale': 1414,
    'Hill Air Force Base': 1414,
    'Holladay': 1414,
    'Honeyville': 1414,
    'Hooper': 1414,
    'Huntington': 1414,
    'Hurricane': 1414,
    'Hyde Park': 1414,
    'Hyrum': 1414,
    'Iron County': 1414,
    'Ivins': 1414,
    'Juab County': 1414,
    'Junction': 1414,
    'Kamas': 1414,
    'Kanab': 1414,
    'Kane County': 1414,
    'Kaysville': 1414,
    'Kearns': 1414,
    'LaVerkin': 1414,
    'Layton': 1414,
    'Lehi': 1414,
    'Lewiston': 1414,
    'Liberty': 1414,
    'Lindon': 1414,
    'Little Cottonwood Creek Valley': 1414,
    'Loa': 1414,
    'Logan': 1414,
    'Maeser': 1414,
    'Magna': 1414,
    'Manila': 1414,
    'Manti': 1414,
    'Mapleton': 1414,
    'Marriott-Slaterville': 1414,
    'Mendon': 1414,
    'Midvale': 1414,
    'Midway': 1414,
    'Milford': 1414,
    'Millard County': 1414,
    'Millcreek': 1414,
    'Millville': 1414,
    'Moab': 1414,
    'Mona': 1414,
    'Monroe': 1414,
    'Monticello': 1414,
    'Morgan': 1414,
    'Morgan County': 1414,
    'Moroni': 1414,
    'Mount Olympus': 1414,
    'Mount Pleasant': 1414,
    'Mountain Green': 1414,
    'Murray': 1414,
    'Naples': 1414,
    'Nephi': 1414,
    'Nibley': 1414,
    'North Logan': 1414,
    'North Ogden': 1414,
    'North Salt Lake': 1414,
    'Oakley': 1414,
    'Ogden': 1414,
    'Oquirrh': 1414,
    'Orangeville': 1414,
    'Orem': 1414,
    'Panguitch': 1414,
    'Park City': 1414,
    'Parowan': 1414,
    'Payson': 1414,
    'Perry': 1414,
    'Piute County': 1414,
    'Plain City': 1414,
    'Pleasant Grove': 1414,
    'Pleasant View': 1414,
    'Price': 1414,
    'Providence': 1414,
    'Provo': 1414,
    'Randolph': 1414,
    'Rich County': 1414,
    'Richfield': 1414,
    'Richmond': 1414,
    'River Heights': 1414,
    'Riverdale': 1414,
    'Riverton': 1414,
    'Roosevelt': 1414,
    'Roy': 1414,
    'Saint George': 1414,
    'Salem': 1414,
    'Salina': 1414,
    'Salt Lake City': 1414,
    'Salt Lake County': 1414,
    'San Juan County': 1414,
    'Sandy': 1414,
    'Sandy Hills': 1414,
    'Sanpete County': 1414,
    'Santa Clara': 1414,
    'Santaquin': 1414,
    'Saratoga Springs': 1414,
    'Sevier County': 1414,
    'Silver Summit': 1414,
    'Smithfield': 1414,
    'Snyderville': 1414,
    'South Jordan': 1414,
    'South Jordan Heights': 1414,
    'South Ogden': 1414,
    'South Salt Lake': 1414,
    'South Weber': 1414,
    'South Willard': 1414,
    'Spanish Fork': 1414,
    'Spring City': 1414,
    'Spring Glen': 1414,
    'Springville': 1414,
    'Stansbury park': 1414,
    'Summit County': 1414,
    'Summit Park': 1414,
    'Sunset': 1414,
    'Syracuse': 1414,
    'Taylorsville': 1414,
    'Tooele': 1414,
    'Tooele County': 1414,
    'Toquerville': 1414,
    'Tremonton': 1414,
    'Uintah': 1414,
    'Uintah County': 1414,
    'Utah County': 1414,
    'Vernal': 1414,
    'Vineyard': 1414,
    'Wasatch County': 1414,
    'Washington': 1414,
    'Washington County': 1414,
    'Washington Terrace': 1414,
    'Wayne County': 1414,
    'Weber County': 1414,
    'Wellington': 1414,
    'Wellsville': 1414,
    'Wendover': 1414,
    'West Bountiful': 1414,
    'West Haven': 1414,
    'West Jordan': 1414,
    'West Mountain': 1414,
    'West Point': 1414,
    'West Valley City': 1414,
    'White City': 1414,
    'Willard': 1414,
    'Wolf Creek': 1414,
    'Woodland Hills': 1414,
    'Woods Cross': 1414,
    'Addison': 1409,
    'Addison County': 1409,
    'Arlington': 1409,
    'Barre': 1409,
    'Bellows Falls': 1409,
    'Bennington': 1409,
    'Bennington County': 1409,
    'Brandon': 1409,
    'Brattleboro': 1409,
    'Bridport': 1409,
    'Bristol': 1409,
    'Burlington': 1409,
    'Caledonia County': 1409,
    'Castleton': 1409,
    'Charlotte': 1409,
    'Chelsea': 1409,
    'Chester': 1409,
    'Chittenden': 1409,
    'Chittenden County': 1409,
    'Clarendon': 1409,
    'Colchester': 1409,
    'Danby': 1409,
    'Dover': 1409,
    'Enosburg Falls': 1409,
    'Essex County': 1409,
    'Essex Junction': 1409,
    'Fair Haven': 1409,
    'Ferrisburgh': 1409,
    'Franklin County': 1409,
    'Grand Isle County': 1409,
    'Guildhall': 1409,
    'Hardwick': 1409,
    'Hartford': 1409,
    'Hinesburg': 1409,
    'Hyde Park': 1409,
    'Jamaica': 1409,
    'Jericho': 1409,
    'Johnson': 1409,
    'Lamoille County': 1409,
    'Leicester': 1409,
    'Lincoln': 1409,
    'Londonderry': 1409,
    'Lunenburg': 1409,
    'Lyndon': 1409,
    'Lyndonville': 1409,
    'Manchester Center': 1409,
    'Mendon': 1409,
    'Middlebury (village)': 1409,
    'Milton': 1409,
    'Montgomery': 1409,
    'Montpelier': 1409,
    'Moretown': 1409,
    'Morristown': 1409,
    'Morrisville': 1409,
    'Mount Holly': 1409,
    'Newfane': 1409,
    'Newport': 1409,
    'North Bennington': 1409,
    'North Hero': 1409,
    'Northfield': 1409,
    'Orange County': 1409,
    'Orleans County': 1409,
    'Pawlet': 1409,
    'Poultney': 1409,
    'Pownal': 1409,
    'Randolph': 1409,
    'Richford': 1409,
    'Rockingham': 1409,
    'Rutland': 1409,
    'Rutland County': 1409,
    'Saint Albans': 1409,
    'Saint Johnsbury': 1409,
    'Salisbury': 1409,
    'South Barre': 1409,
    'South Burlington': 1409,
    'Springfield': 1409,
    'St Johnsbury': 1409,
    'Starksboro': 1409,
    'Stowe': 1409,
    'Swanton': 1409,
    'Townshend': 1409,
    'Vergennes': 1409,
    'Washington': 1409,
    'Washington County': 1409,
    'Waterbury': 1409,
    'West Brattleboro': 1409,
    'West Rutland': 1409,
    'White River Junction': 1409,
    'Wilder': 1409,
    'Williamstown': 1409,
    'Williston': 1409,
    'Windham County': 1409,
    'Windsor': 1409,
    'Windsor County': 1409,
    'Winooski': 1409,
    'Woodstock': 1409,
    'Abingdon': 1427,
    'Accomac': 1427,
    'Accomack County': 1427,
    'Adwolf': 1427,
    'Albemarle County': 1427,
    'Alexandria': 1427,
    'Alleghany County': 1427,
    'Altavista': 1427,
    'Amelia County': 1427,
    'Amelia Court House': 1427,
    'Amherst': 1427,
    'Amherst County': 1427,
    'Annandale': 1427,
    'Appalachia': 1427,
    'Apple Mountain Lake': 1427,
    'Appomattox': 1427,
    'Appomattox County': 1427,
    'Aquia Harbour': 1427,
    'Arlington': 1427,
    'Arlington County': 1427,
    'Ashburn': 1427,
    'Ashland': 1427,
    'Atkins': 1427,
    'Augusta County': 1427,
    'Baileys Crossroads': 1427,
    'Bassett': 1427,
    'Basye': 1427,
    'Bath County': 1427,
    'Bealeton': 1427,
    'Bedford': 1427,
    'Bedford County': 1427,
    'Belle Haven': 1427,
    'Bellwood': 1427,
    'Belmont': 1427,
    'Belmont Estates': 1427,
    'Bensley': 1427,
    'Berryville': 1427,
    'Big Stone Gap': 1427,
    'Blacksburg': 1427,
    'Blackstone': 1427,
    'Bland': 1427,
    'Bland County': 1427,
    'Blue Ridge': 1427,
    'Bluefield': 1427,
    'Bon Air': 1427,
    'Boswell  s Corner': 1427,
    'Botetourt County': 1427,
    'Bowling Green': 1427,
    'Boydton': 1427,
    'Bracey': 1427,
    'Brambleton': 1427,
    'Brandermill': 1427,
    'Bridgewater': 1427,
    'Brightwood': 1427,
    'Bristol': 1427,
    'Broadlands': 1427,
    'Broadway': 1427,
    'Brookneal': 1427,
    'Brunswick County': 1427,
    'Buchanan': 1427,
    'Buchanan County': 1427,
    'Buckhall': 1427,
    'Buckingham': 1427,
    'Buckingham County': 1427,
    'Buena Vista': 1427,
    'Bull Run': 1427,
    'Bull Run Mountain Estates': 1427,
    'Burke': 1427,
    'Campbell County': 1427,
    'Cana': 1427,
    'Cape Charles': 1427,
    'Captains Cove': 1427,
    'Caroline County': 1427,
    'Carroll County': 1427,
    'Carrollton': 1427,
    'Castlewood': 1427,
    'Cave Spring': 1427,
    'Cedar Bluff': 1427,
    'Central Garage': 1427,
    'Centreville': 1427,
    'Chamberlayne': 1427,
    'Chantilly': 1427,
    'Charles City': 1427,
    'Charles City County': 1427,
    'Charlotte County': 1427,
    'Charlotte Court House': 1427,
    'Charlottesville': 1427,
    'Chase City': 1427,
    'Chatham': 1427,
    'Chatmoss': 1427,
    'Cherry Hill': 1427,
    'Chesapeake': 1427,
    'Chester': 1427,
    'Chesterfield County': 1427,
    'Chesterfield Court House': 1427,
    'Chilhowie': 1427,
    'Chincoteague': 1427,
    'Christiansburg': 1427,
    'City of Alexandria': 1427,
    'City of Bedford': 1427,
    'City of Bristol': 1427,
    'City of Buena Vista': 1427,
    'City of Charlottesville': 1427,
    'City of Chesapeake': 1427,
    'City of Colonial Heights': 1427,
    'City of Covington': 1427,
    'City of Danville': 1427,
    'City of Emporia': 1427,
    'City of Fairfax': 1427,
    'City of Falls Church': 1427,
    'City of Franklin': 1427,
    'City of Fredericksburg': 1427,
    'City of Galax': 1427,
    'City of Hampton': 1427,
    'City of Harrisonburg': 1427,
    'City of Hopewell': 1427,
    'City of Lexington': 1427,
    'City of Lynchburg': 1427,
    'City of Manassas': 1427,
    'City of Manassas Park': 1427,
    'City of Martinsville': 1427,
    'City of Newport News': 1427,
    'City of Norfolk': 1427,
    'City of Norton': 1427,
    'City of Petersburg': 1427,
    'City of Poquoson': 1427,
    'City of Portsmouth': 1427,
    'City of Radford': 1427,
    'City of Richmond': 1427,
    'City of Roanoke': 1427,
    'City of Salem': 1427,
    'City of Staunton': 1427,
    'City of Suffolk': 1427,
    'City of Virginia Beach': 1427,
    'City of Waynesboro': 1427,
    'City of Williamsburg': 1427,
    'City of Winchester': 1427,
    'Clarke County': 1427,
    'Clarksville': 1427,
    'Claypool Hill': 1427,
    'Clifton Forge': 1427,
    'Clintwood': 1427,
    'Cloverdale': 1427,
    'Coeburn': 1427,
    'Collinsville': 1427,
    'Colonial Beach': 1427,
    'Colonial Heights': 1427,
    'Concord': 1427,
    'Countryside': 1427,
    'Courtland': 1427,
    'Covington': 1427,
    'Craig County': 1427,
    'Crewe': 1427,
    'Crimora': 1427,
    'Crozet': 1427,
    'Culpeper': 1427,
    'Culpeper County': 1427,
    'Cumberland': 1427,
    'Cumberland County': 1427,
    'Dahlgren': 1427,
    'Dale City': 1427,
    'Daleville': 1427,
    'Danville': 1427,
    'Dayton': 1427,
    'Deltaville': 1427,
    'Dickenson County': 1427,
    'Dinwiddie County': 1427,
    'Dooms': 1427,
    'Dranesville': 1427,
    'Dryden': 1427,
    'Dublin': 1427,
    'Dulles Town Center': 1427,
    'Dumbarton': 1427,
    'Dumfries': 1427,
    'Dunn Loring': 1427,
    'East Hampton': 1427,
    'East Highland Park': 1427,
    'East Lexington': 1427,
    'Eastville': 1427,
    'Edinburg': 1427,
    'Elkton': 1427,
    'Emory': 1427,
    'Emporia': 1427,
    'Enon': 1427,
    'Essex County': 1427,
    'Ettrick': 1427,
    'Exmore': 1427,
    'Fairfax': 1427,
    'Fairfax County': 1427,
    'Fairfax Station': 1427,
    'Fairlawn': 1427,
    'Falls Church': 1427,
    'Falmouth': 1427,
    'Farmville': 1427,
    'Fauquier County': 1427,
    'Ferrum': 1427,
    'Fincastle': 1427,
    'Fishersville': 1427,
    'Floris': 1427,
    'Floyd': 1427,
    'Floyd County': 1427,
    'Fluvanna County': 1427,
    'Forest': 1427,
    'Fort Belvoir': 1427,
    'Fort Hunt': 1427,
    'Fort Lee': 1427,
    'Franconia': 1427,
    'Franklin': 1427,
    'Franklin County': 1427,
    'Frederick County': 1427,
    'Fredericksburg': 1427,
    'Front Royal': 1427,
    'Gainesville': 1427,
    'Galax': 1427,
    'Gate City': 1427,
    'Giles County': 1427,
    'Glade Spring': 1427,
    'Glasgow': 1427,
    'Glen Allen': 1427,
    'Gloucester County': 1427,
    'Gloucester Courthouse': 1427,
    'Gloucester Point': 1427,
    'Goochland': 1427,
    'Goochland County': 1427,
    'Gordonsville': 1427,
    'Grayson County': 1427,
    'Great Falls': 1427,
    'Greenbriar': 1427,
    'Greene County': 1427,
    'Greensville County': 1427,
    'Gretna': 1427,
    'Grottoes': 1427,
    'Groveton': 1427,
    'Grundy': 1427,
    'Halifax': 1427,
    'Halifax County': 1427,
    'Hampden Sydney': 1427,
    'Hampton': 1427,
    'Hanover': 1427,
    'Hanover County': 1427,
    'Harrisonburg': 1427,
    'Hayfield': 1427,
    'Haymarket': 1427,
    'Heathsville': 1427,
    'Henrico County': 1427,
    'Henry County': 1427,
    'Henry Fork': 1427,
    'Herndon': 1427,
    'Highland County': 1427,
    'Highland Springs': 1427,
    'Hillsville': 1427,
    'Hollins': 1427,
    'Hollymead': 1427,
    'Honaker': 1427,
    'Hopewell': 1427,
    'Horse Pasture': 1427,
    'Huntington': 1427,
    'Hurt': 1427,
    'Hybla Valley': 1427,
    'Idylwood': 1427,
    'Independence': 1427,
    'Independent Hill': 1427,
    'Isle of Wight County': 1427,
    'James City County': 1427,
    'Jefferson': 1427,
    'Jolivue': 1427,
    'Jonesville': 1427,
    'Kenbridge': 1427,
    'Kilmarnock': 1427,
    'King and Queen County': 1427,
    'King and Queen Court House': 1427,
    'King George': 1427,
    'King George County': 1427,
    'King William': 1427,
    'King William County': 1427,
    'Kings Park': 1427,
    'Kings Park West': 1427,
    'Lake Barcroft': 1427,
    'Lake Monticello': 1427,
    'Lake Ridge': 1427,
    'Lakeside': 1427,
    'Lancaster County': 1427,
    'Laurel': 1427,
    'Laurel Hill': 1427,
    'Lawrenceville': 1427,
    'Laymantown': 1427,
    'Lebanon': 1427,
    'Lee County': 1427,
    'Leesburg': 1427,
    'Lexington': 1427,
    'Lincolnia': 1427,
    'Linton Hall': 1427,
    'Loch Lomond': 1427,
    'Lorton': 1427,
    'Loudoun County': 1427,
    'Loudoun Valley Estates': 1427,
    'Louisa': 1427,
    'Louisa County': 1427,
    'Lovettsville': 1427,
    'Lovingston': 1427,
    'Lowes Island': 1427,
    'Lunenburg': 1427,
    'Lunenburg County': 1427,
    'Luray': 1427,
    'Lynchburg': 1427,
    'Lyndhurst': 1427,
    'Madison': 1427,
    'Madison County': 1427,
    'Madison Heights': 1427,
    'Manassas': 1427,
    'Manassas Park': 1427,
    'Mantua': 1427,
    'Marion': 1427,
    'Marshall': 1427,
    'Martinsville': 1427,
    'Massanetta Springs': 1427,
    'Massanutten': 1427,
    'Mathews': 1427,
    'Mathews County': 1427,
    'Matoaca': 1427,
    'McLean': 1427,
    'Meadowbrook': 1427,
    'Mechanicsville': 1427,
    'Mecklenburg County': 1427,
    'Merrifield': 1427,
    'Merrimac': 1427,
    'Middlesex County': 1427,
    'Middletown': 1427,
    'Montclair': 1427,
    'Monterey': 1427,
    'Montgomery County': 1427,
    'Montrose': 1427,
    'Montross': 1427,
    'Motley': 1427,
    'Mount Hermon': 1427,
    'Mount Jackson': 1427,
    'Mount Vernon': 1427,
    'Mountain Road': 1427,
    'Narrows': 1427,
    'Nellysford': 1427,
    'Nelson County': 1427,
    'New Baltimore': 1427,
    'New Castle': 1427,
    'New Kent': 1427,
    'New Kent County': 1427,
    'New Market': 1427,
    'Newington': 1427,
    'Newport News': 1427,
    'Nokesville': 1427,
    'Norfolk': 1427,
    'North Shore': 1427,
    'North Springfield': 1427,
    'Northampton County': 1427,
    'Northumberland County': 1427,
    'Norton': 1427,
    'Nottoway County': 1427,
    'Oak Grove': 1427,
    'Oak Hill': 1427,
    'Oakton': 1427,
    'Occoquan': 1427,
    'Onancock': 1427,
    'Orange': 1427,
    'Orange County': 1427,
    'Page County': 1427,
    'Palmyra': 1427,
    'Pannill Fork': 1427,
    'Pantops': 1427,
    'Passapatanzy': 1427,
    'Patrick County': 1427,
    'Patrick Springs': 1427,
    'Pearisburg': 1427,
    'Pembroke': 1427,
    'Pennington Gap': 1427,
    'Petersburg': 1427,
    'Pimmit Hills': 1427,
    'Pittsylvania County': 1427,
    'Plum Creek': 1427,
    'Poquoson': 1427,
    'Portsmouth': 1427,
    'Portsmouth Heights': 1427,
    'Potomac Mills': 1427,
    'Powhatan County': 1427,
    'Prices Fork': 1427,
    'Prince Edward County': 1427,
    'Prince George': 1427,
    'Prince George County': 1427,
    'Prince William County': 1427,
    'Pulaski': 1427,
    'Pulaski County': 1427,
    'Purcellville': 1427,
    'Quantico Station': 1427,
    'Radford': 1427,
    'Rappahannock County': 1427,
    'Raven': 1427,
    'Ravensworth': 1427,
    'Reston': 1427,
    'Richlands': 1427,
    'Richmond': 1427,
    'Richmond County': 1427,
    'Roanoke': 1427,
    'Roanoke County': 1427,
    'Rockbridge County': 1427,
    'Rockingham County': 1427,
    'Rocky Mount': 1427,
    'Rose Hill': 1427,
    'Rosslyn': 1427,
    'Ruckersville': 1427,
    'Rural Retreat': 1427,
    'Rushmere': 1427,
    'Russell County': 1427,
    'Rustburg': 1427,
    'Salem': 1427,
    'Saltville': 1427,
    'Saluda': 1427,
    'Sandston': 1427,
    'Scott County': 1427,
    'Seven Corners': 1427,
    'Shawnee Land': 1427,
    'Shawsville': 1427,
    'Shenandoah': 1427,
    'Shenandoah County': 1427,
    'Shenandoah Farms': 1427,
    'Short Pump': 1427,
    'Smithfield': 1427,
    'Smyth County': 1427,
    'South Boston': 1427,
    'South Hill': 1427,
    'South Riding': 1427,
    'South Suffolk': 1427,
    'Southampton County': 1427,
    'Southern Gateway': 1427,
    'Spotsylvania County': 1427,
    'Spotsylvania Courthouse': 1427,
    'Springfield': 1427,
    'Springville': 1427,
    'Stafford': 1427,
    'Stafford County': 1427,
    'Stanardsville': 1427,
    'Stanley': 1427,
    'Stanleytown': 1427,
    'Staunton': 1427,
    'Stephens City': 1427,
    'Sterling': 1427,
    'Strasburg': 1427,
    'Stuart': 1427,
    'Stuarts Draft': 1427,
    'Sudley': 1427,
    'Suffolk': 1427,
    'Sugarland Run': 1427,
    'Surry': 1427,
    'Surry County': 1427,
    'Sussex': 1427,
    'Sussex County': 1427,
    'Tappahannock': 1427,
    'Tazewell': 1427,
    'Tazewell County': 1427,
    'Timberlake': 1427,
    'Timberville': 1427,
    'Triangle': 1427,
    'Tuckahoe': 1427,
    'Twin Lakes': 1427,
    'Tysons Corner': 1427,
    'Union Hall': 1427,
    'University Center': 1427,
    'Verona': 1427,
    'Victoria': 1427,
    'Vienna': 1427,
    'Vinton': 1427,
    'Virginia Beach': 1427,
    'Warm Springs': 1427,
    'Warren County': 1427,
    'Warrenton': 1427,
    'Warsaw': 1427,
    'Washington': 1427,
    'Washington County': 1427,
    'Wattsville': 1427,
    'Waverly': 1427,
    'Waynesboro': 1427,
    'Weber City': 1427,
    'West Falls Church': 1427,
    'West Gate': 1427,
    'West Lynchburg': 1427,
    'West Point': 1427,
    'West Springfield': 1427,
    'Westmoreland County': 1427,
    'Weyers Cave': 1427,
    'Williamsburg': 1427,
    'Winchester': 1427,
    'Windsor': 1427,
    'Wise': 1427,
    'Wise County': 1427,
    'Wolf Trap': 1427,
    'Woodbridge': 1427,
    'Woodburn': 1427,
    'Woodlake': 1427,
    'Woodlawn': 1427,
    'Woodstock': 1427,
    'Wyndham': 1427,
    'Wythe County': 1427,
    'Wytheville': 1427,
    'York County': 1427,
    'Yorkshire': 1427,
    'Yorktown': 1427,
    'Aberdeen': 1462,
    'Adams County': 1462,
    'Ahtanum': 1462,
    'Airway Heights': 1462,
    'Alderton': 1462,
    'Alderwood Manor': 1462,
    'Algona': 1462,
    'Allyn': 1462,
    'Amboy': 1462,
    'Ames Lake': 1462,
    'Anacortes': 1462,
    'Arlington': 1462,
    'Arlington Heights': 1462,
    'Artondale': 1462,
    'Asotin': 1462,
    'Asotin County': 1462,
    'Auburn': 1462,
    'Ault Field': 1462,
    'Bainbridge Island': 1462,
    'Bangor Trident Base': 1462,
    'Barberton': 1462,
    'Basin City': 1462,
    'Battle Ground': 1462,
    'Belfair': 1462,
    'Bellevue': 1462,
    'Bellingham': 1462,
    'Benton City': 1462,
    'Benton County': 1462,
    'Bethel': 1462,
    'Big Lake': 1462,
    'Birch Bay': 1462,
    'Black Diamond': 1462,
    'Blaine': 1462,
    'Bonney Lake': 1462,
    'Bothell': 1462,
    'Bothell East': 1462,
    'Bothell West': 1462,
    'Boulevard Park': 1462,
    'Bremerton': 1462,
    'Brewster': 1462,
    'Bridgeport': 1462,
    'Brier': 1462,
    'Browns Point': 1462,
    'Brush Prairie': 1462,
    'Bryant': 1462,
    'Bryn Mawr-Skyway': 1462,
    'Buckley': 1462,
    'Bunk Foss': 1462,
    'Burbank': 1462,
    'Burien': 1462,
    'Burley': 1462,
    'Burlington': 1462,
    'Camano': 1462,
    'Camas': 1462,
    'Canterwood': 1462,
    'Carnation': 1462,
    'Carson': 1462,
    'Cascade Valley': 1462,
    'Cashmere': 1462,
    'Castle Rock': 1462,
    'Cathcart': 1462,
    'Cathlamet': 1462,
    'Central Park': 1462,
    'Centralia': 1462,
    'Chehalis': 1462,
    'Chelan': 1462,
    'Chelan County': 1462,
    'Cheney': 1462,
    'Chewelah': 1462,
    'Chico': 1462,
    'City of Sammamish': 1462,
    'Clallam County': 1462,
    'Clark County': 1462,
    'Clarkston': 1462,
    'Clarkston Heights-Vineland': 1462,
    'Cle Elum': 1462,
    'Clear Lake': 1462,
    'Clearview': 1462,
    'Clyde Hill': 1462,
    'Colfax': 1462,
    'College Place': 1462,
    'Columbia County': 1462,
    'Colville': 1462,
    'Connell': 1462,
    'Cosmopolis': 1462,
    'Cottage Lake': 1462,
    'Coulee Dam': 1462,
    'Country Homes': 1462,
    'Coupeville': 1462,
    'Covington': 1462,
    'Cowlitz County': 1462,
    'Crocker': 1462,
    'Dallesport': 1462,
    'Darrington': 1462,
    'Davenport': 1462,
    'Dayton': 1462,
    'Deer Park': 1462,
    'Des Moines': 1462,
    'Desert Aire': 1462,
    'Dishman': 1462,
    'Dollar Corner': 1462,
    'Douglas County': 1462,
    'DuPont': 1462,
    'Duvall': 1462,
    'East Hill-Meridian': 1462,
    'East Port Orchard': 1462,
    'East Renton Highlands': 1462,
    'East Wenatchee': 1462,
    'East Wenatchee Bench': 1462,
    'Eastgate': 1462,
    'Eastmont': 1462,
    'Eatonville': 1462,
    'Edgewood': 1462,
    'Edmonds': 1462,
    'Electric City': 1462,
    'Elk Plain': 1462,
    'Ellensburg': 1462,
    'Elma': 1462,
    'Enetai': 1462,
    'Entiat': 1462,
    'Enumclaw': 1462,
    'Ephrata': 1462,
    'Erlands Point-Kitsap Lake': 1462,
    'Esperance': 1462,
    'Everett': 1462,
    'Everson': 1462,
    'Fairchild Air Force Base': 1462,
    'Fairwood': 1462,
    'Fall City': 1462,
    'Federal Way': 1462,
    'Felida': 1462,
    'Fern Prairie': 1462,
    'Ferndale': 1462,
    'Ferry County': 1462,
    'Fife': 1462,
    'Fife Heights': 1462,
    'Finley': 1462,
    'Fircrest': 1462,
    'Five Corners': 1462,
    'Fobes Hill': 1462,
    'Fords Prairie': 1462,
    'Forks': 1462,
    'Fox Island': 1462,
    'Franklin County': 1462,
    'Frederickson': 1462,
    'Freeland': 1462,
    'Friday Harbor': 1462,
    'Garfield County': 1462,
    'Garrett': 1462,
    'Geneva': 1462,
    'Gig Harbor': 1462,
    'Gleed': 1462,
    'Gold Bar': 1462,
    'Goldendale': 1462,
    'Graham': 1462,
    'Grand Coulee': 1462,
    'Grand Mound': 1462,
    'Grandview': 1462,
    'Granger': 1462,
    'Granite Falls': 1462,
    'Grant County': 1462,
    'Grays Harbor County': 1462,
    'Hansville': 1462,
    'Hazel Dell': 1462,
    'Highland': 1462,
    'Hobart': 1462,
    'Hockinson': 1462,
    'Home': 1462,
    'Hoquiam': 1462,
    'Indianola': 1462,
    'Inglewood-Finn Hill': 1462,
    'Island County': 1462,
    'Issaquah': 1462,
    'Jefferson County': 1462,
    'Joint Base Lewis McChord': 1462,
    'Kalama': 1462,
    'Kelso': 1462,
    'Kenmore': 1462,
    'Kennewick': 1462,
    'Kent': 1462,
    'Kettle Falls': 1462,
    'Key Center': 1462,
    'King County': 1462,
    'Kingsgate': 1462,
    'Kingston': 1462,
    'Kirkland': 1462,
    'Kitsap County': 1462,
    'Kittitas': 1462,
    'Kittitas County': 1462,
    'Klahanie': 1462,
    'Klickitat County': 1462,
    'La Center': 1462,
    'Lacey': 1462,
    'Lake Forest Park': 1462,
    'Lake Marcel-Stillwater': 1462,
    'Lake Morton-Berrydale': 1462,
    'Lake Shore': 1462,
    'Lake Stevens': 1462,
    'Lake Stickney': 1462,
    'Lakeland North': 1462,
    'Lakeland South': 1462,
    'Lakewood': 1462,
    'Langley': 1462,
    'Larch Way': 1462,
    'Lea Hill': 1462,
    'Leavenworth': 1462,
    'Lewis County': 1462,
    'Lewisville': 1462,
    'Liberty Lake': 1462,
    'Lincoln County': 1462,
    'Lochsloy': 1462,
    'Lofall': 1462,
    'Long Beach': 1462,
    'Longbranch': 1462,
    'Longview': 1462,
    'Longview Heights': 1462,
    'Lynden': 1462,
    'Lynnwood': 1462,
    'Mabton': 1462,
    'Machias': 1462,
    'Maltby': 1462,
    'Manchester': 1462,
    'Manson': 1462,
    'Maple Heights-Lake Desire': 1462,
    'Maple Valley': 1462,
    'Maplewood': 1462,
    'Marietta': 1462,
    'Marietta-Alderwood': 1462,
    'Martha Lake': 1462,
    'Marysville': 1462,
    'Mason County': 1462,
    'Mattawa': 1462,
    'McChord Air Force Base': 1462,
    'McCleary': 1462,
    'McMillin': 1462,
    'Mead': 1462,
    'Meadow Glade': 1462,
    'Meadowdale': 1462,
    'Medical Lake': 1462,
    'Medina': 1462,
    'Mercer Island': 1462,
    'Midland': 1462,
    'Mill Creek': 1462,
    'Mill Creek East': 1462,
    'Mill Plain': 1462,
    'Millwood': 1462,
    'Milton': 1462,
    'Minnehaha': 1462,
    'Mirrormont': 1462,
    'Monroe': 1462,
    'Monroe North': 1462,
    'Montesano': 1462,
    'Morton': 1462,
    'Moses Lake': 1462,
    'Moses Lake North': 1462,
    'Mount Vernon': 1462,
    'Mount Vista': 1462,
    'Mountlake Terrace': 1462,
    'Mukilteo': 1462,
    'Napavine': 1462,
    'Navy Yard City': 1462,
    'Newcastle': 1462,
    'Newport': 1462,
    'Nooksack': 1462,
    'Normandy Park': 1462,
    'North Bend': 1462,
    'North Creek': 1462,
    'North Fort Lewis': 1462,
    'North Puyallup': 1462,
    'North Yelm': 1462,
    'Oak Harbor': 1462,
    'Ocean Park': 1462,
    'Ocean Shores': 1462,
    'Okanogan': 1462,
    'Okanogan County': 1462,
    'Olympia': 1462,
    'Omak': 1462,
    'Opportunity': 1462,
    'Orchards': 1462,
    'Oroville': 1462,
    'Orting': 1462,
    'Othello': 1462,
    'Otis Orchards-East Farms': 1462,
    'Pacific': 1462,
    'Pacific County': 1462,
    'Palouse': 1462,
    'Parkland': 1462,
    'Parkwood': 1462,
    'Pasco': 1462,
    'Peaceful Valley': 1462,
    'Pend Oreille County': 1462,
    'Picnic Point': 1462,
    'Picnic Point-North Lynnwood': 1462,
    'Pierce County': 1462,
    'Point Roberts': 1462,
    'Pomeroy': 1462,
    'Port Angeles': 1462,
    'Port Angeles East': 1462,
    'Port Hadlock-Irondale': 1462,
    'Port Ludlow': 1462,
    'Port Orchard': 1462,
    'Port Townsend': 1462,
    'Poulsbo': 1462,
    'Prairie Heights': 1462,
    'Prairie Ridge': 1462,
    'Prosser': 1462,
    'Pullman': 1462,
    'Purdy': 1462,
    'Puyallup': 1462,
    'Quincy': 1462,
    'Rainier': 1462,
    'Ravensdale': 1462,
    'Raymond': 1462,
    'Redmond': 1462,
    'Renton': 1462,
    'Republic': 1462,
    'Richland': 1462,
    'Ridgefield': 1462,
    'Ritzville': 1462,
    'Riverbend': 1462,
    'Riverton': 1462,
    'Rochester': 1462,
    'Rocky Point': 1462,
    'Rosedale': 1462,
    'Royal City': 1462,
    'Salmon Creek': 1462,
    'Sammamish': 1462,
    'San Juan County': 1462,
    'Seabeck': 1462,
    'SeaTac': 1462,
    'Seattle': 1462,
    'Sedro-Woolley': 1462,
    'Selah': 1462,
    'Sequim': 1462,
    'Shelton': 1462,
    'Shoreline': 1462,
    'Silver Firs': 1462,
    'Silverdale': 1462,
    'Sisco Heights': 1462,
    'Skagit County': 1462,
    'Skamania County': 1462,
    'Smokey Point': 1462,
    'Snohomish': 1462,
    'Snohomish County': 1462,
    'Snoqualmie': 1462,
    'Soap Lake': 1462,
    'South Bend': 1462,
    'South Hill': 1462,
    'South Wenatchee': 1462,
    'Southworth': 1462,
    'Spanaway': 1462,
    'Spokane': 1462,
    'Spokane County': 1462,
    'Spokane Valley': 1462,
    'Stanwood': 1462,
    'Steilacoom': 1462,
    'Stevens County': 1462,
    'Stevenson': 1462,
    'Sudden Valley': 1462,
    'Sultan': 1462,
    'Sumas': 1462,
    'Summit': 1462,
    'Summit View': 1462,
    'Sumner': 1462,
    'Sunnyside': 1462,
    'Sunnyslope': 1462,
    'Suquamish': 1462,
    'Tacoma': 1462,
    'Tanglewilde': 1462,
    'Tanglewilde-Thompson Place': 1462,
    'Tanner': 1462,
    'Tenino': 1462,
    'Terrace Heights': 1462,
    'Three Lakes': 1462,
    'Thurston County': 1462,
    'Tieton': 1462,
    'Tonasket': 1462,
    'Toppenish': 1462,
    'Town and Country': 1462,
    'Tracyton': 1462,
    'Trentwood': 1462,
    'Tukwila': 1462,
    'Tulalip': 1462,
    'Tulalip Bay': 1462,
    'Tumwater': 1462,
    'Union Gap': 1462,
    'Union Hill-Novelty Hill': 1462,
    'University Place': 1462,
    'Vancouver': 1462,
    'Vashon': 1462,
    'Venersborg': 1462,
    'Veradale': 1462,
    'Wahkiakum County': 1462,
    'Waitsburg': 1462,
    'Walla Walla': 1462,
    'Walla Walla County': 1462,
    'Walla Walla East': 1462,
    'Waller': 1462,
    'Walnut Grove': 1462,
    'Wapato': 1462,
    'Warden': 1462,
    'Warm Beach': 1462,
    'Washougal': 1462,
    'Waterville': 1462,
    'Wauna': 1462,
    'Wenatchee': 1462,
    'West Clarkston-Highland': 1462,
    'West Lake Sammamish': 1462,
    'West Lake Stevens': 1462,
    'West Longview': 1462,
    'West Pasco': 1462,
    'West Richland': 1462,
    'West Side Highway': 1462,
    'West Valley': 1462,
    'West Wenatchee': 1462,
    'Westport': 1462,
    'Whatcom County': 1462,
    'White Center': 1462,
    'White Salmon': 1462,
    'Whitman County': 1462,
    'Wilderness Rim': 1462,
    'Winlock': 1462,
    'Wollochet': 1462,
    'Woodinville': 1462,
    'Woodland': 1462,
    'Woods Creek': 1462,
    'Woodway': 1462,
    'Yacolt': 1462,
    'Yakima': 1462,
    'Yakima County': 1462,
    'Yarrow Point': 1462,
    'Yelm': 1462,
    'Zillah': 1462,
    'Alderson': 1429,
    'Alum Creek': 1429,
    'Ansted': 1429,
    'Barbour County': 1429,
    'Barboursville': 1429,
    'Barrackville': 1429,
    'Beaver': 1429,
    'Beckley': 1429,
    'Belington': 1429,
    'Belle': 1429,
    'Benwood': 1429,
    'Berkeley County': 1429,
    'Berkeley Springs': 1429,
    'Bethany': 1429,
    'Bethlehem': 1429,
    'Blennerhassett': 1429,
    'Bluefield': 1429,
    'Bluewell': 1429,
    'Boaz': 1429,
    'Bolivar': 1429,
    'Boone County': 1429,
    'Bradley': 1429,
    'Braxton County': 1429,
    'Bridgeport': 1429,
    'Brooke County': 1429,
    'Brookhaven': 1429,
    'Brush Fork': 1429,
    'Buckhannon': 1429,
    'Buffalo': 1429,
    'Cabell County': 1429,
    'Calhoun County': 1429,
    'Ceredo': 1429,
    'Chapmanville': 1429,
    'Charles Town': 1429,
    'Charleston': 1429,
    'Cheat Lake': 1429,
    'Chesapeake': 1429,
    'Chester': 1429,
    'Clarksburg': 1429,
    'Clay': 1429,
    'Clay County': 1429,
    'Clendenin': 1429,
    'Coal City': 1429,
    'Coal Fork': 1429,
    'Crab Orchard': 1429,
    'Craigsville': 1429,
    'Cross Lanes': 1429,
    'Culloden': 1429,
    'Daniels': 1429,
    'Despard': 1429,
    'Doddridge County': 1429,
    'Dunbar': 1429,
    'Eleanor': 1429,
    'Elizabeth': 1429,
    'Elkins': 1429,
    'Elkview': 1429,
    'Fairlea': 1429,
    'Fairmont': 1429,
    'Fayette County': 1429,
    'Fayetteville': 1429,
    'Follansbee': 1429,
    'Fort Ashby': 1429,
    'Franklin': 1429,
    'Gilbert Creek': 1429,
    'Gilmer County': 1429,
    'Glendale': 1429,
    'Glenville': 1429,
    'Grafton': 1429,
    'Grant County': 1429,
    'Grantsville': 1429,
    'Granville': 1429,
    'Greenbrier County': 1429,
    'Hamlin': 1429,
    'Hampshire County': 1429,
    'Hancock County': 1429,
    'Hardy County': 1429,
    'Harrison County': 1429,
    'Harrisville': 1429,
    'Hinton': 1429,
    'Hooverson Heights': 1429,
    'Huntington': 1429,
    'Hurricane': 1429,
    'Inwood': 1429,
    'Jackson County': 1429,
    'Jefferson County': 1429,
    'Kanawha County': 1429,
    'Kenova': 1429,
    'Keyser': 1429,
    'Kingwood': 1429,
    'Lavalette': 1429,
    'Lesage': 1429,
    'Lewis County': 1429,
    'Lewisburg': 1429,
    'Lincoln County': 1429,
    'Logan': 1429,
    'Logan County': 1429,
    'Lubeck': 1429,
    'Mabscott': 1429,
    'MacArthur': 1429,
    'Madison': 1429,
    'Mallory': 1429,
    'Mannington': 1429,
    'Marion County': 1429,
    'Marlinton': 1429,
    'Marmet': 1429,
    'Marshall County': 1429,
    'Martinsburg': 1429,
    'Mason County': 1429,
    'McDowell County': 1429,
    'McMechen': 1429,
    'Mercer County': 1429,
    'Middlebourne': 1429,
    'Milton': 1429,
    'Mineral County': 1429,
    'Mineral Wells': 1429,
    'Mingo County': 1429,
    'Monongah': 1429,
    'Monongalia County': 1429,
    'Monroe County': 1429,
    'Montgomery': 1429,
    'Moorefield': 1429,
    'Morgan County': 1429,
    'Morgantown': 1429,
    'Moundsville': 1429,
    'Mount Gay-Shamrock': 1429,
    'Mount Hope': 1429,
    'Mullens': 1429,
    'New Cumberland': 1429,
    'New Haven': 1429,
    'New Martinsville': 1429,
    'Newell': 1429,
    'Nicholas County': 1429,
    'Nitro': 1429,
    'Nutter Fort': 1429,
    'Oak Hill': 1429,
    'Oceana': 1429,
    'Ohio County': 1429,
    'Paden City': 1429,
    'Parkersburg': 1429,
    'Parsons': 1429,
    'Pea Ridge': 1429,
    'Pendleton County': 1429,
    'Pennsboro': 1429,
    'Petersburg': 1429,
    'Philippi': 1429,
    'Pinch': 1429,
    'Pineville': 1429,
    'Pleasant Valley': 1429,
    'Pleasants County': 1429,
    'Pocahontas County': 1429,
    'Point Pleasant': 1429,
    'Preston County': 1429,
    'Princeton': 1429,
    'Prosperity': 1429,
    'Putnam County': 1429,
    'Rainelle': 1429,
    'Raleigh County': 1429,
    'Rand': 1429,
    'Randolph County': 1429,
    'Ranson': 1429,
    'Ravenswood': 1429,
    'Richwood': 1429,
    'Ripley': 1429,
    'Ritchie County': 1429,
    'Roane County': 1429,
    'Romney': 1429,
    'Ronceverte': 1429,
    'Saint Albans': 1429,
    'Saint Marys': 1429,
    'Salem': 1429,
    'Shady Spring': 1429,
    'Shannondale': 1429,
    'Shepherdstown': 1429,
    'Shinnston': 1429,
    'Sissonville': 1429,
    'Sistersville': 1429,
    'Sophia': 1429,
    'South Charleston': 1429,
    'Spencer': 1429,
    'Stanaford': 1429,
    'Star City': 1429,
    'Stonewood': 1429,
    'Summers County': 1429,
    'Summersville': 1429,
    'Sutton': 1429,
    'Taylor County': 1429,
    'Teays Valley': 1429,
    'Terra Alta': 1429,
    'Tornado': 1429,
    'Tucker County': 1429,
    'Tyler County': 1429,
    'Union': 1429,
    'Upshur County': 1429,
    'Vienna': 1429,
    'Washington': 1429,
    'Wayne': 1429,
    'Wayne County': 1429,
    'Webster County': 1429,
    'Webster Springs': 1429,
    'Weirton': 1429,
    'Weirton Heights': 1429,
    'Welch': 1429,
    'Wellsburg': 1429,
    'West Liberty': 1429,
    'West Union': 1429,
    'Weston': 1429,
    'Westover': 1429,
    'Wetzel County': 1429,
    'Wheeling': 1429,
    'White Sulphur Springs': 1429,
    'Wiley Ford': 1429,
    'Williamson': 1429,
    'Williamstown': 1429,
    'Winfield': 1429,
    'Wirt County': 1429,
    'Wood County': 1429,
    'Wyoming County': 1429,
    'Abbotsford': 1441,
    'Adams': 1441,
    'Adams County': 1441,
    'Addison': 1441,
    'Albany': 1441,
    'Algoma': 1441,
    'Allouez': 1441,
    'Alma': 1441,
    'Alto': 1441,
    'Altoona': 1441,
    'Amery': 1441,
    'Amherst': 1441,
    'Antigo': 1441,
    'Appleton': 1441,
    'Arcadia': 1441,
    'Ashford': 1441,
    'Ashland': 1441,
    'Ashland County': 1441,
    'Ashwaubenon': 1441,
    'Athens': 1441,
    'Augusta': 1441,
    'Aztalan': 1441,
    'Baldwin': 1441,
    'Balsam Lake': 1441,
    'Bangor': 1441,
    'Baraboo': 1441,
    'Barneveld': 1441,
    'Barron': 1441,
    'Barron County': 1441,
    'Barton': 1441,
    'Bayfield County': 1441,
    'Bayside': 1441,
    'Beaver Dam': 1441,
    'Belgium': 1441,
    'Belle Plaine': 1441,
    'Belleville': 1441,
    'Bellevue': 1441,
    'Beloit': 1441,
    'Berlin': 1441,
    'Bevent': 1441,
    'Big Bend': 1441,
    'Black Creek': 1441,
    'Black Earth': 1441,
    'Black River Falls': 1441,
    'Blair': 1441,
    'Bloomer': 1441,
    'Bohners Lake': 1441,
    'Bonduel': 1441,
    'Boscobel': 1441,
    'Boyceville': 1441,
    'Brice Prairie': 1441,
    'Brillion': 1441,
    'Bristol': 1441,
    'Brodhead': 1441,
    'Brookfield': 1441,
    'Brooklyn': 1441,
    'Brothertown': 1441,
    'Brown County': 1441,
    'Brown Deer': 1441,
    'Browns Lake': 1441,
    'Brussels': 1441,
    'Buffalo County': 1441,
    'Burlington': 1441,
    'Burnett County': 1441,
    'Butler': 1441,
    'Cadott': 1441,
    'Caledonia': 1441,
    'Calumet County': 1441,
    'Cambridge': 1441,
    'Cameron': 1441,
    'Camp Lake': 1441,
    'Campbellsport': 1441,
    'Cashton': 1441,
    'Cato': 1441,
    'Cedar Grove': 1441,
    'Cedarburg': 1441,
    'Chetek': 1441,
    'Chilton': 1441,
    'Chippewa County': 1441,
    'Chippewa Falls': 1441,
    'Clark County': 1441,
    'Clear Lake': 1441,
    'Cleveland': 1441,
    'Clinton': 1441,
    'Clintonville': 1441,
    'Colby': 1441,
    'Colfax': 1441,
    'Columbia County': 1441,
    'Columbus': 1441,
    'Combined Locks': 1441,
    'Como': 1441,
    'Concord': 1441,
    'Cooperstown': 1441,
    'Cornell': 1441,
    'Cottage Grove': 1441,
    'Crandon': 1441,
    'Crawford County': 1441,
    'Cross Plains': 1441,
    'Cuba City': 1441,
    'Cudahy': 1441,
    'Cumberland': 1441,
    'Dakota': 1441,
    'Dane': 1441,
    'Dane County': 1441,
    'Darien': 1441,
    'Darlington': 1441,
    'De Forest': 1441,
    'De Pere': 1441,
    'Decatur': 1441,
    'Deerfield': 1441,
    'Delafield': 1441,
    'Delavan': 1441,
    'Delavan Lake': 1441,
    'Denmark': 1441,
    'Dickeyville': 1441,
    'Dodge County': 1441,
    'Dodgeville': 1441,
    'Door County': 1441,
    'Douglas County': 1441,
    'Dousman': 1441,
    'Dunn County': 1441,
    'Durand': 1441,
    'Eagle': 1441,
    'Eagle Lake': 1441,
    'Eagle River': 1441,
    'East Troy': 1441,
    'Easton': 1441,
    'Eau Claire': 1441,
    'Eau Claire County': 1441,
    'Edgar': 1441,
    'Edgerton': 1441,
    'Eldorado': 1441,
    'Elkhorn': 1441,
    'Ellsworth': 1441,
    'Elm Grove': 1441,
    'Elroy': 1441,
    'Evansville': 1441,
    'Evergreen': 1441,
    'Fall Creek': 1441,
    'Fall River': 1441,
    'Fennimore': 1441,
    'Fitchburg': 1441,
    'Florence': 1441,
    'Florence County': 1441,
    'Fond du Lac': 1441,
    'Fond du Lac County': 1441,
    'Fontana': 1441,
    'Forest County': 1441,
    'Fort Atkinson': 1441,
    'Fox Lake': 1441,
    'Fox Point': 1441,
    'Franklin': 1441,
    'Franksville': 1441,
    'Frederic': 1441,
    'Fredonia': 1441,
    'French Island': 1441,
    'Friendship': 1441,
    'Galesville': 1441,
    'Genoa City': 1441,
    'Germantown': 1441,
    'Gillett': 1441,
    'Glendale': 1441,
    'Glenmore': 1441,
    'Glenwood City': 1441,
    'Grafton': 1441,
    'Grant County': 1441,
    'Grantsburg': 1441,
    'Green Bay': 1441,
    'Green County': 1441,
    'Green Lake': 1441,
    'Green Lake County': 1441,
    'Greendale': 1441,
    'Greenfield': 1441,
    'Greenwood': 1441,
    'Hales Corners': 1441,
    'Hammond': 1441,
    'Harrison': 1441,
    'Hartford': 1441,
    'Hartland': 1441,
    'Hayward': 1441,
    'Hazel Green': 1441,
    'Hilbert': 1441,
    'Hillsboro': 1441,
    'Hobart': 1441,
    'Holmen': 1441,
    'Horicon': 1441,
    'Hortonville': 1441,
    'Howard': 1441,
    'Howards Grove': 1441,
    'Hudson': 1441,
    'Hurley': 1441,
    'Hustisford': 1441,
    'Independence': 1441,
    'Iola': 1441,
    'Iowa County': 1441,
    'Iron County': 1441,
    'Ixonia': 1441,
    'Jackson': 1441,
    'Jackson County': 1441,
    'Janesville': 1441,
    'Jefferson': 1441,
    'Jefferson County': 1441,
    'Johnson Creek': 1441,
    'Juneau': 1441,
    'Juneau County': 1441,
    'Kaukauna': 1441,
    'Kenosha': 1441,
    'Kenosha County': 1441,
    'Keshena': 1441,
    'Kewaskum': 1441,
    'Kewaunee': 1441,
    'Kewaunee County': 1441,
    'Kiel': 1441,
    'Kimberly': 1441,
    'King': 1441,
    'Kohler': 1441,
    'Kronenwetter': 1441,
    'La Crosse': 1441,
    'La Crosse County': 1441,
    'Lac du Flambeau': 1441,
    'Ladysmith': 1441,
    'Lafayette County': 1441,
    'Lake Delton': 1441,
    'Lake Geneva': 1441,
    'Lake Hallie': 1441,
    'Lake Koshkonong': 1441,
    'Lake Mills': 1441,
    'Lake Nebagamon': 1441,
    'Lake Ripley': 1441,
    'Lake Wazeecha': 1441,
    'Lake Wisconsin': 1441,
    'Lake Wissota': 1441,
    'Lamartine': 1441,
    'Lancaster': 1441,
    'Langlade County': 1441,
    'Lannon': 1441,
    'Legend Lake': 1441,
    'Lincoln County': 1441,
    'Little Chute': 1441,
    'Little Round Lake': 1441,
    'Lodi': 1441,
    'Lomira': 1441,
    'Loyal': 1441,
    'Luck': 1441,
    'Luxemburg': 1441,
    'Madison': 1441,
    'Maine': 1441,
    'Manawa': 1441,
    'Manchester': 1441,
    'Manitowoc': 1441,
    'Manitowoc County': 1441,
    'Maple Bluff': 1441,
    'Marathon': 1441,
    'Marathon County': 1441,
    'Marinette': 1441,
    'Marinette County': 1441,
    'Marion': 1441,
    'Markesan': 1441,
    'Marquette County': 1441,
    'Marshall': 1441,
    'Marshfield': 1441,
    'Mauston': 1441,
    'Mayville': 1441,
    'Mazomanie': 1441,
    'McFarland': 1441,
    'Medford': 1441,
    'Menasha': 1441,
    'Menominee County': 1441,
    'Menomonee Falls': 1441,
    'Menomonie': 1441,
    'Mequon': 1441,
    'Merrill': 1441,
    'Merton': 1441,
    'Middleton': 1441,
    'Milford': 1441,
    'Milton': 1441,
    'Milwaukee': 1441,
    'Milwaukee County': 1441,
    'Mineral Point': 1441,
    'Mishicot': 1441,
    'Mondovi': 1441,
    'Monona': 1441,
    'Monroe': 1441,
    'Monroe County': 1441,
    'Montello': 1441,
    'Monticello': 1441,
    'Mosinee': 1441,
    'Mount Horeb': 1441,
    'Mount Morris': 1441,
    'Mount Pleasant': 1441,
    'Mukwonago': 1441,
    'Muscoda': 1441,
    'Muskego': 1441,
    'Nashotah': 1441,
    'Nashville': 1441,
    'Neenah': 1441,
    'Neillsville': 1441,
    'Nekoosa': 1441,
    'New Berlin': 1441,
    'New Glarus': 1441,
    'New Holstein': 1441,
    'New Lisbon': 1441,
    'New London': 1441,
    'New Richmond': 1441,
    'Newburg': 1441,
    'Niagara': 1441,
    'North Fond du Lac': 1441,
    'North Hudson': 1441,
    'North La Crosse': 1441,
    'North Prairie': 1441,
    'Oak Creek': 1441,
    'Oakfield': 1441,
    'Oconomowoc': 1441,
    'Oconto': 1441,
    'Oconto County': 1441,
    'Oconto Falls': 1441,
    'Okauchee Lake': 1441,
    'Omro': 1441,
    'Onalaska': 1441,
    'Oneida': 1441,
    'Oneida County': 1441,
    'Oostburg': 1441,
    'Oregon': 1441,
    'Orfordville': 1441,
    'Osceola': 1441,
    'Oshkosh': 1441,
    'Osseo': 1441,
    'Outagamie County': 1441,
    'Ozaukee County': 1441,
    'Paddock Lake': 1441,
    'Palmyra': 1441,
    'Pardeeville': 1441,
    'Park Falls': 1441,
    'Pell Lake': 1441,
    'Pepin County': 1441,
    'Peshtigo': 1441,
    'Pewaukee': 1441,
    'Phillips': 1441,
    'Pierce County': 1441,
    'Pittsfield': 1441,
    'Platteville': 1441,
    'Pleasant Prairie': 1441,
    'Plover': 1441,
    'Plymouth': 1441,
    'Polk County': 1441,
    'Port Edwards': 1441,
    'Port Washington': 1441,
    'Portage': 1441,
    'Portage County': 1441,
    'Portland': 1441,
    'Potter Lake': 1441,
    'Powers Lake': 1441,
    'Poynette': 1441,
    'Prairie du Chien': 1441,
    'Prairie du Sac': 1441,
    'Prescott': 1441,
    'Price County': 1441,
    'Princeton': 1441,
    'Pulaski': 1441,
    'Racine': 1441,
    'Racine County': 1441,
    'Randolph': 1441,
    'Random Lake': 1441,
    'Redgranite': 1441,
    'Reedsburg': 1441,
    'Reedsville': 1441,
    'Rhinelander': 1441,
    'Rib Mountain': 1441,
    'Rice Lake': 1441,
    'Richfield': 1441,
    'Richland Center': 1441,
    'Richland County': 1441,
    'Richmond': 1441,
    'Rio': 1441,
    'Ripon': 1441,
    'River Falls': 1441,
    'River Hills': 1441,
    'Roberts': 1441,
    'Rochester': 1441,
    'Rock County': 1441,
    'Rome': 1441,
    'Rosendale': 1441,
    'Rothschild': 1441,
    'Roxbury': 1441,
    'Rusk County': 1441,
    'Rutland': 1441,
    'Saint Croix County': 1441,
    'Saint Croix Falls': 1441,
    'Saint Francis': 1441,
    'Saint Peter': 1441,
    'Salem': 1441,
    'Sauk City': 1441,
    'Sauk County': 1441,
    'Saukville': 1441,
    'Sawyer County': 1441,
    'Schofield': 1441,
    'Seymour': 1441,
    'Sharon': 1441,
    'Shawano': 1441,
    'Shawano County': 1441,
    'Sheboygan': 1441,
    'Sheboygan County': 1441,
    'Sheboygan Falls': 1441,
    'Shell Lake': 1441,
    'Sherwood': 1441,
    'Shorewood': 1441,
    'Shorewood Hills': 1441,
    'Shullsburg': 1441,
    'Silver Lake': 1441,
    'Siren': 1441,
    'Slinger': 1441,
    'Somers': 1441,
    'Somerset': 1441,
    'South Milwaukee': 1441,
    'Sparta': 1441,
    'Spencer': 1441,
    'Spooner': 1441,
    'Spring Green': 1441,
    'Spring Valley': 1441,
    'Stanley': 1441,
    'Stevens Point': 1441,
    'Stoughton': 1441,
    'Stratford': 1441,
    'Strum': 1441,
    'Sturgeon Bay': 1441,
    'Sturtevant': 1441,
    'Suamico': 1441,
    'Sun Prairie': 1441,
    'Superior': 1441,
    'Sussex': 1441,
    'Tainter Lake': 1441,
    'Taylor County': 1441,
    'Theresa': 1441,
    'Thiensville': 1441,
    'Thorp': 1441,
    'Tichigan': 1441,
    'Tomah': 1441,
    'Tomahawk': 1441,
    'Trempealeau': 1441,
    'Trempealeau County': 1441,
    'Turtle Lake': 1441,
    'Twin Lakes': 1441,
    'Two Rivers': 1441,
    'Union Grove': 1441,
    'Vernon County': 1441,
    'Verona': 1441,
    'Vilas County': 1441,
    'Viroqua': 1441,
    'Wales': 1441,
    'Walworth': 1441,
    'Walworth County': 1441,
    'Washburn': 1441,
    'Washburn County': 1441,
    'Washington County': 1441,
    'Waterford': 1441,
    'Waterloo': 1441,
    'Watertown': 1441,
    'Waukesha': 1441,
    'Waukesha County': 1441,
    'Waunakee': 1441,
    'Waupaca': 1441,
    'Waupaca County': 1441,
    'Waupun': 1441,
    'Wausau': 1441,
    'Waushara County': 1441,
    'Wautoma': 1441,
    'Wauwatosa': 1441,
    'West Allis': 1441,
    'West Baraboo': 1441,
    'West Bend': 1441,
    'West Milwaukee': 1441,
    'West Salem': 1441,
    'Westby': 1441,
    'Westfield': 1441,
    'Weston': 1441,
    'Weyauwega': 1441,
    'Whitefish Bay': 1441,
    'Whitehall': 1441,
    'Whitewater': 1441,
    'Whiting': 1441,
    'Williams Bay': 1441,
    'Wind Lake': 1441,
    'Wind Point': 1441,
    'Windsor': 1441,
    'Winnebago County': 1441,
    'Winneconne': 1441,
    'Wisconsin Dells': 1441,
    'Wisconsin Rapids': 1441,
    'Wittenberg': 1441,
    'Wood County': 1441,
    'Woodville': 1441,
    'Wrightstown': 1441,
    'Afton': 1442,
    'Albany County': 1442,
    'Antelope Valley-Crestview': 1442,
    'Arapahoe': 1442,
    'Bar Nunn': 1442,
    'Basin': 1442,
    'Big Horn County': 1442,
    'Buffalo': 1442,
    'Campbell County': 1442,
    'Carbon County': 1442,
    'Casper': 1442,
    'Cheyenne': 1442,
    'Cody': 1442,
    'Converse County': 1442,
    'Crook County': 1442,
    'Douglas': 1442,
    'Ethete': 1442,
    'Evanston': 1442,
    'Evansville': 1442,
    'Fort Washakie': 1442,
    'Fox Farm-College': 1442,
    'Fremont County': 1442,
    'Gillette': 1442,
    'Glenrock': 1442,
    'Goshen County': 1442,
    'Green River': 1442,
    'Greybull': 1442,
    'Guernsey': 1442,
    'Hoback': 1442,
    'Hot Springs County': 1442,
    'Jackson': 1442,
    'Johnson County': 1442,
    'Kemmerer': 1442,
    'Lander': 1442,
    'Laramie': 1442,
    'Laramie County': 1442,
    'Lincoln County': 1442,
    'Lovell': 1442,
    'Lusk': 1442,
    'Lyman': 1442,
    'Marbleton': 1442,
    'Mills': 1442,
    'Moorcroft': 1442,
    'Moose Wilson Road': 1442,
    'Mountain View': 1442,
    'Natrona County': 1442,
    'Newcastle': 1442,
    'Niobrara County': 1442,
    'North Rock Springs': 1442,
    'Park County': 1442,
    'Pine Bluffs': 1442,
    'Pinedale': 1442,
    'Platte County': 1442,
    'Powell': 1442,
    'Rafter J Ranch': 1442,
    'Ranchettes': 1442,
    'Rawlins': 1442,
    'Riverton': 1442,
    'Rock Springs': 1442,
    'Saratoga': 1442,
    'Sheridan': 1442,
    'Sheridan County': 1442,
    'Sleepy Hollow': 1442,
    'South Greeley': 1442,
    'South Park': 1442,
    'Star Valley Ranch': 1442,
    'Sublette County': 1442,
    'Sundance': 1442,
    'Sweetwater County': 1442,
    'Teton County': 1442,
    'Thermopolis': 1442,
    'Torrington': 1442,
    'Uinta County': 1442,
    'Upton': 1442,
    'Washakie County': 1442,
    'Weston County': 1442,
    'Wheatland': 1442,
    'Wilson': 1442,
    'Worland': 1442,
    'Wright': 1442,
    'Artigas': 3205,
    'Baltasar Brum': 3205,
    'Bella Unión': 3205,
    'Las Piedras': 3205,
    'Tomás Gomensoro': 3205,
    'Aguas Corrientes': 3213,
    'Atlántida': 3213,
    'Barra de Carrasco': 3213,
    'Barros Blancos': 3213,
    'Canelones': 3213,
    'Colonia Nicolich': 3213,
    'Empalme Olmos': 3213,
    'Joaquín Suárez': 3213,
    'Juanicó': 3213,
    'La Floresta': 3213,
    'La Paz': 3213,
    'Las Piedras': 3213,
    'Las Toscas': 3213,
    'Los Cerrillos': 3213,
    'Migues': 3213,
    'Montes': 3213,
    'Pando': 3213,
    'Paso de Carrasco': 3213,
    'Progreso': 3213,
    'San Antonio': 3213,
    'San Bautista': 3213,
    'San Jacinto': 3213,
    'San Ramón': 3213,
    'Santa Lucía': 3213,
    'Santa Rosa': 3213,
    'Sauce': 3213,
    'Soca': 3213,
    'Tala': 3213,
    'Toledo': 3213,
    'Aceguá': 3211,
    'Isidoro Noblía': 3211,
    'Melo': 3211,
    'Río Branco': 3211,
    'Tupambaé': 3211,
    'Carmelo': 3208,
    'Colonia del Sacramento': 3208,
    'Florencio Sánchez': 3208,
    'Juan L. Lacaze': 3208,
    'Nueva Helvecia': 3208,
    'Nueva Palmira': 3208,
    'Ombúes de Lavalle': 3208,
    'Rosario': 3208,
    'Tarariras': 3208,
    'Blanquillo': 3209,
    'Carlos Reyles': 3209,
    'Durazno': 3209,
    'La Paloma': 3209,
    'Santa Bernardina': 3209,
    'Sarandí del Yi': 3209,
    'Villa del Carmen': 3209,
    'Trinidad': 3203,
    '25 de Agosto': 3217,
    '25 de Mayo': 3217,
    'Alejandro Gallinal': 3217,
    'Cardal': 3217,
    'Casupá': 3217,
    'Florida': 3217,
    'Sarandí Grande': 3217,
    'José Batlle y Ordóñez': 3215,
    'José Pedro Varela': 3215,
    'Mariscala': 3215,
    'Minas': 3215,
    'Solís de Mataojo': 3215,
    'Aiguá': 3206,
    'Maldonado': 3206,
    'Pan de Azúcar': 3206,
    'Piriápolis': 3206,
    'Punta del Este': 3206,
    'San Carlos': 3206,
    'Montevideo': 3218,
    'Pajas Blancas': 3218,
    'Santiago Vázquez': 3218,
    'Estación Porvenir': 3212,
    'Guichón': 3212,
    'Paysandú': 3212,
    'Piedras Coloradas': 3212,
    'Quebracho': 3212,
    'San Félix': 3212,
    'Fray Bentos': 3210,
    'Nuevo Berlín': 3210,
    'San Javier': 3210,
    'Young': 3210,
    'Minas de Corrales': 3207,
    'Rivera': 3207,
    'Tranqueras': 3207,
    'Vichadero': 3207,
    'Castillos': 3216,
    'Cebollatí': 3216,
    'Chui': 3216,
    'Dieciocho de Julio': 3216,
    'La Paloma': 3216,
    'Lascano': 3216,
    'Rocha': 3216,
    'Velázquez': 3216,
    'Belén': 3220,
    'Salto': 3220,
    'Villa Constitución': 3220,
    'Delta del Tigre': 3204,
    'Ecilda Paullier': 3204,
    'Libertad': 3204,
    'Puntas de Valdéz': 3204,
    'Rafael Perazza': 3204,
    'Rodríguez': 3204,
    'San José de Mayo': 3204,
    'Cardona': 3219,
    'Dolores': 3219,
    'José Enrique Rodó': 3219,
    'Mercedes': 3219,
    'Palmitas': 3219,
    'Santa Catalina': 3219,
    'Villa Soriano': 3219,
    'Curtina': 3221,
    'Paso de los Toros': 3221,
    'Tacuarembó': 3221,
    'Santa Clara de Olimar': 3214,
    'Treinta y Tres': 3214,
    'Vergara': 3214,
    'Villa Sara': 3214,
    'Andijon': 2540,
    'Andijon Tumani': 2540,
    'Asaka': 2540,
    'Asaka Tumani': 2540,
    'Baliqchi Tumani': 2540,
    'Bo‘z Tumani': 2540,
    'Buloqboshi Tumani': 2540,
    'Izboskan Tumani': 2540,
    'Jalolkuduk Tumani': 2540,
    'Khŭjaobod Tumani': 2540,
    'Marhamat': 2540,
    'Marhamat Tumani': 2540,
    'Oltinkŭl Tumani': 2540,
    'Pakhtaobod Tumani': 2540,
    'Paxtaobod': 2540,
    'Qo‘rg‘ontepa': 2540,
    'Qŭrghontepa Tumani': 2540,
    'Shahrikhon Tumani': 2540,
    'Shahrixon': 2540,
    'Ulug‘nor Tumani': 2540,
    'Xo‘jaobod': 2540,
    'Bukhara': 2541,
    'Galaosiyo': 2541,
    'Gazli': 2541,
    'Ghijduwon': 2541,
    'Karakul’': 2541,
    'Kogon': 2541,
    'Olot': 2541,
    'Peshku Tumani': 2541,
    'Qorako’l': 2541,
    'Qorovulbozor': 2541,
    'Romiton': 2541,
    'Shofirkon': 2541,
    'Wobkent': 2541,
    'Wobkent Tumani': 2541,
    'Beshariq': 2538,
    'Fergana': 2538,
    'Hamza': 2538,
    'Kirguli': 2538,
    'Marg‘ilon': 2538,
    'Oltiariq': 2538,
    'Qo‘qon': 2538,
    'Quva': 2538,
    'Quvasoy': 2538,
    'Rishton': 2538,
    'Shohimardon': 2538,
    'So‘x Tumani': 2538,
    'Toshloq': 2538,
    'Yangi Marg‘ilon': 2538,
    'Yaypan': 2538,
    'Dashtobod': 2545,
    'Dŭstlik': 2545,
    'Gagarin': 2545,
    'Jizzax': 2545,
    'Paxtakor': 2545,
    'Zomin': 2545,
    'Beruniy': 2548,
    'Kegeyli Shahar': 2548,
    'Khŭjayli': 2548,
    'Manghit': 2548,
    'Mŭynoq': 2548,
    'Novyy Turtkul’': 2548,
    'Nukus': 2548,
    'Oltinko‘l': 2548,
    'Chortoq': 2537,
    'Chust': 2537,
    'Haqqulobod': 2537,
    'Kosonsoy': 2537,
    'Namangan': 2537,
    'Pop': 2537,
    'To‘rqao‘rg‘on': 2537,
    'Toshbuloq': 2537,
    'Uchqŭrghon Shahri': 2537,
    'Uychi': 2537,
    'Yangiqo‘rg‘on': 2537,
    'Navoiy': 2542,
    'Nurota': 2542,
    'Qiziltepa': 2542,
    'Yangirabot': 2542,
    'Beshkent': 2543,
    'Chiroqchi': 2543,
    'G‘uzor': 2543,
    'Kitob': 2543,
    'Koson': 2543,
    'Muborak': 2543,
    'Nishon Tumani': 2543,
    'Qarshi': 2543,
    'Shahrisabz': 2543,
    'Bulung’ur': 2544,
    'Charxin': 2544,
    'Chelak': 2544,
    'Daxbet': 2544,
    'Ishtixon': 2544,
    'Jomboy': 2544,
    'Juma': 2544,
    'Kattaqo‘rg‘on': 2544,
    'Oqtosh': 2544,
    'Payshanba': 2544,
    'Samarkand': 2544,
    'Urgut': 2544,
    'Guliston': 2547,
    'Sirdaryo': 2547,
    'Yangiyer': 2547,
    'Boysun': 2546,
    'Denov': 2546,
    'Sho‘rchi': 2546,
    'Tirmiz': 2546,
    'Bektemir': 2536,
    'Tashkent': 2536,
    'Angren': 2549,
    'Bekobod': 2549,
    'Bo‘ka': 2549,
    'Chinoz': 2549,
    'Chirchiq': 2549,
    'G‘azalkent': 2549,
    'Iskandar': 2549,
    'Kyzyldzhar': 2549,
    'Ohangaron': 2549,
    'Olmaliq': 2549,
    'Parkent': 2549,
    'Piskent': 2549,
    'Qibray': 2549,
    'Salor': 2549,
    'Tŭytepa': 2549,
    'Ŭrtaowul': 2549,
    'Yangiobod': 2549,
    'Yangiyŭl': 2549,
    'Zafar': 2549,
    'Boghot Tumani': 2539,
    'Gurlan': 2539,
    'Hazorasp': 2539,
    'Khiwa': 2539,
    'Qŭshkŭpir': 2539,
    'Showot': 2539,
    'Urganch': 2539,
    'Lakatoro': 4775,
    'Norsup': 4775,
    'Luganville': 4776,
    'Port-Olry': 4776,
    'Port-Vila': 4774,
    'Isangel': 4777,
    'Sola': 4772,
    'Maroa': 2044,
    'Municipio Autónomo Alto Orinoco': 2044,
    'Puerto Ayacucho': 2044,
    'San Carlos de Río Negro': 2044,
    'San Fernando de Atabapo': 2044,
    'San Juan de Manapiare': 2044,
    'Anaco': 2050,
    'Aragua de Barcelona': 2050,
    'Barcelona': 2050,
    'Cantaura': 2050,
    'El Tigre': 2050,
    'Municipio José Gregorio Monagas': 2050,
    'Onoto': 2050,
    'Puerto La Cruz': 2050,
    'Puerto Píritu': 2050,
    'San José de Guanipa': 2050,
    'Cagua': 2047,
    'El Limón': 2047,
    'La Victoria': 2047,
    'Las Tejerías': 2047,
    'Maracay': 2047,
    'Palo Negro': 2047,
    'San Mateo': 2047,
    'Santa Rita': 2047,
    'Turmero': 2047,
    'Villa de Cura': 2047,
    'Alto Barinas': 2049,
    'Barinas': 2049,
    'Barinitas': 2049,
    'Municipio Barinas': 2049,
    'Ciudad Bolívar': 2039,
    'Ciudad Guayana': 2039,
    'Municipio Padre Pedro Chien': 2039,
    'Santa Elena de Uairén': 2039,
    'Upata': 2039,
    'Guacara': 2040,
    'Güigüe': 2040,
    'Los Guayos': 2040,
    'Mariara': 2040,
    'Morón': 2040,
    'Puerto Cabello': 2040,
    'San Joaquín': 2040,
    'Tacarigua': 2040,
    'Tocuyito': 2040,
    'Valencia': 2040,
    'San Carlos': 2034,
    'Tinaquillo': 2034,
    'Tucupita': 2051,
    'Chichiriviche': 2035,
    'Coro': 2035,
    'Municipio Los Taques': 2035,
    'Municipio Miranda': 2035,
    'Punta Cardón': 2035,
    'Punto Fijo': 2035,
    'Tucacas': 2035,
    'Altagracia de Orituco': 2045,
    'Calabozo': 2045,
    'San Juan de los Morros': 2045,
    'Valle de La Pascua': 2045,
    'Zaraza': 2045,
    'Barquisimeto': 2038,
    'Cabudare': 2038,
    'Carora': 2038,
    'El Tocuyo': 2038,
    'Los Rastrojos': 2038,
    'Quíbor': 2038,
    'Ejido': 2053,
    'El Vigía': 2053,
    'Mérida': 2053,
    'Mucumpiz': 2053,
    'Municipio Libertador': 2053,
    'Baruta': 2037,
    'Carrizal': 2037,
    'Caucaguita': 2037,
    'Caucagüito': 2037,
    'Chacao': 2037,
    'Charallave': 2037,
    'Cúa': 2037,
    'El Cafetal': 2037,
    'El Hatillo': 2037,
    'Guarenas': 2037,
    'Guatire': 2037,
    'La Dolorita': 2037,
    'Los Dos Caminos': 2037,
    'Los Teques': 2037,
    'Ocumare del Tuy': 2037,
    'Petare': 2037,
    'San Antonio de Los Altos': 2037,
    'Santa Teresa del Tuy': 2037,
    'Caripito': 2054,
    'Maturín': 2054,
    'Municipio Maturín': 2054,
    'Municipio Uracoa': 2054,
    'Juan Griego': 2052,
    'La Asunción': 2052,
    'Porlamar': 2052,
    'Acarigua': 2036,
    'Araure': 2036,
    'Guanare': 2036,
    'Villa Bruzual': 2036,
    'Carúpano': 2056,
    'Cumaná': 2056,
    'Güiria': 2056,
    'Municipio Valdez': 2056,
    'Colón': 2048,
    'La Fría': 2048,
    'La Grita': 2048,
    'Municipio José María Vargas': 2048,
    'Rubio': 2048,
    'San Antonio del Táchira': 2048,
    'San Cristóbal': 2048,
    'Táriba': 2048,
    'Boconó': 2043,
    'Municipio Pampanito': 2043,
    'Municipio San Rafael de Carvajal': 2043,
    'Trujillo': 2043,
    'Valera': 2043,
    'Caraballeda': 2055,
    'Catia La Mar': 2055,
    'La Guaira': 2055,
    'Maiquetía': 2055,
    'Chivacoa': 2041,
    'Municipio Independencia': 2041,
    'Nirgua': 2041,
    'San Felipe': 2041,
    'Yaritagua': 2041,
    'Cabimas': 2042,
    'Ciudad Ojeda': 2042,
    'La Villa del Rosario': 2042,
    'Lagunillas': 2042,
    'Machiques': 2042,
    'Maracaibo': 2042,
    'San Carlos del Zulia': 2042,
    'Santa Rita': 2042,
    'Cho Dok': 3794,
    'Huyện An Phú': 3794,
    'Huyện Châu Phú': 3794,
    'Huyện Chợ Mới': 3794,
    'Huyện Phú Tân': 3794,
    'Huyện Tri Tôn': 3794,
    'Long Xuyên': 3794,
    'Long Xuyên City': 3794,
    'Côn Sơn': 3770,
    'Huyện Châu Đức': 3770,
    'Huyện Đất Đỏ': 3770,
    'Huyện Tân Thành': 3770,
    'Huyện Xuyên Mộc': 3770,
    'Thành Phố Vũng Tàu': 3770,
    'Thị Xã Phú Mỹ': 3770,
    'Vũng Tàu': 3770,
    'Bắc Giang': 3815,
    'Huyện Lục Nam': 3815,
    'Huyện Lục Ngạn': 3815,
    'Huyện Yên Thế': 3815,
    'Bắc Kạn': 3822,
    'Huyện Ba Bể': 3822,
    'Huyện Bạch Thông': 3822,
    'Huyện Chợ Đồn': 3822,
    'Huyện Na Rì': 3822,
    'Huyện Ngân Sơn': 3822,
    'Thị Xã Bắc Kạn': 3822,
    'Bạc Liêu': 3804,
    'Huyện Giá Rai': 3804,
    'Huyện Hồng Dân': 3804,
    'Bắc Ninh': 3791,
    'Cung Kiệm': 3791,
    'Huyện Gia Bình': 3791,
    'Huyện Thuận Thành': 3791,
    'Huyện Tiên Du': 3791,
    'Huyện Yên Phong': 3791,
    'Ấp Tân Ngãi': 3796,
    'Bến Tre': 3796,
    'Huyện Ba Tri': 3796,
    'Huyện Bình Đại': 3796,
    'Huyện Châu Thành': 3796,
    'Huyện Chợ Lách': 3796,
    'Huyện Giồng Trôm': 3796,
    'Huyện Thạnh Phú': 3796,
    'Dĩ An': 3785,
    'Thủ Dầu Một': 3785,
    'Huyện An Lão': 3830,
    'Huyện Hoài Nhơn': 3830,
    'Huyện Phù Mỹ': 3830,
    'Huyện Tây Sơn': 3830,
    'Huyện Tuy Phước': 3830,
    'Huyện Vĩnh Thạnh': 3830,
    'Qui Nhon': 3830,
    'Bình Long': 3797,
    'Don Luan': 3797,
    'Huyện Bù Đốp': 3797,
    'Huyện Chơn Thành': 3797,
    'Huyện Đồng Phú': 3797,
    'Huyện Hớn Quản': 3797,
    'Thị Xã Phước Long': 3797,
    'Huyện Bắc Bình': 3787,
    'Huyện Hàm Tân': 3787,
    'Huyện Hàm Thuận Bắc': 3787,
    'Huyện Hàm Thuận Nam': 3787,
    'Huyện Tánh Linh': 3787,
    'Huyện Tuy Phong': 3787,
    'La Gi': 3787,
    'Phan Thiết': 3787,
    'Thành Phố Phan Thiết': 3787,
    'Cà Mau': 3778,
    'Huyện Cái Nước': 3778,
    'Huyện Đầm Dơi': 3778,
    'Huyen Nam Can': 3778,
    'Huyện Thới Bình': 3778,
    'Cần Thơ': 4925,
    'Cờ Đỏ': 4925,
    'Thới Lai': 4925,
    'Vĩnh Thạnh': 4925,
    'Cao Bằng': 3782,
    'Huyện Bảo Lac': 3782,
    'Huyện Bảo Lâm': 3782,
    'Huyện Hạ Lang': 3782,
    'Huyện Hà Quảng': 3782,
    'Huyện Nguyên Bình': 3782,
    'Huyen Phuc Hoa': 3782,
    'Huyện Thạch An': 3782,
    'Huyện Thông Nông': 3782,
    'Huyện Trà Lĩnh': 3782,
    'Huyện Trùng Khánh': 3782,
    'Da Nang': 3806,
    'Buôn Ma Thuột': 3829,
    'Huyện Buôn Đôn': 3829,
    'Huyện Ea H  Leo': 3829,
    'Huyện Ea Súp': 3829,
    'Huyện Cư Jút': 3823,
    'Huyện Đắk R’Lấp': 3823,
    'Huyện Krông Nô': 3823,
    'Dien Bien Phu': 3773,
    'Huyện Điện Biên Đông': 3773,
    'Huyện Mường Nhé': 3773,
    'Huyện Tủa Chùa': 3773,
    'Huyện Tuần Giáo': 3773,
    'Thị Xã Mưòng Lay': 3773,
    'Biên Hòa': 3821,
    'Huyện Định Quán': 3821,
    'Huyện Long Thành': 3821,
    'Huyện Tân Phú': 3821,
    'Huyện Thống Nhất': 3821,
    'Huyện Trảng Bom': 3821,
    'Huyện Vĩnh Cửu': 3821,
    'Cao Lãnh': 3769,
    'Huyện Châu Thành': 3769,
    'Huyện Lai Vung': 3769,
    'Huyện Lấp Vò': 3769,
    'Huyện Tam Nông': 3769,
    'Huyện Tân Hồng': 3769,
    'Huyện Thanh Bình': 3769,
    'Huyện Tháp Mười': 3769,
    'Sa Dec': 3769,
    'Sa Dec city': 3769,
    'Thị Trấn Tân Thành': 3769,
    'Huyện Chư Păh': 3813,
    'Huyện Chư Prông': 3813,
    'Huyện Chư Sê': 3813,
    'Huyện Đức Cơ': 3813,
    'Huyện Ia Grai': 3813,
    'Huyện KBang': 3813,
    'Huyện Kông Chro': 3813,
    'Huyện Krông Pa': 3813,
    'Huyện Mang Yang': 3813,
    'Pleiku': 3813,
    'Hà Giang': 3779,
    'Huyện Bắc Mê': 3779,
    'Huyện Bắc Quang': 3779,
    'Huyện Đồng Văn': 3779,
    'Huyện Quang Bình': 3779,
    'Huyện Vị Xuyên': 3779,
    'Huyện Xín Mần': 3779,
    'Huyện Yên Minh': 3779,
    'Huyện Bình Lục': 3802,
    'Huyện Duy Tiên': 3802,
    'Huyện Kim Bảng': 3802,
    'Huyện Lý Nhân': 3802,
    'Huyện Thanh Liêm': 3802,
    'Phủ Lý': 3802,
    'Hà Đông': 3810,
    'Hanoi': 3810,
    'Huyện Quốc Oai': 3810,
    'Quận Ba Đình': 3810,
    'Quận Hà Đông': 3810,
    'Sơn Tây': 3810,
    'Hà Tĩnh': 3816,
    'Huyện Cẩm Xuyên': 3816,
    'Huyện Can Lộc': 3816,
    'Huyện Ðức Thọ': 3816,
    'Huyện Kỳ Anh': 3816,
    'Huyện Nghi Xuân': 3816,
    'Huyện Thạch Hà': 3816,
    'Hải Dương': 3827,
    'Huyện Bình Giang': 3827,
    'Huyện Cẩm Giàng': 3827,
    'Huyện Gia Lộc': 3827,
    'Huyện Kinh Môn': 3827,
    'Huyện Nam Sách': 3827,
    'Huyện Thanh Miện': 3827,
    'Thị Xã Chí Linh': 3827,
    'Cát Bà': 3783,
    'Haiphong': 3783,
    'Huyện Châu Thành A': 3777,
    'Vị Thanh': 3777,
    'Cần Giờ': 3811,
    'Củ Chi': 3811,
    'Ho Chi Minh City': 3811,
    'Hòa Bình': 3799,
    'Huyện Cao Phong': 3799,
    'Huyện Kỳ Sơn': 3799,
    'Huyện Lạc Thủy': 3799,
    'Huyện Lương Sơn': 3799,
    'Huyện Tân Lạc': 3799,
    'Hưng Yên': 3768,
    'Huyện Ân Thi': 3768,
    'Huyện Khoái Châu': 3768,
    'Huyện Kim Động': 3768,
    'Huyện Mỹ Hào': 3768,
    'Huyện Phù Cừ': 3768,
    'Huyện Tiên Lữ': 3768,
    'Cam Ranh': 3793,
    'Huyện Diên Khánh': 3793,
    'Huyện Khánh Sơn': 3793,
    'Huyện Khánh Vĩnh': 3793,
    'Huyện Vạn Ninh': 3793,
    'Nha Trang': 3793,
    'Thành Phố Cam Ranh': 3793,
    'Thành Phố Nha Trang': 3793,
    'Thị Xã Ninh Hòa': 3793,
    'Dương Đông': 3800,
    'Hà Tiên': 3800,
    'Huyện An Biên': 3800,
    'Huyện An Minh': 3800,
    'Huyện Châu Thành': 3800,
    'Huyện Giồng Riềng': 3800,
    'Huyện Gò Quao': 3800,
    'Huyện Hòn Đất': 3800,
    'Huyện Kiên Hải': 3800,
    'Huyện Phú Quốc': 3800,
    'Huyện Tân Hiệp': 3800,
    'Huyện Vĩnh Thuận': 3800,
    'Kien Luong Town': 3800,
    'Rạch Giá': 3800,
    'Huyện Đắk Glei': 3772,
    'Huyện Đắk Hà': 3772,
    'Huyện Đắk Tô': 3772,
    'Huyện Ia H  Drai': 3772,
    'Huyện Kon Plông': 3772,
    'Huyện Ngọc Hồi': 3772,
    'Huyện Sa Thầy': 3772,
    'Kon Tum': 3772,
    'Huyện Mưòng Tè': 3825,
    'Huyện Tam Đường': 3825,
    'Huyện Than Uyên': 3825,
    'Bảo Lộc': 3818,
    'Đam Rong': 3818,
    'Đinh Văn': 3818,
    'Đưc Trọng': 3818,
    'Ðà Lạt': 3818,
    'Huyện Bảo Lâm': 3818,
    'Huyện Đạ Huoai': 3818,
    'Huyện Đạ Tẻh': 3818,
    'Huyện Đơn Dương': 3818,
    'Huyện Đức Trọng': 3818,
    'Huyện Lâm Hà': 3818,
    'Huyện Bắc Sơn': 3792,
    'Huyện Bình Gia': 3792,
    'Huyện Cao Lộc': 3792,
    'Huyện Chi Lăng': 3792,
    'Huyện Đình Lập': 3792,
    'Huyện Hũu Lũng': 3792,
    'Huyện Vặn Quan': 3792,
    'Lạng Sơn': 3792,
    'Huyện Bắc Hà': 3817,
    'Huyện Bảo Yên': 3817,
    'Huyện Bát Xát': 3817,
    'Huyện Mường Khương': 3817,
    'Huyện Sa Pa': 3817,
    'Huyện Si Ma Cai': 3817,
    'Huyện Văn Bàn': 3817,
    'Lào Cai': 3817,
    'Lao Chải': 3817,
    'Sa Pa': 3817,
    'Cần Giuộc': 3808,
    'Huyện Bến Lức': 3808,
    'Huyện Cần Đước': 3808,
    'Huyện Cần Giuộc': 3808,
    'Huyện Châu Thành': 3808,
    'Huyện Đức Hòa': 3808,
    'Huyện Đức Huệ': 3808,
    'Huyện Mộc Hóa': 3808,
    'Huyện Tân Hưng': 3808,
    'Huyện Tân Thạnh': 3808,
    'Huyện Tân Trụ': 3808,
    'Huyện Thạnh Hóa': 3808,
    'Huyện Thủ Thừa': 3808,
    'Huyện Vĩnh Hưng': 3808,
    'Tân An': 3808,
    'Huyện Giao Thủy': 3789,
    'Huyện Hải Hậu': 3789,
    'Huyện Mỹ Lộc': 3789,
    'Huyện Nam Trực': 3789,
    'Huyện Nghĩa Hưng': 3789,
    'Huyện Trực Ninh': 3789,
    'Huyện Vụ Bản': 3789,
    'Huyện Ý Yên': 3789,
    'Nam Định': 3789,
    'Huyện Anh Sơn': 3780,
    'Huyện Con Cuông': 3780,
    'Huyện Diễn Châu': 3780,
    'Huyện Đô Lương': 3780,
    'Huyện Hưng Nguyên': 3780,
    'Huyện Kỳ Sơn': 3780,
    'Huyện Nam Đàn': 3780,
    'Huyện Nghi Lộc': 3780,
    'Huyện Nghĩa Đàn': 3780,
    'Huyện Quế Phong': 3780,
    'Huyện Quỳ Châu': 3780,
    'Huyện Quỳ Hợp': 3780,
    'Huyện Quỳnh Lưu': 3780,
    'Huyện Thanh Chương': 3780,
    'Huyện Tương Dương': 3780,
    'Huyện Yên Thành': 3780,
    'Vinh': 3780,
    'Yên Vinh': 3780,
    'Huyện Hoa Lư': 3786,
    'Huyện Kim Sơn': 3786,
    'Huyện Nho Quan': 3786,
    'Huyện Yên Khánh': 3786,
    'Huyện Yên Mô': 3786,
    'Ninh Bình': 3786,
    'Phan Rang-Tháp Chàm': 3788,
    'Huyện Cẩm Khê': 3801,
    'Huyện Đoan Hùng': 3801,
    'Huyện Hạ Hòa': 3801,
    'Huyen Lam Thao': 3801,
    'Huyện Thanh Ba': 3801,
    'Huyện Yên Lập': 3801,
    'Thành Phố Việt Trì': 3801,
    'Thị xã Phú Thọ': 3801,
    'Việt Trì': 3801,
    'Huyện Sơn Hòa': 3824,
    'Huyện Sông Hinh': 3824,
    'Sông Cầu': 3824,
    'Tuy Hòa': 3824,
    'Huyện Lệ Thủy': 3809,
    'Huyện Quảng Ninh': 3809,
    'Huyện Quảng Trạch': 3809,
    'Huyện Tuyên Hóa': 3809,
    'Kwang Binh': 3809,
    'Hội An': 3776,
    'Huyện Duy Xuyên': 3776,
    'Huyện Đại Lộc': 3776,
    'Huyện Điện Bàn': 3776,
    'Huyện Hiệp Đức': 3776,
    'Huyện Nam Giang': 3776,
    'Huyện Núi Thành': 3776,
    'Huyện Phước Sơn': 3776,
    'Huyện Tiên Phước': 3776,
    'Huyện Trà My': 3776,
    'Tam Kỳ': 3776,
    'Huyện Ba Tơ': 3828,
    'Huyện Bình Sơn': 3828,
    'Huyện Đức Phổ': 3828,
    'Huyện Lý Sơn': 3828,
    'Huyện Minh Long': 3828,
    'Huyện Mộ Đức': 3828,
    'Huyện Nghĩa Hành': 3828,
    'Huyện Sơn Hà': 3828,
    'Huyện Sơn Tây': 3828,
    'Huyện Sơn Tịnh': 3828,
    'Huyện Trà Bồng': 3828,
    'Huyện Tư Nghĩa': 3828,
    'Quảng Ngãi': 3828,
    'Cẩm Phả': 3814,
    'Cẩm Phả Mines': 3814,
    'Hạ Long': 3814,
    'Huyện Ba Chẽ': 3814,
    'Huyện Bình Liêu': 3814,
    'Huyện Cô Tô': 3814,
    'Huyện Đầm Hà': 3814,
    'Huyện Đông Triều': 3814,
    'Huyện Hải Hà': 3814,
    'Huyện Hoành Bồ': 3814,
    'Huyện Tiên Yên': 3814,
    'Huyện Vân Đồn': 3814,
    'Móng Cái': 3814,
    'Thành Phố Uông Bí': 3814,
    'Ðông Hà': 3803,
    'Huyện Cam Lộ': 3803,
    'Huyện Đa Krông': 3803,
    'Huyện Gio Linh': 3803,
    'Huyện Hải Lăng': 3803,
    'Huyện Hướng Hóa': 3803,
    'Huyện Triệu Phong': 3803,
    'Huyện Vĩnh Linh': 3803,
    'Huyện Kế Sách': 3819,
    'Huyện Long Phú': 3819,
    'Huyện Mỹ Tú': 3819,
    'Huyện Mỹ Xuyên': 3819,
    'Huyen Nga Nam': 3819,
    'Huyện Thạnh Trị': 3819,
    'Sóc Trăng': 3819,
    'Huyện Bắc Yên': 3812,
    'Huyện Mai Sơn': 3812,
    'Huyện Mộc Châu': 3812,
    'Huyện Mường La': 3812,
    'Huyện Phù Yên': 3812,
    'Huyện Quỳnh Nhai': 3812,
    'Huyện Sông Mã': 3812,
    'Huyện Thuận Châu': 3812,
    'Huyện Yên Châu': 3812,
    'Sơn La': 3812,
    'Huyện Bến Cầu': 3826,
    'Huyện Dương Minh Châu': 3826,
    'Huyện Gò Dầu': 3826,
    'Huyện Hòa Thành': 3826,
    'Huyện Tân Châu': 3826,
    'Huyện Trảng Bàng': 3826,
    'Phú Khương': 3826,
    'Tây Ninh': 3826,
    'Huyện Ðông Hưng': 3775,
    'Huyện Hưng Hà': 3775,
    'Huyện Kiến Xương': 3775,
    'Huyện Quỳnh Phụ': 3775,
    'Huyện Thái Thụy': 3775,
    'Huyện Tiền Hải': 3775,
    'Huyện Vũ Thư': 3775,
    'Thái Bình': 3775,
    'Thái Nguyên': 3807,
    'Bỉm Sơn': 3771,
    'Huyện Bá Thước': 3771,
    'Huyện Cẩm Thủy': 3771,
    'Huyện Đông Sơn': 3771,
    'Huyện Hà Trung': 3771,
    'Huyện Hậu Lộc': 3771,
    'Huyện Lang Chánh': 3771,
    'Huyện Mường Lát': 3771,
    'Huyện Nga Sơn': 3771,
    'Huyện Ngọc Lặc': 3771,
    'Huyện Như Thanh': 3771,
    'Huyện Như Xuân': 3771,
    'Huyện Nông Cống': 3771,
    'Huyện Quan Hóa': 3771,
    'Huyện Quan Sơn': 3771,
    'Huyện Quảng Xương': 3771,
    'Huyện Thiệu Hóa': 3771,
    'Huyện Thọ Xuân': 3771,
    'Huyện Thường Xuân': 3771,
    'Huyện Triệu Sơn': 3771,
    'Huyện Vĩnh Lộc': 3771,
    'Huyện Yên Định': 3771,
    'Thanh Hóa': 3771,
    'Huế': 3798,
    'Huyện A Lưới': 3798,
    'Huyện Nam Đông': 3798,
    'Huyện Phong Điền': 3798,
    'Huyện Phú Lộc': 3798,
    'Huyện Phú Vang': 3798,
    'Huyện Quảng Ðiền': 3798,
    'Huyện Cái Bè': 3781,
    'Huyện Cai Lậy': 3781,
    'Huyện Châu Thành': 3781,
    'Huyện Chợ Gạo': 3781,
    'Huyện Gò Công Đông': 3781,
    'Huyện Gò Công Tây': 3781,
    'Huyện Tân Phước': 3781,
    'Mỹ Tho': 3781,
    'Thành Phố Mỹ Tho': 3781,
    'Thị Xã Gò Công': 3781,
    'Huyện Càng Long': 3805,
    'Huyện Cầu Kè': 3805,
    'Huyện Cầu Ngang': 3805,
    'Huyện Tiểu Cần': 3805,
    'Huyện Trà Cú': 3805,
    'Trà Vinh': 3805,
    'Huyện Chiêm Hóa': 3795,
    'Huyện Hàm Yên': 3795,
    'Huyện Lâm Bình': 3795,
    'Huyện Na Hang': 3795,
    'Huyện Sơn Dương': 3795,
    'Huyện Yên Sơn': 3795,
    'Tuyên Quang': 3795,
    'Huyện Long Hồ': 3790,
    'Huyện Mang Thít': 3790,
    'Huyện Tam Bình': 3790,
    'Huyện Trà Ôn': 3790,
    'Huyện Vũng Liêm': 3790,
    'Vĩnh Long': 3790,
    'Huyện Bình Xuyên': 3774,
    'Huyện Tam Dương': 3774,
    'Huyện Tam Đảo': 3774,
    'Huyện Yên Lạc': 3774,
    'Vĩnh Yên': 3774,
    'Huyện Lục Yên': 3784,
    'Huyện Mù Cang Chải': 3784,
    'Huyện Trạm Tấu': 3784,
    'Huyện Trấn Yên': 3784,
    'Huyện Văn Chấn': 3784,
    'Huyện Văn Yên': 3784,
    'Yên Bái': 3784,
    'Aden': 1242,
    'Al Buraiqeh': 1242,
    'Al Mansura': 1242,
    'Al Mualla': 1242,
    'Ash Shaikh Outhman': 1242,
    'Attawahi': 1242,
    'Craiter': 1242,
    'Dar Sad': 1242,
    '‘Amrān': 1250,
    'Al Ashah': 1250,
    'Al Madan': 1250,
    'Al Qaflah': 1250,
    'As Sawd': 1250,
    'As Sudah': 1250,
    'Bani Suraim': 1250,
    'Dhi Bin': 1250,
    'Habur Zulaymah': 1250,
    'Harf Sufyan': 1250,
    'Hooth': 1250,
    'Iyal Surayh': 1250,
    'Jabal Iyal Yazid': 1250,
    'Khamir': 1250,
    'Kharif': 1250,
    'Maswar': 1250,
    'Raydah': 1250,
    'Shahārah': 1250,
    'Suwayr': 1250,
    'Thula': 1250,
    'Ahwar': 1237,
    'Al Mahfad': 1237,
    'Al Wade  a': 1237,
    'Jawf al Maqbābah': 1237,
    'Jayshan': 1237,
    'Khanfir': 1237,
    'Lawdar': 1237,
    'Mudiyah': 1237,
    'Rasad': 1237,
    'Sarar': 1237,
    'Sibah': 1237,
    'Zingibar': 1237,
    'Zinjibār': 1237,
    'Al A  rsh': 1240,
    'Al Bayda': 1240,
    'Al Bayda City': 1240,
    'Al Bayḑā’': 1240,
    'Al Malagim': 1240,
    'Al Quraishyah': 1240,
    'Ar Ryashyyah': 1240,
    'As Sawadiyah': 1240,
    'As Sawma  ah': 1240,
    'Ash Sharafayn': 1240,
    'At Taffah': 1240,
    'Az Zahir': 1240,
    'Dhi Na  im': 1240,
    'Maswarah': 1240,
    'Mukayras': 1240,
    'Na  man': 1240,
    'Nati  ': 1240,
    'Radā‘': 1240,
    'Radman Al Awad': 1240,
    'Sabah': 1240,
    'Wald Rabi  ': 1240,
    'Ad Dahi': 1241,
    'Ad Durayhimi': 1241,
    'Al Garrahi': 1241,
    'Al Hajjaylah': 1241,
    'Al Hali': 1241,
    'Al Hawak': 1241,
    'Al Ḩudaydah': 1241,
    'Al Mansuriyah': 1241,
    'Al Marawi  ah': 1241,
    'Al Mighlaf': 1241,
    'Al Mina': 1241,
    'Al Munirah': 1241,
    'Al Qanawis': 1241,
    'Alluheyah': 1241,
    'As Salif': 1241,
    'As Sukhnah': 1241,
    'At Tuhayat': 1241,
    'Az Zaydīyah': 1241,
    'Az Zuhrah': 1241,
    'Bājil': 1241,
    'Bayt al Faqīh': 1241,
    'Bura': 1241,
    'Hays': 1241,
    'Jabal Ra  s': 1241,
    'Kamaran': 1241,
    'Zabīd': 1241,
    'Al ‘Inān': 1243,
    'Al Ghayl': 1243,
    'Al Hazm': 1243,
    'Al Humaydat': 1243,
    'Al Khalq': 1243,
    'Al Maslub': 1243,
    'Al Matammah': 1243,
    'Al Maton': 1243,
    'Az Zahir': 1243,
    'Barţ al ‘Anān': 1243,
    'Khabb wa ash Sha  af': 1243,
    'Kharab Al Marashi': 1243,
    'Rajuzah': 1243,
    'Al Ghaydah': 1251,
    'Al Ghayz̧ah': 1251,
    'Al Masilah': 1251,
    'Hat': 1251,
    'Hawf': 1251,
    'Huswain': 1251,
    'Man  ar': 1251,
    'Qishn': 1251,
    'Sayhut': 1251,
    'Shahan': 1251,
    'Al Khabt': 1235,
    'Al Mahwait': 1235,
    'Al Maḩwīt': 1235,
    'Ar Rujum': 1235,
    'Aţ Ţawīlah': 1235,
    'Bani Sa  d': 1235,
    'Hufash': 1235,
    'Milhan': 1235,
    'Shibām Kawkabān': 1235,
    '‘Ans': 1246,
    'Al Hada': 1246,
    'Al Manar': 1246,
    'Al-Medy Village, قرية المدي': 1246,
    'Dawran Aness': 1246,
    'Dhamār': 1246,
    'Jabal Ash sharq': 1246,
    'Jahran': 1246,
    'Maghirib Ans': 1246,
    'Mayfa  at Anss': 1246,
    'Utmah': 1246,
    'Wusab Al Ali': 1246,
    'Wusab As Safil': 1246,
    'Ad Dis': 1238,
    'Ad Dīs ash Sharqīyah': 1238,
    'Adh Dhlia  ah': 1238,
    'Al Abr': 1238,
    'Al Ḩamdī': 1238,
    'Al Mukalla': 1238,
    'Al Mukalla City': 1238,
    'Al Qaf': 1238,
    'Al Qatn': 1238,
    'Amd': 1238,
    'Ar Raydah Wa Qusayar': 1238,
    'As Sawm': 1238,
    'Ash Shihr': 1238,
    'At Taḩāluf': 1238,
    'Brom Mayfa': 1238,
    'Daw  an': 1238,
    'Ghayl Ba Wazir': 1238,
    'Ghayl Bin Yamin': 1238,
    'Hagr As Sai  ar': 1238,
    'Hajr': 1238,
    'Huraidhah': 1238,
    'Kilmia': 1238,
    'Mukalla': 1238,
    'Rakhyah': 1238,
    'Rumah': 1238,
    'Sah': 1238,
    'Sayun': 1238,
    'Shibam': 1238,
    'Suḩayl Shibām': 1238,
    'Tarim': 1238,
    'Thamud': 1238,
    'Wadi Al Ayn': 1238,
    'Yabuth': 1238,
    'Zamakh wa Manwakh': 1238,
    'Abs': 1244,
    'Aflah Al Yaman': 1244,
    'Aflah Ash Shawm': 1244,
    'Al Jamimah': 1244,
    'Al Maghrabah': 1244,
    'Al Mahabishah': 1244,
    'Al Miftah': 1244,
    'Ash Shaghadirah': 1244,
    'Ash Shahil': 1244,
    'Aslem': 1244,
    'Bakil Al Mir': 1244,
    'Banī al ‘Awwām': 1244,
    'Bani Al Awam': 1244,
    'Bani Qa  is': 1244,
    'Hajjah': 1244,
    'Harad District': 1244,
    'Hayran': 1244,
    'Khayran Al Muharraq': 1244,
    'Ku  aydinah': 1244,
    'Kuhlan Affar': 1244,
    'Kuhlan Ash Sharaf': 1244,
    'Kushar': 1244,
    'Mabyan': 1244,
    'Midi': 1244,
    'Mustaba': 1244,
    'Najrah': 1244,
    'Qafl Shamer': 1244,
    'Qarah': 1244,
    'Sharas': 1244,
    'Wadhrah': 1244,
    'Washḩah': 1244,
    'Al ‘Udayn': 1233,
    'Al Dhihar': 1233,
    'Al Makhādir': 1233,
    'Al Mashannah': 1233,
    'Al Qafr': 1233,
    'An Nādirah': 1233,
    'Ar Radmah': 1233,
    'As Sabrah': 1233,
    'As Saddah': 1233,
    'As Sayyani': 1233,
    'Ash Sha  ir': 1233,
    'Ba  dan': 1233,
    'Dhī as Sufāl': 1233,
    'Far Al Udayn': 1233,
    'Hazm Al Udayn': 1233,
    'Hubaysh': 1233,
    'Ibb': 1233,
    'Jiblah': 1233,
    'Mudhaykhirah': 1233,
    'Yarīm': 1233,
    'Al  Hawtah': 1245,
    'Al Ḩabīlayn': 1245,
    'Al Had': 1245,
    'Al Madaribah Wa Al Arah': 1245,
    'Al Maflahy': 1245,
    'Al Maqatirah': 1245,
    'Al Milah': 1245,
    'Al Musaymir': 1245,
    'Al Qabbaytah': 1245,
    'Habil Jabr': 1245,
    'Halimayn': 1245,
    'Laḩij': 1245,
    'Radfan': 1245,
    'Tuban': 1245,
    'Tur Al Bahah': 1245,
    'Yafa  a': 1245,
    'Yahr': 1245,
    'Al Abdiyah': 1234,
    'Al Jubah': 1234,
    'Bidbadah': 1234,
    'Ḩarīb': 1234,
    'Harib Al Qaramish': 1234,
    'Jabal Murad': 1234,
    'Ma  rib': 1234,
    'Mahliyah': 1234,
    'Majzar': 1234,
    'Marib': 1234,
    'Marib City': 1234,
    'Medghal': 1234,
    'Raghwan': 1234,
    'Rahabah': 1234,
    'Sirwah': 1234,
    'Al Jabin': 1248,
    'Al Jafariyah': 1248,
    'As Salafiyah': 1248,
    'Bilad At Ta  am': 1248,
    'Kusmah': 1248,
    'Mazhar': 1248,
    'Al Dhaher': 1249,
    'Al Hashwah': 1249,
    'As Safra': 1249,
    'Ash Shawātī': 1249,
    'Baqim': 1249,
    'Ghamr': 1249,
    'Haydan': 1249,
    'Kitaf wa Al Boqe  e': 1249,
    'Majz': 1249,
    'Monabbih': 1249,
    'Qatabir': 1249,
    'Rāziḩ': 1249,
    'Sa  dah': 1249,
    'Şa‘dah': 1249,
    'Saḩār': 1249,
    'Saqayn': 1249,
    'Shada  a': 1249,
    'Al Haymah Ad Dakhiliyah': 1236,
    'Al Haymah Al Kharijiyah': 1236,
    'Al Husn': 1236,
    'Al Khāniq': 1236,
    'Arhab': 1236,
    'Attyal': 1236,
    'Bani Dhabyan': 1236,
    'Bani Hushaysh': 1236,
    'Bani Matar': 1236,
    'Bilad Ar Rus': 1236,
    'Hamdān': 1236,
    'Jihanah': 1236,
    'Khwlan': 1236,
    'Manakhah': 1236,
    'Nihm': 1236,
    'Sa  fan': 1236,
    'Saḩar': 1236,
    'Sanaa': 1236,
    'Sanhan': 1236,
    'Sayyān': 1236,
    'Ain': 1247,
    'Al ‘Āqir': 1247,
    'Al Talh': 1247,
    'AL-khashā upper': 1247,
    'Ar Rawdah': 1247,
    'Arma': 1247,
    'As Said': 1247,
    'Ataq': 1247,
    'Bayhan': 1247,
    'Dhar': 1247,
    'Habban': 1247,
    'Hatib': 1247,
    'Jardan': 1247,
    'Khimār': 1247,
    'Mayfa  a': 1247,
    'Merkhah Al Ulya': 1247,
    'Merkhah As Sufla': 1247,
    'Nisab': 1247,
    'Rudum': 1247,
    'Usaylan': 1247,
    'Hadibu': 1239,
    'Hidaybu': 1239,
    'Qalansīyah': 1239,
    'Qulensya Wa Abd Al Kuri': 1239,
    'Al Ma  afer': 1231,
    'Al Mawasit': 1231,
    'Al Misrakh': 1231,
    'Al Mudhaffar': 1231,
    'Al Mukhā’': 1231,
    'Al Qahirah': 1231,
    'Al Wazi  iyah': 1231,
    'As Silw': 1231,
    'Ash Shamayatayn': 1231,
    'At Ta‘izzīyah': 1231,
    'Dhubab': 1231,
    'Dimnat Khadir': 1231,
    'Hayfan': 1231,
    'Jabal Habashy': 1231,
    'Maqbanah': 1231,
    'Mashra  a Wa Hadnan': 1231,
    'Māwīyah': 1231,
    'Mawza': 1231,
    'Sabir Al Mawadim': 1231,
    'Salh': 1231,
    'Sama': 1231,
    'Shara  b Ar Rawnah': 1231,
    'Shara  b As Salam': 1231,
    'Ta‘izz': 1231,
    'Village of ALAMRAH': 1231,
    'Chibombo': 1986,
    'Chibombo District': 1986,
    'Kabwe': 1986,
    'Kapiri Mposhi': 1986,
    'Mkushi': 1986,
    'Mumbwa': 1986,
    'Serenje': 1986,
    'Chambishi': 1984,
    'Chililabombwe': 1984,
    'Chingola': 1984,
    'Chingola District': 1984,
    'Kalulushi': 1984,
    'Kataba': 1984,
    'Kitwe': 1984,
    'Luanshya': 1984,
    'Mpongwe': 1984,
    'Mufulira': 1984,
    'Ndola': 1984,
    'Chadiza': 1991,
    'Chipata': 1991,
    'Lundazi': 1991,
    'Nyimba': 1991,
    'Petauke': 1991,
    'Kawambwa': 1987,
    'Mansa': 1987,
    'Mwense': 1987,
    'Nchelenge': 1987,
    'Samfya': 1987,
    'Chongwe': 1988,
    'Kafue': 1988,
    'Luangwa': 1988,
    'Lusaka': 1988,
    'Chama': 1989,
    'Chinsali': 1989,
    'Isoka': 1989,
    'Mpika': 1989,
    'Nakonde': 1989,
    'Kaputa': 1982,
    'Kasama': 1982,
    'Luwingu': 1982,
    'Mbala': 1982,
    'Mporokoso': 1982,
    'Mpulungu': 1982,
    'Mungwi': 1982,
    'Kabompo': 1985,
    'Kalengwa': 1985,
    'Kansanshi': 1985,
    'Kasempa': 1985,
    'Mufumbwe': 1985,
    'Mwinilunga': 1985,
    'Solwezi': 1985,
    'Zambezi': 1985,
    'Choma': 1990,
    'Gwembe': 1990,
    'Itezhi-Tezhi District': 1990,
    'Livingstone': 1990,
    'Maamba': 1990,
    'Mazabuka': 1990,
    'Monze': 1990,
    'Nakambala': 1990,
    'Namwala': 1990,
    'Siavonga': 1990,
    'Siavonga District': 1990,
    'Sinazongwe': 1990,
    'Kalabo': 1983,
    'Kaoma': 1983,
    'Limulunga': 1983,
    'Lukulu': 1983,
    'Mongu': 1983,
    'Senanga': 1983,
    'Sesheke': 1983,
    'Bulawayo': 1956,
    'Chitungwiza': 1958,
    'Epworth': 1958,
    'Harare': 1958,
    'Buhera District': 1959,
    'Chimanimani': 1959,
    'Chimanimani District': 1959,
    'Chipinge': 1959,
    'Chipinge District': 1959,
    'Dorowa Mining Lease': 1959,
    'Headlands': 1959,
    'Makoni District': 1959,
    'Mutare': 1959,
    'Mutare District': 1959,
    'Mutasa District': 1959,
    'Nyanga': 1959,
    'Nyanga District': 1959,
    'Nyazura': 1959,
    'Odzi': 1959,
    'Penhalonga': 1959,
    'Rusape': 1959,
    'Bindura': 1955,
    'Bindura District': 1955,
    'Centenary': 1955,
    'Centenary District': 1955,
    'Concession': 1955,
    'Glendale': 1955,
    'Guruve District': 1955,
    'Mazowe': 1955,
    'Mazowe District': 1955,
    'Mount Darwin': 1955,
    'Mvurwi': 1955,
    'Rushinga District': 1955,
    'Shamva': 1955,
    'Shamva District': 1955,
    'Beatrice': 1951,
    'Chivhu': 1951,
    'Goromonzi District': 1951,
    'Macheke': 1951,
    'Marondera': 1951,
    'Marondera District': 1951,
    'Mudzi District': 1951,
    'Murehwa': 1951,
    'Murehwa District': 1951,
    'Mutoko': 1951,
    'Ruwa': 1951,
    'Banket': 1953,
    'Chakari': 1953,
    'Chegutu': 1953,
    'Chegutu District': 1953,
    'Chinhoyi': 1953,
    'Chirundu': 1953,
    'Hurungwe District': 1953,
    'Kadoma': 1953,
    'Kadoma District': 1953,
    'Kariba': 1953,
    'Kariba District': 1953,
    'Karoi': 1953,
    'Makonde District': 1953,
    'Mhangura': 1953,
    'Norton': 1953,
    'Raffingora': 1953,
    'Bikita District': 1960,
    'Chiredzi': 1960,
    'Chiredzi District': 1960,
    'Chivi District': 1960,
    'Gutu District': 1960,
    'Mashava': 1960,
    'Masvingo': 1960,
    'Masvingo District': 1960,
    'Mwenezi District': 1960,
    'Zvishavane': 1960,
    'Binga': 1954,
    'Binga District': 1954,
    'Bubi District': 1954,
    'Dete': 1954,
    'Hwange': 1954,
    'Hwange District': 1954,
    'Inyati': 1954,
    'Kamativi Mine': 1954,
    'Lupane': 1954,
    'Lupane District': 1954,
    'Nkayi District': 1954,
    'Victoria Falls': 1954,
    'Beitbridge': 1952,
    'Beitbridge District': 1952,
    'Esigodini': 1952,
    'Filabusi': 1952,
    'Gwanda': 1952,
    'Gwanda District': 1952,
    'Insiza': 1952,
    'Insiza District': 1952,
    'Mangwe District': 1952,
    'Matobo': 1952,
    'Plumtree': 1952,
    'Umzingwane District': 1952,
    'Gokwe': 1957,
    'Gweru': 1957,
    'Gweru District': 1957,
    'Kwekwe': 1957,
    'Kwekwe District': 1957,
    'Lalapanzi': 1957,
    'Mberengwa District': 1957,
    'Mvuma': 1957,
    'Redcliff': 1957,
    'Shangani': 1957,
    'Shurugwi': 1957,
    'Shurugwi District': 1957,
    'Zvishavane District': 1957,
}