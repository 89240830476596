import "../App.css";
import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Form, Button, InputGroup, Container } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Facebook from "../asstes/facebooklogin.png";
import Google from "../asstes/google.png";
import Cookies from "js-cookie";
import { apiHost } from "../Helper";
import { AppContext } from "../Context";
import SignalRService from "../SignalR";
// import info from "../asstes/info.svg";
// import closeIcon from "../asstes/closeIcon.svg";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passworderr, setPassworderror] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [type, settype] = useState("password");
  // const [showContent, setShowContent] = useState(false);
  const navigate = useNavigate();

  const context = useContext(AppContext);

  useEffect(() => {
    // if (sessionStorage.getItem("previousPathName") == "/Ordercom") {
    //   sessionStorage.removeItem("sessionIn");
    // }
    // localStorage.setItem("MyOrders", "True");
    if (window.location.host == "localhost:3000") {
      setEmail("usamayasir32@gmail.com");
      setPassword("Admin@123");
    } else if (localStorage.Testing == "True") {
      setEmail("daniyalqazi94@gmail.com");
      setPassword("Admin@123");
    }
    context.dispatch({ Type: "NAVIGATION" });
  }, [null]);

  const changetype = async () => {
    settype("input");
  };
  const changetypepass = async () => {
    settype("password");
  };

  // const isAlreadyVerified = async (userId) => {
  //   setIsLoading(true);
  //   let url = `${apiHost}/api/McPrime/IsVerified?UserId=${userId}`;

  //   axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data == true) {
  //         setIsLoading(false);
  //         sessionStorage.setItem("IsMCPrimeAuthenticated", true);
  //         document.getElementById("accordions").style.display = "block";
  //         document.getElementById("primepasscodeId").style.display = "none";
  //       } else {
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((e) => {
  //       setIsLoading(false);
  //     });
  // };

  const getlocation = () => {
    return new Promise((resolve, reject) => {
      console.log(navigator.permissions)
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result.state)
          if (result.state == "granted" || result.state == "prompt") {
            try {
              navigator.geolocation.getCurrentPosition(function (position) {
                fetch(
                  `https://api.geoapify.com/v1/geocode/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json&apiKey=21f61a865243443b8b07654137eadc62`,
                  { method: "GET" }
                )
                  .then((res) => res.json())
                  .then((data) => {
                    console.log(data);
                    let newObj = { ...data.results[0] };
                    fetch(
                      `${apiHost}/api/Orders/GetCoordinates?city=${newObj.city}&state=${newObj.state}&country=${newObj.country}`,
                      {
                        method: "GET",
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                        },
                      }
                    )
                      .then((res) => res.json())
                      .then((ress) => {
                      })
                    resolve(newObj);
                  })
                  .catch((err) => {
                    console.error(err);
                    resolve({
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude,
                    })
                  }
                  );
              }, (err) => {
                console.error(err)
                resolve(null)
              });
            } catch (err) {
              console.error(err)
            }
          } else {
            resolve(null);
          }
        });
    });
  };

  const login = async () => {
    setIsLoading(true);
    let is1FX = props.is1FX;
    let coordinates = null;
    try {
      let result = await getlocation();
      console.log(result)
      coordinates = result != null && JSON.stringify(result);
      let Email = email.toLocaleLowerCase();
      let url = `${apiHost}/api/Authentication/Login?email=${Email}&password=${password}&location=${coordinates}&pathname=${is1FX ? "1Fx" : "FullFx"
        }`;
      axios
        .post(url)
        .then((response) => {
          if (response.data == "") {
            setPassworderror("Email or Password is incorrect!");
            setIsLoading(false);
          } else if (response.data.token == "" || response.data.token == null) {
            setPassworderror(
              "Your account has been restricted for about 30 minutes."
            );
            setIsLoading(false);
          } else {
            let lastChecked = new Date(response.data.welcomeTime).getMonth();
            //let lastChecked = 0;
            let current = new Date().getMonth();
            Cookies.set("email", Email);
            const cookieWarning = localStorage.Cookie === "SEEN";
            localStorage.clear();
            if (cookieWarning) {
              localStorage.setItem("Cookie", "SEEN");
            }
            sessionStorage.setItem("logedin", true);
            sessionStorage.setItem("email", Email);
            localStorage.setItem("authToken", response.data.token);
            sessionStorage.setItem("sessionIn", 1);
            sessionStorage.setItem("publicKey", response.data.publicKey);
            sessionStorage.setItem("privateKey", response.data.privateKey);
            sessionStorage.setItem("isMute", response.data.isMute);
            sessionStorage.setItem("Reviewedusers", response.data.reviewedusers);
            sessionStorage.setItem("UserName", response.data.userName);
            sessionStorage.setItem("City", response.data.city);
            sessionStorage.setItem("Province", response.data.province);
            sessionStorage.setItem("Country", response.data.country);
            sessionStorage.setItem("contact", response.data.mobileNumber);
            sessionStorage.setItem("UserID", response.data.id);
            sessionStorage.setItem("LastMsg", response.data.lastMsg);
            sessionStorage.setItem("LastInvite", response.data.lastInvite);
            sessionStorage.setItem("Picture", response.data.picture);
            sessionStorage.setItem("IsMC", response.data.isMC);
            sessionStorage.setItem("IsMCPrime", response.data.isMCPrime);
            sessionStorage.setItem("IsMCPrimeAuthenticated", false);
            sessionStorage.setItem("loginStamp", Date.now());
            sessionStorage.setItem(
              "countrywhereGovernmentIdissued",
              response.data.countrywhereGovernmentIdissued
            );
            if (
              response.data.mobileNumber == undefined ||
              response.data.mobileNumber == ""
            ) {
              is1FX = true;
            }
            sessionStorage.setItem(
              "TimeZone",
              Intl.DateTimeFormat().resolvedOptions().timeZone
            );
            setIsLoading(false);
            SignalRService.registerConnection(sessionStorage.UserID);
            context.dispatch({
              Type: "FULLFXCHECK",
              Payload: response.data.city == null || "" ? false : true,
            });
            context.dispatch({ Type: "NAVIGATION" });
            context.dispatch({
              Type: "PERMISSION",
              Payload: {
                isMc: response.data.isMC,
                isMcprime: response.data.isMCPrime,
                isMcprimeAuthenticated: response.data.isMCPrimeAuthenticated,
              },
            });

            if (is1FX) {
              console.log(lastChecked - current, "lastChecked - current");
              if (lastChecked - current <= -1) {
                sessionStorage.setItem("prev", "1fx");
                navigate(`/Welcome?ID=${response.data.id}&t=${Date.now()}`);
              } else if (lastChecked.toString() == "NaN") {
                sessionStorage.setItem("prev", "1fx");
                navigate(`/Welcome?ID=${response.data.id}&t=${Date.now()}`);
              } else {
                // navigate(`/OrderFXHome?ID=${response.data.id}&t=${Date.now()}`);
                window.location.href =
                  window.location.origin +
                  `/OrderFXHome?ID=${response.data.id}&t=${Date.now()}`;
              }
            } else {
              context.dispatch({
                Type: "NavMobile",
                Payload: { tab: "My Orders" },
              });
              if (lastChecked - current <= -1) {
                sessionStorage.setItem("prev", "fullfx");
                navigate(`/Welcome?ID=${response.data.id}&t=${Date.now()}`);
              } else if (lastChecked.toString() == "NaN") {
                sessionStorage.setItem("prev", "fullfx");
                navigate(`/Welcome?ID=${response.data.id}&t=${Date.now()}`);
              } else {
                navigate(`/OrderCom?ID=${response.data.id}&t=${Date.now()}`);
              }
            }

            // isAlreadyVerified(response.data.id);

            fetch(
              `https://api.opencagedata.com/geocode/v1/json?q=${response.data.city}&key=ede4e8b4a1fb486f9f30e23b53b2504d&pretty=1`,
              {
                method: "GET",
              }
            )
              .then((res) => res.json())
              .then((ress) => {
                let giveLat = ress.results[0].annotations.DMS.lat;
                let giveLng = ress.results[0].annotations.DMS.lng;
                giveLat =
                  parseFloat(giveLat.split("°")[0]) +
                  parseFloat(giveLat.split("°")[1].split("'")[0]) / 60 +
                  parseFloat(giveLat.split("°")[1].split("'")[1]) / 3600;
                giveLng =
                  parseFloat(giveLng.split("°")[0]) +
                  parseFloat(giveLng.split("°")[1].split("'")[0]) / 60 +
                  parseFloat(giveLng.split("°")[1].split("'")[1]) / 3600;
                var giveCityLatLon = { lat: giveLat, lon: giveLng };
                sessionStorage.setItem(
                  "UserCoordinates",
                  JSON.stringify(giveCityLatLon)
                );
              });
          }
        })
        .catch((error) => {
          //console.log(error);
          setPassworderror("Email or Password is incorrect!");
          setIsLoading(false);
        });
    } catch (err) {
      console.err(err)
    }
  };

  const loginOnEnter = (e) => {
    if (e.charCode == 13) {
      login();
    }
  };

  // const toggleContent = () => {
  //   setShowContent(!showContent);
  // };

  // const closeContent = () => {
  //   setShowContent(false);
  // };

  return (
    <div>
      <Container>
        {/*
        {showContent && (
          <div className="infoLandingPage">
            <div className="content-header">
              <div className="close-icon d-flex justify-content-end">
                <img src={closeIcon} alt="Close" onClick={closeContent}/>
              </div>
            </div>
            <div
              className=" p-2"
              style={{ background: "white", color: "grey" }}
            >
              <p className=" mb-3">
                Mobala helps you find people and money changers with whom you
                can mutually exchange foreign currency and save on traditional
                conversion costs.
              </p>
              <p className="mt-3 mb-3">
                If you need to exchange on an occasional basis, we suggest you
                use 1-order FX.
              </p>
              <p className="mt-3 mb-3">
                If you have frequent conversion requirements, you might have a
                better experience with our full feature Full FX.
              </p>
              <p className="mt-3 mb-3">
                Regardless, we have no fees, after all we just help you discover
                people like yourself.
              </p>
              <p className="mt-3 mb-3">
                Mobala is a free platform and we don't handle any
                money and have no agents acting on our behalf.
              </p>
            </div>
          </div>
        )} */}
        <Row className="centeritem ">
          <Col md={props.is1FX || props.isFullFx ? 8 : 5} className="formbg ">
            <Form className="forminput radius contentcenter text-center">
              <Form.Group
                className="mb-3 text-center"
                controlId="formBasicEmail"
              >
                <p className="text-form  mb-3">
                  Why go to banks when you can exchange with other people
                </p>

                <Form.Control
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onKeyPress={loginOnEnter}
                  value={email}
                  type="email"
                  placeholder="Enter email"
                  onClick={(e) => {
                    setPassworderror(null);
                  }}
                />
              </Form.Group>

              <InputGroup className="mb-3">
                <Form.Control
                  id="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onKeyPress={loginOnEnter}
                  type={type === "password" ? "password" : "input"}
                  placeholder="Password"
                  aria-label="Password"
                  aria-describedby="basic-addon2"
                  value={password}
                />
                {type === "password" ? (
                  <div style={{ zIndex: 10 }} className="eye-icon">
                    <i onClick={changetype} className="fa fa-eye-slash"></i>
                  </div>
                ) : (
                  <div className="eye-icon" style={{ zIndex: 10 }}>
                    <i onClick={changetypepass} className="fa fa-eye"></i>
                  </div>
                )}
              </InputGroup>
              <h6 style={{ marginBottom: 14, color: "red" }}>{passworderr}</h6>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button
                  variant="primary"
                  className="btnlogin"
                  style={props.is1FX || props.isFullFx ? {} : { width: "100%" }}
                  // type="submit"
                  onClick={login}
                >
                  {isLoading ? (
                    <>
                      {/* <span className="">Loading</span> */}
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                      ></Spinner>
                    </>
                  ) : (
                    "Login"
                  )}
                </Button>
              </div>
              <Row className="mt-3 text-center">
                <a
                  onClick={() => navigate("/passreset")}
                  className="fpass"
                  style={{ textDecoration: "underline" }}
                >
                  Forgot password?
                </a>
              </Row>
              {props.is1FX ? null : (
                <>
                  <p className="text-form  mt-3 text-center">
                    Don't have an account?{" "}
                    <a
                      onClick={() => navigate("/SignUp")}
                      style={{
                        color: "#0d6efd",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Create one here
                    </a>
                  </p>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
      {/* {session.length > 0 ? session.map((d, i) => <p>{d.date_time}</p>) : null} */}
    </div>
  );
}

export default Login;
