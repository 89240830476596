import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { Row, Col, Modal, Button } from "react-bootstrap";
import notifsound from "../asstes/notif.wav";
import { apiHost, addDoc } from "../Helper";
import { AppContext } from "../Context";
import Picture from "../asstes/Profile.svg";
import { useNavigate } from "react-router-dom";
import Notify from "../asstes/notify.svg";
import NotifyWithDot from "../asstes/notifywithdot.svg";
import close from "../asstes/close.svg";

const TopUpNotification = () => {
  const [notifData, setNotifData] = useState([]);
  const [visitedLength, setVisitedLength] = useState(0);
  const [uid, setUID] = useState(sessionStorage.getItem("UserID"));
  const [notification, setNotification] = useState({});
  const [showtoast, setShowtoast] = useState(false);
  const [cardShow, setCardShow] = useState(false);
  const [list, setlist] = useState("");
  const [loading, setloading] = useState(true);
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [signupShow, setSignupShow] = useState(false);
  const handleSignupClose = () => setSignupShow(false);
  const totalNotifications = notifData.length;
  const unreadNotifications = notifData.filter(
    (notif) => !notif.isVisited
  ).length;
  const notificationRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setShowNotification(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setIsMobile(mobile);
  }, []);

  // Function to play notification sound
  function soundFunc() {
    var x = document.getElementById("notificationSound");
    x.play();
  }

  // Function to set the notification body
  const toastBodySet = (obj) => {
    setNotification({
      title: obj.type.charAt(0).toUpperCase() + obj.type.slice(1),
      body: obj.description,
    });
    if (window.location.pathname === "/ChatPage" && obj.type === "Message") {
      setShowtoast(false);
    } else {
      setShowtoast(true);
    }
    soundFunc();
  };

  // Function to fetch notifications from the API
  const getNotifs = async () => {
    let count = 0;
    let notifications = [];
    let url = `${apiHost}/api/User/GetNotification?UserId=${uid}`; // Replace with the correct API endpoint
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        let date = new Date();
        let ms = date.setDate(date.getDate() - 7);
        date = new Date(ms);
        response.data.notifs.forEach((d, i) => {
          if (new Date(d.time.split("T")[0]) > date) {
            if (!d.isVisited || d.isVisited === null) {
              count++;
            }
            let time = new Date(d.time).toLocaleString();
            let newtime =
              time.split(",")[0] +
              " at" +
              time.split(",")[1].split(":")[0] +
              ":" +
              time.split(",")[1].split(":")[1] +
              " " +
              time.split(",")[1].split(":")[2].split(" ")[1];
            let obj = { newtime: newtime, ...d };
            notifications.push(obj);
          }
        });
        setVisitedLength(count);
        setNotifData(notifications);
      });
  };

  useEffect(() => {
    setUID(sessionStorage.getItem("UserID"));
    const x = sessionStorage.getItem("logedin");
    if (x === "false") {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    getNotifs();
  }, [context.applicationStore.notificationMessage]);

  const Getdata = async () => {
    let url = `${apiHost}/api/User/GetUserById?UserId=${uid}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then(async (response) => {
        const data = response.data;
        const image = response.data.profileimagepath;
        if (image == "" || image == null) {
          let name = "storageImage";
          data[name] = "";
          setlist(data);
          setloading(false);
        } else {
          let name = "storageImage";
          data[name] = `${apiHost}/Images/${image}`;
          setlist(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    Getdata();
  }, [uid, loading, context.applicationStore.navigation]);

  const moveToPage = (notifObj) => {
    let notifType = notifObj.type;
    if (!notifObj.isVisited) {
      addDoc("RemoveNotif", {
        description: notifObj.description,
      }).then(() => {
        getNotifs();
      });
    }
    context.dispatch({ Type: "NAVIGATION" });
    if (notifType == "acceptinvite") {
      let orderId = notifObj.description.split("order")[1].trim();
      navigate(`/ChatPage?ID=${uid}?OrderId=${orderId}&t=${Date.now()}`);
    } else if (notifType == "invite") {
      let orderId = notifObj.description.split("order")[1].trim();
      navigate(`/Match_Tab?ID=${uid}?OrderId=${orderId}&t=${Date.now()}`);
    } else if (notifType == "rejectinvite") {
      let orderId = notifObj.description.split("order")[1].trim();
      navigate(`/Match_Tab?ID=${uid}?OrderId=${orderId}&t=${Date.now()}`);
    }
  };

  const navigateSignup = () => {
    let obj = {
      email: sessionStorage.getItem("email"),
      userId: sessionStorage.getItem("UserID"),
      userName: sessionStorage.getItem("UserName"),
    };
    let jsonString = JSON.stringify(obj);
    let encodedString = encodeURIComponent(jsonString);
    navigate(`/SignUp?data=${encodedString}`);
    setSignupShow(false);
  };

  return (
    <>
      <Modal
        show={signupShow}
        onHide={handleSignupClose}
        size="md"
        className="oneFXSignUpModal"
      >
        <Modal.Header>
          <Modal.Title>Full FX </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: "17px" }}>You need to signup as Full-FX user.</p>
        </Modal.Body>
        <Modal.Footer>
          <Col>
            <Button
              variant="Secondary"
              onClick={handleSignupClose}
              style={{ backgroundColor: "#9FA6B2", color: "white" }}
            >
              Cancel
            </Button>
          </Col>
          <Col style={{ textAlign: "end" }}>
            <Button variant="primary" onClick={navigateSignup}>
              SignUp
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>

      <div
        ref={notificationRef}
        className={`notificationTopup ${
          showNotification ? "show-notificationTopup" : ""
        } cursor-pointer`}
        onMouseEnter={() => !isMobile && setShowNotification(true)}
        onMouseLeave={() => !isMobile && setShowNotification(false)}
        onClick={() => isMobile && setShowNotification(!showNotification)}
      >
        <div
          className="d-flex justify-content-between align-items-center"
          style={{
            padding: "5px 2px",
            backgroundColor: "#2995be",
            color: "white",
          }}
        >
          <div className="d-flex justify-content-between align-items-center p-1">
            <img
              style={{
                backgroundColor: "#2995be",
                borderRadius: "50%",
                height: "25px",
                width: "25px",
                borderWidth: 3,
                marginLeft: "-2px",
                marginRight: "10px",
              }}
              src={unreadNotifications > 0 ? NotifyWithDot : Notify}
              alt="Notification Icon"
            />
            &nbsp;&nbsp;
            <h5
              style={{
                fontSize: 16,
                fontWeight: "500",
                marginBottom: "0px",
              }}
            >
              Notifications
            </h5>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p style={{ fontSize: 12, marginBottom: "0px" }}>(Last 7 days)</p>
          </div>
          <div>
            <img
              src={close}
              className={`varrow showNotification ${
                showNotification ? "rotated" : ""
              }`}
              alt="logo"
            />
          </div>
        </div>
        <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
        {showNotification && (
          <>
            <div
              className=""
              style={{ maxHeight: "400px", overflowY: "auto" }}
              onClick={() => {
                if (cardShow) {
                  setCardShow(false);
                } else {
                  setCardShow(true);
                }
              }}
            >
              <div className="card-body">
                <div></div>
                {notifData.map((item, index) => (
                  <div
                    key={index}
                    onClick={(k) => {
                      if (sessionStorage.getItem("City") == "null") {
                        setSignupShow(true);
                      } else {
                        moveToPage(item);
                      }
                    }}
                    className={
                      item.isVisited
                        ? "notifItem-visited"
                        : "notifItem-notvisited"
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "smaller",
                      backgroundColor: index % 2 === 0 ? "#dbdbdb" : "#ffffff",
                      fontWeight: item.isVisited ? "normal" : "bold",
                      padding: "4px",
                      marginBottom: "2px",
                    }}
                  >
                    <img
                      src={
                        item.senderProfileimagepath == ""
                          ? Picture
                          : `${apiHost}/Images/${item.senderProfileimagepath}`
                      }
                      alt="User Profile"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        marginRight: "10px",
                        border: "1px solid",
                      }}
                    />
                    <div>
                      <div className="subjectnotify" style={{ color: "black" }}>
                        {item.description}
                      </div>
                      <div className="datenotify" style={{ color: "black" }}>
                        {item.newtime}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TopUpNotification;
