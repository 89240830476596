
const currencyObj = [
  {"Code":"AED","symbol":"\u062f.\u0625;","Currency":"UAE dirham"},
  {"Code":"AFN","symbol":"Afs","Currency":"Afghan afghani"},
  {"Code":"ALL","symbol":"L","Currency":"Albanian lek"},
  {"Code":"AMD","symbol":"AMD","Currency":"Armenian dram"},
  {"Code":"ANG","symbol":"NA\u0192","Currency":"Netherlands Antillean gulden"},
  {"Code":"AOA","symbol":"Kz","Currency":"Angolan kwanza"},
  {"Code":"ARS","symbol":"$","Currency":"Argentine peso"},
  {"Code":"AUD","symbol":"$","Currency":"Australian dollar"},
  {"Code":"AWG","symbol":"\u0192","Currency":"Aruban florin"},
  {"Code":"AZN","symbol":"AZN","Currency":"Azerbaijani manat"},
  {"Code":"BAM","symbol":"KM","Currency":"Bosnia and Herzegovina konvertibilna marka"},
  {"Code":"BBD","symbol":"Bds$","Currency":"Barbadian dollar"},
  {"Code":"BDT","symbol":"\u09f3","Currency":"Bangladeshi taka"},
  {"Code":"BGN","symbol":"BGN","Currency":"Bulgarian lev"},
  {"Code":"BHD","symbol":".\u062f.\u0628","Currency":"Bahraini dinar"},
  {"Code":"BIF","symbol":"FBu","Currency":"Burundi franc"},
  {"Code":"BMD","symbol":"BD$","Currency":"Bermudian dollar"},
  {"Code":"BND","symbol":"B$","Currency":"Brunei dollar"},
  {"Code":"BOB","symbol":"Bs.","Currency":"Bolivian boliviano"},
  {"Code":"BRL","symbol":"R$","Currency":"Brazilian real"},
  {"Code":"BSD","symbol":"B$","Currency":"Bahamian dollar"},
  {"Code":"BTN","symbol":"Nu.","Currency":"Bhutanese ngultrum"},
  {"Code":"BWP","symbol":"P","Currency":"Botswana pula"},
  {"Code":"BYR","symbol":"Br","Currency":"Belarusian ruble"},
  {"Code":"BZD","symbol":"BZ$","Currency":"Belize dollar"},
  {"Code":"CAD","symbol":"$","Currency":"Canadian dollar"},
  {"Code":"CDF","symbol":"F","Currency":"Congolese franc"},
  {"Code":"CHF","symbol":"Fr.","Currency":"Swiss franc"},
  {"Code":"CLP","symbol":"$","Currency":"Chilean peso"},
  {"Code":"CNY","symbol":"\u00a5","Currency":"Chinese/Yuan renminbi"},
  {"Code":"COP","symbol":"Col$","Currency":"Colombian peso"},
  {"Code":"CRC","symbol":"\u20a1","Currency":"Costa Rican colon"},
  {"Code":"CUC","symbol":"$","Currency":"Cuban peso"},
  {"Code":"CVE","symbol":"Esc","Currency":"Cape Verdean escudo"},
  {"Code":"CZK","symbol":"K\u010d","Currency":"Czech koruna"},
  {"Code":"DJF","symbol":"Fdj","Currency":"Djiboutian franc"},
  {"Code":"DKK","symbol":"Kr","Currency":"Danish krone"},
  {"Code":"DOP","symbol":"RD$","Currency":"Dominican peso"},
  {"Code":"DZD","symbol":"\u062f.\u062c","Currency":"Algerian dinar"},
  {"Code":"EEK","symbol":"KR","Currency":"Estonian kroon"},
  {"Code":"EGP","symbol":"\u00a3","Currency":"Egyptian pound"},
  {"Code":"ERN","symbol":"Nfa","Currency":"Eritrean nakfa"},
  {"Code":"ETB","symbol":"Br","Currency":"Ethiopian birr"},
  {"Code":"EUR","symbol":"\u20ac","Currency":"European Euro"},
  {"Code":"FJD","symbol":"FJ$","Currency":"Fijian dollar"},
  {"Code":"FKP","symbol":"\u00a3","Currency":"Falkland Islands pound"},
  {"Code":"GBP","symbol":"\u00a3","Currency":"British pound"},
  {"Code":"GEL","symbol":"GEL","Currency":"Georgian lari"},
  {"Code":"GHS","symbol":"GH\u20b5","Currency":"Ghanaian cedi"},
  {"Code":"GIP","symbol":"\u00a3","Currency":"Gibraltar pound"},
  {"Code":"GMD","symbol":"D","Currency":"Gambian dalasi"},
  {"Code":"GNF","symbol":"FG","Currency":"Guinean franc"},
  {"Code":"GQE","symbol":"CFA","Currency":"Central African CFA franc"},
  {"Code":"GTQ","symbol":"Q","Currency":"Guatemalan quetzal"},
  {"Code":"GYD","symbol":"GY$","Currency":"Guyanese dollar"},
  {"Code":"HKD","symbol":"HK$","Currency":"Hong Kong dollar"},
  {"Code":"HNL","symbol":"L","Currency":"Honduran lempira"},
  {"Code":"HRK","symbol":"kn","Currency":"Croatian kuna"},
  {"Code":"HTG","symbol":"G","Currency":"Haitian gourde"},
  {"Code":"HUF","symbol":"Ft","Currency":"Hungarian forint"},
  {"Code":"IDR","symbol":"Rp","Currency":"Indonesian rupiah"},
  {"Code":"ILS","symbol":"\u20aa","Currency":"Israeli new sheqel"},
  {"Code":"INR","symbol":"\u20B9","Currency":"Indian rupee"},
  {"Code":"IQD","symbol":"\u062f.\u0639","Currency":"Iraqi dinar"},
  {"Code":"IRR","symbol":"IRR","Currency":"Iranian rial"},
  {"Code":"ISK","symbol":"kr","Currency":"Icelandic kr\u00f3na"},
  {"Code":"JMD","symbol":"J$","Currency":"Jamaican dollar"},
  {"Code":"JOD","symbol":"JOD","Currency":"Jordanian dinar"},
  {"Code":"JPY","symbol":"\u00a5","Currency":"Japanese yen"},
  {"Code":"KES","symbol":"KSh","Currency":"Kenyan shilling"},
  {"Code":"KGS","symbol":"\u0441\u043e\u043c","Currency":"Kyrgyzstani som"},
  {"Code":"KHR","symbol":"\u17db","Currency":"Cambodian riel"},
  {"Code":"KMF","symbol":"KMF","Currency":"Comorian franc"},
  {"Code":"KPW","symbol":"W","Currency":"North Korean won"},
  {"Code":"KRW","symbol":"W","Currency":"South Korean won"},
  {"Code":"KWD","symbol":"KWD","Currency":"Kuwaiti dinar"},
  {"Code":"KYD","symbol":"KY$","Currency":"Cayman Islands dollar"},
  {"Code":"KZT","symbol":"T","Currency":"Kazakhstani tenge"},
  {"Code":"LAK","symbol":"KN","Currency":"Lao kip"},
  {"Code":"LBP","symbol":"\u00a3","Currency":"Lebanese lira"},
  {"Code":"LKR","symbol":"Rs","Currency":"Sri Lankan rupee"},
  {"Code":"LRD","symbol":"L$","Currency":"Liberian dollar"},
  {"Code":"LSL","symbol":"M","Currency":"Lesotho loti"},
  {"Code":"LTL","symbol":"Lt","Currency":"Lithuanian litas"},
  {"Code":"LVL","symbol":"Ls","Currency":"Latvian lats"},
  {"Code":"LYD","symbol":"LD","Currency":"Libyan dinar"},
  {"Code":"MAD","symbol":"MAD","Currency":"MoroCodean dirham"},
  {"Code":"MDL","symbol":"MDL","Currency":"Moldovan leu"},
  {"Code":"MGA","symbol":"FMG","Currency":"Malagasy ariary"},
  {"Code":"MKD","symbol":"MKD","Currency":"Macedonian denar"},
  {"Code":"MMK","symbol":"K","Currency":"Myanma kyat"},
  {"Code":"MNT","symbol":"\u20ae","Currency":"Mongolian tugrik"},
  {"Code":"MOP","symbol":"P","Currency":"Macanese pataca"},
  {"Code":"MRO","symbol":"UM","Currency":"Mauritanian ouguiya"},
  {"Code":"MUR","symbol":"Rs","Currency":"Mauritian rupee"},
  {"Code":"MVR","symbol":"Rf","Currency":"Maldivian rufiyaa"},
  {"Code":"MWK","symbol":"MK","Currency":"Malawian kwacha"},
  {"Code":"MXN","symbol":"$","Currency":"Mexican peso"},
  {"Code":"MYR","symbol":"RM","Currency":"Malaysian ringgit"},
  {"Code":"MZM","symbol":"MTn","Currency":"Mozambican metical"},
  {"Code":"NAD","symbol":"N$","Currency":"Namibian dollar"},
  {"Code":"NGN","symbol":"\u20a6","Currency":"Nigerian naira"},
  {"Code":"NIO","symbol":"C$","Currency":"Nicaraguan c\u00f3rdoba"},
  {"Code":"NOK","symbol":"kr","Currency":"Norwegian krone"},
  {"Code":"NPR","symbol":"NRs","Currency":"Nepalese rupee"},
  {"Code":"NZD","symbol":"NZ$","Currency":"New Zealand dollar"},
  {"Code":"OMR","symbol":"OMR","Currency":"Omani rial"},
  {"Code":"PAB","symbol":"B./","Currency":"Panamanian balboa"},
  {"Code":"PEN","symbol":"S/.","Currency":"Peruvian nuevo sol"},
  {"Code":"PGK","symbol":"K","Currency":"Papua New Guinean kina"},
  {"Code":"PHP","symbol":"\u20b1","Currency":"Philippine peso"},
  {"Code":"PKR","symbol":"Rs.","Currency":"Pakistani rupee"},
  {"Code":"PLN","symbol":"z\u0142","Currency":"Polish zloty"},
  {"Code":"PYG","symbol":"\u20b2","Currency":"Paraguayan guarani"},
  {"Code":"QAR","symbol":"QR","Currency":"Qatari riyal"},
  {"Code":"RON","symbol":"L","Currency":"Romanian leu"},
  {"Code":"RSD","symbol":"din.","Currency":"Serbian dinar"},
  {"Code":"RUB","symbol":"R","Currency":"Russian ruble"},
  {"Code":"SAR","symbol":"SR","Currency":"Saudi riyal"},
  {"Code":"SBD","symbol":"SI$","Currency":"Solomon Islands dollar"},
  {"Code":"SCR","symbol":"SR","Currency":"Seychellois rupee"},
  {"Code":"SDG","symbol":"SDG","Currency":"Sudanese pound"},
  {"Code":"SEK","symbol":"kr","Currency":"Swedish krona"},
  {"Code":"SGD","symbol":"S$","Currency":"Singapore dollar"},
  {"Code":"SHP","symbol":"\u00a3","Currency":"Saint Helena pound"},
  {"Code":"SLL","symbol":"Le","Currency":"Sierra Leonean leone"},
  {"Code":"SOS","symbol":"Sh.","Currency":"Somali shilling"},
  {"Code":"SRD","symbol":"$","Currency":"SuriCurrencyse dollar"},
  {"Code":"SYP","symbol":"LS","Currency":"Syrian pound"},
  {"Code":"SZL","symbol":"E","Currency":"Swazi lilangeni"},
  {"Code":"THB","symbol":"\u0e3f","Currency":"Thai baht"},
  {"Code":"TJS","symbol":"TJS","Currency":"Tajikistani somoni"},
  {"Code":"TMT","symbol":"m","Currency":"Turkmen manat"},
  {"Code":"TND","symbol":"DT","Currency":"Tunisian dinar"},
  {"Code":"TRY","symbol":"TRY","Currency":"Turkish new lira"},
  {"Code":"TTD","symbol":"TT$","Currency":"Trinidad and Tobago dollar"},
  {"Code":"TWD","symbol":"NT$","Currency":"New Taiwan dollar"},
  {"Code":"TZS","symbol":"TZS","Currency":"Tanzanian shilling"},
  {"Code":"UAH","symbol":"UAH","Currency":"Ukrainian hryvnia"},
  {"Code":"UGX","symbol":"USh","Currency":"Ugandan shilling"},
  {"Code":"USD","symbol":"US$","Currency":"United States dollar"},
  {"Code":"UYU","symbol":"$U","Currency":"Uruguayan peso"},
  {"Code":"UZS","symbol":"UZS","Currency":"Uzbekistani som"},
  {"Code":"VEB","symbol":"Bs","Currency":"Venezuelan bolivar"},
  {"Code":"VND","symbol":"\u20ab","Currency":"VietCurrencyse dong"},
  {"Code":"VUV","symbol":"VT","Currency":"Vanuatu vatu"},
  {"Code":"WST","symbol":"WS$","Currency":"Samoan tala"},
  {"Code":"XAF","symbol":"CFA","Currency":"Central African CFA franc"},
  {"Code":"XCD","symbol":"EC$","Currency":"East Caribbean dollar"},
  {"Code":"XDR","symbol":"SDR","Currency":"Special Drawing Rights"},
  {"Code":"XOF","symbol":"CFA","Currency":"West African CFA franc"},
  {"Code":"XPF","symbol":"F","Currency":"CFP franc"},
  {"Code":"YER","symbol":"YER","Currency":"Yemeni rial"},
  {"Code":"ZAR","symbol":"R","Currency":"South African rand"},
  {"Code":"ZMK","symbol":"ZK","Currency":"Zambian kwacha"},
  {"Code":"ZWR","symbol":"Z$","Currency":"Zimbabwean dollar"}
]
let newCurrency = [];
for (let index = 0; index < currencyObj.length; index++) {
  newCurrency[index] = currencyObj[index]["Code"] + " (" + currencyObj[index]["Currency"] + ")";
}
// for (let index = 0; index < currency.length; index++) {
//   currency[index] = currency[index] + " (" + currenciesCurrency[index] + ")";
// }
// currency = [...new Set(currency)];
newCurrency = newCurrency.sort((a, b) => a.localeCompare(b));
export const Currencies = newCurrency//[...new Set(currency)];
